import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const registrationIcon = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 42 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M42 21.5C42 33.098 32.598 42.5 21 42.5C9.40202 42.5 0 33.098 0 21.5C0 9.90202 9.40202 0.5 21 0.5C32.598 0.5 42 9.90202 42 21.5Z'
        fill='#F5ECFF'
      />
      <path
        d='M26.4999 21.0003C29.5374 21.0003 31.9999 23.4627 31.9999 26.5003C31.9999 29.5378 29.5374 32.0003 26.4999 32.0003C23.4623 32.0003 20.9999 29.5378 20.9999 26.5003C20.9999 23.4627 23.4623 21.0003 26.4999 21.0003ZM23.8534 26.1467C23.6582 25.9514 23.3416 25.9514 23.1463 26.1467C22.9511 26.342 22.9511 26.6586 23.1463 26.8538L25.1463 28.8538C25.3416 29.0491 25.6582 29.0491 25.8534 28.8538L29.8534 24.8538C30.0487 24.6586 30.0487 24.342 29.8534 24.1467C29.6582 23.9514 29.3416 23.9514 29.1463 24.1467L25.4999 27.7931L23.8534 26.1467ZM21.0221 22.9996C20.7254 23.4629 20.4859 23.9663 20.3135 24.4999L13.2523 24.5002C12.8387 24.5002 12.5034 24.8355 12.5034 25.2491V25.8267C12.5034 26.3624 12.6945 26.8805 13.0424 27.2878C14.2957 28.7555 16.2616 29.5013 18.9999 29.5013C19.5963 29.5013 20.1562 29.4659 20.6801 29.3954C20.9253 29.8903 21.2328 30.3489 21.5916 30.7618C20.7961 30.922 19.9312 31.0013 18.9999 31.0013C15.854 31.0013 13.468 30.0962 11.9017 28.2619C11.3219 27.583 11.0034 26.7195 11.0034 25.8267V25.2491C11.0034 24.007 12.0103 23.0002 13.2523 23.0002L21.0221 22.9996ZM18.9999 11.0049C21.7613 11.0049 23.9999 13.2435 23.9999 16.0049C23.9999 18.7663 21.7613 21.0049 18.9999 21.0049C16.2384 21.0049 13.9999 18.7663 13.9999 16.0049C13.9999 13.2435 16.2384 11.0049 18.9999 11.0049ZM18.9999 12.5049C17.0669 12.5049 15.4999 14.0719 15.4999 16.0049C15.4999 17.9379 17.0669 19.5049 18.9999 19.5049C20.9329 19.5049 22.4999 17.9379 22.4999 16.0049C22.4999 14.0719 20.9329 12.5049 18.9999 12.5049Z'
        fill='#AC61FF'
      />
    </svg>
  );
};

registrationIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

registrationIcon.defaultProps = {
  width: '42px',
  height: '43px',
};

export default registrationIcon;
