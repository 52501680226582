/**
 * Created by mehmetyurtar on 4/2/18.
 */
import React, { Component } from "react";

class IconTailRight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "14px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 14"
        width={this.state.width}
        height={this.state.height}
      >
        <g id="Page-1" stroke="none" strokeWidth={1} fillRule="evenodd">
          <g
            id="Account-Switch"
            transform="translate(-996.000000, -201.000000)"
            fillRule="nonzero"
          >
            <g id="Group-2" transform="translate(338.000000, 104.000000)">
              <g id="Group" transform="translate(0.000000, 69.000000)">
                <g id="Group-3" transform="translate(0.000000, 24.000000)">
                  <g
                    id="arrows-16px-1_tail-right"
                    transform="translate(658.000000, 4.000000)"
                  >
                    <polygon
                      id="Shape"
                      points="7.879 1.707 12.172 6 0 6 0 8 12.172 8 7.879 12.293 9.293 13.707 16 7 9.293 0.293"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconTailRight;
