/**
 * Created by mehmetyurtar on 21/06/2017.
 */
/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { uiActions } from "../../../actions";

import { TextField } from "../../../components/fields";
import { t } from "../../../system/ui";

class NotificationImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      name: "image"
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    let image = this.props.campaign.image || "";
    this.updateValue(image);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.image);
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  onFieldChange(e) {
    this.updateValue(e.target.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <li>
        <TextField
          name="image"
          label={this.props.options.label}
          className="item-stacked one-whole"
          value={this.state.value}
          required={false}
          onChange={this.onFieldChange}
          validation={{ secureURL: true }}
          tooltip={true}
          tooltipText={t(
            "Enter the URL address of the image that will be displayed on the web push notification."
          )}
        />
      </li>
    );
  }
}

NotificationImage.defaultProps = {
  options: {
    label: "Notification Image URL"
  }
};

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  NotificationImage
);
