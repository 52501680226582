export const globalExclusionConverterForBackEnd = (exclusions = []) => {
  const convertedForBackEnd = [];

  Object.keys(exclusions).forEach(exclusion => {
    let type;
    let productItem;
    if (
      exclusion.slice(-3) !== 'ies' &&
      exclusion.charAt(exclusion.length - 1) === 's'
    ) {
      let exclusionItem = {};
      type = exclusion.substring(0, exclusion.length - 1).toUpperCase();
      if (type === 'MAPPEDPRODUCT') {
        exclusionItem = {
          type: 'PRODUCT',
          products: [
            ...exclusions.mappedProducts.map(({ value, label }) => {
              productItem = {
                id: value,
                name: label,
              };
              return productItem;
            }),
          ],
        };
      }
      if (type !== 'MAPPEDPRODUCT') {
        exclusionItem = {
          type,
          [exclusion]: [...exclusions[exclusion].map(({ value }) => value)],
        };
      }
      convertedForBackEnd.push(exclusionItem);
    } else if (exclusion === 'categories') {
      let categoryItem;
      // eslint-disable-next-line prefer-const
      categoryItem = {
        type: 'CATEGORY',
        [exclusion]: [...exclusions[exclusion].map(({ value }) => value)],
      };
      convertedForBackEnd.push(categoryItem);
    }
  });
  return convertedForBackEnd;
};

export const globalExclusionConverterForFrontEnd = exclusions => {
  let convertedForFrontEnd = {};
  let generalItem;
  let item;
  exclusions.forEach(({ type, products, categories, brands, labels }) => {
    switch (type) {
      case 'PRODUCT':
        generalItem = [
          ...products.map(productElement => {
            item = {
              value: productElement.id,
              label: productElement.name,
            };
            return item;
          }),
        ];
        convertedForFrontEnd = {
          ...convertedForFrontEnd,
          mappedProducts: generalItem,
        };
        break;
      case 'CATEGORY':
        generalItem = [
          ...categories.map(categoryElement => {
            item = {
              value: categoryElement,
              label: categoryElement,
            };
            return item;
          }),
        ];
        convertedForFrontEnd = {
          ...convertedForFrontEnd,
          categories: generalItem,
        };
        break;
      case 'BRAND':
        generalItem = [
          ...brands.map(brandElement => {
            item = {
              value: brandElement,
              label: brandElement,
            };
            return item;
          }),
        ];
        convertedForFrontEnd = { ...convertedForFrontEnd, brands: generalItem };
        break;
      case 'LABEL':
        generalItem = [
          ...labels.map(labelElement => {
            item = {
              value: labelElement,
              label: labelElement,
            };
            return item;
          }),
        ];
        convertedForFrontEnd = { ...convertedForFrontEnd, labels: generalItem };
        break;
      default:
    }
  });
  return convertedForFrontEnd;
};
