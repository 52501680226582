import React from "react";

class IconPlus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "18px",
      height: "18px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox="0 0 24 24"
        fill={this.state.color}
        className="svg-icon"
      >
        <path
          d="M25.7104403,12.1675034 L17.832544,12.1675034 L17.832544,4.28960711 C17.832544,3.99962274 17.7439377,3.76602467 17.5667251,3.58881287 C17.3895125,3.41160025 17.1398044,3.32299394 16.8176,3.32299394 L12.9028198,3.32299394 C12.5806154,3.2907735 12.3309065,3.37132464 12.1536947,3.56464744 C11.9764821,3.75797007 11.8878758,3.99962274 11.8878758,4.28960711 L11.8878758,12.1675034 L4.0099795,12.1675034 C3.71999514,12.1675034 3.48639706,12.2561097 3.30918526,12.4333223 C3.13197264,12.6105349 3.04336633,12.860243 3.04336633,13.1824474 L3.04336633,17.0972276 C3.04336633,17.419432 3.13197264,17.6691409 3.30918526,17.8463527 C3.48639788,18.0235653 3.71999596,18.1121717 4.0099795,18.1121717 L11.8878758,18.1121717 L11.8878758,25.9900679 C11.8878758,26.2800523 11.9764821,26.5136503 12.1536947,26.6908621 C12.3309073,26.8680748 12.5806154,26.9566811 12.9028198,26.9566811 L16.8176,26.9566811 C17.1398044,26.9566811 17.3895133,26.8680748 17.5667251,26.6908621 C17.7439377,26.5136495 17.832544,26.2800514 17.832544,25.9900679 L17.832544,18.1121717 L25.7104403,18.1121717 C26.0004247,18.1121717 26.2340228,18.0235653 26.4112345,17.8463527 C26.5884471,17.6691401 26.6770535,17.419432 26.6770535,17.0972276 L26.6770535,13.1824474 C26.7092739,12.860243 26.6287228,12.6105341 26.4354,12.4333223 C26.2420773,12.2561097 26.0004247,12.1675034 25.7104403,12.1675034 Z"
          transform="translate(-3 -3)"
        />
      </svg>
    );
  }
}

export default IconPlus;
