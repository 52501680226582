import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.replace('px', '')}px`;
};

const People = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.5164 9.86126L25.3433 11.398C26.2203 11.8728 26.7471 12.6725 26.7473 13.5289V14.7198C26.7473 15.4271 26.0418 16.0004 25.1716 16.0004H19.8297C19.7147 15.9159 17.89 14.7633 17.89 14.7633C18.5327 13.7614 18.8684 12.6482 18.8685 11.5183V10.2378C18.8672 8.27504 17.8545 6.40249 16.0762 5.07444C16.8095 3.76047 18.5337 3.00744 20.2867 3.23552C22.2341 3.53384 23.6324 4.93052 23.5958 6.5407V7.67658C23.5927 8.49014 23.2065 9.27187 22.5164 9.86126ZM16.8524 26.6715H4.62914L4.62914 26.6715C4.26894 26.6715 3.92349 26.511 3.66879 26.2252C3.41409 25.9394 3.271 25.5518 3.271 25.1476V22.1776C3.27094 21.2289 3.66455 20.3344 4.33578 19.7576L8.03807 16.5757C6.75423 15.5823 5.98753 13.9444 5.98728 12.1945V10.6706C5.9854 9.20501 6.52111 7.80308 7.46858 6.79417C8.41604 5.78526 9.69177 5.25828 10.9961 5.33701C13.5846 5.59465 15.5516 8.06029 15.4943 10.9754V12.1945C15.494 13.9408 14.7301 15.5759 13.4503 16.5696L17.124 19.6616C17.808 20.2372 18.2105 21.1405 18.2105 22.0998V25.1476C18.2105 25.9892 17.6025 26.6715 16.8524 26.6715Z'
        fill={color}
      />
    </svg>
  );
};

People.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

People.defaultProps = {
  width: '30px',
  height: '30px',
  color: '#000',
};

export default People;
