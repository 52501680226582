import useExtendedContext from '../../../../hooks/module-context/useExtendedContext';
import { GamificationReducer } from './reducers/gamificationReducer';
import {
  editActiveWOFDetails,
  resetActiveWOFDetails,
  setActiveWOFDetails,
  setGamificationList,
  resetActiveScratchDetails,
  setActiveScratchDetails,
  editActiveScratchDetails,
} from './actions/gamificationActions';
import {
  initialWOFDetails,
  initialScratchDetails,
} from '../constants/constants';

const gamificationState = {
  gamificationCampaignList: {
    active: [],
    test: [],
    passive: [],
  },
  activeWOFDetails: initialWOFDetails,
  activeScratchDetails: initialScratchDetails,
};

/**
 * @name gamificationContext
 * @description Context for gamification component. Provides access to the state and actions of the Wrapped component. Actions, state and dispatch are provided as react-hook basis.
 * @property {object} state - State of the context
 */

export const { Context, Provider } = useExtendedContext(
  GamificationReducer,
  {
    setGamificationList,
    setActiveWOFDetails,
    editActiveWOFDetails,
    resetActiveWOFDetails,
    resetActiveScratchDetails,
    setActiveScratchDetails,
    editActiveScratchDetails,
  },
  gamificationState,
);
