import React, { useEffect } from 'react';
import classes from './UserCard.module.scss';
import { t } from '../../../system/ui';
import Icons from '../../icons';
import { DateFormatter } from '../utils';

const SFYUserCard = ({ user }) => {
  const notificationListShape = () => {
    return Object.entries(user.notifications).map(
      ([notificationType, notificationPermission]) => {
        return (
          <>
            <div
              className={classes['sub-content-value-item']}
              key={notificationType}
            >
              {notificationPermission === 'GRANTED' ? (
                <Icons name='confirmationTickIcon' />
              ) : notificationPermission === 'DENIED' ? (
                <Icons name='dismissIcon' />
              ) : (
                <span className={classes['sub-content-value-item-text']}>
                  {t('Unknown')}
                </span>
              )}
            </div>
          </>
        );
      },
    );
  };

  useEffect(() => {
    notificationListShape();
  }, [user.notifications]);

  return (
    <>
      <div className={classes.userCard}>
        <div className={classes['userCard-hero']}>
          <div className={classes['icon-container']}>
            <Icons name='customerPictureIcon' />
          </div>
          <div className={classes['user-hero']}>
            <div className={classes['user-hero-name']}>
              {user?.userName ? user?.userName : 'Unknown User'}
            </div>
            <div className={classes['user-hero-id']}>
              {user?.userId ? `ID : ${user.userId}` : '-'}
            </div>
          </div>
        </div>
        <div className={classes['userCard-information']}>
          <div className={classes['userCard-information-item']}>
            <Icons name='cpMailIcon' />
            <span className={classes['userCard-information-item-text']}>
              {user?.email ? user.email : '-'}
            </span>
          </div>
          <div className={classes['userCard-information-item']}>
            <Icons name='cpPhoneIcon' />
            <span className={classes['userCard-information-item-text']}>
              {user?.phoneNumber ? user.phoneNumber : '-'}
            </span>
          </div>
          <div className={classes['userCard-information-item']}>
            <Icons name='cpGenderIcon' />
            <span className={classes['userCard-information-item-text']}>
              {user?.gender ? user.gender : '-'}
            </span>
          </div>
          <div className={classes['userCard-information-item']}>
            <Icons name='cpBirthdayIcon' />
            <span className={classes['userCard-information-item-text']}>
              {user?.birthDate
                ? DateFormatter(user.birthDate, 'DD-MMM-YY', ',') || '-'
                : '-'}
            </span>
          </div>
          <div className={classes['userCard-information-browser']}>
            <div className={classes['userCard-information-browser-item']}>
              <Icons name='mobile' color='#979797' />
              <span className={classes['userCard-information-item-text']}>
                {user?.device?.length > 0
                  ? user.device.map((device, index) => {
                      return (
                        <span key={index}>
                          {device}
                          {index !== user.device.length - 1 ? ', ' : ''}
                        </span>
                      );
                    })
                  : '-'}
              </span>
            </div>
            <div className={classes['userCard-information-browser-item']}>
              <Icons name='pc' color='#979797' width='16' height='12' />
              <span className={classes['userCard-information-item-text']}>
                {user?.browser?.length > 0
                  ? user.browser.map((browser, index) => {
                      return (
                        <span key={index}>
                          {browser}
                          {index !== user.browser.length - 1 ? ', ' : ''}
                        </span>
                      );
                    })
                  : '-'}
              </span>
            </div>
          </div>
          <div className={classes['userCard-information-consents']}>
            <div className={classes['userCard-consents-title']}>
              {t('Consents')}
            </div>
            <div className={classes['sub-content']}>
              <div>
                <div className={classes['consents-item']}>
                  <span className={classes['userCard-consents-text']}>
                    {t('Email Subscription')}
                  </span>
                </div>
                <div className={classes['consents-item']}>
                  <span className={classes['userCard-consents-text']}>
                    {t('Push Permission')}
                  </span>
                </div>
                <div className={classes['consents-item']}>
                  <span className={classes['userCard-consents-text']}>
                    {t('SMS')}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                {notificationListShape()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SFYUserCard;
