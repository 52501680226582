/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { t } from "../../../system/ui";

import { uiActions } from "../../../actions";

import { CheckboxGroup, Checkbox } from "../../../components/fields";

const devices = {
  web: {
    name: "Web",
    _list: ["PC", "MOBILE"],
    list: [
      { label: "Desktop", value: "PC" },
      { label: "Mobile Web", value: "MOBILE" }
    ]
  },
  app: {
    name: "App",
    _list: ["ANDROID", "IOS"],
    list: [
      { label: "Android", value: "ANDROID" },
      { label: "iOS", value: "IOS" }
    ]
  }
};

class SearchDevices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: [],
      name: "devices",
      web: "none",
      app: "none",
      hasError: false,
      showRequiredError: false
    };

    this.validate = this.validate.bind(this);
    this.deviceSelection = this.deviceSelection.bind(this);
    this.toggleType = this.toggleType.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  componentDidMount() {
    let devices = this.props.campaign.devices || [];
    this.updateValue(devices);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      let devices = newProps.campaign.devices || [];
      this.updateValue(devices);
    }

    if (newProps.validate === true) {
      this.validate();
    }
  }

  updateValue(
    newValue,
    callback = () => {
      return false;
    }
  ) {
    let selectedDevices = [];
    let types = { app: this.state.app, web: this.state.web };

    newValue.forEach(device => {
      if (!selectedDevices.includes(device)) {
        selectedDevices.push(device);
      }
    });

    Object.keys(devices).map(key => {
      let isAllSelected = devices[key]._list.every(device => {
        return selectedDevices.includes(device);
      });

      let isSomeSelected = devices[key]._list.some(device => {
        return selectedDevices.includes(device);
      });

      if (isAllSelected) {
        types[key] = "all";
      } else if (isSomeSelected) {
        types[key] = "partial";
      } else {
        types[key] = "none";
      }
    });
    if (selectedDevices.indexOf("MOBILE") !== -1 && selectedDevices.indexOf("TABLET") === -1) {
      selectedDevices.push("TABLET");
    }
    if (selectedDevices.indexOf("MOBILE") === -1) {
      let tabletIndex = selectedDevices.indexOf("TABLET");
      if (tabletIndex > -1) {
        selectedDevices.splice(tabletIndex, 1);
      }
    }
    this.setState(
      { value: selectedDevices, app: types.app, web: types.web },
      callback
    );
  }

  validate() {
    let hasError = false,
      showRequiredError = false;

    if (this.state.value.length === 0) {
      hasError = true;
      showRequiredError = true;
    }

    this.setState({ hasError, showRequiredError });
  }

  deviceSelection(val) {
    this.updateValue(val, this.validate);
    uiActions.formEdited();
  }

  toggleType(type) {
    let selectedDevices = this.state.value,
      types = devices[type],
      isAllReadyToggled,
      toggled = "none";

    isAllReadyToggled = types._list.every(device => {
      return selectedDevices.includes(device);
    });

    if (isAllReadyToggled) {
      types._list.forEach(device => {
        let index = selectedDevices.indexOf(device);

        if (index > -1) {
          selectedDevices.splice(index, 1);
        }
      });

      toggled = "none";
    } else {
      types._list.forEach(device => {
        if (!selectedDevices.includes(device)) {
          selectedDevices.push(device);
        }
      });

      toggled = "all";
    }

    if (
      selectedDevices.indexOf("MOBILE") !== -1 &&
      selectedDevices.indexOf("TABLET") === -1
    ) {
      selectedDevices.push("TABLET");
    }
    if (selectedDevices.indexOf("MOBILE") === -1) {
      let tabletIndex = selectedDevices.indexOf("TABLET");
      if (tabletIndex > -1) {
        selectedDevices.splice(tabletIndex, 1);
      }
    }

    this.setState(
      {
        value: selectedDevices,
        [type]: toggled
      },
      this.validate
    );
    uiActions.formEdited();
  }

  render() {
    let finalDevices = this.props.isPush ? { web: devices.web } : devices;
    return (
      <div
        className={classNames("wizard-medium-selection", {
          "has-error": this.state.hasError
        })}
      >
        <label className="item item-stacked label-emphasise one-whole">
          <span className="item-label">{t("Device Types")}</span>
        </label>
        <CheckboxGroup
          value={this.state.value}
          className="device-type-selection"
          onChange={this.deviceSelection}
        >
          {Object.keys(finalDevices).map(key => {
            let device = finalDevices[key];
            return (
              <div key={key} className="check-group">
                <div className="check-group-header">
                  {t(device.name)}
                  <span
                    className={classNames("check-group-toggle", {
                      checked: this.state[key] === "all",
                      "semi-checked": this.state[key] === "partial"
                    })}
                    onClick={this.toggleType.bind(null, key)}
                  />
                </div>

                <div className="device-types check-group-drop">
                  {device.list.map(item => {
                    return (
                      <Checkbox
                        key={item.value}
                        label={item.label}
                        value={item.value}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </CheckboxGroup>
        {this.state.showRequiredError ? (
          <span className="item-error">
            {t("You should select at least one device type")}
          </span>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  validate: store.ui.validate
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  SearchDevices
);
