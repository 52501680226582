import React from 'react';
import { notification, t } from '../../system/ui';
import { capitalize } from '../../system/string';
import { clone } from '../../system/object';

import { campaignActions, jbActions, uiActions } from '../../actions';
import { config } from '../../constants/config';
import {
  campaignData,
  initialAction,
  initialJourneyBuilderDeliveryAsset,
  initialJourneyBuilderDiscoveryAsset,
} from '../../constants/datamaps/journey-builder';
import * as Notifications from '../../constants/datamaps/notifications';
import { templates } from '../../constants/datamaps/templates';
import { defaultValues, pageTypes } from '../../constants/datamaps/wizard';
import { campaignTemplate, postJS, preJS } from '../../constants/template';
import { getAccountId, isSwitchAccount } from '../auth/user';
import { addCampaign, updateCampaign } from './ajax';

const fillTitleWithLanguages = (user, switchedUser, jbTitle) => {
  const account = isSwitchAccount() ? switchedUser.account : user.account;

  return {
    [account.mainLanguage]:
      account.mainLanguage === 'TR' ? jbTitle.TR : jbTitle.EN,
    ...account.additionalLanguages.reduce(
      (result, language) => ({
        ...result,
        [language]: language === 'TR' ? jbTitle.TR : jbTitle.EN,
      }),
      {},
    ),
  };
};

export const goTestOrLive = (type, context, routerPageType, optionalFields) => {
  let pageType =
    pageTypes.indexOf(routerPageType) > -1 ? routerPageType : 'journey_builder';

  const query = clone(campaignData);
  let method = addCampaign;
  let action = 'add';
  const { templateId } = defaultValues[pageType];
  const wizardDateSelection = context.refs.wizardDateSelection.state;
  // connect attached so get the real component with getWrappedInstance()
  const wizardAdvancedConfig = context.refs.wizardAdvancedConfig.state;
  // connect attached so get the real component with getWrappedInstance()
  const wizardMediumSelection = context.refs.wizardMediumSelection.state;
  const wizardPageType = context.refs.wizardPageType.state;

  // general info
  query.accountId = getAccountId();
  if (context.state.campaign.instanceId) {
    method = updateCampaign;
    action = 'update';
    query.instanceId = context.state.campaign.instanceId;
  }

  query.scenarioName = context.state.scenarioName;
  query.templateId = templateId;
  query.testMode = type === 'test';
  query.limitations.activeDates.startDate = wizardDateSelection.startDate.toISOString();
  query.limitations.activeDates.endDate = null;

  if (wizardDateSelection.showEndDate) {
    if (wizardDateSelection.endDate) {
      query.limitations.activeDates.endDate = wizardDateSelection.endDate.toISOString();
    } else {
      const newDate = wizardDateSelection.startDate.clone();
      newDate.add(15, 'm');
      query.limitations.activeDates.endDate = newDate.toISOString();
    }
  }
  // trigger side
  /* Protected Existing Variables */
  if (typeof context.state.campaign.actions !== 'undefined') {
    query.actions[0].id = context.state.campaign.actions[0].id;
    query.actions[0].params = context.state.campaign.actions[0].params;

    query.actions[0].constraint =
      context.state.campaign.actions[0].constraint || null;
    query.actions[0].controlGroup =
      context.state.campaign.actions[0].controlGroup || null;
    query.actions[0].delayAction =
      context.state.campaign.actions[0].delayAction || null;
    query.actions[0].group = context.state.campaign.actions[0].group || null;
  }
  query.actions[0].params.notificationTitle = context.state.title;

  // trigger advance config side
  query.actions[0].params.recommendationTemplate =
    wizardAdvancedConfig.htmlTemplate || campaignTemplate;
  query.actions[0].params.preJsCode =
    wizardAdvancedConfig.prejsTemplate || preJS;
  query.actions[0].params.postJsCode =
    wizardAdvancedConfig.postjsTemplate || postJS;
  query.actions[0].params.cssCode = wizardAdvancedConfig.cssTemplate || '';

  // Engagement Options
  if (optionalFields) {
    query.actions[0].params.overlay = optionalFields.overlay;
    query.actions[0].params.verticalPosition = optionalFields.verticalPosition;
    query.actions[0].params.horizontalPosition =
      optionalFields.horizontalPosition;
    query.actions[0].delayAction = optionalFields.delayAction;
    query.actions[0].constraint = optionalFields.constraint;
    query.actions[0].filters = optionalFields.filters;
  }

  if (typeof context.state.campaign.actions !== 'undefined') {
    query.actions[1].id = context.state.campaign.actions[1].id;
    query.actions[1].params = context.state.campaign.actions[1].params;
  }

  // Discovery side
  const discoveryList = context.props.discovery
    .filter(c => c.properties.active)
    .map(criteria => {
      const discoveryItem = {
        type: '',
        titleMap: {},
        buttonTextMap: {},
        items: [],
        recommendationTemplate: '',
        preJsCode: '',
        postJsCode: '',
        cssCode: '',
      };
      discoveryItem.type = criteria.properties.type;
      discoveryItem.titleMap = fillTitleWithLanguages(
        context.props.user,
        context.props.switchedUser,
        criteria.values.criterion.additionalTitles,
      );

      if (criteria.properties.type === 'WELCOME') {
        discoveryItem.buttonTextMap = criteria.values.criterion.buttonText;
      } else if (
        criteria.properties.type === 'MAIN_CATEGORY' &&
        criteria.values.criterion.assetItems
      ) {
        discoveryItem.items = criteria.values.criterion.assetItems.map(
          item => ({
            type: 'STATIC',
            source: item,
          }),
        );
      } else if (criteria.properties.type !== 'PRICE_RANGE') {
        if (criteria.values.criterion.assetItems) {
          discoveryItem.items = criteria.values.criterion.assetItems.map(
            item => ({
              type: item.type === 'intelligent' ? 'INTELLIGENT' : 'STATIC',
              source: item.values.criterion.value,
              itemCount: item.values.itemCount.value,
            }),
          );
        }
      } else if (criteria.properties.type === 'PRICE_RANGE') {
        // push dummy value
        discoveryItem.items = [
          {
            type: 'INTELLIGENT',
            source: 'price',
          },
        ];
      }

      // discovery advance config
      discoveryItem.recommendationTemplate = criteria.advanceConfig.html;
      discoveryItem.preJsCode = criteria.advanceConfig.prejs;
      discoveryItem.postJsCode = criteria.advanceConfig.postjs;
      discoveryItem.cssCode = criteria.advanceConfig.css;

      return discoveryItem;
    });
  query.actions[1].params.discovery = JSON.stringify(discoveryList);
  query.actions[1].params.isCategoryTree = context.props.isCategoryTree;

  // delivery
  const dynamicItems = [];
  const staticItems = [];
  const deliveryAdvancedConfig =
    context.deliveryRef.current.jbItem.current.jbCriteria.current.state;

  let index;
  context.props.delivery
    .filter(c => c.properties.active)
    .forEach(criteria => {
      if (criteria.properties.type === 'RECOMMEND') {
        query.actions.push(clone(initialAction));
        index = query.actions.length - 1;
        query.actions[index].type = 'recommendProducts';

        /* TODO: Problematic point
        if (context.state.campaign.actions) {
          for (let i = 2; i < context.state.campaign.actions.length; i += 1) {
            if (
              context.state.campaign.actions[i] &&
              context.state.campaign.actions[i].type === 'recommendProducts'
            ) {
              query.actions[index].id = context.state.campaign.actions[i].id;
              query.actions[index].params =
                context.state.campaign.actions[i].params;
            }
          }
        }
        */

        if (criteria.values.criterion.assetItems) {
          criteria.values.criterion.assetItems.forEach(item => {
            const obj = {};
            if (item.type === 'intelligent') {
              obj.type = 'INTELLIGENT';
              obj.recommendationSource =
                item.values.criterion.value === 'BEST_MATCH'
                  ? 'RECOMMENDATION_BEST_MATCH'
                  : item.values.criterion.value;
              obj.itemCount = item.values.itemCount.value;
              if (item.values.criterion.value !== 'BEST_MATCH') {
                obj.timeFrame = item.values.timeFrame.value;
              }
              dynamicItems.push(obj);
            } else {
              staticItems.push({
                productId: item.values.criterion.value,
                productSource: 'INTERNAL',
                type: 'PRODUCT',
              });
            }
          });
        }
        query.actions[index].params.dynamicItems = JSON.stringify(dynamicItems);
        query.actions[index].params.staticItems = JSON.stringify(staticItems);

        query.actions[index].params.notificationTitleMap = JSON.stringify(
          fillTitleWithLanguages(
            context.props.user,
            context.props.switchedUser,
            criteria.values.criterion.additionalTitles,
          ),
        );

        // give default pop-up
        query.actions[index].params.actionType = 'POPUP_RECO';

        query.actions[index].params.recommendationTemplate =
          deliveryAdvancedConfig.htmlTemplate || campaignTemplate;
        query.actions[index].params.preJsCode =
          deliveryAdvancedConfig.prejsTemplate || preJS;
        query.actions[index].params.postJsCode =
          deliveryAdvancedConfig.postjsTemplate || postJS;
        query.actions[index].params.cssCode =
          deliveryAdvancedConfig.cssTemplate || '';
      } else if (criteria.properties.type === 'COUPON') {
        query.actions.push(clone(initialAction));
        index = query.actions.length - 1;
        query.actions[index].type = 'giveCoupon';

        query.actions[index].params.coupon = criteria.values.criterion.coupon;
        query.actions[index].params.notificationTitleMap = JSON.stringify(
          fillTitleWithLanguages(
            context.props.user,
            context.props.switchedUser,
            criteria.values.criterion.additionalTitles,
          ),
        );
        query.actions[index].params.description = JSON.stringify(
          criteria.values.criterion.desc,
        );

        query.actions[index].params.recommendationTemplate =
          deliveryAdvancedConfig.htmlTemplate || campaignTemplate;
        query.actions[index].params.preJsCode =
          deliveryAdvancedConfig.prejsTemplate || preJS;
        query.actions[index].params.postJsCode =
          deliveryAdvancedConfig.postjsTemplate || postJS;
        query.actions[index].params.cssCode =
          deliveryAdvancedConfig.cssTemplate || '';

        /* TODO: Problematic point
        if (context.state.campaign.actions) {
          for (let i = 2; i < context.state.campaign.actions.length; i += 1) {
            if (
              context.state.campaign.actions[i] &&
              context.state.campaign.actions[i].type === 'giveCoupon'
            ) {
              query.actions[index].id = context.state.campaign.actions[i].id;
              query.actions[index].params =
                context.state.campaign.actions[i].params;
              break;
            }
          }
        }
        */
      } else if (criteria.properties.type === 'LANDING') {
        query.actions.push(clone(initialAction));
        index = query.actions.length - 1;
        query.actions[index].type = 'landingPage';

        query.actions[index].params.landingPageUrl =
          criteria.values.criterion.pageUrl;
        query.actions[index].params.notificationTitleMap = JSON.stringify(
          fillTitleWithLanguages(
            context.props.user,
            context.props.switchedUser,
            criteria.values.criterion.additionalTitles,
          ),
        );

        query.actions[index].params.recommendationTemplate =
          deliveryAdvancedConfig.htmlTemplate || campaignTemplate;
        query.actions[index].params.preJsCode =
          deliveryAdvancedConfig.prejsTemplate || preJS;
        query.actions[index].params.postJsCode =
          deliveryAdvancedConfig.postjsTemplate || postJS;
        query.actions[index].params.cssCode =
          deliveryAdvancedConfig.cssTemplate || '';

        /* TODO: Problematic point
        if (context.state.campaign.actions) {
          for (let i = 2; i < context.state.campaign.actions.length; i += 1) {
            if (
              context.state.campaign.actions[i] &&
              context.state.campaign.actions[i].type === 'landingPage'
            ) {
              query.actions[index].id = context.state.campaign.actions[i].id;
              query.actions[index].params =
                context.state.campaign.actions[i].params;
              break;
            }
          }
        }
        */
      }
    });

  // main input side
  query.inputs = templates[templateId].inputs.map(input => {
    let pageName;

    if (wizardPageType !== null) {
      pageName = wizardPageType.value;
    } else {
      pageName = [
        `${config.menus.primary.recommendations.children[pageType].name} Page`,
      ];
    }

    if (pageName && pageName[0] === 'Trigger Based Page') {
      pageName = [context.state.eventName];
    }

    if (wizardPageType) {
      if (wizardPageType.selectedFilter === 'url') {
        const pageUrls = wizardPageType.filterUrls.map(url => url.value);
        const pageURLName = pageUrls.filter(item => item);
        if (input.name === 'includeUrls') {
          return {
            name: input.name,
            clazz: input.clazz,
            value:
              wizardPageType.selectedUrlFilter === 'contains'
                ? pageURLName
                : [],
          };
        }
        if (input.name === 'excludeUrls') {
          return {
            name: input.name,
            clazz: input.clazz,
            value:
              wizardPageType.selectedUrlFilter === 'notContains'
                ? pageURLName
                : [],
          };
        }
        return {
          name: input.name,
          clazz: input.clazz,
          value: wizardPageType.customPages,
        };
      }
      return {
        name: input.name,
        clazz: input.clazz,
        value:
          input.name !== 'includeUrls' && input.name !== 'excludeUrls'
            ? pageName
            : [],
      };
    }
    return {
      name: input.name,
      clazz: input.clazz,
      value: pageName,
    };
  });

  query.device = wizardMediumSelection.device;

  method(query, response => {
    if (response.instanceId) {
      const note =
        Notifications[`${capitalize(type)}Mode${capitalize(action)}`];
      notification({
        content: note,
      });

      pageType = 'journey_builder';

      campaignActions.duplicateCampaign(false);

      const ext = context.context.router.routes[0].path || '';

      context.context.router.push({
        pathname: `${ext}/edit/${pageType}/fullWidth/${response.instanceId}`,
      });

      uiActions.showBreadcrumb({
        home: {
          name: t('Recommend'),
          icon: 'heart',
          slug: '/recommendations',
        },
        top: {
          name: t('Journey Builder'),
          slug: `/recommendations/${pageType}`,
        },
        current: {
          name: query.scenarioName,
        },
      });

      context.setState({ campaignStatus: query.testMode ? 'Test' : 'Live' });
    } else {
      context.setState(
        {
          isSubmitting: false,
          submittingButton: '',
        },
        () => {
          const errorMessage = () => <p>{response.response.message}</p>;
          if (response.response) {
            notification({
              content: errorMessage,
              className: `notification-${response.response.toLowerCase()}`,
            });
          }
        },
      );
    }
  });
};

const getRecommendAction = (action, deliveryItem) => {
  deliveryItem.properties.active = true;
  deliveryItem.values.criterion.status = 'ACTIVE';

  const title = JSON.parse(action.params.notificationTitleMap);
  Object.keys(title).forEach(language => {
    if (language === 'TR') {
      deliveryItem.values.criterion.additionalTitles.TR = title[language];
    } else {
      deliveryItem.values.criterion.additionalTitles.EN = title[language];
    }
  });

  deliveryItem.values.criterion.assetItems = [];

  let dynamicItems =
    typeof action.params.dynamicItems !== 'undefined'
      ? JSON.parse(action.params.dynamicItems)
      : [];
  let staticItems =
    typeof action.params.staticItems !== 'undefined'
      ? JSON.parse(action.params.staticItems)
      : [];

  //dynamic items
  dynamicItems.map(assItem => {
    let dynamicObj = {
      id: Math.random(),
      type: assItem.type.toLowerCase(),
      values: {
        criterion: {
          value:
            assItem.recommendationSource === 'RECOMMENDATION_BEST_MATCH'
              ? 'BEST_MATCH'
              : assItem.recommendationSource,
        },
        itemCount: {
          value: assItem.itemCount,
        },
      },
    };
    if (assItem.recommendationSource !== 'RECOMMENDATION_BEST_MATCH') {
      dynamicObj.values.timeFrame = {};
      dynamicObj.values.timeFrame.value = assItem.timeFrame;
    }
    deliveryItem.values.criterion.assetItems.push(dynamicObj);
  });

  //static items
  staticItems.map(assItem => {
    let staticObj = {
      id: Math.random(),
      type: 'recommend',
      values: {
        criterion: {
          value: assItem.productId,
        },
        itemCount: {
          value: 1,
        },
      },
    };
    deliveryItem.values.criterion.assetItems.push(staticObj);
  });
  return deliveryItem;
};

const getCouponAction = (action, coupon) => {
  coupon.properties.active = true;
  coupon.values.criterion.status = 'ACTIVE';
  coupon.values.criterion.coupon = action.params.coupon;

  let title = JSON.parse(action.params.notificationTitleMap);
  Object.keys(title).forEach(language => {
    if (language === 'TR') {
      coupon.values.criterion.additionalTitles.TR = title[language];
    } else {
      coupon.values.criterion.additionalTitles.EN = title[language];
    }
  });
  coupon.values.criterion.desc = JSON.parse(action.params.description);
  return coupon;
};

const getLandingPageAction = (action, landingPage) => {
  landingPage.properties.active = true;
  landingPage.values.criterion.status = 'ACTIVE';
  landingPage.values.criterion.pageUrl = action.params.landingPageUrl;

  const title = JSON.parse(action.params.notificationTitleMap);
  Object.keys(title).forEach(language => {
    if (language === 'TR') {
      landingPage.values.criterion.additionalTitles.TR = title[language];
    } else {
      landingPage.values.criterion.additionalTitles.EN = title[language];
    }
  });
  return landingPage;
};

export const singleCampaign = campaign => {
  try {
    if (campaign) {
      let deliveryAssetList = [];
      campaign.actions.forEach(action => {
        if (action.type === 'buildJourney') {
          let assetList = [],
            discovery =
              typeof action.params.discovery !== 'undefined'
                ? JSON.parse(action.params.discovery)
                : [];

          initialJourneyBuilderDiscoveryAsset.map(item => {
            let cloneItem = clone(item);
            let dynamicObj = {
              id: Math.random(),
              properties: cloneItem.properties,
              values: cloneItem.values,
              advanceConfig: cloneItem.advanceConfig,
            };

            let asset =
              discovery.length > 0
                ? discovery.find(
                    asset => asset.type === cloneItem.properties.type,
                  )
                : [];

            if (asset) {
              dynamicObj.advanceConfig.html = asset.recommendationTemplate;
              dynamicObj.advanceConfig.prejs = asset.preJsCode;
              dynamicObj.advanceConfig.postjs = asset.postJsCode;
              dynamicObj.advanceConfig.css = asset.cssCode;

              dynamicObj.properties.active = true;
              dynamicObj.values.criterion.status = 'ACTIVE';

              Object.keys(asset.titleMap).forEach(language => {
                if (language === 'TR') {
                  dynamicObj.values.criterion.additionalTitles.TR =
                    asset.titleMap[language];
                } else {
                  dynamicObj.values.criterion.additionalTitles.EN =
                    asset.titleMap[language];
                }
              });

              if (
                asset.type === 'WELCOME' &&
                asset.buttonTextMap &&
                Object.keys(asset.buttonTextMap).length !== 0
              ) {
                dynamicObj.values.criterion.buttonText = asset.buttonTextMap;
              }

              if (asset.items && asset.items.length > 0) {
                dynamicObj.properties.isData = true;
                dynamicObj.values.criterion.assetItems = [];

                if (asset.type === 'MAIN_CATEGORY') {
                  let mainCategories = asset.items.map(item => item.source);
                  dynamicObj.values.criterion.assetItems = mainCategories;
                } else if (
                  asset.type !== 'PRICE_RANGE' &&
                  asset.type !== 'WELCOME'
                ) {
                  asset.items.map(assItem => {
                    let assetDynamicObj = {
                      id: Math.random(),
                      type:
                        assItem.type === 'STATIC'
                          ? asset.type.toLowerCase()
                          : 'intelligent',
                      values: {
                        criterion: {
                          value: assItem.source,
                        },
                        itemCount: {
                          value: assItem.itemCount,
                        },
                      },
                    };

                    dynamicObj.values.criterion.assetItems.push(
                      assetDynamicObj,
                    );
                  });
                }
              }
            }
            assetList.push(dynamicObj);
          });

          jbActions.wizardReset();
          jbActions.addCriteria(assetList);
          if (action.params.isCategoryTree) {
            jbActions.updateCategoryFlag(
              action.params.isCategoryTree === 'true',
            );
          }
        }
      });

      const defualtReccomend = clone(initialJourneyBuilderDeliveryAsset[0]);
      let deliveryItem = {
        id: Math.random(),
        properties: defualtReccomend.properties,
        values: defualtReccomend.values,
      };

      const defualtCoupon = clone(initialJourneyBuilderDeliveryAsset[1]);
      let coupon = {
        id: Math.random(),
        properties: defualtCoupon.properties,
        values: defualtCoupon.values,
      };

      const defualtLandingPage = clone(initialJourneyBuilderDeliveryAsset[2]);
      let landingPage = {
        id: Math.random(),
        properties: defualtLandingPage.properties,
        values: defualtLandingPage.values,
      };

      // if we have action that we should update default value with related action type
      // i start from 2 for delivery side
      for (let i = 2; i < campaign.actions.length; i++) {
        if (campaign.actions[i]) {
          if (campaign.actions[i].type === 'recommendProducts') {
            deliveryItem = getRecommendAction(
              campaign.actions[i],
              deliveryItem,
            );
          } else if (campaign.actions[i].type === 'giveCoupon') {
            coupon = getCouponAction(campaign.actions[i], coupon);
          } else if (campaign.actions[i].type === 'landingPage') {
            landingPage = getLandingPageAction(
              campaign.actions[i],
              landingPage,
            );
          }
        }
      }
      deliveryAssetList.push(deliveryItem);
      deliveryAssetList.push(coupon);
      deliveryAssetList.push(landingPage);

      jbActions.wizardDeliveryReset();
      jbActions.addDeliveryCriteria(deliveryAssetList);
    }
  } catch (err) {}
};
