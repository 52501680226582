/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import AllEmails from './emails.viewall';
import { uiActions } from '../actions';
import EmailsList from '../components/email/emails-list';
import EmailTypeSelection from '../components/email/email-type-selection';
import EmailStatusFilters from '../components/filters/email-status-filters';
import EmailTemplates from './email/EmailTemplates';
import Icon from '../components/icon';
import Icons from '../components/icons';
import {
  baseCampaignNames as campaignNames,
  csSearchParams,
  emailSortingTypes as sortingTypes,
  itemsPerScroll,
  mailGroups,
} from '../components/reports/utils';
import LoaderPlaceholder from '../components/widget/loader-placeholder';
import {
  hasModule,
  hasEmailConfiguration,
  mailProviderIsSegmentify,
  getApiKey,
} from '../modules/auth/user';
import { getEmails, getRangedEmailReports } from '../modules/emails/ajax';
import { emailIntegrationInfo } from '../modules/trendify/page';
import { config } from '../constants/config';
import { setTitle } from '../system/document';
import { t } from '../system/ui';
import EmailCampaignPages from './email/email-campaign-pages/EmailCampaignPages';
import EmailCollection from './email/email-collection/EmailCollection';
import { getCampaignsByType } from '../modules/campaign/events';
import { getReports } from '../modules/reports/ajax';
import { getStartEndDate } from '../system/date';

const ConfigurationError = () => {
  return (
    <div className='configuration-error'>
      <div className='configuration-error-icon'>
        <Icons name='warning' color='#000000' width='32' height='32' />
      </div>
      <div className='configuration-error-text'>
        <h3>Please complete ESP configuration</h3>
        <p>
          You have to configure your ESP settings to use the Email Marketing
          module.
        </p>
        <Link to='/settings/email' className='config-cta'>
          Go to Settings
        </Link>
      </div>
    </div>
  );
};

const campaignDescriptions = {
  retention:
    'Increase customer loyalty by reaching your customers through action-based, personalised retention campaigns',
  growth:
    'Keep in touch with your customers with personalised content on a regular basis to increase your online sales',
  winback:
    'Remind your customers about what they’re missing with automated winback campaigns and keep in touch with churn or potential churn segments',
  recommendation:
    'Reach your users and promote your campaigns or make announcement about your all news.',
  fly:
    'Engage with your customers while they’re still on your website and seize the opportunities with different scenarios.',
};

class Emails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      emails: [],
      _emails: [],
      liveEmails: [],
      reports: {},
      title: t('All Campaigns'),
      status: 'ACTIVE',
      searchTerm: '',
      mailType: 'view-all',
      sortValue: '',
      sortIcon: 'preferences',
      showSortOptions: false,
      scrollCount: 0,
      showOptions: false,
      sort: 'DESC',
      sortType: 'date',
      toggleIcon: 'preferences',
      campaignsInited: false,
      sentClosed: true,
      showConfigWarning: false,
    };

    this.getEmails = this.getEmails.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.setMailType = this.setMailType.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.increaseScrollCount = this.increaseScrollCount.bind(this);
    this.onActiveCount = this.onActiveCount.bind(this);
  }

  componentDidMount() {
    uiActions.hideBreadcrumb();

    if (document.location.pathname === '/email') {
      this.props.router.push({
        pathname: '/email/view-all',
      });
    } else {
      this.setMailType(
        document.location.pathname.split('/')[
          document.location.pathname.split('/').length - 1
        ],
      );
      this.props.router.push({
        pathname: `/email/${
          document.location.pathname.split('/')[
            document.location.pathname.split('/').length - 1
          ]
        }`,
      });
    }
    this.getEmails();
    window.onscroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        this.increaseScrollCount();
      }
    };
  }

  increaseScrollCount() {
    if (this.state.mailType === 'view-all') {
      this.setState(
        {
          scrollCount: this.state.scrollCount + 1,
        },
        () => {
          this.filterEmails('scroll', false, false);
        },
      );
    }
  }

  getEmails() {
    emailIntegrationInfo();
    if (hasModule('email') && hasEmailConfiguration()) {
      uiActions.isLoading();
      let allEmails = [];
      getEmails(response => {
        if (response) {
          for (let i = 0; i < response.length; i += 1) {
            if (response[i] && response[i].scenarioName) {
              const obj = {
                ...response[i],
                scenarioName: decodeURIComponent(response[i].scenarioName),
              };
              allEmails.push(obj);
            }
          }

          getCampaignsByType({
            apiKey: getApiKey(),
            campaignType: 'NEWSLETTER',
          }).then(emResponse => {
            const emailCollectionCampaigns =
              typeof emResponse.response === 'object'
                ? emResponse.response
                : [];
            const timeRange = getStartEndDate('lastSevenDays');
            const emailCollections = [];
            getReports(
              emailCollectionCampaigns,
              timeRange,
              campaignsWithReport => {
                const emailCollectionCampaignsWithReports =
                  typeof campaignsWithReport === 'object'
                    ? campaignsWithReport
                    : [];
                emailCollectionCampaignsWithReports.forEach(item => {
                  item.campaign.logs = item.logs;
                  emailCollections.push({
                    ...item.campaign,
                    scenarioName: decodeURIComponent(item.campaign.name),
                    status:
                      item.campaign.status === 'ARCHIVE'
                        ? 'PASSIVE'
                        : item.campaign.status,
                  });
                });

                allEmails = allEmails.concat(emailCollections);
                this.setState(
                  {
                    _emails: allEmails,
                    isLoading: false,
                  },
                  () => {
                    this.filterEmails('statusOrMediumChange');
                    uiActions.isLoaded();
                  },
                );
              },
            );
          });
        }
      });
    } else {
      this.setState({
        showConfigWarning: true,
        isLoading: false,
        campaignsInited: true,
      });
    }
  }

  filterEmails(source, eventParam, e) {
    const allEmails = this.state._emails;
    const { status } = this.state;
    const { searchTerm } = this.state;
    const { mailType } = this.state;
    const isViewAll =
      !this.state.mailType || this.state.mailType === 'view-all';
    // Status filter
    let filteredEmails = allEmails.filter(email => {
      return mailType !== 'view-all' ? true : email.status === status;
    });

    // Type Filter
    if (mailType !== 'view-all') {
      filteredEmails = filteredEmails.filter(email => {
        return mailGroups[mailType]?.indexOf(email?.type) > -1;
      });
    }
    if (this.state.mailType !== 'view-all') {
      this.setState({
        emails: filteredEmails,
        title: isViewAll ? t('All Campaigns') : t(campaignNames[mailType]),
      });
    } else {
      // Sorting
      if (e) {
        e.preventDefault();
      }
      let sortType;
      let sortOrder;
      let scrollCount;
      let deviceStatusFilteredCampaigns;
      let campaigns = this.state.emails;
      const reverseCampaigns = false;
      const matchedCampaigns = [];
      const search = this.refs.search && this.refs.search.value;

      if (search) {
        filteredEmails.map(item => {
          if (
            item.scenarioName.toLowerCase().indexOf(search.toLowerCase()) >
              -1 ||
            item.instanceId.toLowerCase().indexOf(search.toLowerCase()) > -1
          ) {
            matchedCampaigns.push(item);
          }
        });
        deviceStatusFilteredCampaigns = matchedCampaigns;
      } else {
        deviceStatusFilteredCampaigns = filteredEmails;
      }

      if (
        source === 'statusOrMediumChange' ||
        source === 'search' ||
        source === 'scroll'
      ) {
        sortType = this.state.sortType;
        sortOrder = this.state.sort;
        scrollCount = source === 'scroll' ? this.state.scrollCount : 0;
      } else {
        // source is checkbox selected
        sortType = eventParam;
        scrollCount = 0;
        if (this.state.sortType !== sortType) {
          sortOrder = 'DESC';
        } else {
          sortOrder = this.state.sort === 'ASC' ? 'DESC' : 'ASC';
        }
      }

      if (source !== 'scroll') {
        campaigns = [];
      }

      const reportIds = deviceStatusFilteredCampaigns.map(cmp => {
        return cmp.instanceId;
      });

      if (deviceStatusFilteredCampaigns.length) {
        if (csSearchParams.indexOf(sortType) < 0) {
          // Server side sorting
          if (
            itemsPerScroll * (scrollCount + 1) - itemsPerScroll <
            deviceStatusFilteredCampaigns.length
          ) {
            uiActions.isLoading();
            getRangedEmailReports(
              reportIds,
              'weekly',
              campaignReports => {
                uiActions.isLoaded();
                const newCampaigns = Object.keys(campaignReports).map(
                  reportCampaignId => {
                    const targetCampaign = deviceStatusFilteredCampaigns.filter(
                      cmp => {
                        return cmp.instanceId === reportCampaignId;
                      },
                    );
                    return targetCampaign[0];
                  },
                );
                this.setState({
                  reports: {
                    ...this.state.reports,
                    ...campaignReports,
                  },
                  emails: campaigns.concat(newCampaigns),
                  count: deviceStatusFilteredCampaigns.length,
                  sort: sortOrder,
                  sortType,
                  scrollCount,
                  showOptions: false,
                  toggleIcon: 'preferences',
                  title: isViewAll
                    ? t('All Campaigns')
                    : t(campaignNames[mailType]),
                  campaignsInited: true,
                });
              },
              {
                items: itemsPerScroll,
                page: scrollCount + 1,
                sortBy: sortType,
                reverse: sortOrder === 'DESC',
              },
            );
          }
        } else {
          // Client Side Sorting
          const campaignsByDate = [...deviceStatusFilteredCampaigns].sort(
            (a, b) => {
              if (sortOrder === 'DESC') {
                return b.startDate - a.startDate;
              }
              return a.startDate - b.startDate;
            },
          );
          const campaignIdsToGetReport = [];
          const latestExistingCampaignIndex = campaigns.length;
          const newCampaigns = campaignsByDate.slice(
            latestExistingCampaignIndex,
            latestExistingCampaignIndex + itemsPerScroll,
          );
          const campaignsToShow = campaigns.concat(newCampaigns);

          campaignsToShow.forEach(campaign => {
            if (!this.state.reports[campaign.instanceId]) {
              campaignIdsToGetReport.push(campaign.instanceId);
            }
          });

          this.setState(
            {
              emails: campaignsToShow,
              count: campaignsToShow.length,
              sort: sortOrder,
              sortType,
              scrollCount,
              showOptions: false,
              toggleIcon: 'preferences',
              title: isViewAll
                ? t('All Campaigns')
                : t(campaignNames[mailType]),
            },
            () => {
              if (campaignIdsToGetReport.length) {
                uiActions.isLoading();
                getRangedEmailReports(
                  campaignIdsToGetReport,
                  'weekly',
                  campaignReports => {
                    uiActions.isLoaded();
                    this.setState({
                      reports: {
                        ...this.state.reports,
                        ...campaignReports,
                      },
                      campaignsInited: true,
                    });
                  },
                  {},
                );
              }
            },
          );
        }
      } else {
        uiActions.isLoaded();
        this.setState({
          reports: {},
          emails: [],
          count: deviceStatusFilteredCampaigns.length,
          sort: sortOrder,
          sortType,
          scrollCount,
          showOptions: false,
          toggleIcon: 'preferences',
          title: isViewAll ? t('All Campaigns') : t(campaignNames[mailType]),
          campaignsInited: true,
        });
      }
    }
  }

  componentWillReceiveProps(newProps) {
    let children;
    if (newProps.params.email === 'fly') {
      children = config.menus.primary.email.children.flymode;
    } else {
      children = config.menus.primary.email.children[newProps.params.email];
    }
    setTitle(`${children.name} - ${t('Email Marketing Campaigns')}`);
    if (this.props.params.email !== newProps.params.email) {
      this.setMailType(newProps.params.email);
    }

    if (this.props.user !== newProps.user) {
      this.getEmails();
    }
  }

  onChangeStatus(instanceId, status, newStatus) {
    const newEmails = [];
    this.state._emails.forEach(email => {
      if (email.instanceId === instanceId) {
        email.status = newStatus;
      }
      newEmails.push(email);
    });
    this.setState(
      {
        _emails: newEmails,
        status: newStatus,
      },
      () => {
        this.filterEmails('statusOrMediumChange');
      },
    );
  }

  setMailType(newType) {
    this.setState(
      {
        mailType: newType,
        campaignsInited: true,
      },
      () => {
        if (this.state.campaignsInited) {
          this.filterEmails('statusOrMediumChange');
        }
      },
    );
  }

  onStatusChange(status) {
    this.setState(
      {
        status,
      },
      () => {
        this.filterEmails('statusOrMediumChange');
      },
    );
  }

  onSearch(e) {
    e.preventDefault();
    this.filterEmails('search');
  }

  toggleOptions(e) {
    e.preventDefault();

    this.setState({
      showOptions: !this.state.showOptions,
      toggleIcon: !this.state.showOptions ? 'cross' : 'preferences',
    });
  }

  onActiveCount(count) {
    this.setState({
      sentClosed: count > 0,
    });
  }

  render() {
    if (!hasModule('email')) {
      return (
        <div>
          <div className='promo promo--centered email-discover-dashboard'>
            <Icons name='emailModule' width='64' height='64' />
            <p className='promo-text'>{t('MailPromo')}</p>
            <a
              href='https://segmentify.com/solution/cross-channel-marketing/'
              target='_blank'
              className='secondary-action'
              rel='noreferrer'
            >
              {t('Discover')}
            </a>
          </div>
        </div>
      );
    }
    if (
      mailProviderIsSegmentify('SEGMENTIFY') &&
      this.state.mailType !== 'view-all' &&
      this.props.location.pathname !== '/email/templates' &&
      this.props.location.pathname !== '/email/email-collection'
    ) {
      return <EmailCampaignPages location={this.props.location} />;
    }
    if (this.props.location.pathname === '/email/email-collection') {
      return (
        <EmailCollection
          campaigns={this.state._emails}
          onUpdate={this.onChangeStatus}
          location={this.props.location}
        />
      );
    }
    if (this.props.location.pathname === '/email/templates') {
      return <EmailTemplates />;
    }
    if (this.state.isLoading) {
      // show placeholder
      return (
        <div>
          <div className='page-filters has-filter-form'>
            <EmailStatusFilters
              status={this.state.status}
              onStatusChange={this.onStatusChange}
            />
          </div>
          <div style={{ marginTop: '80px' }}>
            <LoaderPlaceholder />
          </div>
        </div>
      );
    }
    // show view all
    if (this.state.mailType !== 'view-all') {
      return (
        <div>
          {this.state.showConfigWarning && <ConfigurationError />}
          <h2 className='page-title'>{t(this.state.title)}</h2>
          <div
            className={
              this.state.showConfigWarning
                ? 'page-content-wrapper prevent-add'
                : 'page-content-wrapper'
            }
          >
            {this.state.emails.filter(cmp => {
              return cmp.status !== 'PASSIVE';
            }).length > 0 ? (
              this.state.mailType === 'recommendation' ? (
                <div className='widget-list-wrapper'>
                  <EmailsList
                    type='ACTIVE'
                    title='Scheduled / Recurring Push Campaigns'
                    emails={this.state.emails}
                    scheduleFilter='scheduled'
                    onCountChange={this.onActiveCount}
                    onChangeStatus={this.onChangeStatus}
                  />
                  <EmailsList
                    type='ACTIVE'
                    title={t('Sent Campaigns')}
                    emails={this.state.emails}
                    closed={this.state.sentClosed}
                    scheduleFilter='sent'
                    noStatusFilter
                    onChangeStatus={this.onChangeStatus}
                  />
                </div>
              ) : (
                <div className='widget-list-wrapper'>
                  <EmailsList
                    type='ACTIVE'
                    title={t('Live Campaigns')}
                    emails={this.state.emails}
                    onChangeStatus={this.onChangeStatus}
                  />

                  <EmailsList
                    type='TEST'
                    title={t('Draft Campaigns')}
                    emails={this.state.emails}
                    closed={
                      this.state.emails.filter(email => {
                        return email.status === 'ACTIVE';
                      }).length > 0
                    }
                    onChangeStatus={this.onChangeStatus}
                  />
                </div>
              )
            ) : (
              <div className='widget-list-wrapper'>
                <div className='widget-list-no-items'>
                  There is no Active {this.state.title}
                </div>
              </div>
            )}
            <EmailTypeSelection
              mailType={this.state.mailType}
              mailGroups={mailGroups}
              campaignNames={campaignNames}
              liveEmails={this.state._emails.map(campaign => {
                if (campaign.status === 'ACTIVE') {
                  return campaign.instanceId;
                }
                return null;
              })}
              allEmails={this.state._emails.map(campaign => {
                return campaign.instanceId;
              })}
              title={this.state.title}
              description={campaignDescriptions[this.state.mailType]}
            />
          </div>
        </div>
      );
    }
    let comp;
    if (this.state.campaignsInited) {
      if (this.state.emails.length) {
        comp = (
          <AllEmails
            emails={this.state.emails}
            reports={this.state.reports}
            onChangeStatus={this.onChangeStatus.bind(this)}
          />
        );
      } else if (this.state.showConfigWarning) {
        comp = <ConfigurationError />;
      } else {
        comp = (
          <div className='no-report'>
            <p className='no-report-info'>{t('no-campaign-info', '')}</p>
          </div>
        );
      }
    } else {
      comp = (
        <div style={{ marginTop: '80px' }}>
          <LoaderPlaceholder />
        </div>
      );
    }
    return (
      <div>
        <h2 className='page-title'>
          {this.state.title}
          <span className='emphasise'> ({this.state.count})</span>
        </h2>

        <div className='page-options page-options-sort-search'>
          <label className='item is-stacked is-search'>
            <Icon name='magnify' />
            <input
              type='text'
              placeholder={t('Search')}
              className='text-field'
              onChange={this.onSearch}
              ref='search'
            />
          </label>

          <div className='data-preferences'>
            <a href='#' className='toggler' onClick={this.toggleOptions}>
              <Icon name={this.state.toggleIcon} />
              <span>Sort</span>
            </a>
            <ul
              className={
                !this.state.showOptions
                  ? 'data-preferences-options is-hidden'
                  : 'data-preferences-options'
              }
            >
              {sortingTypes.map(sortingType => {
                return (
                  <li
                    className='data-preferences-option'
                    key={sortingType.type}
                    onClick={this.filterEmails.bind(
                      this,
                      'event',
                      sortingType.type,
                    )}
                  >
                    <a href='#' className='data-preferences-option-item'>
                      {sortingType.text}
                      {this.state.sortType === sortingType.type ? (
                        this.state.sort !== 'DESC' ? (
                          <i className='icon-caret-up viewall-sorting' />
                        ) : (
                          <i className='icon-caret-down viewall-sorting' />
                        )
                      ) : (
                        ''
                      )}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className='page-filters has-filter-form'>
          <EmailStatusFilters
            status={this.state.status}
            onStatusChange={this.onStatusChange}
          />
        </div>
        {comp}
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  user: store.user.user,
  status: store.campaigns.status,
});

export default connect(MapStatesToProps)(Emails);
