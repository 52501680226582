/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { isSuperUser } from '../../../modules/auth/user';
import { t } from '../../../system/ui';
import { modalActions, uiActions } from '../../../actions';
import { moveCampaignToArchive } from '../../../modules/rbs/ajax';
import { getCampaigns } from '../../../reducers/rbs/extraReducers';

import Icon from '../../../components/icon';
import Icons from '../../../components/icons';
import CampaignHistoryModal from '../../../modules/campaigns/historymodal';
import SegmentDetailPreview from './SegmentDetailPreview';
import { getExternalPersonalisedAds } from '../../../constants/config';

const actions = [
  {
    name: 'Segment Details',
    icon: 'view',
    action: 'editPreview',
  },
  {
    name: 'History',
    icon: 'history',
    iconSvg: true,
    action: 'showCampaignHistory',
  },
  { name: 'Archive', icon: 'archive', action: 'addToArchive' },
  { name: 'Duplicate', icon: 'duplicate', action: 'duplicate' },
  {
    name: 'Send Bulk Push',
    iconSvg: true,
    icon: 'personalizedPush',
    action: 'sendBulkPush',
  },
  {
    name: 'Create Engagement',
    iconSvg: true,
    icon: 'behaviouralTargeting',
    action: 'createEngagementCampaign',
  },
  {
    name: 'Use Personalised Ads',
    iconSvg: true,
    icon: 'personalisedAds',
    action: 'usePersonalisedAds',
  },
  {
    name: 'Send Bulk Email',
    iconSvg: true,
    icon: 'emailModule',
    action: 'sendBulkEmail',
  },
];

const RbsActions = ({ campaign }) => {
  const dispatch = useDispatch();
  const features = useSelector(
    state => state.switchedUser.switchedUser.account.features,
  );
  const mailProvider = useSelector(
    state =>
      state.switchedUser?.switchedUser?.account?.mailConfiguration?.provider,
  );
  const [dropUp, setDropUp] = useState(false);
  const actionMenu = useRef(null);

  const getListClass = () => {
    if (dropUp) {
      return 'widget-nav-popup action-nav-popup drop-up';
    }
    return 'widget-nav-popup action-nav-popup';
  };

  const getControlledActions = () => {
    if (dropUp) {
      return actions.slice().reverse();
    }
    return actions;
  };

  const handleScroll = () => {
    const partOfDocHeight = (document.documentElement.offsetHeight * 70) / 100;
    const listTop = actionMenu.current.getBoundingClientRect().top;
    setDropUp(listTop > partOfDocHeight);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const editPreview = () => {
    function content() {
      return <SegmentDetailPreview campaignDetails={campaign} />;
    }

    modalActions.openModal({
      title: 'Segment Details',
      content,
      className: 'rbs-wizard-save-preview-modal detail-preview',
    });
  };

  const showCampaignHistory = () => {
    const campaignId = campaign.instanceId;
    const campaignName = campaign.segmentName;

    const campaignHistoryModal = () => (
      <CampaignHistoryModal campaignId={campaignId} isRbs />
    );

    modalActions.openModal({
      title: `Campaign History (${campaignName})`,
      content: campaignHistoryModal,
    });
  };

  const addToArchive = () => {
    uiActions.confirmationDialog({
      title: 'Archive the campaign?',
      content: 'When you make this change the campaign will be removed.',
      onConfirm: () => {
        moveCampaignToArchive(campaign.instanceId).then(() => {
          const note = () => {
            return <div>Segmentation is successfully Archived.</div>;
          };
          uiActions.showNotification({
            content: note,
          });
          dispatch(getCampaigns());
        });
      },
    });
  };

  const duplicate = () => {
    const path = `audience/rule-based-segmentation/duplicate/${campaign.instanceId}`;

    browserHistory.push({
      pathname: path,
    });
  };

  const sendBulkPush = () => {
    window.location.state = {
      smartSegmentId: campaign.segmentId,
    };
    return browserHistory.push('/push/add/PUSH_NOTIFICATION');
  };

  const createEngagementCampaign = () => {
    return browserHistory.push('/behavioural-targeting/engagement/view-all');
  };

  const usePersonalisedAds = () => {
    const location = getExternalPersonalisedAds();
    window.open(location, '_blank');
  };

  const sendBulkEmail = () => {
    return browserHistory.push('/email/recommendation');
  };

  const handleClick = action => {
    switch (action) {
      case 'editPreview':
        editPreview();
        break;
      case 'showCampaignHistory':
        showCampaignHistory();
        break;
      case 'addToArchive':
        addToArchive();
        break;
      case 'duplicate':
        duplicate();
        break;
      case 'sendBulkPush':
        sendBulkPush();
        break;
      case 'createEngagementCampaign':
        createEngagementCampaign();
        break;
      case 'usePersonalisedAds':
        usePersonalisedAds();
        break;
      case 'sendBulkEmail':
        sendBulkEmail();
        break;
      default:
        break;
    }
  };

  return (
    <nav
      className='widget-nav action-nav'
      aria-haspopup='true'
      ref={actionMenu}
    >
      <a className='secondary-action'>{t('Actions')}</a>

      <ul className={getListClass()} style={{ left: '-114px' }}>
        {getControlledActions().map((action, index) => {
          if (!isSuperUser() && action.superUserOnly) {
            return null;
          }

          if (
            action.action === 'addToArchive' &&
            campaign.status === 'PASSIVE'
          ) {
            return null;
          }

          if (
            mailProvider !== 'SEGMENTIFY_V2' &&
            action.action === 'sendBulkEmail'
          )
            return null;
          if (
            features?.personalization === 'ACTIVE' &&
            action.action === 'createEngagementCampaign'
          )
            return null;
          if (
            features?.personalisation.status === 'ACTIVE' &&
            action.action === 'usePersonalisedAds'
          )
            return null;
          if (features?.push === 'ACTIVE' && action.action === 'sendBulkPush')
            return null;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${campaign.segmentId}-${index}`}>
              <a
                className='widget-nav-popup-item action-nav-popup-item'
                onClick={() => handleClick(action.action)}
              >
                {action.iconSvg ? (
                  <Icons name={action.icon} color='#fff' />
                ) : (
                  <Icon name={action.icon} />
                )}
                {t(action.name)}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

RbsActions.propTypes = {
  campaign: PropTypes.shape({}).isRequired,
};

export default RbsActions;
