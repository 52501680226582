import React from "react";

class InsightProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2714 516">
        <title>full-no-title</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g id="Layer_1-2-2" data-name="Layer 1-2">
              <path
                d="M4,0H193l3,4h1V273l-4,3v1H4l-3-4H0V4H1Z"
              />
              <path
                d="M244,0H488l3,4h1V31l-4,3v1H244l-3-4h-1V4h1Z"
              />
              <path
                d="M727,0H916l3,4h1V273l-4,3v1H727l-3-4h-1V4h1Z"
              />
              <path
                d="M967,0h244l3,4h1V31l-4,3v1H967l-3-4h-1V4h1Z"
              />
              <path
                d="M1450,0h189l3,4h1V273l-4,3v1H1450l-3-4h-1V4h1Z"
              />
              <path
                d="M1690,0h244l3,4h1V31l-4,3v1H1690l-3-4h-1V4h1Z"
              />
              <path
                d="M2166,0h189l3,4h1V273l-4,3v1H2166l-3-4h-1V4h1Z"
              />
              <path
                d="M2406,0h244l3,4h1V31l-4,3v1H2406l-3-4h-1V4h1Z"
              />
              <path
                d="M244,61H548l3,4h1V92l-4,3v1H244l-3-4h-1V65h1Z"
              />
              <path
                d="M967,61h304l3,4h1V92l-4,3v1H967l-3-4h-1V65h1Z"
              />
              <path
                d="M1690,61h304l3,4h1V92l-4,3v1H1690l-3-4h-1V65h1Z"
              />
              <path
                d="M2406,61h304l3,4h1V92l-4,3v1H2406l-3-4h-1V65h1Z"
              />
              <path
                d="M244,121H392l3,4h1v27l-4,3v1H244l-3-4h-1V125h1Z"
              />
              <path
                d="M967,121h148l3,4h1v27l-4,3v1H967l-3-4h-1V125h1Z"
              />
              <path
                d="M1690,121h148l3,4h1v27l-4,3v1H1690l-3-4h-1V125h1Z"
              />
              <path
                d="M2406,121h148l3,4h1v27l-4,3v1H2406l-3-4h-1V125h1Z"
              />
              <path
                d="M244,182H392l3,4h1v27l-4,3v1H244l-3-4h-1V186h1Z"
              />
              <path
                d="M967,182h148l3,4h1v27l-4,3v1H967l-3-4h-1V186h1Z"
              />
              <path
                d="M1690,182h148l3,4h1v27l-4,3v1H1690l-3-4h-1V186h1Z"
              />
              <path
                d="M2406,182h148l3,4h1v27l-4,3v1H2406l-3-4h-1V186h1Z"
              />
              <path
                d="M244,242H392l3,4h1v27l-4,3v1H244l-3-4h-1V246h1Z"
              />
              <path
                d="M967,242h148l3,4h1v27l-4,3v1H967l-3-4h-1V246h1Z"
              />
              <path
                d="M1690,242h148l3,4h1v27l-4,3v1H1690l-3-4h-1V246h1Z"
              />
              <path
                d="M2406,242h148l3,4h1v27l-4,3v1H2406l-3-4h-1V246h1Z"
              />
              <path
                d="M104,433H252l3,4h1v27l-4,3v1H104l-3-4h-1V437h1Z"
              />
              <path
                d="M388,433H536l3,4h1v27l-4,3v1H388l-3-4h-1V437h1Z"
              />
              <path
                d="M827,433H975l3,4h1v27l-4,3v1H827l-3-4h-1V437h1Z"
              />
              <path
                d="M1111,433h148l3,4h1v27l-4,3v1H1111l-3-4h-1V437h1Z"
              />
              <path
                d="M1550,433h148l3,4h1v27l-4,3v1H1550l-3-4h-1V437h1Z"
              />
              <path
                d="M1834,433h148l3,4h1v27l-4,3v1H1834l-3-4h-1V437h1Z"
              />
              <path
                d="M2266,433h148l3,4h1v27l-4,3v1H2266l-3-4h-1V437h1Z"
              />
              <path
                d="M2550,433h148l3,4h1v27l-4,3v1H2550l-3-4h-1V437h1Z"
              />
              <path
                d="M146,481h64l3,4h1v27l-4,3v1H146l-3-4h-1V485h1Z"
              />
              <path
                d="M430,481h64l3,4h1v27l-4,3v1H430l-3-4h-1V485h1Z"
              />
              <path
                d="M869,481h64l3,4h1v27l-4,3v1H869l-3-4h-1V485h1Z"
              />
              <path
                d="M1153,481h64l3,4h1v27l-4,3v1h-64l-3-4h-1V485h1Z"
              />
              <path
                d="M1592,481h64l3,4h1v27l-4,3v1h-64l-3-4h-1V485h1Z"
              />
              <path
                d="M1876,481h64l3,4h1v27l-4,3v1h-64l-3-4h-1V485h1Z"
              />
              <path
                d="M2308,481h64l3,4h1v27l-4,3v1h-64l-3-4h-1V485h1Z"
              />
              <path
                d="M2592,481h64l3,4h1v27l-4,3v1h-64l-3-4h-1V485h1Z"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default InsightProduct;
