export const GuidanceActions = {
  USER_IDENTIFY: 'USER_IDENTIFY',
  PAGE_VIEW: 'PAGE_VIEW',
};

export const Privileges = {
  ROLE_ACD: 'ROLE_ACD',
  ROLE_OBD: 'ROLE_OBD',
  ROLE_ACM: 'ROLE_ACM',
  ROLE_OBM: 'ROLE_OBM',
  ROLE_SM: 'ROLE_SM',
  MASTERUSER: 'MASTERUSER',
  SUPERUSER: 'SUPERUSER',
  ROLE_AGU: 'ROLE_AGU',
};
