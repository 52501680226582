/**
 * @author Bilal Cinarli
 */

import { browserHistory } from 'react-router';
import { switchUserActions } from '../../actions';
import { getApiPaths } from '../../constants/config';
import { ExternalAppUtility } from '../../external';
import {
  getExternalAccount,
  getExternalCallback,
  getExternalErrorCallback,
  getSource,
  setExtToken,
  setExternalAccount,
  setExternalCallback,
  setExternalErrorCallback,
  setProvider,
  setSource,
  setSubPath,
} from '../../index';
import cookie from '../../system/cookie';
import { removeLocal } from '../../system/object';
import { getQueryString } from '../../system/string';
import user from './user';

export const loginAuth = (currentState, replace) => {
  const querySource = getQueryString('source');
  if (querySource != null && querySource.length > 0) setSource(querySource);

  const accountSource = getQueryString('external_switch_account');
  if (accountSource !== null && accountSource.length > 0)
    setExternalAccount(accountSource);

  const externalCallback = getQueryString('external_load_callback');
  if (externalCallback !== null && externalCallback.length > 0)
    setExternalCallback(externalCallback);

  const externalErrorCallback = getQueryString('external_error_callback');
  if (externalErrorCallback != null && externalErrorCallback.length > 0)
    setExternalErrorCallback(externalErrorCallback);

  const provider = getQueryString('provider');
  if (provider != null && provider.length > 0) setProvider(provider);

  const subPath = getQueryString('subPath');
  if (subPath != null && subPath.length > 0) setSubPath(subPath);

  const extToken = getQueryString('sgfTkn');
  if (extToken != null && extToken.length > 0) setExtToken(extToken);

  const cookieToken = cookie.select();
  cookie.set(cookieToken, '', -1, '/', 'panelv2.segmentify.com');
  const isAuthenticated = user.isLogged(currentState);
  if (!isAuthenticated) {
    if (
      process.env.REACT_APP_ENV === 'development' ||
      process.env.REACT_APP_ENV === 'search'
    ) {
      cookie.delDc();
      replace({
        pathname: '/login',
        state: {
          nextPathname: currentState.location.pathname,
        },
      });
    } else {
      redirectAuth(2, currentState.location.pathname);
    }
  } else {
    user.getUser(user => {
      if (
        user &&
        user.privileges &&
        (user.privileges.indexOf('MASTERUSER') > -1 ||
          user.privileges.indexOf('SUPERUSER') > -1 ||
          user.privileges.indexOf('ROLE_ACM') > -1 ||
          user.privileges.indexOf('ROLE_OBM') > -1 ||
          user.privileges.indexOf('ROLE_SM') > -1)
      ) {
        // TODO switchedUser hafizada  tutan sistem
        // browserHistory.push(currentState.location.pathname || '/admin/stats');
        browserHistory.push('/admin/stats');
      } else if (
        user.privileges.indexOf('ROLE_ACD') > -1 ||
        user.privileges.indexOf('ROLE_OBD') > -1
      ) {
        browserHistory.push('/admin/accounts');
      } else if (
        user &&
        user.privileges &&
        user.privileges.indexOf('ROLE_AGU') > -1
      ) {
        browserHistory.push('/managed-services');
      } else if (
        user &&
        user.privileges &&
        user.privileges.indexOf('MULTI_ACC_USER') !== -1 &&
        getSource() === null
      ) {
        browserHistory.push('/user/accounts');
      } else if (
        user &&
        user.privileges &&
        user.privileges.indexOf('MULTI_ACC_USER') !== -1 &&
        getSource() !== null &&
        getSource() === 'external_app'
      ) {
        switchUserActions.updateSwitchUser(user);
        browserHistory.push('/trendify/insights');
      }
    });
  }
};

export const stringAuth = (currentState, replace) => {
  const authToken = getQueryString('auth');
  const externalNav = getQueryString('nav');
  const externalApp = getQueryString('app');
  const externalMenu =
    getQueryString('menu') || 'dashboard,campaigns,trendify,guide';

  if (authToken !== null && authToken.length > 0) {
    removeLocal('account');

    if (
      process.env.REACT_APP_ENV === 'development' ||
      process.env.REACT_APP_ENV === 'search'
    ) {
      cookie.set('SegmentifyAuthenticationToken', authToken, 30, '/');
    } else {
      cookie.set(cookie.select(), authToken, 30, '/', '.segmentify.com');
    }
  }

  if (externalNav !== null) {
    localStorage.setItem('externalNav', externalNav);
  }

  if (externalApp !== null) {
    localStorage.setItem('externalApp', externalApp);
  }

  if (externalMenu !== null) {
    localStorage.setItem('externalMenu', externalMenu);
  }
};

export const redirectAuth = (mode, redirect) => {
  // This redirect is directed according to the url sent from the external source (BigCommerce etc.).
  cookie.delDc();
  let redirectLocation;
  if (getSource() !== null && getSource() === 'external_app') {
    if (ExternalAppUtility.isDengage()) {
      redirectLocation = getExternalErrorCallback();
      window.location = `${redirectLocation}`;
      return;
    }
    if (getExternalCallback() !== null && getExternalCallback().length > 0) {
      redirectLocation = getExternalCallback();
      window.location = `${redirectLocation}&source=${getSource()}&external_switch_account=${getExternalAccount()}`;
      return;
    }
  }

  redirectLocation =
    getQueryString('loginUri') ||
    getApiPaths().login +
      (mode !== undefined ? `?mode=${mode}` : '') +
      (redirect !== undefined ? `&redirect=${redirect}` : '');
  window.location = redirectLocation;
};

export default {
  loginAuth,
  stringAuth,
  redirectAuth,
};
