export const GlobalConfigurationReducers = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'GLOBAL_BOOST':
      return {
        ...state,
        globalBoost: {
          ...state.globalBoost,
          boostFields: payload,
        },
      };
    case 'GLOBAL_BOOST_SEARCH_IS_ACTIVE':
      return {
        ...state,
        globalBoost: {
          ...state.globalBoost,
          activateOnSearchBox: payload,
        },
      };
    case 'PRODUCT_PIN':
      return {
        ...state,
        productPin: payload,
      };
    case 'PP_SEARCH_IS_ACTIVE':
      return {
        ...state,
        productPin: {
          ...state.productPin,
          activateOnSearchBox: payload,
        },
      };
    case 'PRODUCT_EXCLUSION':
      return {
        ...state,
        globalExclusion: payload,
      };
    case 'PE_SEARCH_IS_ACTIVE':
      return {
        ...state,
        globalExclusion: {
          ...state.globalExclusion,
          activateOnSearchBox: payload,
        },
      };
    case 'SEARCH_RESULTS_INDEX':
      return {
        ...state,
        globalFields: payload,
      };
    case 'ADD_NEW_SEARCH_RESULTS_INDEX':
      return {
        ...state,
        globalFields: [
          ...state.globalFields,
          {
            ...payload,
            index: state.globalFields.length,
          },
        ],
      };
    case 'EDIT_SEARCH_RESULTS_INDEX':
      return {
        ...state,
        globalFields: state.globalFields.map(item => {
          if (item.index === payload.index) {
            return {
              ...item,
              ...payload,
            };
          }
          return item;
        }),
      };
    case 'RESET_SEARCH_RESULTS_INDEX':
      return {
        globalBoost: {
          activateOnSearchBox: false,
          boostFields: [],
        },
        productPin: {
          activateOnSearchBox: false,
          mappedProducts: [],
        },
        globalExclusion: {
          activateOnSearchBox: false,
        },
        globalFields: [],
      };
    default:
      return state;
  }
};
