import React, { useState, useEffect } from 'react';
import { Creatable } from 'react-select';
import Icons from 'components/icons';
import styles from './TypoExclusion.module.scss';

const TypoExclusion = ({ campaign, onChange }) => {
  const [excludedTypos, setExcludedTypos] = useState([]);

  useEffect(() => {
    if (campaign && campaign.nonTypoKeywords) {
      const excludedKeywords = campaign.nonTypoKeywords.map(item => {
        return {
          label: item,
          value: item,
          className: 'Select-create-option-placeholder',
        };
      });
      setExcludedTypos(excludedKeywords);
    }
  }, [campaign.nonTypoKeywords]);

  const onExcludedTyposChange = selectedValues => {
    const selValues = selectedValues.map(item => item.value);
    setExcludedTypos(selectedValues);
    onChange(selValues);
  };

  const removeAll = () => {
    setExcludedTypos([]);
    onChange([]);
  };

  return (
    <div className={styles['typo-exclusion']}>
      <li className='wizard-criterion'>
        <div>
          <span>Typo Exclusion</span>
          <p className='wizard-comp-description search__m_t_20'>
            Words that are not intended to be subject to typo correction.
          </p>
          <div className={styles['typo-exclusion__wizard-area']}>
            <div className={styles['typo-exclusion__wizard-area__wrapper']}>
              <Creatable
                id='typo-exclusion'
                options={[]}
                multi
                clearable={false}
                searchable
                name='typoExclusion'
                noResultsText=''
                style={{ width: '100%' }}
                value={excludedTypos}
                onChange={onExcludedTyposChange}
                placeholder='Enter the typos...'
                shouldKeyDownEventCreateNewOption={e => {
                  switch (e.keyCode) {
                    case 9:
                    case 13:
                      return !0;
                    default:
                      return !1;
                  }
                }}
              />
            </div>
            <a
              className={styles['typo-exclusion__wizard-area__remove']}
              onClick={removeAll}
            >
              <Icons width='24' height='24' name='crossCircle' />
            </a>
          </div>
        </div>
      </li>
    </div>
  );
};

export default TypoExclusion;
