/**
 * @name MonitoringReducer
 * @summary Reducer for Monitoring
 * @param {any} state
 * @param {any[]} action
 */

export const monitoringReducers = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_USERS':
      return {
        ...state,
        users: payload,
      };
    case 'SET_SELECTABLE_USERS':
      return {
        ...state,
        selectableUsers: payload,
      };
    case 'SET_SELECTED_USER':
      return {
        ...state,
        selectedUser: payload,
      };
    case 'SET_ACCOUNTS':
      return {
        ...state,
        accounts: payload,
      };
    case 'SET_SELECTED_ACCOUNTS':
      return {
        ...state,
        selectedAccounts: payload,
      };
    case 'EDIT_SELECTED_ACCOUNTS':
      return {
        ...state,
        selectedAccounts: payload,
      };
    case 'SET_IS_MONITORING_LOADED':
      return {
        ...state,
        isMonitoringLoaded: payload,
      };
    default:
      return state;
  }
};
