import React, { useEffect } from 'react';
import EmailEditor from 'react-email-editor';
import { browserHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { options } from './config/configs';
import { t } from '../../../system/ui';
import WithEmailBuilder from './WithEmailBuilder';
import NavigationWrapper from './components/NavigationWrapper';
import classes from './styles/ActionButtons.module.scss';
import Icons from '../../../components/icons';
import { createHeaders } from '../../../system/ajax';
import { getApiKey } from '../../../modules/auth/user';

const EmailBuilder = ({
  location,
  onLoad,
  onReady,
  saveAs,
  exportHtml,
  emailEditorRef,
  mode,
  isDesign = false,
}) => {
  const hasCampaignPathname = !!location?.state?.campaignPathname;
  const isCollection = location?.state?.isCollection;
  const isEmailToolsEnabled = useSelector(
    store =>
      store?.switchedUser?.switchedUser?.account?.mailConfiguration?.tools,
  );
  const ButtonProps = [
    {
      text: t('Export'),
      onClick: exportHtml,
      isActive: true,
    },
    {
      text: t('Save'),
      onClick: () => saveAs(true, 'save'),
      className: classes['link-button'],
      isActive:
        !isDesign &&
        !(hasCampaignPathname && (mode === 'create' || isCollection)),
    },
    {
      text: t('Save As'),
      onClick: () => saveAs(true, 'saveAs'),
      className: classes['link-button'],
      isActive: !isDesign && mode === 'edit' && !isCollection,
    },
    {
      text: t('Continue'),
      onClick: () => saveAs(false, 'continue'),
      className: classes['action-button'],
      isActive: isDesign,
    },
    {
      text: t('Exit'),
      onClick: () =>
        location?.state?.fromPathName !== undefined || null || ''
          ? browserHistory.push(location.state.fromPathName)
          : browserHistory.push({
              pathname: '/email/templates',
              state: {
                ...location?.state,
                isFork: false,
                campaignPathname: undefined,
              },
            }),
      className: classes['link-button'],
      isActive: true,
    },
    {
      icon: 'FullScreenIcon',
      onClick: () => {
        const elem = document.getElementsByTagName('iframe')[0];
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen();
        }
      },
      ariaLabel: 'Full Screen',
      className: classes['full-screen-button'],
      isActive: true,
    },
  ];

  useEffect(() => {
    const fetchConfig =
      options.tools['custom#product_tool']?.properties?.productLibrary?.editor
        ?.data?.fetchConfig;
    const apiKey = getApiKey();
    if (
      !fetchConfig?.headers ||
      !fetchConfig?.apiKey ||
      fetchConfig?.apiKey !== apiKey
    ) {
      options.tools[
        'custom#product_tool'
      ].properties.productLibrary.editor.data.fetchConfig = {
        ...fetchConfig,
        headers: createHeaders(),
        apiKey,
      };
    }
  });

  useEffect(() => {
    if (
      isEmailToolsEnabled?.countdownEnabled &&
      isEmailToolsEnabled !== undefined
    ) {
      options.tools.timer = {
        enabled: isEmailToolsEnabled?.countdownEnabled,
      };
    } else {
      options.tools.timer = {
        enabled: false,
      };
    }
  }, [isEmailToolsEnabled?.countdownEnabled, options.tools?.timer]);

  const ButtonPropsRenderer = () => {
    return ButtonProps.map(buttonProps => (
      <button
        key={ButtonProps.text}
        className={buttonProps.className}
        type='button'
        onClick={buttonProps.onClick}
        disabled={buttonProps.disabled}
        hidden={!buttonProps.isActive}
      >
        {buttonProps?.text}{' '}
        {buttonProps.icon ? (
          <Icons name={buttonProps.icon} height='16px' width='16px' />
        ) : null}
      </button>
    ));
  };

  return (
    <div className={classes['email-builder-container']}>
      <NavigationWrapper editorTitle={t('Edit Your Template')} isDark>
        {ButtonPropsRenderer()}
      </NavigationWrapper>
      <EmailEditor
        id='SEGMENTIFY_EMAIL_BUILDER'
        projectId='110683'
        minHeight='920px'
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
        options={options}
      />
    </div>
  );
};

export default WithEmailBuilder(EmailBuilder);
