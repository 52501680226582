import React, { useEffect } from 'react';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import { modalActions } from '../../../../actions';
import UploadCouponCodesModal from '../../modals/UploadCouponCodesModal';

const CouponCodes = ({ component, activeEmailDetails, setUploadFile }) => {
  const [uploadedText, setUploadedText] = React.useState('');
  const { coupon } = activeEmailDetails;

  useEffect(() => {
    if (coupon) {
      if (coupon.remainingCoupons === 0) {
        setUploadedText(
          'File uploaded. All coupon codes have been distributed.',
        );
      } else {
        setUploadedText(
          `File uploaded.\n Total coupon codes ${coupon.totalCoupons}.\n Remaining coupon codes ${coupon.remainingCoupons}`,
        );
      }
    } else {
      setUploadedText('No files have been uploaded.');
    }
  }, [coupon]);

  const openUploadContactModal = () => {
    modalActions.openModal({
      className: 'upload-coupon-codes-modal',
      title: 'Upload Your Coupon File',
      content: () => (
        <UploadCouponCodesModal
          setUploadedText={setUploadedText}
          setUploadFile={setUploadFile}
        />
      ),
    });
  };

  return (
    <InputCardWrapper
      type='adaptiveBlock'
      cardTitle={component.title}
      labelFor='scheduling'
      labelClass='cardTitle'
    >
      <p className='wizard-comp-description'>
        You can upload the coupon codes to distribute to users. There is{' '}
        <u style={{ color: 'tomato' }}>100 mb</u> of upload limit.
      </p>
      <div className='widget'>
        <a
          className='button add-criteria'
          onClick={() => openUploadContactModal()}
        >
          {!uploadedText ? 'Upload' : 'Update Current File'}
        </a>
      </div>
      <p className='wizard-comp-description'>{uploadedText}</p>
    </InputCardWrapper>
  );
};

export default CouponCodes;
