"use strict";
import React, { Component } from "react";
import { connect } from "react-redux";
import { wizardActions, uiActions } from "../../actions";
import SearchRow from "./search-row";

const criteriaTypes = {
  searchRow: SearchRow,
};

class SearchCriteria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allItems: [],
    };
    this.handleSearchCriteriaChange = this.handleSearchCriteriaChange.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.criteria !== newProps.criteria) {
      this.setStaticProducts(newProps);
    }
  }

  setStaticProducts(newProps) {
    let newItems = [];
    newProps.criteria.map((item) => {
      newItems.push(item);
    });
    this.setState({ allItems: newItems });
  }

  handleSearchCriteriaChange(criteriaId, selectedCategory) {

   if(this.props.criteria.length!==0){
    let  values = {};

    if(selectedCategory){
      values.criterion={
        status:selectedCategory.active?"ACTIVE":"PASSIVE",
        clickable:selectedCategory.clickable,
        assetItems:selectedCategory.assetItems,
        exclusions:selectedCategory.exclusions,
        additionalTitles:selectedCategory.additionalTitles,
        active: selectedCategory.active
      };
      if(selectedCategory.type==="CATEGORY"){
          values.criterion.treeView=selectedCategory.treeView;
      }
    }
    for (let i = 0; i < this.props.criteria.length; i++) {
      if (this.props.criteria[i] !== undefined && this.props.criteria[i].id === criteriaId) {
          this.props.criteria[i].values = Object.assign({}, values);
          if (this.props.criteria[i].properties) {
              this.props.criteria[i].properties.active = selectedCategory.active;
          }
        break;
      }
    }
    uiActions.formEdited();
   }
  }

  render() {
    return (
      <div className="wizard-items-container">
        <span
          style={{ fontSize: "13px", display: "block", marginBottom: "10px" }}
        >
          Mobile & App
        </span>
        {this.state.allItems.map((item) => {
          if (item.properties.isMobile) {
            let Component = criteriaTypes[item.type];
            return (
              <div key={item.id}>
                <Component
                  key={item.id}
                  {...item}
                  idParams={["srCrz", item.properties.type]}
                  onSearchCriteriaChange={this.handleSearchCriteriaChange}
                  account={this.props.account}
                  wizardLanguage={this.props.wizardLanguage}
                  isLast={item.properties.isLast}
                />
              </div>
            );
          }
        })}

        <span
          style={{
            fontSize: "13px",
            marginTop: "15px",
            display: "block",
            marginBottom: "10px",
          }}
        >
          Desktop
        </span>
        {this.state.allItems.map((item) => {
          if (!item.properties.isMobile) {
            let Component = criteriaTypes[item.type];
            return (
              <div key={item.id}>
                <Component
                  key={item.id}
                  {...item}
                  idParams={["srcrq", item.properties.type]}
                  onSearchCriteriaChange={this.handleSearchCriteriaChange}
                  account={this.props.account}
                  wizardLanguage={this.props.wizardLanguage}
                  isLast={item.properties.isLast}
                />
              </div>
            );
          }
        })}
      </div>
    );
  }
}

const mapStatesToProps = (store) => ({
  criteria: store.searchWizard.criteria,
});

export default connect(mapStatesToProps)(SearchCriteria);
