import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';

import { t } from '../../../system/ui';
import { getCampaigns } from '../../../reducers/rbs/extraReducers';
import Icon from '../../../components/icon';

import SegmentList from './SegmentList';
import { SFYHeader } from '../../../components/sfy-components/module-exports';
import { breadCrumbOptions } from './constants';
import { setTitle } from '../../../system/document';

const RuleBasedSegmentation = () => {
  const dispatch = useDispatch();
  const rbsCampaigns = useSelector(state => state.rbs.criteriaCampaigns.data);
  const [activeSegments, setActiveSegments] = useState([]);
  const [draftSegments, setDraftSegments] = useState([]);

  useEffect(() => {
    dispatch(getCampaigns());
  }, []);

  useEffect(() => {
    setTitle('Rule Based Segmentation - Customer Data Management');
    if (rbsCampaigns) {
      const active = [];
      const draft = [];
      rbsCampaigns.forEach(segment => {
        if (segment.status === 'ACTIVE') {
          active.push(segment);
        } else if (segment.status === 'TEST') {
          draft.push(segment);
        }
      });
      setActiveSegments(active);
      setDraftSegments(draft);
    }
  }, [rbsCampaigns]);

  return (
    <div>
      <SFYHeader
        pageTitle={t('Rule Based Segmentation')}
        breadCrumbOptions={breadCrumbOptions}
      />
      <Link
        to='/audience/rule-based-segmentation/add'
        className='available-type available-type-audience'
      >
        <span className='available-type-wireframe'>
          <Icon name='plus' size='2x' />
          <span>{t('Create a new Segment')}</span>
        </span>
      </Link>

      {activeSegments.length > 0 || draftSegments.length > 0 ? (
        <div className='widget-list-wrapper'>
          {activeSegments.length > 0 && (
            <SegmentList status='active' segments={activeSegments} id='rbSga' />
          )}
          {draftSegments.length > 0 && (
            <SegmentList
              status='test'
              segments={draftSegments}
              id='rbSgd'
              isMustShow={activeSegments.length === 0}
            />
          )}
        </div>
      ) : (
        <div className='widget-list-wrapper'>
          <div className='widget-list-no-items'>{t('No Active Campaign')}</div>
        </div>
      )}
    </div>
  );
};

export default RuleBasedSegmentation;
