import useExtendedContext from '../../../../hooks/module-context/useExtendedContext';
import { GlobalConfigurationReducers } from './reducers/global-configuration-reducers';
import {
  setGlobalBoost,
  setGlobalBoostSearchIsActive,
  setPESearchIsActive,
  setPPSearchIsActive,
  setProductExclusion,
  setProductPin,
  setSearchResultsIndex,
  addNewSearchResultsIndex,
  editSearchResultsIndex,
  resetSearchResultsIndex,
} from './actions/global-configuration-actions';

const globalConfigurationState = {
  globalBoost: {
    activateOnSearchBox: false,
    boostFields: [],
  },
  productPin: {
    activateOnSearchBox: false,
    mappedProducts: [],
  },
  globalExclusion: {
    activateOnSearchBox: false,
    exclusions: [],
  },
  globalFields: [],
};

export const { Context, Provider } = useExtendedContext(
  GlobalConfigurationReducers,
  {
    setGlobalBoost,
    setGlobalBoostSearchIsActive,
    setPPSearchIsActive,
    setProductPin,
    setProductExclusion,
    setPESearchIsActive,
    setSearchResultsIndex,
    addNewSearchResultsIndex,
    editSearchResultsIndex,
    resetSearchResultsIndex,
  },
  globalConfigurationState,
);
