import React, { Component } from 'react';
import AddCampaign from '../modules/campaign/add';
import { advancedConfig } from '../constants/datamaps/campaign';
import { engagementConfirmOnLeave } from '../actions/ui';

class AddPersonaQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Persona Quiz',
      content: [],
      personaQuizImg: [
        {
          field: 'imageWithUpload',
          options: {
            label: 'Notification Image*',
            tooltip: false,
            required: true,
            tooltipContent:
              'Enter the URL of the notification image or upload an image.',
          },
        },
      ],
      expandableCard: true,
      contentExtra: {
        buttons: [
          { field: 'positionButton', ignoreRef: true },
          { field: 'timingButton', ignoreRef: true },
          { field: 'audienceButton', ignoreRef: true },
          { field: 'overlay' },
        ],
        fields: [
          { field: 'positionOptions' },
          { field: 'timingOptions' },
          { field: 'audienceOptions' },
        ],
      },

      separatedContents: [
        {
          field: 'buttonText',
          options: {
            label: 'Button Text',
            required: true,
            tooltip: false,
            tooltipContent: '',
            placeholder: 'Ex : Explore More',
            personaQuiz: true,
          },
        },
        {
          field: 'stylesOptions',
          options: {
            buttonTextColor: { show: false },
            buttonBgColor: { show: false },
            bgColor: { show: true, label: 'Background Color' },
            textColor: { show: true, label: 'Text Color' },
          },
        },
      ],
      options: [
        { field: 'name' },
        { field: 'startEndDate' },
        { field: 'devices' },
        {
          field: 'pages',
          options: {
            hasPageUrlFilter: true,
          },
        },
        {
          field: 'advancedConfig',
          options: advancedConfig.PERSONA_QUIZ,
        },
      ],
      buttons: [
        { field: 'advancedConfigButton' },
        { field: 'goTest' },
        { field: 'goLive' },
      ],
    };
  }

  componentDidMount() {
    engagementConfirmOnLeave(this);
  }

  render() {
    return (
      <>
        <AddCampaign
          type='PERSONA_QUIZ'
          title={this.state.title}
          expandableCard={this.state.expandableCard}
          content={this.state.content}
          personaQuizImg={this.state.personaQuizImg}
          contentExtra={this.state.contentExtra}
          separatedContents={this.state.separatedContents}
          options={this.state.options}
          buttons={this.state.buttons}
          duplicate={this.props.routeParams.campaignId}
        />
      </>
    );
  }
}

export default AddPersonaQuiz;
