/**
 * @author Bilal Cinarli
 */

import { createAction } from '../system/store';

export const closeNotification = () => {
  createAction('HIDE_NOTIFICATION');
};

export default {
  closeNotification,
};
