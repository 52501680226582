import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';
import { t } from '../../system/ui';
import { mailAlgorithmTitles } from '../../constants/datamaps/wizard';

import Icon from '../icon';
import { getEmailConfiguration } from '../../modules/auth/user';

class EmailTypeSelection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { mailType } = this.props;
    const uniqueClass = `available-type-${mailType}`;
    const campaigns = this.props.mailGroups[mailType];
    const emailConfig = getEmailConfiguration();
    const isSegmentifyProvider =
      (emailConfig && emailConfig.provider === 'SEGMENTIFY') ||
      emailConfig.provider === 'SEGMENTIFY_V2';
    const linkPath = isSegmentifyProvider ? 'email-sgm' : 'email';

    return (
      <div
        className={classNames(
          'available-types available-widgets available-widgets-mail',
        )}
      >
        <h3 className='widget-title-head'>{this.props.title}</h3>
        <p className='widget-title-desc'>{this.props.description}</p>
        {mailType === 'fly' ? (
          <div className='available-type-selection'>
            <span>
              <Link
                to={`/${linkPath}/add/fly`}
                className={classNames('available-type', uniqueClass)}
              >
                <h4 className='available-type-name'>Fly Mode</h4>
                <span>Campaign</span>
                <span className='available-type-wireframe' />
              </Link>
            </span>
            <span>
              <Link
                to={`/${linkPath}/add/fly`}
                className='available-type available-type-custom'
              >
                <span className='available-type-wireframe'>
                  <Icon name='plus' size='2x' />
                  <span>{t('Add Campaign')}</span>
                </span>
              </Link>
            </span>
          </div>
        ) : (
          <div className='available-type-selection'>
            {campaigns?.map(campaign => {
              const isNew = this.props.allEmails.indexOf(campaign) < 0;
              const link = isNew
                ? `/${linkPath}/new/${campaign}`
                : `/${linkPath}/edit/${campaign}`;
              const randomImageInd =
                Math.floor(Math.random() * (4 - 1 + 1)) + 1;
              const uniqueSubClass = `available-type-email_tumb_0${randomImageInd}`;
              const isLive = this.props.liveEmails.indexOf(campaign) > -1;
              const comps = [
                <span key={campaign}>
                  <Link
                    to={{ pathname: link, state: { setInitialData: isNew } }}
                    className={classNames('available-type', uniqueSubClass, {
                      'list-email-live': isLive,
                      'list-email': mailType !== 'recommendation',
                    })}
                  >
                    <h4 className='available-type-name'>
                      {mailAlgorithmTitles(campaign).campaignTitle}
                    </h4>
                    <span className='available-type-wireframe'>
                      {isLive && (
                        <div className='list-email-live-label'>Live</div>
                      )}
                    </span>
                    <p className='list-email-desc'>
                      {mailAlgorithmTitles(campaign).description}
                    </p>
                  </Link>
                </span>,
              ];
              if (mailType === 'recommendation') {
                comps.push(
                  <span>
                    <Link
                      to={{
                        pathname: `/${linkPath}/new/RECOMMENDATION`,
                        state: { setInitialData: true },
                      }}
                      className='available-type available-type-custom'
                    >
                      <span className='available-type-wireframe'>
                        <Icon name='plus' size='2x' />
                        <span>{t('Add Campaign')}</span>
                      </span>
                    </Link>
                  </span>,
                );
              }
              return comps;
            })}
          </div>
        )}
      </div>
    );
  }
}

export default EmailTypeSelection;
