import React, { useState } from "react";
import "./ExpandableCardHeader.scss";
import { TextField } from "../../../fields";

const ExpandableCardHeader = ({ handleClick, handleQuizTitle, quizTitle }) => {
  const [isClick, setIsClick] = useState(false);

  function handleEvents() {
    handleClick();
    setIsClick(!isClick);
  }

  return (
    <div className="ExpandableCardHeader">
      <div className="ExpandableCardHeader__wrapper">
        <label htmlFor="quizTitle" className="ExpandableCardHeader__title">
          Quiz Title
        </label>
        <TextField
          name="quizTitle"
          className="item-stacked one-whole"
          value={quizTitle}
          required={true}
          onChange={(e) => handleQuizTitle(e)}
          placeholder="Ex : What is your hair type ?"
        />
      </div>
      <div
        className="ExpandableCardHeader__arrow"
        onClick={() => handleEvents()}
      >
        <i className={isClick ? "arrow up" : "arrow down"} />
      </div>
    </div>
  );
};

export default ExpandableCardHeader;
