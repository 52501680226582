/**
 * @author Bilal Cinarli
 */

// eslint-disable-next-line strict, lines-around-directive
'use strict';

import { createReducer } from '../system/store';
import * as uiActions from '../constants/actions/ui';

const initialState = {
  ui: '',
  submenu: {},
  breadcrumb: {},
  pageClass: '',
  browserTitle: '',
  formError: 0,
  formSaved: true,
  validate: false,
  language: localStorage.getItem('userLanguage') || 'en',
  validateTriggered: false,
  badResponseCatVisible: false,
};

export default createReducer(initialState, {
  [uiActions.default.IS_LOADING]: state => {
    return { ...state, ui: 'isLoading' };
  },
  [uiActions.default.IS_LOADED]: state => {
    return { ...state, ui: 'isLoaded' };
  },
  [uiActions.default.HAS_SUBMENU]: (state, payload) => {
    return { ...state, submenu: payload.menu };
  },
  [uiActions.default.HAS_BREADCRUMB]: (state, payload) => {
    return { ...state, breadcrumb: payload.breadcrumb };
  },
  [uiActions.default.HIDE_BREADCRUMB]: state => {
    return { ...state, breadcrumb: {} };
  },
  [uiActions.default.ADD_PAGE_CLASS]: (state, payload) => {
    return { ...state, pageClass: payload.pageClass };
  },
  [uiActions.default.REMOVE_PAGE_CLASS]: state => {
    return { ...state, pageClass: '' };
  },
  [uiActions.default.UPDATE_BROWSER_TITLE]: (state, payload) => {
    return { ...state, browserTitle: payload.browserTitle };
  },
  [uiActions.default.VALIDATE_FORMS]: state => {
    return { ...state, validate: true };
  },
  [uiActions.default.VALIDATED_FORMS]: state => {
    return { ...state, validate: false };
  },
  [uiActions.default.VALIDATING_TRIGGERED]: state => {
    return { ...state, validateTriggered: true };
  },
  [uiActions.default.VALIDATED_TRIGGERED]: state => {
    return { ...state, validateTriggered: false };
  },
  [uiActions.default.UPDATE_LANGUAGE]: (state, payload) => {
    return { ...state, language: payload.language };
  },
  [uiActions.default.FORM_SAVED]: state => {
    return { ...state, formSaved: true };
  },
  [uiActions.default.FORM_EDITED]: state => {
    return { ...state, formSaved: false };
  },
  [uiActions.default.SHOW_BAD_RESPONSE_CAT]: state => {
    return { ...state, badResponseCatVisible: true };
  },
  [uiActions.default.HIDE_BAD_RESPONSE_CAT]: state => {
    return { ...state, badResponseCatVisible: false };
  },
});
