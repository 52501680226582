import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import Icons from '../../icons';
import classes from './styles/SFYVisualBuilder.module.scss';
import { t } from '../../../system/ui';
import VisualBuilderSubItem from './VisualBuilderSubItem';
import { uiActions } from '../../../actions';

/**
 * @name SFYVisualBuilder
 * @param {any} data  - react routers carried state from destination
 * @param {string} from - previous page's url
 * @param {function} searchHandler - search handler function
 * @return {JSX.Element}
 * @constructor
 */

const SFYVisualBuilder = ({ data, from, searchHandler }) => {
  const [searchString, setSearchString] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const { isLoading, isLoaded, showNotification } = uiActions;

  useEffect(() => {
    if (searchString.length > 2) {
      isLoading();
      searchHandler(searchString, data).then(({ products }) => {
        if (products.length === 0) {
          const errorText = () => <p>{t('No results found')}</p>;
          showNotification({
            content: errorText,
            className: 'notification-fail',
          });
        } else if (products.length > 0) {
          setSearchResults(products);
        }
        isLoaded();
      });
    }
    return setSearchResults([]);
  }, [searchString]);

  const handleRoute = (route, state) => {
    browserHistory.push({ pathname: route, state });
  };

  return (
    <main className={classes['search-bar-container']}>
      <section className={classes['search-bar-controller']}>
        <div className={classes['search-bar-icon']}>
          <Icons name='search' color='#D8D8D8' height='15' width='15' />
        </div>
        <div className={classes['search-bar-input-container']}>
          <input
            name='title'
            type='text'
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            className={classes['search-bar-input']}
          />
        </div>
        <div className={classes['actions-button-container']}>
          <button
            type='submit'
            className={classes['close-button']}
            onClick={() =>
              handleRoute(from, {
                // eslint-disable-next-line no-restricted-globals
                previousPath: location.pathname,
              })
            }
          >
            {t('Close')}
          </button>
        </div>
      </section>
      {searchResults && (
        <section className={classes['flex-list']}>
          {searchResults &&
            searchResults?.map(
              ({
                productId,
                name,
                image,
                priceText,
                inStock,
                brand,
                category,
                relevancyScore,
              }) => (
                <VisualBuilderSubItem
                  id={productId}
                  image={image}
                  name={name}
                  priceText={priceText}
                  inStock={inStock}
                  brand={brand}
                  category={category}
                  score={relevancyScore}
                />
              ),
            )}
        </section>
      )}
    </main>
  );
};

export default SFYVisualBuilder;
