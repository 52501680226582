import { v1 } from 'uuid';
import { t } from '../../../../system/ui';

const inputConfiguration = {
  importFtpConfig: [
    {
      type: 'input',
      order: 1,
      stateName: 'address',
      className: 'item-stacked one-whole',
      name: 'Address *',
      placeHolder: 'Type Here...',
      required: true,
    },
    {
      type: 'input',
      order: 2,
      stateName: 'userName',
      className: 'item-stacked one-whole',
      name: 'Username *',
      placeHolder: 'Type Here...',
      required: true,
    },
    {
      type: 'input',
      order: 3,
      stateName: 'password',
      className: 'item-stacked one-whole',
      name: 'Password *',
      placeHolder: 'Type Here...',
      required: true,
    },
    {
      type: 'input',
      order: 4,
      stateName: 'path',
      className: 'item-stacked one-whole',
      name: 'Path *',
      placeHolder: 'Type Here...',
      required: true,
    },
  ],
  exportFtpConfig: [
    {
      type: 'input',
      order: 1,
      stateName: 'address',
      className: 'item-stacked one-whole',
      name: 'Address *',
      placeHolder: 'Type Here...',
      required: true,
    },
    {
      type: 'input',
      order: 2,
      stateName: 'userName',
      className: 'item-stacked one-whole',
      name: 'Username *',
      placeHolder: 'Type Here...',
      required: true,
    },
    {
      type: 'input',
      order: 3,
      stateName: 'password',
      className: 'item-stacked one-whole',
      name: 'Password *',
      placeHolder: 'Type Here...',
      required: true,
    },
    {
      type: 'input',
      order: 4,
      stateName: 'path',
      className: 'item-stacked one-whole',
      name: 'Path *',
      placeHolder: 'Type Here...',
      required: true,
    },
  ],
  checkbox: [
    {
      type: 'checkbox',
      order: 1,
      name: t('Subscribed'),
      stateName: 'subscribed',
    },
    {
      type: 'checkbox',
      order: 2,
      name: t('Unsubscribed'),
      stateName: 'unSubscribed',
    },
    {
      type: 'checkbox',
      order: 3,
      name: t('Bounce back'),
      stateName: 'bounceBack',
    },
  ],
};

const initialState = {
  importFtpConfig: {
    userName: '',
    password: '',
    address: '',
    path: '',
    frequency: '',
    enabled: false,
    sendToIys: false,
    configType: 'IMPORT',
  },
  exportFtpConfig: {
    userName: '',
    password: '',
    address: '',
    path: '',
    frequency: 'DAILY',
    enabled: false,
    subscribed: false,
    unSubscribed: false,
    bounceBack: false,
    configType: 'EXPORT',
  },
};

const ftpTabs = [
  {
    id: v1(),
    active: true,
    componentName: t('Import From FTP'),
    name: 'importFTP',
  },
  {
    id: v1(),
    active: false,
    componentName: t('Export to FTP'),
    name: 'exportFTP',
  },
  {
    id: v1(),
    active: false,
    componentName: t('Download User Permission Changes'),
    name: 'downloadUserPermissionChanges',
  },
];

const frequencyOptions = [
  { label: t('Once'), value: 'ONCE' },
  { label: t('Daily'), value: 'DAILY' },
];

const fileTypeOptions = [
  {
    label: t('CSV'),
    value: 'csv',
  },
  {
    label: t('XLSX'),
    value: 'xlsx',
  },
];

export {
  inputConfiguration,
  frequencyOptions,
  initialState,
  ftpTabs,
  fileTypeOptions,
};
