/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
/**
 * @author Bilal Cinarli
 */

// eslint-disable-next-line strict, lines-around-directive
'use strict';

import React from 'react';
import { connect } from 'react-redux';

import { config } from '../constants/config';

import { t } from '../system/ui';
import { setTitle } from '../system/document';

import { campaignActions } from '../actions';

import {
  filterPage,
  filterVisible,
  filterStatus,
} from '../modules/campaigns/filters';

import { hasOnlyDynamicBundles } from '../modules/auth/user';

import WidgetList from '../components/widget/list';
import WidgetTypeSelection from '../components/widget/type-selection';
import PromotionSelection from '../components/widget/promotion-selection';
import KeywordSelection from '../components/widget/keyword-selection';

const journeyBuilderDesc =
  "Create unique personal experience for your each customer with Segmentify's intelligent algorithms.";

class Recommendation extends React.Component {
  constructor(props) {
    super(props);

    const children =
      config.menus.primary.recommendations.children[
        this.props.params.recommendation
      ];

    this.state = {
      sub: this.props.params.recommendation,
      menu: children,
      pageTitle:
        children.name !== 'Trigger Based' &&
        children.name !== 'Popup Recommendation' &&
        children.name !== 'Journey Builder'
          ? `${children.name} Page`
          : children.name,
      liveCampaigns: [],
      count: 0,
      liveCount: 0,
      testCount: 0,
    };

    this.campaignCount = this.campaignCount.bind(this);
  }

  componentDidMount() {
    setTitle(`${t(this.state.pageTitle)} ${t('Recommendation')}`);

    campaignActions.resetWizard();
    campaignActions.duplicateCampaign(false);

    this.campaignCount(this.props.campaigns);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (this.props.campaigns !== newProps.campaigns) {
      this.campaignCount(newProps.campaigns);
    }
  }

  campaignCount(campaigns) {
    const count = filterPage(filterVisible(campaigns), this.state.menu.name)
      .length;
    const testCount = filterPage(
      filterStatus(campaigns, 'TEST'),
      this.state.menu.name,
    ).length;
    const liveCount = filterPage(
      filterStatus(campaigns, 'ACTIVE'),
      this.state.menu.name,
    ).length;

    this.setState({
      count,
      testCount,
      liveCount,
    });
  }

  render() {
    const tabIsClosed = this.state.liveCount !== 0;
    const isFiltered = hasOnlyDynamicBundles();
    return (
      <div>
        <h2 className='page-title'>{t(this.state.pageTitle)}</h2>

        <div className='page-content-wrapper'>
          {this.state.count > 0 ? (
            <div className='widget-list-wrapper'>
              <WidgetList
                page={this.state.sub}
                type='LIVE'
                name={this.state.menu.name}
                title={t('Live Campaigns')}
                campaigns={this.props.campaigns}
                idsInAB={this.props.idsInAB}
                {...this.props}
              />

              <WidgetList
                page={this.state.sub}
                type='TEST'
                name={this.state.menu.name}
                title={t('Test Campaigns')}
                campaigns={this.props.campaigns}
                closed={tabIsClosed}
                idsInAB={this.props.idsInAB}
                {...this.props}
              />

              <WidgetList
                page={this.state.sub}
                type='LIVE'
                name={this.state.menu.name}
                title={t('Scheduled Campaigns')}
                campaigns={this.props.campaigns}
                closed
                dateFilter='SCHEDULED'
                idsInAB={this.props.idsInAB}
                {...this.props}
              />

              <WidgetList
                page={this.state.sub}
                type='LIVE'
                name={this.state.menu.name}
                title={t('Completed Campaigns')}
                campaigns={this.props.campaigns}
                closed
                dateFilter='COMPLETED'
                idsInAB={this.props.idsInAB}
                {...this.props}
              />
            </div>
          ) : (
            <div className='widget-list-wrapper'>
              <div className='widget-list-no-items'>
                {t(`NoActive${this.state.menu.name}Campaigns`)}
              </div>
            </div>
          )}

          {this.state.sub !== 'journey_builder' ? (
            <WidgetTypeSelection
              page={this.state.sub}
              isFiltered={isFiltered}
            />
          ) : (
            <WidgetTypeSelection
              page={this.state.sub}
              title='Journey Builder'
              desc={journeyBuilderDesc}
              widgetName='Add Journey Builder'
            />
          )}
        </div>
        {this.state.sub !== 'popup_recommendation' &&
          this.state.sub !== 'journey_builder' && (
            <PromotionSelection page={this.state.sub} isFiltered={isFiltered} />
          )}
        {this.state.sub !== 'popup_recommendation' &&
          this.state.sub !== 'journey_builder' && (
            <KeywordSelection page={this.state.sub} isFiltered={isFiltered} />
          )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  user: store.user.user,
});

export default connect(mapStatesToProps)(Recommendation);
