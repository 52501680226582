/*eslint react/display-name: "off"*/

"use strict";

import React from "react";

export const external_tr = {
  "Segmentify Performance": "Personalization Performansı",
  NoActiveCustomCampaigns: () => {
    return (
      <div>
        <p>
          Personalization’da öntanımlı olmayan özel bir sayfada kampanya
          oluşturabilirsiniz. Örneğin, kullanıcı hesap sayfası, çıkış sayfası
          gibi.
        </p>
        <p>Lütfen aşağıda tercih ettiğiniz widget tipini seçin.</p>
      </div>
    );
  },
  "NoActiveTrigger BasedCampaigns": () => {
    return (
      <div>
        <p>
          Ziyaretçilerinizin site içindeki hareketine bağlı kampanya oluşturmak
          için “Trigger Based” kampanyalar oluşturabilirsiniz
        </p>
        <p>Lütfen aşağıda tercih ettiğiniz widget tipini seçin.</p>
      </div>
    );
  },
  Segmentify: "Personalization",

  "Number Of Clicks By Segmentify Recommendation Widgets":
    "Personalization Öneri Bileşenlerinden Tıklanma Sayısı",
  "Number Of Add To Basket Operations By Segmentify Recommendation Widgets":
    "Personalization Öneri Bileşenlerinden Sepete Ekleme Sayısı",
  "Conversion Rate (Add To Basket/Click)":
    "Dönüşüm Oranı (Sepete Ekleme/Tıklanma)",
  "Number Of Purchases By Segmentify Recommendation Widgets":
    "Personalization Öneri Bileşenlerinden Gerçekleşen Satış Adedi",
  "Conversion Rate (Purchase/Click)": "Dönüşüm Oranı (Satın Alma/Tıklanma)",
  "Amount Of Purchases By Segmentify Recommendation Widgets":
    "Personalization Öneri Bileşenlerinden Gerçekleşen Satış Tutarı",
  "Customer Removal Tool Disclaimer": () => {
    return (
      <p>
        All historical personal information related to given email addresses
        will be deleted within the next 24 hours. When the removal process is
        finished, you will receive an email.
        <br />
        Please note that the removal process is permanent and irreversible, upon
        its completion Personalization can't recover any data.
      </p>
    );
  },
  "Let's Integrate Segmentify": "Personalization Entegrason Adımları",
  "integration-script-info":
    "Sitenizde Personalization ile Akıllı Ürün Önerileri yapmak çok kolay. Tek yapmanız gereken JavaScript kodunu bütün sayfalarınızın <HEAD> tag'i kapanmadan hemen öncesine eklemek",
  IntegrationZendDesk: () => {
    return (
      <span>
        Entegrasyon için gerekli adımları{" "}
        <a href="https://segmentify.zendesk.com/hc/en-us" target="_blank">
          Personalization Customer success Center
        </a>{" "}
        da takip edebilirsiniz.
      </span>
    );
  },
  StartChat: var1 => {
    return (
      <span>
        Personalization Customer Success ekibi entegrasyonu sizin için
        hazırlayabilir, <a>şimdi iletişime geçin</a>
      </span>
    );
  },
  Personalization: "Kişiselleştirme",
  //Provider Warnings
  ShopifyWarning:
    "Personalization uygulamasını indirmişsiniz. Shopify Store'da yönetici panelinden Personalization Uygulamasını kullanmanızı tavsiye ederiz.",
  PrestashopWarning:
    "Personalization eklentisini kurmuşsunuz. Prestashop yönetici panelinde Personalization eklentisini kullanmanızı tavsiye ederiz.",
  MagentoWarning:
    "Personalization eklentisini kurmuşsunuz. Magento yönetici panelinde Personalization eklentisini kullanmanızı tavsiye ederiz.",
  "Segmentify Graph": "Personalization Grafiği",
  "Custom Segmentify integration requires two important values.":
    "Özelleştirilmiş Personalization entegrasyonu için iki önemli değer gerekmektedir.",
  PrivacyPolicyCta:
    "Personalization'ı kullandığınız için cookie ve gizlilik politikalarınızı yeniden düzenlemeniz gerekiyor mu?",
  "The percentage of products that have been purchased after being clicked in a Segmentify Recommendation widget.":
    "Personalization öneri bileşenlerine tıklandıktan sonra satın alınan ürünlerin yüzdesi.",
  "You should make at least one selection from Segmentify Intelligent or Static algorithms":
    "You should make at least one selection from Personalization Intelligent or Static algorithms",
  Widget_Desc:
    "Create personal recommendation campaigns with Personalization's intelligent and static algorithms.",
  Promotion_Desc:
    "Personalize the Special Offers you display in your website with Personalization's Promotion campaigns.",
  "Overall Segmentify Performance":"Overall Personalization Performance",
  "Revenue Generated by Segmentify": "Generated by Personalization",
  "Segmentify’s Revenue Contribution": "of all revenue via Personalization",
  "Products Sold via Segmentify":"products via Personalization",
  "Select from Segmentify Intelligent Recommendation Types to create your personalized recommendation campaigns" :
      "Select from Personalization Intelligent Recommendation Types to create your personalized recommendation campaigns",
  "Select from Segmentify Static Recommendation Types to create your personalized recommendation campaigns"
      : "Select from Personalization Static Recommendation Types to create your personalized recommendation campaigns",
  "Select from Segmentify Promotion Types to create your personalized recommendation campaigns"
      : "Select from Personalization Promotion Types to create your personalized recommendation campaigns",
  "Before_Search_Input" : "Attract your customers with Personalization's intelligent algorithms even before they make a search."
};

export default external_tr;
