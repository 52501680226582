import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { browserHistory } from 'react-router';
import { RecommendationTemplateService } from '../../services/recommendationTemplateServices';
import classes from './EditorPage.module.scss';
import TemplateInfo from './TemplateInfo';
import { CodeEditor } from '../../../../../components/sfy-components/module-exports';
import { files } from '../../../../../components/sfy-components/CodeEditor/constants/defaults';
import {
  hideBreadcrumb,
  isLoaded,
  isLoading,
  showNotification,
} from '../../../../../actions/ui';
import { t } from '../../../../../system/ui';

const EditorPage = ({ params }) => {
  const [campFiles, setCampFiles] = useState({});
  const [templateName, setTemplateName] = useState('');
  const [usedTemplates, setUsedTemplates] = useState([]);

  const showNotificationFunc = (message, type) => {
    const content = () => <p>{t(message)}</p>;
    showNotification({
      content,
      className: `notification-${type}`,
    });
  };

  const save = () => {
    isLoading();
    const body = {
      id: '',
      templateName,
      html: campFiles.html,
      css: campFiles.css,
      preJs: campFiles.prejs,
      postJs: campFiles.postjs,
    };
    if (params?.mode === 'create') {
      body.id = uuid();
      RecommendationTemplateService()
        .create({ body })
        .then(response => {
          showNotificationFunc('Template is successfully created.', 'success');
          browserHistory.push({
            pathname: `/recommendations/templates/edit/${response.id}`,
            state: {
              campaignPathname: `/recommendations/templates/edit/${response.id}`,
            },
          });
        })
        .catch(() => {
          showNotificationFunc('Template could not be created.', 'fail');
        })
        .finally(() => {
          isLoaded();
        });
    } else {
      body.id = params?.id;

      RecommendationTemplateService()
        .updateWithInsideId({ body })
        .then(() => {
          showNotificationFunc('Template is successfully updated.', 'success');
        })
        .catch(() => {
          showNotificationFunc('Template could not be updated.', 'fail');
        })
        .finally(() => {
          isLoaded();
        });
    }
  };

  const handleEditorChange = (fileName, value) => {
    setCampFiles({ ...campFiles, [fileName]: value });
  };

  const handleTemplateNameChange = value => {
    setTemplateName(value);
  };

  useEffect(() => {
    if (params?.mode === 'create') {
      const fileBody = {
        ...campFiles,
        html: files.html.value,
        prejs: files.prejs.value,
        postjs: files.postjs.value,
        css: files.css.value,
      };
      setCampFiles({ ...fileBody });
    } else {
      RecommendationTemplateService()
        .get({ id: params?.id })
        .then(res => {
          const fileBody = {
            ...campFiles,
            html: res?.html || '',
            prejs: res?.preJs || '',
            postjs: res?.postJs || '',
            css: res?.css || '',
          };
          setCampFiles({ ...fileBody });
          setTemplateName(res?.templateName || '');
        });

      RecommendationTemplateService()
        .specialGet({ id: params?.id, tag: 'get-campaigns' })
        .then(res => {
          setUsedTemplates(res);
        });
    }
    hideBreadcrumb();
  }, []);

  return (
    <div className={classes['edit-template']}>
      <div className={classes['edit-template__left']}>
        <TemplateInfo
          templateName={templateName}
          setTemplateName={handleTemplateNameChange}
          saveHandler={save}
          usedTemplates={usedTemplates}
        />
      </div>
      <div className={classes['edit-template__right']}>
        <CodeEditor
          fileValue={campFiles}
          handleEditorChange={handleEditorChange}
        />
      </div>
    </div>
  );
};

export default EditorPage;
