import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const EmailDiamond = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.9989 0C16.2398 0 16.4638 0.11546 16.6038 0.30649L16.6585 0.39288L19.922 6.42119L19.9599 6.51408L19.972 6.5539L19.9921 6.65608L19.9979 6.75C19.9979 6.81904 19.9886 6.88589 19.9711 6.94938L19.9237 7.0754L19.8862 7.144C19.8641 7.1796 19.839 7.2136 19.8107 7.2457L19.8705 7.1683L19.846 7.2025L10.5962 17.7081C10.5161 17.8144 10.4128 17.8917 10.2989 17.94L10.2007 17.973L10.1235 17.9904L9.9993 18.0003L9.899 17.9937L9.7811 17.9682C9.7209 17.9509 9.6623 17.9254 9.6075 17.8922L9.5983 17.8847C9.5512 17.8564 9.5072 17.8218 9.4672 17.7815L0.17809 7.2352L0.15185 7.2025L0.11178 7.144C0.0562999 7.0544 0.0192101 6.9523 0.0056901 6.84286L0 6.75L0.00285006 6.68414L0.0160601 6.59539C0.0222501 6.56547 0.03015 6.53671 0.03966 6.5087L0.06248 6.45021L0.0904801 6.39288L3.33943 0.39288C3.45413 0.18107 3.66229 0.0391299 3.89695 0.0069499L3.99895 0H15.9989ZM12.997 7.5H6.998L9.9989 15.187L12.997 7.5ZM5.389 7.5H2.409L7.76 13.574L5.389 7.5ZM17.587 7.5H14.608L12.24 13.57L17.587 7.5ZM6.971 1.5H4.44495L2.007 6H5.531L6.971 1.5ZM11.4509 1.5H8.5459L7.105 6H12.89L11.4509 1.5ZM15.5519 1.5H13.026L14.466 6H17.988L15.5519 1.5Z'
        fill='#5D5D61'
      />
    </svg>
  );
};

EmailDiamond.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

EmailDiamond.defaultProps = {
  width: '20px',
  height: '18px',
};

export default EmailDiamond;
