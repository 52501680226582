import React from 'react';
import { t } from '../../../system/ui';
import { TextField } from '../../fields';
import classes from './SFYTextWithLabel.module.scss';

const SFYTextWithLabel = ({
  editCampaign,
  fieldValue,
  cardLabel,
  cardName,
  placeholder,
  isBigLabel,
  disabled = false,
  notRequired = false,
  errorMessage = '',
}) => {
  return (
    <div className={classes['sfy-wrapper']}>
      <span
        className={isBigLabel ? classes['sfy-big-label'] : classes['sfy-label']}
      >
        {t(cardLabel)}
      </span>
      <TextField
        name={cardName}
        id={cardName}
        className='item-stacked one-whole'
        required={!notRequired}
        placeholder={t(placeholder)}
        value={fieldValue || ''}
        onChange={e => editCampaign(cardName, e.target.value)}
        disabled={disabled}
        emailErrorMessage={errorMessage !== '' ? errorMessage : null}
      />
    </div>
  );
};

export default SFYTextWithLabel;
