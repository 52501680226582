import React, { Component } from 'react';
import { connect } from 'react-redux';

import { uiActions } from '../../actions';
import { getRangedPushReport } from '../../modules/push/ajax';
import { getRangedReport } from '../../modules/reports/ajax';
import { transformCampaignReport } from '../../modules/transform';
import { t } from '../../system/ui';
import reduceBasket from '../../system/reducer';
import { getOtherDates } from '../analytics/utils';
import { RangePicker } from '../fields';
import ComparisonColumn from './comparisonColumn';

class CompareRecommendationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserSelect: false,
      firstStart: '',
      firstEnd: '',
      secondStart: '',
      secondEnd: '',
      firstColumnReports: [],
      secondColumnReports: [],
    };

    this.getInitialReports = this.getInitialReports.bind(this);
    this.onFirstDatesChange = this.onFirstDatesChange.bind(this);
    this.onSecondDatesChange = this.onSecondDatesChange.bind(this);
    this.changeFirstColumn = this.changeFirstColumn.bind(this);
    this.changeSecondColumn = this.changeSecondColumn.bind(this);
  }

  componentDidMount() {
    this.getInitialReports();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.firstColumnReports.length !==
      prevState.firstColumnReports.length
    ) {
      window.dispatchEvent(new Event('resize'));
    }
  }

  onFirstDatesChange(newDates) {
    this.setState({
      firstStart: newDates.startDate,
      firstEnd: newDates.endDate,
      isUserSelect: true,
    });
  }

  onSecondDatesChange(newDates) {
    this.setState({
      secondStart: newDates.startDate,
      secondEnd: newDates.endDate,
      isUserSelect: true,
    });
  }

  getInitialReports() {
    uiActions.isLoading();
    const boundaries = {
      start: this.props.range[0].format('YYYY-MM-DD'),
      end: this.props.range[1].format('YYYY-MM-DD'),
    };
    const secondBoundaries = {
      start: getOtherDates(this.props.range).formattedStart,
      end: getOtherDates(this.props.range).formattedEnd,
    };

    if (this.props.moduleName === "push") {
      getRangedPushReport(
        this.props.campaign.instanceId,
        boundaries,
        campaignReport => {
          campaignReport =
            this.props.campaign.instanceId === "ABANDONED_CART"
              ? reduceBasket([
                  campaignReport.ABANDONED_CART_FIRST_REMINDER,
                  campaignReport.ABANDONED_CART_SECOND_REMINDER,
                  campaignReport.ABANDONED_CART_ALTERNATIVE_PRODUCTS,
                  campaignReport.ABANDONED_CART_COUPON
                ])
              : campaignReport;

          getRangedPushReport(
            this.props.campaign.instanceId,
            secondBoundaries,
            otherCampaignReport => {
              otherCampaignReport =
                this.props.campaign.instanceId === "ABANDONED_CART"
                  ? reduceBasket([
                      otherCampaignReport.ABANDONED_CART_FIRST_REMINDER,
                      otherCampaignReport.ABANDONED_CART_SECOND_REMINDER,
                      otherCampaignReport.ABANDONED_CART_ALTERNATIVE_PRODUCTS,
                      otherCampaignReport.ABANDONED_CART_COUPON
                    ])
                  : otherCampaignReport;
              this.setState({
                secondColumnReports: transformCampaignReport(
                  otherCampaignReport,
                  false,
                  this.props.moduleName
                ),
              });
              this.setState({
                firstColumnReports: transformCampaignReport(
                  campaignReport,
                  false,
                  this.props.moduleName
                ),
              });
              uiActions.isLoaded();
            },
          );
        },
      );
    } else {
      getRangedReport(
        this.props.campaign.instanceId,
        boundaries,
        campaignReport => {
          let firstColumnReports = campaignReport;
          getRangedReport(
            this.props.campaign.instanceId,
            {
              start: getOtherDates(this.props.range).formattedStart,
              end: getOtherDates(this.props.range).formattedEnd
            },
            otherCampaignReport => {
              this.setState({
                firstColumnReports: transformCampaignReport(firstColumnReports),
                secondColumnReports: transformCampaignReport(
                  otherCampaignReport,
                ),
              });
              window.dispatchEvent(new Event("resize"));
              uiActions.isLoaded();
            }
          );
        }
      );
    }
  }

  changeFirstColumn(dates) {
    if (
      dates.startDate === this.state.firstStart &&
      dates.endDate === this.state.firstEnd
    ) {
      return;
    }
    uiActions.isLoading();
    let boundaries = {};
    boundaries.start = dates.startDate.format("YYYY-MM-DD");
    boundaries.end = dates.endDate.format("YYYY-MM-DD");
    if (this.props.moduleName === "push") {
      getRangedPushReport(
        this.props.campaign.instanceId,
        boundaries,
        campaignReport => {
          campaignReport =
            this.props.campaign.instanceId === "ABANDONED_CART"
              ? reduceBasket([
                  campaignReport.ABANDONED_CART_FIRST_REMINDER,
                  campaignReport.ABANDONED_CART_SECOND_REMINDER,
                  campaignReport.ABANDONED_CART_ALTERNATIVE_PRODUCTS,
                  campaignReport.ABANDONED_CART_COUPON
                ])
              : campaignReport;

          uiActions.isLoaded();
          this.setState({
            firstColumnReports: transformCampaignReport(
              campaignReport,
              false,
              this.props.moduleName
            )
          });
        }
      );
    } else {
      getRangedReport(
        this.props.campaign.instanceId,
        boundaries,
        campaignReport => {
          this.setState({
            firstColumnReports: transformCampaignReport(campaignReport)
          });
          uiActions.isLoaded();
          window.dispatchEvent(new Event("resize"));
        }
      );
    }
  }

  changeSecondColumn(dates) {
    if (
      dates.startDate === this.state.secondStart &&
      dates.endDate === this.state.secondEnd
    ) {
      return;
    }
    uiActions.isLoading();
    let boundaries = {};
    boundaries.start = dates.startDate.format("YYYY-MM-DD");
    boundaries.end = dates.endDate.format("YYYY-MM-DD");
    if (this.props.moduleName === "push") {
      getRangedPushReport(
        this.props.campaign.instanceId,
        boundaries,
        campaignReport => {
          campaignReport =
            this.props.campaign.instanceId === "ABANDONED_CART"
              ? reduceBasket([
                  campaignReport.ABANDONED_CART_FIRST_REMINDER,
                  campaignReport.ABANDONED_CART_SECOND_REMINDER,
                  campaignReport.ABANDONED_CART_ALTERNATIVE_PRODUCTS,
                  campaignReport.ABANDONED_CART_COUPON
                ])
              : campaignReport;

          uiActions.isLoaded();
          this.setState({
            secondColumnReports: transformCampaignReport(
              campaignReport,
              false,
              this.props.moduleName
            )
          });
        }
      );
    } else {
      getRangedReport(
        this.props.campaign.instanceId,
        boundaries,
        campaignReport => {
          this.setState({
            secondColumnReports: transformCampaignReport(campaignReport)
          });
          uiActions.isLoaded();
          window.dispatchEvent(new Event("resize"));
        }
      );
    }
  }

  render() {
    return (
      <div className="report-compare one-whole" style={{ minWidth: "932px" }}>
        <div className="one-half report-compare__field">
          <div className="report-compare-date">
            <span
              style={{
                display: "inline-block",
                marginRight: "12px",
                fontSize: "14px",
                color: "#a3a3a5"
              }}
            >
              {t("Compare To")}:
            </span>
            <RangePicker
              startDate={
                this.state.secondStart || getOtherDates(this.props.range).start
              }
              endDate={
                this.state.secondEnd || getOtherDates(this.props.range).end
              }
              onDatesChange={this.onSecondDatesChange}
              onPickerClose={this.changeSecondColumn}
            />
          </div>
          <ComparisonColumn
            stats={this.state.secondColumnReports}
            isEmailCampaign={this.props.isEmailCampaign}
          />
        </div>
        <div className="one-half report-compare__field">
          <div className="report-compare-date">
            <span
              style={{
                display: "inline-block",
                marginRight: "12px",
                fontSize: "14px",
                color: "#a3a3a5"
              }}
            >
              {t("With")}:
            </span>
            <RangePicker
              startDate={this.state.firstStart || this.props.range[0]}
              endDate={this.state.firstEnd || this.props.range[1]}
              onDatesChange={this.onFirstDatesChange}
              onPickerClose={this.changeFirstColumn}
            />
          </div>
          <ComparisonColumn
            stats={this.state.firstColumnReports}
            withCompareIcons={true}
            comparisonStats={this.state.secondColumnReports}
            isEmailCampaign={this.props.isEmailCampaign}
          />
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias
});

export default connect(mapStatesToProps)(CompareRecommendationModal);
