import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.replace('px', '')}px`;
};

const LeftLongArrow = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 17 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4929 4.73249C13.4897 4.75166 13.4865 4.77076 13.486 4.79026C15.216 5.18167 16.5 6.56182 16.5 8.21889C16.5 10.1795 14.709 11.7691 12.5 11.7691H5C2.515 11.7691 0.5 9.98069 0.5 7.77511C0.5 6.0142 1.793 4.53642 3.575 4.00477C3.939 1.87109 5.995 0.230885 8.5 0.230885C11.261 0.230885 13.5 2.21812 13.5 4.66867C13.5 4.69045 13.4965 4.71152 13.4929 4.73249ZM7.49975 3.78107V6.44374H4.49975L8.49975 9.99397L12.4997 6.44374L9.49975 6.44374L9.49975 3.78107H7.49975Z'
        fill='#656565'
      />
    </svg>
  );
};

LeftLongArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

LeftLongArrow.defaultProps = {
  width: '20px',
  height: '16px',
};

export default LeftLongArrow;
