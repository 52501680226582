import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const IconGoActive = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M5 8L8 6L5 4V8Z' fill={color} />
      <circle cx='6' cy='6' r='5.25' stroke='white' strokeWidth='1.5' />
    </svg>
  );
};

IconGoActive.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

IconGoActive.defaultProps = {
  width: '20px',
  height: '20px',
  color: '#FFFFFF',
};

export default IconGoActive;
