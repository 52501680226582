import React, { useEffect } from 'react';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import { t } from '../../../../system/ui';
import { TextField } from '../../../../components/fields';

const EventName = ({ component, editCampaign, activeEmailDetails }) => {
  const { eventName = '' } = activeEmailDetails?.params;
  const editValue = (fieldKey, fieldValue) => {
    editCampaign('params', {
      ...activeEmailDetails.params,
      [fieldKey]: fieldValue,
    });
  };

  return (
    <InputCardWrapper
      type='block'
      cardTitle='Event Name*'
      labelClass='cardTitle'
      labelFor='EventName'
    >
      <TextField
        name='eventName'
        id='eventName'
        className='item-stacked one-whole'
        required
        placeholder={t(component.placeholder)}
        value={eventName}
        onChange={e => editValue('eventName', e.target.value)}
      />
    </InputCardWrapper>
  );
};

export default EventName;
