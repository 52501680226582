import React from 'react';
import { connect } from 'react-redux';

import { t } from '../system/ui';
import { queryDate } from '../system/date';
import { setTitle } from '../system/document';
import {
  hasModule,
  getAccount,
  getApiKey,
  getSwitchAccountUser,
  getToken,
  isMultiAccountUser,
  isSwitchAccount,
  getRegion,
} from '../modules/auth/user';

import { uiActions, filterActions } from '../actions';

import stats from '../modules/stats';
import { transformStats } from '../modules/transform';

import { searchIntegrationInfo } from '../modules/trendify/page';
import { getSearch } from '../modules/search/ajax';
import { getEndPoint } from '../system/api';
import { SearchReportRow } from './searchreportrow';
import { statTitles } from '../constants/datamaps/stats';
import { config } from '../constants/config';

class ExecutiveSearchReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      transformedStats: {}, // this will hold transformed stats of search, all, faceted and instant
      campaignDevices: [], // important
      isLoaded: false,
      pageTitle: '',
      searchSelectedDevice: 'all',
      brandSelectedDevice: 'all',
      categorySelectedDevice: 'all',
      keywordsSelectedDevice: 'all',
      productSelectedDevice: 'all',
      allSelectedDevice: 'all',
      instantSelectedDevice: 'all',
      facetedSelectedDevice: 'all',
    };

    this.getCampaign = this.getCampaign.bind(this);
    this.selectDevice = this.selectDevice.bind(this);
    this.exportAll = this.exportAll.bind(this);
    this.getSelectedDevice = this.getSelectedDevice.bind(this);
  }

  componentDidMount() {
    this.onChangeDocumentTitle(this.props.params.report);
    this.getCampaign();
    this.getRangedStats(this.props);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.params.report !== newProps.params.report) {
      this.onChangeDocumentTitle(newProps.params.report);
    }

    if (
      this.props.params?.report !== newProps.params?.report ||
      this.props.user !== newProps.user ||
      newProps.update === true
    ) {
      this.getRangedStats(newProps);
    }
  }

  onChangeDocumentTitle(report) {
    let { pageTitle } = this.state;
    switch (report) {
      case 'BEFORE_SEARCH':
        setTitle(
          `${t(
            config.menus.primary.reports.children['before-search-input'].name,
          )} - ${t('Reports')}`,
        );
        break;
      case 'SEARCH':
        setTitle(
          `${t(
            config.menus.primary.reports.children['after-search-input'].name,
          )} - ${t('Reports')}`,
        );
        break;
      default:
        setTitle(`${t('Executive Reports')}`);
        break;
    }
  }

  getSelectedDevice(assetName) {
    return this.state[`${assetName}SelectedDevice`] === 'PC'
      ? 'PC'
      : this.state[`${assetName}SelectedDevice`].toLowerCase();
  }

  getCampaign() {
    searchIntegrationInfo();
    if (hasModule('search')) {
      uiActions.isLoading();
      let searchCamp = [];
      getSearch(null, response => {
        if (response) {
          searchCamp = response;
          const devices = [];
          Object.keys(searchCamp).forEach(instanceId => {
            searchCamp[instanceId].devices.forEach(_device => {
              if (devices.indexOf(_device) === -1) {
                devices.push(_device);
              }
            });
          });
          this.setState({
            campaignDevices: devices,
          });
        }
      });
    }
  }

  getRangedStats(props) {
    filterActions.filtersUpdated();
    filterActions.filtersReset();
    if (props.range[0] && props.range[1]) {
      uiActions.isLoading();

      // prepare query
      const reportInstanceId = props.params?.report ?? 'ALL';
      const boundaries = {};
      boundaries.start = props.range[0].format('YYYY-MM-DD');
      boundaries.end = props.range[1].format('YYYY-MM-DD');
      boundaries.reportPageType = 'searchReportPage';
      if (reportInstanceId !== 'ALL') {
        boundaries.searchInstanceId = reportInstanceId;
        boundaries.instanceid = reportInstanceId;
      }
      boundaries.reportPageType = 'executive_summary';
      //

      // set values to defaults
      this.setState(
        {
          data: {},
          transformedStats: {},
          isLoaded: false,
        },
        () => {
          // fetch data
          if (reportInstanceId !== 'ALL') {
            stats.getRangedStats(
              boundaries,
              allStats => {
                stats.getSearchDetail(
                  boundaries,
                  allTrends => {
                    this.setState({
                      transformedStats: {
                        search: transformStats(allStats),
                      },
                      data: {
                        search: allStats,
                        ...allTrends,
                      },
                      isLoaded: true,
                    });
                    uiActions.isLoaded();
                  },
                  null,
                  reportInstanceId,
                );
              },
              null,
              'Search',
            );
          } else {
            stats.getSearchDetail(
              boundaries,
              allTrends => {
                const transformedStats = {};
                Object.keys(allTrends).forEach(
                  trends => (transformedStats[trends] = allTrends[trends]),
                );
                this.setState({
                  transformedStats,
                  data: {
                    ...allTrends,
                  },
                  isLoaded: true,
                });
                uiActions.isLoaded();
              },
              null,
            );
          }
        },
      );
    }
  }

  rowFilter = asset => {
    if (this.props.params?.report === 'SEARCH') {
      return asset !== 'keywords';
    }
    return true;
  };

  selectDevice(selectedDevice, assetName, e) {
    e.preventDefault();
    this.setState({
      [`${assetName}SelectedDevice`]: selectedDevice,
    });
  }

  exportAll() {
    const globalApiKey =
      getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
    const endpoint = getEndPoint('export/all/search') + globalApiKey;
    const instanceId = '';
    let exportUrl = `${endpoint}&end=${queryDate(
      this.props.range[1],
    )}&start=${queryDate(
      this.props.range[0],
    )}&interval=total&limit=2000&instanceid=${instanceId}&authToken=${getToken()}`;
    if (isSwitchAccount() && !isMultiAccountUser()) {
      exportUrl += `&switchUser=${getSwitchAccountUser(true).username}`;
    }
    if (isMultiAccountUser() && isSwitchAccount()) {
      exportUrl += `&switchAccount=${getAccount().accountId.trim()}`;
    }
    exportUrl += `&switchRegion=${getRegion()}`;
    window.open(exportUrl);
  }

  render() {
    return Object.keys(this.state.data)
      .filter(this.rowFilter)
      .map(asset => {
        let purchaseAmount = null;
        if (asset === 'all' || asset === 'faceted' || asset === 'instant') {
          purchaseAmount = this.state.transformedStats?.[asset]?.purchase?.stats
            ?.amount?.count;
        } else {
          purchaseAmount = this.state.transformedStats?.search?.purchase?.stats
            ?.amount?.count;
        }
        const data = this.state.data[asset];
        const type =
          asset === 'all' ? 'all' : asset === 'faceted' ? 'faceted' : 'instant';
        return (
          <SearchReportRow
            key={`${this.props.params?.report ?? 'ALL'}-${asset}`}
            data={data}
            loaded={this.state.isLoaded}
            type={type}
            title={statTitles[asset].title}
            subtitle={statTitles[asset].subtitle}
            devices={this.state.campaignDevices}
            purchaseAmount={purchaseAmount}
          />
        );
      });
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  user: store.user.user,
  update: store.filters.update,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  reset: store.filters.reset,
});

export default connect(mapStatesToProps)(ExecutiveSearchReport);
