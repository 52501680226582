/* eslint-disable no-useless-return */
/* eslint-disable react/jsx-indent */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable prefer-const */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { connect } from 'react-redux';

import { campaignActions, uiActions } from '../actions';
import Icon from '../components/icon';
import { status } from '../constants/datamaps/campaigns';
import StatusFilters from '../components/filters/status-filters';
import {
  csSearchParams,
  recommendationSortingTypes as sortingTypes,
  itemsPerScroll,
} from '../components/reports/utils';
import WidgetCard from '../components/widget/card';
import { filterStatus } from '../modules/campaigns/filters';
import { getRangedReports } from '../modules/reports/ajax';
import { setTitle } from '../system/document';
import { t } from '../system/ui';
import { hasOnlyDynamicBundles } from '../modules/auth/user';

const campaignRevenues = {};

class AllRecommendations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      scrollCount: 0,
      campaigns: [],
      _campaigns: [],
      deviceStatusFilteredCampaigns: [],
      title: 'Live',
      showOptions: false,
      sort: 'DESC',
      sortType: 'revenue',
      toggleIcon: 'preferences',
      reports: {},
      defaultStatus: false,
      campaignsInited: false,
      throttleTimer: null,
    };

    this.toggleOptions = this.toggleOptions.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.changeMedium = this.changeMedium.bind(this);

    this.searchHandler = this.searchHandler.bind(this);

    this.update = this.update.bind(this);
    this.sortBy = this.sortBy.bind(this);

    this.handleInfiniteScroll = this.handleInfiniteScroll.bind(this);
    this.throttle = this.throttle.bind(this);
    this.removeInfiniteScroll = this.removeInfiniteScroll.bind(this);
  }

  componentDidMount() {
    setTitle(t('All Recommendations'));
    campaignActions.filterActiveCampaigns();
    campaignActions.filterMedium([]);
    window.addEventListener('scroll', this.handleInfiniteScroll);
  }

  /**
   * This function handle the infinite scroll by increasing the `page` number to add new `Recommendation Report`
   * to the container when the reached the end of the page.
   * @name Handle Infinite Scroll
   * @returns {void}
   * @function
   * @see {@link getRangedReports} for increment the `page` number.
   */
  handleInfiniteScroll() {
    this.throttle(() => {
      const endOfPage =
        window.innerHeight + window.scrollY >= document.body.scrollHeight;
      if (endOfPage) {
        this.setState(
          {
            scrollCount: this.state.scrollCount + 1,
          },
          () => {
            this.sortBy('scroll', false, false);
          },
        );
      }
      if (this.state.campaigns.length === this.state.count) {
        this.removeInfiniteScroll();
      }
    }, 1000);
  }

  /**
   * Slowing down the number of calls using a throttle function.
   * @name Throttle
   * @param {function} callback
   * @param {number} time
   * @returns {void}
   * @function
   */
  throttle(callback, time) {
    if (this.throttleTimer) return;
    this.throttleTimer = true;
    setTimeout(() => {
      callback();
      this.throttleTimer = false;
    }, time);
  }

  /**
   * This function, will remove the handleInfiniteScroll function from the scroll event listener.
   * @name Remove Infinite Scroll
   * @returns {void}
   * @function
   */
  removeInfiniteScroll() {
    uiActions.isLoaded();
    window.removeEventListener('scroll', this.handleInfiniteScroll);
  }

  componentWillReceiveProps(newProps) {
    window.addEventListener('scroll', this.handleInfiniteScroll);
    if (this.props.user !== newProps.user) {
      this.changeStatus(
        this.props.status,
        this.props.medium,
        false,
        newProps.campaigns,
      );
    }

    if (this.props.campaigns !== newProps.campaigns) {
      this.update(newProps.campaigns, newProps.defaultStatus, newProps.medium);
    }

    if (newProps.update === true) {
      // this.update(newProps.campaigns);
    }

    if (newProps.status !== this.props.status) {
      this.changeStatus(
        newProps.status,
        newProps.medium,
        false,
        newProps.campaigns,
      );
    }

    if (newProps.medium !== this.props.medium) {
      this.changeMedium(newProps.medium, newProps.status);
    }
    if (newProps.campaignRevenues !== this.props.campaignRevenues) {
      const campaignId = newProps.campaignRevenues.campaign.id;
      campaignRevenues[campaignId] = newProps.campaignRevenues.campaign.revenue;
    }
  }

  update(allCampaigns, defaultStatus, medium) {
    uiActions.isLoading();
    const campaignStatusMap = {
      Test: 'TEST',
    };
    let deviceStatusFilteredCampaigns = filterStatus(
      allCampaigns,
      defaultStatus ? campaignStatusMap[defaultStatus] : 'ACTIVE',
    );
    deviceStatusFilteredCampaigns = this.getFilteredMediums(
      deviceStatusFilteredCampaigns,
      medium,
    );

    if (defaultStatus) {
      this.setState(
        {
          _campaigns: allCampaigns,
          defaultStatus,
          deviceStatusFilteredCampaigns,
        },
        () => {
          this.sortBy('statusOrMediumChange');
        },
      );
      const actionKey = `filter${defaultStatus}Campaigns`;
      campaignActions[actionKey];
    } else {
      this.setState(
        {
          _campaigns: allCampaigns,
          defaultStatus: false,
          deviceStatusFilteredCampaigns,
        },
        () => {
          this.sortBy('statusOrMediumChange');
        },
      );
      campaignActions.filterActiveCampaigns();
    }
    uiActions.isLoaded();
  }

  changeStatus(value, medium, initial, campaigns) {
    let val = value;
    if (medium === 'ALL') {
      medium = [];
    }
    if (initial) {
      val = 'ACTIVE';
    }
    const list = filterStatus(campaigns, val);
    const finalList = this.getFilteredMediums(list, medium);

    this.setState(
      {
        title: status[val],
        deviceStatusFilteredCampaigns: finalList,
      },
      () => {
        // Call SortBy
        this.sortBy('statusOrMediumChange');
      },
    );
  }

  changeMedium(value, status) {
    if (value === 'ALL') {
      value = [];
    }
    const list = filterStatus(this.props.campaigns, status);
    const finalList = this.getFilteredMediums(list, value);
    this.setState(
      {
        deviceStatusFilteredCampaigns: finalList,
      },
      () => {
        // Call SortBy
        this.sortBy('statusOrMediumChange');
      },
    );
  }

  sortBy(source, eventParam, e) {
    if (e) {
      e.preventDefault();
    }
    let sortType;
    let sortOrder;
    let scrollCount;
    let deviceStatusFilteredCampaigns;
    let { campaigns } = this.state;
    const reverseCampaigns = false;
    const matchedCampaigns = [];
    const search = this.refs.search && this.refs.search.value;

    const isFiltered = hasOnlyDynamicBundles();

    if (search) {
      this.state.deviceStatusFilteredCampaigns.map(item => {
        if (
          item.scenarioName.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          item.instanceId.toLowerCase().indexOf(search.toLowerCase()) > -1
        ) {
          matchedCampaigns.push(item);
        }
      });
      deviceStatusFilteredCampaigns = matchedCampaigns;
    } else {
      deviceStatusFilteredCampaigns = this.state.deviceStatusFilteredCampaigns;
    }

    if (
      source === 'statusOrMediumChange' ||
      source === 'search' ||
      source === 'scroll'
    ) {
      sortType = this.state.sortType;
      sortOrder = this.state.sort;
      scrollCount = source === 'scroll' ? this.state.scrollCount : 0;
    } else {
      // source is checkbox selected
      sortType = eventParam;
      scrollCount = 0;
      if (this.state.sortType !== sortType) {
        sortOrder = 'DESC';
      } else {
        sortOrder = this.state.sort === 'ASC' ? 'DESC' : 'ASC';
      }
    }

    if (source !== 'scroll') {
      campaigns = [];
    }

    const reportIds = deviceStatusFilteredCampaigns.map(cmp => {
      return cmp.instanceId;
    });

    if (deviceStatusFilteredCampaigns.length) {
      if (csSearchParams.indexOf(sortType) < 0) {
        // Server side sorting
        if (
          itemsPerScroll * (scrollCount + 1) - itemsPerScroll <
          deviceStatusFilteredCampaigns.length
        ) {
          uiActions.isLoading();

          /**
           * @name Get Ranged Reports
           */
          getRangedReports(
            reportIds,
            'lastSevenDays',
            campaignReports => {
              uiActions.isLoaded();
              let newCampaigns = Object.keys(campaignReports).map(
                reportCampaignId => {
                  const targetCampaign = deviceStatusFilteredCampaigns.filter(
                    cmp => {
                      return cmp.instanceId === reportCampaignId;
                    },
                  );
                  return targetCampaign[0];
                },
              );

              if (isFiltered) {
                newCampaigns = newCampaigns.filter(item => {
                  return (
                    item.templateId === 'HUR_8' || item.templateId === 'T16'
                  );
                });
              }

              this.setState({
                reports: { ...this.state.reports, ...campaignReports },
                campaigns: campaigns.concat(newCampaigns),
                count: isFiltered
                  ? campaigns.concat(newCampaigns).length
                  : deviceStatusFilteredCampaigns.length,
                sort: sortOrder,
                sortType,
                scrollCount,
                showOptions: false,
                toggleIcon: 'preferences',
                campaignsInited: true,
              });
            },
            {
              items: isFiltered ? 1000 : itemsPerScroll,
              page: scrollCount + 1,
              sortBy: sortType,
              reverse: sortOrder === 'DESC',
            },
            true,
          );
        }
      } else {
        // Client Side Sorting
        const campaignsByDate = [...deviceStatusFilteredCampaigns].sort(
          (a, b) => {
            if (sortOrder === 'DESC') {
              return (
                b.limitations.activeDates.startDate -
                a.limitations.activeDates.startDate
              );
            }
            return (
              a.limitations.activeDates.startDate -
              b.limitations.activeDates.startDate
            );
          },
        );
        const campaignIdsToGetReport = [];
        const latestExistingCampaignIndex = campaigns.length;
        const newCampaigns = campaignsByDate.slice(
          latestExistingCampaignIndex,
          latestExistingCampaignIndex + itemsPerScroll,
        );
        let campaignsToShow = campaigns.concat(newCampaigns);

        campaignsToShow.forEach(campaign => {
          if (!this.state.reports[campaign.instanceId]) {
            campaignIdsToGetReport.push(campaign.instanceId);
          }
        });

        if (isFiltered) {
          campaignsToShow = campaignsToShow.filter(item => {
            return item.templateId === 'HUR_8' || item.templateId === 'T16';
          });
        }

        this.setState(
          {
            campaigns: campaignsToShow,
            count: campaignsToShow.length,
            sort: sortOrder,
            sortType,
            scrollCount,
            showOptions: false,
            toggleIcon: 'preferences',
            campaignsInited: true,
          },
          () => {
            if (campaignIdsToGetReport.length) {
              uiActions.isLoading();
              getRangedReports(
                campaignIdsToGetReport,
                'weekly',
                campaignReports => {
                  uiActions.isLoaded();
                  this.setState({
                    reports: {
                      ...this.state.reports,
                      ...campaignReports,
                    },
                  });
                },
                {},
                true,
              );
            }
          },
        );
      }
    } else {
      this.setState({
        reports: {},
        campaigns: [],
        count: deviceStatusFilteredCampaigns.length,
        sort: sortOrder,
        sortType,
        scrollCount,
        showOptions: false,
        toggleIcon: 'preferences',
        campaignsInited: true,
      });
    }
  }

  searchHandler(e) {
    this.setState(
      {
        scrollCount: 0,
      },
      () => {
        this.sortBy('search');
      },
    );
  }

  toggleOptions(e) {
    e.preventDefault();

    this.setState({
      showOptions: !this.state.showOptions,
      toggleIcon: !this.state.showOptions ? 'cross' : 'preferences',
    });
  }

  getFilteredMediums(campaigns, value) {
    const visibleCampaigns = campaigns;
    let filteredMediums;
    let search = ['ALL'];

    search = search.concat(value);

    if (search.indexOf('PCTABLET') > -1) {
      search = search.concat(['PC', 'TABLET', 'PCMOBILETABLET']);
    }

    if (search.indexOf('MOBILE') > -1) {
      search = search.concat(['MOBILETABLET']);
      search = search.concat(['PCMOBILETABLET']);
    }

    if (search.indexOf('ANDROID') > -1 || search.indexOf('IOS') > -1) {
      search = search.concat(['ANDROIDIOS']);
    }

    if (search.length === 1) {
      search = [
        'ALL',
        'PCTABLET',
        'PC',
        'TABLET',
        'PCMOBILETABLET',
        'MOBILETABLET',
        'MOBILE',
        'ANDROID',
        'IOS',
        'ANDROIDIOS',
      ];
    }

    filteredMediums = visibleCampaigns.filter(item => {
      return search.indexOf(item.device) > -1;
    });

    return filteredMediums;
  }

  render() {
    const campaignsStatus = this.props.status;
    let statusText = '';
    if (campaignsStatus === 'ACTIVE') {
      statusText = t('Live');
    } else if (campaignsStatus === 'TEST') {
      statusText = t('Test');
    } else {
      statusText = t('Archived');
    }
    if (!this.state.campaignsInited) {
      return this.props.placeHolder;
    }
    return (
      <div>
        <h2 className='page-title'>
          {t(this.state.title)}
          <span className='emphasise'>({this.state.count})</span>
        </h2>

        <div className='page-options page-options-sort-search'>
          <label className='item is-stacked is-search'>
            <Icon name='magnify' />
            <input
              type='text'
              placeholder={t('Search')}
              className='text-field'
              onChange={this.searchHandler}
              ref='search'
            />
          </label>
          <div className='data-preferences'>
            <a href='#' className='toggler' onClick={this.toggleOptions}>
              <Icon name={this.state.toggleIcon} />
              <span>Sort</span>
            </a>
            <ul
              className={
                !this.state.showOptions
                  ? 'data-preferences-options is-hidden'
                  : 'data-preferences-options'
              }
            >
              {sortingTypes.map(sortingType => {
                return (
                  <li
                    className='data-preferences-option'
                    key={sortingType.type}
                    onClick={this.sortBy.bind(this, 'event', sortingType.type)}
                  >
                    <a href='#' className='data-preferences-option-item'>
                      {sortingType.text}
                      {this.state.sortType === sortingType.type ? (
                        this.state.sort !== 'DESC' ? (
                          <i className='icon-caret-up viewall-sorting' />
                        ) : (
                          <i className='icon-caret-down viewall-sorting' />
                        )
                      ) : (
                        ''
                      )}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className='page-filters has-filter-form'>
          <StatusFilters
            defaultStatus={
              this.state.defaultStatus ? this.state.defaultStatus : false
            }
          />
        </div>

        <div className='page-content-wrapper'>
          {this.state.campaigns.length
            ? this.state.campaigns.map(campaign => {
                return (
                  <WidgetCard
                    templates={this.state.templates}
                    key={campaign?.instanceId}
                    report={this.state.reports[campaign?.instanceId]}
                    getCampaigns={this.props.getCampaigns}
                    getCampaignsAfterArchive={this.getCampaignsAfterArchive}
                    {...campaign}
                    {...this.props}
                    campaign={campaign}
                    withReport
                    campaignType={this.props.campaignType}
                    campaignsInited={this.state.campaignsInited}
                    idsInAB={this.props.idsInAB}
                  />
                );
              })
            : this.props.ui !== 'isLoading' && (
                <div className='no-report'>
                  <p className='no-report-info'>
                    {t('no-campaign-info', statusText)}
                  </p>
                </div>
              )}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  ui: store.ui.ui,
  update: store.campaigns.update,
  status: store.campaigns.status,
  medium: store.campaigns.medium,
  campaignRevenues: store.campaigns.campaignRevenues,
});

export default connect(mapStatesToProps)(AllRecommendations);
