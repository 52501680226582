import React from "react";
import SegmentReport from "./segment.report";
import {t} from "../system/ui";

class SingleSegmentationReport extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <h2 className="page-title">{t("Segment Report")}</h2>
                <SegmentReport {...this.props} key="segment-summary"/>
            </div>
        );
    }
}

export default SingleSegmentationReport;

