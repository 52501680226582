import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const RocketIcon = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 18 17'
      fill='none'
    >
      <path d='M16.5086 2.32265C16.2398 1.45785 15.5625 0.780808 14.6976 0.512378L14.0355 0.306919C11.642 -0.435911 9.03397 0.208508 7.26177 1.98062L6.26597 2.9765C4.89619 1.94786 2.94313 2.0567 1.69682 3.30302L0.454294 4.54555C0.161394 4.83844 0.161394 5.31331 0.454294 5.60621L2.04526 7.19718L1.86545 7.37699C1.18203 8.0604 1.18203 9.1684 1.86545 9.8519L2.3608 10.3472L0.965244 11.1432C0.762094 11.259 0.624624 11.463 0.593494 11.6948C0.562354 11.9266 0.641124 12.1596 0.806484 12.325L4.69559 16.2141C4.86083 16.3793 5.09367 16.4581 5.32527 16.4271C5.55697 16.3962 5.76087 16.259 5.87697 16.0562L6.67487 14.6613L7.17207 15.1584C7.85547 15.8419 8.96347 15.8419 9.64687 15.1584L9.82367 14.9816L11.4133 16.5712C11.7062 16.8641 12.1811 16.8641 12.474 16.5712L13.7165 15.3287C14.9623 14.0829 15.0716 12.1308 14.0442 10.7612L15.0421 9.7632C16.8149 7.99043 17.4591 5.38111 16.715 2.98696L16.5086 2.32265ZM14.253 1.94498C14.6461 2.06699 14.954 2.37473 15.0762 2.76783L15.2826 3.43213C15.8613 5.29426 15.3603 7.32372 13.9815 8.7026L8.58627 14.0978C8.48857 14.1954 8.33027 14.1954 8.23267 14.0978L2.92611 8.7912C2.82848 8.6936 2.82848 8.5353 2.92611 8.4377L8.32247 3.04128C9.70077 1.66297 11.7293 1.16176 13.5909 1.73951L14.253 1.94498ZM12.9641 11.8413C13.4166 12.607 13.3139 13.61 12.6558 14.2681L11.9436 14.9803L10.8844 13.921L12.9641 11.8413ZM2.75748 4.36368C3.41599 3.70516 4.42002 3.60275 5.18597 4.05644L3.10592 6.13652L2.04528 5.07588L2.75748 4.36368ZM5.57557 13.562L5.06227 14.4594L2.56206 11.9592L3.46044 11.4469L5.57557 13.562Z' />
    </svg>
  );
};

RocketIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

RocketIcon.defaultProps = {
  width: '18px',
  height: '17px',
};

export default RocketIcon;
