/**
 * Created by mehmetyurtar on 3/5/18.
 */
import { createAction } from "../system/store";

export const setLabels = options => {
  createAction("SET_LABELS", options);
};

export const removeLabels = () => {
  createAction("REMOVE_LABELS");
};

export default {
  setLabels,
  removeLabels
};
