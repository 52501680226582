import { clone } from '../../system/object';

const trendsMap = {
  widget: {
    stats: {
      view: {
        name: 'Actual View',
        count: 0,
      },
      all: {
        count: 0,
        total: true,
        name: 'Total View',
      },
      bounceBack: {
        name: 'Bounce Back',
        HardBounce: 0,
        SoftBounce: 0,
        total: 0,
      },
      spam: {
        name: 'Spam',
        total: 0,
      },
      unsubscribed: {
        name: 'Unsubscribed',
        total: 0,
      },
      conversion: {
        name: 'Conversion',
        ratio: 0,
      },
    },
    modal: 'Widget',
  },
  interaction: {
    stats: {
      all: {
        count: 0,
        total: true,
      },
      conversion: {
        name: 'Conversion',
        ratio: 0,
      },
    },
    modal: 'Interaction',
  },
  basket: {
    stats: {
      all: {
        count: 0,
        total: true,
      },
      amount: {
        name: 'Total Amount',
        count: 0,
        currency: true,
      },
      conversion: {
        name: 'Conversion',
        ratio: 0,
      },
    },
    modal: 'Basket',
  },
  revenue: {
    stats: {
      all: {
        count: 0,
        total: true,
        currency: true,
      },
      purchases: {
        name: 'Purchases',
        count: 0,
      },
      items: {
        name: 'Purchase Items',
        count: 0,
      },
      average: {
        name: 'Average Order Value',
        count: 0,
        currency: true,
      },
      conversion: {
        name: 'Conversion',
        ratio: 0,
      },
      contribution: {
        name: 'Contribution Ratio',
        ratio: 0,
      },
    },
    modal: 'Revenue',
  },
};

const assistedRevenueTrend = {
  stats: {
    all: {
      count: 0,
      total: true,
      currency: true,
    },
    purchases: {
      name: 'Purchases',
      count: 0,
    },
    items: {
      name: 'Purchase Items',
      count: 0,
    },
    average: {
      name: 'Average Order Value',
      count: 0,
      currency: true,
    },
    conversion: {
      name: 'Conversion',
      ratio: 0,
    },
    contribution: {
      name: 'Contribution Ratio',
      ratio: 0,
    },
  },
  accessor: {
    purchases: 'aRPurchases',
    purchasedItems: 'aRPurchasedItems',
    purchaseAmount: 'aRPurchaseAmount',
    purchaseAmountPrev: 'aRpurchaseAmountPrev',
    'purchaseAmountPrev#': 'aRpurchase:amountPrev',
  },
  modal: 'AssistedRevenue',
  modalTitle: 'Assisted Revenue',
};

const trendsInteractionOrder = [
  'all',
  'leftArrowClick',
  'rightArrowClick',
  'conversion',
];

const permissionTrendsMap = {
  widget: {
    stats: {
      view: {
        name: 'Actual View',
        count: 0,
      },
      all: {
        count: 0,
        total: true,
        name: 'Total View',
      },
      conversion: {
        name: 'Conversion',
        ratio: 0,
      },
    },
    modal: 'Widget',
  },
  grantedPermission: {
    stats: {
      granted: {
        segmentifyCount: 0,
        nonSegmentifyCount: 0,
        name: 'Granted',
      },
      all: {
        segmentifyCount: 0,
        nonSegmentifyCount: 0,
        name: 'All',
      },
    },
  },
  deniedPermission: {
    stats: {
      denied: {
        count: 0,
        name: 'Denied',
      },
      all: {
        count: 0,
        name: 'All',
      },
    },
  },
};

const trendsMapWithDiff = clone(trendsMap);

const trendsMapWithKlaviyo = clone(trendsMap);

const trendsMapWithPageView = clone(trendsMap);

trendsMapWithDiff.revenue.stats = {
  ...trendsMapWithDiff.revenue.stats,
  diff: {
    name: 'Diff',
    ratio: 0,
  },
};

trendsMapWithKlaviyo.widget.stats = {
  ...trendsMapWithKlaviyo.widget.stats,
  klaviyo: {
    profile: 0,
    flow: 0,
    name: 'Klaviyo',
  },
};

trendsMapWithPageView.widget.stats = {
  ...trendsMapWithPageView.widget.stats,
  pageView: {
    name: 'Page View',
    count: 0,
  },
  impressionRate: {
    name: 'Impression Rate',
    ratio: 0,
  },
};

export {
  trendsMap,
  trendsMapWithPageView,
  trendsMapWithDiff,
  permissionTrendsMap,
  trendsMapWithKlaviyo,
  trendsInteractionOrder,
  assistedRevenueTrend,
};
