import React, { useEffect, useRef, useState } from 'react';

import { isSuperUser } from '../../../modules/auth/user';
import Icons from '../../icons';
import Icon from '../../icon';
import { uiActions } from '../../../actions';
import { deleteSTL } from '../../../views/dynamic-bundle/shop-the-look/services/shopTheLookServices';
import PromotionServices from '../../../views/recommendation/promotion-list/services/promotionServices';

/**
 * @name SFYListActions component
 * @description Actions component displays list of actions. Actions passed via props
 * @param {string} bundleName - name of bundle
 * @param {string} bundleId - id of bundle
 * @param {array} actions
 * @param {function} listInvoker - function to invoke list re-renders
 * @return {JSX.Element}
 * @constructor
 */
const SFYListActions = ({
  actions,
  id,
  bundleName,
  listInvoker,
  trashBin,
  itemType,
}) => {
  const [dropUp, setDropUp] = useState(false);
  const actionMenu = useRef(null);
  const { confirmationDialog } = uiActions;

  const getListClass = () => {
    if (dropUp) {
      return 'widget-nav-popup action-nav-popup drop-up action-bottom';
    }
    return 'widget-nav-popup action-nav-popup action-bottom';
  };

  const getControlledActions = () => {
    if (dropUp) {
      return actions.slice().reverse();
    }
    return actions;
  };

  const removeProduct = () => {
    confirmationDialog({
      buttonConfirm: {
        className: 'dialog-delete-button',
        text: 'Delete',
      },
      title: (
        <span>
          {bundleName ? <>Remove the Product ?</> : <>Remove the Promotion ?</>}
        </span>
      ),
      content: (
        <span>
          {bundleName ? (
            <>
              Are you sure you want to remove <b> {bundleName} </b> bundles ?
            </>
          ) : (
            <>
              Are you sure you want to remove this <br /> promotion ?
            </>
          )}
        </span>
      ),
      onConfirm: () => {
        if (bundleName) {
          deleteSTL({ id }).then(() => {
            listInvoker();
          });
          return;
        }
        PromotionServices.deletePromotion({ id }).then(() => {
          listInvoker();
        });
      },
    });
  };

  const handleScroll = () => {
    const partOfDocHeight = (document.documentElement.offsetHeight * 70) / 100;
    const listTop = actionMenu.current.getBoundingClientRect().top;
    setDropUp(listTop > partOfDocHeight);
  };

  const handleClick = (e, buttonAction) => {
    e.stopPropagation();
    switch (buttonAction) {
      case 'remove':
        removeProduct();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className='widget-nav action-nav'
      aria-haspopup='true'
      ref={actionMenu}
    >
      <a style={{ color: '#547494' }}>
        {trashBin ? (
          <Icons name='trashBin' color='#D31116' />
        ) : (
          <Icon name='menu' />
        )}
      </a>

      <ul className={getListClass()}>
        {getControlledActions().map((action, index) => {
          if (!isSuperUser() && action.superUserOnly) {
            return null;
          }

          if (action.action === 'addToArchive' && action.status === 'PASSIVE') {
            return null;
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${action.segmentId}-${index}`}>
              <a
                className='widget-nav-popup-item action-nav-popup-item action-nav-flex'
                onClick={e => handleClick(e, action.action)}
              >
                {action.iconSvg ? (
                  <Icons name={action.icon} color={action.colorName} />
                ) : (
                  <Icon name={action.icon} />
                )}
                Remove Bundle
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default SFYListActions;
