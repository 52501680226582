import Services from '../../../../service/Services';

class GamificationServices extends Services {
  constructor({ endPoint, paginationIsActive }) {
    super({ endPoint, paginationIsActive });
  }
}

const GamificationService = (
  endPoint = 'gamification/campaign',
  paginationIsActive = false,
) => {
  return new GamificationServices({
    endPoint,
    paginationIsActive,
  });
};

export { GamificationService };
