import classNames from 'classnames';
import React, { Component } from 'react';
import { modalActions } from '../../actions';
import { notification, t } from '../../system/ui';
import { sendTestEmail } from './ajax';

class SendTestModalKlaviyo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testTypes: [
        {
          label: 'UI Test',
          value: 'UI',
        },
      ],
      testType: 'UI',
      mails: [],
      inputValue: '',
      hasError: true,
      sendToTrackingApi: true,
      sendToIdentifyApi: false,
    };

    this.sendTest = this.sendTest.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onRemoveMail = this.onRemoveMail.bind(this);
    this.toggleInput = this.toggleInput.bind(this);
    this.toggleInput1 = this.toggleInput1.bind(this);
  }

  sendTest() {
    const data = {};
    data.campaign = this.props.instanceId;
    data.mails = this.state.mails;
    data.additionalSubjects = this.props.additionalSubjects;
    data.additionalContents = this.props.additionalContents;
    data.sendToIdentifyApi = this.state.sendToIdentifyApi;
    data.sendToTrackingApi = this.state.sendToTrackingApi;

    // Send Test Email - data;
    sendTestEmail(data, response => {
      if (response) {
        modalActions.closeModal();
        let note;
        if (data.type === 'UI') {
          note = 'Test email sent. Please check your inbox!';
        } else {
          note =
            'Users are in test mode now. Actualize the scenario conditions and check your inbox!';
        }
        const notificationText = () => {
          return <div>{t(note)}</div>;
        };
        notification({
          content: notificationText,
        });
      }
    });
  }

  onInputChange(e) {
    const val = e.target.value;
    const isProperEmail = modalActions.isProperEmail(val);
    if (isProperEmail) {
      this.setState({
        inputValue: val,
        hasError: false,
      });
    } else {
      this.setState({
        inputValue: val,
        hasError: true,
      });
    }
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { inputValue } = this.state;
    if (this.state.mails.indexOf(inputValue) < 0 && !this.state.hasError) {
      this.setState({
        mails: this.state.mails.concat([inputValue]),
        inputValue: '',
        hasError: true,
      });
    }
  }

  onRemoveMail(mailToRemove, e) {
    e.preventDefault();
    this.setState({
      mails: this.state.mails.filter(mail => mail !== mailToRemove),
    });
  }

  toggleInput() {
    this.setState({
      sendToTrackingApi: !this.state.sendToTrackingApi,
    });
  }

  toggleInput1() {
    this.setState({
      sendToIdentifyApi: !this.state.sendToIdentifyApi,
    });
  }

  render() {
    const isInvalid = this.state.hasError;
    return (
      <div style={{ minWidth: '800px' }}>
        <div style={{ marginBottom: '20px' }}>
          <form
            id='settings-form'
            onSubmit={this.onFormSubmit}
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            <ol className='form-elements'>
              <li
                className={
                  isInvalid
                    ? 'form-element form-element-testMail form-element-testMailErr'
                    : 'form-element form-element-testMail'
                }
              >
                <label className='item item-stacked one-whole'>
                  <input
                    type='text'
                    className='text-field'
                    onChange={this.onInputChange}
                    value={this.state.inputValue}
                    placeholder='Add E-Mail Address'
                  />
                </label>
                <button type='submit' onClick={this.onFormSubmit}>
                  +
                </button>
              </li>
            </ol>
          </form>
          <h4 className='item-label'>Emails</h4>
          <ul style={{ padding: 0 }}>
            {this.state.mails.map(mail => {
              return (
                <li className='multiselect--item__selected tfy-selected-category'>
                  <i
                    className='icon-cross'
                    onClick={this.onRemoveMail.bind(this, mail)}
                  />
                  <span>{mail}</span>
                </li>
              );
            })}
          </ul>

          <span
            key='sendToTrackingApi'
            className='label-mail label-text-field disabled'
          >
            <label className='item item-stacked is-checkbox'>
              <input
                type='checkbox'
                name='sendToTrackingApi'
                checked={this.state.sendToTrackingApi}
              />
              <span className='item-label'>{t('Trigger To Flow')}</span>
            </label>
          </span>

          <span
            key='sendToIdentifyApi'
            className='label-mail label-text-field'
            style={{ marginLeft: '20px' }}
          >
            <label className='item item-stacked is-checkbox'>
              <input
                type='checkbox'
                name='sendToIdentifyApi'
                checked={this.state.sendToIdentifyApi}
                onChange={this.toggleInput1}
              />
              <span className='item-label'>{t('Sync To Profile')}</span>
            </label>
          </span>
        </div>
        <button
          className={classNames('tertiary-action', {
            disabled: this.state.mails.length === 0,
          })}
          onClick={this.sendTest}
          style={{
            float: 'right',
            marginTop: '30px',
            pointerEvents: this.state.mails.length === 0 ? 'none' : 'auto',
          }}
        >
          {t('Send Test')}
        </button>
      </div>
    );
  }
}

export default SendTestModalKlaviyo;
