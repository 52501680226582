import React from "react";
import { Link } from "react-router";
import classNames from "classnames";
import { searchAlgorithmTitles } from "../../constants/datamaps/wizard";
import { idPropFunc } from "../../system/id";

class SearchSelection extends React.Component {
  constructor(props) {
    super(props);
  }

  getCustomizationIcon() {
    return (
      <span key='searchbox-customization'>
        <Link
          to={{
            pathname: '/search/new/instant-rule-set',
            state: { campaigns: this.props.searchCampaigns },
          }}
          className={classNames(
            "available-type",
            "list-email",
          )}
        >
          <h4 className="available-type-name">Customisation</h4>
          <span className="available-type-wireframe customization"/>
        </Link>
      </span>
    );
  }

  render() {

    let searchType = this.props.searchType;
    let campaigns = this.props.searchGroups[searchType];
    return (
      <div
        className={classNames(
          "available-types available-widgets available-widgets-mail"
        )}
      >
        <h3 className="widget-title-head">Search Box</h3>
        <p className="widget-title-desc">
          Create personalized search experience with Segmentify's intelligent algorithm
        </p>
        <div className="available-type-selection">
          {campaigns && <>
            {campaigns.map((campaign) => {
              let isNew = this.props.allSearchs.indexOf(campaign) < 0;
              let link = isNew
                ? "/search/add/" + campaign.toLowerCase()
                : "/search/edit/" + campaign.toLowerCase();
              let isLive = this.props.liveSearches.indexOf(campaign) > -1;
              let isTest = this.props.testSearches.indexOf(campaign) > -1;
              let comps = [
                <span key={campaign} {...idPropFunc("scsSs" , campaign)}>
                <Link
                  to={{ pathname: link, state: { setInitialData: isNew } }}
                  className={classNames(
                    "available-type",
                    "list-email",
                    {
                      "list-email-live": isLive,
                      "list-email-test": isTest
                    }
                  )}
                >
                  <h4 className="available-type-name">
                    {searchAlgorithmTitles(campaign).campaignTitle}
                  </h4>
                  <span className="available-type-wireframe search">
                    {isLive && (
                      <div className="list-email-live-label">Live</div>
                    )}
                    {isTest && (
                      <div className="list-email-test-label">Test</div>
                    )}
                  </span>
                  <p className="list-email-desc">
                    {searchAlgorithmTitles(campaign).description}
                  </p>
                </Link>
              </span>,
              ];
              return comps;
            })}
            {this.getCustomizationIcon()}
          </>}
        </div>
      </div>
    );
  }
}
export default SearchSelection;
