const FACETED_SEARCH_LOADING = 'FACETED_SEARCH/LOADING';
const FACETED_SEARCH_SUCCESS = 'FACETED_SEARCH/SUCCESS';
const FACETED_SEARCH_ERROR = 'FACETED_SEARCH/ERROR';
const FACETED_SEARCH_TOGGLE_ADD_FACET_MODAL =
  'FACETED_SEARCH/TOGGLE_ADD_FACET_MODAL';
const FACETED_SEARCH_GET_FACET_LIST_LOADING =
  'FACETED_SEARCH/GET_FACET_LIST/LOADING';
const FACETED_SEARCH_GET_FACET_LIST_SUCCESS =
  'FACETED_SEARCH/GET_FACET_LIST/SUCCESS';
const FACETED_SEARCH_GET_FACET_LIST_ERROR =
  'FACETED_SEARCH/GET_FACET_LIST/ERROR';

const FACETED_SEARCH_GET_ORDERING_LIST_LOADING =
  'FACETED_SEARCH/GET_ORDERING_LIST/LOADING';
const FACETED_SEARCH_GET_ORDERING_LIST_SUCCESS =
  'FACETED_SEARCH/GET_ORDERING_LIST/SUCCESS';
const FACETED_SEARCH_GET_ORDERING_LIST_ERROR =
  'FACETED_SEARCH/GET_ORDERING_LIST/ERROR';
const FACETED_SEARCH_FORM_UPDATE = 'FACETED_SEARCH/FORM_UPDATE';
const FACETED_SEARCH_SAVE_LOADING = 'FACETED_SEARCH/SAVE/LOADING';
const FACETED_SEARCH_SAVE_SUCCESS = 'FACETED_SEARCH/SAVE/SUCCESS';
const FACETED_SEARCH_SAVE_ERROR = 'FACETED_SEARCH/SAVE/ERROR';

const FACETED_SEARCH_GET_SINGLE_REPORT_LOADING =
  'FACETED_SEARCH/GET_SINGLE_REPORT/LOADING';
const FACETED_SEARCH_GET_SINGLE_REPORT_SUCCESS =
  'FACETED_SEARCH/GET_SINGLE_REPORT/SUCCESS';
const FACETED_SEARCH_GET_SINGLE_REPORT_ERROR =
  'FACETED_SEARCH/GET_SINGLE_REPORT/ERROR';

const FACETED_SEARCH_CAMPAIGN_UPDATE_STATUS_LOADING =
  'FACETED_SEARCH/UPDATE_STATUS/LOADING';
const FACETED_SEARCH_CAMPAIGN_UPDATE_STATUS_SUCCESS =
  'FACETED_SEARCH/UPDATE_STATUS/SUCCESS';
const FACETED_SEARCH_CAMPAIGN_UPDATE_STATUS_ERROR =
  'FACETED_SEARCH/UPDATE_STATUS/ERROR';

const FACETED_SEARCH_ARCHIVE_CAMPAIGN = 'FACETED_SEARCH/ARCHIVE_CAMPAIGN';

const FACETED_SEARCH_CREATE_DRAFT_CAMPAIGN =
  'FACETED_SEARCH/CREATE_DRAFT_CAMPAIGN';

const FACETED_SEARCH_GET_ALL_REPORTS_LOADING =
  'FACETED_SEARCH/GET_ALL_REPORTS/LOADING';
const FACETED_SEARCH_GET_ALL_REPORTS_SUCCESS =
  'FACETED_SEARCH/GET_ALL_REPORTS/SUCCESS';
const FACETED_SEARCH_GET_ALL_REPORTS_ERROR =
  'FACETED_SEARCH/GET_ALL_REPORTS/ERROR';

export {
  FACETED_SEARCH_ERROR,
  FACETED_SEARCH_LOADING,
  FACETED_SEARCH_SUCCESS,
  FACETED_SEARCH_TOGGLE_ADD_FACET_MODAL,
  FACETED_SEARCH_GET_FACET_LIST_LOADING,
  FACETED_SEARCH_GET_FACET_LIST_SUCCESS,
  FACETED_SEARCH_GET_FACET_LIST_ERROR,
  FACETED_SEARCH_FORM_UPDATE,
  FACETED_SEARCH_SAVE_ERROR,
  FACETED_SEARCH_SAVE_LOADING,
  FACETED_SEARCH_SAVE_SUCCESS,
  FACETED_SEARCH_GET_ORDERING_LIST_LOADING,
  FACETED_SEARCH_GET_ORDERING_LIST_ERROR,
  FACETED_SEARCH_GET_ORDERING_LIST_SUCCESS,
  FACETED_SEARCH_GET_SINGLE_REPORT_ERROR,
  FACETED_SEARCH_GET_SINGLE_REPORT_LOADING,
  FACETED_SEARCH_GET_SINGLE_REPORT_SUCCESS,
  FACETED_SEARCH_CAMPAIGN_UPDATE_STATUS_ERROR,
  FACETED_SEARCH_CAMPAIGN_UPDATE_STATUS_LOADING,
  FACETED_SEARCH_CAMPAIGN_UPDATE_STATUS_SUCCESS,
  FACETED_SEARCH_ARCHIVE_CAMPAIGN,
  FACETED_SEARCH_CREATE_DRAFT_CAMPAIGN,
  FACETED_SEARCH_GET_ALL_REPORTS_ERROR,
  FACETED_SEARCH_GET_ALL_REPORTS_LOADING,
  FACETED_SEARCH_GET_ALL_REPORTS_SUCCESS,
};
