import React from 'react';

const EmailPreviewIcon = ({
  width = '16',
  height = '16',
  color = '#4B4B4E',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.5003 3.38862C9.16278 3.38862 10.5105 4.69055 10.5105 6.29653C10.5105 7.90258 9.16278 9.20446 7.5003 9.20446C5.83776 9.20446 4.49003 7.90258 4.49003 6.29653C4.49003 4.69055 5.83776 3.38862 7.5003 3.38862ZM7.5003 4.47907C6.46124 4.47907 5.61887 5.29278 5.61887 6.29653C5.61887 7.30027 6.46124 8.11398 7.5003 8.11398C8.53936 8.11398 9.3817 7.30027 9.3817 6.29653C9.3817 5.29278 8.53936 4.47907 7.5003 4.47907ZM7.5003 0.84082C10.9722 0.84082 13.9694 3.13082 14.801 6.34C14.8767 6.6321 14.6929 6.9282 14.3905 7.00134C14.0881 7.0744 13.7816 6.89694 13.7059 6.60477C12.9993 3.87812 10.4513 1.93129 7.5003 1.93129C4.54793 1.93129 1.99913 3.87979 1.29379 6.60811C1.21827 6.90021 0.91187 7.07789 0.609438 7.00497C0.307006 6.93198 0.123065 6.63603 0.198592 6.34386C1.02873 3.13279 4.02682 0.84082 7.5003 0.84082Z'
        fill={color}
      />
    </svg>
  );
};

export default EmailPreviewIcon;
