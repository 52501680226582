import React from 'react';
import { openModal } from '../system/ui';
import * as modals from '../constants/datamaps/segmentation-modals';
import Icon from '../components/icon';
import { report as SegmentPart } from '../components/segmentation/report';
import { segmentAlgorithmNames } from '../constants/datamaps/wizard';
import { createChart } from '../components/search/insights/utils/chart-utils';
import Table from '../components/segmentation/table/react-table';
import { getNowByTimeZone, queryDate } from '../system/date';
import { getAllStat } from '../modules/segmentation/ajax';
import {
  getDatasForSegment,
  getTableData,
  segmentPieChartConfig,
} from '../components/segmentation/utils/constant';
import { setTitle } from '../system/document';

const userChartData = {
  data: [],
  name: 'name',
  yLabel: 'count',
  yLabelName: '',
  align: 'center',
  type: 'pie',
  calculateTotalByYLabel: true,
  isPriceField: false,
  yLabelFormatConversionF: item => {
    return parseInt(item);
  },
};

const revenueChartData = {
  data: [],
  name: 'name',
  yLabel: 'count',
  yLabelName: '',
  align: 'center',
  type: 'pie',
  calculateTotalByYLabel: true,
  isPriceField: false,
  yLabelFormatConversionF: item => {
    return parseInt(item);
  },
};

const initialStat = {
  userCount: 0,
  revenue: 0,
  averageDaysBetweenOrders: 0,
  averageDiscountUsage: 0,
  averageOrderValue: 0,
  averageOrderCount: 0,
  averageProductPrice: 0,
  averageCustomerLifetimeValue: 0,
  averageDaysSinceLastOrder: 0,
  itemsPerOrder: 0,
  averageWaitingDays: 0,
  otherUserCount: 0,
  otherRevenue: 0,
  otherAverageDaysBetweenOrders: 0,
  otherAverageDiscountUsage: 0,
  otherAverageOrderValue: 0,
  otherAverageOrderCount: 0,
  otherAverageProductPrice: 0,
  otherAverageCustomerLifetimeValue: 0,
  otherAverageDaysSinceLastOrder: 0,
  otherItemsPerOrder: 0,
  otherAverageWaitingDays: 0,
};

class SegmentReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userPieConfig: {},
      revenuePieConfig: {},
      stat: initialStat,
    };

    this.showModal = this.showModal.bind(this);
    this.createTableColumns = this.createTableColumns.bind(this);
    this.getStatOfSegment = this.getStatOfSegment.bind(this);
  }

  createTableColumns(segmentName) {
    return [
      {
        Header: 'Metric',
        accessor: 'metric',
      },
      {
        Header: segmentName,
        accessor: 'segment',
      },
      {
        Header: 'Other Customers',
        accessor: 'other',
      },
    ];
  }

  componentDidMount() {
    this.getStatOfSegment(segmentAlgorithmNames(this.props.params.report));
  }

  getStatOfSegment(segmentName) {
    const opts = {};
    opts.start = queryDate(getNowByTimeZone().subtract(6, 'month'));
    opts.end = queryDate(getNowByTimeZone());

    getAllStat(response => {
      if (response) {
        const stat = response[segmentName]
          ? response[segmentName]
          : initialStat;

        userChartData.data = [
          { name: 'segment', count: stat.userCount },
          { name: 'other', count: stat.otherUserCount },
        ];
        const userCharDataConfig = createChart(
          userChartData,
          segmentPieChartConfig,
        );

        revenueChartData.data = [
          { name: 'revenue', count: stat.revenue },
          { name: 'other', count: stat.otherRevenue },
        ];
        const revenueChartDataConfig = createChart(
          revenueChartData,
          segmentPieChartConfig,
        );

        this.setState({
          stat,
          userPieConfig: userCharDataConfig,
          revenuePieConfig: revenueChartDataConfig,
        });
      }
    }, opts);
  }

  showModal(modal, e) {
    e.preventDefault();

    openModal({
      title: segmentAlgorithmNames(this.props.params.report),
      content: modals[modal],
      className: 'segmentation',
    });
  }

  render() {
    const segmentName = segmentAlgorithmNames(this.props.params.report);
    const data = getDatasForSegment(segmentName, this.state.stat);
    const tableData = getTableData(segmentName, this.state.stat);
    const tableHeaders = this.createTableColumns(segmentName);
    return (
      <div>
        <div className='page-content-block search__m_t_10'>
          <div className='widget-stat-list widget-report'>
            <div className='one-whole'>
              <h2 className='widget-title-segmentation'>
                {segmentName}
                <a onClick={e => this.showModal(this.props.params.report, e)}>
                  <Icon name='info' />
                </a>
              </h2>
            </div>

            <hr className='widget-separator' />

            <SegmentPart
              pieConfig={this.state.userPieConfig}
              segmentName={segmentName}
              position='UP'
              segmentUser={this.state.stat.userCount}
              otherUser={this.state.stat.otherUserCount}
              revenue={this.state.stat.revenue}
              otherRevenue={this.state.stat.otherRevenue}
              data={data}
            />

            <hr className='widget-separator' />

            <SegmentPart
              pieConfig={this.state.revenuePieConfig}
              segmentName={segmentName}
              position='DOWN'
              segmentUser={this.state.stat.userCount}
              otherUser={this.state.stat.otherUserCount}
              revenue={this.state.stat.revenue}
              otherRevenue={this.state.stat.otherRevenue}
              data={data}
            />
          </div>
        </div>

        <div className='page-content-block search__m_t_10'>
          <div className='widget-stat-list widget-report'>
            <div className='one-whole'>
              <h2 className='widget-title-segmentation'>Detailed Metrics</h2>
            </div>

            <hr className='widget-separator' />

            <Table columns={tableHeaders} data={tableData} />
          </div>
        </div>
      </div>
    );
  }
}

export default SegmentReport;
