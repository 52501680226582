import React from "react";

class IconEmailModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "27px",
      height: "17px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 26.5 16"
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
        className="emailIcon"
      >
        <path d="M10.5,3.6H1c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h13.6L10.5,3.6z" />
        <path d="M8.4,7.1c-1.9,0-5.8,0-5.8,0c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h5.8C8.4,5.1,7.8,5.9,8.4,7.1z" />
        <path d="M10,10.5H5.1c-0.5,0-1-0.4-1-1v0c0-0.5,0.4-1,1-1h4L10,10.5z" />
        <path d="M21.2,0L10,5.4C9.5,5.6,9.3,6.1,9.6,6.5l0.5,1.1l8.1,0.5l4.7-6.6l-0.6-1.2C22.1-0.1,21.6-0.2,21.2,0z" />
        <path
          d="M18.2,9.2l-7.6-0.5l3.2,6.6c0.2,0.5,0.7,0.6,1.2,0.4l11.2-5.3c0.5-0.2,0.6-0.7,0.4-1.2l-3.2-6.6L19,8.8
	C18.8,9,18.5,9.2,18.2,9.2z"
        />
      </svg>
    );
  }
}

export default IconEmailModule;
