import { allModules } from '../constants';

const setUpModules = selectedAccounts => {
  const allActiveModules = selectedAccounts.reduce((result, account) => {
    const accountModules = Object.keys(account.features).filter(
      key => account.features[key].status === 'ACTIVE' && !result.includes(key),
    );
    return [...result, ...accountModules];
  }, []);
  const newModules = allModules.filter(module =>
    allActiveModules.includes(module),
  );
  return { newModules };
};

export default setUpModules;
