import React from 'react';
import { connect } from 'react-redux';

import { t } from '../system/ui';
import { setTitle } from '../system/document';
import { getMomentObjDate, isBeforeNow, queryDate } from '../system/date';

import { uiActions, filterActions, dateActions } from '../actions';

import { getCampaign } from '../modules/campaigns/ajax';
import { getPersoCampaign } from '../modules/campaign/events';
import { getSinglePersonalizationReport } from '../modules/reports/ajax';
import { transFormPersReport } from '../modules/transform';

import Filters from '../components/filters/filters';
import WidgetReport from '../components/widget/report';
import PersonalizationWidgetReport from '../components/widget/personalizationreport';

class SingleReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: {},
      logs: [],
      isCampaignLoaded: false,
    };
  }

  componentDidMount() {
    let { reportID } = this.props.params;
    reportID = reportID.replace('?archive', '');

    setTitle(t('Campaign Report'));

    uiActions.isLoading();

    if (this.props.params.campaigntype === 'recommendation') {
      getCampaign(reportID, response => {
        this.setState(
          {
            campaign: response,
          },
          () => {
            this.setState({
              isCampaignLoaded: true,
            });
          },
        );

        uiActions.isLoaded();
      });
    } else {
      const boundaries = [];
      boundaries.start = queryDate(this.props.range[0]);
      boundaries.end = queryDate(this.props.range[1]);
      getPersoCampaign(reportID).then(response => {
        const campaign = response.response;
        if (campaign) {
          if (
            campaign.startDate &&
            campaign.endDate &&
            isBeforeNow(campaign.endDate)
          ) {
            boundaries.start = queryDate(getMomentObjDate(campaign.startDate));
            boundaries.end = queryDate(getMomentObjDate(campaign.endDate));
            dateActions.updateRange([
              getMomentObjDate(campaign.startDate),
              getMomentObjDate(campaign.endDate),
            ]);
          }
          getSinglePersonalizationReport(
            reportID,
            boundaries,
            campaignReport => {
              this.setState({
                campaign,
                logs: campaignReport,
              });
              uiActions.isLoaded();
            },
          );
        }
      });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (this.props.params.campaigntype !== 'recommendation') {
      if (newProps.range[0] && newProps.range[1]) {
        const boundaries = [];
        boundaries.start = queryDate(newProps.range[0]);
        boundaries.end = queryDate(newProps.range[1]);
        if (newProps.update === true) {
          uiActions.isLoading();
          getSinglePersonalizationReport(
            this.props.params.reportID,
            boundaries,
            campaignReport => {
              this.setState({
                logs: campaignReport,
              });
              uiActions.isLoaded();
            },
          );
          filterActions.filtersUpdated();
        }

        if (newProps.reset === true) {
          uiActions.isLoading();
          getSinglePersonalizationReport(
            this.props.params.reportID,
            boundaries,
            campaignReport => {
              this.setState({
                logs: campaignReport,
              });
              uiActions.isLoaded();
            },
          );
          filterActions.filtersReset();
        }
      }
    }
    if (
      this.props.user !== newProps.user &&
      this.props.params.campaigntype !== 'recommendation'
    ) {
      const { reportID } = this.props.params;
      const boundaries = [];
      boundaries.start = queryDate(this.props.range[0]);
      boundaries.end = queryDate(this.props.range[1]);
      getPersoCampaign(reportID).then(response => {
        const campaign = response.response;
        if (campaign) {
          if (
            campaign.startDate &&
            campaign.endDate &&
            isBeforeNow(campaign.endDate)
          ) {
            boundaries.start = queryDate(getMomentObjDate(campaign.startDate));
            boundaries.end = queryDate(getMomentObjDate(campaign.endDate));
            dateActions.updateRange([
              getMomentObjDate(campaign.startDate),
              getMomentObjDate(campaign.endDate),
            ]);
          }
          getSinglePersonalizationReport(
            reportID,
            boundaries,
            campaignReport => {
              this.setState({
                campaign,
                logs: campaignReport,
              });
              uiActions.isLoaded();
            },
          );
        }
      });
    }
  }

  render() {
    let { reportID } = this.props.params;
    reportID = reportID.replace('?archive', '');

    if (this.props.params.campaigntype === 'email') {
      return <div>Email</div>;
    }
    let currentComp = null;
    if (this.props.params.campaigntype === 'recommendation') {
      if (this.state.campaign.instanceId) {
        currentComp = (
          <WidgetReport
            key={reportID}
            {...this.state.campaign}
            campaignType={this.props.params.campaigntype}
            startDate={this.state.campaign.limitations.activeDates.startDate}
            campaign={this.state.campaign}
            isCampaignLoaded={this.state.isCampaignLoaded}
          />
        );
      } else {
        currentComp = <div />;
      }
    } else if (this.state.campaign.type) {
      currentComp = (
        <PersonalizationWidgetReport
          key={reportID}
          campaign={this.state.campaign}
          logs={transFormPersReport(this.state.logs)}
          initialDates
        />
      );
    } else {
      currentComp = <div />;
    }

    return (
      <div>
        <Filters />

        <h2 className='page-title'>{t('Campaign Report')}</h2>

        {currentComp}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  ui: store.ui.ui,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  update: store.filters.update,
  reset: store.filters.reset,
});

export default connect(mapStatesToProps)(SingleReport);
