import React from "react";
import { t } from "../../system/ui";
import JourneyBuilderCriteria from "./jb-criteria";
import {connect} from "react-redux";
import {clone} from "../../system/object";
import {uiActions} from "../../actions";
import classNames from "classnames";
import * as classes from "../../constants/ui.classes";
import {idPropFunc} from "../../system/id";

class JourneyBuilderItems extends React.Component {
  constructor(props) {
    super(props);
    this.jbItem = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isDelivery) {
      const criteriaList = this.props.delivery;
      let isAnyOneActive = false;
      let newActiveLength = 0;
      let oldActiveLength = 0;
      for (let i = 0; i < criteriaList.length; i++) {
        if (criteriaList[i] !== undefined && criteriaList[i].properties.active) {
          newActiveLength += 1;
          isAnyOneActive = true;
        }
        if (prevProps.delivery[i] !== undefined && prevProps.delivery[i].properties.active) {
          oldActiveLength += 1;
        }
      }
      if (newActiveLength !== oldActiveLength)
        isAnyOneActive ? uiActions.isValidating() : uiActions.isValidated();
    }
  }

  render() {
    return (
      <ol className='form-elements wizard-items'>
        <div className='wizard-comp'>
          <h3 className='wizard-comp-title'>{t(this.props.title)}</h3>
          <p className='wizard-comp-description'>{t(this.props.desc)}</p>
          {(this.props.isDelivery && this.props.validate === false) && (
            <span className='item-error has-error'>
              {t('DeliveryRequired')}
            </span>
          )}

          <li className='wizard-input-type-wrapper'>
            <div className='wizard-criteria'>
              <JourneyBuilderCriteria
                ref={this.jbItem}
                isDelivery={this.props.isDelivery}
                account={this.props.account}
                campaign={this.props.campaign}
                isNewCampaign={this.props.isNewCampaign}
              />
            </div>
          </li>
        </div>
      </ol>
    );
  }
}

const mapStatesToProps = (store) => ({
  validate: store.ui.validate,
  delivery: store.jbWizard.delivery,
});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
  JourneyBuilderItems,
);
