import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const pushIcon = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.7153 9.65079C16.7095 11.6777 18.3337 13.3271 20.3418 13.3333C22.3544 13.3396 23.9947 11.7027 24 9.68165C24.0062 7.66328 22.3819 6.00628 20.3916 6.00002C18.3604 5.99376 16.7215 7.62124 16.7153 9.65079ZM8.72822 9.13486C7.96851 9.81287 7.57578 10.6975 7.34342 11.6671C7.05198 12.8827 7.008 14.1215 7 15.2848C7.004 16.4154 7.04443 17.4637 7.22836 18.5008C7.37008 19.2956 7.59399 20.0608 8.02627 20.75C8.6367 21.7236 9.54923 22.2661 10.6217 22.5743C11.564 22.8444 12.5316 22.9401 13.5059 22.975C15.0502 23.03 16.5918 23.0184 18.1117 22.6879C18.9798 22.4987 19.7924 22.1771 20.4663 21.5693C21.2225 20.8868 21.6174 20.0022 21.8409 19.0308C22.203 17.4534 22.2123 15.8505 22.1532 14.2454C22.1448 14.0209 22.0217 13.8961 21.8334 13.9024C17.4044 15.0227 15.0578 11.7243 16.0378 8.06195C16.0445 7.87813 15.9276 7.7538 15.7131 7.74531C14.1559 7.68627 12.6018 7.69611 11.0695 8.0293C10.2072 8.21714 9.4004 8.53467 8.72822 9.13486Z'
        fill={color}
      />
    </svg>
  );
};

pushIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

pushIcon.defaultProps = {
  width: '30px',
  height: '30px',
  color: '#C772A6',
};

export default pushIcon;
