/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";
import { Creatable } from "react-select";
import { decodeHtml } from "../../system/string";

import { getBrands } from "../../modules/account/ajax";

import Icon from "../icon";
import Icons from "../icons";

import { t } from "../../system/ui";

class ExcludesSearchBrand extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brands: [],
      brand: ""
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    let selectedBrands = [];
    getBrands().then(response => {
      let sorted = response.sort();
      let brands = [];
      sorted.map(item => {
        brands.push({
          value: item,
          label: decodeHtml(item)
        });
      });
      if (typeof this.props.values.brand !== "undefined") {
        brands.push({
          value: this.props.values.brand.value,
          label: decodeHtml(this.props.values.brand.value)
        });
        this.props.values.brand.value.forEach(brand => {
          selectedBrands.push({
            value: brand,
            title: decodeHtml(brand),
            label: decodeHtml(brand)
          });
        });
      }
      this.setState({
        brands: brands,
        brand: selectedBrands
      });

      this.onChange();
    });
  }

  onChange(selectedValue) {
    let selectedBrand =
      typeof selectedValue !== "undefined" ? selectedValue : this.state.brand;
    this.setState({ brand: selectedBrand });
    this.props.onExclusionSelectChange("brand", selectedBrand, this.props.id);
  }

  render() {
    let brand =
      typeof this.props.values.brand !== "undefined"
        ? this.props.values.brand.value
        : "";

    return (
      <li className="wizard-criterion" ref="inputs">
        <span className="criteria-type">
          <Icon name="star" color="#787774" />
        </span>
        <div className="wizard-criterion-text">
          <h3 className="wizard-criterion-title">{t("Brand")}</h3>
          <p className="wizard-criterion-description">
            {t(
              "Exclude the brands you don't want showing up in your recommendation."
            )}
          </p>
        </div>
        <span className="wizard-product-select-container">
          <Creatable
            value={this.state.brand}
            options={this.state.brands}
            name="brand"
            clearable={false}
            onChange={this.onChange}
            className="criteria-field criteria-field-category"
            multi={true}
            shouldKeyDownEventCreateNewOption={e => {
              switch (e.keyCode) {
                case 9:
                case 13:
                  return !0;
                default:
                  return !1;
              }
            }}
          />
        </span>
        <a
          onClick={this.props.onExclusionRemove.bind(null, this.props.id)}
          className="cancel-action recommend-filter-cancel"
        >
          <Icons name="crossCircle" />
        </a>
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  excludes: store.wizard.excludes
});

export default connect(mapStatesToProps)(ExcludesSearchBrand);
