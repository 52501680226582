import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PersonaQuiz = ({ width = '27px', height = '34px', color = '#eee' }) => {
  const [state, setState] = useState({
    width: '',
    height: '',
    color: '',
  });

  const setStyles = () => {
    setState({
      width,
      height,
      color,
    });
  };

  useEffect(() => {
    setStyles();
  }, [width, height, color]);

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 27 34'
      width={state.width}
      height={state.height}
      className='svg-icon'
    >
      <path
        d='M12.7433 18.3443C12.7433 17.8494 12.8286 17.3743 12.9851 16.9327H3.18544C1.42616 16.9327 0 18.3539 0 20.1073V20.9227C0 22.1829 0.451157 23.4019 1.27238 24.3602C3.49098 26.9495 6.87067 28.2272 11.3267 28.2272C11.8898 28.2272 12.4358 28.2068 12.9644 28.1659C12.8211 27.7414 12.7433 27.2868 12.7433 26.814V26.0595C12.2884 26.093 11.8163 26.1098 11.3267 26.1098C7.44797 26.1098 4.66337 25.057 2.88811 22.9852C2.39539 22.4102 2.12469 21.6789 2.12469 20.9227V20.1073C2.12469 19.5235 2.5996 19.0501 3.18544 19.0501H12.7433V18.3443ZM11.3267 0C15.2381 0 18.409 3.16001 18.409 7.05806C18.409 10.9561 15.2381 14.1161 11.3267 14.1161C7.41522 14.1161 4.24436 10.9561 4.24436 7.05806C4.24436 3.16001 7.41522 0 11.3267 0ZM11.3267 2.11742C8.58866 2.11742 6.36905 4.32943 6.36905 7.05806C6.36905 9.7867 8.58866 11.9987 11.3267 11.9987C14.0647 11.9987 16.2843 9.7867 16.2843 7.05806C16.2843 4.32943 14.0647 2.11742 11.3267 2.11742Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.8705 15.4084H14.7102L14.5693 15.413L14.5645 15.4134C13.4564 15.4883 12.5809 16.4107 12.5809 17.5377L12.5808 31.2305L12.5855 31.3713L12.5858 31.3761C12.6607 32.4842 13.5831 33.3597 14.7102 33.3597L24.8705 33.3598L25.0113 33.3551L25.0161 33.3548C26.1242 33.2799 26.9997 32.3574 26.9997 31.2305L26.9998 17.5377L26.9951 17.3968L26.9948 17.3921C26.9198 16.284 25.9974 15.4084 24.8705 15.4084ZM14.1889 17.5425L14.1943 17.4623C14.2307 17.2102 14.4479 17.0164 14.7102 17.0164H24.8657L24.9458 17.0218C25.1979 17.0583 25.3917 17.2755 25.3917 17.5377V31.2257L25.3863 31.3057C25.3499 31.5579 25.1327 31.7517 24.8705 31.7517H14.7149L14.6348 31.7463C14.3827 31.7099 14.1889 31.4927 14.1889 31.2305V17.5425Z'
        fill={state.color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7273 20.2499C14.7273 19.911 14.9859 19.6363 15.3048 19.6363H23.9679C24.2869 19.6363 24.5454 19.911 24.5454 20.2499C24.5454 20.5888 24.2869 20.8636 23.9679 20.8636H15.3048C14.9859 20.8636 14.7273 20.5888 14.7273 20.2499Z'
        fill={state.color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.9543 22.7045C15.9543 22.3656 16.2291 22.0908 16.568 22.0908H22.7043C23.0432 22.0908 23.318 22.3656 23.318 22.7045C23.318 23.0434 23.0432 23.3181 22.7043 23.3181H16.568C16.2291 23.3181 15.9543 23.0434 15.9543 22.7045Z'
        fill={state.color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.1552 24.7267C23.3722 24.9684 23.3722 25.3603 23.1552 25.602L18.5941 30.6817L16.1171 27.923C15.9001 27.6813 15.9001 27.2894 16.1171 27.0477C16.3342 26.806 16.686 26.806 16.9031 27.0477L18.5941 28.9311L22.3692 24.7267C22.5863 24.4849 22.9382 24.4849 23.1552 24.7267Z'
        fill={state.color}
      />
    </svg>
  );
};

PersonaQuiz.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

PersonaQuiz.defaultProps = {
  width: '1.68rem',
  height: '2.1rem',
  color: '#eee',
};

export default PersonaQuiz;
