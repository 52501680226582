import React, { useContext, useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { GamificationService } from './services/gamificationServices';
import { Context as GamificationContext } from './store/gamificationProvider';
import {
  GFCampaignList,
  SFYHeader,
  SFYNoResult,
} from '../../../components/sfy-components/module-exports';
import {
  actions,
  breadCrumbOptions,
  campaignWidgets,
} from './constants/constants';
import CampaignCreate from '../../../components/gamification/GFCCampaignCreate/CampaignCreate';
import { modalActions, uiActions } from '../../../actions';
import BundleModalManager from './modals/bundleModalManager';
import { t } from '../../../system/ui';
import { hideBreadcrumb } from '../../../actions/ui';
// eslint-disable-next-line no-unused-vars
import UploadCoupon from './modals/uploadCoupon';
import { setTitle } from '../../../system/document';
import CampaignHistoryModal from '../../../modules/campaigns/historymodal';

/**
 * @name Gamification view holds shop the look components
 * @description gamification components responsible from pagination and path to any related page
 * @returns {JSX.Element} - gamification component
 */
const Gamification = () => {
  const {
    state: { gamificationCampaignList },
    setGamificationList,
    resetActiveWOFDetails,
  } = useContext(GamificationContext);

  const {
    isLoaded,
    isLoading,
    showNotification,
    confirmationDialog,
  } = uiActions;

  // eslint-disable-next-line no-unused-vars
  const [couponDetails, setCouponDetails] = useState({
    possibleRewards: [],
    isUploaded: false,
  });

  // eslint-disable-next-line no-unused-vars
  const handleCouponUpload = possibleRewards => {
    setCouponDetails({ possibleRewards, isUploaded: true });
  };

  const showNotificationFunc = (message, type) => {
    const content = () => <p>{t(message)}</p>;
    showNotification({
      content,
      className: `notification-${type}`,
    });
  };

  const listInvoker = () => {
    isLoading();
    GamificationService()
      .getAllWithCampaignType({ campaignType: 'GAMIFICATION' })
      .then(res => {
        setGamificationList({
          active: res.filter(item => item.status === 'ACTIVE'),
          test: res.filter(item => item.status === 'TEST'),
          passive: res.filter(item => item.status === 'PASSIVE'),
        });
        isLoaded();
      });
  };

  const openWOFTypeModal = type => {
    const content = () => (
      <BundleModalManager wofFor='list-screen' campaignType={type} />
    );

    modalActions.openModal({
      className: 'bundle-product-modal',
      content,
      dynamicHeight: true,
    });
  };

  const updatePriority = draggedList => {
    isLoading();
    GamificationService('gamification/updatePriorities', false)
      .updatePriority({ body: draggedList })
      .then(res => {
        isLoaded();
        showNotificationFunc('Priority updated successfully.', 'success');
        setGamificationList({
          active: res.filter(item => item.status === 'ACTIVE'),
          test: res.filter(item => item.status === 'TEST'),
          passive: res.filter(item => item.status === 'PASSIVE'),
        });
      });
  };

  const goTestOrLive = (instanceId, campaignObj, status) => {
    isLoading();
    GamificationService()
      .updateStatus({
        id: instanceId,
        body: campaignObj,
        status, // 'LIVE' or 'TEST'
      })
      .then(() => {
        isLoaded();
        showNotificationFunc(
          `Campaign is updated in ${status.toLowerCase()} mode.`,
          'success',
        );
        listInvoker();
      });
  };

  const removeCampaign = (instanceId, campaignObj) => {
    isLoading();
    GamificationService()
      .updateStatus({
        id: instanceId,
        body: campaignObj,
        status: 'ARCHIVE',
      })
      .then(() => {
        isLoaded();
        showNotificationFunc('Campaign is successfully deleted.', 'success');
        listInvoker();
      });
  };

  const reDesignedActions = () => {
    return actions.map(action => {
      const newAction = { ...action };

      if (newAction.handler === 'editAction') {
        newAction.actionFunc = (instanceId, type) => {
          browserHistory.push(
            `/behavioural-targeting/engagement/gamification/${
              type === 'WHEEL_OF_FORTUNE' ? 'wof' : 'scratch'
            }/edit/${instanceId}`,
          );
        };
      }

      if (newAction.handler === 'activeAction') {
        newAction.actionFunc = (instanceId, campaignObj, status) => {
          const displayStatus = status === 'TEST' ? 'Test' : 'Live';
          confirmationDialog({
            title: `Go ${displayStatus} the Campaign?`,
            content: `Are you sure you want to get the campaign ${displayStatus}?`,
            onConfirm: () => {
              goTestOrLive(instanceId, campaignObj, status);
            },
          });
        };
      }

      if (newAction.handler === 'removeAction') {
        newAction.actionFunc = (instanceId, campaignObj) => {
          confirmationDialog({
            title: 'Delete the Campaign?',
            content: 'Are you sure you want to delete the campaign?',
            onConfirm: () => {
              removeCampaign(instanceId, campaignObj);
            },
          });
        };
      }

      if (newAction.handler === 'duplicateAction') {
        newAction.actionFunc = (instanceId, type, gamificationType) => {
          if (gamificationType === 'UNIQUE') {
            const content = () => (
              <BundleModalManager
                wofFor='list-screen'
                wofType={gamificationType}
                campaignType={type}
                instanceId={instanceId}
              />
            );
            modalActions.openModal({
              className: 'bundle-product-modal',
              content,
              dynamicHeight: true,
            });
          } else {
            browserHistory.push(
              `/behavioural-targeting/engagement/gamification/${
                type === 'WHEEL_OF_FORTUNE' ? 'wof' : 'scratch'
              }/duplicate/${instanceId}`,
            );
          }
        };
      }

      if (newAction.handler === 'reportAction') {
        newAction.actionFunc = instanceId => {
          browserHistory.push(`/reports/single/engagement/${instanceId}`);
        };
      }

      if (newAction.handler === 'historyAction') {
        newAction.actionFunc = instanceId => {
          const campaignHistoryModal = () => (
            <CampaignHistoryModal campaignId={instanceId} engagement />
          );

          modalActions.openModal({
            title: `Campaign History (${instanceId})`,
            content: campaignHistoryModal,
          });
        };
      }

      return newAction;
    });
  };

  useEffect(() => {
    setTitle('Gamification');
    listInvoker();
    resetActiveWOFDetails();
    return () => {
      hideBreadcrumb();
    };
  }, []);

  return (
    <>
      <SFYHeader
        pageTitle='Gamification'
        breadCrumbOptions={breadCrumbOptions}
      />

      <CampaignCreate
        campaignWidgets={campaignWidgets}
        paginationOptions={{
          action: openWOFTypeModal,
        }}
      />

      <GFCampaignList
        campaignList={gamificationCampaignList}
        setCampaignList={setGamificationList}
        actions={reDesignedActions()}
        onDragged={updatePriority}
      />

      {gamificationCampaignList.active.length === 0 &&
        gamificationCampaignList.test.length === 0 && (
          <SFYNoResult
            noListDescription='There is no gamification campaign yet.'
            listType='Create a new gamification campaign'
          />
        )}
    </>
  );
};

export default Gamification;
