import RPBrands from './RPBrands';
import RPLabels from './RPLabels';
import RPProducts from './RPProducts';
import RPCategories from './RPCategories';
import RPinProduct from './RPinProduct';
import GCSearchResultsAddNew from './GCSearchResultsAddNew/GCSearchResultsAddNew';
import GCSearchResultsIndexList from './GCSearchResultsIndexList/GCSearchResultsIndexList';
import RPCategoryAccessor from './BoostFields/CategoryAccessor';
import BoostFields from './BoostFields/BoostFields';

/**
 * @name attributeComponents
 * @description This is a list of all the components that are used to render the attributes of the related products.
 * @type {Array}
 */
export const attributeComponents = {
  RPBrands,
  RPLabels,
  RPProducts,
  RPCategories,
  RPinProduct,
  GCSearchResultsAddNew,
  GCSearchResultsIndexList,
  RPCategoryAccessor,
  BoostFields,
};
