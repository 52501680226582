import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Select, Button } from './fields';
import { t } from '../system/ui';
import { localeString } from '../system/string';

const Form = ({
  fields,
  buttons,
  account,
  withAdditionalInfo,
  activeLabel,
  isSpecial,
  isMultipleComps,
  button,
  clickHandler,
  onChange,
}) => {
  const [inputs, setInputs] = React.useState({});

  const onValueChange = e => {
    const { value, name } = e.target;
    const newInputs = inputs;

    newInputs[name] = name === 'username' ? value.trim().toLowerCase() : value;

    setInputs({ ...newInputs });
  };

  return (
    <ol
      className='form-elements'
      style={{ position: activeLabel ? 'relative' : 'unset' }}
    >
      {activeLabel && (
        <span
          style={{
            backgroundColor: '#fbfbfb',
            height: '65px',
            position: 'absolute',
            width: '100%',
            top: '0',
            left: '0',
          }}
        />
      )}
      {activeLabel && (
        <span>
          <label className='switch'>
            <input type='checkbox' checked={button} />
            <span className='slider round' onClick={clickHandler} />
          </label>{' '}
          <span style={{ position: 'relative' }}>Active</span>
        </span>
      )}
      {fields.map((item, index) => (
        <li
          className='form-element'
          style={{
            display: isSpecial ? 'inline' : 'list-item',
            position: activeLabel && index === 0 ? 'absolute' : 'unset',
            top: activeLabel && index === 0 ? '0px' : 'unset',
            marginLeft: activeLabel && index === 0 ? '40px' : 'unset',
            width: activeLabel && index === 0 ? '230px' : 'unset',
          }}
          key={`${item.type}-${item.name}`}
        >
          {item.type === 'select' ? (
            <Select {...item} />
          ) : (
            <TextField
              {...item}
              isSecond={activeLabel && index}
              onChange={isMultipleComps ? onChange : e => onValueChange(e)}
              value={
                inputs[item.name] !== undefined
                  ? inputs[item.name]
                  : item.defaultValue
              }
            />
          )}
        </li>
      ))}

      {withAdditionalInfo && (
        <li className='settings-current-cost'>
          {t("This Month's Cost")}:
          <span>
            {`${localeString(account.thisMonthCost)} ${
              account.mainCurrency.code
            }`}
          </span>
        </li>
      )}

      {buttons && (
        <li className='buttons'>
          {buttons.map(item => (
            <Button key={`${item.type}-${item.label}`} attr={item} {...item} />
          ))}
        </li>
      )}
    </ol>
  );
};

Form.defaultProps = {
  fields: [],
  account: null,
  withAdditionalInfo: false,
  activeLabel: false,
  isSpecial: false,
  isMultipleComps: false,
  button: false,
  clickHandler: () => null,
  onChange: () => null,
};

Form.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string.isRequired,
      defaultValue: PropTypes.string,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      readOnly: PropTypes.bool.isRequired,
      required: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
      validation: PropTypes.shape({
        businessEmail: PropTypes.bool.isRequired,
        email: PropTypes.bool.isRequired,
      }).isRequired,
    }),
  ),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      submitAnimation: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  account: PropTypes.shape({
    thisMonthCost: PropTypes.string.isRequired,
    mainCurrency: PropTypes.shape({ code: PropTypes.string.isRequired })
      .isRequired,
  }),
  withAdditionalInfo: PropTypes.bool,
  activeLabel: PropTypes.bool,
  isSpecial: PropTypes.bool,
  isMultipleComps: PropTypes.bool,
  button: PropTypes.bool,
  clickHandler: PropTypes.func,
  onChange: PropTypes.func,
};

export default Form;
