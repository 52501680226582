export const pageMap = {
  home: 'Home Page',
  category: 'Category Page',
  product: 'Product Page',
  search: 'Search Page',
  404: '404 Page',
  notfound: '404 Page',
  basket: 'Basket Page',
  'checkout-success': 'Checkout Success Page',
  triggerbased: 'Trigger Based Page',
  custom: '',
  popup_recommendation: 'Popup Recommendation',
};

export const pageWidgets = {
  home: ['recommended', 'mostPopular', 'recentlyViewed', 'topSellers'],
  category: ['mostPopular', 'topSellers'],
  search: ['recommended', 'recentlyViewed', 'topSellers', 'mostPopular'],
  notfound: ['recommended', 'recentlyViewed', 'topSellers', 'mostPopular'],
  product: [
    'youMayAlsoLike',
    'alternativeProducts',
    'recentlyViewed',
    'shopSimilar',
  ],
  basket: [
    'alternativeProducts',
    'lastMinuteOffers',
    'recentlyViewed',
    'previouslyAddedToBasket',
  ],
  'checkout-success': ['boughtTogether', 'mostPopular'],
  custom: ['stickyRecommendation'],
  triggerbased: [],
  pers: ['fullWidth'],
  popup_recommendation: ['popup_recommendation'],
  journey_builder: [],
};

export const nonRecommendationWidgets = {
  keyword: ['fullWidth'],
  promotion: ['fullWidth'],
};

export const pageTypes = {
  home: {
    pageName: 'Home Page',
    inputs: {
      names: ['categories', 'category'],
      value: 'Home Page',
    },
  },
  category: {
    pageName: 'Category Page',
    inputs: {
      names: ['categories', 'category'],
      value: 'Category Page',
    },
  },
  search: {
    pageName: 'Search Page',
    inputs: {
      names: ['categories', 'category'],
      value: 'Search Page',
    },
  },
  404: {
    pageName: '404 Page',
    inputs: {
      names: ['categories', 'category'],
      value: '404 Page',
    },
  },
  product: {
    pageName: 'Product Page',
    inputs: {
      names: ['categories', 'category'],
      value: 'Product Page',
    },
    templateId: [
      'TO1',
      'T03',
      'T07',
      'T13',
      'T19',
      'T23',
      'T24',
      'HUR_8',
      'HUR_15',
      'HUR_16',
      'N11_6',
      'T03_MULTI',
    ],
  },
  basket: {
    pageName: 'Basket Page',
    inputs: {
      names: ['categories', 'category'],
      value: 'Basket Page',
    },
    templateId: ['T14', 'T16'],
  },
  'checkout-success': {
    pageName: 'Checkout Success Page',
    inputs: {
      names: ['categories', 'category'],
      value: 'Checkout Success Page',
    },
    templateId: ['T21'],
  },
  triggerbased: {
    pageName: 'Trigger Based Page',
    inputs: {
      names: ['categories', 'category'],
      value: 'Trigger Based Page',
    },
    templateId: ['SMSG_01'],
  },
  journey_builder: {
    pageName: 'Journey Builder',
    inputs: {
      names: ['categories', 'category'],
      value: 'Journey Builder',
    },
    templateId: ['JB_1'],
  },
};
