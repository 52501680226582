import React from 'react';
import { notification } from '../../../../../system/ui';
import {
  checkRelatedProductId,
  STLConvertForFrontend,
} from '../../../../../components/sfy-components/utils';

/**
 * @name shopTheLookReducer
 * @summary reducer for shop the look
 * @param {any} state
 * @param {any[]} action
 */

export const shopTheLookReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_SHOP_THE_LOOK_BUNDLE_GROUP_NAME':
      return {
        ...state,
        bundleGroupName: payload,
      };
    case 'SET_RELATED_PRODUCT_ID':
      return {
        ...state,
        relatedProductId: payload,
      };
    case 'SET_SHOP_THE_LOOK_LIST':
      return {
        ...state,
        list: payload,
      };
    case 'SET_SHOP_THE_LOOK_BUNDLE_LIST': {
      const convertForFrontend = STLConvertForFrontend(payload);
      return {
        ...state,
        bundleList: convertForFrontend.bundleList,
        relatedProductId: convertForFrontend.relatedProductId,
        bundleGroupName: convertForFrontend.bundleGroupName,
      };
    }
    case 'SET_SHOP_THE_LOOK_BUNDLE': {
      if (state.bundleList.length === 5) {
        // eslint-disable-next-line react/no-unescaped-entities
        const content = () => <p>You must add a maximum of 5 bundles.</p>;
        notification({
          content,
          className: 'notification-fail',
        });
        return state;
      }

      const newBundleList = [
        ...state.bundleList,
        { ...payload, index: state.bundleList.length },
      ];

      return {
        ...state,
        bundleList: newBundleList,
        relatedProductId: checkRelatedProductId(newBundleList),
      };
    }
    case 'EDIT_SHOP_THE_LOOK_BUNDLE': {
      const newBundleList = state.bundleList.map(bundle => {
        if (bundle.index === payload.index) {
          return { ...payload };
        }
        return bundle;
      });

      return {
        ...state,
        bundleList: newBundleList,
        relatedProductId: checkRelatedProductId(newBundleList),
      };
    }
    case 'REMOVE_SHOP_THE_LOOK_BUNDLE': {
      const newBundleList = state.bundleList
        .filter((bundle, index) => index !== payload)
        .map((bundle, index) => ({ ...bundle, index }));

      return {
        ...state,
        bundleList: newBundleList,
      };
    }
    case 'SET_SHOP_THE_LOOK_INCLUSION_EXCLUSION_OF_CATEGORY': {
      let bundles = {};
      if (payload.bundleType === 'category') {
        bundles = state.bundleList.map((bundle, index) => {
          if (index !== payload.index) {
            return bundle;
          }

          return {
            ...bundle,
            params: payload.params,
          };
        });

        return {
          ...state,
          bundleList: bundles,
        };
      }
      return state;
    }
    case 'SET_SHOP_THE_LOOK_PREVIEW':
      return {
        ...state,
        previewList: payload,
      };
    case 'RESET_SHOP_THE_LOOK':
      return {
        bundleList: [],
        relatedProductId: '',
        bundleGroupName: '',
        previewList: [],
        params: [],
      };
    default:
      return state;
  }
};
