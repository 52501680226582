export const setPromotionList = dispatch => {
  return list => {
    dispatch({
      type: 'SET_PROMOTIONS_LIST',
      payload: list,
    });
  };
};

export const resetPromotion = dispatch => {
  return () => {
    dispatch({
      type: 'RESET_PROMOTION',
    });
  };
};

export const setPromotion = dispatch => {
  return promotion => {
    dispatch({
      type: 'SET_PROMOTION',
      payload: promotion,
    });
  };
};

export const deletePromotion = dispatch => {
  return code => {
    dispatch({
      type: 'DELETE_PROMOTION',
      payload: code,
    });
  };
};
