import {
  htmlTemplate,
  preJSTemplate,
  postJSTemplate,
  cssTemplate,
} from '../constants/templatesWOF';

import {
  htmlTemplate as ScratchHTMLTemplate,
  preJSTemplate as ScratchPreJSTemplate,
  postJSTemplate as ScratchPostJSTemplate,
  cssTemplate as ScratchCSSTemplate,
} from '../constants/templatesScratch';

const getWOFAdvancedConfigInitialTemplates = campaign => {
  if (campaign?.type === 'SCRATCH') {
    return {
      html: campaign?.html || ScratchHTMLTemplate || '',
      preJs: campaign?.preJs || ScratchPreJSTemplate || '',
      postJs: campaign?.postJs || ScratchPostJSTemplate || '',
      css: campaign?.css || ScratchCSSTemplate || '',
    };
  }
  return {
    html: campaign?.html || htmlTemplate || '',
    preJs: campaign?.preJs || preJSTemplate || '',
    postJs: campaign?.postJs || postJSTemplate || '',
    css: campaign?.css || cssTemplate || '',
  };
};

const getWOFAdvancedConfigCampaignDetails = campaign => {
  let campaignStatus = '';
  switch (campaign.status) {
    case 'ACTIVE':
      campaignStatus = 'Live';
      break;
    case 'TEST':
      campaignStatus = 'Test';
      break;
    default:
      break;
  }
  return {
    title: campaign?.name || '',
    campaignStatus,
  };
};

const possibleRewardColors = ['#887DF5', '#83E889', '#CF71F5', '#F5DE58'];
const getInitialWOFPossibleReward = index => {
  return {
    type: 'slice',
    baseCodeCount: 1,
    basePossibility: 25,
    couponCodes: [],
    couponUrl: '',
    description: '',
    possibility: 25,
    content: '',
    color: possibleRewardColors[index % 4],
    index,
  };
};

// Reward color getting differently by index

export {
  getWOFAdvancedConfigInitialTemplates,
  getWOFAdvancedConfigCampaignDetails,
  getInitialWOFPossibleReward,
};
