import { t } from '../../../../system/ui';

const breadCrumbOptions = {
  home: {
    name: t('Dynamic Bundles'),
    icon: 'bundle',
    slug: '/dynamic_bundle',
  },
  current: {
    name: 'Automated Bundles',
  },
};

const getDynamicBreadCrumbOptions = currentName => {
  return {
    home: {
      name: t('Dynamic Bundles'),
      icon: 'bundle',
      slug: '/dynamic_bundle',
    },
    top: {
      name: t('Automated Bundles'),
      slug: '/dynamic_bundle/automated-bundles/list',
    },
    current: {
      name: currentName,
    },
  };
};

const bundleHeadConstant = [
  { headerTitle: 'Product Id', isVisible: true, size: 'sm', key: '1' },
  { headerTitle: 'Product', isVisible: true, size: 'l border-left text-center', key: '2' },
  {
    headerTitle: 'Category',
    isVisible: true,
    size: 'm border-both text-center',
    key: '3',
  },
  { headerTitle: 'Bundle', isVisible: true, size: 'sm text-center', key: '4' },
];

const categoryHeadConstant = [
  {
    headerTitle: 'Category',
    isVisible: true,
    size: 'xl border-right',
    key: '1',
  },
  { headerTitle: 'Bundle', isVisible: true, size: 'l text-center', key: '2' },
];

const bundleModalStates = Object.freeze({
  BUNDLE_TYPE: 'bundle-type',
  CATEGORY_MAIN_ITEM: 'category-main-item',
  CATEGORY_BUNDLE_ITEM: 'category-bundle-item',
  PRODUCT_MAIN_ITEM: 'product-main-item',
  PRODUCT_BUNDLE_ITEM: 'automated-bundles-item',
});

const modalStateDetails = Object.freeze({
  [bundleModalStates.BUNDLE_TYPE]: {
    title: 'Select Bundle Type',
    description: '',
    buttonText: 'Continue',
  },
  [bundleModalStates.CATEGORY_MAIN_ITEM]: {
    title: 'Category Bundle',
    description: '',
    buttonText: 'Continue',
  },
  [bundleModalStates.CATEGORY_BUNDLE_ITEM]: {
    title: 'Category Bundle',
    description: '',
    buttonText: 'Continue',
  },
  [bundleModalStates.PRODUCT_MAIN_ITEM]: {
    title: 'Choose the Product',
    description: 'Add the product you want to show on the bundle list.',
    buttonText: 'Add Selected',
  },
  [bundleModalStates.PRODUCT_BUNDLE_ITEM]: {
    title: 'Choose the Product',
    description: 'Add the product you want to show on the bundle list.',
    buttonText: 'Add Selected',
  },
});

const bundleTypes = [
  {
    label: 'Category',
    description:
      'Category bundles allows you to group products from different categories together. (The most popular products of the category will come)',
    value: 'category',
  },
  {
    label: 'Product',
    description:
      'Products bundles allows you to group from different products together.',
    value: 'product',
  },
];

const categoryFilterTypes = [
  'size',
  'label',
  'color',
  'brand',
  'product',
  'gender',
  'stock',
  'priceRange',
  'custom',
];

const algorithmOptions = [
  {
    label: 'Complementary Products',
    value: 'RECOMMENDATION_COLLABORATIVE_FILTERING',
  },
  {
    label: 'Category Rocks',
    value: 'RECOMMENDATION_CATEGORY_ROCKS',
  },
  {
    label: 'Personalised Top Sellers',
    value: 'RECOMMENDATION_TOP_SELLERS',
  },
  {
    label: 'Top Sellers',
    value: 'RECOMMENDATION_STATISTICAL_TOP_SELLERS',
  },
  {
    label: 'Mostly Purchased Together',
    value: 'RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER',
  },
];

const campaignActionButtonConstant = [
  {
    btnLabel: 'Save All',
    type: 'save-justify-right',
    position: 'flex-end',
    key: '1',
  },
];

const importExportOptions = [
  {
    label: 'Import',
    icon: 'import',
  },
  {
    label: 'Export',
    icon: 'export',
  },
];

export {
  bundleHeadConstant,
  breadCrumbOptions,
  categoryHeadConstant,
  getDynamicBreadCrumbOptions,
  bundleTypes,
  bundleModalStates,
  modalStateDetails,
  categoryFilterTypes,
  algorithmOptions,
  campaignActionButtonConstant,
  importExportOptions,
};
