/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';

import moment from 'moment';
import EmailCard, { campaignBadges } from '../components/widget/email-card';
import { filterActions, uiActions } from '../actions';
import EmailStatusFilters from '../components/filters/email-status-filters';
import Icon from '../components/icon';
import {
  csSearchParams,
  emailSortingTypes as sortingTypes,
  itemsPerScroll,
  mailGroups,
} from '../components/reports/utils';
import Tooltip from '../components/tooltip';
import LoaderPlaceholder from '../components/widget/loader-placeholder';
import {
  getAccount,
  getApiKey,
  getRegion,
  getSwitchAccountUser,
  getToken,
  isMultiAccountUser,
  isSwitchAccount,
} from '../modules/auth/user';
import { getEmails, getRangedEmailReports } from '../modules/emails/ajax';
import { getEndPoint } from '../system/api';
import { queryDate } from '../system/date';
import { setTitle } from '../system/document';
import { t } from '../system/ui';
import { config } from '../constants/config';

class Report extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isFirstLoadComplete: false,
      emails: [],
      _emails: [],
      reports: {},
      pageTitle: '',
      status: 'ACTIVE',
      mailType: this.props.params.report,
      scrollCount: 0,
      canLoadMore: true,
      showOptions: false,
      sort: 'DESC',
      sortType: 'date',
      toggleIcon: 'preferences',
      mailProps: props,
    };

    this.getEmails = this.getEmails.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.setMailType = this.setMailType.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.increaseScrollCount = this.increaseScrollCount.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.onInputKeyDown = this.onInputKeyDown.bind(this);
    this.exportAll = this.exportAll.bind(this);

    this.timer = null;
  }

  componentDidMount() {
    uiActions.hideBreadcrumb();
    this.onChangeDocumentTitle(this.props.params.report);

    if (!this.props.params.report) {
      this.props.router.push({
        pathname: '/reports/all/email/executive-summary',
      });
    }
    this.getEmails();
    window.onscroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.scrollHeight &&
        this.state.canLoadMore &&
        !this.state.isLoading
      ) {
        this.increaseScrollCount();
      }
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (this.props.params.report !== newProps.params.report) {
      this.onChangeDocumentTitle(newProps.params.report);
      this.setMailType(newProps.params.report);
    }

    if (this.props.user !== newProps.user) {
      this.getEmails();
    }

    if (newProps.update === true) {
      this.getEmails(newProps.range);
      this.state.mailProps = newProps;
    }
  }

  onChangeDocumentTitle(report) {
    let { pageTitle } = this.state;
    switch (report) {
      case 'view-all':
        pageTitle = config.menus.primary.reports.children['all-email'].name;
        break;
      case 'winback':
        pageTitle = config.menus.primary.reports.children.winback.name;
        break;
      case 'growth':
        pageTitle = config.menus.primary.reports.children.growth.name;
        break;
      case 'retention':
        pageTitle = config.menus.primary.reports.children.retention.name;
        break;
      case 'recommendation':
        pageTitle = config.menus.primary.reports.children.recommendation.name;
        break;
      case 'fly':
        pageTitle = config.menus.primary.reports.children.flymode.name;
        break;
      default:
        break;
    }
    setTitle(`${t(pageTitle)} ${t('Reports')}`);
  }

  onChangeStatus(instanceId, status, newStatus) {
    const newEmails = [];
    this.state._emails.forEach(email => {
      const newEmail = email;
      if (email.instanceId === instanceId) {
        newEmail.status = status;
      }
      newEmails.push(newEmail);
    });
    this.setState(
      {
        _emails: newEmails,
        status: newStatus,
      },
      () => {
        this.filterEmails('statusOrMediumChange');
      },
    );
  }

  onStatusChange(status) {
    this.setState(
      {
        status,
      },
      () => {
        this.filterEmails('statusOrMediumChange');
      },
    );
  }

  onInputKeyDown() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.filterEmails('search');
    }, 1000);
  }

  setMailType(newType) {
    this.setState(
      {
        mailType: newType,
      },
      () => {
        this.filterEmails('statusOrMediumChange');
      },
    );
  }

  getEmails(range) {
    uiActions.isLoading();
    const boundaries = {};
    let allEmails = [];
    boundaries.start = range
      ? moment(range[0]).format('YYYY-MM-DD')
      : moment(this.props.range[0]).format('YYYY-MM-DD');
    boundaries.end = range
      ? moment(range[1]).format('YYYY-MM-DD')
      : moment(this.props.range[1]).format('YYYY-MM-DD');
    getEmails(response => {
      allEmails = response.map(emailItem => {
        return {
          ...emailItem,
          scenarioName: decodeURIComponent(emailItem.scenarioName),
        };
      });
      this.setState(
        {
          isLoading: false,
          isFirstLoadComplete: true,
          _emails: allEmails,
        },
        () => {
          this.filterEmails('statusOrMediumChange', false, false, range);
          uiActions.isLoaded();
        },
      );
    });
  }

  getCampaignBadges(type) {
    return find(campaignBadges, item => {
      const index = item.elements.indexOf(type);
      return index > -1;
    });
  }

  toggleOptions(e) {
    e.preventDefault();

    this.setState(prevState => ({
      showOptions: !prevState.showOptions,
      toggleIcon: !prevState.showOptions ? 'cross' : 'preferences',
    }));
  }

  filterEmails(source, eventParam, e, range) {
    filterActions.filtersUpdated();
    const allEmails = this.state._emails;
    const { status } = this.state;
    const { mailType } = this.state;

    // Status filter
    let filteredEmails = allEmails.filter(email => {
      return email.status === status;
    });

    // Type Filter
    if (mailType !== 'view-all') {
      filteredEmails = filteredEmails.filter(email => {
        return mailGroups[mailType]?.indexOf(email?.type) > -1;
      });
    }
    // Sorting
    if (e) {
      e.preventDefault();
    }
    let sortType;
    let sortOrder;
    let scrollCount;
    let deviceStatusFilteredCampaigns;
    let campaigns = this.state.emails;
    const matchedCampaigns = [];
    const search = this.refs.search && this.refs.search.value;

    if (search) {
      filteredEmails.forEach(item => {
        if (
          item.scenarioName.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          item.instanceId.toLowerCase().indexOf(search.toLowerCase()) > -1
        ) {
          matchedCampaigns.push(item);
        }
      });
      deviceStatusFilteredCampaigns = matchedCampaigns;
    } else {
      deviceStatusFilteredCampaigns = filteredEmails;
    }

    if (
      source === 'statusOrMediumChange' ||
      source === 'search' ||
      source === 'scroll'
    ) {
      sortType = this.state.sortType;
      sortOrder = this.state.sort;
      scrollCount = source === 'scroll' ? this.state.scrollCount : 0;
    } else {
      // source is checkbox selected
      sortType = eventParam;
      scrollCount = 0;
      if (this.state.sortType !== sortType) {
        sortOrder = 'DESC';
      } else {
        sortOrder = this.state.sort === 'ASC' ? 'DESC' : 'ASC';
      }
    }

    if (source !== 'scroll') {
      campaigns = [];
    }

    const reportIds = deviceStatusFilteredCampaigns.map(cmp => {
      return cmp.instanceId;
    });

    const boundaries = {};
    if (range) {
      boundaries.start = queryDate(range[0]);
      boundaries.end = queryDate(range[1]);
    } else {
      boundaries.start = queryDate(this.props.range[0]);
      boundaries.end = queryDate(this.props.range[1]);
    }

    if (deviceStatusFilteredCampaigns.length) {
      if (csSearchParams.indexOf(sortType) < 0) {
        // Server side sorting
        if (
          itemsPerScroll * (scrollCount + 1) - itemsPerScroll <
          deviceStatusFilteredCampaigns.length
        ) {
          uiActions.isLoading();
          this.setState({
            isLoading: true,
          });
          getRangedEmailReports(
            reportIds,
            boundaries,
            campaignReports => {
              uiActions.isLoaded();
              const newCampaigns = Object.keys(campaignReports).map(
                reportCampaignId => {
                  const targetCampaign = deviceStatusFilteredCampaigns.filter(
                    cmp => {
                      return cmp.instanceId === reportCampaignId;
                    },
                  );
                  return targetCampaign[0];
                },
              );
              const previousReports = this.state.reports;

              this.setState({
                isLoading: false,
                reports: campaignReports,
                canLoadMore:
                  Object.keys(campaignReports).length !==
                  Object.keys(previousReports).length,
                emails: campaigns.concat(newCampaigns),
                sort: sortOrder,
                sortType,
                scrollCount,
                showOptions: false,
                toggleIcon: 'preferences',
              });
            },
            {
              items: itemsPerScroll,
              page: scrollCount + 1,
              sortBy: sortType,
              reverse: sortOrder === 'DESC',
            },
          );
        }
      } else {
        // Client Side Sorting
        const campaignsByDate = [...deviceStatusFilteredCampaigns].sort(
          (a, b) => {
            if (sortOrder === 'DESC') {
              return b.startDate - a.startDate;
            }
            return a.startDate - b.startDate;
          },
        );
        const campaignIdsToGetReport = [];
        const latestExistingCampaignIndex = campaigns.length;
        const newCampaigns = campaignsByDate.slice(
          latestExistingCampaignIndex,
          latestExistingCampaignIndex + itemsPerScroll,
        );
        const campaignsToShow = campaigns.concat(newCampaigns);

        campaignsToShow.forEach(campaign => {
          campaignIdsToGetReport.push(campaign.instanceId);
        });

        this.setState(
          {
            isLoading: false,
            isFirstLoadComplete: true,
            emails: campaignsToShow,
            sort: sortOrder,
            sortType,
            scrollCount,
            showOptions: false,
            toggleIcon: 'preferences',
          },
          () => {
            if (campaignIdsToGetReport.length) {
              uiActions.isLoading();
              this.setState({
                isLoading: true,
              });
              getRangedEmailReports(
                campaignIdsToGetReport,
                boundaries,
                campaignReports => {
                  uiActions.isLoaded();
                  const previousReports = this.state.reports;

                  this.setState({
                    reports: campaignReports,
                    canLoadMore:
                      Object.keys(campaignReports).length !==
                      Object.keys(previousReports).length,
                    isLoading: false,
                  });
                },
                {},
              );
            }
          },
        );
      }
    } else {
      uiActions.isLoaded();
      this.setState({
        isLoading: false,
        isFirstLoadComplete: true,
        reports: {},
        canLoadMore: false,
        emails: [],
        sort: sortOrder,
        sortType,
        scrollCount,
        showOptions: false,
        toggleIcon: 'preferences',
      });
    }
  }

  exportAll() {
    const globalApiKey =
      getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
    const endpoint = getEndPoint('export/all/mail') + globalApiKey;
    const instanceId = '';
    let exportUrl = `${endpoint}&end=${queryDate(
      this.state.mailProps.range[1],
    )}&start=${queryDate(
      this.state.mailProps.range[0],
    )}&interval=total&limit=2000&instanceid=${instanceId}&authToken=${getToken()}`;
    if (isSwitchAccount() && !isMultiAccountUser()) {
      exportUrl += `&switchUser=${getSwitchAccountUser(true).username}`;
    }
    if (isMultiAccountUser() && isSwitchAccount()) {
      exportUrl += `&switchAccount=${getAccount().accountId.trim()}`;
    }
    exportUrl += `&switchRegion=${getRegion()}`;
    window.open(exportUrl);
  }

  increaseScrollCount() {
    this.setState(
      prevState => ({
        scrollCount: prevState.scrollCount + 1,
      }),
      () => {
        this.filterEmails('scroll', false, false);
      },
    );
  }

  render() {
    const isViewAll =
      !this.state.mailType || this.state.mailType === 'view-all';
    let exportAllButton;
    if (isViewAll) {
      const monthDiff = this.state.mailProps.range[1].diff(
        this.state.mailProps.range[0],
        'months',
      );
      if (monthDiff >= 3) {
        exportAllButton = (
          <div style={{ display: 'flex' }}>
            <Tooltip
              content={t(
                'This may take a while, please wait. Your report will be downloaded when it is ready',
              )}
              alignment='top'
              placement='bottom'
            >
              <a
                style={{
                  float: 'left',
                }}
                className='date-filter current'
                onClick={this.exportAll}
              >
                {t('Export All')}
              </a>{' '}
            </Tooltip>
          </div>
        );
      } else {
        exportAllButton = (
          <div style={{ display: 'flex' }}>
            <a
              style={{
                float: 'left',
              }}
              className='date-filter current'
              onClick={this.exportAll}
            >
              {t('Export All')}
            </a>
          </div>
        );
      }
    }
    if (this.state.isLoading && !this.state.isFirstLoadComplete) {
      // show placeholder
      return (
        <div>
          <div className='page-filters has-filter-form'>
            <EmailStatusFilters
              status={this.state.status}
              onStatusChange={this.onStatusChange}
            />
          </div>
          <div style={{ marginTop: '80px' }}>
            <LoaderPlaceholder />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className='page-filters has-filter-form'>
          <EmailStatusFilters
            status={this.state.status}
            onStatusChange={this.onStatusChange}
          />
        </div>
        <div className='page-options page-options-sort-search page-options-with-date'>
          <label className='item is-stacked is-search'>
            <Icon name='magnify' />
            <input
              type='text'
              placeholder={t('Search')}
              className='text-field'
              onKeyDown={this.onInputKeyDown}
              ref='search'
            />
          </label>

          <div className='data-preferences'>
            <a href='#' className='toggler' onClick={this.toggleOptions}>
              <Icon name={this.state.toggleIcon} />
              <span>Sort</span>
            </a>
            <ul
              className={
                !this.state.showOptions
                  ? 'data-preferences-options is-hidden'
                  : 'data-preferences-options'
              }
            >
              {sortingTypes.map(({ type, text }) => {
                const sortingTypesEl = this.state.sortType === type;
                // eslint-disable-jsx-a11y/no-noninteractive-element-interactions

                return (
                  <li
                    className='data-preferences-option'
                    key={type}
                    onClick={this.filterEmails.bind(this, 'event', type)}
                  >
                    <a href='#' className='data-preferences-option-item'>
                      {text}
                      {sortingTypesEl ? (
                        this.state.sort !== 'DESC' ? (
                          <i className='icon-caret-up viewall-sorting' />
                        ) : (
                          <i className='icon-caret-down viewall-sorting' />
                        )
                      ) : (
                        ''
                      )}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        {exportAllButton}
        <div
          style={{ position: 'relative', top: '50px' }}
          className='page-content-wrapper'
        >
          {this.state.emails.length ? (
            this.state.emails.map(email => (
              <EmailCard
                hideWidgetOptions
                rangeAlias={this.state.mailProps.rangeAlias}
                campaign={email}
                key={email.instanceId}
                report={
                  this.state.reports ? this.state.reports[email.instanceId] : []
                }
                isReport
              />
            ))
          ) : (
            <div className='no-report'>
              <p className='no-report-info'>{t('no-campaign-info', '')}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  update: store.filters.update,
  reset: store.filters.reset,
  status: store.campaigns.status,
});

export default connect(mapStatesToProps)(Report);
