import React, { useState } from 'react';
import classes from './SFYNoteContainer.module.scss';
import Icons from '../../icons';
import { t } from '../../../system/ui';
import {
  isLoaded,
  isLoading,
  showErrorNotification,
  showNotification,
} from '../../../actions/ui';
import './note-modal.scss';
import { TextArea } from '../module-exports';
import CustomerProfileService from '../../../views/Audience/customer-profile/services/CustomerProfileService';

const SFYNoteContainer = ({ id, notes, noteSetter }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onClickHandler = () => {
    isLoading();
    try {
      CustomerProfileService.updateWithRequestParam({
        id,
        requestKey: 'note',
        requestParam: notes,
      }).then(response => {
        setIsModalVisible(false);
        isLoaded();
        showNotification({
          content: () => <p>{response}</p>,
        });
      });
    } catch (e) {
      showErrorNotification({
        content: () => <p>{e}</p>,
      });
    }
  };

  const modalFlowHandler = () => {
    CustomerProfileService.get({ id }).then(({ notes }) => {
      noteSetter(notes);
    });
    setIsModalVisible(false);
  };

  return (
    <div className={classes['sfy-note-container']}>
      <div className={classes['sfy-note-header']}>
        <div className={classes['note-desc']}>
          <span className={classes['icon-m']}>
            <Icons name='cpNotePadIcon' />
          </span>
          <span className={classes['sfy-note-text']}>{t('Notes')}</span>
        </div>
        <div
          className={classes['note-actions']}
          onClick={() => setIsModalVisible(true)}
        >
          <span className={classes['icon-m']}>
            <Icons name='edit' color='#47607B' height='14' width='14' />
          </span>
          {t('Edit')}
        </div>
      </div>
      <div
        className={`${
          isExpanded
            ? classes['note-content-expanded']
            : classes['note-content']
        }`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {notes || (
          <div className={classes['note-content-empty']}>
            {t('Type Here...')}
          </div>
        )}
      </div>
      {isModalVisible && (
        <div className='sfy-modal--customer-notes'>
          <div className='cover' />
          <div className='content'>
            <div className='header'>
              <h3>Notes</h3>
              <div className='modal-close' onClick={modalFlowHandler}>
                X
              </div>
            </div>
            <div className='text'>
              <TextArea
                name='note'
                id='note'
                cols='50'
                rows='10'
                value={notes}
                onChange={noteSetter}
                placeholder='Type Here...'
              />
              <button
                type='submit'
                className='dialog-confirm-button'
                onClick={onClickHandler}
              >
                {t('Submit')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SFYNoteContainer;
