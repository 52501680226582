import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { TextField } from '../fields';
import { operatorObjToArray } from './utils/utils';

import {
  urlOperators,
  operatorColumns,
} from '../../constants/datamaps/rule-based-segmentation';

/**
 * @name CriteriaFilterUrl
 * @description Component to filter url
 * @param {object} criteriaData - The criteria data
 * @param {string} filterType - The filter type
 * @param {array} statements - The statements
 * @param {function} callbackFunc - The callback function
 * @returns {JSX.Element}
 */

const criteriaFilterUrl = ({
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const defaultFilterData = {
    dataType: criteriaData?.dataType,
    column: operatorColumns.urlOperator,
    operator: urlOperators.equals.value,
    value: '',
  };

  const [url, setUrl] = useState();
  const [selectedOperator, setSelectedOperator] = useState('');

  useEffect(() => {
    const tempStatements = statements?.[filterType];

    if (tempStatements?.length === 1) {
      setUrl(tempStatements[0].value);
      setSelectedOperator(tempStatements[0].operator);
    } else {
      setSelectedOperator(defaultFilterData.operator);
      setUrl(defaultFilterData.value);
    }
  }, [statements]);

  const triggerCallbackFunc = (val, type) => {
    const columnVal = operatorColumns.urlOperator;
    const operatorVal = type === 'operator' ? val.value : selectedOperator;
    const urlVal = type === 'url' ? val.target.value : url;

    const filterData = [
      {
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator: operatorVal,
        value: urlVal,
      },
    ];

    callbackFunc(filterType, filterData);
  };

  const onChange = (selectedValue, type) => {
    switch (type) {
      case 'url':
        setUrl(selectedValue.target.value);
        break;
      case 'operator':
        setSelectedOperator(selectedValue?.value);
        break;
      default:
        break;
    }

    triggerCallbackFunc(selectedValue, type);
  };

  return (
    <div className='rbs-filter-field'>
      <div className='filter-operator'>
        <span className='filter-operator-title'>Operator*</span>
        <Select
          value={selectedOperator}
          options={operatorObjToArray(urlOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          onChange={e => onChange(e, 'operator')}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      <div className='filter-connector' />
      <div className='filter-operator'>
        <span className='filter-operator-title'>URL*</span>
        <TextField
          value={url}
          onChange={e => onChange(e, 'url')}
          type='text'
          required
        />
      </div>
    </div>
  );
};

export default criteriaFilterUrl;
