"use strict";
import React from "react";
import { clone } from "../../system/object";
import { toCamelCase } from "../../system/string";
import {
  calculateRatio,
  numericCalculateRatio,
  diffRatio,
} from "../../system/ratio";
import { trendsMap } from "../../constants/datamaps/trends";
const transformTrends = (
  trendData,
  purchaseAmount
) => {
  let trends = clone(trendsMap);

  let mappedTrends = {
    impression: 0,
    widgetView: 0,
    click: 0,
    basketQuantity:0,
    basketItems: 0,
    basketAmount:0,
    purchases:0,
    purchasedItems:0,
    purchaseAmount: 0,
    purchaseAmountPrev: 0,
    "purchase:amount":0,
    "purchase:amountPrev": 0,
  },
  totalRevenue = purchaseAmount || 0;

  if (trendData && trendData.value.length > 0) {
    trendData.value.forEach((item) => {
      mappedTrends[toCamelCase(item.x)] =
        mappedTrends[toCamelCase(item.x)] + item.y;
    });
  }


if(totalRevenue===0 && mappedTrends.hasOwnProperty("purchase:amount")){
  totalRevenue=mappedTrends["purchase:amount"];
}

  trends.widget.stats.view.count = mappedTrends.widgetView || trends.widget.stats.view.count;  //view
  trends.widget.stats.all.count =mappedTrends.impression || trends.widget.stats.all.count; //impression
  trends.widget.stats.conversion.ratio = calculateRatio(
    trends.widget.stats.view.count,
    trends.widget.stats.all.count
  ); 

  trends.interaction.stats.all.count = mappedTrends.click || trends.interaction.stats.all.count; //click
  trends.interaction.stats.conversion.ratio = calculateRatio(
    trends.interaction.stats.all.count,
    trends.widget.stats.view.count
  );

  trends.basket.stats.all.count =mappedTrends.basketItems || trends.basket.stats.all.count;//basket
  trends.basket.stats.conversion.ratio = calculateRatio(
    trends.basket.stats.all.count,
    trends.interaction.stats.all.count
  );

  trends.revenue.stats.all.count = mappedTrends.purchaseAmount || trends.revenue.stats.all.count; //renevue
  trends.revenue.stats.purchases.count =mappedTrends.purchases || trends.revenue.stats.purchases.count; //purchases
  trends.revenue.stats.items.count =mappedTrends.purchasedItems || trends.revenue.stats.items.count; //purchased products

  trends.revenue.stats.conversion.ratio = calculateRatio(
    trends.revenue.stats.items.count,
    trends.interaction.stats.all.count
  ); 

  trends.revenue.stats.contribution.ratio = calculateRatio(
    trends.revenue.stats.all.count,
    totalRevenue
  ); 

  trends.revenue.stats.diff = {
    name: "Diff",
    ratio: 0
  };

  let numericRatio = numericCalculateRatio(
    trends.revenue.stats.all.count,
    totalRevenue
  );

  let numericRatioPrev = numericCalculateRatio(
    mappedTrends.purchaseAmountPrev,
    mappedTrends["purchase:amountPrev"]
  );

  const diff = diffRatio(numericRatio, numericRatioPrev);

  trends.revenue.stats.diff.ratio = (
    <span style={{ color: "#f0000" }}>
      <span>{diff}</span>
      {numericRatio > numericRatioPrev && (
        <span style={{ color: "rgb(86, 188, 148)" }}>▲</span>
      )}

      {numericRatio < numericRatioPrev && (
        <span style={{ color: "#cc4331" }}>▼</span>
      )}
    </span>
  );

  return trends;
};
export default transformTrends;
