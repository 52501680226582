/**
 * Module dependencies
 */
import React from 'react';
import classes from './UserActivityCard.module.scss';

import { t } from '../../../../system/ui';
import NoImage from '../../../../images/noImage.svg';
import Tooltip from '../../../tooltip';
import { DateFormatter } from '../../utils';
import { isEmpty } from 'lodash';

/**
 * Creates an item for user activity card
 * @param activityType
 * @param newItem
 * @constructor
 */
const ActivityRender = ({ activityType, newItem, currency }) => {
  /**
   * Helper method to create a title
   * @param {Object} type
   * @param {string} key
   * @return {string} Activity title
   */
  const setTitle = (type, key) => {
    let title = '';
    if (typeof type[key] === 'undefined') {
      if (!newItem[key]?.returnDate) {
        // TODO: This is the not good way, activity can be converted into the array, caused by unique object key. *****
        title = activityType[key.slice(0, key.length - 1)];
      }
    } else {
      title = activityType[key];
    }

    return title;
  };

  return Object.keys(newItem).map(key => {
    return (
      <div
        className={classes['user-last-activity-content']}
        key={Math.random() * 1000}
      >
        <div className={classes['user-last-activity-title']}>
          <span>{setTitle(activityType, key)}</span>
          {newItem[key]?.returnDate && (
            <div className={classes['user-last-activity-return-date']}>
              {DateFormatter(newItem[key]?.returnDate, 'DD-MM-YY', '/')}
            </div>
          )}
        </div>
        <div className={classes['user-last-activity-description']}>
          <Tooltip
            content={
              <div className={classes['user-last-activity-tooltip-image']}>
                <img
                  src={newItem[key]?.image || NoImage}
                  alt={newItem[key]?.name || t('Unknown')}
                />
              </div>
            }
          >
            <div className={classes['user-last-activity-description-img']}>
              <img
                src={newItem[key]?.image || NoImage}
                alt={newItem[key]?.name || t('Unknown')}
              />
            </div>
          </Tooltip>
          {newItem[key] && !isEmpty(newItem[key]) ? (
            <div className={classes['user-last-activity-description-text']}>
              <Tooltip
                content={newItem[key]?.category || t('Unknown')}
                alignment='left'
              >
                <div
                  className={classes['user-last-activity-description-category']}
                >
                  {newItem[key]?.category || t('Unknown')}
                </div>
              </Tooltip>
              <Tooltip
                content={newItem[key]?.productId || t('Unknown')}
                alignment='left'
              >
                <div
                  className={
                    classes['user-last-activity-description-productId']
                  }
                >
                  {t('Product ID')} : {newItem[key]?.productId || t('Unknown')}
                </div>
              </Tooltip>
              <Tooltip
                content={newItem[key]?.name || t('Unknown')}
                alignment='left'
              >
                <div
                  className={
                    classes['user-last-activity-description-productName']
                  }
                >
                  {newItem[key]?.name || t('Unknown')}
                </div>
              </Tooltip>
              <div className={classes['user-last-activity-description-price']}>
                {newItem[key]?.price + ' ' + currency || t('Unknown')}
              </div>
            </div>
          ) : (
            <div className={classes['segment-content-nodata']}>
              <i className='icon-info' color='#979797' />
              {t('There is no data for now')}
            </div>
          )}
        </div>
      </div>
    );
  });
};

const ModalListRender = ({ singleItem, item, currency }) => {
  return singleItem ? (
    <div
      className={classes['user-last-activity-content']}
      key={Math.random() * 1000}
    >
      <div className={classes['user-last-activity-description']}>
        <Tooltip
          content={
            <div className={classes['user-last-activity-tooltip-image']}>
              <img
                src={item?.image || NoImage}
                alt={item?.name || t('Unknown')}
              />
            </div>
          }
        >
          <div className={classes['user-last-activity-description-img']}>
            <img src={item?.image || NoImage} alt='' />
          </div>
        </Tooltip>
        {item && !isEmpty(item) ? (
          <div className={classes['user-last-activity-description-text']}>
            <Tooltip content={item?.category || t('Unknown')} alignment='left'>
              <div
                className={classes['user-last-activity-description-category']}
              >
                {item?.category || t('Unknown')}
              </div>
            </Tooltip>
            <Tooltip content={item?.productId || t('Unknown')} alignment='left'>
              <div
                className={classes['user-last-activity-description-productId']}
              >
                {t('Product ID')} : {item?.productId || t('Unknown')}
              </div>
            </Tooltip>
            <Tooltip content={item.name || t('Unknown')} alignment='left'>
              <div
                className={
                  classes['user-last-activity-description-productName']
                }
              >
                {item.name || t('Unknown')}
              </div>
            </Tooltip>
            <div className={classes['user-last-activity-description-price']}>
              {item.price + ' ' + currency || t('Unknown')}
            </div>
          </div>
        ) : (
          <div className={classes['segment-content-nodata']}>
            <i className='icon-info' color='#979797' />
            {t('There is no data for now')}
          </div>
        )}
      </div>
    </div>
  ) : item.length && !isEmpty(item) ? (
    item.map(({ category, productId, name, price, image }) => {
      return (
        <div
          className={classes['user-last-activity-content--border-bottom']}
          key={Math.random() * 1000}
        >
          <div className={classes['user-last-activity-description']}>
            <Tooltip
              content={
                <div className={classes['user-last-activity-tooltip-image']}>
                  <img src={image || NoImage} alt={name || t('Unknown')} />
                </div>
              }
            >
              <div className={classes['user-last-activity-description-img']}>
                <img src={image || NoImage} alt='' />
              </div>
            </Tooltip>
            {item.every(el => el !== null) ? (
              <div className={classes['user-last-activity-description-text']}>
                <Tooltip content={category || t('Unknown')} alignment='left'>
                  <div
                    className={
                      classes['user-last-activity-description-category']
                    }
                  >
                    {category || t('Unknown')}
                  </div>
                </Tooltip>
                <Tooltip content={productId || t('Unknown')} alignment='left'>
                  <div
                    className={
                      classes['user-last-activity-description-productId']
                    }
                  >
                    {t('Product ID')} : {productId || t('Unknown')}
                  </div>
                </Tooltip>
                <Tooltip content={name || t('Unknown')} alignment='left'>
                  <div
                    className={
                      classes['user-last-activity-description-productName']
                    }
                  >
                    {name || t('Unknown')}
                  </div>
                </Tooltip>
                <div
                  className={classes['user-last-activity-description-price']}
                >
                  {price + ' ' + currency || t('Unknown')}
                </div>
              </div>
            ) : (
              <div className={classes['segment-content-nodata']}>
                <i className='icon-info' color='#979797' />
                {t('There is no data for now')}
              </div>
            )}
          </div>
        </div>
      );
    })
  ) : (
    <div className={classes['nodata-with-image']}>
      <div className={classes['user-last-activity-description-img']}>
        <img src={NoImage} alt='' />
      </div>
      <div className={classes['segment-content-nodata']}>
        <i className='icon-info' color='#979797' />
        {t('There is no data for now')}
      </div>
    </div>
  );
};

export { ActivityRender, ModalListRender };
