/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { t } from "../../../../system/ui";
import { clone } from "../../../../system/object";

import Icons from "../../../../components/icons";
import { Select } from "../../../../components/fields";
import Tooltip from "../../../../components/tooltip";

const defaults = {
  verticalPosition: "middle",
  horizontalPosition: "center"
};

const selectOptions = {
  vertical: [
    { value: "top", label: t("Top") },
    { value: "middle", label: t("Middle") },
    { value: "bottom", label: t("Bottom") }
  ],
  horizontal: [
    { value: "left", label: t("Left") },
    { value: "center", label: t("Center") },
    { value: "right", label: t("Right") }
  ]
};

class PositionOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: clone(defaults)
    };

    this.handleVerticalPosition = this.handleVerticalPosition.bind(this);
    this.handleHorizontalPosition = this.handleHorizontalPosition.bind(this);
    this.onChange = this.onChange.bind(this);
    this.update = this.update.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.setOptions = this.setOptions.bind(this);
    this.setVisibility = this.setVisibility.bind(this);
    this.setDefaults = this.setDefaults.bind(this);
  }

  componentDidMount() {
    if (this.props.duplicate && this.props.duplicate.isDuplicate) {
      this.update(
        this.props.campaign,
        this.props.duplicate.duplicatedValues[7],
        this.props.duplicate.duplicatedValues[8]
      );
    } else {
      this.update(this.props.campaign);
    }
    this.setDefaults(this.props.options);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.update(newProps.campaign);
    }

    if (this.props.options !== newProps.options) {
      this.setDefaults(newProps.options);
    }
  }

  update(campaign, vertical, horizontal) {
    let verticalPosition =
        campaign.verticalPosition || clone(defaults.verticalPosition),
      horizontalPosition =
        campaign.horizontalPosition || clone(defaults.horizontalPosition);

    if (this.props.isRecommendationModule) {
      verticalPosition =
        vertical || campaign.actions[0].params.verticalPosition;
      horizontalPosition =
        horizontal || campaign.actions[0].params.horizontalPosition;
    }

    if (vertical && horizontal) {
      verticalPosition = vertical;
      horizontalPosition = horizontal;
    }

    this.updateValue(verticalPosition, "verticalPosition");
    this.updateValue(horizontalPosition, "horizontalPosition");
  }

  updateValue(newValue, type) {
    let values = this.state.values;
    values[type] = newValue;
    this.setState({ values });
  }

  onChange(selection, type) {
    let values = this.state.values;
    values[type] = selection.value;
    this.setState({ values });
  }

  handleVerticalPosition(selection) {
    this.onChange(selection, "verticalPosition");
  }

  handleHorizontalPosition(selection) {
    this.onChange(selection, "horizontalPosition");
  }

  setDefaults(options) {
    if (this.props.editForm) {
      return;
    }

    let values = this.state.values;

    if (options.vertical.default) {
      values.verticalPosition = options.vertical.default;
    }

    if (options.horizontal.default) {
      values.horizontalPosition = options.horizontal.default;
    }

    this.setState({ values });
  }

  setOptions(type) {
    let options = [];

    selectOptions[type].forEach(option => {
      if (
        this.props.options[type].values &&
        this.props.options[type].values.includes(option.value)
      ) {
        options.push(option);
      }
    });

    return options;
  }

  setVisibility(type) {
    let visibility = true;

    if (this.props.options[type] && this.props.options[type].show === false) {
      visibility = false;
    }

    return visibility;
  }

  render() {
    if (!this.props.isPositionVisible) {
      return false;
    }

    let vertical = this.setVisibility("vertical"),
      horizontal = this.setVisibility("horizontal");

    let verticalOptions = this.setOptions("vertical"),
      horizontalOptions = this.setOptions("horizontal");

    return (
      <div className="wizard-input-type-wrapper">
        <h3 className="wizard-input-type-title">
          <Icons name="position" />
          {t("Position")}
          <span className="field-tooltip-icon">
            <Tooltip
              content={t("position_" + this.props.campaignType)}
              alignment="left"
            >
              <i className="icon-info" role="presentation">
                <span className="for-screenreader-only" />
              </i>
            </Tooltip>
          </span>
        </h3>
        <ol className="form-elements">
          <li className="row">
            {vertical ? (
              <Select
                name="verticalPosition"
                className="item-field medium"
                label={t(this.props.options.vertical.label)}
                value={this.state.values.verticalPosition}
                options={verticalOptions}
                onChange={this.handleVerticalPosition}
                tooltipContent={t(
                  "verticalPosition_" + this.props.campaignType
                )}
              />
            ) : (
              ""
            )}
            {horizontal ? (
              <Select
                name="horizontalPosition"
                className="item-field medium"
                label={t(this.props.options.horizontal.label)}
                value={this.state.values.horizontalPosition}
                options={horizontalOptions}
                onChange={this.handleHorizontalPosition}
                tooltipContent={t(
                  "horizontalPosition_" + this.props.campaignType
                )}
              />
            ) : (
              ""
            )}
          </li>
        </ol>
      </div>
    );
  }
}

PositionOptions.defaultProps = {
  options: {
    vertical: {
      show: true,
      label: "Vertical Align",
      default: "middle",
      values: ["top", "middle", "bottom"]
    },
    horizontal: {
      show: true,
      label: "Horizontal Align",
      default: "center",
      values: ["left", "center", "right"]
    }
  }
};

const MapStatesToProps = store => ({
  isPositionVisible: store.wizard.isPositionVisible,
  editForm: store.wizard.editForm
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  PositionOptions
);
