/* eslint-disable react/no-unescaped-entities */
/**
 * @author Mehmet Yurtar
 */

/* eslint react/display-name: "off" */

import React from 'react';

// shared of config translations
// add component translation files together with the component
export const en = {
  // Menu
  Dashboard: 'Dashboard',
  Recommend: 'Recommend',
  'View All': 'View All',
  Report: 'Report',
  Reports: 'Reports',
  'Executive Summary': 'Executive Summary',
  Sales: 'Sales',
  Trendify: 'Trendify',
  Products: 'Products',
  Categories: 'Categories',
  Brands: 'Brands',
  Insights: 'Insights',
  Bannerify: 'Bannerify',
  Settings: 'Settings',
  Account: 'Account',
  Plugins: 'Plugins',
  Payments: 'Payments',
  Integrations: 'Integrations',
  Currency: 'Currency',
  'Segmentation Module': 'Segmentation',
  'Recommendation Settings': 'Recommendation',

  // Dashboard
  'WidgetActual View': 'View',
  WidgetConversion: 'View Rate',
  'WidgetTotal View': 'Impression',
  ImpressionRate: 'Impression Rate',
  InteractionConversion: 'CTR',
  'InteractionLeft Arrow Click': 'Left Arrow Click',
  'InteractionRight Arrow Click': 'Right Arrow Click',
  'BasketTotal Amount': 'Total Amount',
  'BasketAverage Contribution': 'Average Contribution',
  BasketConversion: 'Add to Basket Rate',
  RevenuePurchases: 'Purchases',
  'RevenuePurchase Items': 'Purchased Items',
  RevenueConversion: 'Conversion Rate',
  'RevenueContribution Ratio': 'Contribution Ratio',

  view: 'views',
  item: 'product',
  views: 'views',
  click: 'click',
  banner_clicks: 'banner clicks',
  product_clicks: 'clicks',
  items: 'products',

  'Segmentify Performance': 'Segmentify Performance',
  'Your Website': 'Your Website',

  Widget: 'Widget',
  Interaction: 'Interaction',
  Revenue: 'Revenue',
  PageView: 'Page View',
  Device: 'Device',
  'Audience-Website': 'Website Visitors',
  Audience: 'Audience',
  Overall: 'Overall',
  'Revenue (Overall)': 'Revenue (Overall)',
  'Average Sessions': 'Average Sessions',
  'Actual View': 'Views',
  Conversion: 'Conversion Rate',
  'Total Amount': 'Total Amount',
  'Average Contribution': 'Average Contribution',
  Purchases: 'Purchases',
  'Purchase Items': 'Purchased Products',
  'Average Basket': 'Average Basket Value',
  'Contribution Ratio': 'Contribution Ratio',
  PC: 'PC',
  Mobile: 'Mobile',
  Tablet: 'Tablet',
  'Unique Visitors': 'Unique Visitors',
  'Unique Sessions': 'Unique Sessions',
  'Pages/Sessions': 'Pages/Sessions',
  'Product/Sessions': 'Product/Sessions',

  // Recommendation Page Types
  Page: 'Page',
  'Home Page': 'Home Page',
  'Category Page': 'Category Page',
  'Product Page': 'Product Page',
  'Basket Page': 'Basket Page',
  'Search Page': 'Search Page',
  '404 Page': '404 Page',
  'Custom Pages': 'Custom Pages',
  'Trigger Based Page': 'Trigger Based Campaign',
  'Other Pages': 'Other Pages',
  'Custom Page': 'Custom Page',
  Home: 'Home',
  Category: 'Category',
  Product: 'Product',
  Basket: 'Basket',
  Search: 'Search',
  Palantir: 'Search',
  Other: 'Other',

  // Campaign Actions
  Actions: 'Actions',
  Edit: 'Edit',
  Duplicate: 'Duplicate',
  History: 'History',

  // Persona Quiz
  Add_New_Response: 'Add New Response',

  'Live Campaigns': 'Live Campaigns',
  'Test Campaigns': 'Test Campaigns',

  // When there is no Active Campaign
  NoActiveHomeCampaigns: () => {
    return (
      <div>
        <p>
          The trending homepage design for e-commerce is quite banner heavy
          which is simply providing the campaign banners and category specific
          banners in shop window. This is missing the opportunity to guide the
          customers for direct conversion from homepage by placement of
          “Selected for you” widgets including personalised products enriched
          with appropriate call to action buttons.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new home page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveCategoryCampaigns: () => {
    return (
      <div>
        <p>
          Category pages are landing so much visitors but bounce rates in
          e-commerce from category pages are also so high. A solution provided
          by Segmentify for high bounce rates from category pages is using
          “Trending in Category” product recommendation widgets and include the
          popular products in category selected based on the behaviour of the
          current visitor.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new category page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveProductCampaigns: () => {
    return (
      <div>
        <p>
          Product pages in e-commerce belong to the heart of conversion and all
          conversion rate optimisation efforts focus on these pages. A regular
          product page at least includes Product title, Images, Price,
          Availability and Add to cart button. Other components such as Product
          detail, Sizing, Colour options and others are the tools that are used
          to represent as much information as possible to the visitors about the
          products.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new product page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveBasketCampaigns: () => {
    return (
      <div>
        <p>
          Increase average basket size by cross-selling related products in
          basket/cart pages. Majority of customers are willing to add at least
          one more product to their shopping cart if right product is offered to
          them.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new basket page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveSearchCampaigns: () => {
    return (
      <div>
        <p>
          Visitors became frustrated when they can't finding no product when
          they searched keywords on online stores, and bounce rates on search
          pages are much higher than other pages. With Segmentify, you can offer
          variety of products inside widgets on search result page to decrease
          bounce rates and gaining back leaving visitors.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new search page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActive404Campaigns: () => {
    return (
      <div>
        <p>
          When you removed a url from your store, this pages actually doesn't
          remove from search engines and other external links. When visitors
          clicks this url, they will face a 404 page without no navigation, and
          they will definitely bounce from this page. With Segmentify, you can
          offer variety of products inside widgets on 404 page to decrease
          bounce rates and gaining back leaving visitors.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new 404 page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveCustomCampaigns: () => {
    return (
      <div>
        <p>
          When you need a campaign on a page that are not predefined in
          Segmentify, you can define custom page campaings like user account
          page, checkout page etc.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new custom page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  'NoActiveTrigger BasedCampaigns': () => {
    return (
      <div>
        <p>
          When you need a campaign based on an event instead of a page, you can
          define trigger based campaigns.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new trigger based recommendation widget.</strong>
        </p>
      </div>
    );
  },

  'Need Help?': 'Need Help?',
  'Full Width': 'Full Width',
  'Right/Left Side': 'Right/Left Side',
  'Add Custom Style Widget': 'Add Custom Style Widget',

  // Add Campaign
  'Widget Title': 'Widget Title',
  'This title will shown on your widget':
    'This title will be shown on your widget',

  Intelligent: 'Intelligent',
  Criteria: 'Filter (Include)',
  Excludes: 'Filter (Exclude)',
  'Shuffle Items': 'Shuffle Products',
  'Smart Offers': 'Smart Offers',
  'All Category Products': 'All Category Products',
  'All Child Category Products': 'All Child Category Products',
  'All Parent Category Products': 'All Parent Category Products',
  'All Products': 'All Products',
  'Last Visited (Product)': 'Last Visited (Product)',
  'Purchased Together (Basket)': 'Purchased Together (Basket)',
  'Purchased Together (Product)': 'Purchased Together (Product)',
  'Viewed Together (Basket)': 'Viewed Together (Basket)',
  'Viewed Together (Product)': 'Viewed Together (Product)',
  'Rockstar Products': 'Rockstar Products',
  'Trending Products': 'Trending Products',
  'Shining Stars': 'Shining Stars',
  'Discounted Products': 'Discounted Products',
  'Brand Products': 'Brand Products',
  'Alternative Products': 'Alternative Products',
  'All Category Product': 'All Category Product',
  'View - Today': 'View - Today',
  'View - This Week': 'View - This Week',
  'View - This Month': 'View - This Month',
  'Purchase - Today': 'Purchase - Today',
  'Purchase - This Week': 'Purchase - This Week',
  'Purchase - This Month': 'Purchase - This Month',
  'New Arrivals': 'New Arrivals',
  'Product Name': 'Product Name',
  'Product Name/ID': 'Product Name/ID',
  'Price Range': 'Price Range',
  Custom: 'Custom',
  Param: 'Param',
  Value: 'Value',
  Brand: 'Brand',
  Label: 'Label',
  Gender: 'Gender',
  Size: 'Size',

  min: 'min',
  max: 'max',

  Equals: 'Equals',
  'Not Equals': 'Not Equals',
  Contains: 'Contains',
  'Not Contains': 'Not Contains',

  'Campaign Subject': 'Campaign Subject',

  'Start Date - Time': 'Start Date - Time',
  'End Date - Time': 'End Date - Time',

  'Device Types': 'Device Types',
  Web: 'Web',
  App: 'App',

  'You should select at least one device type':
    'You should select at least one device type',

  'Target Div Selector': 'Target Div Selector',
  'The target component on your website where recommendation box going to insert itself':
    'The target component on your website where recommendation box going to insert itself',

  Self: 'Into',
  Before: 'Before',
  After: 'After',
  Append: 'Append',
  Prepend: 'Prepend',

  'Edit HTML': 'Edit HTML',
  'Edit PreJS': 'Edit PreJS',
  'Edit PostJS': 'Edit PostJS',

  'Advanced Configuration': 'Advanced Configuration',
  'Go Test': 'Go Test',
  'Go Live': 'Go Live',

  'Attention!': 'Attention!',
  'This action will immediately effect on your website':
    'This action will immediately effect on your website',

  'no-recommendation-report-info': statusAndCampaign => {
    return (
      <span>
        There is <strong>No {statusAndCampaign}</strong> on this page
      </span>
    );
  },

  'no-personalization-report-info': statusAndCampaign => {
    return (
      <span>
        There is <strong>No {statusAndCampaign}</strong>{' '}
      </span>
    );
  },

  'no-campaign-info': status => {
    return (
      <span>
        There is <strong>No {status} Campaign</strong>
      </span>
    );
  },

  'no-segment-info': status => {
    return (
      <span>
        There is <strong>No {status} Segment</strong>
      </span>
    );
  },
  'Create Now': 'Create Now',
  'Campaign Report': 'Campaign Report',

  // Trendify
  'Product Stock': 'Product Stock',
  'Only In Stock': 'Only In Stock',
  'Only Out of Stock': 'Only Out of Stock',
  'All Product Categories': 'All Product Categories',
  'Search Category': 'Search Category',
  'Product Category': 'Product Category',
  'Product ID or Title': 'Product ID or Title',
  'Search Product': 'Search Product',
  'Product Brand': 'Product Brand',
  'All Product Brands': 'All Product Brands',
  Segmentify: 'Segmentify',
  Sources: 'Sources',
  'Check-in': 'Check-in',
  'Product Check-in Time': 'Product Check-in time',
  ID: 'ID',
  'Product ID': 'Product ID',
  'Product name': 'Product name',
  'Product Categories': 'Product Categories',
  Price: 'Price',
  OldPrice: 'Old Price',
  'Product price and discounted price': 'Product price and discounted price',
  Image: 'Image',
  'Product Image Url': 'Product Image Url',
  View: 'View',
  'Number Of Product Views': 'Number of product Views',
  'Number Of Add To Basket Operations': 'Number of Add to Basket Operations',
  Rate: 'Rate',
  'Conversion Rate (Purchase/View)': 'Conversion Rate (Purchase/View)',
  'Conversion Rate (Add To Basket/View)':
    'Conversion rate (Add to Basket/View)',
  Purchase: 'Purchase',
  'Number Of Product Purchases': 'Number of product purchases',
  Amount: 'Amount',
  'Amount Of Product Purchases': 'Amount Of Product Purchases',
  'Number Of Clicks By Segmentify Recommendation Widgets':
    'Number of clicks By Segmentify Recommendation Widgets',
  'Number Of Add To Basket Operations By Segmentify Recommendation Widgets':
    'Number Of Add To Basket Operations By Segmentify Recommendation Widgets',
  'Conversion Rate (Add To Basket/Click)':
    'Conversion Rate (Add To Basket/Click)',
  'Number Of Purchases By Segmentify Recommendation Widgets':
    'Number Of Purchases By Segmentify Recommendation Widgets',
  'Conversion Rate (Purchase/Click)': 'Conversion Rate (Purchase/Click)',
  'Amount Of Purchases By Segmentify Recommendation Widgets':
    'Amount Of Purchases By Segmentify Recommendation Widgets',
  'Views Referred From In-site': 'Views Referred From In-site',
  'Views Referred From Social Media': 'Views Referred From Social Media',
  'Views Referred From Search Engines': 'Views Referred From Search Engines',
  'Views Referred From Advertisements': 'Views Referred From Advertisements',
  'Views Referred From Email Campaigns': 'Views Referred From Email Campaigns',
  'Views Referred From Price Listing Sites':
    'Views Referred From Price Listing Sites',
  'Views Referred From Other Sources': 'Views Referred From Other Sources',
  Show: 'Show',
  Campaigns: 'Campaigns',
  TrendifyPromo: () => {
    return (
      <span>
        Monitor your <strong>products performance</strong> in{' '}
        <strong> Real-time</strong>
      </span>
    );
  },
  'something-went-wrong':
    'Something went wrong while loading the page, try refresh the page',
  'campaigns-not-found': 'Campaigns not found, please try refresh the page',

  // Insights
  'Whenever your customers see these products they buy them':
    'Whenever your customers see these products they buy them',
  'High Spending': 'High Spending',
  'Most viewed products with a referral from a paid channel':
    'Most viewed products with a referral from a paid channel',
  'The best of a bad bunch': 'The best of a bad bunch',
  'These are the most viewed products among the none-selling ones':
    'These are the most viewed products among the none-selling ones',
  'Discount Works': 'Discount Works',
  'Most selling products among the discounted ones':
    'Most selling products among the discounted ones',
  'We Wish You Had': 'We Wish You Had',
  'Most viewed products that are out of stock':
    'Most viewed products that are out of stock',
  'Abandoned Items': 'Abandoned Items',
  'Which products your customers left in their cart':
    'Which products your customers left in their cart',
  'Organic Bazaar': 'Organic Bazaar',
  'Most viewed products with a referral from Search Engine':
    'Most viewed products with a referral from Search Engine',
  'Social Trends': 'Social Trends',
  'Most viewed products with a referral from Social Media':
    'Most viewed products with a referral from Social Media',
  'You push hard': 'You push hard',
  "Your customers see these products but they don't buy them":
    "Your customers see these products but they don't buy them",
  'Products to Improve': 'Products to Improve',
  'Worst converting products': 'Worst converting products',
  'Most Revenue Growth (prior to compared period)':
    'Most Revenue Growth (prior to compared period)',
  'Losing Power': 'Losing Power',
  'Most Revenue Loss (prior to compared period)':
    'Most Revenue Loss (prior to compared period)',
  'An increasing trend occurred for these items in a selected basis':
    'An increasing trend occurred for these items in a selected basis',
  'Under Shadow': 'Under Shadow',
  'A decreasing trend occurred for these items in a selected basis':
    'A decreasing trend occurred for these items in a selected basis',
  'Basket Complementaries': 'Basket Complementaries',
  'List of basket complementaries for the top selling products':
    'List of basket complementaries for the top selling products',
  allView: 'Total Views',
  purchase: 'Purchased Products',
  basket: 'Add to Baskets',
  allViewCurWeek: "Second Period's Views",
  purchaseCurWeek: "Second Period's Purchases",
  allViewLastWeek: "First Period's Views",
  purchaseLastWeek: "First Period's Purchases",
  allPaidClick: 'Clicks From Paid Channel',
  allSearchClick: 'Clicks From Search Engine',
  allSocialClick: 'Clicks From Social Media Channels',
  allAmountLastWeek: 'Revenue First Period',
  allAmountCurWeek: 'Revenue Second Period',
  amount: 'Revenue',

  // Bannerify
  Banner: 'Banner',
  'Banner Group': 'Banner Group',
  'Banner Titles': 'Banner Titles',
  'All Groups': 'All Groups',
  'All Titles': 'All Titles',
  'Show Active Banners': 'Show Active Banners',
  'Show All Banners': 'Show All Banners',
  Group: 'Group',
  'Banner Order': 'Banner Order',
  'Check-in Time of Banner': 'Check-in Time of Banner',
  'Last Update': 'Last Update',
  'Last Update Time of Banner': 'Last Update Time of Banner',
  Title: 'Title',
  'Banner Title': 'Banner Title',
  'Banner Image Url': 'Banner Image Url',
  Targets: 'Targets',
  'Banner Target(s)': 'Banner Target(s)',
  Impression: 'Impression',
  'Number of Impressions': 'Number of Impressions',
  Click: 'Click',
  'Number Of Clicks': 'Number Of Clicks',
  'Conversion Rate (Click/Impression)': 'Conversion Rate (Click/Impression)',
  'Product Views': 'Product Views',
  'Conversion Rate (Product View/Impression)':
    'Conversion Rate (Product View/Impression)',
  'Conversion Rate (Add To Basket/Impression)':
    'Conversion Rate (Add To Basket/Impression)',
  'Number Of Purchases': 'Number Of Purchases',
  'Conversion Rate (Purchase/Impression)':
    'Conversion Rate (Purchase/Impression)',
  BannerifyPromo: () => {
    return (
      <span>
        Monitor your <strong>banners performance</strong> in{' '}
        <strong> Real-time</strong>
      </span>
    );
  },
  Discover: 'Discover',

  of: 'of',
  Previous: 'Previous',
  Next: 'Next',

  // Settings
  'Account Settings': 'Account Settings',
  'User Settings': 'User Settings',
  'Api Key': 'Api Key',
  'Account Domain': 'Account Domain',
  'Contact Person': 'Contact Person',
  'Contact Phone': 'Contact Phone',
  'Contact Address': 'Contact Address',
  Username: 'Username',
  'Display Name': 'Display Name',
  'Display Language': 'Display Language',
  'New Password': 'New Password',
  'New Password (Again)': 'New Password (Again)',
  'Customer Removal Tool': 'Customer Removal Tool',
  'Customer Removal Tool Description':
    'All historical personal data related to the given email address can be removed via this tool.',
  'Customer Removal Tool Description Label':
    "Insert customer's email address(es) here, separated by commas for more than one user.",
  'Customer Removal Tool Disclaimer': () => {
    return (
      <p>
        All historical personal information related to given email addresses
        will be deleted within the next 24 hours. When the removal process is
        finished, you will receive an email.
        <br />
        Please note that the removal process is permanent and irreversible, upon
        its completion Segmentify can't recover any data.
      </p>
    );
  },

  // Payments
  'Monthly Subscription': 'Monthly Subscription',
  'Standard Packages': 'Standard Packages',
  Name: 'Name',
  Surname: 'Surname',
  Address: 'Address',
  City: 'City',
  Country: 'Country',
  STARTER: 'STARTER',
  BASIC: 'BASIC',
  PRO: 'PRO',
  'Payment History': 'Payment History',
  mo: 'mo',
  'Package Details & Payment Info': 'Package Details & Payment Info',
  Date: 'Date',
  Operation: 'Operation',

  // Settings.Integrations
  "Let's Integrate Segmentify": "Let's Integrate Segmentify",
  Initialize: 'Add JS Code to Your Website',
  'add-js-code': 'Add JS Code to Your Website',
  'add-js-code-info':
    'Copy and paste this code to the HEAD block of your website. Or you can add the JS Code via Google Tag Manager or similar tag management tools.',
  'share-your-product': 'Share Your Active Product Feed ',
  'share-your-product-info':
    'Paste your valid, updated and active XML Feed URL here. (Google Shopping Feed matches the expectations of Segmentify. This feed should include product ids, names, image URL’s, stock, price information)',
  'integration-script-info':
    'It is super easy to start smart recommendations on your web site with Segmentify. All you need to do is adding the following line of JS code to all your pages to the very end of your HEAD block.',
  'Complete the Integration': 'Complete the Integration',
  'integration-type-selection':
    'We can do the integration for you! No worries...',
  'Self Service': 'Self Service',
  IntegrationZendDesk: () => {
    return (
      <span>
        You might follow the steps in{' '}
        <a
          href='https://segmentify.zendesk.com/hc/en-us'
          target='_blank'
          rel='noreferrer'
        >
          Segmentify Customer success Center
        </a>{' '}
        for integration.
      </span>
    );
  },
  StartChat: () => {
    return (
      <span>
        Segmentify Customer Success team is ready to do the integration for you,{' '}
        <a>start a chat</a>
      </span>
    );
  },
  'Help me, please': 'Help me, please',
  'Choose what type recommendation features you want to use from Segmentify':
    'Choose what type recommendation features you want to use from Segmentify',
  'integration-page-types':
    'Select Page Types (such as Product Page, Home Page etc) on which you want to have recommendation and then widget type (such as Editor’s Choice, Best of Category, Last Minute Offers).',
  'Recommended for You': 'Recommended for You',
  'Best of Category': 'Best of Category',
  'Last Minute Offers': 'Last Minute Offers',
  'Start Recommend': 'Start Recommend',

  // 404 Texts
  'Page Not Found': 'Page Not Found',

  // Account Types
  type: 'Type',
  provider: 'Provider',
  FAIL: 'Fail',
  WAITING: 'Waiting',
  POC: 'POC',
  PRE_POC: 'Pre-POC',
  STAGING: 'Staging',
  FAILED: 'Failed',
  FREEMIUM: 'Freemium',
  GROUP_A: 'Group A',
  GROUP_B: 'Group B',
  GROUP_C: 'Group C',
  ARCHIVE: 'Archive',
  PAYING: 'Paying',
  ONHOLD: 'Onhold',
  CHURN: 'Churn',

  // Engagement
  Engagement: 'Engagement',
  Personalization: 'Engagement',
  Popup: 'Popup',
  'Add Popup': 'Add Popup',
  Popups: 'Popups',
  'Popup Banner': 'Popup Banner',
  'Popup Recommendation': 'Popup Recommendation',
  'E-Mail Collection': 'E-Mail Collection',
  Notifications: 'Notifications',
  'Push Permission': 'Push Permission',
  'Push Notification': 'Push Notification',
  'Push Notifications': 'Push Notifications',
  'Notification Bar': 'Notification Bar',
  'No Active Campaigns': 'No Active Campaigns',
  Campaign: 'Campaign',

  'Add Popup Banner': 'Add Popup Banner',
  'Add Popup Recommendation': 'Add Popup Recommendation',
  'Add E-Mail Collection Popup': 'Add E-Mail Collection Popup',
  'Add Push Permission': 'Add Push Permission',
  'Add Push Notification': 'Add Push Notification',
  'Add Notification Bar': 'Add Notification Bar',

  'Pages To Show': 'Pages To Show',
  Position: 'Position',
  Top: 'Top',
  Middle: 'Middle',
  Bottom: 'Bottom',
  Left: 'Left',
  Center: 'Center',
  Right: 'Right',
  'Timing & Frequency': 'Timing & Frequency',
  Timing: 'Timing',
  Immediate: 'Immediate',
  Delay: 'Delay',
  Scroll: 'Scroll',
  'Visitor Leaving Page': 'Visitor Leaving Page',
  Always: 'Always',
  Session: 'Session',
  'Life Time': 'Life Time',
  Days: 'Days',
  seconds: 'seconds',
  days: 'days',
  times: 'times',
  'Target Audience': 'Target Audience',
  'Visitor is Member': 'Visitor is Member',
  'Visitor is not a Member': 'Visitor is not a Member',
  'Visitor is': 'Visitor is',

  // Campaign Spesifics
  'Edit Popup Banner': 'Edit Popup Banner',
  'Background Image URL (Recommend 800x700px)':
    'Background Image URL (Recommend 800x700px)',
  'Push Title': 'Push Title',
  'Push Description': 'Push Description',
  'Your Logo URL (Recommend 200x200px)': 'Your Logo URL (Recommend 200x200px)',
  'Background Image URL (Recommend 1920x80px)':
    'Background Image URL (Recommend 1920x80px)',
  'Vertical Align': 'Vertical Align',
  'Horizontal Align': 'Horizontal Align',
  Once: 'Once',
  Periodic: 'Periodic',
  Everyday: 'Everyday',
  'Every Monday': 'Every Monday',
  'Every Tuesday': 'Every Tuesday',
  'Every Wednesday': 'Every Wednesday',
  'Every Thursday': 'Every Thursday',
  'Every Friday': 'Every Friday',
  'Every Saturday': 'Every Saturday',
  'Every Sunday': 'Every Sunday',
  Scheduling: 'Scheduling',
  'Visitor Segment': 'Visitor Segment',
  'Visitor Type': 'Visitor Type',
  Styles: 'Styles',
  Hours: 'Hours',
  'Valid for X': 'Valid for X',
  'Push Recommendation': 'Push Recommendation',
  'Description (Max 192 characters)': 'Description (Max 192 characters)',
  'Notification Icon URL (Recommended 192x192px)':
    'Notification Icon URL (Recommended 192x192px)',
  'Notification Image URL': 'Notification Image URL',
  'Target URL': 'Target URL',
  Frequency: 'Frequency',
  'Background Color': 'Background Color',
  'Text Color': 'Text Color',
  'Your work is not saved!. Are you sure want to leave?':
    'Your work is not saved!. Are you sure want to leave?',
  'Scheduled Campaign': 'Scheduled Campaign',
  'Completed Campaign': 'Completed Campaign',
  Recommendation: 'Recommendation',
  'unique-selector': 'unique-selector',

  PrivacyPolicyCta: 'Update your cookie policy ',
  'proceed-to-integration': 'Proceed to Integration',
  'Give Access from GA4 to': 'Give Access from GA4 to ',
  'Give Access from GA4 to info':
    'To give access to this email address on GA4 Admin > Account Access Management > Add ',
  LinkToAnalytics: () => {
    return (
      <a
        href='mailto:analytics.eu4@segmentify.com'
        rel='noreferrer'
        style={{ textDecoration: 'none', fontWeight: '600' }}
      >
        analytics.eu4@segmentify.com
      </a>
    );
  },
  PrivacyPolicyContent:
    "If your online store has a presence in Europe, then you'll need to adjust your privacy policy to comply with European Union (EU) laws. These laws require websites to gain consent from end users prior to storing data on a website visitor’s computer.",
  PrivacyPolicy: () => {
    return (
      <a
        href='https://segmentify.com/privacy-policy/'
        target='_blank'
        rel='noreferrer'
      >
        Learn More
      </a>
    );
  },

  // Emails
  LAST_VISIT_ALTERNATIVES: 'LAST VISIT ALTERNATIVES',
  ABANDONED_CART: 'ABANDONED CART',
  PERSONALIZED_NEWSLETTER: 'PERSONALISED NEWSLETTER',
  PURCHASE_REMINDER: 'PURCHASE REMINDER',

  // Provider Warnings
  ShopifyWarning:
    'You have already installed Segmentify app. We advise you to use Segmentify app in your Shopify store admin panel.',
  PrestashopWarning:
    'You have already installed Segmentify plug-in. We advise you to use Segmentify add-on in your Prestashop admin panel.',
  MagentoWarning:
    'You have already installed Segmentify plug-in. We advise you to use Segmentify add-on in your Magento admin panel.',

  // Campaign Statuses
  'Live Recommendation': 'Live Recommendation',
  'Test Recommendation': 'Test Recommendation',
  'Archived Recommendation': 'Archived Recommendation',
  Archived: 'Archived',

  'Campaign Note': 'Campaign Note',

  // Sales
  'Purchase Time': 'Purchase Time',
  'Order No': 'Order No',

  // Currency Settings
  'Main Currency': 'Main Currency',
  Code: 'Code',
  'Decimal Places': 'Decimal Places',
  'Decimal Separator': 'Decimal Separator',
  'Thousand Seperator': 'Thousand Seperator',
  Symbol: 'Symbol',
  'Place Symbol': 'Place Symbol',
  'Comma (,)': 'Comma (,)',
  'Dot (.)': 'Dot (.)',
  'Empty Space': 'Empty Space',
  None: 'None',
  'You can check your currency code here':
    'You can check your currency code here',
  "This Month's Cost": "This Month's Cost",

  // Campaigns Status Filters
  Live: 'Live',
  Test: 'Test',
  Archive: 'Archive',
  live: 'live',
  test: 'test',
  'test/draft': 'test-draft',

  // Campaign Device Filters
  Desktop: 'Desktop',
  'Mobile Web': 'Mobile Web',
  Android: 'Android',
  iOS: 'iOS',

  // Campaigns Sorting
  'Sort by Date': 'Sort by Date',
  'Sort by Revenue': 'Sort by Revenue',

  // Named Time Ranges
  All: 'All',
  'This Year': 'This Year',
  'Last Month': 'Last Month',
  'This Month': 'This Month',
  'This Week': 'This Week',
  Yesterday: 'Yesterday',
  Today: 'Today',

  // CTA Buttons
  Save: 'Save',
  Reset: 'Reset',
  Update: 'Update',
  Cancel: 'Cancel',
  Confirm: 'Confirm',
  Continue: 'Continue',
  Export: 'Export',
  'View More': 'View More',
  Details: 'Details',

  // Validation Texts
  'You should select a product from suggestions':
    'You should select a product from suggestions',
  'You should define at least one recommendation':
    'You should define at least one recommendation',
  'This field is required': 'This field is required',
  'This field should equal to #fieldName#':
    'This field should equal to #fieldName#',
  'This field should have at least #minLength# characters':
    'This field should have at least #minLength# characters',
  PasswordField:
    'Password must have at least one uppercase character (A-Z), one lowercase character (a-z) , one digit (0-9) and one special character: ! @ # . _ - ( )',
  EmailField: 'This field should have a valid email.',
  BusinessEmailCheck: 'Please enter your business email address.',
  'Field must be a valid URL.': 'Field must be a valid URL.',
  DeliveryRequired:
    'You should activate at least one option from the Delivery Steps above',

  // Update notification Bars
  'Recommendation widget is updated in test mode.Chrome Extension.':
    'Recommendation widget is updated in test mode.Chrome Extension.',
  'Recommendation widget is updated in live mode.':
    'Recommendation widget is updated in live mode.',
  'Recommendation widget is activated in test mode and only test users can see. You can check the widget by activating test mode in your browserChrome Extension.':
    'Recommendation widget is activated in test mode and only test users can see. You can check the widget by activating test mode in your browserChrome Extension.',

  // ====== 24 AUGUST 2017 ======= //
  'All Recommendations': 'Recommendations',
  Recommendations: 'Recommendations',
  'Trigger Based Campaign': 'Trigger Based',
  'Form - Survey': 'Form - Survey',
  'Form Survey': 'Form - Survey',
  'Add Form - Survey': 'Create Form - Survey',
  'In Future/Recurring Notifications': 'Scheduled / Recurring Notification',
  'Sent Notifications': 'Sent Notifications',
  'WidgetSent Notifications': 'Sent Notifications',
  'Add Push Recommendation': 'Create Push Recommendation',
  'All Campaigns': 'All Campaigns',
  'All Campaign Reports': 'All Campaign Reports',
  'Date Comparison': 'Compare',
  'Compare To': 'Compare Date Ranges',
  'Widget View': 'Widget View',
  'Basket Items': 'Basket Items',
  'Purchased Items': 'Purchased Items',
  'Form Submit': 'Forms Submitted',
  'Notification [shown]': 'Push [viewed]',
  'Notification [clicked]': 'Push [clicked]',
  'Notification [sent]': 'Push [sent]',
  'Notification Permission [granted]': 'Notification Permission Granted',
  'Notification Permission [denied]': 'Notification Permission Denied',
  'Purchase Amount': 'Purchase Amount',
  Quantity: 'Quantity',
  'Payment Type': 'Payment Type',
  'Cart Detail': 'Cart Detail',
  'Sale Time': 'Date',
  'Graphical View': 'Graphical View',
  'Conversion Graph': 'Conversion Graph',
  'Revenue Graph': 'Revenue Graph',
  'Purchase Graph': 'Purchase Graph',
  'Page Graph': 'Page View Graph',
  'Device Graph': 'Device Graph',
  'Audience Graph': 'Audience Graph',
  'Segmentify Graph': 'Segmentify Graph',
  Stock: 'Stock',
  'Price at Cart': 'Price at Cart',
  Order: 'Order',
  'Export Products': 'Export Products',
  'Export File Name': 'File Name',
  'Page Selection': 'Page Selection',
  'CSV Delimiter': 'CSV Delimiter',
  'Self Service? See your account specific values':
    'Self Service Integration Click here to see your account specific values',
  'Custom Segmentify integration requires two important values.':
    'Custom Segmentify integration requires two important values.',
  'Your account specific keys': 'Your account specific keys',
  'System Variable': 'System Variable',
  Description: 'Description',
  'Current Page': 'Current Page',
  'All Pages': 'All Pages',
  'Semicolon (;)': 'Semicolon (;)',
  'Tab (\\t)': 'Tab (\\t)',
  'Export Bannerify Report': 'Export Bannerify Report',
  Banners: 'Banners',
  'Grid View': 'Grid View',
  'Funnel View': 'Funnel View',
  'Event Name': 'Event Name',
  'Back To Wizard': 'Back To Wizard',
  'Your work is not saved! Are you sure want to leave?':
    'Your work is not saved! Are you sure want to leave?',
  'There is no campaign': 'There is no campaign',
  'Edit CSS': 'Edit CSS',
  'Visitor is not': 'Visitor is not',
  New: 'New',
  Registered: 'Registered',
  Logged: 'Logged in',
  'Select Page': 'Select Page',
  Type: 'Type',
  'Checkout Page': 'Checkout Page',

  // ====== 2017.08 ======= //
  Labels: 'Labels',

  // ======= 2017.09 ========= //
  Preview: 'Preview Push Notification',
  "Your browser doesn't support Notification":
    "Your browser doesn't support Web-push notification",
  'You have to allow notifications to preview':
    'You have to allow web-push notifications to preview',
  'Scheduled Campaigns': 'Scheduled Campaigns',
  'Purchases via Banners': 'Purchases via Banners',
  'Event Data': 'Event Data',
  'You should select a promotion from suggestions':
    'You should select a promotion from suggestions',
  'Purchases and Items': 'Purchases and Products',

  'Recommended For You': 'Recommended For You',
  'Most Popular': 'Most Popular Products',
  'Products You Recently Viewed': 'Products You Recently Viewed',
  'You May Also Like': 'You May Also Like',
  'Account Statistics': 'Account Statistics',
  'Checkout Success Page': 'Checkout Success Page',
  'NoActiveCheckout SuccessCampaigns': () => {
    return <div>There is no active Campaign on Checkout Success Page</div>;
  },
  'Selected For You': 'Selected For You',
  'Most Popular Products in this Category':
    'Most Popular Products in this Category',
  'You Might Also Like': 'You Might Also Like',
  'Frequently Bought Together': 'Frequently Bought Together',
  'Complementary Products': 'Complementary Products',
  'You Recently Viewed': 'You Recently Viewed',
  'Do you want to check once more?': 'Do you want to check once more?',
  'Top Sellers': 'Top Sellers',
  'Top Sellers in this Category': 'Top Sellers in this Category',
  'You should select at least one page type':
    'You should select at least one page type',
  'Select Pages': 'Select Pages',
  'Segmented Users': 'Segmented Users',

  Champions: 'Rockstars',
  'Loyal Customers': 'Retained Customers',
  'Potential Loyalist': 'Becoming fans',
  'New Customers': 'Newbies',
  Promising: 'Low spending newbies',
  'Customers Needing Attention': 'Slipping away fans',
  'About To Sleep': 'Slipping away customers',
  'At Risk': 'Slipping away Rockstars',
  "Can't Lose Them": 'Used to spend a lot',
  Hibernating: 'Inactive customers',
  Lost: 'Lost customers',
  // ======= 2017.10 ========= //
  'Segment Visitor': 'Segmented Visitor',
  'Unsegment Visitor': 'Unsegment Visitor',
  'Toggle Visitor Segment': 'Toggle Visitor Segment',
  Segmentation: 'Segmentation',
  Keyword: 'Keyword',
  keyword: 'keyword',
  keywords: 'keywords',
  'Search Keyword': 'Search Keyword',
  'Add Search Keyword Recommendation': 'Add Search Keyword Recommendation',
  Count: 'Count',
  Ratio: 'Ratio',
  'Stock Count': 'Stock Count',
  'Stock Ratio': 'Stock Ratio',
  'Stock Status': 'Stock Status',
  'Greater Than': 'Greater Than',
  'Less Than': 'Less Than',
  'pages selected': 'pages selected',
  'No Results': 'No Results',
  'You can see only last 50 results.': 'You can see the last 50 results only.',
  'You can see only last 50 emails.': 'You can see the last 50 emails only.',
  "Can't create user, user exists with same email.":
    "Can't create user, user exists with same email.",
  'categories selected': 'categories selected',
  'category selected': 'category selected',
  'brands selected': 'brands selected',
  'brand selected': 'brand selected',
  'View List': 'View List',
  'View Stats': 'View Stats',
  Stats: 'Stats',
  'There is no data': 'There is no data',
  responses: 'responses',
  response: 'response',

  // ======= 2017.11 ========= //
  'Select Products': 'Select Products',
  'Search Products': 'Search Products',
  'Add Selected Products': 'Add Selected Products',
  'Your changes will be lost!': 'Your changes will be lost!',
  'Back to the defaults': 'Back to the default settings',
  'Other Languages': 'Other Languages',
  Sticky: 'Sticky',
  'Banner Performance': 'Banner Performance',
  'Purchased Products': 'Purchased Products',

  // ======= Field Descriptions ========= //
  // Popup Banner
  'Enter the title that your visitors will see on the pop-up banner.':
    'Enter the title that your visitors will see on the pop-up banner.',
  'Enter the URL of the image that will be used as the background image of the pop-up banner.':
    'Enter the URL of the image that will be used as the background image of the pop-up banner.',
  'Enter the URL of the page that your visitors will be directed when they click on the pop-up banner.':
    'Enter the URL of the page that your visitors will be directed when they click on the pop-up banner.',
  position_POPUP_BANNER:
    'You can set the position of the pop-up banner on the page.',
  verticalPosition_POPUP_BANNER:
    'You can set the position of the pop-up banner on top, middle or bottom of the page.',
  horizontalPosition_POPUP_BANNER:
    'You can set the position of the pop-up banner on left, middle or right section of the page.',
  timingTitle_POPUP_BANNER:
    'You can set the time and frequency of the pop-up banner to display. ',
  timing_POPUP_BANNER:
    'You can display the pop-up right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_POPUP_BANNER:
    'You can set the frequency of the pop-up banner to display. For example, one time in one session.',
  audience_POPUP_BANNER:
    'You can set the audience of the pop-up banner to display. For example, to the new visitors or returning visitors.',

  // Popup Recommendation
  'Enter the title that your visitors will see on the recommendation pop-up banner.':
    'Enter the title that your visitors will see on the recommendation pop-up banner.',
  position_POPUP_RECOMMENDATION:
    'You can set the position of the pop-up banner on the page.',
  verticalPosition_POPUP_RECOMMENDATION:
    'You can set the position of the pop-up banner on top, middle or bottom of the page.',
  horizontalPosition_POPUP_RECOMMENDATION:
    'You can set the position of the pop-up banner on left, middle or right section of the page.',
  timingTitle_POPUP_RECOMMENDATION:
    'You can set the time and frequency of the pop-up banner to display. ',
  timing_POPUP_RECOMMENDATION:
    'You can display the pop-up right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_POPUP_RECOMMENDATION:
    'You can set the frequency of the pop-up banner to display. For example, one time in one session.',
  audience_POPUP_RECOMMENDATION:
    'You can set the audience of the pop-up banner to display. For example, to the new visitors or returning visitors.',

  // Journey Builder
  position_JOURNEY_BUILDER:
    'You can set the position of the journey builder on the page.',
  verticalPosition_JOURNEY_BUILDER:
    'You can set the position of the journey builder on top, middle or bottom of the page.',
  horizontalPosition_JOURNEY_BUILDER:
    'You can set the position of the journey builder on left, middle or right section of the page.',
  timingTitle_JOURNEY_BUILDER:
    'You can set the time and frequency of the journey builder to display. ',
  timing_JOURNEY_BUILDER:
    'You can display the journey builder right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_JOURNEY_BUILDER:
    'You can set the frequency of the journey builder to display. For example, one time in one session.',
  audience_JOURNEY_BUILDER:
    'You can set the audience of the journey builder to display. For example, to the new visitors or returning visitors.',

  // See All
  position_SEE_ALL:
    'You can set the position of the See All Campaign on the page.',
  verticalPosition_SEE_ALL:
    'You can set the position of the See All Campaign on top, middle or bottom of the page.',
  horizontalPosition_SEE_ALL:
    'You can set the position of the See All Campaign on left, middle or right section of the page.',
  timingTitle_SEE_ALL:
    'You can set the time and frequency of the See All Campaign to display. ',
  timing_SEE_ALL:
    'You can display the See All Campaign right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_SEE_ALL:
    'You can set the frequency of the See All Campaign to display. For example, one time in one session.',
  audience_SEE_ALL:
    'You can set the audience of the See All Campaign to display. For example, to the new visitors or returning visitors.',

  // Email Collection
  'Enter the title of the pop-up that will be displayed for email collection.':
    'Enter the title of the pop-up that will be displayed for email collection.',
  'Enter the description of the pop-up that will be displayed for email collection.':
    'Enter the description of the pop-up that will be displayed for email collection.',
  'Enter the name of the information that you would like your visitors to fill in the form as a placeholder.':
    'Enter the name of the information that you would like your visitors to fill in the form as a placeholder.',
  "Enter the 'Call to Action' text. For example, 'Send'":
    "Enter the 'Call to Action' text. For example, 'Send'",
  "Enter the text to direct visitors to visit the page where your legal statement is published. For example, 'Terms&Conditions' ":
    "Enter the text to direct visitors to visit the page where your legal statement is published. For example, 'Terms&Conditions'",
  'Enter the URL address of the page where your legal statement is published to direct your visitors.':
    'Enter the URL address of the page where your legal statement is published to direct your visitors.',
  styles_NEWSLETTER: 'Select the font and the background color.',
  'styles_Text Color': 'Select text color.',
  'styles_Background Color': 'Select background color.',
  position_NEWSLETTER:
    'You can set the position of the pop-up banner on the page that will be displayed for email collection.',
  verticalPosition_NEWSLETTER:
    'You can set the position of the pop-up banner that will be displayed for email collection on top, middle or bottom of the page.',
  horizontalPosition_NEWSLETTER:
    'You can set the position of the pop-up banner that will be displayed for email collection on left, middle or right section of the page.',
  timingTitle_NEWSLETTER:
    'You can set the time and frequency of the pop-up banner that will be displayed for email collection.',
  timing_NEWSLETTER:
    'You can display the pop-up banner for email collection right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_NEWSLETTER:
    'You can set the frequency of the pop-up banner for email collection to display. For example, one time in one session.',
  audience_NEWSLETTER:
    'You can set the audience of the pop-up banner for email collection to display. For example, to the new visitors or returning visitors.',

  // Form Survey
  'Enter the title that your visitors will see on the form survey pop-up banner.':
    'Enter the title that your visitors will see on the form survey pop-up banner.',
  'Enter the description of the form survey pop-up banner.':
    'Enter the description of the form survey pop-up banner.',
  position_FORM:
    'You can set the position of the form survey pop-up banner on the page.',
  verticalPosition_FORM:
    'You can set the position of the form survey pop-up banner on top, middle or bottom of the page.',
  horizontalPosition_FORM:
    'You can set the position of the form survey pop-up banner on left, middle or right section of the page.',
  timingTitle_FORM:
    'You can set the time and frequency of the form survey pop-up banner to display.',
  timing_FORM:
    'You can display the form survey pop-up banner right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_FORM:
    'You can set the frequency of the form survey pop-up banner to display. For example, one time in one session.',
  audience_FORM:
    'You can set the audience of the form survey pop-up banner to display. For example, to the new visitors or returning visitors.',

  // Hero Banner
  timingTitle_HERO_BANNER: 'You can set the time and frequency of the banner.',
  timing_HERO_BANNER:
    'You can display the banner right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_HERO_BANNER:
    'You can set the frequency of the banner to display. For example, one time in one session.',
  audience_HERO_BANNER:
    'You can set the audience of the banner to display. For example, to the new visitors or returning visitors.',

  // Push Permission
  'Enter the title that your visitors will see on the push permission.':
    'Enter the title that your visitors will see on the push permission.',
  'Enter the description of the push permission.':
    'Enter the description of the push permission.',
  'Enter the URL address of the logo that will be displayed on the push permission.':
    'Enter the URL address of the logo that will be displayed on the push permission.',
  position_PUSH_PERMISSION:
    'You can set the position of the push permission on the page.',
  verticalPosition_PUSH_PERMISSION:
    'You can set the position of the push permission on top, middle or bottom of the page.',
  horizontalPosition_PUSH_PERMISSION:
    'You can set the position of the push permission on left, middle or right section of the page.',
  timingTitle_PUSH_PERMISSION:
    'Select how many times you want each visitor to see the permission prompt.',
  timing_PUSH_PERMISSION:
    'You can display the push permission right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_PUSH_PERMISSION:
    'You can set the frequency of the push permission to display. For example, one time in one session.',
  "Enter the call to action text to give permission. For example, 'Allow'":
    "Enter the call to action text to give permission. For example, 'Allow'",
  "Enter the call to action text to ask for permission later again. For example, 'Remind me later'":
    "Enter the call to action text to ask for permission later again. For example, 'Remind me later'",

  // Push Notifications
  'Enter the title that your visitors will see on the web push notification.':
    'Enter the title that your visitors will see on the web push notification.',
  'Enter the description of the web push notificaiton.':
    'Enter the description of the web push notificaiton.',
  'Enter the URL address of the icon that will be displayed on the web push notification.':
    'Enter the URL address of the icon that will be displayed on the web push notification.',
  'Enter the URL address of the image that will be displayed on the web push notification.':
    'Enter the URL address of the image that will be displayed on the web push notification.',
  'Enter the URL of the page that your visitors will be directed when they click on the web push notification.':
    'Enter the URL of the page that your visitors will be directed when they click on the web push notification.',
  scheduling_PUSH_NOTIFICATION: 'You can plan and schedule to send web push.',
  audience_PUSH_NOTIFICATION:
    'You can set the audience of the web push to send. For example, to the new visitors or returning visitors.',

  // Notification Bar
  'Enter the title that your visitors will see on the notification bar.':
    'Enter the title that your visitors will see on the notification bar.',
  'Enter the URL address of the image that will be displayed on the notification bar.':
    'Enter the URL address of the image that will be displayed on the notification bar.',
  'Enter the URL of the page that your visitors will be directed when they click on the notification bar.':
    'Enter the URL of the page that your visitors will be directed when they click on the notification bar.',
  styles_NOTIFICATION_BAR: 'Select the font and the background color.',
  position_NOTIFICATION_BAR:
    'You can set the position of the notification bar on the page.',
  verticalPosition_NOTIFICATION_BAR:
    'You can set the position of the notification bar on top, middle or bottom of the page.',
  horizontalPosition_NOTIFICATION_BAR:
    'You can set the position of the notification bar on left, middle or right section of the page.',
  timingTitle_NOTIFICATION_BAR:
    'You can set the time and frequency of the notification bar.',
  timing_NOTIFICATION_BAR:
    'You can display the notification bar right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_NOTIFICATION_BAR:
    'You can set the frequency of the notification bar to display. For example, one time in one session.',
  audience_NOTIFICATION_BAR:
    'You can set the audience of the notification bar to display. For example, to the new visitors or returning visitors.',

  "Can't update user information, new password is same with current password":
    "Can't update user information, new password is same with current password",

  'Keep on page change': 'Keep on page change',
  'Category View': 'Category View',
  'Category Name': 'Category Name',
  impressions: 'impressions',
  'Completed Campaigns': 'Completed Campaigns',
  'Conversion Rate': 'Conversion Rate',
  Pages: 'Pages',
  'Views Referred From Adwords': 'Views Referred From Adwords',
  'Views Referred From Retargeting': 'Views Referred From Retargeting',
  'Views Referred From Price Comparison Sites':
    'Views Referred From Price Comparison Sites',
  'Views Referred From Affiliates': 'Views Referred From Affiliates',
  'Views Referred From Referrals': 'Views Referred From Referrals',

  'Will be sent at': 'Will be sent at',
  'Last 7 Days': 'Last 7 Days',
  'The campaign was successfully archived.':
    'The campaign was successfully archived.',
  'Shuffle Promotions': 'Shuffle Promotions',

  'You must change the end date in order to save campaign.':
    'You must change the end date in order to save campaign.',
  Feature: 'Feature',

  'Impression / View / Click': 'Impression / View / Click',
  Items: 'Items',

  'The percentage of products that have been purchased after being clicked in a Segmentify Recommendation widget.':
    'The percentage of products that have been purchased after being clicked in a Segmentify Recommendation widget.',

  'There are no records to show.': 'There are no records to show.',
  'You should make at least one selection from Segmentify Intelligent or Static algorithms':
    'You should make at least one selection from Segmentify Intelligent or Static algorithms',
  'New Comers': 'New Comers',
  'All Rocks': 'All Rocks',
  "All Recommends from all products in your website's catalog with an intelligent sorting ":
    "All Recommends from all products in your website's catalog with an intelligent sorting ",
  // Widget/Promotion/Search Keywrods Desc&Info
  Widget_Desc:
    "Create personal recommendation campaigns with Segmentify's intelligent and static algorithms.",
  Promotion_Desc:
    "Personalise the Special Offers you display in your website with Segmentify's Promotion campaigns.",
  'Search-Keyword_Desc':
    "Find out the top searched keywords in your website and recommend them so your customer's can find what they want more easily.",

  // include filters stock
  stockCount: () => {
    return (
      <span>
        <strong>Count</strong> Refers to the count of the product left in the
        stock (Ex: Products with 50 stock or more)
      </span>
    );
  },
  stockRatio: () => {
    return (
      <span>
        <strong>Ratio</strong> Refers to the percentage of the product left in
        the stock (Ex: Products with %50 in stock or more)
      </span>
    );
  },
  stockStatus: () => {
    return (
      <span>
        <strong>Status</strong> Refers to the percentage of the availability of
        the sizes per product (Ex: Products with %50 in stock sizes or more)
      </span>
    );
  },
  'Email Conversion': 'Revenue',
  'EmailTotal View': 'Emails Sent',
  EmailConversion: 'Open Rate',
  'Push Conversion': 'Revenue',
  'PushTotal View': 'Sent',
  PushConversion: 'Impression Rate',
  SearchPromo:
    'Create a meaningful search experience for your customers with Segmentify’s Search & Discovery module.',
  BehaviouralTargetPromo:
    'Boost your conversion rate with our Engagement module',
  AudiencePromo:
    'Boost your conversion rate with our Customer Data Management module',
  MailPromo: 'Personalise your email marketing with right products.',
  PushPromo: 'Personalise your web push notification with right products.',
  RecoPromo:
    'Create personalised shopping experiences by making real-time product recommendations',
  'The association email template between the mandrill campaign and the email scenario':
    'The association email template between the mandrill campaign and the email scenario',
  "Email campaign's selected language": "Email campaign's selected language",
  "Email campaign's selected currency": "Email campaign's selected currency",
  'Determine campaign based configurations': 'Adjust the campaign details',
  "Determine campaign's language & currency settings":
    "Determine campaign's language & currency settings",
  'For the subject and mail content texts of your personalised email campaigns you can either select or customize from the ready to go contents or you can get creative with your own marketing ideas.':
    'For the subject and mail content texts of your personalised email campaigns you can either select or customize from the ready to go contents or you can get creative with your own marketing ideas.',

  'NoActivePopup RecommendationCampaigns': () => {
    return (
      <div>
        <p>
          To continue making personalised recommendations on your website, you
          can use popup recommendations on any page type.
        </p>
        <p>
          Click ‘Add Custom Widget’ below to create a new popup recommendation
          widget.
        </p>
      </div>
    );
  },
  'NoActiveJourney BuilderCampaigns': () => {
    return (
      <div>
        <p>
          When you need a journey which created uniquely for each of your
          customers, you can define journey builder popups.
        </p>
        <p>
          Please select journey builder below to{' '}
          <strong>add new journey builder popup.</strong>
        </p>
      </div>
    );
  },
  Before_Search_Input:
    "Attract your customers with Segmentify's intelligent algorithms even before they make a search.",

  // FACETED SEARCH
  General: 'General',
  Language: 'Language',
  FACETED_SEARCH_PRODUCT_DESC:
    'Create personalized faceted search experience, using advanced filters.',
  FACETED_SEARCH_CAMPAIGN_SAVE_SUCCESS:
    'You have been created the campaign successfully.',
  FACETED_SEARCH_CAMPAIGN_UPDATE_SUCCESS:
    'You have been updated the campaign successfully.',
  'faceted-report-subtitle': 'See the performance of the Searchandising',
  'faceted-all-report-subtitle':
    'See the performance of all Searchandising reports',

  // Rule Set
  'rule-set-query-condition-title': 'Set Query Conditions',
  'rule-set-query-condition-tooltip':
    'It is very easy to customize your search result pages according to the conditions.\n' +
    'Set a condition and get started.\n' +
    'Usable operators: Exactly Match, Contains, Starts with in',
  'rule-set-query-condition-description': 'Choose the query to define the rule',
  'rule-set-result-customization-title': 'Search Results Customization',
  'rule-set-result-customization-description':
    'Pin or change your products to customize search results',
  'rule-set-result-customization-tooltip':
    'Visual builder to pin and promote products, facets and editorial content for specific search queries.\n' +
    'Click Pin Product and get started.',
  'rule-set-hides-title': 'Hidden Products',
  'rule-set-hides-description':
    'View products which will not appear on search results.',
  'rule-set-boost-fields': 'Boost Fields',
  'rule-set-boost-fields-description':
    'Boost specific fields to make products appear first.',
  'rule-set-contents-title': 'Contents',
  'rule-set-contents-description':
    'Add your custom content to customize your page',
  'rule-set-contents-description-searchbox':
    'Add your custom content to customize your search box',
  'rule-set-contents-tooltip':
    'Customize your search results page by adding banners, buttons on your search results pages.',
  'rule-set-contents-tooltip-searchbox':
    'Customize your searchbox by adding banners, buttons.',
  Discard: 'Discard',
  Pinned: 'Pinned',
  Pin: 'Pin',
  Replace: 'Replace',
  'pin-product': 'Pin Product',
  'add-content': 'Add Content',
  'rule-name': 'Rule Name',
  'rule-set': 'Rule Set',
  'rule-set-mandatory-error': field => <span>{field} is mandatory.</span>,
  'rule-set-campaign-mandatory':
    'In order to use the product pinning capability, please select a campaign.',
  'rule-set-custom-content-key-error': 'Custom content key is empty.',
  'rule-set-boost-field-error': 'Boosting Properties are empty.',
  'rule-set-position-collapse': 'Pin product positions can not be collapsed.',
  'rule-set-delete-confirmation-title': 'Delete the rule?',
  'rule-set-live-confirmation-title': 'Save the rule?',
  'rule-set-delete-confirmation-content':
    'This action will immediately effect rule status',
  'select-campaign': 'Select Campaign',
  'rule-set-save-successful': 'Rule set successfully saved',
  'rule-set-save-failed': 'Error occurred while saving rule set',
  'rule-set-retrieve-failed': 'Error occurred while retrieving rule set data',
  'No Product': 'Product cannot be found',
  RESULTS_FOOTER: 'Results Footer',
  RESULTS_HEADER: 'Results Header',
  FILTERS_FOOTER: 'Filters Footer',
  FILTERS_HEADER: 'Filters Header',
  ASSETS_FOOTER: 'Assets Footer',
  ASSETS_HEADER: 'Assets Header',
  // Start - User Management Page
  'search-and-hit-enter': 'Search and hit enter',
  // End - User Management Page
};

export default en;
