/**
 * @author Bilal Cinarli
 */

import React from 'react';
import { connect } from 'react-redux';

import { currencyIcons } from '../constants/datamaps/currencies';

import { getAccountCurrency } from '../modules/auth/user';

import { localeString, localeStringMoney } from '../system/string';

import Icon from './icon';

class Currency extends React.Component {
  render() {
    const currency = this.props.accountCurrency || getAccountCurrency();
    let symbol = null;

    if (this.props.predefinedCode) {
      if (currencyIcons[this.props.predefinedCode]) {
        symbol = <Icon name={currencyIcons[this.props.predefinedCode]} />;
      } else {
        symbol = this.props.predefinedCode;
      }
    } else {
      if (currencyIcons[currency.code]) {
        symbol = <Icon name={currencyIcons[currency.code]} />;
      } else {
        symbol = currency.symbolBefore
          ? `${currency.symbol} `
          : ` ${currency.symbol}`;
      }
    }

    let elClass = 'user-currency';
    if (this.props.compareCurrency) {
      elClass += ' user-currency-compare';
    }
    return (
      <span className={elClass}>
        {currency.symbolBefore ? symbol : ''}
        <span className='user-currency-amount'>
          {this.props.big
            ? localeStringMoney(this.props.currency, this.props.givenType, {
                currency: currency.code,
                style: 'currency',
                decimal: currency.decimalSeparator,
                thousand: currency.thousandSeparator,
                minimumFractionDigits: currency.decimals,
              })
            : localeString(this.props.currency, {
                currency: currency.code,
                style: 'currency',
                decimal: currency.decimalSeparator,
                thousand: currency.thousandSeparator,
                minimumFractionDigits: currency.decimals,
              })}
        </span>
        {!currency.symbolBefore ? symbol : ''}
        {symbol === null ? (
          <span className='user-currency-code'>{currency.code}</span>
        ) : (
          ''
        )}
      </span>
    );
  }
}

const mapStatsToProps = store => ({
  user: store.user.user,
});

export default connect(mapStatsToProps)(Currency);
