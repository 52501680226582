import React from 'react';
import './SFYCampaignStatus.scss';
import classnames from 'classnames';
import { t } from '../../../../system/ui';
import { uiActions } from '../../../../actions';
import { TextField } from '../../../fields';
import Icon from '../../../icon';
import Tooltip from '../../../tooltip';

/**
 * @name SFYCampaignStatus component
 * @description SFYCampaignStatus component displays campaign status live or passive and length
 * @param {string} actions - campaignStatus
 * @param {array} actions - allCampaign
 * @returns {JSX.Element}
 */

const SFYCampaignStatus = ({
  campaignStatus,
  allCampaign,
  campaignTitle,
  maxCount,
}) => {
  const subtitleClass = classnames('sfy-campaignStatus', {
    passive: campaignStatus !== 'ACTIVE',
  });

  const { showErrorNotification } = uiActions;

  let fail = () => (
    <p>{t(`Maxiumum active campaign size could be ${maxCount.maxSize}`)}</p>
  );

  const onChange = e => {
    const re = /^[0-99\b]+$/;

    if (e.target.value > maxCount.maxSize) {
      showErrorNotification(fail);
    }

    if (
      e.target.value === '' ||
      (re.test(e.target.value) && e.target.value <= maxCount.maxSize)
    ) {
      maxCount.onInputChange(
        e.target.value === '' ? '' : parseInt(e.target.value),
      );
    }
  };

  return (
    <div className='sfy-campaign-wrapper'>
      <div className={subtitleClass}>
        {t(campaignTitle)}
        <span>({allCampaign.length})</span>
      </div>
      {campaignStatus === 'ACTIVE' && maxCount?.visible && (
        <div className='max-campaign-wrapper'>
          <div className='tooltip-title-wrapper'>
            <Tooltip
              content='Returns the maximum number of campaigns to be displayed in total.'
              alignment='top'
              newTooltip
            >
              <Icon name='info' />
            </Tooltip>
            <span className='max-campaign-text'>{t('Maximum Campaign')}</span>
          </div>
          <TextField
            name='maxCampaignCount'
            className='maximum-campaign-count'
            value={maxCount.count}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};
export default SFYCampaignStatus;
