import { loader } from './loader';
import { tools } from './tools';

const originOfEmailCustomTools = 'https://v3.segmentify.com'; // For development use ( window.location.origin )
const recommendationTemplateScript = `${originOfEmailCustomTools}/recommendationTemplateSource.js`;
const recommendationTemplateCSS = `${originOfEmailCustomTools}/recommendationTemplateSource.css`;
const productCardTemplateScript = `${originOfEmailCustomTools}/productCardTemplateSource.js`;
const productCardTemplateCSS = `${originOfEmailCustomTools}/productCardTemplateSource.css`;

const options = {
  displayMode: 'email',
  tools,
  customJS: [recommendationTemplateScript, productCardTemplateScript],
  customCSS: [recommendationTemplateCSS, productCardTemplateCSS],
  editor: {
    autoSelectOnDrop: true,
  },
  appearance: {
    theme: 'light',
    loader: {
      html: loader.html,
      css: loader.css,
    },
    panels: {
      tools: {
        dock: 'right',
      },
    },
  },
};
export { options, tools };
