import React, { useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { onFilterChange, onResetFilterClick } from 'reducers/user-management';
import {
  getUserAccounts,
  getUserList,
  getUserTypes,
  searchUser,
} from 'reducers/user-management/extraReducers';

const UserManagementFilters = ({ getFilterQuery }) => {
  const dispatch = useDispatch();
  const filterState = useSelector(state => state.userManagement.filter);
  const userState = useSelector(state => state.user?.user);

  const SelectFilter = ({ placeholder, selector }) => {
    const { privileges } = userState;
    const currentFilterState = filterState[selector];
    const internalUserArr = [
      'ROLE_SM',
      'ROLE_OBD',
      'ROLE_ACD',
      'ROLE_OBM',
      'ROLE_ACM',
      'ROLE_AGU',
    ];

    const isInternalUser = internalUserArr.some(r => privileges.includes(r));

    if (selector === 'userType' && isInternalUser) {
      return null;
    }

    if (!currentFilterState) {
      throw new Error(
        `Given selector: ${selector} does not exist in userManagement reducer`,
      );
    }

    SelectFilter.propTypes = {
      placeholder: PropTypes.string.isRequired,
      selector: PropTypes.string.isRequired,
    };

    return (
      <Select
        name={selector}
        clearable={false}
        options={currentFilterState.data}
        value={currentFilterState.selected}
        placeholder={placeholder}
        onChange={e => {
          dispatch(onFilterChange({ selector, value: e.value }));
        }}
      />
    );
  };

  const Button = ({ text, primary, normal, ...props }) => {
    Button.propTypes = {
      text: PropTypes.string.isRequired,
      primary: PropTypes.bool,
      normal: PropTypes.bool,
    };

    Button.defaultProps = {
      primary: false,
      normal: true,
    };

    const btnClass = cn('user-management-body-filters-btn', {
      'btn-primary': primary,
      'btn-normal': normal || (!primary && !normal),
    });

    return (
      <button type='button' className={btnClass} {...props}>
        {text}
      </button>
    );
  };

  const onClickUpdate = () => {
    const query = getFilterQuery();
    if (filterState.searchParam) {
      dispatch(searchUser({ searchParam: filterState.searchParam, query }));
    } else {
      dispatch(getUserList(query));
    }
  };

  useEffect(() => {
    dispatch(getUserTypes());
    dispatch(getUserAccounts());
  }, [dispatch]);

  return (
    <div className='user-management-body-filters'>
      <SelectFilter placeholder='User Type' selector='userType' />
      <SelectFilter placeholder='Accounts' selector='account' />
      <SelectFilter placeholder='Status' selector='status' />
      <Button text='Update' primary onClick={onClickUpdate} />
      <Button
        text='Reset Filters'
        onClick={() => {
          dispatch(onResetFilterClick());
          dispatch(getUserList());
        }}
      />
    </div>
  );
};

UserManagementFilters.propTypes = {
  getFilterQuery: PropTypes.func.isRequired,
};

export default UserManagementFilters;
