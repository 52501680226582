/**
 * Created by mehmetyurtar on 12/6/17.
 */
import React from "react";

class IconFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <path
          d="M15,0H1C0.448,0,0,0.447,0,1v2c0,0.297,0.132,0.578,0.36,0.769L6,8.469V15c0,0.404,0.244,0.77,0.617,0.924
	C6.741,15.976,6.871,16,7,16c0.26,0,0.516-0.102,0.707-0.293l2-2C9.895,13.52,10,13.266,10,13V8.469l5.64-4.7
	C15.868,3.578,16,3.297,16,3V1C16,0.447,15.552,0,15,0z"
        />
      </svg>
    );
  }
}

export default IconFilter;
