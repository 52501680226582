import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { modalActions } from '../../../../actions';
import {
  CategorySelector,
  SFYWrapper,
} from '../../../../components/sfy-components/module-exports';
import { algorithmOptions, categoryFilterTypes } from '../constants/constants';
import './productBundleModals.scss';
import { t } from '../../../../system/ui';

/**
 * @name BundleCategoryModal
 * @description Modal to select the category for the bundle
 * @param {object} categoryProp
 * @param {function} onChange
 * @param {boolean} isMainItem
 * @return {JSX.Element}
 */
const BundleCategory = ({
  categoryProp = {
    type: 'category',
    index: 0,
    key: '',
    params: {},
    exist: true,
    imageUrl: '',
    hasError: false,
  },
  onChange,
  isMainItem = false,
}) => {
  const bundleCategoryRef = useRef(null);
  const [category, setCategory] = useState(categoryProp);
  const [filterParams, setFilterParams] = useState(categoryProp?.params || {});
  const [algorithm, setAlgorithm] = useState(
    categoryProp?.algorithm || algorithmOptions[0].value,
  );

  const hasError = () => {
    const errorControlStates = [];
    Object.keys(filterParams).forEach(key => {
      Object.keys(filterParams[key]).forEach(typeKey => {
        const thisFilterType = filterParams[key][typeKey];
        thisFilterType.forEach(item => {
          let hasErrorInThisStep = false;
          const itemValues = item?.values;
          if (item?.type === 'custom') {
            if (
              Object.keys(item?.values).length === 0 ||
              !itemValues?.value ||
              !itemValues?.parameter ||
              (item?.operation === 'BETWEEN' &&
                parseFloat(itemValues?.value) >
                  parseFloat(itemValues?.otherValue))
            ) {
              hasErrorInThisStep = true;
            }
          }

          if (item?.type === 'stock') {
            if (Object.keys(item?.values).length === 0 || !itemValues?.value) {
              hasErrorInThisStep = true;
            }
          }

          if (item?.type === 'priceRange') {
            if (
              Object.keys(item?.values).length === 0 ||
              !itemValues?.range ||
              !itemValues?.max ||
              parseFloat(itemValues?.range) > parseFloat(itemValues?.max)
            ) {
              hasErrorInThisStep = true;
            }
          }

          errorControlStates.push(hasErrorInThisStep);
        });
      });
    });
    return errorControlStates.includes(true);
  };

  useEffect(() => {
    const newCategory = {
      ...category,
      params: filterParams,
      hasError: hasError(),
    };

    if (!isMainItem) {
      newCategory.algorithm = algorithm;
    }

    onChange(newCategory);
  }, [category, algorithm]);

  useEffect(() => {
    setCategory({ ...category, params: filterParams, hasError: hasError() });
    modalActions.calculateModalHeight();
  }, [filterParams]);

  const onCategoryChange = categoryObj => {
    setCategory({ ...category, ...categoryObj, hasError: hasError() });
  };

  return (
    <div className='bundle-category-modal' ref={bundleCategoryRef}>
      <SFYWrapper title='Select Category*'>
        <CategorySelector
          details={category}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          filterTypes={categoryFilterTypes}
          onChange={obj => onCategoryChange(obj)}
        />
      </SFYWrapper>

      {!isMainItem && (
        <SFYWrapper title='Select Algorithm*'>
          <div className='sfy-card-bundle'>
            <div className='sfy-card-nav-select'>
              <span className='sfy-card-select-title'>{t('Algorithm')}:</span>
              <div className='sfy-card-select'>
                <Select
                  value={algorithm}
                  options={algorithmOptions}
                  name='algorithm'
                  clearable={false}
                  searchable={false}
                  onChange={e => setAlgorithm(e.value)}
                  className='bundle-select one-whole'
                  openOnClick={false}
                />
              </div>
            </div>
          </div>
        </SFYWrapper>
      )}
    </div>
  );
};

export default BundleCategory;
