import React, { useState, useEffect, useReducer } from 'react';
import TextField from '../../../components/fields/field.textfield';
import classes from './IysIntegration.module.scss';
// import CheckBox from '../../../components/fields/field.checkbox';
// import Tooltip from '../../../components/tooltip';
// import Icon from '../../../components/icon';
import { consentTypes, inputConfiguration } from './constants';
import { t } from '../../../system/ui';
import IysSettingsSyncModal from './modals/IysSettingsSyncModal';
import { isLoading, isLoaded, showNotification } from '../../../actions/ui';
import { openModal } from '../../../actions/modal';
import { createIYSSettings, getIYSInfo } from '../../../modules/account/ajax';

const IysIntegration = () => {
  const [isEdited, setIsEdited] = useState(false);
  const [mode, setMode] = useState('Save');
  const initialState = {
    iysCode: '',
    brandCode: '',
    mailConsent: true,
    smsConsent: false,
    phoneConsent: false,
    ftpAddress: '',
    ftpUser: '',
    ftpPassword: '',
  };
  const consentElements = ['mailConsent', 'smsConsent', 'phoneConsent'];
  const [settings, setSettings] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState,
  );

  const openSyncModal = () => {
    openModal({
      title: '',
      content: IysSettingsSyncModal,
      className: 'iys-settings-sync-modal',
    });
  };

  const editCheck = () => {
    const stateValueCheck = {
      fields: [settings.iysCode, settings.brandCode].every(Boolean),
      consents: [
        settings.mailConsent,
        settings.smsConsent,
        settings.phoneConsent,
      ].some(Boolean),
    };

    setIsEdited(stateValueCheck.fields && stateValueCheck.consents);
    return !isEdited ? setMode('Save') : setMode('Update');
  };

  const handleCreateIYS = () => {
    isLoading();
    let data = {};
    const getConsentTypes = () => {
      const checkedResultTypes = [
        settings.mailConsent,
        settings.smsConsent,
        settings.phoneConsent,
      ];
      return consentTypes.filter((item, index) => checkedResultTypes[index]);
    };

    const { iysCode, brandCode, ftpAddress, ftpUser, ftpPassword } = settings;

    data = {
      iysCode,
      brandCode,
      consentTypes: getConsentTypes(),
      ftpAddress,
      ftpUser,
      ftpPassword,
    };

    createIYSSettings(data, response => {
      isLoaded();
      if (response) {
        const notificationContent = () => <div>{response}</div>;
        showNotification({ content: notificationContent });
      }
    });
  };

  const handleChange = (e, type, inputName) => {
    if (type === 'checkbox') {
      setSettings({ [inputName]: !settings[inputName] });
    } else {
      const { name, value } = e?.target;
      setSettings({ [name]: value });
    }
  };

  useEffect(() => {
    const checkConsentTypes = usedConsents => {
      consentTypes.forEach((item, index) => {
        if (usedConsents.includes(item)) {
          const usedC = consentElements[index];
          setSettings({ [usedC]: true });
        }
      });
    };
    getIYSInfo(response => {
      if (response) {
        return Object.keys(response).forEach(key => {
          if (key !== 'consentTypes') {
            setSettings({ [key]: response[key] });
          } else {
            checkConsentTypes(response.consentTypes);
          }
        });
      }
    });
    return editCheck();
  }, []);

  useEffect(() => {
    editCheck();
  });

  return (
    <>
      <div className={classes['content-row']}>
        <h2 className='page-title'>{t('IYS Settings')}</h2>
      </div>
      <div className={classes['iys-integration']}>
        <div className={classes['iys-integration__content']}>
          <div className={classes['iys-integration__input_card']}>
            <h3 className={classes['iys-integration__input_card__title']}>
              {t('IYS Configuration')}
            </h3>
            {inputConfiguration.main.map(
              ({ type, order, name, placeHolder, validation, stateName }) => (
                <TextField
                  key={order}
                  type={type}
                  name={stateName}
                  value={settings[stateName]}
                  onChange={e => handleChange(e, type)}
                  label={name}
                  className='item-stacked one-whole'
                  placeHolder={placeHolder}
                  validation={validation}
                  required={!!validation}
                />
              ),
            )}
          </div>
          {/* <div className={classes['iys-integration__input_card']}>
            <h3 className={classes['iys-integration__input_card__title']}>
              {t('Choose Consent Types')}
              {'*'}
              <Tooltip
                content='One of the consent types require to be checked. '
                alignment='right'
              >
                <Icon name='info' />
              </Tooltip>
            </h3>

            {inputConfiguration.consent.map(
              ({ type, order, name, stateName }) => (
                <CheckBox
                  key={order}
                  name={stateName}
                  checked={settings[stateName]}
                  onChange={e => handleChange(e, type, stateName)}
                  label={name}
                />
              ),
            )}
          </div> */}
          {/* <div className={classes['iys-integration__input_card']}>
            <h3 className={classes['iys-integration__input_card__title']}>
              {t('FTP Settings')}
            </h3>
            {inputConfiguration.ftp_settings.map(
              ({ type, order, name, placeHolder, validation, stateName }) => (
                <TextField
                  key={order}
                  type={type}
                  name={stateName}
                  value={settings[stateName]}
                  onChange={e => handleChange(e, type)}
                  label={name}
                  className='item-stacked one-whole'
                  placeHolder={placeHolder}
                  validation={validation}
                  required={!!validation}
                />
              ),
            )}
          </div> */}
        </div>
      </div>
      <div className={classes['button-row']}>
        <button
          type='button'
          className={`${classes['content-row__sync-btn']} 
            ${!isEdited && classes['content-row__disabled']}`}
          disabled={!isEdited}
          onClick={() => openSyncModal()}
        >
          {t('Sync')}
        </button>
        <button
          type='button'
          className={
            classes[`button-row__save-btn${!isEdited ? '__disabled' : ''}`]
          }
          onClick={() => handleCreateIYS()}
          disabled={!isEdited}
        >
          {t(`${mode}`)}
        </button>
      </div>
    </>
  );
};

export default IysIntegration;
