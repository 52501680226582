/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import {
  getExperimentationList,
  getModulePath,
} from './services/abtestServices';
import {
  SFYCampaignList,
  SFYHeader,
  SFYCampaignStatus,
} from '../../components/sfy-components/module-exports';
import { breadCrumbOptions } from './constants/constants';
import Icon from '../../components/icon';
import { t } from '../../system/ui';
import ExperimentationSummary from '../../components/experimentation/definition/ExperimentationSummary';
import { getModuleByPath } from '../../components/experimentation/list/ExperimentationList';
import { setTitle } from '../../system/document';

/**
 * @name ABTest view holds ab test components
 * @description ab components responsible from pagination and path to any related page
 * @param {record} location - location object
 * @returns {JSX.Element} - ab component
 */
const AbTest = ({ route }) => {
  const module = getModuleByPath(getModulePath(route));
  const [experiments, setExperiments] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [values, setValues] = useState({});

  const liveAb = experiments.filter(
    experiment => experiment.status === 'ACTIVE',
  );
  const completedAb = experiments.filter(
    experiment => experiment.status === 'COMPLETED',
  );

  const onPreview = values => {
    values.variations[0].color = '#47607B';
    values.variations[1].color = '#A394D0';
    setShowPreview(true);
    setValues(values);
  };

  const onClose = () => {
    setShowPreview(false);
    setValues({});
  };

  const onFinishExperimentSuccess = () => {
    setShowPreview(false);
    setValues({});
    getExperimentationList(route, experiment => {
      setExperiments(experiment);
    });
  };

  const abChange = ab => {
    setExperiments(ab);
  };

  const renderExperiments = (abTest, campaignStatus, campaignTitle) => {
    return (
      <>
        <SFYCampaignStatus
          campaignStatus={campaignStatus}
          allCampaign={abTest}
          campaignTitle={campaignTitle}
        />
        {abTest.map(ab => (
          <SFYCampaignList
            route={route}
            key={ab.id}
            ab={ab}
            onPreview={onPreview}
            abChange={abChange}
          />
        ))}
      </>
    );
  };

  useEffect(() => {
    setTitle('A/B Testing');
    getExperimentationList(route, experiment => {
      setExperiments(experiment);
    });
  }, []);
  return (
    <>
      <SFYHeader
        pageTitle='A/B Test'
        breadCrumbOptions={breadCrumbOptions(module)}
      />
      <div
        className='available-type available-type-audience'
        onClick={() => {
          browserHistory.push({
            pathname: `/${
              module === 'BT' ? 'behavioural-targeting' : 'recommendations'
            }/experimentation/ab_test/create`,
          });
        }}
      >
        <span className='available-type-wireframe'>
          <Icon name='plus' size='2x' />
          <span>{t('Start an A/B Test')}</span>
        </span>
      </div>
      <div />
      {liveAb.length !== 0 && renderExperiments(liveAb, 'ACTIVE', 'Live Tests')}
      {completedAb.length !== 0 &&
        renderExperiments(completedAb, 'COMPLETED', 'Completed Tests')}
      {showPreview && (
        <ExperimentationSummary
          mode='preview'
          values={values}
          module={module}
          onClose={onClose}
          onFinishExperimentSuccess={onFinishExperimentSuccess}
        />
      )}
    </>
  );
};

export default AbTest;
