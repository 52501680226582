import React from 'react';
import './SFYNoResult.scss';
import { browserHistory } from 'react-router';
import Icons from '../../icons';
import { t } from '../../../system/ui';

/**
 * @name SFYNoResult component
 * @description NoResult component displays no result message and route to Create page
 * @param {string} linkToCreate
 * @param {string} noListDescription - Description of no result
 * @param {string} listType - Type of list
 * @returns {JSX.Element}
 */
const SFYNoResult = ({ linkToCreate = '', noListDescription, listType }) => {
  return (
    <div className='sfy-no-result'>
      <div className='sfy-no-result-text-title'>
        {noListDescription || t('There is no bundle group yet.')}
      </div>
      {linkToCreate && (
        <div className='seperator-holder'>
          <a
            className='darken'
            onClick={() => {
              browserHistory.push({
                pathname: linkToCreate,
              });
            }}
          >
            <span style={{ marginRight: '5px', marginTop: '5px' }}>
              <Icons name='plusCircle' width='19' height='19' color='#47607B' />
            </span>
            {listType || t('Create New Bundle')}
          </a>
        </div>
      )}
    </div>
  );
};

export default SFYNoResult;
