import React from 'react';
import PlusIcon from '../icons/PlusIcon';

import './AddClientButton.scss';

const AddClientButton = () => {
  return (
    <div>
      <a
        className='add-new-client-button'
        target='_blank'
        href='https://zcform.com/0f99a'
        rel='noreferrer'
        style={{ right: '140px' }}
      >
        <PlusIcon />
        <span>Refer a Deal</span>
      </a>
      <a
        className='add-new-client-button'
        target='_blank'
        href='https://zcform.com/u7g8H'
        rel='noreferrer'
      >
        <PlusIcon />
        <span>Refer A Lead</span>
      </a>
    </div>
  );
};

export default AddClientButton;
