import React from 'react';
import { t } from '../../../system/ui';
import { TextField } from '../../fields';
import classes from './SFYScheduling.module.scss';
import Tooltip from '../../tooltip';

const SFYTextWithIcon = ({
  editCampaign,
  fieldValue,
  componentDetail,
  isRequired = true,
  isTooltipVisible = true,
  textFieldClass = '',
  placeholderClass = '',
}) => {
  const { cardTitle, cardName, tooltipText } = componentDetail;
  return (
    <label
      className={`item item-stacked label-emphasise label-mail ${classes['sfy-scheduling-repeat']} ${classes[textFieldClass]}`}
    >
      {isTooltipVisible && (
        <span className={classes['sfy-label-wrapper']}>
          <span className={classes['sfy-label']}>{t(cardTitle)}</span>
          <Tooltip
            content={t(tooltipText)}
            alignment='top'
            placement='right'
            newTooltip
            isInline
            className={`${classes['sfy-font-weight']}`}
          >
            <i className='icon-info' role='presentation'>
              <span className='for-screenreader-only' />
            </i>
          </Tooltip>
        </span>
      )}

      <TextField
        name={cardName}
        className={`item-stacked ${classes[textFieldClass]} ${classes[placeholderClass]} ${classes['sfy-scheduling-repeat']} ${classes['sfy-text-field']}`}
        value={fieldValue}
        required={isRequired}
        type='number'
        onChange={e => editCampaign(cardName, `${e.target.value}`)}
        inputClassName={classes['sfy-input-class']}
      />
    </label>
  );
};

export default SFYTextWithIcon;
