/**
 * @author nedimtas
 */

/* eslint react/display-name: "off" */

import React from 'react';

// shared of config translations
// add component translation files together with the component
export const es = {
  // Menu
  Dashboard: 'Panel de control',
  Recommend: 'Recomendaciones',
  'View All': 'Ver todo',
  Report: 'Informe',
  Reports: 'Informes',
  'Executive Summary': 'Resumen ejecutivo',
  Sales: 'Ventas',
  Trendify: 'Trendify',
  Products: 'Productos',
  Categories: 'Categorías',
  Brands: 'Marcas',
  Insights: 'Insights',
  Bannerify: 'Bannerify',
  Settings: 'Ajustes',
  Account: 'Cuenta',
  Plugins: 'Complementos',
  Payments: 'Pagos',
  Integrations: 'Integraciones',
  Currency: 'Divisa',
  'Segmentation Module': 'Segmentation',
  'Recommendation Settings': 'Recommendation',

  // Dashboard
  'WidgetActual View': 'Ver',
  WidgetConversion: 'Tasa de visualización',
  EmailConversion: 'Impression Rate',
  'WidgetTotal View': 'Impresión',
  ImpressionRate: 'Impression Rate',
  InteractionConversion: 'CTR',
  'InteractionLeft Arrow Click': 'Left Arrow Click',
  'InteractionRight Arrow Click': 'Right Arrow Click',
  'BasketTotal Amount': 'Total Amount',
  'BasketAverage Contribution': 'Cantidad total',
  BasketConversion: 'Tasa de conversión',
  RevenuePurchases: 'Ventas totales/ingresos de ventas totales',
  'RevenuePurchase Items': 'Artículos comprados/importe de compra',
  RevenueConversion: 'Tasa de conversión',
  'RevenueContribution Ratio':
    'Proporción de contribución de ingresos / Proporción de contribución',

  view: 'vista',
  item: 'producto',
  views: 'vistas',
  click: 'clic',
  banner_clicks: 'bandera clics',
  product_clicks: 'productos clics',
  items: 'productos',

  'Segmentify Performance': 'Segmentar rendimiento',
  'Your Website': 'Su página web',

  Widget: 'Widget',
  Interaction: 'Interacción',
  Revenue: 'Ingresos por ventas',
  'Page View': 'Vista de pagina',
  Device: 'Dispositivo',
  'Audience-Website': 'Visitantes del sitio web',
  Audience: 'Audience',
  Overall: 'Ingresos por ventas',
  'Revenue (Overall)': 'Ingresos por ventas totales ',
  'Average Sessions': 'Sesiones promedio',
  'Actual View': 'Vistas',
  Conversion: 'Tasa de conversión',
  'Total Amount': 'Cantidad total',
  'Average Contribution': 'Contribución media',
  Purchases: 'Compra total',
  'Purchase Items': 'Productos comprados',
  'Average Basket': 'Valor promedio de la cesta',
  'Contribution Ratio': 'Proporción de contribución',
  PC: 'PC',
  Mobile: 'Móvil',
  Tablet: 'Tableta',
  'Unique Visitors': 'Visitantes únicos',
  'Unique Sessions': 'Sesiones únicas',
  'Pages/Sessions': 'Páginas/sesiones',
  'Product/Sessions': 'Producto/sesiones',

  // Recommendation Page Types
  Page: 'Página',
  'Home Page': 'Página de inicio',
  'Category Page': 'Página de categoría',
  'Product Page': 'Página de producto',
  'Basket Page': 'Página de cesta',
  'Search Page': 'Página de búsqueda',
  '404 Page': 'Página 404',
  'Custom Pages': 'Páginas personalizadas',
  'Trigger Based Page': 'Campaña basada en disparadores',
  'Other Pages': 'Otras páginas',
  'Custom Page': 'Pagina personalizada',
  Home: 'Página de inicio',
  Category: 'Categoría',
  Product: 'Producto',
  Basket: 'Cesta',
  Search: 'Buscar',
  Palantir: 'Buscar',
  Other: 'Otro',

  // Campaign Actions
  Actions: 'Acciones',
  Edit: 'Editar',
  Duplicate: 'Duplicar',
  History: 'Historial',

  // Persona Quiz
  Add_New_Response: 'Añadir Nueva Respuesta',

  'Live Campaigns': 'Campañas en vivo',
  'Test Campaigns': 'Campañas de prueba',

  // When there is no Active Campaign
  NoActiveHomeCampaigns: () => {
    return (
      <div>
        <p>
          The trending homepage design for e-commerce is quite banner heavy
          which is simply providing the campaign banners and category specific
          banners in shop window. This is missing the opportunity to guide the
          customers for direct conversion from homepage by placement of
          “Selected for you” widgets including personalised products enriched
          with appropriate call to action buttons.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new home page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveCategoryCampaigns: () => {
    return (
      <div>
        <p>
          Category pages are landing so much visitors but bounce rates in
          e-commerce from category pages are also so high. A solution provided
          by Segmentify for high bounce rates from category pages is using
          “Trending in Category” product recommendation widgets and include the
          popular products in category selected based on the behaviour of the
          current visitor.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new category page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveProductCampaigns: () => {
    return (
      <div>
        <p>
          Product pages in e-commerce belong to the heart of conversion and all
          conversion rate optimisation efforts focus on these pages. A regular
          product page at least includes Product title, Images, Price,
          Availability and Add to cart button. Other components such as Product
          detail, Sizing, Colour options and others are the tools that are used
          to represent as much information as possible to the visitors about the
          products.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new product page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveBasketCampaigns: () => {
    return (
      <div>
        <p>
          Increase average basket size by cross-selling related products in
          basket/cart pages. Majority of customers are willing to add at least
          one more product to their shopping cart if right product is offered to
          them.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new basket page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveSearchCampaigns: () => {
    return (
      <div>
        <p>
          Visitors became frustrated when they can't finding no product when
          they searched keywords on online stores, and bounce rates on search
          pages are much higher than other pages. With Segmentify, you can offer
          variety of products inside widgets on search result page to decrease
          bounce rates and gaining back leaving visitors.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new search page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActive404Campaigns: () => {
    return (
      <div>
        <p>
          When you removed a url from your store, this pages actually doesn't
          remove from search engines and other external links. When visitors
          clicks this url, they will face a 404 page without no navigation, and
          they will definitely bounce from this page. With Segmentify, you can
          offer variety of products inside widgets on 404 page to decrease
          bounce rates and gaining back leaving visitors.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new 404 page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveCustomCampaigns: () => {
    return (
      <div>
        <p>
          When you need a campaign on a page that are not predefined in
          Segmentify, you can define custom page campaings like user account
          page, checkout page etc.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new custom page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  'NoActiveTrigger BasedCampaigns': () => {
    return (
      <div>
        <p>
          When you need a campaign based on an event instead of a page, you can
          define trigger based campaigns.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new trigger based recommendation widget.</strong>
        </p>
      </div>
    );
  },

  'Need Help?': 'Need Help?',
  'Full Width': 'Anchura completa',
  'Right/Left Side': 'Barra lateral derecha/izquierda',
  'Add Custom Style Widget': 'Agregar widget de estilo personalizado',

  // Add Campaign
  'Widget Title': 'Título del widget',
  'This title will shown on your widget':
    'Este título se mostrará en su widget',

  Intelligent: 'Algoritmos inteligentes',
  Criteria: 'Filtrar (Incluir)',
  Excludes: 'Filtro (Excluir)',
  'Shuffle Items': 'Mezclar productos',
  'Smart Offers': 'Ofertas inteligentes',
  'All Category Products': 'Todos los productos de la categoría',
  'All Child Category Products':
    'Todos los productos de la categoría secundaria',
  'All Parent Category Products':
    'Todos los productos de la categoría principal',
  'All Products': 'Todos los productos',
  'Last Visited (Product)': 'Últimos productos visitados',
  'Purchased Together (Basket)': 'Comprados juntos (cesta)',
  'Purchased Together (Product)': 'Comprados juntos (producto)',
  'Viewed Together (Basket)': 'Vistos juntos (cesta)',
  'Viewed Together (Product)': 'Vistos juntos (producto)',
  'Rockstar Products': 'Productos de Rockstar',
  'Trending Products': 'Productos populares',
  'Shining Stars': 'Estrellas brillantes',
  'Discounted Products': 'Productos con descuento',
  'Brand Products': 'Productos de marca',
  'Alternative Products': 'Productos alternativos',
  'All Category Product': 'Todos los productos de la categoría',
  'View - Today': 'Vista - Hoy',
  'View - This Week': 'Vista - Esta semana',
  'View - This Month': 'Vista - Este mes',
  'Purchase - Today': 'Compra - Hoy',
  'Purchase - This Week': 'Compra - Esta semana',
  'Purchase - This Month': 'Compra - Este mes',
  'New Arrivals': 'Novedades',
  'Product Name': 'Nombre del producto',
  'Product Name/ID': 'Nombre/ID del producto',
  'Price Range': 'Rango de precios',
  Custom: 'Personal',
  Param: 'Parámetro',
  Value: 'Valor',
  Brand: 'Marca',
  Label: 'Etiqueta',
  Gender: 'Género',
  Size: 'Tamaño',

  min: 'mín',
  max: 'máx',

  Equals: 'Igual que',
  'Not Equals': 'Distinto de',
  Contains: 'Contiene',
  'Not Contains': 'No contiene',

  'Campaign Subject': 'Asunto de campaña',

  'Start Date - Time': 'Inicio fecha - hora',
  'End Date - Time': 'Fin fecha - hora',

  'Device Types': 'Tipos de dispositivo',
  Web: 'Web',
  App: 'App',

  'You should select at least one device type':
    'Debe seleccionar al menos un tipo de dispositivo',

  'Target Div Selector': 'Selector de div objetivo',
  'The target component on your website where recommendation box going to insert itself':
    'El componente html objetivo de su sitio web donde se insertará el widget de recomendación de producto.',

  Self: 'Interior',
  Before: 'Antes',
  After: 'Después',
  Append: 'Adjuntar',
  Prepend: 'Preadjuntar',

  'Edit HTML': 'Editar HTML',
  'Edit PreJS': 'Editar PreJS',
  'Edit PostJS': 'Editar PostJS',

  'Advanced Configuration': 'Configuración avanzada',
  'Go Test': 'Ir a pruebas',
  'Go Live': 'Ir a producción',

  'Attention!': '¡Atención!',
  'This action will immediately effect on your website':
    'Esta acción tendrá un efecto inmediato en su sitio web.',

  'no-recommendation-report-info': statusAndCampaign => {
    return (
      <span>
        <strong>No hay {statusAndCampaign}</strong> en esta página
      </span>
    );
  },

  'no-personalization-report-info': statusAndCampaign => {
    return (
      <span>
        <strong>No hay {statusAndCampaign}</strong>{' '}
      </span>
    );
  },

  'no-campaign-info': status => {
    return (
      <span>
        <strong>No hay campaña</strong> con {status}
      </span>
    );
  },

  'no-segment-info': status => {
    return (
      <span>
        <strong>No hay segmento</strong> con {status}
      </span>
    );
  },
  'Create Now': 'Crear ahora',
  'Campaign Report': 'Informe de campaña',

  // Trendify
  'Product Stock': 'Stock de producto',
  'Only In Stock': 'Solo con stock',
  'Only Out of Stock': 'Solo sin stock',
  'All Product Categories': 'Todas las categorías de producto',
  'Search Category': 'Categoría de búsqueda',
  'Product Category': 'Categoría de producto',
  'Product ID or Title': 'Identificación o título del producto',
  'Search Product': 'Buscar producto',
  'Product Brand': 'Marca de productos',
  'All Product Brands': 'Todas las marcas de productos',
  Segmentify: 'Segmentar',
  Sources: 'Fuentes',
  'Check-in': 'Registro',
  'Product Check-in Time': 'Hora de registro del producto',
  ID: 'ID',
  'Product ID': 'ID del producto',
  'Product name': 'Nombre del producto',
  'Product Categories': 'Categorías de producto',
  Price: 'precio',
  OldPrice: 'Old Price',
  'Product price and discounted price':
    'Precio del producto y precio con descuento',
  Image: 'Imagen',
  'Product Image Url': 'Imagen del producto',
  View: 'Ver',
  'Number Of Product Views': 'Número de vistas del producto',
  'Number Of Add To Basket Operations':
    "Número de eventos 'agregar a la cesta'",
  Rate: 'Tasa',
  'Conversion Rate (Purchase/View)': 'Tasa de conversión (compra/vista)',
  'Conversion Rate (Add To Basket/View)':
    'Tasa de conversión (agregar a la cesta/vista)',
  Purchase: 'Compra',
  'Number Of Product Purchases': 'Número de compras de productos',
  Amount: 'Importe',
  'Amount Of Product Purchases': 'Importe de compras de productos',
  'Number Of Clicks By Segmentify Recommendation Widgets':
    'Número de clics mediante los widgets de segmentación de recomendaciones',
  'Number Of Add To Basket Operations By Segmentify Recommendation Widgets':
    "Número de eventos 'Agregar a la cesta' mediante los widgets de segmentación de recomendaciones",
  'Conversion Rate (Add To Basket/Click)':
    'Tasa de conversión (agregar a la cesta/clic)',
  'Number Of Purchases By Segmentify Recommendation Widgets':
    'Número de compras mediante los widgets de segmentación de recomendaciones',
  'Conversion Rate (Purchase/Click)': 'Tasa de conversión (compra/clic)',
  'Amount Of Purchases By Segmentify Recommendation Widgets':
    'Importe de compras mediante los widgets de segmentación de recomendaciones',
  'Views Referred From In-site': 'Vistas referidas desde el sitio',
  'Views Referred From Social Media': 'Vistas referidas desde redes sociales',
  'Views Referred From Search Engines':
    'Vistas referidas desde motores de búsqueda',
  'Views Referred From Advertisements': 'Vistas referidas desde anuncios',
  'Views Referred From Email Campaigns':
    'Vistas referidas desde campañas de correo electrónico',
  'Views Referred From Price Listing Sites':
    'Vistas referidas desde sitios de listado de precios',
  'Views Referred From Other Sources': 'Vistas referidas desde otras fuentes',
  Show: 'Mostrar',
  Campaigns: 'Campañas',
  TrendifyPromo: () => {
    return (
      <span>
        Supervise el r<strong>endimiento de sus productos</strong> en{' '}
        <strong>tiempo real</strong>
      </span>
    );
  },

  // Insights
  'Whenever your customers see these products they buy them':
    'Cuando sus clientes ven estos productos, los compran',
  'High Spending': 'Productos de alto gasto',
  'Most viewed products with a referral from a paid channel':
    'Productos más vistos con referencia de un canal pago pero no comprados.',
  'The best of a bad bunch':
    'Estos productos despiertan interés, pero no se compran.',
  'These are the most viewed products among the none-selling ones':
    'Estos son los productos más vistos entre los que no se venden',
  'Discount Works': 'Las ofertas funcionan',
  'Most selling products among the discounted ones':
    'Productos que más se venden entre los rebajados.',
  'We Wish You Had': 'Ojalá tuviera',
  'Most viewed products that are out of stock':
    'Productos más vistos y que están agotados',
  'Abandoned Items': 'Productos abandonados',
  'Which products your customers left in their cart':
    'Los productos que se suelen abandonar en el carrito.',
  'Organic Bazaar': 'Bazar ecológico',
  'Most viewed products with a referral from Search Engine':
    'Productos más vistos referidos desde un resultado del motor de búsqueda.',
  'Social Trends': 'Tendencias en los canales de medios sociales',
  'Most viewed products with a referral from Social Media':
    'Productos más vistos con referencias de interacciones en redes sociales.',
  'You push hard': 'Vaya tela',
  "Your customers see these products but they don't buy them":
    'Sus clientes ven estos productos con frecuencia, pero no los agregan al carrito.',
  'Products to Improve': 'Productos de bajo rendimiento',
  'Worst converting products':
    'Sus clientes ven estos productos con frecuencia, pero no los compran.',
  'Most Revenue Growth (prior to compared period)':
    'Productos con el mayor aumento en los ingresos por ventas en comparación con la semana pasada.',
  'Losing Power': 'Productos con ingresos decrecientes por ventas',
  'Most Revenue Loss (prior to compared period)':
    'Productos con la mayor pérdida en ingresos por ventas en comparación con la semana pasada.',
  'An increasing trend occurred for these items in a selected basis':
    'Productos con el mayor aumento en ventas en comparación con la semana pasada.',
  'Under Shadow': 'Productos con ventas decrecientes',
  'A decreasing trend occurred for these items in a selected basis':
    'Productos con la mayor disminución en ventas en comparación con la semana pasada.',
  'Basket Complementaries': 'Complementarios de la cesta',
  'List of basket complementaries for the top selling products':
    'Lista de complementarios de cesta para los productos más vendidos.',
  allView: 'Total Views',
  purchase: 'Purchases',
  basket: 'Añadir al carrito',
  allViewCurWeek: 'Vistas en el Segundo Periodo',
  purchaseCurWeek: 'Compras en el Segundo Periodo',
  allViewLastWeek: 'Vistas en el Primer Periodo',
  purchaseLastWeek: 'Compras en el Primer Periodo',
  allPaidClick: 'Clics desde canal pagado',
  allSearchClick: 'Clics desde motor de búsqueda',
  allSocialClick: 'Clics desde canales de redes sociales',
  allAmountLastWeek: 'Ingresos del Primer Periodo',
  allAmountCurWeek: 'Ingresos del Segundo Periodo',
  amount: 'Ingresos',

  // Bannerify
  Banner: 'Banner',
  'Banner Group': 'Grupo de banners',
  'Banner Titles': 'Títulos de banner',
  'All Groups': 'Todos los grupos',
  'All Titles': 'Todos los títulos',
  'Show Active Banners': 'Mostrar banners activos',
  'Show All Banners': 'Mostrar todos los banners',
  Group: 'Grupo',
  'Banner Order': 'Orden del banner',
  'Check-in Time of Banner': 'Hora de registro del banner',
  'Last Update': 'Última actualización',
  'Last Update Time of Banner': 'Hora de última actualización del banner',
  Title: 'Título',
  'Banner Title': 'Título del banner',
  'Banner Image Url': 'Imagen del banner',
  Targets: 'Objetivos',
  'Banner Target(s)': 'Objetivos del banner',
  Impression: 'Impresión / Vista',
  'Number of Impressions': 'Número de impresiones / número de vistas',
  Click: 'Clic',
  'Number Of Clicks': 'Número de clics',
  'Conversion Rate (Click/Impression)':
    'Porcentaje de clics (clic/impresión de banner)',
  'Product Views': 'Vistas del producto',
  'Conversion Rate (Product View/Impression)':
    'Tasa de vistas de producto (vista del producto/impresión de banner)',
  'Conversion Rate (Add To Basket/Impression)':
    'Tasa de añadir a la cesta (añadir a la cesta/impresión de banner)',
  'Number Of Purchases': 'Numero de compras',
  'Conversion Rate (Purchase/Impression)':
    'Tasa de compra (compra/impresión de banner)',

  BannerifyPromo: () => {
    return (
      <span>
        Supervise el <strong>rendimiento de sus banners</strong> en{' '}
        <strong>tiempo real</strong>
      </span>
    );
  },
  Discover: 'Descubrir',

  of: 'de',
  Previous: 'Anterior',
  Next: 'Siguiente',

  // Settings
  'Account Settings': 'Ajustes de la cuenta',
  'User Settings': 'Ajustes del usuario',
  'Api Key': 'Clave de API',
  'Account Domain': 'URL del sitio web',
  'Contact Person': 'Persona de contacto',
  'Contact Phone': 'Teléfono de contacto',
  'Contact Address': 'Dirección de contacto',
  Username: 'Nombre de usuario',
  'Display Name': 'Nombre para mostrar',
  'Display Language': 'Idioma en pantalla',
  'New Password': 'Nueva contraseña',
  'New Password (Again)': 'Nueva contraseña (otra vez)',
  'Customer Removal Tool': 'Customer Removal Tool',
  'Customer Removal Tool Description':
    'All historical personal data related to the given email address can be removed via this tool.',
  'Customer Removal Tool Description Label':
    "Insert customer's email address(es) here, separated by commas for more than one user.",
  'Customer Removal Tool Disclaimer': () => {
    return (
      <p>
        All historical personal information related to given email addresses
        will be deleted within the next 24 hours. When the removal process is
        finished, you will receive an email.
        <br />
        Please note that the removal process is permanent and irreversible, upon
        its completion Segmentify can't recover any data.
      </p>
    );
  },

  // Payments
  'Monthly Subscription': 'Suscripción mensual',
  'Standard Packages': 'Paquetes estándar',
  Name: 'Nombre',
  Surname: 'Apellidos',
  Address: 'Dirección',
  City: 'Ciudad',
  Country: 'País',
  STARTER: 'PRINCIPIANTE',
  BASIC: 'BÁSICO',
  PRO: 'PRO',
  'Payment History': 'Historial de pagos',
  mo: 'm (mes)',
  'Package Details & Payment Info':
    'Detalles del paquete e información de pago',
  Date: 'Fecha',
  Operation: 'Operación',

  // Settings.Integrations
  "Let's Integrate Segmentify": 'Pasos de integración',
  Initialize: 'Implementar el código',
  'integration-script-info':
    'Es muy fácil comenzar a usar las soluciones de Segmentify. Todo lo que tiene que hacer es agregar la siguiente línea de código JS a todas sus páginas, en el final del bloque HEAD.',
  'Complete the Integration': 'Integración/pasos de integración',
  'integration-type-selection':
    '¡Podemos hacer la integración por usted! Sin preocupaciones...',
  'Self Service': 'Autoservicio',
  IntegrationZendDesk: () => {
    return (
      <span>
        You might follow the steps in{' '}
        <a
          href='https://segmentify.zendesk.com/hc/en-us'
          target='_blank'
          rel='noreferrer'
        >
          Segmentify Customer success Center
        </a>{' '}
        for integration.
      </span>
    );
  },
  StartChat: () => {
    return (
      <span>
        Segmentify Customer Success team is ready to do the integration for you,{' '}
        <a>start a chat</a>
      </span>
    );
  },
  'Help me, please': '¿Pueden echarme una mano?',
  'Choose what type recommendation features you want to use from Segmentify':
    'Elija la solución que más le interesa.',
  'integration-page-types':
    'Primero, elija el tipo de página (es decir, página del producto, página de inicio, etc.) y, luego, el tipo de recomendación (como los productos más populares, por ejemplo)',
  'Recommended for You': 'Recomendado para usted',
  'Best of Category': 'Lo mejor de la categoría',
  'Last Minute Offers': 'Ofertas de última hora',
  'Start Recommend': 'Comience recomendando',

  // 404 Texts
  'Page Not Found': 'Página no encontrada',

  // Account Types
  type: 'Type',
  provider: 'Provider',
  FAIL: 'Fail',
  WAITING: 'Waiting',
  PRE_POC: 'Pre-POC',
  POC: 'POC',
  STAGING: 'Staging',
  FAILED: 'Failed',
  FREEMIUM: 'Freemium',
  GROUP_A: 'Group A',
  GROUP_B: 'Group B',
  GROUP_C: 'Group C',
  ARCHIVE: 'Archive',
  PAYING: 'Paying',
  ONHOLD: 'Onhold',
  CHURN: 'Churn',

  // Engagement
  Engagement: 'Engagement',
  Personalization: 'Personalización',
  Popup: 'Ventana emergente',
  'Add Popup': 'Crear ventana emergente',
  Popups: 'Ventanas emergentes',
  'Popup Banner': 'Banner emergente',
  'Popup Recommendation': 'Recomendación emergente',
  'E-Mail Collection': 'Formulario de recogida de correo',
  Notifications: 'Notificaciones',
  'Push Permission': 'Permiso de push',
  'Push Notification': 'Notificación push',
  'Push Notifications': 'Notificaciones push',
  'Notification Bar': 'Barra de notificación',
  'No Active Campaigns': 'Sin campañas activas',
  Campaign: 'Campaña',

  'Add Popup Banner': 'Crear banner emergente',
  'Add Popup Recommendation':
    'Crear ventana emergente de recomendación de producto ',
  'Add E-Mail Collection Popup':
    'Crear ventana emergente de recogida del correo electrónico',
  'Add Push Permission': 'Crear campaña de permiso de push',
  'Add Push Notification': 'Crear notificación push',
  'Add Notification Bar': 'Crear barra de notificaciones',

  'Pages To Show': 'Páginas para mostrar',
  Position: 'Posición',
  Top: 'Superior',
  Middle: 'Medio',
  Bottom: 'Inferior',
  Left: 'Izquierda',
  Center: 'Centro',
  Right: 'Derecha',
  'Timing & Frequency': 'Tiempo y frecuencia',
  Timing: 'Tiempo',
  Immediate: 'Inmediato',
  Delay: 'Retraso',
  Scroll: 'Desplazar',
  'Visitor Leaving Page': 'El visitante abandona la página',
  Always: 'Siempre',
  Session: 'Sesión',
  'Life Time': 'Toda la vida',
  Days: 'Días',
  seconds: 'segundos',
  days: 'días',
  times: 'veces',
  'Target Audience': 'Público objetivo',
  'Visitor is Member': 'El visitante es miembro',
  'Visitor is not a Member': 'El visitante no es miembro',
  'Visitor is': 'El visitante es',

  // Campaign Spesifics
  'Edit Popup Banner': 'Editar banner emergente',
  'Background Image URL (Recommend 800x700px)':
    'URL de imagen (se recomienda 700x800px)',
  'Push Title': 'Título de ventana emergente de campaña de permiso de push',
  'Push Description':
    'Descripción de ventana emergente de campaña de permiso de push',
  'Your Logo URL (Recommend 200x200px)':
    'URL del logo (se recomienda 200x200px)',
  'Background Image URL (Recommend 1920x80px)':
    'URL de la imagen de fondo (se recomienda 1920x80px)',
  'Vertical Align': 'Alineación vertical',
  'Horizontal Align': 'Alineación horizontal',
  Once: 'Una vez',
  Periodic: 'Periódico',
  Everyday: 'Cada día',
  'Every Monday': 'Cada lunes',
  'Every Tuesday': 'Cada martes',
  'Every Wednesday': 'Cada miércoles',
  'Every Thursday': 'Cada jueves',
  'Every Friday': 'Cada viernes',
  'Every Saturday': 'Cada sábado',
  'Every Sunday': 'Cada domingo',
  Scheduling: 'Programando',
  'Visitor Segment': 'Visitor Segment',
  'Visitor Type': 'Visitor Type',
  Styles: 'Estilos',
  Hours: 'Horas',
  'Valid for X': 'Válido',
  'Push Recommendation': 'Recomendación de producto push',
  'Description (Max 192 characters)': 'Descripción (máx. 192 caracteres)',
  'Notification Icon URL (Recommended 192x192px)':
    'URL del icono de notificación (se recomienda 192x192px)',
  'Notification Image URL': 'URL de imagen de notificación',
  'Target URL': 'URL objetivo',
  Frequency: 'Frecuencia',
  'Background Color': 'Color de fondo',
  'Text Color': 'Color de texto',
  'Your work is not saved!. Are you sure want to leave?':
    '¡Su trabajo no está guardado! ¿Seguro que quiere abandonar?',
  'Scheduled Campaign': 'Campaña programada',
  'Completed Campaign': 'Campaña completada',
  Recommendation: 'Recomendación',
  'unique-selector': 'selector de css',

  PrivacyPolicyCta:
    'Do you need to adjust your cookie and privacy policy for using Segmentify?',
  PrivacyPolicyContent:
    "If your online store has a presence in Europe, then you'll need to adjust your privacy policy to comply with European Union (EU) laws. These laws require websites to gain consent from end users prior to storing data on a website visitor’s computer.",
  PrivacyPolicy: () => {
    return (
      <a
        href='https://segmentify.com/privacy-policy/'
        target='_blank'
        rel='noreferrer'
      >
        Learn More
      </a>
    );
  },

  // Emails
  LAST_VISIT_ALTERNATIVES: 'ALTERNATIVAS DE LA ÚLTIMA VISITA',
  ABANDONED_CART: 'CARRITO ABANDONADO',
  PERSONALIZED_NEWSLETTER: 'BOLETÍN PERSONALIZADO',
  PURCHASE_REMINDER: 'RECORDATORIO DE COMPRA',

  // Provider Warnings
  ShopifyWarning:
    'Ya ha instalado la aplicación Segmentify. Le recomendamos utilizar la aplicación Segmentify en el panel de administración de su tienda Shopify.',
  PrestashopWarning:
    'Ya ha instalado el complemento de Segmentify. Le recomendamos utilizar  el complemento de Segmentify en su panel de administración de Prestashop.',
  MagentoWarning:
    'Ya ha instalado el complemento de Segmentify. Le recomendamos utilizar  el complemento de Segmentify en su panel de administración de Magento.',

  // Campaign Statuses
  'Live Recommendation': 'Campaña de recomendación de productos a producción',
  'Test Recommendation': 'Campaña de recomendación de productos a pruebas',
  'Archived Recommendation': 'Campaña de recomendación de productos archivados',
  Archived: 'Archivado',

  'Campaign Note': 'Nota de campaña',

  // Sales
  'Purchase Time': 'Tiempo de compra',
  'Order No': 'N.º de pedido',

  // Currency Settings
  'Main Currency': 'Moneda principal',
  Code: 'Código',
  'Decimal Places': 'Posiciones decimales',
  'Decimal Separator': 'Separador decimal',
  'Thousand Seperator': 'Separador de miles',
  Symbol: 'Símbolo',
  'Place Symbol': 'Colocar símbolo',
  'Comma (,)': 'Coma (,)',
  'Dot (.)': 'Punto (.)',
  'Empty Space': 'Espacio vacío',
  None: 'Ninguno',
  'You can check your currency code here':
    'Puede verificar su código de moneda aquí.',
  "This Month's Cost": 'Coste de este mes',

  // Campaigns Status Filters
  Live: 'Producción',
  Test: 'Prueba',
  Archive: 'Archivo',
  live: 'producción',
  test: 'prueba',

  // Campaign Device Filters
  Desktop: 'Escritorio',
  'Mobile Web': 'Web móvil',
  Android: 'Android',
  iOS: 'iOS',

  // Campaigns Sorting
  'Sort by Date': 'Ordenar por fecha',
  'Sort by Revenue': 'Ordenar por ingresos',

  // Named Time Ranges
  All: 'Todo',
  'This Year': 'Este año',
  'Last Month': 'El mes pasado',
  'This Month': 'Este mes',
  'This Week': 'Esta semana',
  Yesterday: 'Ayer',
  Today: 'Hoy',

  // CTA Buttons
  Save: 'Guardar',
  Reset: 'Reiniciar',
  Update: 'Actualizar',
  Cancel: 'Cancelar',
  Confirm: 'Confirmar',
  Continue: 'Continuar',
  Export: 'Exportar',
  'View More': 'Ver más',
  Details: 'Detalles',

  // Validation Texts
  'You should select a product from suggestions':
    'Debe seleccionar al menos un producto.',
  'You should define at least one recommendation':
    'Debe seleccionar al menos un tipo de recomendación.',
  'This field is required': 'Este campo es obligatorio.',
  'This field should equal to #fieldName#':
    'Este campo debe ser igual a #fieldName#',
  'This field should have at least #minLength# characters':
    'Este campo debe tener al menos #minLength#' + ' caracteres',
  PasswordField:
    'Su contraseña debe incluir al menos una letra y número. También puede contener estos caracteres especiales: ! @ # . _ - ( )',
  EmailField: 'Debe indicar una dirección de correo electrónico válida.',
  'Field must be a valid URL.': 'Field must be a valid URL.',
  DeliveryRequired:
    'You should activate at least one option from the Delivery Steps above',

  // Update notification Bars
  'Recommendation widget is updated in test mode.Chrome Extension.':
    'El widget de recomendación de productos se ha actualizado en modo de prueba. Puede verlo activando el modo de prueba a través de la extensión de Chrome en su navegador.',
  'Recommendation widget is updated in live mode.':
    'El widget de recomendación de productos se ha actualizado en modo de producción.',
  'Recommendation widget is activated in test mode and only test users can see. You can check the widget by activating test mode in your browserChrome Extension.':
    'El widget de recomendación de productos se ha creado en modo de prueba. Puede verificar el widget en modo de prueba activando la extensión de Chrome de su navegador.',

  // ====== 24 AUGUST 2017 ======= //
  'All Recommendations': 'Recomendaciones',
  Recommendations: 'Recomendaciones',
  'Trigger Based Campaign': 'Basado en disparadores',
  'Form - Survey': 'Formulario - Encuesta',
  'Form Survey': 'Formulario - Encuesta',
  'Add Form - Survey': 'Crear formulario - Encuesta',
  'In Future/Recurring Notifications': 'Notificación programada/periódica',
  'Sent Notifications': 'Notificaciones enviadas',
  'WidgetSent Notifications': 'Sent Notifications',
  'Add Push Recommendation': 'Crear recomendación push',
  'All Campaigns': 'Todas las campañas',
  'All Campaign Reports': 'Todos los informes de campaña',
  'Date Comparison': 'Comparar',
  'Compare To': 'Comparar rangos de fechas',
  'Widget View': 'Vista de widget',
  'Basket Items': 'Artículos de la cesta',
  'Purchased Items': 'Elementos comprados',
  'Form Submit': 'Formularios enviados',
  'Notification [shown]': 'Notificación enviada',
  'Notification [clicked]': 'Notificación pulsada',
  'Notification [sent]': 'Notification Sent',
  'Notification Permission [granted]': 'Permiso de notificación otorgado',
  'Notification Permission [denied]': 'Permiso de notificación denegado',
  'Purchase Amount': 'Importe de compra',
  Quantity: 'Cantidad',
  'Payment Type': 'Tipo de pago',
  'Cart Detail': 'Detalle del carrito',
  'Sale Time': 'Fecha',
  'Graphical View': 'Vista gráfica',
  'Conversion Graph': 'Gráfico de conversión',
  'Revenue Graph': 'Gráfico de ingresos',
  'Purchase Graph': 'Gráfico de compras',
  'Page Graph': 'Gráfico de vistas de página',
  'Device Graph': 'Gráfico del dispositivo',
  'Audience Graph': 'Gráfico de audiencia',
  'Segmentify Graph': 'Gráfico de Segmentify',
  Stock: 'Stock',
  'Price at Cart': 'Precio en el carrito',
  Order: 'Orden',
  'Export Products': 'Exportar productos',
  'Export File Name': 'Nombre del archivo',
  'Page Selection': 'Selección de página',
  'CSV Delimiter': 'Delimitador de CSV',
  'Self Service? See your account specific values':
    'Integración de autoservicio Haga clic aquí para ver los valores específicos de su cuenta',
  'Custom Segmentify integration requires two important values.':
    'La integración personalizada de Segmentify requiere dos valores importantes.',
  'Your account specific keys': 'Claves específicas de su cuenta',
  'System Variable': 'Variable del sistema',
  Description: 'Descripción',
  'Current Page': 'Página actual',
  'All Pages': 'Todas las páginas',
  'Semicolon (;)': 'Punto y coma (;)',
  'Tab (\\t)': 'Tabulador (\\t)',
  'Export Bannerify Report': 'Exportar informe de Bannerify',
  Banners: 'Banners',
  'Grid View': 'Vista de cuadrícula',
  'Funnel View': 'Vista de embudo',
  'Event Name': 'Nombre del evento',
  'Back To Wizard': 'Volver al asistente',
  'Your work is not saved! Are you sure want to leave?':
    '¡Su trabajo no se ha guardado! ¿Seguro que quiere abandonar?',
  'There is no campaign': 'No hay campaña',
  'Edit CSS': 'Editar CSS',
  'Visitor is not': 'El visitante no es/está',
  New: 'Nuevo',
  Registered: 'Registrado',
  Logged: 'Conectado',
  'Select Page': 'Seleccionar página',
  Type: 'Tipo',
  'Checkout Page': 'Página de pago',

  // ====== 2017.08 ======= //
  Labels: 'Etiquetas',

  // ======= 2017.09 ========= //
  Preview: 'Vista previa de notificaciones push',
  "Your browser doesn't support Notification":
    'Tu navegador no es compatible con las notificaciones push de web',
  'You have to allow notifications to preview':
    'Debes permitir la vista previa de las notificaciones push de web ',
  'Scheduled Campaigns': 'Campañas programadas',
  'Purchases via Banners': 'Compras a través de banners',
  'Event Data': 'Datos del evento',
  'You should select a promotion from suggestions':
    'Debes seleccionar una promoción de las sugerencias',
  'Purchases and Items': 'Compras y productos',

  'Recommended For You': 'Recomendado para ti',
  'Most Popular': 'Productos más populares',
  'Products You Recently Viewed': 'Productos que viste recientemente',
  'You May Also Like': 'También te puede interesar',
  'Account Statistics': 'Estadísticas de cuenta',
  'Checkout Success Page': 'Página de éxito de pago',
  'NoActiveCheckout SuccessCampaigns': () => {
    return <div>No hay una campaña activa en la página de éxito de pago</div>;
  },
  'Selected For You': 'Seleccionado para ti',
  'Most Popular Products in this Category':
    'Productos más populares en esta categoría',
  'You Might Also Like': 'También podría gustarte',
  'Frequently Bought Together': 'Frecuentemente comprados juntos',
  'Complementary Products': 'Productos complementarios',
  'You Recently Viewed': 'Recientemente viste',
  'Do you want to check once more?': '¿Quieres comprobarlo una vez más?',
  'Top Sellers': 'Los más vendidos',
  'Top Sellers in this Category': 'Los más vendidos en esta categoría',
  'You should select at least one page type':
    'Debes seleccionar al menos un tipo de página',
  'Select Pages': 'Seleccionar páginas',
  'Segmented Users': 'Usuarios segmentados',

  Champions: 'Rockstars',
  'Loyal Customers': 'Clientes retenidos',
  'Potential Loyalist': 'Nuevos fans',
  'New Customers': 'Principiantes',
  Promising: 'Principiantes con poco gasto',
  'Customers Needing Attention': 'Fans que se escapan',
  'About To Sleep': 'Clientes que se escapan',
  'At Risk': 'Rockstars que se escapan',
  "Can't Lose Them": 'Solía ',
  Hibernating: 'Clientes inactivos',
  Lost: 'Lost customers',
  // ======= 2017.10 ========= //
  'Segment Visitor': 'Visitante segmentado',
  'Unsegment Visitor': 'Visitante no segmentado',
  'Toggle Visitor Segment': 'Alternar segmento de visitantes',
  Segmentation: 'Segmentación',
  Keyword: 'Palabra clave',
  keyword: 'palabra clave',
  keywords: 'palabras clave',
  'Search Keyword': 'Buscar palabra clave',
  'Add Search Keyword Recommendation':
    'Añadir recomendación de búsqueda de palabra clave ',
  Count: 'Recuento de stock',
  Ratio: 'Proporción',
  'Stock Count': 'Recuento de stock',
  'Stock Ratio': 'Proporción de stock',
  'Greater Than': 'Mayor que',
  'Less Than': 'Menor que',
  'pages selected': 'páginas seleccionadas',
  'No Results': 'Sin resultados',
  'You can see only last 50 results.':
    'Solo puedes ver los últimos 50 resultados.',
  'You can see only last 50 emails.':
    'Solo puedes ver los últimos 50 correos electrónicos.',
  "Can't create user, user exists with same email.":
    'No se puede crear el usuario, ya existe uno con el mismo correo electrónico',
  'categories selected': 'categorías seleccionadas',
  'category selected': 'categoría seleccionada',
  'brands selected': 'marcas seleccionadas',
  'brand selected': 'marca seleccionada',
  'View List': 'Ver lista',
  'View Stats': 'Ver estadísticas',
  Stats: 'Estadísticas',
  'There is no data': 'No hay datos',
  responses: 'respuestas',
  response: 'respuesta',

  // ======= 2017.11 ========= //
  'Select Products': 'Seleccionar productos',
  'Search Products': 'Buscar productos',
  'Add Selected Products': 'Agregar productos seleccionados',
  'Your changes will be lost!': '¡Se perderán tus cambios!',
  'Back to the defaults': 'Volver a la configuración predeterminada.',
  'Other Languages': 'Otros idiomas',
  Sticky: 'Chincheta',
  'Banner Performance': 'Rendimiento de banner',
  'Purchased Products': 'Productos comprados',

  // ======= Field Descriptions ========= //
  // Popup Banner
  'Enter the title that your visitors will see on the pop-up banner.':
    'Enter the title that your visitors will see on the pop-up banner.',
  'Enter the URL of the image that will be used as the background image of the pop-up banner.':
    'Enter the URL of the image that will be used as the background image of the pop-up banner.',
  'Enter the URL of the page that your visitors will be directed when they click on the pop-up banner.':
    'Enter the URL of the page that your visitors will be directed when they click on the pop-up banner.',
  position_POPUP_BANNER:
    'You can set the position of the pop-up banner on the page.',
  verticalPosition_POPUP_BANNER:
    'You can set the position of the pop-up banner on top, middle or bottom of the page.',
  horizontalPosition_POPUP_BANNER:
    'You can set the position of the pop-up banner on left, middle or right section of the page.',
  timingTitle_POPUP_BANNER:
    'You can set the time and frequency of the pop-up banner to display. ',
  timing_POPUP_BANNER:
    'You can display the pop-up right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_POPUP_BANNER:
    'You can set the frequency of the pop-up banner to display. For example, one time in one session.',
  audience_POPUP_BANNER:
    'You can set the audience of the pop-up banner to display. For example, to the new visitors or returning visitors.',

  // Popup Recommendation
  'Enter the title that your visitors will see on the recommendation pop-up banner.':
    'Enter the title that your visitors will see on the recommendation pop-up banner.',
  position_POPUP_RECOMMENDATION:
    'You can set the position of the pop-up banner on the page.',
  verticalPosition_POPUP_RECOMMENDATION:
    'You can set the position of the pop-up banner on top, middle or bottom of the page.',
  horizontalPosition_POPUP_RECOMMENDATION:
    'You can set the position of the pop-up banner on left, middle or right section of the page.',
  timingTitle_POPUP_RECOMMENDATION:
    'You can set the time and frequency of the pop-up banner to display. ',
  timing_POPUP_RECOMMENDATION:
    'You can display the pop-up right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_POPUP_RECOMMENDATION:
    'You can set the frequency of the pop-up banner to display. For example, one time in one session.',
  audience_POPUP_RECOMMENDATION:
    'You can set the audience of the pop-up banner to display. For example, to the new visitors or returning visitors.',

  // Journey Builder
  position_JOURNEY_BUILDER:
    'You can set the position of the journey builder on the page.',
  verticalPosition_JOURNEY_BUILDER:
    'You can set the position of the journey builder on top, middle or bottom of the page.',
  horizontalPosition_JOURNEY_BUILDER:
    'You can set the position of the journey builder on left, middle or right section of the page.',
  timingTitle_JOURNEY_BUILDER:
    'You can set the time and frequency of the journey builder to display. ',
  timing_JOURNEY_BUILDER:
    'You can display the journey builder right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_JOURNEY_BUILDER:
    'You can set the frequency of the journey builder to display. For example, one time in one session.',
  audience_JOURNEY_BUILDER:
    'You can set the audience of the journey builder to display. For example, to the new visitors or returning visitors.',

  // See All
  position_SEE_ALL:
    'You can set the position of the See All Campaign on the page.',
  verticalPosition_SEE_ALL:
    'You can set the position of the See All Campaign on top, middle or bottom of the page.',
  horizontalPosition_SEE_ALL:
    'You can set the position of the See All Campaign on left, middle or right section of the page.',
  timingTitle_SEE_ALL:
    'You can set the time and frequency of the See All Campaign to display. ',
  timing_SEE_ALL:
    'You can display the See All Campaign right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_SEE_ALL:
    'You can set the frequency of the See All Campaign to display. For example, one time in one session.',
  audience_SEE_ALL:
    'You can set the audience of the See All Campaign to display. For example, to the new visitors or returning visitors.',

  // Email Collection
  'Enter the title of the pop-up that will be displayed for email collection.':
    'Enter the title of the pop-up that will be displayed for email collection.',
  'Enter the description of the pop-up that will be displayed for email collection.':
    'Enter the description of the pop-up that will be displayed for email collection.',
  'Enter the name of the information that you would like your visitors to fill in the form as a placeholder.':
    'Enter the name of the information that you would like your visitors to fill in the form as a placeholder.',
  "Enter the 'Call to Action' text. For example, 'Send'":
    "Enter the 'Call to Action' text. For example, 'Send'",
  "Enter the text to direct visitors to visit the page where your legal statement is published. For example, 'Terms&Conditions' ":
    "Enter the text to direct visitors to visit the page where your legal statement is published. For example, 'Terms&Conditions'",
  'Enter the URL address of the page where your legal statement is published to direct your visitors.':
    'Enter the URL address of the page where your legal statement is published to direct your visitors.',
  styles_NEWSLETTER: 'Select the font and the background color.',
  'styles_Text Color': 'Select text color.',
  'styles_Background Color': 'Select background color.',
  position_NEWSLETTER:
    'You can set the position of the pop-up banner on the page that will be displayed for email collection.',
  verticalPosition_NEWSLETTER:
    'You can set the position of the pop-up banner that will be displayed for email collection on top, middle or bottom of the page.',
  horizontalPosition_NEWSLETTER:
    'You can set the position of the pop-up banner that will be displayed for email collection on left, middle or right section of the page.',
  timingTitle_NEWSLETTER:
    'You can set the time and frequency of the pop-up banner that will be displayed for email collection.',
  timing_NEWSLETTER:
    'You can display the pop-up banner for email collection right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_NEWSLETTER:
    'You can set the frequency of the pop-up banner for email collection to display. For example, one time in one session.',
  audience_NEWSLETTER:
    'You can set the audience of the pop-up banner for email collection to display. For example, to the new visitors or returning visitors.',

  // Form Survey
  'Enter the title that your visitors will see on the form survey pop-up banner.':
    'Enter the title that your visitors will see on the form survey pop-up banner.',
  'Enter the description of the form survey pop-up banner.':
    'Enter the description of the form survey pop-up banner.',
  position_FORM:
    'You can set the position of the form survey pop-up banner on the page.',
  verticalPosition_FORM:
    'You can set the position of the form survey pop-up banner on top, middle or bottom of the page.',
  horizontalPosition_FORM:
    'You can set the position of the form survey pop-up banner on left, middle or right section of the page.',
  timingTitle_FORM:
    'You can set the time and frequency of the form survey pop-up banner to display.',
  timing_FORM:
    'You can display the form survey pop-up banner right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_FORM:
    'You can set the frequency of the form survey pop-up banner to display. For example, one time in one session.',
  audience_FORM:
    'You can set the audience of the form survey pop-up banner to display. For example, to the new visitors or returning visitors.',

  // Hero Banner
  timingTitle_HERO_BANNER: 'You can set the time and frequency of the banner.',
  timing_HERO_BANNER:
    'You can display the banner right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_HERO_BANNER:
    'You can set the frequency of the banner to display. For example, one time in one session.',
  audience_HERO_BANNER:
    'You can set the audience of the banner to display. For example, to the new visitors or returning visitors.',

  // Push Permission
  'Enter the title that your visitors will see on the push permission.':
    'Enter the title that your visitors will see on the push permission.',
  'Enter the description of the push permission.':
    'Enter the description of the push permission.',
  'Enter the URL address of the logo that will be displayed on the push permission.':
    'Enter the URL address of the logo that will be displayed on the push permission.',
  position_PUSH_PERMISSION:
    'You can set the position of the push permission on the page.',
  verticalPosition_PUSH_PERMISSION:
    'You can set the position of the push permission on top, middle or bottom of the page.',
  horizontalPosition_PUSH_PERMISSION:
    'You can set the position of the push permission on left, middle or right section of the page.',
  timingTitle_PUSH_PERMISSION:
    'You can set the time and the frequency of the push permission.',
  timing_PUSH_PERMISSION:
    'You can display the push permission right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_PUSH_PERMISSION:
    'You can set the frequency of the push permission to display. For example, one time in one session.',
  "Enter the call to action text to give permission. For example, 'Allow'":
    "Enter the call to action text to give permission. For example, 'Allow'",
  "Enter the call to action text to ask for permission later again. For example, 'Remind me later'":
    "Enter the call to action text to ask for permission later again. For example, 'Remind me later'",

  // Push Notifications
  'Enter the title that your visitors will see on the web push notification.':
    'Enter the title that your visitors will see on the web push notification.',
  'Enter the description of the web push notificaiton.':
    'Enter the description of the web push notificaiton.',
  'Enter the URL address of the icon that will be displayed on the web push notification.':
    'Enter the URL address of the icon that will be displayed on the web push notification.',
  'Enter the URL address of the image that will be displayed on the web push notification.':
    'Enter the URL address of the image that will be displayed on the web push notification.',
  'Enter the URL of the page that your visitors will be directed when they click on the web push notification.':
    'Enter the URL of the page that your visitors will be directed when they click on the web push notification.',
  scheduling_PUSH_NOTIFICATION: 'You can plan and schedule to send web push.',
  audience_PUSH_NOTIFICATION:
    'You can set the audience of the web push to send. For example, to the new visitors or returning visitors.',

  // Notification Bar
  'Enter the title that your visitors will see on the notification bar.':
    'Enter the title that your visitors will see on the notification bar.',
  'Enter the URL address of the image that will be displayed on the notification bar.':
    'Enter the URL address of the image that will be displayed on the notification bar.',
  'Enter the URL of the page that your visitors will be directed when they click on the notification bar.':
    'Enter the URL of the page that your visitors will be directed when they click on the notification bar.',
  styles_NOTIFICATION_BAR: 'Select the font and the background color.',
  position_NOTIFICATION_BAR:
    'You can set the position of the notification bar on the page.',
  verticalPosition_NOTIFICATION_BAR:
    'You can set the position of the notification bar on top, middle or bottom of the page.',
  horizontalPosition_NOTIFICATION_BAR:
    'You can set the position of the notification bar on left, middle or right section of the page.',
  timingTitle_NOTIFICATION_BAR:
    'You can set the time and frequency of the notification bar.',
  timing_NOTIFICATION_BAR:
    'You can display the notification bar right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_NOTIFICATION_BAR:
    'You can set the frequency of the notification bar to display. For example, one time in one session.',
  audience_NOTIFICATION_BAR:
    'You can set the audience of the notification bar to display. For example, to the new visitors or returning visitors.',

  "Can't update user information, new password is same with current password":
    "Can't update user information, new password is same with current password",

  'Keep on page change': 'Keep on page change',
  'Category View': 'Category View',
  'Category Name': 'Category Name',
  impressions: 'impressions',
  'Completed Campaigns': 'Completed Campaigns',
  'Conversion Rate': 'Conversion Rate',
  Pages: 'Pages',
  'Views Referred From Adwords': 'Views Referred From Adwords',
  'Views Referred From Retargeting': 'Views Referred From Retargeting',
  'Views Referred From Price Comparison Sites':
    'Views Referred From Price Comparison Sites',
  'Views Referred From Affiliates': 'Views Referred From Affiliates',
  'Views Referred From Referrals': 'Views Referred From Referrals',

  'Will be sent at': 'Will be sent at',
  'Last 7 Days': 'Last 7 Days',
  'The campaign was successfully archived.':
    'The campaign was successfully archived.',
  'Shuffle Promotions': 'Shuffle Promotions',

  'You must change the end date in order to save campaign.':
    'You must change the end date in order to save campaign.',
  Feature: 'Feature',

  'Impression / View / Click': 'Impression / View / Click',
  Items: 'Items',

  'The percentage of products that have been purchased after being clicked in a Segmentify Recommendation widget.':
    'The percentage of products that have been purchased after being clicked in a Segmentify Recommendation widget.',

  'There are no records to show.': 'There are no records to show.',
  'You should make at least one selection from Segmentify Intelligent or Static algorithms':
    'You should make at least one selection from Segmentify Intelligent or Static algorithms',
  'New Comers': 'New Comers',
  'All Rocks': 'All Rocks',
  "All Recommends from all products in your website's catalog with an intelligent sorting ":
    "All Recommends from all products in your website's catalog with an intelligent sorting ",
  // Widget/Promotion/Search Keywrods Desc&Info
  Widget_Desc:
    "Create personal recommendation campaigns with Segmentify's intelligent and static algorithms.",
  Promotion_Desc:
    "Personalize the Special Offers you display in your website with Segmentify's Promotion campaigns.",
  'Search-Keyword_Desc':
    "Find out the top searched keywords in your website and recommend them so your customer's can find what they want more easily.",

  'NoActivePopup RecommendationCampaigns': () => {
    return (
      <div>
        To continue making personalized recommendations on your website, you can
        use popup recommendations on any page type. Click ‘Add Custom Widget’
        below to create a new popup recommendation widget.
      </div>
    );
  },
  'NoActiveJourney BuilderCampaigns': () => {
    return (
      <div>
        <p>
          When you need a journey which created uniquely for each of your
          customers, you can define journey builder popups.
        </p>
        <p>
          Please select journey builder below to{' '}
          <strong>add new journey builder popup.</strong>
        </p>
      </div>
    );
  },
  Before_Search_Input:
    "Attract your customers with Segmentify's intelligent algorithms even before they make a search.",

  // FACETED SEARCH
  General: 'General',
  Language: 'Language',
};

export default es;
