/**
 * Created by mehmetyurtar on 3/30/18.
 */
import React from "react";
import { connect } from "react-redux";
import { isMultiAccountUser, isUserAdmin } from "../modules/auth/user";
import { getUserAccounts } from "../modules/account/ajax";
import { switchUserActions, uiActions } from "../actions";
import Icon from "../components/icon";
import Icons from "../components/icons";
import { t } from "../system/ui";
import { Link } from "react-router";
import Tooltip from "../components/tooltip";

const preventFunc = e => {
  let confirmationMessage = "o/";
  e.returnValue = confirmationMessage;
  return confirmationMessage;
};

class User_Accounts extends React.Component {
  constructor(props) {
    super(props);

    this.getAccounts = this.getAccounts.bind(this);
    this.switchUserAccount = this.switchUserAccount.bind(this);
    this.searchHandler = this.searchHandler.bind(this);

    this.state = {
      accounts: [],
      _accounts: []
    };
  }

  componentDidMount() {
    uiActions.resetPage();
    uiActions.addPageClass("sidebar-is-hidden");
    this.getAccounts(this.props.user);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.user !== newProps.user) {
      this.getAccounts(newProps.user);
    }

    if (newProps.switchedUser !== this.props.switchedUser) {
      this.props.router.push({
        pathname: "/welcome"
      });
    }
  }

  getAccounts(user) {
    uiActions.isLoading();
    if (isMultiAccountUser(user)) {
      getUserAccounts(
        { multiUserAccountId: user.account.accountId },
        response => {
          uiActions.isLoaded();
          this.setState({
            accounts: response,
            _accounts: response
          });
        }
      );
    }
  }

  switchUserAccount(accountId) {
    const switchableAccountsWithDetails = this.state._accounts.filter(
      account => accountId != account.accountId,
    );
    let accountToSwitch = Object.assign({}, this.props.user);
    let selectedAccount = {};
    if (accountId) {
      selectedAccount = this.state.accounts.filter(
        account => account.accountId === accountId
      );
      selectedAccount = selectedAccount[0];
      accountToSwitch.account = selectedAccount;
    }
    accountToSwitch.switchableAccountsWithDetails = switchableAccountsWithDetails;
    window.addEventListener("beforeunload", preventFunc);
    switchUserActions.updateSwitchUser(accountToSwitch);
  }

  searchHandler(e) {
    let accounts = this.state._accounts,
      matchedSearch,
      search = e.currentTarget.value.toLowerCase();

    matchedSearch = accounts.filter(account => {
      return (
        account.domain && account.domain.toLowerCase().indexOf(search) > -1
      );
    });

    if (this.refs.search && !this.refs.search.value) {
      accounts = this.state._accounts;
    }

    this.setState({
      accounts: matchedSearch
    });
  }

  render() {
    return [
      <div className="user-accounts-header">
        <div className="user-accounts-header-titles">
          <Link to="/user/accounts" className="user-accounts-header--active">
            {t("Select Your Account")}
          </Link>
          {isUserAdmin() && <Link to="/user/users">{t("Manage Users")}</Link>}
        </div>
        {this.state._accounts.length > 4 && (
          <div className="user-accounts-search">
            <label className="item is-stacked is-search">
              <Icon name="magnify" />
              <input
                type="text"
                placeholder={t("Search")}
                className="text-field"
                onKeyUp={this.searchHandler}
                ref="search"
              />
            </label>
          </div>
        )}
      </div>,
      <div className="user-accounts">
        <ul className="user-accounts-list">
          {this.state.accounts.map(account => {
            let accountUsers = account.authorizedUsers || [],
              accountUsersText = accountUsers ? accountUsers.join(", ") : "";
            return (
              <li
                onClick={this.switchUserAccount.bind(this, account.accountId)}
              >
                {account.domain}
                <p>{account.description}</p>
                {isUserAdmin() && (
                  <span className="user-accounts-users">
                    <Tooltip
                      content={accountUsersText}
                      newTooltip={true}
                      alignment="top"
                    >
                      <Icons
                        name="users"
                        width="16"
                        height="16"
                        color="#9e9e9e"
                      />
                      {accountUsers.length + " " + t("Authorized Users")}
                    </Tooltip>
                  </span>
                )}
                <span className="user-accounts-list-nav">
                  <Icons name="tailRight" />
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    ];
  }
}

const MapStateToProps = store => ({
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser
});

export default connect(MapStateToProps)(User_Accounts);
