const accountsTable = {
  header: [
    { text: '#', className: 'record-id' },
    { text: 'Account', sortable: true, sort: 'domain' },
    { text: 'Membership', sortable: true, sort: 'membershipDate' },
    { text: 'Type', sortable: true, sort: 'type' },
    { text: 'Provider', sortable: true, sort: 'provider' },
    { text: 'Global', sortable: true, sort: 'globalEnabled' },
    { text: 'Country', sortable: true, sort: 'country' },
    { text: 'Industry', sortable: true, sort: 'industry' },
    {
      text: 'Free Trial Start',
      sortable: true,
      sort: 'importantDates.freeTrialStart',
    },
    {
      text: 'Free Trial End',
      sortable: true,
      sort: 'importantDates.freeTrialEnd',
    },
    { text: 'Contact' },
    { text: 'Responsible' },
    { text: 'GA4 Compare' },
    { text: 'Description*' },
    { text: '', className: 'table-options' },
  ],
};

const gaTable = [
  { text: '#', className: 'record-id' },
  {
    text: 'Domain',
    sortable: true,
    sort: 'domain',
    tooltip: 'The domain of Segmentify account',
  },
  {
    text: 'ID',
    sortable: true,
    sort: 'account_id',
    tooltip: 'The account id of Segmentify account',
  },
  {
    text: 'Revenue  (SGF)',
    sortable: true,
    sort: 'sgfRevenue',
    tooltip: 'Total sales amount of the account measured by Segmentify',
  },
  {
    text: 'Revenue  (GA)',
    sortable: true,
    sort: 'gaRevenue',
    tooltip: 'Total sales amount of the account measured by Google Analytics 4',
  },
  {
    text: '%',
    sortable: true,
    sort: 'revenueRatio',
    tooltip:
      'Ratio between Revenue measured by Segmentify over Revenue Measured by Google Analytics 4',
  },
  {
    text: 'Purchases (SGF)',
    sortable: true,
    sort: 'sgfPurchases',
    tooltip: 'Total purchase counts of the account measured by Segmentify',
  },
  {
    text: 'Purchases (GA)',
    sortable: true,
    sort: 'gaPurchases',
    tooltip:
      'Total purchase counts of the account measured by Google Analytics 4',
  },
  {
    text: '%',
    sortable: true,
    sort: 'purchasesRatio',
    tooltip:
      'Ratio between Purchases measured by Segmentify over Purchases Measured by Google Analytics 4',
  },
  {
    text: 'Traffic (SGF)',
    sortable: true,
    sort: 'sgfPage',
    tooltip:
      'Total traffic of the account in page views measured by Segmentify',
  },
  {
    text: 'Traffic (GA)',
    sortable: true,
    sort: 'gaPage',
    tooltip:
      'Total traffic of the account in page views measured by Google Analytics 4',
  },
  {
    text: '%',
    sortable: true,
    sort: 'pageRatio',
    tooltip:
      'Ratio between Page Views measured by Segmentify over Page Views Measured by Google Analytics 4',
  },
  {
    text: 'Push $ (SGF)',
    sortable: true,
    sort: 'sgfPushRevenue',
    tooltip:
      'Total sales amount of the account generated by push notifications and measured by Segmentify',
  },
  {
    text: 'Push $ (GA)',
    sortable: true,
    sort: 'gaPushRevenue',
    tooltip:
      'Total sales amount of the account generated by push notifications and measured by Google Analytics 4',
  },
  {
    text: '%',
    sortable: true,
    sort: 'pushRevenueRatio',
    tooltip:
      'Ratio between Push Revenue measured by Segmentify over Push Revenue Measured by Google Analytics 4',
  },
  {
    text: 'Mail $ (SGF)',
    sortable: true,
    sort: 'sgfMailRevenue',
    tooltip:
      'Total sales amount of the account generated by personalised email and measured by Segmentify',
  },
  {
    text: 'Mail $ (GA)',
    sortable: true,
    sort: 'gaMailRevenue',
    tooltip:
      'Total sales amount of the account generated by personalised email and measured by Google Analytics 4',
  },
  {
    text: '%',
    sortable: true,
    sort: 'mailRevenueRatio',
    tooltip:
      'Ratio between Mail Revenue measured by Segmentify over Mail Revenue Measured by Google Analytics 4',
  },
];

const statsTable = {
  recommendationHeader: [
    { text: '#', className: 'record-id' },
    {
      text: 'Domain',
      sortable: true,
      sort: 'domain',
      tooltip: 'The domain of the website',
    },
    {
      text: 'ID',
      sortable: true,
      sort: 'account_id',
      tooltip: 'The account ID',
    },
    {
      text: 'Page View',
      className: 'cell-group',
      sortable: true,
      sort: 'pageAll',
      tooltip: 'The total pageviews of the website',
    },
    {
      text: 'Widget View',
      sortable: true,
      sort: 'widgetView',
      tooltip:
        'The total number of Segmentify recommendation widgets on the website that have actually been seen by the visitors',
    },
    {
      text: 'Impression',
      sortable: true,
      sort: 'impression',
      tooltip:
        'The total number of pageviews with a recommendation widget on a page.',
    },
    {
      text: 'View Rate',
      sortable: true,
      sort: 'ratioView',
      tooltip:
        'The ratio between the number of views of the recommendation widgets over the total amount of pageviews with widgets on the page.',
    },
    {
      text: 'Click',
      sortable: true,
      sort: 'click',
      tooltip:
        'Shows how many times the products inside the recommendation widgets are clicked by your visitors.',
    },
    {
      text: 'CTR',
      sortable: true,
      sort: 'ratioCTR',
      tooltip:
        'Click-through rate shows the ratio between the number of clicks on a recommendation widget and the number of views by your visitors.',
    },
    {
      text: 'Purchase (SGF)',
      sortable: true,
      sort: 'sgfPurchases',
      className: 'cell-group',
      tooltip:
        'The number of purchases that include at least one product directed from Segmentify recommendation widgets',
    },
    {
      text: 'Purchases (ALL)',
      sortable: true,
      sort: 'allPurchases',
      tooltip: 'The total number of orders given on the website',
    },
    {
      text: '%',
      sortable: true,
      sort: 'ratioPurchases',
      tooltip:
        'The ratio between purchases through Segmentify recommendation widgets and orders given on the website',
    },
    {
      text: 'Items (SGF)',
      sortable: true,
      sort: 'sgfItems',
      className: 'cell-group',
      tooltip:
        'The total number of products that are sold through Segmentify recommendation widgets.',
    },
    {
      text: 'Items (ALL)',
      sortable: true,
      sort: 'allItems',
      tooltip: 'The total number of products that are sold in the website.',
    },
    {
      text: '%',
      sortable: true,
      sort: 'ratioItems',
      tooltip:
        'The ratio between purchased products through Segmentify widgets and the overall purchased products on the website',
    },
    {
      text: 'Amount (SGF)',
      sortable: true,
      sort: 'sgfAmount',
      className: 'cell-group',
      tooltip:
        'The total monetary amount of products sold through Segmentify recommendation widgets',
    },
    {
      text: 'Amount (ALL)',
      sortable: true,
      sort: 'allAmount',
      tooltip: 'The total monetary amount of products sold on the website',
    },
    {
      text: '%',
      sortable: true,
      sort: 'ratioAmount',
      tooltip:
        'The ratio between Segmentify’s contribution and the overall revenue. It shows Segmentify recommendation widgets’ contribution ratio.',
    },
    {
      text: 'Diff%',
      sortable: true,
      sort: 'ctrDiffRatio',
      tooltip:
        'The difference of Segmentify’s contribution ratio between the choosen time frame',
    },
    {
      text: 'Products',
      sortable: true,
      sort: 'productCount',
      className: 'cell-group',
      alignment: 'right',
      tooltip: 'Total product number in the website',
    },
    {
      text: 'Users',
      sortable: true,
      sort: 'userCount',
      className: 'cell-group',
      alignment: 'right',
      tooltip: 'The total number of unique users',
    },
    { text: '', className: 'table-options' },
  ],
  emailHeader: [
    { text: '#', className: 'record-id' },
    {
      text: 'Domain',
      sortable: true,
      sort: 'domain',
      tooltip: 'The domain of the website',
    },
    {
      text: 'ID',
      sortable: true,
      sort: 'account_id',
      tooltip: 'The account ID',
    },
    {
      text: 'Page View',
      className: 'cell-group',
      sortable: true,
      sort: 'pageAll',
      tooltip: 'Total pageviews of the website',
    },
    {
      text: 'Email Sent',
      sortable: true,
      sort: 'mailSent',
      tooltip: 'The total number of emails sent',
    },
    {
      text: 'Email Open',
      sortable: true,
      sort: 'mailOpened',
      tooltip:
        'The total number of email campaigns that have been read by the visitors.',
    },
    {
      text: 'Email Click',
      sortable: true,
      sort: 'mailClicked',
      tooltip:
        'The total number of times the products inside the email campaigns are clicked by the visitor',
    },
    {
      text: 'Open Rate ',
      sortable: true,
      sort: 'mailRatioOpen',
      tooltip:
        'Open Rate: The ratio between the number of opened email campaigns over the total number of emails sent',
    },
    {
      text: 'Click Rate',
      sortable: true,
      sort: 'mailRatioCTR',
      tooltip:
        'Click Rate: The ratio between the number of clicked email campaigns over the total number of emails sent',
    },
    {
      text: 'Purchase (Mail)',
      sortable: true,
      sort: 'mailPurchases',
      className: 'cell-group',
      tooltip:
        'The number of purchases that is directed from Segmentify email campaigns',
    },
    {
      text: 'Purchases (ALL)',
      sortable: true,
      sort: 'allPurchases',
      tooltip: 'The total number of orders given on the website',
    },
    {
      text: '%',
      sortable: true,
      sort: 'mailRatioPurchase',
      tooltip:
        'The ratio between purchases through Segmentify email campaigns and orders given on the website',
    },
    {
      text: 'Items (Mail)',
      sortable: true,
      sort: 'mailItems',
      className: 'cell-group',
      tooltip:
        'The total number of products that are sold through Segmentify email campaigns',
    },
    {
      text: 'Items (ALL)',
      sortable: true,
      sort: 'allItems',
      tooltip: 'The total number of products that are sold in the website',
    },
    {
      text: '%',
      sortable: true,
      sort: 'mailRatioItem',
      tooltip:
        'The ratio between purchased products through Segmentify email campaigns and the overall purchased products on the website',
    },
    {
      text: 'Amount (Mail)',
      sortable: true,
      sort: 'mailAmount',
      className: 'cell-group',
      tooltip:
        'The total monetary amount of products sold through Segmentify email campaigns',
    },
    {
      text: 'Amount (ALL)',
      sortable: true,
      sort: 'allAmount',
      tooltip: 'The total monetary amount of products sold on the website',
    },
    {
      text: '%',
      sortable: true,
      sort: 'mailRatioRevenue',
      tooltip:
        'The ratio between Segmentify’s contribution and the overall revenue. It shows Segmentify Email Campaigns’ contribution ratio',
    },
    {
      text: 'Diff%',
      sortable: true,
      sort: 'ctrDiffRatioMail',
      tooltip:
        'The difference of Segmentify’s contribution ratio between the choosen time frame',
    },
    {
      text: 'LTV',
      sortable: true,
      sort: 'mailRatioLTVGeneral',
      tooltip:
        'The lifetime value of the sent emails. It’s calculated by dividing the total monetary amount of email campaigns to the number of emails sent.',
    },
    {
      text: 'Email Users',
      sortable: true,
      sort: 'totalMailAddress',
      tooltip: 'The total number of email users',
    },
    {
      text: 'Reachable Email Users',
      sortable: true,
      sort: 'validMailCount',
      tooltip:
        'The total number of email users that Segmentify can actually send emails. This is the most up-to-date number.',
    },
    {
      text: 'Total Campaign',
      sortable: true,
      sort: 'mailTotalCampaign',
      tooltip: 'The total number of active Segmentify Email campaigns.',
    },
    {
      text: 'Products',
      sortable: true,
      sort: 'productCount',
      className: 'cell-group',
      tooltip: 'Total product number in the website',
      alignment: 'right',
    },
    {
      text: 'Users',
      sortable: true,
      sort: 'userCount',
      className: 'cell-group',
      tooltip: 'The total number of unique users',
      alignment: 'right',
    },
    { text: '', className: 'table-options' },
  ],
  pushHeader: [
    { text: '#', className: 'record-id' },
    {
      text: 'Domain',
      sortable: true,
      sort: 'domain',
      tooltip: 'The domain of the website',
    },
    {
      text: 'ID',
      sortable: true,
      sort: 'account_id',
      tooltip: 'The account ID',
    },
    {
      text: 'Page View',
      className: 'cell-group',
      sortable: true,
      sort: 'pageAll',
      tooltip: 'The total pageviews of the website',
    },
    {
      text: 'Total Subscriber Count',
      className: 'cell-group',
      sortable: true,
      sort: 'totalSubscriberCount',
      tooltip: 'Total number of users that give push permission in the website',
    },
    {
      text: 'Push Sent',
      sortable: true,
      sort: 'pushSent',
      tooltip: 'The total number of push notifications that have been sent',
    },
    {
      text: 'Push Impression',
      sortable: true,
      sort: 'pushShown',
      tooltip: 'he total number times of push notifications have been viewed',
    },
    {
      text: 'Push Click',
      sortable: true,
      sort: 'pushClicked',
      tooltip:
        'The total number of times the products on the push notifications have been clicked by the visitors.',
    },
    {
      text: 'Impression Rate',
      sortable: true,
      sort: 'pushRatioShown',
      tooltip:
        'Impression Rate: The ratio between the number of viewed push notifications over the total number of notifications sen',
    },
    {
      text: 'Click Rate',
      sortable: true,
      sort: 'pushRatioCTR',
      tooltip:
        'Click Rate: The ratio between the number of clicks on push notifications and the number of times notifications have been viewed by the visitors.',
    },
    {
      text: 'Purchase (Push)',
      sortable: true,
      sort: 'pushPurchases',
      className: 'cell-group',
      tooltip:
        'The number of purchases that is directed from Segmentify push notifications.',
    },
    {
      text: 'Purchases (ALL)',
      sortable: true,
      sort: 'allPurchases',
      tooltip: 'The total number of orders given on the website',
    },
    {
      text: '%',
      sortable: true,
      sort: 'pushRatioPurchase',
      tooltip:
        'The ratio between the number of purchases that is directed from Segmentify push notifications over the total number of total number of orders given.',
    },
    {
      text: 'Items (Push)',
      sortable: true,
      sort: 'pushItems',
      className: 'cell-group',
      tooltip:
        'The total number of products that are sold through Segmentify push notifications',
    },
    {
      text: 'Items (ALL)',
      sortable: true,
      sort: 'allItems',
      tooltip: 'The total number of products sold on the website.',
    },
    {
      text: '%',
      sortable: true,
      sort: 'pushRatioItem',
      tooltip:
        'The ratio between purchased products through Segmentify push notifications and the overall purchased products on the website',
    },
    {
      text: 'Amount (Push)',
      sortable: true,
      sort: 'pushAmount',
      className: 'cell-group',
      tooltip:
        'The total monetary amount of products sold through Segmentify push notifications.',
    },
    {
      text: 'Amount (ALL)',
      sortable: true,
      sort: 'allAmount',
      tooltip: 'The total monetary amount of products sold on the website',
    },
    {
      text: '%',
      sortable: true,
      sort: 'pushRatioRevenue',
      tooltip:
        'The ratio between Segmentify’s contribution and the overall revenue. It shows Segmentify Push Campaigns’ contribution ratio.',
    },
    {
      text: 'Diff%',
      sortable: true,
      sort: 'ctrDiffRatioPush',
      tooltip:
        'The difference of Segmentify’s contribution ratio between the choosen time frame',
    },
    {
      text: 'LTV General',
      sortable: true,
      sort: 'pushRatioLTVGeneral',
      tooltip:
        'The lifetime value of all sent push notifications. It’s calculated by dividing the total monetary amount of push campaigns to the number of push notifications sent.',
    },
    {
      text: 'LTV Bulk',
      sortable: true,
      sort: 'pushRatioLTVBulk',
      tooltip:
        'The lifetime value of the sent bulk push notifications. It’s calculated by dividing the total monetary amount of bulk push campaigns to the number of push notifications sent',
    },
    {
      text: 'LTV Personalised',
      sortable: true,
      sort: 'pushRatioLTVPersonalized',
      tooltip:
        'The lifetime value of the sent personalised push notifications. It’s calculated by dividing the total monetary amount of personalised push campaigns to the number of personalised push notifications sent',
    },
    {
      text: 'LTV Recommendation',
      sortable: true,
      sort: 'pushRatioLTVRecommendation',
    },
    {
      text: 'Push Total Campaign',
      sortable: true,
      sort: 'pushTotalCampaign',
      tooltip: 'The total number of active Segmentify push campaigns.',
    },
    {
      text: 'Products',
      sortable: true,
      sort: 'productCount',
      className: 'cell-group',
      tooltip: 'Total product number in the website',
      alignment: 'right',
    },
    {
      text: 'Users',
      sortable: true,
      sort: 'userCount',
      className: 'cell-group',
      tooltip: 'The total number of unique users',
      alignment: 'right',
    },
    { text: '', className: 'table-options' },
  ],
  searchHeader: [
    { text: '#', className: 'record-id' },
    {
      text: 'Domain',
      sortable: true,
      sort: 'domain',
      tooltip: 'The domain of the website',
    },
    {
      text: 'ID',
      sortable: true,
      sort: 'account_id',
      tooltip: 'The account ID',
    },
    {
      text: 'Page View',
      className: 'cell-group',
      sortable: true,
      sort: 'pageAll',
      tooltip: 'The total pageviews of the website',
    },
    {
      text: 'Faceted Searches',
      sortable: true,
      sort: 'facetViews',
      tooltip:
        'The total number of searches made on search result page by using Segmentify Faceted Search. Search can be triggered by changing search keyword, selecting or removing filters, changing ordering or asking for next page',
    },
    {
      text: 'Instant Searches',
      sortable: true,
      sort: 'searchWidgetView',
      tooltip:
        'The total number of quick searches made on Segmentify searchbox Indicates how many times the searchbox widget is opened and visitor made search queries',
    },
    {
      text: 'Click',
      sortable: true,
      sort: 'searchClick',
      tooltip:
        'Shows how many times the products inside the Search Box and Searchandising campaigns are clicked by your visitors.',
    },
    {
      text: 'CTR',
      sortable: true,
      sort: 'searchRatioCTR',
      tooltip:
        'Click-through rate shows the ratio between the number of clicks in Search Box and Searchandising campaigns and the number of views by your visitors.',
    },
    {
      text: 'Purchase (SGF)',
      sortable: true,
      sort: 'searchPurchases',
      className: 'cell-group',
      tooltip:
        'The number of purchases that include at least one product directed from Segmentify searchbox widgets',
    },
    {
      text: 'Purchases (ALL)',
      sortable: true,
      sort: 'allPurchases',
      tooltip: 'The total number of orders given on the website',
    },
    {
      text: '%',
      sortable: true,
      sort: 'searchRatioPurchases',
      tooltip:
        'The ratio between purchases through Segmentify searchbox widgets and orders given on the website',
    },
    {
      text: 'Items (SGF)',
      sortable: true,
      sort: 'searchItems',
      className: 'cell-group',
      tooltip:
        'The total number of products that are sold through Segmentify searchbox widgets.',
    },
    {
      text: 'Items (ALL)',
      sortable: true,
      sort: 'allItems',
      tooltip: 'The total number of products that are sold in the website.',
    },
    {
      text: '%',
      sortable: true,
      sort: 'searchRatioItems',
      tooltip:
        'The ratio between purchased products through Segmentify searchbox widgets and the overall purchased products on the website.',
    },
    {
      text: 'Amount (SGF)',
      sortable: true,
      sort: 'searchAmount',
      className: 'cell-group',
      tooltip:
        'The total monetary amount of products sold through Segmentify searchbox widgets',
    },
    {
      text: 'Amount (ALL)',
      sortable: true,
      sort: 'allAmount',
      tooltip: 'The total monetary amount of products sold on the website',
    },
    {
      text: '%',
      sortable: true,
      sort: 'searchRatioAmount',
      tooltip:
        'The ratio between Segmentify’s contribution and the overall revenue. It shows Segmentify searchbox widgets’ contribution ratio.',
    },
    {
      text: 'Diff%',
      sortable: true,
      sort: 'ctrDiffRatioSearch',
      tooltip:
        'The difference of Segmentify’s contribution ratio between the choosen time frame',
    },
    {
      text: 'Products',
      sortable: true,
      sort: 'productCount',
      className: 'cell-group',
      alignment: 'right',
      tooltip: 'Total product number in the website',
    },
    {
      text: 'Users',
      sortable: true,
      sort: 'userCount',
      className: 'cell-group',
      alignment: 'right',
      tooltip: 'The total number of unique users',
    },
    { text: '', className: 'table-options' },
  ],
  otherHeader: [
    { text: '#', className: 'record-id' },
    { text: 'Domain', sortable: true, sort: 'domain' },
    { text: 'ID', sortable: true, sort: 'account_id' },
    {
      text: 'Page View',
      className: 'cell-group',
      sortable: true,
      sort: 'pageAll',
    },
    { text: 'Purchases (ALL)', sortable: true, sort: 'allPurchases' },
    { text: 'Items (ALL)', sortable: true, sort: 'allItems' },
    { text: 'Amount (ALL)', sortable: true, sort: 'allAmount' },
    {
      text: 'Products',
      sortable: true,
      sort: 'productCount',
      className: 'cell-group',
    },
    {
      text: 'Users',
      sortable: true,
      sort: 'userCount',
      className: 'cell-group',
    },
    { text: '', className: 'table-options' },
  ],
};

const monitoringAlertTable = [
  { text: '#', className: 'record-id' },
  {
    text: 'Domain',
    sortable: false,
  },
  {
    text: 'Account ID',
    sortable: false,
  },
  {
    text: 'Segmentify.js',
    sortable: false,
  },
  {
    text: 'Product Feed',
    sortable: false,
  },
  {
    text: 'Page View',
    sortable: false,
  },
  {
    text: 'Purchase',
    sortable: false,
  },
  {
    text: 'Recommendation Performance',
    sortable: false,
  },
  {
    text: 'Search.js',
    sortable: false,
  },
  {
    text: 'Sw.js',
    sortable: false,
  },
  {
    text: 'Push Campaign',
    sortable: false,
  },
  {
    text: 'Email Campaign',
    sortable: false,
  },
  {
    text: 'Contacts API',
    sortable: false,
  },
  {
    text: 'General Event',
    sortable: false,
  },
];

const monitoringTypeMaps = {
  SEGMENTIFY_JS: {
    name: 'Segmentify.js',
  },
  FEED: {
    name: 'Product Feed',
  },
  PAGE_VIEW: {
    name: 'Page View',
  },
  PURCHASE: {
    name: 'Purchase',
  },
  RECOMMENDATION: {
    name: 'Recommendation Performance',
  },
  SEARCH_JS: {
    name: 'Search.js',
  },
  SW_JS: {
    name: 'Sw.js',
  },
  PUSH: {
    name: 'Push Campaign',
  },
  MAIL: {
    name: 'Email Campaign',
  },
  CONTACTS_API: {
    name: 'Contacts API',
  },
  EVENT: {
    name: 'General Event',
  },
};

const userManagementTable = {
  header: [
    { text: '#', className: 'record-id' },
    {
      text: 'Display Name',
      sort: 'display_name',
    },
    {
      text: 'User Type',
      sort: 'user_type',
    },
    {
      text: 'Username',
      sort: 'username',
    },
    {
      text: 'Access Status',
      sort: 'access_status',
    },
    {
      text: 'Lock Email Sent Date',
      sort: 'locked_date',
    },
    {
      text: 'Actions',
      sort: 'actions',
    },
  ],
};

const features = [
  { name: 'Weekly Report', key: 'weeklyReport' },
  { name: 'Problems', key: 'problems' },
  { name: 'Promotion', key: 'promotion' },
  { name: 'Keyword', key: 'keyword' },
  { name: 'Wishlist', key: 'wishlist' },
  { name: 'Recommendation Monitoring', key: 'recommendationMonitoring' },
  {
    name: 'Page View & Purchase Monitoring',
    key: 'pageViewAndPurchaseMonitoring',
  },
  { name: 'General Event Monitoring', key: 'generalEventMonitoring' },
  { name: 'Replenishment', key: 'replenishment' },
];

const variants = [
  { name: 'Variants', key: 'variantEnabled' },
  {
    name: 'Recommendation Variants',
    key: 'recommendVariants',
    relatedModuleKey: 'variantEnabled',
  },
  {
    name: 'Search Box Variants',
    key: 'recommendVariantsForSearch',
    relatedModuleKey: 'variantEnabled',
  },
];

const featureModules = [
  { name: 'Recommendation', key: 'recommendation' },
  { name: 'Web Push', key: 'push' },
  { name: 'Email Marketing', key: 'email' },
  { name: 'Search & Discovery', key: 'search' },
  { name: 'Searchandising', key: 'faceted' },
  { name: 'Trendify', key: 'trendify' },
  { name: 'Bannerify', key: 'bannerify' },
  { name: 'Engagement', key: 'personalization' },
  {
    name: 'Engagement Plus',
    key: 'engagementPlus',
    relatedModuleKey: 'personalization',
  },
  { name: 'Customer Data Management', key: 'ruleBasedSegmentation' },
  { name: 'Dynamic Bundles', key: 'dynamicBundles' },
  {
    name: 'Smart Segmentation',
    key: 'segmentation',
    relatedModuleKey: 'ruleBasedSegmentation',
  },
];

const owners = [
  { name: 'Sales Manager', key: 'salesManager' },
  { name: 'Onboarding Manager', key: 'onboardingManager' },
  { name: 'Onboarding Developer', key: 'onboardingDeveloper' },
  { name: 'Account Manager', key: 'accountManager' },
  { name: 'Account Developer', key: 'accountDeveloper' },
  { name: 'Agent User', key: 'agentUser' },
];

const monitorTable = {
  header: [
    { text: '#', className: 'record-id' },
    { text: 'Account', sortable: true, sort: 'domain' },
    { text: 'Responsible' },
    { text: 'Type', sortable: true, sort: 'type' },
    { text: 'Tier', sortable: true, sort: 'tier' },
    { text: 'Live Date' },
    { text: 'Module' },
    { text: 'Target', sortable: true, sort: 'targets' },
    { text: 'Realized', sortable: true, sort: 'realized' },
    { text: 'Trend' },
    { text: 'Performance', sortable: true, sort: 'performance', tooltip: true },
    { text: 'GA Diff', sortable: true, sort: 'ga', tooltip: true },
    { text: ' ', className: 'actions' },
  ],
};

export {
  accountsTable,
  featureModules,
  features,
  gaTable,
  monitorTable,
  owners,
  statsTable,
  userManagementTable,
  variants,
  monitoringAlertTable,
  monitoringTypeMaps,
};
