import { hideBadResponseCat, showBadResponseCat } from 'actions/ui';
import { getState } from 'system/store';
import { $http } from '../../system/ajax';
import api from '../../system/api';
import { getApiKey } from '../auth/user';

const getSalesOrCount = (data, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint('sales/list') + globalApiKey)
    .post(data, { type: 'json', preventAbort: false })
    .then(response => {
      if (getState().ui.badResponseCatVisible) {
        hideBadResponseCat();
      }
      callback(response);
    })
    .catch(err => {
      if (err.status === 429 || err.status === '429') {
        showBadResponseCat();
      }
    });
};

export const getSales = (data, callback) => {
  const newData = data;
  if (data.currency && data.currency === 'ALL') {
    newData.currency = '';
  }
  newData.type = 'LIST';

  return getSalesOrCount(newData, callback);
};

export const getSalesCount = (data, callback) =>
  getSalesOrCount({ ...data, type: 'COUNT' }, callback);

export const getSalesDetail = (data, callback) => {
  $http(`${api.getRestEndPoint('sales/detail')}?apiKey=${getApiKey()}`)
    .post(data, { type: 'json' })
    .then(response => {
      if (getState().ui.badResponseCatVisible) {
        hideBadResponseCat();
      }
      callback(response);
    })
    .catch(err => {
      if (err.status === 429 || err.status === '429') {
        showBadResponseCat();
      }
    });
};

export const getSalesBanner = (date, data, callback) => {
  $http(
    `${api.getRestEndPoint('sales/banner')}?apiKey=${getApiKey()}&day=${date}`,
  )
    .post(data, { type: 'json', preventAbort: false })
    .then(response => {
      if (getState().ui.badResponseCatVisible) {
        hideBadResponseCat();
      }
      callback(response);
    })
    .catch(err => {
      if (err.status === 429 || err.status === '429') {
        showBadResponseCat();
      }
    });
};

export const getSalesTotal = (data, callback) =>
  getSalesOrCount({ ...data, type: 'TOTAL' }, callback);

export default {
  getSales,
  getSalesTotal,
  getSalesCount,
  getSalesDetail,
  getSalesBanner,
};
