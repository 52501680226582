import React, { Component } from "react";
import { t } from "../../system/ui";

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.position="fixed";  //avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);
}

function copyTextToClipboard(text, cb) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    cb();
  });
}

class DotDigitalModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCopied: false
    };
  }

  copy() {
    const text = `
      {% for context in contact.insight.Segmentify-ABANDONED_CART %}
        {% for prx in context.products %}
            {{prx.name}}
            {{prx.oldPriceText}}
          {{prx.priceText}}
        {% endfor %}
      {% endfor %}
      `;

    if (!navigator.clipboard) {
      return;
    }
    copyTextToClipboard(text, () => {
      this.setState({ isCopied: true });
    });
  }

  render() {
    return (
      <div className="page-forms">
        <ul>
          <li>
            <p>
              <strong>Insights data</strong> Insight data is storable
              information related to your customers’ online transactions and
              habits. It allows you to store collections of arbitrary data
              against your contacts or account via Dotdigital’s API.
            </p>
            <p style={{ marginTop: "10px" }}>
              We store insight data for each email scenario. For exp, we store
              customers’ basket transactions for the Cart Abandonment scenario.
            </p>
          </li>
          <li>
            <p style={{ marginTop: "10px" }}>
              <strong>Liquid</strong> Liquid is a markup language, which was
              developed for the eCommerce system Shopify. Liquid allows you to
              create content that changes, depending on the current data, and
              acts as an extension to the CSS and HTML that emails and landing
              pages are built upon.
            </p>
            <p style={{ marginTop: "10px" }}>
              A fuller description of what is possible in Liquid can be found on{" "}
              <a
                href="https://github.com/Shopify/liquid/wiki/Liquid-for-Designers"
                target="blank"
                style={{ color: "#cb4535" }}
              >
                Github
              </a>
              .
            </p>
          </li>
        </ul>
        <h4>Sample Code Block For Abandoned Cart Scenario</h4>
        <div
          style={{
            backgroundColor: "#efeef7",
            borderRadius: "10px",
            padding: "5px",
            position: "relative",
            fontWeight: "600"
          }}
        >
          <div>
            {"{"}% for context in contact.insight.Segmentify-ABANDONED_CART %
            {"}"}
          </div>
          <div style={{ marginLeft: "15px" }}>
            {"{"}% for prx in context.products %{"}"}
          </div>
          <div style={{ marginLeft: "30px" }}>
            {"{"}
            {"{"}prx.name{"}"}
            {"}"}
          </div>
          <div style={{ marginLeft: "30px" }}>
            {"{"}
            {"{"}prx.oldPriceText{"}"}
            {"}"}
          </div>
          <div style={{ marginLeft: "30px" }}>
            {"{"}
            {"{"}prx.priceText{"}"}
            {"}"}
          </div>
          <div style={{ marginLeft: "15px" }}>
            {"{"}% endfor %{"}"}
          </div>
          <div>
            {"{"}% endfor %{"}"}
          </div>

          <a
            href
            style={{
              position: "absolute",
              right: "10px",
              bottom: "10px",
              padding: "10px",
              borderRadius: "5px",
              textAlign: "center",
              minWidth: "100px"
            }}
            className="button tertiary-action"
            onClick={this.copy.bind(this)}
          >
            {this.state.isCopied ? t("Copied!") : t("Copy")}
          </a>
        </div>
        <p style={{ marginTop: "10px" }}>
          You can reach all Segmentify campaigns by typing;
          Segmentify-Campaign_Name
        </p>
      </div>
    );
  }
}

export default DotDigitalModal;
