import { createReducer } from "../system/store";
import * as wizardActions from "../constants/actions/wizard";

const initialState = {
  mappings: []
};

export default createReducer(initialState, {
  [wizardActions.default.ADD_WIZARD_MAPPING]: (state, payload) => {
    return Object.assign({}, state, {
      mappings: payload.mappings
    });
  },
  [wizardActions.default.REMOVE_WIZARD_MAPPING]: (state, payload) => {
    return Object.assign({}, state, {
      mappings: payload.mappings
    });
  }
});
