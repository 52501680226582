import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { modalActions, uiActions } from 'actions';
import { createDraftCopy } from 'actions/faceted-search';
import { useDispatch } from 'react-redux';
import { FACETED_SEARCH_CREATE_DRAFT_CAMPAIGN } from 'constants/actions/faceted-search';

const CreateDraftModal = ({ instanceId }) => {
  const [draftName, setDraftName] = useState('');
  const dispatch = useDispatch();

  const onDraftNameChange = e => {
    setDraftName(e.target.value);
  };

  const createDraftVersion = () => {
    createDraftCopy(instanceId, draftName)
      .then(draftVersionOfCampaign => {
        dispatch({
          type: FACETED_SEARCH_CREATE_DRAFT_CAMPAIGN,
          data: draftVersionOfCampaign,
        });
        uiActions.showNotification({
          content: () => <p>Draft version created successfully!</p>,
        });
      })
      .catch(err => {
        uiActions.showErrorNotification({
          content: () => (
            <>
              <p>Error occurred while creating draft version of campaign</p>
              <p>{JSON.stringify(err)}</p>
            </>
          ),
        });
      });
    modalActions.closeModal();
  };

  return (
    <div id="create-draft-modal-content">
      <div className="draft-modal-container">
        <input
          type="text"
          placeholder=". . ."
          value={draftName}
          onChange={onDraftNameChange}
        />
        <span className="draft-input-label">[Draft] - </span>
      </div>
      <button
        type="button"
        className="tertiary-action"
        onClick={createDraftVersion}
      >
        Create Draft Version
      </button>
    </div>
  );
};

CreateDraftModal.propTypes = {
  instanceId: PropTypes.number.isRequired,
};

export default CreateDraftModal;
