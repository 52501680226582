/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/* eslint-disable prefer-spread */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { Creatable } from 'react-select';
import classNames from 'classnames';

import { t } from 'system/ui';
import { algorithmDescriptions } from 'constants/datamaps/wizard';
import Icons from 'components/icons';
import Tooltip from '../../../../components/tooltip';
import Icon from '../../../../components/icon';
import localClasses from './Typo.module.scss';

const maxSynonymItemRightValue = 10;

const classes = {
  leftValueClass: 'search__synonym-left-value',
  rightValueClass: 'search__synonym-right-value',
  leftValueCoverClass: 'search__synonym-left-value-cover',
};

export default class TypoReplacementItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leftValue: {},
      rightValues: [],
      validation: {
        left: {
          valueEmptyValidation: false,
          valueUsedValidation: true,
        },
        right: {
          valuesEmptyValidation: false,
          valuesMaxValidation: true,
          valueUsedValidation: true,
        },
      },
      allRightValues: [],
    };
    this.remove = this.remove.bind(this);
    this.onRightValueChange = this.onRightValueChange.bind(this);
    this.onLeftValueChange = this.onLeftValueChange.bind(this);
    this.convertToLowerCase = this.convertToLowerCase.bind(this);
    this.validateRightValue = this.validateRightValue.bind(this);
    this.isKeywordUsedBefore = this.isKeywordUsedBefore.bind(this);
    this.checkInitialValidation = this.checkInitialValidation.bind(this);
  }

  componentDidMount() {
    this.checkInitialValidation();
    this.setState({
      allRightValues: this.getAllRightValues(this.props.synonymItems),
    });
  }

  componentDidUpdate(nextProps, nextState) {
    // component will rendered with synonymItems changes and any state changes.
    const { synonymItem } = this.props;

    const currentSynonymItem = JSON.stringify(synonymItem);
    const nextSynonymItem = JSON.stringify(nextProps.synonymItem);
    const propsCase = currentSynonymItem !== nextSynonymItem;

    const currentStateJson = JSON.stringify(
      this.getOnlyValues(this.state, ['leftValue', 'rightValues']),
    );
    const nextStateJson = JSON.stringify(
      this.getOnlyValues(nextState, ['leftValue', 'rightValues']),
    );
    const stateCase = currentStateJson !== nextStateJson;
    if (propsCase || stateCase) {
      this.checkInitialValidation();
      return true;
    }
    return false;
  }

  getOnlyValues(originalObj, fields) {
    const croppedObj = {};
    for (const field in croppedObj) {
      if (fields.indexOf(field) === -1) {
        croppedObj[field] = originalObj[field];
      }
    }
    return croppedObj;
  }

  checkInitialValidation() {
    // sanırsam sayfa acıldıgında bi calısması gerek
    const { synonymItem } = this.props;
    const validation = {
      left: {
        valueEmptyValidation: !!(
          synonymItem.leftValue && synonymItem.leftValue.value
        ),
        valueUsedValidation: true,
      },
      right: {
        valuesEmptyValidation:
          synonymItem.rightValues &&
          synonymItem.rightValues.length > 0 &&
          synonymItem.rightValues.filter(synonymItem => {
            return synonymItem.values === '';
          }).length === 0,
        valuesMaxValidation: true,
        valueUsedValidation: true,
      },
    };
    this.setState({
      rightValueCoverClass: algorithmDescriptions()[this.props.operator]
        .hasLeftValue
        ? 'search__synonym-right-value-cover'
        : 'search__synonym-right-value-cover__large',
      validation,
    });
  }

  isValidated(operandSide) {
    let validated = true;
    for (const validationType in this.state.validation[operandSide]) {
      validated =
        validated && this.state.validation[operandSide][validationType];
    }
    return validated;
  }

  remove() {
    this.props.onRemove(this.props.selectedLanguage, this.props.synonymItem.id);
  }

  isKeywordUsedBefore(selectedKeyword) {
    const { synonymItems } = this.props;

    selectedKeyword = this.convertToLowerCase(selectedKeyword);

    let validCond1;
    for (const operator in synonymItems) {
      const otherItems = synonymItems.filter(synonymItem => {
        return (
          synonymItem.id !== this.props.synonymItem.id &&
          synonymItem.leftValue.value !== ''
        );
      });

      validCond1 =
        otherItems
          .map(item => {
            return this.convertToLowerCase(item.leftValue.value);
          })
          .indexOf(selectedKeyword) > -1;
      if (validCond1) {
        return true;
      }
    }

    return validCond1;
  }

  onLeftValueChange(selectedValue) {
    const tempSynonymItem = this.props.synonymItem;

    if (selectedValue === null) {
      // this.props.synonymItem.leftValue = "";
      tempSynonymItem.leftValue = '';
      this.setState({
        leftValue: '',
        validation: {
          ...this.state.validation,
          left: {
            valueUsedValidation: true,
            valueEmptyValidation: false,
          },
        },
      });
    } else {
      let selectedKeyword = selectedValue || this.state.leftValue;
      const isUsedBefore = this.isKeywordUsedBefore(selectedKeyword.value);
      if (isUsedBefore) {
        // this.props.synonymItem.leftValue = "";
        tempSynonymItem.leftValue = { label: null, value: null };
        this.setState({
          leftValue: '',
          validation: {
            ...this.state.validation,
            left: {
              valueEmptyValidation: true,
              valueUsedValidation: false,
            },
          },
        });
      } else {
        selectedKeyword = {
          // className: selectedKeyword.className,
          label: this.convertToLowerCase(selectedKeyword.label),
          value: this.convertToLowerCase(selectedKeyword.value),
        };

        // this.props.synonymItem.leftValue = selectedKeyword;
        tempSynonymItem.leftValue = selectedKeyword;
        this.setState({
          leftValue: selectedKeyword,
          validation: {
            ...this.state.validation,
            left: {
              valueUsedValidation: true,
              valueEmptyValidation:
                selectedKeyword && selectedKeyword.value !== '',
            },
          },
        });
      }
    }
    this.props.onChange(tempSynonymItem, this.props.selectedLanguage);
  }

  onRightValueChange(selectedValues) {
    const tempSynonymItem = this.props.synonymItem;
    if (selectedValues.length > maxSynonymItemRightValue) {
      const validation = { ...this.state.validation };
      validation.right.valuesMaxValidation = false;
      validation.right.valueUsedValidation = true;
      validation.right.valuesEmptyValidation = true;

      this.setState({ validation });
    } else {
      const twoWayOperator = algorithmDescriptions().SEARCH_SYNONYMS_TWO_WAY
        .operatorUIKey;
      const { synonymItems } = this.props;
      const lang = this.props.selectedLanguage;
      let isUsed = false;
      if (selectedValues.length !== 0) {
        for (const operator in synonymItems[lang]) {
          if (operator !== twoWayOperator) {
            const otherItems = synonymItems[lang][operator].filter(
              synonymItem => {
                return (
                  synonymItem.id !== this.props.synonymItem.id &&
                  synonymItem.leftValue.value !== ''
                );
              },
            );
            isUsed =
              otherItems
                .map(item => {
                  return this.convertToLowerCase(item.leftValue.value);
                })
                .indexOf(
                  this.convertToLowerCase(
                    selectedValues[selectedValues.length - 1].label,
                  ),
                ) > -1;
          } else {
            const otherTwoWayItems = synonymItems[lang][operator].filter(
              synonymItem => {
                return (
                  synonymItem.id !== this.props.synonymItem.id &&
                  synonymItem.rightValues.length !== 0
                );
              },
            );
            let otherTwoWayValues = [];
            otherTwoWayItems.map(item => {
              otherTwoWayValues = otherTwoWayValues.concat(
                item.rightValues.map(rVal => {
                  return this.convertToLowerCase(rVal.value);
                }),
              );
            });
            isUsed =
              otherTwoWayValues.indexOf(
                this.convertToLowerCase(
                  selectedValues[selectedValues.length - 1].label,
                ),
              ) > -1;
          }
          if (isUsed) {
            break;
          }
        }
      }
      if (isUsed) {
        const validation = { ...this.state.validation };
        validation.right.valuesMaxValidation = true;
        validation.right.valueUsedValidation = false;
        validation.right.valuesEmptyValidation = true;
        this.setState({ validation });
      } else {
        let selectedSynonyms =
          typeof selectedValues !== 'undefined'
            ? selectedValues
            : this.state.rightValues;
        selectedSynonyms = selectedSynonyms.map(synonymItem => {
          return {
            // className: synonymItem.className,
            label: this.convertToLowerCase(synonymItem.label),
            value: this.convertToLowerCase(synonymItem.value),
          };
        });
        const validation = { ...this.state.validation };
        validation.right.valuesMaxValidation = true;
        validation.right.valueUsedValidation = true;
        validation.right.valuesEmptyValidation = true;

        validation.right.valuesEmptyValidation =
          selectedSynonyms.filter(synonymItem => {
            return synonymItem.values === '';
          }).length === 0 && selectedValues.length !== 0;
        // this.props.synonymItem.rightValues = selectedSynonyms;
        tempSynonymItem.rightValues = selectedSynonyms;
        this.setState({
          rightValues: selectedSynonyms,
          validation,
        });
      }
    }
    this.props.onChange(tempSynonymItem, this.props.selectedLanguage);
  }

  validateRightValue(option) {
    // synonym word that will be added for two way should not be in other left values
    const isValidMaxRightValues =
      option.label !== '' &&
      typeof option.label !== 'undefined' &&
      this.state.rightValues.length < maxSynonymItemRightValue + 1;

    const isUsedBefore = this.state.allRightValues.includes(option.label);

    return isValidMaxRightValues && !isUsedBefore;
  }

  getAllRightValues(allItems) {
    let result = [];
    allItems.forEach(item => {
      const tempItems = item.rightValues.map(smItem => {
        return smItem.value;
      });
      result = [...result, ...tempItems];
    });
    return result;
  }

  convertToLowerCase(keyword) {
    if (!keyword) {
      return keyword;
    }
    if (this.props.selectedLanguage && this.props.selectedLanguage !== '') {
      return keyword.toLocaleLowerCase(this.props.selectedLanguage);
    }
    return keyword.toLocaleLowerCase();
  }

  render() {
    return (
      <>
        {/* <li className="wizard-criterion wizard-item-draggable wizard-item-cards-draggable" ref="inputs"> */}
        {algorithmDescriptions()[this.props.operator].hasLeftValue && (
          <>
            <Tooltip content='Enter the correct word' alignment='left'>
              <Icon className={localClasses.typoIcon} name='info' />
            </Tooltip>
            <span
              className={classes.leftValueCoverClass}
              style={{ width: '30%' }}
            >
              <Creatable
                id={this.props.id}
                options={[]}
                multi={false}
                autosize
                clearable
                searchable
                name='leftValue'
                noResultsText=''
                value={this.props.synonymItem.leftValue}
                onChange={this.onLeftValueChange}
                placeholder='Enter the keyword...'
                className={classNames(
                  localClasses.left,
                  !this.isValidated('left')
                    ? localClasses.hasError
                    : localClasses.noError,
                )}
                shouldKeyDownEventCreateNewOption={e => {
                  switch (e.keyCode) {
                    case 9:
                    case 13:
                      return !0;
                    default:
                      return !1;
                  }
                }}
              />
              {!this.state.validation.left.valueUsedValidation ? (
                <span className='item-error'>
                  <span className='item-error'>
                    {t('The typo already used!')}
                  </span>
                </span>
              ) : (
                ''
              )}
              {!this.state.validation.left.valueEmptyValidation ? (
                <span className='item-error'>
                  <span className='item-error'>
                    {t('The field is required!')}
                  </span>
                </span>
              ) : (
                ''
              )}
            </span>
          </>
        )}
        <Tooltip content='Enter the misspelled words' alignment='left'>
          <Icon className={localClasses.typoIcon} name='info' />
        </Tooltip>
        <span
          className={this.state.rightValueCoverClass}
          style={{ marginLeft: '0', width: '70%', position: 'relative' }}
        >
          <Creatable
            id={this.props.id}
            options={[]}
            multi
            clearable={false}
            searchable
            name='rightValues'
            noResultsText=''
            style={{ width: '100%' }}
            value={this.props.synonymItem.rightValues}
            onChange={this.onRightValueChange}
            placeholder='Enter the typos...'
            className={classNames(
              localClasses.right,
              !this.isValidated('right')
                ? localClasses.hasError
                : localClasses.noError,
            )}
            isValidNewOption={this.validateRightValue}
            shouldKeyDownEventCreateNewOption={e => {
              switch (e.keyCode) {
                case 9:
                case 13:
                  return !0;
                default:
                  return !1;
              }
            }}
          />
          {!this.state.validation.right.valueUsedValidation ? (
            <span className='item-error'>
              <span className='item-error'>{t('The typo already used!')}</span>
            </span>
          ) : (
            ''
          )}

          {!this.state.validation.right.valuesMaxValidation ? (
            <span className='item-error'>
              <span className='item-error'>
                {t(`You can add just ${maxSynonymItemRightValue} synonyms.`)}
              </span>
            </span>
          ) : (
            ''
          )}
          {!this.state.validation.right.valuesEmptyValidation ? (
            <span className='item-error'>
              <span className='item-error'>{t('The field is required!')}</span>
            </span>
          ) : (
            ''
          )}
        </span>
        <a
          onClick={this.remove}
          className='cancel-action'
          style={{
            display: 'flex',
            width: '24px',
            height: '24px',
            position: 'relative',
            top: 'auto',
            right: 'auto',
          }}
        >
          <Icons width='24' height='24' name='crossCircle' />
        </a>
      </>
    );
  }
}
