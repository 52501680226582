import React from 'react';
import Icons from '../../../icons';
import { t } from '../../../../system/ui';
import Icon from '../../../icon';
import './SFYFilterButton.scss';

const SFYAttributeButton = ({
  item,
  segmentType,
  setAttributeComp,
  isOpen,
}) => {
  let currentIcon = '';
  if (
    item.icon === 'excludeLabel' ||
    item.icon === 'excludeGender' ||
    item.icon === 'excludeCustom' ||
    item.isSvg
  ) {
    currentIcon = (
      <Icons
        name={item.icon}
        color={
          item.icon.indexOf('asteriks') < 0 && item.icon.indexOf('brain') < 0
            ? '#787779'
            : '#fff'
        }
      />
    );
  } else {
    currentIcon = (
      <div style={{ display: 'inline-block', 'margin-right': '5px' }}>
        <Icon name={item.icon} />
      </div>
    );
  }

  return (
    <a
      key={item.title}
      className={
        item.title !== 'Custom' && isOpen
          ? 'sfy-activate-attribute-btn-selected unselectable'
          : 'sfy-activate-attribute-btn unselectable'
      }
      onClick={() => setAttributeComp(item.attributeType, segmentType, !isOpen)}
    >
      {isOpen && (
        <span className='actived-attribute'>
          <Icons name='checkCircle' width='18' height='18' />
        </span>
      )}
      <span className='activated-btn-icon'>{currentIcon}</span>
      <span className='activated-btn-title'>{t(item.title)}</span>
    </a>
  );
};

export default SFYAttributeButton;
