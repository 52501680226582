import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import styles from './EmailPreview.module.scss';
import { SFYChildWrapper } from '../../../../components/sfy-components/module-exports';
import EmailCampaignPreview from '../../components/email-campaign-preview/EmailCampaignPreview';
import EmailOverviewModal from '../../modals/EmailOverviewModal';
import { Context as EmailContext } from '../../store/EmailProvider';
import { modalActions } from '../../../../actions';
import { t } from '../../../../system/ui';

const EmailPreview = ({ saveHandler, openTestModal, mode }) => {
  const {
    state: { activeEmailDetails },
  } = useContext(EmailContext);

  const userRole = useSelector(
    ({ switchedUser, user }) =>
      switchedUser?.switchedUser?.userRole || user?.user?.userRole,
  );

  const email = {
    companyName: activeEmailDetails?.params?.fromName || 'Your Company',
    address: activeEmailDetails?.params?.fromAddress || 'email@domain.com',
    subject: activeEmailDetails?.params?.subject || 'Please add subject here',
    preheader:
      activeEmailDetails?.params?.mailContent || 'Please add preheader here',
  };

  const emailOverview = () => {
    saveHandler('TEST', false);
    const emailOverviewModal = () => (
      <EmailOverviewModal saveHandler={saveHandler} mode={mode} />
    );
    modalActions.openModal({
      title: `${t('Overview')}`,
      content: emailOverviewModal,
      className: 'email-overview-modal dashboard-campaign-details-modal',
    });
  };

  return (
    <>
      <SFYChildWrapper type='flexColGap2'>
        <div className={styles['email-preview']}>
          <div className={styles['email-preview-container']}>
            <div className={styles['email-preview-title']}>Preview</div>
            <EmailCampaignPreview
              email={email}
              campaignStatus={activeEmailDetails?.status || 'PASSIVE'}
              saveHandler={saveHandler}
              openTestModal={openTestModal}
              mode={mode}
            />
          </div>
        </div>
      </SFYChildWrapper>
      <div className={styles['email-routing']}>
        {userRole !== 'READ_ONLY' && mode !== 'preview' && (
          <button
            type='button'
            className={`${styles['email-routing-button']} ${styles['save-draft-btn']}`}
            onClick={() => saveHandler('TEST')}
          >
            Save as Draft
          </button>
        )}
        <button
          type='button'
          className={`${styles['email-routing-button']} ${styles['continue-btn']}`}
          onClick={emailOverview}
        >
          Continue
        </button>
      </div>
    </>
  );
};

export default EmailPreview;
