import React from 'react';
import PropTypes from 'prop-types';
import IconTailRight from '../../icons/tail-right';
import { uiActions } from '../../../actions';
import { getNewsletterReport } from '../../../modules/reports/ajax';
import { t } from '../../../system/ui';
import FormStats from '../../widget/personalization-form-stats';

const DateRangedFormStats = ({ campaign, startDate, endDate }) => {
  const { instanceId, fields } = campaign;
  const [results, setResults] = React.useState([]);
  const [showAllResults, setShowAllResults] = React.useState(false);

  const getCountInResponses = (option, arr) => {
    const count = arr.filter(item => item === option).length;
    return {
      count,
      percentage:
        (count * 100) / arr.length
          ? ((count * 100) / arr.length).toFixed(2)
          : 0,
    };
  };

  const fetchResults = () => {
    uiActions.isLoading();
    getNewsletterReport(
      instanceId,
      responses => {
        const responsesWithStats = [];
        if (responses.length) {
          // Results
          fields.forEach((stat, statInd) => {
            const statItem = { ...stat };
            statItem.userResponses = [];
            statItem.stats = [];
            if (
              statItem.type === 'CHECKBOX' ||
              statItem.type === 'RADIO_BUTTON'
            ) {
              responses.forEach(result => {
                if (result.fields[statItem.groupTitle]) {
                  statItem.userResponses = statItem.userResponses.concat(
                    result.fields[statItem.groupTitle].split(','),
                  );
                } else if (statItem.type === 'RADIO_BUTTON') {
                  const slang = 'radiobutton';
                  if (result.fields[`${slang}-${statInd}`]) {
                    statItem.userResponses = statItem.userResponses.concat(
                      result.fields[`${slang}-${statInd}`].split(','),
                    );
                  }
                } else if (
                  result.fields[`${statItem.type.toLowerCase()}-${statInd}`]
                ) {
                  statItem.userResponses = statItem.userResponses.concat(
                    result.fields[
                      `${statItem.type.toLowerCase()}-${statInd}`
                    ].split(','),
                  );
                }
              });
              statItem.options.forEach(option => {
                statItem.stats.push({
                  name: option,
                  count: getCountInResponses(option, statItem.userResponses),
                });
              });
            } else {
              responses.forEach(result => {
                if (result.fields[statItem.name]) {
                  statItem.userResponses = statItem.userResponses.concat(
                    result.fields[statItem.name].split(','),
                  );
                } else if (statItem.type === 'TEXT_AREA') {
                  const slang = 'textarea';
                  if (result.fields[`${slang}-${statInd}`]) {
                    statItem.userResponses = statItem.userResponses.concat(
                      result.fields[`${slang}-${statInd}`].split(','),
                    );
                  }
                } else if (
                  result.fields[`${statItem.type.toLowerCase()}-${statInd}`]
                ) {
                  statItem.userResponses = statItem.userResponses.concat(
                    result.fields[
                      `${statItem.type.toLowerCase()}-${statInd}`
                    ].split(','),
                  );
                }
              });
            }
            responsesWithStats.push(statItem);
          });
        }
        uiActions.isLoaded();
        setResults(responsesWithStats);
      },
      showAllResults === false && startDate,
      showAllResults === false && endDate,
    );
  };

  React.useEffect(() => {
    fetchResults();
  }, [showAllResults]);

  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [results.length]);

  return (
    <>
      <div className='collected-emails-date-range'>
        <p>
          {startDate} - {endDate} listed
        </p>
        <p>
          <a onClick={() => setShowAllResults(true)}>
            See All time
            <IconTailRight width='12' />
          </a>
        </p>
      </div>
      {results.length ? (
        <FormStats results={results} />
      ) : (
        <div style={{ textAlign: 'center' }}>
          <i
            className='icon-info'
            style={{ color: '#98241a', padding: '10px 5px' }}
          />
          {t('There is no data')}
        </div>
      )}
    </>
  );
};

DateRangedFormStats.propTypes = {
  campaign: PropTypes.shape({
    instanceId: PropTypes.string,
    type: PropTypes.string,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        headingInd: PropTypes.string,
      }),
    ),
  }),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default DateRangedFormStats;
