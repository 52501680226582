import {
  welcomeHtml,
  mainCategoryHtml,
  categoryHtml,
  brandHtml,
  sizeHtml,
  colorHtml,
  priceRangeHtml,
  preJs,
} from './jbAdvanceConfig';

export const initialJourneyBuilderDiscoveryAsset = [
  {
    id: '0.6461577064040876',
    properties: {
      active: false,
      type: 'WELCOME',
      icons: 'users',
      text: 'Welcome',
      isData: false,
      isDetailsHidden: true,
    },
    values: {
      criterion: {
        additionalTitles: {
          EN:
            'Hello!  Your answers to a few questions we ask will help us to find the right products and personalize your shopping experience with your interests.',
          TR:
            'Merhaba! Sorduğumuz birkaç soruya verdiğiniz yanıtlar, doğru ürünleri bulmamıza ve alışveriş deneyiminizi ilgi alanlarınıza göre kişiselleştirmemize yardımcı olacaktır.',
        },
        buttonText: {
          EN: "Let's Start!",
          TR: 'Başlayalım!',
        },
        status: 'PASSIVE',
      },
    },
    advanceConfig: {
      html: welcomeHtml,
      prejs: preJs,
      postjs: '',
      css: '',
      codetab: 'html',
    },
  },
  {
    id: '0.8461599066040713',
    properties: {
      active: true,
      type: 'MAIN_CATEGORY',
      icons: 'categoryBoxes',
      text: 'Main Category',
      isData: false,
      isDetailsHidden: true,
    },
    values: {
      criterion: {
        additionalTitles: {
          EN: 'Which category are you interested in?',
          TR: 'Hangi kategoriye ilgi duyuyorsun?',
        },
        assetItems: [],
        status: 'PASSIVE',
      },
    },
    advanceConfig: {
      html: mainCategoryHtml,
      prejs: preJs,
      postjs: '',
      css: '',
      codetab: 'html',
    },
  },
  {
    id: '0.4461577066040776',
    properties: {
      active: true,
      type: 'CATEGORY',
      icons: 'dashboard',
      text: 'Category',
      isData: true,
      isDetailsHidden: true,
    },
    values: {
      criterion: {
        additionalTitles: {
          EN:
            "Let's dig a little deeper. Which of the following attracts you more?",
          TR:
            'Haydi, soruyu biraz daha özelleştirelim. Aşağıdakilerden hangisi seni daha çok cezbediyor?',
        },
        assetItems: [
          {
            id: '0.561036181621207',
            type: 'intelligent',
            values: {
              criterion: {
                value: 'TOP_CATEGORY',
              },
              itemCount: {
                value: 8,
              },
            },
          },
        ],
        status: 'PASSIVE',
      },
    },
    advanceConfig: {
      html: categoryHtml,
      prejs: preJs,
      postjs: '',
      css: '',
      codetab: 'html',
    },
  },
  {
    id: '0.03465258576390795',
    properties: {
      active: false,
      type: 'BRAND',
      icons: 'excludeLabel',
      text: 'Brand',
      title: 'brand',
      isData: true,
      isDetailsHidden: true,
    },
    values: {
      criterion: {
        additionalTitles: {
          EN:
            'Awesome! We started to learn more about you. What about your favorite brand?',
          TR:
            'Süper! Senin hakkında daha fazla şey öğrenmeye başladık. Peki ya favori markan?',
        },
        assetItems: [
          {
            id: '0.561037181671207',
            type: 'intelligent',
            values: {
              criterion: {
                value: 'TOP_BRAND',
              },
              itemCount: {
                value: 8,
              },
            },
          },
        ],
        status: 'PASSIVE',
      },
    },
    advanceConfig: {
      html: brandHtml,
      prejs: preJs,
      postjs: '',
      css: '',
      codetab: 'html',
    },
  },
  {
    id: '0.04465258576556795',
    properties: {
      active: false,
      type: 'SIZE',
      icons: 'plusCircle',
      text: 'Size',
      isData: true,
      isDetailsHidden: true,
    },
    values: {
      criterion: {
        additionalTitles: {
          EN: 'Which size makes you look even more magnificent?',
          TR: 'Hangi beden seni daha da harika gösteriyor?',
        },
        assetItems: [
          {
            id: '0.961136181624207',
            type: 'intelligent',
            values: {
              criterion: {
                value: 'TOP_SIZE',
              },
              itemCount: {
                value: 8,
              },
            },
          },
        ],
        status: 'PASSIVE',
      },
    },
    advanceConfig: {
      html: sizeHtml,
      prejs: preJs,
      postjs: '',
      css: '',
      codetab: 'html',
    },
  },
  {
    id: '0.14665258578396795',
    properties: {
      active: false,
      type: 'COLOR',
      icons: 'palette',
      text: 'Colour',
      isData: true,
      isDetailsHidden: true,
    },
    values: {
      criterion: {
        additionalTitles: {
          EN: 'Everyone has a favorite colour, yours?',
          TR: 'Herkesin favori bir rengi vardır, peki ya senin rengin?',
        },
        assetItems: [
          {
            id: '0.821036181621207',
            type: 'intelligent',
            values: {
              criterion: {
                value: 'TOP_COLOR',
              },
              itemCount: {
                value: 8,
              },
            },
          },
        ],
        status: 'PASSIVE',
      },
    },
    advanceConfig: {
      html: colorHtml,
      prejs: preJs,
      postjs: '',
      css: '',
      codetab: 'html',
    },
  },
  {
    id: '0.62665258578396485',
    properties: {
      active: false,
      type: 'PRICE_RANGE',
      icons: 'sales',
      text: 'Price Range',
      isData: false,
      isDetailsHidden: true,
    },
    values: {
      criterion: {
        additionalTitles: {
          EN: 'Can you help us find out your price range?',
          TR: 'Tercih ettiğin fiyat aralığını öğrenmemize yardım eder misin?',
        },
        status: 'PASSIVE',
      },
    },
    advanceConfig: {
      html: priceRangeHtml,
      prejs: preJs,
      postjs: '',
      css: '',
      codetab: 'html',
    },
  },
];

export const initialJourneyBuilderDeliveryAsset = [
  {
    id: '0.08437770660407761',
    properties: {
      active: false,
      type: 'RECOMMEND',
      icons: 'iconHeart',
      text: 'Recommendation Widget',
      isData: true,
      isDetailsHidden: true,
    },
    values: {
      criterion: {
        additionalTitles: {
          EN:
            'Thank you for your answers, now we know you better! We have attentively selected the most suitable products for you.',
          TR:
            'Cevapların için teşekkür ederiz artık seni daha iyi tanıyoruz! Sana en uygun ürünleri özenle seçtik.',
        },
        assetItems: [
          {
            id: '0.561036181620903',
            type: 'intelligent',
            values: {
              criterion: {
                value: 'BEST_MATCH',
              },
              itemCount: {
                value: 8,
              },
            },
          },
        ],
        status: 'PASSIVE',
      },
    },
  },
  {
    id: '0.789106706404087656',
    properties: {
      active: false,
      type: 'COUPON',
      icons: '',
      text: 'Coupon Code',
      isData: false,
      isDetailsHidden: true,
    },
    values: {
      criterion: {
        coupon: '',
        additionalTitles: {
          EN: 'Thank you for your answers',
          TR: 'Cevapların için teşekkür ederiz ',
        },
        desc: {
          EN:
            'Now we know you better! We want to give you a small thank you gift.',
          TR:
            'Artık seni daha iyi tanıyoruz! Sana küçük bir teşekkür hediyesi vermek istiyoruz.',
        },
        status: 'PASSIVE',
      },
    },
  },
  {
    id: '0.789106706404087186',
    properties: {
      active: false,
      type: 'LANDING',
      icons: 'landingPage',
      text: 'Landing Page',
      isData: false,
      isDetailsHidden: true,
    },
    values: {
      criterion: {
        landingPageUrl: '',
        additionalTitles: {
          EN: 'Show me more.',
          TR: 'Daha fazla göster.',
        },
        status: 'PASSIVE',
      },
    },
  },
];

export const jbInitialData = {
  name: 'JourneyBuilder',
  status: 'ACTIVE',
  devices: [],
  html: `<div id="sgm-journey-trigger-[[instanceId]]" class="sgm-journey-trigger sgm-journey-trigger-[[vertical]]-[[horizontal]]">
<div class="sgm-journey-trigger-content">
    [[#title]]
   <span>[[title]]</span>
    [[/title]]
    </div>
</div>`,
  prejs: `// this function will get a configuration object to be executed at html rendering with mustache.js
// conf object has two pre-added objects:
//   - title: name of the recommendation widget
//   - products: products array to be used at recommendation widget
// do not change the function name
function preRenderConf(conf) {
}`,
  postjs: '',
  css: '',

  deliveryAdvancedConfig: {
    html: `<div class="sgm-journey-delivery seg-popup seg-rec-popup seg-center-middle segFadeInUp">
  <div class="seg-popup-container">
    <div class="seg-popup-header">
      <h1 class="seg-popup-title">[[title]]</h1>
    </div>
    <div class="seg-popup-content">
      <div class="seg-rec-container">
        [[#products]]
        <div class="seg-rec-product">
          <a href="[[url]]" target="_self" title="[[name]]">
            <span class="seg-product-images"><img src="[[image]]" title="[[name]]" alt="[[name]]"></span>
            <span class="seg-product-title">[[name]]</span>
            <span class="seg-price-box">
              <span class="seg-regular-price">
                <span class="seg-product-currency">{{account:currencySymbol}}</span> [[price]]
              </span>
              [[#oldPrice]]
              <span class="seg-old-price">
                <span class="seg-product-currency">{{account:currencySymbol}}</span> [[oldPrice]]
              </span>
              [[/oldPrice]]
            </span>
          </a>
        </div>
        [[/products]]
      </div>
    </div>
  </div>
  <div id="sgm-journey-coupon-template">
    <div id="sgm-journey-coupon-wrapper" class="sgm-journey-none" data-instanceId="[[instanceId]]">
       <div class="sgm-journey-description sgm-journey-border">
        <p>
            [[#couponTitle]]
            <span>[[couponTitle]]</span>
            [[/couponTitle]]
        </p>
       </div>
      <span class="sgm-journey-delivery-coupon">[[coupon]]</span>
    </div>
  </div>
  <div class="sgm-journey-action sgm-journey-border">
    <button class="sgm-journey-discovery-action sgm-journey-discovery-action-copy sgm-journey-none" data-type="copy">[[button.copy]]</button>
    <button id="sgm-journey-action-redirect" class="sgm-journey-discovery-action sgm-journey-none" data-type="redirect">[[button.redirect]]</button>
    <button class="sgm-journey-discovery-action" data-type="close">[[button.close]]</button>
  </div>
 <span class="seg-popup-close"></span>
</div>`,
    prejs: `// this function will get a configuration object to be executed at html rendering with mustache.js
// conf object has two pre-added objects:
//   - title: name of the recommendation widget
//   - products: products array to be used at recommendation widget
// do not change the function name
function preRenderConf(conf) {
  // add your custom code inside the function
}`,
    postjs: '',
    css: '',
  },

  recommendationParams: {
    exclusions: '[]',
    staticItems: '[]',
    dynamicItems: '[]',
  },
};

export const jbRecoFields = {
  fields: [
    { field: 'positionOptions' },
    { field: 'timingOptions' },
    { field: 'audienceOptions' },
  ],
};

export const campaignData = {
  scenarioName: '',
  templateId: 'JB_1',
  accountId: '',
  instanceId: '',
  limitations: {
    activeDates: {
      startDate: null,
      endDate: null,
    },
  },
  actions: [
    {
      type: 'showPopup',
      targets: ['start_transition'],
      params: {
        notificationTitle: '',
        elimination: '',
        ordering: '',
        actionType: '',
        selector: '',
        eliminationFunction: '',
        insertType: '',
        recommendationTemplate: '',
        preJsCode: '',
        postJsCode: '',
        staticItems: '',
        dynamicItems: '',
        exclusions: '',
      },
      constraint: null,
      controlGroup: null,
      delayedAction: null,
      group: null,
    },
    {
      type: 'buildJourney',
      targets: ['build_transition'],
      params: {
        notificationTitle: '',
        elimination: '',
        ordering: '',
        actionType: '',
        selector: '',
        eliminationFunction: '',
        insertType: '',
        discovery: [],
        recommendationTemplate: '',
        preJsCode: '',
        postJsCode: '',
        staticItems: '',
        dynamicItems: '',
        exclusions: '',
      },
      constraint: null,
      controlGroup: null,
      delayedAction: null,
      group: null,
    },
  ],
  inputs: [],
  status: 'ACTIVE',
  testMode: true,
  device: 'PC',
};

export const initialAction = {
  type: '',
  targets: ['finish_transition'],
  params: {
    notificationTitle: '',
    notificationTitleMap: '',
    elimination: '',
    ordering: '',
    actionType: '',
    selector: '',
    eliminationFunction: '',
    insertType: '',
    recommendationTemplate: '',
    preJsCode: '',
    postJsCode: '',
    staticItems: '',
    dynamicItems: '',
    exclusions: '',
  },
  constraint: null,
  controlGroup: null,
  delayedAction: null,
  group: null,
};
