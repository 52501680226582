import { config } from '../constants/config';

export const setTitle = title => {
  document.title = title
    ? `${title} | ${config.defaultTitle}`
    : config.defaultTitle;
};

export const getTitle = title => {
  return title ? `${title} | ${config.defaultTitle}` : config.defaultTitle;
};

export default {
  setTitle,
  getTitle,
};
