import React, { useContext, useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { uiActions } from '../../../../../actions';
import { Context as GamificationContext } from '../../store/gamificationProvider';
import {
  campaignActionButtonConstant,
  getDynamicBreadCrumbOptions,
  scratchTabs,
  wofAdvancedConfig,
} from '../../constants/constants';
import {
  getWOFAdvancedConfigCampaignDetails,
  getWOFAdvancedConfigInitialTemplates,
} from '../../utils/utils';
import {
  SFYHeader,
  SFYRightBar,
  SFYWrapper,
  StaticTab,
} from '../../../../../components/sfy-components/module-exports';
import AdvancedConfig from '../../../../../components/sfy-components/advanced-config/AdvancedConfig';
import { GamificationService } from '../../services/gamificationServices';
import {
  LiveModeUpdatePersonalization,
  TestModeAddPersonalization,
} from '../../../../../constants/datamaps/notifications';
import { getAccountData } from '../../../../../modules/auth/user';
import SettingsTab from './tabs/SettingsTab';
import StyleTab from './tabs/StyleTab';

const ScratchAddOrEdit = ({ params }) => {
  const { mode, campaignId } = params;
  const {
    isLoaded,
    isLoading,
    showNotification,
    confirmationDialog,
  } = uiActions;
  const {
    state: { activeScratchDetails },
    setActiveScratchDetails,
    resetActiveScratchDetails,
    editActiveScratchDetails,
  } = useContext(GamificationContext);
  const [breadCrumbName, setBreadCrumbName] = useState('Scratch Off');
  const [tabs, setTabs] = useState(scratchTabs);
  const [isAdvancedConfigShown, setIsAdvancedConfigShown] = useState(false);
  const [templates, setTemplates] = useState(
    getWOFAdvancedConfigInitialTemplates(activeScratchDetails),
  );

  const setAdvancedConfigVisibility = (actionType = 'open') => {
    setIsAdvancedConfigShown(actionType === 'open');
  };

  const validate = () => {
    const {
      name,
      campaignTitle,
      devices,
      filters,
      possibleRewards,
    } = activeScratchDetails;
    if (!name || !campaignTitle || devices.length === 0) {
      return 'Please fill all the required fields.';
    }

    const pagesToShow =
      filters?.find(filter => {
        return filter.type === 'PAGE' && filter?.includedCategories?.length > 0;
      })?.includedCategories || [];
    if (pagesToShow.length === 0) {
      return 'Please select at least one page to show the campaign.';
    }

    const sumOfRewardPercentage = possibleRewards?.reduce(
      (acc, curr) => acc + (curr?.possibility || 0),
      0,
    );
    if (possibleRewards.length <= 0) {
      return 'You should add at least one coupon.';
    }
    if (
      activeScratchDetails.gamificationType !== 'UNIQUE' &&
      (sumOfRewardPercentage > 100 || sumOfRewardPercentage < 100)
    ) {
      return 'Sum of reward percentage should be 100.';
    }

    return true;
  };

  const goTestOrLive = saveType => {
    const fixedStatus = saveType === 'live' ? 'ACTIVE' : 'TEST';
    const body = { ...activeScratchDetails, status: fixedStatus, ...templates };
    if (mode === 'edit') {
      const id = campaignId;
      isLoading();
      GamificationService()
        .update({ id, body })
        .then(res => {
          const { status, name } = res;
          isLoaded();
          let content = TestModeAddPersonalization;
          if (status === 'ACTIVE') {
            content = LiveModeUpdatePersonalization;
          }
          showNotification({
            content,
            className: 'notification-success',
          });
          setActiveScratchDetails(res);
          setBreadCrumbName(name);
        })
        .finally(() => {
          isLoaded();
        });
    } else {
      isLoading();
      body.accountId = getAccountData('accountId');
      GamificationService()
        .create({ body })
        .then(res => {
          const { status, instanceId } = res;
          isLoaded();
          let content = TestModeAddPersonalization;
          if (status === 'ACTIVE') {
            content = LiveModeUpdatePersonalization;
          }
          showNotification({
            content,
            className: 'notification-success',
          });
          browserHistory.push(
            `/behavioural-targeting/engagement/gamification/scratch/edit/${instanceId}`,
          );
        })
        .finally(() => {
          isLoaded();
        });
    }
  };

  const validateAndSave = type => {
    const validation = validate();
    if (validation !== true) {
      const content = () => <p>{validation}</p>;
      showNotification({
        content,
        className: 'notification-fail',
      });
      return;
    }

    const displayStatus = type === 'test' ? 'Test' : 'Live';
    confirmationDialog({
      title: `Go ${displayStatus} the Campaign?`,
      content: `Are you sure you want to get the campaign ${displayStatus}?`,
      onConfirm: () => {
        goTestOrLive(type);
      },
    });
  };

  const editCampaign = (type, value) => {
    if (type === 'filters') {
      const filters = activeScratchDetails?.filters.filter(
        filter => filter.type !== value.type,
      );
      editActiveScratchDetails({
        filters: [...filters, value],
      });
      return;
    }
    editActiveScratchDetails({ [type]: value });
  };

  const duplicateEditHandler = () => {
    if (mode === 'edit' || mode === 'duplicate') {
      isLoading();
      GamificationService()
        .get({ id: campaignId })
        .then(res => {
          const tempRes = { ...res };
          if (mode === 'duplicate') {
            delete tempRes?.instanceId;
            tempRes.name = `${tempRes.name} Copy`;
          }
          if (mode === 'edit') {
            setBreadCrumbName(tempRes.name);
          }
          setActiveScratchDetails(tempRes);
        })
        .finally(() => {
          isLoaded();
        });
    }
  };

  const campaignActionButtonConstantWithFunctions = campaignActionButtonConstant.map(
    actionButton => {
      let actionButtonWithFunction;
      switch (actionButton.type) {
        case 'test':
        case 'live':
          actionButtonWithFunction = () => validateAndSave(actionButton.type);
          break;
        case 'advanceConfig':
          actionButtonWithFunction = setAdvancedConfigVisibility;
          break;
        default:
          actionButtonWithFunction = () => {};
          break;
      }
      return {
        ...actionButton,
        action: actionButtonWithFunction,
      };
    },
  );

  useEffect(() => {
    setTemplates(getWOFAdvancedConfigInitialTemplates(activeScratchDetails));
  }, [activeScratchDetails]);

  useEffect(() => {
    const settingsTab = <SettingsTab mode={mode} />;
    const styleTab = <StyleTab />;

    setTabs(
      tabs.map(tab => {
        return {
          ...tab,
          children: tab.type === 'settings' ? settingsTab : styleTab,
        };
      }),
    );

    duplicateEditHandler();
  }, [mode]);

  useEffect(() => {
    return () => {
      resetActiveScratchDetails();
    };
  }, []);

  return (
    <>
      <SFYHeader
        pageTitle='Scratch Off'
        breadCrumbOptions={getDynamicBreadCrumbOptions(breadCrumbName)}
      />

      <SFYWrapper>
        <StaticTab
          tabs={tabs}
          tabsSetter={newTabs => setTabs(newTabs)}
          alignCenter
        />
      </SFYWrapper>

      <SFYRightBar
        campaignActionButtonConstant={campaignActionButtonConstantWithFunctions}
        activeCampaign={activeScratchDetails}
        startDate={
          activeScratchDetails?.startDate === ''
            ? editCampaign('startDate', new Date().getTime())
            : new Date(activeScratchDetails?.startDate).getTime()
        }
        endDate={new Date(activeScratchDetails.endDate).getTime()}
        campaignSetter={editCampaign}
        isCampaignNameVisible
        isDevicesVisible
        isPagesAllVisible
        isPagesUrlVisible
        isAddCampaign={mode === 'add'}
      />

      <AdvancedConfig
        campaignDetails={getWOFAdvancedConfigCampaignDetails(
          activeScratchDetails,
        )}
        templates={templates}
        setTemplates={setTemplates}
        activeTabs={wofAdvancedConfig.activeTabs}
        params={wofAdvancedConfig.params}
        isShow={isAdvancedConfigShown}
        devices={activeScratchDetails?.devices || []}
        onHide={() => setAdvancedConfigVisibility('close')}
      />
    </>
  );
};

export default ScratchAddOrEdit;
