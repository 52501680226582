import React, { useState } from 'react';
import Select from 'react-select';
import Icons from '../../../icons';
import { decodeHtml } from '../../../../system/string';
import { searchCategories } from '../../../../modules/category/ajax';

const RPCategories = ({ categories, setAttributes, onRemove }) => {
  const [categoryOptions, setCategoryOptions] = useState([]);

  const onInputChange = value => {
    if (value.length > 2) {
      searchCategories(value, response => {
        const options = [];
        if (response) {
          response.forEach(category => {
            options.push({
              value: decodeHtml(category),
              label: decodeHtml(category),
            });
          });
        }
        setCategoryOptions(options);
      });
      return;
    }
    setCategoryOptions([]);
  };

  return (
    <div className='sfy-attribute-field sfy-related-products'>
      <div className='sfy-attribute-field-icon'>
        <i className='icon-tag noV-align' color='#7A7A7C' />
      </div>
      <div className='sfy-attribute-field-select'>
        <Select
          value={categories}
          onInputChange={onInputChange}
          options={categoryOptions}
          onChange={selected => {
            setAttributes(selected, 'categories');
          }}
          multi
          placeholder='Select Category'
          className='sfy-attribute-field-select-input'
          clearable={false}
        />
      </div>
      <div
        className='sfy-attribute-field-remove'
        onClick={() => onRemove('categories')}
      >
        <Icons name='crossCircle' color='#D31116' height='18' width='18' />
      </div>
    </div>
  );
};

export default RPCategories;
