import { useEffect, useState } from 'react';
import { useCampaignExtractorByType } from './useCampaignExtractorByType';
import { EmailPageTypes } from '../constants/page-configuration-constants';
import { findCampaignType } from './findCampaignType';
import { getEmails } from '../../../../modules/emails/ajax';
import { PageConfigurator } from './pageConfigurator';

export const useEmailCampaigns = props => {
  const [emailCampaignList, setEmailCampaignList] = useState([]);
  const [renderRaw, setRenderRaw] = useState([]);
  const [activeCampaign, setActiveCampaign] = useState([]);
  const [draftCampaign, setDraftCampaign] = useState([]);
  const [sentCampaign, setSentCampaign] = useState([]);

  const campaignType = findCampaignType(props.location);
  const pageConfig = PageConfigurator(campaignType);

  const { renderList } = useCampaignExtractorByType({
    location: props.location,
    emailList: emailCampaignList,
  });

  const arrangeEmails = () => {
    const campaignGroupType = findCampaignType(props.location).toUpperCase();
    let cmpType = EmailPageTypes[campaignGroupType];
    if (['RECOMMENDATION', 'FLY'].includes(campaignGroupType)) {
      cmpType = { [campaignGroupType]: campaignGroupType };
    }
    const rawData = [];

    getEmails(res => {
      const filteredCmp = res.filter(campaign => {
        if (cmpType) {
          if (Object.keys(cmpType).includes(campaign.type)) {
            return campaign;
          }
        }
      });
      setEmailCampaignList(filteredCmp);
    });
    renderList.forEach(item => {
      rawData.push(Object.entries(item)[0][1]);
    });
    setRenderRaw(rawData);
  };

  useEffect(() => {
    arrangeEmails();
  }, [props.location, renderList]);

  useEffect(() => {
    return () => {
      setRenderRaw([]);
      setActiveCampaign([]);
      setDraftCampaign([]);
      setSentCampaign([]);
      setEmailCampaignList([]);
    };
  }, [props.location]);

  useEffect(() => {
    renderRaw.forEach(item => {
      switch (item.status) {
        case 'ACTIVE':
          if (
            activeCampaign.find(
              campaign => campaign.instanceId === item.instanceId,
            )
          ) {
            return null;
          }
          if (pageConfig[item.type]) {
            if (item.type === 'RECOMMENDATION') {
              const itemDate = item?.schedule?.date || null;
              const now = Date.now();
              const itemType = item?.schedule?.type || null;
              if (
                itemDate &&
                itemType &&
                ['one_time', 'now'].includes(itemType) &&
                itemDate < now
              ) {
                setSentCampaign(prevState =>
                  [...prevState, item]?.sort(
                    (a, b) => b?.schedule.date - a?.schedule.date,
                  ),
                );
              } else {
                setActiveCampaign(prevState => [...prevState, item]);
              }
            } else {
              setActiveCampaign(prevState => [...prevState, item]);
            }
          } else {
            setActiveCampaign([]);
          }
          break;
        case 'TEST':
          if (
            draftCampaign.find(
              campaign => campaign.instanceId === item.instanceId,
            )
          ) {
            return null;
          }
          if (pageConfig[item.type]) {
            setDraftCampaign(prevState => [...prevState, item]);
          } else {
            setDraftCampaign([]);
          }
          break;
        default:
          break;
      }
    });
  }, [renderRaw]);

  return {
    renderRaw,
    activeCampaign,
    draftCampaign,
    sentCampaign,
  };
};
