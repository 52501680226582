import { createReducer } from "../system/store";
import * as synonymActions from "../constants/actions/synonyms";
const initialState = {
    id: Math.random(),
    errors: {
        languages: []
    },
    synonymItems: {},
    selectedLanguage: "",
    changesId: null,
};

export default createReducer(initialState, {
    [synonymActions.default.RESET_SYNONYMS]: (state) => {
        return Object.assign({}, state, {
            id: Math.random(),
            synonymItems: {},
            selectedLanguage: ""
        });
    },
    [synonymActions.default.ADD_ALL_SYNONYM]: (state, payload) => {
        const { synonyms } = payload;
        return { ...state, synonymItems: synonyms, id: Math.random(), changesId: Math.random()};
    },
    [synonymActions.default.ADD_ERROR]: (state, payload) => {
        if (state.errors.languages.indexOf(payload.language) === -1) {
            state.errors.languages.push(payload.language);
        }
        return Object.assign({}, state, { errors: state.errors });
    },
    [synonymActions.default.REMOVE_ERROR]: (state, payload) => {
        if (state.errors.languages.indexOf(payload.language) > -1) {
            state.errors.languages.remove(payload.language);
        }
        return Object.assign({}, state, { errors: state.errors });
    },
    [synonymActions.default.LANGUAGE_CHANGED]: (state, payload) => {
        return Object.assign({}, state, { selectedLanguage: payload.selectedLanguage, changesId: Math.random()});
    },

    [synonymActions.default.ADD_SYNONYMS_ITEM_GROUP]: (state, payload) => {
        let newTwoWaySynonyms = payload.selectedSynonyms.filter(filterTwoWay);
        let newOneWaySynonyms = payload.selectedSynonyms.filter(filterOneWay);
        let newReplaceSynonyms = payload.selectedSynonyms.filter(filterReplace);

        addSynonymItemGroup(payload.selectedLanguage, newTwoWaySynonyms, "SEARCH_SYNONYMS_TWO_WAY");
        addSynonymItemGroup(payload.selectedLanguage, newOneWaySynonyms, "SEARCH_SYNONYMS_ONE_WAY");
        addSynonymItemGroup(payload.selectedLanguage, newReplaceSynonyms, "SEARCH_SYNONYMS_REPLACE");

        state.id = Math.random();

        return Object.assign({}, state, { synonymItems: state.synonymItems, id: state.id, changesId: Math.random()});

        ///////////

        function addSynonymItemGroup(selectedLanguage, synonyms, operator) {
            if (selectedLanguage !== undefined) {
                if (!state.synonymItems[selectedLanguage]) {
                    state.synonymItems[selectedLanguage] = {};
                }
                if (synonyms.length > 0) {
                    if (!state.synonymItems[selectedLanguage][operator]) {
                        state.synonymItems[selectedLanguage][operator] = [];
                    }
                    let item = { id: Math.random(), "leftValue": payload.leftValue, "rightValues": payload.rightValues };
                    state.synonymItems[selectedLanguage][operator].unshift(item);
                }
            }
        }

        function filterTwoWay(synonym) {
            return synonym.values.criterion.value === 'SEARCH_SYNONYMS_TWO_WAY';
        }

        function filterOneWay(synonym) {
            return synonym.values.criterion.value === 'SEARCH_SYNONYMS_ONE_WAY';
        }
        function filterReplace(synonym) {
            return synonym.values.criterion.value === 'SEARCH_SYNONYMS_REPLACE';
        }
    },
    [synonymActions.default.UPDATE_SYNONYMS_ITEM]: (state, payload) => {
        const { synonymItem, language, operator } = payload;
        const tempState = JSON.parse(JSON.stringify(state));
        const index = state.synonymItems[language][
            operator].findIndex(item => item.id === synonymItem.id);

        tempState.synonymItems[language][operator][index] = synonymItem;
        return { ...tempState, changesId: Math.random() };
    },
    [synonymActions.default.REMOVE_SYNONYMS_ITEM]: (state, payload) => {
        if (state.synonymItems
            && state.synonymItems[payload.selectedLanguage]
            && state.synonymItems[payload.selectedLanguage][payload.operator]
            && state.synonymItems[payload.selectedLanguage][payload.operator].length > 0) {
            state.synonymItems[payload.selectedLanguage][payload.operator] = state.synonymItems[payload.selectedLanguage][payload.operator].filter((item) => item.id !== payload.id);
            if (state.synonymItems[payload.selectedLanguage][payload.operator].length === 0) {
                delete state.synonymItems[payload.selectedLanguage][payload.operator];
            }
            if (Object.keys(state.synonymItems[payload.selectedLanguage]).length === 0) {
                delete state.synonymItems[payload.selectedLanguage];
            }
        }
        state.id = Math.random();
        return Object.assign({}, state, { synonymItems: state.synonymItems, id: state.id, changesId: Math.random() });
    },
})



