/* eslint-disable react/jsx-indent */
import React, { useMemo } from 'react';

import './SFYCampaignList.scss';
import {
  AbActions,
  AbSplitCalendar,
} from '../../../experimentation/module-exports';
import { CountDownActions, ListCalendar } from '../../module-exports';
import CampaignDevices from '../../../../modules/widgets/campaign-devices';
import ListActions from '../../ListActions/ListActions';

/**
 * @name SFYCampaignList component
 * @description Campaign component displays list of items and its actions
 * @param {object} campaign campaign object
 * @param {string} campaignDescription description of the campaign
 * @param {object} campaignActions this is the campaign list actions
 * @param {function} campaignUpdate this is the campaign list update function
 * @param {object} ab this is the ab campaign object
 * @param {function} onPreview this is the preview function for ab campaign
 * @param {function} abChange this is the abList state update function
 * @param {boolean} isCountDownCampaign this is the flag to check if the campaign is countdown campaign
 * @param {object} route this is the route object
 * @returns {JSX.Element}
 */

const SFYCampaignList = ({
  ab,
  route,
  onPreview,
  abChange,
  campaign,
  campaignDescription,
  campaignActions,
  campaignUpdate,
  isCountDownCampaign,
}) => {
  const abListRender = useMemo(() => {
    return (
      <div className='sfy-campaignList' key={ab?.id}>
        <div className='sfy-campaignList-left'>
          <span className='sfy-campaignList-title'>{ab?.testName}</span>
          <div className='sfy-campaignList-calendar'>
            <AbSplitCalendar ab={ab} />
          </div>
        </div>
        <div className='sfy-campaignList-right'>
          <AbActions
            ab={ab}
            route={route}
            onPreview={onPreview}
            abChange={abChange}
          />
        </div>
      </div>
    );
  }, [ab]);
  const campaignListRender = useMemo(() => {
    let isProcessCompleted = true;
    if (campaign?.isProcessCompleted !== undefined) {
      isProcessCompleted = campaign?.isProcessCompleted;
    }
    return (
      <div className='sfy-campaignList' key={campaign?.instanceId}>
        <div className='sfy-campaignList-left'>
          <div
            className={`sfy-campaignList-title ${
              !isProcessCompleted ? 'sfy-campaignList-title__disabled' : ''
            }`}
          >
            {campaign?.name}{' '}
            {!isProcessCompleted && (
              <span style={{ fontStyle: 'italic', color: '#CDCDCD' }}>
                (Still Uploading)
              </span>
            )}
            {campaignDescription && <span>{`(${campaignDescription})`}</span>}
          </div>
        </div>
        <div className='sfy-campaignList-flexRight'>
          <div className='sfy-campaignList-calendar'>
            <ListCalendar
              scheduleDate={campaign?.startDate}
              calendarText='created'
              styleType='calendarTextRight'
              iconColor='#737373'
              iconSize='12'
            />
          </div>
          {campaign?.devices && campaign?.devices.length > 0 && (
            <>
              <div className='sfy-campaignList-divider' />
              <CampaignDevices devices={campaign?.devices} />
            </>
          )}
          {campaign?.customDetails && (
            <>
              <div className='sfy-campaignList-divider' />
              <div className='sfy-campaignList-customDetails'>
                {campaign?.customDetails}
              </div>
            </>
          )}
          {isCountDownCampaign ? (
            <CountDownActions
              countDown={campaign}
              countDownCampaignType={campaign.type}
              countDownUpdate={campaignUpdate}
            />
          ) : (
            <div className='sfy-campaignList-item-actions'>
              {campaignActions && (
                <ListActions actions={campaignActions} item={campaign} />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }, [campaign]);
  return <>{ab ? abListRender : campaign ? campaignListRender : null}</>;
};

export default SFYCampaignList;
