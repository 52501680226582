/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { browserHistory } from "react-router";
import { uiActions } from "../../../../actions";
import { confirm, t } from "../../../../system/ui";
import Icons from "../../../../components/icons";
import { TextField } from "../../../../components/fields";
import CriteriaDetail from "../../../../components/ruleBasedSegmentation/CriteriaDetail";
import { moveCampaignToLive, moveCampaignToTest } from "../../../../modules/ds/ajax";
import { getCampaign, getCampaigns, getSingleCampaigns } from "../../../../reducers/ds/extraReducers";
import { closeModal } from "../../../../actions/modal";

/*
   This component is used to render List of Segments sub Actions like Edit, Status Change etc.
*/

const SegmentDetailPreview = ({ campaignDetails }) => {
  const [campaignStatus, setCampaignStatus] = useState(
    campaignDetails.segments.status.toLowerCase(),
  );

  const dispatch = useDispatch();

  const dsFilterState = useSelector(state => state.ds.wizardFilter.data);
  const { isLoaded, showNotification } = uiActions;

  useEffect(() => {
    if (campaignDetails) {
      dispatch(getCampaign(campaignDetails.segments.instanceId));
    }
    return () => {
      dispatch(getCampaigns());
    };
  }, []);

  const showNotificationCreator = () => {
    isLoaded();
    setCampaignStatus(campaignDetails.segments.status);

    let note = () => {
      return <div>Segmentation is successfully updated in Live mode.</div>;
    };
    if (campaignDetails.segments.status === 'ACTIVE') {
      note = () => {
        return <div>Segmentation is successfully updated in Draft mode.</div>;
      };
    }
    showNotification({
      content: note,
    });
  };

  const moveSegmentToLive = () => {
    if (campaignDetails.segments.instanceId) {
      moveCampaignToLive(campaignDetails.segments.instanceId)
        .then(response => {
          showNotificationCreator(response.status);
        })
        .then(() => {
          dispatch(getCampaigns());
          dispatch(getSingleCampaigns());
          closeModal();
        })
        .catch(() => {
          uiActions.isLoaded();
        });
    }
  };

  const moveSegmentToTest = () => {
    if (campaignDetails.segments.instanceId) {
      moveCampaignToTest(campaignDetails.segments.instanceId)
        .then(response => {
          showNotificationCreator(response.status);
        })
        .then(() => {
          dispatch(getCampaigns());
          dispatch(getSingleCampaigns());
          closeModal();
        })
        .catch(() => {
          uiActions.isLoaded();
        });
    }
  };

  const toggleCampaignStatus = () => {
    const statusCurr =
      campaignStatus.toLowerCase() === 'test' ? 'Live' : 'Draft';
    confirm({
      title: `Update Campaign on ${statusCurr} Mode?`,
      onConfirm: () => {
        uiActions.isLoading();
        if (statusCurr === 'Live') {
          moveSegmentToLive();
        } else {
          moveSegmentToTest();
        }
      },
    });
  };

  const editTheSegment = () => {
    const path = `audience/dynamic-segmentation/edit/${campaignDetails.segments.instanceId}`;

    browserHistory.push({
      pathname: path,
    });
  };

  return (
    <div className='rbs-wizard-save-preview'>
      <div className='rbs-detail-preview-buttons'>
        <div className='segment-options'>
          <label className='item is-toggle'>
            <input
              type='checkbox'
              checked={campaignStatus === 'active'}
              onClick={() => toggleCampaignStatus()}
            />
            <span className='item-label' />
          </label>
          <span className='item-help'>
            Draft / <span className='live'>Live</span>
          </span>
        </div>
        <a
          className='button preview-edit-button'
          onClick={() => editTheSegment()}
        >
          <Icons name='editPencil' /> Edit
        </a>
      </div>
      <div className='save-preview-segmentName-field readonly'>
        <span className='save-preview-title'>{t('Segment Name')}</span>
        <TextField
          className='save-preview-segmentName'
          name='segmentName'
          value={
            campaignDetails.segments.name ||
            campaignDetails.segments.segmentName
          }
          readOnly
        />
      </div>
      <CriteriaDetail criteriaData={dsFilterState} />
    </div>
  );
};

SegmentDetailPreview.propTypes = {
  campaignDetails: PropTypes.shape({}).isRequired,
};
export default SegmentDetailPreview;
