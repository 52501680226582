import React, { useId } from 'react';
import classes from './SFYSwitch.module.scss';
import { t } from '../../../system/ui';

const SFYSwitch = ({ span, isToggled, onToggled, className }) => {
  const checkBoxId = useId();
  return (
    <div className={className}>
      <label className={classes.switch}>
        <input
          id={checkBoxId}
          type='checkbox'
          checked={isToggled}
          onChange={onToggled}
        />
        <span className={classes['switch-slider']} />
      </label>
      <span className={classes['switch-text']}>{t(span)}</span>
    </div>
  );
};

export default SFYSwitch;
