/**
 * Created by mehmetyurtar on 11/07/2017.
 */
import React from "react";
import { connect } from "react-redux";

import { wizardActions, uiActions } from "../../../actions";

import FormFieldInput from "./form-field-input";
import FormFieldTextArea from "./form-field-textarea";
import FormFieldCheckbox from "./form-field-checkbox";
import FormFieldRadio from "./form-field-radio";

const formFieldTypes = {
  input: FormFieldInput,
  textArea: FormFieldTextArea,
  checkbox: FormFieldCheckbox,
  radio: FormFieldRadio
};

class FormFieldsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleFormFieldRemove = this.handleFormFieldRemove.bind(this);
    this.handleFormFieldChange = this.handleFormFieldChange.bind(this);
    this.handleCheckboxRadioAdd = this.handleCheckboxRadioAdd.bind(this);
    this.handleCheckboxRadioRemove = this.handleCheckboxRadioRemove.bind(this);
    this.handleCheckboxRadioChange = this.handleCheckboxRadioChange.bind(this);
  }

  handleFormFieldRemove(fieldId) {
    let modifiedField;

    modifiedField = this.props.formFields.fields.filter(field => {
      return field.id !== fieldId;
    });

    wizardActions.removeFormField(Math.random(), modifiedField);
    uiActions.formEdited();
  }

  handleFormFieldChange(fields, fieldId) {
    let formFields = this.props.formFields.fields,
      inputs =
        typeof fields !== "undefined"
          ? fields.querySelectorAll(".criteria-field")
          : "",
      values = {};

    if (inputs.length > 0) {
      inputs.forEach(item => {
        if (item.name === "required") {
          values[item.name] = {
            value: item.checked
          };
        } else {
          values[item.name] = {
            value: item.value
          };
        }
      });

      for (let i = 0; i < formFields.length; i++) {
        if (formFields[i].id === fieldId) {
          formFields[i].values = Object.assign({}, values);
          break;
        }
      }

      wizardActions.addFormField(Math.random(), formFields);
      uiActions.formEdited();
    }
  }

  handleCheckboxRadioAdd(fieldId) {
    let currentFormFields = this.props.formFields.fields;
    let currentElement = currentFormFields.filter(field => {
      return field.id === fieldId;
    });
    currentElement[0].options = currentElement[0].options.concat({
      id: Math.random(),
      name: ""
    });
    wizardActions.addFormField(Math.random(), currentFormFields);
  }

  handleCheckboxRadioRemove(fieldId, optionId) {
    let currentFormFields = this.props.formFields.fields;
    let currentElement = currentFormFields.filter(field => {
      return field.id === fieldId;
    });
    let currentOptions = currentElement[0].options.filter(option => {
      return option.id !== optionId;
    });
    currentElement[0].options = currentOptions;
    wizardActions.removeFormField(Math.random(), currentFormFields);
  }

  handleCheckboxRadioChange(options, fieldId, optionId) {
    let inputs =
      typeof options !== "undefined"
        ? options.querySelectorAll(".option-field")
        : "";
    let currentFormFields = this.props.formFields.fields;
    let currentElement = currentFormFields.filter(field => {
      return field.id === fieldId;
    });
    if (inputs.length > 0) {
      inputs.forEach((item, index) => {
        currentElement[0].options[index].name = item.value;
      });
      wizardActions.addFormField(Math.random(), currentFormFields);
      uiActions.formEdited();
    }
  }

  render() {
    return (
      <ol className="criteria-list">
        {this.props.formFields.fields.map(item => {
          let Component = formFieldTypes[item.type];
          return (
            <Component
              key={item.id}
              {...item}
              onFormFieldRemove={this.handleFormFieldRemove}
              onFormFieldChange={this.handleFormFieldChange}
              onCheckboxRadioAdd={this.handleCheckboxRadioAdd}
              onCheckboxRadioRemove={this.handleCheckboxRadioRemove}
              onCheckboxRadioChange={this.handleCheckboxRadioChange}
            />
          );
        })}
      </ol>
    );
  }
}

const mapStatesToProps = store => ({
  formFields: store.wizard.formFields
});

export default connect(mapStatesToProps)(FormFieldsContainer);
