import React from "react";

class IconPersonalizedPush extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 98.07 78.08"
        width={this.state.width}
        height={this.state.height}
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              d="M20,44.15a21.56,21.56,0,0,1-13-6.08A21.91,21.91,0,0,1,5.87,7.15l.27-.27a22.27,22.27,0,0,1,31.38-.81A23,23,0,0,1,44.2,19.64a2.18,2.18,0,0,1-1.9,2.44H42a2.18,2.18,0,0,1-2.17-1.91A17,17,0,0,0,34.53,9.31,17.93,17.93,0,0,0,5.71,29.58,18.06,18.06,0,0,0,20.41,40a2.08,2.08,0,0,1-.24,4.15Z"
              fill={this.state.color}
            />
            <path
              d="M63.61,78.07h-.43l-28.8-7.75a9.49,9.49,0,0,1-6.5-5.46,10.27,10.27,0,0,1,.42-8.6A10.08,10.08,0,0,1,37,51.19a10.64,10.64,0,0,1,1.59.12L16.4,30.19a10.06,10.06,0,0,1,1.24-15.3,10.06,10.06,0,0,1,12.6,1.24l13,12.44a9.79,9.79,0,0,1,5.07-4.3,10.06,10.06,0,0,1,7.59.15A7,7,0,0,1,57.81,22a9.65,9.65,0,0,1,10.67-2.41A10.29,10.29,0,0,1,73,17a12.1,12.1,0,0,1,2.29-.23,11.62,11.62,0,0,1,7.93,3.13L89,25.43l8.44,8.07a2.21,2.21,0,0,1,.07,3.12,2.19,2.19,0,0,1-1.59.68h0a2.21,2.21,0,0,1-1.53-.61l-2.6-2.49h0l-.4-.32-4.86-4.71L85,27.75l-.13-.15-4.64-4.5a6.62,6.62,0,0,0-4.74-2,6.85,6.85,0,0,0-1.42.15,4.73,4.73,0,0,0-2,.94l8.14,8.14A2.25,2.25,0,1,1,77,33.54L68,24.45A1.8,1.8,0,0,1,67.6,24a6,6,0,0,0-2.81-.71,5.92,5.92,0,0,0-4,1.59,4.26,4.26,0,0,0-1.15,1.82l8.62,8.63a2.25,2.25,0,1,1-3.18,3.18l-8.72-8.73a1.8,1.8,0,0,1-.54-.38,6.1,6.1,0,0,0-5.89-1.23,5.51,5.51,0,0,0-3.32,3.55l6.91,6.91a2.25,2.25,0,0,1,0,3.18,2.25,2.25,0,0,1-3.18,0l-7.56-7.56-.11-.07L27.42,19.35a5.89,5.89,0,0,0-7.33-.71,4.92,4.92,0,0,0-2.29,4,4.94,4.94,0,0,0,1.58,4.46L47.37,54a2.16,2.16,0,0,1,.53,2.44l0,0,0,0a2.36,2.36,0,0,1-2,1.09,2.43,2.43,0,0,1-.38,0l-6.56-1.79a6.05,6.05,0,0,0-1.81-.29,5.71,5.71,0,0,0-5,3,5.51,5.51,0,0,0,2.38,7.4,5.18,5.18,0,0,0,1,.4l28.71,7.72a2.17,2.17,0,0,1,1.63,2.58l0,.15a2.2,2.2,0,0,1-2,1.41Z"
              fill={this.state.color}
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default IconPersonalizedPush;
