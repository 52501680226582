import React from "react";

class IconMonitorExpand extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: "24px",
            height: "24px",
            color: "#eee"
        };

        this.setStyles = this.setStyles.bind(this);
    }

    componentDidMount() {
        this.setStyles();
    }

    setStyles() {
        let width = this.props.width ? this.props.width + "px" : this.state.width,
            height = this.props.height ? this.props.height + "px" : this.state.height,
            color = this.props.color ? this.props.color : this.state.color;

        this.setState({width, height, color});
    }

    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 400 400"
                width={this.state.width}
                height={this.state.height}
                fill={this.state.color}
            >
                <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" stroke="none">
                    <path d="M40 2520 c-19 -19 -20 -33 -20 -340 0 -307 1 -321 20 -340 11 -11 29
                    -20 40 -20 11 0 29 9 40 20 19 19 20 33 20 257 l0 238 358 -358 c375 -375 385
                    -383 426 -341 42 41 34 51 -341 426 l-358 358 238 0 c224 0 238 1 257 20 11
                    11 20 29 20 40 0 11 -9 29 -20 40 -19 19 -33 20 -340 20 -307 0 -321 -1 -340
                    -20z"/>
                    <path d="M1840 2520 c-11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 19 -19 33
                    -20 257 -20 l238 0 -358 -358 c-375 -375 -383 -385 -341 -426 41 -42 51 -34
                    426 341 l358 358 0 -238 c0 -224 1 -238 20 -257 11 -11 29 -20 40 -20 11 0 29
                    9 40 20 19 19 20 33 20 340 0 307 -1 321 -20 340 -19 19 -33 20 -340 20 -307
                    0 -321 -1 -340 -20z"/>
                    <path d="M497 583 l-357 -358 0 238 c0 224 -1 238 -20 257 -11 11 -29 20 -40
                    20 -11 0 -29 -9 -40 -20 -19 -19 -20 -33 -20 -340 0 -307 1 -321 20 -340 19
                    -19 33 -20 340 -20 307 0 321 1 340 20 25 25 25 55 0 80 -19 19 -33 20 -257
                    20 l-238 0 358 358 c375 375 383 385 341 426 -41 42 -51 34 -427 -341z"/>
                    <path d="M1636 924 c-42 -41 -34 -51 341 -426 l358 -358 -238 0 c-224 0 -238
                    -1 -257 -20 -11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 19 -19 33 -20 340
                    -20 307 0 321 1 340 20 19 19 20 33 20 340 0 307 -1 321 -20 340 -11 11 -29
                    20 -40 20 -11 0 -29 -9 -40 -20 -19 -19 -20 -33 -20 -257 l0 -238 -358 358
                    c-375 375 -385 383 -426 341z"/>
                </g>
            </svg>
        );
    }
}

export default IconMonitorExpand;
