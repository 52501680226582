/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/sort-comp */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { uiActions } from '../../../actions';

import { TextField } from '../../../components/fields';
import { t } from '../../../system/ui';

class FullNamePlaceHolder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      name: 'fullNamePlaceHolder',
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    const fullNamePlaceHolder = this.props.campaign.fullNamePlaceHolder || '';
    this.updateValue(fullNamePlaceHolder);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.fullNamePlaceHolder);
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  onFieldChange(e) {
    this.updateValue(e.target.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <li className='item-title-field'>
        <TextField
          name='fullNamePlaceHolder'
          label={t(this.props.options.label)}
          className='item-stacked one-whole'
          value={this.state.value}
          required={
            this.props.forceOptional ? false : this.props.options.required
          }
          onChange={this.onFieldChange}
          tooltip={this.props.options.tooltip}
          tooltipText={this.props.options.tooltipContent}
        />
      </li>
    );
  }
}

FullNamePlaceHolder.defaultProps = {
  options: {
    label: 'Full Name Placeholder',
    required: true,
    tooltip: false,
    tooltipContent: '',
  },
};

const MapStatesToProps = () => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  FullNamePlaceHolder,
);
