/**
 * Created by mehmetyurtar on 1/6/18.
 */
import React from "react";

class IconSocial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 438.72 438.72"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <path
          stroke="none"
          fill={this.state.color}
          d="m 349.52075,14.517242 c 41.42136,0 75,33.578644 75,75 0,41.421358 -33.57864,74.999998 -75,74.999998 -17.19529,0 -33.03652,-5.79896 -45.6875,-15.53125 l -139.3125,70.375 c 1.1e-4,0.0526 0,0.10366 0,0.15625 0,0.96032 -0.0267,1.92343 -0.0625,2.875 l 137.25,69.34375 c 12.97915,-10.7496 29.64323,-17.21875 47.8125,-17.21875 41.42136,0 75,33.57864 75,75 0,41.42136 -33.57864,75 -75,75 -41.42136,0 -75,-33.57864 -75,-75 0,-1.42183 0.0469,-2.81645 0.125,-4.21875 l -136.28125,-68.875 c -13.12307,11.27321 -30.18654,18.09375 -48.843748,18.09375 -41.42136,0 -75,-33.57864 -75,-75 0,-41.42136 33.57864,-75 75,-75 19.600118,0 37.451198,7.53346 50.812498,19.84375 l 134.5,-67.937498 c -0.20826,-2.27762 -0.3125,-4.57433 -0.3125,-6.90625 0,-41.421356 33.57864,-75 75,-75 z"
        />
      </svg>
    );
  }
}

export default IconSocial;
