import React from 'react';
import PropTypes from 'prop-types';
import { getSingleReport } from 'actions/faceted-search';
import { connect } from 'react-redux';
import moment from 'moment';
import { SearchReportRow } from '../../../../views/searchreportrow';

// This component using for view-all page

class ReportStatsViewAll extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    const date = this.getStartAndEndDate();
    dispatch(
      getSingleReport(
        [this.props.instanceId],
        date.start,
        date.end,
        response => null,
      ),
    );
  }

  getStartAndEndDate() {
    const startDate = moment()
      .subtract(6, 'd')
      .startOf('day');
    const endDate = moment().endOf('day');
    const range = [startDate, endDate];
    return {
      start: moment(range[0]).format('YYYY-MM-DD'),
      end: moment(range[1]).format('YYYY-MM-DD'),
    };
  }

  render() {
    const { data, loaded, instanceId } = this.props;
    return (
      loaded && (
        <SearchReportRow
          type='faceted'
          data={data}
          loaded={loaded}
          instanceId={instanceId}
          viewAll
        />
      )
    );
  }
}

ReportStatsViewAll.propTypes = {
  instanceId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  date: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  loading: state.facetedSearch.singleReport.loading,
  loaded: state.facetedSearch.singleReport.loaded,
  error: state.facetedSearch.singleReport.error,
  data: state.facetedSearch.singleReport.data,
  date: state.date,
});

export default connect(mapStateToProps)(ReportStatsViewAll);
