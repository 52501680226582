/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable no-use-before-define */
export const loadSalesIqWidget = () => {
  const widgetCode =
    'a96aa303e10ca7777aa09cef7034bafffbb61c327605c05c30fee94e125f0dac57406d738438e6fdb00ceb50644d767c';
  const url = 'https://salesiq.zoho.com/widget';

  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');

  const code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

  script.appendChild(document.createTextNode(code));
  document.body.appendChild(script);
};

export const removeSalesIqWidget = () => {
  const d = document;
  const s = d.getElementById('zsiqscript');
  s.parentNode.removeChild(s);
};
