import useExtendedContext from '../../../hooks/module-context/useExtendedContext';
import {
  paginationSetter,
  searchQuerySetter,
  campaignPathnameSetter,
} from './actions/paginationActions';
import { paginationReducer } from './reducers/paginationReducer';

const paginationState = {
  searchQueryString: '',
  pagination: {
    totalRecords: 0,
    pageNumber: 1,
    payloadSize: 20,
    isInfinite: true,
  },
  campaignPathname: '',
};

export const { Context, Provider } = useExtendedContext(
  paginationReducer,
  { paginationSetter, searchQuerySetter, campaignPathnameSetter },
  paginationState,
);
