/**
 * Created by mehmetyurtar on 12/6/17.
 */
import React from "react";

class RssFeed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
      >
        <path
          fill={this.state.color}
          d="M16,14.8c0.1,0.8-0.3,1.3-1.2,1.2c-0.2,0-0.5,0-0.7-0.1c-0.4-0.2-0.5-0.5-0.6-0.9c-0.1-2-0.7-3.8-1.7-5.6
                c-2.1-3.7-5.3-6-9.5-6.8C1.9,2.5,1.5,2.5,1,2.5C0.4,2.4,0,2,0,1.4C0,1.2,0,1,0,0.8C0.1,0.3,0.5-0.1,1.1,0c4.2,0.3,7.7,2,10.6,5
                c2.4,2.5,3.8,5.5,4.3,9c0,0.1,0,0.3,0,0.4C16,14.5,16,14.6,16,14.8z"
        />
        <path
          fill={this.state.color}
          d="M9.8,16c-0.8,0-1.1-0.3-1.2-1.1C8.1,11,4.9,7.9,1,7.4C0.4,7.3,0,7,0,6.4C0,6.2,0,6,0,5.8c0.1-0.5,0.6-0.9,1.1-0.8
                c4.4,0.6,7.5,3,9.2,7.1c0.4,0.9,0.6,1.8,0.7,2.8c0.1,0.7-0.3,1.2-1,1.2C9.9,16,9.9,16,9.8,16z"
        />
        <path
          fill={this.state.color}
          d="M3,16c-0.6,0-1.3,0-1.9,0C0.4,16,0,15.6,0,14.9c0-1.3,0-2.6,0-3.8c0-0.8,0.5-1.3,1.4-1.1c2.5,0.7,4.1,2.3,4.7,4.8
                C6.2,15.4,5.7,16,5,16c-0.6,0-1.1,0-1.7,0C3.2,16,3.1,16,3,16z"
        />
      </svg>
    );
  }
}

export default RssFeed;
