import React from 'react';
import { isEmpty } from 'lodash';
import classes from './LastProductRecommendations.module.scss';
import { t } from '../../../../../system/ui';

const LastProductRecommendations = ({
  lastProductRecommends,
  enumLastProductRecommendations,
  enumCategoryVisit,
  categoryVisit,
  isCategoryVisit,
}) => {
  return (
    <div className={classes['lpr-container']}>
      <div className={classes['lpr-header']}>
        {!isCategoryVisit
          ? t('Last Product Recommendations')
          : t('Category Visit')}
      </div>
      <div
        className={
          classes[`${isCategoryVisit ? 'lpr-content--col' : 'lpr-content'}`]
        }
      >
        {!isCategoryVisit
          ? Object.keys(lastProductRecommends).map(key => {
              const subObj = lastProductRecommends[key];
              return (
                <div key={key} className={classes['lpr-item-container']}>
                  <div className={classes['lpr-item-header']}>
                    {enumLastProductRecommendations[key]}
                  </div>
                  <div className={classes['lpr-item-desc']}>
                    {!isEmpty(subObj)
                      ? Object.keys(subObj).map(key => {
                          return <>{`${key} - ${subObj[key]}`}</>;
                        })
                      : '-'}
                  </div>
                </div>
              );
            })
          : Object.keys(categoryVisit).map(key => {
              return (
                <div key={key}>
                  <div className={classes['lpr-item-header']}>
                    {enumCategoryVisit[key]}
                  </div>
                  <div className={classes['lpr-item-desc']}>
                    <>{!categoryVisit[key].length ? '-' : categoryVisit[key]}</>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default LastProductRecommendations;
