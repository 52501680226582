import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isSwitchAccount } from "../modules/auth/user";
import { getNow } from "../system/date";

import { t } from "../system/ui";
import { setTitle } from "../system/document";

import { uiActions, wizardActions } from "../actions";
import {
  getEmailTemplates
} from "../modules/emails/ajax";

class AddDefaultEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: {
        type: "FLY",
        params: {
          eventName: "",
          mailTo: "",
          klaviyoEventName: ""
        },
        startDate: getNow()
      },
      scenarioName: "",
      campaignStatus: "",
      subject: "",
      mailContent: "",
      campaignCustoms: {
        eventName: "",
        mailTo: ""
      },
      startDate: "",
      endDate: null,
      contentSamples: {},
      mailTemplates: [],
      template: ""
    };
  }

  componentDidMount() {
    setTitle(t("Add Email Campaign"));

    wizardActions.saveReset();
    wizardActions.wizardReset();
    wizardActions.wizardEdit();

    uiActions.isValidated();
    uiActions.removePageClass();

    this.campaignDetails(
      isSwitchAccount() ? this.props.switchedUser : this.props.user
    );
  }

  componentWillReceiveProps(newProps) {}

  componentDidUpdate(oldProps) {}

  campaignDetails(user) {
    if (user.account) {
      uiActions.isLoading();
      getEmailTemplates(templates => {
        uiActions.isLoaded();
        if (templates) {
          this.setState(
            {
              mailTemplates: templates
            },
            () => {
              uiActions.showBreadcrumb({
                home: {
                  name: t("Email"),
                  icon: "emailModule",
                  slug: "/email/view-all",
                  iconSvg: true
                },
                current: {
                  name: "Add Fly Mode Campaign"
                }
              });
            }
          );
        }
      });
    }
  }

  render() {
    return <div>Add {this.props.params.emailtype} Campaign</div>;
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate,
  breadcrumb: store.ui.breadcrumb,
  formSaved: store.ui.formSaved,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes,
  mappings: store.mailWizard.mappings,
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
  ui: store.ui.ui
});

AddDefaultEmail.contextTypes = {
  router: PropTypes.object.isRequired
};

AddDefaultEmail.propTypes = {
  validate: PropTypes.bool
};

export default connect(mapStatesToProps)(AddDefaultEmail);
