import React from 'react';

import { units, icons } from '../constants/ui';
import * as modals from '../constants/datamaps/email-modals';

import { t, openModal } from '../system/ui';
import { toSlug, localeString, toLocaleStringDigits } from '../system/string';

import Icon from './icon';
import Currency from './currency';
import Tooltip from './tooltip';

import { hasKlaviyoConfiguration } from '../modules/auth/user';

class EmailCard extends React.Component {
  constructor(props) {
    super(props);

    this.showModal = this.showModal.bind(this);
  }

  showModal(modal, e) {
    e.preventDefault();
    const modalContent = modals[modal];
    let emailTypeName = false;

    if (modal === 'Widget') {
      emailTypeName = 'Email';
    } else if (modal === 'Revenue') {
      emailTypeName = 'Email Conversion';
    } else {
      emailTypeName = modal;
    }

    openModal({
      title: emailTypeName,
      content: modalContent,
    });
  }

  render() {
    const className = `stat-card-email stat-card stat-${this.props.type}`;
    const mainTitle = this.props.title;
    let emailTypeName = false;
    if (mainTitle === 'Widget') {
      emailTypeName = 'Email';
    } else if (mainTitle === 'Revenue') {
      emailTypeName = 'Email Conversion';
    } else {
      emailTypeName = mainTitle;
    }
    return (
      <div className={className}>
        <h3 className='stat-card-title'>{t(emailTypeName)}</h3>

        {this.props.data.modal &&
        typeof modals[this.props.data.modal] !== 'undefined' ? (
          <a
            className='modal-trigger'
            onClick={e => {
              this.showModal(this.props.data.modal, e);
            }}
          >
            <Icon name='info' />
          </a>
        ) : (
          ''
        )}

        <ol className='stat-card-list'>
          {Object.keys(this.props.data.stats).map((item, index) => {
            const singleStat = this.props.data.stats[item];
            let row;
            const _key = `${item}-${index}`;
            if (singleStat.name === 'Spam') return null;

            if (!singleStat) return null;

            if (mainTitle !== 'Widget') {
              if (singleStat.hasOwnProperty('total')) {
                row = (
                  <li key={_key} className='stat-total'>
                    <span
                      className='stat-item-count'
                      title={toLocaleStringDigits(singleStat.count)}
                    >
                      {singleStat.hasOwnProperty('currency') ? (
                        <Currency currency={singleStat.count} />
                      ) : (
                        localeString(singleStat.count)
                      )}
                    </span>
                    <span className='stat-item-unit'>
                      {this.props.type === 'unique'
                        ? t(singleStat.name)
                        : t(units[this.props.type])}
                    </span>
                  </li>
                );
              } else if (!hasKlaviyoConfiguration()) {
                row = (
                  <li
                    key={_key}
                    className={`${toSlug(
                      singleStat.name,
                    )} stat-item stat-item-${toSlug(mainTitle)}`}
                  >
                    <span className='stat-item-name'>
                      {typeof icons[this.props.type] !== 'undefined' &&
                      typeof icons[this.props.type][item] !== 'undefined' ? (
                        <Icon name={icons[this.props.type][item]} />
                      ) : (
                        ''
                      )}
                      {mainTitle === 'Widget' ||
                      mainTitle === 'Interaction' ||
                      mainTitle === 'Basket'
                        ? t(emailTypeName + singleStat.name)
                        : t(singleStat.name)}
                      {singleStat.tooltip && (
                        <span className='stat-tooltip'>
                          <Tooltip
                            content={singleStat.tooltip}
                            alignment='top'
                            newTooltip
                          >
                            <Icon name='info' />
                          </Tooltip>
                        </span>
                      )}
                    </span>
                    {singleStat.hasOwnProperty('count') === true ? (
                      <span
                        className='stat-item-count'
                        title={toLocaleStringDigits(singleStat.count)}
                      >
                        {singleStat.hasOwnProperty('currency') ? (
                          <Currency currency={singleStat.count} />
                        ) : (
                          localeString(singleStat.count)
                        )}
                        {singleStat.name === 'Purchases' &&
                        mainTitle === 'Revenue' ? (
                          <span> ({localeString(singleStat.ratio)}%)</span>
                        ) : (
                          ''
                        )}
                        {singleStat.name === 'Purchase Items' &&
                        mainTitle === 'Revenue' ? (
                          <span> ({localeString(singleStat.ratio)}%)</span>
                        ) : (
                          ''
                        )}
                      </span>
                    ) : (
                      ''
                    )}
                    <span className='stat-item-unit'>
                      {t(units[this.props.type])}
                    </span>
                    {singleStat.hasOwnProperty('ratio') === true &&
                    singleStat.name !== 'Purchases' &&
                    singleStat.name !== 'Purchase Items' ? (
                      <span className='stat-item-percentage'>
                        {localeString(singleStat.ratio)}%
                      </span>
                    ) : (
                      ''
                    )}
                  </li>
                );
              } else if (mainTitle !== 'Interaction') {
                row = (
                  <li
                    key={_key}
                    className={`${toSlug(
                      singleStat.name,
                    )} stat-item stat-item-${toSlug(mainTitle)}`}
                  >
                    <span className='stat-item-name'>
                      {typeof icons[this.props.type] !== 'undefined' &&
                      typeof icons[this.props.type][item] !== 'undefined' ? (
                        <Icon name={icons[this.props.type][item]} />
                      ) : (
                        ''
                      )}
                      {mainTitle === 'Widget' || mainTitle === 'Basket'
                        ? t(emailTypeName + singleStat.name)
                        : t(singleStat.name)}
                      {singleStat.tooltip && (
                        <span className='stat-tooltip'>
                          <Tooltip
                            content={singleStat.tooltip}
                            alignment='top'
                            newTooltip
                          >
                            <Icon name='info' />
                          </Tooltip>
                        </span>
                      )}
                    </span>
                    {singleStat.hasOwnProperty('count') === true ? (
                      <span
                        className='stat-item-count'
                        title={toLocaleStringDigits(singleStat.count)}
                      >
                        {singleStat.hasOwnProperty('currency') ? (
                          <Currency currency={singleStat.count} />
                        ) : (
                          localeString(singleStat.count)
                        )}
                        {singleStat.name === 'Purchases' &&
                        mainTitle === 'Revenue' ? (
                          <span> ({localeString(singleStat.ratio)}%)</span>
                        ) : (
                          ''
                        )}
                        {singleStat.name === 'Purchase Items' &&
                        mainTitle === 'Revenue' ? (
                          <span> ({localeString(singleStat.ratio)}%)</span>
                        ) : (
                          ''
                        )}
                      </span>
                    ) : (
                      <span className='stat-item-unit'>
                        {t(units[this.props.type])}
                      </span>
                    )}
                    {singleStat.hasOwnProperty('ratio') === true &&
                    singleStat.name !== 'Purchases' &&
                    singleStat.name !== 'Purchase Items' ? (
                      <span className='stat-item-percentage'>
                        {localeString(singleStat.ratio)}%
                      </span>
                    ) : (
                      ''
                    )}
                  </li>
                );
              }
            } else if (!hasKlaviyoConfiguration()) {
              if (singleStat.name === 'Actual View') {
                row = (
                  <li key={_key} className='stat-total'>
                    <span
                      className='stat-item-count'
                      title={toLocaleStringDigits(singleStat.count)}
                    >
                      {singleStat.hasOwnProperty('currency') ? (
                        <Currency currency={singleStat.count} />
                      ) : (
                        localeString(singleStat.count)
                      )}
                    </span>
                    <span className='stat-item-unit'>
                      {this.props.type === 'unique'
                        ? t(singleStat.name)
                        : t(units[this.props.type]) === 'views'
                        ? 'opens'
                        : t(units[this.props.type])}
                    </span>
                  </li>
                );
              } else {
                row = (
                  <li key={_key} className='stat-item'>
                    <span className='stat-item-name'>
                      {typeof icons[this.props.type] !== 'undefined' &&
                      typeof icons[this.props.type][item] !== 'undefined' ? (
                        <Icon name={icons[this.props.type][item]} />
                      ) : (
                        ''
                      )}
                      {mainTitle === 'Widget' ||
                      mainTitle === 'Interaction' ||
                      mainTitle === 'Basket'
                        ? t(emailTypeName + singleStat.name)
                        : t(singleStat.name)}
                      {singleStat.tooltip && (
                        <span className='stat-tooltip'>
                          <Tooltip
                            content={singleStat.tooltip}
                            alignment='top'
                            newTooltip
                          >
                            <Icon name='info' />
                          </Tooltip>
                        </span>
                      )}
                    </span>
                    {singleStat.hasOwnProperty('count') === true ? (
                      <span
                        className='stat-item-count'
                        title={toLocaleStringDigits(singleStat.count)}
                      >
                        {singleStat.hasOwnProperty('currency') ? (
                          <Currency currency={singleStat.count} />
                        ) : (
                          localeString(singleStat.count)
                        )}
                      </span>
                    ) : (
                      ''
                    )}
                    <span className='stat-item-unit'>
                      {t(units[this.props.type])}
                    </span>
                    {singleStat.hasOwnProperty('ratio') === true ? (
                      <span className='stat-item-percentage'>
                        {localeString(singleStat.ratio)}%
                      </span>
                    ) : (
                      ''
                    )}
                  </li>
                );
              }
            } else if (singleStat.name !== 'Actual View') {
              if (mainTitle !== 'Widget') {
                row = (
                  <li key={_key} className='stat-item'>
                    <span className='stat-item-name'>
                      {typeof icons[this.props.type] !== 'undefined' &&
                      typeof icons[this.props.type][item] !== 'undefined' ? (
                        <Icon name={icons[this.props.type][item]} />
                      ) : (
                        ''
                      )}
                      {mainTitle === 'Interaction' || mainTitle === 'Basket'
                        ? t(emailTypeName + singleStat.name)
                        : t(singleStat.name)}
                      {singleStat.tooltip && (
                        <span className='stat-tooltip'>
                          <Tooltip
                            content={singleStat.tooltip}
                            alignment='top'
                            newTooltip
                          >
                            <Icon name='info' />
                          </Tooltip>
                        </span>
                      )}
                    </span>
                    {singleStat.hasOwnProperty('count') === true ? (
                      <span
                        className='stat-item-count'
                        title={toLocaleStringDigits(singleStat.count)}
                      >
                        {singleStat.hasOwnProperty('currency') ? (
                          <Currency currency={singleStat.count} />
                        ) : (
                          localeString(singleStat.count)
                        )}
                      </span>
                    ) : (
                      ''
                    )}
                    <span className='stat-item-unit'>
                      {t(units[this.props.type])}
                    </span>
                    {singleStat.hasOwnProperty('ratio') === true ? (
                      <span className='stat-item-percentage'>
                        {localeString(singleStat.ratio)}%
                      </span>
                    ) : (
                      ''
                    )}
                  </li>
                );
              } else if (item === 'klaviyo') {
                row = (
                  <li
                    key={_key}
                    className='stat-item'
                    style={{ position: 'relative', marginTop: '40px' }}
                  >
                    <span className='total-amount stat-item-name'>
                      {t('Triggered To Flow')}
                    </span>
                    <span className='stat-item-count'>
                      {localeString(singleStat.flow)}
                    </span>
                    <span className='total-amount stat-item-name'>
                      {t('Triggered To Profile')}
                    </span>
                    <span className='stat-item-count'>
                      {localeString(singleStat.profile)}
                    </span>
                  </li>
                );
              }
            }
            return row;
          })}
          {this.props.type === 'purchase' && (
            <li className='stat-item'>
              <span className='stat-item-name'>{t('Success Page View')}</span>
              <span className='stat-item-count'>
                {this.props.pageStats &&
                  this.props.pageStats.page.stats.other.checkoutCount}
              </span>
            </li>
          )}
        </ol>
      </div>
    );
  }
}

export default EmailCard;
