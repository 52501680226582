import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const UserIcon = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 22 27'
      fill='none'
    >
      <path d='M18.9077 16.1966C20.6155 16.1966 22 17.556 22 19.2331V20.0101C22 21.2176 21.5605 22.3853 20.761 23.3026C18.603 25.7783 15.3205 27 10.9954 27C6.66977 27 3.38891 25.7779 1.23517 23.3011C0.437977 22.3845 0 21.2185 0 20.013V19.2331C0 17.556 1.38447 16.1966 3.0923 16.1966H18.9077ZM18.9077 18.2219H3.0923C2.5236 18.2219 2.06256 18.6747 2.06256 19.2331V20.013C2.06256 20.7364 2.32534 21.4359 2.80366 21.986C4.52701 23.9676 7.23018 24.9746 10.9954 24.9746C14.7607 24.9746 17.4662 23.9676 19.194 21.9856C19.6737 21.4352 19.9374 20.7345 19.9374 20.0101V19.2331C19.9374 18.6747 19.4764 18.2219 18.9077 18.2219ZM10.9954 0C14.7925 0 17.8706 3.02263 17.8706 6.75121C17.8706 10.4798 14.7925 13.5024 10.9954 13.5024C7.19839 13.5024 4.12025 10.4798 4.12025 6.75121C4.12025 3.02263 7.19839 0 10.9954 0ZM10.9954 2.02536C8.33747 2.02536 6.18281 4.14121 6.18281 6.75121C6.18281 9.36123 8.33747 11.4771 10.9954 11.4771C13.6534 11.4771 15.8081 9.36123 15.8081 6.75121C15.8081 4.14121 13.6534 2.02536 10.9954 2.02536Z' />
    </svg>
  );
};

UserIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

UserIcon.defaultProps = {
  width: '22px',
  height: '27px',
};

export default UserIcon;
