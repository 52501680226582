import React, { useContext, useEffect } from "react";
import { browserHistory } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import { Context as ProductBundleContext } from '../store/productBundleProvider';
import { t } from '../../../../system/ui';
import Tooltip from '../../../../components/tooltip';
import { SFYCheckBox } from '../../../../components/sfy-components/module-exports';

/**
 * CategoryList component of Automated Bundles
 * @param linkToDetail
 * @param selectedItems
 * @param selectedItemActions
 * @return {*}
 * @constructor
 */
const CategoryList = ({ linkToDetail, selectedItems, selectedItemActions }) => {
  const {
    state: { categoryBundleList },
  } = useContext(ProductBundleContext);

  useEffect(() => {
    selectedItemActions.clearAllItems();
  }, []);

  return (
    categoryBundleList.length > 0 &&
    categoryBundleList.map(({ id, mainItem, subItems }) => {
      return (
        <CSSTransition timeout={900} classNames='item' key={id}>
          <div className='bundleCategory' key={id}>
            <div
              className='bundleCategory-wrapper'
              onClick={() => browserHistory.push(`${linkToDetail}/${id}`)}
            >
              <div className='bundleCategory-item-0'>
                {!mainItem?.exist && (
                  <Tooltip
                    content={t(
                      'You can check the product. It is out of stock or not in the catalog anymore.',
                    )}
                    alignment='left'
                  >
                    <i className='icon-info' role='presentation'>
                      <span className='for-screenreader-only' />
                    </i>
                  </Tooltip>
                )}
                <div className='bundleCategory-item-0-text'>
                  {mainItem?.key}
                </div>
              </div>
              <div className='bundleCategory-item-1'>
                {`${subItems?.length} products`}
              </div>
            </div>
            <div className='bundleCategory-item-2'>
              <SFYCheckBox
                id={id}
                name='bundle'
                label=''
                checked={selectedItems.includes(id)}
                onChange={() => selectedItemActions.toggleItem(id)}
              />
            </div>
          </div>
        </CSSTransition>
      );
    })
  );
};

export default CategoryList;
