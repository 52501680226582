import * as Yup from 'yup';

const urlValidationRegex = new RegExp(
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
);

const industries = [
  'Accessories',
  'Alcoholic Beverages / Spirits',
  'Auto Parts',
  'Baby & Kids',
  'Betting',
  'Books / Music / Art',
  'Classified',
  'Content',
  'Cosmetics & Pharmacy',
  'Costumes',
  'Digital Agency',
  'eCommerce Platforms',
  'Electronics',
  'Eyewear',
  'Fashion',
  'Flower',
  'Food',
  'Hobby & DIY',
  'Home Depot & Furniture & Appliances',
  'Jewellery',
  'Lingerie',
  'Luxury Goods',
  'Marketplace',
  'Pet Store',
  'Shoes',
  'Sports Equipment',
  'Sports Fashion',
  'Supermarket',
  'Tourism',
];

const uniqueSessions = ['0-500K', '500K-1M', '1M-5M', '5M+'];

const pageViews = ['0-5M', '5M-10M', '10M-20M', '20M+'];

const skuList = ['0-20K', '20K-50K', '50K-100K', '100K+'];

const monthlySales = ['0-300', '300-500', '500-1000', '1000+'];

export const types = {
  string: 'string',
  email: 'email',
  selectList: 'selectList',
};

export const formColumns = [
  {
    id: 'websiteName',
    title: 'Website Name of Client',
    placeHolder: 'Facility',
    isRequired: true,
    type: types.string,
    options: [],
  },
  {
    id: 'websiteUrl',
    title: 'Website URL of Client',
    placeHolder: 'www.facility.com',
    isRequired: true,
    type: types.string,
    options: [],
  },
  {
    id: 'clientName',
    title: 'Contact Name of Client',
    placeHolder: 'John Doe',
    isRequired: true,
    type: types.string,
    options: [],
  },
  {
    id: 'clientEmail',
    title: 'Email Address of Client',
    placeHolder: 'johndoe@email.com',
    isRequired: true,
    type: types.email,
    options: [],
  },
  {
    id: 'selectIndustry',
    title: 'Industry (Vertical or Primary)',
    placeHolder: '',
    isRequired: true,
    type: types.selectList,
    options: industries,
  },
  {
    id: 'selectUniqueSession',
    title: 'Unique Sessions per Month of Client',
    placeHolder: '',
    isRequired: true,
    type: types.selectList,
    options: uniqueSessions,
  },
  {
    id: 'selectPageViews',
    title: 'Pageviews per Month of Client',
    placeHolder: '',
    isRequired: true,
    type: types.selectList,
    options: pageViews,
  },
  {
    id: 'selectSku',
    title: 'Number of SKUs (products + variants) of Client',
    placeHolder: '',
    isRequired: true,
    type: types.selectList,
    options: skuList,
  },
  {
    id: 'selectMonthlySales',
    title: 'Number of Monthly Sales of Client',
    placeHolder: '',
    isRequired: true,
    type: types.selectList,
    options: monthlySales,
  },
];

export const validationSchema = Yup.object().shape({
  websiteName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  websiteUrl: Yup.string()
    .matches(urlValidationRegex, 'Invalid URL')
    .required('Required'),
  clientName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  clientEmail: Yup.string()
    .email('Invalid email')
    .required('Required'),
  selectIndustry: Yup.string().required('Required'),
  selectUniqueSession: Yup.string().required('Required'),
  selectPageViews: Yup.string().required('Required'),
  selectSku: Yup.string().required('Required'),
  selectMonthlySales: Yup.string().required('Required'),
});

export const initialValues = {
  websiteName: '',
  websiteUrl: '',
  clientName: '',
  clientEmail: '',
  selectIndustry: industries[0],
  selectUniqueSession: uniqueSessions[0],
  selectPageViews: pageViews[0],
  selectSku: skuList[0],
  selectMonthlySales: monthlySales[0],
};
