import React from 'react';
import './AbSplitCalendar.scss';
import moment from 'moment';
import Icons from '../../icons';

const AbSplitCalendar = ({ ab }) => {
  const isActive = ab.status === 'ACTIVE';
  let testSplitText = '';
  ab.variations.forEach((variation, index) => {
    testSplitText += `${variation.testSplit}%${
      index !== ab.variations.length - 1 ? ' - ' : ''
    }`;
  });
  const dateFormatted = moment(ab.endDate).format('MMMM DD');
  const splitTxt = isActive ? 'User Split' : 'User Splitted';
  const calendarTxt = isActive ? 'will be ended' : 'ended';

  return (
    <div className='ab-listDetail'>
      <div className='ab-listDetail-calendar' data-content={calendarTxt}>
        <Icons name='calendar' color='#737373' width='15' />
        {dateFormatted}
      </div>

      <div className='ab-listDetail-split' data-content={splitTxt}>
        <Icons name='userSplit' color='#737373' width='21' />
        {testSplitText}
      </div>
    </div>
  );
};

export default AbSplitCalendar;
