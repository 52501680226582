/**
 * @author Bilal Cinarli
 */

"use strict";

export const currencyIcons = {
  TL: "try",
  EUR: "euro",
  EURO: "euro",
  USD: "dollar",
  YUAN: "yuan",
  TRY: "try"
};