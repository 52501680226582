import React from 'react';
import PropTypes from 'prop-types';

// import { t } from "system/ui";
import InfoModal from 'components/InfoModal';
import DeviceFilter from '../search/insights/sub-components/device-filter';

// import Icon from 'components/icon';

export const CardWrapper = props => {
  const {
    hasDeviceFilter,
    className,
    devicesLookup,
    devicesValue,
    devicesClassName,
    label,
    isTimeSelectVisible = false,
    selectGraphView,
    selectedGraphView,
  } = props;

  const classNameTemp = ['card-wrapper'];
  if (className) {
    classNameTemp.push(className);
  }

  const deviceFilter = () => {
    if (hasDeviceFilter) {
      return (
        <DeviceFilter
          className='card-header__device-filter'
          devices={devicesLookup}
          selectDevice={devicesValue}
          isTimeSelectVisible={isTimeSelectVisible}
          selectGraphView={selectGraphView}
          selectedGraphView={selectedGraphView}
        />
      );
    }
  };

  return (
    <div className={classNameTemp.join(' ')}>
      <div className='card-header'>
        <h2 className='card-header__text'>
          {label}
          <InfoModal iconClassName='search__info-icon' title='Info'>
            {props.infoContent}
          </InfoModal>
        </h2>
        {deviceFilter()}
      </div>
      <div className='card-body'>{props.children}</div>
    </div>
  );
};

CardWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  hasDeviceFilter: PropTypes.bool,
  devicesLookup: PropTypes.array,
  devicesValue: PropTypes.any, // string object ?? will be control
  devicesClassName: PropTypes.string,
  infoContent: PropTypes.shape,
};

export default CardWrapper;
