import React, { useEffect, useState } from 'react';
import { useMonaco } from '@monaco-editor/react';

// import { uiActions } from '../../../actions';

/**
 * WithCodeEditor HOC
 * @description WithCodeEditor HOC that wraps the Monaco Editor
 * @param {JSX.Element} WrappedComponent
 * @returns  {function(*)}
 */

export const WithCodeEditor = WrappedComponent => {
  return props => {
    const monaco = useMonaco();

    const [fileName, setFileName] = useState('');
    const [fileValue, setFileValue] = useState({});
    const [errorMarkers, setErrorMarkers] = useState([]);
    let flag = false;

    const handleEditorChange = value => {
      if (!flag) props.handleEditorChange(fileName, value);
    };

    const handleFilenameChange = value => {
      flag = true;
      setFileName(value);
    };

    useEffect(() => {
      flag = false;
    }, [fileName]);

    const handleEditorDidMount = () => {
      setFileName('html');
    };

    const handleEditorWillMount = monacoInstance => {
      const { languages } = monacoInstance;
      console.log('languages', languages);

      // languages.typescript.typescriptDefaults.setCompilerOptions({
      //   target: languages.typescript.ScriptTarget.ES5,
      //   lib: ['ESNext', 'DOM'],
      // });

      // languages.html.htmlDefaults.setOptions({
      //   format: {
      //     wrapAttributes: 'force-aligned',
      //   },
      // });
    };

    const handleEditorValidation = markers => {
      markers.forEach(marker => {
        setErrorMarkers([...markers, marker]);
      });
    };

    useEffect(() => {
      setFileValue({
        ...fileValue,
        ...props.fileValue,
      });
    }, [
      props?.fileValue?.html,
      props?.fileValue?.css,
      props?.fileValue?.postjs,
      props?.fileValue?.prejs,
    ]);

    return (
      <>
        <WrappedComponent
          instance={monaco}
          errorMarkers={errorMarkers}
          fileName={fileName}
          fileValue={fileValue}
          setFileName={handleFilenameChange}
          handleEditorChange={handleEditorChange}
          handleEditorDidMount={handleEditorDidMount}
          handleEditorWillMount={handleEditorWillMount}
          handleEditorValidation={handleEditorValidation}
        />
      </>
    );
  };
};
