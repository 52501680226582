import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const PlaybooksIcon = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 25 25'
      fill='none'
    >
      <path d='M10.9808 8.96624L12.1271 6.64374C12.3862 6.11875 13.1348 6.11875 13.3939 6.64374L14.5401 8.96624L17.1031 9.33867C17.6825 9.42286 17.9138 10.1348 17.4946 10.5434L15.6399 12.3513L16.0778 14.904C16.1768 15.4809 15.5711 15.921 15.0529 15.6485L12.7604 14.4433L10.468 15.6485C9.94982 15.921 9.34418 15.4809 9.44315 14.904L9.88096 12.3513L8.02633 10.5434C7.60711 10.1348 7.83845 9.42286 8.4178 9.33867L10.9808 8.96624ZM12.0833 9.92445C11.9804 10.1329 11.7816 10.2774 11.5515 10.3109L10.0373 10.5308L11.1329 11.5989C11.2994 11.7611 11.3754 11.995 11.3361 12.2241L11.0774 13.7322L12.4318 13.0201C12.6375 12.912 12.8833 12.912 13.0892 13.0201L14.4434 13.7322L14.1848 12.2241C14.1455 11.995 14.2215 11.7611 14.3879 11.5989L15.4837 10.5308L13.9695 10.3109C13.7394 10.2774 13.5405 10.1329 13.4376 9.92445L12.7604 8.55239L12.0833 9.92445Z' />
      <path
        d='M6.77079 2.08334C5.33255 2.08334 4.16663 3.24927 4.16663 4.68751V20.3125C4.16663 21.7507 5.33255 22.9167 6.77079 22.9167H20.5729C21.0043 22.9167 21.3541 22.5669 21.3541 22.1354C21.3541 21.704 21.0043 21.3542 20.5729 21.3542H6.77079C6.1955 21.3542 5.72913 20.8878 5.72913 20.3125H20.5729C21.0043 20.3125 21.3541 19.9627 21.3541 19.5313V4.68751C21.3541 3.24927 20.1882 2.08334 18.75 2.08334H6.77079ZM19.7916 18.75H5.72913V4.68751C5.72913 4.11222 6.1955 3.64584 6.77079 3.64584H18.75C19.3253 3.64584 19.7916 4.11222 19.7916 4.68751V18.75Z'
        strokeWidth='0.5'
      />
    </svg>
  );
};

PlaybooksIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

PlaybooksIcon.defaultProps = {
  width: '25px',
  height: '25px',
};

export default PlaybooksIcon;
