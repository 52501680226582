export const TOGGLE = 'ABANDON/TOGGLE';
export const CHANGE_SELECT_OPTIONS = 'ABANDON/CHANGE_SELECT_OPTIONS';
export const CHANGE_DELAY_TIME = 'ABANDON/CHANGE_DELAY_TIME';
export const CHANGE_DELAY_UNIT = 'ABANDON/CHANGE_DELAY_UNIT';
export const CHANGE_ADD_TITLES = 'ABANDON/CHANGE_ADD_TITLES';
export const CHANGE_ADD_DESCRIPTIONS = 'ABANDON/CHANGE_ADD_DESCRIPTIONS';
export const CHANGE_SELECTED_LANGUAGE = 'ABANDON/CHANGE_SELECTED_LANGUAGE';

export const CHANGE_ICON = 'ABANDON/CHANGE_ICON';
export const CHANGE_ADDITIONAL_ICONS = 'ABANDON/CHANGE_ADDITIONAL_ICONS';
export const CHANGE_IMAGE = 'ABANDON/CHANGE_IMAGE';
export const CHANGE_ADDITIONAL_IMAGES = 'ABANDON/CHANGE_ADDITIONAL_IMAGES';
export const CHANGE_TARGET = 'ABANDON/CHANGE_TARGET';

export const CHANGE_ACTIONS = 'ABANDON/CHANGE_ACTIONS';
export const CHANGE_COUPON_CODE = 'ABANDON/CHANGE_COUPON_CODE';
export const CHANGE_COUPON_EXPIRE = 'ABANDON/CHANGE_COUPON_EXPIRE';
export const CHANGE_COUPON_BASKET_AMOUNT =
  'ABANDON/CHANGE_COUPON_BASKET_AMOUNT';
export const RESET_ABANDON = 'ABANDON/RESET_ABANDON';
export const INIT_ABANDON_VALUES = 'ABANDON/INIT_ABANDON_VALUES';
export const SET_FETCHING = 'ABANDON/SET_FETCHING';
export const SET_FETCHED = 'ABANDON/SET_FETCHED';
export const UPDATE_STATUS = 'ABANDON/UPDATE_STATUS';
export const UPDATE_HIDE_PRICE = 'ABANDON/UPDATE_HIDE_PRICE';
export const UPDATE_HIDE_INFORMATION = 'ABANDON/UPDATE_HIDE_INFORMATION';

export const CHANGE_WIDGET_ITEM = 'ABANDON/CHANGE_WIDGET_ITEM';

export const changeWidgetItem = (name, value) => ({
  type: CHANGE_WIDGET_ITEM,
  payload: {
    name,
    value,
  },
});

export const toggleButton = name => ({
  type: TOGGLE,
  payload: {
    name,
  },
});

export const changeSelectOptions = (name, options) => ({
  type: CHANGE_SELECT_OPTIONS,
  payload: {
    name,
    options,
  },
});

export const changeDelayTime = (name, delay) => ({
  type: CHANGE_DELAY_TIME,
  payload: {
    name,
    delay,
  },
});

export const changeDelayUnit = (name, delayUnit) => ({
  type: CHANGE_DELAY_UNIT,
  payload: {
    name,
    delayUnit,
  },
});

export const changeAddTitles = (name, titlesObj) => ({
  type: CHANGE_ADD_TITLES,
  payload: {
    name,
    titlesObj,
  },
});

export const changeAddDescriptions = (name, descObj) => ({
  type: CHANGE_ADD_DESCRIPTIONS,
  payload: {
    name,
    descObj,
  },
});

export const changeIcon = (name, icon) => ({
  type: CHANGE_ICON,
  payload: {
    name,
    icon,
  },
});

export const changeAdditionalIcons = (name, additionalIcons) => ({
  type: CHANGE_ADDITIONAL_ICONS,
  payload: {
    name,
    additionalIcons,
  },
});

export const changeImage = (name, image) => ({
  type: CHANGE_IMAGE,
  payload: {
    name,
    image,
  },
});

export const changeAdditionalImages = (name, additionalImages) => ({
  type: CHANGE_ADDITIONAL_IMAGES,
  payload: {
    name,
    additionalImages,
  },
});

export const changeTarget = (name, target) => ({
  type: CHANGE_TARGET,
  payload: {
    name,
    target,
  },
});

export const changeActions = (name, actions) => ({
  type: CHANGE_ACTIONS,
  payload: {
    name,
    actions,
  },
});

export const changeSelectedLanguage = (name, language) => ({
  type: CHANGE_SELECTED_LANGUAGE,
  payload: {
    name,
    language,
  },
});

export const changeCouponCode = (name, code) => ({
  type: CHANGE_COUPON_CODE,
  payload: {
    name,
    code,
  },
});

export const changeCouponExpire = (name, expire) => ({
  type: CHANGE_COUPON_EXPIRE,
  payload: {
    name,
    expire,
  },
});

export const changeCouponBasketAmount = (name, amount) => ({
  type: CHANGE_COUPON_BASKET_AMOUNT,
  payload: {
    name,
    amount,
  },
});

export const initAbandonValues = payload => ({
  type: INIT_ABANDON_VALUES,
  payload,
});

export const setFetching = () => ({
  type: SET_FETCHING,
});

export const setFetched = () => ({
  type: SET_FETCHED,
});

export const updateStatus = payload => ({
  type: UPDATE_STATUS,
  status: payload,
});

export const updateHidePrice = (name, status) => ({
  type: UPDATE_HIDE_PRICE,
  payload: {
    name,
    status,
  },
});

export const updateHideInformation = (name, status) => ({
  type: UPDATE_HIDE_INFORMATION,
  payload: {
    name,
    status,
  },
});

export const resetAbandon = () => ({
  type: RESET_ABANDON,
});
