/**
 * Created by mehmetyurtar on 20/07/2017.
 */
import React from "react";

class IconLike extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "20px",
      height: "20px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <path
          fill={this.state.color}
          d="M12,21H4c-0.552,0-1,0.447-1,1v22c0,0.553,0.448,1,1,1h8c0.552,0,1-0.447,1-1v-2.394l3.067,1.533
	C18.466,44.339,21.283,45,24,45h14c2.735,0,4.964-2.213,5-4.946l1.993-15.941C44.998,24.071,45,24.029,45,23.988
	C45,21.237,42.757,19,40,19h-9v-5.012c0-3.528-2.119-8.573-5.542-10.571c-0.924-0.54-2.031-0.543-2.962-0.009
	C21.559,3.946,21,4.91,21,5.988v7.638l-8,9.6V22C13,21.447,12.552,21,12,21z M11,43H5V23h6V43z M22.768,14.629
	c0.15-0.18,0.232-0.406,0.232-0.641v-8c0-0.509,0.344-0.761,0.491-0.846c0.145-0.082,0.529-0.249,0.958,0.001
	C27.131,6.709,29,11.062,29,13.988V20c0,0.553,0.448,1,1,1h10c1.635,0,2.968,1.31,3,2.931l-1.992,15.934
	C41.002,39.905,41,39.947,41,39.988C41,41.649,39.654,43,38,43H24c-2.412,0-4.912-0.586-7.039-1.649L13,39.37v-13.02L22.768,14.629z
	"
        />
      </svg>
    );
  }
}

export default IconLike;
