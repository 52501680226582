/**
 * Created by mehmetyurtar on 13/09/2017.
 */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { SortableHandle } from 'react-sortable-hoc';
import { rockstarTimeFrame, items } from '../../constants/datamaps/wizard';
import { labelsActions } from '../../actions';

import { t } from '../../system/ui';

import { getLabels } from '../../modules/account/ajax';
import { decodeHtml } from '../../system/string';

import Icon from '../icon';
import Icons from '../icons';

import Tooltip from '../tooltip';

class CriteriaLabel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: [],
      showRequiredError: false,
      criterion: '',
    };

    this.onChange = this.onChange.bind(this);
    this.setLabels = this.setLabels.bind(this);
  }

  componentDidMount() {
    if (this.props.isLabelsSet && this.props.labels.length) {
      this.setLabels(this.props.labels);
    } else {
      getLabels().then(response => {
        const sorted = response.sort();
        const labels = [];
        sorted.map(item => {
          labels.push({
            value: item,
            label: decodeHtml(item),
          });
        });
        if (typeof this.props.values.criterion !== 'undefined') {
          labels.push({
            value: this.props.values.criterion.value,
            label: decodeHtml(this.props.values.criterion.value),
          });
        }
        labelsActions.setLabels(labels);
        this.setLabels(labels);
      });
    }
  }

  setLabels(labels) {
    this.setState(
      {
        labels,
        criterion:
          typeof this.props.values.criterion !== 'undefined'
            ? this.props.values.criterion.value
            : '',
      },
      () => {
        this.onChange({
          value: this.state.criterion,
          label: this.state.criterion,
        });
      },
    );
  }

  onChange(selectedValue) {
    const selectedLabel =
      'value' in selectedValue ? selectedValue.value : this.state.criterion;
    this.setState({ criterion: selectedLabel });
    this.props.onCriteriaChange(this.refs.inputs, this.props.id, selectedLabel);
    this.validate(selectedLabel);
  }

  validate(selectedLabel = '') {
    const input = selectedLabel || this.state?.criterion || '';

    if (input.length < 1) {
      this.setState({
        showRequiredError: true,
      });
    } else {
      this.setState({
        showRequiredError: false,
      });
    }
  }

  render() {
    const theTimeFrame =
      typeof this.props.values.timeFrame !== 'undefined'
        ? this.props.values.timeFrame.value
        : '';
    const theItemCount =
      typeof this.props.values.itemCount !== 'undefined'
        ? this.props.values.itemCount.value
        : '';
    const mobileItemCount =
      typeof this.props.values.mobileItemCount !== 'undefined'
        ? this.props.values.mobileItemCount.value
        : '';
    const DragHandle = SortableHandle(() => (
      <span className='draghandler'>
        <Icons name='burger' color='#e8e7f2' />
      </span>
    ));
    return (
      <li className='wizard-criterion' ref='inputs'>
        <DragHandle />
        <span className='criteria-type'>
          <Tooltip content={t('Static (Label)')} alignment='left'>
            <Icons name='excludeLabel' color='#547494' />
          </Tooltip>
        </span>
        <label className='item item-field is-select criteria-name'>
          <Select
            value={this.state.criterion}
            options={this.state.labels}
            name='criterion'
            clearable={false}
            onChange={this.onChange}
            className='criteria-field one-whole'
            placeholder={t('Select Label')}
          />
        </label>
        <label className='item item-field is-select criteria-time-frame'>
          <select
            name='timeFrame'
            ref='timeFrame'
            className='one-whole criteria-field'
            onChange={this.onChange}
            defaultValue={theTimeFrame}
          >
            {Object.keys(rockstarTimeFrame).map(item => {
              return (
                <option key={item} value={item}>
                  {t(rockstarTimeFrame[item])}
                </option>
              );
            })}
          </select>
        </label>

        {this.props.isSearch === undefined && (
          <label className='item item-field is-select criteria-item-count'>
            <span className='item-label'>product</span>{' '}
            <select
              name='itemCount'
              className='one-whole criteria-field'
              onChange={this.onChange}
              disabled={this.props.isSingleRec}
              defaultValue={theItemCount}
            >
              {items.map(item => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </label>
        )}

        {this.props.isSearch && (
          <>
            <label className='item item-field is-select criteria-item-mobile-count'>
              <span className='item-label'>mobile</span>{' '}
              <select
                name='mobileItemCount'
                className='one-whole criteria-field'
                onChange={this.onChange}
                defaultValue={mobileItemCount}
              >
                {items.map(item => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </label>

            <label className='item item-field is-select criteria-item-mobile-count'>
              <span className='item-label'>desktop</span>{' '}
              <select
                name='itemCount'
                className='one-whole criteria-field'
                onChange={this.onChange}
                defaultValue={theItemCount}
              >
                {items.map(item => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </label>
          </>
        )}

        <a
          onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
          className={
            this.props.isOnlySelection
              ? 'cancel-action cancel-action--disabled'
              : 'cancel-action'
          }
        >
          <Icons name='crossCircle' />
        </a>
        {this.state.showRequiredError ? (
          <span
            className='item-error has-error'
            style={{ display: 'block', marginTop: '10px' }}
          >
            {t('You should select at least one label from suggestions')}
          </span>
        ) : (
          ''
        )}
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  criteria: store.wizard.criteria,
  labels: store.labels.options,
  isLabelsSet: store.labels.isSet,
});

export default connect(mapStatesToProps)(CriteriaLabel);
