import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { modalActions, uiActions } from 'actions';
import { userManagementTable } from 'constants/datamaps/account';
import UserHistoryModal from 'components/user-history-modal';
import {
  deleteUser,
  getUserList,
  unlockUser,
} from 'reducers/user-management/extraReducers';

import { dialogActions } from '../../actions';
import EditUserForm from '../../components/admin/user-management/EditUserForm';
import { isMasterUser, isSuperUser } from '../../modules/auth/user';
import { onResetFilterClick } from '../../reducers/user-management';
import { t } from '../../system/ui';

const UserManagementTable = () => {
  const userState = useSelector(state => state.userManagement.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  const handleEdit = user => {
    const content = () => <EditUserForm user={user} />;

    uiActions.openModal({ title: t('Edit User'), content });
  };

  const handleDelete = username =>
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t(`Are you sure to delete ${username}?`),
      onConfirm: async () => {
        await dispatch(deleteUser(username));
        dialogActions.closeDialog();
        await dispatch(onResetFilterClick());
        await dispatch(getUserList());
        const notificationContent = () => (
          <div>
            {`${t('You have successfully deleted the user')} ${username}.`}
          </div>
        );
        uiActions.showNotification({ content: notificationContent });
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });

  const handleUnlock = username => {
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t(`Are you sure to unlock the locked user ${username}?`),
      onConfirm: async () => {
        await dispatch(unlockUser(username));
        dialogActions.closeDialog();
        await dispatch(onResetFilterClick());
        await dispatch(getUserList());
        const notificationContent = () => (
          <div>{t(`User (${username}) unlocked successfully.`)}</div>
        );
        uiActions.showNotification({ content: notificationContent });
      },
      onCancel: () => dialogActions.closeDialog(),
    });
  };

  const showHistory = userName => {
    modalActions.openModal({
      title: 'User History',
      content: () => <UserHistoryModal userName={userName} />,
    });
  };

  const getUserType = userTypes => {
    if (userTypes.includes('Master User')) return 'Master User';
    if (userTypes.includes('Super User')) return 'Super User';
    if (userTypes.includes('Account Developer')) return 'Account Developer';
    if (userTypes.includes('Onboarding Developer'))
      return 'Onboarding Developer';
    if (userTypes.includes('Account Manager')) return 'Account Manager';
    if (userTypes.includes('Onboarding Manager')) return 'Onboarding Manager';
    if (userTypes.includes('Sales Manager')) return 'Sales Manager';
    if (userTypes.includes('Agent User')) return 'Agent User';
    return 'Account User';
  };

  return (
    <table className='data-table'>
      <thead>
        <tr>
          {userManagementTable.header.map(item => (
            <th className={item.className}>{item.text}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {userState.loaded &&
          userState.data.map((user, index) => (
            <tr key={user + '' + index}>
              <td>{index + 1}</td>
              <td>{user.displayName}</td>
              <td>{getUserType(user.userType)}</td>
              <td>{user.username}</td>
              <td>{user.status || 'ACTIVE'}</td>
              <td>
                {user.lockedTime > 0
                  ? moment(user.lockedTime).format('DD.MM.YYYY')
                  : '-'}
              </td>
              <td width='250'>
                <div className='actions'>
                  <button
                    type='button'
                    className='edit'
                    onClick={() => {
                      handleEdit(user);
                    }}
                  >
                    {t('Edit')}
                  </button>
                  {user.status === 'LOCKED' && (isSuperUser || isMasterUser) && (
                    <button
                      type='button'
                      className='primary'
                      onClick={() => {
                        handleUnlock(user.username);
                      }}
                    >
                      {t('Unlock')}
                    </button>
                  )}
                  <button
                    type='button'
                    className='delete'
                    onClick={() => {
                      handleDelete(user.username);
                    }}
                  >
                    {t('Delete')}
                  </button>
                  <button
                    onClick={() => showHistory(user.username)}
                    type='button'
                    className='history'
                  >
                    {t('History')}
                  </button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default UserManagementTable;
