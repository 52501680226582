import React, { useState, useCallback, useEffect } from 'react';
import Select from 'react-select';
import Icons from '../../../../icons';
import {
  onCategoryInputChange,
  fixedInputtedNumber,
} from '../common/common-functions';

const RPCategoryAccessor = ({ data, setAttributes, onRemove }) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [weight, setWeight] = useState('');

  const onInputChange = useCallback(
    value => onCategoryInputChange(value, setCategoryOptions),
    [setCategoryOptions],
  );

  const onWeightChange = value => {
    const fixedValue = fixedInputtedNumber(1, 250, value).toString();
    setWeight(fixedValue);
  };

  useEffect(() => {
    if (data?.value) {
      setSelectedCategory({
        value: data.value,
        label: data.value,
      });
      setCategoryOptions([
        {
          value: data.value,
          label: data.value,
        },
      ]);
    }

    if (data?.weight) {
      setWeight(data.weight);
    }
  }, []);

  // set attributes
  useEffect(() => {
    setAttributes({
      id: data.id,
      productAccessor: 'category',
      value: selectedCategory?.value,
      weight,
    });
  }, [selectedCategory, weight]);

  return (
    <div className='sfy-attribute-field sfy-related-products'>
      <div className='sfy-attribute-field-icon'>
        <Icons
          name='categoryAccessoryIcon'
          color='#7A7A7C'
          height='12'
          width='12'
        />
      </div>
      <div className='sfy-attribute-field-select'>
        <Select
          value={selectedCategory}
          onInputChange={onInputChange}
          options={categoryOptions}
          onChange={selected => {
            setSelectedCategory(selected);
          }}
          placeholder='Select Category'
          className='sfy-attribute-field-select-input'
          clearable={false}
        />
      </div>
      <div className='sfy-attribute-field-weight'>
        <span className='sfy-attribute-field-weight-label'>Weight :</span>
        <input
          placeholder='Type Weight'
          type='number'
          className='gc-weight-text-input'
          value={weight}
          onChange={e => {
            onWeightChange(e.target.value);
          }}
        />
      </div>
      <div
        className='sfy-attribute-field-remove'
        onClick={() => onRemove(data.id)}
      >
        <Icons name='crossCircle' color='#D31116' height='18' width='18' />
      </div>
    </div>
  );
};

export default RPCategoryAccessor;
