import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { mailWizardActions, modalActions, uiActions } from '../../../actions';
import { SFYHeader } from '../../../components/sfy-components/module-exports';
import { campaignInitialData } from '../../../constants/datamaps/email';
import { Context as EmailContext } from '../store/EmailProvider';
import { getPageTitle } from '../utils/utils';
import styles from './AddOrEdit.module.scss';

import { getAccountId } from '../../../modules/auth/user';
import { singleEmailCampaign } from '../../../modules/campaigns/wizard';
import {
  addEmailCampaign,
  getEmailCampaign,
  updateEmailCampaign,
} from '../../../modules/emails/ajax';
import SendTestModal from '../../../modules/emails/send-test-modal';
import {
  personalizationRecommendationExclusions,
  personalizationRecommendations,
} from '../../../modules/transform/personalizationRecommendations';
import { notification } from '../../../system/ui';
import EmailStepper from '../components/email-stepper/EmailStepper';
import EmailDetailComponents from './components/EmailDetailComponents';
import EmailPreview from './components/EmailPreview';
import { uploadCouponCodes } from '../services/couponCodeServices';
import { findDefautAlgorithm } from '../../../components/wizard/utils';

const utmCampaignConstants = {
  isCustomUtm: false,
  utmSource: 'segmentify',
  utmMedium: 'email',
  utmCampaign: '',
  utmContent: '',
  utmTerm: '',
};

const AddOrEdit = ({ params, location }) => {
  const { mode, emailType } = params;
  const mailConfiguration = useSelector(
    ({ switchedUser, user }) =>
      switchedUser?.switchedUser?.account?.mailConfiguration?.params ||
      user?.user?.account?.mailConfiguration?.params,
  );

  const componentsRef = useRef(null);
  const { isLoaded, isLoading, showNotification } = uiActions;
  const { closeModal } = modalActions;
  const {
    state: { activeEmailDetails, uploadFile },
    setActiveEmailDetails,
    editActiveEmailDetails,
    setUploadFile,
  } = useContext(EmailContext);
  const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);
  const [checkedEmailType, setCheckedEmailType] = useState(emailType);
  const [campaignUtmState, setCampaignUtmState] = useState({
    default: utmCampaignConstants,
    custom: { ...utmCampaignConstants, isCustomUtm: true },
  });

  const campaignUtmSetter = newCampaignUtm => {
    const defaultUtm = {
      ...campaignUtmState.default,
      utmContent: newCampaignUtm.utmContent,
      utmTerm: newCampaignUtm.utmTerm,
    };
    const customUtm = {
      ...campaignUtmState.custom,
      utmSource: newCampaignUtm.utmSource,
      utmMedium: newCampaignUtm.utmMedium,
      utmContent: newCampaignUtm.utmContent,
      utmTerm: newCampaignUtm.utmTerm,
    };

    if (newCampaignUtm.isCustomUtm) {
      customUtm.utmCampaign = newCampaignUtm.utmCampaign;
    } else {
      defaultUtm.utmCampaign = newCampaignUtm.utmCampaign;
    }

    setCampaignUtmState({ default: defaultUtm, custom: customUtm });
  };

  const editCampaign = (fieldKey, fieldValue) => {
    let tempFieldValue = fieldValue;
    if (fieldKey === 'campaignUtm') {
      if (
        fieldValue.isCustomUtm !== activeEmailDetails?.campaignUtm?.isCustomUtm
      ) {
        tempFieldValue = fieldValue.isCustomUtm
          ? campaignUtmState.custom
          : campaignUtmState.default;
      } else {
        campaignUtmSetter(tempFieldValue);
      }
    }

    editActiveEmailDetails({ [fieldKey]: tempFieldValue });
  };

  const wizard = useSelector(state => state.wizard);
  const mailWizard = useSelector(state => state.mailWizard);

  const { mutateAsync } = useMutation(uploadCouponCodes, {
    onSuccess: response => {
      showNotification({
        content: () => <p>{response}</p>,
        className: 'notification-success',
      });
      isLoaded();
    },
    onError: error => {
      isLoaded();
      showNotification({
        content: () => <p>{error}</p>,
        className: 'notification-fail',
      });
    },
  });

  useEffect(() => {
    const { criteria, includes, excludes } = wizard;
    let recommendationInclusions = [];
    let recommendationExclusions = [];
    const additionalRecommendations = ['FLY', 'RECOMMENDATION'].includes(
      checkedEmailType,
    )
      ? personalizationRecommendations(criteria)
      : personalizationRecommendations(
          criteria.filter((item, index) => {
            return index !== 0;
          }),
        );
    let defaultRecommendation = [];
    if (!['FLY', 'RECOMMENDATION'].includes(checkedEmailType) && criteria[0]) {
      defaultRecommendation = personalizationRecommendations([criteria[0]]);
    }

    if (includes) {
      recommendationInclusions = personalizationRecommendationExclusions(
        includes,
      );
    }
    if (excludes) {
      recommendationExclusions = personalizationRecommendationExclusions(
        excludes,
      );
    }

    editActiveEmailDetails({
      additionalRecommendations,
      defaultRecommendation,
      inclusions: recommendationInclusions,
      exclusions: recommendationExclusions,
    });
  }, [wizard.criteria.length, wizard.includes.length, wizard.excludes.length]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const templateName = location?.state?.templateName || '';
    const templateId = location?.state?.templateId || '';
    if (['edit', 'preview'].includes(mode)) {
      isLoading();
      getEmailCampaign(emailType, campaign => {
        if (campaign) {
          let filterResult = campaign?.filters?.filter(
            item => item?.type === 'SEGMENT' || item?.actionType,
          );

          if (!filterResult?.length) {
            filterResult = [
              {
                type: 'SEGMENT',
                segment: '',
                include: true,
              },
              {
                type: 'SEND_MAIL_SEGMENT',
                actionType: null,
                campaign: '',
                startDate: null,
                endDate: null,
              },
            ];
          } else if (filterResult?.length === 1) {
            if (filterResult[0].type === 'SEGMENT') {
              filterResult = [
                ...filterResult,
                {
                  type: 'SEND_MAIL_SEGMENT',
                  actionType: null,
                  campaign: '',
                  startDate: null,
                  endDate: null,
                },
              ];
            } else {
              delete filterResult[0].segment;
              delete filterResult[0].include;
              filterResult = [
                {
                  type: 'SEGMENT',
                  segment: '',
                  include: true,
                },
                ...filterResult,
              ];
            }
          }

          const newCampaign = {
            ...campaign,
            scenarioName: campaign.scenarioName,
            template: templateName || campaign.template,
            templateId: templateId || campaign.templateId,
            filters: filterResult,
          };
          const campaignUtm = newCampaign?.campaignUtm || {};
          if (Object.keys(campaignUtm).length === 0) {
            newCampaign.campaignUtm = utmCampaignConstants;
            newCampaign.campaignUtm.utmCampaign = campaign?.instanceId || '';
          }
          if (!newCampaign?.params?.replyName) {
            newCampaign.params.replyName = mailConfiguration?.replyName;
          }

          if (newCampaign.type === 'PRICE_DROP') {
            if (!newCampaign.params.workingHour) {
              newCampaign.params.workingHour = '11:00';
            }
            if (
              !newCampaign.params.sendBetweenHour ||
              !newCampaign.params.triggerInHours
            ) {
              newCampaign.params.sendBetweenHour = '10-14';
              newCampaign.params.triggerInHours = '2';
            }
          }

          campaignUtmSetter(newCampaign.campaignUtm);
          setCheckedEmailType(campaign.type);
          setActiveEmailDetails(newCampaign);
          singleEmailCampaign(newCampaign);
          setIsCampaignLoaded(true);
          if (campaign?.customParams && !!campaign?.customParams?.length) {
            mailWizardActions.addMapping(
              campaign?.customParams.map(param => {
                return {
                  id: Math.random(),
                  key: param.key,
                  value: param.value,
                };
              }),
            );
          } else {
            mailWizardActions.addMapping([
              {
                id: Math.random(),
                key: '',
                value: '',
              },
            ]);
          }
        }
        isLoaded();
      });
    } else {
      if (location.state?.campaign?.type) {
        const locationState = location.state.campaign;
        isLoading();
        getEmailCampaign(locationState.instanceId, campaign => {
          if (campaign) {
            let filterResult = campaign?.filters?.filter(
              item => item?.type === 'SEGMENT' || item?.actionType,
            );

            if (!filterResult?.length) {
              filterResult = [
                {
                  type: 'SEGMENT',
                  segment: '',
                  include: true,
                },
                {
                  type: 'SEND_MAIL_SEGMENT',
                  actionType: null,
                  campaign: '',
                  startDate: null,
                  endDate: null,
                },
              ];
            } else if (filterResult?.length === 1) {
              if (filterResult[0].type === 'SEGMENT') {
                filterResult = [
                  ...filterResult,
                  {
                    type: 'SEND_MAIL_SEGMENT',
                    actionType: null,
                    campaign: '',
                    startDate: null,
                    endDate: null,
                  },
                ];
              } else {
                delete filterResult[0].segment;
                delete filterResult[0].include;
                filterResult = [
                  {
                    type: 'SEGMENT',
                    segment: '',
                    include: true,
                  },
                  ...filterResult,
                ];
              }
            }

            const newCampaign = {
              ...campaign,
              scenarioName: campaign.scenarioName,
              template: templateName || campaign.template,
              templateId: templateId || campaign.templateId,
              filters: filterResult,
            };

            delete newCampaign.instanceId;
            delete newCampaign.status;
            delete newCampaign.startDate;
            delete newCampaign.endDate;
            delete newCampaign.lastUpdateDate;
            delete newCampaign.testEmailSent;
            delete newCampaign.sendToIdentifyApi;
            delete newCampaign.prioritized;
            delete newCampaign.campaignSql;
            if (newCampaign.coupon) delete newCampaign.coupon;

            if (newCampaign.type === 'FLY') {
              newCampaign.startDate = new Date().getTime();
            }

            newCampaign.schedule = {
              type: 'one_time',
              period: 'every_day',
              date: new Date().getTime() + 15 * 60 * 1000,
            };

            const campaignUtm = newCampaign?.campaignUtm || {};
            if (Object.keys(campaignUtm).length === 0) {
              newCampaign.campaignUtm = utmCampaignConstants;
            } else {
              newCampaign.campaignUtm.utmCampaign = '';
            }
            if (!newCampaign?.params?.replyName) {
              newCampaign.params.replyName = mailConfiguration?.replyName;
            }

            campaignUtmSetter(newCampaign.campaignUtm);
            setCheckedEmailType(campaign.type);
            setActiveEmailDetails(newCampaign);
            singleEmailCampaign(newCampaign);
            setIsCampaignLoaded(true);
            if (campaign?.customParams && !!campaign?.customParams?.length) {
              mailWizardActions.addMapping(
                campaign?.customParams.map(param => {
                  return {
                    id: Math.random(),
                    key: param.key,
                    value: param.value,
                  };
                }),
              );
            } else {
              mailWizardActions.addMapping([
                {
                  id: Math.random(),
                  key: '',
                  value: '',
                },
              ]);
            }
          }
          isLoaded();
        });
      } else {
        const initialData = campaignInitialData.find(
          cmp => cmp.type === emailType.toUpperCase(),
        );
        initialData.template = templateName;
        initialData.templateId = templateId;
        initialData.accountId = getAccountId();
        initialData.scenarioName = decodeURIComponent(initialData.scenarioName);
        initialData.params.fromAddress = mailConfiguration.fromAddress;
        initialData.params.fromName = mailConfiguration.fromName;
        initialData.params.replyToAddress = mailConfiguration.replyAddress;
        initialData.params.replyName = mailConfiguration.replyName;
        initialData.campaignUtm = utmCampaignConstants;

        // eslint-disable-next-line array-callback-return,consistent-return
        const isSegmentFilterExist = initialData?.filters?.some(filter => {
          if (filter?.type === 'SEGMENT') {
            return true;
          }
        });
        const isSendMailSegmentFilterExist = initialData?.filters?.some(
          filter => {
            if (filter?.type === 'SEND_MAIL_SEGMENT') {
              return true;
            }
            return false;
          },
        );
        if (!isSegmentFilterExist && initialData?.filters) {
          initialData.filters.push({
            type: 'SEGMENT',
            segment: '',
            include: true,
          });
        }
        if (!isSendMailSegmentFilterExist && initialData?.filters) {
          initialData.filters.push({
            type: 'SEND_MAIL_SEGMENT',
            actionType: '',
            campaign: '',
            startDate: '',
            endDate: '',
          });
        }

        campaignUtmSetter(initialData.campaignUtm);
        setActiveEmailDetails(initialData);
        singleEmailCampaign(initialData);
        setIsCampaignLoaded(true);
        mailWizardActions.addMapping([
          {
            id: Math.random(),
            key: '',
            value: '',
          },
        ]);
      }
      return () => {
        setActiveEmailDetails({
          params: {
            mailContent: '',
            subject: '',
            interval: '1-24',
            currency: 'SESSION_BASED',
            language: 'SESSION_BASED',
            fromAddress: mailConfiguration.fromAddress,
            fromName: mailConfiguration.fromName,
            replyToAddress: mailConfiguration.replyToAddress,
            replyName: mailConfiguration.replyName,
          },
          schedule: {
            type: 'one_time',
            period: 'every_day',
            date: new Date().getTime() + 15 * 60 * 1000,
          },
          capping: {
            capCount: '1',
            frequency: 'DAILY',
            types: [],
          },
          filters: [
            {
              type: 'SEGMENT',
              segment: '',
              include: true,
            },
            {
              type: 'SEND_MAIL_SEGMENT',
              actionType: '',
              campaign: '',
              startDate: '',
              endDate: '',
            },
          ],
        });
      };
    }
  }, []);

  const validate = () => {
    const errors = componentsRef.current.querySelectorAll(
      '.has-error, .item-error',
    );
    if (errors.length > 0) {
      const dateError = errors[0].querySelector('.date-error');
      if (errors.length === 1 && dateError) {
        dateError.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
      return 'Please fix all the errors.';
    }

    return true;
  };

  const openTestModal = () => {
    const sendTestModal = () => (
      <SendTestModal
        instanceId={activeEmailDetails?.instanceId}
        template={activeEmailDetails?.template}
        campaignStatus={activeEmailDetails?.status}
        additionalSubjects={activeEmailDetails?.additionalSubjects}
        additionalContents={activeEmailDetails?.additionalContents}
        senderName={activeEmailDetails?.params?.senderName}
        senderEmail={activeEmailDetails?.params?.senderEmail}
        replyEmail={activeEmailDetails?.params?.replyEmail}
      />
    );

    modalActions.openModal({
      title: 'Send Test Mail',
      content: sendTestModal,
    });
  };

  const save = (status, shouldSave = true, shouldOpenTestModal = false) => {
    const validation = validate();
    if (validation !== true) {
      const content = () => <p>{validation}</p>;
      showNotification({
        content,
        className: 'notification-fail',
      });
      return;
    }

    const { criteria, includes, excludes } = wizard;
    const { mappings } = mailWizard;
    let recommendationInclusions = [];
    let recommendationExclusions = [];
    const defaultAlgorithm = findDefautAlgorithm(criteria, emailType);
    const additionalRecommendations = ['FLY', 'RECOMMENDATION'].includes(
      checkedEmailType,
    )
      ? personalizationRecommendations(criteria, true)
      : personalizationRecommendations(
          criteria.filter(item => {
            return (
              item.type === 'product' ||
              item?.values?.criterion?.value !==
                defaultAlgorithm?.values?.criterion?.value
            );
          }),
          true,
        );
    let defaultRecommendation = [];
    if (!['FLY', 'RECOMMENDATION'].includes(checkedEmailType) && criteria[0]) {
      defaultRecommendation = defaultAlgorithm
        ? personalizationRecommendations([defaultAlgorithm])
        : [];
    }
    if (includes) {
      recommendationInclusions = personalizationRecommendationExclusions(
        includes,
      );
    }
    if (excludes) {
      recommendationExclusions = personalizationRecommendationExclusions(
        excludes,
      );
    }
    const customParams =
      mappings
        ?.map(mapping => {
          if (mapping.key && mapping.value) {
            return { key: mapping.key, value: mapping.value };
          }
          return null;
        })
        .filter(Boolean) || [];

    const fixedFilters = activeEmailDetails.filters?.filter(filter => {
      return (
        (filter?.type === 'SEGMENT' && filter?.segment !== '') ||
        (filter?.type === 'SEND_MAIL_SEGMENT' &&
          filter?.actionType &&
          (filter?.startDate || filter?.endDate))
      );
    });

    editActiveEmailDetails({
      additionalRecommendations,
      defaultRecommendation,
    });

    let finalActiveEmailDetails = {
      ...activeEmailDetails,
      additionalRecommendations,
      defaultRecommendation,
      inclusions: recommendationInclusions,
      exclusions: recommendationExclusions,
      customParams,
      status,
      filters: fixedFilters,
    };

    if (finalActiveEmailDetails.type === 'PRICE_DROP') {
      if (finalActiveEmailDetails.params.period === 'every_hour') {
        delete finalActiveEmailDetails.params.workingHour;
      } else if (finalActiveEmailDetails.params.period === 'every_day') {
        delete finalActiveEmailDetails.params.sendBetweenHour;
        delete finalActiveEmailDetails.params.triggerInHours;
      }
    }

    if (finalActiveEmailDetails.endDate === null) {
      delete finalActiveEmailDetails.endDate;
    }

    if (!shouldSave) {
      editActiveEmailDetails({
        ...finalActiveEmailDetails,
        filters: activeEmailDetails.filters,
      });
      return;
    }

    if (finalActiveEmailDetails?.type === 'RECOMMENDATION') {
      if (finalActiveEmailDetails?.schedule?.type === 'now') {
        finalActiveEmailDetails = {
          ...finalActiveEmailDetails,
          schedule: {
            ...finalActiveEmailDetails.schedule,
            date: new Date().setMinutes(new Date().getMinutes() + 15),
          },
        };
      }
    }

    if (mode === 'edit' || !['FLY', 'RECOMMENDATION'].includes(emailType)) {
      if (uploadFile && finalActiveEmailDetails?.schedule?.type === 'now') {
        showNotification({
          content: () => (
            <p>
              Email campaigns with coupon codes can not be scheduled ‘as soon as
              possible’.
            </p>
          ),
          className: 'notification-fail',
        });
        return;
      }
      updateEmailCampaign(
        finalActiveEmailDetails,
        ({ status: responseStatus }) => {
          if (['TEST', 'PASSIVE', 'ACTIVE'].includes(responseStatus)) {
            closeModal();
            const note = () => {
              return <div>Campaign Updated Successfully</div>;
            };
            notification({
              content: note,
            });

            if (uploadFile) {
              mutateAsync({
                id: finalActiveEmailDetails.instanceId,
                file: uploadFile,
              });
              setUploadFile(null);
            }

            editCampaign('status', responseStatus);

            if (shouldOpenTestModal) {
              openTestModal();
            }
          }
        },
      );
    } else {
      if (uploadFile && finalActiveEmailDetails?.schedule?.type === 'now') {
        showNotification({
          content: () => (
            <p>
              Email campaigns with coupon codes can not be scheduled ‘as soon as
              possible’.
            </p>
          ),
          className: 'notification-fail',
        });
        return;
      }
      addEmailCampaign(
        finalActiveEmailDetails,
        ({ status: responseStatus, instanceId, campaignUtm }) => {
          if (['TEST', 'PASSIVE', 'ACTIVE'].includes(responseStatus)) {
            let shouldOpenTestModalAfterRefresh = false;

            setActiveEmailDetails({
              ...finalActiveEmailDetails,
              instanceId,
              campaignUtm,
            });

            if (uploadFile) {
              mutateAsync({
                id: instanceId,
                file: uploadFile,
              });
              setUploadFile(null);
            }
            closeModal();
            const note = () => {
              return <div>Campaign Added Successfully</div>;
            };
            notification({
              content: note,
            });

            editCampaign('status', responseStatus);

            if (shouldOpenTestModal) {
              shouldOpenTestModalAfterRefresh = true;
            }
            browserHistory.push(
              `/email-sgm/edit/${instanceId}?openTestModal=${shouldOpenTestModalAfterRefresh}`,
            );
          }
        },
      );
    }
  };

  useEffect(() => {
    if (location?.query?.openTestModal === 'true') {
      openTestModal();
    }
  }, [location?.search]);

  return (
    <>
      <SFYHeader pageTitle={getPageTitle(checkedEmailType)} />
      <EmailStepper
        activeStepIndex={1}
        campaignPathname={location.pathname}
        templateId={activeEmailDetails?.templateId}
      />
      <div className={styles['email-add-or-edit']}>
        <div ref={componentsRef} className={styles['email-add-or-edit__left']}>
          {isCampaignLoaded && (
            <EmailDetailComponents
              editCampaign={editCampaign}
              emailType={checkedEmailType}
              saveHandler={save}
              mode={mode}
            />
          )}
        </div>
        <div className={styles['email-add-or-edit__right']}>
          <EmailPreview
            saveHandler={save}
            openTestModal={openTestModal}
            mode={mode}
          />
        </div>
      </div>
    </>
  );
};

export default AddOrEdit;
