import React from 'react';
import { connect } from 'react-redux';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { queryDate } from '../system/date';

import Icon from './icon';
import { t } from '../system/ui';

import { getActiveUsers } from '../modules/emails/ajax';

class EmailCount extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.state = {
      count: 0,
    };
  }

  componentDidMount() {
    this.update(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.range !== this.props.range)
      if (nextProps.range[0] !== null && nextProps.range[1] !== null)
        this.update(nextProps);
  }

  update(props) {
    const boundaries = {
      start: queryDate(props.range[0]),
      end: queryDate(props.range[1]),
    };

    getActiveUsers(response => {
      this.setState({
        count: parseInt(response),
      });
    }, boundaries);
  }

  render() {
    return (
      <div className='section-email-count'>
        <span>
          {t('Reachable Email Users')}: <strong>{this.state.count}</strong>
        </span>
        <span
          className='stat-tooltip'
          data-tip='The total number of email users that Segmentify can actually send emails. This is the most up-to-date number.'
        >
          <Icon name='info' />
        </span>
        <ReactTooltip effect='solid' className='sg-tooltip' />
      </div>
    );
  }
}

const mapStateToProps = store => ({
  range: store.date.range,
});

export default connect(mapStateToProps)(EmailCount);
