import React from 'react';
import Currency from 'components/currency';
import { t } from 'system/ui';
import classNames from 'classnames';
import Icon from 'components/icon';
import Icons from 'components/icons';
import PropTypes from 'prop-types';
import ShowEyeIcon from "../../../../images/show_eye_icon.png";
import HideEyeIcon from "../../../../images/hide_eye_icon.png";
import Tooltip from "components/tooltip";

export function AddProductCard({replaceProductModalToggle}) {
  return (
    <div className='product-insight' style={{ cursor: 'pointer' }} onClick={() => replaceProductModalToggle(null)}>
      <div className='product-details add-product'>
        <Icons name='plusCircle' width='40' height='40'/>
        <div>Add Product</div>
      </div>
    </div>
  );
}

export default function PinWizardCard({
  product,
  buttons,
  replaceProductModalToggle,
  onHideClick,
  isHidden,
  fillHorizontal,
  removeProduct,
  orderNo,
}) {
  const renderedButtons = [];
  if (product) {
    buttons?.forEach(button => {
      renderedButtons.push(
        <button
          type='button'
          key={`${product.productId}-${button.label}`}
          onClick={button.onClick}
          className={`pin-wizard-card-button ${button.className}`}
        >
          {t(button.label)}
        </button>,
      );
    });
    if (removeProduct) {
      renderedButtons.push(
        <span
          className='bundle-product-remove'
          onClick={() => removeProduct()}
        >
        <Icons name='trashBin' color='#D31116' width={14} height={14} />
      </span>,
      );
    }
  }
  return product ? (
    <div className={classNames('product-insight', { fillHorizontal })}>
      <div className="product-details">
        {orderNo && <span className='product-order-no'>{orderNo}</span>}
        <span className="product-image-wrapper">
          <a href={product.url} target="_blank">
            <img
              src={product.image}
              className="product-image"
            />
          </a>
        </span>
        <div className="product-info">
          <h3 className="product-name">
            <a href={product.url} target="_blank">
              {product.name}
            </a>
            {onHideClick && (
              <span
                className='show-hide-icon-container'
                onClick={() => onHideClick(product)}
              >
              <Tooltip
                content={isHidden ? 'Show Product' : 'Hide Product'}
                placement='left'
                alignment='center'
              >
                <img
                  src={isHidden ? HideEyeIcon : ShowEyeIcon}
                  alt={isHidden ? 'hidden' : 'shown'}
                />
              </Tooltip>
            </span>
            )}
          </h3>
          <p className="product-price">
            {product.oldPrice && <span className="product-old-price">
              <Currency currency={product.oldPrice} />
            </span>}
            <span className="product-sale-price">
              <Currency currency={product.price} />
            </span>
          </p>
          <p
            className={classNames("product-stock", {
              "product-in-stock": product.inStock
            })}
          >
            <Icon name="check-circle" />
            {product.inStock ? t('In Stock') : t('Out of Stock')}
          </p>
          <p className='product-brand-category'>{product.brand ? <span><b>Brand: </b>{product.brand}</span> : null}</p>
          <p className='product-brand-category'>{fillHorizontal && <span><b>Product ID: </b>{product.productId}</span>}</p>
          <p className='product-brand-category'>{product.category?.[0] ? <span><b>Category: </b>{product.category[0]}</span> : null}</p>
          <p className='product-brand-category'>{product.relevancyScore ? <span><b>Score: </b>{product.relevancyScore}</span> : null}</p>
          <div className='pin-wizard-card-buttons-container'>
            {!isHidden && renderedButtons}
          </div>
        </div>
      </div>
      {isHidden && <div className='hidden-product-cover' />}
    </div>
  ) : (
    <AddProductCard replaceProductModalToggle={replaceProductModalToggle} />
  );
}

PinWizardCard.defaultProps = {
  orderNo: null,
  removeProduct: null,
};

PinWizardCard.propTypes = {
  product: PropTypes.objectOf(PropTypes.object).isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
  onHideClick: PropTypes.func.isRequired,
  replaceProductModalToggle: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired,
  fillHorizontal: PropTypes.bool.isRequired,
  orderNo: PropTypes.number,
  removeProduct: PropTypes.func,
};
