import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFacetList,
  toggleAddFacetModal,
  updateCampaignForm,
} from 'actions/faceted-search';
import { CardWrapper } from 'components/search/faceted-search/common';
import classnames from 'classnames';
import Icon from 'components/icon';
import { DEFAULT_FACETS } from 'reducers/faceted-search/initialState';

const AddFacetButton = () => {
  const dispatch = useDispatch();
  return (
    <button
      type="button"
      className="add-facet-button"
      onClick={() => dispatch(toggleAddFacetModal())}
    >
      <div className="add-facet-button-inner">
        <Icon name="plus" size="2x" />
        <span>Add Facet</span>
      </div>
    </button>
  );
};

const AddFacetModal = ({ instanceId }) => {
  const dispatch = useDispatch();
  const state = useSelector(s => s.facetedSearch);
  const facets = state.facetList;
  const [selectedFacets, setFacets] = useState([]);
  const onFacetSelect = param => {
    const updatedFacets = [...selectedFacets];
    const foundFacetIndex = selectedFacets.indexOf(param);
    if (foundFacetIndex > -1) {
      updatedFacets.splice(foundFacetIndex, 1);
    } else {
      updatedFacets.push(param);
    }
    setFacets(updatedFacets);
  };
  useEffect(() => {
    dispatch(getFacetList(instanceId));
  }, []);
  const filterFacets = facetList => {
    return facetList
      .map(facet => {
        const foundSame = state.campaignForm.desktopFacetDefs.find(
          df => df.property === facet,
        );
        return foundSame ? undefined : facet;
      })
      .filter(f => f !== undefined);
  };
  const onAddSelected = () => {
    if (selectedFacets.length === 0) return;
    const staticProps = {
      limit: -1,
      enabled: false,
      exclusions: [],
      viewMode: 'picklist',
      sort: 'COUNT',
    };
    const selectedFacetList = selectedFacets
      .map(facet => {
        const defaultObj = DEFAULT_FACETS.find(df => df.property === facet);
        const props = defaultObj || staticProps;
        return {
          ...props,
          property: facet,
        };
      })
      .sort();
    const updatedFacets = [
      ...state.campaignForm.desktopFacetDefs,
      ...selectedFacetList,
    ];

    dispatch(
      updateCampaignForm({
        desktopFacetDefs: updatedFacets,
        mobileFacetDefs: updatedFacets,
      }),
    );
    setFacets([]);
    dispatch(toggleAddFacetModal());
  };
  return (
    <div className="add-facet-modal">
      <CardWrapper
        title="Add Facet"
        description="Add the facets you want to show on the search result pages"
        id="add-facet-modal-card"
        extraClasses="add-facet-modal-card-wrapper"
      >
        {facets.loading && <h3>Loading...</h3>}
        {facets.loaded && (
          <div className="params-container">
            {filterFacets(facets.data).map(param => {
              const paramClass = classnames('param-wrapper', {
                'param-wrapper-checked': selectedFacets.includes(param),
              });
              const checkBoxClass = classnames('check-group-toggle', {
                checked: selectedFacets.includes(param),
              });
              return (
                <div
                  key={param}
                  className={paramClass}
                  onClick={() => onFacetSelect(param)}
                >
                  <span>{param}</span>
                  <span className={checkBoxClass} />
                </div>
              );
            })}
          </div>
        )}
        <div className="actions-container">
          <button
            disabled={facets.loading || facets.error}
            type="button"
            className="facet-action facet-action-add"
            onClick={onAddSelected}
          >
            Add Selected
          </button>
          <button
            type="button"
            className="facet-action facet-action-close"
            onClick={() => dispatch(toggleAddFacetModal())}
          >
            Close
          </button>
        </div>
      </CardWrapper>
    </div>
  );
};

AddFacetModal.propTypes = {
  instanceId: PropTypes.string.isRequired,
};

export { AddFacetButton, AddFacetModal };
