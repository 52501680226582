const cappingMailGroups = [
  { value: 'ABANDONED_CART', label: 'ABANDONED CART' },
  { value: 'LAST_VISIT_ALTERNATIVES', label: 'LAST VISIT ALTERNATIVES' },
  { value: 'LAST_VISIT_REMINDER', label: 'LAST VISIT REMINDER' },
  { value: 'NICE_TO_MEET_YOU', label: 'NICE TO MEET YOU' },
  { value: 'NEW_COMERS', label: 'NEW COMERS' },
  { value: 'TOP_SELLERS', label: 'TOP SELLERS' },
  { value: 'ORDER_FOLLOW_UP', label: 'ORDER FOLLOW UP' },
  { value: 'BIRTHDAY', label: 'BIRTHDAY' },
  { value: 'WE_MISSED_YOU', label: 'WE MISSED YOU' },
  { value: 'CHURN', label: 'CHURN' },
  { value: 'PRICE_DROP', label: 'PRICE DROP' },
  { value: 'FLY', label: 'FLY' },
  { value: 'RECOMMENDATION', label: 'BULK / SEGMENTED' },
];

const globalCappingMailGroups = [
  ...cappingMailGroups,
  { value: 'BACK_IN_STOCK', label: 'BACK IN STOCK' },
  { value: 'PUSH_NOTIFICATION', label: 'BULK / SEGMENTED' },
];

export { cappingMailGroups, globalCappingMailGroups };
