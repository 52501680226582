import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const NotificationRingIcon = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 25 25'
      fill='none'
    >
      <path d='M12.5 3.125C8.61677 3.125 5.46877 6.273 5.46877 10.1562V13.9084L3.96203 17.6789C3.86586 17.9195 3.8953 18.1922 4.04064 18.4069C4.186 18.6214 4.42831 18.75 4.6875 18.75H9.375C9.375 20.4819 10.7682 21.875 12.5 21.875C14.2318 21.875 15.625 20.4819 15.625 18.75H20.3125C20.5717 18.75 20.8141 18.6214 20.9594 18.4069C21.1047 18.1922 21.1342 17.9195 21.038 17.6789L19.5312 13.9084V10.1562C19.5312 6.273 16.3833 3.125 12.5 3.125ZM14.0625 18.75C14.0625 19.6189 13.3689 20.3125 12.5 20.3125C11.6311 20.3125 10.9375 19.6189 10.9375 18.75H14.0625ZM7.03127 10.1562C7.03127 7.13594 9.4797 4.6875 12.5 4.6875C15.5203 4.6875 17.9687 7.13594 17.9687 10.1562V14.0587C17.9687 14.1581 17.9877 14.2565 18.0245 14.3487L19.1589 17.1875H5.84103L6.97548 14.3487C7.01233 14.2565 7.03127 14.1581 7.03127 14.0587V10.1562Z' />
    </svg>
  );
};

NotificationRingIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

NotificationRingIcon.defaultProps = {
  width: '25px',
  height: '25px',
};

export default NotificationRingIcon;
