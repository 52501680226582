import React, { useState } from 'react';
import { SFYTextWithIcon } from '../../../../components/sfy-components/module-exports';

const PreviewText = ({
  component,
  editCampaign,
  activeEmailDetails,
  readOnly,
}) => {
  const [mailContent, setMailContent] = useState(
    activeEmailDetails.params.mailContent,
  );
  const editPreviewText = (fieldKey, fieldValue) => {
    editCampaign('params', {
      ...activeEmailDetails.params,
      [fieldKey]: fieldValue,
    });
    setMailContent(fieldValue);
  };

  return (
    <SFYTextWithIcon
      key={component.name}
      cardTitle={component.title}
      cardName={component.name}
      placeholder={component.placeholder}
      editCampaign={editPreviewText}
      fieldValue={mailContent}
      readOnly={readOnly}
    />
  );
};

export default PreviewText;
