import React, { useContext, useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import {
  SFYCardHeader,
  SFYChildWrapper,
  SFYHeader,
  SFYImageInput,
  SFYRelatedProducts,
  SFYRightBar,
} from '../../../../components/sfy-components/module-exports';
import {
  getDynamicBreadCrumbOptions,
  relatedProductAttributes,
  campaignActionButtonConstant,
} from '../constants/constants';
import { Context as PromotionContext } from '../store/promotionProvider';
import { uiActions } from '../../../../actions';
import PromotionServices from '../services/promotionServices';
import {
  isValidUrl,
  mappedProductArrayToSelectFormat,
  selectFormatToStringArray,
  stringArrayToSelectFormat,
} from '../../../../components/sfy-components/utils';
import { t } from '../../../../system/ui';

/**
 * @name AddOrEdit
 * @description Add & Edit Component of Promotion List
 * @param params {{mode, id}} Mode & Id of promotion. Mode can be 'add' or 'edit'. ID is the id of promotion in edit mode.
 * @return {JSX.Element} - Add Related Products component
 * @constructor
 */

const AddOrEdit = ({ params }) => {
  const {
    state: { promotion },
    setPromotion,
    resetPromotion,
  } = useContext(PromotionContext);

  const [breadCrumbName, setBreadCrumbName] = useState('Create New');
  const { mode, id } = params;

  const { isLoaded, isLoading, showNotification } = uiActions;

  const validate = () => {
    const { url, code } = promotion;

    const necessaryFields = ['title', 'image', 'code', 'url'];
    const isFilledAll = Object.keys(promotion).every(key => {
      if (necessaryFields.includes(key)) {
        return promotion[key] !== '' && promotion[key] !== undefined;
      }
      return true;
    });

    if (!isFilledAll) return t('Please fill all the required fields.');

    const specialCharacters = ['`', '%', '&', '+', ',', '|', '/', '?'];

    const testIfIncludesSpecialChars = specialCharacters.some(el =>
      code.includes(el),
    );

    if (testIfIncludesSpecialChars)
      return t('Code cannot contain any of these , `, %, &, +, |, /, ?');

    const validUrl = isValidUrl(url);

    if (!validUrl) return t('Please enter a valid URL.');

    if (new Date(promotion.startDate) - new Date(promotion.endDate) > 0)
      return t('End date must before start date');

    return true;
  };

  const promotionParamsSetter = (value, type) => {
    setPromotion({ ...promotion, [type]: value });
  };

  // NOTE: This function is doing the same thing as the one above. The difference is
  // that SFYRightBar component is calling (type, value) instead of (value, type).
  // and coupled with some other components, so it is hard to change the order.
  // Also, the other components using promotionParamsSetter using (value, type) order.
  // so, I created this function to use in SFYRightBar component.
  const promotionParamsSetterWithType = (type, value) => {
    setPromotion({ ...promotion, [type]: value });
  };

  const save = () => {
    const validation = validate();
    if (validation !== true) {
      const content = () => <p>{validation}</p>;
      showNotification({
        content,
        className: 'notification-fail',
      });
      return;
    }
    if (mode === 'add') {
      isLoading();
      PromotionServices.create({
        body: {
          ...promotion,
          brands: selectFormatToStringArray(promotion?.brands),
          categories: selectFormatToStringArray(promotion?.categories),
          labels: selectFormatToStringArray(promotion?.labels),
          productIds: selectFormatToStringArray(promotion?.mappedProducts),
        },
      }).then(response => {
        isLoaded();
        if (response) {
          const content = () => (
            <p>{t('Promotion is successfully created.')}</p>
          );
          showNotification({
            content,
            className: 'notification-success',
          });
          browserHistory.push(
            `/recommendations/promotion/edit/${decodeURIComponent(
              response.code,
            )}`,
          );
          resetPromotion();
        }
      });
    } else {
      isLoading();
      PromotionServices.update({
        id,
        body: {
          ...promotion,
          brands: selectFormatToStringArray(promotion?.brands),
          categories: selectFormatToStringArray(promotion?.categories),
          labels: selectFormatToStringArray(promotion?.labels),
          productIds: selectFormatToStringArray(promotion?.mappedProducts),
        },
      }).then(response => {
        isLoaded();
        if (response) {
          const content = () => <p>{t('Campaign is successfully updated.')}</p>;
          showNotification({
            content,
            className: 'notification-success',
          });
          setBreadCrumbName(response.title);
        }
      });
    }
  };

  const campaignActionButtonConstantWithFunctions = campaignActionButtonConstant.map(
    actionButton => {
      let actionButtonWithFunction;
      switch (actionButton.type) {
        case 'save':
          actionButtonWithFunction = save;
          break;
        default:
          actionButtonWithFunction = () => {};
          break;
      }
      return {
        ...actionButton,
        action: actionButtonWithFunction,
      };
    },
  );

  useEffect(() => {
    if (mode === 'edit') {
      isLoading();
      PromotionServices.get({ id: encodeURIComponent(id) }).then(response => {
        setPromotion({
          ...response,
          code: decodeURIComponent(response.code),
          brands: stringArrayToSelectFormat(response?.brands),
          categories: stringArrayToSelectFormat(response?.categories),
          labels: stringArrayToSelectFormat(response?.labels),
          mappedProducts: mappedProductArrayToSelectFormat(
            response?.mappedProducts,
          ),
        });
        isLoaded();
        setBreadCrumbName(response.title);
      });
      return;
    }

    resetPromotion();
    promotionParamsSetter(new Date().getTime(), 'startDate');
  }, [mode]);

  useEffect(() => {
    return () => {
      resetPromotion();
    };
  }, []);

  return (
    <>
      <SFYChildWrapper type='sfy-widget-child'>
        <SFYHeader
          pageTitle='Add or Edit'
          breadCrumbOptions={getDynamicBreadCrumbOptions(breadCrumbName)}
        />
        <SFYCardHeader
          cardTitle='Banner Title*'
          stateSetter={promotionParamsSetter}
          state={promotion.title}
          placeholder='Type Here..'
          required
          promotionType='title'
          titleColor='promo-title-color'
        />
        <SFYImageInput
          key='promotion-web-image'
          title='Web Image*'
          iconName='pc'
          fetchedImage={promotion.image}
          stateSetter={promotionParamsSetter}
          type='web'
          isRequired
          color='#E0D4FF'
        />
        <SFYImageInput
          key='promotion-mobile-image'
          title='Mobile Image'
          iconName='mobile'
          fetchedImage={promotion.mImage}
          stateSetter={promotionParamsSetter}
          type='mobil'
        />
        <SFYCardHeader
          cardTitle='Code*'
          stateSetter={promotionParamsSetter}
          state={promotion.code}
          readOnly={mode === 'edit'}
          placeholder='Type Here..'
          setMargin
          promotionType='code'
          required
          titleColor='promo-title-color'
        />
        <SFYCardHeader
          cardTitle='URL of the Banner*'
          stateSetter={promotionParamsSetter}
          state={promotion.url}
          placeholder='Type Here..'
          setMargin
          iconName='pc'
          promotionType='url'
          required
          titleColor='promo-title-color'
        />
        <SFYCardHeader
          cardTitle='Mobile URL of the Banner'
          stateSetter={promotionParamsSetter}
          state={promotion.mUrl}
          placeholder='Type Here..'
          setMargin
          iconName='mobile'
          promotionType='mUrl'
          titleColor='promo-title-color'
        />
        <SFYRelatedProducts
          headerTitle='Add Related Product'
          description='You can add related products based on id, category, banner or label.'
          externalAttributes={relatedProductAttributes}
          data={{
            mappedProducts: promotion.mappedProducts,
            categories: promotion.categories,
            brands: promotion.brands,
            labels: promotion.labels,
          }}
          setAttributes={promotionParamsSetter}
        />
      </SFYChildWrapper>
      <SFYRightBar
        campaignActionButtonConstant={campaignActionButtonConstantWithFunctions}
        startDate={new Date(promotion.startDate).getTime()}
        endDate={new Date(promotion.endDate).getTime()}
        campaignSetter={promotionParamsSetterWithType}
        readOnly={mode === 'edit'}
      />
    </>
  );
};
export default AddOrEdit;
