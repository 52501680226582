import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateCampaignForm } from 'actions/faceted-search';

const ContentInput = ({ content }) => {
  const state = useSelector(s => s.facetedSearch);
  const dispatch = useDispatch();
  const customContents = [...state.campaignForm.customContents];
  return (
    <div className="item item-field rule-set-keyword">
      <input
        name={`faceted-content-input-${content.key}`}
        type="text"
        className="one-whole criteria-field"
        onBlur={e => {
          const index = customContents.findIndex(c => c.key === content.key);
          customContents[index] = {
            ...customContents[index],
            key: e.target.value,
          };
          dispatch(updateCampaignForm({ customContents }));
        }}
        defaultValue={content.key}
      />
    </div>
  );
};

ContentInput.propTypes = {
  content: PropTypes.instanceOf(Object).isRequired,
};

export default ContentInput;
