import React from "react";

import LoginHeader from "../components/login/login.header"
import ForgotPasswordForm from "../components/login/forgot.password.form"
import { connect } from "react-redux";
import { t } from "../system/ui";
import { setTitle } from "../system/document";

class ForgotPassword extends React.Component {
  componentDidMount() {
    setTitle(t("Forgot Password"));
  }

  render() {
    return (
      <div id="wrapper" className="page-wrap login-wrap">
        <LoginHeader 
          loginButton={false}
          bookButton={true}
        />
        <ForgotPasswordForm />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  language: store.ui.language
});

export default connect(mapStateToProps)(ForgotPassword);
