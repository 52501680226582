import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import SearchController from '../../components/pagination/search-controller/SearchController';
import { EmailTemplatesNavigation } from './components/navigation-items/EmailTemplatesNavigation';
import EmailTemplateList from './components/email-template-list/EmailTemplateList';
import SFYPagination from '../../components/pagination/SFYPagination';
import { SFYHeader } from '../../components/sfy-components/module-exports';
import { Context as PaginationContext } from '../../components/pagination/store/paginationProvider';
import { t } from '../../system/ui';
import { Context as EmailContext } from './store/EmailProvider';
import {
  getEmailTemplates,
  searchEmailTemplates,
} from '../../modules/emails/ajax';
import { isLoading, isLoaded } from '../../actions/ui';
import classes from './EmailTemplates.module.scss';
import { setTitle } from '../../system/document';

/**
 *  @description EmailTemplates view under email/templates
 * @returns {React.Component} - EmailTemplates component
 */

const EmailTemplatePageHeader = ({
  setIsCollection,
  isCollection,
  isCollectionActive,
  elements,
}) => {
  return (
    <div className={classes['email-template-page-header']}>
      <EmailTemplateTabs
        setIsCollection={setIsCollection}
        isCollection={isCollection}
        isCollectionActive={isCollectionActive}
      />
      <EmailTemplatesNavigation elements={elements} />
    </div>
  );
};
const EmailTemplateTabs = ({
  setIsCollection,
  isCollection,
  isCollectionActive,
}) => {
  const { setQueryString } = useContext(EmailContext);
  const selectTab = val => {
    setIsCollection(val);
    setQueryString('');
  };

  return (
    <div className={classes['email-template-tabs']}>
      <button
        type='button'
        className={
          !isCollection
            ? classes['email-template-tabs__selected']
            : classes['email-template-tabs__btn']
        }
        onClick={() => selectTab(false)}
      >
        {t('My Templates')}
      </button>
      {isCollectionActive && (
        <button
          type='button'
          className={
            isCollection
              ? classes['email-template-tabs__selected']
              : classes['email-template-tabs__btn']
          }
          onClick={() => selectTab(true)}
        >
          {t('Collections')}
        </button>
      )}
    </div>
  );
};

const EmailTemplates = ({ location }) => {
  const [templates, setTemplates] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [isCollection, setIsCollection] = useState(false);

  const userRole = useSelector(
    ({ switchedUser, user }) =>
      switchedUser?.switchedUser?.userRole || user?.user?.userRole,
  );

  const {
    state: { pagination, searchQueryString },
    paginationSetter,
    searchQuerySetter,
    campaignPathnameSetter,
  } = useContext(PaginationContext);

  const {
    state: { queryString },
    setQueryString,
  } = useContext(EmailContext);

  const getData = () => {
    isLoading();
    getEmailTemplates(
      response => {
        setTemplates(response?.customMailTemplateList);
        paginationSetter({
          pageNumber: response?.pageNo,
          payloadSize: 100,
          totalRecords: response?.totalMailTemplateCount,
          isInfinite: true,
        });
        isLoaded();
      },
      true,
      !!isCollection,
      1,
      100,
    );
  };

  const navigationProps = [
    {
      priority: 1,
      key: 'search-templates',
      element: (
        <SearchController state={queryString} stateSetter={setQueryString} />
      ),
    },
  ];

  const getTemplates = () => {
    if (!('isInfinite' in pagination) || pagination.isInfinite) return;

    isLoading();
    getEmailTemplates(
      response => {
        setTemplates(response?.customMailTemplateList);
        paginationSetter({
          pageNumber: response?.pageNo,
          payloadSize: pagination?.payloadSize,
          totalRecords: response?.totalMailTemplateCount,
        });
        isLoaded();
      },
      true,
      !!isCollection,
      pagination.pageNumber,
      pagination.payloadSize,
    );
  };

  const filterTemplates = () => {
    if (queryString !== '') {
      isLoading();
      searchEmailTemplates(queryString, !!isCollection, response => {
        setTemplates(response?.customMailTemplateList);
        isLoaded();
      });
    } else {
      getData();
    }
  };

  const showPaginationOptions = () => {
    const readOnlyWithCollection = () => {
      return userRole === 'READ_ONLY' && isCollection;
    };
    const readOnlyWithNoCollection = () => {
      return userRole === 'READ_ONLY' && !isCollection;
    };

    const noReadOnlyWithCollection = () => {
      return userRole !== 'READ_ONLY' && isCollection;
    };

    if (queryString === '') {
      if (
        readOnlyWithCollection() ||
        readOnlyWithNoCollection() ||
        noReadOnlyWithCollection()
      ) {
        return 'noCreateWithPagination';
      }
      return 'createWithPagination';
    } else {
      if (
        readOnlyWithCollection() ||
        readOnlyWithNoCollection() ||
        noReadOnlyWithCollection()
      ) {
        return 'noCreateWithNoPagination';
      }
      return 'createWithNoPagination';
    }
  };

  useEffect(() => {
    getData();
    setQueryString('');
    campaignPathnameSetter(location?.state?.campaignPathname);
    setIsCreate(!!location?.state?.campaignPathname?.includes('create'));
  }, []);

  useEffect(() => {
    setTitle(`${t('Select Email Templates')}`);
    filterTemplates();
  }, [queryString]);

  useEffect(() => {
    if (!('isInfinite' in pagination) || pagination.isInfinite) return;

    getTemplates();
  }, [pagination.pageNumber, pagination.payloadSize]);

  useEffect(() => {
    getData();
  }, [isCollection]);

  return (
    <>
      <SFYHeader pageTitle={t('Select Email Templates')} />
      <EmailTemplatePageHeader
        setIsCollection={setIsCollection}
        isCollection={isCollection}
        isCollectionActive={!isCreate}
        elements={navigationProps}
      />
      {showPaginationOptions() === 'noCreateWithPagination' && (
        <SFYPagination
          location={location}
          pagination={pagination}
          stateSetter={searchQuerySetter}
          state={searchQueryString}
          paginationSetter={paginationSetter}
          navigationController={false}
          searchController={false}
          analyticsType='templates'
        />
      )}
      {showPaginationOptions() === 'createWithPagination' && (
        <SFYPagination
          location={location}
          pagination={pagination}
          linkToRoute='/email/templates/create'
          stateSetter={searchQuerySetter}
          state={searchQueryString}
          paginationSetter={paginationSetter}
          navigationController={!isCreate}
          searchController={false}
          analyticsType='templates'
          text='Create a New Template'
        />
      )}
      {showPaginationOptions() === 'createWithNoPagination' && (
        <SFYPagination
          location={location}
          pagination={pagination}
          linkToRoute='/email/templates/create'
          stateSetter={searchQuerySetter}
          state={searchQueryString}
          paginationController={false}
          paginationSetter={paginationSetter}
          navigationController={!isCreate}
          searchController={false}
          analyticsType='templates'
          text='Create a New Template'
        />
      )}
      <EmailTemplateList
        customTemplates={templates}
        isCollection={isCollection}
        isCollectionActive={!isCreate}
        setTemplatesList={setTemplates}
        campaignPathname={location?.state?.campaignPathname || ''}
      />
    </>
  );
};

export default EmailTemplates;
