import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PersonalisedAds = ({
  width = '24px',
  height = '12px',
  color = '#47607b',
}) => {
  const [state, setState] = useState({
    width: '',
    height: '',
    color: '',
  });

  const setStyles = () => {
    setState({
      width,
      height,
      color,
    });
  };

  useEffect(() => {
    setStyles();
  }, [width, height, color]);

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='12'
      fill={state.color}
    >
      <path
        d='M18.261 1.109c-.244-.101-.519.023-.613.278s.028.542.273.643c1.247.515 2.208 1.24 2.892 2.175s1.101 2.092 1.248 3.48c.029.272.263.471.523.444s.448-.269.419-.542c-.165-1.562-.643-2.892-1.439-3.979s-1.9-1.92-3.303-2.5zm.154 2.38c-.245-.101-.519.024-.613.278s.029.542.273.643c.485.2.894.527 1.233.99a3.03 3.03 0 0 1 .59 1.5c.028.272.263.471.523.445s.448-.269.42-.541c-.076-.724-.338-1.393-.782-1.999a3.83 3.83 0 0 0-1.644-1.315z'
        stroke={state.color}
        strokeWidth='.3'
      />
      <path d='M4.673 10.514H2.138l-.406 1.2H0l2.458-6.792h1.916l2.458 6.792H5.08l-.406-1.2zm-.426-1.277L3.406 6.75l-.832 2.487h1.674zm3.003-.232c0-.555.103-1.042.31-1.461.213-.419.5-.742.861-.968a2.23 2.23 0 0 1 1.209-.339 2.09 2.09 0 0 1 .968.223 1.76 1.76 0 0 1 .697.6V4.554h1.655v7.16h-1.655v-.774a1.68 1.68 0 0 1-.668.619c-.284.155-.616.232-.997.232a2.23 2.23 0 0 1-1.209-.339c-.361-.232-.648-.558-.861-.977-.206-.426-.31-.916-.31-1.471zm4.044.01c0-.413-.116-.739-.348-.977a1.1 1.1 0 0 0-.832-.358 1.13 1.13 0 0 0-.842.358c-.226.232-.339.555-.339.968s.113.742.339.987a1.13 1.13 0 0 0 .842.358 1.1 1.1 0 0 0 .832-.358c.232-.239.348-.564.348-.977zm5.048 2.776c-.471 0-.89-.081-1.258-.242s-.658-.381-.871-.658a1.77 1.77 0 0 1-.358-.948h1.635a.63.63 0 0 0 .261.455c.155.116.345.174.571.174.206 0 .364-.039.474-.116.116-.084.174-.19.174-.319 0-.155-.081-.268-.242-.339-.161-.077-.422-.161-.784-.252-.387-.09-.709-.184-.967-.281-.258-.103-.481-.261-.668-.474-.187-.219-.281-.513-.281-.88 0-.31.084-.59.252-.842a1.75 1.75 0 0 1 .755-.61c.335-.148.732-.223 1.19-.223.677 0 1.209.168 1.597.503s.619.78.677 1.335H16.97a.65.65 0 0 0-.252-.445c-.136-.11-.316-.164-.542-.164-.194 0-.342.039-.445.116-.103.071-.155.171-.155.3 0 .155.081.271.242.348.168.077.426.155.774.232.4.103.726.206.977.31a1.59 1.59 0 0 1 .658.484c.194.219.294.516.3.89a1.42 1.42 0 0 1-.271.851c-.174.245-.429.439-.764.581-.329.142-.713.213-1.151.213z' />
    </svg>
  );
};

PersonalisedAds.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

PersonalisedAds.defaultProps = {
  width: '1.5rem',
  height: '0.75rem',
  color: '#47607b',
};

export default PersonalisedAds;
