import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import classNames from 'classnames';
import TrendifyInsightsFiltersNew from 'components/filters/TrendifyInsightsFiltersNew/trendify-insights-filters-new';

import { t } from '../system/ui';
import { queryDate } from '../system/date';
import { clone } from '../system/object';
import { setTitle } from '../system/document';
import { ExternalAppUtility } from '../external';
import {
  dateActions,
  filterActions,
  trendifyActions,
  uiActions,
} from '../actions';

import {
  getApiKey,
  getCustomInsightColumns,
  getCustomTrendifyColumns,
  hasModulePrivilege,
  hasTrendify,
  isInsightsCountDefined,
} from '../modules/auth/user';
import { getBrands, getCategories } from '../modules/account/ajax';
import { insightsQuery } from '../modules/insights/data';
import { getInsightEndpoint, getInsights } from '../modules/insights/ajax';
import { trendifyIntegrationInfo } from '../modules/trendify/page';
import getInsightsFields from '../modules/transform/insightsFieldsTransform';
import Export from '../components/export';

import Filters from '../components/filters/filters';
import Products from '../components/insights/products';
import RelatedProducts from '../components/insights/products-related';
import InsightPlaceHolder from '../components/insights/widgets/placeholder';
import { dateUpdatedQuery, getDatesSchema } from '../modules/trendify/utils';

const sortInsightsById = insights => {
  const result = [];
  // ids are static and their order is coming from SFY-12492
  const resultingIds = [3, 11, 1, 9, 10, 15, 2, 4, 6, 7, 8, 12, 13, 14, 5];
  resultingIds.forEach(item => {
    const pushItem = insights.find(insItem => insItem.id === item);
    result.push(pushItem);
  });
  return result;
};

const hasCommercial = insightsState => {
  const referenceIds = [3, 11, 1, 9, 10, 15, 2, 4, 6, 7, 8];
  return referenceIds.some(id => insightsState[id] > 0);
};

const hasChannel = insightsState => {
  const referenceIds = [12, 13, 14];
  return referenceIds.some(id => insightsState[id] > 0);
};

const hasComplementary = insightsState => {
  const referenceIds = [5];
  return referenceIds.some(id => insightsState[id] > 0);
};

class Insights extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      insights: [],
      query: clone(insightsQuery),
      showTrendifyFilters: false,
      childrenProducts: 0,
      insightsWithProductCount: {},
    };

    this.getDetails = this.getDetails.bind(this);
    this.refresh = this.refresh.bind(this);
    this.reset = this.reset.bind(this);
    this.setInsightsCount = this.setInsightsCount.bind(this);
    this.productsFn = this.productsFn.bind(this);
    this.hasProducts = this.hasProducts.bind(this);
  }

  componentDidMount() {
    setTitle(t('Segmentify Analytics'));
    uiActions.hideBreadcrumb();
    uiActions.isLoading();

    filterActions.filtersReset();
    dateActions.updateRangeAlias('lastSevenDays');

    this.getDetails(this.props.user);
    if (isInsightsCountDefined()) {
      this.setInsightsCount();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      this.getDetails(newProps.user, newProps.range);
    }

    if (!this.props.update && newProps.update === true && !newProps.showModal) {
      this.refresh(newProps);
    }

    if (newProps.reset === true) {
      this.reset();
    }
  }

  setInsightsCount() {
    const newQuery = clone(insightsQuery);
    newQuery.items = isInsightsCountDefined();
    this.setState({ query: newQuery });
  }

  getDetails(user, range) {
    trendifyIntegrationInfo(user, true);
    if (range) {
      this.setState({
        isLoading: true,
      });
      getInsights(response => {
        if (response.status === 0 || response.status > 201) {
          this.setState({
            isLoading: false,
            insights: [],
          });
          uiActions.isLoaded();

          return;
        }

        const actualResponse = [];
        response.forEach(info => {
          const hasId = actualResponse.filter(actualEl => {
            return actualEl.id === info.id;
          });
          if (hasId.length < 1) {
            actualResponse.push(info);
          }
        });

        const query = {
          ...clone(this.state.query),
          startDate: queryDate(range[0]),
          endDate: queryDate(range[1]),
        };

        this.setState({
          insights: sortInsightsById(actualResponse) || [],
          query,
          insightCustoms: getCustomInsightColumns(),
        });

        uiActions.isLoaded();

        this.setState({
          isLoading: false,
        });
      });
    } else {
      this.setState({
        isLoading: true,
      });

      getInsights(response => {
        if (response.status === 0 || response.status > 201) {
          this.setState({
            isLoading: false,
            insights: [],
          });
          uiActions.isLoaded();

          return;
        }

        const actualResponse = [];
        response.forEach(info => {
          const hasId = actualResponse.filter(actualEl => {
            return actualEl.id === info.id;
          });
          if (hasId.length < 1) {
            actualResponse.push(info);
          }
        });
        this.setState({
          insights: sortInsightsById(actualResponse) || [],
        });

        this.setState({
          isLoading: false,
        });
        uiActions.isLoaded();
      });
    }

    getCategories(response => {
      trendifyActions.updateCategories(response);
    });

    getBrands(response => {
      trendifyActions.updateBrands(response);
    });
  }

  exportAll = e => {
    e.preventDefault();
    const content = () => (
      <Export
        pdfExport
        name={t('All Insight Reports')}
        exportAll={this.state.query}
        range={this.props.range}
      />
    );

    uiActions.openModal({ title: t('Export All'), content });
  };

  toggleAnalyticsFilters = () => {
    this.setState({
      showTrendifyFilters: !this.state.showTrendifyFilters,
    });
  };

  refresh(newProps) {
    const query = clone(insightsQuery);

    query.category = [];

    const selectedCategory = this.refs.pageFilters.querySelector(
      '[name = "productCategory"]',
    ).value;

    const selectedBrand = this.refs.pageFilters.querySelector(
      '[name = "productBrand"]',
    ).value;

    if (isInsightsCountDefined()) {
      query.items = isInsightsCountDefined();
    }

    query.category = selectedCategory;

    query.brand = selectedBrand;

    query.device = this.refs.pageFilters.querySelector(
      '[name=visitorDevice]',
    ).value;

    query.productId = this.refs.pageFilters.querySelector(
      'input[name="productName"]',
    ).value;

    const maxPrice = this.refs.pageFilters.querySelector('#maxPrice').value;
    const minPrice = this.refs.pageFilters.querySelector('#minPrice').value;

    query.minPrice = Number(minPrice);
    query.maxPrice = Number(maxPrice);

    if (maxPrice === '0' || maxPrice === 0) {
      query.maxPrice = insightsQuery.maxPrice;
    }
    if (minPrice === '0' || minPrice === 0) {
      query.minPrice = insightsQuery.minPrice;
    }

    const stock = this.refs.pageFilters.querySelector('[name=productStock]')
      .value;

    if (stock !== 'all') {
      query.stock = stock;
    }

    const { range } = newProps;

    query.startDate = queryDate(range[0]);
    query.endDate = queryDate(range[1]);

    // TODO: this is a workaround, filter component should return the required query
    if (getCustomTrendifyColumns()) {
      const custom = getCustomTrendifyColumns()
        .filter(field => field.filterable)
        .reduce((result, field) => {
          if (
            field.dataType === 'string' &&
            this.refs.pageFilters.querySelector(`[name=${field.field}]`).value
          ) {
            const { value } = this.refs.pageFilters.querySelector(
              `[name=${field.field}]`,
            );
            return { ...result, [field.field]: value };
          }
          if (field.dataType === 'number' || field.dataType === 'currency') {
            const minMaxField = {};
            if (
              this.refs.pageFilters.querySelector(`#${field.field}min`)
                .value !== ''
            ) {
              minMaxField[
                `${field.field}.min`
              ] = this.refs.pageFilters.querySelector(
                `#${field.field}min`,
              ).value;
            }
            if (
              this.refs.pageFilters.querySelector(`#${field.field}max`)
                .value !== ''
            ) {
              minMaxField[
                `${field.field}.max`
              ] = this.refs.pageFilters.querySelector(
                `#${field.field}max`,
              ).value;
            }
            return {
              ...result,
              ...minMaxField,
            };
          }
          if (
            field.dataType === 'boolean' &&
            this.refs.pageFilters.querySelector(`[name=${field.field}]`)
              .value !== 'all'
          ) {
            const { value } = this.refs.pageFilters.querySelector(
              `[name=${field.field}]`,
            );

            return { ...result, [field.field]: value };
          }
          return result;
        }, {});

      if (Object.keys(custom).length) query.custom = custom;
    }

    const {
      secondStartDate,
      secondEndDate,
      firstStartDate,
      firstEndDate,
    } = getDatesSchema(range, newProps.rangeAlias);
    query.secondStartDate = queryDate(secondStartDate);
    query.secondEndDate = queryDate(secondEndDate);
    query.firstStartDate = queryDate(firstStartDate);
    query.firstEndDate = queryDate(firstEndDate);

    this.setState({ query });

    filterActions.filtersUpdated();
  }

  reset() {
    const query = dateUpdatedQuery({
      range: this.props.range,
      rangeAlias: this.props.rangeAlias,
      analyticsQuery: insightsQuery,
    });

    if (isInsightsCountDefined()) {
      query.items = isInsightsCountDefined();
    }

    this.setState({ query });
  }

  productsFn(prodsNumber) {
    if (prodsNumber === 0) {
      this.setState(prevState => {
        return {
          ...prevState,
          childrenProducts: 0,
        };
      });
    }
    this.setState(prevState => {
      return {
        ...prevState,
        childrenProducts: prevState.childrenProducts + prodsNumber,
      };
    });
  }

  hasProducts(id, productCount) {
    this.setState(prevState => ({
      insightsWithProductCount: {
        ...prevState.insightsWithProductCount,
        [id]: productCount,
      },
    }));
  }

  render() {
    if (
      this.props.user.displayName &&
      !hasModulePrivilege(false, 'TRENDUSER')
    ) {
      browserHistory.push('/');
    }

    return (
      <div className='segmentify-analytics segmentify-analytics--trendify trendify-insights'>
        {!hasTrendify() && (
          <h2 className='page-title'>
            <span className='for-screenreader-only'>{t('Trendify')}</span>
          </h2>
        )}
        {hasTrendify() && hasModulePrivilege(false, 'TRENDUSER') && (
          <div
            ref='pageFilters'
            className={classNames({
              'externalpage-filters': ExternalAppUtility.isHeaderHidden(),
            })}
          >
            <Filters
              limitRange='months'
              visibleUpdate={false}
              toggleAnalyticsFilters={this.toggleAnalyticsFilters.bind(this)}
              analyticsFilters
              exportFile={this.exportAll}
            >
              <TrendifyInsightsFiltersNew
                showTrendifyFilters={this.state.showTrendifyFilters}
                toggleAnalyticsFilters={this.toggleAnalyticsFilters.bind(this)}
              />
            </Filters>
          </div>
        )}
        {hasTrendify() ? (
          <div className='export-content-wrapper'>
            {this.state.isLoading && <InsightPlaceHolder />}
            {!this.state.isLoading &&
              this.state.insights.map((insight, index) => {
                if (insight.layout === 'LIST') {
                  return (
                    <>
                      {index === 0 &&
                        hasCommercial(this.state.insightsWithProductCount) && (
                          <h3 className='header'>COMMERCIAL INSIGHTS</h3>
                        )}
                      {index === 11 &&
                        hasChannel(this.state.insightsWithProductCount) && (
                          <h3 className='header'>CHANNEL INSIGHTS</h3>
                        )}
                      {index === 14 &&
                        hasComplementary(
                          this.state.insightsWithProductCount,
                        ) && <h3 className='header'>COMPLEMENTARY INSIGHTS</h3>}
                      <Products
                        {...insight}
                        query={this.state.query}
                        key={insight.id}
                        productsFn={this.productsFn}
                        isWeekSelect={
                          getInsightsFields(insight.name).indexOf('date') > -1
                        }
                        hasProducts={this.hasProducts}
                        endpoint={`${getInsightEndpoint() +
                          insight.id}?apiKey=${getApiKey()}`}
                      />
                    </>
                  );
                }

                if (insight.layout === 'RELATED') {
                  return (
                    <>
                      {index === 0 &&
                        hasCommercial(this.state.insightsWithProductCount) && (
                          <h3 className='header'>COMMERCIAL INSIGHTS</h3>
                        )}
                      {index === 11 &&
                        hasChannel(this.state.insightsWithProductCount) && (
                          <h3 className='header'>CHANNEL INSIGHTS</h3>
                        )}
                      {index === 14 &&
                        hasComplementary(
                          this.state.insightsWithProductCount,
                        ) && <h3 className='header'>COMPLEMENTARY INSIGHTS</h3>}
                      <RelatedProducts
                        {...insight}
                        query={this.state.query}
                        key={insight.id}
                        hasProducts={this.hasProducts}
                        endpoint={`${getInsightEndpoint() +
                          insight.id}?apiKey=${getApiKey()}`}
                      />
                    </>
                  );
                }
              })}
          </div>
        ) : (
          <div className='promo promo--centered'>
            <p className='promo-text'>{t('TrendifyPromo')}</p>
            <a
              href='https://www.segmentify.com/solutions/real-time-conversion-analytics/#trendify'
              target='_blank'
              className='secondary-action'
              rel='noreferrer'
            >
              {t('Discover')}
            </a>
          </div>
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  ui: store.ui.ui,
  update: store.filters.update,
  reset: store.filters.reset,
  showModal: store.modal.showModal,
});

export default connect(mapStatesToProps)(Insights);
