import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import Dialog from '../components/dialog';
import Modal from '../components/modal';
import Notification from '../components/notification';
import ScrollUpButton from '../components/scrollUp';

// eslint-disable-next-line react/prefer-stateless-function
class LoginController extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    const pageClass = classNames(
      'page-wrap',
      'login-wrap',
      this.props.pageClass,
      {
        'is-hidden': false,
        'has-overlay-window': this.props.showDialog || this.props.showModal,
        firefox: navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
      },
    );

    return (
      <div className={pageClass}>
        {this.props.children}

        {this.props.showDialog && <Dialog {...this.props.dialog} />}

        {this.props.showModal && <Modal {...this.props.modal} />}

        {this.props.showNotification && (
          <Notification {...this.props.notification} />
        )}

        <ScrollUpButton />
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  pageClass: store.ui.pageClass,
  dialog: store.dialog.dialog,
  showDialog: store.dialog.showDialog,
  modal: store.modal.modal,
  showModal: store.modal.showModal,
  notification: store.notification.notification,
  showNotification: store.notification.showNotification,
});

export default connect(MapStatesToProps)(LoginController);
