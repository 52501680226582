/**
 * @author Bilal Cinarli
 */

"use strict";
import { getCampaignPage } from "../campaigns/data";

const filterByType = (list, type, targetNode) => {
  let filtered = [];

  list.forEach(item => {
    if (searchKeys(item, targetNode) === type) {
      filtered.push(item);
    }
  });

  return filtered;
};

const filterPage = (list, page) => {
  let filtered = [];

  list.forEach(item => {
    let itemPage = getCampaignPage(item);

    if (itemPage === "Popup Recommendation" && itemPage === page) {
      filtered.push(item);
    }else if (itemPage === "Journey Builder" && itemPage === page) {
      filtered.push(item);
    } else {
      if (itemPage === page + " Page") {
        filtered.push(item);
      }
    }
  });

  return filtered;
};

const filterVisible = list => {
  let filtered,
    _list = Array.isArray(list) ? list : [];

  filtered = _list.filter(item => {
    return item.status !== "PASSIVE";
  });

  return filtered;
};

const filterStatus = (list, status, isSearch) => {
  let filtered,
    _list = Array.isArray(list) ? list : [],
    testMode = false;

  if (!isSearch && status === "TEST") {
    status = "ACTIVE";
    testMode = true;
  }

  filtered = _list.filter(item => {
    if (isSearch) {
        return (
            (item.status === "PASSIVE" && status === "PASSIVE") ||
            (item.status === status)
        );
    }
    return (
      (item.status === "PASSIVE" && status === "PASSIVE") ||
      (item.status === status && item.testMode === testMode)
    );
  });

  return filtered;
};

const searchKeys = (obj, str) => {
  str = str.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  str = str.replace(/^\./, ""); // strip a leading dot
  let a = str.split(".");

  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (k in obj) {
      obj = obj[k];
    } else {
      return;
    }
  }
  return obj;
};

export { filterPage, filterVisible, filterStatus };
