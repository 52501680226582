import React from 'react';

/**
 * @name SFYWrapper component
 * @description Wrapper component to wrap all the components
 * @param {string} title - title of the wrapper
 * @param {JSX.Element | JSX.Element[]} children
 * @param {Object} inlineStyle - inline style of the wrapper
 * @returns {JSX.Element}
 * @constructor
 */
const SFYWrapper = ({ title = '', children, inlineStyle = {} }) => {
  return (
    <main className='sfy-wrapper' style={inlineStyle}>
      {title && <h3 className='sfy-wrapper__title'>{title}</h3>}
      {children}
    </main>
  );
};

export default SFYWrapper;
