import {
  isBunsarEnabled,
  isHiddenProductsEnabled,
  isMostSearchForEnabled,
  hasDynamicBundle,
  hasReplenishment,
} from '../../modules/auth/user';

import { t } from '../../system/ui';

export const criterionTypeButtons = {
  intelligent: {
    title: 'Intelligent',
    icon: 'brain',
    className: 'add-criteria',
    iconSvg: true,
  },
  static: {
    title: 'Static',
    icon: 'asteriks',
    className: 'add-criteria',
    iconSvg: true,
  },
};

export const criterionTypeButtonsBeforeSearch = {
  intelligent: {
    title: 'Top Categories',
    icon: 'brain',
    className: 'add-criteria',
    iconSvg: true,
  },
  static: {
    title: 'Static',
    icon: 'asteriks',
    className: 'add-criteria',
    iconSvg: true,
  },
};

export const criterionTypeButtonsJourneyBuilder = {
  jb_intelligent: {
    title: 'Top Categories',
    icon: 'brain',
    className: 'add-criteria',
    iconSvg: true,
  },
  intelligent: {
    title: 'Intelligent',
    icon: 'brain',
    className: 'add-criteria',
    iconSvg: true,
  },
  static: {
    title: 'Static',
    icon: 'asteriks',
    className: 'add-criteria',
    iconSvg: true,
  },
};

export const criterionTypeButtonsPalantir = {
  intelligent: {
    title: 'Add Asset',
    icon: 'plus',
    className: 'add-criteria',
    iconSvg: true,
  },
};

export const synonymsTypeButtonsPalantir = {
  intelligent: {
    title: 'Add Synonym',
    icon: 'plus',
    className: 'add-criteria',
    iconSvg: true,
  },
};

export const criterionTypeButtonsPromotion = {
  intelligent: {
    title: 'Promotion Type',
    icon: 'megaphone',
    className: 'add-criteria',
    iconSvg: true,
  },
};

export const criterionButtons = {
  intelligent: {
    title: 'Intelligent',
    icon: 'bulp',
    className: 'add-criteria',
  },
  category: {
    title: 'Category',
    icon: 'tag',
    className: 'add-criteria',
  },
  product: {
    title: 'Product',
    icon: 'product',
    className: 'add-criteria',
  },
  brand: {
    title: 'Brand',
    icon: 'star',
    className: 'add-criteria',
  },
  label: {
    title: 'Label',
    icon: 'excludeLabel',
    className: 'add-criteria',
  },
  includes: {
    title: 'Criteria',
    icon: 'exclude',
    className: 'add-criteria add-inclusion',
  },
  excludes: {
    title: 'Excludes',
    icon: 'exclude',
    className: 'exclude-criteria',
  },
};

export const criterionButtonsPromotion = {
  intelligent: {
    title: 'Intelligent',
    icon: 'bulp',
    className: 'add-criteria',
  },
  category: {
    title: 'Category',
    icon: 'tag',
    className: 'add-criteria',
  },
  brand: {
    title: 'Brand',
    icon: 'star',
    className: 'add-criteria',
  },
  label: {
    title: 'Label',
    icon: 'excludeLabel',
    className: 'add-criteria',
  },
  promotion: {
    title: 'Promotion',
    icon: 'product',
    className: 'add-criteria',
  },
};

export const exclusionButtons = {
  price_range: {
    title: 'Price Range',
    icon: 'dollar',
    className: 'add-criteria',
  },
  category: {
    title: 'Category',
    icon: 'tag',
    className: 'add-criteria',
  },
  product: {
    title: 'Product',
    icon: 'product',
    className: 'add-criteria',
  },
  brand: {
    title: 'Brand',
    icon: 'star',
    className: 'add-criteria',
  },
  label: {
    title: 'Label',
    icon: 'excludeLabel',
    className: 'add-criteria',
  },
  gender: {
    title: 'Gender',
    icon: 'excludeGender',
    className: 'add-criteria',
  },
  size: {
    title: 'Size',
    icon: 'excludeCustom',
    className: 'add-criteria',
  },
  stock: {
    title: 'Stock',
    icon: 'stock',
    className: 'add-criteria',
  },
  color: {
    title: 'Color',
    icon: 'palette',
    className: 'add-criteria',
    iconSvg: true,
  },
  custom: {
    title: 'Custom',
    icon: 'excludeCustom',
    className: 'add-criteria',
  },
  discounted_product: {
    title: 'Discounted Products',
    icon: 'percentage',
    className: 'add-criteria',
    iconSvg: true,
  },
};

export const inclusionButtons = {
  price_range: {
    title: 'Price Range',
    icon: 'dollar',
    className: 'add-criteria',
  },
  category: {
    title: 'Category',
    icon: 'tag',
    className: 'add-criteria',
  },
  brand: {
    title: 'Brand',
    icon: 'star',
    className: 'add-criteria',
  },
  label: {
    title: 'Label',
    icon: 'excludeLabel',
    className: 'add-criteria',
  },
  gender: {
    title: 'Gender',
    icon: 'excludeGender',
    className: 'add-criteria',
  },
  size: {
    title: 'Size',
    icon: 'excludeCustom',
    className: 'add-criteria',
  },
  stock: {
    title: 'Stock',
    icon: 'stock',
    className: 'add-criteria',
  },
  color: {
    title: 'Color',
    icon: 'palette',
    className: 'add-criteria',
    iconSvg: true,
  },
  custom: {
    title: 'Custom',
    icon: 'excludeCustom',
    className: 'add-criteria',
  },
  discounted_product: {
    title: 'Discounted Products',
    icon: 'percentage',
    className: 'add-criteria',
    iconSvg: true,
  },
};

export const searchIntelligentOptions = {
  RECOMMENDATION_SMART_OFFERS: 'Smart Offers',
  RECOMMENDATION_TOP_SELLERS: 'Personalised Top Sellers',
  RECOMMENDATION_STATISTICAL_TOP_SELLERS: 'Top Sellers',
  RECOMMENDATION_NEW_COMERS: 'New Arrivals',
  RECOMMENDATION_SOURCE_LAST_VISITED: 'Last Visited',
  RECOMMENDATION_LAST_PURCHASE: 'Last Purchased',
  RECOMMENDATION_PRICE_DROP: 'Price Drop',
  RECOMMENDATION_BROWSING_ALTERNATIVES: 'Browsing Alternatives',
  RECOMMENDATION_PURCHASE_ALTERNATIVES: 'Purchase Alternatives',
  RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
  RECOMMENDATION_DISCOUNTED_PRODUCTS: 'Discounted Products',
  RECOMMENDATION_EVENT_DATA: 'Predefined Products',
  RECOMMENDATION_PREVIOUSLY_ADDED_TO_BASKET: 'Previously Added to Basket',
};

export const afterSearchIntelligentOptions = {
  RECOMMENDATION_SMART_OFFERS: 'Smart Offers',
  RECOMMENDATION_TOP_SELLERS: 'Personalised Top Sellers',
  RECOMMENDATION_STATISTICAL_TOP_SELLERS: 'Top Sellers',
  RECOMMENDATION_NEW_COMERS: 'New Arrivals',
  RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
  RECOMMENDATION_DISCOUNTED_PRODUCTS: 'Discounted Products',
};

export const intelligentOptions = {
  RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
  RECOMMENDATION_COLLABORATIVE_FILTERING: 'Complementary Products',
  RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER: 'Mostly Purchased Together',
  RECOMMENDATION_BRAND_PRODUCTS: 'Brand Products',
  RECOMMENDATION_LABEL_PRODUCTS: 'Label Products',
  RECOMMENDATION_DISCOUNTED_PRODUCTS: 'Discounted Products',
  RECOMMENDATION_SOURCE_LAST_VISITED: 'Last Visited',
  RECOMMENDATION_LAST_PURCHASE: 'Last Purchased',
  RECOMMENDATION_SMART_OFFERS: 'Smart Offers',
  RECOMMENDATION_SOURCE_RELATED_PRODUCT: 'Alternative Products',
  RECOMMENDATION_EVENT_DATA: 'Predefined Products',
  RECOMMENDATION_TOP_SELLERS: 'Personalised Top Sellers',
  RECOMMENDATION_STATISTICAL_TOP_SELLERS: 'Top Sellers',
  RECOMMENDATION_NEW_COMERS: 'New Arrivals',
  RECOMMENDATION_PRICE_DROP: 'Price Drop',
  RECOMMENDATION_BACK_IN_STOCK: 'Back In Stock',
  RECOMMENDATION_BROWSING_ALTERNATIVES: 'Browsing Alternatives',
  RECOMMENDATION_PURCHASE_ALTERNATIVES: 'Purchase Alternatives',
  RECOMMENDATION_PREVIOUSLY_ADDED_TO_BASKET: 'Previously Added to Basket',
  RECOMMENDATION_BEST_MATCH: 'Best Match',
};

export const intelligentOptionsHUR_4 = {
  RECOMMENDATION_BRAND_PRODUCTS: 'Brand Products',
  RECOMMENDATION_SOURCE_LAST_VISITED: 'Last Visited',
  RECOMMENDATION_LAST_PURCHASE: 'Last Purchased',
  RECOMMENDATION_SMART_OFFERS: 'Smart Offers',
  RECOMMENDATION_EVENT_DATA: 'Predefined Products',
  RECOMMENDATION_TOP_SELLERS: 'Personalised Top Sellers',
  RECOMMENDATION_STATISTICAL_TOP_SELLERS: 'Top Sellers',
  RECOMMENDATION_PRICE_DROP: 'Price Drop',
  RECOMMENDATION_BACK_IN_STOCK: 'Back In Stock',
  RECOMMENDATION_BROWSING_ALTERNATIVES: 'Browsing Alternatives',
  RECOMMENDATION_PURCHASE_ALTERNATIVES: 'Purchase Alternatives',
  RECOMMENDATION_PREVIOUSLY_ADDED_TO_BASKET: 'Previously Added to Basket',
};

export const intelligentOptionsCustom = {
  RECOMMENDATION_COLLABORATIVE_FILTERING: 'Complementary Products',
  RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER: 'Mostly Purchased Together',
  RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
  RECOMMENDATION_BRAND_PRODUCTS: 'Brand Products',
  RECOMMENDATION_LABEL_PRODUCTS: 'Label Products',
  RECOMMENDATION_DISCOUNTED_PRODUCTS: 'Discounted Products',
  RECOMMENDATION_SOURCE_LAST_VISITED: 'Last Visited',
  RECOMMENDATION_LAST_PURCHASE: 'Last Purchased',
  RECOMMENDATION_SMART_OFFERS: 'Smart Offers',
  RECOMMENDATION_SOURCE_RELATED_PRODUCT: 'Alternative Products',
  RECOMMENDATION_EVENT_DATA: 'Predefined Products',
  RECOMMENDATION_TOP_SELLERS: 'Personalised Top Sellers',
  RECOMMENDATION_STATISTICAL_TOP_SELLERS: 'Top Sellers',
  RECOMMENDATION_CATEGORY_ROCKS: 'Category Rocks',
  RECOMMENDATION_PRICE_DROP: 'Price Drop',
  RECOMMENDATION_BACK_IN_STOCK: 'Back In Stock',
  RECOMMENDATION_BROWSING_ALTERNATIVES: 'Browsing Alternatives',
  RECOMMENDATION_PURCHASE_ALTERNATIVES: 'Purchase Alternatives',
  RECOMMENDATION_PREVIOUSLY_ADDED_TO_BASKET: 'Previously Added to Basket',
  RECOMMENDATION_BEST_MATCH: 'Best Match',
};

export const intelligentOptionsHUR_8 = {
  RECOMMENDATION_COLLABORATIVE_FILTERING: 'Complementary Products',
  RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER: 'Mostly Purchased Together',
  RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER_OFFLINE:
    'Mostly Purchased Together - Offline',
  RECOMMENDATION_BRAND_PRODUCTS: 'Brand Products',
  RECOMMENDATION_LABEL_PRODUCTS: 'Label Products',
  RECOMMENDATION_SOURCE_LAST_VISITED: 'Last Visited',
  RECOMMENDATION_LAST_PURCHASE: 'Last Purchased',
  RECOMMENDATION_SOURCE_RELATED_PRODUCT: 'Alternative Products',
  RECOMMENDATION_EVENT_DATA: 'Predefined Products',
  RECOMMENDATION_TOP_SELLERS: 'Personalised Top Sellers',
  RECOMMENDATION_STATISTICAL_TOP_SELLERS: 'Top Sellers',
  RECOMMENDATION_PRICE_DROP: 'Price Drop',
  RECOMMENDATION_BACK_IN_STOCK: 'Back In Stock',
  RECOMMENDATION_BROWSING_ALTERNATIVES: 'Browsing Alternatives',
  RECOMMENDATION_PURCHASE_ALTERNATIVES: 'Purchase Alternatives',
  RECOMMENDATION_PREVIOUSLY_ADDED_TO_BASKET: 'Previously Added to Basket',
};

export const intelligentOptionsT16 = {
  RECOMMENDATION_DISCOUNTED_PRODUCTS: 'Discounted Products',
  RECOMMENDATION_SOURCE_LAST_VISITED: 'Last Visited',
  RECOMMENDATION_SMART_OFFERS: 'Smart Offers',
  RECOMMENDATION_EVENT_DATA: 'Predefined Products',
  RECOMMENDATION_TOP_SELLERS: 'Personalised Top Sellers',
  RECOMMENDATION_STATISTICAL_TOP_SELLERS: 'Top Sellers',
  RECOMMENDATION_COLLABORATIVE_FILTERING: 'Complementary Products',
  RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER: 'Mostly Purchased Together',
  RECOMMENDATION_PRICE_DROP: 'Price Drop',
  RECOMMENDATION_BACK_IN_STOCK: 'Back In Stock',
  RECOMMENDATION_BROWSING_ALTERNATIVES: 'Browsing Alternatives',
  RECOMMENDATION_PURCHASE_ALTERNATIVES: 'Purchase Alternatives',
  RECOMMENDATION_PREVIOUSLY_ADDED_TO_BASKET: 'Previously Added to Basket',
};

export const intelligentOptionsJB_1 = {
  RECOMMENDATION_SMART_OFFERS: 'Smart Offers',
  RECOMMENDATION_CATEGORY_ROCKS: 'Category Rocks',
  RECOMMENDATION_TOP_SELLERS: 'Personalised Top Sellers',
  RECOMMENDATION_STATISTICAL_TOP_SELLERS: 'Top Sellers',
  RECOMMENDATION_NEW_COMERS: 'New Arrivals',
  RECOMMENDATION_PRICE_DROP: 'Price Drop',
};

export const intelligentOptionsPush = {
  RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
  RECOMMENDATION_DISCOUNTED_PRODUCTS: 'Discounted Products',
  RECOMMENDATION_TOP_SELLERS: 'Personalised Top Sellers',
  RECOMMENDATION_STATISTICAL_TOP_SELLERS: 'Top Sellers',
};

// Add here RECOMMENDATION_RELATED_PROMOTIONS when T31 template is added
export const intelligentOptionsPromotions = {
  RECOMMENDATION_ALL_PROMOTIONS: 'All Promotions',
  RECOMMENDATION_SMART_PROMOTIONS: 'Smart Offers',
  RECOMMENDATION_CATEGORY_PROMOTIONS: 'Category Promotions',
  RECOMMENDATION_BRAND_PROMOTIONS: 'Brand Promotions',
  RECOMMENDATION_LABEL_PROMOTIONS: 'Label Promotions',
  RECOMMENDATION_PREDEFINED_PROMOTIONS: 'Predefined Promotions',
  RECOMMENDATION_LAST_VISITED_BANNERS_PROMOTIONS: 'Last Visited Banners',
  RECOMMENDATION_LAST_VISITED_CATEGORY_PROMOTIONS: 'Last Visited Category',
  RECOMMENDATION_LAST_VISITED_BRAND_PROMOTIONS: 'Last Visited Brands',
};

export const intelligentOptionsSearchKeyword = {
  RECOMMENDATION_SEARCH_KEYWORDS: 'Top Searched Words',
};

export const intelligentOptionsEmail = {
  RECOMMENDATION_SMART_OFFERS: 'Smart Offers',
  RECOMMENDATION_SOURCE_LAST_VISITED: 'Last Visited',
  RECOMMENDATION_LAST_PURCHASE: 'Last Purchased',
  RECOMMENDATION_TOP_SELLERS: 'Personalised Top Sellers',
  RECOMMENDATION_STATISTICAL_TOP_SELLERS: 'Top Sellers',
  RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
  RECOMMENDATION_DISCOUNTED_PRODUCTS: 'Discounted Products',
  RECOMMENDATION_NEW_COMERS: 'New Arrivals',
  RECOMMENDATION_EVENT_DATA: 'Predefined Products',
  RECOMMENDATION_PREVIOUSLY_ADDED_TO_BASKET: 'Previously Added to Basket',
  RECOMMENDATION_LAST_VISIT_REMINDER: 'Last Visited Products',
  RECOMMENDATION_REPLENISHMENT_PRODUCTS: 'Replenishment Algorithm',
};

// export const intelligentOptionsMailProviderSegmentify = {
//   RECOMMENDATION_SMART_OFFERS: 'Smart Offers',
//   RECOMMENDATION_NEW_COMERS: 'New Arrivals',
//   RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
//   RECOMMENDATION_TOP_SELLERS: 'Personalised Top Sellers',
// };

export const intelligentOptionsBeforeSearchCategories = {
  TOP_CATEGORY: 'Top Categories',
};

export const intelligentOptionsBeforeSearchBrand = {
  TOP_BRAND: 'Top Brands',
};

export const intelligentOptionsBeforeSearchKeyword = {
  TOP_KEYWORD: 'Top Keywords',
};

export const intelligentOptionsJBCategories = {
  TOP_CATEGORY: 'Top Categories',
};

export const intelligentOptionsJBBrands = {
  TOP_BRAND: 'Top Brands',
};

export const intelligentOptionsJBSizes = {
  TOP_SIZE: 'Top Sizes',
};

export const intelligentOptionsJBColours = {
  TOP_COLOR: 'Top Colours',
};

export const intelligentOptionsJBBest = {
  BEST_MATCH: 'Best Match',
};

const sortAlgorithmKeys = (currentIntelligents, algorithmOrders, pageType) =>
  Object.keys(currentIntelligents).sort((algorithmA, algorithmB) => {
    if (algorithmOrders[algorithmA] && algorithmOrders[algorithmB]) {
      const a = algorithmOrders[algorithmA][pageType];
      const b = algorithmOrders[algorithmB][pageType];
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    }
    return 0;
  });

const sortAlgorithms = (sortedAlgorithmKeys, currentIntelligents) =>
  currentIntelligents
    ? Object.keys(sortedAlgorithmKeys).reduce(
        (sortedAlgorithms, key) => ({
          ...sortedAlgorithms,
          [sortedAlgorithmKeys[key]]:
            currentIntelligents[sortedAlgorithmKeys[key]],
        }),
        {},
      )
    : {};

export const segmentAlgorithmTitles = segmentType => {
  let title;
  let key;
  let segmentTitle;
  let imageName;
  let desc;
  switch (segmentType) {
    case 'Active Loyals':
      title = 'ACTIVE_LOYALS';
      key = false;
      segmentTitle = 'Active Loyals';
      imageName = 'active-loyals';
      desc = 'Repeat customers whose last order was very recent';
      break;
    case 'Big Spenders':
      title = 'BIG_SPENDERS';
      key = false;
      segmentTitle = 'Big Spenders';
      imageName = 'big-spenders';
      desc = 'High spending customers with repeat orders and large baskets';
      break;
    case 'Loyals at Risk':
      title = 'LOYALS_AT_RISK';
      key = false;
      segmentTitle = 'Loyals at Risk';
      imageName = 'loyals-at-risk';
      desc = 'Repeat customers whom you are at risk to lose due to inactivity';
      break;
    case 'Richie Riches':
      title = 'RICHIE_RICHES';
      key = false;
      segmentTitle = 'Richie Riches';
      imageName = 'richie-riches';
      desc =
        'Elite customers who are willing to pay high prices for high-end products';
      break;
    case 'Bulk Shoppers':
      title = 'BULK_SHOPPERS';
      key = false;
      segmentTitle = 'Bulk Shoppers';
      imageName = 'bulk-shoppers';
      desc =
        'Repeat customers who love to buy multiple items of the same product';
      break;
    case 'Discount Seekers':
      title = 'SLEEPING_OPPORTUNISTS';
      key = false;
      segmentTitle = 'Discount Seekers';
      imageName = 'sleeping-opportunists';
      desc =
        'Opportunist customers who love discounts and waiting for a good deal';
      break;
    case 'Churn Candidates':
      title = 'CHURN_CANDIDATES';
      key = false;
      segmentTitle = 'Churn Candidates';
      imageName = 'churn-candidates';
      desc = 'Customers who haven’t been ordered for a long time';
      break;
    case 'One Time Shoppers':
      title = 'ONE_TIME_SHOPPERS';
      key = false;
      segmentTitle = 'One Time Shoppers';
      imageName = 'one-time-shoppers';
      desc = 'Customers who have made only one purchase';
      break;
    case 'Waiting First Order':
      title = 'WAITING_FIRST_ORDER';
      key = false;
      segmentTitle = 'Waiting First Order';
      imageName = 'waiting-first-order';
      desc = "Customers who haven't made their first purchase yet";
      break;
    case 'Lost Customers':
      title = 'LOST_CUSTOMERS';
      key = false;
      segmentTitle = 'Lost Customers';
      imageName = 'lost-customers';
      desc =
        'Customers who haven’t been interacted with your brand and churned';
      break;
    default:
      title = 'NOT_FOUND_SEGMENT_NAME';
      key = false;
      segmentTitle = 'Unkown Segment Name';
      imageName = '';
      desc = '';
  }
  return { title, key, segmentTitle, imageName, desc };
};

export const segmentAlgorithmNames = segmentType => {
  switch (segmentType) {
    case 'ACTIVE_LOYALS':
      return 'Active Loyals';
    case 'BIG_SPENDERS':
      return 'Big Spenders';
    case 'LOYALS_AT_RISK':
      return 'Loyals at Risk';
    case 'RICHIE_RICHES':
      return 'Richie Riches';
    case 'BULK_SHOPPERS':
      return 'Bulk Shoppers';
    case 'SLEEPING_OPPORTUNISTS':
      return 'Discount Seekers';
    case 'CHURN_CANDIDATES':
      return 'Churn Candidates';
    case 'ONE_TIME_SHOPPERS':
      return 'One Time Shoppers';
    case 'WAITING_FIRST_ORDER':
      return 'Waiting First Order';
    case 'LOST_CUSTOMERS':
      return 'Lost Customers';
    default:
      return 'Unkown Segment Name';
  }
};

export const searchAlgorithmTitles = searchType => {
  let title;
  let key;
  let campaignTitle;
  let description;
  switch (searchType) {
    case 'BEFORE_SEARCH':
      title = 'Before Search Input';
      key = false;
      campaignTitle = 'Before Search Input';
      description = t('Before_Search_Input');
      break;
    case 'SEARCH':
      title = 'After Search Input';
      key = false;
      campaignTitle = 'After Search Input';
      description =
        'Create personalised search experience with the most popular products from your website.';
      break;
    default:
      break;
  }
  return { title, key, campaignTitle, description };
};

export const mailAlgorithmTitles = (mailType, isPush = false) => {
  let title;
  let key;
  let campaignTitle;
  let description;
  let pushTitle;
  switch (mailType) {
    case 'ABANDONED_CART':
      title = 'Abandoned Cart';
      key = false;
      campaignTitle = 'Abandoned Cart';
      description =
        'Reminds your customers about the items they left in their carts';
      break;
    case 'LAST_VISIT_ALTERNATIVES':
      title = 'Browsing Alternatives';
      key = 'RECOMMENDATION_BROWSING_ALTERNATIVES';
      campaignTitle = 'Last Visit Alternatives';
      description =
        'Lets your customers know about the most relevant products based on their browsing history';
      break;
    case 'NICE_TO_MEET_YOU':
      title = 'Spotlights';
      key = 'RECOMMENDATION_SOURCE_ALL_PRODUCTS';
      campaignTitle = 'Nice to meet you';
      description =
        'Welcomes your new customers with the most popular products of the day';
      break;
    case 'NEW_COMERS':
      title = 'New Arrivals';
      key = 'RECOMMENDATION_NEW_COMERS';
      campaignTitle = 'New Arrivals';
      description =
        'Lets your customers know about the new products on your online store';
      break;
    case 'TOP_SELLERS':
      title = 'Top Sellers';
      key = 'RECOMMENDATION_STATISTICAL_TOP_SELLERS';
      campaignTitle = 'Top Sellers';
      description = 'Display favorites of the week';
      break;
    case 'ORDER_FOLLOW_UP':
      title = isPush ? 'Purchase Alternatives' : 'Complementary Products';
      key = 'RECOMMENDATION_ORDER_FOLLOW_UP';
      campaignTitle = 'Order Follow Up';
      description =
        'Offers customers complementary products for their last purchases';
      break;
    case 'WE_MISSED_YOU':
      title = 'Smart Offers';
      key = 'RECOMMENDATION_SMART_OFFERS';
      campaignTitle = 'We missed you';
      description =
        'Reminds the most relevant products to customers who haven’t been visiting online store for a while';
      break;
    case 'CHURN':
      title = 'Smart Offers';
      key = 'RECOMMENDATION_SMART_OFFERS';
      campaignTitle = 'Churn';
      description =
        "Offers the most relevant products to customers who made purchases but haven't been visiting online store for a while";
      break;
    case 'PRICE_DROP':
      title = 'Price Drop';
      key = false;
      campaignTitle = 'Price Drop';
      description =
        'Lets your customers know about the discounts on the products they were previously interested in';
      break;
    case 'FLY':
      title = 'Event Data';
      key = 'RECOMMENDATION_EVENT_DATA';
      campaignTitle = 'FLY Mode';
      break;
    case 'BIRTHDAY':
      title = 'Smart Offers';
      key = 'RECOMMENDATION_SMART_OFFERS';
      campaignTitle = 'Birthday';
      description =
        'Wish your customers a happy birthday with a bundle that includes personalised offers, new arrivals and weekly discounts';
      break;
    case 'ANNIVERSARY':
      title = 'Smart Offers';
      key = 'RECOMMENDATION_SMART_OFFERS';
      campaignTitle = 'Anniversary';
      description =
        'Wish your customers a happy anniversary with a bundle that includes personalised offers, new arrivals and weekly discounts';
      break;
    case 'RECOMMENDATION':
      title = 'Smart Offers';
      key = 'RECOMMENDATION_SMART_OFFERS';
      campaignTitle = 'Bulk / Segmented Campaign';
      break;
    case 'BACK_IN_STOCK':
      title = 'Back in Stock';
      key = false;
      campaignTitle = 'Back in Stock Campaign';
      description =
        'Lets your customers know about the back in stock on the your products they were previously interested in.';
      break;
    case 'REPLENISHMENT':
      title = 'Replenishment';
      key = false;
      campaignTitle = 'Replenishment Campaign';
      description = '';
      break;
    case 'UPCOMING':
      title = 'Upcoming';
      key = 'RECOMMENDATION_UPCOMING';
      campaignTitle = 'Upcoming';
      description =
        'Remind your customers about what they’re missing with automated betting campaigns and keep them in touch with the games';
      break;
    case 'LAST_VISIT_REMINDER':
      title = 'Last Visited Products';
      pushTitle = 'Last Visited Product';
      key = 'RECOMMENDATION_LAST_VISIT_REMINDER';
      campaignTitle = 'Last Visit Reminder';
      description =
        'Reminds your customers about the products they visited before';
      break;
    default:
      return 'Mail Algorithm';
  }
  return { title, key, campaignTitle, description, pushTitle };
};

export const algorithmDescriptions = (favorites = []) => {
  return {
    RECOMMENDATION_SMART_OFFERS: {
      isFavorite: favorites.indexOf('RECOMMENDATION_SMART_OFFERS'),
      hasIcon: false,
      title: 'Smart Offers',
      description:
        'A personalised machine learning algorithm that recommends products based on best-matching categories and behaviour of the current visitor during the last visit',
    },
    RECOMMENDATION_SOURCE_ALL_PRODUCTS: {
      isFavorite: favorites.indexOf('RECOMMENDATION_SOURCE_ALL_PRODUCTS'),
      hasIcon: false,
      title: 'Spotlights',
      description:
        'A machine learning algorithm that sorts all your products in your ecommerce site intelligently',
    },
    RECOMMENDATION_DISCOUNTED_PRODUCTS: {
      isFavorite: favorites.indexOf('RECOMMENDATION_DISCOUNTED_PRODUCTS'),
      hasIcon: false,
      title: 'Discounted Products',
      description:
        'An intelligent algorithm that catches the price drops of all products and recommends from them to the customers.',
    },
    RECOMMENDATION_SHOP_THE_LOOK: {
      isFavorite: false,
      hasIcon: false,
      title: 'Shop The Look',
      description:
        'Use combination bundles of products and categories with the ‘Shop the Look’ algorithm and recommend combinations to website users.',
    },
    RECOMMENDATION_MOST_SEARCHED_PRODUCTS: {
      isFavorite: false,
      hasIcon: false,
      title: 'Most Searched For',
      description:
        'An algorithm that recommends the most searched products by the users',
    },
    RECOMMENDATION_BRAND_PRODUCTS: {
      isFavorite: favorites.indexOf('RECOMMENDATION_BRAND_PRODUCTS'),
      hasIcon: false,
      title: 'Brand Products',
      description:
        'A machine learning algorithm that sorts all the products of that brand intelligently',
    },
    RECOMMENDATION_LABEL_PRODUCTS: {
      isFavorite: favorites.indexOf('RECOMMENDATION_LABEL_PRODUCTS'),
      hasIcon: false,
      title: 'Label Products',
      description:
        'A machine learning algorithm that sorts all the products of that label intelligently',
    },
    RECOMMENDATION_EVENT_DATA: {
      isFavorite: favorites.indexOf('RECOMMENDATION_EVENT_DATA'),
      hasIcon: false,
      title: 'Predefined Products',
      description:
        'A straight forward algorithm that recommends the products pre-defined for each product by you.',
    },
    RECOMMENDATION_RELATED_COMBINE_PRODUCTS: {
      isFavorite: favorites.indexOf('RECOMMENDATION_RELATED_COMBINE_PRODUCTS'),
      hasIcon: false,
      title: 'Bundle Products',
      description:
        'A machine learning algorithm that recommends the subsidiary products of the product your visitor is viewing.',
    },
    RECOMMENDATION_HIDDEN_PRODUCTS: {
      isFavorite: favorites.indexOf('RECOMMENDATION_HIDDEN_PRODUCTS'),
      hasIcon: false,
      title: 'Hidden Products',
      description:
        "An intelligent algorithm that recommends your hidden products that haven't been viewed.",
    },
    RECOMMENDATION_SOURCE_LAST_VISITED: {
      isFavorite: favorites.indexOf('RECOMMENDATION_SOURCE_LAST_VISITED'),
      hasIcon: false,
      title: 'Last Visited',
      description:
        'An algorithm that remind your visitors about all the products they visited during their customer lifetime on all the platforms of your online shop.',
    },
    RECOMMENDATION_LAST_PURCHASE: {
      isFavorite: favorites.indexOf('RECOMMENDATION_LAST_PURCHASE'),
      hasIcon: false,
      title: 'Last Purchased',
      description:
        'An algorithm that recommends the last products your visitors purchased within their last session.',
    },
    RECOMMENDATION_BROWSING_ALTERNATIVES: {
      isFavorite: favorites.indexOf('RECOMMENDATION_BROWSING_ALTERNATIVES'),
      hasIcon: false,
      title: 'Browsing Alternatives',
      description:
        'Recommend visitors alternative products based on their browsing history to engage them with more opportunities to shop.',
    },
    RECOMMENDATION_PURCHASE_ALTERNATIVES: {
      isFavorite: favorites.indexOf('RECOMMENDATION_BROWSING_ALTERNATIVES'),
      hasIcon: false,
      title: 'Purchase Alternatives',
      description:
        'An algorithm making product recommendations to your customers, related to their last purchased items',
    },
    RECOMMENDATION_PRICE_DROP: {
      isFavorite: favorites.indexOf('RECOMMENDATION_PRICE_DROP'),
      hasIcon: false,
      title: 'Price Drop',
      description:
        'Lets your customers know about the discounts on the products they were previously interested in.',
    },
    RECOMMENDATION_BACK_IN_STOCK: {
      isFavorite: favorites.indexOf('RECOMMENDATION_BACK_IN_STOCK'),
      hasIcon: false,
      title: 'Back In Stock',
      description:
        'Lets your customers know about the back in stock on the your products they were previously interested in.',
    },
    RECOMMENDATION_SOURCE_RELATED_PRODUCT: {
      isFavorite: favorites.indexOf('RECOMMENDATION_SOURCE_RELATED_PRODUCT'),
      hasIcon: false,
      title: 'Alternative Products',
      description:
        'A machine learning algorithm that recommends the alternative products of the product your visitor is viewing',
    },
    RECOMMENDATION_IMAGE_BASED_SIMILARITY: {
      isFavorite: favorites.indexOf('RECOMMENDATION_IMAGE_BASED_SIMILARITY'),
      hasIcon: false,
      title: 'Look Alike',
      description:
        'Recommends from visually similar products to the product your visitor is viewing',
    },
    RECOMMENDATION_COLLABORATIVE_FILTERING: {
      isFavorite: favorites.indexOf('RECOMMENDATION_COLLABORATIVE_FILTERING'),
      hasIcon: false,
      title: 'Complementary Products',
      description:
        'A machine learning algorithm that recommends the complimentaries of the product your visitor is viewing.',
    },
    RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER: {
      isFavorite: favorites.indexOf('RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER'),
      hasIcon: false,
      title: 'Mostly Purchased Together',
      description:
        'An algorithm that recommends the products purchased together most with the product you’re viewing or the products that are currently in your cart.',
    },
    RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER_OFFLINE: {
      isFavorite: favorites.indexOf(
        'RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER_OFFLINE',
      ),
      hasIcon: false,
      title: 'Mostly Purchased Together - Offline',
      description:
        'An algorithm that recommends the most purchased products with the product you are viewing or the products currently in your cart based on physical store data.',
    },
    RECOMMENDATION_TOP_SELLERS: {
      isFavorite: favorites.indexOf('RECOMMENDATION_TOP_SELLERS'),
      hasIcon: false,
      title: 'Personalised Top Sellers',
      description:
        'An algorithm that recommends your top selling products from current category or category of current product or you based on the users past behaviour and it is personalised for each visitor',
    },
    RECOMMENDATION_CATEGORY_ROCKS: {
      isFavorite: favorites.indexOf('RECOMMENDATION_CATEGORY_ROCKS'),
      hasIcon: false,
      title: 'Category Rocks',
      description:
        'A Category Page special machine learning algorithm that picks your top performing products from current category or category of current product',
    },
    RECOMMENDATION_NEW_COMERS: {
      isFavorite: favorites.indexOf('RECOMMENDATION_NEW_COMERS'),
      hasIcon: false,
      title: 'New Arrivals',
      description:
        'A machine learning algorithm that recommends your new arrivals',
    },
    RECOMMENDATION_PREVIOUSLY_ADDED_TO_BASKET: {
      isFavorite: favorites.indexOf(
        'RECOMMENDATION_PREVIOUSLY_ADDED_TO_BASKET',
      ),
      hasIcon: false,
      title: 'Previously Added to Basket',
      description:
        'Recommend visitors their previously added to basket products',
    },
    RECOMMENDATION_LAST_VISIT_REMINDER: {
      isFavorite: false,
      hasIcon: false,
      title: 'Last Visited Products',
      description:
        'Reminds your customers about the products they visited before',
    },
    RECOMMENDATION_BEST_MATCH: {
      isFavorite: false,
      hasIcon: false,
      title: 'Best Match',
      description:
        'An intelligent algorithm recommending products that perfectly matches with the choices of your customers',
    },
    RECOMMENDATION_REPLENISHMENT_PRODUCTS: {
      isFavorite: false,
      hasIcon: false,
      title: 'Replenishment Algorithm',
      description:
        'Replenishment refers to the process by which an algorithm predicts when a user will need to restock products based on their usage patterns and purchasing history, and proactively recommends these products to ensure continuous supply.',
    },
    'STATIC-PRODUCT': {
      isFavorite: false,
      hasIcon: true,
      icon: 'product',
      title: 'Product',
      description: 'Create your recommendation campaign with specific products',
    },
    'STATIC-CATEGORY': {
      isFavorite: false,
      hasIcon: true,
      icon: 'tag',
      title: 'Category',
      description:
        'Create your recommendation campaign with products from a specific category',
    },
    'STATIC-KEYWORD-S': {
      isFavorite: false,
      hasIcon: false,
      title: 'Keyword',
      description: 'Show the popular keywords in the Search Box',
    },
    'STATIC-BRAND-S': {
      isFavorite: false,
      hasIcon: false,
      title: 'Brand',
      description:
        'Show the brands of the most relevant and popular products in the Search Box',
    },
    'STATIC-CATEGORY-S': {
      isFavorite: false,
      hasIcon: false,
      title: 'Category',
      description:
        'Show the categories of the most relevant and popular products in the Search Box',
    },
    'STATIC-BRAND': {
      isFavorite: false,
      hasIcon: true,
      icon: 'star',
      title: 'Brand',
      description:
        'Create your recommendation campaign with products from a specific brand',
    },
    'STATIC-LABEL': {
      isFavorite: false,
      hasIcon: true,
      icon: 'excludeLabel',
      iconSvg: true,
      title: 'Label',
      description:
        'Create your recommendation campaign with products from a specific label',
    },
    'STATIC-PROMOTION': {
      isFavorite: false,
      hasIcon: true,
      icon: 'plusCircle',
      iconSvg: true,
      title: 'Static Promotion',
      description: 'Recommends a specific promotion of your selection',
    },
    SEARCH_SYNONYMS_TWO_WAY: {
      isFavorite: false,
      hasIcon: false,
      icon: 'product',
      title: 'Two-Way Synonyms',
      hasLeftValue: false,
      operatorKey: 'TWO_WAY',
      operatorUIKey: 'SEARCH_SYNONYMS_TWO_WAY',
      description:
        'Use Synonyms to specify multiple words that should be considered equivalent.',
    },
    SEARCH_SYNONYMS_ONE_WAY: {
      isFavorite: false,
      hasIcon: false,
      icon: 'tag',
      title: 'One-Way Synonyms',
      hasLeftValue: true,
      operatorKey: 'ONE_WAY',
      operatorUIKey: 'SEARCH_SYNONYMS_ONE_WAY',
      description:
        'Use One Way Synonyms to define alternative words for a search term, which are not synonyms to each others.',
    },
    SEARCH_SYNONYMS_REPLACE: {
      isFavorite: false,
      hasIcon: false,
      title: 'Replacements',
      hasLeftValue: true,
      operatorKey: 'REPLACE',
      operatorUIKey: 'SEARCH_SYNONYMS_REPLACE',
      description:
        'Use Replacements to define tokens that can take any value from list of words that you define.',
    },
    RECOMMENDATION_ALL_PROMOTIONS: {
      isFavorite: false,
      hasIcon: true,
      icon: 'brain',
      iconSvg: true,
      title: 'All Promotions',
      description:
        'Recommends from all promotions in your website with no rules attached.',
    },
    RECOMMENDATION_SMART_PROMOTIONS: {
      isFavorite: false,
      hasIcon: true,
      icon: 'brain',
      iconSvg: true,
      title: 'Smart Offers',
      description:
        "Recommends the related promotions of the users' most favored categories and last visited promotions",
    },
    RECOMMENDATION_CATEGORY_PROMOTIONS: {
      isFavorite: false,
      hasIcon: true,
      icon: 'brain',
      iconSvg: true,
      title: 'Category Promotions',
      description:
        'Recommends the related promotions of the category your customer is viewing.',
    },
    RECOMMENDATION_LAST_VISITED_BANNERS_PROMOTIONS: {
      isFavorite: false,
      hasIcon: true,
      icon: 'brain',
      iconSvg: true,
      title: 'Last Visited Banners',
      description: 'Recommends the last clicked banners to your customer.',
    },
    RECOMMENDATION_LAST_VISITED_CATEGORY_PROMOTIONS: {
      isFavorite: false,
      hasIcon: true,
      icon: 'brain',
      iconSvg: true,
      title: 'Last Visited Category',
      description:
        'Recommends most popular banners that related to categories your customer mostly visited.',
    },
    RECOMMENDATION_LAST_VISITED_BRAND_PROMOTIONS: {
      isFavorite: false,
      hasIcon: true,
      icon: 'brain',
      iconSvg: true,
      title: 'Last Visited Brands',
      description:
        'Recommends most popular banners that related to brands your customer mostly visited.',
    },
    RECOMMENDATION_BRAND_PROMOTIONS: {
      isFavorite: false,
      hasIcon: true,
      icon: 'brain',
      iconSvg: true,
      title: 'Brand Promotions',
      description:
        'Recommends the related promotions of the brand your customer is viewing.',
    },
    RECOMMENDATION_LABEL_PROMOTIONS: {
      isFavorite: false,
      hasIcon: true,
      icon: 'brain',
      iconSvg: true,
      title: 'Label Promotions',
      description:
        'Recommends the related promotions of the label your customer is viewing.',
    },
    RECOMMENDATION_PREDEFINED_PROMOTIONS: {
      isFavorite: false,
      hasIcon: true,
      icon: 'brain',
      iconSvg: true,
      title: 'Predefined Promotions',
      description:
        'A straight forward algorithm that recommends the promotions pre-defined by you.',
    },
    RECOMMENDATION_STATISTICAL_TOP_SELLERS: {
      isFavorite: false,
      hasIcon: false,
      title: 'Top Sellers',
      description:
        'An algorithm that recommends your top selling products from current category or category of current product or you overall ecommerce site',
    },
  };
};

export const algorithmOrders = {
  RECOMMENDATION_SMART_OFFERS: {
    home: 1,
    category: 5,
    product: 100,
    basket: 5,
    'checkout-success': 2,
    search: 1,
    notfound: 1,
    custom: 1,
    triggerbased: 1,
    Personalization: 1,
    popup_recommendation: 1,
  },
  RECOMMENDATION_SOURCE_ALL_PRODUCTS: {
    home: 5,
    category: 100,
    product: 100,
    basket: 100,
    'checkout-success': 3,
    search: 3,
    notfound: 3,
    custom: 5,
    triggerbased: 5,
    Personalization: 5,
    popup_recommendation: 5,
  },
  RECOMMENDATION_DISCOUNTED_PRODUCTS: {
    home: 7,
    category: 100,
    product: 100,
    basket: 3,
    'checkout-success': 6,
    search: 7,
    notfound: 7,
    custom: 11,
    triggerbased: 11,
    Personalization: 10,
    popup_recommendation: 10,
  },
  RECOMMENDATION_BRAND_PRODUCTS: {
    home: 6,
    category: 4,
    product: 6,
    basket: 100,
    'checkout-success': 100,
    search: 6,
    notfound: 6,
    custom: 9,
    triggerbased: 9,
    Personalization: 9,
    popup_recommendation: 9,
  },
  RECOMMENDATION_HIDDEN_PRODUCTS: {
    home: 51,
    category: 51,
    product: 51,
    basket: 51,
    'checkout-success': 51,
    search: 51,
    notfound: 51,
    custom: 51,
    triggerbased: 51,
    Personalization: 51,
    popup_recommendation: 51,
  },
  RECOMMENDATION_LABEL_PRODUCTS: {
    home: 100,
    category: 100,
    product: 7,
    basket: 100,
    'checkout-success': 100,
    search: 100,
    notfound: 100,
    custom: 10,
    triggerbased: 10,
    Personalization: 100,
    popup_recommendation: 100,
  },
  RECOMMENDATION_EVENT_DATA: {
    home: 50,
    category: 50,
    product: 50,
    basket: 50,
    'checkout-success': 50,
    search: 50,
    notfound: 50,
    custom: 50,
    triggerbased: 50,
    Personalization: 11,
    popup_recommendation: 11,
  },
  RECOMMENDATION_RELATED_COMBINE_PRODUCTS: {
    home: 100,
    category: 100,
    product: 8,
    basket: 4,
    'checkout-success': 100,
    search: 100,
    notfound: 100,
    custom: 12,
    triggerbased: 12,
    Personalization: 0,
    popup_recommendation: 0,
  },
  RECOMMENDATION_SOURCE_LAST_VISITED: {
    home: 4,
    category: 3,
    product: 3,
    basket: 2,
    'checkout-success': 5,
    search: 2,
    notfound: 2,
    custom: 4,
    triggerbased: 4,
    Personalization: 4,
    popup_recommendation: 4,
  },
  RECOMMENDATION_LAST_PURCHASE: {
    home: 4.1,
    category: 3.1,
    product: 3.1,
    basket: 2.1,
    'checkout-success': 5.1,
    search: 2.1,
    notfound: 2.1,
    custom: 4.1,
    triggerbased: 4.1,
    Personalization: 4.1,
    popup_recommendation: 4.1,
  },
  RECOMMENDATION_PRICE_DROP: {
    home: 4.2,
    category: 3.2,
    product: 3.2,
    basket: 2.2,
    'checkout-success': 5.2,
    search: 2.2,
    notfound: 2.2,
    custom: 4.2,
    triggerbased: 4.2,
    Personalization: 4.2,
    popup_recommendation: 4.2,
  },
  RECOMMENDATION_BACK_IN_STOCK: {
    home: 4.25,
    category: 3.25,
    product: 3.25,
    basket: 2.25,
    'checkout-success': 5.25,
    search: 2.25,
    notfound: 2.25,
    custom: 4.25,
    triggerbased: 4.25,
    Personalization: 4.25,
    popup_recommendation: 4.25,
  },
  RECOMMENDATION_BROWSING_ALTERNATIVES: {
    home: 4.3,
    category: 3.3,
    product: 3.3,
    basket: 2.3,
    'checkout-success': 5.3,
    search: 2.3,
    notfound: 2.3,
    custom: 4.3,
    triggerbased: 4.3,
    Personalization: 4.3,
    popup_recommendation: 4.3,
  },
  RECOMMENDATION_PURCHASE_ALTERNATIVES: {
    home: 4.4,
    product: 3.4,
    basket: 2.4,
    search: 2.4,
    notfound: 2.4,
  },
  RECOMMENDATION_SOURCE_RELATED_PRODUCT: {
    home: 100,
    category: 100,
    product: 1,
    basket: 100,
    'checkout-success': 100,
    search: 100,
    notfound: 100,
    custom: 7,
    triggerbased: 7,
    Personalization: 7,
    popup_recommendation: 7,
  },
  RECOMMENDATION_COLLABORATIVE_FILTERING: {
    home: 100,
    category: 100,
    product: 2,
    basket: 2,
    'checkout-success': 2,
    search: 100,
    notfound: 100,
    custom: 8,
    triggerbased: 8,
    Personalization: 8,
    popup_recommendation: 8,
  },
  RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER: {
    home: 100,
    category: 100,
    product: 2,
    basket: 2,
    'checkout-success': 2,
    search: 100,
    notfound: 100,
    custom: 8,
    triggerbased: 8,
    Personalization: 8,
    popup_recommendation: 8,
  },
  RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER_OFFLINE: {
    home: 100,
    category: 100,
    product: 2,
    basket: 2,
    'checkout-success': 100,
    search: 100,
    notfound: 100,
    custom: 8,
    triggerbased: 8,
    Personalization: 8,
    popup_recommendation: 8,
  },
  RECOMMENDATION_TOP_SELLERS: {
    home: 2,
    category: 2,
    product: 5,
    basket: 6,
    'checkout-success': 4,
    search: 4,
    notfound: 4,
    custom: 2,
    triggerbased: 2,
    Personalization: 2,
    popup_recommendation: 2,
  },
  RECOMMENDATION_STATISTICAL_TOP_SELLERS: {
    home: 2,
    category: 2,
    product: 5,
    basket: 6,
    'checkout-success': 4,
    search: 4,
    notfound: 4,
    custom: 2,
    triggerbased: 2,
    Personalization: 2,
    popup_recommendation: 2,
  },
  RECOMMENDATION_CATEGORY_ROCKS: {
    home: 100,
    category: 1,
    product: 4,
    basket: 100,
    'checkout-success': 100,
    search: 100,
    notfound: 100,
    custom: 6,
    triggerbased: 6,
    Personalization: 6,
    popup_recommendation: 6,
  },
  RECOMMENDATION_NEW_COMERS: {
    home: 3,
    category: 100,
    product: 100,
    basket: 100,
    'checkout-success': 100,
    search: 5,
    notfound: 5,
    custom: 3,
    triggerbased: 3,
    Personalization: 3,
    popup_recommendation: 3,
  },
  RECOMMENDATION_SMART_PROMOTIONS: {
    home: 1,
    category: 5,
    product: 100,
    basket: 5,
    'checkout-success': 2,
    search: 1,
    notfound: 1,
    custom: 1,
    triggerbased: 1,
    Personalization: 0,
    popup_recommendation: 0,
  },
  RECOMMENDATION_PREVIOUSLY_ADDED_TO_BASKET: {
    home: 3,
    category: 3,
    product: 3,
    basket: 0.9,
    'checkout-success': 2,
    search: 2.1,
    notfound: 2.1,
    custom: 3.1,
    triggerbased: 3,
    Personalization: 3,
    popup_recommendation: 3.1,
  },
};

export const getCurrentIntelligents = (
  pageType = 'Personalization',
  isPromotion,
  isKeyword,
  isSingleRec,
  isEmail,
  props,
) => {
  let currentIntelligents = {};
  let intelligentWithRockstar = false;

  if (
    pageType === 'home' ||
    pageType === 'category' ||
    pageType === 'search' ||
    pageType === 'notfound' ||
    pageType === '404' ||
    pageType === 'before-search-edit' ||
    pageType === 'after-search-edit'
  ) {
    if (
      pageType === 'home' ||
      pageType === 'search' ||
      pageType === 'notfound' ||
      pageType === '404' ||
      pageType === 'before-search-edit' ||
      pageType === 'after-search-edit'
    ) {
      if (pageType === 'before-search-edit') {
        // if most search for enabled then add msf to current intelligents
        if (isMostSearchForEnabled()) {
          currentIntelligents = {
            ...searchIntelligentOptions,
            RECOMMENDATION_MOST_SEARCHED_PRODUCTS: 'Most Searched For',
          };
        } else {
          currentIntelligents = { ...searchIntelligentOptions };
        }
      } else if (pageType === 'after-search-edit') {
        currentIntelligents = { ...afterSearchIntelligentOptions };
      } else {
        currentIntelligents = {
          ...currentIntelligents,
          RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
          RECOMMENDATION_NEW_COMERS: 'New Arrivals',
          RECOMMENDATION_DISCOUNTED_PRODUCTS: 'Discounted Products',
          ...intelligentOptionsHUR_4,
        };
      }
    } else {
      currentIntelligents = { ...intelligentOptionsHUR_4 };
    }
    if (isHiddenProductsEnabled()) {
      currentIntelligents = {
        ...currentIntelligents,
        RECOMMENDATION_HIDDEN_PRODUCTS: 'Hidden Products',
      };
    }
    if (
      hasReplenishment() &&
      pageType !== 'before-search-edit' &&
      pageType !== 'after-search-edit'
    ) {
      currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
        'Replenishment Algorithm';
    }
  } else if (pageType === 'product') {
    currentIntelligents = { ...intelligentOptionsHUR_8 };
    if (hasDynamicBundle()) {
      currentIntelligents.RECOMMENDATION_RELATED_COMBINE_PRODUCTS =
        'Bundle Products';
    }
    if (isBunsarEnabled()) {
      currentIntelligents.RECOMMENDATION_IMAGE_BASED_SIMILARITY = 'Look Alike';
    }
    if (isHiddenProductsEnabled()) {
      currentIntelligents = {
        ...currentIntelligents,
        RECOMMENDATION_HIDDEN_PRODUCTS: 'Hidden Products',
      };
    }
    if (hasDynamicBundle()) {
      currentIntelligents = {
        ...currentIntelligents,
        RECOMMENDATION_SHOP_THE_LOOK: 'Shop The Look',
      };
    }
    if (hasReplenishment()) {
      currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
        'Replenishment Algorithm';
    }
  } else if (pageType === 'basket' || pageType === 'checkout-success') {
    if (pageType === 'basket') {
      if (hasDynamicBundle()) {
        currentIntelligents = {
          ...intelligentOptionsT16,
          RECOMMENDATION_RELATED_COMBINE_PRODUCTS: 'Bundle Products',
          RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER_OFFLINE:
            'Mostly Purchased Together - Offline',
        };
      } else {
        currentIntelligents = {
          ...intelligentOptionsT16,
          RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER_OFFLINE:
            'Mostly Purchased Together - Offline',
        };
      }
    } else {
      currentIntelligents = {
        RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
        ...intelligentOptionsT16,
      };
    }
    if (hasReplenishment()) {
      currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
        'Replenishment Algorithm';
    }
    if (isHiddenProductsEnabled()) {
      currentIntelligents = {
        ...currentIntelligents,
        RECOMMENDATION_HIDDEN_PRODUCTS: 'Hidden Products',
      };
    }
  } else if (pageType === 'triggerbased') {
    if (hasDynamicBundle()) {
      currentIntelligents = {
        ...intelligentOptions,
        RECOMMENDATION_RELATED_COMBINE_PRODUCTS: 'Bundle Products',
      };
    } else {
      currentIntelligents = { ...intelligentOptions };
    }
    if (isHiddenProductsEnabled()) {
      currentIntelligents = {
        ...currentIntelligents,
        RECOMMENDATION_HIDDEN_PRODUCTS: 'Hidden Products',
      };
    }
    if (hasReplenishment()) {
      currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
        'Replenishment Algorithm';
    }
  } else if (pageType === 'custom') {
    if (hasDynamicBundle()) {
      currentIntelligents = {
        ...intelligentOptionsCustom,
        RECOMMENDATION_RELATED_COMBINE_PRODUCTS: 'Bundle Products',
        RECOMMENDATION_NEW_COMERS: 'New Arrivals',
      };
    } else {
      currentIntelligents = {
        ...intelligentOptionsCustom,
        RECOMMENDATION_NEW_COMERS: 'New Arrivals',
      };
    }
    if (isHiddenProductsEnabled()) {
      currentIntelligents = {
        ...currentIntelligents,
        RECOMMENDATION_HIDDEN_PRODUCTS: 'Hidden Products',
      };
    }
    if (hasReplenishment()) {
      currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
        'Replenishment Algorithm';
    }
  } else {
    currentIntelligents = {
      ...intelligentOptionsHUR_4,
      RECOMMENDATION_DISCOUNTED_PRODUCTS: 'Discounted Products',
      RECOMMENDATION_NEW_COMERS: 'New Arrivals',
      RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
      RECOMMENDATION_CATEGORY_ROCKS: 'Category Rocks',
      RECOMMENDATION_SOURCE_RELATED_PRODUCT: 'Alternative Products',
      RECOMMENDATION_COLLABORATIVE_FILTERING: 'Complementary Products',
      RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER: 'Mostly Purchased Together',
    };
    if (hasReplenishment() && pageType === 'popup_recommendation') {
      currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
        'Replenishment Algorithm';
    }
  }

  // Current Intelligents for Email
  if (isEmail) {
    const initialObj = { ...intelligentOptionsEmail };

    const finalObj = {};
    Object.keys(initialObj).forEach(key => {
      if (
        props.campaign.type === 'FLY' ||
        props.campaign.type === 'RECOMMENDATION'
      ) {
        finalObj[key] = initialObj[key];
      } else if (
        mailAlgorithmTitles(props.campaign.type).key &&
        key !== mailAlgorithmTitles(props.campaign.type).key
      ) {
        finalObj[key] = initialObj[key];
      }
    });
    currentIntelligents = finalObj;
  }
  // Current Intelligents for Promotions
  if (isPromotion) {
    currentIntelligents = intelligentOptionsPromotions;
  }

  // Current Intellgents for Keyword Recommendation
  if (isKeyword) {
    currentIntelligents = intelligentOptionsSearchKeyword;
  }

  /* ROCKSTAR ALGORITHMS */
  // Category Rocks
  if (
    pageType === 'category' ||
    pageType === 'product' ||
    pageType === 'triggerbased'
  ) {
    intelligentWithRockstar = { ...currentIntelligents };
    intelligentWithRockstar.RECOMMENDATION_CATEGORY_ROCKS = 'Category Rocks';
  }

  const sortedAlgorithmKeys = sortAlgorithmKeys(
    currentIntelligents,
    algorithmOrders,
    pageType,
  );

  const sortedAlgorithms = sortAlgorithms(
    sortedAlgorithmKeys,
    currentIntelligents,
  );

  const sortedRockstarAlgorithmKeys = sortAlgorithmKeys(
    intelligentWithRockstar,
    algorithmOrders,
    pageType,
  );

  const sortedRockstarAlgorithms = sortAlgorithms(
    sortedRockstarAlgorithmKeys,
    intelligentWithRockstar,
  );

  return intelligentWithRockstar && !isSingleRec && !isPromotion && !isKeyword
    ? sortedRockstarAlgorithms
    : sortedAlgorithms;
};

export const getStaticAlgorithms = (isPromotion, isPalantir) => {
  if (isPromotion) {
    return {
      'STATIC-PRODUCT': 'product',
      'STATIC-CATEGORY': 'category',
      'STATIC-BRAND': 'brand',
      'STATIC-LABEL': 'label',
      'STATIC-PROMOTION': 'promotion',
    };
  }
  if (isPalantir) {
    return {
      'STATIC-CATEGORY-S': 'category-s',
      'STATIC-BRAND-S': 'brand-s',
      // "STATIC-KEYWORD-S": "keyword-s" // it is disabled because after search campaign doesn't use keyword anymore
    };
  }
  return {
    'STATIC-PRODUCT': 'product',
    'STATIC-CATEGORY': 'category',
    'STATIC-BRAND': 'brand',
    'STATIC-LABEL': 'label',
  };
};

export const getSynomymOptions = () => {
  return {
    SEARCH_SYNONYMS_TWO_WAY: 'TWO_WAY',
    SEARCH_SYNONYMS_ONE_WAY: 'ONE_WAY',
  };
};

export const timeFrame = {
  TODAY: 'View - Today',
  THIS_WEEK: 'View - This Week',
  THIS_MONTH: 'View - This Month',
  TODAY_PURCHASE: 'Purchase - Today',
  THIS_WEEK_PURCHASE: 'Purchase - This Week',
  THIS_MONTH_PURCHASE: 'Purchase - This Month',
};

export const limitedTimeFrame = {
  TODAY: 'Today',
  THIS_WEEK: 'This Week',
  THIS_MONTH: 'This Month',
};

export const mostSearchTimeFrame = {
  THIS_WEEK: 'View - This Week',
};

export const previouslyAddedTimeFrame = {
  TODAY: 'View - Today',
};

export const mostLimitedTimeFrame = {
  THIS_WEEK: 'This Week',
};

export const rockstarTimeFrame = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
};

export const eventDataTimeFrame = {
  NONE: 'None',
  ...rockstarTimeFrame,
};

export const getRockstarTimeFrameMapping = value => {
  const timeFrameMapping = {
    TODAY: 'DAILY',
    THIS_WEEK: 'WEEKLY',
    THIS_MONTH: 'MONTHLY',
    TODAY_PURCHASE: 'DAILY',
    THIS_WEEK_PURCHASE: 'WEEKLY',
    THIS_MONTH_PURCHASE: 'MONTHLY',
  };
  return timeFrameMapping[value] || 'DAILY';
};

export const getJourneyBuilderCurrentIntelligent = type => {
  switch (type) {
    case 'CATEGORY':
      return { ...intelligentOptionsJBCategories };
    case 'BRAND':
      return { ...intelligentOptionsJBBrands };
    case 'SIZE':
      return { ...intelligentOptionsJBSizes };
    case 'COLOR':
      return { ...intelligentOptionsJBColours };
    case 'RECOMMEND':
      return { ...intelligentOptionsJBBest };
    default:
      return '';
  }
};

export const items = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
];

export const reactSelectItems = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
  { value: '30', label: '30' },
  { value: '35', label: '35' },
  { value: '40', label: '40' },
  { value: '45', label: '45' },
  { value: '50', label: '50' },
];

export const insertTypes = [
  { value: 'SELF', label: t('Self') },
  { value: 'BEFORE', label: t('Before') },
  { value: 'AFTER', label: t('After') },
  { value: 'APPEND', label: t('Append') },
  { value: 'PREPEND', label: t('Prepend') },
];

export const pageTypes = [
  'home',
  'category',
  'product',
  'basket',
  'checkout-success',
  'search',
  'notfound',
  '404',
  'triggerbased',
  'popup_recommendation',
  'journey_builder',
];

export const defaultValues = {
  home: {
    templateId: 'HUR_4',
    category: 'Home Page',
    criteria: {
      criterion: {
        value: 'RECOMMENDATION_SMART_OFFERS',
      },
      timeFrame: {
        disabled: true,
      },
      itemCount: {
        fullWidth: 4,
        rightSide: 1,
      },
    },
  },
  category: {
    templateId: 'HUR_4',
    category: 'Category Page',
    criteria: {
      criterion: {
        value: 'RECOMMENDATION_SOURCE_SAME_CATEGORY_LAST',
      },
      timeFrame: {
        value: 'THIS_WEEK',
        disabled: false,
      },
      itemCount: {
        fullWidth: 4,
        rightSide: 1,
      },
    },
  },
  product: {
    templateId: 'HUR_8',
    criteria: {
      criterion: {
        value: 'RECOMMENDATION_SOURCE_RELATED_PRODUCT',
      },
      timeFrame: {
        disabled: true,
      },
      itemCount: {
        fullWidth: 4,
        rightSide: 1,
      },
    },
  },
  basket: {
    templateId: 'T16',
    criteria: {
      criterion: {
        value: 'RECOMMENDATION_SOURCE_RELATED_PRODUCT_PURCHASE_BASKET',
      },
      timeFrame: {
        disabled: true,
      },
      itemCount: {
        fullWidth: 4,
        rightSide: 1,
      },
    },
  },
  'checkout-success': {
    templateId: 'T21',
    criteria: {
      criterion: {
        value: 'RECOMMENDATION_SOURCE_RELATED_PRODUCT_PURCHASE_BASKET',
      },
      timeFrame: {
        disabled: true,
      },
      itemCount: {
        fullWidth: 4,
        rightSide: 1,
      },
    },
  },
  search: {
    templateId: 'HUR_4',
    category: 'Search Page',
    criteria: {
      criterion: {
        value: 'RECOMMENDATION_SMART_OFFERS',
      },
      timeFrame: {
        disabled: true,
      },
      itemCount: {
        fullWidth: 4,
        rightSide: 1,
      },
    },
  },
  notfound: {
    templateId: 'HUR_4',
    category: '404 Page',
    criteria: {
      criterion: {
        value: 'RECOMMENDATION_SMART_OFFERS',
      },
      timeFrame: {
        disabled: true,
      },
      itemCount: {
        fullWidth: 4,
        rightSide: 1,
      },
    },
  },
  custom: {
    templateId: 'T10_1',
    category: '',
    criteria: {
      criterion: {
        value: 'RECOMMENDATION_SMART_OFFERS',
      },
      timeFrame: {
        disabled: true,
      },
      itemCount: {
        fullWidth: 4,
        rightSide: 1,
      },
    },
  },
  triggerbased: {
    templateId: 'SMSG_01',
    category: '',
    criteria: {
      criterion: {
        value: 'RECOMMENDATION_SMART_OFFERS',
      },
      timeFrame: {
        disabled: true,
      },
      itemCount: {
        fullWidth: 4,
        rightSide: 1,
      },
    },
  },
  popup_recommendation: {
    templateId: 'T10_1',
    category: '',
    criteria: {
      criterion: {
        value: 'RECOMMENDATION_SMART_OFFERS',
      },
      timeFrame: {
        disabled: true,
      },
      itemCount: {
        fullWidth: 4,
        rightSide: 1,
      },
    },
  },
  journey_builder: {
    templateId: 'JB_1',
    category: '',
    criteria: {
      criterion: {
        value: 'RECOMMENDATION_SMART_OFFERS',
      },
      timeFrame: {
        disabled: true,
      },
      itemCount: {
        fullWidth: 4,
      },
    },
  },
};

export const params = {
  user: {
    title: 'User',
    variables: [
      'fullName',
      'email',
      'phone',
      'age',
      'segments',
      'username',
      'gender',
      'location',
    ],
  },
  event: {
    title: 'Event',
    variables: [
      'userId',
      'sessionId',
      'device',
      'browser',
      'os',
      'pageUrl',
      'referrer',
    ],
  },
  product: {
    title: 'Product',
    variables: [
      'name',
      'productId',
      'description',
      'url',
      'image',
      'price',
      'oldPrice',
      'currency',
      'brand',
      'gender',
      'categories',
      'labels',
    ],
  },
  productCounter: {
    title: 'Product Counters',
    variables: ['DAILY', 'WEEKLY', 'MONTHLY'],
  },
  productPurchaseCounter: {
    title: 'Product Purchase Counters',
    variables: ['DAILY', 'WEEKLY', 'MONTHLY'],
  },
  constant: {
    title: 'Constants',
    variables: ['currentDate', 'currentTime', 'currentDateTime'],
  },
};

export const behaviouralTargetingParams = {
  user: {
    title: 'User',
    variables: [
      'fullName',
      'email',
      'phone',
      'age',
      'segments',
      'username',
      'gender',
      'location',
    ],
  },
  event: {
    title: 'Event',
    variables: [
      'userId',
      'sessionId',
      'device',
      'browser',
      'os',
      'pageUrl',
      'referrer',
    ],
  },
  product: {
    title: 'Product',
    variables: [
      'name',
      'productId',
      'description',
      'url',
      'image',
      'price',
      'oldPrice',
      'currency',
      'brand',
      'gender',
      'categories',
      'labels',
    ],
  },
  productCounter: {
    title: 'Product Counters',
    variables: ['DAILY', 'WEEKLY', 'MONTHLY'],
  },
  productPurchaseCounter: {
    title: 'Product Purchase Counters',
    variables: ['DAILY', 'WEEKLY', 'MONTHLY'],
  },
  constant: {
    title: 'Constants',
    variables: ['currentDate', 'currentTime', 'currentDateTime'],
  },
  productAddedToBasket: {
    title: 'Product Added To Basket Counters',
    variables: [
      '1HOUR',
      '2HOURS',
      '3HOURS',
      '6HOURS',
      '12HOURS',
      'DAILY',
      'WEEKLY',
      'MONTHLY',
    ],
  },
  productStockCount: {
    title: 'Product Stock Count',
    variables: ['none'],
  },
  productSavingOver: {
    title: 'Product Saving Over',
    variables: ['none'],
  },
  productLastBoughtTime: {
    title: 'Product Last Bought Time',
    variables: ['none'],
  },
  productScoreCount: {
    title: 'Product Score Count',
    variables: ['none'],
  },
  productReviewCount: {
    title: 'Product Review Count',
    variables: ['none'],
  },
};

export const campaignData = {
  scenarioName: '',
  templateId: 'HUR_4',
  accountId: '',
  instanceId: '',
  limitations: {
    activeDates: {
      startDate: null,
      endDate: null,
    },
  },
  actions: [
    {
      type: 'recommendProducts',
      targets: [],
      params: {
        notificationTitle: '',
        elimination: 'ELIMINATE_DUPLICATES',
        ordering: '',
        recommendOtherCategories: true,
        actionType: 'INPAGE_WIDGET',
        selector: '',
        eliminationFunction: '',
        insertType: 'SELF',
        recommendationTemplate: '',
        preJsCode: '',
        postJsCode: '',
        staticItems: '',
        dynamicItems: '',
        exclusions: '',
      },
      constraint: null,
      controlGroup: null,
      delayedAction: null,
      group: null,
    },
  ],
  inputs: [],
  status: 'ACTIVE',
  testMode: true,
  device: 'PC',
};

export const emailCampaignData = {
  type: '',
  scenarioName: '',
  accountId: '',
  status: 'ACTIVE',
  defaultRecommendation: [],
  additionalRecommendations: [],
  inclusions: [],
  exclusions: [],
  params: {},
  prioritized: false,
  additionalRecommendationAllowed: true,
  instanceId: '',
  sendToIdentifyApi: false,
};

export const notifications = {
  test: {
    add: {
      content:
        'Recommendation widget is activated in test mode and only test users can see. You can check the widget by activating test mode in your browser.',
    },
    update: {
      content: 'Recommendation widget is updated in test mode.',
    },
  },
  live: {
    update: {
      content: 'Recommendation widget is updated in live mode.',
    },
  },
};

export const formFieldButtons = {
  input: {
    title: 'Input',
    icon: 'text',
    className: 'add-criteria',
  },
  textArea: {
    title: 'Text Area',
    icon: 'fieldTextArea',
    className: 'add-criteria',
  },
  checkbox: {
    title: 'Checkbox',
    icon: 'fieldCheckbox',
    className: 'add-criteria',
  },
  radio: {
    title: 'Radio Button',
    icon: 'fieldRadio',
    className: 'add-criteria',
  },
};

export const getSearchInstanceId = searchId => {
  if (searchId.includes('fcs_')) {
    return searchId;
  }
  switch (searchId) {
    case 'product':
    case 'category':
    case 'brand':
      return 'SEARCH';
    case 'keyword':
    case 'bs_product':
    case 'bs_category':
    case 'bs_brand':
    case 'bs_keyword':
      return 'BEFORE_SEARCH';
    default:
      return '';
  }
};

export default {
  items,
  params,
  behaviouralTargetingParams,
  timeFrame,
  rockstarTimeFrame,
  insertTypes,
  campaignData,
  defaultValues,
  criterionButtons,
  exclusionButtons,
  inclusionButtons,
  intelligentOptions,
  intelligentOptionsHUR_4,
  intelligentOptionsCustom,
  intelligentOptionsHUR_8,
  intelligentOptionsT16,
  notifications,
  formFieldButtons,
  algorithmOrders,
  getCurrentIntelligents,
};
