import React from "react";
import {connect} from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";
import {SortableHandle} from "react-sortable-hoc";
import Tooltip from "../../tooltip";
import {t} from "../../../system/ui";
import Icons from "../../icons";
import {
    rockstarTimeFrame,
    intelligentOptionsJB_1,
    reactSelectItems as items,
    getJourneyBuilderCurrentIntelligent
} from "../../../constants/datamaps/wizard";
import classNames from "classnames";


class JourneyBuilderIntelligent extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state = {
            itemCount: 0,
            timeFrame: ""
        };
        this.onChange = this.onChange.bind(this);
        this.onTimeFrameChange = this.onTimeFrameChange.bind(this);
        this.onItemCountChange = this.onItemCountChange.bind(this);
        this.onSelectInputChange = this.onSelectInputChange.bind(this);
    }

    onChange(e) {
        let value = e.target.value;
        let timeFrame = "WEEKLY";

        if(value==="RECOMMENDATION_SMART_OFFERS" || value==="RECOMMENDATION_PRICE_DROP"){
            timeFrame = "THIS_WEEK";
        }
        this.setState(
            { timeFrame },
            () => {
                this.props.onCriteriaChange(this.myRef, this.props.id);
            }
        );
    }

    onSelectInputChange(inputValue) {
        let maxCount = 50;
        if (parseInt(inputValue) > maxCount) {
            return maxCount.toString();
        }
        return inputValue;
    }

    onTimeFrameChange(e) {
        this.setState(
            {timeFrame: e.target.value},
            () => {
                this.props.onCriteriaChange(this.myRef, this.props.id);
            }
        );
    }

    onItemCountChange(selected) {
        this.setState(
            {itemCount: selected.value},
            () => {
                this.props.onCriteriaChange(this.myRef, this.props.id);
            }
        );
    }

    render() {
        const DragHandle = SortableHandle(() => (
            <span className="draghandler">
        <Icons name="burger" color="#e8e7f2"/>
      </span>
        ));
        let currentIntelligents = this.props.values.timeFrame ?
            intelligentOptionsJB_1 :
            getJourneyBuilderCurrentIntelligent(this.props.mainType);

        let className =  this.props.mainType!=='RECOMMEND' || this.props.values.timeFrame ? "criteria-name-search": "criteria-name-jb";
        let defaultCriterion = "",
            defaultTimeFrame = "",
            currentTimeFrame = "",
            itemCount = 8;
        if (Object.keys(this.props.values).length > 0) {
            defaultCriterion =
                (this.props.values.criterion && this.props.values.criterion.value) || "";
            itemCount =
                (this.props.values.itemCount && this.props.values.itemCount.value) || 8;

            if (this.state.timeFrame && this.state.timeFrame.length > 0) {
                defaultTimeFrame = this.state.timeFrame;
                currentTimeFrame = this.state.timeFrame === "THIS_WEEK" ? {THIS_WEEK : "View-This Week"} : rockstarTimeFrame;
            } else if (this.props.values.timeFrame) {
                defaultTimeFrame = this.props.values.timeFrame.value;
                currentTimeFrame = this.props.values.timeFrame.value === "THIS_WEEK" ? {THIS_WEEK : "View-This Week"} : rockstarTimeFrame;
            }
        }
        let isTimeFrameDisabled= defaultCriterion === "RECOMMENDATION_SMART_OFFERS" || defaultCriterion === "RECOMMENDATION_PRICE_DROP";

        return (
            <li className="wizard-criterion" ref={this.myRef}>
                <DragHandle/>
                <span className="criteria-type">
                 <Tooltip content={t("Intelligent")} alignment="left">
                    <Icons name="brain" color="#5A7391"/>
                 </Tooltip>
                </span>
                <label className={classNames("item item-field is-select",className)}>
                    <select
                        name="criterion"
                        className="one-whole criteria-field"
                        defaultValue={defaultCriterion}
                        onChange={this.onChange}
                    >
                        {Object.keys(currentIntelligents).map((item) => {
                            return (
                                <option key={item} value={item}>
                                    {t(currentIntelligents[item])}
                                </option>
                            );
                        })}
                    </select>
                </label>
                {this.props.values.timeFrame &&
                <label
                    className={
                        (isTimeFrameDisabled)
                            ? "item item-field is-select-jb criteria-time-frame js-disabled"
                            : "item item-field is-select criteria-time-frame"
                    }
                >
                    <select
                        key={Math.random()}
                        name="timeFrame"
                        className="one-whole criteria-field"
                        defaultValue={defaultTimeFrame}
                        onChange={this.onTimeFrameChange}
                        disabled={isTimeFrameDisabled}
                    >
                        {Object.keys(currentTimeFrame).map(item => {
                            return (
                                <option key={item} value={item}>
                                    {t(currentTimeFrame[item])}
                                </option>
                            );
                        })}
                    </select>
                </label>}

                <label className={"item item-field is-select criteria-item-count"}>
                    <span className="item-label">{this.props.mainType.toLowerCase()}</span>
                    <Select
                        value={this.state.itemCount ? this.state.itemCount : itemCount}
                        options={items}
                        name="itemCount"
                        clearable={false}
                        searchable={true}
                        onChange={this.onItemCountChange}
                        className="one-whole criteria-field"
                        onInputChange={this.onSelectInputChange}
                    />
                </label>
                {
                    (this.props.mainType === "CATEGORY" || this.props.mainType === "BRAND" || this.props.mainType === "RECOMMEND") &&
                    <a
                        onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
                        className={"cancel-action"}
                    >
                        <Icons name="crossCircle"/>
                    </a>
                }
            </li>
        );
    }
}

JourneyBuilderIntelligent.contextTypes = {
    router: PropTypes.object.isRequired,
};

const mapStatesToProps = (store) => ({
    discovery: store.jbWizard.discovery,
    delivery: store.jbWizard.delivery
});

export default connect(mapStatesToProps)(JourneyBuilderIntelligent);