const languages = {
  html: 'htmlmixed',
  preJs: 'javascript',
  postJs: 'javascript',
  css: 'css',
};

const defaultOptions = {
  theme: 'mbo',
  lineNumbers: true,
  lineWrapping: true,
  mode: 'htmlmixed',
};

const constantDevices = ['PC', 'MOBILE', 'ANDROID', 'IOS'];

const initialCampaignDetails = {
  eventName: '',
  title: '',
  campaignStatus: '',
};

export { languages, defaultOptions, constantDevices, initialCampaignDetails };
