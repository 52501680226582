import React from 'react';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { wizardActions, uiActions } from '../../actions';
import { searchMultipleProducts } from '../../modules/product/ajax';
import { idPropFunc } from '../../system/id';
import { defaultEmailAlgorithms } from './utils';

import CriteriaProduct from './criterion-product';
import CriteriaCategory from './criterion-category';
import CriteriaIntelligent from './criterion-intelligent';
import CriteriaBrand from './criterion-brand';
import CriteriaLabel from './criterion-label';
import CriteriaPromotion from './criterion-promotion';
import CriteriaSingleProduct from './criterion-single-product';
import CriteriaKeyword from './criteria-keyword';
import CriteriaBrandS from './criteria-brands';
import CriteriaCategoryS from './criteria-categorys';

const criteriaTypes = {
  intelligent: CriteriaIntelligent,
  category: CriteriaCategory,
  product: CriteriaProduct,
  brand: CriteriaBrand,
  label: CriteriaLabel,
  promotion: CriteriaPromotion,
  singleProduct: CriteriaSingleProduct,
  'keyword-s': CriteriaKeyword,
  'category-s': CriteriaCategoryS,
  'brand-s': CriteriaBrandS,
};

class Criteria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staticProducts: [],
      allItems: [],
      removedCriteria: [],
    };

    this.handleCriteriaRemove = this.handleCriteriaRemove.bind(this);
    this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
    this.setStaticProducts = this.setStaticProducts.bind(this);
    this.onStaticSortEnd = this.onStaticSortEnd.bind(this);
    this.onDynamicSortEnd = this.onDynamicSortEnd.bind(this);
    this.onAllSortEnd = this.onAllSortEnd.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onSortMove = this.onSortMove.bind(this);
    this.handleSelectCriteriaChange = this.handleSelectCriteriaChange.bind(
      this,
    );
    this.handleSearchCriteriaChange = this.handleSearchCriteriaChange.bind(
      this,
    );
    this.handleCriteriaItemCountChange = this.handleCriteriaItemCountChange.bind(
      this,
    );
  }

  handleCriteriaRemove(criteriaId) {
    let modifiedCriteria;
    modifiedCriteria = this.props.criteria.filter(criteria => {
      return criteria.id !== criteriaId;
    });
    const remCrit = [...this.state.removedCriteria];
    this.setState({ removedCriteria: [...remCrit, criteriaId] });
    if (this.props.selectedPage === 'afterSearch') {
      wizardActions.removeNoResultCriteria(modifiedCriteria);
    } else {
      wizardActions.removeCriteria(modifiedCriteria);
    }
    uiActions.formEdited();
    if (this.props.isReco) {
      this.props.updateAlgorithmString(this.state.allItems);
    }
  }

  componentDidMount() {
    if (this.props.isEmail) {
      this.setStaticProducts(this.props);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.criteria !== newProps.criteria) {
      this.setStaticProducts(newProps);
    }
    if (
      this.props.campaign &&
      this.props.campaign.searchAssets !== undefined &&
      this.props.campaign.searchAssets !== newProps.campaign.searchAssets
    ) {
      this.setStaticProducts(newProps);
    }
  }

  setStaticProducts(newProps) {
    let staticItems = [];
    const newItems = [];
    newProps.criteria.map(item => {
      if (item !== undefined) {
        newItems.push(item);
        if (item.type == 'product' && item.values.productId) {
          staticItems.push(item.values.productId.value);
        }
      }
    });
    if (staticItems.length) {
      staticItems = Array.isArray(staticItems[0])
        ? staticItems[0]
        : staticItems;
      searchMultipleProducts(staticItems, response => {
        this.setState(
          {
            staticProducts: response,
            allItems: newItems,
          },
          () => {
            if (this.props.isReco) {
              this.props.updateAlgorithmString(this.state.allItems);
            }
          },
        );
      });
    } else {
      this.setState(
        {
          allItems: newItems,
        },
        () => {
          if (this.props.isReco) {
            this.props.updateAlgorithmString(this.state.allItems);
          }
        },
      );
    }
  }

  handleSearchCriteriaChange(fields, criteriaId, selectedCategory) {
    const criteria =
      this.props.criteria.length !== 0
        ? this.props.criteria
        : this.props.campaign.searchAssets.length !== 0
        ? this.props.campaign.searchAssets
        : this.props.criteria;
    const inputs =
      typeof fields !== 'undefined'
        ? fields.querySelectorAll('.criteria-field')
        : '';
    const values = {};
    if (inputs.length > 0) {
      inputs.forEach(item => {
        if (!item.name) {
          if (selectedCategory) {
            if (selectedCategory.treeView !== undefined) {
              values.criterion = {
                clickable: selectedCategory.clickable,
                categoryTreeView: selectedCategory.treeView,
                highlight: selectedCategory.highlight,
              };
              values.itemCount = {
                value: selectedCategory.value,
              };
              values.mobileItemCount = {
                value: selectedCategory.mobileValue,
              };
            } else {
              values.criterion = {
                clickable: selectedCategory.clickable,
                highlight: selectedCategory.highlight,
              };
              values.itemCount = {
                value: selectedCategory.value,
              };
              values.mobileItemCount = {
                value: selectedCategory.mobileValue,
              };
            }
          }
        } else {
          values[item.name] = {
            value: item.value,
          };
        }
      });

      for (let i = 0; i < criteria.length; i++) {
        if (criteria[i] !== undefined && criteria[i].id === criteriaId) {
          criteria[i].values = { ...values };
          // this.props.getData(criteria[i]);
          break;
        }
      }

      uiActions.formEdited();
    }
  }

  handleCriteriaItemCountChange(count, id) {
    const { criteria } = this.props;
    for (let i = 0; i < criteria.length; i++) {
      if (criteria[i] !== undefined && criteria[i].id === id) {
        criteria[i].values = {
          ...criteria[i].values,
          itemCount: {
            value: count,
          },
        };
        break;
      }
    }

    if (this.props.selectedPage === 'afterSearch') {
      wizardActions.addNoResultCriteria(criteria);
    } else {
      wizardActions.addCriteria(criteria);
    }
    if (this.props.isReco) {
      this.props.updateAlgorithmString(criteria);
    }

    if (this.props.isSearch) {
      this.props.updateChangeCounts(criteria);
    }
    uiActions.formEdited();
  }

  handleCriteriaChange(fields, criteriaId, selectedCategory) {
    const { criteria } = this.props;
    const inputs =
      typeof fields !== 'undefined'
        ? fields.querySelectorAll('.criteria-field')
        : '';
    const values = {};

    if (inputs.length > 0) {
      inputs.forEach(item => {
        if (!item.name) {
          if (selectedCategory) {
            values.criterion = {
              value: selectedCategory,
            };
          } else {
            values.itemCount = {
              value: item.querySelector('input').value,
            };
          }

          if (item?.querySelector('input')?.name === 'itemCount') {
            values.itemCount = {
              value: item.querySelector('input').value,
            };
          }
        } else {
          values[item.name] = {
            value: item.value,
          };
        }
      });

      let isDifferent = false;
      for (let i = 0; i < criteria?.length; i++) {
        if (criteria[i] !== undefined && criteria[i]?.id === criteriaId) {
          // check if the values are different
          const propsValues = criteria[i]?.values;
          if (
            Object.keys(propsValues)?.length !== Object.keys(values)?.length
          ) {
            isDifferent = true;
          }
          Object.keys(propsValues).forEach(key => {
            if (propsValues[key]?.value !== values[key]?.value) {
              isDifferent = true;
            }
          });

          criteria[i].values = {
            ...criteria[i]?.values,
            ...values,
          };
          break;
        }
      }

      if (isDifferent) {
        if (this.props.selectedPage === 'afterSearch') {
          wizardActions.addNoResultCriteria(criteria);
        } else {
          wizardActions.addCriteria(criteria);
        }

        if (this.props.isReco) {
          this.props.updateAlgorithmString(criteria);
        }

        if (this.props.isSearch) {
          this.props.updateChangeCounts(criteria);
        }
      }
      uiActions.formEdited();
    }
  }

  handleSelectCriteriaChange(criteriaId, newValues) {
    const { criteria } = this.props;
    const values = {
      criterion: {
        value: 'Products',
      },
      productId: {
        value: '',
      },
    };
    const items = [];
    if (newValues) {
      newValues.forEach(item => {
        items.push(item.value);
      });
      values.productId.value = items;
      for (let i = 0; i < criteria.length; i++) {
        if (criteria[i].id === criteriaId) {
          criteria[i].values = { ...values };
          break;
        }
      }
      if (this.props.updateChangeCounts) {
        this.props.updateChangeCounts(criteria);
      }
      if (this.props.selectedPage === 'afterSearch') {
        wizardActions.addNoResultCriteria(criteria);
      } else {
        wizardActions.addCriteria(criteria);
      }
      uiActions.formEdited();
    }
  }

  onStaticSortEnd(options) {
    this.onSortEnd('static', options);
  }

  onDynamicSortEnd(options) {
    this.onSortEnd('dynamic', options);
  }

  onAllSortEnd(options) {
    this.onSortEnd('all', options);
  }

  onSortEnd(sortType, options) {
    const disabledEl = document.getElementsByClassName(
      'draggable--disabled',
    )[0];
    if (disabledEl && disabledEl.classList) {
      disabledEl.classList.remove('draggable--disabled');
    }
    const current = Object.assign([], this.props.criteria);
    if (sortType !== 'all') {
      const staticItems = [];
      current.forEach(item => {
        if (item.type === 'product') {
          staticItems.push(item);
        }
      });
      const oldIndex =
        sortType === 'static'
          ? options.oldIndex
          : options.oldIndex + staticItems.length;
      const newIndex =
        sortType === 'static'
          ? options.newIndex
          : options.newIndex + staticItems.length;
      const element = current[oldIndex];
      current.splice(oldIndex, 1);
      current.splice(newIndex, 0, element);
    } else {
      const { oldIndex } = options;
      const { newIndex } = options;
      const element = current[oldIndex];
      current.splice(oldIndex, 1);
      current.splice(newIndex, 0, element);
    }
    if (this.props.selectedPage === 'afterSearch') {
      wizardActions.addNoResultCriteria(current);
    } else {
      wizardActions.addCriteria(current);
    }
  }

  onSortMove(options, event) {
    let disabledEl = options.node.parentNode;
    if (disabledEl.classList.contains('criteria-list--static')) {
      disabledEl = document.getElementsByClassName('criteria-list--dynamic')[0];
    } else {
      disabledEl = document.getElementsByClassName('criteria-list--static')[0];
    }
    if (disabledEl && disabledEl.classList) {
      disabledEl.classList.add('draggable--disabled');
    }
  }

  render() {
    const staticItems = [];
    let dynamicItems = [];
    const allItemsExcludeMain = [];
    const mainItem = [];
    let mainItemAdded = false;
    const defaultAlgorithm = defaultEmailAlgorithms[this.props.emailType];
    this.state.allItems.forEach(item => {
      if (this.props.isEmail) {
        if (
          !mainItemAdded &&
          item.values?.criterion?.value === defaultAlgorithm
        ) {
          mainItem.push(item);
          mainItemAdded = true;
        } else {
          allItemsExcludeMain.push(item);
        }
      } else if (item.type === 'product') {
        staticItems.push(item);
      } else {
        dynamicItems.push(item);
      }
    });
    const flyModeItems = mainItem.concat(allItemsExcludeMain);
    const SortableItem = SortableElement(({ item, isMain, emailType }) => {
      const Component =
        item.type === 'product' && this.props.isSingleRec
          ? criteriaTypes.singleProduct
          : criteriaTypes[item.type];
      dynamicItems = dynamicItems.map(i => {
        return i.id !==
          (this.state.removedCriteria[0] !== undefined
            ? this.state.removedCriteria[0].id
            : this.state.removedCriteria.id)
          ? i
          : '';
      });
      return (
        <div
          className='wizard-item-draggable wizard-item-cards-draggable'
          {...idPropFunc('Te', item.type)}
        >
          <Component
            key={item.id}
            highlight
            {...item}
            onCriteriaRemove={this.handleCriteriaRemove}
            onCriteriaChange={this.handleCriteriaChange}
            onCriteriaItemCountChange={this.handleCriteriaItemCountChange}
            onSelectCriteriaChange={this.handleSelectCriteriaChange}
            onSearchCriteriaChange={this.handleSearchCriteriaChange}
            isEdit={this.props.isEdit}
            isSingleRec={this.props.isSingleRec}
            selectedPage={this.props.selectedPage}
            isPromotion={this.props.isPromotion}
            isKeyword={this.props.isKeyword}
            staticProducts={this.state.staticProducts}
            isOnlySelection={staticItems.length + dynamicItems.length === 1}
            selectedType={this.props.selectedType}
            campaign={this.props.campaign}
            isEmail={this.props.isEmail}
            isMain={isMain}
            emailType={emailType}
            isSearch={this.props.isSearch}
          />
        </div>
      );
    });
    const SortableList = SortableContainer(({ items, isMain, emailType }) => {
      let itemClass = 'criteria-list ';
      if (items[0]) {
        itemClass +=
          items[0].type === 'product'
            ? 'criteria-list--static'
            : 'criteria-list--dynamic';
      }
      return (
        <ol className={itemClass}>
          {items.map((value, index) => (
            <SortableItem
              key={`sira${index}`}
              index={index}
              item={value}
              isMain={isMain}
              emailType={emailType}
            />
          ))}
        </ol>
      );
    });
    if (this.props.isPushModule) {
      return (
        <div className='wizard-items-container'>
          {this.state.allItems.map(item => {
            const Component = criteriaTypes[item.type];
            return (
              <div key={item.id}>
                <Component
                  key={item.id}
                  {...item}
                  onCriteriaRemove={this.handleCriteriaRemove}
                  onCriteriaChange={this.handleCriteriaChange}
                  onSelectCriteriaChange={this.handleSelectCriteriaChange}
                  isSingleRec={this.props.isSingleRec}
                  selectedPage={this.props.selectedPage}
                  isPromotion={this.props.isPromotion}
                  isKeyword={this.props.isKeyword}
                  staticProducts={this.state.staticProducts}
                  isOnlySelection={
                    staticItems.length + dynamicItems.length === 1
                  }
                  selectedType={this.props.selectedType}
                  campaign={this.props.campaign}
                  isPushModule={this.props.isPushModule}
                  pushType={this.props.campaign ? this.props.campaign.type : ''}
                />
              </div>
            );
          })}
        </div>
      );
    }
    if (
      mainItem.length ||
      staticItems.length > 0 ||
      dynamicItems.length > 0 ||
      allItemsExcludeMain.length
    ) {
      if (this.props.isEmail) {
        if (
          this.props.emailType !== 'FLY' &&
          this.props.emailType !== 'RECOMMENDATION'
        ) {
          return (
            <div className='wizard-items-container'>
              {mainItem.length > 0 && (
                <SortableList
                  items={mainItem}
                  onSortEnd={this.onStaticSortEnd}
                  useDragHandle
                  helperClass='dragitem'
                  onSortStart={this.onSortMove}
                  isMain
                  emailType={this.props.emailType}
                />
              )}
              {allItemsExcludeMain.length > 0 && (
                <SortableList
                  items={allItemsExcludeMain}
                  onSortEnd={this.onAllSortEnd}
                  useDragHandle
                  helperClass='dragitem'
                  onSortStart={this.onSortMove}
                  emailType={this.props.emailType}
                />
              )}
            </div>
          );
        }
        return (
          <div className='wizard-items-container'>
            {flyModeItems.length > 0 && (
              <SortableList
                items={flyModeItems}
                onSortEnd={this.onAllSortEnd}
                useDragHandle
                helperClass='dragitem'
                onSortStart={this.onSortMove}
                emailType={this.props.emailType}
              />
            )}
          </div>
        );
      }
      if (this.props.isSearch === true) {
        return (
          <div className='wizard-items-container'>
            {staticItems.length > 0 && (
              <SortableList
                items={staticItems.concat()}
                onSortEnd={this.onStaticSortEnd}
                useDragHandle
                helperClass='dragitem'
                onSortStart={this.onSortMove}
              />
            )}
            {dynamicItems.length > 0 && (
              <SortableList
                items={dynamicItems}
                onSortEnd={this.onDynamicSortEnd}
                useDragHandle
                helperClass='dragitem'
                onSortStart={this.onSortMove}
              />
            )}
          </div>
        );
      }
      return (
        <div className='wizard-items-container'>
          {staticItems.length > 0 && (
            <SortableList
              items={staticItems}
              onSortEnd={this.onStaticSortEnd}
              useDragHandle
              helperClass='dragitem'
              onSortStart={this.onSortMove}
            />
          )}
          {dynamicItems.length > 0 && (
            <SortableList
              items={dynamicItems}
              onSortEnd={this.onDynamicSortEnd}
              useDragHandle
              helperClass='dragitem'
              onSortStart={this.onSortMove}
            />
          )}
        </div>
      );
    }
    return '';
  }
}

const mapStatesToProps = (store, thisProps) => ({
  criteria:
    thisProps.selectedPage === 'afterSearch'
      ? store.wizard.noResultCriteria
      : store.wizard.criteria,
});

export default connect(mapStatesToProps)(Criteria);
