import React from 'react';
import './ListActions.scss';
import { t } from '../../../system/ui';
import Icons from '../../icons';
import Icon from '../../icon';
import { modalActions } from '../../../actions';
import CampaignHistoryModal from '../../../modules/campaigns/historymodal';

const ListActions = ({ actions, item }) => {
  const historyAction = () => {
    const campaignId = item.instanceId;
    const campaignName = item.name;
    const campaignHistoryModal = () => (
      <CampaignHistoryModal campaignId={campaignId} engagement />
    );

    modalActions.openModal({
      title: `Campaign History (${campaignName})`,
      content: campaignHistoryModal,
    });
  };

  const handleClick = action => {
    if (!action?.actionFunc) {
      return;
    }

    switch (action.handler) {
      case 'editAction':
        action.actionFunc(item.instanceId, item.type);
        break;
      case 'historyAction':
        historyAction();
        break;
      case 'customHistoryAction':
        action.actionFunc(item.instanceId);
        break;
      case 'activeAction': {
        const status = item.status === 'ACTIVE' ? 'TEST' : 'LIVE';
        action.actionFunc(item.instanceId, item, status);
        break;
      }
      case 'removeAction':
        action.actionFunc(item.instanceId, item);
        break;
      case 'duplicateAction':
        action.actionFunc(item.instanceId, item.type, item.gamificationType);
        break;
      case 'reportAction':
        action.actionFunc(item.instanceId);
        break;
      case 'archiveAction':
        action.actionFunc(item.instanceId, item.status);
        break;
      case 'importNewAction':
        action.actionFunc(item.instanceId, item.name);
        break;
      default:
        break;
    }
  };

  const renderActiveOrPassiveIcon = type => {
    if (type === 'ACTIVE') {
      return <Icons name='archive' color='#fff' />;
    }

    return <Icons name='goActive' color='#fff' />;
  };

  let isProcessCompleted = true;
  if (item?.isProcessCompleted !== undefined) {
    isProcessCompleted = item?.isProcessCompleted;
  }

  return (
    <li className='widget-list-item'>
      <div className='right-side-wrapper'>
        <nav className='widget-nav action-nav' aria-haspopup='true'>
          {isProcessCompleted ? (
            <>
              <a className='secondary-action'>{t('Actions')}</a>
              <ul className='widget-nav-popup action-nav-popup drop-up'>
                {actions.map(action => {
                  if (
                    !action?.activeOnly ||
                    action?.activeOnly?.includes(item?.type)
                  ) {
                    return (
                      <li key={action.name}>
                        <a
                          className='widget-nav-popup-item action-nav-popup-item'
                          style={{ display: 'flex' }}
                          onClick={() => handleClick(action)}
                        >
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {action?.iconSvg ? (
                            action.handler !== 'activeAction' ? (
                              <Icons name={action.icon} color='#fff' />
                            ) : (
                              renderActiveOrPassiveIcon(item.status)
                            )
                          ) : (
                            <Icon name={action.icon} />
                          )}
                          {/* eslint-disable-next-line no-nested-ternary */}
                          {action.handler !== 'activeAction'
                            ? action.name
                            : item.status === 'ACTIVE'
                            ? 'Go Passive'
                            : 'Go Active'}
                        </a>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </>
          ) : (
            <a
              className='secondary-action'
              style={{ backgroundColor: '#9AAABE', cursor: 'not-allowed' }}
            >
              {t('Actions')}
            </a>
          )}
        </nav>
      </div>
    </li>
  );
};

export default ListActions;
