import { useEffect, useRef, useState } from 'react';

// Hook
export function useHover() {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);
        node.addEventListener('click', handleMouseOut); // it was staying on hover status. This is a clck event to reset the state.
        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
          node.removeEventListener('click', handleMouseOut);
        };
      }
      return () => {};
    },
    [ref.current, ref], // Recall only if ref changes
  );

  return [ref, value];
}
