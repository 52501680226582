/**
 * @author Bilal Cinarli
 */

'use strict';

import { createConstants } from '../../system/store';

export default createConstants(
  'UPDATE_WIZARD_FORM',
  'ADD_WIZARD_CRITERIA',
  'REMOVE_WIZARD_CRITERIA',
  'ADD_WIZARD_MAPPING',
  'REMOVE_WIZARD_MAPPING',
  'ADD_FORM_FIELD',
  'REMOVE_FORM_FIELD',
  'ADD_WIZARD_EXCLUSION',
  'REMOVE_WIZARD_EXCLUSION',
  'ADD_WIZARD_INCLUSION',
  'REMOVE_WIZARD_INCLUSION',
  'DUPLICATE_WIZARD_TEMPLATES',
  'RESET_WIZARD',
  'SHOW_STYLES',
  'HIDE_STYLES',
  'SHOW_POSITION',
  'HIDE_POSITION',
  'SHOW_TIMING',
  'HIDE_TIMING',
  'SHOW_AUDIENCE',
  'HIDE_AUDIENCE',
  'SHOW_SCHEDULING',
  'HIDE_SCHEDULING',
  'SHOW_ADVANCED_CONFIG',
  'HIDE_ADVANCED_CONFIG',
  'WIZARD_TYPE_ADD',
  'WIZARD_TYPE_EDIT',
  'SAVE_RESET',
  'SAVE_TEST',
  'SAVE_LIVE',
  'INIT_WIZARD_VALUES',
  'RESET_SEARCH_WIZARD',
  'ADD_SEARCH_WIZARD_CRITERIA',
  'ADD_SEARCH_WIZARD_EXCLUSION',
  'REMOVE_SEARCH_WIZARD_EXCLUSION',
  'ADD_JOURNEY_BUILDER_CRITERIA',
  'RESET_JOURNEY_BUILDER_CRITERIA',
  'RESET_JOURNEY_BUILDER_DELIVERY_CRITERIA',
  'ADD_JOURNEY_BUILDER_DELIVERY_CRITERIA',
  'UPDATE_CATEGORY_TREE_FLAG',
  'ADD_NO_RESULT_WIZARD_CRITERIA',
  'REMOVE_NO_RESULT_WIZARD_CRITERIA',
  'ADD_NO_RESULT_WIZARD_EXCLUSION',
  'REMOVE_NO_RESULT_WIZARD_EXCLUSION',
  'ADD_NO_RESULT_WIZARD_INCLUSION',
  'REMOVE_NO_RESULT_WIZARD_INCLUSION',
);
