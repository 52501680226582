import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Icons from '../../../icons';
import { getBrands } from '../../../../modules/account/ajax';
import { decodeHtml } from '../../../../system/string';

const RPBrands = ({ brands, setAttributes, onRemove }) => {
  const [brandOptions, setBrandOptions] = useState([]);

  useEffect(() => {
    getBrands().then(response => {
      const sorted = response.sort();
      const options = [];
      sorted.map(item => {
        options.push({
          value: item,
          label: decodeHtml(item),
        });
      });
      setBrandOptions(options);
    });
  }, []);

  return (
    <div className='sfy-attribute-field sfy-related-products'>
      <div className='sfy-attribute-field-icon'>
        <i className='icon-star noV-align' color='#7A7A7C' />
      </div>
      <div className='sfy-attribute-field-select'>
        <Select
          value={brands}
          options={brandOptions}
          onChange={selected => {
            setAttributes(selected, 'brands');
          }}
          multi
          placeholder='Select Brand'
          className='sfy-attribute-field-select-input'
          clearable={false}
        />
      </div>
      <div
        className='sfy-attribute-field-remove'
        onClick={() => onRemove('brands')}
      >
        <Icons name='crossCircle' color='#D31116' height='18' width='18' />
      </div>
    </div>
  );
};

export default RPBrands;
