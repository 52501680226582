export const defaultFieldMap = {
  'Product ID': {
    key: 'product.productId',
    sortKey: 'productId',
    type: 'text',
    className: '',
  },
  Product: { key: 'product.name', type: 'text', className: '' },
  CTR: { key: 'ctr', type: 'ratio', sortKey: 'CTR', className: '' },
  'Basket Rate': {
    key: 'basketRate',
    sortKey: 'BR',
    type: 'ratio',
    className: '',
  },
  'Purchased Products': {
    key: 'purchaseItem',
    sortKey: 'purchaseItem',
    type: 'text',
    className: '',
  },
  Purchases: {
    key: 'purchase',
    sortKey: 'purchase',
    type: 'text',
    className: '',
  },
  Revenue: {
    key: 'revenue',
    sortKey: 'revenue',
    type: 'priceText',
    className: '',
  },
  'Conversion Rate': {
    key: 'conversionRate',
    sortKey: 'CR',
    type: 'ratio',
    className: '',
  },
  'Popular Searches': {
    key: 'query',
    sortKey: 'query',
    type: 'text',
    className: '',
  },
  'Total Searches': {
    key: 'search',
    sortKey: 'search',
    type: 'text',
    className: '',
  },
  'No Result Total Searches': {
    key: 'count',
    sortKey: 'count',
    type: 'text',
    className: '',
  },
  'Per Search Value': {
    key: 'perSearchValue',
    sortKey: 'PSV',
    type: 'priceText',
    className: '',
  },
  'No-Result Searches': {
    key: 'query',
    sortKey: 'query',
    type: 'text',
    className: '',
  },
  'No-Result Ratio': {
    key: 'noResultRatio',
    sortKey: 'count',
    type: 'ratio',
    className: '',
  },
  'Most Valuable Products': {
    key: 'product',
    type: 'customComponent',
    className: '',
  },
  'Best Product': { key: 'product', type: 'customComponent', className: '' },
  'Best Product Detail': {
    key: 'product.productId',
    sortKey: 'productId',
    type: 'text',
    className: '',
  },
};

const defaultCellData = {
  isImage: false,
  className: '',
  content: [],
  isBooleanIconFields: false,
  trueContent: '',
  falseContent: '',
  isPriceWithCurrency: false,
};

export const convertTableCellData = (header, data, tableName) => {
  let cellData;

  const fieldMap = header.fieldMap || defaultFieldMap[header.name];
  if (fieldMap) {
    switch (fieldMap.type) {
      case 'text':
        cellData = getTextData(data, fieldMap);
        break;
      case 'ratio':
        cellData = getRatioData(data, fieldMap);
        break;
      case 'priceText':
        cellData = getPriceTextData(data, fieldMap);
        break;
      case 'customComponent':
        cellData = getCustomComponent(data, fieldMap, header.component);
        break;
      default:
        cellData = getTextData(data, fieldMap);
    }
  }
  cellData.type = fieldMap.type;
  cellData.sortKey = fieldMap.sortKey;
  cellData.tableName = tableName;
  return cellData;
};

export const getTextData = (data, fieldMap) => {
  const cellData = { ...defaultCellData };
  let currentData = '';
  if (fieldMap.key.split('.').length > 1) {
    currentData = data;
    for (const property of fieldMap.key.split('.')) {
      if (!currentData[property]) {
        currentData = '-';
        break;
      } else {
        currentData = currentData[property];
      }
    }
  } else {
    currentData = data[fieldMap.key];
  }

  cellData.content = currentData;
  cellData.className = fieldMap.className;
  return cellData;
};

export const getRatioData = (data, fieldMap) => {
  const cellData = { ...defaultCellData };
  cellData.content = `%${data[fieldMap.key]}`;
  cellData.className = fieldMap.className;
  return cellData;
};

export const getPriceTextData = (data, fieldMap) => {
  const cellData = { ...defaultCellData };
  cellData.content = data[fieldMap.key];
  cellData.className = fieldMap.className;
  cellData.isPriceWithCurrency = true;
  return cellData;
};

export const getCustomComponent = (data, fieldMap, customComponent) => {
  const cellData = { ...defaultCellData };
  cellData.componentData = data[fieldMap.key];
  cellData.component = customComponent;
  cellData.className = fieldMap.className;

  return cellData;
};
