import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { setTitle } from '../../../../system/document';
import { clone } from '../../../../system/object';
import { t } from '../../../../system/ui';

import { uiActions, userActions } from '../../../../actions';

import NavigationTab from '../ components/navigation-tab';
import UserAccountList from '../../../../components/admin/user.accounts';
import { AccountUpdate } from '../../../../constants/datamaps/notifications';
import { getUser, updateAccount } from '../../../../modules/auth/user';
import { fieldMapper } from './utils';

export const SettingsManageUser = props => {
  const [state, setState] = useState({
    fields: [],
    buttons: [
      {
        type: 'tertiary',
        label: 'Update',
        submitAnimation: true,
      },
    ],
    error: null,
    ui: 'isLoaded',
  });

  const forms = useRef(null);

  const { isLoaded, isLoading, showNotification } = uiActions;

  const updateForm = account => {
    setState(prev => ({
      ...prev,
      fields: fieldMapper(account),
    }));
  };

  const update = e => {
    e.preventDefault();
    isLoading();
    setState({
      ui: 'isSubmitting',
    });

    const isSwitchedUser = !!props.switchedUser.username;
    const account = isSwitchedUser
      ? clone(props.switchedUser.account)
      : clone(props.user.account);

    if (typeof account.contact === 'undefined') {
      account.contact = {};
    }
    account.domain = forms.current.querySelector('[name="domain"]').value;
    account.contact.person = forms.current.querySelector(
      '[name="person"]',
    ).value;
    account.contact.phone = forms.current.querySelector('[name="phone"]').value;
    account.contact.address = forms.current.querySelector(
      '[name="address"]',
    ).value;

    updateAccount(account, response => {
      if (response.accountId) {
        const user = isSwitchedUser
          ? clone(props.switchedUser)
          : clone(props.user);

        user.account = response;

        if (isSwitchedUser) {
          userActions.updateSwitchUser(user);
        } else {
          userActions.updateUserAccount(user);
        }
      }

      isLoaded();
      setState(prev => ({
        ...prev,
        ui: 'isLoaded',
      }));
      showNotification({
        content: AccountUpdate,
      });
    });
  };

  useEffect(() => {
    setTitle(t('Account Settings'));
    isLoading();

    getUser(response => {
      updateForm(response.account);
      isLoaded();
    });
  }, [props.user]);

  return (
    <>
      <NavigationTab />
      <div className='page-forms' ref={forms}>
        <div>
          <div className='user-accounts'>
            <UserAccountList user={props.user} userToEdit={props.userToEdit} />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStatesToProps = store => ({
  user: store.user.user,
  language: store.ui.language,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(SettingsManageUser);
