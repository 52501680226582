import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Input = ({
  label,
  onChange,
  inputProps,
  extraClasses,
  extraDivClasses,
}) => {
  const inputClasses = classnames('one-whole criteria-field', {
    ...extraClasses,
  });
  const divClasses = classnames(
    `item item-field is-select criteria-item-mobile-count ${extraDivClasses}`,
  );
  return (
    <div className={divClasses}>
      <span className='item-label'>{label}</span>
      <input onChange={onChange} {...inputProps} className={inputClasses} />
    </div>
  );
};

Input.defaultProps = {
  extraClasses: null,
  onChange: null,
  extraDivClasses: '',
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  inputProps: PropTypes.instanceOf(Object).isRequired,
  extraClasses: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
  extraDivClasses: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
  onChange: PropTypes.func,
};

export default Input;
