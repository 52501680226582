import React from 'react';
import classes from './ButtonContainer.module.scss';

import { DiffButton } from '../diff-button/DiffButton';
import { EventButton } from '../event-button/EventButton';
import { DrawerButton } from '../drawer-button/DrawerButton';
import { ActionButtonGroup } from '../button-group/ActionButtonGroup';

export const ButtonContainer = ({
  showDiffEditor,
  setShowDiffEditor,
  handleDrawerOpen,
  groupedErrorMarkers,
  fileName,
  setFileName,
}) => {
  return (
    <div className={classes['button-container']}>
      <div className={classes['icon-container']}>
        <DrawerButton
          onClick={() => handleDrawerOpen('errorBoundary')}
          errorOccurred={Object.keys(groupedErrorMarkers).length}
        />
        <DiffButton
          showDiffEditor={showDiffEditor}
          setShowDiffEditor={setShowDiffEditor}
        />
        <EventButton onClick={() => handleDrawerOpen('event-info')} />
      </div>
      <ActionButtonGroup fileName={fileName} setFileName={setFileName} />
    </div>
  );
};
