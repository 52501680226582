import { createAction } from '../system/store';

export const updateSwitchRegion = region =>
  createAction('UPDATE_SWITCH_REGION', region);

export const resetSwitchRegion = () => createAction('RESET_SWITCH_REGION');

export default {
  updateSwitchRegion,
  resetSwitchRegion,
};
