/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import uiActions from '../actions/ui';
import { t } from '../system/ui';
import { setTitle } from '../system/document';

import AddPushCampaign from '../components/push/add';
import { initialStates, pushTypes } from '../constants/datamaps/push';
import { clone } from '../system/object';

class AddPushPermission extends Component {
  constructor(props) {
    super(props);
    this.state = clone(initialStates[pushTypes[this.props.params.pushType]]);
  }

  componentDidMount() {
    setTitle(t('Add Campaign'));
    uiActions.engagementConfirmOnLeave(this);
  }

  render() {
    return (
      <AddPushCampaign
        type={this.props.params.pushType}
        actualType={pushTypes[this.props.params.pushType]}
        title={this.state.title}
        mainField={this.state.mainField}
        additionalFields={this.state.additionalFields}
        config={this.state.config}
        additionalConfig={this.state.additionalConfig}
        buttons={this.state.buttons}
        mutualFields={this.state.mutualFields}
      />
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps)(AddPushPermission);
