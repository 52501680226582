/**
 * Created by mehmetyurtar on 19/09/2017.
 */
"use strict";

import React from "react";
import { connect } from "react-redux";

import { t, confirm } from "../../system/ui";

import { campaignActions, filterActions } from "../../actions";

import {
  moveCampaignToTest,
  moveCampaignToLive
} from "../../modules/campaign/events";

import { getNow } from "../../system/date";

import Tooltip from "../../components/tooltip";

import Icons from "../../components/icons";

class EngagementOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLive: false
    };

    this.setIsLive = this.setIsLive.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isLive = this.isLive.bind(this);
    this.isActive = this.isActive.bind(this);
  }

  componentDidMount() {
    this.setIsLive();
  }

  setIsLive() {
    this.setState({
      isLive: this.isLive()
    });
  }

  handleChange() {
    if (this.state.isLive) {
      moveCampaignToTest(this.props.instance).then(response => {
        this.props.onUpdate(this.props.instance, response.response.status);
        this.setState({
          isLive: false
        });
      });
    } else {
      moveCampaignToLive(this.props.instance).then(response => {
        this.props.onUpdate(this.props.instance, response.response.status);
        this.setState({
          isLive: true
        });
      });
    }
  }

  isLive() {
    return this.props.testMode === false;
  }

  isActive() {
    return this.props.status === "ACTIVE" || this.props.status === "TEST";
  }

  render() {
    return (
      <div className="widget-options">
        {this.isActive() ? (
          <div>
            <label className="item is-toggle">
              <input
                type="checkbox"
                checked={this.state.isLive}
                onChange={() => {
                  confirm({ onConfirm: this.handleChange });
                }}
              />
              <span className="item-label" />
            </label>
            <span className="item-help">
              {" "}
              {t("test")} / <span className="live">{t("live")}</span>
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  status: store.campaigns.status
});

export default connect(mapStatesToProps)(EngagementOptions);
