/**
 * @author Bilal Cinarli
 */

"use strict";

import { $http, plainGet } from "../../system/ajax";
import api from "../../system/api";
import { getApiKey } from "../../modules/auth/user";

export const getInsights = callback =>
  plainGet(api.getInsightEndPoint("info"), callback);

export const getInsight = (endpoint_url, data, callback, option = null) => {
  const newOption = option || {};
  newOption.type = 'json';
  $http(endpoint_url)
    .post(data, newOption)
    .then(callback);
};

export const getAllInsightReports = (endpoint_url, data, callback) =>
  $http(endpoint_url)
    .post(data, { type: "application/octet-stream" })
    .then(callback);

export const getProductsDetails = (data, callback) => {
  $http(api.getRestEndPoint("sales/detail") + "?apiKey=" + getApiKey())
    .post(data, { type: "json", preventAbort: false })
    .then(response => {
      callback(response);
    });
};

export const getInsightExport = (endpointUrl, id, data, callback, option = null) => {
  const newOption = option || {};
  newOption.type = 'application/octet-stream';
  const endpoint =
    endpointUrl || `${getInsightEndpoint()}export/${id}?apiKey=${getApiKey()}`;

  $http(endpoint)
    .post(data, newOption)
    .then(callback);
};

export const getInsightEndpoint = () => {
  return api.getInsightPath();
};

export default {
  getInsight,
  getInsights,
  getAllInsightReports,
  getProductsDetails,
  getInsightEndpoint,
  getInsightExport
};
