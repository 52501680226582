import React from 'react';
import useRecursiveElement from '../recursive-element/useRecursiveElement';
import { Provider as ShopTheLookProvider } from '../../views/dynamic-bundle/shop-the-look/store/shopTheLookProvider';
import { Provider as PaginationProvider } from '../../components/pagination/store/paginationProvider';
import { Provider as PromotionProvider } from '../../views/recommendation/promotion-list/store/promotionProvider';
import { Provider as GlobalConfigurationProvider } from '../../views/searchandising/global-configuration/store/globalConfigurationProvider';
import { Provider as ProductBundleProvider } from '../../views/dynamic-bundle/automated-bundles/store/productBundleProvider';
import { Provider as CustomerProfileProvider } from '../../views/Audience/customer-profile/store/customerProfileProvider';
import { Provider as GamificationProvider } from '../../views/behavioural-targeting/gamification/store/gamificationProvider';
import { Provider as CountDownProvider } from '../../views/behavioural-targeting/countdown/store/countDownProvider';
import { Provider as EmailProvider } from '../../views/email/store/EmailProvider';

import { UserGuidanceProvider } from '../../user-guidance/user-guidance.provider';

const Providers = [
  PaginationProvider,
  ShopTheLookProvider,
  PromotionProvider,
  GlobalConfigurationProvider,
  ProductBundleProvider,
  CustomerProfileProvider,
  GamificationProvider,
  CountDownProvider,
  EmailProvider,
];
/**
 * @name AppProvider
 * @description AppProvider component a recursive function that renders all the providers
 * @param {React.ReactNode} children
 * @return {JSX.Element}
 * @constructor
 */
const AppProvider = ({ children }) => {
  const ProvideContexts = useRecursiveElement(Providers, children);
  return <UserGuidanceProvider>{ProvideContexts}</UserGuidanceProvider>;
};

export default AppProvider;
