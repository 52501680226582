import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import './AddClientForm.scss';

import { showErrorNotification } from '../../../../actions/ui';
import { showForm } from '../../../../reducers/managed-services/index';
import { postAddNewClient } from '../../../../modules/managed-services/services';
import {
  formColumns,
  types,
  validationSchema,
  initialValues,
} from './addClientConstants';
import Icons from '../../../../components/icons';

const replaceToNoSpecialChar = value => {
  return value.replace(/ /g, '').replace(/[^a-zA-Z0-9]/g, '_');
};

const AddClientForm = ({ formName }) => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const userState = useSelector(state => state.user.user);

  const submitForm = values => {
    const splittedName = values.clientName.split(' ');
    const firstName = splittedName.slice(0, splittedName.length - 1).join(' ');
    const lastName = splittedName[splittedName.length - 1];

    const accountInfo = {
      'your-name': values.clientName,
      'your-email': values.clientEmail,
      'your-site': values.websiteUrl,
      'your-website-name': values.websiteName,
      'partner-name': userState?.displayName || '',
      'partner-email': userState?.username || '',
      industry: values.selectIndustry,
      'unique-sessions': values.selectUniqueSession,
      pageviews: values.selectPageViews,
      sku: values.selectSku,
      'monthly-sales': values.selectMonthlySales,
      'first-name': firstName,
      'last-name': lastName,
      language: 'English',
      querystring: '',
    };

    postAddNewClient(accountInfo, isError => {
      if (!isError) {
        setIsSubmitted(true);
      } else {
        const message = () => {
          return (
            <div>
              <p>Something went wrong</p>
            </div>
          );
        };
        showErrorNotification(message);
      }
    });
  };

  const closeForm = () => {
    dispatch(showForm({ formName: 'addClient', status: false }));
  };

  return (
    <div className={`add-client-form-container ${formName}`}>
      <div className='form-header'>
        <h2>Segmentify Partnership Add Client</h2>
        {!isSubmitted && (
          <span>
            Please fill in the form to refer the client you want to add the
            panel.
          </span>
        )}
      </div>
      <div className={`form-body ${isSubmitted && 'form-submitted'}`}>
        {isSubmitted ? (
          <div className='form-success'>
            <span>
              Request form sent. We will contact you as soon as possible. Please
              check your email adress.
            </span>
            <Icons name='checkCircle' />
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={values => {
              submitForm(values);
            }}
          >
            <Form>
              {formColumns.map(column => {
                return (
                  <div key={column.id} className='form-col'>
                    <ErrorMessage
                      name={column.id}
                      component='div'
                      className='field-error'
                    />

                    {column.type === types.string && (
                      <Field
                        className='col-input'
                        name={column.id}
                        placeholder={column.placeHolder}
                      />
                    )}

                    {column.type === types.email && (
                      <Field
                        className='col-input'
                        name={column.id}
                        placeholder={column.placeHolder}
                        type='email'
                      />
                    )}

                    {column.type === types.selectList && (
                      <Field
                        className='col-select'
                        name={column.id}
                        placeholder={column.placeHolder}
                        as='select'
                      >
                        {column.options.map(option => {
                          return (
                            <option
                              key={replaceToNoSpecialChar(option)}
                              value={option}
                            >
                              {option}
                            </option>
                          );
                        })}
                      </Field>
                    )}

                    <label className='col-label' htmlFor={column.id}>
                      {`${column.title} ${column.isRequired ? ' *' : ''}`}
                    </label>
                  </div>
                );
              })}
              <button className='form-submit-button' type='submit'>
                Send Request Form
              </button>
            </Form>
          </Formik>
        )}
      </div>
      <span className='close-button' onClick={() => closeForm()}>
        X
      </span>
    </div>
  );
};

AddClientForm.propTypes = {
  formName: PropTypes.string,
};

AddClientForm.defaultProps = {
  formName: '',
};

export default AddClientForm;
