/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconDuplicate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            fill={this.state.color}
            className="svg-path"
            d="M341.88,372.5H35.63A30.6,30.6,0,0,1,5,341.88V35.63A30.62,30.62,0,0,1,35.63,5H341.88A30.62,30.62,0,0,1,372.5,35.63V341.88A30.6,30.6,0,0,1,341.88,372.5Z"
            transform="translate(-4.5 -4.5)"
          />
          <path
            fill={this.state.color}
            className="svg-path"
            d="M464.38,495H127.5V433.75H433.75V127.5H495V464.38A30.6,30.6,0,0,1,464.38,495Z"
            transform="translate(-4.5 -4.5)"
          />
        </g>
      </svg>
    );
  }
}

export default IconDuplicate;
