import React from 'react';
import PropTypes from 'prop-types';

function Tick({ width, height, color }) {
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox='0 0 31 31'
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
    >
      <path
        d='M23.2671 12.205C23.7715 11.7006 23.7715 10.8828 23.2671 10.3783C22.7626 9.8739 21.9449
        9.87388 21.4405 10.3783L13.7427 18.0757L11.205 15.5382C10.7006 15.0337 9.88274 15.0337 9.37832
        15.5382C8.87389 16.0424 8.87389 16.8603 9.37832 17.3647L12.8294 20.8157C13.3336 21.3202 14.1515
        21.3202 14.656 20.8157L23.2671 12.205Z'
        fill={color}
      />
      <circle cx='15.5' cy='15.5' r='14' stroke={color} strokeWidth='3' />
    </svg>
  );
}

Tick.defaultProps = {
  color: '#233679',
  width: 20,
  height: 20,
};

Tick.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Tick;
