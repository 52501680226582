import React, { Component } from "react";
import Tooltip from "../../components/tooltip";

class TimeBetween extends Component {
  generateValues(firstValue, secondValue, direction = "first") {
    const values = [];
    for (var i = 1; i <= 24; i++) {
      let isDisabled = false;
      if (direction === "first" && i >= secondValue) {
        isDisabled = true;
      }

      if (direction === "second" && i <= firstValue) {
        isDisabled = true;
      }

      values.push({
        label: i,
        value: i,
        disabled: isDisabled
      });
    }

    return values;
  }

  render() {
    const {
      onChange,
      onChangeSecond,
      name,
      title,
      firstValue,
      secondValue,
      tooltip
    } = this.props;

    const firstValues = this.generateValues(firstValue, secondValue, "first");
    const secondValues = this.generateValues(firstValue, secondValue, "second");
    return (
      <React.Fragment>
        <span className="item-label">
          {title}
          <Tooltip content={tooltip} alignment="left">
            <i className="icon-info" role="presentation">
              <span className="for-screenreader-only" />
            </i>
          </Tooltip>
        </span>
        <select
          value={firstValue}
          className="one-whole criteria-field label-interval--start"
          onChange={onChange}
          name={name}
        >
          {firstValues.map(item => {
            return (
              <option
                key={item.value}
                value={item.value}
                disabled={item.disabled}
              >
                {item.label}
              </option>
            );
          })}
        </select>
        -
        <select
          value={secondValue}
          className="one-whole criteria-field label-interval--end"
          onChange={onChangeSecond}
          name={name}
        >
          {secondValues.map(item => {
            return (
              <option
                key={item.value}
                value={item.value}
                disabled={item.disabled}
              >
                {item.label}
              </option>
            );
          })}
        </select>
      </React.Fragment>
    );
  }
}

export default TimeBetween;
