import React, { useEffect } from 'react';
import { browserHistory } from 'react-router';
import { getExternalPersonalisedAds } from '../constants/config';

const PersonalisedAds = () => {
  const location = getExternalPersonalisedAds();

  useEffect(() => {
    window.open(location, '_blank');
    browserHistory.push('/');
  }, []);

  return <></>;
};

export default PersonalisedAds;
