import React, { useId } from 'react';
import classes from './SFYCheckBox.module.scss';
import { t } from '../../../system/ui';

const SFYCheckBox = ({
  label = 'Activate on Search Box',
  checked,
  searchActiveSetter,
  disabled = false,
  customClass = '',
  ...props
}) => {
  const checkBoxId = useId();

  return (
    <div className={`${classes['checkbox-wrapper']} ${customClass}`}>
      <label className='item item-stacked is-checkbox'>
        <input
          id={checkBoxId}
          type='checkbox'
          checked={checked}
          onChange={() => searchActiveSetter(!checked)}
          disabled={disabled}
          {...props}
        />
        <span className='item-label'>{t(label)}</span>
      </label>
    </div>
  );
};

export default SFYCheckBox;
