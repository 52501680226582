"use strict";

import React from "react";
import { t } from "../system/ui";
import { setTitle } from "../system/document";
import { uiActions } from "../actions";
import { triggerXML, getFeedInfo } from "../modules/account/ajax.js";
import Tooltip from "../components/tooltip";
import ReactPlaceholder from "react-placeholder";
import {reformatDate} from "../system/date";

class Settings_Feed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageReady: false,
      isLoading: false,
      isEnabled: true,
      showDetails: false,
      data: []
    };

    this.triggerFeed = this.triggerFeed.bind(this);
    this.openDetails = this.openDetails.bind(this);
    this.getLastSuccessfulUpdateTime = this.getLastSuccessfulUpdateTime.bind(this);

    getFeedInfo(data => {
      this.setState({
        data,
        isPageReady: true
      });
    });
  }

  componentDidMount() {
    setTitle(t("XML Feed Trigger"));
  }

  triggerFeed(e) {
    e.preventDefault();
    uiActions.isLoading();
    this.setState({ isLoading: true }, () => {
      triggerXML(response => {
        uiActions.isLoaded();
        if (response) {
          let notificationContent = () => (
            <div>{t("You have successfully triggered the XML feed.")}</div>
          );
          uiActions.showNotification({ content: notificationContent });
          this.setState({ isLoading: false });
        }
      });
    });
  }

  openDetails() {
    this.setState({
      showDetails: !this.state.showDetails
    });
  }

  getLastSuccessfulUpdateTime(monitoringMessages) {
    if (!monitoringMessages) {
      return "N/A";
    } else {
      const msg = monitoringMessages.find(
        message => message && message.type === 'SUCCESS',
      );
      if (msg) {
        return reformatDate(msg.startTime, "YYYY-MM-DD HH:mm");
      }
      return "N/A";
    }
  }

  render() {
    const infoStyle = {
      color: "#CEDAE4"
    };

    const feed = this.state.data && this.state.data[0];
    const feedHistory = feed && feed.monitoringMessages && feed.monitoringMessages.length > 0;
    return (
      <div className="feed-trigger-content">
        <h2 className="page-title">{t("XML Feed Trigger")}</h2>
        <div
          style={{
            width: "780px",
            background: "#fff",
            borderRadius: "10px",
            padding: "30px"
          }}
        >
          <ReactPlaceholder rows={4} ready={this.state.isPageReady}>
            {this.state.data.length === 0 && <span>There is no data...</span>}
            {this.state.data.length > 0 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    borderBottom: "2px solid #efeef8"
                  }}
                >
                  <div>
                    <div style={{ fontWeight: "600" }}>
                      {t("XML Feed Status")}{" "}
                      <Tooltip
                        content={t(
                          "Shows if there is an XML integration. You can trigger the feed by clicking Trigger XML Feed button"
                        )}
                        alignment="left"
                      >
                        <i
                          className="icon-info"
                          style={infoStyle}
                          role="presentation"
                        >
                          <span className="for-screenreader-only" />
                        </i>
                      </Tooltip>
                    </div>
                    {feed.isFeedActive && t("Enabled")}
                    {!feed.isFeedActive && t("Disabled")}
                  </div>
                  <div>
                    <button
                      onClick={this.triggerFeed}
                      className="secondary-action"
                    >
                      {t("Trigger XML Feed")}
                    </button>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    borderBottom: "2px solid #efeef8"
                  }}
                >
                  <div>
                    <div style={{ fontWeight: "600" }}>
                      {t("XML Feed Reading History")}{" "}
                    </div>
                    {feedHistory && t("Last Successfully Update Time: ") + this.getLastSuccessfulUpdateTime(feed.monitoringMessages)}
                    {!feedHistory && t("-")}
                  </div>
                  <div>
                    <button
                      onClick={this.openDetails}
                      className="secondary-action"
                    >
                      {this.state.showDetails ? t("Hide") : t("Details")}
                    </button>
                  </div>
                </div>

                {this.state.showDetails && (
                  <div>
                    {feed.monitoringMessages.map(item => {
                      if (item) {
                        if (
                          item.custom &&
                          item.custom.iteratedCount &&
                          item.custom.updatedCount
                        ) {
                          return (
                            <div>{"Reading Time: " + reformatDate(item.startTime, "YYYY-MM-DD HH:mm") + " " + item.custom.iteratedCount
                              + " product read, " + item.custom.updatedCount + " product updated. Status " + (item.type === "SUCCESS" ? "OK" : "FAILED")}</div>);
                        } else {
                          return (
                            <div>{"Reading Time: " + reformatDate(item.startTime, "YYYY-MM-DD HH:mm") + " Status " + (item.type === "SUCCESS" ? "OK" : "FAILED")}</div>);
                        }
                      }
                    })}
                  </div>
                )}

                <div
                  style={{
                    paddingTop: "30px",
                    paddingBottom: "30px",
                    display: "flex"
                  }}
                >
                  <div style={{ paddingRight: "30px", maxWidth: "480px" }}>
                    <div style={{ fontSize: "9pt" }}>
                      XML Link{" "}
                      <Tooltip
                        content={t("The link of the XML")}
                        alignment="left"
                      >
                        <i
                          className="icon-info"
                          style={infoStyle}
                          role="presentation"
                        >
                          <span className="for-screenreader-only" />
                        </i>
                      </Tooltip>
                    </div>
                    {feed.feedUrls.map(item => (
                      <div style={{ wordBreak: "break-all" }}>{item}</div>
                    ))}
                  </div>
                  <div>
                    <div style={{ fontSize: "9pt" }}>
                      Time Period{" "}
                      <Tooltip
                        content={t(
                          "Shows the period of XML integration. For example if it is 2 days, it shows that every 2 days the integration is made"
                        )}
                        alignment="left"
                      >
                        <i
                          className="icon-info"
                          style={infoStyle}
                          role="presentation"
                        >
                          <span className="for-screenreader-only" />
                        </i>
                      </Tooltip>
                    </div>
                    <div>{feed.cronDescription}</div>
                  </div>
                </div>
                <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                  <div style={{ fontWeight: "600" }}>
                    XML Tag Relationship{" "}
                    <Tooltip
                      content={t("Shows which tags are being used")}
                      alignment="left"
                    >
                      <i
                        className="icon-info"
                        style={infoStyle}
                        role="presentation"
                      >
                        <span className="for-screenreader-only" />
                      </i>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginTop: "20px"
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#efeef6",
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        minWidth: "250px",
                        textAlign: "center",
                        fontWeight: "600",
                        paddingTop: "5px",
                        paddingBottom: "5px"
                      }}
                    >
                      XML Tag
                    </div>
                    <div
                      style={{
                        backgroundColor: "#efeef6",
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        minWidth: "250px",
                        textAlign: "center",
                        fontWeight: "600",
                        paddingTop: "5px",
                        paddingBottom: "5px"
                      }}
                    >
                      Product Field
                    </div>
                  </div>
                  {feed.fields.map(item => (
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid  #efeef8",
                        borderRadius: "5px",
                        justifyContent: "space-around",
                        padding: "10px",
                        marginBottom: "20px",
                        alignItems: "center"
                      }}
                    >
                      {" "}
                      <div
                        style={{
                          backgroundColor: "#f2f2f2",
                          padding: "5px",
                          borderRadius: "3px",
                          minWidth: "150px",
                          textAlign: "center"
                        }}
                      >
                        {item.feed}
                      </div>
                      <div>
                        <div className="arrowsImg"></div>
                      </div>
                      <div
                        style={{
                          backgroundColor: "#f2f2f2",
                          padding: "5px",
                          borderRadius: "3px",
                          minWidth: "150px",
                          textAlign: "center"
                        }}
                      >
                        {item.catalog}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </ReactPlaceholder>
        </div>
      </div>
    );
  }
}

export default Settings_Feed;
