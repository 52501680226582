import React from 'react';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import { SFYTextWithLabel } from '../../../../components/sfy-components/module-exports';

const EmailSettings = ({
  component,
  editCampaign,
  activeEmailDetails,
  readOnly,
}) => {
  const { params } = activeEmailDetails;
  const { fromName, fromAddress, replyToAddress, replyName } = params;

  const editEmailSettings = (field, value) => {
    const newParams = { ...params, [field]: value };
    editCampaign('params', newParams);
  };

  const errorMessage = 'Please enter a valid email address.';
  const validationRegex = /^[a-zA-Z0-9!#$%&‘*+/=?^_{|}~-]+(?:[.!#$%&‘*+/=?^_{|}~-][a-zA-Z0-9!#$%&‘*+/=?^_{|}~-]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const isFromAddressValid = validationRegex.test(fromAddress);
  const isReplyAddressValid = validationRegex.test(replyToAddress);

  return (
    <InputCardWrapper
      type='adaptiveBlock'
      cardTitle={component.title}
      labelClass='cardTitle'
    >
      <SFYTextWithLabel
        cardLabel='Sender Name'
        cardName='fromName'
        placeholder={component.placeholder}
        fieldValue={fromName}
        editCampaign={editEmailSettings}
        disabled={readOnly}
      />
      <SFYTextWithLabel
        cardLabel='Sender Email'
        cardName='fromAddress'
        placeholder={component.placeholder}
        fieldValue={fromAddress}
        editCampaign={editEmailSettings}
        errorMessage={!isFromAddressValid ? errorMessage : ''}
        disabled={readOnly}
      />
      <SFYTextWithLabel
        cardLabel='Reply Email'
        cardName='replyToAddress'
        placeholder={component.placeholder}
        fieldValue={replyToAddress}
        editCampaign={editEmailSettings}
        errorMessage={!isReplyAddressValid ? errorMessage : ''}
        disabled={readOnly}
      />
      <SFYTextWithLabel
        cardLabel='Reply Name'
        cardName='replyName'
        placeholder={component.placeholder}
        fieldValue={replyName}
        editCampaign={editEmailSettings}
        disabled={readOnly}
      />
    </InputCardWrapper>
  );
};

export default EmailSettings;
