import React from 'react';
import { IndexRoute, Route } from 'react-router';

import { Auth } from '../modules/auth';

import * as Containers from '../containers';
import * as Views from '../views';

export const externalRoutes = (
  <Route path='/ext' component={Containers.Ext} onEnter={Auth.stringAuth}>
    <IndexRoute component={Views.Dashboard} />
    <Route path='/ext/dashboard' component={Views.Dashboard} />
    <Route path='/ext/dashboard/:type' components={Views.Dashboard} />
    <Route path='/ext/campaigns' component={Views.ExternalCampaigns} />
    <Route path='/ext/trendify' component={Views.Trendify} />
    <Route path='/ext/trendify/categories' component={Views.Categories} />
    <Route path='/ext/trendify/brands' component={Views.Brands} />
    <Route path='/ext/trendify/sales' component={Views.Sales} />
    <Route path='/ext/trendify/insights' component={Views.Insights} />
    <Route path='/ext/bannerify' component={Views.Bannerify} />
    <Route path='/ext/guide' component={Views.ExternalGuide} />
    <Route path='/ext/reports' component={Views.Reports}>
      <Route path='all/:campaigntype' components={Views.Reports} />
      <Route path='all/:campaigntype/:report' components={Views.Reports} />
    </Route>
    <Route path='/ext/push' components={Views.Pushes}>
      <Route path=':push' components={Views.Pushes} />
    </Route>
    <Route path='/ext/push/add/:pushType' component={Views.AddPushCampaign} />
    <Route path='/ext/push/edit/:pushType' component={Views.EditPushCampaign} />
    <Route
      path='/ext/push/replicate/:pushType/:campaignId'
      component={Views.EditPushCampaign}
      isReplicate
    />
    <Route
      path='/ext/push/edit/:pushType/:campaignId'
      component={Views.EditPushCampaign}
    />
    <Route
      path='/ext/reports/single/:campaigntype/:reportID'
      components={Views.SingleReport}
    />
    <Route
      path='/ext/reports/push-single/:reportID'
      components={Views.SinglePushReport}
    />
    <Route
      path='/ext/widget/edit/:pagetype/:placement/:widgetId'
      component={Views.EditWidget}
    />
  </Route>
);
