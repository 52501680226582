import { createSlice } from '@reduxjs/toolkit';
import {
  getUserAccountsReducer,
  getUserListReducer,
  getUserTypeReducer,
  deleteUserReducer,
  searchUserReducer,
  unlockUserReducer,
} from './extraReducers';

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState: {
    filter: {
      searchParam: '',
      userType: {
        selected: '',
        loading: false,
        data: [],
      },
      account: {
        selected: '',
        loading: false,
        data: [],
      },
      status: {
        selected: '',
        data: [
          { label: 'All', value: 'ALL' },
          { label: 'Locked', value: 'LOCKED' },
          { label: 'Active', value: 'UNLOCKED' },
        ],
      },
    },
    users: {
      loading: false,
      loaded: false,
      data: [],
    },
  },
  reducers: {
    usersLoading(state) {
      state.users.loading = true;
    },
    usersLoaded(state, action) {
      state.users.loaded = true;
      state.data = action.payload;
    },
    onFilterChange(state, action) {
      const { payload } = action;
      state.filter[payload.selector].selected = payload.value;
    },
    onResetFilterClick(state) {
      state.filter.userType.selected = '';
      state.filter.account.selected = '';
      state.filter.status.selected = '';
      state.filter.searchParam = '';
    },
    onSearchChange(state, action) {
      state.filter.searchParam = action.payload;
    },
  },
  extraReducers: {
    ...getUserTypeReducer,
    ...getUserListReducer,
    ...getUserAccountsReducer,
    ...deleteUserReducer,
    ...searchUserReducer,
    ...unlockUserReducer,
  },
});

export const {
  usersLoading,
  usersLoaded,
  onFilterChange,
  onResetFilterClick,
  onSearchChange,
} = userManagementSlice.actions;
export default userManagementSlice.reducer;
