import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Cell } from 'react-sticky-table';

import { t } from '../../system/ui';
import Icon from '../icon';
import Icons from '../icons';
import Tooltip from '../tooltip';

/*
TODO: somehow this is not supported by the current StickyTable version
const TableHeadings = ({
  currentSorted,
  direction,
  hasSegmentifyListing,
  heading,
  isBannerifyHeadings,
  isSticky,
  onSort,
  productColumns,
  tooltip,
}) => {

  const calculateText = item => {
    if (item.icon) return <Icon name={item.icon} />;

    if (item.svg) return <Icons name={item.icon} color='#222' />;

    if (item.sortable)
      return (
        <a className='sortable' onClick={() => onSort(item.sort)}>
          {t(item.text)}
        </a>
      );

    if (item.tooltip && tooltip !== false)
      return (
        <Tooltip content={item.tooltip} alignment='top' newTooltip>
          {t(item.text)}
        </Tooltip>
      );

    return t(item.text);
  };

  if (isSticky)
    return (
      <Row>
        {heading.map((item, index) => {
            const className = classNames(
              'analytics-sticky-table-thead',
              item.className,
              {
                'sort-up':
                  direction === 'DESC' &&
                  currentSorted === item.sort,
                'sort-down':
                  direction === 'ASC' &&
                  currentSorted === item.sort,
              },
            );
          const text = calculateText(item);

          return (
            <Cell key={`cell-item-${item.text}`}>
              <div className={className}>{calculateText(item)}</div>
            </Cell>
          );
        })}
      </Row>
    );

  return (
    <tr className='fixed-header'>
      {heading.map(item => {
        const className = classNames('', item.className, {
          'sort-up': direction === 'DESC' && currentSorted === item.sort,
          'sort-down': direction === 'ASC' && currentSorted === item.sort,
        });

        return (
          <th key={`cell-item-${item.text}`} className={className}>
              <div>{calculateText(item)}</div>
          </th>
        );
      })}
    </tr>
  );
  };
*/

class TableHeadings extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const calculateText = item => {
      if (item.tooltip && this.props.tooltip !== false) {
        let content = t(item.text);
        if (item.sortable) {
          content = (
            <a
              className='sortable'
              onClick={() => this.props.onSort(item.sort)}
            >
              {item.icon ? <Icon name={item.icon} /> : t(item.text)}
            </a>
          );
        }
        return (
          <Tooltip content={item.tooltip} alignment='top' newTooltip>
            {content}
          </Tooltip>
        );
      }

      if (item.svg) return <Icons name={item.icon} color='#222' />;

      if (item.icon) return <Icon name={item.icon} />;

      return t(item.text);
    };

    if (this.props.isSticky) {
      return (
        <Row>
          {this.props.heading.map(item => {
            const className = classNames(
              'analytics-sticky-table-thead',
              item.className,
              {
                'sort-up':
                  this.props.direction === 'DESC' &&
                  this.props.currentSorted === item.sort,
                'sort-down':
                  this.props.direction === 'ASC' &&
                  this.props.currentSorted === item.sort,
              },
            );

            return (
              <Cell key={item.className + '' + item.text}>
                <div className={className}>{calculateText(item)}</div>
              </Cell>
            );
          })}
        </Row>
      );
    }
    return (
      <tr className='fixed-header'>
        {this.props.heading.map(item => {
          const className = classNames('', item.className, {
            'sort-up':
              this.props.direction === 'DESC' &&
              this.props.currentSorted === item.sort,
            'sort-down':
              this.props.direction === 'ASC' &&
              this.props.currentSorted === item.sort,
          });

          return (
            <th key={`item-${item.text}`} className={className}>
              <div>{calculateText(item)}</div>
            </th>
          );
        })}
      </tr>
    );
  }
}

TableHeadings.defaultProps = {
  tooltip: true,
  currentSorted: '',
  direction: '',
  isSticky: false,
  onSort: () => null,
};

TableHeadings.propTypes = {
  currentSorted: PropTypes.string,
  direction: PropTypes.string,
  heading: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      tooltip: PropTypes.string,
    }),
  ).isRequired,
  isSticky: PropTypes.bool,
  onSort: PropTypes.func,
  tooltip: PropTypes.string,
};

export default TableHeadings;
