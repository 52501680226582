import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const WofReport = ({
  width = '30.26px',
  height = '33px',
  color = '#7A7A7C',
}) => {
  const [state, setState] = useState({
    width: '',
    height: '',
    color: '',
  });

  const setStyles = () => {
    setState({
      width,
      height,
      color,
    });
  };

  useEffect(() => {
    setStyles();
  }, [width, height, color]);

  return (
    <svg
      width={state.width}
      height={state.height}
      viewBox='0 0 31 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.2764 29.7372C21.7475 29.7372 26.9933 24.4914 26.9933 18.0203C26.9933 11.5493 21.7475 6.30346 15.2764 6.30346C8.80536 6.30346 3.55954 11.5493 3.55954 18.0203C3.55954 24.4914 8.80536 29.7372 15.2764 29.7372ZM15.2764 30.3305C22.0751 30.3305 27.5865 24.819 27.5865 18.0203C27.5865 11.2216 22.0751 5.71021 15.2764 5.71021C8.47771 5.71021 2.96628 11.2216 2.96628 18.0203C2.96628 24.819 8.47771 30.3305 15.2764 30.3305Z'
        fill={state.color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.2764 21.4314C17.1604 21.4314 18.6877 19.9041 18.6877 18.0201C18.6877 16.1361 17.1604 14.6089 15.2764 14.6089C13.3924 14.6089 11.8652 16.1361 11.8652 18.0201C11.8652 19.9041 13.3924 21.4314 15.2764 21.4314ZM15.2764 22.0246C17.488 22.0246 19.2809 20.2317 19.2809 18.0201C19.2809 15.8085 17.488 14.0156 15.2764 14.0156C13.0648 14.0156 11.2719 15.8085 11.2719 18.0201C11.2719 20.2317 13.0648 22.0246 15.2764 22.0246Z'
        fill={state.color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.1281 32.4069C23.1555 32.4069 29.663 25.8994 29.663 17.872C29.663 9.84463 23.1555 3.33716 15.1281 3.33716C7.10073 3.33716 0.593259 9.84463 0.593259 17.872C0.593259 25.8994 7.10073 32.4069 15.1281 32.4069ZM15.1281 33.0001C23.4831 33.0001 30.2562 26.227 30.2562 17.872C30.2562 9.51698 23.4831 2.7439 15.1281 2.7439C6.77309 2.7439 0 9.51698 0 17.872C0 26.227 6.77309 33.0001 15.1281 33.0001Z'
        fill={state.color}
      />
      <path
        d='M15.2764 6.89673L10.524 9.0245e-05L20.0288 9.10759e-05L15.2764 6.89673Z'
        fill={state.color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4084 15.4226L6.47577 10.0833L6.87264 9.64233L12.8052 14.9817L12.4084 15.4226Z'
        fill={state.color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.9797 14.164V5.8584H15.573V14.164H14.9797Z'
        fill={state.color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.8959 14.8335L23.8285 9.49414L24.2254 9.93511L18.2928 15.2744L17.8959 14.8335Z'
        fill={state.color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.4382 18.1685H19.1326V17.5752H27.4382V18.1685Z'
        fill={state.color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.5206 26.8322L17.7363 21.0479L18.1558 20.6284L23.9401 26.4127L23.5206 26.8322Z'
        fill={state.color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.9797 30.182V22.0247H15.573V30.182H14.9797Z'
        fill={state.color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.60999 26.4154L12.2459 20.6311L12.6709 21.0451L7.0349 26.8294L6.60999 26.4154Z'
        fill={state.color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.11462 17.5752H11.2719V18.1685H3.11462V17.5752Z'
        fill={state.color}
      />
    </svg>
  );
};

WofReport.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

WofReport.defaultProps = {
  width: '30.26px',
  height: '33px',
  color: '#7A7A7C',
};

export default WofReport;
