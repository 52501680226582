import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../../system/ui';
import Icons from '../../../../components/icons';

const SPPreview = ({
  message,
  dynamicKeys,
  verticalPos,
  horizontalPos,
  verticalPosMobile,
  bgColor,
  textColor,
}) => {
  const [selectedDeviceType, setSelectedDeviceType] = useState('desktop');

  const changeMessageWithRandomValues = () => {
    let randomMessage = message;
    const keysOfTypeOfTime = [
      '{{LastBoughtTime}}',
      '{{PreviouslyAddedToBasketTime}}',
      '{{LastVisitTime}}',
    ];
    dynamicKeys.forEach(key => {
      let randomValue = Math.floor(Math.random() * 30) + 1;
      if (keysOfTypeOfTime.includes(key)) {
        randomValue += 'm';
      }
      randomMessage = randomMessage.replace(key, randomValue);
    });
    return randomMessage;
  };

  return (
    <div className='preview-container'>
      <span className='preview-title'>Preview</span>
      <div className='preview-tabs'>
        <div
          className={`preview-tab ${
            selectedDeviceType === 'desktop' ? 'active' : ''
          }`}
          onClick={() => setSelectedDeviceType('desktop')}
        >
          <Icons name='pc' color='#7A7A7C' />
          <span className='preview-tab-title'>{t('Desktop')}</span>
        </div>
        <div
          className={`preview-tab ${
            selectedDeviceType === 'mobile' ? 'active' : ''
          }`}
          onClick={() => setSelectedDeviceType('mobile')}
        >
          <Icons name='mobile' color='#7A7A7C' />
          <span className='preview-tab-title'>{t('Mobile')}</span>
        </div>
      </div>
      <div className='preview-content'>
        {selectedDeviceType === 'desktop' && (
          <div className='content-desktop'>
            <Icons name='pcBigger' />
            <div
              className={`content-desktop-popup ${verticalPos} ${horizontalPos}`}
              style={{ backgroundColor: bgColor, color: textColor }}
            >
              {changeMessageWithRandomValues()}
            </div>
          </div>
        )}
        {selectedDeviceType === 'mobile' && (
          <div className='content-mobile'>
            <Icons name='mobileBigger' />
            <div
              className={`content-mobile-popup ${verticalPosMobile}`}
              style={{ backgroundColor: bgColor, color: textColor }}
            >
              {changeMessageWithRandomValues()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

SPPreview.propTypes = {
  message: PropTypes.string.isRequired,
  dynamicKeys: PropTypes.shape([]).isRequired,
  verticalPos: PropTypes.string.isRequired,
  horizontalPos: PropTypes.string.isRequired,
  verticalPosMobile: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default SPPreview;
