/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { modalActions, userActions } from '../../actions';
import { logout } from '../../modules/auth/user';
import UserIcon from './icons/UserIcon';
import NotificationRingIcon from './icons/NotificationRingIcon';
import BackgroundIcon from './icons/BackgroundIcon';
import LogoutIcon from './icons/LogoutIcon';
import PlusIcon from './icons/PlusIcon';

import { featureItems } from './msp-static-data';
import { latestFeaturesModalTypes } from './enums';
import LatestFeatureModal from './LatestFeatureModal';

// eslint-disable-next-line react/prop-types
const FeatureItem = ({
  title,
  descriptions,
  image,
  redirectUrl,
  redirectText,
  styleType,
}) => {
  const getClassName = () => {
    if (!image) {
      return 'msp-no-image';
    }
    switch (styleType) {
      case latestFeaturesModalTypes.ROW:
        return 'msp-column';
      case latestFeaturesModalTypes.COLUMN:
        return 'msp-column';
      default:
        return '';
    }
  };

  const openInModal = () => {
    function content() {
      return (
        <LatestFeatureModal
          title={title}
          descriptions={descriptions}
          image={image}
          redirectUrl={redirectUrl}
          redirectText={redirectText}
          styleType={styleType}
        />
      );
    }

    modalActions.openModal({
      content,
      className: 'latest-features-modal',
    });
  };

  return (
    <div className='msp-features-item clickable' onClick={() => openInModal()}>
      <div className='msp-features-item-title'>
        <span className='msp-features-item-text'>{title}</span>
      </div>
      <div className={`msp-features-item-content ${getClassName()}`}>
        {image && (
          <div className='msp-features-item-image'>
            <img src={image} alt={title} />
          </div>
        )}
        <div className='msp-features-item-description'>
          <span className='msp-features-item-text'>
            {descriptions.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={index}>{item}</p>
            ))}
          </span>
        </div>
      </div>
    </div>
  );
};

FeatureItem.propTypes = {
  title: PropTypes.string.isRequired,
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.string,
  redirectUrl: PropTypes.string,
  redirectText: PropTypes.string,
  styleType: PropTypes.string,
};

const UserField = ({ name }) => {
  const refUserDropdown = useRef(null);
  const [isUserDropdownVisible, setIsUserDropdownVisible] = useState(false);

  const logoutHandler = () => {
    logout();
    userActions.logoutUser();
    window.location = '/login';
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        refUserDropdown.current &&
        !refUserDropdown.current.contains(event.target) &&
        isUserDropdownVisible
      ) {
        setIsUserDropdownVisible(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refUserDropdown, isUserDropdownVisible]);

  return (
    <div className='msp-user-container'>
      <div className='msp-user-notification'>
        <div className='msp-user-notification-icon'>
          <NotificationRingIcon />
          <span className='has-notification' />
        </div>
      </div>
      <div className='msp-user-info'>
        <div className='msp-user-info-detail'>
          <div className='msp-user-info-role'>
            <span className='msp-user-info-role-text'>Hi!</span>
          </div>
          <div className='msp-user-info-name'>
            <span className='msp-user-info-name-text'>{name}</span>
          </div>
        </div>
        <div
          className='msp-user-info-avatar'
          onClick={() => {
            setIsUserDropdownVisible(true);
          }}
        >
          <div className='msp-user-info-avatar-icon'>
            <UserIcon />
          </div>
        </div>
        <div
          className={`msp-user-dropdown-container ${
            isUserDropdownVisible ? 'visible' : ''
          }`}
        >
          <div className='msp-user-dropdown-menu' ref={refUserDropdown}>
            <a
              className='msp-user-dropdown-menu-item'
              target='_blank'
              href='https://zcform.com/0f99a'
              rel='noreferrer'
            >
              <div className='msp-user-dropdown-menu-item-icon'>
                <PlusIcon />
              </div>
              <span className='msp-user-dropdown-menu-item-text'>
                Refer a Deal
              </span>
            </a>
            <a
              className='msp-user-dropdown-menu-item'
              target='_blank'
              href='https://zcform.com/u7g8H'
              rel='noreferrer'
            >
              <div className='msp-user-dropdown-menu-item-icon'>
                <PlusIcon />
              </div>
              <span className='msp-user-dropdown-menu-item-text'>
                Refer a Lead
              </span>
            </a>
            <div
              className='msp-user-dropdown-menu-item'
              onClick={() => {
                logoutHandler();
              }}
            >
              <div className='msp-user-dropdown-menu-item-icon'>
                <LogoutIcon />
              </div>
              <span className='msp-user-dropdown-menu-item-text'>Logout</span>
            </div>
          </div>
          <div className='dropdown-top-arrow' />
        </div>
      </div>
    </div>
  );
};

UserField.propTypes = {
  name: PropTypes.string.isRequired,
};

const WelcomeVideo = ({ title, videoUrl }) => {
  return (
    <div className='msp-container-item'>
      <div className='msp-welcome-video-container'>
        <div className='msp-welcome-title'>
          <span className='msp-welcome-text'>{title}</span>
        </div>
        <div className='msp-welcome-video'>
          <iframe
            title='welcome-video'
            src={videoUrl}
            frameBorder='0'
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};

WelcomeVideo.propTypes = {
  title: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
};

const Features = () => {
  return (
    <div className='msp-container-item'>
      <div className='msp-features-title'>
        <span className='msp-features-text'>Latest Features</span>
      </div>
      <div className='msp-features-container'>
        <div className='msp-features-list'>
          {featureItems.map(item => (
            <FeatureItem key={item.id} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

const SideBarContent = ({ user }) => {
  const welcomeVideoState = useSelector(
    state => state.managedServices.welcomeVideo,
  );
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [welcomeVideo, setWelcomeVideo] = useState({});
  const name = user?.displayName || 'John Doe';

  useEffect(() => {
    setIsVideoLoaded(welcomeVideoState.loaded);
    setWelcomeVideo(welcomeVideoState.data);
  }, [welcomeVideoState]);

  return (
    <div className='msp-sidebar-content'>
      <div className='msp-content-background-holder'>
        <BackgroundIcon className='msp-content-background' />
      </div>
      <div className='msp-sideContainer'>
        <UserField name={name} />
        <div className='msp-content-holder'>
          {isVideoLoaded && (
            <WelcomeVideo title='Welcome !' videoUrl={welcomeVideo.videoUrl} />
          )}
          <Features />
        </div>
      </div>
    </div>
  );
};

SideBarContent.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SideBarContent;
