import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const InsightsTrendifyCardBg1 = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 354 165'
      fill='none'
    >
      <path
        opacity='0.58'
        d='M35.39 123.6L97.09 132.6L108.81 160.85L168.29 144.14L238.8 150.85L299.21 160.85L309.29 130.29L353.8 162.14H177.67L0 164.65L35.39 123.6Z'
        fill='#DCE9E4'
      />
      <path
        opacity='0.33'
        d='M181.54 58.1C197.584 58.1 210.59 45.0939 210.59 29.05C210.59 13.0061 197.584 0 181.54 0C165.496 0 152.49 13.0061 152.49 29.05C152.49 45.0939 165.496 58.1 181.54 58.1Z'
        fill='url(#paint0_radial_1002_2680)'
      />
      <path
        d='M238.33 125.67C238.33 125.67 245.77 137.36 239.33 149.38L236.19 148.3L232.46 126.4C232.322 126.051 232.272 125.673 232.315 125.3C232.357 124.927 232.492 124.571 232.705 124.262C232.919 123.953 233.206 123.702 233.54 123.531C233.874 123.359 234.245 123.273 234.62 123.28C235.426 123.136 236.257 123.302 236.945 123.746C237.633 124.189 238.128 124.877 238.33 125.67Z'
        fill='#D6988E'
      />
      <path
        d='M250.81 117.56C250.81 117.56 264.45 120.02 268.93 132.9L266.01 134.47L247.38 122.37C247.028 122.237 246.715 122.019 246.469 121.735C246.223 121.451 246.05 121.11 245.968 120.744C245.886 120.377 245.896 119.995 245.998 119.633C246.099 119.271 246.289 118.94 246.55 118.67C246.99 117.982 247.673 117.486 248.463 117.28C249.252 117.075 250.091 117.174 250.81 117.56Z'
        fill='#D6988E'
      />
      <path
        d='M240.75 147.83C240.75 147.83 237.18 148.64 236.37 147.83C235.56 147.02 234.97 148.3 234.97 148.3C234.664 149.298 234.131 150.212 233.413 150.97C232.695 151.729 231.811 152.31 230.83 152.67C227.39 154.02 228.29 155.67 228.29 155.67C228.29 155.67 241.76 155.67 242.91 154.06L240.75 147.83Z'
        fill='black'
        stroke='black'
        strokeWidth='0.71'
        strokeMiterlimit='10'
      />
      <path
        d='M240.73 147.05C240.664 146.846 240.526 146.674 240.342 146.563C240.158 146.453 239.941 146.413 239.73 146.45C238.67 146.64 236.83 146.88 236.29 146.32C235.52 145.53 234.95 146.79 234.95 146.79C234.661 147.778 234.15 148.686 233.457 149.447C232.764 150.208 231.907 150.8 230.95 151.18C227.65 152.53 228.51 154.18 228.51 154.18C228.51 154.18 239.31 154.18 242 152.92C242.156 152.85 242.281 152.724 242.349 152.566C242.418 152.409 242.425 152.232 242.37 152.07L240.73 147.05Z'
        fill='#EF8067'
      />
      <path
        d='M270.19 132.41C270.032 132.265 269.828 132.181 269.614 132.172C269.4 132.163 269.19 132.229 269.02 132.36C268.19 133.04 266.68 134.13 265.94 133.9C264.88 133.57 264.99 134.95 264.99 134.95C265.209 135.952 265.197 136.99 264.954 137.987C264.711 138.983 264.245 139.911 263.59 140.7C261.35 143.48 262.9 144.54 262.9 144.54C262.9 144.54 272.37 139.34 274.15 136.98C274.252 136.841 274.3 136.669 274.283 136.498C274.267 136.326 274.187 136.167 274.06 136.05L270.19 132.41Z'
        fill='#EF8067'
      />
      <path
        d='M252.82 118.09L244.91 108.81L238.36 113.45L247.38 122.37L252.82 118.09Z'
        fill='#D6988E'
      />
      <path
        d='M239.38 127.68L240.47 115.53L232.58 114.09L232.51 126.78L239.38 127.68Z'
        fill='#D6988E'
      />
      <path
        d='M169.08 59.31L171.96 58.46C173.48 58.02 178.15 55.2 177.8 54.65C177.45 54.1 174.48 55.71 174.48 55.71C174.48 55.71 178.48 53.61 178.03 52.25C177.58 50.89 174.03 54.49 174.03 54.49C174.03 54.49 177.23 51.61 176.83 50.79C176.43 49.97 173.83 51.88 172.98 53.79C172.98 53.79 175.49 49.79 175.06 49.13C174.63 48.47 172.19 52.52 171.46 53.13C170.73 53.74 170.99 52 171.41 51.45C171.83 50.9 172.25 50.2 171.41 49.92C170.57 49.64 169.67 52.05 169.27 53.42C168.957 54.7373 168.329 55.9591 167.44 56.98L169.08 59.31Z'
        fill='#E5B8B0'
      />
      <path
        d='M75.9302 110.9H72.6502C72.6502 110.9 70.9202 113.45 71.9002 115.22H76.9002L75.9302 110.9Z'
        fill='#D09C96'
      />
      <path
        d='M138.35 117L137.1 120C137.1 120 138.81 122.58 140.81 122.34L142.72 117.71L138.35 117Z'
        fill='#D09C96'
      />
      <path
        d='M70.8801 110.23C70.8801 110.23 70.1201 114.16 71.0201 115.01C71.9201 115.86 70.5501 116.55 70.5501 116.55C69.471 116.92 68.4918 117.533 67.6887 118.343C66.8856 119.153 66.2804 120.138 65.9201 121.22C64.5601 125.01 62.7001 124.09 62.7001 124.09C62.7001 124.09 62.2601 109.4 63.9601 108.09L70.8801 110.23Z'
        fill='black'
        stroke='black'
        strokeWidth='0.71'
        strokeMiterlimit='10'
      />
      <path
        d='M71.7301 110.23C71.9515 110.3 72.1414 110.446 72.2673 110.641C72.3933 110.836 72.4472 111.069 72.4201 111.3C72.2501 112.46 72.0601 114.48 72.6801 115.05C73.5701 115.87 72.2101 116.53 72.2101 116.53C71.1473 116.875 70.1771 117.458 69.3732 118.235C68.5692 119.011 67.9526 119.96 67.5701 121.01C66.2001 124.66 64.3401 123.78 64.3401 123.78C64.3401 123.78 64.0001 112 65.2501 109.02C65.3251 108.849 65.4599 108.711 65.6291 108.632C65.7984 108.553 65.9906 108.538 66.1701 108.59L71.7301 110.23Z'
        fill='#EF8067'
      />
      <path
        d='M135.38 121.3C135.38 121.3 138.6 123.66 139.75 123.22C140.9 122.78 140.95 124.3 140.95 124.3C140.82 125.433 140.955 126.581 141.344 127.653C141.733 128.725 142.364 129.693 143.19 130.48C146.04 133.33 144.41 134.62 144.41 134.62C144.41 134.62 130.93 128.74 130.48 126.62L135.38 121.3Z'
        fill='black'
        stroke='black'
        strokeWidth='0.71'
        strokeMiterlimit='10'
      />
      <path
        d='M135.73 120.56C135.89 120.389 136.104 120.279 136.336 120.25C136.568 120.221 136.803 120.274 137 120.4C138 121.05 139.72 122.09 140.5 121.77C141.62 121.31 141.64 122.82 141.64 122.82C141.498 123.929 141.61 125.055 141.969 126.114C142.327 127.173 142.923 128.136 143.71 128.93C146.42 131.73 144.83 133.03 144.83 133.03C144.83 133.03 134.04 128.31 131.88 125.9C131.757 125.763 131.689 125.585 131.689 125.4C131.689 125.216 131.757 125.038 131.88 124.9L135.73 120.56Z'
        fill='#EF8067'
      />
      <path
        d='M138.45 57.19L136.85 58.05C136.85 58.05 137 62.15 137.85 63.18C138.7 64.21 139.28 65.18 141.08 65.33C142.88 65.48 144.47 62.33 144.47 62.33L138.45 57.19Z'
        fill='#D09C96'
      />
      <path
        d='M134.6 91.97L121.85 92.84L136.85 67.42L134.63 58.03L135.31 58.1L137.21 57.87C137.21 57.87 136.88 58.05 137.36 60.48C138.2 64.83 140.53 65.12 140.53 65.12L145.01 62.52L134.6 91.97Z'
        fill='#CDD9E9'
      />
      <path
        d='M75.3301 109.02C75.3301 109.02 90.8501 106.54 98.7201 106.75C98.7201 106.75 102.03 105.92 105.13 101.75C108.23 97.58 108.65 98.44 110.31 93.88C110.82 92.5317 111.623 91.3132 112.661 90.312C113.698 89.3109 114.944 88.5521 116.31 88.09L133.9 89.56C133.9 89.56 152.74 92.04 152.74 99.49C152.74 106.94 144.46 120.6 144.46 120.6L137.01 117.71C137.01 117.71 138.45 105.49 143.84 103.22C143.84 103.22 128.52 101.77 126.04 100.32L123.14 96.39L102.86 111.7L77.6101 112.95L75.3301 109.02Z'
        fill='black'
      />
      <path
        d='M131.26 89.35L128.74 96.81C128.74 96.81 118.98 110.33 108.4 114.21C97.8201 118.09 75.3301 116.8 75.3301 116.8V109.02C75.3301 109.02 93.2801 106.26 98.9301 106.73C98.9301 106.73 102.02 105.73 105.57 101.21C109.12 96.69 109.08 97.1 109.76 95.33C110.44 93.56 111.15 89.92 115.89 88.26'
        fill='#232322'
      />
      <path
        d='M144.34 71.16C144.34 71.16 137.78 89.28 135.23 92.84C135.23 92.84 130.56 92.72 132.45 87.5C134.34 82.28 139.01 67.04 140.67 65.5L141.45 66.72C141.45 66.72 145.68 63.61 144.34 61.61L146.79 63.83C146.79 63.83 154.9 66.39 158.68 66.16C158.68 66.16 164.58 57.6 166.8 56.27C166.8 56.27 171.02 57.49 171.8 59.16C171.8 59.16 166.02 70.04 159.8 73.38C154.574 73.2841 149.381 72.5384 144.34 71.16Z'
        fill='#5E6E81'
      />
      <path
        d='M98.8203 76.56C99.112 77.1652 99.5312 77.7002 100.049 78.1282C100.567 78.5563 101.171 78.8673 101.82 79.04C101.82 79.04 112.61 71.16 114.92 67.14C114.92 67.14 120.4 69.08 124.34 68.88C124.34 68.88 119.29 76.99 119.46 80.07C119.63 83.15 109.36 90.85 109.36 90.85C109.36 90.85 115.78 98.9 124 92.85C132.22 86.8 134.1 82.85 137.13 73.76C139.66 66.16 139.92 65.32 139.92 65.32L137.44 66.26C137.44 66.26 135.13 59.76 135.72 57.45C135.72 57.45 128.79 57.28 124.6 58.65C120.41 60.02 111.67 62.33 111.67 62.33C111.67 62.33 109.61 62.41 107.05 65.49C104.49 68.57 98.8203 76.56 98.8203 76.56Z'
        fill='#5E6E81'
      />
      <path
        d='M137.21 56.26C137.317 56.019 137.372 55.7584 137.372 55.495C137.372 55.2315 137.317 54.9709 137.21 54.73C136.83 53.91 137.56 52.5899 138.68 51.9899C139.8 51.3899 140.39 53.26 140.39 53.26C140.39 53.26 140.84 53.63 140.99 52.26C141.14 50.89 143.52 49.51 145.67 49.73C147.82 49.95 147.61 53.45 147.61 53.45C147.61 53.45 152.76 63.3599 146.96 62.7699C145.639 62.7287 144.34 62.4244 143.138 61.875C141.936 61.3255 140.855 60.5419 139.96 59.57'
        fill='#E5B8B0'
      />
      <path
        d='M142.6 52.26C142.6 52.26 144.76 54.26 146.11 53.13C147.46 52 151.11 54.58 150.66 49.74C150.21 44.9 145.66 47.93 145.66 47.93C145.046 47.0202 144.136 46.3503 143.085 46.0333C142.034 45.7163 140.905 45.7717 139.89 46.19C135.89 47.49 137.58 49.74 137.58 49.74C137.58 49.74 133.77 49.52 134.73 53.48C135.69 57.44 137.87 57.43 137.87 57.43L137.97 56.26C137.97 56.26 136.97 52.26 138.97 52.26C138.97 52.26 138.68 53.46 140.32 53.78C141.96 54.1 142.6 52.26 142.6 52.26Z'
        fill='#232322'
      />
      <path
        d='M99.35 77L96.73 78.46C95.35 79.23 91.42 83.01 91.88 83.46C92.34 83.91 94.88 81.69 94.88 81.69C94.88 81.69 91.49 84.62 92.19 85.85C92.89 87.08 95.6 82.78 95.6 82.78C95.6 82.78 93.12 86.29 93.69 87.01C94.26 87.73 96.34 85.29 96.77 83.21C96.77 83.21 95.2 87.63 95.77 88.21C96.34 88.79 97.83 84.21 98.41 83.55C98.99 82.89 99.11 84.55 98.83 85.18C98.55 85.81 98.29 86.58 99.17 86.66C100.05 86.74 100.39 84.21 100.48 82.78C100.492 81.4245 100.836 80.0926 101.48 78.9L99.35 77Z'
        fill='#E5B8B0'
      />
      <path
        d='M179.53 84.87L179.35 85.08C178.196 86.429 177.423 88.0608 177.108 89.8077C176.794 91.5546 176.95 93.3537 177.561 95.0202C178.172 96.6868 179.215 98.1607 180.584 99.2907C181.953 100.421 183.598 101.166 185.35 101.45L187.19 101.74C189.479 102.094 191.821 101.643 193.814 100.463C195.807 99.2831 197.329 97.4475 198.12 95.27L200.24 89.42L193.58 86.47L179.53 84.87Z'
        fill='black'
      />
      <path
        d='M171.02 28.41L175.26 41.04C175.383 41.3817 175.572 41.6957 175.817 41.9639C176.062 42.2321 176.357 42.4491 176.686 42.6025C177.016 42.7559 177.372 42.8427 177.735 42.8577C178.097 42.8727 178.46 42.8157 178.8 42.69L192.08 38.18C192.778 37.956 193.363 37.4709 193.711 36.826C194.06 36.1812 194.145 35.4265 193.95 34.72L190.05 21.72C189.936 21.3652 189.751 21.0375 189.506 20.7567C189.261 20.476 188.962 20.2481 188.625 20.0871C188.289 19.9262 187.924 19.8354 187.552 19.8204C187.18 19.8054 186.808 19.8665 186.46 20L172.87 24.84C172.514 24.9525 172.184 25.1342 171.898 25.3747C171.612 25.6151 171.377 25.9096 171.205 26.2412C171.033 26.5728 170.928 26.9351 170.896 27.3072C170.865 27.6794 170.907 28.0541 171.02 28.41Z'
        fill='#B45D61'
      />
      <path
        d='M198.8 112.56C198.8 112.56 186.32 118.56 185.48 132.21L188.72 132.93L203.4 116.27C203.701 116.045 203.942 115.748 204.101 115.408C204.261 115.067 204.334 114.692 204.314 114.317C204.294 113.941 204.182 113.576 203.988 113.254C203.794 112.932 203.523 112.663 203.2 112.47C202.59 111.928 201.798 111.637 200.982 111.653C200.167 111.67 199.387 111.994 198.8 112.56Z'
        fill='#E7B8AE'
      />
      <path
        d='M220.85 66.06C220.85 66.06 232.5 79.61 235.14 87.01C238.48 96.39 238.92 82.39 238.92 82.39C238.92 82.39 236.98 74.2 226.92 65.72C223.824 62.0348 219.777 59.268 215.22 57.72C215.22 57.72 212.93 54.43 209.5 54.52V55.45C209.534 56.4187 209.839 57.3584 210.382 58.1613C210.925 58.9642 211.684 59.598 212.57 59.99L211.27 59.8C211.259 59.7967 211.248 59.7955 211.237 59.7965C211.227 59.7975 211.216 59.8007 211.206 59.8058C211.196 59.8109 211.188 59.8179 211.181 59.8263C211.174 59.8348 211.168 59.8445 211.165 59.855C211.162 59.8655 211.161 59.8766 211.162 59.8875C211.163 59.8985 211.166 59.9091 211.171 59.9189C211.176 59.9286 211.183 59.9373 211.191 59.9443C211.2 59.9514 211.209 59.9567 211.22 59.96L213.74 61.15C213.74 61.15 215.77 63.49 220.85 66.06Z'
        fill='#D6988E'
      />
      <path
        d='M236.84 78.76C238.593 78.1042 240.185 77.0814 241.51 75.76L239.36 69.25L235.52 65.07C235.52 65.07 226.94 70.02 227.33 71.92C228.27 76.6 233.81 76.47 233.81 76.47L236.84 78.76Z'
        fill='#E7B8AE'
      />
      <path
        d='M234.08 65.27C234.08 65.27 236.89 62.63 241.86 65.27C241.86 65.27 242.35 61.04 247.81 62.16C251.51 62.93 252.55 72.46 252.81 78.51C252.84 79.1466 252.74 79.7826 252.515 80.3789C252.29 80.9752 251.945 81.5192 251.502 81.9773C251.059 82.4354 250.527 82.7979 249.938 83.0426C249.35 83.2873 248.717 83.4089 248.08 83.4C246.854 83.3816 245.684 82.8815 244.823 82.0077C243.962 81.1339 243.48 79.9565 243.48 78.73V71.73C243.48 71.73 243.98 75.37 240.67 76.73C240.67 76.73 237.52 75.06 237.67 73.16C237.82 71.26 239.32 70.28 236.18 69.78C236.21 69.74 232.9 69.08 234.08 65.27Z'
        fill='black'
      />
      <path
        d='M236.3 70.19C236.3 70.19 236.88 69.29 237.71 69.58C238.54 69.87 238.71 71.92 237.27 73.48'
        fill='#E7B8AE'
      />
      <path
        d='M236.3 70.19C236.3 70.19 236.88 69.29 237.71 69.58C238.54 69.87 238.71 71.92 237.27 73.48'
        stroke='black'
        strokeMiterlimit='10'
      />
      <path
        d='M238.36 78.09C238.36 78.09 237.45 79.09 238.03 81.86C237.177 82.2616 236.227 82.4136 235.291 82.2986C234.355 82.1836 233.471 81.8062 232.74 81.21L233.81 75.62L238.36 78.09Z'
        fill='#E7B8AE'
      />
      <path
        d='M244.21 95.4199C243.21 92.0199 242.82 88.42 241.51 85.09C239.24 79.35 237.98 79.8 237.98 79.8L226.08 86.56C226.08 86.56 224.51 94.16 230.78 94.03L232.93 101.18C232.93 101.18 232.04 108.46 227.65 111.85C223.26 115.24 225.91 121.51 225.91 121.51L248.5 114.27C248.83 114.165 249.135 113.991 249.393 113.761C249.652 113.53 249.859 113.248 250.002 112.932C250.144 112.616 250.219 112.273 250.22 111.927C250.221 111.58 250.15 111.237 250.01 110.92C249.09 108.85 248.13 106.12 247.92 105.64C246.49 102.327 245.251 98.9349 244.21 95.48V95.4199Z'
        fill='black'
      />
      <path
        d='M237.95 85.14C238.006 84.4064 237.904 83.6694 237.65 82.9787C237.397 82.2879 236.999 81.6594 236.483 81.1354C235.966 80.6114 235.344 80.2042 234.657 79.941C233.97 79.6779 233.234 79.565 232.5 79.61C231.781 79.4325 231.029 79.4325 230.31 79.61'
        stroke='black'
        strokeWidth='5'
        strokeMiterlimit='10'
      />
      <path
        d='M198.68 88.96L184.24 89.42C184.24 89.42 175.17 83.23 174.13 72.01L172.98 69.56L175.06 70.95C174.61 70.0423 174.377 69.043 174.377 68.03C174.377 67.017 174.61 66.0177 175.06 65.11C175.06 65.11 178.16 67.54 177.54 71.05L185.43 83.51C185.43 83.51 192.12 82.37 197.05 81.69L198.68 88.96Z'
        fill='#FFE1E5'
      />
      <path
        d='M212.38 81.33L210.38 67.03C210.38 67.03 202.98 58.9 191.73 59.43L189.85 57.72L190.76 60.51C189.8 60.1876 188.779 60.092 187.776 60.2308C186.773 60.3696 185.816 60.739 184.98 61.31C184.98 61.31 187.82 64.04 191.21 62.94L204.64 69.03C204.64 69.03 204.44 75.81 204.46 80.79L212.38 81.33Z'
        fill='#F7D2D8'
      />
      <path
        d='M178.46 23.83C177.243 23.3184 176.147 22.5589 175.24 21.6C174.5 20.76 173.97 19.16 175.12 18.36C176.27 17.56 177.96 20.36 178.44 21.2C178.8 21.8 179.14 22.41 179.44 23.04'
        stroke='black'
        strokeMiterlimit='10'
      />
      <path
        d='M180.49 22.94C181.352 21.8835 181.963 20.6461 182.28 19.32C182.48 18.32 182.12 16.65 180.86 16.54C179.6 16.43 179.45 18.31 179.36 19.15C179.3 19.69 179.28 20.2299 179.26 20.7699C179.24 21.3099 179.26 22.0699 179.26 22.7099'
        stroke='black'
        strokeMiterlimit='10'
      />
      <path
        d='M194.22 65.94C194.22 65.94 189.89 67.52 191.66 73.52C193.43 79.52 193.73 79.06 197.27 79.74C200.81 80.42 204.56 78.45 203.77 73.52C202.98 68.59 201.8 64.27 194.22 65.94Z'
        fill='#FFE1E5'
      />
      <path
        d='M219.22 112.13L222.16 114.37C222.16 114.37 214.16 124.98 215.98 141.9L219.1 139.49C219.1 139.49 224.72 124.79 228.03 115.77C231.34 106.75 224.21 104.3 224.21 104.3L219.22 112.13Z'
        fill='#E7B8AE'
      />
      <path
        d='M184.22 131.56C184.22 131.56 187.08 133.85 188.16 133.49C189.24 133.13 189.16 134.49 189.16 134.49C188.999 135.523 189.079 136.579 189.393 137.577C189.707 138.574 190.247 139.485 190.97 140.24C193.47 142.96 191.97 144.08 191.97 144.08C191.97 144.08 179.86 138.17 179.52 136.23L184.22 131.56Z'
        fill='black'
        stroke='black'
        strokeWidth='0.71'
        strokeMiterlimit='10'
      />
      <path
        d='M184.58 130.89C184.728 130.735 184.927 130.639 185.141 130.621C185.355 130.602 185.568 130.663 185.74 130.79C186.61 131.42 188.17 132.44 188.9 132.18C189.9 131.8 189.9 133.18 189.9 133.18C189.725 134.189 189.783 135.225 190.071 136.208C190.359 137.191 190.868 138.095 191.56 138.85C193.93 141.52 192.42 142.65 192.42 142.65C192.42 142.65 182.73 137.91 180.84 135.65C180.726 135.522 180.663 135.356 180.663 135.185C180.663 135.014 180.726 134.848 180.84 134.72L184.58 130.89Z'
        fill='#DCE9E4'
      />
      <path
        d='M214.73 139.34C214.73 139.34 218.27 140.26 219.1 139.49C219.93 138.72 220.49 140 220.49 140C220.766 141.006 221.27 141.935 221.964 142.713C222.658 143.492 223.523 144.1 224.49 144.49C227.89 145.95 226.94 147.6 226.94 147.6C226.94 147.6 213.47 147.19 212.37 145.55L214.73 139.34Z'
        fill='black'
        stroke='black'
        strokeWidth='0.71'
        strokeMiterlimit='10'
      />
      <path
        d='M214.77 138.56C214.841 138.361 214.98 138.193 215.164 138.089C215.348 137.984 215.563 137.949 215.77 137.99C216.77 138.21 218.66 138.5 219.21 137.99C220.01 137.22 220.54 138.5 220.54 138.5C220.798 139.492 221.277 140.412 221.943 141.192C222.608 141.971 223.441 142.589 224.38 143C227.64 144.45 226.73 146.1 226.73 146.1C226.73 146.1 215.94 145.75 213.29 144.44C213.135 144.366 213.014 144.237 212.949 144.078C212.884 143.919 212.881 143.741 212.94 143.58L214.77 138.56Z'
        fill='#DCE9E4'
      />
      <path
        d='M217.75 98.56C215.69 97.27 214.13 96.08 213.29 93.74C212.68 92.03 212.01 90.34 211.37 88.64C213.37 87.18 213.67 85.51 213.3 84.02C213.182 83.5746 213.003 83.1474 212.77 82.75L212.72 76.85C210.92 73.76 204.13 76.66 204.13 76.66L204.61 80.66L199.43 81.37L199.48 80.94L192.22 81.72C189.33 83.82 192.89 90.29 192.89 90.29L197.81 89.2C198.81 92.2 200.45 94.95 201.57 97.9L201.99 104.32C201.99 104.32 190.93 116.01 186.79 122.32C186.021 123.433 185.412 124.648 184.98 125.93L192.22 130.66L199.49 123.14L212.38 110.66L219.72 115.25C215.31 120.79 213.82 135.25 213.82 135.25C216.4 137.64 221.37 137.25 221.37 137.25C221.37 137.25 227.5 121 229.37 112.66C231.24 104.32 217.75 98.56 217.75 98.56Z'
        fill='#5E6E81'
      />
      <path
        d='M209.55 80.73C209.55 80.73 225.55 77.92 234.91 80.45C238.48 81.45 236.5 83.7 234.83 85.07C233.861 85.8673 232.722 86.4317 231.5 86.72C228.5 87.43 221.89 88.35 212.75 85.9C207.953 85.4787 203.363 83.7493 199.48 80.9C199.48 80.9 195.48 80.9 193.58 78.03L194.36 77.53C195.174 77.0033 196.119 76.7164 197.089 76.7023C198.058 76.6881 199.011 76.9473 199.84 77.45L198.44 76.38C198.44 76.33 198.25 75.95 198.58 75.88C198.715 75.8507 198.855 75.8517 198.99 75.8828C199.125 75.9139 199.251 75.9744 199.36 76.06L201.46 77.75C201.46 77.75 204.54 78.03 209.55 80.73Z'
        fill='#D6988E'
      />
      <path
        d='M199.45 65.91C199.45 65.91 193.66 61.63 188 70.91L182.57 78.97L185.43 83.48L191.43 82.48L192.21 81.67L199.42 81.32C199.42 81.32 192.58 79.39 194.09 76.37C195.091 74.1871 195.794 71.8799 196.18 69.51C196.18 69.51 199.85 69.51 200.71 68.38C200.71 68.38 204.04 72.89 203.53 76.79L202.85 78.01L204.45 78.48L204.12 76.6L204.45 76.47L204.63 69.02L199.45 65.91Z'
        fill='black'
      />
      <path
        d='M104.23 128.82C104.23 128.82 106.15 131.94 107.23 131.96C108.31 131.98 107.88 133.29 107.88 133.29C107.383 134.206 107.105 135.223 107.067 136.264C107.029 137.305 107.232 138.341 107.66 139.29C109.1 142.69 107.27 143.22 107.27 143.22C107.27 143.22 97.8399 133.6 98.1799 131.66L104.23 128.82Z'
        fill='black'
        stroke='black'
        strokeWidth='0.71'
        strokeMiterlimit='10'
      />
      <path
        d='M140.51 101.67L144.84 94.1L153.71 97.88L150.28 105.89L140.51 101.67Z'
        fill='#D3A9B3'
      />
      <path
        d='M136.77 128.61C136.77 128.61 127.88 140.87 134.1 154.42L137.59 153.42L143.08 129.8C143.256 129.428 143.336 129.019 143.315 128.609C143.293 128.198 143.171 127.799 142.958 127.448C142.745 127.096 142.449 126.803 142.095 126.594C141.741 126.385 141.341 126.267 140.93 126.25C140.067 126.052 139.161 126.184 138.391 126.621C137.621 127.058 137.043 127.768 136.77 128.61Z'
        fill='#D3A9B3'
      />
      <path
        d='M127.66 119.74C127.66 119.74 113.9 118.11 105.86 129.13L108.2 131.48L129.54 125.34C129.915 125.314 130.278 125.196 130.596 124.996C130.914 124.796 131.178 124.521 131.365 124.194C131.551 123.867 131.653 123.5 131.663 123.124C131.672 122.748 131.589 122.376 131.42 122.04C131.199 121.253 130.689 120.579 129.991 120.152C129.294 119.726 128.461 119.578 127.66 119.74Z'
        fill='#D3A9B3'
      />
      <path
        d='M161.73 63.26C161.73 63.26 155.86 80.13 156.11 87.99C156.43 97.94 150.9 85.08 150.9 85.08C150.9 85.08 149.7 76.75 155.96 65.17C157.499 60.594 160.267 56.5292 163.96 53.42C163.96 53.42 164.88 49.52 168.11 48.35L168.42 49.23C168.735 50.1452 168.786 51.1307 168.567 52.0734C168.347 53.0161 167.867 53.8779 167.18 54.56L168.32 53.91C168.338 53.9032 168.357 53.902 168.375 53.9066C168.393 53.9112 168.41 53.9214 168.422 53.9357C168.434 53.9501 168.441 53.9678 168.443 53.9865C168.444 54.0052 168.44 54.0239 168.43 54.04L166.51 56.04C166.51 56.04 165.52 59.01 161.73 63.26Z'
        fill='#B28790'
      />
      <path
        d='M150.69 78.4C148.934 77.7452 147.338 76.7223 146.01 75.4L148.16 68.89L152 64.72C152 64.72 154.74 64.87 155.07 69.79C155.07 69.79 157.46 72.65 157.32 74.23C157.18 75.81 158.83 77.31 151.52 76.85L150.69 78.4Z'
        fill='#D3A9B3'
      />
      <path
        d='M149.17 77.73C149.17 77.73 150.08 78.73 149.5 81.5C150.353 81.9016 151.303 82.0536 152.239 81.9386C153.175 81.8236 154.059 81.4462 154.79 80.85L153.71 75.26L149.17 77.73Z'
        fill='#D3A9B3'
      />
      <path
        d='M141.45 98.5C141.45 98.5 143.31 95.07 143.31 95.06C144.31 91.65 144.7 88.06 146.01 84.73C148.28 78.99 149.55 79.44 149.55 79.44L157.55 84.87C157.466 86.0213 157.63 87.1773 158.03 88.26C159.15 91.38 160.75 95.61 155.63 96.49L153.22 102.64C151.071 102.915 148.888 102.684 146.845 101.965C144.801 101.246 142.954 100.059 141.45 98.5Z'
        fill='#EF8067'
      />
      <path
        d='M149.89 85.08C149.835 84.3464 149.937 83.6094 150.19 82.9187C150.443 82.2279 150.841 81.5994 151.357 81.0754C151.874 80.5514 152.497 80.1442 153.184 79.881C153.871 79.6179 154.606 79.505 155.34 79.55C156.059 79.3725 156.811 79.3725 157.53 79.55'
        stroke='#EF8067'
        strokeWidth='3'
        strokeMiterlimit='10'
      />
      <path
        d='M132.66 152.63C132.66 152.63 136.51 153.75 137.44 152.94C138.37 152.13 138.93 153.55 138.93 153.55C139.201 154.66 139.723 155.692 140.456 156.568C141.19 157.444 142.115 158.139 143.16 158.6C146.83 160.3 145.74 162.07 145.74 162.07C145.74 162.07 131.03 161.17 129.88 159.34L132.66 152.63Z'
        fill='black'
        stroke='black'
        strokeWidth='0.71'
        strokeMiterlimit='10'
      />
      <path
        d='M132.73 151.79C132.821 151.573 132.985 151.396 133.193 151.288C133.401 151.18 133.641 151.149 133.87 151.2C135.01 151.48 137 151.86 137.63 151.29C138.53 150.47 139.06 151.89 139.06 151.89C139.314 152.975 139.81 153.989 140.512 154.855C141.213 155.721 142.101 156.416 143.11 156.89C146.62 158.6 145.57 160.37 145.57 160.37C145.57 160.37 133.79 159.62 130.94 158.1C130.772 158.016 130.642 157.871 130.577 157.694C130.512 157.518 130.517 157.323 130.59 157.15L132.73 151.79Z'
        fill='#5E6E81'
      />
      <path
        d='M104.79 128.29C104.982 128.193 105.204 128.17 105.412 128.225C105.62 128.28 105.801 128.41 105.92 128.59C106.53 129.47 107.65 130.96 108.43 130.95C109.53 130.95 109.03 132.24 109.03 132.24C108.529 133.135 108.238 134.132 108.179 135.156C108.12 136.18 108.295 137.204 108.69 138.15C110.03 141.45 108.23 142.02 108.23 142.02C108.23 142.02 100.69 134.29 99.68 131.51C99.6227 131.347 99.6265 131.169 99.691 131.009C99.7554 130.849 99.8759 130.718 100.03 130.64L104.79 128.29Z'
        fill='#5E6E81'
      />
      <path
        d='M174.08 67.49C174.08 67.49 159.35 74.49 153.2 81.89C150.85 84.74 153.78 85.45 155.94 85.61C157.188 85.7089 158.442 85.5137 159.6 85.04C162.46 83.89 168.39 80.83 174.45 73.56C178.138 70.446 180.905 66.3828 182.45 61.81C182.45 61.81 185.72 59.5 185.61 56.07H184.68C183.713 56.1091 182.776 56.4196 181.976 56.966C181.177 57.5125 180.548 58.2728 180.16 59.16L180.68 57.48C180.68 57.42 180.59 57.02 180.28 57.16C180.153 57.2142 180.039 57.2962 179.947 57.4001C179.856 57.5039 179.788 57.6268 179.75 57.76L179.01 60.34C179.01 60.34 176.58 62.39 174.08 67.49Z'
        fill='#D3A9B3'
      />
      <path
        d='M151.31 69.38C151.31 69.38 154.62 68.71 153.46 64.91C152.367 63.8113 150.944 63.1011 149.409 62.888C147.874 62.6749 146.311 62.9706 144.96 63.73C144.96 63.73 139.66 65.8 139.22 73.12C139.02 76.43 139 79.3 139.04 81.45C139.061 82.5703 139.521 83.6374 140.322 84.4213C141.123 85.2051 142.199 85.6428 143.32 85.64H147.32L149.55 85.31L150.63 75.26L150.1 70.85L151.31 69.38Z'
        fill='black'
      />
      <path
        d='M150.81 73.91C149.17 73.23 148.98 69.5 149.81 69.22C150.64 68.94 151.23 69.82 151.23 69.82'
        fill='#D3A9B3'
      />
      <path
        d='M148.94 107.94L150.94 130.48C150.968 130.811 150.917 131.143 150.79 131.45C150.663 131.757 150.464 132.028 150.211 132.242C149.957 132.456 149.655 132.605 149.331 132.678C149.007 132.751 148.671 132.745 148.35 132.66C138.43 130.03 127.57 122.03 123.1 118.48C122.85 118.282 122.649 118.028 122.514 117.739C122.378 117.451 122.312 117.134 122.319 116.815C122.327 116.496 122.409 116.183 122.558 115.901C122.708 115.619 122.92 115.376 123.18 115.19C123.18 115.19 132.59 109.61 133.68 105.89C134.77 102.17 140.51 101.67 140.51 101.67L150.28 105.89L148.94 107.94Z'
        fill='#DCE9E4'
      />
      <defs>
        <radialGradient
          id='paint0_radial_1002_2680'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(12961.2 15889.8) scale(1687.8)'
        >
          <stop stopColor='#EFBB00' stopOpacity='0.95' />
          <stop offset='0.13' stopColor='#ECBB11' stopOpacity='0.97' />
          <stop offset='0.33' stopColor='#E6BB34' />
          <stop offset='0.4' stopColor='#E6BC33' stopOpacity='0.98' />
          <stop offset='0.49' stopColor='#E7C031' stopOpacity='0.92' />
          <stop offset='0.59' stopColor='#E7C72C' stopOpacity='0.82' />
          <stop offset='0.68' stopColor='#E9D027' stopOpacity='0.69' />
          <stop offset='0.78' stopColor='#EADC1F' stopOpacity='0.51' />
          <stop offset='0.88' stopColor='#ECEB16' stopOpacity='0.29' />
          <stop offset='0.98' stopColor='#EEFC0B' stopOpacity='0.04' />
          <stop offset='1' stopColor='#EEFF09' stopOpacity='0' />
        </radialGradient>
      </defs>
    </svg>
  );
};

InsightsTrendifyCardBg1.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

InsightsTrendifyCardBg1.defaultProps = {
  width: '354px',
  height: '165px',
};

export default InsightsTrendifyCardBg1;
