import React, { Component } from 'react';

import { engagementConfirmOnLeave } from '../actions/ui';
import { t } from '../system/ui';
import { setTitle } from '../system/document';

import { advancedConfig } from '../constants/datamaps/campaign';

import AddCampaign from '../modules/campaign/add';

class AddNewsletter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 'E-Mail Collection',
      content: [
        {
          field: 'title',
          options: {
            label: 'Title',
            required: true,
            tooltip: true,
            tooltipContent:
              'Enter the title of the pop-up that will be displayed for email collection.',
          },
        },
        {
          field: 'description',
          options: {
            label: 'Description',
            name: 'description',
            tooltip: true,
            tooltipContent:
              'Enter the description of the pop-up that will be displayed for email collection.',
          },
        },
        {
          field: 'fullNamePlaceHolder',
          options: {
            label: 'Full Name Placeholder',
            required: true,
            tooltip: true,
            tooltipContent:
              'Enter the name and surname of the pop-up that will be displayed for email collection.',
          },
        },
        {
          field: 'placeholder',
          options: {
            tooltip: true,
            tooltipContent:
              'Enter the name of the information that you would like your visitors to fill in the form as a placeholder.',
          },
        },
        {
          field: 'buttonSubmitText',
          options: {
            label: 'Submit Button Text',
            name: 'buttonSubmitText',
            tooltip: true,
            tooltipContent:
              "Enter the 'Call to Action' text. For example, 'Send'",
          },
        },
        {
          field: 'termsText',
          options: {
            label: 'Terms Text',
            name: 'termsText',
            tooltip: true,
            tooltipContent:
              "Enter the text to direct visitors to visit the page where your legal statement is published. For example, 'Terms&Conditions'",
          },
        },
        {
          field: 'termsUrl',
          options: {
            label: 'Terms URL',
            name: 'termsUrl',
            tooltip: true,
            tooltipContent:
              'Enter the URL address of the page where your legal statement is published to direct your visitors.',
          },
        },
      ],
      contentExtra: {
        buttons: [
          { field: 'stylesButton', ignoreRef: true },
          { field: 'positionButton', ignoreRef: true },
          { field: 'timingButton', ignoreRef: true },
          { field: 'audienceButton', ignoreRef: true },
        ],
        fields: [
          {
            field: 'stylesOptions',
            options: {
              buttonTextColor: { show: true, label: 'Background Color' },
              buttonBgColor: { show: true, label: 'Text Color' },
              bgColor: { show: false },
              textColor: { show: false },
            },
          },
          { field: 'positionOptions' },
          { field: 'timingOptions' },
          { field: 'audienceOptions' },
        ],
      },
      options: [
        { field: 'name' },
        { field: 'startEndDate' },
        { field: 'devices' },
        {
          field: 'pages',
          options: {
            hasPageUrlFilter: true,
          },
        },
        {
          field: 'advancedConfig',
          options: advancedConfig.NEWSLETTER,
        },
      ],
      buttons: [
        { field: 'advancedConfigButton' },
        { field: 'goTest' },
        { field: 'goLive' },
      ],
    };
  }

  componentDidMount() {
    setTitle(t('Add E-Mail Collection'));
    engagementConfirmOnLeave(this);
  }

  render() {
    return (
      <AddCampaign
        type='NEWSLETTER'
        title={this.state.title}
        content={this.state.content}
        contentExtra={this.state.contentExtra}
        options={this.state.options}
        buttons={this.state.buttons}
        duplicate={this.props.routeParams.campaignId}
      />
    );
  }
}

export default AddNewsletter;
