import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const SegmentifyAnalytics = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 80 80'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      fill='none'
    >
      <circle cx='40' cy='40' r='40' fill='#16808e' strokeWidth={0} />
      <g>
        <path
          d='m19.42,40.62c-1.69,0-3.21-1.01-3.85-2.57-.64-1.56-.29-3.35.91-4.54,1.19-1.19,2.99-1.55,4.54-.91,1.56.65,2.57,2.17,2.57,3.85,0,2.3-1.86,4.17-4.17,4.17h0Z'
          fill='#0d4344'
          strokeWidth={0}
          fillRule='evenodd'
        />
        <path
          d='m60.55,44.16c-1.68-.01-3.19-1.04-3.83-2.6-.64-1.56-.27-3.35.92-4.53,1.19-1.19,2.99-1.53,4.54-.89,1.55.65,2.57,2.17,2.57,3.85,0,2.3-1.86,4.17-4.17,4.17h-.03Z'
          fill='#0d4344'
          strokeWidth={0}
          fillRule='evenodd'
        />
        <path
          d='m46.79,32.99c-1.69,0-3.21-1.01-3.86-2.57-.65-1.56-.3-3.35.9-4.55,1.19-1.19,2.98-1.56,4.54-.91,1.56.65,2.58,2.17,2.58,3.85,0,2.3-1.86,4.16-4.16,4.17h0Z'
          fill='#0d4344'
          strokeWidth={0}
          fillRule='evenodd'
        />
        <path
          d='m31.4,55.35c-1.69,0-3.21-1.01-3.86-2.57-.65-1.56-.3-3.36.89-4.55,1.19-1.19,2.99-1.55,4.55-.91s2.58,2.17,2.58,3.85c0,2.29-1.86,4.16-4.16,4.17h0Z'
          fill='#0d4344'
          strokeWidth={0}
        />
        <rect
          x='21.25'
          y='42.69'
          width='7.97'
          height='2.33'
          rx='.4'
          ry='.4'
          transform='translate(40.96 -4.24) rotate(48.02)'
          fill='#ffffff'
          strokeWidth={0}
        />
        <rect
          x='50.24'
          y='33.24'
          width='7.08'
          height='2.33'
          rx='.4'
          ry='.4'
          transform='translate(34.61 -26.49) rotate(39.92)'
          fill='#ffffff'
          strokeWidth={0}
        />
        <rect
          x='32.19'
          y='39.08'
          width='14.32'
          height='2.33'
          rx='.4'
          ry='.4'
          transform='translate(94.73 30.21) rotate(124.09)'
          fill='#ffffff'
          strokeWidth={0}
        />
      </g>
    </svg>
  );
};

SegmentifyAnalytics.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

SegmentifyAnalytics.defaultProps = {
  width: '28px',
  height: '28px',
};

export default SegmentifyAnalytics;
