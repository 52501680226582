import React, { useState, useRef, useEffect } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { InputCardWrapper } from '../../gamification/module-exports';
import { t } from '../../../system/ui';
import { TextField } from '../../fields';
import Icons from '../../icons';
import classes from './SFYTextWithIcon.module.scss';
import SampleContentModal from '../../../modules/emails/sample-content-modal';
import { modalActions } from '../../../actions';

import './emoji-picker.css';

const SFYTextWithIcon = ({
  placeholder,
  editCampaign,
  fieldValue,
  cardTitle,
  cardName,
  sampleTextVisibility = false,
  sampleContent,
  readOnly = false,
  key,
}) => {
  const isOutSideOfTooltip = useRef(null);
  const [tooltipVisibility, setTooltipVisibility] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(fieldValue.length);

  const handleEmojiClick = () => {
    if (readOnly) return;
    setTooltipVisibility(!tooltipVisibility);
  };

  const onClick = emojiData => {
    if (readOnly) return;

    const newFieldValue = `${fieldValue.slice(0, cursorPosition)}${
      emojiData.emoji
    }${fieldValue.slice(cursorPosition)}`;

    setCursorPosition(cursorPosition + 2);

    editCampaign(`${cardName}`, newFieldValue);
  };

  const onSampleTextSelect = (contentType, selectedContent) => {
    editCampaign(`${cardName}`, selectedContent);
    modalActions.closeModal();
  };

  const sampleTextHandler = () => {
    const sampleContentModal = () => (
      <SampleContentModal
        content={sampleContent}
        onSelect={onSampleTextSelect}
        contentType='subject'
      />
    );

    modalActions.openModal({
      title: 'Mail Subject',
      content: sampleContentModal,
    });
  };

  const handleClickOutside = e => {
    if (
      isOutSideOfTooltip.current &&
      !isOutSideOfTooltip.current.contains(e.target)
    ) {
      setTooltipVisibility(false);
    }
  };

  const changeCursorPosition = e => {
    setCursorPosition(e.target.selectionEnd);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className={classes['sfy-wrapper']} key={key}>
      <div
        className={`${(!sampleTextVisibility || readOnly) && classes.hidden} ${
          classes['sample-text-wrapper']
        }`}
        onClick={sampleTextHandler}
      >
        +Sample Text
      </div>
      <InputCardWrapper
        type='block'
        cardTitle={cardTitle}
        labelClass='cardTitle'
      >
        <div className={classes['sfy-text-icon-main-wrapper']}>
          <TextField
            name={cardName}
            id={cardName}
            className='item-stacked one-whole'
            required
            placeholder={t(placeholder)}
            value={fieldValue}
            onChange={e => editCampaign(`${cardName}`, e.target.value)}
            onMouseUp={changeCursorPosition}
            onKeyUp={changeCursorPosition}
            disabled={readOnly}
            key={key}
          />
          <div
            id='emoji-btn'
            className={`${classes['emoji-wrapper']} ${readOnly &&
              classes['disabled-emoji-wrapper']}`}
            onClick={handleEmojiClick}
          >
            <Icons name='emoji' />
          </div>
          <div
            className={`${
              classes['emoji-tooltip-wrapper']
            } ${!tooltipVisibility && classes.hidden}`}
            ref={isOutSideOfTooltip}
          >
            <EmojiPicker
              onEmojiClick={onClick}
              autoFocusSearch={false}
              key={key}
            />
          </div>
        </div>
      </InputCardWrapper>
    </div>
  );
};

export default SFYTextWithIcon;
