import React, { useRef } from 'react';
import '../../gamification/GFCCampaignList/GFCampaignList.scss';
import { useDrag, useDrop } from 'react-dnd';
import moment from 'moment/moment';
import { devicesWithoutNative } from '../../../constants/datamaps/campaigns';
import Icon from '../../icon';
import { t } from '../../../system/ui';
import ListActions from '../ListActions/ListActions';
import { campaignTypes } from './constant';
import { types } from '../../../constants/datamaps/campaign';

/**
 * @name GFCampaignList component
 * @description Campaign component displays list of items and its actions
 * @param {object} priorityItem
 * @param {function} moveCard
 * @param {function} moveCompleted
 * @param {object} actions - action constant
 * @param {boolean} isDraggable - is draggable
 * @returns {JSX.Element}
 */
const DraggableListItem = ({
  priorityItem,
  moveCard,
  moveCompleted,
  actions,
  isDraggable = true,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'bundle',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = priorityItem.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'bundle',
    item: () => {
      return { id: priorityItem.index, index: priorityItem.index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end() {
      moveCompleted();
    },
  });
  const opacity = isDragging ? 0 : 1;
  const cursor = isDragging ? 'grabbing' : 'grab';

  drag(drop(ref));

  return (
    <>
      <div
        className='gfc-campaignList-item'
        ref={isDraggable ? ref : null}
        style={isDraggable ? { opacity, cursor } : {}}
        key={priorityItem?.index}
        data-handler-id={handlerId}
      >
        <div className='gfc-campaignList-item-left'>
          {(priorityItem?.status === 'ACTIVE' ||
            priorityItem?.status === 'TEST') && (
            <div className='gfc-campaignList-item-priority'>
              <span>Priority</span>
              {priorityItem.index + 1}
            </div>
          )}
          <div className='gfc-campaignList-item-campaign'>
            {priorityItem.name}{' '}
            <span>
              (
              {types['social-proof']?.relatedTypes[priorityItem.type]
                ? types['social-proof']?.relatedTypes[priorityItem.type].title
                : campaignTypes[priorityItem.type] === 'Scratch'
                ? 'Scratch Off'
                : campaignTypes[priorityItem.type]}
              )
            </span>
          </div>
        </div>
        <div className='gfc-campaignList-item-right'>
          <div className='gfc-campaignList-item-detail'>
            <div className='widget-meta'>
              <span style={{ marginLeft: 10 }} className='widget-meta-mobile'>
                <Icon name='calendar' />
                <span>
                  {moment(priorityItem?.startDate).format('DD/MM/YYYY')} created
                </span>
              </span>
              |&ensp;&nbsp;
              {Object.keys(devicesWithoutNative).map(deviceKey => {
                const device = devicesWithoutNative[deviceKey];
                if (
                  priorityItem?.devices?.includes(deviceKey) ||
                  priorityItem?.devices?.includes('ALL')
                ) {
                  if (deviceKey === 'MOBILE') {
                    return (
                      <>
                        <span key={device} className='widget-meta-mobile'>
                          <Icon name={device.toLowerCase()} />
                          {t(device)}
                        </span>
                        <span
                          key={devicesWithoutNative.TABLET}
                          className='widget-meta-mobile'
                        >
                          <Icon
                            name={devicesWithoutNative.TABLET.toLowerCase()}
                          />
                          {t(devicesWithoutNative.TABLET)}
                        </span>
                      </>
                    );
                  }
                  return (
                    <span key={device} className='widget-meta-mobile'>
                      <Icon name={device.toLowerCase()} />
                      {t(device)}
                    </span>
                  );
                }
                return null;
              })}
            </div>
          </div>
          <div className='gfc-campaignList-item-actions'>
            {actions && <ListActions actions={actions} item={priorityItem} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default DraggableListItem;
