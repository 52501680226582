/**
 * @author Mehmet Yurtar
 */

import React from 'react';
import { connect } from 'react-redux';
import { t } from '../../system/ui';

import Icon from '../icon';
import Icons from '../icons';

import { TextField } from '../fields';

class IncludesSize extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sizeInclusion: '',
    };

    this.onChange = this.onChange.bind(this);
    this.setValues = this.setValues.bind(this);
    this.onSubComponentChange = this.onSubComponentChange.bind(this);
  }

  componentDidMount() {
    this.setValues();
  }

  setValues() {
    const newState = {
      sizeInclusion:
        typeof this.props.values.size !== 'undefined'
          ? this.props.values.size.value
          : '',
    };
    this.props.subComponents.forEach(subComponent => {
      newState[`sizeInclusion${subComponent.id}`] =
        typeof subComponent.values.size !== 'undefined'
          ? subComponent.values.size.value
          : '';
    });
    this.setState(newState);
  }

  onChange(e) {
    const { value } = e.currentTarget;

    this.setState({
      sizeInclusion: value,
    });

    this.props.onInclusionChange(this.refs.inputs, this.props.id);
  }

  onSubComponentChange(ref, id, e) {
    const { value } = e.currentTarget;

    const newState = {};
    newState[`sizeInclusion${id}`] = value;
    this.setState(newState);

    this.props.onInclusionChange(this.refs[ref], id);
  }

  render() {
    return (
      <li className='wizard-criterion'>
        <span ref='inputs'>
          <span className='criteria-type'>
            <Icons name='excludeCustom' color='#787774' />
          </span>
          <div className='wizard-criterion-text'>
            <h3 className='wizard-criterion-title'>{t('Size')}</h3>
            <p className='wizard-criterion-description'>
              {t(
                'Include the sizes that your selected algorithm will recommend products from.',
              )}
            </p>
          </div>
          <span className='wizard-product-name'>
            <TextField
              name='size'
              value={this.state.sizeInclusion}
              className='item-field criteria-name'
              inputClassName='one-whole criteria-field'
              onChange={this.onChange}
            />
          </span>
          <div className='buttons-criteria'>
            <a
              onClick={this.props.onInclusionRemove.bind(null, this.props.id)}
              className='remove-criteria button-criteria'
            >
              <Icons name='crossCircle' />
            </a>
            {this.props.subComponents.length < 2 && (
              <a
                onClick={this.props.onInclusionAdd.bind(null, 'size')}
                className='button-criteria'
              >
                <Icons name='plusCircle' width='32' height='32' />
              </a>
            )}
          </div>
        </span>
        {this.props.subComponents.map((subComponent, ind) => {
          if (ind > 0) {
            return (
              <span
                ref={`subComponentInputs${subComponent.id}`}
                className='criteria-subcomponent'
                key={subComponent.id}
              >
                <span className='wizard-product-name'>
                  <TextField
                    name='size'
                    value={this.state[`sizeInclusion${subComponent.id}`]}
                    className='item-field criteria-name'
                    inputClassName='one-whole criteria-field'
                    onChange={this.onSubComponentChange.bind(
                      this,
                      `subComponentInputs${subComponent.id}`,
                      subComponent.id,
                    )}
                  />
                </span>
                <div className='buttons-criteria'>
                  {ind === this.props.subComponents.length - 1 && (
                    <a
                      onClick={this.props.onInclusionAdd.bind(
                        null,
                        subComponent.type,
                      )}
                      className='button-criteria'
                    >
                      <Icons name='plusCircle' width='32' height='32' />
                    </a>
                  )}
                  <a
                    onClick={this.props.onInclusionRemove.bind(
                      null,
                      subComponent.id,
                    )}
                    className='remove-criteria button-criteria'
                  >
                    <Icons name='crossCircle' />
                  </a>
                </div>
              </span>
            );
          }
          return null;
        })}
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
});

export default connect(mapStatesToProps)(IncludesSize);
