/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconUserAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={this.state.width}
        height={this.state.height}
      >
        <g>
          <path
            fill={this.state.color}
            className="svg-path"
            d="M224.75,287.25h0c-68.75,0-125-56.25-125-125V131c0-68.75,56.25-125,125-125h0c68.75,0,125,56.25,125,125v31.25C349.75,231,293.5,287.25,224.75,287.25Z"
          />
          <path
            fill={this.state.color}
            className="svg-path"
            d="M162.25,349.75C74.75,349.75,6,418.5,6,506H256V349.75Z"
          />
          <polygon
            fill={this.state.color}
            className="svg-path"
            points="443.5 318.5 381 318.5 381 381 318.5 381 318.5 443.5 381 443.5 381 506 443.5 506 443.5 443.5 506 443.5 506 381 443.5 381 443.5 318.5"
          />
        </g>
      </svg>
    );
  }
}

export default IconUserAdd;
