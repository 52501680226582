/**
 * Created by mehmetyurtar on 12/29/17.
 */
import React from "react";

class Cancelled extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 37.12 33.05"
        width={this.state.width}
        height={this.state.height}
      >
        <title>canceled-order</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              d="M33,22.63H13.69L8.83,5.51l-8-1.7a1,1,0,0,1,.42-2L10.43,3.8l4.78,16.83H31.56l3.62-10.07a1,1,0,0,1,1.88.68Z"
              fill={this.state.color}
            />
            <path
              d="M25.13,6.62l4.58-4.94A1,1,0,0,0,28.24.32L23.77,5.15,19.29.32a1,1,0,0,0-1.41,0,1,1,0,0,0-.05,1.41l4.58,4.94-4.58,4.93a1,1,0,0,0,.73,1.68,1,1,0,0,0,.73-.32l4.48-4.82,4.47,4.82a1,1,0,0,0,.74.32,1,1,0,0,0,.73-1.68Z"
              fill="#d62028"
            />
            <path
              d="M16.24,26.41a2.32,2.32,0,1,1-2.32,2.32,2.32,2.32,0,0,1,2.32-2.32m0-2a4.32,4.32,0,1,0,4.32,4.32,4.33,4.33,0,0,0-4.32-4.32Z"
              fill={this.state.color}
            />
            <path
              d="M30,26.41a2.32,2.32,0,1,1-2.32,2.32A2.32,2.32,0,0,1,30,26.41m0-2a4.32,4.32,0,1,0,4.32,4.32A4.33,4.33,0,0,0,30,24.41Z"
              fill={this.state.color}
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default Cancelled;
