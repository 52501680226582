import { initialCountDown } from '../../constants/constants';

/**
 * @name CountDownReducer
 * @summary Reducer for Countdown
 * @param {any} state
 * @param {any[]} action
 */

export const CountDownReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_COUNTDOWN_LIST': {
      return {
        ...state,
        countdownCampaignList: payload,
      };
    }
    case 'SET_ACTIVE_COUNTDOWN_CAMPAIGN':
      return {
        ...state,
        activeCountdownCampaign: payload,
      };
    case 'EDIT_ACTIVE_COUNTDOWN_CAMPAIGN':
      return {
        ...state,
        activeCountdownCampaign: {
          ...state.activeCountdownCampaign,
          ...payload,
        },
      };
    case 'RESET_ACTIVE_COUNTDOWN_CAMPAIGN':
      return {
        ...state,
        activeCountdownCampaign: initialCountDown,
      };
    default:
      return state;
  }
};
