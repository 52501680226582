/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class excludeGender extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            data-color="color-2"
            fill={this.state.color}
            d="M14,11c0-3.86-3.14-7-7-7s-7,3.14-7,7c0,3.519,2.613,6.432,6,6.92V20H4v2h2v2h2v-2h2v-2H8v-2.08C11.387,17.432,14,14.519,14,11z M2,11c0-2.757,2.243-5,5-5s5,2.243,5,5s-2.243,5-5,5S2,13.757,2,11z"
          />
          <path
            fill={this.state.color}
            d="M18,1v2h2.586l-2.402,2.402C17.015,4.526,15.57,4,14,4c-3.86,0-7,3.14-7,7s3.14,7,7,7s7-3.14,7-7c0-1.57-0.526-3.015-1.402-4.184L22,4.414V7h2V1H18z M14,16c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S16.757,16,14,16z"
          />
        </g>
      </svg>
    );
  }
}

export default excludeGender;
