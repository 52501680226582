import { t } from '../../../system/ui';

export const initialState = {
  tab: 'currency',
  fields: [],
  currencies: [],
  regionDetails: [],
  mainCurrency: '',
  mainRegion: '',
  buttons: [
    {
      type: 'tertiary',
      label: 'Update',
      submitAnimation: true,
    },
  ],
  error: null,
  ui: 'isLoaded',
};

export const UI_ACTIONS = {
  IS_LOADING: 'isLoading',
  IS_LOADED: 'isLoaded',
  IS_SUBMITTING: 'isSubmitting',
};

export const decimals = [
  { label: '#', value: 0 },
  { label: '#.0', value: 1 },
  { label: '#.00', value: 2 },
  { label: '#.000', value: 3 },
];
export const decimalSeparators = [
  { label: t('Comma (,)'), value: ',' },
  { label: t('Dot (.)'), value: '.' },
];
export const thousandSeparators = [
  { label: t('Comma (,)'), value: ',' },
  { label: t('Dot (.)'), value: '.' },
  { label: t('Empty Space'), value: '__EMPTY__' },
  { label: t('None'), value: '__NONE__' },
];
export const symbolPlaces = [
  { label: t('Before'), value: true },
  { label: t('After'), value: false },
];

export const fieldNames = {
  code: 'code',
  decimalSeperator: 'decimalSeparator',
  decimals: 'decimals',
  symbol: 'symbol',
  symbolBefore: 'symbolBefore',
  thousandSeparator: 'thousandSeparator',
};

export function fieldMapper(account) {
  return [
    {
      label: 'Code',
      name: fieldNames.code,
      className: 'item-stacked one-whole',
      placeHolder: 'Currency Code',
      required: true,
      defaultValue: account.mainCurrency.code,
      tooltipText: t('You can check your currency code here'),
      tooltip: true,
      tooltipLink: 'https://www.iban.com/currency-codes.html',
    },
    {
      label: 'Decimal Places',
      name: fieldNames.decimals,
      className: 'one-whole',
      value: account.mainCurrency.decimals,
      options: decimals,
      type: 'select',
    },
    {
      label: 'Decimal Separator',
      name: fieldNames.decimalSeperator,
      className: 'one-whole',
      options: decimalSeparators,
      value: account.mainCurrency.decimalSeparator,
      type: 'select',
    },
    {
      label: 'Thousand Seperator',
      name: fieldNames.thousandSeparator,
      className: 'one-whole',
      options: thousandSeparators,
      value: account.mainCurrency.thousandSeparator,
      type: 'select',
    },
    {
      label: 'Symbol',
      name: fieldNames.symbol,
      className: 'item-stacked one-whole',
      placeHolder: 'Symbol',
      required: true,
      defaultValue: account.mainCurrency.symbol,
    },
    {
      label: 'Place Symbol',
      name: fieldNames.symbolBefore,
      className: 'one-whole',
      options: symbolPlaces,
      value: account.mainCurrency.symbolBefore,
      type: 'select',
    },
  ];
}
