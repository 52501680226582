import { userIdentify } from './events/userIdentify';

import { GuidanceActions } from './constants';

export const UserGuidance = action => {
  switch (action.type) {
    case GuidanceActions.USER_IDENTIFY:
      return userIdentify(action.payload);
    default:
      return null;
  }
};
