import React from 'react';
import { getPermissionReport } from '../../modules/push/ajax';
import { localeString } from '../../system/string';
import Tooltip from '../../components/tooltip';
import { t } from '../../system/ui';

const browsers = [
  'Chrome',
  'Firefox',
  'Safari',
  'Opera',
  'Yandex',
  'UCBrowser',
  'Samsung',
];

const deviceList = ['ANDROID', 'IOS'];

const getSegmentifyTotal = data => {
  let sum = 0;
  Object.keys(data).forEach(obj => {
    sum += data[obj].segmentifyCount;
  });
  return sum;
};

const getNonSegmentifyTotal = data => {
  let sum = 0;
  Object.keys(data).forEach(obj => {
    sum += data[obj].nonSegmentifyCount;
  });
  return sum;
};

class PushPermissionReport extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidUpdate() {
    let segmentifyCount = getSegmentifyTotal(this.props.report);
    let nonSegmentifyCount = getNonSegmentifyTotal(this.props.report);
    let totalCount = segmentifyCount + nonSegmentifyCount;
    if (this.props.isSingle) {
      this.props.getAllPermissions(
        <div
          className='permission-browsers-container'
          style={{ fontSize: '14px', marginTop: '-10px', textAlign: 'center' }}
        >
          All Granted Permissions <br />
          <span
            className='permission-report-all--count'
            style={{ fontSize: '24px', fontWeight: 'bold' }}
          >
            {totalCount}
          </span>
        </div>,
      );
    } else {
      if (segmentifyCount != totalCount) {
        this.props.getAllPermissions(
          <div
            className='permission-report-all'
            style={{ fontSize: '12px', color: 'black' }}
          >
            <div style={{ width: '60%', float: 'left' }}>
              All Granted Permissions <br />
              <span
                className='permission-report-all--count'
                style={{ fontSize: '26px', fontWeight: 'bold' }}
              >
                {totalCount}
              </span>
            </div>
            <div>
              All Segmentify Permissions <br />
              <span
                className='permission-report-all--count'
                style={{ fontSize: '26px', fontWeight: 'bold' }}
              >
                {segmentifyCount}
              </span>
            </div>
          </div>,
        );
      } else {
        this.props.getAllPermissions(
          <div
            className='permission-report-all'
            style={{ fontSize: '12px', color: 'black', marginTop: '-10px' }}
          >
            All Granted Permissions <br />
            <span
              className='permission-report-all--count'
              style={{ fontSize: '26px', fontWeight: 'bold' }}
            >
              {totalCount}
            </span>
          </div>,
        );
      }
    }
  }

  render() {
    return [
      <div className='permission-browsers-container'>
        <div className='permission-browsers'>
          <h3>Desktop</h3>
          <hr className='widget-separator' />

          <div className='permission-browser-list'>
            {browsers.map(browser => {
              if (
                browser === 'Chrome' ||
                browser === 'Firefox' ||
                browser === 'Opera' ||
                browser === 'Yandex' ||
                browser === 'Safari'
              ) {
                let className =
                  'permission-browser ' + browser + ' permission-browser-PC';
                return (
                  <Tooltip
                    key={browser + ' PC'}
                    content={t(browser)}
                    newTooltip={true}
                    alignment='left'
                    isSpan={true}
                    isInline={true}
                    style={{ display: 'inline-block' }}
                  >
                    <div className={className}>
                      <div>
                        <div className='permission-browser-count'>
                          {localeString(
                            this.props.report[browser + 'PC'].segmentifyCount,
                          )}
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
        <div className='permission-browsers'>
          <hr className='widget-separator' />

          <div className='permission-browser-container'>
            <div className='container-browsers-head'>
              <h3>Android Mobile</h3>
            </div>
            <div className='container-apps-head'>
              <h3>Apps</h3>
            </div>
          </div>
          <hr className='widget-separator' />
          <div className='permission-browser-list permission-browser-list-left'>
            <div className='permission-browser-container'>
              <div className='container-browsers'>
                {browsers.map(browser => {
                  if (
                    browser === 'Chrome' ||
                    browser === 'Firefox' ||
                    browser === 'Opera' ||
                    browser === 'Samsung' ||
                    browser === 'Yandex' ||
                    browser === 'UCBrowser'
                  ) {
                    let className =
                      'permission-browser ' +
                      browser +
                      ' permission-browser-Mobile';
                    return (
                      <Tooltip
                        key={browser + ' Mobile'}
                        content={t(browser)}
                        newTooltip={true}
                        alignment='left'
                        isSpan={true}
                        isInline={true}
                      >
                        <div
                          className={className}
                          style={{ marginRight: '15px' }}
                        >
                          <div>
                            <div className='permission-browser-count'>
                              {localeString(
                                this.props.report[browser + 'MOBILE']
                                  .segmentifyCount +
                                  this.props.report[browser + 'TABLET']
                                    .segmentifyCount,
                              )}
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
              <div className='container-apps'>
                {deviceList.map(device => {
                  if (device === 'ANDROID' || device === 'IOS') {
                    let className =
                      'permission-browser ' +
                      device.toLowerCase() +
                      ' permission-app-Mobile';
                    return (
                      <Tooltip
                        key={device}
                        content={t(device)}
                        newTooltip={true}
                        alignment='left'
                        isSpan={true}
                        isInline={true}
                      >
                        <div
                          className={className}
                          style={{ marginRight: '15px' }}
                        >
                          <div>
                            <div className='permission-browser-count'>
                              {localeString(
                                this.props.report[device + 'APPS']
                                  .segmentifyCount,
                              )}
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>,
    ];
  }
}

export default PushPermissionReport;
