import React, { Component } from 'react';

import { uiActions } from '../actions';
import { dataCenterV3, timeZones } from '../components/admin/constants';
import { Select, TextField } from '../components/fields';
import { createNewAccount, getSalesManagers } from '../modules/account/ajax';
import { setTitle } from '../system/document';
import { t } from '../system/ui';

class AddCreateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modules: [],
      selectedModules: [],
      dataCenter: '',
      migrate: false,
      displayName: '',
      username: '',
      password: '',
      webSite: '',
      timezone: '',
      salesManager: '',
      salesManagers: [],
    };

    this.onDataCenterChange = this.onDataCenterChange.bind(this);
    this.onSalesManagerChange = this.onSalesManagerChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleMigrate = this.toggleMigrate.bind(this);
  }

  componentDidMount() {
    this.setSalesManagers();
    setTitle('Create Account');
    uiActions.resetPage();
    uiActions.addPageClass('sidebar-is-hidden');
    uiActions.isValidated();
  }

  onDataCenterChange(selectedOption) {
    this.setState({
      dataCenter: selectedOption.value,
    });
  }

  setSalesManagers() {
    getSalesManagers(salesManagers => {
      if (salesManagers.length) {
        const _salesManagers = [];
        salesManagers.map(item => {
          _salesManagers.push({
            value: item.username,
            label: item.username,
          });
        });
        this.setState({
          salesManagers: _salesManagers,
        });
      }
    });
  }

  onSalesManagerChange(selectedOption) {
    this.setState({
      salesManager: selectedOption.value,
    });
  }

  toggleMigrate() {
    this.setState({
      migrate: !this.state.migrate,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const query = {};

    if (!this.state.timezone) {
      return;
    }

    if (!this.state.dataCenter) {
      return;
    }

    query.account = {
      domain: '',
      provider: 'INTERNAL',
      dataCenter: '',
      timeZone: '',
      ownership: {
        salesManager: '',
      },
    };
    const fields = ['displayName', 'username', 'password', 'webSite'];
    fields.forEach(field => {
      if (field === 'webSite') {
        query.account.domain = this.refs.form.querySelector(
          `[name="${field}"]`,
        ).value;
      } else {
        query[field] = this.refs.form.querySelector(`[name="${field}"]`).value;
      }
    });
    query.account.dataCenter = this.state.dataCenter;
    query.account.timeZone =
      this.refs.form.querySelectorAll('[name="timeZone"]').length !== 0
        ? this.refs.form.querySelectorAll('[name="timeZone"]')[0].value
        : '';
    query.account.ownership.salesManager = this.state.salesManager;
    uiActions.isLoading();
    createNewAccount(query.dataCenter, query, this.state.migrate, response => {
      uiActions.isLoaded();
      if (response) {
        const notificationContent = () => (
          <div>{t('You have successfully created account')}</div>
        );
        uiActions.showNotification({ content: notificationContent });
        fields.forEach(field => {
          this.refs.form.querySelector(`[name="${field}"]`).value = '';
        });
      }
    });
  }

  changeTimezone(txt) {
    this.setState({
      timezone: txt,
    });
  }

  render() {
    return (
      <form className='form-create-account' ref='form' onSubmit={this.onSubmit}>
        <ol className='form-elements'>
          <li>
            <TextField
              name='displayName'
              className='item-stacked one-whole'
              label={t("User's Display Name")}
              value={this.state.displayName}
              onChange={e => this.setState({ displayName: e.target.value })}
              required
            />
          </li>
          <li>
            <TextField
              name='username'
              className='item-stacked one-whole'
              label={t('Email')}
              value={this.state.username}
              onChange={e => this.setState({ username: e.target.value })}
              required
              validation={{ email: true }}
            />
          </li>
          <li>
            <TextField
              name='password'
              type='password'
              className='item-stacked one-whole'
              label={t('Password')}
              value={this.state.password}
              onChange={e => this.setState({ password: e.target.value })}
              required
              validation={{
                minLength: 8,
                password: true,
              }}
            />
          </li>
          <li>
            <TextField
              name='webSite'
              className='item-stacked one-whole'
              label={t('Website')}
              value={this.state.webSite}
              onChange={e => this.setState({ webSite: e.target.value })}
              required
            />
          </li>
          <li>
            <Select
              name='timeZone'
              className='item-stacked one-whole'
              label='Account Timezone'
              options={timeZones}
              onChange={this.changeTimezone.bind(this)}
              value={this.state.timezone}
              searchable
            />
            {!this.state.timezone && (
              <span className='item-error'>
                <span>This field is required</span>
              </span>
            )}
          </li>
          <li>
            <Select
              name='type'
              className='tem-stacked one-whole'
              label={t('Datacenter')}
              value={this.state.dataCenter}
              options={dataCenterV3}
              placeholder={t('Select Datacenter')}
              onChange={this.onDataCenterChange}
            />
            {!this.state.dataCenter && (
              <span className='item-error'>
                <span>This field is required</span>
              </span>
            )}
          </li>
          <li>
            <Select
              name='salesManager'
              className='tem-stacked one-whole'
              label={t('Sales Manager')}
              value={this.state.salesManager}
              options={this.state.salesManagers}
              placeholder={t('Select Sales Manager')}
              onChange={this.onSalesManagerChange}
            />
            {!this.state.salesManager && (
              <span className='item-error'>
                <span>This field is required</span>
              </span>
            )}
          </li>
          <li>
            <span className='one-column create'>
              <input
                type='checkbox'
                checked={this.state.migrate}
                onChange={this.toggleMigrate}
              />
            </span>
            <label className='item item-stacked is-checkbox ten-column'>
              If a user exists in any customer account, user will be migrating
              to current account
            </label>
          </li>
          <li className='buttons'>
            <button className='tertiary-action'>{t('Create Account')}</button>
          </li>
        </ol>
      </form>
    );
  }
}

export default AddCreateAccount;
