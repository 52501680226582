const setCustomerProfileList = dispatch => {
  return list => {
    dispatch({
      type: 'SET_CUSTOMER_PROFILE_LIST',
      payload: list,
    });
  };
};

const setCustomerProfile = dispatch => {
  return profile => {
    dispatch({
      type: 'SET_CUSTOMER_PROFILE',
      payload: profile,
    });
  };
};

const setCustomerProfileNotes = dispatch => {
  return notes => {
    dispatch({
      type: 'SET_CUSTOMER_PROFILE_NOTES',
      payload: notes,
    });
  };
};

const resetCustomerProfile = dispatch => {
  return () => {
    dispatch({
      type: 'RESET_CUSTOMER_PROFILE',
    });
  };
};

export {
  setCustomerProfileList,
  setCustomerProfile,
  setCustomerProfileNotes,
  resetCustomerProfile,
};
