import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const cpBirthDayIcon = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 14 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.00004 0.166687C6.36691 0.166687 5.94304 0.621347 5.70897 1.02168C5.46787 1.43393 5.33337 1.93703 5.33337 2.33335C5.33337 2.6628 5.37526 3.11572 5.62171 3.49725C5.89477 3.92004 6.35851 4.16669 7.00004 4.16669C7.64157 4.16669 8.10531 3.92004 8.37837 3.49725C8.62484 3.11572 8.66671 2.6628 8.66671 2.33335C8.66671 1.93703 8.53224 1.43393 8.29111 1.02168C8.05704 0.621347 7.63317 0.166687 7.00004 0.166687ZM6.33337 2.33335C6.33337 2.12718 6.41404 1.79695 6.57217 1.52649C6.73731 1.24411 6.89677 1.16669 7.00004 1.16669C7.10331 1.16669 7.26277 1.24411 7.42791 1.52649C7.58604 1.79695 7.66671 2.12718 7.66671 2.33335C7.66671 2.6064 7.62524 2.82015 7.53837 2.95466C7.47811 3.04791 7.35851 3.16669 7.00004 3.16669C6.64157 3.16669 6.52197 3.04791 6.46171 2.95466C6.37484 2.82015 6.33337 2.6064 6.33337 2.33335ZM12.6667 6.33335V11.8334H13.1667C13.4428 11.8334 13.6667 12.0572 13.6667 12.3334C13.6667 12.6095 13.4428 12.8334 13.1667 12.8334H0.833374C0.557234 12.8334 0.333374 12.6095 0.333374 12.3334C0.333374 12.0572 0.557234 11.8334 0.833374 11.8334H1.33337V6.33335C1.33337 5.50493 2.00495 4.83335 2.83337 4.83335H11.1667C11.9951 4.83335 12.6667 5.50493 12.6667 6.33335ZM2.33337 6.33335V7.72755L3.96493 8.78022C4.23998 8.95762 4.59343 8.95762 4.86848 8.78022L6.03337 8.02862C6.61917 7.65075 7.36857 7.63769 7.96711 7.99502L9.30604 8.79442C9.58811 8.96282 9.94291 8.94975 10.2118 8.76102L11.6667 7.74009V6.33335C11.6667 6.05721 11.4428 5.83335 11.1667 5.83335H2.83337C2.55723 5.83335 2.33337 6.05721 2.33337 6.33335ZM10.7863 9.57955C10.1946 9.99482 9.41411 10.0236 8.79337 9.65302L7.45451 8.85369C7.18244 8.69122 6.84177 8.69715 6.57551 8.86895L5.41061 9.62049C4.80551 10.0109 4.02791 10.0109 3.42281 9.62049L2.33337 8.91762V11.8334H11.6667V8.96175L10.7863 9.57955Z'
        fill={color}
      />
    </svg>
  );
};

cpBirthDayIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

cpBirthDayIcon.defaultProps = {
  width: '14px',
  height: '13px',
  color: '#979797',
};

export default cpBirthDayIcon;
