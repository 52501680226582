import React, { Component } from 'react';
import classNames from 'classnames';
import { clone } from 'system/object';
import find from 'lodash/find';
import { TextField } from '../fields';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { t } from '../../system/ui';
import Tooltip from '../tooltip';
import Icon from '../icon';
import { SFYCheckBox } from '../sfy-components/module-exports';

class SearchLanguageBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      additionalTitles:
        Object.keys(this.props.additionalTitles).length !== 0
          ? this.props.additionalTitles
          : '',
      clearButtonEnabled:
        this.props.assetItems[0]?.values?.clearButtonEnabled || false,
      clearButtonText: this.props.assetItems[0]?.values?.clearButtonText || '',
    };

    this.langList = this.generateLangList(this.props);
    this.debounced = debounce(() => {
      this.props.onLanguageChange(this.state.additionalTitles);
    }, 250);
    this.debounced = this.debounced.bind(this);
    this.assetChange = this.assetChange.bind(this);
    this.clearStateChange = this.clearStateChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const nextClearButtonEnabled = nextProps.assetItems[0]?.values?.clearButtonEnabled;
    const nextClearButtonText = nextProps.assetItems[0]?.values?.clearButtonText;
    const currentClearButtonEnabled = this.state.clearButtonEnabled;
    const currentClearButtonText = this.state.clearButtonText;

    if (!isEqual(nextProps.additionalTitles, this.props.additionalTitles)) {
      this.setState({
        additionalTitles:
          Object.keys(nextProps.additionalTitles).length !== 0
            ? nextProps.additionalTitles
            : '',
      });
    }

    if (
      !isEqual(nextClearButtonEnabled, currentClearButtonEnabled) ||
      !isEqual(nextClearButtonText, currentClearButtonText)
    ) {
      this.setState({
        clearButtonEnabled: nextClearButtonEnabled,
        clearButtonText: nextClearButtonText,
      });
    }
  }

  generateLangList(props) {
    const { account, wizardLanguage } = props;
    let langList;
    if (
      account.hasOwnProperty('additionalLanguages') &&
      account.additionalLanguages.indexOf(account.mainLanguage) > -1
    ) {
      langList = account.additionalLanguages;
    } else {
      langList = [account.mainLanguage].concat(account.additionalLanguages);
    }

    langList = langList.map(lang => {
      const isDisabled =
        wizardLanguage != 'SESSION_BASED' && wizardLanguage !== lang;
      return { value: lang, label: lang, isDisabled };
    });

    const availableItems = langList.filter(item => !item.isDisabled);
    const selectedItem = find(
      availableItems,
      item => item.label === this.state.selectedLanguageTab,
    );

    if (!selectedItem) {
      this.selectLanguage(availableItems[0].label, true);
    }
    langList.push({ value: 'SESSION_BASED', label: 'Session based' });

    return langList;
  }

  onDataChange(evt) {
    this.setState({
      additionalTitles: {
        ...this.state.additionalTitles,
        [this.state.selectedLanguageTab]: evt.target.value,
      },
      clearButtonEnabled: this.props.assetItems[0]?.values?.clearButtonEnabled || false,
    });

    if (this.props.onLanguageChange) {
      this.debounced();
    }
  }

  getTextValue() {
    let value = '';
    if (
      this.state.additionalTitles.hasOwnProperty(this.state.selectedLanguageTab)
    ) {
      value = this.state.additionalTitles[this.state.selectedLanguageTab];
    }
    return value;
  }

  hasError(lang) {
    const { wizardLanguage } = this.props;
    if (wizardLanguage !== 'SESSION_BASED') {
      return false;
    }

    const language = this.state.additionalTitles[lang];
    const langErr = !language || language === '';

    if (langErr) {
      return true;
    }

    return false;
  }

  selectLanguage(label) {
    this.setState({
      selectedLanguageTab: label,
    });
  }

  assetChange = () => {
    const assetItems = clone(this.props.assetItems);

    if (assetItems.length === 0) {
      assetItems.push({
        id: Math.random(),
        type: 'intelligent',
        values: {
          criterion: {
            value: 'LAST_SEARCHES',
          },
          itemCount: {
            value: 1,
          },
          clearQueryBasedEnabled: false,
          clearButtonEnabled: this.state.clearButtonEnabled,
          clearButtonText: this.state.clearButtonText,
        },
      });
    } else {
      assetItems[0].values.clearButtonEnabled = this.state.clearButtonEnabled;
      assetItems[0].values.clearButtonText = this.state.clearButtonText;
    }

    this.props.handleAsset(assetItems);
  };

  clearStateChange = (type, val) => {
    this.setState({ [type]: val }, () => {
      this.assetChange();
    });
  };

  render() {
    this.langList = this.generateLangList(this.props);

    return (
      <>
        <div className='search__language-box'>
          <span>
            {t('Asset Text')}{' '}
            <Tooltip
              content='Prepare the title for you search asset'
              alignment='top'
              newTooltip={true}
              isInline={true}
            >
              <Icon name='info' />
            </Tooltip>{' '}
          </span>
          {this.langList.length > 2 && (
            <span className={'campaign-language-selection-extended'}>
              {this.langList.map((item, index) => {
                if (item.label !== 'Session based') {
                  return (
                    <span
                      key={index}
                      onClick={this.selectLanguage.bind(this, item.label)}
                      className={classNames('language-box', {
                        'is-selected':
                          this.state.selectedLanguageTab === item.label ||
                          (index === 0 &&
                            this.state.language === 'SESSION_BASED' &&
                            this.state.selectedLanguageTab === ''),
                        'has-error': this.hasError(item.label),
                      })}
                    >
                      {item.label}
                    </span>
                  );
                }
              })}
            </span>
          )}

          <TextField
            name={'title'}
            className='item-stacked one-whole'
            value={this.getTextValue() || ''}
            required={true}
            tooltip={true}
            onChange={this.onDataChange.bind(this)}
            ref='title'
            validation={{ minLength: 1 }}
          />
        </div>
        {this.props?.type === 'LAST_SEARCHES' && (
          <div>
            <SFYCheckBox
              label='Clear Button'
              checked={this.state.clearButtonEnabled}
              searchActiveSetter={val =>
                this.clearStateChange('clearButtonEnabled', val)
              }
            />
            {this.state.clearButtonEnabled && (
              <TextField
                name='clearButtonText'
                placeholder='Clear Button Text'
                className='item-stacked one-whole'
                value={this.state.clearButtonText}
                required={true}
                onChange={e =>
                  this.clearStateChange('clearButtonText', e.target.value)
                }
                ref='clearButtonText'
                validation={{ minLength: 1 }}
                maxLength={20}
              />
            )}
          </div>
        )}
      </>
    );
  }
}

export default SearchLanguageBox;
