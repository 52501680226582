import React from 'react';
import { connect } from 'react-redux';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { queryDate } from '../system/date';

import Icon from './icon';
import { t } from '../system/ui';

import { getActiveUsers } from '../modules/push/ajax';

class PushCount extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.state = {
      count: 0,
    };
  }

  componentDidMount() {
    this.update(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.range !== this.props.range)
      if (nextProps.range[0] !== null && nextProps.range[1] !== null)
        this.update(nextProps);
  }

  update(props) {
    const boundaries = {
      start: queryDate(props.range[0]),
      end: queryDate(props.range[1]),
    };

    getActiveUsers(response => {
      this.setState({
        count: parseInt(response),
      });
    }, boundaries);
  }

  render() {
    return (
      <div className='section-push-count'>
        <span>
          {t('Total Subscribers')}: <strong>{this.state.count}</strong>
        </span>
        <span className='stat-tooltip' data-tooltip-id='push-tooltip'>
          <Icon name='info' />
        </span>
        <ReactTooltip
          effect='solid'
          id='push-tooltip'
          className='sg-tooltip'
          content='Total number of subscribers that Segmentify can actually send pushes. This is the most up-to-date number.'
        />
      </div>
    );
  }
}

const mapStateToProps = store => ({
  range: store.date.range,
});

export default connect(mapStateToProps)(PushCount);
