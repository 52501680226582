/**
 * Created by mehmetyurtar on 03/08/2017.
 */
'use strict';

import React from 'react';
import { connect } from 'react-redux';

import { t } from '../system/ui';
import { setTitle } from '../system/document';

import { uiActions, filterActions } from '../actions';
import { getPersonalizationReport } from '../modules/reports/ajax';

import PersonalizationWidgetReport from '../components/widget/personalizationreport';
import StatusFilters from '../components/filters/status-filters';
import { transFormPersReport } from '../modules/transform';

class AllPersonalizationReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      _campaigns: [],
      campaignsNotRequested: false,
    };

    this.changeStatus = this.changeStatus.bind(this);
    this.changeMedium = this.changeMedium.bind(this);
    this.getReport = this.getReport.bind(this);
  }

  componentDidMount() {
    setTitle(t('View All Reports'));
    this.getReport(this.props.range, this.props.status);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.status !== this.props.status) {
      this.changeStatus(newProps.range, newProps.status);
      this.changeMedium(newProps.medium);
    }

    if (newProps.medium !== this.props.medium) {
      this.changeMedium(newProps.medium);
    }

    if (newProps.update === true) {
      this.getReport(newProps.range, newProps.status);
    }

    if (newProps.user !== this.props.user) {
      this.getReport(newProps.range, newProps.status);
    }

    if (newProps.reset === true) {
      this.getReport(newProps.range, newProps.status);
    }
  }

  getReport(boundaries, status) {
    if (boundaries[0] && boundaries[1]) {
      uiActions.isLoading();
      let timeRange = {};
      timeRange.start = boundaries[0].format('YYYY-MM-DD');
      timeRange.end = boundaries[1].format('YYYY-MM-DD');
      getPersonalizationReport(
        this.props.instanceId,
        timeRange,
        'ALL',
        campaignReport => {
          let campaigns = campaignReport.filter(function(campaign) {
            status = status === 'PASSIVE' ? 'ARCHIVE' : status;
            return campaign.campaign.status === status;
          });
          this.setState({
            campaigns: campaigns,
            _campaigns: campaigns,
            campaignsNotRequested: false,
          });
          filterActions.filtersUpdated();
          filterActions.filtersReset();
          uiActions.isLoaded();
        },
      );
    }
  }

  changeStatus(range, status) {
    this.getReport(range, status);
  }

  changeMedium(value) {
    let visibleCampaigns = this.state._campaigns,
      filteredMediums = [],
      search = [];

    if (!value.length) {
      search = ['ALL'];
    }

    search = search.concat(value);

    if (search.indexOf('PCTABLET') > -1) {
      search = search.concat(['PC', 'TABLET']);
    }

    if (search.indexOf('ANDROID') > -1 || search.indexOf('IOS') > -1) {
      search = search.concat(['ANDROIDIOS']);
    }

    visibleCampaigns.forEach(item => {
      item.campaign.devices.forEach(deviceItem => {
        if (search.indexOf('ALL') > -1) {
          filteredMediums.push(item);
        } else {
          if (search.indexOf(deviceItem) > -1) {
            filteredMediums.push(item);
          }
        }
      });
    });

    this.setState({
      campaigns: filteredMediums,
      campaignsNotRequested: false,
    });
  }

  render() {
    let campaignsStatus = this.props.status,
      statusText = '';
    if (campaignsStatus === 'ACTIVE') {
      statusText = 'Live';
    } else if (campaignsStatus === 'TEST') {
      statusText = 'Test';
    } else {
      statusText = 'Archived';
    }
    return (
      <div>
        <div className='page-filters has-filter-form'>
          <StatusFilters isEngagement={true} />
        </div>
        <h2 className='page-title'>{t('All Campaigns')}</h2>
        {this.state.campaigns.length > 0 ? (
          <div>
            {this.state.campaigns.map(campaign => {
              return (
                <PersonalizationWidgetReport
                  key={Math.random() + campaign.campaign.instanceId}
                  campaign={campaign.campaign}
                  logs={transFormPersReport(campaign.logs)}
                />
              );
            })}
          </div>
        ) : (
          this.props.ui !== 'isLoading' && (
            <div className='no-report'>
              <p className='no-report-info'>
                {t('no-personalization-report-info', statusText)}
              </p>
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  update: store.filters.update,
  reset: store.filters.reset,
  status: store.campaigns.status,
  medium: store.campaigns.medium,
});

export default connect(mapStatesToProps)(AllPersonalizationReport);
