import { store } from '../store';

export function createConstants(...constants) {
  return constants.reduce(
    (acc, constant) => ({
      ...acc,
      [constant]: constant,
    }),
    {},
  );
}

export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];

    return reducer ? reducer(state, action.payload) : state;
  };
}

export const createAction = (actionType, actionData) => {
  return store.dispatch({
    type: actionType,
    payload: actionData,
  });
};

export const getState = () => {
  return store.getState();
};
