import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { campaignActionButtonConstant } from '../constants/constants';
import styles from './EmailOverviewModal.module.scss';
import { t } from '../../../system/ui';
import { intelligentOptionsEmail } from '../../../constants/datamaps/wizard';
import { Context as EmailContext } from '../store/EmailProvider';
import { spamCheckEmailCampaign } from '../../../modules/emails/ajax';
import { uiActions } from '../../../actions';

const InfoRow = ({ row }) => {
  if (!row.shouldShow) return null;

  return (
    <div className={styles['modal-overview-body-row']}>
      <div className={styles['modal-overview-body-title']}>{row.fieldName}</div>
      {Array.isArray(row.fieldValue) &&
        row.fieldValue.map((item, index) => (
          <div key={index} className={styles['modal-overview-body-text']}>
            {item}
          </div>
        ))}
      {!Array.isArray(row.fieldValue) && (
        <div className={styles['modal-overview-body-text']}>
          {row.fieldValue}
        </div>
      )}
    </div>
  );
};

const EmailOverviewModal = ({ saveHandler, mode }) => {
  const userRole = useSelector(
    ({ switchedUser, user }) =>
      switchedUser?.switchedUser?.userRole || user?.user?.userRole,
  );
  const [recommendations, setRecommendations] = useState([]);
  const [totalFilters, setTotalFilters] = useState(0);
  const {
    state: { activeEmailDetails },
  } = useContext(EmailContext);

  const { isLoaded, isLoading } = uiActions;

  useEffect(() => {
    const recommendationList = activeEmailDetails?.additionalRecommendations?.map(
      item => intelligentOptionsEmail[item.source],
    );
    const defaultRecommendation = activeEmailDetails?.defaultRecommendation?.map(
      item =>
        `${intelligentOptionsEmail[item.source]} (${totalFilters} filters)`,
    );
    setTotalFilters(
      activeEmailDetails?.inclusions?.length +
        activeEmailDetails?.exclusions?.length,
    );
    setRecommendations([...recommendationList, ...defaultRecommendation]);
  }, [activeEmailDetails.additionalRecommendations.length, totalFilters]);

  const rowValues = {
    campaignName: {
      fieldName: 'Campaign Name',
      fieldValue: decodeURIComponent(activeEmailDetails?.scenarioName),
      shouldShow: true,
    },
    mailSubject: {
      fieldName: 'Mail Subject',
      fieldValue: activeEmailDetails?.params?.subject,
      shouldShow: true,
    },
    recommendation: {
      fieldName: 'Recommendation',
      fieldValue: recommendations,
      shouldShow: recommendations.length > 0,
    },
    time: {
      fieldName: 'Time',
      fieldValue: activeEmailDetails?.params?.workingHour,
      shouldShow: !!activeEmailDetails?.params?.workingHour,
    },
    triggeredTime: {
      fieldName: 'Triggered Time',
      fieldValue: `${activeEmailDetails?.params?.delayInDays} Days`,
      shouldShow: !!activeEmailDetails?.params?.delayInDays,
    },
  };

  const spamCheck = () => {
    isLoading();
    spamCheckEmailCampaign(activeEmailDetails, response => {
      window.open(response, '_blank', 'noreferrer');
      isLoaded();
    });
  };

  return (
    <div className={styles['modal-wrapper']}>
      <div className={styles['modal-overview-body']}>
        <div className={styles['modal-overview-body-info']}>
          <InfoRow row={rowValues.campaignName} />
          <InfoRow row={rowValues.mailSubject} />
          <InfoRow row={rowValues.recommendation} />
          <div className={styles['modal-overview-body-col']}>
            <InfoRow row={rowValues.time} />
            <InfoRow row={rowValues.triggeredTime} />
          </div>
        </div>
      </div>
      <div className={styles['modal-bottom-area']}>
        {/* <div className={styles['modal-bottom-left-panel']}>
          <div
            className={`${styles['modal-button']} ${styles['left-panel-buttons']}`}
          >
            <button
              className={`${styles.test} ${styles['action-btn']}`}
              type='button'
              onClick={() => spamCheck()}
            >
              <i
                className={`icon-other-sources ${styles['rotate-180']} ${styles['icon-left']}`}
                role='presentation'
              >
                <span className='for-screenreader-only'>
                  icon-other-sources
                </span>
              </i>
              {t('Spam Check')}
            </button>
          </div>
        </div> */}
        <div className={styles['modal-bottom-right-panel']}>
          {campaignActionButtonConstant.map(item => (
            <div
              key={item.key}
              className={`${styles['modal-button']} ${styles['right-panel-buttons']}`}
            >
              {((userRole !== 'READ_ONLY' &&
                mode !== 'preview' &&
                item.type === 'advance-config') ||
                (userRole === 'ADMIN' &&
                  item.type === 'live' &&
                  mode !== 'preview')) && (
                <button
                  className={`${styles[item.type]} ${styles['action-btn']}`}
                  type='button'
                  onClick={() =>
                    saveHandler(item.type === 'live' ? 'ACTIVE' : 'TEST')
                  }
                >
                  {item.icon && (
                    <i
                      className={`${item.icon} ${styles[item.rotate]} ${
                        styles['icon-left']
                      }`}
                      role='presentation'
                    >
                      <span className='for-screenreader-only'>{item.icon}</span>
                    </i>
                  )}
                  {t(item.btnLabel)}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmailOverviewModal;
