import React from 'react';
import './ExperimentationReportUpperTable.scss';
import ExperimentationVariationLabel from '../common/ExperimentationVariationLabel';
import Icons from '../../icons';
import classnames from 'classnames';
import ReactHighstock from 'react-highcharts/ReactHighstock';
import StockTools from 'highcharts/modules/stock-tools';
import { fixPrecision } from './ExperimentationReport';

export default class ExperimentationReportUpperTable extends React.Component {
  constructor(props) {
    super(props);
  }

  getCandleStickConfig = (instanceId, index) => {
    const { experiment, report } = this.props;
    const original = experiment?.variations?.find(variation => variation.variationName === 'Original');
    const data = [];
    const orcfList = report?.[original?.instanceId]?.confidenceIntervalList;
    const orNinetyFive = orcfList?.find(cf => cf.confidenceLevel === 95);
    const orFifty = orcfList?.find(cf => cf.confidenceLevel === 50);
    if (orNinetyFive && orFifty) {
      data.push({
        x: 0,
        open: parseFloat(fixPrecision((orFifty.upperBound ?? 0) * 100)),
        high: parseFloat(fixPrecision((orNinetyFive.upperBound ?? 0) * 100)),
        low: parseFloat(fixPrecision((orNinetyFive.lowerBound ?? 0) * 100)),
        close: parseFloat(fixPrecision((orFifty.lowerBound ?? 0) * 100)),
        name: "Point2",
        color: '#47607B',
      });
    }
    if (original?.instanceId !== instanceId) {
      const cfList = report?.[instanceId]?.confidenceIntervalList;
      const ninetyFive = cfList?.find(cf => cf.confidenceLevel === 95);
      const fifty = cfList?.find(cf => cf.confidenceLevel === 50);
      if (ninetyFive && fifty) {
        data.push({
          x: 1,
          open: parseFloat(fixPrecision((fifty.upperBound ?? 0) * 100)),
          high: parseFloat(fixPrecision((ninetyFive.upperBound ?? 0) * 100)),
          low: parseFloat(fixPrecision((ninetyFive.lowerBound ?? 0) * 100)),
          close: parseFloat(fixPrecision((fifty.lowerBound ?? 0) * 100)),
          name: "Point1",
          color: '#69A3FE',
        });
      }
    }
    return {
      plotOptions: {
        series: { enableMouseTracking: false },
      },
      chart: {
        height: 100,
        width: 50,
        backgroundColor: index % 2 === 0 ? '#F9F9FF' : '#FFFFFF',
      },
      rangeSelector: {
        enabled: false,
      },

      navigator: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },

      xAxis: {
        visible: false,
        scrollbar: {
          enabled: false,
        },
      },

      yAxis: {
        visible: false,
        scrollbar: {
          enabled: false,
        },
      },

      title: {
        text: '',
      },

      series: [{
        type: 'candlestick',
        name: 'Confidence Intervals',
        data: data,
      }]
    };
  };

  getHeader() {
    return (
      <thead>
        <tr>
          <td>&nbsp;</td>
          <th scope='col'>
            <div className='header-wrapper'>Variations</div>
          </th>
          <th scope='col'>
            <div className='header-wrapper'>Test Audience</div>
          </th>
          <th scope='col'>
            <div className='header-wrapper'>Goal Completions</div>
          </th>
          <th scope='col'>
            <div className='header-wrapper'>Conversion Rate</div>
          </th>
          <th scope='col'>
            <div className='header-wrapper'>Win Probability</div>
          </th>
          <th scope='col'>
            <div className='header-wrapper'>Modelled Conversion Rate</div>
          </th>
          <th scope='col'>
            <div className='header-wrapper'>Improvement</div>
          </th>
        </tr>
      </thead>
    );
  }

  getBody() {
    const { experiment, report } = this.props;
    return (
      <tbody>
        {experiment?.variations?.map(({ variationName, instanceId, testSplit }, index) => {
            const variationReport = report?.[instanceId];
            let trophy = false;
            if (variationReport?.winProbability) {
              trophy = variationReport.winProbability * 100 >= 95
            }
            const improvement = variationReport?.improvement ?? 0;
            return (
              <tr>
                <td
                  className={classnames({
                    'base-indicator': variationName === 'Original',
                    trophy,
                  })}
                >
                  {trophy ? (
                    <Icons name='trophy' color='#FFFFFF' />
                  ) : (variationName === 'Original' ? (
                    'base'
                  ) : null)}
                  
                </td>
                <td>
                  <ExperimentationVariationLabel
                    variationName={variationName}
                    color={'#F97600'}
                    testSplit={testSplit}
                  />
                </td>
                <td>{variationReport?.testAudience ?? 0}</td>
                <td>{variationReport?.goalCompletions ?? 0}</td>
                <td>{fixPrecision((variationReport?.conversionRate ?? 0) * 100)}%</td>
                <td>{fixPrecision((variationReport?.winProbability ?? 0) * 100)}%</td>
                <td className='candlestick'>
                  <span className='candlestick-container'>
                    <ReactHighstock config={this.getCandleStickConfig(instanceId, index)} />
                  </span>
                </td>
                <td>
                  {variationName === 'Original'
                    ? 'Baseline'
                    : (
                      <span>
                        {Math.abs(improvement)}
                        {improvement !== 0 && (
                            <span
                              style={{
                                transform: `rotate(${improvement > 0 ? '' : '-'}90deg)`,
                                display: 'inline-block',
                              }}
                            >
                              <Icons
                                name='backarrow'
                                color={improvement > 0 ? '#1B9645' : '#CC4230'}
                                width={10}
                                height={10}
                              />
                            </span>
                          )}
                        </span>
                      )
                    }
                </td>
              </tr>
            );
        })}
      </tbody>
    );
  }

  render() {
    return (
      <div style={{ position: 'relative', width: '100%', overflowX: 'scroll', overflowY: 'hidden' }}>
        <div style={{ width: '100%', maxWidth: '100%' }}>
          <table className='data-table upper-table'>
            {this.getHeader()}
            {this.getBody()}
          </table>
        </div>
      </div>

    );
  }
}
