import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
  changeCriteriaType,
  changeCriteriaStatement,
  addNewCriteria,
  removeCriteria,
  removeGroup,
} from '../../reducers/rbs';
import Icons from '../icons';

import { criteriaTypes } from '../../constants/datamaps/rule-based-segmentation';

import { useHover } from './hooks/useHover';

import CriteriaFilterCategory from './criteriaFilterCategory';
import CriteriaFilterProduct from './criteriaFilterProduct';
import CriteriaFilterBrand from './criteriaFilterBrand';
import CriteriaFilterTotalRevenue from './criteriaFilterTotalRevenue';
import CriteriaFilterTransactionValue from './criteriaFilterTransactionValue';
import CriteriaFilterQuantity from './criteriaFilterQuantity';
import CriteriaFilterAmount from './criteriaFilterAmount';
import CriteriaFilterDate from './criteriaFilterDate';
import CriteriaFilterPage from './criteriaFilterPage';
import CriteriaFilterUrl from './criteriaFilterUrl';
import CriteriaFilterProductViewCount from './criteriaFilterProductViewCount';
import CriteriaFilterPageViewCount from './criteriaFilterPageViewCount';
import CriteriaFilterAge from './criteriaFilterAge';
import CriteriaFilterGender from './criteriaFilterGender';
import CriteriaFilterBrowser from './criteriaFilterBrowser';
import CriteriaFilterDevice from './criteriaFilterDevice';
import CriteriaFilterOperatingSystem from './criteriaFilterOperatingSystem';
import CriteriaFilterEmail from './criteriaFilterEmail';
import CriteriaFilterPush from './criteriaFilterPush';
import CriteriaFilterCustomSegment from './criteriaFilterCustomSegment';

const getCriteriaSelectOptions = criteriaTypeItems => {
  const returnCriteriaOptions = [];

  Object.keys(criteriaTypeItems).forEach(key => {
    const thisCriteriaType = criteriaTypeItems[key];

    if (thisCriteriaType?.type === 'title') {
      // If this criteria is in the group
      const subCriteriaTypes = Object.keys(thisCriteriaType?.criteriaList).map(
        subKey => {
          const subCriteriaType = thisCriteriaType?.criteriaList[subKey];
          return {
            value: subCriteriaType?.id,
            label: subCriteriaType?.name,
            disabled: !subCriteriaType?.isActive,
            criteriaFilters: subCriteriaType?.criteriaFilters,
            dataType: subCriteriaType?.dataType,
            parent: key,
          };
        },
      );

      returnCriteriaOptions.push(...subCriteriaTypes);
    } else {
      // If this criteria is NOT in the group
      returnCriteriaOptions.push({
        value: thisCriteriaType?.id,
        label: thisCriteriaType?.name,
        disabled: !thisCriteriaType?.isActive,
        criteriaFilters: thisCriteriaType?.criteriaFilters,
        dataType: thisCriteriaType?.dataType,
        parent: '',
      });
    }
  });

  return returnCriteriaOptions;
};

/* 
  This is the criteria field component.
  It is used to select the criteria type and the criteria value.
  criteriaType: the criteria type selected - String
  statements: the criteria statements - Array
  position: the position of the criteria in the criteria list - Array
*/
const criteriaField = ({
  criteriaType,
  statements,
  position,
  isLast,
  isAloneCriteria,
  isAloneGroup,
}) => {
  const dispatch = useDispatch();
  const [hoverRef, isHovered] = useHover();

  // The criteria type select options and value
  const criteriaOptions = getCriteriaSelectOptions(criteriaTypes);
  const criteriaData = criteriaOptions.find(
    criteria => criteria.value === criteriaType,
  );

  const onChange = selectedValue => {
    dispatch(
      changeCriteriaType({ position, criteriaType: selectedValue.value }),
    );
  };

  const callbackFunc = (filterType, value) => {
    const tempStatements = { ...statements };
    tempStatements[filterType] = value;
    dispatch(changeCriteriaStatement({ position, statements: tempStatements }));
  };

  const addCriteriaAction = () => {
    dispatch(addNewCriteria({ position }));
  };

  const removeCriteriaAction = () => {
    if (isAloneCriteria && !isAloneGroup) {
      dispatch(removeGroup({ position }));
    } else {
      dispatch(removeCriteria({ position }));
    }
  };

  return (
    <div className={`rbs-criteria-field ${isHovered ? 'hovered' : ''}`}>
      <h5 className='rbs-criteria-title' style={{ marginBottom: '10px' }}>
        Criteria
      </h5>
      <Select
        className='select-criteria-type'
        options={criteriaOptions}
        value={criteriaType}
        clearable={false}
        placeholder='Select a criteria'
        onChange={e => onChange(e)}
      />
      {criteriaType && (
        <div
          className='delete-criteria-button'
          ref={hoverRef}
          onClick={() => removeCriteriaAction()}
        >
          <span>Remove Criteria</span>
          <div className='icon-delete'>
            <Icons name='trashBin' color='#CC4230' width={12} height={12} />
          </div>
        </div>
      )}

      {criteriaType && Object.keys(statements).length > 0 && (
        <div className='rbs-criteria-filters'>
          {Object.keys(statements).map(filterType => {
            const filterProps = {
              criteriaData,
              filterType,
              statements,
              callbackFunc,
            };

            switch (filterType) {
              case 'categoryOperator':
                return <CriteriaFilterCategory {...filterProps} />;
              case 'productOperator':
                return <CriteriaFilterProduct {...filterProps} />;
              case 'brandOperator':
                return <CriteriaFilterBrand {...filterProps} />;
              case 'totalRevenueOperator':
                return <CriteriaFilterTotalRevenue {...filterProps} />;
              case 'transactionOperator':
                return <CriteriaFilterTransactionValue {...filterProps} />;
              case 'quantityOperator':
                return <CriteriaFilterQuantity {...filterProps} />;
              case 'amountOperator':
                return <CriteriaFilterAmount {...filterProps} />;
              case 'dateOperator':
                return <CriteriaFilterDate {...filterProps} />;
              case 'pageOperator':
                return <CriteriaFilterPage {...filterProps} />;
              case 'urlOperator':
                return <CriteriaFilterUrl {...filterProps} />;
              case 'productViewCountOperator':
                return <CriteriaFilterProductViewCount {...filterProps} />;
              case 'pageViewCountOperator':
                return <CriteriaFilterPageViewCount {...filterProps} />;
              case 'ageOperator':
                return <CriteriaFilterAge {...filterProps} />;
              case 'genderOperator':
                return <CriteriaFilterGender {...filterProps} />;
              case 'browserOperator':
                return <CriteriaFilterBrowser {...filterProps} />;
              case 'deviceOperator':
                return <CriteriaFilterDevice {...filterProps} />;
              case 'operatingSystemOperator':
                return <CriteriaFilterOperatingSystem {...filterProps} />;
              case 'emailSubscription':
                return <CriteriaFilterEmail {...filterProps} />;
              case 'pushSubscriptionOperator':
                return <CriteriaFilterPush {...filterProps} />;
              case 'customSegmentOperator':
                return <CriteriaFilterCustomSegment {...filterProps} />;
              default:
                return null;
            }
          })}
        </div>
      )}

      {isLast && criteriaType && (
        <div className='rbs-criteria-add-button-field'>
          <div
            className='rbs-criteria-add-button'
            onClick={() => addCriteriaAction()}
          >
            <span>+ Add Criteria</span>
          </div>
        </div>
      )}
    </div>
  );
};

criteriaField.propTypes = {
  criteriaType: PropTypes.string,
  statements: PropTypes.shape({}),
  position: PropTypes.shape([]),
  isLast: PropTypes.bool.isRequired,
  isAloneCriteria: PropTypes.bool.isRequired,
  isAloneGroup: PropTypes.bool.isRequired,
};

criteriaField.defaultProps = {
  criteriaType: '',
  statements: {},
  position: [0, 0],
};

export default criteriaField;
