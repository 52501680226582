"use strict";

import React from "react";
import {
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";

import { uiActions } from "../../../actions";
import JourneyBuilderStatic from "./jb-static-criteria";
import JourneyBuilderIntelligent from "./jb-intelligent-criteria";
import {searchMultipleProducts} from "../../../modules/product/ajax";

const criteriaTypes = {
  category: JourneyBuilderStatic,
  brand: JourneyBuilderStatic,
  recommend: JourneyBuilderStatic,
  intelligent: JourneyBuilderIntelligent,
};

class DiscoveryCriteria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allItems: [],
      staticProducts: []
    };
    this.handleCriteriaRemove = this.handleCriteriaRemove.bind(this);
    this.setStaticProducts = this.setStaticProducts.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onSortMove = this.onSortMove.bind(this);
    this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
  }

  componentDidMount() {
    this.setStaticProducts(this.props);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.assetList !== newProps.assetList) {
      this.setStaticProducts(newProps);
    }
  }

  handleCriteriaRemove(criteriaId) {
    this.props.onCriteriaRemove(criteriaId);
    uiActions.formEdited();
  }

  handleCriteriaChange(fields, criteriaId, selectedCategory) {
    this.props.onCriteriaChange(fields, criteriaId, selectedCategory);
    uiActions.formEdited();
  }

  setStaticProducts(newProps) {
    let staticItems = [];
    let newItems = [];
    newProps.assetList.forEach((item) => {
      newItems.push(item);
      if (item.type == "recommend" && item.values.criterion) {
        staticItems.push(item.values.criterion.value);
      }
    });
    if (staticItems.length) {
      searchMultipleProducts(staticItems, (response) => {
        this.setState(
            {
              staticProducts: response,
              allItems: newItems,
            }
        );
      });
    }else {
      this.setState({ allItems: newItems });
    }
  }

  onSortEnd(options) {
    let disabledEl = document.getElementsByClassName("draggable--disabled")[0];
    if (disabledEl && disabledEl.classList) {
      disabledEl.classList.remove("draggable--disabled");
    }
    let current = Object.assign([], this.props.assetList);

    let oldIndex =options.oldIndex ;
    let newIndex =options.newIndex ;
    let element = current[oldIndex];
    current.splice(oldIndex, 1);
    current.splice(newIndex, 0, element);

    this.props.onHandleCriteria(current);
  }

  onSortMove(options, event) {
    let disabledEl = options.node.parentNode;
    if (disabledEl.classList.contains("criteria-list--static")) {
      disabledEl = document.getElementsByClassName("criteria-list--dynamic")[0];
    } else {
      disabledEl = document.getElementsByClassName("criteria-list--static")[0];
    }
    if (disabledEl && disabledEl.classList) {
      disabledEl.classList.add("draggable--disabled");
    }
  }

  render() {
    let allItems = [];
    this.state.allItems.map((item) => {
      allItems.push(item);
    });

    const SortableItem = SortableElement(({ item }) => {
      let Component = criteriaTypes[item.type];

      return (
        <div className="wizard-item-draggable wizard-item-cards-draggable">
          <Component
            key={item.id}
            mainType={this.props.type}
            staticProducts={this.state.staticProducts}
            onCriteriaRemove={this.handleCriteriaRemove}
            onCriteriaChange={this.handleCriteriaChange}
            {...item}
          />
        </div>
      );
    });

    const SortableList = SortableContainer(({ items }) => {
      let itemClass = "criteria-list ";
      if (items[0]) {
        itemClass += "criteria-list--dynamic";
      }
      return (
        <ol className={itemClass}>
          {items.map((value, index) => (
            <SortableItem key={"key" + index} index={index} item={value} />
          ))}
        </ol>
      );
    });

    if (allItems.length > 0) {
      return (
        <div className="wizard-items-container">
            <SortableList
              items={allItems}
              onSortEnd={this.onSortEnd}
              useDragHandle={true}
              helperClass="dragitem"
              onSortStart={this.onSortMove}
            />
        </div>
      );
    } else {
      return "";
    }
  }
}

export default DiscoveryCriteria;
