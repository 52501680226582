export const popupRecoTransform = (keyword, keepPageChange) => {
  switch (keyword) {
    case 'IMMEDIATE':
      return 'IMMEDIATE_ACTION';
    case 'DELAY':
      return keepPageChange ? 'WAIT_ALL_PAGES' : 'WAIT_SAME_PAGE';
    case 'SCROLL':
      return 'SCROLL';
    case 'PAGELEAVE':
      return 'LEAVE_PAGE';
    case 'ALWAYS':
      return 'ALWAYS';
    case 'SESSION':
      return 'PER_SESSION';
    case 'LIFETIME':
      return 'PER_LIFETIME';
    case 'DAYS':
      return 'PER_DAYS';
    default:
      return '';
  }
};

export const defaultEmailAlgorithms = {
  ABANDONED_CART: 'RECOMMENDATION_SOURCE_ALL_PRODUCTS',
  TOP_SELLERS: 'RECOMMENDATION_STATISTICAL_TOP_SELLERS',
  WE_MISSED_YOU: 'RECOMMENDATION_SMART_OFFERS',
  CHURN: 'RECOMMENDATION_SMART_OFFERS',
  ORDER_FOLLOW_UP: 'RECOMMENDATION_COLLABORATIVE_FILTERING',
  BIRTHDAY: 'RECOMMENDATION_SMART_OFFERS',
  ANNIVERSARY: 'RECOMMENDATION_SMART_OFFERS',
  LAST_VISIT_ALTERNATIVES: 'RECOMMENDATION_BROWSING_ALTERNATIVES',
  NICE_TO_MEET_YOU: 'RECOMMENDATION_SOURCE_ALL_PRODUCTS',
  NEW_COMERS: 'RECOMMENDATION_NEW_COMERS',
  LAST_VISIT_REMINDER: 'RECOMMENDATION_LAST_VISIT_REMINDER',
  PRICE_DROP: 'RECOMMENDATION_PRICE_DROP',
  BACK_IN_STOCK: 'RECOMMENDATION_BACK_IN_STOCK',
  REPLENISHMENT: 'RECOMMENDATION_REPLENISHMENT_PRODUCTS',
};

export const findDefautAlgorithm = (criteriaArray, emailType) => {
  const defaultAlgorithm = defaultEmailAlgorithms[emailType];
  return criteriaArray.find(
    item => item.values?.criterion?.value === defaultAlgorithm,
  );
};
