/**
 * @author Bilal Cinarli
 */

'use strict';

import React from 'react';
import { connect } from 'react-redux';

import { t } from '../../system/ui';

import Icon from '../icon';
import Icons from '../icons';

import Navlink from '../navlink';

class Breadcrumb extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let className = 'breadcrumb';

    if (Object.keys(this.props.breadcrumb).length === 0) {
      className += ' is-hidden';
    }

    return (
      <nav id='breadcrumb' className={className}>
        {Object.keys(this.props.breadcrumb).map((item, index) => {
          let _item = this.props.breadcrumb[item];
          let icon = '';
          if (typeof _item.icon !== 'undefined') {
            if (_item.iconSvg) {
              icon = <Icons name={_item.icon} />;
            } else {
              icon = <Icon name={_item.icon} />;
            }
          }

          if (typeof _item.slug !== 'undefined') {
            return (
              <Navlink
                key={index}
                to={_item.slug}
                className='nav-item breadcrumb-item'
              >
                {icon}
                {t(_item.name)}
              </Navlink>
            );
          } else {
            return (
              <span key={index} className='nav-item breadcrumb-item current'>
                {t(_item.name)}
              </span>
            );
          }
        })}
      </nav>
    );
  }
}

const mapStatsToProps = store => ({
  breadcrumb: store.ui.breadcrumb,
});

export default connect(mapStatsToProps)(Breadcrumb);
