import React from 'react';
import moment from 'moment';
import Icon from 'components/icon';
import { t } from 'system/ui';
import { withRouter } from 'react-router';
import Icons from '../../icons';
import './ExperimentationList.scss';
import CampaignHistoryModal from '../../../modules/campaigns/historymodal';
import { modalActions, uiActions } from '../../../actions';
import { facetedSearchRequest } from '../../../actions/faceted-search';

class ExperimentationListItem extends React.Component {
  constructor(props) {
    super(props);
  }

  getActions = () => {
    const { experiment } = this.props;
    const actions = [
      {
        icon: 'eye',
        handler: this.previewAction,
        name: 'Preview',
        iconSvg: true,
      },
      {
        icon: 'duplicate',
        handler: this.duplicateAction,
        name: 'Duplicate',
        iconSvg: true,
      },
      {
        icon: 'history',
        handler: this.historyAction,
        name: 'History',
        iconSvg: true,
      },
      { icon: 'pie-chart', handler: this.reportAction, name: 'Report' },
    ];
    if (experiment.status === 'COMPLETED') {
      actions[0].name = 'Test Detail';
      actions.push({
        icon: 'archive',
        handler: this.archiveAction,
        name: 'Archive',
      });
    }
    return actions;
  };

  reportAction = () => {
    const { experiment, router } = this.props;
    router.push({
      pathname: `/report/experimentation/${experiment.id}`,
    });
  };

  archiveAction = () => {
    uiActions.confirmationDialog({
      title: 'Attention!',
      content: 'Are you sure you want to archive the experiment?',
      onConfirm: () => {
        const { experiment, retrieveExperimentationList } = this.props;
        facetedSearchRequest('experimentation/update', {
          instanceId: experiment.id,
        })
          .put(null, { type: 'json' })
          .then(() => retrieveExperimentationList())
          .catch(() => {
            const notificationContent = () => (
              <span>Error occurred while archiving the experiment</span>
            );
            uiActions.showErrorNotification(notificationContent);
          });
      },
    });
  };

  duplicateAction = () => {
    const { experiment, router } = this.props;
    // TODO bad implementation, use regex
    const module = router.location.pathname.substring(
      1,
      router.location.pathname.indexOf('/experimentation/list'),
    );
    router.push({
      pathname: `/experimentation/${module}/duplicate/${experiment.id}`,
    });
  };

  previewAction = () => {
    const { experiment, onPreview } = this.props;
    onPreview(experiment);
  };

  historyAction = () => {
    const { experiment } = this.props;
    const campaignId = experiment.id;
    const campaignName = experiment.testName;
    const campaignHistoryModal = () => (
      <CampaignHistoryModal campaignId={campaignId} isExperiment />
    );

    modalActions.openModal({
      title: `Experiment History (${campaignName})`,
      content: campaignHistoryModal,
    });
  };

  render() {
    const { experiment } = this.props;
    const isActive = experiment.status === 'ACTIVE';
    const actions = this.getActions();
    let testSplitText = '';
    experiment.variations.forEach((variation, index) => {
      testSplitText += `${variation.testSplit}%${
        index !== experiment.variations.length - 1 ? ' - ' : ''
      }`;
    });
    testSplitText += ' User Split';
    const dateFormatted = moment(experiment.endDate).format('MMMM DD');
    const calendarText = isActive
      ? `${dateFormatted}, will be ended`
      : `${dateFormatted}, ended`;
    return (
      <li className='widget-list-item'>
        <div className='widget-title-wrapper' style={{ width: '100%' }}>
          <h2 className='widget-title'>{experiment.testName}</h2>
          <div className='widget-meta experimentation-list-meta'>
            <span className='widget-calendar'>
              <Icon name='calendar' />
              <span>{calendarText}</span>
            </span>
            <span className='widget-user-split'>
              <Icons name='userSplit' width={15} height={15} />
              <span style={{ marginLeft: '10px' }}>{testSplitText}</span>
            </span>
          </div>
        </div>
        <div className='right-side-wrapper'>
          <nav className='widget-nav action-nav' aria-haspopup='true'>
            <a className='secondary-action'>{t('Actions')}</a>
            <ul className='widget-nav-popup action-nav-popup drop-up'>
              {actions.map(action => (
                <li key={action.name}>
                  <a
                    className='widget-nav-popup-item action-nav-popup-item'
                    onClick={action.handler}
                  >
                    {action.iconSvg ? (
                      <Icons name={action.icon} color='#fff' />
                    ) : (
                      <Icon name={action.icon} />
                    )}
                    {action.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </li>
    );
  }
}

export default withRouter(ExperimentationListItem);
