import React, { useEffect, useState } from 'react';
import './SFYRightBar.scss';
import { isValidStartDate, reformatDateByTimeZone } from '../../../system/date';
import { Calendar } from '../../fields';
import { t } from '../../../system/ui';
import { CampaignActionButton } from '../module-exports';
import Devices from './Components/Devices';
import TextFieldComp from './Components/TextFieldComp';
import PagesToShow from './Components/PagesToShow';
import TargetDivSelector from './Components/TargetDivSelector';

const SFYRightBar = ({
  startDate,
  endDate = null,
  campaignActionButtonConstant,
  activeCampaign,
  campaignSetter,
  isCampaignNameVisible = false,
  isLimitVisible = false,
  isPagesAllVisible = false,
  isPagesUrlVisible = false,
  isDevicesVisible = false,
  isTargetSelectorVisible = false,
  isAddCampaign = false,
}) => {
  const [isEndDateVisible, setEndDateVisible] = useState(false);

  useEffect(() => {
    if (endDate !== null && endDate) setEndDateVisible(true);

    return () => {
      setEndDateVisible(false);
    };
  }, [endDate]);

  useEffect(() => {
    if (endDate && !isEndDateVisible) {
      campaignSetter('endDate', '');
    }
  }, [isEndDateVisible]);

  return (
    <div className='sfy-rightBar'>
      <div className='sfy-rightBar-content'>
        {isCampaignNameVisible && (
          <TextFieldComp
            id='campaign-name'
            title='Campaign Name'
            value={activeCampaign.name}
            valueSetter={campaignSetter}
            valueType='name'
            isRequired
          />
        )}
        <div className='start-date'>
          <Calendar
            label='Start Date - Time'
            className='item-stacked one-whole'
            isValidDate={isValidStartDate}
            value={reformatDateByTimeZone(startDate || new Date())}
            onChange={e => campaignSetter('startDate', new Date(e).getTime())}
            inputProps={{ className: 'datetime' }}
          />
        </div>
        {endDate !== null && (
          <div className='end-date'>
            <span className='check-group-toggle' />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className='item item-stacked is-checkbox'>
              <input
                type='checkbox'
                checked={isEndDateVisible}
                onChange={e => {
                  const { checked } = e.target;
                  setEndDateVisible(checked);
                  if (!endDate) {
                    const endDateValue = checked
                      ? new Date(new Date()).getTime() + 15 * 60 * 1000
                      : null;
                    campaignSetter('endDate', new Date(endDateValue).getTime());
                  }
                }}
              />
              <span className='item-label'>{t('End Date - Time')}</span>
            </label>
            {isEndDateVisible && (
              <Calendar
                className='item-stacked one-whole'
                isValidDate={current => current.isAfter(new Date(startDate))}
                value={reformatDateByTimeZone(endDate)}
                onChange={e => campaignSetter('endDate', new Date(e).getTime())}
              />
            )}
          </div>
        )}
        {isDevicesVisible && (
          <Devices
            devices={activeCampaign?.devices || []}
            deviceSetter={campaignSetter}
          />
        )}
        {isLimitVisible && (
          <TextFieldComp
            title='Maximum Number of Spin'
            value={activeCampaign.limit || ''}
            valueSetter={campaignSetter}
            isNumberText
            valueType='limit'
          />
        )}
        {isPagesAllVisible && (
          <PagesToShow
            pages={
              activeCampaign?.filters
                ? activeCampaign?.filters?.find(
                    filter => filter.type === 'PAGE',
                  )?.includedCategories
                : []
            }
            urlFilter={
              activeCampaign?.filters
                ? activeCampaign?.filters?.find(
                    filter => filter.type === 'PAGE_URL',
                  )
                : {
                    type: 'PAGE_URL',
                    includedUrls: [],
                    excludedUrls: [],
                  }
            }
            isAddCampaign={isAddCampaign}
            pagesSetter={campaignSetter}
            isPagesUrlVisible={isPagesUrlVisible}
          />
        )}
        {isTargetSelectorVisible && (
          <TargetDivSelector
            targetPosition={activeCampaign?.targetPosition}
            targetSelector={activeCampaign?.targetSelector}
            targetSetter={campaignSetter}
          />
        )}
      </div>
      <div className='sfy-rightBar-actions'>
        <CampaignActionButton
          campaignActionButtonConstant={campaignActionButtonConstant}
        />
      </div>
    </div>
  );
};

export default SFYRightBar;
