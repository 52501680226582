import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import classNames from 'classnames';
import { propTypes } from 'react-codemirror';
import { devices } from '../../../../constants/datamaps/campaigns';
import Tooltip from '../../../tooltip';
import Icon from '../../../icon';
import Icons from '../../../icons';
import { t } from '../../../../system/ui';

class DeviceFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDevice: 'all',
    };

    this.selectDevice = this.selectDevice.bind(this);
  }

  selectDevice(selectedDevice, e) {
    this.setState({
      selectedDevice,
    });
    this.props.selectDevice(selectedDevice);
  }

  render() {
    return (
      <div
        className={`${this.props.isTimeSelectVisible && 'd-flex'} ${classNames(
          'widget-meta',
          this.props.className,
        )}`}
      >
        {this.props.isTimeSelectVisible && (
          <div className='search-insight-time-select'>
            <div
              className={`search-insight-time-select__daily ${this.props
                .selectedGraphView === 'daily' &&
                'search-insight-time-select__isSelected'}`}
              onClick={() => this.props.selectGraphView('daily')}
            >
              <span className='search-insight-time-select__daily-text'>
                {t('Daily')}
              </span>
            </div>
            <div
              className={`search-insight-time-select__hourly ${this.props
                .selectedGraphView === 'hourly' &&
                'search-insight-time-select__isSelected'}`}
              onClick={() => this.props.selectGraphView('hourly')}
            >
              <span className='search-insight-time-select__hourly-text'>
                {t('Hourly (Cumulative)')}
              </span>
            </div>
          </div>
        )}
        <span className='recommendation-device-selection'>
          <Tooltip
            content={t('All Devices')}
            alignment='top'
            newTooltip
            isInline
          >
            <span
              key='all-devices'
              onClick={this.selectDevice.bind(this, 'all', 'search')}
              className={classNames('widget-meta-mobile all-device-report', {
                'is-selected': this.state.selectedDevice === 'all',
              })}
            >
              <Icons name='allDevices' width='16' height='16' color='#bababa' />
              {t('ALL')}
            </span>
          </Tooltip>
          {this.props.devices
            ? this.props.devices.map(device => {
                return (
                  <Tooltip
                    content={t(devices[device])}
                    alignment='top'
                    newTooltip
                    isInline
                  >
                    <span
                      key={device}
                      onClick={this.selectDevice.bind(this, device)}
                      className={classNames('widget-meta-mobile', {
                        'is-selected': this.state.selectedDevice === device,
                      })}
                    >
                      <Icon name={device.toLowerCase()} />
                    </span>
                  </Tooltip>
                );
              })
            : ''}
        </span>
      </div>
    );
  }
}

export default connect()(DeviceFilter);

DeviceFilter.propTypes = {
  className: PropTypes.string,
};
