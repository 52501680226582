import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '../../../../components/fields';

const CampaignName = ({ value, onChange }) => {
  return (
    <div className='form-element'>
      <span className='element-title'>Campaign Name*</span>
      <TextField
        className='element-textfield'
        name='campaignName'
        value={value}
        onChange={e => onChange(e.target.value)}
        required
      />
    </div>
  );
};

CampaignName.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CampaignName;
