import React from 'react';
import { browserHistory } from 'react-router';
import AdminStats from '../../../admin.stats';
import AddClientButton from '../../components/AddClientButton';

const Accounts = () => {
  return (
    <div className='accounts-group-field'>
      <div className='accounts-title'>My Accounts</div>
      <AddClientButton />
      <div className='accounts-content statics-content'>
        <AdminStats router={browserHistory} />
      </div>
    </div>
  );
};

export default Accounts;
