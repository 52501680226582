import moment from 'moment';

import {
  TOGGLE,
  CHANGE_WIDGET_ITEM,
  CHANGE_DELAY_TIME,
  CHANGE_DELAY_UNIT,
  CHANGE_ADD_TITLES,
  CHANGE_ADD_DESCRIPTIONS,
  CHANGE_ICON,
  CHANGE_ADDITIONAL_ICONS,
  CHANGE_IMAGE,
  CHANGE_ADDITIONAL_IMAGES,
  CHANGE_TARGET,
  CHANGE_ACTIONS,
  CHANGE_COUPON_CODE,
  CHANGE_COUPON_EXPIRE,
  RESET_ABANDON,
  INIT_ABANDON_VALUES,
  SET_FETCHED,
  SET_FETCHING,
  UPDATE_STATUS,
  UPDATE_HIDE_PRICE,
  CHANGE_SELECTED_LANGUAGE,
  UPDATE_HIDE_INFORMATION,
  CHANGE_COUPON_BASKET_AMOUNT,
} from '../actions/abandon';

const initialState = {
  isFetching: true,
  status: 'ACTIVE',
  hidePrice: false,
  hideInformation: false,
  FIRST_REMINDER: {
    active: true,
    delay: 15,
    delayUnit: 'MINUTE',
    icon: '{{push:icon}}',
    image: '{{product:image}}',
    actions: [],
    selectedLanguage: '',
    additionalTitles: {},
    additionalIcons: {},
    additionalImages: {},
  },

  SECOND_REMINDER: {
    active: false,
    delay: 3,
    delayUnit: 'HOUR',
    icon: '{{push:icon}}',
    image: '{{product:image}}',
    actions: [],
    selectedLanguage: '',
    additionalTitles: {},
    additionalIcons: {},
    additionalImages: {},
  },

  ALTERNATIVE_PRODUCTS: {
    active: false,
    delay: 2,
    delayUnit: 'DAY',
    icon: '{{push:icon}}',
    image: '{{product:image}}',
    actions: [],
    selectedLanguage: '',
    additionalTitles: {},
    additionalIcons: {},
    additionalImages: {},
  },

  COUPON: {
    active: false,
    delay: 4,
    delayUnit: 'DAY',
    icon: '{{push:icon}}',
    image: '{{product:image}}',
    actions: [],
    additionalTitles: {},
    additionalIcons: {},
    additionalImages: {},
    couponCode: '',
    couponExpire: false,
    basketAmount: null,
    selectedLanguage: '',
    expireDate: moment().add(3, 'days'),
  },

  WIDGET: {
    template: 'STICK_POP_UP',
    html: `<div id="seg-coupon-asimov">
              <div class="seg-coupon-asimov_switch"></div>
              <div class="seg-coupon-asimov_content seg-border-scoop-top">
                  <h1 class="seg-coupon-asimov_headline">[[title]]</h1>
                  <p class="seg-coupon-asimov_paragraph">[[description]]</p>
                  <small class="seg-coupon-asimov_small">[[valid_until]]</small>
                  <div class="seg-coupon-asimov_code">[[code]]</div>
                  <button class="seg-coupon-asimov_copy">[[copy_button_text]]</button>
              </div>
          </div>`,
    title: '',
    description: '',
    backgroundColor: '#624368',
    foregroundColor: '#ffffff',
    copyButtonText: 'Copy',
    copiedInfoText: 'Copied!',
    validationText: 'Valid Until',
    selectedLanguage: '',
    position: 'LEFT',
  },
};

function abandon(state = initialState, action) {
  switch (action.type) {
    case CHANGE_WIDGET_ITEM: {
      const { name, value } = action.payload;
      return {
        ...state,
        WIDGET: {
          ...state.WIDGET,
          [name]: value,
        },
      };
    }

    case TOGGLE: {
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          active: !state[reminder].active,
        },
      };
    }

    case CHANGE_DELAY_TIME: {
      const delay = action.payload.delay.value;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          delay,
        },
      };
    }

    case CHANGE_DELAY_UNIT: {
      const { delayUnit } = action.payload;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          delayUnit,
        },
      };
    }

    case CHANGE_ADD_TITLES: {
      const { titlesObj } = action.payload;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          additionalTitles: titlesObj,
        },
      };
    }

    case CHANGE_ADD_DESCRIPTIONS: {
      const { descObj } = action.payload;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          additionalDescriptions: descObj,
        },
      };
    }

    case CHANGE_ICON: {
      const { icon } = action.payload;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          icon,
        },
      };
    }

    case CHANGE_ADDITIONAL_ICONS: {
      const { additionalIcons } = action.payload;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          additionalIcons,
        },
      };
    }

    case CHANGE_IMAGE: {
      const { image } = action.payload;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          image,
        },
      };
    }

    case CHANGE_ADDITIONAL_IMAGES: {
      const { additionalImages } = action.payload;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          additionalImages,
        },
      };
    }

    case CHANGE_TARGET: {
      const { target } = action.payload;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          target,
        },
      };
    }

    case CHANGE_COUPON_CODE: {
      const couponCode = action.payload.code;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          couponCode,
        },
      };
    }

    case CHANGE_COUPON_EXPIRE: {
      const { expire } = action.payload;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          expireDate: expire,
        },
      };
    }

    case CHANGE_COUPON_BASKET_AMOUNT: {
      const { amount } = action.payload;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          basketAmount: amount,
        },
      };
    }

    case CHANGE_ACTIONS: {
      const { actions } = action.payload;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          actions,
        },
      };
    }

    case INIT_ABANDON_VALUES: {
      const { payload } = action;

      return {
        ...state,
        ...payload,
      };
    }

    case SET_FETCHING: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case SET_FETCHED: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case UPDATE_STATUS: {
      return {
        ...state,
        status: action.status,
      };
    }

    case CHANGE_SELECTED_LANGUAGE: {
      const { language } = action.payload;
      const reminder = action.payload.name;

      return {
        ...state,
        [reminder]: {
          ...state[reminder],
          selectedLanguage: language,
        },
      };
    }

    case RESET_ABANDON: {
      return initialState;
    }

    case UPDATE_HIDE_PRICE: {
      const hidePrice = action.payload.status;

      return {
        ...state,
        hidePrice,
      };
    }

    case UPDATE_HIDE_INFORMATION: {
      const hideInformation = action.payload.status;

      return {
        ...state,
        hideInformation,
      };
    }

    default:
      return state;
  }
}

export default abandon;
