import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCampaigns,
  getSingleCampaigns,
} from '../../../reducers/ds/extraReducers';

import { t } from '../../../system/ui';
import Icon from '../../../components/icon';
import { SfyAccordion, SfySingleAccordion } from './components/sfy-accordion';
import CreateSegmentGroup from './components/CreateSegmentGroup/CreateSegmentGroup';
import { modalActions } from '../../../actions';
import { SFYHeader } from '../../../components/sfy-components/module-exports';
import { breadCrumbOptions } from './constants';
import { setTitle } from '../../../system/document';

/* 
  This component is used to render the Dynamic Segmentation Landing page. 
  It contains the following components:
  ? - SfyAccordion
  ? - SfySingleAccordion
  ? - CreateSegmentGroup
  ? - Link
  ? - Icon
  ? - t (translation function)
  ? - useDispatch (redux hook)
  ? - useSelector (redux hook)
  ? - getCampaigns (redux action)
  ? - getSingleCampaigns (redux action)
  ? - dispatch (redux hook)
  ? - dsCampaigns (variable from redux state)
  ? - dsSingleCampaigns (variable from redux state for ungrouped segments)
*/

const DynamicSegmentation = () => {
  const dispatch = useDispatch();
  const dsCampaigns = useSelector(state => state.ds.criteriaCampaigns.data);
  const dsSingleCampaigns = useSelector(
    state => state.ds.criteriaSingleCampaigns.data,
  );

  const { openModal } = modalActions;

  const handleClick = useCallback(() => {
    openModal({
      title: 'Segment Group Name',
      content: () => <CreateSegmentGroup mode='add' />,
      className: 'detail-preview',
    });
  }, [openModal]);

  useEffect(() => {
    setTitle('Dynamic Segmentation - Customer Data Management');
    dispatch(getCampaigns());
    dispatch(getSingleCampaigns());
  }, [dispatch]);

  // ? This is the render props for the Group List for Dynamic Segmentation Landing page.
  const segmentCardProps = {
    cardHeader: t('Segments'),
    fetchedData: dsCampaigns,
  };

  // ? This is the render props for the Ungrouped Segment List for Dynamic Segmentation Landing page.
  const singleSegmentCardProps = {
    fetchSingleData: dsSingleCampaigns,
  };

  return (
    <>
      <SFYHeader
        pageTitle={t('Dynamic Segmentation')}
        breadCrumbOptions={breadCrumbOptions}
      />

      <Link
        to='/audience/dynamic-segmentation/add'
        className='available-type available-type-audience'
      >
        <span className='available-type-wireframe'>
          <Icon name='plus' size='2x' />
          <span>{t('Create a new Segment')}</span>
        </span>
      </Link>

      <Link
        onClick={() => handleClick()}
        className='available-type available-type-segment-group'
      >
        <span>
          <Icon name='folder-plus' size='2x' />
          <p>{t('Create a Segment Group')}</p>
        </span>
      </Link>

      <SfyAccordion {...segmentCardProps} />
      <SfySingleAccordion {...singleSegmentCardProps} />
    </>
  );
};

export default DynamicSegmentation;
