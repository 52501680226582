import React from "react";

class IconPeriodicPush extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 81.33 77.94"
        width={this.state.width}
        height={this.state.height}
      >
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            d="M76.88,55.23a2.1,2.1,0,0,1-.73-.14A2,2,0,0,1,75,52.5c.19-.49.37-1,.53-1.47A33.76,33.76,0,0,0,55.28,8.55,2,2,0,1,1,56.67,4.8,37.77,37.77,0,0,1,79.34,52.32c-.19.55-.39,1.09-.6,1.64A2,2,0,0,1,76.88,55.23Z"
            fill={this.state.color}
          />
          <polygon
            points="55.4 14.97 45.2 2.7 60.93 0 55.4 14.97"
            fill={this.state.color}
          />
          <path
            d="M8.23,47.35a2,2,0,0,1-2-1.72A37.76,37.76,0,0,1,35.43,3.36a2,2,0,1,1,.86,3.9,33.76,33.76,0,0,0-26.08,37.8,2,2,0,0,1-1.69,2.27Z"
            fill={this.state.color}
          />
          <polygon
            points="15.79 41.9 9.87 56.71 0 44.18 15.79 41.9"
            fill={this.state.color}
          />
          <path
            d="M43.6,77.94A37.66,37.66,0,0,1,14.43,64.15a2,2,0,0,1,3.09-2.54,33.73,33.73,0,0,0,46,5.82,2,2,0,1,1,2.36,3.23A37.43,37.43,0,0,1,43.6,77.94Z"
            fill={this.state.color}
          />
          <polygon
            points="58.09 63.99 73.95 62.26 67.52 76.86 58.09 63.99"
            fill={this.state.color}
          />
          <path
            d="M43.59,24.68A15.95,15.95,0,1,0,59.54,40.62,15.94,15.94,0,0,0,43.59,24.68Zm6.93,18.65H40.27V32a2,2,0,1,1,4,0v7.38h6.25a2,2,0,0,1,0,4Z"
            fill={this.state.color}
          />
        </g>
      </svg>
    );
  }
}

export default IconPeriodicPush;
