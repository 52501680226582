const shorterIndex = {
  BEFORE_SEARCH: 'feBUs',
  SEARCH: 'RckFK',
  SearchSelection: 'CtXwu',
  SearchsList: 'SLAeB',
  ExecutiveSearchReport: 'RokdJ',
  SearchRow: 'eWjNG',
  KEYWORD: 'WyZxM',
  BRAND: 'WntJU',
  CATEGORY: 'WturB',
  TextField: 'xFlSL',
  name: 'XmMde',
  title: 'XlJkQ',
  categoriesText: 'XtvHq',
  brandsText: 'XrbWq',
  popularProductsText: 'Xpqir',
  mobileCancelText: 'XbGCN',
  notFoundText: 'XuufS',
  WizardItems: 'wDmEQ',
  before_search_edit: 'beslv',
  SearchRecommendations: 'mAnEZ',
  SortableElement: 'TejGx',
  intelligent: 'LlevF',
  segmentList: 'wpdAN',
  'Active Loyals': 'yWZIH',
  'Big Spenders': 'sbgPy',
  'Loyals at Risk': 'ayMDQ',
  'Richie Riches': 'YzRPV',
  'Bulk Shoppers': 'xcNoY',
  'Sleeping Opportunists': 'FlCUF',
  'Churn Candidates': 'rNmrS',
  'Lost Customers': 'paZds',
  'One Time Shoppers': 'EXYti',
  Passive: 'FhmWm',
  History: 'oFxKi',
  Details: 'vtRDh',
};

export const idPropFunc = (inputId, ...args) => {
  const idProp = {};
  if (inputId) {
    idProp.id = inputId;
    if (args.length)
      args.forEach(arg => {
        if (shorterIndex[arg]) {
          idProp.id += shorterIndex[arg];
        } else {
          idProp.id += arg;
        }
      });
  }

  if (idProp.id && idProp.id.indexOf(' ') >= 0)
    idProp.id = `id contains whitespace. Check shorterIndex in id.js id=${idProp.id}`;
  return idProp;
};
