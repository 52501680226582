import React, { useState, useEffect, useContext } from 'react';
import './BoostFields.scss';
import CategoryAccessor from './CategoryAccessor';
import BrandAccessor from './BrandAccessor';
import { Context as GlobalConfigurationContext } from '../../../../../views/searchandising/global-configuration/store/globalConfigurationProvider';
import { t } from '../../../../../system/ui';

const BoostFields = ({ data }) => {
  const { setGlobalBoost } = useContext(GlobalConfigurationContext);

  const [categoryBoostFields, setCategoryBoostFields] = useState([]);
  const [brandBoostFields, setBrandBoostFields] = useState([]);

  useEffect(() => {
    const tempCategoryBoostFields = [];
    const tempBrandBoostFields = [];
    // eslint-disable-next-line no-unused-expressions
    data?.boostFields?.forEach(item => {
      if (item.productAccessor === 'category') {
        tempCategoryBoostFields.push(item);
      } else if (item.productAccessor === 'brand') {
        tempBrandBoostFields.push(item);
      }
    });

    setCategoryBoostFields(tempCategoryBoostFields);
    setBrandBoostFields(tempBrandBoostFields);
  }, [data]);

  const removeBoostField = id => {
    const newBoostFields = data?.boostFields?.filter(item => item.id !== id);
    setGlobalBoost(newBoostFields);
  };

  const setAttributes = boostFieldAttribute => {
    const newBoostFields = data?.boostFields?.map(item => {
      if (item.id === boostFieldAttribute.id) {
        return boostFieldAttribute;
      }
      return item;
    });
    setGlobalBoost(newBoostFields);
  };

  return (
    <div className='sfy-boost-fields-content'>
      {categoryBoostFields && categoryBoostFields.length > 0 && (
        <div className='sfy-boost-field'>
          <span className='sfy-boost-field-label'>
            {`${t('Category')} (${categoryBoostFields.length})`}
          </span>
          {categoryBoostFields.map(item => (
            <CategoryAccessor
              key={item.id}
              data={item}
              onRemove={removeBoostField}
              setAttributes={setAttributes}
            />
          ))}
        </div>
      )}
      {brandBoostFields && brandBoostFields.length > 0 && (
        <div className='sfy-boost-field'>
          <span className='sfy-boost-field-label'>
            {`${t('Brand')} (${brandBoostFields.length})`}
          </span>
          {brandBoostFields.map(item => (
            <BrandAccessor
              key={item.id}
              data={item}
              onRemove={removeBoostField}
              setAttributes={setAttributes}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default BoostFields;
