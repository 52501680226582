import React, { useCallback, useEffect, useState } from 'react';
import cn from './EmailCampaignList.module.scss';
import ListCalendar from '../../../../components/sfy-components/ListCalendar/ListCalendar';
import EmailActions from '../email-actions/EmailActions';

const EmailCampaignList = ({ item, statusType }) => {
  const { reportValues, scenarioName, type, schedule } = item;
  const [reportData] = useState({
    emailSent: 0,
    emailOpened: 0,
    emailClicked: 0,
  });

  const reportDataHandler = useCallback(() => {
    reportValues.forEach(({ type, value }) => {
      if (type === 'Email [sent]') {
        reportData.emailSent = value;
      }
      if (type === 'Email [opened]') {
        reportData.emailOpened = value;
      }
      if (type === 'Email [clicked]') {
        reportData.emailClicked = value;
      }
    });
  }, [reportValues]);

  useEffect(() => {
    reportDataHandler();
  }, []);

  return (
    <div className={cn.cardWrapper}>
      <div className={cn.start}>
        <div className={cn.title}>{decodeURIComponent(scenarioName)}</div>
      </div>
      <div className={cn.leftSide}>
        <div className={cn.calendar}>
          {type === 'RECOMMENDATION' && (
            <ListCalendar
              statusType={statusType}
              scheduleDate={schedule?.date}
              styleType='calendarTextRight'
              iconColor='#737373'
              iconSize='12'
            />
          )}
        </div>
        <div className={cn.actions}>
          <EmailActions data={item} statusType={statusType} />
        </div>
      </div>
    </div>
  );
};

export default EmailCampaignList;
