import React, { useState, useContext, useEffect, useCallback } from 'react';
import { browserHistory } from 'react-router';
import { t } from '../../../../system/ui';
import { modalActions } from '../../../../actions';
import { Context as GamificationContext } from '../store/gamificationProvider';
import { wofModalStates, modalStateDetails } from '../constants/constants';

import WofType from './wofType';
import UploadCoupon from './uploadCoupon';

const getInitialWofState = (wofFor, wofType) => {
  if (wofType === 'UNIQUE') {
    if (wofFor === 'list-screen') {
      return wofModalStates.UNIQUE_LIST_SCREEN;
    }
    if (wofFor === 'edit-screen') {
      return wofModalStates.UNIQUE_EDIT_SCREEN;
    }
  }

  return wofModalStates.WOF_TYPE;
};

/**
 * @name WofModalManager
 * @description This component is responsible for managing the state of the bundle modal
 * @param {string} wofFor - list-screen or edit-screen
 * @param {string} wofType - GENERIC or UNIQUE
 * @param {object} bundleItem - bundle item object
 * @param {boolean} isEditMode - is edit mode
 * @return {JSX.Element}
 */
const WofModalManager = ({
  wofFor,
  wofType = '',
  campaignType,
  instanceId = '',
}) => {
  const {
    editActiveWOFDetails,
    resetActiveWOFDetails,
    resetActiveScratchDetails,
    editActiveScratchDetails,
  } = useContext(GamificationContext);
  const [selectedWofType, setSelectedWofType] = useState(wofType);
  const [wofState, setWofState] = useState(getInitialWofState(wofFor, wofType));
  const [isContinueButtonEnabled, setIsContinueButtonEnabled] = useState(false);
  const [couponDetails, setCouponDetails] = useState({
    possibleRewards: [],
    isUploaded: false,
  });

  const continueHandler = () => {
    if (wofState === wofModalStates.WOF_TYPE) {
      if (campaignType === 'WHEEL_OF_FORTUNE') {
        editActiveWOFDetails({ gamificationType: selectedWofType });
      } else {
        editActiveScratchDetails({ gamificationType: selectedWofType });
      }
      if (selectedWofType === 'GENERIC') {
        browserHistory.push(
          `/behavioural-targeting/engagement/gamification/${
            campaignType === 'WHEEL_OF_FORTUNE' ? 'wof' : 'scratch'
          }/add`,
        );
      }
      if (selectedWofType === 'UNIQUE') {
        if (wofFor === 'list-screen') {
          setWofState(wofModalStates.UNIQUE_LIST_SCREEN);
        }
        if (wofFor === 'edit-screen') {
          setWofState(wofModalStates.UNIQUE_EDIT_SCREEN);
        }
      }
    }

    if (wofState === wofModalStates.UNIQUE_LIST_SCREEN) {
      if (campaignType === 'WHEEL_OF_FORTUNE') {
        editActiveWOFDetails({
          possibleRewards: couponDetails.possibleRewards,
        });
      } else {
        editActiveScratchDetails({
          possibleRewards: couponDetails.possibleRewards,
        });
      }

      if (instanceId !== '') {
        browserHistory.push(
          `/behavioural-targeting/engagement/gamification/wof/duplicate/${instanceId}`,
        );
      } else {
        browserHistory.push(
          `/behavioural-targeting/engagement/gamification/${
            campaignType === 'WHEEL_OF_FORTUNE' ? 'wof' : 'scratch'
          }/add`,
        );
      }
    }

    if (wofState === wofModalStates.UNIQUE_EDIT_SCREEN) {
      if (campaignType === 'WHEEL_OF_FORTUNE') {
        editActiveWOFDetails({
          possibleRewards: couponDetails.possibleRewards,
        });
      } else {
        editActiveScratchDetails({
          possibleRewards: couponDetails.possibleRewards,
        });
      }
      modalActions.closeModal();
    }
  };

  const handleCouponUpload = possibleRewards => {
    setCouponDetails({ possibleRewards, isUploaded: true });
  };

  const activeModalComponent = useCallback(() => {
    switch (wofState) {
      case wofModalStates.WOF_TYPE:
        return <WofType onChange={setSelectedWofType} type={campaignType} />;
      case wofModalStates.UNIQUE_LIST_SCREEN:
      case wofModalStates.UNIQUE_EDIT_SCREEN:
        return (
          <UploadCoupon onChange={handleCouponUpload} type={campaignType} />
        );
      default:
        return null;
    }
  }, [wofState]);

  const stateValidationControl = () => {
    switch (wofState) {
      case wofModalStates.WOF_TYPE:
        return selectedWofType;
      case wofModalStates.UNIQUE_LIST_SCREEN:
      case wofModalStates.UNIQUE_EDIT_SCREEN:
        return couponDetails?.isUploaded;
      default:
        return false;
    }
  };

  useEffect(() => {
    setIsContinueButtonEnabled(stateValidationControl());
  });

  useEffect(() => {
    if (wofFor === 'list-screen') {
      resetActiveWOFDetails();
      resetActiveScratchDetails();
    }
  }, []);

  return (
    <>
      <div className='bundle-modal-header'>
        <h3
          className={`modal-content-title ${modalStateDetails[wofState]
            .titlePosition === 'center' && 'modal-title-center'}`}
          id='modal-title'
        >
          {t(modalStateDetails[wofState].title)}
        </h3>
        {modalStateDetails[wofState].description && (
          <p
            className={`modal-content-subtitle ${
              selectedWofType === 'unique' ? selectedWofType : ''
            }`}
          >
            {t(modalStateDetails[wofState].description)}
          </p>
        )}
      </div>
      <div className='bundle-product-modal'>
        <div className='bundle-product-modal-content'>
          {activeModalComponent()}
        </div>
        <div className='continue-button'>
          <button
            className='tertiary-action'
            type='button'
            onClick={() => continueHandler()}
            disabled={!isContinueButtonEnabled}
          >
            {t(modalStateDetails[wofState].buttonText)}
          </button>
        </div>
      </div>
    </>
  );
};

export default WofModalManager;
