import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const spScoreAndReview = ({ width, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 22 22'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      fill='none'
    >
      <path
        d='M4.74309 13.6779L3.76738 19.3667C3.60518 20.3123 4.35702 21.0803 5.20962 21.0796C5.43483 21.0798 5.66713 21.0264 5.89151 20.9085L4.74309 13.6779ZM4.74309 13.6779L0.609914 9.64905M4.74309 13.6779L7.13236 6.32444M7.13236 6.32444L1.42045 7.15444C0.220878 7.32874 -0.258107 8.80286 0.609914 9.64905M7.13236 6.32444L9.68677 1.14858M7.13236 6.32444L9.68677 1.14858M0.609914 9.64905L1.13342 9.11198C0.710507 8.69966 0.943938 7.98155 1.5283 7.89664L7.24021 7.06664L7.63041 7.00994L7.80491 6.65636L10.3593 1.48054C10.4906 1.21457 10.7374 1.08315 10.9988 1.08334L10.9999 1.08334C11.2612 1.08315 11.5081 1.21454 11.6394 1.4805C11.6394 1.48051 11.6394 1.48052 11.6394 1.48054L13.5 5.25064V5.25494L13.5774 5.41187L14.1917 6.65638L14.3662 7.00995L14.7564 7.06664L20.4683 7.89664C21.0526 7.98155 21.2861 8.69968 20.8631 9.11202L16.7299 13.1408L16.4476 13.416L16.5142 13.8046L17.49 19.4934C17.5898 20.0755 16.9789 20.5193 16.4562 20.2446L16.1071 20.9085L16.4561 20.2446L11.3483 17.5592L10.9993 17.3758L10.6503 17.5592L5.5425 20.2446L5.54248 20.2446C5.42656 20.3056 5.31439 20.3297 5.21033 20.3296H5.20904C4.7851 20.3299 4.42906 19.9454 4.50658 19.4934L5.4823 13.8046L5.54895 13.416L5.2666 13.1408L1.13345 9.11201L0.609914 9.64905ZM9.68677 1.14858C9.95522 0.604722 10.4774 0.332968 10.9993 0.333336L9.68677 1.14858Z'
        stroke={color}
        strokeWidth='1.5'
      />
    </svg>
  );
};

spScoreAndReview.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

spScoreAndReview.defaultProps = {
  width: '22px',
  height: '22px',
  color: '#5D5D61',
};

export default spScoreAndReview;
