import { Link } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import Icons from '../icons';

const ConfigurationError = ({ message }) => {
  return (
    <div
      className='configuration-error has-error'
      style={{ width: 'calc(100% - 300px)' }}
    >
      <div className='configuration-error-icon'>
        <Icons name='warning' color='#000000' width={32} height={32} />
      </div>
      <div className='configuration-error-text'>
        <h3>Configure Your Settings</h3>
        <p>{message}</p>
        <Link to='/settings/push' className='config-cta' onlyActiveOnIndex>
          Go to Settings
        </Link>
      </div>
    </div>
  );
};

ConfigurationError.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ConfigurationError;
