import React, { useContext } from 'react';
import styles from './Stats.module.scss';
import StatsHeader from './StatsHeader';
import StatsBody from './StatsBody';
import { Context as MonitoringContext } from '../store/MonitoringProvider';

/**
 * @name Stats
 * @description Stats component that renders all statistics subcomponents
 * @return {JSX.Element}
 * @constructor
 */

const Stats = () => {
  const {
    state: { selectedAccounts, isMonitoringLoaded },
  } = useContext(MonitoringContext);
  const processedAccounts = [];
  if (isMonitoringLoaded) {
    selectedAccounts.forEach(acc => {
      if (Object.keys(acc.stats).length) processedAccounts.push(acc);
    });
  }

  return (
    <div className={`vertical-content ${styles['monitoring-stats-wrapper']}`}>
      <div className='account-details all-accounts account-last-child'>
        <table className='data-table'>
          <StatsHeader />
          {isMonitoringLoaded &&
            processedAccounts.map((item, index) => (
              <StatsBody account={item} index={index} />
            ))}
        </table>
      </div>
    </div>
  );
};

export default Stats;
