/**
 * @fileoverview This component creates card for user activity
 * @see Route: /audience/customer-profile/customer-detail/{{ID}}
 */

/**
 * Module dependencies
 */
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { t } from '../../../../system/ui';
import { modalActions } from '../../../../actions';
import { StaticTab } from '../../module-exports';
import { ActivityRender, ModalListRender } from './RenderHelper';

import './user-activity.scss';
import classes from './UserActivityCard.module.scss';
import { isEmpty } from 'lodash';

/**
 * User Activity Card
 * @param {string} title Card title and modal title
 * @param {Object} items Items to be displayed
 * @param {Object} enumUserActivityType Enum for user activities
 * @param {string} fallback Fallback enum if items are not enough
 * @param {string} currency
 * @return {JSX.Element}
 * @constructor
 */
const UserActivityCard = ({
  title,
  items,
  enumUserActivityType,
  fallback,
  currency,
}) => {
  const [itemCount, setItemCount] = useState(0);

  const [mappedItems, setMappedItems] = useState({});

  const [tabs, setTab] = useState([]);

  const [tabsIsReady, setTabsIsReady] = useState(false);

  const { openModal } = modalActions;

  const tabsSetter = newTabs => {
    setTabsIsReady(true);
    setTab(newTabs);
  };

  /**
   * Invoke the modal
   */
  const invokeModal = () => {
    openModal({
      title: t(title),
      content: () => (
        <StaticTab tabs={tabs} tabsSetter={tabsSetter} alignCenter />
      ),
      className: 'sfy-modal--history-activity',
    });
  };

  /**
   * Organizes the items for tab and column grid
   */
  useEffect(() => {
    const itemsTemp = {};
    const tabsTemp = [];
    let count = 0;

    // Each item in historyItem is an array of objects and grouped by activity type
    for (let i = 0; i < Object.keys(enumUserActivityType).length; i += 1) {
      const key = Object.keys(enumUserActivityType)[i];
      // If the item is an array, it means that it has more than one item
      if (Array.isArray(items[key]) === true) {
        // eslint-disable-next-line prefer-destructuring
        itemsTemp[key] = items[key][0];

        count += items[key].length;

        tabsTemp.push({
          id: v4(),
          componentName: enumUserActivityType[key],
          active: i === 0,
          children: <ModalListRender item={items[key]} currency={currency} />,
        });
      } else {
        // If the item is not an array, it means that it has only one item
        itemsTemp[key] = items[key];
        if (items[key] !== undefined && !isEmpty(items[key])) {
          count += 1;
        }
        tabsTemp.push({
          id: v4(),
          componentName: enumUserActivityType[key],
          active: i === 0,
          children: (
            <ModalListRender item={items[key]} currency={currency} singleItem />
          ),
        });
      }
    }

    // The fallback algorithm is used when the user has no activity
    const missingItems = 3 - Object.keys(enumUserActivityType).length;
    for (let j = 0; j < missingItems; j += 1) {
      if (Object.hasOwnProperty.call(items, fallback)) {
        if (Array.isArray(items[fallback]) === true) {
          // TODO: If items doesnt have enough item add empty item, this will be throw error *****
          itemsTemp[`${fallback}${j}`] = items[fallback][j + 1];
        }
      }
    }

    setMappedItems(itemsTemp);
    setItemCount(count);
    setTab(tabsTemp);
  }, [items]);

  /**
   * Check if tabs are ready to render
   */
  useEffect(() => {
    // If the tabs are ready, then invoke modal
    if (!tabsIsReady) return;

    invokeModal();
  }, [tabs, tabsIsReady]);

  return (
    <section className={classes['user-last-activity-container']}>
      <div className={classes['user-last-activity-header']}>
        <div className={classes['user-last-activity-header-title']}>
          {t(title)}
        </div>
        {!enumUserActivityType?.returnsRecord && (
          <div
            className={classes['user-last-activity-header-link']}
            onClick={invokeModal}
          >
            {`${t('See All')} (${itemCount})`}
          </div>
        )}
      </div>
      <div className={classes['user-last-activity-content-container']}>
        <ActivityRender
          activityType={enumUserActivityType}
          newItem={mappedItems}
          currency={currency}
        />
      </div>
    </section>
  );
};

export default UserActivityCard;
