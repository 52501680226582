import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.replace('px', '')}px`;
};

const dynamicBundleIcon = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.7663 14.3834C25.7663 14.0391 25.5651 13.7509 25.278 13.608L19.8476 11.1078V4.58509C19.8476 4.24079 19.6463 3.95261 19.3592 3.80962L13.4118 1.05094C13.1819 0.93655 12.9234 0.93655 12.6935 1.05094L6.71626 3.80962C6.40057 3.95372 6.22787 4.2694 6.22787 4.58509V11.079L0.797467 13.6067C0.481779 13.7508 0.309082 14.0665 0.309082 14.3822V21.479C0.309082 21.8233 0.510372 22.1115 0.797467 22.2545L6.74487 25.0132C6.85926 25.0704 6.97476 25.099 7.11884 25.099C7.23324 25.099 7.37733 25.0704 7.49282 25.0132L13.0375 22.4569L18.5834 25.0143C18.6978 25.0715 18.8133 25.1001 18.9574 25.1001C19.0718 25.1001 19.2159 25.0715 19.3314 25.0143L25.2788 22.2556C25.5944 22.1115 25.7671 21.7958 25.7671 21.4801L25.7663 14.3834ZM7.98019 17.6591L12.1753 15.7628V20.9634L7.98019 22.8883V17.6591ZM13.9 7.86073L18.1238 5.93583V11.1365L13.9 13.09V7.86073ZM18.9564 16.1642L15.1353 14.4119L19.0435 12.6014L22.8646 14.3833L18.9564 16.1642ZM13.0375 2.77443L16.9169 4.58495L13.0375 6.36686L9.15807 4.58495L13.0375 2.77443ZM7.0318 12.6012L10.9399 14.4118L7.11881 16.164L3.23939 14.3821L7.0318 12.6012ZM19.8187 22.8879V17.6588L24.0425 15.7339V20.9346L19.8187 22.8879Z'
        fill={color}
      />
    </svg>
  );
};

dynamicBundleIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

dynamicBundleIcon.defaultProps = {
  width: '16px',
  height: '16px',
  color: '#7A7A7C',
};

export default dynamicBundleIcon;
