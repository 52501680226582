import React from 'react';
import './ExperimentationVariationLabel.scss';

const ExperimentationVariationLabel = ({
  variationName,
  color,
  testSplit,
  hideSplitRatio,
}) => {
  return (
    <div className='variant-label'>
      <span style={{ background: color }} className='variant-dot' />
      <span style={{ whiteSpace: 'nowrap' }}>{variationName}</span>
      {!hideSplitRatio && (
        <span className='variant-split-ratio'>{`${testSplit}%`}</span>
      )}
    </div>
  );
};

export default ExperimentationVariationLabel;
