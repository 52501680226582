export const TOOL_TIPS = {
  'Recipient Issues':
    "These issues are related to problems with the recipient's email address, mailbox or provider.",
  'Server Configuration and Policy Issues':
    'These issues arise from server configuration, policy restrictions, or administrative settings.',
  'Communication and Routing Issues':
    'These issues are related to problems in the email delivery process, including communication between servers and routing.',
  'Content and Security Issues':
    "These issues are related to the email's content, security policies, or the presence of malware.",
  'Soft Bounce':
    'A soft bounce is a temporary email delivery failure due to issues like a full mailbox or server downtime.',
};
