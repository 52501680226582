import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Checkbox } from '../../../../components/fields';
import { convertToSelectOptions } from '../utils';

const TimeRange = ({ value, onChange, selectOptions, hasRealTime }) => {
  const options = convertToSelectOptions(selectOptions);
  const [lastSelectedValue, setLastSelectedValue] = useState(
    value && value !== 'realtime' ? value : options[0].value,
  );

  const handleChangeTimeRange = timeRangeValue => {
    if (timeRangeValue === 'realtime') {
      onChange('realtime');
    } else {
      onChange(timeRangeValue);
      setLastSelectedValue(timeRangeValue);
    }
  };

  const handleChangeRealTimeStatus = () => {
    if (value !== 'realtime') {
      handleChangeTimeRange('realtime');
    } else {
      handleChangeTimeRange(lastSelectedValue);
    }
  };

  return (
    <div className='form-element time-range-field'>
      <span className='element-title'>Time Range*</span>
      <span className='element-description'>
        Please select the time range that will be compatible with your campaign
        message :
      </span>
      <div
        className={`element-row-field ${
          value === 'realtime' ? 'element-real-time' : ''
        }`}
      >
        <span className='element-row-label'>in the last</span>
        <Select
          name='timing'
          className='element-select'
          label=''
          value={value === 'realtime' ? lastSelectedValue : value}
          options={options}
          onChange={e => handleChangeTimeRange(e.value)}
          clearable={false}
          disabled={value === 'realtime'}
        />
        {hasRealTime && <div className='real-time-field-separator' />}
        {hasRealTime && (
          <Checkbox
            name='realTime'
            label='show in real time'
            checked={value === 'realtime'}
            onChange={() => handleChangeRealTimeStatus()}
          />
        )}
      </div>
    </div>
  );
};

TimeRange.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectOptions: PropTypes.shape([]).isRequired,
  hasRealTime: PropTypes.bool,
};

TimeRange.defaultProps = {
  hasRealTime: false,
};

export default TimeRange;
