export const statTypes = {
  totalUsers: {
    name: 'Total Users',
    textType: 'widget',
  },
  unSubscriptions: {
    name: 'Unsubscriptions',
    textType: 'revenue',
  },
  bounceBacks: {
    name: 'Bouncebacks',
    textType: 'basket',
  },
  activeUsers: {
    name: 'Reachable Users',
    textType: 'interaction',
  },
  chart: {
    name: '',
    textType: '',
  },
};

export const userChartData = {
  data: [],
  name: 'name',
  yLabel: 'count',
  yLabelName: '',
  align: 'center',
  type: 'pie',
  calculateTotalByYLabel: true,
  isPriceField: false,
  yLabelFormatConversionF: item => {
    return parseInt(item);
  },
};

export const statisticsOptions = [
  { label: 'Total Statistics', value: 'totalStatistics' },
];

export const emailMarketingAnalyticsPieChartConfig = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    height: 150,
    options3d: {
      enabled: false,
      alpha: 25,
      beta: 0,
    },
  },
  tooltip: {
    pointFormat: `<b>{point.y}</b><br/><b>{point.percentage:.2f}%</b>`,
    backgroundColor: '#000',
    style: {
      color: '#C3C3C3',
    },
  },

  title: {
    text: '',
    align: 'left',
    x: 0,
    style: {
      fontSize: '1.125rem',
      fontFamily: 'montserrat, helvetica, arial, sans-serif',
      fontWeight: 'bold',
      color: '#000',
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      size: '140%',
      allowPointSelect: true,
      cursor: 'pointer',
      colors: ['#DC3322', '#55BC94', '#4b4b4f'],
      dataLabels: {
        enabled: false,
      },
      showInLegend: false,
    },
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    useHTML: true,
    itemStyle: {
      color: '#000',
      fontFamily: 'inherit',
    },
    labelFormatter() {
      return (
        `${'' +
          '<div class="dashboard--funnel__halfbox--legend">' +
          '<div class="title">'}${this.name}</div>` +
        '<div class="count-container">' +
        `<span class="count" style="color:${this.color}">${this.y_label}</span>` +
        'views' +
        '</div>' +
        '</div>'
      );
    },
    symbolWidth: 0,
    symbolHeight: 0,
    x: -40,
  },
  series: [
    {
      name: 'Unsubscription',
      colorByPoint: true,
      type: 'pie',
      data: [],
    },
    {
      name: 'Reachable Users',
      colorByPoint: true,
      type: 'pie',
      data: [],
    },
  ],
};
