import React from 'react';
import '../pagination.scss';

/**
 * @name SFYPagination component
 * @description pagination component responsible from pagination and path to any page
 * @param {function} stateSetter dispatch function to set state of searchQuery to Pagination Context
 * @param {string} state searchQuery state
 * @return {JSX.Element}
 * @constructor
 */
const SearchController = ({ stateSetter, state }) => {
  const onChangeHandler = e => stateSetter(e.target.value);

  return (
    <div
      className='page-options page-options-sort-search'
      style={{ position: 'static' }}
    >
      <label className='item is-stacked is-search' style={{ right: 'unset' }}>
        <i className='icon-magnify' role='presentation'>
          <span className='for-screenreader-only'>magnify icon</span>
        </i>
        <input
          value={state}
          type='text'
          placeholder='Search'
          className='text-field'
          onChange={e => onChangeHandler(e)}
        />
      </label>
    </div>
  );
};

export default SearchController;
