import { v4 as uuid } from 'uuid';
/**
 * @name inclusionOrExclusionConverterForBackEnd
 * @description converts the inclusion array to the format that the back end expects
 * @param {any[]} inclusionsOrExclusions - the array of inclusions or exclusions
 * @return {string}
 */

export const inclusionOrExclusionConverterForBackEnd = inclusionsOrExclusions => {
  if (
    inclusionsOrExclusions &&
    Object.keys(inclusionsOrExclusions)?.length > 0
  ) {
    const inclusionArr = [];
    Object.keys(inclusionsOrExclusions).forEach(key => {
      inclusionsOrExclusions[key]
        .flatMap(({ values }) => values)
        .forEach(value => {
          if (
            key === 'size' ||
            key === 'label' ||
            key === 'color' ||
            key === 'gender'
          ) {
            inclusionArr.push({
              [key]: value?.label?.value,
              type: key?.toUpperCase(),
            });
          } else if (key === 'priceRange') {
            inclusionArr.push({
              range: value.range,
              max: value.max,
              operation: value.operation,
              type: 'PRICE_RANGE',
            });
          } else if (key.includes('custom')) {
            inclusionArr.push({
              parameter: value.parameter,
              operation: value.operation,
              value: value.value,
              otherValue: value.otherValue,
              type: 'CUSTOM',
            });
          } else if (key === 'stock') {
            inclusionArr.push({
              field: value.field,
              operation: value.operation,
              value: value.value,
              type: 'STOCK',
            });
          } else if (key === 'product') {
            inclusionArr.push({
              productId: value?.product?.value,
              type: 'PRODUCT',
            });
          } else if (key === 'brand') {
            inclusionArr.push({
              brand: value?.brand?.value,
              type: 'BRAND',
            });
          }
        });
    });

    const inclusionsArrToString = JSON.stringify(inclusionArr);

    const inclusionsArrWithRegex = inclusionsArrToString
      .replace(/"/g, '"')
      .replace(/=/g, '":"');

    return `${inclusionsArrWithRegex}`;
  }
  return '';
};

/**
 * @name inclusionOrExclusionConverterForFrontEnd
 * @description Converts incoming string data to Array
 * @param inclusionsOrExclusions
 * @param bundleIndex
 * @return {Object}
 */
export const inclusionOrExclusionConverterForFrontEnd = (
  inclusionsOrExclusions,
  bundleIndex,
) => {
  if (!inclusionsOrExclusions) {
    return {};
  }

  const replacedInclusionsOrExclusions = inclusionsOrExclusions
    .replace(/"/g, '"')
    .trim();

  const inclusionsArr = JSON.parse(replacedInclusionsOrExclusions);
  const formattedInclusionsOrExclusions = {};
  inclusionsArr.forEach(inclusion => {
    const inclusionType = inclusion.type.toLowerCase();
    if (
      inclusionType === 'size' ||
      inclusionType === 'label' ||
      inclusionType === 'color' ||
      inclusionType === 'gender'
    ) {
      if (!formattedInclusionsOrExclusions[inclusionType]) {
        formattedInclusionsOrExclusions[inclusionType] = [
          {
            id: uuid(),
            index: bundleIndex,
            type: inclusionType,
            values: {
              label: {
                value: [inclusion[inclusionType]],
              },
            },
          },
        ];
      } else {
        return formattedInclusionsOrExclusions[
          inclusionType
        ][0]?.values?.label?.value.push(inclusion[inclusionType]);
      }
    } else if (inclusionType === 'price_range') {
      if (!formattedInclusionsOrExclusions.priceRange) {
        formattedInclusionsOrExclusions.priceRange = [
          {
            id: uuid(),
            index: bundleIndex,
            type: inclusionType,
            values: {
              range: inclusion.range,
              max: inclusion.max,
              operation: inclusion.operation,
            },
          },
        ];
      } else {
        return formattedInclusionsOrExclusions.priceRange.push({
          id: uuid(),
          index: bundleIndex,
          type: inclusionType,
          values: {
            range: inclusion.range,
            max: inclusion.max,
          },
        });
      }
    } else if (inclusionType === 'stock') {
      if (!formattedInclusionsOrExclusions[inclusionType]) {
        formattedInclusionsOrExclusions[inclusionType] = [
          {
            id: uuid(),
            index: bundleIndex,
            type: inclusionType,
            values: {
              field: inclusion.field,
              operation: inclusion.operation,
              value: inclusion.value,
            },
          },
        ];
      } else {
        return formattedInclusionsOrExclusions[inclusionType].push({
          id: uuid(),
          index: bundleIndex,
          type: inclusionType,
          values: {
            operation: inclusion.operation,
            value: inclusion.value,
          },
        });
      }
    } else if (inclusionType === 'product') {
      // eslint-disable-next-line dot-notation
      if (!formattedInclusionsOrExclusions['product']) {
        // eslint-disable-next-line dot-notation
        formattedInclusionsOrExclusions['product'] = [
          {
            id: uuid(),
            index: bundleIndex,
            type: inclusionType,
            values: {
              product: {
                value: inclusion.value,
              },
            },
          },
        ];
      } else {
        return formattedInclusionsOrExclusions[
          // eslint-disable-next-line dot-notation
          'product'
        ][0]?.values?.product?.value.push(inclusion.value);
      }
    } else if (inclusionType === 'brand') {
      // eslint-disable-next-line dot-notation
      if (!formattedInclusionsOrExclusions['brand']) {
        // eslint-disable-next-line dot-notation
        formattedInclusionsOrExclusions['brand'] = [
          {
            id: uuid(),
            index: bundleIndex,
            type: inclusionType,
            values: {
              brand: {
                value: inclusion.brand,
              },
            },
          },
        ];
      } else {
        return formattedInclusionsOrExclusions[
          // eslint-disable-next-line dot-notation
          'brand'
        ][0]?.values?.brand?.value.push(inclusion.brand);
      }
    } else if (inclusionType === 'custom') {
      const id = uuid();
      formattedInclusionsOrExclusions[`${inclusionType}-${id}`] = [
        {
          id,
          index: bundleIndex,
          type: inclusionType,
          values: {
            parameter: inclusion.parameter,
            operation: inclusion.operation,
            value: inclusion.value,
            otherValue: inclusion.otherValue,
          },
        },
      ];
    }
  });

  return formattedInclusionsOrExclusions;
};
