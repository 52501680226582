import { userActions } from '../../actions';
import { appEnv, getApiUrl, getGandalfUrl } from '../../constants/config';
import { $http, plainGet } from '../../system/ajax';
import api, { getRestEndPoint } from '../../system/api';
import {
  getEndOfLastWeek,
  getFirstDayOfWeek,
  getNow,
  getStartOfLastWeek,
  queryDate,
} from '../../system/date';
import { redirectAuth } from '../auth/require-auth';
import { getAccountId, getApiKey, getToken, getXmlUrl } from '../auth/user';

export const getPages = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  plainGet(api.getEndPoint('account/page') + globalApiKey, callback);
};

export const getCategories = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getEndPoint('account/category') + globalApiKey)
    .get({}, { preventAbort: false })
    .then(callback);
};

export const getBrands = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getEndPoint('account/brand') + globalApiKey)
    .get({}, { preventAbort: false })
    .then(callback);
};

export const getLabels = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getEndPoint('account/label') + globalApiKey)
    .get({}, { preventAbort: false })
    .then(callback);
};

export const getUserSegments = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getRestEndPoint('segmentation/list') + globalApiKey)
    .get({}, { preventAbort: false })
    .then(callback);
};

export const getAuthorizedMailUsers = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(
    api.getRestEndPoint('emails/authorizedTotalUserCount') + globalApiKey,
  )
    .get({}, { preventAbort: false })
    .then(callback);
};

export const getTestUsers = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getRestEndPoint('emails/testUsers') + globalApiKey)
    .get({}, { preventAbort: false })
    .then(callback);
};

export const getCampaingsList = (data, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getRestEndPoint('emails/getCampaigns') + globalApiKey)
    .post(data, { type: 'json' })
    .then(callback);
};

export const getCampaingCounts = (data, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getRestEndPoint('emails/getCounts') + globalApiKey)
    .post(data, { type: 'json' })
    .then(callback);
};

export const createTestUsers = (data, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getRestEndPoint('emails/testUsers') + globalApiKey)
    .post(data, { type: 'json' })
    .then(callback);
};

export const updateTestUsers = (data, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getRestEndPoint('emails/testUsers') + globalApiKey)
    .put(data, { type: 'json' })
    .then(callback);
};

export const getSegmentStats = (segmentName, include, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getRestEndPoint('segmentation/stats') + globalApiKey)
    .get({ segmentName, include }, { preventAbort: false })
    .then(callback);
};

export const getAllCustomSegment = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint('account/segment') + globalApiKey)
    .get({}, { preventAbort: false })
    .then(response => {
      callback(response);
    });
};

export const getSegmentationList = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getRestEndPoint('segmentation/list') + globalApiKey)
    .get({}, { preventAbort: false })
    .then(callback);
};

export const getReachableUser = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getEndPoint('push/reachableUsers') + globalApiKey)
    .get({}, { preventAbort: false })
    .then(callback);
};

export const getSegmentUser = (segment, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getEndPoint('push/segmentUsers') + globalApiKey)
    .get({ segment }, { preventAbort: false })
    .then(callback);
};

export const getBannerGroups = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  plainGet(api.getEndPoint('account/banner/group') + globalApiKey, callback);
};

export const getBannerTitles = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  plainGet(api.getEndPoint('account/banner/title') + globalApiKey, callback);
};

export const getIntegrations = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  plainGet(api.getEndPoint('integration/all') + globalApiKey, callback);
};

export const checkIntegrations = () => {
  getIntegrations(response => {
    if (
      response.status &&
      (response.status === 'FAIL' || response.status !== 200)
    ) {
      if (getToken() === 'undefined') {
        if (appEnv() === 'dev' || appEnv() === 'search') {
          window.location = '/login';
        } else {
          redirectAuth(2, '/'); // mode=2, redirect=/
        }
      }
    } else {
      if (
        response &&
        Object.keys(response) &&
        Object.keys(response).length < 1 &&
        window.userGuiding
      ) {
        window.userGuiding.track('segment', {
          dashboardType: 'addCode',
        });
      }
      userActions.userIntegrations(response);
    }
  });
};

export const getUsers = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  plainGet(api.getAdminEndPoint('accounts/users') + globalApiKey, callback);
};

export const getAccounts = (options, callback) => {
  if (options) {
    const defaultOpts =
      getApiKey() !== undefined ? { apiKey: getApiKey() } : {};
    const newOptions = { ...defaultOpts, ...options };
    $http(api.getAdminEndPoint('accounts/list'))
      .get(newOptions)
      .then(callback);
  } else {
    const globalApiKey =
      getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
    plainGet(api.getAdminEndPoint('accounts/list') + globalApiKey, callback);
  }
};

export const getOwners = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  plainGet(api.getAdminV3EndPoint('accounts/owners') + globalApiKey, callback);
};

export const getMonitorAccounts = (data, options, callback) => {
  const opts = { ...options };
  let uri = '?';
  let argCount = 0;

  for (const key in opts) {
    if (opts.hasOwnProperty(key)) {
      if (argCount++) {
        uri += '&';
      }
      uri += `${encodeURIComponent(key)}=${encodeURIComponent(opts[key])}`;
    }
  }
  $http(api.getAdminEndPoint('accounts/monitor') + uri)
    .post(data, { type: 'json' })
    .then(callback);
};

export const getMonitoringAlerts = callback => {
  $http(api.getAdminV3EndPoint('accounts/monitoringAll'), callback)
    .get()
    .then(callback);
};

export const getIndividualMonitoringAlert = (accountId, callback) => {
  const accId = `?accountId=${accountId}`;
  $http(api.getAdminV3EndPoint('accounts/monitoringInd') + accId, callback)
    .get()
    .then(callback);
};

export const getUserAccounts = (options, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint('admin/prod/userAccounts') + globalApiKey)
    .get(false, options)
    .then(callback);
};

export const getMultiUserUsers = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint('admin/prod/multiUserUsers') + globalApiKey)
    .get(false)
    .then(callback);
};

export const createMultiUser = (account, data, callback) => {
  $http(api.getAccountRestEndPoint(account, 'users/create'))
    .post(data, { type: 'json' })
    .then(callback);
};

export const editMultiUser = (data, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint('admin/prod/editMultiUser') + globalApiKey)
    .post(data, { type: 'json' })
    .then(callback);
};

export const deleteMultiUser = (data, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint('admin/prod/deleteMultiUser') + globalApiKey)
    .get(data, { type: 'json' })
    .then(callback);
};

export const deleteLockedUser = (data, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint('admin/prod/deleteLockedUser') + globalApiKey)
    .get(data, { type: 'json' })
    .then(callback);
};

export const getIYSInfo = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getRestEndPoint('account/iys/getInfo') + globalApiKey, callback)
    .get()
    .then(callback);
};

export const getIYSSettings = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getRestEndPoint('account/iys/getSync') + globalApiKey, callback)
    .get()
    .then(callback);
};

export const createIYSSettings = (data, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getRestEndPoint('account/iys/create') + globalApiKey)
    .post(data, { type: 'json' })
    .then(callback);
};

export const saveNoUpdateFields = (data, callback) => {
  fetch(`${api.getRestEndPoint('account/noUpdate')}/${getAccountId()}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(data),
  })
    .then(response => response.text())
    .then(response => {
      callback(response);
    });
};

export const getMailMergeTags = async () => {
  const url = `${api.getRestEndPoint(
    'emails/getMergeTags',
  )}?apiKey=${getApiKey()}`;

  const response = await $http(url).get();
  return await response;
};

export const createMailMergeTags = async data => {
  const url = `${api.getRestEndPoint(
    'emails/createMergeTags',
  )}?apiKey=${getApiKey()}`;

  const response = await $http(url).post(data, { type: 'json' });
  return await response;
};

export const deleteMailMergeTags = async data => {
  const url = `${api.getRestEndPoint(
    'emails/deleteMergeTags',
  )}?mergeTag=${data}&apiKey=${getApiKey()}`;

  const response = await $http(url).delete();
  return await response;
};

export const updateMailMergeTags = async data => {
  const url = `${api.getRestEndPoint(
    'emails/updateMergeTags',
  )}?apiKey=${getApiKey()}`;

  const response = await $http(url).put(data, { type: 'json' });
  return await response;
};

export const syncIYSSettings = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getRestEndPoint('account/iys/sync') + globalApiKey)
    .post({ type: 'json' })
    .then(callback);
};

export const getAccountStats = (options, callback) => {
  const defaults = {
    reverse: true,
    accountId: '',
    sort: 'ratioAmount',
    start: queryDate(getFirstDayOfWeek()),
    end: queryDate(getNow()),
  };

  const newOptions = { ...defaults, ...options };

  $http(api.getAdminEndPoint('accounts/stats'))
    .get(newOptions)
    .then(callback);
};

export const getGaAccountStats = (options, callback) => {
  const defaults = {
    reverse: true,
    accountId: '',
    sort: 'revenueRatioALL',
    start: queryDate(getStartOfLastWeek()),
    end: queryDate(getEndOfLastWeek()),
  };

  const newOptions = { ...defaults, ...options };

  $http(api.getAdminEndPoint('accounts/gastats'))
    .get(newOptions)
    .then(callback);
};

export const createUser = (data, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint('admin/create/user') + globalApiKey)
    .post(data, { type: 'json' })
    .then(callback);
};

const enableAnalytics = (analytic, account, callback) => {
  plainGet(
    api
      .getAccountRestEndPoint(account, `analytics/enable/${analytic}`)
      .replace('{accountId}', account.accountId),
    callback,
  );
};

export const enableTrendify = (account, callback) =>
  enableAnalytics('trendify', account, callback);

export const enableBannerify = (account, callback) =>
  enableAnalytics('bannerify', account, callback);

export const archiveAccount = (account, callback) => {
  plainGet(
    api
      .getAccountRestEndPoint(account, 'account/archive')
      .replace('{accountId}', account.accountId),
    callback,
  );
};

export const updateAccount = (data, callback, options) => {
  let endpoint = api.getAccountRestEndPoint(data, 'account/update');
  if (options && options.isSwitchedUser === true) {
    endpoint = `${endpoint}?apiKey=${getApiKey()}`;
  }
  $http(endpoint)
    .post(data, { type: 'json' })
    .then(callback);
};

export const getNotifications = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint('admin/notifications/load') + globalApiKey)
    .get(false)
    .then(callback);
};

export const readNotifications = (notificationId, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(
    `${api.getEndPoint(
      'admin/notifications/read',
    )}/${notificationId}${globalApiKey}`,
  )
    .get(false)
    .then(callback);
};

export const createNewAccount = (dataCenter, query, transferUser, callback) => {
  $http(`${api.getRestPath()}add/account?transferUser=${transferUser}`)
    .post(query, { type: 'json' })
    .then(callback);
};

export const getSalesManagers = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getRestEndPoint('account/salesManagers') + globalApiKey)
    .get(false)
    .then(callback);
};

export const getUserMailList = (options, callback) => {
  const opt = { switchUser: options.mainUser };
  const globalApiKey = `?apiKey=${options.apiKey}`;

  $http(api.getRestEndPoint('account/userMailList') + globalApiKey, opt)
    .get(false)
    .then(callback);
};

export const getFeedInfo = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  plainGet(api.getRestEndPoint('account/feed') + globalApiKey, callback);
};

export const triggerXML = callback => {
  $http(getXmlUrl())
    .get(false)
    .then(callback);
};

export const getLoginDetails = (accountId, callback) =>
  $http(`${api.getAdminEndPoint('accounts/loginInfo')}?accountId=${accountId}`)
    .get()
    .then(callback);

export const activateTrendify = (
  switchUser,
  apiKey,
  dataCenterUrl,
  callback,
) => {
  $http(
    `${api.getRestEndPoint(
      'account/trendifyactivate',
      dataCenterUrl,
    )}?apiKey=${apiKey}`,
  )
    .post({}, { type: 'json', preventAbort: false, switchUser })
    .then(response => {
      callback(response);
    });
};

export const activatePalantir = (switchUser, apiKey, callback) => {
  $http(`${api.getRestEndPoint('account/palantirActivate')}?apiKey=${apiKey}`)
    .post({}, { type: 'json', preventAbort: false, switchUser })
    .then(response => {
      callback(response);
    });
};

const getShorteningTextAccountOwnerType = type => {
  const types = {
    All: '',
    'Sales Manager': 'SM',
    'Onboarding Manager': 'OBM',
    'Onboarding Developer': 'OBD',
    'Account Manager': 'ACM',
    'Account Developer': 'ACD',
    'Agent User': 'AGU',
  };
  return types[type];
};

export const getAccountTypesMap = typeText => {
  const types = {
    'Pre-POC': 'PRE_POC',
    POC: 'POC',
    Paying: 'PAYING',
    Staging: 'STAGING',
    Onhold: 'ONHOLD',
    Freemium: 'FREEMIUM',
    Failed: 'FAILED',
    Churn: 'CHURN',
    RESTRICTED: 'PANEL_RESTRICTED',
  };
  if (!typeText || typeText === '' || typeText.toUpperCase() === 'ARRAY') {
    return Object.values(types);
  }
  if (
    !typeText ||
    typeText === '' ||
    typeText.toUpperCase() === 'ALL' ||
    !types[typeText]
  ) {
    return types;
  }

  return { [typeText]: types[typeText] };
};

export const getAlertsAccountTypesMap = typeText => {
  const types = {
    POC: 'POC',
    Paying: 'PAYING',
  };
  if (!typeText || typeText === '' || typeText.toUpperCase() === 'ARRAY') {
    return Object.values(types);
  }
  if (
    !typeText ||
    typeText === '' ||
    typeText.toUpperCase() === 'ALL' ||
    !types[typeText]
  ) {
    return types;
  }

  return { [typeText]: types[typeText] };
};

export const getAccountTierMap = tierText => {
  const tiers = {
    'Extra Large': 'Extra Large',
    Large: 'Large',
    Medium: 'Medium',
    Small: 'Small',
    'Extra Small': 'Extra Small',
    Freemium: 'Freemium',
  };
  if (
    !tierText ||
    tierText === '' ||
    tierText.toUpperCase() === 'ALL' ||
    !tiers[tierText]
  ) {
    return tiers;
  }
  return { [tierText]: tiers[tierText] };
};

export const getAccountAllOwners = (
  requestParameter,
  addOwnerTypeShortening,
  callback,
) => {
  const transform = owners =>
    owners.map(owner => ({
      name: 'owner',
      value: owner.username,
      label:
        owner.displayName +
        (addOwnerTypeShortening &&
          owner.type &&
          ` (${getShorteningTextAccountOwnerType(owner.type)})`),
    }));
  $http(api.getAdminV3EndPoint('accounts/owners'))
    .get()
    .then(response => {
      callback(transform(response));
    });
};

export const getAccountAllIndustries = callback => {
  const transform = industries =>
    industries.map(industry => ({
      name: 'industry',
      value: industry,
      label: industry,
    }));
  $http(api.getAdminV3EndPoint('accounts/industries'))
    .get()
    .then(response => {
      callback(transform(response));
    });
};

export const getAccountTypes = (typeText, isAlertType = false) => {
  const transform = types =>
    types.map(type => ({ name: 'type', value: type, label: type }));
  if (!isAlertType) {
    return transform(Object.keys(getAccountTypesMap(typeText)));
  }
  return transform(Object.keys(getAlertsAccountTypesMap(typeText)));
};

export const getAccountTiers = () => {
  const transform = tiers =>
    tiers.map(tier => ({ name: 'tier', value: tier, label: tier }));
  return transform(Object.keys(getAccountTierMap()));
};

export const updateRecommendationSettings = (updatedData, callback) => {
  $http(
    `${api.getRestEndPoint(
      'account/recommendationSettings',
    )}?apiKey=${getApiKey()}`,
  )
    .post(updatedData, { type: 'json' })
    .then(function(response) {
      callback(response);
    })
    .catch(function(error) {
      callback(error);
    });
};

export const getProductDetailsAsync = async ({ accountId, productId }) =>
  await $http(
    `${getRestEndPoint('settings/product')}/${accountId}?productId=${encodeURI(
      productId,
    )}`,
  ).get(false);

export const getProductDetails = (accountId, productId, callback) => {
  $http(
    `${getRestEndPoint('settings/product')}/${accountId}?productId=${encodeURI(
      productId,
    )}`,
  )
    .get(false)
    .then(callback);
};

export const getProductRelations = (accountId, productId, callback) => {
  $http(
    `${getRestEndPoint('settings/relation')}/${accountId}?productId=${encodeURI(
      productId,
    )}`,
  )
    .get(false)
    .then(callback);
};

export const getUserDetails = (dataCenter, accountId, userId, callback) => {
  $http(
    `${getGandalfUrl(dataCenter)}user/${accountId}?userId=${encodeURI(userId)}`,
  )
    .get(false)
    .then(callback);
};

export const getUserDetailsAsync = async ({
  dataCenter,
  accountId,
  userId,
}) => {
  const response = $http(
    `${getApiUrl(dataCenter)}user/${accountId}?userId=${encodeURI(userId)}`,
  ).get(false);
  return await response;
};

export default {
  getPages,
  getBrands,
  getUserSegments,
  getCategories,
  getBannerGroups,
  getBannerTitles,
  getIntegrations,
  getUsers,
  getAccounts,
  getUserAccounts,
  getMultiUserUsers,
  createMultiUser,
  editMultiUser,
  deleteMultiUser,
  getAccountStats,
  getGaAccountStats,
  createUser,
  enableTrendify,
  enableBannerify,
  archiveAccount,
  updateAccount,
  getNotifications,
  readNotifications,
  createNewAccount,
  activateTrendify,
  activatePalantir,
  getSalesManagers,
  getOwners,
  getAllCustomSegment,
  getUserMailList,
  getIYSSettings,
  createIYSSettings,
  syncIYSSettings,
  getIYSInfo,
};
