import React from 'react';
import PropTypes from 'prop-types';
import PinWizardCard from './PinWizardCard';
import ReplaceProductModal from './ReplaceProductModal';
import { facetedSearchRequest } from 'actions/faceted-search';
import Icons from 'components/icons';
import { t } from 'system/ui';
import { getAccount } from 'modules/auth/user';
import { uiActions } from 'actions';
import classnames from 'classnames';

export default class RulesetPinWizard extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      loading: true,
      searchQuery: this.props.searchQuery,
      pins: this.props.pins,
      hides: this.props.hides,
      products: [],
      showReplaceModal: false,
      productIndexes: [],
      replacedProduct: null,
    };
  }

  componentDidMount() {
    this.sendSearchRequest();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.searchQuery !== this.state.searchQuery) {
      this.triggerSearchRequest();
    }
  }

  onChange = e => {
    this.setState({
      searchQuery: e.target.value,
    });
  };

  sendSearchRequest = () => {
    this.setState({
        loading: true,
        replacedProduct: null,
    }, () => {
        const instanceId = `&instanceId=${this.props.isInstant ? 'SEARCH' : this.props.campaign}`;
        const query = `&query=${this.state.searchQuery}`;
        const account = getAccount();
        const language = `&language=${account.mainLanguage}`;
        const order = this.props.ruleSetDefaultOrder.defaultOrderKey
          ? `&order=${this.props.ruleSetDefaultOrder.defaultOrderKey}`
          : '';
        const type = `&searchType=${this.props.isInstant ? 'instant' : 'faceted'}`;
        const queryParams = `${instanceId}${query}${language}${order}${type}`;
        let requestBody = {
          pins: this.state.pins,
          hides: this.state.hides,
          boostFields: this.props.boostFields,
        };
        if (this.props.ruleSetDefaultOrder.defaultOrderKey) {
          requestBody = {
            orders: this.props.ruleSetDefaultOrder.order,
            ...requestBody,
          };
        }
        facetedSearchRequest('ruleset/pinWizardSearch', null, queryParams)
          .post(requestBody, { type: 'json' })
          .then(response => {
            if (Array.isArray(response?.products)) {
              this.setState({
                loading: false,
                products: response.products,
                productIndexes: this.setProductIndexes(
                  response.products,
                  this.state.hides,
                ),
              });
            }
          })
          .catch(err => {
            this.setState({
              loading: false,
              products: [],
              productIndexes: [],
            });
          });
    });
  };

  triggerSearchRequest = () => {
    // Clears running timer and starts a new one each time the user types
    clearTimeout(this.timer);
    this.timer = setTimeout(this.sendSearchRequest, 500);
  };

  setProductIndexes = (products, hides) => {
    const productIndexes = [];
    let numberOfHidesSoFar = 0;
    products.forEach((product, index) => {
      if (hides.includes(product.productId)) {
        numberOfHidesSoFar += 1;
      } else {
        productIndexes.push({
          productId: product.productId,
          index: index - numberOfHidesSoFar + 1, // + 1 is to start indexes from 1.
        });
      }
    });
    return productIndexes;
  };

  setProductPin = (product, isPinned) => {
    this.setState(prevState => {
      let pins = [...prevState.pins];
      // below condition is for the case where onAddClick is called with hidden product
      if (!prevState.hides.includes(product.productId)) {
        if (isPinned) {
          pins = prevState.pins.filter(
            pin => pin.elementId !== product.productId,
          );
        } else {
          const foundProductIndex = prevState.productIndexes.find(
            productIndex => productIndex.productId === product.productId,
          );
          // if there is already a pinned product in that index
          // (ex. out of stock products or with pin position outside of page size), remove their pins
          // TODO: here filter removed to show pinned products while
          // TODO: searchandising settings out of stock pinned product selected
          // pins = pins.filter(pin => pin.position !== foundProductIndex.index);
          // add product as pinned.
          pins = [
            ...pins,
            {
              position: foundProductIndex.index,
              elementId: product.productId,
            },
          ];
        }
      }
      return {
        pins,
      };
    });
  };

  replaceProductModalToggle = product => {
    this.setState(prevProps => {
      return {
        showReplaceModal: !prevProps.showReplaceModal,
        replacedProduct: product,
      };
    });
  };

  onAddClick = product => {
    // if product is already pinned, then show error message.
    if (this.state.pins.find(pin => pin.elementId === product.productId)) {
      uiActions.showErrorNotification(() => <span>Product is already pinned.</span>);
      return;
    }
    this.setState(
      prevState => {
        let productsCopy = [...prevState.products];
        const index = productsCopy.findIndex(
          _product =>
            _product.productId === prevState.replacedProduct?.productId,
        );
        const duplicatedProductIndex = productsCopy.findIndex(
          _product => _product.productId === product.productId,
        );

        if (prevState.replacedProduct !== null) {
          // already existing item replaced
          const temp = productsCopy[index];
          productsCopy[index] = product;
          // to prevent duplicated products if any, swap them.
          (duplicatedProductIndex !== -1) && (productsCopy[duplicatedProductIndex] = temp);
        } else {
          // add product is clicked
          // to prevent duplicated products if any, remove them.
          (duplicatedProductIndex !== -1) && (productsCopy.splice(duplicatedProductIndex, 1));
          productsCopy = [product, ...productsCopy];
        }

        const productIndexes = this.setProductIndexes(
          productsCopy,
          prevState.hides,
        );
        return {
          replacedProduct: null,
          products: productsCopy,
          showReplaceModal: false,
          productIndexes,
          pins: this.reorganizePinsPositions(prevState.pins, productIndexes),
        };
      },
      () => this.setProductPin(product, false),
    );
  };

  reorganizePinsPositions = (pins, productIndexes) => {
    const temp = [];
    // visibles on screen
    pins
      .filter(pin => productIndexes.findIndex(productIndex => productIndex.productId === pin.elementId) !== -1)
      .forEach(visible =>
        temp.push({
          ...visible,
          position: productIndexes.find(
            productIndex => productIndex.productId === visible.elementId,
          ).index,
        })
      );
    // non-visibles and not occupying space of visibles.
    pins
      .filter(pin => {
        return productIndexes.findIndex(productIndex => productIndex.productId === pin.elementId) === -1
          && temp.findIndex(temppin => temppin.position === pin.position) === -1
      })
      .forEach(nonvisible => temp.push(nonvisible));
    return temp;
  };

  onHideClick = product => {
    this.setState(prevState => {
      let hidesCopy = [...prevState.hides];
      let pinsCopy = [...prevState.pins];
      const productShown = hidesCopy.find(hide => hide === product.productId);
      pinsCopy = pinsCopy.filter(pin => pin.elementId !== product.productId); // if product is pinned, remove it
      // show condition
      if (productShown) {
        hidesCopy = hidesCopy.filter(hide => hide !== product.productId);
      } else {
        // hide condition
        hidesCopy = [product.productId, ...hidesCopy];
      }
      const productIndexes = this.setProductIndexes(
        prevState.products,
        hidesCopy,
      );
      return {
        hides: hidesCopy,
        pins: this.reorganizePinsPositions(pinsCopy, productIndexes),
        productIndexes,
      };
    });
  };

  getPinWizardCard = product => {
    const isPinned = this.state.pins.find(pin => pin.elementId === product?.productId) !== undefined;
    const isHidden = this.state.hides.find(hide => hide === product?.productId) !== undefined;
    return (
      <PinWizardCard
        product={product}
        replaceProductModalToggle={this.replaceProductModalToggle}
        onHideClick={this.onHideClick}
        isHidden={isHidden}
        buttons={[
          {
            label: isPinned ? 'Pinned' : 'Pin',
            className: isPinned ? 'pinned' : 'pin',
            onClick: () => this.setProductPin(product, isPinned),
          },
          {
            label: 'Replace',
            className: `replace ${isPinned ? 'disabled' : ''}`,
            onClick: () => !isPinned && this.replaceProductModalToggle(product),
          },
        ]}
      />
    );
  };

  getRows = () => {
    const rows = [];
    let row = [];
    [null, ...this.state.products].forEach((product, index) => {
      if (index % 4 === 0) {
        (index !== 0) && rows.push(<div className='pin-wizard-row'>{[...row]}</div>);
        row = [this.getPinWizardCard(product)];
      } else {
        row.push(this.getPinWizardCard(product));
      }
    });
    if (row.length !== 0) {
      rows.push(<div className='pin-wizard-row'>{[...row]}</div>);
    }
    return rows;
  };

  getHeader = () => {
    return (
      <div className='pin-wizard-header'>
        <div className='pin-wizard-header-icon'><Icons name='search'/></div>
        <div className='pin-wizard-header-input-container'>
          <input
            type='text'
            value={this.state.searchQuery}
            onChange={this.onChange}
          />
        </div>
        <button
          type='button'
          className='discard'
          onClick={this.props.discard}
        >
          {t('Discard')}
        </button>
        <button
          type='button'
          className='save'
          onClick={() => this.props.save(this.state.pins, this.state.hides)}
        >
          {t('Save')}
        </button>
      </div>
    );
  };

  render() {
    const { showReplaceModal, loading } = this.state;
    return (
      <div className='rule-set-pin-wizard'>
        {this.getHeader()}
        <div className={classnames('product-insights faceted-search', {'page-progress': loading})}>
          <div className='product-insights-details'>
            {this.getRows()}
          </div>
        </div>
        {showReplaceModal &&
          <ReplaceProductModal
            onAddClick={this.onAddClick}
            onCloseClick={() => this.replaceProductModalToggle(null)}
            subText='Add the products you want to pin on the search result pages'
          />
        }
      </div>
    );
  }
}

RulesetPinWizard.defaultProps = {
  orders: null,
};

RulesetPinWizard.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  pins: PropTypes.arrayOf(PropTypes.object).isRequired,
  hides: PropTypes.arrayOf(PropTypes.object).isRequired,
  discard: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  campaign: PropTypes.string.isRequired,
  ruleSetDefaultOrder: PropTypes.objectOf(PropTypes.object),
  isInstant: PropTypes.bool.isRequired,
  boostFields: PropTypes.arrayOf(PropTypes.object).isRequired,
};
