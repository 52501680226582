/**
 * Created by mehmetyurtar on 14/07/2017.
 */
import React from "react";

class ImageHolder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            fill={this.state.color}
            d="M15,1H1C0.448,1,0,1.447,0,2v12c0,0.553,0.448,1,1,1h14c0.552,0,1-0.447,1-1V2C16,1.447,15.552,1,15,1z M6,4
	c0.552,0,1,0.448,1,1c0,0.552-0.448,1-1,1C5.448,6,5,5.552,5,5C5,4.448,5.448,4,6,4z M2,12l2-4l3,2l3-4l4,6H2z"
          />
        </g>
      </svg>
    );
  }
}

export default ImageHolder;
