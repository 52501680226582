import moment from 'moment';
import React from 'react';
import classes from './ActivityList.module.scss';

const ActivityList = ({ list }) => {
  if (list?.length) {
    return (
      <div className={classes['activity-list']}>
        <div className={classes['activity-list__header']}>
          <div className={classes['activity-list__header__date_element']}>
            Date
          </div>
          <div
            className={classes['activity-list__header__campaign_name_element']}
          >
            Campaign Name
          </div>
          <div className={classes['activity-list__header__operation_element']}>
            Operation
          </div>
          <div
            className={classes['activity-list__header__email_address_element']}
          >
            Email Address
          </div>
        </div>
        <ul className={classes['activity-list__body']}>
          {list?.map(item => {
            return (
              <li className={classes['activity-list__body__item']}>
                <div
                  className={classes['activity-list__body__item__date_element']}
                >
                  {moment
                    .unix(item.createDate / 1_000)
                    .format('DD/MM/YYYY HH:mm', true)}
                </div>
                <div
                  className={
                    classes['activity-list__body__item__campaign_name_element']
                  }
                >
                  {decodeURIComponent(item.campaignName)}
                </div>
                <div
                  className={
                    classes['activity-list__body__item__operation_element']
                  }
                >
                  {item.actionType}
                </div>
                <div
                  className={
                    classes['activity-list__body__item__email_address_element']
                  }
                >
                  {item.email}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  return <div>No operations found for the specified email address.</div>;
};

export default ActivityList;
