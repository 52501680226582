/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconCross extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={this.state.width}
        height={this.state.height}
      >
        <g>
          <path
            fill={this.state.color}
            className="svg-path"
            d="M488.32,78.5L421.5,11.68c-8.91-8.91-22.27-8.91-31.18,0L250,152,109.68,11.68c-8.91-8.91-22.27-8.91-31.18,0L11.68,78.5c-8.91,8.91-8.91,22.27,0,31.18L152,250,11.68,390.32c-8.91,8.91-8.91,22.27,0,31.18L78.5,488.32c8.91,8.91,22.27,8.91,31.18,0L250,348,390.32,488.32a21.53,21.53,0,0,0,31.18,0l66.82-66.82c8.91-8.91,8.91-22.27,0-31.18L348,250,488.32,109.68C497.23,100.77,497.23,87.41,488.32,78.5Z"
            transform="translate(-4.5 -4.5)"
          />
        </g>
      </svg>
    );
  }
}

export default IconCross;
