/**
 * Created by mehmetyurtar on 3/22/18.
 */
import React from "react";

class IconAsteriks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 19"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth={1}
          fill={this.state.color}
          fillRule="evenodd"
        >
          <g
            id="Wizard-3"
            transform="translate(-282.000000, -398.000000)"
            fill={this.state.color}
            fillRule="nonzero"
          >
            <g
              id="Recommendation-Types"
              transform="translate(107.000000, 279.000000)"
            >
              <g id="Buttons" transform="translate(25.000000, 107.000000)">
                <g
                  id="Intelligent-[Button]-Copy"
                  transform="translate(136.000000, 0.000000)"
                >
                  <g id="Icon+Text" transform="translate(12.000000, 10.000000)">
                    <g
                      id="asterisk-symbol"
                      transform="translate(2.000000, 2.000000)"
                    >
                      <path
                        d="M15.804996,14.25 C15.5355256,14.7444238 15.046109,15.0177388 14.5421264,15.0177388 C14.2952332,15.0177388 14.0446985,14.9517133 13.8152844,14.8119848 L9.45860253,12.1594472 L9.45860253,17.4645224 C9.45860253,18.312106 8.80604704,19 8.00200545,19 C7.19796386,19 6.54540836,18.312106 6.54540836,17.4645224 L6.54540836,12.1586795 L2.18654158,14.8119848 C1.95712754,14.9517133 1.70659284,15.0177388 1.45969964,15.0177388 C0.956445344,15.0177388 0.466300425,14.7444238 0.196829964,14.25 C-0.205190832,13.515274 0.0329627918,12.5763294 0.729944497,12.1540731 L5.08735468,9.50076774 L0.7284879,6.84746242 C0.0315061947,6.42290286 -0.206647429,5.48549378 0.195373367,4.75076774 C0.597394163,4.0160417 1.48883158,3.76422337 2.18508499,4.18878293 L6.54322347,6.84132051 L6.54322347,1.53547761 C6.54322347,0.687893971 7.19577896,0 7.99982055,0 C8.80386214,0 9.45641764,0.687893971 9.45641764,1.53547761 L9.45641764,6.84055277 L13.8130995,4.18801519 C14.5093529,3.76345563 15.4029752,4.01527396 15.8028111,4.75 C16.2048319,5.48472604 15.9666783,6.4236706 15.2696966,6.84669468 L10.913743,9.5 L15.2704249,12.1525376 C15.9674066,12.5763294 16.2070168,13.515274 15.804996,14.25 Z"
                        id="Shape"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconAsteriks;
