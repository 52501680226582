import React from 'react';
import PropTypes from 'prop-types';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import { openUrlInNewTab } from '../../utils';

const WelcomeBanner = ({ items }) => {
  const navSvg = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 8 13'
      height='13'
      width='8'
      fill='none'
    >
      <path
        d='M0.923077 0.794883L6.5641 6.43591L0.923077 12.0769'
        stroke='#58636F'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );

  return (
    <div className='welcome-banner'>
      <Slide
        arrows
        prevArrow={<div className='custom-nav nav-prev'>{navSvg}</div>}
        nextArrow={<div className='custom-nav nav-next'>{navSvg}</div>}
        transitionDuration={500}
        indicators={i => (
          <li className='indicator'>
            <button
              type='button'
              data-key={i}
              aria-label={`Go to slide ${i + 1}`}
            />
          </li>
        )}
      >
        {items.map((item, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{ backgroundImage: `url(${item.imageURL})` }}
            className='banner-item-container'
            onClick={() => openUrlInNewTab(item.linkURL)}
          />
        ))}
      </Slide>
    </div>
  );
};

WelcomeBanner.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      imageURL: PropTypes.string.isRequired,
      linkURL: PropTypes.string,
    }),
  ).isRequired,
};

export default WelcomeBanner;
