import React, { useContext, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { Context as GlobalConfigurationContext } from './store/globalConfigurationProvider';
import GlobalConfigurationServices from './services/globalConfigurationServices';
import classes from './styles/global-configuration.module.scss';
import {
  SFYHeader,
  SFYRelatedProducts,
} from '../../../components/sfy-components/module-exports';
import {
  boostAttributes,
  searchResultsIndexAttributes,
  productPinAttributes,
  productExclusionAttributes,
} from './constants/constants';
import { t } from '../../../system/ui';
import {
  globalExclusionConverterForBackEnd,
  globalExclusionConverterForFrontEnd,
} from './utils/utils';
import { modalActions, uiActions } from '../../../actions';
import GCSearchResultsAddNew from '../../../components/sfy-components/SFYRelatedProducts/Attributes/GCSearchResultsAddNew/GCSearchResultsAddNew';

const GlobalConfiguration = ({ location }) => {
  const previousPath = location?.state?.previousPath || '';
  const { showNotification, confirmationDialog } = uiActions;

  const {
    state: { globalBoost, globalFields, productPin, globalExclusion },
    setGlobalBoost,
    setGlobalBoostSearchIsActive,
    setSearchResultsIndex,
    setProductExclusion,
    setProductPin,
    setPPSearchIsActive,
    resetSearchResultsIndex,
    setPESearchIsActive,
  } = useContext(GlobalConfigurationContext);

  const newGlobalBoostSetter = fieldName => {
    const newGlobalBoost = {
      id: uuid(),
      productAccessor: fieldName,
      value: '',
      weight: '',
    };
    setGlobalBoost([...globalBoost.boostFields, newGlobalBoost]);
  };

  const productExclusionAttributesParamsSetter = (value, type) => {
    setProductExclusion({ ...globalExclusion, [type]: value });
  };

  const productPinParamsSetter = (value, type) => {
    setProductPin({ ...productPin, [type]: value });
  };

  const validateBeforeSave = globalConfigurationState => {
    const isFilledGlobalBoost = globalConfigurationState.globalBoost.boostFields.every(
      boostField =>
        boostField.productAccessor && boostField.value && boostField.weight,
    );

    const isFilledGlobalFields = globalConfigurationState.globalFields.every(
      globalField => globalField.weight,
    );

    if (!isFilledGlobalBoost || !isFilledGlobalFields) {
      return 'Please fill all the required fields.';
    }

    return true;
  };

  const getGlobalConfigurationState = (fixedObjects = {}) => {
    const pins = productPin?.mappedProducts.map(pin => {
      return {
        position: productPin.mappedProducts.indexOf(pin) + 1,
        elementId: pin.value,
        label: pin.label,
      };
    });

    const rawExclusionData = globalExclusionConverterForBackEnd(
      globalExclusion,
    );

    const globalConfigurationState = {
      globalBoost,
      globalFields,
      globalPin: {
        activateOnSearchBox: productPin.activateOnSearchBox,
        pins,
      },
      globalExclusion: {
        activateOnSearchBox: globalExclusion.activateOnSearchBox,
        exclusions: [...rawExclusionData],
      },
      ...fixedObjects,
    };

    return globalConfigurationState;
  };

  const onSaveHandler = (fixedObjects = {}) => {
    const globalConfigurationState = getGlobalConfigurationState(fixedObjects);
    const validation = validateBeforeSave(globalConfigurationState);
    if (validation !== true) {
      const content = () => <p>{validation}</p>;
      showNotification({
        content,
        className: 'notification-fail',
      });
      return;
    }

    GlobalConfigurationServices.create({
      body: {
        ...globalConfigurationState,
      },
    }).then(response => {
      const content = () => <p>Global Configuration saved successfully.</p>;
      showNotification({
        content,
        className: 'notification-success',
      });

      // Special case for the search results index
      setSearchResultsIndex(response?.globalFields);
    });
  };

  const searchResultActionHandler = (actionType, actionItem) => {
    const globalConfigurationState = getGlobalConfigurationState();
    const validation = validateBeforeSave(globalConfigurationState);
    if (validation !== true) {
      const content = () => <p>{validation}</p>;
      showNotification({
        content,
        className: 'notification-fail',
      });
      return;
    }

    switch (actionType) {
      case 'edit': {
        const content = () => (
          <GCSearchResultsAddNew
            modalType='edit'
            data={actionItem}
            editAction={onSaveHandler}
          />
        );

        modalActions.openModal({
          title: t('Edit Search Index'),
          subtitle: t('Edit the index'),
          className: 'search-results-index-add-item-popup',
          content,
        });
        break;
      }
      case 'delete': {
        confirmationDialog({
          title: t('Delete Index?'),
          content: t('Are you sure want to delete the index?'),
          onConfirm: () => {
            const newSearchResultsIndex = globalFields.filter(
              item => item.index !== actionItem.index,
            );
            onSaveHandler({ globalFields: newSearchResultsIndex });
          },
        });
        break;
      }
      default:
        break;
    }
  };

  // Checks if global boost array has any values then updates the boostAttributes array with the values
  useEffect(() => {
    const { boostFields } = globalBoost;
    const isActiveIndex = boostAttributes?.findIndex(
      ({ name }) => name === 'urlRoute',
    );

    if (boostFields && boostFields.length > 0) {
      const checkIfActive = boostAttributes[isActiveIndex].url.isActive;
      // eslint-disable-next-line no-unused-expressions
      !checkIfActive
        ? (boostAttributes[isActiveIndex].url.isActive = true)
        : false;
    } else {
      boostAttributes[isActiveIndex].url.isActive = false;
    }
  }, [globalBoost]);

  useEffect(() => {
    if (previousPath !== '/search/global-configuration/visual-builder') {
      resetSearchResultsIndex();

      GlobalConfigurationServices.getAll().then(response => {
        const boostFieldsWithId = response?.globalBoost?.boostFields.map(
          item => ({
            ...item,
            id: uuid(),
          }),
        );
        setGlobalBoost(boostFieldsWithId);
        setGlobalBoostSearchIsActive(
          response?.globalBoost?.activateOnSearchBox,
        );

        setSearchResultsIndex(response?.globalFields);

        const productPinValues = response?.globalPin?.pins.map(item => ({
          value: item.elementId,
          label: item.label,
        }));

        setProductPin({
          activateOnSearchBox: response?.globalPin?.activateOnSearchBox,
          mappedProducts: productPinValues,
        });

        setProductExclusion({
          activateOnSearchBox: response?.globalExclusion?.activateOnSearchBox,
          ...globalExclusionConverterForFrontEnd(
            response?.globalExclusion.exclusions,
          ),
        });
      });
    }
  }, []);

  return (
    <>
      <SFYHeader
        pageTitle={t('Search & Discovery')}
        childrenAttr={{
          subTitle: t('Global Search Results Settings'),
          noStyling: t(
            'You can easily manipulate your search results independent from the search query ',
          ),
        }}
      />
      <main className={classes['global-config']}>
        <section className={classes['global-boost']}>
          <SFYRelatedProducts
            headerTitle={t('Category & Brand Weight')}
            description={t(
              'Add category or brand based boost to your search results',
            )}
            defaultComponent='BoostFields'
            defaultFunction={newGlobalBoostSetter}
            externalAttributes={boostAttributes}
            data={{
              boostFields: globalBoost.boostFields,
              activateOnSearchBox: globalBoost.activateOnSearchBox,
              productPin,
              exclusions: [
                ...globalExclusionConverterForBackEnd(globalExclusion),
              ],
            }}
            searchActiveSetter={setGlobalBoostSearchIsActive}
            isSearchActive
          />
        </section>
        <section className={classes['product-pin']}>
          <SFYRelatedProducts
            headerTitle={t('Product Pinning')}
            description={t(
              'Pin or change your products to customize search results',
            )}
            externalAttributes={productPinAttributes}
            data={productPin}
            setAttributes={productPinParamsSetter}
            searchActiveSetter={setPPSearchIsActive}
            isSearchActive
            isDraggable
          />
        </section>
        <section className={classes['product-exclusion']}>
          <SFYRelatedProducts
            headerTitle={t('Product Exclusion')}
            description={t('Exclude your products to customize search results')}
            externalAttributes={productExclusionAttributes}
            data={globalExclusion}
            setAttributes={productExclusionAttributesParamsSetter}
            searchActiveSetter={setPESearchIsActive}
            isSearchActive
          />
        </section>
        <section className={classes['search-results-index']}>
          <SFYRelatedProducts
            headerTitle={t('Search Results Index')}
            description={t('Select your index of results and ...')}
            externalAttributes={searchResultsIndexAttributes}
            data={globalFields}
            setAttributes={productExclusionAttributesParamsSetter}
            defaultComponent='GCSearchResultsIndexList'
            defaultComponentAction={searchResultActionHandler}
          />
        </section>
        <div className={classes['save-button']}>
          <button
            className='tertiary-action'
            type='button'
            onClick={() => onSaveHandler()}
          >
            {t('Save All')}
          </button>
        </div>
      </main>
    </>
  );
};

export default GlobalConfiguration;
