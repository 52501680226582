import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { switchUserActions, uiActions } from '../../actions';
import { resetSwitchRegion } from '../../actions/switchRegion';
import { getCampaigns } from '../../modules/campaigns/ajax';
import { filterStatus } from '../../modules/campaigns/filters';
import setHighchartsDefaults from '../../modules/highcharts.global';
import { getRangedReports } from '../../modules/reports/ajax';
import { accountCampaignsTransform } from '../../modules/transform/accountCampaignsTransform';
import transformTrends from '../../modules/transform/trends';
import { queryDate } from '../../system/date';
import { localeString, localeStringMoney } from '../../system/string';
import Currency from '../currency';
import { campaignTableHeaders } from './constants';

const CampaignStats = ({ range, rangeAlias, userToSwitch, account }) => {
  const [data, setData] = useState({});
  const { isLoading, isLoaded } = uiActions;
  const { updateSwitchUser } = switchUserActions;

  const editCampaign = (instanceId, page) => {
    if (userToSwitch) {
      resetSwitchRegion();
      const processedUser = {
        ...userToSwitch,
        account,
      };
      updateSwitchUser(processedUser);
      setHighchartsDefaults(processedUser);
      isLoading();
      setTimeout(() => {
        isLoaded();
        browserHistory.push(`/widget/edit/${page}/fullWidth/${instanceId}`);
      }, 500);
    } else {
      browserHistory.push(`/widget/edit/${page}/fullWidth/${instanceId}`);
    }
  };

  useEffect(() => {
    isLoading();

    getCampaigns(
      allCampaigns => {
        const liveCampaigns = filterStatus(allCampaigns, 'ACTIVE').filter(
          ({ actions }) =>
            actions &&
            actions.length &&
            actions[0].type === 'recommendProducts',
        );

        const campaignIdsToGetReport = liveCampaigns.map(
          ({ instanceId }) => instanceId,
        );

        const boundaries = {};

        boundaries.start = queryDate(range[0]);
        boundaries.end = queryDate(range[1]);
        boundaries.datelabel = rangeAlias;

        getRangedReports(
          campaignIdsToGetReport,
          boundaries,
          allReports => {
            isLoaded();
            setData(accountCampaignsTransform(liveCampaigns, allReports));
            window.dispatchEvent(new Event('resize'));
          },
          userToSwitch ? { switchUser: userToSwitch?.username } : {},
          false,
          account?.apiKey,
        );
      },
      null,
      userToSwitch ? { switchUser: userToSwitch?.username } : {},
      account?.apiKey,
    );
  }, []);

  const tableHead = campaignTableHeaders.map((header, i) => (
    <th key={i}>{header}</th>
  ));

  return (
    <div
      className='account-details campaign-stats-table'
      key={'Campaign-Details'}
    >
      <table className='data-table'>
        <thead>
          <tr>{tableHead}</tr>
        </thead>
        <tbody>
          {Object.keys(data)
            .filter(page => data[page])
            .map(page => {
              const campaigns = data[page];

              return Object.keys(campaigns).map((campaign, index) => {
                const transFormedStats = transformTrends(
                  campaigns[campaign].stats,
                  0,
                  0,
                  0,
                  0,
                  null,
                  true,
                );

                return (
                  <>
                    <tr key={campaign.instanceId}>
                      {index === 0 && (
                        <td rowSpan={Object.keys(campaigns).length}>{page}</td>
                      )}
                      <td
                        className='domain'
                        title={campaigns[campaign].campaign.scenarioName}
                        onClick={editCampaign.bind(
                          null,
                          campaigns[campaign].campaign.instanceId,
                          campaigns[campaign].page_slug,
                        )}
                      >
                        {campaigns[campaign].campaign.scenarioName}
                      </td>
                      <td>
                        <ul>
                          {campaigns[campaign].algorithms.map(algorithm => (
                            <li key={algorithm}>{algorithm}</li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        {localeString(transFormedStats.widget.stats.view.count)}
                      </td>
                      <td>
                        {localeString(transFormedStats.widget.stats.all.count)}
                      </td>
                      <td>
                        {localeString(
                          transFormedStats.widget.stats.conversion.ratio,
                        )}{' '}
                        %
                      </td>
                      <td>
                        {localeString(
                          transFormedStats.interaction.stats.all.count,
                        )}
                      </td>
                      <td>
                        {localeString(
                          transFormedStats.interaction.stats.conversion.ratio,
                        )}{' '}
                        %
                      </td>
                      <td>
                        {localeString(transFormedStats.basket.stats.all.count)}
                      </td>
                      <td>
                        {localeString(
                          transFormedStats.basket.stats.conversion.ratio,
                        )}{' '}
                        %
                      </td>
                      <td>
                        <Currency
                          currency={localeStringMoney(
                            transFormedStats.revenue.stats.all.count,
                          )}
                          accountCurrency={account.mainCurrency}
                        />
                      </td>
                      <td>
                        {localeString(
                          transFormedStats.revenue.stats.purchases.count,
                        )}
                      </td>
                      <td>
                        {localeString(
                          transFormedStats.revenue.stats.items.count,
                        )}
                      </td>
                      <td>
                        {localeString(
                          transFormedStats.revenue.stats.conversion.ratio,
                        )}{' '}
                        %
                      </td>
                      <td className='cont-ratio'>
                        {localeString(
                          transFormedStats.revenue.stats.contribution.ratio,
                        )}{' '}
                        %
                      </td>
                      <td className='diff-ratio'>
                        {transFormedStats.revenue.stats.diff.ratio}
                      </td>
                    </tr>
                    {index === Object.keys(campaigns).length - 1 && (
                      <tr style={{ height: '20px' }}>
                        <td style={{ border: 0 }} />
                      </tr>
                    )}
                  </>
                );
              });
            })}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = store => ({
  range: store.date.range,
});

export default connect(mapStateToProps)(CampaignStats);
