import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import './SFYComponents.scss';
import { getPages } from '../../../../modules/account/ajax';
import { t } from '../../../../system/ui';
import { Checkbox, CheckboxGroup } from '../../../fields';
import Icons from '../../../icons';

const Pages = ({
  pages,
  urlFilter,
  pagesSetter,
  isAddCampaign,
  isPagesUrlVisible,
}) => {
  const [values, setValues] = useState([]);
  const [label, setLabel] = useState(t('Select Pages'));
  const [showRequiredError, setShowRequiredError] = useState(false);
  const [customPages, setCustomPages] = useState([]);
  const [tempPages, setTempPages] = useState([]);
  const [filterUrls, setFilterUrls] = useState([
    { id: Math.random(), value: '' },
  ]);
  const [selectedUrlFilter, setSelectedUrlFilter] = useState('contains');
  const [showURLOptions, setShowURLOptions] = useState(false);

  const urlsRef = useRef(null);
  const urlFilterRef = useRef(null);

  const onValueChange = val => {
    setValues(val);
    pagesSetter('filters', {
      type: 'PAGE',
      includedCategories: val,
      excludedCategories: [],
    });
  };

  const onUrlValueChange = (filteredUrls, selectedUrlFilterType) => {
    if (isPagesUrlVisible) {
      const includedUrls =
        selectedUrlFilterType === 'contains'
          ? filteredUrls.filter(url => url.value).map(url => url.value)
          : [];
      const excludedUrls =
        selectedUrlFilterType === 'notContains'
          ? filteredUrls.filter(url => url.value).map(url => url.value)
          : [];

      pagesSetter('filters', {
        type: 'PAGE_URL',
        includedUrls,
        excludedUrls,
      });
    }
  };

  const onUrlFilterTypeChange = type => {
    setSelectedUrlFilter(type);
    onUrlValueChange(filterUrls, type);
  };

  const onUrlChange = (id, e) => {
    const newUrls = filterUrls.map(url => {
      if (url.id === id) {
        return { id, value: e.target.value };
      }
      return url;
    });
    setFilterUrls(newUrls);
    onUrlValueChange(newUrls, selectedUrlFilter);
  };

  const onAddUrl = e => {
    e.preventDefault();
    const newUrls = [...filterUrls, { id: Math.random(), value: '' }];
    setFilterUrls(newUrls);
    onUrlValueChange(newUrls, selectedUrlFilter);
  };

  const onRemoveUrl = id => {
    const isRemovable = filterUrls.length > 1;

    if (!isRemovable) {
      const newUrls = [{ id: Math.random(), value: '' }];
      setFilterUrls(newUrls);
      onUrlValueChange(newUrls, selectedUrlFilter);
      return;
    }

    const newUrls = filterUrls.filter(url => url.id !== id);
    setFilterUrls(newUrls);
    onUrlValueChange(newUrls, selectedUrlFilter);
  };

  const onCustomPageSearch = e => {
    const { value } = e.target;
    const searchResults = [];
    customPages.forEach(item => {
      if (item.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        searchResults.push(item);
      }
    });
    setTempPages(searchResults);
  };

  const getAllStatus = () => {
    let allIsSelected = true;
    customPages.forEach(val => {
      if (values.indexOf(val) === -1) {
        allIsSelected = false;
      }
    });

    return allIsSelected;
  };

  const validate = () => {
    if (values.length === 0) {
      setShowRequiredError(true);
    } else {
      setShowRequiredError(false);
    }
  };

  const onSelectedAll = () => {
    let newLabel = '';
    if (getAllStatus()) {
      const currentValues = tempPages;
      const newSelectedValues = [];

      values.forEach(val => {
        if (currentValues.indexOf(val) === -1) {
          newSelectedValues.push(val);
        }
        newSelectedValues.forEach(page => {
          newLabel += `${t(page)}, `;
        });
        if (newSelectedValues.length > 5) {
          newLabel = `${newSelectedValues.length} ${t('pages selected')}`;
        }
        if (newLabel.length === 0) {
          newLabel = t('Select Pages');
        }
        onValueChange(newSelectedValues);
        setLabel(newLabel);
      });
    } else if (tempPages.length === customPages.length) {
      onValueChange(customPages);
      setLabel(t('All'));
    } else {
      tempPages.forEach(page => {
        newLabel += `${t(page)}, `;
      });

      if (tempPages.length > 5) {
        newLabel = `${tempPages.length} ${t('pages selected')}`;
      }

      onValueChange(tempPages);
      setLabel(newLabel);
    }
  };

  const updateValue = newValue => {
    const selectedValues = [];
    let newLabel = '';

    newValue.forEach(page => {
      if (!selectedValues.includes(page)) {
        selectedValues.push(page);
        newLabel += `${t(page)}, `;
      }
    });

    newLabel = newLabel.slice(0, -1);

    if (newLabel.length === 0) {
      newLabel = t('Selected Pages');
    }

    if (selectedValues.length > 5) {
      newLabel = `${selectedValues.length} ${t('pages selected')}`;
    }

    if (selectedValues.length === customPages.length) {
      newLabel = t('All');
    }

    onValueChange(selectedValues);
    setLabel(newLabel);
  };

  const onChange = val => {
    updateValue(val);
  };

  const checkIsDifferent = (pageListProps = [], stateList = []) => {
    if (pageListProps.length !== stateList.length) {
      return true;
    }

    const returnVal = pageListProps?.some((item, index) => {
      return item?.instanceId !== stateList[index]?.instanceId;
    });
    return returnVal;
  };

  const checkIsDifferentUrl = (urlListProps = [], stateList = []) => {
    const stateListWithoutEmpty = stateList.filter(url => url.value !== '');
    if (urlListProps.length !== stateListWithoutEmpty.length) {
      return true;
    }

    const returnVal = urlListProps?.some(urlItem => {
      return stateList.find(url => url.value === urlItem) === undefined;
    });

    return returnVal;
  };

  const handleOutsideClick = e => {
    if (
      urlsRef.current &&
      !urlsRef.current.contains(e.target) &&
      urlFilterRef.current &&
      !urlFilterRef.current.contains(e.target)
    ) {
      setShowURLOptions(false);
    }
  };

  const updateUrlFilter = () => {
    const includedUrls = urlFilter?.includedUrls || [];
    const excludedUrls = urlFilter?.excludedUrls || [];
    const selectedUrlType =
      excludedUrls?.length > 0 ? 'notContains' : 'contains';
    const selectedUrls =
      selectedUrlType === 'contains' ? includedUrls : excludedUrls;

    if (selectedUrls.length === 0) {
      setSelectedUrlFilter('contains');
      setFilterUrls([{ id: Math.random(), value: '' }]);
    } else {
      setSelectedUrlFilter(selectedUrlType);
      setFilterUrls(
        selectedUrls.map(filter => {
          return { id: Math.random(), value: filter };
        }),
      );
    }
  };

  useEffect(() => {
    getPages(response => {
      if (isAddCampaign) {
        setCustomPages(response.sort());
        setTempPages(response.sort());
        onValueChange(response.sort());
        setLabel(t('All'));
      } else {
        setCustomPages(response.sort());
        setTempPages(response.sort());
      }
    });

    if (isPagesUrlVisible) {
      updateUrlFilter();

      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (pages?.length === 0) {
      setLabel(t('Selected Pages'));
    } else if (checkIsDifferent(pages, values)) {
      updateValue(pages);
    }
  }, [pages]);

  useEffect(() => {
    if (!urlFilter) return;

    // Only one of the filters can loaded at a time, so merged them is equal to the current filter
    const mergedFilters = [
      ...urlFilter.includedUrls,
      ...urlFilter.excludedUrls,
    ];

    if (isPagesUrlVisible && checkIsDifferentUrl(mergedFilters, filterUrls)) {
      updateUrlFilter();
    }
  }, [urlFilter]);

  useEffect(() => {
    validate();
  }, [values]);

  return (
    <div className='wizard'>
      <ol className={classNames('form-elements form-style')}>
        <div className={classNames('wizard-cards')}>
          <div className={classNames('wizard-page-selection')}>
            <span className='element-title'>{t('Pages to Show')}</span>
            <CheckboxGroup
              value={values}
              className='page-type-selection'
              onChange={onChange}
            >
              <div className='check-group'>
                <div className='check-group-header'>{label}</div>
                <div className='check-group-drop check-group-drop__search'>
                  <div className='check-group-search'>
                    <input
                      type='text'
                      placeholder={t('Search')}
                      onChange={onCustomPageSearch}
                    />
                    <i className='icon-magnify' role='presentation' />
                  </div>
                  {tempPages.length > 0 && (
                    <label
                      className={
                        getAllStatus()
                          ? 'item item-field is-checkbox is-checked'
                          : 'item item-field is-checkbox'
                      }
                    >
                      <input
                        type='checkbox'
                        checked={getAllStatus()}
                        onChange={onSelectedAll}
                      />
                      <span className='item-label'>{t('All')}</span>
                    </label>
                  )}
                  {tempPages.length ? (
                    tempPages.map(page => {
                      return <Checkbox key={page} label={page} value={page} />;
                    })
                  ) : (
                    <div>{t('No Results')}</div>
                  )}
                </div>
              </div>
            </CheckboxGroup>
            {showRequiredError ? (
              <span className='item-error'>
                {t('You should select at least one page type')}
              </span>
            ) : (
              ''
            )}

            {isPagesUrlVisible && (
              <>
                <div ref={urlFilterRef} className='check-group'>
                  <div
                    className='check-group-header'
                    onClick={() => setShowURLOptions(!showURLOptions)}
                  >
                    Select URL
                    <Icons
                      name={showURLOptions ? 'chevronUp' : 'chevronDown'}
                      color='#000'
                    />
                  </div>
                  <div
                    className='device-types check-group-drop'
                    style={{
                      display: showURLOptions ? 'block' : 'none',
                    }}
                  >
                    <button
                      type='button'
                      className='item item-field is-checkbox is-checked'
                      onClick={() => onUrlFilterTypeChange('contains')}
                    >
                      <span className='item-label' title='Desktop'>
                        Contains
                      </span>
                      <Icons
                        name={
                          selectedUrlFilter === 'contains'
                            ? 'radioSelected'
                            : 'radioEmpty'
                        }
                      />
                    </button>

                    <button
                      type='button'
                      className='item item-field is-checkbox is-checked'
                      onClick={() => onUrlFilterTypeChange('notContains')}
                    >
                      <span className='item-label' title='Mobile Web'>
                        Not Contains
                      </span>
                      <Icons
                        name={
                          selectedUrlFilter === 'notContains'
                            ? 'radioSelected'
                            : 'radioEmpty'
                        }
                      />
                    </button>
                  </div>
                </div>

                <div ref={urlsRef} className='url-field'>
                  {filterUrls.map((url, index) => {
                    return (
                      <span key={url.id}>
                        <form onSubmit={onAddUrl}>
                          <input
                            type='text'
                            defaultValue={url.value}
                            className='pageURL'
                            onChange={e => onUrlChange(url.id, e)}
                          />
                          {index === filterUrls.length - 1 && (
                            <span
                              className='pageURL-addIcon'
                              style={{ cursor: 'pointer' }}
                              onClick={onAddUrl}
                            >
                              <Icons name='plusCircle' width='16' height='16' />
                            </span>
                          )}
                          <span
                            style={{
                              cursor: 'pointer',
                              position: 'relative',
                            }}
                            onClick={() => onRemoveUrl(url.id)}
                          >
                            <Icons name='crossCircle' width='16' height='16' />
                          </span>
                        </form>
                      </span>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </ol>
    </div>
  );
};

export default Pages;
