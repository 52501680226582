import React from 'react';
import classes from './PredictiveSegments.module.scss';
import { t } from '../../../../system/ui';

const PredictiveSegmentsCard = ({ predictiveSegments }) => {
  return (
    <div className={classes['predictive-segments-container']}>
      <div className={classes['predictive-segments-header']}>
        {t('Predictive Segments')}
      </div>
      <div className={classes['predictive-segments-content']}>
        {!predictiveSegments && `${t('Coming Soon')}...`}
      </div>
    </div>
  );
};

export default PredictiveSegmentsCard;
