import useExtendedContext from '../../../../hooks/module-context/useExtendedContext';
import { CountDownReducer } from './reducers/countDownReducers';
import {
  setCountDownList,
  editActiveCountdownCampaign,
  resetActiveCountdownCampaign,
  setActiveCountdownCampaign,
} from './actions/countDownActions';
import { initialCountDown } from '../constants/constants';

const countdownState = {
  countdownCampaignList: {
    active: [],
    test: [],
    passive: [],
    completed: [],
  },
  activeCountdownCampaign: initialCountDown,
};

/**
 * @name countdownContext
 * @description Context for Countdown component. Provides access to the state and actions of the Wrapped component. Actions, state and dispatch are provided as react-hook basis.
 * @property {object} state - State of the context
 */

export const { Context, Provider } = useExtendedContext(
  CountDownReducer,
  {
    setCountDownList,
    editActiveCountdownCampaign,
    resetActiveCountdownCampaign,
    setActiveCountdownCampaign,
  },
  countdownState,
);
