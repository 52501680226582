import React, { useContext, useEffect, useState } from 'react';
import { t } from '../../../../../../system/ui';
import { SFYChildWrapper } from '../../../../../../components/sfy-components/module-exports';
import {
  InputCardWrapper,
  WofExpandableCardWrapper,
  WofSliceCard,
} from '../../../../../../components/gamification/module-exports';
import { TextField } from '../../../../../../components/fields';
import PopupStyle from '../../../../social-proof/components/PopupStyle';
import TimingFrequency from '../../../../social-proof/components/TimingFrequency';
import TargetAudience from '../../../../social-proof/components/TargetAudience';
import {
  desktopPosOptions,
  mobilePosOptions,
} from '../../../../social-proof/constants';
import { Context as GamificationContext } from '../../../store/gamificationProvider';
import Icons from '../../../../../../components/icons';
import BundleModalManager from '../../../modals/bundleModalManager';
import { modalActions } from '../../../../../../actions';
import { getInitialWOFPossibleReward } from '../../../utils/utils';

const SettingsTab = ({ mode }) => {
  const {
    state: { activeWOFDetails },
    editActiveWOFDetails,
  } = useContext(GamificationContext);

  // const [desktopPosition, setDesktopPosition] = useState(desktopPosOptions[6]);
  // const [mobilePosition, setMobilePosition] = useState(mobilePosOptions[2]);
  const [visitorSegment, setVisitorSegment] = useState({
    include: true,
    segment: '',
    type: 'SEGMENT',
  });
  const [visitorType, setVisitorType] = useState({
    include: true,
    name: '',
    type: 'VISITOR',
  });

  const editCampaign = (fieldKey, fieldValue) => {
    editActiveWOFDetails({ [fieldKey]: fieldValue });
  };

  useEffect(() => {
    const {
      // verticalPosition,
      // horizontalPosition,
      // mobileVerticalPosition = 'middle',
      // mobileHorizontalPosition = 'center',
      filters = [],
    } = activeWOFDetails;

    filters
      ?.filter(filter => filter.type === 'VISITOR')
      .forEach(filter => {
        setVisitorType(filter);
      });

    filters
      ?.filter(filter => filter.type === 'SEGMENT')
      .forEach(filter => {
        setVisitorSegment(filter);
      });

    // setDesktopPosition(
    //   desktopPosOptions.find(pos => {
    //     const posVertical = pos
    //       ?.split('&')?.[0]
    //       ?.trim()
    //       .toLowerCase();
    //     const posHorizontal = pos
    //       ?.split('&')?.[1]
    //       ?.trim()
    //       .toLowerCase();
    //     return (
    //       posVertical === verticalPosition?.toLowerCase() &&
    //       posHorizontal === horizontalPosition?.toLowerCase()
    //     );
    //   }),
    // );
    //
    // setMobilePosition(
    //   mobilePosOptions.find(pos => {
    //     const posVertical = pos?.trim().toLowerCase();
    //     const posHorizontal = 'center';
    //     return (
    //       posVertical === mobileVerticalPosition?.toLowerCase() &&
    //       posHorizontal === mobileHorizontalPosition?.toLowerCase()
    //     );
    //   }),
    // );
  }, [activeWOFDetails]);

  // const handlePositionChange = (pos, type) => {
  //   if (type === 'desktop') {
  //     setDesktopPosition(pos);
  //     editCampaign(
  //       'verticalPosition',
  //       pos
  //         ?.split('&')?.[0]
  //         ?.trim()
  //         .toLowerCase(),
  //     );
  //     editCampaign(
  //       'horizontalPosition',
  //       pos
  //         ?.split('&')?.[1]
  //         ?.trim()
  //         .toLowerCase(),
  //     );
  //   } else {
  //     setMobilePosition(pos);
  //     editCampaign('mobileVerticalPosition', pos?.trim().toLowerCase());
  //     editCampaign('mobileHorizontalPosition', 'center');
  //   }
  // };

  const handleChangeFilter = (filter, type) => {
    if (type === 'VISITOR') {
      setVisitorType(filter);
    } else {
      setVisitorSegment(filter);
    }

    const tempFilters = activeWOFDetails.filters.filter(
      filterItem =>
        filterItem.type !== 'VISITOR' && filterItem.type !== 'SEGMENT',
    );
    let shouldFilterBeAdded = false;
    if (filter.type === 'VISITOR' && filter.name !== '') {
      shouldFilterBeAdded = true;
    }
    if (filter.type === 'SEGMENT' && filter.segment !== '') {
      shouldFilterBeAdded = true;
    }
    editCampaign('filters', [
      ...tempFilters,
      ...(shouldFilterBeAdded ? [filter] : []),
    ]);
  };

  const handleDelete = index => {
    const newRewards = activeWOFDetails?.possibleRewards?.filter(
      (_, i) => i !== index,
    );
    const reSortedNewRewards = newRewards.map((reward, i) => ({
      ...reward,
      index: i,
    }));

    editCampaign('possibleRewards', reSortedNewRewards || []);
  };

  const handleChangeRewards = (type, val, index) => {
    const fixedVal = val < 0 ? 0 : val;
    const tempRewards = [...activeWOFDetails?.possibleRewards];
    tempRewards[index][type] = fixedVal;
    editCampaign('possibleRewards', tempRewards || []);
  };

  const openCodeUploadModal = () => {
    const content = () => (
      <BundleModalManager
        wofFor='edit-screen'
        wofType='UNIQUE'
        campaignType='WHEEL_OF_FORTUNE'
      />
    );

    modalActions.openModal({
      className: 'bundle-product-modal',
      content,
      dynamicHeight: true,
    });
  };

  const handleCreate = () => {
    const newRewards = [
      ...activeWOFDetails?.possibleRewards,
      getInitialWOFPossibleReward(
        activeWOFDetails?.possibleRewards?.length || 0,
      ),
    ];
    editCampaign('possibleRewards', newRewards || []);
  };

  const codeUploadButton = {
    button: (
      <div className='WofExpCardWrapper__right--code-upload'>
        <Icons name='reUpload' />
        <div className='WofExpCardWrapper__right--code-upload--text'>
          {t('Re-Upload')}
        </div>
      </div>
    ),
    onClick: openCodeUploadModal,
  };

  return (
    <SFYChildWrapper type='flexHalfColGap2'>
      <InputCardWrapper
        type='block'
        cardTitle='Campaign Title*'
        labelFor='campaignTitle'
      >
        <TextField
          name='campaignTitle'
          id='campaignTitle'
          className='item-stacked one-whole'
          required
          placeholder={t('Ex : Spin the Wheel and Get Promotions!')}
          value={activeWOFDetails?.campaignTitle}
          onChange={e => editCampaign('campaignTitle', e.target.value)}
        />
      </InputCardWrapper>
      <WofExpandableCardWrapper
        cardTitle='Coupons'
        customButtonField={
          activeWOFDetails?.gamificationType === 'UNIQUE' && mode === 'add'
            ? codeUploadButton
            : null
        }
      >
        {activeWOFDetails?.possibleRewards?.map((reward, index) => {
          const isRemovable = activeWOFDetails?.possibleRewards?.length > 4;
          const possibleRewardsTotal = activeWOFDetails?.possibleRewards?.reduce(
            (acc, curr) => acc + (curr?.possibility || 0),
            0,
          );
          const isEqualToLimit = possibleRewardsTotal === 100;
          const isReadOnly = activeWOFDetails.gamificationType === 'UNIQUE';
          return (
            <WofSliceCard
              type={activeWOFDetails?.gamificationType}
              count={index}
              cardDetail={reward}
              handleChange={handleChangeRewards}
              handleDelete={handleDelete}
              isRemovable={isRemovable}
              isEqualToLimit={isEqualToLimit}
              isReadOnly={isReadOnly}
            />
          );
        })}
        {activeWOFDetails?.possibleRewards?.length < 8 && (
          <div className='WofSliceCardAdd' onClick={handleCreate}>
            <Icons name='plus' color='#5049A9' width='10' height='10' />
            <div className='WofSliceCardAdd__text'>New Slice</div>
          </div>
        )}
      </WofExpandableCardWrapper>

      <InputCardWrapper
        type='block'
        cardTitle='Result Title*'
        labelFor='resultTitle'
      >
        <TextField
          name='resultTitle'
          id='resultTitle'
          className='item-stacked one-whole'
          required
          placeholder={t('Ex : Congratulations!')}
          value={activeWOFDetails?.resultTitle}
          onChange={e => editCampaign('resultTitle', e.target.value)}
        />
      </InputCardWrapper>

      <div id='wizard' className='wizard social-proof-form wof-special'>
        <div className='page-content-block form-content'>
          {/* <PopupStyle */}
          {/*  activeStyles={['position']} */}
          {/*  desktopPosition={desktopPosition} */}
          {/*  mobilePosition={mobilePosition} */}
          {/*  onChangeDesktopPosition={pos => */}
          {/*    handlePositionChange(pos, 'desktop') */}
          {/*  } */}
          {/*  onChangeMobilePosition={pos => handlePositionChange(pos, 'mobile')} */}
          {/* /> */}
          <TimingFrequency
            timingState={
              {
                ...activeWOFDetails?.timing,
                type:
                  activeWOFDetails?.timing?.type === 'DELAY_ALL_PAGES'
                    ? 'DELAY'
                    : activeWOFDetails?.timing?.type,
              } || {
                param: '',
                type: 'IMMEDIATE',
              }
            }
            frequencyState={
              activeWOFDetails?.frequency || {
                param: '',
                type: 'ALWAYS',
              }
            }
            delayAllPages={activeWOFDetails?.timing?.type === 'DELAY_ALL_PAGES'}
            onChangeTiming={val => editCampaign('timing', val)}
            onChangeFrequency={val => editCampaign('frequency', val)}
            onChangeDelay={val =>
              editCampaign('timing', {
                ...activeWOFDetails.timing,
                type: val ? 'DELAY_ALL_PAGES' : 'DELAY',
              })
            }
          />
          <TargetAudience
            visitorSegmentState={visitorSegment}
            visitorTypeState={visitorType}
            onChangeVisitorSegment={val => handleChangeFilter(val, 'SEGMENT')}
            onChangeVisitorType={val => handleChangeFilter(val, 'VISITOR')}
          />
        </div>
      </div>
    </SFYChildWrapper>
  );
};

export default SettingsTab;
