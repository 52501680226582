import React, { useEffect } from 'react';
import './AbActions.scss';
import { browserHistory } from 'react-router';
import { t } from '../../../system/ui';
import Icons from '../../icons';
import Icon from '../../icon';
import { modalActions, uiActions } from '../../../actions';
import { facetedSearchRequest } from '../../../actions/faceted-search';
import {
  getExperimentationList,
  getModulePath,
  getModuleByPath,
} from '../../../views/ab-test/services/abtestServices';
import CampaignHistoryModal from '../../../modules/campaigns/historymodal';

const AbActions = ({ ab, onPreview, route, abChange }) => {
  const createAction = () => {
    const actions = [
      {
        icon: 'eye',
        handler: 'previewAction',
        name: 'Preview',
        iconSvg: true,
      },
      {
        icon: 'duplicate',
        handler: 'duplicateAction',
        name: 'Duplicate',
        iconSvg: true,
      },
      {
        icon: 'history',
        handler: 'historyAction',
        name: 'History',
        iconSvg: true,
      },
      { icon: 'pie-chart', handler: 'reportAction', name: 'Report' },
    ];

    if (ab.status === 'COMPLETED') {
      actions[0].name = 'Test Detail';
      actions.push({
        icon: 'archive',
        handler: 'archiveAction',
        name: 'Archive',
      });
    }
    return actions;
  };
  const actions = createAction();

  const reportAction = () => {
    browserHistory.push({
      pathname: `/report/experimentation/${ab.id}`,
    });
  };

  const archiveAction = () => {
    const notificationContent = () => (
      <div>
        {`${t(`You have successfully archived ${ab.testName} experiment.`)} `}
      </div>
    );
    uiActions.confirmationDialog({
      title: 'Attention!',
      content: 'Are you sure you want to archive the experiment ?',
      onConfirm: () => {
        facetedSearchRequest('experimentation/update', {
          instanceId: ab.id,
        })
          .put(null, { type: 'json' })
          .then(() => {
            getExperimentationList(route, experiment => {
              abChange(experiment);
            });
            uiActions.showNotification({ content: notificationContent });
          })
          .catch(() => {
            const notificationContent = () => (
              <span>Error occurred while archiving the experiment</span>
            );
            uiActions.showErrorNotification(notificationContent);
          });
      },
    });
  };

  const duplicateAction = () => {
    browserHistory.push({
      pathname: `/${
        getModuleByPath(getModulePath(route)) === 'BT'
          ? 'behavioural-targeting'
          : 'recommendations'
      }/experimentation/ab_test/duplicate/${ab.id}`,
    });
  };

  const historyAction = () => {
    const campaignId = ab.id;
    const campaignName = ab.testName;
    const campaignHistoryModal = () => (
      <CampaignHistoryModal campaignId={campaignId} isExperiment />
    );

    modalActions.openModal({
      title: `Test History (${campaignName})`,
      content: campaignHistoryModal,
    });
  };

  const previewAction = () => {
    onPreview(ab);
  };

  const handleClick = action => {
    switch (action) {
      case 'previewAction':
        previewAction();
        break;
      case 'historyAction':
        historyAction();
        break;
      case 'archiveAction':
        archiveAction(ab);
        break;
      case 'duplicateAction':
        duplicateAction();
        break;
      case 'reportAction':
        reportAction();
        break;
      default:
        break;
    }
  };

  return (
    <li className='widget-list-item'>
      <div className='right-side-wrapper'>
        <nav className='widget-nav action-nav' aria-haspopup='true'>
          <a className='secondary-action'>{t('Actions')}</a>
          <ul className='widget-nav-popup action-nav-popup drop-up'>
            {actions.map(action => (
              <li key={action.name}>
                <a
                  className='widget-nav-popup-item action-nav-popup-item'
                  onClick={() => handleClick(action.handler)}
                >
                  {action.iconSvg ? (
                    <Icons name={action.icon} color='#fff' />
                  ) : (
                    <Icon name={action.icon} />
                  )}
                  {action.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </li>
  );
};

export default AbActions;
