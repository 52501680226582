import React, { useState, useEffect, useRef } from 'react';
import ReactHighcharts from 'react-highcharts';
import FunnelChart from 'highcharts-funnel';
import { t } from '../../system/ui';
import { calculateSegmentRatio } from '../../system/ratio';
import { createChart } from '../search/insights/utils/chart-utils';
import { formatValue, formatMoneyValue } from '../../system/string';
import { segmentPieChartConfig } from '../segmentation/utils/constant';

FunnelChart(ReactHighcharts.Highcharts);

const userChartData = {
  data: [],
  name: 'name',
  yLabel: 'count',
  yLabelName: '',
  align: 'center',
  type: 'pie',
  calculateTotalByYLabel: true,
  isPriceField: false,
  yLabelFormatConversionF: item => {
    return parseInt(item);
  },
};

const WidgetSegmentStats = ({
  segmentUser,
  otherUser,
  revenue,
  fourthValue,
  fourthTitle,
}) => {
  const [userConfig, setUserConfig] = useState({});
  const chartRef = useRef('totalUsers');

  const setPieChartConfig = (tempSegmentUser, tempOtherUser) => {
    userChartData.data = [
      { name: 'segment', count: tempSegmentUser },
      { name: 'other', count: tempOtherUser },
    ];

    return createChart(userChartData, segmentPieChartConfig);
  };

  useEffect(() => {
    const config = setPieChartConfig(segmentUser, otherUser);
    setUserConfig(config);
  }, [segmentUser, otherUser]);

  return (
    <div className='widget-stat-list'>
      <div className='widget-stats'>
        <div className='widget-stat-segmentation  stat-segmentation'>
          <ReactHighcharts config={userConfig} ref={chartRef} />
          <p className='segmentation-title-ratio'>
            <span className='segmentation-span' />
            {calculateSegmentRatio(segmentUser, segmentUser + otherUser)}%
          </p>
        </div>
        <div className='widget-stat-segmentation  stat-segmentation'>
          <h2
            className='widget-stat-title'
            style={{ textAlign: 'left', minHeight: 0 }}
          >
            {t('Segmentation')}
          </h2>

          <p className='segmentation-title' style={{ color: '#4F7398' }}>
            {formatValue(segmentUser)}
          </p>
          <p className='widget-stat-conversion' style={{ color: '#4F7398' }}>
            {t('Segmented User')}
          </p>
          <p className='segmentation-title'>
            {formatValue(segmentUser + otherUser)}
          </p>
          <p className='widget-stat-conversion'>{t('Total Reachable Users')}</p>
        </div>
        <div className='widget-stat-segmentation  stat-segmentation'>
          <p className='segmentation-title-revenue'>
            {formatMoneyValue(revenue)}
          </p>
          <p className='widget-stat-conversion'>{t('Revenue from Segment')}</p>

          <p className='segmentation-title'>{fourthValue}</p>
          <p className='widget-stat-conversion'>{t(fourthTitle)}</p>
        </div>
      </div>
    </div>
  );
};

export default WidgetSegmentStats;
