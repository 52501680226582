import React from 'react';
import './ExperimentationDefinitionPanel.scss';
import Select from 'react-select';
import { SingleDatePicker } from 'react-dates';
import { Formik } from 'formik';
import moment from 'moment';
import { withRouter } from 'react-router';
import Icon from '../../icon';
import ExperimentationVariations from './ExperimentationVariations';
import ExperimentationDefinitionStep from './ExperimentationDefinitionStep';
import ExperimentationSummary from './ExperimentationSummary';
import { clone } from '../../../system/object';
import { facetedSearchRequest } from '../../../actions/faceted-search';
import { getModuleByPath } from '../list/ExperimentationList';

export const goalOptions = [
  { value: 'CTR_VIEW', label: 'CTR(View)' },
  { value: 'CTR_IMP', label: 'CTR(Impression)' },
  { value: 'BASKET_RATE', label: 'Basket Rate' },
  { value: 'CONVERSION_RATE', label: 'Conversion Rate' },
  { value: 'AVERAGE_ORDER_CONTRIBUTION', label: 'Average Order Contribution' },
  { value: 'AVERAGE_ORDER_VALUE', label: 'Average Order Value' },
  { value: 'REVENUE_PER_VIEW', label: 'Revenue(Per View)' },
  { value: 'REVENUE_PER_CLICK', label: 'Revenue(Per Click)' },
];

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

class ExperimentationDefinitionPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: {
        testName: true,
      },
      summary: false,
      summaryValues: {},
      focused: false,
      initialValues: {
        testName: '',
        endDate: moment().add(1, 'months'),
        primaryGoal: null,
        variations: [
          {
            color: getRandomColor(),
            variationName: 'Original',
            instanceId: null,
            testSplit: 50,
          },
          {
            color: getRandomColor(),
            variationName: 'Variant 1',
            instanceId: null,
            testSplit: 50,
          },
        ],
      },
    };
  }

  componentDidMount() {
    const { params } = this.props;
    if (params.mode === 'duplicate') {
      facetedSearchRequest('experimentation/get', {
        instanceId: params.instanceId,
      })
        .get()
        .then(experiment => {
          const initialValues = { ...experiment };
          delete initialValues.id;
          delete initialValues.status;
          for (const variation of initialValues.variations) {
            let randomColor = getRandomColor();
            while (
              initialValues.variations.find(
                _variation => _variation.color === randomColor,
              )
              ) {
              randomColor = getRandomColor();
            }
            variation.color = randomColor;
          }
          initialValues.endDate = moment(initialValues.endDate);
          this.setState({
            initialValues,
          });
        });
    }
  }

  customInputIcon() {
    return <Icon name='calendar' />;
  }

  collapsableController = step => {
    this.setState(prevState => {
      const opened = { ...prevState.opened };
      if (step in opened) {
        delete opened[step];
      } else {
        opened[step] = true;
      }
      return {
        opened,
      };
    });
  };

  onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    const summaryValues = clone(values);
    summaryValues.endDate = values.endDate.format('YYYY-MM-DD');
    this.setState({
      summary: true,
      summaryValues,
    });
  };

  validate = ({ testName, endDate, primaryGoal, variations }) => {
    const errors = {};

    let total = 0;
    for (const variation of variations) {
      if (!variation.instanceId)
        errors.variations = 'You should select campaign for test split';
      total += variation.testSplit ?? 0;
    }

    if (variations.length < 2) {
      errors.variations = 'You should split the test at least two';
    }

    if (total !== 100)
      errors.variations = 'Total split ratio should add up to 100';

    if (!testName) errors.testName = 'Test property is required';

    if (!primaryGoal) errors.primaryGoal = 'Primary goal is required';

    if (!endDate || endDate.isSameOrBefore(moment()))
      errors.endDate = 'End date must after today';

    return errors;
  };

  onClose = () => {
    this.setState({
      summary: false,
      summaryValues: {},
    });
  };

  redirectToListPage = () => {
    const { router, params } = this.props;
    router.push({
      pathname: `/${params.module}/experimentation/list`,
    });
  };

  render() {
    const {
      opened,
      focused,
      summary,
      summaryValues,
      initialValues,
    } = this.state;
    const { params } = this.props;
    return (
      <>
        <Formik
          initialValues={initialValues}
          validate={this.validate}
          onSubmit={this.onSubmit}
          enableReinitialize
        >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => (
            <form onSubmit={handleSubmit}>
              <h3
                className='page-title'
                style={{ marginBottom: '0', textTransform: 'none' }}
              >
                Create an A/B Test
              </h3>
              <p>
                You can easily create an A/B Test with two or more different
                campaigns of a module via our A/B tool.
              </p>
              <div className='widget page-content-block experimentation-definition'>
                <div className='experimentation-definition-wrapper'>
                  <ExperimentationDefinitionStep
                    property='testName'
                    name='Test Name'
                    opened={opened}
                    stepCount={1}
                    touched={touched}
                    errors={errors}
                    collapsableController={this.collapsableController}
                  >
                    <div className='experimentation-definition-testName'>
                      <label id='testName'>Test Name</label>
                      <input
                        placeholder='Enter test name'
                        type='text'
                        name='testName'
                        value={values.testName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className='experimentation-input'
                      />
                    </div>
                  </ExperimentationDefinitionStep>
                  <ExperimentationDefinitionStep
                    property='primaryGoal'
                    name='Primary Goal'
                    opened={opened}
                    stepCount={2}
                    touched={touched}
                    errors={errors}
                    collapsableController={this.collapsableController}
                  >
                    <div style={{ maxWidth: '30%' }}>
                      <Select
                        placeholder='Select a goal type'
                        options={goalOptions}
                        isSearchable={false}
                        isClearable={false}
                        onChange={primaryGoal =>
                          setFieldValue(
                            'primaryGoal',
                            primaryGoal?.value ?? null,
                          )
                        }
                        onInputChange={() => setFieldTouched('primaryGoal')}
                        onMenuOpen={() => setFieldTouched('primaryGoal')}
                        value={values.primaryGoal}
                      />
                    </div>
                  </ExperimentationDefinitionStep>
                  <ExperimentationDefinitionStep
                    property='variations'
                    name='Variations'
                    opened={opened}
                    stepCount={3}
                    touched={touched}
                    errors={errors}
                    collapsableController={this.collapsableController}
                  >
                    <ExperimentationVariations
                      getRandomColor={getRandomColor}
                      module={getModuleByPath(params.module)}
                      variations={values.variations}
                      setFieldTouched={setFieldTouched}
                      setVariations={variations =>
                        setFieldValue('variations', variations)
                      }
                    />
                  </ExperimentationDefinitionStep>
                  <ExperimentationDefinitionStep
                    property='endDate'
                    name='End Date (Optional)'
                    opened={opened}
                    stepCount={4}
                    touched={touched}
                    errors={errors}
                    collapsableController={this.collapsableController}
                  >
                    <SingleDatePicker
                      date={values.endDate}
                      onDateChange={endDate =>
                        setFieldValue('endDate', endDate)}
                      focused={focused}
                      onFocusChange={event =>
                        this.setState({ focused: event.focused })
                      }
                      displayFormat='DD/MM/YYYY'
                      numberOfMonths={1}
                      customInputIcon={this.customInputIcon()}
                      hideKeyboardShortcutsPanel
                      id='experiment-end-date'
                    />
                  </ExperimentationDefinitionStep>
                  <div className='experimentation-button-container'>
                    <button
                      className='experimentation-submit-button'
                      type='submit'
                      disabled={isSubmitting}
                    >
                      Start Test
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
        {summary && (
          <ExperimentationSummary
            values={summaryValues}
            onClose={this.onClose}
            mode='confirmation'
            onFinishExperimentSuccess={this.redirectToListPage}
          />
        )}
      </>
    );
  }
}

export default withRouter(ExperimentationDefinitionPanel);
