import React from 'react';

class IconCheckCircle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: '90px',
      height: '90px',
      color: '#549A49',
      bgColor: '#fff',
      borderRadius: '0px',
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  componentWillReceiveProps() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + 'px' : this.state.width;
    let height = this.props.height
      ? this.props.height + 'px'
      : this.state.height;
    let color = this.props.color ? this.props.color : this.state.color;
    let bgColor = this.props.bgColor ? this.props.bgColor : this.state.bgColor;
    let borderRadius = this.props.borderRadius
      ? this.props.borderRadius + 'px'
      : '';

    this.setState({ width, height, color, bgColor, borderRadius });
  }

  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={this.state.width}
        height={this.state.height}
        viewBox='0 0 90 90'
        className='svg-icon'
        style={{
          backgroundColor: this.state.bgColor,
          borderRadius: this.state.borderRadius,
        }}
      >
        <path
          xmlns='http://www.w3.org/2000/svg'
          d='M45 0C69.8526 0 90 20.1472 90 45C90 69.8526 69.8526 90 45 90C20.1472 90 0 69.8526 0 45C0 20.1472 20.1472 0 45 0ZM45 6.75C23.8751 6.75 6.75 23.8751 6.75 45C6.75 66.1248 23.8751 83.25 45 83.25C66.1248 83.25 83.25 66.1248 83.25 45C83.25 23.8751 66.1248 6.75 45 6.75ZM39.375 51.4768L59.4886 31.3635C60.8067 30.0455 62.9433 30.0455 64.2614 31.3635C65.4597 32.5617 65.5686 34.4367 64.5881 35.7579L64.2614 36.1364L41.7614 58.6364C40.5634 59.8347 38.6883 59.9436 37.3671 58.963L36.9886 58.6364L25.7385 47.3864C24.4205 46.0683 24.4205 43.9317 25.7385 42.6136C26.9367 41.4153 28.8117 41.3064 30.1329 42.287L30.5115 42.6136L39.375 51.4768L59.4886 31.3635L39.375 51.4768Z'
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default IconCheckCircle;
