import React, { useRef, useState } from 'react';
import './uploadModal.scss';
import { t } from '../../../../system/ui';
import Icon from '../../../../components/icon';
import { GamificationService } from '../services/gamificationServices';
import { isLoaded, isLoading } from '../../../../actions/ui';
import { getInitialWOFPossibleReward } from '../utils/utils';

/**
 * @name UploadCoupon
 * @description xls file upload modal
 * @param {Object} onChange - onChange function
 * @param {string} type - coupon type
 * @return {JSX.Element}
 */
const UploadCoupon = ({ onChange, type }) => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileName, setFileName] = useState('');
  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = e => {
    const newFile = e.target.files[0];
    if (newFile) {
      isLoading();
      setFileName(newFile?.name);
      GamificationService(
        type === 'WHEEL_OF_FORTUNE'
          ? 'gamification/wofExcelImport'
          : 'gamification/scratchExcelImport',
        false,
      )
        .excelImport(newFile)
        .then(res => {
          const newCouponList = res.map((item, index) => ({
            ...getInitialWOFPossibleReward(index),
            ...item,
          }));
          onChange(newCouponList);
          setIsUploaded(true);
        })
        .finally(() => {
          isLoaded();
        });
    }
  };

  return (
    <div className='upload-coupon-modal'>
      <div className='upload-coupon-modal__head'>
        <div className='upload-coupon-modal__head__title'>
          {t('You can check your file format before uploading it.')}
          <a
            href={
              type === 'WHEEL_OF_FORTUNE'
                ? 'https://cdn.segmentify.com/v3/assets/WheelofFortuneUniqueCodeUploadFormat.xlsx'
                : 'https://cdn.segmentify.com/v3/assets/Scratch.xlsx'
            }
          >
            {t('See Document Format')}
          </a>
        </div>
      </div>
      <div
        className='upload-coupon-modal__input'
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {isUploaded ? (
          <>
            <div className='upload-coupon-modal__file-name'>
              <Icon name='pdf' />
              <span>{fileName}</span>
            </div>
            <div className='upload-coupon-modal__file-status'>
              <Icon name='check' />
              <span>{t('Uploaded')}</span>
            </div>
          </>
        ) : (
          <>
            <div className='upload-coupon-modal__icon'>
              {t('Upload')}
              <Icon name='upload' />
            </div>
            <span>{t('Drag and drop or browse to choose .xls file.')}</span>
          </>
        )}

        <input
          type='file'
          onChange={onFileDrop}
          accept='.xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          // accept='.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        />
      </div>
    </div>
  );
};

export default UploadCoupon;
