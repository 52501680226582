/**
 * @author Bilal Cinarli
 */

"use strict";

import { createAction } from "../system/store";

export const updateCount = count => {
  createAction("UPDATE_COUNT", { count });
};

export const updateCategories = newCategories => {
  let categories = newCategories.sort();

  createAction("UPDATE_TRENDIFY_CATEGORIES", { categories });
};

export const updateBrands = newBrands => {
  let brands = newBrands.sort();

  createAction("UPDATE_TRENDIFY_BRANDS", { brands });
};

export default {
  updateCount,
  updateBrands,
  updateCategories
};
