/**
 * Created by mehmetyurtar on 1/6/18.
 */
import React from "react";

class IconAffiliate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <g id="Layer_1_110_">
            <g>
              <path
                fill={this.state.color}
                d="M6,5.4L5,4.4C5.1,4.2,5.2,4,5.2,3.8c0-0.8-0.6-1.4-1.4-1.4S2.3,3,2.3,3.8s0.6,1.4,1.4,1.4
				C4,5.2,4.3,5.1,4.5,5l1,0.9C5.6,5.7,5.8,5.5,6,5.4z"
              />
              <path
                fill={this.state.color}
                d="M10.1,5.3l1.3-2.2c0.1,0,0.3,0.1,0.4,0.1c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4
				c-0.8,0-1.4,0.6-1.4,1.4c0,0.4,0.2,0.8,0.4,1L9.5,4.9C9.7,5,9.9,5.1,10.1,5.3z"
              />
              <path
                fill={this.state.color}
                d="M14.6,7.1c-0.6,0-1.1,0.4-1.4,0.9l-1.8-0.2c0,0,0,0,0,0c0,0.2,0,0.5-0.1,0.7l1.8,0.2
				c0.1,0.7,0.7,1.2,1.4,1.2c0.8,0,1.4-0.6,1.4-1.4C16,7.7,15.4,7.1,14.6,7.1z"
              />
              <path
                fill={this.state.color}
                d="M9.6,12.8L9.2,11c-0.2,0.1-0.5,0.1-0.7,0.2l0.4,1.8c-0.5,0.2-0.8,0.7-0.8,1.3c0,0.8,0.6,1.4,1.4,1.4
				c0.8,0,1.4-0.6,1.4-1.4C11,13.4,10.4,12.8,9.6,12.8z"
              />
              <path
                fill={this.state.color}
                d="M4.9,8.6L2.7,9.3C2.4,8.9,1.9,8.7,1.4,8.7C0.6,8.7,0,9.3,0,10.1c0,0.8,0.6,1.4,1.4,1.4
				c0.8,0,1.4-0.6,1.4-1.4c0,0,0-0.1,0-0.1l2.2-0.8C5,9,4.9,8.8,4.9,8.6z"
              />
              <circle fill={this.state.color} cx="8.1" cy="7.9" r="2.5" />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconAffiliate;
