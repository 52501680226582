import React from "react";

class IconAnalytics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({width, height, color});
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <g>
            <path
              fill={this.state.color}
              d="M10.5.5h5v15h-5z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill={this.state.color}
              d="M10.5 15.5h-5v-10h5"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill={this.state.color}
              d="M5.5 15.5h-5v-5h5"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default IconAnalytics;
