/**
 * Created by mehmetyurtar on 19/09/2017.
 */
"use strict";

import React from "react";
import { connect } from "react-redux";

import { t, confirm } from "../../system/ui";
import {
  moveCampaignToTest,
  moveCampaignToLive
} from "../../modules/push/ajax";

class PushOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLive: false
    };

    this.setIsLive = this.setIsLive.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isLive = this.isLive.bind(this);
    this.isActive = this.isActive.bind(this);
  }

  componentDidMount() {
    this.setIsLive();
  }

  setIsLive() {
    this.setState({
      isLive: this.isLive()
    });
  }

  handleChange() {
    if (this.state.isLive) {
      moveCampaignToTest(this.props.instance).then(response => {
        this.props.onChangeStatus(
          this.props.instance,
          "TEST",
          this.props.status === "ACTIVE" ? "ACTIVE" : "TEST"
        );
        this.setState(
          {
            isLive: false
          },
          () => {
            this.props.getCampaignsAfterStatusChange();
          }
        );
      });
    } else {
      moveCampaignToLive(this.props.instance).then(response => {
        this.props.onChangeStatus(
          this.props.campaign.instanceId,
          "ACTIVE",
          "ACTIVE"
        );
        this.setState(
          {
            isLive: true
          },
          () => {
            this.props.getCampaignsAfterStatusChange();
          }
        );
      });
    }
  }

  isLive() {
    return this.props.testMode === false;
  }

  isActive() {
    return this.props.status === "ACTIVE" || this.props.status === "TEST";
  }

  render() {
    return (
      <div className="widget-options">
        {this.isActive() && (
          <div>
            <label className="item is-toggle">
              <input
                type="checkbox"
                checked={this.state.isLive}
                onChange={() => {
                  confirm({ onConfirm: this.handleChange });
                }}
              />
              <span className="item-label" />
            </label>
            <span className="item-help">
              {" "}
              {t("draft")} / <span className="live">{t("live")}</span>
            </span>
          </div>
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  status: store.campaigns.status
});

export default connect(mapStatesToProps)(PushOptions);
