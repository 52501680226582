import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { getUserHistory as getUserHistoryAPI } from 'reducers/user-management/extraReducers';
import { getFormattedDetailedDate } from '../system/date';

const UserHistoryModal = ({ userName }) => {
  const [userHistory, setUserHistory] = useState(null);
  const [loading, setLoading] = useState(false);

  const getUserHistory = () => {
    setLoading(true);
    getUserHistoryAPI(userName)
      .then(response => {
        setUserHistory(response);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserHistory();
  }, []);

  if (userHistory && userHistory.length === 0) {
    return (
      <p style={{ textAlign: 'center' }}>
        No history found for user <b>{userName}</b>
      </p>
    );
  }

  if (loading) {
    return (
      <p style={{ fontWeight: 600, textAlign: 'center' }}>Loading history...</p>
    );
  }

  return (
    <table className='data-table user-history-table'>
      <thead>
        <tr>
          <th>Modified By</th>
          <th>Action</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {userHistory &&
          userHistory.map(item => {
            const isActionUpdated = item.action === 'UPDATED';
            const isActionCreated = item.action === 'CREATED';
            const isActionRemoved = item.action === 'REMOVED';
            const actionClass = cn({
              'action-updated': isActionUpdated,
              'action-created': isActionCreated,
              'action-removed': isActionRemoved,
            });
            return (
              <tr key={item.action + '' + item.updateTime}>
                <td>{item.modifiedBy}</td>
                <td className={actionClass}>{item.action}</td>
                <td>{getFormattedDetailedDate(item.updateTime)}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

UserHistoryModal.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default UserHistoryModal;
