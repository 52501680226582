"use strict";
import React, {Component} from "react";
import { connect } from "react-redux";
import {uiActions, jbActions} from "../../actions";
import JourneyBuilderRow from "./jb-row";
import {clone} from "../../system/object";
import WizardAdvancedConfig from "../wizard/advanced-config";
import {jbInitialData} from "../../constants/datamaps/journey-builder";

class JourneyBuilderCriteria extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allItems: [],
            showAdvancedConfig: false,
            campaign: props.isDelivery ? clone(jbInitialData) : {},
            jbCriteria: "",
        };
        this.jbCriteria = React.createRef();


        this.handleJBCriteriaChange = this.handleJBCriteriaChange.bind(this);
        this.showAdvancedConfig = this.showAdvancedConfig.bind(this);
        this.hideAdvancedConfig = this.hideAdvancedConfig.bind(this);
        this.handleAdvanceConfigChange = this.handleAdvanceConfigChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.isDelivery && this.props.discovery !== prevProps.discovery) {
            this.setStaticItems(this.props.discovery)
        } else if (this.props.isDelivery && this.props.delivery !== prevProps.delivery) {
            this.setStaticItems(this.props.delivery);
        }
    }

    setStaticItems(newProps) {
        let newItems = [];
        newProps.map((item) => {
            newItems.push(item);
        });
        this.setState({allItems: newItems});
    }

    showAdvancedConfig(id, campaignType) {
        let jbCriteria = "";
        if (campaignType === "jbDiscovery") {
            let criteriaList = clone(this.props.discovery);
            for (let i = 0; i < criteriaList.length; i++) {
                if (criteriaList[i] !== undefined && criteriaList[i].id === id) {
                    jbCriteria = criteriaList[i];
                    break;
                }
            }
        }
        this.setState({showAdvancedConfig: true, jbCriteria: jbCriteria});
        uiActions.formEdited();
    }

    hideAdvancedConfig() {
        this.setState({
            showAdvancedConfig: false
        });
    }

    handleJBCriteriaChange(criteriaId, selectedCategory, isDelivery) {
        let criteriaList = isDelivery ? clone(this.props.delivery) : clone(this.props.discovery);

        if (criteriaList && criteriaList.length !== 0) {
            let values = {};

            if (selectedCategory) {
                values.criterion = {
                    status: selectedCategory.active ? "ACTIVE" : "PASSIVE",
                    assetItems: selectedCategory.assetItems,
                    additionalTitles: selectedCategory.additionalTitles,
                    buttonText: selectedCategory.buttonText,
                };
                if (isDelivery) {
                    values.criterion = Object.assign(values.criterion, {coupon: selectedCategory.coupon,desc: selectedCategory.desc, pageUrl: selectedCategory.pageUrl});
                }
            }

            for (let i = 0; i < criteriaList.length; i++) {
                if (criteriaList[i] !== undefined && criteriaList[i].id === criteriaId) {
                    criteriaList[i].values = Object.assign({}, values);
                    if (criteriaList[i].properties) {
                        criteriaList[i].properties.active = selectedCategory.active;
                        criteriaList[i].properties.isDetailsHidden = selectedCategory.isDetailsHidden;
                    }
                    break;
                }
            }
            if (isDelivery) {
                jbActions.wizardDeliveryReset();
                jbActions.addDeliveryCriteria(criteriaList);
            } else {
                jbActions.wizardReset();
                jbActions.addCriteria(criteriaList);
            }
            uiActions.formEdited();
        }
    }

    handleAdvanceConfigChange(criteria) {
        let criteriaList = this.props.discovery;

        if (criteriaList && criteriaList.length !== 0) {
            for (let i = 0; i < criteriaList.length; i++) {
                if (criteriaList[i] !== undefined && criteriaList[i].id === criteria.id) {
                    if (criteriaList[i].advanceConfig) {
                        criteriaList[i].advanceConfig = criteria.advanceConfig;

                    }
                    break;
                }
            }

            jbActions.wizardReset();
            jbActions.addCriteria(criteriaList);
        }
    }

    render() {
        return (
            <div>
                <div className="wizard-items-container">
                    {this.state.allItems.map((item) => {
                        return (
                            <div key={item.id}>
                                <JourneyBuilderRow
                                    isDelivery={this.props.isDelivery}
                                    key={item.id}
                                    {...item}
                                    onJBCriteriaChange={this.handleJBCriteriaChange}
                                    account={this.props.account}
                                    showAdvancedConfig={this.showAdvancedConfig}
                                    isNewCampaign={this.props.isNewCampaign}
                                />
                            </div>
                        );
                    })}
                </div>
                <WizardAdvancedConfig
                    ref={this.jbCriteria}
                    show={this.state.showAdvancedConfig}
                    onHide={this.hideAdvancedConfig}
                    campaignType={this.props.isDelivery ? "jbDelivery" : "jbDiscovery"}
                    campaign={this.props.isDelivery ? this.props.campaign : {}}
                    isJourneyBuilder={true}
                    campaignSpecifics={this}
                    jbCriteria={this.state.jbCriteria}
                    handleAdvanceConfigChange={this.handleAdvanceConfigChange}
                />
            </div>
        );
    }
}

const mapStatesToProps = (store) => ({
    discovery: store.jbWizard.discovery,
    delivery: store.jbWizard.delivery
});

export default connect(mapStatesToProps,null,null,{ forwardRef: true })(JourneyBuilderCriteria);
