import { searchProducts } from '../../../../../modules/product/ajax';
import { decodeHtml } from '../../../../../system/string';
import { searchCategories } from '../../../../../modules/category/ajax';

export const onProductsInputChange = (value, setterFunction) => {
  if (value.length > 2) {
    searchProducts(value, response => {
      const tempOptions = [];
      if (response) {
        response.forEach(({ productId, name }) => {
          tempOptions.push({
            value: productId,
            label: decodeHtml(name),
          });
        });
      }
      setterFunction(tempOptions);
    });
    return;
  }
  setterFunction([]);
};

export const onCategoryInputChange = (value, setterFunction) => {
  if (value.length > 2) {
    searchCategories(value, response => {
      const options = [];
      if (response) {
        response.forEach(category => {
          options.push({
            value: decodeHtml(category),
            label: decodeHtml(category),
          });
        });
      }
      setterFunction(options);
    });
    return;
  }
  setterFunction([]);
};

export const fixedInputtedNumber = (min, max, value) => {
  if (!value) {
    return '';
  }
  let fixedValue = parseInt(value);

  if (value < min) {
    fixedValue = min;
  }

  if (value > max) {
    fixedValue = max;
  }

  return fixedValue;
};
