const behaviouralTargetingModules = [
  'view-all',
  'popup',
  'notification_bar',
  'form_survey',
];

const behaviouralTargetingPlusModules = [
  'gamification',
  'email_collection',
  'hero_banner',
  'persona_quiz',
  'social_proof',
  'countdown',
  'experimentation',
  'see-all',
  'engagementPlus-header',
];

const dynamicBundleModules = ['automatedBundles', 'shopTheLook'];

const audienceModules = [
  'all-segments',
  'smart-segments',
  'rule-based-segmentation',
  'dynamic-segmentation',
  'customer-profile',
];

export {
  behaviouralTargetingModules,
  behaviouralTargetingPlusModules,
  dynamicBundleModules,
  audienceModules,
};
