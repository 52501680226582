/**
 * Created by mehmetyurtar on 12/20/17.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// TODO - Causing defaultProps to be undefined, need to fix(react-highcharts version may cause).
import ReactHighcharts from 'react-highcharts';
import { calculateRatio } from '../../system/ratio';

import { localeString, localeStringMoney } from '../../system/string';
import { t } from '../../system/ui';
import { getAccountCurrency, isSwitchAccount } from '../../modules/auth/user';
import { currencyIcons } from '../../constants/datamaps/currencies';

const graphKeys = {
  'purchase:count': 'Purchases',
  'purchase:items': 'Purchased Items',
  'purchase:amount': 'Purchase Amount',
};

const getTrend = (trends, type, time) => {
  let result = false;
  trends.forEach(trend => {
    if (trend.x === graphKeys[type] && trend.y === time) {
      result = trend;
    }
  });
  return result;
};

class ContributionChart extends Component {
  constructor(props) {
    super(props);

    this.getConfig = this.getConfig.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.chartRenderCount) {
      return this.props.chartRenderCount !== nextProps.chartRenderCount;
    } else {
      return true;
    }
  }

  getCurrencyString(num) {
    let currency = getAccountCurrency(),
      symbol = null;

    if (currencyIcons[currency.code]) {
      symbol =
        '<i class="icon-' +
        currencyIcons[currency.code] +
        '" role="presentation"></i>';
    } else {
      symbol = currency.symbolBefore
        ? currency.symbol + ' '
        : ' ' + currency.symbol;
    }

    let myvar = '<span class="user-currency">';
    if (currency.symbolBefore) {
      myvar += symbol;
    }
    myvar +=
      '<span class="user-currency-amount">' +
      localeString(num, {
        currency: currency.code,
        style: 'currency',
        decimal: currency.decimalSeparator,
        thousand: currency.thousandSeparator,
        minimumFractionDigits: currency.decimals,
      }) +
      '</span>';
    if (!currency.symbolBefore) {
      myvar += symbol;
    }
    myvar += '</span>';
    return myvar;
  }

  getCurrencyTotal(firstNum, secondNum) {
    return (
      this.getCurrencyString(firstNum) +
      ' / ' +
      this.getCurrencyString(secondNum)
    );
  }

  getConfig() {
    const symbolBefore = isSwitchAccount()
      ? this.props.switchedUser.account.mainCurrency.symbolBefore
      : this.props.user.account.mainCurrency.symbolBefore;
    const symbol = isSwitchAccount()
      ? this.props.switchedUser.account.mainCurrency.symbol
      : this.props.user.account.mainCurrency.symbol;
    let getRefs = () => {
      return this.refs;
    };
    let getBiggestPercentages = this.getBiggestPercentages;
    let config;
    config = {
      title: {
        text: '',
        margin: 0,
      },
      tooltip: {
        valueSuffix: '%',
        shared: true,
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 34560000,
        labels: {
          align: 'left',
        },
      },
      yAxis: {
        title: {
          text: t('Ratio'),
        },
        min: 0,
        showFirstLabel: false,
        allowDecimals: false,
        labels: {
          formatter: function() {
            return localeString(this.value) + ' %';
          },
        },
      },
      options: {
        chart: {
          type: 'column',
          showAxes: true,
          zoomType: 'x',
          marginLeft: 75,
          spacingTop: 30,
          spacingBottom: 30,
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          borderRadius: 10,
          borderWidth: 3,
          backgroundColor: 'rgba(225, 225, 255, 0.9)',
        },
        exporting: {
          buttons: {
            contextButton: {
              y: -30,
              x: 0,
            },
          },
          chartOptions: {
            title: {
              text: 'Campaign Revenue Trends',
            },
          },
        },
      },
      loading: true,
      series: [],
      plotOptions: {
        line: {
          events: {
            legendItemClick: function(e) {
              let chart = getRefs();
              let seriesToCalculate = [];
              let clickedItemVisible = !this.visible;
              this.chart.series.forEach(serie => {
                if (serie.name !== this.name) {
                  if (serie.visible) {
                    seriesToCalculate.push(serie);
                  }
                } else {
                  if (clickedItemVisible) {
                    seriesToCalculate.push(serie);
                  }
                }
              });
              let newMaxValue = getBiggestPercentages(seriesToCalculate);
              this.yAxis.setExtremes(0, newMaxValue, true, false);
            },
          },
        },
      },
    };

    let availableFields = [
      {
        name: 'purchase:count',
        label: t('Purchases'),
        isVisible: true,
        type: 'line',
        yAxis: 0,
        zIndex: 2,
      },
      {
        name: 'purchase:items',
        label: t('Purchased Items'),
        isVisible: true,
        type: 'line',
        yAxis: 0,
        zIndex: 2,
      },
      {
        name: 'purchase:amount',
        label: t('Conribution Ratio'),
        isVisible: true,
        type: 'line',
        yAxis: 0,
        zIndex: 2,
      },
    ];

    availableFields.forEach(field => {
      if (this.props.fieldsToHide && this.props.fieldsToHide.length) {
        if (this.props.fieldsToHide.indexOf(field.name) === -1) {
          config.series.push({
            id: field.name,
            name: field.label,
            data: [],
            visible: field.isVisible,
            fillOpacity: 0.3,
            marker: {
              enabled: true,
              fillColor: '#FFFFFF',
              lineColor: null,
              lineWidth: 2,
            },
            type: field.type,
            yAxis: field.yAxis,
            zIndex: field.zIndex,
          });
        }
      } else {
        config.series.push({
          id: field.name,
          name: field.label,
          data: [],
          visible: field.isVisible,
          fillOpacity: 0.3,
          marker: {
            enabled: true,
            fillColor: '#FFFFFF',
            lineColor: null,
            lineWidth: 2,
          },
          type: field.type,
          yAxis: field.yAxis,
          zIndex: field.zIndex,
        });
      }
    });

    let allTrends = [];
    this.props.stats.forEach(stat => {
      config.series.forEach(serie => {
        if (stat.x === serie.id) {
          let ratioValue = 0;
          let trend = getTrend(this.props.trends, stat.x, stat.y);
          if (getTrend(this.props.trends, stat.x, stat.y)) {
            let trendValue = trend.z;
            ratioValue = parseFloat(((trendValue * 100) / stat.z).toFixed(2));
            allTrends.push(trend);
          }
          serie.data.push({
            x: stat.y,
            y: ratioValue,
            trend: trend ? trend.z : 0,
            stat: stat.z,
          });
        }
      });
    });

    config.series.forEach(serie => {
      serie.data.sort(function(a, b) {
        var keyA = new Date(a.x),
          keyB = new Date(b.x);
        // Compare the 2 dates
        if (keyA < keyB) {
          return -1;
        }
        if (keyA > keyB) {
          return 1;
        }
        return 0;
      });
    });

    config.xAxis.tickInterval = '';
    let timeFrame = {};
    if (this.props.customFrame) {
      timeFrame.mode = this.props.customFrame;
    } else {
      timeFrame.mode = 'monthly';
      if (this.props.range[1] && this.props.range[0]) {
        let timeDiff = this.props.range[1] - this.props.range[0];
        if (timeDiff < 24 * 60 * 60 * 1000) {
          timeFrame.mode = 'no_aggregate';
        } else if (timeDiff < 90 * 24 * 60 * 60 * 1000) {
          timeFrame.mode = 'daily';
        }
      }
    }
    switch (timeFrame.mode) {
      case 'monthly':
        config.xAxis.minTickInterval = 28 * 24 * 3600 * 1000; // 1 month
        config.xAxis.tickInterval = 30 * 24 * 3600 * 1000; // 1 month
        break;
      case 'daily':
        config.xAxis.tickInterval = 3 * 24 * 3600 * 1000; // three days
        break;
      case 'hourly':
        config.xAxis.tickInterval = 6 * 3600 * 1000; // 6 hours
        break;
      case 'no_aggregate':
        config.xAxis.tickInterval = 3600 * 1000; // one hour
        break;
      default:
        config.xAxis.tickInterval = 3 * 24 * 3600 * 1000;
    }
    config.credits = { enabled: false };
    let statTotals = {};
    let trendTotals = {};
    config.series.forEach(serie => {
      statTotals[serie.name] = 0;
      trendTotals[serie.name] = 0;
      serie.data.forEach(data => {
        statTotals[serie.name] += data.stat;
        trendTotals[serie.name] += data.trend;
      });
    });
    let getTotal = name => {
      let totals = {};
      totals.count = '';
      totals.percentage = '';
      if (name.indexOf('Amount') !== -1 || name.indexOf('Conribution') !== -1) {
        totals.count = this.getCurrencyTotal(
          trendTotals[name],
          statTotals[name],
        );
      } else {
        totals.count =
          localeString(trendTotals[name]) +
          '/' +
          localeString(statTotals[name]);
      }
      totals.percentage =
        calculateRatio(trendTotals[name], statTotals[name]) + '%';
      return totals;
    };
    config.legend = {
      useHTML: true,
      labelFormatter: function() {
        return (
          '<div style="padding-top: 20px;"><div class="widget-stats"> <div class="widget-stat stat-widget"><h4 class="widget-stat-title">' +
          t(this.name) +
          '</h4><p class="widget-stat-count" style="color:' +
          this.color +
          ';text-align:center;">' +
          getTotal(this.name).count +
          '</p><p style="font-weight:normal;color:#bdbdbd">' +
          getTotal(this.name).percentage +
          '</p></div></div></div>'
        );
      },
    };
    config.yAxis.max = this.getBiggestPercentages(config.series);
    /* if(config.yAxis.max && config.yAxis.max < 1){
            config.yAxis.tickInterval = 0.1;
        }*/
    return config;
  }

  getBiggestPercentages(series) {
    /* let biggestValue = 0;
        Object.keys(trendTotals).forEach(function(key) {
            let intPercentage = parseInt(calculateRatio(trendTotals[key], statTotals[key]));
            biggestValue = (intPercentage > biggestValue) ? intPercentage : biggestValue;
        }); */
    let biggestValue = 0;
    let noData = true;
    series.forEach(serie => {
      let serieData = serie.data;
      if (serieData.length) {
        noData = false;
      }
      serieData.forEach(data => {
        biggestValue = data.y > biggestValue ? data.y : biggestValue;
      });
    });
    if (noData) {
      return false;
    }
    return biggestValue ? biggestValue * 1.1 : 30;
  }

  render() {
    return (
      <div className='report-chart'>
        <ReactHighcharts config={this.getConfig()} ref='chart' />
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(ContributionChart);
