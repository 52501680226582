import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { modalActions } from '../actions';
import { t } from '../system/ui';
import Icons from './icons';

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      scrollable: false,
      contentHeight: 'auto',
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.calculateHeight = this.calculateHeight.bind(this);
    this.onKeyPressed = this.onKeyPressed.bind(this);
    this.setBodyOverflow = this.setBodyOverflow.bind(this);
  }

  componentDidMount() {
    this.open();
    window.addEventListener('resize', this.calculateHeight);
    window.addEventListener('keydown', this.onKeyPressed);
    this.setBodyOverflow('hidden');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateHeight);
    window.removeEventListener('keydown', this.onKeyPressed);
    this.setBodyOverflow('scroll');
  }

  setBodyOverflow(overflowType) {
    if (!document.querySelector('.segmentify-analytics')) {
      const bodyEl = document.querySelector('body');
      bodyEl.style.overflow = overflowType;
    }
  }

  onKeyPressed(evt) {
    evt = evt || window.event;
    let isEscape = false;
    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc';
    } else {
      isEscape = evt.keyCode === 27;
    }
    if (isEscape) {
      modalActions.closeModal();
    }
  }

  componentWillReceiveProps(newProps) {
    if (!this.state.isVisible && newProps) {
      this.open();
    }
  }

  componentDidUpdate() {
    if (this.state.contentHeight === 'auto') {
      this.calculateHeight();
    }

    if (this.props.reCalculateHeight && this.props.dynamicHeight) {
      this.calculateHeight();
    }
  }

  close() {
    this.setState({
      isVisible: false,
    });

    if (this.props.onClose) {
      this.props.onClose();
    }

    modalActions.closeModal();
  }

  open() {
    this.setState({
      isVisible: true,
    });

    if (this.props.onOpen) {
      this.props.onOpen();
    }
  }

  calculateHeight() {
    try {
      let header = 0;
      let height = this.refs.content.offsetHeight - 40; // we have 20px padding
      const visibleHeight = window.self.innerHeight;
      const maxHeight = visibleHeight * 0.9;
      const loadedContentHeight = this.refs.loadedContent.offsetHeight;

      if (typeof this.refs.contentHeader !== 'undefined') {
        header = this.refs.contentHeader.offsetHeight;
      }

      if (loadedContentHeight + header > maxHeight) {
        height = maxHeight - header - 20;

        this.setState({
          contentHeight: `${height}px`,
          scrollable: loadedContentHeight > height,
        });
      }

      if (this.props.dynamicHeight) {
        if (loadedContentHeight + header > maxHeight) {
          height = maxHeight - header - 20;
        } else {
          height = loadedContentHeight;
        }

        this.setState({
          contentHeight: `${height}px`,
          scrollable: loadedContentHeight > height,
        });
      }

      modalActions.cancelCalculation();
    } catch (e) {
      modalActions.cancelCalculation();
    }
  }

  render() {
    return this.state.isVisible ? (
      <div className={classNames('modal', this.props.className)}>
        <div className='modal-cover' />
        <div className='modal-content' ref='content'>
          {this.props.icon ? (
            <div
              className='modal-header modal-header-iconic'
              ref='contentHeader'
            >
              <div className='modal-header-icon'>
                <Icons
                  name={this.props.icon}
                  width='40'
                  height='40'
                  color='#787779'
                />
              </div>
              <div className='modal-header-text'>
                {this.props.title && (
                  <h3 className='modal-content-title' id='modal-title'>
                    {t(this.props.title)}
                  </h3>
                )}

                {this.props.subtitle && (
                  <p className='modal-content-subtitle'>
                    {t(this.props.subtitle)}
                  </p>
                )}
              </div>
            </div>
          ) : (
            <div className='modal-header' ref='contentHeader'>
              {this.props.title && (
                <h3 className='modal-content-title' id='modal-title'>
                  {t(this.props.title)}
                </h3>
              )}

              {this.props.subtitle && (
                <p className='modal-content-subtitle'>
                  {t(this.props.subtitle)}
                </p>
              )}
            </div>
          )}

          <div
            className={classNames('modal-content-wrapper', {
              'is-scrollable': this.state.scrollable,
            })}
            style={{ height: this.state.contentHeight }}
          >
            <div className='modal-content-text' ref='loadedContent'>
              {(() => {
                const Content = this.props.content;

                return <Content />;
              })()}
            </div>
          </div>

          <a className='modal-close' onClick={this.close}>
            X
          </a>
        </div>
      </div>
    ) : (
      <></>
    );
  }
}

Modal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  dynamicHeight: PropTypes.bool,
};

Modal.defaultProps = {
  title: '',
  subtitle: '',
  className: PropTypes.string,
  dynamicHeight: false,
};

const MapStatesToProps = store => ({
  reCalculateHeight: store.modal.reCalculateHeight,
});

export default connect(MapStatesToProps)(Modal);
