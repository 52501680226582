import React from 'react';
import { Link } from 'react-router';

import { isUserAdmin } from '../../../../modules/auth/user';
import { t } from '../../../../system/ui';

const ROUTING_LINKS = {
  Account_Settings: '/settings/account',
  User_Settings: '/settings/account/user',
  Manage_Users: '/settings/account/manageUsers',
  Customer_Removal_Tool: '/settings/account/customer-removal-tool',
};

const titles = {
  Account_Settings: 'Account Settings',
  User_Settings: 'User Settings',
  Manage_Users: 'Manage Users',
  Customer_Removal_Tool: 'Customer Removal Tool',
};

const useElementProps = whereIam => {
  const className = Object.keys(ROUTING_LINKS).map(key => {
    return whereIam === ROUTING_LINKS[key]
      ? 'nav-item page-nav-item current'
      : 'nav-item page-nav-item';
  });

  const tabName = Object.keys(ROUTING_LINKS).map(key => {
    return t(key.replace(/_/g, ' '));
  });

  const routes = Object.keys(ROUTING_LINKS).map(key => {
    return ROUTING_LINKS[key];
  });

  return { className, tabName, routes };
};

const NavigationTab = () => {
  const whereIam = window.location.pathname;

  const elementProps = useElementProps(whereIam);

  return (
    <>
      <h2 className='page-title'>
        {t(
          titles[
            Object.keys(ROUTING_LINKS).find(
              key => ROUTING_LINKS[key] === whereIam,
            )
          ],
        )}
      </h2>

      <nav id='page-nav' className='page-nav'>
        {elementProps.routes.map((route, index) => {
          if (route === '/settings/account/manageUsers' && !isUserAdmin())
            return null;

          return (
            <Link
              to={route}
              className={elementProps.className[index]}
              key={route}
            >
              {elementProps.tabName[index]}
            </Link>
          );
        })}
      </nav>
    </>
  );
};

export default NavigationTab;
