const criteriaTypes = {
  behaviour: {
    type: 'title',
    name: 'Behaviour',
    criteriaList: {
      categoryPurchase: {
        id: 'categoryPurchase',
        name: 'Purchase by Category',
        type: 'criteria',
        dataType: 'purchase',
        isActive: true,
        criteriaFilters: ['categoryOperator'],
      },
      productPurchase: {
        id: 'productPurchase',
        name: 'Purchase by Product',
        type: 'criteria',
        dataType: 'purchase',
        isActive: true,
        criteriaFilters: ['productOperator'],
      },
      brandPurchase: {
        id: 'brandPurchase',
        name: 'Purchase by Brand',
        type: 'criteria',
        dataType: 'purchase',
        isActive: true,
        criteriaFilters: ['brandOperator'],
      },
      totalRevenue: {
        id: 'totalRevenue',
        name: 'Total Revenue',
        type: 'criteria',
        dataType: 'purchase',
        isActive: true,
        criteriaFilters: ['totalRevenueOperator'],
      },
      attributeOperator: {
        id: 'attributeOperator',
        name: 'Purchase by Attribute',
        type: 'criteria',
        dataType: 'purchase',
        isActive: true,
        criteriaFilters: ['attributeOperator'],
      },
    },
  },
};

const stringOperators = {
  equals: {
    name: 'is',
    value: 'equals',
  },
  equalsNot: {
    name: 'is not',
    value: 'equalsNot',
  },
  contains: {
    name: 'contains',
    value: 'contains',
  },
  containsNot: {
    name: 'does not contains',
    value: 'containsNot',
  },
};

const listOperators = {
  is: {
    name: 'is',
    value: 'is',
  },
  isNot: {
    name: 'is not',
    value: 'isNot',
  },
  contains: {
    name: 'contains',
    value: 'contains',
  },
  containsNot: {
    name: 'does not contains',
    value: 'containsNot',
  },
};

const attributeOperators = {
  productSize: {
    name: 'Size',
    value: 'productSize',
  },
  productGender: {
    name: 'Gender',
    value: 'productGender',
  },
  productColor: {
    name: 'Color',
    value: 'productColor',
  },
};

const numberOperators = {
  equals: {
    name: 'is equal to',
    value: 'equals',
  },
  equalsNot: {
    name: 'is not equal to',
    value: 'equalsNot',
  },
  gt: {
    name: 'is greater than',
    value: 'gt',
  },
  lt: {
    name: 'is smaller',
    value: 'lt',
  },
  lte: {
    name: 'is less or equal',
    value: 'lte',
  },
  gte: {
    name: 'greater than or equal',
    value: 'gte',
  },
  between: {
    name: 'between',
    value: 'between',
  },
};

const dateOperators = {
  last: {
    name: 'in the last',
    value: 'last',
  },
  between: {
    name: 'between',
    value: 'between',
  },
  equals: {
    name: 'on date',
    value: 'equals',
  },
};

const dateOperatorUnits = {
  days: {
    name: 'days',
    value: 'days',
  },
  weeks: {
    name: 'weeks',
    value: 'weeks',
  },
  months: {
    name: 'months',
    value: 'months',
  },
};

const quantityOperators = {
  gte: {
    name: 'at least',
    value: 'gte',
  },
  lte: {
    name: 'at most',
    value: 'lte',
  },
  equals: {
    name: 'number X time',
    value: 'equals',
  },
};

const pageOperators = {
  equals: {
    name: 'is',
    value: 'equals',
  },
  equalsNot: {
    name: 'is not',
    value: 'equalsNot',
  },
};

const urlOperators = {
  equals: {
    name: 'is',
    value: 'equals',
  },
  contains: {
    name: 'contains',
    value: 'contains',
  },
};

const operatorColumns = {
  attributeOperator: 'attribute',
  categoryOperator: 'category',
  productOperator: 'productName',
  brandOperator: 'brand',
  totalRevenueOperator: 'totalRevenue',
  transactionOperator: 'transaction',
  quantityOperator: 'quantity',
  amountOperator: 'amount',
  dateOperator: 'date',
  pageOperator: 'page',
  urlOperator: 'url',
  productViewCountOperator: 'productViewCount',
  pageViewCountOperator: 'pageViewCount',
};

const operatorTitles = {
  attributeOperator: 'Attribute',
  categoryOperator: 'Category',
  productOperator: 'Product',
  brandOperator: 'Brand',
  totalRevenueOperator: 'Total Revenue',
  transactionOperator: 'Transaction',
  quantityOperator: 'Quantity',
  amountOperator: 'Amount',
  dateOperator: 'Time Range',
  pageOperator: 'Page',
  urlOperator: 'URL',
  productViewCountOperator: 'View Count',
  pageViewCountOperator: 'View Count',
};

export {
  attributeOperators,
  criteriaTypes,
  stringOperators,
  listOperators,
  numberOperators,
  dateOperators,
  dateOperatorUnits,
  quantityOperators,
  pageOperators,
  urlOperators,
  operatorColumns,
  operatorTitles,
};
