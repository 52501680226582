import React from 'react';

// import { useQuery } from 'react-query';
import { StatisticCard } from './components/StatisticCard';

/**
 * @name IysStatistics
 * @returns JSX.Element - IYS Statistics
 * @description
 * This component is responsible for rendering the statistics of the IYS Dashboard
 * @param {Object} iysData - Data for the statistics
 */

const IysStatistics = ({ iysData }) => {
  return <StatisticCard data={iysData} />;
};

export default IysStatistics;
