/**
 * @author Bilal Cinarli
 */

"use strict";
import user from "../modules/auth/user";

import { uiActions } from "../actions";

import { tryParse } from "./string";
import {getSource,getExternalAccount} from "../index"
import {redirectAuth} from "../modules/auth/require-auth";

const activeRequests = {
  GET: {},
  POST: {},
  PUT: {},
  DELETE: {}
};

export const $http = url => {
  let ajax = function(method, url, data, options) {
    let _options = options || {},
      uri = url;

    if (typeof _options.auth === "undefined") {
      _options.auth = true;
    }

    if (data && method === "GET") {
      uri += "?";
      let argcount = 0;

      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          if (argcount++) {
            uri += "&";
          }
          uri += encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      }
    }

    if (
      typeof activeRequests[method][uri] !== "undefined" &&
      activeRequests[method][uri].preventAbort === false
    ) {
      activeRequests[method][uri].request.abort();
    }

    return new Promise(function(resolve, reject) {
      let request = new XMLHttpRequest(),
        postData = "",
        contentType = "application/x-www-form-urlencoded; charset:utf-8";

      activeRequests[method][uri] = {
        request,
        data,
        preventAbort: _options.preventAbort === false
      };

      if (method === "POST" || method === "PUT") {
        postData = data;

        if (_options.type == "json") {
          contentType = "application/json; charset:utf-8";
          postData = JSON.stringify(data);
        }
      }

      request.open(method, uri);
      request.setRequestHeader("Content-Type", contentType);

      if (_options.auth === true) {
        let tokenToSet = _options.token || user.getToken();
        request.setRequestHeader("Authorization", "Bearer " + tokenToSet);
      }

      if (
        user.isSuperUser() &&
        user.isSwitchAccount() &&
        !user.isMultiAccountUser()
      ) {
        request.setRequestHeader(
          "X-Switch-User",
          user.getSwitchAccountUser().username
        );
      }


      let noSwitchHeader = true;
      if(getSource()!==null && getSource()==="external_app" && noSwitchHeader)
      {
        if(getExternalAccount()!==null && getExternalAccount().length>0){
          request.setRequestHeader(
            "X-Switch-Account",
            getExternalAccount().trim()
            )
        };
        noSwitchHeader=false;
      }

      if (user.isMultiAccountUser() && user.isSwitchAccount() && noSwitchHeader) {
        request.setRequestHeader(
          "X-Switch-Account",
          user.getAccount().accountId.trim()
        );
      }



      if (user.getRegion()) {
        const region = user.getRegion().trim();
        request.setRequestHeader(
          "X-Switch-Region",
          region
        );
      }

      request.onload = function() {
        let data = {
          status: this.status,
          statusText: this.statusText,
          response: tryParse(this.response),
          originalResponse: this
        };

        delete activeRequests[method][url];

        if (this.status >= 200 && this.status < 400) {
          resolve(data);
        } else {
          if (this.status === 419) {
            redirectAuth(1); //mode=1
          } else {
            if (this.status === 500) {
              data.statusText = "Something went wrong";
            }
            reject(data);
            uiActions.notifyRequestError(data);
          }
        }
      };

      request.onerror = function() {
        let data = {
          status: this.status,
          statusText: this.statusText || "Something went wrong",
          response: tryParse(this.response),
          originalResponse: this
        };

        delete activeRequests[method][url];

        uiActions.notifyRequestError(data);
        reject(data);
      };

      request.send(postData);
    });
  };

  return {
    get: function(data, options) {
      return ajax("GET", url, data, options);
    },
    post: function(data, options) {
      return ajax("POST", url, data, options);
    },
    put: function(data, options) {
      return ajax("PUT", url, data, options);
    },
    delete: function(data, options) {
      return ajax("DELETE", url, data, options);
    }
  };
};
