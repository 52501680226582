import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const spViewCounter = ({ width, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 13'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      fill='none'
    >
      <path
        d='M9.9999 4.00462C12.209 4.00462 13.9999 5.7955 13.9999 8.0046C13.9999 10.2138 12.209 12.0046 9.9999 12.0046C7.79073 12.0046 5.99987 10.2138 5.99987 8.0046C5.99987 5.7955 7.79073 4.00462 9.9999 4.00462ZM9.9999 5.5046C8.6192 5.5046 7.49987 6.6239 7.49987 8.0046C7.49987 9.3853 8.6192 10.5046 9.9999 10.5046C11.3806 10.5046 12.4999 9.3853 12.4999 8.0046C12.4999 6.6239 11.3806 5.5046 9.9999 5.5046ZM9.9999 0.5C14.6134 0.5 18.596 3.65001 19.701 8.0644C19.8016 8.4662 19.5574 8.8735 19.1556 8.9741C18.7537 9.0746 18.3465 8.8305 18.2459 8.4286C17.307 4.67796 13.9212 2 9.9999 2C6.07681 2 2.68997 4.68026 1.75273 8.4332C1.65237 8.835 1.24523 9.0794 0.843357 8.9791C0.441487 8.8787 0.197067 8.4716 0.297427 8.0697C1.40052 3.65272 5.38436 0.5 9.9999 0.5Z'
        fill={color}
      />
    </svg>
  );
};

spViewCounter.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

spViewCounter.defaultProps = {
  width: '20px',
  height: '13px',
  color: '#5D5D61',
};

export default spViewCounter;
