import React from "react";
import Snippet from 'components/snippet';
import { uiActions } from 'actions';
import { getSearchSettingsRequest } from 'modules/search/ajax';
import { confirmationDialog } from '../../../actions/ui';
import { t } from 'system/ui';

export default class AdminSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
      value: null,
      snippet: '',
      error: false,
    };
    this.snippetRef = null;
  }

  componentDidMount() {
    getSearchSettingsRequest('search/adminVersions')
      .get()
      .then(options => {
        this.setState({
          options,
        });
      });
  }

  onChange = option => {
    const { formSaved } = this.props;
    if (!formSaved) {
      confirmationDialog({
        content: t("Your work is not saved! Are you sure want to continue?"),
        onConfirm: () => {
          uiActions.formSaved();
          this.changeSchemaVersion(option);
        },
      });
    } else {
      this.changeSchemaVersion(option);
    }
  };

  changeSchemaVersion = option => {
    const version = option.target.value;
    if (version) {
      getSearchSettingsRequest('search/adminQueryLists', { version })
        .get()
        .then(queryLists => {
          this.setState({
            value: version,
            snippet: JSON.stringify(queryLists, null, 2),
          });
        });
    } else {
      this.setState({
        value: null,
        snippet: '',
      });
    }
  };

  getUpdatedCampaign = () => {
    return {
      config: JSON.parse(this.state.snippet),
      version: this.state.value,
    };
  };

  checkDefault = snippet => {
    uiActions.formEdited();
    try {
      const json = JSON.parse(snippet);
      this.setState({
        error: false,
      });
    } catch (e) {
      this.setState({
        error: true,
      });
    }
    this.setState({
      snippet,
    });
  };

  isObject = value => typeof value === 'object' && value !== null;

  render() {
    const { value, options, snippet, error } = this.state;
    return (
      <>
        <label htmlFor='-' className='item is-select'>
          <span>Schema version</span>
          <select
            className='one-whole criteria-field'
            value={value || ''}
            onChange={this.onChange}
          >
            <option value=''>Select version</option>
            {this.isObject(options)
              ? Object.keys(options).map(option => <option value={option}>{options[option]}</option>)
              : []
            }
          </select>
        </label>
        {value && (
          <div style={{height: "400px"}}>
            <Snippet
              ref={ref => {
                this.snippetRef = ref;
              }}
              value={snippet}
              language={{
                name: 'javascript',
                json: true,
              }}
              checkDefault={this.checkDefault}
            />
          </div>
        )}
        {error ? <span className="item-error"><span className="item-error">It's not a valid JSON</span></span> : null}
      </>
    );
  }
}
