import React, { useState } from 'react';
import { DateRange } from 'react-date-range';

import classes from '../downloadFTP.module.scss';

import { Checkbox } from '../../../../../../components/fields';
import { inputConfiguration } from '../../../constants';

const inputTypeEnum = {
  checkbox: 'checkbox',
  datepicker: 'datepicker',
};

export function FTPDownloadForm({ downloadState, setValues }) {
  const [showDatePicker, setShowDatePicker] = useState(false);

  return (
    <div className={classes.formInput}>
      <div>
        {inputConfiguration?.checkbox?.map(({ order, name, stateName }) => (
          <Checkbox
            key={order}
            name={stateName}
            checked={downloadState[stateName]}
            onChange={e =>
              setValues({
                e,
                type: inputTypeEnum.checkbox,
                stateName,
              })
            }
            label={name}
          />
        ))}
      </div>
      <>
        <a
          onClick={() => setShowDatePicker(!showDatePicker)}
          className={classes.dateRangeText}
        >
          {showDatePicker ? 'Close' : 'Add'} Date Range Filter
        </a>
        {showDatePicker && (
          <div className={classes.dateRangePicker}>
            <DateRange
              ranges={downloadState.selection}
              maxDate={new Date()}
              shownDate={new Date()}
              dateDisplayFormat='MM/dd/yyyy'
              disabledDates={[
                {
                  after: new Date(),
                },
              ]}
              rangeColors={['#dc3322']}
              onChange={e =>
                setValues({
                  e,
                  type: inputTypeEnum.datepicker,
                  stateName: 'selection',
                })
              }
            />
          </div>
        )}
      </>
    </div>
  );
}
