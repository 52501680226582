import React from "react";
import { calculateRatio } from "../../system/ratio";
import Currency from "../../components/currency";
import { t } from "../../system/ui";

class CompareConversion extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="one-whole">
        <ul className="one-half trendify-compare-stats">
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("View")}</h4>
              <p>{t("Number Of Product Views")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {this.props.firstColumnData.stats[
                this.props.device + "SiteView"
              ] || "-"}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Basket")}</h4>
              <p>{t("Number Of Add To Basket Operations")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {this.props.firstColumnData.stats[
                this.props.device + "SiteBasket"
              ] || "-"}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Rate")}</h4>
              <p>{t("Conversion Rate (Add To Basket/View)")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {calculateRatio(
                this.props.firstColumnData.stats[
                  this.props.device + "SiteBasket"
                ],
                this.props.firstColumnData.stats[this.props.device + "SiteView"]
              ) + "%"}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Purchase")}</h4>
              <p>{t("Number Of Product Purchases")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {this.props.firstColumnData.stats[
                this.props.device + "SitePurchase"
              ] || "-"}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Rate")}</h4>
              <p>{t("Conversion Rate (Purchase/View)")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {calculateRatio(
                this.props.firstColumnData.stats[
                  this.props.device + "SitePurchase"
                ],
                this.props.firstColumnData.stats[this.props.device + "SiteView"]
              ) + "%"}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Amount")}</h4>
              <p>{t("Amount Of Product Purchases")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {
                <Currency
                  currency={
                    this.props.firstColumnData.stats[
                      this.props.device + "SiteAmount"
                    ] || 0
                  }
                />
              }
            </div>
          </li>
        </ul>
        <ul className="one-half trendify-compare-stats">
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("View")}</h4>
              <p>{t("Number Of Product Views")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {this.props.secondColumnData.stats[
                this.props.device + "SiteView"
              ] || "-"}
              {this.props
                .getCompareIcon()
                .count(
                  this.props.secondColumnData.stats[
                    this.props.device + "SiteView"
                  ],
                  this.props.firstColumnData.stats[
                    this.props.device + "SiteView"
                  ]
                )}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Basket")}</h4>
              <p>{t("Number Of Add To Basket Operations")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {this.props.secondColumnData.stats[
                this.props.device + "SiteBasket"
              ] || "-"}
              {this.props
                .getCompareIcon()
                .count(
                  this.props.secondColumnData.stats[
                    this.props.device + "SiteBasket"
                  ],
                  this.props.firstColumnData.stats[
                    this.props.device + "SiteBasket"
                  ]
                )}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Rate")}</h4>
              <p>{t("Conversion Rate (Add To Basket/View)")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {calculateRatio(
                this.props.secondColumnData.stats[
                  this.props.device + "SiteBasket"
                ],
                this.props.secondColumnData.stats[
                  this.props.device + "SiteView"
                ]
              ) + "%"}
              {this.props
                .getCompareIcon()
                .ratio(
                  this.props.secondColumnData.stats[
                    this.props.device + "SiteBasket"
                  ],
                  this.props.secondColumnData.stats[
                    this.props.device + "SiteView"
                  ],
                  this.props.firstColumnData.stats[
                    this.props.device + "SiteBasket"
                  ],
                  this.props.firstColumnData.stats[
                    this.props.device + "SiteView"
                  ]
                )}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Purchase")}</h4>
              <p>{t("Number Of Product Purchases")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {this.props.secondColumnData.stats[
                this.props.device + "SitePurchase"
              ] || "-"}
              {this.props
                .getCompareIcon()
                .count(
                  this.props.secondColumnData.stats[
                    this.props.device + "SitePurchase"
                  ],
                  this.props.firstColumnData.stats[
                    this.props.device + "SitePurchase"
                  ]
                )}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Rate")}</h4>
              <p>{t("Conversion Rate (Purchase/View)")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {calculateRatio(
                this.props.secondColumnData.stats[
                  this.props.device + "SitePurchase"
                ],
                this.props.secondColumnData.stats[
                  this.props.device + "SiteView"
                ]
              ) + "%"}
              {this.props
                .getCompareIcon()
                .ratio(
                  this.props.secondColumnData.stats[
                    this.props.device + "SitePurchase"
                  ],
                  this.props.secondColumnData.stats[
                    this.props.device + "SiteView"
                  ],
                  this.props.firstColumnData.stats[
                    this.props.device + "SitePurchase"
                  ],
                  this.props.firstColumnData.stats[
                    this.props.device + "SiteView"
                  ]
                )}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Amount")}</h4>
              <p>{t("Amount Of Product Purchases")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {
                <Currency
                  currency={
                    this.props.secondColumnData.stats[
                      this.props.device + "SiteAmount"
                    ] || 0
                  }
                />
              }
              {this.props
                .getCompareIcon()
                .currency(
                  this.props.secondColumnData.stats[
                    this.props.device + "SiteAmount"
                  ],
                  this.props.firstColumnData.stats[
                    this.props.device + "SiteAmount"
                  ]
                )}
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default CompareConversion;
