import React from 'react';
import PropTypes from 'prop-types';
import SwitchButton from 'components/shared/switch-button';
import Tooltip from 'components/tooltip';
import '../../styles/gui/components/_push-permission-prompt.scss';

const PushPermissionPrompt = ({ onToggle, isSwitchedOn, children }) => {
  return (
    <div className='push-permission-prompt'>
      <h3 className='wizard-comp-title'>Push Permission Prompt</h3>
      <p className='wizard-comp-description'>
        Set up prompts to ask for push permission
      </p>
      <div className='two-step-push-permission'>
        <label className='permission-check'>
          <SwitchButton
            onToggle={() => onToggle()}
            isSwitchedOn={isSwitchedOn}
          />
          <span>Two-Step Push Permission</span>
          <span className='field-tooltip-icon'>
            <Tooltip content='Tooltip content' alignment='left'>
              <i className='icon-info' role='presentation'>
                <span className='for-screenreader-only' />
              </i>
            </Tooltip>
          </span>
        </label>
        {isSwitchedOn && children}
      </div>
    </div>
  );
};

PushPermissionPrompt.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isSwitchedOn: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default PushPermissionPrompt;
