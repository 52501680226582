/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes, { shape } from 'prop-types';

import { modalActions } from '../../../../actions';

import SearchBoxPlaybookCard from '../../icons/SearchBoxPlaybookCard';
import BlackFridayPlaybookCard from '../../icons/BlackFridayPlaybookCard';
import {
  InsightHorizontalChartItems,
  InsightVerticalChartItems,
  PlaybookItemsOfSearchBox,
  PlaybookItemsOfBlackFriday,
} from '../../msp-static-data';
import { openUrlInNewTab } from '../../utils';
import { playbookTypes } from '../../enums';

import InsightsTrendify from './InsightsTrendify';
import Playbook from '../../components/Playbook';
import InsightsPieChart from './InsightsPieChart';
import AddClientButton from '../../components/AddClientButton';

const OpenPlaybookModal = playbookType => {
  function content() {
    switch (playbookType) {
      case playbookTypes.SEARCH_BOX:
        return (
          <Playbook
            playbookType={playbookType}
            playbookItems={PlaybookItemsOfSearchBox}
          />
        );
      case playbookTypes.BLACK_FRIDAY:
        return (
          <Playbook
            playbookType={playbookType}
            playbookItems={PlaybookItemsOfBlackFriday}
          />
        );
      default:
        return null;
    }
  }

  modalActions.openModal({
    content,
    className: 'playbook-modal',
  });
};

const VerticalBar = ({ items }) => {
  return (
    <div className='vertical-bar-container'>
      <div className='bar-sub-container'>
        <div className='vertical-bar-bg'>
          {Array.from(Array(6).keys()).map(item => (
            <div key={item} className='vertical-bar-bg-line' />
          ))}
        </div>
        {items.map(item => (
          <div key={item.title} className='bar-item'>
            <div className='bar-value'>
              <div className='bar-holder'>
                <div
                  className='bar-holder-fill'
                  style={{
                    height: `${item.value}%`,
                  }}
                />
              </div>
              <div className='bar-title'>{item.title}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

VerticalBar.propTypes = {
  items: PropTypes.arrayOf(
    shape({
      title: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
};

const LargeCard = ({ title, subtitle, children, redirectUrl }) => {
  return (
    <div
      className={`welcome-card large-card ${redirectUrl && 'clickable'}`}
      onClick={() => {
        openUrlInNewTab(redirectUrl);
      }}
    >
      <div className='card-header'>
        <div className='card-header-title'>{title}</div>
        <div className='card-header-subtitle'>{subtitle}</div>
      </div>
      <div className='card-content'>{children}</div>
    </div>
  );
};

LargeCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};
const conditionalModal = modalName => {
  if (modalName === 'BlackFriday') {
    window.open(
      'https://www.segmentify.com/wp-content/uploads/2022/10/Black-Friday-eBook.pdf?utm_source=newsletter&utm_medium=email-nt&utm_campaign=pb-black-friday-191022&dm_i=59YM,NSBO,4RZD4D,2VGPW,1',
      '_blank',
    );
    return;
  }
  OpenPlaybookModal(modalName);
};
const SmallCard = ({ children, modalName }) => {
  return (
    <div
      className='welcome-card small-card clickable'
      onClick={() => conditionalModal(modalName)}
    >
      <div className='card-bg' />
      {children}
    </div>
  );
};

SmallCard.propTypes = {
  children: PropTypes.node.isRequired,
  modalName: PropTypes.string.isRequired,
};

const Insights = () => {
  return (
    <div className='welcome-group-field'>
      <div className='welcome-title'>Insights</div>
      <AddClientButton />
      <div className='welcome-content'>
        <InsightsTrendify />
        <div className='welcome-column'>
          <LargeCard
            title='Best Performing Push Campaigns'
            subtitle='Conversion'
            redirectUrl='https://www.segmentify.com/solutions/personalised-push-notifications/'
          >
            <InsightsPieChart items={InsightHorizontalChartItems} />
          </LargeCard>
          <SmallCard modalName={playbookTypes.SEARCH_BOX}>
            <div className='card-header'>
              <div className='card-header-big-title'>
                Segmentify published a{' '}
                <span className='purple'>Search Box Playbook</span> for better
                CX!
              </div>
            </div>
            <div className='card-content'>
              <SearchBoxPlaybookCard className='card-image purple' />
            </div>
          </SmallCard>
        </div>
        <div className='welcome-column'>
          <LargeCard
            title='Best Performing Days of Push Campaigns'
            subtitle='Conversion'
            redirectUrl='https://www.segmentify.com/solutions/personalised-push-notifications/'
          >
            <VerticalBar items={InsightVerticalChartItems} />
          </LargeCard>
          <SmallCard modalName={playbookTypes.BLACK_FRIDAY}>
            <div className='card-header'>
              <div className='card-header-big-title'>
                PERSONALISED BEST PRACTICES FOR{' '}
                <span className='red'>BLACK FRIDAY</span>
              </div>
            </div>
            <div className='card-content'>
              <BlackFridayPlaybookCard className='card-image red' />
            </div>
          </SmallCard>
        </div>
      </div>
    </div>
  );
};

export default Insights;
