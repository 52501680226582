import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { modalActions, uiActions } from '../../../../actions';
import CampaignHistoryModal from '../../../../modules/campaigns/historymodal';
import { t } from '../../../../system/ui';
import Icon from '../../../../components/icon';
import Icons from '../../../../components/icons';
import { moveCampaignToArchive } from '../../../../modules/ds/ajax';
import {
  getCampaigns,
  getSingleCampaigns,
} from '../../../../reducers/ds/extraReducers';
import { isSuperUser } from '../../../../modules/auth/user';
import SegmentDetailPreview from './SegmentDetailPreview';
import MoveToGroup from './MoveToGroup';
import { getExternalPersonalisedAds } from '../../../../constants/config';

const subActions = [
  {
    name: 'Segment Details',
    icon: 'view',
    action: 'editPreview',
  },
  {
    name: 'History',
    icon: 'history',
    iconSvg: true,
    action: 'showCampaignHistory',
    superUserOnly: true,
  },
  { name: 'Archive', icon: 'archive', action: 'addToArchive' },
  { name: 'Duplicate', icon: 'duplicate', action: 'duplicate' },
  {
    name: 'Send Bulk Push',
    iconSvg: true,
    icon: 'personalizedPush',
    action: 'sendBulkPush',
  },
  {
    name: 'Create Engagement',
    iconSvg: true,
    icon: 'behaviouralTargeting',
    action: 'createEngagementCampaign',
  },
  {
    name: 'Use Personalised Ads',
    iconSvg: true,
    icon: 'personalisedAds',
    action: 'usePersonalisedAds',
  },
  {
    name: 'Send Bulk Email',
    iconSvg: true,
    icon: 'emailModule',
    action: 'sendBulkEmail',
  },
  { name: 'Move to', icon: 'move', iconSvg: true, action: 'moveTo' },
];

const DsSubActions = segment => {
  const [dropUp, setDropUp] = useState(false);
  const actionMenu = useRef(null);
  const dispatch = useDispatch();
  const features = useSelector(
    state => state.switchedUser.switchedUser.account.features,
  );
  const mailProvider = useSelector(
    state =>
      state.switchedUser?.switchedUser?.account?.mailConfiguration?.provider,
  );

  const { openModal } = modalActions;
  const { confirmationDialog, showNotification } = uiActions;

  const getListClass = () => {
    if (dropUp) {
      return 'widget-nav-popup action-nav-popup drop-up';
    }
    return 'widget-nav-popup action-nav-popup';
  };

  const getControlledActions = () => {
    if (dropUp) {
      return subActions.slice().reverse();
    }
    return subActions;
  };

  const handleScroll = () => {
    const partOfDocHeight = (document.documentElement.offsetHeight * 70) / 100;
    const listTop = actionMenu.current.getBoundingClientRect().top;
    setDropUp(listTop > partOfDocHeight);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const editPreview = () => {
    function content() {
      return <SegmentDetailPreview campaignDetails={segment} />;
    }

    openModal({
      title: 'Segment Details',
      content,
      className: 'rbs-wizard-save-preview-modal detail-preview',
    });
  };

  const showCampaignHistory = () => {
    const campaignId = segment.segments.instanceId;
    const campaignName = segment.segments.segmentName;

    const campaignHistoryModal = () => (
      <CampaignHistoryModal campaignId={campaignId} isDs />
    );

    openModal({
      title: `Campaign History (${campaignName})`,
      content: campaignHistoryModal,
    });
  };

  const addToArchive = () => {
    confirmationDialog({
      title: 'Archive the campaign?',
      content: 'When you make this change the campaign will be removed.',
      onConfirm: () => {
        moveCampaignToArchive(segment.segments.instanceId).then(() => {
          const note = () => {
            return <div>Segmentation is successfully Archived.</div>;
          };
          dispatch(getCampaigns());
          dispatch(getSingleCampaigns());
          showNotification({
            content: note,
          });
        });
      },
    });
  };

  const sendBulkPush = () => {
    window.location.state = {
      smartSegmentId: segment.segments.segmentId,
    };
    return browserHistory.push('/push/add/PUSH_NOTIFICATION');
  };

  const createEngagementCampaign = () => {
    return browserHistory.push('/behavioural-targeting/engagement/view-all');
  };

  const usePersonalisedAds = () => {
    const location = getExternalPersonalisedAds();
    window.open(location, '_blank');
  };

  const sendBulkEmail = () => {
    return browserHistory.push('/email/recommendation');
  };

  const moveTo = () => {
    const campaignId = segment.segments.instanceId;
    openModal({
      title: 'Move To',
      height: 'auto',
      content: () => <MoveToGroup campaignId={campaignId} />,
      className: 'detail-preview ds-move-to-group',
    });
  };

  const duplicate = () => {
    const instance = segment.segments.instanceId;
    const path = `audience/dynamic-segmentation/duplicate/${instance}`;

    browserHistory.push({
      pathname: path,
    });
  };

  const handleClick = action => {
    switch (action) {
      case 'editPreview':
        editPreview();
        break;
      case 'showCampaignHistory':
        showCampaignHistory();
        break;
      case 'addToArchive':
        addToArchive();
        break;
      case 'duplicate':
        duplicate();
        break;
      case 'moveTo':
        moveTo();
        break;
      case 'sendBulkPush':
        sendBulkPush();
        break;
      case 'createEngagementCampaign':
        createEngagementCampaign();
        break;
      case 'usePersonalisedAds':
        usePersonalisedAds();
        break;
      case 'sendBulkEmail':
        sendBulkEmail();
        break;
      default:
        break;
    }
  };
  return (
    <nav
      className='widget-nav action-nav'
      aria-haspopup='true'
      ref={actionMenu}
    >
      <a className='secondary-action'>{t('Actions')}</a>

      <ul className={getListClass()} style={{ left: '-114px' }}>
        {getControlledActions().map(action => {
          if (!isSuperUser() && action.superUserOnly) {
            return null;
          }

          if (action.action === 'addToArchive' && action.status === 'PASSIVE') {
            return null;
          }

          if (
            mailProvider !== 'SEGMENTIFY_V2' &&
            action.action === 'sendBulkEmail'
          )
            return null;
          if (
            features?.personalization === 'ACTIVE' &&
            action.action === 'createEngagementCampaign'
          )
            return null;
          if (
            features?.personalisation.status === 'ACTIVE' &&
            action.action === 'usePersonalisedAds'
          )
            return null;
          if (features?.push === 'ACTIVE' && action.action === 'sendBulkPush')
            return null;

          return (
            <li key={`${action.name} ${action.action}`}>
              <a
                className='widget-nav-popup-item action-nav-popup-item'
                onClick={() => handleClick(action.action)}
              >
                {action.iconSvg ? (
                  <Icons name={action.icon} color='#fff' />
                ) : (
                  <Icon name={action.icon} />
                )}
                {t(action.name)}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default DsSubActions;
