import React, { useState, useEffect } from 'react';
import { t } from '../system/ui';
import { uiActions } from '../actions';
import Icon from '../components/icon';
import Icons from '../components/icons';
import { TextField } from '../components/fields';
import {
  getReplenishmentProducts,
  updateReplenishmentProducts,
} from '../modules/replenishment/ajax';
import {
  getAccountCurrency,
  getAccountReplenishmentConfig,
} from '../modules/auth/user';
import Tooltip from '../components/tooltip';

const convertProductsListToArray = productList => {
  const productsArray = [];
  Object.keys(productList).forEach(key => {
    productsArray.push({
      productId: key,
      ...productList[key],
    });
  });

  const descendingProductsArray = productsArray.sort((a, b) => {
    return b.price - a.price;
  });

  return descendingProductsArray;
};

const SettingsReplenishment = () => {
  const [searchValue, setSearchValue] = useState('');
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [changedFrequencyList, setChangedFrequencyList] = useState([]);
  const currency = getAccountCurrency();
  const replenishmentConfig = getAccountReplenishmentConfig();

  useEffect(() => {
    getReplenishmentProducts(response => {
      setProducts(convertProductsListToArray(response));
    });
  }, []);

  useEffect(() => {
    if (!searchValue) {
      setFilteredProducts(products);
    } else {
      const filteredProductList = products.filter(product => {
        return product?.name?.toLowerCase().includes(searchValue.toLowerCase());
      });
      setFilteredProducts(filteredProductList);
    }
  }, [searchValue, products]);

  const frequencyChangeHandler = (productId, frequency) => {
    let fixedFrequency;
    if (frequency === 'auto') {
      fixedFrequency = 'auto';
    } else {
      fixedFrequency = parseInt(frequency);

      if (frequency && frequency <= 0) {
        fixedFrequency = 1;
      } else if (frequency !== 0 && !frequency) {
        fixedFrequency = null;
      }
    }

    const newFrequencyList = [...changedFrequencyList];
    const index = newFrequencyList.findIndex(
      item => item.productId === productId,
    );

    const oldFrequency = products.find(item => item.productId === productId)
      .user_frequency;

    if (oldFrequency === fixedFrequency && index !== -1) {
      newFrequencyList.splice(index, 1);
    } else if (oldFrequency !== fixedFrequency && index === -1) {
      newFrequencyList.push({ productId, frequency: fixedFrequency });
    } else if (oldFrequency !== fixedFrequency) {
      newFrequencyList[index].frequency = fixedFrequency;
    }

    setChangedFrequencyList(newFrequencyList);
  };

  const showNotification = () => {
    uiActions.isLoaded();

    const note = () => {
      return <div>Replenishment is successfully updated.</div>;
    };
    uiActions.showNotification({
      content: note,
    });
  };

  const showErrorNotification = () => {
    uiActions.isLoaded();

    const note = () => {
      return <div>Please fill in all fields.</div>;
    };
    uiActions.showNotification({
      content: note,
      className: 'notification-fail',
    });
  };

  const updateHandler = () => {
    uiActions.isLoading();

    const fixedUpdateData = changedFrequencyList.map(item => {
      return {
        productId: item.productId,
        frequency: item.frequency === 'auto' ? 0 : item.frequency,
      };
    });

    const emptyValues = fixedUpdateData.filter(item => item.frequency === null);
    if (emptyValues.length > 0) {
      showErrorNotification();
      return;
    }

    updateReplenishmentProducts(fixedUpdateData)
      .then(response => {
        showNotification(response.status);
        setChangedFrequencyList([]);

        getReplenishmentProducts(newResponse => {
          setProducts(convertProductsListToArray(newResponse));
        });
      })
      .catch(() => {
        uiActions.isLoaded();
      });
  };

  return (
    <div className='settings-replenishment'>
      <h2 className='page-title'>{t('Replenishment')}</h2>
      <div className='page-description'>
        <i className='icon-info' />
        <span>
          Products with a frequency of less than{' '}
          {replenishmentConfig?.saleFrequency} days that have been purchased{' '}
          {replenishmentConfig?.leastSale} times by at least{' '}
          {replenishmentConfig?.leastUser} user in the last 1 year are listed.
        </span>
      </div>
      <div className='product-list-field'>
        <div className='list-header'>
          <div className='list-header-title'>List of Product to Replenish</div>
          <div className='list-header-filter'>
            <Icon name='magnify' />
            <TextField
              name='search'
              placeholder={t('Search')}
              className='search-field'
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
            <Icon name='preferences' />
          </div>
        </div>
        <div className='list-content'>
          <div className='product-list'>
            <div className='list-item-row header-row'>
              <div className='row-item item-product-name'>
                <span>{t('Product')}</span>
                <Tooltip content='Product Name' alignment='left'>
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              </div>
              <div className='row-item item-productId'>
                <span>{t('Product ID')}</span>
                <Tooltip content='Product ID' alignment='left'>
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              </div>
              <div className='row-item item-category'>
                <span>{t('Category')}</span>
                <Tooltip
                  content='Category Name of the Product '
                  alignment='left'
                >
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              </div>
              <div className='row-item item-uniq-purchase'>
                <span>{t('Unique Purchases')}</span>
                <Tooltip
                  content='Number of Unique Purchases of the Product'
                  alignment='left'
                >
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              </div>
              <div className='row-item item-total-purchase'>
                <span>{t('Total Purchases')}</span>
                <Tooltip
                  content='Total Number of Purchases of the Product'
                  alignment='left'
                >
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              </div>
              <div className='row-item item-price'>
                <span>
                  {t('Amount')} ({currency.symbol || currency.code})
                </span>
                <Tooltip content='Price of the product' alignment='left'>
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              </div>
              <div className='row-item item-frequency'>
                <span>{t('Frequency')} (days)</span>
                <Tooltip
                  content='These are the purchasing frequencies of products with a frequency of less than 90 days that have been purchased 2 times by at least 1 user in the last 1 year.'
                  alignment='right'
                >
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              </div>
            </div>
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product, index) => {
                const userFrequency = product.user_frequency;
                const systemFrequency = product.system_frequency;
                const changedFrequencyObj = changedFrequencyList.find(
                  item => item.productId === product.productId,
                );
                const changedFrequency = changedFrequencyObj
                  ? changedFrequencyObj.frequency
                  : null;

                let activeFrequency =
                  userFrequency > 0 ? userFrequency : systemFrequency;
                if (
                  changedFrequency !== 'auto' &&
                  (changedFrequency ||
                    (changedFrequencyObj && changedFrequency === null))
                ) {
                  activeFrequency = changedFrequency;
                } else if (changedFrequencyObj && changedFrequency === 'auto') {
                  activeFrequency = systemFrequency;
                }

                const showSystemFrequency =
                  userFrequency > 0 && changedFrequency !== 'auto';

                return (
                  <div className='list-item-row' key={product.productId}>
                    <div className='row-item item-name'>
                      <span className='item-index'>{index + 1}</span>
                      <img
                        className='item-image'
                        src={product.image}
                        alt={product.name}
                      />
                      <span className='item-productName'>{product.name}</span>
                    </div>
                    <div className='row-item item-productId'>
                      {product.productId}
                    </div>
                    <div className='row-item item-category'>
                      {product.category}
                    </div>
                    <div className='row-item item-uniq-purchase'>
                      {product.unique_purchase}
                    </div>
                    <div className='row-item item-total-purchase'>
                      {product.total_purchase}
                    </div>
                    <div className='row-item item-price'>{product.price}</div>
                    <div className='row-item item-frequency'>
                      <div className='current-frequency'>
                        {userFrequency > 0 ? (
                          <Icons name='replenishmentUser' />
                        ) : (
                          <Icons name='replenishmentSystem' />
                        )}
                        <TextField
                          name='frequency'
                          type='number'
                          value={activeFrequency}
                          onChange={e => {
                            frequencyChangeHandler(
                              product.productId,
                              e.target.value,
                            );
                          }}
                        />
                      </div>
                      {showSystemFrequency && (
                        <div className='system-frequency'>
                          <div className='frequency-value'>
                            <Icons
                              name='replenishmentSystem'
                              color='#B3B3B3'
                              width='12px'
                              height='12px'
                            />
                            <span>{systemFrequency}</span>
                          </div>
                          <div
                            className='frequency-go-auto'
                            onClick={() =>
                              frequencyChangeHandler(product.productId, 'auto')
                            }
                          >
                            turn auto
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='data-content-message-text'>
                <i className='icon-info' />
                There are no records to show.
              </div>
            )}
          </div>
        </div>
      </div>
      {changedFrequencyList.length > 0 && (
        <div className='save-button-field'>
          <button
            type='button'
            className='btn btn-update'
            onClick={() => updateHandler()}
          >
            {t('Save')}
          </button>
        </div>
      )}
    </div>
  );
};

export default SettingsReplenishment;
