import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const Countdown = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.05041 2.3175C7.98712 2.28477 7.92552 2.24889 7.86582 2.21C7.69235 2.0953 7.53329 1.96017 7.39207 1.8075C7.03832 1.40292 6.90624 0.9925 6.85791 0.705417H6.85957C6.81916 0.466667 6.83582 0.3125 6.83874 0.3125H5.22832V6.53875C5.22832 6.62208 5.22832 6.705 5.22499 6.78667C5.22499 6.79667 5.22374 6.80583 5.22332 6.81708C5.22332 6.82125 5.22332 6.82625 5.22207 6.83083V6.83458C5.20516 7.05793 5.13362 7.27369 5.01373 7.46288C4.89385 7.65208 4.7293 7.80892 4.53457 7.91958C4.33142 8.03516 4.10164 8.09576 3.86791 8.09542C3.11791 8.09542 2.50957 7.48375 2.50957 6.72833C2.50957 5.9725 3.11791 5.36083 3.86791 5.36083C4.00999 5.36083 4.15124 5.38333 4.28624 5.4275L4.28832 3.7875C3.87833 3.7346 3.46182 3.76728 3.06509 3.88346C2.66836 3.99964 2.30002 4.1968 1.98332 4.4625C1.70576 4.70356 1.4724 4.99121 1.29374 5.3125C1.22582 5.42958 0.969157 5.90083 0.938323 6.665C0.91874 7.09833 1.04916 7.54833 1.11124 7.73375V7.73792C1.14999 7.84708 1.30166 8.22042 1.54832 8.535C1.74724 8.7875 1.98226 9.00933 2.24582 9.19333V9.18917L2.24957 9.19333C3.02874 9.7225 3.89332 9.68792 3.89332 9.68792C4.04291 9.68167 4.54416 9.68792 5.11332 9.41833C5.74457 9.11917 6.10374 8.67375 6.10374 8.67375C6.33337 8.40755 6.51598 8.10419 6.64374 7.77667C6.78957 7.39333 6.83791 6.93417 6.83791 6.75083V3.44708C6.85749 3.45875 7.11791 3.63083 7.11791 3.63083C7.11791 3.63083 7.49291 3.87125 8.07749 4.0275C8.49666 4.13875 9.06207 4.1625 9.06207 4.1625V2.56375C8.86416 2.58542 8.46207 2.52292 8.04999 2.31792L8.05041 2.3175Z'
        fill='white'
      />
    </svg>
  );
};

Countdown.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Countdown.defaultProps = {
  width: '32px',
  height: '28px',
};

export default Countdown;
