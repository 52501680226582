import React, { useContext, useEffect, useState } from "react";
import classes from './SFYTab.module.scss';
import SFYPagination from '../../pagination/SFYPagination';
import { Context as PaginationContext } from '../../pagination/store/paginationProvider';
import List from '../../../views/dynamic-bundle/automated-bundles/List/List';
import { SFYImportExport } from '../module-exports';
import { NavigationController } from '../../pagination/module-exports';
import { t } from '../../../system/ui';

// for id declarations could be used uuid()
// statement below has to be placed to parent component
// const [tab, setTab] = useState([
//   {
//     id: 452,
//     active: active,
//     componentName: 'z List',
//     children: <>z List</>,
//   },
//   {
//     id: 452,
//     active: active,
//     componentName: 'z List',
//     children: <>z List</>,
//   },
// ]);

/**
 * @name SFYTab
 * @description SFYTab is a component that renders dynamic tab
 * @param {Array} tabs - array of objects
 * @param {function} tabsSetter - function that sets the state of tabs
 * @param {object} location - location object
 * @param {boolean} alignCenter - aligns tabs to center
 * @param {boolean} isImportVisible - import export buttons visibility
 * @param {object} importExportOptions - import export buttons options
 * @param {Array} selectedItems - id array of selected items
 * @param {object} selectedItemActions - functions for selected items, like delete, edit, etc.
 * @param {boolean} w - has bundle item in list (category or product)
 * @returns {JSX.Element}
 * @constructor
 */

const SFYTab = ({
  tabs = [],
  tabsSetter,
  alignCenter,
  location,
  isImportVisible = false,
  importExportOptions,
  selectedItems,
  selectedItemActions,
  hasBundleItemInList,
}) => {
  const {
    state: { pagination, searchQueryString },
    paginationSetter,
    searchQuerySetter,
  } = useContext(PaginationContext);

  const [activeTab, setActiveTab] = useState(tabs[0].componentValue);

  useEffect(() => {
    selectedItemActions.clearAllItems();
  }, [pagination.pageNumber]);

  const handleTabClick = id => {
    const newTab = tabs.map(t => {
      if (t.id === id) {
        return { ...t, active: true };
      }
      return { ...t, active: false };
    });

    setActiveTab(newTab.filter(t => t.active)[0].componentValue);
    tabsSetter(newTab);
  };

  return (
    <>
      <div className={classes['sfy-tab-main-wrapper']}>
        <div
          className={
            alignCenter
              ? classes['sfy-tab-container--align-center']
              : classes['sfy-tab-container']
          }
        >
          {tabs.map(({ id, componentName, active }) => (
            <div
              className={`${classes['sfy-tab']} ${
                active ? classes['--active'] : classes['sfy-tab']
              }`}
              onClick={() => {
                handleTabClick(id);
              }}
            >
              <span key={id}>{componentName}</span>
            </div>
          ))}
        </div>

        <div className={classes['button-wrapper']}>
          <NavigationController
            linkToRoute={tabs[0].paginationOptions.linkToRoute}
            action={tabs[0].paginationOptions.action}
            text={t(tabs[0].paginationOptions.text)}
            isExternalButton
          />
          {activeTab === 'product' && isImportVisible && (
            <SFYImportExport buttons={importExportOptions} />
          )}
        </div>
      </div>

      {tabs.map(({ id, active, componentValue, listOptions }) => {
        if (active) {
          const deleteButtonText =
            selectedItems.length > 0 ? t('Delete Selected') : t('Delete All');
          return (
            <div key={id}>
              <SFYPagination
                location={location}
                pagination={pagination}
                linkToRoute=''
                action={selectedItemActions.deleteItems}
                stateSetter={searchQuerySetter}
                state={searchQueryString}
                paginationSetter={paginationSetter}
                navigationController={hasBundleItemInList}
                text={deleteButtonText}
                analyticsType='products'
                navButtonIconOptions={{
                  iconName: 'trashBin',
                  iconColor: '#CC3034',
                }}
              />
              <List
                linkToDetail={listOptions.linkToDetail}
                modalInvoker={listOptions.modalInvoker}
                isCategory={componentValue === 'category'}
                bundleHead
                tableHead
                bundleHeadConstant={listOptions.bundleHeadConstant}
                selectedItems={selectedItems}
                selectedItemActions={selectedItemActions}
              />
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default SFYTab;
