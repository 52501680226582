import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { uiActions, wizardActions, modalActions } from '../../../actions';
import {
  criterionTypeButtons,
  exclusionButtons,
  inclusionButtons,
  getCurrentIntelligents,
  getStaticAlgorithms,
} from '../../../constants/datamaps/wizard';
import { passiveCampaignTypes } from '../utils';

import Icon from '../../../components/icon';
import Icons from '../../../components/icons';
import Criteria from '../../../components/wizard/criteria';
import Excludes from '../../../components/wizard/excludes';
import Includes from '../../../components/wizard/includes';
import ProductSelection from '../../../components/wizard/product-selection';
import IntelligentSelectionModal from '../../../components/wizard/selectionModal.intelligent';

import { t } from '../../../system/ui';

const selectionModals = {
  intelligent: {
    icon: 'brain',
    iconSvg: true,
    title: 'Intelligent Recommendation Types',
    subtitle:
      'Select from Segmentify Intelligent Recommendation Types to create your personalised recommendation campaigns',
    modal: IntelligentSelectionModal,
  },
  static: {
    icon: 'asteriks',
    iconSvg: true,
    title: 'Static Recommendation Types',
    subtitle:
      'Select from Segmentify Static Recommendation Types to create your personalised recommendation campaigns',
    modal: IntelligentSelectionModal,
  },
};

class Recommendations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      excludes: [],
      shuffle: true,
      hideExcludes: true,
      hideIncludes: true,
      showSingleProductErr: false,
      hasError: false,
      showRequiredError: false,
      includeTypes: [],
      excludeTypes: [],
    };

    this.button = this.button.bind(this);
    this.addDetailsRow = this.addDetailsRow.bind(this);
    this.addCriterion = this.addCriterion.bind(this);
    this.addInclusionRow = this.addInclusionRow.bind(this);
    this.addExclusionRow = this.addExclusionRow.bind(this);
    this.scrollToElement = this.scrollToElement.bind(this);
    this.addProducts = this.addProducts.bind(this);
    this.validate = this.validate.bind(this);
    this.onButtonsScroll = this.onButtonsScroll.bind(this);
    this.openAlgorithmSelection = this.openAlgorithmSelection.bind(this);
    this.toggleFilterAction = this.toggleFilterAction.bind(this);
    this.handleShuffle = this.handleShuffle.bind(this);
    this.setShuffle = this.setShuffle.bind(this);
  }

  componentDidMount() {
    this.onButtonsScroll();
    this.setShuffle(this.props.campaign);
    if (this.props.includes.length) {
      this.setState({
        hideIncludes: false,
      });
    }
    if (this.props.excludes.length) {
      this.setState({
        hideExcludes: false,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.excludes !== newProps.excludes) {
      if (newProps.excludes.length > 0) {
        this.setState({
          hideExcludes: false,
        });
      }
    }

    if (newProps.includes.length > 0) {
      this.setState({
        hideIncludes: false,
      });
    }

    if (newProps.disableCustom !== undefined && newProps.disableCustom) {
      this.setState({
        hideExcludes: true,
        hideIncludes: true,
      });
    }
    if (newProps.criteria.length < 1) {
      this.setState({
        showSingleProductErr: false,
      });
    }

    if (this.props.includes !== newProps.includes) {
      this.setState({
        includeTypes: newProps.includes.map(include => include.type),
      });
    }

    if (this.props.excludes !== newProps.excludes) {
      this.setState({
        excludeTypes: newProps.excludes.map(exclude => exclude.type),
      });
    }

    if (this.props.campaign !== newProps.campaign) {
      this.setShuffle(newProps.campaign);
    }
  }

  componentDidUpdate(oldProps) {
    if (
      this.props.criteria !== oldProps.criteria ||
      this.props.excludes !== oldProps.excludes
    ) {
      this.validate();
    }
  }

  handleShuffle() {
    this.setState({ shuffle: !this.state.shuffle });
  }

  onButtonsScroll() {
    const containerEl = document.getElementById('scroll-container');
    const subtitle = document.getElementsByClassName('item-title-sub__rec')[0];
    const el = document.getElementsByClassName('wizard-input-types')[0];
    const elTop =
      el.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top;
    const heighttoset = `${el.offsetHeight + subtitle.offsetHeight}px`;
    containerEl.style.height = heighttoset;
    window.addEventListener('scroll', function() {
      if (
        document.documentElement.scrollTop >
        elTop - el.offsetHeight - subtitle.offsetHeight / 2
      ) {
        el.className = 'wizard-input-types sticky';
      } else {
        el.className = 'wizard-input-types ';
      }
    });
  }

  setShuffle(campaign) {
    this.setState({
      shuffle: campaign.ordering === 'SHUFFLE',
    });
  }

  toggleFilterAction(action) {
    this.setState({ [action]: false }, () => {
      let el =
        action === 'hideIncludes' ? 'includesToScroll' : 'excludesToScroll';
      el = this.refs[el];
      // this.scrollToElement(el, action);
    });
  }

  scrollToElement(item) {
    const diff = item.getBoundingClientRect().top + window.scrollY - 300;
    if (Math.abs(diff) > 1) {
      window.scrollTo(0, window.scrollY + diff + 150);
      clearTimeout(window._TO);
      window._TO = setTimeout(this.scrollToItem, 30, item);
    } else {
      window.scrollTo(0, item.offsetTop);
    }
  }

  validate() {
    if (this.props.excludes.length === 0 && this.props.criteria.length === 0) {
      this.setState({
        hasError: true,
        showRequiredError: true,
      });
    } else {
      this.setState({
        hasError: false,
        showRequiredError: false,
      });
    }
  }

  addDetailsRow(type, algorithms) {
    if (algorithms) {
      this.addCriterion(type, algorithms);
    } else if (type === 'excludes') {
      this.setState({ hideExcludes: '' });
      const scrollSize =
        document
          .querySelector('.wizard-criterion:last-child')
          .getBoundingClientRect().top + 100;
      if (document.querySelector('.wizard-criterion:last-child')) {
        scrollTo(document.body, scrollSize, 800);
      }
    } else if (type === 'includes') {
      this.setState({ hideIncludes: '' });
      const scrollSize =
        document
          .querySelector('.wizard-criterion:last-child')
          .getBoundingClientRect().top + 100;
      if (document.querySelector('.wizard-criterion:last-child')) {
        scrollTo(document.body, scrollSize, 800);
      }
    } else if (type === 'product') {
      const existingProductIds = [];
      this.props.criteria.forEach(item => {
        if (item.type === 'product') {
          existingProductIds.push(item.values.productId.value);
        }
      });
      const content = () => (
        <ProductSelection
          addProducts={this.addProducts}
          existingProducts={existingProductIds}
        />
      );
      uiActions.openModal({ title: t('Select Products'), content });
    } else {
      this.addCriterion(type);
    }
  }

  addInclusionRow(type) {
    let current = this.props.includes;
    current = current.concat({ id: Math.random(), type, values: {} });
    wizardActions.addInclusion(current);
  }

  addExclusionRow(type) {
    let current = this.props.excludes;
    current = current.concat({ id: Math.random(), type, values: {} });
    wizardActions.addExclusion(current);
  }

  addCriterion(type, algorithms = []) {
    const current = this.props.criteria;
    const selectedAlgorithms = Object.assign([], this.props.criteria);
    if (this.props.campaign.messageType === 'product' && current.length > 1) {
      this.setState({ showSingleProductErr: true });
    } else {
      const staticItems = [];
      const dynamicItems = [];
      const newItems = [];
      let newCriteria = [];
      let finalAlgorithms = [];
      const staticAlgorithms = getStaticAlgorithms();
      if (algorithms) {
        const isEmail = this.props.campaign.defaultRecommendation;
        if (isEmail) {
          finalAlgorithms = selectedAlgorithms.concat(
            algorithms.map(algorithm => {
              if (type === 'static') {
                const staticType =
                  staticAlgorithms[algorithm.values.criterion.value];
                return { id: Math.random(), type: staticType, values: {} };
              }
              return algorithm;
            }),
          );
          wizardActions.addCriteria(finalAlgorithms);
        } else if (type === 'static') {
          current.forEach(item => {
            if (item.type === 'product') {
              staticItems.push(item);
            } else {
              dynamicItems.push(item);
            }
          });
          algorithms.forEach(algorithm => {
            const staticType =
              staticAlgorithms[algorithm.values.criterion.value];
            if (staticType === 'product') {
              staticItems.push({
                id: Math.random(),
                type: staticType,
                values: {},
              });
            } else {
              dynamicItems.push({
                id: Math.random(),
                type: staticType,
                values: {},
              });
            }
          });
          wizardActions.addCriteria(staticItems.concat(dynamicItems));
        } else {
          finalAlgorithms = selectedAlgorithms.concat(algorithms);
          wizardActions.addCriteria(finalAlgorithms);
        }
      } else {
        current.forEach(item => {
          if (item.type === 'product') {
            staticItems.push(item);
          } else {
            dynamicItems.push(item);
          }
        });
        newItems.push({ id: Math.random(), type, values: {} });
        if (type === 'product') {
          newCriteria = staticItems.concat(newItems, dynamicItems);
        } else {
          newCriteria = staticItems.concat(dynamicItems, newItems);
        }
        wizardActions.addCriteria(newCriteria);
        /* Scroll to latest criterion element */
        if (type !== 'product') {
          if (document.querySelector('.wizard-criterion:last-child')) {
            scrollTo(
              document.body,
              document
                .querySelector('.wizard-criterion:last-child')
                .getBoundingClientRect().top - 500,
              600,
            );
          }
        }
      }
    }
  }

  button(item, type, cb, filterType) {
    let currentIcon = '';
    if (
      item.icon === 'excludeLabel' ||
      item.icon === 'excludeGender' ||
      item.icon === 'excludeCustom' ||
      item.iconSvg
    ) {
      currentIcon = (
        <Icons
          name={item.icon}
          color={
            item.icon.indexOf('asteriks') < 0 && item.icon.indexOf('brain') < 0
              ? '#787779'
              : '#fff'
          }
        />
      );
    } else {
      currentIcon = <Icon name={item.icon} />;
    }
    const isSelectedCriteria =
      (filterType === 'inclusion' &&
        this.state.includeTypes.indexOf(type) > -1) ||
      (filterType === 'exclusion' &&
        this.state.excludeTypes.indexOf(type) > -1);
    return (
      <a
        key={type}
        className={classNames('button', item.className, {
          'include-exclude-selected': isSelectedCriteria,
          'custom-include-exclude-selected': type === 'custom',
          'pointer-no-event':
            (type === 'intelligent' || type === 'static') &&
            (passiveCampaignTypes.includes(this.props.emailType) ||
              (this.props.emailType === 'TOP_SELLERS' && type === 'static')),
        })}
        onClick={cb.bind(null, type)}
      >
        {isSelectedCriteria && (
          <span>
            <Icons name='checkboxSelected' width='15' height='15' />
          </span>
        )}
        {currentIcon}
        {t(item.title)}
      </a>
    );
  }

  addProducts(products) {
    modalActions.closeModal();
    const current = this.props.criteria;
    const staticItems = [];
    const dynamicItems = [];
    const newItems = [];
    let newCriteria = [];
    products.forEach(product => {
      newItems.push({
        id: Math.random(),
        type: 'product',
        values: { productId: { value: product.productId } },
      });
    });
    current.forEach(item => {
      if (item.type === 'product') {
        staticItems.push(item);
      } else {
        dynamicItems.push(item);
      }
    });
    newCriteria = staticItems.concat(newItems, dynamicItems);
    wizardActions.addCriteria(newCriteria);
  }

  openAlgorithmSelection(obj) {
    const selectionModal = selectionModals[obj];
    const Modal = selectionModal.modal;
    const { props } = this;
    let currentIntelligents = getCurrentIntelligents(
      props.selectedPage,
      props.isPromotion,
      props.isKeyword,
      props.isSingleRec,
      props.isEmail,
      props,
    );

    if (props.emailType === 'TOP_SELLERS') {
      currentIntelligents = {
        RECOMMENDATION_STATISTICAL_TOP_SELLERS: 'Top Sellers',
        RECOMMENDATION_TOP_SELLERS: 'Personalised Top Sellers',
      };
    } else if (
      props.emailType === 'WE_MISSED_YOU' ||
      props.emailType === 'CHURN' ||
      props.emailType === 'BIRTHDAY' ||
      props.emailType === 'ANNIVERSARY'
    ) {
      currentIntelligents = {
        RECOMMENDATION_SMART_OFFERS: 'Smart Offers',
        ...currentIntelligents,
      };
    } else if (props.emailType === 'ORDER_FOLLOW_UP') {
      currentIntelligents = {
        RECOMMENDATION_COLLABORATIVE_FILTERING: 'Complementary Products',
        ...currentIntelligents,
      };
    } else if (props.emailType === 'LAST_VISIT_ALTERNATIVES') {
      currentIntelligents = {
        RECOMMENDATION_BROWSING_ALTERNATIVES: 'Browsing Alternatives',
        ...currentIntelligents,
      };
    } else if (props.emailType === 'NICE_TO_MEET_YOU') {
      currentIntelligents = {
        RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
        ...currentIntelligents,
      };
    }

    if (props.isEmail) {
      const selectedAlgorithms = props.criteria?.map(
        item => item?.values?.criterion?.value,
      );
      selectedAlgorithms.forEach(item => {
        if (currentIntelligents[item]) {
          delete currentIntelligents[item];
        }
      });
    }
    const currentStatics = getStaticAlgorithms();

    const disabledStatics = [];
    this.props.criteria.forEach(criteria => {
      if (criteria.type === 'product') {
        disabledStatics.push(`STATIC-${criteria.type.toUpperCase()}`);
      }
    });

    modalActions.openModal({
      title: t(selectionModal.title),
      subtitle: t(selectionModal.subtitle),
      icon: selectionModal.icon,
      className: 'wizard-comp-modal',
      content: () => (
        <Modal
          currentAlgorithms={
            obj === 'intelligent' ? currentIntelligents : currentStatics
          }
          addDetailsRow={this.addDetailsRow}
          pageProps={props}
          disabledStatics={disabledStatics}
        />
      ),
    });
  }

  render() {
    const isSingleRec = this.props.campaign.messageType === 'product';
    const isEmail =
      this.props.isEmail ||
      (this.props.campaign.defaultRecommendation &&
        this.props.campaign.defaultRecommendation.length) ||
      (this.props.campaign.additionalRecommendations &&
        this.props.campaign.additionalRecommendations.length) ||
      (this.props.campaign.type &&
        (this.props.campaign.type === 'FLY' ||
          this.props.campaign.type === 'RECOMMENDATION'));
    const criterionButtonsToShow = criterionTypeButtons;
    return (
      <div>
        <li className='item-title-field'>
          <div className='wizard-comp'>
            {isSingleRec ? (
              <h3 className='wizard-comp-title item-title-sub item-title-sub__rec'>
                {t('Recommendation')}
              </h3>
            ) : (
              <h3
                className='wizard-comp-title item-title-sub item-title-sub__rec'
                style={{ fontWeight: 600 }}
              >
                {t('Recommendations')}
              </h3>
            )}
            <p className='wizard-comp-description'>
              {t(
                'Select from these algorithms to create your recommendations based on your needs.',
              )}
            </p>
            {this.state.showSingleProductErr ? (
              <span className='item-error'>(*You can select only 1 item)</span>
            ) : (
              ''
            )}
            <div id='scroll-container'>
              <div className='wizard-input-types'>
                {Object.keys(criterionButtonsToShow).map(item => {
                  return this.button(
                    criterionButtonsToShow[item],
                    item,
                    this.openAlgorithmSelection,
                  );
                })}
                <div className='open-filters-buttons'>
                  {!isEmail && (
                    <label className='item item-stacked is-checkbox is-shuffle'>
                      <input
                        type='checkbox'
                        checked={this.state.shuffle}
                        onChange={this.handleShuffle}
                      />
                      <span className='item-label'>
                        <Icon name='shuffle' />
                        {this.props.isPromotion
                          ? t('Shuffle Promotions')
                          : t('Shuffle Items')}
                      </span>
                    </label>
                  )}
                  <div
                    className={classNames(
                      'open-filters open-filters--inclusion',
                      {
                        'filters-disabled': this.props.disableCustom,
                      },
                    )}
                    onClick={this.toggleFilterAction.bind(null, 'hideIncludes')}
                  >
                    <Icons name='plusCircleSmall' color='#787779' />
                    {t('Include Filters')}
                  </div>

                  <div
                    className={classNames(
                      'open-filters open-filters--exclusion',
                      {
                        'filters-disabled': this.props.disableCustom,
                      },
                    )}
                    onClick={this.toggleFilterAction.bind(null, 'hideExcludes')}
                  >
                    <Icons name='minusCircleSmall' color='#787779' />
                    {t('Exclude Filters')}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                !isEmail
                  ? 'wizard-input-type-wrapper'
                  : 'wizard-input-type-wrapper wizard-input-criteria wizard-email-input-type-wrapper'
              }
            >
              <div className='wizard-criteria wizard-items-container'>
                <Criteria
                  isSingleRec={isSingleRec}
                  isEmail={isEmail}
                  emailType={isEmail ? this.props.campaign.type : ''}
                />
                {this.state.showRequiredError &&
                this.props.emailType !== 'FLY' ? (
                  <span className='item-error has-error'>
                    {t(
                      'You should make at least one selection from Segmentify Intelligent or Static algorithms',
                    )}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </li>
        <li>
          <div
            className={classNames('wizard-comp wizard-comp-filters', {
              'is-hidden': this.state.hideIncludes,
            })}
            ref='includesToScroll'
          >
            <h3 className='wizard-comp-title section-title section-title-criteria'>
              {t('Criteria')}
            </h3>
            <p className='wizard-comp-description'>
              {t(
                'Select from these algorithms to create your recommendations based on your needs.',
              )}
            </p>
            {Object.keys(inclusionButtons).map(item => {
              return this.button(
                inclusionButtons[item],
                item,
                this.addInclusionRow,
                'inclusion',
              );
            })}
            <div className='wizard-includes wizard-input-type-wrapper'>
              <div className='wizard-criteria wizard-items-container'>
                <Includes />
              </div>
            </div>
          </div>
        </li>
        <li>
          <div
            className={classNames('wizard-comp wizard-comp-filters', {
              'is-hidden': this.state.hideExcludes,
            })}
            ref='excludesToScroll'
          >
            <h3 className='wizard-comp-title section-title'>{t('Excludes')}</h3>
            <p className='wizard-comp-description'>
              {t(
                'Select from these algorithms to create your recommendations based on your needs.',
              )}
            </p>
            {Object.keys(exclusionButtons).map(item => {
              return this.button(
                exclusionButtons[item],
                item,
                this.addExclusionRow,
                'exclusion',
              );
            })}
            <div className='wizard-includes wizard-input-type-wrapper'>
              <div className='wizard-criteria wizard-items-container'>
                <Excludes />
              </div>
            </div>
          </div>
        </li>
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  validate: store.ui.validate,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes,
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  Recommendations,
);
