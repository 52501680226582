import { calculateDiffMetric } from './functions';
import { calculateSegmentRatio } from '../../../system/ratio';
import {
  toLocaleStringDigits,
  localeStringMoney,
  formatValue,
  formatMoneyValue,
} from '../../../system/string';

export const getDatasForSegment = (segmentName, stat) => {
  const data = {
    title: [],
    segment: [],
    other: [],
    difRatio: [],
  };
  const averageDaysBetweenOrders = stat.averageDaysBetweenOrders
    ? Math.round(stat.averageDaysBetweenOrders)
    : 0;
  const otherAverageDaysBetweenOrders = stat.otherAverageDaysBetweenOrders
    ? Math.round(stat.otherAverageDaysBetweenOrders)
    : 0;
  const averageDaysSinceLastOrder = stat.averageDaysSinceLastOrder
    ? Math.round(stat.averageDaysSinceLastOrder)
    : 0;
  const otherAverageDaysSinceLastOrder = stat.otherAverageDaysSinceLastOrder
    ? Math.round(stat.otherAverageDaysSinceLastOrder)
    : 0;

  switch (segmentName) {
    case 'Active Loyals':
      data.title = [
        'Days Between Orders',
        'Discount Usage',
        'Average Order Value',
        'Average Order Count',
      ];
      data.segment = [
        averageDaysBetweenOrders,
        `${stat.averageDiscountUsage} %`,
        formatMoneyValue(stat.averageOrderValue),
        formatValue(stat.averageOrderCount),
      ];
      data.other = [
        otherAverageDaysBetweenOrders,
        `${stat.otherAverageDiscountUsage} %`,
        formatMoneyValue(stat.otherAverageOrderValue),
        formatValue(stat.otherAverageOrderCount),
      ];
      data.diffRatio = [
        calculateDiffMetric(
          averageDaysBetweenOrders,
          otherAverageDaysBetweenOrders,
        ),
        calculateDiffMetric(
          stat.averageDiscountUsage,
          stat.otherAverageDiscountUsage,
        ),
        calculateDiffMetric(
          stat.averageOrderValue,
          stat.otherAverageOrderValue,
        ),
        calculateDiffMetric(
          stat.averageOrderCount,
          stat.otherAverageOrderCount,
        ),
      ];
      break;
    case 'Big Spenders':
      data.title = [
        'Lifetime Value',
        'Discount Usage',
        'Average Order Value',
        'Average Order Count',
      ];
      data.segment = [
        formatMoneyValue(stat.averageCustomerLifetimeValue),
        `${stat.averageDiscountUsage} %`,
        formatMoneyValue(stat.averageOrderValue),
        formatValue(stat.averageOrderCount),
      ];
      data.other = [
        formatMoneyValue(stat.otherAverageCustomerLifetimeValue),
        `${stat.otherAverageDiscountUsage} %`,
        formatMoneyValue(stat.otherAverageOrderValue),
        formatValue(stat.otherAverageOrderCount),
      ];
      data.diffRatio = [
        calculateDiffMetric(
          stat.averageCustomerLifetimeValue,
          stat.otherAverageCustomerLifetimeValue,
        ),
        calculateDiffMetric(
          stat.averageDiscountUsage,
          stat.otherAverageDiscountUsage,
        ),
        calculateDiffMetric(
          stat.averageOrderValue,
          stat.otherAverageOrderValue,
        ),
        calculateDiffMetric(
          stat.averageOrderCount,
          stat.otherAverageOrderCount,
        ),
      ];
      break;
    case 'Loyals at Risk':
      data.title = [
        'Days Since Last Order',
        'Lifetime Value',
        'Average Order Value',
        'Average Order Count',
      ];
      data.segment = [
        averageDaysSinceLastOrder,
        formatMoneyValue(stat.averageCustomerLifetimeValue),
        formatMoneyValue(stat.averageOrderValue),
        formatValue(stat.averageOrderCount),
      ];
      data.other = [
        otherAverageDaysSinceLastOrder,
        formatMoneyValue(stat.otherAverageCustomerLifetimeValue),
        formatMoneyValue(stat.otherAverageOrderValue),
        formatValue(stat.otherAverageOrderCount),
      ];
      data.diffRatio = [
        calculateDiffMetric(
          averageDaysSinceLastOrder,
          otherAverageDaysSinceLastOrder,
        ),
        calculateDiffMetric(
          stat.averageCustomerLifetimeValue,
          stat.otherAverageCustomerLifetimeValue,
        ),
        calculateDiffMetric(
          stat.averageOrderValue,
          stat.otherAverageOrderValue,
        ),
        calculateDiffMetric(
          stat.averageOrderCount,
          stat.otherAverageOrderCount,
        ),
      ];
      break;
    case 'Richie Riches':
      data.title = [
        'Average Order Value',
        'Lifetime Value',
        'Average Product Price',
        'Discount Usage',
      ];
      data.segment = [
        formatMoneyValue(stat.averageOrderValue),
        formatMoneyValue(stat.averageCustomerLifetimeValue),
        formatMoneyValue(stat.averageProductPrice),
        `${stat.averageDiscountUsage} %`,
      ];
      data.other = [
        formatMoneyValue(stat.otherAverageOrderValue),
        formatMoneyValue(stat.otherAverageCustomerLifetimeValue),
        formatMoneyValue(stat.otherAverageProductPrice),
        `${stat.otherAverageDiscountUsage} %`,
      ];
      data.diffRatio = [
        calculateDiffMetric(
          stat.averageOrderValue,
          stat.otherAverageOrderValue,
        ),
        calculateDiffMetric(
          stat.averageCustomerLifetimeValue,
          stat.otherAverageCustomerLifetimeValue,
        ),
        calculateDiffMetric(
          stat.averageProductPrice,
          stat.otherAverageProductPrice,
        ),
        calculateDiffMetric(
          stat.averageDiscountUsage,
          stat.otherAverageDiscountUsage,
        ),
      ];
      break;
    case 'Bulk Shoppers':
      data.title = [
        'Average Order Count',
        'Items Per Order',
        'Average Order Value',
        'Lifetime Value',
      ];
      data.segment = [
        formatValue(stat.averageOrderCount),
        stat.itemsPerOrder,
        formatMoneyValue(stat.averageOrderValue),
        formatMoneyValue(stat.averageCustomerLifetimeValue),
      ];
      data.other = [
        formatValue(stat.otherAverageOrderCount),
        stat.otherItemsPerOrder,
        formatMoneyValue(stat.otherAverageOrderValue),
        formatMoneyValue(stat.otherAverageCustomerLifetimeValue),
      ];
      data.diffRatio = [
        calculateDiffMetric(
          stat.averageOrderCount,
          stat.otherAverageOrderCount,
        ),
        calculateDiffMetric(stat.itemsPerOrder, stat.otherItemsPerOrder),
        calculateDiffMetric(
          stat.averageOrderValue,
          stat.otherAverageOrderValue,
        ),
        calculateDiffMetric(
          stat.averageCustomerLifetimeValue,
          stat.otherAverageCustomerLifetimeValue,
        ),
      ];
      break;
    case 'Discount Seekers':
      data.title = [
        'Average Order Value',
        'Days Since Last Order',
        'Discount Usage',
        'Lifetime Value',
      ];
      data.segment = [
        formatMoneyValue(stat.averageOrderValue),
        averageDaysSinceLastOrder,
        `${stat.averageDiscountUsage} %`,
        formatMoneyValue(stat.averageCustomerLifetimeValue),
      ];
      data.other = [
        formatMoneyValue(stat.otherAverageOrderValue),
        otherAverageDaysSinceLastOrder,
        `${stat.otherAverageDiscountUsage} %`,
        formatMoneyValue(stat.otherAverageCustomerLifetimeValue),
      ];
      data.diffRatio = [
        calculateDiffMetric(
          stat.averageOrderValue,
          stat.otherAverageOrderValue,
        ),
        calculateDiffMetric(
          averageDaysSinceLastOrder,
          otherAverageDaysSinceLastOrder,
        ),
        calculateDiffMetric(
          stat.averageDiscountUsage,
          stat.otherAverageDiscountUsage,
        ),
        calculateDiffMetric(
          stat.averageCustomerLifetimeValue,
          stat.otherAverageCustomerLifetimeValue,
        ),
      ];
      break;
    case 'Churn Candidates':
    case 'Lost Customers':
      data.title = [
        'Days Between Orders',
        'Days Since Last Order',
        'Average Order Value',
        'Lifetime Value',
      ];
      data.segment = [
        averageDaysBetweenOrders,
        averageDaysSinceLastOrder,
        formatMoneyValue(stat.averageOrderValue),
        formatMoneyValue(stat.averageCustomerLifetimeValue),
      ];
      data.other = [
        otherAverageDaysBetweenOrders,
        otherAverageDaysSinceLastOrder,
        formatMoneyValue(stat.otherAverageOrderValue),
        formatMoneyValue(stat.otherAverageCustomerLifetimeValue),
      ];
      data.diffRatio = [
        calculateDiffMetric(
          averageDaysBetweenOrders,
          otherAverageDaysBetweenOrders,
        ),
        calculateDiffMetric(
          averageDaysSinceLastOrder,
          otherAverageDaysSinceLastOrder,
        ),
        calculateDiffMetric(
          stat.averageOrderValue,
          stat.otherAverageOrderValue,
        ),
        calculateDiffMetric(
          stat.averageCustomerLifetimeValue,
          stat.otherAverageCustomerLifetimeValue,
        ),
      ];
      break;
    case 'One Time Shoppers':
      data.title = [
        'Average Order Value',
        'Days Since Last Order',
        'Discount Usage',
        'Average Product Price',
      ];
      data.segment = [
        formatMoneyValue(stat.averageOrderValue),
        averageDaysSinceLastOrder,
        `${stat.averageDiscountUsage} %`,
        formatMoneyValue(stat.averageProductPrice),
      ];
      data.other = [
        formatMoneyValue(stat.otherAverageOrderValue),
        otherAverageDaysSinceLastOrder,
        `${stat.otherAverageDiscountUsage} %`,
        formatMoneyValue(stat.otherAverageOrderValue),
      ];
      data.diffRatio = [
        calculateDiffMetric(
          stat.averageOrderValue,
          stat.otherAverageOrderValue,
        ),
        calculateDiffMetric(
          averageDaysSinceLastOrder,
          otherAverageDaysSinceLastOrder,
        ),
        calculateDiffMetric(
          stat.averageDiscountUsage,
          stat.otherAverageDiscountUsage,
        ),
        calculateDiffMetric(
          stat.averageProductPrice,
          stat.otherAverageOrderValue,
        ),
      ];
      break;
    default:
      break;
  }
  return data;
};

export const getTableData = (segmentName, stat) => {
  const opts = {
    currencyFormat: '{{code}}{{num}}',
  };
  return [
    {
      metric: 'Customer Count',
      segment: toLocaleStringDigits(stat.userCount),
      other: toLocaleStringDigits(stat.otherUserCount),
    },
    {
      metric: 'Customer Ratio',
      segment: `${
        stat.otherUserCount !== 0
          ? calculateSegmentRatio(
              stat.userCount,
              stat.userCount + stat.otherUserCount,
            )
          : 0
      }%`,
      other: `${
        stat.userCount !== 0
          ? calculateSegmentRatio(
              stat.otherUserCount,
              stat.userCount + stat.otherUserCount,
            )
          : 0
      }%`,
    },
    {
      metric: 'Average Order Count',
      segment: toLocaleStringDigits(stat.averageOrderCount),
      other: toLocaleStringDigits(stat.otherAverageOrderCount),
    },
    {
      metric: 'Average Order Value',
      segment: localeStringMoney(stat.averageOrderValue, false, opts),
      other: localeStringMoney(stat.otherAverageOrderValue, false, opts),
    },
    {
      metric: 'Average Purchased Product Price',
      segment: localeStringMoney(stat.averageProductPrice, false, opts),
      other: localeStringMoney(stat.otherAverageProductPrice, false, opts),
    },
    {
      metric: 'Average Customer Lifetime Value',
      segment: localeStringMoney(
        stat.averageCustomerLifetimeValue,
        false,
        opts,
      ),
      other: localeStringMoney(
        stat.otherAverageCustomerLifetimeValue,
        false,
        opts,
      ),
    },
    {
      metric: 'Average Number of Days Between Orders',
      segment: toLocaleStringDigits(
        stat.averageDaysBetweenOrders
          ? Math.round(stat.averageDaysBetweenOrders)
          : 0,
      ),
      other: toLocaleStringDigits(
        stat.otherAverageDaysBetweenOrders
          ? Math.round(stat.otherAverageDaysBetweenOrders)
          : 0,
      ),
    },
    {
      metric: 'Avarage Number of Days Since Last Order',
      segment: toLocaleStringDigits(
        stat.averageDaysSinceLastOrder
          ? Math.round(stat.averageDaysSinceLastOrder)
          : 0,
      ),
      other: toLocaleStringDigits(
        stat.otherAverageDaysSinceLastOrder
          ? Math.round(stat.otherAverageDaysSinceLastOrder)
          : 0,
      ),
    },
    {
      metric: 'Total Revenue',
      segment: localeStringMoney(stat.revenue, false, opts),
      other: localeStringMoney(stat.otherRevenue, false, opts),
    },
    {
      metric: 'Total Revenue Share',
      segment: `${
        stat.otherRevenue !== 0
          ? calculateSegmentRatio(
              stat.revenue,
              stat.revenue + stat.otherRevenue,
            )
          : 0
      }%`,
      other: `${
        stat.revenue !== 0
          ? calculateSegmentRatio(
              stat.otherRevenue,
              stat.revenue + stat.otherRevenue,
            )
          : 0
      }%`,
    },
    ...(segmentName === 'Bulk Shoppers'
      ? [
          {
            metric: 'Items Per Order',
            segment: toLocaleStringDigits(stat.itemsPerOrder),
            other: toLocaleStringDigits(stat.otherItemsPerOrder),
          },
        ]
      : []),
  ];
};

export const segmentPieChartConfig = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    height: 150,
    options3d: {
      enabled: false,
      alpha: 25,
      beta: 0,
    },
  },
  tooltip: {
    pointFormat: '<b>{point.percentage:.2f}%</b>',
  },

  title: {
    text: '',
    align: 'left',
    x: 0,
    style: {
      fontSize: '1.125rem',
      fontFamily: 'montserrat, helvetica, arial, sans-serif',
      fontWeight: 'bold',
      color: '#78767a',
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      size: '140%',
      allowPointSelect: true,
      cursor: 'pointer',
      colors: ['#4F7398', '#DBDBDB'],
      dataLabels: {
        enabled: false,
      },
      showInLegend: false,
    },
  },
  legend: {
    layout: 'horizontal',
    align: 'center',
    verticalAlign: 'bottom',
    useHTML: true,
    itemStyle: {
      color: '#606062',
      fontFamily: 'inherit',
    },
    labelFormatter() {
      return (
        `${'' +
          '<div class="dashboard--funnel__halfbox--legend">' +
          '<div class="title">'}${this.name}</div>` +
        '<div class="count-container">' +
        `<span class="count" style="color:${this.color}">${this.y_label}</span>` +
        'views' +
        '</div>' +
        '</div>'
      );
    },
    symbolWidth: 0,
    symbolHeight: 0,
    x: -40,
  },
  series: [
    {
      name: 'Pages',
      colorByPoint: true,
      type: 'pie',
      data: [],
    },
  ],
};
