import React from 'react';
import { connect } from 'react-redux';

import MailSendComponent from './mailsent.component';
import { TextField } from '../fields';
import { t } from '../../system/ui';
import { getQueryString } from '../../system/string';
import {
  postForgottenPassword,
  postV1ForgottenPassword,
} from '../../modules/auth/user';

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      isSent: false,
    };
    this.onChangeParams = this.onChangeParams.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const email = getQueryString('email');
    this.setState({ email });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email } = this.state;
    let isSent = false;

    postForgottenPassword(email, response => {
      if (
        response.status === 'OK' ||
        response.message === 'Please check your mail box.'
      ) {
        isSent = true;
      }
      postV1ForgottenPassword(email, responseV1 => {
        if (responseV1.status === 'OK') {
          isSent = true;
        }
        this.setState({ isSent });
      });
    });
  }

  onChangeParams(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    if (this.state.isSent)
      return (
        <MailSendComponent
          color='green'
          title={t('Reset password link has been sent to your email address.')}
          text={t(
            'Please reset your account password by following instruction in the email.',
          )}
        />
      );
    return (
      <div className='lgn-container'>
        <div className='login-container'>
          <h1 className='has-text-align-center'>{t('Forgot Password')}</h1>
          <form
            id='forgot-password-form'
            method='POST'
            onSubmit={this.handleSubmit}
          >
            <div id='email-field' className='input-field'>
              <TextField
                id='email'
                name='email'
                autocomplete='off'
                className='labelClass'
                type='email'
                isSearch
                inputClassName='inputClassName'
                label={t('E-Mail')}
                value={this.state.email}
                onChange={this.onChangeParams}
              />
            </div>
            <div id='submit-field' className='input-field'>
              <button className='full-width' id='sign-up' type='submit'>
                {t('Send')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  language: store.ui.language,
});

export default connect(mapStateToProps)(ForgotPasswordForm);
