import React from 'react';
import PropTypes from 'prop-types';

import * as classes from '../../constants/ui.classes';
import { t } from '../../system/ui';
import Icons from '../icons';

class Checkbox extends React.Component {
  render() {
    const optional = {};
    let _name = this.props.name;
    let _onChange = this.props.onChange;
    let className = 'item item-field is-checkbox';

    if (typeof this.context.checkboxGroup !== 'undefined') {
      const { name, onChange, checkedValues } = this.context.checkboxGroup;

      _name = name;
      _onChange = onChange;

      if (checkedValues) {
        optional.checked = checkedValues.indexOf(this.props.value) >= 0;
      }
    }

    if (typeof _onChange === 'function') {
      optional.onChange = _onChange.bind(null, this.props.value);
    }

    if (optional.checked) {
      className += ' is-checked';
    }

    return (
      <label className={className}>
        <input {...this.props} type='checkbox' name={_name} {...optional} />
        <span className={classes.fieldLabel} title={t(this.props.label)}>
          {this.props.icon && (
            <Icons
              name={this.props.icon}
              color={this.props.iconColor || '#000000'}
            />
          )}
          {t(this.props.label)}
        </span>
      </label>
    );
  }
}

Checkbox.contextTypes = {
  checkboxGroup: PropTypes.object,
};

export default Checkbox;
