import React from 'react';
import { clone } from 'system/object';
import PropTypes from 'prop-types';
import { uiActions } from 'actions';
import { uploadFileWaitUrl } from 'modules/upload/ajax';
import { t } from 'system/ui';
import { v1 } from 'uuid';
import CardWrapper from '../faceted-search/common/CardWrapper';
import Icons from '../../icons';
import { Calendar, TextField } from '../../fields';
import { isValidStartDate, reformatDateByTimeZone } from '../../../system/date';

const methodOptions = ['Upload', 'URL'];

export default class Banners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: this.addUniqueKeys(this.props.banners) ?? [],
    };
    this.searchandisingPositions = [
      'RESULTS_FOOTER',
      'RESULTS_HEADER',
      'FILTERS_FOOTER',
      'FILTERS_HEADER',
    ];
    this.instantSearchPositions = [
      'RESULTS_FOOTER',
      'RESULTS_HEADER',
      'ASSETS_FOOTER',
      'ASSETS_HEADER',
    ];
    this.inputFileRefs = new Map();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.banners) !== JSON.stringify(this.props.banners)
    ) {
      this.setState({
        banners: clone(this.addUniqueKeys(this.props.banners)),
      });
    }
  }

  getBanners = () => {
    return this.state.banners.map(banner => {
      const copied = clone(banner);
      delete copied.key;
      return copied;
    });
  };

  addUniqueKeys(banners) {
    return banners.map(banner => ({
      ...banner,
      key: v1(),
    }));
  }

  onMethodChange = (index, method) => {
    this.setState(
      prevState => {
        const banners = clone(prevState.banners);
        banners[index].method = method;
        banners[index].bannerUrl = null;
        return {
          banners,
        };
      },
      () => this.props.onChange?.(this.state.banners),
    );
  };

  addBanner = () => {
    if (this.state.banners.length < 5) {
      this.setState(
        prevState => ({
          banners: prevState.banners.concat({
            method: 'URL',
            name: null,
            bannerUrl: null,
            targetUrl: null,
            position: 'RESULTS_FOOTER',
            width: '100%',
            height: 'auto',
            newtab: false,
            endDate: '',
            isEndDateVisible: false,
            key: v1(),
          }),
        }),
        () => this.props.onChange?.(this.state.banners),
      );
    } else {
      const notificationContent = () => (
        <span>Maximum number of banners (5) has been reached</span>
      );
      uiActions.showErrorNotification(notificationContent);
    }
  };

  removeBanner = index => {
    this.setState(
      prevState => {
        const banners = clone(prevState.banners);
        banners.splice(index, 1);
        return {
          banners,
        };
      },
      () => this.props.onChange?.(this.state.banners),
    );
  };

  onPropertyChange = (index, event, property) => {
    event.persist();
    this.setState(
      prevState => {
        const banners = clone(prevState.banners);
        banners[index][property] = event.target.value;
        return {
          banners,
        };
      },
      () => this.props.onChange?.(this.state.banners),
    );
  };

  handleDateChange = (index, date) => {
    this.setState(
      prevState => {
        const banners = clone(prevState.banners);
        banners[index].endDate = date;
        return {
          banners,
        };
      },
      () => this.props.onChange?.(this.state.banners),
    );
  };

  onNewtabChange = (index, event) => {
    event.persist();
    this.setState(
      prevState => {
        const banners = clone(prevState.banners);
        banners[index].newtab = event.target.checked;
        return {
          banners,
        };
      },
      () => this.props.onChange?.(this.state.banners),
    );
  };

  handleDateCheckBox = (index, event) => {
    event.persist();
    this.setState(
      prevState => {
        const banners = clone(prevState.banners);
        banners[index].isEndDateVisible = event.target.checked;
        return {
          banners,
        };
      },
      () => this.props.onChange?.(this.state.banners),
    );
  };

  onUploadClick = index => this.inputFileRefs.get(index)?.click();

  onFileChange = (event, index) => {
    const feedBackFile = event.target.files[0];

    if (feedBackFile.size / (1024 * 1024) >= 5) {
      uiActions.notifyRequestError({
        statusText:
          'File is too big to upload! Please try another file below 5MB',
      });
      return;
    }

    uploadFileWaitUrl({ feedBackFile }, 'PUSH', response => {
      if (response) {
        this.setState(
          prevState => {
            const banners = clone(prevState.banners);
            banners[index].bannerUrl = response.replace(/['"]+/g, '');
            return {
              banners,
            };
          },
          () => this.props.onChange?.(this.state.banners),
        );
      }
    });
  };

  render() {
    const { banners } = this.state;
    const { isInstant, removeOlParentClass } = this.props;
    const positionOptions = isInstant
      ? this.instantSearchPositions
      : this.searchandisingPositions;
    return (
      <CardWrapper
        id='search-banner'
        title='Banners'
        description='Add a banner to customize your search box.'
        headerRightComponent={
          <button
            type='button'
            className='add-facet-button'
            onClick={this.addBanner}
          >
            <div className='add-facet-button-inner'>
              <label htmlFor='-'>Add Banners</label>
            </div>
          </button>
        }
        removeOlParentClass={removeOlParentClass}
      >
        {banners.map((banner, index) => {
          const {
            bannerUrl,
            height,
            method,
            name,
            position,
            targetUrl,
            width,
            key,
            newtab,
            endDate,
            isEndDateVisible,
          } = banner;
          return (
            <div
              key={key}
              className='wizard-criterion faceted-search-result search-banner'
              style={{ display: 'block' }}
            >
              <div className='radio-button-group-close-button'>
                <ul className='whitelist-radio'>
                  {methodOptions.map(methodOption => (
                    <li
                      onClick={() => this.onMethodChange(index, methodOption)}
                      style={{
                        display: 'inline',
                        fontSize: '14px',
                      }}
                    >
                      <Icons
                        name={
                          method === methodOption
                            ? 'radioSelected'
                            : 'radioEmpty'
                        }
                        width='14'
                        height='14'
                      />
                      <span>{methodOption}</span>
                    </li>
                  ))}
                </ul>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.removeBanner(index)}
                >
                  <Icons name='crossCircle' />
                </span>
              </div>

              <div>
                {method === 'URL' ? (
                  <TextField
                    className='item-stacked one-whole'
                    value={bannerUrl}
                    onChange={event =>
                      this.onPropertyChange(index, event, 'bannerUrl')}
                    validation={{ URL: true }}
                    label='Image URL'
                    isURL
                    required
                  />
                ) : (
                  <div className='icon-with-upload'>
                    <img src={bannerUrl ?? '/images/layer.png'} />
                    <div className='search-banner-upload-button-container'>
                      <div
                        className='search-banner-upload-button'
                        onClick={() => this.onUploadClick(index)}
                      >
                        Upload
                        <i className='icon-upload' />
                      </div>
                      {!bannerUrl && (
                        <span className='item-error'>
                          {t('This field is required')}
                        </span>
                      )}
                    </div>
                    <input
                      accept='image/jpeg,image/png'
                      type='file'
                      ref={ref => this.inputFileRefs.set(index, ref)}
                      style={{ display: 'none' }}
                      onChange={event => this.onFileChange(event, index)}
                    />
                  </div>
                )}
                <TextField
                  className='item-stacked one-whole'
                  value={name}
                  onChange={event =>
                    this.onPropertyChange(index, event, 'name')}
                  required
                  label='Banner Name'
                />
                <TextField
                  className='item-stacked one-whole'
                  value={targetUrl}
                  onChange={event =>
                    this.onPropertyChange(index, event, 'targetUrl')}
                  validation={{ URL: true }}
                  isURL
                  required
                  label='Target URL'
                />
                <div className='measurement-container'>
                  <TextField
                    className='item-stacked'
                    value={width}
                    onChange={event =>
                      this.onPropertyChange(index, event, 'width')}
                    label='Width'
                  />
                  <TextField
                    className='item-stacked'
                    value={height}
                    onChange={event =>
                      this.onPropertyChange(index, event, 'height')}
                    label='Height'
                  />
                  <div className='position-container'>
                    <span className='item-label'> Position </span>
                    <label className='item is-select'>
                      <select
                        className='criteria-field'
                        value={position}
                        onChange={event =>
                          this.onPropertyChange(index, event, 'position')}
                      >
                        {positionOptions.map(_position => (
                          <option value={_position}>{t(_position)}</option>
                        ))}
                      </select>
                    </label>
                  </div>
                </div>
                <label className='item item-stacked is-checkbox'>
                  <input
                    type='checkbox'
                    checked={newtab}
                    onChange={event => this.onNewtabChange(index, event)}
                  />
                  <span className='item-label'>Open Target URL in New Tab</span>
                </label>
                <div className='search-banner-endDate'>
                  <label className='item item-stacked is-checkbox'>
                    <input
                      type='checkbox'
                      checked={isEndDateVisible}
                      onChange={event => this.handleDateCheckBox(index, event)}
                    />
                    <span className='item-label'>End Date Option</span>
                  </label>
                  {isEndDateVisible ? (
                    <Calendar
                      className='item-stacked one-whole'
                      isValidDate={isValidStartDate}
                      value={reformatDateByTimeZone(endDate)}
                      onChange={e =>
                        this.handleDateChange(index, new Date(e).getTime())
                      }
                    />
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </CardWrapper>
    );
  }
}

Banners.defaultProps = {
  banners: [],
  removeOlParentClass: true,
};

Banners.propTypes = {
  banners: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  removeOlParentClass: PropTypes.bool,
  isInstant: PropTypes.bool.isRequired,
};
