import React, { Component } from 'react';

import { t } from '../../system/ui';
import { useRedirectDuplicate } from './utils/index';

import {
  disabledDuplicateTypes,
  disabledReportTypes,
} from './constants/campaign-actions-constants';

import { modalActions, uiActions } from '../../actions';
import { isSuperUser } from '../auth/user';

import Icons from '../../components/icons';

import { moveCampaignToArchive } from '../campaign/events';
import CampaignHistoryModal from '../campaigns/historymodal';

class CampaignActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [
        { name: 'Edit', icon: 'gear', action: 'edit', newPanelOnly: true },
        { name: 'Report', icon: 'pie', action: 'report' },
        {
          name: 'Duplicate',
          icon: 'duplicate',
          action: 'duplicate',
          newPanelOnly: true,
        },
        { name: 'Archive', icon: 'archive', action: 'addToArchive' },
        {
          name: 'History',
          icon: 'history',
          iconSvg: true,
          action: 'showCampaignHistory',
        },
      ],
      dropUp: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.edit = this.edit.bind(this);
    this.report = this.report.bind(this);
    this.duplicate = this.duplicate.bind(this);
    this.addToArchive = this.addToArchive.bind(this);
    this.addToArchiveConfirmed = this.addToArchiveConfirmed.bind(this);
    this.showCampaignHistory = this.showCampaignHistory.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    setTimeout(() => {
      this.handleScroll();
    }, 800);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    if (this.refs.actionMenu) {
      const partOfDocHeight =
        (document.documentElement.offsetHeight * 70) / 100;
      const listTop = this.refs.actionMenu.getBoundingClientRect().top;
      this.setState({
        dropUp: listTop > partOfDocHeight,
      });
    }
  }

  handleClick(action) {
    this[action]();
  }

  edit() {
    if (this.props.type === 'wheel-of-fortune') {
      uiActions.redirect(
        `/behavioural-targeting/engagement/gamification/wof/edit/${this.props.instanceId}`,
      );
    } else if (this.props.type === 'scratch') {
      uiActions.redirect(
        `/behavioural-targeting/engagement/gamification/scratch/edit/${this.props.instanceId}`,
      );
    } else if (this.props.type === 'pop-up-banner-countdown') {
      uiActions.redirect(
        `/behavioural-targeting/engagement/countdown/popup/edit/${this.props.instanceId}`,
      );
    } else if (this.props.type === 'hero-banner-countdown') {
      uiActions.redirect(
        `/behavioural-targeting/engagement/countdown/hero/edit/${this.props.instanceId}`,
      );
    } else if (this.props.type === 'notification-bar-countdown') {
      uiActions.redirect(
        `/behavioural-targeting/engagement/countdown/notification/edit/${this.props.instanceId}`,
      );
    } else {
      uiActions.redirect(
        `/campaign/${this.props.type}/edit/${this.props.instanceId}`,
      );
    }
  }

  report() {
    uiActions.redirect(`/reports/single/engagement/${this.props.instanceId}`);
  }

  duplicate() {
    uiActions.redirect(
      useRedirectDuplicate({
        campaignType: this.props.type,
        instanceId: this.props.instanceId,
      }),
    );
  }

  addToArchive() {
    uiActions.confirmationDialog({
      onConfirm: this.addToArchiveConfirmed,
    });
  }

  addToArchiveConfirmed() {
    moveCampaignToArchive(this.props.instanceId)
      .then(response => {
        const message = () => (
          <p>
            Campaign <strong>{response.response.name}</strong> moved to Archive
          </p>
        );
        uiActions.showNotification({ content: message });

        this.props.onUpdate(
          response.response.instanceId,
          response.response.status,
        );
      })
      .catch(response => {
        const error = () => <p>{response.response.message}</p>;
        uiActions.showErrorNotification(error);
      });
  }

  showCampaignHistory() {
    const campaignId = this.props.instanceId;
    const campaignName = this.props.campaign.name;
    const campaignHistoryModal = () => (
      <CampaignHistoryModal campaignId={campaignId} engagement />
    );

    modalActions.openModal({
      title: `Campaign History (${campaignName})`,
      content: campaignHistoryModal,
    });
  }

  render() {
    const disableReport = disabledReportTypes.includes(this.props?.type);
    const disableDuplicate = disabledDuplicateTypes.includes(this.props?.type);
    let { actions } = this.state;
    let listClass = 'widget-nav-popup action-nav-popup';
    if (this.state.dropUp) {
      actions = this.state.actions.slice().reverse();
      listClass = 'widget-nav-popup action-nav-popup drop-up';
    }
    return (
      <nav
        className='widget-nav action-nav'
        aria-haspopup='true'
        ref='actionMenu'
      >
        <a className='secondary-action'>{t('Actions')}</a>

        <ul className={listClass}>
          {actions.map(({ action, name, superUserOnly, icon }) => {
            if (action === 'duplicate' && disableDuplicate) {
              return false;
            }
            if (action === 'report' && disableReport) {
              return false;
            }
            if (!isSuperUser() && superUserOnly) {
              return;
            }
            if (action === 'duplicate' && this.props.isReplicate) {
              return '';
            }

            return (
              <li key={action}>
                <a
                  className='widget-nav-popup-item action-nav-popup-item'
                  onClick={this.handleClick.bind(null, action)}
                >
                  <Icons name={icon} color='#fff' />
                  {this.props.isReplicate && name === 'Edit'
                    ? t('Replicate')
                    : t(name)}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default CampaignActions;
