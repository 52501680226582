import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import './ExperimentationActionButton.scss';

const ExperimentationActionButton = ({
  hideRemove,
  onAddNewVariation,
  onRemoveVariation,
  setFieldTouched,
}) => {
  const [open, setOpen] = useState(false);

  const outsideClickHandler = ({ target }) => {
    if (!target.matches('.dropbtn')) {
      setOpen(false);
    }
  };

  const onOpenClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    window.addEventListener('click', outsideClickHandler);
    return () => {
      window.removeEventListener('click', outsideClickHandler);
    };
  }, []);

  useEffect(() => {
    if (open) setFieldTouched('variations');
  }, [open]);

  return (
    <div className='dropdown'>
      <div className='dropbtn' onClick={onOpenClick} />
      <div
        className={classNames('dropdown-content', {
          open,
        })}
      >
        <div onClick={onAddNewVariation} className='dropdown-item'>
          Add New Row
        </div>
        {!hideRemove && (
          <div onClick={onRemoveVariation} className='dropdown-item'>
            Remove Row
          </div>
        )}
      </div>
    </div>
  );
};

export default ExperimentationActionButton;
