import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PersonaQuiz = ({
  width = '24.8px',
  height = '28.27px',
  color = '#CFCFCF',
}) => {
  const [state, setState] = useState({
    width: '',
    height: '',
    color: '',
  });

  const setStyles = () => {
    setState({
      width,
      height,
      color,
    });
  };

  useEffect(() => {
    setStyles();
  }, [width, height, color]);

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 25 30'
      width={state.width}
      height={state.height}
      className='svg-icon'
      fill='none'
    >
      <path
        d='M21.0262 9.62755C23.0808 9.62755 24.7617 11.4503 24.893 13.7546L24.9012 14.0451V24.6466C24.9012 26.9885 23.3024 28.905 21.2811 29.0545L21.0262 29.0641H11.7268C9.67226 29.0641 7.99119 27.2412 7.86 24.9369L7.85175 24.6466V14.0451C7.85175 11.7029 9.45064 9.78651 11.472 9.63696L11.7268 9.62755H21.0262ZM21.0262 11.3946H11.7268C10.5182 11.3946 9.52505 12.4457 9.4124 13.7898L9.40175 14.0451V24.6466C9.40175 26.0241 10.3238 27.1564 11.5028 27.2849L11.7268 27.2971H21.0262C22.2348 27.2971 23.228 26.2459 23.3405 24.9017L23.3512 24.6466V14.0451C23.3512 12.6673 22.4291 11.5351 21.2502 11.4067L21.0262 11.3946ZM16.6277 3.78769L16.7016 4.06582L17.5933 7.86032H15.9875L15.2044 4.52315C14.8916 3.19237 13.7439 2.3708 12.5758 2.59461L12.3568 2.64896L3.3742 5.39281C2.20685 5.7494 1.48618 7.05779 1.68252 8.38929L1.73018 8.63901L4.13707 18.8792C4.40821 20.0327 5.30673 20.8037 6.302 20.8427L6.30219 22.6108C4.7104 22.5739 3.25768 21.4126 2.72076 19.6378L2.63989 19.3365L0.233005 9.09634C-0.298737 6.83402 0.810566 4.51115 2.72906 3.77027L2.97303 3.68602L11.9557 0.942166C13.8575 0.361247 15.808 1.49845 16.5393 3.51918L16.6277 3.78769Z'
        fill={state.color}
      />
    </svg>
  );
};

PersonaQuiz.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

PersonaQuiz.defaultProps = {
  width: '1.55rem',
  height: '1.766875rem',
  color: '#CFCFCF',
};

export default PersonaQuiz;
