/**
 * @author Mehmet Yurtar
 */

"use strict";

import React from "react";

import { t } from "../system/ui";
import ReportClickChart from "../components/reports/chart.click";
import ReportPurchaseChart from "../components/reports/chart.purchase";
import ContributionChart from "../components/reports/chart.contribution";
import {
  hasKlaviyoConfiguration
} from "../modules/auth/user";

class GraphView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let emailFields = [];
    if(!hasKlaviyoConfiguration()) {
      emailFields.push({
        name: "Email [sent]",
        isVisible: true,
        type: "line",
        yAxis: 0
      });
      emailFields.push({
        name: "Email [opened]",
        isVisible: true,
        type: "line",
        yAxis: 0
      });
    }
    if(hasKlaviyoConfiguration()){
      emailFields.push({
        name: "Triggered To Flow",
        isVisible: true,
        type: "line",
        yAxis: 0
      });
      emailFields.push({
        name: "Triggered To Profile",
        isVisible: true,
        type: "line",
        yAxis: 0
      });
    }
    emailFields.push({
      name: "Email [clicked]",
      isVisible: true,
      type: "line",
      yAxis: 0
    });
    let pushFields = [];
    pushFields.push({
      name: "Notification [sent]",
      label: "Push [sent]",
      isVisible: true,
      type: "line",
      yAxis: 0
    });
    pushFields.push({
      name: "Notification [shown]",
      label: "Push [viewed]",
      isVisible: true,
      type: "line",
      yAxis: 0
    });
    pushFields.push({
      name: "Notification [clicked]",
      label: "Push [clicked]",
      isVisible: true,
      type: "line",
      yAxis: 0
    });
    return (
      <div>
        <div className="dashboard-graph">
          <div className="dashboard-graph--title">
            <h3>{t("Conversion Graph")}</h3>
          </div>
          <div className="dashboard-graph--content">
            {this.props.selectedModule === "E-Mail" && (
              <ReportClickChart
                stats={this.props.trends || []}
                customFrame={this.props.interval}
                chartRenderCount={this.props.chartRenderCount}
                fieldsToHide={["Widget View", "Click", "Impression"]}
                emailFields={emailFields}
              />
            )}
            {this.props.selectedModule === "Push" && (
              <ReportClickChart
                stats={this.props.trends || []}
                customFrame={this.props.interval}
                chartRenderCount={this.props.chartRenderCount}
                fieldsToHide={["Widget View", "Click", "Impression"]}
                pushFields={pushFields}
              />
            )}
            {this.props.selectedModule !== "E-Mail" &&
              this.props.selectedModule !== "Push" && (
                <ReportClickChart
                  stats={this.props.trends || []}
                  customFrame={this.props.interval}
                  chartRenderCount={this.props.chartRenderCount}
                />
              )}
          </div>
        </div>
        <div className="dashboard-graph">
          <div className="dashboard-graph--title">
            <h3>{t("Revenue Graph")}</h3>
          </div>
          <div className="dashboard-graph--content">
            <ReportPurchaseChart
              stats={this.props.trends}
              customFrame={this.props.interval}
              chartRenderCount={this.props.chartRenderCount}
            />
          </div>
        </div>
        <div className="dashboard-graph">
          <div className="dashboard-graph--title">
            <h3>{t("Contribution Graph")}</h3>
          </div>
          <div className="dashboard-graph--content">
            <ContributionChart
              trends={this.props.trends}
              customFrame={this.props.interval}
              chartRenderCount={this.props.chartRenderCount}
              stats={this.props.stats}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default GraphView;
