import { createAction } from '../system/store';

export const updateSwitchUser = user => {
  // TODO switchedUser  hafizada tutan sistem
  // localStorage.setItem('_su', JSON.stringify(user));
  createAction('UPDATE_SWITCH_USER', { user });
};

export const updateMailConfInSwitched = conf => {
  createAction('UPDATE_MAIL_CONF_IN_SWITCHED', { conf });
};

export const resetMailConfInSwitched = () => {
  createAction('RESET_MAIL_CONF_IN_SWITCHED');
};

export const updateRegionInSwitched = (
  mainRegion,
  additionalCurrencyRegionList,
) => {
  createAction('UPDATE_REGION_IN_SWITCHED', {
    mainRegion,
    additionalCurrencyRegionList,
  });
};

export default {
  updateSwitchUser,
  updateMailConfInSwitched,
  resetMailConfInSwitched,
  updateRegionInSwitched,
};
