import React from 'react';

class PinIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: '15px',
      height: '15px',
      color: '#fff',
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + 'px' : this.state.width,
      height = this.props.height ? this.props.height + 'px' : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 15 15'
        width={this.state.width}
        height={this.state.height}
        className='svg-icon'
      >
        <path
          fill={this.state.color}
          d='M13.8011 4.81824L10.1819 1.19892C9.1864 0.203465 7.50827 0.47579 6.87865 1.73495L5.05211 5.38811C4.98634 5.51965 4.87167 5.6202 4.73266 5.66822L1.60796 6.74765C0.953432 6.97378 0.7615 7.80703 1.25115 8.2967L3.57952 10.6251L0.25006 13.9545L0.25 14.7501H1.04555L4.37502 11.4205L6.70337 13.749C7.19305 14.2386 8.0263 14.0467 8.25243 13.3921L9.3319 10.2674C9.3799 10.1284 9.48048 10.0138 9.61195 9.94798L13.2651 8.12143C14.5243 7.4918 14.7966 5.81368 13.8011 4.81824Z'
        />
      </svg>
    );
  }
}

export default PinIcon;
