import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const WeMissedYou = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.1092 13.4992C8.1572 12.9841 8.2943 12.4758 8.5207 11.9992H2.25278C1.01076 11.9992 0.00390625 13.0061 0.00390625 14.2481V14.8258C0.00390625 15.7185 0.322416 16.582 0.902186 17.2609C2.46849 19.0952 4.8545 20.0004 8.0004 20.0004C9.4131 20.0004 10.6729 19.8178 11.7743 19.4506L10.5593 18.2356C9.7962 18.4118 8.9441 18.5004 8.0004 18.5004C5.26206 18.5004 3.29618 17.7546 2.04287 16.2869C1.69502 15.8795 1.50391 15.3614 1.50391 14.8258V14.2481C1.50391 13.8345 1.83919 13.4992 2.25278 13.4992H8.1092ZM8.0004 0.00390625C10.7618 0.00390625 13.0004 2.24248 13.0004 5.00391C13.0004 7.76533 10.7618 10.0039 8.0004 10.0039C5.23894 10.0039 3.00036 7.76533 3.00036 5.00391C3.00036 2.24248 5.23894 0.00390625 8.0004 0.00390625ZM8.0004 1.50391C6.06737 1.50391 4.50036 3.07091 4.50036 5.00391C4.50036 6.9369 6.06737 8.5039 8.0004 8.5039C9.9334 8.5039 11.5004 6.9369 11.5004 5.00391C11.5004 3.07091 9.9334 1.50391 8.0004 1.50391ZM15.0439 20.996C14.8519 20.9961 14.6599 20.9228 14.5135 20.7764L10.1052 16.3681C8.7514 15.0142 8.7514 12.8192 10.1052 11.4654C11.4591 10.1116 13.6541 10.1116 15.0079 11.4654L15.0439 11.5014L15.0799 11.4654C16.4337 10.1116 18.6287 10.1116 19.9826 11.4654C21.3364 12.8192 21.3364 15.0142 19.9826 16.3681L15.5743 20.7764C15.4279 20.9228 15.2359 20.9961 15.0439 20.996Z'
        fill='#5D5D61'
      />
    </svg>
  );
};

WeMissedYou.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

WeMissedYou.defaultProps = {
  width: '21px',
  height: '21px',
};

export default WeMissedYou;
