import React, { Component } from 'react';
import { connect } from 'react-redux';

import { engagementConfirmOnLeave } from '../actions/ui';
import { t } from '../system/ui';
import { setTitle } from '../system/document';

import AddCampaign from '../modules/campaign/add';

class AddSegmentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 'Segmentation',
      content: [{ field: 'segment' }],
      contentExtra: {
        buttons: [],
        fields: [{ field: 'segmentationOptions' }],
      },
      options: [
        { field: 'name' },
        { field: 'startEndDate' },
        { field: 'devices' },
        { field: 'pages' },
      ],
      buttons: [{ field: 'goTest' }, { field: 'goLive' }],
    };
  }

  componentDidMount() {
    setTitle(t('Add Segmentation'));
    engagementConfirmOnLeave(this);
  }

  render() {
    return (
      <AddCampaign
        type='SEGMENTATION'
        title={this.state.title}
        content={this.state.content}
        contentExtra={this.state.contentExtra}
        options={this.state.options}
        buttons={this.state.buttons}
        duplicate={this.props.routeParams.campaignId}
      />
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps)(AddSegmentation);
