import React, { useRef, useState } from 'react';
import classes from './UploadContactListModal.module.scss';
import { t } from '../../../../system/ui';
import Icon from '../../../../components/icon';

/**
 * @name UploadContactList component
 * @description xls file upload modal
 * @param {function} onChange - onChange function
 * @return {JSX.Element}
 */
const UploadContactList = ({ onChange }) => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileName, setFileName] = useState('');
  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const onFileDrop = e => {
    const newFile = e.target.files[0];
    if (newFile) {
      setFileName(newFile?.name);
      setIsUploaded(true);
      onChange(newFile);
    }
  };

  const downloadAsset = e => {
    e.preventDefault();
    const url = 'https://cdn.segmentify.com/v3/assets/User-Import-Sample.csv';
    fetch(url)
      .then(response => response.text())
      .then(data => {
        const dataBlob = new Blob([data], { type: 'text/csv;charset=utf-8' });
        const objUrl = URL.createObjectURL(dataBlob);
        const anchor = document.createElement('a');
        anchor.href = objUrl;
        anchor.download = 'User-Import-Sample.csv';
        document.body.appendChild(anchor);
        anchor.click();
      });
  };

  return (
    <div className={classes['upload-coupon-modal']}>
      <div className={classes['upload-coupon-modal__head']}>
        <div className={classes['upload-coupon-modal__head__title']}>
          {t('You can check your file format before uploading it.')}
          <a onClick={e => downloadAsset(e)}>{t('See Document Format')}</a>
        </div>
      </div>
      <div
        className={classes['upload-coupon-modal__input']}
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {isUploaded ? (
          <>
            <div className={classes['upload-coupon-modal__file-name']}>
              <Icon name='pdf' />
              <span>{fileName}</span>
            </div>
            <div className={classes['upload-coupon-modal__file-status']}>
              <Icon name='check' />
              <span>{t('Uploaded')}</span>
            </div>
          </>
        ) : (
          <>
            <div className={classes['upload-coupon-modal__icon']}>
              {t('Upload')}
              <Icon name='upload' />
            </div>
            <span>{t('Drag and drop or browse to choose .csv file.')}</span>
          </>
        )}

        <input type='file' onChange={onFileDrop} accept='.csv' />
      </div>
    </div>
  );
};

export default UploadContactList;
