const setQueryString = dispatch => {
  return queryString => {
    dispatch({
      type: 'SET_QUERY_STRING',
      payload: queryString,
    });
  };
};

const setUploadFile = dispatch => {
  return uploadFile => {
    dispatch({
      type: 'SET_UPLOAD_FILE',
      payload: uploadFile,
    });
  };
};

const setActiveEmailDetails = dispatch => {
  return emailDetails => {
    dispatch({
      type: 'SET_ACTIVE_EMAIL_DETAILS',
      payload: emailDetails,
    });
  };
};

const editActiveEmailDetails = dispatch => {
  return emailDetails => {
    dispatch({
      type: 'EDIT_ACTIVE_EMAIL_DETAILS',
      payload: emailDetails,
    });
  };
};

export {
  setQueryString,
  setUploadFile,
  setActiveEmailDetails,
  editActiveEmailDetails,
};
