import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const EmailSent = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 12 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.70848 10.2786C6.81825 10.4968 7.04084 10.6334 7.28273 10.6334C7.29632 10.6334 7.30901 10.6328 7.32206 10.6323C7.57861 10.6167 7.8012 10.4499 7.88791 10.2075L11.096 1.22375C11.1793 0.99077 11.121 0.72972 10.9451 0.554494C10.77 0.379984 10.5088 0.320264 10.2759 0.404301L1.29336 3.61272C1.05075 3.69872 0.883234 3.92133 0.867859 4.17737C0.852484 4.43413 0.99104 4.6748 1.22149 4.79031L4.16388 6.26095L7.92438 3.57481L5.23782 7.33644L6.70848 10.2786Z'
        fill='#79ADB6'
      />
    </svg>
  );
};

EmailSent.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

EmailSent.defaultProps = {
  width: '12px',
  height: '11px',
};

export default EmailSent;
