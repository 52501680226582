import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { filterActions } from '../../actions';
import { ExternalAppUtility } from '../../external';
import { getAllActiveCampaigns } from '../../modules/campaigns/ajax';
import {
  getAccountCurrency,
  getAdditionalCurrencies,
  getControlGroups,
  hasGlobalControl,
  hasModule,
} from '../../modules/auth/user';
import { clone } from '../../system/object';
import { t } from '../../system/ui';
import Icon from '../icon';
import Icons from '../icons';
import DeviceSelect from './device-select';

const getModules = () => {
  return [
    {
      label: 'Recommendation',
      value: 'recommendation',
      isActive: hasModule('recommendation'),
    },
    { label: 'Email Marketing', value: 'mail', isActive: hasModule('email') },
    { label: 'Web Push', value: 'push', isActive: hasModule('push') },
  ];
};
const moduleColors = {
  recommendation: 'red',
  mail: '#7578ab',
  push: '#bc86ad',
};

class SalesFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: '',
      campaigns: [],
      maxPriceMin: 0,
      maxPriceError: false,
      mode: 'ALL',
      currency: 'ALL',
      selectedModules: [],
    };

    this.reset = this.reset.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeMin = this.onChangeMin.bind(this);
    this.onChangeMax = this.onChangeMax.bind(this);
    this.onCampaignChange = this.onCampaignChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.onModeChange = this.onModeChange.bind(this);
    this.onCurrencyChange = this.onCurrencyChange.bind(this);
    this.onChangeCampaignList = this.onChangeCampaignList.bind(this);
  }

  componentDidMount() {
    const campaigns = [];
    const selectedModules = [
      ...(hasModule('recommendation') ? ['recommendation'] : []),
      ...(hasModule('email') ? ['mail'] : []),
      ...(hasModule('push') ? ['push'] : []),
    ];

    getAllActiveCampaigns(allCampaigns => {
      allCampaigns.map(item => {
        campaigns.push({
          value: `${item.instanceId}|${item.moduleType}` || '',
          label: item.scenarioName || item.name || '',
          module: item.moduleType || '',
          icon:
            item.moduleType === 'recommendation'
              ? 'heart'
              : `${item.moduleType}Module`,
          iconColor: moduleColors[item.moduleType],
        });
      });

      campaigns.sort(function(a, b) {
        const _a = a.label.toUpperCase();
        const _b = b.label.toUpperCase();

        if (_a < _b) {
          return -1;
        }
        if (_a > _b) {
          return 1;
        }

        return 0;
      });

      campaigns.unshift({ value: '', label: t('All Sales') });

      this.setState({
        campaigns,
        selectedModules,
      });
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.reset === true) {
      this.reset();
    }
  }

  onChangeMin(e) {
    filterActions.showButtons();
    filterActions.enableButtons();

    const maxPrice = this.refs.maxTotalPrice.value;

    this.setState({
      maxPriceMin: e.currentTarget.value,
    });

    if (maxPrice > 0 && e.currentTarget.value > maxPrice) {
      this.setState({
        maxPriceError: true,
      });

      filterActions.disableButtons();
    }
  }

  onChangeMax(e) {
    filterActions.showButtons();

    if (
      e.currentTarget.value !== '' &&
      e.currentTarget.value * 1 < this.state.maxPriceMin
    ) {
      this.setState({
        maxPriceError: true,
      });
      filterActions.disableButtons();
    } else {
      this.setState({
        maxPriceError: false,
      });

      filterActions.enableButtons();
    }
  }

  onChange() {
    filterActions.showButtons();
  }

  resetFilters(e) {
    e.preventDefault();
    this.setState(
      {
        maxPriceMin: 0,
      },
      () => {
        this.props.toggleAnalyticsFilters();
        filterActions.resetFilters();
      },
    );
  }

  updateFilters(e) {
    e.preventDefault();
    this.props.toggleAnalyticsFilters();
    if (this.props.disableButtons) {
      return false;
    }
    filterActions.updateFilters();
  }

  onCampaignChange(selectedOption) {
    this.setState({
      campaign: selectedOption ? selectedOption.value : '',
    });

    filterActions.showButtons();
  }

  reset() {
    this.refs.productId.value = '';
    this.refs.orderNo.value = '';
    this.refs.minTotalPrice.value = '';
    this.refs.maxTotalPrice.value = '';
    this.refs.viaBanners.checked = false;

    this.setState({
      campaign: '',
      mode: 'ALL',
      currency: 'ALL',
    });
  }

  onModeChange(selectedOption) {
    this.setState({
      mode: selectedOption ? selectedOption.value : '',
    });

    filterActions.showButtons();
  }

  onCurrencyChange(selectedOption) {
    this.setState({
      currency: selectedOption ? selectedOption.value : '',
    });
    filterActions.showButtons();
  }

  onChangeCampaignList(module) {
    this.setState({
      selectedModules:
        this.state.selectedModules.indexOf(module) > -1
          ? this.state.selectedModules.filter(selectedModule => {
              return selectedModule !== module;
            })
          : this.state.selectedModules.concat([module]),
    });
  }

  render() {
    const isSearchActive = hasModule('search');
    const modulesFilterOptions = [
      {
        filter: {
          value: 'search',
          label: 'All Sales by Search & Discovery',
          icon: 'search',
          iconColor: 'black',
        },
        isActive: isSearchActive,
      },
      {
        filter: {
          value: 'faceted',
          label: 'All Sales by Searchandising',
          icon: 'search',
          iconColor: 'black',
        },
        isActive: hasModule('faceted'),
      },
      {
        filter: {
          value: 'instant',
          label: 'All Sales by Search Box',
          icon: 'search',
          iconColor: 'black',
        },
        isActive: isSearchActive,
      },
      {
        filter: {
          value: 'pinned',
          label: 'All Sales by Pinned Products',
          icon: 'pinIcon',
          iconColor: '#7578AB',
        },
        isActive: isSearchActive,
      },

      {
        filter: {
          value: 'cancel',
          label: 'All Cancelled Sales',
          icon: 'cancelled',
          iconColor: 'black',
        },
        isActive: true,
      },
      {
        filter: {
          value: 'returned',
          label: 'All Returned Sales',
          icon: 'returnedMini',
          iconColor: '#FBA72A',
        },
        isActive: true,
      },
      {
        filter: {
          value: 'segmentify',
          label: 'All Sales by Segmentify',
          icon: 'segmentify',
          iconColor: 'black',
        },
        isActive: true,
      },
      {
        filter: {
          value: 'recommendation',
          label: 'All Sales by Recommendation',
          icon: 'iconHeart',
          iconColor: 'red',
        },
        isActive: hasModule('recommendation'),
      },
      {
        filter: {
          value: 'email',
          label: 'All Sales by Email Marketing',
          icon: 'email',
          iconColor: 'black',
        },
        isActive: hasModule('email'),
      },
      {
        filter: {
          value: 'push',
          label: 'All Sales by Web Push Notification',
          icon: 'pushModule',
          iconColor: 'lightgray',
        },
        isActive: hasModule('push'),
      },
    ];
    let filterOptions = modulesFilterOptions
      .filter(item => item.isActive)
      .map(item => item.filter);
    const defaultFilterOptionLength = filterOptions ? filterOptions.length : 0;

    const _filterOptions = ExternalAppUtility.getExternalSalesFilters();
    if (_filterOptions) {
      filterOptions = _filterOptions;
    }
    const tempOptions = this.state.campaigns.filter(
      campaign =>
        this.state.selectedModules.indexOf(campaign.module) > -1 ||
        campaign.value === '',
    );
    filterOptions.push(...tempOptions);
    let temp;
    if (filterOptions.length > defaultFilterOptionLength) {
      temp = clone(filterOptions[0]);
      filterOptions[0] = clone(filterOptions[defaultFilterOptionLength]);
      filterOptions[defaultFilterOptionLength] = clone(temp);
    }
    const currencyCodes = [{ value: 'ALL', label: 'All' }];
    const mainCurrency = getAccountCurrency() && getAccountCurrency().code;
    if (mainCurrency) {
      currencyCodes.push({ value: mainCurrency, label: mainCurrency });
    }
    getAdditionalCurrencies() &&
      getAdditionalCurrencies().forEach(language => {
        if (language !== mainCurrency) {
          currencyCodes.push({ value: language, label: language });
        }
      });
    let className = 'analytics-filters group hide-filters';
    if (this.props.showTrendifyFilters) {
      className = 'analytics-filters group show-filters';
    }

    const renderOption = option => {
      if (option.value === mainCurrency) {
        return (
          <span
            style={{
              color: '#00bac2',
              position: 'relative',
              width: '100%',
              display: 'block',
            }}
          >
            {option.value}
            <span style={{ position: 'absolute', right: '0' }}>
              <Icons name='star' color='#00bac2' width={14} height={14} />
            </span>
          </span>
        );
      }
      return <span>{option.value}</span>;
    };

    const renderCampaignOption = option => (
      <span
        style={{
          position: 'relative',
          width: '100%',
          display: 'block',
        }}
      >
        <span
          style={{ position: 'absolute', left: '0' }}
          className='campaign-icon-holder'
        >
          {option.module === 'recommendation' ? (
            <Icon
              name={option.icon}
              color={option.iconColor}
              width={14}
              height={14}
            />
          ) : (
            <Icons
              name={option.icon}
              color={option.iconColor}
              width={24}
              height={16}
            />
          )}
        </span>
        <span style={{ paddingLeft: '30px' }}>{option.label}</span>
      </span>
    );

    return (
      <div className={className}>
        <label className='item item-stacked is-select one-quarter sales-campaign-select'>
          <span className='item-label'>{t('Campaigns')}</span>
          {getModules()
            .filter(module => module.isActive)
            .map(module => (
              <span
                onClick={this.onChangeCampaignList.bind(this, module.value)}
                className='sales-campaign-checkbox'
                key={module.label}
              >
                <Icons
                  name={
                    this.state.selectedModules.indexOf(module.value) > -1
                      ? 'checkboxSelected'
                      : 'checkboxEmpty'
                  }
                  width='14'
                  height='14'
                />
                {module.label}
              </span>
            ))}
          <Select
            value={this.state.campaign}
            options={filterOptions}
            name='campaigns'
            onChange={this.onCampaignChange}
            optionRenderer={renderCampaignOption}
          />
        </label>
        {hasGlobalControl() && (
          <label className='item item-stacked is-select global-controls-group'>
            <span className='item-label'>{t('Groups')}</span>
            <Select
              value={this.state.mode}
              options={getControlGroups()}
              name='mode'
              onChange={this.onModeChange}
            />
          </label>
        )}

        <label className='item item-stacked'>
          <span className='item-label'>{t('Order No')}</span>
          <input
            type='text'
            id='orderNo'
            ref='orderNo'
            onChange={this.onChange}
          />
        </label>

        <label className='item item-stacked'>
          <span className='item-label'>{t('ProductID')}</span>
          <input
            type='text'
            id='productId'
            ref='productId'
            onChange={this.onChange}
          />
        </label>

        <label className='item item-stacked price-range price-range-min'>
          <span className='item-label'>{t('Price Range')}</span>
          <span className='item-label-alt'>{t('min')}:</span>
          <input
            type='number'
            id='minTotalPrice'
            ref='minTotalPrice'
            min='0'
            step='1'
            onChange={this.onChangeMin}
          />
        </label>

        <label className='item item-stacked price-range'>
          <span className='item-label'>&nbsp;</span>
          <span className='item-label-alt'>{t('max')}:</span>
          <input
            type='number'
            id='maxTotalPrice'
            ref='maxTotalPrice'
            min={this.state.maxPriceMin}
            step='1'
            onChange={this.onChangeMax}
          />
        </label>

        <DeviceSelect />

        {currencyCodes.length > 2 && (
          <label
            className='item item-stacked is-select'
            style={{ minWidth: '135px' }}
          >
            <span className='item-label'>{t('Currency*')}</span>
            <Select
              value={this.state.currency}
              options={currencyCodes}
              components={{ Option }}
              name='currency'
              onChange={this.onCurrencyChange}
              clearable={false}
              optionRenderer={renderOption}
            />
          </label>
        )}

        <label className='item item-stacked is-checkbox via-banners'>
          <input
            type='checkbox'
            id='viaBanners'
            ref='viaBanners'
            onChange={this.onChange}
          />
          <span className='item-label'>{t('Purchases via Banners')}</span>
        </label>

        {currencyCodes.length > 2 && (
          <div>
            <p style={{ fontSize: '12px', margin: '10px 0 5px 0' }}>
              *When All is selected, All of your sales will be converted to your
              account's main currency from the rate of the purchase date.
            </p>
            <p style={{ fontSize: '12px', margin: '5px 0 0 0' }}>
              *When the currency filter is applied to a single currency, only
              sales that are in that currency will be displayed.
            </p>
          </div>
        )}

        <div
          className='analytics-filters-close'
          onClick={this.props.toggleAnalyticsFilters}
        >
          <Icons name='cross' color='#7b7e7e' />
        </div>
        <div className='analytics-filters-actions'>
          {this.props.visibleReset && (
            <a
              className='cancel-action analytics-filters--reset'
              onClick={this.resetFilters}
            >
              {t('Reset Filters')}
            </a>
          )}

          <a
            className={classNames('tertiary-action analytics-filters--update', {
              disabled: this.props.disableButtons,
            })}
            onClick={this.updateFilters}
          >
            {t('Update')}
          </a>
        </div>
      </div>
    );
  }
}

const mapStatsToProps = store => ({
  reset: store.filters.reset,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  visibleReset: store.filters.visibleReset,
  disableButtons: store.filters.disableButtons,
});

export default connect(mapStatsToProps)(SalesFilters);
