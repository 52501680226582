import React, { Component } from 'react';
import { connect } from 'react-redux';

import Tooltip from 'components/tooltip';
import { uiActions } from 'actions';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: false,
    };

    this.updateValue = this.updateValue.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  componentDidMount() {
    let val = false;
    const propVal = this.props.campaign?.[this.props.options?.propName];
    val = propVal ?? val;
    this.updateValue(val);
  }

  componentWillReceiveProps(newProps) {
    if (
      this.props.campaign?.[this.props.options?.propName] !==
      newProps.campaign?.[newProps.options?.propName]
    ) {
      this.updateValue(newProps.campaign?.[newProps.options?.propName]);
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue }, () => {});
  }

  handleCheckBox() {
    this.updateValue(!this.state.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <li className='item item-stacked is-checkbox'>
        <span className='label-mail label-text-field'>
          <label className='item item-stacked is-checkbox'>
            <input
              type='checkbox'
              checked={this.state.value}
              onChange={this.handleCheckBox}
              key='force-delete-input-val'
            />

            <span className='item-label'>
              {this.props.options.label}
              {this.props.options.toolbar === true && (
                <Tooltip
                  content={this.props.options.tooltipContent}
                  alignment='right'
                >
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              )}
            </span>
          </label>
        </span>
      </li>
    );
  }
}

export default connect(null, null, null, { forwardRef: true })(Checkbox);
