import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../system/ui';
import Icons from '../../icons';
import { moduleMaps } from './constants';

/**
 * @name ModuleButton
 * @description - ModuleButton component is a button component which is used to create a new users
 * @param {boolean} isChecked
 * @param {function} onClick
 * @param {string} text
 * @param {string} height default: '20px'
 * @param {string} width default: '20px'
 * @returns {JSX.Element}
 */
const ModuleButton = ({
  isChecked,
  onClick,
  text,
  width = '20',
  height = '20',
}) => {
  const moduleText = moduleMaps[text];
  return (
    <div className='createuser-modules' onClick={onClick}>
      <span className='icon-create-user'>
        <Icons
          name={`${isChecked ? 'checkboxSelected' : 'checkboxEmpty'}`}
          width={width}
          height={height}
        />
      </span>
      {t(moduleText)}
    </div>
  );
};

export default ModuleButton;
