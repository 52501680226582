import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';

import { t } from '../../../system/ui';
import { setTitle } from '../../../system/document';

import { isSwitchAccount } from '../../../modules/auth/user';

import { getUserDetailsAsync } from '../../../modules/account/ajax';
import Snippet from '../../../components/snippet';
import { SearchUserForm } from './SearchUserForm';
import { resolveFilterSchema } from '../product-search/resolveFilterSchema';

const UserDetail = ({ switchedUser, user }) => {
  const { account } = isSwitchAccount() ? switchedUser : user;
  const [userState, setState] = useState({
    userDetail: '',
    userId: '',
    filterSchema: [],
    showFilter: false,
    sortable: false,
  });

  const { data } = useQuery({
    queryKey: [
      userState.userId,
      userState.sortable,
      userState.filterSchema,
      userState.showFilter,
    ],
    queryFn: () =>
      getUserDetailsAsync({
        dataCenter: account.dataCenter,
        accountId: account.accountId,
        userId: userState.userId,
      }),
    enabled: userState.userId !== '' && userState.userId.length > 2,
    onSuccess: response => {
      setState(prev => {
        return {
          ...prev,
          userDetail:
            userState.filterSchema && userState.filterSchema.length > 0
              ? JSON.stringify(
                  resolveFilterSchema({
                    rawData: response,
                    productFilterSchema: userState.filterSchema,
                    isSortable: userState.sortable,
                  }),
                  null,
                  2,
                )
              : JSON.stringify(response, null, 2),
        };
      });
    },
  });

  const handleOnSubmit = formData => {
    return setState({
      userId: formData.userId,
      userDetail: '',
      filterSchema: formData.userFilter,
      sortable: formData.sortResults,
      showFilter: formData.showFilter,
    });
  };

  useEffect(() => setTitle(t('User Search')), []);

  return (
    <div>
      <h2 className='page-title'>{t('User Search')}</h2>
      <div className='page-forms'>
        <div className='page-content-block one-whole'>
          <ol className='form-elements'>
            <li>
              <SearchUserForm onSubmit={handleOnSubmit} />
            </li>
            {data && (
              <li className='form-element'>
                <div style={{ height: '600px' }}>
                  <Snippet
                    value={userState.userDetail}
                    language={{
                      name: 'javascript',
                      json: true,
                    }}
                    options={{ readOnly: true, lineWrapping: false }}
                  />
                </div>
              </li>
            )}
          </ol>
        </div>
      </div>
    </div>
  );
};

UserDetail.defaultProps = {
  options: {
    label: 'User Details',
    name: 'text',
  },
};

const mapStatesToProps = store => ({
  user: store.user.user,
  validate: store.ui.validate,
  language: store.ui.language,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(UserDetail);
