import React, { useState, useEffect } from 'react';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import * as Components from '../../../../components/sfy-components/module-exports';
import { convertToPositiveInt } from '../../../../components/ruleBasedSegmentation/utils/utils';
import { t } from '../../../../system/ui';

const CampaignName = ({
  component,
  editCampaign,
  activeEmailDetails,
  emailType,
  readOnly,
}) => {
  const [hasError, setHasError] = useState(false);

  const editSchedule = (fieldKey, fieldValue) => {
    editCampaign('schedule', {
      ...activeEmailDetails?.schedule,
      [fieldKey]: fieldValue,
    });
  };

  const editParams = (fieldKey, fieldValue) => {
    let fixedValue = fieldValue;
    if (['delayInHours', 'delayInDays'].includes(fieldKey)) {
      fixedValue = `${convertToPositiveInt(fieldValue, 1)}`;
    }

    editCampaign('params', {
      ...activeEmailDetails.params,
      [fieldKey]: fixedValue,
    });
  };

  const editStartEndDates = (fieldKey, fieldValue) => {
    editCampaign(fieldKey, fieldValue);
  };

  const dynamicEditFunction = () => {
    switch (emailType) {
      case 'RECOMMENDATION':
        return editSchedule;
      case 'FLY':
        return editStartEndDates;
      default:
        return editParams;
    }
  };

  const checkDateError = () => {
    if (emailType === 'RECOMMENDATION') {
      const { date, type } = activeEmailDetails?.schedule;
      if (type === 'one_time' && !readOnly) {
        const dateAfterXMinFromNow = new Date().getTime() + 15 * 60000;
        const result = date < dateAfterXMinFromNow;
        setHasError(result);
      } else {
        setHasError(false);
      }
    }
  };

  useEffect(() => {
    checkDateError();
    const date = activeEmailDetails?.schedule?.date;
    const timoutCount = date - (new Date().getTime() + 15 * 60000);
    const dateCheckTimeout = setTimeout(() => {
      checkDateError();
    }, timoutCount);

    return () => {
      clearTimeout(dateCheckTimeout);
    };
  }, [activeEmailDetails]);

  return (
    <InputCardWrapper
      type='adaptiveBlock'
      cardTitle='Scheduling'
      labelFor='scheduling'
      labelClass='cardTitle'
      errorMessage={
        hasError &&
        t('Scheduled time should be at least 15 min later than current time.')
      }
    >
      <>
        <div
          className={hasError ? 'date-error' : ''}
          style={{ display: 'flex', gap: '2rem' }}
        >
          {component.componentDetails.map(comp => {
            let opt;
            if (activeEmailDetails.coupon && comp.cardTitle === 'Repeating') {
              opt = comp.options.filter(option => option.value !== 'now');
            } else {
              opt = comp.options;
            }
            const Component = Components[comp.componentName];
            return (
              <Component
                key={comp.cardName}
                fieldValue={activeEmailDetails.params[comp.cardName]}
                componentDetail={comp}
                editCampaign={dynamicEditFunction()}
                activeEmailDetails={activeEmailDetails}
                emailType={emailType}
                options={opt}
                readOnly={readOnly}
              />
            );
          })}
        </div>
      </>
    </InputCardWrapper>
  );
};

export default CampaignName;
