import React from 'react';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import Fields from '../../../../modules/campaign/fields';
import { store } from '../../../../store';
import classes from './addOrEditComponents.module.scss';

const Recommendation = ({
  component,
  editCampaign,
  activeEmailDetails,
  emailType,
  readOnly = false,
}) => {
  const isDisabled = () => {
    if (readOnly) {
      return true;
    }

    const { criteria } = store.getState().wizard;
    return criteria.length === 0 && emailType === 'fly';
  };

  return (
    <InputCardWrapper type='simple' labelClass='cardTitle'>
      <div className='widget'>
        <div className={classes['new-email-widget']}>
          <Fields
            campaign={activeEmailDetails}
            comps={[{ field: 'recommendations' }]}
            isEmail
            emailType={emailType.toUpperCase()}
            disabled={isDisabled()}
          />
        </div>
      </div>
    </InputCardWrapper>
  );
};

export default Recommendation;
