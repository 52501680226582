import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { filterActions, uiActions } from '../actions';
import { devices, mediums } from '../constants/datamaps/campaigns';
import { pushList } from '../constants/datamaps/push';
import EmailStatusFilters from '../components/filters/email-status-filters';
import Icon from '../components/icon';
import Icons from '../components/icons';
import { transformReport } from '../components/push/utils';
import {
  campaignBadges,
  csSearchParams,
  itemsPerScroll,
  pushCampaignNames,
  pushGroups,
  sortingTypes,
} from '../components/reports/utils';
import Tooltip from '../components/tooltip';
import EmailStats from '../components/widget/email-stats';
import LoaderPlaceholder from '../components/widget/loader-placeholder';
import {
  getAccount,
  getApiKey,
  getRegion,
  getSwitchAccountUser,
  getToken,
  isMultiAccountUser,
  isSwitchAccount,
} from '../modules/auth/user';
import {
  getPermissionReport,
  getPushCampaigns,
  getRangedPushReports,
} from '../modules/push/ajax';
import { getEndPoint } from '../system/api';
import { getFormattedDate, isAfterNow, queryDate } from '../system/date';
import { setTitle } from '../system/document';
import reduceBasket from '../system/reducer';
import { t } from '../system/ui';
import { config } from '../constants/config';

const pushGroupTitles = type => {
  let title = '';
  Object.keys(pushGroups).forEach(group => {
    if (pushGroups[group].includes(type)) {
      title = pushCampaignNames[group];
    }
  });
  return title;
};

class Report extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      allCampaignStats: [{}],
      pushCampaigns: [],
      _pushCampaigns: [],
      reports: {},
      status: 'ACTIVE',
      pushType: this.props.params.report,
      pageTitle: '',
      searchTerm: '',
      sortValue: '',
      sortIcon: 'preferences',
      showSortOptions: false,
      scrollCount: 0,
      showOptions: false,
      sort: 'DESC',
      sortType: 'revenue',
      toggleIcon: 'preferences',
      permissionReport: transformReport({}),
      count: 0,
      permissionElement: '',
      permissionInstanceId: '',
      permissionCampaign: [],
      itemsPerScroll: 9,
      pushProps: props,
      selectedDevice: 'ALL',
    };
    this.onChangeDocumentTitle = this.onChangeDocumentTitle.bind(this);
    this.getAllPermissions = this.getAllPermissions.bind(this);
    this.getPushCampaigns = this.getPushCampaigns.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.setPushType = this.setPushType.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.increaseScrollCount = this.increaseScrollCount.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.onInputKeyDown = this.onInputKeyDown.bind(this);
    this.getPermission = this.getPermission.bind(this);
    this.exportAll = this.exportAll.bind(this);
    this.filterPush = this.filterPush.bind(this);
    this.selectDevice = this.selectDevice.bind(this);
    this.timer = null;
  }

  componentDidMount() {
    uiActions.hideBreadcrumb();
    this.onChangeDocumentTitle(this.props.params.report);

    if (!this.props.params.report) {
      this.props.router.push({
        pathname: '/reports/all/push/executive-summary',
      });
    }
    window.onscroll = () => {
      if (
        window.innerHeight + 619.97 + window.scrollY >=
        document.body.scrollHeight
      ) {
        this.increaseScrollCount();
      }
    };
    this.getPushCampaigns();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.params.report !== newProps.params.report) {
      this.onChangeDocumentTitle(newProps.params.report);
      this.setPushType(newProps.params.report);
    }

    if (this.props.user !== newProps.user) {
      this.getPushCampaigns();
    }

    if (newProps.update === true) {
      this.getPushCampaigns(newProps.range);
      this.state.pushProps = newProps;
    }
  }

  onChangeDocumentTitle(report) {
    let { pageTitle } = this.state;
    switch (report) {
      case 'view-all':
        pageTitle = config.menus.primary.reports.children['all-push'].name;
        break;
      case 'permission':
        pageTitle =
          config.menus.primary.reports.children['permission-push'].name;
        break;
      case 'bulk':
        pageTitle = config.menus.primary.reports.children['bulk-push'].name;
        break;
      case 'betting':
        pageTitle = config.menus.primary.reports.children.betting.name;
        break;
      case 'recommendation':
        pageTitle =
          config.menus.primary.reports.children['recommendation-push'].name;
        break;
      case 'winback':
        pageTitle = config.menus.primary.reports.children['winback-push'].name;
        break;
      case 'growth':
        pageTitle = config.menus.primary.reports.children['growth-push'].name;
        break;
      case 'retention':
        pageTitle =
          config.menus.primary.reports.children['retention-push'].name;
        break;
      case 'fly':
        pageTitle = config.menus.primary.reports.children.fly.name;
        break;
      default:
        break;
    }
    setTitle(`${t(pageTitle)} ${t('Reports')}`);
  }

  onChangeStatus(instanceId, status, newStatus) {
    const newPushCampaigns = [];
    this.state._pushCampaigns.forEach(push => {
      if (push.instanceId === instanceId) {
        push.status = status;
      }
      newPushCampaigns.push(push);
    });
    this.setState(
      {
        _pushCampaigns: newPushCampaigns,
        status: newStatus,
      },
      () => {
        this.filterPush('statusOrMediumChange');
      },
    );
  }

  onStatusChange(status) {
    this.setState(
      {
        status,
      },
      () => {
        this.getPermission(this.state._pushCampaigns);
        this.filterPush('statusOrMediumChange');
      },
    );
  }

  onInputKeyDown() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.filterPush('search');
    }, 1000);
  }

  getCampaignPages(filter) {
    if (!filter.includedCategories) {
      return false;
    }
    let includedPages = '';
    if (filter.includedCategories.indexOf('allpages') !== -1) {
      includedPages += t('All Pages');
    } else {
      filter.includedCategories.forEach((pageName, pageIndex) => {
        if (pageIndex === filter.includedCategories.length - 1) {
          includedPages += t(pageName);
        } else {
          includedPages += `${t(pageName)}, `;
        }
      });
    }
    return (
      <span className='widget-meta-pages'>
        <Tooltip content={includedPages} alignment='left'>
          <Icons name='browser' width='15' height='17' color='#bababa' />{' '}
          {t('Pages')}
        </Tooltip>
      </span>
    );
  }

  getCampaignBadges(push) {
    let badge = '';
    const { schedule } = push;
    const isLive = push.status === 'ACTIVE';
    if (isLive && schedule) {
      if (schedule.type === 'periodic') {
        badge = 'periodic';
      } else if (schedule && schedule.type === 'one_time') {
        badge = isAfterNow(moment(schedule.date)) ? 'scheduled' : 'sent';
      } else if (schedule && schedule.type === 'now') {
        badge = 'sent';
      }
      return badge;
    }
    if (isLive && pushList.indexOf(push.type) > -1) {
      if (
        push.type === 'ABANDONED_CART' ||
        push.type === 'LAST_VISIT_ALTERNATIVES' ||
        push.type === 'LAST_VISIT_REMINDER' ||
        push.type === 'ORDER_FOLLOW_UP'
      ) {
        badge = 'personalized';
      } else {
        badge = 'periodic';
      }
      return badge;
    }
    return false;
  }

  getAllPermissions(permissionElement) {
    this.setState({
      permissionElement,
    });
  }

  setPushType(newType) {
    this.setState(
      {
        pushType: newType,
      },
      () => {
        this.filterPush('statusOrMediumChange');
      },
    );
  }

  getPermission(array) {
    for (let i = 0; i < array.length; i++) {
      if (
        array[i].type === 'PUSH_PERMISSION' &&
        array[i].status === this.state.status
      ) {
        const permissionCamp = [];
        permissionCamp.push(array[i]);
        this.setState({
          permissionCampaign: permissionCamp,
        });
        break;
      }
      if (i === array.length - 1) {
        this.setState({
          permissionCampaign: [],
        });
      }
    }
  }

  getPushCampaigns(range) {
    uiActions.isLoading();
    const boundaries = {};
    boundaries.start = range
      ? queryDate(range[0])
      : queryDate(this.props.range[0]);
    boundaries.end = range
      ? queryDate(range[1])
      : queryDate(this.props.range[1]);
    getPushCampaigns(response => {
      this.setState(
        {
          _pushCampaigns: response,
        },
        () => {
          this.state._pushCampaigns.forEach(campaign => {
            campaign.selectedDevice = 'ALL';
          });
          this.filterPush('statusOrMediumChange', false, false, range);
          getPermissionReport(response => {
            this.setState(
              {
                permissionReport: response
                  ? transformReport(response)
                  : transformReport({}),
              },
              () => {
                this.getPermission(this.state._pushCampaigns);
              },
            );
          }, boundaries);
        },
      );
    });
  }

  toggleOptions(e) {
    e.preventDefault();

    this.setState({
      showOptions: !this.state.showOptions,
      toggleIcon: !this.state.showOptions ? 'cross' : 'preferences',
    });
  }

  filterPush(source, eventParam, e, range) {
    filterActions.filtersUpdated();
    const allPushCampaigns = this.state._pushCampaigns;
    const { status } = this.state;
    const { pushType } = this.state;
    const isViewAll =
      !this.state.pushType || this.state.pushType === 'view-all';

    // Status filter
    let filteredPushes = allPushCampaigns.filter(
      push => push.status === status,
    );

    // Type Filter
    if (pushType !== 'view-all') {
      filteredPushes = filteredPushes.filter(
        push => pushGroups[pushType].indexOf(push.type) > -1,
      );
    }
    // Sorting
    if (e) {
      e.preventDefault();
    }
    let sortType;
    let sortOrder;
    let scrollCount;
    let deviceStatusFilteredCampaigns;
    let campaigns = this.state.pushCampaigns;
    const reverseCampaigns = false;
    const matchedCampaigns = [];
    const search = this.refs.search && this.refs.search.value;

    if (search) {
      filteredPushes.map(item => {
        if (
          item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          item.instanceId.toLowerCase().indexOf(search.toLowerCase()) > -1
        ) {
          matchedCampaigns.push(item);
        }
      });
      deviceStatusFilteredCampaigns = matchedCampaigns;
    } else {
      deviceStatusFilteredCampaigns = filteredPushes;
    }
    if (
      source === 'statusOrMediumChange' ||
      source === 'search' ||
      source === 'scroll'
    ) {
      sortType = this.state.sortType;
      sortOrder = this.state.sort;
      scrollCount = source === 'scroll' ? this.state.scrollCount : 0;
    } else {
      // source is checkbox selected
      sortType = eventParam;
      scrollCount = 0;
      if (this.state.sortType !== sortType) {
        sortOrder = 'DESC';
      } else {
        sortOrder = this.state.sort === 'ASC' ? 'DESC' : 'ASC';
      }
    }

    if (source !== 'scroll') {
      campaigns = [];
    }

    const reportIds = deviceStatusFilteredCampaigns.map(cmp => cmp.instanceId);

    const boundaries = {};
    if (range) {
      boundaries.start = queryDate(range[0]);
      boundaries.end = queryDate(range[1]);
    } else {
      boundaries.start = queryDate(this.props.range[0]);
      boundaries.end = queryDate(this.props.range[1]);
    }

    if (deviceStatusFilteredCampaigns.length) {
      if (csSearchParams.indexOf(sortType) < 0) {
        // Server side sorting
        if (
          itemsPerScroll * (scrollCount + 1) - itemsPerScroll <
          deviceStatusFilteredCampaigns.length
        ) {
          uiActions.isLoading();
          getRangedPushReports(
            reportIds,
            boundaries,
            campaignReports => {
              uiActions.isLoaded();
              const filterList = [
                'ABANDONED_CART_ALTERNATIVE_PRODUCTS',
                'ABANDONED_CART_COUPON',
                'ABANDONED_CART_FIRST_REMINDER',
                'ABANDONED_CART_SECOND_REMINDER',
              ];
              const filteredKeys = Object.keys(campaignReports).filter(
                item => !filterList.includes(item),
              );
              const newCampaigns = filteredKeys.map(reportCampaignId =>
                deviceStatusFilteredCampaigns.find(
                  cmp => cmp.instanceId === reportCampaignId,
                ),
              );

              if (campaignReports.hasOwnProperty('ABANDONED_CART')) {
                const reminders = [
                  campaignReports.ABANDONED_CART_FIRST_REMINDER || [],
                  campaignReports.ABANDONED_CART_SECOND_REMINDER || [],
                  campaignReports.ABANDONED_CART_ALTERNATIVE_PRODUCTS || [],
                  campaignReports.ABANDONED_CART_COUPON || [],
                  campaignReports.ABANDONED_CART || [],
                ];

                campaignReports.ABANDONED_CART = reduceBasket(reminders);
              }
              this.setState({
                isLoading: false,
                reports: { ...this.state.reports, ...campaignReports },
                pushCampaigns: campaigns.concat(newCampaigns),
                allCampaignStats: reduceBasket(Object.values(campaignReports)),
                count: deviceStatusFilteredCampaigns.length,
                sort: sortOrder,
                sortType,
                scrollCount,
                showOptions: false,
                toggleIcon: 'preferences',
                title: isViewAll
                  ? t('All Campaigns')
                  : t(pushCampaignNames[pushType]),
              });
            },
            {
              items: this.state.itemsPerScroll,
              page: scrollCount + 1,
              sortBy: sortType,
              reverse: sortOrder === 'DESC',
            },
          );
        }
      } else {
        // Client Side Sorting
        const campaignsByDate = [...deviceStatusFilteredCampaigns].sort(
          (a, b) => {
            if (sortOrder === 'DESC') {
              return (
                b.limitations.activeDates.startDate -
                a.limitations.activeDates.startDate
              );
            }
            return (
              a.limitations.activeDates.startDate -
              b.limitations.activeDates.startDate
            );
          },
        );
        const latestExistingCampaignIndex = campaigns.length;
        const newCampaigns = campaignsByDate.slice(
          latestExistingCampaignIndex,
          latestExistingCampaignIndex + itemsPerScroll,
        );
        const campaignsToShow = campaigns.concat(newCampaigns);

        const campaignIdsToGetReport = campaignsToShow
          .filter(campaign => !this.state.reports[campaign.instanceId])
          .map(campaign => campaign.instanceId);

        this.setState(
          {
            pushCampaigns: campaignsToShow,
            count: campaignsToShow.length,
            sort: sortOrder,
            sortType,
            scrollCount,
            showOptions: false,
            toggleIcon: 'preferences',
            title: isViewAll
              ? t('All Campaigns')
              : t(pushCampaignNames[pushType]),
          },
          () => {
            if (campaignIdsToGetReport.length) {
              uiActions.isLoading();
              getRangedPushReports(
                campaignIdsToGetReport,
                boundaries,
                campaignReports => {
                  uiActions.isLoaded();
                  this.setState({
                    reports: {
                      ...this.state.reports,
                      ...campaignReports,
                    },
                  });
                },
                {},
              );
            }
          },
        );
      }
    } else {
      uiActions.isLoaded();
      this.setState({
        isLoading: false,
        reports: {},
        pushCampaigns: [],
        count: deviceStatusFilteredCampaigns.length,
        sort: sortOrder,
        sortType,
        scrollCount,
        showOptions: false,
        toggleIcon: 'preferences',
        title: isViewAll ? t('All Campaigns') : t(pushCampaignNames[pushType]),
      });
    }
    this.setState({
      itemsPerScroll: 10,
    });
  }

  exportAll() {
    const globalApiKey =
      getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
    const endpoint = getEndPoint('export/all/push') + globalApiKey;
    const instanceId = '';
    let exportUrl = `${endpoint}&end=${queryDate(
      this.state.pushProps.range[1],
    )}&start=${queryDate(
      this.state.pushProps.range[0],
    )}&interval=total&limit=2000&instanceid=${instanceId}&authToken=${getToken()}`;
    if (isSwitchAccount() && !isMultiAccountUser()) {
      exportUrl += `&switchUser=${getSwitchAccountUser(true).username}`;
    }
    if (isMultiAccountUser() && isSwitchAccount()) {
      exportUrl += `&switchAccount=${getAccount().accountId.trim()}`;
    }
    exportUrl += `&switchRegion=${getRegion()}`;
    window.open(exportUrl);
  }

  increaseScrollCount() {
    this.setState(
      {
        scrollCount: this.state.scrollCount + 1,
      },
      () => {
        this.filterPush('scroll', false, false);
      },
    );
  }

  selectDevice(selectedDevice, push) {
    // TODO: How does this work?
    const isEmpty = Object.keys(push).length === 0;
    push.selectedDevice = selectedDevice;
    this.setState({
      ...(isEmpty
        ? { selectedDevice }
        : {
            push,
          }),
    });
  }

  render() {
    if (this.state.isLoading) {
      // show placeholder
      return (
        <div>
          <div className='page-filters has-filter-form'>
            <EmailStatusFilters
              status={this.state.status}
              onStatusChange={this.onStatusChange}
              params={this.props.params}
            />
          </div>
          <div style={{ marginTop: '80px' }}>
            <LoaderPlaceholder />
          </div>
        </div>
      );
    }
    let exportAllButton;
    const monthDiff = this.state.pushProps.range[1].diff(
      this.state.pushProps.range[0],
      'months',
    );
    if (monthDiff >= 3) {
      exportAllButton = (
        <div>
          <Tooltip
            content={t(
              'This may take a while, please wait. Your report will be downloaded when it is ready',
            )}
            alignment='top'
            placement='bottom'
          >
            <a
              style={{
                float: 'left',
              }}
              className='date-filter current'
              onClick={this.exportAll}
            >
              {t('Export All')}
            </a>
          </Tooltip>
        </div>
      );
    } else {
      exportAllButton = (
        <div>
          <a
            style={{
              float: 'left',
            }}
            className='date-filter current'
            onClick={this.exportAll}
          >
            {t('Export All')}
          </a>
        </div>
      );
    }
    return (
      <div className='fullw-report'>
        <div className='page-filters has-filter-form'>
          <EmailStatusFilters
            status={this.state.status}
            onStatusChange={this.onStatusChange}
            params={this.props.params}
          />
        </div>
        <div className='page-options page-options-sort-search page-options-with-date'>
          <label className='item is-stacked is-search'>
            <Icon name='magnify' />
            <input
              type='text'
              placeholder={t('Search')}
              className='text-field'
              onKeyDown={this.onInputKeyDown}
              ref='search'
            />
          </label>

          <div className='data-preferences'>
            <a href='#' className='toggler' onClick={this.toggleOptions}>
              <Icon name={this.state.toggleIcon} />
              <span>Sort</span>
            </a>
            <ul
              className={
                !this.state.showOptions
                  ? 'data-preferences-options is-hidden'
                  : 'data-preferences-options'
              }
            >
              {sortingTypes.map(sortingType => (
                <li
                  className='data-preferences-option'
                  key={sortingType.type}
                  onClick={() => this.filterPush('event', sortingType.type)}
                >
                  <a href='#' className='data-preferences-option-item'>
                    {sortingType.text}
                    {this.state.sortType === sortingType.type &&
                      (this.state.sort !== 'DESC' ? (
                        <i className='icon-caret-up viewall-sorting' />
                      ) : (
                        <i className='icon-caret-down viewall-sorting' />
                      ))}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div>{this.state.pushType === 'view-all' && exportAllButton}</div>
        <div style={{ position: 'relative', top: '50px' }}>
          {this.state.pushType === 'view-all' ||
            (this.state.pushType === 'permission' && (
              <div>
                {this.state.permissionCampaign.length &&
                  this.state.permissionCampaign.map(push => {
                    let campaignStatusMarkup = null;
                    const badge = this.getCampaignBadges(push);
                    if (badge) {
                      const badgeObj = campaignBadges[badge];
                      let givenClass =
                        'widget-timelimit-badge widget-timelimit-badge-';
                      givenClass += badge;
                      campaignStatusMarkup = (
                        <Tooltip
                          content={badgeObj.tooltip}
                          alignment='center'
                          className='widget-timelimit-tooltip widget-push-timelimit-tooltip'
                        >
                          <div className={givenClass}>
                            {badgeObj.subIcon && (
                              <div
                                style={{
                                  position: 'absolute',
                                  right: '5px',
                                  top: '-25px',
                                }}
                              >
                                <Icons
                                  name={badgeObj.subIcon}
                                  color={badgeObj.color}
                                  width='10'
                                  height='10'
                                />
                              </div>
                            )}
                            <Icons
                              name={badgeObj.icon}
                              color={badgeObj.color}
                              width='20'
                              height='20'
                            />
                          </div>
                        </Tooltip>
                      );
                    }
                    return (
                      <div
                        key={`${push.accountId} ${push.instanceId} ${push.type}`}
                      >
                        <div className='widget page-content-block'>
                          <div>
                            {campaignStatusMarkup}
                            <div
                              className='one-whole'
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <span
                                style={{
                                  fontSize: '14px',
                                  marginBottom: '3px',
                                }}
                              >
                                {pushGroupTitles(push.type)}
                              </span>
                              <h2
                                className='widget-title'
                                style={{ marginBottom: '7px' }}
                              >
                                {push.name || ''}
                              </h2>
                            </div>
                          </div>
                          <div
                            className={
                              badge
                                ? 'widget-meta widget-metabadge'
                                : 'widget-meta'
                            }
                          >
                            {push.devices.indexOf('ALL') > -1
                              ? Object.keys(devices)
                                  .filter(device => device !== 'TABLET')
                                  .map(device => (
                                    <span
                                      key={device}
                                      className='widget-meta-mobile'
                                    >
                                      <Tooltip
                                        content={t(devices[device])}
                                        alignment='left'
                                      >
                                        <Icon name={device.toLowerCase()} />
                                      </Tooltip>
                                    </span>
                                  ))
                              : push.devices
                                  .filter(device => device !== 'TABLET')
                                  .map(device => (
                                    <span
                                      key={device}
                                      className='widget-meta-mobile'
                                    >
                                      <Tooltip
                                        content={t(devices[device])}
                                        alignment='left'
                                      >
                                        <Icon name={device.toLowerCase()} />
                                      </Tooltip>
                                    </span>
                                  ))}
                            {push.filters &&
                              push.filters[0] &&
                              this.getCampaignPages(push.filters[0])}
                          </div>
                          <hr className='widget-separator' />
                          <EmailStats
                            key={push.id}
                            isReport
                            isPush
                            pushType='PUSH_PERMISSION'
                            isPushPermission
                            permissionReport={this.state.permissionReport}
                            getAllPermissions={this.getAllPermissions}
                            rangeAlias={this.props.rangeAlias}
                            isViewAll
                          />
                          <span>
                            <hr className='widget-separator' />
                            {this.state.permissionElement}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            ))}
          {this.state.pushType === 'bulk' && (
            <div>
              <div className='widget page-content-block'>
                <div>
                  <div
                    className='one-whole'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <h2
                      className='widget-title'
                      style={{ marginBottom: '7px' }}
                    >
                      All {pushGroupTitles('PUSH_NOTIFICATION')}
                    </h2>
                  </div>
                </div>

                <div className='widget-meta'>
                  {this.state.pushProps.range && (
                    <span className='widget-meta-date'>
                      <Icon name='calendar' />
                      {getFormattedDate(this.state.pushProps.range[0])}
                      {this.state.pushProps.range[1] &&
                        ` - ${getFormattedDate(this.state.pushProps.range[1])}`}
                    </span>
                  )}
                  <span className='recommendation-device-selection'>
                    <Tooltip
                      content='All Devices'
                      alignment='top'
                      newTooltip
                      isInline
                    >
                      <span
                        key='all-devices'
                        onClick={() => this.selectDevice('ALL', {})}
                        className={classNames(
                          'widget-meta-mobile all-device-report',
                          {
                            'is-selected': this.state.selectedDevice === 'ALL',
                          },
                        )}
                      >
                        <Icons
                          name='allDevices'
                          width='16'
                          height='16'
                          color='#bababa'
                        />
                        {t('ALL')}
                      </span>
                    </Tooltip>

                    {mediums.ALL.filter(
                      device =>
                        !(
                          device.toLowerCase() === 'tablet' ||
                          device.toLowerCase() === 'pctablet'
                        ),
                    ).map(device => (
                      <Tooltip
                        content={t(devices[device])}
                        alignment='top'
                        newTooltip
                        isInline
                        key={`all${devices[device]}`}
                      >
                        <span
                          key={device}
                          onClick={() => this.selectDevice(device, {})}
                          className={classNames('widget-meta-mobile', {
                            'is-selected': this.state.selectedDevice === device,
                          })}
                        >
                          <Icon name={device.toLowerCase()} />
                        </span>
                      </Tooltip>
                    ))}
                  </span>
                </div>
                <hr className='widget-separator' />

                <EmailStats
                  report={this.state.allCampaignStats}
                  isReport
                  isPush
                  pushType={this.state.pushType}
                  isPushPermission={this.state.pushType === 'PUSH_PERMISSION'}
                  permissionReport={this.state.permissionReport}
                  getAllPermissions={this.getAllPermissions}
                  rangeAlias={this.props.rangeAlias}
                  isSingle
                  selectedDevice={
                    this.state.selectedDevice === 'PC'
                      ? 'PC'
                      : this.state.selectedDevice.toLowerCase()
                  }
                />

                <hr className='widget-separator' />

                {this.state.permissionElement}
              </div>
            </div>
          )}
          {this.state.pushCampaigns.length ? (
            this.state.pushCampaigns
              .filter(push => push.type !== 'PUSH_PERMISSION')
              .map(push => {
                let campaignStatusMarkup = null;
                const badge = this.getCampaignBadges(push);
                if (badge) {
                  const badgeObj = campaignBadges[badge];
                  let givenClass =
                    'widget-timelimit-badge widget-timelimit-badge-';
                  givenClass += badge;
                  campaignStatusMarkup = (
                    <Tooltip
                      content={badgeObj.tooltip}
                      alignment='center'
                      className='widget-timelimit-tooltip widget-push-timelimit-tooltip'
                    >
                      <div className={givenClass}>
                        {badgeObj.subIcon && (
                          <div
                            style={{
                              position: 'absolute',
                              right: '5px',
                              top: '-25px',
                            }}
                          >
                            <Icons
                              name={badgeObj.subIcon}
                              color={badgeObj.color}
                              width='10'
                              height='10'
                            />
                          </div>
                        )}
                        <Icons
                          name={badgeObj.icon}
                          color={badgeObj.color}
                          width='20'
                          height='20'
                        />
                      </div>
                    </Tooltip>
                  );
                }
                return (
                  <div
                    key={`${push.accountId} ${push.instanceId} ${push.type}`}
                  >
                    <div className='widget page-content-block'>
                      <div>
                        {campaignStatusMarkup}
                        <div className='one-whole'>
                          <h3 className='widget-page'>
                            {pushGroupTitles(push.type)} -{' '}
                            <span
                              style={{
                                color: '##c0bebe',
                                fontWeight: 'normal',
                              }}
                            >
                              {push.instanceId}
                            </span>
                          </h3>
                          <h2
                            className='widget-title'
                            style={{ marginBottom: '7px' }}
                          >
                            {push.name || ''}
                          </h2>
                        </div>
                      </div>
                      <div className='widget-meta'>
                        {push.startDate && (
                          <span className='widget-meta-date'>
                            <Icon name='calendar' />
                            {getFormattedDate(push.startDate)}
                            {push.endDate &&
                              ` - ${getFormattedDate(push.endDate)}`}
                          </span>
                        )}
                        <span className='recommendation-device-selection'>
                          <Tooltip
                            content='All Devices'
                            alignment='top'
                            newTooltip
                            isInline
                          >
                            <span
                              key='all-devices'
                              onClick={() => this.selectDevice('ALL', push)}
                              className={classNames(
                                'widget-meta-mobile all-device-report',
                                {
                                  'is-selected': push.selectedDevice === 'ALL',
                                },
                              )}
                            >
                              <Icons
                                name='allDevices'
                                width='16'
                                height='16'
                                color='#bababa'
                              />
                              {t('ALL')}
                            </span>
                          </Tooltip>

                          {push.devices
                            .filter(
                              device =>
                                !(
                                  device.toLowerCase() === 'tablet' ||
                                  device.toLowerCase() === 'pctablet'
                                ),
                            )
                            .map(device => (
                              <Tooltip
                                key={device}
                                content={t(devices[device])}
                                alignment='top'
                                newTooltip
                                isInline
                              >
                                <span
                                  key={device}
                                  onClick={() =>
                                    this.selectDevice(device, push)
                                  }
                                  className={classNames('widget-meta-mobile', {
                                    'is-selected':
                                      push.selectedDevice === device,
                                  })}
                                >
                                  <Icon name={device.toLowerCase()} />
                                </span>
                              </Tooltip>
                            ))}
                        </span>
                        {push.filters &&
                          push.filters[0] &&
                          this.getCampaignPages(push.filters[0])}
                      </div>
                      <hr className='widget-separator' />

                      <EmailStats
                        report={this.state.reports[push.instanceId]}
                        isReport
                        isPush
                        pushType={push.type}
                        isPushPermission={push.type === 'PUSH_PERMISSION'}
                        permissionReport={this.state.permissionReport}
                        getAllPermissions={this.getAllPermissions}
                        rangeAlias={this.props.rangeAlias}
                        isViewAll
                        selectedDevice={
                          push.selectedDevice === 'PC'
                            ? 'PC'
                            : push.selectedDevice.toLowerCase()
                        }
                      />
                    </div>
                  </div>
                );
              })
          ) : (
            <div className='no-report'>
              <p className='no-report-info'>{t('no-campaign-info', '')}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  update: store.filters.update,
  reset: store.filters.reset,
  status: store.campaigns.status,
});

export default connect(mapStatesToProps)(Report);
