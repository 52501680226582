const transformOverallTrends = overallTrends => {
  const result = {};
  result.overallRevenue = {
    modal: 'Revenue Generated by Segmentify',
    stats: {
      all: {
        count: overallTrends.total.revenue,
        total: true,
      },
    },
  };
  result.overallContribution = {
    modal: "Segmentify's Revenue Contribution",
    stats: {
      all: {
        count:
          (overallTrends.total.revenue / overallTrends.totalPurchase) * 100 ||
          0,
        total: true,
      },
    },
  };
  result.overallPCount = {
    modal: 'Products Sold via Segmentify',
    stats: {
      all: {
        count: overallTrends.total.totalProducts,
        total: true,
      },
    },
  };
  return result;
};

export default transformOverallTrends;
