/**
 * Created by mehmetyurtar on 6/12/18.
 */
import React from "react";

class IconCrownBroken extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <path
          d="M0.3,15.7C0.5,15.9,0.7,16,1,16s0.5-0.1,0.7-0.3l14-14c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0l-14,14
		C-0.1,14.7-0.1,15.3,0.3,15.7z"
        />
        <polygon points="9.2,3.2 8,2 4,6 0,2 0,12.4 " />
        <path d="M5.6,14H15c0.6,0,1-0.4,1-1V3.6L5.6,14z" />
      </svg>
    );
  }
}

export default IconCrownBroken;
