import React, { useMemo } from 'react';
import { browserHistory } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { SFYListActions } from '../module-exports';
import './SFYList.scss';
import SFYThead from '../TableHead/TableHead';
import Icons from '../../icons';

/**
 * @name SFYList component
 * @description List component displays list of items and its actions
 * @param {Array} listData - list of items
 * @param {array} actions - array of actions
 * @param {string} listName - name of the list
 * @param {string} linkToDetail - router path to detail page of list item or add edit page
 * @param {number} totalRecords  - total number of records
 * @param {function} listInvoker - function to invoke list re-renders
 * @param {boolean} tableHead - table head of the list
 * @param {boolean} imgList - image list of the list
 * @param {function} modalInvoker - function to invoke modal with delete confirmation
 * @returns {JSX.Element}
 */

const SFYList = ({
  listData,
  actions,
  listName,
  totalRecords,
  linkToDetail,
  listInvoker,
  tableHead,
  imgList,
  modalInvoker,
  promotionHeadConstant,
  promotionHead,
  bundleHead,
  bundleHeadConstant,
  customerProfileList = false,
  showId,
}) => {
  const listRender = useMemo(() => {
    if (customerProfileList) {
      return (
        listData &&
        listData.map(({ fullName, userId, email }, index) => {
          return (
            <CSSTransition timeout={900} classNames='item' key={index}>
              <div
                className='sfy-list-item'
                style={{
                  gap: '4rem',
                }}
                onClick={() => {
                  browserHistory.push(`${linkToDetail}/${userId}`);
                }}
              >
                <div
                  className='sfy-list-item-count'
                  style={{
                    flexBasis: '300px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {userId}
                </div>
                <div
                  className='sfy-list-item-name'
                  style={{
                    marginRight: 'auto',
                    flexBasis: '300px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {!email ? '-' : email}
                </div>
                <div
                  className='sfy-list-item-info'
                  style={{
                    marginRight: 'auto',
                    flexBasis: '200px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <div className='sfy-list-item-name'>
                    {!fullName ? '-' : fullName}
                  </div>
                  {actions && (
                    <SFYListActions
                      actions={actions}
                      id={userId}
                      bundleName={userId}
                      listInvoker={listInvoker}
                    />
                  )}
                </div>
              </div>
            </CSSTransition>
          );
        })
      );
    }
    if (!imgList) {
      return (
        listData &&
        listData
          .filter(Boolean)
          .map(({ name, id, productBundles, categoryBundles }) => {
            return (
              <CSSTransition timeout={900} classNames='item' key={id}>
                <div
                  className='sfy-list-item'
                  onClick={() => {
                    browserHistory.push(`${linkToDetail}/${id}`);
                  }}
                >
                  <div className='sfy-list-item-name'>{name}</div>
                  <div className='sfy-list-item-info'>
                    <div className='sfy-list-item-count'>
                      {productBundles &&
                        categoryBundles &&
                        `${productBundles?.length +
                          categoryBundles?.length} ' ' Bundles`}
                      {showId && `ID:  ${id}`}
                    </div>
                    {actions && (
                      <SFYListActions
                        actions={actions}
                        id={id}
                        bundleName={name}
                        listInvoker={listInvoker}
                      />
                    )}
                  </div>
                </div>
              </CSSTransition>
            );
          })
      );
    }
    return (
      listData &&
      listData.map(({ title, code, image, status }) => {
        return (
          <CSSTransition timeout={900} classNames='item' key={code}>
            <div className='sfy-imgList'>
              <div className='sfy-imgList-wrapper'>
                <div className='sfy-imgList-item-1'>
                  <img src={image} alt={image} />
                </div>
                <div className='sfy-imgList-item-2'>{title}</div>
                <div className='sfy-imgList-item-3'>{code}</div>
                <div
                  className={`sfy-imgList-item-4 ${
                    status === 'LIVE' ? 'live' : 'expired'
                  }`}
                >
                  <div
                    className={`sfy-imgList-svg-${
                      status === 'LIVE' ? 'live' : 'expired'
                    }`}
                  >
                    <Icons name='clock' />
                  </div>
                  {status === 'LIVE' ? 'Live' : 'Expired'}
                </div>
                <div className='sfy-imgList-item-5'>
                  <div
                    onClick={() => {
                      browserHistory.push(`${linkToDetail}/${code}`);
                    }}
                  >
                    See Details
                  </div>
                </div>
              </div>
              <div className='sfy-imgList-item-6'>
                <div
                  className='sfy-imgList-item-6-trashBin'
                  onClick={() => {
                    return modalInvoker(code);
                  }}
                >
                  <Icons name='trashBin' color='#e16a6d' />
                </div>
              </div>
            </div>
          </CSSTransition>
        );
      })
    );
  }, [listData]);

  return (
    <section className='sfy-list-container'>
      {listName && (
        <div className='sfy-list-name'>
          <strong>{listName}</strong> ({listData && totalRecords})
        </div>
      )}
      {tableHead && (
        <SFYThead
          promotionHead={promotionHead}
          promotionHeadConstant={promotionHeadConstant}
          bundleHead={bundleHead}
          bundleHeadConstant={bundleHeadConstant}
        />
      )}

      <TransitionGroup>{listRender}</TransitionGroup>
    </section>
  );
};

export default SFYList;
