/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Select } from '../../../../components/fields';
import Icons from '../../../../components/icons';
import { conditionOperatorOptions, conditionTimeUnits } from '../constants';
import { t } from '../../../../system/ui';

const unitMultiplier = {
  minutes: 1,
  hours: 60,
  days: 1440,
};

const Condition = ({
  currentState,
  onChange,
  closeField,
  componentDetail,
  type,
}) => {
  const hasError = conditionKeyId => {
    let error = false;
    const condition = currentState[conditionKeyId];
    if (condition.operator === '<>') {
      if (componentDetail.conditionType === 'time') {
        error =
          parseFloat(condition.maxValue) * unitMultiplier[condition.maxUnit] <
          parseFloat(condition.value) * unitMultiplier[condition.unit];
      } else {
        error = parseFloat(condition.maxValue) < parseFloat(condition.value);
      }

      if (!condition.maxValue || !condition.value) {
        error = true;
      }
    }

    if (!condition.value) {
      error = true;
    }

    return error;
  };

  const handleChangeCondition = (val, type, conditionKey) => {
    const tempCondition = { ...currentState[conditionKey] };
    if (type === 'operator') {
      tempCondition.operator = val;
      if (val !== '<>') {
        tempCondition.maxValue = '';
      }
    }
    if (type === 'value') {
      tempCondition.value = val < 0 ? 0 : val;
    }
    if (type === 'maxValue') {
      tempCondition.maxValue = val < 0 ? 0 : val;
    }
    if (type === 'unit') {
      tempCondition.unit = val;
    }
    if (type === 'maxUnit') {
      tempCondition.maxUnit = val;
    }

    onChange({ ...currentState, [conditionKey]: tempCondition });
  };

  const valueRender = (type, conditionKeyId) => {
    let customClass = '';
    if (type === 'min' && componentDetail.conditionType !== 'time') {
      customClass = 'has-multiple-input';
    } else if (componentDetail.conditionType === 'time') {
      customClass = 'has-multiple-input';
    }
    const placeholder = type === 'min' ? 'min' : type === 'max' ? 'max' : '';
    const valueKey = type === 'max' ? 'maxValue' : 'value';

    return (
      <>
        {type === 'max' && componentDetail.conditionType !== 'time' && (
          <div className='input-separator' />
        )}
        <TextField
          className={`element-textfield ${customClass}`}
          name='condition'
          type='number'
          placeholder={placeholder}
          value={currentState[conditionKeyId][valueKey]}
          onChange={e =>
            handleChangeCondition(e.target.value, valueKey, conditionKeyId)
          }
        />
      </>
    );
  };

  const unitRender = (type, conditionKeyId) => {
    const unitKey = type === 'max' ? 'maxUnit' : 'unit';

    return (
      <>
        <div className='input-separator' />
        <Select
          name='unit'
          className='element-select time-unit-select'
          label=''
          value={currentState[conditionKeyId][unitKey]}
          options={conditionTimeUnits}
          onChange={e =>
            handleChangeCondition(e.value, unitKey, conditionKeyId)
          }
          clearable={false}
        />
      </>
    );
  };

  return (
    <div className='form-element optional-condition'>
      <span className='element-title'>
        {type === 'condition'
          ? t('Amount Condition')
          : t('Saving Time Condition')}
      </span>
      <span className='element-description'>{componentDetail.description}</span>
      <div
        className='element-row-field remove-option'
        onClick={() => closeField(type)}
      >
        <Icons name='minusCircleSmall' color='#D31116' />
        <span className='element-row-label'>{t('Exclude Condition')}</span>
      </div>
      {componentDetail.keys.map(conditionItem => (
        <>
          <div
            className={`element-row-field ${
              hasError(conditionItem.keyId) ? 'wrong-value' : ''
            }`}
            key={conditionItem.keyName}
          >
            <span className='element-row-label'>{conditionItem.keyName}</span>
            <Select
              name='condition'
              className='element-select'
              label=''
              value={currentState[conditionItem.keyId].operator}
              options={conditionOperatorOptions}
              onChange={e =>
                handleChangeCondition(e.value, 'operator', conditionItem.keyId)
              }
              clearable={false}
            />
            <div className='element-column-field'>
              <div className='element-row-field'>
                {currentState[conditionItem.keyId].operator === '<>'
                  ? valueRender('min', conditionItem.keyId)
                  : valueRender('normal', conditionItem.keyId)}

                {componentDetail.conditionType === 'time' &&
                  unitRender('min', conditionItem.keyId)}

                {currentState[conditionItem.keyId].operator === '<>' &&
                  componentDetail.conditionType !== 'time' &&
                  valueRender('max', conditionItem.keyId)}
              </div>
              {currentState[conditionItem.keyId].operator === '<>' &&
                componentDetail.conditionType === 'time' && (
                  <div className='element-row-field'>
                    {valueRender('max', conditionItem.keyId)}
                    {unitRender('max', conditionItem.keyId)}
                  </div>
                )}
            </div>
          </div>
          {hasError(conditionItem.keyId) && (
            <span className='item-error'>{t('Check the values.')}</span>
          )}
        </>
      ))}
    </div>
  );
};

Condition.propTypes = {
  currentState: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  closeField: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  componentDetail: PropTypes.shape({
    description: PropTypes.string.isRequired,
    conditionType: PropTypes.string.isRequired,
    keys: PropTypes.arrayOf({
      keyId: PropTypes.string.isRequired,
      keyName: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Condition;
