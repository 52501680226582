import React, { useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import './RectangleBundleCard.scss';
import { intelligentOptionsCustom } from '../../../constants/datamaps/wizard';
import Icons from '../../icons';
import { t } from '../../../system/ui';
import NoImage from '../../../images/noImage.svg';
import { SFYCheckBox } from '../module-exports';

/**
 * @name RectangleBundleCard
 * @description Rectangle bundle card. It's include 2 types like 'main' and 'bundle'.
 * @param {object} cardDetails - card details
 * @param {string} cardType - card type like 'main', 'bundle', 'empty'.
 * @param {function} removeFunction - remove function for bundle
 * @param {function} editFunction - edit function for bundle
 * @param {function} addFunction - add function for bundle
 * @param {function} moveCard - move function for bundle
 * @param {boolean} isVariantVisible - whether variant checkbox is shown
 * @param {boolean} isVariantActive - whether variant checkbox is checked
 * @param {function} setIsVariantActive - variant checkbox change function
 * @return {JSX.Element}
 * @constructor
 */
const RectangleBundleCard = ({
  cardDetails,
  cardType,
  removeFunction,
  editFunction,
  addFunction,
  moveCard,
  isVariantVisible = false,
  isVariantActive = false,
  setIsVariantActive = null,
  isDisabled = false,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'bundle',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = cardDetails.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'bundle',
    item: () => {
      return { id: cardDetails.index, index: cardDetails.index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  const cursor = isDragging ? 'grabbing' : 'grab';

  drag(drop(ref));

  const removeBundle = () => {
    // This function is used to remove the bundle. It's using the index to remove the bundle.
    removeFunction(cardDetails.index);
  };

  const editBundle = () => {
    // This function is used to edit the bundle. It's using the index to edit the bundle.
    editFunction(cardType, cardDetails.index);
  };

  const addBundle = () => {
    // This function is used to add the bundle.
    addFunction();
  };

  const selector = useSelector(
    store => store.switchedUser?.switchedUser.account.mainCurrency,
  );

  const cardRenderer = useMemo(() => {
    if (cardType === 'main') {
      if (cardDetails?.type !== 'category') {
        return (
          <div className='rectangle-bundle-card' key={cardDetails?.index}>
            <div className='rectangle-bundle-card-head'>
              <div className='rectangle-bundle-card-main'>{t('Main')}</div>
              <div
                className='rectangle-bundle-card-edit'
                onClick={() => editBundle()}
              >
                <div className='rectangle-bundle-card-edit-pencil' />
                <Icons name='editPencil' />
                {t('Edit')}
              </div>
            </div>
            <div
              className='rectangle-bundle-card-img'
              onClick={() =>
                window.open(`${cardDetails?.productRecord?.url}`, '_blank')
              }
            >
              <img
                src={
                  cardDetails?.productRecord?.image
                    ? cardDetails?.productRecord?.image
                    : NoImage
                }
              />
            </div>
            <div className='rectangle-bundle-card-description'>
              <span>{t('Product')} :</span>
              <div>{cardDetails?.productRecord?.category?.[0]}</div>
            </div>
            <div className='rectangle-bundle-card-description'>
              <span>{t('Product ID')} :</span>
              <div> {cardDetails?.productRecord?.productId}</div>
            </div>
            <div className='rectangle-bundle-card-description'>
              <span>{t('Brand')} :</span>
              <div> {cardDetails?.productRecord?.brand}</div>
            </div>

            {!cardDetails?.exist ? (
              <div className='rectangle-bundle-card-stock'>
                <Icons
                  name='crossCircle'
                  width='18'
                  height='18'
                  color='#D31116'
                />
                <span>{t('Not in the Catalog Anymore')}</span>
              </div>
            ) : !cardDetails?.productRecord?.inStock ? (
              <div className='rectangle-bundle-card-stock'>
                <Icons
                  name='crossCircle'
                  width='16'
                  height='16'
                  color='#D31116'
                />
                <span>{t('Out of Stock')}</span>
              </div>
            ) : (
              ''
            )}
            {isVariantVisible && (
              <div
                className={`rectangle-bundle-card-variant ${
                  isDisabled ? 'rectangle-bundle-card-variant-disabled' : null
                }`}
              >
                <SFYCheckBox
                  key='variant-01'
                  label='Activate in variants'
                  checked={isVariantActive}
                  searchActiveSetter={setIsVariantActive}
                  disabled={isDisabled}
                />
                {isDisabled && (
                  <p className='rectangle-bundle-card-variant-warning'>
                    A bundle group has already defined for the same product
                    group.
                  </p>
                )}
              </div>
            )}
          </div>
        );
      }

      return (
        <div className='rectangle-bundle-card' key={cardDetails?.index}>
          <div className='rectangle-bundle-card-head'>
            <div className='rectangle-bundle-card-main'>{t('Main')}</div>
            <div
              className='rectangle-bundle-card-edit'
              onClick={() => editBundle()}
            >
              <div className='rectangle-bundle-card-edit-pencil' />
              <Icons name='editPencil' />
              {t('Edit')}
            </div>
          </div>
          <div className='rectangle-bundle-card-img'>
            <img
              src={cardDetails?.imageUrl ? cardDetails?.imageUrl : NoImage}
            />
          </div>
          <div className='rectangle-bundle-card-description'>
            <span>{t('Category')} :</span>
            <div>{cardDetails?.key}</div>
          </div>
          {!cardDetails?.exist && (
            <div className='rectangle-bundle-card-stock'>
              <div className='rectangle-bundle-card-stock-icon'>
                <Icons name='cross' width='11' height='11' color='#D31116' />
              </div>
              <span>{t('Not in the Catalog Anymore')}</span>
            </div>
          )}
        </div>
      );
    }

    if (cardType === 'bundle') {
      if (cardDetails?.type !== 'category') {
        return (
          <div
            className='product-bundle-card'
            ref={ref}
            style={{ opacity, cursor }}
            key={cardDetails?.index}
            data-handler-id={handlerId}
          >
            <div className='product-bundle-card-head'>
              <div
                className='product-bundle-card-img'
                onClick={() =>
                  window.open(`${cardDetails?.productRecord?.url}`, '_blank')
                }
              >
                <img
                  src={
                    cardDetails?.productRecord?.image
                      ? cardDetails?.productRecord?.image
                      : NoImage
                  }
                />
              </div>
              <div className='product-bundle-card-brandWrapper'>
                <div className='product-bundle-card-brand'>
                  {t('Brand')} :
                  <span className='product-bundle-card-brand-title'>
                    {cardDetails?.productRecord?.brand}
                  </span>
                </div>
                <div className='product-bundle-card-productId'>
                  {t('Product ID')} :
                  <span className='product-bundle-card-productId-id'>
                    {cardDetails?.productRecord?.productId}
                  </span>
                </div>
              </div>
            </div>
            <div className='product-bundle-card-category'>
              {t('Category')} :
              <span className='product-bundle-card-category-title'>
                {cardDetails?.productRecord?.category?.[0]}
              </span>
            </div>
            <div className='product-bundle-card-bottom'>
              <div className='product-bundle-card-bottom-left'>
                <div className='product-bundle-card-bottom-left-price'>
                  {!selector.symbol ? selector.code : selector.symbol}{' '}
                  {cardDetails?.productRecord?.price}
                </div>
                <div
                  className={`product-bundle-card-bottom-left-stock ${!cardDetails
                    ?.productRecord?.inStock && 'out-stock'}`}
                >
                  {cardDetails?.productRecord?.inStock ? (
                    <>
                      <Icons name='tick' />
                      {t('In Stock')}
                    </>
                  ) : (
                    <>
                      <Icons name='crossCircle' width='16' height='16' />
                      {t('Out of Stock')}
                    </>
                  )}
                </div>
              </div>
              <div className='product-bundle-card-bottom-right'>
                <div
                  className='product-bundle-card-bottom-right-edit'
                  onClick={() => editBundle('bundle', cardDetails?.index)}
                >
                  <div className='product-bundle-card-bottom-right-edit-pencil' />
                  <Icons name='editPencil' />
                  {t('Edit')}
                </div>
                <div
                  className='product-bundle-card-bottom-right-trash'
                  onClick={() =>
                    removeBundle(cardDetails?.productRecord?.index)
                  }
                >
                  <Icons name='trashBin' />
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div
          ref={ref}
          style={{ opacity, cursor }}
          className='product-bundle-card'
          key={cardDetails?.index}
          data-handler-id={handlerId}
        >
          <div className='product-bundle-card-head'>
            <div
              className='product-bundle-card-img'
              onClick={() =>
                !intelligentOptionsCustom?.[cardDetails?.algorithm]
                  ? window.open(`${cardDetails?.url}`, '_blank')
                  : null
              }
            >
              {cardDetails?.imageUrl ? (
                <img
                  src={cardDetails?.imageUrl}
                  alt={`alt-${cardDetails?.key}`}
                />
              ) : (
                <img src={NoImage} alt='no-image' />
              )}
            </div>
            <div className='product-bundle-card-brainWrapper'>
              <div className='product-bundle-card-brain'>
                <Icons name='brain' />
              </div>
              <div className='product-bundle-card-brain-title'>
                {intelligentOptionsCustom?.[cardDetails?.algorithm]}
              </div>
            </div>
          </div>
          <div className='product-bundle-card-category'>
            {t('Category')} :
            <span className='product-bundle-card-category-title'>
              {cardDetails?.key}
            </span>
          </div>
          <div className='product-bundle-card-bottom bundle-end'>
            <div className='product-bundle-card-bottom-right'>
              <div
                className='product-bundle-card-bottom-right-edit'
                onClick={() => editBundle('bundle', cardDetails?.index)}
              >
                <div className='product-bundle-card-bottom-right-edit-pencil' />
                <Icons name='editPencil' />
                {t('Edit')}
              </div>
              <div
                className='product-bundle-card-bottom-right-trash'
                onClick={() => removeBundle(cardDetails?.productRecord?.index)}
              >
                <Icons name='trashBin' />
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (cardType === 'empty') {
      return (
        <div className='product-bundle-create' onClick={() => addBundle()}>
          <div className='product-bundle-create-add'>
            <Icons name='plusCircle' width='26' height='26' color='#8D85D8' />
          </div>
          <div className='product-bundle-create-text'>{t('New Bundle')}</div>
        </div>
      );
    }
  }, [cardDetails, isVariantActive]);

  return <>{cardRenderer}</>;
};

export default RectangleBundleCard;
