/**
 * Created by mehmetyurtar on 1/6/18.
 */
import React from "react";

class IconReferral extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="if_icon-external-link_2867894"
            transform="translate(9.000000, 9.000000) rotate(-180.000000) translate(-9.000000, -9.000000) "
            fillRule="nonzero"
            fill={this.state.color}
          >
            <path
              d="M16,3.41 L5.7,13.71 C5.45355125,13.9934965 5.06901456,14.1149658 4.70443202,14.0244847 C4.33984947,13.9340036 4.05672702,13.6468365 3.97142615,13.2810073 C3.88612528,12.9151781 4.01303645,12.5324029 4.3,12.29 L14.58,2 L11,2 C10.4477153,2 10,1.55228475 10,1 C10,0.44771525 10.4477153,0 11,0 L17,0 C17.5522847,0 18,0.44771525 18,1 L18,7 C18,7.55228475 17.5522847,8 17,8 C16.4477153,8 16,7.55228475 16,7 L16,3.41 Z M14,11 C14,10.4477153 14.4477153,10 15,10 C15.5522847,10 16,10.4477153 16,11 L16,16 C16,17.1045695 15.1045695,18 14,18 L2,18 C0.8954305,18 0,17.1045695 0,16 L0,4 C0,2.9 0.9,2 2,2 L7,2 C7.55228475,2 8,2.44771525 8,3 C8,3.55228475 7.55228475,4 7,4 L2,4 L2,16 L14,16 L14,11 Z"
              id="Shape"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default IconReferral;
