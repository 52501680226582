import React, { useEffect } from 'react';
import { Link } from 'react-router';
import { ADD_RULE, FACETED_SEARCH_NAME } from 'constants/faceted-search';
import { t } from 'system/ui';
import { getFacetedSearchList } from 'actions/faceted-search';
import { useDispatch, useSelector } from 'react-redux';
import Campaigns from './Campaigns';

const FacetedSearchListing = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFacetedSearchList());
  }, []);
  const state = useSelector(s => s.facetedSearch);
  return (
    <>
      {state.ui.loaded && <Campaigns campaigns={state.campaigns} />}
      <div
        className="available-types available-widgets faceted-search-listing-bottom"
        id="faceted-search-listing-wrapper"
      >
        <h3 className="widget-title-head">{FACETED_SEARCH_NAME}</h3>
        <p className="widget-title-desc">{t('FACETED_SEARCH_PRODUCT_DESC')}</p>
        <div className="available-type-selection">
          <Link to="/search/new/faceted">
            <h4 className="available-type-name">{FACETED_SEARCH_NAME}</h4>
            <span className="faceted-search-thumbnail" />
          </Link>
          <Link to={{ pathname: '/search/new/rule-set', state: {campaigns: state.campaigns} }}>
            <h4 className="available-type-name">Customisation</h4>
            <span className="faceted-search-thumbnail customisation" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default FacetedSearchListing;
