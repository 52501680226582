import React from 'react';
import { fixedPxValue } from './pcBigger';

const StarNoFill = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 16 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.333 11.896L8 10.312L10.688 11.896L9.979 8.896L12.292 6.917L9.229 6.646L8 3.792L6.771 6.646L3.708 6.917L6.042 8.896L5.333 11.896ZM3.062 15L4.375 9.458L0 5.729L5.75 5.229L8 0L10.25 5.25L16 5.729L11.625 9.458L12.938 15L8 12.062L3.062 15Z'
        fill='#333333'
      />
    </svg>
  );
};

StarNoFill.defaultProps = {
  width: '16px',
  height: '15px',
};

export default StarNoFill;
