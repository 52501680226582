import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';
import classNames from 'classnames';
import Modal from './modal';

export const InfoModal = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="info-modal-wrapper">
      <Icon className={props.iconClassName} name="info" onClick={() => setIsOpen(true)}/>
      <Modal show={isOpen} close={() => setIsOpen(false)} >
        {props.children}
      </Modal>
    </div>
  );
}


export default InfoModal;
