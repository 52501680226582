/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { t } from '../../../../system/ui';

import Icons from '../../../../components/icons';

import Segment from './segment';
import Visitor from './visitor';
import Tooltip from '../../../../components/tooltip';
import { getNowByTimeZone, queryDate } from '../../../../system/date';
import { getAllStat, getCustomSegmentStat } from '../../../segmentation/ajax';
import { getAllRbsStats } from '../../../rbs/ajax';
import { localeString } from '../../../../system/string';
import { getAllCustomSegment } from '../../../account/ajax';

const isEngagement = type => {
  let flag = false;
  switch (type) {
    case 'POPUP_BANNER':
      flag = true;
      break;
    case 'SEE_ALL':
      flag = true;
      break;
    case 'PERSONA_QUIZ':
      flag = true;
      break;
    case 'NEWSLETTER':
      flag = true;
      break;
    case 'FORM':
      flag = true;
      break;
    case 'NOTIFICATION_BAR':
      flag = true;
      break;
    case 'HERO_BANNER':
      flag = true;
      break;
    default:
      flag = false;
      break;
  }
  return flag;
};

const isRecommendation = type => {
  let flag = false;
  switch (type) {
    case 'JOURNEY_BUILDER':
      flag = true;
      break;
    case 'RECOMMENDATION':
      flag = true;
      break;
    case 'POPUP_RECOMMENDATION':
      flag = true;
      break;
    case 'PROMOTION':
      flag = true;
      break;
    default:
      flag = false;
      break;
  }
  return flag;
};

class AudienceOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: AudienceOptions.defaults,
      visibility: true,
      stats: {},
      selectedSegment: '',
      isCustomSegment: [],
      reqSegment: '',
    };

    this.find = this.find.bind(this);
    this.add = this.add.bind(this);
    this.addSegment = this.addSegment.bind(this);
    this.addVisitor = this.addVisitor.bind(this);
    this.remove = this.remove.bind(this);
    this.removeSegment = this.removeSegment.bind(this);
    this.removeVisitor = this.removeVisitor.bind(this);
    this.segmentFieldChange = this.segmentFieldChange.bind(this);
    this.segmentSelectChange = this.segmentSelectChange.bind(this);
    this.visitorChange = this.visitorChange.bind(this);
    this.update = this.update.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.getStats = this.getStats.bind(this);
    this.updateSelectedSegmentValue = this.updateSelectedSegmentValue.bind(
      this,
    );
    this.addCustomSegmentToStats = this.addCustomSegmentToStats.bind(this);
  }

  componentDidMount() {
    if (this.props.duplicate && this.props.duplicate.isDuplicate) {
      this.update(
        this.props.campaign,
        this.props.duplicate.duplicatedValues[11],
      );
    } else {
      this.update(this.props.campaign);
    }
    if (
      isEngagement(this.props.campaignType) ||
      isRecommendation(this.props.campaignType)
    ) {
      this.getStats();
    }
    getAllCustomSegment(res => {
      this.setState({ isCustomSegment: res });
    });
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.update(newProps.campaign);
    }
  }

  getStats() {
    const opts = {};
    opts.start = queryDate(getNowByTimeZone().subtract(6, 'month'));
    opts.end = queryDate(getNowByTimeZone());
    getAllStat(response => {
      getAllRbsStats(rbsResponse => {
        let stats = {};
        if (response) stats = { ...response };
        if (rbsResponse) stats = { ...stats, ...rbsResponse };
        this.setState({ stats });
      });
    }, opts);
  }

  update(campaign, duplicatedFilters) {
    let filters = campaign.filters || [];
    const segment = [];
    const visitor = [];

    const customSeg = () => {
      setTimeout(() => {
        if (filters) {
          filters.forEach(filter => {
            if (filter.type === 'SEGMENT') {
              if (filter.segment) {
                this.setState({ reqSegment: filter.segment });
              }
              if (filter.param) {
                this.setState({ reqSegment: filter.param });
              }
            }
          });
        }
      }, 200);
    };
    customSeg();

    setTimeout(() => {
      if (this.state.isCustomSegment.length >= 1) {
        this.state.isCustomSegment.forEach(segmentItem => {
          if (String(this.state.reqSegment) === segmentItem) {
            if (filters[1]?.segment) {
              // segment is a behavioral module field
              this.addCustomSegmentToStats(String(filters[1]?.segment));
            }
            if (filters[0]?.segment) {
              // just a seeall campaign request
              this.addCustomSegmentToStats(String(filters[0]?.segment));
            }
          }
          if (String(this.state.reqSegment) === segmentItem) {
            if (filters[0]?.param) {
              // param is a recommendation module field
              this.addCustomSegmentToStats(String(filters[0]?.param));
            }
          }
        });
      }
    }, 600);

    if (this.props.isRecommendationModule) {
      filters = campaign.actions[0].filters;
    }

    if (duplicatedFilters) {
      filters = duplicatedFilters;
    }

    filters.forEach(filter => {
      let key = 'param';
      if (filter.type === 'SEGMENT') {
        if (!this.props.isRecommendationModule && !duplicatedFilters) {
          key = 'segment';
        }
        segment.push({
          id: Math.random(),
          member: filter.include,
          value: filter[key],
        });
      }

      if (filter.type === 'VISITOR') {
        if (!this.props.isRecommendationModule && !duplicatedFilters) {
          key = 'name';
        }
        visitor.push({
          id: Math.random(),
          member: filter.include,
          value: filter[key],
        });
      }
    });

    this.updateValue(segment, 'segment');
    this.updateValue(visitor, 'visitor');
    if (
      isEngagement(this.props.campaignType) ||
      isRecommendation(this.props.campaignType)
    ) {
      if (segment && segment.length > 0) {
        this.setState({ visibility: false }, () =>
          this.updateSelectedSegmentValue(segment),
        );
      } else if (visitor && visitor.length > 0) {
        this.setState({ visibility: false });
      }
    }
  }

  updateValue(newValue, type) {
    const { values } = this.state;
    values[type] = newValue;
    this.setState({ values });
  }

  updateSelectedSegmentValue(values) {
    this.setState({ selectedSegment: values[0].value });
  }

  find(id, type) {
    const { values } = this.state;
    let target = -1;

    values[type].forEach((value, index) => {
      if (value.id === id) {
        target = index;
      }
    });

    return target;
  }

  add(item, type) {
    const { values } = this.state;
    values[type].push(item);
    this.setState({ values, visibility: false });
  }

  addSegment() {
    this.add(
      {
        id: Math.random(),
        member: true,
        value: '',
      },
      'segment',
    );
  }

  addVisitor() {
    this.add(
      {
        id: Math.random(),
        member: true,
        value: 'NEW',
      },
      'visitor',
    );
  }

  remove(id, type) {
    const { values } = this.state;
    const target = this.find(id, type);
    values[type].splice(target, 1);
    this.setState({ values, visibility: true, selectedSegment: '' });
  }

  removeSegment(id) {
    this.remove(id, 'segment');
  }

  removeVisitor(id) {
    this.remove(id, 'visitor');
  }

  segmentSelectChange(value, id) {
    const { values } = this.state;
    const target = this.find(id, 'segment');

    values.segment[target].member = value;
    this.setState({ values });
  }

  segmentFieldChange(segment, id) {
    if (segment.type === 'EVENT_BASED') {
      this.addCustomSegmentToStats(segment.value);
    }
    const { values } = this.state;
    const target = this.find(id, 'segment');
    values.segment[target].value = segment.value;

    this.setState({ values, selectedSegment: segment.value });
  }

  visitorChange(value, id, type) {
    const { values } = this.state;
    const target = this.find(id, 'visitor');

    values.visitor[target][type] = value;

    this.setState({ values });
  }

  addCustomSegmentToStats(id) {
    getCustomSegmentStat(
      id,
      ({ segmentName, segmentedUserCount, totalUserCount }) => {
        if (segmentName && segmentedUserCount && totalUserCount) {
          return this.setState(prevState => ({
            stats: {
              ...prevState.stats,
              [segmentName]: {
                userCount: segmentedUserCount,
                otherUserCount: totalUserCount,
              },
            },
          }));
        }
        return null;
      },
    );
  }

  render() {
    if (!this.props.isAudienceVisible) {
      return false;
    }

    return (
      <div className='wizard-input-type-wrapper'>
        <h3 className='wizard-input-type-title'>
          <Icons name='target' />
          {t('Target Audience')}
          <span className='field-tooltip-icon'>
            <Tooltip
              content={t(`audience_${this.props.campaignType}`)}
              alignment='left'
            >
              <i className='icon-info' role='presentation'>
                <span className='for-screenreader-only' />
              </i>
            </Tooltip>
          </span>
        </h3>
        <ol className='form-elements'>
          {this.state?.values?.segment &&
            this.state?.values?.segment.length > 0 && (
              <li className='row'>
                <ol className='form-elements'>
                  {this.state.values.segment.map(segment => {
                    return (
                      <Segment
                        key={segment.id}
                        id={segment.id}
                        member={segment.member}
                        value={segment.value}
                        onRemove={this.removeSegment}
                        onSelectChange={this.segmentSelectChange}
                        onFieldChange={this.segmentFieldChange}
                        campaignType={this.props.campaignType}
                      />
                    );
                  })}
                </ol>
              </li>
            )}
          {this.state?.values?.visitor &&
            this.state?.values?.visitor.length > 0 && (
              <li className='row'>
                <ol className='form-elements'>
                  {this.state.values.visitor.map(visitor => {
                    return (
                      <Visitor
                        key={visitor.id}
                        id={visitor.id}
                        member={visitor.member}
                        value={visitor.value}
                        onRemove={this.removeVisitor}
                        onChange={this.visitorChange}
                      />
                    );
                  })}
                </ol>
              </li>
            )}
          {((!isEngagement(this.props.campaignType) &&
            !isRecommendation(this.props.campaignType)) ||
            this.state.visibility) && (
            <li>
              <a className='secondary-action' onClick={this.addSegment}>
                <Icons name='useradd' /> {t('Visitor Segment')}
              </a>
              <a className='secondary-action' onClick={this.addVisitor}>
                <Icons name='useradd' /> {t('Visitor Type')}
              </a>
            </li>
          )}
          {(isEngagement(this.props.campaignType) ||
            isRecommendation(this.props.campaignType)) &&
            this.state.selectedSegment && (
              <div className='wizard-input-type-wrapper segment'>
                <label className='item item-stacked four columns'>
                  <span className='item-label'>
                    {t('Total Reachable User')}
                  </span>
                  <span className='item-label'>
                    {this.state.stats[this.state.selectedSegment]
                      ? localeString(
                          this.state.stats[this.state.selectedSegment]
                            .otherUserCount +
                            this.state.stats[this.state.selectedSegment]
                              .userCount,
                        )
                      : 0}
                  </span>
                </label>

                <label className='item item-stacked four columns'>
                  <span className='item-label'>{t('Segment User')}</span>
                  <span className='item-label'>
                    {this.state.stats[this.state.selectedSegment]
                      ? localeString(
                          this.state.stats[this.state.selectedSegment]
                            .userCount,
                        )
                      : 0}
                  </span>
                </label>
              </div>
            )}
        </ol>
      </div>
    );
  }
}

AudienceOptions.defaults = {
  segment: [],
  visitor: [],
};

const MapStatesToProps = store => ({
  isAudienceVisible: store.wizard.isAudienceVisible,
  editForm: store.wizard.editForm,
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  AudienceOptions,
);
