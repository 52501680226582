import { tryParse } from './string';

// use clone instead of Object.assign for deep copying object
export const clone = obj => JSON.parse(JSON.stringify(obj));

export const stringfy = obj => JSON.stringify(obj);

export const getLocal = item => tryParse(localStorage.getItem(item));

export const setLocal = (item, value) => {
  const val = typeof value === 'object' ? JSON.stringify(value) : value;

  localStorage.setItem(item, val);
};

export const removeLocal = item => {
  const items = typeof item === 'object' ? item : [item];

  items.forEach(element => {
    localStorage.removeItem(element);
  });
};

export const sortObj = obj => {
  return Object.keys(obj)
    .sort()
    .reduce(
      (result, key) => ({
        ...result,
        [key]: obj[key],
      }),
      {},
    );
};
