import React, { Component } from 'react';
import EmailCard from '../components/widget/email-card';

class AllEmails extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className='page-content-wrapper'>
          {this.props.emails.map(campaign => {
            if (campaign.type === 'NEWSLETTER') {
              return null;
            }

            return (
              <EmailCard
                campaign={campaign}
                onChangeStatus={this.props.onChangeStatus}
                key={campaign.instanceId}
                report={
                  this.props.reports
                    ? this.props.reports[campaign.instanceId]
                    : []
                }
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default AllEmails;
