export const latestFeaturesModalTypes = Object.freeze({
  NO_IMAGE: 'msp-no-image',
  COLUMN: 'msp-column',
  ROW: 'msp-row',
});

export const insightsTrendifyShowTypes = Object.freeze({
  REAL_DATA: 'trendify-data',
  STATIC_CONTENT: 'trendify-static',
});

export const insightsTrendifyCardTypes = Object.freeze({
  FULL_WIDTH: 'full-width',
  HALF_WIDTH: 'half-width',
});

export const playbookTypes = Object.freeze({
  SEARCH_BOX: 'SearchBox',
  BLACK_FRIDAY: 'BlackFriday',
  PUSH: 'Push',
  RECOMMENDATION: 'Recommendation',
  SEARCHANDISING: 'Searchandising',
});
