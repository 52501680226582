/**
 * Created by mehmetyurtar on 20/07/2017.
 */
import React from "react";

class FormSurvey extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "20px",
      height: "20px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <path
          fill={this.state.color}
          d="M20,9h24c0.553,0,1-0.447,1-1s-0.447-1-1-1H20c-0.553,0-1,0.447-1,1S19.447,9,20,9z"
        />
        <path
          fill={this.state.color}
          d="M20,25h24c0.553,0,1-0.447,1-1s-0.447-1-1-1H20c-0.553,0-1,0.447-1,1S19.447,25,20,25z"
        />
        <path
          fill={this.state.color}
          d="M20,41h24c0.553,0,1-0.447,1-1s-0.447-1-1-1H20c-0.553,0-1,0.447-1,1S19.447,41,20,41z"
        />
        <path
          fill={this.state.color}
          d="M4,13h8c0.553,0,1-0.447,1-1V4c0-0.553-0.447-1-1-1H4C3.447,3,3,3.447,3,4v8C3,12.553,3.447,13,4,13z M5,5h6v6H5V5z"
        />
        <path
          fill={this.state.color}
          d="M3,28c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1v-8c0-0.553-0.447-1-1-1H4c-0.553,0-1,0.447-1,1V28z M5,21h6v6H5V21z"
        />
        <path
          fill={this.state.color}
          d="M3,44c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1v-8c0-0.553-0.447-1-1-1H4c-0.553,0-1,0.447-1,1V44z M5,37h6v6H5V37z"
        />
      </svg>
    );
  }
}

export default FormSurvey;
