import React from 'react';
import { connect } from 'react-redux';

import { browserHistory } from 'react-router';
import AllFacetedSearchReports from 'components/search/faceted-search/report/AllFacetedSearchReports';
import { FACETED_SEARCH_NAME } from 'constants/faceted-search';
import { config } from '../constants/config';
import { t } from '../system/ui';

import { modalActions, uiActions } from '../actions';

import Filters from '../components/filters/filters';

import Report from './report';
import AllRecommendationReport from './report.all.recommendation';
import AllPersonalizationReport from './report.all.personalization';
import PersonalizationReport from './personalizationreport';
import EmailReport from './emailreport';
import PushReport from './pushreport';
import ExecutiveReport from './reports.executive';
import ExecutiveReportEngagement from './reports.executive.engagement';
import ExecutiveReportPush from './reports.executive.push';
import ExecutiveReportSearch from './searchreport';
import {
  getAccount,
  getApiKey,
  getRegion,
  getSwitchAccountUser,
  getToken,
  hasModulePrivilege,
  isMultiAccountUser,
  isSwitchAccount,
} from '../modules/auth/user';
import { getQueryString } from '../system/string';
import { searchAlgorithmTitles } from '../constants/datamaps/wizard';
import SegmentationModuleReport from './segmentationModuleReport';
import SearchBannerReport from '../components/search/search.banner.report';
import IysDashBoard from './reports/iys';
import EmailMarketingAnalytics from './reports/email/email-marketing-analytics';
import { setTitle } from '../system/document';
import AutomatedEmails from './reports/email/email-executive-reports/AutomatedEmails';
import BulkSegmentedEmails from './reports/email/email-executive-reports/BulkSegmentedEmails';
import FlyModeEmails from './reports/email/email-executive-reports/FlyModeEmails';
import SMTPEmails from './reports/email/email-executive-reports/SMTPEmails';
import EmailExecutiveReport from './reports/email/email-executive-reports/ExecutiveReport';
import CompareDashboardModal from '../components/reports/compareModal.dashboard';

import stats from '../modules/stats';
import { transformStats } from '../modules/transform';
import { clone } from '../system/object';
import { statsMap } from '../constants/datamaps';
import { getEndPoint } from '../system/api';
import { queryDate } from '../system/date';

class Reports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: clone(statsMap),
    };
  }

  componentDidMount() {
    if (this.props.params.report === 'searchandising') {
      setTitle(`${t('Searchandising')} ${t('Reports')}`);
    }
    uiActions.resetPage();
    const queryAppType = getQueryString('app_type');

    if (queryAppType && queryAppType === 'push') {
      this.props.router.push({
        pathname: '/ext/reports/all/push/view-all',
      });
    } else if (!this.props.params.report) {
      this.props.router.push({
        pathname: '/reports/all/recommendations/executive-summary',
      });
    }

    if (this.props.params.campaigntype === 'email') {
      this.getStats();
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (this.props.params.report !== newProps.params.report) {
      if (newProps.params.report === 'searchandising') {
        setTitle(`${t('Searchandising')} ${t('Reports')}`);
      }
    }
    if (
      this.props?.params?.campaigntype !== newProps?.params?.campaigntype ||
      this.props?.rangeAlias !== newProps?.rangeAlias
    ) {
      this.getStats();
    }
  }

  getStats() {
    const boundaries = {};
    boundaries.start = this.props.range[0].format('YYYY-MM-DD');
    boundaries.end = this.props.range[1].format('YYYY-MM-DD');
    stats.getRangedStats(boundaries, allStats => {
      this.setState({
        stats: transformStats(allStats),
      });
    });
  }

  openComparisonModal(modalType) {
    let selectedModule;
    switch (modalType) {
      case 'recommendation':
        selectedModule = 'Recommendation';
        break;
      case 'search':
        selectedModule = 'Search';
        break;
      case 'push':
        selectedModule = 'Push';
        break;
      case 'engagement':
        selectedModule = 'Engagement';
        break;
      default:
        selectedModule = 'Recommendation';
    }
    const compareModal = () => (
      <CompareDashboardModal
        range={this.props.range}
        selectedModule={selectedModule}
        isStatic
      />
    );
    modalActions.openModal({
      title: t('Date Comparison'),
      content: compareModal,
    });
  }

  exportAllSearchReports() {
    const globalApiKey =
      getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
    const endpoint = getEndPoint('export/all/search') + globalApiKey;
    const instanceId = '';
    let exportUrl = `${endpoint}&end=${queryDate(
      this.props.range[1],
    )}&start=${queryDate(
      this.props.range[0],
    )}&interval=total&limit=2000&instanceid=${instanceId}&authToken=${getToken()}`;
    if (isSwitchAccount() && !isMultiAccountUser()) {
      exportUrl += `&switchUser=${getSwitchAccountUser(true).username}`;
    }
    if (isMultiAccountUser() && isSwitchAccount()) {
      exportUrl += `&switchAccount=${getAccount().accountId.trim()}`;
    }
    exportUrl += `&switchRegion=${getRegion()}`;
    window.open(exportUrl);
  }

  render() {
    if (this.props.user.displayName && !hasModulePrivilege(false, 'RECOUSER')) {
      browserHistory.push('/');
    }
    let recoPageTitle;
    const personalizationNames = {
      push_notification: 'Push Notifications',
      notification_bar: 'Notification Bar',
      push_permission: 'Push Permission',
      form_survey: 'Form - Survey',
      email_collection: 'E-Mail Collection',
      popup: 'Popup Banner',
      popup_recommendation: 'Popup Recommendation',
      segmentation: 'Segmentation',
      hero_banner: 'Hero Banner',
      gamification: 'Gamification',
      'see-all': 'See All',
      countdown: 'Countdown',
    };

    if (this.props.params.report) {
      recoPageTitle =
        config.menus.primary.recommendations.children[this.props.params.report];
      if (recoPageTitle) {
        recoPageTitle =
          recoPageTitle.name !== 'Trigger Based' &&
          recoPageTitle.name !== 'Popup Recommendation'
            ? `${recoPageTitle.name} Page`
            : recoPageTitle.name;
      }
    }

    return (
      <>
        {this.props.router.location.pathname.includes('iys') ? (
          <IysDashBoard />
        ) : (
          <>
            {this.props.params.report !== 'analytics' && <Filters />}
            {!this.props.params.report &&
              this.props.params.campaigntype === 'recommendations' && (
                <AllRecommendationReport
                  campaignType={this.props.params.campaigntype}
                />
              )}
            {!this.props.params.report &&
              this.props.params.campaigntype === 'engagement' && (
                <AllPersonalizationReport />
              )}
            {this.props.params.report === 'executive-summary' &&
              this.props.params.campaigntype === 'recommendations' && (
                <div>
                  <div className='heading-with-flex-between'>
                    <h2 className='page-title'>{t('Executive Summary')}</h2>
                    <button
                      type='button'
                      className='heading-with-flex-between-btn'
                      onClick={() => {
                        this.openComparisonModal('recommendation');
                      }}
                    >
                      Compare
                    </button>
                  </div>
                  <ExecutiveReport
                    {...this.props}
                    key='executive-summary'
                    campaignType={this.props.params.campaigntype}
                  />
                </div>
              )}
            {this.props.params.campaigntype === 'search' &&
              this.props.params.report !== 'searchandising' &&
              this.props.params.report !== 'banners' && (
                <div>
                  <div className='heading-with-flex-between'>
                    {(!this.props.params.report ||
                      this.props.params.report === '') && (
                      <h2 className='page-title'>
                        {t('Executive Search & Discovery Summary')}
                      </h2>
                    )}
                    {this.props.params.report !== '' && (
                      <h2 className='page-title'>
                        {t(
                          searchAlgorithmTitles(this.props.params.report)
                            .campaignTitle,
                        )}
                      </h2>
                    )}
                    <div className='heading-group'>
                      {this.props.params?.report !== 'BEFORE_SEARCH' &&
                        this.props.params?.report !== 'SEARCH' && (
                          <button
                            type='button'
                            className='heading-export-btn'
                            onClick={() => {
                              this.exportAllSearchReports();
                            }}
                          >
                            Export All
                          </button>
                        )}
                      <button
                        type='button'
                        className='heading-with-flex-between-btn'
                        onClick={() => {
                          this.openComparisonModal('search');
                        }}
                      >
                        Compare
                      </button>
                    </div>
                  </div>
                  <ExecutiveReportSearch
                    {...this.props}
                    key='executive-summary'
                  />
                </div>
              )}
            {this.props.params.campaigntype === 'search' &&
              this.props.params.report === 'searchandising' && (
                <div>
                  <h2 className='page-title'>{FACETED_SEARCH_NAME}</h2>
                  <AllFacetedSearchReports
                    {...this.props}
                    key='executive-summary-faceted'
                  />
                </div>
              )}
            {this.props.params.campaigntype === 'search' &&
              this.props.params.report === 'banners' && (
                <SearchBannerReport {...this.props} />
              )}
            {this.props.params.report === 'executive-summary' &&
              this.props.params.campaigntype === 'engagement' && (
                <div>
                  <div className='heading-with-flex-between'>
                    <h2 className='page-title'>{t('Executive Summary')}</h2>
                    <button
                      type='button'
                      className='heading-with-flex-between-btn'
                      onClick={() => {
                        this.openComparisonModal('engagement');
                      }}
                    >
                      Compare
                    </button>
                  </div>
                  <ExecutiveReportEngagement
                    {...this.props}
                    key='executive-summary-engagement'
                  />
                </div>
              )}
            {this.props.params.report === 'executive-summary' &&
              this.props.params.campaigntype === 'email' && (
                <>
                  <EmailExecutiveReport
                    {...this.props}
                    stats={this.state.stats}
                    key='executive-summary-executive-report'
                  />
                  <AutomatedEmails
                    {...this.props}
                    stats={this.state.stats}
                    key='executive-summary-automated-emails'
                  />
                  <BulkSegmentedEmails
                    {...this.props}
                    stats={this.state.stats}
                    key='executive-summary-bulk-segmented-emails'
                  />
                  <FlyModeEmails
                    {...this.props}
                    stats={this.state.stats}
                    key='executive-summary-fly-mode-emails'
                  />
                  <SMTPEmails
                    {...this.props}
                    stats={this.state.stats}
                    key='executive-summary-smtp-emails'
                  />
                </>
              )}
            {this.props.params.report === 'executive-summary' &&
              this.props.params.campaigntype === 'push' && (
                <div>
                  <div className='heading-with-flex-between'>
                    <h2 className='page-title'>{t('Executive Summary')}</h2>
                    <button
                      type='button'
                      className='heading-with-flex-between-btn'
                      onClick={() => {
                        this.openComparisonModal('push');
                      }}
                    >
                      Compare
                    </button>
                  </div>
                  <ExecutiveReportPush
                    {...this.props}
                    key='executive-summary-push'
                  />
                </div>
              )}
            {this.props.params.report &&
              this.props.params.report !== 'executive-summary' &&
              this.props.params.campaigntype === 'recommendations' && (
                <div>
                  {recoPageTitle && (
                    <h2 className='page-title'>{t(recoPageTitle)}</h2>
                  )}
                  <Report
                    {...this.props}
                    key={this.props.params.report}
                    campaignType={this.props.params.campaigntype}
                  />
                </div>
              )}
            {this.props.params.report &&
              this.props.params.report !== 'executive-summary' &&
              this.props.params.campaigntype === 'engagement' && (
                <div>
                  {this.props.ui !== 'isLoading' && (
                    <h2 className='page-title'>
                      {t(personalizationNames[this.props.params.report])}
                    </h2>
                  )}
                  <PersonalizationReport
                    {...this.props}
                    key={this.props.params.report}
                  />
                </div>
              )}
            {this.props.params.report &&
              this.props.params.report !== 'executive-summary' &&
              this.props.params.report !== 'analytics' &&
              this.props.params.report === 'email_collection' &&
              this.props.params.campaigntype === 'email' && (
                <div>
                  {this.props.ui !== 'isLoading' && (
                    <h2 className='page-title'>
                      {t(personalizationNames[this.props.params.report])}
                    </h2>
                  )}
                  <PersonalizationReport
                    {...this.props}
                    key={this.props.params.report}
                  />
                </div>
              )}
            {this.props.params.report &&
              this.props.params.report !== 'executive-summary' &&
              this.props.params.report !== 'analytics' &&
              this.props.params.report !== 'email_collection' &&
              this.props.params.campaigntype === 'email' && (
                <div>
                  <EmailReport {...this.props} />
                </div>
              )}
            {this.props.params.report &&
              this.props.params.report === 'analytics' &&
              this.props.params.campaigntype === 'email' && (
                <div>
                  <h2 className='page-title'>
                    {t('Email Marketing Analytics')}
                  </h2>
                  <EmailMarketingAnalytics {...this.props} />
                </div>
              )}
            {this.props.params.report &&
              this.props.params.report !== 'executive-summary' &&
              this.props.params.campaigntype === 'push' && (
                <div>
                  <PushReport {...this.props} />
                </div>
              )}
            {this.props.params.report &&
              this.props.params.report !== 'executive-summary' &&
              this.props.params.campaigntype === 'segmentation' && (
                <div>
                  <SegmentationModuleReport {...this.props} />
                </div>
              )}
          </>
        )}
      </>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  user: store.user.user,
});

export default connect(mapStatesToProps)(Reports);
