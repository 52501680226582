import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const Countdown = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_0_12)'>
        <path
          d='M1.82594 0C0.81445 0 0 0.81445 0 1.82594V8.17407C0 9.18556 0.81445 10 1.82594 10H5.26657V6.09063H4.23282V4.68313H5.26657V3.48063C5.26657 2.53588 5.87736 1.66844 7.28438 1.66844C7.85406 1.66844 8.27532 1.72313 8.27532 1.72313L8.24219 3.03751C8.24219 3.03751 7.81256 3.03345 7.34375 3.03345C6.83636 3.03345 6.755 3.26723 6.755 3.65533V4.68314H8.2825L8.21594 6.09064H6.755V10H8.17406C9.18555 10 10 9.18557 10 8.17408V1.82595C10 0.81446 9.18555 1e-05 8.17406 1e-05H1.82593L1.82594 0Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_0_12'>
          <rect
            width={fixedPxValue(width)}
            height={fixedPxValue(height)}
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

Countdown.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Countdown.defaultProps = {
  width: '32px',
  height: '28px',
};

export default Countdown;
