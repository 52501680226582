import React from 'react';
import PropTypes from 'prop-types';
import Icons from 'components/icons';
import { Link } from 'react-router';
import { confirm, t } from 'system/ui';
import { facetedSearchRequest, getFacetedSearchList } from 'actions/faceted-search';
import { useDispatch } from 'react-redux';

export default function CampaignRuleSetItem({ campaign, isInstant, instantRefresh }) {
  const { ruleSets } = campaign;
  const dispatch = useDispatch();
  function deleteRuleSet(instanceId) {
    confirm({
      title: t('rule-set-delete-confirmation-title'),
      content: t('rule-set-delete-confirmation-content'),
      onConfirm: () => {
        facetedSearchRequest('ruleset/delete', { instanceId })
          .delete()
          .then(() => {
            isInstant ? instantRefresh() : dispatch(getFacetedSearchList());
          });
      },
    });
  }

  return (
    <div className='rule-set-widget-list-item-container'>
      {Array.isArray(ruleSets)
        ? ruleSets.sort((ruleSet1, ruleSet2) => ruleSet2 - ruleSet1).map((ruleSet, index) => {
          const { name, condition } = ruleSet;
          const ruleType = <span>Query: {condition.operation} - <b>{condition.keyword}</b></span>;
          return (
            <li className='widget-list-item' key={ruleSet.id}>
              <span className='draghandler'><Icons name="burger" color="#e8e7f2" /></span>
              <h2 className='widget-title'>{`${index + 1}-${name}`}</h2>
              <span className='rule-type'>{ruleType}</span>
              <Link to={{pathname: `/search/edit/${isInstant ? 'instant-rule-set' : 'rule-set'}/${ruleSet.id}`, state: {campaigns: [campaign]}}}>
                <button className='rule-set-edit-button'><Icons name='editRowPencil' /></button>
              </Link>
              <button
                onClick={() => deleteRuleSet(ruleSet.id)}
                className='rule-set-delete-button'
              >
                <Icons name='trashBin' />
              </button>
            </li>
          );
        })
        : null}
    </div>
  );
}

CampaignRuleSetItem.propTypes = {
  campaign: PropTypes.objectOf(PropTypes.object).isRequired,
};
