import React, { Component } from 'react';
import classNames from 'classnames';

import { modalActions } from '../../actions';
import {
  algorithmDescriptions,
  getStaticAlgorithms,
} from '../../constants/datamaps/wizard';
import { t } from '../../system/ui';
import Icon from '../icon';
import Icons from '../icons';
import Tooltip from '../tooltip';
import {
  hasOnlyDynamicBundles,
  isBundleEnabled,
} from '../../modules/auth/user';

class IntelligentSelectionModal extends Component {
  constructor(props) {
    super(props);

    this.setAlgorithms = this.setAlgorithms.bind(this);
    this.toggleAlgorithmSelection = this.toggleAlgorithmSelection.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      algorithms: {},
      selectedAlgorithms: [],
      favoriteAlgorithms: [],
      defaultSelected: true,
    };
  }

  componentDidMount() {
    this.setAlgorithms();
    setTimeout(function() {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  setAlgorithms() {
    const isFiltered = hasOnlyDynamicBundles() && this.props?.isRecoModal;
    const currAlgorithms = { ...this.props.currentAlgorithms };
    let tempAlgorithms = isBundleEnabled()
      ? currAlgorithms
      : Object.keys(currAlgorithms).reduce((acc, key) => {
          if (!key.includes('RECOMMENDATION_RELATED_COMBINE_PRODUCTS')) {
            acc[key] = currAlgorithms[key];
          }
          return acc;
        }, {});

    if (isFiltered) {
      tempAlgorithms = {
        RECOMMENDATION_RELATED_COMBINE_PRODUCTS: 'Bundle Products',
        RECOMMENDATION_SHOP_THE_LOOK: 'Shop The Look',
      };
    }

    this.setState(
      {
        algorithms: tempAlgorithms,
        favoriteAlgorithms:
          this.props.currentFavorites && this.props.currentFavorites.slice(),
        selectedAlgorithms: [],
      },
      () => {
        if (
          this.props.currentAlgorithms &&
          Object.keys(this.props.currentAlgorithms)[0] &&
          Object.keys(this.props.currentAlgorithms)[0].length
        ) {
          const modalType =
            Object.keys(this.props.currentAlgorithms)[0].indexOf('STATIC-') > -1
              ? 'static'
              : 'intelligent';
        }
      },
    );
  }

  toggleAlgorithmSelection(algorithm) {
    if (this.props.isPushModule) {
      this.setState({
        selectedAlgorithms: [algorithm],
      });
    } else {
      if (this.props.disabledStatics.indexOf(algorithm) > -1) {
        return false;
      }
      let { selectedAlgorithms } = this.state;
      if (selectedAlgorithms.indexOf(algorithm) > -1) {
        selectedAlgorithms = selectedAlgorithms.filter(
          item => item !== algorithm,
        );
      } else {
        selectedAlgorithms.push(algorithm);
      }
      this.setState({
        selectedAlgorithms,
      });
    }
  }

  closeModal() {
    const { selectedAlgorithms } = this.state;
    const selectedCriteria = [];
    /* Add selected algorithms inputs here to the page, then close the modal */
    selectedAlgorithms.forEach(algorithm => {
      if (algorithm.indexOf('-S') > -1) {
        selectedCriteria.push({
          id: Math.random(),
          type: getStaticAlgorithms(false, true)[algorithm],
          values: {
            criterion: { value: algorithm, highlight: true },
            itemCount: { value: '3' },
            mobileItemCount: { value: 3 },
          },
        });
      } else if (algorithm.indexOf('STATIC-') > -1) {
        selectedCriteria.push({
          id: Math.random(),
          type: getStaticAlgorithms(this.props.isPromotion)[algorithm],
          values: { criterion: { value: algorithm } },
        });
      } else {
        const intelligentChoose = {
          id: Math.random(),
          type: 'intelligent',
          values: {
            criterion: { value: algorithm },
            timeFrame: {
              value: this.props.isTopSellers
                ? 'THIS_WEEK_PURCHASE'
                : 'THIS_WEEK',
            },
            itemCount: { value: '4' },
          },
        };

        if (algorithm === 'RECOMMENDATION_SOURCE_RELATED_PRODUCT') {
          intelligentChoose.values.timeFrame.value = 'TODAY';
        }

        if (this.props.isSearch) {
          intelligentChoose.values.mobileItemCount = { value: '8' };
          intelligentChoose.values.itemCount = { value: '8' };
        }

        selectedCriteria.push(intelligentChoose);
      }
    });
    const statics = selectedCriteria.filter(criteria => {
      return criteria.values.criterion.value.indexOf('STATIC-') > -1;
    });
    const dynamics = selectedCriteria.filter(criteria => {
      return criteria.values.criterion.value.indexOf('STATIC-') < 0;
    });
    if (statics.length) {
      this.props.addDetailsRow('static', statics);
    }
    if (dynamics.length) {
      this.props.addDetailsRow('intelligent', dynamics);
    }
    this.setState(
      {
        defaultSelected: false,
        selectedAlgorithms: [],
      },
      () => {
        modalActions.closeModal();
      },
    );
  }

  render() {
    const newAlgorithmsFavorite = {};
    const newAlgorithmsStandard = {};
    Object.keys(this.state.algorithms).forEach(algorithm => {
      if (
        this.state.favoriteAlgorithms &&
        this.state.favoriteAlgorithms.indexOf(algorithm) > -1
      ) {
        newAlgorithmsFavorite[algorithm] = this.state.algorithms[algorithm];
      } else {
        newAlgorithmsStandard[algorithm] = this.state.algorithms[algorithm];
      }
    });
    const finalAlgorithms = {
      ...newAlgorithmsFavorite,
      ...newAlgorithmsStandard,
    };

    return (
      <div style={{ minWidth: '900px' }}>
        <div className='algorithm-cards'>
          {Object.keys(finalAlgorithms).map(algorithm => {
            const algorithmDetail = algorithmDescriptions(
              this.state.favoriteAlgorithms,
              this.props.isTopSellers,
              this.props.isMostPopular,
            )[algorithm];
            const isSelected =
              this.state.selectedAlgorithms.indexOf(algorithm) > -1;
            return (
              <div
                className={classNames(
                  `algorithm-card algorithm-card-${algorithm}`,
                  {
                    'algorithm-card--selected': isSelected,
                    'algorithm-card--favorited':
                      this.state.favoriteAlgorithms &&
                      this.state.favoriteAlgorithms.indexOf(algorithm) > -1,
                    'algorithm-card--disabled':
                      this.props.disabledStatics.indexOf(algorithm) > -1,
                  },
                )}
                key={algorithm}
                onClick={this.toggleAlgorithmSelection.bind(null, algorithm)}
              >
                {this.state.favoriteAlgorithms &&
                  this.state.favoriteAlgorithms.indexOf(algorithm) > -1 && (
                    <Tooltip
                      content={t('Best Performer of Selected Widget')}
                      alignment='left'
                      placement='top'
                    >
                      <div className='algorithm-card-favicon'>
                        <Icons name='heart' width='30' height='30' />
                      </div>
                    </Tooltip>
                  )}
                <div className='algorithm-card-checkbox'>
                  <Icons
                    name={isSelected ? 'checkboxSelected' : 'checkboxEmpty'}
                    width='25'
                    height='25'
                  />
                </div>
                {algorithmDetail.hasIcon && (
                  <div
                    className={
                      algorithmDetail.iconSvg
                        ? 'algorithm-card-icon algorithm-card-icon--svg'
                        : 'algorithm-card-icon'
                    }
                  >
                    {algorithmDetail.iconSvg ? (
                      <Icons
                        name={algorithmDetail.icon}
                        width='30'
                        height='30'
                        color='#787779'
                      />
                    ) : (
                      <Icon name={algorithmDetail.icon} />
                    )}
                  </div>
                )}
                <h3 className='algorithm-card-title'>
                  {algorithmDetail.title}
                </h3>
                <p className='algorithm-card-description'>
                  {algorithmDetail.description}
                </p>
              </div>
            );
          })}
          {this.state.selectedAlgorithms.length > 0 && (
            <div className='algorithm-cards-add' onClick={this.closeModal}>
              {t('Add Selected')}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default IntelligentSelectionModal;
