import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { t } from '../../system/ui';

const MpExtraContainer = ({ title, isEmail, isPush, children }) => {
  return (
    <li
      className={classNames('email-mappings', {
        'extra-container-email': isEmail,
        'extra-container-push': isPush,
      })}
    >
      <ol className='form-elements wizard-items'>
        <div
          className='wizard-comp'
          style={{ marginTop: '0', paddingTop: '0' }}
        >
          <ol className='form-elements'>
            <div className='wizard-cards'>
              <div>
                <div className='wizard-comp'>
                  <h3
                    className='wizard-comp-title item-title-sub item-title-sub__rec'
                    style={{ fontWeight: 600 }}
                  >
                    {title}
                  </h3>
                  {children}
                </div>
              </div>
            </div>
          </ol>
        </div>
      </ol>
    </li>
  );
};

MpExtraContainer.defaultProps = {
  title: t('Scheduling & Target'),
};

MpExtraContainer.propTypes = {
  title: PropTypes.string,
  isEmail: PropTypes.bool,
  isPush: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node),
};

export default MpExtraContainer;
