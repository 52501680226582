import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { t } from "../../../system/ui";
import Icons from "../../icons";
import {criterionTypeButtonsBeforeSearch, exclusionButtons} from "../../../constants/datamaps/wizard";
import {
  intelligentOptionsBeforeSearchCategories,
  intelligentOptionsBeforeSearchKeyword,
  intelligentOptionsBeforeSearchBrand,
} from "../../../constants/datamaps/wizard";

import SearchInnerCriteria from "../inner-search/search-inner-criteria";
import SearchExcludes from "../../wizard/search-excludes";
import {searchWizardActions} from "../../../actions";
import Icon from "../../icon";

class SearchInnerItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      excludes: props.excludes,
      showRequiredError: false,
      hideExcludes: props.excludes && props.excludes[props.isMobile?"mobile":"pc"] &&props.excludes[props.isMobile?"mobile":"pc"][props.type].length>0?false: true,
      hasError: false,
      assetItems: props.assetItems,
      excludeTypes: [],
      searchExclusionButton: []
    };
    Object.assign(this.state.searchExclusionButton, exclusionButtons);
    this.button = this.button.bind(this);
    this.exclusionButton = this.exclusionButton.bind(this);
    this.handleAssetItems = this.handleAssetItems.bind(this);
    this.addCriterion = this.addCriterion.bind(this);
    this.addDetailsRow = this.addDetailsRow.bind(this);
    this.openAlgorithmSelection = this.openAlgorithmSelection.bind(this);
    this.handleCriteriaRemove = this.handleCriteriaRemove.bind(this);
    this.sendAssetItems = this.sendAssetItems.bind(this);
    this.handleAssetItems = this.handleAssetItems.bind(this);
    this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
    this.validate = this.validate.bind(this);
    this.toggleFilterAction = this.toggleFilterAction.bind(this);
    this.setStaticProducts = this.setStaticProducts.bind(this);
    this.addExclusionRow = this.addExclusionRow.bind(this);
  }

  componentDidMount() {
    this.showError();
    this.setState({
        isAssetActive: this.props.isAssetActive
    });
    this.validate(this.props.assetItems);
    this.setStaticProducts(this.props.assetItems);
  }

  componentWillReceiveProps(newProps){
    if (this.props.assetItems !== newProps.assetItems) {
      this.validate(newProps.assetItems);
      this.setStaticProducts(newProps.assetItems);
    }
    if (newProps.excludes) {
      this.setState({
          excludes: newProps.excludes[newProps.isMobile?"mobile":"pc"][newProps.type],
          hideExcludes: this.props.excludes && this.props.excludes[this.props.isMobile?"mobile":"pc"] &&this.props.excludes[this.props.isMobile?"mobile":"pc"][this.props.type].length>0?false: true,

      });
    }
  }

  setStaticProducts(assetItems){
    this.setState({
      assetItems:assetItems
    })
  };

  buttonNames = (title) => {
    return title === "Static"
      ? "Static"
      : this.props.type === "CATEGORY"
      ? "Top Categories"
      : this.props.type === "BRAND"
      ? "Top Brands"
      : this.props.type === "KEYWORD"
      ? "Top Keywords"
      : "";
  };

  validate(assetItems) {
    if (assetItems.length === 0) {
      this.setState({
        hasError: true,
        showRequiredError: true,
      });
    } else {
      this.setState({
        hasError: false,
        showRequiredError: false,
      });
    }
  }

  addExclusionRow(type) {
      let current = this.props.excludes[this.props.isMobile?"mobile":"pc"][this.props.type] || [];
      current = current.concat({ id: Math.random(), type: type, values: {} });
      searchWizardActions.addExclusion(current, this.props.isMobile);
  }

  showError() {
    if (this.props.assetItems.length == 0)
      this.setState({ showRequiredError: true });
  }

  sendAssetItems() {
    this.props.handleAsset(this.state.assetItems);
  }

  handleCriteriaRemove(criteriaId) {
    let modifiedCriteria;
    modifiedCriteria = this.props.assetItems.filter((criteria) => {
      return criteria.id !== criteriaId;
    });

    this.handleAssetItems(modifiedCriteria);
  }

  handleCriteriaChange(fields, criteriaId, selectedCategory) {
    let criteria = this.state.assetItems,
      inputs =
        typeof fields !== "undefined"
          ? fields.querySelectorAll(".criteria-field-search")
          : "",
      values = {};

    if (inputs.length > 0) {
      inputs.forEach((item) => {
        if (!item.name) {
          if (selectedCategory) {
            values.criterion = {
              value: selectedCategory,
            };
          } else {
            values.itemCount = {
              value: item.querySelector("input").value,
            };
          }
        } else {
          values[item.name] = {
            value: item.value,
          };
        }
      });

      for (let i = 0; i < criteria.length; i++) {
        if (criteria[i] !== undefined && criteria[i].id === criteriaId) {
          criteria[i].values = Object.assign({}, values);
          break;
        }
      }

      this.handleAssetItems(criteria);
    }
  }

  handleAssetItems(assetList) {
    this.setState({ assetItems: assetList }, () => {
      this.sendAssetItems();
    });
  }

  getCurrentIntelligents() {
    let currentIntelligent = {};
    switch (this.props.type) {
      case "KEYWORD":
        currentIntelligent = Object.assign(
          {},
          intelligentOptionsBeforeSearchKeyword
        );
        break;
      case "CATEGORY":
        currentIntelligent = Object.assign(
          {},
          intelligentOptionsBeforeSearchCategories
        );
        break;
      case "BRAND":
        currentIntelligent = Object.assign(
          {},
          intelligentOptionsBeforeSearchBrand
        );
        break;
    }

    return currentIntelligent;
  }

  addCriterion(type, algorithms) {
    let finalAlgorithms = [];
    let selectedAlgorithms = Object.assign([], this.state.assetItems);
    finalAlgorithms = selectedAlgorithms.concat(algorithms);
    this.handleAssetItems(finalAlgorithms);
  }

  addDetailsRow(type, selection) {
    let intelligentChoose;
    if (type === "intelligent") {
      intelligentChoose = {
        id: Math.random(),
        type: "intelligent",
        values: {
          criterion: { value: selection },
          itemCount: { value: "4" },
        },
      };
      this.addCriterion(type, [intelligentChoose]);
    } else {
      intelligentChoose = {
        id: Math.random(),
        values: {},
      };

      if (this.props.type === "CATEGORY") { intelligentChoose.type = "category"}
      else if (this.props.type === "BRAND") {intelligentChoose.type = "brand"}
      else if (this.props.type === "KEYWORD") {intelligentChoose.type = "keyword"}

      this.addCriterion(type, [intelligentChoose]);
    }
  }

  openAlgorithmSelection(obj) {
    this.addDetailsRow(obj, Object.keys(this.getCurrentIntelligents())[0]);
  }

  button(item, type, cb, filterType) {
    let currentIcon = <Icons name={item.icon} color={"#fff"} />;
    return (
      <a
        key={type}
        className={classNames("button", item.className)}
        onClick={cb.bind(null, type)}
      >
        {currentIcon}
        {t(this.buttonNames(item.title))}
      </a>
    );
  }

    exclusionButton(item, type, cb, filterType) {
        let currentIcon = "";
        if (
            item.icon === "excludeLabel" ||
            item.icon === "excludeGender" ||
            item.icon === "excludeCustom" ||
            item.iconSvg
        ) {
            currentIcon = (
                <Icons
                    name={item.icon}
                    color={
                        item.icon.indexOf("asteriks") < 0 &&
                        item.icon.indexOf("brain") &&
                        item.icon.indexOf("megaphone") < 0
                            ? "#787779"
                            : "#fff"
                    }
                />
            );
        } else {
            currentIcon = <Icon name={item.icon} />;
        }
        let isSelectedCriteria =
            (filterType === "inclusion" &&
                this.state.includeTypes.indexOf(type) > -1) ||
            (filterType === "exclusion" &&
                this.state.excludeTypes.indexOf(type) > -1);
        if (this.props.type.toLowerCase() === item.title.toLowerCase()) {
          return (
                <a
                    key={type}
                    className={classNames("button", item.className, {
                        "include-exclude-selected": isSelectedCriteria,
                        "custom-include-exclude-selected": type === "custom",
                    })}
                    onClick={cb.bind(null, type)}
                >
                  {isSelectedCriteria && (
                    <span>
                      <Icons name="checkboxSelected" width="15" height="15" />
                    </span>
                  )}
                  {currentIcon}
                  {t(item.title)}
                </a>
          );
        }
    }

  toggleFilterAction() {
    this.setState({ hideExcludes: false });
  }

  render() {
    let criterionButtonsToShow = criterionTypeButtonsBeforeSearch;
    this.state.searchExclusionButton["keyword"] = {
      title: "Keyword",
      icon: "excludeCustom",
      className: "add-criteria"
    };
    return (
      <ol className="form-elements wizard-items" style={{ width: "100%" }}>
        <div className="wizard-comp search">
          <h3 className="wizard-comp-title">
            {t(`Recommendation ${this.props.text} for Asset`)}
          </h3>
          <div id="scroll-container">
            <li className="wizard-input-types">
              {Object.keys(criterionButtonsToShow).map((item) => {
                return this.button(
                  criterionButtonsToShow[item],
                  item,
                  this.openAlgorithmSelection
                );
              })}
              <div className="open-filters-buttons">
                <div
                  className={classNames("open-filters open-filters--exclusion")}
                  onClick={this.toggleFilterAction.bind(null, "excludesToScroll")}
                >
                  <Icons name="minusCircleSmall" color="#787779" />
                  {t("Exclude Filters")}
                </div>
              </div>
            </li>
          </div>

          <li className="wizard-input-type-wrapper">
            <div className="wizard-criteria">
              <SearchInnerCriteria
                assetList={this.state.assetItems}
                type={this.props.type}
                onCriteriaRemove={this.handleCriteriaRemove}
                onCriteriaChange={this.handleCriteriaChange}
                onHandleCriteria={this.handleAssetItems}
              />

              {this.state.showRequiredError ? (
                <span
                  className="item-error has-error"
                  style={{
                    display: "block",
                    marginTop: "10px",
                    padding: "15px",
                  }}
                >
                  {t(
                    "You should make at least one selection from Segmentify Intelligent or Static algorithms"
                  )}
                </span>
              ) : (
                ""
              )}
            </div>
          </li>
        </div>

        <div
          className={classNames("wizard-comp wizard-comp-filters", {
            "is-hidden": this.state.hideExcludes,
          })}
          ref={"excludesToScroll"+"_"+(this.props.isMobile? "mobile" : "pc" )+ "_" + this.props.type}
        >
          <h3 className="wizard-comp-title" style={{ color: "#c54343" }}>
            <Icons name="minusCircleSmall" color="#c54343" />{" "}
            {t("Exclude Filters")}
          </h3>
          <p className="wizard-comp-description">
            {t(
              "Exclude filters allows you to narrow down your recommendation selection to the products that meet your criteria"
            )}
          </p>
            {Object.keys(this.state.searchExclusionButton).map((item) => {
                return this.exclusionButton(
                    this.state.searchExclusionButton[item],
                    item,
                    this.addExclusionRow,
                    "exclusion"
                );
            })}
            <li className="wizard-input-type-wrapper">
                <SearchExcludes assetType={this.props.type} isMobile={this.props.isMobile}/>
            </li>
        </div>
      </ol>
    );
  }
}

const mapStatesToProps = (store) => ({
  searchCriteria: store.searchWizard.criteria,
  validate: store.ui.validate,
  excludes: store.searchWizard.excludes,
  typedExcludes: store.searchWizard.typedExcludes
});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
  SearchInnerItems
);
