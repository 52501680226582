/**
 * @author Bilal Çınarlı
 * */

import React from 'react';

class IconClock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: '16px',
      height: '16px',
      color: '#eee',
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    const width = this.props.width ? `${this.props.width}px` : this.state.width;
    const height = this.props.height
      ? `${this.props.height}px`
      : this.state.height;
    const color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 512 512'
        width={this.state.width}
        height={this.state.height}
      >
        <g>
          <path
            fill={this.state.color}
            className='svg-path'
            d='M256,11C121.2,11,11,121.2,11,256s110.2,245,245,245s245-110.2,245-245S390.8,11,256,11z M256,439.8
		c-101.1,0-183.8-82.7-183.8-183.8S154.9,72.2,256,72.2S439.8,154.9,439.8,256S357.1,439.8,256,439.8z'
          />
          <polygon
            fill={this.state.color}
            className='svg-path'
            points='286.6,133.5 225.4,133.5 225.4,286.6 378.5,286.6 378.5,225.4 286.6,225.4'
          />
        </g>
      </svg>
    );
  }
}

export default IconClock;
