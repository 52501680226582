import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const GarbageBin = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.61885 0C9.04625 0 10.2127 1.07176 10.2915 2.42219L10.2957 2.5679H14.6199C14.9611 2.5679 15.2377 2.83322 15.2377 3.16049C15.2377 3.4605 15.0053 3.70844 14.7038 3.74767L14.6199 3.75309H13.9643L12.9099 14.0399C12.8006 15.1058 11.9006 15.9289 10.8003 15.9957L10.6558 16H4.58196C3.46541 16 2.5234 15.2213 2.34646 14.1775L2.32778 14.0399L1.27255 3.75309H0.617744C0.305001 3.75309 0.046545 3.53015 0.00564207 3.2409L0 3.16049C0 2.86048 0.232395 2.61255 0.533921 2.57331L0.617744 2.5679H4.94195C4.94195 1.14968 6.14046 0 7.61885 0ZM12.7231 3.75309H2.51381L3.55733 13.9239C3.60572 14.3957 3.99502 14.7629 4.47729 14.8098L4.58196 14.8148H10.6558C11.15 14.8148 11.569 14.4791 11.6649 14.0233L11.6804 13.9239L12.7231 3.75309ZM7.61885 1.18519C6.86256 1.18519 6.24235 1.74389 6.18222 2.45449L6.17744 2.5679H9.06025C9.06025 1.80425 8.41491 1.18519 7.61885 1.18519Z'
        fill={color}
      />
    </svg>
  );
};

GarbageBin.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

GarbageBin.defaultProps = {
  width: '16px',
  height: '16px',
  color: '#e9888b',
};

export default GarbageBin;
