/**
 * Created by mehmetyurtar on 12/6/17.
 */
import React from "react";

class IconDashboardTfy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <path d="M1,7h5c0.553,0,1-0.448,1-1V1c0-0.552-0.447-1-1-1H1C0.447,0,0,0.448,0,1v5C0,6.552,0.447,7,1,7z M2,2h3v3H2V2z" />
        <path d="M15,0h-5C9.447,0,9,0.448,9,1v5c0,0.552,0.447,1,1,1h5c0.553,0,1-0.448,1-1V1C16,0.448,15.553,0,15,0z M14,5h-3V2h3V5z" />
        <path d="M1,16h5c0.553,0,1-0.448,1-1v-5c0-0.552-0.447-1-1-1H1c-0.553,0-1,0.448-1,1v5C0,15.552,0.447,16,1,16z M2,11h3v3H2V11z" />
        <path d="M15,9h-5c-0.553,0-1,0.448-1,1v5c0,0.552,0.447,1,1,1h5c0.553,0,1-0.448,1-1v-5C16,9.448,15.553,9,15,9z M14,14h-3v-3h3V14z" />
      </svg>
    );
  }
}

export default IconDashboardTfy;
