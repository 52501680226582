import React from 'react';
import { fixedPxValue } from './pcBigger';

const Person = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 26 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13 0C14.8565 0 16.637 0.763837 17.9497 2.12348C19.2625 3.48311 20 5.32718 20 7.25C20 9.17282 19.2625 11.0169 17.9497 12.3765C16.637 13.7362 14.8565 14.5 13 14.5C11.1435 14.5 9.36301 13.7362 8.05025 12.3765C6.7375 11.0169 6 9.17282 6 7.25C6 5.32718 6.7375 3.48311 8.05025 2.12348C9.36301 0.763837 11.1435 0 13 0ZM13 17.5C20.735 17.5 25.5 20.7444 25.5 24.75V33H0.5V24.75C0.5 20.7444 5.265 17.5 13 17.5Z'
        fill='#4474E0'
      />
    </svg>
  );
};

Person.defaultProps = {
  width: '26px',
  height: '32px',
};

export default Person;
