/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import React from 'react';
import { clone } from '../../system/object';
import { toCamelCase } from '../../system/string';
import {
  calculateRatio,
  calculateProportion,
  numericCalculateRatio,
  diffRatio,
} from '../../system/ratio';
import { changeOrderInObject } from '../../components/reports/utils';
import {
  hasKlaviyoConfiguration,
  hasModule,
  isRecommendationTrackEnabled,
} from '../auth/user';

import {
  trendsMap,
  trendsMapWithPageView,
  trendsMapWithKlaviyo,
  trendsInteractionOrder,
  assistedRevenueTrend,
} from '../../constants/datamaps/trends';

const transformTrends = (
  trendData,
  purchaseAmount,
  purchaseCount,
  purchaseItemCount,
  dashboardTrends,
  selectedModule,
  isCampaignDetail = false,
  hasKlaviyo = false,
  isDashboardData = false,
  withPageViewAndImpressionRate = false,
) => {
  let isEmail = false;
  let isFacetedSearch = false;
  if (
    selectedModule &&
    selectedModule.toLowerCase().indexOf('search') > -1 &&
    hasModule('faceted')
  ) {
    isFacetedSearch = true;
  }
  let trends = clone(trendsMap);

  if (
    selectedModule === 'E-Mail' &&
    (hasKlaviyoConfiguration() || hasKlaviyo)
  ) {
    trends = clone(trendsMapWithKlaviyo);
  }

  if (withPageViewAndImpressionRate) {
    trends = clone(trendsMapWithPageView);
  }

  if (isCampaignDetail) {
    trends.revenue.stats.diff = {
      name: 'Diff',
      ratio: 0,
    };
  }

  const mappedTrends = {
    impression: 0,
    widgetView: 0,
    totalView: 0,
    click: 0,
    basketItems: 0,
    basketAmount: 0,
    purchaseAmount: 0,
    purchaseAmountPrev: 0,
    purchases: 0,
    purchasedItems: 0,
    aRPurchaseAmount: 0,
    aRPurchaseAmountPrev: 0,
    aRPurchases: 0,
    aRPurchasedItems: 0,
    unsubUser: 0,
    bounceBack: 0,
    spam: 0,
    'email[HardBounce]': 0,
    'email[SoftBounce]': 0,
    'email[clicked]': 0,
    'email[sent]': 0,
    'email[opened]': 0,
    'notification[clicked]': 0,
    'notification[shown]': 0,
    'notification[sent]': 0,
    'purchase:amount': 0,
    'purchase:amountPrev': 0,
    triggeredToFlow: 0,
    triggeredToProfile: 0,
  };

  if (withPageViewAndImpressionRate) {
    mappedTrends.pageView = 0;
    mappedTrends.impressionRate = 0;
  }

  let totalRevenue = purchaseAmount || 0;
  const totalPurchase = purchaseCount || 0;
  const totalPurchaseItems = purchaseItemCount || 0;

  if (trendData && trendData.length > 0) {
    const uniqueTrendKeys = [...new Set(trendData.map(item => item.x))];
    const uniqueTrendValues = uniqueTrendKeys.map(item =>
      trendData
        .filter(newItem => newItem.x === item)
        .reduce((acc, cur) => acc + cur.y, 0),
    );

    trendData.forEach(item => {
      if (
        toCamelCase(item.x).indexOf('email') !== -1 ||
        toCamelCase(item.x).indexOf('triggeredToFlow') !== -1 ||
        toCamelCase(item.x).indexOf('triggeredToProfile') !== -1
      ) {
        isEmail = true;
      }
    });

    uniqueTrendKeys.forEach((item, index) => {
      mappedTrends[toCamelCase(item)] = uniqueTrendValues[index];
    });
  }

  if (selectedModule === 'E-Mail') {
    isEmail = true;
  }

  if (!totalRevenue) {
    totalRevenue = mappedTrends['purchase:amount'] || 0;
  }

  if (dashboardTrends) {
    isEmail = false;
  }

  // remap data for the view
  if (!isEmail) {
    if (selectedModule && selectedModule === 'Push') {
      trends.widget.stats.all.count =
        mappedTrends['notification[sent]'] || trends.widget.stats.all.count;
      trends.widget.stats.view.count =
        mappedTrends['notification[shown]'] || trends.widget.stats.view.count;
    } else {
      trends.widget.stats.all.count =
        mappedTrends.impression || trends.widget.stats.all.count;
      if (isFacetedSearch) {
        trends.widget.stats.view.count =
          mappedTrends.totalView || trends.widget.stats.view.count;
      } else {
        trends.widget.stats.view.count =
          mappedTrends.widgetView || trends.widget.stats.view.count;
        if (withPageViewAndImpressionRate) {
          trends.widget.stats.pageView.count =
            mappedTrends.pageView || trends.widget.stats.pageView.count;
          trends.widget.stats.impressionRate.ratio = calculateRatio(
            mappedTrends.impression,
            mappedTrends.pageView,
          );
        }
      }
    }
  } else {
    trends.widget.stats.all.count =
      mappedTrends['email[sent]'] || trends.widget.stats.all.count;
    trends.widget.stats.view.count =
      mappedTrends['email[opened]'] || trends.widget.stats.view.count;
    trends.widget.stats.unsubscribed.total =
      mappedTrends.unsubUser || trends.widget.stats.unsubscribed.total;
    trends.widget.stats.bounceBack.HardBounce =
      mappedTrends['email[HardBounce]'] ||
      trends.widget.stats.bounceBack.HardBounce;
    trends.widget.stats.bounceBack.SoftBounce =
      mappedTrends['email[SoftBounce]'] ||
      trends.widget.stats.bounceBack.SoftBounce;
    trends.widget.stats.Spam = mappedTrends.spam;

    if (hasKlaviyoConfiguration() || hasKlaviyo) {
      trends = clone(trendsMapWithKlaviyo);
      if (isCampaignDetail) {
        trends.revenue.stats.diff = {
          name: 'Diff',
          ratio: 0,
        };
      }
      trends.widget.stats.klaviyo.flow =
        mappedTrends.triggeredToFlow || trends.widget.stats.klaviyo.flow;
      trends.widget.stats.klaviyo.profile =
        mappedTrends.triggeredToProfile || trends.widget.stats.klaviyo.profile;
    }
  }

  trends.widget.stats.conversion.ratio = calculateRatio(
    trends.widget.stats.view.count,
    trends.widget.stats.all.count,
  );

  if (!isEmail) {
    if (selectedModule && selectedModule === 'Push') {
      trends.interaction.stats.all.count =
        mappedTrends['notification[clicked]'] ||
        trends.interaction.stats.all.count;
    } else {
      trends.interaction.stats.all.count =
        mappedTrends.click || trends.interaction.stats.all.count;
      if (
        selectedModule &&
        selectedModule.toLowerCase().indexOf('recommendation') > -1 &&
        hasModule('recommendation') &&
        isRecommendationTrackEnabled('sliderClick')
      ) {
        trends.interaction.stats.leftArrowClick = {
          visible: true,
          count: mappedTrends.leftArrowClick || 0,
          name: 'Left Arrow Click',
          icon: 'icon-left-arrow',
        };
        trends.interaction.stats.rightArrowClick = {
          visible: true,
          count: mappedTrends.rightArrowClick || 0,
          name: 'Right Arrow Click',
          icon: 'icon-right-arrow',
        };
        trends.interaction.stats = changeOrderInObject(
          trends.interaction.stats,
          trendsInteractionOrder,
        );
      } else if (isDashboardData === true && isFacetedSearch) {
        const extendedTrendObj = {
          ...trends,
          assistedRevenue: clone(assistedRevenueTrend),
        };
        trends.assistedRevenue = getRevenue(
          extendedTrendObj.assistedRevenue,
          mappedTrends,
          trends,
          totalRevenue,
          totalPurchase,
          totalPurchaseItems,
          isCampaignDetail,
        );
      }
    }
  } else {
    trends.interaction.stats.all.count =
      mappedTrends['email[clicked]'] || trends.interaction.stats.all.count;
  }

  trends.interaction.stats.conversion.ratio = calculateRatio(
    trends.interaction.stats.all.count,
    trends.widget.stats.view.count,
  );

  trends.basket.stats.all.count =
    mappedTrends.basketItems || trends.basket.stats.all.count;
  trends.basket.stats.amount.count =
    mappedTrends.basketAmount || trends.basket.stats.amount.count;
  trends.basket.stats.conversion.ratio = calculateRatio(
    trends.basket.stats.all.count,
    trends.interaction.stats.all.count,
  );

  trends.revenue.stats.all.count =
    mappedTrends.purchaseAmount || trends.revenue.stats.all.count;
  trends.revenue.stats.purchases.count =
    mappedTrends.purchases || trends.revenue.stats.purchases.count;
  trends.revenue.stats.items.count =
    mappedTrends.purchasedItems || trends.revenue.stats.items.count;
  trends.revenue.stats.average.count = calculateProportion(
    mappedTrends.purchaseAmount,
    mappedTrends.purchases,
  );
  trends.revenue.stats.purchases.ratio = calculateRatio(
    trends.revenue.stats.purchases.count,
    totalPurchase,
  );
  trends.revenue.stats.items.ratio = calculateRatio(
    trends.revenue.stats.items.count,
    totalPurchaseItems,
  );

  trends.revenue.stats.conversion.ratio = calculateRatio(
    trends.revenue.stats.purchases.count,
    trends.interaction.stats.all.count,
  );

  trends.revenue.stats.contribution.ratio = calculateRatio(
    trends.revenue.stats.all.count,
    totalRevenue,
  );

  if (isCampaignDetail) {
    trends.revenue.stats.diff.ratio = calculateRatio(
      trends.revenue.stats.all.count,
      totalRevenue,
    );

    const numericRatio = numericCalculateRatio(
      trends.revenue.stats.all.count,
      totalRevenue,
    );

    const numericRatioPrev = numericCalculateRatio(
      mappedTrends.purchaseAmountPrev,
      mappedTrends['purchase:amountPrev'],
    );

    const diff = diffRatio(numericRatio, numericRatioPrev);

    trends.revenue.stats.diff.ratio = (
      <span style={{ color: '#f0000' }}>
        <span>{diff}</span>
        {numericRatio > numericRatioPrev && (
          <span style={{ color: 'rgb(86, 188, 148)' }}>▲</span>
        )}

        {numericRatio < numericRatioPrev && (
          <span style={{ color: '#cc4331' }}>▼</span>
        )}
      </span>
    );
  }

  return trends;
};

const getRevenue = (
  revenueObj,
  mappedTrends,
  trends,
  totalRevenue,
  totalPurchase,
  totalPurchaseItems,
  isCampaignDetail,
) => {
  const mappedTrendsPurchaseAmount =
    mappedTrends[revenueObj.accessor.purchaseAmount] || 0;

  revenueObj.stats.all.count =
    mappedTrendsPurchaseAmount || revenueObj.stats.all.count;

  revenueObj.stats.purchases.count =
    mappedTrends[revenueObj.accessor.purchases] ||
    revenueObj.stats.purchases.count;
  revenueObj.stats.purchases.ratio = calculateRatio(
    revenueObj.stats.purchases.count,
    totalPurchase,
  );

  revenueObj.stats.items.count =
    mappedTrends[revenueObj.accessor.purchasedItems] ||
    revenueObj.stats.items.count;
  revenueObj.stats.items.ratio = calculateRatio(
    revenueObj.stats.items.count,
    totalPurchaseItems,
  );

  revenueObj.stats.average.count = calculateProportion(
    mappedTrendsPurchaseAmount,
    mappedTrends.purchases,
  );

  revenueObj.stats.conversion.ratio = calculateRatio(
    revenueObj.stats.items.count,
    trends.interaction.stats.all.count,
  );
  revenueObj.stats.contribution.ratio = calculateRatio(
    revenueObj.stats.all.count,
    totalRevenue,
  );

  if (isCampaignDetail) {
    revenueObj.stats.diff.ratio = calculateRatio(
      revenueObj.stats.all.count,
      totalRevenue,
    );

    const numericRatio = numericCalculateRatio(
      revenueObj.stats.all.count,
      totalRevenue,
    );
    const numericRatioPrev = numericCalculateRatio(
      mappedTrends[revenueObj.accessor.purchaseAmountPrev],
      mappedTrends[revenueObj.accessor['purchaseAmountPrev#']],
    );

    const diff = diffRatio(numericRatio, numericRatioPrev);

    revenueObj.stats.diff.ratio = (
      <span style={{ color: '#f0000' }}>
        <span>{diff}</span>
        {numericRatio > numericRatioPrev && (
          <span style={{ color: 'rgb(86, 188, 148)' }}>▲</span>
        )}

        {numericRatio < numericRatioPrev && (
          <span style={{ color: '#cc4331' }}>▼</span>
        )}
      </span>
    );
  }
  return revenueObj;
};
export default transformTrends;
