import React, { useEffect, useMemo, useState } from 'react';
import './SFYRelatedProducts.scss';
import { browserHistory } from 'react-router';
import Icons from '../../icons';
import { SFYCheckBox } from '../module-exports';
import { attributeComponents } from './Attributes/attributeComponents';
import { modalActions } from '../../../actions';

/**
 * @name SFYRelatedProducts
 * @description Add Related Products component
 * @param {string} headerTitle
 * @param {string} description
 * @param {any[]} externalAttributes - Array of attributes
 * @param data {Object} - Object of data of reachable components, component props name must be the same as key in data object
 * @param data.modalDetails {{title, subtitle, className, component }} - Modal details
 * @param defaultComponent {string} - Default component to render
 * @param defaultComponentAction {string} - Action buttons for default component
 * @param defaultFunction {function} - Default function to execute for buttons
 * @param setAttributes {function} - set related products function which will be called when user changes related attributes
 * @param isSearchActive {boolean} - search active
 * @param searchActiveSetter {function} - search active setter function
 * @return {JSX.Element} - Add Related Products component
 * @constructor
 */

const SFYRelatedProducts = ({
  headerTitle,
  description,
  data,
  defaultComponent,
  defaultComponentAction,
  defaultFunction,
  setAttributes,
  externalAttributes,
  isSearchActive = false,
  searchActiveSetter,
}) => {
  const [openedFields, setOpenedFields] = useState([]);
  const DefaultComponent = useMemo(
    () => attributeComponents[defaultComponent],
    [defaultComponent],
  );

  /**
   * @name handleRoute
   * @param route - route to direct
   * @param state - state to pass to route
   */
  const handleRoute = (route, state) => {
    browserHistory.push({ pathname: route, state });
  };

  const openModal = ({ title, subtitle, className, component }) => {
    const content = attributeComponents[component];

    modalActions.openModal({
      title,
      subtitle,
      className,
      content,
    });
  };

  const handleButtonClick = fieldName => {
    const newArray = [...openedFields];
    if (newArray.includes(fieldName)) {
      newArray.splice(newArray.indexOf(fieldName), 1);
      setAttributes([], fieldName);
    } else {
      newArray.push(fieldName);
    }

    setOpenedFields(newArray);
  };

  const handleDefaultFunction = fieldName => {
    defaultFunction(fieldName);
  };

  const attributeLabelController = useMemo(
    () =>
      externalAttributes.map(
        ({ type, name, modalDetails, icon, label, url, customStyle = {} }) => (
          <button
            type='button'
            key={`rp-${name}`}
            disabled={!url?.isActive && name === 'urlRoute'}
            className={`sfy-related-products-attributes ${
              openedFields.includes(name) ? 'opened-field' : ''
            }`}
            style={customStyle}
            onClick={() => {
              switch (type) {
                case 'showComponent':
                  handleButtonClick(name);
                  break;
                case 'addComponent':
                  handleDefaultFunction(name);
                  break;
                case 'urlRoute':
                  handleRoute(url.to, {
                    data,
                    from: url.from,
                    endPoint: url.searchEndpoint,
                    searchType: url.searchType,
                  });
                  break;
                case 'modal':
                  openModal(modalDetails);
                  break;
                default:
                  handleButtonClick(name);
              }
            }}
          >
            <Icons name={icon} color='#FFFFFF' height='12' width='12' />
            <span className='sfy-related-products-attributes-name'>
              {label}
            </span>
          </button>
        ),
      ),
    [openedFields],
  );

  useEffect(() => {
    const tempOpenedFields = [];
    externalAttributes.forEach(({ name }) => {
      if (
        (data?.[name] && data[name].length > 0) ||
        openedFields.includes(name)
      ) {
        tempOpenedFields.push(name);
      }
    });

    setOpenedFields(tempOpenedFields);
  }, [data]);

  return (
    <div className='sfy-related-products'>
      <div className='sfy-related-products-header'>
        <div className='sfy-related-products-header-left'>
          <h3 className='sfy-related-products-header-text'>{headerTitle}</h3>
          <span className='sfy-related-products-header-description'>
            {description}
          </span>
        </div>
        <div className='sfy-related-products-header-right'>
          <div className='sfy-related-products-header-buttons-field'>
            {attributeLabelController}
          </div>
          {isSearchActive && (
            <SFYCheckBox
              searchActiveSetter={searchActiveSetter}
              checked={data?.activateOnSearchBox}
            />
          )}
        </div>
      </div>
      <div
        className={
          openedFields.length > 0 || defaultComponent
            ? 'sfy-related-products-body'
            : ''
        }
      >
        {defaultComponent && (
          <DefaultComponent
            data={data}
            actionHandler={defaultComponentAction}
          />
        )}
        {externalAttributes.map(
          ({ name, component, isDraggable, isRemovable }) => {
            if (!openedFields.includes(name)) {
              return null;
            }

            const attributeProps = {
              [name]: data?.[name] || [],
              setAttributes,
              onRemove: handleButtonClick,
              isDraggable: isDraggable && isDraggable,
              isRemovable: isRemovable && isRemovable,
            };

            const Component = attributeComponents[component];
            return <Component {...attributeProps} />;
          },
        )}
      </div>
    </div>
  );
};

export default SFYRelatedProducts;
