/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconAndroid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            fill={this.state.color}
            className="svg-path"
            d="M315.29,46l5.35-8,5.25-7.9,11.83-17.74a4.74,4.74,0,1,0-7.89-5.25l-12.67,19-5.33,8-5.41,8.1a158.66,158.66,0,0,0-112.84,0l-5.38-8.1-5.33-8-12.69-19a4.74,4.74,0,1,0-7.89,5.25L174.1,30.1l5.27,7.9,5.35,8c-40.26,18.74-67.48,54.26-67.48,94.9H382.77C382.77,100.29,355.55,64.78,315.29,46ZM193.11,103a14.22,14.22,0,1,1,14.21-14.22A14.22,14.22,0,0,1,193.11,103Zm113.79,0a14.22,14.22,0,1,1,14.23-14.22A14.22,14.22,0,0,1,306.9,103Z"
          />
          <path
            fill={this.state.color}
            className="svg-path"
            d="M119.29,159.9h-2V368.55a30.06,30.06,0,0,0,30,30h21.68a28.41,28.41,0,0,0-1.13,7.89v60.08a28.45,28.45,0,0,0,56.91,0V406.47a29.21,29.21,0,0,0-1.13-7.89h52.83a28.41,28.41,0,0,0-1.13,7.89v60.08a28.45,28.45,0,1,0,56.9,0V406.47a28.46,28.46,0,0,0-1.13-7.89h21.67a30.07,30.07,0,0,0,30-30V159.9H119.29Z"
          />
          <path
            fill={this.state.color}
            className="svg-path"
            d="M69.82,159.9a28.45,28.45,0,0,0-28.45,28.46V310.05a28.45,28.45,0,1,0,56.9,0V188.35A28.44,28.44,0,0,0,69.82,159.9Z"
          />
          <path
            fill={this.state.color}
            className="svg-path"
            d="M430.2,159.9a28.44,28.44,0,0,0-28.45,28.46V310.05a28.44,28.44,0,1,0,56.88,0V188.35A28.43,28.43,0,0,0,430.2,159.9Z"
          />
        </g>
      </svg>
    );
  }
}

export default IconAndroid;
