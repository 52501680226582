export const selectUserState = {
  accountsOptions: [],
  selectedAccounts: [],
  modules: [],
  selectedModules: [],
  username: '',
  displayName: '',
  selectedUserType: '',
  userRole: {},
  accountsRequiredError: false,
  usernameRequiredError: false,
  displayNameRequiredError: false,
  userRoleRequiredError: false,
  selectModuleRequiredError: false,
};

export const addEditUserReducer = (state = selectUserState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_ACCOUNTS_OPTIONS':
      return {
        ...state,
        accountsOptions: payload,
      };
    case 'SET_SELECTED_ACCOUNTS':
      return {
        ...state,
        selectedAccounts: payload,
      };
    case 'SET_MODULES':
      return {
        ...state,
        modules: payload,
      };
    case 'SET_SELECTED_MODULES':
      return {
        ...state,
        selectedModules: payload,
      };
    case 'SET_USERNAME':
      return {
        ...state,
        username: payload,
      };
    case 'SET_DISPLAY_NAME':
      return {
        ...state,
        displayName: payload,
      };
    case 'SET_SELECTED_USER_TYPE':
      return {
        ...state,
        selectedUserType: payload,
      };
    case 'SET_USER_ROLE':
      return {
        ...state,
        userRole: payload,
      };
    case 'SET_ACCOUNTS_REQUIRED_ERROR':
      return {
        ...state,
        accountsRequiredError: payload,
      };
    case 'SET_USERNAME_REQUIRED_ERROR':
      return {
        ...state,
        usernameRequiredError: payload,
      };
    case 'SET_DISPLAY_NAME_REQUIRED_ERROR':
      return {
        ...state,
        displayNameRequiredError: payload,
      };
    case 'SET_USER_ROLE_REQUIRED_ERROR':
      return {
        ...state,
        userRoleRequiredError: payload,
      };
    case 'SET_SELECT_MODULE_REQUIRED_ERROR':
      return {
        ...state,
        selectModuleRequiredError: payload,
      };
    default:
      return state;
  }
};
