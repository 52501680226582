import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const dismissIcon = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.266267 8.44808C-0.0887555 8.80311 -0.0887555 9.37871 0.266267 9.73373C0.621289 10.0888 1.19689 10.0888 1.55192 9.73373L5 6.28565L8.44808 9.73373C8.80311 10.0888 9.37871 10.0888 9.73373 9.73373C10.0888 9.37871 10.0888 8.80311 9.73373 8.44808L6.28565 5L9.73373 1.55192C10.0888 1.19689 10.0888 0.621289 9.73373 0.266267C9.37871 -0.0887557 8.80311 -0.0887556 8.44808 0.266267L5 3.71435L1.55192 0.266267C1.19689 -0.088755 0.621289 -0.088755 0.266267 0.266267C-0.0887555 0.621289 -0.0887555 1.19689 0.266267 1.55192L3.71435 5L0.266267 8.44808Z'
        fill={color}
      />
    </svg>
  );
};

dismissIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

dismissIcon.defaultProps = {
  width: '10px',
  height: '10px',
  color: '#9F272A',
};

export default dismissIcon;
