import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import DraggableListItem from './DraggableListItem';

const checkIsDifferent = (contextList = [], stateList = []) => {
  return contextList?.some((item, index) => {
    return item?.instanceId !== stateList[index]?.instanceId;
  });
};

/**
 * @name DraggableList component
 * @description Campaign component displays list of items and its actions
 * @param list
 * @param allList
 * @param setList
 * @param {object} actions - action constant
 * @param isDraggable
 * @param onDragged
 * @returns {JSX.Element}
 */
const SFYList = ({
  list,
  setList,
  actions,
  isDraggable = false,
  allList,
  onDragged,
}) => {
  const [listState, setListState] = useState(list);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    setListState(list);
  }, [list]);

  const moveCard = (dragIndex, hoverIndex) => {
    setIsDragging(true);
    const newList = [];
    listState.forEach((item, index) => {
      if (index === dragIndex) {
        newList[hoverIndex] = {
          ...item,
          priority: hoverIndex,
          index: hoverIndex,
        };
      } else if (index === hoverIndex) {
        newList[dragIndex] = {
          ...item,
          priority: dragIndex,
          index: dragIndex,
        };
      } else {
        newList[index] = { ...item, index };
      }
    });
    setListState(newList);
  };

  useEffect(() => {
    if (!isDragging && checkIsDifferent(list, listState)) {
      let draggedList = {};
      if(listState[0].status === 'ACTIVE') {
        draggedList = {
          ...allList,
          active: listState,
        };
      } else if(listState[0].status === 'TEST') {
        draggedList = {
          ...allList,
          test: listState,
        };
      }
      setList(draggedList);
      if (onDragged) {
        const { active, test, passive } = draggedList;
        onDragged([...active, ...test, ...passive]);
      }
    }
  }, [isDragging, listState]);

  const GFCampaignListRender = () => {
    return listState.map((item, index) => {
      return (
        <DraggableListItem
          key={uuid()}
          priorityItem={{ ...item, index }}
          moveCard={moveCard}
          moveCompleted={() => setIsDragging(false)}
          actions={actions}
          isDraggable={isDraggable}
        />
      );
    });
  };

  return <>{GFCampaignListRender()}</>;
};

export default SFYList;
