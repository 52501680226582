/**
 * @fileoverview This page helps to manage edit actions of account
 */

/**
 * Update a state value by state name.
 * @param {string} stateName
 * @param {Object|Array|String|Number|Any} newState
 * @returns {{payload: Object, type: String}}
 */
export const reducerStateChange = (stateName, newState) => {
  return { type: 'STATE_CHANGE', payload: { stateName, newState } };
};

/**
 * Update states that include in array.
 * @param {Object} stateGroup
 * @returns {{payload: Object, type: String}}
 */
export const reducerGroupStateChange = stateGroup => {
  return { type: 'GROUP_STATE_CHANGE', payload: stateGroup };
};
