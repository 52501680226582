import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.replace('px', '')}px`;
};

const EditPencil = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 16 16'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      fill='none'
    >
      <path
        d='M14.7139 1.28595C13.6657 0.237809 11.9664 0.237862 10.9183 1.28606L1.95577 10.2496C1.65102 10.5544 1.43682 10.9378 1.337 11.3571L0.515307 14.8081C0.470067 14.9982 0.526639 15.1981 0.664759 15.3362C0.802887 15.4743 1.00278 15.5309 1.1928 15.4856L4.64373 14.664C5.06313 14.5641 5.44659 14.3498 5.75143 14.0449L14.714 5.08134C15.762 4.03322 15.762 2.33401 14.7139 1.28595ZM11.7139 2.08151C12.3225 1.47269 13.3096 1.47266 13.9184 2.08144C14.5271 2.69018 14.5272 3.67712 13.9185 4.28589L13.25 4.95447L11.0455 2.74997L11.7139 2.08151ZM10.25 3.54551L12.4545 5.75002L4.95589 13.2495C4.79828 13.4071 4.60001 13.5179 4.38316 13.5696L1.82143 14.1795L2.43141 11.6176C2.48301 11.4009 2.59376 11.2027 2.75131 11.0451L10.25 3.54551Z'
        fill='#ffffff'
      />
    </svg>
  );
};

EditPencil.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

EditPencil.defaultProps = {
  width: '16px',
  height: '16px',
};

export default EditPencil;
