import React from 'react';
import './EmailStepper.scss';
import { browserHistory } from 'react-router';
import { t } from '../../../../system/ui';

/**
 * @name EmailStepper this component is used to show the steps of the Email
 * @description this component is used to show the steps of the EmailStepper
 * @returns {JSX.Element} - Countdown component
 */

const EmailStepper = ({
  activeStepIndex,
  campaignPathname = '',
  templateId,
}) => {
  const stepperData = [
    {
      key: 'choose-template',
      label: t('Edit Design'),
      path: `/email/templates/edit/${templateId}`,
    },
    {
      key: 'campaign-info',
      label: t('Campaign Info'),
      path: '',
    },
  ];

  return (
    <div
      className='email-stepper'
      style={activeStepIndex === 0 ? { margin: 0 } : {}}
    >
      {stepperData.map((item, index) => (
        <>
          {index > 0 && <span className='email-stepper__separator'>{'>'}</span>}
          <div
            className={`email-stepper__item ${
              index <= activeStepIndex ? 'active' : ''
            }`}
            key={item.key}
            onClick={() => {
              if (campaignPathname && item.path) {
                browserHistory.push({
                  pathname: item.path,
                  state: {
                    campaignPathname,
                    isFork: true,
                  },
                });
              }
            }}
            style={{ cursor: campaignPathname && item.path ? 'pointer' : '' }}
          >
            <div
              className={`email-stepper__item__icon ${
                index <= activeStepIndex ? 'active' : ''
              }`}
            >
              <span>{index + 1}</span>
            </div>
            <div
              className={`email-stepper__item__text ${
                campaignPathname && item.path ? 'underlined' : ''
              }`}
            >
              <span className='email-stepper__item__text__title'>
                {item.label}
              </span>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default EmailStepper;
