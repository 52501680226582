/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import AllPushes from './pushes.viewall';
import { uiActions } from '../actions';
import {
  getPushCampaigns,
  getRangedPushReports,
  getPermissionReport,
} from '../modules/push/ajax';
import EmailStatusFilters from '../components/filters/email-status-filters';
import Icon from '../components/icon';
import Icons from '../components/icons';
import PushList from '../components/push/push-list';
import PushTypeSelection from '../components/push/push-type-selection';
import { transformReport } from '../components/push/utils';
import {
  csSearchParams,
  itemsPerScroll,
  pushGroups,
  pushCampaignNames,
  sortingTypes,
} from '../components/reports/utils';
import Tooltip from '../components/tooltip';
import { pushList } from '../constants/datamaps/push';
import LoaderPlaceholder from '../components/widget/loader-placeholder';
import { hasModule } from '../modules/auth/user';
import { pushIntegrationInfo } from '../modules/trendify/page';
import { isAfterNow } from '../system/date';
import { config } from '../constants/config';
import { setTitle } from '../system/document';
import { t } from '../system/ui';
import reduceBasket from '../system/reducer';

const getCampaignBadges = campaign => {
  let badge = '';
  const { schedule } = campaign;
  if (schedule) {
    if (schedule.type === 'periodic') {
      badge = 'periodic';
    } else if (schedule.type === 'one_time') {
      badge = isAfterNow(moment(schedule.date)) ? 'scheduled' : 'sent';
    } else if (schedule.type === 'now') {
      badge = 'sent';
    }
    return badge;
  }
  if (pushList.indexOf(campaign.type) > -1) {
    if (
      campaign.type === 'ABANDONED_CART' ||
      campaign.type === 'LAST_VISIT_ALTERNATIVES' ||
      campaign.type === 'LAST_VISIT_REMINDER' ||
      campaign.type === 'ORDER_FOLLOW_UP'
    ) {
      badge = 'personalized';
    } else {
      badge = 'periodic';
    }
    return badge;
  }
  if (campaign.type === 'FLY') {
    badge = 'personalized';
    return badge;
  }
  return false;
};

const campaignDescriptions = {
  retention:
    'Increase customer loyalty by reaching your customers through action-based, personalised retention campaigns',
  growth:
    'Keep in touch with your customers with personalised content on a regular basis to increase your online sales',
  winback:
    'Remind your customers about what they’re missing with automated winback campaigns and keep in touch with churn or potential churn segments',
  bulk:
    'Reach your users and promote your campaigns or make announcement about your all news.',
  fly:
    'Engage with your customers while they’re still on your website and seize the opportunities with different scenarios.',
  recommendation:
    'Use machine-learning based recommendations to increase your sales even when your customers are away.',
  betting:
    "Remind your customers about what they're missing with automated betting campaigns and keep them in touch with the games",
};

const noCampaignTexts = {
  'Permission Prompt':
    'Customize your permission prompts and ask nicely for your visitors’ approval to receive push notifications from you.',
  'Bulk / Segmented Notifications':
    'Create bulk campaigns for your customers or communicate with them in micro-segments.',
  'Recommendation Campaigns':
    'Use machine-learning based recommendations to increase your sales even when your customers are away.',
  'Winback Campaigns':
    'Remind your customers about what they’re missing with automated winback campaigns and keep in touch with churn or potential churn segments.',
  'Retention Campaigns':
    'Retain your customers by reaching them through action-based, personalised retention campaigns.',
  'Growth Campaigns':
    'Keep in touch with your customers with personalised content on a regular basis to increase your online sales.',
  'Fly Mode Campaigns':
    'Engage with your customers while they’re still on your website and seize the opportunities with different scenarios.',
  'Betting Campaigns':
    "Remind your customers about what they're missing with automated betting campaigns and keep them in touch with the games",
};

const scheduleFilters = [
  {
    value: 'ALL',
    icon: 'view',
    label: 'All',
    color: '#827e7c',
    fontIcon: true,
  },
  {
    value: 'personalized',
    icon: 'personalizedPush',
    label: 'Trigger Based',
    color: '#66d281',
  },
  {
    value: 'periodic',
    icon: 'periodicPush',
    label: 'Recurring',
    color: '#56bbd8',
  },
  { value: 'sent', icon: 'sentPush', label: 'Completed', color: '#66d281' },
  {
    value: 'scheduled',
    icon: 'scheduledPush',
    label: 'Scheduled',
    color: '#547494',
  },
];

class Pushes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      pushCampaigns: [],
      _pushCampaigns: [],
      liveEmails: [],
      reports: {},
      title: t('All Campaigns'),
      status: 'ACTIVE',
      medium: [],
      searchTerm: '',
      pushType: 'view-all',
      sortValue: '',
      sortIcon: 'preferences',
      showSortOptions: false,
      scrollCount: 0,
      showOptions: false,
      showScheduleFilters: false,
      sort: 'DESC',
      sortType: 'revenue',
      toggleIcon: 'preferences',
      campaignsInited: false,
      sentClosed: true,
      scheduleType: 'ALL',
      permissionElement: '',
      permissionInstanceId: '',
      permissionCampaign: [],
      permissionReport: {},
      isPure: true,
    };

    this.getPushCampaigns = this.getPushCampaigns.bind(this);
    this.filterPushes = this.filterPushes.bind(this);
    this.setPushType = this.setPushType.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.increaseScrollCount = this.increaseScrollCount.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onMediumChange = this.onMediumChange.bind(this);
    this.onActiveCount = this.onActiveCount.bind(this);
    this.toggleScheduleFilters = this.toggleScheduleFilters.bind(this);
    this.getPermission = this.getPermission.bind(this);
    this.getCampaignsAfterStatusChange = this.getCampaignsAfterStatusChange.bind(
      this,
    );
  }

  componentDidMount() {
    uiActions.hideBreadcrumb();
    const ext = window.location.pathname.indexOf('ext/push') >= 0 ? '/ext' : '';
    if (document.location.pathname === '/push') {
      this.props.router.push({
        pathname: `${ext}/push/view-all`,
      });
    } else {
      this.setPushType(
        document.location.pathname.split('/')[
          document.location.pathname.split('/').length - 1
        ],
      );
    }

    this.getPushCampaigns();
    window.onscroll = () => {
      if (ext === '/ext') {
        if (
          window.innerHeight + window.scrollY + 100 >=
          document.body.scrollHeight
        ) {
          this.increaseScrollCount();
        }
      } else if (
        window.innerHeight + window.scrollY >=
        document.body.scrollHeight
      ) {
        this.increaseScrollCount();
      }
    };
  }

  getCampaignsAfterStatusChange() {
    this.componentDidMount();
  }

  getPermission(array) {
    for (let i = 0; i < array.length; i += 1) {
      if (
        array[i].type === 'PUSH_PERMISSION' &&
        array[i].status === this.state.status
      ) {
        const permissionCamp = [];
        permissionCamp.push(array[i]);
        this.setState({
          permissionCampaign: permissionCamp,
        });
        break;
      }
      if (i === array.length - 1) {
        this.setState({
          permissionCampaign: [],
        });
      }
    }
  }

  componentWillReceiveProps(newProps) {
    const children = config.menus.primary.push.children[newProps.params.push];
    setTitle(`${children.name} - ${t('Push Campaigns')}`);
    if (this.props.params.push !== newProps.params.push) {
      this.setPushType(newProps.params.push);
    }

    if (this.props.user !== newProps.user) {
      //  this.getEmails();
    }
  }

  getPushCampaigns() {
    pushIntegrationInfo();
    if (hasModule('push')) {
      uiActions.isLoading();
      let allPushes = [];
      getPushCampaigns(response => {
        if (response) {
          allPushes = response;
          this.setState(
            {
              _pushCampaigns: allPushes,
              isLoading: false,
            },
            () => {
              this.filterPushes('statusOrMediumChange');
              getPermissionReport(response => {
                this.setState(
                  {
                    permissionReport: response
                      ? transformReport(response)
                      : transformReport({}),
                  },
                  () => {
                    this.getPermission(this.state._pushCampaigns);
                  },
                );
              });
            },
          );
        }
      });
    }
  }

  filterPushes(source, eventParam, e) {
    const allPushCampaigns = this.state._pushCampaigns;
    const { status } = this.state;
    const { medium } = this.state;
    const { searchTerm } = this.state;
    const { pushType } = this.state;
    const isViewAll =
      !this.state.pushType || this.state.pushType === 'view-all';
    const permissionIndex = 0;
    if (
      eventParam === 'CTR' ||
      eventParam === 'Revenue' ||
      eventParam === 'Conversion'
    ) {
      this.setState({ isPure: false });
    }
    // Status filter
    let filteredPushes = allPushCampaigns.filter(push => {
      return pushType !== 'view-all' ? true : push.status === status;
    });

    // Medium filter
    const actualMedium =
      medium.indexOf('ALL') > -1 || medium.length === 0 ? false : medium;

    if (actualMedium) {
      filteredPushes = filteredPushes.filter(push => {
        let isFiltered = false;
        push.devices.forEach(dvc => {
          if (actualMedium.indexOf(dvc) > -1) {
            isFiltered = true;
          }
        });
        if (pushType !== 'view-all') {
          return true;
        }
        return isFiltered;
      });
    }

    // Type Filter
    if (pushType !== 'view-all') {
      filteredPushes = filteredPushes.filter(push => {
        return pushGroups[pushType].indexOf(push.type) > -1;
      });
    }

    if (this.state.pushType !== 'view-all') {
      this.setState({
        pushCampaigns: filteredPushes,
        title: isViewAll ? t('All Campaigns') : t(pushCampaignNames[pushType]),
      });
    } else {
      // Sorting
      if (e) {
        e.preventDefault();
      }
      let sortType;
      let sortOrder;
      let scrollCount;
      let deviceStatusFilteredCampaigns;
      let campaigns = this.state.pushCampaigns;
      const reverseCampaigns = false;
      const matchedCampaigns = [];
      const search = this.refs.search && this.refs.search.value;
      const scheduleType =
        this.state.scheduleType !== 'ALL' ? this.state.scheduleType : false;

      if (scheduleType) {
        filteredPushes = filteredPushes.filter(item => {
          return getCampaignBadges(item) === scheduleType;
        });
      }

      if (search) {
        filteredPushes.map(item => {
          if (
            item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
            item.instanceId.toLowerCase().indexOf(search.toLowerCase()) > -1
          ) {
            matchedCampaigns.push(item);
          }
        });
        deviceStatusFilteredCampaigns = matchedCampaigns;
      } else {
        deviceStatusFilteredCampaigns = filteredPushes;
      }

      if (
        source === 'statusOrMediumChange' ||
        source === 'search' ||
        source === 'scroll' ||
        source === 'schedule'
      ) {
        sortType = this.state.sortType;
        sortOrder = this.state.sort;
        scrollCount = source === 'scroll' ? this.state.scrollCount : 0;
      } else {
        // source is checkbox selected
        sortType = eventParam;
        scrollCount = 0;
        if (this.state.sortType !== sortType) {
          sortOrder = 'DESC';
        } else {
          sortOrder = this.state.sort === 'ASC' ? 'DESC' : 'ASC';
        }
      }

      if (source !== 'scroll') {
        campaigns = [];
      }

      const reportIds = deviceStatusFilteredCampaigns.map(cmp => {
        return cmp.instanceId;
      });

      if (deviceStatusFilteredCampaigns.length) {
        if (csSearchParams.indexOf(sortType) < 0) {
          // Server side sorting
          if (
            itemsPerScroll * (scrollCount + 1) - itemsPerScroll <
            deviceStatusFilteredCampaigns.length
          ) {
            uiActions.isLoading();
            getRangedPushReports(
              reportIds,
              'weekly',
              campaignReports => {
                uiActions.isLoaded();
                const filterList = [
                  'ABANDONED_CART_ALTERNATIVE_PRODUCTS',
                  'ABANDONED_CART_COUPON',
                  'ABANDONED_CART_FIRST_REMINDER',
                  'ABANDONED_CART_SECOND_REMINDER',
                ];
                const filteredKeys = Object.keys(campaignReports).filter(
                  item => filterList.indexOf(item) < 0,
                );
                const newCampaigns = filteredKeys.map(reportCampaignId => {
                  const targetCampaign = deviceStatusFilteredCampaigns.filter(
                    cmp => {
                      return cmp.instanceId === reportCampaignId;
                    },
                  );
                  return targetCampaign[0];
                });

                if (campaignReports.hasOwnProperty('ABANDONED_CART')) {
                  const reminders = [
                    campaignReports.ABANDONED_CART_FIRST_REMINDER,
                    campaignReports.ABANDONED_CART_SECOND_REMINDER,
                    campaignReports.ABANDONED_CART_ALTERNATIVE_PRODUCTS,
                    campaignReports.ABANDONED_CART_COUPON,
                    campaignReports.ABANDONED_CART,
                  ];

                  campaignReports.ABANDONED_CART = reduceBasket(reminders);
                }

                this.setState({
                  reports: {
                    ...this.state.reports,
                    ...campaignReports,
                  },
                  pushCampaigns: campaigns.concat(newCampaigns),
                  count: deviceStatusFilteredCampaigns.length,
                  sort: sortOrder,
                  sortType,
                  scrollCount,
                  showOptions: false,
                  toggleIcon: 'preferences',
                  title: isViewAll
                    ? t('All Campaigns')
                    : t(pushCampaignNames[pushType]),
                  campaignsInited: true,
                });
              },
              {
                items: itemsPerScroll,
                page: scrollCount + 1,
                sortBy: sortType,
                reverse: sortOrder === 'DESC',
              },
            );
          }
        } else {
          // Client Side Sorting
          const campaignsByDate = [...deviceStatusFilteredCampaigns].sort(
            (a, b) => {
              if (sortOrder === 'DESC') {
                return (
                  b.limitations.activeDates.startDate -
                  a.limitations.activeDates.startDate
                );
              }
              return (
                a.limitations.activeDates.startDate -
                b.limitations.activeDates.startDate
              );
            },
          );
          const campaignIdsToGetReport = [];
          const latestExistingCampaignIndex = campaigns.length;
          const newCampaigns = campaignsByDate.slice(
            latestExistingCampaignIndex,
            latestExistingCampaignIndex + itemsPerScroll,
          );
          const campaignsToShow = campaigns.concat(newCampaigns);

          campaignsToShow.forEach(campaign => {
            if (!this.state.reports[campaign.instanceId]) {
              campaignIdsToGetReport.push(campaign.instanceId);
            }
          });

          this.setState(
            {
              pushCampaigns: campaignsToShow,
              count: campaignsToShow.length,
              sort: sortOrder,
              sortType,
              scrollCount,
              showOptions: false,
              toggleIcon: 'preferences',
              title: isViewAll
                ? t('All Campaigns')
                : t(pushCampaignNames[pushType]),
            },
            () => {
              if (campaignIdsToGetReport.length) {
                uiActions.isLoading();
                getRangedPushReports(
                  campaignIdsToGetReport,
                  'weekly',
                  campaignReports => {
                    uiActions.isLoaded();
                    this.setState({
                      reports: {
                        ...this.state.reports,
                        ...campaignReports,
                      },
                      campaignsInited: true,
                    });
                  },
                  {},
                );
              }
            },
          );
        }
      } else {
        uiActions.isLoaded();
        this.setState({
          reports: {},
          pushCampaigns: [],
          count: deviceStatusFilteredCampaigns.length,
          sort: sortOrder,
          sortType,
          scrollCount,
          showOptions: false,
          toggleIcon: 'preferences',
          title: isViewAll
            ? t('All Campaigns')
            : t(pushCampaignNames[pushType]),
          campaignsInited: true,
        });
      }
    }

    uiActions.isLoaded();
  }

  setPushType(newType) {
    this.setState(
      {
        pushType: newType,
        scheduleType: 'ALL',
        showScheduleFilters: false,
      },
      () => {
        this.filterPushes('statusOrMediumChange');
      },
    );
  }

  onStatusChange(status) {
    this.setState(
      {
        status,
      },
      () => {
        this.getPermission(this.state._pushCampaigns);
        this.filterPushes('statusOrMediumChange');
      },
    );
  }

  onMediumChange(medium) {
    this.setState(
      {
        medium,
      },
      () => {
        this.filterPushes('statusOrMediumChange');
        if (medium.length !== 0) {
          this.setState({ isPure: false });
        } else {
          this.setState({ isPure: true });
        }
      },
    );
  }

  onSearch(e) {
    e.preventDefault();
    this.filterPushes('search');
    if (e.target.value !== '') {
      this.setState({ isPure: false });
    } else {
      this.setState({ isPure: true });
    }
  }

  toggleOptions(e) {
    e.preventDefault();

    this.setState({
      showOptions: !this.state.showOptions,
      toggleIcon: !this.state.showOptions ? 'cross' : 'preferences',
    });
  }

  toggleScheduleFilters(e) {
    e.preventDefault();

    this.setState({
      showScheduleFilters: !this.state.showScheduleFilters,
    });
  }

  increaseScrollCount() {
    if (this.state.pushType === 'view-all') {
      this.setState(
        {
          scrollCount: this.state.scrollCount + 1,
        },
        () => {
          this.filterPushes('scroll', false, false);
        },
      );
    }
  }

  onChangeStatus(instanceId, status, newStatus) {
    const newPushes = [];
    this.state._pushCampaigns.forEach(push => {
      if (push.instanceId === instanceId) {
        push.status = status;
      }
      newPushes.push(push);
    });
    this.setState(
      {
        _pushCampaigns: newPushes,
        status: newStatus,
      },
      () => {
        this.filterPushes('statusOrMediumChange');
      },
    );
  }

  onActiveCount(count) {
    this.setState({
      sentClosed: count > 0,
    });
  }

  filterBySchedule(filter) {
    this.setState(
      {
        scheduleType: filter,
      },
      () => {
        this.filterPushes(filter);
        if (filter !== 'ALL') {
          this.setState({ isPure: false });
        } else {
          this.setState({ isPure: true });
        }
      },
    );
  }

  render() {
    const noReportStyle =
      window.location.pathname.indexOf('ext/push') >= 0
        ? { top: '0', left: '175px' }
        : {};
    const containerStyle =
      window.location.pathname.indexOf('ext/push') >= 0
        ? { 'padding-left': '140px' }
        : {};
    if (!hasModule('push')) {
      return (
        <div>
          <div className='promo promo--centered email-discover-dashboard'>
            <Icons name='pushModule' width='64' height='64' />
            <p className='promo-text'>{t('PushPromo')}</p>
            <a
              href='https://segmentify.com/solution/cross-channel-marketing/'
              target='_blank'
              className='secondary-action'
              rel='noreferrer'
            >
              {t('Discover')}
            </a>
          </div>
        </div>
      );
    }
    if (this.state.isLoading) {
      // show placeholder
      return (
        <div>
          <div className='page-filters has-filter-form'>
            <EmailStatusFilters
              status={this.state.status}
              onStatusChange={this.onStatusChange}
              isPush
              onMediumChange={this.onMediumChange}
            />
          </div>
          <div style={{ marginTop: '80px' }}>
            <LoaderPlaceholder />
          </div>
        </div>
      );
    }
    // show view all
    if (this.state.pushType !== 'view-all') {
      const isScheduleFilter =
        this.state.pushType === 'bulk' ||
        this.state.pushType === 'recommendation';

      return (
        <div style={containerStyle}>
          <h2 className='page-title'>{t(this.state.title)}</h2>
          <div className='page-content-wrapper'>
            {this.state.pushCampaigns.filter(
              cmp => isScheduleFilter || cmp.status !== 'PASSIVE',
            ).length > 0 ? (
              isScheduleFilter ? (
                <div className='widget-list-wrapper'>
                  <PushList
                    type='ACTIVE'
                    title='Scheduled / Recurring Push Campaigns'
                    pushCampaigns={this.state.pushCampaigns}
                    scheduleFilter='scheduled'
                    onCountChange={this.onActiveCount}
                    onChangeStatus={this.onChangeStatus}
                  />

                  <PushList
                    type='ACTIVE'
                    title={t('Sent Push Campaigns')}
                    pushCampaigns={this.state.pushCampaigns}
                    closed={this.state.sentClosed}
                    scheduleFilter='sent'
                    withReplicateAction={this.state.pushType === 'bulk'}
                    noStatusFilter
                    onChangeStatus={this.onChangeStatus}
                  />

                  <PushList
                    type='TEST'
                    title={t('Draft Campaigns')}
                    pushCampaigns={this.state.pushCampaigns}
                    scheduleFilter='noScheduledTest'
                    closed={
                      this.state.pushCampaigns.filter(push => {
                        return push.status === 'ACTIVE';
                      }).length > 0
                    }
                    onChangeStatus={this.onChangeStatus}
                  />
                </div>
              ) : (
                <div className='widget-list-wrapper'>
                  <PushList
                    type='ACTIVE'
                    title={t('Live Campaigns')}
                    pushCampaigns={this.state.pushCampaigns}
                    onChangeStatus={this.onChangeStatus}
                  />

                  <PushList
                    type='TEST'
                    title={t('Draft Campaigns')}
                    pushCampaigns={this.state.pushCampaigns}
                    closed={
                      this.state.pushCampaigns.filter(push => {
                        return push.status === 'ACTIVE';
                      }).length > 0
                    }
                    onChangeStatus={this.onChangeStatus}
                  />
                </div>
              )
            ) : (
              <div className='widget-list-wrapper'>
                <div className='widget-list-no-items'>
                  {noCampaignTexts[this.state.title] ||
                    `No Campaign ${this.state.title}`}
                </div>
              </div>
            )}
            <PushTypeSelection
              pushType={this.state.pushType}
              pushGroups={pushGroups}
              campaignNames={pushCampaignNames}
              livePushCampaigns={this.state._pushCampaigns.map(campaign => {
                if (campaign.status === 'ACTIVE') {
                  return campaign.instanceId;
                }
                return null;
              })}
              allPushCampaigns={this.state._pushCampaigns.map(campaign => {
                return campaign.instanceId;
              })}
              title={this.state.title}
              description={campaignDescriptions[this.state.pushType]}
            />
          </div>
        </div>
      );
    }
    const comp = [
      <div className='schedule-filter-container' key={this.state.scheduleType}>
        <a
          href='#'
          className='schedule-filter-toggler'
          onClick={this.toggleScheduleFilters}
        >
          <Icons name='filter' width='16' height='16' />
        </a>
        <ul
          className={
            !this.state.showScheduleFilters
              ? 'schedule-filter-list is-hidden'
              : 'schedule-filter-list'
          }
        >
          {scheduleFilters.map(filter => {
            return (
              <Tooltip
                content={filter.label}
                alignment='left'
                newTooltip
                key={filter.value}
              >
                <li
                  className={classNames({
                    'active-schedule': this.state.scheduleType === filter.value,
                  })}
                  onClick={this.filterBySchedule.bind(this, filter.value)}
                >
                  {filter.fontIcon ? (
                    <Icon name={filter.icon} />
                  ) : (
                    <Icons
                      name={filter.icon}
                      color={filter.color}
                      width='16'
                      height='16'
                    />
                  )}
                </li>
              </Tooltip>
            );
          })}
        </ul>
      </div>,
    ];
    if (this.state.campaignsInited) {
      if (this.state.pushCampaigns.length) {
        comp.push(
          <AllPushes
            key='all-pushes'
            pushCampaigns={this.state.pushCampaigns}
            reports={this.state.reports}
            onChangeStatus={this.onChangeStatus.bind(this)}
            permissionCampaign={this.state.permissionCampaign}
            permissionReport={this.state.permissionReport}
            isPure={this.state.isPure}
            getCampaignsAfterStatusChange={this.getCampaignsAfterStatusChange}
          />,
        );
      } else {
        comp.push(<LoaderPlaceholder key='pushCampaigns-loader' />);
      }
    } else {
      comp.push(
        <div style={{ marginTop: '80px' }}>
          <LoaderPlaceholder key='campaignIniteds-loader' />
        </div>,
      );
    }
    return (
      <div style={containerStyle}>
        <h2 className='page-title'>
          {this.state.title}
          <span className='emphasise'> ({this.state.count})</span>
        </h2>

        <div className='page-options page-options-sort-search'>
          <label className='item is-stacked is-search'>
            <Icon name='magnify' />
            <input
              type='text'
              placeholder={t('Search')}
              className='text-field'
              onChange={this.onSearch}
              ref='search'
            />
          </label>

          <div className='data-preferences'>
            <a href='#' className='toggler' onClick={this.toggleOptions}>
              <Icon name={this.state.toggleIcon} />
              <span>Sort</span>
            </a>
            <ul
              className={
                !this.state.showOptions
                  ? 'data-preferences-options is-hidden'
                  : 'data-preferences-options'
              }
            >
              {sortingTypes.map(sortingType => {
                return (
                  <li
                    className='data-preferences-option'
                    key={sortingType.type}
                    onClick={this.filterPushes.bind(
                      this,
                      'event',
                      sortingType.type,
                    )}
                  >
                    <a href='#' className='data-preferences-option-item'>
                      {sortingType.text}
                      {this.state.sortType === sortingType.type &&
                        (this.state.sort !== 'DESC' ? (
                          <i className='icon-caret-up viewall-sorting' />
                        ) : (
                          <i className='icon-caret-down viewall-sorting' />
                        ))}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className='page-filters has-filter-form'>
          <EmailStatusFilters
            status={this.state.status}
            onStatusChange={this.onStatusChange}
            isPush
            medium={this.state.medium}
            onMediumChange={this.onMediumChange}
          />
        </div>
        {comp}
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  user: store.user.user,
  status: store.campaigns.status,
});

export default connect(MapStatesToProps)(Pushes);
