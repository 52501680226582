/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconCustomExclude extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <polygon
            fill={this.state.color}
            points="9,4 7,4 7,7 4,7 4,9 7,9 7,12 9,12 9,9 12,9 12,7 9,7 "
          />
          <path
            d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6&#xA;&#x9;S11.3,14,8,14z"
            fill={this.state.color}
          />
        </g>
      </svg>
    );
  }
}

export default IconCustomExclude;
