import React from 'react';
import './Tabs.scss';
import { isLoaded, isLoading, showNotification } from '../../../../actions/ui';
import { t } from '../../../../system/ui';
import { inputConfiguration, frequencyOptions } from '../constants';
import { TextField, Select } from '../../../../components/fields';
import { SFYSwitch } from '../../../../components/sfy-components/module-exports';
import FtpService from '../services';

const ImportFTP = ({ state, dispatch }) => {
  const handleChange = e => {
    const el = e.target;
    if (el.type === 'text' || el.type === 'password') {
      dispatch({
        importFtpConfig: { ...state.importFtpConfig, [el.name]: el.value },
      });
    }
  };

  const checkFields = () => {
    const {
      userName,
      password,
      address,
      path,
      frequency,
    } = state.importFtpConfig;
    if (!userName || !password || !address || !path || !frequency) {
      showNotification({
        content: () => <p>Please fill all the required fields.</p>,
        className: 'notification-fail',
      });
      return false;
    }
    return true;
  };

  const checkConnection = async isSave => {
    isLoading();
    let isConn;
    const { userName, password, address } = state.importFtpConfig;
    if (!userName || !password || !address) {
      isConn = false;
      showNotification({
        content: () => (
          <p>Please fill the Address, Username and Password fields.</p>
        ),
        className: 'notification-fail',
      });
      isLoaded();
    } else {
      await FtpService('contacts/ftpConn')
        .create({
          body: {
            userName: state.importFtpConfig.userName,
            password: state.importFtpConfig.password,
            address: state.importFtpConfig.address,
          },
        })
        .then(res => {
          if (!isSave) {
            showNotification({
              content: () => <p>{res}</p>,
              className: 'notification-success',
            });
          }
          isConn = true;
        })
        .catch(err => {
          let newErr;
          if (err.response.error === undefined) newErr = err.response;
          else newErr = err.response.error;
          showNotification({
            content: () => <p>{newErr}</p>,
            className: 'notification-fail',
          });
          isConn = false;
        })
        .finally(() => isLoaded());
    }
    return isConn;
  };
  const handleSaveImport = async () => {
    const validation = checkFields();
    const isConn = await checkConnection(true);
    isLoading();
    if (validation && isConn) {
      FtpService('contacts/ftpUpdate')
        .update({ id: null, body: state.importFtpConfig })
        .then(res => {
          showNotification({
            content: () => <p>{res}</p>,
            className: 'notification-success',
          });
        })
        .catch(err => {
          showNotification({
            content: () => <p>{err.response.error}</p>,
            className: 'notification-fail',
          });
        })
        .finally(() => isLoaded());
    } else {
      isLoaded();
    }
  };

  return (
    <div className='tabs'>
      <div className='tabs-container'>
        <div className='tabs-container__card'>
          <SFYSwitch
            span='Passive / Active'
            isToggled={state.importFtpConfig.enabled}
            onToggled={() =>
              dispatch({
                importFtpConfig: {
                  ...state.importFtpConfig,
                  enabled: !state.importFtpConfig.enabled,
                },
              })
            }
            className='tabs-container__card-switch'
          />
          <h3 className='tabs-container__card-title'>{t('Import From FTP')}</h3>
          {inputConfiguration.importFtpConfig.map(
            ({
              order,
              type,
              stateName,
              name,
              className,
              placeHolder,
              required,
            }) => {
              return (
                <div key={order} className='tabs-container__card-row'>
                  <TextField
                    type={type}
                    name={stateName}
                    value={state.importFtpConfig[stateName]}
                    onChange={e => handleChange(e)}
                    label={name}
                    className={className}
                    placeHolder={placeHolder}
                    required={required}
                  />
                </div>
              );
            },
          )}
          <div className='tabs-container__card-row'>
            <Select
              label='Import Frequency *'
              name='frequency'
              className='item-stacked one-half'
              value={state.importFtpConfig.frequency}
              options={frequencyOptions}
              required
              onChange={e =>
                dispatch({
                  importFtpConfig: {
                    ...state.importFtpConfig,
                    frequency: e.value,
                  },
                })
              }
            />
            <div className='tabs-container__card-row__oneHalf'>
              <SFYSwitch
                span='Send to IYS'
                isToggled={state.importFtpConfig.sendToIys}
                onToggled={() =>
                  dispatch({
                    importFtpConfig: {
                      ...state.importFtpConfig,
                      sendToIys: !state.importFtpConfig.sendToIys,
                    },
                  })
                }
                className='tabs-container__card-row__oneHalf-switch'
              />
              <a onClick={() => checkConnection(false)}>
                {t('Test Connection')}
              </a>
            </div>
          </div>
          <div
            className='tabs-container__card-row'
            style={{ justifyContent: 'flex-end' }}
          >
            <button
              type='button'
              className='tabs-container__card-row-saveBTN'
              onClick={handleSaveImport}
            >
              {t('Save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportFTP;
