export const FACETED_STATUS = {
  LIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  PASSIVE: 'PASSIVE',
};

export const FACETED_STATUS_TEXT = {
  ACTIVE: 'live',
  DRAFT: 'draft',
  PASSIVE: 'passive',
};
