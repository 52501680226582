/**
 * @author Bilal Cinarli
 */

"use strict";
import { pageTypes } from "../../constants/datamaps/pages";

import { clone } from "../../system/object";

const getCampaignPage = instance => {
  let types = clone(pageTypes),
    page = "Custom Page";

  Object.keys(types).forEach(item => {
    if (
      types[item].templateId &&
      types[item].templateId.indexOf(instance.templateId) > -1
    ) {
      page = types[item].pageName;
      if (types[item].templateId === "SMSG_01") {
        page = "Trigger Based Page";
      }
      return false;
    } else {
      if(!instance.inputs) return false;
      instance.inputs.forEach(input => {
        if (
          types[item].inputs.names.indexOf(input.name) > -1 &&
          input.value &&
          input.value[0] === types[item].inputs.value
        ) {
          page = types[item].pageName;
          if (types[item].templateId && types[item].templateId === "SMSG_01") {
            page = "Trigger Based Page";
          }
          return false;
        }
      });
      if (
        instance.inputs[0] &&
        instance.inputs[0].value &&
        instance.inputs[0].value.length > 1
      ) {
        page = "Custom Page";
      }
    }
  });

  if (
    instance &&
    instance.actions &&
    instance.actions[0] &&
    instance.actions[0].params &&
    instance.actions[0].params.actionType === "POPUP_RECO"
  ) {
    page = "Popup Recommendation";
  }

  if (instance.custom) {
    page = "Custom Page";
  }

  return page;
};

const getCampaignsByPage = (campaigns, page) => {
  let matchedCampaigns = [];

  campaigns.forEach(campaign => {
    let campaignPage = getCampaignPage(campaign);

    if (
      campaignPage === page ||
      (campaignPage === "Custom Page" && page === "")
    ) {
      matchedCampaigns.push(campaign);
    }
  });

  return matchedCampaigns;
};

export { getCampaignPage, getCampaignsByPage };
