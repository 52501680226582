import React, { useState, useEffect, useRef } from 'react';
import CodeMirror from 'react-codemirror';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/lint/css-lint';
import 'codemirror/addon/lint/json-lint';
import jsonlint from 'jsonlint-mod';
import { defaultOptions, languages } from './constants';

/**
 * @name Snippet
 * @description Code editor component
 * @param {string} code - Code to show in the editor
 * @param {string} tab - Selected tab.
 * @param {function} onChange - Function to set the code
 * @param {object} optionsProp - Code editor options
 * @return {JSX.Element}
 * @constructor
 */
const Snippet = ({ code, tab, onChange, optionsProp = defaultOptions }) => {
  const codeMirrorRef = useRef(null);
  const [value, setValue] = useState(code);
  const [options, setOptions] = useState({ ...defaultOptions, ...optionsProp });

  useEffect(() => {
    const newOptions = { ...defaultOptions, ...optionsProp };
    newOptions.mode = languages[tab];
    newOptions.lint = true;
    window.jsonlint = jsonlint;
    newOptions.gutters = ['CodeMirror-lint-markers'];
    newOptions.extraKeys = { 'Ctrl-Space': 'autocomplete' };
    if (newOptions.mode === 'htmlmixed') newOptions.autoCloseTags = true;

    setOptions(newOptions);
  }, [optionsProp, tab]);

  useEffect(() => {
    setValue(code);
  }, [code]);

  useEffect(() => {
    codeMirrorRef.current.getCodeMirror().refresh();
  }, [value, options]);

  const handleChange = newCode => {
    setValue(newCode);
    onChange(newCode);
  };

  return (
    <CodeMirror
      ref={codeMirrorRef}
      value={value}
      onChange={handleChange}
      className='code-editor'
      options={options}
    />
  );
};

export default Snippet;
