import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Link } from 'react-router';
import { connect } from 'react-redux';

import { uiActions } from '../../actions';
import { t } from '../../system/ui';

const languages = [
  { label: 'EN', name: 'lang', value: 'en' },
  { label: 'DE', name: 'lang', value: 'de' },
];

class LoginHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedlanguage: this.props.language || 'en',
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (this.props.defaultLanguage) {
      this.onChange({ value: this.props.defaultLanguage });
    }
  }

  onChange(selectedOption) {
    if (selectedOption && selectedOption.value) {
      uiActions.updateLanguage(selectedOption.value);
      this.setState({
        selectedlanguage: selectedOption.value,
      });
    }
  }

  render() {
    return (
      <>
        <header className={this.props.isLoginV2 && 'loginV2-header'}>
          <div className='logo'>
            <a href={t('https://www.segmentify.com/')}>
              <h1 className='login-logo'>
                <span className='for-screenreader-only'>Segmentify</span>
              </h1>
            </a>
          </div>
          <nav>
            <ul className='nav__links'>
              {/* {this.props.bookButton && (
                <li className='book-demo'>
                  <a href={t('https://www.segmentify.com')}>
                    {t('BOOK A DEMO')}
                  </a>
                </li>
              )} */}
              {this.props.loginButton && (
                <li className='login'>
                  <Link to='/login'>{t('Login')}</Link>
                </li>
              )}
              {this.props.showLanguageOption && (
                <li>
                  <label className='language-select'>
                    <Select
                      name='type'
                      arrowRenderer={arrowRenderer}
                      clearable={false}
                      value={this.state.selectedlanguage}
                      onChange={this.onChange}
                      options={languages}
                      id='lang-select'
                    />
                  </label>
                </li>
              )}
            </ul>
          </nav>
        </header>
      </>
    );
  }
}

function arrowRenderer() {
  return <span className='Select-arrow' />;
}

const mapStateToProps = store => ({
  language: store.ui.language,
});

LoginHeader.defaultProps = {
  showLanguageOption: true,
  bookButton: true,
  loginButton: false,
  isLoginV2: false,
};

LoginHeader.propTypes = {
  defaultLanguage: PropTypes.oneOf(['en', 'de']),
  loginButton: PropTypes.bool,
  showLanguageOption: PropTypes.bool,
  bookButton: PropTypes.bool,
  isLoginV2: PropTypes.bool,
};

export default connect(mapStateToProps)(LoginHeader);
