const saveUsedEmails = emails => {
  const previousEmails = localStorage.getItem('previouslyUsedEmails') || '[]';
  const concatEmails = [...JSON.parse(previousEmails), ...emails];
  const unifiedEmails = [...new Set(concatEmails)];
  const emailsString = JSON.stringify(
    unifiedEmails.slice(Math.max(unifiedEmails.length - 5, 0)),
  );
  localStorage.setItem('previouslyUsedEmails', emailsString);
};

const removeUsedEmail = email => {
  const currentEmails = localStorage.getItem('previouslyUsedEmails');
  const parsedEmails = JSON.parse(currentEmails);
  const index = parsedEmails.indexOf(email);
  if (index >= 0) {
    parsedEmails.splice(index, 1);
  }
  const emailsString = JSON.stringify(parsedEmails);
  localStorage.setItem('previouslyUsedEmails', emailsString);
};

const getUsedEmails = () => {
  const emailsString = localStorage.getItem('previouslyUsedEmails');
  if (emailsString) {
    return JSON.parse(emailsString);
  }
  return [];
};

export { saveUsedEmails, getUsedEmails, removeUsedEmail };
