import React from 'react';
import PropTypes from 'prop-types';
import Filters from 'components/filters/filters';
import { getSingleReport } from 'actions/faceted-search';
import { connect } from 'react-redux';
import moment from 'moment';
import { SearchReportRow } from '../../../../views/searchreportrow';
import { t } from 'system/ui';

class SingleFacetedSearchReport extends React.Component {
  componentDidMount() {
    const { params, dispatch, instanceId } = this.props;
    const date = this.getStartAndEndDate(this.props);
    const INSTANCE_ID = instanceId || params?.instanceId;
    dispatch(
      getSingleReport([INSTANCE_ID], date.start, date.end, response => null),
    );
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { date, instanceId, params } = nextProps;
    const INSTANCE_ID = instanceId || params?.instanceId;
    const areRangesEqual =
      JSON.stringify(this.props.date.range) === JSON.stringify(date.range);
    if (!areRangesEqual) {
      const selectedDate = this.getStartAndEndDate(nextProps);
      this.props.dispatch(
        getSingleReport(
          INSTANCE_ID,
          selectedDate.start,
          selectedDate.end,
          response => null,
        ),
      );
    }
  }

  getStartAndEndDate(props) {
    const { date } = props;
    return {
      start: moment(date.range[0]).format('YYYY-MM-DD'),
      end: moment(date.range[1]).format('YYYY-MM-DD'),
    };
  }

  render() {
    const { data, loaded, hideFilters, title, instanceId, params } = this.props;
    return (
      <div>
        {!hideFilters && <Filters />}
        {loaded && (
          <SearchReportRow
            type='faceted'
            data={data}
            loaded={loaded}
            hideFilters
            title={title}
            instanceId={instanceId || params?.instanceId}
            subtitle={t('faceted-report-subtitle')}
          />
        )}
      </div>
    );
  }
}

SingleFacetedSearchReport.defaultProps = {
  instanceId: null,
  hideFilters: false,
  title: 'Summary',
};

SingleFacetedSearchReport.propTypes = {
  title: PropTypes.string,
  hideFilters: PropTypes.bool,
  instanceId: PropTypes.string,
  params: PropTypes.shape({
    instanceId: PropTypes.string.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  date: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  loading: state.facetedSearch.singleReport.loading,
  loaded: state.facetedSearch.singleReport.loaded,
  data: state.facetedSearch.singleReport.data,
  date: state.date,
});

export default connect(mapStateToProps)(SingleFacetedSearchReport);
