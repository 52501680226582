import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Icons from '../../../icons';
import { getFilterOptions } from '../../../../modules/shop-the-look/ajax';
import { decodeHtml } from '../../../../system/string';

const RPLabels = ({ labels, setAttributes, onRemove }) => {
  const [labelOptions, setLabelOptions] = useState([]);

  useEffect(() => {
    getFilterOptions('label').then(res => {
      const sorted = res.sort();
      const fetchedLabels = [];
      sorted.forEach(sortedLabel => {
        fetchedLabels.push({
          value: sortedLabel,
          label: decodeHtml(sortedLabel),
        });
      });

      setLabelOptions(fetchedLabels);
    });
  }, []);

  return (
    <div className='sfy-attribute-field sfy-related-products'>
      <div className='sfy-attribute-field-icon'>
        <Icons name='excludeLabel' color='black' />
      </div>
      <div className='sfy-attribute-field-select'>
        <Select
          value={labels}
          options={labelOptions}
          onChange={selected => {
            setAttributes(selected, 'labels');
          }}
          multi
          placeholder='Select Label'
          className='sfy-attribute-field-select-input'
          clearable={false}
        />
      </div>
      <div
        className='sfy-attribute-field-remove'
        onClick={() => onRemove('labels')}
      >
        <Icons name='crossCircle' color='#D31116' height='18' width='18' />
      </div>
    </div>
  );
};

export default RPLabels;
