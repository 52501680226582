import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { confirmationDialog } from '../../actions/ui';
import Tooltip from '../tooltip';
import { addNewGroup, removeGroup } from '../../reducers/rbs';
import Icons from '../icons';

const AddRemoveGroup = ({ position, isAloneCriteria, hoverRef }) => {
  const dispatch = useDispatch();

  const addGroupAction = () => {
    dispatch(addNewGroup({ position }));
  };

  const removeGroupAction = () => {
    confirmationDialog({
      title: 'Remove the Criteria Group?',
      content: 'Are you sure you want to remove the criteria group you added?',
      onConfirm: () => {
        dispatch(removeGroup({ position }));
      },
    });
  };

  return (
    <div className='rbs-group-add-remove-button-field'>
      <div className='rbs-group-add-button' onClick={() => addGroupAction()}>
        <Icons name='plus' size='2x' />
        <span>New Criteria Group</span>
      </div>
      {!isAloneCriteria && (
        <Tooltip
          overlayClassName='rbs-group-remove-button-tooltip-overlay'
          className='rbs-group-remove-button-tooltip'
          content='Remove Criteria Group'
          alignment='left'
          newTooltip
        >
          <div
            ref={hoverRef}
            className='rbs-group-remove-button'
            onClick={() => removeGroupAction()}
          >
            <Icons name='trashBin' color='#CC4230' width={16} height={16} />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

AddRemoveGroup.propTypes = {
  position: PropTypes.shape([]),
  isAloneCriteria: PropTypes.bool.isRequired,
  hoverRef: PropTypes.shape({}).isRequired,
};

AddRemoveGroup.defaultProps = {
  position: [0, 0],
};

export default AddRemoveGroup;
