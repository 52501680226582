import { t } from '../../../../system/ui';

const breadCrumbOptions = {
  home: {
    name: t('Search & Discovery'),
    icon: 'search',
    svg: true,
    slug: '/search/view-all',
  },
  current: {
    name: 'Global Configuration',
  },
};

const boostAttributes = [
  {
    type: 'addComponent',
    name: 'brand',
    label: t('Brand'),
    component: 'RPBrands',
    icon: 'plus',
  },
  {
    type: 'addComponent',
    name: 'category',
    label: t('Category'),
    component: 'RPCategoryAccessor',
    icon: 'plus',
  },
  {
    type: 'urlRoute',
    name: 'urlRoute',
    label: t('Open Visual Builder'),
    url: {
      to: '/search/global-configuration/visual-builder',
      from: '/search/global-configuration',
      isActive: false,
      searchEndpoint: 'ruleset/pinWizardSearch',
      searchType: 'faceted',
    },
    icon: '',
    customStyle: { marginLeft: '0.7rem' },
  },
];

const productExclusionAttributes = [
  {
    type: 'showComponent',
    name: 'mappedProducts',
    label: t('Products'),
    component: 'RPProducts',
    icon: 'plus',
    isRemovable: true,
  },
  {
    type: 'showComponent',
    name: 'categories',
    label: t('Categories'),
    component: 'RPCategories',
    icon: 'plus',
  },
  {
    type: 'showComponent',
    name: 'brands',
    label: t('Brands'),
    component: 'RPBrands',
    icon: 'plus',
  },
  {
    type: 'showComponent',
    name: 'labels',
    label: t('Labels'),
    component: 'RPLabels',
    icon: 'plus',
  },
];

const productPinAttributes = [
  {
    type: 'showComponent',
    name: 'mappedProducts',
    label: t('Products'),
    component: 'RPProducts',
    icon: 'pinIcon',
    isDraggable: true,
    isRemovable: true,
  },
];

const searchResultsIndexAttributes = [
  {
    type: 'modal',
    name: 'addNewIndex',
    label: t('Add New'),
    modalDetails: {
      title: t('Add Search Index'),
      subtitle: t(
        'Add the index you want to promote rule on search result pages',
      ),
      className: 'search-results-index-add-item-popup',
      component: 'GCSearchResultsAddNew',
    },
    icon: 'plus',
  },
];

const addSearchIndexTypes = [
  {
    value: 'STRING',
    label: 'text exact match',
  },
  {
    value: 'SGM_STRING_NO_SENSITIVE',
    label: 'text no case sensitive',
  },
  {
    value: 'TEXT_GENERAL',
    label: 'free text',
  },
  {
    value: 'INT',
    label: 'integer',
  },
  {
    value: 'DOUBLE',
    label: 'float',
  },
];

export {
  addSearchIndexTypes,
  boostAttributes,
  breadCrumbOptions,
  productExclusionAttributes,
  productPinAttributes,
  searchResultsIndexAttributes,
};
