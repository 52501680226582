/**
 * Created by mehmetyurtar on 6/21/18.
 */
import React from "react";

class IconStar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "20px",
      height: "20px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 139 139"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
        fill={this.state.color}
      >
        <path d="M18.237,60.574l23.096,19.578l-7.183,29.438c-1.411,5.491,4.648,9.998,9.575,6.901L69.5,100.572l25.774,15.916  c4.79,2.955,10.844-1.408,9.576-6.902l-7.184-29.435l23.099-19.579c4.363-3.661,2.111-10.844-3.662-11.267l-30.282-2.255  L75.414,19.025c-2.112-5.211-9.577-5.211-11.832,0L52.175,47.051l-30.281,2.255C16.124,49.872,13.869,56.913,18.237,60.574z" />
      </svg>
    );
  }
}

export default IconStar;
