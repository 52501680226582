import React from 'react';

export const ACTIVE_LOYALS = () => {
  return (
    <div>
      <ul>
        These repeat customers are your <strong>loyal customers</strong> who
        have made multiple orders in a short period of time and their last order
        was very recent. These customers are like your{' '}
        <strong>brand ambassadors</strong>, it often pays to reward them with
        special offers if they spread the word about the brand to their friends,
        e.g., via social networks. You should focus on loyalty programs and
        personalized messaging to these customers to ensure their loyalty.
      </ul>
    </div>
  );
};

export const BIG_SPENDERS = () => {
  return (
    <div>
      <ul>
        These are the highest spending customers who made multiple purchases
        with <strong>high order values</strong>. Your goal is to increase the
        number of high spending customers and convert them into active loyal
        customers.
      </ul>
    </div>
  );
};

export const LOYALS_AT_RISK = () => {
  return (
    <div>
      <ul>
        These repeat customers are your loyal customers who have made multiple
        orders in a short period of time, but it has been a long time since
        their last order and they have become inactive and they are{' '}
        <strong>at risk</strong>. You should pay high attention not to lose
        these loyal customers by communicating with them again on different
        channels (email, push notifications, etc.) and give them free credits or
        discounts for their preferred products/categories to win back them for
        their next order.
      </ul>
    </div>
  );
};

export const RICHIE_RICHES = () => {
  return (
    <div>
      <ul>
        These are the elite customers who bought{' '}
        <strong>expensive products</strong> and are not afraid to pay high
        prices for the products they are searching for. Your goal is to help
        these customers to find the expensive products they are searching for
        and offer other high-end products as alternatives to make up-sell.
      </ul>
    </div>
  );
};

export const BULK_SHOPPERS = () => {
  return (
    <div>
      <ul>
        These are repeat customers who have ordered multiple times and love to
        buy <strong>multiple items of the same product</strong> in the basket.
        They are open to discounts/gifts and seeking opportunities to get
        multiple items of their favorite products. You should offer them bulk
        discounts (also called volume discounts) and communicate this message on
        both off-site channels (email, ads, push notifications, etc.) and
        on-site channels (bulk discount banners, last minute offers, and bundled
        offers).
      </ul>
    </div>
  );
};

export const SLEEPING_OPPORTUNISTS = () => {
  return (
    <div>
      <ul>
        These are the opportunist customers who like to use discounts in their
        orders and have not been ordering for a long time. Your goal is to win
        back these customers and they are{' '}
        <strong>sensitive to discount & coupon campaigns</strong>, the best way
        to reach them is by sending emails with discount codes and bulk
        discounts. If you can make the offer again you can also encourage them
        to make future orders with discount codes that can be used in the next
        order.
      </ul>
    </div>
  );
};

export const CHURN_CANDIDATES = () => {
  return (
    <div>
      <ul>
        You should aim for a churn rate that is as close to 0% as possible. In
        order to do this, you have to be on top of its churn rate at all times
        and treat it as a top priority. Our Churn Candidates segment’s customers
        are your old active loyals who have made multiple orders but it has been
        a long time since their last order. They have become inactive and if you
        don’t try to gain them, they are going to be <strong>churn</strong>.
      </ul>
    </div>
  );
};

export const ONE_TIME_SHOPPERS = () => {
  return (
    <div>
      <ul>
        These are your customers who shopped from your store once and then faded
        from the scene. But once a visitor becomes a customer there is a great
        chance that they will purchase again. Your main objective for this
        segment of customers should be to build brand loyalty and convert them
        into lifelong customers.
      </ul>
    </div>
  );
};

export const WAITING_FIRST_ORDER = () => {
  return (
    <div>
      <ul>
        These are your customers who registered to your store but did not make a
        purchase. They are still waiting to place their first order. It is best
        if you give a boost to these customers so they can come back and make
        their first steps into becoming your customers.
      </ul>
    </div>
  );
};

export const LOST_CUSTOMERS = () => {
  return (
    <div>
      <ul>
        How can an online store measure churn if customers never
        &apos;unsubscribe&apos;? We took a bold step to define churn for you by
        considering
        <strong>
          repeat purchase rate, repeat purchase cycle, and seasonality
        </strong>
        . Our analysis includes a different churn period for the individual
        customers and if the customer has passed the cut-off point (number of
        days after the customer will not return to make a new purchase) we
        marked them as <strong>lost (churned) customers</strong> and will not
        take into consideration for overall metrics. You should try one final
        shot to win-back churned customers with marketing. You can send them
        personal messages with titles like &quot;We missed you&quot; or &quot;It
        has been a while&quot;. You can also add
        <strong>discounts or gift codes</strong> to your mail to reactivate
        them. If you don&apos;t get any positive return from them, you should
        remove them from your marketing lists, and even from the customer base.
      </ul>
    </div>
  );
};
