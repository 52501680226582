export const locale = 'en-US';

export const dateFormat = 'DD/MM/YYYY';
export const timeFormat = 'HH:mm';
export const dateFilters = [
  // {name: 'All', filter: 'all'},
  // {name: 'This Year', filter: 'yearly'},
  { name: 'Last Month', filter: 'lastmonth' },
  { name: 'This Month', filter: 'monthly' },
  { name: 'Last 7 Days', filter: 'lastSevenDays' },
  { name: 'This Week', filter: 'weekly' },
  // {name: 'Yesterday', filter: 'yesterday'},
  { name: 'Today', filter: 'daily' },
];
export const dateFiltersWithYesterday = [
  { name: 'Last Month', filter: 'lastmonth' },
  { name: 'This Month', filter: 'monthly' },
  { name: 'Last 7 Days', filter: 'lastSevenDays' },
  { name: 'This Week', filter: 'weekly' },
  { name: 'Yesterday', filter: 'yesterday' },
  { name: 'Today', filter: 'daily' },
];

export const dateFiltersSearchInsights = [
  { name: 'Last Month', filter: 'lastmonth' },
  { name: 'This Month', filter: 'monthly' },
  { name: 'Last 7 Days', filter: 'lastSevenDays' },
  { name: 'This Week', filter: 'weekly' },
  { name: 'Yesterday', filter: 'yesterday' },
];

export const dateFiltersGoogleAnalytics = [
  { name: 'Last Month', filter: 'lastmonth' },
  { name: 'This Month', filter: 'gMonthly' },
  { name: 'Previous Week', filter: 'gPreviousWeek' },
  { name: 'This Week', filter: 'gWeekly' },
  { name: 'Last 7 Days', filter: 'gLastSevenDays' },
  { name: '2 Days Ago', filter: 'twoDaysAgo' },
];

export const dateFiltersMonitors = [
  { name: 'Days', filter: 'days' },
  { name: 'Weeks', filter: 'weeks' },
];

export const statTitles = {
  page: 'Page View',
  device: 'Device',
  unique: 'Audience-Website',
  purchase: 'Revenue (Overall)',
};

export const trendTitles = {
  widget: 'Widget',
  interaction: 'Interaction',
  basket: 'Basket',
  revenue: 'Revenue',
  assistedRevenue: 'Assisted Revenue',
  overallRevenue: 'Revenue Generated by Segmentify',
  overallContribution: "Segmentify's Revenue Contribution",
  overallPCount: 'Products Sold via Segmentify',
};

export const units = {
  page: 'view',
  device: 'view',
  widget: 'view',
  interaction: 'product_clicks',
  basket: 'items',
};

export const icons = {
  device: {
    pCRatio: 'pc',
    mobileRatio: 'mobile',
    tabletRatio: 'tablet',
    otherRatio: 'globe',
  },
};
