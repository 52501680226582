import React, { useContext, useEffect } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import { getStartEndDate } from '../../../system/date';
import Dropdown from '../../../components/sfy-components/Dropdown/Dropdown';
import { Select } from '../../../components/fields';

import Stats from './components/Stats';

import {
  isSuperiestUser,
  redirectUserToFirstPage,
} from '../../../modules/auth/user';
import { setTitle } from '../../../system/document';
import { dateActions, uiActions } from '../../../actions';
import {
  getAccounts,
  getMonitorAccounts,
  getUsers,
} from '../../../modules/account/ajax';

import styles from './Monitoring.module.scss';
import Filters from '../../../components/filters/filters';
import { t } from '../../../system/ui';
import { Context as MonitoringContext } from './store/MonitoringProvider';
import { isLoaded, isLoading } from '../../../actions/ui';

/**
 * @name Monitoring
 * @description Monitoring component that constructs monitoring page
 * @return {JSX.Element}
 * @constructor
 */

const Monitoring = () => {
  const {
    state: {
      users,
      selectableUsers,
      selectedUser,
      accounts,
      selectedAccounts,
      isMonitoringLoaded,
    },
    setUsers,
    setSelectableUsers,
    setSelectedUser,
    setAccounts,
    setSelectedAccounts,
    setIsMonitoringLoaded,
  } = useContext(MonitoringContext);

  const { rangeAlias } = useSelector(store => store.date);
  const { switchedUser } = useSelector(store => store.switchedUser);

  const onUserSelect = val => {
    setSelectedUser(val);
    setSelectedAccounts([]);
    setIsMonitoringLoaded(false);
  };

  const onAccountSelect = val => {
    setSelectedAccounts(val);
  };

  const onRemoveUser = () => {
    setSelectedUser('');
    setSelectedAccounts([]);
    setIsMonitoringLoaded(false);
  };

  const getMonitoring = () => {
    isLoading();
    const accountData = selectedAccounts.map(item => item.value);
    const boundaries = getStartEndDate(rangeAlias);

    const opts = {
      accountId: '',
      start: boundaries.start,
      end: boundaries.end,
      timeFrame: rangeAlias,
    };
    getMonitorAccounts(accountData, opts, response => {
      const updatedAccounts = [...selectedAccounts];
      response.forEach(stat => {
        let index;
        Object.keys(stat).forEach(acId => {
          index = updatedAccounts.findIndex(acc => acc.accountId === acId);
          updatedAccounts[index].stats = stat[acId];
          updatedAccounts[index].isClicked = false;
        });
      });
      setSelectedAccounts(updatedAccounts);
      setIsMonitoringLoaded(true);
      isLoaded();
    });
  };

  useEffect(() => {
    if (isMonitoringLoaded) getMonitoring();
  }, [rangeAlias]);

  useEffect(() => {
    setTitle('Monitoring');
    uiActions.resetPage();
    dateActions.updateRangeAlias('weeks');

    getUsers(response => {
      const accountUsers = [];
      const selectableAccountUsers = [];
      response.forEach(item => {
        if (
          item?.privileges?.includes('ROLE_SM') ||
          item?.privileges?.includes('ROLE_ACM')
        ) {
          selectableAccountUsers.push({
            name: 'user',
            value: item.displayName,
            label: item.displayName,
            ...item,
          });
        }

        accountUsers.push({
          ...item,
        });
      });

      setUsers(accountUsers);
      setSelectableUsers(selectableAccountUsers);
    });

    if (isSuperiestUser()) {
      getAccounts(null, response => {
        const allAccounts = response.filter(
          account =>
            account.features.recommendation.status === 'ACTIVE' ||
            account.features.search.status === 'ACTIVE' ||
            account.features.push.status === 'ACTIVE',
        );

        const processesAccounts = allAccounts.map(item => {
          return {
            value: item.accountId,
            label: `${item.accountId} - ${item.domain}`,
            ...item,
            stats: {},
            isClicked: false,
          };
        });

        setAccounts(processesAccounts);
      });
    } else {
      getAccounts(null, response => {
        const processesAccounts = response.map(item => {
          return {
            value: item.accountId,
            label: `${item.accountId} - ${item.domain}`,
            ...item,
            stats: {},
            isClicked: false,
          };
        });

        setAccounts(processesAccounts);
      });
    }
  }, []);

  useEffect(() => {
    if (switchedUser.displayName && switchedUser.displayName !== '') {
      redirectUserToFirstPage();
    }
  }, [switchedUser]);

  const getOptions = () => {
    if (selectedUser !== '') {
      const userAccount = users.find(item => item.displayName === selectedUser);
      if (selectedAccounts.length < 5) {
        return accounts.filter(
          item =>
            item.ownership?.salesManager === userAccount.username ||
            item.ownership?.accountManager === userAccount.username,
        );
      }
      return [];
    }
    return selectedAccounts.length < 5 ? accounts : selectedAccounts;
  };

  const accountsOptions = getOptions();

  return (
    <div className='accounts-vertical-result admin-stats admin-stats-page'>
      <ReactTooltip effect='solid' className='sg-tooltip' />
      <div className='account-filters account-filters-stats'>
        <Filters hidePicker isMonitor />
      </div>

      <h2 className={`page-title ${styles['page-title']}`}>
        {t('Monitoring')}
      </h2>

      <div className={styles['selection-wrapper']}>
        {isSuperiestUser() && (
          <div className={styles['selection-wrapper__user']}>
            <Dropdown
              value={selectedUser}
              options={selectableUsers}
              editCampaign={onUserSelect}
              placeholder='Select User'
              id='monitoring-user-select'
            />
            {selectedUser !== '' && (
              <span
                className={styles['selection-wrapper__user__remove-user']}
                onClick={() => onRemoveUser()}
              >
                X
              </span>
            )}
          </div>
        )}
        <div className={styles['selection-wrapper__account']}>
          <Select
            name='selectedAccounts'
            className={styles['selection-wrapper__account__select']}
            placeholder='Select Accounts...'
            values={selectedAccounts}
            onChange={val => onAccountSelect(val)}
            options={accountsOptions}
            value={selectedAccounts}
            multi
            searchable
          />
        </div>
        <div className={styles['selection-wrapper__submit']}>
          <button
            type='button'
            className={styles['selection-wrapper__submit__button']}
            onClick={() => getMonitoring()}
            disabled={!selectedAccounts.length}
          >
            Get Monitoring Data
          </button>
        </div>
      </div>
      <Stats />
    </div>
  );
};

export default Monitoring;
