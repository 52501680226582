import React from 'react';
import classNames from 'classnames';
import NewTooltip from 'rc-tooltip';
import { t } from '../system/ui';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      placement: 'top',
      alignment: 'center',
    };

    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
  }

  showTooltip() {
    this.setState({
      visible: true,
    });
  }

  hideTooltip() {
    this.setState({
      visible: false,
    });
  }

  render() {
    const type = this.props.type || 'text';
    const alignment = this.props.alignment || this.state.alignment;
    const placement = this.props.placement || this.state.placement;
    const tooltip = classNames('tooltip', this.props.className, {
      'tooltip-place-top': placement === 'top',
      'tooltip-place-right': placement === 'right',
      'tooltip-place-bottom': placement === 'bottom',
      'tooltip-place-left': placement === 'left',
      'tooltip-align-center': alignment === 'center',
      'tooltip-align-right': alignment === 'right',
      'tooltip-align-left': alignment === 'left',
    });
    const tooltipContent = classNames({
      'tooltip-content': true,
      'tooltip-content-image': type === 'image',
      'tooltip-sidebar-content': this.props.isSidebar,
      'tooltip-sidebar-content-reports': this.props.isSidebarReports,
      'is-hidden': !this.state.visible,
    });
    if (this.props.newTooltip) {
      return (
        <NewTooltip
          placement={alignment || 'top'}
          overlayClassName={this.props.overlayClassName}
          overlay={
            type === 'image' ? (
              <img
                src={this.props.content}
                style={{ maxHeight: '175px', maxWidth: '350px' }}
              />
            ) : (
              t(this.props.content)
            )
          }
          trigger={this.props.seeMore ? ['click'] : ['hover']}
          defaultVisible={this.props.defaultVisible}
          visible={this.state.visible}
        >
          <div
            className={this.props.className}
            onMouseOver={this.showTooltip}
            onMouseOut={this.hideTooltip}
            style={{
              cursor: this.props.seeMore ? 'pointer' : 'initial',
              display: this.props.isInline ? 'inline-block' : 'block',
            }}
          >
            {this.props.children}{' '}
            {this.props.seeMore && [
              <div style={{ display: 'block', color: '#28a6b5' }}>
                See More
              </div>,
            ]}
          </div>
        </NewTooltip>
      );
    }
    if (this.props.seeMore) {
      return (
        <span className={tooltip}>
          <span
            className='tooltip-trigger'
            onClick={this.showTooltip}
            onMouseOut={this.hideTooltip}
          >
            {this.props.children}
            <div
              style={{
                display: 'block',
                color: '#28a6b5',
                cursor: 'pointer',
              }}
            >
              See More
            </div>
          </span>
          <div
            className={tooltipContent}
            onMouseOver={this.showTooltip}
            onMouseOut={this.hideTooltip}
          >
            {type === 'image' ? (
              <img src={this.props.content} />
            ) : (
              t(this.props.content)
            )}
          </div>
        </span>
      );
    }
    return (
      <span className={tooltip}>
        <span
          className='tooltip-trigger'
          onMouseOver={this.showTooltip}
          onMouseOut={this.hideTooltip}
        >
          {this.props.children}
        </span>
        <div
          className={tooltipContent}
          onMouseOver={this.showTooltip}
          onMouseOut={this.hideTooltip}
        >
          {type === 'image' ? (
            <img src={this.props.content} />
          ) : (
            t(this.props.content)
          )}
        </div>
      </span>
    );
  }
}

export default Tooltip;
