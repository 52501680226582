/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from 'react';
import classNames from 'classnames';

import { t } from '../../system/ui';
import { getFormattedDate } from '../../system/date';
import { types } from '../../constants/datamaps/campaign';

import Icons from '../../components/icons';
import { getNow } from '../../system/date';

import CampaignDevices from './campaign-devices';
import CampaignActions from './campaign-actions';

const title = {
  test: 'Test Campaigns',
  active: 'Live Campaigns',
  SCHEDULED: 'Scheduled Campaigns',
  COMPLETED: 'Completed Campaigns',
};

class CampaignList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      isToggled: false,
      liveCampaignsCount: 0,
    };

    this.consolidateList = this.consolidateList.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.consolidateList(this.props.campaigns);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaigns !== newProps.campaigns) {
      this.consolidateList(newProps.campaigns);
    }
  }

  isScheduled(campaign) {
    let now = getNow().valueOf();
    return campaign.startDate && campaign.startDate > now;
  }

  isCompleted(campaign) {
    let now = getNow().valueOf();
    return campaign.endDate && campaign.endDate < now;
  }

  consolidateList(list) {
    let campaigns = [];
    let liveCampaignsCount = 0;
    list.forEach(campaign => {
      if (
        this.props.type &&
        (types[this.props.type].type === campaign.type ||
          (types[this.props.type].relatedTypes &&
            !!types[this.props.type].relatedTypes[campaign.type]))
      ) {
        if (campaign.status.toLowerCase() === this.props.status.toLowerCase()) {
          campaigns.push(campaign);
        }
        if (campaign.status.toLowerCase() === 'active') {
          liveCampaignsCount++;
        }
      }
    });
    if (this.props.dateFilter) {
      let campaignsFilteredByDate = [];
      if (this.props.dateFilter === 'SCHEDULED') {
        campaignsFilteredByDate = campaigns.filter(campaign => {
          return this.isScheduled(campaign);
        });
      } else {
        campaignsFilteredByDate = campaigns.filter(campaign => {
          return this.isCompleted(campaign);
        });
      }
      this.setState({
        campaigns: campaignsFilteredByDate,
        liveCampaignsCount: liveCampaignsCount,
      });
    } else {
      if (this.props.status.toLowerCase() === 'active') {
        campaigns = campaigns.filter(campaign => {
          return !this.isScheduled(campaign) && !this.isCompleted(campaign);
        });
      }
      this.setState({
        campaigns: campaigns,
        liveCampaignsCount: liveCampaignsCount,
      });
    }
  }

  toggle() {
    this.setState({ isToggled: !this.state.isToggled });
  }

  render() {
    const { idsInAB } = this.props;
    let willToggle = false;
    if (this.props.status === 'test') {
      willToggle = !this.state.liveCampaignsCount;
    } else {
      willToggle = this.props.open;
    }
    if (this.state.campaigns.length === 0) {
      return false;
    }

    return (
      <div
        className={classNames('widget-list-has-items', {
          'will-toggle': !willToggle,
          'is-toggled': this.state.isToggled,
        })}
      >
        <h3
          className={classNames('page-subtitle', {
            passive: this.props.status === 'test',
          })}
          onClick={this.toggle}
        >
          {this.props.dateFilter
            ? t(title[this.props.dateFilter])
            : t(title[this.props.status])}
          ({this.state.campaigns.length})
        </h3>
        <ul className='widget-list'>
          {this.state.campaigns.map(campaign => {
            const relatedType = types[this.props.type]?.relatedTypes
              ? types[this.props.type]?.relatedTypes[campaign.type]
              : null;
            const devices = campaign.devices.filter(device => {
              return !(
                device === 'TABLET' && this.props.type.includes('social-proof')
              );
            });

            if (this.props.newDesign) {
              return (
                <li
                  key={campaign.instanceId}
                  className='widget-list-item new-design'
                >
                  <div className='widget-list-item-content'>
                    <h2 className='widget-title'>{campaign.name}</h2>
                    <div className='widget-meta'>
                      <span className='widget-meta-date'>
                        {campaign.startDate ? (
                          <span className='widget-row-flexed'>
                            <Icons name='calendar' />
                            {getFormattedDate(campaign.startDate)}
                            {campaign.endDate && (
                              <>
                                <div className='date-separator' />
                                {getFormattedDate(campaign.endDate)}
                              </>
                            )}
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                      {this.props.type.indexOf('push-notification') === -1 ? (
                        <CampaignDevices devices={devices} />
                      ) : (
                        ''
                      )}
                      {this.props.type.includes('social-proof') && (
                        <span className='widget-campaign-type widget-row-flexed'>
                          {relatedType?.title}
                        </span>
                      )}
                    </div>
                  </div>
                  <CampaignActions
                    type={`${this.props.type}${
                      relatedType ? `/${relatedType?.pathType}` : ''
                    }`}
                    instanceId={campaign.instanceId}
                    onUpdate={this.props.onUpdate}
                    campaign={campaign}
                  />
                </li>
              );
            }
            const isInAB = idsInAB.find(id => campaign.instanceId === id);
            return (
              <li key={campaign.instanceId} className='widget-list-item'>
                <h2 className='widget-title'>{campaign.name}</h2>
                <div className='widget-meta'>
                  <span className='widget-meta-date'>
                    {campaign.startDate ? (
                      <span>
                        <Icons name='calendar' />
                        {getFormattedDate(campaign.startDate)}
                      </span>
                    ) : (
                      ''
                    )}
                  </span>
                  {this.props.type.indexOf('push-notification') === -1 ? (
                    <CampaignDevices devices={campaign.devices} />
                  ) : (
                    ''
                  )}
                  {isInAB && (
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginRight: '10px',
                        position: 'relative',
                        top: '2px',
                      }}
                    >
                      <Icons name='experimentation' />
                      <span style={{ marginLeft: '5px' }}>in an A/B test</span>
                    </span>
                  )}
                </div>
                <CampaignActions
                  type={`${this.props.type}${
                    relatedType ? `/${relatedType}` : ''
                  }`}
                  instanceId={campaign.instanceId}
                  onUpdate={this.props.onUpdate}
                  campaign={campaign}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default CampaignList;
