import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { filterActions, dateActions } from '../../actions';
import {
  dateFilters,
  dateFiltersWithYesterday,
  dateFiltersGoogleAnalytics,
  dateFiltersSearchInsights,
  dateFiltersMonitors,
} from '../../constants/ui';
import { getFirstDayOfWeek, getNow } from '../../system/date';
import { t } from '../../system/ui';
import { trackingEventNames, trackingService } from '../../trackingService';
import { RangePicker } from '../fields';

class DateFilters extends React.Component {
  constructor(props) {
    super(props);
    const { forceFirstSecondDate } = this.props;

    this.state = {
      firstStartDate:
        forceFirstSecondDate?.firstStartDate ?? getFirstDayOfWeek(),
      firstEndDate: forceFirstSecondDate?.firstEndDate ?? getNow(),
      secondStartDate:
        forceFirstSecondDate?.secondStartDate ??
        getFirstDayOfWeek().subtract(7, 'd'),
      secondEndDate:
        forceFirstSecondDate?.secondEndDate ?? getNow().subtract(7, 'd'),
      start: getNow().startOf('isoweek'),
      firstWeek: getNow()
        .subtract(1, 'weeks')
        .startOf('isoWeek'),
      secondWeek: getNow().startOf('isoweek'),
      end: getNow(),
      limitRange: 'months',
      query: document.location.search,
    };

    this.setRange = this.setRange.bind(this);
    this.changeDateMargin = this.changeDateMargin.bind(this);
    this.changeStartDate = this.changeStartDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
    this.changeDates = this.changeDates.bind(this);
    this.isValidStartDate = this.isValidStartDate.bind(this);
    this.isValidEndDate = this.isValidEndDate.bind(this);
    this.onPickerClose = this.onPickerClose.bind(this);
    this.changeDate = this.changeDate.bind(this);
    this.changeWeekDates = this.changeWeekDates.bind(this);
    this.changeFirstDates = this.changeFirstDates.bind(this);
    this.changeSecondDates = this.changeSecondDates.bind(this);
  }

  componentDidMount() {
    this.setRange();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.range !== newProps.range) {
      this.setState(
        {
          start: newProps.range[0],
          end: newProps.range[1],
          limitRange: newProps.limitRange,
          query: '',
        },
        () => {
          if (newProps.range[3] === null) {
            this.setState({
              end: newProps.range[0],
            });
          }
        },
      );
    }
  }

  setRange() {
    this.setState({
      start: this.props.range[0],
      end: this.props.range[1],
    });
  }

  forceFirstAndSecondToChange = () => {
    const { forceFirstSecondDate } = this.props;
    this.setState({
      firstStartDate:
        forceFirstSecondDate?.firstStartDate ?? getFirstDayOfWeek(),
      firstEndDate: forceFirstSecondDate?.firstEndDate ?? getNow(),
      secondStartDate:
        forceFirstSecondDate?.secondStartDate ??
        getFirstDayOfWeek().subtract(7, 'd'),
      secondEndDate:
        forceFirstSecondDate?.secondEndDate ?? getNow().subtract(7, 'd'),
    });
  };

  changeDateMargin(filter) {
    const newMargin = filter;
    if (this.props.ui === 'isLoading') {
      return false;
    }

    trackingService.sendPageView(
      `${trackingEventNames.PAGE_VIEW}: ${
        browserHistory.getCurrentLocation().pathname
      }`,
    );

    filterActions.showButtons();

    dateActions.updateRangeAlias(newMargin);
    filterActions.updateFilters();
  }

  changeStartDate(datetime) {
    if (this.props.ui === 'isLoading') {
      return false;
    }

    filterActions.showButtons();

    this.setState({
      start: datetime,
      query: '',
    });

    dateActions.updateRange([datetime, this.props.range[1]]);
  }

  changeDates(newDates) {
    this.setState({
      start: newDates.startDate,
      end: newDates.endDate,
      query: '',
    });

    dateActions.updateRangeAlias('custom');
    dateActions.updateRange([newDates.startDate, newDates.endDate]);
  }

  changeWeekDates(dates) {
    const datePickers = [
      {
        startOfWeek: dates[0].clone().startOf('week'),
        endOfWeek: dates[0].clone().endOf('week'),
        uniqueEl:
          this.refs.firstWeek &&
          this.refs.firstWeek.querySelector('.DateInput__display-text'),
      },
    ];
    if (dates[1]) {
      datePickers.push({
        startOfWeek: dates[1].clone().startOf('week'),
        endOfWeek: dates[1].clone().endOf('week'),
        uniqueEl:
          this.refs.secondWeek &&
          this.refs.secondWeek.querySelector('.DateInput__display-text'),
      });
    }
    datePickers.forEach(picker => {
      if (picker.uniqueEl) {
        picker.uniqueEl.innerHTML = `${picker.startOfWeek.format(
          'DD/MM/YYYY',
        )} - ${picker.endOfWeek.format('DD/MM/YYYY')}`;
      }
    });
  }

  changeDate(type, date) {
    const stateKey = type === 'firstWeek' ? 'start' : 'end';
    this.setState({ [stateKey]: date, query: '' }, () => {
      const startOfWeek = date.clone().startOf('week');
      const endOfWeek = date.clone().endOf('week');
      const dateFormat = date.localeData().longDateFormat('L');
      const uniqueEl = this.refs[type].querySelector(
        '.DateInput__display-text',
      );
      uniqueEl.innerHTML = `${startOfWeek.format(
        'DD/MM/YYYY',
      )} - ${endOfWeek.format('DD/MM/YYYY')}`;
      if (this.state.start > this.state.end) {
        dateActions.updateRange([this.state.end, this.state.start]);
      } else if (this.state.start < this.state.end) {
        dateActions.updateRange([this.state.start, this.state.end]);
      } else {
        dateActions.updateRange([this.state.start, this.state.end]);
      }
    });
  }

  onPickerClose(dates) {
    if (!dates.endDate) {
      this.setState({ end: moment() });
      dateActions.updateRange([dates.startDate, moment()]);
    }
  }

  changeEndDate(datetime) {
    if (this.props.ui === 'isLoading') {
      return false;
    }
    filterActions.showButtons();

    this.setState({
      end: datetime,
      query: '',
    });

    dateActions.updateRange([this.props.range[0], datetime]);
  }

  isValidStartDate(current) {
    if (this.props.limitRange) {
      const limitStart = moment(this.state.end).add(-1, this.state.limitRange);
      const limitEnd = moment(this.state.end);

      return (
        current.isBetween(limitStart, limitEnd, null, '[]') &&
        current.isSameOrBefore(getNow())
      );
    }
    return current.isSameOrBefore(getNow());
  }

  isValidEndDate(current) {
    if (this.props.limitRange) {
      const limitStart = moment(this.state.start);
      const limitEnd = moment(this.state.start).add(1, this.state.limitRange);

      return (
        current.isBetween(limitStart, limitEnd, null, '[]') &&
        current.isSameOrBefore(getNow()) &&
        current.isSameOrAfter(this.state.start)
      );
    }
    return current.isSameOrBefore(getNow());
  }

  changeFirstDates(newDates) {
    this.setState(
      {
        firstStartDate: newDates.startDate,
        firstEndDate:
          newDates.endDate !== null ? newDates.endDate : newDates.startDate,
        query: '',
      },
      () => {
        dateActions.updateRange([
          newDates.startDate,
          newDates.endDate !== null ? newDates.endDate : newDates.startDate,
          this.state.secondStartDate,
          this.state.secondEndDate,
        ]);

        dateActions.updateRangeAlias('custom');
      },
    );
  }

  changeSecondDates(newDates) {
    this.setState(
      {
        secondStartDate: newDates.startDate,
        secondEndDate:
          newDates.endDate !== null ? newDates.endDate : newDates.startDate,
        query: '',
      },
      () => {
        dateActions.updateRange([
          this.state.firstStartDate,
          this.state.firstEndDate,
          newDates.startDate,
          newDates.endDate !== null ? newDates.endDate : newDates.startDate,
        ]);

        dateActions.updateRangeAlias('custom');
      },
    );
  }

  render() {
    const hideDateAlias = this.props.hideDateAlias || [];
    const hidePicker = this.props.hidePicker || false;
    const hideDateAliases = this.props.hideDateAliases || false;
    let compRange = '';
    let showPeriod = true;
    if (!hidePicker) {
      if (this.props.includedFields) {
        if (this.props.includedFields.indexOf('date') > -1) {
          compRange = [
            <RangePicker
              startDate={this.state.secondStartDate}
              showUpdateButton={this.props.showUpdateButton}
              endDate={
                this.state.secondEndDate !== null
                  ? this.state.secondEndDate
                  : this.state.secondStartDate
              }
              onDatesChange={this.changeSecondDates}
              onPickerClose={this.onPickerClose}
            />,
            <RangePicker
              startDate={this.state.firstStartDate}
              showUpdateButton={this.props.showUpdateButton}
              endDate={
                this.state.firstEndDate !== null
                  ? this.state.firstEndDate
                  : this.state.firstStartDate
              }
              onDatesChange={this.changeFirstDates}
              onPickerClose={this.onPickerClose}
            />,
          ];
        }
        if (this.props.includedFields.indexOf('dateRange') > -1) {
          compRange = (
            <RangePicker
              startDate={this.state.start}
              showUpdateButton={this.props.showUpdateButton}
              endDate={this.state.end}
              onDatesChange={this.changeDates}
              onPickerClose={this.onPickerClose}
            />
          );
        }
      } else {
        compRange = (
          <RangePicker
            startDate={
              !(this.state.query === '?archive') ? this.state.start : ''
            }
            showUpdateButton={this.props.showUpdateButton}
            endDate={!(this.state.query === '?archive') ? this.state.end : ''}
            onDatesChange={this.changeDates}
            onPickerClose={this.onPickerClose}
          />
        );
      }
    }
    if (
      this.props.includedFields &&
      this.props.includedFields.indexOf('period') < 0
    ) {
      showPeriod = false;
    }
    let visibleDateFilters = this.props.showYesterday
      ? dateFiltersWithYesterday
      : dateFilters;

    if (this.props.isGoogleAnalytics)
      visibleDateFilters = dateFiltersGoogleAnalytics;

    if (this.props.isSearchInsights)
      visibleDateFilters = dateFiltersSearchInsights;

    if (this.props.isMonitor) {
      visibleDateFilters = dateFiltersMonitors;
    }

    return (
      <div className='date-filters group'>
        {compRange}
        {showPeriod &&
          visibleDateFilters
            .filter(filter => {
              if (hideDateAliases) {
                return false;
              }
              if (hideDateAlias.indexOf(filter.filter) > -1) {
                return false;
              }
              if (filter.filter === 'gWeekly' && this.props.isGoogleAnalytics) {
                const currentDay = getNow()
                  .format('dddd')
                  .toLowerCase();
                if (currentDay.includes('mon') || currentDay.includes('tue'))
                  return false;
              }
              return true;
            })
            .map(filter => (
              <a
                key={filter.filter}
                className={classNames('date-filter', {
                  current:
                    this.props.rangeAlias === filter.filter &&
                    this.props.custom === false &&
                    this.state.query === '',
                })}
                onClick={this.changeDateMargin.bind(null, filter.filter)}
              >
                {t(filter.name)}
              </a>
            ))}
      </div>
    );
  }
}

const mapStatesToProps = (store, ownProps) => ({
  custom: store.date.custom,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  ui: store.ui.ui,
  forceFirstSecondDate: ownProps?.forceFirstSecondDate,
});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
  DateFilters,
);
