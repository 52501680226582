import React, { useEffect } from 'react';
import { t } from '../../../system/ui';
import { Select } from '../../fields';

const WeeklyReport = ({
  account,
  userMailList,
  selectedUserMails,
  reducerStateChangeHandler,
}) => {
  useEffect(() => {
    reducerStateChangeHandler('weeklyReportMails', account.weeklyReportMails);
  }, []);

  const mailList = userMailList.map(item => {
    return { value: item, label: item };
  });

  const selectedUsers = selectedUserMails.map(item => {
    return { value: item, label: item };
  });

  const handleMailListUpdate = listItem => {
    const tempUserMailList = [...selectedUsers];
    const listItems = listItem.map(({ value }) => value);

    const controlItem = listItems.find(item =>
      tempUserMailList.includes(item.value),
    );
    if (controlItem !== undefined) {
      return;
    }

    reducerStateChangeHandler('weeklyReportMails', listItems);
  };

  return (
    <li>
      <h3 className='section-title'>{t('Weekly Report')}</h3>
      <div className='row module-segment'>
        <div className='widget-stats'>
          <div className='widget-stat-segmentation  stat-segmentation' />

          <div className='widget-stat-segmentation  stat-segmentation'>
            <p className='widget-stat-conversion-account'>
              <b>{t('Responsible')}</b>
            </p>
          </div>
        </div>
        <Select
          name='weeklyReport'
          className='item-stacked eleven-columns'
          label='Weekly Report'
          value={selectedUsers}
          options={mailList}
          onChange={handleMailListUpdate}
          multi
          searchable
        />
      </div>
    </li>
  );
};

export default WeeklyReport;
