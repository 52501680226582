import React from 'react';
import classes from './Loader.module.scss';

const Loader = () => {
  return (
    <div className={classes['loader-overlay']}>
      <div className={classes['loader-container']}>
        <img
          src='https://www.segmentify.com/wp-content/uploads/2020/09/favicon.png'
          alt=''
        />
      </div>
    </div>
  );
};

export default Loader;
