import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { operatorObjToArray } from './utils/utils';
import {
  pageOperators,
  operatorColumns,
} from '../../constants/datamaps/rule-based-segmentation';
import { TextField } from '../fields';

const criteriaFilterMail = ({
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const [selectedOperator, setSelectedOperator] = useState('');

  const defaultFilterData = {
    dataType: criteriaData?.dataType,
    column: operatorColumns.emailSubscription,
    operator: pageOperators.equals.value,
    value: 'true',
  };

  const triggerCallbackFunc = (val, type) => {
    const columnVal = operatorColumns.emailSubscription;
    const operatorVal =
      type === 'operator' ? val?.value : selectedOperator.value;
    const initialOperator =
      type === 'initial' ? defaultFilterData.operator : '';
    const valueVal = 'true';
    const filterData = [
      {
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator: type === 'operator' ? operatorVal : initialOperator,
        value: valueVal,
      },
    ];
    callbackFunc(filterType, filterData);
  };

  const onChange = (selectedValue, type) => {
    switch (type) {
      case 'operator':
        setSelectedOperator(selectedValue?.value);
        break;
      default:
        break;
    }

    triggerCallbackFunc(selectedValue, type);
  };

  useEffect(() => {
    const tempStatements = statements?.[filterType];
    if (tempStatements?.length === 0) {
      setSelectedOperator(defaultFilterData.operator);
      triggerCallbackFunc(defaultFilterData, 'initial');
    } else if (tempStatements?.length === 1) {
      setSelectedOperator(tempStatements[0].operator);
    } else {
      setSelectedOperator(defaultFilterData.operator);
    }
  }, [statements]);

  return (
    <div className='rbs-filter-field'>
      <div className='filter-operator'>
        <span className='filter-operator-title'>Operator*</span>
        <Select
          value={selectedOperator}
          options={operatorObjToArray(pageOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          onChange={e => onChange(e, 'operator')}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      <div className='filter-connector' />
      <div className='filter-operator'>
        <span className='filter-operator-title'>Email</span>
        <TextField
          disabled
          type='text'
          placeholder='Email Subscription'
          inputClassName='borderContour'
          className='lightGrey'
        />
      </div>
    </div>
  );
};

export default criteriaFilterMail;
