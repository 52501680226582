import React from 'react';
import { fixedPxValue } from '../customer-profile/utils';

const SortUp = ({ width = '9', height = '7', color = 'white' }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.949296 6.55L4.5 0.4L8.0507 6.55L0.949296 6.55Z'
        fill={color}
        stroke='#656565'
        strokeWidth='0.4'
      />
    </svg>
  );
};

export default SortUp;
