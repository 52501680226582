/**
 * Created by mehmetyurtar on 11/07/2017.
 */
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { TextField } from "../../../components/fields";
import Icon from "../../../components/icon";
import Icons from "../../../components/icons";

import { timeFrame, items } from "../../../constants/datamaps/wizard";

import { t } from "../../../system/ui";

import { getBrands } from "../../../modules/account/ajax";

class FormFieldRadio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groupTitle: "",
      required: false,
      options: []
    };

    this.onChange = this.onChange.bind(this);
    this.onCheckboxRadioChange = this.onCheckboxRadioChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.updateValues = this.updateValues.bind(this);
  }

  componentDidMount() {
    if (this.props.values.groupTitle) {
      this.updateValues({
        groupTitle: this.props.values.groupTitle.value,
        required: this.props.values.required.value,
        options: this.props.options
      });
    }
  }

  updateValue(changed, newValue) {
    let currentState = this.state;
    currentState[changed] = newValue;
    this.setState({
      groupTitle: currentState.groupTitle,
      required: currentState.required,
      options: currentState.options
    });
  }

  updateValues(newValues) {
    this.setState({
      groupTitle: newValues.groupTitle,
      required: newValues.required,
      options: newValues.options
    });
  }

  onChange(e) {
    let newVal = e.target.value;
    if (e.target.name === "required") {
      newVal = e.target.checked;
    }
    this.updateValue(e.target.name, newVal);
    this.props.onFormFieldChange(this.refs.inputs, this.props.id);
  }

  onCheckboxRadioChange(fieldId, optionId, optionIndex, event) {
    let currentStateOptions = this.state.options;
    currentStateOptions[optionIndex] = {
      name: event.target.value,
      id: optionId
    };
    this.updateValue("options", currentStateOptions);
    this.props.onCheckboxRadioChange(this.refs.formOptions, fieldId, optionId);
  }

  render() {
    return (
      <li className="wizard-criterion" ref="inputs">
        <span className="criteria-type">
          <Icons name="fieldRadio" color="#7a7a7c" />
        </span>
        <TextField
          name="groupTitle"
          value={this.state.groupTitle}
          className="item-field criteria-name wizard-params field-checkbox-name"
          inputClassName="one-whole criteria-field"
          placeholder="Radio Buttons Title"
          required={false}
          onChange={this.onChange}
        />
        <label className="item item-field is-checkbox field-input-required">
          <input
            type="checkbox"
            name="required"
            checked={this.state.required}
            onChange={this.onChange}
            className="criteria-field"
          />
          <span className="item-label"> {t("Required")} </span>
        </label>

        <a
          onClick={this.props.onCheckboxRadioAdd.bind(null, this.props.id)}
          className="tertiary-action"
        >
          <Icon name="plus" />
        </a>
        <a
          onClick={this.props.onFormFieldRemove.bind(null, this.props.id)}
          className="cancel-action engagement-form-cancel-action"
        >
          <Icon name="cross" />
        </a>

        <div
          style={{ marginLeft: "30px", marginTop: "20px" }}
          ref="formOptions"
        >
          {this.props.options.map((option, optionIndex) => {
            return (
              <li key={option.id} className="field-option-container">
                <TextField
                  name="parameter"
                  value={option.name}
                  className="item-field criteria-name field-checkbox-option"
                  inputClassName="one-whole option-field"
                  placeholder="Param"
                  required={false}
                  onChange={event =>
                    this.onCheckboxRadioChange(
                      this.props.id,
                      option.id,
                      optionIndex,
                      event
                    )
                  }
                />
                <a
                  onClick={this.props.onCheckboxRadioRemove.bind(
                    null,
                    this.props.id,
                    option.id
                  )}
                  className="cancel-action engagement-form-cancel-action"
                >
                  <Icon name="cross" />
                </a>
              </li>
            );
          })}
        </div>
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  criteria: store.wizard.criteria,
  formFields: store.wizard.formFields
});

export default connect(mapStatesToProps)(FormFieldRadio);
