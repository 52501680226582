/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";

import * as classes from "../../constants/ui.classes";

import { t } from "../../system/ui";

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: {
        default: classes.defaultButton,
        primary: classes.primaryAction,
        secondary: classes.secondaryAction,
        tertiary: classes.tertiaryAction
      }
    };
  }

  render() {
    let animation = "";

    if (this.props.attr.submitAnimation && this.props.ui === "isSubmitting") {
      animation = classes.buttonProgressAnimation;
    }

    return (
      <button
        className={this.state.types[this.props.attr.type] + " " + animation}
      >
        {t(this.props.attr.label)}
      </button>
    );
  }
}

export default Button;
