import React, { useState, useEffect } from 'react';
import { RectangleRadioButtonGroup } from '../../../../components/sfy-components/module-exports';
import './uploadModal.scss';

import { scratchTypes, wofTypes } from '../constants/constants';

/**
 * @name WofTypeModal
 * @description Modal to select the type of wof campaign
 * @param {function} onChange
 * @param {string} type - campaignType
 * @return {JSX.Element}
 */
const WofTypeModal = ({ onChange, type }) => {
  const [wofType, setWofType] = useState('');

  useEffect(() => {
    onChange(wofType);
  }, [wofType]);

  return (
    <div className='bundle-type-modal'>
      <RectangleRadioButtonGroup
        radioButtons={type === 'WHEEL_OF_FORTUNE' ? wofTypes : scratchTypes}
        selected={wofType}
        onChange={setWofType}
      />
    </div>
  );
};

export default WofTypeModal;
