import React from 'react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '../../../components/fields';
import { filterOptions } from './constants';
import classes from '../product-search/SearchProductForm.module.scss';

export const SearchUserForm = ({ onSubmit }) => {
  const { handleSubmit, watch, control, register } = useForm({});
  const { actions, form, checkbox } = classes;

  const show = watch('showFilter', false);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={form}>
      <Controller
        name='userId'
        control={control}
        render={({ field }) => (
          <TextField required type='text' label='User ID' {...field} />
        )}
      />
      <div className={checkbox}>
        <label htmlFor='showFilter'>
          <input type='checkbox' {...register('showFilter')} />
          <span>Show Filter</span>
        </label>
        {show && (
          <label htmlFor='sortResults'>
            <input type='checkbox' {...register('sortResults')} />
            <span>Sort Results</span>
          </label>
        )}
      </div>
      {show && (
        <Controller
          name='userFilter'
          control={control}
          render={({ field }) => (
            <Select
              multi
              clearable
              searchable
              placeholder='Create Your Own User Schema'
              options={filterOptions}
              {...field}
            />
          )}
        />
      )}
      <div className={actions}>
        <button type='submit'>Submit</button>
      </div>
    </form>
  );
};
