import { userTypes } from '../constants';

const setUpUserType = userType => {
  const compareUserTypes = userTypes.map(type => {
    return userType.includes(type);
  });

  if (compareUserTypes.includes(true)) {
    return userTypes[compareUserTypes.indexOf(true)];
  }
  return 'Account User';
};

export default setUpUserType;
