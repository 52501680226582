import React from 'react';
import { monitoringAlertTable } from '../../../../constants/datamaps/account';
import Icon from '../../../../components/icon';
import { t } from '../../../../system/ui';
import styles from './AlertTableHead.module.scss';

const AlertTableHead = () => {
  return (
    <thead>
      <tr>
        {monitoringAlertTable.map((item, index) => {
          let { text } = item;

          if (item.icon) {
            text = <Icon name={item.icon} />;
          }

          if (item.tooltip) {
            text = (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span data-tip={item.tooltip}>{text}</span>
              </div>
            );
          }

          return (
            // eslint-disable-next-line react/no-array-index-key
            <th key={index} className={styles['table-head']}>
              {t(text)}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default AlertTableHead;
