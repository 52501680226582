/*eslint react/display-name: "off"*/

"use strict";

import React from "react";

export const external_en = {
  "Segmentify Performance": "Personalization Performance",
  NoActiveCategoryCampaigns: () => {
    return (
      <div>
        <p>
          Category pages are landing so much visitors but bounce rates in
          e-commerce from category pages are also so high. A solution provided
          by Personalization for high bounce rates from category pages is using
          “Trending in Category” product recommendation widgets and include the
          popular products in category selected based on the behaviour of the
          current visitor.
        </p>
        <p>
          Please select a widget type below to{" "}
          <strong>add new category page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveSearchCampaigns: () => {
    return (
      <div>
        <p>
          Visitors became frustrated when they can't finding no product when
          they searched keywords on online stores, and bounce rates on search
          pages are much higher than other pages. With Personalization, you can offer
          variety of products inside widgets on search result page to decrease
          bounce rates and gaining back leaving visitors.
        </p>
        <p>
          Please select a widget type below to{" "}
          <strong>add new search page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActive404Campaigns: () => {
    return (
      <div>
        <p>
          When you removed a url from your store, this pages actually doesn't
          remove from search engines and other external links. When visitors
          clicks this url, they will face a 404 page without no navigation, and
          they will definitely bounce from this page. With Personalization, you can
          offer variety of products inside widgets on 404 page to decrease
          bounce rates and gaining back leaving visitors.
        </p>
        <p>
          Please select a widget type below to{" "}
          <strong>add new 404 page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveCustomCampaigns: () => {
    return (
      <div>
        <p>
          When you need a campaign on a page that are not predefined in
          Personalization, you can define custom page campaigns like user account
          page, checkout page etc.
        </p>
        <p>
          Please select a widget type below to{" "}
          <strong>add new custom page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  Segmentify: "Personalization",
  "Number Of Clicks By Segmentify Recommendation Widgets":
    "Number of clicks By Personalization Recommendation Widgets",
  "Number Of Add To Basket Operations By Segmentify Recommendation Widgets":
    "Number Of Add To Basket Operations By Personalization Recommendation Widgets",
  "Number Of Purchases By Segmentify Recommendation Widgets":
    "Number Of Purchases By Personalization Recommendation Widgets",
  "Amount Of Purchases By Segmentify Recommendation Widgets":
    "Amount Of Purchases By Personalization Recommendation Widgets",
  "Customer Removal Tool Disclaimer": () => {
    return (
      <p>
        All historical personal information related to given email addresses
        will be deleted within the next 24 hours. When the removal process is
        finished, you will receive an email.
        <br />
        Please note that the removal process is permanent and irreversible, upon
        its completion Personalization can't recover any data.
      </p>
    );
  },
  "Let's Integrate Segmentify": "Let's Integrate Personalization",
  "integration-script-info":
    "It is super easy to start smart recommendations on your web site with Personalization. All you need to do is adding the following line of JS code to all your pages to the very end of your HEAD block.",
  IntegrationZendDesk: () => {
    return (
      <span>
        You might follow the steps in{" "}
        <a href="https://segmentify.zendesk.com/hc/en-us" target="_blank">
          Personalization Customer success Center
        </a>{" "}
        for integration.
      </span>
    );
  },
  StartChat: () => {
    return (
      <span>
        Personalization Customer Success team is ready to do the integration for you,{" "}
        <a>start a chat</a>
      </span>
    );
  },
  "Choose what type recommendation features you want to use from Segmentify":
    "Choose what type recommendation features you want to use from Personalization",
  Personalization: "Engagement",
  PrivacyPolicyCta:
    "Do you need to adjust your cookie and privacy policy for using Personalization?",
  ShopifyWarning:
    "You have already installed Personalization app. We advise you to use Personalization app in your Shopify store admin panel.",
  PrestashopWarning:
    "You have already installed Personalization plug-in. We advise you to use Personalization add-on in your Prestashop admin panel.",
  MagentoWarning:
    "You have already installed Personalization plug-in. We advise you to use Personalization add-on in your Magento admin panel.",
  "Segmentify Graph": "Personalization Graph",
  "Custom Segmentify integration requires two important values.":
    "Custom Personalization integration requires two important values.",
  "The percentage of products that have been purchased after being clicked in a Segmentify Recommendation widget.":
    "The percentage of products that have been purchased after being clicked in a Personalization Recommendation widget.",
  "You should make at least one selection from Segmentify Intelligent or Static algorithms":
    "You should make at least one selection from Personalization Intelligent or Static algorithms",
  Widget_Desc:
    "Create personal recommendation campaigns with Personalization's intelligent and static algorithms.",
  Promotion_Desc:
    "Personalise the Special Offers you display in your website with Personalization's Promotion campaigns.",
  SearchPromo: "Create a meaningful search experience for your customers with Personalization’s Search Box.",
  "Overall Segmentify Performance":"Overall Personalization Performance",
  "Revenue Generated by Segmentify": "Generated by Personalization",
  "Segmentify’s Revenue Contribution": "of all revenue via Personalization",
  "Products Sold via Segmentify":"products via Personalization",
  "Select from Segmentify Intelligent Recommendation Types to create your personalized recommendation campaigns" :
      "Select from Personalization Intelligent Recommendation Types to create your personalized recommendation campaigns",
  "Select from Segmentify Static Recommendation Types to create your personalized recommendation campaigns"
      : "Select from Personalization Static Recommendation Types to create your personalized recommendation campaigns",
  "Select from Segmentify Promotion Types to create your personalized recommendation campaigns"
      : "Select from Personalization Promotion Types to create your personalized recommendation campaigns",
  "Before_Search_Input" : "Attract your customers with Personalization's intelligent algorithms even before they make a search."
};

export default external_en;
