import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';

import { uiActions } from '../../actions';
import { isSwitchAccount } from '../../modules/auth/user';
import IconWithUpload from '../../modules/campaign/fields/iconWithUpload';
import ImageWithUpload from '../../modules/campaign/fields/imageWithUpload';
import CouponField from '../shared/coupon-field';
import CouponLanguageBox from '../shared/coupon-language-box';
import LanguageBox from '../shared/language-box';
import SwitchButton from '../shared/switch-button';
import { t } from '../../system/ui';
import { TextField } from '../fields';
import Icons from '../icons';
import Tooltip from '../tooltip';
import ActionButtons from './actionButtons';
import AbandonWidgetSettings from './WidgetSettings';

class AbandonmentRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDetailsHidden: true,
    };

    this.account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;

    this.myRef = React.createRef();

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleIconChange = this.handleIconChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleAdditionalChange = this.handleAdditionalChange.bind(this);
    this.handleTargetChange = this.handleTargetChange.bind(this);
    this.handleChangeActionButtons = this.handleChangeActionButtons.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.validateTriggered === true &&
      nextProps.validateTriggered !== this.props.validateTriggered
    ) {
      // eslint-disable-next-line no-unused-expressions
      this.props.isLast && uiActions.validatedTriggered();

      const errors = this.myRef.current.querySelectorAll('.has-error');
      if (errors.length > 0) {
        this.setState({
          isDetailsHidden: false,
        });
      }
    }
  }

  handleSelectChange(item) {
    let val = item.value;
    if (!val) {
      const opts = this.state.options[0];
      val = opts.value;
    }

    this.props.changeSelectItem(this.props.name, val);
  }

  handleIconChange({ image }) {
    const { name, changeIcon } = this.props;
    changeIcon(name, image);
  }

  handleImageChange({ image }) {
    const { name, changeImage } = this.props;
    changeImage(name, image);
  }

  handleAdditionalChange(additionalType, additionalImageOrIcon) {
    const { name, changeAdditionalImages, changeAdditionalIcons } = this.props;
    if (additionalType === 'additionalImages') {
      changeAdditionalImages(name, additionalImageOrIcon);
    } else {
      changeAdditionalIcons(name, additionalImageOrIcon);
    }
  }

  handleTargetChange(evt) {
    const { name, changeTarget } = this.props;
    changeTarget(name, evt.target.value);
  }

  handleChangeActionButtons(obj) {
    const { name, changeActions } = this.props;
    changeActions(name, obj);
  }

  getTargetValue() {
    const { abandon } = this.props;
    const field = abandon.COUPON;
    return field.target;
  }

  generateOptions = (stop, level) => {
    const arr = [];

    let i;
    for (i = 0; i <= stop; i += level) {
      if (i > 0) {
        const obj = {
          value: i,
          label: i,
        };
        arr.push(obj);
      }
    }

    return arr;
  };

  toggleMenu() {
    this.setState(prevState => ({
      isDetailsHidden: !prevState.isDetailsHidden,
    }));
  }

  isCoupon() {
    return this.props.name === 'COUPON';
  }

  renderCouponField() {
    if (this.props.name !== 'COUPON') {
      return false;
    }
    const {
      name,
      abandon,
      changeCouponCode,
      changeCouponExpire,
      changeCouponBasketAmount,
    } = this.props;

    const field = abandon[name];
    const { couponCode, expireDate, basketAmount } = field;
    return (
      <div className='push-abandonment__row push-abandonment__row--margin push-abandonment__row--nojustify'>
        <CouponField
          couponCode={couponCode || ''}
          changeCouponCode={changeCouponCode}
          expireDate={expireDate}
          changeCouponExpire={changeCouponExpire}
          basketAmount={basketAmount}
          changeCouponBasketAmount={changeCouponBasketAmount}
        />
      </div>
    );
  }

  render() {
    const {
      abandon,
      name,
      toggleButton,
      changeDelayTime,
      changeDelayUnit,
      changeAddTitles,
      changeSelectedLanguage,
      changeAddDescriptions,
    } = this.props;

    const generateOptions = delayUnit => {
      let options;
      if (delayUnit === 'MINUTE') {
        options = this.generateOptions(60, 15);
      }

      if (delayUnit === 'HOUR') {
        options = this.generateOptions(23, 1);
      }

      if (delayUnit === 'DAY') {
        options = this.generateOptions(10, 1);
      }

      return options;
    };

    const changeLocalDelayUnit = (fieldName, unit) => {
      const options = generateOptions(unit);
      changeDelayUnit(fieldName, unit);
      changeDelayTime(fieldName, options[0]);
    };

    const field = abandon[name];

    const {
      additionalTitles,
      additionalDescriptions,
      additionalIcons,
      additionalImages,
      couponCode,
      active,
      delay,
      delayUnit,
      icon,
      image,
      selectedLanguage,
      actions,
    } = field;

    const { mainLanguage } = this.account;
    let modifiedAdditionalIcons = { ...additionalIcons };
    if (
      Object.keys(additionalIcons).length === 0 ||
      !additionalIcons[mainLanguage]
    ) {
      modifiedAdditionalIcons = {
        ...additionalIcons,
        [mainLanguage]: icon,
      };
    }

    let modifiedAdditionalImages = { ...additionalImages };
    if (
      Object.keys(additionalImages).length === 0 ||
      !additionalImages[mainLanguage]
    ) {
      modifiedAdditionalImages = {
        ...additionalImages,
        [mainLanguage]: image,
      };
    }

    const options = generateOptions(delayUnit);

    return (
      <div className='push-abandonment' ref={this.myRef}>
        <div className='push-abandonment__row'>
          <div className='push-abandonment__left'>
            <div className='push-abandonment__number'>{this.props.number}</div>
            <div className='push-abandonment__switch'>
              <SwitchButton
                isSwitchedOn={active}
                onToggle={() => {
                  toggleButton(name);
                }}
              />
            </div>
            <div>
              <span>{this.props.title}</span>
              <Tooltip
                content={this.props.tooltip}
                placement='top'
                alignment='right'
              >
                <i
                  className='icon-info'
                  style={{ color: '#CEDAE4' }}
                  role='presentation'
                >
                  <span className='for-screenreader-only' />
                </i>
              </Tooltip>
            </div>
          </div>
          <div className='push-abandonment__right'>
            <div className='push-abandonment__col'>{t('Send After')}</div>
            <div className='push-abandonment__col'>
              <div className='is-select'>
                <Select
                  clearable={false}
                  searchable={false}
                  isMulti={false}
                  value={delay}
                  onChange={val => {
                    changeDelayTime(name, val);
                  }}
                  options={options}
                />
              </div>
            </div>
            <div
              className='push-abandonment__col push-abandonment__clickable'
              onClick={() => changeLocalDelayUnit(name, 'MINUTE')}
            >
              <Icons
                name={delayUnit === 'MINUTE' ? 'radioSelected' : 'radioEmpty'}
                width={14}
                height={14}
              />
              {t('Minute(s)')}
            </div>
            <div
              className='push-abandonment__col push-abandonment__clickable'
              onClick={() => changeLocalDelayUnit(name, 'HOUR')}
            >
              <Icons
                name={delayUnit === 'HOUR' ? 'radioSelected' : 'radioEmpty'}
                width={14}
                height={14}
              />
              {t('Hour(s)')}
            </div>
            <div
              className='push-abandonment__col push-abandonment__clickable'
              onClick={() => changeLocalDelayUnit(name, 'DAY')}
            >
              <Icons
                name={delayUnit === 'DAY' ? 'radioSelected' : 'radioEmpty'}
                width={14}
                height={14}
              />
              {t('Day(s)')}
            </div>
            <div className='push-abandonment__col'>
              {active && (
                <div
                  className='push-abandonment__expand'
                  onClick={this.toggleMenu.bind(this)}
                >
                  <div
                    className={classNames({
                      'sg-hidden': !this.state.isDetailsHidden,
                    })}
                  >
                    <Icons name='edit' color='#547496' />
                  </div>
                  <div
                    className={classNames({
                      'sg-hidden': this.state.isDetailsHidden,
                    })}
                  >
                    <Icons name='collapse' color='#000' />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {active && (
          <div
            className={classNames({ 'sg-hidden': this.state.isDetailsHidden })}
          >
            {this.renderCouponField()}
            <div className='push-abandonment__row push-abandonment__row--margin'>
              {this.isCoupon() && (
                <>
                  <CouponLanguageBox
                    isSwitchedOn={active}
                    additionalTitles={additionalTitles}
                    additionalDescriptions={additionalDescriptions}
                    couponCode={couponCode}
                    wizardLanguage={this.props.wizardLanguage}
                    account={this.account}
                    onLanguageChange={titlesObj => {
                      changeAddTitles(name, titlesObj);
                    }}
                    changeSelectedLanguage={lang => {
                      changeSelectedLanguage(name, lang);
                    }}
                    onDescChange={descObj => {
                      changeAddDescriptions(name, descObj);
                    }}
                  />
                  <div className='push-abandonment__language-box'>
                    <TextField
                      name='target'
                      label='Target Url'
                      className='item-stacked one-whole'
                      value={this.getTargetValue() || ''}
                      required
                      tooltipText={t('Prepare the target url of your push')}
                      tooltip
                      onChange={this.handleTargetChange}
                      ref='title'
                      validation={{ minLength: 1 }}
                    />
                  </div>
                </>
              )}

              {!this.isCoupon() && (
                <LanguageBox
                  isSwitchedOn={active}
                  additionalTitles={additionalTitles}
                  wizardLanguage={this.props.wizardLanguage}
                  account={this.account}
                  onLanguageChange={titlesObj => {
                    changeAddTitles(name, titlesObj);
                  }}
                  changeSelectedLanguage={lang => {
                    changeSelectedLanguage(name, lang);
                  }}
                />
              )}
            </div>
            <div className='push-abandonment__row push-abandonment__row--margin'>
              <h3 className='push-abandonment__title'>Visuals</h3>
            </div>
            <div className='push-abandonment__row push-abandonment__row--margin'>
              <ol className='form-elements'>
                <IconWithUpload
                  onIconChanged={this.handleIconChange}
                  updateStateValue={this.handleAdditionalChange}
                  campaign={{
                    type: 'ABANDONED_CART',
                    icon,
                    isCoupon: this.isCoupon(),
                  }}
                  options={{ label: 'Notification Icon' }}
                  additionalIcons={modifiedAdditionalIcons}
                  selectedLanguage={selectedLanguage}
                />
              </ol>
            </div>
            <div className='push-abandonment__row push-abandonment__row--margin'>
              <ol className='form-elements'>
                <ImageWithUpload
                  key={this.props.name}
                  onIconChanged={this.handleImageChange}
                  updateStateValue={this.handleAdditionalChange}
                  campaign={{
                    type: 'ABANDONED_CART',
                    image,
                    isCoupon: this.isCoupon(),
                  }}
                  options={{ label: 'Notification Image' }}
                  additionalImages={modifiedAdditionalImages}
                  selectedLanguage={selectedLanguage}
                />
              </ol>
            </div>
            <div className='push-abandonment__row push-abandonment__row--margin'>
              <ActionButtons
                campaign={{
                  actions,
                }}
                onButtonChanged={this.handleChangeActionButtons}
              />
            </div>
            {this.isCoupon() && (
              <AbandonWidgetSettings
                data={this.props.widget}
                onDataChange={this.props.changeWidgetItem}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  validateTriggered: store.ui.validateTriggered,
  widget: store.abandon.WIDGET,
});

export default connect(MapStatesToProps, null)(AbandonmentRow);
