import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { SingleDatePicker } from 'react-dates';
import { Formik } from 'formik';
import moment from 'moment/moment';
import { browserHistory } from 'react-router';
import { SFYHeader } from '../../../components/sfy-components/module-exports';
import { getDynamicBreadCrumbOptions } from '../constants/constants';
import ExperimentationDefinitionStep from '../../../components/experimentation/definition/ExperimentationDefinitionStep';
import ExperimentationVariations from '../../../components/experimentation/definition/ExperimentationVariations';
import { getModuleByPath } from '../../../components/experimentation/list/ExperimentationList';
import {
  getRandomColor,
  goalOptions,
} from '../../../components/experimentation/definition/ExperimentationDefinitionPanel';
import { facetedSearchRequest } from '../../../actions/faceted-search';
import Icon from '../../../components/icon';
import { clone } from '../../../system/object';
import ExperimentationSummary from '../../../components/experimentation/definition/ExperimentationSummary';
import Icons from '../../../components/icons';

const AddOrDuplicate = ({ params }) => {
  const { mode, id, module } = params;
  const [breadCrumbName, setBreadCrumbName] = useState('Create New');
  const [opened, setOpened] = useState({
    testName: true,
  });
  const [arrowDown, setArrowDown] = useState(true);
  const [summary, setSummary] = useState(false);
  const [summaryValues, setSummaryValues] = useState({});
  const [focused, setFocused] = useState(false);
  const [initialTouches, setInitialTouches] = useState({});
  const [initialValues, setInitialValues] = useState({
    testName: '',
    endDate: moment().add(1, 'months'),
    primaryGoal: null,
    variations: [
      {
        color: '#47607B',
        variationName: 'Original',
        instanceId: null,
        testSplit: '50',
      },
      {
        color: '#A394D0',
        variationName: 'Variant 1',
        instanceId: null,
        testSplit: '50',
      },
    ],
  });

  const customInputIcon = () => {
    return <Icon name='calendar' />;
  };

  const collapsableController = step => {
    const temporaryOpens = clone(opened);
    if (temporaryOpens[step]) {
      delete temporaryOpens[step];
    } else {
      temporaryOpens[step] = true;
      Object.keys(temporaryOpens).forEach(open => {
        if (open !== step) delete temporaryOpens[open];
      });
    }
    setOpened(temporaryOpens);
  };

  const onSubmit = values => {
    const summaryValues = clone(values);
    summaryValues.endDate = values.endDate.format('YYYY-MM-DD');
    summaryValues.variations[0].testSplit = parseFloat(
      summaryValues.variations[0].testSplit,
    );
    summaryValues.variations[1].testSplit = parseFloat(
      summaryValues.variations[1].testSplit,
    );
    setSummary(true);
    setSummaryValues(summaryValues);
  };

  const validate = ({ testName, endDate, primaryGoal, variations }) => {
    const errors = {};

    let total = 0;
    for (const variation of variations) {
      if (!variation.instanceId)
        errors.variations = 'You should select campaign for test split';
      total += parseFloat(variation.testSplit) ?? 0;
    }

    if (variations.length < 2) {
      errors.variations = 'You should split the test at least two';
    }

    if (total !== 100)
      errors.variations = 'Total split ratio should add up to 100';

    if (!testName) errors.testName = 'Test property is required';

    if (!primaryGoal) errors.primaryGoal = 'Primary goal is required';

    if (!endDate || endDate.isSameOrBefore(moment()))
      errors.endDate = 'End date must after today';

    return errors;
  };

  const onClose = () => {
    setSummary(false);
    setSummaryValues({});
  };

  const onFinishExperimentSuccess = () => {
    browserHistory.push({
      pathname: `/${
        getModuleByPath(module) === 'BT'
          ? 'behavioural-targeting'
          : 'recommendations'
      }/experimentation/ab_test/list`,
    });
  };

  const arrowRenderer = () => {
    return <Icons name='chevronDown' color='#979797' />;
  };

  useEffect(() => {
    if (mode === 'duplicate') {
      facetedSearchRequest('experimentation/get', {
        instanceId: id,
      })
        .get()
        .then(experiment => {
          const tempInitialValues = { ...experiment };
          delete tempInitialValues.id;
          delete tempInitialValues.status;
          tempInitialValues.variations[0].color = '#47607B';
          tempInitialValues.variations[1].color = '#A394D0';
          tempInitialValues.endDate = moment().add(1, 'months');
          setInitialValues(tempInitialValues);
          setBreadCrumbName(experiment?.id);
          setOpened({
            testName: true,
            primaryGoal: true,
            variations: true,
          });
          setInitialTouches({
            testName: true,
            primaryGoal: true,
            variations: true,
          });
        });
    }
  }, []);

  return (
    <>
      <SFYHeader
        breadCrumbOptions={getDynamicBreadCrumbOptions(
          breadCrumbName,
          getModuleByPath(module),
        )}
      />
      <Formik
        initialValues={initialValues}
        initialTouched={initialTouches}
        validate={validate}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            <h3
              className='page-title'
              style={{ marginBottom: '0', textTransform: 'none' }}
            >
              Create an A/B Test
            </h3>
            <p>
              You can easily create an A/B Test with two or more different
              campaigns of a module via our A/B tool.
            </p>
            <div className='widget page-content-block experimentation-definition'>
              <div
                onClick={() => setArrowDown(!arrowDown)}
                className='experimentation-definition-wrapper'
              >
                <ExperimentationDefinitionStep
                  property='testName'
                  name='Test Name'
                  opened={opened}
                  stepCount={1}
                  touched={touched}
                  errors={errors}
                  collapsableController={collapsableController}
                >
                  <div className='experimentation-definition-testName'>
                    <label htmlFor='testName'>Test Name</label>
                    <input
                      placeholder='Enter test name...'
                      type='text'
                      id='testName'
                      name='testName'
                      value={values.testName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className='experimentation-input'
                    />
                  </div>
                </ExperimentationDefinitionStep>
                <ExperimentationDefinitionStep
                  property='primaryGoal'
                  name='Primary Goal'
                  opened={opened}
                  stepCount={2}
                  touched={touched}
                  errors={errors}
                  collapsableController={collapsableController}
                >
                  <div
                    onClick={() => setArrowDown(!arrowDown)}
                    className='experimentation-definition-goalType'
                  >
                    <Select
                      placeholder='Select a goal type'
                      options={goalOptions}
                      searchable={false}
                      clearable={false}
                      onChange={primaryGoal =>
                        setFieldValue('primaryGoal', primaryGoal?.value ?? null)}
                      onInputChange={() => setFieldTouched('primaryGoal')}
                      onMenuOpen={() => setFieldTouched('primaryGoal')}
                      value={values.primaryGoal}
                      arrowRenderer={arrowRenderer}
                    />
                  </div>
                </ExperimentationDefinitionStep>
                <ExperimentationDefinitionStep
                  property='variations'
                  name='Variations'
                  opened={opened}
                  stepCount={3}
                  touched={touched}
                  errors={errors}
                  collapsableController={collapsableController}
                >
                  <ExperimentationVariations
                    getRandomColor={getRandomColor}
                    module={getModuleByPath(module)}
                    variations={values.variations}
                    setFieldTouched={setFieldTouched}
                    setVariations={variations =>
                      setFieldValue('variations', variations)
                    }
                  />
                </ExperimentationDefinitionStep>
                <ExperimentationDefinitionStep
                  property='endDate'
                  name='End Date'
                  opened={opened}
                  stepCount={4}
                  touched={touched}
                  errors={errors}
                  collapsableController={collapsableController}
                >
                  <SingleDatePicker
                    date={values.endDate}
                    onDateChange={endDate => setFieldValue('endDate', endDate)}
                    focused={focused}
                    onFocusChange={event => setFocused(event.focused)}
                    displayFormat='DD/MM/YYYY'
                    numberOfMonths={1}
                    customInputIcon={customInputIcon()}
                    hideKeyboardShortcutsPanel
                    id='experiment-end-date'
                  />
                </ExperimentationDefinitionStep>
                <div className='experimentation-button-container'>
                  <button
                    className='experimentation-submit-button'
                    type='submit'
                    disabled={!isValid}
                  >
                    Start Test
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      {summary && (
        <ExperimentationSummary
          values={summaryValues}
          onClose={onClose}
          mode='confirmation'
          onFinishExperimentSuccess={onFinishExperimentSuccess}
        />
      )}
    </>
  );
};

export default AddOrDuplicate;
