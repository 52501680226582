import React, { useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import Icons from '../../icons';
import { t } from '../../../system/ui';
import './Attributes.scss';
import { SFYAttributeButton } from '../module-exports';
import { filterAttributeTypes } from './constants';
import Color from './AttributeComponents/Color';
import Gender from './AttributeComponents/Gender';
import Label from './AttributeComponents/Label';
import Size from './AttributeComponents/Size';
import Stock from './AttributeComponents/Stock';
import PriceRange from './AttributeComponents/PriceRange';
import Custom from './AttributeComponents/Custom';
import Brand from './AttributeComponents/Brand';
import Product from './AttributeComponents/Product';

const getInitialComponentPropsState = (segmentType, filterParams) => {
  const initialComponentPropsState = {};
  if (
    filterParams[segmentType] &&
    Object.keys(filterParams[segmentType]).length > 0
  ) {
    Object.keys(filterParams[segmentType]).forEach(attributeType => {
      initialComponentPropsState[attributeType] = {
        filterType: segmentType,
        show: true,
        type: attributeType,
      };
    });
  }
  return initialComponentPropsState;
};

const Attributes = ({
  bundleIndex,
  title,
  titleIcon,
  titleColor,
  description,
  segmentType,
  setFilterParams,
  filterParams,
  filterTypes,
}) => {
  const [componentProps, setComponentProps] = useState(
    getInitialComponentPropsState(segmentType, filterParams),
  );
  const [isExpanded, setIsExpanded] = useState(true);
  const [isCustom, setIsCustom] = useState([]);

  const setAttributeComp = (component, segmentType, isShow, fromId = null) => {
    const components = {
      filterType: segmentType,
      show: isShow,
      type: component,
    };
    if (isShow || component === 'custom') {
      let current =
        (filterParams[segmentType] && filterParams[segmentType]) || [];

      if (component === 'custom') {
        const id = v4();
        if (fromId !== null) {
          const newCurrent = {};
          Object.keys(current).forEach(key => {
            newCurrent[key] = current[key];
            if (key === `${component}-${fromId}`) {
              newCurrent[`${component}-${id}`] = [
                {
                  id,
                  index: bundleIndex,
                  type: component,
                  values: {},
                },
              ];
            }
          });
          current = newCurrent;
        } else {
          current = {
            ...current,
            [`${component}-${id}`]: [
              {
                id,
                index: bundleIndex,
                type: component,
                values: {},
              },
            ],
          };
        }
      } else {
        current = {
          ...current,
          [component]: [
            {
              id: v4(),
              index: bundleIndex,
              type: component,
              values: {},
            },
          ],
        };
      }
      setComponentProps({ ...componentProps, [component]: components });
      setFilterParams({ ...filterParams, [segmentType]: current });
      return;
    }

    if (component !== 'custom') {
      const newComponentProps = { ...componentProps };
      delete newComponentProps[component];
      setComponentProps(newComponentProps);
    }
  };

  const addSubComponents = (component, segmentType) => {
    let current =
      (filterParams[segmentType] && filterParams[segmentType]) || [];

    if (current[component]) {
      current[component].push({
        id: v4(),
        index: bundleIndex,
        type: component,
        values: {},
      });
    } else {
      current = {
        ...current,
        [component]: [
          {
            id: v4(),
            index: bundleIndex,
            type: component,
            values: {},
          },
        ],
      };
    }

    return setFilterParams({ ...filterParams, [segmentType]: current });
  };

  const removeSubComponents = (component, segmentType, id) => {
    const current =
      (filterParams[segmentType] && filterParams[segmentType]) || [];

    if (current[component]) {
      current[component] = current[component].filter(
        subComponent => subComponent.id !== id,
      );
    }

    return setFilterParams({ ...filterParams, [segmentType]: current });
  };

  const handleSelectChange = (
    fieldName,
    newValues,
    inclusionId,
    segmentType,
  ) => {
    console.log(filterParams);
    const includes =
      (filterParams[segmentType] && filterParams[segmentType]) || [];
    const values = {};
    values[fieldName] = {
      value: [],
    };
    const items = [];
    if (newValues) {
      newValues.forEach(item => {
        items.push(item.value);
      });
      values[fieldName].value = items;
      Object.keys(includes).forEach(key => {
        if (includes[key][0].id === inclusionId) {
          includes[key][0].values = values;
        }
      });
    }
    setFilterParams({ ...filterParams, [segmentType]: includes });
  };

  const handleInputChange = (attributeType, segmentType, newValue, id) => {
    const includes = filterParams[segmentType] && filterParams[segmentType];

    Object.keys(includes).forEach(key => {
      includes[key].forEach(subComponent => {
        if (subComponent.id === id) {
          subComponent.values = newValue;
        }
      });
    });

    setFilterParams({ ...filterParams, [segmentType]: includes });
  };

  const handleFilterRemove = (id, segmentType, type) => {
    const includes = filterParams[segmentType] && filterParams[segmentType];
    const newIncludes = Object.fromEntries(
      Object.entries(includes).filter(([key]) => includes[key][0].id !== id),
    );

    const newComponentProps = Object.fromEntries(
      Object.entries(componentProps).filter(
        ([key]) => componentProps[key].type !== type,
      ),
    );

    setComponentProps(newComponentProps);
    setFilterParams({ ...filterParams, [segmentType]: newIncludes });
  };

  const showAttributeComponent = component => {
    if (component.type.includes('custom')) {
      return (
        <Custom
          {...filterParams[segmentType][component.type][0]}
          subComponents={filterParams[segmentType][component.type]}
          segmentType={segmentType}
          onInputChange={handleInputChange}
          onFilterRemove={handleFilterRemove}
          setAttributeComp={setAttributeComp}
          filterParams={filterParams}
        />
      );
    }
    switch (component.type) {
      case 'product':
        return (
          <Product
            {...filterParams[segmentType][component.type][0]}
            subComponents={filterParams[segmentType][component.type]}
            segmentType={segmentType}
            onSelectChange={handleSelectChange}
            onFilterRemove={handleFilterRemove}
          />
        );
      case 'brand':
        return (
          <Brand
            {...filterParams[segmentType][component.type][0]}
            subComponents={filterParams[segmentType][component.type]}
            segmentType={segmentType}
            onSelectChange={handleSelectChange}
            onFilterRemove={handleFilterRemove}
          />
        );
      case 'color':
        return (
          <Color
            {...filterParams[segmentType][component.type][0]}
            subComponents={filterParams[segmentType][component.type]}
            segmentType={segmentType}
            onSelectChange={handleSelectChange}
            onFilterRemove={handleFilterRemove}
          />
        );
      case 'gender':
        return (
          <Gender
            {...filterParams[segmentType][component.type][0]}
            subComponents={filterParams[segmentType][component.type]}
            segmentType={segmentType}
            onSelectChange={handleSelectChange}
            onFilterRemove={handleFilterRemove}
          />
        );
      case 'label':
        return (
          <Label
            {...filterParams[segmentType][component.type][0]}
            subComponents={filterParams[segmentType][component.type]}
            segmentType={segmentType}
            onSelectChange={handleSelectChange}
            onFilterRemove={handleFilterRemove}
          />
        );
      case 'size':
        return (
          <Size
            {...filterParams[segmentType][component.type][0]}
            subComponents={filterParams[segmentType][component.type]}
            segmentType={segmentType}
            onSelectChange={handleSelectChange}
            onFilterRemove={handleFilterRemove}
          />
        );
      case 'stock':
        return (
          <Stock
            {...filterParams[segmentType][component.type][0]}
            subComponents={filterParams[segmentType][component.type]}
            segmentType={segmentType}
            filterParams={filterParams}
            onInputChange={handleInputChange}
            onFilterRemove={handleFilterRemove}
            addSubComponents={addSubComponents}
            removeSubComponents={removeSubComponents}
          />
        );
      case 'priceRange':
        return (
          <PriceRange
            {...filterParams[segmentType][component.type][0]}
            subComponents={filterParams[segmentType][component.type]}
            segmentType={segmentType}
            filterParams={filterParams}
            onInputChange={handleInputChange}
            onFilterRemove={handleFilterRemove}
            addSubComponents={addSubComponents}
            removeSubComponents={removeSubComponents}
          />
        );
      default:
        return null;
    }
  };

  const subChildRenderController = useMemo(
    () =>
      Object.keys(getInitialComponentPropsState(segmentType, filterParams)).map(
        (key, index) => {
          if (componentProps[key]?.show) {
            return (
              <li className='wizard-input-type-wrapper'>
                <ol className='exclusion-list criteria-list wizard-items-container'>
                  {showAttributeComponent(componentProps[key])}
                </ol>
              </li>
            );
          }
        },
      ),
    [componentProps, filterParams, segmentType],
  );

  useEffect(() => {
    setComponentProps(getInitialComponentPropsState(segmentType, filterParams));
    setIsCustom(
      Object.keys(filterParams[segmentType]).map(key => {
        return key.substring(0, 6) === 'custom';
      }),
    );
  }, [filterParams]);

  return (
    <div className='filter-comp'>
      <div className='sfy-accordions-head'>
        <div className='sfy-accordions-head-desc'>
          <h3 className='wizard-comp-title' style={{ color: titleColor }}>
            <Icons name={titleIcon} color={titleColor} />
            &nbsp;{t(title)}
          </h3>
          <p className='wizard-comp-description'>{t(description)}</p>
        </div>
        <div
          className='sfy-accordions-head-arrow'
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <span>{isExpanded ? 'Hide' : 'Show'}</span>
          <i className={isExpanded ? 'arrow up' : 'arrow down'} />
        </div>
      </div>

      <div className='wizard-input-types'>
        {Object.keys(filterAttributeTypes).map(type => {
          if (filterTypes.includes(type)) {
            return (
              <SFYAttributeButton
                key={`${type}-${segmentType}`}
                item={filterAttributeTypes[type]}
                segmentType={segmentType}
                setAttributeComp={setAttributeComp}
                isOpen={
                  type === 'custom' && isCustom.includes(true)
                    ? true
                    : !!filterParams?.[segmentType]?.[type]
                }
              />
            );
          }
          return null;
        })}
      </div>

      <div style={{ display: isExpanded ? 'block' : 'none' }}>
        {subChildRenderController}
      </div>
    </div>
  );
};

export default Attributes;
