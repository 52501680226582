import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Select, Calendar, TextField } from '../../../../components/fields';
import Icons from '../../../../components/icons';
import Tooltip from '../../../../components/tooltip';
import {
  reformatDate,
  isValidStartDate,
  isBeforeNow,
  get15MinutesLater,
  periods,
} from '../../../../system/date';
import { clone } from '../../../../system/object';
import { t } from '../../../../system/ui';

const defaults = {
  schedule: {
    type: 'one_time',
    date: get15MinutesLater().valueOf(),
    period: '',
  },
  validity: '72',
};

const type = [
  { value: 'one_time', label: t('Once') },
  { value: 'periodic', label: t('Periodic') },
];

class SchedulingOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: clone(defaults),
      oneTimeValueChanged: false
    };

    this.update = this.update.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.handleType = this.handleType.bind(this);
    this.handlePeriod = this.handlePeriod.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleValidity = this.handleValidity.bind(this);
  }

  componentDidMount() {
    defaults.schedule.date = get15MinutesLater().valueOf();
    this.update(this.props.campaign);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.update(newProps.campaign);
    }
  }

  update(campaign) {
    let validity = campaign.validity || clone(defaults.validity),
      scheduling = campaign.schedule || clone(defaults.schedule);

    this.updateValue(validity, "validity");
    this.updateValue(scheduling, "schedule");
  }

  updateValue(newValue, type) {
    let values = this.state.values;
    values[type] = newValue;
    this.setState({ values });
  }

  handleType(selection) {
    let values = this.state.values;
    values.schedule.type = selection.value;
    this.setState({ values });
  }

  handleDateChange(datetime) {
    let values = this.state.values;
    values.schedule.date = datetime.valueOf();
    this.setState({ values, oneTimeValueChanged: true });
  }

  handlePeriod(selection) {
    let values = this.state.values;
    values.schedule.period = selection.value;
    this.setState({ values });
  }

  handleValidity(e) {
    let values = this.state.values;
    values.validity = e.target.value;
    this.setState({ values });
  }

  render() {
    if (!this.props.isSchedulingVisible) {
      return false;
    }

    return (
      <div className="wizard-input-type-wrapper">
        <h3 className="wizard-input-type-title">
          <Icons name="clock" />
          {t("Scheduling")}
          <span className="field-tooltip-icon">
            <Tooltip
              content={t("scheduling_" + this.props.campaignType)}
              alignment="left"
            >
              <i className="icon-info" role="presentation">
                <span className="for-screenreader-only" />
              </i>
            </Tooltip>
          </span>
        </h3>
        <ol className="form-elements">
          <li className="row">
            <Select
              name="type"
              className="item-field medium"
              label=""
              value={this.state.values.schedule.type}
              options={type}
              onChange={this.handleType}
            />

            {this.state.values.schedule.type !== "one_time" ? (
              <span>
                <Select
                  name="period"
                  className="item-field large"
                  label=""
                  value={this.state.values.schedule.period}
                  options={periods}
                  onChange={this.handlePeriod}
                />
                <Calendar
                  name="date"
                  className="item-field small"
                  value={reformatDate(this.state.values.schedule.date, "HH:mm")}
                  isValidDate={isValidStartDate}
                  onChange={this.handleDateChange}
                  dateFormat={false}
                />
              </span>
            ) : (
              <span>
                <Calendar
                  name="date"
                  className="item-field medium"
                  value={
                    isBeforeNow(this.state.values.schedule.date)
                      ? reformatDate(defaults.schedule.date)
                      : reformatDate(this.state.values.schedule.date)
                  }
                  isValidDate={isValidStartDate}
                  onChange={this.handleDateChange}
                  checkPastDate={true}
                  unformattedDate={this.state.values.schedule.date}
                />
              </span>
            )}
            <TextField
              name="validity"
              label={t("Hours")}
              labelPlace="after"
              placeholder={t("Valid for X")}
              inputClassName="small"
              value={this.state.values.validity}
              onChange={this.handleValidity}
            />
          </li>
        </ol>
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  isSchedulingVisible: store.wizard.isSchedulingVisible,
  editForm: store.wizard.editForm
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  SchedulingOptions
);
