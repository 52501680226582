import React from 'react';
import PropTypes from 'prop-types';

import { getAccountCurrency } from '../../modules/auth/user';
import { Calendar, NumberField, TextField } from '../fields';
import Tooltip from '../tooltip';
import { t } from '../../system/ui';

const CouponField = ({
  couponCode,
  expireDate,
  basketAmount,
  changeCouponCode,
  changeCouponExpire,
  changeCouponBasketAmount,
}) => {
  const onChangeCoupon = e => {
    changeCouponCode('COUPON', e.target.value);
  };

  const onChangeCalendar = value => {
    changeCouponExpire('COUPON', value);
  };

  const onChangeBasketAmount = e => {
    changeCouponBasketAmount('COUPON', e.target.value);
  };

  return (
    <>
      <div style={{ marginRight: '20px' }}>
        <TextField
          name='coupon'
          label='Coupon'
          className='item-stacked one-whole'
          value={couponCode}
          required
          tooltipText={t('Prepare the Coupon of your push')}
          tooltip
          onChange={e => onChangeCoupon(e)}
          // ref='Coupon'
          isPush
        />
      </div>
      <div>
        <label className='item item-stacked one-whole label-emphasise label-mail'>
          <span className='item-label'>
            {t('Show Until')}
            <Tooltip
              content={t(
                'Choose until when the sticky popup will be shown to your customers.',
              )}
              alignment='left'
              placement='bottom'
            >
              <i className='icon-info' role='presentation'>
                <span className='for-screenreader-only' />
              </i>
            </Tooltip>
          </span>
          <Calendar
            name='date'
            className='item-field'
            value={expireDate}
            onChange={e => onChangeCalendar(e)}
          />
        </label>
      </div>
      <div>
        <label className='item label-emphasise label-mail'>
          <div className='item-label'>
            {t('Basket Amount')}
            <Tooltip
              content={t(
                'Set the minimum basket total amount to send the coupon campaign.',
              )}
              alignment='left'
              placement='bottom'
            >
              <i className='icon-info' role='presentation'>
                <span className='for-screenreader-only' />
              </i>
            </Tooltip>
          </div>
          <div>
            <span className='item-label'>{t('min')}</span>
            <NumberField
              name='basketAmount'
              label={getAccountCurrency().symbol}
              labelPlace='after'
              inputClassName='x-small'
              value={basketAmount}
              onChange={e => onChangeBasketAmount(e)}
              min='0'
              step='1'
            />
          </div>
        </label>
      </div>
    </>
  );
};

CouponField.propTypes = {
  couponCode: PropTypes.string,
  expireDate: PropTypes.string,
  basketAmount: PropTypes.number,
  changeCouponCode: PropTypes.func.isRequired,
  changeCouponExpire: PropTypes.func.isRequired,
  changeCouponBasketAmount: PropTypes.func.isRequired,
};

export default CouponField;
