import { facetedSearchRequest } from '../../../actions/faceted-search';

const getModuleByPath = modulePath => {
  let module = '';
  // TODO this switch conditions will be expanded once more integration is added.
  if (modulePath === 'search') {
    module = 'SEARCH';
  } else if (modulePath === 'recommendations') {
    module = 'RECOMMENDATION';
  } else if (modulePath === 'behavioural-targeting') {
    module = 'BT';
  }
  return module;
};

const getModulePath = route => {
  const modulePath = route.path.substring(1, route.path.length);
  return modulePath;
};

const getExperimentationList = (route, callback) => {
  const moduleQueryParam = `&module=${getModuleByPath(getModulePath(route))}`;
  facetedSearchRequest('experimentation/list', null, moduleQueryParam)
    .get()
    .then(experiment => {
      callback(experiment);
    });
};

export { getExperimentationList, getModulePath, getModuleByPath };
