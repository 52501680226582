import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCampaignForm } from 'actions/faceted-search';
import Ordering from '../../../common/Ordering';

const FacetedOrdering = () => {
  const state = useSelector(s => s.facetedSearch);
  const dispatch = useDispatch();

  return (
    <Ordering
      key={'faceted ' + state.campaignForm.instanceId}
      orders={state.campaignForm.orders ?? {}}
      type='faceted'
      updateOrders={orders => dispatch(updateCampaignForm({ orders }))}
      instanceId={state.campaignForm.instanceId}
      removeOlParentClass={false}
    />
  );
};
export default FacetedOrdering;
