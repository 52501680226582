import React from 'react';

class IconCreateAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: '16px',
      height: '16px',
      color: '#7a7a7c',
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    const width = this.props.width ? `${this.props.width}px` : this.state.width;
    const height = this.props.height
      ? `${this.props.height}px`
      : this.state.height;
    const color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 16 16'
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <path
          fill={this.state.color}
          d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M11,9h-1c-0.6,0-1,0.4-1,1v1c0,0.6-0.4,1-1,1l0,0 c-0.6,0-1-0.4-1-1v-1c0-0.6-0.4-1-1-1H5C4.4,9,4,8.6,4,8l0,0c0-0.6,0.4-1,1-1h1c0.6,0,1-0.4,1-1V5c0-0.6,0.4-1,1-1l0,0 c0.6,0,1,0.4,1,1v1c0,0.6,0.4,1,1,1h1c0.6,0,1,0.4,1,1l0,0C12,8.6,11.6,9,11,9z'
        />
      </svg>
    );
  }
}

export default IconCreateAccount;
