/* eslint-disable react/jsx-indent */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import PushPermissionPrompt from 'components/push/push-permission-prompt';
import { modalActions, wizardActions, uiActions } from '../../actions';
import { pushCampaignSpecificData } from '../../constants/datamaps/campaign';
import {
  days,
  localizationParams,
  paramsInWizard,
  paramsTypes,
  periods,
  campaignInitialData,
  pushNotificationsLifeTime,
  pushNotificationsPeriodicLifeTime,
  pushTypes,
} from '../../constants/datamaps/push';
import MailCapping from '../email/capping';
import { Calendar, TextField } from '../fields';
import Icons from '../icons';
import { isSwitchAccount, hasModule } from '../../modules/auth/user';
import Fields from '../../modules/campaign/fields';
import SampleContentModal from '../../modules/emails/sample-content-modal';
import {
  getCampaign,
  editCampaign,
  addCampaign,
  getSampleContent,
  getActivePushCampaigns,
} from '../../modules/push/ajax';
import {
  personalizationRecommendations,
  personalizationRecommendationExclusions,
} from '../../modules/transform/personalizationRecommendations';
import { MPExtraContainer, MPScheduling, MPAudience } from '../multipleModule';
import { convertToEpochTime } from '../../system/date';
import { clone } from '../../system/object';
import { t } from '../../system/ui';
import TimeAlert from '../time-alert';
import Tooltip from '../tooltip';
import ConfigurationError from './configuration-error';
import LanguageChooser from './language-chooser';
import {
  getBreadcrumbNames,
  getCampaignNames,
  paramsBasedFunctionName,
} from './utils';

class EditPushCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      twoStepEnabled: false,
      algorithmSelected: false,
      contentSamples: [],
      campaign: clone(
        campaignInitialData.filter(cmp => {
          return cmp.type === this.props.type;
        })[0],
      ),
      campaignStatus: '',
      params: campaignInitialData.filter(cmp => {
        return cmp.type === this.props.type;
      })[0].params
        ? clone(
            campaignInitialData.filter(cmp => {
              return cmp.type === this.props.type;
            })[0].params,
          )
        : {},
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      validity: '72',
      showDiscount: false,
      hidePrice: false,
      capping: {
        capCount: '',
        frequency: '',
        types: [],
      },
      sendTimeStart: 1,
      sendTimeEnd: 24,
      cappignError: false,
      periodicSelected: false,
      actions: [],
      selectedLanguage: '',
      additionalTitles: {},
      additionalDescriptions: {},
      additionalRedirectUrls: {},
      additionalIcons: {},
      additionalImages: {},
      additionalButtonLaterText: {},
      additionalButtonAllowText: {},
      isReady: false,
    };

    this.onSampleTextSelect = this.onSampleTextSelect.bind(this);
    this.save = this.save.bind(this);
    this.campaignDetails = this.campaignDetails.bind(this);
    this.onCustomParamChange = this.onCustomParamChange.bind(this);
    this.onWorkingHourChange = this.onWorkingHourChange.bind(this);
    this.onCampaignDataChange = this.onCampaignDataChange.bind(this);
    this.onDelayChange = this.onDelayChange.bind(this);
    this.onValidityChange = this.onValidityChange.bind(this);
    this.toggleDiscountInput = this.toggleDiscountInput.bind(this);
    this.onEventNameChange = this.onEventNameChange.bind(this);
    this.onCampaignCappingParamChange = this.onCampaignCappingParamChange.bind(
      this,
    );
    this.changeFields = this.changeFields.bind(this);
    this.onSchedulingChange = this.onSchedulingChange.bind(this);
    this.setCampaignDetails = this.setCampaignDetails.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
    this.setLang = this.setLang.bind(this);
    this.onSelectSampleContent = this.onSelectSampleContent.bind(this);
    this.campaignDataFix = this.campaignDataFix.bind(this);

    this.workingHourRef = React.createRef();
  }

  componentDidMount() {
    this.setLang();
    if (!this.props.isReplicate) {
      uiActions.resetPage();
      wizardActions.saveReset();
      wizardActions.wizardReset();
      wizardActions.wizardEdit();
    }
    uiActions.isValidated();
    this.campaignDetails();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      this.campaignDetails();
    }

    if (newProps.save !== false) {
      this.save(newProps.save);
    }

    if (this.props.criteria !== newProps.criteria) {
      this.changeFields(newProps.criteria);
    }
  }

  onPermissionPromptToggle = () => {
    this.setState(prevState => {
      return {
        twoStepEnabled: !prevState.twoStepEnabled,
      };
    });
  };

  onSampleTextSelect() {
    if (!this.state.isReady) {
      uiActions.isLoading();
      getSampleContent(this.props.type, samples => {
        uiActions.isLoaded();
        this.setState({ contentSamples: samples, isReady: true }, () =>
          this.openSampleModal(),
        );
      });
    } else {
      this.openSampleModal();
    }
  }

  onSelectSampleContent(contentType, selectedContent) {
    modalActions.closeModal();

    this.setState(prevState => ({
      additionalTitles: {
        ...prevState.additionalTitles,
        [prevState.selectedLanguage]: selectedContent,
      },
    }));
  }

  onCustomParamChange(e) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newCustoms = clone(this.state.params);
    Object.keys(this.state.params).forEach(custom => {
      if (
        paramsInWizard.indexOf(custom) < 0 &&
        Object.keys(localizationParams).indexOf(custom) < 0
      ) {
        if (custom === 'workingHour') {
          newCustoms.workingHour = this.state.params.workingHour;
        } else if (custom === 'delay') {
          newCustoms.delay = this.state.params.delay;
        } else if (custom === 'sendBefore') {
          let selectValue;
          const selectPeriod = this.refs.wizard.querySelector(
            `[name=${custom}Period`,
          ).value;
          if (newCustoms[custom].split('-')[1] !== selectPeriod) {
            switch (selectPeriod) {
              case 'day':
                this.setState({ sendTimeStart: 1, sendTimeEnd: 7 });
                break;
              case 'hour':
                this.setState({ sendTimeStart: 1, sendTimeEnd: 24 });
                break;
              case 'minute':
                this.setState({ sendTimeStart: 1, sendTimeEnd: 60 });
                break;
              default:
                break;
            }
            selectValue = 1;
          } else {
            selectValue = this.refs.wizard.querySelector(`[name=${custom}Start`)
              .value;
          }
          newCustoms[custom] = `${selectValue}-${selectPeriod}`;
        } else if (this.refs.wizard.querySelector(`[name='${custom}Start']`)) {
          const startValue = this.refs.wizard.querySelector(
            `[name='${custom}Start']`,
          ).value;
          const endValue = this.refs.wizard.querySelector(
            `[name='${custom}End']`,
          ).value;
          newCustoms[custom] = `${startValue}-${endValue}`;
        } else if (this.refs.wizard.querySelector(`[name='${custom}']`)) {
          if (
            paramsTypes[custom].type === 'integer' ||
            paramsTypes[custom].type === 'text'
          ) {
            const val = this.refs.wizard.querySelector(`[name='${custom}']`)
              .value;
            if (val) {
              if (paramsTypes[custom].type === 'integer') {
                const minValue = paramsTypes[custom]?.min || 0;
                newCustoms[custom] =
                  val >= minValue ? val : this.state.params[custom];
              } else {
                newCustoms[custom] = val;
              }
            } else {
              newCustoms[custom] = val;
            }
          } else if (e.target.name === 'sendingPeriod') {
            newCustoms[custom] = e.target.value;
          }
        }
      }
    });
    this.setState({
      params: newCustoms,
    });
  }

  onWorkingHourChange(newVal) {
    const campaignCustoms = clone(this.state.params);
    campaignCustoms.workingHour = newVal.format('HH:mm');
    this.setState({
      params: campaignCustoms,
    });
  }

  onDelayChange(newVal) {
    const campaignCustoms = clone(this.state.params);
    campaignCustoms.delay = newVal.format('HH:mm');
    this.setState({
      params: campaignCustoms,
    });
  }

  onCampaignDataChange(e) {
    const newCustoms = clone(this.state.params);
    newCustoms[e.target.name] = e.target.value;

    if (e.target.name === 'language' && e.target.value !== 'SESSION_BASED') {
      this.setState(
        {
          selectedLanguage: e.target.value,
        },
        () => {
          if (this.state.twoStepEnabled) {
            this.refs.wizard.querySelector("input[name='title']").focus();
            this.refs.wizard.querySelector("input[name='title']").blur();
          }
        },
      );
    }
    if (e.target.name === 'title') {
      const tempTitles = this.state.additionalTitles;
      tempTitles[this.state.selectedLanguage] = e.target.value;
      this.setState({
        additionalTitles: tempTitles,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        params: newCustoms,
      });
    }
  }

  onValidityChange(e) {
    this.setState({
      validity: e.target.value,
    });
  }

  onEventNameChange(e) {
    this.setState({
      eventName: e.target.value,
    });
  }

  onCampaignCappingParamChange(param, value) {
    const capping = {
      capCount: '',
      frequency: '',
      types: [],
      ...this.state.capping,
    };
    capping[param] = value;
    let emptyValue = '';
    let emptyValueCount = 0;
    Object.keys(capping).forEach(cappingKey => {
      if (cappingKey !== 'types') {
        if (!capping[cappingKey]) {
          emptyValue = cappingKey;
          emptyValueCount += 1;
        }
      }
    });
    this.setState({
      capping,
      cappignError:
        emptyValue && emptyValueCount < 2
          ? 'You should fill all fields'
          : false,
    });
  }

  onSchedulingChange(values) {
    this.setState({
      periodicSelected:
        values.schedule.type === 'periodic' &&
        values.schedule.period === 'every_day',
      validity:
        values.schedule.type === 'periodic' &&
        values.schedule.period === 'every_day'
          ? '24'
          : this.state.validity,
    });
  }

  setLang() {
    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
    const { mainLanguage } = account;
    this.setState({
      selectedLanguage: mainLanguage,
    });
  }

  setCampaignDetails(campaign, campaignStatus) {
    const modifiedCampaign = clone(campaign);
    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
    const { mainLanguage } = account;
    const { params } = this.state;

    if (modifiedCampaign.type === 'PUSH_PERMISSION') {
      if (!modifiedCampaign.additionalButtonLaterText) {
        modifiedCampaign.additionalButtonLaterText = {
          [mainLanguage]: modifiedCampaign.buttonLaterText,
        };
      }
      if (!modifiedCampaign.additionalButtonAllowText) {
        modifiedCampaign.additionalButtonAllowText = {
          [mainLanguage]: modifiedCampaign.buttonAllowText,
        };
      }
    }

    this.setState(
      {
        twoStepEnabled: modifiedCampaign.twoStepEnabled,
        campaign: modifiedCampaign,
        campaignStatus,
        params: { ...params, ...modifiedCampaign.params },
        showDiscount: modifiedCampaign.params.discountRatio
          ? modifiedCampaign.params.discountRatio
          : false,
        hideInformation: modifiedCampaign.hideInformation,
        hidePrice: modifiedCampaign.hidePrice,
        language: modifiedCampaign.params.language,
        currency: modifiedCampaign.params.currency,
        validity: modifiedCampaign.validity,
        eventName: modifiedCampaign.eventName || '',
        capping: modifiedCampaign.capping || {},
        actions: modifiedCampaign.actions,
        additionalTitles:
          Object.keys(modifiedCampaign.additionalTitles).length !== 0
            ? modifiedCampaign.additionalTitles
            : {
                [mainLanguage]: modifiedCampaign.title,
              },
        additionalDescriptions:
          Object.keys(modifiedCampaign.additionalDescriptions).length !== 0
            ? modifiedCampaign.additionalDescriptions
            : {
                [mainLanguage]: modifiedCampaign.text,
              },
        additionalRedirectUrls:
          Object.keys(modifiedCampaign.additionalRedirectUrls).length !== 0
            ? modifiedCampaign.additionalRedirectUrls
            : {
                [mainLanguage]: modifiedCampaign.redirectUrl,
              },
        additionalIcons:
          Object.keys(modifiedCampaign.additionalIcons || {}).length !== 0
            ? modifiedCampaign.additionalIcons
            : {
                [mainLanguage]: modifiedCampaign.icon,
              },
        additionalImages:
          Object.keys(modifiedCampaign.additionalImages || {}).length !== 0
            ? modifiedCampaign.additionalImages
            : {
                [mainLanguage]: modifiedCampaign.image,
              },
        additionalButtonLaterText:
          modifiedCampaign.type === 'PUSH_PERMISSION'
            ? Object.keys(modifiedCampaign.additionalButtonLaterText).length !==
              0
              ? modifiedCampaign.additionalButtonLaterText
              : {
                  [mainLanguage]: modifiedCampaign.buttonLaterText,
                }
            : {},
        additionalButtonAllowText:
          modifiedCampaign.type === 'PUSH_PERMISSION'
            ? Object.keys(modifiedCampaign.additionalButtonAllowText).length !==
              0
              ? modifiedCampaign.additionalButtonAllowText
              : {
                  [mainLanguage]: modifiedCampaign.buttonAllowText,
                }
            : {},
        selectedLanguage:
          modifiedCampaign.params.language !== 'SESSION_BASED' &&
          modifiedCampaign.params.language !== undefined
            ? modifiedCampaign.params.language
            : mainLanguage,
        periodicSelected:
          modifiedCampaign.schedule &&
          modifiedCampaign.schedule.type &&
          modifiedCampaign.schedule.type === 'periodic' &&
          modifiedCampaign.schedule.period === 'every_day',
      },
      () => {
        this.updateAdditionalLanguage();
        uiActions.showBreadcrumb({
          home: {
            name: t('Web Push'),
            icon: 'pushModule',
            slug: '/push/view-all',
            iconSvg: true,
          },
          top: {
            name: getCampaignNames(this.state.campaign.type),
            slug: `/push/${getBreadcrumbNames(this.state.campaign.type)}`,
          },
          current: {
            name: modifiedCampaign.name,
          },
        });
        if (
          modifiedCampaign.recommendation &&
          modifiedCampaign.recommendation.type
        ) {
          const type = modifiedCampaign.recommendation.type.toLowerCase();
          let crValue;

          switch (type) {
            case 'brand':
              crValue =
                modifiedCampaign.recommendation.brand ||
                modifiedCampaign.recommendation.recommendationSource;
              break;
            case 'product':
              crValue = modifiedCampaign.recommendation.productId;
              break;
            case 'label':
              crValue =
                modifiedCampaign.recommendation.label ||
                modifiedCampaign.recommendation.recommendationSource;
              break;
            case 'category':
              crValue =
                modifiedCampaign.recommendation.category ||
                modifiedCampaign.recommendation.recommendationSource;
              break;
            default:
              crValue =
                modifiedCampaign.recommendation.source ||
                modifiedCampaign.recommendation.recommendationSource;
          }

          wizardActions.addCriteria([
            {
              id: Math.random(),
              type,
              isPush: true,
              values: {
                category: type === 'category' ? crValue : null,
                productId:
                  type === 'product'
                    ? {
                        value: crValue,
                      }
                    : null,
                criterion: {
                  value: crValue,
                },
                timeFrame: {
                  value: modifiedCampaign.recommendation.timeFrame,
                },
                itemCount: {
                  value: modifiedCampaign.recommendation.itemCount,
                },
              },
            },
          ]);
        }
        if (modifiedCampaign.inclusions) {
          const includes = [];
          modifiedCampaign.inclusions.forEach(item => {
            const values = {};

            Object.keys(item).forEach(key => {
              if (key !== 'type') {
                values[key] = {
                  value: item[key],
                };
              }
            });

            includes.push({
              id: Math.random(),
              type: item.type.toLowerCase(),
              values,
            });
          });
          wizardActions.addInclusion(includes);
        }
        if (modifiedCampaign.exclusions) {
          const excludes = [];
          modifiedCampaign.exclusions.forEach(item => {
            const values = {};

            Object.keys(item).forEach(key => {
              if (key !== 'type') {
                values[key] = {
                  value: item[key],
                };
              }
            });

            excludes.push({
              id: Math.random(),
              type: item.type.toLowerCase(),
              values,
            });
          });
          wizardActions.addExclusion(excludes);
        }
      },
    );
  }

  campaignDataFix = cmp => {
    const campaign = clone(cmp);
    if (campaign.type === 'PRICE_DROP') {
      if (!campaign.params.workingHour) {
        campaign.params.workingHour = '11:00';
      }
      if (!campaign.params.sendBetweenHour || !campaign.params.triggerInHours) {
        campaign.params.sendBetweenHour = '10-14';
        campaign.params.triggerInHours = '2';
      }
    }
    return campaign;
  };

  showConfigurationError = account => {
    let showErr = false;
    if (!account.pushConfiguration) {
      showErr = true;
    } else if (!account.pushConfiguration.webConfiguration) {
      showErr = true;
    } else {
      // V3-4403 => error control in bottom line changed from domain to serviceWorkerPath field for V3-4403 task
      if (!account.pushConfiguration.webConfiguration.serviceWorkerPath) {
        showErr = true;
      }
      // https://segmentify.atlassian.net/browse/V3-5348
      if (!account.pushConfiguration.defaultIcon) {
        showErr = true;
      }
    }
    if (showErr) {
      const message = `
          Service Worker Path definition must be provided while collecting
          permission from Chromium Browsers. Please complete instruction from
          Settings Page.`;
      return <ConfigurationError message={message} />;
    }
    return null;
  };

  updateStateValue = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  toggleDiscountInput() {
    this.setState({
      showDiscount: !this.state.showDiscount,
    });
  }

  campaignDetails(options) {
    let campaign;
    let campaignStatus;
    if (options && options.campaign) {
      campaign = options.campaign;
      campaignStatus = options.status === 'ACTIVE' ? 'Live' : 'Test';
      if (options.status === 'PASSIVE') {
        campaignStatus = 'Passive';
      }
      campaign = this.campaignDataFix(campaign);
      this.setCampaignDetails(campaign, campaignStatus);
    } else {
      getCampaign(this.props.campaignId).then(response => {
        campaign = response;
        campaignStatus = response.status === 'ACTIVE' ? 'Live' : 'Test';
        if (response.status === 'PASSIVE') {
          campaignStatus = 'Passive';
        }
        campaign = this.campaignDataFix(campaign);
        this.setCampaignDetails(campaign, campaignStatus);
      });
    }
  }

  changeFields(criteria) {
    if (criteria && criteria.length && this.state.campaign.type === 'FLY') {
      this.setState({
        algorithmSelected: true,
      });
    } else {
      this.setState({
        algorithmSelected: false,
      });
    }
  }

  selectLanguage(selectedLanguage) {
    this.setState(
      {
        selectedLanguage,
      },
      () => {
        this.refs.wizard.querySelector("input[name='title']").focus();
        this.refs.wizard.querySelector("input[name='title']").blur();
      },
    );
  }

  save(saveAs) {
    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
    const { campaign } = this.state;
    const campaignData = pushCampaignSpecificData[this.props.type];
    const campaignProps = {};
    const contentFields = this.refs.mainField;
    const contentExtra = this.refs.additionalFields;
    const contentOptions = this.refs.campaignOptions;
    const contentOptionAdvancedConfig = this.refs.campaignOptionsAdvancedConfig;

    const status = saveAs === 'test' ? 'TEST' : 'ACTIVE';
    let control = true;
    const languages = account.additionalLanguages.concat(account.mainLanguage);
    let firstEmptyLang = '';
    let firstDescriptionsEmptyLang = '';
    let firstRedirectURLSEmptyLang = '';
    let firstImagesEmptyLang = '';
    let firstIconsEmptyLang = '';
    let firstEmptyTitleLang = '';
    let firstAllowButtonTextEmptyLang = '';
    let firstLaterButtonTextEmptyLang = '';

    languages.forEach(language => {
      if (
        !Object.keys(this.state.additionalTitles).includes(language) &&
        this.state.language === 'SESSION_BASED'
      ) {
        if (
          this.state.campaign.type === 'PUSH_PERMISSION' &&
          this.state.twoStepEnabled === false
        ) {
          control = true;
        } else {
          control = false;
          if (this.state.campaign.type === 'PUSH_PERMISSION') {
            firstEmptyTitleLang = language;
          } else {
            firstEmptyLang = language;
          }
        }
      }
    });

    Object.keys(this.state.additionalTitles).forEach(titleKey => {
      if (
        this.state.additionalTitles[titleKey] === '' &&
        this.state.language === 'SESSION_BASED'
      ) {
        control = false;
        firstEmptyLang = titleKey;
      }
    });

    if (
      this.state.campaign.type === 'PUSH_NOTIFICATION' ||
      this.state.campaign.type === 'UPCOMING'
    ) {
      languages.forEach(language => {
        if (
          Object.keys(this.state.additionalDescriptions).indexOf(language) <
            0 &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstDescriptionsEmptyLang = language;
        }
      });

      Object.keys(this.state.additionalDescriptions).forEach(descriptionKey => {
        if (
          this.state.additionalDescriptions[descriptionKey] === '' &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstDescriptionsEmptyLang = descriptionKey;
        }
      });

      languages.forEach(language => {
        if (
          Object.keys(this.state.additionalIcons).indexOf(language) < 0 &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstIconsEmptyLang = language;
        }
      });

      Object.keys(this.state.additionalIcons).forEach(iconKey => {
        if (
          this.state.additionalIcons[iconKey] === '' &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstIconsEmptyLang = iconKey;
        }
      });

      languages.forEach(language => {
        if (
          Object.keys(this.state.additionalImages).indexOf(language) < 0 &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstImagesEmptyLang = language;
        }
      });

      Object.keys(this.state.additionalImages).forEach(imageKey => {
        if (
          this.state.additionalImages[imageKey] === '' &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstImagesEmptyLang = imageKey;
        }
      });

      languages.forEach(language => {
        if (
          Object.keys(this.state.additionalRedirectUrls).indexOf(language) <
            0 &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstRedirectURLSEmptyLang = language;
        }
      });

      Object.keys(this.state.additionalRedirectUrls).forEach(urlKey => {
        if (
          this.state.additionalRedirectUrls[urlKey] === '' &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstRedirectURLSEmptyLang = urlKey;
        }
      });
    }

    if (
      this.state.twoStepEnabled === true &&
      this.state.campaign.type === 'PUSH_PERMISSION'
    ) {
      languages.forEach(language => {
        if (
          Object.keys(this.state.additionalDescriptions).indexOf(language) <
            0 &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstDescriptionsEmptyLang = language;
        }
      });

      Object.keys(this.state.additionalDescriptions).forEach(descriptionKey => {
        if (
          this.state.additionalDescriptions[descriptionKey] === '' &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstDescriptionsEmptyLang = descriptionKey;
        }
      });

      languages.forEach(language => {
        if (
          Object.keys(this.state.additionalIcons).indexOf(language) < 0 &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstIconsEmptyLang = language;
        }
      });

      Object.keys(this.state.additionalIcons).forEach(iconKey => {
        if (
          this.state.additionalIcons[iconKey] === '' &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstIconsEmptyLang = iconKey;
        }
      });

      languages.forEach(language => {
        if (
          Object.keys(this.state.additionalButtonLaterText).indexOf(language) <
            0 &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstLaterButtonTextEmptyLang = language;
        }
      });

      Object.keys(this.state.additionalButtonLaterText).forEach(buttonKey => {
        if (
          this.state.additionalButtonLaterText[buttonKey] === '' &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstLaterButtonTextEmptyLang = buttonKey;
        }
      });

      languages.forEach(language => {
        if (
          Object.keys(this.state.additionalButtonAllowText).indexOf(language) <
            0 &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstAllowButtonTextEmptyLang = language;
        }
      });

      Object.keys(this.state.additionalButtonAllowText).forEach(buttonKey => {
        if (
          this.state.additionalButtonAllowText[buttonKey] === '' &&
          this.state.language === 'SESSION_BASED'
        ) {
          control = false;
          firstAllowButtonTextEmptyLang = buttonKey;
        }
      });
    }

    if (control) {
      if (typeof contentFields !== 'undefined')
        Object.keys(contentFields.refs).forEach(ref => {
          campaignProps[ref] = contentFields.refs[ref];
        });

      if (this.props.additionalFields) {
        Object.keys(contentExtra.refs).forEach(ref => {
          campaignProps[ref] = contentExtra.refs[ref];
        });
      }

      Object.keys(contentOptions.refs).forEach(ref => {
        campaignProps[ref] = contentOptions.refs[ref];
      });

      Object.keys(contentOptionAdvancedConfig.refs).forEach(ref => {
        campaignProps[ref] = contentOptionAdvancedConfig.refs[ref];
      });

      campaign.name = campaignProps.name.state.value.trim();
      if (campaignProps.recommendations) {
        campaign.ordering = campaignProps.recommendations.state.shuffle
          ? 'SHUFFLE'
          : 'GIVEN_ORDER';
      }
      campaign.status = status;
      campaign.devices = [];
      if (campaignProps.devices) {
        campaign.devices = campaignProps.devices.state.value.filter(
          device => device !== 'ALL',
        );
      }
      if (campaignProps.startEndDate) {
        campaign.startDate = campaignProps.startEndDate.state.values.startDate;
        if (campaignProps.startEndDate.state.showEndDate) {
          campaign.endDate = campaignProps.startEndDate.state.values.endDate;
        } else {
          campaign.endDate = '';
        }
      } else {
        campaign.startDate = this.state.campaign.startDate;
        campaign.endDate = '';
      }
      campaign.note = '';

      if (campaignProps.timingOptions) {
        Object.keys(campaignProps.timingOptions.state.values).forEach(key => {
          let timingType = campaignProps.timingOptions.state.values[key].type;

          if (
            campaignProps.timingOptions.state.values[key].type === 'DELAY' &&
            campaignProps.timingOptions.state.delayAllPages
          ) {
            timingType = 'DELAY_ALL_PAGES';
          }

          campaign[key] = {
            type: timingType,
            param: campaignProps.timingOptions.state.values[key].param,
          };
        });
      }

      campaign.filters = [];

      if (campaignProps.pages) {
        if (campaignProps.pages.state.value.indexOf('All') !== -1) {
          campaignProps.pages.state.value = [];
        }

        campaign.filters.push({
          type: 'PAGE',
          includedCategories: campaignProps.pages.state.value,
          excludedCategories: [],
        });
      }

      if (campaignProps.audienceOptions) {
        campaignProps.audienceOptions.state.values.segment.forEach(segment => {
          campaign.filters.push({
            type: 'SEGMENT',
            segment: segment.value,
            include: segment.member,
          });
        });

        campaignProps.audienceOptions.state.values.visitor.forEach(visitor => {
          campaign.filters.push({
            type: 'VISITOR',
            name: visitor.value,
            include: visitor.member,
          });
        });
      }

      // Segmentation Campaign Variable
      if (campaignProps.segmentationOptions) {
        Object.keys(campaignProps.segmentationOptions.state.values).forEach(
          key => {
            campaign[key] = campaignProps.segmentationOptions.state.values[key];
          },
        );
      }

      if (this.refs.schedule) {
        const { schedule } = this.refs;
        campaign.schedule = schedule.state.values.schedule;
        campaign.schedule.date = convertToEpochTime(campaign.schedule.date);
      }

      if (this.refs.audience) {
        const { audience } = this.refs;
        campaign.filters = audience.state.filters
          .filter(filter => {
            return filter.segment && filter.type;
          })
          .map(filter => {
            return {
              type: filter.type,
              segment: filter.segment,
              include: filter.include,
            };
          });
        if (campaign.filters.length > 1) {
          campaign.filterOperator = audience.state.operator;
        }
      }

      // loop through the campaign specific options
      if (this.state.campaign.type === 'PUSH_PERMISSION') {
        if (campaignProps.popupOption) {
          if (campaignProps.popupOption.state.popupOption) {
            const subFields = campaignProps.popupOption.refs.itemFields.refs;
            const { imgSrc, additionalIcons } = subFields.iconWithUpload.state;
            const buttons =
              campaignProps.popupOption.refs.itemFields.refs.confirmButtons
                .state.values;
            campaign.title =
              this.state.additionalTitles[account.mainLanguage] ||
              subFields.pushPermissionTitle.state.value;
            campaign.text =
              this.state.additionalDescriptions[account.mainLanguage] ||
              subFields.pushDescription.state.value;
            campaign.icon = additionalIcons[account.mainLanguage] || imgSrc;
            campaign.buttonAllowText =
              this.state.additionalButtonAllowText[account.mainLanguage] ||
              buttons.buttonAllowText;
            campaign.buttonLaterText =
              this.state.additionalButtonLaterText[account.mainLanguage] ||
              buttons.buttonLaterText;
            campaign.additionalDescriptions = this.state.additionalDescriptions;
            campaign.additionalTitles = this.state.additionalTitles;
            campaign.additionalButtonLaterText = this.state.additionalButtonLaterText;
            campaign.additionalButtonAllowText = this.state.additionalButtonAllowText;
            campaign.additionalIcons = this.state.additionalIcons;
          }
        }
      } else {
        campaign.additionalTitles = this.state.additionalTitles;
        campaign.title = this.state.additionalTitles[account.mainLanguage];

        if (
          this.state.campaign.type === 'PUSH_NOTIFICATION' ||
          this.state.campaign.type === 'UPCOMING'
        ) {
          campaign.additionalDescriptions = this.state.additionalDescriptions;
          campaign.additionalIcons = this.state.additionalIcons;
          campaign.additionalImages = this.state.additionalImages;
          campaign.text = this.state.additionalDescriptions[
            account.mainLanguage
          ];
          campaign.additionalRedirectUrls = this.state.additionalRedirectUrls;
          campaign.redirectUrl = this.state.additionalRedirectUrls[
            account.mainLanguage
          ];
        }
        if (campaignProps.text) {
          campaign.text = campaignProps.text.state.value;
        }
        if (this.state.campaign.type !== 'PUSH_PERMISSION') {
          campaign.actions = campaignProps.actionButtons.state.actions;
        }
        if (campaignProps.text) {
          campaign.text = campaignProps.text.state.value;
        }

        if (campaignProps.iconWithUpload) {
          const {
            method,
            imgSrc,
            additionalIcons,
          } = campaignProps.iconWithUpload.state;
          if (this.state.campaign.type === 'FLY') {
            if (method === 'Product Image' && this.state.algorithmSelected) {
              campaign.icon = '{{product:icon}}';
            } else if (method === 'Company Logo') {
              campaign.icon = '{{push:icon}}';
            } else {
              campaign.icon = imgSrc;
            }
          } else {
            campaign.icon = additionalIcons[account.mainLanguage] || imgSrc;
          }
          campaign.additionalIcons = additionalIcons;
        }

        if (campaignProps.imageWithUpload) {
          const {
            method,
            imgSrc,
            additionalImages,
          } = campaignProps.imageWithUpload.state;
          if (this.state.campaign.type === 'FLY') {
            if (method === 'Product Image' && this.state.algorithmSelected) {
              campaign.image = '{{product:image}}';
            } else {
              campaign.image = imgSrc;
            }
          } else {
            const mainImage = additionalImages[account.mainLanguage] || imgSrc;
            campaign.image = mainImage === '{{no:image}}' ? '' : mainImage;
          }
          const clearedAdditionalImagesFromNoImage = {};
          Object.keys(additionalImages).forEach(lang => {
            if (additionalImages[lang] !== '{{no:image}}') {
              clearedAdditionalImagesFromNoImage[lang] = additionalImages[lang];
            } else {
              clearedAdditionalImagesFromNoImage[lang] = '';
            }
          });
          campaign.additionalImages = clearedAdditionalImagesFromNoImage;
        }

        if (campaignProps.redirectUrl && !this.state.algorithmSelected) {
          campaign.redirectUrl = campaignProps.redirectUrl.state.value;
        } else {
          campaign.redirectUrl = '';
        }

        campaign.validity = this.state.validity;

        if (
          this.state.campaign.type !== 'PUSH_PERMISSION' &&
          this.state.campaign.type !== 'FLY' &&
          this.state.campaign.type !== 'PUSH_NOTIFICATION' &&
          this.state.campaign.type !== 'UPCOMING'
        ) {
          campaign.hideInformation =
            campaignProps.hideInformation?.state.hideInformation || false;
          campaign.hidePrice = campaignProps.hidePrice.state.hidePrice;
        }

        if (
          campaignProps.hideInformation?.state.hideInformation &&
          campaignProps.imageWithUpload.state.method === 'Product Image'
        ) {
          campaign.image = '';
        }

        if (this.state.campaign.type === 'UPCOMING') {
          campaign.hidePrice = true;
        }
      }

      campaignData.forEach(dataName => {
        let data;
        if (typeof dataName === 'object') {
          if (typeof campaignProps[dataName[0]] !== 'undefined') {
            data = campaignProps[dataName[0]].state.values;
            if (dataName[1]) {
              dataName[1].forEach(singleDataName => {
                campaign[singleDataName] = data[singleDataName];
              });
            }
          }
        } else {
          const [recommendation] = personalizationRecommendations(
            this.props.criteria,
          );
          campaign.recommendation = recommendation;
          campaign.exclusions = personalizationRecommendationExclusions(
            this.props.excludes,
          );
          campaign.inclusions = personalizationRecommendationExclusions(
            this.props.includes,
          );
        }
      });

      campaign.params = this.state.params;
      campaign.params.title = this.state.additionalTitles[account.mainLanguage];

      if (
        this.state.campaign.type === 'ABANDONED_CART' ||
        this.state.campaign.type === 'LAST_VISIT_ALTERNATIVES' ||
        this.state.campaign.type === 'LAST_VISIT_REMINDER' ||
        this.state.campaign.type === 'UPCOMING' ||
        this.state.campaign.type === 'PRICE_DROP'
      ) {
        let capping = { ...this.state.capping };
        Object.keys(capping).forEach(key => {
          if (capping && !capping[key]) {
            capping = null;
          }
        });
        campaign.capping =
          capping && Object.keys(capping).length > 0 ? capping : null;
      }

      if (this.state.campaign.type === 'PRICE_DROP') {
        if (this.state.params.sendingPeriod === 'every_day') {
          delete campaign.params.sendBetweenHour;
          delete campaign.params.triggerInHours;
        } else if (this.state.params.sendingPeriod === 'every_hour') {
          delete campaign.params.workingHour;
        }
      }

      if (this.state.eventName) {
        campaign.eventName = this.state.eventName;
      }

      campaign.lastLiveDate = this.state.campaign.lastLiveDate || null;

      if (!this.props.isReplicate) {
        Object.keys(campaign.additionalTitles).forEach(lang => {
          if (lang === 'undefined') {
            delete campaign.additionalTitles[lang];
          }
        });

        Object.keys(campaign.additionalDescriptions).forEach(lang => {
          if (lang === 'undefined') {
            delete campaign.additionalDescriptions[lang];
          }
        });

        Object.keys(campaign.additionalIcons).forEach(lang => {
          if (lang === 'undefined') {
            delete campaign.additionalIcons[lang];
          }
        });

        Object.keys(campaign.additionalImages).forEach(lang => {
          if (lang === 'undefined') {
            delete campaign.additionalImages[lang];
          }
        });

        Object.keys(campaign.additionalRedirectUrls).forEach(lang => {
          if (lang === 'undefined') {
            delete campaign.additionalRedirectUrls[lang];
          }
        });

        if (campaign.type === 'PUSH_PERMISSION') {
          Object.keys(campaign.additionalButtonLaterText).forEach(lang => {
            if (lang === 'undefined') {
              delete campaign.additionalButtonLaterText[lang];
            }
          });

          Object.keys(campaign.additionalButtonAllowText).forEach(lang => {
            if (lang === 'undefined') {
              delete campaign.additionalButtonAllowText[lang];
            }
          });
        }

        if (!campaign.instanceId) {
          campaign.instanceId = this.props.campaignId;
        }

        if (campaign.type === 'REPLENISHMENT') {
          campaign.recommendation.source =
            'RECOMMENDATION_REPLENISHMENT_PRODUCTS';
        }

        campaign.twoStepEnabled = this.state.twoStepEnabled;

        wizardActions.saveReset();

        editCampaign(campaign)
          .then(() => {
            let note = () => (
              <div>
                Push notification campaign is saved to drafts successfully.
              </div>
            );
            if (status !== 'TEST') {
              note = () => (
                <div>Push notification campaign is activated successfully.</div>
              );
            }
            uiActions.showNotification({
              content: note,
            });
            this.campaignDetails({
              campaign,
              status,
            });
          })
          .catch(response => {
            const error = () => <p>{response.response.message}</p>;

            uiActions.showNotification({
              content: error,
              className: 'notification-fail',
            });
            wizardActions.wizardReset();
          });
      } else {
        Object.keys(campaign.additionalTitles).forEach(lang => {
          if (lang === 'undefined') {
            delete campaign.additionalTitles[lang];
          }
        });

        Object.keys(campaign.additionalDescriptions).forEach(lang => {
          if (lang === 'undefined') {
            delete campaign.additionalDescriptions[lang];
          }
        });

        Object.keys(campaign.additionalIcons).forEach(lang => {
          if (lang === 'undefined') {
            delete campaign.additionalIcons[lang];
          }
        });

        Object.keys(campaign.additionalImages).forEach(lang => {
          if (lang === 'undefined') {
            delete campaign.additionalImages[lang];
          }
        });

        Object.keys(campaign.additionalRedirectUrls).forEach(lang => {
          if (lang === 'undefined') {
            delete campaign.additionalRedirectUrls[lang];
          }
        });

        if (campaign.type === 'PUSH_PERMISSION') {
          Object.keys(campaign.additionalButtonLaterText).forEach(lang => {
            if (lang === 'undefined') {
              delete campaign.additionalButtonLaterText[lang];
            }
          });
          Object.keys(campaign.additionalButtonAllowText).forEach(lang => {
            if (lang === 'undefined') {
              delete campaign.additionalButtonAllowText[lang];
            }
          });
        }
        addCampaign(campaign)
          .then(response => {
            const instance = response.instanceId;
            campaign.instanceId = instance;
            let note = () => {
              return (
                <div>
                  Push notification campaign is saved to drafts successfully.
                </div>
              );
            };
            if (response.status === 'ACTIVE') {
              note = () => {
                return (
                  <div>
                    Push notification campaign is activated successfully.
                  </div>
                );
              };
            }
            uiActions.showNotification({
              content: note,
            });
            wizardActions.saveReset();
            this.setState({
              campaignStatus:
                response.status === 'ACTIVE'
                  ? 'live'
                  : response.status.toLowerCase(),
            });
            if (window.location.pathname.indexOf('/ext/push') >= 0) {
              browserHistory.push(
                `/ext/push/edit/${campaign.type}/${campaign.instanceId}`,
              );
            } else {
              browserHistory.push(
                `/push/edit/${campaign.type}/${campaign.instanceId}`,
              );
            }
          })
          .catch(() => {});
      }
    } else if (firstEmptyLang) {
      this.setState(
        {
          selectedLanguage: firstEmptyLang,
        },
        () => {
          this.refs.wizard.querySelector("input[name='title']").focus();
          this.refs.wizard.querySelector("input[name='title']").blur();
          wizardActions.saveReset();
        },
      );
    } else if (firstDescriptionsEmptyLang) {
      this.setState(
        {
          selectedLanguage: firstDescriptionsEmptyLang,
        },
        () => {
          this.refs.wizard
            .querySelector("textarea[name='pushDescription']")
            .focus();
          this.refs.wizard
            .querySelector("textarea[name='pushDescription']")
            .blur();
          wizardActions.saveReset();
        },
      );
    } else if (firstRedirectURLSEmptyLang) {
      this.setState(
        {
          selectedLanguage: firstRedirectURLSEmptyLang,
        },
        () => {
          this.refs.wizard.querySelector("input[name='redirectUrl']").focus();
          this.refs.wizard.querySelector("input[name='redirectUrl']").blur();
          wizardActions.saveReset();
        },
      );
    } else if (firstImagesEmptyLang) {
      this.setState(
        {
          selectedLanguage: firstImagesEmptyLang,
        },
        () => {
          this.refs.wizard.querySelector('.image-with-upload').scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
          wizardActions.saveReset();
        },
      );
    } else if (firstIconsEmptyLang) {
      this.setState(
        {
          selectedLanguage: firstIconsEmptyLang,
        },
        () => {
          this.refs.wizard.querySelector('.icon-with-upload').scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
          wizardActions.saveReset();
        },
      );
    }
    // permission
    else if (firstEmptyTitleLang) {
      this.setState(
        {
          selectedLanguage: firstEmptyTitleLang,
        },
        () => {
          this.refs.wizard.querySelector("input[name='title']").focus();
          this.refs.wizard.querySelector("input[name='title']").blur();
          wizardActions.saveReset();
        },
      );
    } else if (firstAllowButtonTextEmptyLang) {
      this.setState(
        {
          selectedLanguage: firstAllowButtonTextEmptyLang,
        },
        () => {
          this.refs.wizard
            .querySelector("input[name='buttonLaterText']")
            .focus();
          this.refs.wizard
            .querySelector("input[name='buttonLaterText']")
            .blur();
          wizardActions.saveReset();
        },
      );
    } else if (firstLaterButtonTextEmptyLang) {
      this.setState(
        {
          selectedLanguage: firstLaterButtonTextEmptyLang,
        },
        () => {
          this.refs.wizard
            .querySelector("input[name='buttonAllowText']")
            .focus();
          this.refs.wizard
            .querySelector("input[name='buttonAllowText']")
            .blur();
          wizardActions.saveReset();
        },
      );
    }
    // permission
  }

  renderCampaignFields = langList => (
    <>
      {langList.length > 2 && (
        <LanguageChooser
          selectedLanguage={this.state.selectedLanguage}
          langList={langList}
          onSelectLanguage={this.selectLanguage}
          language={this.state.language}
        />
      )}
      {this.state.campaign.type !== 'PUSH_PERMISSION' && (
        <TextField
          name='title'
          label='Title'
          placeholder={
            this.state.campaign.type !== 'UPCOMING' &&
            this.state.campaign.type !== 'PUSH_NOTIFICATION'
              ? 'You can add {{product:name}} to the title to send the product name in push notification.'
              : ''
          }
          className='item-stacked one-whole'
          value={this.state.additionalTitles[this.state.selectedLanguage] || ''}
          required
          tooltipText={t('Prepare the titles of your push')}
          tooltip
          onChange={this.onCampaignDataChange}
          ref='title'
          isPush
          renderProp={
            this.state.campaign.type !== 'PUSH_NOTIFICATION' &&
            this.state.campaign.type !== 'FLY' &&
            this.state.campaign.type !== 'RECOMMENDATION' &&
            this.state.campaign.type !== 'REPLENISHMENT' &&
            !this.props.isPreview &&
            (() => (
              <div
                className='sample-text'
                onClick={this.onSampleTextSelect.bind(this, 'subject')}
              >
                <Icons name='exampleText' width='14' height='16' color='#fff' />{' '}
                <span>{t('Select sample text')}</span>
              </div>
            ))
          }
        />
      )}
      <Fields
        ref='mainField'
        campaign={this.state.campaign}
        comps={this.props.mainField}
        campaignType={this.props.type}
        disableFields={this.state.disableFields}
        algorithmSelected={this.state.algorithmSelected}
        selectedLanguage={this.state.selectedLanguage}
        additionalTitles={this.state.additionalTitles}
        additionalRedirectUrls={this.state.additionalRedirectUrls}
        additionalDescriptions={this.state.additionalDescriptions}
        additionalIcons={this.state.additionalIcons}
        additionalImages={this.state.additionalImages}
        additionalButtonLaterText={this.state.additionalButtonLaterText}
        additionalButtonAllowText={this.state.additionalButtonAllowText}
        updateStateValue={this.updateStateValue}
      />
    </>
  );

  updateAdditionalLanguage() {
    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;

    const { mainLanguage } = account;
    const langList =
      account.additionalLanguages &&
      account.additionalLanguages.indexOf(account.mainLanguage) > -1
        ? account.additionalLanguages
        : [account.mainLanguage].concat(account.additionalLanguages);

    if (
      this.state.campaign.type === 'PUSH_PERMISSION' ||
      this.state.campaign.type === 'RECOMMENDATION' ||
      this.state.campaign.type === 'FLY'
    ) {
      return;
    }

    uiActions.isLoading();
    getSampleContent(this.state.campaign.instanceId, samples => {
      uiActions.isLoaded();

      const additionalTitles = {};

      langList.forEach(item => {
        const stateValue = this.state.additionalTitles[item];
        const contentList = samples[item] && samples[item].titleList[0];
        const mainContentList =
          samples[mainLanguage] && samples[mainLanguage].titleList[0];

        if (stateValue) {
          additionalTitles[item] = stateValue;
          return;
        }

        if (contentList) {
          additionalTitles[item] = contentList;
          return;
        }

        if (mainContentList) {
          additionalTitles[item] = mainContentList;
          return;
        }

        additionalTitles[item] = '';
      });

      this.setState({
        additionalTitles,
      });
    });
  }

  openSampleModal() {
    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
    const { mainLanguage } = account;

    let sampleContent = this.state.contentSamples;
    if (this.state.selectedLanguage in sampleContent) {
      sampleContent = sampleContent[this.state.selectedLanguage].titleList;
    } else if (mainLanguage in sampleContent) {
      sampleContent = sampleContent[mainLanguage].titleList;
    } else if (this.state.campaign.instanceId === 'LAST_VISIT_REMINDER') {
      sampleContent = sampleContent.EN.titleList;
    } else {
      sampleContent = [];
    }

    const sampleContentModal = () => (
      <SampleContentModal
        content={sampleContent}
        onSelect={this.onSelectSampleContent}
        isReady
      />
    );

    modalActions.openModal({
      title: 'Select Push Content',
      content: sampleContentModal,
    });
  }

  render() {
    const containerStyle =
      window.location.pathname.indexOf('ext/push') >= 0
        ? { 'padding-left': '140px' }
        : {};
    const advancedOption = [];
    const allOptions = [];

    // eslint-disable-next-line no-unused-expressions
    this.props.config?.forEach(option => {
      if (option.field === 'advancedConfig') {
        advancedOption.push(option);
      } else {
        allOptions.push(option);
      }
    });

    let formClass =
      this.state.campaign.type !== 'RECOMMENDATION' &&
      this.state.campaign.type !== 'FLY' &&
      this.state.campaign.type !== 'NEW_COMERS'
        ? 'wizard wizard-push-fields wizard-push-disabled-fields'
        : 'wizard wizard-push-fields';

    if (this.state.campaign.type === 'FLY') {
      formClass += ' wizard-push-fields-fly';
    }

    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
    const mainLanguage = this.state.language || account.mainLanguage;
    const mainCurrency = this.state.currency || account.mainCurrency.code;
    const language =
      this.state.selectedLanguage !== undefined
        ? this.state.selectedLanguage
        : account.mainLanguage;

    let langList =
      account.additionalLanguages &&
      account.additionalLanguages.indexOf(account.mainLanguage) > -1
        ? account.additionalLanguages
        : [account.mainLanguage].concat(account.additionalLanguages);
    langList = langList.map(lang => {
      return { value: lang, label: lang };
    });
    langList.push({ value: 'SESSION_BASED', label: 'Session based' });
    let currencyList =
      account.additionalCurrencyList &&
      account.additionalCurrencyList.indexOf(account.mainCurrency.code) > -1
        ? account.additionalCurrencyList
        : [account.mainCurrency.code].concat(account.additionalCurrencyList);
    currencyList = currencyList.map(currency => {
      return { value: currency, label: currency };
    });
    currencyList.push({ value: 'SESSION_BASED', label: 'Session based' });
    return (
      <form
        style={containerStyle}
        id='wizard'
        ref='wizard'
        className={formClass}
      >
        <h3 className='page-title'>
          {!this.props.isReplicate && (
            <span
              className={`status-label status-label-${this.state.campaignStatus.toLowerCase()}`}
            >
              {this.state.campaignStatus.toLocaleLowerCase() === 'test'
                ? 'Draft'
                : this.state.campaignStatus}{' '}
            </span>
          )}
          {this.props.title}
        </h3>
        {this.state.campaign.type === 'PUSH_PERMISSION' &&
          this.showConfigurationError(account)}
        <div
          className='widget page-content-block'
          style={{ background: 'transparent', paddingTop: '0' }}
        >
          <ol className='form-elements wizard-comp'>
            <li>
              {this.props.type === pushTypes.PUSH_PERMISSION ? (
                <PushPermissionPrompt
                  isSwitchedOn={this.state.twoStepEnabled}
                  onToggle={this.onPermissionPromptToggle}
                >
                  {this.renderCampaignFields(langList)}
                </PushPermissionPrompt>
              ) : (
                this.renderCampaignFields(langList)
              )}
            </li>
            {this.props.additionalFields &&
              Object.keys(this.props.additionalFields).length && (
                <li className='wizard-comp'>
                  <ol className='form-elements'>
                    <li className='wizard-perso-fields'>
                      <Fields
                        ref='additionalFields'
                        campaign={this.state.campaign}
                        comps={this.props.additionalFields.fields}
                        campaignType={this.props.type}
                        selectedLanguage={this.state.selectedLanguage}
                        additionalTitles={this.state.additionalTitles}
                        additionalRedirectUrls={
                          this.state.additionalRedirectUrls
                        }
                        additionalDescriptions={
                          this.state.additionalDescriptions
                        }
                        additionalButtonLaterText={
                          this.state.additionalButtonLaterText
                        }
                        additionalButtonAllowText={
                          this.state.additionalButtonAllowText
                        }
                      />
                    </li>
                  </ol>
                </li>
              )}
          </ol>
          {this.props.mutualFields &&
            (this.props.mutualFields.indexOf('scheduling') > -1 ||
              this.props.mutualFields.indexOf('target') > -1) && (
              <MPExtraContainer
                isPush
                title={
                  this.props.mutualFields.length === 1 &&
                  this.props.mutualFields.indexOf('target') > -1
                    ? t('Target')
                    : undefined
                }
              >
                {this.props.mutualFields.indexOf('scheduling') > -1 && (
                  <MPScheduling
                    isPush
                    // TODO: workaround, this param(pushType) should be handle also from route
                    isBulk={this.props.title.toLowerCase().includes('bulk')}
                    schedule={this.state.campaign.schedule}
                    ref='schedule'
                    isReplicate={this.props.isReplicate}
                    isPreview={this.props.isPreview}
                    onDateChange={this.onSchedulingChange}
                  />
                )}
                {this.props.mutualFields.indexOf('target') > -1 &&
                  (hasModule('segmentation') ||
                    hasModule('personalization') ||
                    hasModule('ruleBasedSegmentation')) && (
                    <MPAudience
                      isPush
                      audience={this.state.campaign.filters}
                      ref='audience'
                      operator={this.state.campaign.filterOperator}
                    />
                  )}
              </MPExtraContainer>
            )}
          {(this.state.campaign.type === 'ABANDONED_CART' ||
            this.state.campaign.type === 'LAST_VISIT_ALTERNATIVES' ||
            this.state.campaign.type === 'LAST_VISIT_REMINDER' ||
            this.state.campaign.type === 'PRICE_DROP' ||
            this.state.campaign.type === 'UPCOMING') && (
            <div>
              <MailCapping
                campaignCapping={this.state.capping}
                onCampaignCappingParamChange={this.onCampaignCappingParamChange}
                errorText={this.state.cappignError}
                scenarioName={this.state.campaign.name}
                isPush
              />
            </div>
          )}
        </div>
        <div className='wizard-options email-wizard-option'>
          <ol className='form-elements'>
            <label className='item-head-title' style={{ oveflow: 'hidden' }}>
              {t('Campaign Name')}
            </label>
            <li>
              <Fields
                ref='campaignOptions'
                campaign={this.state.campaign}
                comps={allOptions}
                campaignType={this.props.type}
              />
            </li>
            {this.state.campaign.type !== 'PUSH_PERMISSION' && (
              <label className='item-head-title'>
                {t('Campaign Details')}
                <Tooltip
                  content={t('Determine campaign based configurations')}
                  alignment='right'
                  placement='bottom'
                >
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              </label>
            )}
            {this.props.additionalConfig &&
              Object.keys(this.props.additionalConfig).map(custom => {
                // Hide non-sidebar params and localization params
                if (
                  paramsInWizard.indexOf(custom) < 0 &&
                  Object.keys(localizationParams).indexOf(custom) < 0
                ) {
                  if (paramsTypes[custom].type === 'integer') {
                    if (
                      this.state.params.sendingPeriod !== 'every_hour' &&
                      custom !== 'workingDay' &&
                      custom !== 'frequency'
                    )
                      return null;
                    if (paramsTypes[custom].max) {
                      const start = paramsTypes[custom].min;
                      const end = paramsTypes[custom].max;
                      const list = [];
                      for (let i = start; i <= end; i += 1) {
                        list.push(i);
                      }
                      return (
                        <label className='item item-stacked one-whole label-emphasise is-select label-mail'>
                          <span className='item-label'>
                            {t(paramsTypes[custom].label)}
                            <Tooltip
                              content={t(paramsTypes[custom].tooltip)}
                              alignment='right'
                              placement='bottom'
                            >
                              <i className='icon-info' role='presentation'>
                                <span className='for-screenreader-only' />
                              </i>
                            </Tooltip>
                          </span>
                          <select
                            defaultValue={this.state.params[custom]}
                            value={this.state.params[custom]}
                            className='one-whole criteria-field'
                            onChange={this.onCustomParamChange}
                            name={custom}
                          >
                            {list.map(item => {
                              return (
                                <option
                                  key={item}
                                  value={
                                    custom === 'workingDay'
                                      ? parseInt(item)
                                      : item
                                  }
                                >
                                  {custom === 'workingDay'
                                    ? t(days[item - 1])
                                    : item}
                                </option>
                              );
                            })}
                          </select>
                        </label>
                      );
                    }
                    // https://segmentify.atlassian.net/browse/V3-5084
                    // hidden for now (24.11.2020)
                    if (
                      this.state.campaign.type !== 'NEW_COMERS' &&
                      this.state.campaign.type !== 'TOP_SELLERS'
                    ) {
                      return (
                        <span
                          key={custom}
                          className='label-mail label-text-field'
                        >
                          <TextField
                            name={custom}
                            label={t(paramsTypes[custom].label)}
                            className={`item-stacked one-whole label-emphasise ${
                              this.state.campaign.type === 'REPLENISHMENT'
                                ? 'sending-time-field'
                                : ''
                            }`}
                            value={this.state.params[custom]}
                            required
                            tooltipText={t(paramsTypes[custom].tooltip)}
                            tooltipPlacement='bottom'
                            tooltip
                            type='number'
                            onChange={this.onCustomParamChange}
                            renderProp={
                              this.state.campaign.type === 'REPLENISHMENT' &&
                              (() => (
                                <div className='sending-time-description'>
                                  <span>days before frequency</span>
                                </div>
                              ))
                            }
                          />
                        </span>
                      );
                    }
                  } else if (paramsTypes[custom]?.type === 'select') {
                    const periodList = [
                      { label: 'Daily', value: 'every_day' },
                      { label: 'Hourly', value: 'every_hour' },
                    ];

                    return (
                      <label className='item item-stacked one-whole label-emphasise is-select label-mail label-interval'>
                        <span className='item-label'>
                          {t(paramsTypes[custom].label)}
                          <Tooltip
                            content={t(paramsTypes[custom].tooltip)}
                            alignment='left'
                          >
                            <i className='icon-info' role='presentation'>
                              <span className='for-screenreader-only' />
                            </i>
                          </Tooltip>
                        </span>
                        <select
                          defaultValue={this.state.params[custom]}
                          value={this.state.params[custom]}
                          className='one-whole criteria-field'
                          onChange={this.onCustomParamChange}
                          name={custom}
                        >
                          {periodList.map(item => {
                            return (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                      </label>
                    );
                  } else if (paramsTypes[custom].type === 'timepicker') {
                    if (!this.state.params[custom]) return false;
                    const constraint = { minutes: { step: 15 } };
                    let val = this.state.params[custom];
                    if (val.length < 3) {
                      val += ':00';
                    }
                    const functionName = `on${paramsBasedFunctionName(
                      custom,
                    )}Change`;
                    if (this.state.params.sendingPeriod !== 'every_hour') {
                      return (
                        <>
                          {this.state.campaign.instanceId && (
                            <TimeAlert
                              getter={getActivePushCampaigns}
                              refElem={this.workingHourRef}
                              hour={this.state.params.workingHour}
                              instanceId={this.state.campaign.instanceId}
                            />
                          )}
                          <label
                            className={`item item-stacked ${
                              custom === 'delay' ? 'item-inline' : 'label-mail'
                            } one-whole label-emphasise is-select`}
                            ref={this.workingHourRef}
                          >
                            <span className='item-label'>
                              {t(paramsTypes[custom].label)}
                              <Tooltip
                                content={t(paramsTypes[custom].tooltip)}
                                alignment='left'
                                placement='bottom'
                              >
                                <i className='icon-info' role='presentation'>
                                  <span className='for-screenreader-only' />
                                </i>
                              </Tooltip>
                            </span>
                            <Calendar
                              name='date'
                              className={`item-field small 
                              ${custom === 'delay' && 'label-mail'}`}
                              value={val}
                              dateFormat={false}
                              timeConstraints={constraint}
                              onChange={this[functionName]}
                            />
                            {custom === 'delay' && 'later'}
                          </label>
                        </>
                      );
                    }
                    return null;
                  } else if (paramsTypes[custom].type === 'text') {
                    if (custom === 'discountRatio') {
                      return (
                        <span
                          key={custom}
                          className='label-mail label-text-field'
                        >
                          <label className='item item-stacked is-checkbox'>
                            <input
                              type='checkbox'
                              checked={this.state.showDiscount}
                              onChange={this.toggleDiscountInput}
                            />
                            <span className='item-label'>
                              {t(paramsTypes[custom].label)}
                              <Tooltip
                                content={t(paramsTypes[custom].tooltip)}
                                alignment='left'
                              >
                                <i className='icon-info' role='presentation'>
                                  <span className='for-screenreader-only' />
                                </i>
                              </Tooltip>
                            </span>

                            {this.state.showDiscount && (
                              <span className='label-mail label-text-field'>
                                <TextField
                                  name='discountRatio'
                                  value={this.state.params[custom]}
                                  className='item-stacked one-whole label-emphasise'
                                  required={false}
                                  type='text'
                                  onChange={this.onCustomParamChange}
                                />
                              </span>
                            )}
                          </label>
                        </span>
                      );
                    }
                    return (
                      <span
                        key={custom}
                        className='label-mail label-text-field'
                      >
                        <TextField
                          name={custom}
                          label={t(paramsTypes[custom].label)}
                          className='item-stacked one-whole label-emphasise'
                          value={this.state.params[custom]}
                          required
                          tooltipText={t(paramsTypes[custom].tooltip)}
                          tooltipPlacement='bottom'
                          tooltip
                          type='text'
                          onChange={this.onCustomParamChange}
                        />
                      </span>
                    );
                  } else if (paramsTypes[custom].type === 'sendbefore') {
                    const list = [];
                    for (
                      let i = this.state.sendTimeStart;
                      i <= this.state.sendTimeEnd;
                      i += 1
                    ) {
                      list.push(i);
                    }
                    return (
                      <label className='item item-stacked one-whole label-emphasise is-select label-mail label-interval'>
                        <span className='item-label'>
                          {t(paramsTypes[custom].label)}
                          <Tooltip
                            content={t(paramsTypes[custom].tooltip)}
                            alignment='left'
                          >
                            <i className='icon-info' role='presentation'>
                              <span className='for-screenreader-only' />
                            </i>
                          </Tooltip>
                        </span>
                        <select
                          value={this.state.params[custom].split('-')[0]}
                          className='one-whole criteria-field label-interval--start'
                          onChange={this.onCustomParamChange}
                          name={`${custom}Start`}
                        >
                          {list.map(item => {
                            const higherValue = this.state.params[custom].split(
                              '-',
                            )[1];
                            if (higherValue <= item) {
                              return (
                                <option key={item} value={item} disabled>
                                  {item}
                                </option>
                              );
                            }
                            return (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>

                        <select
                          value={this.state.params[custom]
                            .split('-')[1]
                            .toLowerCase()}
                          className='one-whole criteria-field label-interval--end'
                          onChange={this.onCustomParamChange}
                          name={`${custom}Period`}
                        >
                          {periods.map(item => {
                            return (
                              <option key={item} value={item.toLowerCase()}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </label>
                    );
                  } else {
                    const start = paramsTypes[custom].min;
                    const end = paramsTypes[custom].max;
                    const list = [];
                    for (let i = start; i <= end; i += 1) {
                      list.push(i);
                    }
                    if (
                      'sendingPeriod' in this.state.params &&
                      this.state.params.sendingPeriod !== 'every_hour'
                    )
                      return null;
                    return (
                      <label className='item item-stacked one-whole label-emphasise is-select label-mail label-interval'>
                        <span className='item-label'>
                          {t(paramsTypes[custom].label)}
                          <Tooltip
                            content={t(paramsTypes[custom].tooltip)}
                            alignment='left'
                          >
                            <i className='icon-info' role='presentation'>
                              <span className='for-screenreader-only' />
                            </i>
                          </Tooltip>
                        </span>
                        <select
                          value={this.state.params[custom].split('-')[0]}
                          className='one-whole criteria-field label-interval--start'
                          onChange={this.onCustomParamChange}
                          name={`${custom}Start`}
                        >
                          {list.map(item => {
                            const higherValue = this.state.params[custom].split(
                              '-',
                            )[1];
                            if (higherValue <= item) {
                              return (
                                <option key={item} value={item} disabled>
                                  {item}
                                </option>
                              );
                            }
                            return (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                        -
                        <select
                          value={this.state.params[custom].split('-')[1]}
                          className='one-whole criteria-field label-interval--end'
                          onChange={this.onCustomParamChange}
                          name={`${custom}End`}
                        >
                          {list.map(item => {
                            const lowerValue = this.state.params[custom].split(
                              '-',
                            )[0];
                            if (lowerValue >= item) {
                              return (
                                <option key={item} value={item} disabled>
                                  {item}
                                </option>
                              );
                            }
                            return (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </label>
                    );
                  }
                }

                return null;
              })}
            {this.state.campaign.type === 'FLY' && (
              <span key='eventName' className='label-mail label-text-field'>
                <TextField
                  name='eventName'
                  label='Event Name'
                  className='item-stacked one-whole label-emphasise'
                  value={this.state.eventName}
                  required
                  tooltipText='Define specific event to trigger different Fly Mode campaign.'
                  tooltipPlacement='bottom'
                  tooltip
                  type='text'
                  onChange={this.onEventNameChange}
                />
              </span>
            )}
            {this.state.campaign.type !== 'PUSH_PERMISSION' &&
              this.state.campaign.type !== 'UPCOMING' && (
                <div>
                  <label className='item item-stacked one-whole label-emphasise is-select label-mail'>
                    <span className='item-label'>
                      Lifetime
                      <Tooltip
                        content='For how long the campaign should be visible following the initial launch?'
                        alignment='left'
                      >
                        <i className='icon-info' role='presentation'>
                          <span className='for-screenreader-only' />
                        </i>
                      </Tooltip>
                    </span>
                    <select
                      defaultValue={this.state.validity}
                      className='one-whole criteria-field'
                      onChange={this.onValidityChange}
                      name='validity'
                      value={this.state.validity}
                    >
                      {this.state.periodicSelected
                        ? pushNotificationsPeriodicLifeTime.map(item => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))
                        : pushNotificationsLifeTime.map(item => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                    </select>
                  </label>
                </div>
              )}
            {this.state.campaign.type !== 'PUSH_PERMISSION' &&
              this.state.campaign.type !== 'PUSH_NOTIFICATION' &&
              this.state.campaign.type !== 'FLY' && (
                <div>
                  <label className='item item-stacked one-whole label-emphasise is-select label-mail'>
                    <span className='item-label'>
                      {t('Language')}
                      <Tooltip
                        content={t('Select the campaign language')}
                        alignment='left'
                      >
                        <i className='icon-info' role='presentation'>
                          <span className='for-screenreader-only' />
                        </i>
                      </Tooltip>
                    </span>
                    <select
                      value={mainLanguage}
                      className='one-whole criteria-field'
                      onChange={this.onCampaignDataChange}
                      name='language'
                      ref='language'
                    >
                      {langList.map(item => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </label>

                  <label className='item item-stacked one-whole label-emphasise is-select label-mail'>
                    <span className='item-label'>
                      {t('Currency')}
                      <Tooltip
                        content={t('Select the campaign currency')}
                        alignment='left'
                      >
                        <i className='icon-info' role='presentation'>
                          <span className='for-screenreader-only' />
                        </i>
                      </Tooltip>
                    </span>
                    <select
                      value={mainCurrency}
                      className='one-whole criteria-field'
                      onChange={this.onCampaignDataChange}
                      name='currency'
                      ref='currency'
                    >
                      {currencyList.map(item => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
              )}
            {(this.state.campaign.type === 'PUSH_NOTIFICATION' ||
              this.state.campaign.type === 'PUSH_PERMISSION') && (
              <div>
                <label className='item item-stacked one-whole label-emphasise is-select label-mail'>
                  <span className='item-label'>
                    {t('Language')}
                    <Tooltip
                      content={t('Select the campaign language')}
                      alignment='left'
                    >
                      <i className='icon-info' role='presentation'>
                        <span className='for-screenreader-only' />
                      </i>
                    </Tooltip>
                  </span>
                  <select
                    value={mainLanguage}
                    className='one-whole criteria-field'
                    onChange={this.onCampaignDataChange}
                    name='language'
                    ref='language'
                    disabled={!this.state.twoStepEnabled}
                  >
                    {langList.map(item => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </div>
            )}
            <li className='buttons'>
              {!this.props.isPreview && (
                <Fields
                  campaign={this.state.campaign}
                  comps={this.props.buttons}
                  selectedLanguage={language}
                  campaignType={this.props.type}
                  campaignFields={this.refs.mainField}
                  additionalTitles={this.state.additionalTitles}
                  additionalRedirectUrls={this.state.additionalRedirectUrls}
                  additionalDescriptions={this.state.additionalDescriptions}
                  additionalButtonLaterText={
                    this.state.additionalButtonLaterText
                  }
                  additionalButtonAllowText={
                    this.state.additionalButtonAllowText
                  }
                />
              )}
            </li>
          </ol>
        </div>
        <Fields
          ref='campaignOptionsAdvancedConfig'
          campaign={this.state.campaign}
          comps={advancedOption}
          campaignType={this.props.type}
        />
      </form>
    );
  }
}

EditPushCampaign.propTypes = {
  buttons: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const MapStatesToProps = store => ({
  save: store.wizard.save,
  user: store.user.user,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes,
  switchedUser: store.switchedUser.switchedUser,
  formFields: store.wizard.formFields,
});

export default connect(MapStatesToProps)(EditPushCampaign);
