/**
 * Created by mehmetyurtar on 10/17/17.
 */
import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';

import { searchTypes } from '../../constants/datamaps/campaigns';
import { nonRecommendationWidgets } from '../../constants/datamaps/pages';

import { t } from '../../system/ui';

import Icon from '../icon';

const generateLinkforWidget = function(page, type) {
  if (type) {
    return '/searchkeywords/add' + '/' + page + '/' + type;
  }
  return '/searchkeywords/add' + '/' + page;
};

class KeywordSelection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let availableWidgets = nonRecommendationWidgets.keyword;
    return (
      <div
        className={classNames(
          'available-types available-widgets',
          this.props.page + '-widgets',
        )}
      >
        <h3 className='widget-title-head'>{t('Search Keyword')}</h3>
        <p className='widget-title-desc'>{t('Search-Keyword_Desc')}</p>
        <div className='available-type-selection'>
          {Object.keys(searchTypes).map((type, index) => {
            if (type === 'custom' || availableWidgets.indexOf(type) < 0) {
              return;
            }
            return (
              <span key={type + '-' + index}>
                <Link
                  key={index}
                  to={generateLinkforWidget(this.props.page, false)}
                  className={classNames(
                    'available-type',
                    `available-type-${searchTypes[type].className}`,
                    this.props.isFiltered ? 'pointer-no-event' : '',
                  )}
                >
                  <h4 className='available-type-name'>
                    {t(searchTypes[type].name)}
                  </h4>
                  <span className='available-type-wireframe' />
                </Link>
              </span>
            );
          })}
          <span>
            <Link
              to={generateLinkforWidget(this.props.page, false)}
              className={`available-type available-type-custom ${
                this.props.isFiltered ? 'pointer-no-event' : ''
              }`}
            >
              <span className='available-type-wireframe'>
                <Icon name='plus' size='2x' />
                <span style={{ textAlign: 'center' }}>
                  {t('Add Search Keyword Recommendation')}
                </span>
              </span>
            </Link>
          </span>
        </div>
      </div>
    );
  }
}

export default KeywordSelection;
