import React from 'react';
import { IndexRoute, Route } from 'react-router';
import * as Views from '../views';

export const settingRoutes = (
  <Route path='settings' component={Views.Settings}>
    <Route path='account' component={Views.AccountSettings}>
      <IndexRoute component={Views.AccountSettings} />
      <Route path='user' component={Views.Settings_User} />
      <Route path='manageUsers' component={Views.SettingsManageUser} />
      <Route
        path='customer-removal-tool'
        component={Views.CustomerRemovalTool}
      />
    </Route>
    <Route path='plugins' component={Views.Settings_Plugins} />
    <Route path='integrations' component={Views.Settings_Integrations} />
    <Route path='currency' component={Views.Settings_Currency} />
    <Route path='feed' component={Views.Settings_Feed} />
    <Route path='email' component={Views.Settings_Email} />
    <Route path='push' component={Views.Settings_Push}>
      <Route path='safari' component={Views.Settings_Push_Safari} />
      <Route path='android' component={Views.Settings_Push_Android} />
      <Route path='ios' component={Views.Settings_Push_Ios} />
    </Route>
    <Route path='search' component={Views.Settings_Search} />
    <Route path='recommendation' component={Views.SettingsRecommendation} />
    <Route path='product-detail' component={Views.ProductDetail} />
    <Route path='relation-lookup' component={Views.RelationLookUp} />
    <Route path='user-detail' component={Views.UserDetail} />
    <Route path='replenishment' component={Views.SettingsReplenishment} />
    <Route path='iys-integration' component={Views.IysIntegration} />
    <Route path='no-update' component={Views.SettingsNoUpdate} />
    <Route path='contact-list' component={Views.ContactList} />
    <Route path='contact-report' component={Views.ContactReport} />
    <Route path='ftp-settings' component={Views.FtpSettings} />
  </Route>
);
