import React, { useEffect, useState } from 'react';
import { Creatable } from 'react-select';
import Icons from '../../../icons';
import { t } from '../../../../system/ui';
import { getFilterOptions } from '../../../../modules/shop-the-look/ajax';
import { decodeHtml } from '../../../../system/string';
import './AttributeComponents.scss';

const Size = props => {
  const [sizes, setSizes] = useState([]);
  const [size, setSize] = useState('');

  const onChange = selectedValue => {
    const selectedLabel = selectedValue !== undefined ? selectedValue : size;
    setSize(selectedLabel);

    props.onSelectChange('label', selectedLabel, props.id, props.segmentType);
  };

  const fetchSizes = () => {
    const selectedSizes = [];
    getFilterOptions('size').then(res => {
      const sorted = res.sort();
      const fetchedSizes = [];
      sorted.forEach(sortedSize => {
        fetchedSizes.push({
          value: sortedSize,
          label: decodeHtml(sortedSize),
        });
      });
      if (props.values.label !== undefined) {
        fetchedSizes.push({
          value: props.values.label.value[0],
          label: decodeHtml(props.values.label.value[0]),
        });
        props.values.label.value.forEach(propsSize => {
          selectedSizes.push({
            value: propsSize,
            label: propsSize,
            className: t('Select-create-option-placeholder'),
          });
        });
      }

      setSizes(fetchedSizes);
      setSize(selectedSizes);
    });
  };

  useEffect(() => {
    fetchSizes();
  }, []);

  return (
    <div className='sfy-color'>
      <div className='sfy-color-left'>
        <div className='sfy-color-icoText'>
          <span className='sfy-color-icon'>
            <Icons name='excludeCustom' color='#787774' />
          </span>
          <div className='sfy-color-titleSelectMain'>
            <div className='sfy-color-titleSelect'>
              <span className='sfy-color-title'>{t('Size')}</span>
            </div>
          </div>

          <Creatable
            value={size}
            options={sizes}
            name='size'
            clearable={false}
            onChange={onChange}
            className='criteria-field criteria-field-category'
            placeholder={t('Type Size')}
            multi
            shouldKeyDownEventCreateNewOption={e => {
              switch (e.keyCode) {
                case 9:
                case 13:
                  return !0;
                default:
                  return !1;
              }
            }}
          />
        </div>
      </div>
      <a
        onClick={() =>
          props.onFilterRemove(props.id, props.segmentType, props.type)
        }
        className='sfy-color-actions'
      >
        <Icons name='crossCircle' />
      </a>
    </div>
  );
};

export default Size;
