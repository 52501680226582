import React from 'react';
import styles from './ConditionalScheduleSelect.module.scss';
import {
  SFYSchedulingTextField,
  SFYSelectInterval,
  SFYSelectTime,
} from '../module-exports';

const ConditionalScheduleSelect = ({ editCampaign, activeEmailDetails }) => {
  const selectIntervalDetails = {
    tooltipText:
      'The campaign will be launched during the time slot you choose.',
    cardName: 'sendBetweenHour',
    cardTitle: 'Send Between',
    type: 'interval',
  };

  const schedulingTextDetails = {
    tooltipText:
      'Select how many days should pass after the user action before the email is triggered.',
    cardName: 'triggerInHours',
    cardTitle: 'Trigger Time in Hours',
  };

  const selectTimeDetails = {
    tooltipText: 'When should the campaign launch ?',
    cardName: 'workingHour',
    cardTitle: 'Select Time',
  };

  const editThisCampaign = (fieldName, fieldValue) => {
    let modifiedFieldValue = fieldValue;
    if (fieldName === 'triggerInHours') {
      if (parseInt(fieldValue) < 2) {
        modifiedFieldValue = '2';
      } else if (parseInt(fieldValue) > 12) {
        modifiedFieldValue = '12';
      }
    }
    editCampaign(fieldName, modifiedFieldValue);
  };

  return (
    <>
      {activeEmailDetails?.params?.period === 'every_hour' && (
        <div className={styles['hourly-wrapper']}>
          <SFYSelectInterval
            componentDetail={selectIntervalDetails}
            editCampaign={editThisCampaign}
            fieldValue={activeEmailDetails?.params?.sendBetweenHour}
          />
          <SFYSchedulingTextField
            componentDetail={schedulingTextDetails}
            editCampaign={editThisCampaign}
            fieldValue={activeEmailDetails?.params?.triggerInHours}
            minVal={2}
            maxVal={12}
          />
        </div>
      )}
      {activeEmailDetails?.params?.period === 'every_day' && (
        <div className={styles['daily-wrapper']}>
          <SFYSelectTime
            componentDetail={selectTimeDetails}
            editCampaign={editThisCampaign}
            fieldValue={activeEmailDetails?.params?.workingHour}
          />
        </div>
      )}
    </>
  );
};

export default ConditionalScheduleSelect;
