import React, { useMemo } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { SFYChildWrapper } from '../module-exports';
import './SFYPreview.scss';
import Icons from '../../icons';
import { getAccountCurrency } from '../../../modules/auth/user';

/**
 * @name SFYPreview component
 * @description Preview component displays preview of the shop the look categorized algorithm products
 * @param {string} previewTitle - Title of the preview
 * @param {any[]} previews - Array of previews
 * @return {React.ReactNode}
 * @constructor
 */

const SFYPreview = ({ previewTitle, previews }) => {
  const currency = getAccountCurrency();

  const memoizedPreviews = useMemo(() => {
    return (
      previews &&
      previews.map(({ name, productId, image, inStock, price, oldPrice }) => {
        const priceText = currency.symbolBefore
          ? `${currency.symbol} ${price}`
          : `${price} ${currency.symbol}`;
        let oldPriceText = '';
        if (oldPrice) {
          oldPriceText = currency.symbolBefore
            ? `${currency.symbol} ${oldPrice}`
            : `${oldPrice} ${currency.symbol}`;
        }

        return (
          <CSSTransition timeout={900} classNames='item' key={productId}>
            <figure
              className={
                !inStock
                  ? 'sfy-preview-item-figure glass'
                  : 'sfy-preview-item-figure'
              }
            >
              <img
                src={image}
                alt={image ? '' : ''}
                className='sfy-preview-item-img'
              />
              <div className='sfy-preview-item-info'>
                <figcaption className='sfy-preview-item-name'>
                  {name}
                </figcaption>
                <p className='sfy-preview-item-price'>
                  {oldPriceText !== '' ? (
                    <span className='sfy-preview-old-price-info'>
                      {oldPriceText}
                    </span>
                  ) : null}
                  <span
                    className={
                      !oldPriceText
                        ? 'sfy-preview-price-info'
                        : 'sfy-preview-item-price-red'
                    }
                  >
                    {priceText}
                  </span>
                </p>
              </div>
              {!inStock ? (
                <div className='sfy-item-out-of-stock'>
                  <Icons name='crossCircle' color='red' />
                  <span>Out of stock</span>
                </div>
              ) : null}
            </figure>
          </CSSTransition>
        );
      })
    );
  }, [previews]);

  return (
    <SFYChildWrapper className='sfy-preview-wrapper'>
      <h3 className='sfy-preview-title'>
        {previews.length > 0 ? previewTitle : ''}
      </h3>
      <TransitionGroup class='sfy-preview-holder'>
        {memoizedPreviews}
      </TransitionGroup>
    </SFYChildWrapper>
  );
};

export default SFYPreview;
