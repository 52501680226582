import React from 'react';
import { connect } from 'react-redux';

import { uiActions } from '../../actions';
import { getBanner } from '../../modules/bannerify/ajax';
import { calculateRatio } from '../../system/ratio';
import { localeString } from '../../system/string';
import { t } from '../../system/ui';
import Currency from '../currency';
import { RangePicker } from '../fields';
import Icons from '../icons';
import BannerifyCompareConversion from './compare.conversion.bannerify';
import { getOtherDates } from './utils';

class BannerifyCompare extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserSelect: false,
      firstStart: "",
      firstEnd: "",
      secondStart: "",
      secondEnd: "",
      firstColumnData: { stats: {} },
      secondColumnData: { stats: {} },
      compareType: "conversion",
      results: []
    };

    this.onFirstDatesChange = this.onFirstDatesChange.bind(this);
    this.onSecondDatesChange = this.onSecondDatesChange.bind(this);
    this.changeFirstColumn = this.changeFirstColumn.bind(this);
    this.changeSecondColumn = this.changeSecondColumn.bind(this);
    this.changeCompareType = this.changeCompareType.bind(this);
    this.getCompareIcon = this.getCompareIcon.bind(this);
  }

  getCompareIcon() {
    return {
      count: (firstVal = 0, secondVal = 0) => {
        let difference = firstVal - secondVal;
        if (difference === 0) {
          return "";
        }
        if (difference > 0) {
          return (
            <span style={{ color: "#56bc94", display: "block" }}>
              +{localeString(difference)}{" "}
              <Icons name="increase" color="#56bc94" />
            </span>
          );
        } else {
          return (
            <span style={{ color: "#dc3322", display: "block" }}>
              {" "}
              {localeString(difference)}{" "}
              <Icons name="decrease" color="#dc3322" />
            </span>
          );
        }
      },
      ratio: (
        firstVal = 0,
        firstValOld = 0,
        secondVal = 0,
        secondValOld = 0
      ) => {
        let currentValue = calculateRatio(firstVal, firstValOld);
        let oldValue = calculateRatio(secondVal, secondValOld);
        let difference = currentValue - oldValue;
        if (difference === 0) {
          return "";
        }
        if (difference > 0) {
          return (
            <span style={{ color: "#56bc94", display: "block" }}>
              + {localeString(currentValue - oldValue)} %{" "}
              <Icons name="increase" color="#56bc94" />
            </span>
          );
        } else {
          return (
            <span style={{ color: "#dc3322", display: "block" }}>
              {" "}
              {localeString(oldValue - currentValue)} %{" "}
              <Icons name="decrease" color="#dc3322" />
            </span>
          );
        }
      },
      currency: (firstVal = 0, secondVal = 0) => {
        let difference = firstVal - secondVal;
        if (difference === 0) {
          return "";
        }
        if (difference > 0) {
          return (
            <span style={{ color: "#56bc94", display: "block" }}>
              + <Currency currency={difference || 0} />
              <Icons name="increase" color="#56bc94" />
            </span>
          );
        } else {
          return (
            <span style={{ color: "#dc3322", display: "block" }}>
              <Currency currency={difference || 0} />
              <Icons name="decrease" color="#dc3322" />
            </span>
          );
        }
      }
    };
  }

  componentDidMount() {
    let firstRequestData = {
      title: this.props.bannerTitle,
      group: this.props.bannerGroup,
      startDate: getOtherDates(this.props.range).formattedStart,
      endDate: getOtherDates(this.props.range).formattedEnd
    };
    let secondRequestData = {
      title: this.props.bannerTitle,
      group: this.props.bannerGroup,
      startDate: this.props.range[0].format("YYYY-MM-DD"),
      endDate: this.props.range[1].format("YYYY-MM-DD")
    };
    uiActions.isLoading();
    getBanner(firstRequestData, firstColumnData => {
      getBanner(secondRequestData, secondColumnData => {
        uiActions.isLoaded();
        this.setState({
          firstColumnData: firstColumnData.banner
            ? firstColumnData.banner
            : { stats: {} },
          secondColumnData: secondColumnData.banner
            ? secondColumnData.banner
            : { stats: {} }
        });
      });
    });
  }

  changeFirstColumn(dates) {
    if (
      dates.startDate === this.state.firstStart &&
      dates.enddDate === this.state.firstEnd
    ) {
      return;
    }
    uiActions.isLoading();
    let data = {
      title: this.props.bannerTitle,
      group: this.props.bannerGroup,
      startDate: dates.startDate.format("YYYY-MM-DD"),
      endDate: dates.endDate.format("YYYY-MM-DD")
    };
    getBanner(data, response => {
      uiActions.isLoaded();
      this.setState({
        firstColumnData: response.banner ? response.banner : { stats: {} }
      });
    });
  }

  changeSecondColumn(dates) {
    if (
      dates.startDate === this.state.secondStart &&
      dates.enddDate === this.state.secondEnd
    ) {
      return;
    }
    uiActions.isLoading();
    let data = {
      title: this.props.bannerTitle,
      group: this.props.bannerGroup,
      startDate: dates.startDate.format("YYYY-MM-DD"),
      endDate: dates.endDate.format("YYYY-MM-DD")
    };

    getBanner(data, response => {
      uiActions.isLoaded();
      this.setState({
        secondColumnData: response.banner ? response.banner : { stats: {} }
      });
    });
  }

  onFirstDatesChange(newDates) {
    this.setState({
      firstStart: newDates.startDate,
      firstEnd: newDates.endDate,
      isUserSelect: true
    });
  }

  onSecondDatesChange(newDates) {
    this.setState({
      secondStart: newDates.startDate,
      secondEnd: newDates.endDate,
      isUserSelect: true
    });
  }

  changeCompareType(compareType, e) {
    e.preventDefault();
    this.setState({
      compareType: compareType
    });
  }

  render() {
    let compareComponents = {
      conversion: (
        <BannerifyCompareConversion
          firstColumnData={this.state.firstColumnData}
          secondColumnData={this.state.secondColumnData}
          device={this.props.device}
          getCompareIcon={this.getCompareIcon}
          record={this.props.record}
        />
      )
    };
    return (
      <div className="report-compare one-whole" style={{ minWidth: "960px" }}>
        <div className="one-half report-compare__field">
          <div>
            <span
              style={{
                display: "inline-block",
                marginRight: "12px",
                fontSize: "14px",
                color: "#a3a3a5"
              }}
            >
              {t("Compare To")}:
            </span>
            <RangePicker
              startDate={
                this.state.secondStart || getOtherDates(this.props.range).start
              }
              endDate={
                this.state.secondEnd || getOtherDates(this.props.range).end
              }
              onDatesChange={this.onSecondDatesChange}
              onPickerClose={this.changeFirstColumn}
            />
          </div>
        </div>
        <div className="one-half report-compare__field">
          <div>
            <span
              style={{
                display: "inline-block",
                marginRight: "12px",
                fontSize: "14px",
                color: "#a3a3a5"
              }}
            >
              {t("With")}:
            </span>
            <RangePicker
              startDate={this.state.firstStart || this.props.range[0]}
              endDate={this.state.firstEnd || this.props.range[1]}
              onDatesChange={this.onFirstDatesChange}
              onPickerClose={this.changeSecondColumn}
            />
          </div>
        </div>
        {compareComponents[this.state.compareType]}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range
});

export default connect(mapStatesToProps)(BannerifyCompare);
