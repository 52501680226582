import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { hasKlaviyoConfiguration } from '../../modules/auth/user';
import {
  transformCampaignReport,
  transformPushTrends,
} from '../../modules/transform';
import { statTypes } from '../../constants/datamaps';
import { t } from '../../system/ui';
import { localeString, localeStringMoney } from '../../system/string';
import Currency from '../currency';
import Icon from '../icon';
import PushPermissionReport from '../push/permission-report';
import { findLongestStat } from '../reports/utils';
import Tooltip from '../tooltip';
import { modalActions } from '../../actions';

import { getEmailStatsBounceBackReport } from '../../modules/reports/ajax';

import { EmailStatsBounceBackReport } from '../email/email-stats-bounceback-report';

const rangeAlias = {
  daily: 'Today',
  weekly: 'This Week',
  lastSevenDays: 'Last 7 Days',
  monthly: 'This Month',
  lastmonth: 'Last Month',
  lastMonth: 'Last Month',
  custom: 'Custom Date',
};

class EmailStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: {},
    };
    this.setDefaultReports = this.setDefaultReports.bind(this);
  }

  componentDidMount() {
    this.setDefaultReports(this.props);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.report !== this.props.report) {
      this.setDefaultReports(newProps);
    }

    if (
      this.props.isPush &&
      newProps.selectedDevice !== this.props.selectedDevice
    ) {
      this.setDefaultReports(newProps);
    }
  }

  setDefaultReports(props) {
    this.setState({
      stats: this.props.isPush
        ? transformPushTrends(
            props.report,
            false,
            false,
            false,
            props.isPushPermission,
            props.selectedDevice,
          )
        : transformCampaignReport(props.report, false, 'E-Mail'),
    });
  }

  handleBounceBackModal = async () =>
    await getEmailStatsBounceBackReport({
      campaignId: this.props?.campaignId,
      startDate: moment(this.props.startDate)
        .utc()
        .format('YYYY-MM-DD'),
      endDate: moment(this.props.endDate)
        .utc()
        .format('YYYY-MM-DD'),
    }).then(response => {
      return modalActions.openModal({
        title: `Bounce Back Stats - ${this.props.campaignName}`,
        content: () => (
          <EmailStatsBounceBackReport
            report={response}
            campaignId={this.props?.campaignId}
          />
        ),
      });
    });

  render() {
    const instance = {};
    let revenue = '0';
    let contRatio = '0';
    const hasKlaviyoSetUp = hasKlaviyoConfiguration();
    instance.inputs = this.props.inputs;
    if (this.props.isPushPermission) {
      return (
        <PushPermissionReport
          key='campaign-report'
          getAllPermissions={this.props.getAllPermissions}
          isSingle={this.props.isSingle || this.props.isViewAll}
          report={this.props.permissionReport}
        />
      );
    }
    const longestStat = findLongestStat(this.state.stats);
    return (
      <div
        className={
          this.props.isReport
            ? 'widget-stat-list widget-report'
            : 'widget-stat-list'
        }
      >
        {this.props.rangeAlias ? (
          <p>{t(rangeAlias[this.props.rangeAlias])}</p>
        ) : null}
        <div className='widget-stats'>
          {Object.keys(this.state.stats).map((item, index) => {
            const stat = this.state.stats[item].stats;
            const type = statTypes[item];
            const className = `widget-stat stat-${item.toLowerCase()}`;

            const calculateCountValue = () => {
              if (item !== 'widget') {
                if (type.currency === true) {
                  return localeStringMoney(stat.all.count);
                }
                return localeString(stat.all.count);
              }
              if (type.currency === true) {
                return localeStringMoney(stat.view.count);
              }
              return localeString(stat.view.count);
            };

            const calculateCurrencyValue = () => {
              if (type.unit === 'views') {
                if (this.props.isPush) return 'views';

                return 'opens';
              }
              return type.unit;
            };

            if (item === 'revenue') {
              revenue = stat.all.count;
              contRatio = stat.contribution.ratio;
            }

            let emailTypeName = false;
            if (type.name === 'Widget') {
              emailTypeName = this.props.isPush ? 'Web Push' : 'Email';
            } else if (type.name === 'Revenue') {
              emailTypeName = 'Email Conversion';
            }
            return (
              <div className={className} ref={item} key={`${item}-${index}`}>
                <h4 className='widget-stat-title'>
                  {t(emailTypeName || type.name)}
                </h4>

                <p className='widget-stat-count' ref={`${item}Count`}>
                  {calculateCountValue()}
                </p>

                {!hasKlaviyoSetUp && (
                  <p className='widget-stat-type'>
                    {type.currency === true ? (
                      <Currency />
                    ) : (
                      <Icon name={type.icon} />
                    )}
                    {type.currency !== true && t(calculateCurrencyValue())}
                  </p>
                )}
                {hasKlaviyoSetUp && (
                  <p className='widget-stat-type'>
                    {type.currency === true && <Currency />}
                    {type.currency !== true && this.props.isPush && (
                      <Icon name={type.icon} />
                    )}
                    {type.currency !== true &&
                      !this.props.isPush &&
                      item !== 'widget' && <Icon name={type.icon} />}
                    {type.currency !== true &&
                      t(type.unit !== 'views' ? type.unit : 'views')}
                  </p>
                )}
                {!hasKlaviyoSetUp && (
                  <>
                    <p className='widget-stat-conversion'>
                      {item === 'widget' && t('Sent')}
                      {item === 'widget' && (
                        <span className='widget-stat-conversion-amount'>
                          {localeString(stat.all.count)}
                        </span>
                      )}
                      {emailTypeName === 'Email' && t('Unsubscribed')}
                      {emailTypeName === 'Email' && (
                        <span className='widget-stat-conversion-amount'>
                          {localeString(stat.unsubscribed.total)}
                        </span>
                      )}
                      {emailTypeName === 'Email' && (
                        <Tooltip
                          content={
                            <>
                              <span>
                                Hard Bounce : {stat.bounceBack.HardBounce}
                              </span>
                              <br />
                              <span>
                                Soft Bounce : {stat.bounceBack.SoftBounce}
                              </span>
                            </>
                          }
                          alignment='top'
                          newTooltip
                        >
                          {emailTypeName === 'Email' && (
                            <span
                              className='bounceBack'
                              onClick={this.handleBounceBackModal}
                            >
                              {t('Bounce Back')}
                            </span>
                          )}
                          <span className='widget-stat-conversion-amount'>
                            {localeString(
                              stat.bounceBack.HardBounce +
                                stat.bounceBack.SoftBounce,
                            )}
                          </span>
                        </Tooltip>
                      )}
                      {emailTypeName === 'Email' && (
                        <>
                          <span>{t('Spam')}</span>
                          <span className='widget-stat-conversion-amount'>
                            {stat.Spam}
                          </span>
                        </>
                      )}
                      {this.props.isReport && item === 'basket' && (
                        <>
                          {t('Total Amount')}
                          <span className='widget-stat-conversion-amount'>
                            {stat.amount.currency === true
                              ? localeStringMoney(stat.amount.count)
                              : localeString(stat.amount.count)}
                            {stat.amount.currency === true ? (
                              <Currency />
                            ) : (
                              <Icon name={type.icon} />
                            )}
                          </span>
                        </>
                      )}
                      {item === 'revenue' && t('Purchases')}
                      {item === 'revenue' && (
                        <span className='widget-stat-conversion-amount'>
                          {localeString(stat.purchases.count)}
                        </span>
                      )}
                      {item === 'revenue' && t('Purchased Products')}
                      {item === 'revenue' && (
                        <span className='widget-stat-conversion-amount'>
                          {localeString(stat.items.count)}
                        </span>
                      )}

                      {this.props.isReport && item === 'revenue' && (
                        <>
                          {t('Average Order Value')}
                          <span className='widget-stat-conversion-amount'>
                            {stat.average.currency === true
                              ? localeStringMoney(stat.average.count)
                              : localeString(stat.average.count)}
                            {stat.average.currency === true ? (
                              <Currency />
                            ) : (
                              <Icon name={type.icon} />
                            )}
                          </span>
                        </>
                      )}
                    </p>
                  </>
                )}
                {hasKlaviyoSetUp && (
                  <p className='widget-stat-conversion'>
                    {item === 'widget' && this.props.isPush && t('Sent')}
                    {item === 'widget' && this.props.isPush && (
                      <span className='widget-stat-conversion-amount'>
                        {localeString(stat.all.count)}
                      </span>
                    )}
                    {item === 'widget' &&
                      !this.props.isPush &&
                      t('Triggered To Flow')}
                    {item === 'widget' && !this.props.isPush && (
                      <span className='widget-stat-conversion-amount'>
                        {localeString(stat.klaviyo.flow)}
                      </span>
                    )}
                    {item === 'widget' &&
                      !this.props.isPush &&
                      t('Triggered To Profile')}
                    {item === 'widget' && !this.props.isPush && (
                      <span className='widget-stat-conversion-amount'>
                        {localeString(stat.klaviyo.profile)}
                      </span>
                    )}
                    {this.props.isReport && item === 'basket' && (
                      <>
                        {t('Total Amount')}
                        <span className='widget-stat-conversion-amount'>
                          {stat.amount.currency === true
                            ? localeStringMoney(stat.amount.count)
                            : localeString(stat.amount.count)}
                          {stat.amount.currency === true ? (
                            <Currency />
                          ) : (
                            <Icon name={type.icon} />
                          )}
                        </span>
                      </>
                    )}
                    {item === 'revenue' && t('Purchases')}
                    {item === 'revenue' && (
                      <span className='widget-stat-conversion-amount'>
                        {localeString(stat.purchases.count)}
                      </span>
                    )}
                    {item === 'revenue' && t('Purchased Products')}
                    {item === 'revenue' && (
                      <span className='widget-stat-conversion-amount'>
                        {localeString(stat.items.count)}
                      </span>
                    )}

                    {this.props.isReport && item === 'revenue' && (
                      <>
                        {t('Average Order Value')}
                        <span className='widget-stat-conversion-amount'>
                          {stat.average.currency === true
                            ? localeStringMoney(stat.average.count)
                            : localeString(stat.average.count)}
                          {stat.average.currency === true ? (
                            <Currency />
                          ) : (
                            <Icon name={type.icon} />
                          )}
                        </span>
                      </>
                    )}
                  </p>
                )}

                {!hasKlaviyoSetUp && (
                  <>
                    <p
                      style={{ bottom: '-25px' }}
                      className={`widget-stat-conversion ${item !==
                        longestStat && 'widget-stat-conversion-bottom'}`}
                    >
                      {item === 'widget' &&
                        (this.props.isPush
                          ? t('Impression Rate')
                          : t('Open Rate'))}
                      {item === 'revenue' && t('Conversion')}
                      {(item === 'interaction' || item === 'basket') &&
                        t(`${type.name}Conversion`)}
                      {stat.conversion && stat.conversion.tooltip && (
                        <span className='stat-tooltip'>
                          <Tooltip
                            content={stat.conversion.tooltip}
                            alignment='top'
                            newTooltip
                          >
                            <Icon name='info' />
                          </Tooltip>
                        </span>
                      )}
                      {stat.conversion && (
                        <span className='widget-stat-conversion-amount'>
                          {localeString(stat.conversion.ratio)}%
                        </span>
                      )}
                    </p>
                    <p
                      className={`widget-stat-conversion ${item !==
                        longestStat && 'widget-stat-conversion-bottom'}`}
                    >
                      {item === 'revenue' && (
                        <>
                          {t('Contribution Ratio')}
                          <span className='widget-stat-conversion-amount'>
                            {localeString(stat.contribution.ratio)}%
                          </span>
                        </>
                      )}
                    </p>
                  </>
                )}
                {hasKlaviyoSetUp && (
                  <>
                    <p
                      className={`widget-stat-conversion ${item !==
                        longestStat && 'widget-stat-conversion-bottom'}`}
                    >
                      {item === 'widget' &&
                        this.props.isPush &&
                        t('Impression Rate')}
                      {item === 'revenue' && t('Conversion')}
                      {item === 'interaction' &&
                        this.props.isPush &&
                        t(`${type.name}Conversion`)}
                      {item === 'basket' && t(`${type.name}Conversion`)}
                      {stat.conversion && stat.conversion.tooltip && (
                        <span className='stat-tooltip'>
                          <Tooltip
                            content={stat.conversion.tooltip}
                            alignment='top'
                            newTooltip
                          >
                            <Icon name='info' />
                          </Tooltip>
                        </span>
                      )}
                      {item !== 'widget' &&
                        item !== 'interaction' &&
                        !this.props.isPush &&
                        stat.conversion && (
                          <span className='widget-stat-conversion-amount'>
                            {localeString(stat.conversion.ratio)}%
                          </span>
                        )}
                      {this.props.isPush && stat.conversion && (
                        <span className='widget-stat-conversion-amount'>
                          {localeString(stat.conversion.ratio)}%
                        </span>
                      )}
                    </p>
                    <p
                      className={`widget-stat-conversion ${item !==
                        longestStat && 'widget-stat-conversion-bottom'}`}
                    >
                      {item === 'revenue' && (
                        <>
                          {t('Contribution Ratio')}
                          <span className='widget-stat-conversion-amount'>
                            {localeString(stat.contribution.ratio)}%
                          </span>
                        </>
                      )}
                    </p>
                  </>
                )}
              </div>
            );
          })}
        </div>

        {!this.props.isReport && (
          <span className='widget-amount'>
            <Currency currency={revenue} />
          </span>
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  startDate: store.date.range[0],
  endDate: store.date.range[1],
});

export default connect(mapStatesToProps)(EmailStats);
