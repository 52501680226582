/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { connect } from 'react-redux';

import { getFormattedDate } from 'system/date';
import classNames from 'classnames';
import { trendsMap, statTypes } from '../../constants/datamaps';

import { t } from '../../system/ui';
import { localeString, localeStringMoney } from '../../system/string';
import { findLongestStat } from '../reports/utils';

import { transformCampaignReport } from '../../modules/transform';

import Currency from '../currency';
import Icon from '../icon';
import Icons from '../icons';
import Tooltip from '../tooltip';
import { devices, mediums } from '../../constants/datamaps/campaigns';
import { getCampaignPage } from '../../modules/campaigns/data';

class WidgetAllReportStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: trendsMap,
      segmentStats: [],
      selectedDevice: 'ALL',
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.setReports();
  }

  setReports() {
    this.setState(
      {
        stats: transformCampaignReport(
          this.props.stats,
          this.state.selectedDevice === 'PC'
            ? 'PC'
            : this.state.selectedDevice.toLowerCase(),
          this.props.campaignType,
        ),
        segmentStats: this.props.stats || [],
      },
      () => {
        this.setState({
          isLoaded: true,
        });
      },
    );
  }

  selectDevice(selectedDevice, e) {
    e.preventDefault();
    this.setState(
      {
        selectedDevice,
      },
      () => {
        this.setState({
          stats: transformCampaignReport(
            this.props.stats,
            this.state.selectedDevice === 'PC'
              ? 'PC'
              : this.state.selectedDevice.toLowerCase(),
            this.props.campaignType,
          ),
        });
      },
    );
  }

  render() {
    const checkListForLongestStat = [
      'all',
      'contribution',
      'view',
      'leftArrowClick',
      'rightArrowClick',
      'conversion',
    ];

    const defaultPageTypes = [
      'Home Page',
      'Category Page',
      'Search Page',
      '404 Page',
      'Product Page',
      'Basket Page',
      'Checkout Success Page',
    ];
    const campaignPage = getCampaignPage(this.props.campaign);
    if (defaultPageTypes.includes(campaignPage)) {
      checkListForLongestStat.push('pageView', 'impressionRate');
    }

    const longestStat = findLongestStat(
      this.state.stats,
      checkListForLongestStat,
    );
    const instance = {};
    instance.inputs = this.props.campaign.inputs;
    const isSegment =
      this.props.campaign.actions[0] &&
      this.props.campaign.actions[0].type === 'segmentVisitor';
    const isKeyword =
      this.props.campaign.actions[0] &&
      this.props.campaign.actions[0].type === 'recommendKeyword';
    if (isSegment) {
      let segmentedVisitors = 0;
      this.state.segmentStats.forEach(stat => {
        if (stat.x === 'Segment Visitor') {
          segmentedVisitors = stat.y;
        }
      });
      return (
        <div className='widget-stats-segment'>
          <h4 className='widget-stat-title'>{t('Segmented Users')}</h4>
          <span className='widget-stat-data'>
            {localeString(segmentedVisitors)}
          </span>
          <div className='widget-stats-segment--icon'>
            <i className='icon-add-user' />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className='widget-meta'>
          <span className='widget-meta-date'>
            <Icon name='calendar' />
            {getFormattedDate(
              this.props.campaign.limitations.activeDates.startDate,
            )}
            {this.props.campaign.limitations.activeDates.endDate
              ? ` - ${getFormattedDate(
                  this.props.campaign.limitations.activeDates.endDate,
                )}`
              : ''}
          </span>
          {this.state.isLoaded && (
            <span className='recommendation-device-selection'>
              <Tooltip
                content={t('All Devices')}
                alignment='top'
                newTooltip
                isInline
              >
                <span
                  key='all-devices'
                  onClick={this.selectDevice.bind(this, 'ALL')}
                  className={classNames(
                    'widget-meta-mobile all-device-report',
                    {
                      'is-selected': this.state.selectedDevice === 'ALL',
                    },
                  )}
                >
                  <Icons
                    name='allDevices'
                    width='16'
                    height='16'
                    color='#bababa'
                  />
                  {t('ALL')}
                </span>
              </Tooltip>
              {this.props.campaign.device
                ? mediums[this.props.campaign.device].map(device => {
                    if (
                      device.toLowerCase() === 'pctablet' ||
                      device.toLowerCase() === 'tablet'
                    ) {
                      return null;
                    }

                    return (
                      <Tooltip
                        key={device}
                        content={t(devices[device])}
                        alignment='top'
                        newTooltip
                        isInline
                      >
                        <span
                          key={device}
                          onClick={this.selectDevice.bind(this, device)}
                          className={classNames('widget-meta-mobile', {
                            'is-selected': this.state.selectedDevice === device,
                          })}
                        >
                          <Icon name={device.toLowerCase()} />
                        </span>
                      </Tooltip>
                    );
                  })
                : ''}
            </span>
          )}
        </div>
        <hr className='widget-separator' />
        <div className='widget-stat-list'>
          <div className='widget-stats'>
            {Object.keys(this.state.stats).map((item, index) => {
              const stat = this.state.stats[item].stats;
              const type = statTypes[item];
              const className = `widget-stat stat-${item.toLowerCase()}`;

              if (isKeyword && (item === 'basket' || item === 'revenue')) {
                return null;
              }

              return (
                <div className={className} ref={item} key={`${item}-${index}`}>
                  <h4 className='widget-stat-title'>{t(type.name)}</h4>
                  <p className='widget-stat-count'>
                    {item !== 'widget'
                      ? type.currency === true
                        ? localeStringMoney(stat.all.count)
                        : localeString(stat.all.count)
                      : type.currency === true
                      ? localeStringMoney(stat.view.count)
                      : localeString(stat.view.count)}
                  </p>
                  <p className='widget-stat-type'>
                    {type.currency === true ? (
                      <Currency />
                    ) : (
                      <Icon name={type.icon} />
                    )}
                    {type.currency !== true ? t(type.unit) : ''}
                  </p>
                  <p className='widget-stat-conversion'>
                    {type.name === 'Widget' && t('Impression')}
                    {type.name === 'Widget' && (
                      <span className='widget-stat-conversion-amount'>
                        {localeString(stat.all.count)}
                      </span>
                    )}
                  </p>
                  {type.name === 'Interaction' &&
                    stat.leftArrowClick &&
                    stat.leftArrowClick.visible === true && (
                      <p className='widget-stat-conversion widget-stat-arrow-click-count'>
                        {t(stat.leftArrowClick.name)}

                        <span className='widget-stat-conversion-amount'>
                          {stat.leftArrowClick.count}
                        </span>
                      </p>
                    )}
                  {type.name === 'Interaction' &&
                    stat.rightArrowClick &&
                    stat.rightArrowClick.visible === true && (
                      <p className='widget-stat-conversion widget-stat-arrow-click-count'>
                        {t(stat.rightArrowClick.name)}

                        <span className='widget-stat-conversion-amount'>
                          {stat.rightArrowClick.count}
                        </span>
                      </p>
                    )}

                  <p
                    className={`widget-stat-conversion ${
                      type.name !== 'Revenue' &&
                      item !== longestStat &&
                      !(
                        type.name === 'Widget' &&
                        defaultPageTypes.includes(campaignPage)
                      )
                        ? 'widget-stat-conversion-bottom'
                        : ''
                    }`}
                  >
                    {type.name === 'Widget' ||
                    type.name === 'Interaction' ||
                    type.name === 'Basket'
                      ? t(`${type.name}Conversion`)
                      : t('Conversion')}
                    {stat.conversion && stat.conversion.tooltip && (
                      <span className='stat-tooltip'>
                        <Tooltip
                          content={stat.conversion.tooltip}
                          alignment='top'
                          newTooltip
                        >
                          <Icon name='info' />
                        </Tooltip>
                      </span>
                    )}
                    <span className='widget-stat-conversion-amount'>
                      {stat.conversion.ratio}%
                    </span>
                  </p>

                  {type.name === 'Revenue' && (
                    <p
                      className={`widget-stat-conversion ${
                        item !== longestStat
                          ? 'widget-stat-conversion-bottom'
                          : ''
                      }`}
                    >
                      {t('Contribution Ratio')}
                      <span className='widget-stat-conversion-amount'>
                        {stat.contribution.ratio}%
                      </span>
                    </p>
                  )}

                  {defaultPageTypes.includes(campaignPage) && stat?.pageView && (
                    <p className='widget-stat-conversion'>
                      {type.name === 'Widget' && t('PageView')}
                      {type.name === 'Widget' && (
                        <span className='widget-stat-conversion-amount'>
                          {localeString(stat?.pageView?.count)}
                        </span>
                      )}
                    </p>
                  )}

                  {defaultPageTypes.includes(campaignPage) &&
                    stat?.impressionRate && (
                      <p
                        className={`widget-stat-conversion ${
                          item !== longestStat
                            ? 'widget-stat-conversion-bottom'
                            : ''
                        }`}
                      >
                        {type.name === 'Widget' && t('ImpressionRate')}
                        {type.name === 'Widget' && (
                          <span className='widget-stat-conversion-amount'>
                            {stat?.impressionRate?.ratio}%
                          </span>
                        )}
                      </p>
                    )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  update: store.filters.update,
  reset: store.filters.reset,
  reports: store.reports.reports,
});

export default connect(mapStatesToProps)(WidgetAllReportStats);
