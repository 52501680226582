/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/** @fileOverview ProductBundle.jsx  Product List */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { browserHistory } from 'react-router';

import {
  SFYHeader,
  SFYWrapper,
} from '../../../components/sfy-components/module-exports';
import {
  breadCrumbOptions,
  bundleHeadConstant,
  categoryHeadConstant,
  importExportOptions,
} from './constants/constants';
import {
  ProductBundleSearchService,
  ProductBundleService,
} from './services/productBundleServices';
import { Context as ProductBundleContext } from './store/productBundleProvider';
import { Context as PaginationContext } from '../../../components/pagination/store/paginationProvider';
import { modalActions } from '../../../actions';
import { isLoaded, isLoading } from '../../../actions/ui';
import BundleModalManager from './modals/bundleModalManager';
import SFYTab from '../../../components/sfy-components/SFYTab/SFYTab';
import { t } from '../../../system/ui';
import RemoveListItemModal from '../../../components/sfy-components/RemoveListItemModal/RemoveListItemModal';
import usePrevious from '../../../hooks/use-previous/usePrevious';
import {
  getAccount,
  getApiKey,
  getRegion,
  getSwitchAccountUser,
  getToken,
  hasDynamicBundle,
  isMultiAccountUser,
  isSwitchAccount,
} from '../../../modules/auth/user';
import { getRestEndPoint } from '../../../system/api';
import BundleImport from './modals/bundleImport';
import { dynamicBundleInfo } from '../../../modules/trendify/page';
import { setTitle } from '../../../system/document';

/**
 * @name ProductBundle
 * @description This component is responsible for listing the bundles and managing the actions(edit-remove) of the bundles.
 * @return {JSX.Element}
 */

const ProductBundle = ({ location }) => {
  const {
    state: { productBundleList, categoryBundleList },
    setPBProductList,
    setPBCategoryList,
  } = useContext(ProductBundleContext);

  const [activeTab, setActiveTab] = useState('category');
  const [selectedItems, setSelectedItems] = useState([]);

  const {
    state: { pagination, searchQueryString },
    paginationSetter,
  } = useContext(PaginationContext);

  const openBundleTypeModal = () => {
    const content = () => <BundleModalManager bundleFor='main-item' />;

    modalActions.openModal({
      title: t('Upload Your File'),
      className: 'bundle-product-modal',
      content,
      dynamicHeight: true,
    });
  };

  const openBundleImportModal = listInvoker => {
    const content = () => <BundleImport listInvoker={listInvoker} />;

    modalActions.openModal({
      title: t('Upload Your File'),
      className: 'bundle-product-import-modal',
      content,
      dynamicHeight: true,
    });
  };

  const activeTabComponent = () => {
    // eslint-disable-next-line no-use-before-define
    const activeTab = tabs?.find(tab => tab.active);
    return activeTab.componentValue;
  };

  const listInvoker = (
    forceToInvoke = false,
    type = 'category',
    overridePageNumber = null,
  ) => {
    if (searchQueryString.length > 2 || forceToInvoke) {
      isLoading();
      ProductBundleSearchService.getAll({
        pageNumber: overridePageNumber || pagination.pageNumber,
        payloadSize: pagination.payloadSize,
        searchQueryString,
        itemType: type,
      }).then(({ totalRecordSize, pageNo, pageSize, response }) => {
        const categories = [];
        const products = [];
        if (response) {
          response.forEach(item => {
            if (item.mainItem.type === 'category') {
              categories.push(item);
            } else {
              products.push(item);
            }
          });
        }

        setPBCategoryList(categories);
        setPBProductList(products);

        paginationSetter({
          totalRecords: totalRecordSize,
          pageNumber: pageNo,
          payloadSize: pageSize,
        });
        isLoaded();
      });
    }
  };

  const removeProductBundle = useMemo(
    () => (id, type) => {
      RemoveListItemModal({
        listInvoker,
        confirmBtn: true,
        title: (
          <>{`Remove the ${type.charAt(0).toUpperCase() + type.slice(1)} ?`}</>
        ),
        content: (
          <>{`Are you sure you want to remove the ${type} you added ?`}</>
        ),
        deleteFn: () => {
          isLoading();
          return ProductBundleService.deleteWithParams({ id }).then(() => {
            isLoaded();
            listInvoker(true, type);
          });
        },
      });
    },
    [listInvoker, ProductBundleService.deleteWithParams],
  );

  const setTabsContent = () => {
    return [
      {
        id: v4(),
        active: true,
        componentValue: 'category',
        componentName: t('Category Bundle'),
        paginationOptions: {
          linkToRoute: '',
          action: openBundleTypeModal,
          text: t('Create a New Bundle'),
        },
        listOptions: {
          linkToDetail: '/dynamic_bundle/automated-bundles/edit',
          modalInvoker: removeProductBundle,
          bundleHeadConstant: categoryHeadConstant,
        },
      },
      {
        id: v4(),
        active: false,
        componentValue: 'product',
        componentName: t('Product Bundle'),
        paginationOptions: {
          linkToRoute: '',
          action: openBundleTypeModal,
          text: t('Create a New Bundle'),
        },
        listOptions: {
          linkToDetail: '/dynamic_bundle/automated-bundles/edit',
          modalInvoker: removeProductBundle,
          bundleHeadConstant,
        },
      },
    ];
  };

  const [tabs, setTabs] = useState(setTabsContent());
  const prevData = usePrevious({ tabs, pagination, searchQueryString });

  const getImportExportOptions = () => {
    return importExportOptions.map(option => ({
      ...option,
      action: e => {
        if (option.label === 'Import') {
          openBundleImportModal(listInvoker);
        } else if (option.label === 'Export') {
          const endpoint = `${getRestEndPoint(
            'bundle/export',
          )}?apiKey=${getApiKey()}`;
          let exportUrl = `${endpoint}&authToken=${getToken()}`;
          if (isSwitchAccount() && !isMultiAccountUser()) {
            exportUrl += `&switchUser=${getSwitchAccountUser(true).username}`;
          }
          if (isMultiAccountUser() && isSwitchAccount()) {
            exportUrl += `&switchAccount=${getAccount().accountId.trim()}`;
          }
          exportUrl += `&switchRegion=${getRegion()}`;
          window.open(exportUrl);
        }
      },
    }));
  };

  useEffect(() => {
    setTitle(t('Automated Bundles'));
    const prevTabs = prevData?.tabs;
    const prevPagination = prevData?.pagination || {};
    const prevSearchQueryString = prevData?.searchQueryString || '';

    const isTabChanged =
      prevTabs?.some((prevTab, index) => {
        return prevTab.active !== tabs[index].active;
      }) || false;

    if (
      (Object.keys(prevPagination).length > 0 &&
        (prevPagination.pageNumber !== pagination.pageNumber ||
          prevPagination.payloadSize !== pagination.payloadSize)) ||
      prevSearchQueryString !== searchQueryString ||
      isTabChanged
    ) {
      listInvoker(
        {
          pageNumber: pagination.pageNumber,
          payloadSize: pagination.payloadSize,
          searchQueryString,
          itemType: activeTabComponent(),
        },
        tabs?.find(tab => tab.active).componentValue,
      );
    }
  }, [pagination.pageNumber, pagination.payloadSize, searchQueryString, tabs]);

  useEffect(() => {
    dynamicBundleInfo();
    isLoading();
    if (hasDynamicBundle()) {
      ProductBundleSearchService.getAll({
        pageNumber: pagination.pageNumber,
        payloadSize: pagination.payloadSize,
        searchQueryString,
        itemType: activeTabComponent(),
      }).then(({ response }) => {
        const categories = [];
        const products = [];
        response.forEach(item => {
          if (item.mainItem.type === 'category') {
            categories.push(item);
          } else {
            products.push(item);
          }
        });
        setPBCategoryList(categories);
        setPBProductList(products);
        paginationSetter({
          totalRecords: response.totalRecordSize,
          pageNumber: response.pageNo,
          payloadSize: response.pageSize,
        });
        setTabs(setTabsContent());
        isLoaded();
      });
    } else {
      isLoaded();
    }
    if (document.location.pathname === '/dynamic_bundle') {
      if (hasDynamicBundle()) {
        browserHistory.push({
          pathname: '/dynamic_bundle/automated-bundles/list',
        });
      }
    }
  }, []);

  useEffect(() => {
    setActiveTab(tabs?.find(tab => tab.active)?.componentValue);
  }, [tabs]);

  if (!hasDynamicBundle()) {
    return (
      <div>
        <div className='promo promo--centered dynamic-bundle-discover-dashboard'>
          <p className='promo-text'>
            {t('Create Dynamic Bundles for your customers with Segmentify.')}
          </p>
          <a
            href='https://segmentify.com/solution/dynamic-bundles/'
            target='_blank'
            className='secondary-action'
            rel='noreferrer'
          >
            {t('Discover')}
          </a>
        </div>
      </div>
    );
  }

  const selectedItemActions = {
    toggleItem: id => {
      const tempSelectedItems = [...selectedItems];
      const index = tempSelectedItems.findIndex(item => item === id);
      if (index > -1) {
        tempSelectedItems.splice(index, 1);
      } else {
        tempSelectedItems.push(id);
      }
      setSelectedItems(tempSelectedItems);
    },
    selectAllItems: itemList => {
      setSelectedItems(itemList.map(item => item.id));
    },
    clearAllItems: () => {
      setSelectedItems([]);
    },
    deleteItems: () => {
      const isDeleteAll = selectedItems.length === 0;
      const deleteIds = isDeleteAll ? [] : selectedItems;
      const bundleType = activeTabComponent();
      const bundleTypeFirstLetterCapitalized =
        bundleType.charAt(0).toUpperCase() + bundleType.slice(1);
      const isMultiDelete = isDeleteAll || deleteIds.length > 1;

      const isAllSelectedOnPage =
        selectedItems.length === productBundleList.length ||
        selectedItems.length === categoryBundleList.length;
      const nextPaginationNumber =
        pagination.pageNumber === 1 || isDeleteAll
          ? 1
          : isAllSelectedOnPage
          ? pagination.pageNumber - 1
          : pagination.pageNumber;

      RemoveListItemModal({
        confirmBtn: true,
        title: (
          <>
            {`Remove the ${
              isDeleteAll ? 'All' : 'Selected'
            } ${bundleTypeFirstLetterCapitalized} ${
              isMultiDelete ? 'Bundles' : 'Bundle'
            } ?`}
          </>
        ),
        content: (
          <>
            {`Are you sure you want to remove the ${
              isDeleteAll ? 'All' : deleteIds.length
            } ${bundleTypeFirstLetterCapitalized} ${
              isMultiDelete ? 'Bundles' : 'Bundle'
            } ?`}
          </>
        ),
        deleteFn: () => {
          isLoading();
          return ProductBundleService.multiDelete({
            ids: deleteIds,
            bundleType: bundleType.toUpperCase(),
            isDeleteAll,
          })
            .then(() => {
              listInvoker(true, bundleType, nextPaginationNumber);
            })
            .finally(() => {
              setSelectedItems([]);
              isLoaded();
            });
        },
      });
    },
  };

  return (
    <>
      <SFYHeader
        pageTitle={t('Automated Bundles')}
        breadCrumbOptions={breadCrumbOptions}
      />

      <SFYWrapper>
        <SFYTab
          tabs={tabs}
          tabsSetter={setTabs}
          location={location}
          isImportVisible
          importExportOptions={getImportExportOptions()}
          selectedItems={selectedItems}
          selectedItemActions={selectedItemActions}
          hasBundleItemInList={
            productBundleList.length > 0 || categoryBundleList.length > 0
          }
        />
      </SFYWrapper>
    </>
  );
};

export default ProductBundle;
