import React, { Component } from 'react';
import { connect } from 'react-redux';

import { engagementConfirmOnLeave } from '../actions/ui';
import { t } from '../system/ui';
import { setTitle } from '../system/document';

import { advancedConfig } from '../constants/datamaps/campaign';
import EditCampaign from '../modules/campaign/edit';

class EditHeroBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 'Hero Banner',
      content: [
        {
          field: 'title',
          options: {
            label: 'Banner Name',
            required: true,
            tooltip: true,
            tooltipContent: 'Enter the title of the banner.',
          },
        },
        {
          field: 'url',
          options: {
            label: 'Target URL',
            name: 'url',
            required: true,
            tooltip: true,
            tooltipContent:
              'Enter the URL of the page that your visitors will be directed to when they click on the banner.',
          },
        },
        {
          field: 'imageWithUpload',
          options: {
            label: 'Banner Image',
            required: true,
            tooltip: false,
            tooltipContent:
              'Enter the URL of the notification image or upload an image.',
          },
        },
      ],
      contentExtra: {
        buttons: [
          { field: 'timingButton', ignoreRef: true },
          { field: 'audienceButton', ignoreRef: true },
        ],
        fields: [{ field: 'timingOptions' }, { field: 'audienceOptions' }],
      },
      options: [
        { field: 'name' },
        { field: 'startEndDate' },
        { field: 'devices' },
        {
          field: 'pages',
          options: {
            hasPageUrlFilter: true,
          },
        },
        {
          field: 'advancedConfig',
          options: advancedConfig.HERO_BANNER,
        },
      ],
      buttons: [
        { field: 'advancedConfigButton' },
        { field: 'goTest' },
        { field: 'goLive' },
      ],
    };
  }

  componentDidMount() {
    setTitle(t('Edit Hero Banner'));
    engagementConfirmOnLeave(this);
  }

  render() {
    return (
      <EditCampaign
        type='HERO_BANNER'
        title={this.state.title}
        content={this.state.content}
        contentExtra={this.state.contentExtra}
        options={this.state.options}
        buttons={this.state.buttons}
        campaignId={this.props.params.campaignId}
      />
    );
  }
}

export default connect()(EditHeroBanner);
