import React, { Component } from "react";
import classNames from "classnames";
import find from "lodash/find";

import { TextField } from "../../components/fields";
import { t } from "../../system/ui";
import SampleContentModal from "../../modules/emails/sample-content-modal";
import { modalActions, uiActions } from "../../actions";
import { getSampleContent } from "../../modules/push/ajax";
import Tooltip from "../../components/tooltip";

import Icons from "../../components/icons";

import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

class CouponLanguageBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      additionalTitles:
        Object.keys(this.props.additionalTitles).length !== 0
          ? this.props.additionalTitles
          : "",
      additionalDescriptions:
        Object.keys(this.props.additionalTitles).length !== 0
          ? this.props.additionalDescriptions
          : "",
      copyText: t("Click to copy")
    };

    this.langList = this.generateLangList(props);
    this.selectLanguage = this.selectLanguage.bind(this);
    this.debounced = debounce(() => {
      this.props.onLanguageChange(this.state.additionalTitles);
    }, 250);

    this.debouncedDesc = debounce(() => {
      this.props.onDescChange(this.state.additionalDescriptions);
    }, 250);

    this.debounced = this.debounced.bind(this);
    this.debouncedDesc = this.debouncedDesc.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
    this.onDescChange = this.onDescChange.bind(this);
    this.openSampleModal = this.openSampleModal.bind(this);
    this.couponCode = this.props.cuponCode;
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.additionalTitles, this.props.additionalTitles)) {
      this.setState({
        additionalTitles:
          Object.keys(nextProps.additionalTitles).length !== 0
            ? nextProps.additionalTitles
            : ""
      });
    }

    if (
      !isEqual(
        nextProps.additionalDescriptions,
        this.props.additionalDescriptions
      )
    ) {
      this.setState({
        additionalDescriptions:
          Object.keys(nextProps.additionalDescriptions).length !== 0
            ? nextProps.additionalDescriptions
            : ""
      });
    }
  }

  updateTextAreasByCouponCode() {
    if (this.getTextValue() || this.getTextValue()) {
      return;
    }

    const title = "Here is a {{coupon_code}} just for you!";
    const desc = "You can use {{coupon_code}} code to get 20% discount";
    this.onDataChange(title);
    this.onDescChange(desc);
  }

  onSelectSampleContent(contentType, selectedContent) {
    modalActions.closeModal();

    if (contentType === "titleList") {
      this.setState(
        {
          additionalTitles: {
            ...this.state.additionalTitles,
            [this.state.selectedLanguageTab]: selectedContent
          }
        }
      );

      if (this.props.onLanguageChange) {
        this.debounced();
      }
    }

    if (contentType === "descriptionList") {
      this.setState(
        {
          additionalDescriptions: {
            ...this.state.additionalDescriptions,
            [this.state.selectedLanguageTab]: selectedContent
          }
        }
      );

      if (this.props.onDescChange) {
        this.debouncedDesc();
      }
    }
  }

  openSampleModal(type) {
    let account = this.props.account;
    let mainLanguage = account.mainLanguage;

    let sampleContent = this.state.contentSamples;
    if (sampleContent.hasOwnProperty(this.state.selectedLanguageTab)) {
      sampleContent = sampleContent[this.state.selectedLanguageTab][type];
      if (!sampleContent) {
        sampleContent = [];
      }
    } else if (sampleContent.hasOwnProperty(mainLanguage)) {
      sampleContent = sampleContent[mainLanguage];
    } else {
      sampleContent = [];
    }

    let sampleContentModal = () => (
      <SampleContentModal
        content={sampleContent}
        contentType={type}
        onSelect={this.onSelectSampleContent.bind(this)}
        isReady={true}
      />
    );

    modalActions.openModal({
      title: "Select Push Content",
      content: sampleContentModal
    });
  }

  onSampleTextSelect(type) {
    if (!this.state.isReady) {
      uiActions.isLoading();
      getSampleContent("ABANDONED_CART", samples => {
        uiActions.isLoaded();
        this.setState({ contentSamples: samples, isReady: true }, () =>
          this.openSampleModal(type)
        );
      });
    } else {
      this.openSampleModal(type);
    }
  }

  generateLangList(props) {
    const { account, wizardLanguage } = props;
    let langList;
    if (
      account.hasOwnProperty("additionalLanguages") &&
      account.additionalLanguages.indexOf(account.mainLanguage) > -1
    ) {
      langList = account.additionalLanguages;
    } else {
      langList = [account.mainLanguage].concat(account.additionalLanguages);
    }

    langList = langList.map(lang => {
      const isDisabled =
        wizardLanguage != "SESSION_BASED" && wizardLanguage !== lang;
      return { value: lang, label: lang, isDisabled };
    });

    const availableItems = langList.filter(item => !item.isDisabled);
    const selectedItem = find(
      availableItems,
      item => item.label === this.state.selectedLanguageTab
    );

    if (!selectedItem) {
      this.selectLanguage(availableItems[0].label, true);
    }
    langList.push({ value: "SESSION_BASED", label: "Session based" });

    return langList;
  }

  selectLanguage(label, firstTime) {
    this.setState(
      {
        selectedLanguageTab: label
      },
      () => {
        firstTime && this.updateTextAreasByCouponCode();
        this.props.changeSelectedLanguage(label)
      }
    );
  }

  onDataChange(val) {
    this.setState(
      {
        additionalTitles: {
          ...this.state.additionalTitles,
          [this.state.selectedLanguageTab]: val
        }
      }
    );

    if (this.props.onLanguageChange) {
      this.debounced();
    }
  }

  onDescChange(val) {

    this.setState(
      {
        additionalDescriptions: {
          ...this.state.additionalDescriptions,
          [this.state.selectedLanguageTab]: val
        }
      }
    );

    if (this.props.onDescChange) {
      this.debouncedDesc();
    }
  }

  getTextValue() {
    let value = "";
    if (
      this.state.additionalTitles.hasOwnProperty(this.state.selectedLanguageTab)
    ) {
      value = this.state.additionalTitles[this.state.selectedLanguageTab];
    }
    return value;
  }

  getDescValue() {
    let value = "";
    if (
      this.state.additionalDescriptions.hasOwnProperty(
        this.state.selectedLanguageTab
      )
    ) {
      value = this.state.additionalDescriptions[this.state.selectedLanguageTab];
    }
    return value;
  }

  hasError(lang) {
    const { wizardLanguage } = this.props;
    if (wizardLanguage !== "SESSION_BASED") {
      return false;
    }

    const language = this.state.additionalTitles[lang];
    const langErr = !language || language === "";

    const desc = this.state.additionalDescriptions[lang];
    const descErr = !desc || desc === "";

    if (langErr || descErr) {
      return true;
    }

    return false;
  }

  render() {
    this.langList = this.generateLangList(this.props);
    return (
      <div className="push-abandonment__language-box">
        {this.langList.length > 2 && (
          <span className={"campaign-language-selection-extended"}>
            {this.langList.map((item, index) => {
              if (item.label !== "Session based") {
                return (
                  <span
                    onClick={() => this.selectLanguage(item.label)}
                    className={classNames("language-box", {
                      "is-selected":
                        this.state.selectedLanguageTab === item.label ||
                        (index === 0 &&
                          this.state.language === "SESSION_BASED" &&
                          this.state.selectedLanguageTab === ""),
                      "is-disabled": item.isDisabled,
                      "has-error": this.hasError(item.label)
                    })}
                  >
                    {item.label}
                  </span>
                );
              }
            })}
          </span>
        )}
        <TextField
          name={"title"}
          label="Title"
          className="item-stacked one-whole"
          value={this.getTextValue() || ""}
          required={true}
          tooltipText={t("Prepare the titles of your push")}
          tooltip={true}
          onChange={evt => {
            const val = evt.target.value;
            this.onDataChange(val);
          }}
          ref="title"
          isPush={true}
          validation={{ minLength: 1 }}
          renderProp={() => (
            <div
              className="sample-text"
              onClick={this.onSampleTextSelect.bind(this, "titleList")}
            >
              <Icons name="exampleText" width="14" height="16" color="#fff" />{" "}
              <span>{t("Select sample text")}</span>
            </div>
          )}
        />

        <TextField
          name={"description"}
          label="Description"
          className="item-stacked one-whole"
          value={this.getDescValue() || ""}
          required={true}
          tooltipText={t("Prepare the description of your push")}
          tooltip={true}
          onChange={evt => {
            const val = evt.target.value;
            this.onDescChange(val);
          }}
          ref="desc"
          validation={{ minLength: 1 }}
          renderProp={() => (
            <div
              className="sample-text"
              onClick={this.onSampleTextSelect.bind(this, "descriptionList")}
            >
              <Icons name="exampleText" width="14" height="16" color="#fff" />{" "}
              <span>{t("Select sample text")}</span>
            </div>
          )}
        />
      </div>
    );
  }
}

export default CouponLanguageBox;
