import React, { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { t } from '../../../system/ui';
import { TextField } from '../../fields';
import SelectAccountModules from './SelectAccountModules';
import { getSgfAccountId, module2privilege, userRoles } from './constants';
import { createMultiUser, getAccounts } from '../../../modules/account/ajax';
import { modalActions, uiActions } from '../../../actions';
import { getUserList } from '../../../reducers/user-management/extraReducers';
import { onResetFilterClick } from '../../../reducers/user-management';
import { setUpModules } from './common/common-exports';
import {
  addEditUserReducer,
  selectUserState,
} from './store/add-edit-user-reducer';

/**
 * @name SelectUserInfo
 * @description - SelectUserInfo component is a form component which is used to create a new user
 * @param {object} props
 * @returns {JSX.Element}
 */

const SelectUserInfo = ({
  inputArray,
  privileges,
  isInternal,
  selectedUserType,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user?.user);
  const { isValidating, isLoading, isLoaded, showNotification } = uiActions;
  const { closeModal } = modalActions;

  const [reducerState, reducerDispatcher] = useReducer(
    addEditUserReducer,
    selectUserState,
  );

  const {
    accountsOptions,
    selectedAccounts,
    modules,
    selectedModules,
    username,
    displayName,
    userRole,
    accountsRequiredError,
    usernameRequiredError,
    displayNameRequiredError,
    userRoleRequiredError,
    selectModuleRequiredError,
  } = reducerState;

  const processQuery = () => {
    let requiredPrivileges = privileges;
    if (!requiredPrivileges.length) {
      requiredPrivileges = selectedModules.map(item => module2privilege[item]);
      if (
        requiredPrivileges.length === 0 ||
        requiredPrivileges.length === modules.length
      )
        requiredPrivileges = ['ACCOUNT_ADMIN'];
    }

    const switchableAccounts =
      isInternal === true
        ? [getSgfAccountId()]
        : selectedAccounts.map(account => account.value);

    const processedUserRole = userRole.value ? userRole.value : userRole;

    const userObj = {
      displayName,
      username,
      privileges: requiredPrivileges,
      switchableAccounts,
    };

    if (selectedUserType === 'Account User') {
      userObj.userRole = processedUserRole;
    }

    return userObj;
  };

  const onAccountSelectChange = selectedValue => {
    const values =
      typeof selectedValue !== 'undefined' ? selectedValue : selectedAccounts;
    return reducerDispatcher({
      type: 'SET_SELECTED_ACCOUNTS',
      payload: values,
    });
  };

  const onAccountSelectInputChange = value => {
    if (value.length > 2) {
      const options = {
        accountId: value,
      };
      getAccounts(options, response => {
        const newOptions = response.map(({ features, accountId, domain }) => ({
          features,
          value: accountId,
          label: domain,
        }));
        reducerDispatcher({
          type: 'SET_ACCOUNTS_OPTIONS',
          payload: newOptions,
        });
      });
    } else {
      reducerDispatcher({
        type: 'SET_ACCOUNTS_OPTIONS',
        payload: [],
      });
    }
  };

  const onSelectModule = item => {
    if (item === 'All') {
      reducerDispatcher({
        type: 'SET_SELECTED_MODULES',
        payload: selectedModules.length === modules.length ? [] : modules,
      });
    } else {
      const newSelectedModules = selectedModules.includes(item)
        ? selectedModules.filter(module => module !== item)
        : [...selectedModules, item];
      reducerDispatcher({
        type: 'SET_SELECTED_MODULES',
        payload: newSelectedModules,
      });
    }
  };

  const moduleSetter = () => {
    const { newModules } = setUpModules(selectedAccounts);

    reducerDispatcher({
      type: 'SET_MODULES',
      payload: newModules,
    });
  };

  const inputHandler = e => {
    const { value, name } = e.target;
    if (name === 'displayName') {
      reducerDispatcher({
        type: 'SET_DISPLAY_NAME',
        payload: value,
      });
    }
    if (name === 'username') {
      reducerDispatcher({
        type: 'SET_USERNAME',
        payload: value.trim().toLowerCase(),
      });
    }
  };

  const onUserRoleChange = e => {
    reducerDispatcher({
      type: 'SET_USER_ROLE',
      payload: e,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    isValidating();
    isLoading();
    const query = processQuery();
    createMultiUser(user.account, query, async response => {
      isLoaded();
      if (response) {
        await dispatch(onResetFilterClick());
        await dispatch(getUserList());
        const notificationContent = () => (
          <div>{t('You have successfully created user.')}</div>
        );
        showNotification({ content: notificationContent });
        closeModal();
      }
    });
  };

  useEffect(() => {
    if (selectedUserType === 'Account User' && !selectedModules.length) {
      reducerDispatcher({
        type: 'SET_SELECT_MODULE_REQUIRED_ERROR',
        payload: true,
      });
    } else {
      reducerDispatcher({
        type: 'SET_SELECT_MODULE_REQUIRED_ERROR',
        payload: false,
      });
    }
  }, [selectedUserType, selectedModules.length]);

  useEffect(() => {
    if (displayName === '') {
      reducerDispatcher({
        type: 'SET_DISPLAY_NAME_REQUIRED_ERROR',
        payload: true,
      });
    } else {
      reducerDispatcher({
        type: 'SET_DISPLAY_NAME_REQUIRED_ERROR',
        payload: false,
      });
    }
  }, [displayName]);

  useEffect(() => {
    if (username === '') {
      reducerDispatcher({
        type: 'SET_USERNAME_REQUIRED_ERROR',
        payload: true,
      });
    } else {
      reducerDispatcher({
        type: 'SET_USERNAME_REQUIRED_ERROR',
        payload: false,
      });
    }
  }, [username]);

  useEffect(() => {
    if (modules.length > 0 && selectedModules.length > 0) {
      const newSelectedModules = selectedModules.filter(module =>
        modules.includes(module),
      );
      reducerDispatcher({
        type: 'SET_SELECTED_MODULES',
        payload: newSelectedModules,
      });
    }
  }, [modules]);

  useEffect(() => {
    if (!isInternal && selectedAccounts.length < 1) {
      reducerDispatcher({
        type: 'SET_ACCOUNTS_REQUIRED_ERROR',
        payload: true,
      });
      reducerDispatcher({
        type: 'SET_MODULES',
        payload: [],
      });
      reducerDispatcher({
        type: 'SET_SELECTED_MODULES',
        payload: [],
      });
    } else {
      reducerDispatcher({
        type: 'SET_ACCOUNTS_REQUIRED_ERROR',
        payload: false,
      });
      if (!isInternal) moduleSetter();
    }
  }, [selectedAccounts]);

  useEffect(() => {
    if (selectedUserType === 'Account User' && (!userRole || !userRole.value)) {
      reducerDispatcher({
        type: 'SET_USER_ROLE_REQUIRED_ERROR',
        payload: true,
      });
    } else {
      reducerDispatcher({
        type: 'SET_USER_ROLE_REQUIRED_ERROR',
        payload: false,
      });
    }
  }, [userRole]);

  return (
    <div className='select-user-container'>
      <h2>User Information</h2>
      <form onSubmit={e => handleSubmit(e)}>
        <div className='grid-container'>
          {inputArray.includes('displayName') && (
            <>
              <div className='grid-label'>
                <label htmlFor='displayName'>{t('Display Name')}</label>
              </div>
              <div className='grid-item display-name'>
                <TextField
                  id='displayName'
                  name='displayName'
                  className='width-95'
                  inputClassName='width-95'
                  required={displayNameRequiredError}
                  value={displayName}
                  onChange={inputHandler}
                />
              </div>
            </>
          )}

          {inputArray.includes('username') && (
            <>
              <div className='grid-label'>
                <label htmlFor='username'>{t('Mail Address')}</label>
              </div>
              <div className='grid-item mail-address'>
                <TextField
                  id='username'
                  name='username'
                  className='width-95'
                  inputClassName='width-95'
                  required={usernameRequiredError}
                  validation={{
                    email: true,
                    businessEmail: true,
                  }}
                  value={username}
                  onChange={inputHandler}
                />
              </div>
            </>
          )}

          {inputArray.includes('accounts') && (
            <>
              <div className='grid-label'>
                <label htmlFor='accounts'>{t('Accounts')}</label>
              </div>
              <div className='grid-item'>
                <div className='width-90'>
                  <Select
                    options={accountsOptions}
                    value={selectedAccounts}
                    placeholder='You can add one or more accounts'
                    name='accounts'
                    id='accounts'
                    clearable={false}
                    searchable
                    allowCreate={false}
                    onChange={onAccountSelectChange}
                    onInputChange={onAccountSelectInputChange}
                    multi
                  />
                </div>
              </div>
            </>
          )}

          {inputArray.includes('userRole') && (
            <>
              <div className='grid-label'>
                <label htmlFor='accounts'>{t('User Role')}</label>
              </div>
              <div className='grid-item'>
                <div className='width-90'>
                  <Select
                    value={userRole}
                    options={userRoles}
                    name='userRole'
                    onChange={onUserRoleChange}
                  />
                </div>
              </div>
            </>
          )}

          {inputArray.includes('modules') && (
            <SelectAccountModules
              modules={modules}
              selectModule={onSelectModule}
              selectedModules={selectedModules}
            />
          )}
        </div>
        <div className='user-manage-submit'>
          <button
            aria-label='submit-button'
            disabled={
              accountsRequiredError ||
              displayNameRequiredError ||
              usernameRequiredError ||
              userRoleRequiredError ||
              selectModuleRequiredError
            }
            className='tertiary-action'
            type='submit'
          >
            {t('Create')}
          </button>
        </div>
      </form>
    </div>
  );
};

SelectUserInfo.defaultProps = {
  isInternal: false,
};

SelectUserInfo.propTypes = {
  inputArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  privileges: PropTypes.arrayOf(PropTypes.string).isRequired,
  isInternal: PropTypes.bool,
};

export default SelectUserInfo;
