import { createReducer } from '../system/store';
import * as switchRegionActions from '../constants/actions/switchRegion';

const initialState = {
  selectedRegion: 'ALL',
};

export default createReducer(initialState, {
  [switchRegionActions.default.UPDATE_SWITCH_REGION]: (state, region) => {
    return { ...state, selectedRegion: region };
  },
  [switchRegionActions.default.RESET_SWITCH_REGION]: () => {
    return { ...initialState };
  },
});
