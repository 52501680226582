import React, { Component } from 'react';
import { connect } from 'react-redux';

import { uiActions, wizardActions } from '../../../actions';

import { TextField } from '../../../components/fields';
import { t } from '../../../system/ui';

class Title extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      name: 'title',
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    const title = this.props.campaign.title || '';
    this.updateValue(title);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.title);
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  onFieldChange(e) {
    this.updateValue(e.target.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <li className='item-title-field'>
        <TextField
          name='title'
          label={t(this.props.options.label)}
          className='item-stacked one-whole'
          value={this.state.value}
          required={
            this.props.forceOptional ? false : this.props.options.required
          }
          onChange={this.onFieldChange}
          tooltip={this.props.options.tooltip}
          tooltipText={this.props.options.tooltipContent}
          placeholder={this.props.options.placeholder}
        />
      </li>
    );
  }
}

Title.defaultProps = {
  options: {
    label: 'Title',
    required: true,
    tooltip: false,
    tooltipContent: '',
  },
};

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  Title,
);
