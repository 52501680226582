import React, { useContext, useReducer } from 'react';
import Select from 'react-select';
import { addSearchIndexTypes } from '../../../../../views/searchandising/global-configuration/constants/constants';
import { fixedInputtedNumber } from '../common/common-functions';
import './GCSearchResultsAddNew.scss';
import { Checkbox } from '../../../../fields';
import { Context as GlobalConfigurationContext } from '../../../../../views/searchandising/global-configuration/store/globalConfigurationProvider';
import { modalActions, uiActions } from '../../../../../actions';

const ModalInputReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_TYPE':
      return {
        ...state,
        type: payload,
      };
    case 'SET_NAME':
      return {
        ...state,
        name: payload,
      };
    case 'SET_WEIGHT':
      return {
        ...state,
        value: payload,
      };
    case 'SET_IS_FACED':
      return {
        ...state,
        isFaced: payload,
      };
    default:
      return state;
  }
};

/**
 * @name GCSearchResultsAddNew
 * @description Add New Item for Search Results Index
 * @param type {string} - Type of Modal (Add or Edit)
 * @param data {Object} - Data of Item for Edit
 * @param editAction {function} - Edit Action
 * @return {JSX.Element}
 * @constructor
 */
const GCSearchResultsAddNew = ({
  modalType = 'add',
  data = {},
  editAction = null,
}) => {
  const {
    state: { globalFields },
    addNewSearchResultsIndex,
  } = useContext(GlobalConfigurationContext);
  const getFieldType = fieldName => {
    return addSearchIndexTypes.find(item => item.value === fieldName);
  };
  const reducerInitialState = {
    type: data?.fieldType ? getFieldType(data.fieldType) : '',
    name: data?.fieldName || '',
    value: data?.weight || '',
    isFaced: data?.facetField || false,
  };
  const [state, dispatchModalInput] = useReducer(
    ModalInputReducer,
    reducerInitialState,
  );
  const { type, name, value, isFaced } = state;

  const onValueChange = e => {
    const fixedValue = fixedInputtedNumber(1, 5, e.target.value);
    dispatchModalInput({ type: 'SET_WEIGHT', payload: fixedValue });
  };

  const validate = () => {
    if (!type || !name || !value) {
      return 'Please fill all the required fields.';
    }

    let fixedGlobalFields = globalFields;
    if (modalType === 'edit') {
      fixedGlobalFields = globalFields.filter(
        item => item.index !== data.index,
      );
    }

    const isNameExist = fixedGlobalFields?.find(
      item => item.fieldName === name,
    );
    if (isNameExist) {
      return 'Index already existed with this name.';
    }

    return true;
  };

  const onAddNew = () => {
    const validation = validate();
    if (validation !== true) {
      const content = () => <p>{validation}</p>;
      uiActions.showNotification({
        content,
        className: 'notification-fail',
      });
      return;
    }

    const newItem = {
      status: 'ACTIVE',
      fieldName: name,
      fieldType: type.value,
      weight: value,
      facetField: isFaced,
    };

    addNewSearchResultsIndex(newItem);
    modalActions.closeModal();
  };

  const onEdit = () => {
    const validation = validate();
    if (validation !== true) {
      const content = () => <p>{validation}</p>;
      uiActions.showNotification({
        content,
        className: 'notification-fail',
      });
      return;
    }

    const newSearchResultsIndex = globalFields.map(item => {
      if (item.index === data.index) {
        return {
          ...item,
          fieldName: name,
          fieldType: type.value,
          weight: value,
          facetField: isFaced,
        };
      }
      return item;
    });

    editAction({ globalFields: newSearchResultsIndex });
    modalActions.closeModal();
  };

  return (
    <div className='gc-search-results-add-new'>
      <div className='gc-search-results-add-input-field'>
        <div className='gc-add-input-field-item'>
          <span className='gc-add-input-field-item-label'>Type*</span>
          <Select
            value={type}
            options={addSearchIndexTypes}
            onChange={selected => {
              dispatchModalInput({
                type: 'SET_TYPE',
                payload: selected,
              });
            }}
            searchable={false}
            placeholder='Select'
            className='gc-add-input-field-item-value gc-type-select'
            clearable={false}
          />
        </div>
        <div className='gc-add-input-field-item'>
          <span className='gc-add-input-field-item-label'>Name*</span>
          <input
            placeholder='Type here'
            type='text'
            className='gc-add-input-field-item-value gc-name-text-input'
            value={name}
            onChange={e => {
              dispatchModalInput({
                type: 'SET_NAME',
                payload: e.target.value,
              });
            }}
          />
        </div>
        <div className='gc-add-input-field-item'>
          <span className='gc-add-input-field-item-label'>Weight*</span>
          <input
            placeholder='Type here'
            type='number'
            className='gc-add-input-field-item-value gc-weight-text-input'
            value={value}
            onChange={onValueChange}
          />
        </div>
      </div>
      <Checkbox
        name='useAsFaced'
        label='Also use as facet'
        value='Also use as facet'
        checked={isFaced}
        onChange={() => {
          dispatchModalInput({
            type: 'SET_IS_FACED',
            payload: !isFaced,
          });
        }}
      />
      <div className='gc-search-results-add-button'>
        <button
          className='tertiary-action'
          type='button'
          onClick={() => (modalType === 'edit' ? onEdit : onAddNew)()}
        >
          {modalType === 'edit' ? 'Save' : 'Add Index'}
        </button>
      </div>
    </div>
  );
};

export default GCSearchResultsAddNew;
