import React, { Component, createRef } from 'react';
import { Creatable } from 'react-select';
import { ExpandableCardHeader } from '../index';
import './ExpandableCard.scss';
import { t } from '../../../../system/ui';
import Icons from '../../../icons';
import Fields from '../../../../modules/campaign/fields';
import Tooltip from '../../../tooltip';
import { TextField } from '../../../fields';
import { getSegmentByType } from '../../../../modules/segmentation/ajax';
import { decodeHtml } from '../../../../system/string';

/**
 * @name ExpandableCard
 * @description  ExpandableCard component is used to display a card with a header and a body. Body is hidden by default. Body can be expanded by clicking on the header. Body have responses array for survey
 * @param {object} props
 * @returns {ReactNode}
 */

export default class ExpandableCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      responses: [
        {
          response: '',
          image: '',
          segmentName: '',
          segmentId: null,
        },
        {
          response: '',
          image: '',
          segmentName: '',
          segmentId: null,
        },
      ],
      segments: [],
      fetchedStaticSegments: [],
      quizTitle: '',
      expandClickCount: 0,
      executed: false,
    };

    for (let i = 0; i < this.state.responses.length; i++) {
      i = React.createRef();
    }
  }

  /**
   * @name handleExpandClick
   * @description  handleExpandClick function is used to expand or collapse the body of the card
   */
  handleExpandClick = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
      expandClickCount: this.state.expandClickCount + 1,
    });
    if (this.state.expandClickCount < 2 && !this.state.executed) {
      for (let index = 0; index < this.state.responses.length; index++) {
        this.handleFillImgInput(index);
      }
      this.setState({ executed: true });
    }
  };

  /**
   * @name handleQuizTitle
   * @param e
   * @description handleQuizTitle function can update state
   */

  handleQuizTitle = e => {
    this.setState({ quizTitle: e.target.value });
  };

  /**
   * @name handleCreate
   * @description  handleCreate function is used to add a new response to the responses array
   * @params {event} e
   * @params {index} index
   * @returns {ReactNode}
   */
  handleCreate = (e, index) => {
    e.preventDefault();
    const copy = [
      ...this.state.responses,
      {
        response: '',
        image: '',
        segmentName: '',
        segmentId: null,
        responseId: this.state.responses.length + 1,
      },
    ];
    this.setState({ responses: copy });
    this[index] = createRef();
  };

  /**
   * @name handleDelete
   * @description  handleDelete function is used to delete a response from the responses array
   * @params {id} SegmentId
   * @returns {void}
   */
  handleDelete = id => {
    const copy = [...this.state.responses];
    copy.splice(id, 1);
    this.setState({ responses: copy });
  };

  /**
   * @name handleFormChange
   * @description  handleFormChange function is used to update the response of the responses array
   * @params {event} e
   * @params {index} index
   * @returns {void}
   */
  handleFormChange = (index, event) => {
    const image = 'image';
    const data = [...this.state.responses];

    data[index][event.target.name] = event.target.value;

    this.setState({ responses: data });

    this.handleRefImg(index, image);
  };

  /**
   * @name handleRefImg
   * @description  handleRefImg function is used to update the image of the responses array
   * @params {event} e
   * @params {index} index
   * @params {image} image string name of the image
   * @returns {void}
   */
  handleRefImg = (index, image = 'image') => {
    const data = [...this.state.responses];
    data[index][image] = this.refs[index].refs.imageWithUpload.state.imgSrc;
    this.setState({ responses: data });
  };

  /**
   * @name onSelectItemChange
   * @description  onSelectItemChange function is used to update the segmentation Selected item
   * @params {value} value:{object:{value:string, label:string}}}}
   * @returns {void}
   */
  onSelectItemChange = (value, index) => {
    this.controlRefImgUpload(index);
    const data = [...this.state.responses];

    data[index].segmentId = value.value;
    data[index].segmentName = value.label;

    this.setState({ ...this.state, responses: data });
  };

  /**
   * @name handleSegmentation
   * @description  handleSegmentation function is used to get the segmentation list
   * @returns {void}
   */
  handleSegmentation = async () => {
    getSegmentByType(
      response => {
        const activeSegments = response.filter(
          item => item.status === 'ACTIVE',
        );
        const segmentsAsOption = activeSegments.map(item => {
          return {
            value: item.id.toString(),
            label: decodeHtml(item.name),
            title: decodeHtml(item.name),
          };
        });
        this.setState({
          segments: segmentsAsOption,
          fetchedStaticSegments: [...segmentsAsOption],
        });
      },
      ['EVENT_BASED'],
    );
  };
  /**
   * @name handleFillImgInput
   * @description  handleFillImgInput function is fills related(index) input. This function for campaign edit page.
   * @returns {void}
   */

  handleFillImgInput = (index, image = 'image') => {
    const data = [...this.state.responses];
    setTimeout(() => {
      this.refs[index].refs.imageWithUpload.state.fileName = data[index][image];
      this.refs[index].refs.imageWithUpload.state.imgSrc = data[index][image];
    }, 200);
  };

  checkRefValue = () => {
    setTimeout(() => {
      this.handleExpandClick();
    }, 500);
  };

  handleImgClick = index => {
    setTimeout(() => {
      this.handleRefImg(index);
    }, 4000);
  };

  /**
   * @name controlRefImgUpload
   * @description  controlRefImgUpload:  This function controls image upload, index number matches picture with question.
   * @params {index}
   * @returns {void}
   */

  controlRefImgUpload = index => {
    this.handleRefImg(index);
  };

  componentDidMount() {
    this.handleSegmentation();
    this.checkRefValue();
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.setState({ responses: newProps.campaign.responses });
      this.setState({ quizTitle: newProps.campaign.quizTitle });
      this.handleSegmentation();
    }
  }

  ArrowRenderer() {
    return <Icons name='chevronDown' color='#979797' />;
  }

  render() {
    const { campaignType, campaign, comps } = this.props;
    const { responses, segments, quizTitle, isExpanded } = this.state;

    return (
      <div className='ExpandableCard'>
        <ExpandableCardHeader
          quizTitle={quizTitle}
          handleQuizTitle={this.handleQuizTitle}
          handleClick={this.handleExpandClick}
        />
        {isExpanded && (
          <>
            {responses.map((item, index) => (
              <div className='ExpandableCardItem' key={index}>
                <div className='ExpandableCardItem__header'>
                  <h3 className='ExpandableCardItem__header__title'>
                    {`Response ${index + 1}`} <sup>*</sup>
                  </h3>

                  {responses.length > 2 ? (
                    <div
                      className='ExpandableCardItem__header__remove'
                      onClick={() => this.handleDelete(index)}
                    >
                      <div className='ExpandableCardItem__header__remove__icon'>
                        <Icons
                          name='trashBin'
                          color='#CC4230'
                          width='12'
                          height='12'
                        />
                      </div>

                      <span>Remove</span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className='ExpandableCardItem__body'>
                  <div className='ExpandableCardItem__body__title'>
                    <label
                      htmlFor='responseTitle'
                      className='ExpandableCardItem__body__responseTitle'
                    >
                      Response :
                    </label>
                    <TextField
                      id='responseTitle'
                      type='text'
                      name='response'
                      className='item-stacked one-whole'
                      value={item.response}
                      required
                      onChange={event => this.handleFormChange(index, event)}
                    />
                  </div>

                  <div
                    className='ExpandableCardItem__body__sources'
                    onClick={() => this.handleImgClick(index)}
                  >
                    <Fields
                      comps={comps}
                      campaignType={campaignType}
                      campaign={campaign}
                      ref={index}
                      name='image'
                      item={item}
                    />

                    <div className='ExpandableCardItem__dropdown'>
                      <div className='ExpandableCardItem__dropdown__wrapper'>
                        <h5 className='ExpandableCardItem__dropdown__title'>
                          Select a Custom Segment
                        </h5>
                        <span className='ExpandableCardItem__dropdown__info'>
                          <Tooltip
                            content='Assign users who selected this answer to custom segments.'
                            alignment='left'
                          >
                            <a href='#' rel='noreferrer'>
                              <i className='icon-info' role='presentation'>
                                <span className='for-screenreader-only' />
                              </i>
                            </a>
                          </Tooltip>
                        </span>
                      </div>
                      <div className='ExpandableCardItem__select'>
                        <div className='ExpandableCardItem__select__wrapper'>
                          <Creatable
                            className={`criteria-field one-whole ${!item.segmentName &&
                              'has-error'}`}
                            placeholder='Select...'
                            name='segmentName'
                            clearable={false}
                            value={segments.find(
                              e => e.value === item?.segmentId?.toString(),
                            )}
                            options={segments}
                            onChange={value =>
                              this.onSelectItemChange(value, index)
                            }
                          />

                          <div className='ExpandableCardItem__select__icon'>
                            {this.ArrowRenderer()}
                          </div>
                        </div>
                        {!item.segmentName && (
                          <p className='item-error'>This field is required</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {responses.length === 5 ? (
              ''
            ) : (
              <button
                onClick={e => this.handleCreate(e)}
                className='ExpandableCard__add'
                type='button'
              >
                {t('Add_New_Response')}
              </button>
            )}
          </>
        )}
      </div>
    );
  }
}
