import React, { Component } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../system/document";
import { uiActions, wizardActions, searchWizardActions } from "../../actions";
import { confirm, t } from "../../system/ui";
import { clone } from "../../system/object";
import Fields from "../../modules/campaign/fields";
import WizardAdvancedConfig from "../wizard/advanced-config";
import classNames from "classnames";
import { isSwitchAccount } from "../../modules/auth/user";
import Tooltip from "../tooltip";
import { TextField } from "../fields";
import Icon from "../icon";
import WizardItems from "../../components/wizard/items";
import SearchItems from "../../components/search/search.item";
import { getSearch } from "../../modules/search/ajax";
import { searchAlgorithmTitles } from "../../constants/datamaps/wizard";

import {
  beforeSearchCampaignInitialData,
  initialState
} from "../../constants/datamaps/palantir";
import {
  singleSearch,
  assetText,
  goTestOrLive
} from "../../modules/campaigns/searchwizard";
import Ordering from "./common/Ordering";
import Banners from "./common/Banners";
import { facetedSearchRequest } from "../../actions/faceted-search";

class BeforeEditSearchCampaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignState: clone(initialState),
      campaign: clone(beforeSearchCampaignInitialData),
      showAdvancedConfig: false,
      AlgoString: "",
      selectedType: {
        page: this.props.routeParams.pagetype || "before-search-edit",
        placement: this.props.routeParams.placement || "fullWidth"
      },
      duplicate: {
        isDuplicate: false
      },
      campaignStatus: "",
      status: "test",
      banners: []
    };
    this.campaignDetails = this.campaignDetails.bind(this);
    this.setLang = this.setLang.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
    this.showAdvancedConfig = this.showAdvancedConfig.bind(this);
    this.hideAdvancedConfig = this.hideAdvancedConfig.bind(this);
    this.validate = this.validate.bind(this);
    this.onCampaignDataChange = this.onCampaignDataChange.bind(this);
    this.updateAlgorithmString = this.updateAlgorithmString.bind(this);
    this.save = this.save.bind(this);
    this.hasError = this.hasError.bind(this);

    this.bannerRef = null;
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      this.campaignDetails();
    }

    if (newProps.save !== false) {
      this.save(newProps.save);
    }
  }

  componentDidMount() {
    setTitle(t("Before Search Input"));
    uiActions.confirmOnLeave(this);
    this.setLang();
    uiActions.resetPage();
    uiActions.isValidated();
    searchWizardActions.wizardReset();
    wizardActions.wizardReset();
    wizardActions.wizardEdit();
    wizardActions.saveReset();
    this.campaignDetails();
    this.setBreadCrump();
  }

  componentWillUnmount() {
    searchWizardActions.wizardReset();
    wizardActions.wizardReset();
    wizardActions.wizardEdit();
    wizardActions.saveReset();
  }

  setBreadCrump() {
    uiActions.showBreadcrumb({
      home: {
        name: t("Search & Discovery"),
        icon: "search",
        slug: "/search"
      },
      top: {
        name: "Search Box",
        slug: "/search/searchbox"
      },
      current: {
        name: "Before Search Input"
      }
    });
  }

  setLang() {
    let account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
    let mainLanguage = account.mainLanguage;
    let additionalLanguages = account.additionalLanguages || [];
    let tempState = clone(this.state.campaign);
    let langs = additionalLanguages.concat(mainLanguage);
    tempState.stringSearchAssetTextMap = {};

    langs.map(lang => {
      if (tempState.stringSearchAssetTextMap[lang] === undefined) {
        tempState.stringSearchAssetTextMap[lang] = {};
        assetText.map(asset => {
          tempState.stringSearchAssetTextMap[lang][asset.name] = "";
        });
      }
    });

    this.setState({
      selectedLanguage: mainLanguage,
      campaign: tempState
    });
  }

  onChange(e) {
    this.setState({
      [e.name]: e.value
    });
  }

  selectLanguage(selectedLanguage, e) {
    e.preventDefault();
    this.setState({
      selectedLanguage: selectedLanguage
    });
  }

  showAdvancedConfig() {
    this.setState({
      showAdvancedConfig: true
    });
    uiActions.formEdited();
  }

  hideAdvancedConfig() {
    this.setState({
      showAdvancedConfig: false
    });
  }

  updateAlgorithmString(AlgoString) {
    this.setState({
      AlgoString: AlgoString
    });
  }

  onCampaignDataChange(e) {
    let newCustoms = clone(this.state.campaign);
    newCustoms.stringSearchAssetTextMap[this.state.selectedLanguage][
      e.target.name
      ] = e.target.value;
    this.setState({
      campaign: newCustoms
    });
  }

  validate(type) {
    let errors = document.querySelectorAll(".has-error");
    this.setState({
      status: type
    });
    if (errors.length === 0) {
      confirm({
        title:
          "Update Campaign on " +
          type.charAt(0).toUpperCase() +
          type.slice(1) +
          " Mode?",
        onConfirm: () => {
          this.save();
        },
        onCancel: () => {
          this.setState({
            isSubmitting: false
          });
        }
      });
    } else {
      uiActions.isValidating();
    }
  }

  save() {
    goTestOrLive(this, "update");
  }

  campaignDetails(options) {
    let campaign, campaignStatus;
    if (options && options.campaign) {
      campaign = options.campaign;
      campaignStatus = options.status === "ACTIVE" ? "Live" : "Test";
      if (options.status === "PASSIVE") {
        campaignStatus = "Passive";
      }
      this.setCampaignDetails(campaign, campaignStatus);
    } else {
      getSearch(this.state.campaign.instanceId, async response => {
        if (response != "") {
          campaign = response;
          singleSearch(this, campaign);

          let banners = await facetedSearchRequest(
            "searchbanner/get",
            null,
            "&instanceId=BEFORE_SEARCH"
          ).get();
          this.setState({
            banners
          });
          campaignStatus = response.status === "ACTIVE" ? "Live" : "Test";
          if (response.status === "PASSIVE") {
            campaignStatus = "Passive";
          }
          this.setCampaignDetails(campaign, campaignStatus);
        }
      });
    }
  }

  setCampaignDetails(campaign, campaignStatus) {
    campaign.stringSearchAssetTextMap = Object.assign(
      {},
      this.state.campaign.stringSearchAssetTextMap,
      campaign.stringSearchAssetTextMap
    );
    this.setState(
      {
        campaign: campaign,
        campaignStatus: campaignStatus
      },
      () => {
      }
    );
  }

  getTextValue(name) {
    const textMap = this.state.campaign.stringSearchAssetTextMap[
      this.state.selectedLanguage
      ];
    return textMap === undefined || textMap[name] === undefined
      ? ""
      : textMap[name];
  }

  hasError(lang) {
    let langErr = false;
    const language = this.state.campaign.stringSearchAssetTextMap[lang];

    if (!language) {
      langErr = true;
      return langErr;
    }

    Object.keys(language).forEach(text => {
      let findAsset = assetText.find(asset => asset.name == text);
      if ((!language[text] || language[text] === "") && findAsset) {
        langErr = true;
      }
    });
    return langErr;
  }

  updateOrders = orders => {
    this.setState(prevState => {
      const campaign = clone(prevState.campaign);
      campaign.orders = orders;
      return {
        campaign
      };
    });
  };

  render() {
    let account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;

    let langList =
      account.additionalLanguages &&
      account.additionalLanguages.indexOf(account.mainLanguage) > -1
        ? account.additionalLanguages
        : [account.mainLanguage].concat(account.additionalLanguages);
    langList = langList.map(lang => {
      return { value: lang, label: lang };
    });
    langList.push({ value: "SESSION_BASED", label: "Session based" });

    return (
      <form id="wizard" ref="wizard" className="wizard wizard-cards">
        {this.state.campaign.status !== "" ? (
          <h3 className="page-title">
            <span
              className={
                this.state.campaign.status.toLowerCase() === "active"
                  ? "status-label status-label-live"
                  : this.state.campaign.status.toLowerCase() === "test"
                    ? "status-label status-label-test"
                    : "status-label status-label-passive"
              }
            >
              {this.state.campaign.status.toLowerCase() === "active"
                ? "live"
                : this.state.campaign.status}
            </span>
            {
              searchAlgorithmTitles(this.state.campaign.instanceId)
                .campaignTitle
            }
          </h3>
        ) : (
          <h3 className="page-title">
            {
              searchAlgorithmTitles(this.state.campaign.instanceId)
                .campaignTitle
            }
          </h3>
        )}

        <div
          className="widget page-content-block"
          style={{
            background: "transparent",
            paddingTop: "0",
            display: this.state.showAdvancedConfig ? "none" : "block"
          }}
        >
          <ol
            className="form-elements wizard-comp search"
            style={{
              marginTop: "0",
              display: this.state.showAdvancedConfig ? "none" : "block",
              paddingTop: "10px"
            }}
          >
            <li>
              <SearchItems
                account={account}
                wizardLanguage={this.props.wizardLanguage}
                ref="searchItems"
              />
              <hr className="widget-separator" style={{ height: "10px" }} />
              <span>
                {t("Widget Titles")}{" "}
                <Tooltip
                  content="Prepare the asset titles as will appear on your onsite Search Box. The titles for Popular Categories, Popular Brands and Popular Keywords will appear in the searchbox before your customers make a search. The titles for Categories and Brands will appear in the searchbox when your customers start to make a search."
                  alignment="top"
                  newTooltip={true}
                  isInline={true}
                >
                  <Icon name="info" />
                </Tooltip>{" "}
              </span>
              {langList.length > 2 && (
                <span className={"campaign-language-selection-extended"}>
                  {langList.map((item, index) => {
                    if (item.label !== "Session based") {
                      return (
                        <span
                          key={index}
                          onClick={this.selectLanguage.bind(this, item.label)}
                          className={classNames("language-box", {
                            "is-selected":
                              this.state.selectedLanguage === item.label,
                            "has-error": this.hasError(item.label)
                          })}
                        >
                          {item.label}
                        </span>
                      );
                    }
                  })}
                </span>
              )}
              <div className="wizard-comp" style={{ height: "120px" }}>
                {assetText.map((asset, index) => {
                  return (
                    <span
                      key={index}
                      className="six-columns"
                      style={{ height: "100px" }}
                    >
                      <TextField
                        name={asset.name}
                        label={asset.label}
                        tooltip={asset.tooltip}
                        tooltipText={asset.tooltipText}
                        className="item-stacked one-whole"
                        value={this.getTextValue(asset.name)}
                        required={true}
                        onChange={this.onCampaignDataChange}
                        ref="title"
                        isSearch={true}
                      />
                    </span>
                  );
                })}
              </div>
              <hr className="widget-separator" style={{ height: "10px" }} />

              <Ordering
                key={"before search order " + this.state.campaign.instanceId}
                orders={this.state.campaign.orders ?? {}}
                type="instant"
                updateOrders={this.updateOrders}
                instanceId="BEFORE_SEARCH"
              />

              <hr className="widget-separator" style={{ height: "10px" }} />

              <Banners
                banners={this.state.banners ?? []}
                ref={bannerRef => {
                  this.bannerRef = bannerRef;
                }}
                isInstant={true}
              />

              <hr className="widget-separator" style={{ height: "10px" }} />

              <WizardItems
                id="bse"
                edit={false}
                duplicate={this.props.duplicate}
                selectedType={this.state.selectedType}
                selectedPage={this.props.router.params.pagetype}
                addPage={true}
                isReco={true}
                duplicate={this.state.duplicate}
                isSearch={true}
                updateAlgorithmString={this.updateAlgorithmString}
              />
            </li>
          </ol>
        </div>

        <div className="wizard-options email-wizard-option">
          <ol className="form-elements">
            <li>
              <Fields
                ref="campaignOptions"
                campaign={this.state.campaign}
                comps={this.state.campaignState.config}
              />
            </li>
            <li className="buttons">
              <a
                className="button secondary-action one-whole"
                onClick={this.showAdvancedConfig}
              >
                {t("Advanced Configuration")}
              </a>
              <a
                className={classNames("button gotest-action one-whole", {
                  "gotest-action--disabled":
                    this.state.isSubmitting &&
                    this.state.submittingButton === "test"
                })}
                onClick={this.validate.bind(null, "test")}
              >
                {t("Go Test")}
                {this.state.isSubmitting &&
                  this.state.submittingButton === "test" && (
                    <span className="page-progress page-progress--button" />
                  )}
              </a>
              <a
                className={classNames(
                  "button tertiary-action one-whole golive-action",
                  {
                    "golive-action--disabled":
                      this.state.isSubmitting &&
                      this.state.submittingButton === "live"
                  }
                )}
                onClick={this.validate.bind(null, "live")}
              >
                {t("Go Live")}
                {this.state.isSubmitting &&
                  this.state.submittingButton === "live" && (
                    <span className="page-progress page-progress--button" />
                  )}
              </a>
            </li>
          </ol>
        </div>
        <WizardAdvancedConfig
          ref="wizardAdvancedConfig"
          campaign={this.state.campaign}
          show={this.state.showAdvancedConfig}
          onHide={this.hideAdvancedConfig}
          campaignType="palantir"
          isPalantir={true}
          campaignSpecifics={this}
        />
      </form>
    );
  }
}

const MapStatesToProps = store => ({
  switchedUser: store.switchedUser.switchedUser,
  user: store.user.user,
  save: store.wizard.save,
  wizardLanguage: store.wizard.wizardForm.language,
  criteria: store.wizard.criteria,
  searchCriteria: store.searchWizard.criteria,
  searchExcludes: store.searchWizard.excludes,
  excludes: store.wizard.excludes,
  validate: store.ui.validate
});

export default connect(MapStatesToProps)(BeforeEditSearchCampaign);
