/**
 * @author Barış Altun
 */

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { StickyTable, Row, Cell } from 'react-sticky-table';

import { browserHistory } from 'react-router';
import cn from 'classnames';
import BadResponseCat from 'components/BadResponseCat';
import {
  bannerifyQuery,
  bannerifyTable,
} from '../constants/datamaps/analytics';

import {
  uiActions,
  filterActions,
  bannerifyActions,
  dateActions,
} from '../actions';

import { hasBannerify, hasModulePrivilege } from '../modules/auth/user';
import { productUrl } from '../modules/trendify/page';
import { getBanners, getBannerCount } from '../modules/bannerify/ajax';
import { getBannerGroups, getBannerTitles } from '../modules/account/ajax';

import { t } from '../system/ui';
import { clone } from '../system/object';
import { reformatDate, queryDate } from '../system/date';
import { setTitle } from '../system/document';
import { localeString, decodeHtml } from '../system/string';
import { calculateRatio } from '../system/ratio';

import Icon from '../components/icon';
import Export from '../components/export';
import Tooltip from '../components/tooltip';
import Currency from '../components/currency';
import Filters from '../components/filters/filters';
import TableHeadings from '../components/table/heading';
import BannerDetails from '../components/analytics/banner.details';
import BannerifyFilters from '../components/filters/bannerify-filters';
import transformAnalyticsSorting from '../modules/transform/analyticsTransforms';
import BannerifyCompare from '../components/analytics/banner.compare';
import ScrollUpButtonAnalytics from '../components/scrollUpAnalytics';

import { ExternalAppUtility } from '../external';

const dummyRows = [
  {
    banner: {
      title: '',
      group: '',
      order: 3,
      urls: ['a'],
      image: '',
      productIds: [],
      categories: [],
      brands: [],
      labels: [],
      status: 'ACTIVE',
      insertTime: 1512401107044,
      updateTime: 1522308158397,
      stats: {
        allImpression: 6,
        pcImpression: 6,
      },
    },
  },
];

class Bannerify extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      query: clone(bannerifyQuery),
      sort: 'ASC',
      currentSorted: "record->>'order'",
      selectedGroup: '',
      selectedStatus: 'latest',
      device: 'all',
      showBannerifyFilters: false,
      showLoadingAnimation: true,
      showScrollToTop: false,
      maxHeight: 0,
      newRows: [],
    };

    this.refresh = this.refresh.bind(this);
    this.sort = this.sort.bind(this);
    this.reset = this.reset.bind(this);
    this.exportFile = this.exportFile.bind(this);
    this.showGroup = this.showGroup.bind(this);
    this.showBanner = this.showBanner.bind(this);
    this.withTooltip = this.withTooltip.bind(this);
    this.onBannerCompare = this.onBannerCompare.bind(this);
    this.toggleAnalyticsFilters = this.toggleAnalyticsFilters.bind(this);
    this.setScrollToTop = this.setScrollToTop.bind(this);
    this.calculateSticky = this.calculateSticky.bind(this);
    this.onTableScroll = this.onTableScroll.bind(this);
    this.filterNoDataBanners = this.filterNoDataBanners.bind(this);
  }

  toggleAnalyticsFilters() {
    this.setState({
      showBannerifyFilters: !this.state.showBannerifyFilters,
    });
  }

  componentDidMount() {
    setTitle('Bannerify');
    this.setBodyOverflow('hidden');
    uiActions.hideBreadcrumb();
    uiActions.addPageClass(
      classNames('bannerify', {
        'sidebar-is-hidden': hasBannerify(),
        'no-integration': !hasBannerify(),
      }),
    );
    if (hasBannerify()) {
      uiActions.isLoading();
      filterActions.filtersReset();
      dateActions.updateRangeAlias('lastSevenDays', false);

      getBanners(this.state.query, response => {
        this.setState(
          {
            rows: response,
            newRows: response,
            showLoadingAnimation: false,
          },
          () => {
            this.setScrollToTop();
            this.calculateSticky();
            /*
                    if(document.getElementsByClassName("sticky-table-y-wrapper") && document.getElementsByClassName("sticky-table-y-wrapper")[0]){
                        document.getElementsByClassName("sticky-table-y-wrapper")[0].addEventListener('scroll', this.setScrollToTop);
                    }
                    */
          },
        );
        uiActions.isLoaded();
      });

      getBannerCount(this.state.query, response => {
        bannerifyActions.updateCount(response);
      });

      getBannerGroups(response => {
        bannerifyActions.updateGroups(response);
      });

      getBannerTitles(response => {
        bannerifyActions.updateTitles(response);
      });
    }
  }

  componentWillUnmount() {
    this.setBodyOverflow('scroll');
  }

  setBodyOverflow(overflowType) {
    const bodyEl = document.querySelector('body');
    bodyEl.style.overflowY = overflowType;
  }

  setSideBarHidden() {
    const bodyEl = document.querySelector('#primary-sidebar');
    if (bodyEl) {
      bodyEl.style.display = 'none';
    }
  }

  calculateSticky() {
    const element = this.refs.scrollElement;
    if (element) {
      const { maxHeight } = element.style;
      const headerEl = document.querySelector('.sticky-table-header-wrapper')
        .clientHeight;
      document.querySelector('.sticky-table').style.maxHeight = maxHeight;
      document.querySelector(
        '.sticky-table-y-wrapper',
      ).style.maxHeight = `${parseInt(maxHeight.split('px')[0]) -
        headerEl -
        20}px`;

      let addBorderToProducts = document.querySelectorAll(
        '.product-tableColumn--latest',
      );
      addBorderToProducts = [].slice.call(addBorderToProducts);
      addBorderToProducts.forEach(domEl => {
        domEl.parentElement.style.borderRight = '1px solid #ecebf7';
      });

      let addBorderToHeader = document.querySelectorAll('.add-border');
      addBorderToHeader = [].slice.call(addBorderToHeader);
      addBorderToHeader.forEach(domEl => {
        domEl.parentElement.style.borderRight = '1px solid #ecebf7';
      });

      /// Main Header Borders
      const columnsLength = 8;
      const conversionLength = columnsLength + 10;
      const allLength = conversionLength + 1;

      const headers = document.querySelectorAll(
        '.sticky-table-header .sticky-table-row:first-child .sticky-table-cell',
      );
      const mainHeaders = document.querySelectorAll('.tfy-table-header');

      const intervals = [];
      intervals.push({ start: 0, end: columnsLength });
      intervals.push({ start: columnsLength, end: conversionLength });

      const widths = [];
      intervals.forEach((interval, intervalInd) => {
        let startWidth = 0;
        let endWidth = 0;
        [].forEach.call(headers, function(item, index) {
          // :) hooray `item` can be used here
          if (index < interval.start) {
            startWidth += item.clientWidth;
          }
          if (index < interval.end) {
            endWidth += item.clientWidth;
          }
        });
        if (intervalInd !== 0) {
          widths.push({
            totalWidth:
              endWidth - startWidth + widths[intervalInd - 1].totalWidth,
            width:
              widths[intervalInd + -1].totalWidth + (endWidth - startWidth) / 2,
          });
        } else {
          widths.push({
            totalWidth: endWidth - startWidth,
            width: (endWidth - startWidth) / 2,
          });
        }
      });

      [].forEach.call(mainHeaders, function(mainHeader, mainHeaderInd) {
        mainHeader.style.left = `${widths[mainHeaderInd].width}px`;
      });

      document.querySelector(
        '.sticky-table-header .sticky-table-row',
      ).style.height = '50px';
    }
    this.filterNoDataBanners();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      uiActions.addPageClass(
        classNames('bannerify', {
          'sidebar-is-hidden': hasBannerify(),
          'no-integration': !hasBannerify(),
        }),
      );
      if (Object.keys(this.refs).length) {
        this.refresh();
        this.filterNoDataBanners();
      }
    }

    if (newProps.update === true) {
      this.refresh(newProps, 250, 1);
    }

    if (newProps.reset === true) {
      this.reset();
    }

    if (newProps.pageSize !== this.props.pageSize) {
      this.refresh(newProps);
    }

    if (
      newProps.pageSize !== this.props.pageSize ||
      newProps.pageNumber !== this.props.pageNumber
    ) {
      this.refresh(newProps, 250, newProps.pageNumber);
      this.filterNoDataBanners();
    }
  }

  query(newProps, pageSize, pageNumber) {
    const query = clone(bannerifyQuery);
    const range = newProps ? newProps.range : this.props.range;
    const group = this.refs.pageFilters.querySelector('[name=bannerGroup]');
    const title = this.refs.pageFilters.querySelector('[name=bannerTitle]');
    const status = this.refs.pageFilters.querySelector('[name=bannerStatus]');
    const device = this.refs.pageFilters.querySelector('[name=visitorDevice]')
      .value;

    query.startDate = queryDate(range[0]);
    query.endDate = queryDate(range[1]);
    query.group = group.value;
    query.title = title.value;
    query.items = this.props.pageSize;
    query.page = this.props.pageNumber;
    query.aggregation = status.value;
    query.reverse = this.state.sort === 'DESC';

    if (query.title !== '') {
      query.aggregation = 'all';
    }

    query.sort = transformAnalyticsSorting(this.state.currentSorted, device);

    if (
      typeof newProps !== 'undefined' &&
      typeof newProps.pageSize !== 'undefined'
    ) {
      query.items = 250;
    }

    if (
      typeof newProps !== 'undefined' &&
      typeof newProps.pageNumber !== 'undefined'
    ) {
      query.page = pageNumber || 1;
    }

    return query;
  }

  refresh(newProps, pageSize, pageNumber) {
    uiActions.isLoading();

    const query = this.query(newProps, pageSize, pageNumber);

    getBanners(query, response => {
      this.setState(
        {
          rows: response,
          newRows: response,
          query,
          device: this.refs.pageFilters.querySelector('[name=visitorDevice]')
            .value,
          showLoadingAnimation: false,
        },
        () => {
          this.setScrollToTop();
          this.calculateSticky();
        },
      );

      uiActions.isLoaded();
    });

    getBannerCount(query, response => {
      bannerifyActions.updateCount(response);
    });

    filterActions.filtersUpdated();
  }

  filterNoDataBanners() {
    const { rows } = this.state;
    const device = `${this.state.device}Click`;
    const impression = `${this.state.device}Impression`;
    if (
      rows !== undefined &&
      typeof rows === 'object' &&
      rows.length !== 0 &&
      rows[0].hasOwnProperty('banner') &&
      this.state.device !== 'all'
    ) {
      this.setState({
        newRows: rows.filter(item => {
          return (
            item?.banner?.stats?.hasOwnProperty(device) ||
            item?.banner?.stats?.hasOwnProperty(impression)
          );
        }),
      });
    } else {
      this.setState({
        newRows: this.state.rows,
      });
    }
  }

  reset() {
    const query = clone(bannerifyQuery);

    this.setState({
      selectedGroup: '',
      selectedStatus: 'latest',
    });

    uiActions.isLoading();
    filterActions.filtersReset();

    getBanners(query, response => {
      this.setState(
        {
          rows: response,
          newRows: response,
          sort: 'ASC',
          currentSorted: "record->>'order'",
          selectedGroup: '',
          selectedStatus: 'latest',
          device: 'all',
          showBannerifyFilters: false,
          showScrollToTop: false,
          maxHeight: 0,
        },
        () => {
          this.setScrollToTop();
          this.calculateSticky();
          /*
                    if(document.getElementsByClassName("sticky-table-y-wrapper") && document.getElementsByClassName("sticky-table-y-wrapper")[0]){
                        document.getElementsByClassName("sticky-table-y-wrapper")[0].addEventListener('scroll', this.setScrollToTop);
                    }
                    */
        },
      );
      uiActions.isLoaded();
    });

    getBannerCount(this.state.query, response => {
      bannerifyActions.updateCount(response);
    });

    getBannerGroups(response => {
      bannerifyActions.updateGroups(response);
    });

    getBannerTitles(response => {
      bannerifyActions.updateTitles(response);
    });
  }

  showGroup(group) {
    this.setState(
      {
        selectedGroup: group,
        selectedStatus: 'all',
      },
      this.refresh,
    );
  }

  showBanner(banner) {
    const query = this.query();

    query.title = banner.title;
    query.aggregation = 'all';
    query.group = banner.group;

    const content = () => (
      <BannerDetails query={query} statPrefix={this.state.device} />
    );

    uiActions.openModal({ title: banner.title, content });
  }

  sort(sorting) {
    uiActions.isLoading();

    const query = clone(this.state.query);

    query.sort = transformAnalyticsSorting(sorting, this.state.device);
    query.reverse =
      this.state.currentSorted === sorting ? !query.reverse : query.reverse;
    this.setState({
      query,
      currentSorted: sorting,
      sort: query.reverse ? 'DESC' : 'ASC',
    });

    getBanners(query, response => {
      this.setState(
        {
          rows: response,
          newRows: response,
        },
        () => {
          this.setScrollToTop();
          this.calculateSticky();
        },
      );

      uiActions.isLoaded();
    });
  }

  exportFile(e) {
    e.preventDefault();

    const { range } = this.props;
    const name = `Bannerify_Report_${range[0].format(
      'MMMM_D_YYYY',
    )}-${range[1].format('MMMM_D_YYYY')}.csv`;

    const content = () => (
      <Export name={name} query={this.query()} endpoint='export/bannerify' />
    );

    uiActions.openModal({ title: t('Export Bannerify Report'), content });
  }

  getItemTargetData(item) {
    let itemTargetData = [];
    if (item?.banner?.categories?.length) {
      if (item?.banner?.categories?.length > 2) {
        const summmaryCats = item?.banner?.categories
          .slice(0, 2)
          .map(x => {
            return decodeHtml(x);
          })
          .join(',');
        itemTargetData.push(
          <span>
            <span style={{ display: 'block' }}>{t('Categories')}: </span>
            <Tooltip
              content={item?.banner?.categories
                .map(x => {
                  return decodeHtml(x);
                })
                .join(',')}
              newTooltip
              alignment='top'
            >
              {`${summmaryCats} ...`}
            </Tooltip>
          </span>,
        );
      } else {
        const itemHTML = `${t('Categories')}: ${item?.banner?.categories
          .map(x => {
            return decodeHtml(x);
          })
          .join(',')}`;
        itemTargetData.push(
          <span style={{ display: 'block' }}>
            {this.withTooltip(itemHTML)}
          </span>,
        );
      }
    }
    if (item?.banner?.productIds?.length) {
      if (item?.banner?.productIds?.length > 2) {
        const summaryProducts = item?.banner?.productIds
          ?.slice(0, 2)
          .map(x => {
            return decodeHtml(x);
          })
          .join(',');
        itemTargetData.push(
          <span className='banner-targets'>
            <span style={{ display: 'block' }}>{t('Products')}: </span>
            <Tooltip
              content={item?.banner?.productIds
                .map(x => {
                  return decodeHtml(x);
                })
                .join(',')}
              newTooltip
              alignment='top'
            >
              {`${summaryProducts} ...`}
            </Tooltip>
          </span>,
        );
      } else {
        const itemHTML = `${t('Products')}: ${item?.banner?.productIds
          .map(x => {
            return decodeHtml(x);
          })
          .join(',')}`;
        itemTargetData.push(
          <span style={{ display: 'block' }}>
            {this.withTooltip(itemHTML)}
          </span>,
        );
      }
    }
    if (item?.banner?.brands?.length) {
      if (item?.banner?.brands?.length > 2) {
        const summaryBrands = item?.banner?.brands
          .slice(0, 2)
          .map(x => {
            return decodeHtml(x);
          })
          .join(',');
        itemTargetData.push(
          <span className='banner-targets'>
            <span style={{ display: 'block' }}>{t('Brands')}: </span>
            <Tooltip
              content={item?.banner?.brands
                .map(x => {
                  return decodeHtml(x);
                })
                .join(',')}
              newTooltip
              alignment='top'
            >
              {`${summaryBrands} ...`}
            </Tooltip>
          </span>,
        );
      } else {
        const itemHTML = `${t('Brands')}: ${item?.banner?.brands
          .map(x => {
            return decodeHtml(x);
          })
          .join(',')}`;
        itemTargetData.push(
          <span style={{ display: 'block' }}>
            {this.withTooltip(itemHTML)}
          </span>,
        );
      }
    }
    if (item?.banner?.labels?.length) {
      if (item?.banner?.labels?.length > 2) {
        const summaryLabels = item?.banner?.labels
          .slice(0, 2)
          .map(x => {
            return decodeHtml(x);
          })
          .join(',');
        itemTargetData.push(
          <span className='banner-targets'>
            <span style={{ display: 'block' }}>{t('Labels')}: </span>
            <Tooltip
              content={item?.banner?.labels
                .map(x => {
                  return decodeHtml(x);
                })
                .join(',')}
              newTooltip
              alignment='top'
            >
              {`${summaryLabels} ...`}
            </Tooltip>
          </span>,
        );
      } else {
        const itemHTML = `${t('Labels')}: ${item?.banner?.labels
          .map(x => {
            return decodeHtml(x);
          })
          .join(',')}`;
        itemTargetData.push(
          <span style={{ display: 'block' }}>
            {this.withTooltip(itemHTML)}
          </span>,
        );
      }
    }

    if (itemTargetData.length === 0) {
      itemTargetData = <span>{t('All Products')}</span>;
    }

    return <div>{itemTargetData}</div>;
  }

  withTooltip(el) {
    if (el?.length > 50) {
      return (
        <Tooltip content={el} newTooltip alignment='top'>
          <div>{`${el.substring(0, 50)} ...`}</div>
        </Tooltip>
      );
    }
    return el;
  }

  onBannerCompare(banner, device, e) {
    e.preventDefault();
    const content = () => (
      <BannerifyCompare
        bannerTitle={banner?.title}
        bannerGroup={banner?.group}
        device={device}
        record={banner}
      />
    );
    uiActions.openModal({ title: banner?.title, content });
  }

  setScrollToTop() {
    /*
        let element = document.getElementsByClassName("sticky-table-y-wrapper")[0];
        let showButton = !!element;
        if(element){
            if(element.scrollHeight <= parseFloat(element.style["max-height"].split("px")[0])){
                showButton = false;
            }
            if(element.scrollTop <= 100 ){
                showButton = false;
            }
        }
        this.setState({
            showScrollToTop: showButton
        })
        */
  }

  onTableScroll(event) {
    let showButton = true;
    if (event.scrollTop <= this.state.maxHeight) {
      showButton = false;
    }
    if (event.scrollTop <= 100) {
      showButton = false;
    }
    if (showButton !== this.state.showScrollToTop) {
      this.setState({
        showScrollToTop: showButton,
      });
    }
  }

  render() {
    if (
      this.props.user.displayName &&
      !hasModulePrivilege(false, 'BANNERUSER')
    ) {
      browserHistory.push('/');
    }
    const tableHeight = window.innerHeight - 160;
    const dummyRowsToShow = [];
    for (let i = 0; i < 15; i++) {
      dummyRowsToShow.push(dummyRows[0]);
    }
    const rows = this.state.showLoadingAnimation
      ? dummyRowsToShow
      : this.state.newRows;
    const columnsLength = 8;
    const conversionLength = columnsLength + 10;
    const allLength = conversionLength + 1;

    const { badResponseCatVisible } = this.props;

    const parentClass = cn(
      'segmentify-analytics segmentify-analytics--trendify',
      {
        '.hide': badResponseCatVisible,
      },
    );
    return (
      <>
        <div className={parentClass}>
          {!hasBannerify() && (
            <h2 className='page-title'>
              <span className='for-screenreader-only'>{t('Bannerify')}</span>
            </h2>
          )}
          {hasBannerify() ? (
            hasModulePrivilege(false, 'BANNERUSER') ? (
              <div>
                <div ref='pageFilters'>
                  <Filters
                    limitRange='months'
                    visibleUpdate={false}
                    analyticsFilters
                    toggleAnalyticsFilters={this.toggleAnalyticsFilters.bind(
                      this,
                    )}
                    isBannerifyFilter
                    exportFile={this.exportFile.bind(this)}
                  >
                    <BannerifyFilters
                      selectedGroup={this.state.selectedGroup}
                      selectedStatus={this.state.selectedStatus}
                      showBannerifyFilters={this.state.showBannerifyFilters}
                      toggleAnalyticsFilters={this.toggleAnalyticsFilters.bind(
                        this,
                      )}
                    />
                  </Filters>
                </div>

                {this.state.showLoadingAnimation ||
                this.state.newRows.length > 0 ? (
                  <div
                    className={
                      this.state.showLoadingAnimation
                        ? 'analytics-details analytics-placeholder'
                        : 'analytics-details'
                    }
                    style={{
                      maxHeight: `${tableHeight}px`,
                      overflow: 'hidden',
                    }}
                    ref='scrollElement'
                  >
                    <StickyTable
                      stickyColumnCount={0}
                      stickyHeaderCount={2}
                      onScroll={this.onTableScroll}
                    >
                      <Row>
                        {[...Array(allLength)].map((item, index) => {
                          let givenClass =
                            index === columnsLength - 1
                              ? `add-border add-border-${index}`
                              : '';
                          if (index === 1) {
                            givenClass += ' tfy-table-header analytics-product';
                          } else if (index === 2) {
                            givenClass +=
                              ' tfy-table-header analytics-conversion';
                          }

                          if (this.state.showLoadingAnimation) {
                            givenClass += '--loading';
                          }

                          return (
                            <Cell key={`header_cell_${item}`}>
                              <div className={givenClass}>
                                {index === 1 && t('Banner')}
                                {index === 2 && t('Website')}
                              </div>
                            </Cell>
                          );
                        })}
                      </Row>
                      <TableHeadings
                        currentSorted={this.state.currentSorted}
                        direction={this.state.sort}
                        heading={bannerifyTable.header}
                        isSticky
                        onSort={this.sort}
                      />
                      {rows.length
                        ? rows.map((item, index) => {
                            const statPrefix = this.state.device;
                            const order =
                              index + 1 + 250 * (this.props.pageNumber - 1);
                            return (
                              <Row key={`stat-row-${order}`}>
                                <Cell>
                                  <div>{order}</div>
                                </Cell>
                                <Cell>
                                  <div>
                                    <a
                                      onClick={this.showGroup.bind(
                                        null,
                                        item?.banner?.group,
                                      )}
                                    >
                                      {this.withTooltip(
                                        decodeHtml(item?.banner?.group),
                                      )}
                                    </a>
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>{item?.banner?.order}</div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {reformatDate(item?.banner?.insertTime)}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {reformatDate(item?.banner?.updateTime)}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    <span>
                                      {this.withTooltip(
                                        decodeHtml(item?.banner?.title),
                                      )}
                                    </span>
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    <Tooltip
                                      content={productUrl(item?.banner?.image)}
                                      type='image'
                                      newTooltip
                                      alignment='left'
                                    >
                                      <img
                                        src={productUrl(item?.banner?.image)}
                                        style={{
                                          height: 'auto',
                                          maxWidth: '100px',
                                        }}
                                      />
                                    </Tooltip>
                                  </div>
                                </Cell>
                                <Cell>
                                  <div className='product-tableColumn--latest'>
                                    {' '}
                                    {this.getItemTargetData(item)}
                                  </div>
                                </Cell>

                                <Cell>
                                  <div>
                                    {localeString(
                                      item?.banner?.stats[
                                        `${statPrefix}Impression`
                                      ],
                                    ) || '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {localeString(
                                      item?.banner?.stats[`${statPrefix}Click`],
                                    ) || '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {calculateRatio(
                                      item?.banner?.stats[`${statPrefix}Click`],
                                      item?.banner?.stats[
                                        `${statPrefix}Impression`
                                      ],
                                    )}
                                    %
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {localeString(
                                      item?.banner?.stats[
                                        `${statPrefix}ProdView`
                                      ],
                                    ) || '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {calculateRatio(
                                      item?.banner?.stats[
                                        `${statPrefix}ProdView`
                                      ],
                                      item?.banner?.stats[
                                        `${statPrefix}Impression`
                                      ],
                                    )}
                                    %
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {localeString(
                                      item?.banner?.stats[
                                        `${statPrefix}Basket`
                                      ],
                                    ) || '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {calculateRatio(
                                      item?.banner?.stats[
                                        `${statPrefix}Basket`
                                      ],
                                      item?.banner?.stats[`${statPrefix}Click`],
                                    )}
                                    %
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {localeString(
                                      item?.banner?.stats[
                                        `${statPrefix}Purchase`
                                      ],
                                    ) || '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {calculateRatio(
                                      item?.banner?.stats[
                                        `${statPrefix}Purchase`
                                      ],
                                      item?.banner?.stats[`${statPrefix}Click`],
                                    )}
                                    %
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    <Currency
                                      currency={
                                        item?.banner?.stats[
                                          `${statPrefix}Amount`
                                        ] || 0
                                      }
                                    />
                                  </div>
                                </Cell>

                                <Cell>
                                  <div>
                                    <nav className='account-nav action-nav'>
                                      <Icon name='menu' />
                                      <ul className='action-nav-popup'>
                                        <li>
                                          <a
                                            className='action-nav-popup-item'
                                            onClick={this.showBanner.bind(
                                              null,
                                              item?.banner,
                                            )}
                                            style={{ color: '#fff' }}
                                          >
                                            <Icon name='view' /> {t('Details')}
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className='action-nav-popup-item'
                                            onClick={this.onBannerCompare.bind(
                                              this,
                                              item?.banner,
                                              statPrefix,
                                            )}
                                            style={{ color: '#fff' }}
                                          >
                                            <Icon name='calendar' />{' '}
                                            {t('Compare')}
                                          </a>
                                        </li>
                                      </ul>
                                    </nav>
                                  </div>
                                </Cell>
                              </Row>
                            );
                          })
                        : [...Array(30)].map(() => {
                            return (
                              <Row>
                                {[...Array(allLength)].map(() => {
                                  return (
                                    <Cell>
                                      <div className='category-tableColumn'>
                                        BFY
                                      </div>
                                    </Cell>
                                  );
                                })}
                              </Row>
                            );
                          })}
                    </StickyTable>
                  </div>
                ) : (
                  <div className='data-content-message'>
                    <div className='data-content-message-text'>
                      <i className='icon-info' />{' '}
                      {t('There are no records to show.')}
                    </div>
                  </div>
                )}
                {this.state.showScrollToTop && <ScrollUpButtonAnalytics />}
              </div>
            ) : (
              <div>{t('User has no privilege')}</div>
            )
          ) : (
            <div className='promo promo--centered'>
              <p className='promo-text'>{t('BannerifyPromo')}</p>
              {!ExternalAppUtility.isDengage() && (
                <a
                  href='https://segmentify.com/solution/growth-optimisation/'
                  target='_blank'
                  className='secondary-action'
                  rel='noreferrer'
                >
                  {t('Discover')}
                </a>
              )}
            </div>
          )}
        </div>
        {badResponseCatVisible && <BadResponseCat />}
      </>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  ui: store.ui.ui,
  pageSize: store.filters.pageSize,
  pageNumber: store.filters.pageNumber,
  update: store.filters.update,
  reset: store.filters.reset,
  badResponseCatVisible: store.ui.badResponseCatVisible,
});

export default connect(mapStatesToProps)(Bannerify);
