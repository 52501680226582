import React from 'react';
import './CampaignWidget.scss';
import Icons from '../../icons';
import Icon from '../../icon';
import { t } from '../../../system/ui';

/**
 * @name GFCampaignList component
 * @param actions
 * @returns {JSX.Element}
 * @constructor
 */

const CampaignWidget = ({
  name,
  type,
  icon,
  description,
  actionTitle,
  actionIcon,
  action,
}) => {
  return (
    <>
      <div
        className='available-type available-type-social-proof campaign-widget'
        onClick={() => action(type)}
      >
        <span className='available-type-wireframe'>
          <span className='campaign-title'>
            <Icons name={icon} />
            {t(name)}
          </span>
          <span className='campaign-description'>{description}</span>
          <div className='campaign-create-icon-field'>
            <Icon className='plus-icon' name={actionIcon} size='2x' />
            <span className='create-text'>{t(actionTitle)}</span>
          </div>
        </span>
      </div>
    </>
  );
};

export default CampaignWidget;
