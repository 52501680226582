export const getRockstarScore = type => {
  const scores = {
    RECOMMENDATION_TOP_SELLERS: 'PURCHASE',
    RECOMMENDATION_CATEGORY_ROCKS: 'ROCK',
    RECOMMENDATION_NEW_COMERS: 'ROCK',
    RECOMMENDATION_BRAND_PRODUCTS: 'ROCK',
    RECOMMENDATION_LABEL_PRODUCTS: 'ROCK',
    RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'ROCK',
    RECOMMENDATION_STATISTICAL_TOP_SELLERS: 'PURCHASE',
  };
  return scores[type] ? scores[type] : '';
};

export const isRockstarAlgorithmSelected = type =>
  type === 'RECOMMENDATION_TOP_SELLERS' ||
  type === 'RECOMMENDATION_STATISTICAL_TOP_SELLERS' ||
  type === 'RECOMMENDATION_CATEGORY_ROCKS' ||
  type === 'RECOMMENDATION_BRAND_PRODUCTS' ||
  type === 'RECOMMENDATION_LABEL_PRODUCTS' ||
  type === 'RECOMMENDATION_SOURCE_ALL_PRODUCTS' ||
  type === 'RECOMMENDATION_NEW_COMERS';
