import React from 'react';
import './style.scss';

import { playbookPageItems } from '../msp-static-data';
import { modalActions } from '../../../actions';

import Playbook from '../components/Playbook';

const OpenPlaybookModal = (playbookType, playbookItems) => {
  function content() {
    return (
      <Playbook playbookType={playbookType} playbookItems={playbookItems} />
    );
  }

  modalActions.openModal({
    content,
    className: 'playbook-modal',
  });
};

const titleRenderWithColor = (title, color) => {
  if (!color || !title.includes('|||')) {
    return title;
  }

  const splittedTitle = title.split('|||');

  return (
    <>
      {splittedTitle[0]}
      <span style={{ color }}> {splittedTitle[1]} </span>
      {splittedTitle[2]}
    </>
  );
};

// this function is used to open the modal for the playbooks
const conditionalModal = item => {
  item.modal
    ? OpenPlaybookModal(item.playbookType, item.playbookItems)
    : window.open(item.windowOpenUrl, '_blank');
};

const index = () => {
  return (
    <div className='msp-playbooks'>
      <div className='msp-playbooks__header'>
        <div className='msp-playbooks__header__title'>Playbooks</div>
      </div>
      <div className='msp-playbooks__content'>
        {playbookPageItems.map(item => {
          return (
            <div
              key={item.title}
              className='playbook-card clickable'
              onClick={() => {
                conditionalModal(item);
              }}
            >
              <div className='card-title'>
                {titleRenderWithColor(item.title, item.color)}
              </div>
              <div className='card-content'>
                <item.svgIcon className='card-image' bgColor={item.color} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default index;
