import { createReducer } from "../system/store";
import * as sgfDivTableActions from "../constants/actions/sgf-div-table";

const initialState = {
  clickedCellItem: {},
  clickedCellItemId: 0
};

export default createReducer(initialState, {
    [sgfDivTableActions.default.CLICK_CELL]: (state, payload) => {
        return Object.assign({}, state, {clickedCellItem: payload.cellItem, clickedCellItemId: Math.random()});
    },
    [sgfDivTableActions.default.CLEAR_SORT]: (state) => {
        return Object.assign({}, state, { clickedCellItem: {}, clickedCellItemId: Math.random() });
    }
})
