/**
 * @fileOverview This file open the modal of the show user list of account. Also, it is used to delete or unlock the user.
 * @requires react, react-redux
 */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
/**
 * Module dependencies
 */
import { deleteUser, unlockUser } from 'reducers/user-management/extraReducers';
import { t } from '../../system/ui';
import {
  getUserList,
  isMasterUser,
  isSuperUser,
} from '../../modules/auth/user';
import { dialogActions, modalActions, uiActions } from '../../actions';

/**
 * This component is used to show the list of user of the account.
 * Also, it is used to delete or unlock the user.
 * route for this component is /admin/accounts or /admin/stats
 * @param {Object} account - The account data passed to the component.
 * @returns {JSX.Element} - The JSX element.
 * */
const UserListModal = ({ account }) => {
  const { accountId } = account;
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);

  const setLoginInfo = () => {
    getUserList(accountId, response => {
      setUserList(response);
      modalActions.calculateModalHeight();
    });
  };

  useEffect(() => {
    setLoginInfo();
  }, []);

  /**
   * This function is used to delete the user.
   * This function not usable for 'SuperUser' and 'MasterUser'. Because, the buttons are not visible.
   * @param {String} username
   */
  const handleDelete = username => {
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t(`Are you sure to delete ${username}?`),
      onConfirm: async () => {
        await dispatch(deleteUser(username));
        dialogActions.closeDialog();
        await setLoginInfo();
        const notificationContent = () => (
          <div>
            {`${t('You have successfully deleted the user')} ${username}.`}
          </div>
        );
        uiActions.showNotification({ content: notificationContent });
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  };

  /**
   * This function is used to unlock the user.
   * This function not usable for 'SuperUser' and 'MasterUser'. Because, the buttons are not visible.
   * @param {String} username
   */
  const handleUnlock = username =>
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t(`Are you sure to unlock the locked user ${username}?`),
      onConfirm: async () => {
        await dispatch(unlockUser(username));
        dialogActions.closeDialog();
        await setLoginInfo();
        const notificationContent = () => (
          <div>{t(`User (${username}) unlocked successfully.`)}</div>
        );
        uiActions.showNotification({ content: notificationContent });
      },
      onCancel: () => dialogActions.closeDialog(),
    });

  return (
    <div className='user-list'>
      <table className='data-table'>
        <thead>
          <tr>
            <th>{t('User')}</th>
            <th>{t('Status')}</th>
            <th colSpan={2} style={{ textAlign: 'center' }}>
              {t('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {userList.map(user => {
            const locked = user.status === 'LOCKED';
            return (
              <tr key={user.username}>
                <td>{user.username}</td>
                <td>{locked ? 'Locked' : 'Active'}</td>
                {locked ? (
                  <>
                    {(isSuperUser || isMasterUser) && (
                      <td className='action-unlock'>
                        <span onClick={() => handleUnlock(user.username)}>
                          {t('Unlock')}
                        </span>
                      </td>
                    )}
                    <td
                      className='action-delete'
                      style={{
                        marginLeft: (isSuperUser || isMasterUser) && 'none',
                      }}
                    >
                      <span onClick={() => handleDelete(user.username)}>
                        {locked ? t('Delete User') : ''}
                      </span>
                    </td>
                  </>
                ) : (
                  <td colSpan={2} />
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default UserListModal;
