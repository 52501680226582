import React, { useEffect, useState } from 'react';
import { Creatable } from 'react-select';
import Icons from '../../../icons';
import { t } from '../../../../system/ui';
import { getFilterOptions } from '../../../../modules/shop-the-look/ajax';
import { decodeHtml } from '../../../../system/string';
import './AttributeComponents.scss';

const Color = props => {
  const [colors, setColors] = useState([]);
  const [color, setColor] = useState('');

  const onChange = selectedValue => {
    const selectedLabel = selectedValue !== undefined ? selectedValue : color;
    setColor(selectedLabel);

    props.onSelectChange('label', selectedLabel, props.id, props.segmentType);
  };

  const fetchColors = () => {
    const selectedColors = [];
    getFilterOptions('color').then(res => {
      const sorted = res.sort();
      const fetchedColors = [];
      sorted.forEach(sortedColor => {
        fetchedColors.push({
          value: sortedColor,
          label: decodeHtml(sortedColor),
        });
      });
      if (props.values.label !== undefined) {
        fetchedColors.push({
          value: props.values.label.value[0],
          label: decodeHtml(props.values.label.value[0]),
        });
        props.values.label.value.forEach(propsColor => {
          selectedColors.push({
            value: propsColor,
            label: decodeHtml(propsColor),
            title: decodeHtml(propsColor),
          });
        });
      }

      setColors(fetchedColors);
      setColor(selectedColors);
    });
  };

  useEffect(() => {
    fetchColors();
  }, []);

  return (
    <div className='sfy-color'>
      <div className='sfy-color-left'>
        <div className='sfy-color-icoText'>
          <span className='sfy-color-icon'>
            <Icons name='palette' color='#7A7A7C' width='17' height='17' />
          </span>
          <div className='sfy-color-titleSelectMain'>
            <div className='sfy-color-titleSelect'>
              <span className='sfy-color-title'>{t('Color')}</span>
            </div>
            <Creatable
              value={color}
              options={colors}
              name='label'
              clearable={false}
              onChange={onChange}
              className='criteria-field criteria-field-category'
              placeholder={t('Select Color')}
              multi
              shouldKeyDownEventCreateNewOption={e => {
                switch (e.keyCode) {
                  case 9:
                  case 13:
                    return !0;
                  default:
                    return !1;
                }
              }}
            />
          </div>
        </div>
      </div>

      <a
        onClick={() =>
          props.onFilterRemove(props.id, props.segmentType, props.type)
        }
        className='sfy-color-actions'
      >
        <Icons name='crossCircle' color='#D31116' width='18' height='18' />
      </a>
    </div>
  );
};

export default Color;
