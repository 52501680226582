import React from "react";
import PushEditCampaign from "./push.edit";
const PushPreviewCampaign = props => {
  return (
    <div className="non-editable">
      <PushEditCampaign {...props} isPreview={true} />
    </div>
  );
};

export default PushPreviewCampaign;
