import React, { useState } from 'react';
import './MoveToGroup.scss';
import { useSelector, useDispatch } from 'react-redux';
import { modalActions } from '../../../../../actions';
import DocumentIcon from '../../../../../components/icons/documentIcon';
import { moveCampaignToGroup } from '../../../../../modules/ds/ajax';
import { notification } from '../../../../../system/ui';
import {
  getCampaigns,
  getSingleCampaigns,
} from '../../../../../reducers/ds/extraReducers';

const MoveToGroup = campaignId => {
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(0);
  const dsCampaigns = useSelector(state => state.ds.criteriaCampaigns.data);
  const { closeModal } = modalActions;

  const handleClick = groupId => {
    /* const campaign = dsCampaigns.find(c => c.id === id);
    const index = dsCampaigns.indexOf(campaign); */
    setIsSelected(groupId);
  };

  const handleMove = () => {
    const segmentId = campaignId.campaignId;
    try {
      moveCampaignToGroup(isSelected, segmentId).then(() => {
        const content = () => <p>Successfully Moved</p>;

        notification({
          content,
          className: 'notification-success',
        });
        dispatch(getCampaigns());
        dispatch(getSingleCampaigns());
        closeModal();
      });
    } catch (error) {
      const content = () => <p>{error.message}</p>;
      notification({
        content,
        className: 'notification-fail',
      });
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <div className='MoveContainer'>
      {dsCampaigns.map(({ name, id: groupId, dynamicSegments }) => {
        return (
          <div
            key={groupId}
            className='GroupItem'
            onClick={() => {
              handleClick(groupId);
            }}
            selected={isSelected === groupId}
            style={{
              border:
                isSelected === groupId
                  ? '1px solid #17A447'
                  : '1px solid #e0e0e0',
            }}
          >
            <div className='GroupItem__icon'>
              <DocumentIcon color='#47607B' height='24px' width='24px' />
            </div>
            <div className='GroupItem__title'>{name}</div>
            <div className='GroupItem__number'>
              {dynamicSegments.length > 1
                ? `(${dynamicSegments.length} segments)`
                : `(${dynamicSegments.length} segment)`}
            </div>
          </div>
        );
      })}

      <div className='MoveContainer__actions'>
        <button
          type='button'
          className='button gotest-action'
          onClick={() => handleCancel()}
        >
          Cancel
        </button>
        <button
          type='button'
          className='button tertiary-action golive-action'
          onClick={() => handleMove()}
        >
          Move Here
        </button>
      </div>
    </div>
  );
};

export default MoveToGroup;
