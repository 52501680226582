/**
 * @fileOverview Actions for Countdown and its related components
 * @description This file contains the actions for the Countdown and its related components.
 * @param {React.dispatch} dispatch
 * @returns {void}
 */

export const setCountDownList = dispatch => {
  return list => {
    dispatch({
      type: 'SET_COUNTDOWN_LIST',
      payload: list,
    });
  };
};

export const setActiveCountdownCampaign = dispatch => {
  return details => {
    dispatch({
      type: 'SET_ACTIVE_COUNTDOWN_CAMPAIGN',
      payload: details,
    });
  };
};

export const editActiveCountdownCampaign = dispatch => {
  return newDetails => {
    dispatch({
      type: 'EDIT_ACTIVE_COUNTDOWN_CAMPAIGN',
      payload: newDetails,
    });
  };
};

export const resetActiveCountdownCampaign = dispatch => {
  return () => {
    dispatch({
      type: 'RESET_ACTIVE_COUNTDOWN_CAMPAIGN',
    });
  };
};
