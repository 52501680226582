/**
 * Created by mehmetyurtar on 1/6/18.
 */
import React from "react";

class IconAdwords extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <g>
            <path
              fill={this.state.color}
              d="M10.6,0H5.4C5.2,0,5,0.1,4.9,0.4L0,15.3C-0.1,15.7,0.2,16,0.5,16h3.7c1.1,0,2.1-0.7,2.5-1.8l4.4-13.5
			C11.2,0.3,11,0,10.6,0z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill={this.state.color}
              d="M16,15.3L11.7,2.2l-3.2,9.7l0.7,2.3c0.4,1.1,1.4,1.8,2.5,1.8h3.7C15.8,16,16.1,15.7,16,15.3z"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default IconAdwords;
