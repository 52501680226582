import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { t } from '../../system/ui';

import { uiActions } from '../../actions';
import { CheckboxGroup, Checkbox } from '../fields';
import { getPages } from '../../modules/account/ajax';
import Icons from '../icons';
import Icon from '../icon';
import Tooltip from '../tooltip';

class WizardPageType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: [],
      pageType: '',
      label: t('Select Pages'),
      pages: [],
      customPages: [],
      _customPages: [],
      hasError: false,
      showRequiredError: false,
      selectedFilter: [''],
      selectedUrlFilter: '',
      filterUrls: [{ id: Math.random(), value: '' }],
      urlFilterLabel: '',
      showPageOptions: false,
      showURLOptions: false,
      activeFields: props.activeFields || ['page', 'url'],
    };

    this.onChange = this.onChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.onCustomPageSearch = this.onCustomPageSearch.bind(this);
    this.onSelectedAll = this.onSelectedAll.bind(this);
    this.changeUrlFilter = this.changeUrlFilter.bind(this);
    this.addCustomUrlFilter = this.addCustomUrlFilter.bind(this);
    this.onAddUrl = this.onAddUrl.bind(this);
    this.onRemoveUrl = this.onRemoveUrl.bind(this);
    this.changeDropdownVisibility = this.changeDropdownVisibility.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
  }

  componentDidMount() {
    getPages(response => {
      this.setState({
        customPages: response.sort(),
        _customPages: response.sort(),
      });

      if (
        this.props.campaign &&
        typeof this.props.campaign.inputs !== 'undefined' &&
        this.props.campaign.inputs.length > 0
      ) {
        this.updateValue(this.props.campaign.inputs[0].value, () => {
          if (
            this.props.campaign.inputs[0].value.length ===
            response.sort().length
          ) {
            this.setState({
              value: response.sort((a, b) => a.toLowerCase() > b.toLowerCase()),
              label: t('All'),
            });
          }
        });
      }

      if (this.props.campaign.inputs?.[1]?.value) {
        this.setState(prevState => {
          return {
            ...prevState,
            selectedUrlFilter:
              this.props.campaign.inputs?.[1]?.name === 'includeUrls'
                ? 'contains'
                : 'notContains',
            filterUrls: this.props.campaign.inputs[1].value.map(filter => {
              return { id: Math.random(), value: filter };
            }),
          };
        });
      }

      if (this.props.campaign.inputs?.[2]?.value) {
        this.setState(prevState => {
          return {
            ...prevState,
            selectedUrlFilter:
              this.props.campaign.inputs?.[1]?.name === 'includeUrls'
                ? 'contains'
                : 'notContains',
            filterUrls: this.props.campaign.inputs[1].value.map(filter => {
              return { id: Math.random(), value: filter };
            }),
          };
        });
      }

      if (this.props.duplicate && this.props.duplicate.isDuplicate) {
        this.updateValue(
          this.props.duplicate.duplicatedValues[12].value,
          () => {
            if (
              this.props.duplicate.duplicatedValues[12].value.length ===
              response.sort().length
            ) {
              this.setState({
                value: response.sort(),
                label: t('All'),
              });
            }
          },
        );
      }
    });

    if (
      this.state.activeFields.includes('url') &&
      this.state.activeFields.includes('page')
    ) {
      this.setState({
        selectedFilter: ['url', 'page'],
      });
    } else if (
      this.state.activeFields.includes('url') &&
      !this.state.activeFields.includes('page')
    ) {
      this.setState({
        selectedFilter: ['url'],
      });
    } else {
      this.setState({
        selectedFilter: ['page'],
      });
    }

    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.validate === true) {
      this.validate();
    }

    if (newProps.campaign.inputs?.[0]?.value) {
      if (
        newProps.campaign.inputs[0]?.value.length ===
        this.state.customPages?.length
      ) {
        this.setState(prevState => {
          return {
            ...prevState,
            value: prevState.customPages,
            label: t('All'),
          };
        });
      }
    }

    if (
      this.state.activeFields.includes('url') &&
      this.state.activeFields.includes('page')
    ) {
      this.setState({
        selectedFilter: ['url', 'page'],
      });
    } else if (
      this.state.activeFields.includes('url') &&
      !this.state.activeFields.includes('page')
    ) {
      this.setState({
        selectedFilter: ['url'],
      });
    } else {
      this.setState({
        selectedFilter: ['page'],
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filterUrls.length === 0) {
      this.setState(prev => {
        return {
          ...prev,
          filterUrls: [{ id: Math.random(), value: '' }],
        };
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick(event) {
    const { showPageOptions, showURLOptions } = this.state;

    if (showPageOptions && this.refs.pageOptions.contains(event.target)) {
      // Do Nothing
      return;
    }

    if (showURLOptions && this.refs.urlOptions.contains(event.target)) {
      // Do Nothing
      return;
    }
    this.handleClickOutside();
  }

  handleClickOutside() {
    this.setState({
      showPageOptions: false,
      showURLOptions: false,
    });
  }

  onSelectedAll() {
    let newLabel = '';
    if (this.getAllStatus()) {
      const currentValues = this.state._customPages;
      const newSelectedValues = [];
      this.state.value.forEach(val => {
        if (currentValues.indexOf(val) === -1) {
          newSelectedValues.push(val);
        }
        newSelectedValues.forEach(page => {
          newLabel += `${t(page)}, `;
        });
        if (newSelectedValues.length > 5) {
          newLabel = `${newSelectedValues.length} ${t('pages selected')}`;
        }
        if (newLabel.length === 0) {
          newLabel = t('Select Pages');
        }
        this.setState(prevState => {
          return {
            ...prevState,
            value: newSelectedValues,
            label: newLabel,
            selectedFilter: prevState.selectedFilter.some(
              filter => filter !== 'page',
            )
              ? [...new Set([...prevState.selectedFilter, 'page'])]
              : prevState.selectedFilter,
          };
        }, this.validate);
      });
    } else if (
      this.state._customPages.length === this.state.customPages.length
    ) {
      // All elements selected
      this.setState(prevState => {
        return {
          ...prevState,
          value: prevState.customPages,
          label: t('All'),
          selectedFilter: prevState.selectedFilter.some(
            filter => filter !== 'page',
          )
            ? [...new Set([...prevState.selectedFilter, 'page'])]
            : prevState.selectedFilter,
        };
      }, this.validate);
    } else {
      // All searched elements selected
      this.state._customPages.forEach(page => {
        newLabel += `${t(page)}, `;
      });
      if (this.state._customPages.length > 5) {
        newLabel = `${this.state._customPages.length} ${t('pages selected')}`;
      }

      this.setState(prevState => {
        return {
          ...prevState,
          value: prevState._customPages,
          label: newLabel,
          selectedFilter: prevState.selectedFilter.some(
            filter => filter !== 'page',
          )
            ? [...new Set([...prevState.selectedFilter, 'page'])]
            : prevState.selectedFilter,
        };
      }, this.validate);
    }
  }

  onCustomPageSearch(e) {
    const newValue = e.target.value;
    const searchResults = [];
    this.state.customPages.forEach(page => {
      if (page.toLowerCase().indexOf(newValue.toLowerCase()) !== -1) {
        searchResults.push(page);
      }
    });
    this.setState({
      _customPages: searchResults,
    });
  }

  onChange(val) {
    this.updateValue(val, this.validate);
    uiActions.formEdited();
  }

  onAddUrl(event) {
    event.preventDefault();

    return this.setState(
      prevState => {
        return {
          ...prevState,
          filterUrls: [
            ...prevState.filterUrls,
            { id: Math.random(), value: '' },
          ],
        };
      },
      () => this.refs.pageURlForm.querySelector('input').focus(),
    );
  }

  onRemoveUrl(id) {
    this.setState(prevState => {
      return {
        ...prevState,
        filterUrls: prevState.filterUrls.filter(url => {
          return url.id !== id;
        }),
      };
    });

    if (this.state.filterUrls.length === 0) {
      this.setState(prevState => {
        return {
          ...prevState,
          filterUrls: [{ id: Math.random(), value: '' }],
        };
      });
    }
  }

  onUrlChange(id, e) {
    this.setState(prevState => {
      return {
        ...prevState,
        filterUrls: prevState.filterUrls.map(url => {
          return id === url.id ? { id: url.id, value: e.target.value } : url;
        }),
      };
    });
  }

  getAllStatus() {
    let allIsSelected = true;
    const currentValues = this.state._customPages;
    currentValues.forEach(val => {
      if (this.state.value.indexOf(val) === -1) {
        allIsSelected = false;
      }
    });
    return allIsSelected;
  }

  addCustomUrlFilter(e) {
    this.setState(prevState => {
      return {
        ...prevState,
        filterUrls: [e.target.value],
      };
    });
  }

  changeDropdownVisibility(dropdownType) {
    this.setState(prevState => {
      return {
        ...prevState,
        [dropdownType]: !prevState[dropdownType],
      };
    });
  }

  validate() {
    if (this.state.value.length === -1) {
      this.setState({
        hasError: true,
        showRequiredError: true,
      });
    } else {
      this.setState({
        hasError: false,
        showRequiredError: false,
      });
    }
  }

  updateValue(newValue, callback) {
    if (newValue) {
      const selectedValues = [];
      let newLabel = '';

      newValue.forEach(page => {
        if (!selectedValues.includes(page)) {
          selectedValues.push(page);
          newLabel += `${t(page)}, `;
        }
      });

      newLabel = newLabel.slice(0, -2);

      if (newLabel.length === 0) {
        newLabel = t('Select Pages');
      }

      if (selectedValues.length > 5) {
        newLabel = `${selectedValues.length} ${t('pages selected')}`;
      }

      if (selectedValues.length === this.state.customPages.length) {
        newLabel = t('All');
      }

      this.setState(prevState => {
        return {
          ...prevState,
          value: selectedValues,
          label: newLabel,
        };
      }, callback);
    }
  }

  updateUrlFilter(inputs, callback) {
    const targetInput = inputs.filter(input => {
      return input.value.length > 0 && input.name !== 'category';
    });
    const selectedUrlFilter =
      targetInput[0].name === 'includeUrls' ? 'contains' : 'notContain';
    this.setState(
      {
        selectedFilter: this.state.selectedFilter.some(
          filter => filter !== 'url',
        )
          ? [...new Set([...this.state.selectedFilter, 'url'])]
          : this.state.selectedFilter,
        selectedUrlFilter,
        filterUrls: targetInput[0].value.map(filter => {
          return { id: Math.random(), value: filter };
        }),
      },
      () => {
        callback();
      },
    );
  }

  changeUrlFilter(type) {
    this.setState(prevState => {
      return {
        ...prevState,
        selectedFilter: prevState.selectedFilter.some(
          filterType => filterType !== 'url',
        )
          ? [...new Set([...prevState.selectedFilter, 'url'])]
          : prevState.selectedFilter,
        selectedUrlFilter: type,
      };
    });
  }

  render() {
    return (
      <div
        className={classNames('wizard-page-selection', {
          'has-error': this.state.hasError,
        })}
      >
        <label className='item item-stacked label-emphasise one-whole'>
          <span className='item-label'>
            {t('Pages/Url Selection')}
            <Tooltip
              content={t(
                'Page/url types that you can include or exclude campaigns.',
              )}
              alignment='right'
            >
              <Icon name='help' />
            </Tooltip>
          </span>
        </label>
        {this.state.activeFields.includes('page') && (
          <>
            <CheckboxGroup
              value={this.state.value}
              className={
                this.state.selectedFilter.includes('page')
                  ? 'page-type-selection selected-custom-filter'
                  : 'page-type-selection'
              }
              onChange={this.onChange}
            >
              <div className='check-group' ref='pageOptions'>
                <div
                  className='check-group-header'
                  onClick={this.changeDropdownVisibility.bind(
                    this,
                    'showPageOptions',
                  )}
                  style={{ paddingRight: '24px' }}
                >
                  {this.state.label}
                  <Icons
                    name={
                      this.state.showPageOptions ? 'chevronUp' : 'chevronDown'
                    }
                    color='#000'
                  />
                </div>
                <div
                  className='check-group-drop check-group-drop__search'
                  style={{
                    display: this.state.showPageOptions ? 'block' : 'none',
                  }}
                >
                  <div className='check-group-search'>
                    <input
                      type='text'
                      placeholder={t('Search')}
                      onChange={this.onCustomPageSearch}
                    />
                    <i className='icon-magnify' role='presentation' />
                  </div>
                  {this.state._customPages.length > 0 && (
                    <label
                      className={
                        this.getAllStatus()
                          ? 'item item-field is-checkbox is-checked'
                          : 'item item-field is-checkbox'
                      }
                    >
                      <input
                        type='checkbox'
                        checked={this.getAllStatus()}
                        onChange={this.onSelectedAll}
                      />
                      <span className='item-label'>{t('All')}</span>
                    </label>
                  )}
                  {this.state._customPages.length ? (
                    this.state._customPages.map(page => {
                      return <Checkbox key={page} label={page} value={page} />;
                    })
                  ) : (
                    <div>{t('No Results')}</div>
                  )}
                </div>
              </div>
            </CheckboxGroup>

            {this.state.showRequiredError ? (
              <span className='item-error'>
                {t('You should select at least one page type')}
              </span>
            ) : (
              ''
            )}
          </>
        )}

        {this.state.activeFields.includes('url') && (
          <>
            <div
              className={
                this.state.selectedFilter.includes('url')
                  ? 'check-group selected-custom-filter'
                  : 'check-group'
              }
              ref='urlOptions'
            >
              <div
                className='check-group-header'
                onClick={this.changeDropdownVisibility.bind(
                  this,
                  'showURLOptions',
                )}
              >
                {this.state.selectedUrlFilter === 'contains'
                  ? 'Contains'
                  : this.state.selectedUrlFilter === 'notContains'
                  ? 'Not Contains'
                  : t('Select Url')}
                <Icons
                  name={this.state.showURLOptions ? 'chevronUp' : 'chevronDown'}
                  color='#000'
                />
              </div>
              <div
                className='device-types check-group-drop'
                style={{
                  display: this.state.showURLOptions ? 'block' : 'none',
                }}
              >
                <label
                  className='item item-field is-checkbox is-checked'
                  onClick={this.changeUrlFilter.bind(this, 'contains')}
                >
                  <span className='item-label' title='Desktop'>
                    Contains
                  </span>
                  <Icons
                    name={
                      this.state.selectedUrlFilter === 'contains'
                        ? 'radioSelected'
                        : 'radioEmpty'
                    }
                  />
                </label>

                <label
                  className='item item-field is-checkbox is-checked'
                  onClick={this.changeUrlFilter.bind(this, 'notContains')}
                >
                  <span className='item-label' title='Mobile Web'>
                    Not Contains
                  </span>
                  <Icons
                    name={
                      this.state.selectedUrlFilter === 'notContains'
                        ? 'radioSelected'
                        : 'radioEmpty'
                    }
                  />
                </label>
              </div>
            </div>
          </>
        )}

        <div
          style={{
            display: this.state.selectedFilter.includes('url')
              ? 'block'
              : 'none',
          }}
          ref='urls'
          className='url-field'
        >
          {this.state.filterUrls.map((url, index) => {
            return (
              <span key={url.id}>
                {index === this.state.filterUrls.length - 1 ? (
                  <form onSubmit={this.onAddUrl} ref='pageURlForm'>
                    <input
                      type='text'
                      defaultValue={url.value}
                      className='pageURL'
                      onChange={this.onUrlChange.bind(this, url.id)}
                    />
                    <span
                      className='pageURL-addIcon'
                      style={{ cursor: 'pointer' }}
                      onClick={this.onAddUrl}
                    >
                      <Icons name='plusCircle' width='16' height='16' />
                    </span>
                    <span
                      style={{ cursor: 'pointer', position: 'relative' }}
                      onClick={this.onRemoveUrl.bind(this, url.id)}
                    >
                      <Icons name='crossCircle' width='16' height='16' />
                    </span>
                  </form>
                ) : (
                  <span>
                    <input
                      type='text'
                      defaultValue={url.value}
                      className='pageURL'
                    />
                    <span
                      style={{ cursor: 'pointer', position: 'relative' }}
                      onClick={this.onRemoveUrl.bind(this, url.id)}
                    >
                      <Icons name='crossCircle' width='16' height='16' />
                    </span>
                  </span>
                )}
              </span>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate,
});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
  WizardPageType,
);
