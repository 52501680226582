import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { connect } from 'react-redux';

import { t } from '../../../system/ui';
import { setTitle } from '../../../system/document';

import { isSwitchAccount } from '../../../modules/auth/user';

import { getProductDetailsAsync } from '../../../modules/account/ajax';
import { searchMultipleProductsAsync } from '../../../modules/product/ajax';
import Snippet from '../../../components/snippet';
import { SearchProductForm } from './SearchProductForm';
import { resolveFilterSchema } from './resolveFilterSchema';

const ProductDetail = ({ switchedUser, user }) => {
  const { account } = isSwitchAccount() ? switchedUser : user;

  const [product, setProduct] = useState({
    productDetail: '',
    productDetailId: '',
    filterSchema: [],
    showFilter: false,
    sortable: false,
    multipleProductSearch: false,
  });

  const { data } = useQuery({
    queryKey: [product.productDetailId, product.filterSchema, product.sortable],
    queryFn: () =>
      getProductDetailsAsync({
        accountId: account.accountId,
        productId: product.productDetailId,
      }),
    enabled:
      product.productDetailId !== '' && product.productDetailId.length > 2,
    onSuccess: response => {
      setProduct(prev => {
        return {
          ...prev,
          productDetail:
            product.filterSchema && product.filterSchema.length > 0
              ? JSON.stringify(
                  resolveFilterSchema({
                    rawData: response.productRecord,
                    productFilterSchema: product.filterSchema,
                    isSortable: product.sortable,
                  }),
                  null,
                  2,
                )
              : JSON.stringify(response, null, 2),
        };
      });
    },
  });

  const { mutate, data: mutateData } = useMutation({
    mutationKey: [
      product.multipleProductSearch,
      product.filterSchema,
      product.sortable,
      product.productDetailId,
      product.showFilter,
    ],
    mutationFn: searchMultipleProductsAsync,
    onSuccess: response => {
      setProduct(prev => {
        return {
          ...prev,
          productDetail: JSON.stringify(response, null, 2),
        };
      });
    },
  });

  const onSubmit = formData => {
    if (formData.multipleSearchProduct) {
      return mutate({
        list: formData.productId.split(',').map(p => p.trim()),
      });
    }
    return setProduct({
      productDetailId: formData.productId,
      productDetail: '',
      filterSchema: formData.productFilter,
      sortable: formData.sortResults,
      showFilter: formData.showFilter,
    });
  };

  useEffect(() => {
    setTitle(t('Product Search'));
  }, []);

  return (
    <div>
      <h2 className='page-title'>{t('Product Search')}</h2>
      <div className='page-forms'>
        <div className='page-content-block one-whole'>
          <ol className='form-elements'>
            <li>
              <SearchProductForm onSubmit={onSubmit} />
            </li>
            {product.productDetail && (
              <li className='form-element'>
                <div style={{ height: '600px' }}>
                  <Snippet
                    value={product.productDetail}
                    language={{
                      name: 'javascript',
                      json: true,
                    }}
                    options={{ readOnly: true, lineWrapping: false }}
                  />
                </div>
              </li>
            )}
          </ol>
        </div>
      </div>
    </div>
  );
};

ProductDetail.defaultProps = {
  options: {
    label: 'Product Details & Relationship',
    name: 'text',
  },
};

const mapStatesToProps = store => ({
  user: store.user.user,
  validate: store.ui.validate,
  language: store.ui.language,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(ProductDetail);
