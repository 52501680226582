import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import { uiActions } from '../../actions';
import { hasModule } from '../../modules/auth/user';
import Icons from '../../components/icons';
import { t } from '../../system/ui';
import { audienceIntegrationInfo } from '../../modules/trendify/page';

const Audience = ({ children }) => {
  useEffect(() => {
    uiActions.resetPage();
    audienceIntegrationInfo();
    if (document.location.pathname === '/audience') {
      if (hasModule('segmentation')) {
        browserHistory.push({
          pathname: '/audience/segmentation/view-all',
        });
      } else if (hasModule('ruleBasedSegmentation')) {
        browserHistory.push({
          pathname: '/audience/rule-based-segmentation',
        });
      }
    }
  }, []);

  return !hasModule('ruleBasedSegmentation') && !hasModule('segmentation') ? (
    <div className='promo promo--centered bt-discover-dashboard'>
      <Icons name='audienceIcon' color='#ffffff' width='64' height='64' />
      <p className='promo-text'>{t('AudiencePromo')}</p>
      <a
        href='https://segmentify.com/solution/customer-data-management/'
        target='_blank'
        className='secondary-action'
        rel='noreferrer'
      >
        {t('Discover')}
      </a>
    </div>
  ) : (
    children
  );
};

Audience.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Audience;
