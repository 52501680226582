/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DropzoneComponent from 'react-dropzone-component';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import { uiActions } from '../../../actions';
import { uploadFileWaitUrl } from '../../upload/ajax';
import { TextField } from '../../../components/fields';
import Icons from '../../../components/icons';
import Tooltip from '../../../components/tooltip';
import { pushList } from '../../../constants/datamaps/push';
import { t } from '../../../system/ui';

let dropzoneObj2;
const componentConfig = { postUrl: 'no-url' };
const djsConfig = {
  addRemoveLinks: false,
  autoProcessQueue: false,
  acceptedFiles: 'image/jpeg,image/png',
  createImageThumbnails: false,
};
const methods = ['URL', 'Upload', 'No Image'];
const engagementCampaignTypes = [
  'HERO_BANNER',
  'PERSONA_QUIZ',
  'NOTIFICATION_BAR',
  'POPUP_BANNER',
];

class ImageWithUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      name: 'text',
      imgSrc: '',
      additionalImages: {},
      method:
        this.props.campaign.type !== 'FLY'
          ? this.props.campaign.image === ''
            ? 'No Image'
            : 'Product Image'
          : 'URL',
      disabled: false,
      hasError: false,
      showRequiredError: false,
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.setDropzoneHandlers = this.setDropzoneHandlers.bind(this);
    this.changeImage = this.changeImage.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    if (this.props.additionalImages) {
      const activeMethods = this.calculateMethodsToRender();
      const modifiedAdditionalImages = Object.keys(
        this.props.additionalImages,
      ).reduce((acc, key) => {
        if (this.props.additionalImages[key] === '') {
          acc[key] = activeMethods.includes('No Image') ? '{{no:image}}' : '';
        } else {
          acc[key] = this.props.additionalImages[key];
        }

        return acc;
      }, {});

      this.setState({
        additionalImages: modifiedAdditionalImages,
      });
    }
    if (this.props.item) {
      this.updateValue(this.props.item.image);
      return;
    }

    const activeMethods = this.calculateMethodsToRender();
    let image = this.props.campaign.image || '';
    if (image === '' && activeMethods.includes('No Image')) {
      image = '{{no:image}}';
    }
    this.updateValue(image);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    const activeMethods = this.calculateMethodsToRender();
    let text = newProps.campaign.image;
    if (
      text === '' &&
      newProps.selectedLanguage &&
      (newProps.additionalImages?.[newProps.selectedLanguage] === text ||
        newProps.additionalImages?.[newProps.selectedLanguage] ===
          '{{no:image}}') &&
      activeMethods.includes('No Image')
    ) {
      text = '{{no:image}}';
    }
    if (!isEqual(this.props.campaign, newProps.campaign)) {
      this.updateValue(text);
    }

    if (this.props.disableFields !== newProps.disableFields) {
      this.updateValue(text, newProps.disableFields);
    }

    if (newProps.validate === true) {
      this.validate();
    }

    if (this.props.item) {
      this.updateValue(this.props.item.image);
    }

    if (this.props.selectedLanguage !== newProps.selectedLanguage) {
      let image = this.state.additionalImages[newProps.selectedLanguage] || '';
      if (image === '' && activeMethods.includes('No Image')) {
        image = '{{no:image}}';
      }

      this.updateValue(image, undefined, newProps.selectedLanguage);
    }

    if (!isEqual(this.props.additionalImages, newProps.additionalImages)) {
      const modifiedAdditionalImages = Object.keys(
        newProps.additionalImages,
      ).reduce((acc, key) => {
        if (newProps.additionalImages[key] === '') {
          acc[key] = activeMethods.includes('No Image') ? '{{no:image}}' : '';
        } else {
          acc[key] = newProps.additionalImages[key];
        }

        return acc;
      }, {});

      this.setState({
        additionalImages: modifiedAdditionalImages,
      });
    }
  }

  onFieldChange(e) {
    this.updateValue(e.target.value);
    this.triggerOnIconChanged(e.target.value);
    uiActions.formEdited();
  }

  onSelectMethod(method, e) {
    e.preventDefault();
    this.setState(
      prevState => ({
        method,
        imgSrc:
          method === 'Product Image' || method === 'No Image'
            ? ''
            : prevState.fileName,
      }),
      () => {
        let imageName = this.state.imgSrc;
        if (method === 'URL') {
          this.setState({
            isURL: true,
            imgSrc: imageName === '{{no:image}}' ? '' : imageName,
            fileName: imageName === '{{no:image}}' ? '' : imageName,
          });
        }

        if (method === 'Product Image') {
          imageName = '{{product:image}}';
        }
        if (method === 'No Image') {
          imageName = '{{no:image}}';
        }
        this.triggerOnIconChanged(imageName);
      },
    );
  }

  setDropzoneHandlers() {
    return {
      init: dropzone => {
        dropzoneObj2 = dropzone;
      },
      addedfile: file => {
        window.dispatchEvent(new Event('resize'));
        this.setState(
          {
            imgSrc: '/assets/images/loading.gif',
            fileName: '/assets/images/loading.gif',
          },
          () => {
            const reader = new FileReader();
            reader.onload = () => {
              const fileToUpload = dropzoneObj2.getAcceptedFiles()[
                dropzoneObj2.getAcceptedFiles().length - 1
              ];
              const fileQuery = {};
              fileQuery.feedBackFile = fileToUpload;
              if (fileToUpload.size / (1024 * 1024) < 5) {
                const type = engagementCampaignTypes.includes(
                  this.props.campaignType,
                )
                  ? 'ENGAGEMENT'
                  : 'PUSH';
                uploadFileWaitUrl(fileQuery, type, response => {
                  if (response) {
                    const replacedResponse = response.replace(/['"]+/g, '');
                    this.setState(
                      {
                        file,
                        imgSrc: replacedResponse,
                        fileName: replacedResponse,
                      },
                      () => this.triggerOnIconChanged(replacedResponse),
                    );
                  }
                });
              } else {
                this.setState(
                  {
                    imgSrc: '',
                    fileName: '',
                  },
                  () => {
                    uiActions.notifyRequestError({
                      statusText:
                        'File is too big to upload! Please try another file below 5MB',
                    });
                    this.triggerOnIconChanged('');
                  },
                );
              }
            };
            reader.readAsDataURL(file);
          },
        );
      },
    };
  }

  calculateMethodsToRender = () => {
    if (this.props.campaign.type === 'FLY')
      return methods.slice(0, 2).concat('Product Image');
    if (pushList.indexOf(this.props.campaign.type) > -1)
      return methods.concat('Product Image');
    if (
      this.props.campaign.type === 'PUSH_NOTIFICATION' ||
      engagementCampaignTypes.includes(this.props.campaignType)
    )
      return methods.slice(0, 2);
    return methods;
  };

  validate() {
    if (
      engagementCampaignTypes.includes(this.props.campaignType) &&
      this.props.options?.required
    ) {
      let hasError = false;
      let showRequiredError = false;

      if (!this.state.imgSrc || this.state.imgSrc.length === 0) {
        hasError = true;
        showRequiredError = true;
      }

      this.setState({ hasError, showRequiredError });
    }
  }

  updateValue(newValue, disabled, newSelectedLanguage) {
    if (disabled === undefined) {
      let method = this.state.method || 'Product Image';
      if (
        newValue !== '{{product:image}}' &&
        newValue !== undefined &&
        newValue !== ''
      ) {
        method = 'URL';
      }
      if (engagementCampaignTypes.includes(this.props.campaignType)) {
        method = 'URL';
      }

      const selectedLanguage =
        newSelectedLanguage || this.props.selectedLanguage;

      if (
        newValue !== '{{product:image}}' &&
        newValue !== '{{no:image}}' &&
        this.props.additionalImages &&
        selectedLanguage
      ) {
        method = 'URL';
      }

      if (
        newValue === '{{no:image}}' &&
        this.props.additionalImages &&
        selectedLanguage
      ) {
        method = 'No Image';
      }

      this.setState({
        imgSrc: newValue === '{{product:image}}' ? '' : newValue,
        fileName: newValue === '{{product:image}}' ? '' : newValue,
        method: newValue === '{{product:image}}' ? 'Product Image' : method,
        disabled: false,
      });
    } else if (disabled) {
      this.setState({
        imgSrc: '',
        fileName: '',
        method: 'Product Image',
        disabled: true,
      });
    } else {
      this.setState({
        imgSrc: '',
        fileName: '',
        method: 'URL',
        disabled: false,
      });
    }
  }

  updateAdditionalImages(newValue) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newAdditionalImages = this.state.additionalImages || {};
    newAdditionalImages[this.props.selectedLanguage] =
      newValue || this.state.imgSrc;
    this.setState({
      additionalImages: newAdditionalImages,
    });
    this.props.updateStateValue('additionalImages', newAdditionalImages);
  }

  triggerOnIconChanged(newValue) {
    this.validate();
    if (this.props.onIconChanged) {
      const { method } = this.state;
      let image;

      if (method === 'Product Image') {
        if (this.props.campaign.type === 'ABANDONED_CART') {
          image = '{{product:image}}';
        } else {
          image = this.state.imgSrc;
        }
      } else {
        this.state.isURL = true;
        image = this.state.imgSrc;
      }
      this.props.onIconChanged({
        image,
      });
    }

    if (this.props.additionalImages) {
      this.updateAdditionalImages(newValue);
    }
  }

  // TODO FIX THIS!!!
  // ABANDONMENT_CART state'nin senkron calismasinda bir problem vardi, simdilik bu sekilde kirli bir cozum uyguladik
  // this.setState asenkron calistigi icin bu problemi yasadigimizi dusunuyoruz
  changeImage(e) {
    if (this.props.campaign.type === 'ABANDONED_CART') {
      this.state.imgSrc = e.target.value.trim();
      this.state.imgSrc = e.target.value.trim();
    } else {
      this.setState(
        {
          imgSrc: e.target.value.trim(),
          fileName: e.target.value.trim(),
        },
        () => this.validate(),
      );
    }
    this.triggerOnIconChanged(e.target.value.trim());
  }

  render() {
    let imgComp;
    const latestMethods = this.calculateMethodsToRender();
    if (
      this.state.imgSrc &&
      (this.state.imgSrc.indexOf('http') > -1 ||
        this.state.imgSrc.indexOf('loading') > -1)
    ) {
      imgComp = (
        <img
          src={this.state.imgSrc}
          onError={e => {
            e.target.src = '/images/layer.png';
          }}
          alt={this.state.fileName}
          onLoad={e => {
            e.target.style.display = 'block';
          }}
        />
      );
    } else {
      imgComp = <img src='/images/layer.png' alt='Layer example' />;
    }
    return (
      <li className='item-title-field one-whole image-with-upload page-forms'>
        {imgComp}
        <span className='item-label'>
          {this.props.options.label}
          <span className='recommended-text' />
          {this.props.options.tooltip && (
            <span style={{ position: 'absolute', top: '-3px' }}>
              <Tooltip
                content={this.props.options.tooltipContent}
                alignment='left'
              >
                <i className='icon-info' role='presentation'>
                  <span className='for-screenreader-only' />
                </i>
              </Tooltip>
            </span>
          )}
          {this.props.campaignType === 'FLY' ? (
            <span style={{ position: 'absolute', top: '-2px', right: '-45px' }}>
              <Tooltip
                content={
                  <span>
                    You can choose to send one static image as URL or Upload
                    section to promote your campaign, instead of sending product
                    images automatically.
                  </span>
                }
                alignment='right'
              >
                <Icons name='question' width='16' height='16' color='#e7eef3' />
              </Tooltip>
            </span>
          ) : (
            !engagementCampaignTypes.includes(this.props.campaignType) && (
              <>
                <span
                  style={{ position: 'absolute', top: '-2px', right: '-45px' }}
                >
                  <Tooltip
                    content={
                      <span>
                        <strong>Chrome Web</strong> <br />
                        720x480 for before Chrome 67 <br />
                        360x180 or 2:1 aspect ratio for Chrome 67 and newer
                        <br />
                        <strong>Chrome Mobile</strong> <br />
                        Minimum - 512x256, Maximum - 2048x1024 <br />
                        <strong>ANDROID</strong> <br /> 1440x720 or 2:1 aspect
                        ratio
                        <br /> <strong>iOS</strong> <br />
                        1024x1024 or 1:1 aspect ratio
                      </span>
                    }
                    alignment='right'
                  >
                    <Icons
                      name='question'
                      width='16'
                      height='16'
                      color='#e7eef3'
                    />
                  </Tooltip>
                </span>
                <span
                  style={{ position: 'absolute', top: '-2px', right: '-65px' }}
                >
                  <Tooltip
                    content={
                      <span>The maximum file size is 5 MB for images</span>
                    }
                    alignment='right'
                  >
                    <Icons
                      name='question'
                      width='16'
                      height='16'
                      color='#e7eef3'
                    />
                  </Tooltip>
                </span>
              </>
            )
          )}
        </span>
        <ul
          className='whitelist-radio'
          style={{
            marginTop: '10px',
            marginLeft: '98px',
            opacity: this.state.disabled ? '0.3' : '1',
            pointerEvents: this.state.disabled ? 'none' : 'initial',
            paddingBottom: '4px',
          }}
        >
          {latestMethods.map(method => {
            if (
              method === 'Product Image' &&
              this.props.campaign.type === 'FLY'
            ) {
              return (
                <li
                  onClick={this.onSelectMethod.bind(this, method)}
                  key={method}
                  style={{
                    display: 'inline',
                    fontSize: '14px',
                    opacity: this.props.algorithmSelected ? '1' : '0.3',
                    pointerEvents: this.props.algorithmSelected
                      ? 'initial'
                      : 'none',
                    paddingBottom: '3px',
                  }}
                >
                  <Icons
                    name={
                      this.state.method === method &&
                      this.props.algorithmSelected
                        ? 'radioSelected'
                        : 'radioEmpty'
                    }
                    width='14'
                    height='14'
                  />
                  <span>{method}</span>
                </li>
              );
            }
            if (
              method === 'Product Image' &&
              this.props.campaign.type === 'ABANDONED_CART' &&
              this.props.campaign.isCoupon
            ) {
              return (
                <li
                  onClick={this.onSelectMethod.bind(this, method)}
                  key={method}
                  style={{
                    display: 'inline',
                    fontSize: '14px',
                    opacity: '0',
                    pointerEvents: 'none',
                    paddingBottom: '3px',
                  }}
                >
                  <Icons
                    name={
                      this.state.method === method &&
                      this.props.algorithmSelected
                        ? 'radioSelected'
                        : 'radioEmpty'
                    }
                    width='14'
                    height='14'
                  />
                  <span>{method}</span>
                </li>
              );
            }
            return (
              <>
                <li
                  onClick={this.onSelectMethod.bind(this, method)}
                  key={method}
                  style={{
                    display: 'inline',
                    fontSize: '14px',
                  }}
                >
                  <Icons
                    name={
                      this.state.method === method
                        ? 'radioSelected'
                        : 'radioEmpty'
                    }
                    width='14'
                    height='14'
                  />
                  <span>{method}</span>
                </li>
                {method === 'Upload' && this.state.showRequiredError && (
                  <span
                    className={classNames('item-error', {
                      'has-error': this.state.hasError,
                    })}
                  >
                    {this.state.method === 'Upload'
                      ? t('This field is required')
                      : ''}
                  </span>
                )}
              </>
            );
          })}
        </ul>
        <li className='item-title-field' style={{ minHeight: '26px' }}>
          {this.state.method !== 'URL' &&
            this.state.method !== 'Upload' &&
            this.state.showRequiredError && (
              <span
                style={{ marginLeft: '5px' }}
                className={classNames('item-error', {
                  'has-error': this.state.hasError,
                })}
              >
                {t('This field is required')}
              </span>
            )}
          {this.state.method === 'URL' && (
            <TextField
              name='image'
              className='item-stacked one-whole image-with-upload-input'
              value={this.state.fileName}
              required={
                engagementCampaignTypes.includes(this.props.campaignType)
                  ? this.props.options?.required || false
                  : this.props.campaign.type !== 'FLY'
              }
              onChange={this.changeImage}
              validation={{ mustacheOrUrl: true }}
              placeholder={this.props.options.placeholder}
              isURL={this.state.isURL}
            />
          )}
        </li>
        {this.state.method === 'Upload' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              paddingLeft: '90px',
            }}
          >
            <DropzoneComponent
              config={componentConfig}
              eventHandlers={this.setDropzoneHandlers()}
              djsConfig={djsConfig}
            >
              Upload
              <i className='icon-upload' />
            </DropzoneComponent>
            {(this.state.showRequiredError || this.state.imgSrc === '') && (
              <span
                className='item-error has-error'
                style={{
                  display: 'block',
                  marginTop: '-15px',
                  marginLeft: '5px',
                }}
              >
                {t('This field is required')}
              </span>
            )}
          </div>
        )}
      </li>
    );
  }
}

ImageWithUpload.defaultProps = {
  options: {
    label: 'Description',
    name: 'text',
    tooltip: false,
    tooltipContent: '',
  },
};

const MapStatesToProps = store => ({
  validate: store.ui.validate,
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  ImageWithUpload,
);
