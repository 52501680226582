import React from 'react';
import { t } from 'system/ui';

const Language = () => {
  return (
    <div>
      <br />
      <h2>{t('Language')}</h2>
    </div>
  );
};

export default Language;
