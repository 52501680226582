/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconCompleted extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "20px",
      height: "20px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            d="M23,2h-4V0h-2v2h-4V0h-2v2H7V0H5v2H1C0.448,2,0,2.448,0,3v20c0,0.552,0.448,1,1,1h22c0.552,0,1-0.448,1-1V3&#xA;&#x9;C24,2.448,23.552,2,23,2z M22,22H2V8h20V22z"
            fill={this.state.color}
          />
          <path
            d="M11,19c-0.256,0-0.512-0.098-0.707-0.293L6.586,15L8,13.586l3,3l6-6L18.414,12l-6.707,6.707&#xA;&#x9;C11.512,18.902,11.256,19,11,19z"
            fill={this.state.color}
          />
        </g>
      </svg>
    );
  }
}

export default IconCompleted;
