import React from "react";

class IconBetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee",
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        width={this.state.width}
        height={this.state.height}
      >
        <path
          d="M32.912 34.279a.997.997 0 00-.363 1.117l3.246 9.992a1 1 0 00.951.691h10.506a1 1 0 00.951-.69l3.248-9.992a1 1 0 00-.363-1.118l-8.5-6.176a.996.996 0 00-1.176 0zm16.412 1.191l-2.799 8.61h-9.053l-2.797-8.61L42 30.148z"
          fill={this.state.color}
        />
        <path
          d="M1 33.006h19.578a21.945 21.945 0 00-.578 4.99c0 12.131 9.869 22 22 22 2.957 0 5.778-.591 8.357-1.653l.01-.004C58.359 55.039 64 47.166 64 37.996c0-12.131-9.869-22-22-22-1.365 0-2.699.131-3.996.37V5.004a1 1 0 00-1-1H1a1 1 0 00-1 1v27.002a1 1 0 001 1zm21.954-6c-.19.328-.372.661-.545 1H2v-1zm12.067-7.729l-2.654 5.371-5.973.871a20.077 20.077 0 018.627-6.242zM24.26 25.006H2v-1h23.033c-.267.325-.524.66-.773 1zm-22.26-3V20.19a2.989 2.989 0 011.815 1.815H2zm0 9v-1h19.509c-.129.33-.25.663-.363 1zm23.373 18.087a19.867 19.867 0 01-3.338-10.409l4.391 4.279zm11.075 8.102L42 54.278l5.551 2.918c-1.765.511-3.623.8-5.551.8s-3.787-.289-5.552-.801zm21.126-14.233l4.391-4.279a19.864 19.864 0 01-3.338 10.41zm.031-17.442l-5.973-.871-2.654-5.371a20.05 20.05 0 018.627 6.242zm-11.247-7.031l3.714 7.517a1 1 0 00.752.546l8.33 1.214a19.84 19.84 0 012.742 8.191l-6.095 5.94a1 1 0 00-.287.885l1.449 8.438a20.076 20.076 0 01-6.875 5.051l-7.624-4.008a.994.994 0 00-.93 0l-7.625 4.008a20.072 20.072 0 01-6.874-5.051l1.449-8.438a1 1 0 00-.287-.885l-6.095-5.939c.123-1.214.37-2.391.701-3.532.038-.08.052-.169.067-.259a19.845 19.845 0 011.974-4.401l8.33-1.214a1 1 0 00.752-.546l3.714-7.517c1.405-.314 2.86-.493 4.358-.493s2.955.179 4.36.493zm-19.444 3.517H5.898A5.013 5.013 0 002 18.107V9.902a5.01 5.01 0 003.898-3.898h26.205a5.011 5.011 0 003.9 3.899v6.931a21.98 21.98 0 00-9.089 5.172zm9.09-14.186a2.994 2.994 0 01-1.817-1.816h1.817zM3.815 6.004A2.984 2.984 0 012 7.819V6.004z"
          fill={this.state.color}
        />
        <path
          d="M19.002 8.996c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 8c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z"
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default IconBetting;