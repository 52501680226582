/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { uiActions, wizardActions } from "../../../actions";

import { TextField } from "../../../components/fields";

class Button extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      name: "buttonText"
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    let buttonText = this.props.campaign[this.props.options.name] || "";
    this.updateValue(buttonText);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign[this.props.options.name]);
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  onFieldChange(e) {
    this.updateValue(e.target.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <li className="item item-stacked one-whole">
        <TextField
          name={this.props.options.name}
          label={this.props.options.label}
          className="item-stacked one-whole"
          value={this.state.value}
          required={true}
          onChange={this.onFieldChange}
          tooltip={this.props.options.tooltip}
          tooltipText={this.props.options.tooltipContent}
        />
      </li>
    );
  }
}

Button.defaultProps = {
  options: {
    label: "Button Text",
    name: "buttonText",
    tooltip: false,
    tooltipContent: ""
  }
};

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(Button);
