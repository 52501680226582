/**
 * Created by mehmetyurtar on 4/13/18.
 */
"use strict";

import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { SortableHandle } from "react-sortable-hoc";

import { t } from "../../system/ui";

import { searchProducts } from "../../modules/product/ajax";

import { decodeHtml } from "../../system/string";
import Icon from "../icon";
import Icons from "../icons";
import { TextField } from "../fields";
import Highlight from "../highlight";

import Tooltip from "../../components/tooltip";

class CriterionProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autocompleteVisible: true,
      products: [],
      criterion: "",
      productId: "",
      selected: false,
      preloader: "",
      hasError: false,
      showRequiredError: false
    };

    this.setDefaults = this.setDefaults.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.closeAutocomplete = this.closeAutocomplete.bind(this);
    this.validate = this.validate.bind(this);
    this.setStaticProducts = this.setStaticProducts.bind(this);
    this.timeout = false;
  }

  componentDidMount() {
    this.setStaticProducts(this.props);
  }

  setStaticProducts(newProps) {
    let defaultCriterion = "",
      defaultProductId = "";
    if (Object.keys(newProps.values).length > 0) {
      defaultCriterion = newProps.values.criterion
        ? newProps.values.criterion.value
        : "";
      defaultProductId = newProps.values.productId
        ? newProps.values.productId.value
        : "";
      if (defaultProductId !== "") {
        newProps.staticProducts.forEach(product => {
          if (product) {
            if (defaultProductId == product.productId) {
              this.setState({
                criterion: product.productId + " - " + product.name,
                productId: product.productId,
                selected: true,
                autocompleteVisible: false
              });
            }
          }
        });
      } else {
        this.setDefaults(defaultCriterion, defaultProductId);
      }
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.validate === true) {
      this.validate();
    }
    if (newProps.staticProducts !== this.props.staticProducts) {
      this.setStaticProducts(newProps);
    }
  }

  componentDidUpdate(oldProps, oldStates) {
    if (oldStates.productId !== this.state.productId) {
      this.props.onCriteriaChange(this.refs.inputs, this.props.id);
      this.validate();
    }
  }

  setDefaults(defaultCriterion, defaultProductId) {
    this.setState({
      criterion: defaultCriterion,
      productId: defaultProductId
    });
  }

  closeAutocomplete() {
    setTimeout(() => {
      if (this.state.selected) {
        this.setState({
          autocompleteVisible: false
        });
      } else {
        this.setState({
          criterion: "",
          productId: "",
          autocompleteVisible: false
        });
      }
    }, 250);
  }

  selectProduct(product) {
    this.setState({
      criterion: product.productId + " - " + product.name,
      productId: product.productId,
      autocompleteVisible: false,
      selected: true
    });
  }

  onChange(e) {
    let keyword = "",
      name = "";

    clearTimeout(this.timeout);

    if (Object.keys(this.props.values).length > 0) {
      name = this.props.values.criterion
        ? this.props.values.criterion.value
        : "";
      keyword = this.props.values.productId
        ? this.props.values.productId.value
        : "";
    }

    if (typeof e !== "undefined") {
      keyword = e.currentTarget.value;
      name = e.currentTarget.value;
    }

    this.setState({
      criterion: name.replace(this.refs.productId.value + " - ", ""),
      selected: false
    });

    if (keyword.length > 2) {
      this.timeout = setTimeout(() => {
        this.setState({
          preloader: "isLoading"
        });

        searchProducts(keyword, response => {
          this.setState({
            products: response,
            autocompleteVisible: true,
            preloader: "isLoaded"
          });
        });
      }, 500);
    }
  }

  validate() {
    let input = this.refs.productId;

    if (input.value === "") {
      this.setState({
        hasError: true,
        showRequiredError: true
      });
    } else {
      this.setState({
        hasError: false,
        showRequiredError: false
      });
    }
  }

  render() {
    const DragHandle = SortableHandle(() => (
      <span className="draghandler">
        <Icons name="burger" color="#e8e7f2" />
      </span>
    ));
    return (
      <li className="wizard-criterion" ref="inputs">
        <DragHandle />

        <span className="criteria-type">
          <Tooltip content={t("Static (Product)")} alignment="left">
            <Icon name="product" />
          </Tooltip>
        </span>

        <span className="wizard-product-name">
          <TextField
            name="criterion"
            value={decodeHtml(this.state.criterion)}
            className="item-field criteria-name"
            inputClassName="one-whole criteria-field"
            placeholder="Product Name/ID"
            required={true}
            onChange={this.onChange}
            onBlur={this.closeAutocomplete}
          />

          <input
            type="hidden"
            name="productId"
            ref="productId"
            className={classNames("criteria-field", {
              "has-error": this.state.hasError
            })}
            value={this.state.productId}
            onChange={this.onChange}
          />
          {this.state.showRequiredError ? (
            <span className="item-error">
              {t("You should select a product from suggestions")}
            </span>
          ) : (
            ""
          )}

          <span
            className={classNames("preloader", {
              "preloader-start": this.state.preloader === "isLoading",
              "preloader-end": this.state.preloader === "isLoaded"
            })}
          />
        </span>

        <a
          onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
          className={
            this.props.isOnlySelection
              ? "cancel-action cancel-action--disabled"
              : "cancel-action"
          }
        >
          <Icons name="crossCircle" />
        </a>

        {this.state.products.length > 0 && this.state.autocompleteVisible ? (
          <ul className="autocomplete">
            {this.state.products.map((item, index) => {
              return (
                <li key={index} onClick={this.selectProduct.bind(null, item)}>
                  <span className="autocomplete-item">
                    <span className="autocomplete-item-id">
                      <Highlight
                        keyword={this.state.criterion}
                        text={item.productId}
                      />
                    </span>
                    <span className="autocomplete-item-name">
                      <Highlight
                        keyword={this.state.criterion}
                        text={decodeHtml(item.name)}
                      />
                    </span>
                  </span>
                </li>
              );
            })}
          </ul>
        ) : (
          ""
        )}
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  criteria: store.wizard.criteria,
  validate: store.ui.validate,
  user: store.user.user
});

export default connect(mapStatesToProps)(CriterionProduct);
