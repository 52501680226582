import React from 'react';
import { connect } from 'react-redux';
import { clone } from '../system/object';

import { t } from '../system/ui';
import Instructions from '../components/email/settings.instructions';
import Capping from '../components/email/settings.capping';
import Unsub from '../components/email/settings.unsub';
import TestUsers from '../components/email/Settings.TestUsers';
import UserParameters from '../components/email/settings.UserParameters';
import { getIntegrations } from '../modules/account/ajax';
import {
  isSwitchAccount,
  updateEmailConfig,
  isSuperUser,
} from '../modules/auth/user';
import { uiActions, userActions, switchUserActions } from '../actions';

class Settings_Email extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenuItem: 'Instructions',
      selectedCheckMethod: 'blacklist',
      unsubscriptionLink: '',
      isIntegrated: false,
      congrats: false,
      isSuperUser: false,
    };
    this.checkAvailability = this.checkAvailability.bind(this);
    this.getInformationsFromModules = this.getInformationsFromModules.bind(
      this,
    );

    this.updateProvider = this.updateProvider.bind(this);
    this.updateSettings = this.updateSettings.bind(this);
    this.provider = 'MAILCHIMP';
  }

  componentDidMount() {
    this.checkAvailability();
    if (isSuperUser()) {
      this.setState({
        isSuperUser: true,
      });
    }
  }

  selectMenuItem(selectedMenuItem, e) {
    e.preventDefault();
    if (selectedMenuItem === 'Unsub') {
      const account =
        isSuperUser(this.props.user) && isSwitchAccount()
          ? clone(isSwitchAccount())
          : clone(this.props.user.account);
      this.setState({
        unsubscriptionLink:
          account?.mailConfiguration?.params?.unsubscriptionLink,
      });
    }
    this.setState({
      selectedMenuItem,
    });
  }

  updateProvider(name) {
    this.provider = name;
  }

  getInformationsFromModules(field) {
    if (field.target !== undefined) {
      this.setState({
        [field.target.name]: field.target.value,
      });
    }

    if (field.checkMethod) {
      this.setState({
        selectedCheckMethod: field.checkMethod,
      });
    }

    if (field.selectedCheckMethod) {
      this.setState({
        selectedCheckMethod: field.selectedCheckMethod,
      });
    }

    if (field.unsubscriptionLink || field.unsubscriptionLink === '') {
      this.setState({
        unsubscriptionLink: field.unsubscriptionLink,
      });
    }

    if (field.capCount !== undefined) {
      this.setState({
        capping: field,
      });
    }

    if (field.endPoint !== undefined) {
      this.setState({
        endPoint: field.endPoint,
      });
    }

    if (field === 'disable') {
      this.setState({
        fromAddress: '',
        fromName: '',
        replyAddress: '',
        replyName: '',
        congrats: false,
      });
    }
    if (
      typeof field === 'object' &&
      Object.keys(field).length > 0 &&
      (field.apiKey !== undefined ||
        field.username !== undefined ||
        field.privateKey !== undefined)
    ) {
      this.setState({
        fromAddress: field.fromAddress !== undefined ? field.fromAddress : '',
        fromName: field.fromName !== undefined ? field.fromName : '',
        replyAddress:
          field.replyAddress !== undefined ? field.replyAddress : '',
        replyName: field.replyName !== undefined ? field.replyName : '',
        congrats: true,
        username: field.username !== undefined ? field.username : '',
        password: field.password !== undefined ? field.password : '',
        apiKey: field.apiKey !== undefined ? field.apiKey : '',
        privateKey: field.privateKey !== undefined ? field.privateKey : '',
      });
    }
  }

  updateSettings() {
    if (document.querySelector('.item-error') !== null) {
      return;
    }
    if (this.provider !== 'KLAVIYO') {
      if (
        document.querySelector('.item-error') === null &&
        (this.state.fromAddress === undefined ||
          this.state.fromName === undefined ||
          this.state.replyName === undefined ||
          this.state.replyAddress === undefined)
      ) {
        return;
      }
    }
    uiActions.isLoading();
    this.setState({
      ui: 'isSubmitting',
    });
    const mailConfiguration = {};
    const { forms } = this.refs;
    const account =
      isSuperUser(this.props.user) && isSwitchAccount()
        ? clone(isSwitchAccount())
        : clone(this.props.user.account);
    if (!mailConfiguration.params) {
      mailConfiguration.params = {};
    }
    mailConfiguration.endPoint = '';

    if (this.provider === 'MAILCHIMP') {
      mailConfiguration.params.apiKey = this.state.apiKey
        ? this.state.apiKey
        : account.mailConfiguration
        ? account.mailConfiguration.params.apiKey
        : '';
    }

    if (this.provider === 'SEGMENTIFY_V2') {
      mailConfiguration.endPoint = this.state.endPoint;
      mailConfiguration.params.apiKey = this.state.apiKey
        ? this.state.apiKey
        : account.mailConfiguration.params.apiKey
        ? account.mailConfiguration.params.apiKey
        : account.apiKey;
    }

    if (this.provider === 'DOTDIGITAL') {
      mailConfiguration.params.username = this.state.username
        ? this.state.username
        : account.mailConfiguration
        ? account.mailConfiguration.params.username
        : '';

      mailConfiguration.params.password = this.state.password
        ? this.state.password
        : account.mailConfiguration
        ? account.mailConfiguration.params.password
        : '';
    }

    if (this.provider === 'KLAVIYO') {
      mailConfiguration.params.apiKey = this.state.apiKey
        ? this.state.apiKey
        : account.mailConfiguration
        ? account.mailConfiguration.params.apiKey
        : '';

      mailConfiguration.params.privateKey = this.state.privateKey
        ? this.state.privateKey
        : account.mailConfiguration
        ? account.mailConfiguration.params.privateKey
        : '';
    }

    mailConfiguration.params.fromName = this.state.fromName
      ? this.state.fromName
      : '';
    mailConfiguration.provider = this.provider;
    mailConfiguration.params.fromAddress = this.state.fromAddress
      ? this.state.fromAddress
      : '';
    mailConfiguration.params.replyName = this.state.replyName
      ? this.state.replyName
      : '';
    mailConfiguration.params.replyAddress = this.state.replyAddress
      ? this.state.replyAddress
      : '';
    mailConfiguration.params.checkMethod = this.state.selectedCheckMethod
      ? this.state.selectedCheckMethod
      : '';
    mailConfiguration.params.unsubscriptionLink = this.state.unsubscriptionLink
      ? this.state.unsubscriptionLink
      : '';
    mailConfiguration.capping =
      this.state.capping !== undefined
        ? this.state.capping
        : account.mailConfiguration
        ? account.mailConfiguration.hasOwnProperty('params') &&
          Object.keys(account.mailConfiguration.params).length !== 0
          ? account.mailConfiguration.capping
          : null
        : null;

    this.setState({
      mailConfiguration,
    });

    updateEmailConfig(mailConfiguration, response => {
      if (response && response.status === 'SUCCESS') {
        if (isSwitchAccount()) {
          const newUserToSave = isSwitchAccount();
          newUserToSave.mailConfiguration = account.mailConfiguration;
          userActions.updateSwitchAccount(newUserToSave);

          if (response.customObject) {
            switchUserActions.updateMailConfInSwitched(response.customObject);
          } else {
            switchUserActions.updateMailConfInSwitched(mailConfiguration);
          }
        } else if (response.customObject) {
          userActions.updateMailConf(response.customObject);
        } else {
          userActions.updateMailConf(mailConfiguration);
        }

        uiActions.isLoaded();
        const success = () => (
          <p>{t('Platform integration steps is completed successfully.')}</p>
        );
        uiActions.showNotification({
          content: success,
        });
        this.setState({
          ui: 'isLoaded',
          congrats: true,
        });
      } else {
        uiActions.isLoaded();
        const fail = () => (
          <p>
            {t(
              'Unfortunately integration steps is failed. Please check your cred.',
            )}
          </p>
        );
        uiActions.showErrorNotification(fail);
        this.setState({
          ui: 'isLoaded',
        });
      }
    });
  }

  checkAvailability() {
    getIntegrations(integrations => {
      if (
        integrations.USER_OPERATIONS === 'COMPLETED' &&
        integrations.PAGE_VIEW === 'COMPLETED'
      ) {
        this.setState({
          isIntegrated: true,
        });
      }
    });
  }

  render() {
    return (
      <div>
        <h2 className='page-title'>
          {t("Let's Integrate Segmentify Email Service")}
        </h2>

        <div className='email-settings-tabs'>
          <h4
            style={{
              display: 'inline-block',
              marginRight: '10px',
              fontWeight: '500',
              textDecoration:
                this.state.selectedMenuItem === 'Instructions'
                  ? 'underline'
                  : 'unset',
              cursor: 'pointer',
            }}
            onClick={this.selectMenuItem.bind(this, 'Instructions')}
          >
            Instructions
          </h4>
          <h4
            style={{
              display: 'inline-block',
              marginRight: '10px',
              fontWeight: '500',
              textDecoration:
                this.state.selectedMenuItem === 'Unsub' ? 'underline' : 'unset',
              cursor: 'pointer',
            }}
            onClick={this.selectMenuItem.bind(this, 'Unsub')}
          >
            Unsub
          </h4>
          <h4
            style={{
              display: 'inline-block',
              marginRight: '10px',
              fontWeight: '500',
              textDecoration:
                this.state.selectedMenuItem === 'TestUsers'
                  ? 'underline'
                  : 'unset',
              cursor: 'pointer',
            }}
            onClick={this.selectMenuItem.bind(this, 'TestUsers')}
          >
            Test Users
          </h4>
          <h4
            style={{
              display: 'inline-block',
              marginRight: '10px',
              fontWeight: '500',
              textDecoration:
                this.state.selectedMenuItem === 'Capping'
                  ? 'underline'
                  : 'unset',
              cursor: 'pointer',
            }}
            onClick={this.selectMenuItem.bind(this, 'Capping')}
          >
            Capping
          </h4>
          {this.state.isSuperUser && (
            <h4
              style={{
                display: 'inline-block',
                marginRight: '10px',
                fontWeight: '500',
                textDecoration:
                  this.state.selectedMenuItem === 'UserParameters'
                    ? 'underline'
                    : 'unset',
                cursor: 'pointer',
              }}
              onClick={this.selectMenuItem.bind(this, 'UserParameters')}
            >
              User Parameters
            </h4>
          )}
        </div>
        {this.state.selectedMenuItem === 'Instructions' && (
          <Instructions
            updateData={this.getInformationsFromModules}
            updateProvider={this.updateProvider}
            isIntegrated={this.state.isIntegrated}
            isMultipleComps
            congrats={this.state.congrats}
            updateSettings={this.updateSettings}
            isSuperUser={this.state.isSuperUser}
          />
        )}
        {this.state.selectedMenuItem === 'Unsub' && (
          <Unsub
            updateData={this.getInformationsFromModules}
            isMultipleComps
            checkMethod={this.state.selectedCheckMethod}
            unsubscriptionLink={this.state.unsubscriptionLink}
            updateSettings={this.updateSettings}
            provider={this.provider}
            isSuperUser={this.state.isSuperUser}
          />
        )}
        {this.state.selectedMenuItem === 'TestUsers' && <TestUsers />}
        {this.state.selectedMenuItem === 'Capping' && (
          <Capping
            updateData={this.getInformationsFromModules}
            isMultipleComps
            updateSettings={this.updateSettings}
            isSuperUser={this.state.isSuperUser}
          />
        )}
        {this.state.selectedMenuItem === 'UserParameters' && <UserParameters />}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  validate: store.ui.validate,
  language: store.ui.language,
});

export default connect(mapStatesToProps)(Settings_Email);
