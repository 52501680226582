"use strict";

import { createConstants } from "../../system/store";

export default createConstants(
    "RESET_SYNONYMS",
    "LANGUAGE_CHANGED",
    "ADD_SYNONYMS_ITEM_GROUP",
    "UPDATE_SYNONYMS_ITEM",
    "REMOVE_SYNONYMS_ITEM",
    "ADD_ALL_SYNONYM",
);