import { t } from '../../system/ui';

export const itemsPerScroll = 10;

export const csSearchParams = ['date', 'search'];

export const sortingTypes = [
  {
    text: 'Revenue',
    type: 'revenue',
  },
  {
    text: 'CTR',
    type: 'CTR',
  },
  {
    text: 'Conversion',
    type: 'Conversion',
  },
];

export const ABANDONED_LIST = [
  {
    title: t('Step 1 - First Reminder'),
    desc: t('See the performance of the step 1'),
    key: 'ABANDONED_CART_FIRST_REMINDER',
  },
  {
    title: t('Step 2 - Second Reminder'),
    desc: t('See the performance of the step 2'),
    key: 'ABANDONED_CART_SECOND_REMINDER',
  },
  {
    title: t('Step 3 - Alternative Products'),
    desc: t('See the performance of the step 3'),
    key: 'ABANDONED_CART_ALTERNATIVE_PRODUCTS',
  },
  {
    title: t('Step 4 - Coupon'),
    desc: t('See the performance of the step 4'),
    key: 'ABANDONED_CART_COUPON',
  },
];

export const emailSortingTypes = [
  ...sortingTypes,
  {
    text: 'Contribution',
    type: 'Contribution',
  },
  {
    text: 'Date',
    type: 'date',
  },
];

export const recommendationSortingTypes = [
  {
    text: 'Date',
    type: 'date',
  },
  ...sortingTypes,
  {
    text: 'Contribution',
    type: 'Contribution',
  },
];

export const searchSortingTypes = [
  ...sortingTypes,
  {
    text: 'Contribution',
    type: 'Contribution',
  },
];

export const campaignBadges = {
  scheduled: {
    icon: 'scheduled',
    color: '#547494',
    tooltip: 'Scheduled Push Notification',
  },
  periodic: {
    icon: 'periodicPush',
    color: '#56bbd8',
    tooltip: 'Periodic Push Notification',
  },
  sent: {
    icon: 'sentPush',
    color: '#66d281',
    tooltip: 'Sent Push Notification',
  },
  personalized: {
    icon: 'personalizedPush',
    color: '#66d281',
    tooltip: 'Trigger Based Push Notification',
  },
};

export const baseCampaignNames = {
  retention: t('Retention Campaigns'),
  growth: t('Growth Campaigns'),
  winback: t('Winback Campaigns'),
  fly: t('Fly Mode Campaigns'),
};

export const campaignNames = {
  ...baseCampaignNames,
  recommendation: t('Recommendation Campaigns'),
  permission: t('Permission Prompt'),
  bulk: t('Bulk / Segmented Notifications'),
  'email-collection': t('Email Collection'),
};

export const pushCampaignNames = {
  ...campaignNames,
  betting: t('Betting Campaigns'),
};

export const mailGroups = {
  retention: [
    'ABANDONED_CART',
    'LAST_VISIT_ALTERNATIVES',
    'NICE_TO_MEET_YOU',
    'LAST_VISIT_REMINDER',
    'REPLENISHMENT',
  ],
  growth: [
    'NEW_COMERS',
    'TOP_SELLERS',
    'ORDER_FOLLOW_UP',
    'BIRTHDAY',
    'ANNIVERSARY',
  ],
  winback: ['WE_MISSED_YOU', 'CHURN', 'PRICE_DROP', 'BACK_IN_STOCK'],
  recommendation: ['RECOMMENDATION'],
  fly: ['FLY'],
  'email-collection': ['NEWSLETTER'], // It's not member of mailGroups, but it's used in emails.js
};

export const pushGroups = {
  ...mailGroups,
  permission: ['PUSH_PERMISSION'],
  bulk: ['PUSH_NOTIFICATION'],
  betting: ['UPCOMING'],
  retention: [...mailGroups.retention],
};

export const findLongestStat = (obj, includedFields) => {
  let longestStat = Object.keys(obj).length > 0 && Object.keys(obj)[0];
  Object.keys(obj).forEach(item => {
    const currentStatItems = Object.keys(obj[item].stats);
    const longestStatItems = Object.keys(obj[longestStat].stats);

    let currentStatItemsLength = currentStatItems.length;
    let longestStatItemsLength = longestStatItems.length;
    if (includedFields) {
      currentStatItemsLength = currentStatItems.filter(statItemKey => {
        return includedFields.includes(statItemKey);
      }).length;
      longestStatItemsLength = longestStatItems.filter(statItemKey => {
        return includedFields.includes(statItemKey);
      }).length;
    }

    if (currentStatItemsLength >= longestStatItemsLength) {
      longestStat = item;
    }
  });
  return longestStat;
};

export const changeOrderInObject = (obj, orderList) => {
  const orderedObject = {};
  orderList.forEach(key => {
    if (obj.hasOwnProperty(key)) {
      orderedObject[key] = obj[key];
    }
  });
  return orderedObject;
};
