import './SfyAccordion.scss';
import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../../../system/ui';
import DsActions from '../DsActions';
import DsSubActions from '../DsSubActions';
import DocumentIcon from '../../../../../components/icons/documentIcon';
import Icons from '../../../../../components/icons';
import classes from '../../../RuleBasedSegmentation/SegmentList.module.scss';
import Tooltip from '../../../../../components/tooltip';

const SfyAccordion = ({ cardHeader, fetchedData }) => {
  const [toggle, setToggle] = useState(false);
  const [isToggled, setIsToggled] = useState(true);
  const tooltipHandler = () => {
    setIsToggled(!isToggled);
  };

  const onClickHandler = index => {
    if (toggle === index) {
      return setToggle(null);
    }

    return setToggle(index);
  };

  const openRef = useRef();

  useLayoutEffect(() => {
    if (toggle === null) {
      openRef.current.focus();
    }
  }, [toggle]);

  return (
    <div className='sfy-accordion--container'>
      <div className={classes.ds_single_segment_header}>
        <h3>Segment Groups</h3>
        <div className={classes['toggle-container']}>
          <label className='item is-toggle' style={{ marginBottom: 0 }}>
            <input
              type='checkbox'
              checked={isToggled}
              disabled={fetchedData.length === 0}
              onChange={() => {
                tooltipHandler();
              }}
            />
            <span
              className='item-label'
              style={{ background: 'white', borderRadius: '10px' }}
            />
          </label>
          <span className='item-help'>
            <span className='live'>{t('Stats')}</span>
          </span>
        </div>
      </div>
      {fetchedData.length > 0 ? (
        fetchedData.map(({ id, name, dynamicSegments }, index) => {
          return (
            <>
              <div
                className='sfy-accordion--item'
                key={id}
                onClick={() => onClickHandler(index)}
              >
                <div className='sfy-accordion--item-header-left'>
                  <div className='sfy-accordion--item-header-icon'>
                    <DocumentIcon color='#47607B' height='32px' width='32px' />
                  </div>
                  <div className='sfy-accordion--item-header-name'>
                    {name} {''} (
                    {dynamicSegments.length > 1
                      ? `${dynamicSegments.length} segments`
                      : `${dynamicSegments.length} segment`}
                    )
                  </div>
                </div>
                <div className='sfy-accordion--item-header-right'>
                  <DsActions campaign={id} groupName={name} />
                </div>
              </div>
              <div
                className={
                  toggle === index
                    ? 'sfy-accordion--item-content'
                    : 'sfy-accordion--item-content hidden'
                }
                ref={openRef}
              >
                {dynamicSegments && dynamicSegments.length > 0 ? (
                  dynamicSegments.map(segment => {
                    return (
                      <div
                        className='sfy-accordion--item-content-item'
                        key={segment.instanceId}
                      >
                        <div className='sfy-accordion--item-content-left'>
                          <div className='sfy-accordion--item-content-left-name'>
                            {segment.name}
                            {segment?.description && (
                              <Tooltip
                                content={t(segment.description)}
                                alignment='left'
                              >
                                <i className='icon-info' role='presentation'>
                                  <span className='for-screenreader-only' />
                                </i>
                              </Tooltip>
                            )}
                          </div>
                          {segment.status === 'ACTIVE' && (
                            <div
                              className={
                                classes[
                                  `widget-list-item-container${
                                    isToggled ? '' : '-dnone'
                                  }`
                                ]
                              }
                            >
                              <div
                                className={classes['widget-list-item-details']}
                              >
                                <Icons name='PeopleGroupIcon' />
                                <span
                                  className={
                                    classes['widget-list-item-details-text']
                                  }
                                >
                                  {segment?.reachableUser === 0 ||
                                  !segment?.reachableUser
                                    ? t('Not segmented yet')
                                    : `${segment?.reachableUser} ${t(
                                        'users in segment',
                                      )}`}
                                </span>
                              </div>
                              <div
                                className={classes['widget-list-item-details']}
                              >
                                <Icons name='pushModule' color='#737373' />
                                <span
                                  className={
                                    classes['widget-list-item-details-text']
                                  }
                                >
                                  {segment?.reachablePushUser === 0 ||
                                  !segment?.reachablePushUser
                                    ? t('No Data yet')
                                    : `${t('Push Opt-In')}: ${
                                        segment?.reachablePushUser
                                      } `}
                                </span>
                              </div>
                              <div
                                className={classes['widget-list-item-details']}
                              >
                                <Icons name='emailModule' color='#737373' />
                                <span
                                  className={
                                    classes['widget-list-item-details-text']
                                  }
                                >
                                  {segment?.reachableEmailUser === 0 ||
                                  !segment?.reachableEmailUser
                                    ? t('No Data yet')
                                    : `${t('Email Opt-In')}: ${
                                        segment?.reachableEmailUser
                                      } `}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='sfy-accordion--item-content-item-right'>
                          <div className='sfy-accordion--item-content-item-right-title'>
                            {segment.status === 'ACTIVE' ? (
                              <div className='sfy-accordion--item-active'>
                                Live
                              </div>
                            ) : (
                              <div className='sfy-accordion--item-passive'>
                                Draft
                              </div>
                            )}
                          </div>
                          <div className='sfy-accordion--item-content-item-right-actions'>
                            <DsSubActions segments={segment} />
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className='sfy-accordion--has-no-segments'>
                    Segment Group has no segments yet. You can add a segment or
                    move.
                  </div>
                )}
              </div>
            </>
          );
        })
      ) : (
        <div className='widget-list-wrapper'>
          <div className='widget-list-no-items'>{t('No Active Campaign')}</div>
        </div>
      )}
    </div>
  );
};

SfyAccordion.propTypes = {
  cardHeader: PropTypes.string.isRequired,
  fetchedData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }).isRequired,
  ),
};

SfyAccordion.defaultProps = {
  fetchedData: [],
};

export default SfyAccordion;
