import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import Clipboard from 'clipboard';

import { t } from '../system/ui';
import { selectText } from '../system/string';
import { setTitle } from '../system/document';
import { isSwitchAccount, getApiKey } from '../modules/auth/user';
import { updateAccount } from '../modules/account/ajax';
import { TextField } from '../components/fields';

import { uiActions } from '../actions';

class Settings_Integration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      feedUrl:
        this.props.switchedUser.account.feed?.feedUrl ||
        this.props.user.account.feed?.feedUrl ||
        '',
      readonly: false,
      disabled: false,
    };

    this.successCenterCardClicked = this.successCenterCardClicked.bind(this);
    this.startTrendifyFromButton = this.startTrendifyFromButton.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.updateFeed = this.updateFeed.bind(this);
  }

  componentDidMount() {
    setTitle(t('Integrations'));

    const acc = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;

    if (acc?.feed?.feedUrl && acc?.feed?.feedUrl !== '') {
      this.setState({
        feedUrl: acc?.feed?.feedUrl,
        readonly: true,
        disabled: true,
      });
    }

    uiActions.resetPage();

    this.copyClipBoard();
  }

  onInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value.trim(),
    });
  }

  updateFeed() {
    if (!this.state.feedUrl) {
      return;
    }
    uiActions.isLoading();
    this.setState({
      disabled: true,
    });

    let acc = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;

    if (!acc.feed) {
      acc = {
        ...acc,
        feed: {
          url: this.state.feedUrl,
          type: 'XML',
          active: false,
        },
      };
    }

    updateAccount(
      acc,
      () => {
        const notificationContent = () => (
          <div>
            {t(
              'The Feed link sent was forwarded to the development team and the review started.',
            )}
          </div>
        );
        uiActions.showNotification({ content: notificationContent });
        this.setState({
          readonly: true,
          disabled: true,
        });
        uiActions.isLoaded();
      },
      { isSwitchedUser: true },
    );
  }

  copyClipBoard() {
    new Clipboard(this.refs.codeWrapper.querySelector('button'), {
      target: () => {
        return this.refs.codeWrapper.querySelector('pre');
      },
    });
  }

  successCenterCardClicked(e) {
    e.preventDefault();
    const apiKey = isSwitchAccount()
      ? this.props.switchedUser.account.apiKey
      : this.props.user.account.apiKey;
    const dataCenterUrl = isSwitchAccount()
      ? this.props.switchedUser.account.dataCenterUrl
      : this.props.user.account.dataCenterUrl;

    const content = () => (
      <div className='system-variables'>
        <table className='data-table'>
          <thead>
            <th>{t('System Variable')}</th>
            <th>{t('Value')}</th>
            <th>{t('Description')}</th>
          </thead>
          <tbody>
            <tr>
              <td>Api Key</td>
              <td style={{ minWidth: '260px' }}>{apiKey}</td>
              <td>{t('Unique Segmentify key value to send events')}</td>
            </tr>
            <tr>
              <td>Data Center</td>
              <td>{dataCenterUrl}</td>
              <td>{t('Data center end point to send Segmentify events')}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    uiActions.openModal({ title: t('Your account specific keys'), content });
  }

  startTrendifyFromButton(e) {
    e.preventDefault();
    browserHistory.push('/trendify');
  }

  codeCopied() {
    this.copyClipBoard(); // replaced with analytic code
  }

  render() {
    const scriptUrl = `//cdn.segmentify.com/${getApiKey()}/segmentify.js`;

    return (
      <div>
        <h2 className='page-title'>{t("Let's Integrate Segmentify")}</h2>
        <ol className='help-steps'>
          <li>
            <h3>{t('add-js-code')}</h3>
            <p>{t('add-js-code-info')}</p>
            <div ref='codeWrapper' className='code-wrapper'>
              <pre className='code-sample' onClick={selectText}>
                {`<script src="${scriptUrl}" charset="UTF-8"></script>`}
              </pre>
              <button
                type='button'
                onClick={this.codeCopied}
                className='tertiary-action'
              >
                Copy
              </button>
            </div>
          </li>
          <li>
            <h3>{t('share-your-product')}</h3>
            <p>{t('share-your-product-info')}</p>
            <div className='page-forms'>
              <div className='page-content-block'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    label='Feed Url'
                    name='feedUrl'
                    className='item-stacked one-whole'
                    value={this.state.feedUrl}
                    onChange={this.onInputChange}
                    readOnly={this.state.readonly}
                    required
                    placeholder='https://yourwebsite.com/feed.xml'
                  />
                  <button
                    type='button'
                    onClick={this.updateFeed}
                    className='tertiary-action'
                    disabled={this.state.disabled}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li>
            <h3>
              {t('Give Access fro GA4 to')}
              {t('LinkToAnalytics')}
            </h3>
            <p>
              {t('Give Access from GA4 to info')} {t('LinkToAnalytics')}
            </p>
          </li>
          {/* <li>
            <h3>{t('Integrate Events')}</h3>

            <div className='integration-type'>
              <a
                className={classNames('integration-type-selection')}
                style={{ alignItems: 'center' }}
                href='https://segmentify.github.io/segmentify-dev-doc/integration_web/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icons name='browser' width='64' height='64' color='#7a7a7c' />
                <span>{t('Web Integration')}</span>
              </a>
              <p>{t('Web Integration')}</p>
            </div>

            <div className='integration-type'>
              <a
                className={classNames('integration-type-selection')}
                style={{ alignItems: 'center' }}
                href='https://segmentify.github.io/segmentify-dev-doc/integration_mobile/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icons name='mobile' width='64' height='64' color='#7a7a7c' />
                <span>{t('Mobile App (Native)')}</span>
              </a>
              <p>{t('Mobile App (Native)')}</p>
            </div>

            <div className='integration-type'>
              <a
                className={classNames('integration-type-selection')}
                style={{ alignItems: 'center' }}
                href='https://segmentify.github.io/segmentify-dev-doc/integration_rest/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icons
                  name='editRowPencil'
                  width='64'
                  height='64'
                  color='#7a7a7c'
                />
                <span>{t('Custom Integration')}</span>
              </a>
              <p>{t('Custom Integration')}</p>
            </div>

            <div className='integration-type'>
              <a
                onClick={this.successCenterCardClicked}
                className={classNames('integration-type-selection')}
                href='https://segmentify.zendesk.com/hc/en-us'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icon name='code' />
                <span>
                  {t('Self Service? See your account specific values')}
                </span>
              </a>
              <p onClick={this.successCenterCardClicked}>
                {t(
                  'Custom Segmentify integration requires two important values.',
                )}
              </p>
            </div>
          </li> */}
          <li>
            <h3>{t('PrivacyPolicyCta')}</h3>
            <p>
              {t('PrivacyPolicyContent')}
              {t('PrivacyPolicy')}
            </p>
          </li>
        </ol>
        <div className='lets-integrate'>
          <a
            onClick={this.startTrendifyFromButton}
            href='/trendify'
            className='secondary-action'
          >
            {t('Proceed to Integration')}
          </a>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(Settings_Integration);
