import React from 'react';
import './GFCampaignList.scss';
import SFYList from '../../sfy-components/DraggableList/SFYList';
import { SFYCampaignStatus } from '../../sfy-components/module-exports';

/**
 * @name GFCampaignList component
 * @param campaignList {object}
 * @param setCampaignList {function}
 * @param actions {object}
 * @param onDragged {function}
 * @param maxCount
 * @returns {JSX.Element}
 * @constructor
 */

const GFCampaignList = ({
  campaignList,
  setCampaignList,
  actions,
  onDragged = null,
  maxCount,
}) => {
  const renderCampaignLists = (
    allCampaigns,
    campaigns,
    campaignStatus,
    campaignTitle,
    isDraggable = false,
  ) => {
    if (campaigns.length === 0) {
      return null;
    }

    return (
      <>
        <SFYCampaignStatus
          campaignStatus={campaignStatus}
          allCampaign={campaigns}
          campaignTitle={campaignTitle}
          maxCount={maxCount}
        />
        <SFYList
          isDraggable={isDraggable}
          allList={allCampaigns}
          list={campaigns}
          setList={setCampaignList}
          actions={actions}
          onDragged={onDragged}
        />
      </>
    );
  };

  return (
    <>
      {renderCampaignLists(
        campaignList,
        campaignList.active,
        'ACTIVE',
        'Live Campaigns',
        true,
      )}
      {renderCampaignLists(
        campaignList,
        campaignList.test,
        'COMPLETED',
        'Test Campaigns',
        true,
      )}
    </>
  );
};

export default GFCampaignList;
