import React, { memo } from 'react';
import classes from './styles/VisualBuilderSubItem.module.scss';
import { t } from '../../../system/ui';
import Tooltip from '../../tooltip';
import Icons from '../../icons';

const VisualBuilderSubItem = ({
  id,
  image,
  name,
  priceText,
  inStock,
  category,
  brand,
  score,
}) => {
  return (
    <div className={classes['flex-list-item']} key={id}>
      <caption className={classes['list-image-container']}>
        <img
          src={image}
          alt={`${image}-alt`}
          className={classes['list-image']}
        />
      </caption>
      <div className={classes['list-desc-container']}>
        <div className={classes['list-item-name']}>{name}</div>
        <div className={classes['list-item-price-stock']}>
          <span className={classes['list-item-price']}>{priceText}</span>
          {inStock ? (
            <span className={classes['list-item-in-stock']}>
              <i className='icon-check-circle' />
              <span className={classes['stock-description']}>
                {t('In Stock')}
              </span>
            </span>
          ) : (
            <span className={classes['list-item-out-stock']}>
              <Icons
                name='crossCircle'
                color='#D31116'
                height='18'
                width='18'
              />
              <span className={classes['stock-description']}>
                {t('Out of Stock')}
              </span>
            </span>
          )}
        </div>
        <div className={classes['list-item-brand-category']}>
          <span className={classes['list-item-brand-category-spec']}>
            {t('Brand')}
            {': '}
          </span>
          <span className={classes['list-item-brand-category-name']}>
            {brand}
          </span>
        </div>
        <div className={classes['list-item-brand-category']}>
          <span className={classes['list-item-brand-category-spec']}>
            {t('Category')}
            {': '}
          </span>
          <span className={classes['list-item-brand-category-name']}>
            {category}
          </span>
        </div>
        {score && (
          <div className={classes['list-item-score']}>
            <span className={classes['list-item-score-spec']}>
              {t('Score')} {': '}
            </span>
            <span className={classes['list-item-score-value']}>
              {score}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(VisualBuilderSubItem);
