import React from 'react';
import PropTypes, { shape } from 'prop-types';
import Highcharts from 'highcharts';
import variablePie from 'highcharts/modules/variable-pie';
import HighchartsReact from 'highcharts-react-official';

variablePie(Highcharts);

const InsightsPieChart = ({ items }) => {
  const getRadiusValue = itemIndex => {
    if (itemIndex === items.length - 1) {
      return 0;
    }

    switch (itemIndex) {
      case 0:
        return 100;
      case 1:
        return 60;
      default:
        return 10;
    }
  };

  items.sort((a, b) => b.value - a.value);
  const colors = items.map(item => item.color);
  const chartData = items.map((item, index) => {
    return {
      name: item.title,
      y: item.value,
      z: getRadiusValue(index),
    };
  });

  const config = {
    chart: {
      type: 'variablepie',
      backgroundColor: 'transparent',
    },
    title: {
      text: '',
      margin: 0,
    },
    tooltip: {
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      useHTML: true,
      style: {
        padding: 0,
      },
      headerFormat: '',
      pointFormat: `
        <span class='tooltip-title'>{point.name}</span>
        <span class='tooltip-percent-value'>{point.percentage:.1f}%</span>
        <div class='tooltip-bottom-arrow' />
        `,
      borderRadius: 10,
    },
    responsive: {
      rules: [
        {
          condition: {
            minWidth: 100,
          },
          chartOptions: {
            chart: {
              height: 304,
              maxWidth: 500,
            },
            subtitle: {
              text: null,
            },
            navigator: {
              enabled: false,
            },
          },
        },
      ],
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      variablepie: {
        size: '100%',
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
      },
    },
    colors,
    series: [
      {
        minPointSize: 100,
        innerSize: '0%',
        data: chartData,
      },
    ],
  };

  return (
    <div className='insights-pie-chart-container'>
      <HighchartsReact highcharts={Highcharts} options={config} />
    </div>
  );
};

InsightsPieChart.propTypes = {
  items: PropTypes.arrayOf(
    shape({
      title: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    }),
  ).isRequired,
};

export default InsightsPieChart;
