import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { Select } from "../../components/fields";
import { isSwitchAccount } from "../../modules/auth/user";
import Icon from "../../components/icon";
import { updateSwitchRegion } from "../../actions/switchRegion";

import { browserHistory,withRouter } from "react-router";


class RegionSwitcher extends React.Component {
  constructor(props) {
    super(props);

    const regions = this.getRegions();
    this.state = {
      regions
    };
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.switchedAccount !== this.props.switchedAccount ||
      nextProps.userAccount !== this.props.userAccount
    ) {
      const regions = this.getRegions(nextProps);
      const selected = regions[0].value;
      this.setState({
        regions
      });

      updateSwitchRegion(selected);
    }
  }

  onChange(item) {
    updateSwitchRegion(item.value);
    let urlPath=this.props.location.pathname;
    browserHistory.replace(urlPath);
  }

  getRegions(nextProps) {
    const props = nextProps || this.props;
    const regions = [];

    let account;
    if (isSwitchAccount()) {
      account = props.switchedAccount;
    } else {
      account = props.userAccount;
    }

    const additionalRegions = account.additionalRegions || [];
    const mainRegion = account.mainRegion || "";

    regions.push({
      label: "All",
      value: "ALL"
    });

    if (mainRegion) {
      regions.push({
        label: mainRegion,
        value: mainRegion
      });
    }
    const mappedRegions = additionalRegions.map(item => ({
      label: item,
      value: item
    }));
    return regions.concat(mappedRegions);
  }

  render() {
    const regions = this.state.regions;
    const selectedRegion = this.props.selectedRegion;

    if (regions.length < 3) {
      return null;
    }

    if (this.state.isHidden) {
      return null;
    }

    return (
      <div className={
        classNames(
          "region-switcher", {
          "is-header": this.props.isHeader
        })}
      >
        <div className="region-switcher__icon"></div>
        <Select
          name="region"
          className="item-stacked full-width"
          value={selectedRegion}
          options={regions}
          onChange={this.onChange}
          searchable={true}
        />
        <div className="region-switcher__arrow-down">
          <Icon name="chevron-down" />
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  adminNotifications: store.ui.adminNotifications,
  switchedAccount: store.switchedUser.switchedUser.account,
  userAccount: store.user.user.account,
  selectedRegion: store.switchRegion.selectedRegion
});

export default connect(mapStatesToProps)(withRouter(RegionSwitcher));
