/**
 * @author Bilal Cinarli
 */

import { locale } from '../constants/ui';
import { getAccountCurrency } from '../modules/auth/user';
import { toLocaleString, toLocaleStringNoLetters } from './localeString';

export const localeString = (str, options) => {
  const currency = getAccountCurrency();
  const formatting = options || {
    decimal: currency.decimalSeparator,
    thousand: currency.thousandSeparator,
  };
  if (typeof str !== 'undefined') {
    const number = str * 1;

    return Number.isInteger(number)
      ? toLocaleString(str, locale, formatting)
      : toLocaleString(str, locale, {
          minimumFractionDigits: 2,
          ...formatting,
        });
  }
};

export const toLocaleStringDigits = str => {
  if (typeof str !== 'undefined') {
    const number = str * 1;
    return Number.isInteger(number)
      ? toLocaleStringNoLetters(str, locale)
      : toLocaleStringNoLetters(str, locale, { minimumFractionDigits: 2 });
  }
};

export const localeStringMoney = (str, givenType, options) => {
  let defaults = getAccountCurrency() || { minimumFractionDigits: 2 };
  if (defaults.thousandSeparator) {
    defaults = {
      currency: defaults.code,
      style: 'currency',
      decimal: defaults.decimalSeparator,
      thousand: defaults.thousandSeparator,
      minimumFractionDigits: defaults.decimals,
      currencyFormat: options ? options.currencyFormat : undefined,
    };
  }
  if (typeof str !== 'undefined') {
    if (givenType === 'number') {
      if (isNaN(toLocaleString(str, locale, defaults))) {
        return '0';
      }
      return toLocaleString(str, locale, defaults);
    }
    return toLocaleString(str, locale, defaults);
  }
};

export const decodeHtml = (str, givenType) => {
  if (typeof str !== 'undefined') {
    const e = document.createElement('div');
    e.innerHTML = str;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  }
};

export const toCamelCase = str => {
  return str
    .replace(/\s(.)/g, function($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, function($1) {
      return $1.toLowerCase();
    });
};

export const capitalize = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toSlug = str => {
  return str
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/_+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

export const isAbsoluteUrl = url => {
  if (!url) {
    return '-';
  }
  return url.indexOf('//') > -1 && url.indexOf('//') < 7;
};

export const tryParse = str => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};

export const selectText = node => {
  const range = document.createRange();
  range.selectNode(node.currentTarget);
  window.getSelection().addRange(range);
};

export const getQueryString = (name, url) => {
  if (!url) {
    url = window.location.href;
  }
  const parser = new DOMParser();
  url = parser.parseFromString(url, 'text/html').body.textContent;
  name = name.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  return results
    ? decodeURIComponent(results[2].replace(/\+/g, ' ').trim())
    : null;
};

export const formatMoneyValue = value => {
  const opts = {
    currencyFormat: '{{code}}{{num}}',
  };
  const formattedValue =
    value < 1000
      ? localeStringMoney(value, false, opts)
      : value < 1000000
      ? `${localeStringMoney(value / 1000, false, opts)}K`
      : value < 1000000000
      ? `${localeStringMoney(value / 1000000, false, opts)}M`
      : `${localeStringMoney(value / 1000000000, false, opts)}B`;
  return formattedValue;
};

export const formatValue = value => {
  const formattedValue =
    value < 1000
      ? toLocaleStringDigits(value)
      : value < 1000000
      ? `${toLocaleStringDigits(value / 1000)}K`
      : value < 1000000000
      ? `${toLocaleStringDigits(value / 1000000)}M`
      : `${toLocaleStringDigits(value / 1000000000)}B`;
  return formattedValue;
};
