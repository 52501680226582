import React from 'react';
import classes from './EventButton.module.scss';
import Tooltip from '../../../../../tooltip';
import Icons from '../../../../../icons';

export const EventButton = ({ onClick }) => {
  const tip = 'Shows events';

  return (
    <div onClick={onClick} className={classes['sfy-event-btn']}>
      <Tooltip content={tip} alignment='top' newTooltip>
        <Icons name='EventListIcon' height='28' width='28' />
      </Tooltip>
    </div>
  );
};
