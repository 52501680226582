/** @fileOverview List component for product bundle */

import React, { memo, useContext, useEffect } from 'react';
import { TransitionGroup } from 'react-transition-group';
import '../../../../components/sfy-components/SFYBundleImgList/SFYBundleImgList.scss';
import '../../../../components/sfy-components/SFYBundleCategory/SFYBundleCategoryList.scss';
import { SFYThead } from '../../../../components/sfy-components/module-exports';
import ProductList from './ProductList';
import CategoryList from './CategoryList';
import './List.scss';
import { Context as ProductBundleContext } from '../store/productBundleProvider';

/**
 * @name SFYBundleCategoryList component
 * @description List component displays list of items and its actions
 * @param {Array} listData - list of items
 * @param {string} linkToDetail - router path to detail page of list item or add edit page
 * @param {boolean} tableHead - table head of the list
 * @param {function} modalInvoker - function to invoke modal with delete confirmation
 * @param {boolean} isCategory - is list item category
 * @param {boolean} bundleHead - bundle head
 * @param {boolean} bundleHeadConstant - bundle head constant
 * @param {Array} selectedItems - id array of selected items
 * @param {object} selectedItemActions - functions for selected items, like delete, edit, etc.
 * @returns {JSX.Element}
 */

const List = ({
  linkToDetail,
  tableHead,
  modalInvoker,
  isCategory = false,
  bundleHead,
  bundleHeadConstant,
  selectedItems,
  selectedItemActions,
}) => {
  const {
    state: { productBundleList, categoryBundleList },
  } = useContext(ProductBundleContext);

  useEffect(() => {
    return () => {
      selectedItemActions.clearAllItems();
    };
  }, []);

  /**
   * @name selectAllAction
   * @description select all items in list or clear all items
   * @returns {void}
   */
  const selectAllAction = () => {
    if (isCategory) {
      if (selectedItems.length === categoryBundleList.length) {
        selectedItemActions.clearAllItems();
        return;
      }
      selectedItemActions.selectAllItems(categoryBundleList);
    } else {
      if (selectedItems.length === productBundleList.length) {
        selectedItemActions.clearAllItems();
        return;
      }
      selectedItemActions.selectAllItems(productBundleList);
    }
  };

  return (
    <section className='sfy-bundleCategory-container'>
      {tableHead && (
        <SFYThead
          bundleHead={bundleHead}
          bundleHeadConstant={bundleHeadConstant}
          isSelectAllEnabled={
            categoryBundleList.length > 0 || productBundleList.length > 0
          }
          isSelectAllChecked={
            isCategory
              ? selectedItems.length === categoryBundleList.length
              : selectedItems.length === productBundleList.length
          }
          selectAllAction={selectAllAction}
        />
      )}

      <TransitionGroup>
        {isCategory ? (
          <CategoryList
            linkToDetail={linkToDetail}
            selectedItems={selectedItems}
            selectedItemActions={selectedItemActions}
          />
        ) : (
          <ProductList
            linkToDetail={linkToDetail}
            selectedItems={selectedItems}
            selectedItemActions={selectedItemActions}
          />
        )}
      </TransitionGroup>
    </section>
  );
};

export default memo(List);
