import { t } from '../../../system/ui';

const getEmailComponentList = mode => {
  switch (mode) {
    case 'WE_MISSED_YOU':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'We Missed You',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Select Time',
              cardName: 'workingHour',
              componentName: 'SFYSelectTime',
              tooltipText: 'When should the campaign launch ?',
              isTooltipVisible: true,
            },
            {
              cardTitle: 'Trigger Time in Days',
              cardName: 'delayInDays',
              componentName: 'SFYSchedulingTextField',
              tooltipText:
                'Campaign will be sent after [X] days after the user last visited to your website.',
              isTooltipVisible: true,
            },
          ],
        },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              objectName: 'params',
              tooltipText: '',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              objectName: 'params',
              tooltipText: '',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'CHURN':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Churn',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Select Time',
              cardName: 'workingHour',
              componentName: 'SFYSelectTime',
              tooltipText: 'When should the campaign launch ?',
              isTooltipVisible: true,
            },
            {
              cardTitle: 'Trigger Time in Days',
              cardName: 'delayInDays',
              componentName: 'SFYSchedulingTextField',
              tooltipText:
                'Select how many days should pass after the user action before the email is triggered.',
              isTooltipVisible: true,
            },
          ],
        },
        {
          name: 'condition',
          componentName: 'Condition',
          title: 'Condition',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Purchase Count',
              cardName: 'purchaseCount',
              componentName: 'SFYSchedulingTextField',
              tooltipText: 'Min count of purchases done by the user.',
              isTooltipVisible: true,
              conditionType: 'textField',
              objectName: 'params',
            },
          ],
        },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              objectName: 'params',
              tooltipText: '',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              objectName: 'params',
              tooltipText: '',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'PRICE_DROP':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Price Drop',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Frequency',
              cardName: 'period',
              componentName: 'SFYScheduleDropdown',
              options: [
                { value: 'every_day', label: t('Daily') },
                { value: 'every_hour', label: t('Hourly') },
              ],
              tooltipText: 'How frequently will the campaign be sent?',
              objectName: 'schedule',
              type: 'period',
            },
            {
              cardTitle: 'Select Time',
              cardName: 'workingHour',
              componentName: 'SFYConditionalSchedule',
              tooltipText: 'When should the campaign launch ?',
              isTooltipVisible: true,
            },
          ],
        },
        {
          name: 'condition',
          componentName: 'Condition',
          title: 'Condition',
          placeholder: '',
          key: 'condition-1',
          componentDetails: [
            {
              cardTitle: '',
              cardName: 'capCount',
              componentName: '',
              tooltipText: '',
              isTooltipVisible: false,
              conditionType: 'textField-dropdown',
              objectName: 'capping',
            },
            {
              cardTitle: '',
              cardName: 'frequency',
              componentName: '',
              tooltipText: '',
              isTooltipVisible: false,
              conditionType: 'textField-dropdown',
              objectName: 'capping',
            },
          ],
        },
        {
          name: 'condition',
          componentName: 'Condition',
          title: 'Condition',
          placeholder: '',
          key: 'condition-2',
          componentDetails: [
            {
              cardTitle: 'Discount Ratio',
              cardName: 'discountRatio',
              componentName: 'SFYSchedulingTextField',
              tooltipText:
                'The value that is the minimum discounted limit per product as a percentage.',
              isTooltipVisible: true,
              conditionType: 'textField',
              objectName: 'params',
            },
          ],
        },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              objectName: 'params',
              tooltipText: '',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              objectName: 'params',
              tooltipText: '',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'BACK_IN_STOCK':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Back In Stock',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Select Time',
              cardName: 'workingHour',
              componentName: 'SFYSelectTime',
              tooltipText: 'When should the campaign launch ?',
              isTooltipVisible: true,
            },
          ],
        },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              objectName: 'params',
              tooltipText: '',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              objectName: 'params',
              tooltipText: '',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'NEW_COMERS':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'New Arrivals',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Select a Day',
              cardName: 'workingDay',
              componentName: 'SFYSelectDay',
              tooltipText: 'The day that campaign will work on',
              isTooltipVisible: true,
            },
            {
              cardTitle: 'Select Time',
              cardName: 'workingHour',
              componentName: 'SFYSelectTime',
              tooltipText: 'When should the campaign launch ?',
              isTooltipVisible: true,
            },
          ],
        },
        // {
        //   name: 'condition',
        //   componentName: 'Condition',
        //   title: 'Condition',
        //   placeholder: '',
        //   componentDetails: [
        //     {
        //       cardTitle: '',
        //       cardName: 'daysWithin',
        //       componentName: 'SFYSchedulingTextField',
        //       tooltipText: '',
        //       isTooltipVisible: false,
        //       conditionType: 'text-textField',
        //       objectName: 'params',
        //     },
        //   ],
        // },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              objectName: 'params',
              tooltipText: '',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'TOP_SELLERS':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Top Sellers',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Select a Day',
              cardName: 'workingDay',
              componentName: 'SFYSelectDay',
              tooltipText: 'The day that campaign will work on',
              isTooltipVisible: true,
            },
            {
              cardTitle: 'Select Time',
              cardName: 'workingHour',
              componentName: 'SFYSelectTime',
              tooltipText: 'When should the campaign launch ?',
              isTooltipVisible: true,
            },
          ],
        },
        // {
        //   name: 'condition',
        //   componentName: 'Condition',
        //   title: 'Condition',
        //   placeholder: '',
        //   componentDetails: [
        //     {
        //       cardTitle: '',
        //       cardName: 'daysWithin',
        //       componentName: 'SFYSchedulingTextField',
        //       tooltipText: '',
        //       isTooltipVisible: false,
        //       conditionType: 'text-textField',
        //       objectName: 'params',
        //     },
        //   ],
        // },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'ORDER_FOLLOW_UP':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Order Follow-Up',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Select Time',
              cardName: 'workingHour',
              componentName: 'SFYSelectTime',
              tooltipText: 'When should the campaign launch ?',
              isTooltipVisible: true,
            },
            {
              cardTitle: 'Trigger Time in Days',
              cardName: 'delayInDays',
              componentName: 'SFYSchedulingTextField',
              tooltipText:
                'Select how many days should pass after the user action before the email is triggered.',
              isTooltipVisible: true,
            },
          ],
        },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              objectName: 'params',
              tooltipText: '',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'BIRTHDAY':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Birthday Campaign',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Select Time',
              cardName: 'workingHour',
              componentName: 'SFYSelectTime',
              tooltipText: 'When should the campaign launch ?',
              isTooltipVisible: true,
            },
          ],
        },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'ANNIVERSARY':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Anniversary Campaign',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Select Time',
              cardName: 'workingHour',
              componentName: 'SFYSelectTime',
              tooltipText: 'When should the campaign launch ?',
              isTooltipVisible: true,
            },
          ],
        },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'ABANDONED_CART':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Abandoned Cart',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Send Between',
              cardName: 'interval',
              componentName: 'SFYSelectInterval',
              tooltipText:
                'The campaign will be launched during the time slot you choose.',
              type: 'interval',
            },
            {
              cardTitle: 'Trigger Time in Hours',
              cardName: 'delayInHours',
              componentName: 'SFYSchedulingTextField',
              tooltipText:
                'Select how many days should pass after the user action before the email is triggered.',
              isTooltipVisible: true,
            },
          ],
        },
        {
          name: 'condition',
          componentName: 'Condition',
          title: 'Condition',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: '',
              cardName: 'capCount',
              componentName: '',
              tooltipText: '',
              isTooltipVisible: false,
              conditionType: 'textField-dropdown',
              objectName: 'capping',
            },
            {
              cardTitle: '',
              cardName: 'frequency',
              componentName: '',
              tooltipText: '',
              isTooltipVisible: false,
              conditionType: 'textField-dropdown',
              objectName: 'capping',
              type: 'frequency',
            },
          ],
        },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'LAST_VISIT_ALTERNATIVES':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Last Visit Alternatives',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Send Between',
              cardName: 'interval',
              componentName: 'SFYSelectInterval',
              tooltipText:
                'The campaign will be launched during the time slot you choose.',
              type: 'interval',
            },
            {
              cardTitle: 'Trigger Time in Hours',
              cardName: 'delayInHours',
              componentName: 'SFYSchedulingTextField',
              tooltipText:
                'Select how many days should pass after the user action before the email is triggered.',
              isTooltipVisible: true,
            },
          ],
        },
        {
          name: 'condition',
          componentName: 'Condition',
          title: 'Condition',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: '',
              cardName: 'capCount',
              componentName: '',
              tooltipText: '',
              isTooltipVisible: false,
              conditionType: 'textField-dropdown',
              objectName: 'capping',
            },
            {
              cardTitle: '',
              cardName: 'frequency',
              componentName: '',
              tooltipText: '',
              isTooltipVisible: false,
              conditionType: 'textField-dropdown',
              objectName: 'capping',
              type: 'frequency',
            },
          ],
        },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              objectName: 'params',
              tooltipText: '',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'NICE_TO_MEET_YOU':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Nice to Meet You',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'LAST_VISIT_REMINDER':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Last Visit Reminder',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Send Between',
              cardName: 'interval',
              componentName: 'SFYSelectInterval',
              tooltipText:
                'The campaign will be launched during the time slot you choose.',
              type: 'interval',
            },
            {
              cardTitle: 'Trigger Time in Hours',
              cardName: 'delayInHours',
              componentName: 'SFYSchedulingTextField',
              tooltipText:
                'Select how many days should pass after the user action before the email is triggered.',
              isTooltipVisible: true,
            },
          ],
        },
        {
          name: 'condition',
          componentName: 'Condition',
          title: 'Condition',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: '',
              cardName: 'capCount',
              componentName: '',
              tooltipText: '',
              isTooltipVisible: false,
              conditionType: 'textField-dropdown',
              objectName: 'capping',
            },
            {
              cardTitle: '',
              cardName: 'frequency',
              componentName: '',
              tooltipText: '',
              isTooltipVisible: false,
              conditionType: 'textField-dropdown',
              objectName: 'capping',
              type: 'frequency',
            },
          ],
        },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'REPLENISHMENT':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Replenishment',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Select Time',
              cardName: 'workingHour',
              componentName: 'SFYSelectTime',
              tooltipText: 'When should the campaign launch ?',
              isTooltipVisible: true,
            },
          ],
        },
        {
          name: 'frequency',
          componentName: 'Frequency',
          title: 'Replenishment Configuration',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Sending Time',
              cardName: 'frequency',
              componentName: 'SFYSchedulingTextField',
              tooltipText:
                'Select how many days before the next date the Email Sending Time should be sent according to the purchase frequency.',
              isTooltipVisible: true,
            },
          ],
        },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'RECOMMENDATION':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Repeating',
              cardName: 'type',
              componentName: 'SFYScheduleDropdown',
              options: [
                { value: 'one_time', label: t('Once') },
                { value: 'periodic', label: t('Periodic') },
                { value: 'now', label: t('As soon as possible') },
              ],
              tooltipText: '',
              objectName: 'schedule',
              type: 'type',
            },
            {
              cardTitle: 'Schedule',
              cardName: 'schedule',
              componentName: 'SFYScheduleDatePicker',
              tooltipText:
                'The campaign will be launched during the time slot you choose.',
              type: 'recommendation',
            },
          ],
        },
        {
          name: 'targetAudience',
          componentName: 'TargetAudience',
          title: 'Target Audience',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Condition',
              cardName: 'include',
              componentName: 'SFYScheduleDropdown',
              options: [
                { label: t('Visitor is in'), value: true },
                { label: t('Visitor is not in'), value: false },
              ],
              tooltipText: '',
              objectName: 'filters',
              type: 'include',
            },
            {
              cardTitle: 'Segment',
              cardName: 'segment',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'filters',
              type: 'segment',
              searchable: true,
            },
          ],
          advancedTargeting: {
            name: 'advancedTargeting',
            componentName: 'AdvancedTargeting',
            title: 'Advanced Targeting',
            placeHolder: '',
            firstRowComponentDetails: [
              {
                cardTitle: 'Date',
                cardName: 'date',
                componentName: 'SFYStartEndDateWithDropdowns',
                options: [
                  { label: t('between'), value: 'between' },
                  { label: t('before'), value: 'before' },
                  { label: t('after'), value: 'after' },
                ],
                tooltipText: '',
                objectName: 'filters',
                type: 'include',
              },
            ],
            secondRowComponentDetails: [
              {
                cardTitle: 'Campaign',
                cardName: 'campaign',
                componentName: 'SFYScheduleDropdown',
                tooltipText: '',
                objectName: 'filters',
                type: 'campaign',
                searchable: true,
              },
              {
                cardTitle: 'Action',
                cardName: 'action',
                componentName: 'SFYScheduleDropdown',
                tooltipText: '',
                options: [
                  { label: t('SEND'), value: 'SEND' },
                  { label: t('OPEN'), value: 'OPEN' },
                  { label: t('CLICK'), value: 'CLICK' },
                  { label: t('NOT SEND'), value: 'NOT_SEND' },
                  { label: t('SEND BUT NOT OPEN'), value: 'SEND_BUT_NOT_OPEN' },
                  {
                    label: t('SEND BUT NOT CLICK'),
                    value: 'SEND_BUT_NOT_CLICK',
                  },
                ],
                objectName: 'filters',
                type: 'actionType',
                searchable: true,
              },
            ],
          },
        },
        {
          name: 'languageCurrency',
          componentName: 'LanguageCurrency',
          title: 'Language & Currency',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Language',
              cardName: 'language',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'language',
            },
            {
              cardTitle: 'Currency',
              cardName: 'currency',
              componentName: 'SFYScheduleDropdown',
              tooltipText: '',
              objectName: 'params',
              type: 'currency',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    case 'FLY':
      return [
        {
          name: 'campaignName',
          componentName: 'CampaignName',
          title: 'Campaign Name*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'subject',
          componentName: 'MailSubject',
          title: 'Mail Subject*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailContent',
          componentName: 'PreviewText',
          title: 'Preview Text*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mailSettings',
          componentName: 'EmailSettings',
          title: 'Email Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        {
          name: 'eventName',
          componentName: 'EventName',
          title: 'Event Name*',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'mappingSettings',
          componentName: 'MappingSettings',
          title: 'Mapping Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'scheduling',
          componentName: 'Scheduling',
          title: 'Scheduling',
          placeholder: '',
          componentDetails: [
            {
              cardTitle: 'Schedule',
              cardName: 'schedule',
              componentName: 'SFYScheduleStartEndDate',
            },
          ],
        },
        {
          name: 'utmSettings',
          componentName: 'UtmSettings',
          title: 'UTM Settings*',
          placeholder: 'Type Here...',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'couponCodes',
          componentName: 'CouponCodes',
          title: 'Coupon Codes',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
        {
          name: 'recommendation',
          componentName: 'Recommendation',
          title: 'Recommendation',
          placeholder: '',
          componentDetails: {}, // Can be used to pass any data to the component
        },
      ];
    default:
      return [
        // {
        //   name: 'campaignName',
        //   componentName: 'CampaignName',
        //   title: 'Campaign Name*',
        //   placeholder: 'We Missed You', // TODO: Change it as dynamic
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        // {
        //   name: 'subject',
        //   componentName: 'MailSubject',
        //   title: 'Mail Subject*',
        //   placeholder: 'Type Here...',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        // {
        //   name: 'mailContent',
        //   componentName: 'PreviewText',
        //   title: 'Preview Text*',
        //   placeholder: 'Type Here...',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        // {
        //   name: 'mailSettings',
        //   componentName: 'EmailSettings',
        //   title: 'Mail Settings*',
        //   placeholder: 'Type Here...',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        // {
        //   name: 'promotional',
        //   componentName: 'Promotional',
        //   title: '',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
        // {
        //   name: 'scheduling',
        //   componentName: 'Scheduling',
        //   title: 'Scheduling',
        //   placeholder: '',
        //   componentDetails: {}, // Can be used to pass any data to the component
        // },
      ];
  }
};

const getPageTitle = mode => {
  switch (mode) {
    case 'WE_MISSED_YOU':
      return 'We Missed You';
    case 'CHURN':
      return 'Churn';
    case 'PRICE_DROP':
      return 'Price Drop';
    case 'BACK_IN_STOCK':
      return 'Back In Stock';
    case 'NEW_COMERS':
      return 'New Arrivals';
    case 'TOP_SELLERS':
      return 'Top Sellers';
    case 'ORDER_FOLLOW_UP':
      return 'Order Follow Up';
    case 'BIRTHDAY':
      return 'Birthday';
    case 'REPLENISHMENT':
      return 'Replenishment';
    case 'ANNIVERSARY':
      return 'Anniversary';
    case 'ABANDONED_CART':
      return 'Abandoned Cart';
    case 'LAST_VISIT_ALTERNATIVES':
      return 'Last Visit Alternatives';
    case 'NICE_TO_MEET_YOU':
      return 'Nice To Meet You';
    case 'LAST_VISIT_REMINDER':
      return 'Last Visit Reminder';
    case 'RECOMMENDATION':
      return 'Bulk / Segmented';
    case 'FLY':
      return 'Fly Mode';
    default:
      return '';
  }
};

const campaignActionButtonConstant = [
  {
    btnLabel: 'Save All',
    type: 'save-justify-right',
    position: 'flex-end',
    key: '1',
  },
];

export { campaignActionButtonConstant, getEmailComponentList, getPageTitle };
