'use strict';

export const templates = {
  HUR_4: {
    text: 'first_transition',
    inputs: [
      {
        clazz: 'ListScenarioInput',
        type: 'select',
        name: 'category',
        multiple: true,
        remoteFunction: 'getAccountPageCategories|toMap',
      },
      {
        clazz: 'ListScenarioInput',
        type: 'select',
        name: 'includeUrls',
        multiple: 'true',
        onlyWithRef: true,
      },
      {
        clazz: 'ListScenarioInput',
        type: 'select',
        name: 'excludeUrls',
        multiple: 'true',
        onlyWithRef: true,
      },
    ],
  },
  HUR_8: {
    text: 'final_transition',
    inputs: [
      {
        clazz: 'ListScenarioInput',
        type: 'select',
        name: 'includeUrls',
        multiple: 'true',
        onlyWithRef: true,
      },
      {
        clazz: 'ListScenarioInput',
        type: 'select',
        name: 'excludeUrls',
        multiple: 'true',
        onlyWithRef: true,
      },
    ],
  },
  T16: {
    text: 'final_transition',
    inputs: [],
  },
  SMSG_01: {
    text: 'first_transition',
    inputs: [
      {
        clazz: 'ListScenarioInput',
        type: 'select',
        name: 'eventType',
        multiple: true,
      },
    ],
  },
  T21: {
    text: 'final_transition',
    inputs: [],
  },
  T10_1: {
    text: 'first_transition',
    inputs: [
      {
        clazz: 'ListScenarioInput',
        type: 'select',
        name: 'category',
        multiple: 'true',
        remoteFunction: 'getAccountPageCategories|toMap',
      },
      {
        clazz: 'ListScenarioInput',
        type: 'select',
        name: 'includeUrls',
        multiple: 'true',
      },
      {
        clazz: 'ListScenarioInput',
        type: 'select',
        name: 'excludeUrls',
        multiple: 'true',
      },
    ],
  },
  JB_1: {
    name: 'Journey Builder Campaign',
    templateId: 'JB_1',
    text: 'start_transition',
    inputs: [
      {
        clazz: 'ListScenarioInput',
        type: 'select',
        name: 'category',
        multiple: 'true',
        remoteFunction: 'getAccountPageCategories|toMap',
      },
      {
        clazz: 'ListScenarioInput',
        type: 'select',
        name: 'includeUrls',
        multiple: true,
      },
      {
        clazz: 'ListScenarioInput',
        type: 'select',
        name: 'excludeUrls',
        multiple: true,
      },
    ],
  },
};
