/**
 * Created by mehmetyurtar on 13/07/2017.
 */
import React from "react";

class FieldCheckbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#000"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <path
          d="M14,0H2C0.895,0,0,0.895,0,2v12c0,1.105,0.895,2,2,2h12c1.105,0,2-0.895,2-2V2C16,0.895,15.105,0,14,0z M7,11.414L3.586,8
	L5,6.586l2,2l4-4L12.414,6L7,11.414z"
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default FieldCheckbox;
