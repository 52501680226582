import Services from '../../../../service/Services';

class GlobalConfigurationServices extends Services {
  constructor({ endPoint, paginationIsActive }) {
    super({ endPoint, paginationIsActive });
  }
}

export default new GlobalConfigurationServices({
  endPoint: 'search/global-configuration',
  paginationIsActive: false,
});
