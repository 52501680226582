/**
 * This component helps to generate reports with search module and page type.
 * @fileoverview
 * @requires react
 * @requires classNames
 * @requires react-redux
 * @requires react-router
 */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { browserHistory } from 'react-router';

/**
 * Module dependencies
 */
import { switchUserActions, uiActions } from '../../actions';
import { resetSwitchRegion } from '../../actions/switchRegion';
import setHighchartsDefaults from '../../modules/highcharts.global';
import stats from '../../modules/stats';
import transformTrends from '../../modules/transform/searchReport';
import { queryDate } from '../../system/date';
import { localeString, localeStringMoney } from '../../system/string';
import Currency from '../currency';
import { searchCampaignHeaders } from './constants';

const searchGroups = {
  Search: ['PRODUCT', 'CATEGORY', 'BRAND', 'KEYWORDS'],
};

const searchLang = {
  BRAND: 'Brands',
  CATEGORY: 'Categories',
  KEYWORDS: 'Keywords',
  PRODUCT: 'Products',
};

/**
 * @param {Object} campaigns
 */
const groupSearchCampaigns = campaigns =>
  searchGroups.Search.reduce(
    (result, group) => ({
      Search: [
        ...result.Search,
        ...Object.keys(campaigns)
          .filter(campaign => group === campaign.toUpperCase())
          .map(campaign => ({
            key: group,
            value: campaigns[campaign],
          })),
      ],
    }),
    { Search: [] },
  );

/**
 * This component lists the "Campaign Details" under the "Search" module on the
 * "Account Statistics" screen.
 * @param {Object} userToSwitch
 * @param {string} userToSwitch.username
 * @param {Object} account
 * @param {string} account.apiKey
 * @param {string} account.mainCurrency
 * @param {string} rangeAlias
 * @return {React.ReactNode}
 */
const SearchCampaignStats = ({ userToSwitch, account, rangeAlias }) => {
  const [campaigns, setCampaigns] = useState({});
  const range = useSelector(store => store.date.range);

  const editCampaign = () => {
    if (userToSwitch) {
      resetSwitchRegion();
      const processedUser = {
        ...userToSwitch,
        account,
      };
      switchUserActions.updateSwitchUser(processedUser);
      setHighchartsDefaults(processedUser);
      uiActions.isLoading();
      setTimeout(() => {
        uiActions.isLoaded();
        browserHistory.push('/search/view-all');
      }, 500);
    } else {
      browserHistory.push('/search/view-all');
    }
  };

  const tableHead = searchCampaignHeaders.map((header, i) => (
    <th key={header}>{header}</th>
  ));

  useEffect(() => {
    uiActions.isLoading();
    const boundaries = {};
    boundaries.start = queryDate(range[0]);
    boundaries.end = queryDate(range[1]);
    boundaries.datelabel = rangeAlias;
    boundaries.reportPageType = '';

    stats.getRangedSearchReports(
      boundaries,
      response => {
        uiActions.isLoaded();
        setCampaigns(groupSearchCampaigns(response));
        window.dispatchEvent(new Event('resize'));
      },
      userToSwitch ? { switchUser: userToSwitch.username } : {},
      account.apiKey,
    );
  }, []);

  return (
    <div className='account-details campaign-stats-table'>
      <table className='data-table'>
        <thead>
          <tr>{tableHead}</tr>
        </thead>
        <tbody>
          {campaigns &&
            Object.keys(campaigns)
              .filter(group => campaigns[group].length)
              .map(group => {
                const title = group;
                const campaignsAll = campaigns[group];

                return campaignsAll.map((campaign, index) => {
                  const transFormedStats = transformTrends(campaign, 0);
                  return (
                    <tr key={campaign.key}>
                      {index === 0 && (
                        <td rowSpan={campaignsAll.length}>{title}</td>
                      )}
                      <td className='domain'>
                        <a title={campaign.key} onClick={() => editCampaign()}>
                          {searchLang[campaign.key.toUpperCase()]}
                        </a>
                      </td>
                      <td>
                        {localeString(transFormedStats.widget.stats.view.count)}
                      </td>
                      <td>
                        {localeString(
                          transFormedStats.interaction.stats.all.count,
                        )}
                      </td>
                      <td className={classNames({ ctr: index === 0 })}>
                        {localeString(
                          transFormedStats.interaction.stats.conversion.ratio,
                        )}{' '}
                        %
                      </td>
                      <td>
                        {localeString(transFormedStats.basket.stats.all.count)}
                      </td>
                      <td>
                        {localeString(
                          transFormedStats.basket.stats.conversion.ratio,
                        )}{' '}
                        %
                      </td>
                      <td>
                        <Currency
                          currency={localeStringMoney(
                            transFormedStats.revenue.stats.all.count,
                          )}
                          accountCurrency={account.mainCurrency}
                        />
                      </td>
                      <td>
                        {localeString(
                          transFormedStats.revenue.stats.purchases.count,
                        )}
                      </td>
                      <td>
                        {localeString(
                          transFormedStats.revenue.stats.items.count,
                        )}
                      </td>
                      <td>
                        {localeString(
                          transFormedStats.revenue.stats.conversion.ratio,
                        )}{' '}
                        %
                      </td>
                      <td className='cont-ratio'>
                        {transFormedStats.revenue.stats.contribution.ratio} %
                      </td>
                      <td className='diff-ratio'>
                        {transFormedStats.revenue.stats.diff.ratio}
                      </td>
                    </tr>
                  );
                });
              })}
        </tbody>
      </table>
    </div>
  );
};

export default SearchCampaignStats;
