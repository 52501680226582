import find from 'lodash/find';
import merge from 'lodash/merge';
import clone from 'lodash/clone';
import { browserHistory } from 'react-router';

import { campaignInitialData } from '../constants/datamaps/push';
import { addCampaign, getCampaign, editCampaign } from '../modules/push/ajax';
import { t } from '../system/ui';
import {
  initAbandonValues,
  setFetching,
  setFetched,
  updateStatus,
} from './abandon';
import { wizardActions, uiActions } from '.';
import { initWizardValues } from './wizard';
import { getAccount } from '../modules/auth/user';

export const FETCH_ABANDON = 'database/FETCH_ABANDON';
export const ABANDON_FETCHED = 'database/ABANDON_FETCHED';
export const ABANDON_FETCHING = 'database/ABANDON_FETCHING';

const typeToLowerCase = inclusions => {
  const includes = [];
  inclusions.forEach(item => {
    const values = {};

    Object.keys(item).forEach(key => {
      if (key !== 'type') {
        values[key] = {
          value: item[key],
        };
      }
    });

    includes.push({
      id: Math.random(),
      type: item.type.toLowerCase(),
      values,
    });
  });

  return includes;
};

const typeToUpperCase = includes => {
  const inclusions = [];
  includes.forEach(inclusion => {
    const params = {};

    Object.keys(inclusion.values).forEach(value => {
      params[value] = inclusion.values[value].value;
    });

    params.type = inclusion.type.toUpperCase();

    inclusions.push(params);
  });

  return inclusions;
};

const getItemByMainLanguage = (obj, mainLanguage) => {
  if (!obj) {
    return null;
  }

  // eslint-disable-next-line no-prototype-builtins
  if (obj.hasOwnProperty(mainLanguage)) {
    return obj[mainLanguage];
  }

  return '';
};

export const fetchAbandon = () => {
  return dispatch => {
    dispatch(setFetching());
    const convertBetween = str => {
      const arr = str.split('-');

      if (arr.length > 0) {
        return {
          first: parseInt(arr[0]),
          second: parseInt(arr[1]),
        };
      }

      return {
        first: 18,
        second: 23,
      };
    };
    getCampaign('ABANDONED_CART').then(data => {
      dispatch(setFetched());

      const initial = find(
        campaignInitialData,
        item => item.type === 'ABANDONED_CART',
      );
      // eslint-disable-next-line no-param-reassign
      data = merge({}, initial, data);

      const wizard = {
        includes: typeToLowerCase(data.inclusions),
        excludes: typeToLowerCase(data.exclusions),
        wizardForm: {
          language: data.params.language,
          currency: data.params.currency,
          lifeTime: data.validity,
          devices: data.devices,
          name: data.name,
          startDate: data.startDate,
          betweenFirst: convertBetween(data.params.interval).first,
          betweenSecond: convertBetween(data.params.interval).second,
        },
      };

      const abandon = {
        status: data.status,
        hidePrice: data.hidePrice,
        hideInformation: data.hideInformation,
        FIRST_REMINDER: {
          active: data.steps.FIRST_REMINDER.active,
          actions: data.steps.FIRST_REMINDER.actions,
          icon: data.steps.FIRST_REMINDER.icon,
          image: data.steps.FIRST_REMINDER.image,
          delay: data.steps.FIRST_REMINDER.delay.delay,
          delayUnit: data.steps.FIRST_REMINDER.delay.type,
          additionalTitles: data.steps.FIRST_REMINDER.additionalTitles,
          additionalIcons: data.steps.FIRST_REMINDER.additionalIcons,
          additionalImages: data.steps.FIRST_REMINDER.additionalImages,
        },
        SECOND_REMINDER: {
          active: data.steps.SECOND_REMINDER.active,
          actions: data.steps.SECOND_REMINDER.actions,
          icon: data.steps.SECOND_REMINDER.icon,
          image: data.steps.SECOND_REMINDER.image,
          delay: data.steps.SECOND_REMINDER.delay.delay,
          delayUnit: data.steps.SECOND_REMINDER.delay.type,
          additionalTitles: data.steps.SECOND_REMINDER.additionalTitles,
          additionalIcons: data.steps.SECOND_REMINDER.additionalIcons,
          additionalImages: data.steps.SECOND_REMINDER.additionalImages,
        },
        ALTERNATIVE_PRODUCTS: {
          active: data.steps.ALTERNATIVE_PRODUCTS.active,
          actions: data.steps.ALTERNATIVE_PRODUCTS.actions,
          icon: data.steps.ALTERNATIVE_PRODUCTS.icon,
          image: data.steps.ALTERNATIVE_PRODUCTS.image,
          delay: data.steps.ALTERNATIVE_PRODUCTS.delay.delay,
          delayUnit: data.steps.ALTERNATIVE_PRODUCTS.delay.type,
          additionalTitles: data.steps.ALTERNATIVE_PRODUCTS.additionalTitles,
          additionalIcons: data.steps.ALTERNATIVE_PRODUCTS.additionalIcons,
          additionalImages: data.steps.ALTERNATIVE_PRODUCTS.additionalImages,
        },
        COUPON: {
          active: data.steps.COUPON.active,
          actions: data.steps.COUPON.actions,
          icon: data.steps.COUPON.icon,
          image: data.steps.COUPON.image,
          delay: data.steps.COUPON.delay.delay,
          delayUnit: data.steps.COUPON.delay.type,
          additionalTitles: data.steps.COUPON.additionalTitles,
          additionalIcons: data.steps.COUPON.additionalIcons,
          additionalImages: data.steps.COUPON.additionalImages,
          additionalDescriptions:
            data.steps.COUPON.additionalDescriptions || {},
          target: data.steps.COUPON.redirectUrl,
          basketAmount: data.steps.COUPON.basketAmount,
          couponCode: data.steps.COUPON.couponCode,
          expireDate: data.steps.COUPON.expireDate,
        },
        WIDGET: {
          template: data.steps.COUPON.flowWidget.template,
          html: data.steps.COUPON.flowWidget.html,
          title: data.steps.COUPON.flowWidget.title,
          description: data.steps.COUPON.flowWidget.description,
          backgroundColor: data.steps.COUPON.flowWidget.backgroundColor,
          foregroundColor: data.steps.COUPON.flowWidget.foregroundColor,
          copyButtonText: data.steps.COUPON.flowWidget.copyButtonText,
          copiedInfoText: data.steps.COUPON.flowWidget.copiedInfoText,
          validationText: data.steps.COUPON.flowWidget.validationText,
          position: data.steps.COUPON.flowWidget.position,
        },
      };

      dispatch(initWizardValues(wizard));
      dispatch(initAbandonValues(abandon));
    });
  };
};

const save = (willMapped, status) => {
  return dispatch => {
    addCampaign(willMapped).then(response => {
      dispatch(updateStatus(status));

      let note = () => {
        return t('Push notification campaign is saved to drafts successfully.');
      };
      if (response.status === 'ACTIVE') {
        note = () => {
          return t('Push notification campaign is activated successfully.');
        };
      }
      uiActions.showNotification({
        content: note,
      });

      wizardActions.saveReset();
      if (window.location.pathname.indexOf('/ext/push') >= 0) {
        browserHistory.push(`/ext/push/edit/${response.type}`);
      } else {
        browserHistory.push(`/push/edit/${response.type}`);
      }
    });
  };
};

const edit = (willMapped, status) => {
  return dispatch => {
    editCampaign(willMapped).then(response => {
      dispatch(updateStatus(status));
      const data = response.response;

      let note = () => {
        return t('Push notification campaign is saved to drafts successfully.');
      };
      if (data.status === 'ACTIVE') {
        note = () => {
          return t('Push notification campaign is activated successfully.');
        };
      }
      uiActions.showNotification({
        content: note,
      });

      wizardActions.saveReset();
    });
  };
};

const cleanedAdditionalImagesFromNoImage = additionalImages => {
  const clearedAdditionalImagesFromNoImage = {};
  Object.keys(additionalImages).forEach(lang => {
    if (additionalImages[lang] !== '{{no:image}}') {
      clearedAdditionalImagesFromNoImage[lang] = additionalImages[lang];
    } else {
      clearedAdditionalImagesFromNoImage[lang] = '';
    }
  });
  return clearedAdditionalImagesFromNoImage;
};

const cleanedImageFromNoImage = image => {
  if (image !== '{{no:image}}') {
    return image;
  }
  return '';
};

export const saveAbandon = (saveAs, isNew, mainLanguage) => {
  return (dispatch, getState) => {
    let willMapped = clone(
      find(campaignInitialData, item => item.type === 'ABANDONED_CART'),
    );

    const account = getAccount();
    const state = getState();
    const { wizard } = state;
    const { abandon } = state;
    const status = saveAs === 'test' ? 'TEST' : 'ACTIVE';

    willMapped = {
      ...willMapped,
      status,
      inclusions: typeToUpperCase(wizard.includes),
      exclusions: typeToUpperCase(wizard.excludes),
      name: wizard.wizardForm.name,
      params: {
        language: wizard.wizardForm.language,
        currency: wizard.wizardForm.currency,
        interval: `${wizard.wizardForm.betweenFirst}-${wizard.wizardForm.betweenSecond}`,
      },
      hidePrice: abandon.hidePrice,
      hideInformation: abandon.hideInformation,
      validity: wizard.wizardForm.lifeTime,
      devices: wizard.wizardForm.devices,
      startDate: wizard.wizardForm.startDate,
      steps: {
        FIRST_REMINDER: {
          name: 'PUSH_FLOW_STEP',
          active: abandon.FIRST_REMINDER.active,
          actions: abandon.FIRST_REMINDER.actions,
          icon:
            abandon.FIRST_REMINDER?.additionalIcons[account.mainLanguage] ||
            abandon.FIRST_REMINDER.icon,
          image: cleanedImageFromNoImage(
            abandon.FIRST_REMINDER?.additionalImages[account.mainLanguage] ||
              abandon.FIRST_REMINDER.image,
          ),
          additionalIcons: abandon.FIRST_REMINDER.additionalIcons,
          additionalImages: cleanedAdditionalImagesFromNoImage(
            abandon.FIRST_REMINDER.additionalImages,
          ),
          delay: {
            delay: abandon.FIRST_REMINDER.delay,
            type: abandon.FIRST_REMINDER.delayUnit,
          },
          additionalTitles: abandon.FIRST_REMINDER.additionalTitles,
          title: getItemByMainLanguage(
            abandon.FIRST_REMINDER.additionalTitles,
            mainLanguage,
          ),
        },
        SECOND_REMINDER: {
          name: 'PUSH_FLOW_STEP',
          active: abandon.SECOND_REMINDER.active,
          actions: abandon.SECOND_REMINDER.actions,

          icon:
            abandon.SECOND_REMINDER?.additionalIcons[account.mainLanguage] ||
            abandon.SECOND_REMINDER.icon,
          image: cleanedImageFromNoImage(
            abandon.SECOND_REMINDER?.additionalImages[account.mainLanguage] ||
              abandon.SECOND_REMINDER.image,
          ),
          additionalIcons: abandon.SECOND_REMINDER.additionalIcons,
          additionalImages: cleanedAdditionalImagesFromNoImage(
            abandon.SECOND_REMINDER.additionalImages,
          ),

          delay: {
            delay: abandon.SECOND_REMINDER.delay,
            type: abandon.SECOND_REMINDER.delayUnit,
          },
          additionalTitles: abandon.SECOND_REMINDER.additionalTitles,
          title: getItemByMainLanguage(
            abandon.SECOND_REMINDER.additionalTitles,
            mainLanguage,
          ),
        },
        ALTERNATIVE_PRODUCTS: {
          name: 'PUSH_FLOW_STEP',
          active: abandon.ALTERNATIVE_PRODUCTS.active,
          actions: abandon.ALTERNATIVE_PRODUCTS.actions,

          icon:
            abandon.ALTERNATIVE_PRODUCTS?.additionalIcons[
              account.mainLanguage
            ] || abandon.ALTERNATIVE_PRODUCTS.icon,
          image: cleanedImageFromNoImage(
            abandon.ALTERNATIVE_PRODUCTS?.additionalImages[
              account.mainLanguage
            ] || abandon.ALTERNATIVE_PRODUCTS.image,
          ),
          additionalIcons: abandon.ALTERNATIVE_PRODUCTS.additionalIcons,
          additionalImages: cleanedAdditionalImagesFromNoImage(
            abandon.ALTERNATIVE_PRODUCTS.additionalImages,
          ),

          delay: {
            delay: abandon.ALTERNATIVE_PRODUCTS.delay,
            type: abandon.ALTERNATIVE_PRODUCTS.delayUnit,
          },
          additionalTitles: abandon.ALTERNATIVE_PRODUCTS.additionalTitles,
          title: getItemByMainLanguage(
            abandon.ALTERNATIVE_PRODUCTS.additionalTitles,
            mainLanguage,
          ),
        },
        COUPON: {
          name: 'PUSH_FLOW_COUPON_STEP',
          active: abandon.COUPON.active,
          actions: abandon.COUPON.actions,

          icon:
            abandon.COUPON?.additionalIcons[account.mainLanguage] ||
            abandon.COUPON.icon,
          image: cleanedImageFromNoImage(
            abandon.COUPON?.additionalImages[account.mainLanguage] ||
              abandon.COUPON.image,
          ),
          additionalIcons: abandon.COUPON.additionalIcons,
          additionalImages: cleanedAdditionalImagesFromNoImage(
            abandon.COUPON.additionalImages,
          ),

          delay: {
            delay: abandon.COUPON.delay,
            type: abandon.COUPON.delayUnit,
          },
          basketAmount: abandon.COUPON.basketAmount,
          couponCode: abandon.COUPON.couponCode,
          expireDate: abandon.COUPON.expireDate,
          additionalTitles: abandon.COUPON.additionalTitles,
          additionalDescriptions: abandon.COUPON.additionalDescriptions,
          title: getItemByMainLanguage(
            abandon.COUPON.additionalTitles,
            mainLanguage,
          ),
          description: getItemByMainLanguage(
            abandon.COUPON.additionalDescriptions,
            mainLanguage,
          ),
          redirectUrl: abandon.COUPON.target,
          flowWidget: {
            ...abandon.WIDGET,
          },
        },
      },
    };

    if (isNew) {
      dispatch(save(willMapped, status));
    } else {
      dispatch(edit(willMapped, status));
    }
  };
};
