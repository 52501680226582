import React, { useMemo } from 'react';
import './TableHead.scss';
import { SFYCheckBox } from '../module-exports';

const SFYThead = ({
  promotionHead,
  promotionHeadConstant,
  bundleHead,
  bundleHeadConstant,
  isSelectAllEnabled,
  isSelectAllChecked,
  selectAllAction,
}) => {
  const headRenderer = useMemo(() => {
    if (promotionHead) {
      return (
        <>
          <div className='sfyThead'>
            {promotionHeadConstant.map(item => (
              <div key={item.key} className={`sfyThead-${item.size}`}>
                {item.headerTitle}
              </div>
            ))}
            {isSelectAllEnabled && (
              <SFYCheckBox
                id='sfyThead-checkbox'
                name='sfyThead-checkbox'
                label=''
                className='sfyThead-checkbox'
                checked={isSelectAllChecked}
                onChange={selectAllAction}
              />
            )}
          </div>
        </>
      );
    }

    if (bundleHead) {
      return (
        <>
          <div className='sfyThead'>
            {bundleHeadConstant.map(item => (
              <div key={item.key} className={`sfyThead-${item.size}`}>
                {item.headerTitle}
              </div>
            ))}
            {isSelectAllEnabled && (
              <SFYCheckBox
                id='sfyThead-checkbox'
                name='sfyThead-checkbox'
                label=''
                className='sfyThead-checkbox'
                checked={isSelectAllChecked}
                onChange={selectAllAction}
              />
            )}
          </div>
        </>
      );
    }

    return null;
  }, [isSelectAllEnabled, isSelectAllChecked, selectAllAction]);

  return <>{headRenderer}</>;
};

export default SFYThead;
