/**
 * Created by mehmetyurtar on 10/08/2017.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// TODO - Causing defaultProps to be undefined, need to fix(react-highcharts version may cause).
import ReactHighcharts from 'react-highcharts';

import { localeString } from '../../system/string';
import { t } from '../../system/ui';
import { getAccountCurrency, isSwitchAccount } from '../../modules/auth/user';
import { currencyIcons } from '../../constants/datamaps/currencies';

class ReportPurchaseChart extends Component {
  constructor(props) {
    super(props);

    this.getConfig = this.getConfig.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.chartRenderCount) {
      return this.props.chartRenderCount !== nextProps.chartRenderCount;
    } else {
      return true;
    }
  }

  getCurrencyString(num) {
    let currency = getAccountCurrency(),
      symbol = null;

    if (currencyIcons[currency.code]) {
      symbol =
        '<i class="icon-' +
        currencyIcons[currency.code] +
        '" role="presentation"></i>';
    } else {
      symbol = currency.symbolBefore
        ? currency.symbol + ' '
        : ' ' + currency.symbol;
    }

    let myvar = '<span class="user-currency">';
    if (currency.symbolBefore) {
      myvar += symbol;
    }
    myvar +=
      '<span class="user-currency-amount">' +
      localeString(num, {
        currency: currency.code,
        style: 'currency',
        decimal: currency.decimalSeparator,
        thousand: currency.thousandSeparator,
        minimumFractionDigits: currency.decimals,
      }) +
      '</span>';
    if (!currency.symbolBefore) {
      myvar += symbol;
    }
    myvar += '</span>';
    return myvar;
  }

  getConfig() {
    const symbolBefore = isSwitchAccount()
      ? this.props.switchedUser.account.mainCurrency.symbolBefore
      : this.props.user.account.mainCurrency.symbolBefore;
    const symbol = isSwitchAccount()
      ? this.props.switchedUser.account.mainCurrency.symbol
      : this.props.user.account.mainCurrency.symbol;
    let getCurrencyString = this.getCurrencyString;
    let config;
    config = {
      title: {
        text: '',
        margin: 0,
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 34560000,
        labels: {
          align: 'left',
        },
      },
      yAxis: [
        {
          title: {
            text: t('Purchases and Items'),
          },
          min: 0,
          showFirstLabel: false,
          allowDecimals: false,
          labels: {},
        },
        {
          gridLineWidth: 0,
          title: {
            text: t('Amount'),
          },
          min: 0,
          opposite: true,
          showFirstLabel: false,
          allowDecimals: false,
          labels: {
            style: {
              color: '#428BCA',
            },
            formatter: function() {
              if (symbolBefore) {
                return symbol + ' ' + localeString(this.value);
              } else {
                return localeString(this.value) + ' ' + symbol;
              }
            },
          },
        },
      ],
      options: {
        chart: {
          type: 'column',
          showAxes: true,
          zoomType: 'x',
          marginLeft: 75,
          spacingTop: 30,
          spacingBottom: 30,
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          borderRadius: 10,
          borderWidth: 3,
          backgroundColor: 'rgba(225, 225, 255, 0.9)',
        },
        exporting: {
          buttons: {
            contextButton: {
              y: -30,
              x: 0,
            },
          },
          chartOptions: {
            title: {
              text: 'Campaign Revenue Trends',
            },
          },
        },
      },
      loading: true,
      series: [],
    };

    let availableFields = [
      {
        name: 'Purchases',
        label: t('Purchases'),
        isVisible: true,
        type: 'line',
        yAxis: 0,
        zIndex: 2,
      },
      {
        name: 'Purchased Items',
        label: t('Purchased Items'),
        isVisible: true,
        type: 'line',
        yAxis: 0,
        zIndex: 2,
      },
      {
        name: 'Purchase Amount',
        label: t('Purchase Amount'),
        isVisible: true,
        type: 'column',
        yAxis: 1,
        zIndex: 1,
        countType: 'currency',
      },
    ];

    availableFields.forEach(field => {
      config.series.push({
        id: field.name,
        name: field.label,
        data: [],
        visible: field.isVisible,
        fillOpacity: 0.3,
        marker: {
          enabled: true,
          fillColor: '#FFFFFF',
          lineColor: null,
          lineWidth: 2,
        },
        type: field.type,
        yAxis: field.yAxis,
        zIndex: field.zIndex,
        countType: field.countType,
      });
    });

    this.props.stats.forEach(stat => {
      config.series.forEach(serie => {
        if (stat.x === serie.id) {
          serie.data.push({
            x: stat.y,
            y: stat.z,
          });
        }
      });
    });

    config.series.forEach(serie => {
      serie.data.sort(function(a, b) {
        var keyA = new Date(a.x),
          keyB = new Date(b.x);
        // Compare the 2 dates
        if (keyA < keyB) {
          return -1;
        }
        if (keyA > keyB) {
          return 1;
        }
        return 0;
      });
    });

    config.xAxis.tickInterval = '';
    let timeFrame = {};
    if (this.props.customFrame) {
      timeFrame.mode = this.props.customFrame;
    } else {
      timeFrame.mode = 'monthly';
      if (this.props.range[1] && this.props.range[0]) {
        let timeDiff = this.props.range[1] - this.props.range[0];
        if (timeDiff < 24 * 60 * 60 * 1000) {
          timeFrame.mode = 'no_aggregate';
        } else if (timeDiff < 90 * 24 * 60 * 60 * 1000) {
          timeFrame.mode = 'daily';
        }
      }
    }
    switch (timeFrame.mode) {
      case 'monthly':
        config.xAxis.minTickInterval = 28 * 24 * 3600 * 1000; // 1 month
        config.xAxis.tickInterval = 30 * 24 * 3600 * 1000; // 1 month
        break;
      case 'daily':
        config.xAxis.tickInterval = 3 * 24 * 3600 * 1000; // three days
        break;
      case 'hourly':
        config.xAxis.tickInterval = 6 * 3600 * 1000; // 6 hours
        break;
      case 'no_aggregate':
        config.xAxis.tickInterval = 3600 * 1000; // one hour
        break;
      default:
        config.xAxis.tickInterval = 3 * 24 * 3600 * 1000;
    }
    config.credits = { enabled: false };
    let totals = {};
    config.series.forEach(serie => {
      totals[serie.name] = 0;
      serie.data.forEach(data => {
        totals[serie.name] += data.y;
      });
    });
    config.legend = {
      useHTML: true,
      labelFormatter: function() {
        if (this.options.countType !== 'currency') {
          return (
            '<div style="padding-top: 20px;"><div class="widget-stats"> <div class="widget-stat stat-widget"><h4 class="widget-stat-title">' +
            t(this.name) +
            '</h4><p class="widget-stat-count" style="color:' +
            this.color +
            ';text-align:center;">' +
            localeString(totals[this.name]) +
            '</p><p style="font-weight:normal;color:#bdbdbd">' +
            t('Total') +
            '</p></div></div></div>'
          );
        } else {
          return (
            '<div style="padding-top: 20px;"><div class="widget-stats"> <div class="widget-stat stat-widget"><h4 class="widget-stat-title">' +
            t(this.name) +
            '</h4><p class="widget-stat-count" style="color:' +
            this.color +
            ';text-align:center;">' +
            getCurrencyString(totals[this.name]) +
            '</p><p style="font-weight:normal;color:#bdbdbd">' +
            t('Total') +
            '</p></div></div></div>'
          );
        }
      },
    };
    return config;
  }

  render() {
    return (
      <div className='report-chart'>
        <ReactHighcharts config={this.getConfig()} />
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(ReportPurchaseChart);
