import React from 'react';
import Icon from '../icon';
import { currencyIcons } from '../../constants/datamaps/currencies';
import { getAccountCurrency } from '../../modules/auth/user';

const currencyIcon = () => {
  const currency = getAccountCurrency();

  if (!currency) {
    return null;
  }

  if (currencyIcons[currency.code]) {
    return (
      <Icon className='currency-icon' name={currencyIcons[currency.code]} />
    );
  }

  return <span className='currency-icon'>{currency.symbol}</span>;
};

export default currencyIcon;
