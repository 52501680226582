import React from 'react';
import classes from './SFYTab.module.scss';

// for id declarations could be used uuid()
// statement below has to be placed to parent component
// const [tab, setTab] = useState([
//   {
//     id: 452,
//     active: active,
//     componentName: 'z List',
//     children: <>z List</>,
//   },
//   {
//     id: 452,
//     active: active,
//     componentName: 'z List',
//     children: <>z List</>,
//   },
// ]);

/**
 * @name StaticTab
 * @description SFYTab is a component that renders dynamic tab
 * @param {Array} tabs - array of objects
 * @param {function} tabsSetter - function that sets the state of tabs
 * @param {object} location - location object
 * @param {boolean} alignCenter - aligns tabs to center
 * @param tabsSetter
 * @returns {JSX.Element}
 * @constructor
 */

const StaticTab = ({ tabs = [], tabsSetter, alignCenter }) => {
  const handleTabClick = id => {
    const newTab = tabs.map(t => {
      if (t.id === id) {
        return { ...t, active: true };
      }
      return { ...t, active: false };
    });
    tabsSetter(newTab);
  };

  return (
    <>
      <div
        className={
          alignCenter
            ? classes['sfy-tab-container--align-center']
            : classes['sfy-tab-container']
        }
      >
        {tabs.map(({ id, componentName, active }) => (
          <div
            key={id}
            className={`${classes['sfy-tab']} ${
              active ? classes['--active'] : classes['sfy-tab']
            }`}
            onClick={() => {
              handleTabClick(id);
            }}
          >
            <span key={id}>{componentName}</span>
          </div>
        ))}
      </div>
      {tabs.map(({ id, active, children }) => {
        if (active) {
          return <div key={id}>{children}</div>;
        }
        return null;
      })}
    </>
  );
};

export default StaticTab;
