import React, { useEffect } from 'react';
import {
  NavigationController,
  PaginationController,
  SearchController,
} from './module-exports';
import './pagination.scss';
import { t } from '../../system/ui';

/**
 * @name SFYPagination component
 * @description pagination component responsible from pagination and path to any page
 * @param {record} location - location object
 * @param {string} linkToRoute - router path to create page
 * @param {function} action - action to be performed on click
 * @param {Function} stateSetter - function to set state of searchQuery to Pagination Context
 * @param {string} state - searchQuery state
 * @param {function} paginationSetter - function to set state of pagination Context
 * @param {object} pagination - pagination state
 * @param {boolean} paginationController - flag to show/hide pagination
 * @param {boolean} searchController - flag to show/hide search
 * @param {boolean} navigationController - flag to show/hide navigation
 * @param {string} analyticsType - Changes payload size string
 * @param {string} text - text to show in navigation
 * @param {object} navButtonIconOptions - icon options of navigation button
 * @returns {JSX.Element}
 */

const SFYPagination = ({
  location,
  linkToRoute,
  action = null,
  stateSetter,
  state,
  paginationSetter,
  pagination,
  searchController = true,
  navigationController = true,
  paginationController = true,
  analyticsType = 'group',
  text,
  navButtonIconOptions = {},
}) => {
  useEffect(() => {
    // Clean up the subscription when the component unmounts.
    return () => {
      stateSetter('');
      paginationSetter({
        pageNumber: 1,
        payloadSize: 20,
        totalRecords: 0,
      });
    };
  }, [location.pathname]);

  return (
    <div
      className={`sfy-pagination trendify sidebar-is-hidden ${
        searchController ? 'f-between' : 'f-end'
      }`}
    >
      {searchController && (
        <SearchController stateSetter={stateSetter} state={state} />
      )}
      <div className='separator-holder'>
        {navigationController && (
          <NavigationController
            linkToRoute={linkToRoute}
            action={action}
            text={t(text)}
            isExternalButton
            {...navButtonIconOptions}
          />
        )}
        {paginationController && (
          <PaginationController
            totalRecords={pagination.totalRecords}
            currentPage={pagination.pageNumber}
            payloadSize={pagination.payloadSize}
            paginationSetter={paginationSetter}
            pageNumberOptions={[20, 50, 100]}
            analyticsType={analyticsType}
          />
        )}
      </div>
    </div>
  );
};

export default SFYPagination;
