import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const BackgroundIcon = ({ width, height, className }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 610 607'
      fill='none'
    >
      <path d='M305.011 606.381C263.939 606.381 223.971 598.345 186.209 582.484C149.859 567.212 117.258 545.4 89.3191 517.649C85.0364 513.395 85.0364 506.514 89.3191 502.26C93.6019 498.006 100.53 498.006 104.813 502.26C130.747 528.019 160.999 548.264 194.739 562.439C229.772 577.157 266.869 584.628 304.999 584.628C343.128 584.628 380.226 577.169 415.259 562.439C448.999 548.264 479.251 528.019 505.185 502.26C531.118 476.5 551.5 446.452 565.772 412.939C580.589 378.141 588.111 341.293 588.111 303.42C588.111 265.547 580.601 228.699 565.772 193.902C551.5 160.389 531.118 130.34 505.185 104.581C479.251 78.8216 448.999 58.5771 415.259 44.4012C409.683 42.0562 407.085 35.6694 409.434 30.1428C411.795 24.6044 418.225 22.0238 423.789 24.357C460.139 39.6287 492.728 61.4405 520.667 89.1913C548.605 116.942 570.565 149.312 585.94 185.418C601.908 222.914 609.999 262.613 609.999 303.42C609.999 344.216 601.908 383.915 585.94 421.423C570.565 457.528 548.605 489.899 520.667 517.649C492.728 545.4 460.139 567.212 423.789 582.484C386.063 598.345 346.094 606.381 305.011 606.381Z' />
      <path d='M10.9501 314.297C4.89965 314.297 0 309.43 0 303.42C0 262.625 8.09095 222.926 24.0593 185.418C39.4345 149.312 61.394 116.942 89.3327 89.1915C117.271 61.4407 149.861 39.6289 186.211 24.3571C191.786 22.0122 198.216 24.6046 200.565 30.143C202.926 35.6813 200.316 42.0681 194.74 44.4013C161 58.5772 130.748 78.8217 104.815 104.581C78.8809 130.34 58.4992 160.389 44.2274 193.902C29.4098 228.7 21.8883 265.547 21.8883 303.42C21.912 309.43 17.0124 314.297 10.9501 314.297Z' />
      <path d='M535.521 217.493C531.25 217.493 527.192 214.995 525.425 210.847C513.359 182.53 496.134 157.125 474.21 135.348C452.286 113.572 426.72 96.4618 398.2 84.4777C368.588 72.034 337.245 65.7297 305.011 65.7297C272.778 65.7297 241.423 72.034 211.823 84.4777C183.315 96.4618 157.737 113.572 135.813 135.348C131.531 139.602 124.602 139.602 120.319 135.348C116.037 131.094 116.037 124.213 120.319 119.959C144.248 96.1908 172.163 77.5135 203.281 64.4335C235.598 50.8586 269.824 43.9651 305.011 43.9651C340.199 43.9651 374.413 50.8468 406.742 64.4335C437.872 77.5135 465.775 96.1908 489.703 119.959C513.632 143.727 532.436 171.454 545.605 202.363C547.965 207.901 545.355 214.288 539.78 216.621C538.392 217.222 536.944 217.493 535.521 217.493Z' />
      <path d='M305.012 562.852C269.836 562.852 235.61 555.97 203.282 542.384C172.152 529.304 144.237 510.626 120.32 486.859C96.4027 463.102 77.5871 435.375 64.4304 404.455C50.7636 372.356 43.8234 338.36 43.8234 303.409C43.8234 268.458 50.7517 234.474 64.4304 202.363C66.7912 196.825 73.2213 194.244 78.7853 196.577C84.3612 198.922 86.9593 205.309 84.6103 210.836C72.0824 240.248 65.7354 271.381 65.7354 303.397C65.7354 335.414 72.0824 366.558 84.6103 395.959C96.6756 424.275 113.901 449.681 135.825 471.457C157.749 493.234 183.315 510.344 211.835 522.328C241.447 534.771 272.79 541.076 305.023 541.076C337.257 541.076 368.612 534.771 398.212 522.328C426.72 510.344 452.298 493.234 474.222 471.457C496.146 449.681 513.371 424.287 525.437 395.959C527.798 390.42 534.228 387.84 539.792 390.173C545.368 392.518 547.966 398.904 545.617 404.431C532.448 435.352 513.644 463.067 489.716 486.835C465.787 510.603 437.872 529.28 406.754 542.36C374.425 555.97 340.199 562.852 305.012 562.852Z' />
      <path d='M114.888 394.144C110.617 394.144 106.56 391.645 104.792 387.498C93.4151 360.784 87.6494 332.503 87.6494 303.409C87.6494 274.314 93.4151 246.033 104.792 219.32C115.742 193.596 131.402 170.511 151.321 150.738C171.24 130.953 194.469 115.41 220.367 104.522C247.262 93.2214 275.734 87.4945 305.026 87.4945C311.076 87.4945 315.976 92.3612 315.976 98.3709C315.976 104.381 311.076 109.247 305.026 109.247C278.688 109.247 253.087 114.397 228.909 124.554C205.621 134.335 184.729 148.322 166.815 166.116C148.901 183.909 134.831 204.661 124.972 227.792C114.746 251.807 109.561 277.237 109.561 303.397C109.561 329.557 114.746 354.986 124.972 379.001C127.333 384.54 124.723 390.927 119.147 393.26C117.759 393.873 116.312 394.144 114.888 394.144Z' />
      <path d='M305.012 519.323C275.732 519.323 247.248 513.596 220.353 502.295C194.455 491.419 171.214 475.864 151.307 456.079C147.025 451.826 147.025 444.944 151.307 440.69C155.59 436.436 162.518 436.436 166.801 440.69C184.715 458.483 205.607 472.459 228.895 482.251C253.073 492.409 278.675 497.558 305.012 497.558C311.062 497.558 315.962 502.425 315.962 508.435C315.962 514.444 311.062 519.323 305.012 519.323Z' />
      <path d='M385.413 503.155C381.142 503.155 377.085 500.657 375.317 496.509C372.956 490.971 375.566 484.584 381.142 482.251C404.43 472.47 425.322 458.483 443.236 440.69C461.15 422.896 475.22 402.145 485.079 379.013C495.305 354.998 500.489 329.569 500.489 303.409C500.489 277.249 495.305 251.819 485.079 227.804C475.232 204.672 461.15 183.921 443.236 166.128C425.322 148.334 404.43 134.359 381.142 124.566C375.566 122.221 372.968 115.835 375.317 110.308C377.678 104.77 384.108 102.189 389.672 104.522C415.57 115.399 438.811 130.953 458.718 150.738C478.637 170.523 494.285 193.596 505.247 219.32C516.624 246.033 522.39 274.315 522.39 303.409C522.39 332.491 516.624 360.784 505.247 387.498C494.297 413.222 478.637 436.306 458.718 456.079C438.799 475.864 415.57 491.407 389.672 502.295C388.284 502.884 386.836 503.155 385.413 503.155Z' />
      <path d='M190.044 428.493C187.244 428.493 184.433 427.432 182.297 425.311C166.388 409.509 153.896 391.091 145.14 370.552C136.065 349.235 131.462 326.658 131.462 303.42C131.462 297.41 136.361 292.544 142.412 292.544C148.462 292.544 153.362 297.41 153.362 303.42C153.362 323.723 157.384 343.45 165.308 362.068C172.949 380.015 183.875 396.111 197.779 409.922C202.062 414.176 202.062 421.057 197.779 425.311C195.655 427.432 192.856 428.493 190.044 428.493Z' />
      <path d='M305.014 475.793C281.631 475.793 258.888 471.221 237.427 462.207C231.851 459.862 229.253 453.475 231.602 447.948C233.963 442.41 240.393 439.818 245.957 442.163C264.701 450.034 284.573 454.029 305.002 454.029C325.431 454.029 345.302 450.034 364.047 442.163C382.115 434.574 398.321 423.721 412.225 409.91C416.508 405.656 423.436 405.656 427.719 409.91C432.001 414.164 432.001 421.046 427.719 425.3C411.81 441.102 393.267 453.51 372.589 462.207C351.139 471.221 328.409 475.793 305.014 475.793Z' />
      <path d='M454.789 377.187C453.366 377.187 451.918 376.904 450.53 376.326C444.954 373.981 442.356 367.595 444.705 362.068C448.822 352.382 451.93 342.295 453.911 332.067C455.726 322.71 456.652 313.071 456.652 303.42C456.652 283.117 452.63 263.391 444.705 244.772C437.065 226.826 426.139 210.729 412.235 196.919C398.331 183.108 382.125 172.267 364.057 164.666C345.312 156.795 325.441 152.8 305.012 152.8C284.583 152.8 264.711 156.795 245.967 164.666C227.899 172.255 211.693 183.108 197.789 196.919C183.885 210.729 172.97 226.826 165.318 244.772C162.957 250.311 156.527 252.891 150.963 250.558C145.388 248.213 142.789 241.826 145.138 236.3C153.882 215.761 166.386 197.331 182.295 181.541C198.204 165.739 216.747 153.33 237.425 144.634C258.886 135.619 281.617 131.047 305.012 131.047C328.407 131.047 351.137 135.619 372.598 144.634C393.277 153.319 411.831 165.739 427.728 181.541C443.637 197.343 456.13 215.761 464.885 236.3C473.961 257.617 478.564 280.194 478.564 303.432C478.564 314.473 477.508 325.503 475.432 336.215C473.154 347.928 469.607 359.487 464.885 370.564C463.117 374.689 459.06 377.187 454.789 377.187Z' />
      <path d='M414.442 268.364C410.171 268.364 406.114 265.865 404.346 261.718C398.913 248.968 391.142 237.514 381.26 227.686C371.366 217.858 359.846 210.14 346.998 204.755C333.687 199.158 319.558 196.329 305.025 196.329C298.974 196.329 294.075 191.463 294.075 185.453C294.075 179.443 298.974 174.577 305.025 174.577C322.512 174.577 339.512 177.994 355.54 184.722C370.998 191.215 384.866 200.501 396.754 212.308C408.653 224.127 418.001 237.903 424.526 253.245C426.887 258.783 424.277 265.17 418.701 267.503C417.313 268.093 415.866 268.364 414.442 268.364Z' />
      <path d='M305.013 432.264C287.526 432.264 270.526 428.847 254.498 422.118C246.81 418.89 239.431 414.918 232.55 410.311C225.681 405.715 219.192 400.401 213.272 394.521C201.373 382.702 192.024 368.926 185.499 353.584C178.725 337.664 175.285 320.778 175.285 303.409C175.285 286.039 178.725 269.153 185.499 253.233C192.036 237.879 201.385 224.104 213.284 212.296C225.183 200.477 239.052 191.192 254.498 184.711C260.074 182.366 266.504 184.958 268.853 190.496C271.214 196.035 268.604 202.422 263.028 204.755C250.191 210.152 238.66 217.87 228.766 227.686C218.872 237.514 211.101 248.956 205.679 261.718C200.044 274.939 197.197 288.973 197.197 303.409C197.197 317.855 200.056 331.878 205.679 345.1C211.113 357.85 218.884 369.303 228.766 379.131C233.689 384.021 239.087 388.44 244.794 392.258C250.5 396.076 256.633 399.376 263.028 402.062C276.339 407.66 290.468 410.488 305.001 410.488C311.052 410.488 315.951 415.354 315.951 421.364C315.951 427.374 311.063 432.264 305.013 432.264Z' />
      <path d='M351.27 422.978C346.999 422.978 342.942 420.48 341.174 416.332C338.814 410.794 341.424 404.407 346.999 402.074C359.836 396.677 371.367 388.958 381.261 379.143C391.156 369.315 398.926 357.873 404.348 345.111C409.983 331.89 412.83 317.855 412.83 303.42C412.83 297.41 417.73 292.544 423.78 292.544C429.831 292.544 434.73 297.41 434.73 303.42C434.73 320.789 431.29 337.675 424.516 353.595C417.979 368.95 408.631 382.725 396.731 394.532C384.832 406.351 370.964 415.637 355.517 422.118C354.141 422.707 352.694 422.978 351.27 422.978Z' />
      <path d='M305.012 388.735C295.165 388.735 285.508 387.097 276.302 383.856C267.405 380.722 259.136 376.173 251.709 370.329C244.354 364.543 238.042 357.661 232.941 349.848C227.745 341.906 223.948 333.233 221.647 324.054C220.188 318.221 223.759 312.317 229.631 310.868C235.504 309.418 241.447 312.965 242.906 318.798C246.288 332.314 254.248 344.546 265.305 353.254C270.833 357.602 277.002 360.996 283.61 363.329C290.456 365.745 297.657 366.958 305 366.958C313.803 366.958 322.333 365.214 330.329 361.785C338.064 358.462 344.981 353.725 350.889 347.692C355.1 343.379 362.04 343.285 366.382 347.468C370.724 351.651 370.819 358.545 366.608 362.858C358.695 370.953 349.406 377.316 339.013 381.771C328.265 386.402 316.828 388.735 305.012 388.735Z' />
      <path d='M305.014 345.206C298.963 345.206 294.064 340.339 294.064 334.329C294.064 328.319 298.963 323.453 305.014 323.453C310.4 323.453 315.466 321.367 319.274 317.584C323.082 313.802 325.182 308.77 325.182 303.42C325.182 298.07 323.082 293.039 319.274 289.256C315.466 285.474 310.4 283.388 305.014 283.388C299.628 283.388 294.562 285.474 290.754 289.256C286.946 293.039 284.846 298.07 284.846 303.42C284.846 304.905 285.012 306.378 285.32 307.804C286.625 313.672 282.888 319.482 276.98 320.778C271.072 322.074 265.223 318.362 263.918 312.494C263.254 309.536 262.922 306.472 262.922 303.42C262.922 292.261 267.3 281.762 275.248 273.867C283.197 265.972 293.767 261.623 305.002 261.623C316.237 261.623 326.807 265.972 334.756 273.867C342.704 281.762 347.082 292.261 347.082 303.42C347.082 314.58 342.704 325.079 334.756 332.974C326.819 340.857 316.26 345.206 305.014 345.206Z' />
      <path d='M377.759 332.314C376.882 332.314 375.992 332.208 375.102 331.984C369.23 330.535 365.659 324.631 367.118 318.798C368.375 313.79 369.004 308.617 369.004 303.409C369.004 294.83 367.308 286.499 363.974 278.674C360.747 271.109 356.132 264.31 350.26 258.477C344.387 252.644 337.542 248.06 329.926 244.855C322.036 241.544 313.661 239.859 305.024 239.859C297.692 239.859 290.491 241.084 283.634 243.5C277.014 245.833 270.845 249.227 265.317 253.575C259.836 257.888 255.126 263.026 251.329 268.835L250.938 269.424C247.272 275.151 244.579 281.385 242.93 287.936C242.918 287.995 242.894 288.066 242.883 288.125L242.811 288.431L242.74 288.655C241.305 293.227 237.022 296.279 232.3 296.279C231.565 296.279 230.806 296.209 230.058 296.044C224.138 294.818 220.33 289.056 221.564 283.176C221.588 283.034 221.623 282.905 221.659 282.763C223.948 273.607 227.745 264.934 232.941 256.992L233.463 256.214C238.469 248.732 244.615 242.109 251.721 236.512C259.148 230.667 267.428 226.119 276.326 222.984C285.532 219.744 295.189 218.094 305.036 218.094C316.627 218.094 327.873 220.356 338.479 224.811C348.706 229.112 357.9 235.263 365.777 243.087C373.655 250.912 379.847 260.044 384.178 270.202C388.662 280.737 390.94 291.908 390.94 303.42C390.94 310.396 390.086 317.337 388.401 324.054C387.144 329.003 382.671 332.314 377.759 332.314Z' />
      <path d='M345.988 114.55C351.976 114.55 356.831 109.728 356.831 103.78C356.831 97.8313 351.976 93.0092 345.988 93.0092C339.999 93.0092 335.145 97.8313 335.145 103.78C335.145 109.728 339.999 114.55 345.988 114.55Z' />
      <path d='M132.279 429.955C138.267 429.955 143.122 425.133 143.122 419.184C143.122 413.236 138.267 408.414 132.279 408.414C126.29 408.414 121.435 413.236 121.435 419.184C121.435 425.133 126.29 429.955 132.279 429.955Z' />
      <path d='M547.064 377.906C553.053 377.906 557.908 373.084 557.908 367.135C557.908 361.187 553.053 356.365 547.064 356.365C541.076 356.365 536.221 361.187 536.221 367.135C536.221 373.084 541.076 377.906 547.064 377.906Z' />
      <path d='M13.654 349.295C19.6426 349.295 24.4973 344.473 24.4973 338.524C24.4973 332.576 19.6426 327.754 13.654 327.754C7.66538 327.754 2.81067 332.576 2.81067 338.524C2.81067 344.473 7.66538 349.295 13.654 349.295Z' />
      <path d='M100.367 178.442C106.355 178.442 111.21 173.62 111.21 167.671C111.21 161.723 106.355 156.901 100.367 156.901C94.378 156.901 89.5233 161.723 89.5233 167.671C89.5233 173.62 94.378 178.442 100.367 178.442Z' />
      <path d='M384.358 34.0199C390.346 34.0199 395.201 29.1979 395.201 23.2496C395.201 17.3012 390.346 12.4792 384.358 12.4792C378.369 12.4792 373.514 17.3012 373.514 23.2496C373.514 29.1979 378.369 34.0199 384.358 34.0199Z' />
      <path d='M554.941 314.297C548.89 314.297 543.991 309.43 543.991 303.42C543.991 284.649 541.784 265.983 537.418 247.954C536.007 242.109 539.625 236.241 545.509 234.827C551.394 233.425 557.302 237.019 558.725 242.863C563.483 262.554 565.903 282.928 565.903 303.42C565.903 309.43 560.991 314.297 554.941 314.297Z' />
      <path d='M305.011 606.381C263.939 606.381 223.971 598.345 186.209 582.484C149.859 567.212 117.258 545.4 89.3191 517.649C85.0364 513.395 85.0364 506.514 89.3191 502.26C93.6019 498.006 100.53 498.006 104.813 502.26C130.747 528.019 160.999 548.264 194.739 562.439C229.772 577.157 266.869 584.628 304.999 584.628C343.128 584.628 380.226 577.169 415.259 562.439C448.999 548.264 479.251 528.019 505.185 502.26C531.118 476.5 551.5 446.452 565.772 412.939C580.589 378.141 588.111 341.293 588.111 303.42C588.111 265.547 580.601 228.699 565.772 193.902C551.5 160.389 531.118 130.34 505.185 104.581C479.251 78.8216 448.999 58.5771 415.259 44.4012C409.683 42.0562 407.085 35.6694 409.434 30.1428C411.795 24.6044 418.225 22.0238 423.789 24.357C460.139 39.6287 492.728 61.4405 520.667 89.1913C548.605 116.942 570.565 149.312 585.94 185.418C601.908 222.914 609.999 262.613 609.999 303.42C609.999 344.216 601.908 383.915 585.94 421.423C570.565 457.528 548.605 489.899 520.667 517.649C492.728 545.4 460.139 567.212 423.789 582.484C386.063 598.345 346.094 606.381 305.011 606.381Z' />
      <path d='M226.488 32.0283C221.672 32.0283 217.259 28.8467 215.93 24.0153C214.34 18.2177 217.769 12.2316 223.606 10.6525C249.634 3.58227 276.648 0 303.922 0C319.285 0 334.696 1.14303 349.715 3.39373C355.695 4.28929 359.811 9.83945 358.91 15.7785C358.091 21.1754 353.417 25.0405 348.09 25.0405C347.544 25.0405 346.998 25.0052 346.441 24.9227C332.489 22.8252 318.182 21.7646 303.922 21.7646C278.605 21.7646 253.525 25.0877 229.383 31.6394C228.422 31.9105 227.449 32.0283 226.488 32.0283Z' />
      <path d='M68.2634 488.591C64.9298 488.591 61.6317 487.082 59.4844 484.219C44.8566 464.776 32.5659 443.494 22.9683 420.964C20.6075 415.425 23.2175 409.039 28.7933 406.705C34.3692 404.36 40.7993 406.953 43.1482 412.491C52.0578 433.407 63.4587 453.157 77.0306 471.198C80.649 476.017 79.6643 482.829 74.8121 486.434C72.8428 487.884 70.5412 488.591 68.2634 488.591Z' />
    </svg>
  );
};

BackgroundIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

BackgroundIcon.defaultProps = {
  width: '610px',
  height: '607px',
  className: '',
};

export default BackgroundIcon;
