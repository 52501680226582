import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { getInsight } from '../../modules/insights/ajax';
import { insightsQuery } from '../../modules/insights/data';
import { queryDate } from '../../system/date';
import { clone } from '../../system/object';
import RelatedProduct from './widgets/relatedProduct';

class RelatedProductsDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ui: 'isLoading',
    };
  }

  componentDidMount() {
    this.listProducts();
  }

  listProducts(query) {
    const _query = query || clone(insightsQuery);
    _query.startDate = queryDate(this.props.range[0]);
    _query.endDate = queryDate(this.props.range[1]);
    this.getInsightData(this.props.endpoint, _query);
  }

  getInsightData(endpoint, query, range) {
    this.setState({
      products: [],
      ui: 'isLoading',
    });
    getInsight(endpoint, query, response => {
      const insightProducts = response !== '' ? response : [];
      insightProducts.forEach(currentInsightItem => {
        const insightProduct = currentInsightItem;
        insightProduct.id = insightProduct.record.productId || '';
        insightProduct.productId = insightProduct.record.productId || '';
        insightProduct.inStock = insightProduct.record.inStock || false;
        insightProduct.productCategory = insightProduct.record.categories
          ? insightProduct.record.categories.join(',')
          : '';
        insightProduct.productBrand = insightProduct.record.brand || '';
        insightProduct.productName = insightProduct.record.name || '';
        insightProduct.productOldPrice = insightProduct.record.oldPrice || '';
        insightProduct.productPrice = insightProduct.record.price || '';
        insightProduct.productURL = insightProduct.record.url || '';
        insightProduct.productImage = insightProduct.record.image || '';
      });
      this.setState({ products: insightProducts });
      this.setState({
        ui: 'isLoaded',
      });
    });
  }

  render() {
    return (
      <div
        className='product-insights'
        style={{ minWidth: '1100px' }}
        ref='pageFilters'
      >
        <div
          className={classNames('product-insights-details', {
            'page-progress': this.props.ui === 'isLoading',
          })}
        >
          <table className='data-table'>
            <thead>
              <tr>
                <th colSpan='4'>Product</th>
                <th className='cell-group'>#Orders</th>
                <th colSpan='4' className='cell-group'>
                  Bought Together With
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.products
                .filter(product => product.related)
                .filter(
                  (_, index) => !this.props.limit || index < this.props.limit,
                )
                .map(product => (
                  <RelatedProduct
                    key={`insight-related-product-${product.id}`}
                    {...product}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  update: store.filters.update,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  reset: store.filters.reset,
});

export default connect(mapStatesToProps)(RelatedProductsDetail);
