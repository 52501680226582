import React, { useEffect, useState } from 'react';
import Icons from '../../../icons';
import { t } from '../../../../system/ui';
import { NumberField } from '../../../fields';
import Icon from '../../../icon';
import Tooltip from '../../../tooltip';

const PriceRange = ({
  subComponents,
  onInputChange,
  onFilterRemove,
  addSubComponents,
  removeSubComponents,
  values,
  filterParams,
  id,
  segmentType,
  type,
  productPage = false,
}) => {
  const [state, setState] = useState(subComponents || []);

  const setValues = () => {
    const newState = subComponents.map((subComponent, index) => {
      if (index === 0) {
        return {
          ...subComponent,
          values: {
            range:
              typeof subComponent.values.range !== 'undefined'
                ? subComponent.values.range
                : '',
            max:
              typeof subComponent.values.max !== 'undefined'
                ? subComponent.values.max
                : '',
            operation:
              typeof subComponent.values.operation !== 'undefined'
                ? subComponent.values.operation
                : 'FIXED',
          },
        };
      }
      return {
        ...subComponent,
        values: {
          range:
            typeof subComponent.values.range !== 'undefined'
              ? subComponent.values.range
              : '',
          max:
            typeof subComponent.values.max !== 'undefined'
              ? subComponent.values.max
              : '',
        },
      };
    });

    return setState(newState);
  };

  const onChange = (e, ind, subId) => {
    const { value, name } = e.target;

    const newState = state.map((subComponent, index) => {
      if (index === ind) {
        return {
          ...subComponent,
          values: {
            ...subComponent.values,
            [name]: value,
          },
        };
      }
      return subComponent;
    });

    setState(newState);

    onInputChange('priceRange', segmentType, newState[ind].values, subId);
  };

  useEffect(() => {
    setValues();
  }, [filterParams, values]);

  return (
    <div className='sfy-color'>
      <div className='sfy-color-sub'>
        <div className='sfy-color-main-item'>
          <div className='sfy-color-left'>
            <div className='sfy-color-icoText'>
              <span className='sfy-color-icon'>
                <Icon name='dollar' color='#547494' />
              </span>
              <Tooltip
                content='Price Range allows you to specify the maximum and minimum values of the products that will be recommended.'
                alignment='top'
                newTooltip
                isInline
              >
                <Icon name='info' />
              </Tooltip>
              <div className='sfy-color-titleSelectMain'>
                <div className='sfy-color-titleSelect'>
                  <span className='sfy-color-title'>{t('Price Range')}</span>
                </div>
              </div>

              {productPage ? (
                <label className='item item-field is-select'>
                  <select
                    name='operation'
                    className='one-whole criteria-field'
                    onChange={e => onChange(e, 0, id)}
                    value={state[0].values.operation}
                  >
                    <option value='FIXED'>{t('Fixed')}</option>
                    <option value='PERCENTAGE'>{t('Percentage')}</option>
                  </select>
                </label>
              ) : (
                <input
                  type='hidden'
                  className='one-whole criteria-field'
                  name='operation'
                  defaultValue='FIXED'
                />
              )}
              <NumberField
                type='number'
                name='range'
                label={
                  !state[0].values.operation ||
                  state[0].values.operation === 'FIXED'
                    ? 'min'
                    : '%'
                }
                value={state[0].values.range}
                validation={{ maxNumber: state[0].values.max }}
                className={
                  state[0].values.operation === 'FIXED'
                    ? 'item-field sfy-price'
                    : 'item-field sfy-price wizard-price-item-two'
                }
                inputClassName='one-whole criteria-field'
                onChange={e => onChange(e, 0, id)}
              />
              {(!state[0].values.operation ||
                state[0].values.operation === 'FIXED') && (
                <NumberField
                  type='number'
                  name='max'
                  label='max'
                  value={state[0].values.max}
                  validation={{ minNumber: state[0].values.range }}
                  className='item-field sfy-price'
                  inputClassName='one-whole criteria-field'
                  onChange={e => onChange(e, 0, id)}
                />
              )}
            </div>
          </div>
          <div className='sfy-color-button-criteria'>
            {subComponents.length < 2 && state[0].values.operation === 'FIXED' && (
              <a
                onClick={() => {
                  addSubComponents('priceRange', segmentType);
                }}
                className='sfy-color-actions'
              >
                <Icons name='plusCircle' width='32' height='32' />
              </a>
            )}
            <a
              onClick={() => onFilterRemove(id, segmentType, type)}
              className='sfy-color-actions'
            >
              <Icons name='crossCircle' />
            </a>
          </div>
        </div>
        {subComponents.map((subComponent, ind) => {
          if (ind > 0) {
            return (
              <div className='sfy-color-sub-items' key={subComponent.id}>
                <div className='sfy-color-left'>
                  <div className='sfy-color-icoText'>
                    <div className='sfy-aligner-price_range'>
                      <NumberField
                        type='number'
                        name='range'
                        label={
                          !state[0].values.operation ||
                          state[0].values.operation === 'FIXED'
                            ? 'min'
                            : '%'
                        }
                        value={state[ind]?.values.range || ''}
                        validation={{ maxNumber: state[ind]?.values.max }}
                        className={
                          state[0].values.operation === 'FIXED'
                            ? 'item-field sfy-price'
                            : 'item-field sfy-price wizard-price-item-two'
                        }
                        inputClassName='one-whole criteria-field'
                        onChange={e => onChange(e, ind, subComponent.id)}
                      />
                      {(!state[0].values.operation ||
                        state[0].values.operation === 'FIXED') && (
                        <NumberField
                          type='number'
                          name='max'
                          label='max'
                          value={state[ind]?.values.max || ''}
                          validation={{ minNumber: state[ind]?.values.range }}
                          className='item-field sfy-price'
                          inputClassName='one-whole sfy-price'
                          onChange={e => onChange(e, ind, subComponent.id)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className='sfy-color-button-criteria'>
                  {ind === subComponents.length - 1 &&
                    state[0].values.operation === 'FIXED' && (
                      <a
                        onClick={() => {
                          addSubComponents('priceRange', segmentType, id);
                        }}
                        className='sfy-color-actions'
                      >
                        <Icons name='plusCircle' width='32' height='32' />
                      </a>
                    )}
                  <a
                    onClick={() => {
                      removeSubComponents(
                        'priceRange',
                        segmentType,
                        subComponent.id,
                      );
                    }}
                    className='sfy-color-actions'
                  >
                    <Icons name='crossCircle' />
                  </a>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default PriceRange;
