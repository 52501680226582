import React, { useState, useEffect } from 'react';
import { RectangleRadioButtonGroup } from '../../../../components/sfy-components/module-exports';
import './productBundleModals.scss';

import { bundleTypes } from '../constants/constants';

/**
 * @name BundleTypeModal
 * @description Modal to select the type of bundle
 * @param {function} onChange
 * @return {JSX.Element}
 */
const BundleTypeModal = ({ onChange }) => {
  const [bundleType, setBundleType] = useState('');

  useEffect(() => {
    onChange(bundleType);
  }, [bundleType]);
  return (
    <div className='bundle-type-modal'>
      <RectangleRadioButtonGroup
        radioButtons={bundleTypes}
        selected={bundleType}
        onChange={setBundleType}
      />
    </div>
  );
};

export default BundleTypeModal;
