import React from 'react';
import PropTypes from 'prop-types';
import { calculateRatio } from '../../system/ratio';
import Currency from '../currency';
import { t } from '../../system/ui';

const BannerifyCompareConversion = ({
  firstColumnData,
  secondColumnData,
  device,
  getCompareIcon,
}) => {
  return (
    <div className='one-whole'>
      <ul className='one-half trendify-compare-stats'>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('View')}</h4>
            <p>{t('Number Of Views')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {firstColumnData.stats[`${device}Impression`] || '-'}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Click')}</h4>
            <p>{t('Number Of Clicks')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {firstColumnData.stats[`${device}Click`] || '-'}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Rate')}</h4>
            <p>{t('Conversion Rate (Click / View)')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {`${calculateRatio(
              firstColumnData.stats[`${device}Click`],
              firstColumnData.stats[`${device}Impression`],
            )}%`}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Product Views')}</h4>
            <p>{t('Number Of Product Views')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {firstColumnData.stats[`${device}ProdView`] || '-'}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Rate')}</h4>
            <p>{t('Conversion Rate (Product View / View)')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {`${calculateRatio(
              firstColumnData.stats[`${device}ProdView`],
              firstColumnData.stats[`${device}Impression`],
            )}%`}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Basket')}</h4>
            <p>{t('Number Of Add To Basket Operations')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {firstColumnData.stats[`${device}Basket`] || '-'}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Rate')}</h4>
            <p>{t('Conversion Rate (Add To Basket/Click)')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {`${calculateRatio(
              firstColumnData.stats[`${device}Basket`],
              firstColumnData.stats[`${device}Click`],
            )}%`}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Purchase')}</h4>
            <p>{t('Number Of Purchases')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {firstColumnData.stats[`${device}Purchase`] || '-'}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Rate')}</h4>
            <p>{t('Conversion Rate (Purchase/Click)')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {`${calculateRatio(
              firstColumnData.stats[`${device}Purchase`],
              firstColumnData.stats[`${device}Click`],
            )}%`}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Amount')}</h4>
            <p>{t('Amount of Purchases')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            <Currency
              currency={firstColumnData.stats[`${device}Amount`] || 0}
            />
          </div>
        </li>
      </ul>
      <ul className='one-half trendify-compare-stats'>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('View')}</h4>
            <p>{t('Number Of Views')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {secondColumnData.stats[`${device}Impression`] || '-'}
            {getCompareIcon().count(
              secondColumnData.stats[`${device}Impression`],
              firstColumnData.stats[`${device}Impression`],
            )}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Click')}</h4>
            <p>{t('Number Of Clicks')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {secondColumnData.stats[`${device}Click`] || '-'}
            {getCompareIcon().count(
              secondColumnData.stats[`${device}Click`],
              firstColumnData.stats[`${device}Click`],
            )}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Rate')}</h4>
            <p>{t('Conversion Rate (Click / View)')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {`${calculateRatio(
              secondColumnData.stats[`${device}Click`],
              secondColumnData.stats[`${device}Impression`],
            )}%`}
            {getCompareIcon().ratio(
              secondColumnData.stats[`${device}Click`],
              secondColumnData.stats[`${device}Impression`],
              firstColumnData.stats[`${device}Click`],
              firstColumnData.stats[`${device}Impression`],
            )}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Product Views')}</h4>
            <p>{t('Number Of Product Views')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {secondColumnData.stats[`${device}ProdView`] || '-'}
            {getCompareIcon().count(
              secondColumnData.stats[`${device}ProdView`],
              firstColumnData.stats[`${device}ProdView`],
            )}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Rate')}</h4>
            <p>{t('Conversion Rate (Product View / View)')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {`${calculateRatio(
              secondColumnData.stats[`${device}ProdView`],
              secondColumnData.stats[`${device}Impression`],
            )}%`}
            {getCompareIcon().ratio(
              secondColumnData.stats[`${device}ProdView`],
              secondColumnData.stats[`${device}Impression`],
              firstColumnData.stats[`${device}ProdView`],
              firstColumnData.stats[`${device}Impression`],
            )}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Basket')}</h4>
            <p>{t('Number Of Add To Basket Operations')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {secondColumnData.stats[`${device}Basket`] || '-'}
            {getCompareIcon().count(
              secondColumnData.stats[`${device}Basket`],
              firstColumnData.stats[`${device}Basket`],
            )}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Rate')}</h4>
            <p>{t('Conversion Rate (Add To Basket/Click)')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {`${calculateRatio(
              secondColumnData.stats[`${device}Basket`],
              secondColumnData.stats[`${device}Click`],
            )}%`}
            {getCompareIcon().ratio(
              secondColumnData.stats[`${device}Basket`],
              secondColumnData.stats[`${device}Click`],
              firstColumnData.stats[`${device}Basket`],
              firstColumnData.stats[`${device}Click`],
            )}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Purchase')}</h4>
            <p>{t('Number Of Purchases')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {secondColumnData.stats[`${device}Purchase`] || '-'}
            {getCompareIcon().count(
              secondColumnData.stats[`${device}Purchase`],
              firstColumnData.stats[`${device}Purchase`],
            )}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Rate')}</h4>
            <p>{t('Conversion Rate (Purchase/Click)')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {`${calculateRatio(
              secondColumnData.stats[`${device}Purchase`],
              secondColumnData.stats[`${device}Click`],
            )}%`}
            {getCompareIcon().ratio(
              secondColumnData.stats[`${device}Purchase`],
              secondColumnData.stats[`${device}Click`],
              firstColumnData.stats[`${device}Purchase`],
              firstColumnData.stats[`${device}Click`],
            )}
          </div>
        </li>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Amount')}</h4>
            <p>{t('Amount of Purchases')}</p>
          </div>
          <div className='compare-stat-container one-half'>
            {
              <Currency
                currency={secondColumnData.stats[`${device}Amount`] || 0}
              />
            }
            {getCompareIcon().currency(
              secondColumnData.stats[`${device}Amount`],
              firstColumnData.stats[`${device}Amount`],
            )}
          </div>
        </li>
      </ul>
    </div>
  );
};

BannerifyCompareConversion.propTypes = {
  firstColumnData: PropTypes.shape({
    stats: PropTypes.shape({}).isRequired,
  }).isRequired,
  secondColumnData: PropTypes.shape({
    stats: PropTypes.shape({}).isRequired,
  }).isRequired,
  device: PropTypes.string.isRequired,
  getCompareIcon: PropTypes.func.isRequired,
};

export default BannerifyCompareConversion;
