import React, { useContext } from 'react';
import { InputCardWrapper } from '../../../../../../components/gamification/module-exports';
import { ColorPicker, TextField } from '../../../../../../components/fields';
import {
  SFYChildWrapper,
  SFYImageInput,
} from '../../../../../../components/sfy-components/module-exports';
import { Context as GamificationContext } from '../../../store/gamificationProvider';
import { t } from '../../../../../../system/ui';

const StyleTab = () => {
  const {
    state: { activeScratchDetails },
    editActiveScratchDetails,
  } = useContext(GamificationContext);

  const editCampaign = (fieldKey, fieldValue) => {
    editActiveScratchDetails({ [fieldKey]: fieldValue });
  };

  const onChangePointerIcon = iconVal => {
    editCampaign('bgImage', iconVal);
  };

  return (
    <SFYChildWrapper type='flexHalfColGap2'>
      <InputCardWrapper
        type='withIcon'
        cardTitle='Style of Scratch'
        cardDescription='These options will act on buttons and other design elements.'
        iconType='scratchStyle'
      >
        <div className='CTAButton'>
          <div className='CTAButton__content'>
            <div className='CTAButton__colorPicker'>
              <span className='CTAButton__colorPicker__title'>Theme Color</span>
              <div className='CTAButton__colorPicker__body'>
                <div className='CTAButton__colorPicker__col1'>
                  <span className='ColorOfBase__color__title'>
                    Background Color
                  </span>
                  <ColorPicker
                    className='item-field large'
                    value={activeScratchDetails?.scratchBgColor || '#FFFFFF'}
                    onChange={color => editCampaign('scratchBgColor', color)}
                    disableTooltip
                  />
                </div>
                <div className='CTAButton__colorPicker__col2'>
                  <span className='ColorOfBase__color__title'>Text Color</span>
                  <ColorPicker
                    className='item-field large'
                    value={activeScratchDetails?.scratchTextColor}
                    onChange={color => editCampaign('scratchTextColor', color)}
                    disableTooltip
                  />
                </div>
              </div>
              <div className='CTAButton__textField'>
                <span className='CTAButton__title'>
                  Content of Result Button
                </span>
                <TextField
                  placeholder='Ex : Use Code Now!'
                  value={activeScratchDetails?.contentOfResultButton || ''}
                  onChange={e =>
                    editCampaign('contentOfResultButton', e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </InputCardWrapper>

      <InputCardWrapper
        type='withIcon'
        cardTitle='Popup Background'
        iconType='scratchBG'
      >
        <div className='SpinWheel'>
          <div className='SpinWheel__content'>
            <div className='CTAButton__colorPicker__body'>
              <div className='CTAButton__colorPicker__col1'>
                <span className='SpinWheel__title'>Background Color</span>
                <span className='ColorOfBase__color__title'>Select Color</span>
                <ColorPicker
                  className='item-field large'
                  value={activeScratchDetails?.bgBgColor || '#FFFFFF'}
                  onChange={color => editCampaign('bgBgColor', color)}
                  disableTooltip
                />
              </div>
              <div className='CTAButton__colorPicker__col2'>
                <span className='SpinWheel__title'>Text Color</span>
                <span className='ColorOfBase__color__title'>Select Color</span>
                <ColorPicker
                  className='item-field large'
                  value={activeScratchDetails?.bgTextColor || '#000000'}
                  onChange={color => editCampaign('bgTextColor', color)}
                  disableTooltip
                />
              </div>
            </div>
            <div className='SpinWheel__img'>
              <span className='SpinWheel__img__title'>
                Background Image Upload
              </span>
              <small>
                You can improve your scratch off by adding an image behind the
                scratch.
              </small>
              <SFYImageInput
                key='wof-pointer-image'
                iconName='wof-pointer-image'
                fetchedImage={activeScratchDetails?.bgImage || undefined}
                stateSetter={onChangePointerIcon}
                type='mobil'
                withoutCardSpace
                endpointType='engagement'
              />
            </div>
          </div>
        </div>
      </InputCardWrapper>
      <InputCardWrapper type='withIcon' cardTitle='Overlay'>
        <div className='Overlay'>
          <label className='CountSettings__control'>
            <input
              id='scratch-overlay'
              key='scratch-overlay'
              type='checkbox'
              checked={
                activeScratchDetails?.overlay === 'true' ||
                activeScratchDetails?.overlay === true
              }
              onChange={() =>
                editCampaign('overlay', !activeScratchDetails.overlay)
              }
            />
            <span className='overlay-text'>
              {t(
                'I want an overlay at the behind of scratch off pop-up on site.',
              )}
            </span>
          </label>
        </div>
      </InputCardWrapper>
    </SFYChildWrapper>
  );
};

export default StyleTab;
