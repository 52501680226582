import React from 'react';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import classes from './addOrEditComponents.module.scss';
import {
  SFYScheduleDropdown,
  SFYSchedulingTextField,
} from '../../../../components/sfy-components/module-exports';
import { convertToPositiveInt } from '../../../../components/ruleBasedSegmentation/utils/utils';
import { t } from '../../../../system/ui';

const frequency = [
  { value: 'DAILY', label: 'Daily' },
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'MONTHLY', label: 'Monthly' },
];

const Condition = ({ component, editCampaign, activeEmailDetails }) => {
  const editCapping = (fieldKey, fieldValue) => {
    let fixedValue = fieldValue;
    if (fieldKey !== 'frequency') {
      fixedValue = convertToPositiveInt(fieldValue, 1);
    }

    editCampaign('capping', {
      ...activeEmailDetails?.capping,
      [fieldKey]: `${fixedValue}`,
    });
  };

  const editParams = (fieldKey, fieldValue) => {
    let fixedValue = fieldValue;
    if (fieldKey !== 'frequency') {
      fixedValue = convertToPositiveInt(fieldValue, 1);
    }

    editCampaign('params', {
      ...activeEmailDetails.params,
      [fieldKey]: `${fixedValue}`,
    });
  };

  const dynamicEditFunction = type => {
    switch (type) {
      case 'capping':
        return editCapping;
      case 'params':
        return editParams;
      default:
        return editParams;
    }
  };
  t('This field is required');
  const renderCondition = conditionType => {
    const { title, componentDetails } = component;
    const firstFieldValue =
      activeEmailDetails[componentDetails[0].objectName][
        componentDetails[0].cardName
      ];
    let errorMessage = '';
    if (!firstFieldValue) {
      errorMessage = t('This field is required');
    }
    switch (conditionType) {
      case 'textField':
        return (
          <InputCardWrapper
            type='adaptiveBlock'
            cardTitle={title}
            labelFor='campaignTitle'
            labelClass='cardTitle'
            errorMessage={errorMessage}
          >
            <div>
              <SFYSchedulingTextField
                key={title}
                fieldValue={firstFieldValue}
                componentDetail={componentDetails[0]}
                editCampaign={dynamicEditFunction('params')}
                activeEmailDetails={activeEmailDetails}
                placeholderClass={
                  componentDetails[0].cardName === 'discountRatio' &&
                  'sfy-discount-ratio'
                }
                isRequired={false}
              />
            </div>
          </InputCardWrapper>
        );
      case 'text-textField':
        return (
          <InputCardWrapper
            type='adaptiveBlock'
            cardTitle={title}
            labelFor='campaignTitle'
            labelClass='cardTitle'
            errorMessage={errorMessage}
          >
            <div className={classes['conditional-card-wrapper']}>
              if user visit the site in the last &nbsp;
              <div className={classes['sfy-select-after']}>
                <input
                  id={title}
                  type='number'
                  min={0}
                  name='delayInDays'
                  value={firstFieldValue}
                  onChange={e => {
                    dynamicEditFunction('params')(
                      componentDetails[0].cardName,
                      e.target.value,
                    );
                  }}
                />
              </div>
              &nbsp; email will be sent
            </div>
          </InputCardWrapper>
        );
      case 'textField-dropdown': {
        const secondFieldValue =
          activeEmailDetails[componentDetails[1].objectName][
            componentDetails[1].cardName
          ];

        return (
          <InputCardWrapper
            type='adaptiveBlock'
            cardTitle={title}
            labelFor='campaignTitle'
            labelClass='cardTitle'
            errorMessage={errorMessage}
          >
            <div className={classes['conditional-card-wrapper']}>
              frequency cap is &nbsp;
              <SFYSchedulingTextField
                key={title}
                fieldValue={firstFieldValue}
                componentDetail={componentDetails[0]}
                editCampaign={dynamicEditFunction('capping')}
                activeEmailDetails={activeEmailDetails}
                textFieldClass='sfy-scheduling-periodic'
                isTooltipVisible={false}
                isRequired={false}
              />
              &nbsp; per user and email is sent &nbsp;
              <SFYScheduleDropdown
                key={title}
                fieldValue={secondFieldValue}
                componentDetail={componentDetails[1]}
                options={frequency}
                editCampaign={dynamicEditFunction('capping')}
                activeEmailDetails={activeEmailDetails}
                selectClass='sfy-scheduling-periodic'
                isTooltipVisible={false}
              />
            </div>
          </InputCardWrapper>
        );
      }
      default:
        return null;
    }
  };

  return renderCondition(component?.componentDetails[0]?.conditionType);
};

export default Condition;
