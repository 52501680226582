/**
 * @author Bilal Cinarli
 */

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import get from 'lodash/get';

import { config } from '../constants/config';

import { uiActions, userActions } from '../actions';
import { t } from '../system/ui';

import user, { isSuperUser } from '../modules/auth/user';
import { checkIntegrations } from '../modules/account/ajax';

import { redirectAuth } from '../modules/auth/require-auth';

import setHighchartsDefaults from '../modules/highcharts.global';

import Modal from '../components/modal';
import Dialog from '../components/dialog';
import Header from '../components/layout/header';
import Sidebar from '../components/layout/sidebar';
import Notification from '../components/notification';
import Breadcrumb from '../components/nav/breadcrumb';
import SidebarSecondary from '../components/layout/sidebar.secondary';

import Settings_Integration from '../views/settings.integration';

import ScrollUpButton from '../components/scrollUp';
import { ExternalAppUtility } from '../external';
import { trackingService } from '../trackingService';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: false,
      hasOverlayWindow: false,
      showProviderDialog: true,
    };
    this.closeProviderDialog = this.closeProviderDialog.bind(this);
  }

  initAnnounceKit() {
    window.announcekit = window.announcekit || {
      queue: [],
      on: function(n, x) {
        window.announcekit.queue.push([n, x]);
      },
      push: function(x) {
        window.announcekit.queue.push(x);
      },
    };
    window.announcekit.push({
      widget: 'https://announcekit.app/widget/4fqzQY',
      version: 2,
    });

    const $script = document.createElement('script');
    $script.src = 'https://cdn.announcekit.app/widget.js';
    $script.async = true;

    document.body.appendChild($script);
  }

  componentDidMount() {
    this.setMenu();
    // TODO switchedUser hafizada  tutan sistem
    const switchedUser = localStorage.getItem('_su');
    // TODO switchedUser hafizada tutan sistem
    if (switchedUser !== null)
      userActions.updateSwitchUser(JSON.parse(switchedUser));
    user
      .getUserPromise()
      .then(
        response => {
          const loggedUser = response.response;
          const language = loggedUser.language || 'en';
          if (user.isSuperUser(loggedUser)) {
            this.initAnnounceKit();
          }

          if (
            user.isMultiAccountUser(loggedUser) &&
            this.props.router.location.pathname.indexOf('/user/accounts') < 0 &&
            this.props.router.location.pathname.indexOf(
              '/multiUser/setPassword',
            ) < 0
          ) {
            //uiActions.redirect("/user/accounts");
          }
          userActions.loggedUser(loggedUser);
          const lastLogin = localStorage.getItem('lastLogin');
          let lastLoginToLog = moment(new Date(lastLogin));
          if (!lastLogin) {
            lastLoginToLog = moment(new Date());
          }
          const { username, displayName, account, privileges } = loggedUser;
          const isInternal = isSuperUser();
          trackingService.setUserId(username);
          trackingService.setUserProps({
            $email: username,
            userid: username,
            user_fullname: displayName,
            is_customer: !isInternal,
            account_id: account.accountId,
            account_domain: account.domain,
            account_apikey: account.apiKey,
            account_tier: account.tier,
            account_type: account.type,
            user_type: privileges,
            panel_version: account.panelVersion,
            account_package: get(account, 'accountPackage.name'),
            reco_module: get(account, 'features.recommendation.status'),
            search_module: get(account, 'features.search.status'),
            push_module: get(account, 'features.push.status'),
            email_module: get(account, 'features.email.status'),
            trendify_module: get(account, 'features.trendify.status'),
            bannerify_module: get(account, 'features.bannerify.status'),
            segmentation_module: get(account, 'features.segmentation.status'),
            engagement_module: get(account, 'features.engagement.status'),
            last_login_date: moment(lastLoginToLog).format('LLLL'),
            last_login_date_format: moment(lastLoginToLog).format(),
            owner_sales: get(account, 'ownership.salesManager', '-'),
            owner_onb_manager: get(account, 'ownership.onboardingManager', '-'),
            owner_onb_developer: get(
              account,
              'ownership.onboardingDeveloper',
              '-',
            ),
            owner_acc_manager: get(account, 'ownership.accountManager', '-'),
            owner_acc_developer: get(
              account,
              'ownership.accountDeveloper',
              '-',
            ),
          });
          moment.locale(language);
          uiActions.updateLanguage(language);
          localStorage.setItem('userLanguage', language);

          const externalSubPath = ExternalAppUtility.getLinkToBeRedirectedViaAvailableExternalPaths();
          if (externalSubPath) {
            uiActions.redirect(externalSubPath);
          }

          this.setState({
            logged: true,
          });

          localStorage.setItem(
            'restUrl',
            this.props.user.account.dataCenterUrl,
          );
          setHighchartsDefaults(loggedUser);
          checkIntegrations();
        },
        response => {
          redirectAuth();
        },
      )
      .catch(response => {});
  }

  setMenu() {
    let allMenu = {},
      menu = {};
    if (ExternalAppUtility.isExternalApp()) {
      Object.assign(
        allMenu,
        config.menus.primary,
        ExternalAppUtility.setSecondaryMenusForExternalApp(
          config.menus.secondary,
        ),
      );
    } else {
      Object.assign(allMenu, config.menus.primary, config.menus.secondary);
    }

    Object.keys(allMenu).forEach(item => {
      if (allMenu[item].hasOwnProperty('children')) {
        menu[item] = allMenu[item];
      }
    });

    uiActions.hasSubmenu(menu);
  }

  closeProviderDialog() {
    this.setState({ showProviderDialog: false });
  }

  render() {
    const menuParent = this.props.routes[1];

    const pageClass = classNames('page-wrap', this.props.pageClass, {
      'is-hidden': !this.state.logged,
      'has-overlay-window': this.props.showDialog || this.props.showModal,
      firefox: navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
    });
    const isExternal = ExternalAppUtility.isHeaderHidden();
    const isMSP = this.props.router.location.pathname.includes(
      'managed-services',
    );

    return isMSP ? (
      this.props.children
    ) : (
      <div id='wrapper' className={pageClass}>
        <Header />
        <div
          id='content'
          className={classNames('page-content', { external: isExternal })}
        >
          <Sidebar />
          {menuParent.hasOwnProperty('childRoutes') && (
            <SidebarSecondary {...this.props} parent={menuParent} />
          )}

          <div
            id='main'
            className={classNames('page-main', { external: isExternal })}
          >
            <Breadcrumb />
            {!user.isSuperUser() &&
            !ExternalAppUtility.isDengage() &&
            this.props.integrations.PAGE_VIEW !== 'COMPLETED' &&
            this.props.location.pathname === '/' ? (
              <Settings_Integration />
            ) : (
              this.props.children
            )}
          </div>
        </div>

        {this.props.router.location.pathname.indexOf('/ext/') === -1 &&
          this.state.showProviderDialog &&
          this.props.user.account.provider &&
          (this.props.user.account.provider === 'PRESTASHOP' ||
            this.props.user.account.provider === 'SHOPIFY' ||
            this.props.user.account.provider === 'MAGENTO') && (
            <div className='notification' style={{ background: '#547498' }}>
              <div className='notification-content'>
                <div className='notification-content-text'>
                  <p>
                    <i
                      className='icon-info'
                      style={{ fontSize: '1.75rem', marginRight: '10px' }}
                    />
                    {this.props.user.account.provider === 'SHOPIFY' && (
                      <span>{t('ShopifyWarning')}</span>
                    )}
                    {this.props.user.account.provider === 'MAGENTO' && (
                      <span>{t('MagentoWarning')}</span>
                    )}
                    {this.props.user.account.provider === 'PRESTASHOP' && (
                      <span>{t('PrestashopWarning')}</span>
                    )}
                  </p>
                </div>
                <a
                  className='notification-close notification-provider-close'
                  onClick={this.closeProviderDialog}
                >
                  X
                </a>
              </div>
            </div>
          )}

        {this.props.showDialog && <Dialog {...this.props.dialog} />}

        {this.props.showModal && <Modal {...this.props.modal} />}

        {this.props.showNotification && (
          <Notification {...this.props.notification} />
        )}

        <ScrollUpButton />
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  ui: store.ui.ui,
  pageClass: store.ui.pageClass,
  language: store.ui.language,
  user: store.user.user,
  integrations: store.user.integrations,
  dialog: store.dialog.dialog,
  showDialog: store.dialog.showDialog,
  modal: store.modal.modal,
  showModal: store.modal.showModal,
  notification: store.notification.notification,
  showNotification: store.notification.showNotification,
});

export default connect(MapStatesToProps)(App);
