import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { t } from 'system/ui';

function RulesetRightSideWizard({
  name,
  onNameChange,
  campaign,
  campaignOptions,
  onCampaignChange,
  save,
  router,
  isInstant,
  hasError,
}) {
  return (
    <div className="wizard-options email-wizard-option faceted-search-right-side-wizard rule-set-right-side">
      <div className="campaign-name-label">
        <span>{t('rule-name')}</span>
      </div>
      <div className="campaign-name-text">
        <input
          type="text"
          value={name}
          onChange={onNameChange}
        />
      </div>
      <span className='item-label'>{t('Campaign')}</span>
      <label htmlFor="-" className="item is-select campaign">
        <select name="search-result" id="rule-set-ordering-options"
                className="one-whole criteria-field" value={campaign || ''}
                onChange={onCampaignChange}
                disabled={isInstant}
        >
          <option value={''}>{isInstant ? 'Searchbox Recommendation' : `-${t('select-campaign')}-`}</option>
          {!isInstant
            ? campaignOptions?.map(campaignOption => <option value={campaignOption.instanceId}>{campaignOption.name}</option>)
            : null
          }
        </select>
      </label>
      <div className='action-buttons'>
        <a
          href={() => false}
          onClick={() =>
            router.push({
              pathname: isInstant ? '/search/searchbox' : '/search/faceted',
            })
          }
          className='button gotest-action one-whole'
        >
          {t('Discard')}
        </a>
        <a
          href={() => false}
          onClick={hasError ? null : save}
          className={`button ${
            hasError ? '' : 'tertiary-action'
          } one-whole golive-action`}
        >
          {t('Go Live')}
        </a>
      </div>
    </div>
  );
}

RulesetRightSideWizard.propTypes = {
  name: PropTypes.string.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onCampaignChange: PropTypes.func.isRequired,
  campaign: PropTypes.string.isRequired,
  campaignOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  save: PropTypes.func.isRequired,
  isInstant: PropTypes.bool.isRequired,
};

export default withRouter(RulesetRightSideWizard);
