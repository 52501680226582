import React from 'react';
import styles from './CampaignActionButton.module.scss';

/* 
all classnames it depends campaignActionButtonConstant
*/

const CampaignActionButton = ({
  campaignActionButtonConstant,
  isDisabled = false,
}) => {
  return (
    <>
      {campaignActionButtonConstant?.map(btn => (
        <div className={styles[btn.position]} key={btn.type}>
          <button
            type='button'
            className={styles[btn.type]}
            onClick={() => btn.action()}
            disabled={isDisabled}
          >
            {btn.btnLabel}
          </button>
        </div>
      ))}
    </>
  );
};

export default CampaignActionButton;
