/**
 * @fileOverview This file helps to show Base part of edit screen.
 * @deprecated
 * @requires react
 * @requires react-tag-autocomplete
 * @requires classNames
 */
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import ReactTag from 'react-tag-autocomplete';
import { getAccountTiers } from '../../../modules/account/ajax';
import { reformatDate } from '../../../system/date';
import { t } from '../../../system/ui';
import { Calendar, Select, TextArea, TextField } from '../../fields';
import { currencies, languages, timeZones, types } from '../constants';

/**
 * This component helps to show Base part of edit screen.
 * @param {Object} account
 * @param {string} account.domain
 * @param {string} account.type
 * @param {string} account.panelVersion
 * @param {string} account.tier
 * @param {string} account.contact
 * @param {string} account.createdBy
 * @param {string} account.description
 * @param {string} account.mainLanguage
 * @param {string} account.mainRegion
 * @param {Array} account.additionalLanguages
 * @param {Array} account.additionalCurrencyList
 * @param {Array} account.additionalRegions
 * @param {string} account.cartUrl
 * @param {string} account.mobileCartUrl
 * @param {string} account.timeZone
 * @param {string} account.allowedUrlDomain
 * @param {Array} account.allowedImageDomains
 * @param {Boolean} betting
 * @param {Object[]} additionalDomains
 * @param {Object[]} pageUrlQueryParamWhitelist
 * @param {moment|string|boolean} startDate
 * @param {moment|string|boolean} endDate
 * @param {Function} reducerStateChangeHandler
 * @returns {JSX.Element|null}
 */
const BaseDetail = ({
  account,
  betting,
  additionalDomains,
  pageUrlQueryParamWhitelist,
  startDate,
  endDate,
  reducerStateChangeHandler,
}) => {
  const privileges = useSelector(store => store.user.user.privileges);
  const startDateChange = datetime => {
    reducerStateChangeHandler('startDate', datetime);
  };

  const endDateChange = datetime => {
    reducerStateChangeHandler('endDate', datetime);
  };

  const handleDomainDelete = i => {
    const tempAdditionalDomains = [...additionalDomains];
    tempAdditionalDomains.splice(i, 1);

    reducerStateChangeHandler('additionalDomains', tempAdditionalDomains);
  };

  const handleDomainAddition = domain => {
    const tempAdditionalDomains = [...additionalDomains];
    const mappedAdditionalDomains = additionalDomains.map(item => item.name);

    if (mappedAdditionalDomains.includes(domain.name)) return;

    tempAdditionalDomains.push(domain);
    reducerStateChangeHandler('additionalDomains', tempAdditionalDomains);
  };

  const handlePageUrlQueryParamWhitelistDelete = i => {
    const tempPageUrlQueryParamWhitelist = [...pageUrlQueryParamWhitelist];
    tempPageUrlQueryParamWhitelist.splice(i, 1);

    reducerStateChangeHandler(
      'pageUrlQueryParamWhitelist',
      tempPageUrlQueryParamWhitelist,
    );
  };

  const handlePageUrlQueryParamWhitelistAddition = listItem => {
    const tempPageUrlQueryParamWhitelist = [...pageUrlQueryParamWhitelist];
    const mappedPageUrlQueryParamWhitelist = pageUrlQueryParamWhitelist.map(
      item => item.name,
    );

    if (mappedPageUrlQueryParamWhitelist.includes(listItem.name)) return;

    tempPageUrlQueryParamWhitelist.push(listItem);
    reducerStateChangeHandler(
      'pageUrlQueryParamWhitelist',
      tempPageUrlQueryParamWhitelist,
    );
  };

  const onChange = e => {
    const { value } = e.currentTarget;
    const { name } = e.currentTarget;

    reducerStateChangeHandler(name, value);
  };

  const tiers = getAccountTiers();
  const additionalRegions = account?.additionalRegions?.map(region => ({
    value: region,
    label: region,
  }));
  const allowedImageDomains = account?.allowedImageDomains?.map(image => ({
    value: image,
    label: image,
  }));

  return (
    <>
      <li className='row pull-push' style={{ zIndex: 2 }}>
        <TextField
          name='domain'
          defaultValue={account.domain}
          className='item-stacked four-columns account-setting--domain'
          label='Domain Name'
          onChange={onChange}
        />
        <Select
          name='type'
          className='item-stacked four-columns'
          label='Type'
          value={account.type}
          options={types}
          disabled={
            process.env.REACT_APP_ENV === 'production' &&
            !privileges.includes('MASTERUSER')
          }
        />

        <Select
          name='tier'
          className='item-stacked four-columns'
          label='Tier'
          value={account.tier}
          options={tiers}
        />
      </li>

      <li style={{ zIndex: 1 }}>
        <div
          className={classNames('item item-stacked one-whole', {
            'disabled-tag': false,
          })}
        >
          <span className='item-label'>{t('Additional Domains')}</span>
          <ReactTag
            tags={additionalDomains}
            handleDelete={handleDomainDelete}
            handleAddition={handleDomainAddition}
            allowNew
            placeholder={t('Add Domains')}
          />
        </div>
      </li>

      <li style={{ zIndex: 1 }}>
        <div
          className={classNames('item item-stacked one-whole', {
            'disabled-tag': false,
          })}
        >
          <span className='item-label'>{t('Query Parameters White List')}</span>
          <ReactTag
            tags={pageUrlQueryParamWhitelist}
            handleDelete={handlePageUrlQueryParamWhitelistDelete}
            handleAddition={handlePageUrlQueryParamWhitelistAddition}
            allowNew
            placeholder={t('Type Here')}
          />
        </div>
      </li>

      <li className='row pull-push'>
        <TextField
          name='person'
          className='item-stacked six-columns'
          label='Contact Person'
          defaultValue={account.contact.person}
          onChange={onChange}
        />

        <TextField
          name='email'
          type='email'
          className='item-stacked six-columns'
          label='Contact Email'
          defaultValue={account.contact.email}
          onChange={onChange}
        />
      </li>

      <li className='row pull-push'>
        <TextField
          name='phone'
          className='item-stacked six-columns'
          label='Contact Phone'
          defaultValue={account.contact.phone}
          onChange={onChange}
        />

        <label className='item item-stacked six-columns'>
          <span className='item-label'>Created By</span>
          <div
            className='text-field'
            style={{ backgroundColor: '#f9f9f9', height: '35px' }}
          >
            {account.createdBy}
          </div>
        </label>
      </li>

      <li className='row pull-push'>
        <Calendar
          className='item-stacked six-columns'
          label='Free Trial Start'
          icon
          value={reformatDate(startDate)}
          onChange={startDateChange}
        />

        <Calendar
          className='item-stacked six-columns'
          label='Free Trial End'
          icon
          value={reformatDate(endDate)}
          onChange={endDateChange}
        />
      </li>

      <li>
        <TextArea
          className='item-stacked one-whole'
          name='description'
          label='Description'
          defaultValue={account.description}
          onChange={onChange}
        />
      </li>

      <li className='row pull-push'>
        <Select
          name='mainLanguage'
          className='item-stacked four-columns'
          label='Main Language'
          value={account.mainLanguage}
          options={languages}
          searchable
        />
      </li>

      <li className='row pull-push'>
        <TextField
          name='mainRegion'
          className='item-stacked four-columns'
          label='Main Region'
          defaultValue={account.mainRegion}
          onChange={onChange}
        />
      </li>

      <li className='row pull-push'>
        <Select
          name='additionalLanguages'
          className='item-stacked eleven-columns'
          label='Additional Languages'
          value={account.additionalLanguages}
          options={languages}
          multi
          searchable
        />
      </li>

      <li className='row pull-push'>
        <Select
          name='additionalCurrencyList'
          className='item-stacked eleven-columns'
          label='Additional Currency List'
          value={account.additionalCurrencyList}
          options={currencies}
          multi
          searchable
        />
      </li>

      <li className='row pull-push'>
        <Select
          name='additionalRegions'
          className='item-stacked eleven-columns'
          label='Additional Regions'
          value={account.additionalRegions}
          options={additionalRegions}
          multi
          searchable
          creatable
        />
      </li>

      <li className='row pull-push'>
        <TextField
          name='cartUrl'
          className='item-stacked twelve-columns'
          label='Cart URL'
          defaultValue={account.cartUrl}
          onChange={onChange}
        />
      </li>

      <li className='row pull-push'>
        <TextField
          name='mobileCartUrl'
          className='item-stacked twelve-columns'
          label='Mobile Cart URL'
          defaultValue={account.mobileCartUrl}
          onChange={onChange}
        />
      </li>

      <li className='row pull-push'>
        <Select
          name='timeZone'
          className='item-stacked twelve-columns'
          label='Account Timezone'
          value={account.timeZone}
          options={timeZones}
          searchable
        />
      </li>

      <li className='row pull-push'>
        <TextField
          name='allowedUrlDomain'
          className='item-stacked eleven-columns'
          label='Allowed Url Domain'
          defaultValue={account.allowedUrlDomain}
          onChange={onChange}
        />
      </li>

      <li className='row pull-push'>
        <Select
          name='allowedImageDomains'
          className='item-stacked eleven-columns'
          label='Allowed Image Domains'
          value={account.allowedImageDomains}
          options={allowedImageDomains}
          multi
          searchable
          creatable
        />
      </li>
    </>
  );
};

export default BaseDetail;
