import React from 'react';
import { useSelector } from 'react-redux';
import Icons from '../../../../components/icons';
import style from './EmailCampaignPreview.module.scss';

const MessageIcon = () => {
  return (
    <div className={style['icon-wrapper']}>
      <Icons className={style['message-icon']} name='emailMessageCanvas' />
    </div>
  );
};

const MessagePreviewWithContent = ({ email }) => {
  return (
    <div className={style['preview-email-message']}>
      <div className={style['preview-email-message-body']}>
        <div className={style['preview-avatar']}>
          <Icons name='Person' />
        </div>
        <div className={style['preview-info']}>
          <div className={style['preview-info-header']}>
            <span className={style['preview-company-name']}>
              {email.companyName}
            </span>
            <span className={style['preview-email-address']}>
              {email.address}
            </span>
          </div>
          <span className={style['preview-email-subject']}>
            {email.subject}
          </span>
          <span className={style['preview-email-preheader']}>
            {email.preheader}
          </span>
        </div>
        <div className={style['preview-icons']}>
          <Icons name='StarNoFill' />
          <Icons name='Reply' color='black' />
          <Icons name='VerticalEllipsis' />
        </div>
      </div>
    </div>
  );
};

const EmailCampaignPreview = ({
  email,
  campaignStatus,
  saveHandler,
  openTestModal,
  mode,
}) => {
  const userRole = useSelector(
    ({ switchedUser, user }) =>
      switchedUser?.switchedUser?.userRole || user?.user?.userRole,
  );
  const testEmailButtonText =
    campaignStatus === 'ACTIVE'
      ? 'Send Test Email'
      : 'Save as Draft & Send Test Email';

  const testEmailButtonHandler = () => {
    if (campaignStatus === 'ACTIVE') {
      openTestModal();
    } else {
      saveHandler('TEST', true, true);
    }
  };

  return (
    <div className={style['preview-container']}>
      <div className={style['inbox-preview']}>
        <MessageIcon />
        <MessagePreviewWithContent email={email} />
        <MessageIcon />
        <MessageIcon />
        {userRole !== 'READ_ONLY' && mode !== 'preview' && (
          <>
            <div className={style['btn-row']}>
              <button
                className={style['send-test-email-btn']}
                type='button'
                onClick={() => testEmailButtonHandler()}
              >
                {testEmailButtonText}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmailCampaignPreview;
