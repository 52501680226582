/**
 * Created by mehmetyurtar on 21/06/2017.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _Select from 'react-select';
import { campaignActions, modalActions } from '../../actions';
import { t } from '../../system/ui';

const pageOptions = [
  {
    value: 'home',
    label: t('Home Page'),
  },
  {
    value: 'category',
    label: t('Category Page'),
  },
  {
    value: 'product',
    label: t('Product Page'),
  },
  {
    value: 'basket',
    label: t('Basket Page'),
  },
  {
    value: 'search',
    label: t('Search Page'),
  },
  {
    value: 'notfound',
    label: t('404 Page'),
  },
  {
    value: 'custom',
    label: t('Custom Page'),
  },
  {
    value: 'triggerbased',
    label: t('Trigger Based Page'),
  },
];

class DuplicateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPage: 'home',
    };

    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onChange(selectedValue) {
    this.setState({
      selectedPage: selectedValue.value,
    });
  }

  onClick(e) {
    e.preventDefault();
    let { titleMap } = this.props.duplicateOptions;
    if (titleMap) {
      titleMap = JSON.parse(titleMap);
    }

    campaignActions.duplicateCampaign(true, [
      this.props.duplicateOptions.name,
      this.props.duplicateOptions.title,
      this.props.duplicateOptions.selector,
      this.props.duplicateOptions.insertType,
      this.props.duplicateOptions.devices,
      this.props.duplicateOptions.shuffleItems,
      titleMap,
      this.props.duplicateOptions.widgetTemplateId,
    ]);
    this.props.router.push({
      pathname: `/widget/add/${this.state.selectedPage}/fullWidth`,
    });

    modalActions.closeModal();
  }

  render() {
    return (
      <div style={{ marginTop: '15px' }}>
        <label className='item is-select one-whole'>
          <span className='item-label'>Type</span>
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <_Select
            searchable={false}
            clearable={false}
            name='pagetype'
            options={pageOptions}
            value={this.state.selectedPage}
            onChange={this.onChange}
          />
        </label>
        {/* eslint-disable-next-line react/button-has-type */}
        <button
          className='tertiary-action duplicate-button'
          onClick={this.onClick}
          style={{ marginTop: '50px' }}
        >
          Duplicate Campaign
        </button>
      </div>
    );
  }
}

DuplicateModal.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default DuplicateModal;
