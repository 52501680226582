/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";

import { devices, mediums } from "../constants/datamaps/campaigns";

import { t } from "../system/ui";
import { setTitle } from "../system/document";

import { uiActions } from "../actions";

import { getCampaigns } from "../modules/campaigns/ajax";
import { getCampaignPage } from "../modules/campaigns/data";

import WidgetOptions from "../components/widget/options";
import WidgetActions from "../components/widget/external.actions";
import Icon from "../components/icon";

class ExtDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaigns: []
    };

    this.changeStatus = this.changeStatus.bind(this);
  }

  componentDidMount() {
    setTitle(t("Campaigns"));

    uiActions.resetPage();
    uiActions.isLoading();

    getCampaigns(response => {
      if (response.length > 0) {
        this.setState({
          campaigns: response
        });
      }

      uiActions.isLoaded();
    });
  }

  changeStatus(instanceId, newStatus) {
    let campaigns = this.state.campaigns;
    let campaignIndex;
    this.state.campaigns.forEach((campaign, ind) => {
      if (campaign.instanceId === instanceId) {
        campaignIndex = ind;
      }
    });
    campaigns[campaignIndex].testMode = newStatus === "test";
    this.setState({
      campaigns: campaigns
    });
  }

  render() {
    return (
      <div>
        <h2 className="page-title">{t("Campaigns")}</h2>

        <div className="campaign-list">
          {this.state.campaigns.map(campaign => {
            return (
              <div key={campaign.instanceId} className="single-campaign">
                <p className="single-campaign-name">{campaign.scenarioName}</p>
                <div className="single-campaign-details">
                  <span className="single-campaign-page">
                    {getCampaignPage(campaign)}
                  </span>
                  {mediums[campaign.device].map(device => {
                    if (device === "PCTABLET") {
                      return;
                    }

                    return (
                      <span key={device} className="widget-meta-mobile">
                        <Icon name={device.toLowerCase()} />
                        {t(devices[device])}
                      </span>
                    );
                  })}

                  <WidgetActions
                    {...campaign}
                    router={this.props.router}
                    changeStatus={this.changeStatus}
                  />
                  <WidgetOptions
                    instance={campaign.instanceId}
                    testMode={campaign.testMode}
                    limitations={campaign.limitations}
                    key={Math.random()}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  ui: store.ui.ui
});

export default connect(mapStateToProps)(ExtDashboard);
