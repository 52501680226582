/* eslint-disable no-unused-expressions */
/* eslint-disable func-names */
import transformTrends from './trends';

const capitalize = text => {
  return text.replace(/(?:^|\s)\S/g, function(a) {
    return a.toUpperCase();
  });
};

const unique = data => {
  const newData = [];
  data?.forEach(function(o) {
    if (!this[o.x]) {
      this[o.x] = { x: o.x, y: 0 };
      newData.push(this[o.x]);
    }
    this[o.x].y += o.y;
  }, Object.create(null));

  return newData;
};

const transformCampaignReport = (
  reportData,
  selectedDevice = false,
  moduleName = '',
  totalAmount,
  pcIncludesTablet = true,
) => {
  let finalData;
  const isPageViewAndImpressionActive = [
    'recommendation',
    'recommendations',
  ].includes(moduleName);

  if (selectedDevice) {
    finalData = reportData?.filter(data => {
      if (data.x === 'purchase:amount') {
        return true;
      }

      if (selectedDevice === 'all') {
        return true;
      }

      if (selectedDevice === 'PC') {
        return pcIncludesTablet
          ? data.z === 'tablet' || data.z === 'PC'
          : data.z === 'PC';
      }

      return data.z === selectedDevice;
    });

    return transformTrends(
      unique(finalData),
      totalAmount,
      '',
      '',
      '',
      capitalize(moduleName),
      false,
      false,
      false,
      isPageViewAndImpressionActive,
    );
  }

  if (moduleName !== '') {
    finalData = reportData;
    return transformTrends(
      finalData,
      totalAmount,
      '',
      '',
      '',
      capitalize(moduleName),
      false,
      false,
      false,
      isPageViewAndImpressionActive,
    );
  }

  finalData = reportData;
  return transformTrends(
    finalData,
    totalAmount,
    '',
    '',
    '',
    capitalize(moduleName),
    false,
    false,
    false,
    isPageViewAndImpressionActive,
  );
};

export default transformCampaignReport;
