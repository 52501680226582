/**
 * Created by mehmetyurtar on 10/19/17.
 */

import React from "react";
import { connect } from "react-redux";

import { t } from "../../system/ui";

import Icon from "../icon";
import Icons from "../icons";

import { TextField } from "../fields";

class ExcludesStock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      field: "",
      operation: "",
      value: ""
    };

    this.onChange = this.onChange.bind(this);
    this.setValues = this.setValues.bind(this);
  }

  componentDidMount() {
    this.setValues();
  }

  setValues() {
    let newState = {
      field:
        typeof this.props.values.field !== "undefined"
          ? this.props.values.field.value
          : "stockCount",
      operation:
        typeof this.props.values.operation !== "undefined"
          ? this.props.values.operation.value
          : "GREATER",
      value:
        typeof this.props.values.value !== "undefined"
          ? this.props.values.value.value
          : ""
    };
    this.props.subComponents.forEach(subComponent => {
      (newState["operation" + subComponent.id] =
        typeof subComponent.values.operation !== "undefined"
          ? subComponent.values.operation.value
          : ""),
        (newState["value" + subComponent.id] =
          typeof subComponent.values.value !== "undefined"
            ? subComponent.values.value.value
            : "");
    });
    this.setState(newState);
  }

  onChange(ref, id, e) {
    let value = e.target.value;
    if (!ref && !id) {
      this.setState({ [e.target.name]: value });
      if (e.target.name === "field") {
        this.props.onExclusionChange(this.refs.inputs, this.props.id, {
          reset: "stock"
        });
      } else {
        this.props.onExclusionChange(this.refs.inputs, this.props.id);
      }
    } else {
      let newState = {};
      newState[e.target.name + id] = value;
      this.setState(newState);
      if (e.target.name === "field") {
        this.props.onExclusionChange(this.refs[ref], id, { reset: "stock" });
      } else {
        this.props.onExclusionChange(this.refs[ref], id);
      }
    }
  }

  render() {
    return (
      <li className="wizard-criterion">
        <span ref="inputs">
          <span className="criteria-type" style={{ color: "#787774" }}>
            <Icon name="stock" color="#dc3322" />
          </span>
          <div className="wizard-criterion-text">
            <h3 className="wizard-criterion-title">{t("Stock")}</h3>
            <p className="wizard-criterion-description">
              {t(
                "Stock filter allows you to specify a range for the stock counts of the products that will be eliminated from the recommendation."
              )}
              {t("stockCount")}
              {t("stockRatio")}
              {t("stockStatus")}
            </p>
          </div>
          <div>
            <label className="item item-field is-select criteria-name wizard-operator">
              <select
                name="field"
                className="one-whole criteria-field"
                onChange={this.onChange.bind(this, null, null)}
                value={this.state.field}
              >
                <option value="stockCount">{t("Stock Count")}</option>
                <option value="stockRatio">{t("Stock Ratio")}</option>
                <option value="stockStatus">{t("Stock Status")}</option>
              </select>
            </label>

            <label className="item item-field is-select criteria-name wizard-operator">
              <select
                name="operation"
                className="one-whole criteria-field"
                onChange={this.onChange.bind(this, null, null)}
                value={this.state.operation}
              >
                <option value="GREATER">{t("Greater Than")}</option>
                <option value="LESS">{t("Less Than")}</option>
              </select>
            </label>

            <TextField
              name="value"
              label={this.state.field === "stockCount" ? "" : "%"}
              placeholder={this.state.field === "stockCount" ? "Value" : ""}
              value={this.state.value}
              className="item-field criteria-name wizard-value"
              inputClassName={
                this.state.field === "stockCount"
                  ? "one-whole criteria-field"
                  : "one-whole criteria-field label-percent"
              }
              required={true}
              onChange={this.onChange.bind(this, null, null)}
              type="number"
            />
            <div className="buttons-criteria">
              <a
                onClick={this.props.onExclusionRemove.bind(null, this.props.id)}
                className="remove-criteria button-criteria"
              >
                <Icons name="crossCircle" />
              </a>
              {this.props.subComponents.length < 2 &&
                this.state.field === "stockCount" && (
                  <a
                    onClick={this.props.onExclusionAdd.bind(null, "stock")}
                    className="button-criteria"
                  >
                    <Icons name="plusCircle" width="32" height="32" />
                  </a>
                )}
            </div>
          </div>
        </span>
        {this.props.subComponents.map((subComponent, ind) => {
          if (ind > 0) {
            return (
              <div
                className="criteria-subcomponent criteria-subcomponent-mg"
                ref={"subComponentInputs" + subComponent.id}
                key={subComponent.id}
              >
                <div>
                  <label className="item item-field is-select criteria-name wizard-operator">
                    <select
                      name="operation"
                      className="one-whole criteria-field"
                      onChange={this.onChange.bind(
                        this,
                        "subComponentInputs" + subComponent.id,
                        subComponent.id
                      )}
                      value={this.state["operation" + subComponent.id]}
                    >
                      <option value="GREATER">{t("Greater Than")}</option>
                      <option value="LESS">{t("Less Than")}</option>
                    </select>
                  </label>

                  <TextField
                    name="value"
                    label={this.state.field === "stockCount" ? "" : "%"}
                    placeholder={
                      this.state.field === "stockCount" ? "Value" : ""
                    }
                    value={this.state["value" + subComponent.id]}
                    className="item-field criteria-name wizard-value"
                    inputClassName={
                      this.state.field === "stockCount"
                        ? "one-whole criteria-field"
                        : "one-whole criteria-field label-percent"
                    }
                    required={true}
                    onChange={this.onChange.bind(
                      this,
                      "subComponentInputs" + subComponent.id,
                      subComponent.id
                    )}
                    type="number"
                  />
                  <div className="buttons-criteria">
                    {ind === this.props.subComponents.length - 1 && (
                      <a
                        onClick={this.props.onExclusionAdd.bind(
                          null,
                          subComponent.type
                        )}
                        className="button-criteria"
                      >
                        <Icons name="plusCircle" width="32" height="32" />
                      </a>
                    )}
                    <a
                      onClick={this.props.onExclusionRemove.bind(
                        null,
                        subComponent.id
                      )}
                      className="remove-criteria button-criteria"
                    >
                      <Icons name="crossCircle" />
                    </a>
                  </div>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate,
  excludes: store.wizard.excludes
});

export default connect(mapStatesToProps)(ExcludesStock);
