import React from 'react';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const EventListIcon = ({ width = '50', height = '50', color = 'yellow' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 50 50'
      height={fixedPxValue(height)}
      width={fixedPxValue(width)}
      fill={color}
    >
      <path d='M 13 4 C 8.038 4 4 8.037 4 13 L 4 37 C 4 41.963 8.038 46 13 46 L 37 46 C 41.962 46 46 41.963 46 37 L 46 13 C 46 8.037 41.962 4 37 4 L 13 4 z M 13 11 C 15.206 11 17 12.794 17 15 C 17 17.206 15.206 19 13 19 C 10.794 19 9 17.206 9 15 C 9 12.794 10.794 11 13 11 z M 13 12 C 11.346 12 10 13.346 10 15 C 10 16.654 11.346 18 13 18 C 14.654 18 16 16.654 16 15 C 16 13.346 14.654 12 13 12 z M 13 13 C 14.105 13 15 13.895 15 15 C 15 16.105 14.105 17 13 17 C 11.895 17 11 16.105 11 15 C 11 13.895 11.895 13 13 13 z M 21 14 L 39 14 C 39.553 14 40 14.447 40 15 C 40 15.553 39.553 16 39 16 L 21 16 C 20.447 16 20 15.553 20 15 C 20 14.447 20.447 14 21 14 z M 13 21 C 15.206 21 17 22.794 17 25 C 17 27.206 15.206 29 13 29 C 10.794 29 9 27.206 9 25 C 9 22.794 10.794 21 13 21 z M 13 22 C 11.346 22 10 23.346 10 25 C 10 26.654 11.346 28 13 28 C 14.654 28 16 26.654 16 25 C 16 23.346 14.654 22 13 22 z M 13 23 C 14.105 23 15 23.895 15 25 C 15 26.105 14.105 27 13 27 C 11.895 27 11 26.105 11 25 C 11 23.895 11.895 23 13 23 z M 21 24 L 39 24 C 39.553 24 40 24.447 40 25 C 40 25.553 39.553 26 39 26 L 21 26 C 20.447 26 20 25.553 20 25 C 20 24.447 20.447 24 21 24 z M 13 31 C 15.206 31 17 32.794 17 35 C 17 37.206 15.206 39 13 39 C 10.794 39 9 37.206 9 35 C 9 32.794 10.794 31 13 31 z M 13 32 C 11.346 32 10 33.346 10 35 C 10 36.654 11.346 38 13 38 C 14.654 38 16 36.654 16 35 C 16 33.346 14.654 32 13 32 z M 13 33 C 14.105 33 15 33.895 15 35 C 15 36.105 14.105 37 13 37 C 11.895 37 11 36.105 11 35 C 11 33.895 11.895 33 13 33 z M 21 34 L 39 34 C 39.553 34 40 34.447 40 35 C 40 35.553 39.553 36 39 36 L 21 36 C 20.447 36 20 35.553 20 35 C 20 34.447 20.447 34 21 34 z' />
    </svg>
  );
};

export default EventListIcon;
