import React from 'react';

import { dialogActions, uiActions } from '../../actions';
import { archiveAccount } from '../../modules/account/ajax';
import { t } from '../../system/ui';
import { isMasterUser } from '../../modules/auth/user';
import Icon from '../icon';
import Icons from '../icons';
import ActivateGoogleAnalyticsModal from './ActivateGoogleAnalyticsModal';
import ActivateGoogleAnalytics4Modal from './ActivateGoogleAnalytics4Modal';
import ActivatePushModal from './ActivatePushModal';
import EditAccount from './edit-account/index';
import LoginDetailsModal from './login-details';
import UserListModal from './user-list';

/**
 * @name AdminActions
 * @description Admin actions component for the accounts page
 * @param {Object} props
 * @param account
 * @param onUpdate
 * @param onArchive
 * @param onDetail
 * @param onPushUpdate
 * @param onGaUpdate
 * @param onGa4Update
 * @param hiddenFields
 * @param showDetails
 * @param features
 * @param user
 * @param users
 * @returns {JSX.Element}
 * @constructor
 */
const AdminActions = ({
  account,
  onUpdate,
  onArchive,
  onDetail,
  onPushUpdate,
  onGaUpdate,
  onGa4Update,
  hiddenFields,
  showDetails,
  features,
  user,
  users,
}) => {
  const { openModal, showNotification } = uiActions;
  const { openDialog, closeDialog } = dialogActions;

  const editAccount = e => {
    e.preventDefault();
    const content = () => <EditAccount account={account} onUpdate={onUpdate} />;
    openModal({ title: t('Edit Account'), content });
  };
  const ArchiveSelectedAccount = () => {
    archiveAccount(account, response => {
      let notificationContent;
      let notificationClassName;
      notificationContent = () => <div>{response.message}</div>;

      if (response.status !== 'OK') {
        notificationClassName = 'notification-fail';
      }

      onArchive(account);

      showNotification({
        content: notificationContent,
        className: notificationClassName,
      });
    });
  };

  const archiveAccountConfirm = () => {
    openDialog({
      title: t('Attention!'),
      content: t('This action will immediately effect on account'),
      onConfirm: () => {
        ArchiveSelectedAccount();
        closeDialog();
      },
      onCancel: () => {
        closeDialog();
      },
    });
  };

  const showLoginDetails = e => {
    e.preventDefault();

    const content = () => <LoginDetailsModal account={account} />;

    openModal({
      title: `${t('Login Details')} - ${account.domain}`,
      content,
    });
  };

  const showUserList = e => {
    e.preventDefault();

    openModal({
      title: `${t('User List')} ( ${account.domain} ) `,
      content: () => <UserListModal account={account} />,
    });
  };

  const handleActivatePush = () => {
    openModal({
      title: t('Firebase Credentials'),
      content: () => (
        <ActivatePushModal
          pushStatus={features.push.status}
          account={account}
          users={users}
          user={user}
          onPushUpdate={onPushUpdate}
        />
      ),
    });
  };

  const handleActivateGa = () => {
    openModal({
      title: t('Google Analytics Credentials'),
      content: () => (
        <ActivateGoogleAnalyticsModal
          account={account}
          users={users}
          user={user}
          onGaUpdate={onGaUpdate}
        />
      ),
    });
  };

  const handleActivateGa4 = () => {
    openModal({
      title: t('Google Analytics 4 Credentials'),
      content: () => (
        <ActivateGoogleAnalytics4Modal
          account={account}
          users={users}
          user={user}
          onGa4Update={onGa4Update}
        />
      ),
    });
  };

  return (
    <nav className='account-nav action-nav'>
      <Icon name='menu' />
      <ul className='action-nav-popup'>
        {hiddenFields.indexOf('campaignStats') < 0 && showDetails && (
          <li>
            <a className='action-nav-popup-item' onClick={onDetail}>
              <Icons name='details' width='16' height='16' />
              {t('Campaign Details')}
            </a>
          </li>
        )}
        <li>
          <a className='action-nav-popup-item' onClick={editAccount}>
            <Icon name='gear' /> {t('Edit')}
          </a>
        </li>
        <li>
          <a className='action-nav-popup-item' onClick={showLoginDetails}>
            <Icon name='user' /> Login Info
          </a>
        </li>

        <li>
          <a className='action-nav-popup-item' onClick={showUserList}>
            <Icons name='userList' /> {t('User List')}
          </a>
        </li>

        <li>
          <a className='action-nav-popup-item' onClick={handleActivatePush}>
            <Icons name='pushModule' height='16' width='16' />
            {features.push.status === 'ACTIVE' || features.push.status === 'POC'
              ? t('Edit Credentails')
              : t('Activate Push')}

            {(features.push.status === 'ACTIVE' ||
              features.push.status === 'POC') && (
              <span style={{ marginLeft: '10px' }}>
                <Icon name='check-circle' />
              </span>
            )}
          </a>
        </li>

        <li>
          <a className='action-nav-popup-item' onClick={handleActivateGa4}>
            <Icons name='analytics' height='16' width='16' />
            {account.ga4 && account.ga4.propertyId
              ? t('Edit GA4')
              : t('Activate GA4')}

            {account.ga4 && account.ga4.propertyId && (
              <span style={{ marginLeft: '10px' }}>
                <Icon name='check-circle' />
              </span>
            )}
          </a>
        </li>

        {hiddenFields.indexOf('archive') < 0 && isMasterUser() && (
          <li>
            <a
              className='action-nav-popup-item'
              onClick={archiveAccountConfirm}
            >
              <Icon name='archive' /> {t('Archive')}
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default AdminActions;
