import React from 'react';
import { t } from '../../system/ui';
import {
  transFormPersReport,
  transformPushTrends,
} from '../../modules/transform';
import { campaignTypesForGamificationReport } from '../../constants/datamaps/campaign';

import PopupBannerReport from '../widget/personalization-popup-report';
import NotificationBarReport from '../widget/personalization-notbar-report';
import EmailCollectionReport from '../widget/email-collection-report';
import SegmentationReport from '../widget/segmentation-report';
import HeroBannerReport from '../widget/personalization-hero-report';
import SeeAllReport from '../widget/personalization-seeall-report';
import GamificationReport from '../widget/personalization-gamification-report';

class EngagementStats extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let statsComponent = null;
    const logs = this.props.oldReport
      ? transformPushTrends(this.props.logs)
      : transFormPersReport(this.props.logs);
    if (this.props.type === 'POPUP_BANNER') {
      statsComponent = <PopupBannerReport stats={logs} onViewAll />;
    } else if (this.props.type === 'SEE_ALL') {
      statsComponent = <SeeAllReport stats={logs} onViewAll />;
    } else if (campaignTypesForGamificationReport.includes(this.props.type)) {
      statsComponent = <GamificationReport stats={logs} onViewAll />;
    } else if (this.props.type === 'NOTIFICATION_BAR') {
      statsComponent = <NotificationBarReport stats={logs} onViewAll />;
    } else if (this.props.type === 'HERO_BANNER') {
      statsComponent = <HeroBannerReport stats={logs} onViewAll />;
    } else if (this.props.type === 'NEWSLETTER' || this.props.type === 'FORM') {
      statsComponent = <EmailCollectionReport stats={logs} onViewAll />;
    } else if (this.props.type === 'SEGMENTATION') {
      statsComponent = (
        <SegmentationReport stats={logs} onViewAll action={this.props.action} />
      );
    }
    return (
      <div className='widget-stat-list'>
        <p>{t('Last 7 Days')}</p>
        <div className='widget-stats'>{statsComponent}</div>
      </div>
    );
  }
}

export default EngagementStats;
