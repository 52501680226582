import React from "react";

class IconWinback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={this.state.width}
        height={this.state.height}
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="icon/winback"
            transform="translate(-3.000000, -3.000000)"
            fill={this.state.color}
            fillRule="nonzero"
          >
            <path
              d="M23.3134625,23.3772391 C20.799069,25.9134172 17.2554698,27.14814 13.7094625,26.7236328 L13.8107547,26.0183391 C18.9043558,26.5947582 23.7194514,23.5715006 25.4134145,18.7333687 C27.1073776,13.8952368 25.229785,8.52868509 20.8890874,5.80189661 C16.5483898,3.07510813 10.8987743,3.71313334 7.27553281,7.33930938 C3.02052117,11.6277498 3.00881113,18.5416881 7.24927187,22.8445172 L7.50812969,23.103375 L8.97123906,21.60275 L10.0516891,26.104625 L5.54981406,25.0842 L7.016675,23.583575 L6.76907187,23.3322203 C3.42061237,19.9739453 2.42688976,14.9283054 4.25187836,10.5511364 C6.07686697,6.17396736 10.3605861,3.32866994 15.1029447,3.34372846 C19.8453033,3.35878698 24.1108668,6.23123114 25.908021,10.6199016 C27.7051752,15.0085721 26.6794299,20.0477996 23.3097109,23.3847422 L23.3134625,23.3772391 Z M10.498125,16.0354313 C9.80250709,15.3384731 9.5315524,14.3233494 9.78732696,13.3724476 C10.0431015,12.4215457 10.786747,11.6793303 11.7381395,11.4253866 C12.6895319,11.1714429 13.7041321,11.4443512 14.39975,12.1413094 L15.0712797,12.8128391 L15.7503125,12.1413094 C16.8256644,11.0660253 18.5690824,11.0660253 19.6444344,12.1413094 C20.7176516,13.2175166 20.7176516,14.959224 19.6444344,16.0354313 L15.0712797,20.6010828 L10.498125,16.0354313 Z M12.4451859,11.9649859 C11.5868755,11.9656601 10.8133789,12.482998 10.4850089,13.2760115 C10.1566389,14.069025 10.3379807,14.9817411 10.9445609,15.5889953 L15.0712797,19.7157141 L19.1979984,15.5889953 C19.9803047,14.752702 19.9585306,13.4467127 19.1487808,12.6369629 C18.339031,11.8272131 17.0330417,11.805439 16.1967484,12.5877453 L15.0712797,13.7132141 L13.9458109,12.5877453 C13.5507152,12.1898717 13.0134059,11.9657678 12.4526891,11.9649859 L12.4451859,11.9649859 Z"
              id="winback"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default IconWinback;
