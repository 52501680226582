import emailBuilderInitialState from './initialState';

export const emailBuilderReducer = (
  state = emailBuilderInitialState,
  action,
) => {
  switch (action.type) {
    case 'SET_EMAIL_BUILDER_EDITOR_OPTIONS':
      return {
        ...state,
        editorOptions: action.payload,
      };
    case 'SET_EMAIL_BUILDER_THEME':
      return {
        ...state,
        isDarkMode: action.payload,
      };
    case 'SET_EMAIL_BUILDER_SERVER_SIDE_QUERY':
      return {
        ...state,
        serverSideQuery: action.payload,
      };
    case 'SET_EMAIL_BUILDER_IS_EDITOR_READY':
      return {
        ...state,
        isEditorReady: action.payload,
      };
    case 'SET_EMAIL_BUILDER_FETCHED_TEMPLATE':
      return {
        ...state,
        fetchedTemplate: action.payload,
      };
    default:
      return state;
  }
};
