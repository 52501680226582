import { t } from '../../../system/ui';

const emptyCampaignData = Object.freeze({
  type: '',
  name: '',
  instanceId: '',
  status: '',
  devices: [],
  startDate: '',
  endDate: '',
  note: '',
  timing: {},
  frequency: {},
  filters: [],
  prioritized: false,
  lifetime: false,
  campaignMessage: '',
  bgColor: '',
  textColor: '',
  html: '',
  css: '',
  preJs: '',
  postJs: '',
  verticalPosition: '',
  horizontalPosition: '',
  mobileVerticalPosition: '',
  mobileHorizontalPosition: '',
  conditions: [],
  savingOverTime: [],
  timeRange: {},
});

const dateFormat = 'DD/MM/YYYY';

const desktopPosOptions = [
  'Top & Left',
  'Top & Center',
  'Top & Right',
  'Middle & Left',
  'Middle & Center',
  'Middle & Right',
  'Bottom & Left',
  'Bottom & Center',
  'Bottom & Right',
];

const mobilePosOptions = ['Top', 'Middle', 'Bottom'];

const timingOptions = [
  { label: t('Immediate'), value: 'IMMEDIATE' },
  { label: t('Delay'), value: 'DELAY' },
  { label: t('Scroll'), value: 'SCROLL' },
  { label: t('Visitor Leaving Page'), value: 'PAGELEAVE' },
];

const frequencyOptions = [
  { label: t('Always'), value: 'ALWAYS' },
  { label: t('Session'), value: 'SESSION' },
  { label: t('Life Time'), value: 'LIFETIME' },
  { label: t('Days'), value: 'DAYS' },
];

const visitorTypeOptions = {
  options: [
    { label: 'Visitor is', value: true },
    { label: 'Visitor is not', value: false },
  ],
  types: [
    { label: 'New', value: 'new' },
    { label: 'Registered', value: 'register' },
    { label: 'Logged in', value: 'login' },
  ],
};

const visitorSegmentOptions = [
  { label: t('Visitor is Member'), value: true },
  { label: t('Visitor is not a Member'), value: false },
];

const conditionOperatorOptions = [
  { label: 'is equal to', value: '=' },
  { label: 'is not equal to', value: '!=' },
  { label: 'is greater than', value: '>' },
  { label: 'is smaller than', value: '<' },
  { label: 'is less or equal to', value: '<=' },
  { label: 'is greater than or equal to', value: '>=' },
  { label: 'is between', value: '<>' },
];

const conditionTimeUnits = [
  { label: 'minutes', value: 'minutes' },
  { label: 'hours', value: 'hours' },
  { label: 'days', value: 'days' },
];

export {
  emptyCampaignData,
  dateFormat,
  desktopPosOptions,
  mobilePosOptions,
  timingOptions,
  frequencyOptions,
  visitorTypeOptions,
  visitorSegmentOptions,
  conditionOperatorOptions,
  conditionTimeUnits,
};
