export const params = ['recommendations', 'inclusions', 'exclusions'];

export const initialBeforeSearchAsset = [
  {
    id: '0.4461577066040776',
    type: 'searchRow',
    properties: {
      isMobile: false,
      active: true,
      type: 'CATEGORY',
      icons: 'categoryBoxes',
      text: 'Category',
      isTreeView: true,
      isData: true,
    },
    exclusions: [],
    values: {
      criterion: {
        additionalTitles: {
          EN: 'Popular Categories',
          TR: 'Popüler Kategoriler',
        },
        clickable: true,
        treeView: true,
        status: 'ACTIVE',
        assetItems: [
          {
            id: '0.561036181621207',
            type: 'intelligent',
            values: {
              criterion: {
                value: 'TOP_CATEGORY',
              },
              itemCount: {
                value: 8,
              },
            },
          },
        ],
      },
    },
  },
  {
    id: '0.03465258576390795',
    type: 'searchRow',
    properties: {
      isMobile: false,
      active: true,
      type: 'BRAND',
      icons: 'excludeLabel',
      text: 'Brand',
      isTreeView: false,
      isData: true,
    },
    exclusions: [],
    values: {
      criterion: {
        additionalTitles: {
          EN: 'Popular Brands',
          TR: 'Popüler Markalar',
        },
        clickable: true,
        treeView: false,
        status: 'ACTIVE',
        assetItems: [
          {
            id: '0.2507071231102096',
            type: 'intelligent',
            values: {
              criterion: {
                value: 'TOP_BRAND',
              },
              itemCount: {
                value: 8,
              },
            },
          },
        ],
      },
    },
  },
  {
    id: '0.8105245267761845',
    type: 'searchRow',
    properties: {
      isMobile: false,
      active: true,
      type: 'KEYWORD',
      icons: 'fieldTextArea',
      text: 'Keyword',
      isTreeView: false,
      isData: true,
    },
    exclusions: [],
    values: {
      criterion: {
        additionalTitles: {
          EN: 'Top Keywords',
          TR: 'Popüler Kelimeler',
        },
        clickable: true,
        treeView: false,
        status: 'ACTIVE',
        assetItems: [
          {
            id: '0.13872822226250214',
            type: 'intelligent',
            values: {
              criterion: {
                value: 'TOP_KEYWORD',
              },
              itemCount: {
                value: 8,
              },
            },
          },
        ],
      },
    },
  },
  {
    id: '0.3392429026531638',
    type: 'searchRow',
    properties: {
      isMobile: true,
      type: 'CATEGORY',
      active: false,
      icons: 'categoryBoxes',
      text: 'Category',
      isTreeView: true,
      isData: true,
    },
    exclusions: [],
    values: {
      criterion: {
        additionalTitles: {
          EN: 'Popular Categories',
          TR: 'Popüler Kategoriler',
        },
        clickable: false,
        treeView: false,
        status: 'PASSIVE',
      },
    },
  },
  {
    id: '0.9740578588113034',
    type: 'searchRow',
    properties: {
      isMobile: true,
      type: 'BRAND',
      active: false,
      icons: 'excludeLabel',
      text: 'Brand',
      isTreeView: false,
      isData: true,
    },
    exclusions: [],
    values: {
      criterion: {
        additionalTitles: {
          EN: 'Popular Brands',
          TR: 'Popüler Markalar',
        },
        clickable: false,
        treeView: false,
        status: 'PASSIVE',
      },
    },
  },
  {
    id: '0.788495230651743',
    type: 'searchRow',
    properties: {
      isMobile: true,
      type: 'KEYWORD',
      active: false,
      icons: 'fieldTextArea',
      text: 'Keyword',
      isTreeView: false,
      isData: true,
    },
    exclusions: [],
    values: {
      criterion: {
        additionalTitles: {
          EN: 'Top Keywords',
          TR: 'Popüler Kelimeler',
        },
        clickable: false,
        treeView: false,
        status: 'PASSIVE',
      },
    },
  },
  {
    id: '0.999999999999',
    type: 'searchRow',
    properties: {
      isMobile: false,
      active: true,
      type: 'LAST_SEARCHES',
      icons: 'circleBack',
      text: 'Search History',
      isTreeView: false,
      isData: true,
    },
    exclusions: [],
    values: {
      criterion: {
        additionalTitles: {
          EN: 'Last Searches',
          TR: 'Son Aramalarınız',
        },
        clickable: true,
        treeView: false,
        status: 'ACTIVE',
        assetItems: [
          {
            id: '0.23112315415123',
            type: 'intelligent',
            values: {
              criterion: {
                value: 'LAST_SEARCHES',
              },
              itemCount: {
                value: 1,
              },
            },
          },
        ],
      },
    },
  },
  {
    id: '0.9999995555555',
    type: 'searchRow',
    properties: {
      isMobile: true,
      active: false,
      type: 'LAST_SEARCHES',
      icons: 'circleBack',
      text: 'Search History',
      isTreeView: false,
      isData: true,
    },
    exclusions: [],
    values: {
      criterion: {
        additionalTitles: {
          EN: 'Last Searches',
          TR: 'Son Aramalarınız',
        },
        clickable: true,
        treeView: false,
        status: 'PASSIVE',
        assetItems: [
          {
            id: '0.13423455123412',
            type: 'intelligent',
            values: {
              criterion: {
                value: 'LAST_SEARCHES',
              },
              itemCount: {
                value: 1,
              },
            },
          },
        ],
      },
    },
  },
];

export const initialBeforeSearchAlgorithm = [
  {
    id: 0.4125919951345425,
    type: 'intelligent',
    values: {
      criterion: { value: 'RECOMMENDATION_SMART_OFFERS' },
      itemCount: { value: '8' },
      mobileItemCount: { value: '8' },
      timeFrame: { value: 'THIS_WEEK' },
    },
  },
];

export const searchDefaultOrder = {
  NEWEST: {
    fields: [
      {
        direction: 'desc',
        textSorting: false,
        productAccessor: 'insertTime',
      },
    ],
    name: 'Newest',
    enabled: false,
    isDefault: false,
  },
  PRICE_ASC: {
    fields: [
      {
        direction: 'asc',
        textSorting: false,
        productAccessor: 'price',
      },
    ],
    name: 'Price Ascending',
    enabled: false,
    isDefault: false,
  },
  BEST_MATCH: {
    fields: [
      {
        direction: 'desc',
        textSorting: false,
        productAccessor: 'score',
      },
    ],
    name: 'Best Match',
    enabled: false,
    isDefault: false,
  },
  MOST_POPULAR: {
    fields: [
      {
        productAccessor: 'rockScore',
        direction: 'desc',
        textSorting: false,
      },
    ],
    name: 'Most Popular',
    enabled: false,
    isDefault: false,
  },
  BOOSTED_SMART_SORTING: {
    name: 'Boosted Smart Sorting',
    enabled: false,
    isDefault: false,
  },
  PRICE_DESC: {
    fields: [
      {
        direction: 'desc',
        textSorting: false,
        productAccessor: 'price',
      },
    ],
    name: 'Price Descending',
    enabled: false,
    isDefault: false,
  },
  BEST_SELLERS: {
    fields: [
      {
        direction: 'desc',
        textSorting: false,
        productAccessor: 'totalMonthlyPurchases',
      },
    ],
    name: 'Best Sellers',
    enabled: false,
    isDefault: false,
  },
  SMART_SORTING: {
    fields: [
      {
        direction: 'desc',
        textSorting: false,
        productAccessor: 'rockScore',
      },
      {
        productAccessor: 'score',
        direction: 'desc',
        textSorting: false,
      },
    ],
    name: 'Smart Sorting',
    enabled: false,
    isDefault: false,
    rockScorePercentage: 50,
  },
  ALPHABETICAL_ASC: {
    fields: [
      {
        direction: 'asc',
        textSorting: true,
        productAccessor: 'name',
      },
    ],
    name: 'Alphabetical Ascending',
    enabled: false,
    isDefault: false,
  },
  ALPHABETICAL_DESC: {
    fields: [
      {
        direction: 'desc',
        textSorting: true,
        productAccessor: 'name',
      },
    ],
    name: 'Alphabetical Descending',
    enabled: false,
    isDefault: false,
  },
};

export const beforeSearchCampaignInitialData = {
  instanceId: 'BEFORE_SEARCH',
  name: 'Before Input Search',
  status: 'ACTIVE',
  devices: ['PC', 'MOBILE'],
  html: `<div id="sgm-search-template" type="x-tmpl-mustache">
  <div class="sgm-search-header">
<div class="sgm-search-close-title"> [[#emptySearch]]
          <h3 class="sgm-search-products-title">[[texts.popularProducts]]</h3> [[/emptySearch]] [[#keywordSearch]]
          <h3 class="sgm-search-products-title">[[texts.products]]</h3> [[/keywordSearch]] </div>
  </div>
  <div class="sgm-search-sidebar">
     <!-- LAST SEARCH -->
    [[#itemEnable.lastSearches]]
      [[#lastSearches.0]]
        <div class="sgm-search-lastSearches-header">
          <h3 class="sgm-search-lastSearches-title">[[texts.lastSearchesText]]</h3>
          [[#searchHistoryClearSettings.clearButtonEnabled]]
          <span class="sgm-search-lastSearches-clear-text">
            [[texts.clearButtonText]]
          </span>
          [[/searchHistoryClearSettings.clearButtonEnabled]]
        </div> 
        <hr/>
        <div data-group="lastSearches" class="sgm-search-last-searches-list"> 
         [[#lastSearches]]
            <div data-url="/search?search=[[.]]" data-original="[[.]]" class="sgm-search-last-searches-list-item">
              <span class="sgm-search-last-searches-list-name">[[.]]</span>
              [[#searchHistoryClearSettings.clearQueryBasedEnabled]]
              <span class="sgm-search-last-searches-list-delete">x</span>
              [[/searchHistoryClearSettings.clearQueryBasedEnabled]]
            </div>
         [[/lastSearches]]
        </div>
      [[/lastSearches.0]]
    [[/itemEnable.lastSearches]]
      <!-- CATEGORIES -->[[#itemEnable.category]] [[#categories.0]] [[#emptySearch]]
      <h3 class="sgm-search-category-title">[[texts.popularCategories]]</h3> [[/emptySearch]] [[#keywordSearch]]
      <h3 class="sgm-search-category-title">[[texts.categories]]</h3> [[/keywordSearch]]
      <hr/>
      <ul data-group="categories"> [[#categories]]
          <li data-url="[[url]]" data-original="[[name]]">[[name]]</li> [[/categories]] </ul> [[/categories.0]] [[/itemEnable.category]]
      <!-- BRANDS -->[[#itemEnable.brand]] [[#brands.0]] [[#emptySearch]]
      <h3 class="sgm-search-brand-title">[[texts.popularBrands]]</h3> [[/emptySearch]] [[#keywordSearch]]
      <h3 class="sgm-search-brand-title">[[texts.brands]]</h3> [[/keywordSearch]]
      <hr/>
      <ul data-group="brands"> [[#brands]]
          <li data-url="[[url]]" data-original="[[name]]">[[name]]</li> [[/brands]] </ul> [[/brands.0]] [[/itemEnable.brand]]
      <!-- KEYWORDS -->[[#itemEnable.keyword]] [[#keywords.0]] [[#emptySearch]]
      <h3 class="sgm-search-keyword-title">[[texts.keywords]]</h3> [[/emptySearch]]
      <hr/>
      <ul data-group="keywords"> [[#keywords]]
          <li data-url="[[url]]" data-original="[[name]]">[[name]]</li> [[/keywords]] </ul> [[/keywords.0]] [[/itemEnable.keyword]] </div>
  <div class="sgm-search-products">
    <div id="sgm-search-not-found">[[texts.notFound]]</div>
      <!-- PRODUCTS -->
      <div class="sgm-search-products-title-container"> [[#emptySearch]]
          <h3 class="sgm-search-products-title">[[texts.popularProducts]]</h3> [[/emptySearch]] [[#keywordSearch]]
          <h3 class="sgm-search-products-title">[[texts.products]]</h3> [[/keywordSearch]]
          <hr/> </div>
      <ul data-group="products"> [[>products]] </ul>
  </div>
  <button class="sgm-search-show-all">[[texts.showAll]]</button>
</div>
<div id="sgm-search-product-template" type="x-tmpl-mustache"> [[#products]]
  [[^inStock]]
  <a target="_self" href="[[url]]" class="sgm-search-product-out-of-stock">
  [[/inStock]]
  [[#inStock]]
  <a target="_self" href="[[url]]">
  [[/inStock]]
      <li>
          <div class="sgm-search-product-image"> <img src = "[[image]]"> </div>
          <div class="sgm-search-product-info">
              <div class="sgm-search-product-info-category">[[category]]</div>
              <div class="sgm-search-product-info-name">[[name]]</div> <span class="sgm-search-product-info-price">[[priceText]]</span><span class="sgm-search-product-info-oldPrice">[[#oldPriceText]][[oldPriceText]][[/oldPriceText]]</span> </div>
      </li>
  </a> [[/products]] </div>`,
  preJs: `// this function will get a configuration object to be executed at html rendering with mustache.js
  // do not change the function name
    function preRenderConf(searchSettings) {
  }`,
  postJs: `
  var listenerType = window.screen.width < 800 ? 'touchstart' : 'click';
 
  // Selects clear all btn
  var clearAllBtn = document.getElementsByClassName(
    'sgm-search-lastSearches-clear-text',
  )[0];
  
  // Ships Clear All History Event && removes from DOM
  clearAllBtn.addEventListener(listenerType, function () {
    Segmentify('user:lastsearchdeletedkeywords');
    document.querySelector('.sgm-search-last-searches-list').remove();
  });
  
  // Ships Clear by Id History Event && removes from DOM
  var clearByIdBtn = document.querySelectorAll(
    '.sgm-search-last-searches-list-delete',
  );
  
  clearByIdBtn.forEach(function (el) {
    el.addEventListener(listenerType, function (e) {
      e.stopPropagation();
      Segmentify('user:lastsearchdeletedkeywords', {
        lastSearchDeletedKeywords: e.target.parentNode.dataset.original,
      });
      e.target.parentNode.remove();
    });
  });
  
  // Clickable search history
  var searchHistoryText = document.querySelectorAll(
    '.sgm-search-last-searches-list-name',
  );
  
  searchHistoryText.forEach(function (el) {
    el.addEventListener(listenerType, function (e) {
      e.stopPropagation();
      window.location.href = e.target.parentNode.dataset.url;
    });
  });
`,
  stringSearchAssetTextMap: {
    EN: {
      popularCategoriesText: 'Popular Categories',
      popularBrandsText: 'Popular Brands',
      popularKeywordsText: 'Top Keywords',
      mobilePopularCategoriesText: 'Popular Categories',
      mobilePopularBrandsText: 'Popular Brands',
      mobilePopularKeywordsText: 'Top Keywords',
      popularProductsText: 'Popular Products',
      mobileCancelText: 'Cancel',
      mobileLastSearchesText: 'Arama Geçmişi',
      lastSearchesText: 'Arama Geçmişi',
    },
    TR: {
      popularCategoriesText: 'Popüler Kategoriler',
      popularBrandsText: 'Popüler Markalar',
      popularKeywordsText: 'Popüler Kelimeler',
      mobilePopularCategoriesText: 'Popüler Kategoriler',
      mobilePopularBrandsText: 'Popüler Markalar',
      mobilePopularKeywordsText: 'Popüler Kelimeler',
      popularProductsText: 'Popüler Ürünler',
      mobileCancelText: 'İptal',
      mobileLastSearchesText: 'Arama Geçmişi',
      lastSearchesText: 'Arama Geçmişi',
    },
  },
  searchAssets: [
    {
      type: 'CATEGORY',
      status: 'ACTIVE',
      clickable: true,
      assetItems: [
        {
          count: 8,
          algorithm: 'TOP_CATEGORY',
        },
      ],
      exclusions: [],
      categoryTreeView: true,
    },
    {
      type: 'BRAND',
      status: 'ACTIVE',
      clickable: true,
      assetItems: [
        {
          count: 8,
          algorithm: 'TOP_BRAND',
        },
      ],
      exclusions: [],
      categoryTreeView: false,
    },
    {
      type: 'KEYWORD',
      status: 'ACTIVE',
      clickable: true,
      assetItems: [
        {
          count: 8,
          algorithm: 'TOP_KEYWORD',
        },
      ],
      exclusions: [],
      categoryTreeView: false,
    },
    {
      type: 'LAST_SEARCHES',
      status: 'ACTIVE',
      clickable: true,
      assetItems: [
        {
          count: 1,
          algorithm: 'LAST_SEARCHES',
        },
      ],
      exclusions: [],
      categoryTreeView: false,
    },
  ],
  mobileItemCount: 8,
  desktopItemCount: 8,
  mobileSearchAssets: [
    {
      type: 'CATEGORY',
      status: 'PASSIVE',
      clickable: false,
      assetItems: [],
      exclusions: [],
      categoryTreeView: false,
      internalAssetItems: [],
    },
    {
      type: 'BRAND',
      status: 'PASSIVE',
      clickable: false,
      assetItems: [],
      exclusions: [],
      categoryTreeView: false,
      internalAssetItems: [],
    },
    {
      type: 'KEYWORD',
      status: 'PASSIVE',
      clickable: false,
      assetItems: [],
      exclusions: [],
      categoryTreeView: false,
      internalAssetItems: [],
    },
    {
      type: 'LAST_SEARCHES',
      status: 'PASSIVE',
      clickable: false,
      assetItems: [],
      exclusions: [],
      categoryTreeView: false,
      internalAssetItems: [],
    },
  ],
  recommendationParams: {
    exclusions: '[]',
    staticItems: '[]',
    dynamicItems:
      '[{"recommendationSource":"RECOMMENDATION_SMART_OFFERS","itemCount":"8","mobileItemCount":"8","type":"INTELLIGENT","timeFrame":"THIS_WEEK","score":"NONE"}]',
  },
  orders: searchDefaultOrder,
};

export const campaignInitialData = {
  instanceId: 'SEARCH',
  name: 'After Search Input',
  status: 'ACTIVE',
  devices: ['PC', 'MOBILE'],
  desktopItemCount: 6,
  mobileItemCount: 6,
  synonyms: {},
  html: `<div id="sgm-search-template" type="x-tmpl-mustache">
  <div class="sgm-search-header">
<div class="sgm-search-close-title"> [[#emptySearch]]
          <h3 class="sgm-search-products-title">[[texts.popularProducts]]</h3> [[/emptySearch]] [[#keywordSearch]]
          <h3 class="sgm-search-products-title">[[texts.products]]</h3> [[/keywordSearch]] </div>
  </div>
  <div class="sgm-search-sidebar">
      <div class="sgm-search-ac-assets-header" data-sgm-ac-position="searchbox-assets-header"></div>
      <!-- CATEGORIES -->[[#itemEnable.category]] [[#categories.0]] [[#emptySearch]]
      <h3 class="sgm-search-category-title">[[texts.popularCategories]]</h3> [[/emptySearch]] [[#keywordSearch]]
      <h3 class="sgm-search-category-title">[[texts.categories]]</h3> [[/keywordSearch]]
      <hr/>
      <ul data-group="categories"> [[#categories]]
          <li data-url="[[url]]" data-original="[[name]]">[[name]]</li> [[/categories]] </ul> [[/categories.0]] [[/itemEnable.category]]
      <!-- BRANDS -->[[#itemEnable.brand]] [[#brands.0]] [[#emptySearch]]
      <h3 class="sgm-search-brand-title">[[texts.popularBrands]]</h3> [[/emptySearch]] [[#keywordSearch]]
      <h3 class="sgm-search-brand-title">[[texts.brands]]</h3> [[/keywordSearch]]
      <hr/>
      <ul data-group="brands"> [[#brands]]
          <li data-url="[[url]]" data-original="[[name]]">[[name]]</li> [[/brands]] </ul> [[/brands.0]] [[/itemEnable.brand]]
      <!-- KEYWORDS -->[[#itemEnable.keyword]] [[#keywords.0]] [[#emptySearch]]
      <h3 class="sgm-search-keyword-title">[[texts.keywords]]</h3> [[/emptySearch]]
      <hr/>
      <ul data-group="keywords"> [[#keywords]]
          <li data-url="[[url]]" data-original="[[name]]">[[name]]</li> [[/keywords]] </ul> [[/keywords.0]] [[/itemEnable.keyword]] </div>
          <div class="sgm-search-ac-assets-footer" data-sgm-ac-position="searchbox-assets-footer"></div>
  <div class="sgm-search-products">
    <div class="sgm-search-ac-results-header" data-sgm-ac-position="searchbox-results-header"></div>
    <div id="sgm-search-not-found">[[texts.notFound]]</div>
      <!-- PRODUCTS -->
      <div class="sgm-search-products-title-container"> [[#emptySearch]]
          <h3 class="sgm-search-products-title">[[texts.popularProducts]]</h3> [[/emptySearch]] [[#keywordSearch]]
          <h3 class="sgm-search-products-title">[[texts.products]]</h3> [[/keywordSearch]]
          <hr/> </div>
      <ul data-group="products"> [[>products]] </ul>
      <div class="sgm-search-ac-results-footer" data-sgm-ac-position="searchbox-results-footer"></div>
  </div>
  <button class="sgm-search-show-all">[[texts.showAll]]</button>
</div>
<div id="sgm-search-product-template" type="x-tmpl-mustache"> [[#products]]
  [[^inStock]]
  <a target="_self" href="[[url]]" class="sgm-search-product-out-of-stock">
  [[/inStock]]
  [[#inStock]]
  <a target="_self" href="[[url]]">
  [[/inStock]]
      <li>
          <div class="sgm-search-product-image"> <img src = "[[image]]"> </div>
          <div class="sgm-search-product-info">
              <div class="sgm-search-product-info-category">[[category]]</div>
              <div class="sgm-search-product-info-name">[[name]]</div> <span class="sgm-search-product-info-price">[[priceText]]</span><span class="sgm-search-product-info-oldPrice">[[#oldPriceText]][[oldPriceText]][[/oldPriceText]]</span> </div>
      </li>
  </a> [[/products]] </div>`,
  preJs: `// this function will get a configuration object to be executed at html rendering with mustache.js
          // do not change the function name
            function preRenderConf(searchSettings) {
          }`,

  postJs: '',
  exclusions: [],
  inclusions: [],
  searchAssets: [{}],
  mobileSearchAssets: [{}],
  stringSearchAssetTextMap: {
    EN: {
      categoriesText: 'Categories',
      brandsText: 'Brands',
      popularProductsText: 'Products',
      notFoundText: 'Not Found',
      mobileCancelText: 'Cancel',
    },
    TR: {
      categoriesText: 'Kategoriler',
      brandsText: 'Markalar',
      popularProductsText: 'Ürünler',
      notFoundText: 'Bulunamadı...',
      mobileCancelText: 'İptal',
    },
  },
  noResultRecommendation: {
    isNoResultRecommendationActive: false,
    text: 'No Result Found!',
    recommendationParams: {
      exclusions: '[]',
      staticItems: '[]',
      dynamicItems:
        '[{"recommendationSource":"RECOMMENDATION_SMART_OFFERS","itemCount":"8","mobileItemCount":"8","type":"INTELLIGENT","timeFrame":"THIS_WEEK","score":"NONE"}]',
    },
  },
  orders: searchDefaultOrder,
};

export const initialNoResultAfterSearchAlgorithm = [
  {
    id: 0.412597435678458345973,
    type: 'intelligent',
    values: {
      criterion: { value: 'RECOMMENDATION_SMART_OFFERS' },
      itemCount: { value: '8' },
      mobileItemCount: { value: '8' },
      timeFrame: { value: 'THIS_WEEK' },
    },
  },
];
export const searchSettingsCampaignInitialData = {
  searchDelay: 700,
  minCharacterCount: 3,
  searchUrlPrefix: '/search?keyword=',
  searchInputSelector: 'div#searchInput',
  hideCurrentSelector: 'div#currentSelector',
  triggerSelector: 'div#triggerSelector',
  openingDirection: 'LEFT',
  forceRemoveInputVal: false,
  facetedIncludeOutOfStock: false,
  instantIncludeOutOfStock: false,
  recommendVariantForFaceted: false,
  skipAttributeBasedEliminationInSearchResults: true,
  useInStockInOrderForInstant: false,
  useInStockInOrderForFaceted: false,
  clearInputBindingsOnSite: false,
  activateProductIdAndCategoryAndBrandRedirection: false,
  noOutOfStockProductOnPinnedProducts: false,
  synonyms: {},
  shadowRootElements: [],
  shadowRootElementsForHide: [],
  shadowRootElementsForTrigger: [],
  nonTypoKeywords: [],
  redirectionEnabled: false,
  redirectionJs: '',
  rockScoreFrameType: '',
  typoCorrection: {
    // typoTolerance: {
    //   fuzzyDefs: [
    //     {
    //       min: 3,
    //       max: 6,
    //       val: 1,
    //     },
    //     {
    //       min: 7,
    //       max: null,
    //       val: 2,
    //     },
    //   ],
    // },
  },
};

export const initialState = {
  title: 'Search Box',
  mainField: [
    {
      field: 'searchRecommendation',
      fields: [
        {
          field: 'title',
          options: {
            label: 'assetTexts',
            required: true,
            tooltip: true,
            tooltipContent:
              'Enter the asset texts that your visitors will see on the Search Box recommendation',
          },
        },
      ],
    },
  ],
  synonymField: [
    {
      field: 'searchSynonyms',
      fields: [
        {
          field: 'title',
          options: {
            label: 'assetTexts',
            required: true,
            tooltip: true,
            tooltipContent:
              'Enter the asset texts that your visitors will see on the Search Box recommendation',
          },
        },
      ],
    },
  ],
  config: [
    {
      field: 'name',
    },
    {
      field: 'searchDevices',
      options: {
        tooltip: true,
        tooltipContent:
          'Choose which devices you want to active the Search Box',
      },
    },
    {
      field: 'advancedConfig',
      options: {},
    },
  ],
  settings: [
    {
      field: 'outOfStockInputVal',
      options: {
        label: 'Include Out of Stock Products',
        tooltip: false,
        propName: 'instantIncludeOutOfStock',
      },
    },
    {
      field: 'useInStockInOrderForInstant',
      options: {
        label: 'Show In Stock Products First',
        tooltip: false,
        propName: 'useInStockInOrderForInstant',
      },
    },
    {
      field: 'clearInputBindingsOnSite',
      options: {
        label: 'Disable Search Box Events',
        tooltip: false,
        propName: 'clearInputBindingsOnSite',
      },
    },
    {
      field: 'activateProductIdAndCategoryAndBrandRedirection',
      options: {
        label: 'Active Product ID & Category & Brand Redirection',
        tooltip: false,
        propName: 'activateProductIdAndCategoryAndBrandRedirection',
      },
    },
    {
      field: 'delay',
      options: {
        label: 'Search Delay',
        tooltip: true,
        tooltipContent:
          'Specifies when the search results will be shown after how many milliseconds a search is made.',
        placeholder: '700',
      },
    },
    {
      field: 'minChar',
      options: {
        label: 'Min Character for Search',
        tooltip: true,
        tooltipContent:
          'Specifies after how many characters in the search bar the search results will be shown.',
        placeholder: '3',
      },
    },
    {
      field: 'urlPrefix',
      options: {
        label: 'Search Page URL',
        tooltip: true,
        tooltipContent:
          'This is the search path; the URL that is directed when a search is made',
        placeholder: '/search?keyword=',
      },
    },
    {
      field: 'inputSelectorShadowRoots',
      options: {
        propName: 'shadowRootElements',
      },
    },
    {
      field: 'inputSelector',
      options: {
        label: 'Search Input Selector',
        tooltip: true,
        tooltipContent: 'The selector of the search bar in the website.',
        placeholder: 'div#searchInput',
      },
    },
    {
      field: 'forceRemoveInputVal',
      options: {
        label: 'Force to Remove Input Value',
        tooltip: false,
        propName: 'forceRemoveInputVal',
      },
    },
    {
      field: 'hideSelectorShadowRoots',
      options: {
        propName: 'shadowRootElementsForHide',
      },
    },
    {
      field: 'currentSelector',
      options: {
        label: 'Hide Current Selector',
        tooltip: true,
        tooltipContent:
          'Hiding the current searchbox that is already used in the website',
        placeholder: 'div#currentSelector',
      },
    },
    {
      field: 'manuelSelectorShadowRoots',
      options: {
        propName: 'shadowRootElementsForTrigger',
      },
    },
    {
      field: 'triggerSelector',
      options: {
        label: 'Manuel Trigger Selector',
        tooltip: true,
        tooltipContent:
          'The selector in the website that can trigger our searchbox apart from the input element.',
        placeholder: 'div#triggerSelector',
      },
    },
    {
      field: 'openingDirection',
      options: {
        label: 'Opening Direction',
        tooltip: true,
        tooltipContent:
          'The position of the searchbox according to input area.',
      },
    },
    {
      field: 'rockScoreFrameType',
      options: {
        label: 'Rock Score Time Frame',
      },
    },
  ],
  buttons: [
    { field: 'advancedConfigButton' },
    { field: 'goTest' },
    { field: 'goLive' },
  ],
};
