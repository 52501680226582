"use strict";

import React from "react";
import { connect } from "react-redux";
import { Creatable } from "react-select";

import { decodeHtml } from "../../system/string";

import Icon from "../icon";
import Icons from "../icons";

import { t } from "../../system/ui";

class ExcludesSearchKeyword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keywords: [],
      keyword: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.setDefaults = this.setDefaults.bind(this);
  }

  componentDidMount() {
    if (typeof this.props.values.keyword !== "undefined") {
      this.setDefaults();
    }
  }

  onInputChange(value) {
    return value;
  }
  createOption = (label) => ({
      label,
      keywords: label,
  });
  handleKeyDown(event){
    event.preventDefault();
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    switch (event.key) {
        case 'Enter':
        case 'Tab':
            this.setState({
                inputValue: '',
                value: [...value, this.createOption(inputValue)],
            });
    }
  };

  onChange(selectedValue) {
    let selectedKeyword =
      typeof selectedValue !== "undefined"
        ? selectedValue
        : this.state.keyword;
    this.setState({ keyword: selectedKeyword });
    this.props.onExclusionSelectChange(
      "keyword",
      selectedKeyword,
      this.props.id
    );
  }

  setDefaults() {
    let selectedKeywords = [];

    this.props.values.keyword.value.forEach(_keyword => {
        selectedKeywords.push({
        value: _keyword,
        title: decodeHtml(_keyword),
        label: decodeHtml(_keyword)
      });
    });

    this.setState({
      keyword: selectedKeywords
    });
  }

  render() {
    return (
      <li className="wizard-criterion" ref="inputs">
        <span className="criteria-type">
          <Icon name="tag" color="#787774" />
        </span>
        <div className="wizard-criterion-text">
          <h3 className="wizard-criterion-title">{t("Keyword")}</h3>
          <p className="wizard-criterion-description">
            {t(
              "Exclude the keywords you don't want showing up in your recommendation."
            )}
          </p>
        </div>
        <span className="wizard-product-select-container">
          <Creatable
              value={this.state.keyword}
              name="keywordId"
              clearable={false}
              searchable={true}
              onChange={this.onChange}
              className="criteria-field criteria-field-category"
              multi={true}
              openOnClick={false}
              onInputChange={this.onInputChange}
              shouldKeyDownEventCreateNewOption={e => {
                  switch (e.keyCode) {
                      case 9:
                      case 13:
                          return !0;
                      default:
                          return !1;
                  }
              }}
          />
        </span>
        <a
          onClick={this.props.onExclusionRemove.bind(null, this.props.id)}
          className="cancel-action recommend-filter-cancel"
        >
          <Icons name="crossCircle" />
        </a>
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  excludes: store.searchWizard.excludes
});

export default connect(mapStatesToProps)(ExcludesSearchKeyword);
