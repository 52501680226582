import React from "react";
import connect from "react-redux/es/connect/connect";
import {convertTableCellData} from "../../utils/table-utils";
import SgfDivTableCell from "./sgf-div-table-cell";
import classNames from "classnames";

import { sgfDivTableActions } from "actions";

class SgfDivTable extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
      sgfDivTableActions.clearSort();
    }

    render() {
      return (
        <div className={classNames("sgf-div-table", this.props.className)}>
          <div className={"sgf-div-table__head"}>
            {
              this.props.headers.map((header, index) => {
                header.tableName = this.props.tableName;
                return (
                  <SgfDivTableCell key={index} headClass={"flex-color"} className={"head__item"}  type={"head-item"} data={header}/>
                )
              })
            }
          </div>

          <div className={"sgf-div-table-body"}>
            {
              this.props.data.map((item, itemIndex) => {
                let rows = [];
                rows.push(
                  <div key={itemIndex} className={"sgf-div-table__row"}>
                    {
                      this.props.headers.map((header, headerIndex) => {
                        let cellData = convertTableCellData(header, item, this.props.tableName);
                        cellData.className = header.className;
                        return (
                          <SgfDivTableCell key={itemIndex + "_" + headerIndex} className={"cell"} type={"cell"} data={cellData}/>
                        )
                      })
                    }
                  </div>
                );

                return rows;
              })
            }
          </div>
        </div>

        );
    }
}

export default connect()(SgfDivTable);
