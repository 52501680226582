import React, { useState, useContext, useEffect, useCallback } from 'react';
import { browserHistory } from 'react-router';
import { t } from '../../../../system/ui';
import { Context as ProductBundleContext } from '../store/productBundleProvider';
import { bundleModalStates, modalStateDetails } from '../constants/constants';
import { modalActions } from '../../../../actions';

import BundleTypeModal from './bundleType';
import BundleProduct from './bundleProduct';
import BundleCategory from './bundleCategory';

const getInitialBundleState = (bundleFor, bundleType) => {
  if (bundleFor === 'main-item') {
    if (bundleType === 'product') {
      return bundleModalStates.PRODUCT_MAIN_ITEM;
    }
    if (bundleType === 'category') {
      return bundleModalStates.CATEGORY_MAIN_ITEM;
    }
  }

  if (bundleFor === 'bundle-item') {
    if (bundleType === 'product') {
      return bundleModalStates.PRODUCT_BUNDLE_ITEM;
    }
    if (bundleType === 'category') {
      return bundleModalStates.CATEGORY_BUNDLE_ITEM;
    }
  }

  return bundleModalStates.BUNDLE_TYPE;
};

/**
 * @name BundleModalManager
 * @description This component is responsible for managing the state of the bundle modal
 * @param {string} bundleFor - main-item or bundle-item
 * @param {string} bundleType - product or category
 * @param {object} bundleItem - bundle item object
 * @param {boolean} isEditMode - is edit mode
 * @return {JSX.Element}
 */
const BundleModalManager = ({
  bundleFor,
  bundleType = '',
  bundleItem = {},
  isEditMode = false,
}) => {
  const {
    state: { activeBundleDetails },
    setActiveBundleDetails,
    editActiveBundleDetails,
  } = useContext(ProductBundleContext);

  const [selectedBundleType, setSelectedBundleType] = useState(bundleType);
  const [selectedBundleItem, setSelectedBundleItem] = useState(null);
  const [bundleState, setBundleState] = useState(
    getInitialBundleState(bundleFor, bundleType),
  );
  const [isContinueButtonEnabled, setIsContinueButtonEnabled] = useState(false);

  useEffect(() => {
    if (bundleFor === 'main-item' && bundleType === '') {
      setActiveBundleDetails({
        id: '',
        mainItem: {},
        subItems: [],
      });
    }
  }, []);

  const AddSelectedBundleItem = () => {
    const newBundleItem = { ...selectedBundleItem, shouldBeDetailed: true };
    delete newBundleItem.hasError;

    if (Object.keys(bundleItem).length > 0) {
      newBundleItem.index = bundleItem.index;
      newBundleItem.exist = true;
      editActiveBundleDetails({ type: bundleFor, item: newBundleItem });
      return;
    }

    switch (bundleState) {
      case bundleModalStates.PRODUCT_MAIN_ITEM:
      case bundleModalStates.CATEGORY_MAIN_ITEM:
        setActiveBundleDetails({
          mainItem: newBundleItem,
        });
        break;
      case bundleModalStates.PRODUCT_BUNDLE_ITEM:
      case bundleModalStates.CATEGORY_BUNDLE_ITEM:
        setActiveBundleDetails({
          subItems: [
            ...activeBundleDetails.subItems,
            { ...newBundleItem, index: activeBundleDetails.subItems.length },
          ],
        });
        break;
      default:
        break;
    }
  };

  const continueHandler = () => {
    AddSelectedBundleItem();
    if (bundleFor === 'main-item') {
      if (selectedBundleType === 'product') {
        setBundleState(bundleModalStates.PRODUCT_MAIN_ITEM);
      }
      if (selectedBundleType === 'category') {
        setBundleState(bundleModalStates.CATEGORY_MAIN_ITEM);
      }
    }

    if (bundleFor === 'bundle-item') {
      if (selectedBundleType === 'product') {
        setBundleState(bundleModalStates.PRODUCT_BUNDLE_ITEM);
      }
      if (selectedBundleType === 'category') {
        setBundleState(bundleModalStates.CATEGORY_BUNDLE_ITEM);
      }
    }

    setSelectedBundleItem(null);

    if (bundleState !== bundleModalStates.BUNDLE_TYPE) {
      modalActions.closeModal();
      if (!isEditMode) {
        browserHistory.push('/dynamic_bundle/automated-bundles/add');
      }
    }
  };

  const activeModalComponent = useCallback(() => {
    const commonProps = {
      onChange: setSelectedBundleItem,
    };

    if (
      selectedBundleType === 'product' &&
      Object.keys(bundleItem).length > 0
    ) {
      commonProps.productProp = bundleItem;
    }

    if (
      selectedBundleType === 'category' &&
      Object.keys(bundleItem).length > 0
    ) {
      commonProps.categoryProp = bundleItem;
    }

    switch (bundleState) {
      case bundleModalStates.BUNDLE_TYPE:
        return <BundleTypeModal onChange={setSelectedBundleType} />;
      case bundleModalStates.CATEGORY_MAIN_ITEM:
        return <BundleCategory {...commonProps} isMainItem />;
      case bundleModalStates.CATEGORY_BUNDLE_ITEM:
        return <BundleCategory {...commonProps} />;
      case bundleModalStates.PRODUCT_MAIN_ITEM:
      case bundleModalStates.PRODUCT_BUNDLE_ITEM:
        return <BundleProduct {...commonProps} />;
      default:
        return null;
    }
  }, [bundleState]);

  const stateValidationControl = () => {
    const isSubItemExist = activeBundleDetails.subItems.some(
      item => item.key === selectedBundleItem?.key,
    );
    switch (bundleState) {
      case bundleModalStates.BUNDLE_TYPE:
        return selectedBundleType;
      case bundleModalStates.CATEGORY_MAIN_ITEM:
      case bundleModalStates.CATEGORY_BUNDLE_ITEM:
        return selectedBundleItem?.key && !selectedBundleItem?.hasError;
      case bundleModalStates.PRODUCT_MAIN_ITEM:
      case bundleModalStates.PRODUCT_BUNDLE_ITEM:
        return (
          selectedBundleItem?.key !== activeBundleDetails?.mainItem?.key &&
          !isSubItemExist &&
          selectedBundleItem?.key
        );
      default:
        return false;
    }
  };

  useEffect(() => {
    setIsContinueButtonEnabled(stateValidationControl());
  });
  return (
    <>
      <div className='bundle-modal-header'>
        {modalStateDetails[bundleState].title && (
          <h3 className='modal-content-title' id='modal-title'>
            {t(modalStateDetails[bundleState].title)}
          </h3>
        )}
        {modalStateDetails[bundleState].description && (
          <p className='modal-content-subtitle'>
            {t(modalStateDetails[bundleState].description)}
          </p>
        )}
      </div>
      <div className='bundle-product-modal'>
        <div className='bundle-product-modal-content'>
          {activeModalComponent()}
        </div>
        <div className='continue-button'>
          <button
            className='tertiary-action'
            type='button'
            onClick={() => continueHandler()}
            disabled={!isContinueButtonEnabled}
          >
            {t(modalStateDetails[bundleState].buttonText)}
          </button>
        </div>
      </div>
    </>
  );
};

export default BundleModalManager;
