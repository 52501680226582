import React from 'react';
import { t } from '../../../system/ui';
import { Calendar, Select } from '../../fields';
import classes from './SFYScheduling.module.scss';
import {
  isBeforeNowByTimeZone,
  isValidStartDate,
  periods,
  reformatDateByTimeZone,
} from '../../../system/date';

const defaults = {
  schedule: {
    type: 'one_time',
    period: 'every_day',
    periodList: null,
  },
};

const SelectTime = ({
  editCampaign,
  componentDetail,
  activeEmailDetails,
  readOnly = false,
}) => {
  const handleDateChange = date => {
    let tempDate;
    if (activeEmailDetails.schedule.type === 'periodic') {
      tempDate = date.valueOf();
    } else {
      tempDate = date.valueOf();
      const isBeforeNow = isBeforeNowByTimeZone(tempDate);

      if (isBeforeNow) {
        tempDate = new Date().getTime();
      }
    }

    editCampaign('date', tempDate);
  };

  const getFixedValue = () => {
    if (activeEmailDetails?.schedule?.date) {
      return reformatDateByTimeZone(activeEmailDetails.schedule.date);
    }
    const fifteenMinAfterFromNow = new Date().getTime() + 15 * 60 * 1000;
    return reformatDateByTimeZone(fifteenMinAfterFromNow);
  };

  const renderDatepicker = repeatType => {
    switch (repeatType) {
      case 'one_time':
        return (
          <label
            className={`item item-stacked label-emphasise label-mail ${classes['sfy-scheduling-repeat']}`}
          >
            <span className={classes['sfy-label-wrapper']}>
              <span className={classes['sfy-label']}>{t('Date')}</span>
            </span>
            <Calendar
              name='date'
              className={`item-field ${classes['sfy-scheduling-repeat']} ${classes['sfy-border']}`}
              checkPastDate
              value={getFixedValue()}
              isValidDate={isValidStartDate}
              onChange={handleDateChange}
              disabled={readOnly}
            />
          </label>
        );
      case 'periodic':
        return (
          <>
            <label
              className={`item item-stacked label-emphasise label-mail ${classes['sfy-scheduling-periodic']}`}
            >
              <span className={classes['sfy-label-wrapper']}>
                <span className={classes['sfy-label']}>
                  {t('Period (Every)')}
                </span>
              </span>
              <div className={classes['sfy-datepicker']}>
                <Select
                  name='period'
                  className={`item-stacked ${classes['sfy-scheduling-periodic']} ${classes['sfy-select']} ${classes['sfy-border']}`}
                  value={activeEmailDetails?.schedule?.period}
                  options={periods}
                  onChange={e => editCampaign('period', e.value)}
                  // specialLabel='Every'
                  multi={false}
                  disabled={readOnly}
                />
              </div>
            </label>
            <label
              className={`item item-stacked label-emphasise label-mail ${classes['sfy-scheduling-periodic']}`}
            >
              <span className={classes['sfy-label-wrapper']}>
                <span className={classes['sfy-label']}>{t('Hour')}</span>
              </span>
              <div className={classes['sfy-datepicker']}>
                <Calendar
                  name='date'
                  className={`item-field ${classes['sfy-scheduling-periodic']} ${classes['sfy-border']}`}
                  dateFormat={false}
                  isValidDate={isValidStartDate}
                  onChange={handleDateChange}
                  value={reformatDateByTimeZone(
                    activeEmailDetails?.schedule?.date,
                    'HH:mm',
                  )}
                  disabled={readOnly}
                />
              </div>
            </label>
          </>
        );
      case 'now':
        return null;
      default:
        return null;
    }
  };
  return <>{renderDatepicker(activeEmailDetails?.schedule?.type)}</>;
};

export default SelectTime;
