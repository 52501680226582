import { appEnv } from './config';

const reCaptchaPublicKey = {
  prod: '6LcumdcaAAAAAOyJFY1vtuuz69BVBXb-1_HT7vJZ',
  dev: '6LddDzQbAAAAAGSOe40aupc4OnWOfANCoeK-d6YD',
  search: '6Les9tYaAAAAADFk-0o7UepAvxqKUiFRvr5wiIoZ',
  local: '6Les9tYaAAAAADFk-0o7UepAvxqKUiFRvr5wiIoZ',
  qa: '6LcFnNcaAAAAAKB_ug6mrmeMWIaqXFh3ECAZBCG-',
};

const getReCaptchaPublicKey = () => reCaptchaPublicKey[appEnv()];

export default getReCaptchaPublicKey;
