/**
 * Created by mehmetyurtar on 3/22/18.
 */
import React, { Component } from "react";
class IconCheckboxEmpty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 25"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Intelligent-Modal"
            transform="translate(-582.000000, -531.000000)"
            fill="#E3EBF1"
            fillRule="nonzero"
          >
            <g id="Modal" transform="translate(29.000000, 135.000000)">
              <g id="Group-2" transform="translate(0.000000, 173.000000)">
                <g
                  id="Smart-Offers-Copy-2"
                  transform="translate(0.000000, 146.000000)"
                >
                  <g
                    id="ui-16px-1_check-square-09"
                    transform="translate(553.000000, 77.000000)"
                  >
                    <path
                      d="M21.875,0 L3.125,0 C1.3984375,0 0,1.3984375 0,3.125 L0,21.875 C0,23.6015625 1.3984375,25 3.125,25 L21.875,25 C23.6015625,25 25,23.6015625 25,21.875 L25,3.125 C25,1.3984375 23.6015625,0 21.875,0 Z"
                      id="Shape"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconCheckboxEmpty;
