import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/tooltip';

const CardWrapper = ({
  id,
  title,
  description,
  children,
  tooltipText,
  headerRightComponent,
  extraClasses,
  removeOlParentClass
}) => {
  const rootClass = `wizard wizard-cards faceted-search-card-wrapper ${extraClasses}`;
  return (
    <div id={id} className={rootClass}>
      <div className={!removeOlParentClass ? "widget page-content-block" : ""}>
        <ol className="form-elements">
          <div className="wizard-comp">
            <div className="wizard-comp-title-wrapper">
              <h3 className="wizard-comp-title">
                {title}
                {tooltipText && (
                  <span className="field-tooltip-icon">
                    <Tooltip content={tooltipText} alignment="left">
                      <i className="icon-info" role="presentation">
                        <span className="for-screenreader-only" />
                      </i>
                    </Tooltip>
                  </span>
                )}
              </h3>
              {headerRightComponent && headerRightComponent}
            </div>
            <p className="widget-title-desc">{description}</p>
            <li className="wizard-input-type-wrapper">
              <div className="wizard-criteria">
                <div className="wizard-items-container">{children}</div>
              </div>
            </li>
          </div>
        </ol>
      </div>
    </div>
  );
};

CardWrapper.defaultProps = {
  tooltipText: null,
  headerRightComponent: null,
  extraClasses: null,
  removeOlParentClass: false,
};

CardWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  headerRightComponent: PropTypes.element,
  extraClasses: PropTypes.string,
  removeOlParentClass: PropTypes.bool,
};

export default CardWrapper;
