import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { SortableHandle, arrayMove } from 'react-sortable-hoc';

import { t } from '../../system/ui';
import { isLoaded, isLoading } from '../../actions/ui';
import {
  searchProducts,
  postFileToGetProducts,
} from '../../modules/product/ajax';
import { decodeHtml } from '../../system/string';
import Icon from '../icon';
import Icons from '../icons';
import Tooltip from '../tooltip';
import {
  DragHandler,
  DraggableItemWrap,
  DraggableList,
} from './criterion-product-draggable';

class CriterionProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autocompleteVisible: true,
      products: [],
      criterion: '',
      productId: '',
      selected: false,
      preloader: '',
      hasError: false,
      showRequiredError: false,
      selectedOption: '',
      inputValue: '',
      noResultsText: 'Type at least 3 characters.',
      isSelectLoading: false,
      isSearchable: true,
    };

    this.setDefaults = this.setDefaults.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.closeAutocomplete = this.closeAutocomplete.bind(this);
    this.validate = this.validate.bind(this);
    this.setStaticProducts = this.setStaticProducts.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputKeyDown = this.onInputKeyDown.bind(this);
    this.searchProducts = this.searchProducts.bind(this);
    this.onSelectBlur = this.onSelectBlur.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.timeout = false;
    this.timer = null;
  }

  componentDidMount() {
    this.setStaticProducts(this.props);
  }

  setStaticProducts(newProps) {
    let defaultCriterion = '';
    let defaultProductId = '';
    if (Object.keys(newProps.values).length > 0) {
      defaultCriterion = newProps.values.criterion
        ? newProps.values.criterion.value
        : '';
      defaultProductId = newProps.values.productId
        ? newProps.values.productId.value
        : '';
      const products = [];
      if (defaultProductId !== '') {
        newProps.staticProducts.forEach(product => {
          if (product) {
            products.push({
              value: product.productId,
              title: `${product.productId} - ${decodeHtml(product.name)}`,
              label: decodeHtml(product.name),
            });
          }
        });
        this.setState({
          products,
          selected: true,
          selectedOption: products,
        });

        this.setDisableSearch(products);
      } else {
        this.setDefaults(defaultCriterion, defaultProductId);
      }
    } else {
      this.validate();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.validate === true) {
      this.validate();
    }
    if (newProps.staticProducts !== this.props.staticProducts) {
      this.setStaticProducts(newProps);
    }
  }

  componentDidUpdate(oldProps, oldStates) {
    if (oldStates.productId !== this.state.productId) {
      this.props.onCriteriaChange(this.refs.inputs, this.props.id);
      this.validate();
    }
  }

  setDefaults(defaultCriterion, defaultProductId) {
    this.validate();
  }

  closeAutocomplete() {
    setTimeout(() => {
      if (this.state.selected) {
        this.setState({
          autocompleteVisible: false,
        });
      } else {
        this.setState({
          criterion: '',
          productId: '',
          autocompleteVisible: false,
        });
      }
    }, 250);
  }

  selectProduct(product) {
    this.setState({
      criterion: `${product.productId} - ${product.name}`,
      productId: product.productId,
      autocompleteVisible: false,
      selected: true,
    });
  }

  onChange(e) {
    let keyword = '';
    let name = '';

    clearTimeout(this.timeout);

    if (Object.keys(this.props.values).length > 0) {
      name = this.props.values.criterion
        ? this.props.values.criterion.value
        : '';
      keyword = this.props.values.productId
        ? this.props.values.productId.value
        : '';
    }

    if (typeof e !== 'undefined') {
      keyword = e.currentTarget.value;
      name = e.currentTarget.value;
    }

    this.setState({
      criterion: name.replace(`${this.refs.productId.value} - `, ''),
      selected: false,
    });

    if (keyword.length > 2) {
      this.timeout = setTimeout(() => {
        this.setState({
          preloader: 'isLoading',
        });

        searchProducts(keyword, response => {
          this.setState({
            products: response,
            autocompleteVisible: true,
            preloader: 'isLoaded',
          });
        });
      }, 500);
    }
  }

  searchProducts(value) {
    if (value.length > 2) {
      this.setState(
        {
          isSelectLoading: true,
          noResultsText: 'Loading',
        },
        () => {
          searchProducts(value, response => {
            const productOptions = [];
            if (response) {
              response.forEach(product => {
                productOptions.push({
                  value: product.productId,
                  title: `${product.productId} - ${decodeHtml(product.name)}`,
                  label: decodeHtml(product.name),
                });
              });
            }
            this.setState({
              products: productOptions,
              isSelectLoading: false,
              noResultsText: 'No Results Found',
            });
          });
        },
      );
    } else {
      this.setState({
        products: [],
        noResultsText: 'Type at least 3 characters.',
      });
    }
  }

  onInputKeyDown() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.searchProducts(this.state.inputValue);
    }, 1000);
  }

  validate() {
    const input = this.state.selectedOption;

    if (input.length < 1) {
      this.setState({
        hasError: true,
        showRequiredError: true,
      });
    } else {
      this.setState({
        hasError: false,
        showRequiredError: false,
      });
    }
  }

  setDisableSearch(selectedOption) {
    if (this.props.isPushModule) {
      if (selectedOption.length > 0) this.setState({ isSearchable: false });
      else this.setState({ isSearchable: true });
    }
  }

  handleChange(selectedOption) {
    this.setState({ selectedOption, products: [] }, () => {
      this.validate();
      this.props.onSelectCriteriaChange(this.props.id, selectedOption);
    });

    this.setDisableSearch(selectedOption);
  }

  valueRenderer(option) {
    return <DragHandler title={option.title}>{option.label}</DragHandler>;
  }

  // Function for setting array on drag
  onSortEnd({ oldIndex, newIndex }) {
    const newSelectedOption = arrayMove(
      this.state.selectedOption,
      oldIndex,
      newIndex,
    );
    this.setState({ selectedOption: newSelectedOption, products: [] }, () => {
      this.validate();
      this.props.onSelectCriteriaChange(this.props.id, newSelectedOption);
    });
  }

  onInputChange(value) {
    this.setState({
      inputValue: value,
    });
  }

  onSelectBlur() {
    this.setState({
      products: [],
      noResultsText: 'Type at least 3 characters.',
    });
  }

  handleFileUpload(e) {
    e.preventDefault();

    isLoading();
    const file = e.target.files[0];
    postFileToGetProducts(file, response => {
      if (response.length > 0) {
        const hasSelectedOption = !!this.state.selectedOption;
        let existingProducts = [];
        let idListOfExitingProducts = [];
        if (hasSelectedOption) {
          existingProducts = this.state.selectedOption;
          idListOfExitingProducts = this.state.selectedOption.map(
            product => product.value,
          );
        }
        const uploadedProducts = response
          .map(product => {
            if (!idListOfExitingProducts.includes(product.productId)) {
              return {
                value: product.productId,
                title: `${product.productId} - ${decodeHtml(product.name)}`,
                label: decodeHtml(product.name),
              };
            }
            return null;
          })
          .filter(product => product !== null);

        this.handleChange([...existingProducts, ...uploadedProducts]);
      }
      isLoaded();
      // clear the file input for next upload
      e.target.value = '';
    });
  }

  render() {
    const DragHandle = SortableHandle(() => (
      <span className='draghandler'>
        <Icons name='burger' color='#e8e7f2' />
      </span>
    ));
    const value = this.state.selectedOption;
    return (
      <li className='wizard-criterion' ref='inputs'>
        <DragHandle />

        <span className='criteria-type'>
          <Tooltip content={t('Static (Product)')} alignment='left'>
            <Icon name='product' />
          </Tooltip>
        </span>

        <span className='wizard-product-name wizard-product-select-container'>
          <DraggableList
            axis='xy'
            shouldCancelStart={() => value && value.length < 2}
            onSortEnd={props => this.onSortEnd(props)}
            useDragHandle
            helperClass='draggable-dragging'
          >
            <Select
              name='form-field-name'
              value={value}
              onChange={this.handleChange}
              onInputChange={this.onInputChange}
              onInputKeyDown={this.onInputKeyDown}
              options={this.state.products}
              searchable={this.state.isSearchable}
              multi
              ref='productId'
              clearable={false}
              placeholder={t('Search Product')}
              valueRenderer={this.valueRenderer}
              valueComponent={DraggableItemWrap}
              openOnClick={false}
              isLoading={this.state.isSelectLoading}
              noResultsText={t(this.state.noResultsText)}
              onBlur={this.onSelectBlur}
            />
          </DraggableList>
        </span>

        <Tooltip
          className='tooltip-file-upload'
          content={t('Upload product list')}
          alignment='left'
        >
          <label className='product-file-upload'>
            <input
              type='file'
              accept='.csv,.txt'
              onChange={e => this.handleFileUpload(e)}
            />
            <Icon name='upload' />
          </label>
        </Tooltip>

        <a
          onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
          className={
            this.props.isOnlySelection
              ? 'cancel-action cancel-action--disabled'
              : 'cancel-action'
          }
        >
          <Icons name='crossCircle' />
        </a>

        {this.state.showRequiredError ? (
          <span
            className='item-error has-error'
            style={{ display: 'block', marginTop: '10px' }}
          >
            {t('You should select at least one product from suggestions')}
          </span>
        ) : (
          ''
        )}
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  criteria: store.wizard.criteria,
  validate: store.ui.validate,
  user: store.user.user,
});

export default connect(mapStatesToProps)(CriterionProduct);
