import React from 'react';
import PropTypes from 'prop-types';
import { Select, TextField, Checkbox } from '../../../../components/fields';
import { timingOptions, frequencyOptions } from '../constants';
import { t } from '../../../../system/ui';

const TimingFrequency = ({
  timingState,
  frequencyState,
  fadeOutState = null,
  delayAllPages,
  onChangeTiming,
  onChangeFrequency,
  onChangeDelay,
  onChangeFadeOut = null,
}) => {
  const handleChangeTiming = (val, type) => {
    if (type === 'type') {
      const param = ['DELAY', 'SCROLL'].includes(val) ? '3' : '';
      onChangeTiming({ type: val, param });
    } else if (type === 'param') {
      onChangeTiming({ type: timingState.type, param: val });
    } else if (type === 'fadeOut') {
      if (!val || val < 0) {
        onChangeFadeOut(0);
      } else if (fadeOutState === 0) {
        onChangeFadeOut(val.slice(1));
      } else {
        onChangeFadeOut(parseInt(val));
      }
    }
  };

  const handleChangeFrequency = (val, type) => {
    if (type === 'type') {
      const param = ['DAYS'].includes(val) ? '3' : '';
      onChangeFrequency({ type: val, param });
    } else if (type === 'param') {
      onChangeFrequency({ type: frequencyState.type, param: val });
    }
  };

  return (
    <div className='form-element'>
      <span className='element-title'>{t('Timing Frequency')}</span>
      <div className='timing-frequency'>
        <div className='element-row-field'>
          <div className='element-row-field fade-out'>
            <span className='element-column-label secondary-label'>
              {t('Timing')}
            </span>
            <div className='element-row-field'>
              <Select
                name='timing'
                className='element-select'
                value={timingState.type}
                options={timingOptions}
                onChange={e => handleChangeTiming(e.value, 'type')}
                clearable={false}
              />
            </div>
          </div>

          {fadeOutState !== null && onChangeFadeOut !== null && (
            <div className='element-row-field fade-out'>
              <span className='element-column-label secondary-label'>
                {t('Fade Out Time')}
              </span>
              <div className='element-row-field'>
                <TextField
                  title='Fade Out Time'
                  name='input'
                  type='number'
                  label={t('sec')}
                  labelPlace='after'
                  value={fadeOutState}
                  onChange={e => handleChangeTiming(e.target.value, 'fadeOut')}
                />
              </div>
            </div>
          )}
        </div>

        {timingState.type === 'DELAY' && (
          <>
            <TextField
              className='fade-out-delay'
              name='timingParam'
              label={t('seconds')}
              labelPlace='after'
              inputClassName='x-small'
              value={timingState.param}
              onChange={e => handleChangeTiming(e.target.value, 'param')}
            />
            <Checkbox
              name='delayAllPages'
              label={t('Keep on page change')}
              checked={delayAllPages}
              onChange={() => onChangeDelay(!delayAllPages)}
            />
          </>
        )}
        {timingState.type === 'SCROLL' && (
          <TextField
            name='timingParam'
            label='%'
            labelPlace='after'
            inputClassName='x-small'
            value={timingState.param}
            onChange={e => handleChangeTiming(e.target.value, 'param')}
          />
        )}
      </div>

      <div className='element-column-field'>
        <span className='element-column-label secondary-label'>
          {t('Frequency')}
        </span>
        <div className='element-row-field'>
          <Select
            name='frequency'
            className='element-select'
            value={frequencyState.type}
            options={frequencyOptions}
            onChange={e => handleChangeFrequency(e.value, 'type')}
            clearable={false}
          />
          {frequencyState.type === 'DAYS' && (
            <TextField
              name='frequencyParam'
              label={t('days')}
              labelPlace='after'
              inputClassName='x-small'
              value={frequencyState.param}
              onChange={e => handleChangeFrequency(e.target.value, 'param')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

TimingFrequency.propTypes = {
  timingState: PropTypes.shape({
    type: PropTypes.string.isRequired,
    param: PropTypes.string.isRequired,
  }).isRequired,
  frequencyState: PropTypes.shape({
    type: PropTypes.string.isRequired,
    param: PropTypes.string.isRequired,
  }).isRequired,
  delayAllPages: PropTypes.bool.isRequired,
  onChangeTiming: PropTypes.func.isRequired,
  onChangeFrequency: PropTypes.func.isRequired,
  onChangeDelay: PropTypes.func.isRequired,
  onChangeFadeOut: PropTypes.func.isRequired,
};

export default TimingFrequency;
