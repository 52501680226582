import { t } from '../../../../system/ui';
import { behaviouralTargetingParams } from '../../../../constants/datamaps/wizard';

const getDynamicBreadCrumbOptions = currentName => {
  return {
    home: {
      name: t('Engagement'),
      icon: 'behaviouralTargeting',
      slug: '/behavioural-targeting/engagement/view-all',
      iconSvg: true,
    },
    current: {
      name: currentName,
      slug: '/behavioural-targeting/engagement/Countdown/list',
    },
  };
};

const campaignTitleConstant = {
  popup: 'Popup Banner Countdown',
  hero: 'Hero Banner Countdown',
  notification: 'Notification Bar Countdown',
  // these are the campaign types for the React router mode for backend based
  POP_UP_BANNER_COUNTDOWN: 'popup',
  NOTIFICATION_BAR_COUNTDOWN: 'notification',
  HERO_BANNER_COUNTDOWN: 'hero',
};

const listTitleConstant = {
  POP_UP_BANNER_COUNTDOWN: 'Pop-Up Banner',
  NOTIFICATION_BAR_COUNTDOWN: 'Notification Bar',
  HERO_BANNER_COUNTDOWN: 'Hero Banner',
};

const campaignCreateTypeConstant = {
  popup: 'POP_UP_BANNER_COUNTDOWN',
  hero: 'HERO_BANNER_COUNTDOWN',
  notification: 'NOTIFICATION_BAR_COUNTDOWN',
};
const selectCampaignType = (campaignType, listTitle) => {
  if (listTitle) {
    return listTitleConstant[campaignType];
  }
  return campaignTitleConstant[campaignType];
};

const createCardConstant = [
  {
    title: 'Popup Banner Countdown',
    description: 'We have special offers until 01 d : 02 h : 03 m : 04 sec',
    icon: 'plus',
    type: 'popup',
  },
  {
    title: 'Hero Banner Countdown',
    description: 'We have special offers until 01 d : 02 h : 03 m : 04 sec',
    icon: 'plus',
    type: 'hero',
  },
  {
    title: 'Notification Bar Countdown',
    description: 'We have special offers until 01 d : 02 h : 03 m : 04 sec',
    icon: 'plus',
    type: 'notification',
  },
];

const countDownAdvancedConfig = {
  activeTabs: [
    { key: 'html', label: 'Edit HTML' },
    { key: 'preJs', label: 'Edit PreJS' },
    { key: 'postJs', label: 'Edit PostJS' },
    { key: 'css', label: 'Edit CSS' },
  ],
  params: behaviouralTargetingParams,
};
const initialCountDown = {
  type: '',
  targetUrl: '',
  name: '',
  title: '',
  imageUrl: '',
  description: '',
  endDate: '',
  seperator: ':',
  showOnWebsite: [
    {
      type: 'DAY',
      activate: false,
      text: 'days',
    },
    {
      type: 'HOUR',
      activate: false,
      text: 'hours',
    },
    {
      type: 'MINUTE',
      activate: false,
      text: 'minute',
    },
    {
      type: 'SECOND',
      activate: false,
      text: 'sec',
    },
  ],
  bgColor: '#000',
  textColor: '#fff',
  image: '',
  status: 'ACTIVE',
  devices: [],
  startDate: '',
  timing: {
    type: 'IMMEDIATE',
    param: '',
  },
  frequency: {
    type: 'ALWAYS',
    param: '',
  },
  targetSelector: '',
  targetPosition: 'SELF',
  filters: [],
  overlay: false,
  sticky: false,
  verticalPosition: 'middle',
  horizontalPosition: 'center',
  preJs:
    'function preRenderConf(conf) { /* add your custom code inside this function */ }',
  postJs: '',
  accountId: '',
  html: '',
  css: '',
};

export {
  getDynamicBreadCrumbOptions,
  campaignTitleConstant,
  selectCampaignType,
  createCardConstant,
  listTitleConstant,
  countDownAdvancedConfig,
  initialCountDown,
  campaignCreateTypeConstant,
};
