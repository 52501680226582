import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { TextField } from '../fields';
import { convertToPositiveInt, operatorObjToArray } from './utils/utils';
import CurrencyIcon from './currencyIcon';

import {
  numberOperators,
  operatorColumns,
} from '../../constants/datamaps/rule-based-segmentation';

/**
 * @name CriteriaFilterTotalRevenue
 * @description Component to filter total revenue
 * @param {object} criteriaData - The criteria data
 * @param {string} filterType - The filter type
 * @param {array} statements - The statements
 * @param {function} callbackFunc - The callback function
 * @returns {JSX.Element}
 */
const criteriaFilterTotalRevenue = ({
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const defaultFilterData = {
    dataType: criteriaData?.dataType,
    column: operatorColumns.totalRevenueOperator,
    operator: numberOperators.equals.value,
    value: '',
  };

  const [totalRevenue, setTotalRevenue] = useState();
  const [totalRevenueMax, setTotalRevenueMax] = useState(); // Using only with between operator
  const [selectedOperator, setSelectedOperator] = useState('');

  useEffect(() => {
    const tempStatements = statements?.[filterType];

    if (tempStatements?.length > 1) {
      // Between operator
      tempStatements.forEach(statement => {
        if (statement.operator === numberOperators.gte.value) {
          setTotalRevenue(statement.value);
        } else if (statement.operator === numberOperators.lte.value) {
          setTotalRevenueMax(statement.value);
        }
      });
      setSelectedOperator(numberOperators.between.value);
    } else if (tempStatements?.length === 1) {
      setTotalRevenue(tempStatements[0].value);
      setSelectedOperator(tempStatements[0].operator);
    } else {
      setSelectedOperator(defaultFilterData.operator);
      setTotalRevenue(defaultFilterData.value);
    }
  }, [statements]);

  const triggerCallbackFunc = (val, type) => {
    const columnVal = operatorColumns.totalRevenueOperator;
    const operatorVal = type === 'operator' ? val.value : selectedOperator;
    const revenueVal =
      type === 'totalRevenue'
        ? convertToPositiveInt(val.target.value, 0)
        : totalRevenue;
    let revenueValMax =
      type === 'totalRevenueMax'
        ? convertToPositiveInt(val.target.value, 0)
        : totalRevenueMax;

    if (type === 'operator' && val.value !== numberOperators.between.value) {
      revenueValMax = null;
    }

    const filterData = [
      {
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator:
          operatorVal === numberOperators.between.value
            ? numberOperators.gte.value
            : operatorVal,
        value: revenueVal,
      },
    ];
    if (operatorVal === numberOperators.between.value) {
      filterData.push({
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator: numberOperators.lte.value,
        value: revenueValMax,
      });
    }

    callbackFunc(filterType, filterData);
  };

  const onChange = (selectedValue, type) => {
    switch (type) {
      case 'totalRevenue':
        setTotalRevenue(convertToPositiveInt(selectedValue.target.value, 0));
        break;
      case 'totalRevenueMax':
        setTotalRevenueMax(convertToPositiveInt(selectedValue.target.value, 0));
        break;
      case 'operator':
        setSelectedOperator(selectedValue?.value);
        break;
      default:
        break;
    }

    triggerCallbackFunc(selectedValue, type);
  };

  return (
    <div
      className={`rbs-filter-field ${
        selectedOperator === numberOperators.between.value ? 'three-row' : ''
      }`}
    >
      <div className='filter-operator'>
        <span className='filter-operator-title'>Operator*</span>
        <Select
          value={selectedOperator}
          options={operatorObjToArray(numberOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          onChange={e => onChange(e, 'operator')}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      <div className='filter-connector' />
      <div className='filter-operator totalRevenue-operator'>
        <span className='filter-operator-title'>Total Revenue*</span>
        <TextField
          value={totalRevenue}
          onChange={e => onChange(e, 'totalRevenue')}
          type='number'
          required
          placeholder={
            selectedOperator === numberOperators.between.value ? 'min' : ''
          }
          renderProp={() => <CurrencyIcon />}
        />
        {selectedOperator === numberOperators.between.value && (
          <>
            <div className='filter-multi-connector' />
            <TextField
              value={totalRevenueMax}
              onChange={e => onChange(e, 'totalRevenueMax')}
              type='number'
              required
              placeholder='max'
              renderProp={() => <CurrencyIcon />}
              validation={totalRevenue ? { minNumber: totalRevenue } : {}}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default criteriaFilterTotalRevenue;
