import React from 'react';
import ChooseUserTypes from './user-management/ChooseUserTypes';
import SelectUserInfo from './user-management/SelectUserInfo';
import { SuperUserRefObject } from './user-management/constants';

/**
 * @name CreateUserModal
 * @description - CreateUserModal component is a wrapper component for ChooseUserTypes & SelectUserInfo
 * @param adminType
 * @returns {JSX.Element}
 */
const CreateUserModal = ({ adminType }) => {
  const [selectedUserType, setSelectedUserType] = React.useState('');

  return (
    <div className='user-management-modal create-modal-info'>
      {adminType !== 'none' ? (
        <>
          <ChooseUserTypes
            adminType={adminType}
            selectUserType={setSelectedUserType}
            selectedUserType={selectedUserType}
          />
          {selectedUserType && (
            <SelectUserInfo
              {...SuperUserRefObject[selectedUserType]}
              key={selectedUserType}
              selectedUserType={selectedUserType}
            />
          )}
        </>
      ) : (
        <SelectUserInfo
          {...SuperUserRefObject['Account User']}
          selectedUserType='Account User'
        />
      )}
    </div>
  );
};

export default CreateUserModal;
