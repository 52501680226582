import React from "react";

class IconPermission extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={this.state.width}
        height={this.state.height}
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-6.000000, -3.000000)"
            fill={this.state.color}
            fillRule="nonzero"
          >
            <path
              d="M14.9261231,22.8771269 C13.3374303,22.8771269 12.0495404,21.589237 12.0495404,20.0005442 C12.0495404,18.4118515 13.3374303,17.1239615 14.9261231,17.1239615 C16.5148158,17.1239615 17.8027058,18.4118515 17.8027058,20.0005442 C17.8001635,21.5881828 16.5137616,22.8745847 14.9261231,22.8771269 Z M14.9261231,17.9089038 C14.0778463,17.9089059 13.3131841,18.4201437 12.9889957,19.2040285 C12.6648072,19.9879134 12.845002,20.8899123 13.4454853,21.4890729 C14.0459686,22.0882335 14.9483627,22.2664389 15.7315307,21.9405228 C16.5146988,21.6146066 17.0242492,20.848819 17.0223808,20.0005442 C17.0198346,18.8446167 16.0820535,17.908901 14.9261231,17.9089038 Z M8.71122692,26.6032942 C7.4678471,26.6007541 6.46052088,25.5934279 6.45798077,24.3500481 L6.45798077,15.6510404 C6.46052088,14.4076606 7.4678471,13.4003344 8.71122692,13.3977942 L8.94209231,13.3977942 L8.94209231,9.24221731 C9.00276898,5.99469767 11.6784047,3.40783693 14.9261231,3.45673077 C18.1792306,3.39751641 20.8654649,5.98456667 20.9286231,9.2376 L20.9286231,10.1610615 L20.1252115,10.1610615 L20.1252115,9.2376 C20.067128,6.42247434 17.7413766,4.18553936 14.9261231,4.23705577 C12.1100843,4.18551849 9.7830083,6.42174152 9.72241731,9.2376 L9.72241731,13.3931769 L21.1410192,13.3931769 C22.3843991,13.395717 23.3917253,14.4030432 23.3942654,15.6464231 L23.3942654,24.3500481 C23.3917253,25.5934279 22.3843991,26.6007541 21.1410192,26.6032942 L8.71122692,26.6032942 Z M8.71122692,14.1781192 C7.89775503,14.1781192 7.23830577,14.8375685 7.23830577,15.6510404 L7.23830577,24.3500481 C7.23830577,25.16352 7.89775503,25.8229692 8.71122692,25.8229692 L21.1410192,25.8229692 C21.5324616,25.8241963 21.9082922,25.6695559 22.1855179,25.3931965 C22.4627436,25.1168371 22.6185596,24.7414924 22.6185577,24.3500481 L22.6185577,15.6510404 C22.6185577,14.8375685 21.9591085,14.1781192 21.1456365,14.1781192 L8.71122692,14.1781192 Z"
              id="permission-prompt"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default IconPermission;
