import React from 'react';
import PropTypes from 'prop-types';
import Currency from '../currency';
import { t } from '../../system/ui';

const CompareProduct = ({
  firstColumnData,
  secondColumnData,
  getCompareIcon,
}) => {
  const price =
    firstColumnData && firstColumnData.record && firstColumnData.record.price;
  const oldPrice =
    firstColumnData &&
    firstColumnData.record &&
    firstColumnData.record.oldPrice;
  const secondPrice =
    secondColumnData &&
    secondColumnData.record &&
    secondColumnData.record.price;
  const secondOldPrice =
    secondColumnData &&
    secondColumnData.record &&
    secondColumnData.record.oldPrice;

  return (
    <div className='one-whole'>
      <ul className='one-half trendify-compare-stats'>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Price')}</h4>
          </div>
          <div className='compare-stat-container one-half'>
            <Currency currency={price || 0} />
            <span className='price-compare-product'>
              <Currency currency={oldPrice || 0} />
            </span>
          </div>
        </li>
      </ul>
      <ul className='one-half trendify-compare-stats'>
        <li className='trendify-compare-stat'>
          <div className='compare-stat-container one-half'>
            <h4>{t('Price')}</h4>
          </div>
          <div className='compare-stat-container one-half'>
            <Currency currency={secondPrice || 0} />
            <span className='price-compare-product'>
              <Currency currency={secondOldPrice || 0} />
            </span>
            {getCompareIcon().currency(secondPrice, price)}
          </div>
        </li>
      </ul>
    </div>
  );
};

CompareProduct.defaultProps = {
  firstColumnData: { record: { oldPrice: 0, price: 0 } },
  secondColumnData: { record: { oldPrice: 0, price: 0 } },
};

CompareProduct.propTypes = {
  firstColumnData: PropTypes.shape({
    record: PropTypes.shape({
      oldPrice: PropTypes.string,
      price: PropTypes.string,
    }),
  }),
  secondColumnData: PropTypes.shape({
    record: PropTypes.shape({
      oldPrice: PropTypes.string,
      price: PropTypes.string,
    }),
  }),
  getCompareIcon: PropTypes.func.isRequired,
};

export default CompareProduct;
