import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uiActions, filterActions } from '../../actions';
import { getRangedEmailReportInterval } from '../../modules/emails/ajax';
import { getRangedPushReportInterval } from '../../modules/push/ajax';
import { getRangedReportInterval } from '../../modules/reports/ajax';
import { reduceBasketByZ } from '../../system/reducer';
import { t } from '../../system/ui';

import ReportClickChart from './chart.click';
import ReportPurchaseChart from './chart.purchase';
import ReportConversionChart from './chart.contribution';

class GraphRecommendationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: [],
      graphType: "click"
    };

    this.getReport = this.getReport.bind(this);
    this.changeGraphType = this.changeGraphType.bind(this);
  }

  componentDidMount() {
    this.getReport();
  }

  changeGraphType(chartType) {
    this.setState({
      graphType: chartType
    });
  }

  getReport() {
    uiActions.isLoading();
    let boundaries = {};
    boundaries.start = this.props.range[0].format("YYYY-MM-DD");
    boundaries.end = this.props.range[1].format("YYYY-MM-DD");
    let timeFrame = "monthly";
    if (this.props.range[1] && this.props.range[0]) {
      let timeDiff = this.props.range[1] - this.props.range[0];
      if (timeDiff < 24 * 60 * 60 * 1000) {
        timeFrame = "no_aggregate";
      } else if (timeDiff < 90 * 24 * 60 * 60 * 1000) {
        timeFrame = "daily";
      }
    }
    if (this.props.isEmailCampaign) {
      getRangedEmailReportInterval(
        this.props.campaign.instanceId,
        boundaries,
        timeFrame,
        campaignReport => {
          this.setState({
            stats: campaignReport
          });
          filterActions.filtersUpdated();
          filterActions.filtersReset();
          uiActions.isLoaded();
        }
      );
    } else if (this.props.isPushCampaign) {
      getRangedPushReportInterval(
        this.props.campaign.instanceId,
        boundaries,
        timeFrame,
        campaignReport => {
          const report =
            this.props.campaign.instanceId === 'ABANDONED_CART'
              ? reduceBasketByZ([
                  campaignReport.ABANDONED_CART_FIRST_REMINDER,
                  campaignReport.ABANDONED_CART_SECOND_REMINDER,
                  campaignReport.ABANDONED_CART_ALTERNATIVE_PRODUCTS,
                  campaignReport.ABANDONED_CART_COUPON,
                ])
              : campaignReport;

          this.setState({
            stats: report,
          });
          filterActions.filtersUpdated();
          filterActions.filtersReset();
          uiActions.isLoaded();
        },
      );
    } else {
      getRangedReportInterval(
        this.props.instanceId,
        boundaries,
        timeFrame,
        campaignReport => {
          this.setState({
            stats: campaignReport
          });
          filterActions.filtersUpdated();
          filterActions.filtersReset();
          uiActions.isLoaded();
        }
      );
    }
  }

  getFieldsToHide() {
    if (this.props.isEmailCampaign || this.props.isPushCampaign) {
      let fields = [];
      if (this.props.campaign.type !== "POPUP_RECOMMENDATION") {
        fields.push("Widget View");
        fields.push("Click");
        fields.push("Impression");
      }
      return fields;
    }
  }
  addExtraFields() {
    let fields = [];
    if (this.props.isEmailCampaign) {
      fields.push({
        name: "Email [sent]",
        label: "Sent",
        isVisible: true,
        type: "line",
        yAxis: 0
      });
      fields.push({
        name: "Email [opened]",
        label: "Opens",
        isVisible: true,
        type: "line",
        yAxis: 0
      });
      fields.push({
        name: "Email [clicked]",
        label: "Click",
        isVisible: true,
        type: "line",
        yAxis: 0
      });
    }

    if (this.props.isPushCampaign) {
      fields.push({
        name: "Notification [sent]",
        label: "Sent",
        isVisible: true,
        type: "line",
        yAxis: 0
      });
      fields.push({
        name: "Notification [shown]",
        label: "View",
        isVisible: true,
        type: "line",
        yAxis: 0
      });
      fields.push({
        name: "Notification [clicked]",
        label: "Click",
        isVisible: true,
        type: "line",
        yAxis: 0
      });
    }

    return fields;
  }

  render() {
    return (
      <div className="report-compare one-whole" style={{ minWidth: "932px" }}>
        <div className="report--graph__buttons">
          <ul>
            <li
              className={
                this.state.graphType === "click"
                  ? "secondary-action selected"
                  : "report--graph__button"
              }
              onClick={this.changeGraphType.bind(this, "click")}
            >
              {t("Conversion Graph")}
            </li>
            <li
              className={
                this.state.graphType === "purchase"
                  ? "secondary-action selected"
                  : "report--graph__button"
              }
              onClick={this.changeGraphType.bind(this, "purchase")}
            >
              {t("Revenue Graph")}
            </li>
            {!this.props.isEmailCampaign && !this.props.isPushCampaign && (
              <li
                className={
                  this.state.graphType === "contribution"
                    ? "secondary-action selected"
                    : "report--graph__button"
                }
                onClick={this.changeGraphType.bind(this, "contribution")}
              >
                {t("Contribution Graph")}
              </li>
            )}
          </ul>
        </div>
        {this.state.graphType === "click" && (
          <ReportClickChart
            stats={this.state.stats}
            fieldsToHide={this.getFieldsToHide()}
            emailFields={this.addExtraFields()}
          />
        )}
        {this.state.graphType === "purchase" && (
          <ReportPurchaseChart stats={this.state.stats} />
        )}
        {this.state.graphType === "contribution" &&
          !this.props.isEmailCampaign &&
          !this.props.isPushCampaign && (
            <ReportConversionChart
              stats={this.state.stats}
              trends={this.state.stats}
              fieldsToHide={["purchase:count", "purchase:items"]}
              isCampaign={true}
            />
          )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias
});

export default connect(mapStatesToProps)(GraphRecommendationModal);
