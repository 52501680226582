"use strict";

import { createAction } from "../system/store";
import * as sgfDivTableActions from "../constants/actions/sgf-div-table";


export const clickCell = (cellItem) => {
  createAction(sgfDivTableActions.default.CLICK_CELL, {cellItem});
};
export const clearSort = () => {
  createAction(sgfDivTableActions.default.CLEAR_SORT, { });
};

export default{
  clickCell,
  clearSort
}