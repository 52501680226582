import React from 'react';
import classNames from 'classnames';
import { t } from "../../system/ui";

export const Modal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div className={classNames("modal", props.modalClassName)}>
      <div className="modal-cover" />
      <div className="modal-content">
        {props.icon ? (
          <div
            className="modal-header modal-header-iconic"
            ref="contentHeader"
          >
            <div className="modal-header-icon">
              <Icons
                name={props.icon}
                width="40"
                height="40"
                color="#787779"
              />
            </div>
            <div className="modal-header-text">
              {props.title ? (
                <h3 className="modal-content-title" id="modal-title">
                  {props.title}
                </h3>
              ) : (
                  ""
                )}

              {props.subtitle ? (
                <p className="modal-content-subtitle">
                  {props.subtitle}
                </p>
              ) : (
                  ""
                )}
            </div>
          </div>
         ) : (
            <div className="modal-header">
              {props.title ? (
                <h3 className="modal-content-title" id="modal-title">
                  {t(props.title)}
                </h3>
              ) : (
                  ""
                )}

              {props.subtitle ? (
                <p className="modal-content-subtitle">
                  {t(props.subtitle)}
                </p>
              ) : (
                  ""
                )}
            </div>
          )}
          {props.children}

          <a className="modal-close" onClick={props.close}>
          X
          </a>
      </div>
    </div>
  );
}
export default Modal;


Modal.defaultProps = {
  title: '',
};
