/**
 * Created by mehmetyurtar on 10/18/17.
 */
import React from "react";

class IconRemoveUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12,0C8.691,0,6,2.691,6,6v2c0,3.309,2.691,6,6,6s6-2.691,6-6V6	C18,2.691,15.309,0,12,0z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21,20c-2.419,0-4.436-1.718-4.899-4H7c-3.859,0-7,3.14-7,7v1h24v-1	c0-1.26-0.339-2.44-0.925-3.463C22.442,19.828,21.743,20,21,20z"
        />
        <rect
          x="18"
          y="14"
          fillRule="evenodd"
          clipRule="evenodd"
          width="6"
          height="2"
        />
      </svg>
    );
  }
}

export default IconRemoveUser;
