import get from 'lodash/get';
import React from 'react';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';

import { getDashboardUrl } from '../../constants/config';
import getReCaptchaPublicKey from '../../constants/reCaptcha.config';
import {
  getUserCurrent,
  internalLogin,
  isSuperUser,
  login,
} from '../../modules/auth/user';
import cookie, { select, set } from '../../system/cookie';
import { t } from '../../system/ui';
import { SEGMENTIFY_TOKEN_V3 } from '../admin/constants';
import { TextField } from '../fields';
import LoginExceptionHandler from './login-exception-handler';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: true,
      password: '',
      email: '',
      checked: '',
      isPageLoading: true,
      unexpectedError: false,
    };
    this.changeShowHide = this.changeShowHide.bind(this);
    this.onChangeParams = this.onChangeParams.bind(this);
    this.checkCurrent = this.checkCurrent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.rememberMe = this.rememberMe.bind(this);
  }

  componentDidMount() {
    this.checkCurrent();
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { email, password } = this.state;
    const { executeRecaptcha } = this.props.googleReCaptchaProps;

    if (!executeRecaptcha) {
      console.log('Recaptcha has not been loaded');
      return;
    }

    const token = await executeRecaptcha('login');

    const userPayload = {
      email,
      password,
      recaptchaObj: {
        siteKey: getReCaptchaPublicKey(),
        token,
        recaptchaAction: 'LOGIN',
      },
    };

    await login(userPayload)
      .then(({ env, response }) => {
        // UserGuiding Identify
        if (response.uType === 'INTERNAL') {
          this.getOtherTokensForInternalUsers(
            env,
            userPayload,
            response.token,
            this.continueToNewPage,
            response,
          );
        } else {
          this.rememberMe(response.token);
          this.continueToNewPage(env, response);
        }
      })
      .catch(err => {
        LoginExceptionHandler({
          err,
          setState: this.setState.bind(this),
          browserHistory,
        });
      });
  }

  onChangeParams(e) {
    const { type, checked, value, name } = e.target;
    const stateValue = type === 'checkbox' ? checked : value;

    this.setState({
      [name]: stateValue,
    });
  }

  getOtherTokensForInternalUsers(env, userPayload, _token, cb, _rsp) {
    internalLogin(env, userPayload).then(response => {
      const { tokens } = response;
      let V3token;
      if (tokens.length > 0) {
        V3token = tokens.find(item => item.env === 'prod');
      }

      if (V3token || env === 'prod') {
        this.rememberMeForInternalUsers(
          SEGMENTIFY_TOKEN_V3,
          !V3token ? _token : V3token.response.token,
        );
        this.rememberMeForInternalUsers(
          'SegmentifyAuthenticationToken',
          !V3token ? _token : V3token.response.token,
        );
      }
      cb(env, _rsp);
    });
  }

  rememberMeForInternalUsers(cookieName, token) {
    if (window.location.hostname.includes('localhost')) {
      set(cookieName, token, 365, '/');
    } else if (this.state.checked) {
      set(cookieName, token, 365, '/', '.segmentify.com');
    } else {
      set(cookieName, token, 30, '/', '.segmentify.com');
    }
  }

  rememberMe(token) {
    if (window.location.hostname.includes('localhost')) {
      set('SegmentifyAuthenticationToken', token, 365, '/');
    } else if (this.state.checked) {
      set('SegmentifyAuthenticationToken', token, 365, '/', '.segmentify.com');
    } else {
      set('SegmentifyAuthenticationToken', token, 30, '/', '.segmentify.com');
    }
  }

  changeShowHide() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  checkCurrent() {
    const token = cookie.get(select());
    if (token) {
      getUserCurrent(token, (isError, response, url) => {
        if (!isError) {
          if (window.location.hostname.includes('localhost')) {
            window.location.pathname = '/admin/accounts';
          } else if (url.includes('sauron')) {
            window.location.replace(getDashboardUrl());
          } else {
            let redirectDomain = '';
            try {
              const panelVersion = get(response, 'account.panelVersion');
              if (panelVersion) {
                if (panelVersion === 'V2') {
                  redirectDomain = 'https://panelv2.segmentify.com';
                } else if (response.account.type === 'CONTENT') {
                  redirectDomain = 'https://content.segmentify.com';
                }
                if (window.location.hostname.includes('localhost')) {
                  window.location.pathname = '/admin/accounts';
                } else {
                  window.location.replace(redirectDomain);
                }
              }
            } catch (error) {}
          }
        } else {
          console.error('Error while requesting current user', response);
          this.setState({
            isPageLoading: false,
          });
        }
      });
    } else {
      this.setState({
        isPageLoading: false,
      });
    }
  }

  continueToNewPage(env, response) {
    let redirectDomain = '';
    switch (env) {
      case 'panel': {
        if (response.panelVersion === 'V2') {
          redirectDomain = 'https://panelv2.segmentify.com';
        }
        break;
      }
      case 'content': {
        if (response.panelVersion === 'V2') {
          redirectDomain = 'https://panelv2.segmentify.com';
        } else {
          redirectDomain = 'https://content.segmentify.com';
        }
        break;
      }
      default:
        redirectDomain = getDashboardUrl();
        break;
    }
    if (window.location.hostname.includes('localhost')) {
      if (isSuperUser()) {
        window.location.pathname = '/admin/accounts';
      } else {
        window.location.pathname = '/admin/accounts';
      }
    } else {
      window.location.replace(redirectDomain);
    }
  }

  render() {
    const type = this.state.showPassword ? 'password' : 'text';
    return (
      <>
        <div className='lgn-container'>
          {this.state.isPageLoading && (
            <div className='page-loading-container'>
              <div className='page-progress' />
              <span className='please-wait-text'>Please Wait</span>
            </div>
          )}
          {!this.state.isPageLoading && (
            <div className='login-container'>
              <h1 className='has-text-align-center'>{t('Login')}</h1>
              {this.state.error && (
                <p>{t('Invalid email or password. Please try again. (1)')}</p>
              )}
              {this.state.unexpectedError && <p>{t('Unexpected Error')}</p>}
              <form id='login-form' method='POST' onSubmit={this.handleSubmit}>
                <div id='email-field' className='input-field'>
                  <TextField
                    id='email'
                    name='email'
                    className='labelClass'
                    type='email'
                    isSearch
                    inputClassName='inputClassName'
                    label={t('E-Mail')}
                    isPush={this.state.showPassword}
                    value={this.state.email}
                    onChange={this.onChangeParams}
                  />
                </div>

                <div id='password-field' className='input-field password-field'>
                  <TextField
                    id='password'
                    name='password'
                    className='labelClass'
                    type={type}
                    isSearch
                    inputClassName='inputClassName'
                    label={t('Password')}
                    value={this.state.password}
                    onChange={this.onChangeParams}
                    renderProp={() => (
                      <div className='password-toggle'>
                        {this.state.showPassword ? (
                          <span
                            className='pass-show pass-icon'
                            onClick={this.changeShowHide}
                          >
                            {t('Show')}
                          </span>
                        ) : (
                          <span
                            className='pass-hide pass-icon'
                            onClick={this.changeShowHide}
                          >
                            {t('Hide')}
                          </span>
                        )}
                      </div>
                    )}
                  />
                </div>

                <div className='input-field checkbox-field'>
                  <label className='seg-checkbox'>
                    <input
                      type='checkbox'
                      checked={this.state.checked}
                      onClick={this.onChangeParams}
                      name='checked'
                    />{' '}
                    {t('Remember Me')}
                  </label>
                  <Link
                    className='move-to-end'
                    to={`/forgot-password/?email=${this.state.email}`}
                  >
                    {t('Forgot Password')}?
                  </Link>
                </div>

                <div id='submit-field' className='input-field'>
                  <button id='login' type='submit'>
                    {t('Login')}
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </>
    );
  }
}

const WithGoogleRecaptchaLoginForm = withGoogleReCaptcha(LoginForm);

const mapStateToProps = store => ({
  switchedUser: store.switchedUser,
  user: store.user,
  language: store.ui.language,
  modal: store.modal.modal,
  showModal: store.modal.showModal,
});

export default connect(mapStateToProps)(WithGoogleRecaptchaLoginForm);
