/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import moment from "moment";
import classNames from "classnames";

import { t } from "../../system/ui";
import {
  getNow,
  reformatDate,
  isValidStartDate,
  isValidEndDate,
  isValidCampaignEndDate
} from "../../system/date";

import { uiActions } from "../../actions";

import { Calendar } from "../fields";

class WizardDateSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: getNow(),
      endDate: false,
      showEndDate: false
    };

    this.endDateChange = this.endDateChange.bind(this);
    this.startDateChange = this.startDateChange.bind(this);

    this.toggleEndDate = this.toggleEndDate.bind(this);
    this.setMailDates = this.setMailDates.bind(this);
  }

  componentDidMount() {
    if (this.props.isEmail) {
      this.setMailDates(this.props);
    }
  }

  setMailDates(newProps) {
    this.setState({
      startDate: moment(newProps.campaign.startDate),
      endDate: newProps.campaign.endDate
        ? moment(newProps.campaign.endDate)
        : false,
      showEndDate: !!newProps.campaign.endDate
    });
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      if (newProps.isEmail) {
        this.setMailDates(newProps);
      } else {
        this.setState({
          startDate: moment(
            newProps.campaign.limitations.activeDates.startDate
          ),
          endDate: newProps.campaign.limitations.activeDates.endDate
            ? moment(newProps.campaign.limitations.activeDates.endDate)
            : false,
          showEndDate: !!newProps.campaign.limitations.activeDates.endDate
        });
      }
    }
  }

  startDateChange(datetime) {
    this.setState({
      startDate: datetime
    });

    uiActions.formEdited();
  }

  endDateChange(datetime) {
    this.setState({
      endDate: datetime
    });

    uiActions.formEdited();
  }

  toggleEndDate() {
    this.setState({
      showEndDate: !this.state.showEndDate
    });
  }

  render() {
    let endDate,
      startDate = new Date(
        this.state.startDate.toDate().getTime() + 15 * 60000
      );
    if (this.state.endDate) {
      endDate = isValidEndDate(this.state.endDate, startDate)
        ? reformatDate(this.state.endDate)
        : reformatDate(moment(startDate));
    } else {
      endDate = reformatDate(moment(startDate));
    }
    return (
      <ol className="form-elements wizard-date-selection">
        <li>
          <Calendar
            label="Start Date - Time"
            value={reformatDate(this.state.startDate)}
            isValidDate={isValidStartDate}
            onChange={this.startDateChange}
            className="item-stacked one-whole"
          />
        </li>
        <li>
          <label className="item item-stacked is-checkbox">
            <input
              type="checkbox"
              checked={this.state.showEndDate}
              onChange={this.toggleEndDate}
            />
            <span className="item-label">{t("End Date - Time")}</span>
          </label>
          <Calendar
            className={classNames("item item-stacked one-whole", {
              "is-hidden": !this.state.showEndDate
            })}
            value={endDate}
            isValidDate={current => {
              return isValidCampaignEndDate(current, this.state.startDate);
            }}
            onChange={this.endDateChange}
          />
        </li>
      </ol>
    );
  }
}

export default WizardDateSelection;
