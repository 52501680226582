import React from 'react';
import ReactHighcharts from 'react-highcharts';
import FunnelChart from 'highcharts-funnel';
import * as modals from '../constants/datamaps/modals';
import { localeString, localeStringMoney } from '../system/string';
import { calculateRatio } from '../system/ratio';
import { t, openModal } from '../system/ui';
import { hasKlaviyoConfiguration } from '../modules/auth/user';

FunnelChart(ReactHighcharts.Highcharts);

const graphTitles = {
  revenue: t('Revenue Graph'),
  purchase: t('Purchase Graph'),
  page: t('Page Graph'),
  device: t('Device Graph'),
  audience: t('Audience Graph'),
  segmentify: t('Segmentify Graph'),
};

class FunnelView extends React.Component {
  constructor(props) {
    super(props);

    this.showDescriptionModal = this.showDescriptionModal.bind(this);
  }

  showDescriptionModal(graphType) {
    const ModalContent = modals[`${graphType}graph`];
    openModal({
      title: graphTitles[graphType],
      content: ModalContent,
    });
  }

  getPageStats() {
    const { stats } = this.props.pageStats.page;
    const toShowData = [];
    const dataColors = {
      homePage: '#1967D2',
      productPage: '#217FDD',
      categoryPage: '#2A97E8',
      basketPage: '#33AFE6',
      searchPage: '#3CC7E1',
      '404Page': '#45DFDB',
      checkoutPage: '#4EF7D5',
      other: '#66DFCA',
    };

    Object.keys(stats).forEach(function(stat) {
      if (stat.toLowerCase() !== 'other' && stat.toLowerCase() !== 'all') {
        toShowData.push({
          name: stats[stat].name,
          y: stats[stat].count,
          y_label: stats[stat].count,
          color: dataColors[stat],
        });
      }
    });
    if (stats.other) {
      toShowData.push({
        name: stats.other.name,
        y: stats.other.count,
        y_label: stats.other.count,
        color: dataColors.other,
      });
    }
    const config = {
      chart: {
        type: 'pie',
        height: 400,
        custom: {},
        events: {
          render() {
            const chart = this;
            const series = chart.series[0];
            let customLabel = chart.options.chart.custom.label;

            if (!customLabel) {
              customLabel = chart.renderer
                .label(
                  `Total<br/><strong>${localeString(stats.aLL.count)}</strong>`,
                )
                .css({
                  color: '#333',
                  textAnchor: 'middle',
                  fontFamily: 'montserrat, helvetica, arial, sans-serif',
                })
                .add();

              chart.options.chart.custom.label = customLabel;
            }

            const x = series.center[0] + chart.plotLeft;
            const y =
              series.center[1] + chart.plotTop - customLabel.attr('height') / 2;

            customLabel.attr({
              x,
              y,
            });
            // Set font size based on chart diameter
            customLabel.css({
              fontSize: `${series.center[2] / 12}px`,
            });
          },
        },
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>',
      },
      title: {
        text: 'Page View',
        align: 'left',
        x: 0,
        style: {
          fontSize: '1.125rem',
          fontFamily: 'montserrat, helvetica, arial, sans-serif',
          fontWeight: 'bold',
          color: '#78767a',
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        useHTML: true,
        itemStyle: {
          color: '#626061',
          fontFamily: 'inherit',
        },
        labelFormatter() {
          return `
          <div class="dashboard--funnel__halfbox__donut--legend">
            <span class="custom-donut-legend-symbol" style="background-color:${
              this.color
            };"></span>
            <div class="donut-title">${this.name}</div>
            <div class="donut-count-container">
              <span class="donut-count" style="color:${
                this.color
              }">${localeString(this.y)}</span>views
            </div>
          </div>`;
        },
        symbolWidth: 10,
        symbolHeight: 5,
        symbolPadding: 5,
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderRadius: 8,
          dataLabels: [
            {
              enabled: true,
              distance: 20,
              format: '{point.name}',
            },
            {
              enabled: true,
              distance: -15,
              format: '{point.percentage:.0f}%',
              style: {
                fontSize: '0.9em',
              },
            },
          ],
          showInLegend: true,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Pages',
          colorByPoint: true,
          type: 'pie',
          innerSize: '75%',
          data: toShowData,
        },
      ],
    };
    return config;
  }

  getDeviceStats() {
    const { stats } = this.props.stats.device;
    const allCount = localeString(this.props.stats.page.stats.aLL.count);
    const config = {
      chart: {
        type: 'pie',
        height: 400,
        custom: {},
        events: {
          render() {
            const chart = this;
            const series = chart.series[0];
            let customLabel = chart.options.chart.custom.label;

            if (!customLabel) {
              customLabel = chart.renderer
                .label(`Total<br/><strong>${allCount}</strong>`)
                .css({
                  color: '#333',
                  textAnchor: 'middle',
                  fontFamily: 'montserrat, helvetica, arial, sans-serif',
                })
                .add();

              chart.options.chart.custom.label = customLabel;
            }

            const x = series.center[0] + chart.plotLeft;
            const y =
              series.center[1] + chart.plotTop - customLabel.attr('height') / 2;

            customLabel.attr({
              x,
              y,
            });
            // Set font size based on chart diameter
            customLabel.css({
              fontSize: `${series.center[2] / 12}px`,
            });
          },
        },
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>',
      },
      title: {
        text: 'Device Type',
        align: 'left',
        x: 0,
        style: {
          fontSize: '1.125rem',
          fontFamily: 'montserrat, helvetica, arial, sans-serif',
          fontWeight: 'bold',
          color: '#78767a',
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        useHTML: true,
        itemStyle: {
          color: '#626061',
          fontFamily: 'inherit',
        },
        labelFormatter() {
          return `
          <div class="dashboard--funnel__halfbox__donut--legend">
            <span class="custom-donut-legend-symbol" style="background-color:${
              this.color
            };"></span>
            <div class="donut-title">${this.name}</div>
            <div class="donut-count-container">
              <span class="donut-count" style="color:${
                this.color
              }">${localeString(this.y)}</span>views
            </div>
          </div>`;
        },
        symbolWidth: 10,
        symbolHeight: 5,
        symbolPadding: 5,
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderRadius: 8,
          dataLabels: [
            {
              enabled: true,
              distance: 20,
              format: '{point.name}',
            },
            {
              enabled: true,
              distance: -15,
              format: '{point.percentage:.0f}%',
              style: {
                fontSize: '0.9em',
              },
            },
          ],
          showInLegend: true,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Device',
          colorByPoint: true,
          innerSize: '75%',
          data: [
            {
              name: 'Mobile',
              y: stats.mobileCount.count,
              color: '#1967D2',
            },
            {
              name: 'Desktop',
              y: stats.pCCount.count,
              color: '#4285F4',
            },
            {
              name: 'Tablet',
              y: stats.tabletCount.count,
              color: '#8AB4F7',
            },
          ],
          reversed: false,
        },
      ],
    };
    return config;
  }

  getAudienceFunnel() {
    const allPageCount = this.props.stats.page.stats.aLL.count;
    const productPageCount = this.props.stats.page.stats.productPage.count;
    const { stats } = this.props.stats.unique;
    const config = {
      chart: {
        type: 'pyramid',
      },
      title: {
        text: 'Audience',
        align: 'left',
        x: 0,
        style: {
          fontSize: '1.125rem',
          fontFamily: 'montserrat, helvetica, arial, sans-serif',
          fontWeight: 'bold',
          color: '#78767a',
        },
      },
      tooltip: {
        pointFormatter() {
          return ` (${localeString(this.options.visibleY)})`;
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            formatter() {
              return `<b>${this.point.name}</b> (${localeString(
                this.point.visibleY,
              )})`;
            },
            color: 'black',
            softConnector: true,
            style: {
              fontSize: '14px',
            },
          },
          center: ['30%', '50%'],
          width: '60%',
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Unique users',
          data: [
            { name: 'Page Views', y: 100, visibleY: allPageCount },
            { name: 'Product Views', y: 75, visibleY: productPageCount },
            { name: 'Sessions', y: 50, visibleY: stats.session.count },
            { name: 'Visitors', y: 25, visibleY: stats.visitor.count },
          ],
        },
      ],
    };

    return config;
  }

  getSegmentifyFunnel(module) {
    let targetTrend;
    let title;
    let cnfObj;
    if (module === 'email') {
      targetTrend = this.props.extraTrends;
    } else if (module === 'push') {
      targetTrend = this.props.pushTrends;
    } else {
      targetTrend = this.props.trends;
    }
    const impression = targetTrend.widget.stats.all.count;
    const view = targetTrend.widget.stats.view.count;
    const impressionrate = targetTrend.widget.stats.conversion.ratio;
    const interaction = targetTrend.interaction.stats.all.count;
    const ctr = targetTrend.interaction.stats.conversion.ratio;
    const basket = targetTrend.basket.stats.all.count;
    const purchase = targetTrend.revenue.stats.purchases.count;
    if (module === 'email') {
      title = 'Email Marketing Conversion';
      if (!hasKlaviyoConfiguration()) {
        cnfObj = [
          { name: 'Emails Sent', y: 125, visibleY: impression },
          { name: 'Opens', y: 125, visibleY: view },
          { name: 'Interaction', y: 125, visibleY: interaction },
          { name: 'Basket', y: 125, visibleY: basket },
          { name: 'Purchase', y: 125, visibleY: purchase },
        ];
      } else {
        const sentToFlow = targetTrend.widget.stats.klaviyo.flow;
        cnfObj = [
          { name: 'Triggered To Flow', y: 125, visibleY: sentToFlow },
          { name: 'Interaction', y: 125, visibleY: interaction },
          { name: 'Basket', y: 125, visibleY: basket },
          { name: 'Purchase', y: 125, visibleY: purchase },
        ];
      }
    } else if (module === 'push') {
      title = 'Web Push Conversion';
      cnfObj = [
        { name: 'Sent', y: 125, visibleY: impression },
        { name: 'Views', y: 125, visibleY: view },
        { name: 'Interaction', y: 125, visibleY: interaction },
        { name: 'Basket', y: 125, visibleY: basket },
        { name: 'Purchase', y: 125, visibleY: purchase },
      ];
    } else {
      title = 'Segmentify Widget Conversion';
      cnfObj = [
        { name: 'Impression', y: 125, visibleY: impression },
        { name: 'View', y: 125, visibleY: view },
        { name: 'Interaction', y: 125, visibleY: interaction },
        { name: 'Basket', y: 125, visibleY: basket },
        { name: 'Purchase', y: 125, visibleY: purchase },
      ];
    }
    const config = {
      chart: {
        type: 'pyramid',
      },
      title: {
        text: title,
        align: 'left',
        x: 0,
        style: {
          fontSize: '1.125rem',
          fontFamily: 'montserrat, helvetica, arial, sans-serif',
          fontWeight: 'bold',
          color: '#78767a',
        },
      },
      yAxis: {
        visible: true,
        min: 0,
        title: {
          enabled: false,
        },
        labels: {
          enabled: false,
        },
      },
      tooltip: {
        pointFormatter() {
          return ` (${localeString(this.options.visibleY)})`;
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            formatter() {
              return `<b>${this.point.name}</b> (${localeString(
                this.point.visibleY,
              )})`;
            },
            color: 'black',
            softConnector: true,
            style: {
              fontSize: '14px',
            },
          },
          center: ['30%', '50%'],
          width: '50%',
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Unique users',
          data: cnfObj,
          reversed: false,
        },
      ],
    };

    return config;
  }

  getRevenueStats() {
    const { symbolBefore } = this.props;
    const { symbol } = this.props;
    const purchases = this.props.stats.purchase.stats.count.count;
    const revenue = this.props.stats.purchase.stats.amount.count;
    const sgfPurchase = this.props.trends.revenue.stats.purchases.count;
    const sgfAmount = this.props.trends.revenue.stats.all.count;
    const mailAmount = this.props.extraTrends.revenue.stats.all.count;
    const pushAmount = this.props.pushTrends.revenue.stats.all.count;
    const config = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Revenue',
        align: 'left',
        x: 0,
        style: {
          fontSize: '1.125rem',
          fontFamily: 'montserrat, helvetica, arial, sans-serif',
          fontWeight: 'bold',
          color: '#78767a',
        },
      },
      xAxis: {
        visible: false,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: null,
        },
        min: 0,
        showFirstLabel: true,
        allowDecimals: false,
        labels: {
          formatter() {
            if (symbolBefore) {
              return `${symbol} ${localeString(this.value)}`;
            }
            return `${localeString(this.value)} ${symbol}`;
          },
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 2,
        },
      },
      series: [
        {
          name: 'Your Website',
          data: [{ name: '', y: revenue }],
          color: '#67dfca',
          dataLabels: {
            enabled: true,
            color: '#47bbaa',
            align: 'center',
            formatter() {
              if (symbolBefore) {
                return `${symbol} ${localeStringMoney(this.y)}`;
              }
              return `${localeStringMoney(this.y)} ${symbol}`;
            },
            y: -26, // 10 pixels down from the top
            style: {
              fontSize: '18px',
              textShadow: null,
              textOutline: false,
            },
          },
        },
      ],
    };

    if (this.props.hasRecommendation) {
      config.series.push({
        name: 'Segmentify',
        data: [
          {
            name: '',
            y: sgfAmount,
            ratio: calculateRatio(sgfAmount, revenue),
          },
        ],
        color: '#49b4d3',
        dataLabels: {
          enabled: true,
          color: '#3395b2',
          align: 'center',
          formatter() {
            if (symbolBefore) {
              return `${symbol} ${localeStringMoney(
                this.y,
              )}<br />(<span style="font-weight:normal">${
                this.point.ratio
              }%</span>)`;
            }
            return (
              `${localeStringMoney(
                this.y,
              )}<br />(<span style="font-weight:normal">${
                this.point.ratio
              }%</span>)` + ` ${symbol}`
            );
          },
          y: -52, // 10 pixels down from the top
          style: {
            fontSize: '18px',
            textShadow: false,
            textOutline: false,
          },
        },
      });
    }

    if (this.props.hasEmail) {
      config.series.push({
        name: 'E-Mail',
        data: [
          {
            name: '',
            y: mailAmount,
            ratio: calculateRatio(mailAmount, revenue),
          },
        ],
        color: '#e4d361',
        dataLabels: {
          enabled: true,
          color: '#e4d361',
          align: 'center',
          formatter() {
            if (symbolBefore) {
              return `${symbol} ${localeStringMoney(
                this.y,
              )}<br />(<span style="font-weight:normal">${
                this.point.ratio
              }%</span>)`;
            }
            return (
              `${localeStringMoney(
                this.y,
              )}<br />(<span style="font-weight:normal">${
                this.point.ratio
              }%</span>)` + ` ${symbol}`
            );
          },
          y: -52, // 10 pixels down from the top
          style: {
            fontSize: '18px',
            textShadow: false,
            textOutline: false,
          },
        },
      });
    }

    if (this.props.hasPush) {
      config.series.push({
        name: 'Web Push',
        data: [
          {
            name: '',
            y: pushAmount,
            ratio: calculateRatio(pushAmount, revenue),
          },
        ],
        color: '#4c4c52',
        dataLabels: {
          enabled: true,
          color: '#4c4c52',
          align: 'center',
          formatter() {
            if (symbolBefore) {
              return `${symbol} ${localeStringMoney(
                this.y,
              )}<br />(<span style="font-weight:normal">${
                this.point.ratio
              }%</span>)`;
            }
            return (
              `${localeStringMoney(
                this.y,
              )}<br />(<span style="font-weight:normal">${
                this.point.ratio
              }%</span>)` + ` ${symbol}`
            );
          },
          y: -52, // 10 pixels down from the top
          style: {
            fontSize: '18px',
            textShadow: false,
            textOutline: false,
          },
        },
      });
    }
    return config;
  }

  getPurchaseStats() {
    const { symbolBefore } = this.props;
    const { symbol } = this.props;
    const purchases = this.props.stats.purchase.stats.count.count;
    const revenue = this.props.stats.purchase.stats.amount.count;
    const sgfPurchase = this.props.trends.revenue.stats.purchases.count;
    const sgfAmount = this.props.trends.revenue.stats.all.count;
    const mailPurchase = this.props.extraTrends.revenue.stats.purchases.count;
    const pushPurchase = this.props.pushTrends.revenue.stats.purchases.count;
    const config = {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Purchase',
        align: 'left',
        x: 0,
        style: {
          fontSize: '1.125rem',
          fontFamily: 'montserrat, helvetica, arial, sans-serif',
          fontWeight: 'bold',
          color: '#78767a',
        },
      },
      xAxis: {
        visible: false,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        title: {
          text: null,
        },
        min: 0,
        showFirstLabel: true,
        allowDecimals: false,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 2,
        },
      },
      series: [
        {
          name: 'Your Website',
          data: [{ name: '', y: purchases }],
          color: '#67dfca',
          dataLabels: {
            enabled: true,
            color: '#47bbaa',
            align: 'center',
            formatter() {
              return localeString(this.y);
            },
            y: -26, // 10 pixels down from the top
            style: {
              fontSize: '18px',
              textShadow: null,
              textOutline: false,
            },
          },
        },
      ],
    };

    if (this.props.hasRecommendation) {
      config.series.push({
        name: 'Segmentify',
        data: [
          {
            name: '',
            y: sgfPurchase,
            ratio: calculateRatio(sgfPurchase, purchases),
          },
        ],
        color: '#49b4d3',
        dataLabels: {
          enabled: true,
          color: '#3395b2',
          align: 'center',
          formatter() {
            return `${localeString(
              this.y,
            )}<br />(<span style="font-weight:normal">${
              this.point.ratio
            }%</span>)`;
          },
          y: -52, // 10 pixels down from the top
          style: {
            fontSize: '18px',
            textShadow: false,
            textOutline: false,
          },
        },
      });
    }

    if (this.props.hasEmail) {
      config.series.push({
        name: 'E-Mail',
        data: [
          {
            name: '',
            y: mailPurchase,
            ratio: calculateRatio(mailPurchase, purchases),
          },
        ],
        color: '#e4d361',
        dataLabels: {
          enabled: true,
          color: '#e4d361',
          align: 'center',
          formatter() {
            return `${localeString(
              this.y,
            )}<br />(<span style="font-weight:normal">${
              this.point.ratio
            }%</span>)`;
          },
          y: -52, // 10 pixels down from the top
          style: {
            fontSize: '18px',
            textShadow: false,
            textOutline: false,
          },
        },
      });
    }

    if (this.props.hasPush) {
      config.series.push({
        name: 'Web Push',
        data: [
          {
            name: '',
            y: pushPurchase,
            ratio: calculateRatio(pushPurchase, purchases),
          },
        ],
        color: '#4c4c52',
        dataLabels: {
          enabled: true,
          color: '#4c4c52',
          align: 'center',
          formatter() {
            return `${localeString(
              this.y,
            )}<br />(<span style="font-weight:normal">${
              this.point.ratio
            }%</span>)`;
          },
          y: -52, // 10 pixels down from the top
          style: {
            fontSize: '18px',
            textShadow: false,
            textOutline: false,
          },
        },
      });
    }
    return config;
  }

  render() {
    return (
      <div className='dashboard--funnel'>
        <div className='dashboard--funnel__container one-whole'>
          <div className='dashboard--funnel__halfbox'>
            <div
              onClick={this.showDescriptionModal.bind(null, 'revenue')}
              className='dashboard--funnel__info'
            >
              <i className='icon-info' role='presentation' />
            </div>
            <ReactHighcharts config={this.getRevenueStats()} />
          </div>
          <div className='dashboard--funnel__halfbox'>
            <div
              onClick={this.showDescriptionModal.bind(null, 'purchase')}
              className='dashboard--funnel__info'
            >
              <i className='icon-info' role='presentation' />
            </div>
            <ReactHighcharts config={this.getPurchaseStats()} />
          </div>
        </div>
        <div className='dashboard--funnel__container one-whole'>
          <div className='dashboard--funnel__halfbox'>
            <div
              onClick={this.showDescriptionModal.bind(null, 'page')}
              className='dashboard--funnel__info'
            >
              <i className='icon-info' role='presentation' />
            </div>
            <ReactHighcharts config={this.getPageStats()} />
          </div>
          <div className='dashboard--funnel__halfbox'>
            <div
              onClick={this.showDescriptionModal.bind(null, 'device')}
              className='dashboard--funnel__info'
            >
              <i className='icon-info' role='presentation' />
            </div>
            <ReactHighcharts config={this.getDeviceStats()} />
          </div>
        </div>
        <div className='dashboard--funnel__container one-whole'>
          <div
            className='dashboard--funnel__halfbox'
            style={{ width: this.props.hasRecommendation ? '48%' : '100%' }}
          >
            <div
              onClick={this.showDescriptionModal.bind(null, 'audience')}
              className='dashboard--funnel__info'
            >
              <i className='icon-info' role='presentation' />
            </div>
            <ReactHighcharts config={this.getAudienceFunnel()} />
          </div>
          {this.props.hasRecommendation && (
            <div className='dashboard--funnel__halfbox'>
              <div
                onClick={this.showDescriptionModal.bind(null, 'segmentify')}
                className='dashboard--funnel__info'
              >
                <i className='icon-info' role='presentation' />
              </div>
              <ReactHighcharts config={this.getSegmentifyFunnel()} />
            </div>
          )}
        </div>
        {(this.props.hasEmail || this.props.hasPush) && (
          <div className='dashboard--funnel__container one-whole'>
            {this.props.hasEmail && (
              <div className='dashboard--funnel__halfbox'>
                <div
                  onClick={this.showDescriptionModal.bind(null, 'segmentify')}
                  className='dashboard--funnel__info'
                >
                  <i className='icon-info' role='presentation' />
                </div>
                <ReactHighcharts config={this.getSegmentifyFunnel('email')} />
              </div>
            )}
            {this.props.hasPush && (
              <div className='dashboard--funnel__halfbox'>
                <div
                  onClick={this.showDescriptionModal.bind(null, 'segmentify')}
                  className='dashboard--funnel__info'
                >
                  <i className='icon-info' role='presentation' />
                </div>
                <ReactHighcharts config={this.getSegmentifyFunnel('push')} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
export default FunnelView;
