import React from 'react';
import classes from './DrawerButton.module.scss';
import Tooltip from '../../../../../tooltip';

export const DrawerButton = ({ onClick, errorOccurred }) => {
  const tip = 'Shows compilation errors and warnings';

  return (
    <Tooltip content={tip} alignment='top' newTooltip>
      <div
        onClick={onClick}
        className={
          classes[`notify-badge${errorOccurred ? '--error' : '--success'}`]
        }
      />
    </Tooltip>
  );
};
