import React from 'react';
import Select from 'react-select';
import styles from './Dropdown.module.scss';

const Dropdown = ({
  header,
  editCampaign,
  value,
  options,
  isTitleVisible = true,
  ddStyle = {},
  placeholder = 'Select...',
  onClick = null,
}) => {
  const DDIcon = () => {
    return (
      <div className={styles.icon}>
        <svg
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1.74866 5.39727L8.0528 11.7014L14.3569 5.39727L13.197 4.2373L8.05279 9.38148L2.90861 4.2373L1.74866 5.39727Z'
            fill='#7A7A7C'
          />
        </svg>
      </div>
    );
  };

  return (
    <div className={styles.dropdown} onClick={onClick}>
      {isTitleVisible && (
        <label className={styles.dropdown__header}>
          <span className={styles.dropdown__header__text}>{header}</span>
        </label>
      )}
      <div className={styles['dropdown__select-area']}>
        <DDIcon />
        <Select
          className={styles['dropdown__select-area__select']}
          name='dropdown'
          value={value}
          options={options}
          onChange={e => editCampaign(e?.value)}
          style={ddStyle}
          clearable={false}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default Dropdown;
