import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const cpPhoneIcon = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 12 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.70431 1.11231L3.48231 0.877806C4.36397 0.612046 5.306 1.04153 5.68353 1.88142L6.28467 3.21857C6.60647 3.93445 6.43687 4.77579 5.86287 5.31109L4.86667 6.24009C4.83753 6.26722 4.81907 6.30388 4.81467 6.34349C4.78513 6.60822 4.9646 7.12382 5.37807 7.84002C5.6788 8.36089 5.95093 8.72609 6.1826 8.93155C6.344 9.07469 6.43287 9.10562 6.47073 9.09435L7.81093 8.68462C8.56133 8.45522 9.37453 8.72875 9.83367 9.36508L10.6875 10.5484C11.2249 11.2932 11.1283 12.3208 10.4614 12.9523L9.87047 13.5119C9.23287 14.1158 8.32513 14.3408 7.47933 14.1047C5.64327 13.5922 3.99707 12.0428 2.52248 9.48875C1.04585 6.93115 0.527721 4.72817 1.00535 2.88031C1.22393 2.03465 1.86802 1.36439 2.70431 1.11231ZM2.99291 2.06976C2.49113 2.22101 2.10468 2.62317 1.97353 3.13056C1.57177 4.6849 2.03101 6.63749 3.38851 8.98875C4.7442 11.3368 6.20347 12.7104 7.7482 13.1415C8.25567 13.2832 8.80027 13.1482 9.18287 12.7858L9.77374 12.2262C10.0769 11.9392 10.1208 11.4721 9.87653 11.1336L9.02273 9.95022C8.814 9.66102 8.4444 9.53662 8.10327 9.64095L6.75973 10.0517C5.97993 10.2842 5.27213 9.65649 4.51207 8.34002C3.99987 7.45289 3.76114 6.76709 3.82085 6.23249C3.85179 5.9555 3.98083 5.69877 4.18465 5.50871L5.18087 4.57974C5.4418 4.33642 5.51887 3.95399 5.3726 3.62859L4.77147 2.29145C4.59985 1.90968 4.17166 1.71445 3.77091 1.83525L2.99291 2.06976Z'
        fill={color}
      />
    </svg>
  );
};

cpPhoneIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

cpPhoneIcon.defaultProps = {
  width: '12px',
  height: '15px',
  color: '#979797',
};

export default cpPhoneIcon;
