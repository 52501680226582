import React from 'react';
import classes from './PushCategory.module.scss';
import PushStats from './push-stats/PushStats';

const PushCategory = ({ push, email, enumPush, enumEmail }) => {
  return (
    <div className={classes['pc-container']}>
      <PushStats push={push} enumPush={enumPush} />
      <PushStats push={email} enumPush={enumEmail} isEmail />
    </div>
  );
};

export default PushCategory;
