import React from 'react';
import PropTypes from 'prop-types';
import { getAllFacetedReports } from 'actions/faceted-search';
import { connect } from 'react-redux';
import moment from 'moment';
import { SearchReportRow } from '../../../../views/searchreportrow';
import { t } from 'system/ui';

class FacetedReportsSumReport extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    const date = this.getStartAndEndDate(this.props);
    dispatch(
      getAllFacetedReports(date.start, date.end, data => null),
    );
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { date, dispatch } = nextProps;
    const areRangesEqual =
      JSON.stringify(this.props.date.range) === JSON.stringify(date.range);
    if (!areRangesEqual) {
      const selectedDate = this.getStartAndEndDate(nextProps);
      dispatch(
        getAllFacetedReports(selectedDate.start, selectedDate.end, data => null),
      );
    }
  }

  getStartAndEndDate(props) {
    const { date } = props;
    return {
      start: moment(date.range[0]).format('YYYY-MM-DD'),
      end: moment(date.range[1]).format('YYYY-MM-DD'),
    };
  }

  render() {
    const { data, loaded, error } = this.props;
    return (
      <div>
        {error && (
          <span
            className="item-error has-error"
            style={{ display: 'block', marginTop: '10px', fontSize: 15 }}
          >
            {JSON.stringify(error)}
          </span>
        )}
        {loaded && (
          <SearchReportRow
            type='faceted'
            data={data}
            loaded={loaded}
            title={t('Summary')}
            subtitle={t('faceted-all-report-subtitle')}
          />
        )}
      </div>
    );
  }
}

FacetedReportsSumReport.defaultProps = {
  data: [],
};

FacetedReportsSumReport.propTypes = {
  params: PropTypes.shape({
    instanceId: PropTypes.string.isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.instanceOf(Object)]).isRequired,
  loaded: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  date: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  loading: state.facetedSearch.allReportsSum.loading,
  loaded: state.facetedSearch.allReportsSum.loaded,
  error: state.facetedSearch.allReportsSum.error,
  data: state.facetedSearch.allReportsSum.data,
  date: state.date,
});

export default connect(mapStateToProps)(FacetedReportsSumReport);
