export const postAddNewClient = (clientData, callback) => {
  const url = 'https://hooks.zapier.com/hooks/catch/2271931/brs6ilg/';
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(clientData),
    redirect: 'follow',
  };

  fetch(url, requestOptions)
    .then(response => response.json())
    .then(result => {
      if (result.status === 'success') {
        callback(false);
      } else {
        callback(true);
      }
    })
    .catch(() => callback(true));
};
