import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { searchRbsProducts } from '../../modules/product/ajax';
import { operatorObjToArray } from './utils/utils';
import { decodeHtml } from '../../system/string';
import { t } from '../../system/ui';

import {
  stringOperators,
  operatorColumns,
} from '../../constants/datamaps/rule-based-segmentation';
import { ErrorText } from './module-exports';

const defaultSelectData = {
  value: 'any',
  label: 'any product',
  title: 'any product',
};

/**
 * @name CriteriaFilterProduct
 * @description Component to filter product
 * @param {object} criteriaData - The criteria data
 * @param {string} filterType - The filter type
 * @param {array} statements - The statements
 * @param {function} callbackFunc - The callback function
 * @returns {JSX.Element}
 */
const criteriaFilterProduct = ({
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const defaultFilterData = {
    dataType: criteriaData?.dataType,
    column: operatorColumns.productOperator,
    operator: stringOperators.equals.value,
    value: 'any',
    name: 'any product',
  };

  const [selectedProduct, setSelectedProduct] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState('');
  const [isCustomText, setIsCustomText] = useState();

  if (statements?.[filterType].length === 0) {
    callbackFunc(filterType, [defaultFilterData]);
  }

  useEffect(() => {
    const statement = statements?.[filterType]?.[0];

    setSelectedOperator(statement?.operator || defaultFilterData.operator);

    const defaultProduct = statement
      ? statement?.value
      : defaultFilterData.value;

    setSelectedProduct(defaultProduct);
    if (defaultProduct) {
      if (defaultProduct === 'any') {
        setProducts([defaultSelectData]);
      } else {
        setProducts([
          defaultSelectData,
          {
            value: defaultProduct,
            title: decodeHtml(defaultProduct),
            label: decodeHtml(defaultProduct),
          },
        ]);
      }
    } else {
      setProducts([defaultSelectData]);
    }
  }, [statements]);

  const triggerCallbackFunc = (val, type) => {
    const columnVal = operatorColumns.productOperator;
    const operatorVal = type === 'operator' ? val?.value : selectedOperator;
    let productVal = type === 'product' ? val?.value || '' : selectedProduct;
    if (
      [
        stringOperators.contains.value,
        stringOperators.containsNot.value,
      ].includes(selectedOperator) &&
      [stringOperators.equals.value, stringOperators.equalsNot.value].includes(
        operatorVal,
      ) &&
      isCustomText
    ) {
      productVal = '';
    }

    const filterData = [
      {
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator: operatorVal,
        value: productVal,
      },
    ];

    callbackFunc(filterType, filterData);
  };

  const onChange = (selectedValue, type) => {
    switch (type) {
      case 'product':
        setSelectedProduct(selectedValue?.value || '');
        setProducts([
          defaultSelectData,
          {
            value: selectedValue?.value || '',
            title: decodeHtml(selectedValue?.title || ''),
            label: decodeHtml(selectedValue?.label || ''),
          },
        ]);
        // eslint-disable-next-line no-prototype-builtins
        setIsCustomText(!selectedValue?.hasOwnProperty('title'));
        break;
      case 'operator':
        setSelectedOperator(selectedValue?.value);
        break;
      default:
        break;
    }

    triggerCallbackFunc(selectedValue, type);
  };

  const onInputChange = value => {
    if (value.length > 2) {
      searchRbsProducts(value, response => {
        const productOptions = [];
        const productNames = [];
        productOptions.push(defaultSelectData);
        if (response) {
          response.forEach(product => {
            const productName = decodeHtml(product.name);
            if (!productNames.includes(productName)) {
              productOptions.push({
                value: productName,
                title: productName,
                label: productName,
              });
              productNames.push(productName);
            }
          });
        }
        setProducts(productOptions);
      });
    } else if (selectedProduct) {
      if (selectedProduct === 'any') {
        setProducts([defaultSelectData]);
      } else {
        setProducts([
          {
            value: selectedProduct,
            title: decodeHtml(selectedProduct),
            label: decodeHtml(selectedProduct),
          },
        ]);
      }
    } else {
      setProducts([defaultSelectData]);
    }
    return value;
  };

  const ConditionalSelect = [
    stringOperators.contains.value,
    stringOperators.containsNot.value,
  ].includes(selectedOperator)
    ? Select.Creatable
    : Select;

  return (
    <div className='rbs-filter-field'>
      <div className='filter-operator'>
        <span className='filter-operator-title'>Operator*</span>
        <Select
          value={selectedOperator}
          options={operatorObjToArray(stringOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          onChange={e => onChange(e, 'operator')}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      <div className='filter-connector' />
      <div className='filter-operator'>
        <span className='filter-operator-title'>Product*</span>
        <ConditionalSelect
          value={selectedProduct}
          options={products}
          name='product'
          clearable={false}
          searchable
          onChange={e => onChange(e, 'product')}
          onInputChange={onInputChange}
          className={`criteria-field one-whole ${
            selectedProduct ? '' : 'has-error'
          }`}
          openOnClick={false}
          placeholder={t('Select or text')}
        />
        {!selectedProduct && <ErrorText />}
      </div>
    </div>
  );
};

export default criteriaFilterProduct;
