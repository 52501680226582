/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { t } from "../../../../system/ui";

import { wizardActions } from "../../../../actions";

import Icons from "../../../../components/icons";

class TimingButton extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    e.preventDefault();

    this.props.isTimingVisible
      ? wizardActions.hideTiming()
      : wizardActions.showTiming();
  }

  render() {
    return (
      <button onClick={this.toggle} className="secondary-action">
        <Icons name="clock" />
        {t("Timing & Frequency")}
      </button>
    );
  }
}

const MapStatesToProps = store => ({
  isTimingVisible: store.wizard.isTimingVisible
});

export default connect(MapStatesToProps)(TimingButton);
