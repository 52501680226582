import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from 'components/icon';
import {
  getUserList,
  searchUser,
} from 'reducers/user-management/extraReducers';
import { onSearchChange } from 'reducers/user-management';
import { t } from 'system/ui';

const UserManagementSearch = ({ getFilterQuery }) => {
  const dispatch = useDispatch();
  const filterState = useSelector(state => state.userManagement.filter);

  const onInputChange = e => {
    dispatch(onSearchChange(e.target.value));
  };

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      const { value } = e.target;
      const query = getFilterQuery();
      if (value.trim().length > 0)
        dispatch(searchUser({ searchParam: value, query }));
      else dispatch(getUserList(query));
    }
  };

  return (
    <div className='page-options page-options-admin-stats'>
      <label className='item is-stacked is-search'>
        <span style={{ cursor: 'pointer' }}>
          <Icon name='magnify' />
        </span>
        <input
          type='text'
          placeholder={t('search-and-hit-enter')}
          className='text-field'
          name='userManagementSearchInput'
          value={filterState.searchParam}
          onKeyPress={onKeyPress}
          onChange={onInputChange}
        />
      </label>
    </div>
  );
};

UserManagementSearch.propTypes = {
  getFilterQuery: PropTypes.func.isRequired,
};

export default UserManagementSearch;
