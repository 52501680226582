import Services from '../../../../service/Services';

class RecommendationTemplateServices extends Services {
  constructor({ endPoint, paginationIsActive }) {
    super({ endPoint, paginationIsActive });
  }
}

const RecommendationTemplateService = (
  endPoint = 'recommendationTemplate/templates',
  paginationIsActive = false,
) => {
  return new RecommendationTemplateServices({
    endPoint,
    paginationIsActive,
  });
};

export { RecommendationTemplateService };
