/**
 * @author Bilal Cinarli
 * Actions for old campaign model
 */

"use strict";

import { createAction } from "../system/store";

export const resetWizard = () => {
  createAction("RESET_WIZARD");
};

export const duplicateCampaign = (isDuplicate, duplicatedValues) => {
  createAction("DUPLICATE_CAMPAIGN", { isDuplicate, duplicatedValues });
};

export const filterCampaigns = status => {
  createAction("FILTER_CAMPAIGN_STATUS", { status });
};

export const filterMedium = medium => {
  createAction("FILTER_CAMPAIGN_MEDIUM", { medium });
};

export const addCampaignRevenues = campaign => {
  createAction("ADD_CAMPAIGN_REVENUE", { campaign });
};

export const filterActiveCampaigns = () => {
  filterCampaigns("ACTIVE");
};

export const filterTestCampaigns = () => {
  filterCampaigns("TEST");
};

export const filterArchivedCampaigns = () => {
  filterCampaigns("PASSIVE");
};

export default {
  resetWizard,
  duplicateCampaign,
  filterCampaigns,
  filterActiveCampaigns,
  filterTestCampaigns,
  filterArchivedCampaigns,
  filterMedium,
  addCampaignRevenues
};
