/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import {ExternalAppUtility} from "../../external";
import {uiActions} from "../../actions";

class Preloader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let isExternal = ExternalAppUtility.isHeaderHidden();
    return (
        <div className={classNames("preloader", {
          "external": isExternal,
          "preloader-start": this.props.ui === "isLoading",
          "preloader-end": this.props.ui === "isLoaded"
        })}
        />
    );
  }
}

const MapStatesToProps = store => ({
  ui: store.ui.ui
});

export default connect(MapStatesToProps)(Preloader);
