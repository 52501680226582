/**
 * Created by mehmetyurtar on 16/05/2017.
 */

"use strict";
const transformAnalyticsSorting = (sorting, device) => {
  let newSorting = sorting.replace(/all/g, device);
  return newSorting;
};

export default transformAnalyticsSorting;
