/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";

import Icon from "../icon";
import Icons from "../icons";
import { TextField } from "../fields";

import { t } from "../../system/ui";
import classNames from "classnames";

class ExcludesPriceRange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      min: "",
      max: ""
    };

    this.onChange = this.onChange.bind(this);
    this.setValues = this.setValues.bind(this);
  }

  componentDidMount() {
    this.setValues();
  }

  setValues() {
    let newState = {
      min:
        typeof this.props.values.min !== "undefined"
          ? this.props.values.min.value
          : "",
      max:
        typeof this.props.values.max !== "undefined"
          ? this.props.values.max.value
          : ""
    };
    this.props.subComponents.forEach(subComponent => {
      (newState["min" + subComponent.id] =
        typeof subComponent.values.min !== "undefined"
          ? subComponent.values.min.value
          : ""),
        (newState["max" + subComponent.id] =
          typeof subComponent.values.max !== "undefined"
            ? subComponent.values.max.value
            : "");
    });
    this.setState(newState);
  }

  onChange(ref, id, e) {
    let value = e.target.value;
    if (!ref && !id) {
      this.setState({ [e.target.name]: value });
      this.props.onExclusionChange(this.refs.inputs, this.props.id);
    } else {
      let newState = {};
      newState[e.target.name + id] = value;
      this.setState(newState);
      this.props.onExclusionChange(this.refs[ref], id);
    }
  }

  render() {
    return (
      <li className="wizard-criterion" key={this.props.id}>
        <span ref="inputs">
          <span className="criteria-type">
            <Icon name="dollar" color="#547494" />
          </span>
          <div className="wizard-criterion-text">
            <h3 className="wizard-criterion-title">{t("Price Range")}</h3>
            <p className="wizard-criterion-description">
              {t(
                "Price Range allows you to specify the maximum and minimum values of the products that will be eliminated from the recommendation."
              )}
            </p>
          </div>
          <div>
            <TextField
              type="number"
              name="min"
              label="min"
              value={this.state.min}
              className="item-field criteria-name wizard-price-range"
              inputClassName="one-whole criteria-field"
              onChange={this.onChange.bind(this, null, null)}
            />

            <TextField
              type="number"
              name="max"
              label="max"
              value={this.state.max}
              className="item-field criteria-name wizard-price-range"
              inputClassName="one-whole criteria-field"
              onChange={this.onChange.bind(this, null, null)}
            />
            <div className="buttons-criteria">
              <a
                onClick={this.props.onExclusionRemove.bind(null, this.props.id)}
                className="remove-criteria button-criteria recommend-filter-cancel"
              >
                <Icons name="crossCircle" />
              </a>
              {this.props.subComponents.length < 2 && (
                <a
                  onClick={this.props.onExclusionAdd.bind(null, "price_range")}
                  className="button-criteria"
                >
                  <Icons name="plusCircle" width="32" height="32" />
                </a>
              )}
            </div>
          </div>
        </span>
        {this.props.subComponents.map((subComponent, ind) => {
          if (ind > 0) {
            return (
              <div
                className="criteria-subcomponent"
                ref={"subComponentInputs" + subComponent.id}
                key={subComponent.id}
              >
                <TextField
                  type="number"
                  name="min"
                  label="min"
                  value={this.state["min" + subComponent.id]}
                  className="item-field criteria-name wizard-price-range"
                  inputClassName="one-whole criteria-field"
                  onChange={this.onChange.bind(
                    this,
                    "subComponentInputs" + subComponent.id,
                    subComponent.id
                  )}
                />

                <TextField
                  type="number"
                  name="max"
                  label="max"
                  value={this.state["max" + subComponent.id]}
                  className="item-field criteria-name wizard-price-range"
                  inputClassName="one-whole criteria-field"
                  onChange={this.onChange.bind(
                    this,
                    "subComponentInputs" + subComponent.id,
                    subComponent.id
                  )}
                />

                <div className="buttons-criteria">
                  {ind === this.props.subComponents.length - 1 && (
                    <a
                      onClick={this.props.onExclusionAdd.bind(
                        null,
                        subComponent.type
                      )}
                      className="button-criteria"
                    >
                      <Icons name="plusCircle" width="32" height="32" />
                    </a>
                  )}
                  <a
                    onClick={this.props.onExclusionRemove.bind(
                      null,
                      subComponent.id
                    )}
                    className="remove-criteria button-criteria"
                  >
                    <Icons name="crossCircle" />
                  </a>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate,
  excludes: store.wizard.excludes
});

export default connect(mapStatesToProps)(ExcludesPriceRange);
