/**
 * @author Bilal Cinarli
 */

import React from "react";

import { bannerifyTable } from "../../constants/datamaps/analytics";
import { bannerProductDetailTable } from "../../constants/datamaps/analytics";

import { uiActions } from "../../actions";

import { productUrl } from "../../modules/trendify/page";
import { getBanners } from "../../modules/bannerify/ajax";
import { getSalesDetail } from "../../modules/sales/ajax";
import { decodeHtml } from "../../system/string";
import Icon from "../../components/icon";

import { t } from "../../system/ui";
import { clone } from "../../system/object";
import { reformatDate } from "../../system/date";
import { localeString } from "../../system/string";
import { calculateRatio } from "../../system/ratio";

import Tooltip from "../tooltip";
import TableHeadings from "../table/heading";
import Currency from "../../components/currency";

class BannerDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      query: "",
      sort: "ASC",
      currentSorted: "record->>'order'",
      products: {},
      productsInBanner: [],
      detailType: "banner"
    };

    this.sort = this.sort.bind(this);
    this.changeDetailType = this.changeDetailType.bind(this);
  }

  componentDidMount() {
    uiActions.isLoading();
    let statPrefix = this.props.statPrefix;
    let products = {};
    let productIds = [];
    let bannerProducts = [];
    getBanners(this.props.query, response => {
      response.forEach &&
        response.forEach(item => {
          if (item.banner.stats[statPrefix + "Products"]) {
            item.banner.stats[statPrefix + "Products"].forEach(product => {
              productIds.push(product.productId);
              bannerProducts.push(product);
            });
          }
        });
      if (productIds.length) {
        getSalesDetail(productIds, productsResponse => {
          productsResponse.forEach(product => {
            if (product) {
              products[product.productId] = product;
            }
          });
          this.setState(
            {
              productsInBanner: bannerProducts,
              rows: response,
              query: this.props.query,
              products: products
            },
            () => {
              window.dispatchEvent(new Event("resize"));
            }
          );
          uiActions.isLoaded();
        });
      } else {
        this.setState({
          rows: response,
          query: this.props.query
        });
        uiActions.isLoaded();
      }
    });
  }

  sort(sorting) {
    uiActions.isLoading();

    let query = clone(this.state.query);

    query.sort = sorting;
    query.reverse =
      this.state.currentSorted === sorting ? !query.reverse : query.reverse;

    this.setState({
      query: query,
      currentSorted: sorting,
      sort: query.reverse ? "DESC" : "ASC"
    });

    getBanners(query, response => {
      this.setState({
        rows: response
      });

      uiActions.isLoaded();
    });
  }

  changeDetailType() {
    this.setState(
      prevState => ({
        detailType: prevState.detailType === "banner" ? "products" : "banner"
      }),
      () => {
        window.dispatchEvent(new Event("resize"));
      }
    );
  }

  render() {
    let statPrefix = this.props.statPrefix;
    let tableStyle = {
      height: "calc(100% - 100px)",
      overflowY: "scroll"
    };
    return (
      <div>
        <div
          className="report--graph__buttons"
          style={{ padding: "15px", marginTop: "-20px" }}
        >
          <li
            className={
              this.state.detailType === "banner"
                ? "secondary-action selected"
                : "report--graph__button"
            }
            onClick={this.changeDetailType}
          >
            {t("Banner Performance")}
          </li>
          {this.state.productsInBanner.length > 0 && (
            <li
              className={
                this.state.detailType === "products" &&
                this.state.productsInBanner.length > 0
                  ? "secondary-action selected"
                  : "report--graph__button"
              }
              onClick={this.changeDetailType}
            >
              {t("Purchased Products")}
            </li>
          )}
        </div>
        {this.state.detailType === "products" &&
          this.state.productsInBanner.length > 0 && (
            <div
              className="analytics-details banner-products"
              style={{ minHeight: "360px", minWidth: "1100px" }}
            >
              <div style={tableStyle}>
                <table className="data-table">
                  <thead>
                    <TableHeadings heading={bannerProductDetailTable.header} />
                  </thead>
                  <tbody>
                    {this.state.productsInBanner.map((item, index) => {
                      let productInBanner = item;
                      let productInfo = this.state.products[
                        productInBanner.productId
                      ];
                      let categories = [];
                      if (!productInfo) {
                        return false;
                      }
                      return (
                        <tr key={index}>
                          <td style={{ padding: "5px 15px" }}>
                            {productInfo.image ? (
                              <a
                                href={productUrl(productInfo.url)}
                                target="_blank"
                              >
                                <img
                                  src={productUrl(productInfo.image)}
                                  height="40"
                                />
                              </a>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td style={{ padding: "5px 15px" }}>
                            {productInfo.name ? (
                              <a
                                href={productUrl(productInfo.url)}
                                target="_blank"
                              >
                                {productInfo.name} - {productInfo.productId}
                              </a>
                            ) : (
                              <span>
                                No Information - {productInfo.productId}
                              </span>
                            )}
                          </td>
                          <td style={{ padding: "5px 15px" }}>
                            {productInfo.categories ? (
                              productInfo.categories.length > 2 ? (
                                <Tooltip
                                  content={productInfo.categories.join(", ")}
                                >
                                  {productInfo.categories[0]}
                                  ...{" "}
                                  {
                                    productInfo.categories[
                                      productInfo.categories.length - 1
                                    ]
                                  }
                                </Tooltip>
                              ) : (
                                productInfo.categories
                                  .map(x => {
                                    return decodeHtml(x);
                                  })
                                  .join(", ")
                              )
                            ) : (
                              <div>-</div>
                            )}
                          </td>
                          <td style={{ padding: "5px 15px" }}>
                            {productInfo.brand
                              ? decodeHtml(productInfo.brand)
                              : "-"}
                          </td>
                          <td style={{ padding: "5px 15px" }}>
                            {productInfo.inStock ? (
                              <Icon name="check-circle" />
                            ) : productInfo.name ? (
                              <span className="out-of-stock" />
                            ) : (
                              "-"
                            )}
                          </td>
                          <td style={{ padding: "5px 15px" }}>
                            {productInBanner.quantity}
                          </td>
                          <td style={{ padding: "5px 15px" }}>
                            <Currency currency={productInBanner.price} />
                          </td>
                          <td style={{ padding: "5px 15px" }}>
                            {reformatDate(productInBanner.time)}
                          </td>
                          <td style={{ padding: "5px 15px" }}>
                            <Icon name="check-circle" />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        {this.state.detailType === "banner" && (
          <div
            className="analytics-details"
            style={{ minHeight: "360px", minWidth: "1100px" }}
          >
            <table className="data-table">
              <thead>
                <TableHeadings
                  heading={bannerifyTable.header}
                  currentSorted={this.state.currentSorted}
                  direction={this.state.sort}
                  onSort={this.sort}
                  tooltip={false}
                />
              </thead>
              <tbody>
                {this.state.rows.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.banner.group}</td>
                      <td>{item.banner.order}</td>
                      <td>{reformatDate(item.banner.insertTime)}</td>
                      <td>{reformatDate(item.banner.updateTime)}</td>
                      <td>{item.banner.title}</td>
                      <td>
                        <Tooltip
                          content={productUrl(item.banner.image)}
                          type="image"
                          placement="left"
                        >
                          <img
                            src={productUrl(item.banner.image)}
                            height="40"
                          />
                        </Tooltip>
                      </td>
                      <td>{item.banner.categories.join(",")}</td>
                      <td className="cell-group">
                        {localeString(
                          item.banner.stats[statPrefix + "Impression"]
                        ) || "-"}
                      </td>
                      <td>
                        {localeString(
                          item.banner.stats[statPrefix + "Click"]
                        ) || "-"}
                      </td>
                      <td>
                        {calculateRatio(
                          item.banner.stats[statPrefix + "Click"],
                          item.banner.stats[statPrefix + "Impression"]
                        )}
                        %
                      </td>
                      <td>
                        {localeString(
                          item.banner.stats[statPrefix + "ProdView"]
                        ) || "-"}
                      </td>
                      <td>
                        {calculateRatio(
                          item.banner.stats[statPrefix + "ProdView"],
                          item.banner.stats[statPrefix + "Impression"]
                        )}
                        %
                      </td>
                      <td>
                        {localeString(
                          item.banner.stats[statPrefix + "Basket"]
                        ) || "-"}
                      </td>
                      <td>
                        {calculateRatio(
                          item.banner.stats[statPrefix + "Basket"],
                          item.banner.stats[statPrefix + "Click"]
                        )}
                        %
                      </td>
                      <td>
                        {localeString(
                          item.banner.stats[statPrefix + "Purchase"]
                        ) || "-"}
                      </td>
                      <td>
                        {calculateRatio(
                          item.banner.stats[statPrefix + "Purchase"],
                          item.banner.stats[statPrefix + "Click"]
                        )}
                        %
                      </td>
                      <td>
                        <Currency
                          currency={
                            item.banner.stats[statPrefix + "Amount"] || 0
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default BannerDetails;
