import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const LastVisitReminder = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 3C13.6944 3 17.5 6.80558 17.5 11.5C17.5 16.1944 13.6944 20 9 20C4.30558 20 0.5 16.1944 0.5 11.5C0.5 6.80558 4.30558 3 9 3ZM9 4.5C5.13401 4.5 2 7.63401 2 11.5C2 15.366 5.13401 18.5 9 18.5C12.866 18.5 16 15.366 16 11.5C16 7.63401 12.866 4.5 9 4.5ZM9 6C9.3797 6 9.6935 6.28215 9.7432 6.64823L9.75 6.75V11.25C9.75 11.6642 9.4142 12 9 12C8.6203 12 8.3065 11.7178 8.2568 11.3518L8.25 11.25V6.75C8.25 6.33579 8.5858 6 9 6ZM16.1472 3.11371L16.2298 3.17355L17.3882 4.13773C17.7066 4.40272 17.7498 4.87562 17.4849 5.19398C17.2419 5.48581 16.8243 5.54649 16.5112 5.35047L16.4286 5.29063L15.2702 4.32645C14.9518 4.06146 14.9086 3.58856 15.1735 3.2702C15.4165 2.97837 15.8341 2.91769 16.1472 3.11371ZM11.25 0.5C11.6642 0.5 12 0.83579 12 1.25C12 1.6297 11.7178 1.94349 11.3518 1.99315L11.25 2H6.75C6.33579 2 6 1.66421 6 1.25C6 0.8703 6.28215 0.55651 6.64823 0.50685L6.75 0.5H11.25Z'
        fill='#5D5D61'
      />
    </svg>
  );
};

LastVisitReminder.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

LastVisitReminder.defaultProps = {
  width: '18px',
  height: '20px',
};

export default LastVisitReminder;
