import React from 'react';
import classes from './GridListRender.module.scss';
import Tooltip from '../../../../../components/tooltip';
import Icon from '../../../../../components/icon';
/**
 * @name GridListRender
 * @description
 * This component is responsible for rendering the grid list
 * @param {Array} data - Data for the grid list
 * @param {Array} schema - Schema for the grid list
 * @returns JSX.Element - Grid List
 * @example
 * <GridListRender data={data} schema={schema} />
 */
export const GridListRender = ({ data, schema }) => {
  return (
    <tbody>
      {data?.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={i}>
          {schema.map(column => (
            <td className={column.accessor} key={column.order}>
              {column.resolver.hasResolver ? (
                <div
                  className={
                    column.accessor === 'status' ||
                    column.accessor === 'transferStatus'
                      ? classes[
                          item[column.accessor] === 'IYS Error'
                            ? 'IYS-Error'
                            : item[column.accessor]
                        ]
                      : classes.base_class
                  }
                >
                  {column.accessor === 'errorMessage' ? (
                    <Tooltip
                      content={column.resolver.resolver(item[column.accessor])}
                      placement='top'
                      alignment='center'
                    >
                      <Icon name='info' color='black' />
                    </Tooltip>
                  ) : (
                    column.resolver.resolver(item[column.accessor])
                  )}
                </div>
              ) : (
                <div
                  className={
                    column.accessor === 'status' ||
                    column.accessor === 'transferStatus'
                      ? classes[item[column.accessor]]
                      : classes.base_class
                  }
                >
                  {item[column.accessor]}
                </div>
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
