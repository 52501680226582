import React, { Component } from 'react';
import { connect } from 'react-redux';

import { uiActions } from '../../actions';
import { getRangedEmailReport } from '../../modules/emails/ajax';
import { transformCampaignReport } from '../../modules/transform';
import { t } from '../../system/ui';
import { getOtherDates } from '../analytics/utils';
import { RangePicker } from '../fields';
import ComparisonColumn from './comparisonColumn';

class CompareRecommendationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserSelect: false,
      firstStart: '',
      firstEnd: '',
      secondStart: '',
      secondEnd: '',
      firstColumnReports: [],
      secondColumnReports: [],
    };

    this.getInitialReports = this.getInitialReports.bind(this);
    this.onFirstDatesChange = this.onFirstDatesChange.bind(this);
    this.onSecondDatesChange = this.onSecondDatesChange.bind(this);
    this.changeFirstColumn = this.changeFirstColumn.bind(this);
    this.changeSecondColumn = this.changeSecondColumn.bind(this);
  }

  componentDidMount() {
    // Get initial Reports
    this.getInitialReports();
  }

  onFirstDatesChange(newDates) {
    this.setState({
      firstStart: newDates.startDate,
      firstEnd: newDates.endDate,
      isUserSelect: true,
    });
  }

  onSecondDatesChange(newDates) {
    this.setState({
      secondStart: newDates.startDate,
      secondEnd: newDates.endDate,
      isUserSelect: true,
    });
  }

  getInitialReports() {
    uiActions.isLoading();
    const boundaries = {};
    boundaries.start = this.props.range[0].format('YYYY-MM-DD');
    boundaries.end = this.props.range[1].format('YYYY-MM-DD');
    // Get Email Report Here
    getRangedEmailReport(
      this.props.campaign.instanceId,
      boundaries,
      campaignReport => {
        const firstColumnReports = campaignReport;
        getRangedEmailReport(
          this.props.campaign.instanceId,
          {
            start: getOtherDates(this.props.range).formattedStart,
            end: getOtherDates(this.props.range).formattedEnd,
          },
          otherCampaignReport => {
            this.setState({
              firstColumnReports: transformCampaignReport(firstColumnReports),
              secondColumnReports: transformCampaignReport(otherCampaignReport),
            });
            window.dispatchEvent(new Event('resize'));
            uiActions.isLoaded();
          },
        );
      },
    );
  }

  changeFirstColumn(dates) {
    if (
      dates.startDate === this.state.firstStart &&
      dates.enddDate === this.state.firstEnd
    ) {
      return;
    }
    uiActions.isLoading();
    const boundaries = {};
    boundaries.start = dates.startDate.format('YYYY-MM-DD');
    boundaries.end = dates.endDate.format('YYYY-MM-DD');

    getRangedEmailReport(
      this.props.campaign.instanceId,
      boundaries,
      campaignReport => {
        this.setState({
          firstColumnReports: transformCampaignReport(campaignReport),
        });
        uiActions.isLoaded();
        window.dispatchEvent(new Event('resize'));
      },
    );
  }

  changeSecondColumn(dates) {
    if (
      dates.startDate === this.state.secondtStart &&
      dates.enddDate === this.state.secondEnd
    ) {
      return;
    }
    uiActions.isLoading();
    const boundaries = {};
    boundaries.start = dates.startDate.format('YYYY-MM-DD');
    boundaries.end = dates.endDate.format('YYYY-MM-DD');

    getRangedEmailReport(
      this.props.campaign.instanceId,
      boundaries,
      campaignReport => {
        this.setState({
          secondColumnReports: transformCampaignReport(campaignReport),
        });
        uiActions.isLoaded();
        window.dispatchEvent(new Event('resize'));
      },
    );
  }

  render() {
    return (
      <div className='report-compare one-whole' style={{ minWidth: '932px' }}>
        <div className='one-half report-compare__field'>
          <div className='report-compare-date'>
            <span
              style={{
                display: 'inline-block',
                marginRight: '12px',
                fontSize: '14px',
                color: '#a3a3a5',
              }}
            >
              {t('Compare To')}:
            </span>
            <RangePicker
              startDate={
                this.state.secondStart || getOtherDates(this.props.range).start
              }
              endDate={
                this.state.secondEnd || getOtherDates(this.props.range).end
              }
              onDatesChange={this.onSecondDatesChange}
              onPickerClose={this.changeSecondColumn}
            />
          </div>
          <ComparisonColumn
            stats={this.state.secondColumnReports}
            isEmailCampaign
          />
        </div>
        <div className='one-half report-compare__field'>
          <div className='report-compare-date'>
            <span
              style={{
                display: 'inline-block',
                marginRight: '12px',
                fontSize: '14px',
                color: '#a3a3a5',
              }}
            >
              {t('With')}:
            </span>
            <RangePicker
              startDate={this.state.firstStart || this.props.range[0]}
              endDate={this.state.firstEnd || this.props.range[1]}
              onDatesChange={this.onFirstDatesChange}
              onPickerClose={this.changeFirstColumn}
            />
          </div>
          <ComparisonColumn
            stats={this.state.firstColumnReports}
            withCompareIcons
            comparisonStats={this.state.secondColumnReports}
            isEmailCampaign
          />
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
});

export default connect(mapStatesToProps)(CompareRecommendationModal);
