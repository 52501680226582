/**
 * Created by mehmetyurtar on 1/13/18.
 */
import { createAction } from "../system/store";

export const setCategories = options => {
  createAction("SET_CATEGORIES", options);
};

export const removeCategories = () => {
  createAction("REMOVE_CATEGORIES");
};

export default {
  setCategories,
  removeCategories
};
