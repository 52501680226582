import useExtendedContext from '../../../../hooks/module-context/useExtendedContext';
import { productBundleReducer } from './reducers/productBundleReducer';
import {
  setPBCategoryList,
  setPBProductList,
  resetProductBundle,
  setActiveBundleDetails,
  editActiveBundleDetails,
} from './actions/productBundleActions';

const productBundleState = {
  productBundleList: [],
  categoryBundleList: [],
  activeBundleDetails: {
    id: '',
    mainItem: {},
    subItems: [],
  },
};

/**
 * @name productBundleContext
 * @description Context for Product Bundle component. Provides access to the state and actions of the Wrapped component. Actions, state and dispatch are provided as react-hook basis.
 * @property {object} state - State of the context
 */

export const { Context, Provider } = useExtendedContext(
  productBundleReducer,
  {
    setPBCategoryList,
    setPBProductList,
    setActiveBundleDetails,
    editActiveBundleDetails,
    resetProductBundle,
  },
  productBundleState,
);
