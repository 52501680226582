export const eventsConstants = {
  USER: [
    {
      eventName: 'FULL_NAME',
      eventData: '{{user:fullName}}',
    },
    {
      eventName: 'EMAIL',
      eventData: '{{user:email}}',
    },
    {
      eventName: 'PHONE',
      eventData: '{{user:phone}}',
    },
    {
      eventName: 'AGE',
      eventData: '{{user:age}}',
    },
    {
      eventName: 'SEGMENTS',
      eventData: '{{user:segments}}',
    },
    {
      eventName: 'USER_NAME',
      eventData: '{{user:username}}',
    },
    {
      eventName: 'GENDER',
      eventData: '{{user:gender}}',
    },
    {
      eventName: 'LOCATION',
      eventData: '{{user:location}}',
    },
  ],
  EVENT: [
    {
      eventName: 'USER_ID',
      eventData: '{{event:userId}}',
    },
    {
      eventName: 'SESSION_ID',
      eventData: '{{event:sessionId}}',
    },
    {
      eventName: 'DEVICE',
      eventData: '{{event:device}}',
    },
    {
      eventName: 'BROWSER',
      eventData: '{{event:browser}}',
    },
    {
      eventName: 'OS',
      eventData: '{{event:os}}',
    },
    {
      eventName: 'PAGE_URL',
      eventData: '{{event:pageUrl}}',
    },
    {
      eventName: 'REFERRER',
      eventData: '{{event:referrer}}',
    },
  ],
  PRODUCT: [
    {
      eventName: 'NAME',
      eventData: '{{product:name}}',
    },
    {
      eventName: 'PRODUCT_ID',
      eventData: '{{product:productId}}',
    },
    {
      eventName: 'DESCRIPTION',
      eventData: '{{product:description}}',
    },
    {
      eventName: 'URL',
      eventData: '{{product:url}}',
    },
    {
      eventName: 'IMAGE',
      eventData: '{{product:image}}',
    },
    {
      eventName: 'PRICE',
      eventData: '{{product:price}}',
    },
    {
      eventName: 'OLD_PRICE',
      eventData: '{{product:oldPrice}}',
    },
    {
      eventName: 'CURRENCY',
      eventData: '{{product:currency}}',
    },

    {
      eventName: 'BRAND',
      eventData: '{{product:brand}}',
    },
    {
      eventName: 'GENDER',
      eventData: '{{product:gender}}',
    },
    {
      eventName: 'CATEGORIES',
      eventData: '{{product:categories}}',
    },
    {
      eventName: 'LABELS',
      eventData: '{{product:labels}}',
    },
  ],
  PRODUCT_COUNTERS: [
    { eventName: 'DAILY', eventData: '{{productCounter:DAILY}}' },
    { eventName: 'WEEKLY', eventData: '{{productCounter:WEEKLY}}' },
    { eventName: 'MONTHLY', eventData: '{{productCounter:MONTHLY}}' },
  ],
  PRODUCT_PURCHASE_COUNTERS: [
    { eventName: 'DAILY', eventData: '{{productPurchaseCounter:DAILY}}' },
    {
      eventName: 'WEEKLY',
      eventData: '{{productPurchaseCounter:WEEKLY}}',
    },
    {
      eventName: 'MONTHLY',
      eventData: '{{productPurchaseCounter:MONTHLY}}',
    },
  ],
  CONSTANTS: [
    {
      eventName: 'CURRENT_DATE',
      eventData: '{{constant:currentDate}}',
    },
    {
      eventName: 'CURRENT_TIME',
      eventData: '{{constant:currentTime}}',
    },
    {
      eventName: 'CURRENT_DATE_TIME',
      eventData: '{{constant:currentDateTime}}',
    },
  ],
};
