import api from '../../system/api';
import { $http } from '../../system/ajax';

import { getApiKey } from '../auth/user';

export const searchRbsBrand = callback => {
  return $http(`${api.getPath()}search/rbs/brand/` + `?&apiKey=${getApiKey()}`)
    .get({}, { preventAbort: false })
    .then(callback);
};
