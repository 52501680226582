/**
 * @fileOverview This File is used to render the navigation wrapper for the email builder.
 * @requires react
 * @requires classes
 */
import React, { memo } from 'react';
import classes from '../styles/NavigationWrapper.module.scss';

/**
 * @description Render component for the navigation wrapper.
 * @param children {JSX.Element[]} The children of the component.
 * @param editorTitle {string} The title of the editor.
 * @param isDark {boolean} Whether the editor is in dark mode or not.
 * @return {JSX.Element}
 * @constructor
 */

const NavigationWrapper = ({ children, editorTitle }) => {
  return (
    <div className={classes['navigation-wrapper']}>
      <div className={classes['navigation-wrapper-header']}>
        <span className={classes['navigation-wrapper-header-text']}>
          {editorTitle}
        </span>
      </div>
      <div className={classes['navigation-wrapper-actions-container']}>
        {children}
      </div>
    </div>
  );
};

export default memo(NavigationWrapper);
