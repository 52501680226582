import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { t } from '../../system/ui';
import { filterActions, modalActions, uiActions } from '../../actions';

import Icon from '../icon';
import Icons from '../icons';
import SegmentationHistoryModal from '../../modules/segmentation/historymodal';
import {
  moveSegmentToArchive,
  moveSegmentToTest,
  moveSegmentToLive,
} from '../../modules/segmentation/ajax';
import { segmentAlgorithmTitles } from '../../constants/datamaps/wizard';
import { idPropFunc } from '../../system/id';
import { getExternalPersonalisedAds } from '../../constants/config';

class SegmentActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [
        {
          name: 'Passive',
          icon: 'archive',
          action: 'addToArchive',
        },
        {
          name: 'Draft',
          icon: 'Reply',
          iconSvg: true,
          action: 'addToTest',
        },
        {
          name: 'History',
          icon: 'history',
          iconSvg: true,
          action: 'showSegmentHistory',
        },
        {
          name: 'Details',
          icon: 'pie-chart',
          action: 'report',
        },
        {
          name: 'Live',
          icon: 'brain',
          iconSvg: true,
          action: 'addToLive',
        },
        {
          name: 'Send Bulk Push',
          iconSvg: true,
          icon: 'personalizedPush',
          action: 'sendBulkPush',
        },
        {
          name: 'Create Engagement',
          iconSvg: true,
          icon: 'behaviouralTargeting',
          action: 'createEngagementCampaign',
        },
        {
          name: 'Use Personalised Ads',
          iconSvg: true,
          icon: 'personalisedAds',
          action: 'usePersonalisedAds',
        },
        {
          name: 'Send Bulk Email',
          iconSvg: true,
          icon: 'emailModule',
          action: 'sendBulkEmail',
        },
      ],
      dropUp: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.addToArchive = this.addToArchive.bind(this);
    this.addToArchiveConfirmed = this.addToArchiveConfirmed.bind(this);
    this.showSegmentHistory = this.showSegmentHistory.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.report = this.report.bind(this);
  }

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleClick(action) {
    this[action]();
  }

  handleScroll() {
    const partOfDocHeight = (document.documentElement.offsetHeight * 70) / 100;
    const listTop = this.refs.actionMenu.getBoundingClientRect().top;
    this.setState({
      dropUp: listTop > partOfDocHeight,
    });
  }

  addToArchiveConfirmed = () => {
    moveSegmentToArchive(this.props.segment.id).then(response => {
      if (response.status === 'OK') {
        filterActions.updateFilters();
        const note = () => {
          return <div>Segmentation status turned passive successfully.</div>;
        };
        uiActions.showNotification({
          content: note,
        });
      }
    });
  };

  addToLiveConfirmed = () => {
    moveSegmentToLive(this.props.segment.id, response => {
      if (response.status === 'OK') {
        filterActions.updateFilters();
        const note = () => {
          return <>Segmentation status turned live successfully.</>;
        };
        uiActions.showNotification({
          content: note,
        });
      }
    });
  };

  addToTestConfirmed = () => {
    moveSegmentToTest(this.props.segment.id, response => {
      if (response.status === 'OK') {
        filterActions.updateFilters();
        const note = () => {
          return <>Segmentation status turned test successfully.</>;
        };
        uiActions.showNotification({
          content: note,
        });
      }
    });
  };

  createEngagementCampaign = () => {
    return browserHistory.push('/behavioural-targeting/engagement/view-all');
  };

  usePersonalisedAds = () => {
    const location = getExternalPersonalisedAds();
    window.open(location, '_blank');
  };

  sendBulkEmail = () => {
    return browserHistory.push('/email/recommendation');
  };

  sendBulkPush = () => {
    window.location.state = {
      smartSegmentId: this.props.segment.id,
    };
    return browserHistory.push('/push/add/PUSH_NOTIFICATION');
  };

  addToArchive() {
    uiActions.confirmationDialog({
      title: 'Are you sure to make the segment passive?',
      content: '',
      onConfirm: this.addToArchiveConfirmed,
    });
  }

  addToLive() {
    uiActions.confirmationDialog({
      title: 'Are you sure to make the segment Live?',
      content: '',
      onConfirm: this.addToLiveConfirmed,
    });
  }

  addToTest() {
    uiActions.confirmationDialog({
      title: 'Are you sure to make the segment Test?',
      content: '',
      onConfirm: this.addToTestConfirmed,
    });
  }

  showSegmentHistory() {
    const segmentId = this.props.segment.id;
    const tempName =
      this.props.segment.name === 'Sleeping Opportunists'
        ? 'Discount Seekers'
        : this.props.scenarioName || this.props.segment.name;
    const segmentHistoryModal = () => (
      <SegmentationHistoryModal id={segmentId} />
    );
    modalActions.openModal({
      title: `Segment History ( ${tempName} )`,
      content: segmentHistoryModal,
    });
  }

  report() {
    const segmentName =
      this.props.segment?.name === 'Sleeping Opportunists'
        ? 'Discount Seekers'
        : this.props.segment?.name;
    const link = `/reports/segment-single/${
      segmentAlgorithmTitles(segmentName)?.title
    }`;
    uiActions.redirect(link);
  }

  render() {
    let { actions } = this.state;

    let listClass = 'widget-nav-popup action-nav-popup';
    if (this.state.dropUp) {
      actions = this.state.actions.slice().reverse();
      listClass = 'widget-nav-popup action-nav-popup drop-up';
    }

    return (
      <nav
        className='widget-nav action-nav'
        aria-haspopup='true'
        ref='actionMenu'
      >
        <a className='secondary-action'>{t('Actions')}</a>

        <ul
          className={listClass}
          style={this.props.id !== 'sgVw' ? { left: '-114px' } : {}}
        >
          {actions.map(action => {
            if (
              this.props.segment.name === 'Waiting First Order' &&
              action.name === 'Details'
            ) {
              return null;
            }

            if (
              this.props.segment.name === 'Lost Customers' &&
              (action.name === 'Passive' || action.name === 'History')
            ) {
              return null;
            }

            if (
              this.props.mailProvider !== 'SEGMENTIFY_V2' &&
              action.action === 'sendBulkEmail'
            ) {
              return null;
            }

            if (
              this.props?.features?.personalization === 'ACTIVE' &&
              action.action === 'createEngagementCampaign'
            ) {
              return null;
            }

            if (
              this.props?.features?.personalisation.status === 'ACTIVE' &&
              action.action === 'usePersonalisedAds'
            ) {
              return null;
            }

            if (
              this.props?.features?.push === 'ACTIVE' &&
              action.action === 'sendBulkPush'
            ) {
              return null;
            }

            if (
              (action.action === 'sendBulkEmail' ||
                action.action === 'sendBulkPush' ||
                action.action === 'usePersonalisedAds' ||
                action.action === 'createEngagementCampaign' ||
                action.action === 'addToArchive') &&
              this.props.segment.status === 'PASSIVE'
            ) {
              return null;
            }

            if (
              (action.action === 'sendBulkEmail' ||
                action.action === 'sendBulkPush' ||
                action.action === 'usePersonalisedAds' ||
                action.action === 'createEngagementCampaign' ||
                action.action === 'addToTest') &&
              this.props.segment.status === 'DRAFT'
            ) {
              return null;
            }

            if (
              action.action === 'addToLive' &&
              this.props.segment.status === 'ACTIVE'
            ) {
              return null;
            }

            return (
              <li key={action.action}>
                <a
                  className='widget-nav-popup-item action-nav-popup-item'
                  onClick={this.handleClick.bind(null, action.action)}
                  {...idPropFunc(
                    this.props.id,
                    this.props.segment.name,
                    action.name,
                  )}
                >
                  {action.iconSvg ? (
                    <Icons name={action.icon} color='#fff' />
                  ) : (
                    <Icon name={action.icon} />
                  )}
                  {t(action?.name)}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

const mapStatesToProps = store => ({
  mailProvider:
    store.switchedUser?.switchedUser?.account?.mailConfiguration?.provider,
  features: store.switchedUser?.switchedUser?.account?.features,
});

export default connect(mapStatesToProps)(SegmentActions);
