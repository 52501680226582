import React, { useCallback, useContext, useEffect, useState } from 'react';
import './SFYCardBundle.scss';

import { SFYCardBundleCategory, SFYCardBundleProduct } from './Bundles/index';
import Icons from '../../icons';
import { Context as ShopTheLookContext } from '../../../views/dynamic-bundle/shop-the-look/store/shopTheLookProvider';
import { useHover } from '../../ruleBasedSegmentation/hooks/useHover';

const getBundleItem = (
  bundleType,
  bundleDetail,
  index,
  parentSetter,
  filterParams,
  setBGColorClassName,
) => {
  switch (bundleType) {
    case 'product':
      return (
        <SFYCardBundleProduct
          bundleDetail={bundleDetail}
          bundleIndex={index}
          setBGColorClassName={setBGColorClassName}
        />
      );
    case 'category':
      return (
        <SFYCardBundleCategory
          bundleDetail={bundleDetail}
          bundleIndex={index}
          filterParams={filterParams}
          setFilterParams={parentSetter}
          setBGColorClassName={setBGColorClassName}
        />
      );
    default:
      return (
        <SFYCardBundleProduct bundleDetail={bundleDetail} bundleIndex={index} />
      );
  }
};

const SFYCardBundle = ({
  cardTitle,
  type,
  bundleDetail,
  index,
  bundleListLength,
}) => {
  const { removeSTLBundle, setSTLInclusionExclusionOfCategory } = useContext(
    ShopTheLookContext,
  );

  const [hoverRef, isHovered] = useHover();
  const [BGColorClassName, setBGColorClassName] = useState('');
  const [filterParams, setFilterParams] = useState(bundleDetail?.params || {});

  const removeBundle = () => {
    removeSTLBundle(index);
  };

  const parentSetter = useCallback(
    childState => {
      setFilterParams(childState);
    },
    [setFilterParams],
  );

  useEffect(() => {
    setSTLInclusionExclusionOfCategory({
      params: filterParams,
      index,
      bundleType: bundleDetail.type,
    });
  }, [filterParams]);

  return (
    <div
      className={`sfy-card-bundle ${BGColorClassName} ${isHovered &&
        'bg-color-red'}`}
    >
      <span className='item-label'>{cardTitle}</span>
      {getBundleItem(
        type,
        bundleDetail,
        index,
        parentSetter,
        filterParams,
        setBGColorClassName,
      )}
      {bundleListLength > 2 && (
        <div
          className='delete-bundle-button'
          ref={hoverRef}
          onClick={() => removeBundle()}
        >
          <span>Delete Bundle</span>
          <div className='icon-delete'>
            <Icons name='trashBin' color='#CC4230' width={12} height={12} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SFYCardBundle;
