import React from 'react';
import PropTypes from 'prop-types';

import { openUrlInNewTab } from '../../utils';
import { quickActionItems } from '../../msp-static-data';

import RightArrowInCircleIcon from '../../icons/RightArrowInCircleIcon';

const ActionCard = ({ title, text, icon, redirectUrl }) => {
  // If redirectUrl is empty then we add only Coming Soon text.
  return (
    <div
      className={`action-card small-card ${redirectUrl ? 'clickable' : ''}`}
      onClick={() => {
        openUrlInNewTab(redirectUrl);
      }}
    >
      <div className='card-header'>
        <div className='card-header-icon'>{icon}</div>
        <div className='card-header-title'>{title}</div>
      </div>
      <div className='card-content'>
        <div className='card-content-text'>{text}</div>
      </div>
      <div className='card-footer'>
        {redirectUrl ? (
          <div className='card-footer-link'>
            <RightArrowInCircleIcon />
          </div>
        ) : (
          <div className='card-footer-link-text'>Coming Soon</div>
        )}
      </div>
    </div>
  );
};

ActionCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  redirectUrl: PropTypes.string.isRequired,
};

const QuickActions = () => {
  return (
    <div className='accounts-group-field'>
      <div className='accounts-title'>Quick Actions</div>
      <div className='accounts-content'>
        <div className='accounts-column'>
          {quickActionItems.map(item => (
            <ActionCard
              key={item.title.replaceAll(' ', '')}
              title={item.title}
              text={item.text}
              icon={<item.icon />}
              redirectUrl={item.redirectUrl}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuickActions;
