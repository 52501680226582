import React from 'react';
import styles from './EventList.module.scss';
import { eventsConstants } from '../../../../../components/sfy-components/CodeEditor/constants/events';
import { eventMap } from '../../constants/constants';
import Section from './Section';

const EventList = () => {
  const eventList = [];
  Object.keys(eventsConstants).forEach(key => {
    eventList.push({
      header: eventMap[key],
      events: eventsConstants[key],
    });
  });
  return (
    <div className={styles['event-list']}>
      {eventList.map(item => (
        <div className={styles['event-list__area']}>
          <Section data={item} />
        </div>
      ))}
    </div>
  );
};

export default EventList;
