import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import { getNow, convertToEpochTime } from '../../../system/date';
import { socialProofTypes } from '../../../constants/datamaps/campaigns';
import { advancedConfig } from '../../../constants/datamaps/campaign';
import {
  addCampaign,
  getCampaign,
  editCampaign,
} from '../../../modules/campaign/events';
import { isSwitchAccount } from '../../../modules/auth/user';
import { uiActions, wizardActions } from '../../../actions';
import { notification, t } from '../../../system/ui';
import {
  TestModeAddPersonalization,
  LiveModeUpdatePersonalization,
} from '../../../constants/datamaps/notifications';
import {
  addBreadcrumb,
  capitalize,
  separatePositions,
  margePositions,
  getSelectedDevices,
  getTimeRange,
  margeTimeRange,
  getCondition,
  margeCondition,
} from './utils';
import Icons from '../../../components/icons';
import {
  desktopPosOptions,
  mobilePosOptions,
  timingOptions,
  frequencyOptions,
  conditionOperatorOptions,
  conditionTimeUnits,
  emptyCampaignData,
} from './constants';
import Fields from '../../../modules/campaign/fields';
import SPPreview from './components/SPPreview';
import CampaignName from './components/CampaignName';
import CampaignMessage from './components/CampaignMessage';
import TimeRange from './components/TimeRange';
import DeviceType from './components/DeviceType';
import Multiplier from './components/Multiplier';
import Condition from './components/Condition';
import PopupStyle from './components/PopupStyle';
import StartEndDate from './components/StartEndDate';
import TimingFrequency from './components/TimingFrequency';
import TargetAudience from './components/TargetAudience';
import FinalizeButtons from './components/FinalizeButtons';
import { setTitle } from '../../../system/document';

const SocialProof = ({ params }) => {
  const userAccount = useSelector(state => state.user.user.account);
  const switchedUserAccount = useSelector(
    state => state.switchedUser.switchedUser.account,
  );
  const account = isSwitchAccount() ? switchedUserAccount : userAccount;
  const wizardAdvancedConfig = useRef();
  const { campaignType, mode, campaignId } = params;
  const campaignDetail = socialProofTypes[campaignType];
  const [fetchedCampaign, setFetchedCampaign] = useState({});
  const [priority, setPriority] = useState(0);
  const [campaignName, setCampaignName] = useState('');
  const [campaignMessage, setCampaignMessage] = useState(
    account.mainLanguage === 'TR'
      ? campaignDetail?.descriptionTR
      : campaignDetail?.description,
  );
  const [campaignStatus, setCampaignStatus] = useState('');
  const [timeRange, setTimeRange] = useState(
    campaignDetail?.timeRanges.length ? campaignDetail?.timeRanges[0] : '',
  );
  const [checkedDTDesktop, setCheckedDTDesktop] = useState(true);
  const [checkedDTMobile, setCheckedDTMobile] = useState(true);
  const [desktopPosition, setDesktopPosition] = useState(desktopPosOptions[6]);
  const [mobilePosition, setMobilePosition] = useState(mobilePosOptions[2]);
  const [colorBackground, setColorBackground] = useState('#FFFFFF');
  const [colorText, setColorText] = useState('#000000');
  const [startDate, setStartDate] = useState(getNow());
  const [endDate, setEndDate] = useState('');
  const [timing, setTiming] = useState({
    type: timingOptions[0].value,
    param: '',
  });
  const [delayAllPages, setDelayAllPages] = useState(false);
  const [frequency, setFrequency] = useState({
    type: frequencyOptions[0].value,
    param: '',
  });
  const [visitorSegment, setVisitorSegment] = useState({
    include: true,
    segment: '',
    type: 'SEGMENT',
  });
  const [visitorType, setVisitorType] = useState({
    include: true,
    name: '',
    type: 'VISITOR',
  });
  const [multiplier, setMultiplier] = useState({
    isVisible: false,
    value: 2,
  });
  const [condition, setCondition] = useState({
    isVisible: false,
    default: {
      operator: conditionOperatorOptions[0].value,
      value: '',
      maxValue: '',
      unit: conditionTimeUnits[0].value,
      maxUnit: conditionTimeUnits[0].value,
    },
    review: {
      operator: conditionOperatorOptions[0].value,
      value: '',
      maxValue: '',
      unit: conditionTimeUnits[0].value,
      maxUnit: conditionTimeUnits[0].value,
    },
    score: {
      operator: conditionOperatorOptions[0].value,
      value: '',
      maxValue: '',
      unit: conditionTimeUnits[0].value,
      maxUnit: conditionTimeUnits[0].value,
    },
  });
  const [savingOverTime, setSavingOverTime] = useState({
    isVisible: false,
    default: {
      operator: conditionOperatorOptions[0].value,
      value: '',
      maxValue: '',
      unit: conditionTimeUnits[0].value,
      maxUnit: conditionTimeUnits[0].value,
    },
    review: {
      operator: conditionOperatorOptions[0].value,
      value: '',
      maxValue: '',
      unit: conditionTimeUnits[0].value,
      maxUnit: conditionTimeUnits[0].value,
    },
    score: {
      operator: conditionOperatorOptions[0].value,
      value: '',
      maxValue: '',
      unit: conditionTimeUnits[0].value,
      maxUnit: conditionTimeUnits[0].value,
    },
  });

  const [icon, setIcon] = useState(undefined);

  const [fadeOut, setFadeOut] = useState(null);

  const toggleOptionalFields = type => {
    if (type === 'multiplier') {
      setMultiplier({ ...multiplier, isVisible: !multiplier.isVisible });
    } else if (type === 'condition') {
      setCondition({ ...condition, isVisible: !condition.isVisible });
    } else if (type === 'saving-over') {
      setSavingOverTime({
        ...savingOverTime,
        isVisible: !savingOverTime.isVisible,
      });
    }
  };

  const setCampaignData = newCampaignData => {
    // eslint-disable-next-line no-shadow
    const { devices, timing, filters } = newCampaignData;
    setCampaignName(
      mode === 'duplicate'
        ? `${newCampaignData.name} clone`
        : newCampaignData.name,
    );
    setCampaignStatus(
      // eslint-disable-next-line no-nested-ternary
      mode === 'edit'
        ? newCampaignData.status === 'ACTIVE'
          ? 'Live'
          : 'Test'
        : '',
    );
    setCheckedDTDesktop(devices.includes('PC'));
    setCheckedDTMobile(devices.includes('MOBILE'));
    setStartDate(newCampaignData.startDate);
    setEndDate(newCampaignData.endDate);
    setTiming({
      type: timing.type === 'DELAY_ALL_PAGES' ? 'DELAY' : timing.type,
      param: timing.param,
    });
    setDelayAllPages(timing.type === 'DELAY_ALL_PAGES');
    setFrequency({
      type: newCampaignData.frequency.type,
      param: newCampaignData.frequency.param,
    });
    filters.forEach(filter => {
      if (filter.type === 'SEGMENT') {
        setVisitorSegment(filter);
      } else if (filter.type === 'VISITOR') {
        setVisitorType(filter);
      }
    });
    setCampaignMessage(newCampaignData.campaignMessage);
    setColorBackground(newCampaignData.bgColor);
    setColorText(newCampaignData.textColor);
    setDesktopPosition(
      margePositions(
        newCampaignData.verticalPosition,
        newCampaignData.horizontalPosition,
        'desktop',
      ),
    );
    setMobilePosition(
      margePositions(
        newCampaignData.mobileVerticalPosition,
        'center', // No horizontal position for mobile
        'mobile',
      ),
    );
    setTimeRange(margeTimeRange(newCampaignData.timeRange));
    setCondition(
      margeCondition(
        newCampaignData.conditions,
        newCampaignData.scoreConditions,
        newCampaignData.reviewConditions,
        campaignDetail.condition,
      ),
    );
    setIcon(newCampaignData?.iconUrl || undefined);

    if (newCampaignData?.multiplier?.multiplier) {
      setMultiplier({
        isVisible: true,
        value: newCampaignData.multiplier.multiplier,
      });
    }
    setFadeOut(newCampaignData?.fadeOut || undefined);

    if (newCampaignData?.savingOverTime) {
      setSavingOverTime(
        margeCondition(
          newCampaignData.savingOverTime,
          newCampaignData.scoreConditions,
          newCampaignData.reviewConditions,
          campaignDetail.savingOverConditions,
        ),
      );
    }

    if (newCampaignData?.priority) {
      setPriority(newCampaignData.priority);
    }
    addBreadcrumb(campaignType, newCampaignData.name);
  };

  const getCampaignDetails = () => {
    uiActions.isLoading();
    getCampaign(campaignId).then(res => {
      uiActions.isLoaded();
      if (res.response !== null) {
        setFetchedCampaign(res.response);
        setCampaignData(res.response);
      }
    });
  };

  useEffect(() => {
    setTitle('Social Proof');
    if (mode === 'edit' || mode === 'duplicate') {
      getCampaignDetails();
    } else {
      addBreadcrumb(campaignType);
    }
  }, [mode, campaignId, campaignType]);

  const createCampaign = campaignData => {
    addCampaign(campaignData)
      .then(response => {
        uiActions.isLoaded();
        const instance = response.response.instanceId;
        let note = TestModeAddPersonalization;
        if (response.response.status === 'ACTIVE') {
          note = LiveModeUpdatePersonalization;
        }
        uiActions.showNotification({
          content: note,
        });
        setCampaignData(response.response);
        browserHistory.push(
          `/campaign/social-proof/${campaignType}/edit/${instance}`,
        );
      })
      .catch(response => {
        uiActions.isLoaded();
        const error = () => <p>{response.response.message}</p>;

        uiActions.showNotification({
          content: error,
          className: 'notification-fail',
        });

        wizardActions.saveReset();
        wizardActions.wizardReset();
      });
  };

  const editCurrentCampaign = campaignData => {
    editCampaign(campaignData)
      .then(response => {
        uiActions.isLoaded();
        const instance = response.response.instanceId;
        let note = TestModeAddPersonalization;
        if (response.response.status === 'ACTIVE') {
          note = LiveModeUpdatePersonalization;
        }
        uiActions.showNotification({
          content: note,
        });
        setCampaignData(response.response);
        browserHistory.push(
          `/campaign/social-proof/${campaignType}/edit/${instance}`,
        );
      })
      .catch(response => {
        uiActions.isLoaded();
        const error = () => <p>{response.response.message}</p>;

        uiActions.showNotification({
          content: error,
          className: 'notification-fail',
        });

        wizardActions.saveReset();
        wizardActions.wizardReset();
      });
  };

  const goTestOrLive = type => {
    const advancedConfigData =
      wizardAdvancedConfig?.current?.refs?.advancedConfig?.state?.values || {};
    const tempFilters = [];

    const campaignData = { ...emptyCampaignData };
    campaignData.type = campaignDetail.type;
    campaignData.name = campaignName;
    if (mode === 'edit' && campaignId) {
      campaignData.instanceId = campaignId;
    }
    campaignData.status = type;
    campaignData.devices = getSelectedDevices(
      checkedDTDesktop,
      checkedDTMobile,
    );
    campaignData.startDate = convertToEpochTime(startDate);
    if (endDate) {
      campaignData.endDate = convertToEpochTime(endDate);
    }
    campaignData.timing = {
      param: timing.param,
      type: delayAllPages ? 'DELAY_ALL_PAGES' : timing.type,
    };
    campaignData.frequency = frequency;
    if (visitorSegment.segment) {
      tempFilters.push(visitorSegment);
    }
    if (visitorType.name) {
      tempFilters.push(visitorType);
    }
    campaignData.fadeOut = fadeOut;
    campaignData.filters = tempFilters;
    campaignData.campaignMessage = campaignMessage;
    campaignData.bgColor = colorBackground;
    campaignData.textColor = colorText;
    campaignData.html = advancedConfigData.html || '';
    campaignData.css = advancedConfigData.css || '';
    campaignData.preJs = advancedConfigData.preJs || '';
    campaignData.postJs = advancedConfigData.postJs || '';
    campaignData.verticalPosition = separatePositions(
      desktopPosition,
      'desktop',
    ).verticalPos;
    campaignData.horizontalPosition = separatePositions(
      desktopPosition,
      'desktop',
    ).horizontalPos;
    campaignData.mobileVerticalPosition = separatePositions(
      mobilePosition,
      'mobile',
    ).verticalPos;
    campaignData.mobileHorizontalPosition = separatePositions(
      mobilePosition,
      'mobile',
    ).horizontalPos;
    const conditionData = getCondition(
      condition,
      'default',
      campaignDetail.condition,
    );
    if (conditionData) {
      campaignData.conditions = [conditionData];
    }
    const savingOverTimeData = getCondition(
      savingOverTime,
      'default',
      campaignDetail.savingOverConditions,
    );
    if (savingOverTimeData) {
      campaignData.savingOverTime = [savingOverTimeData];
    }
    const scoreConditionData = getCondition(
      condition,
      'score',
      campaignDetail.condition,
    );
    if (scoreConditionData) {
      campaignData.scoreConditions = [scoreConditionData];
    }
    const reviewConditionData = getCondition(
      condition,
      'review',
      campaignDetail.condition,
    );
    if (reviewConditionData) {
      campaignData.reviewConditions = [reviewConditionData];
    }
    if (timeRange) {
      campaignData.timeRange = getTimeRange(timeRange);
    }

    if (fadeOut) {
      campaignData.fadeOut = fadeOut;
    }

    if (icon) {
      campaignData.iconUrl = icon;
    }
    if (multiplier.isVisible && multiplier.value) {
      campaignData.multiplier = { multiplier: parseFloat(multiplier.value) };
    }

    if (priority !== 0) {
      campaignData.priority = priority;
    }

    uiActions.isLoading();
    if (mode === 'edit') {
      editCurrentCampaign(campaignData);
    } else {
      createCampaign(campaignData);
    }
  };

  const handleSubmit = type => {
    const displayStatus = type.toLowerCase() === 'test' ? 'Test' : 'Live';
    const errors = document.querySelectorAll('.has-error');
    const wrongValues = document.querySelectorAll('.wrong-value');

    let errorMessage = '';
    if (errors.length > 0) {
      errorMessage = 'Please fill in all required fields.';
    } else if (wrongValues.length > 0) {
      errorMessage = 'Please fix all wrong values.';
    }

    if (errorMessage) {
      const content = () => <p>{errorMessage}</p>;
      notification({
        content,
        className: 'notification-fail',
      });
    } else {
      uiActions.confirmationDialog({
        title: `Go ${displayStatus} the Campaign?`,
        content: `Are you sure you want to get the campaign ${displayStatus}?`,
        onConfirm: () => {
          goTestOrLive(type);
        },
      });
    }
  };
  // eslint-disable-next-line no-unused-vars
  const iconSetter = (iconVal, iconType) => {
    setIcon(iconVal);
  };

  return (
    <div id='wizard' className='wizard social-proof-form'>
      <h3 className='page-title'>
        {campaignStatus && (
          <span
            className={`status-label status-label-${campaignStatus.toLowerCase()}`}
          >
            {campaignStatus}{' '}
          </span>
        )}
        {capitalize(campaignType.replaceAll('-', ' '))}
      </h3>
      <div className='page-content-block form-content'>
        {/* Campaign Name */}
        <CampaignName value={campaignName} onChange={setCampaignName} />
        {/* Campaign Message */}
        <CampaignMessage
          value={campaignMessage}
          onChange={setCampaignMessage}
        />
        {/* Time Range */}
        {campaignDetail.timeRanges.length > 0 && (
          <TimeRange
            value={timeRange}
            onChange={setTimeRange}
            selectOptions={campaignDetail.timeRanges}
            hasRealTime={campaignDetail.hasRealTimeTimeRange}
          />
        )}
        {/* Device Type */}
        <DeviceType
          valueDesktop={checkedDTDesktop}
          valueMobile={checkedDTMobile}
          onChangeDesktop={setCheckedDTDesktop}
          onChangeMobile={setCheckedDTMobile}
        />
        {/* Optional Fields */}
        <div className='form-element optional-fields'>
          <div className='element-row-field'>
            {campaignDetail.multiplier.isActive && (
              <div
                className={`element-row-field option-item multiplier ${
                  multiplier.isVisible ? 'active' : ''
                }`}
                onClick={() => toggleOptionalFields('multiplier')}
              >
                <Icons name='plusCircleSmall' />
                <span className='element-row-label'>
                  {t('Include a Multiplier')}
                </span>
              </div>
            )}
            {campaignDetail.savingOverConditions?.isActive && (
              <div
                className={`element-row-field option-item saving-over ${
                  savingOverTime.isVisible ? 'active' : ''
                }`}
                onClick={() => toggleOptionalFields('saving-over')}
              >
                <Icons name='plusCircleSmall' />
                <span className='element-row-label'>
                  {t('Saving Time Condition')}
                </span>
              </div>
            )}
            {campaignDetail.condition.isActive && (
              <div
                className={`element-row-field option-item condition ${
                  condition.isVisible ? 'active' : ''
                }`}
                onClick={() => toggleOptionalFields('condition')}
              >
                <Icons name='plusCircleSmall' />
                <span className='element-row-label'>
                  {params.campaignType === 'saving-over'
                    ? t('Amount Condition')
                    : t('Include a Condition')}
                </span>
              </div>
            )}
          </div>
        </div>
        {/* Optional Multiplier */}
        {multiplier.isVisible && (
          <Multiplier
            currentState={multiplier}
            value={multiplier.value}
            onChange={setMultiplier}
            closeField={toggleOptionalFields}
            componentDetail={campaignDetail.multiplier}
          />
        )}
        {/* Optional Condition */}
        {condition.isVisible && (
          <Condition
            currentState={condition}
            onChange={setCondition}
            closeField={toggleOptionalFields}
            componentDetail={campaignDetail.condition}
            type='condition'
          />
        )}
        {/* Optional Saving Over Time */}
        {savingOverTime.isVisible && (
          <Condition
            currentState={savingOverTime}
            onChange={setSavingOverTime}
            closeField={toggleOptionalFields}
            componentDetail={campaignDetail.savingOverConditions}
            type='saving-over'
          />
        )}

        {/* Pop-up Style */}
        <PopupStyle
          desktopPosition={desktopPosition}
          mobilePosition={mobilePosition}
          colorBackground={colorBackground}
          colorText={colorText}
          onChangeDesktopPosition={setDesktopPosition}
          onChangeMobilePosition={setMobilePosition}
          onChangeColorBg={setColorBackground}
          onChangeColorText={setColorText}
          icon={icon}
          onChangeIcon={iconSetter}
        />
        {/* Start/End Date */}
        <StartEndDate
          startDate={startDate}
          endDate={endDate}
          onChangeStartDate={setStartDate}
          onChangeEndDate={setEndDate}
        />
        {/* Timing Frequency */}
        <TimingFrequency
          timingState={timing}
          frequencyState={frequency}
          fadeOutState={fadeOut}
          delayAllPages={delayAllPages}
          onChangeTiming={setTiming}
          onChangeFrequency={setFrequency}
          onChangeDelay={setDelayAllPages}
          onChangeFadeOut={setFadeOut}
        />
        {/* Target Audience */}
        <TargetAudience
          visitorSegmentState={visitorSegment}
          visitorTypeState={visitorType}
          onChangeVisitorSegment={setVisitorSegment}
          onChangeVisitorType={setVisitorType}
        />
      </div>
      <div className='page-content-preview'>
        <SPPreview
          message={campaignMessage}
          dynamicKeys={campaignDetail.dynamicKeys}
          verticalPos={
            separatePositions(desktopPosition, 'desktop').verticalPos
          }
          horizontalPos={
            separatePositions(desktopPosition, 'desktop').horizontalPos
          }
          verticalPosMobile={
            separatePositions(mobilePosition, 'mobile').verticalPos
          }
          bgColor={colorBackground}
          textColor={colorText}
        />
        <FinalizeButtons onSubmit={handleSubmit} />
      </div>
      <Fields
        ref={wizardAdvancedConfig}
        campaign={fetchedCampaign}
        comps={[
          {
            field: 'advancedConfig',
            options: advancedConfig.SOCIAL_PROOF,
          },
        ]}
      />
    </div>
  );
};

SocialProof.propTypes = {
  params: PropTypes.shape({
    campaignType: PropTypes.string,
    mode: PropTypes.string,
    campaignId: PropTypes.string,
  }).isRequired,
};

export default SocialProof;
