const favicon = `${window.location.origin}/favicon.png`;

export const loader = {
  html: `<div class='loader'>
          <img src='${favicon}' alt=''/>
        </div>`,
  css: `
    .loader {
      height: 100vh;
      width: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      animation: spin 2s linear infinite;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    
    .loader > img {
      height: 100px;
      border-radius: 50%;
    }`,
};
