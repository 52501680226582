/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { clone } from "../../../system/object";

import { uiActions, wizardActions } from "../../../actions";

import { TextField } from "../../../components/fields";
import { t } from "../../../system/ui";

const defaults = {
  buttonLaterText: "Later",
  buttonAllowText: "Allow"
};

class ConfirmButtons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: clone(defaults)
    };

    this.updateValue = this.updateValue.bind(this);
    this.onAllowTextFieldChange = this.onAllowTextFieldChange.bind(this);
    this.onLaterTextFieldChange = this.onLaterTextFieldChange.bind(this);
  }

  componentDidMount() {
    let buttonLaterText = this.props.campaign.buttonLaterText,
      buttonAllowText = this.props.campaign.buttonAllowText;

    this.updateValue(buttonLaterText, "buttonLaterText");
    this.updateValue(buttonAllowText, "buttonAllowText");
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.buttonLaterText, "buttonLaterText");
      this.updateValue(newProps.campaign.buttonAllowText, "buttonAllowText");
    }
  }

  updateValue(newValue, type) {
    let values = this.state.values;
    values[type] = newValue;
    this.setState({ values });
  }

  onAllowTextFieldChange(e) {
    let tempLaterTexts = this.props.additionalButtonAllowText;
    tempLaterTexts[this.props.selectedLanguage] = e.target.value;
    this.setState({
      additionalButtonAllowText: tempLaterTexts
    });

    this.updateValue(e.target.value, e.target.name);
    uiActions.formEdited();
  }

  onLaterTextFieldChange(e) {
    let tempLaterTexts = this.props.additionalButtonLaterText;
    tempLaterTexts[this.props.selectedLanguage] = e.target.value;
    this.setState({
      additionalButtonLaterText: tempLaterTexts
    });

    this.updateValue(e.target.value, e.target.name);
    uiActions.formEdited();
  }

  render() {
    return (
      <span className={this.props.isPush ? "" : "move-to-end"}>
        <span className="item-field">
          <TextField
            name="buttonLaterText"
            label={this.props.options.cancelLabel}
            className="item-stacked"
            value={this.props.additionalButtonLaterText[this.props.selectedLanguage] || ""}
            required={true}
            onChange={this.onLaterTextFieldChange}
            tooltip={true}
            validation={{ minLength: 1 }}
            tooltipText={t(
              "Enter the call to action text to ask for permission later again. For example, 'Remind me later'"
            )}
          />
        </span>
        <span className="item-field">
          <TextField
            name="buttonAllowText"
            label={this.props.options.confirmLabel}
            className="item-stacked"
            value={this.props.additionalButtonAllowText[this.props.selectedLanguage] || ""}
            required={true}
            onChange={this.onAllowTextFieldChange}
            tooltip={true}
            validation={{ minLength: 1 }}
            tooltipText={t(
              "Enter the call to action text to give permission. For example, 'Allow'"
            )}
          />
        </span>
      </span>
    );
  }
}

ConfirmButtons.defaultProps = {
  options: {
    cancelLabel: "Later Button",
    confirmLabel: "Allow Button"
  }
};

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  ConfirmButtons
);
