
"use strict";


const status = {
  ACTIVE: "Live",
  DRAFT: "Draft",
  PASSIVE: "Passive"
  };

export {
  status
};