import React from 'react';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import { t } from '../../../../system/ui';
import classes from './addOrEditComponents.module.scss';

const Promotional = ({ component, editCampaign, activeEmailDetails }) => {
  const { params } = activeEmailDetails;
  const { promotional = false } = params;

  const editEmailSettings = (field, value) => {
    const newParams = { ...params, [field]: value };
    editCampaign('params', newParams);
  };

  return (
    <InputCardWrapper type='adaptiveBlock'>
      <div className={classes['promotional-wrapper']}>
        <span className={classes['promotional-text']}>
          {t('Not promotional')}
        </span>
        <div className='promotional'>
          <label className='CountSettings__control'>
            <input
              id={component.name}
              key={component.name}
              type='checkbox'
              checked={promotional === 'true' || promotional === true}
              onChange={() => editEmailSettings('promotional', !promotional)}
            />
          </label>
        </div>
      </div>
    </InputCardWrapper>
  );
};

export default Promotional;
