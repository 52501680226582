import React from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';

import { dialogActions } from '../../actions';
import Icons from '../icons';
import Icon from '../icon';
import { isSuperUser, isSwitchAccount } from '../../modules/auth/user';
import { updateEmailStatus } from '../../modules/emails/ajax';
import { campaignNames, mailGroups } from '../reports/utils';
import Tooltip from '../tooltip';
import { getFormattedDate } from '../../system/date';
import { t } from '../../system/ui';
import EmailStats from './email-stats';
import EmailWidgetActions from './email-actions';
import WidgetOptions from './options';

export const mailGroupTitles = type => {
  let title = '';
  Object.keys(mailGroups).forEach(group => {
    if (mailGroups[group].indexOf(type) > -1) {
      title = campaignNames[group];
    }
  });
  return title;
};

export const campaignBadges = [
  {
    icon: 'periodicPush',
    color: '#56bbd8',
    tooltip: 'Periodic Mail Campaign',
    elements: [
      'CHURN',
      'WE_MISSED_YOU',
      'PRICE_DROP',
      'TOP_SELLERS',
      'NEW_COMERS',
      'NICE_TO_MEET_YOU',
      'BACK_IN_STOCK',
      'REPLENISHMENT',
    ],
  },
  {
    icon: 'personalizedPush',
    color: '#66d281',
    tooltip: 'Trigger Based Mail Campaign ',
    elements: [
      'ORDER_FOLLOW_UP',
      'ABANDONED_CART',
      'LAST_VISIT_ALTERNATIVES',
      'LAST_VISIT_REMINDER',
      'RECOMMENDATION',
    ],
  },
];

class EmailCard extends React.Component {
  constructor(props) {
    super(props);
    this.goTestFromArchive = this.goTestFromArchive.bind(this);
    this.goTestConfirmed = this.goTestConfirmed.bind(this);
    this.state = {
      userRole: '',
    };
  }

  componentDidMount() {
    const user = isSwitchAccount() ? this.props.switchedUser : this.props.user;
    this.setState({
      userRole: user.userRole,
    });
  }

  getCampaignBadges() {
    const { type } = this.props.campaign;
    return find(campaignBadges, item => {
      const index = item.elements.indexOf(type);
      return index > -1;
    });
  }

  goTestFromArchive() {
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('Are you sure to switch campaign to test mode?'),
      onConfirm: () => {
        this.goTestConfirmed();

        dialogActions.closeDialog();
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  goTestConfirmed() {
    updateEmailStatus('TEST', this.props.campaign.instanceId, response => {
      if (response) {
        this.props.onChangeStatus(
          this.props.campaign.instanceId,
          'TEST',
          'TEST',
        );
      }
    });
  }

  render() {
    let campaignStatusMarkup = null;
    const badge = this.getCampaignBadges();
    if (badge) {
      const givenClass = 'widget-timelimit-badge widget-timelimit-badge-';
      campaignStatusMarkup = (
        <Tooltip
          content={badge.tooltip}
          alignment='center'
          className='widget-timelimit-tooltip widget-push-timelimit-tooltip'
        >
          <div className={givenClass}>
            {badge.subIcon && (
              <div style={{ position: 'absolute', right: '5px', top: '-25px' }}>
                <Icons
                  name={badge.subIcon}
                  color={badge.color}
                  width='15'
                  height='15'
                />
              </div>
            )}
            <Icons
              name={badge.icon}
              color={badge.color}
              width='25'
              height='25'
            />
          </div>
        </Tooltip>
      );
    }
    return (
      <div className='widget page-content-block' ref='widget'>
        {!this.props.hideWidgetOptions && this.state.userRole === 'ADMIN' && (
          <WidgetOptions
            {...this.props.campaign}
            isEmail
            onChangeStatus={this.props.onChangeStatus}
            campaign={this.props.campaign}
            key={Math.random()}
          />
        )}
        {campaignStatusMarkup}
        {isSuperUser() ? (
          <h3 className='widget-page'>
            {mailGroupTitles(this.props.campaign.type)} -{' '}
            <span style={{ color: '#c0bebe', fontWeight: 'normal' }}>
              {this.props.campaign.instanceId}
            </span>
          </h3>
        ) : (
          <h3 className='widget-page'>
            {mailGroupTitles(this.props.campaign.type)}
          </h3>
        )}
        <h2 className='widget-title'>
          {this.props.campaign.scenarioName.split(' Campaign')[0]}
        </h2>

        {this.props.campaign?.schedule?.date && (
          <div
            className={badge ? 'widget-meta widget-metabadge' : 'widget-meta'}
          >
            <span className='widget-meta-date widget-meta-date-noborder'>
              <Icon name='calendar' />
              {getFormattedDate(this.props.campaign?.schedule?.date)}
            </span>
          </div>
        )}

        <hr className='widget-separator' />

        {this.props.hideWidgetOptions && (
          // hideWidgetOptions is used to determine if the view all screen is
          // in the campaign section or reports section
          <>
            <EmailStats
              campaignId={this.props.campaign.instanceId}
              campaignName={this.props.campaign.scenarioName}
              startDate={this.props.startDate}
              rangeAlias={this.props.rangeAlias}
              report={this.props.report}
            />

            <hr className='widget-separator' />
          </>
        )}

        <div className='widget-buttons group'>
          {this.props.campaign.status !== 'PASSIVE' ? (
            <EmailWidgetActions
              campaign={this.props.campaign}
              onChangeStatus={this.props.onChangeStatus}
              isReport={this.props.isReport}
            />
          ) : (
            isSuperUser() && (
              <EmailWidgetActions
                isPassive
                campaign={this.props.campaign}
                onChangeStatus={this.props.onChangeStatus}
                goTestFromArchive={this.goTestFromArchive}
                isReport={this.props.isReport}
              />
            )
          )}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(EmailCard);
