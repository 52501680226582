import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tooltip from '../../../components/tooltip';
import { updateHidePrice } from '../../../actions/abandon';

class HidePrice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidePrice: false,
    };

    this.setHidePrice = this.setHidePrice.bind(this);
    this.handleHidePrice = this.handleHidePrice.bind(this);
  }

  componentDidMount() {
    this.setHidePrice(this.props.campaign);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      if (this.props.campaign.type === 'ABANDONED_CART') {
        this.updateValue(newProps.abandon.hidePrice);
      } else {
        this.updateValue(newProps.campaign.hidePrice);
      }
    }
  }

  setHidePrice(campaign) {
    if (campaign.type === 'ABANDONED_CART') {
      this.setState({
        hidePrice: this.props.abandon.hidePrice,
      });
    } else {
      this.setState({
        hidePrice: campaign.hidePrice,
      });
    }
  }

  handleHidePrice() {
    if (this.props.campaign.type === 'ABANDONED_CART') {
      this.props.updateHidePrice('hidePrice', !this.props.abandon.hidePrice);
      this.setState({
        hidePrice: !this.props.abandon.hidePrice,
      });
    } else {
      this.setState({
        hidePrice: !this.state.hidePrice,
      });
    }
  }

  updateValue(newValue) {
    this.setState({ hidePrice: newValue });
  }

  render() {
    const { updateHidePrice } = this.props;
    return (
      <li className='item item-stacked is-checkbox'>
        <span className='label-mail label-text-field'>
          <label className='item item-stacked is-checkbox'>
            <input
              key='push-hide-price'
              type='checkbox'
              checked={this.state.hidePrice}
              onChange={this.handleHidePrice}
            />
            <span className='item-label'>
              {this.props.options.label}
              <Tooltip
                content={this.props.options.tooltipContent}
                alignment='right'
              >
                <i className='icon-info' role='presentation'>
                  <span className='for-screenreader-only' />
                </i>
              </Tooltip>
            </span>
          </label>
        </span>
      </li>
    );
  }
}

HidePrice.defaultProps = {
  options: {
    required: false,
    label: 'Hide Product Price on Push Message',
    tooltip: true,
    tooltipContent:
      'If you check this option, price of the products will not be shown in the push notifications.',
  },
};

HidePrice.propTypes = {
  options: PropTypes.shape({
    required: PropTypes.bool,
    label: PropTypes.string,
    toolTip: PropTypes.bool,
    tooltipContent: PropTypes.string,
  }),
};

const mapStatesToProps = store => ({
  abandon: store.abandon,
});

const mapDispatchToProps = dispatch => {
  return {
    updateHidePrice: (name, status) => dispatch(updateHidePrice(name, status)),
  };
};

export default connect(mapStatesToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(HidePrice);
