import React, { useState, useEffect } from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';
import { t } from '../../../system/ui';
import { types } from '../../../constants/datamaps/campaign';
import Icon from '../../../components/icon';
import { CampaignList } from '../../../modules/widgets';
import './EmailCampaignPages.scss';

const EmailCampaignPages = ({ campaigns, onUpdate }) => {
  const [accessibleCampaigns, setAccessibleCampaigns] = useState([]);
  const link = '/campaign/email-collection/add';

  const updateHandler = (instanceId, status) => {
    const fakeStatus = 'ACTIVE'; // It's fake because we don't have a real status, and we don't need it.
    onUpdate(instanceId, fakeStatus, status);
  };

  useEffect(() => {
    if (campaigns?.length > 0) {
      const accessible = campaigns.filter(item =>
        ['ACTIVE', 'TEST'].includes(item.status),
      );
      setAccessibleCampaigns(accessible);
    }
  }, [campaigns]);

  return (
    <div>
      <div>
        <h2 className='page-title'>{t(types['email-collection'].title)}</h2>
        {accessibleCampaigns?.length > 0 ? (
          <div className='widget-list-wrapper'>
            <CampaignList
              status='active'
              campaigns={accessibleCampaigns}
              open
              type='email-collection'
              onUpdate={updateHandler}
              newDesign={false}
              idsInAB={[]}
            />
            <CampaignList
              status='test'
              campaigns={accessibleCampaigns}
              type='email-collection'
              onUpdate={updateHandler}
              newDesign={false}
              idsInAB={[]}
            />
          </div>
        ) : (
          <div className='widget-list-wrapper'>
            <div className='widget-list-no-items'>
              {t('No Active Campaigns')}
            </div>
          </div>
        )}


        <Link
          to={link}
          className={classNames(
            'available-type',
            'available-type-personalization',
          )}
          onlyActiveOnIndex={false}
        >
          <h4 className='available-type-name'>
            {t(types['email-collection'].title)}
          </h4>
          <span>{t('Campaign')}</span>
          <span
            className={classNames(
              'available-type-wireframe',
              'available-type-personalization-email-collection',
            )}
          />
        </Link>
        <Link
          to={link}
          className='available-type available-type-custom available-type-personalization'
          onlyActiveOnIndex={false}
        >
          <span className='available-type-wireframe'>
            <Icon name='plus' size='2x' />
            <span>{t(types['email-collection'].new)}</span>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default EmailCampaignPages;
