/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconClock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "14px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        width={this.state.width}
        height={this.state.height}
        viewBox="0 0 24 14"
        version="1.1"
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="icon/Chevron-Up"
            transform="translate(-3.000000, -8.000000)"
            fill="#000000"
            fillRule="nonzero"
          >
            <polygon
              id="Icon-Color"
              transform="translate(15.098946, 14.942412) rotate(-180.000000) translate(-15.098946, -14.942412) "
              points="3.27868655 10.1197477 15.0989459 21.9400071 26.9192053 10.1197477 24.7442741 7.94481645 15.0989379 17.5901526 5.45360169 7.94481645"
            ></polygon>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconClock;
