import React from 'react';

import { modalActions } from '../../../actions';
import PreviewModalContent from '../../../components/push/previewModalContent';
import { t } from '../../../system/ui';

// TODO: What is this for?
const PreviewDialogButton = () => {
  const handleClick = () => {
    const modal = () => <PreviewModalContent />;

    modalActions.openModal({
      title: t('Preview Push Notification'),
      content: modal,
    });
  };
  return (
    <a href className='button preview-action one-whole' onClick={handleClick}>
      <i className='icon-view' role='presentation'>
        <span className='for-screenreader-only' />
      </i>
      {t('Preview')}
    </a>
  );
};

PreviewDialogButton.propTypes = {};

export default PreviewDialogButton;
