'use strict';

import React from 'react';
import { connect } from 'react-redux';

import { t, confirm } from '../../system/ui';

import { campaignActions, filterActions } from '../../actions';

import { goLiveCampaign, goTestCampaign } from '../../modules/campaigns/ajax';

import { getNow } from '../../system/date';

import Tooltip from '../../components/tooltip';

import Icons from '../../components/icons';

import { updateEmailStatus } from '../../modules/emails/ajax';
import {
  moveCampaignToLive,
  moveCampaignToTest,
} from '../../modules/search/ajax';
import { updateCampaignStatus } from 'actions/faceted-search';

class WidgetOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLive: false,
      isLiveEmail: false,
      isLiveSearch: false,
    };

    this.setIsLive = this.setIsLive.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isLive = this.isLive.bind(this);
    this.isActive = this.isActive.bind(this);
    this.isWaiting = this.isWaiting.bind(this);
    this.isCompleted = this.isCompleted.bind(this);
    this.isNotLimited = this.isNotLimited.bind(this);
    this.goLiveEmail = this.goLiveEmail.bind(this);
    this.goTestEmail = this.goTestEmail.bind(this);
    this.goLiveSearch = this.goLiveSearch.bind(this);
    this.goTestSearch = this.goTestSearch.bind(this);
    this.goTestOrLiveFacetedSearch = this.goTestOrLiveFacetedSearch.bind(this);
  }

  componentDidMount() {
    this.setIsLive();
  }

  setIsLive() {
    this.setState({
      isLive: this.isLive(),
      isLiveEmail: this.isLiveEmail(),
      isLiveSearch: this.isLiveSearch(),
    });
  }

  handleChange() {
    if (!this.props.isEmail) {
      if (this.state.isLive) {
        goTestCampaign(this.props.instance, response => {
          if (response.status === 'OK') {
            filterActions.updateFilters();
            this.setState({
              isLive: false,
            });
            //campaignActions.filterTestCampaigns();
          }
        });
      } else {
        goLiveCampaign(this.props.instance, response => {
          if (response.status === 'OK') {
            filterActions.updateFilters();
            this.setState({
              isLive: true,
            });
            //campaignActions.filterActiveCampaigns();
          }
        });
      }
    } else {
      if (this.state.isLiveEmail) {
        updateEmailStatus('TEST', this.props.instanceId, () => {
          this.props.onChangeStatus(
            this.props.instanceId,
            'TEST',
            this.props.status === 'ACTIVE' ? 'ACTIVE' : 'TEST',
          );
        });
      } else {
        updateEmailStatus('ACTIVE', this.props.instanceId, () => {
          this.props.onChangeStatus(this.props.instanceId, 'ACTIVE', 'ACTIVE');
        });
      }
    }
  }

  isLive() {
    return (
      (this.props.status === 'ACTIVE' || this.props.status === 'TEST') &&
      this.props.testMode === false
    );
  }

  isActive() {
    return this.props.status === 'ACTIVE' || this.props.status === 'TEST';
  }

  isWaiting(isMail) {
    let now = getNow().valueOf();
    if (isMail) {
      if (this.props.campaign.schedule) {
        let schedule = this.props.campaign.schedule;
        return this.isLiveEmail() && schedule.date > now;
      } else {
        return this.isLiveEmail() && this.props.campaign.startDate > now;
      }
    } else {
      if (this.props.isSearch) {
        return this.isLiveSearch();
      } else {
        return (
          this.isLive() && this.props.limitations.activeDates.startDate > now
        );
      }
    }
  }

  isCompleted(isMail) {
    let now = getNow().valueOf();
    if (isMail && this.props.campaign.schedule) {
      let schedule = this.props.campaign.schedule;
      if (schedule.type === 'now') {
        return true;
      }
      if (schedule.type === 'once' && schedule.date < now) {
        return true;
      }
      return !schedule.period && schedule.date < now;
    } else {
      return this.isLiveEmail() && this.props.campaign.endDate < now;
    }
  }

  isNotLimited(isMail) {
    return !this.isCompleted(isMail) && !this.isWaiting(isMail);
  }

  isLiveEmail() {
    return this.props.campaign && this.props.campaign.status === 'ACTIVE';
  }

  isLiveSearch() {
    return this.props.campaign && this.props.campaign.status === 'ACTIVE';
  }

  goTestEmail() {
    updateEmailStatus('TEST', this.props.campaign.instanceId, () => {
      this.props.onChangeStatus(
        this.props.campaign.instanceId,
        'TEST',
        this.props.campaign.status === 'ACTIVE' ? 'ACTIVE' : 'TEST',
      );
    });
  }

  goLiveEmail() {
    updateEmailStatus('ACTIVE', this.props.campaign.instanceId, () => {
      this.props.onChangeStatus(
        this.props.campaign.instanceId,
        'ACTIVE',
        'ACTIVE',
      );
    });
  }

  goTestSearch() {
    moveCampaignToTest(this.props.campaign.instanceId, response => {
      if (response.status === 'OK') {
        filterActions.updateFilters();
        this.setState(
          {
            isLiveSearch: false,
          },
          () => {
            this.props.getCampaignsAfterStatusChange();
          },
        );
      }
    });
  }

  goLiveSearch() {
    moveCampaignToLive(this.props.campaign.instanceId, response => {
      if (response.status === 'OK') {
        filterActions.updateFilters();
        this.setState(
          {
            isLiveSearch: true,
          },
          () => {
            this.props.getCampaignsAfterStatusChange();
          },
        );
      }
    });
  }

  goTestOrLiveFacetedSearch() {
    const { dispatch, campaign, getCampaignsAfterStatusChange } = this.props;
    let campaignStatus;
    if (campaign.status === 'ACTIVE') campaignStatus = 'TEST';
    else campaignStatus = 'LIVE';
    dispatch(
      updateCampaignStatus(campaign.instanceId, campaignStatus, () =>
        getCampaignsAfterStatusChange(),
      ),
    );
  }

  render() {
    let campaignStatusMarkup = null;
    if (this.isWaiting(this.props.isEmail && this.props.campaign)) {
      campaignStatusMarkup = (
        <Tooltip
          content={t('Scheduled Campaign')}
          placement='left'
          className='widget-timelimit-tooltip'
        >
          <div className='widget-timelimit-badge'>
            <Icons name='scheduled' color='#547494' />
          </div>
        </Tooltip>
      );
    }
    if (this.isCompleted(this.props.isEmail && this.props.campaign)) {
      campaignStatusMarkup = (
        <Tooltip
          content={t('Completed Campaign')}
          placement='left'
          className='widget-timelimit-tooltip'
        >
          <div className='widget-timelimit-badge'>
            <Icons name='completed' color='#bababb' />
          </div>
        </Tooltip>
      );
    }
    if (this.props.isEmail && this.props.campaign) {
      return (
        <div className='widget-options'>
          {this.isNotLimited(true) ? (
            <div>
              {this.props.campaign.status !== 'PASSIVE' && [
                <label
                  className='item is-toggle'
                  key={this.props.campaign.status}
                >
                  <input
                    type='checkbox'
                    checked={this.props.campaign.status === 'ACTIVE'}
                    onChange={() => {
                      confirm({
                        onConfirm:
                          this.props.campaign.status === 'ACTIVE'
                            ? this.goTestEmail
                            : this.goLiveEmail,
                      });
                    }}
                  />
                  <span className='item-label' />
                </label>,
                <span
                  className='item-help'
                  key={'help ' + this.props.campaign.status}
                >
                  {' '}
                  {this.props.isEmail ? t('draft') : t('test')} /{' '}
                  <span className='live'>{t('live')}</span>
                </span>,
              ]}
            </div>
          ) : (
            <div>{campaignStatusMarkup}</div>
          )}
        </div>
      );
    } else if (this.props.isSearch && this.props.campaign) {
      return (
        <div className='widget-options'>
          <div>
            {this.props.campaign.status !== 'PASSIVE' && [
              <label
                className='item is-toggle'
                key={this.props.isSearch + ' ' + this.props.campaign}
              >
                <input
                  type='checkbox'
                  checked={this.props.campaign.status === 'ACTIVE'}
                  onChange={() => {
                    if (this.props.campaign.type === 'faceted') {
                      confirm({
                        onConfirm: this.goTestOrLiveFacetedSearch,
                      });
                    } else {
                      confirm({
                        onConfirm:
                          this.props.campaign.status === 'ACTIVE'
                            ? this.goTestSearch
                            : this.goLiveSearch,
                      });
                    }
                  }}
                />
                <span className='item-label' />
              </label>,
              <span
                className='item-help'
                key={'help' + this.props.isSearch + ' ' + this.props.campaign}
              >
                {' '}
                {t('test/draft')} / <span className='live'>{t('live')}</span>
              </span>,
            ]}
          </div>
        </div>
      );
    } else {
      return (
        <div className='widget-options'>
          {this.isActive() ? (
            this.isNotLimited() ? (
              <div>
                <label className='item is-toggle'>
                  <input
                    type='checkbox'
                    checked={this.state.isLive}
                    onChange={() => {
                      confirm({ onConfirm: this.handleChange });
                    }}
                  />
                  <span className='item-label' />
                </label>
                <span className='item-help'>
                  {' '}
                  {this.props.isEmail ? t('draft') : t('test')} /{' '}
                  <span className='live'>{t('live')}</span>
                </span>
              </div>
            ) : (
              <div>{campaignStatusMarkup}</div>
            )
          ) : (
            ''
          )}
        </div>
      );
    }
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  status: store.campaigns.status,
});

export default connect(mapStatesToProps)(WidgetOptions);
