/**
 * Created by mehmetyurtar on 27/07/2017.
 */
import React, { Component } from "react";
import classNames from "classnames";
import moment from "moment";

import { t } from "../../system/ui";

import Icons from "../../components/icons";

import CampaignDevices from "./campaign-devices";
import CampaignActions from "./campaign-actions";

import {
  getFormattedDetailedDate,
  isAfterNow,
  isBeforeNow
} from "../../system/date";

const title = {
  live: "In Future/Recurring Notifications",
  completed: "Sent Notifications"
};

class PushList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      isToggled: false,
      liveCampaignsCount: 0
    };

    this.consolidateList = this.consolidateList.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.consolidateList(this.props.campaigns);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaigns !== newProps.campaigns) {
      this.consolidateList(newProps.campaigns);
    }
  }

  consolidateList(list) {
    let campaigns = [];
    let liveCampaignsCount = 0;
    list.forEach(campaign => {
      if (
        campaign &&
        campaign.type.indexOf("PUSH") !== -1 &&
        campaign.schedule
      ) {
        if (
          campaign.status !== "ARCHIVE" &&
          this.props.status.toLowerCase() === "live" &&
          ((campaign.schedule && campaign.schedule.type === "periodic") ||
            (campaign.schedule.type === "one_time" &&
              isAfterNow(moment(campaign.schedule.date))))
        ) {
          campaigns.push(campaign);
          liveCampaignsCount++;
        }

        if (
          campaign.status !== "ARCHIVE" &&
          this.props.status.toLowerCase() === "completed" &&
          campaign.schedule &&
          campaign.schedule.type === "one_time"
        ) {
          if (isBeforeNow(moment(campaign.schedule.date))) {
            campaigns.push(campaign);
          }
        }
      }
    });

    this.setState({
      campaigns: campaigns,
      liveCampaignsCount: liveCampaignsCount
    });
  }

  toggle() {
    this.setState({ isToggled: !this.state.isToggled });
  }

  render() {
    let willToggle = false;
    if (this.props.status === "completed") {
      willToggle = !this.state.liveCampaignsCount;
    } else {
      willToggle = this.props.open;
    }
    if (this.state.campaigns.length === 0) {
      return false;
    }

    let sortedCampaigns = this.state.campaigns.sort(function(a, b) {
      let keyA = a.schedule.date,
        keyB = b.schedule.date;
      // Compare the 2 dates
      if (keyB < keyA) {
        return -1;
      }
      if (keyB > keyA) {
        return 1;
      }
      return 0;
    });

    return (
      <div
        className={classNames("widget-list-has-items", {
          "will-toggle": !willToggle,
          "is-toggled": this.state.isToggled
        })}
      >
        <h3
          className={classNames("page-subtitle", {
            passive: this.props.type === "TEST"
          })}
          onClick={this.toggle}
        >
          {t(title[this.props.status])}({this.state.campaigns.length})
        </h3>
        <ul className="widget-list">
          {sortedCampaigns.map(campaign => {
            return (
              <li key={campaign.instanceId} className="widget-list-item">
                <h2 className="widget-title">{campaign.name}</h2>
                <div className="widget-meta">
                  <span className="widget-meta-date" style={{ borderRight: 0 }}>
                    <span>
                      <span>
                        {this.props.status === "completed"
                          ? t("Sent at")
                          : t("Will be sent at")}
                      </span>
                      {" " + getFormattedDetailedDate(campaign.schedule.date)}
                    </span>
                  </span>
                </div>
                <CampaignActions
                  isReplicate={this.props.status === "completed"}
                  type={this.props.type}
                  instanceId={campaign.instanceId}
                  onUpdate={this.props.onUpdate}
                  campaign={campaign}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default PushList;
