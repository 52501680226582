/**
 * Created by mehmetyurtar on 13/09/2017.
 */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import { t, confirm } from "../system/ui";
import { getLocal } from "../system/object";
import { setTitle } from "../system/document";

import { uiActions } from "../actions";

import { goTestOrLive } from "../modules/campaigns/wizard";

import { config } from "../constants/config";
import { widgetTypes } from "../constants/datamaps/campaigns";


import Icon from "../components/icon";
import { TextField } from "../components/fields";
import WizardItems from "../components/wizard/items";
import WizardTarget from "../components/wizard/target";
import WizardPageType from "../components/wizard/page-type";
import WizardDateSelection from "../components/wizard/date-selection";
import WizardAdvancedConfig from "../components/wizard/advanced-config";
import WizardMediumSelection from "../components/wizard/medium-selection";
import WizardPrioritySelection from "../components/wizard/priority-selection";
import Fields from "../modules/campaign/fields";

const promoFields = {
  fields: [{ field: "audienceOptions" }]
};

class AddPromotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: {},
      title: "",
      scenarioName: "",
      eventName: "",
      showAdvancedConfig: false,
      saved: true,
      selectedType: {
        page: this.props.routeParams.pagetype || "",
        placement: this.props.routeParams.placement || ""
      },
      isSubmitting: false,
      submittingButton: ""
    };

    if (this.props.duplicate) {
      this.state = {
        campaign: {},
        title: this.props.duplicate.isDuplicate
          ? this.props.duplicate.duplicatedValues[1]
          : "",
        scenarioName: this.props.duplicate.isDuplicate
          ? this.props.duplicate.duplicatedValues[0]
          : "",
        eventName: "",
        showAdvancedConfig: false,
        saved: true,
        selectedType: {
          page: this.props.routeParams.pagetype || "",
          placement: this.props.routeParams.placement || ""
        },
        isSubmitting: false,
        submittingButton: ""
      };
    }

    this.initialSaved = this.initialSaved.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onScenarioNameChange = this.onScenarioNameChange.bind(this);
    this.onEventNameChange = this.onEventNameChange.bind(this);

    this.showAdvancedConfig = this.showAdvancedConfig.bind(this);
    this.hideAdvancedConfig = this.hideAdvancedConfig.bind(this);

    this.goTest = this.goTest.bind(this);
    this.validate = this.validate.bind(this);
    this.getEngagementValues = this.getEngagementValues.bind(this);
  }

  componentDidMount() {
    setTitle(
      t(
        "Add " +
          config.menus.primary.recommendations.children[
            this.state.selectedType.page
          ].name +
          " Page" +
          " Campaign"
      )
    );

    uiActions.isValidated();
    uiActions.resetPage();

    uiActions.confirmOnLeave(this);

    uiActions.showBreadcrumb({
      home: {
        name: t("Recommend"),
        icon: "heart",
        slug: "/recommendations"
      },
      top: {
        name: t(
          config.menus.primary.recommendations.children[
            this.state.selectedType.page
          ].name + " Page Widgets"
        ),
        slug: "/recommendations/" + this.state.selectedType.page
      },
      current: {
        name: t(widgetTypes.fullWidth.name + " Style")
      }
    });

    this.initialSaved();
  }

  componentWillReceiveProps(newProps) {
    if (this.state.saved !== newProps.formSaved) {
      this.setState({
        saved: newProps.formSaved
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.validate === true && oldProps.validate === false) {
      this.validate("test");
    }
  }

  initialSaved() {
    this.setState({
      saved: true
    });
  }

  getEngagementValues() {
    let audienceOptions = this.refs.contentExtra.refs.audienceOptions
        .state;
    let audience = [];
    audienceOptions.values.segment.forEach(option => {
      audience.push({
        type: "SEGMENT",
        include: option.member,
        param: option.value
      });
    });
    audienceOptions.values.visitor.forEach(option => {
      audience.push({
        type: "VISITOR",
        include: option.member,
        param: option.value
      });
    });

    return {filters: audience};
  }

  goTest() {
    this.setState({ isSubmitting: true, submittingButton: "test" }, () => {
      goTestOrLive("test", this, this.state.selectedType.page, true,false, this.getEngagementValues());
      uiActions.formSaved();
    });
  }

  validate(type) {
    let errors = document.querySelectorAll(".has-error");

    if (this.props.validate === true && errors.length === 0) {
      if (type === "test") {
        confirm({
          onConfirm: this.goTest,
          onCancel: () => {
            this.setState({
              isSubmitting: false
            });
          }
        });
      }
    } else {
      uiActions.isValidating();
    }
  }

  onTitleChange(e) {
    this.setState({
      title: e.currentTarget.value,
      saved: false
    });
    uiActions.formEdited();
  }

  onEventNameChange(e) {
    this.setState({
      eventName: e.currentTarget.value,
      saved: false
    });
    uiActions.formEdited();
  }

  onScenarioNameChange(e) {
    this.setState({
      scenarioName: e.currentTarget.value,
      saved: false
    });
    uiActions.formEdited();
  }

  showAdvancedConfig() {
    this.setState({
      showAdvancedConfig: true
    });
    uiActions.formEdited();
  }

  hideAdvancedConfig() {
    this.setState({
      showAdvancedConfig: false
    });
  }

  render() {
    return (
      <form
        id="wizard"
        ref="wizard"
        className="wizard wizard-cards wizard-cards--promotion"
      >
        <h3 className="page-title">{this.state.title}</h3>
        <div className="widget page-content-block">
          <ol className="form-elements">
            <div className="wizard-comp wizard-comp-campaigntitle">
              <li className="item-title-field">
                <TextField
                  name="notificationTitle"
                  label="Widget Title"
                  className="item-stacked one-whole"
                  value={this.state.title}
                  required={true}
                  onChange={this.onTitleChange}
                  tooltipText={t("Title, as will appear on your website")}
                  tooltip={true}
                  tooltipLink="#"
                />
              </li>
              {this.state.selectedType.page === "triggerbased" ? (
                <li>
                  <TextField
                    name="eventName"
                    label={t("Event Name")}
                    className="item-stacked one-whole label-emphasise"
                    value={this.state.eventName}
                    required={true}
                    onChange={this.onEventNameChange}
                  />
                </li>
              ) : (
                ""
              )}
            </div>
            <li>
              <WizardItems
                ref="wizardItems"
                edit={false}
                duplicate={this.props.duplicate}
                selectedType={this.state.selectedType}
                selectedPage={this.props.router.params.pagetype}
                isPromotion={true}
              />
            </li>
            <li>
              <li className="wizard-comp">
                <ol className="form-elements">
                  <li className="wizard-perso-fields">
                    <Fields
                        ref="contentExtra"
                        campaign={this.state.campaign}
                        comps={promoFields.fields}
                        duplicate={this.props.duplicate}
                        campaignType="PROMOTION"
                    />
                  </li>
                </ol>
              </li>
            </li>
          </ol>
        </div>

        <div className="wizard-options">
          <ol className="form-elements">
            <li className="campaign-subject">
              <TextField
                name="scenarioName"
                label="Campaign Name"
                className="item-stacked one-whole label-emphasise"
                value={this.state.scenarioName}
                required={true}
                onChange={this.onScenarioNameChange}
              />
            </li>
            <li>
              <WizardDateSelection ref="wizardDateSelection" />
            </li>
            <li>
              <WizardMediumSelection
                ref="wizardMediumSelection"
                duplicate={this.props.duplicate}
              />
            </li>
            <li>
              <WizardTarget
                ref="wizardTarget"
                duplicate={this.props.duplicate}
              />
            </li>
            {this.state.selectedType.page === "custom" ? (
              <li>
                <WizardPageType ref="wizardPageType" />
              </li>
            ) : (
              ""
            )}
            <li className="buttons">
              <a
                className="button secondary-action one-whole"
                onClick={this.showAdvancedConfig}
              >
                {t("Advanced Configuration")}
              </a>
              <a
                className={classNames("button gotest-action one-whole", {
                  "gotest-action--disabled":
                    this.state.isSubmitting &&
                    this.state.submittingButton === "test"
                })}
                onClick={this.validate.bind(null, "test")}
              >
                {t("Go Test")}
                {this.state.isSubmitting &&
                  this.state.submittingButton === "test" && (
                    <span className="page-progress page-progress--button" />
                  )}
              </a>
              <a className="button disabled one-whole">{t("Go Live")}</a>
            </li>
          </ol>
        </div>

        <WizardAdvancedConfig
          ref="wizardAdvancedConfig"
          show={this.state.showAdvancedConfig}
          onHide={this.hideAdvancedConfig}
          duplicate={this.props.duplicate}
          campaignType="promotion"
          campaignSpecifics={this}
          device={this.state.devices}
        />
      </form>
    );
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate,
  breadcrumb: store.ui.breadcrumb,
  formSaved: store.ui.formSaved,
  user: store.user.user,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes,
  duplicate: store.campaigns.duplicate
});

AddPromotion.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(mapStatesToProps)(AddPromotion);
