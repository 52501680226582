import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { localeString } from '../../system/string';
import Currency from '../currency';
import Icons from '../icons';
import Loader from '../Loader';
import Icon from '../icon';
import { t } from '../../system/ui';
import { hasSegmentifyListing } from '../../modules/auth/user';

const TotalsLine = ({ totalsLabels, totals, isLoading, selectedCurrency }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const navigateForward = () => {
    if (currentIndex > -1 && currentIndex < totalsLabels.length)
      setCurrentIndex(currentIndex + 1);
  };

  const navigateBackward = () => {
    if (currentIndex > -1 && currentIndex < totalsLabels.length)
      setCurrentIndex(currentIndex - 1);
  };

  const renderForeButton = () => {
    if (currentIndex < totalsLabels.length - 1)
      return (
        <button
          type='button'
          onClick={navigateForward}
          className='total-line-fore-scroll'
        >
          <Icons name='scroll' width='20' height='20' />
        </button>
      );
    return <></>;
  };

  const renderBackButton = () => {
    if (currentIndex > 0)
      return (
        <button
          type='button'
          onClick={navigateBackward}
          className='total-line-back-scroll'
        >
          <Icons name='scroll' width='20' height='20' />
        </button>
      );
    return <></>;
  };

  const renderCell = ({ label, value, showIcons }) => {
    const labelWidget = showIcons ? <Icon name={label} /> : t(label);
    const valueWidget =
      label?.toLowerCase()?.includes('amount') ||
      label?.toLowerCase()?.includes('price') ? (
        <Currency
          currency={value}
          predefinedCode={selectedCurrency !== 'ALL' ? selectedCurrency : false}
        />
      ) : (
        localeString(value)
      );
    return (
      <td key={`${label} ${value}`}>
        <span>{labelWidget}</span>
        {isLoading ? <Loader /> : valueWidget}
      </td>
    );
  };

  const renderCurrentLine = ({ total, totalLabel }) => (
    <>
      <td className='title-cell'>
        <span
          style={{
            color: totalLabel.titleColor,
            borderRight: `1px solid ${totalLabel.titleColor}`,
          }}
        >
          {totalLabel.title}
        </span>
      </td>
      {totalLabel.title.toLowerCase() === 'segmentify' &&
      !hasSegmentifyListing() ? (
        <td>You have no Segmentify fields active!</td>
      ) : (
        Object.entries(total).map(([key, value]) =>
          renderCell({
            label: totalLabel[key],
            value,
            showIcons: totalLabel.icons,
          }),
        )
      )}
    </>
  );

  return (
    <div className='table-total'>
      <table className='data-table'>
        <tbody>
          <tr>
            <td>{renderBackButton()}</td>
            {renderCurrentLine({
              total: totals[currentIndex],
              totalLabel: totalsLabels[currentIndex],
            })}
            <td>{renderForeButton()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

TotalsLine.propTypes = {
  totals: PropTypes.arrayOf(PropTypes.shape({})),
  totalsLabels: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      titleColor: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  selectedCurrency: PropTypes.string,
};

export default TotalsLine;
