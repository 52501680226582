import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CardWrapper from '../faceted-search/common/CardWrapper';
import { TextField } from '../../fields';
import WizardItems from '../../wizard/items';
import styles from './NoResultBox.module.scss';
import Banners from './Banners';

import { wizardActions } from '../../../actions';

const NoResultBox = ({
  edit,
  addPage,
  duplicate,
  isSearch,
  isReco,
  updateAlgoString,
  noResultRecommendation,
  handleNoResultRecommendationChange,
  banners,
  isDataLoaded,
  noResultBannerRef,
}) => {
  const [addNoResultBox, setAddNoResultBox] = useState(false);
  const [text, setText] = useState('');
  // const [exclude, setExclude] = useState([]);
  const [staticItems, setStaticItems] = useState([]);
  const [dynamicItems, setDynamicItems] = useState([]);
  const exclude = useSelector(state => state.wizard.noResultExcludes);
  const noResultCriteria = useSelector(state => state.wizard.noResultCriteria);

  useEffect(() => {
    if (
      isDataLoaded &&
      noResultRecommendation?.isNoResultRecommendationActive
    ) {
      setAddNoResultBox(noResultRecommendation?.isNoResultRecommendationActive);
      setText(noResultRecommendation?.text);
      setStaticItems(noResultRecommendation?.recommendationParams?.staticItems);
      setDynamicItems(
        noResultRecommendation?.recommendationParams?.dynamicItems,
      );
      wizardActions.addNoResultCriteria(
        noResultRecommendation?.recommendationParams?.staticItems?.concat(
          noResultRecommendation?.recommendationParams?.dynamicItems,
        ) || [],
      );
      wizardActions.addNoResultExclusion(
        noResultRecommendation?.recommendationParams?.exclusions || [],
      );
    }
  }, [isDataLoaded]);

  useEffect(() => {
    // singleSearch(parentContext, campaign);
    if (isDataLoaded) {
      if (addNoResultBox) {
        handleNoResultRecommendationChange({
          isNoResultRecommendationActive: addNoResultBox,
          text,
          recommendationParams: {
            exclusions: exclude,
            staticItems,
            dynamicItems,
          },
        });
        wizardActions.addNoResultCriteria(
          staticItems?.concat(dynamicItems) || [],
        );
      } else {
        handleNoResultRecommendationChange({
          isNoResultRecommendationActive: addNoResultBox,
          text: '',
          recommendationParams: {
            exclusions: [],
            staticItems: [],
            dynamicItems: [],
          },
        });
      }
    }
  }, [
    addNoResultBox,
    text,
    JSON.stringify(staticItems),
    JSON.stringify(exclude),
    JSON.stringify(dynamicItems),
  ]);

  const onAddNoResultBoxChange = e => {
    setAddNoResultBox(!addNoResultBox);
  };

  const onTextChange = e => {
    setText(e.target.value);
  };

  useEffect(() => {
    setStaticItems(
      noResultCriteria.filter(item => item.type !== 'intelligent'),
    );
    setDynamicItems(
      noResultCriteria.filter(item => item.type === 'intelligent'),
    );
  }, [JSON.stringify(noResultCriteria)]);

  return (
    <div className={styles.container}>
      <CardWrapper
        description='This option allows you to customize no result box.'
        id='no-result-box'
        title='Add No Result Box'
        extraClasses={styles['card-wrapper']}
        removeOlParentClass
        headerRightComponent={
          <label className='item item-stacked is-checkbox'>
            <input
              type='checkbox'
              checked={addNoResultBox}
              onChange={event => onAddNoResultBoxChange(event)}
            />
            <span
              className={`${styles['checkbox-label']} ${
                addNoResultBox ? styles['checkbox-label-checked'] : null
              }`}
            />
          </label>
        }
      >
        {addNoResultBox && (
          <div className={`${styles['card-body']}`}>
            <TextField
              className='item-stacked one-whole'
              value={text}
              onChange={event => onTextChange(event)}
              required
              label='Text'
              placeholder='Ex : We couldn’t find any results.'
            />
            <WizardItems
              edit={edit}
              duplicate={duplicate}
              selectedPage='afterSearch'
              selectedType={{
                page: 'after-search-edit',
                placement: 'fullWidth',
              }}
              addPage={addPage}
              isReco={isReco}
              isSearch={isSearch}
              updateAlgorithmString={updateAlgoString}
            />
            <Banners
              banners={banners}
              ref={noResultBannerRef}
              isInstant
            />
          </div>
        )}
      </CardWrapper>
    </div>
  );
};

export default NoResultBox;
