import React from 'react';
import './Tabs.scss';
import { isLoaded, isLoading, showNotification } from '../../../../actions/ui';
import { t } from '../../../../system/ui';
import { SFYSwitch } from '../../../../components/sfy-components/module-exports';
import { inputConfiguration } from '../constants';
import { Checkbox, TextField } from '../../../../components/fields';

import FtpService from '../services';

const ExportFTP = ({ state, dispatch }) => {
  const handleChange = (e, type, stateName) => {
    if (type === 'checkbox') {
      dispatch({
        exportFtpConfig: {
          ...state.exportFtpConfig,
          [stateName]: !state.exportFtpConfig[stateName],
        },
      });
    } else {
      const { name, value } = e?.target;
      dispatch({
        exportFtpConfig: { ...state.exportFtpConfig, [name]: value },
      });
    }
  };

  const checkFields = () => {
    const {
      userName,
      password,
      address,
      path,
      frequency,
    } = state.exportFtpConfig;
    if (!userName || !password || !address || !path || !frequency) {
      showNotification({
        content: () => <p>Please fill all the required fields.</p>,
        className: 'notification-fail',
      });
      return false;
    }
    return true;
  };

  const checkConnection = async isSave => {
    isLoading();
    let isConn;
    const { userName, password, address } = state.exportFtpConfig;
    if (!userName || !password || !address) {
      isConn = false;
      showNotification({
        content: () => (
          <p>Please fill the Address, Username and Password fields.</p>
        ),
        className: 'notification-fail',
      });
      isLoaded();
    } else {
      await FtpService('contacts/ftpConn')
        .create({
          body: {
            userName: state.exportFtpConfig.userName,
            password: state.exportFtpConfig.password,
            address: state.exportFtpConfig.address,
          },
        })
        .then(res => {
          if (!isSave) {
            showNotification({
              content: () => <p>{res}</p>,
              className: 'notification-success',
            });
          }
          isConn = true;
        })
        .catch(err => {
          let newErr;
          if (err.response.error === undefined) newErr = err.response;
          else newErr = err.response.error;
          showNotification({
            content: () => <p>{newErr}</p>,
            className: 'notification-fail',
          });
          isConn = false;
        })
        .finally(() => isLoaded());
    }
    return isConn;
  };

  const handleSaveExport = async () => {
    const validation = checkFields();
    const isConn = await checkConnection(true);
    isLoading();
    if (validation && isConn) {
      FtpService('contacts/ftpUpdate')
        .update({ id: null, body: state.exportFtpConfig })
        .then(res => {
          showNotification({
            content: () => <p>{res}</p>,
            className: 'notification-success',
          });
        })
        .catch(err => {
          showNotification({
            content: () => <p>{err.response.error}</p>,
            className: 'notification-fail',
          });
        })
        .finally(() => isLoaded());
    } else {
      isLoaded();
    }
  };

  return (
    <div className='tabs'>
      <div className='tabs-container'>
        <div className='tabs-container__card'>
          <SFYSwitch
            span='Passive / Active'
            isToggled={state.exportFtpConfig.enabled}
            onToggled={() =>
              dispatch({
                exportFtpConfig: {
                  ...state.exportFtpConfig,
                  enabled: !state.exportFtpConfig.enabled,
                },
              })
            }
            className='tabs-container__card-switch'
          />
          <h3 className='tabs-container__card-title'>{t('Export To FTP')}</h3>
          {inputConfiguration?.exportFtpConfig?.map(
            ({
              order,
              type,
              stateName,
              name,
              className,
              placeHolder,
              required,
            }) => {
              return (
                <div key={order} className='tabs-container__card-row'>
                  <TextField
                    key={order}
                    type={type}
                    name={stateName}
                    value={state.exportFtpConfig[stateName]}
                    label={name}
                    className={className}
                    placeHolder={placeHolder}
                    required={required}
                    onChange={e => handleChange(e, type, stateName)}
                  />
                </div>
              );
            },
          )}
          <div
            style={{ gap: '40px', padding: '16px 0' }}
            className='tabs-container__card-row'
          >
            <div
              style={{ gap: '20px' }}
              className='tabs-container__card-row__oneHalf'
            >
              {inputConfiguration?.checkbox?.map(
                ({ type, order, name, stateName }) => (
                  <Checkbox
                    key={order}
                    name={stateName}
                    checked={state.exportFtpConfig[stateName]}
                    onChange={e => handleChange(e, type, stateName)}
                    label={name}
                  />
                ),
              )}
            </div>
            <div className='tabs-container__card-row__oneHalf'>
              <a onClick={() => checkConnection(false)}>
                {t('Test Connection')}
              </a>
            </div>
          </div>
          <div
            className='tabs-container__card-row'
            style={{ justifyContent: 'flex-end' }}
          >
            <button
              type='button'
              className='tabs-container__card-row-saveBTN'
              onClick={handleSaveExport}
            >
              {t('Save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportFTP;
