import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Select from 'react-select';
import { clone, stringfy } from '../../system/object';
import {
  params,
  campaignInitialData,
  initialState,
} from '../../constants/datamaps/palantir';

import { confirm, t } from '../../system/ui';

import Fields from '../../modules/campaign/fields';

import { wizardActions, uiActions } from '../../actions';
import { updateCampaign, getSearch } from '../../modules/search/ajax';
import { TextField } from '../fields';
import Tooltip from '../tooltip';
import {
  personalizationRecommendationExclusions,
  searchAlgorithms,
} from '../../modules/transform/personalizationRecommendations';
import { isSwitchAccount } from '../../modules/auth/user';
import Icon from '../icon';
import WizardAdvancedConfig from '../wizard/advanced-config';
import { setTitle } from '../../system/document';
import Ordering from './common/Ordering';
import Banners from './common/Banners';
import NoResultBox from './common/NoResultBox';
import { facetedSearchRequest } from '../../actions/faceted-search';

const valueGenerator = (count, name) => {
  return [...Array(count)].map((item, index) => ({
    value: index + 1,
    label: index + 1,
    name,
  }));
};

const typesM = valueGenerator(18, 'mobileItemCount');
const typesL = valueGenerator(18, 'desktopItemCount');
const assetTexts = [
  { label: 'Categories', name: 'categoriesText' },
  { label: 'Brands', name: 'brandsText' },
  { label: 'Products', name: 'popularProductsText' },
  {
    label: 'Mobile Cancel Text',
    name: 'mobileCancelText',
    tooltip: true,
    tooltipText: 'The text for closing the Search Box on mobile.',
  },
  {
    label: 'Not Found Text',
    name: 'notFoundText',
    tooltip: true,
    tooltipText: 'The text that will be shown on mobile for zero results.',
  },
];

class EditSearchCampaign extends Component {
  constructor(props) {
    super(props);
    const cloneCampaign = clone(campaignInitialData);
    cloneCampaign.stringSearchAssetTextMap = { EN: [], TR: [] };
    cloneCampaign.triggerSelector = '';

    this.state = {
      campaignState: clone(initialState),
      campaign: cloneCampaign,
      params: {},
      selectedLanguage: 'EN',
      additionalTitles: {},
      desktopItemCount: 6,
      mobileItemCount: 6,
      showAdvancedConfig: false,
      isSubmitting: false,
      status: 'test',
      isFinished: false,
      banners: [],
      AlgoString: '',
      noResultRecommendation: {},
      noResultRecommendationBanners: [],
      isDataLoaded: false,
    };

    this.save = this.save.bind(this);
    this.onCampaignDataChange = this.onCampaignDataChange.bind(this);
    this.selectLanguage = this.selectLanguage.bind(this);
    this.setLang = this.setLang.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateAlgoString = this.updateAlgoString.bind(this);
    this.showAdvancedConfig = this.showAdvancedConfig.bind(this);
    this.hideAdvancedConfig = this.hideAdvancedConfig.bind(this);
    this.validate = this.validate.bind(this);
    this.getDataFromCriteria = this.getDataFromCriteria.bind(this);
    this.handleNoResultRecommendationChange = this.handleNoResultRecommendationChange.bind(
      this,
    );

    this.bannerRef = null;
    this.noResultBannerRef = null;
  }

  componentDidMount() {
    setTitle(t('After Search Input'));
    this.setLang();
    uiActions.confirmOnLeave(this);
    uiActions.isValidated();
    this.campaignDetails();
    uiActions.resetPage();
    wizardActions.wizardReset();
    wizardActions.wizardAdd();
    wizardActions.saveReset();
    this.setBreadCrump();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.save !== false) {
      this.save(newProps.save);
    }

    if (
      stringfy(this.state.campaign.searchAssets) !== stringfy(newProps.criteria)
    ) {
      if (this.props.criteria.length > 0) this.changeFields(newProps.criteria);
    }
  }

  componentWillUnmount() {
    wizardActions.wizardReset();
    wizardActions.wizardAdd();
    wizardActions.saveReset();
  }

  handleNoResultRecommendationChange = newState => {
    this.setState({
      noResultRecommendation: newState,
    });
  };

  save() {
    const campaign = clone(campaignInitialData);
    const campaignData = clone(params);
    const campaignProps = {};
    const contentFields = this.refs.mainField;
    const contentOptions = this.refs.campaignOptions;

    let noResultDynamicItems = '';
    let noResultStaticItems = '';
    let noResultExcludes = '';
    let noResultExcludesArray = [];
    let noResultStaticArray = [];
    const noResultDynamicArray =
      this.state?.noResultRecommendation?.recommendationParams?.dynamicItems?.map(
        item => {
          return {
            type: item.type.toUpperCase(),
            recommendationSource: item.values.criterion.value,
            itemCount: item.values.itemCount.value,
            mobileItemCount: item.values.mobileItemCount.value,
            timeFrame: item.values.timeFrame.value,
            score: 'NONE',
          };
        },
      ) || [];
    noResultExcludesArray =
      personalizationRecommendationExclusions(
        this.state?.noResultRecommendation?.recommendationParams?.exclusions,
      ) || [];
    noResultStaticArray = this.state?.noResultRecommendation
      ?.recommendationParams?.staticItems[0]?.values?.productId?.value
      ? [
          {
            type: 'PRODUCT',
            productSource: 'INTERNAL',
            productId: this.state?.noResultRecommendation?.recommendationParams
              ?.staticItems[0]?.values?.productId?.value,
          },
        ]
      : [];
    // Main Field
    Object.keys(contentFields.refs).forEach(ref => {
      campaignProps[ref] = contentFields.refs[ref];
    });

    // Campaign Config
    Object.keys(contentOptions.refs).forEach(ref => {
      campaignProps[ref] = contentOptions.refs[ref];
    });

    // Campaign Type&Name&Status&Devices
    campaign.name = campaignProps.name.state.value.trim();
    campaign.status = this.state.status === 'test' ? 'TEST' : 'ACTIVE';
    campaign.devices = [];
    if (campaignProps.searchDevices) {
      campaign.devices = campaignProps.searchDevices.state.value.filter(
        device => device !== 'ALL',
      );
    }

    campaign.filters = [];
    campaign.stringSearchAssetTextMap = this.state.campaign.stringSearchAssetTextMap;
    campaign.mobileItemCount = this.state.mobileItemCount;
    campaign.desktopItemCount = this.state.desktopItemCount;
    campaign.html = this.refs.wizardAdvancedConfig.state.htmlTemplate;
    campaign.preJs = this.refs.wizardAdvancedConfig.state.prejsTemplate;
    campaign.postJs = this.refs.wizardAdvancedConfig.state.postjsTemplate;
    campaign.css = this.refs.wizardAdvancedConfig.state.cssTemplate;
    campaign.orders = this.state.campaign.orders;

    noResultDynamicItems = JSON.stringify(noResultDynamicArray);
    noResultExcludes = JSON.stringify(noResultExcludesArray);
    noResultStaticItems = JSON.stringify(noResultStaticArray);

    campaign.noResultRecommendation = this.state.noResultRecommendation;
    campaign.noResultRecommendation.recommendationParams.dynamicItems = noResultDynamicItems;
    campaign.noResultRecommendation.recommendationParams.exclusions = noResultExcludes;
    campaign.noResultRecommendation.recommendationParams.staticItems = noResultStaticItems;

    campaignData.forEach(dataName => {
      let data;
      if (typeof dataName === 'object') {
        if (typeof campaignProps[dataName[0]] !== 'undefined') {
          data = campaignProps[dataName[0]].state.values;
          dataName[1] &&
            dataName[1].forEach(singleDataName => {
              campaign[singleDataName] = data[singleDataName];
            });
        }
      } else {
        const recommendation = searchAlgorithms(this.props.criteria);
        campaign.searchAssets = recommendation;

        const mobileRecommendation = searchAlgorithms(
          this.props.criteria,
          'Mobile',
        );
        campaign.mobileSearchAssets = mobileRecommendation;
        campaign.inclusions = personalizationRecommendationExclusions(
          this.props.includes,
        );
        campaign.exclusions = personalizationRecommendationExclusions(
          this.props.excludes,
        );
      }
    });

    updateCampaign(campaign, async response => {
      const instance = response.instanceId;
      let status = 'test';

      let note = () => {
        return <div>Search Box campaign is saved to drafts successfully.</div>;
      };
      if (response.status === 'ACTIVE') {
        status = 'active';
        note = () => {
          return <div>Search Box campaign is activated successfully.</div>;
        };
      }

      const campaign = {
        ...response,
        searchAssets: response.searchAssets,
        mobileSearchAssets: response.mobileSearchAssets,
        status,
      };

      this.setState({
        noResultRecommendation: {
          isNoResultRecommendationActive:
            campaign.noResultRecommendation?.isNoResultRecommendationActive,
          text: campaign.noResultRecommendation?.text,
          recommendationParams: {
            dynamicItems: this.criteriaCreation(
              campaign.noResultRecommendation?.recommendationParams
                ?.dynamicItems,
            ),
            exclusions: this.exclusionCreation(
              campaign.noResultRecommendation?.recommendationParams?.exclusions,
            ),
            staticItems: this.exclusionCreation(
              campaign.noResultRecommendation?.recommendationParams
                ?.staticItems,
            ),
          },
        },
      });

      let banners = [];
      try {
        banners = await facetedSearchRequest('searchbanner/upsert', {
          instanceId: 'SEARCH',
        }).post(
          [
            ...this.bannerRef.getBanners().map(banner => {
              return {
                ...banner,
                instanceId: 'SEARCH',
                searchType: 'instant',
              };
            }),
            ...(this.noResultBannerRef?.getBanners()?.map(banner => {
              return {
                ...banner,
                instanceId: 'SEARCH',
                searchType: 'instant',
                resultType: 'NO_RESULT',
              };
            }) || []),
          ],
          { type: 'json' },
        );
        this.setState({
          banners: banners.filter(banner => banner?.resultType !== 'NO_RESULT'),
        });
        this.setState({
          noResultRecommendationBanners: banners.filter(
            banner => banner?.resultType === 'NO_RESULT',
          ),
        });
      } catch (e) {
        const notificationContent = () => (
          <span>Banner could not be saved.</span>
        );
        uiActions.showErrorNotification(notificationContent);
        return;
      }

      this.setState({
        campaign,
      });

      uiActions.showNotification({
        content: note,
      });
    });
  }

  onChange(e) {
    this.setState({
      [e.name]: e.value,
    });
  }

  selectLanguage(selectedLanguage, e) {
    e.preventDefault();
    this.setState({
      selectedLanguage,
    });
  }

  onCampaignDataChange(e) {
    const newCustoms = clone(this.state.campaign);
    const criteria = searchAlgorithms(this.props.criteria);
    if (!newCustoms.stringSearchAssetTextMap[this.state.selectedLanguage]) {
      newCustoms.stringSearchAssetTextMap[this.state.selectedLanguage] = {
        categoriesText: '',
        brandsText: '',
        popularProductsText: '',
        mobileCancelText: '',
        notFoundText: '',
      };
    }
    newCustoms.searchAssets = criteria;
    newCustoms.stringSearchAssetTextMap[this.state.selectedLanguage][
      e.target.name
    ] = e.target.value;

    this.setState({
      campaign: newCustoms,
    });
  }

  getTextValue(name) {
    const textMap = this.state.campaign.stringSearchAssetTextMap[
      this.state.selectedLanguage
    ];
    return textMap === undefined || textMap[name] === undefined
      ? ''
      : textMap[name];
  }

  updateOrders = orders => {
    this.setState(prevState => {
      const campaign = clone(prevState.campaign);
      campaign.orders = orders;
      return {
        campaign,
      };
    });
  };

  updateAlgoString = AlgoString => {
    this.setState({
      AlgoString,
    });
  };

  showAdvancedConfig() {
    this.setState({
      showAdvancedConfig: true,
    });
    uiActions.formEdited();
  }

  hideAdvancedConfig() {
    this.setState({
      showAdvancedConfig: false,
    });
  }

  criteriaCreation(arrayStr) {
    if (
      !arrayStr ||
      !(typeof arrayStr === 'string' || arrayStr instanceof String)
    ) {
      return [];
    }
    const items = JSON.parse(arrayStr);
    const result = items.map(item => {
      return {
        id: Math.random(),
        type: item.type.toLowerCase(),
        values: {
          criterion: {
            value: item.recommendationSource,
          },
          itemCount: {
            value: item.itemCount,
          },
          mobileItemCount: {
            value: item.mobileItemCount,
          },
          timeFrame: {
            value: item.timeFrame,
          },
        },
      };
    });
    return result;
  }

  exclusionCreation(arrayStr) {
    if (
      !arrayStr ||
      !(typeof arrayStr === 'string' || arrayStr instanceof String)
    ) {
      return [];
    }
    const items = JSON.parse(arrayStr);
    const result = [];
    items.forEach(item => {
      const values = {};

      Object.keys(item).forEach(key => {
        if (key !== 'type') {
          values[key] = {
            value: item[key],
          };
        }
      });

      result.push({
        id: Math.random(),
        type: item.type.toLowerCase(),
        values,
      });
    });
    return result;
  }

  setBreadCrump() {
    uiActions.showBreadcrumb({
      home: {
        name: t('Search & Discovery'),
        icon: 'search',
        slug: '/search',
      },
      top: {
        name: 'Search Box',
        slug: '/search/searchbox',
      },
      current: {
        name: 'After Search Input',
      },
    });
  }

  getDataFromCriteria(state) {
    const tempCampaign = clone(this.state.campaign);
    state.map(elem => {
      if (elem.values !== undefined) {
        delete elem.values;
      }
    });
    tempCampaign.searchAssets = clone(state);
    this.setState({ campaign: tempCampaign }, () => {
      this.setState({ isFinished: true });
    });
  }

  campaignDetails(options) {
    let campaign;
    let campaignStatus;
    if (options && options.campaign) {
      campaign = options.campaign;
      campaignStatus = options.status === 'ACTIVE' ? 'Live' : 'Test';
      if (options.status === 'PASSIVE') {
        campaignStatus = 'Passive';
      }
      this.setCampaignDetails(campaign, campaignStatus);
      this.setState({
        noResultRecommendation: campaign.noResultRecommendation,
      });
    } else {
      getSearch(this.state.campaign.instanceId, async response => {
        campaign = response;
        campaignStatus = response.status === 'ACTIVE' ? 'Live' : 'Test';
        if (response.status === 'PASSIVE') {
          campaignStatus = 'Passive';
        }
        const banners = await facetedSearchRequest(
          'searchbanner/get',
          null,
          '&instanceId=SEARCH',
        ).get();
        this.setState({
          banners: banners.filter(banner => banner?.resultType !== 'NO_RESULT'),
        });
        this.setState({
          noResultRecommendationBanners: banners.filter(
            banner => banner?.resultType === 'NO_RESULT',
          ),
        });
        this.setState({
          noResultRecommendation: {
            isNoResultRecommendationActive:
              campaign.noResultRecommendation?.isNoResultRecommendationActive,
            text: campaign.noResultRecommendation?.text,
            recommendationParams: {
              dynamicItems: this.criteriaCreation(
                campaign.noResultRecommendation?.recommendationParams
                  ?.dynamicItems,
              ),
              exclusions: this.exclusionCreation(
                campaign.noResultRecommendation?.recommendationParams
                  ?.exclusions,
              ),
              staticItems: this.exclusionCreation(
                campaign.noResultRecommendation?.recommendationParams
                  ?.staticItems,
              ),
            },
          },
          isDataLoaded: true,
        });
        this.setCampaignDetails(campaign, campaignStatus);
      });
    }
  }

  setCampaignDetails(campaign, campaignStatus) {
    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
    const { mainLanguage } = account;
    const additionalLanguages = account.additionalLanguages || [];
    const accountLanguages = additionalLanguages.concat(mainLanguage);

    campaign.stringSearchAssetTextMap = {
      ...this.state.campaign.stringSearchAssetTextMap,
      ...campaign.stringSearchAssetTextMap,
    };
    this.setState(
      {
        campaign,
        campaignStatus,
        mobileItemCount: campaign.mobileItemCount,
        desktopItemCount: campaign.desktopItemCount,
      },
      () => {
        if (campaign.inclusions && campaign.inclusions.length > 0) {
          const includes = [];
          campaign.inclusions.forEach(item => {
            const values = {};

            Object.keys(item).forEach(key => {
              if (key !== 'type') {
                values[key] = {
                  value: item[key],
                };
              }
            });

            includes.push({
              id: Math.random(),
              type: item.type.toLowerCase(),
              values,
            });
          });
          wizardActions.addInclusion(includes);
        }

        if (campaign.exclusions && campaign.exclusions.length > 0) {
          const excludes = [];
          campaign.exclusions.forEach(item => {
            const values = {};

            Object.keys(item).forEach(key => {
              if (key !== 'type') {
                values[key] = {
                  value: item[key],
                };
              }
            });

            excludes.push({
              id: Math.random(),
              type: item.type.toLowerCase(),
              values,
            });
          });
          wizardActions.addExclusion(excludes);
        }
      },
    );
  }

  setLang() {
    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
    const { mainLanguage } = account;
    const additionalLanguages = account.additionalLanguages || [];
    const tempState = clone(this.state.campaign);
    const langs = additionalLanguages.concat(mainLanguage);
    tempState.stringSearchAssetTextMap = {};

    langs.map(lang => {
      if (tempState.stringSearchAssetTextMap[lang] === undefined) {
        tempState.stringSearchAssetTextMap[lang] = {};
        assetTexts.map(asset => {
          tempState.stringSearchAssetTextMap[lang][asset.name] = '';
        });
      }
    });

    this.setState({
      selectedLanguage: mainLanguage,
      campaign: tempState,
    });
  }

  changeFields(criteria) {
    const campaignCopy = clone(this.state.campaign);
    const criteriaSearch = searchAlgorithms(criteria);
    campaignCopy.searchAssets = criteriaSearch;
    this.setState({ campaign: campaignCopy });
  }

  validate(type) {
    const errors = document.querySelectorAll('.has-error');
    this.setState({
      status: type,
    });
    if (errors.length === 0) {
      confirm({
        title: `Update Campaign on ${type.charAt(0).toUpperCase()}${type.slice(
          1,
        )} Mode?`,
        onConfirm: () => {
          this.save();
        },
        onCancel: () => {
          this.setState({
            isSubmitting: false,
          });
        },
      });
    } else {
      uiActions.isValidating();
    }
  }

  render() {
    const containerStyle =
      window.location.pathname.indexOf('ext/push') >= 0
        ? { 'padding-left': '140px' }
        : {};
    const formClass = 'wizard';

    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
    const mainLanguage = this.state.language || account.mainLanguage;
    let langList =
      account.additionalLanguages &&
      account.additionalLanguages.indexOf(account.mainLanguage) > -1
        ? account.additionalLanguages
        : [account.mainLanguage].concat(account.additionalLanguages);
    langList = langList.map(lang => {
      return { value: lang, label: lang };
    });
    langList.push({ value: 'SESSION_BASED', label: 'Session based' });
    return (
      <form
        style={containerStyle}
        id='wizard'
        ref='wizard'
        className={formClass}
      >
        {this.state.campaign.status !== undefined &&
        this.state.campaign.status !== '' ? (
          <h3 className='page-title'>
            <span
              className={
                this.state.campaign.status.toLowerCase() === 'active'
                  ? 'status-label status-label-live'
                  : this.state.campaign.status.toLowerCase() === 'test'
                  ? 'status-label status-label-test'
                  : 'status-label status-label-passive'
              }
            >
              {this.state.campaign.status.toLowerCase() === 'active'
                ? 'live'
                : this.state.campaign.status}
            </span>
            After Search Input
          </h3>
        ) : (
          <h3 className='page-title'>{this.state.campaign.name}</h3>
        )}
        <div
          className='widget page-content-block'
          style={{
            background: 'transparent',
            paddingTop: '0',
            display: this.state.showAdvancedConfig ? 'none' : 'block',
          }}
        >
          <ol
            className='form-elements wizard-comp search'
            style={{ marginTop: '0', paddingTop: '10px' }}
          >
            <li>
              <Fields
                ref='mainField'
                campaign={this.state.campaign}
                comps={this.state.campaignState.mainField}
                getData={this.getDataFromCriteria}
                isFinished={this.state.isFinished}
                isEdit
              />
              <hr className='widget-separator' style={{ height: '10px' }} />
              <div
                className='wizard-criterion'
                style={{
                  position: 'relative',
                  marginLeft: '15%',
                  marginBottom: '2em',
                }}
                id='wI1'
              >
                <span
                  className='criteria-type'
                  style={{
                    bottom: '2px',
                    position: 'relative',
                    color: '#5a7390',
                  }}
                >
                  <Icon name='product' />
                </span>
                <span className='criteria-item-count'>
                  {t('On mobile')}
                  <span
                    className='item-label keyword product'
                    style={{ zIndex: 1 }}
                  >
                    Product
                  </span>
                  <Select
                    value={this.state.mobileItemCount}
                    options={typesM}
                    name='mobileItemCount'
                    clearable={false}
                    searchable={false}
                    onChange={this.onChange}
                    className='keyword-criteria criteria-field'
                    style={{
                      display: 'inline-block',
                      top: '10px',
                      boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1px 0px',
                    }}
                  />
                  {t('On Desktop')}
                  <span
                    className='item-label keyword product'
                    style={{ left: '450px', zIndex: '1' }}
                  >
                    Product
                  </span>
                  <Select
                    value={this.state.desktopItemCount}
                    options={typesL}
                    name='desktopItemCount'
                    clearable={false}
                    searchable={false}
                    onChange={this.onChange}
                    className='keyword-criteria criteria-field'
                    style={{
                      display: 'inline-block',
                      top: '10px',
                      boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1px 0px',
                    }}
                  />
                </span>
              </div>
              <hr className='widget-separator' style={{ height: '10px' }} />
              <Ordering
                key={`after search order${this.state.campaign.id}`}
                orders={this.state.campaign.orders ?? {}}
                type='instant'
                updateOrders={this.updateOrders}
                instanceId='SEARCH'
              />
              <hr className='widget-separator' style={{ height: '10px' }} />
              <Banners
                banners={this.state.banners ?? []}
                ref={bannerRef => {
                  this.bannerRef = bannerRef;
                }}
                isInstant
              />

              <hr className='widget-separator' style={{ height: '10px' }} />
              <span>
                {t('Asset Texts')}{' '}
                <Tooltip
                  content='Prepare the asset titles as will appear on your onsite Search Box. The titles for Popular Categories, Popular Brands and Popular Keywords will appear in the searchbox before your customers make a search. The titles for Categories and Brands will appear in the searchbox when your customers start to make a search.'
                  alignment='top'
                  newTooltip
                  isInline
                >
                  <Icon name='info' />
                </Tooltip>{' '}
              </span>
              {langList.length > 2 && (
                <span className='campaign-language-selection'>
                  {langList.map((item, index) => {
                    if (item.label !== 'Session based') {
                      return (
                        <span
                          key={index}
                          onClick={this.selectLanguage.bind(this, item.label)}
                          className={classNames('language-box', {
                            'is-selected':
                              this.state.selectedLanguage === item.label,
                          })}
                        >
                          {item.label}
                        </span>
                      );
                    }
                  })}
                </span>
              )}
              <div className='wizard-comp' style={{ height: '300px' }} id='wI2'>
                {assetTexts.map((asset, index) => {
                  return (
                    <span
                      className='four-columns'
                      style={{ height: '100px' }}
                      key={asset?.name || index}
                    >
                      <TextField
                        name={asset.name}
                        label={asset.label}
                        tooltip={asset.tooltip}
                        tooltipText={asset.tooltipText}
                        className='item-stacked one-whole'
                        value={this.getTextValue(asset.name)}
                        required
                        onChange={this.onCampaignDataChange}
                        ref='title'
                        isSearch
                      />
                    </span>
                  );
                })}
              </div>
              <hr className='widget-separator' style={{ height: '10px' }} />
              <NoResultBox
                edit={false}
                duplicate={this.props.duplicate}
                addPage
                isReco
                isSearch
                updateAlgoString={this.updateAlgoString}
                noResultRecommendation={this.state.noResultRecommendation}
                handleNoResultRecommendationChange={
                  this.handleNoResultRecommendationChange
                }
                banners={this.state.noResultRecommendationBanners || []}
                noResultBannerRef={bannerRef => {
                  this.noResultBannerRef = bannerRef;
                }}
                isDataLoaded={this.state.isDataLoaded}
              />
            </li>
          </ol>
        </div>
        <div
          className='wizard-options email-wizard-option'
          style={{ display: this.state.showAdvancedConfig ? 'none' : 'block' }}
        >
          <ol className='form-elements'>
            <li>
              <Fields
                ref='campaignOptions'
                campaign={this.state.campaign}
                comps={this.state.campaignState.config}
              />
            </li>
            <li className='buttons'>
              <a
                className='button secondary-action one-whole'
                onClick={this.showAdvancedConfig}
              >
                {t('Advanced Configuration')}
              </a>
              <a
                className={classNames('button gotest-action one-whole', {
                  'gotest-action--disabled':
                    (this.state.isSubmitting &&
                      this.state.submittingButton === 'test') ||
                    !this.props.criteria.length,
                })}
                onClick={this.validate.bind(null, 'test')}
              >
                {t('Go Test')}
                {this.state.isSubmitting &&
                  this.state.submittingButton === 'test' && (
                    <span className='page-progress page-progress--button' />
                  )}
              </a>
              <a
                className={classNames(
                  'button tertiary-action one-whole golive-action',
                  {
                    'golive-action--disabled':
                      (this.state.isSubmitting &&
                        this.state.submittingButton === 'live') ||
                      !this.props.criteria.length,
                  },
                )}
                onClick={this.validate.bind(null, 'live')}
              >
                {t('Go Live')}
                {this.state.isSubmitting &&
                  this.state.submittingButton === 'live' && (
                    <span className='page-progress page-progress--button' />
                  )}
              </a>
            </li>
          </ol>
        </div>
        <WizardAdvancedConfig
          ref='wizardAdvancedConfig'
          campaign={this.state.campaign}
          show={this.state.showAdvancedConfig}
          onHide={this.hideAdvancedConfig}
          campaignType='palantir'
          campaignSpecifics={this}
          isPalantir
        />
      </form>
    );
  }
}

EditSearchCampaign.propTypes = {
  content: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  buttons: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const MapStatesToProps = store => ({
  save: store.wizard.save,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes,
  formFields: store.wizard.formFields,
  switchedUser: store.switchedUser.switchedUser,
  user: store.user.user,
  synonymItems: store.synonyms.synonymItems,
});

export default connect(MapStatesToProps)(EditSearchCampaign);
