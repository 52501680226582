/**
 * @author Bilal Cinarli
 */

'use strict';

import { createReducer } from '../system/store';
import * as userActions from '../constants/actions/user';

const initialState = {
  user: {
    displayName: '',
    account: {
      mainCurrency: {},
      analytics: {},
      recommendationSettings: {
        sliderClick: {},
      },
      mainRegion: '',
      additionalCurrencyRegionList: [],
      contact: {
        person: '',
        phone: '',
        address: '',
      },
      domain: '',
      features: {
        trendify: '',
        bannerify: '',
        recommendation: '',
        email: '',
        push: '',
        search: '',
        segmentation: '',
        personalization: '', // engagement
        personalisation: '',
        promotion: '',
        weeklyReport: '',
        dataLayer: '',
        problems: '',
        priceDrop: '',
        keyword: '',
        hidden: '',
        bundle: '',
      },
      industry: '',
    },
    privileges: [],
    lastLogin: null,
  },
  integrations: {},
};

export default createReducer(initialState, {
  [userActions.default.USER_IS_LOGGED]: (state, payload) => {
    return {
      ...state,
      user: {
        ...state.user,
        ...payload.user,
      },
    };
  },
  [userActions.default.UPDATE_MAIL_CONF]: (state, payload) => {
    const cloneState = {
      ...state,
    };

    cloneState.user.account.mailConfiguration = payload.conf;
    return cloneState;
  },
  [userActions.default.RESET_MAIL_CONF]: state => {
    const cloneState = {
      ...state,
    };

    cloneState.user.account.mailConfiguration = null;
    return cloneState;
  },
  [userActions.default.GET_INTEGRATIONS]: (state, payload) => {
    return Object.assign({}, state, {
      integrations: payload.integrations,
    });
  },
  [userActions.default.UPDATE_USER]: (state, payload) => {
    return Object.assign({}, state, {
      user: payload.user,
    });
  },
  [userActions.default.UPDATE_ACCOUNT]: (state, payload) => {
    return Object.assign({}, state, {
      user: payload.user,
    });
  },
  [userActions.default.UPDATE_CURRENCY]: (state, payload) => {
    return {
      ...state,
      user: {
        ...state.user,
        account: {
          ...state.user.account,
          mainCurrency: payload,
        },
      },
    };
  },
  [userActions.default.UPDATE_REGION]: (state, payload) => {
    return {
      ...state,
      user: {
        ...state.user,
        account: {
          ...state.user.account,
          mainRegion: payload.mainRegion,
          additionalCurrencyRegionList: payload.additionalCurrencyRegionList,
        },
      },
    };
  },
  [userActions.default.UPDATE_PERSONALISATION_CONFIG]: (state, payload) => {
    return {
      ...state,
      user: {
        ...state.user,
        account: {
          ...state.user.account,
          personalisationConfig: {
            ...state.user.account.personalisationConfig,
            parentCategories: payload.personalisationConfig.parentCategories,
          },
          features: {
            ...state.user.account.features,
            personalisation: payload.features.personalisation,
          },
        },
      },
    };
  },
  [userActions.default.UPDATE_RECOMMENDATION_SETTINGS]: (state, payload) => {
    return {
      ...state,
      user: {
        ...state.user,
        account: {
          ...state.user.account,
          recommendationSettings: payload.recommendationSettings,
        },
      },
    };
  },
  [userActions.default.UPDATE_SEARCH_PROPERTY]: (state, payload) => {
    return {
      ...state,
      user: {
        ...state.user,
        account: {
          ...state.user.account,
          searchProperty: payload.searchProperty,
        },
      },
    };
  },
  [userActions.default.LOG_OUT_USER]: state => {
    return {
      ...state,
      user: {
        ...state.user,
        lastLogin: null,
      },
    };
  },
});
