/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";

import { t } from "../system/ui";

import { uiActions } from "../actions";

import { getQueryString } from "../system/string";

class Guide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      guides: {
        shopify: "https://shopify-app.segmentify.com/guide/index.html",
        prestashop:
          "https://cdn.segmentify.com/dash-ext/introduction/index2.html"
      },
      app: null
    };

    this.setApp = this.setApp.bind(this);
    this.calculateHeight = this.calculateHeight.bind(this);
  }

  componentDidMount() {
    uiActions.resetPage();
    let app = localStorage.getItem("externalApp");
    this.calculateHeight();
    this.setApp(app);
  }

  calculateHeight() {
    setInterval(() => {
      let iFrame2 = this.getIframe();
      iFrame2.width = iFrame2.contentWindow.document.body.scrollWidth;
      iFrame2.height = iFrame2.contentWindow.document.body.scrollHeight;
      iFrame2.style.width = iFrame2.contentWindow.document.body.scrollWidth;
      iFrame2.style.height = iFrame2.contentWindow.document.body.scrollHeight;
    }, 1000);
  }

  setApp(app) {
    this.setState({ app });
  }

  render() {
    return (
      <div className="guide">
        <h2 className="page-title">{t(this.state.app + " Guide")}</h2>
        <iframe
          ref="guideFrame"
          src={getQueryString("guideUri") || this.state.guides[this.state.app]}
          className="guide-frame"
        />
      </div>
    );
  }
}

export default Guide;
