import React from 'react';
import AlertTableHead from './AlertTableHead';
import AlertTableBody from './AlertTableBody';

const AlertTable = ({ alerts }) => {
  return (
    <div className='account-details account-last-child'>
      <table className='data-table'>
        <AlertTableHead />
        <AlertTableBody alerts={alerts} />
      </table>
    </div>
  );
};

export default AlertTable;
