import React, { useEffect, useState } from 'react';
import { getFormattedDetailedDate } from '../../system/date';
import { getLoginDetails } from '../../modules/account/ajax';
import { modalActions } from '../../actions';

const LoginDetailsModal = ({ account }) => {
  const [info, setInfo] = useState([]);
  const { calculateModalHeight } = modalActions;

  const setLoginInfo = () => {
    getLoginDetails(account.accountId, response => {
      setInfo(response);
      calculateModalHeight();
    }).then(() => {});
  };

  useEffect(() => {
    setLoginInfo();
  }, []);

  return (
    <div className='account-details no-scroll'>
      <table className='data-table' style={{ minWidth: '600px' }}>
        <thead>
          <tr>
            <th>#</th>
            <th>Username</th>
            <th>Login Count</th>
            <th>Last Login</th>
          </tr>
        </thead>
        <tbody>
          {info.map(({ username, count, last_login }, index) => {
            return (
              <tr key={username}>
                <td>{index + 1}</td>
                <td>{username}</td>
                <td>{count}</td>
                <td>{getFormattedDetailedDate(last_login)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default LoginDetailsModal;
