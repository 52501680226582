/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import classNames from "classnames";

import { t } from "../../system/ui";

import { filterActions, trendifyActions, dateActions } from "../../actions";

import { getBrands } from "../../modules/account/ajax";

import DeviceSelect from "./device-select";
import Icon from "../icon";
import Icons from "../icons";

class BrandFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brand: ""
    };

    this.reset = this.reset.bind(this);
    this.onChangeBrand = this.onChangeBrand.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
  }

  componentDidMount() {
    getBrands(response => {
      trendifyActions.updateBrands(response);
    });
  }
  resetFilters(e) {
    e.preventDefault();
    this.props.toggleAnalyticsFilters();
    filterActions.resetAllFilters();
  }

  updateFilters(e) {
    e.preventDefault();
    this.props.toggleAnalyticsFilters();
    if (this.props.disableButtons) {
      return false;
    }
    filterActions.updateFilters();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.reset === true) {
      this.reset();
    }
  }

  onChangeBrand(selectedOption) {
    this.setState({
      brand: selectedOption ? selectedOption.value : ""
    });

    filterActions.showButtons();
  }

  reset() {
    this.setState({
      brand: ""
    });
  }

  render() {
    let brands = [{ value: "", label: t("All Product Brands") }];

    this.props.brands.map(item => {
      brands.push({
        value: item,
        label: item
      });
    });
    let className = "analytics-filters group hide-filters";
    if (this.props.showTrendifyFilters) {
      className = "analytics-filters group show-filters";
    }
    return (
      <div className={className} ref="filters">
        <label className="item item-stacked is-select large">
          <span className="item-label">{t("Product Brand")}</span>
          <Select
            value={this.state.brand}
            options={brands}
            name="productBrand"
            onChange={this.onChangeBrand}
          />
        </label>

        <DeviceSelect />
        <div
          className="analytics-filters-close"
          onClick={this.props.toggleAnalyticsFilters}
        >
          <Icons name="cross" color="#7b7e7e" />
        </div>
        <div className="analytics-filters-actions">
          {this.props.visibleReset ? (
            <a
              className="cancel-action analytics-filters--reset"
              onClick={this.resetFilters}
            >
              {t("Reset Filters")}
            </a>
          ) : (
            ""
          )}

          <a
            className={classNames("tertiary-action analytics-filters--update", {
              disabled: this.props.disableButtons
            })}
            onClick={this.updateFilters}
          >
            {t("Update")}
          </a>
        </div>
      </div>
    );
  }
}

const mapStatsToProps = store => ({
  brands: store.filters.trendifyBrands,
  reset: store.filters.reset,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  visibleReset: store.filters.visibleReset,
  disableButtons: store.filters.disableButtons
});

export default connect(mapStatsToProps)(BrandFilters);
