import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const RightArrowInCircleIcon = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 21 21'
      fill='none'
    >
      <path d='M11.7803 6.2196L15.5303 9.96967C15.8232 10.2626 15.8232 10.7374 15.5303 11.0303L11.7803 14.7803C11.4874 15.0732 11.0126 15.0732 10.7197 14.7803C10.4268 14.4874 10.4268 14.0126 10.7197 13.7197L13.1893 11.25L6 11.25C5.58585 11.25 5.25 10.9142 5.25 10.5C5.25 10.0858 5.58585 9.75 6 9.75L13.1893 9.75L10.7197 7.2804C10.4268 6.98745 10.4268 6.51255 10.7197 6.2196C11.0126 5.9268 11.4874 5.9268 11.7803 6.2196ZM21 10.5C21 4.701 16.299 -4.10976e-07 10.5 -9.17939e-07C4.701 -1.4249e-06 1.4249e-06 4.701 9.17939e-07 10.5C4.10976e-07 16.299 4.701 21 10.5 21C16.299 21 21 16.299 21 10.5ZM10.5 1.5C15.4706 1.5 19.5 5.52945 19.5 10.5C19.5 15.4706 15.4706 19.5 10.5 19.5C5.52945 19.5 1.5 15.4706 1.5 10.5C1.5 5.52945 5.52945 1.5 10.5 1.5Z' />
    </svg>
  );
};

RightArrowInCircleIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

RightArrowInCircleIcon.defaultProps = {
  width: '21px',
  height: '21px',
};

export default RightArrowInCircleIcon;
