/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Select } from '../../../../components/fields';
import { visitorSegmentOptions, visitorTypeOptions } from '../constants';
import { getUserSegments } from '../../../../modules/account/ajax';
import {
  getAllSegment,
  getAllStat,
  getCustomSegmentStat,
} from '../../../../modules/segmentation/ajax';
import { getAllRbsStats } from '../../../../modules/rbs/ajax';
import { getNowByTimeZone, queryDate } from '../../../../system/date';

const TargetAudience = ({
  visitorSegmentState,
  visitorTypeState,
  onChangeVisitorSegment,
  onChangeVisitorType,
}) => {
  const [selectedTargetAudience, setSelectedTargetAudience] = useState(
    'visitorSegment',
  );
  const [visitorSegmentList, setVisitorSegmentList] = useState([]);
  const [visitorSegmentStats, setVisitorSegmentStats] = useState({});
  const selectedSegmentStats = visitorSegmentStats[visitorSegmentState.segment];

  const getSegments = () => {
    const userSegments = [];
    const allSegments = [];
    getUserSegments().then(userSegmentResponse => {
      const sorted = userSegmentResponse.sort();

      sorted.forEach(item => {
        userSegments.push({
          value: item,
          label: item,
        });
      });
      getAllSegment(allSegmentResponse => {
        allSegmentResponse.forEach(segmentItem => {
          if (segmentItem.segment.status === 'ACTIVE') {
            allSegments.push({
              value: segmentItem.segment.name,
              label: segmentItem.segment.name,
              type: segmentItem.segment.type,
              id: segmentItem.segment.id,
            });
          }
        });
        setVisitorSegmentList([...allSegments]);
      });
    });
  };

  const getStats = () => {
    const opts = {};
    opts.start = queryDate(getNowByTimeZone().subtract(6, 'month'));
    opts.end = queryDate(getNowByTimeZone());
    getAllStat(response => {
      getAllRbsStats(rbsResponse => {
        let stats = {};
        if (response) stats = { ...response };
        if (rbsResponse) stats = { ...stats, ...rbsResponse };

        setVisitorSegmentStats(stats);
      });
    }, opts);
  };

  const handleChangeSelectedTargetAudience = val => {
    if (val === selectedTargetAudience) {
      setSelectedTargetAudience('');
      onChangeVisitorSegment({
        include: true,
        segment: '',
        type: 'SEGMENT',
      });
      onChangeVisitorType({
        include: true,
        name: '',
        type: 'VISITOR',
      });
      return;
    }

    setSelectedTargetAudience(val);
    if (val === 'visitorSegment') {
      onChangeVisitorType({
        include: true,
        name: '',
        type: 'VISITOR',
      });
    } else if (val === 'visitorType') {
      onChangeVisitorSegment({
        include: true,
        segment: '',
        type: 'SEGMENT',
      });
    }
  };

  const handleChangeVisitorType = (val, type) => {
    if (type === 'include') {
      onChangeVisitorType({ ...visitorTypeState, include: val });
    } else if (type === 'name') {
      onChangeVisitorType({ ...visitorTypeState, name: val });
    }
  };

  const addCustomSegmentToStats = id => {
    getCustomSegmentStat(
      id,
      // eslint-disable-next-line consistent-return
      ({ segmentName, segmentedUserCount, totalUserCount }) => {
        if (segmentName && segmentedUserCount && totalUserCount) {
          const stats = {
            ...visitorSegmentStats,
            [segmentName]: {
              userCount: segmentedUserCount,
              otherUserCount: totalUserCount,
            },
          };
          return setVisitorSegmentStats(stats);
        }
      },
    );
  };

  const handleChangeVisitorSegment = (val, type, e) => {
    if (e?.type === 'EVENT_BASED') {
      addCustomSegmentToStats(e?.label);
    }
    if (type === 'include') {
      onChangeVisitorSegment({ ...visitorSegmentState, include: val });
    } else if (type === 'segment') {
      onChangeVisitorSegment({ ...visitorSegmentState, segment: val });
    }
  };

  useEffect(() => {
    getSegments();
    getStats();
  }, []);

  useEffect(() => {
    if (visitorTypeState.name && visitorSegmentState.name !== '') {
      setSelectedTargetAudience('visitorType');
    }
  }, [visitorTypeState]);

  useEffect(() => {
    const newVisitor = visitorSegmentList.find(
      item =>
        item.value === visitorSegmentState.segment &&
        item.type === 'EVENT_BASED',
    );
    if (newVisitor) {
      addCustomSegmentToStats(visitorSegmentState.segment);
    }
  }, [visitorSegmentState, visitorSegmentList]);

  return (
    <div className='form-element'>
      <span className='element-title'>Target Audience</span>
      <div className='element-column-field'>
        <div className='element-row-field target-audience'>
          <Checkbox
            name='visitorSegment'
            label='Visitor Segment'
            checked={selectedTargetAudience === 'visitorSegment'}
            onChange={() =>
              handleChangeSelectedTargetAudience('visitorSegment')
            }
          />
          <Checkbox
            name='visitorType'
            label='Visitor Type'
            checked={selectedTargetAudience === 'visitorType'}
            onChange={() => handleChangeSelectedTargetAudience('visitorType')}
          />
        </div>
        {selectedTargetAudience === 'visitorSegment' && (
          <div className='element-row-field'>
            <Select
              name='visitorSegment'
              className='element-select'
              label=''
              value={visitorSegmentState.include}
              options={visitorSegmentOptions}
              onChange={e => handleChangeVisitorSegment(e.value, 'include')}
              clearable={false}
            />
            <Select
              name='visitorSegmentValue'
              className='element-select'
              label=''
              value={visitorSegmentState.segment}
              options={visitorSegmentList}
              onChange={e => handleChangeVisitorSegment(e.value, 'segment', e)}
              clearable={false}
              searchable
            />
          </div>
        )}
        {selectedTargetAudience === 'visitorType' && (
          <div className='element-row-field'>
            <Select
              name='visitorType'
              className='element-select'
              label=''
              value={visitorTypeState.include}
              options={visitorTypeOptions.options}
              onChange={e => handleChangeVisitorType(e.value, 'include')}
              clearable={false}
            />
            <Select
              name='visitorTypeValue'
              className='element-select'
              label=''
              value={visitorTypeState.name}
              options={visitorTypeOptions.types}
              onChange={e => handleChangeVisitorType(e.value, 'name')}
              clearable={false}
            />
          </div>
        )}
        {selectedTargetAudience === 'visitorSegment' &&
          visitorSegmentState.segment && (
            <div className='element-row-field segment-counts'>
              <div className='element-column-field'>
                <span className='element-column-label secondary-label'>
                  Total Reachable User
                </span>
                <span className='element-column-label secondary-label'>
                  {selectedSegmentStats
                    ? selectedSegmentStats.otherUserCount +
                      selectedSegmentStats.userCount
                    : 0}
                </span>
              </div>
              <div className='element-column-field'>
                <span className='element-column-label secondary-label'>
                  Segment User
                </span>
                <span className='element-column-label secondary-label'>
                  {selectedSegmentStats ? selectedSegmentStats.userCount : 0}
                </span>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

TargetAudience.propTypes = {
  visitorSegmentState: PropTypes.shape({
    include: PropTypes.bool,
    segment: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  visitorTypeState: PropTypes.shape({
    include: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  onChangeVisitorSegment: PropTypes.func.isRequired,
  onChangeVisitorType: PropTypes.func.isRequired,
};

export default TargetAudience;
