/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { uiActions } from '../../../../actions';
import { Context as CountDownContext } from '../store/countDownProvider';
import { CountdownService } from '../services/countdownServices';
import {
  campaignCreateTypeConstant,
  countDownAdvancedConfig,
  getDynamicBreadCrumbOptions,
  selectCampaignType,
} from '../constants/constants';
import {
  CountSettings,
  SFYChildWrapper,
  SFYHeader,
  SFYImageInput,
  SFYRightBar,
} from '../../../../components/sfy-components/module-exports';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import { ColorPicker, TextField } from '../../../../components/fields';
import { t } from '../../../../system/ui';
import {
  PopupStyle,
  TargetAudience,
  TimingFrequency,
} from '../../social-proof/module-export';

import AdvancedConfig from '../../../../components/sfy-components/advanced-config/AdvancedConfig';
import { campaignActionButtonConstant } from '../../gamification/constants/constants';
import {
  getCountDownConfigCampaignDetails,
  getCountDownInitialTemplate,
} from '../utils/utils';
import './AddorEdit.scss';
import {
  desktopPosOptions,
  mobilePosOptions,
} from '../../social-proof/constants';
import {
  LiveModeUpdatePersonalization,
  TestModeAddPersonalization,
} from '../../../../constants/datamaps/notifications';
import { getAccountData } from '../../../../modules/auth/user';

const { isLoaded, isLoading, showNotification, confirmationDialog } = uiActions;

const AddOrEdit = ({ params }) => {
  const { mode, campaignId, campaignType } = params;

  const {
    state: { activeCountdownCampaign },
    editActiveCountdownCampaign,
    resetActiveCountdownCampaign,
    setActiveCountdownCampaign,
  } = useContext(CountDownContext);

  const [desktopPosition, setDesktopPosition] = useState(null);
  const [visitorSegment, setVisitorSegment] = useState({
    include: true,
    segment: '',
    type: 'SEGMENT',
  });
  const [visitorType, setVisitorType] = useState({
    include: true,
    name: '',
    type: 'VISITOR',
  });
  const [isAdvancedConfigShown, setIsAdvancedConfigShown] = useState(false);
  const [templates, setTemplates] = useState(
    getCountDownInitialTemplate(activeCountdownCampaign, campaignType),
  );

  const validate = () => {
    const {
      name,
      title,
      targetUrl,
      devices,
      filters,
      imageUrl,
      targetSelector,
    } = activeCountdownCampaign;
    if (campaignType !== 'notification' && !imageUrl) {
      return 'Please fill all the required fields.';
    }
    if (!targetSelector && campaignType === 'hero') {
      return 'Please fill all the required fields.';
    }
    if (!name || !title || !targetUrl || devices.length === 0) {
      return 'Please fill all the required fields.';
    }

    const pagesToShow =
      filters?.find(filter => {
        return filter.type === 'PAGE' && filter?.includedCategories?.length > 0;
      })?.includedCategories || [];
    if (pagesToShow.length === 0) {
      return 'Please select at least one page to show the campaign.';
    }

    return true;
  };

  const goTestOrLive = saveType => {
    const fixedStatus = saveType === 'live' ? 'ACTIVE' : 'TEST';
    const body = {
      ...activeCountdownCampaign,
      status: fixedStatus,
      ...templates,
    };
    if (mode === 'edit') {
      const id = campaignId;
      isLoading();
      CountdownService()
        .update({ id, body })
        .then(res => {
          const { status, name } = res;
          isLoaded();
          let content = TestModeAddPersonalization;
          if (status === 'ACTIVE') {
            content = LiveModeUpdatePersonalization;
          }
          showNotification({
            content,
            className: 'notification-success',
          });
          setActiveCountdownCampaign(res);
        })
        .finally(() => {
          isLoaded();
        });
    } else {
      isLoading();
      body.accountId = getAccountData('accountId');
      CountdownService()
        .create({ body })
        .then(res => {
          const { status, instanceId } = res;
          isLoaded();
          let content = TestModeAddPersonalization;
          if (status === 'ACTIVE') {
            content = LiveModeUpdatePersonalization;
          }
          showNotification({
            content,
            className: 'notification-success',
          });
          browserHistory.push(
            `/behavioural-targeting/engagement/countdown/${campaignType}/edit/${instanceId}`,
          );
        })
        .finally(() => {
          isLoaded();
        });
    }
  };

  const validateAndSave = type => {
    const validation = validate();
    if (validation !== true) {
      const content = () => <p>{validation}</p>;
      showNotification({
        content,
        className: 'notification-fail',
      });
      return;
    }

    const displayStatus = type === 'test' ? 'Test' : 'Live';
    confirmationDialog({
      title: `Go ${displayStatus} the Campaign?`,
      content: `Are you sure you want to get the campaign ${displayStatus}?`,
      onConfirm: () => {
        goTestOrLive(type);
      },
    });
  };

  const editCampaign = (fieldKey, fieldValue) => {
    editActiveCountdownCampaign({ [fieldKey]: fieldValue });
  };

  useEffect(() => {
    if (mode === 'edit' || mode === 'duplicate') {
      isLoading();
      CountdownService()
        .get({ id: campaignId })
        .then(res => {
          isLoaded();
          setActiveCountdownCampaign(res);
        });
    }
    editCampaign('type', campaignCreateTypeConstant[campaignType]);
  }, [mode]);

  useEffect(() => {
    setTemplates(
      getCountDownInitialTemplate(activeCountdownCampaign, campaignType),
    );
  }, [activeCountdownCampaign, campaignType]);

  useEffect(() => {
    return () => {
      resetActiveCountdownCampaign();
    };
  }, []);

  const setAdvancedConfigVisibility = (actionType = 'open') => {
    setIsAdvancedConfigShown(actionType === 'open');
  };

  const handlePositionChange = (pos, type) => {
    if (type === 'desktop') {
      setDesktopPosition(pos);
      if (campaignType !== 'notification') {
        editCampaign(
          'verticalPosition',
          pos
            ?.split('&')?.[0]
            ?.trim()
            .toLowerCase(),
        );
        editCampaign(
          'horizontalPosition',
          pos
            ?.split('&')?.[1]
            ?.trim()
            .toLowerCase(),
        );
      } else {
        editCampaign('verticalPosition', pos);
        editCampaign('horizontalPosition', 'middle');
      }
    }
  };

  const campaignActionButtonConstantWithFunctions = campaignActionButtonConstant.map(
    actionButton => {
      let actionButtonWithFunction;
      switch (actionButton.type) {
        case 'test':
        case 'live':
          actionButtonWithFunction = () => validateAndSave(actionButton.type);
          break;
        case 'advanceConfig':
          actionButtonWithFunction = setAdvancedConfigVisibility;
          break;
        default:
          actionButtonWithFunction = () => {};
          break;
      }
      return {
        ...actionButton,
        action: actionButtonWithFunction,
      };
    },
  );

  const onChangeImg = e => {
    editCampaign('imageUrl', e);
  };

  const handleChangeFilter = (filter, type) => {
    if (type === 'VISITOR') {
      setVisitorType(filter);
    } else {
      setVisitorSegment(filter);
    }

    const tempFilters = activeCountdownCampaign?.filters?.filter(
      filterItem =>
        filterItem.type !== 'VISITOR' && filterItem.type !== 'SEGMENT',
    );
    let shouldFilterBeAdded = false;
    if (filter.type === 'VISITOR' && filter.name !== '') {
      shouldFilterBeAdded = true;
    }
    if (filter.type === 'SEGMENT' && filter.segment !== '') {
      shouldFilterBeAdded = true;
    }
    editCampaign('filters', [
      ...tempFilters,
      ...(shouldFilterBeAdded ? [filter] : []),
    ]);
  };

  const editCampaignForRightBar = (type, value) => {
    if (type === 'filters') {
      const filters = activeCountdownCampaign?.filters.filter(
        filter => filter.type !== value.type,
      );
      editActiveCountdownCampaign({
        filters: [...filters, value],
      });
      return;
    }
    editActiveCountdownCampaign({ [type]: value });
  };

  const statement = () => {
    let options = [];
    if (campaignType !== 'notification') {
      options = desktopPosOptions[6];
    } else {
      options = mobilePosOptions[0];
    }
    return options;
  };

  useEffect(() => {
    const {
      verticalPosition,
      horizontalPosition,
      filters = [],
    } = activeCountdownCampaign;

    filters
      ?.filter(filter => filter.type === 'VISITOR')
      .forEach(filter => {
        setVisitorType(filter);
      });

    filters
      ?.filter(filter => filter.type === 'SEGMENT')
      .forEach(filter => {
        setVisitorSegment(filter);
      });

    if (campaignType !== 'notification') {
      setDesktopPosition(
        desktopPosOptions?.find(pos => {
          const posVertical = pos
            ?.split('&')?.[0]
            ?.trim()
            .toLowerCase();
          const posHorizontal = pos
            ?.split('&')?.[1]
            ?.trim()
            .toLowerCase();
          return (
            posVertical === verticalPosition?.toLowerCase() &&
            posHorizontal === horizontalPosition?.toLowerCase()
          );
        }),
      );
    } else {
      setDesktopPosition(
        mobilePosOptions.find(pos => {
          const posVertical = pos.toLowerCase();
          const posHorizontal = 'middle';
          return (
            posVertical === verticalPosition?.toLowerCase() &&
            posHorizontal === 'middle'
          );
        }),
      );
    }
  }, [activeCountdownCampaign]);

  useEffect(() => {
    statement();
    if (mode === 'add' && campaignType === 'notification') {
      editCampaign('verticalPosition', mobilePosOptions[0]);
    }
  }, []);

  const overlayCheckBoxRenderer = () => {
    const notificationText = t('Make it Sticky');
    const popupText = t('Show overlay in background');
    const checkBoxKey = campaignType === 'popup' ? 'overlay' : 'sticky';

    return (
      <label className='CountSettings__control' htmlFor={campaignType}>
        <input
          id={campaignType}
          name='activate'
          type='checkbox'
          checked={activeCountdownCampaign[checkBoxKey]}
          value={activeCountdownCampaign[checkBoxKey]}
          onChange={() =>
            editCampaign(checkBoxKey, !activeCountdownCampaign[checkBoxKey])
          }
        />
        {campaignType === 'notification' ? notificationText : popupText}
      </label>
    );
  };

  return (
    <>
      <SFYHeader
        pageTitle={selectCampaignType(campaignType)}
        breadCrumbOptions={getDynamicBreadCrumbOptions('Countdown')}
      />

      <SFYChildWrapper type='flexHalfColGap2'>
        <InputCardWrapper
          type='block'
          cardTitle='Campaign Title*'
          labelFor='campaignTitle'
        >
          <TextField
            name='title'
            id='campaignTitle'
            value={activeCountdownCampaign?.title}
            className='item-stacked one-whole'
            required
            onChange={e => editCampaign('title', e.target.value)}
            placeholder={t('Type here..')}
          />
        </InputCardWrapper>
        <InputCardWrapper
          type='block'
          cardTitle='Target URL*'
          labelFor='targetUrl'
        >
          <TextField
            name='targetUrl'
            id='targetUrl'
            value={activeCountdownCampaign?.targetUrl}
            className='item-stacked one-whole'
            onChange={e => editCampaign('targetUrl', e.target.value)}
            required
            placeholder={t('Type here..')}
          />
        </InputCardWrapper>
        <InputCardWrapper
          type='block'
          cardTitle='Campaign Description'
          labelFor='campaignDescription'
        >
          <TextField
            name='description'
            id='campaignDescription'
            value={activeCountdownCampaign?.description}
            onChange={e => editCampaign('description', e.target.value)}
            className='item-stacked one-whole'
            placeholder={t('Type here..')}
          />
        </InputCardWrapper>

        <CountSettings
          mode={mode}
          activeCountdownCampaign={activeCountdownCampaign}
          editCampaign={editCampaign}
          editActiveCountdownCampaign={editActiveCountdownCampaign}
        />

        <InputCardWrapper type='adaptiveBlock' cardTitle='Style'>
          <div className='BannerStyleImg'>
            <div className='BannerStyleImg__checkbox'>
              {campaignType === 'popup' || campaignType === 'notification'
                ? overlayCheckBoxRenderer()
                : ''}
            </div>
            <div className='BannerStyleImg__colorPicker'>
              <span className='CTAButton__colorPicker__title'>
                {t('Banner Style')}
              </span>
              <div className='CTAButton__colorPicker__body'>
                <div className='CTAButton__colorPicker__col1'>
                  <span className='ColorOfBase__color__title'>
                    {t(' Background Color')}
                  </span>
                  <ColorPicker
                    className='item-field large'
                    value={activeCountdownCampaign?.bgColor || '#ffffff'}
                    onChange={color => editCampaign('bgColor', color)}
                    disableTooltip
                  />
                </div>
                <div className='CTAButton__colorPicker__col2'>
                  <span className='ColorOfBase__color__title'>Text Color</span>
                  <ColorPicker
                    className='item-field large'
                    value={activeCountdownCampaign?.textColor || '#000000'}
                    onChange={color => editCampaign('textColor', color)}
                    disableTooltip
                  />
                </div>
              </div>
            </div>
            <div className='SpinWheel__img'>
              <span className='CTAButton__colorPicker__title'>
                {t('Banner Image Upload')}
              </span>
              <small>
                {t(
                  ' You can upgrade your pop-up style with adding an image at the behind of countdown.',
                )}
              </small>
              <SFYImageInput
                key='wof-pointer-image'
                iconName='wof-pointer-image'
                stateSetter={onChangeImg}
                fetchedImage={activeCountdownCampaign?.imageUrl}
                type='web'
                withoutCardSpace
                endpointType='engagement'
                isRequired={campaignType !== 'notification'}
              />
            </div>
          </div>
        </InputCardWrapper>

        <div className='CountDown'>
          <div id='wizard' className='wizard social-proof-form'>
            <div className='page-content-block form-content'>
              {campaignType !== 'hero' && (
                <PopupStyle
                  activeStyles={['position']}
                  desktopPosition={desktopPosition}
                  title={t('Position')}
                  onChangeDesktopPosition={pos =>
                    handlePositionChange(pos, 'desktop')
                  }
                />
              )}
              <TimingFrequency
                timingState={
                  activeCountdownCampaign?.timing || {
                    param: '',
                    type: 'IMMEDIATE',
                  }
                }
                frequencyState={
                  activeCountdownCampaign?.frequency || {
                    param: '',
                    type: 'ALWAYS',
                  }
                }
                delayAllPages={
                  activeCountdownCampaign?.timing?.type === 'DELAY_ALL_PAGES'
                }
                onChangeTiming={val => editCampaign('timing', val)}
                onChangeFrequency={val => editCampaign('frequency', val)}
                onChangeDelay={val =>
                  editCampaign({
                    ...activeCountdownCampaign.timing,
                    type: val ? 'DELAY_ALL_PAGES' : 'DELAY',
                  })
                }
              />
              <TargetAudience
                visitorSegmentState={visitorSegment}
                visitorTypeState={visitorType}
                onChangeVisitorSegment={val =>
                  handleChangeFilter(val, 'SEGMENT')
                }
                onChangeVisitorType={val => handleChangeFilter(val, 'VISITOR')}
              />
            </div>
          </div>
        </div>

        <SFYRightBar
          campaignActionButtonConstant={
            campaignActionButtonConstantWithFunctions
          }
          activeCampaign={activeCountdownCampaign}
          startDate={
            activeCountdownCampaign?.startDate === ''
              ? editCampaign('startDate', new Date().getTime())
              : new Date(activeCountdownCampaign?.startDate).getTime()
          }
          campaignSetter={editCampaignForRightBar}
          isCampaignNameVisible
          isDevicesVisible
          isPagesAllVisible
          isPagesUrlVisible
          isAddCampaign={mode === 'add'}
          isTargetSelectorVisible={campaignType === 'hero'}
        />
        <AdvancedConfig
          campaignDetails={getCountDownConfigCampaignDetails(
            activeCountdownCampaign,
          )}
          templates={templates}
          setTemplates={setTemplates}
          activeTabs={countDownAdvancedConfig.activeTabs}
          params={countDownAdvancedConfig.params}
          isShow={isAdvancedConfigShown}
          devices={activeCountdownCampaign?.devices || []}
          onHide={() => setAdvancedConfigVisibility('close')}
        />
      </SFYChildWrapper>
    </>
  );
};

export default AddOrEdit;
