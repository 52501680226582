import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { TextField } from '../fields';
import { decodeHtml } from '../../system/string';
import { convertToPositiveInt } from './utils/utils';

import {
  ageOperators,
  operatorColumns,
} from '../../constants/datamaps/rule-based-segmentation';

const operatorObjToArray = operatorObj => {
  const operatorArray = [];
  Object.keys(operatorObj).forEach(key => {
    operatorArray.push({
      value: decodeHtml(operatorObj[key].value),
      title: decodeHtml(operatorObj[key].name),
      label: decodeHtml(operatorObj[key].name),
    });
  });
  return operatorArray;
};

const criteriaFilterAge = ({
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const defaultFilterData = {
    dataType: criteriaData?.dataType,
    column: operatorColumns.ageOperator,
    operator: ageOperators.gte.value,
    value: '',
  };

  const [ageValue, setAmountValue] = useState();
  const [ageValueMax, setAmountValueMax] = useState(); // Using only with between operator
  const [selectedOperator, setSelectedOperator] = useState('');

  useEffect(() => {
    const tempStatements = statements?.[filterType];

    if (tempStatements?.length > 1) {
      // Between operator
      tempStatements.forEach(statement => {
        if (statement.operator === ageOperators.gte.value) {
          setAmountValue(statement.value);
        } else if (statement.operator === ageOperators.lte.value) {
          setAmountValueMax(statement.value);
        }
      });
      setSelectedOperator(ageOperators.between.value);
    } else if (tempStatements?.length === 1) {
      setAmountValue(tempStatements[0].value);
      setSelectedOperator(tempStatements[0].operator);
    } else {
      setSelectedOperator(defaultFilterData.operator);
      setAmountValue(defaultFilterData.value);
    }
  }, [statements]);

  const triggerCallbackFunc = (val, type) => {
    const columnVal = operatorColumns.ageOperator;
    const operatorVal = type === 'operator' ? val.value : selectedOperator;
    const ageVal =
      type === 'age' ? convertToPositiveInt(val.target.value, 0) : ageValue;
    let ageValMax =
      type === 'ageMax'
        ? convertToPositiveInt(val.target.value, 0)
        : ageValueMax;

    if (type === 'operator' && val.value !== ageOperators.between.value) {
      ageValMax = null;
    }

    const filterData = [
      {
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator:
          operatorVal === ageOperators.between.value
            ? ageOperators.gte.value
            : operatorVal,
        value: ageVal,
      },
    ];
    if (operatorVal === ageOperators.between.value) {
      filterData.push({
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator: ageOperators.lte.value,
        value: ageValMax,
      });
    }

    callbackFunc(filterType, filterData);
  };

  const onChange = (selectedValue, type) => {
    switch (type) {
      case 'age':
        setAmountValue(convertToPositiveInt(selectedValue.target.value, 0));
        break;
      case 'ageMax':
        setAmountValueMax(convertToPositiveInt(selectedValue.target.value, 0));
        break;
      case 'operator':
        setSelectedOperator(selectedValue?.value);
        break;
      default:
        break;
    }

    triggerCallbackFunc(selectedValue, type);
  };

  return (
    <div
      className={`rbs-filter-field ${
        selectedOperator === ageOperators.between.value ? 'three-row' : ''
      }`}
    >
      <div className='filter-operator'>
        <span className='filter-operator-title'>*Operator</span>
        <Select
          value={selectedOperator}
          options={operatorObjToArray(ageOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          onChange={e => onChange(e, 'operator')}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      <div className='filter-connector' />
      <div className='filter-operator'>
        <span className='filter-operator-title'>*Age</span>
        <TextField
          value={ageValue}
          onChange={e => onChange(e, 'age')}
          type='number'
          dontShow='false'
          placeholder={
            selectedOperator === ageOperators.between.value
              ? 'e.g. 24'
              : 'Ex: 25'
          }
          required
        />
        {selectedOperator === ageOperators.between.value && (
          <>
            <div className='filter-multi-connector' />
            <TextField
              value={ageValueMax}
              onChange={e => onChange(e, 'ageMax')}
              type='number'
              placeholder='e.g. 34'
              dontShow='false'
              validation={ageValue ? { minNumber: ageValue } : {}}
              required
            />
          </>
        )}
      </div>
    </div>
  );
};

export default criteriaFilterAge;
