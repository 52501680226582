/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { devices, mediums } from "../../constants/datamaps/campaigns";
import { getNow } from "../../system/date";

import { t } from "../../system/ui";
import { getFormattedDate } from "../../system/date";

import { uiActions, campaignActions } from "../../actions";

import { filterStatus, filterPage } from "../../modules/campaigns/filters";

import Icon from "../icon";
import WidgetActions from "./actions";
import Icons from '../icons';

class WidgetList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      count: 0,
      isToggled: false
    };

    this.getCampaigns = this.getCampaigns.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.getCampaigns(this.props.campaigns);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaigns !== newProps.campaigns) {
      this.getCampaigns(newProps.campaigns);
    }

    if (newProps.status === "PASSIVE") {
      this.getCampaigns(newProps.campaigns);

      campaignActions.filterActiveCampaigns();
    }
  }

  isScheduled(campaign) {
    let now = getNow().valueOf();
    return campaign.limitations.activeDates.startDate > now;
  }

  isCompleted(campaign) {
    let now = getNow().valueOf();
    return campaign.limitations.activeDates.endDate < now;
  }

  getCampaigns(allCampaigns) {
    let type = this.props.type === "LIVE" ? "ACTIVE" : "TEST";
    let list = filterPage(filterStatus(allCampaigns, type), this.props.name);
    if (this.props.dateFilter) {
      let campaignsFilteredByDate = [];
      if (this.props.dateFilter === "SCHEDULED") {
        campaignsFilteredByDate = list.filter(campaign => {
          return this.isScheduled(campaign);
        });
      } else {
        campaignsFilteredByDate = list.filter(campaign => {
          return this.isCompleted(campaign);
        });
      }
      this.setState({
        campaigns: campaignsFilteredByDate,
        count: campaignsFilteredByDate.length
      });
    } else {
      if (type === "ACTIVE") {
        list = list.filter(campaign => {
          return !this.isScheduled(campaign) && !this.isCompleted(campaign);
        });
      }
      this.setState({
        campaigns: list,
        count: list.length
      });
    }
    uiActions.isLoaded();
  }

  toggle() {
    if (this.props.closed) {
      this.setState({
        isToggled: !this.state.isToggled
      });
    }
  }

  render() {
    return this.state.count > 0 ? (
      <div
        className={classNames("widget-list-has-items", {
          "will-toggle": this.props.closed,
          "is-toggled": this.state.isToggled
        })}
      >
        <h3
          className={classNames("page-subtitle", {
            passive: this.props.type === "TEST"
          })}
          onClick={this.toggle}
        >
          {t(this.props.title)} ({this.state.count})
        </h3>
        <ul className="widget-list">
          {this.state.campaigns.map((campaign, index) => {
            let isPromotion =
              campaign.actions.length > 0 &&
              campaign.actions[0].type === "recommendPromotion";
            let isKeyword =
              campaign.actions.length > 0 &&
              campaign.actions[0].type === "recommendKeyword";
            const isInAB = this.props.idsInAB.find(id => campaign.instanceId === id);
            return (
              <li key={index} className="widget-list-item">
                <h2 className="widget-title">
                  {campaign.scenarioName}
                  {this.props.page === "triggerbased" && (
                    <div className="widget-title-desc">
                      <strong>{t("Event Name")}:</strong>{" "}
                      {campaign.inputs.length > 0 &&
                        campaign.inputs[0].value[0]}
                    </div>
                  )}
                </h2>
                <div className="widget-meta" style={{ display: 'flex', alignItems: 'center' }}>
                  {isPromotion && (
                    <span className="promotion-label">{t("Promotion")}</span>
                  )}
                  {isKeyword && (
                    <span className="promotion-label">{t("Keyword")}</span>
                  )}
                  {isInAB && (
                    <span style={{ display: 'inline-flex', alignItems: 'center', marginRight: '10px' }}>
                      <Icons name='experimentation' />
                      <span style={{ marginLeft: '5px' }}>in an A/B test</span>
                    </span>
                  )}
                  <span className="widget-meta-date">
                    <Icon name="calendar" />
                    {getFormattedDate(
                      campaign.limitations.activeDates.startDate
                    )}
                  </span>
                  {mediums[campaign.device].map(device => {
                    if (device === "PCTABLET") {
                      return;
                    }

                    return (
                      <span key={device} className="widget-meta-mobile">
                        <Icon name={device.toLowerCase()} />
                        {t(devices[device])}
                      </span>
                    );
                  })}
                </div>
                <WidgetActions
                  campaign={campaign}
                  {...campaign}
                  {...this.props}
                />
              </li>
            );
          })}
        </ul>
      </div>
    ) : (
      false
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  user: store.user.user,
  status: store.campaigns.status
});

export default connect(mapStatesToProps)(WidgetList);
