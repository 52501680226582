import React from 'react';
import { uiActions } from '../../../actions';
import { AccountStatus } from '../../../constants/datamaps/modals';
import { isMasterUser } from '../../../modules/auth/user';

const ERR_CODES = {
  Unauthorized: 401,
  Forbidden: 403,
  Locked: 423,
};

export default function LoginExceptionHandler({
  err,
  setState,
  browserHistory,
}) {
  const { error } = err;

  switch (error.status) {
    case ERR_CODES.Unauthorized:
      if (error.response.message && !error.response.accountStatus) {
        setState({ error });
      }

      if (!error.response.message && error.response.accountStatus) {
        if (error.response.AccountStatus === 'PANEL_RESTRICTED') {
          if (isMasterUser()) {
            return browserHistory.push('/admin');
          }

          window.location.href = '/login';
        }
        return uiActions.openModal({
          title: 'Important Notice!',
          content: () => <AccountStatus type={error.response.accountStatus} />,
        });
      }
      break;
    case ERR_CODES.Forbidden:
      browserHistory.push('/mail-send/verification-mail/');
      break;
    case ERR_CODES.Locked:
      browserHistory.push('/mail-send/verification-mail-already');
      break;
    default:
      setState({
        unexpectedError: true,
      });
  }
}
