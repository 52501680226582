import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const EmailBell = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.4286 17.9244L10.9911 16.4123C11.1244 17.2925 10.6531 18.185 9.7958 18.5489C8.9385 18.9128 7.9692 18.6318 7.4286 17.9244ZM12.8613 0.093922C14.947 1.23347 16.5128 2.69659 17.543 4.48101C18.5732 6.26543 19.0575 8.353 19.0015 10.7291C18.9917 11.1432 18.6481 11.4709 18.234 11.4612C17.8199 11.4514 17.4921 11.1078 17.5019 10.6937C17.5516 8.5824 17.1304 6.76635 16.244 5.23101C15.3575 3.69566 13.9954 2.42285 12.1421 1.41027C11.7786 1.21167 11.6449 0.756002 11.8435 0.392502C12.0421 0.0290019 12.4978 -0.104678 12.8613 0.093922ZM3.71144 4.51547C6.57319 3.24134 9.9156 4.4461 11.3109 7.26099L11.4092 7.47041L12.6966 10.3623L14.3949 11.832C14.4876 11.9123 14.5679 12.0057 14.661 12.1568L14.7197 12.2711C14.9991 12.9024 14.7138 13.6407 14.0825 13.9201L3.75588 18.4902C3.59655 18.5607 3.42424 18.5972 3.25 18.5972C2.55964 18.5972 2 18.0375 2 17.3475L1.99892 15.0046L0.756538 12.2132C-0.556962 9.2631 0.765718 5.82699 3.71144 4.51547ZM2.1269 11.6032L3.49877 14.6855L3.49982 16.9632L13.1149 12.708L11.4622 11.2777L10.0453 8.0946L9.9603 7.91326C8.9289 5.83289 6.44847 4.93882 4.32154 5.88579C2.13205 6.86061 1.15056 9.4103 2.1269 11.6032ZM12.6237 3.69457C13.5907 4.22184 14.3657 4.9892 14.94 5.98387C15.5142 6.97855 15.7913 8.0334 15.7644 9.1345C15.7543 9.5486 15.4105 9.8761 14.9964 9.866C14.5823 9.8559 14.2548 9.512 14.2649 9.0979C14.2849 8.2763 14.0792 7.49294 13.6409 6.73387C13.2027 5.97481 12.6272 5.40494 11.9056 5.01151C11.542 4.81321 11.4079 4.35765 11.6062 3.99399C11.8045 3.63032 12.2601 3.49627 12.6237 3.69457Z'
        fill='#5D5D61'
      />
    </svg>
  );
};

EmailBell.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

EmailBell.defaultProps = {
  width: '18px',
  height: '18px',
};

export default EmailBell;
