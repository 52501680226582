/**
 * Created by mehmetyurtar on 12/6/17.
 */
import React from "react";

class IconBrowser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={this.state.width}
        height={this.state.height}
      >
        <path
          fill={this.state.color}
          d="M21.6,6.6C21.6,6.6,21.6,6.6,21.6,6.6C19.6,3.2,16.1,1,12,1C7.9,1,4.4,3.2,2.5,6.5c0,0,0,0.1,0,0.1C1.5,8.2,1,10,1,12
	s0.5,3.8,1.4,5.4c0,0,0,0.1,0,0.1C4.4,20.8,7.9,23,12,23c4.1,0,7.6-2.2,9.5-5.5c0,0,0-0.1,0-0.1C22.5,15.8,23,14,23,12
	S22.5,8.2,21.6,6.6z M20.1,16h-2.5c0.2-0.9,0.3-1.9,0.4-3h3C20.8,14.1,20.5,15.1,20.1,16z M3.9,8h2.5C6.2,8.9,6.1,9.9,6,11h-3
	C3.2,9.9,3.5,8.9,3.9,8z M13,6V3.3c0.7,0.5,1.4,1.4,1.9,2.7H13z M15.6,8c0.2,0.9,0.4,1.9,0.4,3h-3V8H15.6z M11,3.3V6H9.1
	C9.6,4.7,10.3,3.8,11,3.3z M11,8v3H8c0.1-1.1,0.2-2.1,0.4-3H11z M3.1,13h3c0,1.1,0.2,2.1,0.4,3H3.9C3.5,15.1,3.2,14.1,3.1,13z M8,13
	h3v3H8.4C8.2,15.1,8.1,14.1,8,13z M11,18v2.7c-0.7-0.5-1.4-1.4-1.9-2.7H11z M13,20.7V18h1.9C14.4,19.3,13.7,20.2,13,20.7z M13,16v-3
	h3c-0.1,1.1-0.2,2.1-0.4,3H13z M18,11c0-1.1-0.2-2.1-0.4-3h2.5c0.5,0.9,0.8,1.9,0.9,3H18z M18.7,6h-1.6c-0.3-0.7-0.5-1.4-0.9-2
	C17.1,4.5,18,5.2,18.7,6z M7.8,4C7.5,4.6,7.2,5.3,6.9,6H5.3C6,5.2,6.9,4.5,7.8,4z M5.3,18h1.6c0.3,0.7,0.5,1.4,0.9,2
	C6.9,19.5,6,18.8,5.3,18z M16.2,20c0.3-0.6,0.6-1.2,0.9-2h1.6C18,18.8,17.1,19.5,16.2,20z"
        />
      </svg>
    );
  }
}

export default IconBrowser;
