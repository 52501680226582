import { inclusionOrExclusionConverterForFrontEnd } from './Filters/utils/inclusion-exlusion-converter';
import { t } from '../../system/ui';

export const checkRelatedProductId = bundleList => {
  const bundleProductList = bundleList.filter(
    bundle => bundle.type === 'product',
  );

  if (
    bundleProductList.length === 1 &&
    bundleProductList[0]?.productId !== ''
  ) {
    return bundleProductList[0].productId;
  }
  return '';
};

export const getAllPreviewProducts = bundleList => {
  const previewProducts = [];
  const categoryPostList = [];
  const relatedProductId = checkRelatedProductId(bundleList);
  bundleList.forEach(bundle => {
    if (bundle.type === 'product' && bundle.productId !== '') {
      previewProducts.push({ ...bundle, productId: bundle.id });
    } else if (bundle.type === 'category') {
      categoryPostList.push({ ...bundle, productId: relatedProductId });
    }
  });

  return { previewProducts, categoryPostList };
};

export const STLConvertForFrontend = requestResponse => {
  const categoryBundles = requestResponse.categoryBundles.map(category => {
    return {
      ...category,
      params: {
        inclusions: inclusionOrExclusionConverterForFrontEnd(
          category?.params?.inclusions,
          category?.index,
        ),
        exclusions: inclusionOrExclusionConverterForFrontEnd(
          category?.params?.exclusions,
          category?.index,
        ),
      },
      type: 'category',
    };
  });
  const productBundles = requestResponse.productBundles.map(product => ({
    ...product,
    type: 'product',
  }));

  const bundleList = [...categoryBundles, ...productBundles];
  bundleList.sort((a, b) => a.index - b.index);

  return {
    bundleGroupName: requestResponse?.name,
    bundleList,
    relatedProductId: checkRelatedProductId(bundleList),
  };
};

export const STLConvertForBackend = (
  bundleList,
  bundleGroupName,
  campaignId,
) => {
  const categoryBundles = bundleList
    .map(({ index, category, params, type }) => {
      if (type === 'category') {
        return {
          index,
          category,
          params,
        };
      }
      return null;
    })
    .filter(bundle => bundle !== null);

  const productBundles = bundleList
    .map(({ index, id, name, inStock, image, price, oldPrice, type }) => {
      if (type === 'product') {
        return {
          index,
          id,
          name,
          inStock,
          image,
          price,
          oldPrice,
        };
      }
      return null;
    })
    .filter(bundle => bundle !== null);

  const convertedSTL = {
    name: bundleGroupName,
    productBundles,
    categoryBundles,
  };

  if (campaignId) {
    convertedSTL.id = campaignId;
  }

  return convertedSTL;
};

export const stringArrayToSelectFormat = operatorArr => {
  if (!operatorArr) return [];
  const selectFormattedArray = [];
  operatorArr.forEach(operator => {
    selectFormattedArray.push({
      value: operator,
      label: operator,
    });
  });
  return selectFormattedArray;
};

export const mappedProductArrayToSelectFormat = operatorArr => {
  if (!operatorArr) return [];
  const selectFormattedArray = [];
  operatorArr.forEach(operator => {
    selectFormattedArray.push({
      value: operator.productId,
      label: operator.name,
    });
  });
  return selectFormattedArray;
};

export const selectFormatToStringArray = selectArray => {
  if (!selectArray) return [];
  return selectArray.map(select => {
    return select.value;
  });
};

export const isValidUrl = url => {
  if (url.includes('www.')) {
    return true;
  }
  return !!(url.includes('http://') || url.includes('https://'));
};

export const getOnlyFilledArrayWithRequiredFields = (array, requiredFields) => {
  return array.filter(item => {
    let isItemValid = true;
    requiredFields.forEach(field => {
      if (!item[field]) {
        isItemValid = false;
      }
    });
    return isItemValid;
  });
};

export const DateFormatter = (
  date,
  format = 'DD-MM-YYYY',
  returnWithSyntax = '/',
) => {
  if (!date) return '';
  if (typeof date === 'undefined') return '';

  const enumMonths = {
    1: t('January'),
    2: t('February'),
    3: t('March'),
    4: t('April'),
    5: t('May'),
    6: t('Jun'),
    7: t('July'),
    8: t('August'),
    9: t('September'),
    10: t('October'),
    11: t('November'),
    12: t('December'),
  };
  const returnTypes = {
    '/': '/',
    '-': '-',
    '.': '.',
    ' ': ' ',
    ',': ',',
  };

  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth();
  const dateNum = dateObj.getDate();
  const hour = dateObj.getHours();
  const minute = dateObj.getMinutes();
  const second = dateObj.getSeconds();
  const monthName = enumMonths[month];

  // refactor code below with switch
  switch (format) {
    case 'YYYY-MM-DD':
      return `${year} ${returnTypes[returnWithSyntax]} ${month + 1} ${
        returnTypes[returnWithSyntax]
      } ${dateNum}`;
    case 'DD-MM-YYYY':
      return `${dateNum}${returnTypes[returnWithSyntax]}${month + 1}${
        returnTypes[returnWithSyntax]
      }${year}`;
    case 'DD-MM-YYYY HH:MM:SS':
      return `${dateNum} ${returnTypes[returnWithSyntax]} ${month + 1}${
        returnTypes[returnWithSyntax]
      } ${returnTypes[returnWithSyntax]} ${year} ${hour}:${minute}:${second}`;
    case 'DD-MM-YY': {
      const formattedDate = [];
      formattedDate.push(dateNum);
      formattedDate.push((month + 1 < 10 ? '0' : '') + String(month + 1));
      formattedDate.push(year.toString().slice(2));

      return formattedDate.join(returnTypes[returnWithSyntax]);
    }
    case 'DD-MMM-YY':
      return `${dateNum}  ${enumMonths[month + 1]}${
        returnTypes[returnWithSyntax]
      } ${year.toString().slice(2)}`;
    case 'YYYY-MM-DD HH:MM:SS':
      return `${year} ${returnTypes[returnWithSyntax]} ${month + 1} ${
        returnTypes[returnWithSyntax]
      } ${dateNum} ${hour}:${minute}:${second}`;
    case 'YYYY-MM-DD HH:MM':
      return `${year} ${returnTypes[returnWithSyntax]} ${month + 1} ${
        returnTypes[returnWithSyntax]
      } ${dateNum} ${hour}:${minute}`;
    case 'YYYY-MM-DD HH':
      return `${year} ${returnTypes[returnWithSyntax]} ${month + 1} ${
        returnTypes[returnWithSyntax]
      } ${dateNum} ${hour}`;
    case 'YYYY-MM-DD HH:MM:SS AM/PM':
      return `${year} ${returnTypes[returnWithSyntax]} ${month + 1} ${
        returnTypes[returnWithSyntax]
      } ${dateNum} ${hour}:${minute}:${second} ${hour > 12 ? 'PM' : 'AM'}`;
    case 'YYYY-MM-DD HH:MM AM/PM':
      return `${year} ${returnTypes[returnWithSyntax]} ${month + 1} ${
        returnTypes[returnWithSyntax]
      } ${dateNum} ${hour}:${minute} ${hour > 12 ? 'PM' : 'AM'}`;
    case 'YYYY-MM-DD HH AM/PM':
      return `${year} ${returnTypes[returnWithSyntax]} ${month + 1} ${
        returnTypes[returnWithSyntax]
      } ${dateNum} ${hour} ${hour > 12 ? 'PM' : 'AM'}`;
    case 'DD-MM-YY HH:MM:SS':
      return `${dateNum} ${returnTypes[returnWithSyntax]} ${month + 1} ${
        returnTypes[returnWithSyntax]
      } ${returnTypes[returnWithSyntax]} ${year
        .toString()
        .slice(2)} ${hour}:${minute}:${second}`;
    case 'DD-MMM-YYYY':
      return `${dateNum} ${returnTypes[returnWithSyntax]} ${monthName} ${returnTypes[returnWithSyntax]} ${year}`;
    case 'DD-MMM-YYYY HH:MM:SS':
      return `${dateNum} ${returnTypes[returnWithSyntax]} ${monthName} ${returnTypes[returnWithSyntax]} ${year} ${hour}:${minute}:${second}`;
    default:
      return '';
  }
};

export const productPinConverter = pins => {
  return pins?.mappedProducts.map(pin => {
    return {
      position: pins.mappedProducts.indexOf(pin) + 1,
      elementId: pin.value,
      label: pin.label,
    };
  });
};
