import React, { useState } from 'react';
import { useMutation } from 'react-query';
import classes from './UploadContactListModal.module.scss';
import { t } from '../../../../system/ui';
import { isLoaded, closeModal } from '../../../../actions/ui';
import {
  uploadContactList,
  updateContactList,
} from '../services/contactListServices';
import UploadContactList from './uploadContactList';
import {
  SFYSwitch,
  SFYTextWithLabel,
} from '../../../../components/sfy-components/module-exports';
import { hasIysIntegrationEnabled } from '../../../../modules/auth/user';

const UploadContactListModal = ({
  continueHandler,
  instanceId = null,
  listName = '',
}) => {
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [campaignName, setCampaignName] = useState(listName);
  const [iysEnabled, setIysEnabled] = useState(true);
  const hasIysIntegration = hasIysIntegrationEnabled();
  const onUpload = uploadableFile => {
    setUploadFile(uploadableFile);
    setIsUploaded(true);
  };
  const changeCampaignName = (name, value) => {
    setCampaignName(value);
  };

  const { mutate: uploadMutate } = useMutation(uploadContactList, {
    onSuccess: () => {
      continueHandler();
      closeModal();
      isLoaded();
    },
    onError: () => {
      isLoaded();
    },
  });

  const { mutate: updateMutate } = useMutation(updateContactList, {
    onSuccess: () => {
      continueHandler();
      closeModal();
      isLoaded();
    },
    onError: () => {
      isLoaded();
    },
  });

  const saveContactList = type => {
    const config = [
      {
        name: 'iysEnabled',
        value: iysEnabled,
      },
    ];

    if (type === 'save') {
      config.push({
        name: 'fileName',
        value: encodeURI(campaignName),
      });
    }

    if (uploadFile) {
      const mutateObj = {
        file: uploadFile,
        config,
      };

      if (instanceId) {
        mutateObj.id = instanceId;
        updateMutate(mutateObj);
      } else {
        uploadMutate(mutateObj);
      }
    }
  };

  return (
    <div className={classes.content}>
      <div
        className={classes['bundle-product-modal-content']}
        style={{ height: '450px !important' }}
      >
        <SFYTextWithLabel
          cardLabel='List Name*'
          cardName='contactListName'
          placeholder='Example contact list name'
          fieldValue={campaignName}
          editCampaign={changeCampaignName}
          isBigLabel
          disabled={!!instanceId}
        />
        <UploadContactList onChange={onUpload} />
      </div>
      <div className={classes['bottom-actions']}>
        {hasIysIntegration && (
          <div className={classes['iys-switcher']}>
            <SFYSwitch
              span='Send to İYS'
              isToggled={iysEnabled}
              onToggled={() => setIysEnabled(!iysEnabled)}
            />
            {!iysEnabled && (
              <p className={classes['iys-switcher-warning']}>
                If you do not select that option, the uploaded list will NOT
                send to the İYS system.
              </p>
            )}
          </div>
        )}
        <div className='continue-button'>
          <button
            className='tertiary-action'
            type='button'
            onClick={() => saveContactList(instanceId ? 'importNew' : 'save')}
            disabled={!isUploaded || !campaignName}
          >
            {t('Save')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadContactListModal;
