import React from 'react';
import PropTypes from 'prop-types';

function BackArrow(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${props.width}px`}
      height={`${props.height}px`}
      viewBox='0 0 1000 1000'
    >
      <g>
        <path
          d='M380.5,100.5l-350,350c-27.3,27.3-27.3,71.6,0,99l350,350c27.3,27.3,71.6,27.3,99,
          0c27.3-27.3,27.3-71.6,0-99L249,570h671c38.7,0,70-31.3,70-70c0-38.7-31.3-70-70-70H249l230.5-230.5c13.7-13.7
          ,20.5-31.6,20.5-49.5c0-17.9-6.8-35.8-20.5-49.5C452.1,73.2,407.8,73.2,380.5,100.5z'
          fill={props.color}
        />
      </g>
    </svg>
  );
}

BackArrow.defaultProps = {
  color: '#4B4B4F',
  width: 20,
  height: 20,
};

BackArrow.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default BackArrow;
