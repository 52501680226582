import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const FtpSettingsIcon = ({ width, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 500 500'
      fill={color}
    >
      <path d='M398.46,173.63h0a11.33,11.33,0,0,0-16,0l-14.82,14.83V133.8a38.71,38.71,0,0,0-38.67-38.68H281.11a11.34,11.34,0,0,0,0,22.67h47.83a16,16,0,0,1,16,16v54.67l-14.8-14.79a11.33,11.33,0,1,0-16,16l32.6,32.61,0,0,3.37,3.3.19-.2a11,11,0,0,0,2.15,1.06l.35.12.39.11a12.51,12.51,0,0,0,1.5.33l.95,0,.76,0,.64,0,.63,0,.59-.07a11.15,11.15,0,0,0,1.31-.3,11.4,11.4,0,0,0,4.78-2.85l34.17-34.18A11.35,11.35,0,0,0,398.46,173.63Z' />
      <path d='M207.45,382.45H159.61a16,16,0,0,1-16-16V311.78l14.81,14.79a11.33,11.33,0,0,0,16,0,11.37,11.37,0,0,0,0-16l-34.2-34.2-.35-.35a11.25,11.25,0,0,0-3.74-2.26l-.34-.13-.39-.1a11.45,11.45,0,0,0-1.52-.33l-.35-.05-1.34,0-.66,0-.61,0-.58.06a11.89,11.89,0,0,0-1.32.31,11.33,11.33,0,0,0-4.79,2.85L90.1,310.59a11.33,11.33,0,1,0,16,16L121,311.79v54.66a38.71,38.71,0,0,0,38.66,38.67h47.84a11.34,11.34,0,0,0,0-22.67Z' />
      <path d='M184.54,66.18C171.4,61,153,58.07,132.78,57.85H128c-20.22.22-38.59,3.18-51.74,8.33-18.56,7.28-22.46,16.91-22.46,23.7v104c0,19.16,30.8,32,76.63,32s76.62-12.88,76.62-32v-104C207,83.08,203.1,73.46,184.54,66.18ZM171.83,97.32a158.56,158.56,0,0,1-41.47,5.1A158.66,158.66,0,0,1,89,97.33c-12.52-3.46-15.58-6.78-15.71-7.41.13-.68,3.08-3.9,15-7.28a158.45,158.45,0,0,1,39.88-5.29h4.56a158.15,158.15,0,0,1,39.88,5.29c11.9,3.38,14.84,6.6,15,7.21C187.37,90.55,184.31,93.86,171.83,97.32Zm-41.45,24.6c20.76,0,39.71-2.85,53.35-8,1.27-.47,2.53-1,3.77-1.55v29.53c-.12.73-3.25,4.16-16.28,7.66-11.78,3.17-26.28,4.91-40.81,4.91l-4.65-.06c-32.73-.83-51.63-9.48-52.49-12.44l0-29.6c1.24.56,2.5,1.08,3.77,1.55C90.67,119.07,109.61,121.92,130.38,121.92Zm57.13,72c-.13.73-3.26,4.17-16.29,7.67-11.77,3.16-26.28,4.9-40.84,4.9s-29.07-1.74-40.85-4.9c-13-3.49-16.13-6.91-16.28-7.66V164.4c13.85,6.16,33.93,9.53,57.13,9.53s43.27-3.37,57.12-9.53Z' />
      <path d='M306.34,446.75A24.37,24.37,0,0,1,282,422.41V292.59a24.34,24.34,0,0,1,24.34-24.34h65.43a8,8,0,0,1,4.7,1.9l0,0,0,0,0,0,0,0,.07.07h0a3.83,3.83,0,0,1,.29.28l48.68,48.68a8,8,0,0,1,.67.75h0l.05.07,0,0,0,0,0,.05v0A8,8,0,0,1,428,324.4h0v.1h0v97.89a24.37,24.37,0,0,1-24.3,24.36Zm-8.11-154.16V422.41a8.11,8.11,0,0,0,8.11,8.11H403.7a8.12,8.12,0,0,0,8.12-8.11V333.16H371.25a8.11,8.11,0,0,1-8.11-8.11V284.48h-56.8A8.11,8.11,0,0,0,298.23,292.59Zm81.13,24.34h21l-21-21Zm-56.79,89.25a8.12,8.12,0,1,1,0-16.23h64.91a8.12,8.12,0,0,1,0,16.23Zm0-32.45a8.12,8.12,0,1,1,0-16.23h64.91a8.12,8.12,0,0,1,0,16.23Zm0-32.46a8.11,8.11,0,1,1,0-16.22h16.22a8.11,8.11,0,1,1,0,16.22Z' />
    </svg>
  );
};

FtpSettingsIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

FtpSettingsIcon.defaultProps = {
  width: '30px',
  height: '30px',
  color: '#FFFFFF',
};

export default FtpSettingsIcon;
