import React from 'react';
import { connect } from 'react-redux';
import { calculateRatio } from '../system/ratio';
import { t } from '../system/ui';
import { localeString, localeStringMoney } from '../system/string';
import { isSwitchAccount } from '../modules/auth/user';

class KeyMetricsText extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const symbolBefore = isSwitchAccount()
      ? this.props.switchedUser.account.mainCurrency.symbolBefore
      : this.props.user.account.mainCurrency.symbolBefore;
    const symbol = isSwitchAccount()
      ? this.props.switchedUser.account.mainCurrency.symbol
      : this.props.user.account.mainCurrency.symbol;
    const counts = {
      'sgf:purchase:count': 0,
      'sgf:visits': 0,
      'all:purchase:count': 0,
      'all:visits': 0,
      'sgf:purchase:amount': 0,
      'all:purchase:amount': 0,
    };
    this.props.data.forEach(data => {
      if (Object.keys(counts).indexOf(data.x) > -1) {
        counts[data.x] = data.y;
      }
    });
    const conversion = {
      clicked: calculateRatio(
        counts['sgf:purchase:count'],
        counts['sgf:visits'],
      ),
      notClicked: calculateRatio(
        counts['all:purchase:count'] - counts['sgf:purchase:count'],
        counts['all:visits'] - counts['sgf:visits'],
      ),
    };
    const average_visit = {
      clicked: isNaN(counts['sgf:purchase:amount'] / counts['sgf:visits'])
        ? 0
        : counts['sgf:purchase:amount'] / counts['sgf:visits'],
      notClicked: isNaN(
        (counts['all:purchase:amount'] - counts['sgf:purchase:amount']) /
          (counts['all:visits'] - counts['sgf:visits']),
      )
        ? 0
        : (counts['all:purchase:amount'] - counts['sgf:purchase:amount']) /
          (counts['all:visits'] - counts['sgf:visits']),
    };
    const average_order = {
      clicked: isNaN(
        counts['sgf:purchase:amount'] / counts['sgf:purchase:count'],
      )
        ? 0
        : counts['sgf:purchase:amount'] / counts['sgf:purchase:count'],
      notClicked: isNaN(
        (counts['all:purchase:amount'] - counts['sgf:purchase:amount']) /
          (counts['all:purchase:count'] - counts['sgf:purchase:count']),
      )
        ? 0
        : (counts['all:purchase:amount'] - counts['sgf:purchase:amount']) /
          (counts['all:purchase:count'] - counts['sgf:purchase:count']),
    };
    const clickedGroupCount = counts['sgf:visits'];
    const nonClickedGroupCount = counts['all:visits'] - counts['sgf:visits'];
    const clickedGroupRatio = calculateRatio(
      counts['sgf:visits'],
      counts['all:visits'],
    );
    const nonClickedGroupRatio = calculateRatio(
      counts['all:visits'] - counts['sgf:visits'],
      counts['all:visits'],
    );
    return (
      <div>
        <div className='metrics-text-header'>{t('Key Metrics')}</div>
        <div className='metric-text-main'>
          <div className='metric-text-item'>
            <h3>Conversion Rate</h3>
            <span>{localeString(conversion.clicked)} %</span>
            <span>{localeString(conversion.notClicked)} %</span>
          </div>

          <div className='metric-text-item'>
            <h3>Average Visit Value</h3>
            <span>
              {symbolBefore && `${symbol} `}
              {localeStringMoney(average_visit.clicked)}
              {!symbolBefore && `${symbol} `}
            </span>
            <span>
              {symbolBefore && `${symbol} `}
              {localeStringMoney(average_visit.notClicked)}
              {!symbolBefore && `${symbol} `}
            </span>
          </div>

          <div className='metric-text-item'>
            <h3>Average Order Value</h3>
            <span>
              {symbolBefore && `${symbol} `}
              {localeStringMoney(average_order.clicked)}
              {!symbolBefore && `${symbol} `}
            </span>
            <span>
              {symbolBefore && `${symbol} `}
              {localeStringMoney(average_order.notClicked)}
              {!symbolBefore && `${symbol} `}
            </span>
          </div>
        </div>
        <div className='metric-text-footer'>
          <p>
            {t('Clicked Segmentify Recommendations -')} %
            {localeString(clickedGroupRatio)} ({localeString(clickedGroupCount)}{' '}
            users)
          </p>
          <p>
            {t('Did not click Segmentify Recommendations -')} %
            {localeString(nonClickedGroupRatio)} (
            {localeString(nonClickedGroupCount)} users)
          </p>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(KeyMetricsText);
