import React from 'react';
import LoadingGif from '../../../images/loading.gif';
import './Loading.scss';

const Loading = () => {
  return (
    <caption className='loading-gif-container'>
      <img src={LoadingGif} alt='Loading' />
    </caption>
  );
};

export default Loading;
