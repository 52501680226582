import { hideBadResponseCat, showBadResponseCat } from 'actions/ui';
import { getState } from 'system/store';
import { $http, plainGet } from '../../system/ajax';
import api from '../../system/api';
import { getApiKey } from '../auth/user';

const getTrendifyData = (
  trendName,
  trendType,
  data,
  callback,
  errorCallback,
) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint(`trendify/${trendName}/${trendType}`) + globalApiKey)
    .post(data, { type: 'json' })
    .then(response => {
      if (getState().ui.badResponseCatVisible) {
        hideBadResponseCat();
      }
      callback(response);
    })
    .catch(err => {
      if (err.status === 429 || err.status === '429') {
        showBadResponseCat();
      }
    });
};

const getProducts = (data, callback) =>
  getTrendifyData('product', 'list', data, callback);

const getProduct = (data, callback) =>
  getTrendifyData('product', 'single', data, callback);

const getProductCount = (data, callback) =>
  getTrendifyData('product', 'count', data, callback);

const getProductTotal = (data, callback) =>
  getTrendifyData('product', 'total', data, callback);

const getAllCategories = callback =>
  plainGet(
    `${api.getEndPoint('trendify/category/list')}?apiKey=${getApiKey()}`,
    callback,
  );

const getCategories = (data, callback) =>
  getTrendifyData('category', 'list', data, callback);

const getCategory = (data, callback) =>
  getTrendifyData('category', 'single', data, callback);

const getCategoryCount = (data, callback) =>
  getTrendifyData('category', 'count', data, callback);

const getCategoryTotal = (data, callback) =>
  getTrendifyData('category', 'total', data, callback);

const getBrands = (data, callback) =>
  getTrendifyData('brand', 'list', data, callback);

const getBrand = (data, callback) =>
  getTrendifyData('brand', 'single', data, callback);

const getBrandCount = (data, callback) =>
  getTrendifyData('brand', 'count', data, callback);

const getBrandTotal = (data, callback) =>
  getTrendifyData('brand', 'total', data, callback);

const getAnalyticsUserFilters = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint('trendify/filters/get') + globalApiKey)
    .post({}, { type: 'json' })
    .then(response => {
      if (getState().ui.badResponseCatVisible) {
        hideBadResponseCat();
      }
      callback(response);
    })
    .catch(err => {
      if (err.status === 429 || err.status === '429') {
        showBadResponseCat();
      }
    });
};

const addAnalyticsUserFilter = (data, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint('trendify/filters/add') + globalApiKey)
    .post(data, { type: 'json' })
    .then(response => {
      if (getState().ui.badResponseCatVisible) {
        hideBadResponseCat();
      }
      callback(response);
    })
    .catch(err => {
      if (err.status === 429 || err.status === '429') {
        showBadResponseCat();
      }
    });
};

const updateAnalyticsUserFilter = (data, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint('trendify/filters/update') + globalApiKey)
    .post(data, { type: 'json' })
    .then(response => {
      if (getState().ui.badResponseCatVisible) {
        hideBadResponseCat();
      }
      callback(response);
    })
    .catch(err => {
      if (err.status === 429 || err.status === '429') {
        showBadResponseCat();
      }
    });
};

const removeAnalyticsUserFilters = (data, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(api.getEndPoint('trendify/filters/remove') + globalApiKey)
    .post(data, { type: 'json' })
    .then(response => {
      if (getState().ui.badResponseCatVisible) {
        hideBadResponseCat();
      }
      callback(response);
    })
    .catch(err => {
      if (err.status === 429 || err.status === '429') {
        showBadResponseCat();
      }
    });
};

export {
  getProducts,
  getProduct,
  getProductCount,
  getProductTotal,
  getAllCategories,
  getCategory,
  getCategoryCount,
  getCategories,
  getCategoryTotal,
  getBrands,
  getBrand,
  getBrandCount,
  getBrandTotal,
  getAnalyticsUserFilters,
  addAnalyticsUserFilter,
  updateAnalyticsUserFilter,
  removeAnalyticsUserFilters,
};
