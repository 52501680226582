import React, { Component } from 'react';
import PushCard from '../components/widget/push-card';

class AllPushes extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className='page-content-wrapper'>
          {Object.keys(this.props.permissionReport).length !== 0 &&
            this.props.permissionReport !== undefined &&
            this.props.permissionCampaign.length !== 0 &&
            this.props.isPure && (
              <PushCard
                {...this.props.permissionCampaign}
                name={
                  this.props.permissionCampaign !== undefined &&
                  this.props.permissionCampaign.length !== 0
                    ? this.props.permissionCampaign[0].name
                    : ''
                }
                type={'PUSH_PERMISSION'}
                campaign={this.props.permissionCampaign}
                onChangeStatus={this.props.onChangeStatus}
                getCampaignsAfterStatusChange={
                  this.props.getCampaignsAfterStatusChange
                }
                report={
                  this.props.permissionReport ? this.props.permissionReport : []
                }
              />
            )}
          {this.props.pushCampaigns.map(campaign => {
            if (campaign && campaign.type !== 'PUSH_PERMISSION') {
              return (
                <PushCard
                  {...campaign}
                  campaign={campaign}
                  onChangeStatus={this.props.onChangeStatus}
                  key={campaign.instanceId}
                  getCampaignsAfterStatusChange={
                    this.props.getCampaignsAfterStatusChange
                  }
                  report={
                    this.props.reports
                      ? this.props.reports[campaign.instanceId]
                      : []
                  }
                />
              );
            }
          })}
          {Object.keys(this.props.permissionReport).length !== 0 &&
            this.props.permissionReport !== undefined &&
            this.props.permissionCampaign.length !== 0 &&
            !this.props.isPure && (
              <PushCard
                {...this.props.permissionCampaign}
                name={
                  this.props.permissionCampaign !== undefined &&
                  this.props.permissionCampaign.length !== 0
                    ? this.props.permissionCampaign[0].name
                    : ''
                }
                type={'PUSH_PERMISSION'}
                campaign={this.props.permissionCampaign}
                onChangeStatus={this.props.onChangeStatus}
                report={
                  this.props.permissionReport ? this.props.permissionReport : []
                }
              />
            )}
        </div>
      </div>
    );
  }
}

export default AllPushes;
