/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { connect } from 'react-redux';

import * as modals from '../../constants/datamaps/modals';
import { trendsMap, statTypes } from '../../constants/datamaps';
import { findLongestStat } from '../reports/utils';

import { t, openModal } from '../../system/ui';
import {
  localeString,
  localeStringMoney,
  toLocaleStringDigits,
} from '../../system/string';

import { filterActions, uiActions } from '../../actions';
import { transformCampaignReport } from '../../modules/transform';
import { getCampaignReport, getRangedReport } from '../../modules/reports/ajax';

import Currency from '../currency';
import Icon from '../icon';
import Icons from '../icons';
import Tooltip from '../tooltip';
import { getCampaignPage } from '../../modules/campaigns/data';

class WidgetReportStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: trendsMap,
      unmodifiedStats: trendsMap,
      segmentStats: [],
      query: document.location.search,
      loadingAnimation: true,
    };

    this.getReport = this.getReport.bind(this);
    this.getRangedReport = this.getRangedReport.bind(this);
  }

  componentDidMount() {
    if (this.props.isEmailCampaign) {
      this.getRangedReport(
        this.props.range,
        this.props.instanceId,
        this.props.selectedDevice,
      );
    } else {
      this.getRangedReport(
        this.props.range,
        this.props.instanceId,
        this.props.selectedDevice,
        () => {
          this.setReports();
        },
      );
      filterActions.resetAllFilters();
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (this.props.instanceId !== newProps.instanceId) {
      this.getRangedReport(
        newProps.range,
        newProps.instanceId,
        newProps.selectedDevice,
      );
    }
    if (newProps.update === true) {
      this.setState(
        {
          query: '',
        },
        () => {
          this.getRangedReport(
            newProps.range,
            newProps.instanceId,
            newProps.selectedDevice,
          );
        },
      );
    }

    if (newProps.selectedDevice !== this.props.selectedDevice) {
      this.setReports(newProps.selectedDevice);
    }
  }

  getRangedReport(range, instanceId, selectedDevice) {
    if (range[0] && range[1]) {
      uiActions.isLoading();
      const boundaries = {};
      if (this.state.query === '?archive') {
        boundaries.start = this.formatDate(this.props.startDate);
        boundaries.end = range[1].format('YYYY-MM-DD');
      } else {
        boundaries.start = range[0].format('YYYY-MM-DD');
        boundaries.end = range[1].format('YYYY-MM-DD');
      }
      const { campaignType } = this.props;
      getRangedReport(
        instanceId,
        boundaries,
        campaignReport => {
          this.setState({
            stats: transformCampaignReport(
              campaignReport,
              selectedDevice,
              campaignType,
            ),
            segmentStats: campaignReport,
            unmodifiedStats: campaignReport,
          });
          filterActions.filtersUpdated();
          filterActions.filtersReset();
          uiActions.isLoaded();
          this.setState({ loadingAnimation: false });
          this.props.dataCheck(false);
        },
        true,
      );
    }
  }

  setReports(selectedDevice) {
    const { campaignType } = this.props;
    this.setState(prevState => ({
      stats: transformCampaignReport(
        prevState.unmodifiedStats,
        selectedDevice === 'PC' ? 'PC' : selectedDevice.toLowerCase(),
        campaignType,
      ),
    }));
  }

  getReport(rangeAlias) {
    const { campaignType } = this.props;
    getCampaignReport(
      this.props.instanceId,
      rangeAlias,
      campaignReport => {
        this.setState({
          stats: transformCampaignReport(
            campaignReport,
            this.props.selectedDevice,
            campaignType,
          ),
          segmentStats: campaignReport,
        });
      },
      true,
    );
  }

  formatDate = date => {
    const d = new Date(date);
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d
      .getDate()
      .toString()
      .padStart(2, '0');
    const year = d.getFullYear();

    return `${year}-${month}-${day}`;
  };

  showModal = (modal, e) => {
    e.preventDefault();

    const modalContent = modals[modal];

    openModal({
      title: t(modal),
      content: modalContent,
    });
  };

  render() {
    const checkListForLongestStat = [
      'all',
      'contribution',
      'view',
      'leftArrowClick',
      'rightArrowClick',
      'conversion',
      'average',
      'purchases',
      'items',
    ];

    const defaultPageTypes = [
      'Home Page',
      'Category Page',
      'Search Page',
      '404 Page',
      'Product Page',
      'Basket Page',
      'Checkout Success Page',
    ];
    const campaignPage = getCampaignPage(this.props);
    if (defaultPageTypes.includes(campaignPage)) {
      checkListForLongestStat.push('pageView', 'impressionRate');
    }
    const longestStat = findLongestStat(
      this.state.stats,
      checkListForLongestStat,
    );
    const instance = {};
    instance.inputs = this.props.inputs;
    if (this.props.isSegmentCampaign) {
      let segmentedVisitors = 0;
      this.state.segmentStats.forEach(stat => {
        if (stat.x === 'Segment Visitor') {
          segmentedVisitors = stat.y;
        }
      });
      return (
        <div className='widget-stats-segment'>
          <h4 className='widget-stat-title'>{t('Segmented Users')}</h4>
          <span className='widget-stat-data'>
            {localeString(segmentedVisitors)}
          </span>
          <div className='widget-stats-segment--icon'>
            <i className='icon-add-user' />
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className='widget-stat-list widget-report'>
          {this.state.loadingAnimation ? (
            <div>Loading...</div>
          ) : (
            <div className='widget-stats'>
              {Object.keys(this.state.stats).map((item, index) => {
                const stat = this.state.stats[item].stats;
                if (
                  (item === 'widget', !defaultPageTypes.includes(campaignPage))
                ) {
                  // Remove pageView and impressionRate from widget stats if not on default page types
                  delete stat.pageView;
                  delete stat.impressionRate;
                }

                const type = statTypes[item];
                let typeName = type.name;
                let typeUnit = type.unit;
                const className = `widget-stat stat-${item.toLowerCase()}`;
                const originalStatVal =
                  item !== 'widget' ? stat.all.count : stat.view.count;

                const statName = item;
                if (this.props.isEmailCampaign && item === 'widget') {
                  typeName = 'Email';
                  typeUnit = 'Views';
                }

                if (
                  this.props.isKeywordCampaign &&
                  (item === 'basket' || item === 'revenue')
                ) {
                  return null;
                }

                return (
                  <div className={className} key={`${item}-${index}`}>
                    {this.state.stats[item].modal &&
                    typeof modals[this.state.stats[item].modal] !==
                      'undefined' ? (
                      <a
                        className='modal-trigger'
                        onClick={e => {
                          this.showModal(this.state.stats[item].modal, e);
                        }}
                      >
                        <Icon name='info' />
                      </a>
                    ) : (
                      ''
                    )}

                    <h4 className='widget-stat-title'>{t(typeName)}</h4>
                    <p
                      className='widget-stat-count'
                      title={toLocaleStringDigits(originalStatVal)}
                    >
                      {type.currency === true
                        ? localeStringMoney(originalStatVal)
                        : localeString(originalStatVal)}
                    </p>
                    <p className='widget-stat-type'>
                      {type.currency === true ? (
                        <Currency />
                      ) : typeName === 'Email' ? (
                        <Icons name='email' color='#4bb3d2' />
                      ) : (
                        <Icon name={type.icon} />
                      )}
                      {type.currency !== true ? t(typeUnit) : ''}
                    </p>
                    <p key={item} className='widget-stat-name'>
                      {typeName === 'Widget' && t('Impression')}
                      {typeName === 'Widget' && (
                        <span className='widget-stat-conversion-amount'>
                          {localeString(stat.all.count)}
                        </span>
                      )}
                      {typeName === 'Email' && t('Sent')}
                      {typeName === 'Email' && (
                        <span className='widget-stat-conversion-amount'>
                          {localeString(stat.all.count)}
                        </span>
                      )}
                    </p>
                    {Object.keys(stat).map((mapItem, mapIndex) => {
                      if (
                        mapItem === 'bounceBack' ||
                        mapItem === 'unsubscribed'
                      )
                        return null;
                      const singleStat = stat[mapItem];
                      const isLastItem =
                        mapIndex + 1 === Object.keys(stat).length;

                      if (singleStat.visible === false) {
                        return null;
                      }

                      let singleStatData;
                      if (
                        mapItem === 'all' ||
                        singleStat.name === 'Actual View'
                      ) {
                        return null;
                      }
                      if (singleStat.currency) {
                        singleStatData = (
                          <Currency currency={singleStat.count} />
                        );
                      } else {
                        singleStatData =
                          localeString(singleStat.count) ||
                          `${localeString(singleStat.ratio)}%`;
                      }

                      if (singleStat.name === 'Spam') {
                        return null;
                      }

                      return (
                        <p
                          key={mapItem}
                          className={`widget-stat-name ${
                            statName !== longestStat && isLastItem
                              ? 'widget-stat-conversion-bottom'
                              : ''
                          }`}
                        >
                          {singleStat.icon && <i className={singleStat.icon} />}
                          {(typeName === 'Widget' ||
                            typeName === 'Interaction' ||
                            typeName === 'Basket' ||
                            typeName === 'Email') &&
                          mapItem !== 'pageView' &&
                          mapItem !== 'impressionRate'
                            ? t(typeName + singleStat.name)
                            : t(singleStat.name)}
                          {singleStat.tooltip && (
                            <span className='stat-tooltip'>
                              <Tooltip
                                content={singleStat.tooltip}
                                alignment='top'
                                newTooltip
                              >
                                <Icon name='info' />
                              </Tooltip>
                            </span>
                          )}
                          <span className='widget-stat-data'>
                            {singleStatData}
                          </span>
                        </p>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  update: store.filters.update,
  reset: store.filters.reset,
});

export default connect(mapStatesToProps)(WidgetReportStats);
