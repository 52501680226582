/**
 * Created by mehmetyurtar on 14/05/2017.
 */
"use strict";

import React from "react";
import { connect } from "react-redux";

import * as modals from "../../constants/datamaps/modals";
import {
  trendsMap,
  statTypes,
  personalizationTrendsMap
} from "../../constants/datamaps";

import { t, openModal } from "../../system/ui";
import { queryDate } from "../../system/date";
import { localeString } from "../../system/string";

import { transformCampaignReport } from "../../modules/transform";
import { transFormPersReport } from "../../modules/transform";
import {
  getCampaignReport,
  getRangedReport,
  getPersonalizationReport
} from "../../modules/reports/ajax";

import Currency from "../currency";
import Icon from "../icon";
import Icons from "../icons";

class EmailCollectionReport extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return this.props.compareMode ? (
      <div className="widget-stat-list widget-report">
        <div>
          {Object.keys(this.props.stats).map((item, index) => {
            let stat = this.props.stats[item].stats,
              type = statTypes[item],
              className = "compare-stat stat-" + item.toLowerCase();
            let mainCount = "";
            let otherCount = "";
            if (
              type.name === "Segment Visitor" ||
              type.name === "Unsegment Visitor" ||
              type.name === "Toggle Visitor Segment" ||
              type.name === "Interaction" ||
              type.name === "Basket" ||
              type.name === "Revenue" ||
              type.name === "Granted" ||
              type.name === "Denied"
            ) {
              return;
            } else {
              if (type.name === "Widget") {
                mainCount = stat.all.count;
                otherCount = this.props.comparisonStats[item].stats.all.count;
              } else {
                if (type.name === "Form Submit") {
                  mainCount = stat.count;
                  otherCount = this.props.comparisonStats[item].stats.count;
                }
              }
            }
            let isSecondColumn = this.props.withCompareIcons,
              statDifference = isSecondColumn ? mainCount - otherCount : 0;
            return (
              <div className={className} key={item + "-" + index}>
                <div className="compare-stat-container one-half">
                  <h4 className="widget-stat-title">{t(type.name)}</h4>
                </div>
                <div
                  className="compare-stat-container one-half"
                  style={{ textAlign: "right", position: "relative" }}
                >
                  <p className="widget-stat-count">
                    {localeString(mainCount)}
                    <span className="compare-unit">
                      {type.currency === true ? (
                        <Currency />
                      ) : (
                        <Icon name={type.icon} />
                      )}
                      {type.currency !== true ? t(type.unit) : ""}
                    </span>
                  </p>
                  <span
                    style={{
                      position: "absolute",
                      right: "0",
                      bottom: "-20px"
                    }}
                  >
                    {isSecondColumn && statDifference > 0 && (
                      <span style={{ color: "#56bc94" }}>
                        +{localeString(statDifference)}{" "}
                        <Icons name="increase" color="#56bc94" />
                      </span>
                    )}
                    {isSecondColumn && statDifference < 0 && (
                      <span style={{ color: "#dc3322" }}>
                        {" "}
                        {localeString(statDifference)}{" "}
                        <Icons name="decrease" color="#dc3322" />
                      </span>
                    )}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    ) : this.props.onViewAll ? (
      <div style={{ display: "inherit", width: "100%" }}>
        {Object.keys(this.props.stats).map((item, index) => {
          let stat = this.props.stats[item].stats,
            type = statTypes[item],
            className = "widget-stat stat-" + item.toLowerCase();
          let mainCount = "";
          if (
            type.name === "Segment Visitor" ||
            type.name === "Unsegment Visitor" ||
            type.name === "Toggle Visitor Segment" ||
            type.name === "Interaction" ||
            type.name === "Basket" ||
            type.name === "Revenue" ||
            type.name === "Granted" ||
            type.name === "Denied"
          ) {
            return;
          } else {
            if (type.name === "Widget") {
              mainCount = stat.all.count;
            } else {
              if (type.name === "Form Submit") {
                mainCount = stat.count;
              }
            }
          }
          return (
            <div className={className} key={item + "-" + index}>
              <h4 className="widget-stat-title">{t(type.name)}</h4>
              <p className="widget-stat-count">{localeString(mainCount)}</p>
              <p className="widget-stat-type">
                {type.currency === true ? (
                  <Currency />
                ) : (
                  <Icon name={type.icon} />
                )}
                {type.currency !== true ? t(type.unit) : ""}
              </p>
            </div>
          );
        })}
      </div>
    ) : (
      <div className="widget-stat-list widget-report">
        <div className="widget-stats">
          {Object.keys(this.props.stats).map((item, index) => {
            let stat = this.props.stats[item].stats,
              type = statTypes[item],
              className = "widget-stat stat-" + item.toLowerCase();
            let mainCount = "";
            if (
              type.name === "Segment Visitor" ||
              type.name === "Unsegment Visitor" ||
              type.name === "Toggle Visitor Segment" ||
              type.name === "Interaction" ||
              type.name === "Basket" ||
              type.name === "Revenue" ||
              type.name === "Granted" ||
              type.name === "Denied"
            ) {
              return;
            } else {
              if (type.name === "Widget") {
                mainCount = stat.all.count;
              } else {
                if (type.name === "Form Submit") {
                  mainCount = stat.count;
                }
              }
            }
            return (
              <div className={className} key={item + "-" + index}>
                <h4 className="widget-stat-title">{t(type.name)}</h4>
                <p className="widget-stat-count">{localeString(mainCount)}</p>
                <p className="widget-stat-type">
                  {type.currency === true ? (
                    <Currency />
                  ) : (
                    <Icon name={type.icon} />
                  )}
                  {type.currency !== true ? t(type.unit) : ""}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias
});

export default connect(mapStatesToProps)(EmailCollectionReport);
