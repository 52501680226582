import React from 'react';
import { Checkbox } from '../../../fields';
import './SFYComponents.scss';

const Devices = ({ devices, deviceSetter }) => {
  const onChange = type => {
    const newDevices = [...devices];
    const index = newDevices.indexOf(type);
    if (index === -1) {
      if (type === 'PC') {
        newDevices.push('TABLET');
      }
      newDevices.push(type);
    } else {
      newDevices.splice(index, 1);
    }
    deviceSetter('devices', newDevices);
  };

  return (
    <div
      className={`form-element form-style ${
        devices.length === 0 ? 'has-error' : ''
      }`}
    >
      <span className='element-title'>Device Type</span>
      <span className='element-row-label'>Activate On</span>
      <div className='element-row-field device-type'>
        <Checkbox
          name='desktop'
          label='Desktop'
          checked={devices?.includes('PC')}
          onChange={() => onChange('PC')}
        />
        <Checkbox
          name='mobile'
          label='Mobile'
          checked={devices?.includes('MOBILE')}
          onChange={() => onChange('MOBILE')}
        />
      </div>
      {devices.length === 0 && (
        <span className='item-error'>
          <span>This field is required</span>
        </span>
      )}
    </div>
  );
};

export default Devices;
