import React, { useEffect, useState } from 'react';
import { Calendar } from 'components/fields';
import {
  convertToEpochTime,
  isValidStartDate,
  reformatDateByTimeZone,
} from 'system/date';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveCampaign,
  updateCampaign,
  updateCampaignForm,
} from 'actions/faceted-search';
import { t } from 'system/ui';
import { getAccountId } from 'modules/auth/user';
import { uiActions } from 'actions';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import {
  FACETED_STATUS,
  FACETED_STATUS_TEXT,
} from 'components/search/faceted-search/common/constants';

export function copyToClipboard(value) {
  const tempInput = document.createElement('input');
  tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
}

const RightSideWizard = ({ mode }) => {
  const dispatch = useDispatch();
  const state = useSelector(s => s.facetedSearch);
  const [isEndDateVisible, setEndDateVisible] = useState(false);
  const [draftOrLive, setDraftOrLive] = useState('');
  const draftUrl = `&_sgm_search_mode=DRAFT|${state.campaignForm.instanceId}`;
  const limitationState = state.campaignForm.limitations;
  const onDateChange = (date, type) => {
    const property = type === 'start' ? ['startDate'] : ['endDate'];
    dispatch(
      updateCampaignForm({
        limitations: {
          ...limitationState,
          [property]: convertToEpochTime(date),
        },
      }),
    );
  };

  const onSaveCampaign = statusType => {
    setDraftOrLive(statusType);
    const isThereAnotherLiveCampaign = state.campaigns
      .filter(c => c.service === state.campaignForm.service)
      .some(fc => fc.status === statusType);

    const dialogs = {
      default: `Are you sure to make this campaign ${FACETED_STATUS_TEXT[statusType]} ?`,
      newOrDraftToLive: `There is another Live Searchandising Campaign under service ${state.campaignForm.service}.
      You can save this one as a draft campaign, then switch to Live.`,
      passiveToLive: `There is another Live Searchandising Campaign under service ${state.campaignForm.service}.
      Do you want to switch this Passive Campaign to Live ?`,
      liveToLive:
        'Updating a Live Searchandising Campaign is risky. Recommended approach is creating a draft version, check it, ' +
        'and switch Draft with Live version. Do you continue with risks of updating a Live Campaign?',
      liveToDraft: `You are changing status of a Live Searchandising Campaign and there will be no Live Campaign under service ${state.campaignForm.service},
      and visitors will not be getting search service afterwards. Do you continue with risks of changing status of a Live Campaign?`,
    };

    let content = '';
    if (mode === 'new') {
      // new -> go live
      if (statusType === FACETED_STATUS.LIVE) {
        content = isThereAnotherLiveCampaign
          ? dialogs.newOrDraftToLive
          : dialogs.default;
      } else {
        content = dialogs.default;
      }
    } else if (mode === 'edit') {
      // live -> live
      if (
        state.campaignForm.status === FACETED_STATUS.LIVE &&
        statusType === FACETED_STATUS.LIVE
      ) {
        content = dialogs.liveToLive;
      }
      // live -> draft
      else if (
        state.campaignForm.status === FACETED_STATUS.LIVE &&
        statusType === FACETED_STATUS.DRAFT
      ) {
        content = dialogs.liveToDraft;
      } else if (
        state.campaignForm.status === FACETED_STATUS.DRAFT &&
        statusType === FACETED_STATUS.LIVE
      ) {
        content = isThereAnotherLiveCampaign
          ? dialogs.newOrDraftToLive
          : dialogs.default;
      }
      // passive -> live
      else if (
        state.campaignForm.status === FACETED_STATUS.PASSIVE &&
        statusType === FACETED_STATUS.LIVE
      ) {
        content = isThereAnotherLiveCampaign
          ? dialogs.passiveToLive
          : dialogs.default;
      } else {
        content = dialogs.default;
      }
    }

    const campaignObject = {
      ...state.campaignForm,
      status: statusType,
      accountId: getAccountId(),
    };

    const normalizeBanners = banner => {
      return {
        ...banner,
        instanceId: state.campaignForm.instanceId,
        searchType: 'faceted',
      };
    };

    uiActions.confirmationDialog({
      title: 'Attention!',
      content,
      onConfirm: () => {
        if (mode === 'new' && !state.ui.saveCampaign.loaded) {
          dispatch(
            saveCampaign({
              campaignObject,
              banners: state.bannersCopy.map(normalizeBanners),
            }),
          );
        } else if (mode === 'edit') {
          dispatch(
            updateCampaign(
              {
                campaignObject,
                banners: state.bannersCopy.map(normalizeBanners),
              },
              state.campaignForm.instanceId,
            ),
          );
        }
      },
    });
  };
  useEffect(() => {
    if (state.ui.saveCampaign.loaded) {
      let message = 'FACETED_SEARCH_CAMPAIGN_UPDATE_SUCCESS';
      if (mode === 'new') {
        message = 'FACETED_SEARCH_CAMPAIGN_SAVE_SUCCESS';
        browserHistory.push(
          `/search/edit/faceted/${state.campaignForm.instanceId}`,
        );
      }
      const notificationContent = () => <span>{t(message)}</span>;
      uiActions.showNotification({ content: notificationContent });
      // make sure draftOrLive string is not empty
      if (draftOrLive) dispatch(updateCampaignForm({ status: draftOrLive }));
    }
  }, [state.ui.saveCampaign.loaded]);
  useEffect(() => {
    if (
      limitationState.endDate !== null &&
      typeof limitationState.endDate === 'number' &&
      !Number.isNaN(limitationState.endDate)
    ) {
      setEndDateVisible(true);
    } else {
      setEndDateVisible(false);
    }
  }, [state.ui.loaded]);

  const copyDraftUrl = e => {
    e.preventDefault();
    copyToClipboard(draftUrl);
    uiActions.showNotification({
      content: () => <span>Draft url copied!</span>,
    });
  };

  return (
    <div className='wizard-options email-wizard-option faceted-search-right-side-wizard'>
      <div className='campaign-name-label'>
        <span>Campaign Name</span>
      </div>
      <div className='campaign-name-text'>
        <input
          type='text'
          value={state.campaignForm.name}
          onChange={e => {
            dispatch(
              updateCampaignForm({
                name: e.target.value,
              }),
            );
          }}
        />
      </div>
      <div className='start-date'>
        <Calendar
          label='Start Date - Time'
          className='item-stacked one-whole'
          isValidDate={isValidStartDate}
          value={reformatDateByTimeZone(limitationState.startDate)}
          onChange={e => onDateChange(e, 'start')}
        />
      </div>
      <div className='end-date'>
        <span className='check-group-toggle' />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className='item item-stacked is-checkbox'>
          <input
            type='checkbox'
            checked={isEndDateVisible}
            onChange={e => {
              const { checked } = e.target;
              setEndDateVisible(checked);
              const endDateValue = checked ? new Date().getTime() : null;
              onDateChange(endDateValue, 'end');
            }}
          />
          <span className='item-label'>{t('End Date - Time')}</span>
        </label>
        {isEndDateVisible && (
          <Calendar
            className='item-stacked one-whole'
            isValidDate={isValidStartDate}
            value={reformatDateByTimeZone(limitationState.endDate)}
            onChange={e => onDateChange(e, 'end')}
          />
        )}
      </div>
      <div className='service'>
        <span className='item-label'>Service</span>
        <input
          type='text'
          value={state.campaignForm.service}
          onChange={e => {
            dispatch(
              updateCampaignForm({
                service: e.target.value,
              }),
            );
          }}
        />
      </div>
      <div className='action-buttons'>
        <a
          href='#'
          onClick={() => onSaveCampaign('DRAFT')}
          className='button gotest-action one-whole'
        >
          {t('Go Draft')}
        </a>

        <a
          href='#'
          onClick={() => onSaveCampaign('ACTIVE')}
          className='button tertiary-action one-whole golive-action'
        >
          {t('Go Live')}
        </a>

        {mode === 'edit' && state?.campaignForm?.status === 'DRAFT' && (
          <a
            onClick={copyDraftUrl}
            className='button tertiary-action one-whole copy-draft-url-action'
          >
            {t('Copy Draft Url')}
          </a>
        )}
      </div>
    </div>
  );
};

RightSideWizard.propTypes = {
  mode: PropTypes.oneOf(['new', 'edit']).isRequired,
};

export default RightSideWizard;
