import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const cpNotePadIcon = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 5.5C3 5.22386 3.22386 5 3.5 5H8.5C8.7761 5 9 5.22386 9 5.5C9 5.77614 8.7761 6 8.5 6H3.5C3.22386 6 3 5.77614 3 5.5ZM3.5 8C3.22386 8 3 8.2239 3 8.5C3 8.7761 3.22386 9 3.5 9H8.5C8.7761 9 9 8.7761 9 8.5C9 8.2239 8.7761 8 8.5 8H3.5ZM3 11.5C3 11.2239 3.22386 11 3.5 11H5.5C5.77614 11 6 11.2239 6 11.5C6 11.7761 5.77614 12 5.5 12H3.5C3.22386 12 3 11.7761 3 11.5ZM2 0.5C2 0.22386 2.22386 0 2.5 0C2.77614 0 3 0.22386 3 0.5V1H5.5V0.5C5.5 0.22386 5.72386 0 6 0C6.2761 0 6.5 0.22386 6.5 0.5V1H9V0.5C9 0.22386 9.2239 0 9.5 0C9.7761 0 10 0.22386 10 0.5V1H10.5C11.3284 1 12 1.67157 12 2.5V7.23163C11.6738 7.37139 11.3683 7.5744 11.102 7.84065L11 7.94266V2.5C11 2.22386 10.7761 2 10.5 2H1.5C1.22386 2 1 2.22386 1 2.5V14.5C1 14.7761 1.22386 15 1.5 15H5.22047L5.05716 15.6532C5.02795 15.7701 5.01015 15.8859 5.00286 16H1.5C0.67157 16 0 15.3284 0 14.5V2.5C0 1.67157 0.67157 1 1.5 1H2V0.5ZM11.8092 8.5478L6.9798 13.3772C6.6982 13.6588 6.4984 14.0116 6.4018 14.3979L6.0274 15.8958C5.86451 16.5472 6.4546 17.1372 7.106 16.9744L8.6039 16.5999C8.9902 16.5033 9.343 16.3036 9.6246 16.022L14.454 11.1926C15.1843 10.4622 15.1843 9.2781 14.454 8.5478C13.7237 7.81741 12.5395 7.81741 11.8092 8.5478Z'
        fill={color}
      />
    </svg>
  );
};

cpNotePadIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

cpNotePadIcon.defaultProps = {
  width: '16px',
  height: '18px',
  color: '#212121',
};

export default cpNotePadIcon;
