import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import { uiActions, filterActions, dateActions } from '../actions';
import { devices } from '../constants/datamaps/campaigns';
import EmailStats from '../components/widget/email-stats';
import Filters from '../components/filters/filters';
import Icon from '../components/icon';
import Icons from '../components/icons';
import { transformReport } from '../components/push/utils';
import ReportActions from '../components/reports/actions';
import Tooltip from '../components/tooltip';
import {
  getRangedPushReport,
  getCampaign,
  getPermissionReport,
} from '../modules/push/ajax';
import { isBeforeNow, queryDate } from '../system/date';
import { setTitle } from '../system/document';
import { t } from '../system/ui';

class SinglePushReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: {},
      campaignReport: {},
      permissionReport: transformReport({}),
      query: document.location.search,
      permissionElement: "",
      selectedDevice: "ALL"
    };

    this.getPushReport = this.getPushReport.bind(this);
    this.getPushWithReport = this.getPushWithReport.bind(this);
    this.setPermissionReport = this.setPermissionReport.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.getAllPermissions = this.getAllPermissions.bind(this);
  }

  componentDidMount() {
    let reportID = this.props.params.reportID;
    setTitle(t("Campaign Report"));
    this.getPushWithReport(reportID, this.props.range, true);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      this.getPushWithReport(newProps.params.reportID, newProps.range, true);
    }

    if (newProps.update === true) {
      this.getPushWithReport(newProps.params.reportID, newProps.range);
    }

    if (newProps.reset === true) {
      this.getPushWithReport(newProps.params.reportID, newProps.range);
    }
  }
  getAllPermissions(permissionElement) {
    this.setState({
      permissionElement
    });
  }
  getPushReport(reportId, range) {
    let boundaries = {};
    boundaries.start = queryDate(range[0]);
    boundaries.end = queryDate(range[1]);
    getRangedPushReport(reportId, boundaries, response => {
      uiActions.isLoaded();
      this.setState({
        campaignReport: response
      });
    });
  }
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    return [year, month, day].join("-");
  }
  getCampaignDataFromStart(reportId, startDate) {
    let boundaries = [];
    let unSubBoundaries = [];
    let today = new Date();
    boundaries.start = this.formatDate(startDate);
    boundaries.end = this.formatDate(today);
    unSubBoundaries.startDate = this.formatDate(startDate);
    unSubBoundaries.endDate = this.formatDate(today);
    getRangedPushReport(reportId, boundaries, campaignReport => {
      uiActions.isLoaded();
      this.setState({
        campaignReport: campaignReport
      });
    });
  }

  setPermissionReport(type, range) {
    if (type === "PUSH_PERMISSION") {
      let boundaries = {};
      boundaries.start = range[0].format("YYYY-MM-DD");
      boundaries.end = range[1].format("YYYY-MM-DD");
      getPermissionReport(response => {
        uiActions.isLoaded();
        this.setState({
          permissionReport: response
            ? transformReport(response)
            : transformReport({})
        });
      }, boundaries);
    }
  }

  getPushWithReport(reportId, range, campaignDate) {
    let reportIdArchive = reportId;
    uiActions.isLoading();
    filterActions.filtersUpdated();
    filterActions.filtersReset();
    if (this.state.campaign.name) {
      if (this.state.campaign.type === "PUSH_PERMISSION") {
        this.setPermissionReport("PUSH_PERMISSION", range);
      } else {
        if (
          campaignDate &&
          this.state.campaign.schedule &&
          this.state.campaign.schedule.type === "one_time" &&
          isBeforeNow(moment(this.state.campaign.schedule.date))
        ) {
          dateActions.updateRange([
            moment(this.state.campaign.schedule.date),
            moment()
          ]);
          setTimeout(() => {
            this.getPushReport(reportId, [
              moment(this.state.campaign.schedule.date),
              moment()
            ]);
          }, 200);
        } else {
          this.getPushReport(reportId, range);
        }
      }
    } else {
      getCampaign(reportId).then(campaign => {
        if (campaign) {
          this.setState(
            {
              campaign
            },
            () => {
              if (campaign.type === "PUSH_PERMISSION") {
                this.setPermissionReport("PUSH_PERMISSION", range);
              } else {
                if (
                  campaignDate &&
                  campaign.schedule &&
                  campaign.schedule.type === "one_time" &&
                  isBeforeNow(moment(campaign.schedule.date))
                ) {
                  if (
                    this.state.campaign.startDate &&
                    (this.state.query === '?archive' ||
                      (this.state.campaign.type === 'PUSH_NOTIFICATION' &&
                        this.state.campaign.pushType === 'BULK'))
                  ) {
                    dateActions.updateRangeAlias('custom');
                    dateActions.updateRange([
                      moment(campaign.startDate),
                      moment(),
                    ]);
                    this.getCampaignDataFromStart(
                      reportIdArchive,
                      this.state.campaign.startDate
                    );
                  } else {
                    dateActions.updateRange([
                      moment(campaign.schedule.date),
                      moment(),
                    ]);
                    setTimeout(() => {
                      this.getPushReport(reportId, [
                        moment(campaign.schedule.date),
                        moment()
                      ]);
                    }, 200);
                  }
                } else {
                  if (
                    this.state.campaign.startDate &&
                    (this.state.query === '?archive' ||
                      (this.state.campaign.type === 'PUSH_NOTIFICATION' &&
                        this.state.campaign.pushType === 'BULK'))
                  ) {
                    dateActions.updateRangeAlias('custom');
                    dateActions.updateRange([
                      moment(campaign.startDate),
                      moment(),
                    ]);
                    this.getCampaignDataFromStart(
                      reportIdArchive,
                      this.state.campaign.startDate
                    );
                  } else {
                    dateActions.updateRangeAlias('lastSevenDays');
                    this.getPushReport(reportId, range);
                  }
                }
              }
            }
          );
        }
      });
    }
  }

  selectDevice(selectedDevice) {
    this.setState({
      selectedDevice
    });
  }

  render() {
    return (
      <div className="fullw-report">
        <Filters isArchive={this.state.query === "?archive"} />
        <h2 className="page-title">{t("Campaign Report")}</h2>

        <div className="widget page-content-block">
          <div className="one-whole">
            <h2 className="widget-title">{this.state.campaign.name || ""}</h2>

            {this.state.campaign.type !== "PUSH_PERMISSION" && (
              <ReportActions
                {...this.props}
                campaign={this.state.campaign}
                moduleName="push"
              />
            )}

            {this.state.campaign && this.state.campaign.type &&
            this.state.campaign.type !== "PUSH_PERMISSION" && (
              <div className={"widget-meta"}>
              <span className={"recommendation-device-selection"}>
              <Tooltip
                content={"All Devices"}
                alignment="top"
                newTooltip={true}
                isInline={true}
              >
                <span
                  key="all-devices"
                  onClick={this.selectDevice.bind(this, "ALL")}
                  className={classNames(
                    "widget-meta-mobile all-device-report",
                    {
                      "is-selected": this.state.selectedDevice === "ALL"
                    }
                  )}
                >
                  <Icons
                    name={"allDevices"}
                    width="16"
                    height="16"
                    color="#bababa"
                  />
                  {t("ALL")}
                </span>
              </Tooltip>

                {this.state.campaign.devices.map(device => {
                  if (
                    device.toLowerCase() === "tablet" ||
                    device.toLowerCase() === "pctablet"
                  ) {
                    return;
                  }

                  return (
                    <Tooltip
                      content={t(devices[device])}
                      alignment="top"
                      newTooltip={true}
                      isInline={true}
                    >
                    <span
                      key={device}
                      onClick={this.selectDevice.bind(this, device)}
                      className={classNames("widget-meta-mobile", {
                        "is-selected": this.state.selectedDevice === device
                      })}
                    >
                      <Icon name={device.toLowerCase()} />
                    </span>
                    </Tooltip>
                  );
                })}
            </span>
              </div>
            )}
          </div>

          <hr className="widget-separator" />

          <EmailStats
            report={this.state.campaignReport}
            isReport={true}
            isPush={true}
            pushType={this.state.campaign.type}
            isPushPermission={this.state.campaign.type === "PUSH_PERMISSION"}
            permissionReport={this.state.permissionReport}
            getAllPermissions={this.getAllPermissions}
            isSingle={true}
            selectedDevice={
              this.state.selectedDevice === "PC"
                ? "PC"
                : this.state.selectedDevice.toLowerCase()
            }
          />
          <hr className="widget-separator" />
          {this.state.permissionElement}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  ui: store.ui.ui,
  range: store.date.range,
  update: store.filters.update,
  reset: store.filters.reset
});

export default connect(mapStatesToProps)(SinglePushReport);
