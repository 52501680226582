import React from "react";
import connect from "react-redux/es/connect/connect";
import {
  getMostValuableProducts,
  initialRequestQueryParams
} from "../../../modules/search/insights/ajax";
import { defaultFieldMap } from "./utils/table-utils";
import { createChart } from "./utils/chart-utils";
import ReactHighcharts from "react-highcharts";
import FunnelChart from "highcharts-funnel";

FunnelChart(ReactHighcharts.Highcharts);
import { t } from "../../../system/ui";
import SgfDivTable from "./sub-components/table/sgf-div-table";
import classNames from "classnames";
import { searchInsightsActions, sgfDivTableActions, uiActions } from "../../../actions";
import SearchInsightsProductWithImageCell from "./sub-components/search-insights-product-with-image-cell";
import CardWrapper from 'components/CardWrapper';
import InfoModal from 'components/InfoModal';
import { queryDate } from '../../../system/date';
import { exportCb, revenueConverter } from './utils/common-capabilities';

const totalCtrChartData = {
  data: [],
  name: "product.name",
  yLabel: "ctr",
  yLabelName: "CTR",
  type: "pie",
  calculateTotalByYLabel: true,
  isPriceField: false,
  labelFormatter: function () {
    return (
      '<div class="dashboard--funnel__halfbox--legend">' +
        '<div class="title ellipses" title="' + this.name +'">' +
          this.name +
        '</div>' +
        '<div class="count-container">' +
          '<span class="count" style="color:' + this.color + '">' +
            this.y_label +
          '</span>' +
          'CTR' +
        '</div>' +
      '</div>'
    );
  },
  yLabelFormatConversionF: (item) => {
    return parseInt(item)
  }
};

const totalRevenueChartData = {
  data: [],
  name: "product.name",
  yLabel: "revenue",
  yLabelName: "",
  type: "pie",
  calculateTotalByYLabel: true,
  isPriceField: true,
  labelFormatter: function () {
    return (
      '<div class="dashboard--funnel__halfbox--legend">' +
        '<div class="title ellipses" title="' + this.name +'">' +
          this.name +
        '</div>' +
        '<div class="count-container">' +
          '<span class="count" style="color:' + this.color + '">' +
            this.y_label +
          '</span>' +
        '</div>' +
      '</div>'
    );
  },
  yLabelFormatConversionF: (item) => {
    return parseInt(item)
  }
};

class SearchInsightsMostValuableProducts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      range: undefined,
      tableHeaders: [],
      totalCtrChartConfig: {},
      totalRevenueChartConfig: {},
      requestParameter: Object.assign({}, initialRequestQueryParams, { limit: this.props.isDetail ? undefined : 5, device: 'all', sort: 'revenue', reverse: true }),
    };

    this.getData = this.getData.bind(this);
    this.selectDevice = this.selectDevice.bind(this);
    this.setTableHeaders = this.setTableHeaders.bind(this);
    this.onClickBreadCrumb = this.onClickBreadCrumb.bind(this);
    this.showMostValuableProducts = this.showMostValuableProducts.bind(this);

    this.deviceFilterLookup = ['PC', 'TABLET', 'MOBILE', "IOS", "ANDROID"];
  }

  setTableHeaders(callback) {
    let tableHeaders = [
      {
        name: "CTR",
        fieldMap: defaultFieldMap["CTR"]
      },
      {
        name: "Basket Rate",
        fieldMap: defaultFieldMap["Basket Rate"]
      },

      {
        name: "Purchased Products",
        fieldMap: defaultFieldMap["Purchased Products"]
      },
      {
        name: "Purchases",
        fieldMap: defaultFieldMap["Purchases"]
      },
      {
        name: "Revenue",
        fieldMap: defaultFieldMap["Revenue"]
      },
      {
        name: "Conversion Rate",
        fieldMap: defaultFieldMap["Conversion Rate"]
      }
    ];

    let tableName = "";
    if (this.props.isDetail === true) {
      tableName = "mostValuableProductsDetail";
      tableHeaders.unshift({
        name: "Product",
        fieldMap: defaultFieldMap["Product"]
      });
      tableHeaders.unshift({
        name: "Product ID",
        fieldMap: defaultFieldMap["Product ID"]
      });
    } else {
      tableName = "mostValuableProducts";
      tableHeaders.unshift({
        name: "Most Valuable Products",
        fieldMap: defaultFieldMap["Most Valuable Products"],
        className: "flex-grow-2",
        component: SearchInsightsProductWithImageCell
      })
    }
    this.setState({
      tableName: tableName,
      tableHeaders: tableHeaders
    }, ()=> {
      if (callback) {
        callback();
      }
    });
  }


  componentDidMount() {
    this.setTableHeaders();
    this.setState({
      requestParameter: {
        ...this.state.requestParameter,
        lang: this.props.selectedLanguage,
        limit: this.props.isDetail ? undefined : 5,
        device: "all",
        sort: "revenue",
        reverse: true,
        start: this.props.range[0],
        end: this.props.range[1]
      }
    }, () => {
      this.getData(true);
    });
  }

  componentWillReceiveProps(newProps) {
    let needChartUpdate = false;

    let sortKey = this.state.requestParameter.sort;
    let reverse = this.state.requestParameter.reverse;
    let sortChanged = false;
    if (newProps.clickedCellItem
      && newProps.clickedCellItem.fieldMap
      && newProps.clickedCellItem.fieldMap.sortKey
      && this.state.tableName === newProps.clickedCellItem.tableName
      && this.isSortedChanged(this.props.clickedCellItemId, newProps.clickedCellItemId)
      ) {
      sortKey = newProps.clickedCellItem.fieldMap.sortKey;
      reverse = (this.state.requestParameter.sort === newProps.clickedCellItem.fieldMap.sortKey) ? !this.state.requestParameter.reverse : this.state.requestParameter.reverse;
      sortChanged = true;
    }

    if (newProps.range[0] && newProps.range[1]) {

      if (this.props.range[0] !== newProps.range[0]
        || this.props.range[1] !== newProps.range[1]
        || this.props.selectedLanguage !== newProps.selectedLanguage
        || this.props.isDetail !== newProps.isDetail) {
        needChartUpdate = true;
      }

      if (sortChanged || needChartUpdate) {
        this.setState({
          requestParameter: {
            ...this.state.requestParameter,
            lang: newProps.selectedLanguage,
            limit: newProps.isDetail ? undefined : 5,
            sort: sortKey,
            reverse,
            start: newProps.range[0],
            end: newProps.range[1]
          }
        }, () => {
          this.setTableHeaders(()=> {
            this.getData(needChartUpdate);
          });
        });
      }
    }
  }

  selectDevice(selectedDevice) {
    this.setTableHeaders();
    this.setState({
      requestParameter: {
        ...this.state.requestParameter,
        lang: this.props.selectedLanguage,
        limit: this.props.isDetail ? undefined : 5,
        device: selectedDevice,
        start: this.props.range[0],
        end: this.props.range[1]
      }
    }, () => {
      this.getData(true);
    });
  }

  getData(needChartUpdate) {
    needChartUpdate = false; // piechartlar kapatıldıgı icin !
    uiActions.isLoading();
    getMostValuableProducts(this.state.requestParameter, mostValuableSummaryData => {
      uiActions.isLoaded();
      this.setState({
        data: mostValuableSummaryData
      });

      if (!this.props.isDetail && needChartUpdate) {
        let totalCtrRequestParameter = Object.assign({}, this.state.requestParameter, { sort: "CTR", reverse: true });
        getMostValuableProducts(totalCtrRequestParameter, mostValuableSummaryData => {
          uiActions.isLoaded();
          totalCtrChartData.data = mostValuableSummaryData;
          let totalCtrConfig = createChart(totalCtrChartData);
          this.setState({
            totalCtrChartConfig: totalCtrConfig
          });

          let totalRevenueRequestParameter = Object.assign({}, this.state.requestParameter, { sort: "revenue", reverse: true });
          getMostValuableProducts(totalRevenueRequestParameter, mostValuableSummaryData => {
            uiActions.isLoaded();
            totalRevenueChartData.data = mostValuableSummaryData;
            let totalRevenueConfig = createChart(totalRevenueChartData);
            this.setState({
              totalRevenueChartConfig: totalRevenueConfig
            });
          });

        });
      }
    });


  }

  onClickBreadCrumb() {
    sgfDivTableActions.clearSort();
    this.setState({
      requestParameter: {
        ...this.state.requestParameter,
        sort: 'revenue',
        reverse: true,
      }
    }, () => {
      searchInsightsActions.openDetailTable("");
    });
  }

  showMostValuableProducts() {
    this.setState({
      requestParameter: {
        ...this.state.requestParameter,
        sort: 'revenue',
        reverse: true,
      }
    }, () => {
      searchInsightsActions.openDetailTable("assets_searchInsightMostValuableProductsDetail");
    });
    sgfDivTableActions.clearSort();
  }

  isSortedChanged = (currentId, nextId) => {
    if (currentId !== nextId) {
      return true;
    }
    return false;
  };

  export = () => {
    const { start, end, lang } = this.state.requestParameter;
    exportCb(queryDate(start), queryDate(end), lang, 'valuable');
  };

  convertData(data) {
    return revenueConverter(data, 'revenue');
  }

  render() {
    return (
      <div>
        {this.props.isDetail &&
          <div style={{display: "flex", justifyContent: "space-between", marginBottom: "5px"}}>
            <nav id="breadcrumb" className="breadcrumb search__breadcrumb">
              <a className="nav-item breadcrumb-item current" onClick={this.onClickBreadCrumb}>
                <i className="icon-asset" role="presentation">
                  <span className="for-screenreader-only">asset icon</span>
                </i>
                Assets
              </a>
              <span className="nav-item breadcrumb-item current">Most Valuable Products</span>
            </nav>
            <a className='secondary-action' onClick={this.export}>{t('Export')}</a>
          </div>
        }

      <CardWrapper
        hasDeviceFilter
        devicesLookup={this.deviceFilterLookup}
        devicesValue={this.selectDevice}
        label={t("Most Valuable Products")}
        className="widget"
        infoContent={
          <div className="search__info-text">
            <strong> Most Valuable Products: </strong>
            <span>Products with the most contributions.</span>
          </div>
        }
      >

        {/* TODO Pie chart is closed with V3-4910 issue */}
        {this.props.isDetail !== true &&
          <div className="dashboard--funnel__container one-whole d-none">
            {Object.keys(this.state.totalCtrChartConfig).length > 0 &&
              <div className="dashboard--funnel__halfbox search__insights__body__chart-wrapper">
              <h2 className="chart-headers"> {t('Total CTR')}
                  <InfoModal iconClassName="search__info-icon" title={'Info'} >
                    <div className="search__info-text">
                      <strong> Total CTR: </strong>
                      <span>Click-through rate shows the ratio between the number of clicks on products and the number of searches by your visitors. It is calculated by dividing Clicks on Products to Searches.</span>
                    </div>
                  </InfoModal>
                </h2>
                <ReactHighcharts config={this.state.totalCtrChartConfig} ref={"totalSearches"} />
                <div className="dashboard--funnel__total">
                  <div className="dashboard--funnel__halfbox--legend">
                    <div className="title">{t("Most Valuable Products")}</div>
                    <div className="count-container">
                      <span className="count" style={{ color: "#000", fontWeight: "bolder" }}>
                        {this.state.totalCtrChartConfig.sgfData && this.state.totalCtrChartConfig.sgfData.totalValView}
                      </span>
                      {t("")}
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className={"search__insights__body__chart-divider"} />

            {Object.keys(this.state.totalRevenueChartConfig).length > 0 &&
              <div className="dashboard--funnel__halfbox search__insights__body__chart-wrapper">
                <h2 className="chart-headers" > {t('Total Revenue')}
                  <InfoModal iconClassName="search__info-icon" title={'Info'} >
                  <div className="search__info-text">
                    <strong> Total Revenue: </strong>
                    <span>Shows the purchases of Products Asset.</span>
                  </div>
                  </InfoModal>
                </h2>
                <ReactHighcharts config={this.state.totalRevenueChartConfig} ref={"totalRevenue"} />
                <div className="dashboard--funnel__total">
                  <div className="dashboard--funnel__halfbox--legend">
                    <div className="title">{t("Total Revenue")}</div>
                    <div className="count-container">
                      <span className="count" style={{ color: "#000", fontWeight: "bolder" }}>
                        {this.state.totalRevenueChartConfig.sgfData && this.state.totalRevenueChartConfig.sgfData.totalValView}
                      </span>
                      {t("")}
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        }

        <SgfDivTable data={this.convertData(this.state.data)} headers={this.state.tableHeaders} tableName={this.state.tableName}/>

        {this.props.isDetail !== true &&
          <div style={{ textAlign: "right", marginRight: "10px" }}>
            <a className={classNames("button", "add-criteria")} onClick={this.showMostValuableProducts.bind(this)}>
              {t("Show All")}
            </a>
          </div>
        }
      </CardWrapper>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  tabName: store.searchInsights.tabName,
  clickedCellItemId: store.sgfDivTable.clickedCellItemId,
  clickedCellItem: store.sgfDivTable.clickedCellItem,
  selectedLanguage: store.switchLanguage.selectedLanguage,
});

export default connect(mapStatesToProps)(SearchInsightsMostValuableProducts);

