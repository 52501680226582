import React from 'react';

class BehaviouralTargeting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: '#eee',
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + 'px' : this.state.width,
      height = this.props.height ? this.props.height + 'px' : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="100 0 512 300"
        width={this.state.width}
        height={this.state.height}
        fill= {this.state.color}
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.150000,-0.150000)"
          stroke="none"
        >
          <path
            d="M3674 4233 c-11 -2 -107 -91 -212 -197 l-192 -192 0 -195 0 -194
-372 -372 -372 -372 -30 17 c-23 13 -57 17 -136 17 -96 0 -110 -3 -165 -29
-76 -38 -143 -105 -181 -181 -26 -55 -29 -69 -29 -165 0 -95 3 -111 29 -168
37 -79 123 -162 198 -190 242 -90 488 47 528 294 10 63 0 156 -20 195 -11 20
14 48 361 395 l374 374 183 0 c139 0 189 3 210 14 15 8 107 95 205 193 171
173 177 181 177 220 0 25 -7 50 -19 64 -19 23 -24 24 -213 29 l-193 5 -5 185
c-5 181 -10 212 -42 237 -17 13 -57 21 -84 16z"
          />
          <path
            d="M2106 3820 c-398 -72 -748 -300 -969 -633 -169 -254 -248 -515 -248
-822 0 -242 48 -451 151 -656 332 -659 1092 -976 1785 -744 230 77 406 187
586 365 411 407 540 1043 321 1583 l-35 87 -67 0 -66 0 -53 -54 -53 -54 26
-65 c113 -284 121 -598 20 -877 -64 -179 -154 -317 -296 -456 -211 -207 -436
-313 -736 -345 -237 -26 -501 32 -717 158 -310 180 -522 484 -591 849 -25 134
-15 376 19 504 79 288 225 506 457 681 92 69 286 164 399 194 263 69 551 53
777 -44 l69 -30 58 57 57 56 0 62 0 62 -72 30 c-186 77 -312 102 -533 107
-148 4 -199 1 -289 -15z"
          />
          <path
            d="M2279 3289 c-216 -22 -406 -109 -557 -253 -170 -163 -274 -381 -289
-606 -24 -379 178 -735 511 -900 497 -246 1084 -21 1289 495 50 124 71 253 63
380 -5 90 -34 237 -50 253 -4 3 -54 -41 -111 -98 l-105 -104 6 -51 c8 -65 -10
-177 -41 -268 -89 -255 -360 -447 -629 -447 -204 0 -425 112 -541 274 -190
267 -174 608 39 843 134 148 309 223 520 223 l82 0 97 100 c53 55 97 104 97
109 0 10 -115 39 -188 47 -98 10 -119 11 -193 3z"
          />
        </g>
      </svg>
    );
  }
}

export default BehaviouralTargeting;
