import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const PriceDrop = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 3.5V5.5H1.5C0.67157 5.5 0 6.17157 0 7V16.5C0 18.7091 1.79086 20.5 4 20.5H8.1683C7.9439 20.0266 7.9439 19.4734 8.1683 19H4C2.61929 19 1.5 17.8807 1.5 16.5V7H9.01V9.8321C9.4621 9.6191 9.9671 9.5 10.5 9.5H10.51V7H14.5V12.5251L16 11.0251V7C16 6.17157 15.3284 5.5 14.5 5.5H13V3.5C13 1.84315 11.6569 0.5 10 0.5C9.2317 0.5 8.5308 0.78885 8 1.2639C7.4692 0.78886 6.7684 0.5 6 0.5C4.34315 0.5 3 1.84315 3 3.5ZM7.5 3.5V5.5H4.5V3.5C4.5 2.67157 5.17157 2 6 2C6.8284 2 7.5 2.67157 7.5 3.5ZM11.5 5.5H9V3.5C9 3.16563 8.9453 2.84403 8.8444 2.54368C9.1195 2.21156 9.535 2 10 2C10.8284 2 11.5 2.67157 11.5 3.5V5.5ZM10.5 15.5C11.8807 15.5 13 14.3807 13 13C13 11.6193 11.8807 10.5 10.5 10.5C9.1193 10.5 8 11.6193 8 13C8 14.3807 9.1193 15.5 10.5 15.5ZM10.5 12C11.0523 12 11.5 12.4477 11.5 13C11.5 13.5523 11.0523 14 10.5 14C9.9477 14 9.5 13.5523 9.5 13C9.5 12.4477 9.9477 12 10.5 12ZM16.5 21.5C17.8807 21.5 19 20.3807 19 19C19 17.6193 17.8807 16.5 16.5 16.5C15.1193 16.5 14 17.6193 14 19C14 20.3807 15.1193 21.5 16.5 21.5ZM16.5 18C17.0523 18 17.5 18.4477 17.5 19C17.5 19.5523 17.0523 20 16.5 20C15.9477 20 15.5 19.5523 15.5 19C15.5 18.4477 15.9477 18 16.5 18ZM17.7803 12.7803C18.0732 12.4874 18.0732 12.0126 17.7803 11.7197C17.4874 11.4268 17.0126 11.4268 16.7197 11.7197L9.2197 19.2197C8.9268 19.5126 8.9268 19.9874 9.2197 20.2803C9.5126 20.5732 9.9874 20.5732 10.2803 20.2803L17.7803 12.7803Z'
        fill='#5D5D61'
      />
    </svg>
  );
};

PriceDrop.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

PriceDrop.defaultProps = {
  width: '19px',
  height: '22px',
};

export default PriceDrop;
