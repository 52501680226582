import React, { useContext, useEffect, useState } from 'react';
import { t } from '../../../../../../system/ui';
import { SFYChildWrapper } from '../../../../../../components/sfy-components/module-exports';
import {
  InputCardWrapper,
  WofExpandableCardWrapper,
  ScratchCouponCard,
} from '../../../../../../components/gamification/module-exports';
import { TextField } from '../../../../../../components/fields';
import TimingFrequency from '../../../../social-proof/components/TimingFrequency';
import TargetAudience from '../../../../social-proof/components/TargetAudience';
import { Context as GamificationContext } from '../../../store/gamificationProvider';
import Icons from '../../../../../../components/icons';
import BundleModalManager from '../../../modals/bundleModalManager';
import { modalActions } from '../../../../../../actions';

const SettingsTab = ({ mode }) => {
  const {
    state: { activeScratchDetails },
    editActiveScratchDetails,
  } = useContext(GamificationContext);

  const [visitorSegment, setVisitorSegment] = useState({
    include: true,
    segment: '',
    type: 'SEGMENT',
  });
  const [visitorType, setVisitorType] = useState({
    include: true,
    name: '',
    type: 'VISITOR',
  });

  const editCampaign = (fieldKey, fieldValue) => {
    editActiveScratchDetails({ [fieldKey]: fieldValue });
  };

  const handleChangeFilter = (filter, type) => {
    if (type === 'VISITOR') {
      setVisitorType(filter);
    } else {
      setVisitorSegment(filter);
    }

    const tempFilters = activeScratchDetails.filters.filter(
      filterItem =>
        filterItem.type !== 'VISITOR' && filterItem.type !== 'SEGMENT',
    );
    let shouldFilterBeAdded = false;
    if (filter.type === 'VISITOR' && filter.name !== '') {
      shouldFilterBeAdded = true;
    }
    if (filter.type === 'SEGMENT' && filter.segment !== '') {
      shouldFilterBeAdded = true;
    }
    editCampaign('filters', [
      ...tempFilters,
      ...(shouldFilterBeAdded ? [filter] : []),
    ]);
  };

  const handleDelete = index => {
    let newRewards = activeScratchDetails?.possibleRewards?.filter(
      (_, i) => i !== index,
    );
    if (activeScratchDetails.gamificationType === 'UNIQUE') {
      newRewards = [];
    }
    editCampaign('possibleRewards', newRewards || []);
  };

  const getChangedRewardsByAlgorithm = (type, val, index) => {
    const lengthOfRewards = activeScratchDetails?.possibleRewards?.length || 0;
    const totalPossibility = activeScratchDetails?.possibleRewards?.reduce(
      (acc, curr) => {
        const thisPossibility = curr?.index === index ? val : curr?.possibility;
        return acc + thisPossibility;
      },
      0,
    );
    if (totalPossibility < 100 && lengthOfRewards > 1) {
      const missingPossibility = 100 - totalPossibility;
      const extraPossibilityForEach = Math.floor(
        missingPossibility / (lengthOfRewards - 1),
      );
      return activeScratchDetails?.possibleRewards?.map((reward, i) => {
        const isSurplus =
          index !== lengthOfRewards - 1
            ? i === lengthOfRewards - 1
            : i === lengthOfRewards - 2;
        if (i === index) {
          return { ...reward, possibility: val };
        }
        if (isSurplus) {
          const lastTotalPossibility =
            totalPossibility + extraPossibilityForEach * (lengthOfRewards - 1);
          const redundant = lastTotalPossibility - 100;
          const lastPossibility = extraPossibilityForEach - redundant;
          return {
            ...reward,
            possibility: reward?.possibility + lastPossibility,
          };
        }
        return {
          ...reward,
          possibility: reward?.possibility + extraPossibilityForEach,
        };
      });
    }
    const tempRewards = [...activeScratchDetails?.possibleRewards];
    tempRewards[index][type] = val;
    return tempRewards;
  };

  const handleChangeRewards = (type, val, index) => {
    const fixedVal = val < 0 ? 0 : val;
    let tempRewards = [...activeScratchDetails?.possibleRewards];
    tempRewards[index][type] = fixedVal;
    if (type === 'possibilityAlgorithm') {
      tempRewards = getChangedRewardsByAlgorithm(type, fixedVal, index);
    }
    editCampaign('possibleRewards', tempRewards || []);
  };

  const openCodeUploadModal = () => {
    const content = () => (
      <BundleModalManager
        wofFor='edit-screen'
        wofType='UNIQUE'
        campaignType='SCRATCH'
      />
    );

    modalActions.openModal({
      className: 'bundle-product-modal',
      content,
      dynamicHeight: true,
    });
  };

  const handleCreate = () => {
    const newRewards = [
      ...activeScratchDetails?.possibleRewards,
      {
        type: 'slice',
        baseCodeCount: 1,
        basePossibility: 25.0,
        couponCodes: [],
        couponUrl: '',
        description: '',
        possibility: 25.0,
        content: '',
        color: '#FF6D6D',
        index: activeScratchDetails?.possibleRewards?.length,
      },
    ];
    editCampaign('possibleRewards', newRewards || []);
  };

  const codeUploadButton = {
    button: (
      <div className='WofExpCardWrapper__right--code-upload'>
        <Icons name='reUpload' />
        <div className='WofExpCardWrapper__right--code-upload--text'>
          {t('Re-Upload')}
        </div>
      </div>
    ),
    onClick: openCodeUploadModal,
  };

  useEffect(() => {
    const { filters } = activeScratchDetails;
    filters
      ?.filter(filter => filter.type === 'VISITOR')
      .forEach(filter => {
        setVisitorType(filter);
      });

    filters
      ?.filter(filter => filter.type === 'SEGMENT')
      .forEach(filter => {
        setVisitorSegment(filter);
      });
  }, [activeScratchDetails]);

  return (
    <SFYChildWrapper type='flexHalfColGap2'>
      <InputCardWrapper
        type='block'
        cardTitle='Campaign Title*'
        labelFor='campaignTitle'
      >
        <TextField
          name='campaignTitle'
          id='campaignTitle'
          className='item-stacked one-whole'
          required
          placeholder={t('Ex : Scratch Off the card and Get Promotions!')}
          value={activeScratchDetails?.campaignTitle}
          onChange={e => editCampaign('campaignTitle', e.target.value)}
        />
      </InputCardWrapper>

      <InputCardWrapper
        type='block'
        cardTitle='Description'
        labelFor='description'
      >
        <TextField
          name='description'
          id='description'
          className='item-stacked one-whole'
          placeholder={t('Ex : Scratch this area to see coupon code!')}
          value={activeScratchDetails?.description}
          onChange={e => editCampaign('description', e.target.value)}
        />
      </InputCardWrapper>

      <WofExpandableCardWrapper
        cardTitle='Coupons'
        customButtonField={
          activeScratchDetails?.gamificationType === 'UNIQUE' && mode === 'add'
            ? codeUploadButton
            : null
        }
        isHideVisible={activeScratchDetails.gamificationType === 'GENERIC'}
      >
        {activeScratchDetails.gamificationType === 'GENERIC' ? (
          activeScratchDetails?.possibleRewards?.map((reward, index) => {
            const isRemovable =
              activeScratchDetails?.possibleRewards?.length > 1;
            const possibleRewardsTotal = activeScratchDetails?.possibleRewards?.reduce(
              (acc, curr) => acc + (curr?.possibility || 0),
              0,
            );
            const isOverLimit =
              possibleRewardsTotal > 100 || possibleRewardsTotal < 100;
            return (
              <ScratchCouponCard
                type={activeScratchDetails?.gamificationType}
                count={index}
                cardDetail={reward}
                handleChange={handleChangeRewards}
                handleDelete={handleDelete}
                isRemovable={isRemovable}
                isOverLimit={isOverLimit}
              />
            );
          })
        ) : activeScratchDetails?.possibleRewards?.length > 0 ? (
          <ScratchCouponCard
            type={activeScratchDetails?.gamificationType}
            handleChange={handleChangeRewards}
            handleDelete={handleDelete}
          />
        ) : (
          <div className='WofSliceCard__empty'>
            <div className='WofSliceCard__empty--icon'>
              <Icons name='empty' />
            </div>
            <div className='WofSliceCard__empty--text'>
              {t('No coupons uploaded yet')}
            </div>
          </div>
        )}
        {activeScratchDetails?.gamificationType === 'GENERIC' &&
          activeScratchDetails?.possibleRewards?.length < 8 && (
            <div className='WofSliceCardAdd' onClick={handleCreate}>
              <Icons name='plus' color='#5049A9' width='10' height='10' />
              <div className='WofSliceCardAdd__text'>New Slice</div>
            </div>
          )}
      </WofExpandableCardWrapper>
      <div id='wizard' className='wizard social-proof-form wof-special'>
        <div className='page-content-block form-content'>
          <TimingFrequency
            timingState={
              {
                ...activeScratchDetails?.timing,
                type:
                  activeScratchDetails?.timing?.type === 'DELAY_ALL_PAGES'
                    ? 'DELAY'
                    : activeScratchDetails?.timing?.type,
              } || {
                param: '',
                type: 'IMMEDIATE',
              }
            }
            frequencyState={
              activeScratchDetails?.frequency || {
                param: '',
                type: 'ALWAYS',
              }
            }
            deldeayAllPages={
              activeScratchDetails?.timing?.type === 'DELAY_ALL_PAGES'
            }
            onChangeTiming={val => editCampaign('timing', val)}
            onChangeFrequency={val => editCampaign('frequency', val)}
            onChangeDelay={val =>
              editCampaign('timing', {
                ...activeScratchDetails.timing,
                type: val ? 'DELAY_ALL_PAGES' : 'DELAY',
              })
            }
          />
          <TargetAudience
            visitorSegmentState={visitorSegment}
            visitorTypeState={visitorType}
            onChangeVisitorSegment={val => handleChangeFilter(val, 'SEGMENT')}
            onChangeVisitorType={val => handleChangeFilter(val, 'VISITOR')}
          />
        </div>
      </div>
    </SFYChildWrapper>
  );
};

export default SettingsTab;
