/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import FunnelChart from 'highcharts-funnel';
import classes from './ContactReport.module.scss';
import { isLoaded, showNotification } from '../../../actions/ui';
import { SFYHeader } from '../../../components/sfy-components/module-exports';
import { t } from '../../../system/ui';
import { localeString, toLocaleStringDigits } from '../../../system/string';
import { createChart } from '../../../components/search/insights/utils/chart-utils';
import { fetchContactReport } from './services/contactReportServices';
import { pieChartConfig, statTypes, chartData } from './constants/constant';

FunnelChart(ReactHighcharts.Highcharts);

const ContactReport = (/* { route } */) => {
  const [chartStats, setChartStats] = React.useState({});
  const [records, setRecords] = React.useState([]);
  const [chartConfig, setChartConfig] = React.useState({});
  const chartRef = React.useRef('total');
  const setPieChartConfig = (success, failed) => {
    chartData.data = [
      { name: 'Success Count', count: success },
      { name: 'Failed Count', count: failed },
    ];

    return createChart(chartData, pieChartConfig);
  };

  React.useEffect(() => {
    fetchContactReport()
      .then(response => {
        response.successRecords.forEach((item, index) => {
          records.push({
            id: index + 1,
            date: item.date,
            successCount: item.count || 0,
            totalCount: item.count || 0,
            failedCount: 0,
            newUserCount: item.newUserCount,
            updateUserCount: item.updateUserCount,
            isHide: true,
            failed: [],
          });
        });
        response.failedRecords.forEach((item, index) => {
          const isExist = records.some(rec => rec.date === item.date);
          if (!isExist) {
            records.push({
              id: records.length + 1,
              date: item.date,
              successCount: 0,
              totalCount: item.count,
              failedCount: item.count,
              isHide: true,
              failed: [
                {
                  id: index + 1,
                  count: item.count,
                  errorMsg: item.errorMessage,
                },
              ],
            });
          } else {
            records.forEach(rec => {
              if (rec.date === item.date) {
                rec.totalCount += item.count;
                rec.failedCount += item.count;
                rec.failed.push({
                  id: index + 1,
                  count: item.count,
                  errorMsg: item.errorMessage,
                });
              }
            });
          }
        });

        records.sort((a, b) => new Date(a.date) - new Date(b.date));

        setRecords([...records]);

        let success = 0;
        let failed = 0;
        records.forEach(rec => {
          success += rec.successCount;
          failed += rec.failedCount;
        });

        const config = setPieChartConfig(success, failed);
        setChartConfig(config);
        setChartStats({
          total: success + failed,
          successCount: success,
          failedCount: failed,
        });
      })
      .catch(err => {
        showNotification({
          content: () => <p>{err.response}</p>,
          className: 'notification-fail',
        });
      })
      .finally(() => isLoaded());
  }, []);

  const showDetail = id => () => {
    records.forEach(rec => {
      if (rec.id === id) {
        rec.isHide = !rec.isHide;
      }
    });
    setRecords([...records]);
  };

  const renderContactReport = report => {
    return (
      <>
        <div className={classes['contact-report__row']}>
          <div className={classes['contact-report__row--title']}>
            <span>Date</span>
            <span>Total Count</span>
            <span>Success Count</span>
            <span>Failed Count</span>
            <span>Detail</span>
          </div>
        </div>
        {report.map(rec => (
          <>
            <div key={rec.id} className={classes['contact-report__row']}>
              <div className={classes['contact-report__row--data']}>
                <span>{new Date(rec.date).toLocaleDateString()}</span>
                <span>{rec.totalCount}</span>
                <span>{rec.successCount}</span>
                <span>{rec.failedCount}</span>
                <span>
                  <button
                    type='button'
                    onClick={showDetail(rec.id)}
                    className='secondary-action'
                    disabled={
                      rec.failed.length === 0 &&
                      rec.contactsDailyLog?.updateUserCount === 0 &&
                      rec.contactsDailyLog?.newUserCount === 0
                    }
                  >
                    {rec.isHide ? t('Details') : t('Hide')}
                  </button>
                </span>
              </div>

              <div
                className={classes['contact-report__row--detail']}
                style={{ display: rec.isHide ? 'none' : 'flex' }}
              >
                {rec?.failed.length > 0 && (
                  <>
                    <p
                      className={classes['contact-report__row--detail__header']}
                    >
                      <span
                        className={
                          classes['contact-report__row--detail__header--left']
                        }
                      >
                        Failed Count
                      </span>
                      <span
                        className={
                          classes['contact-report__row--detail__header--right']
                        }
                      >
                        Error Message
                      </span>
                    </p>
                    {rec.failed.map(fail => (
                      <p
                        key={fail.id}
                        className={
                          classes['contact-report__row--detail__content']
                        }
                      >
                        <span
                          className={
                            classes['contact-report__row--detail__header--left']
                          }
                        >
                          {fail.count}
                        </span>
                        <span
                          className={
                            classes[
                              'contact-report__row--detail__header--right'
                            ]
                          }
                        >
                          {fail.errorMsg}
                        </span>
                      </p>
                    ))}
                  </>
                )}
              </div>
              <div
                className={classes['contact-report__row--detail']}
                style={{ display: rec.isHide ? 'none' : 'flex' }}
              >
                <p className={classes['contact-report__row--detail__header']}>
                  <span
                    className={
                      classes['contact-report__row--detail__header--left']
                    }
                  >
                    User Statistics
                  </span>
                  <span
                    className={
                      classes['contact-report__row--detail__header--right']
                    }
                  >
                    Info Message
                  </span>
                </p>
                <>
                  <p
                    key={rec?.newUserCount}
                    className={classes['contact-report__row--detail__content']}
                  >
                    <span
                      className={
                        classes['contact-report__row--detail__header--left']
                      }
                    >
                      {rec?.newUserCount}
                    </span>
                    <span
                      className={
                        classes['contact-report__row--detail__header--right']
                      }
                    >
                      The number of new user added via API
                    </span>
                  </p>
                  <p
                    key={rec?.updateUserCount}
                    className={classes['contact-report__row--detail__content']}
                  >
                    <span
                      className={
                        classes['contact-report__row--detail__header--left']
                      }
                    >
                      {rec?.updateUserCount}
                    </span>
                    <span
                      className={
                        classes['contact-report__row--detail__header--right']
                      }
                    >
                      The number of existing user updated via API
                    </span>
                  </p>
                </>
              </div>
            </div>
          </>
        ))}
      </>
    );
    // }
  };

  return (
    <>
      <SFYHeader pageTitle='Contact API Request Report' />
      <div className='page-content-block'>
        <div className='widget-stat-list widget-report'>
          <div className='widget-stats'>
            {Object.keys(chartStats).map((item, index) => {
              const stat = chartStats[item];
              const type = statTypes[item];
              const mainTitle = type.name;
              const color = type.textType;
              const className = `widget-stat stat-${color}`;
              const originalStatVal = stat;

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={className} key={`${item}-${index}`}>
                  <h4 className='widget-stat-title'>{t(mainTitle)}</h4>
                  {item !== 'widget' && (
                    <p
                      className='widget-stat-count'
                      title={toLocaleStringDigits(originalStatVal)}
                    >
                      {localeString(originalStatVal)}
                    </p>
                  )}
                  {item !== 'total' && (
                    <p style={{ fontWeight: 600 }}>
                      (%
                      {localeString(
                        chartStats.total === 0
                          ? 0
                          : (originalStatVal / chartStats.total) * 100,
                      )}
                      )
                    </p>
                  )}
                </div>
              );
            })}
            <div
              className='widget-stat'
              key='stat-chart-5'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ReactHighcharts config={chartConfig} ref={chartRef} />
            </div>
          </div>
        </div>
      </div>
      <div className={classes['contact-report']}>
        {records?.length > 0 && renderContactReport(records)}
      </div>
    </>
  );
};

export default ContactReport;
