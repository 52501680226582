import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { modalActions, uiActions, wizardActions } from '../../actions';
import { widgetTypes } from '../../constants/datamaps/campaigns';
import {
  criterionTypeButtons,
  criterionTypeButtonsPromotion,
  exclusionButtons,
  getCurrentIntelligents,
  getStaticAlgorithms,
  inclusionButtons,
} from '../../constants/datamaps/wizard';
import {
  hasOnlyDynamicBundles,
  isCategoryHierarchy,
} from '../../modules/auth/user';
import { idPropFunc } from '../../system/id';
import { t } from '../../system/ui';
import Icon from '../icon';
import Icons from '../icons';
import Criteria from './criteria';
import Excludes from './excludes';
import Includes from './includes';
import ProductSelection from './product-selection';
import IntelligentSelectionModal from './selectionModal.intelligent';

const selectionModals = {
  intelligent: {
    icon: 'brain',
    iconSvg: true,
    title: 'Intelligent Recommendation Types',
    subtitle:
      'Select from Segmentify Intelligent Recommendation Types to create your personalized recommendation campaigns',
    modal: IntelligentSelectionModal,
  },
  static: {
    icon: 'asteriks',
    iconSvg: true,
    title: 'Static Recommendation Types',
    subtitle:
      'Select from Segmentify Static Recommendation Types to create your personalized recommendation campaigns',
    modal: IntelligentSelectionModal,
  },
  intelligentDefault: {
    icon: 'brain',
    iconSvg: true,
    title: 'Intelligent Recommendation Types',
    subtitle:
      'Select from Segmentify Intelligent Recommendation Types to create your personalized recommendation campaigns',
    modal: IntelligentSelectionModal,
    openDefault: true,
  },
  intelligentPromotion: {
    icon: 'megaphone',
    iconSvg: true,
    title: 'Promotion Types',
    subtitle:
      'Select from Segmentify Promotion Types to create your personalized recommendation campaigns',
    modal: IntelligentSelectionModal,
  },
};

class WizardItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excludes: [],
      hideShuffle: false,
      shuffle: false,
      recommendOtherCategories: true,
      hideExcludes: true,
      hideIncludes: true,
      selectedType: '',
      hasError: false,
      showRequiredError: false,
      includeTypes: [],
      excludeTypes: [],
      mobileCount: 0,
      itemCount: 0,
    };

    this.handleShuffle = this.handleShuffle.bind(this);
    this.handleRecommendOtherCategories = this.handleRecommendOtherCategories.bind(
      this,
    );

    this.addDetailsRow = this.addDetailsRow.bind(this);
    this.addExclusionRow = this.addExclusionRow.bind(this);
    this.addInclusionRow = this.addInclusionRow.bind(this);
    this.addCriterion = this.addCriterion.bind(this);
    this.getCampaignDefaults = this.getCampaignDefaults.bind(this);
    this.scrollToElement = this.scrollToElement.bind(this);
    this.button = this.button.bind(this);
    this.addProducts = this.addProducts.bind(this);

    this.validate = this.validate.bind(this);
    this.onButtonsScroll = this.onButtonsScroll.bind(this);
    this.openAlgorithmSelection = this.openAlgorithmSelection.bind(this);
    this.toggleFilterAction = this.toggleFilterAction.bind(this);
    this.setRequired = this.setRequired.bind(this);
    this.changeCounts = this.changeCounts.bind(this);
  }

  componentDidMount() {
    if (
      !this.props.edit &&
      this.props.duplicate &&
      !this.props.duplicate.isDuplicate &&
      this.props.selectedType &&
      this.props.selectedType.placement !== 'fullWidth'
    ) {
      if (this.props.selectedPage === 'afterSearch') {
        wizardActions.addNoResultCriteria(this.getCampaignDefaults());
      } else {
        wizardActions.addCriteria(this.getCampaignDefaults());
      }
    } else {
      if (
        this.props &&
        this.props.duplicate &&
        !this.props.duplicate.isDuplicate
      ) {
        this.setRequired();
      }
      if (
        this.props &&
        this.props.duplicate &&
        this.props.duplicate.isDuplicate &&
        this.props.duplicate.duplicatedValues
      ) {
        this.initCriteria();
        this.setOrder(this.props.duplicate.duplicatedValues[5]);
      }
    }
    const shopTheLookExist = this.props.criteria.map(
      ({ values }) =>
        values?.criterion?.value === 'RECOMMENDATION_SHOP_THE_LOOK',
    );
    if (shopTheLookExist.some(x => x === true)) {
      this.setState({
        hideShuffle: true,
        shuffle: false,
      });
    } else {
      this.setState({
        hideShuffle: false,
      });
    }
  }

  setRequired() {
    this.setState({
      showRequiredError: true,
    });
  }

  setOrder(shuffle) {
    this.setState({
      shuffle,
      hideExcludes: this.props.excludes && this.props.excludes.length < 1,
      hideIncludes: this.props.includes && this.props.includes.length < 1,
    });
  }

  toggleFilterAction(action) {
    this.setState({ [action]: false }, () => {
      let el =
        action === 'hideIncludes' ? 'includesToScroll' : 'excludesToScroll';
      el = this.refs[el];
      this.scrollToElement(el);
    });
  }

  getCampaignDefaults() {
    let initialDefault;
    const isStickyReco =
      this.props?.selectedPage === 'custom' &&
      this.props?.selectedType?.placement === 'stickyRecommendation';
    if (this.props.isKeyword) {
      initialDefault = {
        id: Math.random(),
        type: 'intelligent',
        values: {
          criterion: {
            value: 'RECOMMENDATION_SEARCH_KEYWORDS',
          },
          timeFrame: {
            value: 'THIS_WEEK',
          },
          itemCount: {
            value: '4',
          },
        },
      };

      return [initialDefault];
    }
    if (this.props.isPromotion) {
      initialDefault = {
        id: Math.random(),
        type: 'intelligent',
        values: {
          criterion: {
            value: 'RECOMMENDATION_SMART_PROMOTIONS',
          },
          timeFrame: {
            value: 'THIS_WEEK',
          },
          itemCount: {
            value: '4',
          },
        },
      };
      return [initialDefault];
    }
    initialDefault = {
      id: Math.random(),
      type: 'intelligent',
      values: {
        criterion: {
          value: 'RECOMMENDATION_SMART_OFFERS',
        },
        timeFrame: {
          value: 'THIS_WEEK',
        },
        itemCount: {
          value: '4',
        },
      },
    };
    const criterions = [];
    let defaultAlgorithms =
      this.props.selectedType.page !== 'triggerbased'
        ? widgetTypes[this.props.selectedType.placement].algorithms
          ? widgetTypes[this.props.selectedType.placement].algorithms[
              this.props.selectedType.page
            ]
          : []
        : [];
    if (
      this.props.selectedType.page === 'category' &&
      this.props.selectedType.placement === 'mostPopular' &&
      isCategoryHierarchy()
    ) {
      defaultAlgorithms = widgetTypes.mostPopular.algorithms.categoryHierarchy;
    }
    if (
      this.props.selectedType.page === 'category' &&
      this.props.selectedType.placement === 'topSellers' &&
      isCategoryHierarchy()
    ) {
      defaultAlgorithms = widgetTypes.topSellers.algorithms.categoryHierarchy;
    }
    if (
      this.props.selectedType.page === 'checkout-success' &&
      this.props.selectedType.placement === 'fullWidth'
    ) {
      defaultAlgorithms = [
        {
          title: 'Complementary Products',
          name: 'RECOMMENDATION_COLLABORATIVE_FILTERING',
          timeFrame: false,
        },
      ];
    }
    defaultAlgorithms.forEach(alg => {
      criterions.push({
        id: Math.random(),
        type: 'intelligent',
        values: {
          criterion: {
            value: alg.name,
          },
          timeFrame: {
            value: alg.timeFrame ? alg.timeFrame : 'TODAY',
          },
          itemCount: {
            value: !isStickyReco ? '4' : '12',
          },
        },
      });
    });
    return criterions.length ? criterions : [initialDefault];
  }

  changeCounts(criteria) {
    let mobileCount = 0;
    let itemCount = 0;
    criteria.map(crt => {
      if (
        crt.type &&
        crt.type === 'product' &&
        crt.values &&
        crt.values.productId &&
        crt.values.productId.value
      ) {
        itemCount += crt.values.productId.value.length;
        mobileCount += crt.values.productId.value.length;
      }

      if (crt.values.hasOwnProperty('itemCount'))
        itemCount += parseInt(crt.values.itemCount.value);

      if (crt.values.hasOwnProperty('mobileItemCount'))
        mobileCount += parseInt(crt.values.mobileItemCount.value);
    });
    this.setState({ itemCount, mobileCount });
  }

  componentWillReceiveProps(newProps) {
    if (this.props.criteria !== newProps.criteria) {
      if (this.props.isSearch) {
        this.changeCounts(newProps.criteria);
      }
    }

    if (this.props.campaign !== newProps.campaign) {
      if (this.props.excludes.length > 0) {
        this.setState({
          hideExcludes: false,
        });
      }

      if (this.props.includes.length > 0) {
        this.setState({
          hideIncludes: false,
        });
      }

      if (newProps.campaign.actions[0].params.ordering === 'SHUFFLE') {
        this.setState({
          shuffle: true,
        });
      }

      if (newProps.campaign.actions[0]?.params?.recommendOtherCategories) {
        const recommendOtherCategories =
          newProps.campaign.actions[0]?.params?.recommendOtherCategories ===
          'true';
        this.setState({
          recommendOtherCategories,
        });
      }
    }

    if (this.props.selectedType !== newProps.selectedType) {
      this.setState({
        selectedType: newProps.selectedType,
      });
    }

    if (this.props.includes !== newProps.includes) {
      this.setState({
        includeTypes: newProps.includes.map(include => include.type),
      });
    }

    if (this.props.excludes !== newProps.excludes) {
      this.setState({
        excludeTypes: newProps.excludes.map(include => include.type),
      });
    }

    const shopTheLookExist = this.props.criteria.map(
      ({ values }) =>
        values?.criterion?.value === 'RECOMMENDATION_SHOP_THE_LOOK',
    );
    if (shopTheLookExist.some(x => x === true)) {
      this.setState({
        hideShuffle: true,
        shuffle: false,
      });
    } else if (this.state?.hideShuffle) {
      this.setState({
        hideShuffle: false,
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (
      this.props.criteria !== oldProps.criteria ||
      this.props.excludes !== oldProps.excludes ||
      this.props.includes !== oldProps.includes
    ) {
      this.validate();
      const shopTheLookExist = this.props.criteria.map(
        ({ values }) =>
          values?.criterion?.value === 'RECOMMENDATION_SHOP_THE_LOOK',
      );

      if (shopTheLookExist.some(x => x === true)) {
        this.setState({
          hideShuffle: true,
          shuffle: false,
        });
      } else if (this.state?.hideShuffle) {
        this.setState({
          hideShuffle: false,
        });
      }
    }
  }

  validate() {
    if (this.props.criteria.length === 0) {
      this.setState({
        hasError: true,
        showRequiredError: true,
      });
    } else {
      this.setState({
        hasError: false,
        showRequiredError: false,
      });
    }
    if (this.props.includes.length > 0) {
      this.setState({
        hideIncludes: false,
      });
    }
    if (this.props.excludes.length > 0) {
      this.setState({
        hideExcludes: false,
      });
    }
  }

  scrollToElement(item) {
    const diff = item.offsetTop - window.scrollY;
    if (Math.abs(diff) > 1) {
      window.scrollTo(
        0,
        window.scrollY + item.getBoundingClientRect().top + 150,
      );
      clearTimeout(window._TO);
      window._TO = setTimeout(this.scrollToItem, 30, item);
    } else {
      window.scrollTo(0, item.offsetTop);
    }
  }

  handleShuffle() {
    this.setState({ shuffle: !this.state.shuffle });
    uiActions.formEdited();
  }

  handleRecommendOtherCategories() {
    this.setState({
      recommendOtherCategories: !this.state.recommendOtherCategories,
    });
    uiActions.formEdited();
  }

  addDetailsRow(type, algorithms) {
    if (algorithms) {
      this.addCriterion(type, algorithms);
    } else if (type === 'excludes') {
      this.setState({ hideExcludes: '' });
      const elementsLength = document.querySelectorAll('.wizard-criterion')
        .length;
      const scrollSize =
        document
          .querySelectorAll('.wizard-criterion')
          [elementsLength - 1].getBoundingClientRect().top + 100;
      if (document.querySelector('.wizard-criterion')) {
        scrollTo(document.body, scrollSize, 800);
      }
    } else if (type === 'includes') {
      this.setState({ hideIncludes: '' });
      const elementsLength = document.querySelectorAll('.wizard-criterion')
        .length;
      const scrollSize =
        document
          .querySelectorAll('.wizard-criterion')
          [elementsLength - 1].getBoundingClientRect().top + 100;
      if (document.querySelector('.wizard-criterion')) {
        scrollTo(document.body, scrollSize, 800);
      }
    } else if (type === 'product') {
      const existingProductIds = [];
      this.props.criteria.forEach(item => {
        if (item.type === 'product') {
          existingProductIds.push(item.values.productId.value);
        }
      });
      const content = () => (
        <ProductSelection
          addProducts={this.addProducts}
          existingProducts={existingProductIds}
        />
      );
      uiActions.openModal({ title: t('Select Products'), content });
    } else {
      this.addCriterion(type);
    }
  }

  addExclusionRow(type) {
    const isAfterSearch = this.props.selectedPage === 'afterSearch';
    let current = this.props.excludes;
    current = current.concat({ id: Math.random(), type, values: {} });
    if (isAfterSearch) {
      wizardActions.addNoResultExclusion(current);
    } else {
      wizardActions.addExclusion(current);
    }
  }

  addInclusionRow(type) {
    const isAfterSearch = this.props.selectedPage === 'afterSearch';
    let current = this.props.includes;
    current = current.concat({ id: Math.random(), type, values: {} });
    if (isAfterSearch) {
      wizardActions.addNoResultInclusion(current);
    } else {
      wizardActions.addInclusion(current);
    }
  }

  addCriterion(type, algorithms = []) {
    const current = Object.assign([], this.props.criteria);
    const staticItems = [];
    const dynamicItems = [];
    const selectedAlgorithms = Object.assign([], this.props.criteria);
    let finalAlgorithms = [];
    const staticAlgorithms = getStaticAlgorithms(this.props.isPromotion);

    if (algorithms) {
      if (type === 'static') {
        current.forEach(item => {
          if (item.type === 'product') {
            staticItems.push(item);
          } else {
            dynamicItems.push(item);
          }
        });
        algorithms.forEach(algorithm => {
          const staticType = staticAlgorithms[algorithm.values.criterion.value];
          if (staticType === 'product') {
            staticItems.push({
              id: Math.random(),
              type: staticType,
              values: {},
            });
          } else {
            dynamicItems.push({
              id: Math.random(),
              type: staticType,
              values: {},
            });
          }
        });
        if (this.props.selectedPage === 'afterSearch') {
          wizardActions.addNoResultCriteria(staticItems.concat(dynamicItems));
        } else {
          wizardActions.addCriteria(staticItems.concat(dynamicItems));
        }
      } else {
        finalAlgorithms = selectedAlgorithms.concat(algorithms);
        if (this.props.selectedPage === 'afterSearch') {
          wizardActions.addNoResultCriteria(finalAlgorithms);
        } else {
          wizardActions.addCriteria(finalAlgorithms);
        }
      }
    } else {
      current.forEach(item => {
        if (item.type === 'product') {
          staticItems.push(item);
        } else {
          dynamicItems.push(item);
        }
      });
      if (type === 'product') {
        staticItems.push({ id: Math.random(), type, values: {} });
      } else {
        dynamicItems.push({ id: Math.random(), type, values: {} });
      }
      if (this.props.selectedPage === 'afterSearch') {
        wizardActions.addNoResultCriteria(staticItems.concat(dynamicItems));
      } else {
        wizardActions.addCriteria(staticItems.concat(dynamicItems));
      }
      /* Scroll to latest criterion element */
      const elementsLength = document.querySelectorAll('.wizard-criterion')
        .length;
      if (type !== 'product') {
        if (
          document.querySelectorAll('.wizard-criterion')[elementsLength - 1]
        ) {
          document
            .querySelectorAll('.wizard-criterion')
            [elementsLength - 1].scrollIntoView();
        }
      }
    }
  }

  button(item, type, cb, filterType) {
    const isFiltered =
      hasOnlyDynamicBundles() &&
      (this.props.selectedType.page === 'product' ||
        this.props.selectedType.page === 'basket');
    let currentIcon;
    if (
      item.icon === 'excludeLabel' ||
      item.icon === 'excludeGender' ||
      item.icon === 'excludeCustom' ||
      item.iconSvg
    ) {
      currentIcon = (
        <Icons
          name={item.icon}
          color={
            item.icon.indexOf('asteriks') < 0 &&
            item.icon.indexOf('brain') &&
            item.icon.indexOf('megaphone') < 0
              ? '#787779'
              : '#fff'
          }
        />
      );
    } else {
      currentIcon = <Icon name={item.icon} />;
    }
    const isSelectedCriteria =
      (filterType === 'inclusion' &&
        this.state.includeTypes.indexOf(type) > -1) ||
      (filterType === 'exclusion' &&
        this.state.excludeTypes.indexOf(type) > -1);
    return (
      <a
        key={type}
        className={classNames('button', item.className, {
          'include-exclude-selected': isSelectedCriteria,
          'custom-include-exclude-selected': type === 'custom',
          'pointer-no-event': isFiltered && type === 'static',
        })}
        onClick={cb.bind(null, type)}
      >
        {isSelectedCriteria && (
          <span>
            <Icons name='checkboxSelected' width='15' height='15' />
          </span>
        )}
        {currentIcon}
        {t(item.title)}
      </a>
    );
  }

  initCriteria() {
    const current = Object.assign([], this.props.criteria);
    const staticItems = [];
    const dynamicItems = [];
    current.forEach(item => {
      if (item.type === 'product') {
        staticItems.push(item);
      } else {
        dynamicItems.push(item);
      }
      if (this.props.selectedPage === 'afterSearch') {
        wizardActions.addNoResultCriteria(staticItems.concat(dynamicItems));
      } else {
        wizardActions.addCriteria(staticItems.concat(dynamicItems));
      }
    });
  }

  addProducts(products) {
    const staticItems = [];
    modalActions.closeModal();

    products.forEach(product => {
      staticItems.push({
        id: Math.random(),
        type: 'product',
        values: { productId: { value: product.productId } },
      });
    });
    if (this.props.selectedPage === 'afterSearch') {
      wizardActions.addNoResultCriteria(staticItems);
    } else {
      wizardActions.addCriteria(staticItems);
    }
    const scrollSize =
      document.querySelectorAll('.wizard-criterion')[0].getBoundingClientRect()
        .top - 100;
    if (document.querySelectorAll('.wizard-criterion')[0]) {
      scrollTo(document.body, scrollSize, 800);
    }
  }

  onButtonsScroll() {
    const containerEl = document.getElementById('scroll-container');
    if (containerEl) {
      const el = document.getElementsByClassName('wizard-input-types')[0];
      const elTop =
        el.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top;
      const heighttoset = `${el.offsetHeight}px`;
      containerEl.style.height = heighttoset;
      window.addEventListener('scroll', function() {
        if (document.documentElement.scrollTop + 15 > elTop) {
          el.className = 'wizard-input-types sticky';
        } else {
          el.className = 'wizard-input-types ';
        }
      });
    }
  }

  openAlgorithmSelection(obj) {
    const selectionModal = this.props.isPromotion
      ? selectionModals[`${obj}Promotion`]
      : selectionModals[obj];
    const Modal = selectionModal.modal;
    const { props } = this;
    let currentIntelligents = getCurrentIntelligents(
      props.selectedType.page,
      props.isPromotion,
      props.isKeyword,
      props.isSingleRec,
    );
    const currentStatics = getStaticAlgorithms(this.props.isPromotion);
    let currentFavorites = [];
    if (this.props.addPage) {
      const favorites = this.getCampaignDefaults();
      if (favorites && favorites.length) {
        favorites.forEach(favorite => {
          const { values } = favorite;
          if (values.criterion) {
            currentFavorites.push(values.criterion.value);
          }
        });
      }
    }
    if (
      obj === 'intelligent' &&
      this.props.selectedType.placement === 'fullWidth'
    ) {
      const currentPage = this.props.selectedType.page;
      // product, basket. category
      if (currentPage === 'product') {
        currentFavorites = ['RECOMMENDATION_SOURCE_RELATED_PRODUCT'];
      } else if (currentPage === 'basket') {
        currentFavorites = ['RECOMMENDATION_COLLABORATIVE_FILTERING'];
      } else if (currentPage === 'category') {
        currentFavorites = ['RECOMMENDATION_CATEGORY_ROCKS'];
      }
    }

    const disabledStatics = [];
    this.props.criteria.forEach(criteria => {
      if (criteria.type === 'product') {
        disabledStatics.push(`STATIC-${criteria.type.toUpperCase()}`);
      }
    });

    const currentAlgorithms =
      obj === 'intelligent' || obj === 'intelligentDefault'
        ? currentIntelligents
        : currentStatics;
    if (this.props.isPromotion) {
      currentIntelligents = {
        ...currentAlgorithms,
        'STATIC-PROMOTION': 'promotion',
      };
    }
    modalActions.openModal({
      title: t(selectionModal.title),
      subtitle: t(selectionModal.subtitle),
      icon: selectionModal.icon,
      className: 'wizard-comp-modal',
      content: () => (
        <Modal
          currentAlgorithms={
            obj === 'intelligent' || obj === 'intelligentDefault'
              ? currentIntelligents
              : currentStatics
          }
          addDetailsRow={this.addDetailsRow}
          currentFavorites={currentFavorites}
          addPage={this.props.addPage}
          isDefaultOpen={selectionModal.openDefault}
          isTopSellers={
            this.props.selectedType &&
            this.props.selectedType.placement === 'topSellers'
          }
          isMostPopular={
            this.props.selectedType &&
            this.props.selectedType.placement === 'mostPopular'
          }
          isSearch={this.props.isSearch}
          disabledStatics={disabledStatics}
          isPromotion={this.props.isPromotion}
          isRecoModal={
            this.props.selectedType.page === 'product' ||
            this.props.selectedType.page === 'basket'
          }
        />
      ),
    });
  }

  render() {
    let criterionButtonsToShow = criterionTypeButtons;
    if (this.props.isPromotion) {
      criterionButtonsToShow = criterionTypeButtonsPromotion;
    }
    if (this.props.isKeyword) {
      // There is no recommendation type button if it is search keyword recommendation
      criterionButtonsToShow = {};
    }
    return (
      <ol
        className='form-elements wizard-items'
        {...idPropFunc(this.props.id, 'WizardItems')}
      >
        {this.props.isSearch && (
          <div className='counts'>
            <ul>
              <li>
                <Icon name='mobile' />|{this.state.mobileCount}
              </li>
              <li>
                <Icon name='pc' />|{this.state.itemCount}
              </li>
            </ul>
          </div>
        )}
        <div className='wizard-comp'>
          <h3 className='wizard-comp-title'>{t('Recommendations')}</h3>
          <p className='wizard-comp-description'>
            {t(
              'Select from these algorithms to create your recommendations based on your needs.',
            )}
          </p>
          {!this.props.isKeyword && (
            <div id='scroll-container'>
              <li className='wizard-input-types'>
                {Object.keys(criterionButtonsToShow).map(item => {
                  return this.button(
                    criterionButtonsToShow[item],
                    item,
                    this.openAlgorithmSelection,
                  );
                })}
                <div
                  className={
                    this.props.selectedPage === 'product'
                      ? 'open-filters-buttons__productPage'
                      : 'open-filters-buttons'
                  }
                >
                  {this.props.selectedPage === 'product' && (
                    <>
                      <label className='item item-stacked is-checkbox recommendOtherCategories'>
                        <input
                          name='recommendOtherCategories'
                          type='checkbox'
                          checked={this.state.recommendOtherCategories}
                          onChange={this.handleRecommendOtherCategories}
                        />
                        <span className='item-label'>
                          {t('Recommend Other Categories')}
                        </span>
                      </label>
                    </>
                  )}
                  {this.props.isSearch === undefined && (
                    <>
                      {this.state.hideShuffle === false ? (
                        <label className='item item-stacked is-checkbox is-shuffle'>
                          <input
                            type='checkbox'
                            checked={this.state.shuffle}
                            onChange={this.handleShuffle}
                          />
                          <span className='item-label'>
                            <Icon name='shuffle' />
                            {this.props.isPromotion
                              ? t('Shuffle Promotions')
                              : t('Shuffle Items')}
                          </span>
                        </label>
                      ) : null}
                      <div
                        name='inclusion'
                        className={classNames(
                          'open-filters open-filters--inclusion',
                          {
                            'is-hidden':
                              this.props.isKeyword || this.props.isPromotion,
                          },
                        )}
                        onClick={this.toggleFilterAction.bind(
                          null,
                          'hideIncludes',
                        )}
                      >
                        <Icons name='plusCircleSmall' color='#787779' />
                        {t('Include Filters')}
                      </div>
                    </>
                  )}

                  <div
                    name='exclusion'
                    className={classNames(
                      'open-filters open-filters--exclusion',
                      {
                        'is-hidden':
                          this.props.isKeyword || this.props.isPromotion,
                      },
                    )}
                    onClick={this.toggleFilterAction.bind(null, 'hideExcludes')}
                  >
                    <Icons name='minusCircleSmall' color='#787779' />
                    {t('Exclude Filters')}
                  </div>
                </div>
              </li>
            </div>
          )}
          <li className='wizard-input-type-wrapper'>
            <div className='wizard-criteria'>
              <Criteria
                selectedPage={this.props.selectedPage}
                isPromotion={this.props.isPromotion}
                isKeyword={this.props.isKeyword}
                selectedType={this.props.selectedType}
                campaign={this.props.campaign}
                isReco={this.props.isReco}
                updateAlgorithmString={this.props.updateAlgorithmString}
                isSearch={this.props.isSearch}
                updateChangeCounts={this.changeCounts}
              />
              {this.state.showRequiredError ? (
                <span
                  className='item-error has-error'
                  style={{
                    display: 'block',
                    marginTop: '10px',
                    padding: '15px',
                  }}
                >
                  {t(
                    'You should make at least one selection from Segmentify Intelligent or Static algorithms',
                  )}
                </span>
              ) : (
                ''
              )}
            </div>
          </li>
        </div>
        <div
          className={classNames('wizard-comp  wizard-comp-filters', {
            'is-hidden':
              this.props.isKeyword ||
              this.props.isPromotion ||
              this.state.hideIncludes,
          })}
          ref='includesToScroll'
        >
          <h3 className='wizard-comp-title' style={{ color: '#8ea976' }}>
            <Icons name='plusCircleSmall' color='#8ea976' />
            {t('Include Filters')}
          </h3>
          <p className='wizard-comp-description'>
            {t(
              'Include filters allows you to highlight the products you need in your recommendations based on your selected attributes.',
            )}
          </p>
          {Object.keys(inclusionButtons).map(item => {
            return this.button(
              inclusionButtons[item],
              item,
              this.addInclusionRow,
              'inclusion',
            );
          })}
          <li className='wizard-input-type-wrapper'>
            <Includes selectedPage={this.props.selectedPage} />
          </li>
        </div>
        <div
          className={classNames('wizard-comp wizard-comp-filters', {
            'is-hidden':
              this.props.isKeyword ||
              this.props.isPromotion ||
              this.state.hideExcludes,
          })}
          ref='excludesToScroll'
        >
          <h3 className='wizard-comp-title' style={{ color: '#c54343' }}>
            <Icons name='minusCircleSmall' color='#c54343' />{' '}
            {t('Exclude Filters')}
          </h3>
          <p className='wizard-comp-description'>
            {t(
              'Exclude filters allows you to narrow down your recommendation selection to the products that meet your criteria',
            )}
          </p>
          {Object.keys(exclusionButtons).map(item => {
            return this.button(
              exclusionButtons[item],
              item,
              this.addExclusionRow,
              'exclusion',
            );
          })}
          <li className='wizard-input-type-wrapper'>
            <Excludes selectedPage={this.props.selectedPage} />
          </li>
        </div>
      </ol>
    );
  }
}

const mapStatesToProps = (store, thisProps) => ({
  validate: store.ui.validate,
  criteria:
    thisProps.selectedPage === 'afterSearch'
      ? store.wizard.noResultCriteria
      : store.wizard.criteria,
  excludes:
    thisProps.selectedPage === 'afterSearch'
      ? store.wizard.noResultExcludes
      : store.wizard.excludes,
  includes:
    thisProps.selectedPage === 'afterSearch'
      ? store.wizard.noResultIncludes
      : store.wizard.includes,
});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
  WizardItems,
);
