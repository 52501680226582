import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  findRealized,
  findTotalTarget,
  getSparklineColor,
  getTargetRatio,
  prepareGARatio,
  preparePerformance,
} from '../../../../components/monitor/utils/functions';
import { isSuperUser } from '../../../../modules/auth/user';
import {
  monitorAccountTypes,
  monitorModuleList,
} from '../../../../components/admin/constants';
import { t } from '../../../../system/ui';
import { reformatDate } from '../../../../system/date';
import Icons from '../../../../components/icons';
import { featureModules } from '../../../../constants/datamaps/account';
import { calculateRatio } from '../../../../system/ratio';
import SparkLine from '../../../../components/widget/sparkline';
import SelectUser from '../../../../components/admin/select-user';
import {
  modalActions,
  switchUserActions,
  uiActions,
} from '../../../../actions';
import setHighchartsDefaults from '../../../../modules/highcharts.global';
import EditTarget from '../../../../components/admin/edit-target';
import { Context as MonitoringContext } from '../store/MonitoringProvider';

/**
 * @name StatsBody
 * @description StatsBody component that renders monitoring stats coming from server
 * @param {object} account
 * @param {number} index
 * @return {JSX.Element}
 * @constructor
 */

const StatsBody = ({ account, index }) => {
  const [expandedRows, setExpandedRows] = useState([]);

  const {
    state: { users, accounts, selectedAccounts },
    setSelectedAccounts,
  } = useContext(MonitoringContext);

  const { rangeAlias } = useSelector(store => store.date);

  const handleRowClick = acc => {
    const rowId = acc.accountId;
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
    acc.isClicked = acc.isClicked ? !acc.isClicked : true;

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter(id => id !== rowId)
      : currentExpandedRows.concat(rowId);

    setExpandedRows(newExpandedRows);
  };

  const sparkLine = (array, totalPurchases, target) => {
    const values = array.map((value, ind) =>
      parseFloat(
        parseFloat(calculateRatio(value, totalPurchases[ind]) - target).toFixed(
          2,
        ),
      ),
    );

    const data = values.map(value => {
      const obj = {};
      obj.y = value;
      obj.color = getSparklineColor(value);

      return obj;
    });

    const text = rangeAlias === 'weeks' ? 'Week' : 'Day';

    const options = {
      xAxis: {
        title: {
          text: rangeAlias === 'weeks' ? 'Last <br/>7 Days' : 'Last <br/>Day',
        },
      },
      series: [
        {
          data,
          pointStart: 1,
        },
      ],

      tooltip: {
        headerFormat: `<span style="font-size:10px">${text} {point.x}:</span><br/>`,
        pointFormat: '<b>{point.y}</b> %',
      },
    };

    return <SparkLine options={options} />;
  };

  const switchAccount = acnt => {
    const accountUsers = {};

    selectedAccounts.forEach(acc => {
      accountUsers[acc.accountId] = [];

      users.forEach(usr => {
        if (usr.switchableAccounts.includes(acc.accountId))
          accountUsers[acc.accountId].push({
            user: usr,
            isOwner: usr.switchableAccounts.includes(acc.accountId),
          });
      });
    });

    const switchedAccount = {};
    switchedAccount.displayName =
      accountUsers[acnt.accountId][0]?.user?.displayName;
    switchedAccount.account = accountUsers[acnt.accountId][0]?.user?.account;
    switchedAccount.language = accountUsers[acnt.accountId][0]?.user?.language;
    switchedAccount.privileges =
      accountUsers[acnt.accountId][0]?.user?.privileges;
    switchedAccount.userRole = accountUsers[acnt.accountId][0]?.user?.userRole;
    switchedAccount.username = accountUsers[acnt.accountId][0]?.user?.username;

    let allSwitchableAccounts = [];
    let switchableAccountsWithDetails = [];

    accountUsers[acnt.accountId].forEach(user => {
      allSwitchableAccounts = allSwitchableAccounts.concat(
        user.user.switchableAccounts,
      );
    });

    switchableAccountsWithDetails = accounts.filter(
      acc => allSwitchableAccounts.indexOf(acc.accountId) > -1,
    );

    if (accountUsers[acnt.accountId].length > 1) {
      const modal = () => (
        <SelectUser
          account={acnt}
          users={accountUsers[acnt.accountId]}
          accounts={selectedAccounts}
          switchableAccountsWithDetails={switchableAccountsWithDetails}
        />
      );
      modalActions.openModal({
        title: t('Select User'),
        content: modal,
      });
    } else {
      selectedAccounts.forEach(acc => {
        if (acc.accountId === switchedAccount.account.accountId) {
          switchedAccount.account = acc;
        }
      });
      switchUserActions.updateSwitchUser({
        ...switchedAccount,
        switchableAccountsWithDetails,
      });
      setHighchartsDefaults({
        ...switchedAccount,
        switchableAccountsWithDetails,
      });
    }
  };

  const updateAccount = updatedAccount => {
    modalActions.closeModal();
    const updatedAccounts = [...selectedAccounts];
    const ind = updatedAccounts.findIndex(
      acc => acc.accountId === updatedAccount.accountId,
    );
    updatedAccounts[ind] = updatedAccount;

    setSelectedAccounts(updatedAccounts);
  };

  const editTarget = acnt => {
    const content = () => (
      <EditTarget account={acnt} onUpdate={updateAccount} />
    );

    uiActions.openModal({ title: t('Edit Target'), content });
  };

  const clickCallback = () => handleRowClick(account);
  const totalPerformance = preparePerformance(
    account?.stats?.total,
    findTotalTarget(account.targets, account.features, account.stats),
    account.stats.all[account?.stats?.all?.length - 1],
  );
  let itemRows = [];
  itemRows = [
    <tr key={`row-data-${account.accountId}`}>
      <td>{index + 1}</td>
      <td className='account-name-monitor'>
        <a
          className='account-domain account-label'
          onClick={() => (isSuperUser() ? switchAccount(account) : null)}
        >
          {account.domain}
        </a>
        <span
          className='account-id account-label'
          style={{ minWidth: '140px' }}
        >
          {account.accountId}
        </span>
      </td>
      <td>
        {monitorAccountTypes.includes(account.type) ? (
          <span>
            <br />
            <strong>{t('Onboarding Manager')}</strong>
            <br />
            {account?.ownership?.onboardingManager}
            <br />
            <strong>{t('Onboarding Developer')}</strong>
            <br />
            {account?.ownership?.onboardingDeveloper}
            <br />
            <strong>{t('Sales Manager')}</strong>
            <br />
            {account?.ownership?.salesManager}
          </span>
        ) : (
          <span>
            <br />
            <strong>{t('Account Manager')}</strong>
            <br />
            {account?.ownership?.accountManager}
            <br />
            <strong>{t('Account Developer')}</strong>
            <br />
            {account?.ownership?.accountDeveloper}
          </span>
        )}
      </td>
      <td>{t(account?.type)}</td>
      <td>{account?.tier && account?.tier?.length > 0 ? account?.tier : '-'}</td>
      <td>
        <span className='date-time'>
          {account.stats.dates
            ? reformatDate(account?.stats?.dates?.early)
            : 'No Data'}
        </span>
      </td>
      <td>All</td>
      <td>
        {`${findTotalTarget(
          account?.targets,
          account?.features,
          account?.stats,
        )} %`}
      </td>
      <td>
        {`${findRealized(
          account?.stats?.total,
          account?.stats?.all[account?.stats?.all?.length - 1],
        )} %`}
      </td>
      <td className='trends'>
        {sparkLine(
          account?.stats?.total,
          account?.stats?.all,
          findTotalTarget(account?.targets, account?.features, account?.stats),
        )}
      </td>
      <td
        className='performance'
        style={{
          background: `linear-gradient(to right, white 90%, ${totalPerformance.color} 10%)`,
        }}
      >
        {totalPerformance?.text}
      </td>
      <td className='ga'>
        {account?.googleAnalytics !== undefined &&
        account?.googleAnalytics?.viewId !== undefined &&
        account?.googleAnalytics?.viewId !== ''
          ? prepareGARatio(account?.stats?.ga)
          : 'No Data'}
      </td>
      <td className='actions'>
        <div className='buttons-criteria'>
          <a onClick={() => editTarget(account)} className='button-criteria'>
            <Icons name='edit' width='16' height='16' color='gray' />
          </a>
          <a
            onClick={clickCallback}
            className='button-criteria'
            style={
              account.isClicked
                ? { paddingRight: '7px' }
                : { paddingLeft: '10px' }
            }
          >
            {account.isClicked ? (
              <Icons
                name='monitorCollapse'
                width='24'
                height='24'
                color='gray'
              />
            ) : (
              <Icons name='monitorExpand' width='24' height='24' color='gray' />
            )}
          </a>
        </div>
      </td>
    </tr>,
  ];

  if (expandedRows.includes(account.accountId)) {
    Object.keys(account.features).forEach(item => {
      if (
        monitorModuleList.includes(item) &&
        account?.features[item]?.status === 'ACTIVE'
      ) {
        if (account?.stats[item]) {
          const performance = preparePerformance(
            account?.stats[item],
            getTargetRatio(account?.targets, item),
            account?.stats?.all[account?.stats?.all?.length - 1],
          );

          const moduleObj = featureModules.find(m => m.key === item);
          itemRows.push(
            <tr key={`row-expanded-${item}${account.accountId}`}>
              <td />
              <td />
              <td />
              <td />
              <td />
              <td>
                <span className='date-time'>
                  {account.stats.dates
                    ? reformatDate(account.stats.dates[item])
                    : 'No data'}
                </span>
              </td>
              <td>{moduleObj.name}</td>
              <td>{`${getTargetRatio(account.targets, item)} %`}</td>
              <td>
                {`${findRealized(
                  account.stats[item],
                  account.stats.all[account.stats.all.length - 1],
                )} %`}
              </td>
              <td>
                {sparkLine(
                  account.stats[item],
                  account.stats.all,
                  getTargetRatio(account.targets, item),
                )}
              </td>
              <td
                className='performance'
                style={{
                  background: `linear-gradient(to right, white 90%, ${performance.color} 10%)`,
                }}
              >
                {performance.text}
              </td>
              <td />
              <td />
            </tr>,
          );
        }
      }
    });
  }

  return itemRows;
};

export default StatsBody;
