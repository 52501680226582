/**
 * Created by mehmetyurtar on 29/06/2017.
 */
import React, { Component } from "react";
import Diff from "react-diff/dist/react-diff";

class CampaignDiff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixedHeight: 0
    };
    this.setHeight = this.setHeight.bind(this);
  }
  componentDidMount() {
    let second = this.refs.second;
    let first = this.refs.first;
    if (second.scrollHeight > first.scrollHeight) {
      this.setHeight(second.scrollHeight);
    } else {
      this.setHeight(first.scrollHeight);
    }
  }
  setHeight(fixedHeight) {
    this.setState({
      fixedHeight
    });
  }
  render() {
    return (
      <div className="campaign-diff">
        <div
          className="campaign-diff__first"
          ref={"second"}
          style={{ height: this.state.fixedHeight }}
        >
          <h2>Older Version ({this.props.operationId})</h2>
          <pre>{JSON.stringify(this.props.secondInput, undefined, 2)}</pre>
        </div>
        <div
          className="campaign-diff__second"
          ref={"first"}
          style={{ height: this.state.fixedHeight }}
        >
          <h2>Current Version</h2>
          <pre>{JSON.stringify(this.props.firstInput, undefined, 2)}</pre>
        </div>
        <div className="campaign-diff__result">
          <h2>Difference</h2>
          <Diff
            inputA={this.props.secondInput}
            inputB={this.props.firstInput}
            type="json"
          />
        </div>
      </div>
    );
  }
}

export default CampaignDiff;
