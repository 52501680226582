"use strict";

import React from "react";
import KeyMetricsGraph from "./dashboard.metrics.graph";
import KeyMetricsText from "./dashboard.metrics.text";

class MetricsView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="dashboard--funnel">
        <div className="dashboard--funnel__container one-whole">
          <div className="dashboard--funnel__halfbox metric-box">
            <KeyMetricsGraph
              data={this.props.metricsWithInterval}
              textData={this.props.metrics}
              customFrame={this.props.interval}
            />
          </div>
          <div className="dashboard--funnel__halfbox metric-box">
            <KeyMetricsText data={this.props.metrics} />
          </div>
        </div>
      </div>
    );
  }
}
export default MetricsView;
