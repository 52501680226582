import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

import { t } from '../../system/ui';

import { filterActions } from '../../actions';

import Icon from '../icon';
import { TextField } from '../fields';
import DeviceSelect from './device-select';
import { searchCategories } from '../../modules/category/ajax';
import { decodeHtml } from '../../system/string';
import { insightsQuery } from '../../modules/insights/data';

class TrendifyFilters extends React.Component {
  initialState = {
    brand: '',
    category: '',
    productId: '',
    device: 'all',
    minPrice: 0,
    maxPrice: 1000000000000,
    maxPriceMin: 0,
    maxPriceError: false,
    stock: 'all',
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.initialState,
      includedFields: [],
      categories: [],
    };

    this.reset = this.reset.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeMin = this.onChangeMin.bind(this);
    this.onChangeMax = this.onChangeMax.bind(this);
    this.onChangeBrand = this.onChangeBrand.bind(this);
    this.onChangeStock = this.onChangeStock.bind(this);
    this.onChangeCategory = this.onChangeCategory.bind(this);
    this.onDeviceChange = this.onDeviceChange.bind(this);
    this.setIncludedValues = this.setIncludedValues.bind(this);
    this.onSearchCategory = this.onSearchCategory.bind(this);
  }

  componentDidMount() {
    const {
      brand,
      category,
      device,
      minPrice,
      maxPrice,
      stock,
      productId,
    } = this.props.filterValues;

    this.setState({
      brand,
      category,
      device,
      minPrice,
      maxPrice: maxPrice !== insightsQuery.maxPrice && maxPrice,
      stock,
      productId,
    });
    this.setIncludedValues();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.reset === true) {
      this.reset();
    }
  }

  onChangeName(e) {
    this.setState({
      productId: e.target.value,
    });
    filterActions.showButtons();
  }

  onChangeMin(e) {
    filterActions.showButtons();
    filterActions.enableButtons();

    const maxPrice = this.refs.maxPrice.value * 1;

    this.setState({
      maxPriceMin: e.currentTarget.value,
      minPrice: e.currentTarget.value,
    });

    if (maxPrice > 0 && e.currentTarget.value > maxPrice) {
      this.setState({
        maxPriceError: true,
      });

      filterActions.disableButtons();
    }
  }

  onChangeMax(e) {
    filterActions.showButtons();

    if (
      e.currentTarget.value !== '' &&
      e.currentTarget.value * 1 < this.state.maxPriceMin
    ) {
      this.setState({
        maxPriceError: true,
        maxPrice: e.currentTarget.value,
      });
      filterActions.disableButtons();
    } else {
      this.setState({
        maxPriceError: false,
        maxPrice: e.currentTarget.value,
      });

      filterActions.enableButtons();
    }
  }

  onChangeCategory(selectedOption) {
    this.setState({
      category: selectedOption ? selectedOption.value : '',
    });

    filterActions.showButtons();
  }

  onChangeBrand(selectedOption) {
    this.setState({
      brand: selectedOption ? selectedOption.value : '',
    });

    filterActions.showButtons();
  }

  onChangeStock(selectedOption) {
    this.setState({
      stock: selectedOption.value,
    });

    filterActions.showButtons();
  }

  onDeviceChange(device) {
    this.setState({
      device,
    });

    filterActions.showButtons();
  }

  onSearchCategory(value) {
    if (value.length > 2) {
      searchCategories(value, response => {
        const sorted = response.sort();
        const categories = sorted.map(item => ({
          value: item,
          label: decodeHtml(item),
        }));
        this.setState({
          categories,
        });
      });
    } else {
      this.setState({
        categories: [],
      });
    }
    return value;
  }

  setIncludedValues() {
    this.setState({
      includedFields: this.props.includedFields || [],
    });
  }

  reset() {
    this.setState(this.initialState);
    filterActions.filtersReset();
  }

  render() {
    const stocks = [
      { value: 'all', label: t('Product Stock') },
      { value: 'true', label: t('Only In Stock') },
      { value: 'false', label: t('Only Out of Stock') },
    ];

    let categories = [{ value: '', label: t('All Product Categories') }];

    categories = categories.concat(this.state.categories);
    if (this.state.category) {
      categories = categories.concat([
        { value: this.state.category, label: decodeHtml(this.state.category) },
      ]);
    }

    const brands = [
      { value: '', label: t('All Product Brands') },
      ...this.props.brands.map(item => ({
        value: item,
        label: item,
      })),
    ];

    return (
      <div
        className={
          this.props.isInsights
            ? 'analytics-filters analytics-filters__insights group'
            : 'analytics-filters group'
        }
        ref='filters'
      >
        {this.state.includedFields.indexOf('productCategory') !== -1 && (
          <label className='item item-stacked is-select large'>
            <span className='item-label'>{t('Product Category')}</span>
            <Select
              value={this.state.category}
              options={categories}
              name='productCategory'
              onChange={this.onChangeCategory}
              clearable={false}
              searchable
              onInputChange={this.onSearchCategory}
            />
          </label>
        )}
        {this.state.includedFields.indexOf('productBrand') !== -1 && (
          <label className='item item-stacked is-select large'>
            <span className='item-label'>{t('Product Brand')}</span>
            <Select
              value={this.state.brand}
              options={brands}
              name='productBrand'
              onChange={this.onChangeBrand}
              clearable={false}
            />
          </label>
        )}
        {this.state.includedFields.indexOf('productName') !== -1 && (
          <TextField
            name='productName'
            className='item-stacked'
            label='Search Product'
            placeholder='Product ID or Title'
            onChange={this.onChangeName}
            value={this.state.productId}
          />
        )}
        {this.state.includedFields.indexOf('minPrice') !== -1 && (
          <label className='item item-stacked price-range price-range-min'>
            <span className='item-label'>{t('Price Range')}</span>
            <span className='item-label-alt'>{t('min')}:</span>
            <input
              type='number'
              id='minPrice'
              ref='minPrice'
              min='0'
              step='1'
              onChange={this.onChangeMin}
              value={this.state.minPrice}
              style={{ width: this.props.isInsights ? '80px' : 'auto' }}
            />
          </label>
        )}
        {this.state.includedFields.indexOf('maxPrice') !== -1 && (
          <label className='item item-stacked price-range'>
            <span className='item-label'>&nbsp;</span>
            <span className='item-label-alt'>{t('max')}:</span>
            <input
              type='number'
              id='maxPrice'
              ref='maxPrice'
              min={this.state.maxPriceMin}
              step='1'
              onChange={this.onChangeMax}
              value={this.state.maxPrice}
              style={{ width: this.props.isInsights ? '80px' : 'auto' }}
            />
            {this.state.maxPriceError && (
              <span className='item-error'>
                {t('This number should bigger than min price')}
              </span>
            )}
          </label>
        )}
        {this.state.includedFields.indexOf('productStock') !== -1 && (
          <label
            className='item item-field is-select has-icon-label'
            style={{ width: '160px' }}
          >
            <span className='item-label'>
              <Icon name='stock' />
            </span>
            <Select
              searchable={false}
              clearable={false}
              value={this.state.stock}
              options={stocks}
              name='productStock'
              onChange={this.onChangeStock}
            />
          </label>
        )}
        {this.state.includedFields.indexOf('device') !== -1 && (
          <DeviceSelect
            selectedDevice={this.state.device}
            onDeviceChange={this.onDeviceChange}
          />
        )}
      </div>
    );
  }
}

const mapStatsToProps = store => ({
  brands: store.filters.trendifyBrands,
  categories: store.filters.trendifyCategories,
  reset: store.filters.reset,
});

export default connect(mapStatsToProps)(TrendifyFilters);
