/**
 * @author Bilal Cinarli
 */

"use strict";

import { createReducer } from "../system/store";
import * as modalActions from "../constants/actions/modal";

const initialState = {
  modal: {},
  showModal: false,
  reCalculateHeight: false
};

export default createReducer(initialState, {
  [modalActions.default.OPEN_MODAL]: (state, payload) => {
    return Object.assign({}, state, {
      modal: payload,
      showModal: true
    });
  },
  [modalActions.default.CLOSE_MODAL]: state => {
    return Object.assign({}, state, {
      modal: {},
      showModal: false
    });
  },
  [modalActions.default.CALCULATE_MODAL_HEIGHT]: state => {
    return Object.assign({}, state, {
      reCalculateHeight: true
    });
  },
  [modalActions.default.CANCEL_CALCULATION]: state => {
    return Object.assign({}, state, {
      reCalculateHeight: false
    });
  }
});
