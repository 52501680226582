import React, { useState } from 'react';
import classes from './UploadCouponCodesModal.module.scss';
import { t } from '../../../system/ui';
import { closeModal } from '../../../actions/ui';

import UploadCouponCodes from './UploadCouponCodes';

const UploadCouponCodesModal = ({ setUploadedText, setUploadFile }) => {
  const [uploadFileState, setUploadFileState] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);

  const onUpload = uploadableFile => {
    setUploadFileState(uploadableFile);
    setIsUploaded(true);
  };

  const saveCouponCodes = () => {
    setUploadFile(uploadFileState);
    setUploadedText('File uploaded.');
    closeModal();
  };

  return (
    <div className={classes.content}>
      <div className={classes['bundle-product-modal-content']}>
        <UploadCouponCodes onChange={onUpload} />
      </div>
      <div className={classes['bottom-actions']}>
        <div className='continue-button'>
          <button
            className='tertiary-action'
            type='button'
            onClick={() => saveCouponCodes()}
            disabled={!isUploaded}
          >
            {t('Save')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadCouponCodesModal;
