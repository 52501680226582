import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { browserHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { modalActions, uiActions } from '../../../actions';
import { t } from '../../../system/ui';
import SaveAsTemplateModal from '../modals/SaveAsTemplateModal';
import {
  EmailBuilder as EmailBuilderServices,
  EmailMergeTag as EmailMergeTagService,
} from '../services/emailServices';
import { minifyTemplateHtml } from '../utils/minifyTemplateHtml';
import { emailBuilderReducer } from './reducer/emailBuilderReducer';
import emailBuilderDefaultState from './reducer/initialState';
import { isLoading, isLoaded } from '../../../actions/ui';

const WithEmailBuilder = WrappedComponent => {
  return ({ params: { id, mode }, location }, props) => {
    const [state, dispatch] = useReducer(
      emailBuilderReducer,
      emailBuilderDefaultState,
      () => emailBuilderDefaultState,
    );

    const [currentTemplateName, setCurrentTemplateName] = useState('');

    const emailEditorRef = useRef(null);

    const firstRender = useRef(true);

    const isDesign = !!location?.state?.isFork;

    const exportHtml = () => {
      return emailEditorRef?.current?.editor?.exportHtml(({ html }) => {
        const element = document.createElement('a');
        const file = new Blob([minifyTemplateHtml(html).result], {
          type: 'text/html',
        });
        element.href = URL.createObjectURL(file);
        element.download = 'email.html';
        document.body.appendChild(element);
        element.click();
      });
    };

    const saveAs = (withModal = false, saveType = '') => {
      return emailEditorRef?.current?.editor?.exportHtml(
        ({ design, html }) => {
          let templateName = currentTemplateName;
          if (mode !== 'edit') {
            templateName = isDesign
              ? `${currentTemplateName} - ${uuidv4()}`
              : '';
          }
          const newServerSideQuery = {
            templateName,
            templateJson: design,
            templateHtml: minifyTemplateHtml(html).result,
            templatePreviewImageUrlForPc: '',
            templatePreviewImageUrlForTablet: '',
            templatePreviewImageUrlForMobile: '',
            isFork: saveType === 'continue',
          };

          dispatch({
            type: 'SET_EMAIL_BUILDER_SERVER_SIDE_QUERY',
            payload: {
              ...state.serverSideQuery,
              ...newServerSideQuery,
            },
          });

          if (withModal) {
            isLoading();
            emailEditorRef?.current?.editor?.exportImage(data => {
              newServerSideQuery.templatePreviewImageUrlForPc = data.url;
              isLoaded();
              modalActions.openModal({
                title: `${t('Save as Template')}`,
                content: () => (
                  <SaveAsTemplateModal
                    campaignInfo={newServerSideQuery}
                    templateId={mode === 'edit' ? id : null}
                    mode={mode}
                    saveType={saveType}
                    fromPathName={location?.state?.campaignPathname || ''}
                    isCollection={location?.state?.isCollection || false}
                  />
                ),
                className:
                  'save-as-template-modal dashboard-campaign-details-modal',
              });
            });
          } else if (!withModal && saveType === 'continue') {
            isLoading();
            if (mode === 'create') {
              EmailBuilderServices.create({
                body: {
                  ...newServerSideQuery,
                },
              }).then(data => {
                isLoaded();
                const notificationContent = () => (
                  <div>{t('Template created successfully.')}</div>
                );
                uiActions.showNotification({ content: notificationContent });
                browserHistory.push({
                  pathname: location?.state?.campaignPathname || '',
                  state: {
                    campaign: location?.state?.campaign
                      ? location?.state?.campaign
                      : null,
                    templateName: `${newServerSideQuery?.templateName}` || '',
                    templateId: `${data.id}` || '',
                  },
                });
              });
            } else {
              emailEditorRef?.current?.editor?.exportImage(data => {
                newServerSideQuery.templatePreviewImageUrlForPc = data.url;
                EmailBuilderServices.updateWithInsideId({
                  body: {
                    ...newServerSideQuery,
                    id,
                  },
                }).then(() => {
                  isLoaded();
                  const notificationContent = () => (
                    <div>{t('Template updated successfully.')}</div>
                  );
                  uiActions.showNotification({ content: notificationContent });
                  browserHistory.push({
                    pathname: location?.state?.campaignPathname || '',
                    state: {
                      templateName: newServerSideQuery?.templateName || '',
                    },
                  });
                });
              });
            }
          }
        },
        {
          minify: false,
          cleanup: true,
          fullPage: true,
          customJS: [],
        },
      );
    };

    const onLoad = useCallback(
      template => {
        const bodyValues = {
          contentWidth: template?.body?.values?.contentWidth || 650,
          backgroundColor:
            template?.body?.values?.backgroundColor || 'transparent',
          padding: template?.body?.values?.padding || 0,
          preheaderText: '{{campaign:previewText}}',
        };

        emailEditorRef?.current?.editor?.loadDesign(template);
        emailEditorRef?.current?.editor?.setBodyValues(bodyValues);

        return '';
      },
      [mode],
    );

    const onReady = () => {
      if (emailEditorRef?.current?.editor) {
        if (!state.isEditorReady) {
          dispatch({
            type: 'SET_EMAIL_BUILDER_IS_EDITOR_READY',
            payload: true,
          });
        }
      }
    };

    const getEmailTemplateById = (templateId = '') => {
      let query = '';
      let queryId;
      if (location?.state?.isCollection) {
        query = 'collection=true';
      }
      if (templateId !== '') {
        queryId = templateId;
      } else {
        queryId = id;
      }
      EmailBuilderServices.get({ id: queryId, query }).then(
        ({ templateJson, templateName }) => {
          setCurrentTemplateName(templateName);
          dispatch({
            type: 'SET_EMAIL_BUILDER_SERVER_SIDE_QUERY',
            payload: {
              ...state.serverSideQuery,
              templateName,
            },
          });
          dispatch({
            type: 'SET_EMAIL_BUILDER_FETCHED_TEMPLATE',
            payload: {
              isFetched: true,
              templateJson,
            },
          });
        },
      );
    };

    useEffect(() => {
      EmailMergeTagService.getAll().then(({ mergeTags }) => {
        if (mergeTags) {
          return emailEditorRef?.current?.editor?.setMergeTags(mergeTags);
        }
      });

      if (mode === 'edit' && id) {
        return getEmailTemplateById();
      } else if (mode === 'create' && isDesign) {
        return getEmailTemplateById(location.state.templateId);
      }
      onLoad();

      return onReady();
    }, [mode, id, emailBuilderDefaultState.editorOptions]);

    useEffect(() => {
      if (firstRender.current) {
        firstRender.current = false;
      }
      saveAs(false);
    }, [state.serverSideQuery.templateHtml]);

    useEffect(() => {
      if (state.fetchedTemplate.isFetched && state.isEditorReady) {
        onLoad(state.fetchedTemplate.templateJson);
      }
      onLoad();
    }, [state.fetchedTemplate.isFetched, state.isEditorReady]);

    return (
      <WrappedComponent
        {...props}
        id={id}
        mode={mode}
        location={location}
        onReady={onReady}
        isDesign={isDesign}
        saveAs={saveAs}
        emailEditorRef={emailEditorRef}
        exportHtml={() => exportHtml()}
        isNavDisabled={!emailEditorRef?.current?.loaded}
      />
    );
  };
};

export default WithEmailBuilder;
