/*eslint react/display-name: "off"*/

"use strict";

import React from "react";
import {t} from "../system/ui";

export const external_de = {
  "Segmentify Performance": "Personalization Leistung",
  NoActiveSearchCampaigns: () => {
    return (
      <div>
        <p>
          Es ist sehr frustrierend für Besucher, wenn eine Suche nicht die
          Artikel zu Tage fördert, nach denen er sucht. Bounce Raten sind
          daherauf Suchergebnisseiten besonders hoch. Mit Hilfe von den
          Personalization Empfehlungswidgets können Sie auch auf der
          Suchergebnisseite Ihren Besuchern gezielt personalisierte
          Produktempfehlungen präsentieren, um so die Wahrscheinlichkeit eines
          Bounces zu verringern und Besucher zurückzuholen.
        </p>
        <p>
          Bitte wählen Sie unten einen Widgettyp aus, um{" "}
          <strong>
            ein neues Empfehlungswidget zur Suchergebnisseite hinzuzufügen.
          </strong>
        </p>
      </div>
    );
  },
  NoActive404Campaigns: () => {
    return (
      <div>
        <p>
          Wenn Sie eine URL aus Ihrem Onlineshop entfernen wird diese
          üblicherweise nicht sofort auch aus Suchmaschinen und externen Links
          entfernt. Klickt nun einer Ihrer Besucher auf diese URL, so findet er
          sich auf einer 404 Seite wieder - ohne Navigation. Dieser Besucher
          wird Ihre Seite sofort verlassen. Mit Personalization können Sie selbst auf
          der 404 Seite Produktempfehlungen ausspielen, um so Besucher zu Ihrem
          Shop zurückzuführen.
        </p>
        <p>
          PBitte wählen Sie unten einen Widgettyp aus, um{" "}
          <strong>
            ein neues Empfehlungswidget zur 404 Seite hinzuzufügen.
          </strong>
        </p>
      </div>
    );
  },
  NoActiveCustomCampaigns: () => {
    return (
      <div>
        <p>
          Wenn Sie Kampagnen auf noch nicht in Personalization definierten Seiten
          fahren möchten (z.B. Nutzerkontoseite, Check-Out Seite, etc.), so
          können Sie diese hier mit Empfehlungswidgets versehen.
        </p>
        <p>
          Bitte wählen Sie unten einen Widgettyp aus, um{" "}
          <strong>
            ein neues Empfehlungswidget zu einer individuellen Seite
            hinzuzufügen.
          </strong>
        </p>
      </div>
    );
  },
  Segmentify: "Personalization",
  "Number Of Clicks By Segmentify Recommendation Widgets":
    "Anzahl von Klicks durch Personalization Empfehlungswidgets",
  "Number Of Add To Basket Operations By Segmentify Recommendation Widgets":
    "Anzahl von zum Warenkorb hinzugefügt durch Personalization Empfehlungswidgets",
  "Number Of Purchases By Segmentify Recommendation Widgets":
    "Anzahl von Käufen durch Personalization Empfehlungswidgets",
  "Amount Of Purchases By Segmentify Recommendation Widgets":
    "Wert gekaufter Produkte durch Personalization Empfehlungswidgets",
  "Customer Removal Tool Disclaimer": () => {
    return (
      <p>
        All historical personal information related to given email addresses
        will be deleted within the next 24 hours. When the removal process is
        finished, you will receive an email.
        <br />
        Please note that the removal process is permanent and irreversible, upon
        its completion Personalization can't recover any data.
      </p>
    );
  },
  "Let's Integrate Segmentify": "Personalization Integrieren",
  "integration-script-info":
    "Die Personalization Integration ist sehr einfach. Alles was Sie tun müssen ist die folgende Zeile JavaScript Code auf alle Seiten Ihrer Webseite ganz am Ende des HEAD Blocks einzufügen. Falls vorhanden, können Sie hierfür auch Ihren Tagmanager nutzen.",
  IntegrationZendDesk: () => {
    return (
      <span>
        Folgen Sie den Anweisungen auf{" "}
        <a href="https://segmentify.zendesk.com/hc/en-us" target="_blank">
          Personalization Customer Success Center
        </a>{" "}
        für die Integration.
      </span>
    );
  },
  StartChat: var1 => {
    return (
      <span>
        Das Personalization Customer Success Team steht Ihnen zur Seite, um Ihnen bei
        der Integration zu helfen. <a>Jetzt einen Chat starten.</a>
      </span>
    );
  },
  "Choose what type recommendation features you want to use from Segmentify":
    "Wählen Sie aus welche Art Empfehlungen Sie von Personalization nutzen möchten.",
  Personalization: "Personalisierung",
  ShopifyWarning:
    "Sie haben bereits die Personalization App installiert. Wir beraten Sie bei der Verwendung der Personalization App in Ihrem Shopify Store Verwaltungsbereich.",
  PrestashopWarning:
    "Sie haben bereits das Personalization Plug-in installiert. Wir beraten Sie bei der Verwendung des Personalization Add-on in Ihrem Prestashop Verwaltungsbereich.",
  MagentoWarning:
    "Sie haben bereits das Personalization Plug-in installiert. Wir beraten Sie bei der Verwendung des Personalization Add-on in Ihrem Magento Verwaltungsbereich.",
  "Segmentify Graph": "Personalization Grafik",
  "Custom Segmentify integration requires two important values.":
    "Für die personalisierte Personalization Integration sind zwei wichtige Werte notwendig",
  PrivacyPolicyCta:
    "Avez-vous besoin d'ajuster votre politique en matière de cookies et de confidentialité pour utiliser Personalization ?",
  "The percentage of products that have been purchased after being clicked in a Segmentify Recommendation widget.":
    "Der prozentuale Anteil an Produkten, die gekauft wurden nachdem Sie in einem Personalization Empfehlungswidget angeklickt wurden.",
  "You should make at least one selection from Segmentify Intelligent or Static algorithms":
    "You should make at least one selection from Personalization Intelligent or Static algorithms",
  Widget_Desc:
    "Create personal recommendation campaigns with Personalization's intelligent and static algorithms.",
  Promotion_Desc:
    "Personalize the Special Offers you display in your website with Personalization's Promotion campaigns.",
  "Overall Segmentify Performance":"Overall Personalization Performance",
  "Revenue Generated by Segmentify": "Generated by Personalization",
  "Segmentify’s Revenue Contribution": "of all revenue via Personalization",
  "Products Sold via Segmentify":"products via Personalization",
  "Select from Segmentify Intelligent Recommendation Types to create your personalized recommendation campaigns" :
      "Select from Personalization Intelligent Recommendation Types to create your personalized recommendation campaigns",
  "Select from Segmentify Static Recommendation Types to create your personalized recommendation campaigns"
      : "Select from Personalization Static Recommendation Types to create your personalized recommendation campaigns",
  "Select from Segmentify Promotion Types to create your personalized recommendation campaigns"
      : "Select from Personalization Promotion Types to create your personalized recommendation campaigns",
  "Before_Search_Input" : "Attract your customers with Personalization's intelligent algorithms even before they make a search."
};

export default external_de;
