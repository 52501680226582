import React, { useState, useEffect, useRef } from 'react';
import ReactHighcharts from 'react-highcharts';
import FunnelChart from 'highcharts-funnel';
import { t } from '../../../../system/ui';
import { setTitle } from '../../../../system/document';
import stats from '../../../../modules/stats';
import { localeString, toLocaleStringDigits } from '../../../../system/string';
import { Dropdown } from '../../../../components/sfy-components/module-exports';
import { createChart } from '../../../../components/search/insights/utils/chart-utils';
import {
  emailMarketingAnalyticsPieChartConfig,
  statTypes,
  statisticsOptions,
  userChartData,
} from './constants/constant';

FunnelChart(ReactHighcharts.Highcharts);

const EmailMarketingAnalytics = () => {
  const [
    emailMarketingAnalyticsStats,
    setEmailMarketingAnalyticsStats,
  ] = useState({});
  const [statisticsItem, setStatisticsItem] = useState('');
  const [userConfig, setUserConfig] = useState({});
  const chartRef = useRef('totalUsers');

  const setPieChartConfig = (unsubscriptions, activeUsers, bounceBacks) => {
    userChartData.data = [
      { name: 'Unsubscriptions', count: unsubscriptions },
      { name: 'Reachable Users', count: activeUsers },
      { name: 'Bouncebacks', count: bounceBacks },
    ];

    return createChart(userChartData, emailMarketingAnalyticsPieChartConfig);
  };

  useEffect(() => {
    const config = setPieChartConfig(
      emailMarketingAnalyticsStats.unSubscriptions,
      emailMarketingAnalyticsStats.activeUsers,
      emailMarketingAnalyticsStats.bounceBacks,
    );
    setUserConfig(config);
  }, [
    emailMarketingAnalyticsStats.unSubscriptions,
    emailMarketingAnalyticsStats.activeUsers,
    emailMarketingAnalyticsStats.bounceBacks,
  ]);

  useEffect(() => {
    setTitle(t('Email Marketing Analytics'));
    stats.getEmailAnalytics(allStats => {
      const activeUsers =
        allStats.totalUsers - allStats.unSubscriptions - allStats.bounceBacks;
      setEmailMarketingAnalyticsStats({
        ...allStats,
        activeUsers,
      });
    });
    setStatisticsItem(statisticsOptions[0].value);
  }, []);

  const onDropdownChange = e => {
    setStatisticsItem(e);
  };

  return (
    <>
      <div style={{ width: '30%', minWidth: '400px' }}>
        <Dropdown
          value={statisticsItem}
          options={statisticsOptions}
          editCampaign={onDropdownChange}
        />
      </div>
      <div className='page-content-block'>
        <div className='widget-stat-list widget-report'>
          <div className='widget-stats'>
            {Object.keys(emailMarketingAnalyticsStats).map((item, index) => {
              const stat = emailMarketingAnalyticsStats[item];
              const type = statTypes[item];
              const mainTitle = type.name;
              const color = type.textType;
              const className = `widget-stat stat-${color}`;
              const originalStatVal = stat;

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={className} key={`${item}-${index}`}>
                  <h4 className='widget-stat-title'>{t(mainTitle)}</h4>
                  {item !== 'widget' && (
                    <p
                      className='widget-stat-count'
                      title={toLocaleStringDigits(originalStatVal)}
                    >
                      {localeString(originalStatVal)}
                    </p>
                  )}
                  {item !== 'totalUsers' && (
                    <p style={{ fontWeight: 600 }}>
                      (%
                      {localeString(
                        emailMarketingAnalyticsStats.totalUsers === 0
                          ? 0
                          : (originalStatVal /
                              emailMarketingAnalyticsStats.totalUsers) *
                              100,
                      )}
                      )
                    </p>
                  )}
                </div>
              );
            })}
            <div className='widget-stat' key='stat-chart-5'>
              <ReactHighcharts config={userConfig} ref={chartRef} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailMarketingAnalytics;
