/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconWand extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#7a7a7c"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <rect
          x="6.2"
          y="267.6"
          width="346.5"
          height="129.9"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -182.6017 224.2922)"
        />
        <rect
          x="341.5"
          y="87.6"
          width="104.6"
          height="61.2"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 31.7766 313.0797)"
        />
        <rect x="409.1" y="225.4" width="91.9" height="61.3" />
        <rect
          x="366.1"
          y="350.8"
          width="61.2"
          height="91.9"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -164.3418 396.7467)"
        />
        <rect x="225.4" y="11" width="61.3" height="91.9" />
        <rect
          x="84.6"
          y="69.3"
          width="61.2"
          height="91.9"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -47.7269 115.2427)"
        />
      </svg>
    );
  }
}

export default IconWand;
