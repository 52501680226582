import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoaderPlaceholder from '../components/widget/loader-placeholder';
import { uiActions } from '../actions';
import { t } from '../system/ui';

import Icons from '../components/icons';
import { setTitle } from '../system/document';
import { hasModule } from '../modules/auth/user';
import { searchIntegrationInfo } from '../modules/trendify/page';
import {
  getSearch,
  moveCampaignToTest,
  moveCampaignToLive,
} from '../modules/search/ajax';
import SearchSelection from '../components/search/search-campaign-selection';

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      _searchCampaign: [],
      searchCampaign: [],
      status: false,
    };
    this.clickHandler = this.clickHandler.bind(this);
    this.moveLive = this.moveLive.bind(this);
    this.moveTest = this.moveTest.bind(this);
  }

  componentDidMount() {
    uiActions.hideBreadcrumb();
    setTitle(t('After Search Input'));
    this.getSearch();
  }

  getSearch() {
    searchIntegrationInfo();
    if (hasModule('search')) {
      uiActions.isLoading();
      let searchCamp = [];
      getSearch(null, response => {
        if (response) {
          searchCamp = response;
          this.setState(
            {
              _searchCampaign: searchCamp,
              searchCampaign: searchCamp,
              isLoading: false,
              status: searchCamp.status === 'ACTIVE',
            },
            () => {
              uiActions.isLoaded();
            },
          );
        } else {
          this.setState(
            {
              isLoading: false,
              // eslint-disable-next-line react/no-access-state-in-setstate
              status: this.state.searchCampaign.status === 'ACTIVE',
            },
            () => {
              uiActions.isLoaded();
            },
          );
        }
      });
    } else {
      this.setState(
        {
          isLoading: false,
          campaignsInited: true,
          status: false,
        },
        () => {
          uiActions.isLoaded();
        },
      );
    }
  }

  moveTest() {
    moveCampaignToTest().then(response => {
      if (response) {
        const note = () => (
          <div>Search Box campaign is moved to drafts successfully.</div>
        );
        this.setState({
          // eslint-disable-next-line react/no-access-state-in-setstate
          status: !this.state.status,
        });
        uiActions.showNotification({
          content: note,
        });
      }
    });
  }

  clickHandler() {
    if (!this.state.status) {
      uiActions.confirmationDialog({
        title: 'Are you sure to take the campaign to live mode?',
        content: '',
        onConfirm: this.moveLive,
      });
    } else {
      uiActions.confirmationDialog({
        title: 'Are you sure to take the campaign to test mode?',
        content: '',
        onConfirm: this.moveTest,
      });
    }
  }

  moveLive() {
    moveCampaignToLive().then(response => {
      if (response) {
        const note = () => (
          <div>Search Box campaign is moved to live successfully.</div>
        );
        this.setState({
          // eslint-disable-next-line react/no-access-state-in-setstate
          status: !this.state.status,
        });
        uiActions.showNotification({
          content: note,
        });
      }
    });
  }

  render() {
    if (!hasModule('search')) {
      return (
        <div>
          <div className='promo promo--centered search-discover-dashboard'>
            <Icons name='search' width='60' height='60' color='#fe714f' />
            <p className='promo-text' style={{ color: 'black' }}>
              {t('SearchPromo')}
            </p>
            <a
              href='https://www.segmentify.com/solutions/personalized-search-recommendation/'
              target='_blank'
              className='secondary-action'
              style={{ backgroundColor: '#fe714f' }}
              rel='noreferrer'
            >
              {t('Discover')}
            </a>
          </div>
        </div>
      );
    }
    if (this.state.isLoading) {
      // show placeholder
      return (
        <div style={{ marginTop: '80px' }}>
          <LoaderPlaceholder />
        </div>
      );
    }
    return (
      <div>
        <SearchSelection
          {...this.state}
          clickHandler={this.clickHandler}
          campaign={this.state.searchCampaign}
        />
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  user: store.user.user,
  status: store.campaigns.status,
});

export default connect(MapStatesToProps)(Search);
