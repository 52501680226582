/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import PropTypes from "prop-types";

class CheckboxGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || this.props.defaultValue || []
    };

    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._isControlledComponent = this._isControlledComponent.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.value) {
      this.setState({
        value: newProps.value
      });
    }
  }

  getValue() {
    return this.state.value;
  }

  _isControlledComponent() {
    return !!this.props.value;
  }

  _onCheckboxChange(checkboxValue, event) {
    let newValue;

    if (event.target.checked) {
      newValue = this.state.value.concat(checkboxValue);
    } else {
      newValue = this.state.value.filter(v => v !== checkboxValue);
    }

    if (!this._isControlledComponent()) {
      this.setState({ value: newValue });
    } else {
      this.setState({ value: this.props.value });
    }

    if (typeof this.props.onChange === "function") {
      this.props.onChange(newValue);
    }
  }

  getChildContext() {
    return {
      checkboxGroup: {
        name: this.props.name,
        checkedValues: this.state.value,
        onChange: this._onCheckboxChange
      }
    };
  }

  render() {
    const {
      Component,
      name,
      value,
      checkedValues,
      onChange,
      children,
      ...rest
    } = this.props;
    return <Component {...rest}>{children}</Component>;
  }
}

CheckboxGroup.propTypes = {
  name: PropTypes.string,
  value: PropTypes.array,
  defaultValue: PropTypes.array,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  Component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object
  ])
};

CheckboxGroup.childContextTypes = {
  checkboxGroup: PropTypes.object
};

CheckboxGroup.defaultProps = {
  Component: "div"
};

export default CheckboxGroup;
