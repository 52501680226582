import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const customerPictureIcon = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M36.9877 29.1671C39.5752 29.1671 41.6727 31.2646 41.6727 33.8523V35.051C41.6727 36.9142 41.0069 38.7158 39.7954 40.1312C36.5258 43.951 31.5527 45.836 24.9998 45.836C18.4458 45.836 13.475 43.9504 10.2119 40.1289C9.00404 38.7146 8.34045 36.9156 8.34045 35.0556V33.8523C8.34045 31.2646 10.4381 29.1671 13.0256 29.1671H36.9877ZM36.9877 32.2921H13.0256C12.164 32.2921 11.4655 32.9906 11.4655 33.8523V35.0556C11.4655 36.1717 11.8636 37.251 12.5883 38.0996C15.1993 41.1573 19.2949 42.711 24.9998 42.711C30.7046 42.711 34.8037 41.1573 37.4215 38.0991C38.1483 37.2498 38.5477 36.1687 38.5477 35.051V33.8523C38.5477 32.9906 37.8492 32.2921 36.9877 32.2921ZM24.9998 4.17682C30.7527 4.17682 35.4165 8.84053 35.4165 14.5935C35.4165 20.3465 30.7527 25.0102 24.9998 25.0102C19.2468 25.0102 14.5831 20.3465 14.5831 14.5935C14.5831 8.84053 19.2468 4.17682 24.9998 4.17682ZM24.9998 7.30182C20.9727 7.30182 17.7081 10.5664 17.7081 14.5935C17.7081 18.6206 20.9727 21.8852 24.9998 21.8852C29.0269 21.8852 32.2915 18.6206 32.2915 14.5935C32.2915 10.5664 29.0269 7.30182 24.9998 7.30182Z'
        fill={color}
      />
    </svg>
  );
};

customerPictureIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

customerPictureIcon.defaultProps = {
  width: '50px',
  height: '50px',
  color: '#D8D8D8',
};

export default customerPictureIcon;
