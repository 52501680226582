import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { searchRbsCategories } from '../../modules/category/ajax';
import { operatorObjToArray } from './utils/utils';
import { decodeHtml } from '../../system/string';
import { t } from '../../system/ui';

import {
  listOperators,
  operatorColumns,
} from '../../constants/datamaps/rule-based-segmentation';
import { ErrorText } from './module-exports';

const defaultSelectData = {
  value: 'any',
  label: 'any category',
  title: 'any category',
};
/**
 * @name CriteriaFilterCategory
 * @description Component that renders the category filter
 * @param {object} criteriaData - The criteria data
 * @param {string} filterType - The filter type
 * @param {array} statements - The statements
 * @param {function} callbackFunc - The callback function
 * @returns {JSX.Element}
 */
const criteriaFilterCategory = ({
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const defaultFilterData = {
    dataType: criteriaData?.dataType,
    column: operatorColumns.categoryOperator,
    operator: listOperators.is.value,
    value: 'any',
  };

  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState('');
  const [isCustomText, setIsCustomText] = useState();

  if (statements?.[filterType].length === 0) {
    callbackFunc(filterType, [defaultFilterData]);
  }

  useEffect(() => {
    const statement = statements?.[filterType]?.[0];

    setSelectedOperator(statement?.operator || defaultFilterData.operator);

    const defaultCategory = statement
      ? statement?.value
      : defaultFilterData.value;

    setSelectedCategory(defaultCategory);
    if (defaultCategory) {
      if (defaultCategory === 'any') {
        setCategories([defaultSelectData]);
      } else {
        setCategories([
          defaultSelectData,
          {
            value: defaultCategory,
            title: decodeHtml(defaultCategory),
            label: decodeHtml(defaultCategory),
          },
        ]);
      }
    } else {
      setCategories([defaultSelectData]);
    }
  }, [statements]);

  const triggerCallbackFunc = (val, type) => {
    const columnVal = operatorColumns.categoryOperator;
    const operatorVal = type === 'operator' ? val?.value : selectedOperator;
    let categoryVal = type === 'category' ? val?.value || '' : selectedCategory;
    if (
      [listOperators.contains.value, listOperators.containsNot.value].includes(
        selectedOperator,
      ) &&
      [listOperators.is.value, listOperators.isNot.value].includes(
        operatorVal,
      ) &&
      isCustomText
    ) {
      categoryVal = '';
    }

    const filterData = [
      {
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator: operatorVal,
        value: categoryVal,
      },
    ];

    callbackFunc(filterType, filterData);
  };

  const onChange = (selectedValue, type) => {
    switch (type) {
      case 'category':
        setSelectedCategory(selectedValue?.value || '');
        setCategories([
          defaultSelectData,
          {
            value: selectedValue?.value || '',
            title: decodeHtml(selectedValue?.value || ''),
            label: decodeHtml(selectedValue?.value || ''),
          },
        ]);
        // eslint-disable-next-line no-prototype-builtins
        setIsCustomText(!selectedValue?.hasOwnProperty('title'));
        break;
      case 'operator':
        setSelectedOperator(selectedValue?.value);
        break;
      default:
        break;
    }

    triggerCallbackFunc(selectedValue, type);
  };

  const onInputChange = value => {
    if (value.length > 2) {
      searchRbsCategories(value, response => {
        const categoryOptions = [];
        categoryOptions.push(defaultSelectData);
        if (response) {
          response.forEach(category => {
            categoryOptions.push({
              value: decodeHtml(category),
              title: decodeHtml(category),
              label: decodeHtml(category),
            });
          });
        }
        setCategories(categoryOptions);
      });
    } else if (selectedCategory) {
      if (selectedCategory === 'any') {
        setCategories([defaultSelectData]);
      } else {
        setCategories([
          {
            value: selectedCategory,
            title: decodeHtml(selectedCategory),
            label: decodeHtml(selectedCategory),
          },
        ]);
      }
    } else {
      setCategories([defaultSelectData]);
    }
    return value;
  };

  const ConditionalSelect = [
    listOperators.contains.value,
    listOperators.containsNot.value,
  ].includes(selectedOperator)
    ? Select.Creatable
    : Select;

  return (
    <div className='rbs-filter-field'>
      <div className='filter-operator'>
        <span className='filter-operator-title'>Operator*</span>
        <Select
          value={selectedOperator}
          options={operatorObjToArray(listOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          onChange={e => onChange(e, 'operator')}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      <div className='filter-connector' />
      <div className='filter-operator'>
        <span className='filter-operator-title'>Category*</span>
        <ConditionalSelect
          value={selectedCategory}
          options={categories}
          name='category'
          clearable={false}
          searchable
          onChange={e => onChange(e, 'category')}
          onInputChange={onInputChange}
          className={`criteria-field one-whole ${
            selectedCategory ? '' : 'has-error'
          }`}
          openOnClick={false}
          placeholder={t('Select or text')}
        />
        {!selectedCategory && <ErrorText />}
      </div>
    </div>
  );
};

export default criteriaFilterCategory;
