/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { t } from "../../../system/ui";

import { uiActions, wizardActions } from "../../../actions";

class GoTestButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: false,
      isSubmitting: false
    };

    this.validate = this.validate.bind(this);
  }

  validate(trigger) {
    let errors = document.querySelectorAll(".has-error");
    let visibleErrors = 0;
    errors.forEach(error => {
      if (error.offsetWidth > 0  || this.props.includeHiddens) {
        visibleErrors++;
      }
    });
    if (this.props.validate === true && visibleErrors === 0) {
      if (this.props.editForm) {
        uiActions.confirmationDialog({
          title: this.props.isPush
            ? "Update Campaign on Draft Mode?"
            : "Update Campaign on Test Mode?",
          content: this.props.isPush
            ? "This action will immediately effect campaign status."
            : null,
          onConfirm: wizardActions.saveTest
        });
      } else {
        uiActions.confirmationDialog({
          onConfirm: wizardActions.saveTest
        });
      }
    } else {
      uiActions.isValidating();
      this.props.includeHiddens &&
      !trigger &&
      uiActions.validatingTriggered();
      if (!this.state.clicked) {
        this.setState({ clicked: true }, () => this.validate(true));
      }
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      isSubmitting: newProps.save === "test"
    });
  }

  render() {
    return (
      <a
        className={classNames("button gotest-action one-whole", {
          "gotest-action--disabled": this.state.isSubmitting
        })}
        onClick={() => this.validate(false)}
      >
        {this.props.isPush ? "Go Draft" : t("Go Test")}
        {this.state.isSubmitting && (
          <span className="page-progress page-progress--button" />
        )}
      </a>
    );
  }
}

const MapStatesToProps = store => ({
  validate: store.ui.validate,
  editForm: store.wizard.editForm,
  save: store.wizard.save
});

export default connect(MapStatesToProps)(GoTestButton);
