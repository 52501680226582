import React, { useState, useEffect } from 'react';
import './CountSettings.scss';
import {
  isValidStartDate,
  reformatDateByTimeZone,
} from '../../../../system/date';
import { Calendar } from '../../../fields';
import { t } from '../../../../system/ui';

/**
 * @name CountSettings component
 * @description CountSettings component for Countdown widget
 * @param {function} editCampaign - editCampaign function from parent component (Addoredit) to set the campaign state
 * @param {JSX.Element | JSX.Element[]} children
 * @param {Object} activeCountdownCampaign - campaign object from  context
 * @param {string} mode - mode of the component (add or edit)
 * @return {JSX.Element} - CountSettings component
 * @constructor
 */

const CountSettings = ({ activeCountdownCampaign, editCampaign, mode }) => {
  const [showOnWeb, setShowOnWeb] = useState([
    {
      type: 'DAY',
      activate: false,
      text: 'days',
    },
    {
      type: 'HOUR',
      activate: false,
      text: 'hours',
    },
    {
      type: 'MINUTE',
      activate: false,
      text: 'minute',
    },
    {
      type: 'SECOND',
      activate: false,
      text: 'sec',
    },
  ]);

  const handleChangeInput = (val, type) => {
    editCampaign(type, val);
  };

  const handleShowOnWebsite = (type, value, event) => {
    const data = [...showOnWeb];
    if (event.target.name === 'activate') {
      data.find(item => item.type === type)[event.target.name] = value;
    } else {
      data.find(item => item.type === type)[event.target.name] =
        event.target.value;
    }

    setShowOnWeb(data);
    editCampaign('showOnWebsite', [...showOnWeb]);
  };

  useEffect(() => {
    if (mode === 'edit' || mode === 'duplicate') {
      setShowOnWeb(activeCountdownCampaign?.showOnWebsite);
    }
  }, [mode, activeCountdownCampaign]);

  useEffect(() => {
    if (!activeCountdownCampaign?.endDate) {
      const endDateDelayMinutes = 15;
      const afterSomeMinutes = new Date(
        new Date().getTime() + endDateDelayMinutes * 60000,
      );
      handleChangeInput(afterSomeMinutes, 'endDate');
    }
  }, []);

  return (
    <div className='CountSettings'>
      <div className='CountSettings__content'>
        <div className='CountSettings__title'>{t('Countdown Settings*')}</div>
        <span className='CountSettings__description'>
          {t('Please select the time range that will be.')}
        </span>
        <div className='CountSettings__time'>
          <div className='CountSettings__endTime'>
            <label htmlFor='endTime'>End Date-Time</label>
            <Calendar
              className='item-stacked one-whole'
              isValidDate={isValidStartDate}
              value={reformatDateByTimeZone(activeCountdownCampaign?.endDate)}
              onChange={e =>
                handleChangeInput(new Date(e).getTime(), 'endDate')
              }
              inputProps={{ className: 'datetime' }}
            />
          </div>
          <div className='CountSettings__timeSeperator'>
            <label htmlFor='timeSep'>
              {t('Seperator')} <span>(Ex. : , - , / )</span>
            </label>
            <input
              id='timeSep'
              name='seperator'
              type='text'
              value={activeCountdownCampaign?.seperator}
              placeholder=':'
              onChange={e => handleChangeInput(e.target.value, 'seperator')}
            />
          </div>
        </div>
      </div>
      <div className='CountSettings__webView'>
        <div className='CountSettings__webView__left'>
          {t('Show on website with :')}
        </div>
        <div className='CountSettings__webView__right'>
          <div className='CountSettings__day'>
            <div className='CountSettings__checkbox'>
              <label className='CountSettings__control' htmlFor='dayCheck'>
                <input
                  id='dayCheck'
                  name='activate'
                  value={showOnWeb?.[0]?.activate}
                  type='checkbox'
                  checked={showOnWeb?.[0]?.activate}
                  onChange={e =>
                    handleShowOnWebsite('DAY', !showOnWeb[0].activate, e)
                  }
                />
                {t('Day')}
              </label>
            </div>
            <div className='CountSettings__timeInput'>
              <label htmlFor='days'>{t('Text :')}</label>
              <input
                type='text'
                name='text'
                id='days'
                value={showOnWeb?.[0]?.text}
                placeholder='days'
                onChange={e => handleShowOnWebsite('DAY', '', e)}
              />
            </div>
          </div>
          <div className='CountSettings__hours'>
            <div className='CountSettings__checkbox'>
              <label className='CountSettings__control' htmlFor='hoursCheck'>
                <input
                  id='hoursCheck'
                  name='activate'
                  value={showOnWeb?.[1]?.activate}
                  checked={showOnWeb?.[1]?.activate}
                  type='checkbox'
                  onChange={e =>
                    handleShowOnWebsite('HOUR', !showOnWeb[1].activate, e)
                  }
                />
                {t('Hours')}
              </label>
            </div>
            <div className='CountSettings__timeInput'>
              <label htmlFor='hours'>{t('Text :')}</label>
              <input
                type='text'
                name='text'
                value={showOnWeb?.[1]?.text}
                id='hours'
                placeholder='hours'
                onChange={e => handleShowOnWebsite('HOUR', '', e)}
              />
            </div>
          </div>
          <div className='CountSettings__minute'>
            <div className='CountSettings__checkbox'>
              <label className='CountSettings__control' htmlFor='minuteCheck'>
                <input
                  id='minuteCheck'
                  name='activate'
                  value={showOnWeb?.[2]?.activate}
                  checked={showOnWeb?.[2]?.activate}
                  type='checkbox'
                  onChange={e =>
                    handleShowOnWebsite('MINUTE', !showOnWeb[2].activate, e)
                  }
                />
                {t('Minute')}
              </label>
            </div>
            <div className='CountSettings__timeInput'>
              <label htmlFor='minute'>{t('Text :')}</label>
              <input
                type='text'
                name='text'
                value={showOnWeb?.[2]?.text}
                id='minute'
                placeholder='minute'
                onChange={e => handleShowOnWebsite('MINUTE', '', e)}
              />
            </div>
          </div>
          <div className='CountSettings__second'>
            <div className='CountSettings__checkbox'>
              <label className='CountSettings__control' htmlFor='SecondCheck'>
                <input
                  id='SecondCheck'
                  name='activate'
                  value={showOnWeb?.[3]?.activate}
                  checked={showOnWeb?.[3]?.activate}
                  type='checkbox'
                  onChange={e =>
                    handleShowOnWebsite('SECOND', !showOnWeb[3].activate, e)
                  }
                />
                {t('Second')}
              </label>
            </div>
            <div className='CountSettings__timeInput'>
              <label htmlFor='second'>{t('Text :')}</label>
              <input
                type='text'
                name='text'
                value={showOnWeb?.[3]?.text}
                id='second'
                placeholder='minute'
                onChange={e => handleShowOnWebsite('SECOND', '', e)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountSettings;
