/**
 * Created by mehmetyurtar on 3/23/18.
 */
import React from "react";

class IconCrossCircle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 12"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
        fill={this.state.color}
      >
        <path
          d="M8.2,4.9c1.8,1.8,3.6,3.6,5.4,5.4c0.4,0.4,0.4,1,0.1,1.3c-0.4,0.4-0.9,0.4-1.3-0.1c-1.5-1.5-3-3-4.4-4.4
	c-0.3-0.3-0.5-0.3-0.8,0C5.7,8.6,4.2,10,2.7,11.5c-0.6,0.6-1.3,0.5-1.6-0.2c-0.2-0.5,0-0.8,0.3-1.1C3,8.7,4.4,7.3,5.9,5.8
	c0.3-0.3,0.6-0.6,1-0.9C7.3,4.4,7.7,4.4,8.2,4.9z"
        />
        <path d="M13.9,1L13.9,1c0,0.5-0.4,0.9-0.9,0.9H1.9C1.5,1.9,1.1,1.5,1.1,1v0c0-0.5,0.4-0.9,0.9-0.9h11.1C13.5,0.1,13.9,0.5,13.9,1z" />
      </svg>
    );
  }
}

export default IconCrossCircle;
