import { getRestEndPoint } from '../../../../system/api';
import { getApiKey } from '../../../../modules/auth/user';
import { $http } from '../../../../system/ajax';
import { getGandalfUrl } from '../../../../constants/config';
import { STLConvertForBackend } from '../../../../components/sfy-components/utils';

const getSTLList = async ({
  pageNumber = 1,
  payloadSize = 20,
  searchQueryString = '',
}) => {
  const params = `&pageNo=${pageNumber}&pageSize=${payloadSize}&keyword=${searchQueryString}`;
  const url = `${getRestEndPoint('stl/base')}?apiKey=${getApiKey()}${params}`;
  const response = await $http(url).get();
  return await response;
};

const getSTL = async ({ id }) => {
  const url = `${getRestEndPoint('stl/base')}/${id}?apiKey=${getApiKey()}`;
  const response = await $http(url).get();
  return await response;
};

const deleteSTL = async ({ id }) => {
  const url = `${getRestEndPoint('stl/base')}/${id}?apiKey=${getApiKey()}`;
  const response = await $http(url).delete();
  return await response;
};

const createSTL = async ({ bundleList, bundleGroupName }) => {
  const postData = STLConvertForBackend(bundleList, bundleGroupName, null);

  const url = `${getRestEndPoint('stl/base')}?apiKey=${getApiKey()}`;
  const response = await $http(url).post(postData, { type: 'json' });
  return await response;
};

const updateSTL = async ({ id, bundleList, bundleGroupName }) => {
  const postData = STLConvertForBackend(bundleList, bundleGroupName, id);

  const url = `${getRestEndPoint('stl/base')}/${id}?apiKey=${getApiKey()}`;
  const response = await $http(url).put(postData, { type: 'json' });
  return await response;
};

// getGandalfUrl(dataCenter)}category/bundle/${accountId}

const getSTLPreviewItemByCategory = async ({ accountId, data }) =>
  await $http(`${getRestEndPoint('stl/category')}/${accountId}`).post(data, {
    type: 'json',
  });

const uploadImportedFile = async file => {
  const url = `${getRestEndPoint('stl/import')}?apiKey=${getApiKey()}`;
  const formData = new FormData();
  formData.append('file', file);
  return await $http(url).post(formData, {
    type: 'form',
  });
};

export {
  getSTLList,
  getSTL,
  deleteSTL,
  createSTL,
  updateSTL,
  getSTLPreviewItemByCategory,
  uploadImportedFile,
};
