import React from 'react';
import {
  QueryClient,
  QueryClientProvider as SegmentifyProvider,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient();

/**
 * SegmentifyQueryProvider
 * @name SegmentifyQueryProvider - Server state manager for rest api calls
 * @param children - JSX element Array
 * @returns {JSX.Element}
 */
export const SegmentifyQueryProvider = ({ children }) => {
  return (
    <SegmentifyProvider client={queryClient}>
      <ReactQueryDevtools position='bottom-left' initialIsOpen={false} />
      {children}
    </SegmentifyProvider>
  );
};
