/**
 * @author Bilal Cinarli
 */
import { today, queryDate } from '../../system/date';
import { getDatesSchema } from '../trendify/utils';

const range = [today().subtract(6, 'd'), today()];

const lastSevenDaysDates = getDatesSchema(range, 'lastSevenDays');

/*
 * By default {rangeAlias} is always "lastSevenDays".
 * */
export const insightsQuery = {
  startDate: queryDate(range[0]),
  endDate: queryDate(range[1]),
  firstStartDate: queryDate(lastSevenDaysDates.firstStartDate),
  firstEndDate: queryDate(lastSevenDaysDates.firstEndDate),
  secondStartDate: queryDate(lastSevenDaysDates.secondStartDate),
  secondEndDate: queryDate(lastSevenDaysDates.secondEndDate),
  items: 10,
  maxPrice: 1000000000000,
  minPrice: 0,
  stock: 'all',
  category: '',
  brand: '',
  productId: '',
  device: 'all',
};

const weeklyDates = getDatesSchema(range, 'weekly');

export const insightsQueryWeek = {
  firstStartDate: queryDate(weeklyDates.firstStartDate),
  firstEndDate: queryDate(weeklyDates.firstEndDate),
  secondStartDate: queryDate(weeklyDates.secondStartDate),
  secondEndDate: queryDate(weeklyDates.secondEndDate),
  startDate: queryDate(range[0]),
  endDate: queryDate(range[1]),
  items: 10,
  maxPrice: 1000000000000,
  minPrice: 0,
  stock: 'all',
  category: '',
  brand: '',
  productId: '',
  device: 'all',
};
