import React from 'react';
import '../../Tabs.scss';
import classes from '../downloadFTP.module.scss';

export function FTPWrapper({ children }) {
  return (
    <div className='tabs'>
      <div className='tabs-container'>
        <div className='tabs-container__card'>
          <div className={classes.form}>{children}</div>
        </div>
      </div>
    </div>
  );
}
