// Components
import Homepage from './Homepage';
import Products from './Products';
import Playbooks from './Playbooks';
// import Insights from './Insights';
// import Chat from './Chat';
import EgsVideos from './EgsVideos';

// Icons
import HomepageIcon from './icons/HomepageIcon';
import ProductsIcon from './icons/ProductsIcon';
import PlaybooksIcon from './icons/PlaybooksIcon';
// import InsightsIcon from './icons/InsightsIcon';
// import ChatIcon from './icons/ChatIcon';
import EgsVideosIcon from './icons/EgsVideosIcon';
import AddClientForm from './components/AddClientForm/AddClientForm';
import TrainingSessionIcon from './icons/TrainingSessionIcon';

export const componentRoots = [
  {
    id: 1,
    title: 'Homepage',
    iconComponent: HomepageIcon,
    component: Homepage,
    tempUrl: '',
    isVisible: true,
    props: {},
  },
  {
    id: 2,
    title: 'Products',
    iconComponent: ProductsIcon,
    component: Products,
    tempUrl: 'https://www.segmentify.com/solutions',
    isVisible: true,
    props: {},
  },
  {
    id: 3,
    title: 'Playbooks',
    iconComponent: PlaybooksIcon,
    component: Playbooks,
    tempUrl: '',
    isVisible: true,
    props: {},
  },
  // {
  //   id: 4,
  //   title: 'Insights',
  //   iconComponent: InsightsIcon,
  //   component: Insights,
  //   tempUrl: '',
  // },
  // {
  //   id: 5,
  //   title: 'Chat',
  //   iconComponent: ChatIcon,
  //   component: Chat,
  //   tempUrl: '',
  // },
  {
    id: 6,
    title: 'EGS Videos',
    iconComponent: EgsVideosIcon,
    component: EgsVideos,
    tempUrl: 'https://www.segmentify.com/ecommerce-growth-show',
    isVisible: true,
    props: {},
  },
  {
    id: 7,
    title: 'Training Sessions',
    iconComponent: TrainingSessionIcon,
    component: EgsVideos,
    tempUrl:
      'https://segmentify.zohoshowtime.com/sessions/segmentify-sales-training--3345363989',
    isVisible: true,
    props: {},
  },
  {
    id: 99,
    title: 'Add Client Form',
    iconComponent: '',
    component: AddClientForm,
    tempUrl: HomepageIcon,
    isVisible: false,
    props: {
      formName: 'addClient',
    },
  },
];
