import { getSource } from '../index';

const SEGMENTIFY_TOKEN = 'SegmentifyAuthenticationToken';
const SEGMENTIFY_EXTERNAL_TOKEN = 'SegmentifyExternalAuthenticationToken';
const SEGMENTIFY_TOKEN_V3 = 'SegmentifyAuthenticationTokenV3';

export const get = name => {
  const re = new RegExp(`${name}=([^;]+)`);
  const value = re.exec(document.cookie);

  return value !== null ? decodeURI(value[1]) : null;
};

export const getDc = () => {
  return get('_sgf_dc');
};

export const select = () => {
  const source = getSource();
  if (source !== null && source === 'external_app')
    return SEGMENTIFY_EXTERNAL_TOKEN;

  const dc = getDc();
  if (dc === 'V3') {
    return SEGMENTIFY_TOKEN_V3;
  }
  return SEGMENTIFY_TOKEN;
};

export const set = (name, value, expires, path, domain, secure) => {
  const today = new Date();
  let parsedExpireTime;

  today.setTime(today.getTime());

  if (expires) {
    if (expires === 'halfAnHour') {
      parsedExpireTime = 1000 * 60 * 30;
    } else {
      parsedExpireTime = expires * 1000 * 60 * 60 * 24;
    }
  }

  const expireDate = new Date(today.getTime() + parsedExpireTime);

  document.cookie = `${name}=${encodeURI(value)}${
    expires ? `;expires=${expireDate.toGMTString()}` : ''
  }${path ? `;path=${path}` : ''}${domain ? `;domain=${domain}` : ''}${
    secure ? ';secure' : ''
  }`;
};

export const del = (name, path, domain) => {
  document.cookie = `${name}=${path ? `;path=${path}` : ''}${
    domain ? `;domain=${domain}` : ''
  };expires=Thu, 01-Jan-1970 00:00:01 GMT`;
};

export const setDc = name => {
  set('_sgf_dc', name, 'Session', '/', '.segmentify.com');
};

export const delDc = () => {
  let domain = '.segmentify.com';
  if (window.location.hostname.includes('localhost')) {
    domain = 'localhost';
  }
  del('_sgf_dc', '/', domain);
};

export const getDcList = () => {
  const dcs = [];
  if (get(SEGMENTIFY_TOKEN_V3)) {
    dcs.push({
      label: 'V3',
      value: 'V3',
    });
  }
  return dcs;
};

export default {
  set,
  get,
  del,
  select,
  setDc,
  getDc,
  delDc,
  getDcList,
};
