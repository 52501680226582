import api from '../../../system/api';
import { getApiKey, isSwitchAccount } from '../../auth/user';
import { $http } from '../../../system/ajax';
import { store } from '../../../store';
import { calculateRatio } from '../../../system/ratio';

export const initialRequestQueryParams = {
  lang: '',
  device: '',
  start: '',
  end: '',
  sort: '',
  reverse: false,
  limit: undefined,
};

export const getMostValuableProducts = (requestParameter, callback) => {
  const endPoint = api.getRestEndPoint('palantir/analytics/valuable');

  const transform = data => {
    return data.map(item => {
      return {
        conversionRate: calculateRatio(item.purchase, item.search),
        revenue: item.revenue,
        purchase: item.purchase,
        purchaseItem: item.purchaseItem,
        basketRate: calculateRatio(item.basketItem, item.search),
        ctr: calculateRatio(item.click, item.search),
        product: item.product,
      };
    });
  };
  callSearchAnalyticsApi(endPoint, requestParameter, transform, callback);
};

export const getNoResultSearchesSummary = (requestParameter, callback) => {
  const endPoint = api.getRestEndPoint('palantir/analytics/noresult');

  const transform = data => {
    return data.map(item => {
      return {
        query: item.query,
        count: item.count,
        noResultRatio: !requestParameter.total
          ? 0
          : calculateRatio(item.count, requestParameter.total),
      };
    });
  };
  callSearchAnalyticsApi(endPoint, requestParameter, transform, callback);
};

export const getPopularSearches = (requestParameter, callback) => {
  const endPoint = api.getRestEndPoint('palantir/analytics/popular');

  const transform = data => {
    return data.map(item => {
      return {
        query: item.query,
        search: item.search,
        revenue: item.revenue,
        ctr: calculateRatio(item.click, item.search),
        purchase: item.purchase,
        conversionRate: calculateRatio(item.purchase, item.search),
        perSearchValue: calculateRatio(item.revenue, item.search),
        product: item.bestProduct,
      };
    });
  };
  callSearchAnalyticsApi(endPoint, requestParameter, transform, callback);
};

export const getOverallTotalSearches = (requestParameter, callback) => {
  const endPoint = api.getRestEndPoint('palantir/analytics/overall');

  const transform = data => {
    let overAllTotal = 0;
    let uniqueTotal = 0;
    if (data.overall) {
      data.overall.forEach(item => {
        overAllTotal += item.count;
      });
    }
    if (data.unique) {
      data.unique.forEach(item => {
        uniqueTotal += item.count;
      });
    }
    data.overallTotal = overAllTotal;
    data.uniqueTotal = uniqueTotal;
    return data;
  };

  callSearchAnalyticsApi(endPoint, requestParameter, transform, callback);
};

const callSearchAnalyticsApi = (
  endpoint,
  requestParameter,
  transform,
  callback,
) => {
  const parameter = { ...requestParameter };

  parameter.apiKey = getApiKey();
  if (!parameter.limit || parameter.limit === '' || parameter.limit === 0) {
    const account = isSwitchAccount() || store.getState().user.user.account;
    parameter.limit =
      (account.searchProperty &&
        account.searchProperty.analyticsTopResultShow) ||
      50;
  }
  if (parameter.start) {
    parameter.start = parameter.start.format('YYYY-MM-DD');
  }
  if (parameter.end) {
    parameter.end = parameter.end.format('YYYY-MM-DD');
  }
  if (
    parameter.device &&
    parameter.device !== '' &&
    parameter.device !== 'PC'
  ) {
    parameter.device = parameter.device.toLowerCase();
  }

  $http(endpoint)
    .get(parameter)
    .then(response => {
      if (transform) {
        callback(transform(response));
      } else {
        callback(response);
      }
    });
};
