import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';

class TimeAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      campaigns: [],
      style: '',
      hour: null,
    };

    this.checkConflict = this.checkConflict.bind(this);
  }

  componentDidMount() {
    this.props.getter(response => {
      if (response) {
        this.setState(
          {
            campaigns: response,
          },
          () => {
            this.checkConflict(this.props.hour, this.props.instanceId);
          },
        );
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.hour !== nextProps.hour) {
      this.checkConflict(nextProps.hour, nextProps.instanceId);
    }
  }

  checkConflict(hour, instanceId) {
    const oneHourBeforeSelected = moment(hour, 'HH:mm')
      .subtract(1, 'hours')
      .subtract(1, 'minutes');
    const oneHourAfterSelected = moment(hour, 'HH:mm')
      .add(1, 'hours')
      .add(1, 'minutes');

    const filtered = this.state.campaigns.filter(campaign => {
      const campaignDate = moment(campaign.params.workingHour, 'HH:mm');
      if (instanceId && campaign.instanceId === instanceId) {
        return false;
      }
      return campaignDate.isBetween(
        oneHourBeforeSelected,
        oneHourAfterSelected,
      );
    });

    if (filtered.length > 0) {
      this.calculateStyle();
      this.setState({
        conflictCampaigns: filtered,
        isActive: true,
        hour,
      });

      return;
    }

    this.setState({
      conflictCampaigns: [],
      isActive: false,
      hour,
    });
  }

  closeWarning() {
    this.setState({
      isActive: false,
    });
  }

  calculateStyle() {
    const $elm = this.props.refElem && this.props.refElem.current;
    if (!$elm) {
      return;
    }
    const rect = $elm.getBoundingClientRect();
    const style = {
      top: `${rect.top + 10}px`,
      left: `${rect.left - 320}px`,
    };

    this.setState({
      style,
    });
  }

  html() {
    return (
      this.state.isActive && (
        <div className='conflict-warning right-side' style={this.state.style}>
          You already have active campaign(s) called
          {this.state.conflictCampaigns.map((cmp, index) => {
            if (index !== this.state.conflictCampaigns.length - 1) {
              return (
                <strong key={cmp.name}> {cmp.name || cmp.scenarioName},</strong>
              );
            }
            return (
              <strong key={cmp.name}> {cmp.name || cmp.scenarioName} </strong>
            );
          })}
          at this time. Change the time to prevent your campaigns from
          overlapping.
          <button type='button' onClick={this.closeWarning.bind(this)}>
            Got It!
          </button>
        </div>
      )
    );
  }

  render() {
    return ReactDOM.createPortal(
      this.html(),
      document.getElementById('modal-portal'),
    );
  }
}

export default TimeAlert;
