import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { wizardActions } from '../../../../actions';
import { isSuperUser } from '../../../../modules/auth/user';

const FinalizeButtons = ({ onSubmit }) => {
  const isAdvancedConfigVisible = useSelector(
    state => state.wizard.isAdvancedConfigVisible,
  );

  const toggleAdvanceConfig = () => {
    if (isAdvancedConfigVisible) {
      wizardActions.hideAdvancedConfig();
    } else {
      wizardActions.showAdvancedConfig();
    }
  };

  return (
    <div className='campaign-finalize-buttons'>
      <div className='buttons-row'>
        <a
          className='button tertiary-action golive-action'
          onClick={() => onSubmit('ACTIVE')}
        >
          Go Live
        </a>
        <a className='button gotest-action' onClick={() => onSubmit('TEST')}>
          Go Test
        </a>
      </div>
      {isSuperUser() && (
        <div className='buttons-row'>
          <a
            className='button secondary-action'
            onClick={() => toggleAdvanceConfig()}
          >
            Advanced Configuration
          </a>
        </div>
      )}
    </div>
  );
};

FinalizeButtons.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FinalizeButtons;
