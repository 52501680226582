import React from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { devices, mediums } from '../../constants/datamaps/campaigns';

import { t } from '../../system/ui';

import Icon from '../icon';
import Icons from '../icons';
import WidgetReportStats from './report-stats';
import ReportActions from '../reports/actions';
import Tooltip from '../tooltip';

class WidgetReport extends React.Component {
  constructor(props) {
    super(props);
    this.dataCheck = this.dataCheck.bind(this);
    this.state = {
      selectedDevice: 'ALL',
      loadingAnimation: true,
    };
  }

  selectDevice(selectedDevice, e) {
    e.preventDefault();
    this.setState({
      selectedDevice,
    });
  }

  dataCheck(value) {
    this.setState({
      loadingAnimation: value,
    });
  }

  render() {
    const isPromotion =
      this.props.actions &&
      this.props.actions[0] &&
      this.props.actions[0].type === 'recommendPromotion';
    const isKeyword =
      this.props.actions &&
      this.props.actions[0] &&
      this.props.actions[0].type === 'recommendKeyword';
    const isSegmentCampaign =
      this.props.actions &&
      this.props.actions[0] &&
      this.props.actions[0].type === 'segmentVisitor';
    return (
      <div className='widget page-content-block'>
        <div className='one-whole'>
          <h2 className='widget-title'>{this.props.scenarioName}</h2>
          <ReportActions {...this.props} moduleName='recommendation' />
        </div>
        {this.state.loadingAnimation ? (
          <div>Loading... </div>
        ) : (
          <div className='widget-meta'>
            {isPromotion && (
              <span className='promotion-label'>{t('Promotion')}</span>
            )}
            {isKeyword && (
              <span className='promotion-label'>{t('Keyword')}</span>
            )}
            {this.props.isCampaignLoaded && (
              <span className='recommendation-device-selection'>
                <Tooltip
                  content={t('All Devices')}
                  alignment='top'
                  newTooltip
                  isInline
                >
                  <span
                    key='all-devices'
                    onClick={this.selectDevice.bind(this, 'ALL')}
                    className={classNames(
                      'widget-meta-mobile all-device-report',
                      {
                        'is-selected': this.state.selectedDevice === 'ALL',
                      },
                    )}
                  >
                    <Icons
                      name='allDevices'
                      width='16'
                      height='16'
                      color='#bababa'
                    />
                    {t('ALL')}
                  </span>
                </Tooltip>

                {this.props.device
                  ? mediums[this.props.device].map(device => {
                      if (
                        device.toLowerCase() === 'pctablet' ||
                        device.toLowerCase() === 'tablet'
                      ) {
                        return null;
                      }

                      return (
                        <Tooltip
                          key={device}
                          content={t(devices[device])}
                          alignment='top'
                          newTooltip
                          isInline
                        >
                          <span
                            key={device}
                            onClick={this.selectDevice.bind(this, device)}
                            className={classNames('widget-meta-mobile', {
                              'is-selected':
                                this.state.selectedDevice === device,
                            })}
                          >
                            <Icon name={device.toLowerCase()} />
                          </span>
                        </Tooltip>
                      );
                    })
                  : ''}
              </span>
            )}
          </div>
        )}

        <hr className='widget-separator' />
        <WidgetReportStats
          {...this.props}
          selectedDevice={
            this.state.selectedDevice === 'PC'
              ? 'PC'
              : this.state.selectedDevice.toLowerCase()
          }
          campaignType={this.props.campaignType}
          startDate={this.props.startDate}
          isSegmentCampaign={isSegmentCampaign}
          isKeywordCampaign={isKeyword}
          dataCheck={this.dataCheck}
        />
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
});

export default connect(mapStatesToProps)(WidgetReport);
