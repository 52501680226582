import React, { Component } from 'react';
import classNames from 'classnames';
import find from 'lodash/find';

import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

import { TextField } from '../fields';
import { t } from '../../system/ui';
import SampleContentModal from '../../modules/emails/sample-content-modal';
import { modalActions, uiActions } from '../../actions';
import { getSampleContent } from '../../modules/push/ajax';
import Icons from '../icons';

class LanguageBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      additionalTitles:
        Object.keys(this.props.additionalTitles).length !== 0
          ? this.props.additionalTitles
          : '',
    };

    this.langList = this.generateLangList(props);

    this.debounced = debounce(() => {
      this.props.onLanguageChange(this.state.additionalTitles);
    }, 250);

    this.debounced = this.debounced.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.additionalTitles, this.props.additionalTitles)) {
      this.setState({
        additionalTitles:
          Object.keys(nextProps.additionalTitles).length !== 0
            ? nextProps.additionalTitles
            : '',
      });
    }
  }

  onSelectSampleContent(contentType, selectedContent) {
    modalActions.closeModal();

    this.setState({
      additionalTitles: {
        ...this.state.additionalTitles,
        [this.state.selectedLanguageTab]: selectedContent,
      },
    });

    if (this.props.onLanguageChange) {
      this.debounced();
    }
  }

  onSampleTextSelect() {
    if (!this.state.isReady) {
      uiActions.isLoading();
      getSampleContent('ABANDONED_CART', (samples) => {
        uiActions.isLoaded();
        this.setState({ contentSamples: samples, isReady: true }, () =>
          this.openSampleModal(),
        );
      });
    } else {
      this.openSampleModal();
    }
  }

  onDataChange(evt) {
    this.setState({
      additionalTitles: {
        ...this.state.additionalTitles,
        [this.state.selectedLanguageTab]: evt.target.value,
      },
    });

    if (this.props.onLanguageChange) {
      this.debounced();
    }
  }

  getTextValue() {
    let value = '';
    if (
      this.state.additionalTitles.hasOwnProperty(this.state.selectedLanguageTab)
    ) {
      value = this.state.additionalTitles[this.state.selectedLanguageTab];
    }
    return value;
  }

  openSampleModal() {
    const { account } = this.props;
    const { mainLanguage } = account;

    let sampleContent = this.state.contentSamples;
    if (sampleContent.hasOwnProperty(this.state.selectedLanguageTab)) {
      sampleContent = sampleContent[this.state.selectedLanguageTab].titleList;
    } else if (sampleContent.hasOwnProperty(mainLanguage)) {
      sampleContent = sampleContent[mainLanguage].titleList;
    } else {
      sampleContent = [];
    }

    const sampleContentModal = () => (
      <SampleContentModal
        content={sampleContent}
        onSelect={this.onSelectSampleContent.bind(this)}
        isReady
      />
    );

    modalActions.openModal({
      title: 'Select Push Content',
      content: sampleContentModal,
    });
  }

  generateLangList(props) {
    const { account, wizardLanguage } = props;
    let langList;
    if (
      account.hasOwnProperty('additionalLanguages') &&
      account.additionalLanguages.indexOf(account.mainLanguage) > -1
    ) {
      langList = account.additionalLanguages;
    } else {
      langList = [account.mainLanguage].concat(account.additionalLanguages);
    }

    langList = langList.map(lang => {
      const isDisabled =
        wizardLanguage != 'SESSION_BASED' && wizardLanguage !== lang;
      return { value: lang, label: lang, isDisabled };
    });

    const availableItems = langList.filter((item) => !item.isDisabled);
    const selectedItem = find(
      availableItems,
      item => item.label === this.state.selectedLanguageTab,
    );

    if (!selectedItem) {
      this.selectLanguage(availableItems[0].label);
    }
    langList.push({ value: 'SESSION_BASED', label: 'Session based' });

    return langList;
  }

  selectLanguage(label) {
    this.setState(
      {
        selectedLanguageTab: label,
      },
      () => {
        this.props.changeSelectedLanguage(label);
      },
    );
  }

  hasError(lang) {
    const { wizardLanguage } = this.props;
    if (wizardLanguage !== 'SESSION_BASED') {
      return false;
    }

    const language = this.state.additionalTitles[lang];
    const err = !language || language === '';

    return err;
  }

  render() {
    this.langList = this.generateLangList(this.props);

    return (
      <div className='push-abandonment__language-box'>
        {this.langList.length > 2 && (
          <span className='campaign-language-selection-extended'>
            {this.langList.map((item, index) => {
              if (item.label !== 'Session based') {
                return (
                  <span
                    onClick={this.selectLanguage.bind(this, item.label)}
                    className={classNames('language-box', {
                      'is-selected':
                        this.state.selectedLanguageTab === item.label ||
                        (index === 0 &&
                          this.state.language === 'SESSION_BASED' &&
                          this.state.selectedLanguageTab === ''),
                      'is-disabled': item.isDisabled,
                      'has-error': this.hasError(item.label),
                    })}
                  >
                    {item.label}
                  </span>
                );
              }
            })}
          </span>
        )}
        <TextField
          name='title'
          label='Title'
          placeholder='You can add {{product:name}} to the title to send the product name in push notification.'
          className='item-stacked one-whole'
          value={this.getTextValue() || ''}
          required
          tooltipText={t('Prepare the titles of your push')}
          tooltip
          onChange={this.onDataChange.bind(this)}
          ref='title'
          isPush
          validation={{ minLength: 1 }}
          renderProp={() => (
            <div
              className='sample-text'
              onClick={this.onSampleTextSelect.bind(this)}
            >
              <Icons name='exampleText' width='14' height='16' color='#fff' />{' '}
              <span>{t('Select sample text')}</span>
            </div>
          )}
        />
      </div>
    );
  }
}

export default LanguageBox;
