import {
  getNow,
  getFirstDayOfWeek,
  queryDate,
  get7DaysAgo,
} from '../../system/date';

export const salesTable = {
  header: [
    { text: '', className: 'sales-table-order' },
    { text: '#', className: 'sales-table-order' },
    { text: 'Groups', className: 'sales-table-groups' },
    {
      text: 'Purchase Time',
      sortable: true,
      sort: 'time',
      className: 'sales-table-time',
    },
    {
      text: 'Device',
      sortable: true,
      sort: 'device',
      className: 'sales-table-device',
    },
    {
      text: 'Order No',
      sortable: true,
      sort: 'orderNo',
      className: 'sales-table-orderNo',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: 'totalPrice',
      tooltip: 'Total Price',
      className: 'sales-table-totalprice',
    },
    {
      text: 'Discount',
      sortable: true,
      sort: 'totalDiscount',
      tooltip: 'Total Discount',
      className: 'sales-table-totalprice',
    },
    {
      text: 'Shipment',
      sortable: true,
      sort: 'shipment',
      tooltip: 'Shipment',
      className: 'sales-table-totalprice',
    },
    {
      text: 'Payment Type',
      sortable: true,
      sort: 'paymentType',
      tooltip: 'Payment Type',
      className: 'sales-table-paymenttype add-border',
    },
    {
      text: 'Product',
      tooltip: 'Product List',
      className: 'sales-table-product',
    },
    { text: 'Quantity', className: 'sales-table-quantity' },
    { text: 'Price', className: 'sales-table-price' },
    {
      text: 'Segmentify',
      tooltip: 'Purchases by Segmentify',
      alignment: 'right',
      className: 'sales-table-segmentify',
    },
    { text: 'Banner', className: 'sales-table-banner' },
    { text: 'Actions', tooltip: 'Actions', className: 'sales-table-actions' },
  ],
  totals: {
    title: 'Total',
    titleColor: '#525252',
    totalSales: 'Purchase: ',
    totalQuantity: 'Quantity: ',
    totalAmount: 'Amount: ',
    totalPrice: 'Price: ',
  },
};

export const salesQuery = {
  basketId: '',
  campaignId: '',
  start: queryDate(get7DaysAgo()),
  end: queryDate(getNow()),
  items: 100,
  maxPrice: '',
  maxTotalPrice: '',
  minPrice: '',
  minTotalPrice: '',
  orderNo: '',
  page: 1,
  reverse: true,
  viaBanners: false,
  sort: 'time',
  type: 'LIST',
  mode: '',
  currency: 'ALL',
};

export default {
  salesQuery,
  salesTable,
};
