import { algorithmDescriptions } from '../../constants/datamaps/wizard';
import { getCampaignPage } from '../campaigns/data';

export const pageMap = {
  home: 'Home Page',
  category: 'Category Page',
  product: 'Product Page',
  search: 'Search Page',
  404: '404 Page',
  notfound: '404 Page',
  basket: 'Basket Page',
  'checkout-success': 'Checkout Success Page',
  triggerbased: 'Trigger Based Page',
  custom: 'Custom Page',
};

export const getEmailCampaignAlgorithms = campaign => {
  const finalAlgorithms = [];
  const defaultAlgorithms = campaign.defaultRecommendation || [];
  const additionalAlgorithms = campaign.additionalRecommendations || [];
  const campaignAlgorithms = defaultAlgorithms.concat(additionalAlgorithms);
  const staticItemCount = 0;
  let brandCount = 0;
  let labelCount = 0;
  let categoryCount = 0;

  campaignAlgorithms.forEach(algorithm => {
    if (algorithmDescriptions()[algorithm.source]) {
      finalAlgorithms.push(algorithmDescriptions()[algorithm.source].title);
    } else if (algorithm.type === 'CATEGORY') {
      categoryCount += 1;
    } else if (algorithm.type === 'BRAND') {
      brandCount += 1;
    } else if (algorithm.type === 'LABEL') {
      labelCount += 1;
    }
  });
  if (categoryCount) {
    finalAlgorithms.push(`${categoryCount} Category Recommendations`);
  }
  if (brandCount) {
    finalAlgorithms.push(`${brandCount} Brand Recommendations`);
  }
  if (labelCount) {
    finalAlgorithms.push(`${labelCount} Label Recommendations`);
  }
  return finalAlgorithms;
};

export const getCampaignAlgorithms = campaign => {
  let dynamicAlgorithms;
  let staticAlgorithms;
  const finalAlgorithms = [];
  let staticItemCount = 0;
  let brandCount = 0;
  let labelCount = 0;
  let categoryCount = 0;
  if (
    campaign &&
    campaign.actions &&
    campaign.actions[0] &&
    campaign.actions[0].params &&
    campaign.actions[0].type === 'recommendProducts'
  ) {
    dynamicAlgorithms = campaign.actions[0].params.dynamicItems
      ? JSON.parse(campaign.actions[0].params.dynamicItems)
      : [];
    staticAlgorithms = campaign.actions[0].params.staticItems
      ? JSON.parse(campaign.actions[0].params.staticItems)
      : [];
    dynamicAlgorithms.forEach(algorithm => {
      if (algorithmDescriptions()[algorithm.recommendationSource]) {
        finalAlgorithms.push(
          algorithmDescriptions()[algorithm.recommendationSource].title,
        );
      } else if (algorithm.type === 'CATEGORY') {
        categoryCount += 1;
      } else if (algorithm.type === 'BRAND') {
        brandCount += 1;
      } else if (algorithm.type === 'LABEL') {
        labelCount += 1;
      }
    });
    if (categoryCount) {
      finalAlgorithms.push(`${categoryCount} Category Recommendations`);
    }
    if (brandCount) {
      finalAlgorithms.push(`${brandCount} Brand Recommendations`);
    }
    if (labelCount) {
      finalAlgorithms.push(`${labelCount} Label Recommendations`);
    }
    staticItemCount = staticAlgorithms.length;
    if (staticItemCount) {
      finalAlgorithms.push(`${staticItemCount} Product Recommendations`);
    }
  }
  return finalAlgorithms;
};

export const accountCampaignsTransform = (campaigns, stats) => {
  const mainObj = Object.keys(pageMap).reduce(
    (result, objKey) => ({ ...result, [pageMap[objKey]]: {} }),
    {},
  );

  campaigns.forEach(campaign => {
    let campaignPage = '';
    Object.keys(pageMap).forEach(pageKey => {
      if (pageMap[pageKey] === getCampaignPage(campaign)) {
        campaignPage = pageKey;
      }
    });
    if (mainObj[getCampaignPage(campaign)]) {
      mainObj[getCampaignPage(campaign)][campaign.instanceId] = {};
      mainObj[getCampaignPage(campaign)][campaign.instanceId].campaign =
        campaign || {};
      mainObj[getCampaignPage(campaign)][campaign.instanceId].algorithms =
        getCampaignAlgorithms(campaign) || [];
      mainObj[getCampaignPage(campaign)][campaign.instanceId].stats =
        stats[campaign.instanceId] || {};
      mainObj[getCampaignPage(campaign)][
        campaign.instanceId
      ].page_slug = campaignPage;
    }
  });

  const orderedPages = [
    'Home Page',
    'Category Page',
    'Product Page',
    'Search Page',
    '404 Page',
    'Basket Page',
    'Checkout Success Page',
    'Trigger Based Page',
    'Custom Page',
  ];
  return orderedPages.reduce(
    (result, page) => ({ ...result, [page]: mainObj[page] || {} }),
    {},
  );
};
