import React from 'react';
import { withRouter } from 'react-router';
import ReactHighcharts from 'react-highcharts';
import highchartsMore from 'highcharts-more';
import './ExperimentationReport.scss';
import ExperimentationReportUpperTable from './ExperimentationReportUpperTable';
import ExperimentationReportResultsTable from './ExperimentationReportResultsTable';
import { facetedSearchRequest, requestToSecureApi } from '../../../actions/faceted-search';
import moment from 'moment';
import { getRandomColor } from '../definition/ExperimentationDefinitionPanel';

highchartsMore(ReactHighcharts.Highcharts);

export function fixPrecision(number) {
  if (Number.isInteger(number)) return number;
  return number.toFixed(2);
}

class ExperimentationReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      experiment: {},
      report: {},
    };
  }

  componentDidMount() {
    this.getReport();
  }

  getReport = async () => {
    const { params } = this.props;
    const { instanceId } = params;
    const experiment = await facetedSearchRequest('experimentation/get', {
      instanceId,
    }).get();
    if (experiment) {
      experiment.variations.forEach(variation => {
        const randomColor = getRandomColor();
        variation.randomColor = randomColor;
        variation.shadeColor = this.shadeColor(randomColor, 40);
        variation.shadeColorLine = this.shadeColor(randomColor, -40);
      });
      const queryParams = `&startDate=${moment(experiment.startDate).format('YYYY-MM-DD')}&endDate=${moment(experiment.endDate).format('YYYY-MM-DD')}`;
      const report = await requestToSecureApi(
        'report/experimentation',
        {
          instanceId,
        },
        queryParams,
      ).get();
      this.setState({
        experiment,
        report,
      });
    }
  };

  shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    const RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    const GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    const BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return `#${RR}${GG}${BB}`;
  }

  getConfig() {
    const { experiment, report } = this.state;
    let zIndex = 0;
    const lineSeries = [];
    const areaSeries = [];
    experiment?.variations?.forEach(
      ({ instanceId, variationName, randomColor, shadeColorLine, shadeColor, testSplit }) => {
        const dailyData = report?.[instanceId]?.dailyRatesMap ?? {};
        const lineData = Object.keys(dailyData).map(date => {
          const conversionRate = dailyData[date].conversionRate * 100;
          return {
            x: moment(date, 'YYYY-MM-DD').valueOf(),
            y: conversionRate,
            shadeColor,
            conversionRate,
            testSplit,
          };
        });

        const areaData = [];
        Object.keys(dailyData).map(date => {
          if (
            Array.isArray(dailyData[date].confidenceIntervalList) &&
            dailyData[date].confidenceIntervalList.length !== 0
          ) {
            const nintyFiveConf = dailyData[date].confidenceIntervalList.find(
              cf => cf.confidenceLevel === 95,
            );
            if (nintyFiveConf) {
              areaData.push({
                x: moment(date, 'YYYY-MM-DD').valueOf(),
                low: nintyFiveConf.lowerBound * 100,
                high: nintyFiveConf.upperBound * 100,
                shadeColor,
                conversionRate: dailyData[date].conversionRate * 100,
                testSplit,
              });
            }
          }
        });
        areaSeries.push({
          name: variationName,
          data: areaData,
          type: 'areasplinerange',
          lineWidth: 0,
          color: randomColor,
          fillOpacity: 0.3,
          zIndex,
          marker: {
            enabled: false,
          },
        });
        lineSeries.push({
          name: variationName,
          data: lineData,
          type: 'spline',
          zIndex,
          marker: {
            enabled: false,
          },
          color: shadeColorLine,
        });
        zIndex += 1;
      },
    );
    return {
      title: {
        text: 'Conversion Rate (%)',
        align: 'left',
      },

      xAxis: {
        type: 'datetime',
      },

      yAxis: {
        labels: {
          format: '{value}%',
        },
        title: {
          text: null,
        },
      },

      tooltip: {
        useHTML: true,
        backgroundColor: null,
        borderWidth: 0,
        shadow: false,
        style: {
          padding: 0,
        },
        formatter: function() {
          return `
            <div style="background: ${this.point.shadeColor};padding: 1rem; border-radius: 10px" class="experimentation-report-tooltip">
                <div class="variant-name">${this.series.name}</div>
                <div class="result-footer">
                    <span class="ratio">%${fixPrecision(this.point.conversionRate)}</span>
                    <div class="variation-ratio-container">
                        <span class="split-label">Split</span>
                        <span class="variation-ratio">%${this.point.testSplit}</span>
                    </div>
                </div>
            </div>`;
        },
      },

      legend: {
        enabled: false,
      },

      series: [
        ...lineSeries,
        ...areaSeries
      ],
    };
  }

  render() {
    const { experiment, report } = this.state;
    return (
      <div className='experimentation-report'>
        <ExperimentationReportUpperTable
          experiment={experiment}
          report={report}
        />
        <ReactHighcharts config={this.getConfig()} isPureConfig />
        <ExperimentationReportResultsTable
          experiment={experiment}
          report={report}
        />
      </div>
    );
  }
}

export default withRouter(ExperimentationReport);
