import React, { useState } from 'react';
import moment from 'moment';
import ActivityList from './ActivityList';
import classes from './EmailActivityReport.module.scss';
import { TextField } from '../../../components/fields';
import { getActivityReports } from '../../../modules/emails/ajax';
import { isLoaded, isLoading } from '../../../actions/ui';

import { setTitle } from '../../../system/document';

const EmailActivityReport = () => {
  const [searchStr, setSearchStr] = useState('');
  const [emails, setEmails] = useState(null);
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const editValue = e => {
    setSearchStr(e.target.value);
  };

  const handleSearch = () => {
    isLoading();

    getActivityReports(searchStr, async data => {
      setEmails(data.emailReportData);
      setSubscriptionInfo(data.subscriptionInfo);
      isLoaded();
    });
  };

  const subTemp = data => {
    if (Object.keys(data).length === 0) return <></>;
    return (
      <>
        <div className={classes['header-right']}>
          <div className={classes['header-right__line']}>
            <div className={classes['header-right__line--left']}>
              Subscription Date:
            </div>
            <div className={classes['header-right__line--right']}>
              {data.subscriptionDate
                ? moment
                    .unix(data.subscriptionDate / 1_000)
                    .format('DD/MM/YYYY HH:mm', true)
                : '-'}
            </div>
          </div>
          {data.unsubscriptionDate && (
            <div className={classes['header-right__line']}>
              <div className={classes['header-right__line--left']}>
                Unsubscription Date:
              </div>
              <div className={classes['header-right__line--right']}>
                {moment
                  .unix(data.unsubscriptionDate / 1_000)
                  .format('DD/MM/YYYY HH:mm', true)}
              </div>
            </div>
          )}
          {data.unsubscriptionChannel && (
            <div className={classes['header-right__line']}>
              <div className={classes['header-right__line--left']}>
                Unsubscription Channel:
              </div>
              <div className={classes['header-right__line--right']}>
                {data.unsubscriptionChannel}
              </div>
            </div>
          )}
          {data.unsubscriptionReason && (
            <div className={classes['header-right__line']}>
              <div className={classes['header-right__line--left']}>
                Unsubscription Reason:
              </div>
              <div className={classes['header-right__line--right']}>
                {data.unsubscriptionReason}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  setTitle('Mail Activity Report');

  return (
    <>
      <div className={classes.header}>
        <div className={classes['header-left']}>
          <TextField
            className={classes['text-area']}
            inputClassName={classes['text-area__input']}
            placeholder='Email Address...'
            value={searchStr}
            onChange={editValue}
            onKeyUp={e => {
              if (e.key === 'Enter') handleSearch();
            }}
            validation={{ email: true }}
          />
          <button
            type='button'
            onClick={() => handleSearch()}
            disabled={searchStr.length === 0 || !searchStr.includes('@')}
            className={
              classes[
                `submit-btn${
                  searchStr.length === 0 || !searchStr.includes('@')
                    ? '-disabled'
                    : ''
                }`
              ]
            }
          >
            Search
          </button>
        </div>
        {subscriptionInfo && subTemp(subscriptionInfo)}
      </div>

      {emails && <ActivityList list={emails} />}
    </>
  );
};

export default EmailActivityReport;
