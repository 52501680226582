import React from 'react';
import classes from './StatisticCard.module.scss';

import { IYS_STATISTICS } from '../../common/constants/constants';
import { useStatsFormatter } from '../../dashboard-list/hooks/useStatsFormatter';

/**
 * @name StatisticCard
 * @description
 * This component is responsible for rendering the statistics of the IYS Dashboard
 * @param {Object} data - Data for the statistics
 * @returns JSX.Element - Statistics Card
 */

export const StatisticCard = ({ data }) => {
  // TODO: remove this part after 24.10.0 Release
  // remove iysNotSent key from data in case it comes from BE which will be removed via https://segmentify.atlassian.net/browse/SFY-11980
  const cleanedData =
    data && typeof data === 'object'
      ? Object.keys(data).reduce((acc, key) => {
          if (key !== 'iysNotSend') {
            acc[key] = data[key];
          }
          return acc;
        }, {})
      : {};
  const statsFormatter = useStatsFormatter(cleanedData, IYS_STATISTICS);

  return (
    <div className={classes.stats_card}>
      <div className={classes.stats_card__item}>
        {statsFormatter?.map(({ key, value, classIdentifier }) => {
          if (key === 'Opt-In' || key === 'Opt-Out') {
            return (
              <div key={key} className={classes.stat}>
                <div className={classes.stat_key}>{key}</div>
                <div className={classes[`stat__${classIdentifier}`]}>
                  {value}
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
      <div className={classes.vl} />
      <div className={classes.stats_card__item}>
        {statsFormatter?.map(({ key, value, classIdentifier }) => {
          if (key !== 'Opt-In' && key !== 'Opt-Out') {
            return (
              <div key={key} className={classes.stat}>
                <div className={classes.stat_key}>{key}</div>
                <div className={classes[`stat__${classIdentifier}`]}>
                  {value}
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
