import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment/moment';

import { t } from '../system/ui';
import { setTitle } from '../system/document';

import { uiActions, filterActions, dateActions } from '../actions';
import { queryDate } from '../system/date';
import { calculateRatio } from '../system/ratio';

import Filters from '../components/filters/filters';
import EmailStats from '../components/widget/email-stats';
import {
  getRangedEmailReport,
  getEmailCampaign,
  getUnsubReport,
} from '../modules/emails/ajax';
import FormStats from '../components/widget/personalization-form-stats';
import ReportActions from '../components/reports/actions';

import { hasKlaviyoConfiguration } from '../modules/auth/user';

class SingleEmailReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: {},
      campaignReport: {},
      unsubReport: [],
      query: document.location.search,
    };

    this.getEmailReport = this.getEmailReport.bind(this);
    this.openUnsubDetail = this.openUnsubDetail.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

  componentDidMount() {
    const { reportID } = this.props.params;
    setTitle(t('Campaign Report'));
    this.getEmailWithReport(reportID, this.props.range);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      this.getEmailWithReport(newProps.params.reportID, newProps.range);
    }

    if (newProps.update === true) {
      this.getEmailWithReport(newProps.params.reportID, newProps.range);
    }

    if (newProps.reset === true) {
      this.getEmailWithReport(newProps.params.reportID, newProps.range);
    }
  }

  formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }

    return [year, month, day].join('-');
  }

  getCampaignDataFromStart(reportId, startDate) {
    const boundaries = [];
    const unSubBoundaries = [];
    const today = new Date();
    boundaries.start = this.formatDate(startDate);
    boundaries.end = this.formatDate(today);
    unSubBoundaries.startDate = this.formatDate(startDate);
    unSubBoundaries.endDate = this.formatDate(today);
    getRangedEmailReport(reportId, boundaries, campaignReport => {
      uiActions.isLoaded();
      if (campaignReport) {
        getUnsubReport(reportId, unSubBoundaries, unsubReport => {
          if (unsubReport) {
            campaignReport.push({
              x: 'unsub user',
              y: unsubReport?.reduce((acc, curr) => {
                return acc + curr.count;
              }, 0),
            });
            this.setState({
              campaignReport,
              unsubReport,
            });
          }
        });
      }
    });
  }

  getEmailReport(reportId, range) {
    const boundaries = {};
    const unSubBoundaries = {};
    boundaries.start = queryDate(range[0]);
    boundaries.end = queryDate(range[1]);
    unSubBoundaries.startDate = queryDate(range[0]);
    unSubBoundaries.endDate = queryDate(range[1]);
    getRangedEmailReport(reportId, boundaries, response => {
      uiActions.isLoaded();
      if (response) {
        getUnsubReport(reportId, unSubBoundaries, unsubReport => {
          if (unsubReport) {
            response.push({
              x: 'unsub user',
              y: unsubReport?.reduce((acc, curr) => {
                return acc + curr.count;
              }, 0),
            });
            this.setState({
              campaignReport: response,
              unsubReport,
            });
          }
        });
      }
    });
  }

  getEmailWithReport(reportId, range) {
    const reportIdArchive = reportId;
    filterActions.filtersUpdated();
    filterActions.filtersReset();
    uiActions.isLoading();
    if (this.state.campaign.scenarioName) {
      this.getEmailReport(reportId, range);
    } else {
      getEmailCampaign(reportId, campaign => {
        if (campaign) {
          this.setState(
            {
              campaign,
            },
            () => {
              if (
                this.state.campaign.startDate &&
                (this.state.query === '?archive' ||
                  this.state.campaign.type === 'RECOMMENDATION')
              ) {
                dateActions.updateRangeAlias('custom');
                dateActions.updateRange([
                  moment(campaign?.startDate),
                  moment(),
                ]);
                this.getCampaignDataFromStart(
                  reportIdArchive,
                  this.state.campaign.startDate,
                );
              } else {
                dateActions.updateRangeAlias('lastSevenDays');
                this.getEmailReport(reportId, range);
              }
            },
          );
        }
      });
    }
  }

  openUnsubDetail() {
    const groupReasons = ['hard_bounce', 'soft_bounce', 'rejected', 'spam'];
    let totalUnsubscribers = 0;
    this.state.unsubReport.forEach(unsubReport => {
      totalUnsubscribers += unsubReport.count;
    });
    const results = [];
    const groupedResult = {
      name: 'Email delivery failure',
      count: {
        count: 0,
        percentage: 0,
      },
    };
    this.state.unsubReport.forEach(report => {
      if (groupReasons.indexOf(report.reason) > -1) {
        groupedResult.count.count += report.count;
      } else {
        results.push({
          name: report.reason,
          count: {
            count: report.count,
            percentage: calculateRatio(report.count, totalUnsubscribers),
          },
        });
      }
    });
    groupedResult.count.percentage = calculateRatio(
      groupedResult.count.count,
      totalUnsubscribers,
    );
    const content = () => (
      <FormStats
        results={
          groupedResult.count.count > 0
            ? results.concat([groupedResult])
            : results
        }
        unsubStats
        totalCount={totalUnsubscribers}
      />
    );
    uiActions.openModal({
      title: `${t('Unsubscription Stats')} - ${t(
        decodeURIComponent(this.state.campaign.scenarioName),
      )}`,
      content,
    });
  }

  render() {
    let unSubCount = 0;
    this.state.unsubReport.forEach(unsubReport => {
      unSubCount += unsubReport.count;
    });
    return (
      <div>
        <Filters isArchive={this.state.query === '?archive'} />
        <h2 className='page-title'>{t('Campaign Report')}</h2>

        <div className='widget page-content-block'>
          <div className='one-whole'>
            <h2 className='widget-title'>
              {decodeURIComponent(this.state.campaign.scenarioName) || ''}
            </h2>
            <ReportActions
              {...this.props}
              campaign={this.state.campaign}
              moduleName='email'
            />
          </div>

          <hr className='widget-separator' />
          <EmailStats
            report={this.state.campaignReport}
            campaignName={decodeURIComponent(
              this.state?.campaign?.scenarioName,
            )}
            campaignId={this.state?.campaign?.instanceId}
            isReport
          />

          {!hasKlaviyoConfiguration() && <hr className='widget-separator' />}

          {!hasKlaviyoConfiguration() && (
            <div style={{ textAlign: 'center' }}>
              {unSubCount === 0 ? (
                <p>
                  <strong>{unSubCount}</strong> visitors in total have
                  unsubscribed.
                </p>
              ) : (
                <p style={{ cursor: 'pointer' }} onClick={this.openUnsubDetail}>
                  <strong>{unSubCount}</strong> Visitors are totally
                  unsubscribed.
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  ui: store.ui.ui,
  range: store.date.range,
  update: store.filters.update,
  reset: store.filters.reset,
});

export default connect(mapStatesToProps)(SingleEmailReport);
