import { getApiKey } from '../auth/user';
import { $http } from '../../system/ajax';
import { getRestEndPoint } from '../../system/api';

export const getSegment = (id, callback, options, predefinedApiKey) => {
  const apiKey = predefinedApiKey || getApiKey();
  $http(`${getRestEndPoint('segmentation/get')}/${id}?apiKey=${apiKey}`)
    .get(options)
    .then(response => {
      callback(response);
    });
};

export const getAllSegment = (callback, options, predefinedApiKey) => {
  const apiKey = predefinedApiKey || getApiKey();
  $http(`${getRestEndPoint('segmentation/list')}?apiKey=${apiKey}`)
    .get(options)
    .then(response => {
      callback(response);
    });
};

/**
 * Get segment by type
 * @param {function} callback function to call after the request is done
 * @param {string[]} types array of types ['EVENT_BASED', 'USER_BASED']
 * @param predefinedApiKey
 */
export const getSegmentByType = (callback, types, predefinedApiKey) => {
  const apiKey = predefinedApiKey || getApiKey();
  $http(`${getRestEndPoint('segmentation/getByType')}?types=${types.join(',')}&apiKey=${apiKey}`)
    .get()
    .then(response => {
      callback(response);
    });
};

/**
 * Create a new segment
 * @param {Object} data [{name: 'segment name', type: 'EVENT_BASED'}]
 * @param {function} callback function to call after the request is done
 * @param {string} predefinedApiKey if you want to use a predefined api key
 */
export const createSegment = (data, callback, predefinedApiKey) => {
  const apiKey = predefinedApiKey || getApiKey();
  $http(`${getRestEndPoint('segmentation/create')}?apiKey=${apiKey}`)
    .post(data, { type: 'json' })
    .then(response => {
      callback(response);
    });
};

export const getAllStat = (callback, options, predefinedApiKey) => {
  const apiKey = predefinedApiKey || getApiKey();
  $http(`${getRestEndPoint('segmentation/report')}?apiKey=${apiKey}`)
    .get(options)
    .then(response => {
      callback(response);
    });
};

export const getCustomSegmentStat = (
  id,
  callback,
  options,
  predefinedApiKey,
) => {
  const apiKey = predefinedApiKey || getApiKey();
  $http(`${getRestEndPoint('segmentation/stats')}/?apiKey=${apiKey}&segmentName=${id}`)
    .get(options)
    .then(response => {
      callback(response);
    });
};

export const moveSegmentToArchive = id => {
  return $http(
    `${getRestEndPoint('segmentation/get')}/` +
      `PASSIVE/${id}?apiKey=${getApiKey()}`,
  ).put();
};

export const moveSegmentToTest = (id, callback) => {
  return $http(
    `${getRestEndPoint('segmentation/get')}/` +
      `DRAFT/${id}?apiKey=${getApiKey()}`,
  )
    .put()
    .then(response => {
      if (callback) {
        callback(response);
      }
    });
};

export const moveSegmentToLive = (id, callback) => {
  return $http(
    `${getRestEndPoint('segmentation/get')}/` +
      `ACTIVE/${id}?apiKey=${getApiKey()}`,
  )
    .put()
    .then(response => {
      if (callback) {
        callback(response);
      }
    });
};

export const activateSegmentation = (switchUser, apiKey, callback) => {
  $http(`${getRestEndPoint('segmentation/activate')}?apiKey=${apiKey}`)
    .post({}, { type: 'json', preventAbort: false, switchUser })
    .then(response => {
      callback(response);
    });
};
