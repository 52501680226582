import React from "react";
import debounce from "lodash/debounce";

import ColorPicker from "../../components/fields/field.colorpicker.js";
import { TextField } from "../../components/fields";
import { Select } from "../../components/fields";
import Tooltip from "../../components/tooltip";

import { t } from "../../system/ui";

const AbandonWidgetSettings = ({ data, onDataChange }) => {
  const positionOptions = [
    { value: "LEFT", label: "Left" },
    { value: "RIGHT", label: "Right" }
  ];

  const onValueChange = evt => {
    const name = evt.target.name;
    const value = evt.target.value;

    onDataChange(name, value);
  }

  const onColorChange = debounce(onDataChange, 250);

  return (
    <React.Fragment>
      <div className="push-abandonment__row push-abandonment__row--margin">
        <h3 className="push-abandonment__title">Website Widget Settings</h3>
      </div>
      <div className="push-abandonment__row push-abandonment__row--margin">
        <div>
          {t("Choose Your Template")}{" "}
          <Tooltip
            content={
              "Choose the template that will show your coupon code in the web page after a visitor clicks on the push notification"
            }
            alignment="left"
          >
            <i className="icon-info" role="presentation">
              <span className="for-screenreader-only" />
            </i>
          </Tooltip>
        </div>
      </div>
      <div className="push-abandonment__row push-abandonment__row--margin">
        <div className="push-abandonment__sticky">
          <div className="push-abandonment__sticky-img"></div>
          <div className="push-abandonment__sticky-bottom">
            <span className="push-abandonment__sticky-tick"></span>{" "}
            <span className="push-abandonment__sticky-text">Sticky Popup</span>
          </div>
        </div>
      </div>
      <div className="push-abandonment__row push-abandonment__row--margin">
        <TextField
          name={"title"}
          label="Title"
          value={data.title}
          onChange={onValueChange}
          className="item-stacked one-whole"
          required={true}
          tooltipText={t("Prepare the title of your sticky popup")}
          tooltip={true}
          validation={{ minLength: 1 }}
        />
      </div>
      <div className="push-abandonment__row push-abandonment__row--margin">
        <TextField
          name={"description"}
          label="Description"
          value={data.description}
          onChange={onValueChange}
          className="item-stacked one-whole"
          required={true}
          tooltipText={t("Enter the description of the sticky popup")}
          tooltip={true}
          validation={{ minLength: 1 }}
        />
      </div>
      <div className="push-abandonment__row push-abandonment__row--margin">
        <TextField
          name={"validationText"}
          label="Validation Text"
          value={data.validationText}
          onChange={onValueChange}
          className="item-stacked one-whole"
          required={true}
          tooltipText={t("Write the text that will show your customers until when the coupon code will be valid.")}
          tooltip={true}
          validation={{ minLength: 1 }}
        />
      </div>
      <div className="push-abandonment__row push-abandonment__row--margin">
        <div className="push-abandonment__coupon-grid">
          <div>
            <TextField
              name={"copyButtonText"}
              label="Copy Button Text"
              value={data.copyButtonText}
              onChange={onValueChange}
              className="item-stacked one-whole"
              required={true}
              tooltipText={t(
                "Write the texts that will be shown to your visitors in the copy button"
              )}
              tooltip={true}
              validation={{ minLength: 1 }}
            />
          </div>
          <div>
            <TextField
              name={"copiedInfoText"}
              label="Copied Info Text"
              value={data.copiedInfoText}
              onChange={onValueChange}
              className="item-stacked one-whole"
              required={true}
              tooltipText={t(
                "Write the text that will be shown to your visitors after the coupon copied successfully"
              )}
              tooltip={true}
              validation={{ minLength: 1 }}
            />
          </div>
        </div>
      </div>
      <div className="push-abandonment__row push-abandonment__row--margin">
        <div className="push-abandonment__coupon-grid">
          <div>
            <ColorPicker
              name={"backgroundColor"}
              label="Background Color"
              value={data.backgroundColor}
              className="item-stacked one-whole"
              onChange={value => onColorChange("backgroundColor", value)}
              required={true}
              tooltipText={t(
                "Choose the background color of the sticky popup icon"
              )}
              tooltip={true}
              validation={{ minLength: 1 }}
            />
          </div>
          <div>
            <ColorPicker
              name={"foregroundColor"}
              label="Foreground Color"
              value={data.foregroundColor}
              onChange={value => onColorChange("foregroundColor", value)}
              className="item-stacked one-whole"
              required={true}
              tooltipText={t(
                "Choose the foreground color of the sticky popup icon and the texts inside it"
              )}
              tooltip={true}
              validation={{ minLength: 1 }}
            />
          </div>
          <div>
            <div>
              {t("Position")}{" "}
              <Tooltip
                content={"Set the position of the sticky pop upon the page."}
                alignment="left"
              >
                <i className="icon-info" role="presentation">
                  <span className="for-screenreader-only" />
                </i>
              </Tooltip>
            </div>
            <Select
              name="position"
              className="item-stacked push-abandonment__select"
              value={data.position}
              onChange={item => onDataChange("position", item.value)}
              options={positionOptions}
              clearable={false}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AbandonWidgetSettings;
