/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { t } from "../../../../system/ui";

import { wizardActions } from "../../../../actions";

import Icons from "../../../../components/icons";

class AudienceButton extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    e.preventDefault();

    this.props.isAudienceVisible
      ? wizardActions.hideAudience()
      : wizardActions.showAudience();
  }

  render() {
    return (
      <button onClick={this.toggle} className="secondary-action">
        <Icons name="target" />
        {t("Target Audience")}
      </button>
    );
  }
}

const MapStatesToProps = store => ({
  isAudienceVisible: store.wizard.isAudienceVisible
});

export default connect(MapStatesToProps)(AudienceButton);
