/**
 * Created by mehmetyurtar on 3/22/18.
 */
import React, { Component } from "react";
class IconHeart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 31 31"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Intelligent-Modal"
            transform="translate(-640.000000, -308.000000)"
          >
            <g id="Modal" transform="translate(29.000000, 135.000000)">
              <g id="Group-2" transform="translate(0.000000, 173.000000)">
                <g
                  id="Smart-Offers-Copy"
                  transform="translate(611.000000, 0.000000)"
                >
                  <g id="Group-4-Copy">
                    <circle
                      id="Oval-2"
                      fill="#5A7391"
                      cx="15.5"
                      cy="15.5"
                      r="15.5"
                    />
                    <g
                      id="health-24px-glyph_heart"
                      transform="translate(9.000000, 9.000000)"
                      fill="#FFFFFF"
                      fillRule="nonzero"
                    >
                      <path
                        d="M9.91666667,0 C8.80833333,0 7.75833333,0.452173913 7,1.18695652 C6.24166667,0.452173913 5.19166667,0 4.08333333,0 C1.80833333,0 0,1.75217391 0,3.95652174 C0,7.57391304 6.35833333,12.6608696 6.65,12.8869565 C6.76666667,13 6.88333333,13 7,13 C7.11666667,13 7.23333333,12.9434783 7.35,12.8869565 C7.64166667,12.6608696 14,7.57391304 14,3.95652174 C14,1.75217391 12.1916667,0 9.91666667,0 Z"
                        id="Shape"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconHeart;
