import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const BackInStock = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox="0 0 21 21"
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d="M8.5911 0.513006C9.4947 0.146706 10.5053 0.146706 11.4089 0.513006L18.9075 3.55298C19.5679 3.82071 20 4.46216 20 5.17477V10.8105C19.5557 10.3842 19.051 10.0204 18.5 9.7332V5.77316L10.7503 8.7654V11.0635C10.0581 11.8041 9.5371 12.7067 9.2503 13.7083V8.7641L1.5 5.75003V14.8275C1.5 14.9293 1.56173 15.0209 1.65607 15.0592L9.1547 18.0991C9.1863 18.112 9.2183 18.1241 9.2503 18.1354V18.1342L9.439 18.2109C9.4976 18.2348 9.5575 18.2548 9.618 18.2709C9.8663 18.7973 10.1832 19.2849 10.5572 19.7224C9.898 19.8214 9.2187 19.7437 8.5911 19.4892L1.09252 16.4493C0.43211 16.1815 0 15.5401 0 14.8275V5.17477C0 4.46216 0.43211 3.82071 1.09252 3.55298L8.5911 0.513006ZM10.8453 1.90312C10.3032 1.68334 9.6968 1.68334 9.1547 1.90312L7.24127 2.67882L14.7684 5.60604L17.4373 4.57554L10.8453 1.90312ZM2.58998 4.56448L10.0013 7.44667L12.6917 6.40787L5.21503 3.50027L2.58998 4.56448ZM21 15.501C21 12.4634 18.5376 10.001 15.5 10.001C12.4624 10.001 10 12.4634 10 15.501C10 18.5385 12.4624 21.001 15.5 21.001C18.5376 21.001 21 18.5385 21 15.501ZM15.5424 12.0028L15.6281 12.0176L15.691 12.0388L15.7585 12.0729L15.806 12.105L18.3536 14.6474L18.4114 14.7167C18.5464 14.9115 18.5271 15.181 18.3536 15.3545C18.18 15.5281 17.9106 15.5474 17.7157 15.4124L17.6464 15.3545L15.999 13.707L16 19.001L15.9919 19.0909C15.9549 19.2949 15.794 19.4559 15.5899 19.4929L15.5 19.501L15.4101 19.4929C15.206 19.4559 15.0451 19.2949 15.0081 19.0909L15 19.001L14.999 13.709L13.3536 15.3545L13.2843 15.4124C13.1138 15.5305 12.8862 15.5305 12.7157 15.4124L12.6464 15.3545L12.5886 15.2853C12.4705 15.1148 12.4705 14.8872 12.5886 14.7167L12.6464 14.6474L15.1589 12.1354L15.2153 12.0898L15.2886 12.0477L15.3719 12.0176L15.432 12.006L15.488 12.001L15.5424 12.0028Z" fill="#5D5D61"/>

    </svg>
  );
};

BackInStock.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

BackInStock.defaultProps = {
  width: '21px',
  height: '21px',
};

export default BackInStock;
