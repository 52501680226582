import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CardWrapper } from 'components/search/faceted-search/common';
import {
  AddFacetButton,
  Facets,
  FacetedOrdering,
  SearchResult,
} from 'components/search/faceted-search/campaign/general';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFacetedSearchList,
  updateCampaignForm,
} from 'actions/faceted-search';
import classnames from 'classnames';
import { FACETED_SEARCH_NAME } from 'constants/faceted-search';
import Contents from 'components/search/faceted-search/campaign/general/Contents';
import { t } from 'system/ui';
import Banners from '../../../common/Banners';
import { updateBanner, updateBannersCopy } from '../../../../../actions/faceted-search';

const General = ({ mode, instanceId }) => {
  const state = useSelector(s => s.facetedSearch);
  const switchedUser = useSelector(s => s.switchedUser);
  const dispatch = useDispatch();
  const [showStatusBadgeOnNew, setShowStatusBadge] = useState(false);
  useEffect(
    () => {
      if (switchedUser.switchedUser.username?.length > 0)
        dispatch(getFacetedSearchList());
    },
    [switchedUser],
  );
  useEffect(
    () => {
      if (mode === 'edit' && state.ui.loaded) {
        dispatch(updateCampaignForm());
        dispatch(
          updateCampaignForm(
            state.campaigns.find(s => s.instanceId === instanceId),
          ),
        );
        dispatch(updateBanner(instanceId));
      } else if (mode === 'new' && state.ui.loaded) {
        dispatch(updateCampaignForm(null));
      }
    },
    [state.ui.loaded],
  );
  useEffect(
    () => {
      if (state.ui.saveCampaign.loaded) {
        setShowStatusBadge(true);
      }
    },
    [state.ui.saveCampaign.loaded],
  );

  const { status } = state.campaignForm;

  const statusBadgeClass = classnames('status-label', {
    'status-label-live': status === 'ACTIVE',
    'status-label-test': status === 'DRAFT',
    'status-label-passive': status === 'PASSIVE',
  });

  const statusText = {
    ACTIVE: 'Live',
    DRAFT: 'Draft',
    PASSIVE: 'Passive',
  };

  return (
    <div id="faceted-search-tab-general-page">
      <br />
      <h3 className="page-title">
        {(mode === 'edit' || showStatusBadgeOnNew) && (
          <span className={statusBadgeClass}>{statusText[status]}</span>
        )}
        {FACETED_SEARCH_NAME}
      </h3>
      <CardWrapper
        id="faceted-card-search"
        title="Search"
        tooltipText="Thanks to this field, you can choose the number of products to be displayed on each page on a device basis."
        description="Choose the number of products to show on each page in search results"
      >
        <SearchResult />
      </CardWrapper>
      <CardWrapper
        id="faceted-card-facets"
        title="Facets"
        tooltipText="Provides management of filtering capabilities. Adding, removing, changing the view type, determining the number of filters to be displayed is done with the help of this field."
        description="Select the facets you want to show on the search result pages"
        headerRightComponent={<AddFacetButton />}
      >
        <Facets />
      </CardWrapper>
      <FacetedOrdering />
      <CardWrapper
        id="faceted-card-content"
        title="Contents"
        tooltipText="Customize your search results page by adding banners, buttons on your search results pages."
        description="Add your custom content to customize your page"
        headerRightComponent={
          <button
            type="button"
            className="add-facet-button"
            onClick={() => {
              dispatch(
                updateCampaignForm({
                  customContents: [
                    ...state.campaignForm.customContents,
                    { key: '', html: '' },
                  ],
                }),
              );
            }}
          >
            <div className="add-facet-button-inner">
              <label htmlFor="-">{t('add-content')}</label>
            </div>
          </button>
        }
      >
        <Contents />
      </CardWrapper>
      <Banners
        banners={state.banners}
        isInstant={false}
        onChange={banners => dispatch(updateBannersCopy(banners))}
        removeOlParentClass={false}
      />
    </div>
  );
};

General.defaultProps = {
  instanceId: null,
};

General.propTypes = {
  mode: PropTypes.oneOf(['new', 'edit']).isRequired,
  instanceId: PropTypes.string,
};

export default General;
