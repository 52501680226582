import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const LightBulbIcon = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 16 20'
      fill='none'
    >
      <path d='M8 0.000980377C12.0041 0.000980377 15.25 3.24691 15.25 7.25098C15.25 9.347 14.3493 11.2707 12.5869 12.9929C12.51 13.0681 12.4504 13.1586 12.4118 13.2582L12.3804 13.3605L11.2493 18.2561C11.0266 19.22 10.2035 19.9183 9.2302 19.993L9.057 19.9996H6.9433C5.95374 19.9996 5.08791 19.3545 4.79629 18.4228L4.75088 18.2555L3.62132 13.3607C3.58904 13.2208 3.51728 13.0931 3.41456 12.9928C1.73515 11.3526 0.83778 9.5297 0.75613 7.54923L0.75 7.25098L0.75388 7.01166C0.88014 3.11837 4.07601 0.000980377 8 0.000980377ZM10.115 16.499H5.884L6.2125 17.9182C6.2831 18.2245 6.5357 18.4504 6.8401 18.4925L6.9433 18.4996H9.057C9.3713 18.4996 9.6481 18.3044 9.7577 18.0174L9.7878 17.9184L10.115 16.499ZM8 1.50098C4.89821 1.50098 2.37006 3.95699 2.25415 7.03042L2.25 7.25098L2.25672 7.52799C2.33286 9.0913 3.05722 10.5471 4.46262 11.9197C4.72675 12.1777 4.92265 12.496 5.03422 12.846L5.08291 13.0235L5.538 14.999H7.25V8.7503C7.25 8.3361 7.5858 8.0003 8 8.0003C8.4142 8.0003 8.75 8.3361 8.75 8.7503V14.999H10.461L10.9189 13.0228C11.0019 12.6634 11.1718 12.3309 11.4124 12.0539L11.5386 11.9201C12.9432 10.5475 13.6672 9.0916 13.7433 7.52803L13.75 7.25098L13.7458 7.03042C13.6299 3.95699 11.1018 1.50098 8 1.50098Z' />
    </svg>
  );
};

LightBulbIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

LightBulbIcon.defaultProps = {
  width: '16px',
  height: '20px',
};

export default LightBulbIcon;
