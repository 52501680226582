import { $http } from '../../system/ajax';
import { getRestEndPoint } from '../../system/api';
import { getApiKey } from '../auth/user';

export const getReplenishmentProducts = callback => {
  return $http(`${getRestEndPoint('replenishment/list')}`)
    .get({
      apiKey: getApiKey(),
    })
    .then(response => {
      callback(response);
    });
};

export const updateReplenishmentProducts = updatedProducts => {
  const url = `${getRestEndPoint(
    'replenishment/update',
  )}?apiKey=${getApiKey()}`;

  return $http(url).post(updatedProducts, { type: 'json' });
};
