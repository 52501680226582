import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';
import { t } from '../../system/ui';

import Icon from '../icon';
import { pushAlgorithmTitles } from '../../constants/datamaps/push';

const nonEditableTypes = ['permission', 'bulk', 'recommendation', 'fly'];

const nonEditableTexts = {
  permission: {
    title: 'Permission Prompt',
    linkTo: '/push/add/PUSH_PERMISSION',
  },
  bulk: {
    title: 'Bulk / Segmented Push',
    linkTo:
      window.location.pathname.indexOf('ext/push') >= 0
        ? '/ext/push/add/PUSH_NOTIFICATION'
        : '/push/add/PUSH_NOTIFICATION',
  },
  recommendation: {
    title: 'Recommendation',
    linkTo:
      window.location.pathname.indexOf('ext/push') >= 0
        ? '/ext/push/add/RECOMMENDATION'
        : '/push/add/RECOMMENDATION',
  },
  fly: {
    title: 'Fly Mode',
    linkTo:
      window.location.pathname.indexOf('ext/push') >= 0
        ? '/ext/push/add/FLY'
        : '/push/add/FLY',
  },
};

class PushTypeSelection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { pushType } = this.props;
    const uniqueClass = `available-type-${
      pushType === 'permission'
        ? 'push-push-permission'
        : 'push-push-notification'
    }`;
    const campaigns = this.props.pushGroups[pushType];
    return (
      <div
        className={classNames(
          'available-types available-widgets available-widgets-mail',
        )}
      >
        <h3 className='widget-title-head'>{this.props.title}</h3>
        <p className='widget-title-desc'>{this.props.description}</p>
        {nonEditableTypes.indexOf(pushType) > -1 ? (
          <div className='available-type-selection'>
            <span>
              <Link
                to={nonEditableTexts[pushType].linkTo}
                className={classNames('available-type', uniqueClass)}
              >
                <h4 className='available-type-name'>
                  {nonEditableTexts[pushType].title}
                </h4>
                <span>Campaign</span>
                <span className='available-type-wireframe' />
              </Link>
            </span>
            <span>
              <Link
                to={nonEditableTexts[pushType].linkTo}
                className='available-type available-type-custom'
              >
                <span className='available-type-wireframe'>
                  <Icon name='plus' size='2x' />
                  <span>{t('Add Campaign')}</span>
                </span>
              </Link>
            </span>
          </div>
        ) : (
          <div className='available-type-selection'>
            {campaigns.map(campaign => {
              if (campaign === 'ANNIVERSARY') return;
              const isNew = this.props.allPushCampaigns.indexOf(campaign) < 0;
              let link = isNew
                ? `/push/add/${campaign}`
                : `/push/edit/${campaign}`;
              if (window.location.pathname.indexOf('/ext/push') >= 0) {
                link = `/ext${link}`;
              }
              const randomImageInd =
                Math.floor(Math.random() * (4 - 1 + 1)) + 1;
              const uniqueSubClass = `available-type-email_tumb_0${randomImageInd}`;
              const isLive =
                this.props.livePushCampaigns.indexOf(campaign) > -1;
              return (
                <span key={campaign}>
                  <Link
                    to={{ pathname: link, state: { setInitialData: isNew } }}
                    className={classNames(
                      'available-type',
                      'list-email',
                      uniqueClass,
                      {
                        'list-email-live': isLive,
                      },
                    )}
                  >
                    <h4 className='available-type-name'>
                      {pushAlgorithmTitles(campaign).campaignTitle}
                    </h4>
                    <span className='available-type-wireframe'>
                      {isLive && (
                        <div className='list-email-live-label'>Live</div>
                      )}
                    </span>
                    <p className='list-email-desc'>
                      {pushAlgorithmTitles(campaign).description}
                    </p>
                  </Link>
                </span>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default PushTypeSelection;
