/**
 * Created by mehmetyurtar on 25/05/2017.
 */

import React from 'react';
import { connect } from 'react-redux';

import { wizardActions, uiActions } from '../../actions';

import IncludesCustom from './includes-custom';
import IncludesCategory from './includes-category';
import IncludesPriceRange from './includes-price-range';
import IncludesBrand from './includes-brands';
import IncludesLabel from './includes-label';
import IncludesGender from './includes-gender';
import IncludesSize from './includes-size';
import IncludesStock from './includes-stock';
import IncludesColor from './includes-color';
import IncludesDiscounted from './includes-discounted';

const inclusionTypes = {
  price_range: IncludesPriceRange,
  category: IncludesCategory,
  brand: IncludesBrand,
  label: IncludesLabel,
  gender: IncludesGender,
  custom: IncludesCustom,
  size: IncludesSize,
  stock: IncludesStock,
  color: IncludesColor,
  discounted_product: IncludesDiscounted,
};

class Includes extends React.Component {
  constructor(props) {
    super(props);

    this.handleInclusionRemove = this.handleInclusionRemove.bind(this);
    this.handleInclusionChange = this.handleInclusionChange.bind(this);
    this.handleInclusionSelectChange = this.handleInclusionSelectChange.bind(
      this,
    );
    this.handleInclusionAdd = this.handleInclusionAdd.bind(this);
  }

  handleInclusionRemove(inclusionId) {
    let modifiedInclusions;

    modifiedInclusions = this.props.includes.filter(include => {
      return include.id !== inclusionId;
    });

    if (this.props.selectedPage === 'afterSearch') {
      wizardActions.removeNoResultInclusion(modifiedInclusions);
    } else {
      wizardActions.removeInclusion(modifiedInclusions);
    }
    uiActions.formEdited();
  }

  handleInclusionChange(fields, inclusionId, options) {
    const oldIncludes = this.props.includes;
    const inputs = fields.querySelectorAll('.criteria-field');
    const values = {};
    let includes = {};

    if (options && Object.keys(options).length > 0) {
      includes = oldIncludes.filter(include => {
        if (include.type !== options.reset) {
          return true;
        }
        return include.id === inclusionId;
      });
    } else {
      includes = oldIncludes;
    }

    inputs.forEach(item => {
      values[item.name] = {
        value: item.value,
      };
    });

    for (let i = 0; i < includes.length; i++) {
      if (includes[i].id === inclusionId) {
        includes[i].values = values;
        break;
      }
    }

    if (this.props.selectedPage === 'afterSearch') {
      wizardActions.addNoResultInclusion(includes);
    } else {
      wizardActions.addInclusion(includes);
    }
    uiActions.formEdited();
  }

  handleInclusionSelectChange(fieldName, newValues, inclusionId) {
    const { includes } = this.props;
    const values = {};
    values[fieldName] = {
      value: [],
    };

    const items = [];
    if (newValues) {
      newValues.forEach(item => {
        items.push(item.value);
      });
      values[fieldName].value = items;
      for (let i = 0; i < includes.length; i++) {
        if (includes[i].id === inclusionId) {
          includes[i].values = values;
          break;
        }
      }
    }

    if (this.props.selectedPage === 'afterSearch') {
      wizardActions.addNoResultInclusion(includes);
    } else {
      wizardActions.addInclusion(includes);
    }
    uiActions.formEdited();
  }

  handleInclusionAdd(type) {
    let current = this.props.includes;
    current = current.concat({ id: Math.random(), type, values: {} });
    if (this.props.selectedPage === 'afterSearch') {
      wizardActions.addNoResultInclusion(current);
    } else {
      wizardActions.addInclusion(current);
    }
  }

  render() {
    const subComponents = {};
    const allComponents = [];
    this.props.includes.forEach(include => {
      if (include.type) {
        if (subComponents[include.type]) {
          subComponents[include.type].push(include);
        } else {
          subComponents[include.type] = [include];
        }
      }
    });
    Object.keys(subComponents).forEach(subComponent => {
      const items = subComponents[subComponent];
      items.forEach((item, ind) => {
        const Component = inclusionTypes[item.type];
        const renderedComponent = (
          <Component
            key={item.id}
            {...item}
            onInclusionRemove={this.handleInclusionRemove}
            onInclusionChange={this.handleInclusionChange}
            onInclusionSelectChange={this.handleInclusionSelectChange}
            onInclusionAdd={this.handleInclusionAdd}
            selectedPage={this.props.selectedPage}
            subComponents={subComponents[item.type]}
          />
        );
        if (subComponent === 'custom' || ind === 0) {
          allComponents.push(renderedComponent);
        }
      });
    });
    if (this.props.includes.length > 0) {
      return (
        <ol className='exclusion-list criteria-list wizard-items-container'>
          {allComponents}
        </ol>
      );
    }
    return '';
  }
}

const mapStatesToProps = (store, thisProps) => ({
  includes:
    thisProps.selectedPage === 'afterSearch'
      ? store.wizard.noResultIncludes
      : store.wizard.includes,
});

export default connect(mapStatesToProps)(Includes);
