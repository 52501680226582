/**
 * @author Mehmet Yurtar
 */

/* eslint react/display-name: "off" */

import React from 'react';

// shared of config translations
// add component translation files together with the component
export const de = {
  // Menu
  Dashboard: 'Dashboard',
  Recommend: 'Empfehlungen',
  'View All': 'Alle ansehen',
  Report: 'Bericht',
  Reports: 'Berichte',
  'Executive Summary': 'Überblick',
  Sales: 'Absatz',
  Trendify: 'Trendify',
  Products: 'Produkte',
  Categories: 'Kategorien',
  Brands: 'Marken',
  Insights: 'Insights',
  Bannerify: 'Bannerify',
  Settings: 'Einstellungen',
  Account: 'Konto',
  Plugins: 'Plugins',
  Payments: 'Zahlungen',
  Integrations: 'Integrations',
  Currency: 'Währung',
  'Segmentation Module': 'Segmentation',
  'Recommendation Settings': 'Recommendation',

  // Dashboard
  'WidgetActual View': 'Impressionen',
  WidgetConversion: 'Ansichtsrate',
  EmailConversion: 'Impressionsrate',
  'WidgetTotal View': 'Seitenaufrufe',
  ImpressionRate: 'Impressionsrate',
  InteractionConversion: 'CTR',
  'InteractionLeft Arrow Click': 'Left Arrow Click',
  'InteractionRight Arrow Click': 'Right Arrow Click',
  'BasketTotal Amount': 'Gesamtbetrag',
  'BasketAverage Contribution': 'Durchschnittliche Beisteuerung',
  BasketConversion: 'Warenkorbrate',
  RevenuePurchases: 'Kaufwert',
  'RevenuePurchase Items': 'Gekaufte Artikel',
  RevenueConversion: 'Conversion',
  'RevenueContribution Ratio': 'Beisteuerungsrate',

  view: 'Seitenaufruf',
  item: 'Artikel',
  views: 'Seitenaufrufe',
  click: 'Klick',
  banner_clicks: 'banner kliks',
  product_clicks: 'produkt kliks',
  items: 'Artikel',

  'Segmentify Performance': 'Segmentify Leistung',
  'Your Website': 'Ihre Website',

  Widget: 'Widget',
  Interaction: 'Interaktion',
  Revenue: 'Umsatz',
  'Page View': 'Seitenaufruf',
  Device: 'Gerät',
  'Audience-Website': 'Zielgruppe',
  Audience: 'Audience',
  Overall: 'Gesamt',
  'Revenue (Overall)': 'Gesamtumsatz',
  'Average Sessions': 'Durchschnittliche Sitzungen',
  'Actual View': 'Impression',
  Conversion: 'Conversion',
  'Total Amount': 'Gesamtbetrag',
  'Average Contribution': 'Durchschnittliche Beisteuerung',
  Purchases: 'Kaufwert',
  'Purchase Items': 'Gekaufte Artikel',
  'Average Basket': 'Durchschnittlicher Warenkorb',
  'Contribution Ratio': 'Beisteuerungsrate',
  PC: 'Computer',
  Mobile: 'Mobile',
  Tablet: 'Tablet',
  'Unique Visitors': 'Besucher',
  'Unique Sessions': 'Sitzungen',
  'Pages/Sessions': 'Seitenaufrufe/Sitzung',
  'Product/Sessions': 'Produkte/Sitzung',

  // Recommendation Page Types
  Page: 'Seite',
  'Home Page': 'Homepage',
  'Category Page': 'Kategorieseite',
  'Product Page': 'Produktseite',
  'Basket Page': 'Warenkorb',
  'Search Page': 'Suchergebnisseite',
  '404 Page': '404 Seite',
  'Custom Pages': 'Individuelle Seiten',
  'Trigger Based Page': 'Trigger Based Campaign',
  'Other Pages': 'Andere Seiten',
  'Custom Page': 'Individuelle Seite',
  Home: 'Startseite',
  Category: 'Kategorie',
  Product: 'Produkt',
  Basket: 'Warenkorb',
  Search: 'Suche',
  Palantir: 'Suche',
  Other: 'Andere',

  // Campaign Actions
  Actions: 'Aktionen',
  Edit: 'Bearbeiten',
  Duplicate: 'Klonen',
  History: 'History',

  // Persona Quiz
  Add_New_Response: 'Neue Antwort Hinzufügen',

  'Live Campaigns': 'Live Kampagnen',
  'Test Campaigns': 'Test Campaigns',

  // When there is no Active Campaign
  NoActiveHomeCampaigns: () => {
    return (
      <div>
        <p>
          Der aktuelle Trend in Sachen Homepage Design im Bereich e-Commerce ist
          sehr bannerlastig. Konkret bedeutet das, dass vor allem Kampagnen-,
          Angebots- und Produktkategoriebanner auf der Homepage dargestellt
          werden. Um Besucher direkt von Beginn an in zu einem Kauf zu führen
          und erfolgreich zu konvertieren hat es sich als wirksam erwiesen die
          Hompage gezielt mit personalisierten Empfehlungswidgets, wie zum
          Beispiel "Für dich ausgewählt", zu optimieren.
        </p>
        <p>
          Bitte wählen Sie unten einen Widgettyp aus, um{' '}
          <strong>ein neues Empfehlungswidget zur Hompage hinzuzufügen.</strong>
        </p>
      </div>
    );
  },
  NoActiveCategoryCampaigns: () => {
    return (
      <div>
        <p>
          Kategorieseiten haben erfahrungsgemäß viel Traffic, da Sie gut als
          Landingpages für Produktkategorien, Marken, oder Ähnliches genutzt
          werden. Leider ist auch die Bounce Rate auf Kategorieseiten
          üblicherweise relativ hoch. Um die Bounce Rate zu verringern,
          empfehlen wir das Einsetzen von "Beliebt in Kategorie"
          Empfehlungswidgets und besonders populäre Produkte hervorzuheben.
        </p>
        <p>
          Bitte wählen Sie unten einen Widgettyp aus, um{' '}
          <strong>
            ein neues Empfehlungswidget zur Kategorieseite hinzuzufügen.
          </strong>
        </p>
      </div>
    );
  },
  NoActiveProductCampaigns: () => {
    return (
      <div>
        <p>
          Produktseiten sind das Herz eines jeden Onlineshops. Die meisten
          Conversion Optimierungsansätze konzentrieren sich auf diese Seite.
          Üblicherweise beinhaltet eine Produktseite Elemente wie Produktname,
          Produktbilder, Preis, Verfügbarkeit und einen "Zum Warenkorb
          hinzufügen"-Button. Außerdem werden häufig Informationen über Größe,
          Farbvarianten, oder andere Produktdetails dargestellt werden. Die
          Produktseite eignet sich hervorragend,um den Besucher mittels
          Empfehlungswidgets zu vergleichbaren und ergänzenden Produkten zu
          führen.
        </p>
        <p>
          Bitte wählen Sie unten einen Widgettyp aus, um{' '}
          <strong>
            ein neues Empfehlungswidget zur Produktseite hinzuzufügen.
          </strong>
        </p>
      </div>
    );
  },
  NoActiveBasketCampaigns: () => {
    return (
      <div>
        <p>
          Vergrößern Sie den durchschnittlichen Warenkorb indem Sie ergänzende
          Produkte auf der Warenkorbseite in Szene setzen. Die Mehrheit Ihrer
          Besucher ist üblicherweise bereit dazu wenigstens noch einen
          zusätzlichen Artikel zum Warenkorb hinzuzufügen, sofern das empfohlene
          Produkt zum Gesamteinkauf passt.
        </p>
        <p>
          Bitte wählen Sie unten einen Widgettyp aus, um{' '}
          <strong>
            ein neues Empfehlungswidget zur Warenkorbseite hinzuzufügen.
          </strong>
        </p>
      </div>
    );
  },
  NoActiveSearchCampaigns: () => {
    return (
      <div>
        <p>
          Es ist sehr frustrierend für Besucher, wenn eine Suche nicht die
          Artikel zu Tage fördert, nach denen er sucht. Bounce Raten sind
          daherauf Suchergebnisseiten besonders hoch. Mit Hilfe von den
          Segmentify Empfehlungswidgets können Sie auch auf der
          Suchergebnisseite Ihren Besuchern gezielt personalisierte
          Produktempfehlungen präsentieren, um so die Wahrscheinlichkeit eines
          Bounces zu verringern und Besucher zurückzuholen.
        </p>
        <p>
          Bitte wählen Sie unten einen Widgettyp aus, um{' '}
          <strong>
            ein neues Empfehlungswidget zur Suchergebnisseite hinzuzufügen.
          </strong>
        </p>
      </div>
    );
  },
  NoActive404Campaigns: () => {
    return (
      <div>
        <p>
          Wenn Sie eine URL aus Ihrem Onlineshop entfernen wird diese
          üblicherweise nicht sofort auch aus Suchmaschinen und externen Links
          entfernt. Klickt nun einer Ihrer Besucher auf diese URL, so findet er
          sich auf einer 404 Seite wieder - ohne Navigation. Dieser Besucher
          wird Ihre Seite sofort verlassen. Mit Segmentify können Sie selbst auf
          der 404 Seite Produktempfehlungen ausspielen, um so Besucher zu Ihrem
          Shop zurückzuführen.
        </p>
        <p>
          PBitte wählen Sie unten einen Widgettyp aus, um{' '}
          <strong>
            ein neues Empfehlungswidget zur 404 Seite hinzuzufügen.
          </strong>
        </p>
      </div>
    );
  },
  NoActiveCustomCampaigns: () => {
    return (
      <div>
        <p>
          Wenn Sie Kampagnen auf noch nicht in Segmentify definierten Seiten
          fahren möchten (z.B. Nutzerkontoseite, Check-Out Seite, etc.), so
          können Sie diese hier mit Empfehlungswidgets versehen.
        </p>
        <p>
          Bitte wählen Sie unten einen Widgettyp aus, um{' '}
          <strong>
            ein neues Empfehlungswidget zu einer individuellen Seite
            hinzuzufügen.
          </strong>
        </p>
      </div>
    );
  },
  'NoActiveTrigger BasedCampaigns': () => {
    return (
      <div>
        <p>
          When you need a campaign based on an event instead of a page, you can
          define trigger based campaigns.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new trigger based recommendation widget.</strong>
        </p>
      </div>
    );
  },

  'Need Help?': 'Need Help?',
  'Full Width': 'Komplette Breite',
  'Right/Left Side': 'Rechte/Linke Seite',
  'Add Custom Style Widget': 'Individuelles Widget hinzufügen',

  // Add Campaign
  'Widget Title': 'Widget Titel',
  'This title will shown on your widget':
    'Dieser Titel wird in Ihrem Widget angezeigt',

  Intelligent: 'Intelligente',
  Criteria: 'Filter (Einschluss)',
  Excludes: 'Filter (Ausschluss)',
  'Shuffle Items': 'Artikel mischen',
  'Smart Offers': 'Intelligente Produktempfehlungen',
  'All Category Products': 'Alle Produkte in Kategorie',
  'All Child Category Products': 'Alle Unterkategorie Produkte',
  'All Parent Category Products': 'Alle  Überkategorie Produkte',
  'All Products': 'Alle Produkte',
  'Last Visited (Product)': 'Zuletzt Besuchte Produkte (Produktseite)',
  'Purchased Together (Basket)': 'Oft Zusammen Gekauft (Warenkorbseite)',
  'Purchased Together (Product)': 'Oft Zusammen Gekauft (Produktseite)',
  'Viewed Together (Basket)': 'Oft Zusammen Angeschaut (Warenkorbseite)',
  'Viewed Together (Product)': 'VOft Zusammen Angeschaut (Produktseite)',
  'Rockstar Products': 'Rockstar-Produkte',
  'Trending Products': 'Trendige Produkte',
  'Shining Stars': 'Shining Stars',
  'Discounted Products': 'Heruntergesetzte Produkte',
  'Brand Products': 'Markenprodukte',
  'Alternative Products': 'Alternative Produkte',
  'All Category Product': 'Alle Produkte in Kategorie',
  'View - Today': 'Aufrufe - Heute',
  'View - This Week': 'Aufrufe - Diese Woche',
  'View - This Month': 'Aufrufe – Dieser Monat',
  'Purchase - Today': 'Käufe - Heute',
  'Purchase - This Week': 'Käufe - Diese Woche',
  'Purchase - This Month': 'Käufe – Dieser Monat',
  'New Arrivals': 'Neue Produkte',
  'Product Name': 'Produkt Name',
  'Product Name/ID': 'Produkt Name/ID',
  'Price Range': 'Preisspanne',
  Custom: 'Individuell',
  Param: 'Parameter',
  Value: 'Wert',
  Brand: 'Marke ',
  Label: 'Label',
  Gender: 'Geschlecht',
  Size: 'Größe',

  min: 'Min',
  max: 'Max',

  Equals: 'Ist',
  'Not Equals': 'Ist Nicht',
  Contains: 'Enthält',
  'Not Contains': 'Enthält Nicht',

  'Campaign Subject': 'Kampagnen Betreff',

  'Start Date - Time': 'Startdatum - Zeit',
  'End Date - Time': 'Enddatum - Zeit',

  'Device Types': 'Gerätetyp',
  Web: 'Web',
  App: 'App',

  'You should select at least one device type':
    'Bitte wählen Sie mindestens eine Plattform.',

  'Target Div Selector': 'Ziel Div Selector',
  'The target component on your website where recommendation box going to insert itself':
    'Das Element auf Ihrer Webseite, an dem sich das Empfehlungswidget orientiert. Bitte nutzen Sie zur Identifikation entweder den CSS Selektor für die Klasse (Punktnotation: .className) oder ID (Rautennotation: #idName).',

  Self: 'In',
  Before: 'Vor',
  After: 'Nach',
  Append: 'Hinzufügen',
  Prepend: 'Voranstellen',

  'Edit HTML': 'HTML anpassen',
  'Edit PreJS': 'PreJS anpassen',
  'Edit PostJS': 'PostJS anpassen',

  'Advanced Configuration': 'Erweiterte Konfiguration',
  'Go Test': 'Testmodus Aktivieren',
  'Go Live': 'Livemodus Aktivieren',

  'Attention!': 'Achtung!',
  'This action will immediately effect on your website':
    'Diese Aktion wirkt sich unmittelbar auf Ihre Webseite aus.',

  'no-recommendation-report-info': statusAndCampaign => {
    return (
      <span>
        Es existiert <strong>keine {statusAndCampaign}</strong> auf dieser Seite
      </span>
    );
  },

  'no-personalization-report-info': statusAndCampaign => {
    return (
      <span>
        Es existiert <strong>keine {statusAndCampaign}</strong>{' '}
      </span>
    );
  },

  'no-campaign-info': status => {
    return (
      <span>
        Es existiert <strong>keine {status} Kampagne</strong>
      </span>
    );
  },

  'no-segment-info': status => {
    return (
      <span>
        Es existiert <strong>keine {status} Segment</strong>
      </span>
    );
  },
  'Create Now': 'Jetzt Erstellen',
  'Campaign Report': 'Kampganenreport',

  // Trendify
  'Product Stock': 'Produktverfügbarkeit',
  'Only In Stock': 'Nur Auf Lager',
  'Only Out of Stock': 'Nur Nicht Auf Lager',
  'All Product Categories': 'Alle Produktkategorien',
  'Search Category': 'Kategorie suchen',
  'Product Category': 'Produkt Kategorie',
  'Product ID or Title': 'Produkt ID oder Titel',
  'Search Product': 'Nach Produkt Suchen',
  'Product Brand': 'Produktmarke',
  'All Product Brands': 'Alle Produktmarken',
  Segmentify: 'Segmentify',
  Sources: 'Quellen',
  'Check-in': 'Check-In',
  'Product Check-in Time': 'Produkt Check-In Zeit',
  ID: 'ID',
  'Product ID': 'Produkt ID',
  'Product name': 'Produkt Name',
  'Product Categories': 'Produktkategorien',
  Price: 'Preis',
  OldPrice: 'Old Price',
  'Product price and discounted price':
    'Produktpreis und heruntergesetzter Preis',
  Image: 'Bild',
  'Product Image Url': 'Produktbild URL',
  View: 'Aufrufe',
  'Number Of Product Views': 'Anzahl an Aufrufen',
  'Number Of Add To Basket Operations': 'Anzahl an zum Warenkorb hinzugefügt',
  Rate: 'Rate',
  'Conversion Rate (Purchase/View)': 'Conversion Rate (KaufAufruf)',
  'Conversion Rate (Add To Basket/View)':
    'Conversion Rate (Zum Warenkorb hinzugefügt/Aufruf)',
  Purchase: 'Kauf',
  'Number Of Product Purchases': 'Anzahl an Produktkäufen',
  Amount: 'Wert',
  'Amount Of Product Purchases': 'Wert gekaufter Produkte',
  'Number Of Clicks By Segmentify Recommendation Widgets':
    'Anzahl von Klicks durch Segmentify Empfehlungswidgets',
  'Number Of Add To Basket Operations By Segmentify Recommendation Widgets':
    'Anzahl von zum Warenkorb hinzugefügt durch Segmentify Empfehlungswidgets',
  'Conversion Rate (Add To Basket/Click)':
    'Conversion Rate (Zum Warenkorb hinzugefügt/Klick)',
  'Number Of Purchases By Segmentify Recommendation Widgets':
    'Anzahl von Käufen durch Segmentify Empfehlungswidgets',
  'Conversion Rate (Purchase/Click)': 'Conversion Rate (Käufe/Klick)',
  'Amount Of Purchases By Segmentify Recommendation Widgets':
    'Wert gekaufter Produkte durch Segmentify Empfehlungswidgets',
  'Views Referred From In-site': 'Aufrufe von On-Site',
  'Views Referred From Social Media': 'Aufrufe von Social Media',
  'Views Referred From Search Engines': 'Aufrufe von Suchmaschinen',
  'Views Referred From Advertisements': 'Aufrufe von Werbeanzeigen',
  'Views Referred From Email Campaigns': 'Aufrufe von E-Mail Kampagnen',
  'Views Referred From Price Listing Sites': 'Aufrufe von Preisvergleichseiten',
  'Views Referred From Other Sources': 'Aufrufe von Anderen Quellen',
  Show: 'Anzeigen',
  Campaigns: 'Kampganen',
  TrendifyPromo: () => {
    return (
      <span>
        Überprüfen Sie Ihre <strong>Produktperformance</strong> in{' '}
        <strong> Echtzeit.</strong>
      </span>
    );
  },

  // Insights
  'Whenever your customers see these products they buy them':
    'Wann immer Ihre Kunden diese Produkte sehen, kaufen sie sie',
  'High Spending': 'Hohe Ausgabenprodukte',
  'Most viewed products with a referral from a paid channel':
    'Die meisten angesehenen Produkte mit einem Verweis von einem bezahlten Kanal werden aber nicht gekauft.',
  'The best of a bad bunch':
    'Diese Produkte erzeugen Interesse, werden aber nicht gekauft.',
  'These are the most viewed products among the none-selling ones':
    'Dies sind die am meisten angesehenen Produkte unter den nicht verkauften',
  'Discount Works': 'Rabatt-Produkte',
  'Most selling products among the discounted ones':
    'Die meistverkauften Produkte unter den herabgesetzten.',
  'We Wish You Had': 'Wir wünschen, Sie haben',
  'Most viewed products that are out of stock':
    'Die am meisten angesehenen Produkte, die nicht auf Lager sind',
  'Abandoned Items': 'Zurückgelassene Produkte',
  'Which products your customers left in their cart':
    'Die Produkte, die am meisten im Warenkorb zurückgelassen werden',
  'Organic Bazaar': 'Organic Bazaar',
  'Most viewed products with a referral from Search Engine':
    'Most viewed products with a referral from Search Engine',
  'Social Trends': 'Trends in Social Media Channels',
  'Most viewed products with a referral from Social Media':
    'Die am meisten angesehenen Produkte mit einem Verweis von Social-Media-Interaktionen.',
  'You push hard': 'Sie arbeiten hart',
  "Your customers see these products but they don't buy them":
    'Ihre Kunden sehen häufig diese Produkte an, aber fügen sie nicht zum Warenkorb hinzu.',
  'Products to Improve': 'Low-Performance-Produkte',
  'Worst converting products':
    'Ihre Kunden sehen häufig diese Produkte an, aber kaufen sie nicht.',
  'Most Revenue Growth (prior to compared period)':
    'Produkte mit dem höchsten Umsatzplus im Vergleich zur letzten Woche.',
  'Losing Power': 'Produkte mit sinkenden Umsatzerlösen',
  'Most Revenue Loss (prior to compared period)':
    'Produkte mit dem höchsten Umsatzverlust im Vergleich zur letzten Woche.',
  'An increasing trend occurred for these items in a selected basis':
    'Produkte mit dem höchsten Umsatzanstieg gegenüber der letzten Woche.',
  'Under Shadow': 'Produkte mit sinkenden Verkäufen',
  'A decreasing trend occurred for these items in a selected basis':
    'Produkte mit dem höchsten Umsatzrückgang im Vergleich zur letzten Woche.',
  'Basket Complementaries': 'Warenkorb-Ergänzungen',
  'List of basket complementaries for the top selling products':
    'Liste der Warenkorb-Ergänzungen für die meistverkauften Produkte.',

  // Bannerify
  Banner: 'Banner',
  'Banner Group': 'Bannergruppe',
  'Banner Titles': 'Bannertitel',

  'All Groups': 'Alle Gruppen',
  'All Titles': 'Alle Titel',
  'Show Active Banners': 'Aktive Banner Anzeigen',
  'Show All Banners': 'Alle Banner Anzeigen',

  Group: 'Gruppe',
  'Banner Order': 'Banner-Reihenfolge',
  'Check-in Time of Banner': 'Check-In Zeit des Banners',
  'Last Update': 'Letztes Update',
  'Last Update Time of Banner': 'Letzte Update Zeit des Banners',
  Title: 'Titel',
  'Banner Title': 'Banner Titel',
  'Banner Image Url': 'Banner Bild URL',
  Targets: 'Ziele',
  'Banner Target(s)': 'Banner Ziel(e)',
  Impression: 'Impression',
  'Number of Impressions': 'Anzahl der Impressionen',
  Click: 'Klick',
  'Number Of Clicks': 'Anzahl der Klicks',
  'Conversion Rate (Click/Impression)': 'Conversion Rate (Klick/Impression)',
  'Product Views': 'Produkt Aufrufe',
  'Conversion Rate (Product View/Impression)':
    'Conversion Rate (Produkt Aufruf/Impression)',
  'Conversion Rate (Add To Basket/Impression)':
    'Conversion Rate (Zum Warenkorb hinzugefügt/Impression)',
  'Number Of Purchases': 'Anzahl von Käufen',
  'Conversion Rate (Purchase/Impression)': 'Conversion Rate (Käufe/Impression)',

  BannerifyPromo: () => {
    return (
      <span>
        Überprüfen Sie Ihre <strong>Bannerperformance</strong> in{' '}
        <strong> Echtzeit.</strong>
      </span>
    );
  },
  Discover: 'Mehr Erfahren',

  of: 'von',
  Previous: 'Zurück',
  Next: 'Vor',

  // Settings
  'Account Settings': 'Kontoeinstellungen',
  'User Settings': 'Benutzereinstellungens',
  'Api Key': 'API Key',
  'Account Domain': 'Konto Domain',
  'Contact Person': 'Kontakt Person',
  'Contact Phone': 'Kontakt Telefon',
  'Contact Address': 'Kontakt Adresse',
  Username: 'Benutzername',
  'Display Name': 'Anzeigename',
  'Display Language': 'Anzeigesprache',
  'New Password': 'Neues Passwort',
  'New Password (Again)': 'Neues Passwort (Wiederholung)',
  'Customer Removal Tool': 'Customer Removal Tool',
  'Customer Removal Tool Description':
    'All historical personal data related to the given email address can be removed via this tool.',
  'Customer Removal Tool Description Label':
    "Insert customer's email address(es) here, separated by commas for more than one user.",
  'Customer Removal Tool Disclaimer': () => {
    return (
      <p>
        All historical personal information related to given email addresses
        will be deleted within the next 24 hours. When the removal process is
        finished, you will receive an email.
        <br />
        Please note that the removal process is permanent and irreversible, upon
        its completion Segmentify can't recover any data.
      </p>
    );
  },

  // Payments
  'Monthly Subscription': 'Monatliches Abo',
  'Standard Packages': 'Standard Pakete',
  Name: 'Vorname',
  Surname: 'Nachname',
  Address: 'Adresse',
  City: 'Stadt',
  Country: 'Land',
  STARTER: 'STARTER',
  BASIC: 'BASIC',
  PRO: 'PRO',
  'Payment History': 'Zahlungsprotokoll',
  mo: 'mo',
  'Package Details & Payment Info': 'Paketdetails & Zahlungsinfo',
  Date: 'Datum',
  Operation: 'Vorgang',

  // Settings.Integrations
  "Let's Integrate Segmentify": 'Segmentify Integrieren',
  Initialize: 'Initialisieren',
  'integration-script-info':
    'Die Segmentify Integration ist sehr einfach. Alles was Sie tun müssen ist die folgende Zeile JavaScript Code auf alle Seiten Ihrer Webseite ganz am Ende des HEAD Blocks einzufügen. Falls vorhanden, können Sie hierfür auch Ihren Tagmanager nutzen.',
  'Complete the Integration': 'Integration Abschließen',
  'integration-type-selection':
    'Keine Sorge, wir kümmern uns um Ihre Integration.',
  'Self Service': 'Selbstbedienung',
  IntegrationZendDesk: () => {
    return (
      <span>
        Folgen Sie den Anweisungen auf{' '}
        <a
          href='https://segmentify.zendesk.com/hc/en-us'
          target='_blank'
          rel='noreferrer'
        >
          Segmentify Customer Success Center
        </a>{' '}
        für die Integration.
      </span>
    );
  },
  StartChat: var1 => {
    return (
      <span>
        Das Segmentify Customer Success Team steht Ihnen zur Seite, um Ihnen bei
        der Integration zu helfen. <a>Jetzt einen Chat starten.</a>
      </span>
    );
  },
  'Help me, please': 'Bitte helfen Sie mir.',

  'Choose what type recommendation features you want to use from Segmentify':
    'Wählen Sie aus welche Art Empfehlungen Sie von Segmentify nutzen möchten.',
  'integration-page-types':
    'Wählen Sie den Seitentyp (wie z.B. Produktseite, Homepage, etc.) auf denen Sie Empfehlungen präsentieren möchten, sowie den Widgettyp (wie z.B. Editor’s Choice, Das Beste aus Kategorie, Last Minute Angebote).',
  'Recommended for You': 'Für Sie ausgewählt',
  'Best of Category': 'Das Beste aus Kategorie',
  'Last Minute Offers': 'Last Minute Angebote',
  'Start Recommend': 'Empfehlungen Starten',

  // 404 Texts
  'Page Not Found': 'Seite Nicht Gefunden',

  // Account Types
  type: 'Type',
  provider: 'Provider',
  FAIL: 'Fail',
  WAITING: 'Waiting',
  PRE_POC: 'Pre-POC',
  POC: 'POC',
  STAGING: 'Staging',
  FAILED: 'Failed',
  FREEMIUM: 'Freemium',
  GROUP_A: 'Group A',
  GROUP_B: 'Group B',
  GROUP_C: 'Group C',
  ARCHIVE: 'Archive',
  PAYING: 'Paying',
  ONHOLD: 'Onhold',
  CHURN: 'Churn',

  // Engagment
  Engagement: 'Personalisierung',
  Personalization: 'Personalisierung',
  'Popup Banner': 'Popup Banner',
  'Popup Recommendation': 'Popup Empfehlung',
  Popup: 'Popup',
  'Pop-Ups': 'Pop-Ups',
  'E-Mail Collection': 'E-Mail Adressen Sammeln',
  Notifications: 'Notifications',
  'Push Permission': 'Push Erlaubnis',
  'Push Notification': 'Push Notification',
  'Push Notifications': 'Push Notifications',
  'Notification Bar': 'Notification Leiste',
  'No Active Campaigns': 'Keine Aktiven Kampagnen',
  Campaign: 'Kampagne',

  'Add Popup': 'Pop-Up hinzufügen',
  'Add Popup Banner': 'Pop-Up Banner hinzufügen',
  'Add Popup Recommendation': 'Add Popup Recommendation',
  'Add E-Mail Collection Popup': 'E-Mail Adressen Sammel-Pop-Up hinzufügen',
  'Add Push Permission': 'Push Erlaubnis hinzufügen',
  'Add Push Notification': 'Push Notification hinzufügen',
  'Add Notification Bar': 'Notification Leiste hinzufügen',

  'Pages To Show': 'Auf Seiten anzeigen',
  Position: 'Position',
  Top: 'Oben',
  Middle: 'Mitte',
  Bottom: 'Unten',
  Left: 'Links',
  Center: 'Zentriert',
  Right: 'Rechts',
  'Timing & Frequency': 'Zeit & Frequenz',
  Timing: 'Zeit',
  Immediate: 'Sofort',
  Delay: 'Verzögerung',
  Scroll: 'Scrollen',
  'Visitor Leaving Page': 'Besucher Verlässt Seite',
  Always: 'Immer',
  Session: 'Sitzung',
  'Life Time': 'Life Time',
  Days: 'Tage',
  seconds: 'Sekunden',
  days: 'Tage',
  times: 'Mal',
  'Target Audience': 'Zielgruppe',
  'Visitor is Member': 'Besucher ist Kunde',
  'Visitor is not a Member': 'Besucher ist kein Kunde',
  'Visitor is': 'Besucher ist',

  // Campaign Spesifics
  'Edit Popup Banner': 'Pop-Up Banner bearbeiten',
  'Background Image URL (Recommend 800x700px)':
    'Hintergrundbild URL (empfohlene Auflösung: 800px x 700px)',
  'Push Title': 'Push Titel',
  'Push Description': 'Push Besschreibung',
  'Your Logo URL (Recommend 200x200px)':
    'Ihre Logo URL (empfohlene Auflösung 200px x 200px)',
  'Background Image URL (Recommend 1920x80px)':
    'Hintergrundbild URL (empfohlene Auflösung: 1920px x 80px)',
  'Vertical Align': 'Vertikale Ausrichtung',
  'Horizontal Align': 'Horizontale Ausrichtung',
  Once: 'Einmal',
  Periodic: 'Wiederkehrend',
  Everyday: 'Täglich',
  'Every Monday': 'Jeden Montag',
  'Every Tuesday': 'Jeden Dienstag',
  'Every Wednesday': 'Jeden Mittwoch',
  'Every Thursday': 'Jeden Donnerstag',
  'Every Friday': 'Jeden Freitag',
  'Every Saturday': 'Jeden Samstag',
  'Every Sunday': 'Jeden Sonntag',
  Scheduling: 'Planung',
  'Visitor Segment': 'Kundenbereich',
  'Visitor Type': 'Kundentyp',
  Styles: 'Stile',
  Hours: 'Stunden',
  'Valid for X': 'Gültig',
  'Push Recommendation': 'Push Produktempfehlung',
  'Description (Max 192 characters)': 'Beschreibung (Max. 192 Zeichen)',
  'Notification Icon URL (Recommended 192x192px)':
    'Notification-Icon URL (Empfohlen 192 x 192 px)',
  'Notification Image URL': 'Notification-Bild URL',
  'Target URL': 'URL Ziel',
  Frequency: 'Häufigkeit',
  'Background Color': 'Hintergrundfarbe',
  'Text Color': 'Textfarbe',
  'Your work is not saved!. Are you sure want to leave?':
    'Ihre Arbeit ist nicht gespeichert! Sind sie sicher, dass Sie beenden möchten?',
  'Scheduled Campaign': 'Geplante Kampagne',
  'Completed Campaign': 'Abgeschlossene Kampagne',
  Recommendation: 'Empfehlung',
  'unique-selector': 'CSS-Auswahl',

  // Campaign Statuses
  'Live Recommendation': 'Live Recommendation',
  'Test Recommendation': 'Test Recommendation',
  'Archived Recommendation': 'Archived Recommendation',
  Archived: 'Archived',

  'Campaign Note': 'Campaign Note',

  // Sales
  'Purchase Time': 'Purchase Time',
  'Order No': 'Order No',

  // Currency Settings
  'Main Currency': 'Main Currency',
  Code: 'Code',
  'Decimal Places': 'Decimal Places',
  'Decimal Separator': 'Decimal Separator',
  'Thousand Seperator': 'Thousand Seperator',
  Symbol: 'Symbol',
  'Place Symbol': 'Place Symbol',
  'Comma (,)': 'Comma (,)',
  'Dot (.)': 'Dot (.)',
  'Empty Space': 'Empty Space',
  None: 'None',
  'You can check your currency code here':
    'You can check your currency code here',
  "This Month's Cost": "This Month's Cost",

  // Campaigns Status Filters
  Live: 'Live',
  Test: 'Test',
  Archive: 'Archiv',
  live: 'live',
  test: 'test',

  // Campaign Device Filters
  Desktop: 'Desktop',
  'Mobile Web': 'Mobile Web',
  Android: 'Android',
  iOS: 'iOS',

  // Campaigns Sorting
  'Sort by Date': 'nach Datum sortieren',
  'Sort by Revenue': 'nach Umsatz sortieren',

  // Named Time Ranges
  All: 'Alle',
  'This Year': 'Aktuelles Jahr',
  'Last Month': 'Last Month',
  'This Month': 'Aktueller Monat',
  'This Week': 'Aktuelle Woche',
  Yesterday: 'Gestern',
  Today: 'Heute',

  // CTA Buttons
  Save: 'Speichern',
  Reset: 'Zurücksetzen',
  Update: 'Aktualisieren',
  Cancel: 'Abbrechen',
  Confirm: 'Bestätigen',
  Continue: 'Weiter',
  Export: 'Exportieren',
  'View More': 'Mehr ansehen',
  Details: 'Details',

  // Validation Texts
  'You should select a product from suggestions':
    'Bitte wählen Sie ein Produkt aus den Vorschlägen.',
  'You should define at least one recommendation':
    'Bitte definieren Sie mindestens eine Empfehlung.',
  'This field is required': 'Dieses Feld ist erforderlich',
  'This field should equal to #fieldName#':
    'Dieses Feld sollte identisch sein zu #fieldName#',
  'This field should have at least #minLength# characters':
    'Dieses Feld sollte mindestens #minLength#' + ' Zeichen haben',
  PasswordField:
    'Das Passwort muss mindestens einen Buchstaben und eine Zahl beinhalten und darf nur aus Buchstaben, Zahlen und folgenden Sonderzeichen bestehen: ! @ # . _ - ( )',
  EmailField: 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
  'Field must be a valid URL.': 'Field must be a valid URL.',
  DeliveryRequired:
    'You should activate at least one option from the Delivery Steps above',

  // Update notification Bars
  'Recommendation widget is updated in test mode.Chrome Extension.':
    'Das Produktempfehlungs-Widget wurde im Test-Modus aktualisiert. Sie können es ansehen, indem Sie den Test-Modus durch die Chrome Extension in Ihrem Browser aktivieren.',
  'Recommendation widget is updated in live mode.':
    'Das Produktempfehlungs-Widget wurde im Live-Modus aktualisiert. ',
  'Recommendation widget is activated in test mode and only test users can see. You can check the widget by activating test mode in your browserChrome Extension.':
    'Das Produktempfehlungs-Widget wurde im Test-Modus erstellt. Sie können das Widget im Test-Modus aufrufen, indem Sie die Chrome Extension in Ihrem Browser aktivieren.',

  // ====== 24 AUGUST 2017 ======= //
  /// Insights
  allView: 'Total Views',
  purchase: 'Purchases',
  basket: 'Add to Baskets',
  allViewCurWeek: 'Ansichten der zweiten Periode',
  purchaseCurWeek: 'Einkäufe der zweiten Periode',
  allViewLastWeek: 'Ansichten der ersten Periode',
  purchaseLastWeek: 'Einkäufe der ersten Periode',
  allPaidClick: 'Klicks über einen bezahlten Kanal',
  allSearchClick: 'Klicks über eine Suchmaschine',
  allSocialClick: 'Klicks über Social Media Kanäle',
  allAmountLastWeek: 'Umsatz erste Periode',
  allAmountCurWeek: 'Umsatz der zweiten Period',
  amount: 'Umsatz',

  // Emails
  LAST_VISIT_ALTERNATIVES: 'ERINNERUNG LETZTER BESUCH',
  ABANDONED_CART: 'VERLASSENER WARENKORB',
  PERSONALIZED_NEWSLETTER: 'PERSONALISIERTER NEWSLETTER',
  PURCHASE_REMINDER: 'KAUFERINNERUNG',

  // Provider Warnings
  ShopifyWarning:
    'Sie haben bereits die Segmentify App installiert. Wir beraten Sie bei der Verwendung der Segmentify App in Ihrem Shopify Store Verwaltungsbereich.',
  PrestashopWarning:
    'Sie haben bereits das Segmentify Plug-in installiert. Wir beraten Sie bei der Verwendung des Segmentify Add-on in Ihrem Prestashop Verwaltungsbereich.',
  MagentoWarning:
    'Sie haben bereits das Segmentify Plug-in installiert. Wir beraten Sie bei der Verwendung des Segmentify Add-on in Ihrem Magento Verwaltungsbereich.',

  'All Recommendations': 'Empfehlungen',
  Recommendations: 'Empfehlungen',
  'Trigger Based Campaign': 'Trigger Basiert',
  'Form - Survey': 'Formular - Umfrage',
  'Form Survey': 'Formular - Umfrage',
  'Add Form - Survey': 'Formular erstellen - Umfrage',
  'In Future/Recurring Notifications':
    'Geplante / wiederkehrende Benachrichtigung',
  'Sent Notifications': 'Gesendete Benachrichtigungen',
  'WidgetSent Notifications': 'Sent Notifications',
  'Add Push Recommendation': 'Push-Empfehlung erstellen',
  'All Campaigns': 'Alle Kampagnen',
  'All Campaign Reports': 'Alle Kampagnen-Berichte',
  'Date Comparison': 'Vergleichen',
  'Compare To': 'Datumsbereiche vergleichen',
  'Widget View': 'Widget-Ansicht',
  'Basket Items': 'Artikel im Warenkorb',
  'Purchased Items': 'Gekaufte Artikel',
  'Form Submit': 'Gesendete Formulare',
  'Notification [shown]': 'Benachrichtigung gesendet',
  'Notification [sent]': 'Notification Sent',
  'Notification [clicked]': 'Benachrichtigung angeklickt',
  'Notification Permission [granted]': 'Benachrichtigungsberechtigung gewährt',
  'Notification Permission [denied]': 'Benachrichtigungsberechtigung abgelehnt',
  'Purchase Amount': 'Gesamtbetrag des Einkaufs',
  Quantity: 'Menge',
  'Payment Type': 'Zahlungsart',
  'Cart Detail': 'Warenkorb Details',
  'Sale Time': 'Datum',
  'Graphical View': 'Grafikansicht',
  'Conversion Graph': 'Umrechnungsgrafik',
  'Revenue Graph': 'Umsatzgrafik',
  'Purchase Graph': 'Grafik der Einkäufe',
  'Page Graph': 'Grafik der Seitenaufrufe',
  'Device Graph': 'Gerätegrafik',
  'Audience Graph': 'Grafik der Besucher',
  'Segmentify Graph': 'Segmentify Grafik',
  Stock: 'Lager',
  'Price at Cart': 'Preis im Warenkorb',
  Order: 'Bestellung',
  'Export Products': 'Produkte exportieren',
  'Export File Name': 'Dateiname',
  'Page Selection': 'Seitenauswahl',
  'CSV Delimiter': 'CSV-Trennzeichen',
  'Self Service? See your account specific values':
    'Self Service IntegrationKlicken Sie hier zur Anzeige Ihrer kontospezifischen Werte',
  'Custom Segmentify integration requires two important values.':
    'Für die personalisierte Segmentify Integration sind zwei wichtige Werte notwendig',
  'Your account specific keys': 'Ihre speziellen Kontoschlüssel',
  'System Variable': 'Systemvariable',
  Description: 'Beschreibung',
  'Current Page': 'Aktuelle Seite',
  'All Pages': 'Alle Seiten',
  'Semicolon (;)': 'Semikolon (;)',
  'Tab (\\t)': 'Onglet (\\t)',
  'Export Bannerify Report': 'Export Bannerify Report',
  Banners: 'Bannières',
  'Grid View': 'Vue grille',
  'Funnel View': 'Vue en entonnoir',
  'Event Name': "Nom de l'événement",
  'Back To Wizard': "Retour à l'assistant",
  'Your work is not saved! Are you sure want to leave?':
    "Votre travail n'est pas sauvegardé ! Êtes-vous sûr de vouloir quitter ?",
  'There is no campaign': "Il n'y a pas de campagne",
  'Edit CSS': 'Éditer le code CSS',
  'Visitor is not': "Le visiteur n'est pas",
  New: 'Nouveau',
  Registered: 'Inscrit',
  Logged: 'Connecté',
  'Select Page': 'Sélectionner la Page',
  Type: 'Caractère',
  'Checkout Page': 'Page de paiement',
  PrivacyPolicyCta:
    "Avez-vous besoin d'ajuster votre politique en matière de cookies et de confidentialité pour utiliser Segmentify ?",
  PrivacyPolicyContent:
    "Si votre magasin en ligne est présent en Europe, vous devrez alors ajuster votre politique de confidentialité pour vous conformer aux loisde l'Union européenne (U.E). Ces lois imposent aux sites web d'obtenir le consentement des utilisateurs terminaux avant de stocker les données sur l'ordinateur du visiteur du site.",
  PrivacyPolicy: () => {
    return (
      <a
        href='https://segmentify.com/privacy-policy/'
        target='_blank'
        rel='noreferrer'
      >
        Learn More
      </a>
    );
  },

  // ======  2017.08 ======= //
  Labels: 'Labels',

  // ======= 2017.09 ========= //
  Preview: 'Vorschau der Push-Benachrichtigungen',
  "Your browser doesn't support Notification":
    'Ihr Browser unterstützt keineWeb-Push-Benachrichtgungen',
  'You have to allow notifications to preview':
    'Sie müssen Web-PushBenachrichtigungen erlauben, um die Vorschau aufzurufen',
  'Scheduled Campaigns': 'geplante Kampagnen',
  'Purchases via Banners': 'Käufe über Banner',
  'Event Data': 'Veranstaltungsdaten',
  'You should select a promotion from suggestions':
    'Sie sollten eine Werbeaktion aus den Vorschlägen auswählen',
  'Purchases and Items': 'Käufe und Produkte',

  'Recommended For You': 'Für Sie empfohlen',
  'Most Popular': 'Beliebteste Produkte in dieser Kategorie',
  'Products You Recently Viewed': 'Produkte, die Sie zuletzt angesehen haben',
  'You May Also Like': 'Sie könnten auch mögen',
  'Account Statistics': 'Kontostatistiken',
  'Checkout Success Page': 'Seite für erfolgreichen Kassenvorgang',
  'NoActiveCheckout SuccessCampaigns': () => {
    return (
      <div>
        Es gibt keine aktive Kampagne auf der Seite für erfolgreichen
        Kassenvorgang
      </div>
    );
  },
  'Selected For You': 'Für Sie ausgewählt',
  'Most Popular Products in this Category':
    'Beliebteste Produkte in dieser Kategorie',
  'You Might Also Like': 'Ihnen könnte ebenso gefallen',
  'Frequently Bought Together': 'Häufig zusammen gekauft',
  'Complementary Products': 'komplementäre Produkte',
  'You Recently Viewed': 'Sie haben zuletzt angesehen',
  'Do you want to check once more?': 'Möchten Sie es erneut überprüfen?',
  'Top Sellers': 'Bestverkaufte Produkte',
  'Top Sellers in this Category': 'Bestverkaufte Produkte in dieser Kategorie',
  'You should select at least one page type':
    'Sie sollten mindestens eine Seitenart auswählen',
  'Select Pages': 'Seiten auswählen',
  'Segmented Users': 'Segmentierte Benutzer',

  Champions: 'Rockstars',
  'Loyal Customers': 'Gehaltene Kunden',
  'Potential Loyalist': 'Werdende Fans',
  'New Customers': 'Einsteiger',
  Promising: 'wenig ausgebende Einsteiger',
  'Customers Needing Attention': 'entwischende Fans',
  'About To Sleep': 'entwischende Kunden',
  'At Risk': 'entwischende Rockstars',
  "Can't Lose Them": 'Haben gewöhnlich eine Menge ausgegeben',
  Hibernating: 'Inaktive Kunden',
  Lost: 'Lost customers',
  // ======= 2017.10 ========= //
  'Segment Visitor': 'Besucher segmentieren',
  'Unsegment Visitor': 'Besuchersegmentierung aufheben',
  'Toggle Visitor Segment': 'Besuchersegment umschalten',
  Segmentation: 'Segmentierung',
  Keyword: 'Schlüsselwort',
  keyword: 'Schlüsselwort',
  keywords: 'Schlüsselworte',
  'Search Keyword': 'Such-Schlüsselwort',
  'Add Search Keyword Recommendation':
    'Such-Schlüsselwortempfehlung hinzufügen',
  Count: 'Lagerbestand',
  Ratio: 'Verhältnis',
  'Stock Count': 'Lagerbestand',
  'Stock Ratio': 'Lagerbestandsverhältnis',
  'Greater Than': 'mehr als',
  'Less Than': 'weniger als',
  'pages selected': 'Ausgewählte Seiten',
  'No Results': 'Keine Ergebnisse',
  'You can see only last 50 results.':
    'Sie können nur die letzten 50 Ergebnisse sehen.',
  'You can see only last 50 emails.':
    'Sie können nur die letzten 50 E-Mails sehen.',
  "Can't create user, user exists with same email.":
    'Kann Benutzer nicht erstellen, Benutzer existiert bereits mit der selben E-Mail',
  'categories selected': 'ausgewählte Kategorien',
  'category selected': 'ausgewählte Kategorie',
  'brands selected': 'ausgewählte Marken',
  'brand selected': 'ausgewählte Marke',
  'View List': 'Liste ansehen',
  'View Stats': 'Statistiken ansehen',
  Stats: 'Statistiken ansehen',
  'There is no data': 'es gibt keine Daten',
  responses: 'Reaktionen',
  response: 'Reaktionen',

  // ======= 2017.11 ========= //
  'Select Products': 'Produkte auswählen',
  'Search Products': 'Nach Produkten suchen',
  'Add Selected Products': 'Ausgewählte Produkte hinzufügen',
  'Your changes will be lost!': 'Ihre Änderungen gehen verloren',
  'Back to the defaults': 'Zurück zu den Standardeinstellungen',
  'Other Languages': 'Weitere Sprachen',
  Sticky: 'Haftend',
  'Banner Performance': 'Banner-Performance',
  'Purchased Products': 'Gekaufte Produkte',

  // ======= Field Descriptions ========= //
  // Popup Banner
  'Enter the title that your visitors will see on the pop-up banner.':
    'Geben Sie den Titel ein, den Ihre Kunden auf dem Popup-Banner sehen werden.',
  'Enter the URL of the image that will be used as the background image of the pop-up banner.':
    'Geben Sie die URL des Bildes an, das als Hintergrundbild für das Pop-Up-Banner benutzt wird.',
  'Enter the URL of the page that your visitors will be directed when they click on the pop-up banner.':
    'Geben Sie die URL der Seite an, auf die Ihre Besucher geleitet werden, wenn sie auf das Pop-Up-Banner klicken.',
  position_POPUP_BANNER:
    'Sie können die Position des Pop-Up-Banners auf der Seite festlegen.',
  verticalPosition_POPUP_BANNER:
    'Sie können die Position des Pop-Up-Banners oben, in der Mitte oder unten auf der Seite festlegen.',
  horizontalPosition_POPUP_BANNER:
    'Sie können die Position des Pop-Up-Banners links, in der Mitte oder rechts auf der Seite festlegen.',
  timingTitle_POPUP_BANNER:
    'Sie können die Zeit und die Frequenz festlegen, wann Ihr Pop-Up-Banner angezeigt wird. ',
  timing_POPUP_BANNER:
    'Sie können das Pop-Up direkt nach dem Besuch auf Ihrer Webseite, ein paar Sekunden danach oder dann einblenden, wenn die Seite verlassen werden soll.',
  frequency_POPUP_BANNER:
    'Sie können die Frequenz festlegen, mit der das Pop-Up-Banner angezeigt wird. Z.B. einmal pro Sitzung.',
  audience_POPUP_BANNER:
    'Sie können das Zielpublikum für die Einblendung des Pop-Up-Banners festlegen. Zum Beispiel den neuen Besuchern oder denen, die zurückkommen.',

  // Popup Recommendation
  'Enter the title that your visitors will see on the recommendation pop-up banner.':
    'Geben Sie den Titel ein, den Ihre Kunden auf dem Empfehlungs-Popup-Banner sehen werden.',
  position_POPUP_RECOMMENDATION:
    'Sie können die Position des Empfehlungs-Pop-Up-Banners auf der Seite festlegen.',
  verticalPosition_POPUP_RECOMMENDATION:
    'Sie können die Position des Empfehlungs-Pop-Up-Banners oben, in der Mitte oder unten auf der Seite festlegen.',
  horizontalPosition_POPUP_RECOMMENDATION:
    'Sie können die Position des Empfehlungs-Pop-Up-Banners links, in der Mitte oder rechts auf der Seite festlegen.',
  timingTitle_POPUP_RECOMMENDATION:
    'Sie können die Zeit und die Frequenz festlegen, wann Ihr Empfehlungs-Pop-Up-Banner angezeigt wird. ',
  timing_POPUP_RECOMMENDATION:
    'Sie können das Empfehlungs-Pop-Up direkt nach dem Besuch auf Ihrer Webseite, ein paar Sekunden danach oder dann einblenden, wenn die Seite verlassen werden soll.',
  frequency_POPUP_RECOMMENDATION:
    'Sie können die Frequenz festlegen, mit der das Empfehlungs-Pop-Up angezeigt wird. Z.B. einmal pro Sitzung.',
  audience_POPUP_RECOMMENDATION:
    'Sie können das Zielpublikum für die Einblendung des Empfehlungs-Pop-Up-Banners festlegen. Zum Beispiel den neuen Besuchern oder denen, die zurückkommen.',

  // Journey Builder
  position_JOURNEY_BUILDER:
    'You can set the position of the journey builder on the page.',
  verticalPosition_JOURNEY_BUILDER:
    'You can set the position of the journey builder on top, middle or bottom of the page.',
  horizontalPosition_JOURNEY_BUILDER:
    'You can set the position of the journey builder on left, middle or right section of the page.',
  timingTitle_JOURNEY_BUILDER:
    'You can set the time and frequency of the journey builder to display. ',
  timing_JOURNEY_BUILDER:
    'You can display the journey builder right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_JOURNEY_BUILDER:
    'You can set the frequency of the journey builder to display. For example, one time in one session.',
  audience_JOURNEY_BUILDER:
    'You can set the audience of the journey builder to display. For example, to the new visitors or returning visitors.',

  // See All
  position_SEE_ALL:
    'You can set the position of the See All Campaign on the page.',
  verticalPosition_SEE_ALL:
    'You can set the position of the See All Campaign on top, middle or bottom of the page.',
  horizontalPosition_SEE_ALL:
    'You can set the position of the See All Campaign on left, middle or right section of the page.',
  timingTitle_SEE_ALL:
    'You can set the time and frequency of the See All Campaign to display. ',
  timing_SEE_ALL:
    'You can display the See All Campaign right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_SEE_ALL:
    'You can set the frequency of the See All Campaign to display. For example, one time in one session.',
  audience_SEE_ALL:
    'You can set the audience of the See All Campaign to display. For example, to the new visitors or returning visitors.',

  // Email Collection
  'Enter the title of the pop-up that will be displayed for email collection.':
    'Geben Sie den Titel des Pop-Ups an, das bei der E-Mailsammlung angezeigt wird.',
  'Enter the description of the pop-up that will be displayed for email collection.':
    'Geben Sie die Beschreibung des Pop-Ups an, das bei der E-Mailsammlung angezeigt wird.',
  'Enter the name of the information that you would like your visitors to fill in the form as a placeholder.':
    'Geben Sie den Namen der Information an, die Ihre Besucher als Platzhalter in das Formular eintragen sollen.',
  "Enter the 'Call to Action' text. For example, 'Send'":
    "Geben Sie den „Call to Action“-Text (Handlungsaufforderung) an. Zum Beispiel 'Senden'",
  "Enter the text to direct visitors to visit the page where your legal statement is published. For example, 'Terms&Conditions' ":
    "Geben Sie den Text ein, um Ihre Kunden zum Besuch der Seite aufzufordern, auf der Ihre rechtlichen Hinweise veröffentlicht sind. Zum Beispiel 'Geschäftsbedingungen'",
  'Enter the URL address of the page where your legal statement is published to direct your visitors.':
    'Geben Sie die URL-Adresse der Seite an, auf der Ihre rechtlichen Hinweise veröffentlicht sind, um Ihre Besucher dorthin zu leiten.',
  styles_NEWSLETTER: 'Wählen Sie die Schriftart und die Hintergrundfarbe aus.',
  'styles_Text Color': 'Wählen Sie die Textfarbe aus.',
  'styles_Background Color': 'Wählen Sie die Hintergrundfarbe aus.',
  position_NEWSLETTER:
    'Sie können die Position des Pop-Up-Banners für die E-Mailsammlung auf der Seite festlegen.',
  verticalPosition_NEWSLETTER:
    'Sie können die Position des Pop-Up-Banners, das bei der E-Mailsammlung angezeigt wird, oben, in der Mitte oder unten auf der Seite festlegen.',
  horizontalPosition_NEWSLETTER:
    'Sie können die Position des Pop-Up-Banners, das bei der E-Mailsammlung angezeigt wird, links, in der Mitte oder rechts auf der Seite festlegen.',
  timingTitle_NEWSLETTER:
    'Sie können die Zeit und die Frequenz festlegen, wann das Pop-Up-Banner für die E-Mail-Sammlung angezeigt wird.',
  timing_NEWSLETTER:
    'Sie können das Pop-Up für die E-Mailsammlung direkt nach dem Besuch auf Ihrer Webseite, ein paar Sekunden danach oder dann einblenden, wenn die Seite verlassen werden soll.',
  frequency_NEWSLETTER:
    'Sie können die Frequenz festlegen, mit der das Pop-Up-Banner für die E-Mailsammlung angezeigt wird. Z.B. einmal pro Sitzung.',
  audience_NEWSLETTER:
    'Sie können das Zielpublikum für die Einblendung des Pop-Up-Banners für die E-Mailsammlung festlegen. Zum Beispiel den neuen Besuchern oder denen, die zurückkommen.',

  // Hero Banner
  timingTitle_HERO_BANNER: 'You can set the time and frequency of the banner.',
  timing_HERO_BANNER:
    'You can display the banner right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_HERO_BANNER:
    'You can set the frequency of the banner to display. For example, one time in one session.',
  audience_HERO_BANNER:
    'You can set the audience of the banner to display. For example, to the new visitors or returning visitors.',

  // Form Survey
  'Enter the title that your visitors will see on the form survey pop-up banner.':
    'Geben Sie den Titel ein, den Ihre Kunden auf dem Umfragen-Popup-Banner sehen werden.',
  'Enter the description of the form survey pop-up banner.':
    'Geben Sie die Beschreibung des Umfragen-Pop-Up-Banners ein.',
  position_FORM:
    'Sie können die Position des Umfragen-Pop-Up-Banners auf der Seite festlegen.',
  verticalPosition_FORM:
    'Sie können die Position des Umfragen-Pop-Up-Banners oben, in der Mitte oder unten auf der Seite festlegen.',
  horizontalPosition_FORM:
    'Sie können die Position des Umfragen-Pop-Up-Banners links, in der Mitte oder rechts auf der Seite festlegen.',
  timingTitle_FORM:
    'Sie können die Zeit und die Frequenz festlegen, wann Ihr Umfragen-Pop-Up-Banner angezeigt wird.',
  timing_FORM:
    'Sie können das Umfrage-Pop-Up-Banner direkt nach dem Besuch auf Ihrer Webseite, ein paar Sekunden danach oder dann einblenden, wenn die Seite verlassen werden soll.',
  frequency_FORM:
    'Sie können die Frequenz festlegen, mit der das Umfragen-Pop-Up-Banner angezeigt wird. Z.B. einmal pro Sitzung.',
  audience_FORM:
    'Sie können das Zielpublikum für die Einblendung des Umfragen-Pop-Up-Banners festlegen. Zum Beispiel den neuen Besuchern oder denen, die zurückkommen.',

  // Push Permission
  'Enter the title that your visitors will see on the push permission.':
    'Geben Sie den Titel ein, den Ihre Besucher auf der Push-Erlaubnis sehen werden.',
  'Enter the description of the push permission.':
    'Geben Sie die Beschreibung der Push-Erlaubnis ein.',
  'Enter the URL address of the logo that will be displayed on the push permission.':
    'Geben Sie die URL-Adresse des Logos an, das bei der Push-Erlaubnis angezeigt wird.',
  position_PUSH_PERMISSION:
    'Sie können die Position der Push-Erlaubnis auf der Seite festlegen.',
  verticalPosition_PUSH_PERMISSION:
    'Sie können die Position der Push-Erlaubnis oben, in der Mitte oder unten auf der Seite festlegen.',
  horizontalPosition_PUSH_PERMISSION:
    'Sie können die Position der Push-Erlaubnis links, in der Mitte oder rechts auf der Seite festlegen.',
  timingTitle_PUSH_PERMISSION:
    'Sie können die Zeit und die Frequenz für die Push-Erlaubnis festlegen.',
  timing_PUSH_PERMISSION:
    'Sie können die Push-Erlaubnis direkt nach dem Besuch auf Ihrer Webseite, ein paar Sekunden danach oder dann einblenden, wenn die Seite verlassen werden soll.',
  frequency_PUSH_PERMISSION:
    'Sie können die Frequenz festlegen, mit der die Push-Erlaubnis angezeigt wird. Z.B. einmal pro Sitzung.',
  "Enter the call to action text to give permission. For example, 'Allow'":
    "Geben Sie den Text für die Handlungsaufforderung an, um später noch einmal um die Erlaubnis zu bitten. Zum Beispiel 'Mich später erinnern'",
  "Enter the call to action text to ask for permission later again. For example, 'Remind me later'":
    "Geben Sie den Text für die Handlungsaufforderung an, um die Erlaubnis zu erteilen. Zum Beispiel 'Erlauben'",

  // Push Notifications
  'Enter the title that your visitors will see on the web push notification.':
    'Geben Sie den Titel ein, den Ihre Besucher auf der Web-Push-Benachrichtigung sehen werden.',
  'Enter the description of the web push notificaiton.':
    'Geben Sie die Beschreibung der Web-Push-Benachrichtigung ein.',
  'Enter the URL address of the icon that will be displayed on the web push notification.':
    'Geben Sie die URL-Adresse des Symbols an, das bei der Web-Push-Benachrichtigung angezeigt wird.',
  'Enter the URL address of the image that will be displayed on the web push notification.':
    'Geben Sie die URL-Adresse des Bildes an, das bei der Web-Push-Benachrichtigung angezeigt wird.',
  'Enter the URL of the page that your visitors will be directed when they click on the web push notification.':
    'Geben Sie die URL der Seite an, auf die Ihre Besucher geleitet werden, wenn sie auf die Web-Push-Benachrichtigung klicken.',
  scheduling_PUSH_NOTIFICATION:
    'Sie können die Zeit planen, zu der Web-Push-Empfehlungen geschickt werden.',
  audience_PUSH_NOTIFICATION:
    'Sie können das Zielpublikum für die zu sendenden Web-Push-Empfehlungen festlegen. Zum Beispiel den neuen Besuchern oder denen, die zurückkommen.',

  // Notification Bar
  'Enter the title that your visitors will see on the notification bar.':
    'Geben Sie den Titel ein, den Ihre Besucher auf der Benachrichtigungsleiste sehen werden.',
  'Enter the URL address of the image that will be displayed on the notification bar.':
    'Geben Sie die URL-Adresse des Bildes an, das bei der Benachrichtigungsleiste angezeigt wird.',
  'Enter the URL of the page that your visitors will be directed when they click on the notification bar.':
    'Geben Sie die URL der Seite an, auf die Ihre Besucher geleitet werden, wenn sie auf die Benachrichtigungsleiste klicken.',
  styles_NOTIFICATION_BAR:
    'Wählen Sie die Schriftart und die Hintergrundfarbe aus.',
  position_NOTIFICATION_BAR:
    'Sie können die Position der Benachrichtigungsleiste auf der Seite festlegen.',
  verticalPosition_NOTIFICATION_BAR:
    'Sie können die Position der Benachrichtigungsleiste oben, in der Mitte oder unten auf der Seite festlegen.',
  horizontalPosition_NOTIFICATION_BAR:
    'Sie können die Position der Benachrichtigungsleiste links, in der Mitte oder rechts auf der Seite festlegen.',
  timingTitle_NOTIFICATION_BAR:
    'Sie können die Zeit und die Frequenz der Benachrichtigungsleiste festlegen.',
  timing_NOTIFICATION_BAR:
    'Sie können die Benachrichtigungsleiste direkt nach dem Besuch auf Ihrer Webseite, ein paar Sekunden danach oder dann einblenden, wenn die Seite verlassen werden soll.',
  frequency_NOTIFICATION_BAR:
    'Sie können die Frequenz festlegen, mit der die Benachrichtigungsleiste angezeigt wird. Z.B. einmal pro Sitzung.',
  audience_NOTIFICATION_BAR:
    'Sie können das Zielpublikum für die Einblendung der Benachrichtigungsleiste festlegen. Zum Beispiel den neuen Besuchern oder denen, die zurückkommen.',

  "Can't update user information, new password is same with current password":
    "Can't update user information, new password is same with current password",

  'Keep on page change': 'Keep on page change',
  'Category View': 'Category View',
  'Category Name': 'Category Name',
  impressions: 'impressionen',
  'Completed Campaigns': 'beendete Kampagnen',
  'Conversion Rate': 'Conversion Rate',
  Pages: 'Pages',
  'Views Referred From Adwords': 'Views Referred From Adwords',
  'Views Referred From Retargeting': 'Views Referred From Retargeting',
  'Views Referred From Price Comparison Sites':
    'Views Referred From Price Comparison Sites',
  'Views Referred From Affiliates': 'Views Referred From Affiliates',
  'Views Referred From Referrals': 'Views Referred From Referrals',

  'Will be sent at': 'Will be sent at',
  'Last 7 Days': 'Last 7 Days',
  'The campaign was successfully archived.':
    'The campaign was successfully archived.',
  'Shuffle Promotions': 'Shuffle Promotions',

  'You must change the end date in order to save campaign.':
    'You must change the end date in order to save campaign.',
  Feature: 'Feature',

  'Impression / View / Click': 'Impression / View / Click',
  Items: 'Items',
  'The percentage of products that have been purchased after being clicked in a Segmentify Recommendation widget.':
    'Der prozentuale Anteil an Produkten, die gekauft wurden nachdem Sie in einem Segmentify Empfehlungswidget angeklickt wurden.',
  'There are no records to show.': 'There are no records to show.',
  'You should make at least one selection from Segmentify Intelligent or Static algorithms':
    'You should make at least one selection from Segmentify Intelligent or Static algorithms',
  'New Comers': 'New Comers',
  'All Rocks': 'All Rocks',
  "All Recommends from all products in your website's catalog with an intelligent sorting ":
    "All Recommends from all products in your website's catalog with an intelligent sorting ",
  // Widget/Promotion/Search Keywrods Desc&Info
  Widget_Desc:
    "Create personal recommendation campaigns with Segmentify's intelligent and static algorithms.",
  Promotion_Desc:
    "Personalize the Special Offers you display in your website with Segmentify's Promotion campaigns.",
  'Search-Keyword_Desc':
    "Find out the top searched keywords in your website and recommend them so your customer's can find what they want more easily.",
  'NoActivePopup RecommendationCampaigns': () => {
    return (
      <div>
        To continue making personalized recommendations on your website, you can
        use popup recommendations on any page type. Click ‘Add Custom Widget’
        below to create a new popup recommendation widget.
      </div>
    );
  },
  'NoActiveJourney BuilderCampaigns': () => {
    return (
      <div>
        <p>
          When you need a journey which created uniquely for each of your
          customers, you can define journey builder popups.
        </p>
        <p>
          Please select journey builder below to{' '}
          <strong>add new journey builder popup.</strong>
        </p>
      </div>
    );
  },
  Before_Search_Input:
    "Attract your customers with Segmentify's intelligent algorithms even before they make a search.",

  // login
  'BOOK A DEMO': 'DEMO BUCHEN',
  'Invalid email or password. Please try again. (1)':
    'Ungültige E-Mail oder ungültiges Passwort. Bitte versuchen Sie es erneut. (1)',
  'E-Mail': 'E-Mail-Adresse',
  Password: 'Passwort',
  Hide: 'Ausblenden',
  'Forgot Password': 'Passwort Vergessen',
  'Remember Me': 'Angemeldet bleiben',

  Send: 'Senden',
  'Please fill out this field.': 'Bitte füllen Sie dieses Feld aus.',

  'Reset password link has been sent to your email address.':
    'Der Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet.',

  'Please reset your account password by following instruction in the email.':
    'Bitte setzen Sie Ihr Kontopasswort zurück, indem Sie den Anweisungen in der E-Mail folgen.',

  'Verification Email Sent': 'Bestätigungs-E-Mail gesendet',

  'Please check your email and verify your email address.':
    'Bitte überprüfen Sie Ihre E-Mails und bestätigen Sie Ihre E-Mail-Adresse.',

  'Verification Email Already Sent': 'Bestätigungs-E-Mail bereits gesendet',

  'Reset password token is either expired or compromised, please try to forgot password from login screen!':
    'Das Zurücksetzen des Passwort-Tokens ist entweder abgelaufen oder kompromittiert. Bitte versuchen Sie, das Passwort vom Anmeldebildschirm aus zu vergessen!',

  'Verification token is either expired or compromised, please try to login again!':
    'Das Bestätigungstoken ist entweder abgelaufen oder kompromittiert. Bitte versuchen Sie erneut, sich anzumelden.',

  'https://www.segmentify.com/': 'https://www.segmentify.com/de/',
  'https://www.segmentify.com/book-demo':
    'https://www.segmentify.com/de/demo-buchen',

  'Reset Password': 'Passwort Zurücksetzen',

  'Enter your new password to change your current password.':
    'Geben Sie Ihr neues Passwort ein, um Ihr aktuelles Passwort zu ändern.',
  'Password Again': 'Passwort Erneut',

  'Password fields must be match.': 'Passwortfelder müssen übereinstimmen.',

  // FACETED SEARCH
  General: 'General',
  Language: 'Language',
  FACETED_SEARCH_PRODUCT_DESC:
    'Erstellen Sie mithilfe erweiterter Filter ein personalisiertes, facettenreiches Sucherlebnis',
};

export default de;
