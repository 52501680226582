import React, { useState } from 'react';
import classes from './UsedTemplates.module.scss';

export const UsedTemplates = ({ usedTemplates }) => {
  const [enable, setEnable] = useState(false);

  return (
    <div className={classes.container}>
      <div onClick={() => setEnable(!enable)} className={classes.button}>
        Show Campaigns
      </div>
      {enable && (
        <div className={classes.list}>
          {usedTemplates?.map(item => (
            <div key={item.instanceId} className={classes.list_item}>
              <div>Id: {item?.instanceId}</div>
              <div>Name: {item?.templateName}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
