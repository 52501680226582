import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '../../../../components/fields';

const DeviceType = ({
  valueDesktop,
  valueMobile,
  onChangeDesktop,
  onChangeMobile,
}) => {
  return (
    <div
      className={`form-element ${
        !valueDesktop && !valueMobile ? 'has-error' : ''
      }`}
    >
      <span className='element-title'>Device Type*</span>
      <span className='element-description'>
        Please select the web device type that campaign will work :
      </span>
      <div className='element-row-field device-type'>
        <span className='element-row-label'>Activate On</span>
        <Checkbox
          name='desktop'
          label='Desktop'
          checked={valueDesktop}
          onChange={() => onChangeDesktop(!valueDesktop)}
          icon='pc'
          iconColor='#7A7A7C'
        />
        <Checkbox
          name='mobile'
          label='Mobile'
          checked={valueMobile}
          onChange={() => onChangeMobile(!valueMobile)}
          icon='mobile'
          iconColor='#7A7A7C'
        />
      </div>
      {!valueDesktop && !valueMobile && (
        <span className='item-error'>
          <span>This field is required</span>
        </span>
      )}
    </div>
  );
};

DeviceType.propTypes = {
  valueDesktop: PropTypes.bool.isRequired,
  valueMobile: PropTypes.bool.isRequired,
  onChangeDesktop: PropTypes.func.isRequired,
  onChangeMobile: PropTypes.func.isRequired,
};

export default DeviceType;
