import React from 'react';
import classNames from 'classnames';
import PrimaryNav from '../nav/primary';
import { ExternalAppUtility } from '../../external';

function Sidebar() {
  const isExternal = ExternalAppUtility.isHeaderHidden();
  return (
    <aside
      id='primary-sidebar'
      className={classNames('page-sidebar', { external: isExternal })}
    >
      <PrimaryNav />
    </aside>
  );
}

export default Sidebar;
