import { createReducer } from "../system/store";
import * as jbWizardActions from "../constants/actions/wizard";

const initialState = {
    discovery: [],
    delivery: [],
    isCategoryTree: true
};


export default createReducer(initialState, {
    [jbWizardActions.default.RESET_JOURNEY_BUILDER_CRITERIA]: state => {
        return Object.assign({}, state, {
            discovery: [],
        });
    },
    [jbWizardActions.default.ADD_JOURNEY_BUILDER_CRITERIA]: (state, payload) => {
        return Object.assign({}, state, {
            discovery: payload.criteria
        });
    },
    [jbWizardActions.default.RESET_JOURNEY_BUILDER_DELIVERY_CRITERIA]: state => {
        return Object.assign({}, state, {
            delivery: [],
        });
    },
    [jbWizardActions.default.ADD_JOURNEY_BUILDER_DELIVERY_CRITERIA]: (state, payload) => {
        return Object.assign({}, state, {
            delivery: payload.criteria
        });
    },
    [jbWizardActions.default.UPDATE_CATEGORY_TREE_FLAG]: (state, payload) => {
        return Object.assign({}, state, {
            isCategoryTree: payload.flag
        });
    },
})