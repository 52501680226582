import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { operatorObjToArray } from './utils/utils';
import { decodeHtml } from '../../system/string';
import { t } from '../../system/ui';

import {
  stringOperators,
  operatorColumns,
} from '../../constants/datamaps/rule-based-segmentation';
import { searchRbsBrand } from '../../modules/brand/ajax';
import ErrorText from './ErrorText';

const defaultSelectData = {
  value: 'any',
  label: 'any brand',
  title: 'any brand',
};

/**
 * @name CriteriaFilterBrand
 * @description Component that renders the brand filter
 * @param {object} criteriaData - The criteria data
 * @param {string} filterType - The filter type
 * @param {array} statements - The statements
 * @param {function} callbackFunc - The callback function
 * @returns {JSX.Element}
 */
const criteriaFilterBrand = ({
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const defaultFilterData = {
    dataType: criteriaData?.dataType,
    column: operatorColumns.brandOperator,
    operator: stringOperators.equals.value,
    value: 'any',
  };

  const [selectedBrand, setSelectedBrand] = useState('');
  const [brands, setBrands] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState('');
  const [isCustomText, setIsCustomText] = useState();

  if (statements?.[filterType].length === 0) {
    callbackFunc(filterType, [defaultFilterData]);
  }

  useEffect(() => {
    const statement = statements?.[filterType]?.[0];

    setSelectedOperator(statement?.operator || defaultFilterData.operator);
    const defaultBrand = statement ? statement?.value : defaultFilterData.value;
    setSelectedBrand(defaultBrand);

    const isDefaultBrandExistInPages = brands.some(
      item => item.value === defaultBrand,
    );

    if (
      (!isDefaultBrandExistInPages && defaultBrand !== 'any') ||
      brands.length === 0
    ) {
      searchRbsBrand().then(response => {
        const isDefaultBrandExist = [
          defaultFilterData.value,
          ...response,
        ].includes(defaultBrand);
        const responseWithDefaultBrand =
          !isDefaultBrandExist && defaultBrand
            ? [...response, defaultBrand]
            : response;
        const sorted = responseWithDefaultBrand.sort();
        const sortedBrands = sorted.map(item => {
          return {
            value: item,
            label: decodeHtml(item),
            title: decodeHtml(item),
          };
        });
        setBrands([defaultSelectData, ...sortedBrands]);
      });
    }
  }, [statements]);

  const triggerCallbackFunc = (val, type) => {
    const columnVal = operatorColumns.brandOperator;
    const operatorVal = type === 'operator' ? val?.value : selectedOperator;
    let brandVal = type === 'brand' ? val?.value : selectedBrand;

    if (
      [
        stringOperators.contains.value,
        stringOperators.containsNot.value,
      ].includes(selectedOperator) &&
      [stringOperators.equals.value, stringOperators.equalsNot.value].includes(
        operatorVal,
      ) &&
      isCustomText
    ) {
      brandVal = '';
    }

    const filterData = [
      {
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator: operatorVal,
        value: brandVal,
      },
    ];

    callbackFunc(filterType, filterData);
  };

  const onChange = (selectedValue, type) => {
    switch (type) {
      case 'brand':
        setSelectedBrand(selectedValue?.value || '');
        // eslint-disable-next-line no-prototype-builtins
        setIsCustomText(!selectedValue?.hasOwnProperty('title'));
        break;
      case 'operator':
        setSelectedOperator(selectedValue?.value);
        break;
      default:
        break;
    }

    triggerCallbackFunc(selectedValue, type);
  };

  const ConditionalSelect = [
    stringOperators.contains.value,
    stringOperators.containsNot.value,
  ].includes(selectedOperator)
    ? Select.Creatable
    : Select;

  return (
    <div className='rbs-filter-field'>
      <div className='filter-operator'>
        <span className='filter-operator-title'>Operator*</span>
        <Select
          value={selectedOperator}
          options={operatorObjToArray(stringOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          onChange={e => onChange(e, 'operator')}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      <div className='filter-connector' />
      <div className='filter-operator'>
        <span className='filter-operator-title'>Brand*</span>
        <ConditionalSelect
          value={selectedBrand}
          options={brands}
          name='brand'
          clearable={false}
          searchable
          onChange={e => onChange(e, 'brand')}
          className={`criteria-field one-whole ${
            selectedBrand ? '' : 'has-error'
          }`}
          openOnClick
          placeholder={t('Select or text')}
        />
        {!selectedBrand && <ErrorText />}
      </div>
    </div>
  );
};

export default criteriaFilterBrand;
