import React from 'react';
import { t } from '../../../system/ui';
import classes from './SFYScheduling.module.scss';
import Tooltip from '../../tooltip';
import { days } from '../../../constants/datamaps/email';

const SelectDay = ({ componentDetail, editCampaign, fieldValue }) => {
  const { tooltipText, cardName, cardTitle } = componentDetail;
  const start = 1;
  const end = 7;
  const list = [];
  for (let i = start; i <= end; i++) {
    list.push(i);
  }
  return (
    <label
      className={`item item-stacked label-emphasise is-select ${classes['sfy-scheduling-repeat']}`}
    >
      <span className={classes['sfy-label-wrapper']}>
        <span className={classes['sfy-label']}>{t(cardTitle)}</span>
        <Tooltip content={t(tooltipText)} alignment='right' placement='bottom'>
          <i className='icon-info' role='presentation'>
            <span className='for-screenreader-only' />
          </i>
        </Tooltip>
      </span>
      <select
        defaultValue={fieldValue}
        className={`${classes['sfy-scheduling-repeat']} ${classes['sfy-select']} ${classes['sfy-border']}`}
        onChange={e => editCampaign(cardName, e.target.value)}
        name={cardName}
      >
        {list.map(item => {
          return (
            <option key={item} value={item}>
              {t(days[item - 1])}
            </option>
          );
        })}
      </select>
    </label>
  );
};

export default SelectDay;
