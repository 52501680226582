/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconNotification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={this.state.width}
        height={this.state.height}
      >
        <g>
          <rect
            stroke={this.state.color}
            style={{ fill: "none" }}
            className="svg-stroke"
            x="21"
            y="68"
            width="470"
            height="376"
          />
          <line
            stroke={this.state.color}
            className="svg-stroke"
            style={{ strokeWidth: "3.25em", strokeLinecap: "square"}}
            x1="113"
            y1="145"
            x2="400"
            y2="145"
          />
        </g>
      </svg>
    );
  }
}

export default IconNotification;
