import { createAsyncThunk } from '@reduxjs/toolkit';
import { $http } from 'system/ajax';
import { getRestEndPoint } from '../../system/api';
import { getApiKey } from '../../modules/auth/user';
import { emptyCriteriaFilter, convertDataForFrontend } from './customFunctions';

export const getCampaigns = createAsyncThunk('RBS/getCampaigns', async () => {
  const url = `${getRestEndPoint('rbs/campaign')}?apiKey=${getApiKey()}`;
  const response = await $http(url).get();

  return await response;
});

export const getCampaignsReducer = {
  [getCampaigns.pending]: state => {
    state.criteriaCampaigns.loading = true;
    state.criteriaCampaigns.loaded = false;
  },
  [getCampaigns.fulfilled]: (state, action) => {
    state.criteriaCampaigns.loading = false;
    state.criteriaCampaigns.loaded = true;
    state.criteriaCampaigns.data = action.payload;
  },
  [getCampaigns.rejected]: state => {
    state.criteriaCampaigns.loading = false;
    state.criteriaCampaigns.loaded = false;
    state.criteriaCampaigns.data = [];
  },
};

export const getCampaign = createAsyncThunk(
  'RBS/getCampaign',
  async (instance, isDetailed = false) => {
    const url = `${getRestEndPoint('rbs/campaign')}/${instance}`;
    const response = await $http(url).get({
      apiKey: getApiKey(),
      withOps: isDetailed,
    });

    response.segmentationRequest = await convertDataForFrontend(
      response?.segmentationRequest,
    );

    return await response;
  },
);

export const getCampaignReducer = {
  [getCampaign.pending]: state => {
    state.wizardFilter.loading = true;
    state.wizardFilter.loaded = false;
  },
  [getCampaign.fulfilled]: (state, action) => {
    const { payload } = action;

    state.wizardFilter.loading = false;
    state.wizardFilter.loaded = true;
    state.wizardFilter.data = payload.segmentationRequest.filter;
    state.wizardFilter.segmentName = payload.segmentName;
    state.wizardFilter.description = payload?.description || '';
    state.wizardFilter.campaignDetails = payload;
  },
  [getCampaign.rejected]: state => {
    state.wizardFilter.loading = false;
    state.wizardFilter.loaded = false;
    state.wizardFilter.data = emptyCriteriaFilter;
  },
};
