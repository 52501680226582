import React from 'react';
import { uiActions } from 'actions';
import { clone } from 'system/object';

export default class SearchandisingSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: {
        facetedIncludeOutOfStock: false,
        useInStockInOrderForFaceted: false,
        skipAttributeBasedEliminationInSearchResults: true,
        noOutOfStockProductOnPinnedProducts: false,
        recommendVariantForFaceted: false,
      },
    };
  }

  componentDidMount() {
    this.setState({
      campaign: {
        facetedIncludeOutOfStock: this.props.campaign.facetedIncludeOutOfStock,
        useInStockInOrderForFaceted: this.props.campaign
          .useInStockInOrderForFaceted,
        skipAttributeBasedEliminationInSearchResults: this.props.campaign
          .skipAttributeBasedEliminationInSearchResults,
        recommendVariantForFaceted: this.props.campaign
          .recommendVariantForFaceted,
        noOutOfStockProductOnPinnedProducts: this.props.campaign
          .noOutOfStockProductOnPinnedProducts,
      },
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.campaign.facetedIncludeOutOfStock !==
        this.props.campaign.facetedIncludeOutOfStock ||
      prevProps.campaign.useInStockInOrderForFaceted !==
        this.props.campaign.useInStockInOrderForFaceted ||
      prevProps.campaign.skipAttributeBasedEliminationInSearchResults !==
        this.props.campaign.skipAttributeBasedEliminationInSearchResults ||
      prevProps.campaign.recommendVariantForFaceted !==
        this.props.campaign.recommendVariantForFaceted ||
      prevProps.campaign.noOutOfStockProductOnPinnedProducts !==
        this.props.campaign.noOutOfStockProductOnPinnedProducts
    ) {
      this.setState({
        campaign: {
          facetedIncludeOutOfStock: this.props.campaign
            .facetedIncludeOutOfStock,
          useInStockInOrderForFaceted: this.props.campaign
            .useInStockInOrderForFaceted,
          skipAttributeBasedEliminationInSearchResults: this.props.campaign
            .skipAttributeBasedEliminationInSearchResults,
          recommendVariantForFaceted: this.props.campaign
            .recommendVariantForFaceted,
          noOutOfStockProductOnPinnedProducts: this.props.campaign
            .noOutOfStockProductOnPinnedProducts,
        },
      });
    }
  }

  getUpdatedCampaign = () => {
    return this.state.campaign;
  };

  handleCheckBox = e => {
    uiActions.formEdited();
    const copyCampaign = clone(this.state.campaign);
    this.setState({
      campaign: {
        ...copyCampaign,
        [e.target.id]: e.target.checked,
      },
    });
  };

  render() {
    return (
      <ol className='form-elements'>
        <div className='wizard-cards'>
          <li className='item item-stacked is-checkbox'>
            <span className='label-mail label-text-field'>
              <label className='item item-stacked is-checkbox'>
                <input
                  type='checkbox'
                  checked={this.state.campaign.facetedIncludeOutOfStock}
                  onChange={this.handleCheckBox}
                  id='facetedIncludeOutOfStock'
                />
                <span className='item-label'>
                  Include Out of Stock Products
                </span>
              </label>
            </span>
          </li>
          <li className='item item-stacked is-checkbox'>
            <span className='label-mail label-text-field'>
              <label className='item item-stacked is-checkbox'>
                <input
                  type='checkbox'
                  checked={this.state.campaign.useInStockInOrderForFaceted}
                  onChange={this.handleCheckBox}
                  id='useInStockInOrderForFaceted'
                />
                <span className='item-label'>Show In Stock Products First</span>
              </label>
            </span>
          </li>
          <li className='item item-stacked is-checkbox'>
            <span className='label-mail label-text-field'>
              <label className='item item-stacked is-checkbox'>
                <input
                  type='checkbox'
                  checked={this.state.campaign.recommendVariantForFaceted}
                  onChange={this.handleCheckBox}
                  id='recommendVariantForFaceted'
                />
                <span className='item-label'>
                  Enable Recommend Variants for Searchandising
                </span>
              </label>
            </span>
          </li>
          <li className='item item-stacked is-checkbox'>
            <span className='label-mail label-text-field'>
              <label className='item item-stacked is-checkbox'>
                <input
                  type='checkbox'
                  checked={
                    this.state.campaign
                      .skipAttributeBasedEliminationInSearchResults
                  }
                  onChange={this.handleCheckBox}
                  id='skipAttributeBasedEliminationInSearchResults'
                />
                <span className='item-label'>
                  Skip Attribute Based Elimination in Search Results
                </span>
              </label>
            </span>
          </li>
          <li className='item item-stacked is-checkbox'>
            <span className='label-mail label-text-field'>
              <label className='item item-stacked is-checkbox'>
                <input
                  type='checkbox'
                  checked={
                    this.state.campaign.noOutOfStockProductOnPinnedProducts
                  }
                  onChange={this.handleCheckBox}
                  id='noOutOfStockProductOnPinnedProducts'
                />
                <span className='item-label'>
                  Don&apos;t Show Out of Stock Product on Pinned Products
                </span>
              </label>
            </span>
          </li>
        </div>
      </ol>
    );
  }
}
