import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const Countdown = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 10 10'
      fill='none'
    >
      <g clipPath='url(#clip0_0_23)'>
        <path
          d='M8.33333 3.13802C8.09028 3.24653 7.82769 3.32031 7.54557 3.35938C7.84071 3.18576 8.04253 2.93186 8.15104 2.59766C7.86892 2.76259 7.57812 2.87326 7.27865 2.92969C7.01389 2.64323 6.68186 2.5 6.28255 2.5C5.90495 2.5 5.58268 2.63346 5.31575 2.90039C5.04883 3.16732 4.91536 3.48958 4.91536 3.86719C4.91536 3.99306 4.92622 4.09722 4.94792 4.17969C4.38802 4.14931 3.86285 4.00825 3.3724 3.75651C2.88194 3.50477 2.46528 3.1684 2.1224 2.7474C1.99653 2.96441 1.93359 3.19444 1.93359 3.4375C1.93359 3.93229 2.13108 4.31207 2.52604 4.57682C2.32205 4.57248 2.10503 4.51606 1.875 4.40755V4.42057C1.875 4.74609 1.98351 5.03581 2.20052 5.28971C2.41753 5.54362 2.68446 5.70095 3.0013 5.76172C2.87543 5.79644 2.76476 5.8138 2.66927 5.8138C2.61285 5.8138 2.52821 5.80512 2.41536 5.78776C2.50651 6.0612 2.66819 6.28689 2.90039 6.46484C3.1326 6.6428 3.39627 6.73394 3.69141 6.73828C3.18793 7.12891 2.62153 7.32422 1.99219 7.32422C1.87934 7.32422 1.77083 7.31771 1.66667 7.30469C2.30903 7.71267 3.00781 7.91667 3.76302 7.91667C4.24913 7.91667 4.70486 7.83963 5.13021 7.68555C5.55556 7.53147 5.92014 7.3253 6.22396 7.06706C6.52778 6.80881 6.78928 6.5115 7.00846 6.17513C7.22765 5.83876 7.39041 5.4872 7.49674 5.12044C7.60308 4.75369 7.65625 4.38802 7.65625 4.02344C7.65625 3.94531 7.65408 3.88672 7.64974 3.84766C7.92318 3.65234 8.15104 3.4158 8.33333 3.13802ZM10 1.875V8.125C10 8.64149 9.81662 9.08312 9.44987 9.44987C9.08312 9.81662 8.64149 10 8.125 10H1.875C1.35851 10 0.916884 9.81662 0.55013 9.44987C0.183377 9.08312 0 8.64149 0 8.125V1.875C0 1.35851 0.183377 0.916884 0.55013 0.55013C0.916884 0.183377 1.35851 0 1.875 0H8.125C8.64149 0 9.08312 0.183377 9.44987 0.55013C9.81662 0.916884 10 1.35851 10 1.875Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_0_23'>
          <rect
            width={fixedPxValue(width)}
            height={fixedPxValue(height)}
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

Countdown.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Countdown.defaultProps = {
  width: '32px',
  height: '28px',
};

export default Countdown;
