import React from 'react';
import classes from './Drawer.module.scss';
import { changeAnchor } from '../utils/change-anchor';
import { t } from '../../../../../system/ui';
import { ErrorContent } from './error-content/ErrorContent';
import { EventContent } from './event-content/EventContent';

/**
 *  Drawer component for the code editor
 * @param {object} groupedErrorMarkers - grouped error markers
 * @param {string} type - type of the drawer (errorBoundary or event)
 * @param {string} anchor - anchor of the drawer
 * @param {function} onClose - callback function to close the drawer
 * @param {boolean} open - open state of the drawer
 * @returns {JSX.Element} - Drawer component
 */

const Drawer = ({ open, anchor, onClose, groupedErrorMarkers, type }) => {
  const {
    drawer,
    animate,
    hidden,
    header,
    dialogContent,
    compilationError,
  } = classes;

  const uiFriendlyErrorMarkers = Object?.keys(groupedErrorMarkers)?.map(key => {
    const [startLineNumber, endLineNumber] = key.split('-');
    const errorMessageGroup = groupedErrorMarkers[key].reduce((acc, marker) => {
      const { code, message, owner } = marker;
      if (!acc[owner]) {
        // eslint-disable-next-line no-param-reassign
        acc[owner] = [];
      }
      acc[owner].push({ message, code });

      return acc;
    }, {});

    return {
      startLineNumber: Number(startLineNumber),
      endLineNumber: Number(endLineNumber),
      message: errorMessageGroup,
    };
  });

  return (
    <>
      <div
        tabIndex='-1'
        className={`${drawer} ${open && animate} ${!open &&
          hidden} ${changeAnchor(anchor, classes)}`}
      >
        <div className={header}>
          <h3>
            {type === 'errorBoundary'
              ? t('Compilation Errors')
              : t('Event Info')}
          </h3>
          <div
            className='dialog-close'
            style={{ cursor: 'pointer' }}
            onClick={onClose}
          >
            X
          </div>
        </div>
        <div className={dialogContent}>
          {type === 'errorBoundary' ? (
            <p className={compilationError}>
              <ErrorContent uiFriendlyErrorMarkers={uiFriendlyErrorMarkers} />
            </p>
          ) : (
            <EventContent />
          )}
        </div>
      </div>
    </>
  );
};

export default Drawer;
