/**
 * Created by mehmetyurtar on 12/07/2017.
 */
import React from "react";

class FieldText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#000"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <path
          fill={this.state.color}
          d="M15,0H1C0.447,0,0,0.448,0,1v14c0,0.553,0.447,1,1,1h14c0.553,0,1-0.447,1-1V1C16,0.448,15.553,0,15,0z
                 M14,14H2V2h12V14z"
        />
        <path
          fill={this.state.color}
          d="M8,12.22C8.532,12.699,9.229,13,10,13h1v-2h-1c-0.551,0-1-0.449-1-1h2V8H9V6c0-0.551,0.449-1,1-1h1V3h-1
                C9.229,3,8.532,3.301,8,3.78C7.468,3.301,6.771,3,6,3H5v2h1c0.551,0,1,0.449,1,1v2H5v2h2c0,0.551-0.449,1-1,1H5v2h1
                C6.771,13,7.468,12.699,8,12.22z"
        />
      </svg>
    );
  }
}

export default FieldText;
