import React from 'react';
import { wizardActions, searchWizardActions, uiActions } from '../../actions';
import { isSwitchAccount, isGG } from '../auth/user';
import { addCampaign, updateCampaign } from '../search/ajax';
import { getRockstarScore, isRockstarAlgorithmSelected } from './utils';
import { facetedSearchRequest } from '../../actions/faceted-search';

export const searchList = [
  {
    type: 'searchRow',
    properties: {
      isMobile: false,
      active: true,
      type: 'CATEGORY',
      icons: 'categoryBoxes',
      text: 'Category',
      isTreeView: true,
      isData: false,
    },
    exclusions: [],
    values: { criterion: { additionalTitles: {} } },
  },
  {
    type: 'searchRow',
    properties: {
      isMobile: false,
      active: true,
      type: 'BRAND',
      icons: 'excludeLabel',
      text: 'Brand',
      isTreeView: false,
      isData: false,
    },
    exclusions: [],
    values: { criterion: { additionalTitles: {} } },
  },
  {
    type: 'searchRow',
    properties: {
      isMobile: false,
      active: true,
      type: 'KEYWORD',
      icons: 'fieldTextArea',
      text: 'Keyword',
      isTreeView: false,
      isData: false,
    },
    exclusions: [],
    values: { criterion: { additionalTitles: {} } },
  },

  {
    type: 'searchRow',
    properties: {
      isMobile: true,
      type: 'CATEGORY',
      active: false,
      icons: 'categoryBoxes',
      text: 'Category',
      isTreeView: true,
      isData: false,
    },
    exclusions: [],
    values: { criterion: { additionalTitles: {} } },
  },
  {
    type: 'searchRow',
    properties: {
      isMobile: true,
      type: 'BRAND',
      active: false,
      icons: 'excludeLabel',
      text: 'Brand',
      isTreeView: false,
      isData: false,
    },
    exclusions: [],
    values: { criterion: { additionalTitles: {} } },
  },
  {
    type: 'searchRow',
    properties: {
      isMobile: true,
      type: 'KEYWORD',
      active: false,
      icons: 'fieldTextArea',
      text: 'Keyword',
      isTreeView: false,
      isData: false,
    },
    exclusions: [],
    values: { criterion: { additionalTitles: {} } },
  },
  {
    type: 'searchRow',
    properties: {
      isMobile: true,
      type: 'LAST_SEARCHES',
      active: false,
      icons: 'circleBack',
      text: 'Search History',
      isTreeView: false,
      isData: true,
    },
    exclusions: [],
    values: { criterion: { additionalTitles: {} } },
  },
  {
    type: 'searchRow',
    properties: {
      isMobile: false,
      type: 'LAST_SEARCHES',
      active: false,
      icons: 'circleBack',
      text: 'Search History',
      isTreeView: false,
      isData: true,
    },
    exclusions: [],
    values: { criterion: { additionalTitles: {} } },
  },
];

export const assetText = [
  {
    label: 'Product Text',
    name: 'popularProductsText',
    tooltip: true,
    tooltipText: 'Product text',
  },
  {
    label: 'Mobile Cancel Text',
    name: 'mobileCancelText',
    tooltip: true,
    tooltipText: 'The text for closing the Search Box on mobile.',
  },
];

const assetTexts = [
  {
    name: 'popularProductsText',
  },
  {
    name: 'mobileCancelText',
  },
  {
    name: 'mobilePopularBrandsText',
  },
  {
    name: 'mobilePopularCategoriesText',
  },
  {
    name: 'mobilePopularKeywordsText',
  },
  {
    name: 'popularBrandsText',
  },
  {
    name: 'popularKeywordsText',
  },
  {
    name: 'popularCategoriesText',
  },
  {
    name: 'mobileLastSearchesText',
  },
  {
    name: 'lastSearchesText',
  },
];

const langWiz = {
  Category: 'Categories',
  Brand: 'Brands',
  Keyword: 'Keywords',
};

const categoryOptions = ['all', 'category', 'parent', 'child'];

export const goTestOrLive = (context, action = 'add') => {
  let control = true;
  let campaignContext = context.state.campaign;
  let account = isSwitchAccount()
    ? context.props.switchedUser.account
    : context.props.user.account;

  let emptyLanguage = '';
  let accountLanguages = account.additionalLanguages.concat(
    account.mainLanguage,
  );
  for (let i = 0; i < accountLanguages.length; i++) {
    if (
      Object.keys(campaignContext.stringSearchAssetTextMap).indexOf(
        accountLanguages[i],
      ) === -1 ||
      campaignContext.stringSearchAssetTextMap[accountLanguages[i]] === ''
    ) {
      emptyLanguage = accountLanguages[i];
      control = false;
      break;
    }
  }

  if (control) {
    let contentOptions = context.refs.campaignOptions,
      exclusions = [],
      staticItems = [],
      dynamicItems = [],
      searchAssets = [],
      campaignProps = {},
      mobileItemCount = 0,
      desktopItemCount = 0,
      mobileSearchAssets = [];

    Object.keys(contentOptions.refs).forEach(ref => {
      campaignProps[ref] = contentOptions.refs[ref];
    });

    // campaign object initialization
    let campaign = {
      status: context.state.status === 'test' ? 'TEST' : 'ACTIVE',
      devices: [],
      stringSearchAssetTextMap: {},
      instanceId: campaignContext.instanceId,
      name: campaignProps.name.state.value.trim(),
      css: context.refs.wizardAdvancedConfig.state.cssTemplate,
      html: context.refs.wizardAdvancedConfig.state.htmlTemplate,
      preJs: context.refs.wizardAdvancedConfig.state.prejsTemplate,
      postJs: context.refs.wizardAdvancedConfig.state.postjsTemplate,
      orders: campaignContext.orders,
    };

    // campaign asset text initialization
    accountLanguages.map(language => {
      if (campaign.stringSearchAssetTextMap[language] === undefined) {
        campaign.stringSearchAssetTextMap[language] = {};
        assetTexts.map(asset => {
          campaign.stringSearchAssetTextMap[language][asset.name] = '';
        });
      }
    });

    // campaign set device property
    if (campaignProps.searchDevices) {
      campaign.devices = campaignProps.searchDevices.state.value.filter(
        device => device !== 'ALL',
      );
    }

    campaign.devices = [];
    if (campaignProps.searchDevices) {
      campaign.devices = campaignProps.searchDevices.state.value.filter(
        device => device !== 'ALL',
      );
    }

    context.props.criteria.forEach(criteria => {
      if (criteria.type === 'product') {
        staticItems.push({
          productId: criteria.values.productId.value,
          type: criteria.type.toUpperCase(),
        });
        desktopItemCount += criteria.values.productId.value.length;
        mobileItemCount += criteria.values.productId.value.length;
      } else {
        let recommendationSource = criteria.values.criterion.value;
        if (
          criteria.values.criterion.value ===
          'RECOMMENDATION_RELATED_COMBINE_PRODUCTS'
        ) {
          recommendationSource = 'RECOMMENDATION_ALTERNATIVE_COMBINE_PRODUCTS';
        }
        let dynamicObj = {
          recommendationSource: recommendationSource,
          itemCount: criteria.values.itemCount.value,
          mobileItemCount: criteria.values.mobileItemCount.value,
          type: criteria.type.toUpperCase(),
        };

        mobileItemCount += parseInt(dynamicObj.mobileItemCount);
        desktopItemCount += parseInt(dynamicObj.itemCount);

        if (criteria.values.timeFrame) {
          let isCategory = false;
          categoryOptions.forEach(category => {
            if (!isCategory) {
              if (criteria.values.timeFrame.value === category) {
                isCategory = true;
              }
            }
          });
          if (isCategory) {
            dynamicObj.categoryChoice = criteria.values.timeFrame.value;
          } else {
            dynamicObj.timeFrame = criteria.values.timeFrame.disabled
              ? 'context_WEEK'
              : criteria.values.timeFrame.value;
          }
        }

        // Score for Rockstar Algorithms
        if (isRockstarAlgorithmSelected(criteria.values.criterion.value)) {
          dynamicObj.score = getRockstarScore(criteria.values.criterion.value);
        } else if (
          criteria.type === 'brand' ||
          criteria.type === 'category' ||
          criteria.type === 'label'
        ) {
          dynamicObj.score = 'ROCK';
        } else {
          dynamicObj.score = 'NONE';
        }

        //Score for Event Data Algorithms
        if (criteria.values.criterion.value === 'RECOMMENDATION_EVENT_DATA') {
          if (dynamicObj.timeFrame === 'NONE') {
            dynamicObj.score = '';
            dynamicObj.timeFrame = 'CURRENT';
          } else {
            dynamicObj.score = 'VIEW';
          }
        }

        if (
          criteria.values.criterion.value === 'RECOMMENDATION_HIDDEN_PRODUCTS'
        ) {
          dynamicObj.timeFrame = '';
          dynamicObj.score = '';
        }
        dynamicItems.push(dynamicObj);
      }
    });

    campaign.mobileItemCount = mobileItemCount;
    campaign.desktopItemCount = desktopItemCount;

    context.props.excludes.forEach(exclusion => {
      let params = {};
      params.type = exclusion.type.toUpperCase();
      Object.keys(exclusion.values).forEach(value => {
        params[value] = exclusion.values[value].value;
      });
      exclusions.push(params);
    });

    campaign.recommendationParams = {
      dynamicItems: JSON.stringify(dynamicItems),
      staticItems: JSON.stringify(staticItems),
      exclusions: JSON.stringify(exclusions),
    };

    context.props.searchCriteria.forEach(criteria => {
      let dynamicObj = {
        assetItems: [],
        status: 'PASSIVE',
        type: criteria.properties.type,
      };

      if (criteria.values.hasOwnProperty('criterion')) {
        // if status is set..
        if (!!criteria.values.criterion.status) {
          dynamicObj.status = criteria.values.criterion.status;
        }

        // if clickable is checked
        dynamicObj.clickable = criteria.values.criterion.clickable;

        // if treeView is checked
        dynamicObj.categoryTreeView = criteria.values.criterion.treeView;

        //exclusions
        dynamicObj.exclusions = [];

        let _device = criteria.properties.isMobile ? 'mobile' : 'pc';
        if (
          context.props &&
          context.props.searchExcludes &&
          context.props.searchExcludes[_device] &&
          context.props.searchExcludes[_device][criteria.properties.type]
        ) {
          context.props.searchExcludes[_device][
            criteria.properties.type
          ].forEach(function(item) {
            if (item.type.toLowerCase() === 'category') {
              dynamicObj.exclusions = dynamicObj.exclusions.concat(
                item.values.categoryId.value,
              );
            } else if (item.type.toLowerCase() === 'brand') {
              dynamicObj.exclusions = dynamicObj.exclusions.concat(
                item.values.brand.value,
              );
            } else if (item.type.toLowerCase() === 'keyword') {
              dynamicObj.exclusions = dynamicObj.exclusions.concat(
                item.values.keyword.value,
              );
            }
          });
        }

        if (!!criteria.values.criterion.assetItems) {
          criteria.values.criterion.assetItems.forEach(asset => {
            let assetObj = {
              algorithm:
                asset.type === 'intelligent'
                  ? asset.values.criterion.value
                  : 'STATIC',
              count: asset.values.itemCount.value,
            };
            if (asset.type !== 'intelligent') {
              assetObj.value = asset.values.criterion.value || '';
            }
            if (asset.values?.clearQueryBasedEnabled) {
              assetObj.clearQueryBasedEnabled =
                asset.values.clearQueryBasedEnabled;
            }
            if (asset.values?.clearButtonEnabled) {
              assetObj.clearButtonEnabled = asset.values.clearButtonEnabled;
            }
            if (asset.values?.clearButtonText) {
              assetObj.clearButtonText = asset.values.clearButtonText;
            }
            dynamicObj.assetItems.push(assetObj);
          });
        }

        // set campaign text by languages
        if (!!criteria.values.criterion.additionalTitles) {
          let firstLetter = criteria.properties.isMobile
            ? 'mobilePopular'
            : 'popular';
          let secondLetter = langWiz[criteria.properties.text];
          let langSet = firstLetter + secondLetter + 'Text';

          if (criteria.properties.type === 'LAST_SEARCHES') {
            langSet = criteria.properties.isMobile
              ? 'mobileLastSearchesText'
              : 'lastSearchesText';
          }

          Object.keys(campaign.stringSearchAssetTextMap).forEach(addLang => {
            campaign.stringSearchAssetTextMap[addLang][langSet] =
              criteria.values.criterion.additionalTitles[addLang];
          });
        }
      } else {
        dynamicObj.status = 'PASSIVE';
      }

      if (criteria.properties.isMobile === false) {
        if (!!dynamicObj.type) {
          searchAssets.push(dynamicObj);
        }
      } else {
        if (!!dynamicObj.type) {
          mobileSearchAssets.push(dynamicObj);
        }
      }
    });

    Object.keys(campaign.stringSearchAssetTextMap).forEach(addLang => {
      campaign.stringSearchAssetTextMap[addLang]['mobileCancelText'] =
        campaignContext.stringSearchAssetTextMap[addLang]['mobileCancelText'];
      campaign.stringSearchAssetTextMap[addLang]['popularProductsText'] =
        campaignContext.stringSearchAssetTextMap[addLang][
          'popularProductsText'
        ];
    });
    campaign.searchAssets = searchAssets;
    campaign.mobileSearchAssets = mobileSearchAssets;
    if (action === 'add') {
      addCampaign(campaign, async response => {
        let status = 'test';
        let note = () => {
          return (
            <div>Before Search campaign is saved to drafts successfully.</div>
          );
        };

        if (response.status === 'ACTIVE') {
          status = 'active';
          note = () => {
            return <div>Before Search campaign is activated successfully.</div>;
          };
        }

        try {
          const banners = await facetedSearchRequest('searchbanner/upsert', {
            instanceId: 'BEFORE_SEARCH',
          }).post(
            context.bannerRef.getBanners().map(banner => {
              return {
                ...banner,
                instanceId: 'BEFORE_SEARCH',
                searchType: 'instant',
              };
            }),
            { type: 'json' },
          );
        } catch (e) {
          const notificationContent = () => (
            <span>Banner could not be saved.</span>
          );
          uiActions.showErrorNotification(notificationContent);
          return;
        }

        uiActions.showNotification({
          content: note,
        });

        let campaign = {
          ...response,
          status,
        };

        if (
          context.context &&
          context.context.router &&
          context.context.router.routes
        ) {
          let ext = context.context.router.routes[0].path || '';
          context.context.router.push({
            pathname: ext + '/search/edit/' + response.instanceId,
          });
        }

        context.setState(
          {
            campaign,
            saved: true,
          },
          () => {
            let link = '/search/edit/' + campaign.instanceId.toLowerCase();
            uiActions.redirect(link);
          },
        );
      });
    } else if (action === 'update') {
      updateCampaign(campaign, async response => {
        let status = 'test';
        let note = () => {
          return (
            <div>Before Search campaign is saved to drafts successfully.</div>
          );
        };

        if (response.status === 'ACTIVE') {
          status = 'active';
          note = () => {
            return <div>Before Search campaign is activated successfully.</div>;
          };
        }

        let campaign = {
          ...response,
          status,
        };

        let banners = [];

        try {
          banners = await facetedSearchRequest('searchbanner/upsert', {
            instanceId: 'BEFORE_SEARCH',
          }).post(
            context.bannerRef.getBanners().map(banner => {
              return {
                ...banner,
                instanceId: 'BEFORE_SEARCH',
                searchType: 'instant',
              };
            }),
            { type: 'json' },
          );
        } catch (e) {
          const notificationContent = () => (
            <span>Banner could not be saved.</span>
          );
          uiActions.showErrorNotification(notificationContent);
          return;
        }

        context.setState({
          campaign,
          banners,
        });

        uiActions.showNotification({
          content: note,
        });
      });
    }
  } else {
    context.setState(
      {
        selectedLanguage: emptyLanguage,
      },
      () => {
        this.refs.wizard.querySelector("input[name='title']").focus();
        wizardActions.saveReset();
      },
    );
  }
};

export const singleSearch = (context, campaign) => {
  try {
    if (campaign) {
      let account = isSwitchAccount()
        ? context.props.switchedUser.account
        : context.props.user.account;

      let criteria = [],
        excludes = [],
        assetList = [],
        products = [],
        htmlTemplate = campaign.html,
        prejsTemplate = campaign.preJs,
        postjsTemplate = campaign.postJs,
        cssTemplate = campaign.css,
        dynamicItems =
          typeof campaign.recommendationParams.dynamicItems !== 'undefined'
            ? JSON.parse(campaign.recommendationParams.dynamicItems)
            : [],
        staticItems =
          typeof campaign.recommendationParams.staticItems !== 'undefined'
            ? JSON.parse(campaign.recommendationParams.staticItems)
            : [],
        exclusions =
          typeof campaign.recommendationParams.exclusions !== 'undefined'
            ? JSON.parse(campaign.recommendationParams.exclusions)
            : [];

      let excludesMap = {
        category: [],
        brand: [],
        label: [],
        product: [],
      };

      let willBeAddedExclusions = [];

      exclusions.forEach(item => {
        let values = {};

        Object.keys(item).forEach(key => {
          if (key !== 'type' && key !== 'criterion') {
            if (key === 'categoryId') {
              if (Array.isArray(item.categoryId)) {
                excludesMap.category = excludesMap.category.concat(
                  item.categoryId,
                );
              } else {
                if (item.categoryId.indexOf('|') > -1) {
                  excludesMap.category = excludesMap.category.concat(
                    item.categoryId.split('|'),
                  );
                } else {
                  excludesMap.category.push(item.categoryId);
                }
              }
            } else if (key === 'brand') {
              if (Array.isArray(item.brand)) {
                excludesMap.brand = excludesMap.brand.concat(item.brand);
              } else {
                if (item.brand.indexOf('|') > -1) {
                  excludesMap.brand = excludesMap.brand.concat(
                    item.brand.split('|'),
                  );
                } else {
                  excludesMap.brand.push(item.brand);
                }
              }
            } else if (key === 'label') {
              if (Array.isArray(item.label)) {
                excludesMap.label = excludesMap.label.concat(item.label);
              } else {
                if (item.label.indexOf('|') > -1) {
                  excludesMap.label = excludesMap.label.concat(
                    item.label.split('|'),
                  );
                } else {
                  excludesMap.label.push(item.label);
                }
              }
            } else if (key === 'productId' || key === 'product') {
              if (Array.isArray(item[key])) {
                excludesMap.product = excludesMap.product.concat(item[key]);
              } else {
                if (item[key].indexOf('|') > -1) {
                  excludesMap.product = excludesMap.product.concat(
                    item[key].split('|'),
                  );
                } else {
                  excludesMap.product.push(item[key]);
                }
              }
            } else {
              values[key] = {
                value: item[key],
              };
            }
          }
        });

        if (Object.keys(values).length) {
          excludes.push({
            id: Math.random(),
            type: item.type.toLowerCase(),
            values,
          });
        }
      });

      staticItems.forEach(item => {
        if (item.productId) {
          if (Array.isArray(item.productId)) {
            products = products.concat(item.productId);
          } else {
            products.push(item.productId);
          }
        }

        if (item.promoCode) {
          criteria.push({
            id: Math.random(),
            type: item.type.toLowerCase(),
            values: {
              code: {
                value: item.promoCode,
              },
            },
          });
        }
      });

      if (products.length) {
        criteria.push({
          id: Math.random(),
          type: 'product',
          values: {
            productId: {
              value: products,
            },
          },
        });
      }

      Object.keys(excludesMap).forEach(key => {
        let targetExclude = excludesMap[key];
        let values = {};
        if (key === 'category') {
          values.categoryId = {};
          values.categoryId.value = targetExclude;
        } else if (key === 'product') {
          values.productId = {};
          values.productId.value = targetExclude;
        } else {
          values[key] = {};
          values[key].value = targetExclude;
        }
        if (targetExclude.length) {
          excludes.push({
            id: Math.random(),
            type: key.toLowerCase(),
            values,
          });
        }
      });

      dynamicItems.forEach(item => {
        criteria.push({
          id: Math.random(),
          type: item.type.toLowerCase(),
          values: {
            criterion: {
              value:
                item.recommendationSource ===
                  'RECOMMENDATION_ALTERNATIVE_COMBINE_PRODUCTS' && isGG()
                  ? 'RECOMMENDATION_RELATED_COMBINE_PRODUCTS'
                  : item.recommendationSource,
            },
            itemCount: {
              value: item.itemCount,
            },
            mobileItemCount: {
              value: item.mobileItemCount,
            },
            timeFrame: {
              value: item.timeFrame || item.categoryChoice,
            },
          },
        });
      });

      let mainLanguage = account.mainLanguage;
      let additionalLanguages = account.additionalLanguages || [];
      let langs = additionalLanguages.concat(mainLanguage);

      let exclusionTypeMap = {
        CATEGORY: { type: 'category', valueName: 'categoryId' },
        BRAND: { type: 'brand', valueName: 'brand' },
        KEYWORD: { type: 'keyword', valueName: 'keyword' },
      };
      searchList.map(item => {
        let dynamicObj = {
          id: Math.random(),
          type: item.type,
          properties: item.properties,
          exclusions: [],
          values: {
            criterion: {
              additionalTitles: {},
            },
          },
        };

        let asset;

        if (!item.properties.isMobile)
          asset =
            campaign.searchAssets && campaign.searchAssets.length > 0
              ? campaign.searchAssets.find(
                  asset => asset.type === item.properties.type,
                )
              : [];
        else
          asset =
            campaign.mobileSearchAssets &&
            campaign.mobileSearchAssets.length > 0
              ? campaign.mobileSearchAssets.find(
                  asset => asset.type === item.properties.type,
                )
              : [];

        if (asset) {
          if (asset.hasOwnProperty('clickable'))
            dynamicObj.values.criterion['clickable'] = asset.clickable;

          if (asset.hasOwnProperty('categoryTreeView'))
            dynamicObj.values.criterion.treeView = asset.categoryTreeView;

          if (asset.hasOwnProperty('status')) {
            dynamicObj.values.criterion.status = asset.status;
            dynamicObj.properties.active =
              asset.status === 'ACTIVE' ? true : false;
          }
          if (asset.hasOwnProperty('exclusions')) {
            if (asset.exclusions && asset.exclusions.length > 0) {
              dynamicObj.exclusions = [
                {
                  id: 0.7448625251572978,
                  type: exclusionTypeMap[asset.type].type,
                  values: {},
                },
              ];
              dynamicObj.exclusions[0].values[
                exclusionTypeMap[asset.type].valueName
              ] = { value: asset.exclusions };
              willBeAddedExclusions.push({
                exclusions: dynamicObj.exclusions,
                isMobile: item.properties.isMobile,
              });
            } else {
              dynamicObj.exclusions = [];
            }
          }

          dynamicObj.properties.isData = true;

          if (asset.assetItems && asset.assetItems.length > 0) {
            dynamicObj.values.criterion.assetItems = [];

            asset.assetItems.map(assItem => {
              let assetDynamicObj = {
                id: Math.random(),
                type:
                  assItem.algorithm === 'STATIC'
                    ? asset.type.toLowerCase()
                    : 'intelligent',
                values: {
                  criterion: {
                    value:
                      assItem.algorithm === 'STATIC'
                        ? assItem.value
                        : assItem.algorithm,
                  },
                  itemCount: {
                    value: assItem.count,
                  },
                },
              };

              if (assItem.hasOwnProperty('clearButtonEnabled')) {
                assetDynamicObj.values.clearButtonEnabled =
                  assItem.clearButtonEnabled;
              }
              if (assItem.hasOwnProperty('clearQueryBasedEnabled')) {
                assetDynamicObj.values.clearQueryBasedEnabled =
                  assItem.clearQueryBasedEnabled;
              }
              if (assItem.hasOwnProperty('clearButtonText')) {
                assetDynamicObj.values.clearButtonText =
                  assItem.clearButtonText;
              }

              dynamicObj.values.criterion.assetItems.push(assetDynamicObj);
            });
          }
        }
        langs.forEach(langAdd => {
          dynamicObj.values.criterion.additionalTitles[langAdd] = '';
        });

        let firstLetter = item.properties.isMobile
          ? 'mobilePopular'
          : 'popular';
        let secondLetter = langWiz[item.properties.text];
        let langSet = firstLetter + secondLetter + 'Text';

        if (item.properties.type === 'LAST_SEARCHES') {
          langSet = item.properties.isMobile
            ? 'mobileLastSearchesText'
            : 'lastSearchesText';
        }

        Object.keys(campaign.stringSearchAssetTextMap).forEach(language => {
          dynamicObj.values.criterion.additionalTitles[language] =
            campaign.stringSearchAssetTextMap[language][langSet];
        });

        assetList.push(dynamicObj);
      });
      searchWizardActions.wizardReset();
      searchWizardActions.addCriteria(assetList);
      wizardActions.addCriteria(criteria);
      wizardActions.addExclusion(excludes);
      willBeAddedExclusions.forEach(item => {
        searchWizardActions.addExclusion(item.exclusions, item.isMobile);
      });
      wizardActions.addTemplates(
        htmlTemplate,
        prejsTemplate,
        postjsTemplate,
        cssTemplate,
      );
    }
  } catch (err) {}
};
