import React from "react";

import {localeString, localeStringMoney} from "../../../../system/string";
import Currency from "../../../currency";

export const defaultPieChartConfig = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: 350,
        options3d: {
            enabled: true,
            alpha: 45,
            beta: 0
        }
    },
    tooltip: {
        pointFormat: "<b>{point.percentage:.1f}%</b>"
    },
    title: {
        text: "",
        align: "left",
        x: 0,
        style: {
            fontSize: "1.125rem",
            fontFamily: "montserrat, helvetica, arial, sans-serif",
            fontWeight: "bold",
            color: "#78767a"
        }
    },
    credits: {
        enabled: false
    },
    plotOptions: {
        pie: {
            size: "75%",
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
                enabled: false
            },
            showInLegend: true
        }
    },
    legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
        useHTML: true,
        itemStyle: {
            color: "#626061",
            fontFamily: "inherit"
        },
        labelFormatter: function() {
            return (
                "" +
                '<div class="dashboard--funnel__halfbox--legend">' +
                    '<div class="title">' +
                        this.name +
                     '</div>' +
                    '<div class="count-container">' +
                        '<span class="count" style="color:' + this.color + '">' +
                            this.y_label +
                        '</span>' +
                        'views' +
                    '</div>' +
                '</div>'
            );
        },
        symbolWidth: 0,
        symbolHeight: 0,
        x: -40
    },
    series: [
        {
            name: "Pages",
            colorByPoint: true,
            type: "pie",
            data: []
        }
    ]
};


const defaultChartData = {
    data: [],
    name: "",
    yLabel: "",
    yLabelName: "",
    type: "pie",
    align: "right",
    layout: "vertical",
    calculateTotalByYLabel: true,
    isPriceField: false,
    yLabelFormatConversionF: (item) => {return parseInt(item)}
};

/**
 * chartData.data : []
 * chartData.name : ""
 * chartData.yLabel : ""
 * chartData.yLabelName : ""
 * chartData.type : "pie"
 * chartData.align : "right"
 * chartData.layout : "vertical"
 * chartData.yLabelFormatConversionF
 * chartData.calculateTotalByYLabel
 * @param chartData
 * @returns {*}
 */
export const createChart = (chartData,chartConfig) => {
    let config = JSON.parse(JSON.stringify(chartConfig ? chartConfig : defaultPieChartConfig)); // yLabel search error -> referance assign !!
    chartData = Object.assign({}, defaultChartData, chartData);

    let toShowData = [];

    let totalVal = 0;
    if (!chartData.data) {
        return config;
    }
    chartData.data.forEach(item => {
        let yLabel = item && item[chartData.yLabel] ? chartData.yLabelFormatConversionF(item[chartData.yLabel]) : 0;

          let currentData = "";
          if (chartData.name.split(".").length > 1) {
            currentData = item;
            for (let property of chartData.name.split(".")) {
              if (!currentData[property]) {
                currentData = "-";
                break
              } else {
                currentData = currentData[property];
              }
            }
          } else {
            currentData = item && item[chartData.name] ? item[chartData.name] : "";
          }

        let chartItem = {
            name: currentData,
            y: yLabel,
            y_label: chartData.isPriceField ? localeStringMoney(yLabel, undefined, {currencyFormat: "{{num}}{{code}}"}) : localeString(yLabel)
        };

        toShowData.push(chartItem);
        if (chartData.calculateTotalByYLabel) {
            totalVal = totalVal + chartItem.y;
        }
    });

    if( totalVal === 0) {
        toShowData = toShowData.map(item => ({ ...item, y: 1 }))
    }

    config.chart.type = chartData.type;
    config.legend.align = chartData.align;
    config.legend.layout = chartData.layout;
    config.legend.labelFormatter = chartData.labelFormatter;

    config.series = [];
    config.series.push({
        name: "Pages",
        colorByPoint: true,
        type: "pie",
        data: toShowData
    });

    config.sgfData = {
        totalVal: totalVal,
        totalValView: chartData.isPriceField ? (<Currency currency={totalVal}/>) : localeString(totalVal)
    };

    return config;
};
