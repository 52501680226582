import { clone } from '../../system/object';
import { toCamelCase } from '../../system/string';
import {
  calculateStatRatio,
  calculateRatio,
  calculateProportion,
} from '../../system/ratio';
import { statsMap } from '../../constants/datamaps/stats';

const transformStats = function(statData) {
  const mappedStats = clone(statsMap);
  let deviceView = 0;
  if (statData.length > 0) {
    statData.forEach(function(item) {
      let type;
      let name;
      let key;
      let other = 'other';

      if (item.x.startsWith('page:')) {
        type = 'page';
      } else if (item.x.startsWith('device:')) {
        type = 'device';
      } else if (item.x.startsWith('unique:')) {
        type = 'unique';
      } else if (item.x.startsWith('purchase:')) {
        type = 'purchase';
      }

      name = item.x.replace(`${type}:`, '');
      key = toCamelCase(name);

      if (type === 'device') {
        other += 'Count';
        key += 'Count';
      }

      if (mappedStats[type]) {
        if (typeof mappedStats[type].stats[key] !== 'undefined') {
          mappedStats[type].stats[key].count += item.y;
        } else {
          mappedStats[type].stats[other].count += item.y;
        }
      }

      if (type === 'device') {
        deviceView += item.y;
      }
    });
  }

  const pageStats = mappedStats.page.stats;
  mappedStats.page.stats.other.count =
    pageStats.aLL.count -
    (pageStats.homePage.count +
      pageStats.productPage.count +
      pageStats.basketPage.count +
      pageStats.categoryPage.count);

  mappedStats.device.stats.totalMobileCount.count =
    mappedStats.device.stats.mobileCount.count +
    mappedStats.device.stats.androidCount.count +
    mappedStats.device.stats.iosCount.count;
  // page ratio
  mappedStats.page.stats = calculateStatRatio(
    mappedStats.page.stats,
    mappedStats.page.stats.aLL.count,
  );

  // device ratio
  mappedStats.device.stats = calculateStatRatio(
    mappedStats.device.stats,
    deviceView,
  );

  // audience ratio
  mappedStats.unique.stats.average.count = calculateProportion(
    mappedStats.unique.stats.session.count,
    mappedStats.unique.stats.visitor.count,
  );
  mappedStats.unique.stats.pageSessions.count = calculateProportion(
    mappedStats.page.stats.aLL.count,
    mappedStats.unique.stats.session.count,
  );
  mappedStats.unique.stats.productSessions.count = calculateProportion(
    mappedStats.page.stats.productPage.count,
    mappedStats.unique.stats.session.count,
  );

  if (mappedStats.purchase.stats.amount.count > 0) {
    mappedStats.purchase.stats.averageBasket.count = calculateProportion(
      mappedStats.purchase.stats.amount.count,
      mappedStats.purchase.stats.count.count,
    );
    // REVENUE (OVERALL) Conversion Ratio
    mappedStats.purchase.stats.conversion.ratio = calculateRatio(
      mappedStats.purchase.stats.count.count,
      mappedStats.unique.stats.session.count,
    );
  }

  return mappedStats;
};

export default transformStats;
