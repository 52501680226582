import React, { useState } from 'react';
import ContentAdvancedConfig from 'components/search/faceted-search/rule-set/ContentAdvancedConfig';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'system/ui';
import Icons from 'components/icons';
import { updateCampaignForm } from 'actions/faceted-search';
import ContentInput from 'components/search/faceted-search/campaign/general/ContentInput';

const Contents = () => {
  const [advancedConfig, setAdvancedConfig] = useState({
    show: false,
    index: null,
  });
  const state = useSelector(s => s.facetedSearch);
  const dispatch = useDispatch();
  return (
    <div className="faceted-content">
      {state.campaignForm.customContents.map((content, contentIndex) => {
        const customContents = [...state.campaignForm.customContents];
        return (
          <div
            key={contentIndex.toLocaleString() + (content.key || '')}
            className=" wizard-criterion faceted-search-result rule-set-custom-content-list-item"
          >
            <ContentInput content={content} />
            <button
              type="button"
              onClick={() =>
                setAdvancedConfig({ show: true, index: contentIndex })
              }
            >
              {t('Advanced Configuration')}
            </button>
            <div
              className="rule-set-custom-content-list-cross"
              onClick={() => {
                dispatch(
                  updateCampaignForm({
                    customContents: customContents.filter(
                      c => c.key !== content.key,
                    ),
                  }),
                );
              }}
            >
              <Icons name="crossCircle" />
            </div>
          </div>
        );
      })}
      <ContentAdvancedConfig
        showAdvancedConfig={advancedConfig.show}
        onHide={() =>
          setAdvancedConfig({ show: false, index: advancedConfig.index })
        }
        html={
          state.campaignForm.customContents[advancedConfig.index ?? 0]?.html ??
          ''
        }
        onChange={html => {
          const customContents = [...state.campaignForm.customContents];
          customContents[advancedConfig.index] = {
            ...customContents[advancedConfig.index],
            html,
          };
          dispatch(updateCampaignForm({ customContents }));
        }}
      />
    </div>
  );
};

export default Contents;
