import React from 'react';
import { connect } from 'react-redux';
import { t } from '../system/ui';
import {
  getAccount,
  getApiKey,
  getRegion,
  getSwitchAccountUser,
  getToken,
  isMultiAccountUser,
  isSwitchAccount,
} from '../modules/auth/user';
import { getEndPoint } from '../system/api';
import { queryDate } from '../system/date';
import { setTitle } from '../system/document';

class SegmentationModuleReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      reportType: this.props.params.report,
    };

    this.exportAll = this.exportAll.bind(this);
  }

  componentDidMount() {
    setTitle(t('All Segmentation Reports'));
  }

  exportAll() {
    let globalApiKey =
      getApiKey() !== undefined ? '?apiKey=' + getApiKey() : '';
    let endpoint = getEndPoint('export/all/segmentation') + globalApiKey;
    let instanceId = '';
    let exportUrl =
      endpoint +
      '&end=' +
      queryDate(this.props.range[1]) +
      '&start=' +
      queryDate(this.props.range[0]) +
      '&interval=total&limit=2000&instanceid=' +
      instanceId +
      '&authToken=' +
      getToken();
    if (isSwitchAccount() && !isMultiAccountUser()) {
      exportUrl += '&switchUser=' + getSwitchAccountUser(true).username;
    }
    if (isMultiAccountUser() && isSwitchAccount()) {
      exportUrl += '&switchAccount=' + getAccount().accountId.trim();
    }
    exportUrl += '&switchRegion=' + getRegion();
    window.open(exportUrl);
  }

  render() {
    let exportAllButton = (
      <div>
        <a
          style={{ float: 'left' }}
          className='date-filter current'
          onClick={this.exportAll}
        >
          {t('Export All')}
        </a>
      </div>
    );
    return (
      <div className='fullw-report'>
        {this.state.reportType === 'view-all' && (
          <div>
            <h2 className='page-title' style={{ marginBottom: '30px' }}>
              {t('View All')}
            </h2>
            {exportAllButton}
          </div>
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
});

export default connect(mapStatesToProps)(SegmentationModuleReport);
