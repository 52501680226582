import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  isSwitchAccount,
  isMultiAccountUser,
  hasModulePrivilege,
  getUser,
  hasKlaviyoConfiguration,
  isSuperUser,
  dashboardAccess,
  hasModule,
  hasNoRevenueModule,
  hasOnlyDynamicBundles,
} from '../modules/auth/user';
import { appEnv } from '../constants/config';

import {
  statsMap,
  trendsMap,
  trendsMapWithKlaviyo,
} from '../constants/datamaps';
import { t } from '../system/ui';
import { clone } from '../system/object';
import { queryDate } from '../system/date';
import { setTitle } from '../system/document';

import {
  uiActions,
  filterActions,
  dateActions,
  modalActions,
} from '../actions';
import { getIntegrations } from '../modules/account/ajax';

import stats from '../modules/stats';
import {
  transformStats,
  transformTrends,
  transformPageStats,
} from '../modules/transform';

import Filters from '../components/filters/filters';
import GridView from './dashboard.grid';
import FunnelView from './dashboard.funnel';
import GraphView from './dashboard.graph';
import MetricsView from './dashboard.metrics';
import AnalyticsView from './dashboard.analytics';
import DashboardDropdown from '../components/dashboardDropdown';

import CompareDashboardModal from '../components/reports/compareModal.dashboard';
import Export from '../components/export';

import { recommendationIntegrationInfo } from '../modules/trendify/page';
import { getExtAppType, setExtAppTypeEvent, getSource } from '../index';

import Icon from '../components/icon';
import Summary from '../components/summary';

import { redirectAuth } from '../modules/auth/require-auth';
import CampaignDetailsModal from '../components/reports/CampaignDetailsModal';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: clone(statsMap),
      trends: clone(trendsMap),
      extraTrends: hasKlaviyoConfiguration()
        ? clone(trendsMapWithKlaviyo)
        : clone(trendsMap),
      pushTrends: clone(trendsMap),
      dashboardView: 'grid',
      trendsWithInterval: [],
      statsWithInterval: [],
      interval: '',
      chartRenderCount: 1,
      range: [
        moment()
          .subtract(6, 'd')
          .startOf('day'),
        moment().endOf('day'),
      ],
      selectedModule: 'Recommendation',
      metrics: [],
      metricsWithInterval: [],
      activeModules: [],
      total: {
        revenue: 0,
        revenueRatio: 0,
        totalProducts: 0,
      },
      totalPurchase: 0,
      isLoaded: false,
      account: {},
    };

    this.changeDashboardView = this.changeDashboardView.bind(this);
    this.getStatAfterIntegrations = this.getStatAfterIntegrations.bind(this);
    this.getRangedStats = this.getRangedStats.bind(this);
    this.getDashboardTypeComponent = this.getDashboardTypeComponent.bind(this);
    this.campaignDetails = this.campaignDetails.bind(this);
    this.compare = this.compare.bind(this);
    this.export = this.export.bind(this);
    this.onModuleChange = this.onModuleChange.bind(this);
    this.setModule = this.setModule.bind(this);
    this.onAppTypeReceived = this.onAppTypeReceived.bind(this);
    this.sumDataForSummary = this.sumDataForSummary.bind(this);
    this.getSummaryData = this.getSummaryData.bind(this);
    this.dashboardAccessRequest = this.dashboardAccessRequest.bind(this);
    const _this = this;
    setExtAppTypeEvent(_this.onAppTypeReceived);
  }

  componentDidMount() {
    setTitle(t('Dashboard'));
    uiActions.resetPage();
    dateActions.updateRangeAlias('lastSevenDays', true);
    getUser(response => {
      this.setState(
        {
          account: response.account,
        },
        () => {
          const checkIntegration =
            this.props.router.getCurrentLocation().pathname === '/welcome';
          if (checkIntegration) {
            this.setModule(this.getStatAfterIntegrations);
          } else {
            this.setModule(this.getRangedStats);
          }
          this.dashboardAccessRequest();
        },
      );
    });
  }

  setModule(callback) {
    let extAppType = getExtAppType();
    if (extAppType !== null) {
      if (extAppType === 'reco') {
        extAppType = 'Recommendation';
      }
      if (extAppType === 'push') {
        extAppType = 'Push';
      }
    }
    const activeModules = [];
    if (hasModule('recommendation') || hasOnlyDynamicBundles()) {
      activeModules.push('Recommendation');
    }
    if (hasModule('email')) {
      activeModules.push('E-Mail');
    }
    if (hasModule('push')) {
      activeModules.push('Push');
    }
    if (hasModule('search')) {
      activeModules.push('Search');
    }
    this.setState(
      {
        selectedModule: activeModules[0],
        activeModules,
      },
      () => {
        callback(this.state.range, false, this.getSummaryData);
      },
    );
  }

  sumDataForSummary(state) {
    let totalPurchase = 0;
    const total = {
      revenue: 0,
      revenueRatio: 0,
      totalProducts: 0,
    };
    if (state.hasOwnProperty('allStats')) {
      state.allStats.map(prop => {
        prop.x === 'purchase:amount' ? (totalPurchase = prop.y) : '';
      });
    }
    if (
      state.hasOwnProperty('summaryRecommendationTrends') &&
      (hasModule('recommendation') || hasOnlyDynamicBundles())
    ) {
      state.summaryRecommendationTrends.map(prop => {
        prop.x === 'Purchase Amount' ? (total.revenue += prop.y) : '';
        prop.x === 'Purchased Items' ? (total.totalProducts += prop.y) : '';
      });
    }
    if (state.hasOwnProperty('summaryPushTrends') && hasModule('push')) {
      state.summaryPushTrends.map(prop => {
        prop.x === 'Purchase Amount' ? (total.revenue += prop.y) : '';
        prop.x === 'Purchased Items' ? (total.totalProducts += prop.y) : '';
      });
    }
    if (state.hasOwnProperty('summaryEmailTrends') && hasModule('email')) {
      state.summaryEmailTrends.map(prop => {
        prop.x === 'Purchase Amount' ? (total.revenue += prop.y) : '';
        prop.x === 'Purchased Items' ? (total.totalProducts += prop.y) : '';
      });
    }
    if (state.hasOwnProperty('summarySearchTrends') && hasModule('search')) {
      state.summarySearchTrends.map(prop => {
        prop.x === 'Purchase Amount' ? (total.revenue += prop.y) : '';
        prop.x === 'Purchased Items' ? (total.totalProducts += prop.y) : '';
      });
    }
    this.setState({
      total,
      totalPurchase,
    });
  }

  getStatAfterIntegrations(range, dummy, callback) {
    const activeModules = [];
    if (hasModule('recommendation')) {
      activeModules.push('Recommendation');
    }
    if (hasModule('email')) {
      activeModules.push('E-Mail');
    }
    if (hasModule('push')) {
      activeModules.push('Push');
    }
    if (hasModule('search')) {
      activeModules.push('Search');
    }
    getIntegrations(integrations => {
      if (
        integrations.status &&
        (integrations.status === 'FAIL' || integrations.status !== 200)
      ) {
        if (getToken() === 'undefined') {
          if (appEnv() === 'dev' || appEnv() === 'search') {
            window.location = '/login';
          } else {
            redirectAuth(2, '/');
          }
        }
      } else {
        const isIntegrated = integrations.PAGE_VIEW === 'COMPLETED';
        if (isIntegrated) {
          this.getRangedStats(range, false, callback);
        } else {
          if (getSource() !== null && getSource() === 'external_app') return;

          this.props.router.push({
            pathname: '/settings/integrations',
          });
        }
      }
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      const checkIntegration =
        this.props.router.getCurrentLocation().pathname === '/welcome';
      if (checkIntegration) {
        this.getStatAfterIntegrations(
          newProps.range,
          false,
          this.getSummaryData,
        );
      } else {
        this.getRangedStats(newProps.range, false, this.getSummaryData);
      }
    }

    if (newProps.update === true) {
      this.getRangedStats(newProps.range, false, this.getSummaryData);
    }
    if (newProps.reset === true) {
      this.getRangedStats(newProps.range, false, this.getSummaryData);
    }

    if (this.props.params !== newProps.params) {
      this.changeDashboardView(newProps.params.type);
    }
  }

  onAppTypeReceived() {
    this.setModule(this.getRangedStats);
  }

  getSummaryData(range) {
    const boundaries = {};

    boundaries.start = queryDate(range[0]);
    boundaries.end = queryDate(range[1]);
    stats.getRangedTrends(
      boundaries,
      recoTrends => {
        stats.getRangedTrends(
          boundaries,
          pushTrends => {
            stats.getRangedTrends(
              boundaries,
              emailTrends => {
                stats.getRangedTrends(
                  boundaries,
                  searchTrends => {
                    this.setState(
                      {
                        summaryRecommendationTrends: recoTrends,
                        summaryEmailTrends: emailTrends,
                        summaryPushTrends: pushTrends,
                        summarySearchTrends: searchTrends,
                      },
                      () => {
                        this.sumDataForSummary(this.state);
                      },
                    );
                  },
                  false,
                  'Search',
                );
              },
              false,
              'E-Mail',
            );
          },
          false,
          'Push',
        );
      },
      false,
      'Recommendation',
    );
  }

  getRangedStats(range, viewType, callback) {
    const dashboardView = viewType || this.state.dashboardView;
    uiActions.isLoading();
    filterActions.filtersUpdated();
    filterActions.filtersReset();

    const boundaries = {};

    boundaries.start = queryDate(range[0]);
    boundaries.end = queryDate(range[1]);

    let timeFrame = '';
    if (range[1] && range[0]) {
      const timeDiff = range[1] - range[0];
      if (timeDiff < 24 * 60 * 60 * 1000) {
        timeFrame = 'no_aggregate';
      } else if (timeDiff < 90 * 24 * 60 * 60 * 1000) {
        timeFrame = 'daily';
      } else {
        timeFrame = 'monthly';
      }
    }

    if (dashboardView === 'grid') {
      stats.getRangedStats(boundaries, allStats => {
        stats.getRangedTrends(
          boundaries,
          allTrends => {
            this.setState(
              {
                stats: transformStats(allStats),
                trends: transformTrends(
                  allTrends,
                  transformStats(allStats).purchase.stats.amount.count,
                  transformStats(allStats).purchase.stats.count.count,
                  transformStats(allStats).purchase.stats.items.count,
                  this.state.selectedModule === 'Recommendation',
                  this.state.selectedModule,
                  false,
                  false,
                  true,
                ),
                pageStats: transformPageStats(allStats),
                range,
                isLoaded: true,
                dashboardView,
                allStats,
              },
              () => {
                callback(range);
              },
            );
            uiActions.isLoaded();
          },
          false,
          this.state.selectedModule,
        );
      });
    } else if (dashboardView === 'funnel') {
      stats.getRangedStats(boundaries, allStats => {
        stats.getRangedTrends(
          boundaries,
          allTrends => {
            this.setState(
              {
                stats: transformStats(allStats),
                trends: transformTrends(
                  allTrends,
                  transformStats(allStats).purchase.stats.amount.count,
                  transformStats(allStats).purchase.stats.count.count,
                  transformStats(allStats).purchase.stats.items.count,
                  this.state.selectedModule === 'Recommendation',
                  this.state.selectedModule,
                  false,
                  false,
                  true,
                ),
                pageStats: transformPageStats(allStats),
                range,
                dashboardView,
                allStats,
              },
              () => {
                stats.getRangedTrends(
                  boundaries,
                  extraTrends => {
                    const mailTrends = transformTrends(
                      extraTrends,
                      transformStats(allStats).purchase.stats.amount.count,
                      transformStats(allStats).purchase.stats.count.count,
                      transformStats(allStats).purchase.stats.items.count,
                      this.state.selectedModule === 'Recommendation',
                      'E-Mail',
                      false,
                      false,
                      true,
                    );
                    stats.getRangedTrends(
                      boundaries,
                      trends => {
                        const pushTrends = transformTrends(
                          trends,
                          transformStats(allStats).purchase.stats.amount.count,
                          transformStats(allStats).purchase.stats.count.count,
                          transformStats(allStats).purchase.stats.items.count,
                          this.state.selectedModule === 'Recommendation',
                          'Push',
                          false,
                          false,
                          true,
                        );
                        this.setState(
                          {
                            extraTrends: mailTrends,
                            pushTrends,
                          },
                          () => {
                            callback(range);
                          },
                        );
                      },
                      false,
                      'Push',
                    );
                  },
                  false,
                  'E-Mail',
                );
              },
            );
            uiActions.isLoaded();
          },
          false,
        );
      });
    } else if (dashboardView === 'graph') {
      stats.getRangedStats(boundaries, allStats => {
        stats.getRangedStats(
          boundaries,
          allStatsWithInterval => {
            stats.getRangedTrends(
              boundaries,
              allTrendsWithInterval => {
                this.setState(
                  {
                    trendsWithInterval: allTrendsWithInterval,
                    statsWithInterval: allStatsWithInterval,
                    interval: timeFrame,
                    chartRenderCount: this.state.chartRenderCount + 1,
                    range,
                    dashboardView,
                    allStats,
                  },
                  () => {
                    callback(range);
                  },
                );
                uiActions.isLoaded();
              },
              timeFrame,
              this.state.selectedModule,
            );
          },
          timeFrame,
        );
      });
    } else if (dashboardView === 'analytics') {
      stats.getGAnalyticsDashboardTrends(boundaries, allStats => {
        // Segmentify
        stats.getAnalyticsTrends(boundaries, analyticsTrends => {
          // Google
          this.setState(
            {
              segmentifyAnalytics: allStats,
              googleAnalytics: analyticsTrends,
              range,
              dashboardView,
            },
            () => {
              callback(range);
            },
          );
          uiActions.isLoaded();
        });
      });
    } else {
      stats.getRangedStats(boundaries, allStats => {
        stats.getTotalKeyMetrics(boundaries, metrics => {
          stats.getTotalKeyMetrics(
            boundaries,
            metricsWithInterval => {
              this.setState(
                {
                  dashboardView,
                  metrics,
                  metricsWithInterval,
                  range,
                  allStats,
                },
                () => {
                  callback(range);
                },
              );
              uiActions.isLoaded();
            },
            timeFrame,
          );
        });
      });
    }
  }

  changeDashboardView(dashboardView) {
    let dashboardRoute = dashboardView;
    if (!dashboardView) {
      dashboardRoute = 'grid';
    }
    if (this.state.dashboardView !== dashboardRoute) {
      this.getRangedStats(
        this.state.range,
        dashboardRoute,
        this.getSummaryData,
      );
    }
  }

  getDashboardTypeComponent(type, noModule) {
    if (type === 'grid') {
      return (
        <GridView
          trends={this.state.trends}
          stats={this.state.stats}
          pageStats={this.state.pageStats}
          selectedModule={this.state.selectedModule}
          noModule={noModule}
        />
      );
    }
    if (type === 'funnel') {
      return (
        <FunnelView
          trends={this.state.trends}
          extraTrends={this.state.extraTrends}
          pushTrends={this.state.pushTrends}
          stats={this.state.stats}
          pageStats={this.state.pageStats}
          symbolBefore={
            isSwitchAccount()
              ? this.props.switchedUser.account.mainCurrency.symbolBefore
              : this.props.user.account.mainCurrency.symbolBefore
          }
          symbol={
            isSwitchAccount()
              ? this.props.switchedUser.account.mainCurrency.symbol
              : this.props.user.account.mainCurrency.symbol
          }
          hasEmail={hasModule('email')}
          hasPush={hasModule('push')}
          hasRecommendation={hasModule('recommendation')}
          noModule={noModule}
        />
      );
    }
    if (type === 'graph') {
      return (
        <GraphView
          trends={this.state.trendsWithInterval}
          interval={this.state.interval}
          chartRenderCount={this.state.chartRenderCount}
          stats={this.state.statsWithInterval}
          selectedModule={this.state.selectedModule}
          noModule={noModule}
        />
      );
    }
    if (type === 'analytics') {
      return (
        <AnalyticsView
          selectedModule={this.state.selectedModule}
          segmentifyAnalytics={this.state.segmentifyAnalytics}
          googleAnalytics={this.state.googleAnalytics}
          account={
            isSwitchAccount()
              ? this.props.switchedUser.account
              : this.props.user.account
          }
        />
      );
    }
    return (
      <MetricsView
        metrics={this.state.metrics}
        interval={this.state.interval}
        metricsWithInterval={this.state.metricsWithInterval}
        noModule={noModule}
      />
    );
  }

  campaignDetails() {
    const user =
      isSwitchAccount() && !isMultiAccountUser()
        ? this.props.switchedUser
        : this.props.user;
    const account = user?.account;

    const campaignDetailsModal = () => (
      <CampaignDetailsModal
        account={account}
        user={user}
        rangeAlias={this.props.rangeAlias}
        isSwitchAccount={isSwitchAccount() && !isMultiAccountUser()}
      />
    );
    modalActions.openModal({
      title: `${t('Campaign Details')} - ${account.domain}`,
      content: campaignDetailsModal,
      className: 'campaign-details-modal dashboard-campaign-details-modal',
    });
  }

  compare() {
    const compareModal = () => (
      <CompareDashboardModal {...this.state} isDashboard />
    );
    modalActions.openModal({
      title: t('Date Comparison'),
      content: compareModal,
    });
  }

  export() {
    const content = () => (
      <Export
        name={t('Export Dashboard Report')}
        pdfExport
        showPageNumbers={false}
        range={this.props.range}
      />
    );

    uiActions.openModal({ title: t('Export All'), content });
  }

  goTo(path) {
    this.props.router.push({
      pathname: path,
    });
  }

  onModuleChange(module) {
    this.setState(
      {
        selectedModule: module,
      },
      () => {
        this.getRangedStats(this.props.range, false, this.getSummaryData);
      },
    );
  }

  // eslint-disable-next-line class-methods-use-this
  dashboardAccessRequest() {
    if (!isSuperUser()) {
      dashboardAccess();
    }
  }

  render() {
    recommendationIntegrationInfo();
    if (!hasModulePrivilege(false, 'RECOUSER')) {
      return (
        <div>
          <div className='promo'>
            <p className='promo-welcome'>
              {t('Welcome to')} <strong>Segmentify</strong>
            </p>
            {hasModulePrivilege(false, 'TRENDUSER') && (
              <div
                className='promotion-box promotion-box-trendify'
                onClick={this.goTo.bind(this, '/trendify')}
              />
            )}
            {hasModulePrivilege(false, 'BANNERUSER') && (
              <div
                className='promotion-box promotion-box-bannerify'
                onClick={this.goTo.bind(this, '/bannerify')}
              />
            )}
          </div>
        </div>
      );
    }

    return (
      <div>
        {this.state.dashboardView === 'grid' && (
          <nav className='account-nav action-nav dashboard-nav'>
            <Icon name='menu' />
            <ul className='action-nav-popup'>
              <li>
                <a
                  className='action-nav-popup-item'
                  onClick={this.campaignDetails}
                >
                  {t('Campaign Details')}
                </a>
                <a className='action-nav-popup-item' onClick={this.compare}>
                  {t('Compare')}
                </a>
                <a className='action-nav-popup-item' onClick={this.export}>
                  {t('Export')}
                </a>
              </li>
            </ul>
          </nav>
        )}
        <Filters />
        {!hasNoRevenueModule() && (
          <Summary
            summary={this.state.total}
            ratio={(
              (this.state.total.revenue / this.state.totalPurchase) *
              100
            ).toFixed(2)}
            isLoaded={this.state.isLoaded}
            isBefore={
              Object.keys(this.state.account).length !== 0
                ? this.state.account.mainCurrency.symbolBefore
                : false
            }
          />
        )}
        <DashboardDropdown
          type={this.state.dashboardView}
          selectedModule={this.state.selectedModule}
          onModuleChange={this.onModuleChange}
          {...this.props}
          hideDropdown={
            this.state.dashboardView === 'key_metrics' ||
            this.state.dashboardView === 'funnel' ||
            this.state.dashboardView === 'analytics' ||
            this.state.activeModules.length < 1
          }
          activeModules={this.state.activeModules}
          noModule={hasNoRevenueModule()}
        />
        {this.getDashboardTypeComponent(
          this.state.dashboardView,
          hasNoRevenueModule(),
        )}
      </div>
    );
  }
}

const mapStateToProps = store => ({
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  reset: store.filters.reset,
  ui: store.ui.ui,
  update: store.filters.update,
});

export default connect(mapStateToProps)(Dashboard);
