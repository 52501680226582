import React from 'react';
import { Tooltip } from 'react-tooltip';
import { TOOL_TIPS } from './constants';

import classes from './index.module.scss';

export const NameWithTooltip = ({ name, key }) => (
  <>
    <p data-tooltip-id={name} className={classes.name} key={key}>
      {name}
    </p>

    {name !== 'Other' && (
      <Tooltip
        effect='solid'
        className='sg-tooltip'
        id={name}
        content={TOOL_TIPS[name]}
      />
    )}
  </>
);
