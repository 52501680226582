import React, { useState } from 'react';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import MailMapping from '../../../../components/email/mapping';

const MappingSettings = ({ component, editCampaign, activeEmailDetails }) => {
  const [campaignCustoms, setCampaignCustoms] = useState(
    activeEmailDetails?.params || {},
  );
  const editValue = (fieldKey, fieldValue) => {
    editCampaign('params', {
      ...activeEmailDetails.params,
      [fieldKey]: fieldValue,
    });
  };

  const onMailToChange = e => {
    setCampaignCustoms({ ...campaignCustoms, mailTo: e.target.value });
    editValue('mailTo', e.target.value);
  };

  const onMappingListChange = value => {
    setCampaignCustoms({ ...campaignCustoms, mappingList: value });
    editCampaign('customParams', value);
  };

  return (
    <InputCardWrapper
      type='mappingSettings'
      labelClass='mappingSettings'
      labelFor='MappingSettings'
    >
      <MailMapping
        campaignCustoms={campaignCustoms}
        onMailToChange={onMailToChange}
        onMappingListChange={onMappingListChange}
      />
    </InputCardWrapper>
  );
};

export default MappingSettings;
