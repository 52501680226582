import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const lastOrderIcon = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 42 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M42 21.5C42 33.098 32.598 42.5 21 42.5C9.40202 42.5 0 33.098 0 21.5C0 9.90202 9.40202 0.5 21 0.5C32.598 0.5 42 9.90202 42 21.5Z'
        fill='#FFECEC'
      />
      <path
        d='M20.9994 13C17.8956 13 14.9993 15.432 14.9993 19.25V23.4028L13.6829 26.5H28.3525L26.9993 23.4069V21.25C26.9993 20.8358 27.3351 20.5 27.7493 20.5C28.1635 20.5 28.4993 20.8358 28.4993 21.25V23.0931L29.88 26.249C30.2413 27.0748 29.6362 28 28.7348 28H24.0012C24.0004 29.657 22.6568 31 20.9996 31C19.3424 31 17.9989 29.657 17.9981 28H13.305C12.4093 28 11.8043 27.0855 12.1546 26.2611L13.4993 23.0972V19.25C13.4993 14.568 17.1033 11.5 20.9994 11.5C21.8589 11.5 22.7043 11.6491 23.4999 11.932C23.8902 12.0707 24.094 12.4996 23.9553 12.8899C23.8165 13.2802 23.3876 13.484 22.9973 13.3453C22.3609 13.119 21.6854 13 20.9994 13ZM20.9996 29.5C21.8284 29.5 22.5004 28.8286 22.5012 28H19.498C19.4989 28.8286 20.1709 29.5 20.9996 29.5ZM24.2503 16.5H21.7503L21.6486 16.5068C21.2825 16.5565 21.0003 16.8703 21.0003 17.25C21.0003 17.6642 21.3361 18 21.7503 18H22.793L21.14 20.3141L21.0847 20.4035C20.8281 20.8892 21.1762 21.5 21.7503 21.5H24.2503L24.3521 21.4932C24.7182 21.4435 25.0003 21.1297 25.0003 20.75C25.0003 20.3358 24.6646 20 24.2503 20H23.2077L24.8606 17.6859L24.9159 17.5965C25.1726 17.1108 24.8245 16.5 24.2503 16.5ZM30.2503 11.5H26.7503L26.6486 11.5069C26.2825 11.5565 26.0003 11.8703 26.0003 12.25C26.0003 12.6642 26.3361 13 26.7503 13H28.8841L26.1176 17.3473L26.0685 17.4369C25.8429 17.9212 26.1933 18.5 26.7503 18.5H30.2503L30.3521 18.4931C30.7182 18.4435 31.0003 18.1297 31.0003 17.75C31.0003 17.3358 30.6646 17 30.2503 17H28.1166L30.8831 12.6527L30.9322 12.5631C31.1578 12.0788 30.8074 11.5 30.2503 11.5Z'
        fill='#FF7777'
      />
    </svg>
  );
};

lastOrderIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

lastOrderIcon.defaultProps = {
  width: '42px',
  height: '43px',
};

export default lastOrderIcon;
