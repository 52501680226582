import React from 'react';
import { connect } from 'react-redux';

import { uiActions } from '../../actions';
import { getProduct } from '../../modules/trendify/ajax';
import { calculateRatio } from '../../system/ratio';
import { localeString } from '../../system/string';
import { t } from '../../system/ui';
import Currency from '../currency';
import { RangePicker } from '../fields';
import Icons from '../icons';
import CompareConversion from './compare.conversion';
import CompareProduct from './compare.product';
import CompareSegmentify from './compare.segmentify';
import CompareSources from './compare.sources';
import { getOtherDates } from './utils';

class TrendifyProductDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserSelect: false,
      firstStart: "",
      firstEnd: "",
      secondStart: "",
      secondEnd: "",
      firstColumnData: { stats: {} },
      secondColumnData: { stats: {} },
      compareType: "conversion",
      results: []
    };

    this.onFirstDatesChange = this.onFirstDatesChange.bind(this);
    this.onSecondDatesChange = this.onSecondDatesChange.bind(this);
    this.changeFirstColumn = this.changeFirstColumn.bind(this);
    this.changeSecondColumn = this.changeSecondColumn.bind(this);
    this.changeCompareType = this.changeCompareType.bind(this);
    this.getCompareIcon = this.getCompareIcon.bind(this);
  }

  getCompareIcon() {
    return {
      count: (firstVal = 0, secondVal = 0) => {
        let difference = firstVal - secondVal;
        if (difference === 0) {
          return "";
        }
        if (difference > 0) {
          return (
            <span style={{ color: "#56bc94", display: "block" }}>
              +{localeString(difference)}{" "}
              <Icons name="increase" color="#56bc94" />
            </span>
          );
        } else {
          return (
            <span style={{ color: "#dc3322", display: "block" }}>
              {" "}
              {localeString(difference)}{" "}
              <Icons name="decrease" color="#dc3322" />
            </span>
          );
        }
      },
      ratio: (
        firstVal = 0,
        firstValOld = 0,
        secondVal = 0,
        secondValOld = 0
      ) => {
        let currentValue = calculateRatio(firstVal, firstValOld);
        let oldValue = calculateRatio(secondVal, secondValOld);
        let difference = currentValue - oldValue;
        if (difference === 0) {
          return "";
        }
        if (difference > 0) {
          return (
            <span style={{ color: "#56bc94", display: "block" }}>
              + {localeString(currentValue - oldValue)} %{" "}
              <Icons name="increase" color="#56bc94" />
            </span>
          );
        } else {
          return (
            <span style={{ color: "#dc3322", display: "block" }}>
              {" "}
              {localeString(oldValue - currentValue)} %{" "}
              <Icons name="decrease" color="#dc3322" />
            </span>
          );
        }
      },
      currency: (firstVal = 0, secondVal = 0) => {
        let difference = firstVal - secondVal;
        if (difference === 0) {
          return "";
        }
        if (difference > 0) {
          return (
            <span style={{ color: "#56bc94", display: "block" }}>
              + <Currency currency={difference || 0} />
              <Icons name="increase" color="#56bc94" />
            </span>
          );
        } else {
          return (
            <span style={{ color: "#dc3322", display: "block" }}>
              <Currency currency={difference || 0} />
              <Icons name="decrease" color="#dc3322" />
            </span>
          );
        }
      }
    };
  }

  componentDidMount() {
    let firstRequestData = {
      product: this.props.product,
      startDate: getOtherDates(this.props.range).formattedStart,
      endDate: getOtherDates(this.props.range).formattedEnd
    };
    let secondRequestData = {
      product: this.props.product,
      startDate: this.props.range[0].format("YYYY-MM-DD"),
      endDate: this.props.range[1].format("YYYY-MM-DD")
    };
    uiActions.isLoading();
    getProduct(firstRequestData, firstColumnData => {
      getProduct(secondRequestData, secondColumnData => {
        uiActions.isLoaded();
        this.setState({
          firstColumnData: firstColumnData.stats
            ? firstColumnData
            : { stats: {} },
          secondColumnData: secondColumnData.stats
            ? secondColumnData
            : { stats: {} }
        });
      });
    });
  }

  changeFirstColumn(dates) {
    if (
      dates.startDate === this.state.firstStart &&
      dates.enddDate === this.state.firstEnd
    ) {
      return;
    }
    uiActions.isLoading();
    let data = {
      product: this.props.product,
      startDate: dates.startDate.format("YYYY-MM-DD"),
      endDate: dates.endDate.format("YYYY-MM-DD")
    };
    getProduct(data, response => {
      uiActions.isLoaded();
      this.setState({
        firstColumnData: response.stats ? response : { stats: {} }
      });
    });
  }

  changeSecondColumn(dates) {
    if (
      dates.startDate === this.state.secondStart &&
      dates.enddDate === this.state.secondEnd
    ) {
      return;
    }
    uiActions.isLoading();
    let data = {
      product: this.props.product,
      startDate: dates.startDate.format("YYYY-MM-DD"),
      endDate: dates.endDate.format("YYYY-MM-DD")
    };

    getProduct(data, response => {
      uiActions.isLoaded();
      this.setState({
        secondColumnData: response.stats ? response : { stats: {} }
      });
    });
  }

  onFirstDatesChange(newDates) {
    this.setState({
      firstStart: newDates.startDate,
      firstEnd: newDates.endDate,
      isUserSelect: true
    });
  }

  onSecondDatesChange(newDates) {
    this.setState({
      secondStart: newDates.startDate,
      secondEnd: newDates.endDate,
      isUserSelect: true
    });
  }

  changeCompareType(compareType, e) {
    e.preventDefault();
    this.setState(
      {
        compareType: compareType
      },
      () => {
        window.dispatchEvent(new Event("resize"));
      }
    );
  }

  render() {
    let compareComponents = {
      conversion: (
        <CompareConversion
          firstColumnData={this.state.firstColumnData}
          secondColumnData={this.state.secondColumnData}
          device={this.props.device}
          getCompareIcon={this.getCompareIcon}
          record={this.props.record}
        />
      ),
      segmentify: (
        <CompareSegmentify
          firstColumnData={this.state.firstColumnData}
          secondColumnData={this.state.secondColumnData}
          results={this.state.results}
          device={this.props.device}
          getCompareIcon={this.getCompareIcon}
        />
      ),
      sources: (
        <CompareSources
          firstColumnData={this.state.firstColumnData}
          secondColumnData={this.state.secondColumnData}
          results={this.state.results}
          device={this.props.device}
          getCompareIcon={this.getCompareIcon}
          hasTrendifySources={this.props.hasTrendifySources}
        />
      ),
      product: (
        <CompareProduct
          firstColumnData={this.state.firstColumnData}
          secondColumnData={this.state.secondColumnData}
          getCompareIcon={this.getCompareIcon}
        />
      )
    };
    return (
      <div className="report-compare one-whole" style={{ minWidth: "960px" }}>
        <div className="one-half report-compare__field">
          <div>
            <span
              style={{
                display: "inline-block",
                marginRight: "12px",
                fontSize: "14px",
                color: "#a3a3a5"
              }}
            >
              {t("Compare To")}:
            </span>
            <RangePicker
              startDate={
                this.state.secondStart || getOtherDates(this.props.range).start
              }
              endDate={
                this.state.secondEnd || getOtherDates(this.props.range).end
              }
              onDatesChange={this.onSecondDatesChange}
              onPickerClose={this.changeFirstColumn}
            />
          </div>
        </div>
        <div className="one-half report-compare__field">
          <div>
            <span
              style={{
                display: "inline-block",
                marginRight: "12px",
                fontSize: "14px",
                color: "#a3a3a5"
              }}
            >
              {t("With")}:
            </span>
            <RangePicker
              startDate={this.state.firstStart || this.props.range[0]}
              endDate={this.state.firstEnd || this.props.range[1]}
              onDatesChange={this.onFirstDatesChange}
              onPickerClose={this.changeSecondColumn}
            />
          </div>
        </div>
        <div className="trendify-compare-buttons">
          <ul>
            <li
              className={
                this.state.compareType === "product"
                  ? "secondary-action selected"
                  : "trendify-compare-button"
              }
              onClick={this.changeCompareType.bind(this, "product")}
            >
              {t("Product")}
            </li>
            <li
              className={
                this.state.compareType === "conversion"
                  ? "secondary-action selected"
                  : "trendify-compare-button"
              }
              onClick={this.changeCompareType.bind(this, "conversion")}
            >
              {t("Website")}
            </li>
            {this.props.hasSegmentifyListing && (
              <li
                className={
                  this.state.compareType === "segmentify"
                    ? "secondary-action selected"
                    : "trendify-compare-button"
                }
                onClick={this.changeCompareType.bind(this, "segmentify")}
              >
                {t("Segmentify")}
              </li>
            )}
            <li
              className={
                this.state.compareType === "sources"
                  ? "secondary-action selected"
                  : "trendify-compare-button"
              }
              onClick={this.changeCompareType.bind(this, "sources")}
            >
              {t("Sources")}
            </li>
          </ul>
        </div>
        {compareComponents[this.state.compareType]}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range
});

export default connect(mapStatesToProps)(TrendifyProductDetail);
