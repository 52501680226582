import React from "react";

class IconChrome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox={"0 0 23.8 23.8"}
        fill={this.state.color}
      >
        <path
          d="M6.3,10.1L2.6,4.4C4.9,1.6,8.4,0,11.9,0c2,0,4.1,0.5,6,1.6c2.1,1.2,3.7,2.9,4.7,4.9L12.7,6C9.9,5.9,7.2,7.4,6.3,10.1z
	M7.9,11.9c0,2.2,1.8,4,4,4s4-1.8,4-4s-1.8-4-4-4S7.9,9.7,7.9,11.9z M23,7.6L16.3,8c1.8,2.1,1.8,5.2,0.3,7.6l-5.4,8.3
	c2.2,0.1,4.5-0.4,6.6-1.6C23,19.2,25.1,13,23,7.6z M6.4,14.2L1.9,5.4C0.7,7.3,0,9.5,0,11.9c0,6,4.4,10.9,10.1,11.8l3.1-6
	C10.4,18.2,7.7,16.7,6.4,14.2z"
        />
      </svg>
    );
  }
}

export default IconChrome;
