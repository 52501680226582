/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { uiActions } from "../../../actions";

import { TextField } from "../../../components/fields";

class Icon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      name: "icon"
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    let icon = this.props.campaign && this.props.campaign.icon ? this.props.campaign.icon : "";
    this.updateValue(icon);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.icon);
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  onFieldChange(e) {
    this.updateValue(e.target.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <li>
        <TextField
          name="icon"
          label={this.props.options.label}
          className="item-stacked one-whole"
          value={this.state.value}
          required={true}
          onChange={this.onFieldChange}
          validation={{ secureURL: true }}
          tooltip={this.props.options.tooltip}
          tooltipText={this.props.options.tooltipContent}
        />
      </li>
    );
  }
}

Icon.defaultProps = {
  options: {
    label: "Your Logo URL (Recommend 200x200px)",
    tooltip: false,
    tooltipContent: ""
  }
};

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(Icon);
