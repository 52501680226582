import React, { useMemo } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './SFYBundleCategoryList.scss';
import SFYThead from '../TableHead/TableHead';

/**
 * @name SFYBundleCategoryList component
 * @description List component displays list of items and its actions
 * @param {Array} listData - list of items
 * @param {array} actions - array of actions
 * @param {string} listName - name of the list
 * @param {string} linkToDetail - router path to detail page of list item or add edit page
 * @param {number} totalRecords  - total number of records
 * @param {function} listInvoker - function to invoke list re-renders
 * @param {boolean} tableHead - table head of the list
 * @param {boolean} imgList - image list of the list
 * @param {function} modalInvoker - function to invoke modal with delete confirmation
 * @returns {JSX.Element}
 */

const SFYBundleCategoryList = ({
  actions,
  listName,
  totalRecords,
  linkToDetail,
  listInvoker,
  listData,
  tableHead,
  modalInvoker,
  bundleHead,
  bundleHeadConstant,
}) => {
  const listRender = useMemo(() => {
    return (
      listData &&
      listData.map(({ bundleCount, code, id, mainItem }) => {
        return (
          <CSSTransition timeout={900} classNames='item' key={id}>
            <pre>{JSON.stringify(mainItem)}</pre>
            {/*<div className='bundleCategory'>*/}
            {/*  <div className='bundleCategory-wrapper'>*/}
            {/*    <div className='bundleCategory-item-0'>*/}
            {/*      {!mainElement?.inStock && (*/}
            {/*        <Tooltip*/}
            {/*          content='You can check the product. It is out of stock or not in the catalog anymore.'*/}
            {/*          alignment='left'*/}
            {/*        >*/}
            {/*          <a href='#' rel='noreferrer'>*/}
            {/*            <i className='icon-info' role='presentation'>*/}
            {/*              <span className='for-screenreader-only' />*/}
            {/*            </i>*/}
            {/*          </a>*/}
            {/*        </Tooltip>*/}
            {/*      )}*/}
            {/*      <div className='bundleCategory-item-0-text'>*/}
            {/*        {mainElement.name}*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className='bundleCategory-item-1'>*/}
            {/*      {`${bundleCount} products`}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className='bundleCategory-item-2'>*/}
            {/*    <div*/}
            {/*      className='bundleCategory-item-2-trashBin'*/}
            {/*      onClick={() => {*/}
            {/*        return modalInvoker(code);*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <Icons name='trashBin' color='#e16a6d' />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </CSSTransition>
        );
      })
    );
  }, [listData]);

  return (
    <section className='sfy-bundleCategory-container'>
      {tableHead && (
        <SFYThead
          bundleHead={bundleHead}
          bundleHeadConstant={bundleHeadConstant}
        />
      )}

      <TransitionGroup>{listRender}</TransitionGroup>
    </section>
  );
};

export default SFYBundleCategoryList;
