import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const spStockCount = ({ width, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 23 23'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      fill='none'
    >
      <path
        d='M9.4093 0.304328C10.399 -0.101443 11.5058 -0.101443 12.4955 0.304328L20.7082 3.67187C21.4315 3.96845 21.9048 4.67902 21.9048 5.46842V11.7114C21.4181 11.2392 20.8654 10.8362 20.2619 10.518V6.13129L11.7741 9.44595V11.9917C11.016 12.8121 10.4454 13.8119 10.1313 14.9215V9.44451L1.64286 6.10566V16.1613C1.64286 16.274 1.71047 16.3755 1.81379 16.4179L10.0266 19.7854C10.0612 19.7997 10.0962 19.8131 10.1313 19.8256V19.8243L10.338 19.9093C10.4021 19.9357 10.4677 19.9579 10.534 19.9757C10.8059 20.5588 11.153 21.099 11.5626 21.5836C10.8407 21.6933 10.0967 21.6072 9.4093 21.3253L1.19657 17.9578C0.473263 17.6612 0 16.9507 0 16.1613V5.46842C0 4.67902 0.473263 3.96845 1.19657 3.67187L9.4093 0.304328ZM11.8782 1.84423C11.2845 1.60077 10.6203 1.60077 10.0266 1.84423L7.93091 2.70351L16.1749 5.94616L19.098 4.80462L11.8782 1.84423ZM2.83664 4.79236L10.9538 7.98512L13.9004 6.83439L5.7117 3.61348L2.83664 4.79236ZM23 16.9073C23 13.5424 20.3031 10.8147 16.9762 10.8147C13.6493 10.8147 10.9524 13.5424 10.9524 16.9073C10.9524 20.2722 13.6493 23 16.9762 23C20.3031 23 23 20.2722 23 16.9073Z'
        fill={color}
      />
      <path
        d='M17.3762 13.8469C17.3762 13.626 17.1971 13.4469 16.9762 13.4469C16.7553 13.4469 16.5762 13.626 16.5762 13.8469L17.3762 13.8469ZM16.6934 20.7763C16.8496 20.9325 17.1028 20.9325 17.259 20.7763L19.8046 18.2307C19.9608 18.0745 19.9608 17.8212 19.8046 17.665C19.6484 17.5088 19.3951 17.5088 19.2389 17.665L16.9762 19.9278L14.7135 17.665C14.5572 17.5088 14.304 17.5088 14.1478 17.665C13.9916 17.8212 13.9916 18.0745 14.1478 18.2307L16.6934 20.7763ZM16.5762 13.8469L16.5762 20.4935L17.3762 20.4935L17.3762 13.8469L16.5762 13.8469Z'
        fill='white'
      />
    </svg>
  );
};

spStockCount.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

spStockCount.defaultProps = {
  width: '23px',
  height: '23px',
  color: '#5D5D61',
};

export default spStockCount;
