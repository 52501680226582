import React, { useRef } from 'react';
import SegmentWidgetOptions from './segment-options';
import SegmentActions from './segment-actions';
import WidgetSegmentStats from './segment-stat';
import Icon from '../icon';
import * as modals from '../../constants/datamaps/segmentation-modals';
import { openModal } from '../../system/ui';
import { segmentAlgorithmTitles } from '../../constants/datamaps/wizard';
import { idPropFunc } from '../../system/id';
import {
  getInterestedValue,
  getInterestedTitle,
} from '../segmentation/utils/functions';

const SegmentWidgetCard = ({ scenarioName, segment, stat, id }) => {
  const widget = useRef('widget');

  const showModal = (modal, e) => {
    e.preventDefault();

    const modalContent = modals[modal];
    openModal({
      title: scenarioName,
      content: modalContent,
      className: 'segmentation',
    });
  };

  return (
    <div className='widget page-content-block' ref={widget}>
      {scenarioName !== 'Lost Customers' && (
        <SegmentWidgetOptions segment={segment} />
      )}
      <h2 className='widget-title-segmentation'>
        {scenarioName}
        <a
          onClick={e =>
            showModal(segmentAlgorithmTitles(scenarioName).title, e)
          }
        >
          <Icon name='info' />
        </a>
      </h2>

      <hr className='widget-separator' />
      <WidgetSegmentStats
        segmentUser={stat ? stat.userCount : 0}
        otherUser={stat ? stat.otherUserCount : 0}
        revenue={stat ? stat.revenue : 0}
        fourthTitle={getInterestedTitle(scenarioName)}
        fourthValue={getInterestedValue(scenarioName, stat)}
      />

      <hr className='widget-separator' />
      <div className='widget-buttons group'>
        <SegmentActions
          segment={segment}
          scenarioName={scenarioName}
          {...idPropFunc(id)}
        />
      </div>
    </div>
  );
};

export default SegmentWidgetCard;
