/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconScheduled extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "20px",
      height: "20px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            data-color="color-2"
            fill={this.state.color}
            d="M22.9,9.1c0.2-0.1,0.4-0.2,0.5-0.4C23.8,7.8,24,6.9,24,6c0-3.3-2.7-6-6-6
	c-1.5,0-2.9,0.6-4,1.6c-0.2,0.2-0.3,0.4-0.3,0.6C17.8,2.7,21.3,5.4,22.9,9.1z"
          />
          <path
            data-color="color-2"
            fill={this.state.color}
            d="M1.1,9.1c1.7-3.7,5.1-6.3,9.2-6.9c0-0.2-0.1-0.4-0.3-0.6C8.9,0.6,7.5,0,6,0C2.7,0,0,2.7,0,6
	c0,0.9,0.2,1.8,0.6,2.6C0.7,8.8,0.9,9,1.1,9.1z"
          />
          <path
            fill={this.state.color}
            d="M21.7,22.3l-2-2c1.4-1.7,2.3-3.9,2.3-6.3c0-5.5-4.5-10-10-10S2,8.5,2,14c0,2.4,0.8,4.6,2.3,6.3l-2,2
	c-0.4,0.4-0.4,1,0,1.4C2.5,23.9,2.7,24,3,24s0.5-0.1,0.7-0.3l2-2C7.4,23.2,9.6,24,12,24c2.4,0,4.6-0.8,6.3-2.3l2,2
	c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3C22.1,23.3,22.1,22.7,21.7,22.3z M17,15h-6V9h2v4h4V15z"
          />
        </g>
      </svg>
    );
  }
}

export default IconScheduled;
