import React from "react";

class IconUserManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#7a7a7c"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9 9"
        width={this.state.width}
        height={this.state.height}
      >

        <g>
          <path
            fill={this.state.color}
            className="svg-path"
            d="M3.51428 0.0160232C2.70654 0.125322 1.99321 0.697535 1.70158 1.46906C1.51066 1.97483 1.50647 2.57919 1.689 3.09997C1.70578 3.15141 1.76243 3.27357 1.81488 3.37215C2.38344 4.46728 3.69261 4.90662 4.77519 4.36441C5.43187 4.03652 5.87875 3.44287 6.01302 2.72064C6.06128 2.45704 6.04449 1.95769 5.97945 1.71766C5.79693 1.03615 5.2976 0.444647 4.68288 0.185329C4.32831 0.0331683 3.86884 -0.0332685 3.51428 0.0160232Z"
          />
          <path
            fill={this.state.color}
            className="svg-path"
            d="M3.24145 5.02026C3.03165 5.05241 2.70855 5.12956 2.52812 5.18957C1.64695 5.48532 0.897955 6.12397 0.434291 6.97908C0.184626 7.44199 0 8.13422 0 8.61642V8.7793H2.87429H5.74859L5.64369 8.64642C5.42969 8.37425 5.25975 8.00992 5.19261 7.67559C5.14645 7.45271 5.14645 7.06266 5.19261 6.83977C5.27024 6.44972 5.47584 6.04039 5.7318 5.76607L5.81363 5.6782L5.68145 5.59033C5.32479 5.36102 4.85273 5.16814 4.41634 5.06955C4.2443 5.03312 4.12262 5.02241 3.75546 5.01812C3.5079 5.01383 3.27711 5.01598 3.24145 5.02026Z"
          />
          <path
            fill={this.state.color}
            className="svg-path"
            d="M6.98653 5.53243C6.66344 5.59458 6.42846 5.70817 6.18089 5.92248C5.78646 6.25895 5.57666 6.72615 5.57666 7.25764C5.57666 7.79557 5.78646 8.25634 6.19138 8.60138C6.50818 8.8757 6.84806 9 7.28026 9C7.60965 9 7.7649 8.96357 8.05443 8.81783C8.24535 8.72139 8.2999 8.68068 8.47613 8.5028C8.70272 8.27348 8.82021 8.08917 8.90413 7.83629C9.03211 7.44195 9.03211 7.07334 8.90413 6.679C8.82021 6.42611 8.70272 6.2418 8.47613 6.01249C8.2999 5.83461 8.24535 5.79389 8.05443 5.69745C7.93484 5.63744 7.77539 5.57315 7.69986 5.55386C7.49635 5.50671 7.17326 5.496 6.98653 5.53243ZM7.49426 6.76901L7.50055 7.13977L7.86351 7.1462L8.22647 7.15049L8.22017 7.36051L8.21388 7.5684L7.85302 7.57483L7.49006 7.57911V7.94344V8.30777H7.28026H7.07045V7.94344V7.57911H6.71379H6.35713V7.3648V7.15049H6.71379H7.07045V6.77544V6.4004H7.28026H7.49006L7.49426 6.76901Z"
          />
        </g>
      </svg>
    );
  }
}

export default IconUserManagement;