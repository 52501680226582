import React, { useContext, useEffect } from "react";
import { CSSTransition } from 'react-transition-group';
import { v4 } from 'uuid';
import { browserHistory } from 'react-router';
import { Context as ProductBundleContext } from '../store/productBundleProvider';
import Tooltip from '../../../../components/tooltip';
import { t } from '../../../../system/ui';
import NoImage from '../../../../images/noImage.svg';
import { SFYCheckBox } from '../../../../components/sfy-components/module-exports';

/**
 * ProductList component of Automated Bundles
 * @param linkToDetail - link to detail page
 * @param selectedItems - selected items
 * @param selectedItemActions - selected item actions
 * @return {*}
 * @constructor
 */
const ProductList = ({ linkToDetail, selectedItems, selectedItemActions }) => {
  const {
    state: { productBundleList },
  } = useContext(ProductBundleContext);

  useEffect(() => {
    selectedItemActions.clearAllItems();
  }, []);

  return (
    productBundleList.length > 0 &&
    productBundleList.map(({ mainItem, id, subItems }) => {
      return (
        <CSSTransition timeout={900} classNames='item' key={v4()}>
          <div className='bundleImgList' key={id}>
            <div
              className='bundleImgList-wrapper'
              onClick={() => browserHistory.push(`${linkToDetail}/${id}`)}
            >
              <div className='bundleImgList-item-0'>
                {!mainItem?.exist && (
                  <Tooltip
                    content={t(
                      'You can check the product. It is out of stock or not in the catalog anymore.',
                    )}
                    alignment='left'
                  >
                    <i className='icon-info' role='presentation'>
                      <span className='for-screenreader-only' />
                    </i>
                  </Tooltip>
                )}
                <span className='bundleImgList-item-0-id'>{mainItem?.key}</span>
              </div>
              <div className='bundleImgList-item-1'>
                {mainItem?.productRecord?.image ? (
                  <img
                    src={mainItem?.productRecord?.image}
                    alt={mainItem?.productRecord?.name}
                  />
                ) : (
                  // eslint-disable-next-line jsx-a11y/img-redundant-alt
                  <img src={NoImage} alt='no image' />
                )}
                <div className='bundleImgList-item-1-text'>
                  {mainItem?.productRecord?.name}
                </div>
              </div>
              <div className='bundleImgList-item-2'>
                {mainItem?.productRecord?.category}
              </div>
              <div className='bundleImgList-item-3'>
                {subItems?.length} products
              </div>
            </div>
            <div className='bundleImgList-item-4'>
              <SFYCheckBox
                id={id}
                name='bundle'
                label=''
                checked={selectedItems.includes(id)}
                onChange={() => selectedItemActions.toggleItem(id)}
              />
            </div>
          </div>
        </CSSTransition>
      );
    })
  );
};

export default ProductList;
