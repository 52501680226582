/**
 * @author Bilal Cinarli
 */

'use strict';

import React from 'react';
import { Link } from 'react-router';
import classNames from 'classnames';

import { widgetTypes } from '../../constants/datamaps/campaigns';
import { pageWidgets } from '../../constants/datamaps/pages';

import { t } from '../../system/ui';

import Icon from '../icon';

const generateLinkforWidget = function(page, type) {
  if (type) {
    return '/widget/add' + '/' + page + '/' + type;
  }
  return page === 'journey_builder'
    ? '/add' + '/' + page + '/fullWidth'
    : '/widget/add' + '/' + page + '/fullWidth';
};

class WidgetTypeSelection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const availableWidgets = pageWidgets[this.props.page];
    return (
      <div
        className={classNames(
          'available-types available-widgets',
          this.props.page + '-widgets',
        )}
      >
        <h3 className='widget-title-head'>
          {this.props.title ? t(this.props.title) : t('Widget')}
        </h3>
        <p className='widget-title-desc'>
          {this.props.desc ? t(this.props.desc) : t('Widget_Desc')}
        </p>
        <div className='available-type-selection'>
          {Object.keys(widgetTypes).map((type, index) => {
            if (type === 'custom' || availableWidgets.indexOf(type) < 0) {
              return;
            }
            return (
              <span key={type + '-' + index}>
                <Link
                  key={index}
                  to={generateLinkforWidget(this.props.page, type)}
                  className={classNames(
                    'available-type',
                    'available-type-' +
                    this.props.page +
                      '-' +
                      widgetTypes[type].className,
                    this.props.isFiltered ? 'pointer-no-event' : '',
                  )}
                >
                  <h4 className='available-type-name'>
                    {widgetTypes[type].alternativeName
                      ? t(widgetTypes[type].alternativeName)
                      : t(
                          widgetTypes[type].algorithms[this.props.page][0]
                            .title,
                        )}
                  </h4>

                  <span className='available-type-wireframe'>
                    <span
                      className={classNames(
                        'available-type-text',
                        'available-type-text-' + this.props.page,
                        'available-type-text-' +
                          this.props.page +
                          '-' +
                          widgetTypes[type].className,
                      )}
                    >
                      {type !== 'popup_recommendation' &&
                        type !== 'stickyRecommendation' &&
                        t(
                          widgetTypes[type].algorithms[this.props.page][0]
                            .title,
                        )}
                    </span>
                  </span>
                </Link>
              </span>
            );
          })}
          <span>
            <Link
              to={generateLinkforWidget(this.props.page, false)}
              className='available-type available-type-custom'
            >
              <span className='available-type-wireframe'>
                <Icon name='plus' size='2x' />
                <span>
                  {this.props.widgetName
                    ? t(this.props.widgetName)
                    : t('Add Custom Widget')}
                </span>
              </span>
            </Link>
          </span>
        </div>
      </div>
    );
  }
}

export default WidgetTypeSelection;
