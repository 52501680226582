import { createReducer } from "../system/store";
import * as searchWizardActions from "../constants/actions/wizard";

const initialState = {
  criteria: [],
  excludes: {mobile: {CATEGORY:[], BRAND:[], KEYWORD:[], LAST_SEARCHES: []}, pc: {CATEGORY:[], BRAND:[], KEYWORD:[], LAST_SEARCHES: []}},
};

const transformCriteria = criterias => {
    let grouped = criterias.reduce(
        (h, a) => Object.assign(h, { [a.type]: (h[a.type] || []).concat(a) }),
        {}
    );
    let newArr = [];
    Object.keys(grouped).forEach(group => {
        newArr = newArr.concat(grouped[group]);
    });
    return newArr;
};

export default createReducer(initialState, {
    [searchWizardActions.default.RESET_SEARCH_WIZARD]: state => {
        return Object.assign({}, state, {
            criteria: [],
            typedExcludes: [],
            excludes: {mobile: {CATEGORY:[], BRAND:[], KEYWORD:[], LAST_SEARCHES: []}, pc: {CATEGORY:[], BRAND:[], KEYWORD:[], LAST_SEARCHES: []}},
        });
    },
    [searchWizardActions.default.ADD_SEARCH_WIZARD_CRITERIA]: (state, payload) => {
        return Object.assign({}, state, {
          criteria: payload.criteria
        });
    },
    [searchWizardActions.default.ADD_SEARCH_WIZARD_EXCLUSION]: (state, payload) => {
        if (payload && payload.excludes && payload.excludes.length > 0) {
            let type = "";
            payload.excludes.forEach(exclude=>{
                let searchCriteria = state.criteria.find(item=>item.properties.type.toLowerCase()===exclude.type);
                searchCriteria.values.criterion.exclusions = [exclude];
                type = searchCriteria.properties.type;
            });
            let newExcludes = {excludes: {pc: state.excludes.pc, mobile:state.excludes.mobile}};
            let device = payload.isMobile? 'mobile' : 'pc';
            newExcludes.excludes[device][type] = transformCriteria(payload.excludes);
            return Object.assign({}, state, newExcludes);
        } else {
            return Object.assign({}, state);
        }
    },
    [searchWizardActions.default.REMOVE_SEARCH_WIZARD_EXCLUSION]: (state, payload) => {
        let device = payload.isMobile? 'mobile' : 'pc';
        if (payload && payload.excludes && payload.excludes.length > 0) {
            let type = "";
            payload.excludes.forEach(exclude=>{
                let searchCriteria = state.criteria.find(item=>item.properties.type.toLowerCase()===exclude.type);
                searchCriteria.values.criterion.exclusions = [exclude];
                type = searchCriteria.properties.type;
            });
            let newExcludes = {excludes: {pc: state.excludes.pc, mobile:state.excludes.mobile}};
            newExcludes.excludes[device][type] = transformCriteria(payload.excludes);
            return Object.assign({}, state, newExcludes);
        } else {
            if (device && payload.type) {
                state.excludes[device][payload.type.toUpperCase()] = [];
            }
            return Object.assign({}, state, {typedExcludes: []});
        }
    },
})
