import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { devices } from '../../constants/datamaps/campaigns';
import { pushList } from '../../constants/datamaps/push';
import Icon from '../icon';
import Icons from '../icons';
import { isSuperUser } from '../../modules/auth/user';
import {
  getPermissionReport,
  moveCampaignToTest,
} from '../../modules/push/ajax';
import { campaignBadges, campaignNames, pushGroups } from '../reports/utils';
import { getFormattedDate, isAfterNow } from '../../system/date';
import { t } from '../../system/ui';
import PushOptions from '../push/options';
import { browserSlugs, transformReport } from '../push/utils';
import Tooltip from '../tooltip';
import EmailWidgetActions from './email-actions';
import EmailStats from './email-stats';

const pushGroupTitles = type => {
  let title = '';
  Object.keys(pushGroups).forEach(group => {
    if (pushGroups[group].indexOf(type) > -1) {
      title = campaignNames[group];
    }
  });
  return title;
};

class PushCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissionElement: '',
      selectedDevice: 'ALL',
      permissionReport: transformReport({}),
    };
    this.goTestFromArchive = this.goTestFromArchive.bind(this);
    this.getCampaignPages = this.getCampaignPages.bind(this);
    this.setPermissionReport = this.setPermissionReport.bind(this);
    this.getAllPermissions = this.getAllPermissions.bind(this);
  }

  componentDidMount() {
    this.setPermissionReport(this.props.type);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.type !== newProps.type) {
      this.setPermissionReport(newProps.type);
    }
  }

  setPermissionReport(type) {
    if (type === 'PUSH_PERMISSION') {
      getPermissionReport(response => {
        this.setState({
          permissionReport: response
            ? transformReport(response)
            : transformReport({}),
        });
      });
    }
  }

  goTestFromArchive() {
    let campaignId = this.props.instanceId;
    if (campaignId) {
      moveCampaignToTest(campaignId).then(response => {
        this.props.onChangeStatus(
          this.props.campaign.instanceId,
          'TEST',
          'TEST',
        );
      });
    }
  }
  getAllPermissions(permissionElement) {
    this.setState({
      permissionElement,
    });
  }

  isLive() {
    return this.props.status === 'ACTIVE';
  }

  getCampaignBadges() {
    let badge = '',
      schedule = this.props.schedule;
    if (schedule) {
      if (this.props.schedule.type === 'periodic') {
        badge = 'periodic';
      } else if (schedule && schedule.type === 'one_time') {
        badge = isAfterNow(moment(schedule.date)) ? 'scheduled' : 'sent';
      } else if (schedule && schedule.type === 'now') {
        badge = 'sent';
      }
      return badge;
    }
    if (pushList.indexOf(this.props.type) > -1) {
      if (
        this.props.type === 'ABANDONED_CART' ||
        this.props.type === 'LAST_VISIT_REMINDER' ||
        this.props.type === 'ORDER_FOLLOW_UP'
      ) {
        badge = 'personalized';
      } else {
        badge = 'periodic';
      }
      return badge;
    }
    if (this.props.type === 'FLY') {
      badge = 'personalized';
      return badge;
    }
    return false;
  }

  getCampaignPages(filter) {
    if (!filter.includedCategories) {
      return false;
    }
    let includedPages = '';
    if (filter.includedCategories.indexOf('allpages') !== -1) {
      includedPages += t('All Pages');
    } else {
      filter.includedCategories.forEach((pageName, pageIndex) => {
        if (pageIndex === filter.includedCategories.length - 1) {
          includedPages += t(pageName);
        } else {
          includedPages += t(pageName) + ', ';
        }
      });
    }
    return (
      <span className='widget-meta-pages'>
        <Tooltip content={includedPages} alignment='left'>
          <Icons name='browser' width='15' height='17' color='#bababa' />{' '}
          {t('Pages')}
        </Tooltip>
      </span>
    );
  }

  getDevices(props) {
    let comp = [];
    if (props.type !== 'PUSH_PERMISSION') {
      props.devices.indexOf('ALL') > -1
        ? Object.keys(props.devices).map(device => {
            if (device !== 'TABLET') {
              comp.push(
                <span key={device} className='widget-meta-mobile'>
                  <Tooltip
                    content={t(devices[device])}
                    newTooltip={true}
                    alignment='left'
                  >
                    <Icon name={device.toLowerCase()} />
                  </Tooltip>
                </span>,
              );
            }
          })
        : props.devices.map(device => {
            if (device !== 'TABLET') {
              comp.push(
                <span key={device} className='widget-meta-mobile'>
                  <Tooltip content={t(devices[device])} alignment='left'>
                    <Icon name={device.toLowerCase()} />
                  </Tooltip>
                </span>,
              );
            }
          });
      return comp;
    } else {
      let report = Object.keys(this.state.permissionReport).map(reportObj => {
        return this.state.permissionReport[reportObj];
      });
      let activeBrowsers = report
        .filter(rp => {
          return rp.segmentifyCount > 0;
        })
        .map(rp => {
          return rp.browser;
        });
      return (
        <span>
          {Object.keys(browserSlugs).map(browser => {
            let opacity = activeBrowsers.indexOf(browser) > -1 ? '1' : '0.4';
            let className =
              'browser-icon browser-icon-' + browserSlugs[browser];
            return (
              <Tooltip content={t(browser)} alignment='left' key={browser}>
                <span
                  style={{
                    opacity: opacity,
                  }}
                  className={className}
                >
                  <Icons
                    name={browserSlugs[browser]}
                    width='16'
                    height='16'
                    color='#c2c2c2'
                  />
                </span>
              </Tooltip>
            );
          })}
        </span>
      );
    }
  }

  selectDevice(selectedDevice) {
    this.setState({
      selectedDevice,
    });
  }

  render() {
    let campaignStatusMarkup = null;
    let badge = this.getCampaignBadges();
    if (badge) {
      let badgeObj = campaignBadges[badge];
      let givenClass = 'widget-timelimit-badge widget-timelimit-badge-';
      givenClass += badge;
      campaignStatusMarkup = (
        <Tooltip
          content={badgeObj.tooltip}
          alignment='center'
          className='widget-timelimit-tooltip widget-push-timelimit-tooltip'
        >
          <div className={givenClass}>
            {badgeObj.subIcon && (
              <div style={{ position: 'absolute', right: '5px', top: '-25px' }}>
                <Icons
                  name={badgeObj.subIcon}
                  color={badgeObj.color}
                  width='15'
                  height='15'
                />
              </div>
            )}
            <Icons
              name={badgeObj.icon}
              color={badgeObj.color}
              width='25'
              height='25'
            />
          </div>
        </Tooltip>
      );
    }
    return (
      <div className='widget page-content-block' ref='widget'>
        <div>
          {campaignStatusMarkup}
          {isSuperUser() ? (
            <h3 className='widget-page'>
              {pushGroupTitles(this.props.type)} -{' '}
              <span style={{ color: '#c0bebe', fontWeight: 'normal' }}>
                {this.props.instanceId}
              </span>
            </h3>
          ) : (
            <h3 className='widget-page'>{pushGroupTitles(this.props.type)}</h3>
          )}
          <h2 className='widget-title'>{this.props.name}</h2>
        </div>
        {this.props.status !== 'PASSIVE' && (
          <PushOptions
            {...this.props}
            instance={
              this.props.campaign[0] !== undefined &&
              this.props.campaign[0].type === 'PUSH_PERMISSION'
                ? this.props[0].instanceId
                : this.props.instanceId
            }
            testMode={
              this.props.campaign[0] !== undefined &&
              this.props.campaign[0].type === 'PUSH_PERMISSION'
                ? this.props.campaign[0].status !== 'ACTIVE'
                : this.props.campaign.status !== 'ACTIVE'
            }
          />
        )}
        <div className={'widget-meta'}>
          {this.props.startDate && (
            <span className='widget-meta-date'>
              <Icon name='calendar' />
              {getFormattedDate(this.props.startDate)}
              {this.props.endDate
                ? ' - ' + getFormattedDate(this.props.endDate)
                : ''}
            </span>
          )}
          {this.props.type !== 'PUSH_PERMISSION' && (
            <span className={'recommendation-device-selection'}>
              <Tooltip
                content={'All Devices'}
                alignment='top'
                newTooltip={true}
                isInline={true}
              >
                <span
                  key='all-devices'
                  onClick={this.selectDevice.bind(this, 'ALL')}
                  className={classNames(
                    'widget-meta-mobile all-device-report',
                    {
                      'is-selected': this.state.selectedDevice === 'ALL',
                    },
                  )}
                >
                  <Icons
                    name={'allDevices'}
                    width='16'
                    height='16'
                    color='#bababa'
                  />
                  {t('ALL')}
                </span>
              </Tooltip>

              {this.props.devices.map(device => {
                if (
                  device.toLowerCase() === 'tablet' ||
                  device.toLowerCase() === 'pctablet'
                ) {
                  return;
                }

                return (
                  <Tooltip
                    content={t(devices[device])}
                    alignment='top'
                    newTooltip={true}
                    isInline={true}
                    key={device}
                  >
                    <span
                      key={device}
                      onClick={this.selectDevice.bind(this, device)}
                      className={classNames('widget-meta-mobile', {
                        'is-selected': this.state.selectedDevice === device,
                      })}
                    >
                      <Icon name={device.toLowerCase()} />
                    </span>
                  </Tooltip>
                );
              })}
            </span>
          )}
          {this.props.type === 'PUSH_PERMISSION' && this.getDevices(this.props)}
          {this.props.type !== 'PUSH_PERMISSION' &&
            this.props.filters &&
            this.props.filters[0] &&
            this.getCampaignPages(this.props.filters[0])}
          <hr className='widget-separator' />

          {/*<EmailStats*/}
          {/*  report={this.props.report}*/}
          {/*  isPush={true}*/}
          {/*  pushType={this.props.type}*/}
          {/*  isPushPermission={this.props.type === 'PUSH_PERMISSION'}*/}
          {/*  permissionReport={this.state.permissionReport}*/}
          {/*  getAllPermissions={this.getAllPermissions}*/}
          {/*  selectedDevice={*/}
          {/*    this.state.selectedDevice === 'PC'*/}
          {/*      ? 'PC'*/}
          {/*      : this.state.selectedDevice.toLowerCase()*/}
          {/*  }*/}
          {/*/>*/}
          {/*<hr className='widget-separator' />*/}

          <div className='widget-buttons group'>
            {this.props.campaign.status !== 'PASSIVE' ? (
              <div>
                {' '}
                <EmailWidgetActions
                  campaign={
                    this.props.type !== 'PUSH_PERMISSION'
                      ? this.props.campaign
                      : this.props[0]
                  }
                  onChangeStatus={this.props.onChangeStatus}
                  isPush={true}
                />
                {this.props.type === 'PUSH_PERMISSION' &&
                  this.state.permissionElement}
              </div>
            ) : (
              isSuperUser() && (
                <EmailWidgetActions
                  isPassive={true}
                  campaign={
                    this.props.type !== 'PUSH_PERMISSION'
                      ? this.props.campaign
                      : this.props[0]
                  }
                  onChangeStatus={this.props.onChangeStatus}
                  isPush={true}
                  goTestFromArchive={this.goTestFromArchive}
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PushCard;
