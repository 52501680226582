/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";

import { wizardActions, uiActions } from "../../actions";

import ExcludesCustom from "./excludes-custom";
import ExcludesProduct from "./excludes-product";
import ExcludesCategory from "./excludes-category";
import ExcludesPriceRange from "./excludes-price-range";
import ExcludesBrand from "./excludes-brands";
import ExcludesLabel from "./excludes-label";
import ExcludesGender from "./excludes-gender";
import ExcludesSize from "./excludes-size";
import ExcludesStock from "./excludes-stock";
import ExcludesColor from "./excludes-color";
import ExcludesDiscounted from "./excludes-discounted";

const exclusionTypes = {
  price_range: ExcludesPriceRange,
  category: ExcludesCategory,
  product: ExcludesProduct,
  brand: ExcludesBrand,
  label: ExcludesLabel,
  gender: ExcludesGender,
  custom: ExcludesCustom,
  size: ExcludesSize,
  stock: ExcludesStock,
  color: ExcludesColor,
  discounted_product: ExcludesDiscounted,
};

class Excludes extends React.Component {
  constructor(props) {
    super(props);

    this.handleExclusionRemove = this.handleExclusionRemove.bind(this);
    this.handleExclusionChange = this.handleExclusionChange.bind(this);
    this.handleExclusionSelectChange = this.handleExclusionSelectChange.bind(
      this,
    );
    this.handleExclusionAdd = this.handleExclusionAdd.bind(this);
  }

  handleExclusionRemove(exclusionId) {
    let modifiedExclusions;

    modifiedExclusions = this.props.excludes.filter(exclude => {
      return exclude.id !== exclusionId;
    });

    if (this.props.selectedPage === 'afterSearch') {
      wizardActions.removeNoResultExclusion(modifiedExclusions);
    } else {
      wizardActions.removeExclusion(modifiedExclusions);
    }
    uiActions.formEdited();
  }

  handleExclusionChange(fields, exclusionId, options) {
    let oldExcludes = this.props.excludes,
      inputs = fields.querySelectorAll(".criteria-field"),
      values = {},
      excludes = {};

    if (options && Object.keys(options).length > 0) {
      excludes = oldExcludes.filter(exclude => {
        if (exclude.type !== options.reset) {
          return true;
        }
        return exclude.id === exclusionId;
      });
    } else {
      excludes = oldExcludes;
    }

    inputs.forEach(item => {
      values[item.name] = {
        value: item.value
      };
    });

    for (let i = 0; i < excludes.length; i++) {
      if (excludes[i].id === exclusionId) {
        excludes[i].values = values;
        break;
      }
    }

    if (this.props.selectedPage === 'afterSearch') {
      wizardActions.addNoResultExclusion(excludes);
    } else {
      wizardActions.addExclusion(excludes);
    }
    uiActions.formEdited();
  }

  handleExclusionSelectChange(fieldName, newValues, exclusionId) {
    let excludes = this.props.excludes,
      values = {};
    values[fieldName] = {
      value: []
    };

    let items = [];
    if (newValues) {
      newValues.forEach(item => {
        items.push(item.value);
      });
      values[fieldName].value = items;
      for (let i = 0; i < excludes.length; i++) {
        if (excludes[i].id === exclusionId) {
          excludes[i].values = values;
          break;
        }
      }
    }
    if (this.props.selectedPage === 'afterSearch') {
      wizardActions.addNoResultExclusion(excludes);
    } else {
      wizardActions.addExclusion(excludes);
    }
    uiActions.formEdited();
  }

  handleExclusionAdd(type) {
    let current = this.props.excludes;
    current = current.concat({ id: Math.random(), type: type, values: {} });
    if (this.props.selectedPage === 'afterSearch') {
      wizardActions.addNoResultExclusion(current);
    } else {
      wizardActions.addExclusion(current);
    }
  }

  render() {
    let subComponents = {};
    let allComponents = [];
    this.props.excludes.forEach(exclude => {
      if (exclude.type) {
        if (subComponents[exclude.type]) {
          subComponents[exclude.type].push(exclude);
        } else {
          subComponents[exclude.type] = [exclude];
        }
      }
    });
    Object.keys(subComponents).forEach(subComponent => {
      let items = subComponents[subComponent];
      items.forEach((item, ind) => {
        let Component = exclusionTypes[item.type];
        let renderedComponent = (
          <Component
            key={item.id}
            {...item}
            onExclusionRemove={this.handleExclusionRemove}
            onExclusionChange={this.handleExclusionChange}
            onExclusionAdd={this.handleExclusionAdd}
            onExclusionSelectChange={this.handleExclusionSelectChange}
            subComponents={subComponents[item.type]}
          />
        );
        if (subComponent === "custom" || ind === 0) {
          allComponents.push(renderedComponent);
        }
      });
    });
    if (this.props.excludes.length > 0) {
      return (
        <ol className="exclusion-list criteria-list wizard-items-container">
          {allComponents}
        </ol>
      );
    } else {
      return "";
    }
  }
}

const mapStatesToProps = (store, thisProps) => ({
  excludes:
    thisProps.selectedPage === 'afterSearch'
      ? store.wizard.noResultExcludes
      : store.wizard.excludes,
});

export default connect(mapStatesToProps)(Excludes);
