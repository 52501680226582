import React, { useEffect } from 'react';
import { uiActions } from '../../../actions';
import './SFYHeader.scss';
/**
 * @name SFYHeader component
 * @description Header component sets @param pageTitle and @param breadCrumbOptions which are used in Breadcrumb component
 * @param {string} pageTitle
 * @param {object} breadCrumbOptions
 * @returns {JSX.Element}
 */

/* const attrVal = {
  desc:
    'The feature allows you manage your product catalog and axplore attributes of products.',
}; */

const SFYHeader = ({ pageTitle, breadCrumbOptions, childrenAttr }) => {
  const { showBreadcrumb } = uiActions;

  const invokeBreadcrumb = () => {
    showBreadcrumb(breadCrumbOptions);
  };

  useEffect(() => {
    if (breadCrumbOptions) {
      invokeBreadcrumb();
    }
  }, [pageTitle, breadCrumbOptions, childrenAttr]);

  return (
    <>
      <header>
        <h2 className='page-title' style={{ marginBottom: '30px' }}>
          {pageTitle}
        </h2>
      </header>
      {childrenAttr && <p className='subTitle'>{childrenAttr?.subTitle}</p>}
      {childrenAttr?.desc && (
        <div className='page-desc'>
          <i className='icon-info' role='presentation' />
          <p>{childrenAttr?.desc}</p>
        </div>
      )}
      {childrenAttr?.noStyling && (
        <p className='no-style'>{childrenAttr?.noStyling}</p>
      )}
    </>
  );
};

export default SFYHeader;
