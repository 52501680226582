import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const ArrowTrendDown = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 20 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.7489 13.5L19.3027 13.4995L19.403 13.4856L19.5018 13.458L19.5621 13.4324C19.6413 13.3975 19.7155 13.3469 19.7808 13.2817L19.8215 13.2376L19.865 13.1812L19.9192 13.0911L19.9579 13.0008L19.977 12.9367L19.9906 12.8727L20 12.7785L20.0004 5.2461C20.0004 4.83191 19.6647 4.49612 19.2504 4.49612C18.8708 4.49612 18.557 4.77827 18.5073 5.1443L18.5004 5.2461L18.5 10.941L10.7812 3.22075C10.5149 2.95447 10.0982 2.93025 9.8046 3.14811L9.7205 3.22072L6.75001 6.1911L1.28033 0.721407C0.98744 0.428517 0.51256 0.428517 0.21967 0.721407C-0.0465996 0.987677 -0.0707998 1.40434 0.14705 1.69795L0.21967 1.78207L6.21967 7.7821C6.48593 8.0483 6.90259 8.0725 7.1962 7.8547L7.28032 7.7821L10.2508 4.81173L17.438 12H11.7489C11.3692 12 11.0554 12.2822 11.0058 12.6482L10.9989 12.75C10.9989 13.1297 11.2811 13.4435 11.6472 13.4932L11.7489 13.5Z'
        fill='#5D5D61'
      />
    </svg>
  );
};

ArrowTrendDown.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

ArrowTrendDown.defaultProps = {
  width: '20px',
  height: '14px',
};

export default ArrowTrendDown;
