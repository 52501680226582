/* eslint-disable react/no-deprecated */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { t } from '../../../system/ui';
import { getPages } from '../../account/ajax';

import { CheckboxGroup, Checkbox } from '../../../components/fields';

import { uiActions } from '../../../actions';
import Icons from '../../../components/icons';

class Pages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: [],
      name: 'pages',
      label: t('All'),
      hasError: false,
      showRequiredError: false,
      customPages: [],
      _customPages: [],
      hasPageUrlFilter: props?.options?.hasPageUrlFilter || false,
      onlyPageUrlFilter: props?.options?.onlyPageUrlFilter || false,
      showURLOptions: false,
      selectedUrlFilter: 'contains', // contains, notContains
      filterUrls: [{ id: Math.random(), value: '' }],
    };

    this.onChange = this.onChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.onCustomPageSearch = this.onCustomPageSearch.bind(this);
    this.getAllStatus = this.getAllStatus.bind(this);
    this.onSelectedAll = this.onSelectedAll.bind(this);
    this.validate = this.validate.bind(this);
    this.changeDropdownVisibility = this.changeDropdownVisibility.bind(this);
    this.changeUrlFilter = this.changeUrlFilter.bind(this);
    this.updateUrlFilter = this.updateUrlFilter.bind(this);
    this.onAddUrl = this.onAddUrl.bind(this);
    this.onRemoveUrl = this.onRemoveUrl.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.handleClickOutsideOfDropdown = this.handleClickOutsideOfDropdown.bind(
      this,
    );
  }

  componentDidMount() {
    const { isAddCampaign } = this.props;
    getPages(response => {
      if (isAddCampaign) {
        this.setState({
          customPages: response.sort(),
          _customPages: response.sort(),
          value: response.sort(),
          label: t('All'),
        });
      } else {
        this.setState({
          customPages: response.sort(),
          _customPages: response.sort(),
        });
      }
    });
    this.updateUrlFilter(this.props.campaign.filters);

    // Close dropdowns when clicked outside
    document.addEventListener(
      'mousedown',
      this.handleClickOutsideOfDropdown,
      false,
    );
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      if (!this.state.onlyPageUrlFilter) {
        const pages = this.findPages(newProps.campaign.filters)[0];
        this.updateValue(pages, () => {
          if (pages.length === 0) {
            this.setState(prevState => ({
              value: prevState.customPages,
              label: t('All'),
            }));
          }
        });
      }
      this.updateUrlFilter(newProps.campaign.filters);
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      'mousedown',
      this.handleClickOutsideOfDropdown,
      false,
    );
  }

  handleClickOutsideOfDropdown(event) {
    if (
      this.refs.urls &&
      !this.refs.urlFilter.contains(event.target) &&
      !this.refs.urls.contains(event.target) &&
      this.state.showURLOptions
    ) {
      this.setState({
        showURLOptions: false,
      });
    }
  }

  onCustomPageSearch(e) {
    const newValue = e.target.value;
    const searchResults = [];
    this.state.customPages.forEach(page => {
      if (page.toLowerCase().indexOf(newValue.toLowerCase()) !== -1) {
        searchResults.push(page);
      }
    });
    this.setState({
      _customPages: searchResults,
    });
  }

  onSelectedAll() {
    let newLabel = '';
    if (this.getAllStatus()) {
      const currentValues = this.state._customPages;
      const newSelectedValues = [];
      this.state.value.forEach(val => {
        if (currentValues.indexOf(val) === -1) {
          newSelectedValues.push(val);
        }
        newSelectedValues.forEach(page => {
          newLabel += `${t(page)}, `;
        });
        if (newSelectedValues.length > 5) {
          newLabel = `${newSelectedValues.length} ${t('pages selected')}`;
        }
        if (newLabel.length === 0) {
          newLabel = t('Select Pages');
        }
        this.setState(
          {
            value: newSelectedValues,
            label: newLabel,
          },
          this.validate,
        );
      });
    } else if (
      this.state._customPages.length === this.state.customPages.length
    ) {
      // All elements selected
      this.setState(
        prevState => ({
          value: prevState.customPages,
          label: t('All'),
        }),
        this.validate,
      );
    } else {
      // All searched elements selected
      this.setState(
        prevState => ({
          value: prevState._customPages,
          label: newLabel,
        }),
        this.validate,
      );
    }
  }

  onChange(val) {
    this.updateValue(val, this.validate);
    uiActions.formEdited();
  }

  onAddUrl(event) {
    event.preventDefault();
    this.setState(
      prevState => ({
        filterUrls: prevState.filterUrls.concat({
          id: Math.random(),
          value: '',
        }),
      }),
      () => {
        this.refs.pageURlForm.querySelector('input').focus();
      },
    );
  }

  onRemoveUrl(id) {
    this.setState(prevState => ({
      filterUrls: prevState.filterUrls.filter(url => url.id !== id),
    }));
  }

  onUrlChange(id, e) {
    this.setState(prevState => ({
      filterUrls: prevState.filterUrls.map(url =>
        id === url.id ? { id: url.id, value: e.target.value } : url,
      ),
    }));
  }

  getAllStatus() {
    let allIsSelected = true;
    const currentValues = this.state._customPages;
    currentValues.forEach(val => {
      if (this.state.value.indexOf(val) === -1) {
        allIsSelected = false;
      }
    });
    return allIsSelected;
  }

  findPages = filters => {
    if (filters) {
      return filters.map(filter => {
        if (filter.type === 'PAGE') {
          return filter.includedCategories;
        }
        return [];
      });
    }

    return [[]];
  };

  updateValue(newValue, callback) {
    const selectedValues = [];
    let newLabel = '';

    newValue.forEach(page => {
      if (!selectedValues.includes(page)) {
        selectedValues.push(page);
        newLabel += `${t(page)}, `;
      }
    });

    newLabel = newLabel.slice(0, -2);

    if (newLabel.length === 0) {
      newLabel = t('Select Pages');
    }

    if (selectedValues.length > 5) {
      newLabel = `${selectedValues.length} ${t('pages selected')}`;
    }

    if (selectedValues.length === this.state.customPages.length) {
      newLabel = t('All');
    }

    this.setState(
      {
        value: selectedValues,
        label: newLabel,
      },
      callback,
    );
  }

  validate() {
    if (this.state.value.length === 0) {
      this.setState({
        hasError: true,
        showRequiredError: true,
      });
    } else {
      this.setState({
        hasError: false,
        showRequiredError: false,
      });
    }
  }

  changeUrlFilter(type) {
    this.setState({
      selectedUrlFilter: type,
    });
  }

  updateUrlFilter(filters) {
    if (!filters) {
      return;
    }

    const urlFilter = filters.find(filter => {
      return filter.type === 'PAGE_URL';
    });
    const includedUrls = urlFilter?.includedUrls || [];
    const excludedUrls = urlFilter?.excludedUrls || [];
    const selectedUrlFilter =
      excludedUrls?.length > 0 ? 'notContains' : 'contains';
    const selectedUrls =
      selectedUrlFilter === 'contains' ? includedUrls : excludedUrls;

    if (selectedUrls.length === 0) {
      this.setState({
        selectedUrlFilter,
        filterUrls: [{ id: Math.random(), value: '' }],
      });
    } else {
      this.setState({
        selectedUrlFilter,
        filterUrls: selectedUrls.map(filter => {
          return { id: Math.random(), value: filter };
        }),
      });
    }
  }

  changeDropdownVisibility(dropdownType) {
    this.setState(prevState => ({
      [dropdownType]: !prevState[dropdownType],
    }));
  }

  render() {
    return (
      <div className={classNames('wizard-page-selection')}>
        <label className='item item-stacked label-emphasise one-whole'>
          <span className='item-label'>{t('Pages/Url Selection')}</span>
        </label>
        {!this.state.onlyPageUrlFilter && (
          <>
            <CheckboxGroup
              value={this.state.value}
              className={`page-type-selection ${this.state.showRequiredError &&
                'has-error'}`}
              onChange={this.onChange}
            >
              <div className='check-group'>
                <div className='check-group-header'>{this.state.label}</div>
                <div className='check-group-drop check-group-drop__search'>
                  <div className='check-group-search'>
                    <input
                      type='text'
                      placeholder={t('Search')}
                      onChange={this.onCustomPageSearch}
                    />
                    <i className='icon-magnify' role='presentation' />
                  </div>
                  {this.state._customPages.length > 0 && (
                    <label
                      className={
                        this.getAllStatus()
                          ? 'item item-field is-checkbox is-checked'
                          : 'item item-field is-checkbox'
                      }
                    >
                      <input
                        type='checkbox'
                        checked={this.getAllStatus()}
                        onChange={this.onSelectedAll}
                      />
                      <span className='item-label'>{t('All')}</span>
                    </label>
                  )}
                  {this.state._customPages.length ? (
                    this.state._customPages.map(page => {
                      return <Checkbox key={page} label={page} value={page} />;
                    })
                  ) : (
                    <div>{t('No Results')}</div>
                  )}
                </div>
              </div>
            </CheckboxGroup>
            {this.state.showRequiredError ? (
              <span className='item-error'>
                {t('You should select at least one page type')}
              </span>
            ) : (
              ''
            )}
          </>
        )}

        {this.state.hasPageUrlFilter && (
          <>
            <div ref='urlFilter' className='check-group'>
              <div
                className='check-group-header'
                onClick={this.changeDropdownVisibility.bind(
                  this,
                  'showURLOptions',
                )}
              >
                Select URL
                <Icons
                  name={this.state.showURLOptions ? 'chevronUp' : 'chevronDown'}
                  color='#000'
                />
              </div>
              <div
                className='device-types check-group-drop'
                style={{
                  display: this.state.showURLOptions ? 'block' : 'none',
                }}
              >
                <button
                  type='button'
                  className='item item-field is-checkbox is-checked'
                  onClick={this.changeUrlFilter.bind(this, 'contains')}
                >
                  <span className='item-label' title='Desktop'>
                    Contains
                  </span>
                  <Icons
                    name={
                      this.state.selectedUrlFilter === 'contains'
                        ? 'radioSelected'
                        : 'radioEmpty'
                    }
                  />
                </button>

                <button
                  type='button'
                  className='item item-field is-checkbox is-checked'
                  onClick={this.changeUrlFilter.bind(this, 'notContains')}
                >
                  <span className='item-label' title='Mobile Web'>
                    Not Contains
                  </span>
                  <Icons
                    name={
                      this.state.selectedUrlFilter === 'notContains'
                        ? 'radioSelected'
                        : 'radioEmpty'
                    }
                  />
                </button>
              </div>
            </div>

            <div ref='urls' className='url-field'>
              {this.state.filterUrls.map((url, index) => {
                const isRemovable = this.state.filterUrls.length > 1;
                return (
                  <span key={url.id}>
                    {index === this.state.filterUrls.length - 1 ? (
                      <form onSubmit={this.onAddUrl} ref='pageURlForm'>
                        <input
                          type='text'
                          defaultValue={url.value}
                          className='pageURL'
                          onChange={this.onUrlChange.bind(this, url.id)}
                        />
                        <span
                          className='pageURL-addIcon'
                          style={{ cursor: 'pointer' }}
                          onClick={this.onAddUrl}
                        >
                          <Icons name='plusCircle' width='16' height='16' />
                        </span>
                        <span
                          style={{ cursor: 'pointer', position: 'relative' }}
                          onClick={
                            isRemovable
                              ? this.onRemoveUrl.bind(this, url.id)
                              : () =>
                                  this.setState({
                                    filterUrls: [
                                      { id: Math.random(), value: '' },
                                    ],
                                  })
                          }
                        >
                          <Icons name='crossCircle' width='16' height='16' />
                        </span>
                      </form>
                    ) : (
                      <span>
                        <input
                          type='text'
                          defaultValue={url.value}
                          className='pageURL'
                        />
                        <span
                          style={{ cursor: 'pointer', position: 'relative' }}
                          onClick={this.onRemoveUrl.bind(this, url.id)}
                        >
                          <Icons name='crossCircle' width='16' height='16' />
                        </span>
                      </span>
                    )}
                  </span>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  validate: store.ui.validate,
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  Pages,
);
