import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { facetedSearchRequest } from '../../actions/faceted-search';
import { SearchReportRow } from '../../views/searchreportrow';
import StatusFilters from '../filters/search-status-filters';
import { uiActions } from '../../actions';
import { setTitle } from '../../system/document';
import { t } from '../../system/ui';

class SearchBannerReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
      statistics: {},
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      moment(prevProps.date.range[0]).format('YYYY-MM-DD') !==
        moment(this.props.date.range[0]).format('YYYY-MM-DD') ||
      moment(prevProps.date.range[1]).format('YYYY-MM-DD') !==
        moment(this.props.date.range[1]).format('YYYY-MM-DD')
    ) {
      this.getData();
    }
  }

  componentDidMount() {
    setTitle(t('Search Banner Report'));
    this.getData();
  }

  getStartAndEndDate = () => {
    const { date } = this.props;
    return {
      start: moment(date.range[0]).format('YYYY-MM-DD'),
      end: moment(date.range[1]).format('YYYY-MM-DD'),
    };
  };

  getData = async () => {
    try {
      uiActions.isLoading();
      const { start, end } = this.getStartAndEndDate();
      const banners = await facetedSearchRequest('searchbanner/list').get();
      let v = 0;
      const statisticsTasks = banners.map(banner => {
        v += 1;
        return facetedSearchRequest(
          'facetedSearch/campaignReport',
          null,
          `&start=${start}&end=${end}&interval=total&limit=10&sortBy=revenue&v=${v}`,
        ).post([banner.id], { type: 'json' });
      });
      const statisticsRes = await Promise.all(statisticsTasks);
      let statistics = {};
      statisticsRes.forEach(singleRes => {
        statistics = { ...statistics, ...singleRes };
      });
      this.setState(
        {
          banners,
          statistics,
        },
        () => uiActions.isLoaded(),
      );
    } catch (e) {
      uiActions.isLoaded();
      const notificationContent = () => (
        <span>Error occurred while retrieving the banner reports</span>
      );
      uiActions.showErrorNotification(notificationContent);
    }
  };

  render() {
    const { banners, statistics } = this.state;
    const { status } = this.props;
    const shownStatistics = Object.keys(statistics).filter(instanceId =>
      banners.some(banner => {
        let bannerStatus = banner.status === status;
        if (status === 'TEST') {
          bannerStatus = bannerStatus || banner.status === 'DRAFT';
        }
        return banner.id === instanceId && bannerStatus;
      }),
    );
    return (
      <div className='search-banner-report'>
        <h2 className='page-title'>Banners</h2>
        <div className='page-filters has-filter-form' id='search-banner-filter'>
          <StatusFilters showCheckbox={false} />
        </div>
        {shownStatistics.map(instanceId => (
          <SearchReportRow
            key={instanceId}
            type='banner'
            data={statistics}
            loaded
            hideFilters
            title={banners.find(banner => banner.id === instanceId)?.name}
            instanceId={instanceId}
          />
        ))}
        {shownStatistics.length === 0 && (
          <div className='no-report'>
            <p className='no-report-info'>
              There is <strong>no banner report data</strong>
            </p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = store => ({
  date: store.date,
  status: store.campaigns.status,
});

export default connect(mapStateToProps)(SearchBannerReport);
