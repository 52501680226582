import useExtendedContext from '../../../../hooks/module-context/useExtendedContext';
import { shopTheLookReducer } from './reducers/shopTheLookReducer';
import {
  editSTLBundle,
  removeSTLBundle,
  resetSTL,
  setRelatedProductId,
  setSTLBundle,
  setSTLBundleGroupName,
  setSTLBundleList,
  setSTLInclusionExclusionOfCategory,
  setSTLList,
  setSTLPreview,
} from './actions/shopTheLookActions';

const shopTheLookState = {
  list: [],
  bundleGroupName: '',
  relatedProductId: '',
  bundleList: [],
  previewList: [],
  params: [],
};

/**
 * @name shopTheLookContext
 * @description Context for ShopTheLook component. Provides access to the state and actions of the Wrapped component. Actions, state and dispatch are provided as react-hook basis.
 * @property {object} state - State of the context
 * @property {function} setSTLSearchParams - Function to set search query string
 * @property {function} setSTLList - Function to set list of bundles
 * @property {function} setSTLBundles - Function to set list of bundles
 * @property {function} dispatch - Function to dispatch actions
 */

export const { Context, Provider } = useExtendedContext(
  shopTheLookReducer,
  {
    setSTLBundleGroupName,
    setRelatedProductId,
    setSTLList,
    setSTLBundleList,
    setSTLBundle,
    removeSTLBundle,
    editSTLBundle,
    setSTLInclusionExclusionOfCategory,
    setSTLPreview,
    resetSTL,
  },
  shopTheLookState,
);
