import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { TextField } from '../fields';
import { convertToPositiveInt, operatorObjToArray } from './utils/utils';

import {
  numberOperators,
  operatorColumns,
} from '../../constants/datamaps/rule-based-segmentation';

/**
 * @name CriteriaFilterPageViewCount
 * @description Component to filter page view count
 * @param {object} criteriaData - The criteria data
 * @param {string} filterType - The filter type
 * @param {array} statements - The statements
 * @param {function} callbackFunc - The callback function
 * @returns {JSX.Element}
 */
const criteriaFilterPageViewCount = ({
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const defaultFilterData = {
    dataType: criteriaData?.dataType,
    column: operatorColumns.pageViewCountOperator,
    operator: numberOperators.equals.value,
    value: '',
  };

  const [pageViewCountValue, setPageViewCountValue] = useState();
  const [pageViewCountValueMax, setPageViewCountValueMax] = useState(); // Using only with between operator
  const [selectedOperator, setSelectedOperator] = useState('');

  useEffect(() => {
    const tempStatements = statements?.[filterType];

    if (tempStatements?.length > 1) {
      // Between operator
      tempStatements.forEach(statement => {
        if (statement.operator === numberOperators.gte.value) {
          setPageViewCountValue(statement.value);
        } else if (statement.operator === numberOperators.lte.value) {
          setPageViewCountValueMax(statement.value);
        }
      });
      setSelectedOperator(numberOperators.between.value);
    } else if (tempStatements?.length === 1) {
      setPageViewCountValue(tempStatements[0].value);
      setSelectedOperator(tempStatements[0].operator);
    } else {
      setSelectedOperator(defaultFilterData.operator);
      setPageViewCountValue(defaultFilterData.value);
    }
  }, [statements]);

  const triggerCallbackFunc = (val, type) => {
    const columnVal = operatorColumns.pageViewCountOperator;
    const operatorVal = type === 'operator' ? val.value : selectedOperator;
    const pageViewCountVal =
      type === 'pageViewCount'
        ? convertToPositiveInt(val.target.value, 0)
        : pageViewCountValue;
    let pageViewCountValMax =
      type === 'pageViewCountMax'
        ? convertToPositiveInt(val.target.value, 0)
        : pageViewCountValueMax;

    if (type === 'operator' && val.value !== numberOperators.between.value) {
      pageViewCountValMax = null;
    }

    const filterData = [
      {
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator:
          operatorVal === numberOperators.between.value
            ? numberOperators.gte.value
            : operatorVal,
        value: pageViewCountVal,
      },
    ];
    if (operatorVal === numberOperators.between.value) {
      filterData.push({
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator: numberOperators.lte.value,
        value: pageViewCountValMax,
      });
    }

    callbackFunc(filterType, filterData);
  };

  const onChange = (selectedValue, type) => {
    switch (type) {
      case 'pageViewCount':
        setPageViewCountValue(
          convertToPositiveInt(selectedValue.target.value, 0),
        );
        break;
      case 'pageViewCountMax':
        setPageViewCountValueMax(
          convertToPositiveInt(selectedValue.target.value, 0),
        );
        break;
      case 'operator':
        setSelectedOperator(selectedValue?.value);
        break;
      default:
        break;
    }

    triggerCallbackFunc(selectedValue, type);
  };

  return (
    <div
      className={`rbs-filter-field ${
        selectedOperator === numberOperators.between.value ? 'three-row' : ''
      }`}
    >
      <div className='filter-operator'>
        <span className='filter-operator-title'>View Operator</span>
        <Select
          value={selectedOperator}
          options={operatorObjToArray(numberOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          onChange={e => onChange(e, 'operator')}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      <div className='filter-connector' />
      <div className='filter-operator'>
        <span className='filter-operator-title'>View Count</span>
        <TextField
          value={pageViewCountValue}
          onChange={e => onChange(e, 'pageViewCount')}
          type='number'
          placeholder={
            selectedOperator === numberOperators.between.value ? 'min' : ''
          }
        />
        {selectedOperator === numberOperators.between.value && (
          <>
            <div className='filter-multi-connector' />
            <TextField
              value={pageViewCountValueMax}
              onChange={e => onChange(e, 'pageViewCountMax')}
              type='number'
              placeholder='max'
              validation={
                pageViewCountValue ? { minNumber: pageViewCountValue } : {}
              }
            />
          </>
        )}
      </div>
    </div>
  );
};

export default criteriaFilterPageViewCount;
