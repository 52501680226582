import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { jbActions, uiActions, wizardActions } from '../actions';
import { TextField } from '../components/fields';
import JourneyBuilderItems from '../components/journeyBuilder/jb.item';
import WizardAdvancedConfig from '../components/wizard/advanced-config';
import WizardDateSelection from '../components/wizard/date-selection';
import WizardMediumSelection from '../components/wizard/medium-selection';
import WizardPageType from '../components/wizard/page-type';
import { popupRecoTransform } from '../components/wizard/utils';
import { config } from '../constants/config';
import {
  jbInitialData,
  jbRecoFields,
} from '../constants/datamaps/journey-builder';
import { isSwitchAccount } from '../modules/auth/user';
import { getCampaign } from '../modules/campaigns/ajax';
import Fields from '../modules/campaign/fields';
import { goTestOrLive, singleCampaign } from '../modules/campaigns/jbwizard';
import Overlay from '../modules/campaign/fields/overlay';
import { setTitle } from '../system/document';
import { clone } from '../system/object';
import { t, confirm } from '../system/ui';

let lastType = '';

class JourneyBuilderEdit extends React.Component {
    constructor(props) {
        super(props);
        this.discoveryRef = React.createRef();
        this.deliveryRef = React.createRef();

        this.state = {
            campaign: clone(jbInitialData),
            title: "",
            scenarioName: "",
            showAdvancedConfig: false,
            selectedType: {
                page: "journey_builder",
                placement: this.props.routeParams.placement || ""
            },
            isSubmitting: false,
            submittingButton: "",
            campaignStatus: "",
            devices: "",
            saved: true,
        };

        this.onTitleChange = this.onTitleChange.bind(this);
        this.onScenarioNameChange = this.onScenarioNameChange.bind(this);
        this.showAdvancedConfig = this.showAdvancedConfig.bind(this);
        this.hideAdvancedConfig = this.hideAdvancedConfig.bind(this);
        this.campaignDetails = this.campaignDetails.bind(this);
        this.goTest = this.goTest.bind(this);
        this.goLive = this.goLive.bind(this);
        this.validate = this.validate.bind(this);
        this.getEngagementValues = this.getEngagementValues.bind(this);
        this.showBreadcrumb = this.showBreadcrumb.bind(this);
    }

    componentDidMount() {
        let  account = isSwitchAccount()
            ? this.props.switchedUser.account
            : this.props.user.account;
        this.setState({title: account && account.mainLanguage ==="TR" ? "Yardım ister misin?" : "Need Help?"},
            ()=>setTitle(
                t(
                    "Edit " +
                    config.menus.primary.recommendations.children[
                        this.state.selectedType.page
                        ].name +
                    " Page" +
                    " Campaign"
                )
            ));

        this.campaignDetails();
        uiActions.isValidated();
        uiActions.confirmOnLeave(this);
        wizardActions.saveReset();
        wizardActions.wizardReset();
        wizardActions.wizardEdit();
    }

    componentDidUpdate(oldProps) {
        if (this.props.validate === true && oldProps.validate === false) {
            this.validate(lastType);
        }
    }


    componentWillReceiveProps(newProps) {
        if (newProps.user !== this.props.user) {
            this.campaignDetails();
        }

        if (newProps.formSaved !== this.props.formSaved) {
            this.setState({
                isSubmitting: false
            });
        }

        if (newProps.formSaved !== this.state.saved) {
            this.setState({
                saved: newProps.formSaved
            });
        }

        if (newProps.formSaved === true) {
            this.setState({
                isSubmitting: false
            });
        }
    }

    componentWillUnmount() {
        wizardActions.saveReset();
        wizardActions.wizardReset();
        jbActions.wizardReset();
        jbActions.wizardDeliveryReset();
    }

    campaignDetails() {
        getCampaign(this.props.params.widgetId, response => {
            if (response) {
                singleCampaign(response);

                this.setState(
                    {
                        campaign: response,
                        scenarioName: response.scenarioName,
                        title: response.actions[0].params.notificationTitle,
                        campaignStatus: response.testMode ? "Test" : "Live",
                        devices: response.device
                    },
                    () => this.showBreadcrumb()
                );
            }
        });
    }

    showBreadcrumb() {
        uiActions.showBreadcrumb({
            home: {
                name: t("Recommend"),
                icon: "heart",
                slug: "/recommendations"
            },
            top: {
                name: t("Journey Builder"),
                slug: "/recommendations/" + this.state.selectedType.page
            },
            current: {
                name: this.state.scenarioName
            }
        });
    }

    getEngagementValues() {
        if (this.refs.contentExtra) {
            let positionOptions = this.refs.contentExtra.refs.positionOptions
                .state;
            let timingOptions = this.refs.contentExtra.refs.timingOptions
                .state;
            let audienceOptions = this.refs.contentExtra.refs.audienceOptions
                .state;
            let overlay = this.refs.contentButtons.state;
            let audience = [];
            audienceOptions.values.segment.forEach(option => {
                audience.push({
                    type: "SEGMENT",
                    include: option.member,
                    param: option.value
                });
            });
            audienceOptions.values.visitor.forEach(option => {
                audience.push({
                    type: "VISITOR",
                    include: option.member,
                    param: option.value
                });
            });
            return {
                overlay: overlay.value,
                verticalPosition: positionOptions.values.verticalPosition,
                horizontalPosition: positionOptions.values.horizontalPosition,
                delayAction: {
                    type: popupRecoTransform(
                        timingOptions.values.timing.type,
                        timingOptions.delayAllPages
                    ),
                    time: timingOptions.values.timing.param || ""
                },
                constraint: {
                    type: popupRecoTransform(timingOptions.values.frequency.type),
                    times: timingOptions.values.frequency.param || ""
                },
                filters: audience
            };
        }
        return false;
    }

    goTest() {
        this.setState(
            {isSubmitting: true, submittingButton: "test", campaignStatus: "Test"},
            () => {
                goTestOrLive(
                    "test",
                    this,
                    this.state.selectedType.page,
                    this.getEngagementValues()
                );
                uiActions.formSaved();
            }
        );
    }

    goLive() {
        this.setState(
            {isSubmitting: true, submittingButton: "live", campaignStatus: "Live"},
            () => {
                goTestOrLive(
                    "live",
                    this,
                    this.state.selectedType.page,
                    this.getEngagementValues()
                );
                uiActions.formSaved();
            }
        );
    }

    validate(type) {
        lastType = type;
        let errors = document.querySelectorAll(".has-error");

        if (this.props.validate === true && errors.length === 0) {
            if (type === "test") {
                confirm({
                    title: "Update Campaign on Test Mode?",
                    onConfirm: this.goTest,
                    onCancel: () => {
                        this.setState({
                            isSubmitting: false
                        });
                    }
                });
            }

            if (type === "live") {
                confirm({
                    title: "Update Campaign on Live?",
                    onConfirm: this.goLive,
                    onCancel: () => {
                        this.setState({
                            isSubmitting: false
                        });
                    }
                });
            }
        } else {
            const deliveryItems = this.props.delivery;
            if (deliveryItems) {
                const isAnyOneActive = deliveryItems.some(item => item.properties.active)
                if(isAnyOneActive) uiActions.isValidating();
            }
        }
    }

    onTitleChange(e) {
        this.setState({
            title: e.currentTarget.value,
        });
        uiActions.formEdited();
    }

    onScenarioNameChange(e) {
        this.setState({
            scenarioName: e.currentTarget.value,
        });
        uiActions.formEdited();
    }

    showAdvancedConfig() {
        let devices = this.refs.wizardMediumSelection.state.mediums.join("");
        this.setState({
            showAdvancedConfig: true,
            devices
        });
        uiActions.formEdited();
    }

    hideAdvancedConfig() {
        this.setState({
            showAdvancedConfig: false
        });
    }

    render() {
        let account = isSwitchAccount()
            ? this.props.switchedUser.account
            : this.props.user.account;
        return (
            <form id="wizard" ref="wizard" className="wizard wizard-cards">
                {this.state.campaign.testMode !== "" ? (
                    <h3 className="page-title">
                        <span className={"status-label status-label-" + this.state.campaignStatus.toLowerCase()}>
                            {this.state.campaignStatus}{" "}
                        </span>
                            {this.state.scenarioName}
                    </h3>
                ) : (<h3 className="page-title">{this.state.scenarioName}</h3>)}
                <div className="widget page-content-block">
                    <ol className="form-elements">
                        <li className="wizard-comp" style={{marginTop: "0px"}}>
                            <h3 className="wizard-comp-title">Trigger</h3>
                            <p className="widget-title-desc">Prepare your triggering method for your survey</p>
                            <li className="wizard-input-types wizard-perso-buttons">
                                <TextField
                                    name="notificationTitle"
                                    label="Title"
                                    className="item-stacked"
                                    value={this.state.title}
                                    required={true}
                                    onChange={this.onTitleChange}
                                    tooltip={false}
                                    style={{minWidth: "600px"}}
                                />
                                {this.state.campaign.actions && this.state.campaign.actions[0] && <Overlay
                                    ref="contentButtons"
                                    isRecommendationModule={true}
                                    campaign={this.state.campaign}
                                />}
                            </li>

                            {this.state.campaign.actions && this.state.campaign.actions[0] &&
                            <li className="wizard-perso-fields">
                                <Fields
                                    ref="contentExtra"
                                    campaign={this.state.campaign}
                                    comps={jbRecoFields.fields}
                                    campaignType="JOURNEY_BUILDER"
                                    isRecommendationModule={true}
                                />
                            </li>
                            }
                        </li>
                        <hr className="widget-separator" style={{height: "0px"}}/>
                        <li style={{marginBottom: "60px"}}>
                            <JourneyBuilderItems
                                title={"Discovery"}
                                desc={"Choose the interests you want ask you shoppers"}
                                account={account}
                                isDelivery={false}
                                ref={this.discoveryRef}
                            />

                        </li>
                        <hr className="widget-separator" style={{height: "0px"}}/>
                        <li>
                            <JourneyBuilderItems
                                title={"Delivery"}
                                desc={"Choose the best journey for your customers"}
                                account={account}
                                isDelivery={true}
                                ref={this.deliveryRef}
                                campaign={this.state.campaign}
                            />

                        </li>
                    </ol>
                </div>

                <div className="wizard-options">
                    <ol className="form-elements">
                        <li className="campaign-subject">
                            <TextField
                                name="scenarioName"
                                label="Campaign Name"
                                className="item-stacked one-whole label-emphasise"
                                value={this.state.scenarioName}
                                required={true}
                                onChange={this.onScenarioNameChange}
                            />
                        </li>
                        <li>
                            <WizardDateSelection
                                ref="wizardDateSelection"
                                campaign={this.state.campaign}
                            />
                        </li>
                        <li>
                            <WizardMediumSelection
                                ref="wizardMediumSelection"
                                campaign={this.state.campaign}
                            />
                        </li>
                        {this.state.campaign.inputs &&
                        <li>
                            <WizardPageType
                                ref="wizardPageType"
                                campaign={this.state.campaign}
                            />

                        </li>
                        }

                        <li className="buttons">
                            <a
                                className="button secondary-action one-whole"
                                onClick={this.showAdvancedConfig}
                            >
                                {t("Advanced Configuration")}
                            </a>
                            <a
                                className={classNames("button gotest-action one-whole", {
                                    "gotest-action--disabled":
                                        this.state.isSubmitting &&
                                        this.state.submittingButton === "test"
                                })}
                                onClick={this.validate.bind(null, "test")}
                            >
                                {t("Go Test")}
                                {this.state.isSubmitting &&
                                this.state.submittingButton === "test" && (
                                    <span className="page-progress page-progress--button"/>
                                )}
                            </a>
                            <a
                                className={classNames(
                                    "button tertiary-action one-whole golive-action",
                                    {
                                        "golive-action--disabled":
                                            this.state.isSubmitting &&
                                            this.state.submittingButton === "live"
                                    }
                                )}
                                onClick={this.validate.bind(null, "live")}
                            >
                                {t("Go Live")}
                                {this.state.isSubmitting &&
                                this.state.submittingButton === "live" && (
                                    <span className="page-progress page-progress--button"/>
                                )}
                            </a></li>
                    </ol>
                </div>

                <WizardAdvancedConfig
                    ref="wizardAdvancedConfig"
                    show={this.state.showAdvancedConfig}
                    onHide={this.hideAdvancedConfig}
                    campaignType={"jbTrigger"}
                    isJourneyBuilder={true}
                    campaignSpecifics={this}
                    campaign={this.state.campaign}
                />
            </form>
        );
    }
}

const mapStatesToProps = store => ({
    validate: store.ui.validate,
    breadcrumb: store.ui.breadcrumb,
    formSaved: store.ui.formSaved,
    user: store.user.user,
    switchedUser: store.switchedUser.switchedUser,
    discovery: store.jbWizard.discovery,
    delivery: store.jbWizard.delivery,
    isCategoryTree: store.jbWizard.isCategoryTree
});

JourneyBuilderEdit.contextTypes = {
    router: PropTypes.object.isRequired
};

JourneyBuilderEdit.propTypes = {
    validate: PropTypes.bool
};

export default connect(mapStatesToProps)(JourneyBuilderEdit);
