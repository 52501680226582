export const filterOptions = [
  {
    value: 'dailyRock',
    label: 'Daily Rock',
  },
  {
    value: 'dailyViewCounter',
    label: 'Daily View Counter',
  },
  {
    value: 'dailyViewTimes',
    label: 'Daily View Times',
  },
  {
    value: 'productId',
    label: 'Product ID',
  },
  {
    value: 'name',
    label: 'Product Name',
  },
  {
    value: 'url',
    label: 'Product Url',
  },
  {
    value: 'mUrl',
    label: 'Product Mobile Url',
  },
  {
    value: 'image',
    label: 'Product Image',
  },
  {
    value: 'imageS',
    label: 'Product Small Image',
  },
  {
    value: 'price',
    label: 'Product Price',
  },
  {
    value: 'category',
    label: 'Product Category Tree',
  },
  {
    value: 'lastUpdateTime',
    label: 'Last Update Time',
  },
  {
    value: 'inStock',
    label: 'Stock Status',
  },
  {
    value: 'insertTime',
    label: 'Insert Time',
  },
  {
    value: 'publishTime',
    label: 'Publish Time',
  },
  {
    value: 'brand',
    label: 'Brand',
  },
  {
    value: 'labels',
    label: 'Labels',
  },
  {
    value: 'params',
    label: 'Params',
  },
  {
    value: 'groupId',
    label: 'Group ID',
  },
  {
    value: 'scoreCount',
    label: 'Score Count',
  },
  {
    value: 'reviewCount',
    label: 'Review Count',
  },
  {
    value: 'monthlyPriceTimes',
    label: 'Monthly Price Times',
  },
  {
    value: 'monthlyRock',
    label: 'Monthly Rock',
  },
  {
    value: 'monthlyViewCounter',
    label: 'Monthly View Counter',
  },
  {
    value: 'monthlyViewTimes',
    label: 'Monthly View Times',
  },
  {
    value: 'weeklyRock',
    label: 'Weekly Rock',
  },
  {
    value: 'yearlyMaxPrices',
    label: 'Yearly Max Prices',
  },
  {
    value: 'yearlyMinPrices',
    label: 'Yearly Min Prices',
  },
  {
    value: 'yearlyPriceTimes',
    label: 'Yearly Price Times',
  },
];
