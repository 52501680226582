import Services from '../../../service/Services';

class EmailBuilderServices extends Services {
  constructor({ endPoint, paginationIsActive }) {
    super({ endPoint, paginationIsActive });
  }
}

const EmailBuilder = new EmailBuilderServices({
  endPoint: 'emails/custom-template',
  paginationIsActive: false,
});

const EmailMergeTag = new EmailBuilderServices({
  endPoint: 'emails/merge-tags',
  paginationIsActive: false,
});

export { EmailBuilder, EmailMergeTag };
