import React from 'react';

const SEGMENTIFY_CHROME_EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/segmentify/dhcffckfimabfhbbjdngnajhnlohfjeo';

export const TestModeAdd = () => (
  <div>
    Recommendation widget is activated in test mode and only test users can see.
    You can check the widget by activating <strong>test mode</strong> in your
    browser
    <a href={SEGMENTIFY_CHROME_EXTENSION_URL} target='_blank' rel='noreferrer'>
      Chrome Extension
    </a>
    .
  </div>
);

export const TestModeUpdate = () => (
  <div>
    Recommendation widget is updated in <strong>test mode.</strong>
    <a href={SEGMENTIFY_CHROME_EXTENSION_URL} target='_blank' rel='noreferrer'>
      Chrome Extension
    </a>
    .
  </div>
);

export const TestModeAddPersonalization = () => (
  <div>
    Personalization campaign is activated in test mode and only test users can
    see. You can check the campaign by activating <strong>test mode</strong> in
    your browser
    <a href={SEGMENTIFY_CHROME_EXTENSION_URL} target='_blank' rel='noreferrer'>
      Chrome Extension
    </a>
    .
  </div>
);

export const TestModeUpdatePersonalization = () => (
  <div>
    Personalization campaign is activated in test mode and only test users can
    see. You can check the campaign by activating <strong>test mode</strong> in
    your browser
    <a href={SEGMENTIFY_CHROME_EXTENSION_URL} target='_blank' rel='noreferrer'>
      Chrome Extension
    </a>
    .
  </div>
);

export const LiveModeUpdate = () => (
  <div>
    Recommendation widget is updated in <strong>live mode</strong>.
  </div>
);

export const LiveModeAdd = () => (
  <div>
    Recommendation widget is activated in <strong>live mode</strong>.
  </div>
);

export const LiveModeUpdatePersonalization = () => (
  <div>
    Personalization campaign is updated in <strong>live mode</strong>.
  </div>
);

export const LiveModeAddPersonalization = () => (
  <div>
    Personalization campaign is activated in <strong>live mode</strong>.
  </div>
);

export const UserUpdate = () => {
  return <div>User settings are updated successfully.</div>;
};

export const AccountUpdate = () => (
  <div>Account settings are updated successfully.</div>
);

export const CustomerRemoval = () => (
  <div>Your request is successfully submitted.</div>
);

export const CampaignReverted = () => (
  <div>Campaign Reverted successfully.</div>
);

export const TemplateReverted = () => (
  <div>Template Reverted successfully.</div>
);

export default {
  TestModeAdd,
  TestModeUpdate,
  TestModeAddPersonalization,
  TestModeUpdatePersonalization,
  LiveModeUpdate,
  LiveModeAdd,
  UserUpdate,
  AccountUpdate,
  LiveModeUpdatePersonalization,
  LiveModeAddPersonalization,
  CampaignReverted,
  TemplateReverted,
};
