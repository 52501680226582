import { t } from '../../../../system/ui';

const breadCrumbOptions = {
  home: {
    name: t('Recommend'),
    icon: 'heart',
    slug: '/recommendations',
  },
  current: {
    name: 'Promotion List',
  },
};

const getDynamicBreadCrumbOptions = currentName => {
  return {
    home: {
      name: t('Recommend'),
      icon: 'heart',
      slug: '/recommendations',
      iconSvg: true,
    },
    top: {
      name: t('Promotion List'),
      slug: '/recommendations/promotion/list',
    },
    current: {
      name: currentName,
    },
  };
};

const relatedProductAttributes = [
  {
    name: 'mappedProducts',
    label: t('Products'),
    component: 'RPProducts',
    icon: 'plus',
  },
  {
    name: 'categories',
    label: t('Categories'),
    component: 'RPCategories',
    icon: 'plus',
  },
  {
    name: 'brands',
    label: t('Brands'),
    component: 'RPBrands',
    icon: 'plus',
  },
  {
    name: 'labels',
    label: t('Labels'),
    component: 'RPLabels',
    icon: 'plus',
  },
];

const promotionHeadConstant = [
  { headerTitle: 'Image', isVisible: true, size: 'sm', key: '1' },
  { headerTitle: 'Title', isVisible: true, size: 'l text-left', key: '2' },
  {
    headerTitle: 'Code',
    isVisible: true,
    size: 'm border-both text-center',
    key: '3',
  },
  {
    headerTitle: 'Status',
    isVisible: true,
    size: 'sm border-right text-center',
    key: '4',
  },
  { headerTitle: 'Actions', isVisible: true, size: 'm text-center', key: '5' },
];
const campaignActionButtonConstant = [
  { btnLabel: 'Save All', type: 'save', position: 'flex-bottom', key: '1' },
];
export {
  promotionHeadConstant,
  breadCrumbOptions,
  getDynamicBreadCrumbOptions,
  relatedProductAttributes,
  campaignActionButtonConstant,
};
