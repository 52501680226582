import React from 'react';
import { t } from '../../../system/ui';
import { Select } from '../../fields';
import classes from './SFYScheduling.module.scss';
import Tooltip from '../../tooltip';

const SelectTime = ({
  editCampaign,
  activeEmailDetails,
  componentDetail,
  options,
  isTitleVisible = true,
  selectClass = '',
  fieldValue = '',
  error = '',
  readOnly = false,
}) => {
  const getActiveEmailDetails = () => {
    return activeEmailDetails[componentDetail.objectName]?.[0]
      ? activeEmailDetails[componentDetail.objectName][0][
          componentDetail.cardName
        ]
      : activeEmailDetails[componentDetail.objectName][
          componentDetail.cardName
        ];
  };

  return (
    <label
      className={`${classes['sfy-scheduling-repeat']} ${classes[selectClass]}`}
    >
      {isTitleVisible && (
        <span className={classes['sfy-label-wrapper']}>
          <span className={classes['sfy-label']}>
            {t(componentDetail.cardTitle)}
          </span>
          {componentDetail.tooltipText && componentDetail.tooltipText !== '' && (
            <Tooltip
              content={t(componentDetail.tooltipText)}
              alignment='top'
              placement='right'
              newTooltip
              isInline
            >
              <i className='icon-info' role='presentation'>
                <span className='for-screenreader-only' />
              </i>
            </Tooltip>
          )}
        </span>
      )}
      <Select
        className={`${classes['sfy-scheduling-repeat']} ${classes['sfy-select']} ${classes['sfy-border']} ${classes[selectClass]}`}
        name='timing'
        value={
          componentDetail.cardName === 'campaign' ||
          componentDetail.cardName === 'date' ||
          componentDetail.cardName === 'action' ||
          componentDetail.cardName === 'period'
            ? fieldValue
            : getActiveEmailDetails()
        }
        options={options}
        searchable={componentDetail.searchable}
        onChange={e => editCampaign(componentDetail.type, e.value)}
        clearable={false}
        disabled={readOnly}
      />
      {error !== '' && <span className='item-error'>{t(error)}</span>}
    </label>
  );
};

export default SelectTime;
