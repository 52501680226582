import React from 'react';
import { browserHistory } from 'react-router';

import Icon from './icon';
import Icons from './icons';
import Tooltip from './tooltip';
import {
  isSuperUser,
  hasModule,
  hasGoogleAnalyticsId,
} from '../modules/auth/user';

class DashboardDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onChangeModule = this.onChangeModule.bind(this);
  }

  onClick(dashboardType, e) {
    e.preventDefault();
    if (this.props.router.location.pathname.indexOf('/ext') === -1) {
      browserHistory.push(`/dashboard/${dashboardType}`);
    } else {
      browserHistory.push(`/ext/dashboard/${dashboardType}`);
    }

    if (dashboardType === 'grid') {
      if (this.props.router.location.pathname.indexOf('/ext') === -1) {
        browserHistory.push('/');
      } else {
        browserHistory.push('/ext');
      }
    }
  }

  onChangeModule(module, e) {
    e.preventDefault();
    this.props.onModuleChange(module);
  }

  render() {
    const options = this.props.noModule
      ? [
          {
            name: 'Grid View',
            icon: 'dashboard',
            svg: true,
            value: 'grid',
          },
        ]
      : [
          {
            name: 'Grid View',
            icon: 'dashboard',
            svg: true,
            value: 'grid',
          },
          {
            name: 'Graphical View',
            icon: 'graph',
            svg: true,
            value: 'graph',
          },
          {
            name: 'Funnel View',
            icon: 'funnel',
            svg: true,
            value: 'funnel',
          },
          {
            name: 'Key Metrics',
            icon: 'metrics',
            svg: true,
            value: 'key_metrics',
            adminOnly: true,
            modules: ['recommendation'],
          },
          {
            name: 'Analytics',
            icon: 'analytics',
            svg: true,
            value: 'analytics',
          },
        ];
    const getCurrent = () => {
      let current;
      options.forEach(option => {
        if (option.value === this.props.type) {
          current = option;
        }
      });
      return current || options[0];
    };
    const current = getCurrent();
    return (
      <div className='dashboard-select'>
        {current.value === 'analytics' && (
          <div className='dashboard-type-module'>
            <h2 style={{ color: '#cc4331' }}>Segmentify vs Google Analytics 4</h2>
          </div>
        )}

        {!this.props.hideDropdown && (
          <div className='dashboard-type-module'>
            {this.props.activeModules.map(module => {
              if (module === 'E-Mail') {
                if (hasModule('email')) {
                  return (
                    <a
                      href='#'
                      className={
                        module === this.props.selectedModule
                          ? 'dashboard-type-module-item active'
                          : 'dashboard-type-module-item'
                      }
                      onClick={this.onChangeModule.bind(this, module)}
                      key={module}
                    >
                      {module === 'E-Mail' ? 'Email Marketing' : module}
                    </a>
                  );
                }
                return null;
              }
              if (module === 'Push') {
                if (hasModule('push')) {
                  return (
                    <a
                      href='#'
                      className={
                        module === this.props.selectedModule
                          ? 'dashboard-type-module-item active'
                          : 'dashboard-type-module-item'
                      }
                      onClick={this.onChangeModule.bind(this, module)}
                      key={module}
                    >
                      {module === 'Push' ? 'Web Push' : module}
                    </a>
                  );
                }
                return null;
              }
              return (
                <a
                  key={module}
                  href='#'
                  className={
                    module === this.props.selectedModule
                      ? 'dashboard-type-module-item active'
                      : 'dashboard-type-module-item'
                  }
                  onClick={this.onChangeModule.bind(this, module)}
                >
                  {module === 'Search' ? 'Search & Discovery' : module}
                </a>
              );
            })}
          </div>
        )}
        <div className='dashboard-type-view'>
          {options.map(option => {
            if (option.adminOnly && !isSuperUser()) {
              return '';
            }
            if (option.value === 'analytics' && !hasGoogleAnalyticsId()) {
              return null;
            }

            if (option.modules && option.modules.length) {
              if (!hasModule('recommendation')) {
                return null;
              }
            }
            return (
              <a
                href='#'
                onClick={this.onClick.bind(this, option.value)}
                className={
                  this.props.type === option.value ? 'view-selected' : ''
                }
                key={option.value}
              >
                <span style={{ color: '#676769' }}>
                  <Tooltip content={option.name} newTooltip alignment='top'>
                    {option.svg ? (
                      <Icons name={option.icon} color='#676769' />
                    ) : (
                      <Icon name={option.icon} />
                    )}
                  </Tooltip>
                </span>
              </a>
            );
          })}
        </div>
      </div>
    );
  }
}

export default DashboardDropdown;
