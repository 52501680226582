import React, { useState, useEffect } from 'react';
import './WofSliceCard.scss';
import { t } from '../../../system/ui';
import Icons from '../../icons';
import { ColorPicker, TextField } from '../../fields';
import { InputCardWrapper } from '../module-exports';

/**
 * @name WofSliceCard component
 * @description WofExpandableCardWrapper component
 * @param {JSX.Element | JSX.Element[]} children
 * @returns {JSX.Element}
 * @constructor
 */
const WofSliceCard = ({
  type = 'null',
  count,
  cardDetail,
  handleChange,
  handleDelete,
  isRemovable = true,
  isEqualToLimit = true,
  isReadOnly,
}) => {
  const [cardData, setCardData] = useState(cardDetail);

  useEffect(() => {
    setCardData(cardDetail);
  }, [cardDetail]);

  return (
    <>
      <div className='WofSliceCard' id={count}>
        <div className='WofSliceCard__header'>
          <h3 className='WofSliceCard__title'>
            {t('Slice')} {count + 1}
          </h3>
          {isRemovable && (
            <div
              className='WofSliceCard__remove'
              onClick={() => handleDelete(count)}
            >
              <div className='WofSliceCard__remove__icon'>
                <Icons name='trashBin' color='#CC4230' width='12' height='12' />
              </div>
              <span>{t('Remove')}</span>
            </div>
          )}
        </div>
        <div className='WofSliceCard__body'>
          <div className='WofSliceCard__left'>
            <InputCardWrapper
              type='simple'
              cardTitle={t('Content of Slice')}
              labelFor={`sliceContent${count}`}
              leftCharacter={32 - cardData.content.length}
              required
            >
              <TextField
                id={`sliceContent${count}`}
                type='text'
                name='content'
                required
                className='item-stacked one-whole WofSliceCard__left__text-color'
                placeholder={t('Ex : 25% discount')}
                value={cardData.content}
                onChange={e => handleChange('content', e.target.value, count)}
                maxLength={32}
              />
            </InputCardWrapper>
            <InputCardWrapper
              type='simple'
              cardTitle={t('Code of Coupon')}
              labelFor={`couponCode${count}`}
              required
              leftCharacter={
                type === 'GENERIC'
                  ? 12 - (cardData.couponCodes?.[0]?.length || 0)
                  : null
              }
            >
              {type === 'GENERIC' ? (
                <TextField
                  id={`couponCode${count}`}
                  type='text'
                  name='couponCodes'
                  required
                  className='item-stacked one-whole WofSliceCard__left__text-color'
                  placeholder='Ex:TECH20'
                  value={cardData.couponCodes?.[0] || ''}
                  onChange={e =>
                    handleChange('couponCodes', [e.target.value], count)
                  }
                  maxLength={12}
                />
              ) : (
                <div className='WofSliceCard__left--uploaded'>
                  <Icons name='tick' color='#06BE44' width='13' height='13' />
                  {t('Uploaded')}
                </div>
              )}
            </InputCardWrapper>
            <InputCardWrapper
              type='simple'
              cardTitle={t('Redirect URL of Coupon')}
              labelFor={`couponUrl${count}`}
              tooltipContent='couponUrl'
            >
              <TextField
                id={`couponUrl${count}`}
                type='text'
                name='couponUrl'
                className='item-stacked one-whole WofSliceCard__left__text-color'
                placeholder={t('Type here')}
                value={cardData.couponUrl}
                onChange={e => handleChange('couponUrl', e.target.value, count)}
              />
            </InputCardWrapper>
            <InputCardWrapper
              type='simple'
              cardTitle={t('Description of Wining Result')}
              labelFor={`descriptionWin${count}`}
            >
              <TextField
                id={`descriptionWin${count}`}
                type='text'
                name='description'
                className='item-stacked one-whole WofSliceCard__left__text-color'
                placeholder={t('Type here')}
                value={cardData.description}
                onChange={e =>
                  handleChange('description', e.target.value, count)
                }
              />
            </InputCardWrapper>
          </div>
          <div className='WofSliceCard__right'>
            <div className='WofSliceCard__possibility'>
              <div className='WofSliceCard__possibility--head'>
                <div
                  className='WofSliceCard__possibility--arc'
                  style={{
                    borderBottom: `100px solid ${cardDetail.color}`,
                  }}
                />
                <ColorPicker
                  name='color'
                  disableTooltip
                  className='item-field large'
                  type='withAlpha'
                  value={cardDetail.color}
                  onChange={value => handleChange('color', value, count)}
                />
              </div>
              <div className='WofSliceCard__possibility--bottom'>
                <label htmlFor={`possibility${count}`}>
                  {t('Possibility of win')}
                </label>
                <div className='WofSliceCard__possibility__input'>
                  <input
                    className={`${!isEqualToLimit ? 'has-error' : ''}`}
                    id={`possibility${count}`}
                    type='number'
                    min={0}
                    name='possibility'
                    placeholder={t('Ex: 25')}
                    value={cardData.possibility}
                    onChange={e => {
                      handleChange(
                        'possibility',
                        e.target.value === '' ? '' : parseFloat(e.target.value),
                        count,
                      );
                    }}
                    onBlur={e => {
                      handleChange(
                        'possibilityAlgorithm',
                        e.target.value === '' ? 0 : parseFloat(e.target.value),
                        count,
                      );
                    }}
                    readOnly={isReadOnly}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WofSliceCard;
