import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const WofCta = ({ width = '91px', height = '73px' }) => {
  const [state, setState] = useState({
    width: '',
    height: '',
  });

  const setStyles = () => {
    setState({
      width,
      height,
    });
  };

  useEffect(() => {
    setStyles();
  }, [width, height]);

  return (
    <svg
      width={state.width}
      height={state.height}
      viewBox='0 0 91 73'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='90'
        height='72'
        rx='9.5'
        fill='white'
        stroke='#D1D1D1'
      />
      <rect x='16' y='12' width='60' height='33' rx='3' fill='#F0F0F0' />
      <rect
        x='23'
        y='52.4366'
        width='45'
        height='11.3099'
        rx='5'
        fill='#CC4230'
      />
      <path
        d='M41.6542 60.662C41.1521 60.662 40.6978 60.5519 40.2912 60.3317C39.8895 60.1067 39.5739 59.8004 39.3443 59.4127C39.1148 59.0202 39 58.5798 39 58.0915C39 57.6033 39.1148 57.1653 39.3443 56.7776C39.5739 56.3851 39.8919 56.0788 40.2984 55.8586C40.7049 55.6336 41.1593 55.5211 41.6614 55.5211C42.0536 55.5211 42.4122 55.5881 42.7374 55.7222C43.0626 55.8514 43.34 56.0453 43.5696 56.3037L43.1033 56.7561C42.7255 56.3588 42.2544 56.1601 41.6901 56.1601C41.3171 56.1601 40.9799 56.2439 40.6786 56.4114C40.3773 56.579 40.1406 56.8111 39.9684 57.1079C39.8011 57.3999 39.7174 57.7278 39.7174 58.0915C39.7174 58.4553 39.8011 58.7856 39.9684 59.0824C40.1406 59.3744 40.3773 59.6041 40.6786 59.7717C40.9799 59.9392 41.3171 60.023 41.6901 60.023C42.2496 60.023 42.7207 59.8219 43.1033 59.4198L43.5696 59.8722C43.34 60.1307 43.0603 60.3269 42.7303 60.4609C42.4051 60.595 42.0464 60.662 41.6542 60.662Z'
        fill='white'
      />
      <path
        d='M45.534 56.2032H43.8124V55.5786H47.9659V56.2032H46.2442V60.6045H45.534V56.2032Z'
        fill='white'
      />
      <path
        d='M51.6944 59.348H49.0258L48.4735 60.6045H47.7346L50.0086 55.5786H50.7188L53 60.6045H52.2468L51.6944 59.348ZM51.4433 58.7736L50.3601 56.3109L49.2769 58.7736H51.4433Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.5 38.7711C50.6203 38.7711 54.7711 34.6203 54.7711 29.5C54.7711 24.3797 50.6203 20.2289 45.5 20.2289C40.3797 20.2289 36.2289 24.3797 36.2289 29.5C36.2289 34.6203 40.3797 38.7711 45.5 38.7711ZM45.5 39C50.7467 39 55 34.7467 55 29.5C55 24.2533 50.7467 20 45.5 20C40.2533 20 36 24.2533 36 29.5C36 34.7467 40.2533 39 45.5 39Z'
        fill='#7A7A7C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.5 32.7407C47.2898 32.7407 48.7407 31.2898 48.7407 29.5C48.7407 27.7102 47.2898 26.2593 45.5 26.2593C43.7102 26.2593 42.2593 27.7102 42.2593 29.5C42.2593 31.2898 43.7102 32.7407 45.5 32.7407ZM45.5 33C47.433 33 49 31.433 49 29.5C49 27.567 47.433 26 45.5 26C43.567 26 42 27.567 42 29.5C42 31.433 43.567 33 45.5 33Z'
        fill='#7A7A7C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.5 40.7745C51.7267 40.7745 56.7745 35.7267 56.7745 29.5C56.7745 23.2733 51.7267 18.2255 45.5 18.2255C39.2733 18.2255 34.2255 23.2733 34.2255 29.5C34.2255 35.7267 39.2733 40.7745 45.5 40.7745ZM45.5 41C51.8513 41 57 35.8513 57 29.5C57 23.1487 51.8513 18 45.5 18C39.1487 18 34 23.1487 34 29.5C34 35.8513 39.1487 41 45.5 41Z'
        fill='#7A7A7C'
      />
      <path d='M45.5 21L42 16L49 16L45.5 21Z' fill='#7A7A7C' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.8705 27L39 23.1586L39.1295 23L43 26.8414L42.8705 27Z'
        fill='#7A7A7C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M48 26.8414L51.8705 23L52 23.1586L48.1295 27L48 26.8414Z'
        fill='#7A7A7C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M51.86 36L48 32.14L48.14 32L52 35.86L51.86 36Z'
        fill='#7A7A7C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M39 35.8618L42.8547 32L43 32.1382L39.1453 36L39 35.8618Z'
        fill='#7A7A7C'
      />
    </svg>
  );
};

WofCta.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

WofCta.defaultProps = {
  width: '91px',
  height: '73px',
};

export default WofCta;
