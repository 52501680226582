/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable react/sort-comp */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FACETED_SEARCH_NAME } from 'constants/faceted-search';
import LoaderPlaceholder from '../components/widget/loader-placeholder';
import { uiActions } from '../actions';
import { t } from '../system/ui';
import Icons from '../components/icons';
import { config } from '../constants/config';
import { setTitle } from '../system/document';
import { hasModule } from '../modules/auth/user';
import { searchIntegrationInfo } from '../modules/trendify/page';
import SearchTypeSelection from '../components/search/search-campaign-selection';
import { getAllSearch } from '../modules/search/ajax';

import SearchsList from '../components/search/searchs-list';
import FacetedSearchListing from '../components/search/faceted-search/listing';
import { AllSearches, GlobalConfiguration, Recommendation } from './index';

const searchGroups = {
  searchbox: ['BEFORE_SEARCH', 'SEARCH'],
  faceted: [],
};

const campaignNames = {
  searchbox: t('Search Box Campaigns'),
  faceted: t(`${FACETED_SEARCH_NAME} Campaigns`),
};

class Searchs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      searchs: [],
      _searchs: [],
      liveSearches: [],
      testSearches: [],
      title: 'Live',
      status: 'ACTIVE',
      searchType: 'view-all',
      sortIcon: 'preferences',
      showOptions: false,
      scrollCount: 0,
      sort: 'DESC',
      toggleIcon: 'preferences',
      sortType: 'revenue',
    };

    this.sortBy = this.sortBy.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.setSearchType = this.setSearchType.bind(this);
    this.getSearchs = this.getSearchs.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.increaseScrollCount = this.increaseScrollCount.bind(this);
    this.getCampaignsAfterStatusChange = this.getCampaignsAfterStatusChange.bind(
      this,
    );
  }

  componentDidMount() {
    uiActions.hideBreadcrumb();
    if (document.location.pathname === '/search') {
      this.props.router.push({ pathname: '/search/view-all' });
    } else {
      this.setSearchType(
        document.location.pathname.split('/')[
          document.location.pathname.split('/').length - 1
        ],
      );
    }

    this.getSearchs();
    window.onscroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        this.increaseScrollCount();
      }
    };
  }

  getCampaignsAfterStatusChange() {
    this.componentDidMount();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.params.search === 'faceted') {
      setTitle(`Searchandising - ${t('Search Campaigns')}`);
    } else {
      const children =
        config.menus.primary.search.children[newProps.params.search];
      setTitle(`${children.name} - ${t('Search Campaigns')}`);
    }
    if (this.props.params.search !== newProps.params.search) {
      this.setSearchType(newProps.params.search);
    }

    if (
      this.props.user !== newProps.user ||
      this.props.location.pathname !== newProps.location.pathname
    ) {
      this.getSearchs();
    }
  }

  setSearchType(newType) {
    this.setState(
      {
        searchType: newType,
        campaignsInited: true,
      },
      () => {
        if (this.state.campaignsInited) {
          this.filterSearchs('statusOrMediumChange');
        }
      },
    );
  }

  getSearchs() {
    searchIntegrationInfo();
    if (hasModule('search')) {
      uiActions.isLoading();
      const searchCampaign = [];
      getAllSearch(response => {
        if (response) {
          if (response.hasOwnProperty('beforeSearch'))
            searchCampaign.push(response.beforeSearch);
          if (response.hasOwnProperty('search'))
            searchCampaign.push(response.search);
          Object.keys(response).map(key => {
            if (key.startsWith('fcs')) {
              searchCampaign.push(response[key]);
            }
          });
          this.setState(
            {
              _searchs: searchCampaign,
              searchs: searchCampaign,
              isLoading: false,
            },
            () => {
              this.filterSearchs();
              uiActions.isLoaded();
            },
          );
        }
      });
    }
  }

  increaseScrollCount() {
    if (this.state.searchType === 'view-all') {
      this.setState(
        {
          scrollCount: this.state.scrollCount + 1,
        },
        () => {
          this.filterSearchs('scroll', false, false);
        },
      );
    }
  }

  // eslint-disable-next-line no-unused-vars
  filterSearchs(source, eventParam, e) {
    const allSearchs = this.state._searchs;
    const { status } = this.state;
    const { searchType } = this.state;
    const isViewAll =
      !this.state.searchType || this.state.searchType === 'view-all';

    let filteredSearchs = allSearchs.filter(search => {
      return searchType !== 'view-all' ? true : search.status === status;
    });

    // Type Filter
    if (searchType !== 'view-all' && searchType !== undefined) {
      filteredSearchs = filteredSearchs.filter(search => {
        return searchGroups[searchType]?.indexOf(search.instanceId) > -1;
      });
    }

    if (this.state.searchType !== 'view-all') {
      this.setState({
        searchs: filteredSearchs,
        title: isViewAll ? t('All Campaigns') : t(campaignNames[searchType]),
      });
    }
  }

  onChangeStatus(instanceId, status, newStatus) {
    const newSearchs = [];
    this.state._searchs.forEach(search => {
      if (search.instanceId === instanceId) {
        search.status = status;
      }
      newSearchs.push(search);
    });
    this.setState(
      {
        _searchs: newSearchs,
        status: newStatus,
      },
      () => {
        this.filterSearchs('statusOrMediumChange');
      },
    );
  }

  onStatusChange(status) {
    this.setState(
      {
        status,
      },
      () => {
        this.filterSearchs('statusOrMediumChange');
      },
    );
  }

  onSearch(e) {
    e.preventDefault();
    this.filterSearchs('search');
  }

  sortBy(source, eventParam, e) {
    if (e) {
      e.preventDefault();
    }
  }

  toggleOptions(e) {
    e.preventDefault();

    this.setState({
      showOptions: !this.state.showOptions,
      toggleIcon: !this.state.showOptions ? 'cross' : 'preferences',
    });
  }

  render() {
    if (!hasModule('search')) {
      return (
        <div id='nhSsrch'>
          <div className='promo promo--centered email-discover-dashboard'>
            <Icons name='search' width='64' height='64' />
            <p className='promo-text'>{t('SearchPromo')}</p>
            <a
              href='https://segmentify.com/solution/search-discovery/'
              target='_blank'
              className='secondary-action'
              rel='noreferrer'
            >
              {t('Discover')}
            </a>
          </div>
        </div>
      );
    }
    if (this.state.isLoading) {
      return (
        <div id='hSLsrch'>
          <div style={{ marginTop: '80px' }}>
            <LoaderPlaceholder />
          </div>
        </div>
      );
    }
    if (this.state.searchType === 'global-configuration') {
      return <GlobalConfiguration location={this.props.location} />;
    }
    if (this.state.searchType !== 'view-all') {
      return (
        <div id='hSlTsrch'>
          <h2 className='page-title'>{t(this.state.title)}</h2>
          <div
            className={
              this.state.showConfigWarning
                ? 'page-content-wrapper prevent-add'
                : 'page-content-wrapper'
            }
          >
            {this.state.searchType === 'faceted' ? (
              <FacetedSearchListing />
            ) : (
              <div className='widget-list-wrapper'>
                {this.state.searchs.length > 0 ? (
                  <SearchsList
                    key={this.state.searchType}
                    searchs={this.state.searchs}
                    id='hSlsrch'
                    getSearchs={this.getSearchs}
                  />
                ) : (
                  !this.state.isLoading && (
                    <div className='widget-list-no-items'>
                      There is no {this.state.title}
                    </div>
                  )
                )}
              </div>
            )}

            {this.state.searchType !== 'faceted' && (
              <SearchTypeSelection
                searchType={this.state.searchType}
                searchGroups={searchGroups}
                campaignNames={campaignNames}
                liveSearches={this.state.searchs.map(campaign => {
                  if (campaign.status === 'ACTIVE') {
                    return campaign.instanceId;
                  }
                  return null;
                })}
                testSearches={this.state.searchs.map(campaign => {
                  if (campaign.status === 'TEST') {
                    return campaign.instanceId;
                  }
                  return null;
                })}
                allSearchs={this.state.searchs.map(campaign => {
                  return campaign.instanceId;
                })}
                searchCampaigns={this.state.searchs.filter(campaign => {
                  return campaign.instanceId === 'SEARCH';
                })}
              />
            )}
          </div>
        </div>
      );
    }
    return (
      <div id='hSltsrch'>
        {this.state.isLoading ? (
          <LoaderPlaceholder />
        ) : this.props.params.search === 'view-all' ||
          !this.props.params.search ? (
          <AllSearches
            {...this.props}
            campaigns={this.state.searchs}
            key='view-all'
            defaultStatus={this.state.defaultStatus}
            getCampaigns={this.getSearchs}
            getCampaignsAfterArchive={this.getCampaignsAfterArchive}
            getCampaignsAfterStatusChange={this.getCampaignsAfterStatusChange}
            placeHolder={<LoaderPlaceholder />}
          />
        ) : (
          <Recommendation
            {...this.props}
            campaigns={this.state.campaigns}
            key={this.props.params.recommendation}
          />
        )}
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  user: store.user.user,
  status: store.campaigns.status,
});

export default connect(MapStatesToProps)(Searchs);
