import { getRestEndPoint } from '../../../../system/api';
import { getApiKey } from '../../../../modules/auth/user';
import { $http } from '../../../../system/ajax';
import { isLoading } from '../../../../actions/ui';

export const fetchContactList = async () => {
  isLoading();
  const url = `${getRestEndPoint('contacts/list')}?apiKey=${getApiKey()}`;

  const response = await $http(url).get();
  return await response;
};

export const uploadContactList = async data => {
  isLoading();
  const { file, config } = data;
  const url = `${getRestEndPoint('contacts/upload')}?apiKey=${getApiKey()}`;
  const formData = new FormData();
  formData.append('file', file);
  if (config && config.length > 0) {
    config.forEach(item => {
      formData.append(item.name, item.value);
    });
  }
  const response = await $http(url).post(formData, {
    type: 'form',
  });
  return await response;
};

export const updateContactList = async data => {
  isLoading();
  const { file, config, id } = data;
  const url = `${getRestEndPoint(
    'contacts/update',
  )}/${id}?apiKey=${getApiKey()}`;
  const formData = new FormData();
  formData.append('file', file);
  if (config && config.length > 0) {
    config.forEach(item => {
      formData.append(item.name, item.value);
    });
  }
  const response = await $http(url).put(formData, {
    type: 'form',
  });
  return await response;
};

export const deleteContactList = async name => {
  isLoading();
  const url = `${getRestEndPoint(
    'contacts/delete',
  )}/${name}?apiKey=${getApiKey()}`;
  const response = await $http(url).delete();
  return await response;
};
