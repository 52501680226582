"use strict";

import React from "react";
import { connect } from "react-redux";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

import { wizardActions, uiActions } from "../../../actions";
import CriteriaIntelligent from "./search-inner-intelligent";
import CriteriaCategory from "./search-inner-category";
import CriteriaBrand from "./search-inner-brand";
import CriteriaKeyword from "./search-inner-keyword";

const criteriaTypes = {
  intelligent: CriteriaIntelligent,
  category: CriteriaCategory,
  brand: CriteriaBrand,
  keyword: CriteriaKeyword,
};

class SearchInnerCriteria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removedCriteria: [],
      allItems: [],
    };
    this.handleCriteriaRemove = this.handleCriteriaRemove.bind(this);
    this.setStaticProducts = this.setStaticProducts.bind(this);
    this.onDynamicSortEnd = this.onDynamicSortEnd.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.onSortMove = this.onSortMove.bind(this);
    this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
  }

  componentDidMount() {
    this.setStaticProducts(this.props);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.assetList !== newProps.assetList) {
      this.setStaticProducts(newProps);
    }
  }

  handleCriteriaRemove(criteriaId) {
    this.props.onCriteriaRemove(criteriaId);
    uiActions.formEdited();
  }

  handleCriteriaChange(fields, criteriaId, selectedCategory) {
    this.props.onCriteriaChange(fields, criteriaId, selectedCategory);
    uiActions.formEdited();
  }

  setStaticProducts(newProps) {
    let newItems = [];
    newProps.assetList.map((item) => {
      newItems.push(item);
    });
    this.setState({ allItems: newItems });
  }

  onDynamicSortEnd(options) {
    this.onSortEnd("dynamic", options);
  }

  onSortEnd(sortType, options) {
    let disabledEl = document.getElementsByClassName("draggable--disabled")[0];
    if (disabledEl && disabledEl.classList) {
      disabledEl.classList.remove("draggable--disabled");
    }
    let current = Object.assign([], this.props.assetList);
    if (sortType !== "all") {
      let staticItems = [];
      current.forEach(item => {
        if (item.type == "product") {
          staticItems.push(item);
        }
      });
      let oldIndex =options.oldIndex + staticItems.length;
      let newIndex =options.newIndex + staticItems.length;
      let element = current[oldIndex];
      current.splice(oldIndex, 1);
      current.splice(newIndex, 0, element);
    } else {
      let oldIndex = options.oldIndex;
      let newIndex = options.newIndex;
      let element = current[oldIndex];
      current.splice(oldIndex, 1);
      current.splice(newIndex, 0, element);
    }

    this.props.onHandleCriteria(current);

  }

  onSortMove(options, event) {
    let disabledEl = options.node.parentNode;
    if (disabledEl.classList.contains("criteria-list--static")) {
      disabledEl = document.getElementsByClassName("criteria-list--dynamic")[0];
    } else {
      disabledEl = document.getElementsByClassName("criteria-list--static")[0];
    }
    if (disabledEl && disabledEl.classList) {
      disabledEl.classList.add("draggable--disabled");
    }
  }

  render() {
    let dynamicItems = [];
    this.state.allItems.map((item) => {
      dynamicItems.push(item);
    });

    const SortableItem = SortableElement(({ item }) => {
      let Component = criteriaTypes[item.type];
      dynamicItems = dynamicItems.map((i) => {
        return i.id !==
          (this.state.removedCriteria[0] !== undefined
            ? this.state.removedCriteria[0].id
            : this.state.removedCriteria.id)
          ? i
          : "";
      });

      return (
        <div className="wizard-item-draggable wizard-item-cards-draggable">
          <Component
            key={item.id}
            mainType={this.props.type}
            onCriteriaRemove={this.handleCriteriaRemove}
            onCriteriaChange={this.handleCriteriaChange}
            {...item}
          />
        </div>
      );
    });

    const SortableList = SortableContainer(({ items }) => {
      let itemClass = "criteria-list ";
      if (items[0]) {
        itemClass += "criteria-list--dynamic";
      }
      return (
        <ol className={itemClass}>
          {items.map((value, index) => (
            <SortableItem key={"sira" + index} index={index} item={value} />
          ))}
        </ol>
      );
    });

    if (dynamicItems.length > 0) {
      return (
        <div className="wizard-items-container">
          {dynamicItems.length > 0 && (
            <SortableList
              items={dynamicItems}
              onSortEnd={this.onDynamicSortEnd}
              useDragHandle={true}
              helperClass="dragitem"
              onSortStart={this.onSortMove}
            />
          )}
        </div>
      );
    } else {
      return "";
    }
  }
}
const mapStatesToProps = (store) => ({
  criteria: store.searchWizard.criteria,
});

export default connect(mapStatesToProps)(SearchInnerCriteria);
