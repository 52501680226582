"use strict";

import { createAction } from "../system/store";

export const addMapping = mappings => {
  createAction("ADD_WIZARD_MAPPING", { mappings });
};

export const removeMapping = mappings => {
  createAction("REMOVE_WIZARD_MAPPING", { mappings });
};

export default {
  addMapping,
  removeMapping
};
