"use strict";

import { createReducer } from "../system/store";
import * as segmentActions from "../constants/actions/segment";

const initialState = {
  status: "ACTIVE"
};

export default createReducer(initialState, {
  [segmentActions.default.FILTER_SEGMENT_STATUS]: (state, payload) => {
    return Object.assign({}, state, {
      status: payload.status
    });
  }
});
