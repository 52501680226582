/**
 * @author Bilal Cinarli
 */

import React from "react";
import { Link } from "react-router";

import { config } from "../../constants/config";
import { clone } from "../../system/object";

import Navigation from "../navigation";

class ExternalHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: config.menus.external,
      showNav: false
    };

    this.setData = this.setData.bind(this);
  }

  componentDidMount() {
    let externalMenu = localStorage.getItem("externalMenu"),
      externalNav = localStorage.getItem("externalNav");

    if (externalNav === null || externalNav.toLowerCase() !== "off") {
      this.setData({
        showNav: true
      });
    }

    if (externalMenu !== null) {
      let menuItems = externalMenu.split(","),
        menu = {};

      menuItems.forEach(item => {
        let _item = item.toLowerCase().trim();

        menu[_item] = config.menus.external[_item];
      });

      this.setData({
        menu: menu
      });
    }
  }

  setData(data) {
    this.setState(data);
  }

  render() {
    if (this.state.showNav) {
      return (
        <header id="masthead" className="page-header">
          <Link to="/ext" className="page-logo">
            <span className="for-screenreader-only">Segmentify</span>
          </Link>
          <Navigation items={this.state.menu} id="external-nav" />
        </header>
      );
    } else {
      return <div style={{ marginTop: "-65px" }} />;
    }
  }
}

export default ExternalHeader;
