import React from 'react';
import { fixedPxValue } from './pcBigger';

const VerticalEllipsis = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 4 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 12C1.58333 12 1.22933 11.854 0.938 11.562C0.646 11.2707 0.5 10.9167 0.5 10.5C0.5 10.0833 0.646 9.72933 0.938 9.438C1.22933 9.146 1.58333 9 2 9C2.41667 9 2.77067 9.146 3.062 9.438C3.354 9.72933 3.5 10.0833 3.5 10.5C3.5 10.9167 3.354 11.2707 3.062 11.562C2.77067 11.854 2.41667 12 2 12ZM2 7.5C1.58333 7.5 1.22933 7.354 0.938 7.062C0.646 6.77067 0.5 6.41667 0.5 6C0.5 5.58333 0.646 5.22933 0.938 4.938C1.22933 4.646 1.58333 4.5 2 4.5C2.41667 4.5 2.77067 4.646 3.062 4.938C3.354 5.22933 3.5 5.58333 3.5 6C3.5 6.41667 3.354 6.77067 3.062 7.062C2.77067 7.354 2.41667 7.5 2 7.5ZM2 3C1.58333 3 1.22933 2.854 0.938 2.562C0.646 2.27067 0.5 1.91667 0.5 1.5C0.5 1.08333 0.646 0.729334 0.938 0.438C1.22933 0.146 1.58333 0 2 0C2.41667 0 2.77067 0.146 3.062 0.438C3.354 0.729334 3.5 1.08333 3.5 1.5C3.5 1.91667 3.354 2.27067 3.062 2.562C2.77067 2.854 2.41667 3 2 3Z'
        fill='#333333'
      />
    </svg>
  );
};

VerticalEllipsis.defaultProps = {
  width: '4px',
  height: '15px',
};

export default VerticalEllipsis;
