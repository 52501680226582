import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { uiActions } from '../../actions';

const SETTINGS_PATH = '/settings';
const ACCOUNT_PATH = '/settings/account';

const Settings = ({ children, location, router }) => {
  useEffect(() => {
    uiActions.resetPage();

    if (location.pathname === SETTINGS_PATH) {
      router.push({
        pathname: ACCOUNT_PATH,
      });
    }
  }, [location.pathname]);

  return <>{children}</>;
};

const mapStatesToProps = store => ({
  user: store.user.user,
});

export default connect(mapStatesToProps)(Settings);
