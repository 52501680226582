/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-use-before-define */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React, { useId } from 'react';
import Icons from 'components/icons';
import { getAccount } from 'modules/auth/user';
import classNames from 'classnames';
import TypoReplacementItem from './settings.search.typo.replitem';

export default class TypoReplacement extends React.Component {
  constructor(props) {
    super(props);
    const account = getAccount();
    this.lastElementRef = React.createRef();
    this.state = {
      selectedLanguage: account.mainLanguage,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.addTypoTriggered && !this.props.addTypoTriggered) {
      this.lastElementRef?.current.scrollIntoView();
    }
  }

  convertTypoForReplacementItems(campaign, language) {
    return (
      campaign.typoCorrection.typoReplaceMap?.[language]?.map(
        (typoReplace, index) => ({
          id: index,
          leftValue: {
            label: typoReplace.target,
            value: typoReplace.target,
          },
          rightValues:
            typoReplace.typos?.map(typo => ({
              label: typo,
              value: typo,
            })) ?? [],
        }),
      ) ?? []
    );
  }

  isSynonymExists(campaign) {
    const replaceMapExists =
      typeof campaign.typoCorrection.typoReplaceMap === 'object' &&
      campaign.typoCorrection.typoReplaceMap !== null;
    let synonymExists = false;
    if (replaceMapExists) {
      Object.keys(campaign.typoCorrection.typoReplaceMap).forEach(language => {
        if (
          Array.isArray(campaign.typoCorrection.typoReplaceMap[language]) &&
          campaign.typoCorrection.typoReplaceMap[language].length > 0
        ) {
          synonymExists = true;
        }
      });
    }
    return synonymExists;
  }

  getAccountLangs = () => {
    const account = getAccount();
    let langList =
      account.additionalLanguages &&
      account.additionalLanguages.indexOf(account.mainLanguage) > -1
        ? account.additionalLanguages
        : [account.mainLanguage].concat(account.additionalLanguages);
    langList = langList.map(lang => {
      return { value: lang, label: lang };
    });
    return langList;
  };

  selectLanguage = selectedLanguage => {
    this.setState({
      selectedLanguage,
    });
  };

  hasError(lang) {
    let isValid = true;

    const synonymItems = this.convertTypoForReplacementItems(
      this.props.campaign,
      lang,
    );

    synonymItems.forEach(onIterateSynonymInnerItem);

    function onIterateSynonymInnerItem(item) {
      isValid = isValid && item.leftValue.value && item.leftValue.value !== '';
      isValid =
        isValid &&
        item.rightValues.length > 0 &&
        item.rightValues.filter(onFilterRightValues).length === 0;

      function onFilterRightValues(rVal) {
        if (rVal && rVal.value && rVal.value !== '') {
          return false;
        }
        return true;
      }
    }

    return !isValid;
  }

  render() {
    const synonymItems = this.convertTypoForReplacementItems(
      this.props.campaign,
      this.state.selectedLanguage,
    );
    const langList = this.getAccountLangs();
    return (
      <div>
        <li className='item-title-field'>
          <div className='wizard-comp'>
            <div>
              <li className='wizard-criterion '>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <span className='criteria-type'>
                      <Icons name='check' color='#787775' bgColor='#fff' />
                    </span>
                    <span>Typo Corrections</span>
                  </div>
                  <button
                    type='button'
                    onClick={() =>
                      this.props.onAdd(this.state.selectedLanguage)
                    }
                  >
                    <div>
                      <label>Add Typo</label>
                    </div>
                  </button>
                </div>
                {this.isSynonymExists(this.props.campaign) &&
                  langList.length > 1 && (
                    <div
                      className='campaign-language-selection-extended c-both'
                      style={{ marginTop: '15px' }}
                    >
                      {langList.map((item, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => this.selectLanguage(item.label)}
                            className={classNames('language-box', {
                              'is-selected':
                                this.state.selectedLanguage === item.label,
                              'has-error': this.hasError(item.label),
                            })}
                          >
                            {item.label}
                          </span>
                        );
                      })}
                    </div>
                  )}
                {synonymItems.length === 0 && (
                  <p className='wizard-comp-description search__m_t_20'>
                    Please provide different typos for a target keyword
                  </p>
                )}
                {synonymItems.map((synonymItem, index) => {
                  let props = {
                    className:
                      'wizard-criterion wizard-item-draggable wizard-item-cards-draggable',
                  };
                  index === synonymItems.length - 1 &&
                    (props = { ...props, ref: this.lastElementRef });
                  return (
                    <li
                      key={useId}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        gap: '10px',
                        marginTop: '1rem',
                      }}
                      {...props}
                    >
                      <TypoReplacementItem
                        key={synonymItem.id}
                        id={synonymItem.id}
                        index={synonymItem.id}
                        synonymItem={synonymItem}
                        synonymItems={synonymItems}
                        operator='SEARCH_SYNONYMS_REPLACE'
                        selectedLanguage={this.state.selectedLanguage}
                        onRemove={this.props.onRemove}
                        onChange={this.props.onChange}
                      />
                    </li>
                  );
                })}
              </li>
            </div>
          </div>
        </li>
      </div>
    );
  }
}
