import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { operatorObjToArray } from './utils/utils';

import {
  pageOperators,
  browserOperators,
  operatorColumns,
} from '../../constants/datamaps/rule-based-segmentation';
import { ErrorText } from './module-exports';

/**
 * @name CriteriaFilterBrowser
 * @description Component that renders the browser filter
 * @param {object} criteriaData - The criteria data
 * @param {string} filterType - The filter type
 * @param {array} statements - The statements
 * @param {function} callbackFunc - The callback function
 * @returns {JSX.Element}
 */

const criteriaFilterBrowser = ({
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const defaultFilterData = {
    dataType: criteriaData?.dataType,
    column: operatorColumns.browserOperator,
    operator: pageOperators.equals.value,
    value: '',
  };

  const [browser, setBrowser] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState('');

  useEffect(() => {
    const tempStatements = statements?.[filterType];

    if (tempStatements?.length === 1) {
      setBrowser(tempStatements[0].value);
      setSelectedOperator(tempStatements[0].operator);
    } else {
      setSelectedOperator(defaultFilterData.operator);
      setBrowser(defaultFilterData.value);
    }
  }, [statements]);

  const triggerCallbackFunc = (val, type) => {
    const columnVal = operatorColumns.browserOperator;
    const operatorVal = type === 'operator' ? val.value : selectedOperator;
    const browserVal = type === 'browser' ? val.value : browser;

    const filterData = [
      {
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator: operatorVal,
        value: browserVal,
      },
    ];

    callbackFunc(filterType, filterData);
  };

  const onChange = (selectedValue, type) => {
    switch (type) {
      case 'browser':
        setBrowser(selectedValue?.value);
        break;
      case 'operator':
        setSelectedOperator(selectedValue?.value);
        break;
      default:
        break;
    }

    triggerCallbackFunc(selectedValue, type);
  };

  return (
    <div className='rbs-filter-field'>
      <div className='filter-operator'>
        <span className='filter-operator-title'>Operator*</span>
        <Select
          value={selectedOperator}
          options={operatorObjToArray(pageOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          onChange={e => onChange(e, 'operator')}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      <div className='filter-connector' />
      <div className='filter-operator'>
        <span className='filter-operator-title'>Browser*</span>
        <Select
          value={browser}
          placeholder={browser || 'Select a browser'}
          options={operatorObjToArray(browserOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          className={`criteria-field one-whole ${!browser && 'has-error'}`}
          onChange={e => onChange(e, 'browser')}
          openOnClick
        />
        {!browser && <ErrorText el='block' />}
      </div>
    </div>
  );
};

export default criteriaFilterBrowser;
