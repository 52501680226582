/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Select } from '../../../../components/fields';
import Icons from '../../../../components/icons';
import { getAllSegment } from '../../../segmentation/ajax';

import { t } from '../../../../system/ui';

const options = [
  { label: t('Visitor is Member'), value: true },
  { label: t('Visitor is not a Member'), value: false },
];

const isEngagement = type => {
  let flag = false;
  switch (type) {
    case 'POPUP_BANNER':
      flag = true;
      break;
    case 'PERSONA_QUIZ':
      flag = true;
      break;
    case 'SEE_ALL':
      flag = true;
      break;
    case 'NEWSLETTER':
      flag = true;
      break;
    case 'FORM':
      flag = true;
      break;
    case 'NOTIFICATION_BAR':
      flag = true;
      break;
    case 'HERO_BANNER':
      flag = true;
      break;
    default:
      flag = false;
      break;
  }
  return flag;
};

const isRecommendation = type => {
  let flag = false;
  switch (type) {
    case 'JOURNEY_BUILDER':
      flag = true;
      break;
    case 'RECOMMENDATION':
      flag = true;
      break;
    case 'POPUP_RECOMMENDATION':
      flag = true;
      break;
    case 'PROMOTION':
      flag = true;
      break;
    default:
      flag = false;
      break;
  }
  return flag;
};

class AudienceSegment extends Component {
  constructor(props) {
    super(props);

    this.onSelectChange = this.onSelectChange.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.remove = this.remove.bind(this);
    this.getSegmentsWithSegmentation = this.getSegmentsWithSegmentation.bind(
      this,
    );
    this.state = {
      userSegments: [],
      segment: this.props.value || '',
    };
  }

  componentDidMount() {
    this.getSegmentsWithSegmentation();
  }

  onSelectChange(selectedValue) {
    this.props.onSelectChange(selectedValue.value, this.props.id);
  }

  onFieldChange(selectedValue) {
    if (selectedValue) {
      this.setState({ segment: selectedValue.value }, () => {
        this.props.onFieldChange(selectedValue, this.props.id);
      });
    }
  }

  getSegmentsWithSegmentation() {
    getAllSegment(response => {
      if (response) {
        let userSegments = [];
        if (isRecommendation(this.props.campaignType)) {
          // Listing only smart and event based (audience[RBS]) segments
          response.forEach(segmentItem => {
            if (segmentItem.segment.status === 'ACTIVE') {
              if (
                segmentItem.segment.type === 'SMART' ||
                segmentItem.segment.type === 'RBS' ||
                segmentItem.segment.type === 'DYNAMIC' ||
                segmentItem.segment.type === 'EVENT_BASED'
              ) {
                userSegments.push({
                  value: segmentItem.segment.name,
                  label: segmentItem.segment.name,
                  id: segmentItem.segment.id,
                  type: segmentItem.segment.type,
                });
              }
            }
          });
          // sorting smart segments
          userSegments = userSegments.sort((a, b) =>
            a.name === 'Lost Customers' ||
            (b.name !== 'Lost Customers' && a.id >= b.id)
              ? 1
              : -1,
          );
        } else if (isEngagement(this.props.campaignType)) {
          response.forEach(segmentItem => {
            if (segmentItem.segment.status === 'ACTIVE') {
              userSegments.push({
                value: segmentItem.segment.name,
                label: segmentItem.segment.name,
                type: segmentItem.segment.type,
                id: segmentItem.segment.id,
              });
            }
          });
        } else {
          response.forEach(segmentItem => {
            if (
              segmentItem.segment.status === 'ACTIVE' &&
              segmentItem.segment.type === 'EVENT_BASED'
            ) {
              userSegments.push({
                value: segmentItem.segment.name,
                label: segmentItem.segment.name,
                type: segmentItem.segment.type,
                id: segmentItem.segment.id,
              });
            }
          });
        }

        if (this.props.value) {
          const isExistValue = userSegments.some(item => {
            return this.props.value === item.name;
          });
          if (!isExistValue) {
            userSegments.push({
              value: this.props.value,
              label: this.props.value,
            });
          }
        }
        this.setState({ userSegments });
      }
    });
  }

  remove() {
    this.props.onRemove(this.props.id);
  }

  render() {
    return (
      <li>
        <Select
          className='item-field x-large'
          value={this.props.member}
          options={options}
          onChange={this.onSelectChange}
        />
        <Select
          value={this.state.segment}
          options={this.state.userSegments}
          name='criterion'
          clearable={false}
          onChange={this.onFieldChange}
          className='item-field x-large segment-select'
          searchable
          shouldKeyDownEventCreateNewOption={e => {
            switch (e.keyCode) {
              case 9:
              case 13:
                return !0;
              default:
                return !1;
            }
          }}
        />
        <a onClick={this.remove} className='cancel-action helper-action'>
          <Icons name='cross' />
        </a>
      </li>
    );
  }
}

export default AudienceSegment;
