/**
 * @author Bilal Cinarli
 * Action constants for old campaign model
 */

"use strict";

import { createConstants } from "../../system/store";

export default createConstants(
  "IS_UPDATED",
  "UPDATE_LIST",
  "DUPLICATE_CAMPAIGN",
  "ADD_CAMPAIGN_REVENUE",
  "FILTER_CAMPAIGN_STATUS",
  "FILTER_CAMPAIGN_MEDIUM"
);
