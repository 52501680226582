import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { decodeHtml } from '../../system/string';
import { operatorObjToArray } from './utils/utils';
import { t } from '../../system/ui';

import {
  pageOperators,
  operatorColumns,
} from '../../constants/datamaps/rule-based-segmentation';
import { getRbsPageCategories } from '../../modules/page/ajax';
import { ErrorText } from './module-exports';

const defaultSelectData = {
  value: 'any',
  label: 'any page',
  title: 'any page',
};
/**
 * @name CriteriaFilterPage
 * @description Component to filter page
 * @param {object} criteriaData - The criteria data
 * @param {string} filterType - The filter type
 * @param {array} statements - The statements
 * @param {function} callbackFunc - The callback function
 * @returns {JSX.Element}
 */
const criteriaFilterPage = ({
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const defaultFilterData = {
    dataType: criteriaData?.dataType,
    column: operatorColumns.pageOperator,
    operator: pageOperators.equals.value,
    value: 'any',
  };

  const [selectedPage, setSelectedPage] = useState('');
  const [pages, setPages] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState('');
  const [isCustomText, setIsCustomText] = useState();

  if (statements?.[filterType].length === 0) {
    callbackFunc(filterType, [defaultFilterData]);
  }

  useEffect(() => {
    const statement = statements?.[filterType]?.[0];

    setSelectedOperator(statement?.operator || defaultFilterData.operator);
    const defaultPage = statement ? statement?.value : defaultFilterData.value;
    setSelectedPage(defaultPage);

    const isDefaultPageExistInPages = pages.some(
      item => item.value === defaultPage,
    );

    if (
      (!isDefaultPageExistInPages && defaultPage !== 'any') ||
      pages.length === 0
    ) {
      getRbsPageCategories().then(response => {
        const isDefaultPageExist = [
          defaultFilterData.value,
          ...response,
        ].includes(defaultPage);
        const responseWithDefaultPage =
          !isDefaultPageExist && defaultPage
            ? [...response, defaultPage]
            : response;
        const sorted = responseWithDefaultPage.sort();
        const sortedPages = sorted.map(item => {
          return {
            value: item,
            label: decodeHtml(item),
            title: decodeHtml(item),
          };
        });
        setPages([defaultSelectData, ...sortedPages]);
      });
    }
  }, [statements]);

  const triggerCallbackFunc = (val, type) => {
    const columnVal = operatorColumns.pageOperator;
    const operatorVal = type === 'operator' ? val?.value : selectedOperator;
    let pageVal = type === 'page' ? val?.value : selectedPage;

    if (
      [pageOperators.equals.value, pageOperators.equalsNot.value].includes(
        operatorVal,
      ) &&
      isCustomText
    ) {
      pageVal = '';
    }

    const filterData = [
      {
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator: operatorVal,
        value: pageVal,
      },
    ];

    callbackFunc(filterType, filterData);
  };

  const onChange = (selectedValue, type) => {
    switch (type) {
      case 'page':
        setSelectedPage(selectedValue?.value || '');
        // eslint-disable-next-line no-prototype-builtins
        setIsCustomText(!selectedValue?.hasOwnProperty('title'));
        break;
      case 'operator':
        setSelectedOperator(selectedValue?.value);
        break;
      default:
        break;
    }

    triggerCallbackFunc(selectedValue, type);
  };

  return (
    <div className='rbs-filter-field'>
      <div className='filter-operator'>
        <span className='filter-operator-title'>Operator*</span>
        <Select
          value={selectedOperator}
          options={operatorObjToArray(pageOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          onChange={e => onChange(e, 'operator')}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      <div className='filter-connector' />
      <div className='filter-operator'>
        <span className='filter-operator-title'>Page*</span>
        <Select
          value={selectedPage}
          options={pages}
          name='page'
          clearable={false}
          searchable
          onChange={e => onChange(e, 'page')}
          className={`criteria-field one-whole ${
            selectedPage ? '' : 'has-error'
          }`}
          openOnClick
          placeholder={t('Select or text')}
        />
        {!selectedPage && <ErrorText />}
      </div>
    </div>
  );
};

export default criteriaFilterPage;
