/**
 * @author Bilal Cinarli
 */

'use strict';

import { createReducer } from '../system/store';
import * as wizardActions from '../constants/actions/wizard';

const initialState = {
  criteria: [],
  excludes: [],
  includes: [],
  formFields: {
    version: '',
    fields: [],
  },
  wizardForm: {
    name: '',
    betweenFirst: 10,
    betweenSecond: 21,
    lifeTime: 72,
    language: 'SESSION_BASED',
    currency: 'SESSION_BASED',
    devices: [],
  },
  html: '',
  prejs: '',
  postjs: '',
  css: '',
  isStylesVisible: true,
  isPositionVisible: true,
  isTimingVisible: true,
  isAudienceVisible: true,
  isSchedulingVisible: true,
  isAdvancedConfigVisible: false,
  editForm: false,
  save: false,
  noResultCriteria: [],
  noResultExcludes: [],
  noResultIncludes: [],
};

const transformCriteria = criterias => {
  const grouped = criterias.reduce(
    (h, a) => Object.assign(h, { [a.type]: (h[a.type] || []).concat(a) }),
    {},
  );
  let newArr = [];
  Object.keys(grouped).forEach(group => {
    newArr = newArr.concat(grouped[group]);
  });
  return newArr;
};

export default createReducer(initialState, {
  [wizardActions.default.ADD_WIZARD_CRITERIA]: (state, payload) => {
    return Object.assign({}, state, {
      criteria: payload.criteria,
    });
  },
  [wizardActions.default.REMOVE_WIZARD_CRITERIA]: (state, payload) => {
    return Object.assign({}, state, {
      criteria: payload.criteria,
    });
  },
  [wizardActions.default.ADD_WIZARD_EXCLUSION]: (state, payload) => {
    return Object.assign({}, state, {
      excludes: transformCriteria(payload.excludes),
    });
  },
  [wizardActions.default.REMOVE_WIZARD_EXCLUSION]: (state, payload) => {
    return Object.assign({}, state, {
      excludes: transformCriteria(payload.excludes),
    });
  },
  [wizardActions.default.ADD_WIZARD_INCLUSION]: (state, payload) => {
    return Object.assign({}, state, {
      includes: transformCriteria(payload.includes),
    });
  },
  [wizardActions.default.REMOVE_WIZARD_INCLUSION]: (state, payload) => {
    return Object.assign({}, state, {
      includes: transformCriteria(payload.includes),
    });
  },
  [wizardActions.default.DUPLICATE_WIZARD_TEMPLATES]: (state, payload) => {
    return Object.assign({}, state, {
      html: payload.html,
      prejs: payload.prejs,
      postjs: payload.postjs,
      css: payload.css || '',
    });
  },
  [wizardActions.default.RESET_WIZARD]: state => {
    return Object.assign({}, state, initialState);
  },
  [wizardActions.default.SHOW_STYLES]: state => {
    return Object.assign({}, state, {
      isStylesVisible: true,
    });
  },
  [wizardActions.default.HIDE_STYLES]: state => {
    return Object.assign({}, state, {
      isStylesVisible: false,
    });
  },
  [wizardActions.default.SHOW_POSITION]: state => {
    return Object.assign({}, state, {
      isPositionVisible: true,
    });
  },
  [wizardActions.default.HIDE_POSITION]: state => {
    return Object.assign({}, state, {
      isPositionVisible: false,
    });
  },
  [wizardActions.default.SHOW_TIMING]: state => {
    return Object.assign({}, state, {
      isTimingVisible: true,
    });
  },
  [wizardActions.default.HIDE_TIMING]: state => {
    return Object.assign({}, state, {
      isTimingVisible: false,
    });
  },
  [wizardActions.default.SHOW_AUDIENCE]: state => {
    return Object.assign({}, state, {
      isAudienceVisible: true,
    });
  },
  [wizardActions.default.HIDE_AUDIENCE]: state => {
    return Object.assign({}, state, {
      isAudienceVisible: false,
    });
  },
  [wizardActions.default.SHOW_SCHEDULING]: state => {
    return Object.assign({}, state, {
      isSchedulingVisible: true,
    });
  },
  [wizardActions.default.HIDE_SCHEDULING]: state => {
    return Object.assign({}, state, {
      isSchedulingVisible: false,
    });
  },
  [wizardActions.default.SHOW_ADVANCED_CONFIG]: state => {
    return Object.assign({}, state, {
      isAdvancedConfigVisible: true,
    });
  },
  [wizardActions.default.HIDE_ADVANCED_CONFIG]: state => {
    return Object.assign({}, state, {
      isAdvancedConfigVisible: false,
    });
  },
  [wizardActions.default.WIZARD_TYPE_ADD]: state => {
    return Object.assign({}, state, {
      editForm: false,
    });
  },
  [wizardActions.default.WIZARD_TYPE_EDIT]: state => {
    return Object.assign({}, state, {
      editForm: true,
      isStylesVisible: true,
      isPositionVisible: true,
      isTimingVisible: true,
      isAudienceVisible: true,
      isSchedulingVisible: true,
    });
  },
  [wizardActions.default.SAVE_RESET]: state => {
    return Object.assign({}, state, {
      save: false,
    });
  },
  [wizardActions.default.SAVE_TEST]: state => {
    return Object.assign({}, state, {
      save: 'test',
    });
  },
  [wizardActions.default.SAVE_LIVE]: state => {
    return Object.assign({}, state, {
      save: 'live',
    });
  },
  [wizardActions.default.ADD_FORM_FIELD]: (state, payload) => {
    return Object.assign({}, state, {
      formFields: {
        version: payload.version,
        fields: payload.field,
      },
    });
  },
  [wizardActions.default.REMOVE_FORM_FIELD]: (state, payload) => {
    return Object.assign({}, state, {
      formFields: {
        version: payload.version,
        fields: payload.field,
      },
    });
  },

  [wizardActions.default.UPDATE_WIZARD_FORM]: (state, payload) => {
    return {
      ...state,
      wizardForm: {
        ...state.wizardForm,
        [payload.name]: payload.value
      },
    };
  },

  [wizardActions.default.INIT_WIZARD_VALUES]: (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  },

  [wizardActions.default.ADD_NO_RESULT_WIZARD_CRITERIA]: (state, payload) => {
    return Object.assign({}, state, {
      noResultCriteria: payload.noResultCriteria,
    });
  },
  [wizardActions.default.REMOVE_NO_RESULT_WIZARD_CRITERIA]: (
    state,
    payload,
  ) => {
    return Object.assign({}, state, {
      noResultCriteria: payload.noResultCriteria,
    });
  },
  [wizardActions.default.ADD_NO_RESULT_WIZARD_EXCLUSION]: (state, payload) => {
    return Object.assign({}, state, {
      noResultExcludes: transformCriteria(payload.noResultExcludes),
    });
  },
  [wizardActions.default.REMOVE_NO_RESULT_WIZARD_EXCLUSION]: (
    state,
    payload,
  ) => {
    return Object.assign({}, state, {
      noResultExcludes: transformCriteria(payload.noResultExcludes),
    });
  },
  [wizardActions.default.ADD_NO_RESULT_WIZARD_INCLUSION]: (state, payload) => {
    return Object.assign({}, state, {
      noResultIncludes: transformCriteria(payload.noResultIncludes),
    });
  },
  [wizardActions.default.REMOVE_NO_RESULT_WIZARD_INCLUSION]: (
    state,
    payload,
  ) => {
    return Object.assign({}, state, {
      noResultIncludes: transformCriteria(payload.noResultIncludes),
    });
  },
});
