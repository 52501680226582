import * as filterActions from '../constants/actions/filters';
import { createReducer } from '../system/store';

const initialState = {
  trendifyCategories: [],
  trendifyBrands: [],
  bannerifyGroups: [],
  bannerifyTitles: [],
  bannerifyStatus: 'latest',
  pageSize: 100,
  pageNumber: 1,
  count: 0,
  update: false,
  reset: false,
  disableButtons: false,
  visibleReset: false,
};

export default createReducer(initialState, {
  [filterActions.default.UPDATE_FILTERED]: state => {
    return { ...state, update: true };
  },
  [filterActions.default.FILTERED_UPDATED]: state => {
    return { ...state, update: false };
  },
  [filterActions.default.RESET_FILTERED]: state => {
    return { ...state, reset: true };
  },
  [filterActions.default.FILTERED_RESET]: state => {
    return {
      ...state,
      reset: false,
      pageSize: 100,
      pageNumber: 1,
    };
  },
  [filterActions.default.UPDATE_PAGESIZE]: (state, payload) => {
    return { ...state, pageSize: payload.pageSize };
  },
  [filterActions.default.UPDATE_PAGENUMBER]: (state, payload) => {
    return { ...state, pageNumber: payload.pageNumber };
  },
  [filterActions.default.UPDATE_COUNT]: (state, payload) => {
    return { ...state, count: payload.count };
  },
  [filterActions.default.UPDATE_TRENDIFY_CATEGORIES]: (state, payload) => {
    return { ...state, trendifyCategories: payload.categories };
  },
  [filterActions.default.UPDATE_TRENDIFY_BRANDS]: (state, payload) => {
    return { ...state, trendifyBrands: payload.brands };
  },
  [filterActions.default.UPDATE_BANNERIFY_GROUPS]: (state, payload) => {
    return { ...state, bannerifyGroups: payload.groups };
  },
  [filterActions.default.UPDATE_BANNERIFY_TITLES]: (state, payload) => {
    return { ...state, bannerifyTitles: payload.titles };
  },
  [filterActions.default.SHOW_RESET_BUTTON]: state => {
    return { ...state, visibleReset: true };
  },
  [filterActions.default.HIDE_RESET_BUTTON]: state => {
    return { ...state, visibleReset: false };
  },
  [filterActions.default.DISABLE_BUTTONS]: state => {
    return { ...state, disableButtons: true };
  },
  [filterActions.default.ENABLE_BUTTONS]: state => {
    return { ...state, disableButtons: false };
  },
});
