/**
 * @author Bilal Cinarli
 */

"use strict";

import { createAction } from "../system/store";
import { updateCount } from "./trendify";

export const updateGroups = newGroups => {
  let groups = newGroups.sort();

  createAction("UPDATE_BANNERIFY_GROUPS", { groups });
};

export const updateTitles = newTitles => {
  let titles = newTitles.sort();

  createAction("UPDATE_BANNERIFY_TITLES", { titles });
};

export default {
  updateGroups,
  updateTitles,
  updateCount
};
