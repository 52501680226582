'use strict';

import React from 'react';
import { connect } from 'react-redux';

import {
  getAccount,
  getApiKey,
  getRegion,
  getSwitchAccountUser,
  getToken,
  isMultiAccountUser,
  isSuperUser,
  isSwitchAccount,
} from '../../modules/auth/user';

import { pageTypes } from '../../constants/datamaps/wizard';

import { t } from '../../system/ui';
import {
  campaignActions,
  dialogActions,
  filterActions,
  modalActions,
  uiActions,
} from '../../actions';
import { mediums } from '../../constants/datamaps/campaigns';

import { singleCampaign } from '../../modules/campaigns/wizard';
import { deactivateCampaign } from '../../modules/campaigns/ajax';

import Icon from '../icon';
import Icons from '../../components/icons';
import DuplicateModal from '../../modules/campaigns/duplicatemodal';
import CampaignHistoryModal from '../../modules/campaigns/historymodal';
import CampaignReplicateModal from '../../modules/campaigns/multireplicatemodal';
import { getEndPoint } from '../../system/api';

const editableTemplates = [
  'HUR_8',
  'T16',
  'HUR_4',
  'SMSG_01',
  'T21',
  'T10_1',
  'JB_1',
];

class WidgetActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [
        { name: 'Edit', icon: 'gear', action: 'edit', newPanelOnly: true },
        { name: 'Report', icon: 'pie-chart', action: 'report' },
        {
          name: 'Duplicate',
          icon: 'duplicate',
          action: 'duplicate',
          newPanelOnly: true,
        },
        {
          name: 'Replicate',
          icon: 'history',
          iconSvg: true,
          action: 'replicateMultiAccounts',
          superUserOnly: true,
          multiAccountUser: true,
        },
        { name: 'Archive', icon: 'archive', action: 'addToArchive' },
        {
          name: 'History',
          icon: 'history',
          iconSvg: true,
          action: 'showCampaignHistory',
        },
        { name: 'Export', icon: 'pie-chart', action: 'exportJourneyBuilder' },
      ],
      dropUp: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.edit = this.edit.bind(this);
    this.report = this.report.bind(this);
    this.duplicate = this.duplicate.bind(this);
    this.addToArchive = this.addToArchive.bind(this);
    this.addToArchiveConfirmed = this.addToArchiveConfirmed.bind(this);
    this.showCampaignHistory = this.showCampaignHistory.bind(this);
    this.exportJourneyBuilder = this.exportJourneyBuilder.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleClick(action) {
    if (this.props.isArchive && action === 'report') {
      this[action]('?archive');
    } else {
      this[action]();
    }
  }

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    let partOfDocHeight = (document.documentElement.offsetHeight * 70) / 100;
    let listTop = this.refs.actionMenu.getBoundingClientRect().top;
    this.setState({
      dropUp: listTop > partOfDocHeight,
    });
  }

  exportJourneyBuilder() {
    let globalApiKey =
      getApiKey() !== undefined ? '?apiKey=' + getApiKey() : '';
    let endpoint = getEndPoint('export/all/journeyBuilder') + globalApiKey;
    let instanceId = this.props.instanceId;
    let exportUrl =
      endpoint +
      '&interval=total&limit=2000&instanceId=' +
      instanceId +
      '&authToken=' +
      getToken();
    if (isSwitchAccount() && !isMultiAccountUser()) {
      exportUrl += '&switchUser=' + getSwitchAccountUser(true).username;
    }
    if (isMultiAccountUser() && isSwitchAccount()) {
      exportUrl += '&switchAccount=' + getAccount().accountId.trim();
    }
    exportUrl += '&switchRegion=' + getRegion();
    window.open(exportUrl);
  }

  edit() {
    let page;

    if (this.props.inputs.length > 0) {
      let value =
        this.props.inputs[0].value &&
        this.props.inputs[0].value.length &&
        this.props.inputs[0].value[0].replace(' Page', '').toLowerCase();
      page = pageTypes.indexOf(value) > -1 ? value : 'custom';
    }

    if (
      this.props.inputs[0] &&
      this.props.inputs[0].value &&
      this.props.inputs[0].value.length > 1
    ) {
      page = 'custom';
    } else {
      if (this.props.templateId === 'HUR_8') {
        page = 'product';
      }

      if (this.props.templateId === 'T16') {
        page = 'basket';
      }

      if (this.props.templateId === 'T21') {
        page = 'checkout-success';
      }
    }

    if (
      this.props &&
      this.props.actions &&
      this.props.actions[0] &&
      this.props.actions[0].params &&
      this.props.actions[0].params.actionType === 'POPUP_RECO'
    ) {
      page = 'popup_recommendation';
    }

    if (
      this.props.actions.length &&
      this.props.actions[0].type === 'recommendPromotion'
    ) {
      this.props.router.push({
        pathname: '/promotion/edit/' + page + '/' + this.props.instanceId,
      });
    } else if (
      this.props.actions.length &&
      this.props.actions[0].type === 'recommendKeyword'
    ) {
      this.props.router.push({
        pathname: '/searchkeywords/edit/' + page + '/' + this.props.instanceId,
      });
    } else if (this.props.templateId === 'JB_1') {
      this.props.router.push({
        pathname: '/edit/journey_builder/fullWidth/' + this.props.instanceId,
      });
    } else {
      this.props.router.push({
        pathname:
          '/widget/edit/' + page + '/fullWidth/' + this.props.instanceId,
      });
    }
  }

  report(query = '') {
    this.props.router.push({
      pathname:
        '/reports/single/recommendation/' + this.props.instanceId + query,
    });
  }

  duplicate() {
    singleCampaign(this.props);
    const duplicatedName = `${this.props.scenarioName} Copy`;
    const duplicatedTitle =
      this.props.actions.length &&
      this.props.actions[0].params.notificationTitle;
    const duplicatedTitleMap =
      this.props.actions.length &&
      this.props.actions[0].params.notificationTitleMap;
    const duplicatedDynamicItems =
      this.props.actions.length && this.props.actions[0].params.dynamicItems;
    const duplicatedSelector =
      this.props.actions.length && this.props.actions[0].params.selector;
    const duplicatedInsertType =
      this.props.actions.length && this.props.actions[0].params.insertType;
    const duplicatedOrder =
      this.props.actions.length && this.props.actions[0].params.ordering;
    const duplicatedDevices = mediums[this.props.device] || '';
    const duplicateWidgetTemplateId =
      this.props.widgetTemplateId !== undefined
        ? this.props.widgetTemplateId
        : null;

    if (duplicatedTitle && duplicatedTitleMap) {
      const account = getAccount();
      const { mainLanguage } = account;
      const additionalTitles = { ...duplicatedTitleMap };
      additionalTitles[mainLanguage] = duplicatedTitle;
    }

    if (this.props.actions[0].params.actionType !== 'POPUP_RECO') {
      const duplicateModal = () => (
        <DuplicateModal
          router={this.props.router}
          duplicateOptions={{
            name: duplicatedName,
            title: duplicatedTitle,
            titleMap: duplicatedTitleMap,
            dynamicItems: duplicatedDynamicItems,
            selector: duplicatedSelector,
            insertType: duplicatedInsertType,
            devices: duplicatedDevices,
            shuffleItems: duplicatedOrder === 'SHUFFLE',
            widgetTemplateId: duplicateWidgetTemplateId,
          }}
        />
      );

      modalActions.openModal({
        title: t('Select Page'),
        content: duplicateModal,
        className: 'modal-noscroll',
      });
    } else {
      // Popup reco duplicates
      const overlay =
        this.props.actions.length && this.props.actions[0].params.overlay;
      const verticalPosition =
        this.props.actions.length &&
        this.props.actions[0].params.verticalPosition;
      const horizontalPosition =
        this.props.actions.length &&
        this.props.actions[0].params.horizontalPosition;
      const delayAction =
        this.props.actions.length && this.props.actions[0].delayAction;
      const constraint =
        this.props.actions.length && this.props.actions[0].constraint;
      const filters =
        this.props.actions.length && this.props.actions[0].filters;
      const pages = this.props.inputs.length && this.props.inputs[0];

      campaignActions.duplicateCampaign(true, [
        duplicatedName,
        duplicatedTitle,
        duplicatedSelector,
        duplicatedInsertType,
        duplicatedDevices,
        duplicatedOrder,
        overlay,
        verticalPosition,
        horizontalPosition,
        delayAction,
        constraint,
        filters,
        pages,
        duplicateWidgetTemplateId,
      ]);

      this.props.router.push({
        pathname: '/widget/add/popup_recommendation/popup_recommendation',
      });
    }
  }

  addToArchive() {
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('This action will immediately effect on your website'),
      onConfirm: () => {
        this.addToArchiveConfirmed();

        dialogActions.closeDialog();
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  addToArchiveConfirmed() {
    deactivateCampaign(this.props.instanceId, response => {
      if (response.status === 'OK') {
        if (this.props.campaign.testMode) {
          if (this.props.getCampaignsAfterArchive) {
            this.props.getCampaignsAfterArchive();
          } else {
            filterActions.updateFilters();
          }
        } else {
          filterActions.updateFilters();
        }
        uiActions.showNotification({
          content: () => {
            return <div>{t('The campaign was successfully archived.')}</div>;
          },
        });
      }
    });
  }

  showCampaignHistory() {
    let campaignName = this.props.scenarioName,
      campaignId = this.props.instanceId,
      campaignHistoryModal = () => (
        <CampaignHistoryModal campaignId={campaignId} />
      );

    modalActions.openModal({
      title: 'Campaign History (' + campaignName + ')',
      content: campaignHistoryModal,
    });
  }

  replicateMultiAccounts() {
    let campaignName = this.props.campaign.scenarioName,
      campaignReplicateModal = () => (
        <CampaignReplicateModal campaign={this.props.campaign} />
      );

    modalActions.openModal({
      title: 'Replicate Campaign (' + campaignName + ')',
      content: campaignReplicateModal,
    });
  }

  render() {
    let actions = this.state.actions;
    let listClass = 'widget-nav-popup action-nav-popup';
    if (this.state.dropUp) {
      actions = this.state.actions.slice().reverse();
      listClass = 'widget-nav-popup action-nav-popup drop-up';
    }
    return (
      <nav
        className='widget-nav action-nav'
        aria-haspopup='true'
        ref='actionMenu'
      >
        <a className='secondary-action'>{t('Actions')}</a>

        <ul className={listClass}>
          {actions.map((action, index) => {
            if (this.props.templateId === 'JB_1') {
              if (
                action.name !== 'Export' &&
                action.name !== 'Edit' &&
                action.name !== 'History' &&
                action.name !== 'Archive'
              ) {
                return;
              }
            } else {
              if (action.name === 'Export') {
                return;
              }

              if (
                this.props.actions.length &&
                this.props.actions[0].type !== 'recommendPromotion' &&
                this.props.actions[0].params.actionType !== 'INPAGE_WIDGET' &&
                this.props.actions[0].params.actionType !== 'POPUP_RECO' &&
                action.newPanelOnly
              ) {
                return;
              }

              if (
                this.props.actions.length &&
                this.props.actions[0].type === 'recommendPromotion' &&
                action.action == 'duplicate'
              ) {
                return;
              }
            }

            if (!this.props.actions.length && action.newPanelOnly) {
              return;
            }

            if (
              editableTemplates.indexOf(this.props.templateId) === -1 &&
              action.newPanelOnly
            ) {
              return;
            }

            if (
              (!isSuperUser() && action.superUserOnly) ||
              (!isMultiAccountUser && action.multiAccountUser)
            ) {
              return;
            }

            if (this.props.isArchive) {
              if (
                action.name === 'Archive' &&
                this.props.templateId === 'JB_1'
              ) {
                return (
                  <span>
                    <li key={index}>
                      <a
                        className='widget-nav-popup-item action-nav-popup-item'
                        onClick={this.props.goTestFromArchive}
                      >
                        <Icons name={'goTest'} color='#fff' />
                        {t('Go Test')}
                      </a>
                    </li>
                  </span>
                );
              } else if (action.action === 'report') {
                return (
                  <span>
                    <li key={index}>
                      <a
                        className='widget-nav-popup-item action-nav-popup-item'
                        onClick={this.handleClick.bind(null, action.action)}
                      >
                        {action.iconSvg ? (
                          <Icons name={action.icon} color='#fff' />
                        ) : (
                          <Icon name={action.icon} />
                        )}
                        {t(action.name)}
                      </a>
                    </li>
                    <li key={index + 1}>
                      <a
                        className='widget-nav-popup-item action-nav-popup-item'
                        onClick={this.props.goTestFromArchive}
                      >
                        <Icons name={'goTest'} color='#fff' />
                        {t('Go Test')}
                      </a>
                    </li>
                  </span>
                );
              }
            } else {
              return (
                <li key={index}>
                  <a
                    className='widget-nav-popup-item action-nav-popup-item'
                    onClick={this.handleClick.bind(null, action.action)}
                  >
                    {action.iconSvg ? (
                      <Icons name={action.icon} color='#fff' />
                    ) : (
                      <Icon name={action.icon} />
                    )}
                    {t(action.name)}
                  </a>
                </li>
              );
            }
          })}
        </ul>
      </nav>
    );
  }
}

const mapStatesToProps = store => ({
  status: store.campaigns.status,
});

export default connect(mapStatesToProps)(WidgetActions);
