import React from 'react';
import PropTypes from 'prop-types';

export default function Experimentation(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${props.width}px`}
      height={`${props.height}px`}
      viewBox='0 0 21 18'
      fill='none'
    >
      <path
        d='M12.7037 5.59259H1.74074V16.2593H8.57479L12.7037 5.59259Z'
        fill='white'
      />
      <path
        d='M8.85185 16.2593L19.8148 16.2593L19.8148 5.59259L12.9808 5.59259L8.85185 16.2593Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 3.51852C1 2.12758 2.12758 1 3.51852 1H17.7407C19.1317 1 20.2593 2.12758 20.2593
        3.51852V14.4815C20.2593 15.8724 19.1317 17 17.7407 17H3.51852C2.12758 17 1 15.8724 1
        14.4815V3.51852ZM3.51852 1.88889C2.6185 1.88889 1.88889 2.6185 1.88889
        3.51852V5.2963H19.3704V3.51852C19.3704 2.6185 18.6408 1.88889 17.7407 1.88889H3.51852ZM1.88889
        14.4815V6.18518H12.3452L8.40203 16.1111H3.51852C2.6185 16.1111 1.88889 15.3815 1.88889
        14.4815ZM9.35849 16.1111H17.7407C18.6408 16.1111 19.3704 15.3815
        19.3704 14.4815V6.18518H13.3017L9.35849 16.1111Z'
        fill={props.color}
      />
      <path
        d='M7.11081 11.4871H5.76267L5.49689 12.1111H4.98074L6.19407 9.41482H6.68711L7.90429
        12.1111H7.38044L7.11081 11.4871ZM6.94518 11.0942L6.43674 9.91556L5.93215 11.0942H6.94518Z'
        fill={props.color}
      />
      <path
        d='M16.1615 10.709C16.3104 10.7578 16.4285 10.84 16.5158 10.9556C16.6032 11.0686 16.6468
        11.2098 16.6468 11.3793C16.6468 11.6129 16.5569 11.794 16.3772 11.9224C16.1974 12.0482
        15.9355 12.1111 15.5914 12.1111H14.251V9.41482H15.5144C15.8328 9.41482 16.078 9.47774
        16.2501 9.60356C16.4221 9.72682 16.5081 9.89759 16.5081 10.1159C16.5081 10.2494 16.4773
        10.3675 16.4157 10.4702C16.3541 10.5729 16.2693 10.6526 16.1615 10.709ZM14.7517
        9.80771V10.5511H15.4604C15.6351 10.5511 15.7686 10.5203 15.861 10.4587C15.956 10.3945 16.0035
        10.302 16.0035 10.1813C16.0035 10.0581 15.956 9.96564 15.861 9.90401C15.7686 9.83981 15.6351
        9.80771 15.4604 9.80771H14.7517ZM15.5606 11.7182C15.9483 11.7182 16.1422 11.5886 16.1422
        11.3292C16.1422 11.0698 15.9483 10.9402 15.5606 10.9402H14.7517V11.7182H15.5606Z'
        fill={props.color}
      />
      <path
        d='M17.4444 3.81481C17.4444 4.14209 17.1791 4.40741 16.8518 4.40741C16.5246 4.40741 16.2593
        4.14209 16.2593 3.81481C16.2593 3.48753 16.5246 3.22222 16.8518 3.22222C17.1791 3.22222
        17.4444 3.48753 17.4444 3.81481Z'
        fill={props.color}
      />
      <path
        d='M15.963 3.81481C15.963 4.14209 15.6976 4.40741 15.3704 4.40741C15.0431 4.40741 14.7778
        4.14209 14.7778 3.81481C14.7778 3.48753 15.0431 3.22222 15.3704 3.22222C15.6976 3.22222
        15.963 3.48753 15.963 3.81481Z'
        fill={props.color}
      />
      <path
        d='M14.4815 3.81481C14.4815 4.14209 14.2162 4.40741 13.8889 4.40741C13.5616 4.40741 13.2963
        4.14209 13.2963 3.81481C13.2963 3.48753 13.5616 3.22222 13.8889 3.22222C14.2162 3.22222
        14.4815 3.48753 14.4815 3.81481Z'
        fill={props.color}
      />
      <path
        d='M1.74074 5.59259V5.49259H1.64074V5.59259H1.74074ZM12.7037 5.59259L12.797 5.62869L12.8496
        5.49259H12.7037V5.59259ZM8.57479 16.2593V16.3593H8.64332L8.66805 16.2954L8.57479
        16.2593ZM1.74074 16.2593H1.64074V16.3593H1.74074V16.2593ZM19.8148
        16.2593V16.3593H19.9148V16.2593H19.8148ZM8.85185 16.2593L8.75859
        16.2232L8.70591 16.3593H8.85185V16.2593ZM12.9808 5.59259V5.49259H12.9122L12.8875
        5.55649L12.9808 5.59259ZM19.8148 5.59259H19.9148V5.49259H19.8148V5.59259ZM1.88889
        5.2963H1.78889V5.3963H1.88889V5.2963ZM19.3704 5.2963V5.3963H19.4704V5.2963H19.3704ZM1.88889
        6.18518V6.08519H1.78889V6.18518H1.88889ZM12.3452 6.18518L12.4381 6.2221L12.4925
        6.08519H12.3452V6.18518ZM8.40203 16.1111V16.2111H8.46991L8.49497 16.148L8.40203
        16.1111ZM9.35849 16.1111L9.26556 16.0742L9.21117 16.2111H9.35849V16.1111ZM19.3704
        6.18518H19.4704V6.08519H19.3704V6.18518ZM13.3017 6.18518V6.08519H13.2338L13.2087
        6.14827L13.3017 6.18518ZM7.11081 11.4871L7.20261 11.4475L7.17654 11.3871H7.11081V11.4871ZM5.76267
        11.4871V11.3871H5.69657L5.67066 11.4479L5.76267 11.4871ZM5.49689 12.1111V12.2111H5.56299L5.58889
        12.1503L5.49689 12.1111ZM4.98074 12.1111L4.88955 12.0701L4.82608 12.2111H4.98074V12.1111ZM6.19407
        9.41482V9.31482H6.12941L6.10288 9.37379L6.19407 9.41482ZM6.68711 9.41482L6.77825 9.37368L6.75168
        9.31482H6.68711V9.41482ZM7.90429 12.1111V12.2111H8.05915L7.99544 12.07L7.90429 12.1111ZM7.38044
        12.1111L7.28865 12.1508L7.31472 12.2111H7.38044V12.1111ZM6.94518 11.0942V11.1942H7.09723L7.03701
        11.0546L6.94518 11.0942ZM6.43674 9.91556L6.52856 9.87595L6.43639 9.66229L6.34481 9.87621L6.43674
        9.91556ZM5.93215 11.0942L5.84022 11.0549L5.78056 11.1942H5.93215V11.0942ZM16.1615 10.709L16.1151
        10.6205L15.9053 10.7303L16.1303 10.8041L16.1615 10.709ZM16.5158 10.9556L16.4361 11.0158L16.4367
        11.0167L16.5158 10.9556ZM16.3772 11.9224L16.4345 12.0043L16.4353 12.0038L16.3772 11.9224ZM14.251
        12.1111H14.151V12.2111H14.251V12.1111ZM14.251 9.41482V9.31482H14.151V9.41482H14.251ZM16.2501
        9.60356L16.191 9.68428L16.1918 9.68485L16.2501 9.60356ZM16.4157 10.4702L16.3299 10.4188L16.4157
        10.4702ZM14.7517 9.80771V9.70771H14.6517V9.80771H14.7517ZM14.7517 10.5511H14.6517V10.6511H14.7517V10.5511ZM15.861
        10.4587L15.9165 10.5419L15.917 10.5415L15.861 10.4587ZM15.861 9.90401L15.804 9.98619L15.8066 9.9879L15.861
        9.90401ZM14.7517 10.9402V10.8402H14.6517V10.9402H14.7517ZM14.7517 11.7182H14.6517V11.8182H14.7517V11.7182ZM1.74074
        5.69259H12.7037V5.49259H1.74074V5.69259ZM12.6104 5.55649L8.48154 16.2232L8.66805 16.2954L12.797 5.62869L12.6104
        5.55649ZM8.57479 16.1593H1.74074V16.3593H8.57479V16.1593ZM1.84074 16.2593V5.59259H1.64074V16.2593H1.84074ZM19.8148
        16.1593L8.85185 16.1593V16.3593L19.8148 16.3593V16.1593ZM8.94511 16.2954L13.074 5.62869L12.8875 5.55649L8.75859
        16.2232L8.94511 16.2954ZM12.9808 5.69259L19.8148 5.69259V5.49259L12.9808 5.49259V5.69259ZM19.7148
        5.59259L19.7148 16.2593H19.9148L19.9148 5.59259H19.7148ZM3.51852 0.9C2.07235 0.9 0.9 2.07235 0.9
        3.51852H1.1C1.1 2.18281 2.18281 1.1 3.51852 1.1V0.9ZM17.7407 0.9H3.51852V1.1H17.7407V0.9ZM20.3593
        3.51852C20.3593 2.07235 19.1869 0.9 17.7407 0.9V1.1C19.0764 1.1 20.1593 2.18281 20.1593
        3.51852H20.3593ZM20.3593 14.4815V3.51852H20.1593V14.4815H20.3593ZM17.7407 17.1C19.1869 17.1
        20.3593 15.9276 20.3593 14.4815H20.1593C20.1593 15.8172 19.0764 16.9 17.7407 16.9V17.1ZM3.51852
        17.1H17.7407V16.9H3.51852V17.1ZM0.9 14.4815C0.9 15.9276 2.07235 17.1 3.51852 17.1V16.9C2.18281
        16.9 1.1 15.8172 1.1 14.4815H0.9ZM0.9 3.51852V14.4815H1.1V3.51852H0.9ZM1.98889 3.51852C1.98889
        2.67373 2.67373 1.98889 3.51852 1.98889V1.78889C2.56327 1.78889 1.78889 2.56327 1.78889
        3.51852H1.98889ZM1.98889 5.2963V3.51852H1.78889V5.2963H1.98889ZM19.3704 5.1963H1.88889V5.3963H19.3704V5.1963ZM19.2704
        3.51852V5.2963H19.4704V3.51852H19.2704ZM17.7407 1.98889C18.5855 1.98889 19.2704 2.67373 19.2704 3.51852H19.4704C19.4704
        2.56327 18.696 1.78889 17.7407 1.78889V1.98889ZM3.51852 1.98889H17.7407V1.78889H3.51852V1.98889ZM1.78889
        6.18518V14.4815H1.98889V6.18518H1.78889ZM12.3452 6.08519H1.88889V6.28518H12.3452V6.08519ZM8.49497
        16.148L12.4381 6.2221L12.2523 6.14827L8.3091 16.0742L8.49497 16.148ZM3.51852 16.2111H8.40203V16.0111H3.51852V16.2111ZM1.78889
        14.4815C1.78889 15.4367 2.56327 16.2111 3.51852 16.2111V16.0111C2.67373 16.0111 1.98889 15.3263 1.98889 14.4815H1.78889ZM9.35849
        16.2111H17.7407V16.0111H9.35849V16.2111ZM17.7407 16.2111C18.696 16.2111 19.4704 15.4367 19.4704 14.4815H19.2704C19.2704 15.3263
        18.5855 16.0111 17.7407 16.0111V16.2111ZM19.4704 14.4815V6.18518H19.2704V14.4815H19.4704ZM19.3704 6.08519H13.3017V6.28518H19.3704V6.08519ZM13.2087
        6.14827L9.26556 16.0742L9.45143 16.148L13.3946 6.2221L13.2087 6.14827ZM7.11081 11.3871H5.76267V11.5871H7.11081V11.3871ZM5.67066 11.4479L5.40489
        12.0719L5.58889 12.1503L5.85467 11.5263L5.67066 11.4479ZM5.49689 12.0111H4.98074V12.2111H5.49689V12.0111ZM5.07193 12.1522L6.28527 9.45586L6.10288
        9.37379L4.88955 12.0701L5.07193 12.1522ZM6.19407 9.51482H6.68711V9.31482H6.19407V9.51482ZM6.59597 9.45597L7.81315 12.1523L7.99544 12.07L6.77825
        9.37368L6.59597 9.45597ZM7.90429 12.0111H7.38044V12.2111H7.90429V12.0111ZM7.47224 12.0715L7.20261 11.4475L7.01902 11.5268L7.28865 12.1508L7.47224
        12.0715ZM7.03701 11.0546L6.52856 9.87595L6.34492 9.95517L6.85336 11.1338L7.03701 11.0546ZM6.34481 9.87621L5.84022 11.0549L6.02408 11.1336L6.52867
        9.95492L6.34481 9.87621ZM5.93215 11.1942H6.94518V10.9942H5.93215V11.1942ZM16.1303 10.8041C16.2616 10.8471 16.3621 10.9179 16.4361 11.0158L16.5956
        10.8953C16.495 10.7621 16.3593 10.6686 16.1926 10.614L16.1303 10.8041ZM16.4367 11.0167C16.5081
        11.1091 16.5468 11.2276 16.5468 11.3793H16.7468C16.7468 11.1919 16.6982 11.028 16.595 10.8944L16.4367
        11.0167ZM16.5468 11.3793C16.5468 11.5829 16.4708 11.7326 16.3191 11.841L16.4353 12.0038C16.6431
        11.8553 16.7468 11.643 16.7468 11.3793H16.5468ZM16.3198 11.8405C16.164 11.9496 15.9258 12.0111
        15.5914 12.0111V12.2111C15.9452 12.2111 16.2309 12.1469 16.4345 12.0043L16.3198 11.8405ZM15.5914
        12.0111H14.251V12.2111H15.5914V12.0111ZM14.351 12.1111V9.41482H14.151V12.1111H14.351ZM14.251
        9.51482H15.5144V9.31482H14.251V9.51482ZM15.5144 9.51482C15.8215 9.51482 16.0427 9.5758 16.191
        9.68428L16.3091 9.52285C16.1133 9.37967 15.8441 9.31482 15.5144 9.31482V9.51482ZM16.1918
        9.68485C16.3367 9.78865 16.4081 9.92864 16.4081 10.1159H16.6081C16.6081 9.86654 16.5075 9.665
        16.3083 9.52227L16.1918 9.68485ZM16.4081 10.1159C16.4081 10.2334 16.3812 10.3333 16.3299
        10.4188L16.5014 10.5217C16.5734 10.4017 16.6081 10.2654 16.6081 10.1159H16.4081ZM16.3299
        10.4188C16.2779 10.5055 16.2069 10.5723 16.1151 10.6205L16.2079 10.7976C16.3317 10.7328 16.4302
        10.6404 16.5014 10.5217L16.3299 10.4188ZM14.6517 9.80771V10.5511H14.8517V9.80771H14.6517ZM14.7517
        10.6511H15.4604V10.4511H14.7517V10.6511ZM15.4604 10.6511C15.6444 10.6511 15.8007 10.6191 15.9165
        10.5419L15.8056 10.3755C15.7365 10.4215 15.6257 10.4511 15.4604 10.4511V10.6511ZM15.917 10.5415C16.0412
        10.4576 16.1035 10.3332 16.1035 10.1813H15.9035C15.9035 10.2709 15.8709 10.3313 15.805 10.3758L15.917
        10.5415ZM16.1035 10.1813C16.1035 10.0278 16.0418 9.90204 15.9155 9.82011L15.8066 9.9879C15.8703 10.0292
        15.9035 10.0884 15.9035 10.1813H16.1035ZM15.9181 9.82187C15.802 9.74124 15.645 9.70771 15.4604
        9.70771V9.90771C15.6251 9.90771 15.7352 9.93838 15.804 9.98614L15.9181 9.82187ZM15.4604 9.70771H14.7517V9.90771H15.4604V9.70771ZM15.5606
        11.8182C15.7625 11.8182 15.9314 11.785 16.0524 11.7041C16.1806 11.6184 16.2422 11.4879 16.2422 11.3292H16.0422C16.0422
        11.4299 16.0069 11.4939 15.9412 11.5379C15.8684 11.5866 15.7465 11.6182 15.5606 11.6182V11.8182ZM16.2422
        11.3292C16.2422 11.1705 16.1806 11.04 16.0524 10.9543C15.9314 10.8733 15.7625 10.8402 15.5606
        10.8402V11.0402C15.7465 11.0402 15.8684 11.0718 15.9412 11.1205C16.0069 11.1645 16.0422 11.2285
        16.0422 11.3292H16.2422ZM15.5606 10.8402H14.7517V11.0402H15.5606V10.8402ZM14.6517 10.9402V11.7182H14.8517V10.9402H14.6517ZM14.7517
        11.8182H15.5606V11.6182H14.7517V11.8182ZM17.3444 3.81481C17.3444 4.08687 17.1239 4.30741 16.8518 4.30741V4.50741C17.2344
        4.50741 17.5444 4.19732 17.5444 3.81481H17.3444ZM16.8518 4.30741C16.5798 4.30741 16.3593 4.08687 16.3593 3.81481H16.1593C16.1593
        4.19732 16.4693 4.50741 16.8518 4.50741V4.30741ZM16.3593 3.81481C16.3593 3.54276 16.5798 3.32222 16.8518 3.32222V3.12222C16.4693
        3.12222 16.1593 3.43231 16.1593 3.81481H16.3593ZM16.8518 3.32222C17.1239 3.32222 17.3444 3.54276 17.3444 3.81481H17.5444C17.5444
        3.43231 17.2344 3.12222 16.8518 3.12222V3.32222ZM15.863 3.81481C15.863 4.08687 15.6424 4.30741 15.3704 4.30741V4.50741C15.7529
        4.50741 16.063 4.19732 16.063 3.81481H15.863ZM15.3704 4.30741C15.0983 4.30741 14.8778 4.08687 14.8778 3.81481H14.6778C14.6778
        4.19732 14.9879 4.50741 15.3704 4.50741V4.30741ZM14.8778 3.81481C14.8778 3.54276 15.0983 3.32222 15.3704 3.32222V3.12222C14.9879
        3.12222 14.6778 3.43231 14.6778 3.81481H14.8778ZM15.3704 3.32222C15.6424 3.32222 15.863 3.54276 15.863 3.81481H16.063C16.063 3.43231
        15.7529 3.12222 15.3704 3.12222V3.32222ZM14.3815 3.81481C14.3815 4.08687 14.1609 4.30741 13.8889 4.30741V4.50741C14.2714
        4.50741 14.5815 4.19732 14.5815 3.81481H14.3815ZM13.8889 4.30741C13.6168 4.30741 13.3963 4.08687 13.3963 3.81481H13.1963C13.1963
        4.19732 13.5064 4.50741 13.8889 4.50741V4.30741ZM13.3963 3.81481C13.3963 3.54276 13.6168 3.32222 13.8889 3.32222V3.12222C13.5064
        3.12222 13.1963 3.43231 13.1963 3.81481H13.3963ZM13.8889 3.32222C14.1609 3.32222 14.3815 3.54276 14.3815 3.81481H14.5815C14.5815
        3.43231 14.2714 3.12222 13.8889 3.12222V3.32222Z'
        fill={props.color}
      />
    </svg>
  );
}

Experimentation.defaultProps = {
  color: '#BABABA',
  width: 16,
  height: 16,
};

Experimentation.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
