/**
 * Created by mehmetyurtar on 13/09/2017.
 */
import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { t } from "../../system/ui";

import { searchPromotion } from "../../modules/product/ajax";

import Icon from "../icon";
import Icons from "../icons";

import { TextField } from "../fields";
import Highlight from "../highlight";

import Tooltip from "../../components/tooltip";

class CriterionPromotion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autocompleteVisible: true,
      promotions: [],
      criterion: "",
      code: "",
      selected: false,
      preloader: "",
      hasError: false,
      showRequiredError: false
    };

    this.setDefaults = this.setDefaults.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectPromotion = this.selectPromotion.bind(this);
    this.closeAutocomplete = this.closeAutocomplete.bind(this);
    this.validate = this.validate.bind(this);
    this.timeout = false;
  }

  componentDidMount() {
    let defaultCriterion = "",
      defaultCode = "";

    if (Object.keys(this.props.values).length > 0) {
      defaultCriterion = this.props.values.criterion
        ? this.props.values.criterion.value
        : "";
      defaultCode = this.props.values.code ? this.props.values.code.value : "";

      if (defaultCode !== '') {
        searchPromotion(defaultCode, response => {
          const result = response.filter(item => item.code === defaultCode);
          const { code } = result[0];
          const { title } = result[0];
          this.setState({
            criterion: `${code} - ${title || 'PROMOTION NAME'}`,
            code,
            selected: true,
            autocompleteVisible: false,
          });
        });
      }
    }

    this.setDefaults(defaultCriterion, defaultCode);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.validate === true) {
      this.validate();
    }
  }

  componentDidUpdate(oldProps, oldStates) {
    if (oldStates.code !== this.state.code) {
      this.props.onCriteriaChange(this.refs.inputs, this.props.id);
      this.validate();
    }
  }

  setDefaults(defaultCriterion, defaultCode) {
    this.setState({
      criterion: defaultCriterion,
      code: defaultCode
    });
  }

  closeAutocomplete() {
    setTimeout(() => {
      if (this.state.selected) {
        this.setState({
          autocompleteVisible: false
        });
      } else {
        this.setState({
          criterion: "",
          code: "",
          autocompleteVisible: false
        });
      }
    }, 250);
  }

  selectPromotion(promotion) {
    this.setState({
      criterion: promotion.code + " - " + (promotion.title || "PROMOTION NAME"),
      code: promotion.code,
      autocompleteVisible: false,
      selected: true
    });
  }

  onChange(e) {
    let keyword = "",
      name = "";

    clearTimeout(this.timeout);

    if (Object.keys(this.props.values).length > 0) {
      name = this.props.values.criterion
        ? this.props.values.criterion.value
        : "";
      keyword = this.props.values.code ? this.props.values.code.value : "";
    }

    if (typeof e !== "undefined") {
      keyword = e.currentTarget.value;
      name = e.currentTarget.value;
    }

    this.setState({
      criterion: name.replace(this.refs.code.value + " - ", ""),
      selected: false
    });

    if (keyword.length > 2) {
      this.timeout = setTimeout(() => {
        this.setState({
          preloader: "isLoading"
        });

        searchPromotion(keyword, response => {
          this.setState({
            promotions: response,
            autocompleteVisible: true,
            preloader: "isLoaded"
          });
        });
      }, 500);
    }
  }

  validate() {
    let input = this.refs.code;

    if (input.value === "") {
      this.setState({
        hasError: true,
        showRequiredError: true
      });
    } else {
      this.setState({
        hasError: false,
        showRequiredError: false
      });
    }
  }

  render() {
    return (
      <li className="wizard-criterion" ref="inputs">
        <span className="criteria-type">
          <Tooltip content={t("Static (Promotion)")} alignment="left">
            <Icon name="product" />
          </Tooltip>
        </span>

        <span className="wizard-product-name">
          <TextField
            name="criterion"
            value={this.state.criterion}
            className="item-field criteria-name"
            inputClassName="one-whole criteria-field"
            placeholder="Promotion Name/Code"
            required={true}
            onChange={this.onChange}
            onBlur={this.closeAutocomplete}
          />

          <input
            type="hidden"
            name="code"
            ref="code"
            className={classNames("criteria-field", {
              "has-error": this.state.hasError
            })}
            value={this.state.code}
            onChange={this.onChange}
          />
          {this.state.showRequiredError ? (
            <span className="item-error">
              {t("You should select a promotion from suggestions")}
            </span>
          ) : (
            ""
          )}

          <span
            className={classNames("preloader", {
              "preloader-start": this.state.preloader === "isLoading",
              "preloader-end": this.state.preloader === "isLoaded"
            })}
          />
        </span>

        <a
          onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
          className={
            this.props.isOnlySelection
              ? "cancel-action cancel-action--disabled"
              : "cancel-action"
          }
        >
          <Icons name="crossCircle" />
        </a>

        {this.state.promotions.length > 0 && this.state.autocompleteVisible ? (
          <ul className="autocomplete">
            {this.state.promotions.map((item, index) => {
              return (
                <li key={index} onClick={this.selectPromotion.bind(null, item)}>
                  <span className="autocomplete-item">
                    <span className="autocomplete-item-id">
                      <Highlight
                        keyword={this.state.criterion}
                        text={item.code}
                      />
                    </span>
                    <span className="autocomplete-item-name">
                      <Highlight
                        keyword={this.state.criterion}
                        text={item.title || "PROMOTION NAME"}
                      />
                    </span>
                  </span>
                </li>
              );
            })}
          </ul>
        ) : (
          ""
        )}
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  criteria: store.wizard.criteria,
  validate: store.ui.validate,
  user: store.user.user
});

export default connect(mapStatesToProps)(CriterionPromotion);
