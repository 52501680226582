/**
 * Created by mehmetyurtar on 23/08/2017.
 */
"use strict";

import React from "react";

import { t } from "../../system/ui";

import { Radio, RadioGroup, Checkbox, CheckboxGroup } from "../fields";

let pushStatuses = {
  inFuture: {
    value: "inFuture",
    label: "In Future / Recurring"
  },
  sent: {
    value: "sent",
    label: "Sent"
  }
};

class PushFilters extends React.Component {
  constructor(props) {
    super(props);

    this.toggleStatus = this.toggleStatus.bind(this);
  }

  toggleStatus(value) {
    this.props.onPushFilterChange(value);
  }

  render() {
    return (
      <div className="status-filters">
        <RadioGroup
          name="status"
          selectedValue={this.props.selectedValue}
          onChange={this.toggleStatus}
          className="parent-filters"
        >
          {Object.keys(pushStatuses).map(item => {
            return (
              <Radio
                key={pushStatuses[item].value}
                value={pushStatuses[item].value}
                label={t(pushStatuses[item].label)}
              />
            );
          })}
        </RadioGroup>
      </div>
    );
  }
}

export default PushFilters;
