import React from 'react';
import { connect } from 'react-redux';

import { t } from '../system/ui';
import { setTitle } from '../system/document';

import { isSwitchAccount } from '../modules/auth/user';

import { getProductRelations } from '../modules/account/ajax';
import Snippet from '../components/snippet';

class RelationLookUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productRelationship: '',
      productRelationshipId: '',
    };
    this.getProductRelationShip = this.getProductRelationShip.bind(this);
    this.setProductRelationshipId = this.setProductRelationshipId.bind(this);
  }

  componentDidMount() {
    setTitle(t('Relation Lookup'));
  }

  getProductRelationShip() {
    if (!this.state.productRelationshipId) {
      this.setState({ productRelationship: '' });
    } else {
      const account = isSwitchAccount()
        ? this.props.switchedUser.account
        : this.props.user.account;
      getProductRelations(
        account.accountId,
        this.state.productRelationshipId,
        response => {
          if (response) {
            this.setState({
              productRelationship: JSON.stringify(response, null, 2),
            });
          } else {
            this.setState({ productRelationship: t('No Product') });
          }
        },
      );
    }
  }

  setProductRelationshipId(e) {
    this.setState({ productRelationshipId: e.currentTarget.value });
  }

  render() {
    return (
      <div>
        <h2 className='page-title'>{t('Relation Lookup')}</h2>

        <div className='page-forms' ref='forms'>
          <div className='page-content-block one-half'>
            <ol className='form-elements'>
              <li>
                <p>Product ID</p>
                <input
                  type='text'
                  name='product-relationship'
                  className='capping-capCount'
                  onChange={this.setProductRelationshipId}
                />
                <button
                  className='secondary-action'
                  type='button'
                  style={{ float: 'right' }}
                  onClick={this.getProductRelationShip}
                >
                  {t('Search')}
                </button>
              </li>
              {this.state.productRelationship && (
                <li className='form-element'>
                  <div style={{ height: '400px' }}>
                    <Snippet
                      value={this.state.productRelationship}
                      language={{
                        name: 'javascript',
                        json: true,
                      }}
                      options={{ readOnly: true, lineWrapping: false }}
                    />
                  </div>
                </li>
              )}
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

RelationLookUp.defaultProps = {
  options: {
    label: 'Relation Lookup',
    name: 'text',
  },
};

const mapStatesToProps = store => ({
  user: store.user.user,
  validate: store.ui.validate,
  language: store.ui.language,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(RelationLookUp);
