import React, { Component } from "react";

class ReactPlaceholder extends Component {
  renderLoading() {}
  render() {
    const { isLoading, children } = this.props;

    return (
      <React.Fragment>
        {isLoading && (
          <div className="react-placeholder">
            <div className="react-placeholder__line"></div>
            <div className="react-placeholder__line"></div>
            <div className="react-placeholder__line"></div>
            <div className="react-placeholder__line"></div>
          </div>
        )}

        {!isLoading && children}
      </React.Fragment>
    );
  }
}

export default ReactPlaceholder;
