import React, { useState, useCallback, useEffect } from 'react';
import './AdvancedConfig.scss';
import { t } from '../../../system/ui';
import Icons from '../../icons';
import Icon from '../../icon';
import { constantDevices, initialCampaignDetails } from './constants';
import Snippet from './Snippet';

/**
 * @name AdvancedConfig
 * @description AdvancedConfig component for the campaign
 * @param {object} templates - html, css, preJs, postJs templates as an object
 * @param {function} setTemplates - function to set the templates
 * @param {object} campaignDetails - campaign details object. Object elements -> title, campaignStatus
 * @param {object} activeTabs - active tabs object. Object elements -> key, label
 * @param {object} params - params object. Mustache details to show in the left bar.
 * @param {boolean} isShow - Advanced config visibility
 * @param {array} devices - devices array to show in the left bar
 * @param {function} saveAction - function to save the campaign
 * @param {function} onHide - function to hide the advanced config component
 * @return {JSX.Element}
 * @constructor
 */
const AdvancedConfig = ({
  templates,
  setTemplates,
  campaignDetails = initialCampaignDetails,
  activeTabs = [], // example: [{key: 'html', label: 'Edit HTML'}]
  params = {},
  isShow = false,
  devices = [], // example: ['PC', 'MOBILE', 'ANDROID', 'IOS']
  saveAction = null,
  onHide,
}) => {
  const [codeTab, setCodeTab] = useState(activeTabs[0].key);
  const [snippet, setSnippet] = useState(templates[codeTab]);

  const renderDevices = () => {
    return constantDevices
      .filter(
        deviceItem => devices.includes(deviceItem) || devices.includes('ALL'),
      )
      .map(deviceItem => {
        return (
          <>
            <span
              className={`device ${deviceItem.toLowerCase()}`}
              key={deviceItem}
            >
              <Icons color='#adaeb5' name={deviceItem.toLowerCase()} />
            </span>
            {deviceItem === 'MOBILE' && (
              <span className='device tablet' key='tablet'>
                <Icon name='tablet' color='#adaeb5' />
              </span>
            )}
          </>
        );
      });
  };

  const renderCampaignDetails = useCallback(() => {
    const { campaignStatus, title } = campaignDetails;
    if (campaignStatus || title || devices) {
      return (
        <h3 className='page-title'>
          {campaignStatus && (
            <span
              className={`status-label status-label-${campaignStatus.toLowerCase()}`}
            >
              {campaignStatus}
            </span>
          )}
          {title}
          {devices.length && <div>{renderDevices()}</div>}
        </h3>
      );
    }
    return null;
  }, [campaignDetails, devices]);

  const changeTab = tab => {
    setCodeTab(tab);
    setSnippet(templates[tab]);
  };

  const onSnippetChange = val => {
    setSnippet(val);
    setTemplates({ ...templates, [codeTab]: val });
  };

  useEffect(() => {
    if (snippet !== templates[codeTab]) {
      setSnippet(templates[codeTab]);
    }
  }, [templates]);

  return (
    <div className={`wizard-advanced-config ${!isShow && 'is-hidden'}`}>
      <div className='campaign-variables'>
        {renderCampaignDetails()}
        {saveAction && (
          <>
            <a
              className='button gotest-action five-column'
              onClick={() => saveAction('test')}
            >
              {t('Go Test')}
            </a>
            <a
              className='button tertiary-action five-column golive-action'
              onClick={() => saveAction('live')}
            >
              {t('Go Live')}
            </a>
          </>
        )}

        {Object.keys(params).map(item => (
          <div key={item} className='campaign-variable-set'>
            <h3 className='page-title'>{params[item].title}</h3>
            {params[item].variables.map(variable => (
              <span key={variable} className='campaign-variable'>
                {`{{${item}:${variable}}}`}
              </span>
            ))}
          </div>
        ))}
      </div>

      <div className='code-samples'>
        <nav className='code-sample-tabs'>
          {activeTabs.map(tab => (
            <a
              className={`code-sample-tab-nav ${codeTab === tab.key &&
                'current'}`}
              onClick={() => changeTab(tab.key)}
            >
              {t(tab.label)}
            </a>
          ))}
        </nav>
        <div className='code-sample-tab'>
          <Snippet code={snippet} tab={codeTab} onChange={onSnippetChange} />
        </div>
      </div>

      <a
        className='wizard-advanced-config-save tertiary-action'
        onClick={() => onHide()}
      >
        {t('Back To Wizard')}
      </a>
    </div>
  );
};

export default AdvancedConfig;
