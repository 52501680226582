/**
 * Created by mehmetyurtar on 10/17/17.
 */

import React, { Component } from "react";
import { connect } from "react-redux";

import { t } from "../../../../system/ui";
import { clone } from "../../../../system/object";

import Icons from "../../../../components/icons";
import { Select } from "../../../../components/fields";

const defaults = {
  action: "segment"
};

const selectOptions = {
  action: [
    { value: "segment", label: t("Segment") },
    { value: "unsegment", label: t("Unsegment") },
    { value: "toggle", label: t("Toggle") }
  ]
};

class SegmentationOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: clone(defaults)
    };

    this.handleVerticalPosition = this.handleVerticalPosition.bind(this);
    this.onChange = this.onChange.bind(this);
    this.update = this.update.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.setOptions = this.setOptions.bind(this);
    this.setVisibility = this.setVisibility.bind(this);
    this.setDefaults = this.setDefaults.bind(this);
  }

  componentDidMount() {
    this.update(this.props.campaign);
    this.setDefaults(this.props.options);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.update(newProps.campaign);
    }

    if (this.props.options !== newProps.options) {
      this.setDefaults(newProps.options);
    }
  }

  update(campaign) {
    let action = campaign.action || clone(defaults.action);

    this.updateValue(action, "action");
  }

  updateValue(newValue, type) {
    let values = this.state.values;
    values[type] = newValue;
    this.setState({ values });
  }

  onChange(selection, type) {
    let values = this.state.values;
    values[type] = selection.value;
    this.setState({ values });
  }

  handleVerticalPosition(selection) {
    this.onChange(selection, "action");
  }

  setDefaults(options) {
    if (this.props.editForm) {
      return;
    }

    let values = this.state.values;

    if (options.action.default) {
      values.action = options.action.default;
    }

    this.setState({ values });
  }

  setOptions(type) {
    let options = [];

    selectOptions[type].forEach(option => {
      if (
        this.props.options[type].values &&
        this.props.options[type].values.includes(option.value)
      ) {
        options.push(option);
      }
    });

    return options;
  }

  setVisibility(type) {
    let visibility = true;

    if (this.props.options[type] && this.props.options[type].show === false) {
      visibility = false;
    }

    return visibility;
  }

  render() {
    if (!this.props.isPositionVisible) {
      return false;
    }

    let action = this.setVisibility("action");
    let actionlOptions = this.setOptions("action");
    return (
      <div className="wizard-input-type-wrapper">
        <h3 className="wizard-input-type-title">
          <Icons name="position" />
          {t("Action")}
        </h3>
        <ol className="form-elements">
          <li className="row">
            {action ? (
              <Select
                name="action"
                className="item-field medium"
                label=""
                value={this.state.values.action}
                options={actionlOptions}
                onChange={this.handleVerticalPosition}
              />
            ) : (
              ""
            )}
          </li>
        </ol>
      </div>
    );
  }
}

SegmentationOptions.defaultProps = {
  options: {
    action: {
      show: true,
      label: "",
      default: "segment",
      values: ["segment", "unsegment", "toggle"]
    }
  }
};

const MapStatesToProps = store => ({
  isPositionVisible: store.wizard.isPositionVisible,
  editForm: store.wizard.editForm
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  SegmentationOptions
);
