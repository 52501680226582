import React from 'react';
import classes from './PushStats.module.scss';
import Icons from '../../../../icons';
import { t } from '../../../../../system/ui';
import { DateFormatter } from '../../../utils';

const PushStats = ({ push = {}, enumPush, isEmail = false }) => {
  const renderPush = key => {
    switch (key) {
      case 'emailClickRate':
      case 'emailConversionRate':
      case 'pushClickRate':
      case 'pushConversionRate':
        return `${push[key] || '-'}%`;
      case 'emailDelivered':
      case 'pushDelivered':
        return push[key] || '-';
      case 'emailLastClick':
      case 'pushLastClick':
        return push[key][0] ? (
          <div className={classes['push-stats-last-click']}>
            <div className={classes['push-stats-last-click-name']}>
              {push[key][0].name.substring(push[key][0].name.indexOf('-') + 1)}
            </div>
            <div className={classes['push-stats-last-click-value']}>
              {DateFormatter(push[key][0].value, 'DD-MM-YY')}
            </div>
          </div>
        ) : (
          '-'
        );
      default:
        return null;
    }
  };
  return (
    <div className={classes['push-stats-container']}>
      <div className={classes['push-stats-header']}>
        <div style={{ marginTop: isEmail ? '5px' : '0' }}>
          <Icons name={isEmail ? 'email' : 'pushIcon'} color='#c672a6' />
        </div>
        <div className={classes['push-stats-header-text']}>
          {t(`${isEmail ? 'Email Activity' : 'Push Activity'}`)}
        </div>
      </div>
      <div className={classes['push-stats-content']}>
        {Object.keys(push).length > 0 &&
          Object.keys(push).map(key => {
            return (
              <div className={classes['push-stats-item']}>
                <div className={classes['push-stats-item-title']}>
                  {enumPush[key] || '-'}
                </div>
                <div className={classes['push-stats-item-value']}>
                  {renderPush(key)}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PushStats;
