/**
 * Created by mehmetyurtar on 10/17/17.
 */
import React, { Component } from "react";
import { connect } from "react-redux";

import { uiActions } from "../../../actions";

import { TextField } from "../../../components/fields";
import { t } from "../../../system/ui";

class Segment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      segment: "name"
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    let segment = this.props.campaign.segment || "";
    this.updateValue(segment);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.segment);
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  onFieldChange(e) {
    this.updateValue(e.target.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <li className="campaign-subject">
        <TextField
          name="segment"
          label={t("Segment Name")}
          className="item-stacked one-whole label-emphasise"
          value={this.state.value}
          required={true}
          onChange={this.onFieldChange}
        />
      </li>
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  Segment
);
