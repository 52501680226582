/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { t } from "../../../system/ui";

import { uiActions, wizardActions } from "../../../actions";

class GoLiveButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: false,
      isSubmitting: false
    };

    this.validate = this.validate.bind(this);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      isSubmitting: newProps.save === "live"
    });
  }

  validate(trigger) {
    if (
      this.props.editForm ||
      this.props.campaignType === "PUSH_NOTIFICATION" ||
      this.props.isPush
    ) {
      let errors = document.querySelectorAll(".has-error");
      let visibleErrors = 0;
      errors.forEach(error => {
        if (error.offsetWidth > 0 || this.props.includeHiddens) {
          visibleErrors++;
        }
      });
      if (this.props.validate === true && visibleErrors === 0) {
        uiActions.confirmationDialog({
          title: "Update Campaign on Live?",
          content: this.props.isPush
            ? "This action will immediately effect campaign status."
            : null,
          onConfirm: wizardActions.saveLive
        });
      } else {
        uiActions.isValidating();
        this.props.includeHiddens &&
          !trigger &&
          uiActions.validatingTriggered();
        if (!this.state.clicked) {
          this.setState({ clicked: true }, () => this.validate(true));
        }
      }
    }
  }

  render() {
    let isPushNotification =
      this.props.campaignType === "PUSH_NOTIFICATION" || this.props.isPush;
    return (
      <a
        className={classNames(
          "button tertiary-action one-whole golive-action",
          {
            disabled: !isPushNotification && !this.props.editForm,
            "golive-action--disabled": this.state.isSubmitting
          }
        )}
        onClick={() => this.validate(false)}
      >
        {t("Go Live")}
        {this.state.isSubmitting && (
          <span className="page-progress page-progress--button" />
        )}
      </a>
    );
  }
}

const MapStatesToProps = store => ({
  validate: store.ui.validate,
  editForm: store.wizard.editForm,
  save: store.wizard.save
});

export default connect(MapStatesToProps)(GoLiveButton);
