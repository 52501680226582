import React from 'react';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import { t } from '../../../../system/ui';
import { TextField } from '../../../../components/fields';

const CampaignName = ({
  component,
  editCampaign,
  activeEmailDetails,
  emailType,
  readOnly,
}) => {
  const isDisabled = () => {
    if (readOnly) return true;

    return !(
      emailType === 'FLY' ||
      emailType === 'RECOMMENDATION' ||
      emailType.includes('eml_')
    );
  };

  return (
    <InputCardWrapper
      type='block'
      cardTitle='Campaign Name*'
      labelClass='cardTitle'
      labelFor='campaignName'
    >
      <TextField
        name='campaignTitle'
        id='campaignTitle'
        className='item-stacked one-whole'
        required
        placeholder={t(component.placeholder)}
        value={decodeURIComponent(activeEmailDetails?.scenarioName) || ''}
        onChange={e =>
          editCampaign('scenarioName', encodeURIComponent(e.target.value))
        }
        disabled={isDisabled()}
      />
    </InputCardWrapper>
  );
};

export default CampaignName;
