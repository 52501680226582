import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { uiActions, modalActions } from '../../../../actions';
import { t, notification } from '../../../../system/ui';
import { createEmptySegment, setDescription } from '../../../../reducers/ds';
import { getCampaign } from '../../../../reducers/ds/extraReducers';
import SavePreview from './SavePreview';
import { useHover } from '../../../../components/ruleBasedSegmentation/hooks/useHover';
import {
  CriteriaField,
  AndOrOperator,
  AddRemoveGroup,
} from '../../../../components/dynamicSegmentation/module-exports';
import { TextField } from '../../../../components/fields';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';

export const GroupItem = ({ dsFilterState, groupItem, groupIndex }) => {
  const [hoverRef, isHovered] = useHover();
  const groupOperator = dsFilterState?.operator;
  const isAloneGroup = dsFilterState?.criteriaGroups.length === 1;
  const groupCriteriaLength = groupItem?.criterias?.length;
  const hasLastCriteriaType = !!groupItem?.criterias?.[groupCriteriaLength - 1]
    ?.criteriaType;

  return (
    <>
      {groupIndex > 0 && (
        <AndOrOperator
          operatorType={groupOperator}
          position={[groupIndex]}
          type='group'
          tooltip={dsFilterState?.criteriaGroups.length > 2} // If activate tooltip, it will be shown only if there are more than 2 groups
        />
      )}

      <div className={`rbs-wizard-group ${isHovered ? 'hovered' : ''}`}>
        {groupItem?.criterias.map((criteriaItem, criteriaIndex) => {
          const criteriaOperator = groupItem?.operator;
          const isLast = criteriaIndex === groupItem?.criterias.length - 1;
          const isAloneCriteria = groupItem?.criterias.length === 1;

          return (
            <>
              {criteriaIndex > 0 && (
                <AndOrOperator
                  operatorType={criteriaOperator}
                  position={[groupIndex, criteriaIndex]}
                  type='criteria'
                  tooltip={groupCriteriaLength > 2} // If activate tooltip, it will be shown only if there are more than 2 criteria
                />
              )}
              <CriteriaField
                criteriaType={criteriaItem.criteriaType}
                statements={criteriaItem.statements}
                position={[groupIndex, criteriaIndex]}
                isLast={isLast}
                isAloneCriteria={isAloneCriteria}
                isAloneGroup={isAloneGroup}
              />
            </>
          );
        })}

        {groupCriteriaLength >= 1 && hasLastCriteriaType && (
          <AddRemoveGroup
            position={[groupIndex, 0]} // criteriaPosition is not necessary here
            isAloneCriteria={groupCriteriaLength === 1}
            hoverRef={hoverRef}
          />
        )}
      </div>
    </>
  );
};

GroupItem.propTypes = {
  dsFilterState: PropTypes.shape({
    operator: PropTypes.string,
    criteriaGroups: PropTypes.shape([]),
  }).isRequired,
  groupItem: PropTypes.shape({
    operator: PropTypes.string,
    criterias: PropTypes.shape([]),
  }).isRequired,
  groupIndex: PropTypes.number.isRequired,
};

const AddOrEdit = ({ params }) => {
  // Destructured props / actions
  const { mode, segmentId } = params;
  const { showBreadcrumb } = uiActions;
  const { openModal } = modalActions;

  const dispatch = useDispatch();
  const segmentName = useSelector(state => state.ds.wizardFilter.segmentName);
  const dsFilterState = useSelector(state => state.ds.wizardFilter.data);
  const description = useSelector(state => state.ds.wizardFilter.description);

  useEffect(() => {
    if (mode === 'edit' || mode === 'duplicate') {
      dispatch(getCampaign(segmentId));
    } else {
      dispatch(createEmptySegment());
    }

    showBreadcrumb({
      home: {
        name: 'Customer Data Management',
        icon: 'audienceIcon',
        iconSvg: true,
        slug: '/audience/segmentation/view-all',
      },
      before: {
        name: 'Dynamic Segmentation',
        slug: '/audience/dynamic-segmentation/',
      },
      current: {
        name:
          mode === 'add'
            ? 'Add Dynamic Segmentation'
            : 'Edit Dynamic Segmentation',
      },
    });
  }, [mode, segmentId]);

  const openSavePreviewModal = status => {
    const controlledSegmentName = mode !== 'add' ? segmentName : '';

    function content() {
      return (
        <SavePreview
          segmentName={controlledSegmentName}
          description={description}
          criteriaData={dsFilterState}
          status={status}
          mode={mode}
          id={segmentId}
        />
      );
    }

    openModal({
      content,
      className: 'rbs-wizard-save-preview-modal save-preview',
    });
  };

  const validateAndSave = status => {
    const errors = document.querySelectorAll('.has-error');

    let errorMessage = '';
    if (errors.length > 0) {
      errorMessage = 'Please fill in all required fields.';
    } else if (
      dsFilterState?.criteriaGroups.length === 1 &&
      dsFilterState?.criteriaGroups[0]?.criterias.length === 1 &&
      dsFilterState?.criteriaGroups[0]?.criterias[0]?.criteriaType === ''
    ) {
      errorMessage = 'Please add at least one criteria.';
    }

    if (errorMessage) {
      const content = () => <p>{errorMessage}</p>;
      notification({
        content,
        className: 'notification-fail',
      });
    } else {
      openSavePreviewModal(status);
    }
  };

  return (
    <div className='rbs-wizard'>
      <h2
        className='page-title'
        style={{ marginBottom: '30px', color: '#dc3322' }}
      >
        {t(mode === 'edit' ? segmentName : 'Create Dynamic Segment')}
      </h2>
      <InputCardWrapper
        type='block--bottom'
        cardTitle={t('Description (Optional)')}
        labelFor='description'
        leftCharacter={100 - description.length}
      >
        <TextField
          id='description'
          type='text'
          name='description'
          className='item-stacked one-whole'
          placeholder={t('Type here...')}
          value={description}
          onChange={e => dispatch(setDescription(e.target.value))}
          maxLength={100}
        />
      </InputCardWrapper>
      {dsFilterState?.criteriaGroups?.map((groupItem, groupIndex) => (
        <GroupItem
          dsFilterState={dsFilterState}
          groupItem={groupItem}
          groupIndex={groupIndex}
        />
      ))}
      <div className='rbs-wizard-buttons'>
        <a
          className='button gotest-action one-whole'
          onClick={() => validateAndSave('Test')}
        >
          {t('Save as Draft')}
        </a>
        <a
          className='button tertiary-action one-whole golive-action'
          onClick={() => validateAndSave('Live')}
        >
          {t(mode === 'edit' ? 'Update Segment' : 'Create Segment')}
        </a>
      </div>
    </div>
  );
};

AddOrEdit.propTypes = {
  params: PropTypes.shape({
    mode: PropTypes.string.isRequired,
    segmentId: PropTypes.string,
  }).isRequired,
};

export default AddOrEdit;
