import React from "react";

class IconCheck extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#fff",
      bgColor: "#52c866"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }
  componentWillReceiveProps() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color,
      bgColor = this.props.bgColor ? this.props.bgColor : this.state.bgColor;

    this.setState({ width, height, color, bgColor });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox="0 0 24 20"
        className="svg-icon"
        style={{
          border: "1px solid #f0eff9",
          borderRadius: "20px",
          padding: "4px",
          backgroundColor: this.state.bgColor
        }}
      >
        <polygon
          points="27.005 9.629 23.183 5.807 12.305 16.685 6.817 11.246 2.995 15.117 12.305 24.378"
          fill={this.state.color}
          transform="translate(-3 -5)"
        />
      </svg>
    );
  }
}

export default IconCheck;
