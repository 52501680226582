/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { isSuperUser } from '../../../../modules/auth/user';
import { t } from '../../../../system/ui';
import { modalActions } from '../../../../actions';
import Icon from '../../../../components/icon';
import Icons from '../../../../components/icons';
import CreateSegmentGroup from './CreateSegmentGroup/CreateSegmentGroup';

/* 
  ? This component is used to render  the Group actions of the dynamic segmentation. 
  ? It is used in hovered dropdown menu.
*/

// ? Action carries the action, name and icon of the sub action. Additionally, it can have a superUserOnly property to check if the action is available only to super users.

const actions = [
  { name: 'Add Segment', icon: 'plus', action: 'addSegment' },
  {
    name: 'Change Group Name',
    icon: 'renew',
    action: 'changeGroupName',
    iconSvg: true,
  },
];

const dsActions = (campaign, name) => {
  const [dropUp, setDropUp] = useState(false);
  const actionMenu = useRef(null);

  const { openModal } = modalActions;

  const getListClass = () => {
    if (dropUp) {
      return 'widget-nav-popup action-nav-popup drop-up';
    }
    return 'widget-nav-popup action-nav-popup';
  };

  const getControlledActions = () => {
    if (dropUp) {
      return actions.slice().reverse();
    }
    return actions;
  };

  const handleScroll = () => {
    const partOfDocHeight = (document.documentElement.offsetHeight * 70) / 100;
    const listTop = actionMenu.current.getBoundingClientRect().top;
    setDropUp(listTop > partOfDocHeight);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const groupNameHandler = useCallback(() => {
    const title = 'Change Group Name';
    openModal({
      title,
      content: () => (
        <CreateSegmentGroup
          mode='edit'
          id={campaign.campaign}
          groupName={campaign.groupName}
        />
      ),
      className: 'detail-preview',
    });
  }, [campaign]);

  const segmentAddHandler = useCallback(() => {
    browserHistory.push({
      pathname: `/audience/dynamic-segmentation/add/${campaign.campaign}`,
    });
  }, [campaign]);

  const handleClick = action => {
    if (action === 'addSegment') {
      return segmentAddHandler();
    }
    if (action === 'changeGroupName') {
      return groupNameHandler();
    }

    return null;
  };

  return (
    <nav
      className='widget-nav action-nav'
      aria-haspopup='true'
      ref={actionMenu}
    >
      <a className='secondary-action'>{t('Actions')}</a>

      <ul className={getListClass()}>
        {getControlledActions().map(action => {
          if (!isSuperUser() && action.superUserOnly) {
            return null;
          }

          if (
            action.action === 'addToArchive' &&
            campaign.status === 'PASSIVE'
          ) {
            return null;
          }

          return (
            <li key={campaign.segmentId}>
              <a
                className='widget-nav-popup-item action-nav-popup-item'
                onClick={() => handleClick(action.action, campaign, name)}
              >
                {action.iconSvg ? (
                  <Icons name={action.icon} color='#fff' />
                ) : (
                  <Icon name={action.icon} />
                )}
                {t(action.name)}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

dsActions.propTypes = {
  campaign: PropTypes.shape({
    campaign: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  instanceId: PropTypes.string,
};

dsActions.defaultProps = {
  instanceId: null,
};

export default dsActions;
