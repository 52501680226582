import { $http } from 'system/ajax';
import * as facetedSearchActions from 'constants/actions/faceted-search';
import { getEndPoint, getRestEndPoint } from 'system/api';
import { getApiKey } from 'modules/auth/user';
import { uiActions } from 'actions/index';
import { store } from '../store';
import { isMultiAccountUser, isSwitchAccount } from '../modules/auth/user';

function findReplaceString(string, find, replace) {
  if (/[a-zA-Z_]+/g.test(string)) {
    return string.replace(new RegExp(`{(?:\\s+)?(${find})(?:\\s+)?}`), replace);
  }
  throw new Error(
    'Find statement does not match regular expression: /[a-zA-Z_]+/',
  );
}

function replaceAll(string, mapObj) {
  let replacedStr = string;
  Object.keys(mapObj).forEach(key => {
    replacedStr = findReplaceString(replacedStr, key, mapObj[key]);
  });
  return replacedStr;
}

const request = (facetedSearchEndpoint, replaceParams, queryParams) => {
  if (replaceParams) {
    facetedSearchEndpoint = replaceAll(facetedSearchEndpoint, replaceParams);
  }
  const apiKey = getApiKey();
  let url = `${facetedSearchEndpoint}?apiKey=${apiKey}`;
  if (queryParams) url += queryParams;
  const options = {};
  if (isSwitchAccount() && !isMultiAccountUser()) {
    const { switchedUser } = store.getState().switchedUser;
    if (switchedUser.username.length > 0)
      options.switchUser = switchedUser.username;
  }
  return $http(url, options);
};

const requestToRest = (endpoint, replaceParams, queryParams) => {
  const facetedSearchEndpoint = getRestEndPoint(endpoint);
  return request(facetedSearchEndpoint, replaceParams, queryParams);
};

const requestToSecureApi = (endpoint, replaceParams, queryParams) => {
  const facetedSearchEndpoint = getEndPoint(endpoint);
  return request(facetedSearchEndpoint, replaceParams, queryParams);
};

const getFacetedSearchList = () => {
  return dispatch => {
    dispatch({
      type: facetedSearchActions.FACETED_SEARCH_LOADING,
    });
    return requestToRest('facetedSearch/get')
      .get()
      .then(facetedSearchList =>
        dispatch({
          type: facetedSearchActions.FACETED_SEARCH_SUCCESS,
          data: facetedSearchList,
        }),
      )
      .catch(error =>
        dispatch({ type: facetedSearchActions.FACETED_SEARCH_ERROR, error }),
      );
  };
};

const getFacetedSearchByInstanceId = (instanceId, opts, callBack) => {
  requestToRest('facetedSearch/getSingle', { instanceId })
    .get(opts)
    .then(facetedSearch => callBack(facetedSearch))
    .catch(() => callBack(null));
};

const getFacetList = instanceId => {
  return dispatch => {
    dispatch({
      type: facetedSearchActions.FACETED_SEARCH_GET_FACET_LIST_LOADING,
    });
    const queryParams = `&instanceId=${instanceId}`;
    return requestToRest('facetedSearch/facets', null, queryParams)
      .get()
      .then(facets => {
        dispatch({
          type: facetedSearchActions.FACETED_SEARCH_GET_FACET_LIST_SUCCESS,
          data: facets,
        });
      })
      .catch(error => {
        dispatch({
          type: facetedSearchActions.FACETED_SEARCH_GET_FACET_LIST_ERROR,
          error,
        });
      });
  };
};

const updateCampaignForm = object => ({
  type: facetedSearchActions.FACETED_SEARCH_FORM_UPDATE,
  object,
});

const updateBannersCopy = banners => ({
  type: 'UPDATE_BANNER_COPY',
  bannersCopy: banners,
});

const updateBanner = instanceId => {
  return dispatch => {
    return requestToRest('searchbanner/get', null, `&instanceId=${instanceId}`)
      .get()
      .then(banners => {
        dispatch({
          type: 'UPDATE_BANNERS',
          banners,
        });
      })
      .catch(error =>
        dispatch({ type: facetedSearchActions.FACETED_SEARCH_ERROR, error }),
      );
  };
};

const toggleAddFacetModal = () => ({
  type: facetedSearchActions.FACETED_SEARCH_TOGGLE_ADD_FACET_MODAL,
});

const saveCampaign = ({ campaignObject, banners }) => {
  return dispatch => {
    dispatch({ type: facetedSearchActions.FACETED_SEARCH_SAVE_LOADING });
    return requestToRest('facetedSearch/add')
      .post(campaignObject, { type: 'json' })
      .then(response => {
        requestToRest('searchbanner/upsert', {
          instanceId: response.instanceId,
        })
          .post(
            banners.map(banner => ({
              ...banner,
              instanceId: response.instanceId,
            })),
            { type: 'json' },
          )
          .then(bannersResponse => {
            dispatch({
              type: facetedSearchActions.FACETED_SEARCH_SAVE_SUCCESS,
              response,
              banners: bannersResponse,
            });
          })
          .catch(error => {
            dispatch({
              type: facetedSearchActions.FACETED_SEARCH_SAVE_ERROR,
              error,
            });
          });
      })
      .catch(error => {
        dispatch({
          type: facetedSearchActions.FACETED_SEARCH_SAVE_ERROR,
          error,
        });
      });
  };
};

const facetedRevertUpdate = (campaign, instanceId) => {
  return requestToRest('facetedSearch/update', { instanceId }).put(campaign, {
    type: 'json',
  });
};

const updateCampaign = ({ campaignObject, banners }, instanceId) => {
  return dispatch => {
    dispatch({ type: facetedSearchActions.FACETED_SEARCH_SAVE_LOADING });
    return requestToRest('facetedSearch/update', { instanceId })
      .put(campaignObject, { type: 'json' })
      .then(response => {
        requestToRest('searchbanner/upsert', { instanceId })
          .post(banners, { type: 'json' })
          .then(bannerResponse => {
            dispatch({
              type: facetedSearchActions.FACETED_SEARCH_SAVE_SUCCESS,
              response,
              banners: bannerResponse,
            });
          })
          .catch(error => {
            dispatch({
              type: facetedSearchActions.FACETED_SEARCH_SAVE_ERROR,
              error,
            });
          });
      })
      .catch(error => {
        dispatch({
          type: facetedSearchActions.FACETED_SEARCH_SAVE_ERROR,
          error,
        });
      });
  };
};

const updateCampaignStatus = (instanceId, status, callback = null) => {
  return dispatch => {
    dispatch({
      type: facetedSearchActions.FACETED_SEARCH_CAMPAIGN_UPDATE_STATUS_LOADING,
    });
    return requestToRest('facetedSearch/updateStatus', { instanceId, status })
      .post({}, { type: 'json' })
      .then(() => {
        dispatch({
          type:
            facetedSearchActions.FACETED_SEARCH_CAMPAIGN_UPDATE_STATUS_SUCCESS,
          instanceId,
        });
        if (callback) callback();
      })
      .catch(error =>
        dispatch({
          type:
            facetedSearchActions.FACETED_SEARCH_CAMPAIGN_UPDATE_STATUS_ERROR,
          error,
        }),
      );
  };
};

const archiveCampaign = (instanceId, callBack) => {
  return dispatch => {
    dispatch(updateCampaignStatus(instanceId, 'ARCHIVE', callBack));
    dispatch({
      type: facetedSearchActions.FACETED_SEARCH_ARCHIVE_CAMPAIGN,
      instanceId,
    });
  };
};

const getSingleReport = (instanceId, startDate, endDate, callback) => {
  const queryParams = `&start=${startDate}&end=${endDate}&interval=total&limit=10&sortBy=revenue&v=${new Date().getMilliseconds()}`;
  return dispatch => {
    uiActions.isLoading();
    dispatch({
      type: facetedSearchActions.FACETED_SEARCH_GET_SINGLE_REPORT_LOADING,
    });
    return requestToRest('facetedSearch/campaignReport', null, queryParams)
      .post(instanceId, { type: 'json' })
      .then(response => {
        dispatch({
          type: facetedSearchActions.FACETED_SEARCH_GET_SINGLE_REPORT_SUCCESS,
          data: response,
        });
        if (callback) callback(response);
        uiActions.isLoaded();
      })
      .catch(error => {
        uiActions.isLoaded();
        dispatch({
          type: facetedSearchActions.FACETED_SEARCH_GET_SINGLE_REPORT_ERROR,
          error,
        });
      });
  };
};

const getAllReports = (startDate, endDate, callback) => {
  const queryParams = `&start=${startDate}&end=${endDate}&interval=total&limit=10&sortBy=revenue&v=${new Date().getMilliseconds()}`;
  return dispatch => {
    uiActions.isLoading();
    dispatch({
      type: facetedSearchActions.FACETED_SEARCH_GET_ALL_REPORTS_LOADING,
    });
    return requestToRest('facetedSearch/accountReport', null, queryParams)
      .get(null, { type: 'json' })
      .then(response => {
        dispatch({
          type: facetedSearchActions.FACETED_SEARCH_GET_ALL_REPORTS_SUCCESS,
          data: response,
        });
        if (callback) callback(response);
        uiActions.isLoaded();
      })
      .catch(error => {
        uiActions.isLoaded();
        dispatch({
          type: facetedSearchActions.FACETED_SEARCH_GET_ALL_REPORTS_ERROR,
          error,
        });
      });
  };
};

const updateReportData = data => {
  return { type: 'UPDATE_REPORT_DATA', data };
};

const createDraftCopy = (instanceId, draftName) => {
  const queryParams = `&name=${draftName}`;
  return requestToRest(
    'facetedSearch/draft',
    { instanceId },
    queryParams,
  ).get();
};

export {
  getFacetedSearchList,
  getFacetedSearchByInstanceId,
  toggleAddFacetModal,
  updateCampaignForm,
  getFacetList,
  saveCampaign,
  updateCampaign,
  updateCampaignStatus,
  archiveCampaign,
  getSingleReport,
  updateReportData,
  createDraftCopy,
  updateBannersCopy,
  updateBanner,
  getAllReports as getAllFacetedReports,
  requestToRest as facetedSearchRequest,
  requestToSecureApi,
  facetedRevertUpdate,
};
