import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const cpMailIcon = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 14 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.50004 0.166687H11.5C12.6554 0.166687 13.5995 1.07099 13.6633 2.21041L13.6667 2.33335V8.66669C13.6667 9.82202 12.7624 10.7662 11.623 10.83L11.5 10.8334H2.50004C1.34469 10.8334 0.400541 9.92902 0.336801 8.78962L0.333374 8.66669V2.33335C0.333374 1.178 1.23767 0.233854 2.37709 0.170114L2.50004 0.166687H11.5H2.50004ZM12.6667 3.74869L7.23291 6.60915C7.10797 6.67489 6.96211 6.68429 6.83124 6.63735L6.76717 6.60915L1.33337 3.74935V8.66669C1.33337 9.27882 1.80478 9.78082 2.40435 9.82949L2.50004 9.83335H11.5C12.1122 9.83335 12.6142 9.36195 12.6628 8.76235L12.6667 8.66669V3.74869ZM11.5 1.16669H2.50004C1.88793 1.16669 1.38591 1.63809 1.33724 2.23767L1.33337 2.33335V2.61935L7.00004 5.60169L12.6667 2.61869V2.33335C12.6667 1.72124 12.1953 1.21923 11.5957 1.17055L11.5 1.16669Z'
        fill={color}
      />
    </svg>
  );
};

cpMailIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

cpMailIcon.defaultProps = {
  width: '14px',
  height: '11px',
  color: '#979797',
};

export default cpMailIcon;
