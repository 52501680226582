import React from 'react';

class IconPc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: '23px',
      height: '16px',
      color: '#eee',
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    const width = this.props.width ? this.props.width + "px" : this.state.width;
    const height = this.props.height ? this.props.height + "px" : this.state.height;
    const color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={this.state.width}
        height={this.state.height}
        viewBox='0 0 23 16'
        fill='none'
        className='svg-icon'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          className='svg-path'
          d='M4.49533 2.96978C4.49533 2.74632 4.58557 2.55079 4.76606 2.38319C4.94655 2.2156 5.17216 2.1318 5.44289 2.1318H18.3026C18.5734 2.1318 18.799 2.2156 18.9795 2.38319C19.1599 2.55079 19.2502 2.74632 19.2502 2.96978V10.6791H21.1002V2.96978C21.1002 2.27146 20.8295 1.67091 20.288 1.16812C19.7465 0.665335 19.0847 0.41394 18.3026 0.41394H5.44289C4.66077 0.41394 3.99899 0.665335 3.45753 1.16812C2.91606 1.67091 2.64533 2.27146 2.64533 2.96978V10.6791H4.49533L4.49533 2.96978ZM10.9478 4.68763C10.9478 4.93903 11.038 5.14852 11.2185 5.31612C11.399 5.48371 11.6171 5.56751 11.8728 5.56751C12.1285 5.56751 12.3466 5.48371 12.527 5.31612C12.7075 5.14852 12.7978 4.94601 12.7978 4.70858C12.7978 4.47115 12.7075 4.26864 12.527 4.10105C12.3466 3.93345 12.1285 3.84965 11.8728 3.84965C11.6171 3.84965 11.399 3.93345 11.2185 4.10105C11.038 4.26864 10.9478 4.46417 10.9478 4.68763ZM0.840454 13.235V12.397H22.9051V13.235C22.9051 13.9612 22.6419 14.5688 22.1154 15.0576C21.589 15.5464 20.9348 15.7908 20.1526 15.7908H3.59289C2.81078 15.7908 2.15651 15.5464 1.63009 15.0576C1.10367 14.5688 0.840454 13.9612 0.840454 13.235Z'
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default IconPc;
