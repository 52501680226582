import { getRockstarScore } from '../campaigns/utils';

export const searchAlgorithms = (data, type = 'Pc') => {
  const transformedData = [];
  data.forEach(criteria => {
    const transformedItem = {};
    transformedItem.type = criteria.type.split('-')[0].toUpperCase();
    if (criteria.values !== undefined) {
      if (type === 'Pc')
        transformedItem.itemCount = criteria.values.itemCount.value;
      else transformedItem.itemCount = criteria.values.mobileItemCount.value;
      transformedItem.clickable =
        criteria.values.criterion !== undefined
          ? criteria.values.criterion.clickable
          : '';
      transformedItem.categoryTreeView =
        criteria.values.criterion !== undefined
          ? criteria.values.criterion.categoryTreeView
          : '';
      transformedItem.highlight =
        criteria.values.criterion !== undefined
          ? criteria.values.criterion.highlight
          : false;
    } else {
      transformedItem.type = criteria.type.split('-')[0].toUpperCase();
      if (type === 'Pc') transformedItem.itemCount = criteria.itemCount;
      else transformedItem.itemCount = criteria.mobileItemCount;

      transformedItem.clickable =
        criteria.clickable !== undefined ? criteria.clickable : '';
      transformedItem.highlight =
        criteria.highlight !== undefined ? criteria.highlight : '';
      transformedItem.categoryTreeView =
        criteria.categoryTreeView !== undefined
          ? criteria.categoryTreeView
          : '';
    }
    transformedData.push(transformedItem);
  });
  return transformedData;
};

export const personalizationRecommendations = (data, sourceEmail = false) => {
  const transformedData = [];
  data.forEach(criteria => {
    const transformedItem = {};
    transformedItem.type = criteria.type.toUpperCase();
    if (transformedItem.type !== 'PRODUCT') {
      transformedItem.timeFrame = criteria.values.timeFrame
        ? criteria.values.timeFrame.value
        : 'THIS_WEEK';
      transformedItem.itemCount = criteria?.values?.itemCount?.value;
      transformedItem.score = getRockstarScore(
        criteria?.values?.criterion?.value,
      );
      if (transformedItem.type === 'INTELLIGENT') {
        transformedItem.source = criteria?.values?.criterion?.value;
      } else {
        if (sourceEmail) {
          transformedItem.recommendationSource =
            criteria?.values?.criterion?.value;
        } else {
          transformedItem[criteria.type] = criteria?.values?.criterion?.value;
        }
        if (criteria.isPush || criteria?.isEmail) {
          transformedItem.source = criteria?.values?.criterion?.value;
        }
      }
    } else {
      transformedItem.productId = criteria.values?.productId?.value;
    }
    if (transformedItem.timeFrame === 'NONE') {
      transformedItem.score = '';
      transformedItem.timeFrame = 'CURRENT';
    }
    transformedData.push(transformedItem);
  });
  return transformedData;
};

export const personalizationRecommendationExclusions = data => {
  const transformedData = [];
  data.forEach(exclusion => {
    const params = {};

    Object.keys(exclusion.values).forEach(value => {
      params[value] = exclusion.values[value].value;
    });

    params.type = exclusion.type.toUpperCase();

    transformedData.push(params);
  });
  return transformedData;
};
