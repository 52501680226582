import React, { Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";
import { SortableHandle } from "react-sortable-hoc";
import Tooltip from "../../tooltip";
import { t } from "../../../system/ui";
import Icon from "../../icon";
import Icons from "../../icons";
import {
  intelligentOptionsBeforeSearchBrand,
  intelligentOptionsBeforeSearchCategories,
  intelligentOptionsBeforeSearchKeyword,
} from "../../../constants/datamaps/wizard";
import {
  isSuperUser,
} from "../../../modules/auth/user";

let types = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "30", label: "30" },
  { value: "35", label: "35" },
  { value: "40", label: "40" },
  { value: "45", label: "45" },
  { value: "50", label: "50" },
];

let adminTypes = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
  { value: "30", label: "30" },
  { value: "35", label: "35" },
  { value: "40", label: "40" },
  { value: "45", label: "45" },
  { value: "50", label: "50" },
  { value: "55", label: "55" },
  { value: "60", label: "60" },
  { value: "65", label: "65" },
  { value: "70", label: "70" },
  { value: "75", label: "75" },
  { value: "100", label: "100" },
  { value: "125", label: "125" },
  { value: "150", label: "150" },
  { value: "175", label: "175" },
  { value: "200", label: "200" },
  { value: "225", label: "225" },
  { value: "250", label: "250" },
];

class SearchCriterionIntelligent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {itemCount:false};

    this.onChange = this.onChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSelectInputChange = this.onSelectInputChange.bind(this);
  }

  onInputChange(selectedOption) {
    this.setState({
      product: selectedOption
        ? parseInt(selectedOption.value) > 250
          ? "250"
          : selectedOption.value
        : "",
    });
  }


  onSelectInputChange(inputValue) {
    let maxCount = isSuperUser() ? 250 : 50;
    if (parseInt(inputValue) > maxCount) {
      return maxCount.toString();
    }
    return inputValue;
  }

  onChange(newItemCount) {
    let currentCriterion = this.refs.criterion.value;
    this.setState({
      tempAlg: currentCriterion,
    });

    if (newItemCount) {
      this.setState(
        {
          itemCount: newItemCount ? newItemCount.value : this.state.itemCount,
        },
        () => {
          this.props.onCriteriaChange(this.refs.inputs, this.props.id);
        }
      );
    }

  }

  render() {
    const DragHandle = SortableHandle(() => (
      <span className="draghandler">
        <Icons name="burger" color="#e8e7f2" />
      </span>
    ));
    let currentIntelligents = {};
    if (this.props.mainType === "CATEGORY")
      currentIntelligents = Object.assign(
        {},
        intelligentOptionsBeforeSearchCategories
      );
    else if (this.props.mainType === "KEYWORD")
      currentIntelligents = Object.assign(
        {},
        intelligentOptionsBeforeSearchKeyword
      );
    else if (this.props.mainType === "BRAND")
      currentIntelligents = Object.assign(
        {},
        intelligentOptionsBeforeSearchBrand
      );

    let defaultCriterion = "",
      itemCount = 4;
    if (Object.keys(this.props.values).length > 0) {
      defaultCriterion =
        (this.props.values.criterion && this.props.values.criterion.value) ||
        "";

      itemCount =
        (this.props.values.itemCount && this.props.values.itemCount.value) || 4;
    }

    let sortedAlgorithmKeys = Object.keys(currentIntelligents).sort(
      (algorithmA, algorithmB) => {
        if (algorithmOrders[algorithmA] && algorithmOrders[algorithmB]) {
          let a = algorithmOrders[algorithmA][pageType];
          let b = algorithmOrders[algorithmB][pageType];
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        }
        return 0;
      }
    );

    let sortedAlgorithms = {};
    Object.keys(sortedAlgorithmKeys).forEach((algorithm) => {
      if (currentIntelligents) {
        sortedAlgorithms[sortedAlgorithmKeys[algorithm]] =
          currentIntelligents[sortedAlgorithmKeys[algorithm]];
      }
    });

    currentIntelligents = sortedAlgorithms;

    return (
      <li className="wizard-criterion" ref="inputs">
        <DragHandle />
        <span className="criteria-type">
          <Tooltip content={t("Intelligent")} alignment="left">
            <Icons name="brain" color="#5A7391" />
          </Tooltip>
        </span>
        <label className={"item item-field is-select criteria-name-search"}>
          <select
            name="criterion"
            ref="criterion"
            className="one-whole criteria-field criteria-field-search"
            defaultValue={defaultCriterion}
            onChange={this.onChange}
          >
            {Object.keys(currentIntelligents).map((item) => {
              return (
                <option key={item} value={item}>
                  {t(currentIntelligents[item])}
                </option>
              );
            })}
          </select>
        </label>

        <label className={"item item-field is-select criteria-item-count"}>
          <span className="item-label">{this.props.mainType.toLowerCase()}</span>
          {isSuperUser() ? (
            <Select.Creatable
              value={
                this.state.itemCount ? this.state.itemCount : itemCount
              }
              options={adminTypes}
              name="itemCount"
              clearable={false}
              searchable={true}
              onChange={this.onChange}
              onInputChange={this.onSelectInputChange}
              className="one-whole criteria-field criteria-field-search"
              isValidNewOption={(option) => {
                return (
                  option.label !== "" &&
                  typeof option.label !== "undefined" &&
                  parseInt(option.label) < 251
                );
              }}
            />
          ) : (
            <Select
              value={
                this.state.itemCount ? this.state.itemCount : itemCount
              }
              options={ types}
              name="itemCount"
              clearable={false}
              searchable={true}
              onChange={this.onChange}
              className="one-whole criteria-field criteria-field-search"
              onInputChange={this.onSelectInputChange}
            />
          )}
        </label>

        <a
        onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
        className={"cancel-action"}
      >
        <Icons name="crossCircle" />
      </a>

      </li>
    );
  }
}

const mapStatesToProps = (store) => ({
  criteria: store.searchWizard.criteria,
});

SearchCriterionIntelligent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStatesToProps)(SearchCriterionIntelligent);
