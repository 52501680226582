import React from 'react';
import { fixedPxValue } from './pcBigger';

const PcCanvas = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 516 293'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M60.8516 0H454.598C461.289 0 466.713 5.45037 466.713 12.1738V276.676C466.713 283.4 461.289 288.85 454.598 288.85H60.8516C54.1606 288.85 48.7364 283.4 48.7364 276.676V12.1738C48.7364 5.45037 54.1606 0 60.8516 0Z'
        fill='#ECECEC'
      />
      <g filter='url(#filter0_ii_0_3)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.8516 1.1067H454.598C460.68 1.1067 465.611 6.06159 465.611 12.1737V276.676C465.611 282.788 460.68 287.743 454.598 287.743H60.8516C54.7688 287.743 49.8378 282.788 49.8378 276.676V12.1737C49.8378 6.06159 54.7688 1.1067 60.8516 1.1067Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M60.8516 1.1067H454.598C460.68 1.1067 465.611 6.06159 465.611 12.1737V276.676C465.611 282.788 460.68 287.743 454.598 287.743H60.8516C54.7688 287.743 49.8378 282.788 49.8378 276.676V12.1737C49.8378 6.06159 54.7688 1.1067 60.8516 1.1067Z'
          fill='white'
        />
      </g>
      <g filter='url(#filter1_ii_0_3)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.20277 280.273H513.797C515.014 280.273 516 281.264 516 282.486V288.573H0V282.486C0 281.264 0.986216 280.273 2.20277 280.273Z'
          fill='white'
        />
      </g>
      <g filter='url(#filter2_ii_0_3)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.20277 281.38H513.797C514.405 281.38 514.899 281.875 514.899 282.486V287.466H1.10138V282.486C1.10138 281.875 1.59449 281.38 2.20277 281.38Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.20277 281.38H513.797C514.405 281.38 514.899 281.875 514.899 282.486V287.466H1.10138V282.486C1.10138 281.875 1.59449 281.38 2.20277 281.38Z'
          fill='url(#paint0_linear_0_3)'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 288.573H516L513.813 289.745C509.826 291.882 505.375 293 500.856 293H15.1441C10.6245 293 6.17443 291.882 2.18658 289.745L0 288.573Z'
        fill='#ECECEC'
      />
      <g filter='url(#filter3_i_0_3)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.65207 288.573H514.348L511.833 289.656C508.406 291.132 504.715 291.893 500.986 291.893H15.0135C11.2846 291.893 7.59447 291.132 4.16704 289.656L1.65207 288.573Z'
          fill='#F3F3F3'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.65207 288.573H514.348L511.833 289.656C508.406 291.132 504.715 291.893 500.986 291.893H15.0135C11.2846 291.893 7.59447 291.132 4.16704 289.656L1.65207 288.573Z'
          fill='url(#paint1_linear_0_3)'
        />
      </g>
      <g filter='url(#filter4_i_0_3)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M221.104 280.273H295.172V280.55C295.172 283.606 292.706 286.083 289.665 286.083H226.61C223.569 286.083 221.104 283.606 221.104 280.55V280.273Z'
          fill='#F4F4F4'
        />
      </g>
      <defs>
        <filter
          id='filter0_ii_0_3'
          x='49.8378'
          y='1.1067'
          width='415.774'
          height='296.636'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='30' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'
          />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_0_3' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='10' />
          <feGaussianBlur stdDeviation='20' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_innerShadow_0_3'
            result='effect2_innerShadow_0_3'
          />
        </filter>
        <filter
          id='filter1_ii_0_3'
          x='0'
          y='280.273'
          width='516'
          height='9.30026'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'
          />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_0_3' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_innerShadow_0_3'
            result='effect2_innerShadow_0_3'
          />
        </filter>
        <filter
          id='filter2_ii_0_3'
          x='1.10138'
          y='281.38'
          width='513.797'
          height='16.0869'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='30' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'
          />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_0_3' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='10' />
          <feGaussianBlur stdDeviation='20' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_innerShadow_0_3'
            result='effect2_innerShadow_0_3'
          />
        </filter>
        <filter
          id='filter3_i_0_3'
          x='1.65207'
          y='288.573'
          width='512.696'
          height='4.32013'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='30' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'
          />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_0_3' />
        </filter>
        <filter
          id='filter4_i_0_3'
          x='221.104'
          y='280.273'
          width='74.0683'
          height='6.81018'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='5' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'
          />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_0_3' />
        </filter>
        <linearGradient
          id='paint0_linear_0_3'
          x1='-255.797'
          y1='284.423'
          x2='-255.653'
          y2='296.595'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.01' />
          <stop offset='1' stopOpacity='0.03' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_0_3'
          x1='-254.696'
          y1='290.233'
          x2='-254.653'
          y2='296.873'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.01' />
          <stop offset='1' stopOpacity='0.03' />
        </linearGradient>
      </defs>
    </svg>
  );
};

PcCanvas.defaultProps = {
  width: '516px',
  height: '293px',
};

export default PcCanvas;
