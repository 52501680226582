import React, { Component } from 'react';
import { connect } from 'react-redux';
import { t } from '../../system/ui';
import { TextField } from '../fields';
import Tooltip from '../tooltip';
import { mailWizardActions } from '../../actions';
import Icons from '../icons';

class MailMapping extends Component {
  onMappingChange(changedObj, e) {
    const { mappings } = this.props;
    const newMappings = [];
    mappings.forEach(mapping => {
      const mappingObj = {
        id: mapping.id,
        key: mapping.key,
        value: mapping.value,
      };
      if (mapping.id === changedObj.obj.id) {
        mappingObj[changedObj.changedParam] = e.target.value;
      }
      newMappings.push(mappingObj);
    });
    if (this.props?.onMappingListChange) {
      this.props.onMappingListChange(newMappings);
    }
    mailWizardActions.addMapping(newMappings);
  }

  onMappingRemove(id) {
    mailWizardActions.addMapping(
      this.props.mappings.filter(mapping => {
        return mapping.id !== id;
      }),
    );
  }

  onMappingAdd() {
    mailWizardActions.addMapping(
      this.props.mappings.concat([{ id: Math.random(), key: '', value: '' }]),
    );
  }

  render() {
    return (
      <li>
        <ol className='form-elements wizard-items'>
          <div
            className='wizard-comp'
            style={{ marginTop: '0', paddingTop: '0' }}
          >
            <ol className='form-elements'>
              <div className='wizard-cards'>
                <div>
                  <div className='wizard-comp'>
                    <h3
                      className='wizard-comp-title item-title-sub item-title-sub__rec'
                      style={{ fontWeight: 600 }}
                    >
                      {t('Mapping Settings')}
                    </h3>
                    <span
                      style={{
                        position: 'relative',
                        top: '-2px',
                        left: '2px',
                      }}
                    >
                      <Tooltip
                        content={t(
                          'Mail recipients can either be static or triggered by certain events.',
                        )}
                        alignment='left'
                        placement='right'
                      >
                        <i className='icon-info' role='presentation'>
                          <span className='for-screenreader-only' />
                        </i>
                      </Tooltip>
                    </span>
                    <div style={{ marginTop: '10px' }}>
                      <li className='wizard-perso-fields'>
                        <TextField
                          name='mailTo'
                          label='Send Mail to'
                          className='item-stacked one-whole'
                          value={this.props.campaignCustoms.mailTo}
                          required
                          tooltipText={t(
                            'It can be triggered or specified as static that allow users to send an email to a specific address',
                          )}
                          tooltip
                          onChange={this.props.onMailToChange}
                        />
                      </li>
                    </div>
                    <div className='email-mappings'>
                      <span className='email-mappings-code-icon'>
                        <i className='icon-code' role='presentation' />
                      </span>
                      <h3
                        className='wizard-comp-title'
                        style={{
                          display: 'inline-block',
                          color: '#8f7aa2',
                          fontWeight: 'bold',
                        }}
                      >
                        {t('Mail Mapping Variables')}
                      </h3>
                      <span
                        style={{
                          position: 'relative',
                          top: '-2px',
                          left: '2px',
                        }}
                      >
                        <Tooltip
                          content={t(
                            'Which parameter will be replaced from triggered action to email template variable',
                          )}
                          alignment='left'
                          placement='right'
                        >
                          <i className='icon-info' role='presentation'>
                            <span className='for-screenreader-only' />
                          </i>
                        </Tooltip>
                      </span>
                      {this.props.mappings.map((mapping, index) => {
                        return (
                          <div key={mapping.id} className='email-mapping'>
                            <TextField
                              label='Event Variable'
                              className='item-stacked one-half'
                              value={mapping.value}
                              required={mapping.key.length > 0}
                              tooltipText={t(
                                'Which parameter will be triggered from device.',
                              )}
                              tooltip
                              onChange={this.onMappingChange.bind(this, {
                                changedParam: 'value',
                                obj: mapping,
                              })}
                            />

                            <TextField
                              label='Template Variable'
                              className='item-stacked one-half'
                              value={mapping.key}
                              required={mapping.value.length > 0}
                              tooltipText={t(
                                'Which parameter will be placed to email template.',
                              )}
                              tooltip
                              onChange={this.onMappingChange.bind(this, {
                                changedParam: 'key',
                                obj: mapping,
                              })}
                            />
                            {index === this.props.mappings.length - 1 && (
                              <a
                                className='mapping-add'
                                onClick={this.onMappingAdd.bind(this, null)}
                              >
                                <Icons
                                  name='plusCircle'
                                  width='32'
                                  height='32'
                                />
                              </a>
                            )}
                            {this.props.mappings.length > 1 && (
                              <a
                                onClick={this.onMappingRemove.bind(
                                  this,
                                  mapping.id,
                                )}
                                className='mapping-delete'
                              >
                                <Icons
                                  name='crossCircle'
                                  width='32'
                                  height='32'
                                />
                              </a>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </ol>
          </div>
        </ol>
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  mappings: store.mailWizard.mappings,
});

export default connect(mapStatesToProps)(MailMapping);
