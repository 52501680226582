/**
 * Created by mehmetyurtar on 08/08/2017.
 */
import React from "react";

class IconGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
      >
        <path
          fill={this.state.color}
          d="M9,0H7C6.4,0,6,0.4,6,1v14c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V1C10,0.4,9.6,0,9,0z"
        />
        <path
          data-color="color-2"
          fill={this.state.color}
          d="M3,10H1c-0.6,0-1,0.4-1,1v4c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1v-4C4,10.4,3.6,10,3,10z"
        />
        <path
          data-color="color-2"
          fill={this.state.color}
          d="M15,5h-2c-0.6,0-1,0.4-1,1v9c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1V6C16,5.4,15.6,5,15,5z"
        />
      </svg>
    );
  }
}

export default IconGraph;
