/**
 * Created by Barisaltun on 21/07/19.
 */
import React from "react";
import Icons from "./index";

class Revenue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: '16px',
      height: '16px',
      color: '#eee',
      bgColor: '#d62028',
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + 'px' : this.state.width,
      height = this.props.height ? this.props.height + 'px' : this.state.height,
      color = this.props.color ? this.props.color : this.state.color,
      bgColor = this.props.bgColor ? this.props.bgColor : this.state.bgColor;

    this.setState({ width, height, color, bgColor });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox="0 0 49.45 49.45"
      >
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <rect width="49.45" height="49.45" rx="2.5" fill={this.state.bgColor} />
            <path
              d="M9.47,35a1.58,1.58,0,0,1-.78-1.42,1.64,1.64,0,0,1,1.66-1.66,1.82,1.82,0,0,1,.88.23,9.38,9.38,0,0,0,5.15,1.73c1.76,0,2.77-.74,2.77-1.93v-.07c0-1.38-1.89-1.86-4-2.5-2.64-.75-5.59-1.83-5.59-5.25v-.06c0-3.39,2.81-5.45,6.37-5.45a12.29,12.29,0,0,1,5.61,1.45,1.75,1.75,0,0,1,1,1.56,1.65,1.65,0,0,1-1.7,1.66,1.58,1.58,0,0,1-.81-.21,9.75,9.75,0,0,0-4.19-1.21c-1.6,0-2.51.74-2.51,1.76v.06c0,1.32,1.93,1.86,4,2.54,2.6.81,5.55,2,5.55,5.21v.07c0,3.76-2.91,5.62-6.64,5.62A12.25,12.25,0,0,1,9.47,35Z"
              fill={this.state.color}
            />
            <path
              d="M24.47,18.88H24a1,1,0,0,1-1-1,1,1,0,0,1,1-1h.43V16a3.69,3.69,0,0,1,.88-2.66,3.28,3.28,0,0,1,2.44-.86A6.19,6.19,0,0,1,29,12.6a1,1,0,0,1-.18,2,4.06,4.06,0,0,0-.51,0c-.94,0-1.43.51-1.43,1.64v.59h1.92a1,1,0,0,1,1,1,1,1,0,0,1-1,1H27v7.55a1.24,1.24,0,0,1-2.48,0Z"
              fill={this.state.color}
            />
            <path
              d="M31.35,30.58a1.05,1.05,0,0,1-.78-1,1,1,0,0,1,1-1,1.25,1.25,0,0,1,.45.08,2.58,2.58,0,0,0,.86.15c.74,0,1.13-.31,1.53-1.15l.09-.22-4-9a1.56,1.56,0,0,1-.15-.6,1.19,1.19,0,0,1,1.23-1.2,1.24,1.24,0,0,1,1.21.94l2.9,7.18,2.7-7.18a1.28,1.28,0,0,1,1.19-.94,1.17,1.17,0,0,1,1.19,1.16,2.3,2.3,0,0,1-.11.6l-3.94,9.47c-.93,2.21-1.91,3-3.65,3A4.42,4.42,0,0,1,31.35,30.58Z"
              fill={this.state.color}
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default Revenue;
