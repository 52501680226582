/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";
import { Creatable } from "react-select";

import { decodeHtml } from "../../system/string";
import { searchCategories } from "../../modules/category/ajax";

import Icon from "../icon";
import Icons from "../icons";

import { t } from "../../system/ui";

class ExcludesSearchCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      categoryId: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.setDefaults = this.setDefaults.bind(this);
  }

  componentDidMount() {
    if (typeof this.props.values.categoryId !== "undefined") {
      this.setDefaults();
    }
  }

  onInputChange(value) {
    if (value.length > 2) {
      searchCategories(value, response => {
        let sorted = response.sort();
        let categories = [];
        sorted.map(item => {
          categories.push({
            value: item,
            label: decodeHtml(item)
          });
        });
        if (typeof this.props.values.categoryId !== "undefined") {
          categories.push({
            value: this.props.values.categoryId.value,
            label: decodeHtml(this.props.values.categoryId.value)
          });
        }
        this.setState({
          categories: categories
        });

        this.onChange();
      });
    } else {
      this.setState({
        categories: []
      });
    }
    return value;
  }

  onChange(selectedValue) {
    let selectedCategory =
      typeof selectedValue !== "undefined"
        ? selectedValue
        : this.state.categoryId;
    this.setState({ categoryId: selectedCategory });
    this.props.onExclusionSelectChange(
      "categoryId",
      selectedCategory,
      this.props.id
    );
  }

  setDefaults() {
    let selectedCategories = [];

    this.props.values.categoryId.value.forEach(category => {
      selectedCategories.push({
        value: category,
        title: decodeHtml(category),
        label: decodeHtml(category)
      });
    });

    this.setState({
      categoryId: selectedCategories
    });
  }

  render() {
    return (
      <li className="wizard-criterion" ref="inputs">
        <span className="criteria-type">
          <Icon name="tag" color="#787774" />
        </span>
        <div className="wizard-criterion-text">
          <h3 className="wizard-criterion-title">{t("Category")}</h3>
          <p className="wizard-criterion-description">
            {t(
              "Exclude the categories you don't want showing up in your recommendation."
            )}
          </p>
        </div>
        <span className="wizard-product-select-container">
          <Creatable
            value={this.state.categoryId}
            options={this.state.categories}
            name="categoryId"
            clearable={false}
            onChange={this.onChange}
            className="criteria-field criteria-field-category"
            multi={true}
            onInputChange={this.onInputChange}
            searchable={true}
            shouldKeyDownEventCreateNewOption={e => {
              switch (e.keyCode) {
                case 9:
                case 13:
                  return !0;
                default:
                  return !1;
              }
            }}
          />
        </span>
        <a
          onClick={this.props.onExclusionRemove.bind(null, this.props.id)}
          className="cancel-action recommend-filter-cancel"
        >
          <Icons name="crossCircle" />
        </a>
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  excludes: store.searchWizard.excludes
});

export default connect(mapStatesToProps)(ExcludesSearchCategory);
