/**
 * @author Bilal Cinarli
 */

'use strict';

import React from 'react';
import { connect } from 'react-redux';
import { Creatable } from 'react-select';
import { t } from '../../system/ui';

import { getLabels } from '../../modules/account/ajax';

import Icons from '../icons';

import { decodeHtml } from '../../system/string';

class IncludesLabel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: [],
      labelInclusion: '',
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const selectedLabels = [];
    getLabels().then(response => {
      const sorted = response.sort();
      const labels = [];
      sorted.map(item => {
        labels.push({
          value: item,
          label: decodeHtml(item),
        });
      });
      if (typeof this.props.values.label !== 'undefined') {
        labels.push({
          value: this.props.values.label.value,
          label: decodeHtml(this.props.values.label.value),
        });
        this.props.values.label.value.forEach(label => {
          selectedLabels.push({
            value: label,
            title: decodeHtml(label),
            label: decodeHtml(label),
          });
        });
      }
      this.setState({
        labels,
        labelInclusion: selectedLabels,
      });
    });
  }

  onChange(selectedValue) {
    const { labelInclusion } = this.state;
    const selectedLabel =
      typeof selectedValue !== 'undefined' ? selectedValue : labelInclusion;
    this.setState({ labelInclusion: selectedLabel });
    this.props.onInclusionSelectChange('label', selectedLabel, this.props.id);
  }

  render() {
    return (
      <li className='wizard-criterion' ref='inputs'>
        <span className='criteria-type'>
          <Icons name='excludeLabel' color='#787774' />
        </span>
        <div className='wizard-criterion-text'>
          <h3 className='wizard-criterion-title'>{t('Label')}</h3>
          <p className='wizard-criterion-description'>
            {t(
              'Include the label that your selected algorithm will recommend products from.',
            )}
          </p>
        </div>
        <span className='wizard-product-select-container'>
          <Creatable
            value={this.state.labelInclusion}
            options={this.state.labels}
            name='label'
            clearable={false}
            onChange={this.onChange}
            className='criteria-field criteria-field-category'
            multi
            shouldKeyDownEventCreateNewOption={e => {
              switch (e.keyCode) {
                case 9:
                case 13:
                  return !0;
                default:
                  return !1;
              }
            }}
          />
        </span>
        <a
          onClick={this.props.onInclusionRemove.bind(null, this.props.id)}
          className='cancel-action recommend-filter-cancel'
        >
          <Icons name='crossCircle' />
        </a>
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
});

export default connect(mapStatesToProps)(IncludesLabel);
