import React from 'react';
import './SFYImportExport.scss';
import Icons from '../../icons';

const SFYImportExport = ({ buttons, customWrapperClass = '' }) => {
  const buttonRender = () => {
    return buttons.map((button, i) => {
      return (
        <div
          className='import-export-wrapper-button'
          onClick={button.action}
          key={i}
        >
          <span>{button.label}</span>
          <Icons name={button.icon} />
        </div>
      );
    });
  };

  return (
    <div className={`import-export-wrapper ${customWrapperClass}`}>
      {buttonRender()}
    </div>
  );
};

export default SFYImportExport;
