import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { TextField } from '../fields';
import { convertToPositiveInt, operatorObjToArray } from './utils/utils';

import {
  quantityOperators,
  operatorColumns,
} from '../../constants/datamaps/rule-based-segmentation';

/**
 * @name CriteriaFilterQuantity
 * @description Component to filter quantity
 * @param {object} criteriaData - The criteria data
 * @param {string} filterType - The filter type
 * @param {array} statements - The statements
 * @param {function} callbackFunc - The callback function
 * @returns {JSX.Element}
 */
const criteriaFilterQuantity = ({
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const defaultFilterData = {
    dataType: criteriaData?.dataType,
    column: operatorColumns.quantityOperator,
    operator: quantityOperators.gte.value,
    value: '',
  };

  const [quantityValue, setQuantityValue] = useState();
  const [selectedOperator, setSelectedOperator] = useState('');

  useEffect(() => {
    const statement = statements?.[filterType]?.[0];

    setSelectedOperator(statement?.operator || defaultFilterData.operator);
    setQuantityValue(statement?.value);
  }, [statements]);

  const triggerCallbackFunc = (val, type) => {
    const columnVal = operatorColumns.quantityOperator;
    const operatorVal = type === 'operator' ? val.value : selectedOperator;
    const quantityVal =
      type === 'quantity'
        ? convertToPositiveInt(val.target.value, 0)
        : quantityValue;

    const filterData = [
      {
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator: operatorVal,
        value: quantityVal,
      },
    ];

    callbackFunc(filterType, filterData);
  };

  const onChange = (selectedValue, type) => {
    switch (type) {
      case 'quantity':
        setQuantityValue(convertToPositiveInt(selectedValue.target.value, 0));
        break;
      case 'operator':
        setSelectedOperator(selectedValue?.value);
        break;
      default:
        break;
    }

    triggerCallbackFunc(selectedValue, type);
  };

  return (
    <div className='rbs-filter-field'>
      <div className='filter-operator'>
        <span className='filter-operator-title'>Quantity Operator</span>
        <Select
          value={selectedOperator}
          options={operatorObjToArray(quantityOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          onChange={e => onChange(e, 'operator')}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      <div className='filter-connector' />
      <div className='filter-operator'>
        <span className='filter-operator-title'>Quantity</span>
        <TextField
          name='quantity'
          value={quantityValue}
          onChange={e => onChange(e, 'quantity')}
          type='number'
        />
      </div>
    </div>
  );
};

export default criteriaFilterQuantity;
