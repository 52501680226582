import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { TextField } from "../components/fields";
import Icons from "../components/icons";
import DropzoneComponent from "react-dropzone-component";

import { t } from "../system/ui";
import { setTitle } from "../system/document";

import { uiActions, userActions } from "../actions";

import { uploadFile } from "../modules/upload/ajax";
import {
  getAccount,
  getUser,
  isSwitchAccount,
  updatePushConfig
} from "../modules/auth/user";
import PushGlobalCapping from "../components/push/globalCapping";
import IconUpload from "../modules/campaign/fields/iconUpload";

let dropzoneObj;
const componentConfig = { postUrl: "no-url" };
const djsConfig = {
  addRemoveLinks: true,
  autoProcessQueue: false,
  acceptedFiles: "application/x-pkcs12",
  maxFiles: 1
};

const providers = ["APNS", "Firebase"];
class SettingsPushIos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      provider: "APNS",
      serverApiKey: "",
      password: "",
      file: "",
      capping: null
    };

    this.setDropzoneHandlers = this.setDropzoneHandlers.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onProviderChange = this.onProviderChange.bind(this);
    this.updateConfig = this.updateConfig.bind(this);
    this.update = this.update.bind(this);
    this.setValues = this.setValues.bind(this);
  }

  componentDidMount() {
    setTitle("Push Settings");
    uiActions.isLoading();
    if (isSwitchAccount()) {
      let values = {};
      let config =
        isSwitchAccount().pushConfiguration &&
        isSwitchAccount().pushConfiguration.iosConfiguration;
      let capping =
        isSwitchAccount().pushConfiguration &&
        isSwitchAccount().pushConfiguration.capping;
      if (config) {
        if (config.fireBase) {
          values = {
            serverApiKey: config.fireBase.serverApiKey,
            provider: "Firebase",
            capping: capping
          };
        }
        if (config.apns) {
          values = {
            password: config.apns.password,
            file: config.apns.fileId,
            provider: "APNS",
            capping: capping
          };
        }
        if (Object.keys(values).length) {
          this.setValues(values);
        }
      }
      uiActions.isLoaded();
    } else {
      getUser(response => {
        let values = {};
        let config =
          response.account.pushConfiguration &&
          response.account.pushConfiguration.iosConfiguration;
        let capping =
          response.account.pushConfiguration &&
          response.account.pushConfiguration.capping;
        if (config) {
          if (config.fireBase) {
            values = {
              serverApiKey: config.fireBase.serverApiKey,
              provider: "Firebase",
              capping
            };
          }
          if (config.apns) {
            values = {
              password: config.apns.password,
              file: config.apns.fileId,
              provider: "APNS",
              capping
            };
          }
          if (Object.keys(values).length) {
            this.setValues(values);
          }
        }
        let defaultIcon =
            response.account.pushConfiguration
                ? response.account.pushConfiguration.defaultIcon
                : "";
        this.onDefaultIconValueChange(defaultIcon);
        uiActions.isLoaded();
      });
    }
  }

  setDropzoneHandlers() {
    return {
      init: dropzone => {
        dropzoneObj = dropzone;
      },
      addedfile: file => {
        window.dispatchEvent(new Event("resize"));
        this.setState({
          file: ""
        });
      }
    };
  }

  onProviderChange(provider) {
    this.setState({ provider });
  }

  setValues(values) {
    this.setState(values);
  }

  onInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value.trim()
    });
  }

  onDefaultIconValueChange(value) {
    this.state.defaultIcon = value;
  }

  update(e) {
    e.preventDefault();

    let account=getAccount();

    let cappingState = this.refs.capping.state;
    let fileToUpload = dropzoneObj.getAcceptedFiles()[0];
    let fileQuery = { feedBackFile: fileToUpload };
    let config = {
      iosConfiguration: {
        fireBase: {
          serverApiKey: '',
        },
        apns: {
          fileId: '',
          password: '',
        },
      },
      vapidConfiguration: {},
      vapidEnabled: false,
      defaultIcon: '',
    };

    config.capping = Object.assign({}, cappingState.capping);
    for (var key in config.capping) {
      if (config.capping && !config.capping[key]) {
        config.capping = null;
      }
    }

    if (
      account.pushConfiguration &&
      account.pushConfiguration.vapidConfiguration
    ) {
      config.vapidConfiguration = account.pushConfiguration.vapidConfiguration;
    }

    if (account.pushConfiguration && account.pushConfiguration.vapidEnabled) {
      config.vapidEnabled = account.pushConfiguration.vapidEnabled;
    }

    if (this.state.provider === 'APNS') {
      if (!cappingState.hasCappingError) {
        config.iosConfiguration.fireBase = null;
        config.iosConfiguration.apns.password = this.state.password;
        if (this.state.file) {
          config.iosConfiguration.apns.fileId = this.state.file;
          this.updateConfig(config);
        } else {
          uploadFile(fileQuery, 'PUSH', response => {
            if (response) {
              config.iosConfiguration.apns.fileId = JSON.parse(response);
              this.updateConfig(config);
            }
          });
        }
      }
    } else {
      if (!cappingState.hasCappingError) {
        config.iosConfiguration.apns = null;
        config.iosConfiguration.fireBase.serverApiKey = this.state.serverApiKey;
        this.updateConfig(config);
      }
    }
  }

  updateConfig(config) {
    updatePushConfig('ios', config, response => {
      if (response.pushConfiguration) {
        if (isSwitchAccount()) {
          let newUserToSave = isSwitchAccount();
          newUserToSave.pushConfiguration = response.pushConfiguration;
          userActions.updateSwitchAccount(newUserToSave);
        }
        uiActions.isLoaded();
        let success = () => <p>{t("Push settings successfully updated.")}</p>;
        uiActions.showNotification({
          content: success
        });
        this.setState({
          ui: "isLoaded"
        });
      } else {
        uiActions.isLoaded();
        this.setState({
          ui: "isLoaded"
        });
      }
    });
  }

  render() {
    return (
      <form id="currency-form" onSubmit={this.update}>
        <div className="settings-push">
          <h2 className="page-title">
            {"Let's Integrate Segmentify Push Service"}
          </h2>
          <div className="settings-push-form">
            <nav id="page-nav" className="page-nav">
              <Link to="/settings/push" className="nav-item page-nav-item">
                {"Chromium & Firefox "}
              </Link>
              <Link
                to="/settings/push/safari"
                className="nav-item page-nav-item"
              >
                {"Safari"}
              </Link>
              <Link
                to="/settings/push/android"
                className="nav-item page-nav-item"
              >
                {"Android"}
              </Link>
              <Link
                to="/settings/push/ios"
                className="nav-item page-nav-item current"
              >
                {"iOS"}
              </Link>
            </nav>

            <div className="one-whole page-forms">
              <p className="item-label">
                Please select iOS Notification platform and complete the
                instructions.
              </p>
              <ul className="whitelist-radio">
                {providers.map(provider => {
                  return (
                    <li
                      key={provider}
                      onClick={this.onProviderChange.bind(this, provider)}
                    >
                      <Icons
                        name={
                          this.state.provider === provider
                            ? "radioSelected"
                            : "radioEmpty"
                        }
                        width="14"
                        height="14"
                      />
                      <span>{provider}</span>
                    </li>
                  );
                })}
              </ul>
              {this.state.provider === "APNS" ? (
                [
                  <span className="item-label">
                    {"Upload Certification File (.p12)"}
                  </span>,
                  <DropzoneComponent
                    config={componentConfig}
                    eventHandlers={this.setDropzoneHandlers()}
                    djsConfig={djsConfig}
                  >
                    {this.state.file ? (
                      <span>Click to upload new licence file</span>
                    ) : (
                      <i
                        className="icon-plus"
                        style={{
                          color: "#dedbe8",
                          fontSize: "32px",
                          position: "relative"
                        }}
                      />
                    )}
                  </DropzoneComponent>,
                  <TextField
                    type="password"
                    name="password"
                    label={"Password"}
                    className="item-stacked one-whole"
                    onChange={this.onInputChange}
                    value={this.state.password}
                  />
                ]
              ) : (
                <TextField
                  name="serverApiKey"
                  label={"Firebase Server Key"}
                  className="item-stacked one-whole"
                  value={this.state.serverApiKey}
                  onChange={this.onInputChange}
                />
              )}
            </div>
          </div>

          <div className="settings-push-form">
            <IconUpload
                account={getAccount()}
                options={{ label: "Notification Icon" }}
                uploadType="icon"
                onDefaultIconValueChange={this.onDefaultIconValueChange.bind(this)}
            />
          </div>

          <PushGlobalCapping ref="capping" />
          <li
            className="buttons"
            style={{ marginTop: "50px", listStyle: "none" }}
          >
            <button className="tertiary-action">Save</button>
          </li>
        </div>
      </form>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  user: store.user.user
});

export default connect(mapStatesToProps)(SettingsPushIos);
