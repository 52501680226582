import React, { useState, useEffect } from 'react';
import classes from './NoUpdate.module.scss';
import { t } from '../../../system/ui';
import { noUpdateFieldsDictionary, initialNoUpdateState } from './constants';
import { Checkbox } from '../../../components/fields';
import Icon from '../../../components/icon';
import { getUser, isSwitchAccount } from '../../../modules/auth/user';
import { saveNoUpdateFields } from '../../../modules/account/ajax';
import { isLoaded, isLoading, showNotification } from '../../../actions/ui';
import { userActions } from '../../../actions';

const NoUpdate = () => {
  const [noUpdateFields, setNoUpdateFields] = useState(initialNoUpdateState);

  useEffect(() => {
    isLoading();

    getUser(response => {
      if (isSwitchAccount()) {
        userActions.updateSwitchUser(response);
      } else {
        userActions.updateUserAccount(response);
      }
      setNoUpdateFields(response.account.noUpdate);
      isLoaded();
    });
  }, []);

  const saveNoUpdate = () => {
    isLoading();
    saveNoUpdateFields(noUpdateFields, response => {
      isLoaded();
      if (response) {
        const notificationContent = () => <div>{response}</div>;
        showNotification({ content: notificationContent });
      }
    });
  };

  const toggleField = (deviceName, fieldValue) => {
    const updatedFields = { ...noUpdateFields };
    if (fieldValue === 'all') {
      updatedFields[deviceName].fieldList = ['all'];
    } else if (fieldValue === 'none') {
      updatedFields[deviceName].fieldList = [];
    } else {
      const totalFieldsLength = noUpdateFieldsDictionary.length;
      const isCurrentFieldAll = updatedFields[deviceName].fieldList.includes(
        'all',
      );
      const isExist =
        updatedFields[deviceName].fieldList.includes(fieldValue) ||
        isCurrentFieldAll;

      if (isExist && !isCurrentFieldAll) {
        updatedFields[deviceName].fieldList = updatedFields[
          deviceName
        ].fieldList.filter(field => field !== fieldValue);
      } else if (isExist && isCurrentFieldAll) {
        updatedFields[deviceName].fieldList = noUpdateFieldsDictionary.map(
          field => field.value !== fieldValue && field.value,
        );
      } else {
        const newFieldList = [
          ...updatedFields[deviceName].fieldList,
          fieldValue,
        ];
        updatedFields[deviceName].fieldList =
          newFieldList.length === totalFieldsLength ? ['all'] : newFieldList;
      }
    }

    setNoUpdateFields(updatedFields);
  };

  const deviceHeaderRender = deviceName => {
    const isDeviceAll = noUpdateFields[deviceName].fieldList.includes('all');
    return (
      <div className={classes['no-update-container__body__cell__header']}>
        <div
          className={classes['no-update-container__body__cell__header__icon']}
        >
          <Icon name={deviceName} color='#B4B4B4' height='20px' width='20px' />
          <div
            className={
              classes['no-update-container__body__cell__header__icon__tooltip']
            }
          >
            <span>{deviceName}</span>
          </div>
        </div>
        <div
          className={
            classes['no-update-container__body__cell__header__toggle-button']
          }
        >
          <button
            type='button'
            onClick={
              () => toggleField(deviceName, isDeviceAll ? 'none' : 'all')
              // eslint-disable-next-line react/jsx-curly-newline
            }
            className={
              classes[
                'no-update-container__body__cell__header__toggle-button__btn'
              ]
            }
          >
            {isDeviceAll ? t('Remove All') : t('Select All')}
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={classes['content-row']}>
        <h2 className={classes['page-title']}>{t('No Update Fields')}</h2>
      </div>
      <div className={classes['no-update-container']}>
        <div className={classes['no-update-container__header']}>
          <div className={classes['no-update-container__header__cell']} />
          {noUpdateFieldsDictionary.map(field => (
            <div
              key={field.value}
              className={classes['no-update-container__header__cell']}
            >
              {field.label}
            </div>
          ))}
        </div>
        <div className={classes['no-update-container__body']}>
          {Object.keys(noUpdateFields).map(deviceName => (
            <div
              key={deviceName}
              className={classes['no-update-container__body__row']}
            >
              <div className={classes['no-update-container__body__cell']}>
                {deviceHeaderRender(deviceName)}
              </div>
              {noUpdateFieldsDictionary.map(field => (
                <div
                  key={field.value}
                  className={classes['no-update-container__body__cell']}
                >
                  <Checkbox
                    checked={
                      noUpdateFields[deviceName].fieldList.includes(
                        field.value,
                      ) || noUpdateFields[deviceName].fieldList.includes('all')
                    }
                    onChange={() => toggleField(deviceName, field.value)}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className={classes['button-row']}>
        <button
          type='button'
          className={classes['button-row__save-btn']}
          onClick={() => saveNoUpdate()}
        >
          {t('Save')}
        </button>
      </div>
    </>
  );
};

export default NoUpdate;
