import React from 'react';
import CodeMirror from 'react-codemirror';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/javascript-lint';
import 'codemirror/addon/lint/css-lint';
import 'codemirror/addon/lint/json-lint';
import jsonlint from 'jsonlint-mod';

const defaults = {
  theme: 'mbo',
  lineNumbers: true,
  lineWrapping: true,
  mode: 'htmlmixed',
};

class Snippet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };

    this.setValue = this.setValue.bind(this);
    this.refresh = this.refresh.bind(this);
    this.updateCode = this.updateCode.bind(this);
  }

  componentDidMount() {
    this.setValue();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.value !== newProps.value || newProps.value === '') {
      this.setState({
        value: newProps.value,
      });
      this.refresh();
    }
  }

  setValue() {
    this.setState({
      value: this.props.value,
    });
  }

  updateCode(newCode) {
    this.setState({
      value: newCode,
    });
    this.props.checkDefault(newCode);
  }

  refresh() {
    this.refs.editor.codeMirror.refresh();
  }

  render() {
    const options = { ...defaults, ...this.props.options };

    options.mode = this.props.language;
    options.lint = true;
    window.jsonlint = jsonlint;
    options.gutters = ['CodeMirror-lint-markers'];
    options.extraKeys = { 'Ctrl-Space': 'autocomplete' };
    if (options.mode === 'htmlmixed') options.autoCloseTags = true;

    return (
      <CodeMirror
        value={this.state.value}
        ref='editor'
        onChange={this.updateCode}
        className='code-editor'
        options={options}
      />
    );
  }
}

export default Snippet;
