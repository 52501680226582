import React from 'react';

import * as modals from '../constants/datamaps/modals';
import { icons, units } from '../constants/ui';
import { localeString, toLocaleStringDigits, toSlug } from '../system/string';
import { openModal, t } from '../system/ui';
import Currency from './currency';
import DeviceCard from './DeviceCard';
import ErrorBoundary from './ErrorBoundary';
import Icon from './icon';
import PageCard from './PageCard';
import Tooltip from './tooltip';

class Card extends React.Component {
  constructor(props) {
    super(props);

    this.showModal = this.showModal.bind(this);
  }

  showModal(modalData, e) {
    const { modal, modalTitle } = modalData;
    e.preventDefault();
    const modalContent = modals[modal];

    openModal({
      title: modalTitle || modal,
      content: modalContent,
    });
  }

  render() {
    const mainTitle = this.props.title;
    const className = `stat-card stat-${
      this.props.isSearch ? 'search' : this.props.type
    } ${mainTitle.includes('Revenue') && 'stat-revenue'}`;
    if (this.props.type === 'page') {
      return (
        <ErrorBoundary>
          <PageCard title={this.props.title} data={this.props.data} />
        </ErrorBoundary>
      );
    }

    if (this.props.type === 'device') {
      return (
        <ErrorBoundary>
          <DeviceCard
            showIn={this.props.showIn}
            title={this.props.title}
            data={this.props.data}
          />
        </ErrorBoundary>
      );
    }

    return (
      <div
        className={className}
        style={{ backgroundColor: this.props.isSearch && '#ff6640' }}
      >
        <h3 className='stat-card-title'>{t(mainTitle)}</h3>

        {this.props.data.modal &&
          typeof modals[this.props.data.modal] !== 'undefined' && (
            <a
              className='modal-trigger'
              onClick={e => {
                this.showModal(this.props.data, e);
              }}
            >
              <Icon name='info' />
            </a>
          )}

        <ol className='stat-card-list'>
          {Object.keys(this.props.data.stats).map((item, index) => {
            const singleStat = this.props.data.stats[item];
            let row;

            if (singleStat.name === 'Spam') return null;

            if (mainTitle !== 'Widget') {
              if (singleStat.hasOwnProperty('total')) {
                row = (
                  <li key={`${item}-${index}`} className='stat-total'>
                    <span
                      className='stat-item-count'
                      title={toLocaleStringDigits(singleStat.count)}
                    >
                      {singleStat.hasOwnProperty('currency') ? (
                        <Currency currency={singleStat.count} />
                      ) : (
                        localeString(singleStat.count)
                      )}
                    </span>
                    <span className='stat-item-unit'>
                      {this.props.type === 'unique'
                        ? t(singleStat.name)
                        : t(units[this.props.type])}
                    </span>
                  </li>
                );
              } else {
                row = (
                  <li
                    key={`${item}-${index}`}
                    className={`${toSlug(
                      singleStat.name,
                    )} stat-item stat-item-${toSlug(mainTitle)}`}
                  >
                    <span className='stat-item-name'>
                      {typeof icons[this.props.type] !== 'undefined' &&
                        typeof icons[this.props.type][item] !== 'undefined' && (
                          <Icon name={icons[this.props.type][item]} />
                        )}
                      {mainTitle === 'Widget' ||
                      mainTitle === 'Interaction' ||
                      mainTitle === 'Basket'
                        ? t(mainTitle + singleStat.name)
                        : t(singleStat.name)}
                      {singleStat.tooltip && (
                        <span className='stat-tooltip'>
                          <Tooltip
                            content={singleStat.tooltip}
                            alignment='top'
                            newTooltip
                          >
                            <Icon name='info' />
                          </Tooltip>
                        </span>
                      )}
                    </span>
                    {singleStat.hasOwnProperty('count') === true && (
                      <span
                        className='stat-item-count'
                        title={toLocaleStringDigits(singleStat.count)}
                      >
                        {singleStat.hasOwnProperty('currency') ? (
                          <Currency currency={singleStat.count} />
                        ) : (
                          localeString(singleStat.count)
                        )}
                        {singleStat.name === 'Purchases' &&
                          (mainTitle === 'Revenue' ||
                            mainTitle === 'Assisted Revenue') && (
                            <span> ({localeString(singleStat.ratio)}%)</span>
                          )}
                        {singleStat.name === 'Purchase Items' &&
                          (mainTitle === 'Revenue' ||
                            mainTitle === 'Assisted Revenue') && (
                            <span> ({localeString(singleStat.ratio)}%)</span>
                          )}
                      </span>
                    )}
                    <span className='stat-item-unit'>
                      {t(units[this.props.type])}
                    </span>
                    {singleStat.hasOwnProperty('ratio') === true &&
                      singleStat.name !== 'Purchases' &&
                      singleStat.name !== 'Purchase Items' && (
                        <span className='stat-item-percentage'>
                          {localeString(singleStat.ratio)}%
                        </span>
                      )}
                  </li>
                );
              }
            } else if (singleStat.name === 'Actual View') {
              row = (
                <li key={`${item}-${index}`} className='stat-total'>
                  <span
                    className='stat-item-count'
                    title={toLocaleStringDigits(singleStat.count)}
                  >
                    {singleStat.hasOwnProperty('currency') ? (
                      <Currency currency={singleStat.count} />
                    ) : (
                      localeString(singleStat.count)
                    )}
                  </span>
                  <span className='stat-item-unit'>
                    {this.props.type === 'unique'
                      ? t(singleStat.name)
                      : t(units[this.props.type])}
                  </span>
                </li>
              );
            } else if (
              this.props.isSearch &&
              (singleStat.name === 'Conversion' ||
                singleStat.name === 'Total View')
            ) {
              row = null;
            } else {
              row = (
                <li key={`${item}-${index}`} className='stat-item'>
                  <span className='stat-item-name'>
                    {typeof icons[this.props.type] !== 'undefined' &&
                      typeof icons[this.props.type][item] !== 'undefined' && (
                        <Icon name={icons[this.props.type][item]} />
                      )}
                    {mainTitle === 'Widget' ||
                    mainTitle === 'Interaction' ||
                    mainTitle === 'Basket'
                      ? t(mainTitle + singleStat.name)
                      : t(singleStat.name)}
                    {singleStat.tooltip && (
                      <span className='stat-tooltip'>
                        <Tooltip
                          content={singleStat.tooltip}
                          alignment='top'
                          newTooltip
                        >
                          <Icon name='info' />
                        </Tooltip>
                      </span>
                    )}
                  </span>
                  {singleStat.hasOwnProperty('count') === true && (
                    <span
                      className='stat-item-count'
                      title={toLocaleStringDigits(singleStat.count)}
                    >
                      {singleStat.hasOwnProperty('currency') ? (
                        <Currency currency={singleStat.count} />
                      ) : (
                        localeString(singleStat.count)
                      )}
                    </span>
                  )}
                  <span className='stat-item-unit'>
                    {t(units[this.props.type])}
                  </span>
                  {singleStat.hasOwnProperty('ratio') === true && (
                    <span className='stat-item-percentage'>
                      {localeString(singleStat.ratio)}%
                    </span>
                  )}
                </li>
              );
            }

            return row;
          })}
          {this.props.type === 'purchase' && (
            <li className='stat-item'>
              <span className='stat-item-name'>{t('Success Page View')}</span>
              <span className='stat-item-count'>
                {this.props.pageStats &&
                  this.props.pageStats.page.stats.other.checkoutCount}
              </span>
            </li>
          )}
        </ol>
      </div>
    );
  }
}

export default Card;
