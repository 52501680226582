import React, {Component} from "react";
import classNames from "classnames";
import {t} from "../../../system/ui";
import Icons from "../../icons";
import {
    criterionTypeButtonsJourneyBuilder,
    intelligentOptionsJB_1,
    getRockstarTimeFrameMapping,
    getJourneyBuilderCurrentIntelligent
} from "../../../constants/datamaps/wizard";
import DiscoveryCriteria from "./jb-discovery-criteria";
import {modalActions} from "../../../actions";
import IntelligentSelectionModal from "../../wizard/selectionModal.intelligent";
import {clone} from "../../../system/object";

const selectionModal = {
    icon: "brain",
    iconSvg: true,
    title: "Intelligent Recommendation Types",
    subtitle:
        "Select from Segmentify Intelligent Recommendation Types to create your personalized recommendation campaigns",
    modal: IntelligentSelectionModal,
}

const buttonMap = {
    "Static": "Static",
    "Intelligent": "Intelligent",
    "CATEGORY": "Top Categories",
    "BRAND": "Top Brands",
    "RECOMMEND": "BestMatch"
}

class DiscoveryItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showRequiredError: false,
            hasError: false,
            assetItems: props.assetItems,
        };
        this.button = this.button.bind(this);
        this.handleAssetItems = this.handleAssetItems.bind(this);
        this.addCriterion = this.addCriterion.bind(this);
        this.addDetailsRow = this.addDetailsRow.bind(this);
        this.openAlgorithmSelection = this.openAlgorithmSelection.bind(this);
        this.handleCriteriaRemove = this.handleCriteriaRemove.bind(this);
        this.sendAssetItems = this.sendAssetItems.bind(this);
        this.handleAssetItems = this.handleAssetItems.bind(this);
        this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
        this.validate = this.validate.bind(this);
        this.onlyValidateItems = this.onlyValidateItems.bind(this);
        this.setStaticProducts = this.setStaticProducts.bind(this);
    }

    componentDidMount() {
        this.showError();
        this.setState({
            isAssetActive: this.props.isAssetActive
        });
        if (this.props.type === "MAIN_CATEGORY") {
            this.setMainCategory(this.props.assetItems);
        } else {
            this.setStaticProducts(this.props.assetItems);
        }

    }

    componentWillReceiveProps(newProps) {
        if (this.props.assetItems !== newProps.assetItems) {
            this.setStaticProducts(newProps.assetItems);
        }else{
            this.onlyValidateItems(newProps.assetItems);
        }
    }

    setMainCategory() {
        let assetItems = [];
        this.props.account.personalisationConfig.parentCategories.map(item => assetItems.push(item));
        this.handleAssetItems(assetItems);
    }

    setStaticProducts(assetItems) {
        this.setState({assetItems: assetItems}, () => this.validate(assetItems))
    };

    buttonNames = (title) => {
        title = title === "Static" || title === "Intelligent" ? title : this.props.type;
        return buttonMap[title];
    };

    onlyValidateItems(assetItems){
        if (assetItems.length > 0 && this.props.type !== "MAIN_CATEGORY") {
            let controlFlag = false;
            assetItems.forEach(item => {
                if (typeof item.values.criterion === "undefined") {
                    controlFlag = true;
                }
            });

            this.setState({
                hasError: controlFlag,
                showRequiredError: controlFlag,
            });

        }
    }

    validate(assetItems) {
        if (assetItems.length === 0) {
            this.setState({
                hasError: true,
                showRequiredError: true,
            });
        } else if (assetItems.length > 0 && this.props.type !== "MAIN_CATEGORY") {
            let controlFlag = false;
            assetItems.forEach(item => {
                if (typeof item.values.criterion === "undefined") {
                    controlFlag = true;
                }
            });

            this.setState({
                hasError: controlFlag,
                showRequiredError: controlFlag,
            });

        } else {
            this.setState({
                hasError: false,
                showRequiredError: false,
            });
        }
    }

    showError() {
        if (this.props.assetItems.length === 0)
            this.setState({showRequiredError: true});
    }

    sendAssetItems() {
        this.props.handleAsset(this.state.assetItems);
    }

    handleCriteriaRemove(criteriaId) {
        this.handleAssetItems(this.props.assetItems.filter((criteria) => criteria.id !== criteriaId));
    }

    handleCriteriaChange(fields, criteriaId, selectedCategory) {
        let criteria = this.state.assetItems,
            inputs =
                typeof fields !== "undefined"
                    ? fields.current.querySelectorAll(".criteria-field")
                    : "",
            values = {};

        if (inputs.length > 0) {
            inputs.forEach((item) => {
                if (!item.name) {
                    if (selectedCategory) {
                        values.criterion = {
                            value: selectedCategory,
                        };
                    } else {
                        values.itemCount = {
                            value: item.querySelector("input").value,
                        };
                    }
                } else {
                    values[item.name] = {
                        value: item.value,
                    };
                }
            });

            for (let i = 0; i < criteria.length; i++) {
                if (criteria[i] !== undefined && criteria[i].id === criteriaId) {
                    criteria[i].values = Object.assign({}, values);
                    break;
                }
            }
            this.handleAssetItems(criteria);
        }
    }

    handleAssetItems(assetList) {
        this.setState({assetItems: assetList}, () => this.sendAssetItems());
    }

    getCurrentIntelligents() {
        return getJourneyBuilderCurrentIntelligent(this.props.type);
    }

    addCriterion(type, algorithms) {
        let finalAlgorithms = [];
        let selectedAlgorithms = Object.assign([], this.state.assetItems);
        finalAlgorithms = selectedAlgorithms.concat(algorithms);
        this.handleAssetItems(finalAlgorithms);
    }

    addDetailsRow(type, selection) {
        let intelligentList = [];
        let intelligentChoose;
        if (type === "jb_intelligent") {
            intelligentChoose = {
                id: Math.random(),
                type: "intelligent",
                values: {
                    criterion: {value: selection},
                    itemCount: {value: "8"},
                },
            };
            intelligentList.push(intelligentChoose);
        } else if (type === "intelligent") {
            selection.forEach(item => {
                let values = item.values;
                intelligentChoose = {
                    id: Math.random(),
                    type: "intelligent",
                    values: {
                        criterion: {value: values.criterion.value},
                        timeFrame: {
                            value: (values.criterion.value === "RECOMMENDATION_SMART_OFFERS"
                                || values.criterion.value === "RECOMMENDATION_PRICE_DROP")
                                ? "THIS_WEEK"
                                : getRockstarTimeFrameMapping(values.timeFrame.value)
                        },
                        itemCount: {value: "8"},
                    },
                };
                intelligentList.push(intelligentChoose);
            })
        } else {
            intelligentChoose = {
                id: Math.random(),
                values: {},
            };

            if (this.props.type === "CATEGORY") {
                intelligentChoose.type = "category"
            } else if (this.props.type === "BRAND") {
                intelligentChoose.type = "brand"
            } else if (this.props.type === "RECOMMEND") {
                intelligentChoose.type = "recommend"
            }
            intelligentList.push(intelligentChoose);
        }
        this.addCriterion(type, intelligentList);
    }

    openAlgorithmSelection(obj) {
        if (obj === "intelligent") {
            let Modal = selectionModal.modal;

            modalActions.openModal({
                title: t(selectionModal.title),
                subtitle: t(selectionModal.subtitle),
                icon: selectionModal.icon,
                className: "wizard-comp-modal",
                content: () => (
                    <Modal
                        currentAlgorithms={intelligentOptionsJB_1}
                        addDetailsRow={this.addDetailsRow}
                        disabledStatics={[]}
                    />
                ),
            });

        } else {
            this.addDetailsRow(obj, Object.keys(this.getCurrentIntelligents())[0]);
        }
    }

    button(item, type, cb) {
        let currentIcon = <Icons name={item.icon} color={"#fff"}/>;
        return (
            <a
                key={type}
                className={classNames("button", item.className)}
                onClick={cb.bind(null, type)}
            >
                {currentIcon}
                {t(this.buttonNames(item.title))}
            </a>
        );
    }

    handleMainCategory(category) {
        let assetItems = clone(this.props.assetItems);
        let index = assetItems.indexOf(category);
        if (index > -1) {
            assetItems.splice(index, 1);
        } else {
            assetItems.push(category);
        }
        this.handleAssetItems(assetItems);
    }

    render() {
        let criterionButtonsToShow = criterionTypeButtonsJourneyBuilder;
        let parentCategories = this.props.account.personalisationConfig.parentCategories ?
            this.props.account.personalisationConfig.parentCategories :
            [];
        return (
            <ol className="form-elements wizard-items" style={{width: "100%"}}>
                <div className="wizard-comp search">
                    <h3 className="wizard-comp-title">
                        {this.props.type === "RECOMMEND" ? "Recommendations" : t(`Recommendation ${this.props.text}`)}
                    </h3>
                    {(this.props.type === "CATEGORY" || this.props.type === "BRAND" || this.props.type === "RECOMMEND") &&
                    <div id="scroll-container">
                        <li className="wizard-input-types">
                            {Object.keys(criterionButtonsToShow).map((item) => {
                                if (item !== "intelligent" || (item === "intelligent" && this.props.type === "RECOMMEND")) {
                                    return this.button(
                                        criterionButtonsToShow[item],
                                        item,
                                        this.openAlgorithmSelection
                                    );
                                }
                            })}
                        </li>
                    </div>}

                    {this.props.type === "MAIN_CATEGORY" &&
                    <div className="wizard-criteria wizard-comp wizard-comp-filters">
                        {parentCategories.map((item) => {
                            let isSelected = this.props.assetItems.indexOf(item) > -1;

                            return <a key={item}
                                      className={classNames("button add-criteria", {
                                          "main-category-selected": isSelected
                                      })}
                                      onClick={() => this.handleMainCategory(item)}
                            >
                                {isSelected &&
                                (<span>
                                        <Icons name="checkboxSelected" width="15" height="15"/>
                                     </span>)
                                }
                                <Icons name="categoryBoxes" color="#547494"/>
                                {t(item)}
                            </a>
                        })
                        }
                    </div>
                    }

                    {this.props.type !== "MAIN_CATEGORY" && <li className="wizard-input-type-wrapper">
                        <div className="wizard-criteria">
                            <DiscoveryCriteria
                                assetList={this.state.assetItems}
                                type={this.props.type}
                                onCriteriaRemove={this.handleCriteriaRemove}
                                onCriteriaChange={this.handleCriteriaChange}
                                onHandleCriteria={this.handleAssetItems}
                            />
                        </div>
                    </li>}

                    {this.state.showRequiredError ? (
                        <span
                            className="item-error has-error"
                            style={{
                                display: "block",
                                marginTop: "10px",
                                padding: "15px",
                            }}
                        >
                  {t(this.props.type === "MAIN_CATEGORY" ?
                      "You should make at least one selection from Main Categories above" :
                      "You should make at least one selection from Segmentify Intelligent or Static algorithms"
                  )}
                </span>
                    ) : (
                        ""
                    )}


                </div>
            </ol>
        );
    }
}

export default DiscoveryItems;