import { createReducer } from "../system/store";
import * as searchInsightsActions from "../constants/actions/search-insights";

const initialState = {
    tableName: "",
    tabName: ""
};

export default createReducer(initialState, {
    [searchInsightsActions.default.OPEN_DETAIL_TABLE]: (state, payload) => {
        return Object.assign({}, state, {tableName: payload.tableName});
    },
    [searchInsightsActions.default.TAB_CHANGED]: (state, payload) => {
        return Object.assign({}, state, {tabName: payload.tabName.name});
    }
})
