export const noUpdateFieldsDictionary = [
  { value: 'title', label: 'Title' },
  { value: 'category', label: 'Category' },
  { value: 'url', label: 'URL' },
  { value: 'mUrl', label: 'Mobile URL' },
  { value: 'image', label: 'Image' },
  { value: 'inStock', label: 'In Stock' },
  { value: 'brand', label: 'Brand' },
  { value: 'price', label: 'Price' },
  { value: 'oldPrice', label: 'Old Price' },
  { value: 'specialPrice', label: 'Special Price' },
  { value: 'gender', label: 'Gender' },
  { value: 'labels', label: 'Labels' },
  { value: 'sizes', label: 'Sizes' },
  { value: 'colors', label: 'Colors' },
  { value: 'groupId', label: 'Group Id' },
  { value: 'imageXS', label: 'Image X Small' },
  { value: 'imageS', label: 'Image Small' },
  { value: 'imageM', label: 'Image Medium' },
  { value: 'imageL', label: 'Image Large' },
  { value: 'imageXL', label: 'Image X Large' },
  { value: 'additionalImages', label: 'Additional Images' },
  { value: 'mainCategory', label: 'Main Category' },
  { value: 'stockCount', label: 'Stock Count' },
  { value: 'stockStatus', label: 'Stock Status' },
  { value: 'stockRatio', label: 'Stock Ratio' },
  { value: 'publishTime', label: 'Publish Time' },
  { value: 'badges', label: 'Badges' },
  { value: 'allSizes', label: 'All Sizes' },
  { value: 'params', label: 'Parameters' },
  { value: 'paramsList', label: 'Parameters List' },
  { value: 'scoreCount', label: 'Score Count' },
  { value: 'reviewCount', label: 'Review Count' },
];

export const initialNoUpdateState = {
  pc: {
    fieldList: [],
  },
  mobile: {
    fieldList: [],
  },
  tablet: {
    fieldList: [],
  },
  ios: {
    fieldList: [],
  },
  android: {
    fieldList: [],
  },
};
