import React from 'react';
import { clone } from 'system/object';
import { t } from '../../system/ui';
import classNames from 'classnames';
import { SFYCheckBox } from '../sfy-components/module-exports';
import isEqual from "lodash/isEqual";

export default class LastSearches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clearQueryBasedEnabled:
        this.props.assetItems[0]?.values?.clearQueryBasedEnabled || false,
      itemCount: this.props.assetItems[0]?.values?.itemCount?.value || 1,
    };
    this.options = this.createOptions();
    this.stateSetter = this.stateSetter.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const nextItemCount = nextProps.assetItems[0]?.values?.itemCount?.value;
    const nextclearQueryBasedEnabled =
      nextProps.assetItems[0]?.values?.clearQueryBasedEnabled;
    const currentItemCount = this.state.itemCount;
    const currentclearQueryBasedEnabled = this.state.clearQueryBasedEnabled;
    if (!isEqual(nextItemCount, currentItemCount) || !isEqual(nextclearQueryBasedEnabled, currentclearQueryBasedEnabled)) {
      this.setState({
        clearQueryBasedEnabled: nextclearQueryBasedEnabled,
        itemCount: nextItemCount,
      });
    }
  }

  onChange = () => {
    const assetItems = clone(this.props.assetItems);

    if (assetItems.length === 0) {
      assetItems.push({
        id: Math.random(),
        type: 'intelligent',
        values: {
          criterion: {
            value: 'LAST_SEARCHES',
          },
          itemCount: {
            value: this.state.itemCount,
          },
          clearQueryBasedEnabled: this.state.clearQueryBasedEnabled,
          clearButtonEnabled: false,
        },
      });
    } else {
      assetItems[0].values.clearQueryBasedEnabled = this.state.clearQueryBasedEnabled;
      assetItems[0].values.itemCount.value = this.state.itemCount;
    }

    this.props.handleAsset(assetItems);
  };

  stateSetter = (type, value) => {
    this.setState({ [type]: value }, () => {
      this.onChange();
    });
  };

  createOptions() {
    const options = Array(10)
      .fill()
      .map((_, i) => <option value={i + 1}>{i + 1}</option>);
    options.unshift(<option value=''></option>);
    return options;
  }

  render() {
    const hasError = this.state.itemCount === '';
    return (
      <>
        <label
          className={classNames(
            'item item-field is-select criteria-item-mobile-count',
            { 'has-error': hasError },
          )}
          style={{ width: '200px' }}
        >
          <span className='item-label'>keyword</span>
          <select
            value={this.state.itemCount}
            onChange={e =>
              this.stateSetter('itemCount', parseInt(e.target.value))
            }
            name='mobileItemCount'
            className='one-whole criteria-field'
          >
            {this.options}
          </select>
          {hasError && (
            <span className='item-error'>
              <span>{t('This field is required')}</span>
            </span>
          )}
        </label>
        <SFYCheckBox
          label='Clear Button for Individual Keywords'
          checked={this.state.clearQueryBasedEnabled}
          searchActiveSetter={val => this.stateSetter('clearQueryBasedEnabled', val)}
        />
      </>
    );
  }
}
