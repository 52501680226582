/**
 * @fileOverview Product Bundle Actions
 * @description This file contains the actions for the Product Bundle component
 * @param {React.dispatch}dispatch
 * @returns {void}
 */

export const setPBProductList = dispatch => {
  return productList => {
    dispatch({
      type: 'SET_PRODUCT_BUNDLE_PRODUCT_LIST',
      payload: productList,
    });
  };
};

export const setPBCategoryList = dispatch => {
  return categoryList => {
    dispatch({
      type: 'SET_PRODUCT_BUNDLE_CATEGORY_LIST',
      payload: categoryList,
    });
  };
};

export const setActiveBundleDetails = dispatch => {
  return activeBundleDetails => {
    dispatch({
      type: 'SET_ACTIVE_BUNDLE_DETAILS',
      payload: activeBundleDetails,
    });
  };
};

export const editActiveBundleDetails = dispatch => {
  return activeBundleDetails => {
    dispatch({
      type: 'EDIT_ACTIVE_BUNDLE_DETAILS',
      payload: activeBundleDetails,
    });
  };
};

export const resetProductBundle = dispatch => {
  return () => {
    dispatch({
      type: 'RESET_PRODUCT_BUNDLE',
    });
  };
};
