import { getRestEndPoint } from '../system/api';
import { getApiKey } from '../modules/auth/user';
import { $http } from '../system/ajax';

class Services {
  constructor({ endPoint, paginationIsActive }) {
    this.endPoint = endPoint;
    this.paginationIsActive = paginationIsActive;
  }

  async getAll(config) {
    let params = '';
    if (this.paginationIsActive) {
      const {
        pageNumber = 1,
        payloadSize = 20,
        searchQueryString = '',
        itemType = '',
      } = config;
      const conditionalPageNumber = searchQueryString ? 1 : pageNumber;
      params = `&pageNo=${conditionalPageNumber}&pageSize=${payloadSize}&query=${searchQueryString}&itemType=${itemType}`;
    }
    const url = `${getRestEndPoint(
      this.endPoint,
    )}?apiKey=${getApiKey()}${params}`;
    return await $http(url).get();
  }

  async getAllWithCampaignType({ campaignType }) {
    const url = `${getRestEndPoint(
      this.endPoint,
    )}?apiKey=${getApiKey()}&campaignType=${campaignType}`;
    return await $http(url).get();
  }

  async create({ body }) {
    return await $http(
      `${getRestEndPoint(this.endPoint)}?apiKey=${getApiKey()}`,
    ).post(body, { type: 'json' });
  }

  async get({ id, query = '' }) {
    const url = `${getRestEndPoint(
      this.endPoint,
    )}/${id}?apiKey=${getApiKey()}${query && `&${query}`}`;
    return await $http(url).get();
  }

  async getWithParams({ id }) {
    const url = `${getRestEndPoint(
      this.endPoint,
    )}?id=${id}&apiKey=${getApiKey()}`;
    return await $http(url).get();
  }

  async update({ id = null, body }) {
    let url;
    if (!id) {
      url = `${getRestEndPoint(this.endPoint)}?apiKey=${getApiKey()}`;
    } else {
      url = `${getRestEndPoint(this.endPoint)}/${id}?apiKey=${getApiKey()}`;
    }
    return await $http(url).put(body, { type: 'json' });
  }

  async updateWithInsideId({ body }) {
    // This is a special case where the id is inside the body
    const url = `${getRestEndPoint(this.endPoint)}?apiKey=${getApiKey()}`;
    return await $http(url).put(body, { type: 'json' });
  }

  async updateStatus({ id, body, status }) {
    const url = `${getRestEndPoint(
      this.endPoint,
    )}/${id}/${status}?apiKey=${getApiKey()}`;
    return await $http(url).put(body, { type: 'json' });
  }

  async updatewithIdParam({ id, body }) {
    const url = `${getRestEndPoint(
      this.endPoint,
    )}?apiKey=${getApiKey()}&id=${id}`;
    return await $http(url).put(body, { type: 'json' });
  }

  async delete({ id }) {
    const url = `${getRestEndPoint(this.endPoint)}/${id}?apiKey=${getApiKey()}`;
    return await $http(url).delete();
  }

  async multiDelete({ ids, bundleType, isDeleteAll }) {
    const body = { ids, bundleType };
    const url = `${getRestEndPoint(
      this.endPoint,
    )}/delete?isDeleteAll=${isDeleteAll}&apiKey=${getApiKey()}`;
    return await $http(url).delete(body, { type: 'json' });
  }

  async deleteWithParams({ id }) {
    const url = `${getRestEndPoint(
      this.endPoint,
    )}?id=${id}&apiKey=${getApiKey()}`;
    return await $http(url).delete();
  }

  async updateWithRequestParam({ id, requestKey, requestParam }) {
    const url = `${getRestEndPoint(
      this.endPoint,
    )}/${id}?apiKey=${getApiKey()}&${requestKey}=${requestParam}`;
    return await $http(url).put();
  }

  async excelImport(file, callback) {
    const url = `${getRestEndPoint(this.endPoint)}`;
    const formData = new FormData();
    formData.append('file', file);
    return await $http(url)
      .post(formData, { type: 'form', fileType: file.type })
      .then(callback);
  }

  async updatePriority({ body }) {
    const url = `${getRestEndPoint(this.endPoint)}?apiKey=${getApiKey()}`;
    return await $http(url).post(body, { type: 'json' });
  }

  async fileUpload(file, config) {
    const url = `${getRestEndPoint(this.endPoint)}?apiKey=${getApiKey()}`;
    const formData = new FormData();
    formData.append('file', file);
    if (config && config.length > 0) {
      config.forEach(item => {
        formData.append(item.name, item.value);
      });
    }
    return await $http(url).post(formData, {
      type: 'form',
    });
  }

  async specialGet({ id, tag }) {
    const url = `${getRestEndPoint(
      this.endPoint,
    )}/${tag}/${id}?apiKey=${getApiKey()}`;
    return await $http(url).get();
  }

  async fileUploadAsJSON(body) {
    const url = `${getRestEndPoint(this.endPoint)}?apiKey=${getApiKey()}`;
    return await $http(url).post(body, {
      type: 'json',
    });
  }
}

export default Services;
