import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const MoveIcon = ({ width = '32px', height = '32px', color = '#eee' }) => {
  const [state, setState] = useState({
    width: '',
    height: '',
    color: '',
  });

  const setStyles = () => {
    setState({
      width,
      height,
      color,
    });
  };

  useEffect(() => {
    setStyles();
  }, [width, height, color]);

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 14'
      width={state.width}
      height={state.height}
      className='svg-icon'
    >
      <path
        d='M9.18678 3.02026C8.99152 3.21552 8.67498 3.21552 8.47972 3.02026L7.49992 2.04048V4.50004C7.49992 4.77618 7.27605 5.00004 6.99992 5.00004C6.72378 5.00004 6.49992 4.77618 6.49992 4.50004V2.04048L5.52014 3.02026C5.32488 3.21552 5.00829 3.21552 4.81303 3.02026C4.61777 2.825 4.61777 2.50841 4.81303 2.31315L6.64638 0.479821C6.74012 0.386054 6.86732 0.333374 6.99992 0.333374C7.13252 0.333374 7.25972 0.386054 7.35345 0.479821L9.18678 2.31315C9.38205 2.50841 9.38205 2.825 9.18678 3.02026ZM3.02014 8.47984C3.2154 8.67511 3.2154 8.99164 3.02014 9.18691C2.82488 9.38217 2.50829 9.38217 2.31303 9.18691L0.479699 7.35357C0.385932 7.25984 0.333252 7.13264 0.333252 7.00004C0.333252 6.86744 0.385932 6.74024 0.479699 6.64651L2.31303 4.81315C2.50829 4.61789 2.82488 4.61789 3.02014 4.81315C3.2154 5.00841 3.2154 5.325 3.02014 5.52026L2.04036 6.50004H4.49992C4.77606 6.50004 4.99992 6.72391 4.99992 7.00004C4.99992 7.27617 4.77606 7.50004 4.49992 7.50004H2.04036L3.02014 8.47984ZM10.9797 9.18691C10.7845 8.99164 10.7845 8.67511 10.9797 8.47984L11.9595 7.50004H9.49992C9.22378 7.50004 8.99992 7.27617 8.99992 7.00004C8.99992 6.72391 9.22378 6.50004 9.49992 6.50004H11.9595L10.9797 5.52026C10.7845 5.325 10.7845 5.00841 10.9797 4.81315C11.175 4.61789 11.4915 4.61789 11.6868 4.81315L13.5201 6.64651C13.6139 6.74024 13.6666 6.86744 13.6666 7.00004C13.6666 7.13264 13.6139 7.25984 13.5201 7.35357L11.6868 9.18691C11.4915 9.38217 11.175 9.38217 10.9797 9.18691ZM9.18678 10.9798C8.99152 10.7846 8.67498 10.7846 8.47972 10.9798L7.49992 11.9596V9.50004C7.49992 9.22391 7.27605 9.00004 6.99992 9.00004C6.72378 9.00004 6.49992 9.22391 6.49992 9.50004V11.9596L5.52014 10.9798C5.32488 10.7846 5.00829 10.7846 4.81303 10.9798C4.61777 11.1751 4.61777 11.4916 4.81303 11.6869L6.64638 13.5202C6.74012 13.614 6.86732 13.6667 6.99992 13.6667C7.13252 13.6667 7.25972 13.614 7.35345 13.5202L9.18678 11.6869C9.38205 11.4916 9.38205 11.1751 9.18678 10.9798Z'
        fill={state.color}
      />
    </svg>
  );
};

MoveIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

MoveIcon.defaultProps = {
  width: '1rem',
  height: '1rem',
  color: '#eee',
};

export default MoveIcon;
