import React from "react";

class IconSegment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
        height = this.props.height ? this.props.height + "px" : this.state.height,
        color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 550 550"
            width={this.state.width}
            height={this.state.height}
            fill={this.state.color}
        >
          <g>
            <path d="M422.7 229.6L300 300L422.7 370L300 439.9L177.3 370L52.7998 440.9L300 581.8L547.2 440.9V300L422.7 229.6Z" />
            <path d="M177.3 370.4L300 300L177.3 230L300 160.1L422.7 230L547.2 159.1L300 18.2L52.7998 159.1V300L177.3 370.4Z" />
          </g>
        </svg>
    );
  }
}

export default IconSegment;
