import React from 'react';
import { v4 as uuid } from 'uuid';
import styles from './AlertTableBody.module.scss';
import { modalActions } from '../../../../actions';
import { t } from '../../../../system/ui';
import AlertModal from './AlertModal';

const AlertTableBody = ({ alerts }) => {
  const openMonitoringAlertModal = (accountId, domain) => {
    modalActions.openModal({
      title: t(`Account Alert Details - ${domain} - ${accountId}`),
      content: () => <AlertModal accountId={accountId} />,
      className: 'alert-modal',
    });
  };

  // since server response is as SUCCESS and SFY-11487 wants this field as OK
  // conversion is needed
  const textFixer = text => {
    return text === 'SUCCESS' ? 'OK' : text;
  };

  return (
    <tbody>
      {alerts.map((item, index) => {
        const cssProdFeedMonitoring = `color-${item.productFeedMonitoring?.toLowerCase()}`;
        const cssPageViewMonitoring = `color-${item.pageViewMonitoring?.toLowerCase()}`;
        const cssPurchaseMonitoring = `color-${item.purchaseMonitoring?.toLowerCase()}`;
        const cssRecoPerfMonitoring = `color-${item.recommendationPerformanceMonitoring?.toLowerCase()}`;
        const cssGenEventMonitoring = `color-${item.generalEventMonitoring?.toLowerCase()}`;
        const cssPushCampMonitoring = `color-${item.pushCampaignMonitoring?.toLowerCase()}`;
        const cssEmailCamMonitoring = `color-${item.emailCampaignMonitoring?.toLowerCase()}`;
        const cssSegmentifyJsMonitoring = `color-${item.segmentifyJsMonitoring?.toLowerCase()}`;
        const cssSearchJsMonitoring = `color-${item.searchJsMonitoring?.toLowerCase()}`;
        const cssSwJsMonitoring = `color-${item.swJsMonitoring?.toLowerCase()}`;
        const cssContactsApiMonitoring = `color-${item.contactsApiMonitoring?.toLowerCase()}`;
        return (
          <tr key={uuid()} className='accounts-row'>
            <td>{index + 1}</td>
            <td
              onClick={() =>
                openMonitoringAlertModal(item.accountId, item.domain)
              }
              className='domain'
            >
              {item.domain}
            </td>
            <td>{item.accountId}</td>
            <td className={styles[cssSegmentifyJsMonitoring]}>
              {textFixer(item.segmentifyJsMonitoring)}
            </td>
            <td className={styles[cssProdFeedMonitoring]}>
              {textFixer(item.productFeedMonitoring)}
            </td>
            <td className={styles[cssPageViewMonitoring]}>
              {textFixer(item.pageViewMonitoring)}
            </td>
            <td className={styles[cssPurchaseMonitoring]}>
              {textFixer(item.purchaseMonitoring)}
            </td>
            <td className={styles[cssRecoPerfMonitoring]}>
              {textFixer(item.recommendationPerformanceMonitoring)}
            </td>
            <td className={styles[cssSearchJsMonitoring]}>
              {textFixer(item.searchJsMonitoring)}
            </td>
            <td className={styles[cssSwJsMonitoring]}>
              {textFixer(item.swJsMonitoring)}
            </td>
            <td className={styles[cssPushCampMonitoring]}>
              {textFixer(item.pushCampaignMonitoring)}
            </td>
            <td className={styles[cssEmailCamMonitoring]}>
              {textFixer(item.emailCampaignMonitoring)}
            </td>
            <td className={styles[cssContactsApiMonitoring]}>
              {textFixer(item.contactsApiMonitoring)}
            </td>
            <td className={styles[cssGenEventMonitoring]}>
              {textFixer(item.generalEventMonitoring)}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default AlertTableBody;
