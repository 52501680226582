import React from 'react';
import PropTypes from 'prop-types';

export default function Trophy(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${props.width}px`}
      height={`${props.height}px`}
      viewBox='0 0 16 18'
      fill='none'
    >
      <path
        d='M3 3.37502H1.64583C1.5883 3.37502 1.54167 3.42165 1.54167 3.47919V6.13544C1.54167 7.0184
        2.16942 7.75469 3.00292 7.92248C3.00096 7.86523 3 7.80773 3 7.75002V3.37502ZM3.15558 8.99252C1.6711
        8.88548 0.5 7.64723 0.5 6.13544V3.47919C0.5 2.84636 1.01301 2.33335 1.64583 2.33335H3.003C3.05692
        1.40383 3.82779 0.666687 4.77083 0.666687H11.2292C12.1722 0.666687 12.9431 1.40384 12.997
        2.33335H14.3542C14.987 2.33335 15.5 2.84636 15.5 3.47919V6.13544C15.5 7.64723 14.3289 8.88548
        12.8444 8.99252C12.3336 10.9899 10.62 12.5059 8.52083 12.7232V14.2084H10.9167C12.0672 14.2084
        13 15.1411 13 16.2917V16.5C13 16.9603 12.6269 17.3334 12.1667 17.3334H3.83333C3.37308 17.3334
        3 16.9603 3 16.5V16.2917C3 15.1411 3.93275 14.2084 5.08333 14.2084H7.47917V12.7232C5.38 12.5059
        3.66637 10.9899 3.15558 8.99252ZM12.9971 7.92248C13.8306 7.75469 14.4583 7.0184 14.4583
        6.13544V3.47919C14.4583 3.42165 14.4117 3.37502 14.3542 3.37502H13V7.75002C13 7.80773 12.999
        7.86523 12.9971 7.92248ZM4.04167 2.43752V7.75002C4.04167 9.93615 5.81387 11.7084 8 11.7084C10.1861
        11.7084 11.9583 9.93615 11.9583 7.75002V2.43752C11.9583 2.03481 11.6319 1.70835 11.2292 1.70835H4.77083C4.36812
        1.70835 4.04167 2.03481 4.04167 2.43752ZM4.04167 16.2917H11.9583C11.9583 15.7164 11.492 15.25 10.9167
        15.25H5.08333C4.50804 15.25 4.04167 15.7164 4.04167 16.2917Z'
        fill={props.color}
      />
    </svg>
  );
}

Trophy.defaultProps = {
  color: '#BABABA',
  width: 16,
  height: 16,
};

Trophy.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
