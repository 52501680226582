/**
 * @author Bilal Cinarli
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Icons from '../components/icons';

import { accountsTable } from '../constants/datamaps/account';

import {
  filterActions,
  modalActions,
  switchUserActions,
  uiActions,
} from '../actions';

import { reformatDate } from '../system/date';
import { toSlug } from '../system/string';

import {
  isMasterUser,
  isSuperUser,
  redirectUserToFirstPage,
} from '../modules/auth/user';

import {
  getAccountTypesMap,
  getAccounts,
  getUsers,
} from '../modules/account/ajax';

import { setTitle } from '../system/document';
import { t } from '../system/ui';

import AdminActions from '../components/admin/admin-actions';
import SelectUser from '../components/admin/select-user';
import {
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
} from '../components/fields';
import Icon from '../components/icon';
import Tooltip from '../components/tooltip';
import setHighchartsDefaults from '../modules/highcharts.global';
import { AccountStatus } from '../constants/datamaps/modals';

const types = getAccountTypesMap('ARRAY');

class Admin_Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      _accounts: [],
      _users: [],
      filter: 'type',
      filters: ['type', 'provider'],
      types,
      providers: [
        'SEGMENTIFY',
        'INTERNAL',
        'BIGCOMMERCE',
        'SHOPIFY',
        'TSOFT',
        'DENGAGE',
      ],
      ui: 'isLoading',
      sorting: 'membershipDate',
      reverse: true,
      selectedFilter: 'type',
      selectedType: [],
      defaultTypes: types,
      searched: false,
    };

    this.sort = this.sort.bind(this);
    this.listAccounts = this.listAccounts.bind(this);
    this.accountUsers = this.accountUsers.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.filterAccounts = this.filterAccounts.bind(this);
    this.filterByType = this.filterByType.bind(this);
    this.filterByProvider = this.filterByProvider.bind(this);
    this.switchAccount = this.switchAccount.bind(this);
    this.updateAccount = this.updateAccount.bind(this);
    this.onArchive = this.onArchive.bind(this);
    this.toggleAccountTypes = this.toggleAccountTypes.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.onPushUpdate = this.onPushUpdate.bind(this);
    this.onGaUpdate = this.onGaUpdate.bind(this);
    this.onGa4Update = this.onGa4Update.bind(this);
  }

  componentDidMount() {
    setTitle(t('Accounts'));

    uiActions.resetPage();
    uiActions.addPageClass('sidebar-is-hidden');
    localStorage.removeItem('account');

    if (isSuperUser()) {
      this.listAccounts(types);
      this.accountUsers();
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.user !== newProps.user) {
      if (!isSuperUser(newProps.user)) {
        redirectUserToFirstPage();
      } else {
        this.listAccounts(types);
        this.accountUsers();
      }
    }

    if (newProps.switchedUser !== this.props.switchedUser) {
      redirectUserToFirstPage();
    }

    if (newProps.reset === true) {
      this.reset();
    }
  }

  updateAccount(updatedAccount) {
    modalActions.closeModal();

    const updatedAccounts = [];
    const updatedCurrentAccounts = [];

    this.state._accounts.forEach(account => {
      const accountToPush =
        account.accountId === updatedAccount.accountId
          ? updatedAccount
          : account;
      updatedAccounts.push(accountToPush);
    });

    this.state.accounts.forEach(account => {
      const accountToPush =
        account.accountId === updatedAccount.accountId
          ? updatedAccount
          : account;
      if (this.state.selectedType.length) {
        if (this.state.selectedType.indexOf(account.type) !== -1) {
          updatedCurrentAccounts.push(accountToPush);
        }
      } else {
        updatedCurrentAccounts.push(accountToPush);
      }
    });

    this.setState({
      _accounts: updatedAccounts,
      accounts: updatedCurrentAccounts,
    });
  }

  reset() {
    filterActions.filtersReset();
    this.listAccounts();
  }

  onPushUpdate(accountId, data) {
    const updatedAccounts = this.state._accounts.map(account => {
      if (account.accountId === accountId) {
        const newObj = { ...account };
        if (!newObj.pushConfiguration) {
          newObj.pushConfiguration = {
            webConfiguration: {},
          };
        }

        newObj.pushConfiguration.webConfiguration = {
          ...newObj.pushConfiguration.webConfiguration,
          ...data,
        };
        return newObj;
      }
      return account;
    });

    const updatedCurrentAccounts = this.state.accounts.map(account => {
      if (account.accountId === accountId) {
        const newObj = { ...account };
        if (!newObj.pushConfiguration) {
          newObj.pushConfiguration = {
            webConfiguration: {},
          };
        }

        newObj.pushConfiguration.webConfiguration = {
          ...newObj.pushConfiguration.webConfiguration,
          ...data,
        };
        return newObj;
      }
      return account;
    });
    this.setState({
      _account: updatedAccounts,
      accounts: updatedCurrentAccounts,
    });
  }

  onGaUpdate(accountId, data) {
    const updatedAccounts = this.state._accounts.map(account => {
      if (account.accountId === accountId) {
        const newObj = { ...account };
        if (!newObj.googleAnalytics) {
          newObj.googleAnalytics = {
            googleAnalytics: {},
          };
        }
        newObj.googleAnalytics = {
          ...newObj.googleAnalytics,
          ...data,
        };
        return newObj;
      }
      return account;
    });

    const updatedCurrentAccounts = this.state.accounts.map(account => {
      if (account.accountId === accountId) {
        const newObj = { ...account };
        if (!newObj.googleAnalytics) {
          newObj.googleAnalytics = {
            googleAnalytics: {},
          };
        }
        newObj.googleAnalytics = {
          ...newObj.googleAnalytics,
          ...data,
        };
        return newObj;
      }
      return account;
    });
    this.setState({
      _account: updatedAccounts,
      accounts: updatedCurrentAccounts,
    });
  }

  onGa4Update(accountId, data) {
    const updatedAccounts = this.state._accounts.map(account => {
      if (account.accountId === accountId) {
        const newObj = { ...account };
        if (!newObj.ga4) {
          newObj.ga4 = {
            ga4: {},
          };
        }
        newObj.ga4 = {
          ...newObj.ga4,
          ...data,
        };
        return newObj;
      }
      return account;
    });

    const updatedCurrentAccounts = this.state.accounts.map(account => {
      if (account.accountId === accountId) {
        const newObj = { ...account };
        if (!newObj.ga4) {
          newObj.ga4 = {
            ga4: {},
          };
        }
        newObj.ga4 = {
          ...newObj.ga4,
          ...data,
        };
        return newObj;
      }
      return account;
    });
    this.setState({
      _account: updatedAccounts,
      accounts: updatedCurrentAccounts,
    });
  }

  sorter(sorting, list, reverse = false) {
    const _list = list;
    let mapped;
    let sorted;

    mapped = _list.map(function(item, i) {
      const index = i;
      let value = item[sorting];

      if (typeof value === 'string') {
        value = value.toLowerCase();
      }

      if (sorting == 'importantDates.freeTrialStart') {
        value = item.importantDates.freeTrialStart;
        value = value || '1';
      } else if (sorting == 'importantDates.freeTrialEnd') {
        value = item.importantDates.freeTrialEnd;
        value = value || '1';
      }

      return { index, value };
    });

    mapped.sort(function(a, b) {
      if (reverse === true) {
        return +(a.value < b.value) || +(a.value === b.value) - 1;
      }
      return +(a.value > b.value) || +(a.value === b.value) - 1;
    });

    sorted = mapped.map(function(item) {
      return _list[item.index];
    });

    return sorted;
  }

  sort(sorting) {
    const reverse =
      this.state.sorting === sorting ? !this.state.reverse : false;
    const sorted = this.sorter(sorting, this.state.accounts, reverse);

    this.setState({
      accounts: sorted,
      reverse,
      sorting,
    });
  }

  accountUsers() {
    getUsers(response => {
      const accountUsers = {};

      response.forEach(item => {
        item.switchableAccounts &&
          item.switchableAccounts.forEach(accountId => {
            if (typeof accountUsers[accountId] === 'undefined') {
              accountUsers[accountId] = [];
            }
            accountUsers[accountId].push({
              user: item,
              isOwner: item.account.accountId === accountId,
            });
          });
      });

      this.setState({
        _users: accountUsers,
      });
    });
  }

  listAccounts(types) {
    uiActions.isLoading();
    this.setState({
      ui: 'isLoading',
    });

    getAccounts(null, response => {
      const sorted = this.sorter(
        this.state.sorting,
        response,
        this.state.reverse,
      );

      this.setState(
        {
          accounts: sorted,
          _accounts: response,
          ui: 'isLoaded',
        },
        () => {
          if (types) {
            this.filterAccounts('type', types);
          } else {
            this.setState({
              defaultTypes: [],
            });
          }
        },
      );

      uiActions.isLoaded();
    });
  }

  toggleFilter(value) {
    this.setState(
      {
        filter: value,
        accounts: this.state._accounts,
        selectedType: [],
        defaultTypes: types,
      },
      () => {
        this.refs.accountSearch.value = '';
        if (value === 'type') {
          this.filterByType(types);
        }
      },
    );
  }

  filterByType(value) {
    this.filterAccounts('type', value);
  }

  filterByProvider(value) {
    this.filterAccounts('provider', value);
  }

  filterAccounts(filter, value) {
    const accounts = this.state._accounts;
    let filtered;

    this.setState({
      ui: 'isLoading',
    });

    if (value.length === 0) {
      this.setState({
        accounts: this.sorter(this.state.sorting, accounts, this.state.reverse),
        ui: 'isLoaded',
        selectedFilter: filter,
        selectedType: [],
        defaultTypes: value,
      });

      return;
    }

    filtered = accounts.filter(account => {
      return value.indexOf(account[filter]) > -1;
    });

    this.setState({
      accounts: this.sorter(this.state.sorting, filtered, this.state.reverse),
      ui: 'isLoaded',
      selectedFilter: filter,
      selectedType: value,
      defaultTypes: value,
    });
  }

  searchHandler(e) {
    const accounts = this.state._accounts;
    let matchedSearch;
    const search = e.currentTarget.value.toLowerCase();
    const code = e.keyCode ? e.keyCode : e.which;

    if (code == 13) {
      this.setState({
        ui: 'isLoading',
      });

      matchedSearch = accounts.filter(account => {
        return (
          (account.domain &&
            account.domain.toLowerCase().indexOf(search) > -1) ||
          account.accountId.toLowerCase().indexOf(search) > -1 ||
          account.panelVersion.toLowerCase().indexOf(search) > -1 ||
          (account.contact &&
            account.contact.person &&
            account.contact.person.toLowerCase().indexOf(search) > -1) ||
          (account.contact &&
            account.contact.email &&
            account.contact.email.toLowerCase().indexOf(search) > -1) ||
          (account.description &&
            account.description.toLowerCase().indexOf(search) > -1)
        );
      });

      let sorted = this.sorter(
        this.state.sorting,
        matchedSearch,
        this.state.reverse,
      );

      if (this.state.selectedType.length > 0) {
        sorted = sorted.filter(account => {
          return (
            this.state.selectedType.indexOf(
              account[this.state.selectedFilter],
            ) > -1
          );
        });
      }

      this.setState({
        accounts: sorted,
        ui: 'isLoaded',
        searched: true,
      });
    }
  }

  switchAccount(account) {
    if (account.type === 'PANEL_RESTRICTED' && !isMasterUser()) {
      uiActions.openModal({
        title: 'Important Notice!',
        content: () => <AccountStatus type={account.type} />,
      });

      return;
    }

    const accountUsers = this.state._users;
    const switchedAccount = accountUsers[account.accountId][0].user;

    let allSwitchableAccounts = [];
    let switchableAccountsWithDetails = [];

    accountUsers[account.accountId].forEach(user => {
      if (user.user.switchableAccounts) {
        allSwitchableAccounts = allSwitchableAccounts.concat(
          user.user.switchableAccounts,
        );
      }
    });

    switchableAccountsWithDetails = this.state._accounts.filter(account => {
      return allSwitchableAccounts.indexOf(account.accountId) > -1;
    });

    if (accountUsers[account.accountId].length > 1) {
      const modal = () => (
        <SelectUser
          account={account}
          users={accountUsers[account.accountId]}
          accounts={this.state.accounts}
          switchableAccountsWithDetails={switchableAccountsWithDetails}
        />
      );
      modalActions.openModal({
        title: t('Select User'),
        content: modal,
      });
    } else {
      this.state.accounts.forEach(account => {
        if (account.accountId === switchedAccount.account.accountId) {
          switchedAccount.account = account;
        }
      });
      switchUserActions.updateSwitchUser({
        ...switchedAccount,
        switchableAccountsWithDetails,
      });
      setHighchartsDefaults({
        ...switchedAccount,
        switchableAccountsWithDetails,
      });
    }
  }

  onArchive(archivedAccount) {
    const excludeAccount = (list, archivedAccount) => {
      const filteredList = list.filter(account => {
        return account.accountId !== archivedAccount.accountId;
      });
      return filteredList;
    };
    this.setState({
      accounts: excludeAccount(this.state.accounts, archivedAccount),
      _accounts: excludeAccount(this.state._accounts, archivedAccount),
    });
  }

  toggleAccountTypes() {
    const newVal = this.state.defaultTypes.length === 0 ? this.state.types : [];
    this.setState(
      {
        defaultTypes: newVal,
      },
      () => {
        this.filterAccounts('type', newVal);
      },
    );
  }

  resetSearch() {
    if (this.state.searched) {
      const searchInput = this.refs.accountSearch;
      searchInput.value = '';
      const accounts = this.state._accounts;
      let sorted = this.sorter(
        this.state.sorting,
        accounts,
        this.state.reverse,
      );
      if (this.state.selectedType.length > 0) {
        sorted = sorted.filter(account => {
          return (
            this.state.selectedType.indexOf(
              account[this.state.selectedFilter],
            ) > -1
          );
        });
      }
      this.setState({
        accounts: sorted,
        ui: 'isLoaded',
        searched: false,
      });
    }
  }

  render() {
    const containerClass =
      this.state.accounts.length <= 10 ? 'accounts-vertical-result' : '';
    return (
      <div className={containerClass}>
        <h2 className='page-title'>{t('Accounts')}</h2>

        <div className='page-options'>
          <label className='item is-stacked is-search'>
            <span
              onClick={this.resetSearch}
              style={{ cursor: this.state.searched ? 'pointer' : 'initial' }}
            >
              <Icon name={this.state.searched ? 'cross' : 'magnify'} />
            </span>
            <input
              type='text'
              placeholder={t('Search and hit enter')}
              className='text-field'
              onKeyPress={this.searchHandler}
              ref='accountSearch'
            />
          </label>
        </div>

        <div className='page-filters  has-filter-form' ref='pageFilters'>
          <div className='status-filters'>
            <RadioGroup
              name='status'
              selectedValue={this.state.filter}
              onChange={this.toggleFilter}
              className='parent-filters'
            >
              {this.state.filters.map(filter => {
                return <Radio key={filter} value={filter} label={t(filter)} />;
              })}
            </RadioGroup>

            <CheckboxGroup
              name='type'
              onChange={this.filterByType}
              className={classNames('filter-details', {
                'is-hidden': this.state.filter !== 'type',
              })}
              ref='typeCheckboxContainer'
              value={this.state.defaultTypes}
            >
              <span
                onClick={this.toggleAccountTypes}
                className='accounts-toggle'
              >
                {this.state.defaultTypes.length === this.state.types.length && (
                  <Icons name='checkboxSelected' />
                )}
                {this.state.defaultTypes.length !== this.state.types.length &&
                  (this.state.defaultTypes.length === 0 ? (
                    <Icons name='checkboxEmpty' />
                  ) : (
                    <Icons name='checkboxPartial' />
                  ))}
              </span>
              {this.state.types.map(type => {
                return (
                  <Checkbox
                    key={type}
                    value={type}
                    label={type === 'PANEL_RESTRICTED' ? 'Restricted' : type}
                  />
                );
              })}
            </CheckboxGroup>

            <CheckboxGroup
              name='provider'
              onChange={this.filterByProvider}
              className={classNames('filter-details', {
                'is-hidden': this.state.filter !== 'provider',
              })}
              ref='providerCheckboxContainer'
            >
              {this.state.providers.map(provider => {
                return (
                  <Checkbox
                    key={provider}
                    value={provider}
                    label={t(provider)}
                  />
                );
              })}
            </CheckboxGroup>
          </div>
        </div>
        <div className='vertical-content'>
          <div
            className={classNames(
              'account-details all-accounts account-last-child',
              { 'page-progress': this.state.ui === 'isLoading' },
            )}
          >
            <table className='data-table'>
              <thead>
                <tr>
                  {accountsTable.header.map((item, index) => {
                    let { text } = item;
                    const alignment = item.alignment || 'center';
                    const className = classNames('', item.className, {
                      'sort-up':
                        this.state.reverse === true &&
                        this.state.sorting === item.sort,
                      'sort-down':
                        this.state.reverse === false &&
                        this.state.sorting === item.sort,
                    });

                    if (item.icon) {
                      text = <Icon name={item.icon} />;
                    }

                    if (item.sortable) {
                      text = (
                        <a
                          className='sortable'
                          onClick={this.sort.bind(null, item.sort)}
                        >
                          {t(text)}
                        </a>
                      );
                    }

                    if (item.tooltip) {
                      text = (
                        <Tooltip content={item.tooltip} alignment={alignment}>
                          {t(text)}
                        </Tooltip>
                      );
                    }

                    return (
                      <th key={index} className={className}>
                        {t(text)}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {this.state.accounts.map((account, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {index + 1}
                        <span
                          className={classNames(
                            'account-type',
                            toSlug(account.type),
                          )}
                        />
                      </td>
                      <td className='account-name'>
                        <a
                          className='account-domain account-label'
                          onClick={this.switchAccount.bind(null, account)}
                        >
                          {account.domain}
                        </a>
                        <span
                          className='account-id account-label'
                          style={{ minWidth: '190px' }}
                        >
                          {account.accountId}
                        </span>
                        <span className='account-apikey account-label'>
                          {account.apiKey}
                        </span>
                      </td>
                      <td>
                        <span className='date-time'>
                          {reformatDate(account.membershipDate)}
                        </span>
                      </td>
                      <td>
                        {t(
                          account.type === 'PANEL_RESTRICTED'
                            ? 'Restricted'
                            : account.type,
                        )}
                      </td>
                      <td>{t(account.provider)}</td>
                      <td>
                        {account.global && account.global.globalEnabled
                          ? t('Active')
                          : t('Disabled')}
                        {account.global && account.global.globalEnabled ? (
                          <span className='global-percentage account-label'>
                            ({account.global.globalPercentage}%)
                          </span>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>{t(account.country) ?? '-'}</td>
                      <td>{t(account.industry) ?? '-'}</td>
                      <td>
                        {reformatDate(account.importantDates.freeTrialStart)}
                      </td>
                      <td>
                        {reformatDate(account.importantDates.freeTrialEnd)}
                      </td>
                      <td>
                        <span className='contact-name account-label'>
                          {account.contact.person}
                        </span>
                        <span className='contact-email account-label'>
                          {account.contact.email}
                        </span>
                        <span className='contact-phone account-label'>
                          {account.contact.phone}
                        </span>
                      </td>
                      <td>
                        <span>
                          <br />
                          <strong>Technical Responsible</strong>
                          <br />
                          {account.type === 'POC' || account.type === 'PRE_POC'
                            ? account.ownership.onboardingDeveloper
                            : account.ownership.accountDeveloper}
                          <br />
                          <strong>Account Responsible</strong>
                          <br />
                          {account.type === 'POC' || account.type === 'PRE_POC'
                            ? account.ownership.onboardingManager
                            : account.ownership.accountManager}
                          <br />
                          <strong>Sales Responsible</strong>
                          <br />
                          {account.ownership.salesManager}
                        </span>
                      </td>
                      <td>
                        {account.ga4 !== undefined &&
                        account.ga4.propertyId !== undefined &&
                        account.ga4.propertyId !== '' ? (
                          <Icons name='checkboxSelected' />
                        ) : (
                          <Icons name='crossCircle' />
                        )}
                      </td>
                      <td>
                        <p className='account-description'>
                          {account.description}
                        </p>
                      </td>
                      <td>
                        <AdminActions
                          account={account}
                          onUpdate={this.updateAccount}
                          onArchive={this.onArchive}
                          hiddenFields={['campaignStats']}
                          features={account.features}
                          users={this.state._users}
                          onPushUpdate={this.onPushUpdate}
                          onGaUpdate={this.onGaUpdate}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

Admin_Accounts.contextTypes = {
  router: PropTypes.object.isRequired,
};

const MapStateToProps = store => ({
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
  update: store.filters.update,
  reset: store.filters.reset,
});

export default connect(MapStateToProps)(Admin_Accounts);
