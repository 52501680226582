/**
 * Created by mehmetyurtar on 19/09/2017.
 */
"use strict";

import { clone } from "../../system/object";
import { toCamelCase } from "../../system/string";
import {
  calculateStatRatio,
  calculateRatio,
  calculateProportion
} from "../../system/ratio";
import { statsMap } from "../../constants/datamaps/stats";

const transformPageStats = function(statData) {
  let mappedStats = clone(statsMap);
  let extraFields = [
    {
      key: "searchPage",
      name: "Search Page"
    },
    {
      key: "basketPage",
      name: "Basket Page"
    },
    {
      key: "404Page",
      name: "404 Page"
    },
    {
      key: "checkoutPage",
      name: "Checkout Page"
    }
  ];
  extraFields.forEach(field => {
    mappedStats.page.stats[field.key] = {
      name: field.name,
      count: 0,
      ratio: 0
    };
  });
  if (statData.length > 0) {
    statData.forEach(function(item) {
      let type,
        name,
        key,
        other = "other";

      if (item.x.startsWith("page:")) {
        type = "page";
      }

      name = item.x.replace(type + ":", "");
      key = toCamelCase(name);

      if (type === "page") {
        if (mappedStats[type]) {
          if (typeof mappedStats[type].stats[key] !== "undefined") {
            mappedStats[type].stats[key].count = item.y;
          } else {
            mappedStats[type].stats[other].count += item.y;
            if (
              key === "purchaseSuccessPage" ||
              key === "checkoutSuccessPage"
            ) {
              mappedStats[type].stats[other].checkoutCount += item.y;
            }
          }
        }
      }
    });
  }

  let pageStats = mappedStats.page.stats;
  mappedStats.page.stats.other.count =
    pageStats.aLL.count -
    (pageStats.homePage.count +
      pageStats.productPage.count +
      pageStats.categoryPage.count);
  extraFields.forEach(field => {
    mappedStats.page.stats.other.count -= pageStats[field.key].count;
  });

  return mappedStats;
};

export default transformPageStats;
