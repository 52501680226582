import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const RenewIcon = ({ width = '32px', height = '32px', color = '#eee' }) => {
  const [state, setState] = useState({
    width: '',
    height: '',
    color: '',
  });

  const setStyles = () => {
    setState({
      width,
      height,
      color,
    });
  };

  useEffect(() => {
    setStyles();
  }, [width, height, color]);

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 18'
      width={state.width}
      height={state.height}
      className='svg-icon'
    >
      <path
        d='M10.188 3.88508C9.99955 4.1321 10.047 4.48507 10.2941 4.67346C11.6353 5.69636 12.4375 7.28116 12.4375 9C12.4375 11.802 10.3181 14.109 7.59497 14.4053L8.10227 13.8977C8.32195 13.6781 8.32195 13.3219 8.10227 13.1023C7.90255 12.9025 7.59002 12.8844 7.36983 13.0478L7.30675 13.1023L5.80675 14.6023C5.60703 14.8019 5.58887 15.1144 5.7523 15.3346L5.80675 15.3977L7.30675 16.8977C7.52643 17.1174 7.8826 17.1174 8.10227 16.8977C8.30192 16.6981 8.32008 16.3856 8.15672 16.1653L8.10227 16.1023L7.53955 15.5406C10.9116 15.2662 13.5625 12.4427 13.5625 9C13.5625 6.92644 12.5937 5.01241 10.9763 3.77892C10.7293 3.59053 10.3763 3.63806 10.188 3.88508ZM5.89773 1.10225C5.67805 1.32192 5.67805 1.67808 5.89773 1.89775L6.4597 2.45942C3.08807 2.7342 0.4375 5.55756 0.4375 9C0.4375 10.9834 1.32344 12.823 2.82311 14.0618C3.06262 14.2597 3.41717 14.2259 3.61502 13.9864C3.81286 13.7469 3.77909 13.3923 3.53957 13.1944C2.29595 12.1672 1.5625 10.6441 1.5625 9C1.5625 6.19827 3.68149 3.89146 6.40427 3.59476L5.89773 4.10225C5.67805 4.32192 5.67805 4.67808 5.89773 4.89775C6.1174 5.11741 6.47357 5.11741 6.69325 4.89775L8.19325 3.39775C8.41292 3.17808 8.41292 2.82192 8.19325 2.60225L6.69325 1.10225C6.47357 0.882583 6.1174 0.882583 5.89773 1.10225Z'
        fill={state.color}
        stroke={state.color}
        stroke-width='0.4'
      />
    </svg>
  );
};

RenewIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

RenewIcon.defaultProps = {
  width: '1rem',
  height: '1rem',
  color: '#eee',
};

export default RenewIcon;
