/**
 * Created by mehmetyurtar on 10/05/2017.
 */
import React from "react";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import { filterActions } from "../../actions";
import Icon from "../icon";
import { dateActions } from "../../actions";

class RangePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: null
    };

    this.isOutsideRange = this.isOutsideRange.bind(this);
    this.customInputIcon = this.customInputIcon.bind(this);
    this.customArrowIcon = this.customArrowIcon.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.calendarInfo = this.calendarInfo.bind(this);
    this.clickedButton = this.clickedButton.bind(this);
    this.openPicker = this.openPicker.bind(this);

    moment()._locale._week.dow = 1;
  }

  isOutsideRange(day) {
    return day > moment().endOf("day");
  }

  customInputIcon() {
    return <Icon name="calendar" />;
  }

  customArrowIcon() {
    return <div style={{ padding: "10px" }}>-</div>;
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput: focusedInput });
  }

  clickedButton() {
    if (!this.props.endDate) {
      this.setState({ end: moment() }, () => {
        dateActions.updateRange([this.props.startDate, moment()]);
        filterActions.updateFilters();
        filterActions.showButtons();
        this.setState({
          focusedInput: null
        });
      });
    } else {
      filterActions.updateFilters();
      filterActions.showButtons();
      this.setState({
        focusedInput: null
      });
    }
  }

  openPicker(e) {
    if (!e.target.classList.contains("date-update-button")) {
      this.setState({
        focusedInput: "startDate"
      });
    }
  }

  calendarInfo() {
    return (
      <div onClick={this.clickedButton} className="date-update">
        <a className="tertiary-action date-update-button">
          Update
          <span className="for-screenreader-only" />
        </a>
      </div>
    );
  }

  render() {
    let renderCalendarInfo = this.props.showUpdateButton
      ? this.calendarInfo
      : null;
    let keepOpen = this.props.showUpdateButton ? true : false;
    return (
      <div onClick={this.openPicker} className="datepicker--container">
        <DateRangePicker
          startDate={this.props.startDate} // momentPropTypes.momentObj or null,
          endDate={this.props.endDate} // momentPropTypes.momentObj or null,
          onDatesChange={this.props.onDatesChange}
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={this.onFocusChange} // PropTypes.func.isRequired,
          hideKeyboardShortcutsPanel={true}
          minimumNights={0}
          isOutsideRange={this.isOutsideRange}
          customInputIcon={this.customInputIcon()}
          customArrowIcon={this.customArrowIcon()}
          displayFormat="DD/MM/YYYY"
          numberOfMonths={1}
          required={true}
          onClose={this.props.onPickerClose}
          renderCalendarInfo={renderCalendarInfo}
          keepOpenOnDateSelect={keepOpen}
        />
      </div>
    );
  }
}

export default RangePicker;
