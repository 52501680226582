/* eslint-disable react/jsx-curly-newline */
import React, { useState, useEffect } from 'react';
import { browserHistory } from 'react-router';
import { setTitle } from '../../../system/document';
import {
  SFYHeader,
  SFYCampaignList,
  SFYCampaignStatus,
} from '../../../components/sfy-components/module-exports';
import { RecommendationTemplateService } from './services/recommendationTemplateServices';
import { breadCrumbOptions, actions } from './constants/constants';
import styles from './Templates.module.scss';
import Icons from '../../../components/icons';
import { t } from '../../../system/ui';
import { modalActions, uiActions } from '../../../actions';
import CampaignHistoryModal from '../../../modules/campaigns/historymodal';
// import { getFormattedDate } from '../../../system/date';

/**
 * @name Template view holds recommendation template library components
 * @description recommendation template components responsible from pagination and path to any related page
 * @param {record} location - location object
 * @returns {JSX.Element} - recommendation template component
 */
export const WidgetTemplateRouterItem = ({
  to,
  label = 'New Template',
  campaignPathname = '/recommendations/templates/create',
}) => {
  return (
    <div
      className={styles['new-template']}
      style={{ cursor: 'pointer' }}
      onClick={() =>
        browserHistory.push({
          pathname: to,
          state: {
            campaignPathname,
          },
        })
      }
    >
      <div className={styles['new-template__add-container']}>
        <div className={styles['new-template__add-container__icon']}>
          <Icons name='IconPlusThin' width='20' height='20' color='#cacaca' />
        </div>
        <span className={styles['new-template__add-container__text']}>
          {t(label)}
        </span>
      </div>
    </div>
  );
};

const RecommendationTemplates = () => {
  const [templates, setTemplates] = useState([]);
  // const [dates, setDates] = useState({});

  const {
    isLoaded,
    isLoading,
    showNotification,
    confirmationDialog,
  } = uiActions;

  const showNotificationFunc = (message, type) => {
    const content = () => <p>{t(message)}</p>;
    showNotification({
      content,
      className: `notification-${type}`,
    });
  };

  const listInvoker = () => {
    isLoading();
    RecommendationTemplateService()
      .getAll()
      .then(res => {
        setTemplates(res || []);
      })
      .finally(() => {
        isLoaded();
      });
  };
  /*
  useEffect(() => {
    const calls = [];
    const responseComposite = {};
    templates.map(item =>
      calls.push(
        RecommendationTemplateService()
          .get({ id: item?.id, query: 'withOps=true' })
          .then(res => {
            const campaign = res?.find(
              element => element?.operation?.type === 'CREATED',
            );
            const timestamp = campaign?.operation?.time;
            const result = getFormattedDate(timestamp);
            responseComposite[item?.id] = result;
          }),
      ),
    );
    Promise.all(calls).then(res => {
      setDates({ ...responseComposite });
    });
  }, [templates.length]);
  */

  useEffect(() => {
    listInvoker();
    setTitle('Widget Template Library - Recommendations');
  }, []);

  const removeCampaign = instanceId => {
    isLoading();
    RecommendationTemplateService()
      .deleteWithParams({ id: instanceId })
      .then(() => {
        isLoaded();
        showNotificationFunc('Campaign is successfully deleted.', 'success');
        listInvoker();
      });
  };

  const reDesignedActions = () => {
    return actions.map(action => {
      const newAction = { ...action };

      if (newAction.handler === 'editAction') {
        newAction.actionFunc = instanceId => {
          browserHistory.push(`/recommendations/templates/edit/${instanceId}`);
        };
      }

      if (newAction.handler === 'removeAction') {
        newAction.actionFunc = instanceId => {
          confirmationDialog({
            title: 'Delete the Template?',
            content: 'Are you sure you want to delete the template?',
            onConfirm: () => {
              removeCampaign(instanceId);
            },
          });
        };
      }

      if (newAction.handler === 'customHistoryAction') {
        newAction.actionFunc = instanceId => {
          const campaignHistoryModal = () => (
            <CampaignHistoryModal
              campaignId={instanceId}
              isRecommendationTemplate
            />
          );
          modalActions.openModal({
            title: 'Widget Templates History',
            content: campaignHistoryModal,
          });
        };
      }

      return newAction;
    });
  };

  const renderTemplateList = (campaignList, campaignTitle) => {
    return (
      <div className={styles['template-list-wrapper']}>
        <SFYCampaignStatus
          allCampaign={campaignList}
          campaignTitle={campaignTitle}
          campaignStatus='ACTIVE'
        />
        {campaignList.map(campaign => {
          const fixedCampaign = {
            type: 'TEMPLATE_LIST',
            name: campaign?.templateName || '',
            startDate: null, // dates[campaign?.id], TO DO: waiting for BE implementation
            instanceId: campaign?.id,

            customDetails: [
              `${campaign?.totalCampaignCount ||
                0} campaigns are using this template`,
            ],
          };
          return (
            <SFYCampaignList
              campaign={fixedCampaign}
              campaignActions={reDesignedActions()}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      <SFYHeader
        pageTitle='Widget Template Library'
        breadCrumbOptions={breadCrumbOptions()}
      />
      <WidgetTemplateRouterItem to='/recommendations/templates/create' />
      <div className={styles['template-list']}>
        {templates.length !== 0 &&
          renderTemplateList(templates, 'Widget Templates')}
      </div>
    </>
  );
};

export default RecommendationTemplates;
