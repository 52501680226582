/**
 * @name productBundleReducer
 * @summary reducer for product bundle
 * @param {any} state
 * @param {any[]} action
 */

export const productBundleReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_PRODUCT_BUNDLE_PRODUCT_LIST':
      return {
        ...state,
        productBundleList: payload,
      };
    case 'SET_PRODUCT_BUNDLE_CATEGORY_LIST':
      return {
        ...state,
        categoryBundleList: payload,
      };
    case 'SET_ACTIVE_BUNDLE_DETAILS':
      return {
        ...state,
        activeBundleDetails: {
          ...state.activeBundleDetails,
          ...payload,
        },
      };
    case 'EDIT_ACTIVE_BUNDLE_DETAILS':
      if (payload.type === 'main-item') {
        return {
          ...state,
          activeBundleDetails: {
            ...state.activeBundleDetails,
            mainItem: payload.item,
          },
        };
      }
      if (payload.type === 'bundle-item') {
        return {
          ...state,
          activeBundleDetails: {
            ...state.activeBundleDetails,
            subItems: state.activeBundleDetails.subItems.map(item => {
              if (item.index === payload.item.index) {
                return payload.item;
              }
              return item;
            }),
          },
        };
      }
      return state;
    case 'RESET_PRODUCT_BUNDLE':
      return {
        productBundleList: [],
        categoryBundleList: [],
        activeBundleDetails: {
          id: '',
          mainItem: {},
          subItems: [],
        },
      };
    default:
      return state;
  }
};
