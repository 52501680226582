/**
 * Created by mehmetyurtar on 12/12/17.
 */
import React from "react";
import moment from "moment";
import classNames from "classnames";
import { SingleDatePicker } from "react-dates";
import { filterActions } from "../../actions";
import Icon from "../icon";

import * as classes from "../../constants/ui.classes";
import { dateFormat, timeFormat } from "../../constants/ui";
import { dateActions } from "../../actions";

class WeekPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      date: ""
    };

    this.isOutsideRange = this.isOutsideRange.bind(this);
    this.customInputIcon = this.customInputIcon.bind(this);
    this.customArrowIcon = this.customArrowIcon.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.calendarInfo = this.calendarInfo.bind(this);
    this.clickedButton = this.clickedButton.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);

    moment()._locale._week.dow = 1;
  }

  componentDidMount() {
    this.setDates();
  }

  setDates() {
    this.setState(
      {
        date: this.props.startDate
      },
      () => {
        let startOfWeek = this.props.startDate.clone().startOf("week");
        let endOfWeek = this.props.startDate.clone().endOf("week");
        let dateFormat = this.props.startDate.localeData().longDateFormat("L");
        let uniqueEl =
          "#weekpicker-" + this.props.week + " .DateInput__display-text";
        let dateInput = document.querySelectorAll(uniqueEl)[0];
        dateInput.innerHTML =
          startOfWeek.format("DD/MM/YYYY") +
          " - " +
          endOfWeek.format("DD/MM/YYYY");
      }
    );
  }

  isOutsideRange(day) {
    return day > moment().endOf("day");
  }

  customInputIcon() {
    return <Icon name="calendar" />;
  }

  customArrowIcon() {
    return <div style={{ padding: "10px" }}>-</div>;
  }

  onFocusChange(focused) {
    this.setState({
      focused: focused.focused ? focused.focused : false
    }); /* setTimeout(() => {
         this.setState({ focusedInput: focusedInput });
         }, 100); */
  }

  onDatesChange(date) {
    let startOfWeek = date.clone().startOf("week");
    let endOfWeek = date.clone().endOf("week");
    let dateFormat = date.localeData().longDateFormat("L");
    let uniqueEl =
      "#weekpicker-" + this.props.week + " .DateInput__display-text";
    this.setState({ date: startOfWeek }, () => {
      let dateInput = document.querySelectorAll(uniqueEl)[0];
      dateInput.innerHTML =
        startOfWeek.format("DD/MM/YYYY") +
        " - " +
        endOfWeek.format("DD/MM/YYYY");
    });
  }

  clickedButton() {
    filterActions.updateFilters();
    filterActions.showButtons();
    this.setState({
      focusedInput: null
    });
  }

  calendarInfo() {
    return (
      <div onClick={this.clickedButton} className="date-update">
        <a className="tertiary-action date-update-button">
          Update
          <span className="for-screenreader-only" />
        </a>
      </div>
    );
  }

  onDateChange(date) {
    this.setState({ date });
  }

  render() {
    let renderCalendarInfo = this.props.showUpdateButton
      ? this.calendarInfo
      : null;
    let keepOpen = this.props.showUpdateButton ? true : false;
    return (
      <span
        className="react-dates-weekpicker"
        id={"weekpicker-" + this.props.week}
      >
        <SingleDatePicker
          onFocusChange={this.onFocusChange} // PropTypes.func.isRequired,
          date={this.state.date} // momentPropTypes.momentObj or null
          onDateChange={this.props.onDateChange} // PropTypes.func.isRequired
          focused={this.state.focused} // PropTypes.bool
          numberOfMonths={1}
          keepOpenOnDateSelect={false}
          isOutsideRange={this.isOutsideRange}
          displayFormat="DD/MM/YYYY"
          minimumNights={0}
          customInputIcon={this.customInputIcon()}
          customArrowIcon={this.customArrowIcon()}
          showDefaultInputIcon={true}
          renderCalendarInfo={false}
          hideKeyboardShortcutsPanel={true}
        />
      </span>
    );
  }
}

export default WeekPicker;
