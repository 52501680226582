/**
 * Created by mehmetyurtar on 11/21/17.
 */
import React from 'react';
import { calculateRatio } from '../../system/ratio';
import Currency from '../currency';
import Icons from '../icons';
import Icon from '../icon';
import { localeString } from '../../system/string';
import { t } from '../../system/ui';

class CompareSources extends React.Component {
  render() {
    if (this.props.hasTrendifySources) {
      return (
        <div className='one-whole'>
          <ul className='one-half trendify-compare-stats'>
            {this.props.hasTrendifySources.map(source => {
              return (
                <li className='trendify-compare-stat'>
                  <div className='compare-stat-container one-half'>
                    <h4>
                      <Icons name={source.icon} color='#222' />
                    </h4>
                    <p>{t(source.label)}</p>
                  </div>
                  <div className='compare-stat-container one-half'>
                    {localeString(
                      this.props.firstColumnData.stats[
                        `${this.props.device + source.field}Click`
                      ],
                    ) || '-'}
                  </div>
                </li>
              );
            })}
          </ul>
          <ul className='one-half trendify-compare-stats'>
            {this.props.hasTrendifySources.map(source => {
              return (
                <li className='trendify-compare-stat'>
                  <div className='compare-stat-container one-half'>
                    <h4>
                      <Icons name={source.icon} color='#222' />
                    </h4>
                    <p>{t(source.label)}</p>
                  </div>
                  <div className='compare-stat-container one-half'>
                    {localeString(
                      this.props.secondColumnData.stats[
                        `${this.props.device + source.field}Click`
                      ],
                    ) || '-'}
                    {this.props
                      .getCompareIcon()
                      .count(
                        this.props.secondColumnData.stats[
                          `${this.props.device + source.field}Click`
                        ] || 0,
                        this.props.firstColumnData.stats[
                          `${this.props.device + source.field}Click`
                        ] || 0,
                      )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
    return (
      <div className='one-whole'>
        <ul className='one-half trendify-compare-stats'>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='click' />
              </h4>
              <p>{t('Views Referred From In-site')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.firstColumnData.stats[
                  `${this.props.device}InternalClick`
                ],
              ) || '-'}
            </div>
          </li>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='share' />
              </h4>
              <p>{t('Views Referred From Social Media')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.firstColumnData.stats[
                  `${this.props.device}SocialClick`
                ],
              ) || '-'}
            </div>
          </li>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='magnify' />
              </h4>
              <p>{t('Views Referred From Search Engines')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.firstColumnData.stats[
                  `${this.props.device}SearchClick`
                ],
              ) || '-'}
            </div>
          </li>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='paid' />
              </h4>
              <p>{t('Views Referred From Advertisements')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.firstColumnData.stats[
                  `${this.props.device}PaidClick`
                ],
              ) || '-'}
            </div>
          </li>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='newsletter' />
              </h4>
              <p>{t('Views Referred From Email Campaigns')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.firstColumnData.stats[
                  `${this.props.device}EmailClick`
                ],
              ) || '-'}
            </div>
          </li>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='bullet-list' />
              </h4>
              <p>{t('Views Referred From Price Listing Sites')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.firstColumnData.stats[
                  `${this.props.device}ListingClick`
                ],
              ) || '-'}
            </div>
          </li>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='other-sources' />
              </h4>
              <p>{t('Views Referred From Other Sources')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.firstColumnData.stats[
                  `${this.props.device}OtherClick`
                ],
              ) || '-'}
            </div>
          </li>
        </ul>
        <ul className='one-half trendify-compare-stats'>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='click' />
              </h4>
              <p>{t('Views Referred From In-site')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.secondColumnData.stats[
                  `${this.props.device}InternalClick`
                ],
              ) || '-'}
              {this.props
                .getCompareIcon()
                .count(
                  this.props.secondColumnData.stats[
                    `${this.props.device}InternalClick`
                  ] || 0,
                  this.props.firstColumnData.stats[
                    `${this.props.device}InternalClick`
                  ] || 0,
                )}
            </div>
          </li>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='share' />
              </h4>
              <p>{t('Views Referred From Social Media')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.secondColumnData.stats[
                  `${this.props.device}SocialClick`
                ],
              ) || '-'}
              {this.props
                .getCompareIcon()
                .count(
                  this.props.secondColumnData.stats[
                    `${this.props.device}SocialClick`
                  ] || 0,
                  this.props.firstColumnData.stats[
                    `${this.props.device}SocialClick`
                  ] || 0,
                )}
            </div>
          </li>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='magnify' />
              </h4>
              <p>{t('Views Referred From Search Engines')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.secondColumnData.stats[
                  `${this.props.device}SearchClick`
                ],
              ) || '-'}
              {this.props
                .getCompareIcon()
                .count(
                  this.props.secondColumnData.stats[
                    `${this.props.device}SearchClick`
                  ] || 0,
                  this.props.firstColumnData.stats[
                    `${this.props.device}SearchClick`
                  ] || 0,
                )}
            </div>
          </li>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='paid' />
              </h4>
              <p>{t('Views Referred From Advertisements')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.secondColumnData.stats[
                  `${this.props.device}PaidClick`
                ],
              ) || '-'}
              {this.props
                .getCompareIcon()
                .count(
                  this.props.secondColumnData.stats[
                    `${this.props.device}PaidClick`
                  ] || 0,
                  this.props.firstColumnData.stats[
                    `${this.props.device}PaidClick`
                  ] || 0,
                )}
            </div>
          </li>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='newsletter' />
              </h4>
              <p>{t('Views Referred From Email Campaigns')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.secondColumnData.stats[
                  `${this.props.device}EmailClick`
                ],
              ) || '-'}
              {this.props
                .getCompareIcon()
                .count(
                  this.props.secondColumnData.stats[
                    `${this.props.device}EmailClick`
                  ] || 0,
                  this.props.firstColumnData.stats[
                    `${this.props.device}EmailClick`
                  ] || 0,
                )}
            </div>
          </li>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='bullet-list' />
              </h4>
              <p>{t('Views Referred From Price Listing Sites')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.secondColumnData.stats[
                  `${this.props.device}ListingClick`
                ],
              ) || '-'}
              {this.props
                .getCompareIcon()
                .count(
                  this.props.secondColumnData.stats[
                    `${this.props.device}ListingClick`
                  ] || 0,
                  this.props.firstColumnData.stats[
                    `${this.props.device}ListingClick`
                  ] || 0,
                )}
            </div>
          </li>
          <li className='trendify-compare-stat'>
            <div className='compare-stat-container one-half'>
              <h4>
                <Icon name='other-sources' />
              </h4>
              <p>{t('Views Referred From Other Sources')}</p>
            </div>
            <div className='compare-stat-container one-half'>
              {localeString(
                this.props.secondColumnData.stats[
                  `${this.props.device}OtherClick`
                ],
              ) || '-'}
              {this.props
                .getCompareIcon()
                .count(
                  this.props.secondColumnData.stats[
                    `${this.props.device}OtherClick`
                  ] || 0,
                  this.props.firstColumnData.stats[
                    `${this.props.device}OtherClick`
                  ] || 0,
                )}
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default CompareSources;
