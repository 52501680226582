/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";

import * as modals from "../../constants/datamaps/modals";
import {
  trendsMap,
  statTypes,
  personalizationTrendsMap
} from "../../constants/datamaps";

import { t, openModal } from "../../system/ui";
import { queryDate } from "../../system/date";
import { localeString, localeStringMoney } from "../../system/string";

import { transformCampaignReport } from "../../modules/transform";
import { transFormPersReport } from "../../modules/transform";
import {
  getCampaignReport,
  getRangedReport,
  getPersonalizationReport
} from "../../modules/reports/ajax";

import Currency from "../currency";
import Icon from "../icon";
import Icons from "../icons";
import Tooltip from "../tooltip";

class PopupBannerReport extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return this.props.compareMode ? (
      <div className="widget-stat-list widget-report">
        <div>
          {Object.keys(this.props.stats).map((item, index) => {
            let stat = this.props.stats[item].stats,
              type = statTypes[item];
            if (
              type.name === "Segment Visitor" ||
              type.name === "Unsegment Visitor" ||
              type.name === "Toggle Visitor Segment" ||
              type.name === "Granted" ||
              type.name === "Denied" ||
              type.name === "Form Submit" ||
              type.name === "Notification View"
            ) {
              return;
            }
            let className = "compare-stat stat-" + item.toLowerCase(),
              isSecondColumn = this.props.withCompareIcons,
              statDifference = isSecondColumn
                ? stat.all.count -
                  this.props.comparisonStats[item].stats.all.count
                : 0;
            return (
              <div className={className} key={item + "-" + index}>
                <div className="compare-stat-container one-half">
                  <h4 className="widget-stat-title">{t(type.name)}</h4>
                  {Object.keys(stat).map(item => {
                    let singleStat = stat[item];
                    if (
                      type.name === "Widget" &&
                      (singleStat.name === "Actual View" ||
                        singleStat.name === "Conversion")
                    ) {
                      return;
                    }

                    if (item === "all" || item === "contribution") {
                      return;
                    }

                    return (
                      <p key={item} className="widget-stat-name">
                        {t(singleStat.name)}
                        <span className="widget-stat-data">
                          {localeString(singleStat.count) ||
                            localeString(singleStat.ratio) + "%"}
                        </span>
                      </p>
                    );
                  })}
                </div>
                <div
                  className="compare-stat-container one-half"
                  style={{ textAlign: "right", position: "relative" }}
                >
                  <p className="widget-stat-count">
                    {localeString(stat.all.count)}
                    <span className="compare-unit">
                      {type.currency === true ? (
                        <Currency />
                      ) : (
                        <Icon name={type.icon} />
                      )}
                      {type.currency !== true ? t(type.unit) : ""}
                    </span>
                  </p>
                  <span
                    style={{
                      position: "absolute",
                      right: "0",
                      bottom: "-20px"
                    }}
                  >
                    {isSecondColumn && statDifference > 0 && (
                      <span style={{ color: "#56bc94" }}>
                        +{localeString(statDifference)}{" "}
                        <Icons name="increase" color="#56bc94" />
                      </span>
                    )}
                    {isSecondColumn && statDifference < 0 && (
                      <span style={{ color: "#dc3322" }}>
                        {" "}
                        {localeString(statDifference)}{" "}
                        <Icons name="decrease" color="#dc3322" />
                      </span>
                    )}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    ) : this.props.onViewAll ? (
      <div style={{ display: "inherit", width: "100%" }}>
        {Object.keys(this.props.stats).map((item, index) => {
          let stat = this.props.stats[item].stats,
            type = statTypes[item],
            className = "widget-stat stat-" + item.toLowerCase();
          if (
            type.name === "Segment Visitor" ||
            type.name === "Unsegment Visitor" ||
            type.name === "Toggle Visitor Segment" ||
            type.name === "Granted" ||
            type.name === "Denied" ||
            type.name === "Form Submit" ||
            type.name === "Notification View"
          ) {
            return;
          }
          return (
            <div className={className} ref={item} key={item + "-" + index}>
              <h4 className="widget-stat-title">{t(type.name)}</h4>
              <p className="widget-stat-count" ref={item + "Count"}>
                {type.currency === true
                  ? localeStringMoney(stat.all.count)
                  : localeString(stat.all.count)}
              </p>
              <p className="widget-stat-type">
                {type.currency === true ? (
                  <Currency />
                ) : (
                  <Icon name={type.icon} />
                )}
                {type.currency !== true ? t(type.unit) : ""}
              </p>
              <p className="widget-stat-conversion">
                {type.name === "Widget" ||
                type.name === "Interaction" ||
                type.name === "Basket"
                  ? t(type.name + "Conversion")
                  : t("Conversion")}
                {stat.conversion.tooltip && (
                  <span className="stat-tooltip">
                    <Tooltip
                      content={stat.conversion.tooltip}
                      alignment="top"
                      newTooltip={true}
                    >
                      <Icon name="info" />
                    </Tooltip>
                  </span>
                )}
                <span className="widget-stat-conversion-amount">
                  {stat.conversion.ratio}%
                </span>
              </p>
            </div>
          );
        })}
      </div>
    ) : (
      <div className="widget-stat-list widget-report">
        <div className="widget-stats">
          {Object.keys(this.props.stats).map((item, index) => {
            let stat = this.props.stats[item].stats,
              type = statTypes[item],
              className = "widget-stat stat-" + item.toLowerCase();
            if (
              type.name === "Segment Visitor" ||
              type.name === "Unsegment Visitor" ||
              type.name === "Toggle Visitor Segment" ||
              type.name === "Granted" ||
              type.name === "Denied" ||
              type.name === "Form Submit" ||
              type.name === "Notification View"
            ) {
              return;
            }
            return (
              <div className={className} key={item + "-" + index}>
                <h4 className="widget-stat-title">{t(type.name)}</h4>
                <p className="widget-stat-count">
                  {localeString(stat.all.count)}
                </p>
                <p className="widget-stat-type">
                  {type.currency === true ? (
                    <Currency />
                  ) : (
                    <Icon name={type.icon} />
                  )}
                  {type.currency !== true ? t(type.unit) : ""}
                </p>
                {Object.keys(stat).map(item => {
                  let singleStat = stat[item];
                  let singleStatData;

                  if (singleStat.currency) {
                    singleStatData = (
                      <Currency
                        currency={localeStringMoney(singleStat.count)}
                      />
                    );
                  } else {
                    singleStatData =
                      localeString(singleStat.count) ||
                      localeString(singleStat.ratio) + "%";
                  }

                  if (
                    type.name === "Widget" &&
                    (singleStat.name === "Actual View" ||
                      singleStat.name === "Conversion")
                  ) {
                    return;
                  }

                  if (item === "all" || item === "contribution") {
                    return;
                  }

                  return (
                    <p key={item} className="widget-stat-name">
                      {type.name === "Widget" ||
                      type.name === "Interaction" ||
                      type.name === "Basket"
                        ? t(type.name + singleStat.name)
                        : t(singleStat.name)}
                      {singleStat.tooltip && (
                        <span className="stat-tooltip">
                          <Tooltip
                            content={singleStat.tooltip}
                            alignment="top"
                            newTooltip={true}
                          >
                            <Icon name="info" />
                          </Tooltip>
                        </span>
                      )}
                      <span className="widget-stat-data">{singleStatData}</span>
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias
});

export default connect(mapStatesToProps)(PopupBannerReport);
