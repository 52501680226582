const defaultDuplicateUrl = (campaignType, instanceId) => {
  if (campaignType === 'wheel-of-fortune' || campaignType === 'scratch') {
    const typeUrlMatch = {
      'wheel-of-fortune': 'wof',
      scratch: 'scratch',
    };

    const typeUrl = typeUrlMatch[campaignType] || campaignType;

    return `/behavioural-targeting/engagement/gamification/${typeUrl}/duplicate/${instanceId}`;
  }

  return `/campaign/${campaignType}/duplicate/${instanceId}`;
};

const countdownDuplicateUrl = (campaignType, instanceId) => {
  const typeUrlMatch = {
    'pop-up-banner-countdown': 'popup',
    'hero-banner-countdown': 'hero',
    'notification-bar-countdown': 'notification',
  };

  const typeUrl = typeUrlMatch[campaignType] || campaignType;

  return `/behavioural-targeting/engagement/countdown/${typeUrl}/duplicate/${instanceId}`;
};

/**
 * @name useRedirectDuplicate
 * @param {string} campaignType
 * @param {string} instanceId
 * @returns {string} Returns the redirect URL for duplicating a campaign
 */
export const useRedirectDuplicate = ({ campaignType, instanceId }) => {
  const isCountdown = campaignType.includes('countdown');

  return isCountdown
    ? countdownDuplicateUrl(campaignType, instanceId)
    : defaultDuplicateUrl(campaignType, instanceId);
};
