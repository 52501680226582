import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const lastVisitIcon = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 42 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M42 21.5C42 33.098 32.598 42.5 21 42.5C9.40202 42.5 0 33.098 0 21.5C0 9.90202 9.40202 0.5 21 0.5C32.598 0.5 42 9.90202 42 21.5Z'
        fill='#E8F7FF'
      />
      <path
        d='M29.0258 26.5014C26.2639 31.2851 20.1471 32.9241 15.3634 30.1622C14.0607 29.4101 12.964 28.3926 12.1287 27.1797C11.8495 26.7741 12.0301 26.2141 12.4937 26.0482C16.2611 24.6997 18.2789 23.1372 19.4498 20.9021C20.6825 18.5491 21.001 15.9716 20.1387 12.4386C20.0195 11.9501 20.4053 11.4849 20.9075 11.5119C22.4645 11.5954 23.9856 12.0426 25.3649 12.839C30.1486 15.6009 31.7876 21.7177 29.0258 26.5014ZM20.7785 21.5981C19.5272 23.9867 17.4671 25.6972 13.961 27.097C14.5693 27.7929 15.2928 28.3894 16.1134 28.8632C20.1796 31.2108 25.3791 29.8176 27.7267 25.7514C30.0744 21.6852 28.6812 16.4857 24.6149 14.1381C23.7379 13.6317 22.7951 13.2917 21.8228 13.1225C22.4699 16.5065 22.0525 19.1662 20.7785 21.5981Z'
        fill='#94C0D7'
      />
    </svg>
  );
};

lastVisitIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

lastVisitIcon.defaultProps = {
  width: '42px',
  height: '43px',
};

export default lastVisitIcon;
