/* eslint-disable react/jsx-curly-newline */
import React, { useContext, useEffect } from 'react';
import { browserHistory } from 'react-router';
import { Context as CountDownContext } from './store/countDownProvider';
import { CountdownService } from './services/countdownServices';

import {
  SFYHeader,
  SFYChildWrapper,
  SFYCampaignStatus,
  SFYCampaignList,
} from '../../../components/sfy-components/module-exports';
import {
  getDynamicBreadCrumbOptions,
  createCardConstant,
  selectCampaignType,
} from './constants/constants';
import { t } from '../../../system/ui';
import Icon from '../../../components/icon';
import { uiActions } from '../../../actions';
import { hideBreadcrumb } from '../../../actions/ui';
import { setTitle } from '../../../system/document';

/**
 * @name Countdown view holds Countdown components
 * @description Countdown components responsible from pagination and path to any related page
 * @param {record} location - location object
 * @returns {JSX.Element} - Countdown component
 */
const Countdown = ({ route }) => {
  const {
    state: { countdownCampaignList },
    setCountDownList,
  } = useContext(CountDownContext);

  const { isLoaded, isLoading } = uiActions;

  const listInvoker = () => {
    isLoading();
    CountdownService()
      .getAllWithCampaignType({ campaignType: 'COUNTDOWN' })
      .then(res => {
        setCountDownList({
          active: res.filter(item => item.status === 'ACTIVE'),
          test: res.filter(item => item.status === 'TEST'),
          passive: res.filter(item => item.status === 'PASSIVE'),
          completed: res.filter(item => item.status === 'COMPLETED'),
        });

        isLoaded();
      });
  };
  useEffect(() => {
    setTitle('Countdown');
    listInvoker();
    return () => {
      hideBreadcrumb();
    };
  }, []);

  const renderCountDownList = (
    countDownCampaign,
    campaignStatus,
    campaignTitle,
  ) => {
    return (
      <>
        <SFYCampaignStatus
          campaignStatus={campaignStatus}
          allCampaign={countDownCampaign}
          campaignTitle={campaignTitle}
        />
        {countDownCampaign.map(countDown => (
          <SFYCampaignList
            route={route}
            campaign={countDown}
            countDownCampaignType={countDown.type}
            campaignDescription={selectCampaignType(countDown.type, true)}
            campaignUpdate={listInvoker}
            isCountDownCampaign
          />
        ))}
      </>
    );
  };

  return (
    <>
      <SFYHeader
        pageTitle='Countdown'
        breadCrumbOptions={getDynamicBreadCrumbOptions('Countdown')}
      />
      <div className='gamification-create-title'>{t('Campaigns')}</div>
      <SFYChildWrapper type='flexRow'>
        {createCardConstant.map(campaign => (
          <div
            className='available-type available-type-social-proof'
            key={campaign.type}
            onClick={() =>
              browserHistory.push(
                `/behavioural-targeting/engagement/countdown/${campaign.type}/add`,
              )
            }
          >
            <span className='available-type-wireframe'>
              <span className='campaign-title'>{t(campaign.title)}</span>
              <span className='campaign-description'>
                {campaign.description}
              </span>
              <div className='campaign-create-icon-field'>
                <Icon className='plus-icon' name={campaign.icon} size='2x' />
                <span className='create-text'>{t('Create')}</span>
              </div>
            </span>
          </div>
        ))}
      </SFYChildWrapper>

      <div className='CountDown__list'>
        {countdownCampaignList.active.length !== 0 &&
          renderCountDownList(
            countdownCampaignList.active,
            'ACTIVE',
            'Live Campaigns',
          )}
        {countdownCampaignList.test.length !== 0 &&
          renderCountDownList(
            countdownCampaignList.test,
            'TEST',
            'Test Campaigns',
          )}
      </div>
    </>
  );
};

export default Countdown;
