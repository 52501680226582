import React from "react";

class IconFunnel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
      >
        <g>
          <polygon points="14.5,3.1 16,0 0,0 1.5,3.1 	" />
          <polygon points="5.5,11 8,16 10.5,11 	" />
          <polygon points="11.5,9 13.5,5.1 2.5,5.1 4.5,9 	" />
        </g>
      </svg>
    );
  }
}

export default IconFunnel;
