import React, { Component } from "react";
import { connect } from "react-redux";
import { t } from "../../system/ui";
import { Select, TextField } from "../../components/fields";
import Tooltip from "../../components/tooltip";
import Icons from "../../components/icons";
import { getAllSegment } from "../../modules/segmentation/ajax";

const options = [
    { label: t("Visitor is in"), value: true },
    { label: t("Visitor is not in"), value: false }
];

class MPAudienceEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: [],
            segments: []
        };

        this.setSegmentsAndUpdate = this.setSegmentsAndUpdate.bind(this);
        this.onInclusionChange = this.onInclusionChange.bind(this);
        this.onSegmentChange = this.onSegmentChange.bind(this);
    }
    componentDidMount() {
        this.setSegmentsAndUpdate(this.props.audience);
    }

    componentWillReceiveProps(newProps) {
        if (this.props.audience !== newProps.audience) {
            let segments = newProps.audience && newProps.audience.length > 0 ? newProps.audience.map((item)=>{return {
                value: item.segment,
                label: item.segment
            }}) : [];
            this.setSegmentsAndUpdate(newProps.audience, segments);
        }
    }

  setSegmentsAndUpdate(audience, staticOptions) {
    getAllSegment(response => {
      if (response) {
        const userSegments = [];
        response.forEach(segment => {
          if (segment.status === 'ACTIVE' && segment.type === 'EVENT_BASED') {
            userSegments.push({
              value: segment.name,
              label: segment.name,
              type: segment.type,
              id: segment.id,
            });
          }
        });
        const segments =
          staticOptions && staticOptions.length > 0
            ? userSegments.concat(staticOptions)
            : userSegments;
        this.update(audience, segments, this.props.operator);
      }
    });
  }

    update(audience, segment, operator) {
        let obj = {
            filters:
                audience && audience.length
                    ? audience.map(filter => {
                        return Object.assign({}, filter, {
                            id: Math.floor(Math.random() * 10000) + 1
                        });
                    })
                    : [
                        {
                            type: "SEGMENT",
                            segment: "",
                            include: true,
                            id: Math.floor(Math.random() * 10000) + 1
                        }
                    ]
        };
        if (segment) {
            obj.segments = segment;
        }
        this.setState(obj);
    }

    onInclusionChange(id, selectedOption) {
        this.setState({
            filters: this.state.filters.map(filter => {
                return filter.id === id
                    ? Object.assign({}, filter, { include: selectedOption.value })
                    : filter;
            })
        });
    }

    onSegmentChange(id, selectedOption) {
        this.setState({
            filters: this.state.filters.map(filter => {
                return filter.id === id
                    ? Object.assign({}, filter, {
                        segment: selectedOption ? selectedOption.value : ""
                    })
                    : filter;
            })
        });
    }

    render() {
        let tooltipText = "You can set the audience of the mail";
        return (
            <div className="mp-field-wrapper">
                <h3 className="wizard-input-type-title">
                    <Icons name="target" />
                    {t("Target Audience")}
                    <span className="field-tooltip-icon">
            <Tooltip content={tooltipText} alignment="left">
              <i className="icon-info" role="presentation">
                <span className="for-screenreader-only" />
              </i>
            </Tooltip>
          </span>
                </h3>
                {this.state.filters.map((filter, index) => {
                    return [
                        <div key={filter.id} className="email-mapping">
                            <Select
                                className="item-field x-large"
                                options={options}
                                value={filter.include}
                                label="Condition"
                                onChange={this.onInclusionChange.bind(null, filter.id)}
                            />

                            <Select
                                value={filter.segment}
                                options={this.state.segments}
                                clearable={true}
                                className="item-field x-large segment-select"
                                creatable={false}
                                searchable={true}
                                label="Segment"
                                onChange={this.onSegmentChange.bind(null, filter.id)}
                            />
                        </div>
                    ];
                })}
            </div>
        );
    }
}

const mapStatesToProps = store => ({});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
    MPAudienceEmail
);
