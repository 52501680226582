/**
 * @author Bilal Cinarli
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";

import { t } from "../system/ui";

import { dialogActions } from "../actions";

class Dialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false
    };

    this.setVisibility = this.setVisibility.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.cancel = this.cancel.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  componentDidMount() {
    this.setVisibility();
  }

  componentWillReceiveProps(newProps) {
    if (!this.state.isVisible && newProps) {
      this.open();
    }
  }

  setVisibility() {
    this.setState({
      isVisible: true
    });
  }

  confirm() {
    this.close();

    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  }

  cancel() {
    this.close();

    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  close() {
    this.setState({
      isVisible: false
    });

    if (this.props.onClose) {
      this.props.onClose();
    }

    dialogActions.closeDialog();
  }

  open() {
    this.setState({
      isVisible: true
    });

    if (this.props.onOpen) {
      this.props.onOpen();
    }
  }

  render() {
    return this.state.isVisible ? (
      <div className={classNames("dialog", this.props.className)}>
        <div className="dialog-cover" />
        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }} className="dialog-content">
          {this.props.title ? (
            <h3 className="dialog-content-title">{t(this.props.title)}</h3>
          ) : (
            ""
          )}

          <div className="dialog-content-text">
            {this.props.content ? t(this.props.content) : this.props.children}
          </div>

          <div className="dialog-content-buttons">
            {this.props.buttonCancel ? (
              <a
                className={classNames(
                  "dialog-button dialog-cancel-button",
                  this.props.buttonCancel.className
                )}
                onClick={this.cancel}
              >
                {t(this.props.buttonCancel.text)}
              </a>
            ) : (
              ""
            )}
            {this.props.buttonConfirm ? (
              <a
                className={classNames(
                  "dialog-confirm-button dialog-button",
                  this.props.buttonConfirm.className
                )}
                onClick={this.confirm}
              >
                {t(this.props.buttonConfirm.text)}
              </a>
            ) : (
              ""
            )}
          </div>

          <a className="dialog-close" onClick={this.close}>
            X
          </a>
        </div>
      </div>
    ) : (
      false
    );
  }
}

Dialog.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  buttonConfirm: PropTypes.object,
  buttonCancel: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func
};

Dialog.defaultProps = {
  title: "",
  buttonConfirm: {
    className: "",
    text: "Confirm"
  },
  buttonCancel: {
    className: "",
    text: "Cancel"
  }
};

export default connect()(Dialog);
