import React from 'react';
import PropTypes from 'prop-types';

export default function UserSplit(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${props.width}px`}
      height={`${props.height}px`}
      viewBox='0 0 20 20'
    >
      <path
        d='M11.295 6.33333C12.1004 6.33333 12.7533 6.98625 12.7533 7.79167V11.7492C12.7533 13.8207 11.074 15.5 9.00246
        15.5C6.93089 15.5 5.25157 13.8207 5.25157 11.7492V7.79167C5.25157 6.98625 5.90448 6.33333 6.7099 6.33333H11.295ZM11.295
        7.58333H6.7099C6.59484 7.58333 6.50157 7.67658 6.50157 7.79167V11.7492C6.50157 13.1303 7.62121 14.25 9.00246 14.25C10.3836
        14.25 11.5033 13.1303 11.5033 11.7492V7.79167C11.5033 7.67658 11.41 7.58333 11.295 7.58333ZM2.12496 6.33333L4.94269 6.33255C4.65655
        6.67875 4.47016 7.11042 4.42756 7.5835L2.12496 7.58333C2.0099 7.58333 1.91663 7.67658 1.91663 7.79167V10.499C1.91663 11.6502 2.84983
        12.5833 4.00099 12.5833C4.16765 12.5833 4.32974 12.5637 4.48509 12.5268C4.55568 12.9467 4.68459 13.3472 4.86235 13.72C4.5881 13.7942
        4.29912 13.8333 4.00099 13.8333C2.15948 13.8333 0.666626 12.3405 0.666626 10.499V7.79167C0.666626 6.98625 1.31954 6.33333 2.12496
        6.33333ZM13.0622 6.33255L15.875 6.33333C16.6804 6.33333 17.3333 6.98625 17.3333 7.79167V10.5C17.3333 12.3409 15.8409 13.8333 14
        13.8333C13.703 13.8333 13.415 13.7945 13.1409 13.7216C13.32 13.3481 13.449 12.9476 13.5208 12.5276C13.6743 12.5642 13.8349 12.5833
        14 12.5833C15.1505 12.5833 16.0833 11.6506 16.0833 10.5V7.79167C16.0833 7.67658 15.99 7.58333 15.875 7.58333L13.5773 7.5835C13.5347
        7.11042 13.3483 6.67875 13.0622 6.33255ZM8.99996 0.5C10.3807 0.5 11.5 1.61929 11.5 3C11.5 4.38071 10.3807 5.5 8.99996 5.5C7.61921 5.5
        6.49996 4.38071 6.49996 3C6.49996 1.61929 7.61921 0.5 8.99996 0.5ZM14.4166 1.33333C15.5672 1.33333 16.5 2.26607 16.5 3.41667C16.5 4.56726
        15.5672 5.5 14.4166 5.5C13.266 5.5 12.3333 4.56726 12.3333 3.41667C12.3333 2.26607 13.266 1.33333 14.4166 1.33333ZM3.58329 1.33333C4.73388
        1.33333 5.66663 2.26607 5.66663 3.41667C5.66663 4.56726 4.73388 5.5 3.58329 5.5C2.4327 5.5 1.49996 4.56726 1.49996 3.41667C1.49996 2.26607
        2.4327 1.33333 3.58329 1.33333ZM8.99996 1.75C8.30963 1.75 7.74996 2.30964 7.74996 3C7.74996 3.69036 8.30963 4.25 8.99996 4.25C9.69029 4.25
        10.25 3.69036 10.25 3C10.25 2.30964 9.69029 1.75 8.99996 1.75ZM14.4166 2.58333C13.9564 2.58333 13.5833 2.95643 13.5833 3.41667C13.5833 3.8769
        13.9564 4.25 14.4166 4.25C14.8769 4.25 15.25 3.8769 15.25 3.41667C15.25 2.95643 14.8769 2.58333 14.4166 2.58333ZM3.58329 2.58333C3.12306 2.58333
        2.74996 2.95643 2.74996 3.41667C2.74996 3.8769 3.12306 4.25 3.58329 4.25C4.04353 4.25 4.41663 3.8769 4.41663 3.41667C4.41663 2.95643 4.04353
        2.58333 3.58329 2.58333Z'
        fill={props.color}
      />
    </svg>
  );
}

UserSplit.defaultProps = {
  color: '#737373',
  width: 20,
  height: 20,
};

UserSplit.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
