import React from "react";

import LoginHeader from "../components/login/login.header"
import MailSendComponent from "../components/login/mailsent.component"
import { t } from "../system/ui";
import { connect } from "react-redux";

class MailSendPage extends React.Component {
  render() {
    let mailType = this.props.params.mailType;
    return (
      <div id="wrapper" className="page-wrap login-wrap">
        { mailType === "verification-mail" && (
        <>
          <LoginHeader 
            loginButton={false}
            bookButton={false}
          />
          <MailSendComponent
            color="red"
            title={t("Verification Email Sent")}
            text={t("Please check your email and verify your email address.")}
          />
        </>)}

        { mailType === "verification-mail-already" && (
        <>
          <LoginHeader 
            loginButton={false}
            bookButton={false}
          />
          <MailSendComponent
            image="mail"
            color="red"
            title={t("Verification Email Already Sent")}
            text={t("Please check your email and verify your email address." )}
          />
        </>)}

        { mailType === "reset-token-expired" && (
        <>
          <LoginHeader 
            loginButton={true}
            bookButton={true}
          />
          <MailSendComponent
            image="expired"
            color="red"
            title={t("Oops!")}
            text={t("Reset password token is either expired or compromised, please try to forgot password from login screen!")}
          />
        </>)}

        { mailType === "verification-mail-expired" && (
        <>
          <LoginHeader 
            loginButton={true}
            bookButton={false}
          />
          <MailSendComponent
            image="expired"
            color="red"
            title={t("Oops!")}
            text={t("Verification token is either expired or compromised, please try to login again!")}
          />
        </>)}
      </div>
    );
  }
}


const mapStateToProps = (store) => ({
  language: store.ui.language
});

export default connect(mapStateToProps)(MailSendPage);

