import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import { uiActions, userActions } from '../actions';

import { checkIntegrations } from '../modules/account/ajax';
import user from '../modules/auth/user';
import { getQueryString } from '../system/string';

import Dialog from '../components/dialog';
import Header from '../components/layout/external.header';
import Preloader from '../components/layout/preloader';
import SidebarSecondary from '../components/layout/sidebar.secondary';
import Modal from '../components/modal';
import Breadcrumb from '../components/nav/breadcrumb';
import Notification from '../components/notification';
import { config, getApiPaths } from '../constants/config';
import { setExtAppType, setExtToken } from '../index';

class Ext extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: false,
      hasOverlayWindow: false,
    };
  }

  componentDidMount() {
    const queryToken = getQueryString('auth');
    const queryAppType = getQueryString('app_type');
    if (queryToken && queryToken !== undefined && queryToken !== '') {
      setExtToken(queryToken);
    }
    if (queryAppType && queryAppType !== undefined && queryAppType !== '') {
      setExtAppType(queryAppType);
    }
    const integrations = localStorage.getItem('integrations');

    if (!integrations || integrations === '{}') {
      checkIntegrations();
    } else {
      userActions.userIntegrations(integrations);
    }

    user
      .getUserPromise()
      .then(
        response => {
          const loggedUser = response.response;
          userActions.loggedUser(loggedUser);
          uiActions.updateLanguage(loggedUser.language || 'en');

          this.setState({
            logged: true,
          });

          localStorage.setItem(
            'restUrl',
            this.props.user.account.dataCenterUrl,
          );

          if (user.isSwitchAccount()) {
            checkIntegrations();
          }
        },
        response => {
          const redirectLocation =
            getQueryString('loginUri') || getApiPaths().login;
          window.location = redirectLocation;
        },
      )
      .catch(response => {});
  }

  render() {
    const menuparent = this.props.routes[1];
    const pageClass = classNames(
      'page-wrap external-page-wrap',
      this.props.pageClass,
      {
        'has-overlay-window': this.props.showDialog || this.props.showModal,
      },
    );

    return (
      <div id='wrapper' className={pageClass}>
        <Header />

        <Preloader />

        <div id='content' className='page-content'>
          <div id='main' className='page-main'>
            {this.props.router.location.pathname.indexOf('/ext/') < 0 && (
              <Breadcrumb />
            )}
            {this.props.children}
            {window.location.pathname.indexOf('ext/push') >= 0 ? (
              <SidebarSecondary
                {...this.props}
                submenus={config.menus.external.push}
                parent={menuparent}
              />
            ) : (
              ''
            )}
          </div>
        </div>

        {this.props.showDialog ? <Dialog {...this.props.dialog} /> : ''}

        {this.props.showModal ? <Modal {...this.props.modal} /> : ''}

        {this.props.showNotification ? (
          <Notification {...this.props.notification} />
        ) : (
          ''
        )}
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  ui: store.ui.ui,
  pageClass: store.ui.pageClass,
  language: store.ui.language,
  user: store.user.user,
  integrations: store.user.integrations,
  dialog: store.dialog.dialog,
  showDialog: store.dialog.showDialog,
  modal: store.modal.modal,
  showModal: store.modal.showModal,
  notification: store.notification.notification,
  showNotification: store.notification.showNotification,
});

export default connect(MapStatesToProps)(Ext);
