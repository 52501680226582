export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const periods = ['Minute', 'Hour', 'Day'];

export const localizationParams = {
  language: {
    tooltip: '',
    type: 'select',
    label: 'Language',
  },
  currency: {
    tooltip: '',
    type: 'select',
    label: 'Currency',
  },
};

export const paramsInWizard = [
  'mailContent',
  'subject',
  'logoUrl',
  'mailTo',
  '_mailContent',
  '_subject',
];

export const paramsTypes = {
  title: {
    label: 'Title',
    type: 'text',
  },
  delayInHours: {
    label: 'Trigger time in hours',
    tooltip:
      'Select how many hours should pass after the user action before the email is triggered.',
    type: 'integer',
    min: 1,
    max: 24,
  },
  interval: {
    label: 'Send Between',
    tooltip: 'The campaign will be launched during the time slot you choose.',
    type: 'interval',
    min: 1,
    max: 24,
  },
  delayInDays: {
    label: 'Trigger time in days',
    tooltip:
      'Select how many days should pass after the user action before the email is triggered.',
    type: 'integer',
    min: 1,
  },
  purchaseCount: {
    label: 'Purchase Count',
    tooltip: 'Min count of purchases done by the user',
    type: 'integer',
  },
  workingHour: {
    label: 'Select Time',
    tooltip: 'When should the campaign launch?',
    type: 'timepicker',
    min: 0,
    max: 23,
  },
  workingDay: {
    label: 'Select a day',
    tooltip: 'The day that campaign will work on',
    type: 'integer',
    min: 1,
    max: 7,
  },

  productLimit: {
    label: 'Product Limit',
    tooltip:
      'The Product Limit contains information that provides product count limit.',
    type: 'integer',
    min: 1,
    max: 9,
  },
  eventName: {
    label: 'Event Name',
    type: 'text',
    tooltip: 'Event name that will trigger the campaign.',
  },
  startDate: {
    label: 'Start Date',
    type: 'datepicker',
    tooltip:
      'The Product Limit contains information that provides product count limit.',
  },
  endDate: {
    label: 'End Date',
    type: 'datepicker',
    tooltip:
      'The Product Limit contains information that provides product count limit.',
  },
  mailTo: {
    label: 'Mail To',
    type: 'text',
  },
  discountRatio: {
    label: 'Discount Ratio (%)',
    type: 'text',
    tooltip:
      'The value that is the minimum discounted limit per product as a percentage',
  },
  delay: {
    label: 'Trigger Time',
    tooltip:
      'How long should past after visitor action for the campaign to be triggered?',
    type: 'timepicker',
    min: 0,
    max: 23,
  },
  sendBefore: {
    label: 'Send Before',
    tooltip: '',
    type: 'sendbefore',
    min: 1,
    max: 24,
  },
  klaviyoEventName: {
    label: 'Prefix Name',
    type: 'text',
    tooltip: 'Enter a prefix to add the campaign to the profile in Klaviyo',
  },
  frequency: {
    label: 'Sending Time',
    tooltip:
      'Select how many days before the next date the Push Sending Time should be sent according to the purchase frequency.',
    type: 'integer',
    min: 0,
  },
};

export const campaignInitialData = [
  {
    type: 'ORDER_FOLLOW_UP',
    scenarioName: 'Order Follow-Up',
    instanceId: 'ORDER_FOLLOW_UP',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_COLLABORATIVE_FILTERING',
        timeFrame: 'TODAY',
        itemCount: 9,
        score: 'ROCK',
      },
    ],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingHour: '09:00',
      delayInDays: '1',
    },
    customParams: [],
    prioritized: true,
    additionalRecommendationAllowed: true,
    sendToIdentifyApi: false,
  },
  {
    type: 'NICE_TO_MEET_YOU',
    scenarioName: 'Nice To Meet You',
    instanceId: 'NICE_TO_MEET_YOU',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_SOURCE_ALL_PRODUCTS',
        timeFrame: 'WEEKLY',
        itemCount: 9,
        score: 'ROCK',
      },
    ],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
    },
    customParams: [],
    prioritized: false,
    additionalRecommendationAllowed: true,
    sendToIdentifyApi: false,
  },
  {
    type: 'BIRTHDAY',
    scenarioName: 'Birthday Campaign',
    instanceId: 'BIRTHDAY',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_SMART_OFFERS',
        timeFrame: 'TODAY',
        itemCount: 3,
        score: 'ROCK',
      },
    ],
    additionalRecommendations: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_NEW_COMERS',
        timeFrame: 'WEEKLY',
        itemCount: 3,
        score: 'ROCK',
      },
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_DISCOUNTED_PRODUCTS',
        timeFrame: 'THIS_WEEK',
        itemCount: 3,
        score: 'ROCK',
      },
    ],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingHour: '10:00',
    },
    customParams: [],
    prioritized: false,
    additionalRecommendationAllowed: true,
    sendToIdentifyApi: false,
  },
  {
    type: 'ANNIVERSARY',
    scenarioName: 'Anniversary Campaign',
    instanceId: 'ANNIVERSARY',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_SMART_OFFERS',
        timeFrame: 'TODAY',
        itemCount: 3,
        score: 'ROCK',
      },
    ],
    additionalRecommendations: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_NEW_COMERS',
        timeFrame: 'WEEKLY',
        itemCount: 3,
        score: 'ROCK',
      },
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_DISCOUNTED_PRODUCTS',
        timeFrame: 'THIS_WEEK',
        itemCount: 3,
        score: 'ROCK',
      },
    ],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingHour: '10:00',
    },
    customParams: [],
    prioritized: false,
    additionalRecommendationAllowed: true,
    sendToIdentifyApi: false,
  },
  {
    type: 'CHURN',
    scenarioName: 'Churn',
    instanceId: 'CHURN',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_SMART_OFFERS',
        timeFrame: 'TODAY',
        itemCount: 9,
        score: 'ROCK',
      },
    ],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      purchaseCount: '3',
      workingHour: '09:00',
      delayInDays: '90',
    },
    customParams: [],
    prioritized: false,
    additionalRecommendationAllowed: true,
    sendToIdentifyApi: false,
  },
  {
    type: 'NEW_COMERS',
    scenarioName: 'New Arrivals',
    instanceId: 'NEW_COMERS',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_NEW_COMERS',
        timeFrame: 'WEEKLY',
        itemCount: 9,
        score: 'ROCK',
      },
    ],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingDay: '1',
      workingHour: '09:00',
    },
    customParams: [],
    prioritized: false,
    additionalRecommendationAllowed: true,
    sendToIdentifyApi: false,
  },
  {
    type: 'LAST_VISIT_ALTERNATIVES',
    scenarioName: 'Last Visit Alternatives',
    instanceId: 'LAST_VISIT_ALTERNATIVES',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_BROWSING_ALTERNATIVES',
        timeFrame: 'TODAY',
        itemCount: 9,
        score: 'ROCK',
      },
    ],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      delayInHours: '2',
      interval: '10-21',
    },
    customParams: [],
    prioritized: false,
    additionalRecommendationAllowed: true,
    capping: {
      capCount: '1',
      frequency: 'DAILY',
      types: [],
    },
    sendToIdentifyApi: false,
  },
  {
    type: 'LAST_VISIT_REMINDER',
    scenarioName: 'Last Visit Reminder',
    instanceId: 'LAST_VISIT_REMINDER',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_LAST_VISIT_REMINDER',
        timeFrame: 'TODAY',
        itemCount: 9,
        score: 'ROCK',
      },
    ],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      delayInHours: '3',
      interval: '10-21',
    },
    customParams: [],
    prioritized: true,
    additionalRecommendationAllowed: true,
    capping: {
      capCount: '2',
      frequency: 'DAILY',
      types: [],
    },
    sendToIdentifyApi: false,
  },
  {
    type: 'REPLENISHMENT',
    scenarioName: 'Replenisment',
    instanceId: 'REPLENISHMENT',
    accountId: '',
    status: 'PASSIVE',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_REPLENISHMENT_PRODUCTS',
        timeFrame: 'TODAY',
        itemCount: 9,
        score: 'ROCK',
      },
    ],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      frequency: '0',
      workingHour: '10:00',
    },
    prioritized: false,
    additionalRecommendationAllowed: false,
    lastLiveDate: null,
    template: '',
    startDate: '',
    capping: {
      capCount: '2',
      frequency: 'DAILY',
      types: [],
    },
    sendToIdentifyApi: false,
  },
  {
    type: 'WE_MISSED_YOU',
    scenarioName: 'We Missed You',
    instanceId: 'WE_MISSED_YOU',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_SMART_OFFERS',
        timeFrame: 'TODAY',
        itemCount: 9,
        score: 'ROCK',
      },
    ],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingHour: '09:00',
      delayInDays: '30',
    },
    customParams: [],
    prioritized: false,
    additionalRecommendationAllowed: true,
    sendToIdentifyApi: false,
  },
  {
    type: 'ABANDONED_CART',
    scenarioName: 'Abandoned Cart',
    instanceId: 'ABANDONED_CART',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_SOURCE_ALL_PRODUCTS',
        timeFrame: 'WEEKLY',
        itemCount: 9,
        score: 'ROCK',
      },
    ],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      delayInHours: '2',
      interval: '10-21',
    },
    customParams: [],
    prioritized: true,
    additionalRecommendationAllowed: false,
    capping: {
      capCount: '1',
      frequency: 'DAILY',
      types: [],
    },
    sendToIdentifyApi: false,
  },
  {
    type: 'TOP_SELLERS',
    scenarioName: 'Top Sellers',
    instanceId: 'TOP_SELLERS',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_STATISTICAL_TOP_SELLERS',
        timeFrame: 'WEEKLY',
        itemCount: 9,
        score: 'PURCHASE',
      },
    ],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingDay: '2',
      workingHour: '09:00',
    },
    customParams: [],
    prioritized: false,
    additionalRecommendationAllowed: true,
    sendToIdentifyApi: false,
  },
  {
    type: 'PRICE_DROP',
    scenarioName: 'Price Drop',
    instanceId: 'PRICE_DROP',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        source: 'RECOMMENDATION_PRICE_DROP',
        timeFrame: 'TODAY',
        itemCount: 9,
        score: 'ROCK',
      },
    ],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingHour: '11:00',
      discountRatio: '',
      period: 'every_day',
      sendBetweenHour: '10-14',
      triggerInHours: '2',
    },
    capping: {
      capCount: '1',
      frequency: 'DAILY',
      types: [],
    },
    customParams: [],
    prioritized: false,
    additionalRecommendationAllowed: true,
    sendToIdentifyApi: false,
  },
  {
    type: 'BACK_IN_STOCK',
    scenarioName: 'Back In Stock',
    accountId: '',
    status: 'PASSIVE',
    defaultRecommendation: [
      {
        type: 'INTELLIGENT',
        timeFrame: 'TODAY',
        itemCount: '9',
        score: '',
        source: 'RECOMMENDATION_BACK_IN_STOCK',
      },
    ],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingHour: '10:00',
    },
    prioritized: false,
    additionalRecommendationAllowed: false,
    instanceId: 'BACK_IN_STOCK',
    lastLiveDate: null,
    template: '',
    startDate: '',
    sendToIdentifyApi: false,
  },
  {
    type: 'RECOMMENDATION',
    scenarioName: 'E-Mail Recommendation',
    instanceId: '',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
    },
    customParams: [],
    prioritized: false,
    additionalRecommendationAllowed: true,
    schedule: {
      type: 'one_time',
      period: 'every_day',
      date: new Date().getTime() + 15 * 60 * 1000,
    },
    filters: [],
    executedBefore: false,
    sendToIdentifyApi: false,
  },
  {
    type: 'FLY',
    scenarioName: '',
    instanceId: '',
    accountId: '',
    status: 'PASSIVE',
    template: '',
    defaultRecommendation: [],
    additionalRecommendations: [],
    inclusions: [],
    exclusions: [],
    startDate: new Date().getTime(),
    params: {
      subject: '',
      mailContent: '',
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      mailTo: '{{user:email}}',
    },
    customParams: [],
    prioritized: false,
    additionalRecommendationAllowed: true,
    sendToIdentifyApi: false,
  },
];
