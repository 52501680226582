/**
 * @author Bilal Cinarli
 */

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { SortableHandle } from 'react-sortable-hoc';

import {
  rockstarTimeFrame,
  items,
  reactSelectItems,
} from '../../constants/datamaps/wizard';

import { t } from '../../system/ui';

import { getCategories } from '../../modules/account/ajax';
import { decodeHtml } from '../../system/string';

import Icon from '../icon';
import Icons from '../icons';

import { categoriesActions } from '../../actions';
import Tooltip from '../tooltip';
import { searchCategories } from '../../modules/category/ajax';
import { searchProducts } from '../../modules/product/ajax';

const selectItems = [
  ...reactSelectItems,
  { value: '55', label: '55' },
  { value: '60', label: '60' },
  { value: '65', label: '65' },
  { value: '70', label: '70' },
  { value: '75', label: '75' },
  { value: '100', label: '100' },
  { value: '125', label: '125' },
  { value: '150', label: '150' },
  { value: '175', label: '175' },
  { value: '200', label: '200' },
  { value: '225', label: '225' },
  { value: '250', label: '250' },
];

class CriterionCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      criterion: '',
      itemCount:
        typeof this.props.values.itemCount !== 'undefined'
          ? this.props.values.itemCount.value
          : 1,
    };

    this.onChange = this.onChange.bind(this);
    this.setCategories = this.setCategories.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onCreatableSelectInputChange = this.onCreatableSelectInputChange.bind(
      this,
    );
    this.onCreatableSelectChange = this.onCreatableSelectChange.bind(this);
  }

  componentDidMount() {
    this.validate();
    this.setCategories();
  }

  setCategories() {
    if (typeof this.props.values.criterion !== 'undefined') {
      this.setState(
        {
          criterion: this.props.values.criterion.value,
        },
        () => {
          this.onChange({
            value: this.state.criterion,
            label: this.state.criterion,
          });
        },
      );
    }
  }

  onChange(selectedValue) {
    const selectedCategory =
      'value' in selectedValue ? selectedValue.value : this.state.criterion;
    this.setState(
      {
        criterion: selectedCategory,
        categories: [
          {
            value: selectedCategory,
            title: decodeHtml(selectedCategory),
            label: decodeHtml(selectedCategory),
          },
        ],
      },
      () => {
        this.props.onCriteriaChange(
          this.refs.inputs,
          this.props.id,
          selectedCategory,
        );
      },
    );
    this.validate();
  }

  onInputChange(value) {
    this.validate();
    if (value.length > 2) {
      searchCategories(value, response => {
        const categoryOptions = [];
        if (response) {
          response.forEach(category => {
            categoryOptions.push({
              value: category,
              title: decodeHtml(category),
              label: decodeHtml(category),
            });
          });
        }
        this.setState({
          categories: categoryOptions,
        });
      });
    } else if (this.state.criterion) {
      this.setState({
        categories: [
          {
            value: this.state.criterion,
            title: decodeHtml(this.state.criterion),
            label: decodeHtml(this.state.criterion),
          },
        ],
      });
    } else {
      this.setState({
        categories: [],
      });
    }

    return value;
  }

  onCreatableSelectChange = value => {
    this.setState(
      {
        itemCount: value ? value.value : selectItems[0].value,
      },
      () => {
        this.props.onCriteriaItemCountChange(
          value ? value.value : selectItems[0].value,
          this.props.id,
        );
      },
    );
  };

  onCreatableSelectInputChange = value => {
    const maxCount = 250;
    if (parseInt(value) > maxCount) {
      return maxCount.toString();
    }
    return value;
  };

  validate() {
    const input = this.state?.criterion || '';

    if (input.length < 1) {
      this.setState({
        showRequiredError: true,
      });
    } else {
      this.setState({
        showRequiredError: false,
      });
    }
  }

  render() {
    const theTimeFrame =
      typeof this.props.values.timeFrame !== 'undefined'
        ? this.props.values.timeFrame.value
        : '';
    const theItemCount =
      typeof this.props.values.itemCount !== 'undefined'
        ? this.props.values.itemCount.value
        : '';
    const mobileItemCount =
      typeof this.props.values.mobileItemCount !== 'undefined'
        ? this.props.values.mobileItemCount.value
        : '';

    const DragHandle = SortableHandle(() => (
      <span className='draghandler'>
        <Icons name='burger' color='#e8e7f2' />
      </span>
    ));

    return (
      <li className='wizard-criterion' ref='inputs'>
        <DragHandle />
        <span className='criteria-type'>
          <Tooltip content={t('Static (Category)')} alignment='left'>
            <Icon name='tag' />
          </Tooltip>
        </span>
        <label className='item item-field is-select criteria-name'>
          <Select
            value={this.state.criterion}
            options={this.state.categories}
            name='criterion'
            clearable={false}
            searchable
            onChange={this.onChange}
            onInputChange={this.onInputChange}
            className='criteria-field one-whole'
            openOnClick={false}
            placeholder={t('Search Categories')}
          />
        </label>
        <label className='item item-field is-select criteria-time-frame'>
          <select
            name='timeFrame'
            ref='timeFrame'
            className='one-whole criteria-field'
            onChange={this.onChange}
            defaultValue={theTimeFrame}
          >
            {Object.keys(rockstarTimeFrame).map(item => {
              return (
                <option key={item} value={item}>
                  {t(rockstarTimeFrame[item])}
                </option>
              );
            })}
          </select>
        </label>

        {this.props.isSearch === undefined && (
          <label className='item item-field is-select criteria-item-count'>
            <span className='item-label'>product</span>{' '}
            <Select.Creatable
              value={this.state.itemCount}
              options={selectItems}
              name='itemCount'
              clearable={false}
              searchable
              disabled={this.props.isSingleRec}
              onChange={this.onCreatableSelectChange}
              onInputChange={this.onCreatableSelectInputChange}
              className='one-whole criteria-field'
              isValidNewOption={option =>
                option.label !== '' &&
                typeof option.label !== 'undefined' &&
                parseInt(option.label) < 251
              }
            />
          </label>
        )}

        {this.props.isSearch && (
          <>
            <label className='item item-field is-select criteria-item-mobile-count'>
              <span className='item-label'>mobile</span>{' '}
              <select
                name='mobileItemCount'
                className='one-whole criteria-field'
                onChange={this.onChange}
                defaultValue={mobileItemCount}
              >
                {items.map(item => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </label>

            <label className='item item-field is-select criteria-item-mobile-count'>
              <span className='item-label'>desktop</span>{' '}
              <select
                name='itemCount'
                className='one-whole criteria-field'
                onChange={this.onChange}
                defaultValue={theItemCount}
              >
                {items.map(item => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </label>
          </>
        )}

        <a
          onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
          className={
            this.props.isOnlySelection
              ? 'cancel-action cancel-action--disabled'
              : 'cancel-action'
          }
        >
          <Icons name='crossCircle' />
        </a>
        {this.state.showRequiredError ? (
          <span
            className='item-error has-error'
            style={{ display: 'block', marginTop: '10px' }}
          >
            {t('You should select at least one category from suggestions')}
          </span>
        ) : (
          ''
        )}
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  criteria: store.wizard.criteria,
  categories: store.categories.options,
  isCategoriesSet: store.categories.isSet,
});

export default connect(mapStatesToProps)(CriterionCategory);
