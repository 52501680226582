/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import Icon from "../icon";
import Icons from "../icons";

import { TextField } from "../fields";
import { t } from "../../system/ui";

class IncludesPriceRange extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      range: "",
      max: "",
      operator: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onOperationChange = this.onOperationChange.bind(this);
    this.setValues = this.setValues.bind(this);
  }

  componentDidMount() {
    this.setValues();
  }

  setValues() {
    let newState = {
      range:
        typeof this.props.values.range !== "undefined"
          ? this.props.values.range.value
          : "",
      max:
        typeof this.props.values.max !== "undefined"
          ? this.props.values.max.value
          : "",
      operator:
        typeof this.props.values.operation !== "undefined"
          ? this.props.values.operation.value
          : "FIXED"
    };
    this.props.subComponents.forEach(subComponent => {
      (newState["range" + subComponent.id] =
        typeof subComponent.values.range !== "undefined"
          ? subComponent.values.range.value
          : ""),
        (newState["max" + subComponent.id] =
          typeof subComponent.values.max !== "undefined"
            ? subComponent.values.max.value
            : "");
    });
    this.setState(newState);
  }

  onOperationChange(e) {
    this.setState({ operator: e.target.value });
    this.props.onInclusionChange(this.refs.inputs, this.props.id, {
      reset: "price_range"
    });
  }

  onChange(ref, id, e) {
    let value = e.target.value;
    if (!ref && !id) {
      this.setState({ [e.target.name]: value });
      this.props.onInclusionChange(this.refs.inputs, this.props.id);
    } else {
      let newState = {};
      newState[e.target.name + id] = value;
      this.setState(newState);
      this.props.onInclusionChange(this.refs[ref], id);
    }
  }

  render() {
    return (
      <li className="wizard-criterion">
        <span ref="inputs">
          <span className="criteria-type">
            <Icon name="dollar" color="#547494" />
          </span>
          <div className="wizard-criterion-text">
            <h3 className="wizard-criterion-title">{t("Price Range")}</h3>
            <p className="wizard-criterion-description">
              {this.props.selectedPage === "product" ? (
                <span>
                  {t(
                    "Price Range allows you to specify the maximum and minimum values of the products that will be recommended."
                  )}
                  <br />
                  {t(
                    "By selecting 'Fixed' you can define an explicit amount or price range for your product recommendations."
                  )}
                  <br />
                  {t(
                    "By selecting 'Percentage' you can select the price range of the recommendations to be between the x% above or below the price of the product customer is vieweing"
                  )}
                </span>
              ) : (
                <span>
                  {t(
                    "Price Range allows you to specify the maximum and minimum values of the products that will be recommended."
                  )}
                </span>
              )}
            </p>
          </div>
          <div
            className={
              this.state.operator === "FIXED" ? "" : "wizard-price-two"
            }
          >
            {this.props.selectedPage === "product" ? (
              <label className="item item-field is-select criteria-name wizard-operator">
                <select
                  name="operation"
                  className="one-whole criteria-field"
                  onChange={this.onOperationChange}
                  value={this.state.operator}
                >
                  <option value="FIXED">{t("Fixed")}</option>
                  <option value="PERCENTAGE">{t("Percentage")}</option>
                </select>
              </label>
            ) : (
              <input
                type="hidden"
                className="one-whole criteria-field"
                name="operation"
                defaultValue="FIXED"
              />
            )}
            <TextField
              type="text"
              name="range"
              label={
                !this.state.operator || this.state.operator === "FIXED"
                  ? "min"
                  : "%"
              }
              value={this.state.range}
              className={
                this.state.operator === "FIXED"
                  ? "item-field criteria-name wizard-price-range"
                  : "item-field criteria-name wizard-price-range wizard-price-item-two"
              }
              inputClassName="one-whole criteria-field"
              onChange={this.onChange.bind(this, null, null)}
            />
            {(!this.state.operator || this.state.operator === "FIXED") && (
              <TextField
                type="text"
                name="max"
                label="max"
                value={this.state.max}
                className="item-field criteria-name wizard-price-range"
                inputClassName="one-whole criteria-field"
                onChange={this.onChange.bind(this, null, null)}
              />
            )}
            <div className="buttons-criteria">
              <a
                onClick={this.props.onInclusionRemove.bind(null, this.props.id)}
                className={
                  this.props.selectedPage === "product"
                    ? "remove-criteria button-criteria product-cancel-button"
                    : "remove-criteria button-criteria recommend-filter-cancel"
                }
              >
                <Icons name="crossCircle" />
              </a>
              {this.props.subComponents.length < 2 &&
                this.state.operator === "FIXED" && (
                  <a
                    onClick={this.props.onInclusionAdd.bind(
                      null,
                      "price_range"
                    )}
                    className="button-criteria"
                  >
                    <Icons name="plusCircle" width="32" height="32" />
                  </a>
                )}
            </div>
          </div>
        </span>
        {this.props.subComponents.map((subComponent, ind) => {
          if (ind > 0) {
            return (
              <div
                className={classNames("criteria-subcomponent", {
                  "wizard-price-two": this.state.operator !== "FIXED",
                  "criteria-subcomponent-mg":
                    this.props.selectedPage === "product"
                })}
                ref={"subComponentInputs" + subComponent.id}
                key={subComponent.id}
              >
                <TextField
                  type="text"
                  name="range"
                  label={
                    !this.state["operator" + subComponent.id] ||
                    this.state["operator" + subComponent.id] === "FIXED"
                      ? "min"
                      : "%"
                  }
                  value={this.state["range" + subComponent.id]}
                  className={
                    this.state["operator" + subComponent.id] === "FIXED"
                      ? "item-field criteria-name wizard-price-range"
                      : "item-field criteria-name wizard-price-range wizard-price-item-two"
                  }
                  inputClassName="one-whole criteria-field"
                  onChange={this.onChange.bind(
                    this,
                    "subComponentInputs" + subComponent.id,
                    subComponent.id
                  )}
                />
                {(!this.state["operator" + subComponent.id] ||
                  this.state["operator" + subComponent.id] === "FIXED") && (
                  <TextField
                    type="text"
                    name="max"
                    label="max"
                    value={this.state["max" + subComponent.id]}
                    className="item-field criteria-name wizard-price-range"
                    inputClassName="one-whole criteria-field"
                    onChange={this.onChange.bind(
                      this,
                      "subComponentInputs" + subComponent.id,
                      subComponent.id
                    )}
                  />
                )}
                <div className="buttons-criteria">
                  {ind === this.props.subComponents.length - 1 &&
                    this.state.operator === "FIXED" && (
                      <a
                        onClick={this.props.onInclusionAdd.bind(
                          null,
                          subComponent.type
                        )}
                        className="button-criteria"
                      >
                        <Icons name="plusCircle" width="32" height="32" />
                      </a>
                    )}
                  <a
                    onClick={this.props.onInclusionRemove.bind(
                      null,
                      subComponent.id
                    )}
                    className="remove-criteria button-criteria"
                  >
                    <Icons name="crossCircle" />
                  </a>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate,
  includes: store.wizard.includes
});

export default connect(mapStatesToProps)(IncludesPriceRange);
