import React from 'react';
import classes from './SFYScheduling.module.scss';
import { t } from '../../../system/ui';
import Tooltip from '../../tooltip';

const SelectInterval = ({ componentDetail, editCampaign, fieldValue }) => {
  const { tooltipText, cardName, cardTitle, type } = componentDetail;
  const start = 1;
  const end = 24;
  const list = [];
  for (let i = start; i <= end; i++) {
    list.push(i);
  }
  const onChange = e => {
    const { name, value } = e.target;
    let startValue = fieldValue.split('-')[0];
    let endValue = fieldValue.split('-')[1];
    if (name === 'intervalStart') {
      startValue = value;
    } else {
      endValue = value;
    }
    editCampaign(cardName, `${startValue}-${endValue}`);
  };
  return (
    <label className={`is-select ${classes['sfy-scheduling-repeat']}`}>
      <span className={classes['sfy-label-wrapper']}>
        <span className={classes['sfy-label']}>{t(cardTitle)}</span>
        <Tooltip content={t(tooltipText)} alignment='left'>
          <i className='icon-info' role='presentation'>
            <span className='for-screenreader-only' />
          </i>
        </Tooltip>
      </span>
      <select
        value={fieldValue?.split('-')[0]}
        className={`label-interval--start ${classes['sfy-select']} ${classes['sfy-border']}`}
        onChange={onChange}
        name={`${type}Start`}
      >
        {list.map(item => {
          const higherValue = fieldValue?.split('-')[1];
          if (higherValue <= item) {
            return (
              <option key={item} value={item} disabled>
                {item}
              </option>
            );
          }
          return (
            <option key={item} value={item}>
              {item}
            </option>
          );
        })}
      </select>
      -
      <select
        value={fieldValue?.split('-')[1]}
        className={`label-interval--end ${classes['sfy-select']} ${classes['sfy-border']}`}
        onChange={onChange}
        name={`${type}End`}
      >
        {list.map(item => {
          const lowerValue = fieldValue?.split('-')[0];
          if (lowerValue >= item) {
            return (
              <option key={item} value={item} disabled>
                {item}
              </option>
            );
          }
          return (
            <option key={item} value={item}>
              {item}
            </option>
          );
        })}
      </select>
    </label>
  );
};

export default SelectInterval;
