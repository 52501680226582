/**
 * @author Barış Altun
 */

import React from "react";
import Select from "react-select";
import { connect } from "react-redux";
import classNames from "classnames";

import { t } from "../../system/ui";
import { filterActions, dateActions } from "../../actions";
import DeviceSelect from "./device-select";
import Icons from "../icons";

class BannerifyFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: "",
      titles: "",
      status: "latest",
      resetDevice: false
    };

    this.setSelectedFilters = this.setSelectedFilters.bind(this);
    this.reset = this.reset.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeGroups = this.onChangeGroups.bind(this);
    this.onChangeTitles = this.onChangeTitles.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
  }

  componentDidMount() {
    this.setSelectedFilters();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.reset === true) {
      this.reset();
    }

    if (newProps.selectedStatus !== this.props.selectedStatus) {
      this.setState({
        status: newProps.selectedStatus
      });
      filterActions.showButtons();
    }

    if (newProps.selectedGroup !== this.props.selectedGroup) {
      this.setState({
        groups: newProps.selectedGroup
      });
      filterActions.showButtons();
    }
  }

  setSelectedFilters() {
    this.setState({
      groups: this.props.selectedGroup,
      status: this.props.selectedStatus
    });
  }

  resetFilters(e) {
    e.preventDefault();
    this.setState({
      groups: "",
      titles: "",
      status: "latest",
      resetDevice: true
    });
    this.props.toggleAnalyticsFilters();
    filterActions.resetAllFilters();
  }

  updateFilters(e) {
    e.preventDefault();

    this.props.toggleAnalyticsFilters();
    if (this.props.disableButtons) {
      return false;
    }
    filterActions.updateFilters();
  }

  reset() {
    this.setState({
      groups: "",
      titles: "",
      status: "latest"
    });
  }

  onChange(selectedOptions) {
    this.setState({
      status: selectedOptions.value
    });

    filterActions.showButtons();
  }

  onChangeGroups(selectedOption) {
    this.setState({
      groups: selectedOption ? selectedOption.value : ""
    });

    filterActions.showButtons();
  }

  onChangeTitles(selectedOption) {
    this.setState({
      titles: selectedOption ? selectedOption.value : ""
    });

    filterActions.showButtons();
  }

  render() {
    let style = {
      width: "250px"
    };

    let groups = [{ value: "", label: t("All Groups") }];

    this.props.groups.forEach(item => {
      groups.push({
        value: item,
        label: item
      });
    });

    let isSelectedGroup = groups.filter(group => {
      return group.value === this.state.groups;
    });
    if (!isSelectedGroup.length && this.state.groups) {
      groups.push({
        value: this.state.groups,
        label: this.state.groups
      });
    }

    let titles = [{ value: "", label: t("All Titles") }];

    this.props.titles.forEach(item => {
      titles.push({
        value: item,
        label: item
      });
    });

    let isSelectedTitle = titles.filter(title => {
      return title.value === this.state.titles;
    });
    if (!isSelectedTitle.length && this.state.titles) {
      titles.push({
        value: this.state.titles,
        label: this.state.titles
      });
    }

    let status = [
      { value: "latest", label: t("Show Active Banners") },
      { value: "all", label: t("Show All Banners") }
    ];
    let className = "analytics-filters group hide-filters";
    if (this.props.showBannerifyFilters) {
      className = "analytics-filters group show-filters";
    }
    return (
      <div className={className} ref="pageFilters">
        <label
          className="item item-stacked is-select bannerify-creatable-label"
          style={style}
        >
          <span className="item-label">{t("Banner Group")}</span>
          <Select.Creatable
            clearable={true}
            name="bannerGroup"
            options={groups}
            value={this.state.groups}
            onChange={this.onChangeGroups}
            promptTextCreator={label => {
              return label;
            }}
          />
        </label>

        <label
          className="item item-stacked is-select bannerify-creatable-label "
          style={style}
        >
          <span className="item-label">{t("Banner Titles")}</span>
          <Select.Creatable
            clearable={true}
            name="bannerTitle"
            options={titles}
            value={this.state.titles}
            onChange={this.onChangeTitles}
            promptTextCreator={label => {
              return label;
            }}
          />
        </label>

        <DeviceSelect resetDevice={this.state.resetDevice} />

        <label className="item item-stacked is-select" style={style}>
          <span className="item-label">&nbsp;</span>
          <Select
            searchable={false}
            clearable={false}
            name="bannerStatus"
            options={status}
            value={this.state.status}
            onChange={this.onChange}
          />
        </label>

        <div
          className="analytics-filters-close"
          onClick={this.props.toggleAnalyticsFilters}
        >
          <Icons name="cross" color="#7b7e7e" />
        </div>
        <div className="analytics-filters-actions">
          {this.props.visibleReset ? (
            <a
              className="cancel-action analytics-filters--reset"
              onClick={this.resetFilters}
            >
              {t("Reset Filters")}
            </a>
          ) : (
            ""
          )}

          <a
            className={classNames("tertiary-action analytics-filters--update", {
              disabled: this.props.disableButtons
            })}
            onClick={this.updateFilters}
          >
            {t("Update")}
          </a>
        </div>
      </div>
    );
  }
}

const mapStatsToProps = store => ({
  groups: store.filters.bannerifyGroups,
  titles: store.filters.bannerifyTitles,
  status: store.filters.bannerifyStatus,
  reset: store.filters.reset,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  visibleReset: store.filters.visibleReset,
  disableButtons: store.filters.disableButtons
});

export default connect(mapStatsToProps)(BannerifyFilters);
