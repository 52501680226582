import * as facetedSearchActions from 'constants/actions/faceted-search';
import initialState, {
  campaignDefaults,
} from 'reducers/faceted-search/initialState';

export default function facetedSearchReducer(state = initialState, action) {
  switch (action.type) {
    case facetedSearchActions.FACETED_SEARCH_LOADING: {
      return {
        ...state,
        ui: {
          ...initialState.ui,
          loading: true,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_SUCCESS: {
      return {
        ...state,
        ui: {
          ...initialState.ui,
          loaded: true,
          saveCampaign: {
            ...state.ui.saveCampaign,
            loaded: false,
            loading: false,
            error: null,
          },
        },
        campaigns: action.data,
      };
    }
    case facetedSearchActions.FACETED_SEARCH_ERROR: {
      return {
        ...state,
        ui: {
          ...initialState.ui,
          error: action.error,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_SAVE_LOADING: {
      return {
        ...state,
        ui: {
          ...state.ui,
          saveCampaign: {
            ...initialState.ui.saveCampaign,
            loading: true,
          },
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_SAVE_SUCCESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          saveCampaign: {
            ...initialState.ui.saveCampaign,
            loaded: true,
          },
        },
        campaignForm: action.response || state.campaignForm,
        banners: action.banners,
      };
    }
    case facetedSearchActions.FACETED_SEARCH_SAVE_ERROR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          saveCampaign: {
            ...initialState.ui.saveCampaign,
            error: action.error,
          },
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_TOGGLE_ADD_FACET_MODAL: {
      return {
        ...state,
        addFacetModal: {
          ...state.addFacetModal,
          isOpen: !state.addFacetModal.isOpen,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_FORM_UPDATE: {
      if (action.object === null || action.object === undefined) {
        return {
          ...state,
          campaignForm: campaignDefaults,
          banners: [],
          bannersCopy: [],
        };
      }
      return {
        ...state,
        campaignForm: {
          ...state.campaignForm,
          ...action.object,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_GET_FACET_LIST_LOADING: {
      return {
        ...state,
        facetList: {
          ...initialState.facetList,
          loading: true,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_GET_FACET_LIST_SUCCESS: {
      return {
        ...state,
        facetList: {
          ...initialState.facetList,
          loaded: true,
          data: action.data,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_GET_FACET_LIST_ERROR: {
      return {
        ...state,
        facetList: {
          ...initialState.facetList,
          error: action.error,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_GET_ORDERING_LIST_LOADING: {
      return {
        ...state,
        orderList: {
          ...initialState.orderList,
          loading: true,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_GET_ORDERING_LIST_SUCCESS: {
      return {
        ...state,
        orderList: {
          ...initialState.orderList,
          loaded: true,
          data: action.data,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_GET_ORDERING_LIST_ERROR: {
      return {
        ...state,
        orderList: {
          ...initialState.orderList,
          error: action.error,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_ARCHIVE_CAMPAIGN: {
      return {
        ...state,
        campaigns: state.campaigns.filter(
          c => c.instanceId !== action.instanceId,
        ),
      };
    }
    case facetedSearchActions.FACETED_SEARCH_GET_SINGLE_REPORT_LOADING: {
      return {
        ...state,
        singleReport: {
          ...state.singleReport,
          loading: true,
          loaded: false,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_GET_SINGLE_REPORT_SUCCESS: {
      return {
        ...state,
        singleReport: {
          ...state.singleReport,
          loading: false,
          loaded: true,
          data: { ...state.singleReport.data, ...action.data },
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_GET_SINGLE_REPORT_ERROR: {
      return {
        ...state,
        singleReport: {
          ...state.singleReport,
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_CREATE_DRAFT_CAMPAIGN: {
      return {
        ...state,
        campaigns: [...state.campaigns, action.data],
      };
    }
    case facetedSearchActions.FACETED_SEARCH_GET_ALL_REPORTS_LOADING: {
      return {
        ...state,
        allReportsSum: {
          ...initialState.allReportsSum,
          loading: true,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_GET_ALL_REPORTS_SUCCESS: {
      return {
        ...state,
        allReportsSum: {
          ...initialState.allReportsSum,
          data: action.data,
          loaded: true,
        },
      };
    }
    case facetedSearchActions.FACETED_SEARCH_GET_ALL_REPORTS_ERROR: {
      return {
        ...state,
        allReportsSum: {
          ...initialState.allReportsSum,
          error: action.error,
        },
      };
    }
    case 'UPDATE_REPORT_DATA': {
      return {
        ...state,
        singleReport: {
          ...state.singleReport,
          data: action.data.length > 0 ? action.data : state.singleReport.data,
        },
      };
    }
    case 'UPDATE_TOTAL_REVENUE': {
      return {
        ...state,
        singleReport: {
          ...state.singleReport,
          totalRevenue: {
            ...state.singleReport.totalRevenue,
            [action.instanceId]: action.value,
          },
        },
      };
    }
    case 'UPDATE_BANNER_COPY': {
      return {
        ...state,
        bannersCopy: action.bannersCopy,
      };
    }
    case 'UPDATE_BANNERS': {
      return {
        ...state,
        banners: action.banners,
        bannersCopy: action.banners,
      };
    }
    default:
      return state;
  }
}
