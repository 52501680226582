import React, { memo, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Context as CustomerProfileContext } from '../store/customerProfileProvider';
import {
  BrandsRecommendation,
  // PredictiveSegmentsCard,
  PushCategory,
  SegmentsCard,
  SFYHeader,
  // SFYNoteContainer,
  SFYUserCard,
  SFYUserCardWrapper,
  UserActivityCard,
  UserCardStats,
  SectionWrapper,
  LastProductRecommendations,
} from '../../../../components/sfy-components/module-exports';

import TopBrands from '../../../../components/sfy-components/SFYUserCard/brands-recommendations/brands/TopBrands';
import {
  enumCategoryVisit,
  enumLastProductRecommendations,
  enumPush,
  enumSegmentType,
  enumUserActivityType,
  enumUserInformationType,
  getDynamicBreadCrumbOptions,
  notesSegmentationStyle,
  BrandsCategoryVisitStyle,
  enumEmail,
} from '../constants/constants';

import CustomerProfileService from '../services/CustomerProfileService';

const CustomerProfileDetailPage = ({ params: { id } }) => {
  const {
    state: {
      customerProfile: {
        user,
        segments,
        stats,
        lastProductHistory,
        email,
        topBrands,
        lastProductRecommendations,
        categoryVisit,
        push,
      },
    },
    setCustomerProfile,
    resetCustomerProfile,
  } = useContext(CustomerProfileContext);

  const currency = useSelector(
    state => state.switchedUser.switchedUser.account.mainCurrency,
  );

  const [breadCrumb] = useState(() => `User ID : ${id}`);

  useEffect(() => {
    CustomerProfileService.get({ id }).then(response => {
      setCustomerProfile(response);
    });
    return () => {
      resetCustomerProfile();
    };
  }, []);

  return (
    <>
      <SFYHeader breadCrumbOptions={getDynamicBreadCrumbOptions(breadCrumb)} />
      <SFYUserCardWrapper>
        <SFYUserCard user={user} />
        <div style={notesSegmentationStyle}>
          <SectionWrapper>
            <SegmentsCard
              segments={segments}
              enumSegmentType={enumSegmentType}
            />
            <div style={BrandsCategoryVisitStyle}>
              <TopBrands brands={topBrands} />
              <LastProductRecommendations
                enumCategoryVisit={enumCategoryVisit}
                categoryVisit={categoryVisit}
                isCategoryVisit
              />
            </div>
          </SectionWrapper>
          <UserCardStats
            stats={stats}
            enumUserInformationType={enumUserInformationType}
          />
        </div>
      </SFYUserCardWrapper>

      <UserActivityCard
        title='Last Product History'
        items={lastProductHistory}
        enumUserActivityType={enumUserActivityType.lastProductHistory}
        currency={currency.symbol ? currency.symbol : currency.code}
      />
      <PushCategory
        enumCategoryVisit={enumCategoryVisit}
        email={email}
        push={push}
        enumPush={enumPush}
        enumEmail={enumEmail}
      />
      <BrandsRecommendation
        lastProductRecommends={lastProductRecommendations}
        enumLastProductRecommendations={enumLastProductRecommendations}
      />
    </>
  );
};

export default memo(CustomerProfileDetailPage);
