import React from "react";

class Yandex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 220 220"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <title>yandex</title>
        <path
          d="M172.94,0H47.06A47.06,47.06,0,0,0,0,47.06V172.94A47.06,47.06,0,0,0,47.06,220H172.94A47.06,47.06,0,0,0,220,172.94V47.06A47.06,47.06,0,0,0,172.94,0ZM110,202.62A92.62,92.62,0,1,1,202.62,110,92.62,92.62,0,0,1,110,202.62Z"
          fill={this.state.color}
        />
        <path
          d="M155.78,57.65c-6.32,6.32-45.84,44.78-45.84,44.78L64.22,57.65A33,33,0,0,0,50.36,71.5C63.11,84.25,99.91,120,99.91,120l-.11,60.58a29.28,29.28,0,0,0,9.31,1.77,44.42,44.42,0,0,0,10.64-1.77V120.5l49.89-48.66A39.2,39.2,0,0,0,155.78,57.65Z"
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default Yandex;
