import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const confirmationTickIcon = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 5.5L5 10L13 1'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};

confirmationTickIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

confirmationTickIcon.defaultProps = {
  width: '14px',
  height: '12px',
  color: '#63CA63',
};

export default confirmationTickIcon;
