import React, { useState } from 'react';
import classes from './TextArea.module.scss';

const TextArea = ({ value, onChange, ...props }) => {
  const [state, setState] = useState(value || '');
  const handleOnchange = ({ target }) => {
    setState(target.value);
    onChange(target.value);
  };
  return (
    <textarea
      className={classes.textArea}
      value={state}
      onChange={handleOnchange}
      {...props}
    />
  );
};

export default TextArea;
