import Services from '../../../../service/Services';

class FtpServices extends Services {
  constructor({ endPoint, paginationIsActive }) {
    super({ endPoint, paginationIsActive });
  }
}

const FtpService = (endPoint, paginationIsActive = false) => {
  return new FtpServices({
    endPoint,
    paginationIsActive,
  });
};

export default FtpService;
