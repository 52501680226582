import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { changeGroupOperator } from '../../reducers/rbs';
import Tooltip from '../tooltip';

const AndOrOperator = ({ operatorType, position, type, tooltip }) => {
  const dispatch = useDispatch();
  const onChange = value => {
    dispatch(changeGroupOperator({ position, operator: value, type }));
  };

  const tooltipContent = () => {
    if (type === 'group') {
      return 'When you make this change all group conditions will be changing';
    }

    return 'When you make this change all criteria conditions will be changing';
  };

  const operatorSelect = () => (
    <div className='rbs-and-or-operator-select'>
      <div
        className={`rbs-operators ${operatorType === 'and' ? 'selected' : ''}`}
        onClick={() => onChange('and')}
      >
        AND
      </div>
      <div
        className={`rbs-operators ${operatorType === 'or' ? 'selected' : ''}`}
        onClick={() => onChange('or')}
      >
        OR
      </div>
    </div>
  );

  return (
    <div className='rbs-and-or-operator'>
      <div className='rbs-and-or-operator-line' />
      {tooltip ? (
        <Tooltip
          overlayClassName='rbs-and-or-operator-tooltip-overlay'
          className='rbs-and-or-operator-tooltip'
          content={tooltipContent()}
          alignment={type === 'group' ? 'left' : 'right'}
          newTooltip
        >
          {operatorSelect()}
        </Tooltip>
      ) : (
        operatorSelect()
      )}
    </div>
  );
};

AndOrOperator.propTypes = {
  operatorType: PropTypes.string.isRequired,
  position: PropTypes.shape([]).isRequired,
  type: PropTypes.string.isRequired,
  tooltip: PropTypes.bool,
};

AndOrOperator.defaultProps = {
  tooltip: false,
};

export default AndOrOperator;
