import api from '../../system/api';
import { $http, plainGet } from '../../system/ajax';

import { getApiKey } from '../auth/user';

export const searchProducts = (keyword, callback) => {
  plainGet(
    `${api.getRestEndPoint('search/product')}/?query=${encodeURI(
      keyword,
    )}&apiKey=${getApiKey()}`,
    callback,
  );
};

export const searchRbsProducts = (keyword, callback) => {
  plainGet(
    `${api.getRestEndPoint('search/rbs/product')}/?query=${encodeURI(
      keyword,
    )}&apiKey=${getApiKey()}`,
    callback,
  );
};

export const searchDetailedProducts = (keyword, callback) => {
  plainGet(
    `${api.getRestEndPoint('search/product')}/?query=${encodeURI(
      keyword,
    )}&apiKey=${getApiKey()}&withDetail=true`,
    callback,
  );
};

export const searchMultipleProducts = (list, callback) => {
  $http(`${api.getRestEndPoint('search/products')}?apiKey=${getApiKey()}`)
    .post(list, { type: 'json', preventAbort: false })
    .then(response => {
      callback(response);
    });
};

export const searchMultipleProductsAsync = async ({ list }) => {
  const response = $http(
    `${api.getRestEndPoint('search/products')}?apiKey=${getApiKey()}`,
  ).post(list, { type: 'json', preventAbort: false });

  return await response;
};

export const searchPromotion = (keyword, callback) => {
  plainGet(
    `${api.getRestPath()}search/promotion/${keyword}?apiKey=${getApiKey()}`,
    callback,
  );
};

export const getRandomProduct = callback => {
  plainGet(
    `${api.getRestEndPoint('search/randomProduct')}?apiKey=${getApiKey()}`,
    callback,
  );
};

export const postFileToGetProducts = (file, callback) => {
  const url = `${api.getRestEndPoint(
    'search/productCSV',
  )}?&apiKey=${getApiKey()}`;
  const formData = new FormData();
  formData.append('file', file);

  $http(url)
    .post(formData, { type: 'form', fileType: file.type })
    .then(callback);
};
