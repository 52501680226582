import React from "react";

class IconSentPush extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
      >
        <path
          fill={this.state.color}
          d="M9.105,15.447C9.276,15.787,9.623,16,10,16c0.021,0,0.041-0.001,0.061-0.002
	c0.4-0.024,0.747-0.284,0.882-0.662l5-14c0.13-0.363,0.039-0.77-0.235-1.043c-0.273-0.272-0.68-0.365-1.043-0.234l-14,5
	C0.287,5.193,0.026,5.54,0.002,5.939c-0.024,0.4,0.192,0.775,0.551,0.955l4.586,2.292L11,5l-4.187,5.862L9.105,15.447z"
        />
      </svg>
    );
  }
}

export default IconSentPush;
