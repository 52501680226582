import { createSlice } from '@reduxjs/toolkit';
import {
  getUserListReducer,
  getAccountListReducer,
  getEgsVideosReducer,
  getWelcomeVideoReducer,
  getInsightsReducer,
} from './extraReducers';

const managedServicesSlice = createSlice({
  name: 'managedServices',
  initialState: {
    accountList: {
      loading: false,
      loaded: false,
      data: [],
    },
    userList: {
      loading: false,
      loaded: false,
      data: {},
    },
    egsVideos: {
      loading: false,
      loaded: false,
      data: [],
    },
    welcomeVideo: {
      loading: false,
      loaded: false,
      data: {},
    },
    insights: {
      loading: false,
      loaded: false,
      data: [],
    },
    form: {
      formName: '',
      showForm: false,
    },
  },
  reducers: {
    showForm(state, action) {
      state.form.formName = action.payload.formName;
      state.form.showForm = action.payload.status;
    },
  },
  extraReducers: {
    ...getUserListReducer,
    ...getAccountListReducer,
    ...getEgsVideosReducer,
    ...getWelcomeVideoReducer,
    ...getInsightsReducer,
  },
});

export const isMainRequestsLoaded = state => {
  const accountListLoaded = state.managedServices.accountList.loaded;
  const userListLoaded = state.managedServices.userList.loaded;
  const egsVideosLoaded = state.managedServices.egsVideos.loaded;
  const welcomeVideoLoaded = state.managedServices.welcomeVideo.loaded;

  return (
    accountListLoaded && userListLoaded && egsVideosLoaded && welcomeVideoLoaded
  );
};

export const { showForm } = managedServicesSlice.actions;

export default managedServicesSlice.reducer;
