import React from "react";
import connect from "react-redux/es/connect/connect";
import classNames from "classnames";
import {sgfDivTableActions} from "../../../../../actions";


class SgfDivTableCell extends React.Component {
    constructor(props) {
        super(props);

        this.onClickItem = this.onClickItem.bind(this);
    }

    onClickItem(item) {
        if (item.fieldMap.sortKey) {
          sgfDivTableActions.clickCell(item);
        }
    }

  render() {
        if (this.props.type === 'head-item') {
            return (
              <div className={classNames("sgf-div-table__" + this.props.className, this.props.data.className, this.props.headClass)} onClick={this.onClickItem.bind(this, this.props.data)}>
                { this.props.data.name}
              </div>
            )
        } else if (this.props.type === 'cell') {
            if (this.props.data.type === 'customComponent' && this.props.data.component) {
                let CustomComponent = this.props.data.component;
                return (
                  <CustomComponent data={this.props.data.componentData}/>
                )
            }
            return (
              <div className={classNames("sgf-div-table__" + this.props.className, this.props.data.className)}>
                <div className="sgf-div-table__cell__data-field">
                  {this.props.data.content}
                </div>
              </div>
            )
        }
    }
}

export default connect()(SgfDivTableCell);
