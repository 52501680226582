/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";

import { config } from "../constants/config";
import { pageMap } from "../constants/datamaps/pages";

import { t } from "../system/ui";
import { setTitle } from "../system/document";

import { uiActions, campaignActions } from "../actions";
import {
  getActiveCampaigns,
  getCampaignsByStatus
} from "../modules/campaigns/ajax";
import { getCampaignsByPage } from "../modules/campaigns/data";

import WidgetReport from "../components/widget/report";
import StatusFilters from "../components/filters/status-filters";

class Report extends React.Component {
  constructor(props) {
    super(props);

    let children =
      config.menus.primary.recommendations.children[this.props.params.report];

    this.state = {
      sub: this.props.params.report,
      children: children,
      pageTitle: children.name + " Page",
      campaigns: [],
      _campaigns: [],
      campaignsNotRequested: true
    };

    this.changeStatus = this.changeStatus.bind(this);
    this.changeMedium = this.changeMedium.bind(this);
  }

  componentDidMount() {
    setTitle(
      t(
        this.state.pageTitle.charAt(0).toUpperCase() +
          this.state.pageTitle.slice(1)
      ) +
        " " +
        t("Reports")
    );

    uiActions.isLoading();

    campaignActions.filterActiveCampaigns();

    getActiveCampaigns(response => {
      let campaigns = getCampaignsByPage(response, pageMap[this.state.sub]);

      this.setState({
        campaigns: campaigns,
        _campaigns: campaigns,
        campaignsNotRequested: false
      });

      uiActions.isLoaded();
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.status !== this.props.status) {
      this.changeStatus(newProps.status);
    }

    if (newProps.medium !== this.props.medium) {
      this.changeMedium(newProps.medium);
    }
  }

  changeStatus(status) {
    uiActions.isLoading();

    getCampaignsByStatus(status, allCampaigns => {
      let campaigns = getCampaignsByPage(allCampaigns, pageMap[this.state.sub]);

      this.setState({
        campaigns: campaigns,
        _campaigns: campaigns,
        campaignsNotRequested: false
      });

      uiActions.isLoaded();
    });
  }

  changeMedium(value) {
    let showAll = false;
    if (!value.length) {
      showAll = true;
    }
    let visibleCampaigns = this.state._campaigns,
      filteredMediums = [],
      search = ["ALL"];

    search = search.concat(value);

    if (search.indexOf("PCTABLET") > -1) {
      search = search.concat(["PC", "TABLET"]);
    }

    if (search.indexOf("ANDROID") > -1 || search.indexOf("IOS") > -1) {
      search = search.concat(["ANDROIDIOS"]);
    }

    visibleCampaigns.forEach(item => {
      if (showAll) {
        filteredMediums.push(item);
      } else {
        if (search.indexOf(item.device) > -1) {
          filteredMediums.push(item);
        }
      }
    });

    this.setState({
      campaigns: filteredMediums,
      campaignsNotRequested: false
    });
  }

  render() {
    let campaignsStatus = this.props.status,
      statusText = "";
    if (campaignsStatus === "ACTIVE") {
      statusText = "Live";
    } else if (campaignsStatus === "TEST") {
      statusText = "Test";
    } else {
      statusText = "Archived";
    }
    statusText += " Recommendation";
    statusText = t(statusText);
    return (
      <div>
        <div className="page-filters has-filter-form">
          <StatusFilters />
        </div>

        {this.state.campaignsNotRequested || this.state.campaigns.length > 0 ? (
          <div>
            {this.state.campaigns.map(campaign => {
              return (
                <WidgetReport
                  key={campaign.instanceId}
                  {...campaign}
                  campaign={campaign}
                  campaignType={this.props.campaignType}
                />
              );
            })}
          </div>
        ) : (
          this.props.ui !== "isLoading" && (
            <div className="no-report">
              <p className="no-report-info">
                {t("no-recommendation-report-info", statusText)}
              </p>
              <Link
                to={"/recommendations/" + this.props.params.report}
                className="secondary-action"
              >
                {t("Create Now")}
              </Link>
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  update: store.filters.update,
  reset: store.filters.reset,
  status: store.campaigns.status,
  medium: store.campaigns.medium
});

export default connect(mapStatesToProps)(Report);
