import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { connect } from 'react-redux';
import getReCaptchaPublicKey from '../constants/reCaptcha.config';
import { setTitle } from '../system/document';
import LoginHeader from '../components/login/login.header';
import ResetPasswordForm from '../components/login/reset.password.form';
import { t } from '../system/ui';
import user from '../modules/auth/user';
import { getQueryString } from '../system/string';
import MailSendComponent from '../components/login/mailsent.component';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expiredToken: false,
    };
  }

  componentDidMount() {
    setTitle(t('Reset Password'));

    const queryStringToken = getQueryString('token');
    const queryStringV = getQueryString('v');

    if (queryStringToken)
      user.resetPasswordCheck(
        response => {
          if (response.status === 400)
            this.setState({
              expiredToken: true,
            });
        },
        queryStringToken,
        queryStringV,
      );
  }

  render() {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={getReCaptchaPublicKey()}
        scriptProps={{ async: true, defer: true, appendTo: 'head' }}
      >
        <div id='wrapper' className='page-wrap login-wrap'>
          <LoginHeader
            loginButton
            defaultLanguage={this.props.defaultLanguage}
          />
          {!this.state.expiredToken ? (
            <ResetPasswordForm />
          ) : (
            <MailSendComponent
              image='expired'
              color='red'
              title={t('Oops!')}
              text={t(
                'Reset password token is either expired or compromised, please try to forgot password from login screen!',
              )}
            />
          )}
        </div>
      </GoogleReCaptchaProvider>
    );
  }
}

const mapStateToProps = store => ({
  language: store.ui.language,
});

export default connect(mapStateToProps)(ResetPassword);
