import React, { Component } from 'react';
import Select from 'react-select';

import Icons from '../icons';
import { t } from '../../system/ui';
import { modalActions, uiActions } from '../../actions';
import {
  addAnalyticsUserFilter,
  updateAnalyticsUserFilter,
} from '../../modules/trendify/ajax';

class TfyFilterModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFilter: '',
      selectedAction: 'create',
      filterName: '',
    };

    this.setSelectedFilter = this.setSelectedFilter.bind(this);
    this.onFilterSelect = this.onFilterSelect.bind(this);
    this.onSelectAction = this.onSelectAction.bind(this);
    this.onFilterNameChange = this.onFilterNameChange.bind(this);
    this.saveFilter = this.saveFilter.bind(this);
  }

  componentDidMount() {
    this.setSelectedFilter();
  }

  setSelectedFilter() {
    this.setState({
      selectedFilter: this.props.selectedFilter,
    });
  }

  onSelectAction(action) {
    this.setState({
      selectedAction: action,
      filterName: '',
    });
  }

  onFilterSelect(selectedOption) {
    this.setState({
      selectedFilter: selectedOption.value,
    });
  }

  onFilterNameChange(e) {
    this.setState({
      filterName: e.target.value,
    });
  }

  fail = () => <p>{t('Please write a filter name!')}</p>;

  saveFilter(e) {
    e.preventDefault();
    let method;
    let data;
    let message;
    if (this.state.selectedAction === 'create') {
      if (this.state.filterName.length === 0) {
        uiActions.showErrorNotification(this.fail);
      } else {
        method = addAnalyticsUserFilter;
        data = {
          name: this.state.filterName.trim(),
          data: this.props.currentFilters,
        };
        message = `${data.name} filter created`;
      }
    } else {
      method = updateAnalyticsUserFilter;
      [data] = this.props.filters.filter(filter => {
        return filter.name === this.state.selectedFilter;
      });
      data.data = this.props.currentFilters;
      message = `${data.name} filter updated`;
    }
    if (method) {
      method(data, response => {
        if (response) {
          uiActions.showNotification({
            content: () => {
              return <div>{message}</div>;
            },
          });
          this.props.getFilters(data.name);
        }
        setTimeout(() => {
          modalActions.closeModal();
        }, 200);
      });
    }
  }

  render() {
    const buttonClass =
      this.state.selectedAction === 'create' && this.state.filterName.length < 1
        ? 'tertiary-action disabled'
        : 'tertiary-action';

    return (
      <div className='page-forms'>
        {this.props.filters.length > 0 && (
          <ul className='whitelist-radio' style={{ 'margin-bottom': '16px' }}>
            <li
              onClick={this.onSelectAction.bind(this, 'create')}
              style={{ color: '#000' }}
            >
              <Icons
                name={
                  this.state.selectedAction === 'create'
                    ? 'radioSelected'
                    : 'radioEmpty'
                }
                width={14}
                height={14}
              />
              <span>Create</span>
            </li>
            <li
              onClick={this.onSelectAction.bind(this, 'update')}
              style={{ color: '#000' }}
            >
              <Icons
                name={
                  this.state.selectedAction === 'update'
                    ? 'radioSelected'
                    : 'radioEmpty'
                }
                width={14}
                height={14}
              />
              <span>Update Existing</span>
            </li>
          </ul>
        )}
        {this.state.selectedAction === 'create' ? (
          <input
            type='text'
            value={this.state.filterName}
            onChange={this.onFilterNameChange}
            style={{
              width: '70%',
            }}
          />
        ) : (
          <label
            className='item item-field is-select'
            style={{
              width: '70%',
            }}
          >
            <Select
              searchable={false}
              clearable={false}
              value={this.state.selectedFilter}
              options={this.props.filters.map(filter => ({
                value: filter.name,
                label: filter.name,
              }))}
              name='filters'
              onChange={this.onFilterSelect}
              placeholder='Select Filter'
            />
          </label>
        )}
        <button
          type='button'
          className={buttonClass}
          onClick={this.saveFilter}
          style={{
            width: '25%',
            float: 'right',
          }}
        >
          {this.state.selectedAction === 'create' ? 'Create' : 'Update'}
        </button>
      </div>
    );
  }
}

export default TfyFilterModal;
