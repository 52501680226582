import React, { useEffect } from 'react';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import './style.scss';

import { useSelector } from 'react-redux';
import Insights from './Insights';
import EgsVideos from './EgsVideos';
import WelcomeBanner from './WelcomeBanner';

const index = ({ bannerItems }) => {
  const switchedUser = useSelector(state => state.switchedUser?.switchedUser);

  useEffect(() => {
    if (switchedUser?.account?.domain) {
      const isTrendifyActive =
        switchedUser?.account?.features?.trendify?.status !== 'PASSIVE';
      const routingAddress = isTrendifyActive
        ? '/trendify/insights'
        : '/trendify';

      browserHistory.push(routingAddress);
    }
  }, [switchedUser]);

  return (
    <div className='msp-welcome-container'>
      <WelcomeBanner items={bannerItems} />
      <Insights />
      <EgsVideos />
    </div>
  );
};

index.propTypes = {
  bannerItems: PropTypes.arrayOf(
    PropTypes.shape({
      imageURL: PropTypes.string.isRequired,
      linkURL: PropTypes.string,
    }),
  ),
};

export default index;
