import { createSlice } from '@reduxjs/toolkit';
import { criteriaTypes } from '../../constants/datamaps/dynamic-segmentation';
import {
  emptyCriteria,
  emptyCriteriaGroup,
  emptyCriteriaFilter,
  getDefaultStatements,
  getCriteriaSelectOptions,
} from '../rbs/customFunctions';
import {
  getCampaignReducer,
  getCampaignsReducer,
  getSingleCampaignsReducer,
} from './extraReducers';

const dsSlice = createSlice({
  name: 'ds',
  initialState: {
    wizardFilter: {
      loading: false,
      loaded: false,
      data: emptyCriteriaFilter,
      segmentName: '',
      description: '',
      campaignDetails: {},
    },
    criteriaCampaigns: {
      loading: false,
      loaded: false,
      data: [],
    },
    criteriaSingleCampaigns: {
      loading: false,
      loaded: false,
      data: [],
    },
    criteriaOptions: {
      loading: false,
      loaded: false,
      data: getCriteriaSelectOptions(criteriaTypes),
    },
  },
  reducers: {
    createEmptySegment: state => {
      state.wizardFilter.data = emptyCriteriaFilter;
      state.wizardFilter.segmentName = '';
      state.wizardFilter.description = '';
    },
    changeSegmentName: (state, action) => {
      state.wizardFilter.segmentName = action.payload;
    },
    setDescription: (state, action) => {
      state.wizardFilter.description = action.payload;
    },
    changeCriteriaType: (state, action) => {
      const { position, criteriaType } = action.payload;

      state.wizardFilter.data.criteriaGroups[position[0]].criterias[
        position[1]
      ].criteriaType = criteriaType;
      state.wizardFilter.data.criteriaGroups[position[0]].criterias[
        position[1]
      ].statements = getDefaultStatements(
        criteriaType,
        state.criteriaOptions.data,
      );
    },
    changeCriteriaStatement: (state, action) => {
      const { position, statements } = action.payload;

      state.wizardFilter.data.criteriaGroups[position[0]].criterias[
        position[1]
      ].statements = statements;
    },
    changeGroupOperator: (state, action) => {
      const { position, operator, type } = action.payload;

      if (type === 'group') {
        state.wizardFilter.data.operator = operator;
      } else {
        state.wizardFilter.data.criteriaGroups[position[0]].operator = operator;
      }
    },
    addNewCriteria: (state, action) => {
      const { position } = action.payload;

      state.wizardFilter.data.criteriaGroups[position[0]].criterias.push(
        emptyCriteria,
      );
    },
    removeCriteria: (state, action) => {
      const { position } = action.payload;

      state.wizardFilter.data.criteriaGroups[position[0]].criterias.splice(
        position[1],
        1,
      );
      if (
        state.wizardFilter.data.criteriaGroups[position[0]].criterias.length ===
        0
      ) {
        state.wizardFilter.data.criteriaGroups[position[0]].criterias.push(
          emptyCriteria,
        );
      }
    },
    addNewGroup: (state, action) => {
      const { position } = action.payload;
      const tempData = [...state.wizardFilter.data.criteriaGroups];
      tempData.splice(position[0] + 1, 0, emptyCriteriaGroup);
      state.wizardFilter.data.criteriaGroups = tempData;
    },
    removeGroup: (state, action) => {
      const { position } = action.payload;
      state.wizardFilter.data.criteriaGroups.splice(position[0], 1);
      if (state.wizardFilter.data.criteriaGroups.length === 0) {
        state.wizardFilter.data.criteriaGroups.push(emptyCriteriaGroup);
      }
    },
  },
  extraReducers: {
    ...getCampaignReducer,
    ...getCampaignsReducer,
    ...getSingleCampaignsReducer,
  },
});

export const {
  createEmptySegment,
  changeSegmentName,
  setDescription,
  changeCriteriaType,
  changeCriteriaStatement,
  changeGroupOperator,
  addNewCriteria,
  removeCriteria,
  addNewGroup,
  removeGroup,
} = dsSlice.actions;

export default dsSlice.reducer;
