import React, { Component } from "react";

class IconCheckboxPartial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="01---Accounts"
            transform="translate(-103.000000, -220.000000)"
            fillRule="nonzero"
          >
            <g id="Filter" transform="translate(91.000000, 151.000000)">
              <g transform="translate(0.000000, 57.000000)" id="Button">
                <g transform="translate(12.000000, 12.000000)">
                  <g id="Deselect" stroke="#D8D8D8">
                    <rect
                      id="Rectangle-3"
                      x="0.5"
                      y="0.5"
                      width="17"
                      height="17"
                      rx="3"
                    />
                  </g>
                  <rect
                    id="Rectangle-5"
                    stroke="#979797"
                    fill="#D8D8D8"
                    x="3.5"
                    y="8.5"
                    width="11"
                    height="1"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconCheckboxPartial;
