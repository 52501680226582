/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from 'react';
import { t } from '../../../system/ui';
import { breadCrumbOptions } from './constants/constants';
import CustomerProfileService from './services/CustomerProfileService';

import {
  SFYHeader,
  SFYList,
  SFYNoResult,
  SFYWrapper,
} from '../../../components/sfy-components/module-exports';
import { Context as PaginationContext } from '../../../components/pagination/store/paginationProvider';
import { Context as CustomerProfileContext } from './store/customerProfileProvider';
import { uiActions } from '../../../actions';
import SFYPagination from '../../../components/pagination/SFYPagination';
import { setTitle } from '../../../system/document';

const CustomerProfile = ({ location }) => {
  const {
    state: { pagination, searchQueryString },
    paginationSetter,
    searchQuerySetter,
  } = useContext(PaginationContext);

  const {
    state: { customerProfileList },
    setCustomerProfileList,
  } = useContext(CustomerProfileContext);

  const { isLoaded, isLoading } = uiActions;

  const listInvoker = () => {
    isLoading();
    CustomerProfileService.getAll({
      pageNumber: pagination.pageNumber,
      payloadSize: pagination.payloadSize,
      searchQueryString,
    }).then(({ userRecords, totalRecordSize, pageNo, pageSize }) => {
      setCustomerProfileList(userRecords || []);
      paginationSetter({
        totalRecords: totalRecordSize,
        pageNumber: pageNo,
        payloadSize: pageSize,
      });
      isLoaded();
    });
  };

  useEffect(() => {
    setTitle('Customer Profiles - Customer Data Management');
    listInvoker({
      pageNumber: pagination.pageNumber,
      payloadSize: pagination.payloadSize,
      searchQueryString,
    });
  }, [pagination.pageNumber, pagination.payloadSize, searchQueryString]);

  return (
    <>
      <SFYHeader
        pageTitle={t('Customer Profiles')}
        breadCrumbOptions={breadCrumbOptions}
        childrenAttr={{
          desc: t(
            'Customer profiles lists the unified data of your customers and allows you to take action on this data.',
          ),
        }}
      />
      <SFYWrapper>
        {customerProfileList && customerProfileList.length > 0 ? (
          <>
            <SFYPagination
              location={location}
              pagination={pagination}
              navigationController={false}
              stateSetter={searchQuerySetter}
              state={searchQueryString}
              paginationSetter={paginationSetter}
              analyticsType='customers'
            />
            <SFYList
              linkToDetail='/audience/customer-profile/customer-detail'
              listData={customerProfileList}
              totalRecords={pagination.totalRecords}
              listInvoker={listInvoker}
              showId
              customerProfileList
            />
          </>
        ) : searchQueryString !== '' && customerProfileList.length === 0 ? (
          <>
            <SFYPagination
              location={location}
              pagination={pagination}
              navigationController={false}
              stateSetter={searchQuerySetter}
              state={searchQueryString}
              paginationSetter={paginationSetter}
            />
            <SFYNoResult noListDescription={t('There is no customers yet.')} />
          </>
        ) : (
          <SFYNoResult noListDescription={t('There is no customers yet.')} />
        )}
      </SFYWrapper>
    </>
  );
};

export default CustomerProfile;
