import React from 'react';
import PropTypes from 'prop-types';
import {
  reformatDate,
  isValidStartDate,
  isValidCampaignEndDate,
} from '../../../../system/date';
import { dateFormat } from '../constants';
import { Calendar } from '../../../../components/fields';
import Icons from '../../../../components/icons';

const StartEndDate = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
}) => {
  return (
    <div className='form-element date-field'>
      <div className='element-row-field'>
        <div className='element-column-field'>
          <span className='element-column-label'>Start Date of Campaign*</span>
          <Calendar
            label=''
            value={reformatDate(startDate, dateFormat)}
            placeholder='dd/mm/yyyy'
            isValidDate={isValidStartDate}
            onChange={date => onChangeStartDate(date)}
            className='element-calendar'
            timeFormat={false}
            icon
          />
        </div>
        <div className='element-column-field'>
          <span className='element-column-label'>End Date of Campaign</span>
          <div className='element-row-field'>
            <Calendar
              label=''
              value={reformatDate(endDate, dateFormat)}
              placeholder='dd/mm/yyyy'
              isValidDate={current => {
                return isValidCampaignEndDate(current, startDate);
              }}
              onChange={date => onChangeEndDate(date)}
              className={`element-calendar ${endDate ? 'has-clear' : ''}`}
              timeFormat={false}
              icon
            />
            {endDate && (
              <div
                onClick={() => {
                  onChangeEndDate('');
                }}
                className='clear-action'
              >
                <Icons name='cross' />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

StartEndDate.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
};

export default StartEndDate;
