/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconPush extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={this.state.width}
        height={this.state.height}
      >
        <g>
          <circle
            stroke={this.state.color}
            className="svg-stroke"
            style={{ fill: "none" }}
            cx="397"
            cy="115"
            r="94"
          />
          <polyline
            stroke={this.state.color}
            className="svg-stroke"
            style={{ fill: "none" }}
            points="209,115 21,115 21,491 397,491 397,303"
          />
        </g>
      </svg>
    );
  }
}

export default IconPush;
