import React from 'react';
import { connect } from 'react-redux';

import { t } from '../system/ui';
import { setTitle } from '../system/document';
import { segmentActions } from '../actions';
import SegmentWidgetCard from '../components/widget/segment-card';
import SegmentStatusFilters from '../components/filters/segment-status-filters';
import { segmentAlgorithmTitles } from '../constants/datamaps/wizard';

class AllSegments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      scrollCount: 0,
      segments: [],
      filteredSegments: [],
      showOptions: false,
      toggleIcon: 'preferences',
    };

    this.toggleOptions = this.toggleOptions.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.increaseScrollCount = this.increaseScrollCount.bind(this);
  }

  componentDidMount() {
    setTitle(t('All Segments'));
    this.changeStatus('ACTIVE', this.props.segments);
    segmentActions.filterSegments('ACTIVE');

    window.onscroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        this.increaseScrollCount();
      }
    };
  }

  componentWillReceiveProps(newProps) {
    if (this.props.user !== newProps.user) {
      this.changeStatus(this.props.status, newProps.segments);
    }

    if (this.props.segments !== newProps.segments) {
      this.changeStatus(this.props.status, newProps.segments);
    }

    if (newProps.status !== this.props.status) {
      this.changeStatus(newProps.status, newProps.segments);
    }
  }

  increaseScrollCount() {
    this.setState({
      scrollCount: this.state.scrollCount + 1,
    });
  }

  changeStatus(value, segments) {
    const list = segments.filter(s => {
      return s.segment.status === value && s.segment.type === 'SMART';
    });
    this.setState({
      count: list.length,
      filteredSegments: list,
    });
  }

  toggleOptions(e) {
    e.preventDefault();

    this.setState({
      showOptions: !this.state.showOptions,
      toggleIcon: !this.state.showOptions ? 'cross' : 'preferences',
    });
  }

  render() {
    const segmentStatus = this.props.status;
    let statusText = '';
    if (segmentStatus === 'ACTIVE') {
      statusText = t('Live');
    } else if (segmentStatus === 'DRAFT') {
      statusText = t('Test');
    } else {
      statusText = t('Archived');
    }

    return (
      <div>
        <h2 className='page-title' style={{ marginBottom: '5px' }}>
          {t('All Segments')}
          <span className='emphasise'> ({this.state.count})</span>
        </h2>
        <div className='page-options page-options-sort-search' />

        <div className='page-filters has-filter-form'>
          <SegmentStatusFilters />
        </div>
        <br />
        <br />
        <div className='page-content-wrapper'>
          {this.state.filteredSegments.length
            ? this.state.filteredSegments.map(s => {
                const segmentName =
                  s.segment.name === 'Sleeping Opportunists'
                    ? 'Discount Seekers'
                    : s.segment.name;
                return (
                  <SegmentWidgetCard
                    stat={this.props.stats[s.segment.name]}
                    segment={s.segment}
                    key={s.segment.id}
                    scenarioName={
                      segmentAlgorithmTitles(segmentName).segmentTitle
                    }
                    id='sgVw'
                  />
                );
              })
            : this.props.ui !== 'isLoading' && (
                <div className='no-report'>
                  <p className='no-report-info'>
                    {t('no-segment-info', statusText)}
                  </p>
                </div>
              )}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  ui: store.ui.ui,
  status: store.segment.status,
});

export default connect(mapStatesToProps)(AllSegments);
