/**
 * @author Barış Altun
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { uiActions } from "../../../actions";

import { TextArea, TextField } from "../../../components/fields";
import { t } from "../../../system/ui";

class RedirectURL extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      name: "redirectUrl",
      passedValue: ""
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    let url = this.props.campaign.redirectUrl || "";
    if (url !== "") {
      this.updateValue(url);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.redirectUrl);
    }
  }

  updateValue(newValue) {
    this.setState(
      {
        passedValue: newValue
      },
      () => {
        if (
          newValue.indexOf("https://") < 0 &&
          newValue.indexOf("http://") < 0 &&
          newValue !== ""
        ) {
          newValue = "https://" + newValue;
        } else if (newValue.indexOf("http://") >= 0) {
          newValue = newValue.replace("http://", "https://");
        }
        this.setState({
          value: newValue
        });
      }
    );
  }

  onFieldChange(e) {
    let tempRedirectUrls = this.props.additionalRedirectUrls;
    tempRedirectUrls[this.props.selectedLanguage] = e.target.value;
    this.setState({
      additionalRedirectUrls: tempRedirectUrls
    });
    this.updateValue(e.target.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <li>
        <TextField
          name="redirectUrl"
          label="Target URL"
          className="item-stacked one-whole"
          required={true}
          onChange={this.onFieldChange}
          value={this.props.additionalRedirectUrls[this.props.selectedLanguage] || ""}
          validation={{ URL: true }}
          tooltip={true}
          tooltipText={t(
            "Enter the URL of the page that your visitors will be directed when they click on the web push notification."
          )}
          placeholder={this.props.options.placeholder}
          disabled={this.props.disableFields}
        />
      </li>
    );
  }
}

const MapStatesToProps = store => ({
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  RedirectURL
);
