import React, { useCallback, useState } from 'react';
import Icons from '../../../icons';
import { onProductsInputChange } from './common/common-functions';

const RPinProduct = ({ mappedProducts, setAttributes, onRemove }) => {
  const [productOptions, setProductOptions] = useState([]);

  const onInputChange = useCallback(
    value => onProductsInputChange(value, setProductOptions),
    [setProductOptions],
  );

  return (
    <div className='sfy-attribute-field sfy-related-products'>
      <div className='sfy-attribute-field-icon'>
        <i className='icon-product noV-align' color='#7A7A7C' />
      </div>
      <div className='sfy-attribute-field-select'></div>
      <div
        className='sfy-attribute-field-remove'
        onClick={() => onRemove('mappedProducts')}
      >
        <Icons name='crossCircle' color='#D31116' height='18' width='18' />
      </div>
    </div>
  );
};
export default RPinProduct;
