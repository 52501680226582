import React from 'react';
import { connect } from 'react-redux';

import { uiActions } from '../../actions';
import { getUser } from '../../modules/auth/user';
import { cappingMailGroups } from '../../modules/capping/settings';
import { t } from '../../system/ui';
import { Select } from '../fields';

class Settings_Capping extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      capping: {
        capCount: '',
        frequency: '',
        types: [],
      },
      error: null,
      hasCappingError: false,
    };

    this.onCappingParamChange = this.onCappingParamChange.bind(this);
    this.onTypesChange = this.onTypesChange.bind(this);
    this.validateCapping = this.validateCapping.bind(this);
    this.onFrequencyChange = this.onFrequencyChange.bind(this);
  }

  componentDidMount() {
    uiActions.isLoaded();
    getUser(response => {
      this.updateCapping(response.account);
      this.props.updateData(response.account.mailConfiguration.capping);
    });
  }

  updateCapping(account) {
    let capping =
      account.mailConfiguration && account.mailConfiguration.capping
        ? account.mailConfiguration.capping
        : this.state.capping;
    if (Object.keys(capping).length < 1) {
      capping = this.state.capping;
    }
    this.setState({
      capping,
    });
  }

  onFrequencyChange(selectedOption) {
    if (!this.props.isSuperUser) return;

    const { capping } = this.state;
    capping.frequency = selectedOption ? selectedOption.value : '';
    this.setState(
      {
        capping,
      },
      () => {
        this.validateCapping();
        this.props.updateData(this.state.capping);
      },
    );
  }

  onCappingParamChange(param, e) {
    if (!this.props.isSuperUser) return;

    const { capping } = this.state;
    let newValue = e.target.value;
    if (parseInt(newValue) < 1) {
      newValue = 1;
    }
    capping[param] = newValue;
    this.setState(
      {
        capping,
      },
      () => {
        this.validateCapping();
        this.props.updateData(this.state.capping);
      },
    );
  }

  onTypesChange(selectedOption) {
    if (!this.props.isSuperUser) return;

    const { capping } = this.state;
    if (selectedOption.length) {
      capping.types = selectedOption.map(option => {
        return option.value;
      });
    } else {
      capping.types = [];
    }
    this.setState(
      {
        capping,
      },
      () => {
        this.validateCapping();
        this.props.updateData(this.state.capping);
      },
    );
  }

  validateCapping() {
    let emptyValueCount = 0;
    Object.keys(this.state.capping).forEach(cappingKey => {
      if (cappingKey !== 'types') {
        if (!this.state.capping[cappingKey]) {
          emptyValueCount++;
        }
      } else if (this.state.capping[cappingKey].length === 0) {
        emptyValueCount++;
      }
    });
    this.setState({
      hasCappingError: emptyValueCount < 3 && emptyValueCount > 0,
    });
  }

  render() {
    const readOnly = !this.props.isSuperUser;
    const frequency = [
      { value: 'DAILY', label: 'Daily' },
      { value: 'WEEKLY', label: 'Weekly' },
      { value: 'MONTHLY', label: 'Monthly' },
    ];

    return (
      <div className='page-content-block one-whole email-settings-form'>
        <div className='email-settings-capping'>
          <h3
            className='modal-content-title'
            style={{ color: '#6968b2', marginBottom: '5px' }}
          >
            {t('Global Capping Count')}
          </h3>
          <p className='widget-description'>
            {t('Complete the two simple steps to finish the setup')}
          </p>
          {this.state.hasCappingError && (
            <span
              className='item-error has-error'
              style={{ display: 'block', marginTop: '10px' }}
            >
              {'* '}You should fill all fields
            </span>
          )}
          <p>
            Frequency Cap is
            <input
              type='number'
              name='capCount'
              min={1}
              value={this.state.capping.capCount}
              className='capping-capCount'
              onChange={this.onCappingParamChange.bind(null, 'capCount')}
              readOnly={readOnly}
            />
            per user and email send
            <Select
              name='frequency'
              className='item-stacked eleven-columns capping-frequency'
              value={this.state.capping.frequency}
              options={frequency}
              onChange={this.onFrequencyChange}
              clearable
              disabled={readOnly}
            />
            for
            <Select
              name='types'
              className='item-stacked eleven-columns capping-types'
              value={this.state.capping.types}
              options={cappingMailGroups}
              onChange={this.onTypesChange}
              multi
              clearable
              disabled={readOnly}
            />
            campaigns
          </p>
        </div>
        <li
          className='buttons'
          style={{ listStyle: 'none', background: 'white' }}
        >
          <button
            onClick={this.props.updateSettings}
            className='tertiary-action'
            style={{ position: 'relative', right: '30px' }}
            disabled={readOnly}
          >
            Update and Send Test
          </button>
        </li>
      </div>
    );
  }
}
const mapStatesToProps = store => ({
  user: store.user.user,
  validate: store.ui.validate,
  language: store.ui.language,
});
export default connect(mapStatesToProps)(Settings_Capping);
