"use strict";

import React from 'react';
import ReactHighcharts from 'react-highcharts';
import { connect } from 'react-redux';
import { t } from '../system/ui';
import { calculateRatio } from '../system/ratio';
import { localeString } from '../system/string';
import { isSwitchAccount } from '../modules/auth/user';

const getSingleStat = (obj, val) => {
  return obj[val] ? obj[val].z : 0;
};

const transformStatsByDate = data => {
  if (data) {
    let statsByDate = {};
    data.forEach(stat => {
      statsByDate[stat.y] = {};
    });
    Object.keys(statsByDate).forEach(timestamp => {
      data.forEach(stat => {
        if (stat.y.toString() === timestamp.toString()) {
          statsByDate[timestamp][stat.x] = stat;
        }
      });
    });
    return statsByDate;
  }
};

class KeyMetricsGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGraph: 'conversion',
      transformedData: [],
    };

    this.setData = this.setData.bind(this);
    this.changeGraphType = this.changeGraphType.bind(this);
    this.getGraphConfig = this.getGraphConfig.bind(this);
    this.getDefaultGraphConfig = this.getDefaultGraphConfig.bind(this);
  }

  componentDidMount() {
    this.setData(this.props.data);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.data !== this.props.data) {
      this.setData(newProps.data);
    }
  }

  setData(transformedData) {
    this.setState({
      transformedData,
    });
  }

  changeGraphType(selectedGraph, e) {
    e.preventDefault();
    this.setState({
      selectedGraph,
    });
  }

  getDefaultGraphConfig(
    firstSeriesData,
    secondSeriesData,
    graphType,
    seriesNameTextClicked,
    seriesNameTextNonClicked,
  ) {
    const symbolBefore = isSwitchAccount()
      ? this.props.switchedUser.account.mainCurrency.symbolBefore
      : this.props.user.account.mainCurrency.symbolBefore;
    const symbol = isSwitchAccount()
      ? this.props.switchedUser.account.mainCurrency.symbol
      : this.props.user.account.mainCurrency.symbol;
    let config;
    config = {
      title: {
        text: '',
        margin: 0,
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 34560000,
        labels: {
          align: 'left',
        },
      },
      yAxis: {
        title: {
          text: graphType === 'conversion' ? t('Ratio') : t('Value'),
        },
        min: 0,
        showFirstLabel: false,
        allowDecimals: false,
        labels: {
          formatter: function() {
            if (graphType === 'conversion') {
              return `${localeString(this.value)} %`;
            } else {
              if (symbolBefore) {
                return `${symbol} ${localeString(this.value)}`;
              } else {
                return `${localeString(this.value)} ${symbol}`;
              }
            }
          },
        },
      },
      options: {
        chart: {
          type: 'column',
          showAxes: true,
          zoomType: 'x',
          marginLeft: 75,
          spacingTop: 30,
          spacingBottom: 30,
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          borderRadius: 10,
          borderWidth: 3,
          backgroundColor: 'rgba(225, 225, 255, 0.9)',
        },
        exporting: {
          buttons: {
            contextButton: {
              y: -30,
              x: 0,
            },
          },
          chartOptions: {
            title: {
              text: 'Campaign Revenue Trends',
            },
          },
        },
      },
      loading: true,
      series: [
        {
          id: 'segmentify',
          name: seriesNameTextClicked,
          data: firstSeriesData,
          fillOpacity: 0.3,
          marker: {
            enabled: true,
            fillColor: '#FFFFFF',
            lineColor: null,
            lineWidth: 2,
          },
          type: 'line',
          yAxis: 0,
          zIndex: 2,
        },
        {
          id: 'notSegmentify',
          name: seriesNameTextNonClicked,
          data: secondSeriesData,
          fillOpacity: 0.3,
          marker: {
            enabled: true,
            fillColor: '#FFFFFF',
            lineColor: '#a6ea8a',
            lineWidth: 2,
          },
          type: 'line',
          yAxis: 0,
          zIndex: 2,
          color: '#a6ea8a',
        },
      ],
    };

    config.legend = {
      useHTML: true,
      labelFormatter: function() {
        return (
          '<div>' +
          '<div class="widget-stats"> ' +
          '<div class="widget-stat stat-widget">' +
          '<h4 class="widget-stat-title" style="color:' +
          this.color +
          ';text-align:center;">' +
          t(this.name) +
          '</h4>' +
          '</div></div></div>'
        );
      },
    };

    config.series.forEach(serie => {
      serie.data.sort(function(a, b) {
        let keyA = new Date(a.x),
          keyB = new Date(b.x);
        // Compare the 2 dates
        if (keyA < keyB) {
          return -1;
        }
        if (keyA > keyB) {
          return 1;
        }
        return 0;
      });
    });

    config.xAxis.tickInterval = "";
    let timeFrame = {};
    if (this.props.customFrame) {
      timeFrame.mode = this.props.customFrame;
    } else {
      timeFrame.mode = 'monthly';
      if (this.props.range[1] && this.props.range[0]) {
        let timeDiff = this.props.range[1] - this.props.range[0];
        if (timeDiff < 24 * 60 * 60 * 1000) {
          timeFrame.mode = 'no_aggregate';
        } else if (timeDiff < 90 * 24 * 60 * 60 * 1000) {
          timeFrame.mode = 'daily';
        }
      }
    }
    switch (timeFrame.mode) {
      case 'monthly':
        config.xAxis.minTickInterval = 28 * 24 * 3600 * 1000; // 1 month
        config.xAxis.tickInterval = 30 * 24 * 3600 * 1000; // 1 month
        break;
      case 'daily':
        config.xAxis.tickInterval = 3 * 24 * 3600 * 1000; // three days
        break;
      case 'hourly':
        config.xAxis.tickInterval = 6 * 3600 * 1000; // 6 hours
        break;
      case 'no_aggregate':
        config.xAxis.tickInterval = 3600 * 1000; // one hour
        break;
      default:
        config.xAxis.tickInterval = 3 * 24 * 3600 * 1000;
    }
    config.credits = { enabled: false };

    if (graphType === 'conversion') {
      config.tooltip.valueSuffix = '%';
    } else {
      if (symbolBefore) {
        config.tooltip.valuePrefix = symbol;
      } else {
        config.tooltip.valueSuffix = symbol;
      }
    }
    return config;
  }

  getGraphConfig(data, graphType, seriesNameTextClicked, seriesNameTextNonClicked) {
    let transformedData = transformStatsByDate(data);
    let firstSeriesData = [];
    let secondSeriesData = [];
    Object.keys(transformedData).forEach(timestamp => {
      let clicked, notClicked;
      let sgfPurchaseCount = getSingleStat(
        transformedData[timestamp],
        'sgf:purchase:count',
      );
      let sgfVisits = getSingleStat(transformedData[timestamp], 'sgf:visits');
      let allPurchaseCount = getSingleStat(
        transformedData[timestamp],
        'all:purchase:count',
      );
      let allVisits = getSingleStat(transformedData[timestamp], 'all:visits');

      let sgfPurchaseAmount = getSingleStat(
        transformedData[timestamp],
        'sgf:purchase:amount',
      );
      let allPurchaseAmount = getSingleStat(
        transformedData[timestamp],
        'all:purchase:amount',
      );

      if (graphType === 'conversion') {
        clicked = calculateRatio(sgfPurchaseCount, sgfVisits);
        notClicked = calculateRatio(
          allPurchaseCount - sgfPurchaseCount,
          allVisits - sgfVisits,
        );
      }
      if (graphType === 'average_visit') {
        clicked = (sgfPurchaseAmount / sgfVisits).toFixed(2);
        notClicked = (
          (allPurchaseAmount - sgfPurchaseAmount) /
          (allVisits - sgfVisits)
        ).toFixed(2);
      }
      if (graphType === 'average_order') {
        clicked = (sgfPurchaseAmount / sgfPurchaseCount).toFixed(2);
        notClicked = (
          (allPurchaseAmount - sgfPurchaseAmount) /
          (allPurchaseCount - sgfPurchaseCount)
        ).toFixed(2);
      }
      firstSeriesData.push({
        x: parseInt(timestamp),
        y: parseFloat(clicked),
      });
      secondSeriesData.push({
        x: parseInt(timestamp),
        y: parseFloat(notClicked),
      });
    });
    return this.getDefaultGraphConfig(
      firstSeriesData.map(data => {
        return isFinite(data.y) && !isNaN(data.y) ? data : { x: data.x, y: 0 };
      }),
      secondSeriesData.map(data => {
        return isFinite(data.y) && !isNaN(data.y) ? data : { x: data.x, y: 0 };
      }),
      graphType,
      seriesNameTextClicked,
      seriesNameTextNonClicked,
    );
  }

  render() {
    const graphs = [
      {
        value: 'conversion',
        label: 'Conversion',
      },
      {
        value: 'average_visit',
        label: 'Average Visit Value',
      },
      {
        value: 'average_order',
        label: 'Average Order Value',
      }
    ];

    const counts = {
      'sgf:visits': 0,
      'all:visits': 0,
    };
    this.props.textData.forEach(data => {
      if (Object.keys(counts).indexOf(data.x) > -1) {
        counts[data.x] = data.y;
      }
    });
    const clickedGroupCount = counts['sgf:visits'];
    const nonClickedGroupCount = counts['all:visits'] - counts['sgf:visits'];
    const clickedGroupRatio = calculateRatio(
      counts['sgf:visits'],
      counts['all:visits'],
    );
    const nonClickedGroupRatio = calculateRatio(
      counts['all:visits'] - counts['sgf:visits'],
      counts['all:visits'],
    );
    const seriesNameTextClicked = `Clicked Segmentify Recommendations - %${localeString(
      clickedGroupRatio,
    )} (${localeString(clickedGroupCount)} users)`;
    const seriesNameTextNonClicked = `Did not click Segmentify Recommendations - %${localeString(
      nonClickedGroupRatio,
    )} (${localeString(nonClickedGroupCount)} users)`;

    return (
      <div>
        <div className='metric-graph-header'>
          {graphs.map(graph => {
            return (
              <div
                key={graph.value}
                onClick={this.changeGraphType.bind(this, graph.value)}
                className={
                  this.state.selectedGraph === graph.value ? 'active-graph' : ''
                }
              >
                {graph.label}
              </div>
            );
          })}
        </div>
        <div className='metric-graph-graph'>
          <ReactHighcharts
            ref='chart'
            config={this.getGraphConfig(
              this.state.transformedData,
              this.state.selectedGraph,
              seriesNameTextClicked,
              seriesNameTextNonClicked,
            )}
          />
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(KeyMetricsGraph);
