import React from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import classNames from 'classnames';
import { goalOptions } from './ExperimentationDefinitionPanel';
import ExperimentationVariations from './ExperimentationVariations';
import { clone } from '../../../system/object';
import { facetedSearchRequest } from '../../../actions/faceted-search';
import './ExperimentationSummary.scss';
import Icons from '../../icons';
import Icon from '../../icon';
import { uiActions } from '../../../actions';
import { getModuleByPath } from '../list/ExperimentationList';
import { t } from '../../../system/ui';

class ExperimentationSummary extends React.Component {
  constructor(props) {
    super(props);
  }

  customInputIcon() {
    return <Icon name='calendar' />;
  }

  onSubmit = event => {
    event.preventDefault();
    const { params, values, mode } = this.props;
    const notificationContent = () => (
      <div>
        {mode === 'confirmation'
          ? `${t(
              `You have successfully created ${values.testName} experiment.`,
            )}`
          : `${t(
              `You have successfully finished ${values.testName} experiment.`,
            )} `}
      </div>
    );
    if (mode === 'confirmation') {
      const requestBody = clone(values);
      for (const variation of requestBody.variations) {
        delete variation.color;
      }
      requestBody.module = getModuleByPath(params.module);
      requestBody.status = 'ACTIVE';
      facetedSearchRequest('experimentation/create')
        .post(requestBody, { type: 'json' })
        .then(() => {
          this.onSuccess();
          uiActions.showNotification({ content: notificationContent });
        })
        .catch(this.onError);
    } else {
      uiActions.confirmationDialog({
        title: 'Attention!',
        content: 'Are you sure you want to finish the experiment?',
        onConfirm: () => {
          facetedSearchRequest('experimentation/update', {
            instanceId: values.id,
          })
            .put(null, { type: 'json' })
            .then(() => {
              this.onSuccess();
              uiActions.showNotification({ content: notificationContent });
            })
            .catch(this.onError);
        },
      });
    }
  };

  onSuccess = () => {
    const { onFinishExperimentSuccess } = this.props;
    onFinishExperimentSuccess();
  };

  onError() {
    const notificationContent = () => <span>Error occurred while </span>;
    uiActions.showErrorNotification(notificationContent);
  }

  getModule = () => {
    const { mode, params, module } = this.props;
    if (mode === 'confirmation') return getModuleByPath(params.module);
    return module;
  };

  render() {
    const { values, onClose, mode } = this.props;
    const isPreview = mode === 'preview';
    const isCompleted = values?.status === 'COMPLETED';
    const submitText = isPreview ? 'Finish Test' : 'Confirm and Start';
    return (
      <div className='dialog'>
        <div className='dialog-cover' />
        <div
          className='dialog-content experimentation-summary'
          style={{ maxWidth: 'unset', minWidth: '1081px' }}
        >
          <form onSubmit={this.onSubmit} className='experimentation-definition'>
            <div className='experimentation-summary-testName'>
              <label htmlFor='testName'>Test Name</label>
              <input
                type='text'
                name='testName'
                value={values.testName}
                disabled
                className='experimentation-input'
                id='experimentation-test-name-input'
              />
            </div>
            <div className='experimentation-summary-primaryGoal'>
              <label htmlFor='primaryGoal'>Primary Goal</label>
              <Select
                placeholder='Select Goal'
                options={goalOptions}
                disabled
                id='primaryGoal'
                isSearchable={false}
                isClearable={false}
                value={values.primaryGoal}
              />
            </div>
            <div className='experimentation-summary-variations'>
              <label htmlFor='variations'>Variations</label>
              <ExperimentationVariations
                experimentId={values.id}
                variations={values.variations}
                mode={mode}
                module={this.getModule()}
                isCompleted={isCompleted}
              />
            </div>
            <div className='experimentation-summary-endDate'>
              <label>End Date</label>
              <SingleDatePicker
                disabled
                date={moment(values.endDate)}
                displayFormat='DD/MM/YYYY'
                numberOfMonths={1}
                hideKeyboardShortcutsPanel
                id='experiment-end-date-view'
                customInputIcon={this.customInputIcon()}
              />
            </div>
            {isPreview && (
              <div
                className='experimentation-summary-closeBtn'
                onClick={onClose}
              >
                &#x2715;
              </div>
            )}
            <div
              className={classNames(
                `experimentation-button-container ${!isPreview && 'summary'}`,
                { completed: isCompleted },
              )}
            >
              {!isPreview && (
                <span
                  className='experimentation-back-button'
                  style={{
                    display: 'inline-flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                  }}
                  onClick={onClose}
                >
                  <Icons name='backarrow' />
                  <span style={{ marginLeft: '5px' }}>Back</span>
                </span>
              )}

              {!isCompleted && (
                <div
                  className={`experimentation-summary-finishBtn ${
                    isPreview === 'preview' ? 'preview' : ''
                  }`}
                >
                  <button
                    className={classNames('experimentation-submit-button', {
                      finish: isPreview,
                    })}
                    type='submit'
                  >
                    {submitText}
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(ExperimentationSummary);
