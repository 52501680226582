/**
 * Created by mehmetyurtar on 20/07/2017.
 */
import React from "react";

class IconLock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "20px",
      height: "20px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <path
          fill={this.state.color}
          d="M24,3c4.962,0,9,4.038,9,9c0,0.552,0.448,1,1,1s1-0.448,1-1c0-6.065-4.935-11-11-11S13,5.935,13,12v5H8
	c-0.552,0-1,0.448-1,1v28c0,0.552,0.448,1,1,1h32c0.552,0,1-0.448,1-1V18c0-0.552-0.448-1-1-1H15v-5C15,7.038,19.038,3,24,3z M39,45
	H9V19h30V45z"
        />
        <path
          fill={this.state.color}
          d="M23,34.899V38c0,0.552,0.448,1,1,1s1-0.448,1-1v-3.101c2.279-0.465,4-2.484,4-4.899c0-2.757-2.243-5-5-5s-5,2.243-5,5
	C19,32.414,20.721,34.434,23,34.899z M24,27c1.654,0,3,1.346,3,3s-1.346,3-3,3s-3-1.346-3-3S22.346,27,24,27z"
        />
      </svg>
    );
  }
}

export default IconLock;
