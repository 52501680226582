import React from 'react';
import './style.scss';

import Accounts from './Accounts';
import QuickActions from './QuickActions';

const index = () => {
  return (
    <div className='msp-accounts-container'>
      <Accounts />
      <QuickActions />
    </div>
  );
};

export default index;
