/**
 * @author Bilal Cinarli
 */

import api from '../../system/api';
import { $http, plainGet } from '../../system/ajax';
import { getStartEndDate } from '../../system/date';
import { filterStatus, filterPage } from './filters';
import { getApiKey, isMultiAccountUser } from '../auth/user';

const getCampaign = (campaign_id, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  plainGet(
    (api.getEndPoint('campaign/single') + globalApiKey).replace(
      '{CAMPAING_ID}',
      campaign_id,
    ),
    callback,
  );
};

const getCampaigns = (callback, data, options, accountApiKey = '') => {
  let globalApiKey = '';
  if (accountApiKey !== '') {
    globalApiKey = `?apiKey=${accountApiKey}`;
  } else {
    globalApiKey = `?apiKey=${getApiKey()}`;
  }
  let newData = {};
  if (!data) {
    newData = {};
  } else {
    const boundaries = getStartEndDate('weekly');
    newData = { ...data, ...boundaries };
  }
  plainGet(
    api.getEndPoint('campaign/all') + globalApiKey,
    callback,
    newData,
    options,
  );
};

const getAllCampaigns = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  plainGet(api.getEndPoint('campaign/allModules') + globalApiKey, callback);
};

const getCampaignsByModuleType = (data, callback) => {
  const endPoint = `${api.getEndPoint(
    'campaign/getWithModules',
  )}?apiKey=${getApiKey()}`;

  $http(endPoint)
    .post(data, { type: 'json' })
    .then(response => {
      callback(response);
    })
    .catch(response => {
      callback(response);
    });
};

const getCampaignsByStatus = (status, callback) => {
  return getCampaigns(response => {
    return callback(filterStatus(response, status));
  });
};

const getActiveCampaigns = callback => {
  return getCampaigns(response => {
    return callback(filterStatus(response, 'ACTIVE'));
  });
};

const getAllActiveCampaigns = callback => {
  return getAllCampaigns(response => {
    const allCampaigns = Object.keys(response).reduce(
      (acc, module) => [
        ...acc,
        ...response[module].map(cmp => ({
          ...cmp,
          moduleType: module,
        })),
      ],
      [],
    );
    return callback(
      allCampaigns.filter(
        cmp => cmp.status === 'ACTIVE' || cmp.status === 'LIVE',
      ),
    );
  });
};

const getTestCampaigns = callback => {
  return getCampaigns(response => {
    return callback(filterStatus(response, 'TEST'));
  });
};

const getActiveCampaignCount = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  plainGet(api.getEndPoint('campaign/active') + globalApiKey, callback);
};

const getActiveCampaignsByPage = (page, callback) => {
  return getCampaigns(response => {
    return callback(filterPage(filterStatus(response, 'ACTIVE'), page));
  });
};

const getPassiveCampaignsByPage = (page, callback) => {
  return getCampaigns(response => {
    return callback(filterPage(filterStatus(response, 'TEST'), page));
  });
};

const addCampaign = (
  instance,
  callback,
  optionalApikey,
  optionalDatacenter,
  isMultiAccountUser,
) => {
  if (isMultiAccountUser) {
    return duplicateCampaign(
      instance,
      callback,
      optionalApikey,
      optionalDatacenter,
    );
  }
  const apiKey = optionalApikey || getApiKey();
  const dataCenter = optionalDatacenter || false;
  const endPoint = `${api.getRestEndPoint(
    'campaign/add',
    dataCenter,
  )}?apiKey=${apiKey}`;

  $http(endPoint)
    .post(instance, { type: 'json' })
    .then(response => {
      callback(response);
    })
    .catch(response => {
      callback(response);
    });
};

const addCampaignV2 = (
  instance,
  callback,
  optionalApikey,
  optionalDatacenter,
  isMultiAccountUser,
) => {
  if (isMultiAccountUser) {
    return duplicateCampaign(
      instance,
      callback,
      optionalApikey,
      optionalDatacenter,
    );
  }
  const apiKey = optionalApikey || getApiKey();
  const dataCenter = optionalDatacenter || false;
  const endPoint = `${api.getRestEndPoint(
    'campaign/addV2',
    dataCenter,
  )}?apiKey=${apiKey}`;

  $http(endPoint)
    .post(instance, { type: 'json' })
    .then(response => {
      callback(response);
    })
    .catch(response => {
      callback(response);
    });
};

const duplicateCampaign = (
  instance,
  callback,
  optionalApikey,
  optionalDatacenter,
) => {
  const apiKey = optionalApikey || getApiKey();
  const dataCenter = optionalDatacenter || false;
  const endPoint = `${api.getRestEndPoint(
    'campaign/duplicate',
    dataCenter,
  )}?apiKey=${apiKey}`;

  $http(endPoint)
    .post(instance, { type: 'json' })
    .then(response => {
      callback(response);
    })
    .catch(response => {
      callback(response);
    });
};

const updateCampaign = (instance, callback) => {
  const endPoint = `${api.getRestEndPoint(
    'campaign/update',
  )}?apiKey=${getApiKey()}`;
  $http(endPoint)
    .post(instance, { type: 'json' })
    .then(response => {
      callback(response);
    })
    .catch(response => {
      callback(response);
    });
};

const updateCampaignV2 = (instance, callback) => {
  const endPoint = `${api.getRestEndPoint(
    'campaign/updateV2',
  )}?apiKey=${getApiKey()}`;
  $http(endPoint)
    .post(instance, { type: 'json' })
    .then(response => {
      callback(response);
    })
    .catch(response => {
      callback(response);
    });
};

const goLiveCampaign = (instance, callback) => {
  plainGet(
    `${api.getRestEndPoint('campaign/live') + instance}?apiKey=${getApiKey()}`,
    callback,
  );
};

const goTestCampaign = (instance, callback) => {
  plainGet(
    `${api.getRestEndPoint('campaign/test') + instance}?apiKey=${getApiKey()}`,
    callback,
  );
};

const activateCampaign = (instance, callback) => {
  plainGet(
    `${api.getRestEndPoint('campaign/activate') +
      instance}?apiKey=${getApiKey()}`,
    callback,
  );
};

const deactivateCampaign = (instance, callback) => {
  plainGet(
    `${api.getRestEndPoint('campaign/deactivate') +
      instance}?apiKey=${getApiKey()}`,
    callback,
  );
};

export {
  addCampaign,
  addCampaignV2,
  updateCampaign,
  updateCampaignV2,
  getCampaign,
  getCampaigns,
  getActiveCampaigns,
  getActiveCampaignCount,
  getActiveCampaignsByPage,
  getPassiveCampaignsByPage,
  getTestCampaigns,
  getCampaignsByStatus,
  goLiveCampaign,
  goTestCampaign,
  activateCampaign,
  deactivateCampaign,
  getCampaignsByModuleType,
  getAllActiveCampaigns,
};
