import React, { useEffect, useState } from 'react';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import * as Components from '../../../../components/sfy-components/module-exports';
import {
  getAuthorizedMailUsers,
  getCampaingCounts,
  getSegmentStats,
  getUserSegments,
} from '../../../../modules/account/ajax';
import { t } from '../../../../system/ui';
import AdvancedTargeting from './AdvancedTargeting';
import styles from './TargetAudience.module.scss';

const CampaignName = ({
  component,
  editCampaign,
  activeEmailDetails,
  emailType,
  readOnly,
}) => {
  const [options, setOptions] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0); // activeEmailDetails.filters[0]?.segment ? segmentUser : totalReachableUser
  const [segmentUser, setSegmentUser] = useState(null);

  const editParams = (fieldKey, fieldValue, secondField = false) => {
    const otherFilters = activeEmailDetails.filters[1] || {};
    const filters = activeEmailDetails.filters[0] || {};
    if (!secondField) {
      if (fieldKey === 'segment') {
        editCampaign('filters', [
          {
            ...filters,
            [fieldKey]: fieldValue,
            type: 'SEGMENT',
          },
          { ...otherFilters },
        ]);
      } else {
        editCampaign('filters', [
          {
            ...filters,
            [fieldKey]: fieldValue,
          },
          { ...otherFilters },
        ]);
      }
    } else {
      editCampaign('filters', [
        {
          ...filters,
        },
        { ...otherFilters, [fieldKey]: fieldValue },
      ]);
    }
  };

  const getOptions = () => {
    const userSegments = [];
    getUserSegments().then(response => {
      const sorted = response.sort();
      sorted.map(item => {
        if (item.segment.status === 'ACTIVE') {
          userSegments.push({
            value: item.segment.name,
            label: item.segment.name,
            isProcessCompleted: item.isProcessCompleted,
          });
        }
      });
      setOptions([...userSegments]);
    });
  };

  const authorizedMailUsers = () => {
    return getAuthorizedMailUsers().then(r => setTotalUsers(r));
  };

  const handleRemoveSegment = () => {
    editParams('segment', '');
  };

  const getCountsWithAdvancedTargeting = dateInterval => {
    const data = {
      type: 'SEND_MAIL_SEGMENT',
      actionType: activeEmailDetails?.filters[1]?.actionType,
      campaign: activeEmailDetails?.filters[1]?.campaign,
      startDate: activeEmailDetails?.filters[1]?.startDate,
      endDate: activeEmailDetails?.filters[1]?.endDate,
    };
    if (activeEmailDetails?.filters[0]?.segment !== '') {
      data.segment = activeEmailDetails?.filters[0]?.segment;
      data.include = activeEmailDetails?.filters[0]?.include;
    }
    if (dateInterval === 'between') {
      if (
        activeEmailDetails?.filters[1]?.startDate &&
        activeEmailDetails?.filters[1]?.endDate &&
        activeEmailDetails?.filters[1]?.actionType
      ) {
        getCampaingCounts(data).then(response => {
          setSegmentUser(response);
        });
      }
    } else if (dateInterval === 'before') {
      if (
        activeEmailDetails?.filters[1]?.endDate &&
        activeEmailDetails?.filters[1]?.actionType
      ) {
        getCampaingCounts(data).then(response => {
          setSegmentUser(response);
        });
      }
    } else if (dateInterval === 'after') {
      if (
        activeEmailDetails?.filters[1]?.startDate &&
        activeEmailDetails?.filters[1]?.actionType
      ) {
        getCampaingCounts(data).then(response => {
          setSegmentUser(response);
        });
      }
    } else if (activeEmailDetails.filters[0]?.segment) {
      const filters = activeEmailDetails.filters[0];
      getSegmentStats(filters?.segment, filters?.include).then(response => {
        setSegmentUser(response.segmentedUserCount);
      });
    } else {
      setSegmentUser(null);
    }
  };

  useEffect(() => {
    component.componentDetails.forEach(comp => {
      if (comp.cardName === 'segment') {
        getOptions();
        authorizedMailUsers();
      }
    });
  }, []);

  return (
    <InputCardWrapper
      type='adaptiveBlock'
      cardTitle={component.title}
      labelFor='scheduling'
      labelClass='cardTitle'
    >
      <div style={{ display: 'flex', gap: '2rem' }}>
        {component.componentDetails.map(comp => {
          const Component = Components[comp.componentName];
          return (
            <Component
              key={comp.cardName}
              fieldValue={activeEmailDetails[comp.objectName][comp.type]}
              componentDetail={comp}
              options={comp.cardName === 'segment' ? options : comp.options}
              editCampaign={editParams}
              activeEmailDetails={activeEmailDetails}
              emailType={emailType}
              readOnly={readOnly}
            />
          );
        })}
      </div>
      {activeEmailDetails.filters[0]?.segment && (
        <span className={styles.removeSegment} onClick={handleRemoveSegment}>
          X
        </span>
      )}

      <AdvancedTargeting
        component={component}
        editCampaign={editParams}
        activeEmailDetails={activeEmailDetails}
        emailType={emailType}
        getSegmentCounts={getCountsWithAdvancedTargeting}
        readOnly={readOnly}
      />

      <div className={styles.stats}>
        <label className={styles.label}>
          <span className={styles.header}>{t('Total Opt-in Users')}</span>
          <span className={styles.stat}>{totalUsers || 0}</span>
        </label>
        {(activeEmailDetails.filters[0]?.segment || segmentUser) && (
          <label className={styles.label}>
            <span className={styles.header}>
              {t('Opt-in Users in Selected Segment')}
            </span>
            <span className={styles.stat}>{segmentUser || 0}</span>
          </label>
        )}
      </div>
    </InputCardWrapper>
  );
};

export default CampaignName;
