import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { browserHistory } from 'react-router';
import {
  isSwitchAccount,
  hasDotDigitalConfiguration,
  hasKlaviyoConfiguration,
  mailProviderIsSegmentify,
} from '../modules/auth/user';
import Tooltip from '../components/tooltip';
import { getNow } from '../system/date';

import { t, confirm } from '../system/ui';
import { setTitle } from '../system/document';

import {
  modalActions,
  uiActions,
  wizardActions,
  mailWizardActions,
} from '../actions';

import { getEmailTemplates, getActiveEmails } from '../modules/emails/ajax';
import { emailGoTestOrLive } from '../modules/campaigns/wizard';

import { TextField, TextArea, Calendar } from '../components/fields';
import Fields from '../modules/campaign/fields';
import {
  paramsTypes,
  days,
  localizationParams,
  paramsInWizard,
} from '../constants/datamaps/email';
import MailMapping from '../components/email/mapping';
import WizardDateSelection from '../components/wizard/date-selection';
import DotDigitalModal from '../components/email/dotdigital-modal';

import TimeAlert from '../components/time-alert';

let lastType = '';

class AddEmailCampaign extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: {
        type: 'FLY',
        params: {
          eventName: '',
          mailTo: '',
          klaviyoEventName: '',
          subject: '',
          mailContent: '',
        },
        startDate: getNow(),
      },
      scenarioName: '',
      campaignStatus: '',
      subject: '',
      _subject: '',
      mailContent: '',
      _mailContent: '',
      campaignCustoms: {
        eventName: '',
        mailTo: '{{user:email}}',
        klaviyoEventName: '',
        subject: '',
        mailContent: '',
      },
      startDate: '',
      endDate: null,
      contentSamples: {},
      mailTemplates: [],
      template: '',
      sendToIdentifyApi: false,
      customTemplateList: [],
      isSegmentifyProvider: mailProviderIsSegmentify('SEGMENTIFY'),
    };

    this.campaignDetails = this.campaignDetails.bind(this);
    this.onCampaignDataChange = this.onCampaignDataChange.bind(this);
    this.validate = this.validate.bind(this);
    this.goLive = this.goLive.bind(this);
    this.goTest = this.goTest.bind(this);
    this.onCustomParamChange = this.onCustomParamChange.bind(this);
    this.onMailToChange = this.onMailToChange.bind(this);
    this.onScenarioNameChange = this.onScenarioNameChange.bind(this);
    this.toggleIdentifyInput = this.toggleIdentifyInput.bind(this);
    this.customTemplateAction = this.customTemplateAction.bind(this);
    this.workingHourRef = React.createRef();
  }

  openDotModal() {
    modalActions.openModal({
      title: 'Dotdigital Campaign Template Variables',
      className: 'tfy-filter-modal',
      content: () => <DotDigitalModal />,
    });
  }

  componentDidMount() {
    setTitle(t('Add Email Marketing Campaign'));

    wizardActions.saveReset();
    wizardActions.wizardReset();
    wizardActions.wizardEdit();

    uiActions.confirmOnLeave(this);

    this.campaignDetails(
      isSwitchAccount() ? this.props.switchedUser : this.props.user,
    );
    uiActions.isValidated();
    uiActions.removePageClass();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      this.campaignDetails(
        isSwitchAccount() ? newProps.switchedUser : newProps.user,
      );
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.validate === true && oldProps.validate === false) {
      this.validate(lastType);
    }
  }

  componentWillUnmount() {
    wizardActions.saveReset();
    wizardActions.wizardEdit();
    wizardActions.wizardReset();
    uiActions.hideBreadcrumb();
    uiActions.confirmOnLeave(this);
  }

  campaignDetails(user) {
    if (user.account) {
      uiActions.isLoading();
      getEmailTemplates(templates => {
        uiActions.isLoaded();
        if (templates) {
          let fixedTemplates = templates;
          let customTemplates = [];
          if (this.state.isSegmentifyProvider) {
            fixedTemplates = templates.map(template => template.templateName);
            customTemplates = templates;
          }

          this.setState(
            {
              mailTemplates: fixedTemplates,
              template: fixedTemplates[0],
              customTemplateList: customTemplates,
            },
            () => {
              uiActions.showBreadcrumb({
                home: {
                  name: t('Email Marketing'),
                  icon: 'emailModule',
                  slug: '/email/view-all',
                  iconSvg: true,
                },
                current: {
                  name: 'Add Fly Mode Campaign',
                },
              });
            },
          );
        }
        mailWizardActions.addMapping([
          { id: Math.random(), key: '', value: '' },
        ]);
      }, this.state.isSegmentifyProvider);
    }
  }

  onCampaignDataChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      this.onCustomParamChange,
    );
  }

  validate(type) {
    const errors = document.querySelectorAll('.has-error');

    lastType = type;

    if (this.props.validate === true && errors.length === 0) {
      if (type === 'test') {
        confirm({
          title: 'Update Campaign on Draft Mode?',
          content: 'This action will immediately effect campaign status',
          onConfirm: this.goTest,
          onCancel: () => {
            this.setState({
              isSubmitting: false,
            });
          },
        });
      }

      if (type === 'live') {
        confirm({
          title: 'Update Campaign on Live?',
          content: 'This action will immediately effect campaign status',
          onConfirm: this.goLive,
          onCancel: () => {
            this.setState({
              isSubmitting: false,
            });
          },
        });
      }
    } else {
      uiActions.isValidating();
    }
  }

  goTest() {
    this.setState({ isSubmitting: true, submittingButton: 'live' }, () => {
      emailGoTestOrLive(
        '',
        this,
        this.props.criteria,
        this.props.includes,
        this.props.excludes,
        this.props.mappings,
        campaign => {
          this.setState({
            saved: true,
            campaignStatus: 'TEST',
            campaign,
          });
        },
      );
      uiActions.formSaved();
    });
  }

  goLive() {
    this.setState({ isSubmitting: true, submittingButton: 'live' }, () => {
      emailGoTestOrLive(
        '',
        this,
        this.props.criteria,
        this.props.includes,
        this.props.excludes,
        this.props.mappings,
        campaign => {
          this.setState({
            saved: true,
            campaignStatus: 'ACTIVE',
            campaign,
          });
        },
      );
      uiActions.formSaved();
    });
  }

  onCustomParamChange() {
    const newCustoms = {
      subject: this.state.subject,
      _subject: this.state.subject,
      mailContent: this.state.mailContent,
      _mailContent: this.state.mailContent,
      mailTo: this.state.campaignCustoms.mailTo,
      klaviyoEventName: this.state.klaviyoEventName,
    };
    Object.keys(this.state.campaignCustoms).forEach(custom => {
      if (
        paramsInWizard.indexOf(custom) < 0 &&
        Object.keys(localizationParams).indexOf(custom) < 0
      ) {
        if (custom === 'workingHour') {
          newCustoms.workingHour = this.state.campaignCustoms.workingHour;
        } else if (this.refs.wizard.querySelector(`[name='${custom}Start']`)) {
          const startValue = this.refs.wizard.querySelector(
            `[name='${custom}Start']`,
          ).value;
          const endValue = this.refs.wizard.querySelector(
            `[name='${custom}End']`,
          ).value;
          newCustoms[custom] = `${startValue}-${endValue}`;
        } else if (this.refs.wizard.querySelector(`[name='${custom}']`)) {
          if (
            paramsTypes[custom].type === 'integer' ||
            paramsTypes[custom].type === 'text'
          ) {
            const val = this.refs.wizard.querySelector(`[name='${custom}']`)
              .value;
            if (val) {
              if (paramsTypes[custom].type === 'integer') {
                newCustoms[custom] =
                  val > 0 ? val : this.state.campaignCustoms[custom];
              } else {
                newCustoms[custom] = val;
              }
            } else {
              newCustoms[custom] = val;
            }
          }
        }
      }
    });
    this.setState({
      campaignCustoms: newCustoms,
    });
  }

  onMailToChange(e) {
    const campaignCustoms = { ...this.state.campaignCustoms };
    campaignCustoms.mailTo = e.target.value;
    this.setState({
      campaignCustoms,
    });
  }

  onScenarioNameChange(e) {
    this.setState({
      scenarioName: e.target.value,
    });
  }

  toggleIdentifyInput() {
    this.setState({
      sendToIdentifyApi: !this.state.sendToIdentifyApi,
    });
  }

  customTemplateAction(actionType) {
    let pathname = '';
    const state = { fromPathName: this.props.location.pathname };
    if (actionType === 'create') {
      pathname = '/email/templates/create';
    } else if (actionType === 'edit' && this.state.template) {
      const selectedTemplateObj = this.state.customTemplateList.filter(
        template => template.templateName === this.state.template,
      );
      pathname = `/email/templates/edit/${selectedTemplateObj[0].id}`;
    }

    if (pathname) {
      browserHistory.push({ pathname, state });
    }
  }

  render() {
    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
    const _hasKlaviyoConfiguration = hasKlaviyoConfiguration();
    return (
      <form id='wizard' ref='wizard' className='wizard wizard-cards'>
        <h3 className='page-title'>{this.state.scenarioName}</h3>
        <div className='widget page-content-block'>
          <ol className='form-elements'>
            <div className='wizard-comp wizard-comp-campaigntitle'>
              <li>
                <div
                  className='wizard-comp'
                  style={{ marginTop: '0', paddingTop: '0' }}
                >
                  <h3
                    className='wizard-comp-title'
                    style={{ display: 'inline-block', fontWeight: 600 }}
                  >
                    {t('Mail Settings')}
                  </h3>
                  <span
                    style={{
                      position: 'relative',
                      top: '-2px',
                      left: '2px',
                    }}
                  >
                    <Tooltip
                      content={t(
                        'Prepare your email’s subject and content here. You can either get creative with your own words or select one from the content list and change it as you wish.',
                      )}
                      alignment='left'
                      placement='right'
                    >
                      <i className='icon-info' role='presentation'>
                        <span className='for-screenreader-only' />
                      </i>
                    </Tooltip>
                  </span>
                  {!_hasKlaviyoConfiguration && (
                    <TextField
                      name='subject'
                      label='Mail Subject'
                      className='item-stacked one-whole'
                      value={this.state.subject}
                      required
                      tooltipText={t('Prepare the subject of your emails.')}
                      tooltip
                      onChange={this.onCampaignDataChange}
                      ref='subject'
                    />
                  )}

                  <TextArea
                    name='mailContent'
                    label='Mail Content'
                    className='item-stacked one-whole'
                    value={this.state.mailContent}
                    required
                    tooltipText={t(
                      'Prepare the text in your emails. This will be shown above the recommended products inside the email.',
                    )}
                    tooltip
                    tooltipLink='#'
                    onChange={this.onCampaignDataChange}
                    ref='content'
                  />
                </div>
              </li>
            </div>

            <li>
              <ol className='form-elements wizard-items'>
                <div
                  className='wizard-comp'
                  style={{ marginTop: '0', paddingTop: '0' }}
                >
                  <li
                    className={
                      this.state.campaign.instanceId === 'PRICE_DROP' ||
                      this.state.campaign.instanceId === 'ABANDONED_CART' ||
                      this.state.campaign.instanceId === 'BACK_IN_STOCK'
                        ? 'wizard-perso-fields'
                        : 'wizard-perso-fields'
                    }
                  >
                    <Fields
                      ref='contentExtra'
                      campaign={this.state.campaign}
                      comps={[{ field: 'recommendations' }]}
                      isEmail
                      emailType={this.state.campaign.type}
                      disabled={
                        this.props.criteria.length === 0 &&
                        this.state.campaign.type === 'FLY'
                      }
                    />
                  </li>
                </div>
              </ol>
            </li>

            {this.state.campaign.type === 'FLY' && (
              <MailMapping
                campaignCustoms={this.state.campaignCustoms}
                onMappingChange={this.onMappingChange}
                onMailToChange={this.onMailToChange}
              />
            )}
          </ol>
        </div>

        <div className='wizard-options email-wizard-option'>
          <ol className='form-elements'>
            <label className='item-head-title' style={{ oveflow: 'hidden' }}>
              {t('Campaign Name')}
            </label>
            <li
              className='campaign-subject'
              style={{ marginBottom: '35px', paddingTop: '20px' }}
            >
              <TextField
                name='scenarioName'
                className='item-stacked one-whole label-emphasise'
                value={this.state.scenarioName}
                required
                onChange={this.onScenarioNameChange}
                disabled={this.state.campaign.type !== 'FLY'}
              />
            </li>
            <label className='item-head-title'>
              {t('Campaign Details')}
              <Tooltip
                content={t('Determine campaign based configurations')}
                alignment='right'
                placement='bottom'
              >
                <i className='icon-info' role='presentation'>
                  <span className='for-screenreader-only' />
                </i>
              </Tooltip>
            </label>
            {this.state.campaign.type === 'FLY' && (
              <WizardDateSelection
                ref='wizardDateSelection'
                campaign={this.state.campaign}
                isEmail
              />
            )}
            {Object.keys(this.state.campaignCustoms).map(custom => {
              if (custom === 'daysWithin') return;
              // Hide non-sidebar params and localization params
              if (
                paramsInWizard.indexOf(custom) < 0 &&
                Object.keys(localizationParams).indexOf(custom) < 0
              ) {
                if (paramsTypes[custom].type === 'integer') {
                  if (paramsTypes[custom].max) {
                    const start = paramsTypes[custom].min;
                    const end = paramsTypes[custom].max;
                    const list = [];
                    for (let i = start; i <= end; i++) {
                      list.push(i);
                    }
                    return (
                      <label className='item item-stacked one-whole label-emphasise is-select label-mail'>
                        <span className='item-label'>
                          {t(paramsTypes[custom].label)}
                          <Tooltip
                            content={t(paramsTypes[custom].tooltip)}
                            alignment='right'
                            placement='bottom'
                          >
                            <i className='icon-info' role='presentation'>
                              <span className='for-screenreader-only' />
                            </i>
                          </Tooltip>
                        </span>
                        <select
                          defaultValue={this.state.campaignCustoms[custom]}
                          className='one-whole criteria-field'
                          onChange={this.onCustomParamChange}
                          name={custom}
                        >
                          {list.map(item => {
                            return (
                              <option key={item} value={item}>
                                {custom === 'workingDay'
                                  ? t(days[item - 1])
                                  : item}
                              </option>
                            );
                          })}
                        </select>
                      </label>
                    );
                  }
                  return (
                    <span key={custom} className='label-mail label-text-field'>
                      <TextField
                        name={custom}
                        label={t(paramsTypes[custom].label)}
                        className='item-stacked one-whole label-emphasise'
                        value={this.state.campaignCustoms[custom]}
                        required
                        tooltipText={t(paramsTypes[custom].tooltip)}
                        tooltipPlacement='bottom'
                        tooltip
                        type='number'
                        onChange={this.onCustomParamChange}
                      />
                    </span>
                  );
                }
                if (paramsTypes[custom].type === 'timepicker') {
                  const constraint = { minutes: { step: 15 } };
                  let val = this.state.campaignCustoms[custom];
                  if (val.length < 3) {
                    val += ':00';
                  }
                  return (
                    <>
                      <TimeAlert
                        getter={getActiveEmails}
                        refElem={this.workingHourRef}
                        hour={this.state.params.workingHour}
                      />
                      <label
                        className='item item-stacked one-whole label-emphasise is-select label-mail'
                        ref={this.workingHourRef}
                      >
                        <span className='item-label'>
                          {t(paramsTypes[custom].label)}
                          <Tooltip
                            content={t(paramsTypes[custom].tooltip)}
                            alignment='right'
                            placement='bottom'
                          >
                            <i className='icon-info' role='presentation'>
                              <span className='for-screenreader-only' />
                            </i>
                          </Tooltip>
                        </span>
                        <Calendar
                          name='date'
                          className='item-field small'
                          value={val}
                          dateFormat={false}
                          timeConstraints={constraint}
                          onChange={this.onWorkingHourChange}
                        />
                      </label>
                    </>
                  );
                }
                if (paramsTypes[custom].type === 'text') {
                  if (
                    custom === 'klaviyoEventName' &&
                    !_hasKlaviyoConfiguration
                  ) {
                    return;
                  }
                  return (
                    <span key={custom} className='label-mail label-text-field'>
                      <TextField
                        name={custom}
                        label={t(paramsTypes[custom].label)}
                        className='item-stacked one-whole label-emphasise'
                        value={this.state.campaignCustoms[custom]}
                        required
                        tooltipText={t(paramsTypes[custom].tooltip)}
                        tooltipPlacement='bottom'
                        tooltip
                        type='text'
                        onChange={this.onCustomParamChange}
                      />
                    </span>
                  );
                }
                const start = paramsTypes[custom].min;
                const end = paramsTypes[custom].max;
                const list = [];
                for (let i = start; i <= end; i++) {
                  list.push(i);
                }
                return (
                  <label className='item item-stacked one-whole label-emphasise is-select label-mail label-interval'>
                    <span className='item-label'>
                      {t(paramsTypes[custom].label)}
                      <Tooltip
                        content={t(paramsTypes[custom].tooltip)}
                        alignment='left'
                      >
                        <i className='icon-info' role='presentation'>
                          <span className='for-screenreader-only' />
                        </i>
                      </Tooltip>
                    </span>
                    <select
                      value={this.state.campaignCustoms[custom].split('-')[0]}
                      className='one-whole criteria-field label-interval--start'
                      onChange={this.onCustomParamChange}
                      name={`${custom}Start`}
                    >
                      {list.map(item => {
                        const higherValue = this.state.campaignCustoms[
                          custom
                        ].split('-')[1];
                        if (higherValue <= item) {
                          return (
                            <option key={item} value={item} disabled>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                    -
                    <select
                      value={this.state.campaignCustoms[custom].split('-')[1]}
                      className='one-whole criteria-field label-interval--end'
                      onChange={this.onCustomParamChange}
                      name={`${custom}End`}
                    >
                      {list.map(item => {
                        const lowerValue = this.state.campaignCustoms[
                          custom
                        ].split('-')[0];
                        if (lowerValue >= item) {
                          return (
                            <option key={item} value={item} disabled>
                              {item}
                            </option>
                          );
                        }
                        return (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                );
              }
            })}

            {!_hasKlaviyoConfiguration && (
              <label className='item item-stacked one-whole label-emphasise is-select label-mail'>
                <span className='item-label'>
                  {t('Campaign Template')}
                  <Tooltip
                    content={t(
                      'Choose an email template from your ESP account.',
                    )}
                    alignment='right'
                    placement='bottom'
                  >
                    <i className='icon-info' role='presentation'>
                      <span className='for-screenreader-only' />
                    </i>
                  </Tooltip>
                </span>
                <select
                  value={this.state.template}
                  className='one-whole criteria-field'
                  onChange={this.onCampaignDataChange}
                  name='template'
                >
                  {this.state.mailTemplates.map(item => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </label>
            )}
            {hasDotDigitalConfiguration() && (
              <label className='item item-stacked one-whole'>
                <span className='item-label'>
                  <a
                    href
                    onClick={this.openDotModal.bind(this)}
                    style={{
                      display: 'inline',
                      color: '#375065',
                      textDecoration: 'underline',
                      fontSize: '9pt',
                    }}
                  >
                    {t('Show dotdigital template variables')}
                  </a>
                  <Tooltip
                    content={t('List template variables.')}
                    alignment='right'
                    placement='top'
                  >
                    <i
                      className='icon-info'
                      style={{ color: '#375065' }}
                      role='presentation'
                    >
                      <span className='for-screenreader-only' />
                    </i>
                  </Tooltip>
                </span>
              </label>
            )}

            {this.state.campaign.type === 'FLY' && _hasKlaviyoConfiguration && (
              <span
                key='sendToIdentifyApi'
                className='label-mail label-text-field'
              >
                <label className='item item-stacked is-checkbox'>
                  <input
                    type='checkbox'
                    name='sendToIdentifyApi'
                    checked={this.state.sendToIdentifyApi}
                    onChange={this.toggleIdentifyInput}
                  />
                  <span className='item-label'>{t('Sync To Profile')}</span>
                </label>
              </span>
            )}

            {this.state.isSegmentifyProvider && (
              <>
                <label
                  className='item-head-title'
                  style={{ marginTop: '20px' }}
                >
                  {t('Template Actions')}
                  <Tooltip
                    content={t(
                      'Edit the selected template or create a new one.',
                    )}
                    alignment='right'
                    placement='bottom'
                  >
                    <i className='icon-info' role='presentation'>
                      <span className='for-screenreader-only' />
                    </i>
                  </Tooltip>
                </label>
                <li className='buttons' style={{ marginBottom: '40px' }}>
                  <a
                    className={classNames({
                      'button custom-template-action ct-edit one-whole': true,
                      disabled: !this.state.template,
                    })}
                    onClick={() => this.customTemplateAction('edit')}
                  >
                    {t('Edit Template')}
                  </a>

                  <a
                    className={classNames({
                      'button custom-template-action ct-create one-whole': true,
                    })}
                    onClick={() => this.customTemplateAction('create')}
                  >
                    {t('Create Template')}
                  </a>
                </li>
              </>
            )}

            <li className='buttons' style={{ marginTop: '20px' }}>
              <a
                className={classNames('button gotest-action one-whole', {
                  'gotest-action--disabled': this.props.ui === 'isLoading',
                })}
                onClick={this.validate.bind(null, 'test')}
              >
                {t('Go Draft')}
              </a>
              <a
                className={classNames(
                  'button tertiary-action one-whole golive-action disabled',
                  { 'golive-action--disabled': this.props.ui === 'isLoading' },
                )}
                onClick={this.validate.bind(null, 'live')}
              >
                {t('Go Live')}
              </a>
            </li>
          </ol>
        </div>
      </form>
    );
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate,
  breadcrumb: store.ui.breadcrumb,
  formSaved: store.ui.formSaved,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes,
  mappings: store.mailWizard.mappings,
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
  ui: store.ui.ui,
});

AddEmailCampaign.contextTypes = {
  router: PropTypes.object.isRequired,
};

AddEmailCampaign.propTypes = {
  validate: PropTypes.bool,
};

export default connect(mapStatesToProps)(AddEmailCampaign);
