/*
 * Created by Barisaltun
 * */
'use strict';

import React from 'react';
import { connect } from 'react-redux';

import { isSuperUser } from '../../modules/auth/user';

import { t } from '../../system/ui';
import { modalActions, uiActions } from '../../actions';

import Icon from '../icon';
import Icons from '../../components/icons';
import CampaignHistoryModal from '../../modules/campaigns/historymodal';
import { moveCampaignToArchive } from '../../modules/search/ajax';
import { searchAlgorithmTitles } from '../../constants/datamaps/wizard';

class SearchActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [
        { name: 'Edit', icon: 'gear', action: 'edit' },
        { name: 'Report', icon: 'pie-chart', action: 'report' },
        {
          name: 'Passive',
          icon: 'archive',
          action: 'addToArchive',
          superUserOnly: true,
        },
        {
          name: 'History',
          icon: 'history',
          iconSvg: true,
          action: 'showCampaignHistory',
        },
      ],
      dropUp: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.edit = this.edit.bind(this);
    this.report = this.report.bind(this);
    this.addToArchive = this.addToArchive.bind(this);
    this.addToArchiveConfirmed = this.addToArchiveConfirmed.bind(this);
    this.showCampaignHistory = this.showCampaignHistory.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleClick(action) {
    this[action](this.props.campaign.instanceId);
  }

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    let partOfDocHeight = (document.documentElement.offsetHeight * 70) / 100;
    let listTop = this.refs.actionMenu.getBoundingClientRect().top;
    this.setState({
      dropUp: listTop > partOfDocHeight,
    });
  }

  edit() {
    let link = '/search/edit/' + this.props.campaign.instanceId.toLowerCase();
    uiActions.redirect(link);
  }

  report(instanceId = '') {
    let link = '/reports/search-single/' + instanceId;
    uiActions.redirect(link);
  }

  addToArchive() {
    uiActions.confirmationDialog({
      title: 'Are you sure to make the campaign passive?',
      content: '',
      onConfirm: this.addToArchiveConfirmed,
    });
  }

  addToArchiveConfirmed() {
    moveCampaignToArchive(this.props.campaign.instanceId).then(response => {
      if (response) {
        let note = () => {
          return <div>Searchbox campaign is saved to drafts successfully.</div>;
        };
        uiActions.showNotification({
          content: note,
        });
        this.props.getCampaignsAfterStatusChange();
      }
    });
  }

  showCampaignHistory() {
    let campaignId = this.props.campaign.instanceId,
      campaignName =
        this.props.campaign.scenarioName ||
        this.props.campaign.name ||
        searchAlgorithmTitles(this.props.campaign.instanceId).campaignTitle,
      campaignHistoryModal = () => (
        <CampaignHistoryModal campaignId={campaignId} isPalantir={true} />
      );

    modalActions.openModal({
      title: 'Campaign History (' + campaignName + ')',
      content: campaignHistoryModal,
    });
  }

  render() {
    let actions = this.state.actions;
    let listClass = 'widget-nav-popup action-nav-popup';
    if (this.state.dropUp) {
      actions = this.state.actions.slice().reverse();
      listClass = 'widget-nav-popup action-nav-popup drop-up';
    }
    return (
      <nav
        className='widget-nav action-nav'
        aria-haspopup='true'
        ref='actionMenu'
      >
        <a className='secondary-action'>{t('Actions')}</a>

        <ul className={listClass}>
          {actions.map((action, index) => {
            if (
              (!isSuperUser() && action.superUserOnly) ||
              (action.name.toLowerCase() === 'passive' &&
                this.props.status.toLowerCase() === 'passive')
            ) {
              return;
            }
            return (
              <li key={index}>
                <a
                  className='widget-nav-popup-item action-nav-popup-item'
                  onClick={this.handleClick.bind(null, action.action)}
                >
                  {action.iconSvg ? (
                    <Icons name={action.icon} color='#fff' />
                  ) : (
                    <Icon name={action.icon} />
                  )}
                  {t(action.name)}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

const mapStatesToProps = store => ({
  status: store.campaigns.status,
});

export default connect(mapStatesToProps)(SearchActions);
