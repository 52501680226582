import React, { Component } from 'react';
import { connect } from 'react-redux';

import Fields from '../../modules/campaign/fields';
import Name from '../../modules/campaign/fields/name';
import Devices from '../../modules/campaign/fields/devices';
import { isSwitchAccount } from '../../modules/auth/user';
import Tooltip from '../../components/tooltip';
import SelectBox from '../../components/shared/selectbox';
import TimeBetween from '../../components/shared/time-between';
import { t } from '../../system/ui';
import { updateWizardForm, wizardReset } from '../../actions/wizard';
import {
  pushNotificationsLifeTime,
  pushNotificationsPeriodicLifeTime,
} from '../../constants/datamaps/push';

class RightWizardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
  }

  componentWillUnmount() {
    wizardReset();
  }

  render() {
    const {
      additionalLanguages,
      additionalCurrencyList,
      mainCurrency,
      wizardForm,
    } = this.props;

    const { mainLanguage } = this.account;

    let langList =
      additionalLanguages && additionalLanguages.indexOf(mainLanguage) > -1
        ? additionalLanguages
        : [mainLanguage].concat(additionalLanguages);
    langList = langList.map(lang => {
      return { value: lang, label: lang };
    });
    langList.push({ value: 'SESSION_BASED', label: 'Session based' });

    let currencyList =
      additionalCurrencyList &&
      additionalCurrencyList.indexOf(mainCurrency.code) > -1
        ? additionalCurrencyList
        : [mainCurrency.code].concat(additionalCurrencyList);
    currencyList = currencyList.map(currency => {
      return { value: currency, label: currency };
    });

    currencyList.push({ value: 'SESSION_BASED', label: 'Session based' });

    return (
      <div className='wizard-options email-wizard-option'>
        <ol className='form-elements'>
          <label className='item-head-title' style={{ overflow: 'hidden' }}>
            {t('Campaign Name')}
          </label>
          <label className='item item-stacked one-whole label-emphasise'>
            <Name
              isPush
              campaign={{
                name: 'Abandoned Cart',
              }}
              disabled
              options={{ updateWizardForm: this.props.updateWizardForm }}
            />
          </label>

          <div className='item item-stacked one-whole label-emphasise'>
            <Devices
              campaign={{ devices: this.props.wizardForm.devices }}
              options={{ updateWizardForm: this.props.updateWizardForm }}
            />
          </div>

          <label className='item-head-title'>
            {t('Campaign Details')}
            <Tooltip
              content={t('Determine campaign based configurations')}
              alignment='right'
              placement='bottom'
            >
              <i className='icon-info' role='presentation'>
                <span className='for-screenreader-only' />
              </i>
            </Tooltip>
          </label>
          <label
            className='item item-stacked one-whole label-emphasise is-select label-mail'
            style={{ border: 'none' }}
          >
            <TimeBetween
              title={t('Send Between')}
              tooltip={t(
                'The campaign will be launched during the time slot you choose.',
              )}
              firstValue={wizardForm.betweenFirst}
              secondValue={wizardForm.betweenSecond}
              onChange={evt => {
                this.props.updateWizardForm('betweenFirst', evt.target.value);
              }}
              onChangeSecond={evt => {
                this.props.updateWizardForm('betweenSecond', evt.target.value);
              }}
            />
          </label>
          <label className='item item-stacked one-whole label-emphasise is-select label-mail'>
            <span className='item-label'>
              Lifetime
              <Tooltip
                content='For how long the campaign should be visible following the initial launch?'
                alignment='left'
              >
                <i className='icon-info' role='presentation'>
                  <span className='for-screenreader-only' />
                </i>
              </Tooltip>
            </span>
            <select
              defaultValue={wizardForm.lifeTime}
              className='one-whole criteria-field'
              onChange={evt => {
                this.props.updateWizardForm('lifeTime', evt.target.value);
              }}
              name='validity'
              value={wizardForm.lifeTime}
            >
              {this.state.periodicSelected
                ? pushNotificationsPeriodicLifeTime.map(item => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))
                : pushNotificationsLifeTime.map(item => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
            </select>
          </label>
          <label className='item item-stacked one-whole label-emphasise is-select label-mail'>
            <SelectBox
              title={t('Language')}
              tooltip={t('Select the campaign language')}
              defaultValue={mainLanguage}
              value={wizardForm.language}
              values={langList}
              onChange={evt => {
                this.props.updateWizardForm('language', evt.target.value);
              }}
            />
          </label>

          <label className='item item-stacked one-whole label-emphasise is-select label-mail'>
            <SelectBox
              title={t('Currency')}
              tooltip={t('Select the campaign currency')}
              defaultValue={mainCurrency}
              value={wizardForm.currency}
              values={currencyList}
              onChange={evt => {
                this.props.updateWizardForm('currency', evt.target.value);
              }}
            />
          </label>
          <li className='buttons'>
            <Fields
              campaign={this.props.campaign}
              comps={this.props.buttons}
              campaignType={this.props.type}
              campaignFields={this.refs.mainField}
              additionalTitles={this.state.additionalTitles}
            />
          </li>
        </ol>
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  save: store.wizard.save,
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
  abandon: store.abandon,
  wizardForm: store.wizard.wizardForm,
});

const mapDispatchToProps = dispatch => {
  return {
    updateWizardForm: (name, actions) =>
      dispatch(updateWizardForm(name, actions)),
  };
};
export default connect(
  MapStatesToProps,
  mapDispatchToProps,
)(RightWizardContainer);
