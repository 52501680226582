/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';

import * as modals from '../constants/datamaps/modals';
import { trendsMap, statTypes } from '../constants/datamaps';

import { t, openModal } from '../system/ui';
import { clone } from '../system/object';
import { setTitle } from '../system/document';
import {
  localeString,
  localeStringMoney,
  toLocaleStringDigits,
} from '../system/string';
import { getPersonalizationReport } from '../modules/reports/ajax';
import stats from '../modules/stats';

import { uiActions, filterActions } from '../actions';
import { transFormPersReport, transformStats } from '../modules/transform';

import { findLongestStat } from '../components/reports/utils';

import Currency from '../components/currency';
import Icon from '../components/icon';
import Tooltip from '../components/tooltip';

class ExecutiveReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trends: clone(trendsMap),
    };

    this.getReport = this.getReport.bind(this);
    // this.transFormReports = this.transFormReports.bind(this);
  }

  componentDidMount() {
    setTitle(t('Executive Reports'));
    this.getReport(this.props.range);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (newProps.update === true) {
      this.getReport(newProps.range);
    }

    if (newProps.reset === true) {
      this.getReport(newProps.range);
    }
  }

  getReport(boundaries) {
    filterActions.filtersUpdated();
    filterActions.filtersReset();
    if (boundaries[0] && boundaries[1]) {
      uiActions.isLoading();
      const timeRange = {};
      timeRange.start = boundaries[0].format('YYYY-MM-DD');
      timeRange.end = boundaries[1].format('YYYY-MM-DD');
      stats.getRangedStats(timeRange, allStats => {
        getPersonalizationReport('ALL', timeRange, 'ALL', campaigns => {
          const currentCampaigns =
            typeof campaigns === 'object' ? campaigns : [];

          const transformedStats = transformStats(allStats);

          this.setState({
            trends: this.transFormReports(currentCampaigns, transformedStats),
          });
          uiActions.isLoaded();
        });
      });
    }
  }

  transFormReports(campaigns, transformedStats) {
    let allCampaignLogs = [];
    campaigns.forEach(campaign => {
      allCampaignLogs = allCampaignLogs.concat(campaign.logs);
    });
    const ref = {};
    const res = allCampaignLogs.reduce((arr, o) => {
      if (!(o.trend in ref)) {
        ref[o.trend] = arr.length;
        arr.push({ ...o });
      } else {
        // eslint-disable-next-line no-param-reassign
        arr[ref[o.trend]].count += o.count;
      }
      return arr;
    }, []);

    const purchaseAmount =
      transformedStats?.purchase?.stats?.amount?.count || 0;

    return transFormPersReport(res, purchaseAmount, true);
  }

  showModal(modal, e) {
    e.preventDefault();

    const modalContent = modals[modal];

    openModal({
      title: modal,
      content: modalContent,
    });
  }

  render() {
    const longestStat = findLongestStat(this.state.trends);

    return (
      <div className='page-content-block'>
        <div className='widget-stat-list widget-report'>
          <div className='widget-stats'>
            {Object.keys(this.state.trends).map((item, index) => {
              const stat = this.state.trends[item].stats;
              const type = statTypes[item];
              const mainTitle = type.name;
              const className = `widget-stat stat-${item.toLowerCase()}`;
              const isVisible =
                item === 'widget' ||
                item === 'interaction' ||
                item === 'basket' ||
                item === 'revenue';

              const statName = item;
              if (!isVisible) {
                return false;
              }

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={className} key={`${item}-${index}`}>
                  {this.state.trends[item].modal &&
                  typeof modals[this.state.trends[item].modal] !==
                    'undefined' ? (
                    // eslint-disable-next-line react/jsx-indent
                    <a
                      className='modal-trigger'
                      onClick={e => {
                        this.showModal(this.state.trends[item].modal, e);
                      }}
                    >
                      <Icon name='info' />
                    </a>
                  ) : (
                    ''
                  )}

                  <h4 className='widget-stat-title'>{t(type.name)}</h4>
                  <p
                    className='widget-stat-count'
                    title={toLocaleStringDigits(stat.all.count)}
                  >
                    {type.currency === true
                      ? localeStringMoney(stat.all.count)
                      : localeString(stat.all.count)}
                  </p>
                  <p className='widget-stat-type'>
                    {type.currency === true ? (
                      <Currency />
                    ) : (
                      <Icon name={type.icon} />
                    )}
                    {type.currency !== true ? t(type.unit) : ''}
                  </p>
                  {Object.keys(stat).map((statItem, statIndex) => {
                    const singleStat = stat[statItem];
                    let singleStatData;
                    const isLastItem =
                      statIndex + 1 === Object.keys(stat).length;
                    if (
                      statItem === 'all' ||
                      singleStat.name === 'Actual View'
                    ) {
                      return;
                    }

                    if (mainTitle === 'Widget') {
                      return;
                    }

                    if (singleStat.currency) {
                      singleStatData = <Currency currency={singleStat.count} />;
                    } else {
                      singleStatData =
                        localeString(singleStat.count) ||
                        `${localeString(singleStat.ratio)}%`;
                    }

                    // eslint-disable-next-line consistent-return
                    return (
                      <div key={Math.random()}>
                        {mainTitle === 'Interaction' &&
                        this.state.trends.formSubmit ? (
                          <p key={Math.random()} className='widget-stat-name'>
                            {t('Form Submissions')}
                            <span className='widget-stat-data'>
                              {localeString(
                                this.state.trends.formSubmit.stats.count,
                              )}
                            </span>
                          </p>
                        ) : (
                          ''
                        )}
                        <p
                          key={statItem}
                          className={`widget-stat-name ${
                            statName !== longestStat && isLastItem
                              ? 'widget-stat-conversion-bottom'
                              : ''
                          }`}
                        >
                          {mainTitle === 'Widget' ||
                          mainTitle === 'Interaction' ||
                          mainTitle === 'Basket'
                            ? t(mainTitle + singleStat.name)
                            : t(singleStat.name)}
                          {singleStat.tooltip && (
                            <span className='stat-tooltip'>
                              <Tooltip
                                content={singleStat.tooltip}
                                alignment='top'
                                newTooltip
                              >
                                <Icon name='info' />
                              </Tooltip>
                            </span>
                          )}
                          <span className='widget-stat-data'>
                            {singleStatData}
                          </span>
                        </p>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  user: store.user.user,
  update: store.filters.update,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  reset: store.filters.reset,
});

export default connect(mapStatesToProps)(ExecutiveReport);
