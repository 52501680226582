/**
 * @fileOverview EmailBuilder initialState
 */
import { options, tools } from '../config/configs';

export default {
  editorOptions: {
    options,
    tools,
  },
  isDarkMode: false,
  serverSideQuery: {
    templateName: '',
    templateJson: [],
    templateHtml: '',
    templatePreviewImageUrlForPc: '',
    templatePreviewImageUrlForTablet: '',
    templatePreviewImageUrlForMobile: '',
    isFork: false,
  },
  isEditorReady: false,
  fetchedTemplate: {
    isFetched: false,
    templateJson: {},
  },
};
