import React from 'react';
import { clone } from 'system/object';
import { TextField } from 'components/fields';
import Icons from 'components/icons';
import { uiActions } from 'actions';

export default class ShadowRoot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectors: this.checkSelectorsEmpty(this.props.campaign?.[this.props.options?.propName]),
    };
  }

  componentWillReceiveProps(newProps) {
    if (
      JSON.stringify(this.props.campaign) !== JSON.stringify(newProps.campaign)
    ) {
      this.setState({
        selectors: this.checkSelectorsEmpty(newProps.campaign?.[newProps.options?.propName]),
      });
    }
  }

  addShadowRootSelector = () => {
    this.setState(prevState => ({
      selectors: prevState.selectors.concat(null),
    }), () => uiActions.formEdited());
  };

  removeShadowRootSelector = index => {
    this.setState(prevState => {
      const selectors = clone(prevState.selectors);
      selectors.splice(index, 1);
      return {
        selectors,
      };
    }, () => uiActions.formEdited());
  };

  onChange = (event, index) => {
    event.persist();
    this.setState(prevState => {
      const selectors = clone(prevState.selectors);
      selectors[index] = event.target.value;
      return {
        selectors,
      };
    }, () => uiActions.formEdited());
  };

  checkSelectorsEmpty(selectors) {
    return !Array.isArray(selectors) ? [] : selectors;
  }

  render() {
    const { selectors } = this.state;
    return (
      <div className='shadow-root'>
        <div onClick={this.addShadowRootSelector} style={{ display: 'flex', marginBottom: '1em' }}>
          <Icons name='plusWithBorder' />
          <span
            className='item-label'
            style={{ fontWeight: 100, color: 'unset', marginLeft: '5px' }}
          >
            Shadow Root
          </span>
        </div>

        {selectors.map((selector, index) => {
          return (
            <li className='item-title-field'>
              <TextField
                name='input'
                className='item-stacked one-whole'
                value={selector}
                required
                onChange={event => this.onChange(event, index)}
              />
              <span className='shadow-root-delete-icon' onClick={() => this.removeShadowRootSelector(index)}>
                <Icons name='crossCircle' color='#525252' />
              </span>
            </li>
          );
        })}
      </div>
    );
  }
}
