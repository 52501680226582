/**
 * @author Bilal Cinarli
 */

/* eslint react/display-name: "off" */

import React from 'react';

// shared of config translations
// add component translation files together with the component
export const fr = {
  // Menu
  Dashboard: 'Tableau de bord',
  Recommend: 'Recommandations',
  'View All': 'Voir Tout',
  Report: 'Rapport',
  Reports: 'Rapports',
  'Executive Summary': 'Sommaire Exécutif',
  Sales: 'Ventes',
  Trendify: 'Trendify',
  Products: 'Produits',
  Categories: 'Catégories',
  Brands: 'Marques',
  Insights: 'Insights',
  Bannerify: 'Bannerify',
  Settings: 'Paramètres',
  Account: 'Compte',
  Plugins: 'Plugins',
  Payments: 'Paiements',
  Integrations: 'Intégrations',
  Currency: 'Devise',
  'Segmentation Module': 'Segmentation',
  'Recommendation Settings': 'Recommendation',

  // Dashboard
  'WidgetActual View': 'Actual View',
  'WidgetTotal View': 'Impression',
  ImpressionRate: 'Impression Rate',
  WidgetConversion: 'View Rate',
  InteractionConversion: 'CTR',
  'BasketTotal Amount': 'Total Amount',
  'BasketAverage Contribution': 'Average Contribution',
  BasketConversion: 'Add to Basket Rate',
  RevenuePurchases: 'Purchases',
  'RevenuePurchase Items': 'Purchase Items',
  RevenueConversion: 'Conversion',
  'RevenueContribution Ratio': 'Contribution Ratio',

  view: 'vue',
  item: 'produit',
  views: 'vues',
  click: 'clic',
  banner_clicks: 'banniére clics',
  product_clicks: 'produits clics',
  items: 'produits',

  'Segmentify Performance': 'Performances de Segmentify',
  'Your Website': 'Votre Site Web',

  Widget: 'Widget',
  Interaction: 'Interaction',
  Revenue: 'Revenu',
  'Page View': 'Vue de la page',
  Device: 'Appareil',
  'Audience-Website': 'Visiteurs du site',
  Audience: 'Audience',
  Overall: 'Global',
  'Revenue (Overall)': 'Revenu (Global)',
  'Average Sessions': 'Durée Moyenne de Navigation',
  'Actual View': 'Vues',
  Conversion: 'Conversion',
  'Total Amount': 'Montant Total',
  'Average Contribution': 'Contribution Moyenne',
  Purchases: 'Achats',
  'Purchase Items': 'Produits Achetés',
  'Average Basket': 'Panier Moyen',
  'Contribution Ratio': 'Ratio de Contribution',
  PC: 'PC',
  Mobile: 'Mobile',
  Tablet: 'Tablette',
  'Unique Visitors': 'Visiteurs Uniques',
  'Unique Sessions': 'Sessions Uniques',
  'Pages/Sessions': 'Pages/Sessions',
  'Product/Sessions': 'Produit/Sessions',

  // Recommendation Page Types
  Page: 'Page',
  'Home Page': "Page d'accueil",
  'Category Page': 'Page de Catégorie',
  'Product Page': 'Page Produit',
  'Basket Page': 'Page Panier',
  'Search Page': 'Page de Recherche',
  '404 Page': 'Page 404',
  'Custom Pages': 'Pages personnalisées',
  'Trigger Based Page': "Campagne d'e-mailing",
  'Other Pages': 'Autres pages',
  'Custom Page': 'Page personnalisée',
  Home: "Page d'accueil",
  Category: 'Catégorie',
  Product: 'Produit',
  Basket: 'Panier',
  Search: 'Recherche',
  Palantir: 'Recherche',
  Other: 'Autre',

  // Campaign Actions
  Actions: 'Actions',
  Edit: 'Éditer',
  Duplicate: 'Dupliquer',
  History: 'Historique',

  // Persona Quiz
  Add_New_Response: 'Ajouter une Nouvelle Réponse',

  'Live Campaigns': 'Campagnes live',
  'Test Campaigns': 'Campagnes test',

  // When there is no Active Campaign
  NoActiveHomeCampaigns: () => {
    return (
      <div>
        <p>
          The trending homepage design for e-commerce is quite banner heavy
          which is simply providing the campaign banners and category specific
          banners in shop window. This is missing the opportunity to guide the
          customers for direct conversion from homepage by placement of
          “Selected for you” widgets including personalised products enriched
          with appropriate call to action buttons.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new home page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveCategoryCampaigns: () => {
    return (
      <div>
        <p>
          Category pages are landing so much visitors but bounce rates in
          e-commerce from category pages are also so high. A solution provided
          by Segmentify for high bounce rates from category pages is using
          “Trending in Category” product recommendation widgets and include the
          popular products in category selected based on the behaviour of the
          current visitor.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new category page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveProductCampaigns: () => {
    return (
      <div>
        <p>
          Product pages in e-commerce belong to the heart of conversion and all
          conversion rate optimisation efforts focus on these pages. A regular
          product page at least includes Product title, Images, Price,
          Availability and Add to cart button. Other components such as Product
          detail, Sizing, Colour options and others are the tools that are used
          to represent as much information as possible to the visitors about the
          products.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new product page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveBasketCampaigns: () => {
    return (
      <div>
        <p>
          Increase average basket size by cross-selling related products in
          basket/cart pages. Majority of customers are willing to add at least
          one more product to their shopping cart if right product is offered to
          them.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new basket page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveSearchCampaigns: () => {
    return (
      <div>
        <p>
          Visitors became frustrated when they can't finding no product when
          they searched keywords on online stores, and bounce rates on search
          pages are much higher than other pages. With Segmentify, you can offer
          variety of products inside widgets on search result page to decrease
          bounce rates and gaining back leaving visitors.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new search page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActive404Campaigns: () => {
    return (
      <div>
        <p>
          When you removed a url from your store, this pages actually doesn't
          remove from search engines and other external links. When visitors
          clicks this url, they will face a 404 page without no navigation, and
          they will definitely bounce from this page. With Segmentify, you can
          offer variety of products inside widgets on 404 page to decrease
          bounce rates and gaining back leaving visitors.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new 404 page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveCustomCampaigns: () => {
    return (
      <div>
        <p>
          When you need a campaign on a page that are not predefined in
          Segmentify, you can define custom page campaings like user account
          page, checkout page etc.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new custom page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  'NoActiveTrigger BasedCampaigns': () => {
    return (
      <div>
        <p>
          When you need a campaign based on an event instead of a page, you can
          define trigger based campaigns.
        </p>
        <p>
          Please select a widget type below to{' '}
          <strong>add new trigger based recommendation widget.</strong>
        </p>
      </div>
    );
  },

  'Need Help?': 'Need Help?',
  'Full Width': 'Largeur totale',
  'Right/Left Side': 'Barre latérale gauche/droite',
  'Add Custom Style Widget': 'Ajouter un style de widget personnalisé',

  // Add Campaign
  'Widget Title': 'Titre Widget',
  'This title will shown on your widget':
    'Ce titre sera affiché sur votre widget',

  Intelligent: 'Intelligent',
  Criteria: 'Filtrer (inclure)',
  Excludes: 'Filtrer (exclure)',
  'Shuffle Items': 'Articles aléatoires',
  'Smart Offers': 'Offres Smart',
  'All Category Products': 'Tous les produits de la catégorie',
  'All Child Category Products': 'Tous les produits de la catégorie enfant',
  'All Parent Category Products': 'Tous les produits de la catégorie Parent',
  'All Products': 'Tous les produits',
  'Last Visited (Product)': 'Dernière visite (produit)',
  'Purchased Together (Basket)': 'Acheté ensemble (panier)',
  'Purchased Together (Product)': 'Acheté ensemble (produit)',
  'Viewed Together (Basket)': 'Consultés Ensemble (panier)',
  'Viewed Together (Product)': 'Consultés Ensemble (produit)',
  'Rockstar Products': 'Produits stars',
  'Trending Products': 'Produits tendances',
  'Shining Stars': 'En vedette',
  'Discounted Products': 'Produits en réduction',
  'Brand Products': 'Produits de marque',
  'Alternative Products': 'Produits alternatifs',
  'All Category Product': 'Toutes les produits de la catégorie',
  'View - Today': "Consulté - Aujourd'hui",
  'View - This Week': 'Consulté - Cette semaine',
  'View - This Month': 'Consulté - Ce mois',
  'Purchase - Today': "Acheté Aujourd'hui",
  'Purchase - This Week': 'Acheté - Cette semaine',
  'Purchase - This Month': 'Acheté - Ce mois',
  'New Arrivals': 'Nouveaux produits',
  'Product Name': 'Nom du produit',
  'Product Name/ID': 'Nom du produit/ID',
  'Price Range': 'Tranche de prix',
  Custom: 'Personnalisé',
  Param: 'Paramètre',
  Value: 'Valeur',
  Brand: 'Marque',
  Label: 'Étiquette',
  Gender: 'Genre',
  Size: 'Taille',

  min: 'min',
  max: 'max',

  Equals: 'Correspond à',
  'Not Equals': 'Ne correspond pas à',
  Contains: 'Contient',
  'Not Contains': 'Ne contient pas',

  'Campaign Subject': 'Sujet de la campagne',

  'Start Date - Time': 'Date de début - Heure',
  'End Date - Time': 'Date de fin - Heure',

  'Device Types': "Types d'appareils",
  Web: 'Web',
  App: 'App',

  'You should select at least one device type':
    "Vous devriez sélectionner au moins un type d'appareil",

  'Target Div Selector': 'Cible Div ID',
  'The target component on your website where recommendation box going to insert itself':
    "L'élément HTML de votre site où le widget de recommandation de produit sera inséré.",

  Self: 'Propre à la place',
  Before: 'Avant',
  After: 'Après',
  Append: 'Joindre',
  Prepend: 'Ajouter',

  'Edit HTML': 'Éditer HTML',
  'Edit PreJS': 'Éditer PreJS',
  'Edit PostJS': 'Éditer PostJS',

  'Advanced Configuration': 'Configuration Avancée',
  'Go Test': 'Passer en mode test',
  'Go Live': 'Passer en mode live',

  'Attention!': 'Attention!',
  'This action will immediately effect on your website':
    'Cette action prendra effet immédiatement sur votre site.',

  'no-recommendation-report-info': statusAndCampaign => {
    return (
      <span>
        Il n'y a pas <strong>de {statusAndCampaign}</strong> sur cette page
      </span>
    );
  },

  'no-personalization-report-info': statusAndCampaign => {
    return (
      <span>
        Il n'y a pas <strong>de {statusAndCampaign}</strong>{' '}
      </span>
    );
  },

  'no-campaign-info': status => {
    return (
      <span>
        Il n'y a pas <strong>de {status} campagne</strong>
      </span>
    );
  },

  'no-segment-info': status => {
    return (
      <span>
        Il n'y a pas <strong>de {status} segment</strong>
      </span>
    );
  },

  'Create Now': 'Créer maintenant',
  'Campaign Report': 'Rapport de campagne',

  // Trendify
  'Product Stock': 'Stock de Produit',
  'Only In Stock': 'Seulement en stock',
  'Only Out of Stock': 'Seulement en rupture de stock',
  'All Product Categories': 'Tous les produits de la catégorie ',
  'Search Category': 'Rechercher une catégorie',
  'Product Category': 'Catégorie de produit',
  'Product ID or Title': 'ID ou titre du produit',
  'Search Product': 'Rechercher un produit',
  'Product Brand': 'Produit de marque',
  'All Product Brands': 'Toutes les marques de produits',
  Segmentify: 'Segmentify',
  Sources: 'Sources',
  'Check-in': 'Enregistrement',
  'Product Check-in Time': "Heure d'enregistrement du produit",
  ID: 'ID',
  'Product ID': 'ID de Produit',
  'Product name': 'Nom du Produit',
  'Product Categories': 'Catégories de Produits',
  Price: 'Prix',
  OldPrice: 'Old Price',
  'Product price and discounted price': 'Le prix des produits et prix réduit',
  Image: 'Image',
  'Product Image Url': 'Image du produit',
  View: 'Vues',
  'Number Of Product Views': 'Nombre de produits consultés',
  'Number Of Add To Basket Operations': "Nombre d'ajouts au panier",
  Rate: 'Taux',
  'Conversion Rate (Purchase/View)': 'Taux de conversion (achats/consultation)',
  'Conversion Rate (Add To Basket/View)':
    'Taux de conversion (ajouts au panier/consultation)',
  Purchase: 'Achat',
  'Number Of Product Purchases': 'Nombre de produits achetés',
  Amount: 'Montant',
  'Amount Of Product Purchases': 'Montant de produits achetés',
  'Number Of Clicks By Segmentify Recommendation Widgets':
    'Nombre de clics par les widgets de recommandation Segmentify',
  'Number Of Add To Basket Operations By Segmentify Recommendation Widgets':
    "Nombre d'ajouts au panier par les widgets de recommandation Segmentify  ",
  'Conversion Rate (Add To Basket/Click)':
    'Taux de conversion (ajouts au panier/clic)',
  'Number Of Purchases By Segmentify Recommendation Widgets':
    "Nombre d'achats par les widgets de recommandation Segmentify",
  'Conversion Rate (Purchase/Click)': 'Taux de conversion (achats/clic)',
  'Amount Of Purchases By Segmentify Recommendation Widgets':
    'Montant des achats par les widgets de recommandation Segmentify',
  'Views Referred From In-site': 'Visites venant du site',
  'Views Referred From Social Media': 'Visites venant des réseaux sociaux',
  'Views Referred From Search Engines':
    'Visites venant des moteurs de recherche',
  'Views Referred From Advertisements': 'Visites venant de la publicité',
  'Views Referred From Email Campaigns': 'Visites venant des campagnes email',
  'Views Referred From Price Listing Sites':
    'Visites venant des sites comparateurs',
  'Views Referred From Other Sources': "Visites venant d'autre sources",
  Show: 'Afficher',
  Campaigns: 'Campagnes',
  TrendifyPromo: () => {
    return (
      <span>
        Suivez <strong>les performances de vos produits</strong> en{' '}
        <strong> temps réel</strong>
      </span>
    );
  },

  // Insights
  'Whenever your customers see these products they buy them':
    'Chaque fois que vos clients consultent ces produits, ils les achètent',
  'High Spending': 'Produits pour lesquels les clients dépensent le plus',
  'Most viewed products with a referral from a paid channel':
    'Produits les plus consultés redirigés depuis un canal payant mais qui ne sont pas achetés.',
  'The best of a bad bunch':
    "Ces produits suscitent l'intérêt mais ne sont pas achetés.",
  'These are the most viewed products among the none-selling ones':
    'Ce sont les produits les plus consultés parmi ceux qui ne se vendent pas',
  'Discount Works': 'Réductions',
  'Most selling products among the discounted ones':
    'Produits les plus vendus parmi ceux en réduction.',
  'We Wish You Had': 'Nous vous souhaitons',
  'Most viewed products that are out of stock':
    'Produits les plus consultés qui sont en rupture de stock',
  'Abandoned Items': 'Produits abandonnés',
  'Which products your customers left in their cart':
    'Les produits le plus souvent laissés dans le panier.',
  'Organic Bazaar': 'Marché bio',
  'Most viewed products with a referral from Search Engine':
    'Produits les plus consultés provenant des résultats des moteurs de recherche.',
  'Social Trends': 'Tendances sur les réseaux sociaux',
  'Most viewed products with a referral from Social Media':
    'Produits les plus vus provenant des interactions sur les réseaux sociaux.',
  'You push hard': 'Vous envoyez beaucoup de notifications push',
  "Your customers see these products but they don't buy them":
    'Vos clients consultent ces produits fréquemment mais ne les ajoutent pas au panier.',
  'Products to Improve': 'Produits les moins vendus',
  'Worst converting products':
    'Vos clients consultent ces produits fréquemment mais ne les achètent pas.',
  'Most Revenue Growth (prior to compared period)':
    'Produits dont la hausse du revenu des ventes est la plus élevée par rapport à la semaine dernière.',
  'Losing Power': 'Produits dont le revenu des ventes décline',
  'Most Revenue Loss (prior to compared period)':
    'Produits dont la perte de revenus est la plus élevée rapport à la semaine dernière.',
  'An increasing trend occurred for these items in a selected basis':
    'Produits dont la hausse des ventes est la plus élevée par rapport à la semaine dernière.',
  'Under Shadow': 'Produits dont les ventes diminuent',
  'A decreasing trend occurred for these items in a selected basis':
    'Produits dont la diminution des ventes est la plus élevée par rapport à la semaine dernière.',
  'Basket Complementaries': 'Produits complémentaires dans le panier',
  'List of basket complementaries for the top selling products':
    'Liste des produits complémentaires dans le panier pour les produits les plus vendus.',

  // Bannerify
  Banner: 'Bannière',
  'Banner Group': 'Groupe de la bannière',
  'Banner Titles': 'Titres de la bannière',

  'All Groups': 'Tous les groupes',
  'All Titles': 'Tous les titres',
  'Show Active Banners': 'Afficher des bannières actives',
  'Show All Banners': 'Afficher tous les bannières',

  Group: 'Groupe',
  'Banner Order': 'Commander Bannière',
  'Check-in Time of Banner': "Heure d'enregistrement de la bannière",
  'Last Update': 'Dernière mise à jour',
  'Last Update Time of Banner':
    'Heure de la dernière mise à jour de la bannière',
  Title: 'Titre',
  'Banner Title': 'Titre de Bannière',
  'Banner Image Url': 'Image de la bannière',
  Targets: 'Cibles',
  'Banner Target(s)': 'Cible(s) de le bannière',
  Impression: 'Impression/vue',
  'Number of Impressions': "LNombre d'impressions/Nombre de vues",
  Click: 'Clic',
  'Number Of Clicks': 'Nombre de clics',
  'Conversion Rate (Click/Impression)':
    'Taux de clic (clic/Impression de la bannière)',
  'Product Views': 'Produits consultés',
  'Conversion Rate (Product View/Impression)':
    'Taux de Produits consultés (produits consultés/Impression de la bannière)',
  'Conversion Rate (Add To Basket/Impression)':
    "Taux d'ajout au panier (ajouts au panier/Impression de la bannière)",
  'Number Of Purchases': "Nombre d'achats",
  'Conversion Rate (Purchase/Impression)':
    "Taux d'achats (achats/Impression de la bannière)",

  BannerifyPromo: () => {
    return (
      <span>
        Suivez <strong>les performances de vos bannières</strong> en{' '}
        <strong> temps réel</strong>
      </span>
    );
  },
  Discover: 'Découvrir',

  of: 'de',
  Previous: 'Précédent',
  Next: 'Suivant',

  // Settings
  'Account Settings': 'Paramètres du Compte',
  'User Settings': 'Paramètres Utilisateur',
  'Api Key': "La clé de l'API",
  'Account Domain': 'URL du site',
  'Contact Person': 'Contact',
  'Contact Phone': 'Numéro du Contact',
  'Contact Address': 'Adresse de contact',
  Username: "Nom d'utilisateur",
  'Display Name': 'Afficher un nom',
  'Display Language': "Langue d'affichage",
  'New Password': 'Nouveau Mot de Passe',
  'New Password (Again)': 'Nouveau Mot de Passe (de nouveau)',
  'Customer Removal Tool': 'Customer Removal Tool',
  'Customer Removal Tool Description':
    'All historical personal data related to the given email address can be removed via this tool.',
  'Customer Removal Tool Description Label':
    "Insert customer's email address(es) here, separated by commas for more than one user.",
  'Customer Removal Tool Disclaimer': () => {
    return (
      <p>
        All historical personal information related to given email addresses
        will be deleted within the next 24 hours. When the removal process is
        finished, you will receive an email.
        <br />
        Please note that the removal process is permanent and irreversible, upon
        its completion Segmentify can't recover any data.
      </p>
    );
  },

  // Payments
  'Monthly Subscription': 'Abonnement mensuel',
  'Standard Packages': 'Packs standard',
  Name: 'Nom',
  Surname: 'Prénom',
  Address: 'Adresse',
  City: 'Ville',
  Country: 'Pays',
  STARTER: 'STARTER',
  BASIC: 'BASIC',
  PRO: 'PRO',
  'Payment History': 'Historique de Paiement',
  mo: 'mois',
  'Package Details & Payment Info':
    'Détails du pack et informations de paiement',
  Date: 'Date',
  Operation: 'Opération',

  // Settings.Integrations
  "Let's Integrate Segmentify": "Étapes d'intégration",
  Initialize: 'Initialiser',
  'integration-script-info':
    'Il est très simple de commencer des recommandations intelligentes sur votre site avec Segmentify. Tout ce que vous devez faire est d’ajouter la ligne de code JS suivante à toutes vos pages à la fin de votre bloc HEAD.',
  'Complete the Integration': "Intégration/ les étapes de l'integration",
  'integration-type-selection':
    "Pas d'inquiétude, nous pouvons gérer l'intégration pour vous !",
  'Self Service': 'Self-Service',
  IntegrationZendDesk: () => {
    return (
      <span>
        Suivez les étapes du document d'aide pour terminer l'intégration.
      </span>
    );
  },
  StartChat: var1 => {
    return (
      <span>
        Notre service client peut gérer l'intégration pour vous.{' '}
        <a>J'ai besoin d'aide</a>.
      </span>
    );
  },
  'Help me, please': 'Aidez-moi !',

  'Choose what type recommendation features you want to use from Segmentify':
    'Choisissez la solution qui vous intéresse.',
  'integration-page-types':
    "Sélectionnez le type de page (exemple : page de produit, page d'accueil, etc.) sur lequel vous voulez avoir la recommandation puis le type de widget (exemple : produits les plus populaires)",
  'Recommended for You': 'Recommandé pour vous',
  'Best of Category': 'Le meilleur de la catégorie',
  'Last Minute Offers': 'Offres de dernière minute',
  'Start Recommend': 'Commencer à recommander',

  // 404 Texts
  'Page Not Found': 'Page non trouvée',

  // Account Types
  type: 'Type',
  provider: 'Provider',
  FAIL: 'Fail',
  WAITING: 'Waiting',
  PRE_POC: 'Pre-POC',
  POC: 'POC',
  STAGING: 'Staging',
  FAILED: 'Failed',
  FREEMIUM: 'Freemium',
  GROUP_A: 'Group A',
  GROUP_B: 'Group B',
  PAYING: 'Paying',
  ONHOLD: 'Onhold',
  CHURN: 'Churn',
  GROUP_C: 'Group C',
  ARCHIVE: 'Archive',

  // Engagmement
  Personalization: 'Personalization',
  Popup: 'Popup',
  'Pop-Ups': 'Pop-Ups',
  'Popup Banner': 'Popup Banner',
  'Popup Recommendation': 'Popup Recommendation',
  'E-Mail Collection': 'E-Mail Collection',
  Notifications: 'Notifications',
  'Push Permission': 'Push Permission',
  'Push Notification': 'Push Notification',
  'Push Notifications': 'Push Notifications',
  'Notification Bar': 'Notification Bar',
  'No Active Campaigns': 'Aucune campagne active',
  Campaign: 'Campagne',

  'Add Popup': 'Créer une pop-up',
  'Add Popup Banner': 'Créer une bannière pop-up',
  'Add Popup Recommendation': 'Créer une pop-up de recommandation de produit',
  'Add E-Mail Collection Popup':
    "Créer une pop-up de collecte d'adresses e-mail",
  'Add Push Permission': "Créer une campagne d'autorisation push",
  'Add Push Notification': 'Créer une notification push',
  'Add Notification Bar': 'Créer une barre de notifications',

  'Pages To Show': 'Pages à afficher',
  Position: 'Position',
  Top: 'Haut',
  Middle: 'Milieu',
  Bottom: 'Bas',
  Left: 'Gauche',
  Center: 'Centre',
  Right: 'Droite',
  'Timing & Frequency': 'TCalendrier et Fréquence',
  Timing: 'Calendrier',
  Immediate: 'Calendrier',
  Delay: 'Délai',
  Scroll: 'Défiler',
  'Visitor Leaving Page': 'Le visiteur quitte la page',
  Always: 'Toujours',
  Session: 'Session',
  'Life Time': 'Durée de vie',
  Days: 'Jours',
  seconds: 'secondes',
  days: 'jours',
  times: 'heures',
  'Target Audience': 'Audience ciblée',
  'Visitor is Member': 'Le visiteur est membre',
  'Visitor is not a Member': "Le visiteur n'est pas membre",
  'Visitor is': 'Le visiteur est',

  // Campaign Spesifics
  'Edit Popup Banner': 'Éditer la bannière pop-up',
  'Background Image URL (Recommend 800x700px)':
    "URL de l'image (taille recommandée : 800x700px)",
  'Push Title': "Titre de la pop-up d'autorisation push",
  'Push Description': "Description de la pop-up d'autorisation push",
  'Your Logo URL (Recommend 200x200px)':
    'URL du logo (taille recommandée : 200x200px)',
  'Background Image URL (Recommend 1920x80px)':
    "URL de l'image d'arrière-plan (taille recommandée : 1920x80px)",
  'Vertical Align': 'Alignement vertical',
  'Horizontal Align': 'Alignement horizontal',
  Once: 'Une fois',
  Periodic: 'Récurrent',
  Everyday: 'Chaque jour',
  'Every Monday': 'Chaque lundi',
  'Every Tuesday': 'Chaque mardi',
  'Every Wednesday': 'Chaque mercredi',
  'Every Thursday': 'Chaque jeudi',
  'Every Friday': 'Chaque vendredi',
  'Every Saturday': 'Chaque samedi',
  'Every Sunday': 'Chaque dimanche',
  Scheduling: 'Planification',
  'Visitor Segment': 'Catégorie de client',
  'Visitor Type': 'Type de client',
  Styles: 'Styles',
  Hours: 'Heures',
  'Valid for X': 'Valide',
  'Push Recommendation': 'Notification push de recommandation de produit',
  'Description (Max 192 characters)': 'Description (192 caractères maximum)',
  'Notification Icon URL (Recommended 192x192px)':
    "URL de l'icône de notification (taille recommandée : 192x192px)",
  'Notification Image URL': "URL de l'image de notification",
  'Target URL': 'URL',
  Frequency: 'Fréquence',
  'Background Color': "Couleur de l'arrière-plan",
  'Text Color': 'Couleur du texte',
  'Your work is not saved!. Are you sure want to leave?':
    "Votre travail n'est pas enregistré ! Voulez-vous vraiment quitter ?",
  'Scheduled Campaign': 'Campagne planifiée',
  'Completed Campaign': 'Campagne terminée',
  Recommendation: 'Recommandation',
  'unique-selector': 'sélecteur css',

  // Campaign Statuses
  'Live Recommendation': 'Live',
  'Test Recommendation': 'Test',
  'Archived Recommendation': 'Archive',
  Archived: 'Archived',

  'Campaign Note': 'Notes de la campagne',

  // Sales
  'Purchase Time': 'Heure de la commande',
  'Order No': 'Commande n°',

  // Currency Settings
  'Main Currency': 'Devise principale',
  Code: 'Code',
  'Decimal Places': 'Décimales',
  'Decimal Separator': 'Séparateur de décimales',
  'Thousand Seperator': 'Séparateur de milliers',
  Symbol: 'Symbole',
  'Place Symbol': 'Placer symbole',
  'Comma (,)': 'Comma (,)',
  'Dot (.)': 'Dot (.)',
  'Empty Space': 'Espace vide',
  None: 'Aucun',
  'You can check your currency code here':
    'Vous pouvez vérifier votre code devise ici.',
  "This Month's Cost": 'Coût de ce mois',
  // Campaigns Status Filters
  Live: 'Live',
  Test: 'Test',
  Archive: 'Archiv',
  live: 'live',
  test: 'test',

  // Campaign Device Filters
  Desktop: 'Desktop',
  'Mobile Web': 'Mobile Web',
  Android: 'Android',
  iOS: 'iOS',

  // Campaigns Sorting
  'Sort by Date': 'Trier par date',
  'Sort by Revenue': 'Trier par revenu',

  // Named Time Ranges
  All: 'Tout',
  'This Year': 'Cette Année',
  'Last Month': 'Mois précédent',
  'This Month': 'Ce Mois',
  'This Week': 'Cette Semaine',
  Yesterday: 'Hier',
  Today: "Aujourd'hui",

  // CTA Buttons
  Save: 'Enregistrer',
  Reset: 'Réinitialiser',
  Update: 'Mettre à jour',
  Cancel: 'Mettre à jour',
  Confirm: 'Confirmer',
  Continue: 'Continuer',
  Export: 'Exporter',
  'View More': 'Voir plus',
  Details: 'Détails',

  // Validation Texts
  'You should select a product from suggestions':
    'Vous devriez sélectionner au moins un produit.',
  'You should define at least one recommendation':
    'Vous devriez sélectionner au moins un type de recommandation.',
  'This field is required': 'Ce champ est requis.',
  'This field should equal to #fieldName#':
    'Ce champ devrait correspondre à #fieldName#',
  'This field should have at least #minLength# characters':
    'Ce champ devrait contenir au moins #minLength#' + ' caractères',
  PasswordField:
    'Votre mot de passe doit contenir au moins une lettre et un chiffre. Ces caractères spéciaux peuvent être inclus : ! @ # . _ - ( )',
  EmailField: 'Vous devez entrer une adresse e-mail valide.',
  'Field must be a valid URL.': 'Field must be a valid URL.',
  DeliveryRequired:
    'You should activate at least one option from the Delivery Steps above',

  // Update notification Bars
  'Recommendation widget is updated in test mode.Chrome Extension.':
    "Le widget de recommandation de produit est mis à jour en mode test. Vous pouvez le visualiser en activant le mode test via l'extension Chrome sur votre navigateur.",
  'Recommendation widget is updated in live mode.':
    'Le widget de recommandation de produit est mis à jour en mode live.',
  'Recommendation widget is activated in test mode and only test users can see. You can check the widget by activating test mode in your browserChrome Extension.':
    "Le widget de recommandation de produit est créé en mode test. Vous pouvez vérifier le widget en mode test en activant l'extension Chrome dans votre navigateur.",

  // ====== 24 AUGUST 2017 ======= //
  /// Insights
  allView: 'Total Views',
  purchase: 'Purchases',
  basket: 'Ajouter au panier',
  allViewCurWeek: 'Achats de la première périodee',
  purchaseCurWeek: 'Vues de la deuxième période',
  allViewLastWeek: 'Vues de la première période',
  purchaseLastWeek: 'Achats de la deuxième période',
  allPaidClick: 'Clics à partir de campagnes publicitaires',
  allSearchClick: "Clics à partir d'un moteur de recherche",
  allSocialClick: 'Clics à partir des réseaux sociaux',
  allAmountLastWeek: 'Revenus de la deuxième période',
  allAmountCurWeek: 'Revenus de la première période',
  amount: 'Recettes',

  // Emails
  LAST_VISIT_ALTERNATIVES: 'LAST VISIT ALTERNATIVES',
  ABANDONED_CART: 'ABANDONED CART',
  PERSONALIZED_NEWSLETTER: 'PERSONALIZED NEWSLETTER',
  PURCHASE_REMINDER: 'PURCHASE REMINDER',

  // Provider Warnings
  ShopifyWarning:
    "You have a Shopify store and we advise you to use Segmentify app from your store's Shopify admin panel",
  PrestashopWarning:
    'You have a Prestashop store and we advise you to use Segmentify add-on from your Prestashop admin panel',
  MagentoWarning:
    'You have a Magento store and we advise you to use Segmentify plugin from Magento admin panel',

  'All Recommendations': 'Recommendations',
  Recommendations: 'Recommedations',
  'Trigger Based Campaign': 'Trigger Based',
  'Form - Survey': 'Formulaire – Questionnaire',
  'Form Survey': 'Formulaire – Questionnaire',
  'Add Form - Survey': 'Créer un formulaire – questionnaire',
  'In Future/Recurring Notifications': 'Notification programmée / récurrente',
  'Sent Notifications': 'Notifications envoyées',
  'WidgetSent Notifications': 'Sent Notifications',
  'Add Push Recommendation': 'Créer une recommandation push',
  'All Campaigns': 'Toutes les campagnes',
  'All Campaign Reports': 'Tous les rapports de campagnes',
  'Date Comparison': 'Comparer',
  'Compare To': 'Comparer les Périodes',
  'Widget View': 'Affichage du Widget',
  'Basket Items': 'Articles du panier',
  'Purchased Items': 'Articles achetés',
  'Form Submit': 'Formulaires envoyés',
  'Notification [shown]': 'Notification envoyée',
  'Notification [clicked]': 'Notification cliquée',
  'Notification [sent]': 'Notification Sent',
  'Notification Permission [granted]': 'Notification permission accordée',
  'Notification Permission [denied]': 'Notification permission refusée',
  'Purchase Amount': "Montant d'achat",
  Quantity: 'Quantité',
  'Payment Type': 'Mode de Paiement',
  'Cart Detail': 'Détails de la Carte',
  'Sale Time': 'Date',
  'Graphical View': 'Vue graphique',
  'Conversion Graph': 'Graphique de conversion',
  'Revenue Graph': 'Graphique des revenus',
  'Purchase Graph': 'Graphique des achats',
  'Page Graph': 'Graphique des pages vues',
  'Device Graph': 'Graphique des appareils',
  'Audience Graph': 'Graphique de visiteurs',
  'Segmentify Graph': 'Graphique Segmentify',
  Stock: 'Stock',
  'Price at Cart': 'Montant du panier',
  Order: 'Commander',
  'Export Products': 'Exporter les produits',
  'Export File Name': 'Nom du Fichier',
  'Page Selection': 'Sélection de page',
  'CSV Delimiter': 'Délimiteur CSV',
  'Self Service? See your account specific values':
    'Intégration en libre-serviceCliquez ici pour voir les valeurs spécifiques de votre compte',
  'Custom Segmentify integration requires two important values.':
    "L'intégration personnalisée de Segmentify requiert deux valeurs importantes.",
  'Your account specific keys': 'Les touches spécifiques de votre compte',
  'System Variable': 'Variable système',
  Description: 'Description',
  'Current Page': 'Page active',
  'All Pages': 'Toutes les Pages',
  'Semicolon (;)': 'Point-virgule (;)',
  'Tab (\\t)': 'Onglet (\\t)',
  'Export Bannerify Report': 'Exporter le rapport Bannerify',
  Banners: 'Bannières',
  'Grid View': 'Vue grille',
  'Funnel View': 'Vue en entonnoir',
  'Event Name': "Nom de l'événement",
  'Back To Wizard': "Retour à l'assistant",
  'Your work is not saved! Are you sure want to leave?':
    "Votre travail n'est pas sauvegardé ! Êtes-vous sûr de vouloir quitter ?",
  'There is no campaign': "Il n'y a pas de campagne",
  'Edit CSS': 'Éditer le code CSS',
  'Visitor is not': "Le visiteur n'est pas",
  New: 'Nouveau',
  Registered: 'Inscrit',
  Logged: 'Connecté',
  'Select Page': 'Sélectionner la Page',
  Type: 'Caractère',
  'Checkout Page': 'Page de paiement',
  PrivacyPolicyCta:
    "Avez-vous besoin d'ajuster votre politique en matière de cookies et de confidentialité pour utiliser Segmentify ?",
  PrivacyPolicyContent:
    "Si votre magasin en ligne est présent en Europe, vous devrez alors ajuster votre politique de confidentialité pour vous conformer aux loisde l'Union européenne (U.E). Ces lois imposent aux sites web d'obtenir le consentement des utilisateurs terminaux avant de stocker les données sur l'ordinateur du visiteur du site.",
  PrivacyPolicy: () => {
    return (
      <a
        href='https://segmentify.com/privacy-policy/'
        target='_blank'
        rel='noreferrer'
      >
        Learn More
      </a>
    );
  },

  // ======  2017.08 ======= //
  Labels: 'Labels',
  // ======= 2017.09 ========= //
  Preview: 'Prévisualisation des Notifications Push',
  "Your browser doesn't support Notification":
    'Votre navigateur ne prend pas en charge les notifications push',
  'You have to allow notifications to preview':
    'Vous devez autoriser les notifications push pour la prévisualisation',
  'Scheduled Campaigns': 'Campagnes Planifiées',
  'Purchases via Banners': 'Achats via Bannières',
  'Event Data': 'Données d’Événement',
  'You should select a promotion from suggestions':
    'Vous devez sélectionner une promotion parmi les suggestions',
  'Purchases and Items': 'Achats et Produits',

  'Recommended For You': 'Recommandé pour Vous',
  'Most Popular': 'Produits les Plus Populaires',
  'Products You Recently Viewed': 'Produits que Vous avez Récemment Consultés',
  'You May Also Like': 'Vous Aimerez Peut-être Aussi',
  'Account Statistics': 'Statistiques du Compte',
  'Checkout Success Page': 'Page de Confirmation de Commande',
  'NoActiveCheckout SuccessCampaigns': () => {
    return (
      <div>Aucune Campagne ou Page de Confirmation de Commande Active</div>
    );
  },
  'Selected For You': 'Sélectionné Pour Vous',
  'Most Popular Products in this Category':
    'Produits les plus Populaires dans cette Catégorie',
  'You Might Also Like': 'Vous Aimerez Peut-être Aussi',
  'Frequently Bought Together': 'Produits Fréquemment Achetés Ensemble',
  'Complementary Products': 'Produits Complémentaires',
  'You Recently Viewed': 'Vous avez Récemment Consulté',
  'Do you want to check once more?': 'Voulez-vous vérifier à nouveau ?',
  'Top Sellers': 'Meilleurs Vendeurs',
  'Top Sellers in this Category': 'Meilleurs Vendeurs de cette Catégorie',
  'You should select at least one page type':
    'Vous devez sélectionner au moins un type de page',
  'Select Pages': 'Sélectionner des Pages',
  'Segmented Users': 'Utilisateurs Segmentés',
  Champions: 'Rockstars',
  'Loyal Customers': 'Clients Retenus',
  'Potential Loyalist': 'Fans en devenir',
  'New Customers': 'Newbies',
  Promising: 'Newbies qui dépensent peu',
  'Customers Needing Attention': 'Fans qui s’en vont',
  'About To Sleep': 'Clients qui s’en vont',
  'At Risk': 'Rockstars qui s’en vont',
  "Can't Lose Them": 'Dépensait beaucoup par le passé',
  Hibernating: 'Clients inactifs',
  Lost: 'Lost',
  // ======= 2017.10 ========= //
  'Segment Visitor': 'Segmenter Visiteurs',
  'Unsegment Visitor': 'Dé-segmenter Visiteurs',
  'Toggle Visitor Segment': 'Alterner Segment Visiteur',
  Segmentation: 'Segmentation',
  Keyword: 'Mot-clé',
  keyword: 'mot-clé',
  keywords: 'mots-clés',
  'Search Keyword': 'Recherche de Mot-clé',
  'Add Search Keyword Recommendation':
    'Ajouter une Recommandation de Mot-Clé de Recherche',
  Count: 'Compte du Stock',
  Ratio: 'Ratio',
  'Stock Count': 'Compte du Stock',
  'Stock Ratio': 'Ratio du Stock',
  'Greater Than': 'Plus Que',
  'Less Than': 'Moins Que',
  'pages selected': 'pages sélectionnées',
  'No Results': 'Aucun Résultat',
  'You can see only last 50 results.':
    'Vous pouvez uniquement voir les 50 derniers résultats.',
  'You can see only last 50 emails.':
    'Vous pouvez uniquement voir les 50 derniers e-mails.',
  "Can't create user, user exists with same email.":
    'Impossible de créer un utilisateur, un utilisateur existe déjà avec cet e-mail',
  'categories selected': 'catégories sélectionnées',
  'category selected': 'catégorie sélectionnée',
  'brands selected': 'marques sélectionnées',
  'brand selected': 'marque sélectionnée',
  'View List': 'Afficher la Liste',
  'View Stats': 'Afficher les Stats',
  Stats: 'Stats',
  'There is no data': 'TIl n’y a pas de données',
  responses: 'réponses',
  response: 'réponse',

  // ======= 2017.11 ========= //
  'Select Products': 'Sélectionnez des Produits',
  'Search Products': 'Rechercher des Produits',
  'Add Selected Products': 'Ajouter les Produits Sélectionnés',
  'Your changes will be lost!': 'Vos changements seront perdus !',
  'Back to the defaults': 'Retour aux paramètres par défaut',
  'Other Languages': 'Autres Langues',
  Sticky: 'Fixe',
  'Banner Performance': 'Performances de la Bannière',
  'Purchased Products': 'Produits Achetés',

  // ======= Field Descriptions ========= //
  // Popup Banner
  'Enter the title that your visitors will see on the pop-up banner.':
    'Entrez le titre que vos visiteurs verront sur la bannière pop-up.',
  'Enter the URL of the image that will be used as the background image of the pop-up banner.':
    'Entrez l’URL de l’image qui sera utilisée comme image d’arrière-plan de la bannière pop-up.',
  'Enter the URL of the page that your visitors will be directed when they click on the pop-up banner.':
    'Entrez l’URL de la page vers laquelle vos visiteurs seront dirigés lorsqu’ils cliquent sur la bannière pop-up.',
  position_POPUP_BANNER:
    'Vous pouvez définir la position de la bannière pop-up sur la page.',
  verticalPosition_POPUP_BANNER:
    'Vous pouvez définir la position de la bannière pop-up en haut, au milieu ou en bas de la page.',
  horizontalPosition_POPUP_BANNER:
    'Vous pouvez définir la position de la bannière pop-up à gauche, au centre ou à droite de la page.',
  timingTitle_POPUP_BANNER:
    'Vous pouvez définir le moment et la fréquence de l’apparition de la bannière pop-up. ',
  timing_POPUP_BANNER:
    'Vous pouvez afficher le pop-up juste après une visite sur votre site Web, quelques secondes après une visite ou lorsque le visiteur s’apprête à quitter le site.',
  frequency_POPUP_BANNER:
    'Vous pouvez définir la fréquence d’affichage de la bannière pop-up. Par exemple, une fois par session.',
  audience_POPUP_BANNER:
    'Vous pouvez définir le public qui verra la bannière pop-up. Par exemple, les nouveaux visiteurs ou ceux qui reviennent sur votre site.',

  // Popup Recommendation
  'Enter the title that your visitors will see on the recommendation pop-up banner.':
    'Entrez le titre que vos visiteurs verront sur la bannière pop-up de recommandation.',
  position_POPUP_RECOMMENDATION:
    'Vous pouvez définir la position de la bannière pop-up de recommandation sur la page.',
  verticalPosition_POPUP_RECOMMENDATION:
    'Vous pouvez définir la position de la bannière pop-up de recommandation en haut, au milieu ou en bas de la page.',
  horizontalPosition_POPUP_RECOMMENDATION:
    'Vous pouvez définir la position de la bannière pop-up de recommandation à gauche, au centre ou à droite de la page.',
  timingTitle_POPUP_RECOMMENDATION:
    'Vous pouvez définir le moment et la fréquence de l’apparition de la bannière pop-up de recommandation. ',
  timing_POPUP_RECOMMENDATION:
    'Vous pouvez afficher le pop-up de recommandation juste après une visite sur votre site Web, quelques secondes après une visite ou lorsque le visiteur s’apprête à quitter le site.',
  frequency_POPUP_RECOMMENDATION:
    'Vous pouvez définir la fréquence d’affichage de la bannière pop-up de recommandation. Par exemple, une fois par session.',
  audience_POPUP_RECOMMENDATION:
    'Vous pouvez définir le public qui verra la bannière pop-up de recommandation. Par exemple, les nouveaux visiteurs ou ceux qui reviennent sur votre site.',

  // Journey Builder
  position_JOURNEY_BUILDER:
    'You can set the position of the journey builder on the page.',
  verticalPosition_JOURNEY_BUILDER:
    'You can set the position of the journey builder on top, middle or bottom of the page.',
  horizontalPosition_JOURNEY_BUILDER:
    'You can set the position of the journey builder on left, middle or right section of the page.',
  timingTitle_JOURNEY_BUILDER:
    'You can set the time and frequency of the journey builder to display. ',
  timing_JOURNEY_BUILDER:
    'You can display the journey builder right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_JOURNEY_BUILDER:
    'You can set the frequency of the journey builder to display. For example, one time in one session.',
  audience_JOURNEY_BUILDER:
    'You can set the audience of the journey builder to display. For example, to the new visitors or returning visitors.',

  // See All
  position_SEE_ALL:
    'You can set the position of the See All Campaign on the page.',
  verticalPosition_SEE_ALL:
    'You can set the position of the See All Campaign on top, middle or bottom of the page.',
  horizontalPosition_SEE_ALL:
    'You can set the position of the See All Campaign on left, middle or right section of the page.',
  timingTitle_SEE_ALL:
    'You can set the time and frequency of the See All Campaign to display. ',
  timing_SEE_ALL:
    'You can display the See All Campaign right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_SEE_ALL:
    'You can set the frequency of the See All Campaign to display. For example, one time in one session.',
  audience_SEE_ALL:
    'You can set the audience of the See All Campaign to display. For example, to the new visitors or returning visitors.',

  // Email Collection
  'Enter the title of the pop-up that will be displayed for email collection.':
    'Entrez le titre du pop-up qui s’affichera pour récupérer des e-mails.',
  'Enter the description of the pop-up that will be displayed for email collection.':
    'Entrez la description du pop-up qui s’affichera pour récupérer des e-mails.',
  'Enter the name of the information that you would like your visitors to fill in the form as a placeholder.':
    'Entrez le type d’informations que vous souhaitez que vos visiteurs remplissent dans le formulaire dans l’espace réservé.',
  "Enter the 'Call to Action' text. For example, 'Send'":
    "Entrez le texte d’« Appel à l’Action ». Par exemple, 'Envoyer'",
  "Enter the text to direct visitors to visit the page where your legal statement is published. For example, 'Terms&Conditions' ":
    "Entrez le texte pour diriger les visiteurs vers votre page mentions légales. Par exemple, 'Conditions Générales' ",
  'Enter the URL address of the page where your legal statement is published to direct your visitors.':
    'Entrez l’adresse URL de la page où vos mentions légales sont publiées pour diriger vos visiteurs.',
  styles_NEWSLETTER: 'Sélectionnez la police et la couleur d’arrière-plan.',
  'styles_Text Color': 'Sélectionnez la couleur du texte.',
  'styles_Background Color': 'Sélectionnez la couleur d’arrière-plan.',
  position_NEWSLETTER:
    'Vous pouvez définir la position de la bannière pop-up sur la page qui sera affichée pour récupérer des e-mails.',
  verticalPosition_NEWSLETTER:
    'Vous pouvez définir la position de la bannière pop-up qui sera affichée pour récupérer des e-mails en haut, au milieu ou en bas de la page.',
  horizontalPosition_NEWSLETTER:
    'Vous pouvez définir la position de la bannière pop-up pour récupérer des e-mails à droite, au centre ou à gauche.',
  timingTitle_NEWSLETTER:
    'Vous pouvez définir le moment et la fréquence d’affichage de la bannière pop-up pour récupérer des e-mails.',
  timing_NEWSLETTER:
    'Vous pouvez afficher le pop-up pour récupérer des e-mails juste après une visite sur votre site Web, quelques secondes après une visite ou lorsque le visiteur s’apprête à quitter le site.',
  frequency_NEWSLETTER:
    'Vous pouvez définir la fréquence d’affichage de la bannière pop-up pour récupérer des e-mails. Par exemple, une fois par session.',
  audience_NEWSLETTER:
    'Vous pouvez définir le public qui verra la bannière pop-up pour récupérer des e-mails. Par exemple, les nouveaux visiteurs ou ceux qui reviennent sur votre site.',

  // Form Survey
  'Enter the title that your visitors will see on the form survey pop-up banner.':
    'Entrez le titre que vos visiteurs verront sur la bannière pop-up de formulaire d’enquête.',
  'Enter the description of the form survey pop-up banner.':
    "Entrez la description de la bannière pop-up de formulaire d'enquête.",
  position_FORM:
    'Vous pouvez définir la position de la bannière pop-up de formulaire d’enquête sur la page.',
  verticalPosition_FORM:
    'Vous pouvez définir la position de la bannière pop-up de formulaire d’enquête en haut, au milieu ou en bas de la page..',
  horizontalPosition_FORM:
    'Vous pouvez définir la position de la bannière pop-up de formulaire d’enquête à gauche, au centre ou à droite de la page.',
  timingTitle_FORM:
    'Vous pouvez définir le moment et la fréquence de l’apparition de la bannière pop-up de formulaire d’enquête.',
  timing_FORM:
    'Vous pouvez afficher le pop-up de formulaire d’enquête juste après une visite sur votre site Web, quelques secondes après une visite ou lorsque le visiteur s’apprête à quitter le site.',
  frequency_FORM:
    'Vous pouvez définir la fréquence d’apparition de la bannière pop-up de formulaire d’enquête. Par exemple, une fois par session.',
  audience_FORM:
    'Vous pouvez définir le public qui verra la bannière pop-up de formulaire d’enquête. Par exemple, les nouveaux visiteurs ou ceux qui reviennent sur votre site.',

  // Hero Banner
  timingTitle_HERO_BANNER: 'You can set the time and frequency of the banner.',
  timing_HERO_BANNER:
    'You can display the banner right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_HERO_BANNER:
    'You can set the frequency of the banner to display. For example, one time in one session.',
  audience_HERO_BANNER:
    'You can set the audience of the banner to display. For example, to the new visitors or returning visitors.',

  // Push Permission
  'Enter the title that your visitors will see on the push permission.':
    'Entrez le titre que vos visiteurs verront sur la demande d’autorisation push.',
  'Enter the description of the push permission.':
    'Entrez la description de la demande d’autorisation push.',
  'Enter the URL address of the logo that will be displayed on the push permission.':
    'Entrez l’adresse URL du logo qui sera affiché sur la demande d’autorisation push.',
  position_PUSH_PERMISSION:
    'Vous pouvez définir la position de la demande d’autorisation push.',
  verticalPosition_PUSH_PERMISSION:
    'Vous pouvez définir la position de la demande d’autorisation push en haut, au milieu ou en bas de la page.',
  horizontalPosition_PUSH_PERMISSION:
    'Vous pouvez définir la position de la demande d’autorisation push à gauche, au centre ou à droite de la page.',
  timingTitle_PUSH_PERMISSION:
    'Vous pouvez définir le moment et la fréquence de la demande d’autorisation push.',
  timing_PUSH_PERMISSION:
    'Vous pouvez afficher la demande d’autorisation push après une visite sur votre site Web, quelques secondes après une visite ou lorsque le visiteur s’apprête à quitter le site.',
  frequency_PUSH_PERMISSION:
    'Vous pouvez définir la fréquence d’affichage la demande d’autorisation push. Par exemple, une fois par session.',
  "Enter the call to action text to give permission. For example, 'Allow'":
    "Entrez le texte d’appel à l’action pour l’autorisation. Par exemple, 'Autoriser'",
  "Enter the call to action text to ask for permission later again. For example, 'Remind me later'":
    'Entrez le texte d’appel à l’action pour demander à nouveau l’autorisation plus tard. Par exemple, « Me le rappeler plus tard »',

  // Push Notifications
  'Enter the title that your visitors will see on the web push notification.':
    'Entrez le titre que vos visiteurs verront sur la notification push.',
  'Enter the description of the web push notificaiton.':
    'Entrez la description de la notification push.',
  'Enter the URL address of the icon that will be displayed on the web push notification.':
    'Entrez l’adresse URL de l’icône qui sera affichée sur la notification push.',
  'Enter the URL address of the image that will be displayed on the web push notification.':
    'Entrez l’adresse URL de l’image qui sera affichée sur la notification push.',
  'Enter the URL of the page that your visitors will be directed when they click on the web push notification.':
    'Entrez l’URL de la page vers laquelle vos visiteurs seront dirigés lorsqu’ils cliquent sur la notification push.',
  scheduling_PUSH_NOTIFICATION:
    'Vous pouvez planifier et programmer l’envoi d’une notification push de recommandation.',
  audience_PUSH_NOTIFICATION:
    'Vous pouvez définir le public qui verra les notifications push de recommandation. Par exemple, les nouveaux visiteurs ou ceux qui reviennent sur votre site.',

  // Notification Bar
  'Enter the title that your visitors will see on the notification bar.':
    'Entrez le titre que vos visiteurs verront sur la barre de notification.',
  'Enter the URL address of the image that will be displayed on the notification bar.':
    'Entrez l’adresse URL de l’image qui sera affichée sur la barre de notification.',
  'Enter the URL of the page that your visitors will be directed when they click on the notification bar.':
    'Entrez l’URL de la page vers laquelle vos visiteurs seront dirigés lorsqu’ils cliquent sur la barre de notification.',
  styles_NOTIFICATION_BAR:
    'Sélectionnez la police et la couleur d’arrière-plan.',
  position_NOTIFICATION_BAR:
    'Vous pouvez définir la position de la barre de notification.',
  verticalPosition_NOTIFICATION_BAR:
    'Vous pouvez définir la position de la barre de notification en haut, au milieu ou en bas de la page.',
  horizontalPosition_NOTIFICATION_BAR:
    'Vous pouvez définir la position de la barre de notification à gauche, au centre ou à droite de la page.',
  timingTitle_NOTIFICATION_BAR:
    'Vous pouvez définir le moment et la fréquence de l’apparition de la barre de notification.',
  timing_NOTIFICATION_BAR:
    'Vous pouvez afficher la barre de notification après une visite sur votre site Web, quelques secondes après une visite ou lorsque le visiteur s’apprête à quitter le site.',
  frequency_NOTIFICATION_BAR:
    'Vous pouvez définir la fréquence d’affichage la barre de notification. Par exemple, une fois par session.',
  audience_NOTIFICATION_BAR:
    'Vous pouvez définir le public qui verra la barre de notification. Par exemple, les nouveaux visiteurs ou ceux qui reviennent sur votre site.',

  "Can't update user information, new password is same with current password":
    "Can't update user information, new password is same with current password",

  'Keep on page change': 'Keep on page change',
  'Category View': 'Category View',
  'Category Name': 'Category Name',
  impressions: 'impression',
  'Completed Campaigns': 'Campagnes Terminées',
  'Conversion Rate': 'Taux de conversion',
  Pages: 'Pages',
  'Views Referred From Adwords': 'Views Referred From Adwords',
  'Views Referred From Retargeting': 'Views Referred From Retargeting',
  'Views Referred From Price Comparison Sites':
    'Views Referred From Price Comparison Sites',
  'Views Referred From Affiliates': 'Views Referred From Affiliates',
  'Views Referred From Referrals': 'Views Referred From Referrals',

  'Will be sent at': 'Will be sent at',
  'Last 7 Days': 'Last 7 Days',
  'The campaign was successfully archived.':
    'The campaign was successfully archived.',
  'Shuffle Promotions': 'Shuffle Promotions',

  'You must change the end date in order to save campaign.':
    'You must change the end date in order to save campaign.',
  Feature: 'Feature',

  'Impression / View / Click': 'Impression / View / Click',
  Items: 'Items',
  'The percentage of products that have been purchased after being clicked in a Segmentify Recommendation widget.':
    'le pourcentage de produits qui ont été achetés via les Widgets de Recommendations de Segmentify',

  'There are no records to show.': 'There are no records to show.',
  'You should make at least one selection from Segmentify Intelligent or Static algorithms':
    'You should make at least one selection from Segmentify Intelligent or Static algorithms',
  'New Comers': 'New Comers',
  'All Rocks': 'All Rocks',
  "All Recommends from all products in your website's catalog with an intelligent sorting ":
    "All Recommends from all products in your website's catalog with an intelligent sorting ",
  // Widget/Promotion/Search Keywrods Desc&Info
  Widget_Desc:
    "Create personal recommendation campaigns with Segmentify's intelligent and static algorithms.",
  Promotion_Desc:
    "Personalize the Special Offers you display in your website with Segmentify's Promotion campaigns.",
  'Search-Keyword_Desc':
    "Find out the top searched keywords in your website and recommend them so your customer's can find what they want more easily.",

  'NoActivePopup RecommendationCampaigns': () => {
    return (
      <div>
        To continue making personalized recommendations on your website, you can
        use popup recommendations on any page type. Click ‘Add Custom Widget’
        below to create a new popup recommendation widget.
      </div>
    );
  },
  'NoActiveJourney BuilderCampaigns': () => {
    return (
      <div>
        <p>
          When you need a journey which created uniquely for each of your
          customers, you can define journey builder popups.
        </p>
        <p>
          Please select journey builder below to{' '}
          <strong>add new journey builder popup.</strong>
        </p>
      </div>
    );
  },
  Before_Search_Input:
    "Attract your customers with Segmentify's intelligent algorithms even before they make a search.",

  // FACETED SEARCH
  General: 'Général',
  Language: 'Langue',
  FACETED_SEARCH_PRODUCT_DESC:
    "Créez une expérience de recherche à facettes personnalisée, à l'aide de filtres avancés",
};

export default fr;
