import React from 'react';
import { connect } from 'react-redux';

import { status } from '../../constants/datamaps/segment';
import { t } from '../../system/ui';

import { segmentActions } from '../../actions';
import { Radio, RadioGroup } from '../fields';

class SegmentStatusFilters extends React.Component {
  constructor(props) {
    super(props);

    this.toggleStatus = this.toggleStatus.bind(this);
  }

  toggleStatus(value) {
    segmentActions.filterSegments(value);
  }

  componentWillUnmount() {
    segmentActions.filterSegments('ACTIVE');
  }

  render() {
    return (
      <div className='status-filters'>
        <RadioGroup
          name='status'
          selectedValue={this.props.status}
          onChange={this.toggleStatus}
          className='parent-filters'
        >
          {Object.keys(status).map(item => {
            return <Radio key={item} value={item} label={t(status[item])} />;
          })}
        </RadioGroup>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  status: store.segment.status,
});

export default connect(mapStatesToProps)(SegmentStatusFilters);
