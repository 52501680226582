import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { getFilterOptions } from '../../../../modules/shop-the-look/ajax';
import { decodeHtml } from '../../../../system/string';
import Icons from '../../../icons';
import { t } from '../../../../system/ui';
import './AttributeComponents.scss';

const Brand = props => {
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState('');

  const onChange = selectedValue => {
    const selectedBrand = selectedValue !== undefined ? selectedValue : brand;
    setBrand(selectedBrand);

    props.onSelectChange('brand', selectedBrand, props.id, props.segmentType);
  };

  const fetchBrands = () => {
    getFilterOptions('brand').then(res => {
      const sorted = res.sort();
      const selectedBrands = [];
      const fetchedBrands = [];
      sorted.forEach(sortedLabel => {
        fetchedBrands.push({
          value: sortedLabel,
          label: decodeHtml(sortedLabel),
        });
      });
      if (props.values.label !== undefined) {
        fetchedBrands.push({
          value: props.values.label.value[0],
          label: decodeHtml(props.values.label.value[0]),
        });
        props.values.label?.value?.forEach(propsBrand => {
          fetchedBrands.push({
            value: propsBrand,
            label: decodeHtml(propsBrand),
            title: decodeHtml(propsBrand),
          });
        });
      }
      if (props.values.brand !== undefined) {
        props.values.brand.value?.forEach(propsBrand => {
          fetchedBrands.push({
            value: propsBrand,
            label: decodeHtml(propsBrand),
            title: decodeHtml(propsBrand),
          });
          selectedBrands.push({
            value: propsBrand,
            label: decodeHtml(propsBrand),
            title: decodeHtml(propsBrand),
          });
        });
      }
      setBrands(fetchedBrands);
      setBrand(selectedBrands);
    });
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  return (
    <div className='sfy-color'>
      <div className='sfy-color-left'>
        <div className='sfy-color-icoText'>
          <span className='sfy-color-icon'>
            <Icons name='star' color='#787774' />
          </span>
          <div className='sfy-color-titleSelectMain'>
            <div className='sfy-color-titleSelect'>
              <span className='sfy-color-title'>{t('Brand')}</span>
            </div>
          </div>

          <Select
            value={brand}
            options={brands}
            name='label'
            clearable={false}
            onChange={onChange}
            className='criteria-field criteria-field-category'
            placeholder={t('Select Brand')}
            multi
            shouldKeyDownEventCreateNewOption={e => {
              switch (e.keyCode) {
                case 9:
                case 13:
                  return !0;
                default:
                  return !1;
              }
            }}
          />
        </div>
      </div>
      <a
        onClick={() =>
          props.onFilterRemove(props.id, props.segmentType, props.type)
        }
        className='sfy-color-actions'
      >
        <Icons name='crossCircle' />
      </a>
    </div>
  );
};

export default Brand;
