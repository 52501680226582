export const PromotionReducers = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_PROMOTIONS_LIST':
      return {
        ...state,
        promotionList: payload,
      };
    case 'SET_PROMOTION':
      return {
        ...state,
        promotion: payload,
      };
    case 'RESET_PROMOTION':
      return {
        ...state,
        promotion: {
          title: '',
          image: undefined,
          mImage: undefined,
          code: '',
          url: '',
          mUrl: '',
          productIds: [],
          categories: [],
          brands: [],
          labels: [],
          startDate: '',
          endDate: '',
        },
      };
    case 'DELETE_PROMOTION':
      const newPromotionList = state.promotionList
        .filter((promotion, index) => index !== payload)
        .map((promotion, index) => ({ ...promotion, index }));
      return {
        ...state,
        promotionList: newPromotionList,
      };
    default:
      return state;
  }
};
