/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import React from 'react';
import { clone } from 'system/object';
import { uiActions } from 'actions';
import TypoReplacement from './settings.search.typo.repl';
import TypoExclusion from './TypoExclusion';

export default class TypoSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addTypoTriggered: false,
      campaign: {
        typoCorrection: {},
        nonTypoKeywords: [],
      },
    };
  }

  componentDidMount() {
    this.cloneCampaign(this.state.campaign, this.props.campaign);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.campaign.typoCorrection) !==
        JSON.stringify(this.props.campaign.typoCorrection) ||
      JSON.stringify(prevProps.campaign.nonTypoKeywords) !==
        JSON.stringify(this.props.campaign.nonTypoKeywords)
    ) {
      this.cloneCampaign(this.state.campaign, this.props.campaign);
    }

    if (!prevState.addTypoTriggered && this.state.addTypoTriggered) {
      this.setState({
        addTypoTriggered: false,
      });
    }
  }

  cloneCampaign = (currentCampaign, newCampaign) => {
    const stateClone = clone(currentCampaign);
    const campaignClone = clone(newCampaign);
    stateClone.typoCorrection = campaignClone.typoCorrection ?? {};
    stateClone.nonTypoKeywords = campaignClone.nonTypoKeywords ?? [];
    this.setState({
      campaign: stateClone,
    });
  };

  getFuzzyInfo = (campaign, typo) => {
    return {
      beforeFuzzy: campaign.typoCorrection.typoTolerance?.fuzzyDefs?.find(
        _fuzzy => _fuzzy.val === typo - 1,
      ),
      fuzzy: campaign.typoCorrection.typoTolerance?.fuzzyDefs?.find(
        _fuzzy => _fuzzy.val === typo,
      ),
      afterFuzzy: campaign.typoCorrection.typoTolerance?.fuzzyDefs?.find(
        _fuzzy => _fuzzy.val === typo + 1,
      ),
      beforeShouldExists: typo !== 1,
      afterShouldExists: typo !== 2,
    };
  };

  clickHandler = typo => {
    this.setState(prevState => {
      const prevStateClone = clone(prevState.campaign);
      const {
        beforeFuzzy,
        fuzzy,
        afterFuzzy,
        beforeShouldExists,
        afterShouldExists,
      } = this.getFuzzyInfo(prevStateClone, typo);
      if (fuzzy) {
        if (!afterShouldExists || !afterFuzzy) {
          const array = prevStateClone.typoCorrection.typoTolerance.fuzzyDefs;
          const index = array.indexOf(fuzzy);
          array.splice(index, 1);
          uiActions.formEdited();
        }
      } else if (
        !beforeShouldExists ||
        (beforeFuzzy &&
          beforeFuzzy.max !== null &&
          beforeFuzzy.min !== null &&
          beforeFuzzy.min >= 0 &&
          beforeFuzzy.min < beforeFuzzy.max)
      ) {
        prevStateClone.typoCorrection.typoTolerance =
          prevStateClone.typoCorrection.typoTolerance ?? {};
        prevStateClone.typoCorrection.typoTolerance.fuzzyDefs =
          prevStateClone.typoCorrection.typoTolerance.fuzzyDefs ?? [];
        prevStateClone.typoCorrection.typoTolerance.fuzzyDefs.push({
          min: !beforeShouldExists ? 3 : beforeFuzzy.max + 1,
          max: !beforeShouldExists ? 12 : null,
          val: typo,
        });
        uiActions.formEdited();
      }
      return {
        campaign: prevStateClone,
      };
    });
  };

  onTypeExclusionChange = value => {
    uiActions.formEdited();
    this.setState(prevState => {
      const prevStateClone = clone(prevState.campaign);
      prevStateClone.nonTypoKeywords = value;
      return {
        campaign: prevStateClone,
      };
    });
  };

  onFieldChange = (value, typo, isMin) => {
    uiActions.formEdited();
    this.setState(prevState => {
      const prevStateClone = clone(prevState.campaign);
      const { fuzzyDefs } = prevStateClone.typoCorrection.typoTolerance;
      const integerValue = parseInt(value);
      const isNaN = Number.isNaN(integerValue);
      const fuzzy = fuzzyDefs.find(_fuzzy => _fuzzy.val === typo);
      fuzzy[isMin ? 'min' : 'max'] = isNaN ? null : integerValue;
      if (fuzzyDefs.length > 1 && typo === 1 && !isMin) {
        const fuzzy2 = fuzzyDefs.find(_fuzzy => _fuzzy.val === 2);
        if (fuzzy2) {
          fuzzy2.min = isNaN ? null : fuzzy.max + 1;
        }
      }
      return {
        campaign: prevStateClone,
      };
    });
  };

  getUpdatedCampaign = () => {
    return this.state.campaign;
  };

  onRemove = (language, index) => {
    uiActions.formEdited();
    this.setState(prevState => {
      const prevStateClone = clone(prevState.campaign);
      const typoReplace =
        prevStateClone.typoCorrection.typoReplaceMap[language];
      typoReplace.splice(index, 1);
      return {
        campaign: prevStateClone,
      };
    });
  };

  onAdd = language => {
    uiActions.formEdited();
    this.setState(prevState => {
      const prevStateClone = clone(prevState.campaign);
      prevStateClone.typoCorrection.typoReplaceMap =
        prevStateClone.typoCorrection.typoReplaceMap ?? {};
      prevStateClone.typoCorrection.typoReplaceMap[language] =
        prevStateClone.typoCorrection.typoReplaceMap[language] ?? [];
      prevStateClone.typoCorrection.typoReplaceMap[language].push({});
      return {
        campaign: prevStateClone,
        addTypoTriggered: true,
      };
    });
  };

  onChange = (typoReplaceItem, language) => {
    uiActions.formEdited();
    this.setState(prevState => {
      const prevStateClone = clone(prevState.campaign);
      const typoReplace =
        prevStateClone.typoCorrection.typoReplaceMap[language];
      let foundItem = null;
      for (let i = 0; i < typoReplace.length; i += 1) {
        i === typoReplaceItem.id && (foundItem = typoReplace[i]);
      }
      foundItem.target = typoReplaceItem.leftValue
        ? typoReplaceItem.leftValue.value ?? null
        : null;
      foundItem.typos = typoReplaceItem.rightValues.map(
        rightValue => rightValue.value,
      );
      return {
        campaign: prevStateClone,
      };
    });
  };

  getToleranceComponent = typo => {
    const { campaign } = this.state;
    const {
      fuzzy,
      beforeFuzzy,
      afterFuzzy,
      afterShouldExists,
    } = this.getFuzzyInfo(campaign, typo);
    const label = typo === 1 ? 'One Typo' : 'Two Typo';
    const isChecked = !!fuzzy;

    return (
      <li className='wizard-criterion wizard-item-draggable wizard-item-cards-draggable'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '100px' }}>{label}</div>
          <label
            className='switch action-button-1'
            style={{ marginRight: '10px' }}
          >
            <input type='checkbox' checked={isChecked} />
            <span
              name='firstAction'
              className='slider round'
              onClick={() => this.clickHandler(typo)}
            />
          </label>
          {typo !== 2 && (
            <>
              <div>for min</div>
              <input
                style={{ width: '50px', textAlign: 'center' }}
                type='number'
                name='input'
                value={fuzzy?.min ?? ''}
                required
                readOnly={!isChecked}
                onChange={e => this.onFieldChange(e.target.value, typo, true)}
              />
              <div>and max</div>
              <input
                style={{ width: '50px', textAlign: 'center' }}
                type='number'
                name='input'
                value={fuzzy?.max ?? ''}
                readOnly={!isChecked}
                onChange={e => this.onFieldChange(e.target.value, typo, false)}
              />
              <div>characters</div>
            </>
          )}
          {typo === 2 && (
            <>
              <div>for more than</div>
              <input
                style={{ width: '50px', textAlign: 'center' }}
                type='number'
                name='input'
                value={beforeFuzzy?.max && isChecked ? beforeFuzzy.max : ''}
                readOnly
                onChange={e => this.onFieldChange(e.target.value, typo, false)}
              />
              <div>characters</div>
            </>
          )}
        </div>
        {this.getErrorComponent(typo)}
      </li>
    );
  };

  getErrorComponent = typo => {
    const { campaign } = this.state;
    const { fuzzy } = this.getFuzzyInfo(campaign, typo);
    const isChecked = !!fuzzy;
    if (isChecked && fuzzy.val !== 2) {
      let errorMessage = null;
      if (fuzzy.min === null) {
        errorMessage = 'Min is required';
      } else if (fuzzy.val === 1) {
        if (fuzzy.min < 0) {
          errorMessage = 'Min must be greater than or equal to zero';
        } else if (fuzzy.max !== null && fuzzy.min >= fuzzy.max) {
          errorMessage = 'Max must be greater than min';
        }
      }
      return errorMessage ? (
        <span className='item-error'>
          <span className='item-error'>{errorMessage}</span>
        </span>
      ) : null;
    }
    return null;
  };

  render() {
    return (
      <>
        <ol className='form-elements'>
          <div className='wizard-cards'>
            <div>
              <li className='item-title-field'>
                <div className='wizard-comp' style={{ padding: '0 20px' }}>
                  <div>
                    <li
                      className='wizard-criterion search__synonym-item-group search__m_t_20'
                      id='createable-0'
                    >
                      <span>Typo Tolerance</span>
                      <p className='wizard-comp-description search__m_t_20'>
                        Please provide typo tolerance values for each
                      </p>
                      {this.getToleranceComponent(1)}
                      {this.getToleranceComponent(2)}
                    </li>
                  </div>
                </div>
              </li>
            </div>
            <TypoReplacement
              campaign={this.state.campaign}
              onRemove={this.onRemove}
              onAdd={this.onAdd}
              onChange={this.onChange}
              addTypoTriggered={this.state.addTypoTriggered}
            />
            <TypoExclusion
              campaign={this.state.campaign}
              onChange={this.onTypeExclusionChange}
            />
          </div>
        </ol>
      </>
    );
  }
}
