import React, { Component } from 'react';
import { connect } from 'react-redux';
import { t } from '../../system/ui';
import { Select } from '../fields';
import Tooltip from '../tooltip';
import Icons from '../icons';
import {
  getSegmentationList,
  getReachableUser,
  getSegmentUser,
} from '../../modules/account/ajax';

const options = [
  { label: t('Visitor is in'), value: true },
  { label: t('Visitor is not in'), value: false },
];

class MPAudience extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: [],
      segments: [],
      selectedSegment: '',
      totalReachableUser: 0,
      totalSegmentUser: 0,
    };

    this.onInclusionChange = this.onInclusionChange.bind(this);
    this.onSegmentChange = this.onSegmentChange.bind(this);
    this.setSegments = this.setSegments.bind(this);
  }

  componentDidMount() {
    this.setSegments(this.props.audience);

    if (this.props.audience && this.props.audience.length > 0) {
      this.setTotalReachableUser();
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.audience !== newProps.audience) {
      this.setSegments(newProps.audience);
    }
    if (newProps.audience && newProps.audience.length > 0) {
      this.setTotalReachableUser();
    }
  }

  onInclusionChange(id, selectedOption) {
    this.setState({
      filters: this.state.filters.map(filter => {
        return filter.id === id
          ? { ...filter, include: selectedOption.value }
          : filter;
      }),
    });
  }

  onSegmentChange(id, selectedOption) {
    if (selectedOption && selectedOption.value !== '') {
      this.setSegmentedUser(selectedOption.value);
      this.setTotalReachableUser();
    }
    this.setState({
      filters: this.state.filters.map(filter => {
        return filter.id === id
          ? {
              ...filter,
              segment: selectedOption ? selectedOption.value : '',
            }
          : filter;
      }),
      selectedSegment: selectedOption ? selectedOption.value : '',
    });
  }

  setSegments(audience) {
    const userSegments = [];
    getSegmentationList().then(response => {
      const sorted = response.sort();
      sorted.map(item => {
        if (item.segment.status === 'ACTIVE') {
          userSegments.push({
            value: item.segment.id,
            label: item.segment.name,
          });
        }
      });
      this.update(audience, userSegments);
    });
  }

  setSegmentedUser(segment) {
    getSegmentUser(segment).then(response => {
      this.setState({
        totalSegmentUser: response || 0,
      });
    });
  }

  setTotalReachableUser() {
    getReachableUser().then(response => {
      this.setState({
        totalReachableUser: response || 0,
      });
    });
  }

  update(audience, segment) {
    const obj = {
      filters:
        audience && audience.length
          ? audience.map(filter => {
              return {
                ...filter,
                id: Math.floor(Math.random() * 10000) + 1,
              };
            })
          : [
              {
                type: 'SEGMENT',
                segment: '',
                include: true,
                id: Math.floor(Math.random() * 10000) + 1,
              },
            ],
    };

    if (obj.filters[0].segment !== '') {
      obj.selectedSegment = obj.filters[0].segment;
    }

    if (obj.selectedSegment && obj.selectedSegment !== '') {
      this.setSegmentedUser(obj.selectedSegment);
    }

    if (segment) {
      obj.segments = segment;
    }
    this.setState({
      filters: obj.filters,
      segments: obj.segments,
      selectedSegment: obj.selectedSegment,
    });
  }

  render() {
    const segments = this.state.segments.map(segment => {
      if (segment.label === 'Sleeping Opportunists') {
        return {
          ...segment,
          label: 'Discount Seekers',
        };
      }

      return segment;
    });

    const hasRouterState = window.location.state ? window.location.state : {};
    const tooltipText = 'You can set the audience of the push notification';
    const visitorIsNotIn = !this.state.filters[0]?.include;
    const calculatedSegmentUsers =
      this.state.totalReachableUser - this.state.totalSegmentUser;
    return (
      <div className='mp-field-wrapper'>
        <h3 className='wizard-input-type-title'>
          <Icons name='target' />
          {t('Target Audience')}
          <span className='field-tooltip-icon'>
            <Tooltip content={tooltipText} alignment='left'>
              <i className='icon-info' role='presentation'>
                <span className='for-screenreader-only' />
              </i>
            </Tooltip>
          </span>
        </h3>
        {this.state.filters.map(filter => {
          return [
            <div key={filter.id} className='email-mapping'>
              <Select
                className='item-field x-large'
                options={options}
                value={filter.include}
                label='Condition'
                onChange={this.onInclusionChange.bind(null, filter.id)}
              />
              <Select
                value={
                  hasRouterState.smartSegmentId && this.props.isPush
                    ? segments.find(option => {
                        return option.value === hasRouterState.smartSegmentId;
                      })
                    : filter.segment
                }
                options={segments}
                clearable
                className='item-field x-large segment-select'
                creatable={false}
                searchable
                label='Segment'
                onChange={this.onSegmentChange.bind(null, filter.id)}
              />
            </div>,
          ];
        })}

        {this.state.selectedSegment && this.state.selectedSegment !== '' && (
          <div className='email-mapping'>
            <label className='item item-label'>
              Total Reachable Users <br />
              {this.state.totalReachableUser}
            </label>
            <label className='item item-label' style={{ margin: '0 20px' }}>
              Segment Users <br />
              {visitorIsNotIn
                ? calculatedSegmentUsers > 0
                  ? calculatedSegmentUsers
                  : 0
                : this.state.totalSegmentUser}
            </label>
          </div>
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
  MPAudience,
);
