"use strict";

import { createAction } from "../system/store";

export const updateSwitchLanguage = region =>
  createAction("UPDATE_SWITCH_LANGUAGE", region);

export default {
  updateSwitchLanguage,
};
