import React from 'react';

/**
 * @name ErrorText
 * @description Error text component
 * @param { string } text - Error text to display in the component
 * @param { string } el - element default type is inline you can change it passing block props
 * @param { string } classProps - class name to add to the component default is 'item-error'
 * @returns {JSX.Element}
 * @constructor
 */
const ErrorText = ({
  text = 'This field is required',
  el,
  classProps = 'item-error',
}) => {
  return (
    <>
      {el === 'block' ? (
        <p className={classProps}>{text}</p>
      ) : (
        <span className={classProps}>{text}</span>
      )}
    </>
  );
};

export default ErrorText;
