export const filterAttributeTypes = {
  color: {
    title: 'Color',
    icon: 'palette',
    iconColor: '#547494',
    isSvg: true,
    attributeType: 'color',
  },
  gender: {
    title: 'Gender',
    icon: 'excludeGender',
    iconColor: '#787774',
    attributeType: 'gender',
  },
  label: {
    title: 'Label',
    icon: 'excludeLabel',
    iconColor: '#787774',
    attributeType: 'label',
  },
  size: {
    title: 'Size',
    icon: 'excludeCustom',
    iconColor: '#787774',
    attributeType: 'size',
  },
  stock: {
    title: 'Stock',
    icon: 'stock',
    iconColor: '#787779',
    attributeType: 'stock',
  },
  priceRange: {
    title: 'Price Range',
    icon: 'dollar',
    iconColor: '#787779',
    attributeType: 'priceRange',
  },
  product: {
    title: 'Product',
    icon: 'product',
    iconColor: '#787779',
    attributeType: 'product',
  },
  brand: {
    title: 'Brand',
    icon: 'star',
    iconColor: '#787779',
    attributeType: 'brand',
  },
  custom: {
    title: 'Custom',
    icon: 'excludeCustom',
    iconColor: '#787774',
    attributeType: 'custom',
  },
};
