/**
 * Created by mehmetyurtar on 6/12/18.
 */
import React from "react";

class IconRead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <g>
          <path
            d="M49.1,50.9c0,0.9-0.8,1.7-1.7,1.7H13.1c-0.9,0-1.7-0.8-1.7-1.7V16.6c0-0.9,0.8-1.7,1.7-1.7H32v-3.4H13.1
			c-2.8,0-5.1,2.3-5.1,5.1v34.3c0,2.8,2.3,5.1,5.1,5.1h34.3c2.9,0,5.2-2.3,5.1-5.1V32h-3.4V50.9z"
          />
          <path d="M22.2,26.6L20,28.6c0,0,7.7,8,8.8,9.1c1.1,0.9,2.1,0,2.1,0l26.9-27.4l-2.1-2.1L29.8,34.4L22.2,26.6z" />
        </g>
      </svg>
    );
  }
}

export default IconRead;
