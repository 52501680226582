import React from 'react';
import Icons from '../../../../../icons';
import classes from './ErrorContent.module.scss';

/**
 * @description ErrorContent
 * @param {object} props.uiFriendlyErrorMarkers
 * @returns {JSX.Element}
 */

export const ErrorContent = ({ uiFriendlyErrorMarkers }) => {
  // eslint-disable-next-line consistent-return
  const IconHandler = name => {
    switch (name) {
      case 'html':
        return <Icons name='htmlIcon' height={20} width={20} />;
      case 'javascript':
        return <Icons name='jsIcon' height={20} width={20} />;
      case 'css':
        return <Icons name='cssIcon' height={20} width={20} />;
      default:
    }
  };

  return (
    <>
      {uiFriendlyErrorMarkers.map((marker, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i}>
          {/* eslint-disable-next-line react/no-array-index-key */}
          {Object?.keys(marker.message)?.map((key, j) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={j}>
              <div className={classes['error-header']}>
                {IconHandler(key)}{' '}
                <span className={classes['line-text']}>Error at Line</span>[
                {marker.startLineNumber} - {marker.endLineNumber}]{' '}
                <span className={classes['line-text']}>Description:</span>
              </div>
              {marker.message[key].map((error, k) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={k}>
                  {error.code} - {error.message}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
