import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import DropzoneComponent from 'react-dropzone-component';
import classNames from 'classnames';
import { TextField, TextArea, Select } from '../components/fields';
import { t } from '../system/ui';

import { modalActions, uiActions } from '../actions';
import { contactUs } from './upload/ajax';

const feedbackOptions = [
  { value: 'BUG', label: 'Bug/Error' },
  { value: 'TRAINING', label: 'Training/Meeting Request' },
  { value: 'NEW_CAMPAIGN', label: 'Help with New Campaign' },
];
const productOptions = [
  { value: 'GROWTH', label: 'Growth Solution' },
  { value: 'SEARCH', label: 'Search & Discovery' },
  { value: 'CROSS_CHANNEL', label: 'Channel Marketing' },
  { value: 'ANALYTICS', label: 'Segmentify Analytics' },
];

let dropzoneObj;
const dropzoneConfig = { postUrl: 'no-url' };
const djsConfig = {
  addRemoveLinks: true,
  autoProcessQueue: false,
  acceptedFiles:
    'image/jpeg,image/png,image/gif,video/mp4,video/mov,video/mpeg',
  iconFiletypes: ['.jpeg', '.png', '.gif'],
  maxFiles: 1,
};

const schema = yup
  .object({
    type: yup.object({
      value: yup.string().required(),
      label: yup.string().required(),
    }),
    productType: yup.object({
      value: yup.string().required(),
      label: yup.string().required(),
    }),
    title: yup.string().required(),
    message: yup.string().required(),
  })
  .required();

const ContactUsModal = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: '',
      productType: '',
      title: '',
      message: '',
    },
  });

  const dropzoneHandlers = () => {
    return {
      init: dropzone => {
        // eslint-disable-next-line
        dropzoneObj = dropzone;
      },
      addedfile: () => {
        window.dispatchEvent(new Event('resize'));
      },
    };
  };

  const onSubmit = async data => {
    uiActions.isLoading();
    let payload = {
      type: data.type.value,
      productType: data.productType.value,
      title: data.title,
      message: data.message,
    };

    if (dropzoneObj.getAcceptedFiles().length > 0) {
      const fileToUpload = await dropzoneObj.getAcceptedFiles()[0];

      if (fileToUpload) {
        payload = {
          ...payload,
          file: fileToUpload,
        };
      }
    }

    const formData = new FormData();

    Object.keys(payload).forEach(key => {
      formData.append(key, payload[key]);
    });

    await contactUs(formData, response => {
      uiActions.isLoaded();
      if (response) {
        modalActions.closeModal();
        modalActions.openModal({
          title: t('Thank You'),
          content: () => (
            <p>
              {t(
                'Your request has been recorded. Customer Success Team will contact you soon.',
              )}
            </p>
          ),
          className: 'feedback-modal',
        });
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name='type'
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            className='item-stacked is-select one-whole'
            options={feedbackOptions}
            label='Type'
            error={errors.type}
            placeholder='Please select...'
            required
          />
        )}
      />
      <Controller
        name='productType'
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            className='item-stacked is-select one-whole'
            options={productOptions}
            label='Product'
            error={errors.productType}
            placeholder='Please select...'
            required
          />
        )}
      />
      <Controller
        name='title'
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className='item-stacked one-whole'
            label='Title'
            error={errors.title}
            required
          />
        )}
      />
      <Controller
        name='message'
        control={control}
        render={({ field }) => (
          <TextArea
            {...field}
            className='item-stacked one-whole'
            label='Description'
            error={errors.message}
            required
          />
        )}
      />
      <DropzoneComponent
        config={dropzoneConfig}
        djsConfig={djsConfig}
        eventHandlers={dropzoneHandlers()}
      >
        <i
          className='icon-plus'
          style={{
            color: '#dedbe8',
            fontSize: '32px',
            zIndex: -1,
            position: 'relative',
          }}
        />
      </DropzoneComponent>
      <button
        className={classNames('tertiary-action one-whole', {
          disabled: isSubmitting || !isValid,
        })}
        type='submit'
        style={{
          marginTop: '10px',
        }}
      >
        {t('Submit')}
      </button>
    </form>
  );
};

export default ContactUsModal;
