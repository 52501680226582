import React from 'react';
import { Link, withRouter } from 'react-router';
import classnames from 'classnames';
import LoaderPlaceholder from '../../widget/loader-placeholder';
import Icon from '../../icon';
import { t } from '../../../system/ui';
import { facetedSearchRequest } from '../../../actions/faceted-search';
import ExperimentationListItem from './ExperimentationListItem';
import ExperimentationSummary from '../definition/ExperimentationSummary';
import { getRandomColor } from '../definition/ExperimentationDefinitionPanel';

export function getModuleByPath(modulePath) {
  let module = '';
  // TODO this switch conditions will be expanded once more integration is added.
  if (modulePath === 'search') {
    module = 'SEARCH';
  } else if (modulePath === 'recommendations') {
    module = 'RECOMMENDATION';
  } else if (modulePath === 'behavioural-targeting') {
    module = 'BT';
  }
  return module;
}

class ExperimentationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      experiments: [],
      showPreview: false,
      values: {},
    };
  }

  componentDidMount() {
    this.retrieveExperimentationList();
  }

  getLoading() {
    return (
      <div id='hSLsrch'>
        <div style={{ marginTop: '80px' }}>
          <LoaderPlaceholder />
        </div>
      </div>
    );
  }

  retrieveExperimentationList = () => {
    const { route } = this.props;
    const modulePath = route.path.substring(1, route.path.length);
    const moduleQueryParam = `&module=${getModuleByPath(modulePath)}`;
    facetedSearchRequest('experimentation/list', null, moduleQueryParam)
      .get()
      .then(experiments => this.setState({ experiments }));
  };

  onClose = () => {
    this.setState({
      showPreview: false,
      values: {},
    });
  };

  onFinishExperimentSuccess = () => {
    this.setState(
      {
        showPreview: false,
        values: {},
      },
      () => this.retrieveExperimentationList(),
    );
  };

  onPreview = values => {
    for (const variation of values.variations) {
      let randomColor = getRandomColor();
      while (
        values.variations.find(variation => variation.color === randomColor)
      ) {
        randomColor = getRandomColor();
      }
      variation.color = randomColor;
    }
    this.setState({
      values,
      showPreview: true,
    });
  };

  getCreateButton = () => {
    const { route } = this.props;
    return (
      <div className='available-types available-widgets available-widgets-mail'>
        <div className='available-type-selection'>
          <span key='searchbox-customization'>
            <Link
              to={`/experimentation${route.path}/create`}
              className='available-type available-type-custom'
            >
              <span className='available-type-wireframe'>
                <Icon name='plus' size='2x' />
                <span>Start an A/B Test</span>
              </span>
            </Link>
          </span>
        </div>
      </div>
    );
  };

  renderExperiments = (experiments, campaignStatus) => {
    const subtitleClass = classnames('page-subtitle', {
      passive: campaignStatus !== 'ACTIVE',
    });
    return (
      <div className='widget-list-has-items'>
        <h3 className={subtitleClass}>
          {campaignStatus === 'ACTIVE' ? t('Live Tests') : t('Completed Tests')}
          <span style={{ marginLeft: 5 }}>({experiments.length})</span>
        </h3>
        <ul className='widget-list'>
          {experiments.map(experiment => (
            <ExperimentationListItem
              experiment={experiment}
              onPreview={this.onPreview}
              retrieveExperimentationList={this.retrieveExperimentationList}
            />
          ))}
        </ul>
      </div>
    );
  };

  render() {
    const { values, experiments, showPreview } = this.state;
    const liveExperiments = experiments.filter(
      experiment => experiment.status === 'ACTIVE',
    );
    const completedExperiments = experiments.filter(
      experiment => experiment.status === 'COMPLETED',
    );
    const { route } = this.props;
    const modulePath = route.path.substring(1, route.path.length);
    return (
      <div id='hSlTsrch'>
        <h2 className='page-title' style={{ marginBottom: '0' }}>
          A/B Test
        </h2>
        <div className='page-content-wrapper'>{this.getCreateButton()}</div>
        {liveExperiments.length !== 0 &&
          this.renderExperiments(liveExperiments, 'ACTIVE')}
        {completedExperiments.length !== 0 &&
          this.renderExperiments(completedExperiments, 'COMPLETED')}
        {showPreview && (
          <ExperimentationSummary
            mode='preview'
            values={values}
            onClose={this.onClose}
            onFinishExperimentSuccess={this.onFinishExperimentSuccess}
            module={getModuleByPath(modulePath)}
          />
        )}
      </div>
    );
  }
}

export default withRouter(ExperimentationList);
