import React, { useState } from 'react';
import Editor from '@monaco-editor/react';
import { t } from '../../../../system/ui';
import { Loader } from '../../../../components/sfy-components/module-exports';
import { editorOptions } from '../../../../components/sfy-components/CodeEditor/constants/editor-options';

function RedirectSettings({
  redirectionState,
  redirectionJs,
  updateRedirectState,
  updateRedirectJs,
}) {
  const [activateRedirection, setActivateRedirection] = useState(
    redirectionState,
  );
  const [redirectJsCode, setRedirectJsCode] = useState(redirectionJs);
  const toggleActivateRedirection = () => {
    updateRedirectState(!redirectionState);
    setActivateRedirection(!activateRedirection);
  };

  const handleEditorChange = value => {
    updateRedirectJs(value);
    setRedirectJsCode(value);
  };

  return (
    <>
      <label className='item item-stacked is-checkbox'>
        <input
          type='checkbox'
          checked={activateRedirection === true}
          onChange={toggleActivateRedirection}
        />
        <span className='item-label'>
          {t('Activate redirection to search result page')}
        </span>
      </label>
      {activateRedirection && (
        <Editor
          height='50vh'
          theme='vs-dark'
          defaultLanguage='javascript'
          value={redirectJsCode}
          onChange={handleEditorChange}
          loading={<Loader />}
          options={editorOptions}
        />
      )}
    </>
  );
}

export default RedirectSettings;
