/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import isEqual from 'lodash/isEqual';
import DropzoneComponent from 'react-dropzone-component';
import { uiActions } from '../../../actions';
import { uploadFileWaitUrl } from '../../upload/ajax';
import { TextField } from '../../../components/fields';
import Icons from '../../../components/icons';
import Tooltip from '../../../components/tooltip';
import { pushList } from '../../../constants/datamaps/push';
import { getAccount } from '../../auth/user';
import { t } from '../../../system/ui';

let dropzoneObj;
const componentConfig = { postUrl: 'no-url' };
const djsConfig = {
  addRemoveLinks: false,
  autoProcessQueue: false,
  acceptedFiles: 'image/jpeg,image/png',
  createImageThumbnails: false,
};
const methods = ['URL', 'Upload'];
class IconWithUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      name: 'text',
      imgSrc: '',
      additionalIcons: {},
      method: this.props.campaign.type !== 'FLY' ? 'Company Logo' : 'URL',
      disabled: false,
    };

    this.account = getAccount();

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.setDropzoneHandlers = this.setDropzoneHandlers.bind(this);
    this.changeImage = this.changeImage.bind(this);
    this.onSelectMethod = this.onSelectMethod.bind(this);
  }

  componentDidMount() {
    if (this.props.additionalIcons) {
      this.setState({
        additionalIcons: this.props.additionalIcons,
      });
    }
    const text = this.props.campaign.icon;
    this.updateValue(text);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    const text = newProps.campaign.icon;
    if (!isEqual(this.props.campaign, newProps.campaign)) {
      this.updateValue(text);
    }

    if (this.props.disableFields !== newProps.disableFields) {
      this.updateValue(text, newProps.disableFields);
    }

    if (this.props.selectedLanguage !== newProps.selectedLanguage) {
      this.updateValue(
        this.state.additionalIcons[newProps.selectedLanguage] || '',
        undefined,
        newProps.selectedLanguage,
      );
    }

    if (!isEqual(this.props.additionalIcons, newProps.additionalIcons)) {
      this.setState({
        additionalIcons: newProps.additionalIcons,
      });
    }
  }

  onFieldChange = e => {
    this.updateValue(e.target.value);
    this.triggerOnIconChanged(e.target.value);
    uiActions.formEdited();
  };

  onSelectMethod(method, e) {
    e.preventDefault();
    this.setState(
      prevState => ({
        method,
        imgSrc: method === 'Company Logo' ? '' : prevState.fileName,
      }),
      () => {
        if (method === 'URL') {
          this.setState({
            isURL: true,
          });
        }

        let imageName = this.state.imgSrc;
        if (method === 'Company Logo') {
          imageName = '{{push:icon}}';
        }
        if (method === 'Product Image') {
          imageName = '{{product:icon}}';
        }
        this.triggerOnIconChanged(imageName);
      },
    );
  }

  setDropzoneHandlers() {
    return {
      init: dropzone => {
        dropzoneObj = dropzone;
      },
      addedfile: file => {
        window.dispatchEvent(new Event('resize'));
        this.setState(
          {
            imgSrc: '/assets/images/loading.gif',
            fileName: '/assets/images/loading.gif',
          },
          () => {
            const reader = new FileReader();
            reader.onload = () => {
              const fileToUpload = dropzoneObj.getAcceptedFiles()[
                dropzoneObj.getAcceptedFiles().length - 1
              ];
              const fileQuery = {};
              fileQuery.feedBackFile = fileToUpload;
              if (fileToUpload.size / (1024 * 1024) < 5) {
                uploadFileWaitUrl(fileQuery, 'PUSH', response => {
                  const replacedResponse = response.replace(/['"]+/g, '');
                  if (response) {
                    this.setState(
                      {
                        file,
                        imgSrc: replacedResponse,
                        fileName: replacedResponse,
                      },
                      () => this.triggerOnIconChanged(replacedResponse),
                    );
                  }
                });
              } else {
                this.setState(
                  {
                    imgSrc: '',
                    fileName: '',
                  },
                  () => {
                    uiActions.notifyRequestError({
                      statusText:
                        'File is too big to upload! Please try another file below 5MB',
                    });

                    this.triggerOnIconChanged('');
                  },
                );
              }
            };
            reader.readAsDataURL(file);
          },
        );
      },
    };
  }

  updateValue(newValue, disabled, newSelectedLanguage) {
    if (disabled === undefined) {
      let method = this.state.method || 'Company Logo';
      if (
        newValue !== '{{product:icon}}' &&
        newValue !== '{{push:icon}}' &&
        newValue !== undefined &&
        newValue !== ''
      ) {
        method = 'URL';
      }

      if (newValue !== undefined && newValue === '{{product:icon}}') {
        method = 'Product Image';
      }

      const selectedLanguage =
        newSelectedLanguage || this.props.selectedLanguage;

      if (
        newValue !== '{{product:icon}}' &&
        newValue !== '{{push:icon}}' &&
        this.props.additionalIcons &&
        selectedLanguage
      ) {
        method = 'URL';
      }

      this.setState({
        imgSrc: newValue === '{{push:icon}}' ? '' : newValue,
        fileName: newValue === '{{push:icon}}' ? '' : newValue,
        method: newValue === '{{push:icon}}' ? 'Company Logo' : method,
        disabled: false,
      });
    } else if (disabled) {
      this.setState({
        imgSrc: '',
        fileName: '',
        method: 'Company Logo',
        disabled: true,
      });
    } else {
      this.setState({
        imgSrc: '',
        fileName: '',
        method: 'URL',
        disabled: false,
      });
    }
  }

  updateAdditionalIcons(newValue) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newAdditionalIcons = this.state.additionalIcons || {};
    newAdditionalIcons[this.props.selectedLanguage] =
      newValue || this.state.imgSrc;
    this.setState({
      additionalIcons: newAdditionalIcons,
    });
    this.props.updateStateValue('additionalIcons', newAdditionalIcons);
  }

  triggerOnIconChanged(newValue) {
    if (this.props.onIconChanged) {
      const { method } = this.state;
      let image;

      if (method === 'Company Logo') {
        if (this.props.campaign.type === 'ABANDONED_CART') {
          image = '{{push:icon}}';
        } else {
          image = this.state.imgSrc;
        }
      } else if (method === 'Product Image') {
        image = '{{product:icon}}';
      } else {
        this.state.isURL = true;
        image = this.state.imgSrc;
      }
      this.props.onIconChanged({
        image,
      });
    }

    if (this.props.additionalIcons) {
      this.updateAdditionalIcons(newValue);
    }
  }

  // TODO FIX THIS!!!
  // ABANDONMENT_CART state'nin senkron calismasinda bir problem vardi, simdilik bu sekilde kirli bir cozum uyguladik
  // this.setState asenkron calistigi icin bu problemi yasadigimizi dusunuyoruz
  changeImage(e) {
    if (this.props.campaign.type === 'ABANDONED_CART') {
      this.state.imgSrc = e.target.value.trim();
      this.state.imgSrc = e.target.value.trim();
    } else {
      this.setState({
        imgSrc: e.target.value.trim(),
        fileName: e.target.value.trim(),
      });
    }
    this.triggerOnIconChanged(e.target.value.trim());
  }

  render() {
    let imgComp;
    let latestMethods =
      pushList.indexOf(this.props.campaign.type) > -1 ||
      this.props.campaign.type === 'FLY'
        ? methods.concat('Product Image')
        : methods;
    if (this.props.campaign.type !== 'RECOMMENDATION') {
      latestMethods = latestMethods.filter(item => item !== 'Company Logo');
      latestMethods.unshift('Company Logo');
    }
    if (
      this.state.imgSrc &&
      (this.state.imgSrc.indexOf('http') > -1 ||
        this.state.imgSrc.indexOf('loading') > -1)
    ) {
      imgComp = (
        <img
          src={this.state.imgSrc}
          onError={e => {
            e.target.src = '/images/layer.png';
          }}
          alt={this.state.fileName}
          onLoad={e => {
            e.target.style.display = 'block';
          }}
        />
      );
    } else if (
      this.state.imgSrc === '{{push:icon}}' ||
      this.state.method === 'Company Logo'
    ) {
      if (
        this.account.pushConfiguration &&
        this.account.pushConfiguration.defaultIcon
      ) {
        imgComp = (
          <img src={this.account.pushConfiguration.defaultIcon} alt='icon' />
        );
      } else {
        imgComp = <img src='/images/layer.png' alt='icon' />;
      }
    } else {
      imgComp = <img src='/images/layer.png' alt='icon' />;
    }
    return (
      <li className='item-title-field one-whole icon-with-upload page-forms'>
        {imgComp}
        <span className='item-label'>
          {this.props.options.label}
          <span className='recommended-text' />
          {this.props.options.tooltip && (
            <span style={{ position: 'absolute', top: '-3px' }}>
              <Tooltip
                content={this.props.options.tooltipContent}
                alignment='left'
              >
                <i className='icon-info' role='presentation'>
                  <span className='for-screenreader-only' />
                </i>
              </Tooltip>
            </span>
          )}
          {this.props.campaignType === 'FLY' ? (
            <span style={{ position: 'absolute', top: '-2px', right: '-45px' }}>
              <Tooltip
                content={
                  <span>
                    You can choose to send one static image as URL or Upload
                    section to promote your campaign, instead of sending product
                    images automatically.
                  </span>
                }
                alignment='right'
              >
                <Icons name='question' width='16' height='16' color='#e7eef3' />
              </Tooltip>
            </span>
          ) : (
            <>
              <span
                style={{ position: 'absolute', top: '-2px', right: '-45px' }}
              >
                <Tooltip
                  content={
                    <span>
                      192x192 - jpg, png, gif, webp, ico, cur, bmp (Not
                      supported: svg or gif animations.)
                    </span>
                  }
                  alignment='right'
                >
                  <Icons
                    name='question'
                    width='16'
                    height='16'
                    color='#e7eef3'
                  />
                </Tooltip>
              </span>
              <span
                style={{ position: 'absolute', top: '-2px', right: '-65px' }}
              >
                <Tooltip
                  content={<span>The maximum file size is 5 MB for icons</span>}
                  alignment='right'
                >
                  <Icons
                    name='question'
                    width='16'
                    height='16'
                    color='#e7eef3'
                  />
                </Tooltip>
              </span>
            </>
          )}{' '}
        </span>
        <ul
          className='whitelist-radio'
          style={{
            marginTop: '10px',
            marginLeft: '98px',
            opacity: this.state.disabled ? '0.3' : '1',
            pointerEvents: this.state.disabled ? 'none' : 'initial',
            paddingBottom: '4px',
          }}
        >
          {latestMethods.map(method => {
            if (
              method === 'Product Image' &&
              this.props.campaign.type === 'FLY'
            ) {
              return (
                <li
                  onClick={this.onSelectMethod.bind(this, method)}
                  key={method}
                  style={{
                    display: 'inline',
                    fontSize: '14px',
                    opacity: this.props.algorithmSelected ? '1' : '0.3',
                    pointerEvents: this.props.algorithmSelected
                      ? 'initial'
                      : 'none',
                    paddingBottom: '3px',
                  }}
                >
                  <Icons
                    name={
                      this.state.method === method &&
                      this.props.algorithmSelected
                        ? 'radioSelected'
                        : 'radioEmpty'
                    }
                    width='14'
                    height='14'
                  />
                  <span>{method}</span>
                </li>
              );
            }
            if (
              method === 'Product Image' &&
              this.props.campaign.type === 'ABANDONED_CART' &&
              this.props.campaign.isCoupon
            ) {
              return (
                <li
                  onClick={this.onSelectMethod.bind(this, method)}
                  key={method}
                  style={{
                    display: 'none',
                    fontSize: '14px',
                    opacity: '0',
                    pointerEvents: 'none',
                    paddingBottom: '3px',
                  }}
                >
                  <Icons
                    name={
                      this.state.method === method &&
                      this.props.algorithmSelected
                        ? 'radioSelected'
                        : 'radioEmpty'
                    }
                    width='14'
                    height='14'
                  />
                  <span>{method}</span>
                </li>
              );
            }

            return (
              <li
                onClick={this.onSelectMethod.bind(this, method)}
                key={method}
                style={{
                  display: 'inline',
                  fontSize: '14px',
                }}
              >
                <Icons
                  name={
                    this.state.method === method
                      ? 'radioSelected'
                      : 'radioEmpty'
                  }
                  width='14'
                  height='14'
                />
                <span>{method}</span>
              </li>
            );
          })}
        </ul>
        <li className='item-title-field'>
          {this.state.method === 'URL' && (
            <TextField
              name='image'
              className='item-stacked one-whole icon-with-upload-input'
              value={this.state.fileName}
              required={this.props.campaign.type !== 'FLY'}
              onChange={this.changeImage}
              validation={{ mustacheOrUrl: true }}
              placeholder={this.props.options.placeholder}
              isURL={this.state.isURL}
            />
          )}
        </li>
        {this.state.method === 'Upload' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              paddingLeft: '90px',
            }}
          >
            <DropzoneComponent
              config={componentConfig}
              eventHandlers={this.setDropzoneHandlers()}
              djsConfig={djsConfig}
            >
              Upload
              <i className='icon-upload' />
            </DropzoneComponent>
            {this.state.imgSrc === '' && (
              <span
                className='item-error has-error'
                style={{
                  display: 'block',
                  marginTop: '-15px',
                  marginLeft: '5px',
                }}
              >
                {t('This field is required')}
              </span>
            )}
          </div>
        )}
      </li>
    );
  }
}

IconWithUpload.defaultProps = {
  options: {
    label: 'Description',
    name: 'text',
    tooltip: false,
    tooltipContent: '',
  },
};

const MapStatesToProps = () => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  IconWithUpload,
);
