import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { modalActions } from '../../../actions';
import {
  listOperators,
  operatorColumns,
  stringOperators,
} from '../../../constants/datamaps/rule-based-segmentation';
import FilterDetail from './FilterDetail';

const defaultMainStatementByAnyType = filterType => {
  const columnName = operatorColumns[filterType];
  let operatorName = stringOperators.equals.value;

  if (columnName === operatorColumns.categoryOperator) {
    operatorName = listOperators.is.value;
  }

  const defaultStatement = {
    dataType: 'purchase',
    column: columnName,
    operator: operatorName,
    value: 'any',
  };

  return defaultStatement;
};

const CriteriaField = ({ criteriaTypeName, statements }) => {
  return (
    <div className='rbs-preview-criteria-field'>
      <div className='rbs-preview-criteria-title-field'>
        <span className='criteria-title-constant'>Criteria</span>
        <span className='criteria-title-criteriaType'>{criteriaTypeName}</span>
      </div>
      <div className='rbs-preview-criteria-content-field'>
        <div className='rbs-criteria-filters'>
          {Object.keys(statements).map((filterType, index) => {
            const tempStatements = { ...statements };
            const isFirst = index === 0;

            // If it's first statement and it's empty, fill it with default value
            if (isFirst && tempStatements[filterType].length === 0) {
              const defaultStatement = defaultMainStatementByAnyType(
                filterType,
              );
              tempStatements[filterType] = [defaultStatement];
            }

            const filterProps = {
              filterType,
              statements: tempStatements,
              isFirst,
            };
            return (
              <FilterDetail {...filterProps} key={index + ' ' + filterType} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

CriteriaField.propTypes = {
  criteriaTypeName: PropTypes.string.isRequired,
  statements: PropTypes.shape({}).isRequired,
};

const CriteriaGroupItem = ({ criteriaData, groupItem, groupIndex }) => {
  const groupOperator = criteriaData?.operator;
  const criteriaOptions = useSelector(state => state.ds.criteriaOptions.data);

  useEffect(() => {
    modalActions.calculateModalHeight();
  }, []);

  const andOrOperator = (type, index, operator) => {
    if (index === 0) {
      return null;
    }

    return (
      <div className={`preview-and-or-operator ${type}-operator`}>
        <div className='preview-and-or-operator-line' />
        <div className='preview-operator'>
          <span>{operator.toUpperCase()}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      {andOrOperator('group', groupIndex, groupOperator)}

      <div className='rbs-wizard-group'>
        {groupItem?.criterias.map((criteriaItem, criteriaIndex) => {
          const criteriaOperator = groupItem?.operator;
          const criteriaTypeName = criteriaOptions.find(
            criteriaType => criteriaType.value === criteriaItem.criteriaType,
          )?.label;

          return (
            <>
              {andOrOperator('criteria', criteriaIndex, criteriaOperator)}
              <CriteriaField
                criteriaTypeName={criteriaTypeName}
                statements={criteriaItem.statements}
              />
            </>
          );
        })}
      </div>
    </>
  );
};

CriteriaGroupItem.propTypes = {
  criteriaData: PropTypes.shape({
    operator: PropTypes.string,
    criteriaGroups: PropTypes.shape([]),
  }).isRequired,
  groupItem: PropTypes.shape({
    operator: PropTypes.string,
    criterias: PropTypes.shape([]),
  }).isRequired,
  groupIndex: PropTypes.number.isRequired,
};

export default CriteriaGroupItem;
