/**
 * @author Bilal Cinarli
 */

"use strict";

// button classes
export const primaryAction = "primary-action";
export const secondaryAction = "secondary-action";
export const tertiaryAction = "tertiary-action";
export const defaultButton = "button";
export const buttonProgressAnimation = "page-progress";

// fields
export const fieldItem = "item";
export const fieldLabel = "item-label";
export const textField = "text-field";

// ui classes
export const current = "current";
