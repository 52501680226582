import React from 'react';
import classes from './TopBrands.module.scss';
import { t } from '../../../../../system/ui';
import Tooltip from '../../../../tooltip';

const TopBrands = ({ brands }) => {
  return (
    <div className={classes['top-brands-container']}>
      <div className={classes['top-brands-header']}>{t('Top Brands')}</div>
      <div className={classes['top-brands-content']}>
        {brands?.topBrands?.length > 0 ? (
          brands?.topBrands.map((brand, index) => (
            <Tooltip content={brand} aligment='left'>
              <div className={classes['top-brands-item']} key={index}>
                {`${index + 1} - ${brand}`}
              </div>
            </Tooltip>
          ))
        ) : (
          <div className={classes['top-brands-no-data']}>
            {t('There is no data for now')}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBrands;
