import { t } from '../../system/ui';

export const types = [
  { value: 'PRE_POC', label: t('Pre-POC') },
  { value: 'POC', label: t('POC') },
  { value: 'STAGING', label: t('Staging') },
  { value: 'ONHOLD', label: t('Onhold') },
  { value: 'PAYING', label: t('Paying') },
  { value: 'FREEMIUM', label: t('Freemium') },
  { value: 'FAILED', label: t('Failed') },
  { value: 'CHURN', label: t('Churn') },
  { value: 'PANEL_RESTRICTED', label: t('Restricted') },
];

export const tokenMailList = [
  { value: 'analytics@segmentify.com', label: 'analytics@segmentify.com' },
  {
    value: 'analytics.tr@segmentify.com',
    label: 'analytics.tr@segmentify.com',
  },
  {
    value: 'analytics.tr2@segmentify.com',
    label: 'analytics.tr2@segmentify.com',
  },
  {
    value: 'analytics.eu@segmentify.com',
    label: 'analytics.eu@segmentify.com',
  },
  {
    value: 'analytics.eu2@segmentify.com',
    label: 'analytics.eu2@segmentify.com',
  },
];
export const dataCenterV3 = [
  {
    label: 'Equinix(Turkey)',
    value: 'eq',
  },
  {
    label: 'Google Cloud(Europe)',
    value: 'gc',
  },
];

export const SEGMENTIFY_TOKEN_V3 = 'SegmentifyAuthenticationTokenV3';
export const timeZones = [
  { value: 'Asia/Kabul', label: 'Asia/Kabul' },
  { value: 'Europe/Mariehamn', label: 'Europe/Mariehamn' },
  { value: 'Europe/Tirane', label: 'Europe/Tirane' },
  { value: 'Africa/Algiers', label: 'Africa/Algiers' },
  { value: 'Pacific/Pago_Pago', label: 'Pacific/Pago_Pago' },
  { value: 'Europe/Andorra', label: 'Europe/Andorra' },
  { value: 'Africa/Luanda', label: 'Africa/Luanda' },
  { value: 'America/Anguilla', label: 'America/Anguilla' },
  { value: 'Antarctica/Casey', label: 'Antarctica/Casey' },
  { value: 'Antarctica/Davis', label: 'Antarctica/Davis' },
  {
    value: 'Antarctica/DumontDUrville',
    label: 'Antarctica/DumontDUrville',
  },
  { value: 'Antarctica/Mawson', label: 'Antarctica/Mawson' },
  { value: 'Antarctica/McMurdo', label: 'Antarctica/McMurdo' },
  { value: 'Antarctica/Palmer', label: 'Antarctica/Palmer' },
  { value: 'Antarctica/Rothera', label: 'Antarctica/Rothera' },
  { value: 'Antarctica/Syowa', label: 'Antarctica/Syowa' },
  { value: 'Antarctica/Troll', label: 'Antarctica/Troll' },
  { value: 'Antarctica/Vostok', label: 'Antarctica/Vostok' },
  { value: 'America/Antigua', label: 'America/Antigua' },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: 'America/Argentina/Buenos_Aires',
  },
  {
    value: 'America/Argentina/Catamarca',
    label: 'America/Argentina/Catamarca',
  },
  {
    value: 'America/Argentina/Cordoba',
    label: 'America/Argentina/Cordoba',
  },
  { value: 'America/Argentina/Jujuy', label: 'America/Argentina/Jujuy' },
  {
    value: 'America/Argentina/La_Rioja',
    label: 'America/Argentina/La_Rioja',
  },
  {
    value: 'America/Argentina/Mendoza',
    label: 'America/Argentina/Mendoza',
  },
  {
    value: 'America/Argentina/Rio_Gallegos',
    label: 'America/Argentina/Rio_Gallegos',
  },
  { value: 'America/Argentina/Salta', label: 'America/Argentina/Salta' },
  {
    value: 'America/Argentina/San_Juan',
    label: 'America/Argentina/San_Juan',
  },
  {
    value: 'America/Argentina/San_Luis',
    label: 'America/Argentina/San_Luis',
  },
  {
    value: 'America/Argentina/Tucuman',
    label: 'America/Argentina/Tucuman',
  },
  {
    value: 'America/Argentina/Ushuaia',
    label: 'America/Argentina/Ushuaia',
  },
  { value: 'Asia/Yerevan', label: 'Asia/Yerevan' },
  { value: 'America/Aruba', label: 'America/Aruba' },
  { value: 'Antarctica/Macquarie', label: 'Antarctica/Macquarie' },
  { value: 'Australia/Adelaide', label: 'Australia/Adelaide' },
  { value: 'Australia/Brisbane', label: 'Australia/Brisbane' },
  { value: 'Australia/Broken_Hill', label: 'Australia/Broken_Hill' },
  { value: 'Australia/Currie', label: 'Australia/Currie' },
  { value: 'Australia/Darwin', label: 'Australia/Darwin' },
  { value: 'Australia/Eucla', label: 'Australia/Eucla' },
  { value: 'Australia/Hobart', label: 'Australia/Hobart' },
  { value: 'Australia/Lindeman', label: 'Australia/Lindeman' },
  { value: 'Australia/Lord_Howe', label: 'Australia/Lord_Howe' },
  { value: 'Australia/Melbourne', label: 'Australia/Melbourne' },
  { value: 'Australia/Perth', label: 'Australia/Perth' },
  { value: 'Australia/Sydney', label: 'Australia/Sydney' },
  { value: 'Europe/Vienna', label: 'Europe/Vienna' },
  { value: 'Asia/Baku', label: 'Asia/Baku' },
  { value: 'America/Nassau', label: 'America/Nassau' },
  { value: 'Asia/Bahrain', label: 'Asia/Bahrain' },
  { value: 'Asia/Dhaka', label: 'Asia/Dhaka' },
  { value: 'America/Barbados', label: 'America/Barbados' },
  { value: 'Europe/Minsk', label: 'Europe/Minsk' },
  { value: 'Europe/Brussels', label: 'Europe/Brussels' },
  { value: 'America/Belize', label: 'America/Belize' },
  { value: 'Africa/Porto-Novo', label: 'Africa/Porto-Novo' },
  { value: 'Atlantic/Bermuda', label: 'Atlantic/Bermuda' },
  { value: 'Asia/Thimphu', label: 'Asia/Thimphu' },
  { value: 'America/La_Paz', label: 'America/La_Paz' },
  { value: 'America/Kralendijk', label: 'America/Kralendijk' },
  { value: 'Europe/Sarajevo', label: 'Europe/Sarajevo' },
  { value: 'Africa/Gaborone', label: 'Africa/Gaborone' },
  { value: 'America/Araguaina', label: 'America/Araguaina' },
  { value: 'America/Bahia', label: 'America/Bahia' },
  { value: 'America/Belem', label: 'America/Belem' },
  { value: 'America/Boa_Vista', label: 'America/Boa_Vista' },
  { value: 'America/Campo_Grande', label: 'America/Campo_Grande' },
  { value: 'America/Cuiaba', label: 'America/Cuiaba' },
  { value: 'America/Eirunepe', label: 'America/Eirunepe' },
  { value: 'America/Fortaleza', label: 'America/Fortaleza' },
  { value: 'America/Maceio', label: 'America/Maceio' },
  { value: 'America/Manaus', label: 'America/Manaus' },
  { value: 'America/Noronha', label: 'America/Noronha' },
  { value: 'America/Porto_Velho', label: 'America/Porto_Velho' },
  { value: 'America/Recife', label: 'America/Recife' },
  { value: 'America/Rio_Branco', label: 'America/Rio_Branco' },
  { value: 'America/Santarem', label: 'America/Santarem' },
  { value: 'America/Sao_Paulo', label: 'America/Sao_Paulo' },
  { value: 'Indian/Chagos', label: 'Indian/Chagos' },
  { value: 'America/Tortola', label: 'America/Tortola' },
  { value: 'Asia/Brunei', label: 'Asia/Brunei' },
  { value: 'Europe/Sofia', label: 'Europe/Sofia' },
  { value: 'Africa/Ouagadougou', label: 'Africa/Ouagadougou' },
  { value: 'Africa/Bujumbura', label: 'Africa/Bujumbura' },
  { value: 'Asia/Phnom_Penh', label: 'Asia/Phnom_Penh' },
  { value: 'Africa/Douala', label: 'Africa/Douala' },
  { value: 'America/Atikokan', label: 'America/Atikokan' },
  { value: 'America/Blanc-Sablon', label: 'America/Blanc-Sablon' },
  { value: 'America/Cambridge_Bay', label: 'America/Cambridge_Bay' },
  { value: 'America/Creston', label: 'America/Creston' },
  { value: 'America/Dawson', label: 'America/Dawson' },
  { value: 'America/Dawson_Creek', label: 'America/Dawson_Creek' },
  { value: 'America/Edmonton', label: 'America/Edmonton' },
  { value: 'America/Fort_Nelson', label: 'America/Fort_Nelson' },
  { value: 'America/Glace_Bay', label: 'America/Glace_Bay' },
  { value: 'America/Goose_Bay', label: 'America/Goose_Bay' },
  { value: 'America/Halifax', label: 'America/Halifax' },
  { value: 'America/Inuvik', label: 'America/Inuvik' },
  { value: 'America/Iqaluit', label: 'America/Iqaluit' },
  { value: 'America/Moncton', label: 'America/Moncton' },
  { value: 'America/Nipigon', label: 'America/Nipigon' },
  { value: 'America/Pangnirtung', label: 'America/Pangnirtung' },
  { value: 'America/Rainy_River', label: 'America/Rainy_River' },
  { value: 'America/Rankin_Inlet', label: 'America/Rankin_Inlet' },
  { value: 'America/Regina', label: 'America/Regina' },
  { value: 'America/Resolute', label: 'America/Resolute' },
  { value: 'America/St_Johns', label: 'America/St_Johns' },
  { value: 'America/Swift_Current', label: 'America/Swift_Current' },
  { value: 'America/Thunder_Bay', label: 'America/Thunder_Bay' },
  { value: 'America/Toronto', label: 'America/Toronto' },
  { value: 'America/Vancouver', label: 'America/Vancouver' },
  { value: 'America/Whitehorse', label: 'America/Whitehorse' },
  { value: 'America/Winnipeg', label: 'America/Winnipeg' },
  { value: 'America/Yellowknife', label: 'America/Yellowknife' },
  { value: 'Atlantic/Cape_Verde', label: 'Atlantic/Cape_Verde' },
  { value: 'America/Cayman', label: 'America/Cayman' },
  { value: 'Africa/Bangui', label: 'Africa/Bangui' },
  { value: 'Africa/Ndjamena', label: 'Africa/Ndjamena' },
  { value: 'America/Punta_Arenas', label: 'America/Punta_Arenas' },
  { value: 'America/Santiago', label: 'America/Santiago' },
  { value: 'Pacific/Easter', label: 'Pacific/Easter' },
  { value: 'Asia/Shanghai', label: 'Asia/Shanghai' },
  { value: 'Asia/Urumqi', label: 'Asia/Urumqi' },
  { value: 'Indian/Christmas', label: 'Indian/Christmas' },
  { value: 'Indian/Cocos', label: 'Indian/Cocos' },
  { value: 'America/Bogota', label: 'America/Bogota' },
  { value: 'Indian/Comoro', label: 'Indian/Comoro' },
  { value: 'Pacific/Rarotonga', label: 'Pacific/Rarotonga' },
  { value: 'America/Costa_Rica', label: 'America/Costa_Rica' },
  { value: 'Europe/Zagreb', label: 'Europe/Zagreb' },
  { value: 'America/Havana', label: 'America/Havana' },
  { value: 'America/Curacao', label: 'America/Curacao' },
  { value: 'Asia/Famagusta', label: 'Asia/Famagusta' },
  { value: 'Asia/Nicosia', label: 'Asia/Nicosia' },
  { value: 'Europe/Prague', label: 'Europe/Prague' },
  { value: 'Africa/Kinshasa', label: 'Africa/Kinshasa' },
  { value: 'Africa/Lubumbashi', label: 'Africa/Lubumbashi' },
  { value: 'Europe/Copenhagen', label: 'Europe/Copenhagen' },
  { value: 'Africa/Djibouti', label: 'Africa/Djibouti' },
  { value: 'America/Dominica', label: 'America/Dominica' },
  { value: 'America/Santo_Domingo', label: 'America/Santo_Domingo' },
  { value: 'Asia/Dili', label: 'Asia/Dili' },
  { value: 'America/Guayaquil', label: 'America/Guayaquil' },
  { value: 'Pacific/Galapagos', label: 'Pacific/Galapagos' },
  { value: 'Africa/Cairo', label: 'Africa/Cairo' },
  { value: 'America/El_Salvador', label: 'America/El_Salvador' },
  { value: 'Africa/Malabo', label: 'Africa/Malabo' },
  { value: 'Africa/Asmara', label: 'Africa/Asmara' },
  { value: 'Europe/Tallinn', label: 'Europe/Tallinn' },
  { value: 'Africa/Addis_Ababa', label: 'Africa/Addis_Ababa' },
  { value: 'Atlantic/Stanley', label: 'Atlantic/Stanley' },
  { value: 'Atlantic/Faroe', label: 'Atlantic/Faroe' },
  { value: 'Pacific/Fiji', label: 'Pacific/Fiji' },
  { value: 'Europe/Helsinki', label: 'Europe/Helsinki' },
  { value: 'Europe/Paris', label: 'Europe/Paris' },
  { value: 'America/Cayenne', label: 'America/Cayenne' },
  { value: 'Pacific/Gambier', label: 'Pacific/Gambier' },
  { value: 'Pacific/Marquesas', label: 'Pacific/Marquesas' },
  { value: 'Pacific/Tahiti', label: 'Pacific/Tahiti' },
  { value: 'Indian/Kerguelen', label: 'Indian/Kerguelen' },
  { value: 'Africa/Libreville', label: 'Africa/Libreville' },
  { value: 'Africa/Banjul', label: 'Africa/Banjul' },
  { value: 'Asia/Tbilisi', label: 'Asia/Tbilisi' },
  { value: 'Europe/Berlin', label: 'Europe/Berlin' },
  { value: 'Europe/Busingen', label: 'Europe/Busingen' },
  { value: 'Africa/Accra', label: 'Africa/Accra' },
  { value: 'Europe/Gibraltar', label: 'Europe/Gibraltar' },
  { value: 'Europe/Athens', label: 'Europe/Athens' },
  { value: 'America/Danmarkshavn', label: 'America/Danmarkshavn' },
  { value: 'America/Godthab', label: 'America/Godthab' },
  { value: 'America/Scoresbysund', label: 'America/Scoresbysund' },
  { value: 'America/Thule', label: 'America/Thule' },
  { value: 'America/Grenada', label: 'America/Grenada' },
  { value: 'America/Guadeloupe', label: 'America/Guadeloupe' },
  { value: 'Pacific/Guam', label: 'Pacific/Guam' },
  { value: 'America/Guatemala', label: 'America/Guatemala' },
  { value: 'Europe/Guernsey', label: 'Europe/Guernsey' },
  { value: 'Africa/Conakry', label: 'Africa/Conakry' },
  { value: 'Africa/Bissau', label: 'Africa/Bissau' },
  { value: 'America/Guyana', label: 'America/Guyana' },
  { value: 'America/Port-au-Prince', label: 'America/Port-au-Prince' },
  { value: 'America/Tegucigalpa', label: 'America/Tegucigalpa' },
  { value: 'Asia/Hong_Kong', label: 'Asia/Hong_Kong' },
  { value: 'Europe/Budapest', label: 'Europe/Budapest' },
  { value: 'Atlantic/Reykjavik', label: 'Atlantic/Reykjavik' },
  { value: 'Asia/Kolkata', label: 'Asia/Kolkata' },
  { value: 'Asia/Jakarta', label: 'Asia/Jakarta' },
  { value: 'Asia/Jayapura', label: 'Asia/Jayapura' },
  { value: 'Asia/Makassar', label: 'Asia/Makassar' },
  { value: 'Asia/Pontianak', label: 'Asia/Pontianak' },
  { value: 'Asia/Tehran', label: 'Asia/Tehran' },
  { value: 'Asia/Baghdad', label: 'Asia/Baghdad' },
  { value: 'Europe/Dublin', label: 'Europe/Dublin' },
  { value: 'Europe/Isle_of_Man', label: 'Europe/Isle_of_Man' },
  { value: 'Asia/Jerusalem', label: 'Asia/Jerusalem' },
  { value: 'Europe/Rome', label: 'Europe/Rome' },
  { value: 'Africa/Abidjan', label: 'Africa/Abidjan' },
  { value: 'America/Jamaica', label: 'America/Jamaica' },
  { value: 'Asia/Tokyo', label: 'Asia/Tokyo' },
  { value: 'Europe/Jersey', label: 'Europe/Jersey' },
  { value: 'Asia/Amman', label: 'Asia/Amman' },
  { value: 'Asia/Almaty', label: 'Asia/Almaty' },
  { value: 'Asia/Aqtau', label: 'Asia/Aqtau' },
  { value: 'Asia/Aqtobe', label: 'Asia/Aqtobe' },
  { value: 'Asia/Atyrau', label: 'Asia/Atyrau' },
  { value: 'Asia/Oral', label: 'Asia/Oral' },
  { value: 'Asia/Qostanay', label: 'Asia/Qostanay' },
  { value: 'Asia/Qyzylorda', label: 'Asia/Qyzylorda' },
  { value: 'Africa/Nairobi', label: 'Africa/Nairobi' },
  { value: 'Pacific/Enderbury', label: 'Pacific/Enderbury' },
  { value: 'Pacific/Kiritimati', label: 'Pacific/Kiritimati' },
  { value: 'Pacific/Tarawa', label: 'Pacific/Tarawa' },
  { value: 'Asia/Kuwait', label: 'Asia/Kuwait' },
  { value: 'Asia/Bishkek', label: 'Asia/Bishkek' },
  { value: 'Asia/Vientiane', label: 'Asia/Vientiane' },
  { value: 'Europe/Riga', label: 'Europe/Riga' },
  { value: 'Asia/Beirut', label: 'Asia/Beirut' },
  { value: 'Africa/Maseru', label: 'Africa/Maseru' },
  { value: 'Africa/Monrovia', label: 'Africa/Monrovia' },
  { value: 'Africa/Tripoli', label: 'Africa/Tripoli' },
  { value: 'Europe/Vaduz', label: 'Europe/Vaduz' },
  { value: 'Europe/Vilnius', label: 'Europe/Vilnius' },
  { value: 'Europe/Luxembourg', label: 'Europe/Luxembourg' },
  { value: 'Asia/Macau', label: 'Asia/Macau' },
  { value: 'Europe/Skopje', label: 'Europe/Skopje' },
  { value: 'Indian/Antananarivo', label: 'Indian/Antananarivo' },
  { value: 'Africa/Blantyre', label: 'Africa/Blantyre' },
  { value: 'Asia/Kuala_Lumpur', label: 'Asia/Kuala_Lumpur' },
  { value: 'Asia/Kuching', label: 'Asia/Kuching' },
  { value: 'Indian/Maldives', label: 'Indian/Maldives' },
  { value: 'Africa/Bamako', label: 'Africa/Bamako' },
  { value: 'Europe/Malta', label: 'Europe/Malta' },
  { value: 'Pacific/Kwajalein', label: 'Pacific/Kwajalein' },
  { value: 'Pacific/Majuro', label: 'Pacific/Majuro' },
  { value: 'America/Martinique', label: 'America/Martinique' },
  { value: 'Africa/Nouakchott', label: 'Africa/Nouakchott' },
  { value: 'Indian/Mauritius', label: 'Indian/Mauritius' },
  { value: 'Indian/Mayotte', label: 'Indian/Mayotte' },
  { value: 'America/Bahia_Banderas', label: 'America/Bahia_Banderas' },
  { value: 'America/Cancun', label: 'America/Cancun' },
  { value: 'America/Chihuahua', label: 'America/Chihuahua' },
  { value: 'America/Hermosillo', label: 'America/Hermosillo' },
  { value: 'America/Matamoros', label: 'America/Matamoros' },
  { value: 'America/Mazatlan', label: 'America/Mazatlan' },
  { value: 'America/Merida', label: 'America/Merida' },
  { value: 'America/Mexico_City', label: 'America/Mexico_City' },
  { value: 'America/Monterrey', label: 'America/Monterrey' },
  { value: 'America/Ojinaga', label: 'America/Ojinaga' },
  { value: 'America/Tijuana', label: 'America/Tijuana' },
  { value: 'Pacific/Chuuk', label: 'Pacific/Chuuk' },
  { value: 'Pacific/Kosrae', label: 'Pacific/Kosrae' },
  { value: 'Pacific/Pohnpei', label: 'Pacific/Pohnpei' },
  { value: 'Europe/Chisinau', label: 'Europe/Chisinau' },
  { value: 'Europe/Monaco', label: 'Europe/Monaco' },
  { value: 'Asia/Choibalsan', label: 'Asia/Choibalsan' },
  { value: 'Asia/Hovd', label: 'Asia/Hovd' },
  { value: 'Asia/Ulaanbaatar', label: 'Asia/Ulaanbaatar' },
  { value: 'Europe/Podgorica', label: 'Europe/Podgorica' },
  { value: 'America/Montserrat', label: 'America/Montserrat' },
  { value: 'Africa/Casablanca', label: 'Africa/Casablanca' },
  { value: 'Africa/Maputo', label: 'Africa/Maputo' },
  { value: 'Asia/Yangon', label: 'Asia/Yangon' },
  { value: 'Africa/Windhoek', label: 'Africa/Windhoek' },
  { value: 'Pacific/Nauru', label: 'Pacific/Nauru' },
  { value: 'Asia/Kathmandu', label: 'Asia/Kathmandu' },
  { value: 'Europe/Amsterdam', label: 'Europe/Amsterdam' },
  { value: 'Pacific/Noumea', label: 'Pacific/Noumea' },
  { value: 'Pacific/Auckland', label: 'Pacific/Auckland' },
  { value: 'Pacific/Chatham', label: 'Pacific/Chatham' },
  { value: 'America/Managua', label: 'America/Managua' },
  { value: 'Africa/Niamey', label: 'Africa/Niamey' },
  { value: 'Africa/Lagos', label: 'Africa/Lagos' },
  { value: 'Pacific/Niue', label: 'Pacific/Niue' },
  { value: 'Pacific/Norfolk', label: 'Pacific/Norfolk' },
  { value: 'Asia/Pyongyang', label: 'Asia/Pyongyang' },
  { value: 'Pacific/Saipan', label: 'Pacific/Saipan' },
  { value: 'Europe/Oslo', label: 'Europe/Oslo' },
  { value: 'Asia/Muscat', label: 'Asia/Muscat' },
  { value: 'Asia/Karachi', label: 'Asia/Karachi' },
  { value: 'Pacific/Palau', label: 'Pacific/Palau' },
  { value: 'Asia/Gaza', label: 'Asia/Gaza' },
  { value: 'Asia/Hebron', label: 'Asia/Hebron' },
  { value: 'America/Panama', label: 'America/Panama' },
  { value: 'Pacific/Bougainville', label: 'Pacific/Bougainville' },
  { value: 'Pacific/Port_Moresby', label: 'Pacific/Port_Moresby' },
  { value: 'America/Asuncion', label: 'America/Asuncion' },
  { value: 'America/Lima', label: 'America/Lima' },
  { value: 'Asia/Manila', label: 'Asia/Manila' },
  { value: 'Pacific/Pitcairn', label: 'Pacific/Pitcairn' },
  { value: 'Europe/Warsaw', label: 'Europe/Warsaw' },
  { value: 'Atlantic/Azores', label: 'Atlantic/Azores' },
  { value: 'Atlantic/Madeira', label: 'Atlantic/Madeira' },
  { value: 'Europe/Lisbon', label: 'Europe/Lisbon' },
  { value: 'America/Puerto_Rico', label: 'America/Puerto_Rico' },
  { value: 'Asia/Qatar', label: 'Asia/Qatar' },
  { value: 'Africa/Brazzaville', label: 'Africa/Brazzaville' },
  { value: 'Indian/Reunion', label: 'Indian/Reunion' },
  { value: 'Europe/Bucharest', label: 'Europe/Bucharest' },
  { value: 'Asia/Anadyr', label: 'Asia/Anadyr' },
  { value: 'Asia/Barnaul', label: 'Asia/Barnaul' },
  { value: 'Asia/Chita', label: 'Asia/Chita' },
  { value: 'Asia/Irkutsk', label: 'Asia/Irkutsk' },
  { value: 'Asia/Kamchatka', label: 'Asia/Kamchatka' },
  { value: 'Asia/Khandyga', label: 'Asia/Khandyga' },
  { value: 'Asia/Krasnoyarsk', label: 'Asia/Krasnoyarsk' },
  { value: 'Asia/Magadan', label: 'Asia/Magadan' },
  { value: 'Asia/Novokuznetsk', label: 'Asia/Novokuznetsk' },
  { value: 'Asia/Novosibirsk', label: 'Asia/Novosibirsk' },
  { value: 'Asia/Omsk', label: 'Asia/Omsk' },
  { value: 'Asia/Sakhalin', label: 'Asia/Sakhalin' },
  { value: 'Asia/Srednekolymsk', label: 'Asia/Srednekolymsk' },
  { value: 'Asia/Tomsk', label: 'Asia/Tomsk' },
  { value: 'Asia/Ust-Nera', label: 'Asia/Ust-Nera' },
  { value: 'Asia/Vladivostok', label: 'Asia/Vladivostok' },
  { value: 'Asia/Yakutsk', label: 'Asia/Yakutsk' },
  { value: 'Asia/Yekaterinburg', label: 'Asia/Yekaterinburg' },
  { value: 'Europe/Astrakhan', label: 'Europe/Astrakhan' },
  { value: 'Europe/Kaliningrad', label: 'Europe/Kaliningrad' },
  { value: 'Europe/Kirov', label: 'Europe/Kirov' },
  { value: 'Europe/Moscow', label: 'Europe/Moscow' },
  { value: 'Europe/Samara', label: 'Europe/Samara' },
  { value: 'Europe/Saratov', label: 'Europe/Saratov' },
  { value: 'Europe/Simferopol', label: 'Europe/Simferopol' },
  { value: 'Europe/Ulyanovsk', label: 'Europe/Ulyanovsk' },
  { value: 'Europe/Volgograd', label: 'Europe/Volgograd' },
  { value: 'Africa/Kigali', label: 'Africa/Kigali' },
  { value: 'America/St_Barthelemy', label: 'America/St_Barthelemy' },
  { value: 'Atlantic/St_Helena', label: 'Atlantic/St_Helena' },
  { value: 'America/St_Kitts', label: 'America/St_Kitts' },
  { value: 'America/St_Lucia', label: 'America/St_Lucia' },
  { value: 'America/Marigot', label: 'America/Marigot' },
  { value: 'America/Miquelon', label: 'America/Miquelon' },
  { value: 'America/St_Vincent', label: 'America/St_Vincent' },
  { value: 'Pacific/Apia', label: 'Pacific/Apia' },
  { value: 'Europe/San_Marino', label: 'Europe/San_Marino' },
  { value: 'Africa/Sao_Tome', label: 'Africa/Sao_Tome' },
  { value: 'Asia/Riyadh', label: 'Asia/Riyadh' },
  { value: 'Africa/Dakar', label: 'Africa/Dakar' },
  { value: 'Europe/Belgrade', label: 'Europe/Belgrade' },
  { value: 'Indian/Mahe', label: 'Indian/Mahe' },
  { value: 'Africa/Freetown', label: 'Africa/Freetown' },
  { value: 'Asia/Singapore', label: 'Asia/Singapore' },
  { value: 'America/Lower_Princes', label: 'America/Lower_Princes' },
  { value: 'Europe/Bratislava', label: 'Europe/Bratislava' },
  { value: 'Europe/Ljubljana', label: 'Europe/Ljubljana' },
  { value: 'Pacific/Guadalcanal', label: 'Pacific/Guadalcanal' },
  { value: 'Africa/Mogadishu', label: 'Africa/Mogadishu' },
  { value: 'Africa/Johannesburg', label: 'Africa/Johannesburg' },
  { value: 'Atlantic/South_Georgia', label: 'Atlantic/South_Georgia' },
  { value: 'Asia/Seoul', label: 'Asia/Seoul' },
  { value: 'Africa/Juba', label: 'Africa/Juba' },
  { value: 'Africa/Ceuta', label: 'Africa/Ceuta' },
  { value: 'Atlantic/Canary', label: 'Atlantic/Canary' },
  { value: 'Europe/Madrid', label: 'Europe/Madrid' },
  { value: 'Asia/Colombo', label: 'Asia/Colombo' },
  { value: 'Africa/Khartoum', label: 'Africa/Khartoum' },
  { value: 'America/Paramaribo', label: 'America/Paramaribo' },
  { value: 'Arctic/Longyearbyen', label: 'Arctic/Longyearbyen' },
  { value: 'Africa/Mbabane', label: 'Africa/Mbabane' },
  { value: 'Europe/Stockholm', label: 'Europe/Stockholm' },
  { value: 'Europe/Zurich', label: 'Europe/Zurich' },
  { value: 'Asia/Damascus', label: 'Asia/Damascus' },
  { value: 'Asia/Taipei', label: 'Asia/Taipei' },
  { value: 'Asia/Dushanbe', label: 'Asia/Dushanbe' },
  { value: 'Africa/Dar_es_Salaam', label: 'Africa/Dar_es_Salaam' },
  { value: 'Asia/Bangkok', label: 'Asia/Bangkok' },
  { value: 'Africa/Lome', label: 'Africa/Lome' },
  { value: 'Pacific/Fakaofo', label: 'Pacific/Fakaofo' },
  { value: 'Pacific/Tongatapu', label: 'Pacific/Tongatapu' },
  { value: 'America/Port_of_Spain', label: 'America/Port_of_Spain' },
  { value: 'Africa/Tunis', label: 'Africa/Tunis' },
  { value: 'Europe/Istanbul', label: 'Europe/Istanbul' },
  { value: 'Asia/Ashgabat', label: 'Asia/Ashgabat' },
  { value: 'America/Grand_Turk', label: 'America/Grand_Turk' },
  { value: 'Pacific/Funafuti', label: 'Pacific/Funafuti' },
  { value: 'America/St_Thomas', label: 'America/St_Thomas' },
  { value: 'Africa/Kampala', label: 'Africa/Kampala' },
  { value: 'Europe/Kiev', label: 'Europe/Kiev' },
  { value: 'Europe/Uzhgorod', label: 'Europe/Uzhgorod' },
  { value: 'Europe/Zaporozhye', label: 'Europe/Zaporozhye' },
  { value: 'Asia/Dubai', label: 'Asia/Dubai' },
  { value: 'Europe/London', label: 'Europe/London' },
  { value: 'America/Adak', label: 'America/Adak' },
  { value: 'America/Anchorage', label: 'America/Anchorage' },
  { value: 'America/Boise', label: 'America/Boise' },
  { value: 'America/Chicago', label: 'America/Chicago' },
  { value: 'America/Denver', label: 'America/Denver' },
  { value: 'America/Detroit', label: 'America/Detroit' },
  {
    value: 'America/Indiana/Indianapolis',
    label: 'America/Indiana/Indianapolis',
  },
  { value: 'America/Indiana/Knox', label: 'America/Indiana/Knox' },
  { value: 'America/Indiana/Marengo', label: 'America/Indiana/Marengo' },
  {
    value: 'America/Indiana/Petersburg',
    label: 'America/Indiana/Petersburg',
  },
  {
    value: 'America/Indiana/Tell_City',
    label: 'America/Indiana/Tell_City',
  },
  { value: 'America/Indiana/Vevay', label: 'America/Indiana/Vevay' },
  {
    value: 'America/Indiana/Vincennes',
    label: 'America/Indiana/Vincennes',
  },
  { value: 'America/Indiana/Winamac', label: 'America/Indiana/Winamac' },
  { value: 'America/Juneau', label: 'America/Juneau' },
  {
    value: 'America/Kentucky/Louisville',
    label: 'America/Kentucky/Louisville',
  },
  {
    value: 'America/Kentucky/Monticello',
    label: 'America/Kentucky/Monticello',
  },
  { value: 'America/Los_Angeles', label: 'America/Los_Angeles' },
  { value: 'America/Menominee', label: 'America/Menominee' },
  { value: 'America/Metlakatla', label: 'America/Metlakatla' },
  { value: 'America/New_York', label: 'America/New_York' },
  { value: 'America/Nome', label: 'America/Nome' },
  {
    value: 'America/North_Dakota/Beulah',
    label: 'America/North_Dakota/Beulah',
  },
  {
    value: 'America/North_Dakota/Center',
    label: 'America/North_Dakota/Center',
  },
  {
    value: 'America/North_Dakota/New_Salem',
    label: 'America/North_Dakota/New_Salem',
  },
  { value: 'America/Phoenix', label: 'America/Phoenix' },
  { value: 'America/Sitka', label: 'America/Sitka' },
  { value: 'America/Yakutat', label: 'America/Yakutat' },
  { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu' },
  { value: 'Pacific/Midway', label: 'Pacific/Midway' },
  { value: 'Pacific/Wake', label: 'Pacific/Wake' },
  { value: 'America/Montevideo', label: 'America/Montevideo' },
  { value: 'Asia/Samarkand', label: 'Asia/Samarkand' },
  { value: 'Asia/Tashkent', label: 'Asia/Tashkent' },
  { value: 'Pacific/Efate', label: 'Pacific/Efate' },
  { value: 'Europe/Vatican', label: 'Europe/Vatican' },
  { value: 'America/Caracas', label: 'America/Caracas' },
  { value: 'Asia/Ho_Chi_Minh', label: 'Asia/Ho_Chi_Minh' },
  { value: 'Pacific/Wallis', label: 'Pacific/Wallis' },
  { value: 'Africa/El_Aaiun', label: 'Africa/El_Aaiun' },
  { value: 'Asia/Aden', label: 'Asia/Aden' },
  { value: 'Africa/Lusaka', label: 'Africa/Lusaka' },
  { value: 'Africa/Harare', label: 'Africa/Harare' },
];

export const languages = [
  {
    value: 'AA',
    label: 'Afar (AA)',
  },
  {
    value: 'AB',
    label: 'Abkhazian (AB)',
  },
  {
    value: 'AE',
    label: 'Avestan (AE)',
  },
  {
    value: 'AF',
    label: 'Afrikaans (AF)',
  },
  {
    value: 'AK',
    label: 'Akan (AK)',
  },
  {
    value: 'AM',
    label: 'Amharic (AM)',
  },
  {
    value: 'AN',
    label: 'Aragonese (AN)',
  },
  {
    value: 'AR',
    label: 'Arabic (AR)',
  },
  {
    value: 'AS',
    label: 'Assamese (AS)',
  },
  {
    value: 'AV',
    label: 'Avaric (AV)',
  },
  {
    value: 'AY',
    label: 'Aymara (AY)',
  },
  {
    value: 'AZ',
    label: 'Azerbaijani (AZ)',
  },
  {
    value: 'BA',
    label: 'Bashkir (BA)',
  },
  {
    value: 'BE',
    label: 'Belarusian (BE)',
  },
  {
    value: 'BG',
    label: 'Bulgarian (BG)',
  },
  {
    value: 'BH',
    label: 'Bihari languages (BH)',
  },
  {
    value: 'BM',
    label: 'Bambara (BM)',
  },
  {
    value: 'BI',
    label: 'Bislama (BI)',
  },
  {
    value: 'BN',
    label: 'Bengali (BN)',
  },
  {
    value: 'BO',
    label: 'Tibetan (BO)',
  },
  {
    value: 'BR',
    label: 'Breton (BR)',
  },
  {
    value: 'BS',
    label: 'Bosnian (BS)',
  },
  {
    value: 'CA',
    label: 'Catalan; Valencian (CA)',
  },
  {
    value: 'CE',
    label: 'Chechen (CE)',
  },
  {
    value: 'CH',
    label: 'Chamorro (CH)',
  },
  {
    value: 'CO',
    label: 'Corsican (CO)',
  },
  {
    value: 'CR',
    label: 'Cree (CR)',
  },
  {
    value: 'CS',
    label: 'Czech (CS)',
  },
  {
    value: 'CU',
    label:
      'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic (CU)',
  },
  {
    value: 'CV',
    label: 'Chuvash (CV)',
  },
  {
    value: 'CY',
    label: 'Welsh (CY)',
  },
  {
    value: 'DA',
    label: 'Danish (DA)',
  },
  {
    value: 'DE',
    label: 'German (DE)',
  },
  {
    value: 'DV',
    label: 'Divehi; Dhivehi; Maldivian (DV)',
  },
  {
    value: 'DZ',
    label: 'Dzongkha (DZ)',
  },
  {
    value: 'EE',
    label: 'Ewe (EE)',
  },
  {
    value: 'EL',
    label: 'Greek, Modern (1453-) (EL)',
  },
  {
    value: 'EN',
    label: 'English (EN)',
  },
  {
    value: 'EO',
    label: 'Esperanto (EO)',
  },
  {
    value: 'ES',
    label: 'Spanish; Castilian (ES)',
  },
  {
    value: 'ET',
    label: 'Estonian (ET)',
  },
  {
    value: 'EU',
    label: 'Basque (EU)',
  },
  {
    value: 'FA',
    label: 'Persian (FA)',
  },
  {
    value: 'FF',
    label: 'Fulah (FF)',
  },
  {
    value: 'FI',
    label: 'Finnish (FI)',
  },
  {
    value: 'FJ',
    label: 'Fijian (FJ)',
  },
  {
    value: 'FO',
    label: 'Faroese (FO)',
  },
  {
    value: 'FR',
    label: 'French (FR)',
  },
  {
    value: 'FY',
    label: 'Western Frisian (FY)',
  },
  {
    value: 'GA',
    label: 'Irish (GA)',
  },
  {
    value: 'GD',
    label: 'Gaelic; Scottish Gaelic (GD)',
  },
  {
    value: 'GL',
    label: 'Galician (GL)',
  },
  {
    value: 'GN',
    label: 'Guarani (GN)',
  },
  {
    value: 'GU',
    label: 'Gujarati (GU)',
  },
  {
    value: 'GV',
    label: 'Manx (GV)',
  },
  {
    value: 'HA',
    label: 'Hausa (HA)',
  },
  {
    value: 'HE',
    label: 'Hebrew (HE)',
  },
  {
    value: 'HI',
    label: 'Hindi (HI)',
  },
  {
    value: 'HO',
    label: 'Hiri Motu (HO)',
  },
  {
    value: 'HR',
    label: 'Croatian (HR)',
  },
  {
    value: 'HT',
    label: 'Haitian; Haitian Creole (HT)',
  },
  {
    value: 'HU',
    label: 'Hungarian (HU)',
  },
  {
    value: 'HY',
    label: 'Armenian (HY)',
  },
  {
    value: 'HZ',
    label: 'Herero (HZ)',
  },
  {
    value: 'IA',
    label: 'Interlingua (International Auxiliary Language Association) (IA)',
  },
  {
    value: 'ID',
    label: 'Indonesian (ID)',
  },
  {
    value: 'IE',
    label: 'Interlingue; Occidental (IE)',
  },
  {
    value: 'IG',
    label: 'Igbo (IG)',
  },
  {
    value: 'II',
    label: 'Sichuan Yi; Nuosu (II)',
  },
  {
    value: 'IK',
    label: 'Inupiaq (IK)',
  },
  {
    value: 'IO',
    label: 'Ido (IO)',
  },
  {
    value: 'IS',
    label: 'Icelandic (IS)',
  },
  {
    value: 'IT',
    label: 'Italian (IT)',
  },
  {
    value: 'IU',
    label: 'Inuktitut (IU)',
  },
  {
    value: 'JA',
    label: 'Japanese (JA)',
  },
  {
    value: 'JV',
    label: 'Javanese (JV)',
  },
  {
    value: 'KA',
    label: 'Georgian (KA)',
  },
  {
    value: 'KG',
    label: 'Kongo (KG)',
  },
  {
    value: 'KI',
    label: 'Kikuyu; Gikuyu (KI)',
  },
  {
    value: 'KJ',
    label: 'Kuanyama; Kwanyama (KJ)',
  },
  {
    value: 'KK',
    label: 'Kazakh (KK)',
  },
  {
    value: 'KL',
    label: 'Kalaallisut; Greenlandic (KL)',
  },
  {
    value: 'KM',
    label: 'Central Khmer (KM)',
  },
  {
    value: 'KN',
    label: 'Kannada (KN)',
  },
  {
    value: 'KO',
    label: 'Korean (KO)',
  },
  {
    value: 'KR',
    label: 'Kanuri (KR)',
  },
  {
    value: 'KS',
    label: 'Kashmiri (KS)',
  },
  {
    value: 'KU',
    label: 'Kurdish (KU)',
  },
  {
    value: 'KV',
    label: 'Komi (KV)',
  },
  {
    value: 'KW',
    label: 'Cornish (KW)',
  },
  {
    value: 'KY',
    label: 'Kirghiz; Kyrgyz (KY)',
  },
  {
    value: 'LA',
    label: 'Latin (LA)',
  },
  {
    value: 'LB',
    label: 'Luxembourgish; Letzeburgesch (LB)',
  },
  {
    value: 'LG',
    label: 'Ganda (LG)',
  },
  {
    value: 'LI',
    label: 'Limburgan; Limburger; Limburgish (LI)',
  },
  {
    value: 'LN',
    label: 'Lingala (LN)',
  },
  {
    value: 'LO',
    label: 'Lao (LO)',
  },
  {
    value: 'LT',
    label: 'Lithuanian (LT)',
  },
  {
    value: 'LU',
    label: 'Luba-Katanga (LU)',
  },
  {
    value: 'LV',
    label: 'Latvian (LV)',
  },
  {
    value: 'ME',
    label: 'Montenegrin (ME)',
  },
  {
    value: 'MG',
    label: 'Malagasy (MG)',
  },
  {
    value: 'MH',
    label: 'Marshallese (MH)',
  },
  {
    value: 'MI',
    label: 'Maori (MI)',
  },
  {
    value: 'MK',
    label: 'Macedonian (MK)',
  },
  {
    value: 'ML',
    label: 'Malayalam (ML)',
  },
  {
    value: 'MN',
    label: 'Mongolian (MN)',
  },
  {
    value: 'MR',
    label: 'Marathi (MR)',
  },
  {
    value: 'MS',
    label: 'Malay (MS)',
  },
  {
    value: 'MT',
    label: 'Maltese (MT)',
  },
  {
    value: 'MY',
    label: 'Burmese (MY)',
  },
  {
    value: 'NA',
    label: 'Nauru (NA)',
  },
  {
    value: 'NB',
    label: 'Bokmål, Norwegian; Norwegian Bokmål (NB)',
  },
  {
    value: 'ND',
    label: 'Ndebele, North; North Ndebele (ND)',
  },
  {
    value: 'NE',
    label: 'Nepali (NE)',
  },
  {
    value: 'NG',
    label: 'Ndonga (NG)',
  },
  {
    value: 'NL',
    label: 'Dutch; Flemish (NL)',
  },
  {
    value: 'NN',
    label: 'Norwegian Nynorsk; Nynorsk, Norwegian (NN)',
  },
  {
    value: 'NO',
    label: 'Norwegian (NO)',
  },
  {
    value: 'NR',
    label: 'Ndebele, South; South Ndebele (NR)',
  },
  {
    value: 'NV',
    label: 'Navajo; Navaho (NV)',
  },
  {
    value: 'NY',
    label: 'Chichewa; Chewa; Nyanja (NY)',
  },
  {
    value: 'OC',
    label: 'Occitan (post 1500); Provençal (OC)',
  },
  {
    value: 'OJ',
    label: 'Ojibwa (OJ)',
  },
  {
    value: 'OM',
    label: 'Oromo (OM)',
  },
  {
    value: 'OR',
    label: 'Oriya (OR)',
  },
  {
    value: 'OS',
    label: 'Ossetian; Ossetic (OS)',
  },
  {
    value: 'PA',
    label: 'Panjabi; Punjabi (PA)',
  },
  {
    value: 'PI',
    label: 'Pali (PI)',
  },
  {
    value: 'PL',
    label: 'Polish (PL)',
  },
  {
    value: 'PS',
    label: 'Pushto; Pashto (PS)',
  },
  {
    value: 'PT',
    label: 'Portuguese (PT)',
  },
  {
    value: 'QU',
    label: 'Quechua (QU)',
  },
  {
    value: 'RM',
    label: 'Romansh (RM)',
  },
  {
    value: 'RN',
    label: 'Rundi (RN)',
  },
  {
    value: 'RO',
    label: 'Romanian; Moldavian; Moldovan (RO)',
  },
  {
    value: 'RU',
    label: 'Russian (RU)',
  },
  {
    value: 'RW',
    label: 'Kinyarwanda (RW)',
  },
  {
    value: 'SA',
    label: 'Sanskrit (SA)',
  },
  {
    value: 'SC',
    label: 'Sardinian (SC)',
  },
  {
    value: 'SD',
    label: 'Sindhi (SD)',
  },
  {
    value: 'SE',
    label: 'Northern Sami (SE)',
  },
  {
    value: 'SG',
    label: 'Sango (SG)',
  },
  {
    value: 'SI',
    label: 'Sinhala; Sinhalese (SI)',
  },
  {
    value: 'SK',
    label: 'Slovak (SK)',
  },
  {
    value: 'SL',
    label: 'Slovenian (SL)',
  },
  {
    value: 'SM',
    label: 'Samoan (SM)',
  },
  {
    value: 'SN',
    label: 'Shona (SN)',
  },
  {
    value: 'SO',
    label: 'Somali (SO)',
  },
  {
    value: 'SQ',
    label: 'Albanian (SQ)',
  },
  {
    value: 'SR',
    label: 'Serbian (SR)',
  },
  {
    value: 'SS',
    label: 'Swati (SS)',
  },
  {
    value: 'ST',
    label: 'Sotho, Southern (ST)',
  },
  {
    value: 'SU',
    label: 'Sundanese (SU)',
  },
  {
    value: 'SV',
    label: 'Swedish (SV)',
  },
  {
    value: 'SW',
    label: 'Swahili (SW)',
  },
  {
    value: 'TA',
    label: 'Tamil (TA)',
  },
  {
    value: 'TE',
    label: 'Telugu (TE)',
  },
  {
    value: 'TG',
    label: 'Tajik (TG)',
  },
  {
    value: 'TH',
    label: 'Thai (TH)',
  },
  {
    value: 'TI',
    label: 'Tigrinya (TI)',
  },
  {
    value: 'TK',
    label: 'Turkmen (TK)',
  },
  {
    value: 'TL',
    label: 'Tagalog (TL)',
  },
  {
    value: 'TN',
    label: 'Tswana (TN)',
  },
  {
    value: 'TO',
    label: 'Tonga (Tonga Islands) (TO)',
  },
  {
    value: 'TR',
    label: 'Turkish (TR)',
  },
  {
    value: 'TS',
    label: 'Tsonga (TS)',
  },
  {
    value: 'TT',
    label: 'Tatar (TT)',
  },
  {
    value: 'TW',
    label: 'Twi (TW)',
  },
  {
    value: 'TY',
    label: 'Tahitian (TY)',
  },
  {
    value: 'UG',
    label: 'Uighur; Uyghur (UG)',
  },
  {
    value: 'UK',
    label: 'Ukrainian (UK)',
  },
  {
    value: 'UR',
    label: 'Urdu (UR)',
  },
  {
    value: 'UZ',
    label: 'Uzbek (UZ)',
  },
  {
    value: 'VE',
    label: 'Venda (VE)',
  },
  {
    value: 'VI',
    label: 'Vietnamese (VI)',
  },
  {
    value: 'VO',
    label: 'Volapük (VO)',
  },
  {
    value: 'WA',
    label: 'Walloon (WA)',
  },
  {
    value: 'WO',
    label: 'Wolof (WO)',
  },
  {
    value: 'XH',
    label: 'Xhosa (XH)',
  },
  {
    value: 'YI',
    label: 'Yiddish (YI)',
  },
  {
    value: 'YO',
    label: 'Yoruba (YO)',
  },
  {
    value: 'ZA',
    label: 'Zhuang; Chuang (ZA)',
  },
  {
    value: 'ZH',
    label: 'Chinese (ZH)',
  },
  {
    value: 'ZU',
    label: 'Zulu (ZU)',
  },
];

export const currencies = [
  {
    value: 'AED',
    label: 'United Arab Emirates Dirham (AED)',
  },

  {
    value: 'AFN',
    label: 'Afghanistan Afghani (AFN)',
  },
  {
    value: 'ALL',
    label: 'Albania Lek (ALL)',
  },
  {
    value: 'AMD',
    label: 'Armenia Dram (AMD)',
  },
  {
    value: 'ANG',
    label: 'Netherlands Antilles Guilder (ANG)',
  },
  {
    value: 'AOA',
    label: 'Angola Kwanza (AOA)',
  },
  {
    value: 'ARS',
    label: 'Argentina Peso (ARS)',
  },
  {
    value: 'AUD',
    label: 'Australia Dollar (AUD)',
  },
  {
    value: 'AWG',
    label: 'Aruba Guilder (AWG)',
  },
  {
    value: 'AZN',
    label: 'Azerbaijan Manat (AZN)',
  },
  {
    value: 'BAM',
    label: 'Bosnia and Herzegovina Convertible Marka (BAM)',
  },
  {
    value: 'BBD',
    label: 'Barbados Dollar (BBD)',
  },
  {
    value: 'BDT',
    label: 'Bangladesh Taka (BDT)',
  },
  {
    value: 'BGN',
    label: 'Bulgaria Lev (BGN)',
  },
  {
    value: 'BHD',
    label: 'Bahrain Dinar (BHD)',
  },
  {
    value: 'BIF',
    label: 'Burundi Franc (BIF)',
  },
  {
    value: 'BMD',
    label: 'Bermuda Dollar (BMD)',
  },
  {
    value: 'BND',
    label: 'Brunei Darussalam Dollar (BND)',
  },
  {
    value: 'BOB',
    label: 'Bolivia Bolíviano (BOB)',
  },
  {
    value: 'BRL',
    label: 'Brazil Real (BRL)',
  },
  {
    value: 'BSD',
    label: 'Bahamas Dollar (BSD)',
  },
  {
    value: 'BTN',
    label: 'Bhutan Ngultrum (BTN)',
  },
  {
    value: 'BWP',
    label: 'Botswana Pula (BWP)',
  },
  {
    value: 'BYN',
    label: 'Belarus Ruble (BYN)',
  },
  {
    value: 'BZD',
    label: 'Belize Dollar (BZD)',
  },
  {
    value: 'CAD',
    label: 'Canada Dollar (CAD)',
  },
  {
    value: 'CDF',
    label: 'Congo/Kinshasa Franc (CDF)',
  },
  {
    value: 'CHF',
    label: 'Switzerland Franc (CHF)',
  },
  {
    value: 'CLP',
    label: 'Chile Peso (CLP)',
  },
  {
    value: 'CNY',
    label: 'China Yuan Renminbi (CNY)',
  },
  {
    value: 'COP',
    label: 'Colombia Peso (COP)',
  },
  {
    value: 'CRC',
    label: 'Costa Rica Colon (CRC)',
  },
  {
    value: 'CUC',
    label: 'Cuba Convertible Peso (CUC)',
  },
  {
    value: 'CUP',
    label: 'Cuba Peso (CUP)',
  },
  {
    value: 'CVE',
    label: 'Cape Verde Escudo (CVE)',
  },
  {
    value: 'CZK',
    label: 'Czech Republic Koruna (CZK)',
  },
  {
    value: 'DJF',
    label: 'Djibouti Franc (DJF)',
  },
  {
    value: 'DKK',
    label: 'Denmark Krone (DKK)',
  },
  {
    value: 'DOP',
    label: 'Dominican Republic Peso (DOP)',
  },
  {
    value: 'DZD',
    label: 'Algeria Dinar (DZD)',
  },
  {
    value: 'EGP',
    label: 'Egypt Pound (EGP)',
  },
  {
    value: 'ERN',
    label: 'Eritrea Nakfa (ERN)',
  },
  {
    value: 'ETB',
    label: 'Ethiopia Birr (ETB)',
  },
  {
    value: 'EUR',
    label: 'Euro Member Countries (EUR)',
  },
  {
    value: 'FJD',
    label: 'Fiji Dollar (FJD)',
  },
  {
    value: 'FKP',
    label: 'Falkland Islands Pound (FKP)',
  },
  {
    value: 'GBP',
    label: 'United Kingdom Pound (GBP)',
  },
  {
    value: 'GEL',
    label: 'Georgia Lari (GEL)',
  },
  {
    value: 'GGP',
    label: 'Guernsey Pound (GGP)',
  },
  {
    value: 'GHS',
    label: 'Ghana Cedi (GHS)',
  },
  {
    value: 'GIP',
    label: 'Gibraltar Pound (GIP)',
  },
  {
    value: 'GMD',
    label: 'Gambia Dalasi (GMD)',
  },
  {
    value: 'GNF',
    label: 'Guinea Franc (GNF)',
  },
  {
    value: 'GTQ',
    label: 'Guatemala Quetzal (GTQ)',
  },
  {
    value: 'GYD',
    label: 'Guyana Dollar (GYD)',
  },
  {
    value: 'HKD',
    label: 'Hong Kong Dollar (HKD)',
  },
  {
    value: 'HNL',
    label: 'Honduras Lempira (HNL)',
  },
  {
    value: 'HRK',
    label: 'Croatia Kuna (HRK)',
  },
  {
    value: 'HTG',
    label: 'Haiti Gourde (HTG)',
  },
  {
    value: 'HUF',
    label: 'Hungary Forint (HUF)',
  },
  {
    value: 'IDR',
    label: 'Indonesia Rupiah (IDR)',
  },
  {
    value: 'ILS',
    label: 'Israel Shekel (ILS)',
  },
  {
    value: 'IMP',
    label: 'Isle of Man Pound (IMP)',
  },
  {
    value: 'INR',
    label: 'India Rupee (INR)',
  },
  {
    value: 'IQD',
    label: 'Iraq Dinar (IQD)',
  },
  {
    value: 'IRR',
    label: 'Iran Rial (IRR)',
  },
  {
    value: 'ISK',
    label: 'Iceland Krona (ISK)',
  },
  {
    value: 'JEP',
    label: 'Jersey Pound (JEP)',
  },
  {
    value: 'JMD',
    label: 'Jamaica Dollar (JMD)',
  },
  {
    value: 'JOD',
    label: 'Jordan Dinar (JOD)',
  },
  {
    value: 'JPY',
    label: 'Japan Yen (JPY)',
  },
  {
    value: 'KES',
    label: 'Kenya Shilling (KES)',
  },
  {
    value: 'KGS',
    label: 'Kyrgyzstan Som (KGS)',
  },
  {
    value: 'KHR',
    label: 'Cambodia Riel (KHR)',
  },
  {
    value: 'KMF',
    label: 'Comorian Franc (KMF)',
  },
  {
    value: 'KPW',
    label: 'Korea (North) Won (KPW)',
  },
  {
    value: 'KRW',
    label: 'Korea (South) Won (KRW)',
  },
  {
    value: 'KWD',
    label: 'Kuwait Dinar (KWD)',
  },
  {
    value: 'KYD',
    label: 'Cayman Islands Dollar (KYD)',
  },
  {
    value: 'KZT',
    label: 'Kazakhstan Tenge (KZT)',
  },
  {
    value: 'LAK',
    label: 'Laos Kip (LAK)',
  },
  {
    value: 'LBP',
    label: 'Lebanon Pound (LBP)',
  },
  {
    value: 'LKR',
    label: 'Sri Lanka Rupee (LKR)',
  },
  {
    value: 'LRD',
    label: 'Liberia Dollar (LRD)',
  },
  {
    value: 'LSL',
    label: 'Lesotho Loti (LSL)',
  },
  {
    value: 'LTL',
    label: 'Lithuanian Litas',
  },
  {
    value: 'LYD',
    label: 'Libya Dinar (LYD)',
  },
  {
    value: 'MAD',
    label: 'Morocco Dirham (MAD)',
  },
  {
    value: 'MDL',
    label: 'Moldova Leu (MDL)',
  },
  {
    value: 'MGA',
    label: 'Madagascar Ariary (MGA)',
  },
  {
    value: 'MKD',
    label: 'Macedonia Denar (MKD)',
  },
  {
    value: 'MMK',
    label: 'Myanmar Kyat (MMK)',
  },
  {
    value: 'MNT',
    label: 'Mongolia Tughrik (MNT)',
  },
  {
    value: 'MOP',
    label: 'Macau Pataca (MOP)',
  },
  {
    value: 'MRU',
    label: 'Mauritania Ouguiya (MRU)',
  },
  {
    value: 'MRO',
    label: 'Mauritania Ouguiya (MRO)',
  },
  {
    value: 'MUR',
    label: 'Mauritius Rupee (MUR)',
  },
  {
    value: 'MVR',
    label: 'Maldives Rufiyaa (MVR)',
  },
  {
    value: 'MWK',
    label: 'Malawi Kwacha (MWK)',
  },
  {
    value: 'MXN',
    label: 'Mexico Peso (MXN)',
  },
  {
    value: 'MYR',
    label: 'Malaysia Ringgit (MYR)',
  },
  {
    value: 'MZN',
    label: 'Mozambique Metical (MZN)',
  },
  {
    value: 'NAD',
    label: 'Namibia Dollar (NAD)',
  },
  {
    value: 'NGN',
    label: 'Nigeria Naira (NGN)',
  },
  {
    value: 'NIO',
    label: 'Nicaragua Cordoba (NIO)',
  },
  {
    value: 'NOK',
    label: 'Norway Krone (NOK)',
  },
  {
    value: 'NPR',
    label: 'Nepal Rupee (NPR)',
  },
  {
    value: 'NZD',
    label: 'New Zealand Dollar (NZD)',
  },
  {
    value: 'OMR',
    label: 'Oman Rial (OMR)',
  },
  {
    value: 'PAB',
    label: 'Panama Balboa (PAB)',
  },
  {
    value: 'PEN',
    label: 'Peru Sol (PEN)',
  },
  {
    value: 'PGK',
    label: 'Papua New Guinea Kina (PGK)',
  },
  {
    value: 'PHP',
    label: 'Philippines Piso (PHP)',
  },
  {
    value: 'PKR',
    label: 'Pakistan Rupee (PKR)',
  },
  {
    value: 'PLN',
    label: 'Poland Zloty (PLN)',
  },
  {
    value: 'PYG',
    label: 'Paraguay Guarani (PYG)',
  },
  {
    value: 'QAR',
    label: 'Qatar Riyal (QAR)',
  },
  {
    value: 'RON',
    label: 'Romania Leu (RON)',
  },
  {
    value: 'RSD',
    label: 'Serbia Dinar (RSD)',
  },
  {
    value: 'RUB',
    label: 'Russia Ruble (RUB)',
  },
  {
    value: 'RWF',
    label: 'Rwanda Franc (RWF)',
  },
  {
    value: 'SAR',
    label: 'Saudi Arabia Riyal (SAR)',
  },
  {
    value: 'SBD',
    label: 'Solomon Islands Dollar (SBD)',
  },
  {
    value: 'SCR',
    label: 'Seychelles Rupee (SCR)',
  },
  {
    value: 'SDG',
    label: 'Sudan Pound (SDG)',
  },
  {
    value: 'SEK',
    label: 'Sweden Krona (SEK)',
  },
  {
    value: 'SGD',
    label: 'Singapore Dollar (SGD)',
  },
  {
    value: 'SHP',
    label: 'Saint Helena Pound (SHP)',
  },
  {
    value: 'SLL',
    label: 'Sierra Leone Leone (SLL)',
  },
  {
    value: 'SOS',
    label: 'Somalia Shilling (SOS)',
  },
  {
    value: 'SPL',
    label: 'Seborga Luigino (SPL)',
  },
  {
    value: 'SRD',
    label: 'Suriname Dollar (SRD)',
  },
  {
    value: 'STN',
    label: 'São Tomé and Príncipe Dobra (STN)',
  },
  {
    value: 'SVC',
    label: 'El Salvador Colon (SVC)',
  },
  {
    value: 'SYP',
    label: 'Syria Pound (SYP)',
  },
  {
    value: 'SZL',
    label: 'Swaziland Lilangeni (SZL)',
  },
  {
    value: 'THB',
    label: 'Thailand Baht (THB)',
  },
  {
    value: 'TJS',
    label: 'Tajikistan Somoni (TJS)',
  },
  {
    value: 'TMT',
    label: 'Turkmenistan Manat (TMT)',
  },
  {
    value: 'TND',
    label: 'Tunisia Dinar (TND)',
  },
  {
    value: 'TOP',
    label: "Tonga Pa'anga (TOP)",
  },
  {
    value: 'TRY',
    label: 'Turkey Lira (TRY)',
  },
  {
    value: 'TTD',
    label: 'Trinidad and Tobago Dollar (TTD)',
  },
  {
    value: 'TVD',
    label: 'Tuvalu Dollar (TVD)',
  },
  {
    value: 'TWD',
    label: 'Taiwan New Dollar (TWD)',
  },
  {
    value: 'TZS',
    label: 'Tanzania Shilling (TZS)',
  },
  {
    value: 'UAH',
    label: 'Ukraine Hryvnia (UAH)',
  },
  {
    value: 'UGX',
    label: 'Uganda Shilling (UGX)',
  },
  {
    value: 'USD',
    label: 'United States Dollar (USD)',
  },
  {
    value: 'UYU',
    label: 'Uruguay Peso (UYU)',
  },
  {
    value: 'UZS',
    label: 'Uzbekistan Som (UZS)',
  },
  {
    value: 'VEF',
    label: 'Venezuela Bolívar (VEF)',
  },
  {
    value: 'VND',
    label: 'Viet Nam Dong (VND)',
  },
  {
    value: 'VUV',
    label: 'Vanuatu Vatu (VUV)',
  },
  {
    value: 'WST',
    label: 'Samoa Tala (WST)',
  },
  {
    value: 'XCD',
    label: 'East Caribbean Dollar (XCD)',
  },
  {
    value: 'XAF',
    label: 'Central African XAF (XAF)',
  },
  {
    value: 'XOF',
    label: 'Central African XOF (XOF)',
  },
  {
    value: 'YER',
    label: 'Yemen Rial (YER)',
  },
  {
    value: 'ZAR',
    label: 'South Africa Rand (ZAR)',
  },
  {
    value: 'ZMW',
    label: 'Zambia Kwacha (ZMW)',
  },
  {
    value: 'ZWD',
    label: 'Zimbabwe Dollar (ZWD)',
  },
];

export const moduleOptions = [
  { value: 'POC', label: 'POC' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'PASSIVE', label: 'Passive' },
];

export const featuresOptions = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'PASSIVE', label: 'Passive' },
];

export const trueFalseOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Passive' },
];

export const monitorRecoTargetReasons = [
  { value: 'Below 300 orders', label: 'Below 300 orders' },
  { value: 'Not having all 6 widgets', label: 'Not having all 6 widgets' },
  {
    value: 'Widgets are not in the right place',
    label: 'Widgets are not in the right place',
  },
  {
    value: 'Unable to retrieve the object',
    label: 'Unable to retrieve the object',
  },
  {
    value: 'Unable to retrieve the XML feed',
    label: 'Unable to retrieve the XML feed',
  },
  {
    value: 'Shopify / BigCommerce app problems',
    label: 'Shopify / BigCommerce app problems',
  },
  { value: 'Mismatch in sales', label: 'Mismatch in sales' },
  { value: 'Code has been removed', label: 'Code has been removed' },
  { value: 'Industrial', label: 'Industrial' },
];

export const monitorModuleList = [
  'recommendation',
  'search',
  'faceted',
  'push',
];

export const monitorAccountTypes = ['PRE_POC', 'POC', 'ONHOLD', 'FAILED'];

export const campaignTableHeaders = [
  'Page',
  'Campaigns',
  'Algorithms',
  'View',
  'Impression',
  'View Rate',
  'Click',
  'CTR',
  'Basket',
  'Basket Rate',
  'Revenue',
  'Purchases',
  'Purchased Products',
  'Conversion Rate',
  'Contribution Ratio',
  'Diff Ratio',
];

export const pushCampaignTableHeaders = [
  'Group',
  'Campaigns',
  'Sent',
  'Open',
  'Click',
  'Open Rate',
  'Basket',
  'Basket Rate',
  'Revenue',
  'Purchases',
  'Purchased Products',
  'Conversion Rate',
  'Contribution Ratio',
  'Diff Ratio',
];

export const monitorDefaultTarget = {
  recommendation: { value: parseFloat('10').toFixed(2), reason: '' },
  search: { value: parseFloat('5').toFixed(2), reason: '' },
  faceted: { value: parseFloat('10').toFixed(2), reason: '' },
  push: { value: parseFloat('2').toFixed(2), reason: '' },
};

export const searchCampaignHeaders = [
  'Group',
  'Assets',
  'View',
  'Click',
  'CTR',
  'Basket',
  'Basket Rate',
  'Revenue',
  'Purchases',
  'Purchased Products',
  'Conversion Rate',
  'Contribution Ratio',
  'Diff Ratio',
];

export default {
  types,
  timeZones,
  languages,
  currencies,
  moduleOptions,
  featuresOptions,
  trueFalseOptions,
  dataCenterV3,
  SEGMENTIFY_TOKEN_V3,
  searchCampaignHeaders,
};
