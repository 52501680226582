/**
 * Created by mehmetyurtar on 16/06/2017.
 */
"use strict";

import { createConstants } from "../../system/store";

export default createConstants(
  "UPDATE_SWITCH_USER",
  "RESET_MAIL_CONF_IN_SWITCHED",
  "UPDATE_MAIL_CONF_IN_SWITCHED",
  "UPDATE_REGION_IN_SWITCHED"
);
