import mixpanel from 'mixpanel-browser';
import get from 'lodash/get';
import moment from 'moment';
import { appEnv } from './constants/config';
import { store } from './store';

const configByEnv = {
  local: {
    apiKey: 'a7cafa1e77f40bb2e1e83b892ef24074',
  },
  dev: {
    apiKey: '9f4295dcc523139b304af4c9cc3eb4e6',
  },
  search: {
    apiKey: '7f7f343e3d893605ddb04b9187192599',
  },
  qa: {
    apiKey: '583c4c578a1165e2f7e9225e3de6131b',
  },
  prod: {
    apiKey: '18565d25ec0af391ebb0e1804eab1c77',
  },
};
const trackingInstance = mixpanel;
const trackingEventNames = {
  PAGE_VIEW: 'Page view',
};

function getDataForEvent() {
  const switchedUser = get(store.getState(), 'switchedUser.switchedUser');
  const timeFrame = get(store.getState(), 'date');
  const username = get(switchedUser, 'username');
  let dataForEvent = {
    act_as_user: '-',
    act_as_account_id: '-',
    act_as_account_domain: '-',
    userAgent: '-',
    page_url: '-',
  };
  if (username) {
    dataForEvent = {
      act_as_user: username,
      act_as_account_id: get(switchedUser, 'account.accountId', '-'),
      act_as_account_domain: get(switchedUser, 'account.domain', '-'),
    };
  }
  dataForEvent = {
    ...dataForEvent,
    timeFrame: {
      rangeAlias: timeFrame.rangeAlias,
      from: moment(timeFrame.range[0]).format('DD.MM.YYYY'),
      to: moment(timeFrame.range[1]).format('DD.MM.YYYY'),
    },
    userAgent: window.navigator ? window.navigator.userAgent : '-',
    page_url: window.location.href,
  };
  return dataForEvent;
}
const trackingService = {
  name: 'mixpanel',
  sendPageView(actionName, props, callback) {
    trackingInstance.track(
      actionName,
      { ...getDataForEvent(), ...props },
      callback,
    );
  },
  setUserProps(props, to, callback) {
    trackingInstance.people.set(props, to, callback);
  },
  setUserId(userId) {
    trackingInstance.identify(userId);
  },
  init() {
    const environment = appEnv();
    const trackingConfig = configByEnv[environment];
    if (trackingConfig) {
      mixpanel.init(trackingConfig.apiKey, {
        ignore_dnt: environment !== 'local',
      });
    } else {
      console.error(
        'Environment cannot set for tracking service',
        trackingService.name,
      );
    }
  },
};

export { trackingService, trackingEventNames };
