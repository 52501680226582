/**
 * Created by barisaltun on 05/08/2019.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { uiActions, wizardActions, modalActions } from "../../../actions";
import {
  criterionTypeButtonsPalantir,
  exclusionButtons,
  inclusionButtons,
  getStaticAlgorithms
} from "../../../constants/datamaps/wizard";
import Icon from "../../../components/icon";
import Icons from "../../../components/icons";
import Criteria from "../../../components/wizard/criteria";
import Excludes from "../../../components/wizard/excludes";
import Includes from "../../../components/wizard/includes";
import ProductSelection from "../../../components/wizard/product-selection";
import IntelligentSelectionModal
  from "../../../components/wizard/selectionModal.intelligent";

import { t } from "../../../system/ui";

const selectionModals = {
  intelligent: {
    icon: "search",
    iconSvg: true,
    title: "Assets",
    subtitle:
      "Select from Segmentify Search Box Asset Types to create your search campaigns. Each one of the fields for Category, Brand and Product indicate an asset.",
    modal: IntelligentSelectionModal
  }
};

class SearchRecommendations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      excludes: [],
      shuffle: true,
      hideExcludes: true,
      hideIncludes: true,
      showSingleProductErr: false,
      hasError: false,
      showRequiredError: false,
      includeTypes: [],
      excludeTypes: []
    };

    this.button = this.button.bind(this);
    this.addDetailsRow = this.addDetailsRow.bind(this);
    this.addCriterion = this.addCriterion.bind(this);
    this.addInclusionRow = this.addInclusionRow.bind(this);
    this.addExclusionRow = this.addExclusionRow.bind(this);
    this.scrollToElement = this.scrollToElement.bind(this);
    this.addProducts = this.addProducts.bind(this);
    this.validate = this.validate.bind(this);
    this.onButtonsScroll = this.onButtonsScroll.bind(this);
    this.openAlgorithmSelection = this.openAlgorithmSelection.bind(this);
    this.toggleFilterAction = this.toggleFilterAction.bind(this);
    this.handleShuffle = this.handleShuffle.bind(this);
    this.setShuffle = this.setShuffle.bind(this);
    this.criteriaAdd = this.criteriaAdd.bind(this);
  }

  componentDidMount() {
    this.onButtonsScroll();
    this.setShuffle(this.props.campaign);
  }

  criteriaAdd(campaign) {
    let newItems = [];
    campaign.searchAssets.map(item => {
      if (item.type !== undefined) {
        if (campaign.mobileSearchAssets !== undefined) {
          let mobileAsset = campaign.mobileSearchAssets.find(
            mobile => mobile.type === item.type
          );
          if (mobileAsset !== undefined) {
            item.mobileItemCount = mobileAsset.itemCount;
          }
        }

        if (item.type.indexOf("-s") < 0) {
          item.type = item.type.toLowerCase() + "-s";
        }
        if (item.id === undefined) {
          item.id = Math.random();
        }

        newItems.push(item);
      }
    });
    if (newItems.length > 0) {
      wizardActions.addCriteria(newItems);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.excludes !== newProps.excludes) {
      if (newProps.excludes.length > 0) {
        this.setState({
          hideExcludes: false
        });
      }
    }

    if (newProps.includes.length > 0) {
      this.setState({
        hideIncludes: false
      });
    }

    if (newProps.disableCustom !== undefined && newProps.disableCustom) {
      this.setState({
        hideExcludes: true,
        hideIncludes: true
      });
    }
    if (newProps.criteria.length < 1) {
      this.setState({
        showSingleProductErr: false
      });
    }

    if (this.props.includes !== newProps.includes) {
      this.setState({
        includeTypes: newProps.includes.map(include => include.type)
      });
    }

    if (this.props.excludes !== newProps.excludes) {
      this.setState({
        excludeTypes: newProps.excludes.map(exclude => exclude.type)
      });
    }

    if (this.props.campaign !== newProps.campaign) {
      this.setShuffle(newProps.campaign);
      this.criteriaAdd(newProps.campaign);
    }
  }

  setShuffle(campaign) {
    this.setState({
      shuffle: campaign.ordering === "SHUFFLE"
    });
  }

  componentDidUpdate(oldProps) {
    if (
      this.props.criteria !== oldProps.criteria ||
      this.props.excludes !== oldProps.excludes
    ) {
      this.validate();
    }
  }

  toggleFilterAction(action) {
    this.setState({ [action]: false }, () => {
      let el =
        action === "hideIncludes" ? "includesToScroll" : "excludesToScroll";
      el = this.refs[el];
      //this.scrollToElement(el, action);
    });
  }

  scrollToElement(item) {
    let diff = item.getBoundingClientRect().top + window.scrollY - 300;
    if (Math.abs(diff) > 1) {
      window.scrollTo(0, window.scrollY + diff + 150);
      clearTimeout(window._TO);
      window._TO = setTimeout(this.scrollToItem, 30, item);
    } else {
      window.scrollTo(0, item.offsetTop);
    }
  }

  validate() {
    if (this.props.excludes.length === 0 && this.props.criteria.length === 0) {
      this.setState({
        hasError: true,
        showRequiredError: true
      });
    } else {
      this.setState({
        hasError: false,
        showRequiredError: false
      });
    }
  }

  addDetailsRow(type, algorithms) {
    if (algorithms) {
      this.addCriterion(type, algorithms);
    } else {
      if (type === "excludes") {
        this.setState({ hideExcludes: "" });
        let scrollSize =
          document
            .querySelector(".wizard-criterion:last-child")
            .getBoundingClientRect().top + 100;
        if (document.querySelector(".wizard-criterion:last-child")) {
          scrollTo(document.body, scrollSize, 800);
        }
      } else if (type === "includes") {
        this.setState({ hideIncludes: "" });
        let scrollSize =
          document
            .querySelector(".wizard-criterion:last-child")
            .getBoundingClientRect().top + 100;
        if (document.querySelector(".wizard-criterion:last-child")) {
          scrollTo(document.body, scrollSize, 800);
        }
      } else if (type === "product") {
        let existingProductIds = [];
        this.props.criteria.forEach(item => {
          if (item.type === "product") {
            existingProductIds.push(item.values.productId.value);
          }
        });
        let content = () => (
          <ProductSelection
            addProducts={this.addProducts}
            existingProducts={existingProductIds}
          />
        );
        uiActions.openModal({ title: t("Select Products"), content });
      } else {
        this.addCriterion(type);
      }
    }
  }

  addInclusionRow(type) {
    let current = this.props.includes;
    current = current.concat({ id: Math.random(), type: type, values: {} });
    wizardActions.addInclusion(current);
  }

  addExclusionRow(type) {
    let current = this.props.excludes;
    current = current.concat({ id: Math.random(), type: type, values: {} });
    wizardActions.addExclusion(current);
  }

  addCriterion(type, algorithms = []) {
    let current = this.props.criteria;
    let selectedAlgorithms = Object.assign([], this.props.criteria);
    if (this.props.campaign.messageType === "product" && current.length > 1) {
      this.setState({ showSingleProductErr: true });
    } else {
      let staticItems = [];
      let dynamicItems = [];
      let newItems = [];
      let newCriteria = [];
      let finalAlgorithms = [];
      let staticAlgorithms = getStaticAlgorithms(false, true);
      if (algorithms) {
        let isEmail = this.props.campaign.defaultRecommendation;
        if (isEmail) {
          finalAlgorithms = selectedAlgorithms.concat(
            algorithms.map(algorithm => {
              if (type === "static") {
                let staticType =
                  staticAlgorithms[algorithm.values.criterion.value];
                return { id: Math.random(), type: staticType, values: {} };
              } else {
                return algorithm;
              }
            })
          );
          wizardActions.addCriteria(finalAlgorithms);
        } else {
          if (type === "static") {
            current.forEach(item => {
              if (item.type == "product") {
                staticItems.push(item);
              } else {
                dynamicItems.push(item);
              }
            });
            algorithms.forEach(algorithm => {
              let staticType =
                staticAlgorithms[algorithm.values.criterion.value];
              if (staticType === "product") {
                staticItems.push({
                  id: Math.random(),
                  type: staticType,
                  values: {}
                });
              } else {
                dynamicItems.push({
                  id: Math.random(),
                  type: staticType,
                  values: algorithm.values
                });
              }
            });
            wizardActions.addCriteria(staticItems.concat(dynamicItems));
          } else {
            finalAlgorithms = selectedAlgorithms.concat(algorithms);
            wizardActions.addCriteria(finalAlgorithms);
          }
        }
      } else {
        current.forEach(item => {
          if (item.type === "product") {
            staticItems.push(item);
          } else {
            dynamicItems.push(item);
          }
        });
        newItems.push({ id: Math.random(), type: type, values: {} });
        if (type === "product") {
          newCriteria = staticItems.concat(newItems, dynamicItems);
        } else {
          newCriteria = staticItems.concat(dynamicItems, newItems);
        }
        wizardActions.addCriteria(newCriteria);
        /* Scroll to latest criterion element */
        if (type !== "product") {
          if (document.querySelector(".wizard-criterion:last-child")) {
            scrollTo(
              document.body,
              document
                .querySelector(".wizard-criterion:last-child")
                .getBoundingClientRect().top - 500,
              600
            );
          }
        }
      }
    }
  }

  button(item, type, cb, filterType) {
    let currentIcon = "";
    if (
      item.icon === "excludeLabel" ||
      item.icon === "excludeGender" ||
      item.icon === "excludeCustom" ||
      item.iconSvg
    ) {
      currentIcon = (
        <Icons
          name={item.icon}
          color={
            item.icon.indexOf("asteriks") < 0 && item.icon.indexOf("brain") < 0
              ? "#787779"
              : "#fff"
          }
        />
      );
    } else {
      currentIcon = <Icon name={item.icon} />;
    }
    let isSelectedCriteria =
      (filterType === "inclusion" &&
        this.state.includeTypes.indexOf(type) > -1) ||
      (filterType === "exclusion" &&
        this.state.excludeTypes.indexOf(type) > -1);
    return (
      <a
        key={type}
        className={classNames("button", item.className, {
          "include-exclude-selected": isSelectedCriteria,
          "custom-include-exclude-selected": type === "custom"
        })}
        onClick={cb.bind(null, type)}
      >
        {isSelectedCriteria && (
          <span>
            <Icons name="checkboxSelected" width="15" height="15" />
          </span>
        )}
        {currentIcon}
        {t(item.title)}
      </a>
    );
  }

  addProducts(products) {
    modalActions.closeModal();
    let current = this.props.criteria;
    let staticItems = [];
    let dynamicItems = [];
    let newItems = [];
    let newCriteria = [];
    products.forEach(product => {
      newItems.push({
        id: Math.random(),
        type: "product",
        values: { productId: { value: product.productId } }
      });
    });
    current.forEach(item => {
      if (item.type === "product") {
        staticItems.push(item);
      } else {
        dynamicItems.push(item);
      }
    });
    newCriteria = staticItems.concat(newItems, dynamicItems);
    wizardActions.addCriteria(newCriteria);
  }

  onButtonsScroll() {
    let containerEl = document.getElementById("scroll-container");
    let subtitle = document.getElementsByClassName("item-title-sub__rec")[0];
    let el = document.getElementsByClassName("wizard-input-types")[0];
    let elTop =
      el.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top;
    let heighttoset = el.offsetHeight + subtitle.offsetHeight + "px";
    containerEl.style.height = heighttoset;
    window.addEventListener("scroll", function() {
      if (
        document.documentElement.scrollTop >
        elTop - el.offsetHeight - subtitle.offsetHeight / 2
      ) {
        el.className = "wizard-input-types";
      } else {
        el.className = "wizard-input-types ";
      }
    });
  }

  openAlgorithmSelection(obj) {
    let selectionModal = selectionModals[obj];
    let Modal = selectionModal.modal;
    let props = this.props;
    let currentStatics = getStaticAlgorithms(false, true);

    let disabledStatics = [];
    this.props.criteria.forEach(criteria => {
      disabledStatics.push("STATIC-" + criteria.type.toUpperCase());
    });

    modalActions.openModal({
      title: t(selectionModal.title),
      subtitle: t(selectionModal.subtitle),
      icon: selectionModal.icon,
      className: "wizard-comp-modal",
      content: () => (
        <Modal
          currentAlgorithms={
            obj === "intelligent" ? currentStatics : currentStatics
          }
          addDetailsRow={this.addDetailsRow}
          pageProps={props}
          disabledStatics={disabledStatics}
        />
      )
    });
  }

  handleShuffle() {
    this.setState({ shuffle: !this.state.shuffle });
  }

  render() {
    let isSingleRec = this.props.campaign.messageType === "product";
    let isEmail =
      (this.props.campaign.defaultRecommendation &&
        this.props.campaign.defaultRecommendation.length) ||
      (this.props.campaign.type &&
        (this.props.campaign.type === "FLY" ||
          this.props.campaign.type === "RECOMMENDATION"));
    let criterionButtonsToShow = criterionTypeButtonsPalantir;
    return (
      <div>
        <li className="item-title-field">
          <div className="wizard-comp" id="mA1">
            {isSingleRec ? (
              <h3
                className="wizard-comp-title item-title-sub item-title-sub__rec">
                {t("Recommendation")}
              </h3>
            ) : (
              <h3
                className="wizard-comp-title item-title-sub item-title-sub__rec"
                style={{ fontWeight: 600 }}
              >
                {t("Assets")}
              </h3>
            )}
            <p className="wizard-comp-description">
              {t(
                "Prepare your Search Box content here. Each one of the Category, Brand and Product fields indicate an Asset"
              )}
            </p>
            {this.state.showSingleProductErr ? (
              <span className="item-error">(*You can select only 1 item)</span>
            ) : (
              ""
            )}
            <div id="scroll-container">
              <div className="wizard-input-types search">
                {Object.keys(criterionButtonsToShow).map(item => {
                  return this.button(
                    criterionButtonsToShow[item],
                    item,
                    this.openAlgorithmSelection
                  );
                })}
                <div className="open-filters-buttons">
                  <div
                    className={classNames(
                      "open-filters open-filters--inclusion",
                      {
                        "filters-disabled": this.props.disableCustom
                      }
                    )}
                    onClick={this.toggleFilterAction.bind(null, "hideIncludes")}
                  >
                    <Icons name="plusCircleSmall" color="#787779" />
                    {t("Include Filters")}
                  </div>

                  <div
                    className={classNames(
                      "open-filters open-filters--exclusion",
                      {
                        "filters-disabled": this.props.disableCustom
                      }
                    )}
                    onClick={this.toggleFilterAction.bind(null, "hideExcludes")}
                  >
                    <Icons name="minusCircleSmall" color="#787779" />
                    {t("Exclude Filters")}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                !isEmail
                  ? "wizard-input-type-wrapper"
                  : "wizard-input-type-wrapper wizard-input-criteria wizard-email-input-type-wrapper"
              }
            >
              <div className="wizard-criteria wizard-items-container">
                <Criteria
                  isPalantir={true}
                  emailType={isEmail ? this.props.campaign.type : ""}
                  campaign={this.props.campaign}
                  getData={this.props.getData}
                  isFinished={this.props.isFinished}
                  isEdit={this.props.isEdit}
                />
              </div>
            </div>
          </div>
        </li>
        <li>
          <div
            className={classNames("wizard-comp wizard-comp-filters", {
              "is-hidden": this.state.hideIncludes
            })}
            ref="includesToScroll"
            id="mA2"
          >
            <h3 className="wizard-comp-title" style={{ color: "#8ea976" }}>
              <Icons name="plusCircleSmall" color="#8ea976" />
              {t("Include Filters")}
            </h3>
            <p className="wizard-comp-description">
              {t(
                "Select from these algorithms to create your recommendations based on your needs."
              )}
            </p>
            {Object.keys(inclusionButtons).map(item => {
              return this.button(
                inclusionButtons[item],
                item,
                this.addInclusionRow,
                "inclusion"
              );
            })}
            <div className="wizard-includes wizard-input-type-wrapper">
              <div className="wizard-criteria wizard-items-container">
                <Includes />
              </div>
            </div>
          </div>
        </li>
        <li>
          <div
            className={classNames("wizard-comp wizard-comp-filters", {
              "is-hidden": this.state.hideExcludes
            })}
            ref="excludesToScroll"
            id="mA3"
          >
            <h3 className="wizard-comp-title" style={{ color: "#c54343" }}>
              <Icons name="minusCircleSmall" color="#c54343" />{" "}
              {t("Exclude Filters")}
            </h3>
            <p className="wizard-comp-description">
              {t(
                "Select from these algorithms to create your recommendations based on your needs."
              )}
            </p>
            {Object.keys(exclusionButtons).map(item => {
              return this.button(
                exclusionButtons[item],
                item,
                this.addExclusionRow,
                "exclusion"
              );
            })}
            <div className="wizard-includes wizard-input-type-wrapper">
              <div className="wizard-criteria wizard-items-container">
                <Excludes />
              </div>
            </div>
          </div>
        </li>
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  validate: store.ui.validate,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  SearchRecommendations
);
