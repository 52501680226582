import React from 'react';
import PropTypes from 'prop-types';
import './Loader.scss';

const Loader = props => {
  const style = { ...props.style } || {};
  return <div className="sgm-loader" {...props} style={style} />;
};

Loader.defaultProps = {
  style: {},
};

Loader.propTypes = {
  style: PropTypes.instanceOf(Object),
};

export default Loader;
