import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const GaAnalyticsBlock = props => {
  return (
    <div className='widget page-content-block'>
      <div className='one-whole'>
        <h2 className='widget-title'>
          {props.title}
          <span className='stat-tooltip-ga' data-tooltip-id='ga-tooltip'>
            <i
              className='icon-info'
              style={{ color: '#5a7896' }}
              role='presentation'
            >
              <span className='for-screenreader-only' />
            </i>
          </span>
        </h2>
        <ReactTooltip
          effect='solid'
          className='sg-tooltip'
          id='ga-tooltip'
          content='Google Analytics 4 might count the data twice depending on your Google analytics 4 integration. Segmentify optimizes the data to only be counted once. So as a result there might be differences between our data and Google Analytics 4.'
        />
      </div>
      <div className='widget-meta'>{props.meta}</div>
      <hr className='widget-separator' />
      <div>
        <div className='widget-stat-list widget-report'>{props.children}</div>
      </div>
    </div>
  );
};
export default GaAnalyticsBlock;
