import React, { useState } from 'react';
import classNames from 'classnames';
import { uiActions, modalActions } from '../../actions';
import { getApiUrl } from '../../constants/config';
import { $http } from '../../system/ajax';
import { t } from '../../system/ui';
import { Select, TextField } from '../fields';

/**
 * @name ActivateGoogleAnalytics4Modal
 * @description Modal for activating Google Analytics 4
 * @param {object} account
 * @param {array<user>} users
 * @param {object} user
 * @param {function} onGa4Update
 * @returns {JSX.Element}
 */
const ActivateGoogleAnalytics4Modal = ({
  account,
  users,
  user,
  onGa4Update,
}) => {
  const username = user ? user.username : false;
  const switchUser = users
    ? users?.[account.accountId]?.[0]?.user?.username
    : username;

  let ga4;

  if (account.ga4) {
    ga4 = account.ga4;
  } else {
    ga4 = {
      propertyId: '',
      tokenMail: '',
    };
  }
  const defaults = {
    propertyId: ga4.propertyId,
    tokenMail: ga4.tokenMail,
  };

  const tokenMailList = [
    { value: 'analytics@segmentify.com', label: 'analytics@segmentify.com' },
    {
      value: 'analytics.tr@segmentify.com',
      label: 'analytics.tr@segmentify.com',
    },
    {
      value: 'analytics.tr2@segmentify.com',
      label: 'analytics.tr2@segmentify.com',
    },
    {
      value: 'analytics.tr3@segmentify.com',
      label: 'analytics.tr3@segmentify.com',
    },
    {
      value: 'analytics.tr4@segmentify.com',
      label: 'analytics.tr4@segmentify.com',
    },
    {
      value: 'analytics.eu@segmentify.com',
      label: 'analytics.eu@segmentify.com',
    },
    {
      value: 'analytics.eu2@segmentify.com',
      label: 'analytics.eu2@segmentify.com',
    },
    {
      value: 'analytics.eu3@segmentify.com',
      label: 'analytics.eu3@segmentify.com',
    },
    {
      value: 'analytics.eu4@segmentify.com',
      label: 'analytics.eu4@segmentify.com',
    },
    {
      value: 'analytics.pl@segmentify.com',
      label: 'analytics.pl@segmentify.com',
    },
    {
      value: 'analytics.br@segmentify.com',
      label: 'analytics.br@segmentify.com',
    },
  ];

  const [propertyId, setPropertyId] = useState(defaults.propertyId);
  const [tokenMail, setTokenMail] = useState(defaults.tokenMail);

  const [isSending, setSending] = useState(false);

  const submit = () => {
    if (!propertyId || !tokenMail || !switchUser) {
      return;
    }

    setSending(true);
    const url = `${getApiUrl()}update/ga4/configuration?apiKey=${
      account.apiKey
    }`;
    const data = {
      propertyId: propertyId.trim(),
      tokenMail: tokenMail.trim(),
    };

    $http(url)
      .post(data, { type: 'json', switchUser })
      .then(() => {
        const content = () => (
          <span>
            {t('Google Analytics 4 Configuration successfully saved.')}
          </span>
        );
        uiActions.showNotification({
          content,
        });

        onGa4Update(account.accountId, data);
        modalActions.closeModal();
      })
      .catch(() => {
        setSending(false);
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <div className='push-dialog'>
      <div className='push-dialog--input'>
        <Select
          name='tokenMail'
          className='item-stacked one-whole ga'
          label={t('Authorized Email Address')}
          value={tokenMail}
          options={tokenMailList}
          onChange={evt => setTokenMail(evt.value.trim())}
          required
        />
      </div>

      <div className='push-dialog--input'>
        <TextField
          name='propertyId'
          className='item-stacked one-whole'
          label={t('Property Id')}
          value={propertyId}
          onChange={evt => setPropertyId(evt.target.value.trim())}
          required
        />
      </div>

      <div className='push-dialog--input'>
        <button
          type='button'
          className={classNames({
            'tertiary-action': true,
            disabled: isSending,
          })}
          onClick={() => submit()}
        >
          {!isSending && <span>{t('Save')}</span>}
          {isSending && <span>{t('Saving...')}</span>}
        </button>
      </div>
    </div>
  );
};

export default ActivateGoogleAnalytics4Modal;
