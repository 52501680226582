import React, { Component } from "react";
import classNames from "classnames";
class SwitchButton extends Component {
  render() {
    const classList = {
      switch: true,
      sliderOn: this.props.isSwitchedOn
    };

    return (
      <label className={classNames(classList)} onClick={this.props.onToggle}>
        <span className="slider round"></span>
      </label>
    );
  }
}

export default SwitchButton;
