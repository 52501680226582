import React from 'react';
import { useQuery } from 'react-query';
import classes from './SMTPEmails.module.scss';

import { statTypes } from '../../../../constants/datamaps';

import { t } from '../../../../system/ui';
import { localeString } from '../../../../system/string';
import { transformTrends } from '../../../../modules/transform';
import fetchData from './services/requests';

const SMTPEmails = ({ range, stats }) => {
  const boundaries = {};
  boundaries.start = range[0]?.format('YYYY-MM-DD');
  boundaries.end = range[1]?.format('YYYY-MM-DD');

  const getData = async () => {
    const type = 'SMTP_EMAILS';

    if (boundaries.start && boundaries.end) {
      return await fetchData(boundaries, type);
    }

    if (!boundaries.end) {
      boundaries.end = boundaries.start;

      return await fetchData(boundaries, type);
    }
  };

  const rangeStr = boundaries.start + boundaries.end || boundaries.start;
  const { data } = useQuery(['data5', rangeStr], getData);

  const hasEmailSent = data?.filter(item => item?.x === 'Email [sent]') || [];
  let showField = true;
  if (!hasEmailSent?.length) {
    showField = false;
  }

  const purchaseAmount = stats?.purchase?.stats?.amount?.count;
  const transformedTrends = transformTrends(
    data,
    purchaseAmount,
    0,
    0,
    false,
    'E-Mail',
  );

  const stat = transformedTrends?.widget?.stats;
  const item = 'widget';
  const type = statTypes?.widget;
  const mainTitle = type.name;
  const className = `widget-stat stat-${item.toLowerCase()}`;

  const emailTypeName = 'Email';

  return (
    <>
      {showField && (
        <div className={classes.component}>
          <h2 className={classes.component__header}>{t('SMTP Emails')}</h2>
          <div className='page-content-block'>
            <div className='widget-stat-list widget-report'>
              <div className={`widget-stats ${classes['stat-area']}`}>
                <div
                  className={`${className} ${classes['stat-area__stat']}`}
                  key={`${item}-1`}
                >
                  <h4 className='widget-stat-title'>{t(emailTypeName)}</h4>
                  <p key={item} className='widget-stat-name'>
                    {mainTitle === 'Widget' && t('Emails Sent')}
                    {mainTitle === 'Widget' && (
                      <span className='widget-stat-conversion-amount'>
                        {localeString(stat.all.count)}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SMTPEmails;
