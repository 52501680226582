import React from 'react';
import Select from 'react-select';
import { t } from '../../../../system/ui';
import Tooltip from '../../../tooltip';
import Icon from '../../../icon';
import { TextField } from '../../../fields';
import { insertTypes } from '../../../../constants/datamaps/wizard';
import { uiActions } from '../../../../actions';

const TargetDivSelector = ({
  targetSelector,
  targetPosition,
  targetSetter,
}) => {
  const insertTypeChange = selectedOptions => {
    targetSetter('targetPosition', selectedOptions.value);
    uiActions.formEdited();
  };

  const selectorChange = e => {
    targetSetter('targetSelector', e.currentTarget.value);
    uiActions.formEdited();
  };

  return (
    <div className='wizard-target'>
      <span className='item item-stacked label-emphasise one-whole'>
        <span className='item-label'>
          {t('Target Div Selector')}
          <Tooltip
            content={t(
              'The target component on your website where recommendation box going to insert itself',
            )}
            alignment='right'
          >
            <Icon name='help' />
          </Tooltip>
        </span>
      </span>

      <span
        className='item two-thirds'
        style={{ display: 'inline-block', verticalAlign: 'top' }}
      >
        <TextField
          name='selector'
          value={targetSelector}
          placeholder={`#${t('unique-selector')}`}
          required
          style={{ width: '90%' }}
          onChange={selectorChange}
        />
      </span>

      <label className='item is-select one-third'>
        <Select
          searchable={false}
          clearable={false}
          name='insertType'
          options={insertTypes}
          value={targetPosition || 'SELF'}
          onChange={insertTypeChange}
        />
      </label>
    </div>
  );
};

export default TargetDivSelector;
