/**
 * @fileoverview This file allows creating, editing and deleting users for
 * multi-user accounts.
 * @requires react
 */
import React, { useEffect, useState } from 'react';

/**
 * Module dependencies
 */
import { modalActions, uiActions } from '../../actions';
import { deleteMultiUser, getMultiUserUsers } from '../../modules/account/ajax';
import { t } from '../../system/ui';
import Icon from '../icon';
import Icons from '../icons';
import CreateUser from './multi-createuser';
import EditUser from './multi-edituser';

/**
 * This component helps to management of CRUD operations in multi-user type
 * accounts.
 * @param {Object} user
 * @param {Object} user.account
 * @param {string} user.displayName
 * @param {string} user.language
 * @param {string|null} user.lastLogin
 * @param {string[]} user.privileges
 * @param {string[]} user.switchableAccounts
 * @param {string} user.username
 * @returns {React.ReactNode}
 */
const UserAccountsList = ({ user }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { openModal } = modalActions;
  const {
    isLoading,
    isLoaded,
    confirmationDialog,
    showNotification,
  } = uiActions;

  /**
   * Lists accounts to which the user has edit/delete access.
   */
  const getUsers = () => {
    isLoading();
    getMultiUserUsers(response => {
      isLoaded();
      if (response) {
        const sortUsers = response.sort((a, b) => {
          if (a.username < b.username) {
            return -1;
          }
          if (a.username > b.username) {
            return 1;
          }
          return 0;
        });

        setUsers(sortUsers);
        setFilteredUsers(sortUsers);
      }
    });
  };

  const deleteUser = userToDelete => {
    // Delete Confirm
    confirmationDialog({
      title: 'Are you sure you want to delete this user?',
      subtitle:
        'If continued, this user, along with its data, will be deleted permanently.',
      onConfirm: () => {
        deleteMultiUser({ username: userToDelete.username }, () => {
          const notificationContent = () => (
            <div>
              {`${userToDelete.username} ${t('User deleted successfully')}`}
            </div>
          );
          showNotification({ content: notificationContent });
          setUsers(
            users.filter(({ username }) => {
              return username !== userToDelete.username;
            }),
          );
          setFilteredUsers(
            filteredUsers.filter(({ username }) => {
              return username !== userToDelete.username;
            }),
          );
        });
      },
    });
  };

  const createUser = () => {
    openModal({
      title: t('Create User'),
      content: () => <CreateUser user={user} context={{ getUsers }} />,
      className: 'user-create-modal',
    });
  };

  const editUser = userToEdit => {
    const modal = () => (
      <EditUser user={user} userToEdit={userToEdit} context={{ getUsers }} />
    );
    openModal({
      title: `${t('Edit User')}(${userToEdit.username})`,
      content: modal,
      className: 'user-create-modal',
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <div className='user-accounts-add'>
        <button className='secondary-action' onClick={createUser} type='button'>
          <Icons name='useradd' color='#fff' /> {t('Create User')}
        </button>
      </div>
      <ul className='user-accounts-list'>
        {users.map(item => {
          return (
            <li key={item.username}>
              {item.displayName}({item.username})
              {item.pending && (
                <div className='user-accounts-pending'>{t('pending')}</div>
              )}
              <nav className='account-nav action-nav account-users-nav'>
                <Icon name='menu' />
                <ul className='action-nav-popup'>
                  <li onClick={() => editUser(item)}>
                    <a
                      className='action-nav-popup-item'
                      style={{ color: '#fff' }}
                    >
                      <Icon name='gear' /> {t('Edit')}
                    </a>
                  </li>
                  <li onClick={() => deleteUser(item)}>
                    <a
                      className='action-nav-popup-item'
                      style={{ color: '#fff' }}
                    >
                      <Icons name='delete' color='#fff' /> {t('Delete')}
                    </a>
                  </li>
                </ul>
              </nav>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default UserAccountsList;
