import useExtendedContext from '../../../hooks/module-context/useExtendedContext';
import { emailReducers } from './reducers/email-reducers';
import {
  setQueryString,
  setUploadFile,
  setActiveEmailDetails,
  editActiveEmailDetails,
} from './actions/email-actions';

/**
 * @fileOverview Email Provider for Email Component
 */

const emailState = {
  queryString: '',
  uploadFile: null,
  activeEmailDetails: {
    params: {
      mailContent: '',
      subject: '',
      interval: '1-24',
      currency: 'SESSION_BASED',
      language: 'SESSION_BASED',
      fromAddress: '',
      fromName: '',
      replyToAddress: '',
      replyName: '',
    },
    campaignUtm: {
      isCustomUtm: false,
      utmSource: 'segmentify',
      utmMedium: 'email',
      utmCampaign: '',
      utmContent: '',
      utmTerm: '',
    },
    schedule: {
      type: 'one_time',
      period: 'every_day',
      date: new Date(),
    },
    capping: {
      capCount: '1',
      frequency: 'DAILY',
      types: [],
    },
    filters: [
      {
        type: 'SEGMENT',
        segment: '',
        include: true,
      },
      {
        type: 'SEND_MAIL_SEGMENT',
        actionType: null,
        campaign: '',
        startDate: null,
        endDate: null,
      },
    ],
  },
};

/**
 * @name Email
 * @description Context for Email component. Provides access to the state and actions of the Wrapped component. Actions, state and dispatch are provided as react-hook basis.
 */
export const { Context, Provider } = useExtendedContext(
  emailReducers,
  {
    setQueryString,
    setUploadFile,
    setActiveEmailDetails,
    editActiveEmailDetails,
  },
  emailState,
);
