import { FACETED_SEARCH_NAME } from 'constants/faceted-search';

let env;
if (process.env.REACT_APP_ENV === 'development') {
  env = 'dev';
} else if (process.env.REACT_APP_ENV === 'qa') {
  env = 'qa';
} else if (process.env.REACT_APP_ENV === 'local') {
  env = 'local';
} else if (process.env.REACT_APP_ENV === 'search') {
  env = 'search';
} else {
  env = 'prod';
}

export const appEnv = () => env;

const urls = {
  prod: {
    path: {
      prod: 'https://sauron.segmentify.com/secure/v1/',
      rest: 'https://sauron.segmentify.com/',
      admin: 'https://sauron.segmentify.com/admin/v1/',
      adminV3: 'https://sauron.segmentify.com/',
      insights: 'https://sauron.segmentify.com/secure/v1/insights/',
      personalization: 'https://sauron.segmentify.com/secure/v2/campaign',
      personalisedAds: 'https://utils.segmentify.com/personalised-ads/',
      password:
        'https://sauron.segmentify.com/public/v1/multiaccount/set/password',
      login: 'https://www.segmentify.com/login.html',
      dashboard: 'https://v3.segmentify.com/',
      gandalf: {
        eq: 'https://per2.segmentify.com/',
        gc: 'https://gandalf-eu.segmentify.com/',
      },
    },
    endpoints: {
      token: {
        prod: 'https://sauron.segmentify.com/tokenWithCaptcha',
        check:
          'https://sauron.segmentify.com/public/v1/multiaccount/checkUserCreateToken',
      },
    },
  },
  qa: {
    path: {
      prod: 'https://sauron-qa.segmentify.com/secure/v1/',
      rest: 'https://sauron-qa.segmentify.com/',
      admin: 'https://sauron-qa.segmentify.com/admin/v1/',
      adminV3: 'https://sauron-qa.segmentify.com/',
      insights: 'https://sauron-qa.segmentify.com/secure/v1/insights/',
      personalization: 'https://sauron-qa.segmentify.com/secure/v2/campaign',
      personalisedAds: 'https://utils-qa.segmentify.com/personalised-ads/',
      password:
        'https://sauron-qa.segmentify.com/public/v1/multiaccount/set/password',
      login: 'https://qa.segmentify.com/login',
      dashboard: 'https://qa.segmentify.com/',
      gandalf: {
        eq: 'https://gandalf-qa.segmentify.com/',
        gc: 'https://gandalf-qa.segmentify.com/',
      },
    },
    endpoints: {
      token: {
        prod: 'https://sauron-qa.segmentify.com/tokenWithCaptcha',
        check:
          'https://sauron-qa.segmentify.com/public/v1/multiaccount/checkUserCreateToken',
      },
    },
  },
  dev: {
    path: {
      prod: 'https://sauron-dev.segmentify.com/secure/v1/',
      rest: 'https://sauron-dev.segmentify.com/',
      admin: 'https://sauron-dev.segmentify.com/admin/v1/',
      adminV3: 'https://sauron-dev.segmentify.com/',
      insights: 'https://sauron-dev.segmentify.com/secure/v1/insights/',
      personalization: 'https://sauron-dev.segmentify.com/secure/v2/campaign',
      personalisedAds: 'https://dev-utils.segmentify.com:6602/',
      password:
        'https://sauron-dev.segmentify.com/public/v1/multiaccount/set/password',
      login: 'https://v3-dev.segmentify.com/login',
      dashboard: 'https://v3-dev.segmentify.com/',
      gandalf: {
        eq: 'https://gandalf-dev.segmentify.com/',
        gc: 'https://gandalf-dev.segmentify.com/',
      },
    },
    endpoints: {
      token: {
        prod: 'https://sauron-dev.segmentify.com/tokenWithCaptcha',
        check:
          'https://sauron-dev.segmentify.com/public/v1/multiaccount/checkUserCreateToken',
      },
    },
  },
  search: {
    path: {
      prod: 'https://sauron-search-dev.segmentify.com/secure/v1/',
      rest: 'https://sauron-search-dev.segmentify.com/',
      admin: 'https://sauron-search-dev.segmentify.com/admin/v1/',
      adminV3: 'https://sauron-search-dev.segmentify.com/',
      insights: 'https://sauron-search-dev.segmentify.com/secure/v1/insights/',
      personalization:
        'https://sauron-search-dev.segmentify.com/secure/v2/campaign',
      personalisedAds: 'https://dev-utils.segmentify.com:6602/',
      password:
        'https://sauron-search-dev.segmentify.com/public/v1/multiaccount/set/password',
      login: 'https://v3-search-dev.segmentify.com/login',
      dashboard: 'https://v3-search-dev.segmentify.com/',
      gandalf: {
        eq: 'https://gandalf-dev.segmentify.com/',
        gc: 'https://gandalf-dev.segmentify.com/',
      },
    },
    endpoints: {
      token: {
        prod: 'https://sauron-search-dev.segmentify.com/getToken',
        check:
          'https://sauron-search-dev.segmentify.com/public/v1/multiaccount/checkUserCreateToken',
      },
    },
  },
  local: {
    path: {
      prod: 'http://localhost:8081/secure/v1/',
      rest: 'http://localhost:8081/',
      admin: 'http://localhost:8081/admin/v1/',
      adminV3: 'http://localhost:8081/',
      insights: 'http://localhost:8081/secure/v1/insights/',
      personalization: 'http://localhost:8081/secure/v2/campaign',
      personalisedAds: 'https://dev-utils.segmentify.com:6602/',
      password: 'http://localhost:8081/public/v1/multiaccount/set/password',
      login: 'http://localhost:3000/login',
      dashboard: 'http://localhost:3000',
      gandalf: {
        eq: 'http://localhost:8080/',
        gc: 'http://localhost:8080/',
      },
    },
    endpoints: {
      token: {
        prod: 'http://localhost:8081/tokenWithCaptcha',
        panel: 'http://localhost:8081/getToken',
        content: 'http://localhost:8081/getToken',
        check:
          'http://localhost:8081/public/v1/multiaccount/checkUserCreateToken',
      },
    },
  },
};

export const getApiPaths = () => urls[appEnv()].path;

export const getApiUrl = () => urls[appEnv()].path.rest;

export const getDashboardUrl = () => urls[appEnv()].path.dashboard;

export const getGandalfUrl = dataCenter =>
  urls[appEnv()].path.gandalf[dataCenter];

export const getExternalPersonalisedAds = () =>
  urls[appEnv()].path.personalisedAds;

export const config = {
  api: {
    version: {
      number: 2,
      nice_name: 'Varangerfjord',
    },
    path: {
      local: '/data/',
      prod: urls[env].path.prod,
      rest: urls[env].path.rest,
      admin: urls[env].path.admin,
      adminV3: urls[env].path.adminV3,
      insights: urls[env].path.insights,
      personalization: urls[env].path.personalization,
      password: urls[env].path.password,
    },
    endpoints: {
      token: {
        local: '/data/getToken.json',
        prod: urls[env].endpoints.token.prod,
        panel: urls[env].endpoints.token.panel,
        content: urls[env].endpoints.token.content,
        check: urls[env].endpoints.token.check,
      },
      admin: {
        accounts: {
          list: 'get/account/list.json',
          stats: 'get/account/stats.json',
          users: 'get/user/list.json',
          loginInfo: 'get/account/logininfo',
          gastats: 'get/account/gaStats.json',
          owners: '/account/list-owners',
          monitor: 'get/account/monitor',
          industries: 'account/list-industry',
          monitoringAll: 'monitoring/all',
          monitoringInd: 'monitoring/by-account',
        },
        prod: {
          userAccounts: 'get/account/all',
          multiUserUsers: 'multiaccount/list/users',
          editMultiUser: 'multiaccount/update/user',
          deleteMultiUser: 'multiaccount/delete/user',
          deleteLockedUser: 'delete/user/locked',
        },
        create: {
          user: 'create/user',
        },
        notifications: {
          load: 'load/panel/notifications',
          read: 'read/panel/notification',
        },
      },
      insights: {
        info: 'info',
        export: 'export/all',
      },
      rest: {
        v2: {
          campaign: 'v2/campaign',
          campaignHistory: 'v2/campaign/{CAMPAIGN_ID}',
        },
        campaign: {
          add: 'add/campaign',
          addV2: 'v2/add/campaign',
          update: 'update/campaign',
          updateV2: 'v2/update/campaign',
          test: 'test/campaign/',
          live: 'live/campaign/',
          activate: 'activate/campaign/',
          deactivate: 'deactivate/campaign/',
          duplicate: 'duplicate/campaign',
        },
        search: {
          product: 'search/product',
          productCSV: 'search/product/csv',
          randomProduct: 'push/randomproduct',
          products: 'search/products',
          promotion: 'search/promotion',
          category: 'search/category',
          property: 'search/property',
          'global-configuration': 'search/global-configuration',
          searchablePages: {
            get: 'search/searchable-page/all',
            update: 'search/searchable-page/batch',
          },
          adminVersions: 'search/schema/versions/campaigns',
          adminQueryLists: 'search/schema/{version}/query',
          adminUpdateQueryList: 'search/schema/query',
          fields: 'search/fields',
          rbs: {
            product: 'search/rbs/product',
            brand: 'search/rbs/brand',
            label: 'search/rbs/label',
            page: 'search/rbs/page',
            gender: 'search/rbs/gender/',
          },
          list: 'search/list',
        },
        iys: {
          dashboard: 'iys/dashboard',
        },
        experimentation: {
          create: 'ab-test/add',
          list: 'ab-test/list-by-module',
          get: 'ab-test/{instanceId}.json',
          update: 'ab-test/{instanceId}',
          campaignlist: 'ab-test/list-campaigns',
        },
        bundle: {
          search: 'bundle/search',
          crud: 'bundle',
          export: 'bundle/export',
          import: 'bundle/import',
        },
        countdown: {
          search: 'Countdown/search',
          crud: 'countdown',
        },
        gamification: {
          campaign: 'v2/campaign',
          wofExcelImport: 'v2/campaign/wheel-of-fortune/excel-import',
          scratchExcelImport: 'v2/campaign/scratch/excel-import',
          updatePriorities: 'v2/campaign/gamification/update-priorities',
        },
        analytics: {
          enable: {
            trendify: 'analytics/trendify/{accountId}',
            bannerify: 'analytics/bannerify/{accountId}',
          },
        },
        account: {
          archive: 'archive/account/{accountId}',
          update: 'update/account',
          trendifyactivate: 'activate/trendify',
          palantirActivate: 'activate/palantir',
          feed: 'info/feed/',
          recommendationSettings: '/update/recommendation',
          salesManagers: 'account/list-owners/SM',
          dashboardAccess: 'account/access/dashboard',
          userMailList: '/secure/v1/search/username',
          iys: {
            getSync: 'account/get/sync-data/iys',
            getInfo: 'account/get/account-iys',
            sync: 'account/sync/iys',
            create: 'account/create/iys',
          },
          noUpdate: 'account/no-update',
        },
        currency: {
          update: 'update/currency',
        },
        additionalCurrencyRegionList: {
          update: 'update/additionalCurrencyRegionList',
        },
        sales: {
          detail: 'search/products',
          banner: 'banner/info',
        },
        emails: {
          list: 'mail/list',
          updatestatus: 'mail/update',
          getCampaign: 'mail/campaign',
          update: 'mail/update',
          templates: 'mail/templates',
          test: 'mail/test',
          config: 'mail/register',
          disableConfig: 'mail/unregister',
          add: 'mail/custom',
          'custom-template': 'mail/custom-template',
          'search-template': 'mail/custom-template/search',
          'merge-tags': 'mail/merge-tags',
          spamCheck: 'mail/spam-check',
          authorizedTotalUserCount: 'mail/users/get/granted-users',
          analytics: 'mail/analytics',
          activeUsers: 'mail/analytics/active-users',
          unsubReport: 'mail/unsub/unsubCampaign',
          testUsers: 'mail/user-test-email',
          getMergeTags: 'mail/custom/merge-tags',
          createMergeTags: 'mail/custom/merge-tags',
          deleteMergeTags: 'mail/custom/merge-tags',
          updateMergeTags: 'mail/custom/merge-tags',
          getCampaigns: 'mail/segment/get-campaign',
          getCounts: 'mail/segment/get-count',
          activityReport: 'mail/activity-report',
          couponCodes: 'mail/campaign/coupon/file/upload',
          bounceBackReport: 'mail/bounce-report',
          bounceBackReportExport: 'mail/bounce-report/export',
        },
        push: {
          list: 'push/campaign/list',
          activeList: 'push/actives',
          configweb: 'configuration/push/web',
          configandroid: 'configuration/push/android',
          configios: 'configuration/push/ios',
          configsafari: 'configuration/push/safari',
          getsw: 'configuration/push/sw',
          getmanifest: 'configuration/push/manifest',
          campaign: 'push/campaign',
        },
        rbs: {
          campaign: 'rbs/campaign',
          userCount: 'rbs/campaign/user-count',
        },
        ds: {
          getCampaignGroupList: 'dynamic-segment/group',
          addCampaign: 'dynamic-segment',
          addGroup: 'dynamic-segment/group',
          moveCampaignToGroup: 'dynamic-segment/move-to-group',
          changeCampaignStatus: '/dynamic-segment/campaign',
          getAttributeByType: 'secure/v1/get/account/list',
        },
        customerProfile: {
          list: 'customer-profile',
        },
        stl: {
          base: 'shop-the-look',
          category: 'category/bundle',
          export: 'shop-the-look/export',
          import: 'shop-the-look/import',
        },
        replenishment: {
          list: 'replenishment/list-replenishment-products',
          update: 'replenishment/update-products',
        },
        palantir: {
          get: 'search',
          add: 'search/add',
          update: 'search/update',
          archive: 'search/archive',
          live: 'search/live',
          test: 'search/test',
          config: 'update/search',
          getFields: 'search/fields',
          analytics: {
            noresult: 'search/analytics/noresult',
            overall: 'search/analytics/overall',
            popular: 'search/analytics/popular',
            valuable: 'search/analytics/valuable',
          },
        },
        userManagement: {
          getUserTypes: 'usermanagement/user-types/list',
          getUserList: 'usermanagement/user/list',
          getUserHistory: 'usermanagement/history/list/{username}',
          getUserAccounts: 'usermanagement/list/account',
          getResponsibleAccountsByUser:
            '/usermanagement/responsible-account/{username}',
          searchUser: 'usermanagement/user/search/?searchParam={param}',
          deleteUser: 'usermanagement/delete/{username}',
          editUser: 'usermanagement/edit-user',
          unlockUser: 'usermanagement/unlock-user/{username}',
        },
        settings: {
          product: 'product',
          relation: 'relation',
        },
        facetedSearch: {
          get: 'faceted-search/list',
          getSingle: 'faceted-search/{instanceId}',
          add: 'faceted-search',
          update: 'faceted-search/{instanceId}',
          facets: 'faceted-search/facets',
          ordering: 'faceted-search/orders',
          archive: 'faceted-search/{instanceId}/ARCHIVE',
          draft: 'faceted-search/draft/{instanceId}',
          updateStatus: 'faceted-search/{instanceId}/{status}',
          campaignReport:
            'secure/v1/campaign/reports/faceted_campaign_trend.json',
          accountReport: 'secure/v1/get/report/faceted_account_trend.json',
        },
        searchbanner: {
          get: 'search/banner/list-by-campaign',
          upsert: 'search/banner/{instanceId}',
          list: 'search/banner/list',
        },
        ruleset: {
          getSingle: 'search/rule-set/{instanceId}',
          delete: 'search/rule-set/{instanceId}',
          add: 'search/rule-set',
          update: 'search/rule-set/{instanceId}',
          pinWizardSearch: 'search/execute',
        },
        upload: {
          file: 'file/upload',
        },
        feedback: {
          add: 'feedBack/add',
          get: 'feedBack/get',
        },
        'contact-us': {
          add: 'contact-us/add',
        },
        users: {
          create: 'multiaccount/add/user',
        },
        gdpr: {
          remove: 'gdpr/remove',
        },
        email: {
          list: 'mail/list',
          unsub: 'mail/unsub/mail-user',
        },
        report: {
          pushSingleReport: 'push/report/graphical',
        },
        segmentation: {
          list: 'segmentation/list',
          getByType: 'segmentation/segments',
          create: 'segmentation/create/segment',
          activate: 'segmentation/activate',
          get: 'segmentation',
          report: 'segmentation/report',
          stats: 'segmentation/stats',
        },
        promotion: {
          list: 'promotion',
        },
        contacts: {
          list: 'contacts',
          report: 'contacts/report/api',
          upload: 'contacts/file/upload',
          update: 'contacts/file/update',
          ftpGet: 'contacts/ftp-config',
          ftpUpdate: 'contacts/ftp-config/update',
          ftpConn: 'contacts/test-connection',
          ftpDownload: 'contacts/file/download',
          delete: 'contacts/delete',
        },
        recommendationTemplate: {
          templates: 'recommendation-widget-template',
        },
      },
      report: {
        account_trend: 'get/report/account_trend.json',
        campaign_trend: 'get/report/campaign_trend.json',
        campaign_trend_v2: 'get/report/campaign_trend_v2.json',
        campaign_trends: 'campaign/reports/campaign_trend.json',
        campaign_trends_v2: 'campaign/reports/campaign_trend_v2.json',
        stats_trend: 'get/report/stats_trend.json',
        google_analytics_trend: 'get/report/analytics_trend.json',
        google_analytics_dashboard_trend: 'get/report/stats_trend_ga.json',
        newsletters_trend: 'get/form/list.json',
        newsletter_trend: 'get/form/',
        newsletter_export: 'export/form/csv',
        emails: 'mail/list',
        emailMultiReport: 'campaign/reports/mail_campaign_trend.json',
        emailSingleReport: 'get/report/mail_campaign_trend.json',
        pushMultiReport: 'campaign/reports/push_campaign_trend.json',
        pushSingleReport: 'get/report/push_campaign_trend.json',
        key_metrics: 'get/report/key_metrics.json',
        search: 'get/report/search.json',
        searchDetailReport: 'get/reports/search.json',
        searchMultiReport: 'campaign/reports/search.json',
        experimentation: 'get/ab-report/{instanceId}',
      },
      emails: {
        sampleContent: 'mail/info/{CAMPAIGN_ID}',
        account_trend: 'get/report/mail_account_trend.json',
        list: 'get/mail/list',
        activeList: 'get/mail/actives',
        getCampaign: 'get/mail',
      },
      push: {
        sampleContent: 'push/info/{CAMPAIGN_ID}',
        account_trend: 'get/report/push_account_trend.json',
        list: 'get/push/list',
        activeList: 'get/push/actives',
        campaign: 'get/push',
        permissionreport: 'get/push/subscriptions',
        activeUsers: 'push/active-users',
        reachableUsers: 'push/reachable-users',
        segmentUsers: 'push/segment-users',
      },
      rbs: {
        list: 'get/rbs/list',
        campaign: 'get/rbs',
      },
      palantir: {
        get: 'search',
        add: 'search/add',
        update: 'search/update',
        archive: 'search/archive',
        live: 'search/live',
        test: 'search/test',
      },
      user: {
        current: 'get/user/current.json',
        locked: 'get/user/locked',
        update: {
          user: 'update/user.json',
          account: 'update/account.json',
        },
      },
      account: {
        page: 'get/account/list/pageCategory.json',
        brand: 'get/account/list/productBrand.json',
        label: 'get/account/list/accountLabel',
        color: 'get/account/list/productColor',
        size: 'get/account/list/productSize',
        gender: 'get/account/list/productGender',
        segment: 'get/account/list/userSegment.json',
        category: 'get/account/list/intProductCategory.json',
        banner: {
          group: 'get/account/banner/group.json',
          title: 'get/account/banner/title.json',
        },
        payments: 'get/account/ipnRecords.json',
      },
      campaign: {
        all: 'get/campaign/all.json',
        allModules: 'get/sales/all.json',
        single: 'get/campaign/{CAMPAING_ID}.json',
        active: 'count/campaigns/ACTIVE.json',
        history: 'get/campaign/{CAMPAING_ID}.json?withOps=true',
        getWithModules: 'campaign/allCampaigns',
      },
      integration: {
        all: 'get/integration/all.json',
      },
      template: {
        all: 'get/template/all.json',
      },
      trendify: {
        activate: 'activate/trendify',
        product: {
          count: 'trendify/product/count.json',
          list: 'trendify/product/list.json',
          export: 'trendify/product/export.json',
          single: 'trendify/product/single.json',
          total: 'trendify/product/total.json',
        },
        category: {
          count: 'trendify/category/count.json',
          list: 'trendify/category/list.json',
          export: 'trendify/category/export.json',
          single: 'trendify/category/single.json',
          total: 'trendify/category/total.json',
        },
        brand: {
          count: 'trendify/brand/count.json',
          list: 'trendify/brand/list.json',
          export: 'trendify/brand/export.json',
          single: 'trendify/brand/single.json',
          total: 'trendify/brand/total.json',
        },
        filters: {
          get: 'trendify/filter/list',
          update: 'trendify/filter/update',
          add: 'trendify/filter/add',
          remove: 'trendify/filter/delete',
        },
      },
      bannerify: {
        banner: {
          list: 'bannerify/banner/list.json',
          single: 'bannerify/banner/single',
          count: 'bannerify/banner/count',
        },
      },
      export: {
        campaign: 'export/campaign/excel',
        engagement: 'export/engagement/excel',
        recommendation: 'export/campaign/excel',
        dashboard: 'export/dashboard/csv',
        bannerify: 'bannerify/export.json',
        mail: 'export/mail/excel',
        push: 'export/push/excel',
        special: {
          userIds: 'export/userIds',
          interactions: 'export/engagement/clicks',
        },
        all: {
          campaign: 'export/all/campaign',
          mail: 'export/all/mail',
          push: 'export/all/push',
          search: 'export/all/search',
          segmentation: 'export/all/segmentation',
          journeyBuilder: 'export/all/journeyBuilder',
        },
        searchInsights: 'export/search-insights',
      },
      payment: {
        iyzico: 'iyzico/init.json',
      },
      sales: {
        list: 'gmv.json',
        export: 'gmv/export.json',
      },
    },
  },
  maintenanceMode: false,
  defaultTitle: 'Segmentify',
  languages: [
    {
      name: 'english',
      nativeName: 'English',
      code: 'en',
      direction: 'ltr',
    },
    {
      name: 'french',
      nativeName: 'Français',
      code: 'fr',
      direction: 'ltr',
    },
    {
      name: 'turkish',
      nativeName: 'Türkçe',
      code: 'tr',
      direction: 'ltr',
    },
    {
      name: 'german',
      nativeName: 'Deutsch',
      code: 'de',
      direction: 'ltr',
    },
    {
      name: 'spanish',
      nativeName: 'Spanish',
      code: 'es',
      direction: 'ltr',
    },
  ],
  menus: {
    primary: {
      trendify: {
        name: 'Segmentify Analytics',
        icon: 'segmentifyAnalytics',
        cssClass: 'trendify',
        slug: '/trendify/insights',
        svg: true,
        children: {
          insights: {
            name: 'Insights',
            name_alt: '',
            slug: '/trendify/insights',
          },
          dashboard: {
            name: 'Products',
            name_alt: '',
            slug: '/trendify',
          },
          categories: {
            name: 'Categories',
            name_alt: '',
            slug: '/trendify/categories',
          },
          brands: {
            name: 'Brands',
            name_alt: '',
            slug: '/trendify/brands',
          },
          sales: {
            name: 'Sales',
            name_alt: '',
            slug: '/trendify/sales',
            visible: false,
          },
        },
        extChildren: {
          dashboard: {
            name: 'Products',
            name_alt: '',
            slug: '/ext/trendify',
          },
          categories: {
            name: 'Categories',
            name_alt: '',
            slug: '/ext/trendify/categories',
          },
          brands: {
            name: 'Brands',
            name_alt: '',
            slug: '/ext/trendify/brands',
          },
          sales: {
            name: 'Sales',
            name_alt: '',
            slug: '/ext/trendify/sales',
            visible: false,
          },
          insights: {
            name: 'Insights',
            name_alt: '',
            slug: '/ext/trendify/insights',
          },
        },
      },
      growthSolutionSep: {
        separator: true,
        header: 'Growth Solution',
      },
      growthSolutionHeader: {
        header: true,
        name: 'Growth Solution',
      },
      recommendations: {
        name: 'Recommendation',
        icon: 'heart',
        slug: '/recommendations',
        children: {
          'view-all': {
            name: 'View All',
            name_alt: '',
            slug: '/recommendations/view-all',
          },
          home: {
            name: 'Home',
            name_alt: 'Page',
            slug: '/recommendations/home',
          },
          category: {
            name: 'Category',
            name_alt: 'Page',
            slug: '/recommendations/category',
          },
          product: {
            name: 'Product',
            name_alt: 'Page',
            slug: '/recommendations/product',
          },
          basket: {
            name: 'Basket',
            name_alt: 'Page',
            slug: '/recommendations/basket',
          },
          'checkout-success': {
            name: 'Checkout Success',
            name_alt: 'Page',
            slug: '/recommendations/checkout-success',
          },
          search: {
            name: 'Search',
            name_alt: 'Page',
            slug: '/recommendations/search',
          },
          notfound: {
            name: '404',
            name_alt: 'Page',
            slug: '/recommendations/notfound',
          },
          custom: {
            name: 'Custom',
            name_alt: 'Pages',
            slug: '/recommendations/custom',
          },
          triggerbased: {
            name: 'Trigger Based',
            name_alt: '',
            slug: '/recommendations/triggerbased',
          },
          popup_recommendation: {
            name: 'Popup Recommendation',
            name_alt: '',
            slug: '/recommendations/popup_recommendation',
          },
          journey_builder: {
            name: 'Journey Builder',
            name_alt: '',
            slug: '/recommendations/journey_builder',
          },
          experimentation: {
            name: 'A/B Testing',
            name_alt: '',
            slug: '/recommendations/experimentation/ab_test/list',
          },
          promotion: {
            name: 'Promotion List',
            name_alt: '',
            slug: '/recommendations/promotion/list',
          },
          templates: {
            name: 'Widget Template Library',
            name_alt: '',
            slug: '/recommendations/templates/list',
          },
        },
      },
      'behavioural-targeting': {
        name: 'Engagement',
        icon: 'behaviouralTargeting',
        svg: true,
        slug: '/behavioural-targeting/engagement/view-all',
        visible: false,
        children: {
          'view-all': {
            name: 'View All',
            name_alt: '',
            slug: '/behavioural-targeting/engagement/view-all',
          },
          popup: {
            name: 'Popup Banner',
            name_alt: '',
            icon: 'popup',
            svg: true,
            slug: '/behavioural-targeting/engagement/popup',
          },
          form_survey: {
            name: 'Form',
            name_alt: 'Survey',
            icon: 'formsurvey',
            svg: true,
            slug: '/behavioural-targeting/engagement/form-survey',
          },
          notification_bar: {
            name: 'Notification Bar',
            name_alt: '',
            icon: 'notification',
            svg: true,
            slug: '/behavioural-targeting/engagement/notification-bar',
          },
          segmentation: {
            name: 'Custom Segments',
            name_alt: '',
            slug: '/behavioural-targeting/engagement/segmentation',
          },
          'engagementPlus-header': {
            name: 'Engagement Plus',
            header: true,
          },
          social_proof: {
            name: 'Social Proof',
            name_alt: '',
            icon: 'socialProof',
            svg: true,
            slug: '/behavioural-targeting/engagement/social-proof',
          },
          gamification: {
            name: 'Gamification',
            name_alt: '',
            icon: 'gamification',
            svg: true,
            slug: '/behavioural-targeting/engagement/gamification/list',
          },
          hero_banner: {
            name: 'Hero Banner',
            name_alt: '',
            icon: 'heroBanner',
            svg: true,
            slug: '/behavioural-targeting/engagement/hero-banner',
          },
          countdown: {
            name: 'Countdown',
            name_alt: '',
            icon: 'countdown',
            svg: true,
            slug: '/behavioural-targeting/engagement/countdown/list',
          },
          persona_quiz: {
            name: 'Persona Quiz',
            name_alt: '',
            icon: 'personaQuiz',
            svg: true,
            slug: '/behavioural-targeting/engagement/persona-quiz',
          },
          'see-all': {
            name: 'See All',
            name_alt: '',
            icon: 'seeAll',
            svg: true,
            slug: '/behavioural-targeting/engagement/see-all',
          },
          email_collection: {
            name: 'E-Mail Collection',
            name_alt: '',
            icon: 'email',
            svg: true,
            slug: '/behavioural-targeting/engagement/email-collection',
            visible: true,
          },
          experimentation: {
            name: 'A/B Testing',
            name_alt: '',
            slug: '/behavioural-targeting/experimentation/ab_test/list',
          },
        },
      },
      dynamic_bundle: {
        name: 'Dynamic Bundles',
        icon: 'dynamicBundleIcon',
        svg: true,
        slug: '/dynamic_bundle',
        children: {
          automatedBundles: {
            name: 'Automated Bundles',
            name_alt: '',
            slug: '/dynamic_bundle/automated-bundles/list',
          },
          shopTheLook: {
            name: 'Shop The Look',
            name_alt: '',
            slug: '/dynamic_bundle/shop_the_look/list',
          },
        },
      },
      searchAndDiscoverySep: {
        separator: true,
      },
      searchAndDiscoveryHeader: {
        header: true,
        name: 'Search & Discovery',
      },
      search: {
        name: 'Search & Discovery',
        icon: 'search',
        svg: true,
        slug: '/search',
        children: {
          'view-all': {
            name: 'View All',
            name_alt: '',
            slug: '/search/view-all',
          },
          searchbox: {
            name: 'Search Box',
            name_alt: '',
            slug: '/search/searchbox',
          },
          searchandising: {
            name: FACETED_SEARCH_NAME,
            name_alt: '',
            slug: '/search/faceted',
          },
          'global-configuration': {
            name: 'Global Configuration',
            name_alt: '',
            slug: '/search/global-configuration',
          },
        },
      },
      channelMarketingSep: {
        separator: true,
      },
      channelMarketingHeader: {
        header: true,
        name: 'Channel Marketing',
      },
      email: {
        name: 'Email Marketing',
        icon: 'emailModule',
        svg: true,
        slug: '/email',
        children: {
          'view-all': {
            name: 'View All',
            name_alt: '',
            slug: '/email/view-all',
          },
          winback: {
            name: 'Winback',
            name_alt: '',
            icon: 'winback',
            svg: true,
            slug: '/email/winback',
          },
          growth: {
            name: 'Growth',
            name_alt: '',
            icon: 'growth',
            svg: true,
            slug: '/email/growth',
          },
          retention: {
            name: 'Retention',
            name_alt: '',
            icon: 'retention',
            svg: true,
            slug: '/email/retention',
          },
          recommendation: {
            name: 'Bulk / Segmented',
            name_alt: '',
            icon: 'bulk',
            svg: true,
            slug: '/email/recommendation',
          },
          flymode: {
            name: 'Fly Mode',
            name_alt: '',
            icon: 'fly',
            svg: true,
            slug: '/email/fly',
          },
          'email-collection': {
            name: 'E-Mail Collection',
            name_alt: '',
            icon: 'email',
            svg: true,
            slug: '/email/email-collection',
            visible: true,
          },
          'email-templates': {
            name: 'Email Templates',
            name_alt: '',
            slug: '/email/templates',
            icon: 'EmailTemplatesIcon',
            svg: true,
          },
          'email-activity-report': {
            name: 'Mail Activity Report',
            name_alt: '',
            slug: '/email/activity-report',
            icon: 'emailScheduleTimeClock',
            svg: true,
          },
        },
      },
      push: {
        name: 'Web Push',
        icon: 'pushModule',
        svg: true,
        slug: '/push',
        children: {
          'view-all': {
            name: 'View All',
            name_alt: '',
            slug: '/push/view-all',
          },
          permission: {
            name: 'Permission Prompt',
            name_alt: '',
            slug: '/push/permission',
            icon: 'permission',
            svg: true,
          },
          bulk: {
            name: 'Bulk / Segmented',
            name_alt: '',
            slug: '/push/bulk',
            icon: 'bulk',
            svg: true,
          },
          recommendation: {
            name: 'Recommendation',
            name_alt: '',
            slug: '/push/recommendation',
            icon: 'reco_cmp',
            svg: true,
          },
          winback: {
            name: 'Winback',
            name_alt: '',
            slug: '/push/winback',
            icon: 'winback',
            svg: true,
          },
          growth: {
            name: 'Growth',
            name_alt: '',
            slug: '/push/growth',
            icon: 'growth',
            svg: true,
          },
          retention: {
            name: 'Retention',
            name_alt: '',
            slug: '/push/retention',
            icon: 'retention',
            svg: true,
          },
          fly: {
            name: 'Fly Mode',
            name_alt: '',
            slug: '/push/fly',
            icon: 'fly',
            svg: true,
          },
          betting: {
            name: 'Betting',
            name_alt: '',
            slug: '/push/betting',
            icon: 'betting',
            svg: true,
          },
        },
      },
      audience: {
        name: 'Customer Data Management',
        icon: 'audienceIcon',
        svg: true,
        slug: '/audience',
        visible: false,
        children: {
          'segmentation-header': {
            name: 'Segmentation',
            header: true,
          },
          'all-segments': {
            name: 'All Segments',
            name_alt: '',
            slug: '/audience/segmentation/view-all',
          },
          'smart-segments': {
            name: 'Smart Segmentation',
            name_alt: '',
            slug: '/audience/segmentation/smart-segments',
          },
          'dynamic-segmentation': {
            name: 'Dynamic Segmentation',
            name_alt: '',
            slug: '/audience/dynamic-segmentation',
          },
          'rule-based-segmentation': {
            name: 'Rule Based Segmentation',
            name_alt: '',
            slug: '/audience/rule-based-segmentation',
          },
          'customer-profile': {
            name: 'Customer Profiles',
            name_alt: '',
            slug: '/audience/customer-profile',
          },
        },
      },
      personalisedAds: {
        name: 'Personalised Ads',
        icon: 'personalisedAds',
        cssClass: 'personalisedAds',
        slug: '/personalisedAds',
        svg: true,
      },
      reportsSep: {
        separator: true,
      },
      reportsHeader: {
        header: true,
        name: 'Reports',
      },
      dashboard: {
        name: 'Dashboard',
        icon: 'dashboardTfy',
        slug: '/',
        svg: true,
      },
      reports: {
        name: 'Reports',
        icon: 'pie-chart',
        slug: '/reports',
        children: {
          recommendations: {
            name: 'Recommendation',
            header: true,
          },
          'executive-summary': {
            name: 'Executive Summary',
            name_alt: '',
            slug: '/reports/all/recommendations/executive-summary',
            parent: 'Recommendation',
          },
          'all-recommendations': {
            name: 'View All',
            name_alt: '',
            slug: '/reports/all/recommendations',
            parent: 'Recommendation',
          },
          home: {
            name: 'Home',
            name_alt: 'Page',
            slug: '/reports/all/recommendations/home',
            parent: 'Recommendation',
          },
          category: {
            name: 'Category',
            name_alt: 'Page',
            slug: '/reports/all/recommendations/category',
            parent: 'Recommendation',
          },
          product: {
            name: 'Product',
            name_alt: 'Page',
            slug: '/reports/all/recommendations/product',
            parent: 'Recommendation',
          },
          basket: {
            name: 'Basket',
            name_alt: 'Page',
            slug: '/reports/all/recommendations/basket',
            parent: 'Recommendation',
          },
          'checkout-success': {
            name: 'Checkout Success',
            name_alt: 'Page',
            slug: '/reports/all/recommendations/checkout-success',
            parent: 'Recommendation',
          },
          search: {
            name: 'Search',
            name_alt: 'Page',
            slug: '/reports/all/recommendations/search',
            parent: 'Recommendation',
          },
          notfound: {
            name: '404',
            name_alt: 'Page',
            slug: '/reports/all/recommendations/notfound',
            parent: 'Recommendation',
          },
          custom: {
            name: 'Custom',
            name_alt: 'Pages',
            slug: '/reports/all/recommendations/custom',
            parent: 'Recommendation',
          },
          triggerbased: {
            name: 'Trigger Based',
            name_alt: '',
            slug: '/reports/all/recommendations/triggerbased',
            parent: 'Recommendation',
          },
          popup_recommendation: {
            name: 'Popup Recommendation',
            name_alt: '',
            slug: '/reports/all/recommendations/popup_recommendation',
            parent: 'Recommendation',
          },
          emails: {
            name: 'Email Marketing',
            header: true,
            slug: '',
            visible: false,
          },
          'executive-summary-emails': {
            name: 'Executive Summary',
            name_alt: '',
            slug: '/reports/all/email/executive-summary',
            visible: false,
            parent: 'Email Marketing',
          },
          'all-email': {
            name: 'View All',
            name_alt: '',
            slug: '/reports/all/email/view-all',
            visible: false,
            parent: 'Email Marketing',
          },
          'email-marketing-analytics': {
            name: 'Email Marketing Analytics',
            name_alt: '',
            slug: '/reports/all/email/analytics',
            visible: false,
            parent: 'Email Marketing',
          },
          winback: {
            name: 'Winback',
            name_alt: '',
            slug: '/reports/all/email/winback',
            icon: 'add-user',
            svg: true,
            parent: 'Email Marketing',
            visible: false,
          },
          growth: {
            name: 'Growth',
            name_alt: '',
            slug: '/reports/all/email/growth',
            icon: 'add-user',
            svg: true,
            parent: 'Email Marketing',
            visible: false,
          },
          retention: {
            name: 'Retention',
            name_alt: '',
            slug: '/reports/all/email/retention',
            icon: 'add-user',
            svg: true,
            parent: 'Email Marketing',
            visible: false,
          },
          recommendation: {
            name: 'Bulk / Segmented',
            name_alt: '',
            slug: '/reports/all/email/recommendation',
            icon: 'add-user',
            svg: true,
            parent: 'Email Marketing',
            visible: false,
          },
          flymode: {
            name: 'Fly Mode',
            name_alt: '',
            slug: '/reports/all/email/fly',
            icon: 'add-user',
            svg: true,
            parent: 'Email Marketing',
            visible: false,
          },
          em_email_collection: {
            // Email Marketing Email Collection
            name: 'Email Collection',
            name_alt: '',
            slug: '/reports/all/email/email_collection',
            visible: false,
            icon: 'email',
            svg: true,
            parent: 'Email Marketing',
          },
          push: {
            name: 'Web Push',
            header: true,
            visible: false,
          },
          'executive-summary-push': {
            name: 'Executive Summary',
            name_alt: '',
            slug: '/reports/all/push/executive-summary',
            visible: false,
            parent: 'Web Push',
          },
          'all-push': {
            name: 'View All',
            name_alt: '',
            slug: '/reports/all/push/view-all',
            visible: false,
            parent: 'Web Push',
          },
          'permission-push': {
            name: 'Permission Prompt',
            name_alt: '',
            slug: '/reports/all/push/permission',
            icon: 'add-user',
            svg: true,
            parent: 'Web Push',
            visible: false,
          },
          'bulk-push': {
            name: 'Bulk / Segmented',
            name_alt: '',
            slug: '/reports/all/push/bulk',
            icon: 'add-user',
            svg: true,
            parent: 'Web Push',
            visible: false,
          },
          betting: {
            name: 'Betting',
            name_alt: '',
            slug: '/reports/all/push/betting',
            icon: 'add-user',
            svg: true,
            parent: 'Web Push',
            visible: false,
          },
          'recommendation-push': {
            name: 'Recommendation',
            name_alt: '',
            slug: '/reports/all/push/recommendation',
            icon: 'add-user',
            svg: true,
            parent: 'Web Push',
            visible: false,
          },
          'winback-push': {
            name: 'Winback',
            name_alt: '',
            slug: '/reports/all/push/winback',
            icon: 'add-user',
            svg: true,
            parent: 'Web Push',
            visible: false,
          },
          'growth-push': {
            name: 'Growth',
            name_alt: '',
            slug: '/reports/all/push/growth',
            icon: 'add-user',
            svg: true,
            parent: 'Web Push',
            visible: false,
          },
          'retention-push': {
            name: 'Retention',
            name_alt: '',
            slug: '/reports/all/push/retention',
            icon: 'add-user',
            svg: true,
            parent: 'Web Push',
            visible: false,
          },
          fly: {
            name: 'Fly Mode',
            name_alt: '',
            slug: '/reports/all/push/fly',
            icon: 'add-user',
            svg: true,
            parent: 'Web Push',
            visible: false,
          },
          'behavioural-targeting': {
            name: 'Engagement',
            header: true,
            slug: '',
            visible: false,
          },
          'segmentation-module-all': {
            name: 'All Segments',
            name_alt: '',
            roles: [
              'ROLE_ACM',
              'ROLE_OBM',
              'ROLE_ACD',
              'ROLE_OBD',
              'ROLE_SM',
              'ROLE_AGU',
            ],
            slug: '/reports/all/segmentation/view-all',
            visible: false,
            parent: 'Engagement',
          },
          'executive-summary-engagement': {
            name: 'Executive Summary',
            name_alt: '',
            slug: '/reports/all/engagement/executive-summary',
            visible: false,
            parent: 'Engagement',
          },
          'all-personalizations': {
            name: 'View All',
            name_alt: '',
            slug: '/reports/all/engagement',
            visible: false,
            parent: 'Engagement',
          },
          gamification: {
            name: 'Gamification',
            name_alt: '',
            slug: '/reports/all/engagement/gamification',
            icon: 'gamification',
            svg: true,
            parent: 'Engagement',
          },
          countdown: {
            name: 'Countdown',
            name_alt: '',
            slug: '/reports/all/engagement/countdown',
            icon: 'countdown',
            svg: true,
            parent: 'Engagement',
          },
          'see-all': {
            name: 'See All',
            name_alt: '',
            slug: '/reports/all/engagement/see-all',
            visible: true,
            icon: 'seeAll',
            svg: true,
            parent: 'Engagement',
          },
          popup: {
            name: 'Popup',
            name_alt: 'Banner',
            slug: '/reports/all/engagement/popup',
            visible: false,
            icon: 'popup',
            svg: true,
            parent: 'Engagement',
          },
          hero_banner: {
            name: 'Hero Banner',
            name_alt: '',
            slug: '/reports/all/engagement/hero_banner',
            visible: false,
            icon: 'heroBanner',
            svg: true,
            parent: 'Engagement',
          },
          email_collection: {
            name: 'Email Collection',
            name_alt: '',
            slug: '/reports/all/engagement/email_collection',
            visible: false,
            icon: 'email',
            svg: true,
            parent: 'Engagement',
          },
          form_survey: {
            name: 'Form',
            name_alt: 'Survey',
            slug: '/reports/all/engagement/form_survey',
            visible: false,
            icon: 'formsurvey',
            svg: true,
            parent: 'Engagement',
          },
          notification_bar: {
            name: 'Notification Bar',
            name_alt: '',
            slug: '/reports/all/engagement/notification_bar',
            visible: false,
            icon: 'notification',
            svg: true,
            parent: 'Engagement',
          },
          palantir: {
            name: 'Search & Discovery',
            header: true,
            visible: false,
          },
          'executive-summary-search': {
            name: 'Executive Summary',
            name_alt: '',
            slug: '/reports/all/search',
            visible: false,
            parent: 'Search & Discovery',
          },
          'before-search-input': {
            name: 'Search Box Before Search',
            name_alt: '',
            slug: '/reports/all/search/BEFORE_SEARCH',
            parent: 'Search & Discovery',
          },
          'after-search-input': {
            name: 'Search Box After Search',
            name_alt: '',
            slug: '/reports/all/search/SEARCH',
            parent: 'Search & Discovery',
          },
          searchandising: {
            name: 'Searchandising',
            name_alt: '',
            slug: '/reports/all/search/searchandising',
            parent: 'Search & Discovery',
          },
          'search-insights': {
            name: 'Insights',
            name_alt: '',
            slug: '/reports/search/insights',
            parent: 'Search & Discovery',
          },
          'search-banners': {
            name: 'Banners',
            name_alt: '',
            slug: '/reports/all/search/banners',
            parent: 'Search & Discovery',
          },
          iys: {
            name: 'IYS',
            visible: false,
            header: true,
          },
          iys_dashboard: {
            name: 'IYS Dashboard',
            name_alt: '',
            slug: '/reports/all/iys/dashboard',
            parent: 'IYS',
          },
        },
      },
      sales: {
        name: 'Sales',
        icon: 'sales',
        cssClass: 'bannerify',
        slug: '/trendify/sales',
      },
      bannerify: {
        name: 'Bannerify',
        icon: 'bannerify',
        cssClass: 'bannerify',
        slug: '/bannerify',
      },
      settingSep: {
        separator: false,
      },
    },
    secondary: {
      settings: {
        name: 'Settings',
        icon: 'gear',
        slug: '/settings',
        children: {
          account: {
            name: 'Account',
            name_alt: '',
            icon: 'account',
            slug: '/settings/account',
          },
          plugins: {
            name: 'Plugins',
            name_alt: '',
            icon: 'envelope',
            slug: '/settings/plugins',
            visible: false,
          },
          integrations: {
            name: 'Integrations',
            name_alt: '',
            icon: 'puzzle',
            slug: '/settings/integrations',
          },
          currency: {
            name: 'Currency',
            name_alt: '',
            icon: 'numberAndCurrency',
            slug: '/settings/currency',
            svg: true,
          },
          recommendation: {
            name: 'Recommendation Settings',
            name_alt: '',
            icon: 'heart',
            slug: '/settings/recommendation',
            visible: true,
          },
          email: {
            name: 'Email Marketing',
            name_alt: '',
            icon: 'emailModule',
            svg: true,
            slug: '/settings/email',
            visible: true,
          },
          push: {
            name: 'Web Push',
            name_alt: '',
            icon: 'pushModule',
            svg: true,
            slug: '/settings/push',
            visible: true,
          },
          search: {
            name: 'Search & Discovery',
            name_alt: '',
            icon: 'search',
            slug: '/settings/search',
            svg: true,
            visible: true,
          },
          feed: {
            name: 'Feed',
            name_alt: '',
            icon: 'feedTrigger',
            svg: true,
            slug: '/settings/feed',
            visible: true,
            cssClass: 'feed-trigger',
          },
          productSearch: {
            name: 'Product Search',
            name_alt: '',
            icon: 'productSearch',
            svg: true,
            slug: '/settings/product-detail',
            visible: true,
          },
          relationLookUp: {
            name: 'Relation Lookup',
            name_alt: '',
            icon: 'relationLookUp',
            svg: true,
            slug: '/settings/relation-lookup',
            visible: true,
          },
          userSearch: {
            name: 'User Search',
            name_alt: '',
            icon: 'userDetail',
            svg: true,
            slug: '/settings/user-detail',
            visible: true,
          },
          replenishment: {
            name: 'Replenishment',
            name_alt: '',
            icon: 'replenishment',
            svg: true,
            slug: '/settings/replenishment',
            visible: true,
          },
          'iys-integration': {
            name: 'IYS Settings',
            name_alt: '',
            icon: 'iysIcon',
            svg: true,
            slug: '/settings/iys-integration',
            visible: true,
          },
          'no-update': {
            name: 'No Update',
            name_alt: '',
            icon: 'noUpdateIcon',
            svg: true,
            slug: '/settings/no-update',
            visible: true,
          },
          'contacts-header': {
            name: 'Contacts',
            header: true,
          },
          'contact-list': {
            name: 'Contact List',
            name_alt: '',
            icon: 'contact',
            svg: true,
            slug: '/settings/contact-list',
          },
          'contact-Report': {
            name: 'Contact API Request Report',
            name_alt: '',
            icon: 'pie',
            svg: true,
            slug: '/settings/contact-report',
          },
          'ftp-settings': {
            name: 'FTP Settings',
            name_alt: '',
            icon: 'ftpSettings',
            svg: true,
            slug: '/settings/ftp-settings',
          },
        },
      },
    },
    external: {
      dashboard: {
        name: 'Dashboard',
        slug: '/ext/dashboard',
      },
      campaigns: {
        name: 'Campaigns',
        slug: '/ext/campaigns',
      },
      trendify: {
        name: 'Segmentify Analytics',
        slug: '/ext/trendify',
        cssClass: 'trendify',
      },
      bannerify: {
        name: 'Bannerify',
        slug: '/ext/bannerify',
        cssClass: 'bannerify',
        default: false,
      },
      guide: {
        name: 'Guide',
        slug: '/ext/guide',
      },
      push: {
        name: 'Web Push',
        icon: 'pushModule',
        svg: true,
        slug: '/ext/push',
        children: {
          'view-all': {
            name: 'View All',
            name_alt: '',
            slug: '/ext/push/view-all',
          },
          bulk: {
            name: 'Bulk / Segmented',
            name_alt: '',
            slug: '/ext/push/bulk',
            icon: 'bulk',
            svg: true,
          },
          recommendation: {
            name: 'Recommendation',
            name_alt: '',
            slug: '/ext/push/recommendation',
            icon: 'reco_cmp',
            svg: true,
          },
          winback: {
            name: 'Winback',
            name_alt: '',
            slug: '/ext/push/winback',
            icon: 'winback',
            svg: true,
          },
          growth: {
            name: 'Growth',
            name_alt: '',
            slug: '/ext/push/growth',
            icon: 'growth',
            svg: true,
          },
          retention: {
            name: 'Retention',
            name_alt: '',
            slug: '/ext/push/retention',
            icon: 'retention',
            svg: true,
          },
          fly: {
            name: 'Fly Mode',
            name_alt: '',
            slug: '/ext/push/fly',
            icon: 'fly',
            svg: true,
          },
        },
      },
    },
  },
};
