import React from 'react';

import Icons from '../../components/icons';
import Icon from '../../components/icon';

/**
 * @name CampaignDevices component
 * @description Campaign component displays list of items and its actions
 * @param {devices} devices - list of devices
 * @param {isPushModule} isPushModule - boolean to check if push module
 * @returns {JSX.Element}
 */

const CampaignDevices = ({ devices, isPushModule }) => {
  return (
    <span className='campaign-devices'>
      {devices?.map(device => {
        if (isPushModule) if (device === 'TABLET') return null;
        return (
          <span key={device} className='widget-meta-device widget-meta-mobile'>
            {device === 'TABLET' ? (
              <Icon name={device.toLowerCase()} />
            ) : (
              <Icons name={device.toLowerCase()} />
            )}
            {device}
          </span>
        );
      })}
    </span>
  );
};

export default CampaignDevices;
