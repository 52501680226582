/**
 * Created by mehmetyurtar on 5/21/18.
 */
import React from "react";

class IconMegaphone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "20px",
      height: "20px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <g>
            <path
              d="M497,226h-46c-8.291,0-15,6.709-15,15s6.709,15,15,15h46c8.291,0,15-6.709,15-15S505.291,226,497,226z"
              fill={this.state.color}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M476.606,320.394l-30-30c-5.859-5.859-15.352-5.859-21.211,0s-5.86,15.352,0,21.211l30,30    c5.859,5.859,15.352,5.859,21.211,0S482.465,326.253,476.606,320.394z"
              fill={this.state.color}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M476.605,140.394c-5.859-5.859-15.352-5.859-21.211,0l-30,30c-5.859,5.859-5.859,15.352,0,21.211s15.352,5.86,21.211,0    l30-30C482.464,155.746,482.464,146.253,476.605,140.394z"
              fill={this.state.color}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M106,136c-19.53,0-36.024,12.578-42.237,30H61c-33.091,0-61,26.909-61,60s27.909,60,61,60h2.763    c6.213,17.422,22.707,30,42.237,30h75V136H106z"
              fill={this.state.color}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M361,46c-16.569,0-30,13.431-30,30v300c0,16.567,13.431,30,30,30c16.569,0,30-13.433,30-30V76    C391,59.431,377.569,46,361,46z"
              fill={this.state.color}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M106,346c-5.136,0-10.151-0.529-15-1.518V421c0,24.814,20.186,45,45,45c24.814,0,45-20.186,45-45v-75H106z"
              fill={this.state.color}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M287.816,97.973c-20.92,20.92-47.855,33.224-76.816,36.563v182.929c28.962,3.338,55.897,15.643,76.816,36.563L301,367.211    V84.789L287.816,97.973z"
              fill={this.state.color}
            />
          </g>
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
      </svg>
    );
  }
}

export default IconMegaphone;
