import React, { useEffect, useState } from 'react';
import './CategorySelector.scss';
import Select from 'react-select';
import { modalActions } from '../../../actions';
import { t } from '../../../system/ui';
import { searchCategories } from '../../../modules/category/ajax';
import { decodeHtml } from '../../../system/string';
import { Attributes, ErrorText, SFYFilterButton } from '../module-exports';

const defaultFilterTypes = ['size', 'label', 'color', 'gender'];

const shouldShow = (filterParams, filterType) => {
  return filterParams?.[filterType] && filterParams[filterType].length > 0;
};

/**
 * @name CategorySelector
 * @description Component to select a category
 * @param {object} details - category details
 * @param {function} setFilterParams - parent setter for filter params
 * @param {object} filterParams - parent filter params
 * @param {array} - filterTypes - filter types to show
 * @param {function} onChange - parent onChange function
 * @return {JSX.Element}
 */
const CategorySelector = ({
  details,
  setFilterParams,
  filterParams,
  filterTypes = defaultFilterTypes,
  onChange,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(details?.key || '');
  const [categories, setCategories] = useState(
    details?.key
      ? [
          {
            value: details?.key,
            label: details?.key,
            title: details?.key,
          },
        ]
      : [],
  );
  const [isTouched, setIsTouched] = useState(false);
  const [hideInclude, setHideInclude] = useState(
    shouldShow(filterParams, 'inclusions'),
  );
  const [hideExclude, setHideExclude] = useState(
    shouldShow(filterParams, 'exclusions'),
  );

  const onInputChange = value => {
    setIsTouched(true);

    if (value.length > 2) {
      searchCategories(value, response => {
        const categoryOptions = [];
        if (response) {
          response.forEach(category => {
            categoryOptions.push({
              value: decodeHtml(category),
              title: decodeHtml(category),
              label: decodeHtml(category),
            });
          });
        }
        setCategories(categoryOptions);
      });
    } else if (selectedCategory) {
      setCategories([
        {
          value: selectedCategory,
          title: selectedCategory,
          label: selectedCategory,
        },
      ]);
    } else {
      setCategories([]);
    }
  };

  const onCategoryChange = selectedValue => {
    if (!selectedValue) {
      setSelectedCategory('');
      setCategories([]);
    } else {
      setSelectedCategory(selectedValue.value);
      setCategories([
        {
          value: selectedValue.value,
          title: selectedValue.value,
          label: selectedValue.value,
        },
      ]);
    }

    const categoryBundle = {
      type: 'category',
      key: selectedValue?.value || '',
      params: details?.params || {},
    };

    onChange(categoryBundle);
  };

  const toggleFilterAction = filter => {
    switch (filter) {
      case 'inclusions':
        setHideInclude(!hideInclude);
        break;
      case 'exclusions':
        setHideExclude(!hideExclude);
        break;
      default:
    }
  };

  useEffect(() => {
    if (filterParams?.inclusions && !hideInclude) {
      setFilterParams({ ...filterParams, inclusions: [] });
    }
    modalActions.calculateModalHeight();
  }, [hideInclude]);

  useEffect(() => {
    if (filterParams?.exclusions && !hideExclude) {
      setFilterParams({ ...filterParams, exclusions: [] });
    }
    modalActions.calculateModalHeight();
  }, [hideExclude]);

  useEffect(() => {
    setSelectedCategory(details?.key);
    setCategories([
      {
        value: details?.key,
        label: details?.key,
        title: details?.key,
      },
    ]);
    setFilterParams({
      exclusions: details.params.exclusions || [],
      inclusions: details.params.inclusions || [],
    });
  }, [details.key]);

  return (
    <div className='sfy-card-bundle'>
      <div className='sfy-card-nav'>
        <div className='sfy-card-nav-select'>
          <span className='sfy-card-select-title'>Category:</span>
          <div className='sfy-card-select'>
            <Select
              value={selectedCategory}
              options={categories}
              name='category'
              clearable={false}
              searchable
              onChange={e => onCategoryChange(e)}
              onInputChange={onInputChange}
              className={`bundle-select one-whole ${!selectedCategory &&
                isTouched &&
                'has-error'}`}
              openOnClick
              placeholder={t('Select or Type')}
              autoFocus
            />
          </div>
          {!selectedCategory && isTouched && <ErrorText />}
        </div>
        <div className='sfy-card-nav-actions'>
          <SFYFilterButton
            filterType='inclusions'
            filterName='Include Filters'
            buttonIcon='plusCircleSmall'
            toggleFilterAction={toggleFilterAction}
          />

          <SFYFilterButton
            filterType='exclusions'
            filterName='Exclude Filters'
            buttonIcon='minusCircleSmall'
            toggleFilterAction={toggleFilterAction}
          />
        </div>
      </div>

      <div>
        {hideInclude && (
          <Attributes
            title='Include Filters'
            titleIcon='plusCircleSmall'
            titleColor='#8ea976'
            description='Include filters allows you to highlight the products you need in your recommendations based on your selected attributes.'
            segmentType='inclusions'
            bundleIndex={0}
            bundleParams={details.params}
            setFilterParams={setFilterParams}
            filterParams={filterParams}
            filterTypes={[
              'color',
              'brand',
              'gender',
              'label',
              'size',
              'stock',
              'priceRange',
              'custom',
            ]}
          />
        )}
        {hideExclude && (
          <Attributes
            title='Exclude Filters'
            titleIcon='minusCircleSmall'
            titleColor='#c54343'
            description='Exclude filters allows you to narrow down your recommendation selection to the products that meet your criteria'
            segmentType='exclusions'
            bundleIndex={0}
            bundleParams={details.params}
            setFilterParams={setFilterParams}
            filterParams={filterParams}
            filterTypes={[
              'color',
              'gender',
              'brand',
              'label',
              'product',
              'size',
              'stock',
              'priceRange',
              'custom',
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default CategorySelector;
