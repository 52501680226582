/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import React from 'react';
import { browserHistory } from 'react-router';
import { createAction } from '../system/store';
import { t } from '../system/ui';

export const redirect = url => {
  browserHistory.push(url);
};

export const isLoading = () => createAction('IS_LOADING');

export const isLoaded = () => createAction('IS_LOADED');

export const hasSubmenu = menu => createAction('HAS_SUBMENU', { menu });

export const hideBreadcrumb = () => createAction('HIDE_BREADCRUMB');

export const showBreadcrumb = breadcrumb =>
  createAction('HAS_BREADCRUMB', { breadcrumb });

export const removePageClass = () => createAction('REMOVE_PAGE_CLASS');

export const addPageClass = pageClass =>
  createAction('ADD_PAGE_CLASS', { pageClass });

export const resetPage = () => {
  hideBreadcrumb();
  removePageClass();
};

export const updateTitle = browserTitle =>
  createAction('UPDATE_BROWSER_TITLE', { browserTitle });

export const updateLanguage = language =>
  createAction('UPDATE_LANGUAGE', { language });

export const isValidating = () => createAction('VALIDATE_FORMS');
export const isValidated = () => createAction('VALIDATED_FORMS');

export const validatingTriggered = () => createAction('VALIDATING_TRIGGERED');
export const validatedTriggered = () => createAction('VALIDATED_TRIGGERED');

export const formEdited = () => createAction('FORM_EDITED');
export const formSaved = () => createAction('FORM_SAVED');

export const openModal = options => {
  const defaults = {
    title: '',
    subtitle: '',
    content: '',
  };

  const modal = { ...defaults, ...options };

  createAction('OPEN_MODAL', modal);
};

export const closeModal = () => createAction('CLOSE_MODAL');

export const showNotification = options => {
  const defaults = {
    title: '',
    content: '',
  };

  const notification = { ...defaults, ...options };

  createAction('SHOW_NOTIFICATION', notification);
};

export const showErrorNotification = message => {
  return showNotification({
    content: message,
    className: 'notification-fail',
  });
};

export const confirmationDialog = options => {
  const defaults = {
    title: 'Attention!',
    content: 'This action will immediately effect on your website',
    onConfirm: () => {
      return false;
    },
    onCancel: () => {
      return false;
    },
  };

  const confirmation = { ...defaults, ...options };

  createAction('OPEN_DIALOG', confirmation);
};

export const confirmOnLeave = comp => {
  const preventedFunc = e => {
    const confirmationMessage = 'o/';

    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  const routeChangeConfirmation = nextLocation => {
    if (
      nextLocation.pathname === '/user/accounts' ||
      nextLocation.pathname.includes('email-sgm')
    ) {
      comp.setState(
        {
          saved: true,
        },
        () => {
          window.removeEventListener('beforeunload', preventedFunc);
          comp.props.router.push(nextLocation);
        },
      );
    } else if (!comp.state.saved) {
      confirmationDialog({
        content: t('Your work is not saved! Are you sure want to leave?'),
        onConfirm: () => {
          comp.setState(
            {
              saved: true,
            },
            () => {
              window.removeEventListener('beforeunload', preventedFunc);
              comp.props.router.push(nextLocation);
            },
          );
        },
      });

      return false;
    }
  };

  window.addEventListener('beforeunload', preventedFunc);

  comp.props.router.setRouteLeaveHook(
    comp.props.route,
    routeChangeConfirmation,
  );
};

export const engagementConfirmOnLeave = comp => {
  const preventedFunc = e => {
    const confirmationMessage = 'o/';

    e.returnValue = confirmationMessage;
    return confirmationMessage;
  };

  const routeChangeConfirmation = nextLocation => {
    if (!comp.state.saved && nextLocation.pathname.indexOf('edit') === -1) {
      confirmationDialog({
        content: t('Your work is not saved! Are you sure want to leave?'),
        onConfirm: () => {
          comp.setState(
            {
              saved: true,
            },
            () => {
              if (window.location?.state?.smartSegmentId) {
                delete window.location.state;
              }
              window.removeEventListener('beforeunload', preventedFunc);
              comp.props.router.push(nextLocation);
            },
          );
        },
      });

      return false;
    }
  };

  window.addEventListener('beforeunload', preventedFunc);

  comp.props.router.setRouteLeaveHook(
    comp.props.route,
    routeChangeConfirmation,
  );
};

export const notifyRequestError = errorData => {
  let text = errorData.statusText;
  if (
    errorData.response === "Can't create user, user exists with same email."
  ) {
    text = errorData.response;
  }

  const message = () => {
    return (
      <div>
        <p>{text}</p>
      </div>
    );
  };

  return showErrorNotification(message);
};

export const showBadResponseCat = () => {
  createAction('SHOW_BAD_RESPONSE_CAT');
};

export const hideBadResponseCat = () => {
  createAction('HIDE_BAD_RESPONSE_CAT');
};

export default {
  redirect,
  isLoaded,
  isLoading,
  hasSubmenu,
  showBreadcrumb,
  hideBreadcrumb,
  addPageClass,
  removePageClass,
  resetPage,
  updateTitle,
  updateLanguage,
  isValidating,
  isValidated,
  formSaved,
  formEdited,
  openModal,
  showNotification,
  showErrorNotification,
  confirmationDialog,
  confirmOnLeave,
  engagementConfirmOnLeave,
  notifyRequestError,
  validatedTriggered,
  validatingTriggered,
  showBadResponseCat,
  hideBadResponseCat,
};
