import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const spFavoriteActivity = ({ width, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      fill='none'
    >
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M9.6961 14.8559L5.59019 10.064C4.81469 9.1589 4.79928 7.82819 5.55362 6.90541C6.4661 5.78917 8.1384 5.69296 9.1729 6.69719L9.9999 7.5L10.8269 6.69722C11.8615 5.69296 13.5339 5.78922 14.4464 6.90555C15.2008 7.82847 15.1854 9.1594 14.4097 10.0645L10.3036 14.8559C10.144 15.0422 9.8558 15.0422 9.6961 14.8559ZM20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18.5 10C18.5 5.30558 14.6944 1.5 10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5C14.6944 18.5 18.5 14.6944 18.5 10Z'
        fill={color}
      />
    </svg>
  );
};

spFavoriteActivity.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

spFavoriteActivity.defaultProps = {
  width: '20px',
  height: '20px',
  color: '#5D5D61',
};

export default spFavoriteActivity;
