import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import {
  isSuperUser,
  isSwitchAccount,
  mailProviderIsSegmentify,
} from '../../modules/auth/user';

import { t } from '../../system/ui';
import { modalActions, uiActions } from '../../actions';

import Icon from '../icon';
import Icons from '../icons';
import CampaignHistoryModal from '../../modules/campaigns/historymodal';
import { updateEmailStatus } from '../../modules/emails/ajax';
import { moveCampaignToArchive as movePushCampaignToArchive } from '../../modules/push/ajax';

class EmailWidgetActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [
        { name: 'Edit', icon: 'gear', action: 'edit' },
        { name: 'Report', icon: 'pie-chart', action: 'report' },
        {
          name: 'Passive',
          icon: 'archive',
          action: 'addToArchive',
          superUserOnly: true,
        },
        {
          name: 'History',
          icon: 'history',
          iconSvg: true,
          action: 'showCampaignHistory',
        },
      ],
      dropUp: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.edit = this.edit.bind(this);
    this.report = this.report.bind(this);
    this.addToArchive = this.addToArchive.bind(this);
    this.addToArchiveConfirmed = this.addToArchiveConfirmed.bind(this);
    this.showCampaignHistory = this.showCampaignHistory.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.duplicateCampaign = this.duplicateCampaign.bind(this);
  }

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
    const user = isSwitchAccount()
      ? this.props?.switchedUser
      : this.props?.user;
    const userRole = user?.userRole;
    if (userRole !== 'ADMIN') {
      const newActions = [
        { name: 'Edit', icon: 'gear', action: 'edit' },
        { name: 'Report', icon: 'pie-chart', action: 'report' },
        {
          name: 'History',
          icon: 'history',
          iconSvg: true,
          action: 'showCampaignHistory',
          superUserOnly: true,
        },
      ];
      if (
        !this.props.isReport &&
        (this.props.campaign.type === 'RECOMMENDATION' ||
          this.props.campaign.type === 'FLY')
      ) {
        newActions.push({
          icon: 'duplicate',
          handler: 'duplicateCampaign',
          name: 'Duplicate',
        });
      }
      this.setState({
        actions: newActions,
      });
    } else if (
      !this.props.isReport &&
      (this.props.campaign.type === 'RECOMMENDATION' ||
        this.props.campaign.type === 'FLY')
    ) {
      this.setState(prevState => {
        const newActions = prevState.actions.map(item => item);
        newActions.push({
          icon: 'duplicate',
          action: 'duplicateCampaign',
          name: 'Duplicate',
        });
        return { actions: newActions };
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleClick(action) {
    if (this.props.isPassive && action === 'report') {
      this[action]('?archive');
    } else {
      this[action]();
    }
  }

  handleScroll(e) {
    const partOfDocHeight = (document.documentElement.offsetHeight * 70) / 100;
    const listTop = this.refs.actionMenu.getBoundingClientRect().top;
    this.setState({
      dropUp: listTop > partOfDocHeight,
    });
  }

  edit() {
    const ext = window.location.pathname.indexOf('ext/push') >= 0 ? '/ext' : '';
    const pushLink =
      this.props.campaign.instanceId === this.props.campaign.type
        ? this.props.campaign.instanceId
        : `${this.props.campaign.type}/${this.props.campaign.instanceId}`;
    const editPath = mailProviderIsSegmentify()
      ? '/email-sgm/edit/'
      : '/email/edit/';
    const link = this.props.isPush
      ? `${ext}/push/edit/${pushLink}`
      : editPath + this.props.campaign.instanceId;
    uiActions.redirect(link);
  }

  duplicateCampaign() {
    const path = '/email/templates/create/';
    const data = this.props.campaign;
    browserHistory.push({
      pathname: path,
      state: {
        campaignPathname: `/email-sgm/create/${data.type}`,
        campaign: data,
        templateId: data.templateId,
        isFork: true,
      },
    });
  }

  report(query = '') {
    const ext = window.location.pathname.indexOf('ext/push') >= 0 ? '/ext' : '';
    let link = this.props.isPush
      ? `${ext}/reports/push-single/${this.props.campaign.instanceId}${query}`
      : `/reports/email-single/${this.props.campaign.instanceId}${query}`;

    if (this.props.campaign.instanceId === 'ABANDONED_CART') {
      link = this.props.isPush
        ? `${ext}/reports/push-multiple/${this.props.campaign.instanceId}${query}`
        : `/reports/email-single/${this.props.campaign.instanceId}${query}`;
    }
    uiActions.redirect(link);
  }

  addToArchive() {
    uiActions.confirmationDialog({
      title: 'Are you sure to make the campaign passive?',
      content: '',
      onConfirm: this.addToArchiveConfirmed,
    });
  }

  addToArchiveConfirmed() {
    if (this.props.isPush) {
      movePushCampaignToArchive(this.props.campaign.instanceId).then(
        response => {
          if (response) {
            this.props.onChangeStatus(
              this.props.campaign.instanceId,
              'PASSIVE',
              this.props.campaign.status === 'TEST' ? 'TEST' : 'ACTIVE',
            );
          }
        },
      );
    } else {
      updateEmailStatus('PASSIVE', this.props.campaign.instanceId, response => {
        if (response) {
          this.props.onChangeStatus(
            this.props.campaign.instanceId,
            'PASSIVE',
            this.props.campaign.status === 'TEST' ? 'TEST' : 'ACTIVE',
          );
        }
      });
    }
  }

  showCampaignHistory() {
    const campaignId = this.props.campaign.instanceId;
    const campaignName =
      this.props.campaign.scenarioName || this.props.campaign.name;
    const campaignHistoryModal = () => (
      <CampaignHistoryModal
        campaignId={campaignId}
        isEmail={!this.props.isPush}
        isPush={this.props.isPush}
      />
    );

    modalActions.openModal({
      title: `Campaign History (${campaignName})`,
      content: campaignHistoryModal,
    });
  }

  render() {
    let { actions } = this.state;
    let listClass = 'widget-nav-popup action-nav-popup';
    if (this.state.dropUp) {
      actions = this.state.actions.slice().reverse();
      listClass = 'widget-nav-popup action-nav-popup drop-up';
    }
    return (
      <nav
        className='widget-nav action-nav'
        aria-haspopup='true'
        ref='actionMenu'
      >
        <a className='secondary-action'>{t('Actions')}</a>

        <ul className={listClass}>
          {actions.map((action, index) => {
            if (!isSuperUser() && action.superUserOnly) {
              return;
            }
            if (this.props.isPassive) {
              if (action.action === 'report') {
                return (
                  <span>
                    <li key={index}>
                      <a
                        className='widget-nav-popup-item action-nav-popup-item'
                        onClick={this.handleClick.bind(null, action.action)}
                      >
                        {action.iconSvg ? (
                          <Icons name={action.icon} color='#fff' />
                        ) : (
                          <Icon name={action.icon} />
                        )}
                        {t(action.name)}
                      </a>
                    </li>
                    <li key={index + 1}>
                      <a
                        className='widget-nav-popup-item action-nav-popup-item'
                        onClick={this.props.goTestFromArchive}
                      >
                        <Icons name='goTest' color='#fff' />
                        {t('Go Test')}
                      </a>
                    </li>
                  </span>
                );
              }
            } else {
              return (
                <li key={index}>
                  <a
                    className='widget-nav-popup-item action-nav-popup-item'
                    onClick={this.handleClick.bind(null, action.action)}
                  >
                    {action.iconSvg ? (
                      <Icons name={action.icon} color='#fff' />
                    ) : (
                      <Icon name={action.icon} />
                    )}
                    {t(action.name)}
                  </a>
                </li>
              );
            }
          })}
        </ul>
      </nav>
    );
  }
}

const mapStatesToProps = store => ({
  status: store.campaigns.status,
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(EmailWidgetActions);
