import React from "react";

class IconApple extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox="0 0 19 22.6"
        fill={this.state.color}
      >
        <path
          d="M15.9,11.9c0-1.9,0.8-3.2,2.5-4.3c-0.9-1.4-2.4-2.1-4.3-2.2c-1.8-0.1-3.7,1-4.5,1c-0.8,0-2.5-1-3.9-1C3,5.5,0,7.7,0,12.2
	c0,1.3,0.2,2.7,0.7,4.1c0.6,1.9,3,6.4,5.4,6.3c1.3,0,2.2-0.9,3.8-0.9c1.6,0,2.4,0.9,3.9,0.9c2.5,0,4.6-4.2,5.2-6
	C15.7,15,15.9,12,15.9,11.9z M13,3.7C14.4,2,14.3,0.5,14.2,0c-1.2,0.1-2.6,0.8-3.4,1.8C9.9,2.8,9.4,4,9.5,5.4
	C10.8,5.5,12,4.8,13,3.7z"
        />
      </svg>
    );
  }
}

export default IconApple;
