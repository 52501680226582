import React from 'react';

export default function MailSendComponent({
  title,
  text,
  color,
  image = 'mail',
}) {
  return (
    <div className='align-center mail-send'>
      <div className={`${image}-sended-background`} />
      <h2 className={`${color}-title`}>{title}</h2>
      <p className='confirm-text'>
        <strong>{text}</strong>
      </p>
    </div>
  );
}
