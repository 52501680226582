import { config, getApiUrl } from '../constants/config';
import { getApiKey, getUserRestUrl } from '../modules/auth/user';

export const getEnv = () => {
  const isLocal = localStorage.getItem('isLocal');

  return isLocal ? 'local' : 'prod';
};

export const getPath = () => {
  return config.api.path[getEnv()];
};

export const getRestPath = () => {
  return config.api.path.rest;
};

export const getAdminPath = () => {
  return config.api.path.admin;
};

export const getAdminV3Path = () => {
  return config.api.path.adminV3;
};

export const getInsightsPath = () => {
  return config.api.path.insights;
};

export const getNode = (nodes, nodeString) => {
  const splitNodes = nodeString.split('/');

  return splitNodes.reduce((acc, item) => acc[item], nodes);
};

export const getEndPoint = endpoint => {
  const ep = getNode(config.api.endpoints, endpoint);

  return getPath() + ep;
};

export const getAdminEndPoint = endpoint => {
  const ep = getNode(config.api.endpoints, `admin/${endpoint}`);

  return getAdminPath() + ep;
};

export const getAdminV3EndPoint = endpoint => {
  const ep = getNode(config.api.endpoints, `admin/${endpoint}`);

  return getAdminV3Path() + ep;
};

export const getInsightEndPoint = endpoint => {
  const ep = getNode(config.api.endpoints, `insights/${endpoint}`);

  return `${getInsightsPath() + ep}?apiKey=${getApiKey()}`;
};

export const getTokenEndPoint = () => {
  return config.api.endpoints.token[getEnv()];
};

export const getTokenEndPoints = env => {
  return config.api.endpoints.token[env];
};

export const checkTokenEndPoint = () => {
  return config.api.endpoints.token.check;
};

export const setPasswordEndPoint = () => {
  return config.api.path.password;
};

export const getRestEndPoint = (endpoint, optionalDatacenter) => {
  if (optionalDatacenter) {
    return getRestPath() + getNode(config.api.endpoints.rest, endpoint);
  }
  return getUserRestUrl() + getNode(config.api.endpoints.rest, endpoint);
};

export const getInsightPath = () => {
  return config.api.path.insights;
};

export const getAccountRestEndPoint = (account, endpoint) => {
  return getRestPath() + getNode(config.api.endpoints.rest, endpoint);
};

export const buildUrl = (url, v = 3) => {
  let apiURL;
  if (v === 3) apiURL = getApiUrl();
  if (v === 1) apiURL = 'https://dcetr4.segmentify.com/';
  const apiKey = getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';

  return `${apiURL}${url}${apiKey}`;
};

export default {
  getEnv,
  getNode,
  getPath,
  getEndPoint,
  getRestPath,
  getRestEndPoint,
  getInsightPath,
  getTokenEndPoint,
  getTokenEndPoints,
  checkTokenEndPoint,
  setPasswordEndPoint,
  getAdminEndPoint,
  getAdminV3EndPoint,
  getInsightEndPoint,
  getAccountRestEndPoint,
  buildUrl,
};
