import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const MegaphoneIcon = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 20 17'
      fill='none'
    >
      <path d='M19.9068 1.62236C19.9686 1.83039 20 2.04627 20 2.26329V13.7387C20 14.9813 18.9926 15.9887 17.75 15.9887C17.5329 15.9887 17.317 15.9573 17.1089 15.8954L11.595 14.2558C10.9378 15.6008 9.5584 16.4994 8 16.4994C5.8578 16.4994 4.10892 14.8155 4.0049 12.6991L4 12.4994L3.999 11.9987L1.60891 11.288C0.65446 11.0043 0 10.127 0 9.1313V6.8693C0 5.87356 0.65455 4.99622 1.60908 4.71256L17.1091 0.106501C18.3002 -0.247469 19.5528 0.431201 19.9068 1.62236ZM5.499 12.4447L5.5 12.4994C5.5 13.8801 6.61929 14.9994 8 14.9994C8.8852 14.9994 9.6783 14.5352 10.1238 13.82L5.499 12.4447ZM17.5364 1.54436L2.03636 6.1504C1.71818 6.245 1.5 6.5374 1.5 6.8693V9.1313C1.5 9.4632 1.71815 9.7556 2.0363 9.8502L17.5363 14.4576C17.6057 14.4782 17.6776 14.4887 17.75 14.4887C18.1642 14.4887 18.5 14.1529 18.5 13.7387V2.26329C18.5 2.19095 18.4895 2.11899 18.4689 2.04964C18.3509 1.65259 17.9334 1.42637 17.5364 1.54436Z' />
    </svg>
  );
};

MegaphoneIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

MegaphoneIcon.defaultProps = {
  width: '20px',
  height: '17px',
};

export default MegaphoneIcon;
