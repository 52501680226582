import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { t } from '../system/ui';
import Icon from './icon';
import Icons from './icons';
import Navlink from './navlink';
import Tooltip from './tooltip';

import { ExternalAppUtility } from '../external';

const Navigation = ({ id, items, tooltip, itemClass, isPrimarySidebar }) => {
  const external = ExternalAppUtility.isHeaderHidden();
  return (
    <nav id={id} className={classNames(id, { external })}>
      {Object.keys(items).map((item, index) => {
        const _item = items[item];
        const isVisible = _item.visible !== false;
        const className = classNames('nav-item', itemClass, _item.cssClass, {
          'disabled-nav': _item.disabled,
        });
        if (_item.separator) {
          return <span key={index} className='nav-item-separator' />;
        }

        if (_item.header && isVisible) {
          return (
            <p key={index} className='nav-item-header'>
              {_item.name}
            </p>
          );
        }

        let icon = <Icon name={_item.icon} />;

        if (_item.svg) {
          icon = <Icons name={_item.icon} />;
        }

        if (tooltip && isVisible) {
          if (isPrimarySidebar) {
            return (
              <>
                <Navlink
                  key={index}
                  to={_item.slug}
                  className={className}
                  data-tooltip-id={_item.name}
                >
                  {icon}
                </Navlink>
                <ReactTooltip
                  id={_item.name}
                  place='right'
                  content={t(_item.name)}
                />
              </>
            );
          }

          return (
            <Navlink key={index} to={_item.slug} className={className}>
              <Tooltip content={t(_item.name)} alignment='left'>
                {icon}
                <span className='for-screenreader-only'>{t(_item.name)}</span>
              </Tooltip>
            </Navlink>
          );
        }

        if (_item.icon) {
          return (
            isVisible && (
              <Navlink key={index} to={_item.slug} className={className}>
                <span>
                  {icon}
                  <span className='for-screenreader-only'>{t(_item.name)}</span>
                </span>
              </Navlink>
            )
          );
        }
        return (
          isVisible && (
            <Navlink key={index} to={_item.slug} className={className}>
              <span>{t(_item.name)}</span>
            </Navlink>
          )
        );
      })}
    </nav>
  );
};

Navigation.propTypes = {
  id: PropTypes.string,
  items: PropTypes.object,
  tooltip: PropTypes.bool,
  itemClass: PropTypes.string,
};

Navigation.defaultProps = {
  id: 'navigation',
  items: {},
  tooltip: false,
  itemClass: '',
};

export default Navigation;
