import React from 'react';

class ProductSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: '24px',
      height: '24px',
      color: '#eee',
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    const width = this.props.width ? `${this.props.width  }px` : this.state.width;
      let height = this.props.height ? this.props.height + "px" : this.state.height;
      let color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox="0 0 492.145 492.145"
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <path
            d="M395.167,195.6c-11.4-2.1-21.8,4.2-23.9,15.6c-3.1,18.7-11.4,34.3-25,47.9c-13.5,12.5-30.2,21.8-47.9,25
			c-10.4,2.1-17.7,12.5-15.6,23.9c2.1,10.4,11.5,17.9,22.9,16.6c26-5.2,49.9-17.7,68.7-36.4c18.7-18.7,31.2-42.7,36.4-68.7
			C412.867,208.1,406.667,197.7,395.167,195.6z"
          fill={this.state.color}
        />
        <path
            d="M290.067,0c-111.1,0-201.3,90.1-201.3,201.3c0,29.9,6.5,58.3,18.2,83.8l-74.9,65.5c-54.6,56.2-23.9,105.1-9.4,119.6
			c13.5,14.6,63.9,45.5,119.6-9.4l66-75.6c25,11.1,52.6,17.3,81.7,17.3c111.2,0,201.3-90.1,201.3-201.3S401.267,0,290.067,0z
			 M112.267,434.9c-9.4,10.4-36.1,27.9-60.3,7.3c-6.7-5.7-20.3-34.4,7.3-60.3l69.3-60.5c12.3,16.5,27,31.1,43.7,43.1L112.267,434.9z
			 M290.067,360.5c-87.9,0-159.2-71.3-159.2-159.2s71.3-159.2,159.2-159.2s159.2,71.3,159.2,159.2S378.067,360.5,290.067,360.5z"
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default ProductSearch;
