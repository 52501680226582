import React from 'react';
import './countDownActions.scss';
import { browserHistory } from 'react-router';
import { t } from '../../../../system/ui';
import Icons from '../../../icons';
import Icon from '../../../icon';
import { modalActions, uiActions } from '../../../../actions';
import CampaignHistoryModal from '../../../../modules/campaigns/historymodal';
import { CountdownService } from '../../../../views/behavioural-targeting/countdown/services/countdownServices';
import { selectCampaignType } from '../../../../views/behavioural-targeting/countdown/constants/constants';
import { isLoaded } from '../../../../actions/ui';

/**
 * @name CountDownActions this component is used to show the actions of the countDown
 * @description this component is used to show the actions of the countDown
 * @param {countDown} countDown this is the countDown campaign object
 * @param {countDownCampaignType} countDownCampaignType this is the countDown campaign type
 * @param {countDownUpdate} countDownUpdate this is the countDown campaign list update function
 * @returns {JSX.Element} - Countdown component
 */

const CountDownActions = ({
  countDown,
  countDownCampaignType,
  countDownUpdate,
}) => {
  const createAction = () => {
    return [
      {
        icon: 'edit',
        handler: 'editAction',
        name: 'Edit',
        iconSvg: true,
      },
      {
        icon: 'duplicate',
        handler: 'duplicateAction',
        name: 'Duplicate',
        iconSvg: true,
      },
      {
        icon: 'history',
        handler: 'historyAction',
        name: 'History',
        iconSvg: true,
      },
      { icon: 'pie-chart', handler: 'reportAction', name: 'Report' },
      { icon: 'archive', handler: 'archiveAction', name: 'Archive' },
    ];
  };
  const actions = createAction();

  const reportAction = () => {
    browserHistory.push({
      pathname: `/reports/single/engagement/${countDown?.instanceId}`,
    });
  };

  const editAction = () => {
    browserHistory.push({
      pathname: `/behavioural-targeting/engagement/countdown/${selectCampaignType(
        countDownCampaignType,
      )}/edit/${countDown?.instanceId}`,
    });
  };

  const archiveAction = () => {
    const notificationContent = () => (
      <div>
        {`${t(`You have successfully archived ${countDown?.name} campaign.`)} `}
      </div>
    );
    uiActions.confirmationDialog({
      title: 'Attention!',
      content: 'This action will immediately effect on your website.',
      onConfirm: () => {
        CountdownService()
          .updateStatus({
            id: countDown?.instanceId,
            body: countDown,
            status: 'ARCHIVE',
          })
          .then(() => {
            isLoaded();
            uiActions.showNotification({ content: notificationContent });
            countDownUpdate();
          })
          .catch(() => {
            const errorContent = () => (
              <span>Error occurred while archiving the campaign</span>
            );
            uiActions.showErrorNotification(errorContent);
          });
      },
    });
  };

  const duplicateAction = () => {
    browserHistory.push({
      pathname: `/behavioural-targeting/engagement/countdown/${selectCampaignType(
        countDownCampaignType,
      )}/duplicate/${countDown?.instanceId}`,
    });
  };

  const historyAction = () => {
    const campaignHistoryModal = () => (
      <CampaignHistoryModal campaignId={countDown?.instanceId} engagement />
    );

    modalActions.openModal({
      title: `Campaign History (${countDown?.name})`,
      content: campaignHistoryModal,
    });
  };

  const handleClick = action => {
    switch (action) {
      case 'editAction':
        editAction();
        break;
      case 'historyAction':
        historyAction();
        break;
      case 'archiveAction':
        archiveAction(countDown);
        break;
      case 'duplicateAction':
        duplicateAction();
        break;
      case 'reportAction':
        reportAction();
        break;
      default:
        break;
    }
  };

  return (
    <li className='widget-list-item'>
      <div className='right-side-wrapper'>
        <nav className='widget-nav action-nav' aria-haspopup='true'>
          <a className='secondary-action'>{t('Actions')}</a>
          <ul className='widget-nav-popup action-nav-popup drop-up'>
            {actions.map(action => (
              <li key={action.name}>
                <a
                  className='widget-nav-popup-item action-nav-popup-item'
                  onClick={() => handleClick(action.handler)}
                >
                  {action.iconSvg ? (
                    <Icons name={action.icon} color='#fff' />
                  ) : (
                    <Icon name={action.icon} />
                  )}
                  {action.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </li>
  );
};

export default CountDownActions;
