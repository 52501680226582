import React, { Component } from 'react';
import { connect } from 'react-redux';

import { uiActions } from '../../actions';
import { getSinglePersonalizationReport } from '../../modules/reports/ajax';
import { transFormPersReport } from '../../modules/transform';
import { t } from '../../system/ui';
import { campaignTypesForGamificationReport } from '../../constants/datamaps/campaign';
import EmailCollectionReport from '../widget/email-collection-report';
import HeroBannerReport from '../widget/personalization-hero-report';
import NotificationBarReport from '../widget/personalization-notbar-report';
import PopupBannerReport from '../widget/personalization-popup-report';
import SeeAllReport from '../widget/personalization-seeall-report';
import GamificationReport from '../widget/personalization-gamification-report';
import SegmentationReport from '../widget/segmentation-report';
import { getOtherDates } from '../analytics/utils';
import { RangePicker } from '../fields';

class ComparePersonalizationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserSelect: false,
      firstStart: '',
      firstEnd: '',
      secondStart: '',
      secondEnd: '',
      firstColumnReports: {},
      secondColumnReports: {},
    };

    this.getInitialReports = this.getInitialReports.bind(this);
    this.onFirstDatesChange = this.onFirstDatesChange.bind(this);
    this.onSecondDatesChange = this.onSecondDatesChange.bind(this);
    this.changeFirstColumn = this.changeFirstColumn.bind(this);
    this.changeSecondColumn = this.changeSecondColumn.bind(this);
    this.ReportComponent = this.ReportComponent.bind(this);
  }

  componentDidMount() {
    this.getInitialReports();
  }

  getInitialReports() {
    uiActions.isLoading();
    const boundaries = {};
    boundaries.start = this.props.range[0].format('YYYY-MM-DD');
    boundaries.end = this.props.range[1].format('YYYY-MM-DD');

    getSinglePersonalizationReport(
      this.props.campaign.instanceId,
      boundaries,
      campaignReport => {
        const firstColumnReports = campaignReport;
        getSinglePersonalizationReport(
          this.props.campaign.instanceId,
          {
            start: getOtherDates(this.props.range).formattedStart,
            end: getOtherDates(this.props.range).formattedEnd,
          },
          otherCampaignReport => {
            this.setState({
              firstColumnReports: transFormPersReport(firstColumnReports),
              secondColumnReports: transFormPersReport(otherCampaignReport),
            });
          },
        );
        window.dispatchEvent(new Event('resize'));
        uiActions.isLoaded();
      },
    );
  }

  onFirstDatesChange(newDates) {
    this.setState({
      firstStart: newDates.startDate,
      firstEnd: newDates.endDate,
      isUserSelect: true,
    });
  }

  onSecondDatesChange(newDates) {
    this.setState({
      secondStart: newDates.startDate,
      secondEnd: newDates.endDate,
      isUserSelect: true,
    });
  }

  changeFirstColumn(dates) {
    if (
      dates.startDate === this.state.firstStart &&
      dates.enddDate === this.state.firstEnd
    ) {
      return;
    }
    uiActions.isLoading();
    const boundaries = {};
    boundaries.start = dates.startDate.format('YYYY-MM-DD');
    boundaries.end = dates.endDate.format('YYYY-MM-DD');

    getSinglePersonalizationReport(
      this.props.campaign.instanceId,
      boundaries,
      campaignReport => {
        this.setState({
          firstColumnReports: transFormPersReport(campaignReport),
        });
        window.dispatchEvent(new Event('resize'));
        uiActions.isLoaded();
      },
    );
  }

  changeSecondColumn(dates) {
    if (
      dates.startDate === this.state.secondtStart &&
      dates.enddDate === this.state.secondEnd
    ) {
      return;
    }
    uiActions.isLoading();
    const boundaries = {};
    boundaries.start = dates.startDate.format('YYYY-MM-DD');
    boundaries.end = dates.endDate.format('YYYY-MM-DD');

    getSinglePersonalizationReport(
      this.props.campaign.instanceId,
      boundaries,
      campaignReport => {
        this.setState({
          secondColumnReports: transFormPersReport(campaignReport),
        });
        window.dispatchEvent(new Event('resize'));
        uiActions.isLoaded();
      },
    );
  }

  ReportComponent(props) {
    let statsComponent = null;
    if (this.props.campaign.type === 'POPUP_BANNER') {
      statsComponent = (
        <PopupBannerReport
          stats={props.stats}
          compareMode
          withCompareIcons={props.withCompareIcons}
          comparisonStats={this.state.secondColumnReports}
        />
      );
    } else if (this.props.campaign.type === 'SEE_ALL') {
      statsComponent = (
        <SeeAllReport
          stats={props.stats}
          compareMode
          withCompareIcons={props.withCompareIcons}
          comparisonStats={this.state.secondColumnReports}
        />
      );
    } else if (
      campaignTypesForGamificationReport.includes(this.props.campaign.type)
    ) {
      statsComponent = (
        <GamificationReport
          stats={props.stats}
          compareMode
          withCompareIcons={props.withCompareIcons}
          comparisonStats={this.state.secondColumnReports}
        />
      );
    } else if (this.props.campaign.type === 'NOTIFICATION_BAR') {
      statsComponent = (
        <NotificationBarReport
          stats={props.stats}
          compareMode
          withCompareIcons={props.withCompareIcons}
          comparisonStats={this.state.secondColumnReports}
        />
      );
    } else if (this.props.campaign.type === 'HERO_BANNER') {
      statsComponent = (
        <HeroBannerReport
          stats={props.stats}
          compareMode
          withCompareIcons={props.withCompareIcons}
          comparisonStats={this.state.secondColumnReports}
        />
      );
    } else if (
      this.props.campaign.type === 'NEWSLETTER' ||
      this.props.campaign.type === 'FORM'
    ) {
      statsComponent = (
        <EmailCollectionReport
          stats={props.stats}
          compareMode
          withCompareIcons={props.withCompareIcons}
          comparisonStats={this.state.secondColumnReports}
        />
      );
    } else if (this.props.campaign.type === 'SEGMENTATION') {
      statsComponent = (
        <SegmentationReport
          stats={props.stats}
          compareMode
          withCompareIcons={props.withCompareIcons}
          comparisonStats={this.state.secondColumnReports}
          action={this.props.campaign.action}
        />
      );
    }
    return statsComponent;
  }

  render() {
    return (
      <div className='report-compare one-whole' style={{ minWidth: '932px' }}>
        <div className='one-half report-compare__field'>
          <div className='report-compare-date'>
            <span
              style={{
                display: 'inline-block',
                marginRight: '12px',
                fontSize: '14px',
                color: '#a3a3a5',
              }}
            >
              {t('Compare To')}:
            </span>
            <RangePicker
              startDate={
                this.state.secondStart || getOtherDates(this.props.range).start
              }
              endDate={
                this.state.secondEnd || getOtherDates(this.props.range).end
              }
              onDatesChange={this.onSecondDatesChange}
              onPickerClose={this.changeSecondColumn}
            />
          </div>
          {this.ReportComponent({
            stats: this.state.secondColumnReports,
            withCompareIcons: false,
          })}
        </div>
        <div className='one-half report-compare__field'>
          <div className='report-compare-date'>
            <span
              style={{
                display: 'inline-block',
                marginRight: '12px',
                fontSize: '14px',
                color: '#a3a3a5',
              }}
            >
              {t('With')}:
            </span>
            <RangePicker
              startDate={this.state.firstStart || this.props.range[0]}
              endDate={this.state.firstEnd || this.props.range[1]}
              onDatesChange={this.onFirstDatesChange}
              onPickerClose={this.changeFirstColumn}
            />
          </div>
          {this.ReportComponent({
            stats: this.state.firstColumnReports,
            withCompareIcons: true,
          })}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
});

export default connect(mapStatesToProps)(ComparePersonalizationModal);
