/**
 * @author Barış ALTUN
 */

"use strict";

import React from "react";
import { store } from "../../store";

export const Revenue = () => {
  return (
    <div>
      <p>
        This box shows the statistics about products that are purchased through
        Segmentify Search Box Widgets and the effect of those purchases over
        your
        website’s overall revenue. The values here indicates the total of all
        assets.
      </p>
      <ul>
        <li>
          <strong>Revenue:</strong> Shows the total monetary amount of products
          sold through Search Box widgets. Segmentify only tracks the products
          clicked from the Search Box widgets in the same session. When a
          visitor
          buys 2 products with only one of them directed from a Search Box
          widget
          , Segmentify only adds that one product to its revenue contribution.
          Segmentify only adds that one product to its revenue contribution.
        </li>
        <li>
          <strong>Purchases:</strong> Shows the number of purchases that include
          at least one product directed from Segmentify Search Box Widgets.
        </li>
        <li>
          <strong>Purchased Products:</strong> Shows the total number of
          products that are sold through Segmentify Search Box Widgets. If a
          visitor buys two of the same product after clicking to it in a
          Search Box widget, it is counted as two items.
        </li>
        <li>
          <strong>Average Order Value:</strong> Shows Segmentify
          Search Box Widgets’ average amount of contribution to an order
        </li>
        <li>
          <strong>Conversion Rate:</strong> The percentage of products that have
          been purchased after being clicked in a Segmentify Search Box widget.
        </li>
        <li>
          <strong>Contribution Ratio: </strong> Shows the ratio between
          Segmentify Search Box Widgets’ contribution and the overall revenue.
          It
          is calculated by Revenue of the Widgets divided by Overall Revenue.
        </li>
      </ul>
    </div>
  );
};

export const Basket = () => {
  return (
    <div>
      <p>
        This box shows the statistics about products that are added to the
        basket through Segmentify Search Box Widgets. The values here indicates
        the total of all assets.
      </p>
      <ul>
        <li>
          <strong>Products:</strong> Shows how many products are added to the
          basket through Segmentify Search Box Widgets. This is the total number
          of items added to the basket. If a visitor adds two of the same
          product after clicking to a Search Box widget, it is counted as two
          items.
        </li>
        <li>
          <strong>Total Amount: </strong> Shows the total monetary amount of
          items added to the basket through Search Box widgets.
        </li>
        <li>
          <strong>Add to Basket Rate:</strong> Shows the ratio between the number of
          products that are added to the basket and the number of products that
          are clicked through the Search Box widgets. It is calculated by
          dividing Products Added to the Basket to Products Clicked.
        </li>
      </ul>
    </div>
  );
};

export const Interaction = () => {
  return (
    <div>
      <p>
        This box shows the statistics about user interaction with Segmentify
        Search Box Widget. The values here indicates the total of all assets.
      </p>
      <ul>
        <li>
          <strong>Clicks:</strong> Shows how many times the products inside the
          Search Box widgets are clicked by your visitor.
        </li>
        <li>
          <strong>CTR:</strong> Click-through rate shows the ratio between the
          number of clicks on a Search Box widget and the number of views by
          your
          visitors. It is calculated by dividing Clicks to Views. If this ratio
          is low and if you did not activate all the assets, you should consider
          adding new assets to your Search Box widget.
        </li>
      </ul>
    </div>
  );
};

export const Widget = () => {
  return (
    <div>
      <p>
        This box shows the statistics about Segmentify Recommendation Widgets
      </p>
      <ul>
        <li>
          <strong>Views:</strong> Indicate the total number of Search Box widget
          on your website that have actually been seen by your visitors. This
          number indicates how many times the Search Box widget is opened and
          seen by the users.
        </li>
        <li>
          <strong>Impressions:</strong> This number indicates how many times the
          Search Box is clicked by the users.
        </li>
        <li>
          <strong>Impression Rate:</strong>The ratio between the number of views
          of the Search Box widget over the total amount of impressions of the
          Search Box widget. It is calculated by dividing Views to Impressions.
        </li>
      </ul>
    </div>
  );
};

export const revenuegraph = () => {
  let userLang = store.getState().ui.language;
  if (userLang === "tr") {
    return (
      <p>
        Seçtiğiniz tarih aralığında, sitenizde gerçekleşen toplam satış geliri
        ile Segmentify önerisi ile gerçekleşen satış geliri karşılaştırmalı
        olarak gösterilmektedir.
      </p>
    );
  } else {
    return (
      <p>
        This bar bar chart illustrates you the overall sales revenue generated
        on your website in comparison to the Segmentify contribution within the
        selected date range.
      </p>
    );
  }
};

export const purchasegraph = lang => {
  let userLang = store.getState().ui.language;
  if (userLang === "tr") {
    return (
      <p>
        Seçtiğiniz tarih aralığında sitenizde gerçekleşen toplam satış işlem
        adedi ile Segmentify önerisi ile satın alınan bir ya da birden fazla
        ürünün içinde bulunduğu satış işlem adedi karşılaştırmalı olarak
        gösterilmektedir. Bir satın alma işlemi içinde bir ya da birden fazla
        ürün yer alabilir. Ziyaretçiniz, Segmentify ile önerilen bir ürüne
        tıklayarak aynı oturum içinde satın alma yaparsa, bu ürüne ait satış
        Segmentify katkısı olarak sayılır.
      </p>
    );
  } else {
    return (
      <p>
        This bar chart illustrates you the total number of purchase transactions
        on your website in comparison to the ones that include items that are
        recommended by Segmentify widget within the selected date range. A
        purchase transaction may include one or more products. If a visitor
        clicks on a product recommended by Segmentify and purchase in the same
        session, it counts as Segmentify contribution.
      </p>
    );
  }
};

export const pagegraph = lang => {
  let userLang = store.getState().ui.language;
  if (userLang === "tr") {
    return (
      <p>
        Seçtiğiniz tarih aralığında, toplam sayfa görüntüleme adedinin sayfa
        bazında kırılımı gösterilmektedir.
      </p>
    );
  } else {
    return (
      <p>
        This pie chart illustrates the breakdown of the total pageviews by pages
        within selected date range.
      </p>
    );
  }
};

export const devicegraph = lang => {
  let userLang = store.getState().ui.language;
  if (userLang === "tr") {
    return (
      <p>
        Seçtiğiniz tarih aralığında, toplam sayfa görüntüleme adedinin cihaz
        bazında kırılımı gösterilmektedir.
      </p>
    );
  } else {
    return (
      <p>
        This pie chart illustrates you the breakdown of your page views by
        device only within the selected date range.
      </p>
    );
  }
};

export const audiencegraph = lang => {
  let userLang = store.getState().ui.language;
  if (userLang === "tr") {
    return (
      <div>
        <p>
          Seçtiğiniz tarih aralığında, sitenize gelen tekil ziyaretçilerin
          davranışları rakamlarla gösterilmektedir.
        </p>
        <ul>
          <li>Sitenize toplam gelen tekil ziyaretçi adedi</li>
          <li>Bu ziyaretçilerin açtığı toplam oturum adedi</li>
          <li>Açılan oturumlarda görüntülenen toplam ürün adedi</li>
          <li>Görüntülenen toplam sayfa sayısı</li>
        </ul>
      </div>
    );
  } else {
    return (
      <div>
        <p>
          This funnel view illustrates the figures reflecting your visitors’
          behavior on your website.
        </p>
        <ul>
          <li>
            How many unique visitors your website has within selected time
            period?
          </li>
          <li>How many sessions do these unique visitors open?</li>
          <li>How many products have they viewed?</li>
          <li>How many pages have they viewed?</li>
        </ul>
      </div>
    );
  }
};

export const segmentifygraph = lang => {
  let userLang = store.getState().ui.language;
  if (userLang === "tr") {
    return (
      <p>
        Seçtiğiniz tarih aralığında, Segmentify ile önerilen ürünlere ait satış
        hunisinin dönüşüm adet ve oranları gösterilmektedir.
      </p>
    );
  } else {
    return (
      <p>
        This graph illustrates you the top to down sales funnel conversion
        figures of the products recommended by Segmentify within the selected
        date range.
      </p>
    );
  }
};

export const PageView = () => {
  return (
    <div>
      <p>
        Segmentify tags and tracks all your pages with its various page types.
        This box shows the overall and the breakdown page views and ratios.
      </p>
      <p>
        Segmentify groups pages as Home Page, Product Page, Category Page and
        Other Pages (Empty Search, 404 Pages, Brand Pages, Custom Pages etc.)
      </p>
      <ul>
        <li>
          <strong>Page View:</strong>
          Total page views of your website.
        </li>
        <li>
          <strong>Home Page:</strong>
          The number of views your website had on the main page. The percentage
          shows the % of the overall pageview coming from the home page.
        </li>
        <li>
          <strong>Product Page:</strong>
          The number of views of your products. The percentage shows the % of
          the overall pageview coming from the product page.
        </li>
        <li>
          <strong>Category Page:</strong>
          The number of views of your Category Pages and Product List Pages. The
          percentage shows the % of the overall pageview coming from the
          category page.
        </li>
        <li>
          <strong>Other Pages:</strong>
          The number of views on other pages in your site. The percentage shows
          the % of the overall pageview coming from other pages.
        </li>
      </ul>
    </div>
  );
};

export const Device = () => {
  return (
    <div>
      <p>
        This box shows from which devices your visitors are entering your
        website. You can also see the usage rate according to the devices
      </p>
    </div>
  );
};

export const Audience = () => {
  return (
    <div>
      <p>
        This box shows the statistics based on your website’s visitor traffic.
      </p>
      <ul>
        <li>
          <strong>Average Sessions: </strong>
          The average number of unique sessions
        </li>
        <li>
          <strong>Unique Visitors: </strong>
          Unique visitors refers to the number of distinct individuals visiting
          your website, regardless of how often they visit.{" "}
        </li>
        <li>
          <strong>Unique Sessions: </strong>
          The total number of visits from unique visitors
        </li>
        <li>
          <strong>Pages/Sessions: </strong>
          Ratio of page views to sessions.
        </li>
        <li>
          <strong>Product/Sessions: </strong>
          Ratio of product views to sessions.
        </li>
      </ul>
    </div>
  );
};

export const RevenueOverall = () => {
  return (
    <div>
      <p>This box shows all purchase based metrics of your website.</p>
      <ul>
        <li>
          <strong>Revenue (Overall): </strong>
          Shows the total monetary amount of products sold on your website
        </li>
        <li>
          <strong>Purchases: </strong>
          Shows the total number of orders given on your website
        </li>
        <li>
          <strong>Purchased Products: </strong>
          Shows the total number of products sold on your website.
        </li>
        <li>
          <strong>Average Order Value: </strong>
          Shows the average monetary value of orders given through your website.
        </li>
      </ul>
    </div>
  );
};

export default {
  Widget,
  Basket,
  Revenue,
  Interaction,
  revenuegraph,
  purchasegraph,
  pagegraph,
  devicegraph,
  audiencegraph,
  segmentifygraph,
  PageView,
  Device,
  Audience,
  RevenueOverall
};
