/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from 'react';
import { connect } from 'react-redux';

import uiActions from '../actions/ui';
import { t } from '../system/ui';
import { setTitle } from '../system/document';

import { advancedConfig } from '../constants/datamaps/campaign';

import EditCampaign from '../modules/campaign/edit';

class EditNotificationBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 'Edit Notification Bar',
      content: [
        {
          field: 'title',
          options: {
            label: 'Title',
            required: false,
            tooltip: true,
            tooltipContent:
              'Enter the title that your visitors will see on the notification bar.',
          },
        },
        {
          field: 'imageWithUpload',
          options: {
            label: 'Background Image URL (Recommend 1920x80px)',
            required: false,
            tooltip: true,
            tooltipContent:
              'Enter the URL address of the image that will be displayed on the notification bar.',
          },
        },
        {
          field: 'url',
          options: {
            label: 'Target URL',
            name: 'url',
            required: false,
            tooltip: true,
            tooltipContent:
              'Enter the URL of the page that your visitors will be directed when they click on the notification bar.',
          },
        },
      ],
      contentExtra: {
        buttons: [
          { field: 'stylesButton', ignoreRef: true },
          { field: 'positionButton', ignoreRef: true },
          { field: 'timingButton', ignoreRef: true },
          { field: 'audienceButton', ignoreRef: true },
          { field: 'sticky' },
        ],
        fields: [
          {
            field: 'stylesOptions',
            options: {
              buttonTextColor: { show: false },
              buttonBgColor: { show: false },
              bgColor: { show: true, label: 'Background Color' },
              textColor: { show: true, label: 'Text Color' },
            },
          },
          {
            field: 'positionOptions',
            options: {
              horizontal: { show: false },
              vertical: {
                show: true,
                label: '',
                default: 'top',
                values: ['top', 'bottom'],
              },
            },
          },
          { field: 'timingOptions' },
          { field: 'audienceOptions' },
        ],
      },
      options: [
        { field: 'name' },
        { field: 'startEndDate' },
        { field: 'devices' },
        {
          field: 'pages',
          options: {
            hasPageUrlFilter: true,
          },
        },
        {
          field: 'advancedConfig',
          options: advancedConfig.NOTIFICATION_BAR,
        },
      ],
      buttons: [
        { field: 'advancedConfigButton' },
        { field: 'goTest' },
        { field: 'goLive' },
      ],
    };
  }

  componentDidMount() {
    setTitle(t('Edit Notification Bar'));
    uiActions.confirmOnLeave(this);
  }

  render() {
    return (
      <EditCampaign
        type='NOTIFICATION_BAR'
        title={this.state.title}
        content={this.state.content}
        contentExtra={this.state.contentExtra}
        options={this.state.options}
        buttons={this.state.buttons}
        campaignId={this.props.params.campaignId}
      />
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps)(EditNotificationBar);
