import React from 'react';
import PropTypes from 'prop-types';

function CircleBack(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${props.width}px`}
      height={`${props.height}px`}
      viewBox='0 0 20 20'
    >
      <path
        d='M9.59259 0.660645C14.5018 0.660645 18.4815 4.61813 18.4815 9.49993C18.4815 14.3818 14.5018 18.3392 9.59259 18.3392C7.9041 18.3392 6.28341 17.8696 4.88228
        16.9974C4.31155 16.6422 3.78292 16.2234 3.30683 15.7499C2.83003 15.2757 2.40841 14.7491 2.05088 14.1805C1.17521 12.7879 0.703705 11.1776 0.703705 9.49993C0.703705
        9.23063 0.715833 8.9628 0.739952 8.69683C0.776683 8.2916 1.13676 7.99283 1.54421 8.02927C1.95166 8.06581 2.25218 8.4239 2.21545 8.82913C2.19532 9.05109 2.18519
        9.27482 2.18519 9.49993C2.18519 10.8994 2.57755 12.2394 3.30699 13.3994C3.60508 13.8734 3.95672 14.3126 4.35446 14.7082C4.75159 15.1032 5.1925 15.4525 5.66833
        15.7486C6.8355 16.4752 8.1841 16.866 9.59259 16.866C13.6836 16.866 17 13.5681 17 9.49993C17 5.43176 13.6836 2.13386 9.59259 2.13386C7.2249 2.13386 5.04892 3.24644
        3.66445 5.08221L6.38686 5.08234C6.79595 5.08234 7.1276 5.41214 7.1276 5.81895C7.1276 6.19187 6.84892 6.50005 6.48737 6.54884L6.38686 6.55556H1.93827C1.56326 6.55556
        1.25334 6.27844 1.2043 5.9189L1.19753 5.81895V1.3993C1.19753 0.992491 1.52918 0.662697 1.93827 0.662697C2.31329 0.662697 2.6232 0.939819 2.67225 1.29935L2.67901
        1.3993L2.67803 3.94442C4.34407 1.89425 6.86299 0.660645 9.59259 0.660645ZM8.85185 4.58922C9.22667 4.58922 9.53679 4.86651 9.58578 5.22591L9.59259 5.32582V9.49993H11.8148C12.2237
        9.49993 12.5556 9.82993 12.5556 10.2365C12.5556 10.6093 12.2767 10.9177 11.9153 10.9664L11.8148 10.9731H8.85185C8.47704 10.9731 8.16691 10.6959 8.11793 10.3364L8.11111
        10.2365V5.32582C8.11111 4.91922 8.44296 4.58922 8.85185 4.58922Z'
        fill={props.color}
      />
    </svg>
  );
}

CircleBack.defaultProps = {
  color: '#233679',
  width: 20,
  height: 20,
};

CircleBack.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CircleBack;
