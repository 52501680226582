import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { SortableHandle } from 'react-sortable-hoc';

import {
  afterSearchIntelligentOptions,
  algorithmOrders,
  eventDataTimeFrame,
  getRockstarTimeFrameMapping,
  intelligentOptions,
  intelligentOptionsCustom,
  intelligentOptionsEmail,
  intelligentOptionsHUR_4,
  intelligentOptionsHUR_8,
  intelligentOptionsPromotions,
  intelligentOptionsSearchKeyword,
  intelligentOptionsT16,
  limitedTimeFrame,
  mailAlgorithmTitles,
  mostLimitedTimeFrame,
  mostSearchTimeFrame,
  previouslyAddedTimeFrame,
  reactSelectItems,
  rockstarTimeFrame,
  searchIntelligentOptions,
  timeFrame,
} from '../../constants/datamaps/wizard';
import {
  isBundleEnabled,
  isBunsarEnabled,
  isCategoryHierarchy,
  isHiddenProductsEnabled,
  isMostSearchForEnabled,
  isSuperUser,
  hasDynamicBundle,
  hasOnlyDynamicBundles,
  hasReplenishment,
} from '../../modules/auth/user';
import { isRockstarAlgorithmSelected } from '../../modules/campaigns/utils';
import { t } from '../../system/ui';
import Icon from '../icon';
import Icons from '../icons';
import Tooltip from '../tooltip';

const adminTypes = [
  ...reactSelectItems,
  { value: '55', label: '55' },
  { value: '60', label: '60' },
  { value: '65', label: '65' },
  { value: '70', label: '70' },
  { value: '75', label: '75' },
  { value: '100', label: '100' },
  { value: '125', label: '125' },
  { value: '150', label: '150' },
  { value: '175', label: '175' },
  { value: '200', label: '200' },
  { value: '225', label: '225' },
  { value: '250', label: '250' },
];

const isLimitedInsightSelected = type => {
  return (
    type === 'RECOMMENDATION_TRENDING_PRODUCTS' ||
    type === 'RECOMMENDATION_SHINING_STARS'
  );
};

const isCategoryOptionsSelected = type => {
  return type === 'RECOMMENDATION_SOURCE_RELATED_PRODUCT_PURCHASE';
};

class CriterionIntelligent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeFrameHidden: false,
      timeFrameDisabled: false,
      timeFrameDisableWhen: [
        'RECOMMENDATION_SOURCE_RELATED_PRODUCT',
        'RECOMMENDATION_SOURCE_RELATED_PRODUCT_PURCHASE',
        'RECOMMENDATION_SOURCE_RELATED_PRODUCT_PURCHASE_BASKET',
        'RECOMMENDATION_SMART_OFFERS',
        'RECOMMENDATION_SOURCE_LAST_VISITED',
        'RECOMMENDATION_PRICE_DROP',
        'RECOMMENDATION_BACK_IN_STOCK',
        'RECOMMENDATION_LAST_PURCHASE',
        'RECOMMENDATION_COLLABORATIVE_FILTERING',
        'RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER',
        'RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER_OFFLINE',
        'RECOMMENDATION_IMAGE_BASED_SIMILARITY',
        'RECOMMENDATION_SMART_PROMOTIONS',
        'RECOMMENDATION_HIDDEN_PRODUCTS',
        'RECOMMENDATION_BROWSING_ALTERNATIVES',
        'RECOMMENDATION_PURCHASE_ALTERNATIVES',
        'RECOMMENDATION_PREVIOUSLY_ADDED_TO_BASKET',
        'RECOMMENDATION_BEST_MATCH',
        'RECOMMENDATION_MOST_SEARCHED_PRODUCTS',
        'RECOMMENDATION_LAST_VISITED_BANNERS_PROMOTIONS',
        'RECOMMENDATION_LAST_VISITED_CATEGORY_PROMOTIONS',
        'RECOMMENDATION_LAST_VISITED_BRAND_PROMOTIONS',
      ],
      timeFrame: '',
      removableAlgorithmCampaigns: [
        'WE_MISSED_YOU',
        'CHURN',
        'TOP_SELLERS',
        'ORDER_FOLLOW_UP',
        'BIRTHDAY',
        'ANNIVERSARY',
        'LAST_VISIT_ALTERNATIVES',
        'NICE_TO_MEET_YOU',
      ],
      itemCount: false,
      tempAlg: '',
    };

    this.setTimeFrames = this.setTimeFrames.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSelectInputChange = this.onSelectInputChange.bind(this);
  }

  componentDidMount() {
    if (Object.keys(this.props.values).length > 0) {
      this.setTimeFrames();
    }
  }

  setTimeFrames() {
    const currentCriterion =
      this.props.values.criterion && this.props.values.criterion.value;
    this.setState(
      {
        timeFrameDisabled: this.props.values.timeFrame
          ? this.props.values.timeFrame.disabled
          : false,
      },
      () => {
        this.onChange();
      },
    );
  }

  onInputChange(selectedOption) {
    this.setState({
      product: selectedOption
        ? parseInt(selectedOption.value) > 250
          ? '250'
          : selectedOption.value
        : '',
    });
  }

  onChange(newItemCount) {
    const currentCriterion = this.refs.criterion.value;
    this.setState({
      tempAlg: currentCriterion,
    });

    const availableCategories = { all: 'All Products' };
    if (isCategoryHierarchy()) {
      availableCategories.category = 'Category Products';
    } else {
      availableCategories.parent = 'Parent Category Products';
      availableCategories.child = 'Child Category Products';
    }
    // Use Limited Timeframe if it's a keyword campaign or an insight algorithm selected.
    let availableTimeFrame = timeFrame;
    if (this.props.isKeyword && this.refs.criterion) {
      availableTimeFrame = limitedTimeFrame;
    }
    if (
      this.refs.criterion &&
      isLimitedInsightSelected(this.refs.criterion.value)
    ) {
      availableTimeFrame = mostLimitedTimeFrame;
    }
    if (
      this.refs.criterion &&
      isCategoryOptionsSelected(this.refs.criterion.value)
    ) {
      availableTimeFrame = availableCategories;
    }

    // Use Rockstart Timeframe if Rockstar Algorithms Selected
    if (
      this.refs.criterion &&
      isRockstarAlgorithmSelected(this.refs.criterion.value)
    ) {
      availableTimeFrame = rockstarTimeFrame;
    }

    // Use eventdata timeframe if event data selected
    if (
      this.refs.criterion &&
      this.refs.criterion.value === 'RECOMMENDATION_EVENT_DATA'
    ) {
      availableTimeFrame = eventDataTimeFrame;
    }

    if (newItemCount) {
      if (this.props.isSearch) {
        this.setState(
          {
            timeFrameDisabled:
              this.state.timeFrameDisableWhen.indexOf(currentCriterion) > -1,
            timeFrame:
              this.refs.timeFrame &&
              this.refs.timeFrame.value &&
              availableTimeFrame[this.refs.timeFrame.value]
                ? this.refs.timeFrame.value
                : availableTimeFrame[Object.keys(availableTimeFrame)[0]],
            mobileItemCount: this.refs.mobileCount.value,
            itemCount: this.refs.itemCount.value,
          },
          () => {
            this.props.onCriteriaChange(this.refs.inputs, this.props.id);
          },
        );
      } else {
        this.setState(
          {
            timeFrameDisabled:
              this.state.timeFrameDisableWhen.indexOf(currentCriterion) > -1,
            timeFrame:
              this.refs.timeFrame &&
              this.refs.timeFrame.value &&
              availableTimeFrame[this.refs.timeFrame.value]
                ? this.refs.timeFrame.value
                : availableTimeFrame[Object.keys(availableTimeFrame)[0]],
            itemCount: newItemCount ? newItemCount.value : this.state.itemCount,
          },
          () => {
            this.props.onCriteriaChange(this.refs.inputs, this.props.id);
          },
        );
      }
    } else {
      this.setState(
        {
          timeFrameDisabled:
            this.state.timeFrameDisableWhen.indexOf(currentCriterion) > -1,
          timeFrame:
            this.refs.timeFrame &&
            this.refs.timeFrame.value &&
            availableTimeFrame[this.refs.timeFrame.value]
              ? this.props.values.isNew
                ? this.refs.timeFrame.lastElementChild.value
                : this.refs.timeFrame.value
              : availableTimeFrame[Object.keys(availableTimeFrame)[0]],
          itemCount: false,
        },
        () => {
          this.props.onCriteriaChange(this.refs.inputs, this.props.id);
        },
      );
    }
  }

  onSelectInputChange(inputValue) {
    const maxCount = isSuperUser() ? 250 : 50;
    if (parseInt(inputValue) > maxCount) {
      return maxCount.toString();
    }
    return inputValue;
  }

  render() {
    let pageType;
    let intelligentWithRockstar = false;
    if (this.props.selectedPage) {
      pageType = this.props.selectedPage;
    } else if (this.props.isEmail) {
      pageType = 'Email';
    } else if (this.props.isSearch) {
      pageType = 'before-search-edit';
    } else {
      pageType = 'Personalization';
    }
    const isFiltered =
      hasOnlyDynamicBundles() &&
      (pageType === 'product' || pageType === 'basket');
    let currentIntelligents = {};
    if (
      pageType === 'home' ||
      pageType === 'category' ||
      pageType === 'search' ||
      pageType === 'notfound' ||
      pageType === '404' ||
      pageType === 'before-search-edit'
    ) {
      if (
        pageType === 'home' ||
        pageType === 'notfound' ||
        pageType === 'search' ||
        pageType === '404'
      ) {
        currentIntelligents = {
          ...currentIntelligents,
          RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
          RECOMMENDATION_NEW_COMERS: 'New Arrivals',
          RECOMMENDATION_DISCOUNTED_PRODUCTS: 'Discounted Products',
          ...intelligentOptionsHUR_4,
        };
        if (hasReplenishment()) {
          currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
            'Replenishment Algorithm';
        }
      } else if (pageType === 'before-search-edit') {
        if (isMostSearchForEnabled()) {
          currentIntelligents = {
            ...searchIntelligentOptions,
            RECOMMENDATION_MOST_SEARCHED_PRODUCTS: 'Most Searched For',
          };
        } else {
          currentIntelligents = { ...searchIntelligentOptions };
        }
      } else {
        currentIntelligents = { ...intelligentOptionsHUR_4 };
        if (hasReplenishment()) {
          currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
            'Replenishment Algorithm';
        }
      }
    } else if (pageType === 'product') {
      if (hasDynamicBundle()) {
        currentIntelligents = {
          ...intelligentOptionsHUR_8,
          RECOMMENDATION_RELATED_COMBINE_PRODUCTS: 'Bundle Products',
        };
      } else {
        currentIntelligents = intelligentOptionsHUR_8;
      }
      if (isBunsarEnabled()) {
        currentIntelligents.RECOMMENDATION_IMAGE_BASED_SIMILARITY =
          'Look Alike';
      }
      if (hasDynamicBundle()) {
        currentIntelligents.RECOMMENDATION_SHOP_THE_LOOK = 'Shop The Look';
      }
      if (isFiltered) {
        currentIntelligents = {
          RECOMMENDATION_RELATED_COMBINE_PRODUCTS: 'Bundle Products',
          RECOMMENDATION_SHOP_THE_LOOK: 'Shop The Look',
        };
      }
      if (hasReplenishment()) {
        currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
          'Replenishment Algorithm';
      }
    } else if (pageType === 'basket' || pageType === 'checkout-success') {
      if (pageType === 'basket') {
        if (isBundleEnabled()) {
          currentIntelligents = {
            ...intelligentOptionsT16,
            RECOMMENDATION_RELATED_COMBINE_PRODUCTS: 'Bundle Products',
            RECOMMENDATION_LAST_PURCHASE: 'Last Purchased',
            RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER_OFFLINE:
              'Mostly Purchased Together - Offline',
          };
        } else {
          currentIntelligents = {
            ...intelligentOptionsT16,
            RECOMMENDATION_LAST_PURCHASE: 'Last Purchased',
            RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER_OFFLINE:
              'Mostly Purchased Together - Offline',
          };
        }
        if (isFiltered) {
          currentIntelligents = {
            RECOMMENDATION_RELATED_COMBINE_PRODUCTS: 'Bundle Products',
            RECOMMENDATION_SHOP_THE_LOOK: 'Shop The Look',
          };
        }
      } else {
        currentIntelligents = {
          RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
          ...intelligentOptionsT16,
        };
      }
      if (hasReplenishment()) {
        currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
          'Replenishment Algorithm';
      }
    } else if (
      pageType === 'triggerbased' ||
      (pageType === 'custom' &&
        this.props.campaign &&
        this.props.campaign.templateId &&
        this.props.campaign.templateId === 'SMSG_01')
    ) {
      if (isBundleEnabled()) {
        currentIntelligents = {
          ...intelligentOptions,
          RECOMMENDATION_RELATED_COMBINE_PRODUCTS: 'Bundle Products',
        };
      } else {
        currentIntelligents = intelligentOptions;
      }
      if (hasReplenishment()) {
        currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
          'Replenishment Algorithm';
      }
    } else if (pageType === 'custom') {
      if (isBundleEnabled()) {
        currentIntelligents = {
          ...intelligentOptionsCustom,
          RECOMMENDATION_NEW_COMERS: 'New Arrivals',
          RECOMMENDATION_RELATED_COMBINE_PRODUCTS: 'Bundle Products',
        };
      } else {
        currentIntelligents = {
          ...intelligentOptionsCustom,
          RECOMMENDATION_NEW_COMERS: 'New Arrivals',
        };
      }
      if (hasReplenishment()) {
        currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
          'Replenishment Algorithm';
      }
    } else if (pageType === 'afterSearch') {
      currentIntelligents = {
        ...afterSearchIntelligentOptions,
      };
    } else {
      currentIntelligents = intelligentOptionsHUR_4;
    }

    if (isHiddenProductsEnabled()) {
      currentIntelligents = {
        ...currentIntelligents,
        RECOMMENDATION_HIDDEN_PRODUCTS: 'Hidden Products',
      };
    }

    if (
      pageType === 'Personalization' ||
      this.props.isSingleRec ||
      pageType === 'popup_recommendation'
    ) {
      currentIntelligents = {
        ...intelligentOptionsHUR_4,
        RECOMMENDATION_DISCOUNTED_PRODUCTS: 'Discounted Products',
        RECOMMENDATION_NEW_COMERS: 'New Arrivals',
        RECOMMENDATION_SOURCE_ALL_PRODUCTS: 'Spotlights',
        RECOMMENDATION_CATEGORY_ROCKS: 'Category Rocks',
        RECOMMENDATION_SOURCE_RELATED_PRODUCT: 'Alternative Products',
        RECOMMENDATION_COLLABORATIVE_FILTERING: 'Complementary Products',
        RECOMMENDATION_MOSTLY_PURCHASED_TOGETHER: 'Mostly Purchased Together',
      };
      if (hasReplenishment() && pageType === 'popup_recommendation') {
        currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
          'Replenishment Algorithm';
      }
    }

    if (pageType === 'Email') {
      //   if (this.props.mailProvider === 'SEGMENTIFY') {
      //     if (this.props.emailType === 'RECOMMENDATION') {
      //       currentIntelligents = {
      //         ...intelligentOptionsMailProviderSegmentify,
      //       };
      //     } else {
      //       currentIntelligents = { ...intelligentOptionsEmail };
      //     }
      //   }
      // } else {
      currentIntelligents = { ...intelligentOptionsEmail };
    }

    /* ROCKSTAR ALGORITHMS */
    // Category Rocks
    if (
      pageType === 'category' ||
      pageType === 'product' ||
      pageType === 'triggerbased' ||
      (this.props.selectedType &&
        this.props.selectedType.page &&
        this.props.selectedType.page === 'triggerbased')
    ) {
      if (!isFiltered) {
        intelligentWithRockstar = { ...currentIntelligents };
        intelligentWithRockstar.RECOMMENDATION_CATEGORY_ROCKS =
          'Category Rocks';
      }
    }

    // Current Intelligents for Promotions
    if (this.props.isPromotion) {
      currentIntelligents = intelligentOptionsPromotions;
    }

    // Current Intellgents for Keyword Recommendation
    if (this.props.isKeyword) {
      currentIntelligents = intelligentOptionsSearchKeyword;
    }

    let defaultCriterion = '';
    let defaultTimeFrame = '';
    const defaultItemCountHidden = 5; // Sets RECOMMENDATION_SHOP_THE_LOOK item count to 5 while its hidden
    let defaultItemCount = 4;
    let mobileItemCount = 8;
    const defaultCategorySelection = '';

    if (Object.keys(this.props.values).length > 0) {
      if (this.state.timeFrame) {
        defaultTimeFrame = this.state.timeFrame;
      } else if (this.props.values.timeFrame) {
        defaultTimeFrame = this.props.values.timeFrame.value;
        if (
          this.refs.criterion &&
          isRockstarAlgorithmSelected(this.refs.criterion.value) &&
          !rockstarTimeFrame[defaultTimeFrame]
        ) {
          defaultTimeFrame = getRockstarTimeFrameMapping(defaultTimeFrame);
        }
      }
      defaultCriterion =
        (this.props.values.criterion && this.props.values.criterion.value) ||
        '';
      defaultTimeFrame = defaultTimeFrame || '';
      defaultItemCount =
        (this.props.values.itemCount && this.props.values.itemCount.value) || 4;
      mobileItemCount =
        (this.props.values.mobileItemCount &&
          this.props.values.mobileItemCount.value) ||
        4;

      if (defaultCriterion === 'RECOMMENDATION_CATEGORY_ROCKS') {
        intelligentWithRockstar = { ...currentIntelligents };
        intelligentWithRockstar.RECOMMENDATION_CATEGORY_ROCKS =
          'Category Rocks';
      }
    }

    const sortedAlgorithmKeys = Object.keys(currentIntelligents).sort(
      (algorithmA, algorithmB) => {
        if (algorithmOrders[algorithmA] && algorithmOrders[algorithmB]) {
          const a = algorithmOrders[algorithmA][pageType];
          const b = algorithmOrders[algorithmB][pageType];
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        }
        return 0;
      },
    );

    const sortedAlgorithms = {};
    Object.keys(sortedAlgorithmKeys).forEach(algorithm => {
      if (currentIntelligents) {
        sortedAlgorithms[sortedAlgorithmKeys[algorithm]] =
          currentIntelligents[sortedAlgorithmKeys[algorithm]];
      }
    });

    currentIntelligents = sortedAlgorithms;

    if (intelligentWithRockstar) {
      const sortedRockstarAlgorithmKeys = Object.keys(
        intelligentWithRockstar,
      ).sort((algorithmA, algorithmB) => {
        if (algorithmOrders[algorithmA] && algorithmOrders[algorithmB]) {
          const a = algorithmOrders[algorithmA][pageType];
          const b = algorithmOrders[algorithmB][pageType];
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        }
        return 0;
      });

      const sortedRockstarAlgorithms = {};
      Object.keys(sortedRockstarAlgorithmKeys).forEach(algorithm => {
        if (currentIntelligents) {
          sortedRockstarAlgorithms[sortedRockstarAlgorithmKeys[algorithm]] =
            intelligentWithRockstar[sortedRockstarAlgorithmKeys[algorithm]];
        }
      });
      intelligentWithRockstar = sortedRockstarAlgorithms;
    }

    /* Sorting End */

    const availableCategories = { all: 'All Products' };
    if (isCategoryHierarchy()) {
      availableCategories.category = 'Category Products';
    } else {
      availableCategories.parent = 'Parent Category Products';
      availableCategories.child = 'Child Category Products';
    }

    // Use Limited Timeframe if it's a keyword campaign or an insight algorithm selected.
    let availableTimeFrame = timeFrame;
    if (this.props.isKeyword && this.refs.criterion) {
      availableTimeFrame = limitedTimeFrame;
    }
    if (
      this.refs.criterion &&
      isLimitedInsightSelected(this.refs.criterion.value)
    ) {
      availableTimeFrame = mostLimitedTimeFrame;
    }
    if (
      this.refs.criterion &&
      isCategoryOptionsSelected(this.refs.criterion.value)
    ) {
      availableTimeFrame = availableCategories;
    }

    // Use Rockstart Timeframe if Rockstar Algorithms Selected
    if (
      this.refs.criterion &&
      isRockstarAlgorithmSelected(this.refs.criterion.value)
    ) {
      availableTimeFrame = rockstarTimeFrame;
    }

    if (
      this.refs.criterion &&
      this.refs.criterion.value === 'RECOMMENDATION_MOST_SEARCHED_PRODUCTS'
    ) {
      availableTimeFrame = mostSearchTimeFrame;
    }

    if (
      this.refs.criterion &&
      this.refs.criterion.value === 'RECOMMENDATION_PREVIOUSLY_ADDED_TO_BASKET'
    ) {
      availableTimeFrame = previouslyAddedTimeFrame;
    }

    // Use eventdata timeframe if event data selected
    if (
      this.refs.criterion &&
      this.refs.criterion.value === 'RECOMMENDATION_EVENT_DATA'
    ) {
      availableTimeFrame = eventDataTimeFrame;
    }

    if (
      (this.refs.criterion &&
        this.refs.criterion?.value ===
          'RECOMMENDATION_LAST_VISITED_BANNERS_PROMOTIONS') ||
      this.refs.criterion?.value ===
        'RECOMMENDATION_LAST_VISITED_CATEGORY_PROMOTIONS' ||
      this.refs.criterion?.value ===
        'RECOMMENDATION_LAST_VISITED_BRAND_PROMOTIONS'
    ) {
      availableTimeFrame = mostSearchTimeFrame;
    }

    const DragHandle = SortableHandle(() => (
      <span className='draghandler'>
        <Icons name='burger' color='#e8e7f2' />
      </span>
    ));
    const isTimeFrameDisabled = availableTimeFrame.all
      ? false
      : this.state.timeFrameDisabled;

    // Hides the item count dropdown if the algorithm is RECOMMENDATION_SHOP_THE_LOOK

    const isTimeFrameHidden =
      this.refs.criterion &&
      this.refs.criterion.value === 'RECOMMENDATION_SHOP_THE_LOOK';

    if (this.props.emailType === 'TOP_SELLERS') {
      currentIntelligents = {
        RECOMMENDATION_STATISTICAL_TOP_SELLERS: 'Top Sellers',
        RECOMMENDATION_TOP_SELLERS: 'Personalised Top Sellers',
      };
    } else if (
      this.props.emailType === 'WE_MISSED_YOU' ||
      this.props.emailType === 'CHURN' ||
      this.props.emailType === 'BIRTHDAY' ||
      this.props.emailType === 'ANNIVERSARY'
    ) {
      currentIntelligents.RECOMMENDATION_SMART_OFFERS = 'Smart Offers';
    } else if (this.props.emailType === 'ORDER_FOLLOW_UP') {
      currentIntelligents.RECOMMENDATION_COLLABORATIVE_FILTERING =
        'Complementary Products';
    } else if (this.props.emailType === 'LAST_VISIT_ALTERNATIVES') {
      currentIntelligents.RECOMMENDATION_BROWSING_ALTERNATIVES =
        'Browsing Alternatives';
    } else if (this.props.emailType === 'REPLENISHMENT') {
      currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
        'Replenishment Algorithm';
    } else if (this.props.emailType === 'NICE_TO_MEET_YOU') {
      currentIntelligents.RECOMMENDATION_SOURCE_ALL_PRODUCTS = 'Spotlights';
    } else if (this.props.emailType === 'PRICE_DROP') {
      currentIntelligents.RECOMMENDATION_PRICE_DROP = 'Price Drop';
    } else if (this.props.emailType === 'BACK_IN_STOCK') {
      currentIntelligents.RECOMMENDATION_BACK_IN_STOCK = 'Back In Stock';
    } else if (
      this.props.emailType === 'RECOMMENDATION' ||
      this.props.emailType === 'FLY'
    ) {
      currentIntelligents.RECOMMENDATION_REPLENISHMENT_PRODUCTS =
        'Replenishment Algorithm';
    }

    return (
      <li className='wizard-criterion' ref='inputs'>
        {!this.props.isMain && !this.props.isPushModule && <DragHandle />}
        <span className='criteria-type'>
          <Tooltip content={t('Intelligent')} alignment='left'>
            {this.props.isKeyword ? (
              <Icon name='magnify' />
            ) : (
              <Icons name='brain' color='#5A7391' />
            )}
          </Tooltip>
        </span>
        <label
          className={
            this.props.isMain
              ? 'item item-field is-select criteria-name criteria-name-email-main'
              : 'item item-field is-select criteria-name'
          }
        >
          <select
            name='criterion'
            ref='criterion'
            className='one-whole criteria-field'
            defaultValue={defaultCriterion}
            onChange={this.onChange}
          >
            {!this.props.isPromotion &&
            !this.props.isKeyword &&
            !this.props.isSingleRec &&
            intelligentWithRockstar
              ? Object.keys(intelligentWithRockstar).map(item => {
                  if (this.props.isMain) {
                    return (
                      <option disabled key={item} value={item}>
                        {t(mailAlgorithmTitles(this.props.emailType).title)}
                      </option>
                    );
                  }
                  if (this.props.isEmail) {
                    return mailAlgorithmTitles(this.props.emailType).key !==
                      item ? (
                      <option key={item} value={item}>
                        {t(intelligentWithRockstar[item])}
                      </option>
                    ) : (
                      ''
                    );
                  }
                  return (
                    <option key={item} value={item}>
                      {t(intelligentWithRockstar[item])}
                    </option>
                  );
                })
              : Object.keys(currentIntelligents).map(item => {
                  if (this.props.isMain) {
                    return (
                      <option disabled key={item} value={item}>
                        {t(mailAlgorithmTitles(this.props.emailType).title)}
                      </option>
                    );
                  }
                  if (
                    this.props.emailType === 'FLY' ||
                    this.props.emailType === 'RECOMMENDATION'
                  ) {
                    return (
                      <option key={item} value={item}>
                        {t(currentIntelligents[item])}
                      </option>
                    );
                  }
                  if (this.props.isEmail) {
                    return (
                      <option key={item} value={item}>
                        {t(currentIntelligents[item])}
                      </option>
                    );
                  }
                  if (
                    this.props.isPushModule &&
                    this.props.pushType === 'LAST_VISIT_REMINDER'
                  ) {
                    return (
                      <option key={item} value={item}>
                        {t(mailAlgorithmTitles(this.props.pushType).pushTitle)}
                      </option>
                    );
                  }
                  if (
                    this.props.isPushModule &&
                    this.props.pushType !== 'RECOMMENDATION' &&
                    this.props.pushType !== 'FLY'
                  ) {
                    return (
                      <option key={item} value={item}>
                        {t(
                          mailAlgorithmTitles(this.props.pushType, true).title,
                        )}
                      </option>
                    );
                  }
                  return (
                    <option key={item} value={item}>
                      {t(currentIntelligents[item])}
                    </option>
                  );
                })}
          </select>
        </label>
        {
          <label
            className={
              isTimeFrameDisabled
                ? 'item item-field is-select criteria-time-frame js-disabled'
                : isTimeFrameHidden
                ? 'item item-field is-select criteria-time-frame d-hidden'
                : 'item item-field is-select criteria-time-frame'
            }
            style={{ display: this.props.isMain ? 'none' : 'inline-block' }}
          >
            <select
              key={Math.random()}
              name='timeFrame'
              ref='timeFrame'
              className='one-whole criteria-field'
              defaultValue={defaultTimeFrame}
              disabled={isTimeFrameDisabled}
              onChange={this.onChange}
            >
              {Object.keys(availableTimeFrame).map(item => (
                <option key={item} value={item}>
                  {t(availableTimeFrame[item])}
                </option>
              ))}
            </select>
          </label>
        }

        {this.props.isSearch === undefined && (
          <label
            className={
              this.props.isMain
                ? 'item item-field is-select criteria-item-count item-field-email-main'
                : isTimeFrameHidden
                ? 'item item-field is-select criteria-item-count d-hidden'
                : 'item item-field is-select criteria-item-count'
            }
          >
            <span className='item-label'>
              {this.props.isPromotion ? 'promotion' : 'product'}
            </span>
            <Select.Creatable
              value={
                this.state.itemCount
                  ? this.state.itemCount
                  : isTimeFrameHidden
                  ? defaultItemCountHidden
                  : defaultItemCount
              }
              options={this.props.isEmail ? adminTypes.slice(0, 9) : adminTypes}
              name='itemCount'
              clearable={false}
              searchable
              onChange={this.onChange}
              onInputChange={this.onSelectInputChange}
              className='one-whole criteria-field'
              isValidNewOption={option =>
                option.label !== '' &&
                typeof option.label !== 'undefined' &&
                parseInt(option.label) < 251
              }
            />
          </label>
        )}

        {this.props.isSearch && (
          <>
            <label className='item item-field is-select criteria-item-mobile-count'>
              <span className='item-label'>mobile</span>

              <select
                key={Math.random()}
                defaultValue={
                  this.state.mobileItemCount
                    ? this.state.mobileItemCount
                    : mobileItemCount
                }
                ref='mobileCount'
                name='mobileItemCount'
                onChange={this.onChange}
                className='one-whole criteria-field'
              >
                {reactSelectItems.map(typeValues => (
                  <option key={typeValues.value} value={typeValues.value}>
                    {typeValues.label}
                  </option>
                ))}
              </select>
            </label>

            <label className='item item-field is-select criteria-item-mobile-count'>
              <span className='item-label'>desktop</span>

              <select
                key={Math.random()}
                defaultValue={
                  this.state.itemCount ? this.state.itemCount : defaultItemCount
                }
                ref='itemCount'
                name='itemCount'
                onChange={this.onChange}
                className='one-whole criteria-field'
              >
                {reactSelectItems.map(typeValues => (
                  <option key={typeValues.value} value={typeValues.value}>
                    {typeValues.label}
                  </option>
                ))}
              </select>
            </label>
          </>
        )}

        {!this.props.isKeyword && !this.props.isMain && (
          <a
            onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
            className={
              this.props.isOnlySelection
                ? 'cancel-action cancel-action--disabled'
                : 'cancel-action'
            }
          >
            <Icons name='crossCircle' />
          </a>
        )}
        {!this.props.isKeyword &&
          this.props.isMain &&
          this.state?.removableAlgorithmCampaigns?.includes(
            this.props?.emailType,
          ) && (
            <a
              onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
              className={
                this.props.isOnlySelection
                  ? 'cancel-action cancel-action--disabled'
                  : 'cancel-action'
              }
            >
              <Icons name='crossCircle' />
            </a>
          )}
      </li>
    );
  }
}

const mapStatesToProps = (store, thisProps) => ({
  criteria:
    thisProps.selectedPage === 'afterSearch'
      ? store.wizard.noResultCriteria
      : store.wizard.criteria,
  mailProvider:
    store.switchedUser?.switchedUser?.account?.mailConfiguration?.provider,
});

CriterionIntelligent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStatesToProps)(CriterionIntelligent);
