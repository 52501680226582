import React from 'react';
import PropTypes from 'prop-types';

function PlusWithBorder(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${props.width}px`}
      height={`${props.height}px`}
      viewBox='0 0 20 20'
    >
      <path
        d='M10 5.83333C10.3452 5.83333 10.625 6.11315 10.625 6.45833V9.37499H13.5417C13.8869 9.37499 14.1667
        9.65483 14.1667 9.99999C14.1667 10.3452 13.8869 10.625 13.5417 10.625H10.625V13.5417C10.625 13.8868 10.3452 14.1667 10
        14.1667C9.65487 14.1667 9.37504 13.8868 9.37504 13.5417V10.625H6.45837C6.1132 10.625 5.83337 10.3452 5.83337 9.99999C5.83337
        9.65483 6.1132 9.37499 6.45837 9.37499H9.37504V6.45833C9.37504 6.11315 9.65487 5.83333 10 5.83333Z'
        fill={props.color}
      />
      <path
        d='M2.5 5.20833C2.5 3.71256 3.71256 2.5 5.20833 2.5H14.7917C16.2874 2.5 17.5 3.71256 17.5 5.20833V14.7917C17.5 16.2874
        16.2874 17.5 14.7917 17.5H5.20833C3.71256 17.5 2.5 16.2874 2.5 14.7917V5.20833ZM5.20833 3.75C4.40292 3.75 3.75 4.40292
        3.75 5.20833V14.7917C3.75 15.5971 4.40292 16.25 5.20833 16.25H14.7917C15.5971 16.25 16.25 15.5971 16.25 14.7917V5.20833C16.25
        4.40292 15.5971 3.75 14.7917 3.75H5.20833Z'
        fill={props.color}
      />
    </svg>
  );
}

PlusWithBorder.defaultProps = {
  color: '#233679',
  width: 20,
  height: 20,
};

PlusWithBorder.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default PlusWithBorder;
