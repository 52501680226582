import React from 'react';
import { TextField } from '../../fields';
import './SFYCardHeader.scss';
import Icon from '../../icon';

const SFYCardHeader = ({
  cardTitle,
  stateSetter,
  state,
  placeholder,
  setMargin,
  required,
  promotionType,
  readOnly,
  titleColor,
  iconName,
}) => {
  return (
    <div
      className='sfy-card-header'
      style={{
        margin: setMargin ? '20px 0 20px 0' : '',
      }}
    >
      <div className='sfy-card-header-head'>
        <Icon name={iconName} size='16' />
        <span className={`item-label ${titleColor || ''}`}>{cardTitle}</span>
      </div>

      <TextField
        value={state}
        type='text'
        className='item-stacked one-whole'
        placeholder={placeholder}
        required={required}
        readOnly={readOnly}
        onChange={e =>
          promotionType
            ? stateSetter(e.target.value, promotionType)
            : stateSetter(e.target.value)
        }
      />
    </div>
  );
};

export default SFYCardHeader;
