/**
 * @author Bilal Cinarli
 */

import { locale } from '../constants/ui';

import { toLocaleString } from './localeString';

export const calculateStatRatio = (statType, statTotal) => {
  Object.keys(statType).forEach(item => {
    const stat = statType[item];

    if (stat.hasOwnProperty('ratio')) {
      let { count } = stat;

      if (typeof count === 'undefined') {
        count = statType[item.replace('Ratio', 'Count')].count;
      }

      stat.ratio = calculateRatio(count, statTotal);
    }
  });

  return statType;
};

export const calculateRatio = (fragment, total, fixed = 2) => {
  let finalResult;
  finalResult =
    total > 0 && typeof fragment !== 'undefined'
      ? toLocaleString((fragment / total) * 100, locale, {
          minimumFractionDigits: fixed,
          maximumFractionDigits: fixed,
        })
      : '0.0';
  if (finalResult !== '0.0' && finalResult > 100) {
    finalResult = '100.0';
  }
  if (typeof total === 'undefined' && typeof fragment !== 'undefined') {
    finalResult = '100.0';
  }
  if (typeof total !== 'undefined' && typeof fragment !== 'undefined') {
    if (total < fragment) {
      finalResult = '100.0';
    }
  }
  return finalResult;
};

export const calculateSegmentRatio = (fragment, total, fixed = 2) => {
  let finalResult;
  finalResult =
    total > 0 && typeof fragment !== 'undefined'
      ? toLocaleString((fragment / total) * 100, locale, {
          minimumFractionDigits: fixed,
          maximumFractionDigits: fixed,
        })
      : '0.00';
  if (finalResult !== '0.0' && finalResult > 100) {
    finalResult = '100';
  }
  if (typeof total === 'undefined' && typeof fragment !== 'undefined') {
    finalResult = '100';
  }
  if (typeof total !== 'undefined' && typeof fragment !== 'undefined') {
    if (total < fragment) {
      finalResult = '100';
    }
  }
  return finalResult;
};

export const numericRoundRatio = (ratio, fixed = 2) => {
  return ratio > 0
    ? toLocaleString(ratio, locale, {
        minimumFractionDigits: fixed,
        maximumFractionDigits: fixed,
      })
    : '0.0';
};

export const numericCalculateRatio = (fragment, total) => {
  if (typeof total === 'undefined' || total === 0) {
    return 0;
  }

  return (fragment / total) * 100;
};

export const numericDiffRatio = (cr, fixed = 2) => {
  if (isNaN(cr)) cr = 0;

  cr *= 100;

  return `${toLocaleString(cr, locale, {
    minimumFractionDigits: fixed,
    maximumFractionDigits: fixed,
  })}%`;
};

export const diffRatio = (cr, crPrev, fixed = 2) => {
  let diff = null;

  if ((typeof crPrev === 'undefined' || crPrev === 0) && cr) {
    diff = 100;
  }

  if (!cr && !crPrev) {
    diff = 0;
  }

  if (crPrev > 100) {
    crPrev = 100;
  }

  if (cr > 100) {
    cr = 100;
  }

  if (diff === null) {
    if (cr > crPrev) {
      diff = ((cr - crPrev) / crPrev) * 100;
    } else {
      diff = ((crPrev - cr) / crPrev) * 100;
    }
  }

  if (diff > 100) {
    diff = 100;
  }

  return `${toLocaleString(diff, locale, {
    minimumFractionDigits: fixed,
    maximumFractionDigits: fixed,
  })}%`;
};

export const calculateProportion = (fragment, total, fixed = 0) => {
  return total > 0 && typeof fragment !== 'undefined' ? fragment / total : 0;
};
