import React, { useCallback } from 'react';
import classes from './EmailTemplatesNavigation.module.scss';

export const EmailTemplatesNavigation = ({ elements, children }) => {
  const sortedElements = useCallback(() => {
    return elements
      .sort((a, b) => a.priority - b.priority)
      .map(({ key, element }) => (
        <div key={key} className={classes['email-navigation__action']}>
          {element}
        </div>
      ));
  }, [elements]);

  return (
    <div
      className={
        classes[
          `email-navigation-container${!children ? '' : '__with-children'}`
        ]
      }
    >
      {children ? (
        <div className={classes['email-navigation__children']}>{children}</div>
      ) : null}
      <div className={classes['email-navigation__actions']}>
        {sortedElements()}
      </div>
    </div>
  );
};
