/* eslint-disable prefer-destructuring */
import { config } from '../../../constants/config';
import { uiActions } from '../../../actions';
import { t } from '../../../system/ui';
import { conditionOperatorOptions, conditionTimeUnits } from './constants';

const capitalize = s => {
  if (typeof s !== 'string') return '';

  const words = s.split(' ');
  return words
    .map(word => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

const addBreadcrumb = (campaignType, campaignName) => {
  const fixedCampaignType = capitalize(campaignType.replaceAll('-', ' '));

  const breadcrumbDetails = {
    home: {
      name: t('Engagement'),
      icon: 'wand',
      iconSvg: true,
      slug: '/behavioural-targeting/engagement',
    },
    top: {
      name: t(
        config.menus.primary['behavioural-targeting'].children.social_proof
          .name,
      ),
      slug:
        config.menus.primary['behavioural-targeting'].children.social_proof
          .slug,
    },
    currentType: {
      name: fixedCampaignType,
      slug:
        config.menus.primary['behavioural-targeting'].children.social_proof
          .slug,
    },
  };

  if (campaignName) {
    breadcrumbDetails.currentCampaign = {
      name: campaignName,
    };
  }

  uiActions.showBreadcrumb(breadcrumbDetails);
};

const convertToSelectOptions = arr => {
  return arr.map(item => {
    return {
      value: item,
      label: item,
    };
  });
};

const separatePositions = (positions, deviceType) => {
  let verticalPos = '';
  let horizontalPos = '';
  if (deviceType === 'desktop') {
    verticalPos = positions
      .split('&')[0]
      .replaceAll(' ', '')
      .toLowerCase();
    horizontalPos = positions
      .split('&')[1]
      .replaceAll(' ', '')
      .toLowerCase();
  } else if (deviceType === 'mobile') {
    verticalPos = positions
      .split('&')[0]
      .replaceAll(' ', '')
      .toLowerCase();
    horizontalPos = 'center';
  }
  return { verticalPos, horizontalPos };
};

const firstLetterUpperCase = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const margePositions = (verticalPos, horizontalPos, deviceType) => {
  let positions = '';
  if (deviceType === 'desktop') {
    positions = `${firstLetterUpperCase(verticalPos)} & ${firstLetterUpperCase(
      horizontalPos,
    )}`;
  } else if (deviceType === 'mobile') {
    positions = firstLetterUpperCase(verticalPos);
  }
  return positions;
};

const getSelectedDevices = (checkedDTDesktop, checkedDTMobile) => {
  const selectedDevices = [];
  if (checkedDTDesktop) {
    selectedDevices.push('PC');
  }
  if (checkedDTMobile) {
    selectedDevices.push('MOBILE');
    selectedDevices.push('TABLET');
  }
  return selectedDevices;
};

const getTimeRange = selectedTimeRange => {
  const timeRange = {};
  if (selectedTimeRange.includes('hour')) {
    const timeValue = selectedTimeRange.split(' ')[0];
    timeRange.value = timeValue === 'a' ? 1 : parseInt(timeValue);
    timeRange.type = 'hours';
  } else if (selectedTimeRange.includes('day')) {
    const timeValue = selectedTimeRange.split(' ')[0];
    timeRange.value = timeValue === 'a' ? 1 : parseInt(timeValue);
    timeRange.type = 'days';
  } else if (selectedTimeRange.includes('week')) {
    timeRange.value = 7;
    timeRange.type = 'days';
  } else if (selectedTimeRange.includes('month')) {
    timeRange.value = 30;
    timeRange.type = 'days';
  } else if (selectedTimeRange.includes('realtime')) {
    timeRange.value = 0;
    timeRange.type = 'realtime';
  }
  return timeRange;
};

const margeTimeRange = timeRange => {
  if (!timeRange) {
    return '';
  }

  const timeRangeValue = timeRange.value;
  const timeRangeType = timeRange.type;
  let returnValue = '';
  if (timeRangeType === 'days') {
    if (timeRangeValue === 30) {
      returnValue = 'month';
    } else if (timeRangeValue === 7) {
      returnValue = 'week';
    } else if (timeRangeValue === 1) {
      returnValue = 'a day';
    }
  } else if (timeRangeType === 'hours') {
    if (timeRangeValue === 1) {
      returnValue = '1 hour';
    } else {
      returnValue = `${timeRangeValue} hours`;
    }
  } else if (timeRangeType === 'realtime') {
    returnValue = 'realtime';
  }

  return returnValue;
};

const getCondition = (condition, conditionType, conditionDetail) => {
  if (!condition.isVisible) {
    // If condition is not visible, return empty object
    return null;
  }

  let hasMissing = false;
  const currentCondition = condition[conditionType];
  const conditionObj = {};
  conditionObj.operator = currentCondition.operator;
  let value = '';

  if (conditionDetail.conditionType === 'time') {
    if (currentCondition.maxValue) {
      value = [
        {
          value: parseInt(currentCondition.value),
          type: currentCondition.unit,
        },
        {
          value: parseInt(currentCondition.maxValue),
          type: currentCondition.maxUnit,
        },
      ];
      hasMissing =
        currentCondition.value === '' || currentCondition.maxValue === '';
    } else {
      value = {
        value: parseInt(currentCondition.value),
        type: currentCondition.unit,
      };
      hasMissing = currentCondition.value === '';
    }
  } else if (currentCondition.maxValue) {
    value = [
      parseInt(currentCondition.value),
      parseInt(currentCondition.maxValue),
    ];
    hasMissing =
      currentCondition.value === '' || currentCondition.maxValue === '';
  } else {
    value = parseInt(currentCondition.value);
    hasMissing = currentCondition.value === '';
  }

  conditionObj.value = value;

  if (hasMissing) {
    return null;
  }

  return conditionObj;
};

const margeCondition = (
  conditions,
  scoreConditions,
  reviewConditions,
  conditionDetail,
) => {
  const conditionObj = {
    isVisible: false,
    default: {
      operator: conditionOperatorOptions[0].value,
      value: '',
      maxValue: '',
      unit: conditionTimeUnits[0].value,
      maxUnit: conditionTimeUnits[0].value,
    },
    review: {
      operator: conditionOperatorOptions[0].value,
      value: '',
      maxValue: '',
      unit: conditionTimeUnits[0].value,
      maxUnit: conditionTimeUnits[0].value,
    },
    score: {
      operator: conditionOperatorOptions[0].value,
      value: '',
      maxValue: '',
      unit: conditionTimeUnits[0].value,
      maxUnit: conditionTimeUnits[0].value,
    },
  };
  if (
    conditions?.length > 0 ||
    scoreConditions?.length > 0 ||
    reviewConditions?.length > 0
  ) {
    conditionObj.isVisible = true;
    if (conditions?.length > 0) {
      conditionObj.default.operator = conditions[0].operator;
      if (conditionDetail.conditionType === 'time') {
        if (conditions[0].value.length > 1) {
          conditionObj.default.value = conditions[0].value[0].value;
          conditionObj.default.maxValue = conditions[0].value[1].value;
          conditionObj.default.unit = conditions[0].value[0].type;
          conditionObj.default.maxUnit = conditions[0].value[1].type;
        } else {
          conditionObj.default.value = conditions[0].value.value;
          conditionObj.default.unit = conditions[0].value.type;
        }
      } else if (
        typeof conditions[0].value === 'object' &&
        conditions[0].value.length > 1
      ) {
        conditionObj.default.value = conditions[0].value[0];
        conditionObj.default.maxValue = conditions[0].value[1];
      } else {
        conditionObj.default.value = conditions[0].value;
      }
    }
    if (scoreConditions?.length > 0) {
      conditionObj.score.operator = scoreConditions[0].operator;
      if (
        typeof scoreConditions[0].value === 'object' &&
        scoreConditions[0].value.length > 1
      ) {
        conditionObj.score.value = scoreConditions[0].value[0];
        conditionObj.score.maxValue = scoreConditions[0].value[1];
      } else {
        conditionObj.score.value = scoreConditions[0].value;
      }
    }
    if (reviewConditions?.length > 0) {
      conditionObj.review.operator = reviewConditions[0].operator;
      if (
        typeof reviewConditions[0].value === 'object' &&
        reviewConditions[0].value.length > 1
      ) {
        conditionObj.review.value = reviewConditions[0].value[0];
        conditionObj.review.maxValue = reviewConditions[0].value[1];
      } else {
        conditionObj.review.value = reviewConditions[0].value;
      }
    }
  }
  return conditionObj;
};

export {
  capitalize,
  addBreadcrumb,
  convertToSelectOptions,
  separatePositions,
  margePositions,
  getSelectedDevices,
  getTimeRange,
  margeTimeRange,
  getCondition,
  margeCondition,
};
