import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { switchUserActions, uiActions } from '../../actions';
import { resetSwitchRegion } from '../../actions/switchRegion';
import { getEmails, getRangedEmailReports } from '../../modules/emails/ajax';
import setHighchartsDefaults from '../../modules/highcharts.global';
import { getEmailCampaignAlgorithms } from '../../modules/transform/accountCampaignsTransform';
import transformTrends from '../../modules/transform/trends';
import { queryDate } from '../../system/date';
import { calculateRatio } from '../../system/ratio';
import { localeString, localeStringMoney } from '../../system/string';
import Currency from '../currency';

const mailGroups = {
  Retention: [
    'ABANDONED_CART',
    'LAST_VISIT_ALTERNATIVES',
    'NICE_TO_MEET_YOU',
    'LAST_VISIT_REMINDER',
    'REPLENISHMENT',
  ],
  Growth: ['NEW_COMERS', 'TOP_SELLERS', 'ORDER_FOLLOW_UP', 'BIRTHDAY'],
  Winback: ['WE_MISSED_YOU', 'CHURN', 'PRICE_DROP', 'BACK_IN_STOCK'],
  'FLY Mode': ['FLY'],
};

const groupEmailCampaigns = campaigns => {
  const groups = {
    Retention: [],
    Growth: [],
    Winback: [],
    'FLY Mode': [],
  };

  campaigns.forEach(campaign => {
    let isInGroup = false;
    Object.keys(mailGroups).forEach(group => {
      if (mailGroups[group].indexOf(campaign.instanceId) > -1) {
        isInGroup = true;
        groups[group].push(campaign);
      }
    });
    if (!isInGroup) {
      groups['FLY Mode'].push(campaign);
    }
  });

  return groups;
};

const EmailCampaignStats = ({ range, userToSwitch, account }) => {
  const [campaigns, setCampaigns] = useState({});

  const { isLoading, isLoaded } = uiActions;

  useEffect(() => {
    isLoading();
    getEmails(
      response => {
        if (response) {
          const allCampaigns = response
            .filter(campaign => campaign.status === 'ACTIVE')
            .map(campaign => ({
              instanceId: campaign.instanceId,
              campaign,
              report: {},
            }));
          const reportIds = response
            .filter(campaign => campaign.status === 'ACTIVE')
            .map(email => email.instanceId);

          const boundaries = {};
          boundaries.start = queryDate(range[0]);
          boundaries.end = queryDate(range[1]);

          getRangedEmailReports(
            reportIds,
            boundaries,
            reportsResponse => {
              isLoaded();
              if (reportsResponse) {
                setCampaigns(
                  groupEmailCampaigns(
                    allCampaigns.map(campaign => ({
                      ...campaign,
                      report: reportsResponse[campaign.instanceId] || {},
                    })),
                  ),
                );
                window.dispatchEvent(new Event('resize'));
              }
            },
            userToSwitch ? { switchUser: userToSwitch?.username } : {},
            account.apiKey,
          );
        }
      },
      userToSwitch ? { switchUser: userToSwitch?.username } : {},
      account?.apiKey,
      account?.dataCenterUrl,
    );
  }, []);

  const editCampaign = instanceId => {
    if (userToSwitch) {
      resetSwitchRegion();
      const processedUser = {
        ...userToSwitch,
        account,
      };
      switchUserActions.updateSwitchUser(processedUser);
      setHighchartsDefaults(processedUser);
      isLoading();
      setTimeout(() => {
        isLoaded();
        browserHistory.push(`/email/edit/${instanceId}`);
      }, 500);
    } else {
      browserHistory.push(`/email/edit/${instanceId}`);
    }
  };

  const hasKlaviyoConfiguration = () => {
    let hasMailConfig = false;
    const mailConfig = account?.mailConfiguration;
    if (mailConfig) {
      hasMailConfig = account?.mailConfiguration.provider === 'KLAVIYO';
    }
    return hasMailConfig;
  };

  return (
    <div className='account-details campaign-stats-table'>
      <table className='data-table'>
        <thead>
          <tr>
            <th>Group</th>
            <th>Campaigns</th>
            <th>Algorithms</th>
            <th>Sent</th>
            <th>Open</th>
            <th>Click</th>
            <th>Open Rate</th>
            {hasKlaviyoConfiguration() && (
              <>
                <th>Triggered To Flow</th>
                <th>Sync to Profile</th>
              </>
            )}
            <th>Basket</th>
            <th>Basket Rate</th>
            <th>Revenue</th>
            <th>Purchases</th>
            <th>Purchased Products</th>
            <th>Conversion Rate</th>
            <th>Contribution Ratio</th>
            <th>Diff Ratio</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(campaigns)
            .filter(group => campaigns[group].length)
            .map(group => {
              const title = group;
              const campaignsWithReport = campaigns[group];

              return campaignsWithReport.map((campaignWithReport, index) => {
                const { campaign } = campaignWithReport;
                const report = transformTrends(
                  campaignWithReport.report,
                  0,
                  0,
                  0,
                  0,
                  'E-Mail',
                  true,
                  hasKlaviyoConfiguration(),
                );
                const algorithms = getEmailCampaignAlgorithms(campaign);

                return (
                  <tr key={campaign.instanceId}>
                    {index === 0 && (
                      <td rowSpan={campaignsWithReport.length}>{title}</td>
                    )}
                    <td
                      className='domain'
                      onClick={() => editCampaign(campaign.instanceId)}
                      title={campaign.scenarioName}
                    >
                      {campaign.scenarioName}
                    </td>
                    <td>
                      <ul>
                        {algorithms.map(algorithm => (
                          <li>{algorithm}</li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      {index === 0
                        ? localeString(report.widget.stats.all.count)
                        : '-'}
                    </td>
                    <td>
                      {index === 0
                        ? localeString(report.widget.stats.view.count)
                        : '-'}
                    </td>
                    <td>{localeString(report.interaction.stats.all.count)}</td>
                    <td>
                      {index === 0
                        ? `${calculateRatio(
                            report.widget.stats.view.count,
                            report.widget.stats.all.count,
                          )}%`
                        : '-'}
                    </td>
                    {hasKlaviyoConfiguration() && (
                      <>
                        <td>
                          {localeString(report.widget.stats.klaviyo.flow)}
                        </td>
                        <td>
                          {localeString(report.widget.stats.klaviyo.profile)}
                        </td>
                      </>
                    )}
                    <td>{localeString(report.basket.stats.all.count)}</td>
                    <td>
                      {localeString(report.basket.stats.conversion.ratio)} %
                    </td>
                    <td>
                      <Currency
                        currency={localeStringMoney(
                          report.revenue.stats.all.count,
                        )}
                        accountCurrency={account.mainCurrency}
                      />
                    </td>
                    <td>
                      {localeString(report.revenue.stats.purchases.count)}
                    </td>
                    <td>{localeString(report.revenue.stats.items.count)}</td>
                    <td>
                      {localeString(report.revenue.stats.conversion.ratio)} %
                    </td>
                    <td className='cont-ratio'>
                      {localeString(report.revenue.stats.contribution.ratio)} %
                    </td>
                    <td className='diff-ratio'>
                      {report.revenue.stats.diff.ratio}
                    </td>
                  </tr>
                );
              });
            })}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = store => ({
  range: store.date.range,
});

export default connect(mapStateToProps)(EmailCampaignStats);
