import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import Icons from '../components/icons';

import { t } from '../system/ui';
import { setTitle } from '../system/document';
import {
  getAccount,
  getUser,
  isSwitchAccount,
  updatePushConfig,
  generateConfigFileUrl,
  getApiKey,
  getToken,
  isMultiAccountUser,
  getSwitchAccountUser,
} from '../modules/auth/user';

import { uiActions, userActions } from '../actions';

import { TextField } from '../components/fields';
import PushGlobalCapping from '../components/push/globalCapping';
import IconUpload from '../modules/campaign/fields/iconUpload';
import { getEndPoint } from '../system/api';

class Settings_Push extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domain: '',
      serviceWorkerPath: '',
      defaultIcon: '',
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onServiceWorkerPathInputChange = this.onServiceWorkerPathInputChange.bind(
      this,
    );
    this.onServiceWorkerPathValueChange = this.onServiceWorkerPathValueChange.bind(
      this,
    );
    this.update = this.update.bind(this);
    this.exportJourneyBuilder = this.exportJourneyBuilder.bind(this);
  }

  componentDidMount() {
    setTitle('Push Settings');
    uiActions.isValidated();
    uiActions.isLoading();
    if (isSwitchAccount()) {
      let value =
        isSwitchAccount().pushConfiguration &&
        isSwitchAccount().pushConfiguration.webConfiguration
          ? isSwitchAccount().pushConfiguration.webConfiguration.domain
          : '';
      this.onValueChange(
        value,
        isSwitchAccount().pushConfiguration &&
          isSwitchAccount().pushConfiguration.webConfiguration,
      );
      let serviceWorkerPathValue =
        isSwitchAccount().pushConfiguration &&
        isSwitchAccount().pushConfiguration.webConfiguration
          ? isSwitchAccount().pushConfiguration.webConfiguration
              .serviceWorkerPath
          : '';
      this.onServiceWorkerPathValueChange(
        serviceWorkerPathValue,
        isSwitchAccount().pushConfiguration &&
          isSwitchAccount().pushConfiguration.webConfiguration,
      );
      uiActions.isLoaded();
      this.refs.form.querySelector("input[name='serviceWorkerPath']").focus();
      this.refs.form.querySelector("input[name='serviceWorkerPath']").blur();
    } else {
      getUser(response => {
        let value =
          response.account.pushConfiguration &&
          response.account.pushConfiguration.webConfiguration
            ? response.account.pushConfiguration.webConfiguration.domain
            : '';
        this.onValueChange(value);
        let serviceWorkerPathValue =
          response.account.pushConfiguration &&
          response.account.pushConfiguration.webConfiguration
            ? response.account.pushConfiguration.webConfiguration
                .serviceWorkerPath
            : '';
        this.onServiceWorkerPathValueChange(serviceWorkerPathValue);
        let defaultIcon = response.account.pushConfiguration
          ? response.account.pushConfiguration.defaultIcon
          : '';
        this.onDefaultIconValueChange(defaultIcon);
        uiActions.isLoaded();
        this.refs.form.querySelector("input[name='serviceWorkerPath']").focus();
        this.refs.form.querySelector("input[name='serviceWorkerPath']").blur();
      });
    }
  }

  onValueChange(value) {
    this.setState({
      domain: value,
    });
  }

  onInputChange(e) {
    this.onValueChange(e.target.value);
  }

  onServiceWorkerPathValueChange(value) {
    this.setState({
      serviceWorkerPath: value,
    });
  }

  onServiceWorkerPathInputChange(e) {
    this.onServiceWorkerPathValueChange(e.target.value);
  }

  onDefaultIconValueChange(value) {
    this.state.defaultIcon = value;
  }

  update(e) {
    e.preventDefault();
    let cappingState = this.refs.capping.state;

    let errors = this.refs.form.querySelectorAll('.has-error');
    if (errors.length > 0) {
      return;
    }

    let account = getAccount();
    let config = {
      webConfiguration: { domain: '', serviceWorkerPath: '' },
      vapidConfiguration: {},
      vapidEnabled: false,
      defaultIcon: '',
    };
    config.webConfiguration.domain = this.state.domain;
    config.webConfiguration.serviceWorkerPath = this.state.serviceWorkerPath;
    config.defaultIcon = this.state.defaultIcon;

    if (
      account.pushConfiguration &&
      account.pushConfiguration.webConfiguration
    ) {
      config.webConfiguration.senderId =
        account.pushConfiguration.webConfiguration.senderId;
      config.webConfiguration.serverApiKey =
        account.pushConfiguration.webConfiguration.serverApiKey;
      config.webConfiguration.type =
        account.pushConfiguration.webConfiguration.type;
    }

    if (
      account.pushConfiguration &&
      account.pushConfiguration.vapidConfiguration
    ) {
      config.vapidConfiguration = account.pushConfiguration.vapidConfiguration;
    }

    if (account.pushConfiguration && account.pushConfiguration.vapidEnabled) {
      config.vapidEnabled = account.pushConfiguration.vapidEnabled;
    }

    config.capping = Object.assign({}, cappingState.capping);
    for (var key in config.capping) {
      if (config.capping && !config.capping[key]) {
        config.capping = null;
      }
    }

    if (!cappingState.hasCappingError) {
      updatePushConfig('web', config, response => {
        if (response.pushConfiguration) {
          if (isSwitchAccount()) {
            let newUserToSave = isSwitchAccount();
            newUserToSave.pushConfiguration = response.pushConfiguration;
            userActions.updateSwitchAccount(newUserToSave);
          }
          uiActions.isLoaded();
          let success = () => <p>{t('Push settings successfully updated.')}</p>;
          uiActions.showNotification({
            content: success,
          });
        } else {
          uiActions.isLoaded();
          this.setState({
            ui: 'isLoaded',
          });
        }
      });
    }
  }

  downloadFile(fileType) {
    let url = generateConfigFileUrl(fileType);
    window.open(url);
  }

  exportJourneyBuilder() {
    const apiKey = getApiKey();
    const globalApiKey = apiKey ? `?apiKey=${apiKey}` : '';
    const endpoint = getEndPoint('export/special/userIds') + globalApiKey;

    let exportUrl = `${endpoint}&authToken=${getToken()}`;
    if (isSwitchAccount()) {
      exportUrl += isMultiAccountUser()
        ? `&switchAccount=${getAccount().accountId.trim()}`
        : `&switchUser=${getSwitchAccountUser(true).username}`;
    }

    window.open(exportUrl);
  }

  render() {
    if (this.props.location.pathname !== '/settings/push') {
      return <div>{this.props.children}</div>;
    }

    return (
      <form ref='form' onSubmit={this.update}>
        <div className='settings-push'>
          <h2 className='page-title'>
            {"Let's Integrate Segmentify Push Service"}
          </h2>
          <div className='settings-push-form'>
            <nav id='page-nav' className='page-nav'>
              <Link
                to='/settings/push'
                className='nav-item page-nav-item'
                activeClassName='current'
              >
                {'Chromium & Firefox '}
              </Link>
              <Link
                to='/settings/push/safari'
                className='nav-item page-nav-item'
                activeClassName='current'
              >
                {'Safari'}
              </Link>
              <Link
                to='/settings/push/android'
                className='nav-item page-nav-item'
                activeClassName='current'
              >
                {'Android'}
              </Link>
              <Link
                to='/settings/push/ios'
                className='nav-item page-nav-item'
                activeClassName='current'
              >
                {'iOS'}
              </Link>
            </nav>
            <div className='one-whole'>
              <p className='item-label'>
                It is super easy start collecting permission from your website.
                All you need to do is adding the following files to under the
                root path (/) at your website.
              </p>
              <TextField
                name='serviceWorkerPath'
                label={'Service Worker Path'}
                className='item-stacked one-whole'
                value={this.state.serviceWorkerPath}
                onChange={this.onServiceWorkerPathInputChange}
                required={true}
                placeholder={'https://example.com/sw.js'}
                validation={{ secureURLWithPath: true }}
                type={'url'}
              />

              <TextField
                name='permissionDomain'
                label={'Permission Domain'}
                className='item-stacked one-whole sg-hidden'
                value={this.state.domain}
                onChange={this.onInputChange}
                required={false}
              />
              <div className='settings-push-files'>
                <ul>
                  <li onClick={this.downloadFile.bind(this, 'sw')}>
                    <Icons name='download' width='16' height='16' />
                    sw.js
                  </li>
                  <li onClick={this.downloadFile.bind(this, 'manifest')}>
                    <Icons name='download' width='14' height='14' />
                    manifest.json
                  </li>
                  <li onClick={this.exportJourneyBuilder}>
                    <Icons name='download' width='14' height='14' />
                    all_granted_userid_list.xlsx
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='settings-push-form'>
            <IconUpload
              account={getAccount()}
              options={{ label: 'Your Company Logo (Recommend 200x200px)' }}
              uploadType='icon'
              onDefaultIconValueChange={this.onDefaultIconValueChange.bind(
                this,
              )}
            />
          </div>

          <PushGlobalCapping ref='capping' />
          <li
            className='buttons'
            style={{ marginTop: '50px', listStyle: 'none' }}
          >
            <button className='tertiary-action'>Save</button>
          </li>
        </div>
      </form>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  validate: store.ui.validate,
  user: store.user.user,
});

export default connect(mapStatesToProps)(Settings_Push);
