import React from 'react';
import Icons from '../../../../../icons';
import classes from './DiffButton.module.scss';
import Tooltip from '../../../../../tooltip';

export const DiffButton = ({ showDiffEditor, setShowDiffEditor }) => {
  const tip = 'Compares original source with modified code';
  return (
    <div
      className={classes['diff-button-position']}
      onClick={() => setShowDiffEditor(!showDiffEditor)}
    >
      <Tooltip content={tip} alignment='top' newTooltip>
        <Icons name='gitMergeIcon' height='28' width='28' color='#9adafc' />
      </Tooltip>
    </div>
  );
};
