/* eslint-disable no-console */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import PropTypes, { shape } from 'prop-types';
import './Playbook.scss';

import { playbookTypes } from '../enums';

import RightArrowInCircleIcon from '../icons/RightArrowInCircleIcon';
import SearchBoxPlaybookCard from '../icons/SearchBoxPlaybookCard';
import BlackFridayPlaybookCard from '../icons/BlackFridayPlaybookCard';
import PushPlaybookCard from '../icons/PushPlaybookCard';
import RecommendationPlaybookCard from '../icons/RecommendationPlaybookCard';
import SearchandisingPlaybookCard from '../icons/SearchandisingPlaybookCard';

const PlayBookItem = ({ title, image, content, designType }) => (
  <div className='playbook-item'>
    <div className='playbook-title'>{title}</div>
    <div className={`playbook-content ${designType}`}>
      <div className='content-image'>
        <img src={image} alt='placeholder' />
      </div>
      <div className='content-text'>
        {content.title && <span>{content.title}</span>}
        <ul className={`content-list-container ${content.type}`}>
          {content.items.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

PlayBookItem.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  designType: PropTypes.string.isRequired,
  content: shape({
    title: PropTypes.string,
    type: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const Playbook = ({ playbookType, playbookItems }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  useEffect(() => {
    setSelectedItem(playbookItems[selectedItemIndex]);
  }, [selectedItemIndex]);

  const previousItem = () => {
    if (selectedItemIndex > 0) {
      setSelectedItemIndex(selectedItemIndex - 1);
    }
  };

  const nextItem = () => {
    if (selectedItemIndex < playbookItems.length - 1) {
      setSelectedItemIndex(selectedItemIndex + 1);
    }
  };

  useEffect(() => {
    const onKeyPressed = event => {
      if (event.key === 'ArrowRight') {
        if (selectedItemIndex < playbookItems.length - 1) {
          setSelectedItemIndex(selectedItemIndex + 1);
        }
      } else if (event.key === 'ArrowLeft') {
        if (selectedItemIndex > 0) {
          setSelectedItemIndex(selectedItemIndex - 1);
        }
      }
    };

    document.addEventListener('keydown', onKeyPressed, false);
    return () => {
      document.removeEventListener('keydown', onKeyPressed, false);
    };
  }, [selectedItemIndex]);

  const paginationStatus = () => {
    if (selectedItemIndex === 0) {
      return 'first';
    }
    if (selectedItemIndex === playbookItems.length - 1) {
      return 'last';
    }
    return 'middle';
  };

  const getPlaybookColor = () => {
    switch (playbookType) {
      case playbookTypes.SEARCH_BOX:
        return 'purple';
      case playbookTypes.BLACK_FRIDAY:
        return 'red';
      case playbookTypes.PUSH:
        return 'light-red';
      case playbookTypes.RECOMMENDATION:
        return 'light-blue';
      case playbookTypes.SEARCHANDISING:
        return 'navy-blue';
      default:
        return null;
    }
  };

  const getPlaybookCardIcon = () => {
    switch (playbookType) {
      case playbookTypes.SEARCH_BOX:
        return <SearchBoxPlaybookCard />;
      case playbookTypes.BLACK_FRIDAY:
        return <BlackFridayPlaybookCard />;
      case playbookTypes.PUSH:
        return <PushPlaybookCard />;
      case playbookTypes.RECOMMENDATION:
        return <RecommendationPlaybookCard />;
      case playbookTypes.SEARCHANDISING:
        return <SearchandisingPlaybookCard />;
      default:
        return null;
    }
  };

  return (
    <div className={`playbook-container ${getPlaybookColor()}`}>
      {selectedItem && <PlayBookItem {...selectedItem} />}
      <div className='playbook-page-info'>
        {getPlaybookCardIcon()}
        <span className='playbook-page-info-text'>{selectedItemIndex + 1}</span>
        <span className='playbook-page-info-upper-text'>
          of {playbookItems.length}
        </span>
      </div>
      <div className='playbook-page-navigation'>
        <div
          className={`playbook-page-arrow left ${paginationStatus() ===
            'first' && 'disabled'}`}
          onClick={() => previousItem()}
        >
          <RightArrowInCircleIcon />
        </div>
        <div
          className={`playbook-page-arrow right ${paginationStatus() ===
            'last' && 'disabled'}`}
          onClick={() => nextItem()}
        >
          <RightArrowInCircleIcon />
        </div>
      </div>
    </div>
  );
};

Playbook.propTypes = {
  playbookType: PropTypes.string.isRequired,
  playbookItems: PropTypes.arrayOf(
    shape({
      title: PropTypes.string,
      image: PropTypes.string,
      useCases: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
};

export default Playbook;
