/**
 * @author Bilal Cinarli
 */

import React from 'react';
import { connect } from 'react-redux';

import { t } from '../../system/ui';

import Icon from '../icon';
import Icons from '../icons';
import Navlink from '../navlink';
import SidebarSecondaryReports from './sidebar.secondary.reports';
import {
  isSuperUser,
  hasFeed,
  hasBetting,
  hasModule,
  isGlobalAccount,
  isLimitedUser,
} from '../../modules/auth/user';
import { FACETED_SEARCH_NAME } from '../../constants/faceted-search';
import Tooltip from '../tooltip';

class SidebarSecondary extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const isExtStyle =
      window.location.pathname.indexOf('ext/push') >= 0 ? { left: '0px' } : {};
    const menu =
      window.location.pathname.indexOf('ext/push') >= 0
        ? this.props.submenus
        : this.props.submenu[this.props.parent.path.replace('/', '')];

    if (this.props.parent.path === '/reports') {
      return <SidebarSecondaryReports {...this.props} />;
    }
    return (
      <aside
        id='secondary-sidebar'
        className='navigation-sidebar'
        style={isExtStyle}
      >
        {(() => {
          if (typeof menu !== 'undefined') {
            return (
              <nav id='sub-nav' className='sub-nav' ref='subNav'>
                <h3 className='sub-nav-title'>{t(menu.name)}</h3>

                {Object.keys(menu.children).map((item, index) => {
                  const _item = menu.children[item];
                  const isVisible = _item.visible !== false;
                  if (_item.header) {
                    if (isVisible) {
                      return (
                        <p key={index} className='nav-item-header'>
                          {t(_item.name)}
                        </p>
                      );
                    }
                    return '';
                  }

                  if (_item.name === 'Email Marketing') {
                    if (!isSuperUser() && isLimitedUser()) {
                      return '';
                    }
                    if (!hasModule('email')) {
                      return null;
                    }
                  }

                  if (_item.name === FACETED_SEARCH_NAME) {
                    if (!hasModule('faceted')) {
                      return null;
                    }
                  }

                  if (_item.name === 'Global Configuration') {
                    if (!hasModule('search')) {
                      return null;
                    }
                  }

                  if (_item.name === 'Web Push') {
                    if (!hasModule('push')) {
                      return null;
                    }
                  }

                  if (_item.name === 'Recommendation Settings') {
                    if (!hasModule('recommendation')) {
                      return null;
                    }
                  }

                  if (_item.name === 'Feed') {
                    if (!hasFeed()) {
                      return null;
                    }
                  }

                  if (_item.name === 'Search & Discovery') {
                    if (!hasModule('search')) return null;
                  }

                  if (_item.name === 'Betting') {
                    if (!hasBetting()) return null;
                  }

                  if (
                    _item.name === 'Product Search' ||
                    _item.name === 'Relation Lookup' ||
                    _item.name === 'User Search'
                  ) {
                    if (!isSuperUser()) return null;
                  }

                  if (_item.name === 'IYS Settings') {
                    if (isGlobalAccount()) return null;
                  }

                  let icon = <Icon name={_item.icon} />;

                  if (_item.svg) {
                    icon = <Icons name={_item.icon} width='24' height='24' />;
                  }
                  return (
                    isVisible && (
                      <li key={_item.name} className='relative-item list-none'>
                        <Navlink
                          key={index}
                          to={_item.slug}
                          className={`${'nav-item sub-nav-item header-symbol' +
                            ' '}${_item.cssClass} ${
                            _item.disabled ? 'disabled-nav' : null
                          }`}
                        >
                          {_item.hasOwnProperty('new_module') &&
                            _item.new_module && <Icons name='newBadge' />}
                          {_item.hasOwnProperty('icon') ? (
                            <span>{icon}</span>
                          ) : (
                            ''
                          )}
                          {t(`${_item.name} ${_item.name_alt}`)}
                        </Navlink>
                        {_item.disabled && (
                          <span className='absolute-icon'>
                            <Tooltip
                              content={t(
                                'To unlock all potential of the Recommendation module, please contact your Account Manager or Sales team.',
                              )}
                              alignment='left'
                              placement='left'
                              isSidebar
                            >
                              <Icons
                                width={30}
                                height={30}
                                name='lock'
                                color='#fff'
                              />
                            </Tooltip>
                          </span>
                        )}
                      </li>
                    )
                  );
                })}
              </nav>
            );
          }
        })()}
      </aside>
    );
  }
}

const MapStatesToProps = store => ({
  submenu: store.ui.submenu,
});

export default connect(MapStatesToProps)(SidebarSecondary);
