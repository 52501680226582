import React from "react";

class IconPopupOption extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 21 21"
        width={this.state.width}
        height={this.state.height}
      >
        <g>
          <path
            fill={this.state.color}
            className="st0"
            d="M12.3,3.8c1.6,0,3.2,0,4.9,0c1,0,1.7,0.6,1.7,1.6c0,2.2,0,4.4,0,6.6c0,1-0.6,1.7-1.7,1.7c-3.2,0-6.5,0-9.7,0
		c-1,0-1.7-0.7-1.7-1.7c0-2.2,0-4.4,0-6.6c0-1,0.6-1.7,1.7-1.7C9,3.8,10.6,3.8,12.3,3.8z M12.3,13.1c1.6,0,3.2,0,4.8,0
		c0.8,0,1.1-0.3,1.1-1.1c0-2.2,0-4.3,0-6.5c0-0.8-0.3-1.1-1.1-1.1c-3.2,0-6.4,0-9.6,0c-0.7,0-1.1,0.4-1.1,1.1c0,2.2,0,4.4,0,6.6
		c0,0.7,0.4,1.1,1.1,1.1C9,13.1,10.6,13.1,12.3,13.1z"
          />
          <path
            fill={this.state.color}
            className="st0"
            d="M8.8,16.6c1.6,0,3.2,0,4.8,0c0.2,0,0.4,0,0.5-0.1c0.4-0.1,0.5-0.3,0.5-0.7c0-0.2,0-0.4,0-0.6
		c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3c0,0.3,0,0.6,0,0.9c0,0.5-0.5,0.9-1.1,1c-0.2,0-0.4,0-0.6,0c-3.2,0-6.4,0-9.6,0
		c-1.1,0-1.8-0.6-1.8-1.7c0-2.2,0-4.4,0-6.5c0-0.3,0-0.5,0.1-0.8c0.2-0.6,0.6-0.9,1.2-0.9c0.2,0,0.4,0,0.6,0c0.1,0,0.3,0.2,0.3,0.3
		c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.4,0-0.7,0C3.2,7.9,3.1,8,3,8.2C2.9,8.4,2.8,8.7,2.8,8.9c0,2.2,0,4.4,0,6.6c0,0.7,0.4,1.1,1.1,1.1
		C5.5,16.6,7.2,16.6,8.8,16.6z"
          />
        </g>
      </svg>
    );
  }
}

export default IconPopupOption;
