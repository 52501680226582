/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconLabelExclude extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        className="svg-icon"
        height={this.state.height}
        width={this.state.width}
        viewBox="0 0 24 24"
      >
        <g>
          <path
            d="M22.707,12.293l-11-11C11.52,1.106,11.265,1,11,1H2C1.448,1,1,1.448,1,2v9c0,0.265,0.105,0.52,0.293,0.707&#xA;&#x9;L5,15.414V20c0,0.552,0.448,1,1,1s1-0.448,1-1V9C5.895,9,5,8.105,5,7c0-1.105,0.895-2,2-2c1.104,0,2,0.895,2,2h0v12.414l3.293,3.293&#xA;&#x9;C12.488,22.903,12.744,23,13,23s0.512-0.098,0.707-0.293l9-9C23.098,13.317,23.098,12.684,22.707,12.293z"
            fill={this.state.color}
          />
        </g>
      </svg>
    );
  }
}

export default IconLabelExclude;
