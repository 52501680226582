import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import classNames from 'classnames';

import { modalActions, uiActions } from '../actions';
import {
  isSwitchAccount,
  getSwitchAccountUser,
  getToken,
  isMultiAccountUser,
  getAccount,
  getApiKey,
  getRegion,
} from '../modules/auth/user';
import {
  getAllInsightReports,
  getInsightExport,
} from '../modules/insights/ajax';
import api, { getEndPoint } from '../system/api';
import { queryDate, printFormatDate } from '../system/date';
import { t } from '../system/ui';
import { TextField, Select } from './fields';

class Export extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      pages: 'current',
      delimiter: '59',
      fileType: 'csv',
      isLoading: false,
    };

    this.setName = this.setName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeDelimiter = this.onChangeDelimiter.bind(this);
    this.onChangeFileType = this.onChangeFileType.bind(this);
    this.exportPdf = this.exportPdf.bind(this);
    this.exportCampaignPdf = this.exportCampaignPdf.bind(this);
  }

  componentDidMount() {
    this.setName();
  }

  setName() {
    this.setState({
      name: this.props.name,
    });
    modalActions.calculateModalHeight();
  }

  onChangeName(e) {
    this.setState({
      name: e.currentTarget.value,
    });
  }

  onChangePage(selectedOption) {
    this.setState({
      pages: selectedOption.value,
    });
  }

  onChangeDelimiter(selectedOption) {
    this.setState({
      delimiter: selectedOption.value,
    });
  }

  requestFileDownload(response, request) {
    const type = request.getResponseHeader('Content-Type');
    const [, filename] = request
      .getResponseHeader('Content-Disposition')
      .split('filename=');
    const blob = new Blob([response], { type, encoding: 'UTF-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    link.remove();
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.props.campaignExport) {
      if (this.state.fileType === 'pdf') {
        this.exportCampaignPdf();
      } else {
        window.open(this.props.urlDownload);
        modalActions.closeModal();
      }
    } else if (this.props.pdfExport) {
      if (this.state.fileType === 'pdf') {
        this.exportPdf();
      } else if (this.props.exportAll) {
        // This code's portions is only for Trendify - Insights CSV
        const query = {};
        query.filters = {
          '1': this.props.exportAll,
          '2': this.props.exportAll,
          '3': this.props.exportAll,
          '4': this.props.exportAll,
          '5': this.props.exportAll,
          '6': this.props.exportAll,
          '7': this.props.exportAll,
          '8': this.props.exportAll,
          '9': this.props.exportAll,
          '10': this.props.exportAll,
          '11': this.props.exportAll,
          '12': this.props.exportAll,
          '13': this.props.exportAll,
          '14': this.props.exportAll,
          '15': this.props.exportAll,
        };
        query.delimiter = this.state.delimiter;
        uiActions.isLoading();
        this.setState(
          {
            isLoading: true,
          },
          () => {
            getAllInsightReports(
              api.getInsightEndPoint('export'),
              query,
              res => {
                this.requestFileDownload(res.response, res.request);

                // window.open(response.message);
                this.setState(
                  {
                    isLoading: false,
                  },
                  () => {
                    modalActions.closeModal();
                    uiActions.isLoaded();
                  },
                );
              },
            );
          },
        );
      } else {
        // This code's portions is only for Dashboard
        const globalApiKey = getApiKey() !== undefined ? getApiKey() : '';
        let exportUrl = `${getEndPoint('export/dashboard')}?end=${queryDate(
          this.props.range[1],
        )}&start=${queryDate(
          this.props.range[0],
        )}&interval=total&authToken=${getToken()}&delimiter=${
          this.state.delimiter
        }&exportFile=${this.state.name}&apiKey=${globalApiKey}`;
        if (isSwitchAccount() && !isMultiAccountUser()) {
          exportUrl += `&switchUser=${getSwitchAccountUser(true).username}`;
        }
        window.open(exportUrl);
        modalActions.closeModal();
      }
    } else {
      const { query } = this.props;
      if (!this.props.insightsId) {
        const exportEndpoint = getEndPoint(this.props.endpoint);

        if (this.state.pages === 'all') {
          delete query.page;
        }

        const queryString = Object.keys(query)
          .map(
            key =>
              `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`,
          )
          .join('&');

        let file = `${exportEndpoint}?${queryString}&exportFile=${
          this.state.name
        }&delimiter=${
          this.state.delimiter
        }&authToken=${getToken()}&apiKey=${getApiKey()}&switchRegion=${getRegion()}`;

        if (isSwitchAccount() && !isMultiAccountUser()) {
          file += `&switchUser=${getSwitchAccountUser(true).username}`;
        }
        if (isMultiAccountUser() && isSwitchAccount()) {
          file += `&switchAccount=${getAccount().accountId.trim()}`;
        }
        window.open(file);
        modalActions.closeModal();
      } else {
        query.title = this.state.name;
        query.delimiter = this.state.delimiter;
        const exportEndpoint = this.props.isMsp ? this.props.endpoint : null;
        const exportCustomOption = this.props.isMsp
          ? { switchUser: this.props.switchUser }
          : null;
        uiActions.isLoading();
        this.setState(
          {
            isLoading: true,
          },
          () => {
            getInsightExport(
              exportEndpoint,
              this.props.insightsId,
              query,
              res => {
                this.requestFileDownload(res.response, res.request);

                this.setState(
                  {
                    isLoading: false,
                  },
                  () => {
                    uiActions.isLoaded();
                    modalActions.closeModal();
                  },
                );
              },
              exportCustomOption,
            );
          },
        );
      }
    }
  }

  onChangeFileType(selectedOption) {
    this.setState({
      fileType: selectedOption.value,
    });
  }

  async exportPdf() {
    uiActions.isLoading();
    modalActions.closeModal();
    const elements = document.querySelectorAll('.export-content-wrapper > *');

    const doc = new jsPDF('l', 'mm');

    // Add Date Text
    doc.setFontSize(12);
    doc.setFillColor(218, 217, 228);
    doc.rect(10, 10, 70, 10, 'F');
    doc.setTextColor(115, 119, 130);
    doc.text(
      `${printFormatDate(this.props.range[0])} - ${printFormatDate(
        this.props.range[1],
      )}`,
      21,
      17,
    );

    const promises = [...elements].map(element => {
      element.classList += ' export-content-wrapper--print';
      const divHeight = element.clientHeight;
      const divWidth = element.clientWidth;
      // const ratio = divHeight / divWidth;

      return html2canvas(element, {
        height: divHeight,
        width: divWidth,
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/jpeg');

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const widthRatio = pageWidth / canvas.width;
        const heightRatio = pageHeight / canvas.height;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;

        const marginX = (pageWidth - canvasWidth * 0.9) / 2;
        const marginY = (pageHeight - canvasHeight * 0.9) / 2;

        doc.addImage(
          imgData,
          'JPEG',
          marginX,
          marginY,
          canvasWidth * 0.9,
          canvasHeight * 0.9,
        );
        element.classList.remove('export-content-wrapper--print');
        doc.addPage();
      });
    });
    Promise.all(promises).then(() => {
      const pageCount = doc.internal.getNumberOfPages();
      doc.deletePage(pageCount);
      doc.save(`${this.state.name}.pdf`);
      uiActions.isLoaded();
    });
  }

  exportCampaignPdf() {
    const element = this.props.domElement;
    element.classList = element.classList += ' page-content-block--print';
    const divHeight = element.clientHeight;
    const divWidth = element.clientWidth;
    const ratio = divHeight / divWidth;
    const html2obj = html2canvas(element, {
      height: divHeight,
      width: divWidth,
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/jpeg');
      const doc = new jsPDF('l', 'mm');

      // Add Date Text
      doc.setFontSize(12);
      doc.setFillColor(218, 217, 228);
      doc.rect(10, 10, 70, 10, 'F');
      doc.setTextColor(115, 119, 130);
      doc.text(
        `${printFormatDate(this.props.range[0])} - ${printFormatDate(
          this.props.range[1],
        )}`,
        21,
        17,
      );

      // Add Image
      const width = doc.internal.pageSize.getWidth();
      let height = doc.internal.pageSize.getHeight();
      height = ratio * width;
      doc.addImage(imgData, 'JPEG', 10, 25, width - 20, height - 10);
      doc.save(`${this.state.name}.pdf`);
      element.classList.remove('page-content-block--print');
      modalActions.closeModal();
    });
  }

  render() {
    const pages = [
      { label: t('Current Page'), value: 'current' },
      { label: t('All Pages'), value: 'all' },
    ];
    const delimiter = [
      { label: t('Comma (,)'), value: '44' },
      { label: t('Semicolon (;)'), value: '59' },
      { label: t('Tab (\\t)'), value: '9' },
    ];
    const fileTypes = [
      { label: t('CSV'), value: 'csv' },
      { label: t('PDF'), value: 'pdf' },
    ];
    let showPageNumbers = true;
    let delimiterClassName = 'item-stacked six-columns';
    if (this.props.insightsId) {
      delimiterClassName = 'item-stacked twelve-columns';
    }
    if (this.props.exportAll || this.props.pdfExport) {
      showPageNumbers = false;
      delimiterClassName = 'item-stacked twelve-columns';
    }
    return (
      <form onSubmit={this.handleSubmit}>
        <fieldset>
          <ol className='form-elements'>
            <li>
              <TextField
                name='title'
                value={this.state.name}
                label={t('Export File Name')}
                className='item-stacked one-whole'
                onChange={this.onChangeName}
              />
            </li>
            <li className='row pull-push'>
              {showPageNumbers && (
                <Select
                  name='pages'
                  label={t('Page Selection')}
                  className={
                    this.props.insightsId
                      ? 'item-stacked six-columns is-hidden'
                      : 'item-stacked six-columns'
                  }
                  searchable={false}
                  clearable={false}
                  options={pages}
                  value={this.state.pages}
                  onChange={this.onChangePage}
                />
              )}
              {this.props.pdfExport && (
                <Select
                  name='fileType'
                  label={t('File Type')}
                  className={delimiterClassName}
                  searchable={false}
                  clearable={false}
                  options={fileTypes}
                  value={this.state.fileType}
                  onChange={this.onChangeFileType}
                />
              )}
              {this.state.fileType !== 'pdf' && !this.props.campaignExport && (
                <Select
                  name='delimiter'
                  label={t('CSV Delimiter')}
                  className={delimiterClassName}
                  searchable={false}
                  clearable={false}
                  options={delimiter}
                  value={this.state.delimiter}
                  onChange={this.onChangeDelimiter}
                />
              )}
            </li>
            <li className='buttons'>
              <button
                className={classNames('tertiary-action', {
                  disabled: this.state.isLoading,
                })}
              >
                {t('Export')}
              </button>
            </li>
          </ol>
        </fieldset>
      </form>
    );
  }
}

export default Export;
