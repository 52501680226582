import React from "react";

class IconMetrics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
      >
        <g>
          <path
            className="st0"
            d="M2.5,11.4h-2c-0.3,0-0.5,0.2-0.5,0.5v3.5c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5v-3.5
		C3,11.6,2.8,11.4,2.5,11.4z"
          />
          <path
            className="st0"
            d="M6.8,8.4h-2c-0.3,0-0.5,0.2-0.5,0.5v6.5c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5V8.9
		C7.3,8.6,7.1,8.4,6.8,8.4z"
          />
          <path
            className="st0"
            d="M11.1,9.9h-2c-0.3,0-0.5,0.2-0.5,0.5v5c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5v-5
		C11.7,10.1,11.4,9.9,11.1,9.9z"
          />
          <path
            className="st0"
            d="M15.5,6.9h-2c-0.3,0-0.5,0.2-0.5,0.5v8c0,0.3,0.2,0.5,0.5,0.5h2c0.3,0,0.5-0.2,0.5-0.5v-8
		C16,7.1,15.8,6.9,15.5,6.9z"
          />
          <path
            className="st0"
            d="M13.5,5c0.3,0.2,0.6,0.4,1,0.4c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5C13.6,2.3,13,3,13,3.8c0,0.2,0,0.4,0.1,0.5l-1.9,1.3c-0.3-0.2-0.6-0.4-1-0.4c-0.5,0-1,0.3-1.3,0.7L7.4,5.5c0,0,0-0.1,0-0.1c0-0.8-0.7-1.5-1.5-1.5S4.3,4.5,4.3,5.3c0,0.2,0,0.4,0.1,0.5L2.6,7.2C2.3,7,1.9,6.9,1.6,6.9C0.7,6.9,0,7.5,0,8.4c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5C3.1,8.2,3,8,3,7.8l1.9-1.3c0.3,0.2,0.6,0.4,1,0.4c0.5,0,1-0.3,1.3-0.7l1.5,0.5c0,0,0,0.1,0,0.1c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5c0-0.2,0-0.4-0.1-0.5L13.5,5z M14.5,3c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8C13.7,3.4,14.1,3,14.5,3z M1.6,9.2c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8C2,7.6,2.3,8,2.3,8.4C2.3,8.8,2,9.2,1.6,9.2zM5.9,6.1c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8C6.6,5.8,6.3,6.1,5.9,6.1z M10.2,7.6
		c-0.4,0-0.8-0.3-0.8-0.8c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8C10.9,7.3,10.6,7.6,10.2,7.6z"
          />
        </g>
      </svg>
    );
  }
}

export default IconMetrics;
