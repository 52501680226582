import React from 'react';
import { connect } from 'react-redux';

import { Select } from '../fields';
import { getUser, isSwitchAccount } from '../../modules/auth/user';
import { globalCappingMailGroups } from '../../modules/capping/settings';

class PushGlobalCapping extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      capping: {
        capCount: "",
        frequency: "",
        types: []
      },
      hasCappingError: false
    };

    this.onCappingParamChange = this.onCappingParamChange.bind(this);
    this.onFrequencyChange = this.onFrequencyChange.bind(this);
    this.onTypesChange = this.onTypesChange.bind(this);
    this.validateCapping = this.validateCapping.bind(this);
    this.updateCapping = this.updateCapping.bind(this);
  }

  componentDidMount() {
    this.updateCapping();
  }

  componentWillReceiveProps() {
    this.updateCapping();
  }

  updateCapping() {
    if (isSwitchAccount()) {
      let capping =
        isSwitchAccount().pushConfiguration &&
        isSwitchAccount().pushConfiguration.capping;
      this.setState({
        capping: capping || this.state.capping
      });
    } else {
      getUser(response => {
        let capping =
          response.account.pushConfiguration &&
          response.account.pushConfiguration.capping;
        this.setState({
          capping: capping || this.state.capping
        });
      });
    }
  }

  onCappingParamChange(param, e) {
    let newValue = e.target.value;
    if (parseInt(newValue) < 1) {
      newValue = 1;
    }
    this.setState(
      { capping: Object.assign({}, this.state.capping, { [param]: newValue }) },
      () => {
        this.validateCapping();
      }
    );
  }

  onFrequencyChange(selectedOption) {
    let frequency = selectedOption ? selectedOption.value : "";
    this.setState(
      { capping: Object.assign({}, this.state.capping, { frequency }) },
      () => {
        this.validateCapping();
      }
    );
  }

  onTypesChange(selectedOption) {
    let types = [];
    if (selectedOption.length) {
      types = selectedOption.map(option => {
        return option.value;
      });
    } else {
      types = [];
    }
    this.setState(
      { capping: Object.assign({}, this.state.capping, { types }) },
      () => {
        this.validateCapping();
      }
    );
  }

  validateCapping() {
    let emptyValueCount = 0;
    Object.keys(this.state.capping).forEach(cappingKey => {
      if (cappingKey !== "types") {
        if (!this.state.capping[cappingKey]) {
          emptyValueCount++;
        }
      } else {
        if (this.state.capping[cappingKey].length === 0) {
          emptyValueCount++;
        }
      }
    });
    this.setState({
      hasCappingError: emptyValueCount < 3 && emptyValueCount > 0
    });
  }

  render() {
    const frequency = [
      { value: "DAILY", label: "Daily" },
      { value: "WEEKLY", label: "Weekly" },
      { value: "MONTHLY", label: "Monthly" }
    ];
    return (
      <div className="email-settings-capping push-global-capping">
        <p className="subtitle">Global Capping</p>
        {this.state.hasCappingError && (
          <span
            className="item-error has-error"
            style={{ display: "block", marginTop: "10px" }}
          >
            {"* "}You should fill all fields
          </span>
        )}
        <p>
          Frequency Cap is
          <input
            type="number"
            name="capCount"
            min={1}
            value={this.state.capping.capCount}
            className="capping-capCount"
            onChange={this.onCappingParamChange.bind(null, "capCount")}
          />
          per user and push notification send
          <Select
            name="frequency"
            className="item-stacked eleven-columns capping-frequency"
            value={this.state.capping.frequency}
            options={frequency}
            onChange={this.onFrequencyChange}
            clearable={true}
          />
          for
          <Select
            name="types"
            className="item-stacked eleven-columns capping-types"
            value={this.state.capping.types}
            options={globalCappingMailGroups}
            onChange={this.onTypesChange}
            multi
            clearable
          />
          campaigns.
        </p>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser
});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
  PushGlobalCapping
);
