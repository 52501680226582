import React from 'react';
import { connect } from 'react-redux';

import { isSuperUser } from '../../modules/auth/user';

import { t } from '../../system/ui';
import { modalActions, uiActions } from '../../actions';

import Icon from '../icon';
import Icons from '../../components/icons';
import CampaignHistoryModal from '../../modules/campaigns/historymodal';
import { moveCampaignToArchive } from '../../modules/push/ajax';

class PushWidgetActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [
        {
          name: 'Edit',
          icon: 'gear',
          action: 'edit',
          hideKey: 'withReplicateAction',
        },
        {
          name: 'Preview Campaign',
          icon: 'view',
          action: 'preview',
          showKey: 'withReplicateAction',
        },
        {
          name: 'Replicate',
          icon: 'gear',
          action: 'replicate',
          showKey: 'withReplicateAction',
        },
        { name: 'Report', icon: 'pie-chart', action: 'report' },
        { name: 'Passive', icon: 'archive', action: 'addToArchive' },
        {
          name: 'History',
          icon: 'history',
          iconSvg: true,
          action: 'showCampaignHistory',
        },
      ],
      dropUp: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.edit = this.edit.bind(this);
    this.report = this.report.bind(this);
    this.addToArchive = this.addToArchive.bind(this);
    this.addToArchiveConfirmed = this.addToArchiveConfirmed.bind(this);
    this.showCampaignHistory = this.showCampaignHistory.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleClick(action) {
    this[action]();
  }

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    let partOfDocHeight = (document.documentElement.offsetHeight * 70) / 100;
    let listTop = this.refs.actionMenu.getBoundingClientRect().top;
    this.setState({
      dropUp: listTop > partOfDocHeight,
    });
  }

  edit() {
    let ext = window.location.pathname.indexOf('ext/push') >= 0 ? '/ext' : '';
    let link = ext + '/push/edit/' + this.props.campaign.type;

    if (this.props.campaign.type !== 'ABANDONED_CART') {
      link = link + '/' + this.props.campaign.instanceId;
    }

    uiActions.redirect(link);
  }

  preview() {
    let ext = window.location.pathname.indexOf('ext/push') >= 0 ? '/ext' : '';
    uiActions.redirect(
      ext +
        '/push/preview/' +
        this.props.campaign.type +
        '/' +
        this.props.campaign.instanceId,
    );
  }

  report() {
    let ext = window.location.pathname.indexOf('ext/push') >= 0 ? '/ext' : '';
    if (this.props.campaign.instanceId === 'ABANDONED_CART') {
      uiActions.redirect(
        ext + '/reports/push-multiple/' + this.props.campaign.instanceId,
      );
    } else {
      uiActions.redirect(
        ext + '/reports/push-single/' + this.props.campaign.instanceId,
      );
    }
  }

  addToArchive() {
    uiActions.confirmationDialog({
      title: 'Are you sure to make the campaign passive?',
      content: '',
      onConfirm: this.addToArchiveConfirmed,
    });
  }

  addToArchiveConfirmed() {
    moveCampaignToArchive(this.props.campaign.instanceId).then(response => {
      if (response) {
        if (this.props.onChangeStatus) {
          this.props.onChangeStatus(
            this.props.campaign.instanceId,
            'PASSIVE',
            this.props.campaign.status === 'TEST' ? 'TEST' : 'ACTIVE',
          );
        }
      }
    });
  }

  showCampaignHistory() {
    let campaignId = this.props.campaign.instanceId,
      campaignName = this.props.campaign.name,
      campaignHistoryModal = () => (
        <CampaignHistoryModal campaignId={campaignId} isPush={true} />
      );

    modalActions.openModal({
      title: 'Campaign History (' + campaignName + ')',
      content: campaignHistoryModal,
    });
  }

  replicate() {
    let ext = window.location.pathname.indexOf('ext/push') >= 0 ? '/ext' : '';
    uiActions.redirect(
      ext +
        '/push/replicate/' +
        this.props.campaign.type +
        '/' +
        this.props.campaign.instanceId,
    );
  }

  render() {
    let actions = this.state.actions;
    let listClass = 'widget-nav-popup action-nav-popup';
    if (this.state.dropUp) {
      actions = this.state.actions.slice().reverse();
      listClass = 'widget-nav-popup action-nav-popup drop-up';
    }
    return (
      <nav
        className='widget-nav action-nav'
        aria-haspopup='true'
        ref='actionMenu'
      >
        <a className='secondary-action'>{t('Actions')}</a>

        <ul className={listClass}>
          {actions.map((action, index) => {
            if (!isSuperUser() && action.superUserOnly) {
              return;
            }
            if (action.hideKey && this.props[action.hideKey]) {
              return;
            }
            if (action.showKey && !this.props[action.showKey]) {
              return;
            }
            if (
              action.action === 'addToArchive' &&
              this.props.campaign.status === 'PASSIVE'
            ) {
              return;
            }
            return (
              <li key={index}>
                <a
                  className='widget-nav-popup-item action-nav-popup-item'
                  onClick={this.handleClick.bind(null, action.action)}
                >
                  {action.iconSvg ? (
                    <Icons name={action.icon} color='#fff' />
                  ) : (
                    <Icon name={action.icon} />
                  )}
                  {t(action.name)}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

const mapStatesToProps = store => ({
  status: store.campaigns.status,
});

export default connect(mapStatesToProps)(PushWidgetActions);
