/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";

class IconTarget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={this.state.width}
        height={this.state.height}
      >
        <g>
          <path
            className="svg-path"
            fill={this.state.color}
            d="M256,11C121.2,11,11,121.2,11,256s110.2,245,245,245s245-110.2,245-245S390.8,11,256,11z M286.6,436.7v-58.2h-61.2v58.2c-76.6-12.2-137.8-73.5-150.1-150.1h58.2v-61.2H75.3c12.2-76.6,73.5-137.8,150.1-150.1v58.2h61.2V75.3c76.6,12.2,137.8,73.5,150.1,150.1h-58.2v61.2h58.2C424.4,363.2,363.2,424.4,286.6,436.7z"
          />
          <circle
            className="svg-path"
            fill={this.state.color}
            cx="256"
            cy="256"
            r="61.2"
          />
        </g>
      </svg>
    );
  }
}

export default IconTarget;
