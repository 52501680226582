"use strict";

import React from "react";
import { t } from "../../../system/ui";
import { setTitle } from "../../../system/document";
import {isSwitchAccount, getUser, isSuperUser} from "../../../modules/auth/user";
import {uiActions} from "../../../actions";
import RecoSettings from "./reco-settings";
import PersonalisationSettings from "./personalisation-settings";

class SettingsRecommendation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      account: {}
    };
  }


  componentDidMount() {
    setTitle(t("Recommendation Settings"));
    uiActions.isLoading();
    let account = isSwitchAccount();
    if (account) {
      this.setState({account: account}, () => {
        uiActions.isLoaded();
      });

    } else {
      getUser(response => {
        this.setState({account: account}, ()=> {
          uiActions.isLoaded();
        });
      });
    }
  }

  render() {
    const personalisationConfigVisible = isSuperUser();
    return (
      <div className={"recommendation-settings"}>
        <h2 className="page-title">{t("Recommendation")}</h2>
        <div className="page-forms" ref="forms">
            <RecoSettings account={this.state.account} {...this.props}/>
            {personalisationConfigVisible &&
              <PersonalisationSettings account={this.state.account} {...this.props}/>
            }
        </div>
      </div>
    );
  }
}

export default SettingsRecommendation;
