import {
  criteriaTypes,
  operatorColumns,
} from '../../constants/datamaps/dynamic-segmentation';

export const emptyCriteria = {
  criteriaType: '',
  statements: {},
};
export const emptyCriteriaGroup = {
  operator: 'and',
  criterias: [emptyCriteria],
};

export const emptyCriteriaFilter = {
  operator: 'and',
  criteriaGroups: [emptyCriteriaGroup],
};

export const getDefaultStatements = (criteriaType, criteriaOptions) => {
  const selectedCriteria = criteriaOptions.find(
    option => option.value === criteriaType,
  );

  const statementObject = {};
  // eslint-disable-next-line no-unused-expressions
  selectedCriteria?.criteriaFilters?.forEach(filterType => {
    statementObject[filterType] = [];
  });

  return statementObject;
};

export const getCriteriaSelectOptions = criteriaTypeItems => {
  const returnCriteriaOptions = [];

  Object.keys(criteriaTypeItems).forEach(key => {
    const thisCriteriaType = criteriaTypeItems[key];

    if (thisCriteriaType?.type === 'title') {
      const subCriteriaTypes = Object.keys(thisCriteriaType?.criteriaList).map(
        subKey => {
          const subCriteriaType = thisCriteriaType?.criteriaList[subKey];
          return {
            value: subCriteriaType?.id,
            label: subCriteriaType?.name,
            disabled: !subCriteriaType?.isActive,
            criteriaFilters: subCriteriaType?.criteriaFilters,
            parent: key,
          };
        },
      );

      returnCriteriaOptions.push(...subCriteriaTypes);
    } else {
      returnCriteriaOptions.push({
        value: thisCriteriaType?.id,
        label: thisCriteriaType?.name,
        disabled: !thisCriteriaType?.isActive,
        criteriaFilters: thisCriteriaType?.criteriaFilters,
        parent: '',
      });
    }
  });

  return returnCriteriaOptions;
};

export const convertDataForFrontend = async data => {
  const dataToBeConvert = { ...data };

  const oneDimensionCriteriaTypes = getCriteriaSelectOptions(criteriaTypes);

  const findRelatedCriteriaFilters = criteriaType => {
    return oneDimensionCriteriaTypes.find(
      oneDimensionCriteriaType =>
        oneDimensionCriteriaType.value === criteriaType,
    )?.criteriaFilters;
  };

  const criteriaStatementsControl = (criteriaType, statements) => {
    const criteriaFilterParents = findRelatedCriteriaFilters(criteriaType);
    const newCriteriaObject = {
      criteriaType,
      statements: {},
    };

    criteriaFilterParents.forEach(criteriaFilterParent => {
      newCriteriaObject.statements[criteriaFilterParent] = [];
    });

    statements.forEach(statement => {
      const { column, dataType } = statement;
      Object.keys(newCriteriaObject.statements).forEach(key => {
        if (criteriaType === 'attributeOperator') {
          if (operatorColumns[key] === dataType) {
            newCriteriaObject.statements[key].push(statement);
          }
        }
        if (operatorColumns[key] === column) {
          newCriteriaObject.statements[key].push(statement);
        }
      });
    });

    return newCriteriaObject;
  };

  const groupControl = groups => {
    if (!groups) {
      return emptyCriteriaFilter;
    }

    const tempGroups = groups;
    tempGroups.criteriaGroups = groups?.criteriaGroups?.map(group => {
      let tempCriterias = group?.criterias;
      tempCriterias = group?.criterias?.map(criteria => {
        return criteriaStatementsControl(
          criteria.criteriaType,
          criteria.statements,
        );
      });
      return { criterias: tempCriterias, operator: group.operator };
    });

    return tempGroups;
  };

  return { filter: groupControl(dataToBeConvert?.filter) };
};
