import classNames from 'classnames';
import React, { Component } from 'react';
import { notification, t } from '../../system/ui';
import { getTestUsers } from '../account/ajax';

import styles from './SendTestModal.module.scss';
import { modalActions } from '../../actions';
import Dropdown from '../../components/sfy-components/Dropdown/Dropdown';
import { sendTestEmail } from './ajax';
import { saveUsedEmails, getUsedEmails, removeUsedEmail } from './utils';

class SendTestModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testTypes: [
        {
          label: 'UI Test',
          value: 'UI',
        },
      ],
      testType: 'UI',
      mails: [],
      inputValue: '',
      selectedGroup: '',
      groupNames: [],
      groupData: [],
      previouslyUsedEmails: [],
      initialPreviouslyUsedEmails: [],
      removedEmails: [],
      hasError: true,
      inputFocused: false,
    };

    this.setTestType = this.setTestType.bind(this);
    this.sendTest = this.sendTest.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onRemoveMail = this.onRemoveMail.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
    this.convertArrayToLabelList = this.convertArrayToLabelList.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onFocusOut = this.onFocusOut.bind(this);
    this.addToSelected = this.addToSelected.bind(this);
    this.removeFromPreviouslyUsedEmail = this.removeFromPreviouslyUsedEmail.bind(
      this,
    );
  }

  componentDidMount() {
    getTestUsers().then(data => {
      const keys = data.map(item => item.name);
      const usedEmails = getUsedEmails();
      this.setState({
        groupData: data,
        groupNames: keys,
        previouslyUsedEmails: [...usedEmails],
        initialPreviouslyUsedEmails: [...usedEmails],
      });
    });
  }

  onInputChange(e) {
    const val = e.target.value;
    const isProperEmail = modalActions.isProperEmail(val);
    if (isProperEmail) {
      this.setState({
        inputValue: val,
        hasError: false,
      });
    } else {
      this.setState({
        inputValue: val,
        hasError: true,
      });
    }
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { inputValue } = this.state;
    if (this.state.mails.indexOf(inputValue) < 0 && !this.state.hasError) {
      const newPrevUsedEmails = this.state.initialPreviouslyUsedEmails.filter(
        item =>
          item !== inputValue &&
          !this.state.mails.includes(item) &&
          !this.state.removedEmails.includes(item),
      );
      this.setState({
        mails: this.state.mails?.concat([inputValue]),
        inputValue: '',
        hasError: true,
        previouslyUsedEmails: [...newPrevUsedEmails],
      });
    }
  }

  onRemoveMail(mailToRemove, e) {
    e.preventDefault();
    const newPrevUsedEmails = this.state.initialPreviouslyUsedEmails.filter(
      item =>
        (!this.state.mails.includes(item) || item === mailToRemove) &&
        !this.state.removedEmails.includes(item),
    );
    this.setState({
      mails: this.state.mails?.filter(mail => mail !== mailToRemove),
      previouslyUsedEmails: [...newPrevUsedEmails],
    });
  }

  onDropdownChange(e) {
    const hasKey = (element, obj) => {
      return element === obj.name;
    };
    const itemIndex = this.state.groupData.findIndex(item => hasKey(e, item));
    this.setState({
      selectedGroup: e,
      mails: this.state.groupData[itemIndex]?.emailList,
    });
  }

  onInputFocus() {
    const newPrevUsedEmails = this.state.initialPreviouslyUsedEmails.filter(
      item =>
        !this.state.mails.includes(item) &&
        !this.state.removedEmails.includes(item),
    );
    this.setState({
      inputFocused: true,
      previouslyUsedEmails: [...newPrevUsedEmails],
    });
  }

  onFocusOut() {
    this.setState({
      inputFocused: false,
    });
  }

  setTestType(newType) {
    this.setState({
      testType: newType,
    });
  }

  addToSelected(item) {
    const newPrevUsedEmails = this.state.initialPreviouslyUsedEmails.filter(
      val =>
        val !== item &&
        !this.state.mails.includes(val) &&
        !this.state.removedEmails.includes(val),
    );
    this.setState({
      mails: this.state.mails?.concat([item]),
      previouslyUsedEmails: [...newPrevUsedEmails],
    });
  }

  removeFromPreviouslyUsedEmail(item) {
    const newRemovedEmails = [...this.state.removedEmails, item];
    const newPrevUsedEmails = this.state.initialPreviouslyUsedEmails.filter(
      val =>
        val !== item &&
        !this.state.mails.includes(val) &&
        !newRemovedEmails.includes(val),
    );
    removeUsedEmail(item);
    this.setState({
      previouslyUsedEmails: [...newPrevUsedEmails],
      removedEmails: [...newRemovedEmails],
    });
  }

  sendTest() {
    const data = {};
    const {
      instanceId,
      template,
      additionalSubjects,
      additionalContents,
    } = this.props;
    const { testType, mails } = this.state;
    if (this.props.senderName) {
      const { senderName, senderEmail, replyEmail } = this.props;
      data.senderName = senderName;
      data.senderEmail = senderEmail;
      data.replyEmail = replyEmail;
    }
    data.campaign = instanceId;
    data.template = template;
    data.type = testType;
    data.mails = mails;
    data.additionalSubjects = additionalSubjects;
    data.additionalContents = additionalContents;

    // Send Test Email - data;
    sendTestEmail(data, response => {
      if (response) {
        saveUsedEmails(mails);
        modalActions.closeModal();
        let note;
        if (data.type === 'UI') {
          note = 'Test email sent. Please check your inbox!';
        } else {
          note =
            'Users are in test mode now. Actualize the scenario conditions and check your inbox!';
        }
        const notificationText = () => {
          return <div>{t(note)}</div>;
        };
        notification({
          content: notificationText,
        });
      }
    });
  }

  convertArrayToLabelList(arr) {
    return arr.map(item => {
      return { label: item, value: item };
    });
  }

  render() {
    const isInvalid = this.state.hasError;
    return (
      <div style={{ minWidth: '800px' }}>
        <div style={{ marginBottom: '20px' }}>
          <form
            id='settings-form'
            onSubmit={this.onFormSubmit}
            style={{ marginBottom: '10px', marginTop: '10px' }}
          >
            <ol className='form-elements'>
              <li
                className={
                  isInvalid
                    ? 'form-element form-element-testMail form-element-testMailErr'
                    : 'form-element form-element-testMail'
                }
              >
                <label className='item item-stacked one-whole'>
                  <input
                    type='text'
                    className='text-field'
                    onChange={this.onInputChange}
                    onFocus={this.onInputFocus}
                    value={this.state.inputValue}
                    placeholder='Add E-Mail Address'
                  />
                </label>
                <button type='submit' onClick={this.onFormSubmit}>
                  +
                </button>
              </li>
            </ol>
          </form>
          {this.state.inputFocused && (
            <div className={styles['selectable-items']}>
              {this.state.previouslyUsedEmails.map(item => (
                <div className={styles['selectable-items__container']}>
                  <span
                    className={styles['selectable-items__container__item']}
                    onClick={() => this.addToSelected(item)}
                  >
                    {item}
                  </span>
                  <span
                    className={styles['selectable-items__container__close']}
                    onClick={() => this.removeFromPreviouslyUsedEmail(item)}
                  >
                    x
                  </span>
                </div>
              ))}
            </div>
          )}
          <div>
            <Dropdown
              value={this.state?.selectedGroup}
              options={this.convertArrayToLabelList(this.state?.groupNames)}
              editCampaign={this.onDropdownChange}
              onClick={() => this.onFocusOut()}
              placeholder='Select Email Test Group'
              ddStyle={{ fontSize: '1rem' }}
            />
          </div>
          <h4 className='item-label'>Emails</h4>
          <ul style={{ padding: 0 }}>
            {this.state?.mails?.map(mail => {
              return (
                <li className='multiselect--item__selected tfy-selected-category'>
                  <i
                    className='icon-cross'
                    onClick={this.onRemoveMail.bind(this, mail)}
                  />
                  <span>{mail}</span>
                </li>
              );
            })}
          </ul>
        </div>
        <button
          className={classNames('tertiary-action', {
            disabled: this.state?.mails?.length === 0,
          })}
          type='button'
          onClick={this.sendTest}
          style={{
            float: 'right',
            marginTop: '30px',
            pointerEvents: this.state?.mails?.length === 0 ? 'none' : 'auto',
          }}
        >
          {t('Send Test')}
        </button>
      </div>
    );
  }
}

export default SendTestModal;
