/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";

import { Select } from "../../../../components/fields";
import Icons from "../../../../components/icons";

const options = [
  { label: "Visitor is", value: true },
  { label: "Visitor is not", value: false }
];

const types = [
  { label: "New", value: "new" },
  { label: "Registered", value: "register" },
  { label: "Logged in", value: "login" }
];

class AudienceType extends Component {
  constructor(props) {
    super(props);

    this.onSelectChange = this.onSelectChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.remove = this.remove.bind(this);
  }

  onSelectChange(selectedValue) {
    this.props.onChange(selectedValue.value, this.props.id, "member");
  }

  onTypeChange(selectedValue) {
    this.props.onChange(selectedValue.value, this.props.id, "value");
  }

  remove() {
    this.props.onRemove(this.props.id);
  }

  render() {
    return (
      <li>
        <Select
          className="item-field x-large"
          value={this.props.member}
          options={options}
          onChange={this.onSelectChange}
        />
        <Select
          className="item-field large"
          value={this.props.value}
          options={types}
          onChange={this.onTypeChange}
        />
        <a onClick={this.remove} className="cancel-action helper-action">
          <Icons name="cross" />
        </a>
      </li>
    );
  }
}

export default AudienceType;
