import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { gaTable } from '../constants/datamaps/account';

import { isSuperUser } from '../modules/auth/user';
import { getGaAccountStats, getAccountTypesMap } from '../modules/account/ajax';
import { localeString } from '../system/string';

import SelectUser from '../components/admin/select-user';
import Currency from '../components/currency';

import { numericRoundRatio } from '../system/ratio';
import Icons from '../components/icons';

import { setTitle } from '../system/document';
import {
  dateActions,
  filterActions,
  modalActions,
  switchUserActions,
  uiActions,
} from '../actions';
import { t } from '../system/ui';
import { queryDate } from '../system/date';

import {
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
} from '../components/fields';

import Filters from '../components/filters/filters';
import Icon from '../components/icon';

const types = getAccountTypesMap('ARRAY');

class Admin_GaComparision extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: [],
      _stats: [],
      filters: ['type', 'provider'],
      sorting: 'revenueRatioALL',
      _sorting: '',
      reverse: true,
      filter: 'type',
      types,
      providers: [
        'SEGMENTIFY',
        'INTERNAL',
        'SENDLOOP',
        'SHOPIFY',
        'MAGENTO',
        'PRESTASHOP',
      ],
      defaultTypes: types,
      selectedProviders: [],
      selectedModule: 'all',
      searched: false,
      searchTriggered: false,
    };

    this.sort = this.sort.bind(this);
    this.filterByType = this.filterByType.bind(this);
    this.filterByProvider = this.filterByProvider.bind(this);
    this.updateStats = this.updateStats.bind(this);
    this.switchToUser = this.switchToUser.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
  }

  componentDidMount() {
    setTitle(t('Google Analytics 4 Comparison'));
    uiActions.resetPage();
    uiActions.addPageClass('sidebar-is-hidden');
    dateActions.updateRangeAlias('gPreviousWeek');
    localStorage.removeItem('account');

    if (isSuperUser()) {
      this.listStats(
        false,
        [
          moment()
            .subtract(1, 'isoWeek')
            .startOf('isoWeek'),
          moment()
            .subtract(1, 'isoWeek')
            .endOf('isoWeek')
            .subtract(2, 'd'),
        ],
        false,
      );
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.user !== newProps.user) {
      if (!isSuperUser(newProps.user)) {
        this.context.router.push({
          pathname: '/',
        });

        return;
      }

      this.listStats();
    }

    if (newProps.switchedUser !== this.props.switchedUser) {
      this.props.router.push({
        pathname: '/welcome',
      });
    }

    if (newProps.reset === true) {
      this.reset(newProps.range);
    }

    if (newProps.update === true) {
      this.listStats(
        this.state.sorting,
        newProps.range,
        false,
        newProps.rangeAlias,
      );
    }
  }

  filterByType(value) {
    this.filterAccounts('type', value);
  }

  filterByProvider(value) {
    this.filterAccounts('provider', value);
  }

  filterAccounts(filter, value) {
    const _stats = this.state._stats.filter(stat => {
      return stat.account;
    });

    const filtered = _stats.filter(stat => {
      const { account } = stat;
      return value.indexOf(account[filter]) > -1;
    });

    const obj = {};
    if (filter === 'type') {
      obj.defaultTypes = value;
      obj.selectedProviders = [];
    } else {
      obj.selectedProviders = value;
      obj.defaultTypes = [];
    }

    let stats = _stats;
    if (value.length !== 0) stats = filtered;

    const { selectedModule } = this.state;
    const copyStats = [];
    stats.map(response => {
      const statsObj = {};
      Object.entries(response).map(([key, value]) => {
        const keyObject = key.toLowerCase();
        if (
          keyObject === 'all' ||
          keyObject === 'mobile' ||
          keyObject === 'pc'
        ) {
          if (selectedModule === keyObject) statsObj[key] = value;
        } else statsObj[key] = value;
      });
      copyStats.push(statsObj);
    });

    if (value.length === 0) {
      this.setState({
        defaultTypes: value,
        selectedProviders: value,
        selectedFilter: filter,
        stats: copyStats,
      });
    } else {
      obj.selectedFilter = filter;
      obj.stats = copyStats;
      this.setState(obj);
    }
  }

  sort(sorting) {
    this.listStats(sorting, false, true);
  }

  listStats(sorting, dateRange, listBySorting, rangeAlias) {
    uiActions.isLoading();
    const query = {};
    const range = dateRange || this.props.range;
    const accountId = this.refs.accountSearch.querySelector('[name=accountId]');

    if (sorting) {
      let selectModule = this.state.selectedModule;
      selectModule = selectModule.toUpperCase();

      let columnName;
      if (sorting !== 'domain' && sorting !== 'account_id')
        columnName = sorting + selectModule;
      else columnName = sorting;

      query.sort = columnName;
      if (listBySorting) {
        query.reverse =
          this.state._sorting === columnName ? !this.state.reverse : true;
      } else {
        query.reverse = this.state.reverse;
      }
    }

    query.start = queryDate(range[0]);
    query.end = queryDate(range[1]);
    query.accountId = accountId.value;
    query.accountType = '';
    query.datelabel = rangeAlias;

    getGaAccountStats(query, response => {
      const { selectedModule } = this.state;
      this.setState(
        {
          _stats: response,
          sorting,
          _sorting: query.sort,
          reverse: query.reverse,
          searched: accountId.value.length,
          selectedModule,
          searchTriggered: false,
        },
        () => {
          this.filterAccounts(
            this.state.filter,
            this.state.filter === 'type'
              ? this.state.defaultTypes
              : this.state.selectedProviders,
          );
        },
      );

      uiActions.isLoaded();
      filterActions.filtersUpdated();
    });
  }

  updateStats(e) {
    const code = e.keyCode ? e.keyCode : e.which;
    if (code == 13) {
      this.setState(
        {
          searchTriggered: true,
        },
        () => {
          filterActions.updateFilters();
        },
      );
    }
  }

  reset(dateRange) {
    this.refs.accountSearch.querySelector('[name=accountId]').value = '';
    this.listStats(false, dateRange, false);
    filterActions.filtersReset();
  }

  switchToUser(data) {
    const { users } = data;
    const { account } = data;
    const accountUsers = users;
    const switchedAccount = users[0];
    switchedAccount.account = account;

    let allSwitchableAccounts = [];
    let switchableAccountsWithDetails = [];

    users.forEach(user => {
      if (user.switchableAccounts) {
        allSwitchableAccounts = allSwitchableAccounts.concat(
          user.switchableAccounts,
        );
      }
    });

    switchableAccountsWithDetails = this.state._stats.filter(
      stat =>
        stat.account &&
        allSwitchableAccounts.indexOf(stat.account.accountId) > -1,
    );

    switchableAccountsWithDetails = switchableAccountsWithDetails.map(
      stat => stat.account,
    );

    if (users.length > 1) {
      const modal = () => (
        <SelectUser
          account={account}
          users={accountUsers}
          accounts={[account]}
          switchableAccountsWithDetails={switchableAccountsWithDetails}
        />
      );
      modalActions.openModal({
        title: t('Select User'),
        content: modal,
      });
    } else {
      switchUserActions.updateSwitchUser({
        ...switchedAccount,
        switchableAccountsWithDetails,
      });
    }
  }

  toggleFilter(value) {
    this.setState(
      {
        filter: value,
        defaultTypes: types,
        selectedProviders: [],
      },
      () => {
        this.filterAccounts(value, value === 'type' ? types : []);
      },
    );
  }

  onSelectedModuleChange(module) {
    this.setState({ selectedModule: module }, () => {
      this.filterAccounts(
        this.state.filter,
        this.state.filter === 'type'
          ? this.state.defaultTypes
          : this.state.selectedProviders,
      );
    });
  }

  resetSearch() {
    if (this.state.searched) {
      const searchInput = this.refs.accountSearch.querySelector(
        '[name=accountId]',
      );
      searchInput.value = '';
      filterActions.updateFilters();
    }
  }

  render() {
    const containerClass =
      this.state.stats.length <= 10 ? 'accounts-vertical-result' : '';
    return (
      <div className={containerClass}>
        <ReactTooltip effect='solid' className='sg-tooltip' />
        <div
          className='account-filters account-filters-stats'
          ref='pageFilters'
        >
          <Filters
            defaultDateYesterday={false}
            visibleUpdate={false}
            showYesterday={false}
            isGoogleAnalytics
          />
        </div>
        <h2 className='page-title'>{t('Google Analytics 4 Comparison')}</h2>
        <div className='page-options page-options-admin-stats'>
          <label className='item is-stacked is-search' ref='accountSearch'>
            <span
              onClick={this.resetSearch}
              style={{ cursor: this.state.searched ? 'pointer' : 'initial' }}
            >
              <Icon name={this.state.searched ? 'cross' : 'magnify'} />
            </span>
            <input
              type='text'
              placeholder={t('Account ID or Domain')}
              className='text-field'
              name='accountId'
              onKeyPress={this.updateStats}
            />
          </label>
        </div>

        <div
          className='page-filters low-priority stats-filters has-filter-form'
          ref='pageFilters'
        >
          <div className='status-filters'>
            <RadioGroup
              name='status'
              selectedValue={this.state.filter}
              onChange={this.toggleFilter}
              className='parent-filters'
            >
              {this.state.filters.map(filter => {
                return <Radio key={filter} value={filter} label={t(filter)} />;
              })}
            </RadioGroup>

            <CheckboxGroup
              name='type'
              onChange={this.filterByType}
              className={classNames('filter-details', {
                'is-hidden': this.state.filter !== 'type',
              })}
              ref='typeCheckboxContainer'
              value={this.state.defaultTypes}
            >
              <span
                onClick={this.toggleAccountTypes}
                className='accounts-toggle'
              >
                {this.state.defaultTypes.length === this.state.types.length && (
                  <Icons name='checkboxSelected' />
                )}
                {this.state.defaultTypes.length !== this.state.types.length &&
                  (this.state.defaultTypes.length === 0 ? (
                    <Icons name='checkboxEmpty' />
                  ) : (
                    <Icons name='checkboxPartial' />
                  ))}
              </span>
              {this.state.types.map(type => {
                return <Checkbox key={type} value={type} label={type} />;
              })}
            </CheckboxGroup>

            <CheckboxGroup
              name='provider'
              onChange={this.filterByProvider}
              className={classNames('filter-details', {
                'is-hidden': this.state.filter !== 'provider',
              })}
              ref='providerCheckboxContainer'
              value={this.state.selectedProviders}
            >
              {this.state.providers.map(provider => {
                return (
                  <Checkbox
                    key={provider}
                    value={provider}
                    label={t(provider)}
                  />
                );
              })}
            </CheckboxGroup>
          </div>
        </div>
        <div className='vertical-content account-statistics'>
          <ul className='stats-modules'>
            <li
              onClick={this.onSelectedModuleChange.bind(this, 'all')}
              className={classNames({
                'active-module': this.state.selectedModule === 'all',
              })}
            >
              ALL
            </li>
            <li
              onClick={this.onSelectedModuleChange.bind(this, 'mobile')}
              className={classNames({
                'active-module': this.state.selectedModule === 'mobile',
              })}
            >
              Mobile
            </li>
            <li
              onClick={this.onSelectedModuleChange.bind(this, 'pc')}
              className={classNames({
                'active-module': this.state.selectedModule === 'pc',
              })}
            >
              Web
            </li>
          </ul>
          <div className='account-details account-last-child'>
            <table className='data-table'>
              <thead>
                <tr>
                  {gaTable.map((item, index) => {
                    let { text } = item;
                    const alignment = item.alignment || 'center';
                    const className = classNames('', item.className, {
                      'sort-up':
                        this.state.reverse === true &&
                        this.state.sorting === item.sort,
                      'sort-down':
                        this.state.reverse === false &&
                        this.state.sorting === item.sort,
                    });

                    if (item.icon) {
                      text = <Icon name={item.icon} />;
                    }

                    if (item.sortable) {
                      text = (
                        <a
                          className='sortable'
                          onClick={this.sort.bind(null, item.sort)}
                        >
                          {t(text)}
                        </a>
                      );
                    }

                    if (item.tooltip) {
                      text = (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <span data-tip={item.tooltip}>{text}</span>
                        </div>
                      );
                    }
                    return (
                      <th key={index} className={className}>
                        {t(text)}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {this.state.stats.map((item, index) => {
                  if (item && item.users[0]) {
                    const { account } = item;
                    const componentVal =
                      item[this.state.selectedModule.toUpperCase()];

                    if (componentVal !== undefined) {
                      if (!account) {
                        return '';
                      }
                      return (
                        <tr key={index} className={classNames('accounts-row')}>
                          <td> {index + 1}</td>
                          <td
                            onClick={this.switchToUser.bind(null, {
                              users: item.users,
                              account: item.account,
                            })}
                            className='domain'
                          >
                            {item.domain}
                          </td>
                          <td>{item.account_id}</td>
                          <td className='cell-group'>
                            <Currency
                              currency={componentVal.sgfRevenue}
                              accountCurrency={account.mainCurrency}
                            />
                          </td>
                          <td className='cell-group'>
                            <Currency
                              currency={componentVal.gaRevenue}
                              accountCurrency={account.mainCurrency}
                            />
                          </td>
                          <td>
                            {numericRoundRatio(componentVal.revenueRatio, 2)}%
                          </td>

                          <td className='cell-group'>
                            {localeString(componentVal.sgfPurchases)}
                          </td>
                          <td className='cell-group'>
                            {localeString(componentVal.gaPurchases)}
                          </td>
                          <td>
                            {numericRoundRatio(componentVal.purchasesRatio, 2)}%
                          </td>

                          <td className='cell-group'>
                            {localeString(componentVal.sgfPage)}
                          </td>
                          <td className='cell-group'>
                            {localeString(componentVal.gaPage)}
                          </td>
                          <td>
                            {numericRoundRatio(componentVal.pageRatio, 2)}%
                          </td>

                          <td className='cell-group'>
                            <Currency
                              currency={componentVal.sgfPushRevenue}
                              accountCurrency={account.mainCurrency}
                            />
                          </td>
                          <td className='cell-group'>
                            <Currency
                              currency={componentVal.gaPushRevenue}
                              accountCurrency={account.mainCurrency}
                            />
                          </td>
                          <td>
                            {numericRoundRatio(
                              componentVal.pushRevenueRatio,
                              2,
                            )}
                            %
                          </td>

                          <td className='cell-group'>
                            <Currency
                              currency={componentVal.sgfMailRevenue}
                              accountCurrency={account.mainCurrency}
                            />
                          </td>
                          <td className='cell-group'>
                            <Currency
                              currency={componentVal.gaMailRevenue}
                              accountCurrency={account.mainCurrency}
                            />
                          </td>
                          <td>
                            {numericRoundRatio(
                              componentVal.mailRevenueRatio,
                              2,
                            )}
                            %
                          </td>
                        </tr>
                      );
                    }
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const MapStateToProps = store => ({
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  update: store.filters.update,
  reset: store.filters.reset,
});

export default connect(MapStateToProps)(Admin_GaComparision);
