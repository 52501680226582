const statsMap = {
  page: {
    stats: {
      aLL: {
        name: 'All',
        count: 0,
        total: true,
      },
      homePage: {
        name: 'Home Page',
        count: 0,
        ratio: 0,
      },
      productPage: {
        name: 'Product Page',
        count: 0,
        ratio: 0,
      },
      categoryPage: {
        name: 'Category Page',
        count: 0,
        ratio: 0,
      },
      basketPage: {
        name: 'Basket Page',
        count: 0,
        ratio: 0,
      },
      other: {
        name: 'Other Pages',
        count: 0,
        ratio: 0,
        checkoutCount: 0,
      },
    },
    modal: 'PageView',
  },
  device: {
    stats: {
      pCRatio: {
        name: 'PC',
        ratio: 0,
      },
      mobileRatio: {
        name: 'Mobile',
        ratio: 0,
      },
      iosRatio: {
        name: 'IOS',
        ratio: 0,
      },
      androidRatio: {
        name: 'Android',
        ratio: 0,
      },
      totalMobileRatio: {
        name: 'Total Mobile',
        ratio: 0,
      },
      tabletRatio: {
        name: 'Tablet',
        ratio: 0,
      },
      otherRatio: {
        name: 'Other',
        ratio: 0,
      },
      pCCount: {
        name: 'PC',
        count: 0,
      },
      mobileCount: {
        name: 'Mobile',
        count: 0,
      },
      iosCount: {
        name: 'IOS',
        count: 0,
      },
      androidCount: {
        name: 'Android',
        count: 0,
      },
      totalMobileCount: {
        name: 'Mobile',
        count: 0,
      },
      tabletCount: {
        name: 'Tablet',
        count: 0,
      },
      otherCount: {
        name: 'Other',
        count: 0,
      },
    },
    modal: 'Device',
  },
  unique: {
    stats: {
      average: {
        name: 'Average Sessions',
        count: 0,
        total: true,
      },
      visitor: {
        name: 'Unique Visitors',
        count: 0,
      },
      session: {
        name: 'Unique Sessions',
        count: 0,
      },
      pageSessions: {
        name: 'Pages/Sessions',
        count: 0,
      },
      productSessions: {
        name: 'Product/Sessions',
        count: 0,
      },
    },
    modal: 'Audience',
  },
  purchase: {
    stats: {
      amount: {
        name: '',
        count: 0,
        total: true,
        currency: true,
      },
      count: {
        name: 'Purchases',
        count: 0,
      },
      items: {
        name: 'Purchase Items',
        count: 0,
      },
      averageBasket: {
        name: 'Average Order Value',
        count: 0,
        currency: true,
      },
      conversion: {
        name: 'Conversion',
        ratio: 0,
      },
    },
    modal: 'RevenueOverall',
  },
};

const statTypes = {
  widget: {
    name: 'Widget',
    unit: 'views',
    icon: 'view',
  },
  interaction: {
    name: 'Interaction',
    unit: 'product_clicks',
    icon: 'hand',
  },
  basket: {
    name: 'Basket',
    unit: 'items',
    icon: 'cart',
  },
  revenue: {
    name: 'Revenue',
    currency: true,
    unit: 'try',
    icon: 'try',
  },
  grantedPermission: {
    name: 'Granted',
    unit: '',
    icon: 'check',
  },
  deniedPermission: {
    name: 'Denied',
    unit: '',
    icon: 'cross',
  },
  formSubmit: {
    name: 'Form Submit',
    unit: '',
    icon: 'check',
  },
  segmentVisitor: {
    name: 'Segment Visitor',
    unit: '',
    icon: 'add-user',
  },
  unsegmentVisitor: {
    name: 'Unsegment Visitor',
    unit: '',
    icon: 'removeUser',
    iconSvg: true,
  },
  toggleVisitorSegment: {
    name: 'Toggle Visitor Segment',
    unit: '',
    icon: 'toggleUser',
    iconSvg: true,
  },
  overallRevenue: {
    name: 'Revenue Generated by Segmentify',
    currency: true,
    unit: 'try',
    icon: 'try',
  },
  overallContribution: {
    name: "Segmentify's Revenue Contribution",
    unit: '%',
    icon: '',
  },
  overallPCount: {
    name: 'Products Sold via Segmentify',
    unit: 'items',
    icon: 'cart',
  },
};

const statsMapAnalytics = {
  page: {
    stats: {
      aLL: {
        name: 'All',
        count: 0,
        total: true,
      },
    },
    modal: 'PageView',
  },
  device: {
    stats: {
      pCRatio: {
        name: 'PC',
        ratio: 0,
      },
      mobileRatio: {
        name: 'Mobile',
        ratio: 0,
      },
      tabletRatio: {
        name: 'Tablet',
        ratio: 0,
      },
      otherRatio: {
        name: 'Other',
        ratio: 0,
      },
      pCCount: {
        name: 'PC',
        count: 0,
      },
      mobileCount: {
        name: 'Mobile',
        count: 0,
      },
      tabletCount: {
        name: 'Tablet',
        count: 0,
      },
      otherCount: {
        name: 'Other',
        count: 0,
      },
    },
    modal: 'Device',
  },
  unique: {
    stats: {
      average: {
        name: 'Average Sessions',
        count: 0,
        total: true,
      },
      visitor: {
        name: 'Unique Visitors',
        count: 0,
      },
      session: {
        name: 'Unique Sessions',
        count: 0,
      },
      pageSessions: {
        name: 'Pages/Sessions',
        count: 0,
      },
    },
    modal: 'Audience',
  },
  purchase: {
    stats: {
      amount: {
        name: '',
        count: 0,
        total: true,
        currency: true,
      },
      count: {
        name: 'Purchases',
        count: 0,
      },
      items: {
        name: 'Purchase Items',
        count: 0,
      },
      averageBasket: {
        name: 'Average Basket',
        count: 0,
        currency: true,
      },
    },
    modal: 'RevenueOverall',
  },
};

const statTitles = {
  all: {
    title: 'Summary',
    subtitle:
      'See the summary performance of the all Segmentify Search & Discovery module.',
  },
  faceted: {
    title: 'Searchandising',
    subtitle: 'See the performance of Searchandising',
  },
  instant: {
    title: 'Search Box',
    subtitle: 'See the performance of search box.',
  },
  search: {
    title: 'Summary',
    subtitle: 'See the summary performance of the all the assets.',
  },
  product: {
    title: 'Product',
    subtitle: 'See the performance of the Product asset in the Search Box.',
  },
  brand: {
    title: 'Brands',
    subtitle: 'See the performance of the brand asset in the Search Box.',
  },
  keywords: {
    title: 'Keywords',
    subtitle: 'See the performance of the keywords in the Search Box.',
  },
  category: {
    title: 'Categories',
    subtitle: 'See the performance of the categories in the Search Box.',
  },
};

export { statsMap, statsMapAnalytics, statTypes, statTitles };
