import React from "react";
import { connect } from "react-redux";

import { Select } from "../../components/fields";
import { isSwitchAccount } from "../../modules/auth/user";
import Icon from "../../components/icon";
import { updateSwitchLanguage } from "../../actions/switchLanguage";

import { browserHistory,withRouter } from "react-router";


class LanguageSwitcher extends React.Component {
  constructor(props) {
    super(props);

    const languages = this.getLanguages();
    this.state = {
      languages
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount(nextProps) {
    const languages = this.getLanguages(nextProps);
    const selected = languages[0].value;
    if (selected) {
      this.setState({
        languages
      });
      updateSwitchLanguage(selected);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.switchedAccount !== this.props.switchedAccount ||
        nextProps.userAccount !== this.props.userAccount) {
      const languages = this.getLanguages(nextProps);
      const selected = languages[0].value;
      if (selected) {
        this.setState({
          languages
        });
        updateSwitchLanguage(selected);
      }
    }
  }

  onChange(item) {
    if (item) {
      updateSwitchLanguage(item.value);
      let urlPath=this.props.location.pathname;
      browserHistory.replace(urlPath);
    }
  }

  getLanguages(nextProps) {
    const props = nextProps || this.props;

    let account;
    if (isSwitchAccount()) {
      account = props.switchedAccount;
    } else {
      account = props.userAccount;
    }

    const additionalLanguages = account.additionalLanguages || [];
    const mainLanguage = account.mainLanguage;

    let languages = Array(mainLanguage).concat(additionalLanguages);

    return languages.map(item => ({
      label: item,
      value: item
    }));

  }

  render() {
    const languages = this.state.languages;
    const selectedLanguage = this.props.selectedLanguage;

    if (languages.length < 2) {
      return null;
    }

    if (this.state.isHidden) {
      return null;
    }

    return (
      <div className="language-switcher">
        <div className="language-icon"></div>
        <Select
          name="language"
          className="item-stacked full-width"
          value={selectedLanguage}
          options={languages}
          onChange={this.onChange}
          searchable={true}
        />
        <div className="language-switcher__arrow-down">
          <Icon name="chevron-down" />
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
    switchedAccount: store.switchedUser.switchedUser.account,
    userAccount: store.user.user.account,
    selectedLanguage: store.switchLanguage.selectedLanguage
});

export default connect(mapStatesToProps)(withRouter(LanguageSwitcher));
