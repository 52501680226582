import React from 'react';

import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';

import { modalActions } from '../actions';
import AccountStatusProvider from '../hooks/account-status-provider/index';
import AppProvider from '../hooks/app-provider/appProvider';
import {
  externalRoutes,
  notFoundRoute,
  protectedRoutes,
  setPasswordRoute,
  staticRoutes,
} from '../routes';
import { trackingService } from '../trackingService';

browserHistory.listen(() => {
  modalActions.closeModal();
});

trackingService.init();

const Root = ({ store }) => (
  <Provider store={store}>
    <AccountStatusProvider>
      <AppProvider>
        <Router history={browserHistory} onUpdate={() => window.scrollTo(0, 0)}>
          {protectedRoutes}
          {externalRoutes}
          {staticRoutes}
          {setPasswordRoute}
          {notFoundRoute}
        </Router>
      </AppProvider>
    </AccountStatusProvider>
  </Provider>
);

export default Root;
