import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icons from '../../../components/icons';
import { t } from '../../../system/ui';
import { idPropFunc } from '../../../system/id';
import classes from './SegmentList.module.scss';
import RbsActions from './RbsActions';
import Tooltip from '../../../components/tooltip';

const title = {
  test: 'Draft Segments',
  active: 'Live Segments',
  archive: 'Archived Segments',
};

const SegmentList = ({ id, status, segments, isMustShow }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isToggled, setIsToggled] = useState(true);

  const tooltipHandler = () => {
    setIsToggled(!isToggled);
  };

  const toggleListShow = () => {
    if (status.toLowerCase() !== 'active' && !isMustShow) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div
      className={`widget-list-has-items ${
        status.toLowerCase() === 'active' || isMustShow
          ? 'is-toggled'
          : 'will-toggle'
      } ${isOpen ? 'is-toggled' : ''}`}
    >
      <div
        className={classNames('page-subtitle', {
          passive: status.toLowerCase() !== 'active',
        })}
        onClick={() => toggleListShow()}
        style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
      >
        <h3>
          {t(title[status])} ({segments.length})
        </h3>
        {status.toLowerCase() === 'active' && (
          <div className={classes['toggle-container']}>
            <label className='item is-toggle' style={{ marginBottom: 0 }}>
              <input
                type='checkbox'
                checked={isToggled}
                disabled={segments.length === 0}
                onChange={() => {
                  tooltipHandler();
                }}
              />
              <span
                className='item-label'
                style={{ background: 'white', borderRadius: '10px' }}
              />
            </label>
            <span className='item-help'>
              <span className='live'>{t('Stats')}</span>
            </span>
          </div>
        )}
      </div>
      <ul className='widget-list'>
        {segments.map(segment => {
          return (
            <li
              key={segment.segmentId}
              className='widget-list-item'
              {...idPropFunc(id, 'segmentList')}
            >
              <div className={classes['widget-title']}>
                {segment.segmentName}
                {segment?.description && (
                  <Tooltip content={t(segment.description)} alignment='left'>
                    <i className='icon-info' role='presentation'>
                      <span className='for-screenreader-only' />
                    </i>
                  </Tooltip>
                )}
              </div>

              {status.toLowerCase() === 'active' && (
                <div
                  className={
                    classes[
                      `widget-list-item-container${isToggled ? '' : '-dnone'}`
                    ]
                  }
                >
                  <div className={classes['widget-list-item-details']}>
                    <Icons name='PeopleGroupIcon' />
                    <span className={classes['widget-list-item-details-text']}>
                      {segment?.reachableUser === 0 || !segment?.reachableUser
                        ? t('Not segmented yet')
                        : `${segment?.reachableUser} ${t('users in segment')}`}
                    </span>
                  </div>
                  <div className={classes['widget-list-item-details']}>
                    <Icons name='pushModule' color='#737373' />
                    <span className={classes['widget-list-item-details-text']}>
                      {segment?.reachablePushUser === 0 ||
                      !segment?.reachablePushUser
                        ? t('No Data yet')
                        : `${t('Push Opt-In')}: ${segment?.reachablePushUser} `}
                    </span>
                  </div>
                  <div className={classes['widget-list-item-details']}>
                    <Icons name='emailModule' color='#737373' />
                    <span className={classes['widget-list-item-details-text']}>
                      {segment?.reachableEmailUser === 0 ||
                      !segment?.reachableEmailUser
                        ? t('No Data yet')
                        : `${t('Email Opt-In')}: ${
                            segment?.reachableEmailUser
                          } `}
                    </span>
                  </div>
                </div>
              )}
              <RbsActions campaign={segment} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
SegmentList.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  segments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMustShow: PropTypes.bool,
};

SegmentList.defaultProps = {
  isMustShow: false,
};

export default SegmentList;
