import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const TrendifyLogoIcon = ({ width, height, bgColor, textColor }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 37 35'
      fill='none'
    >
      <rect width='36.5909' height='35' rx='2' fill={bgColor} />
      <path
        d='M10.6273 23.0091V18.6818H9.54546V16.4864H10.6273V14.1318H13.2045V16.4864H15.3682V18.6818H13.2045V22.5637C13.1867 22.682 13.1963 22.8028 13.2327 22.9169C13.269 23.0309 13.3311 23.135 13.4142 23.2211C13.4973 23.3073 13.5991 23.3731 13.7117 23.4136C13.8243 23.4541 13.9447 23.4681 14.0636 23.4546C14.5046 23.4554 14.9381 23.3402 15.3205 23.1205V25.2046C14.697 25.5634 13.9873 25.7449 13.2682 25.7296C12.5905 25.7799 11.9176 25.5819 11.375 25.1727C11.1041 24.8857 10.8974 24.5444 10.7685 24.1713C10.6396 23.7983 10.5914 23.4021 10.6273 23.0091ZM18.0091 13.3523H17.3091V11.9364H18.0091V11.5227C17.9892 11.2521 18.024 10.9801 18.1114 10.7232C18.1989 10.4663 18.3372 10.2296 18.5182 10.0273C18.9142 9.68623 19.4286 9.51478 19.95 9.55002C20.3661 9.54503 20.7801 9.60956 21.175 9.74092V11.1409C20.9234 11.0607 20.659 11.0284 20.3955 11.0455C19.9659 11.0455 19.7591 11.2682 19.7591 11.7137V11.9364H21.175V13.3523H19.7591V18.0455H18.0091V13.3523ZM21.8432 19.5091L22.4159 18.2364C22.6491 18.3879 22.9178 18.4757 23.1955 18.4909C23.3093 18.5274 23.4316 18.5274 23.5455 18.4909C23.6957 18.4007 23.8086 18.2596 23.8636 18.0932L21.3659 11.8568H23.2273L24.6432 16.0727L25.9955 11.8568H27.8091L25.4227 18.2205C25.2792 18.729 25.0113 19.1937 24.6432 19.5727C24.289 19.8583 23.8404 20.0002 23.3864 19.9705C22.838 19.9702 22.3017 19.8099 21.8432 19.5091Z'
        fill={textColor}
      />
    </svg>
  );
};

TrendifyLogoIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
};

TrendifyLogoIcon.defaultProps = {
  width: '37px',
  height: '35px',
  bgColor: '#3B808E',
  textColor: '#FFFFFF',
};

export default TrendifyLogoIcon;
