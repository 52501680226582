const browsers = [
  'Chrome',
  'Firefox',
  'Safari',
  'Opera',
  'Yandex',
  'UCBrowser',
  'Samsung',
];

export const browserSlugs = {
  Chrome: 'chrome',
  Firefox: 'firefox',
  Safari: 'safari',
  Opera: 'opera',
  Samsung: 'samsung',
  UCBrowser: 'ucbrowser',
  Yandex: 'yandex',
  Android: 'android',
  Apple: 'apple',
};

const platforms = ['PC', 'MOBILE', 'TABLET', 'APPS'];

const deviceList = ['ANDROID', 'IOS'];

export const getCampaignNames = type => {
  let result;
  const names = {
    Retention: [
      'ABANDONED_CART',
      'LAST_VISIT_ALTERNATIVES',
      'NICE_TO_MEET_YOU',
      'LAST_VISIT_REMINDER',
      'REPLENISHMENT',
    ],
    'Permission Prompt': ['PUSH_PERMISSION'],
    Winback: ['WE_MISSED_YOU', 'CHURN', 'PRICE_DROP', 'BACK_IN_STOCK'],
    Recommendation: ['RECOMMENDATION'],
    'Fly Mode': ['FLY'],
    'Bulk / Segmented': ['PUSH_NOTIFICATION'],
    Growth: ['NEW_COMERS', 'TOP_SELLERS', 'ORDER_FOLLOW_UP', 'BIRTHDAY'],
    Betting: ['UPCOMING'],
  };

  Object.keys(names).forEach(name => {
    if (names[name].indexOf(type) > -1) {
      result = name;
    }
  });
  return result;
};

export const getBreadcrumbNames = type => {
  let result;
  const names = {
    retention: [
      'ABANDONED_CART',
      'LAST_VISIT_ALTERNATIVES',
      'NICE_TO_MEET_YOU',
      'LAST_VISIT_REMINDER',
      'REPLENISHMENT',
    ],
    permission: ['PUSH_PERMISSION'],
    winback: ['WE_MISSED_YOU', 'CHURN', 'PRICE_DROP', 'BACK_IN_STOCK'],
    recommendation: ['RECOMMENDATION'],
    fly: ['FLY'],
    bulk: ['PUSH_NOTIFICATION'],
    growth: ['NEW_COMERS', 'TOP_SELLERS', 'ORDER_FOLLOW_UP', 'BIRTHDAY'],
    betting: ['UPCOMING'],
  };

  Object.keys(names).forEach(name => {
    if (names[name].indexOf(type) > -1) {
      result = name;
    }
  });
  return result;
};

export const transformReport = data => {
  const result = {};
  browsers.forEach(browser => {
    platforms
      .filter(platform => platform !== 'APPS')
      .forEach(platform => {
        result[browser + platform] = {
          name: browser + platform,
          segmentifyCount: 0,
          nonSegmentifyCount: 0,
          browser,
        };
      });
  });

  deviceList.forEach(device => {
    platforms
      .filter(platform => platform === 'APPS')
      .forEach(platform => {
        result[device + platform] = {
          name: device + platform,
          segmentifyCount: 0,
          nonSegmentifyCount: 0,
          device,
        };
      });
  });

  if (data && data.web) {
    data.web
      .filter(stat => stat.device)
      .forEach(stat => {
        const agentAndDevice = `${stat.agent}${stat.device?.toUpperCase()}`;
        if (result[agentAndDevice]) {
          if (stat.segmentifyCount) {
            result[agentAndDevice].segmentifyCount += stat.segmentifyCount;
          }
          if (stat.nonSegmentifyCount) {
            result[agentAndDevice].nonSegmentifyCount +=
              stat.nonSegmentifyCount;
          }
        }
      });
  }

  if (data && data.nativeApp) {
    data.nativeApp
      .filter(native => native.device)
      .forEach(native => {
        result[`${native.device}APPS`].segmentifyCount = native.segmentifyCount;
        result[`${native.device}APPS`].nonSegmentifyCount =
          native.nonSegmentifyCount;
      });
  }
  return result;
};

export const paramsBasedFunctionName = param => {
  switch (param) {
    case 'workingHour':
      return 'WorkingHour';
    case 'delay':
      return 'Delay';
    default:
      return '';
  }
};
