/**
 * Created by mehmetyurtar on 29/06/2017.
 */
import React from "react";

class IconCompare extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            d="M12.707,0.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L12.586,3H1C0.448,3,0,3.447,0,4s0.448,1,1,1h11.586
	l-1.293,1.293c-0.391,0.391-0.391,1.023,0,1.414C11.488,7.902,11.744,8,12,8s0.512-0.098,0.707-0.293l3-3
	c0.391-0.391,0.391-1.023,0-1.414L12.707,0.293z"
            fill={this.state.color}
          />
          <path
            d="M15,11H3.414l1.293-1.293c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-3,3c-0.391,0.391-0.391,1.023,0,1.414l3,3
	C3.488,15.902,3.744,16,4,16s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L3.414,13H15c0.552,0,1-0.447,1-1
	S15.552,11,15,11z"
            fill={this.state.color}
          />
        </g>
      </svg>
    );
  }
}

export default IconCompare;
