import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Select = ({ name, id, label, extraClasses, children, inputProps, defaultClasses }) => {
  const inputClasses = classnames('one-whole criteria-field', {
    ...extraClasses,
  });
  return (
    <label htmlFor="-" className={defaultClasses}>
      <select {...inputProps} name={name} id={id} className={inputClasses}>
        {children}
      </select>
      {label && <span className="absolute-label">{label}</span>}
    </label>
  );
};

Select.defaultProps = {
  id: 'faceted-search-select',
  extraClasses: null,
  inputProps: null,
  label: null,
  defaultClasses: 'item item-field is-select criteria-name',
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  inputProps: PropTypes.instanceOf(Object),
  extraClasses: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Object),
  ]),
  label: PropTypes.string,
  defaultClasses: PropTypes.string,
};

export default Select;
