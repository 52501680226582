import React from "react";
import { connect } from "react-redux";
import { t } from "../../system/ui";
import SearchCriteria from "./search-criteria";

class SearchItems extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ol className="form-elements wizard-items">
        <div className="wizard-comp">
          <h3 className="wizard-comp-title">{t("Assets")}</h3>
          <p className="wizard-comp-description">
            {t(
              "Prepare your Search Box content here. Each one of the Category, Brand and Product fields indicate an Asset."
            )}
          </p>

          <li className="wizard-input-type-wrapper">
            <div className="wizard-criteria">
              <SearchCriteria
                campaign={this.props.campaign}
                account={this.props.account}
                wizardLanguage={this.props.wizardLanguage}
              />
            </div>
          </li>
        </div>
      </ol>
    );
  }
}

const mapStatesToProps = (store) => ({
  validate: store.ui.validate,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes
});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
  SearchItems
);
