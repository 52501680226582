// TODO: AFTER VERSION 2 IS IMPLEMENTED, REMOVE THE CONDITIONAL RENDERINGs OF THE VERSION 2

/**
 * @fileOverview This file open the modal of the edit screen of Firebase Credentials.
 * @requires react
 * @requires classNames
 */
import React, { useState } from 'react';
import classNames from 'classnames';
import { uiActions, modalActions } from '../../actions';
import { t } from '../../system/ui';
import Icon from '../icon';
import { TextArea, TextField } from '../fields';
import { $http } from '../../system/ajax';
import { getApiUrl } from '../../constants/config';

const INPUT_CONSTANTS = {
  senderID: 'senderID',
  serverApiKey: 'serverApiKey',
  apiKey: 'apiKey',
  appId: 'appId',
  projectId: 'projectId',
  publicKey: 'publicKey',
  serviceAccount: 'serviceAccount',
};

const STATE_CONSTANTS = {
  isSending: 'isSending',
  isVersionTwo: 'isVersionTwo',
};

/**
 * The modal of the edit screen of Firebase Credentials.
 * @param {Object} account Details of selected account.
 * @param {Array} users List of users defined to the selected account
 * @param {Object} user Details of login user
 * @param {Function} onPushUpdate Sending the data inputted to the backend.
 * @return {JSX.Element}
 */
const ActivatePushModal = ({
  account,
  users,
  user,
  onPushUpdate,
  pushStatus,
}) => {
  const username = user ? user.username : false;
  const switchUser = users
    ? users?.[account.accountId]?.[0]?.user?.username
    : username;

  const [state, setState] = useState({
    isSending: false,
    switchUser,
    isVersionTwo: !!(
      pushStatus === 'POC' ||
      pushStatus === 'PASSIVE' ||
      account?.pushConfiguration?.fcmConfiguration
    ),
    senderID: account?.pushConfiguration?.webConfiguration?.senderId || '',
    serverApiKey:
      account?.pushConfiguration?.webConfiguration?.serverApiKey || '',
    projectId: account?.pushConfiguration?.fcmConfiguration?.projectId || '',
    apiKey: account?.pushConfiguration?.fcmConfiguration?.apiKey || '',
    appId: account?.pushConfiguration?.fcmConfiguration?.appId || '',
    publicKey: account?.pushConfiguration?.fcmConfiguration?.publicKey || '',
    serviceAccount:
      JSON.stringify(
        account?.pushConfiguration?.fcmConfiguration?.serviceAccount,
        undefined,
        4,
      ) || '',
  });

  const stateChangeHandler = (key, value) => {
    setState(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleOnPaste = event => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('Text');
    try {
      const parsedData = JSON.parse(pastedData);
      stateChangeHandler(
        INPUT_CONSTANTS.serviceAccount,
        JSON.stringify(parsedData, undefined, 4),
      );
    } catch (error) {
      console.error('Error parsing JSON', error);
    }
  };

  const submit = () => {
    const deprecatedVersionValidation =
      ![!!state.serverApiKey, !!state.senderID, !!state.switchUser].every(
        value => value,
      ) && !state.isVersionTwo;

    const versionTwoValidation =
      ![
        !!state.senderID,
        !!state.projectId,
        !!state.publicKey,
        !!state.appId,
        !!state.apiKey,
        !!state.serviceAccount,
        !!state.switchUser,
      ].every(value => value) && state.isVersionTwo;

    if (deprecatedVersionValidation) {
      return;
    }

    if (versionTwoValidation) {
      return;
    }

    stateChangeHandler(STATE_CONSTANTS.isSending, true);

    const url = `${getApiUrl()}update/push/${
      state.isVersionTwo ? 'fcmConfiguration' : 'configuration'
    }?apiKey=${account.apiKey}`;

    let data;

    if (state.isVersionTwo) {
      data = {
        senderId: state.senderID.trim(),
        projectId: state.projectId.trim(),
        publicKey: state.publicKey.trim(),
        appId: state.appId.trim(),
        apiKey: state.apiKey.trim(),
        serviceAccount: JSON.parse(state?.serviceAccount?.trim()),
      };
    } else {
      data = {
        serverApiKey: state.serverApiKey.trim(),
        senderId: state.senderID.trim(),
      };
    }

    $http(url)
      .post(data, { type: 'json', switchUser })
      .then(() => {
        const content = () => (
          <span>{t('Push Configuration successfully saved.')}</span>
        );
        uiActions.showNotification({
          content,
        });

        onPushUpdate(account.accountId, data);
        modalActions.closeModal();
      })
      .finally(() => {
        stateChangeHandler(STATE_CONSTANTS.isSending, false);
      });
  };

  return (
    <div className='push-dialog'>
      <div className='info-box'>
        <span className='info-icon'>
          <Icon name='info' />
        </span>
        {t('Enter your firebase credentials for Chromium & Firefox')}
        {pushStatus !== 'ACTIVE' ||
          (pushStatus !== 'POC' && (
            <button
              className='versionTwo'
              type='button'
              onClick={() =>
                stateChangeHandler(
                  STATE_CONSTANTS.isVersionTwo,
                  !state.isVersionTwo,
                )
              }
            >
              {state.isVersionTwo ? t('Version 1') : t('Version 2')}
            </button>
          ))}
      </div>
      <div className='push-dialog--input'>
        <TextField
          name={INPUT_CONSTANTS.senderID}
          className='item-stacked one-whole'
          label={t('Sender ID')}
          value={state.senderID}
          onChange={event =>
            stateChangeHandler(INPUT_CONSTANTS.senderID, event.target.value)
          }
          required
        />
      </div>
      {!state.isVersionTwo && (
        <div className='push-dialog--input'>
          <TextField
            name={INPUT_CONSTANTS.serverApiKey}
            className='item-stacked one-whole'
            label={t('Server Key')}
            value={state.serverApiKey}
            onChange={event =>
              stateChangeHandler(
                INPUT_CONSTANTS.serverApiKey,
                event.target.value,
              )
            }
            required={!state.isVersionTwo}
          />
        </div>
      )}
      {state.isVersionTwo && (
        <>
          <div className='push-dialog--input'>
            <TextField
              name={INPUT_CONSTANTS.projectId}
              className='item-stacked one-whole'
              label={t('Project ID')}
              value={state.projectId}
              onChange={event =>
                stateChangeHandler(
                  INPUT_CONSTANTS.projectId,
                  event.target.value,
                )
              }
              required={state.isVersionTwo}
            />
          </div>
          <div className='push-dialog--input'>
            <TextField
              name={INPUT_CONSTANTS.publicKey}
              className='item-stacked one-whole'
              label={t('Public Key')}
              value={state.publicKey}
              onChange={event =>
                stateChangeHandler(
                  INPUT_CONSTANTS.publicKey,
                  event.target.value,
                )
              }
              required={state.isVersionTwo}
            />
          </div>
          <div className='push-dialog--input'>
            <TextField
              name={INPUT_CONSTANTS.appId}
              className='item-stacked one-whole'
              label={t('App ID')}
              value={state.appId}
              onChange={event =>
                stateChangeHandler(INPUT_CONSTANTS.appId, event.target.value)
              }
              required={state.isVersionTwo}
            />
          </div>
          <div className='push-dialog--input'>
            <TextField
              name={INPUT_CONSTANTS.apiKey}
              className='item-stacked one-whole'
              label={t('Api Key')}
              value={state.apiKey}
              onChange={event =>
                stateChangeHandler(INPUT_CONSTANTS.apiKey, event.target.value)
              }
              required={state.isVersionTwo}
            />
          </div>
          <div className='push-dialog--input'>
            <TextArea
              name={INPUT_CONSTANTS.serviceAccount}
              className='item-stacked one-whole'
              inputClassName='pushConfigTextArea'
              label={t('Service Account')}
              value={state.serviceAccount}
              onChange={event =>
                stateChangeHandler(
                  INPUT_CONSTANTS.serviceAccount,
                  event.target.value,
                )
              }
              onPaste={event => {
                handleOnPaste(event);
              }}
              required={state.isVersionTwo}
            />
          </div>
        </>
      )}

      <div className='push-dialog--input'>
        <button
          className={classNames({
            'tertiary-action': true,
            disabled: state.isSending,
          })}
          type='button'
          onClick={() => submit()}
        >
          {!state.isSending ? (
            <span>{t('Save')}</span>
          ) : (
            <span>{t('Saving...')}</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default ActivatePushModal;
