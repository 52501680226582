import React, { useRef, useState } from 'react';
import { t } from '../../../../system/ui';
import Icon from '../../../../components/icon';
import { uploadImportedFile } from '../services/shopTheLookServices';
import {
  isLoaded,
  isLoading,
  showNotification,
  showErrorNotification,
} from '../../../../actions/ui';
import style from './shopTheLookImport.module.scss';
import { modalActions } from '../../../../actions';

/**
 * @name STL import csv file
 * @description csv file upload modal
 * @return {JSX.Element}
 */
const STLImport = ({ listInvoker }) => {
  const [isUploaded, setIsUploaded] = useState(false);
  const [isContinueButtonEnabled, setIsContinueButtonEnabled] = useState(false);
  const [fileName, setFileName] = useState('');
  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const continueHandler = () => {
    listInvoker(true, 'product');
    modalActions.closeModal();
  };

  const onFileDrop = e => {
    const newFile = e.target.files[0];
    if (newFile) {
      isLoading();
      setFileName(newFile?.name);
      uploadImportedFile(newFile)
        .then(() => {
          const notificationContent = () => (
            <div>{t('File uploaded successfully')}</div>
          );
          showNotification({ content: notificationContent });
          setIsUploaded(true);
          setIsContinueButtonEnabled(true);
        })
        .catch(error => {
          const errorMessages =
            error?.statusMessage ||
            error?.response?.message ||
            'Something went wrong';
          const notificationContent = () => <div>{t(errorMessages)}</div>;
          showErrorNotification(notificationContent);
        })
        .finally(() => {
          isLoaded();
        });
    }
  };

  const downloadAsset = e => {
    e.preventDefault();
    const url = 'https://cdn.segmentify.com/v3/assets/shopTheLookImport.csv';
    fetch(url)
      .then(response => response.text())
      .then(data => {
        const dataBlob = new Blob([data], { type: 'text/csv;charset=utf-8' });
        const objUrl = URL.createObjectURL(dataBlob);
        const anchor = document.createElement('a');
        anchor.href = objUrl;
        anchor.download = 'Shop-The-Look-Import.csv';
        document.body.appendChild(anchor);
        anchor.click();
      });
  };

  return (
    <div className={style['upload-bundle-modal']}>
      <div className={style['upload-bundle-modal__head']}>
        <div className={style['upload-bundle-modal__head__title']}>
          {t('You can check your file format before uploading it.')}
          <a onClick={e => downloadAsset(e)}>{t('See Document Format')}</a>
        </div>
      </div>
      <div
        className={style['upload-bundle-modal__input']}
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        {isUploaded ? (
          <>
            <div className={style['upload-bundle-modal__file-name']}>
              <Icon name='pdf' />
              <span>{fileName}</span>
            </div>
            <div className={style['upload-bundle-modal__file-status']}>
              <Icon name='check' />
              <span>{t('Uploaded')}</span>
            </div>
          </>
        ) : (
          <>
            <div className={style['upload-bundle-modal__icon']}>
              {t('Upload')}
              <span>
                <Icon name='upload' />
              </span>
            </div>
            <span>{t('Drag and drop or browse to choose .csv file.')}</span>
          </>
        )}

        <input type='file' onChange={onFileDrop} accept='.csv' />
      </div>
      <div className={style['continue-button']}>
        <button
          className='tertiary-action'
          type='button'
          onClick={() => continueHandler()}
          disabled={!isContinueButtonEnabled}
        >
          {t('Continue')}
        </button>
      </div>
    </div>
  );
};

export default STLImport;
