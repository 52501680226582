import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.replace('px', '')}px`;
};

const EmailScheduleTimeClock = ({ width, height }) => {
  return (
    <svg
      version='1.1'
      id='Icons'
      xmlns='http://www.w3.org/2000/svg'
      // xmlns:xlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 32 32'
      // xml:space='preserve'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
    >
      <polyline
        className='st0'
        points='22,18 22,22 25,22 '
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeWidth: 2,
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <circle
        className='st0'
        cx='22'
        cy='22'
        r='7'
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeWidth: 2,
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <polyline
        className='st0'
        points='12,25 4,25 4,7 28,7 28,13 '
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeWidth: 2,
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <line
        className='st0'
        x1='17.9'
        y1='16.3'
        x2='24'
        y2='11'
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeWidth: 2,
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <line
        className='st0'
        x1='8'
        y1='11'
        x2='16'
        y2='18'
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeWidth: 2,
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
      />
      <line
        className='st0'
        x1='13.7'
        y1='16'
        x2='8'
        y2='21'
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeWidth: 2,
          strokeLinejoin: 'round',
          strokeMiterlimit: 10,
        }}
      />
    </svg>
  );
};

EmailScheduleTimeClock.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

EmailScheduleTimeClock.defaultProps = {
  width: '24px',
  height: '24px',
};

export default EmailScheduleTimeClock;
