import React, { Component } from 'react';
import CampaignDiff from './campaignhistorydiff';
import { getCampaignHistory, getEngagementHistory } from '../reports/ajax';
import { editCampaign } from '../campaign/events';
import { updateCampaign } from './ajax';
import Icon from '../../components/icon';
import Icons from '../../components/icons';
import { notification, t } from '../../system/ui';
import { dialogActions, filterActions, modalActions } from '../../actions';

import { reformatDate } from '../../system/date';
import * as Notifications from '../../constants/datamaps/notifications';
import { getEmailCampaignHistory, updateEmailCampaign } from '../emails/ajax';
import {
  editCampaign as editPushCampaign,
  getPushCampaignHistory,
} from '../push/ajax';
import {
  getCampaignWithDetail,
  revertCampaign as editRbsCampaign,
} from '../rbs/ajax';
import {
  getCampaignWithDetail as getRbsCampaignWithDetail,
  revertCampaign as editDsCampaign,
} from '../ds/ajax';

import {
  getSearch,
  updateCampaign as updatePalantirCampaign,
} from '../search/ajax';
import {
  facetedRevertUpdate,
  facetedSearchRequest,
  getFacetedSearchByInstanceId,
} from '../../actions/faceted-search';
import { CountdownService } from '../../views/behavioural-targeting/countdown/services/countdownServices';
import { RecommendationTemplateService } from '../../views/recommendation/templates/services/recommendationTemplateServices';
import { isSuperUser } from '../auth/user';

class CampaignHistoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignHistory: [],
      currentCampaign: {},
      previousCampaign: {},
      isBack: true,
      modalTitle: '',
    };
    this.listDsCampaignHistory = this.listDsCampaignHistory.bind(this);
    this.listRbsCampaignHistory = this.listRbsCampaignHistory.bind(this);
    this.listCampaignHistory = this.listCampaignHistory.bind(this);
    this.revertCampaign = this.revertCampaign.bind(this);
    this.compareCampaign = this.compareCampaign.bind(this);
    this.listRecommendationHistory = this.listRecommendationHistory.bind(this);
    this.listEngagementHistory = this.listEngagementHistory.bind(this);
    this.listMailCampaignHistory = this.listMailCampaignHistory.bind(this);
    this.listPushHistory = this.listPushHistory.bind(this);
    this.listPalantirHistory = this.listPalantirHistory.bind(this);
    this.revertRecommendation = this.revertRecommendation.bind(this);
    this.revertEngagement = this.revertEngagement.bind(this);
    this.revertEmail = this.revertEmail.bind(this);
    this.revertPush = this.revertPush.bind(this);
    this.revertRbs = this.revertRbs.bind(this);
    this.revertPalantir = this.revertPalantir.bind(this);
    this.revertFacetedSearch = this.revertFacetedSearch.bind(this);
    this.listRecommendationTemplateHistory = this.listRecommendationTemplateHistory.bind(
      this,
    );
    this.revertRecommendationTemplate = this.revertRecommendationTemplate.bind(
      this,
    );
  }

  componentDidMount() {
    this.listCampaignHistory();
  }

  listCampaignHistory() {
    if (this.props.isRbs) {
      this.listRbsCampaignHistory();
    } else if (this.props.isDs) {
      this.listDsCampaignHistory();
    } else if (this.props.isEmail) {
      this.listMailCampaignHistory();
    } else if (this.props.engagement) {
      this.listEngagementHistory();
    } else if (this.props.isPush) {
      this.listPushHistory();
    } else if (this.props.isPalantir) {
      this.listPalantirHistory();
    } else if (this.props.isFacetedSearch) {
      this.listFacetedSearchHistory();
    } else if (this.props.isExperiment) {
      this.listExperimentHistory();
    } else if (this.props.isRecommendationTemplate) {
      this.listRecommendationTemplateHistory();
    } else {
      this.listRecommendationHistory();
    }
  }

  listRecommendationTemplateHistory() {
    RecommendationTemplateService()
      .get({ id: this.props.campaignId, query: 'withOps=true' })
      .then(res => {
        this.setState(
          {
            campaignHistory: res,
            currentCampaign: res[0]?.recommendation_widget_template,
            modalTitle: document.querySelector('#modal-title').innerHTML,
          },
          function() {
            window.dispatchEvent(new Event('resize'));
          },
        );
      });
  }

  listExperimentHistory() {
    facetedSearchRequest(
      'experimentation/get',
      { instanceId: this.props.campaignId },
      '&withOps=true',
    )
      .get()
      .then(response => {
        this.setState(
          {
            campaignHistory: response.operations,
            currentCampaign: response.ab_test,
            modalTitle: document.querySelector('#modal-title').innerHTML,
          },
          function() {
            window.dispatchEvent(new Event('resize'));
          },
        );
      });
  }

  listRbsCampaignHistory() {
    getCampaignWithDetail(this.props.campaignId, response => {
      this.setState(
        {
          campaignHistory: response.operations,
          currentCampaign: response.campaign,
          modalTitle: document.querySelector('#modal-title').innerHTML,
        },
        function() {
          window.dispatchEvent(new Event('resize'));
        },
      );
    });
  }

  listDsCampaignHistory() {
    getRbsCampaignWithDetail(this.props.campaignId, response => {
      this.setState(
        {
          campaignHistory: response.operations,
          currentCampaign: response.campaign,
          modalTitle: document.querySelector('#modal-title').innerHTML,
        },
        function() {
          window.dispatchEvent(new Event('resize'));
        },
      );
    });
  }

  listPalantirHistory() {
    const opts = {};
    opts.withOps = true;
    getSearch(
      this.props.campaignId,
      response => {
        this.setState(
          {
            campaignHistory: response.operations,
            currentCampaign: response.campaign,
            modalTitle: document.querySelector('#modal-title').innerHTML,
          },
          function() {
            window.dispatchEvent(new Event('resize'));
          },
        );
      },
      opts,
    );
  }

  listRecommendationHistory() {
    getCampaignHistory(this.props.campaignId, response => {
      this.setState(
        {
          campaignHistory: response.operations,
          currentCampaign: response.campaign,
          modalTitle: document.querySelector('#modal-title').innerHTML,
        },
        function() {
          window.dispatchEvent(new Event('resize'));
        },
      );
    });
  }

  listEngagementHistory() {
    getEngagementHistory(this.props.campaignId, response => {
      this.setState(
        {
          campaignHistory: response.operations,
          currentCampaign: response.campaign,
          modalTitle: document.querySelector('#modal-title').innerHTML,
        },
        function() {
          window.dispatchEvent(new Event('resize'));
        },
      );
    });
  }

  listMailCampaignHistory() {
    getEmailCampaignHistory(this.props.campaignId, response => {
      this.setState(
        {
          campaignHistory: response.operations,
          currentCampaign: response.campaign,
          modalTitle: document.querySelector('#modal-title').innerHTML,
        },
        function() {
          window.dispatchEvent(new Event('resize'));
        },
      );
    });
  }

  listPushHistory() {
    getPushCampaignHistory(this.props.campaignId, response => {
      this.setState(
        {
          campaignHistory: response.operations,
          currentCampaign: response.campaign,
          modalTitle: document.querySelector('#modal-title').innerHTML,
        },
        function() {
          window.dispatchEvent(new Event('resize'));
        },
      );
    });
  }

  listFacetedSearchHistory() {
    const opts = {};
    opts.withOps = true;
    getFacetedSearchByInstanceId(this.props.campaignId, opts, response => {
      this.setState(
        {
          campaignHistory: response.operations,
          currentCampaign: response.campaign,
          modalTitle: document.querySelector('#modal-title').innerHTML,
        },
        function() {
          window.dispatchEvent(new Event('resize'));
        },
      );
    });
  }

  revertCampaign(item, e) {
    if (this.props.isRbs) {
      this.revertRbs(item, e);
    } else if (this.props.isDs) {
      this.revertDs(item, e);
    } else if (this.props.isEmail) {
      this.revertEmail(item, e);
    } else if (this.props.engagement) {
      this.revertEngagement(item, e);
    } else if (this.props.isPush) {
      this.revertPush(item, e);
    } else if (this.props.isFacetedSearch) {
      this.revertFacetedSearch(item, e);
    } else if (this.props.isPalantir) {
      this.revertPalantir(item, e);
    } else if (this.props.isRecommendationTemplate) {
      this.revertRecommendationTemplate(item, e);
    } else {
      this.revertRecommendation(item, e);
    }
  }

  revertRecommendation(item, e) {
    const campaignToRevert = item.campaign;
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('Do you want to revert campaign?'),
      onConfirm: () => {
        // update campaign here
        updateCampaign(campaignToRevert, response => {
          dialogActions.closeDialog();
          modalActions.closeModal();
          if (response.instanceId) {
            filterActions.updateFilters();
            notification({
              content: Notifications.CampaignReverted,
            });
          } else if (response.response) {
            notification({
              content: response.response.message,
              className: `notification-${response.response.status.toLowerCase()}`,
            });
          }
        });
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  revertRecommendationTemplate(item, e) {
    const campaignToRevert = item?.recommendation_widget_template;
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('Do you want to revert template?'),
      onConfirm: () => {
        RecommendationTemplateService()
          .updateWithInsideId({ body: campaignToRevert })
          .then(() => {
            notification({
              content: Notifications.CampaignReverted,
            });
          })
          .catch(response => {
            notification({
              content: response?.response?.message,
              className: `notification-${response?.response?.status?.toLowerCase()}`,
            });
          });
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  revertEngagement(item, e) {
    const campaignToRevert = item.campaign;
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('Do you want to revert campaign?'),
      onConfirm: () => {
        editCampaign(campaignToRevert)
          .then(() => {
            notification({
              content: Notifications.CampaignReverted,
            });
          })
          .catch(response => {
            notification({
              content: response.response.message,
              className: `notification-${response.response.status.toLowerCase()}`,
            });
          });
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  revertEmail(item, e) {
    const campaignToRevert = item.campaign;
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('Do you want to revert campaign?'),
      onConfirm: () => {
        updateEmailCampaign(campaignToRevert, response => {
          dialogActions.closeDialog();
          modalActions.closeModal();
          if (response) {
            notification({
              content: Notifications.CampaignReverted,
            });
          } else {
            notification({
              content: response.response.message,
              className: `notification-${response.response.status.toLowerCase()}`,
            });
          }
        });
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  revertRbs(item, e) {
    const campaignToRevert = item.campaign;
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('Do you want to revert campaign?'),
      onConfirm: () => {
        editRbsCampaign(campaignToRevert)
          .then(() => {
            notification({
              content: Notifications.CampaignReverted,
            });
          })
          .catch(response => {
            notification({
              content: response.message,
              className: `notification-${response.status.toLowerCase()}`,
            });
          });
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  revertFacetedSearch(item, e) {
    const campaignToRevert = item.campaign;
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('Do you want to revert campaign?'),
      onConfirm: () => {
        facetedRevertUpdate(campaignToRevert, campaignToRevert.instanceId)
          .then(() => {
            notification({
              content: Notifications.CampaignReverted,
            });
          })
          .catch(response => {
            notification({
              content: response.message,
              className: `notification-${response.status.toLowerCase()}`,
            });
          });
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  revertDs(item, e) {
    const campaignToRevert = item.campaign;
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('Do you want to revert campaign?'),
      onConfirm: () => {
        editDsCampaign(campaignToRevert)
          .then(() => {
            notification({
              content: Notifications.CampaignReverted,
            });
          })
          .catch(response => {
            notification({
              content: response.message,
              className: `notification-${response.status.toLowerCase()}`,
            });
          });
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  revertPush(item, e) {
    const campaignToRevert = item.campaign;
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('Do you want to revert campaign?'),
      onConfirm: () => {
        editPushCampaign(campaignToRevert)
          .then(() => {
            notification({
              content: Notifications.CampaignReverted,
            });
          })
          .catch(response => {
            notification({
              content: response.message,
              className: `notification-${response.status.toLowerCase()}`,
            });
          });
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  revertPalantir(item, e) {
    const campaignToRevert = item.campaign;
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('Do you want to revert campaign?'),
      onConfirm: () => {
        updatePalantirCampaign(campaignToRevert, response => {
          dialogActions.closeDialog();
          modalActions.closeModal();
          if (response) {
            notification({
              content: Notifications.CampaignReverted,
            });
          } else {
            notification({
              content: response.message,
              className: `notification-${response.status.toLowerCase()}`,
            });
          }
        });
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  compareCampaign(item) {
    const context = this;
    this.setState(
      {
        previousCampaign: item,
        isBack: false,
      },
      function() {
        modalActions.calculateModalHeight();
        window.dispatchEvent(new Event('resize'));
        const modalTitleEl = document.querySelector('#modal-title');
        modalTitleEl.innerHTML = `<a class="modal-back" id="modal-back-button"><i class="icon-chevron-left"></i></a>${modalTitleEl.innerHTML}`;
        const modalBackButton = document.querySelector('#modal-back-button');
        modalBackButton.addEventListener('click', function() {
          context.setState(
            {
              isBack: true,
            },
            function() {
              const modalTitleEl = document.querySelector('#modal-title');
              modalTitleEl.innerHTML = context.state.modalTitle;
            },
          );
        });
      },
    );
  }

  render() {
    return (
      <div>
        {this.state.isBack ? (
          <div style={{ minWidth: '760px' }}>
            <table className='data-table'>
              <thead>
                <tr>
                  <th>Modified By</th>
                  <th>Action</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {this.state?.campaignHistory?.map(historyItem => {
                  let actionColor = '#9f9798';
                  if (historyItem.operation.type === 'WENT_LIVE') {
                    actionColor = '#59c471';
                  } else if (historyItem.operation.type === 'BACK_TO_TEST') {
                    actionColor = '#cd3d3e';
                  } else if (historyItem.operation.type === 'ADDED') {
                    actionColor = '#7da6d1';
                  }
                  return (
                    <tr key={Math.random()}>
                      <td>{historyItem.operation.username}</td>
                      <td style={{ color: actionColor, fontWeight: 'bold' }}>
                        {historyItem.operation.type}
                      </td>
                      <td>{reformatDate(historyItem.operation.time)}</td>
                      <td>
                        {historyItem?.campaign ||
                        historyItem?.recommendation_widget_template ? (
                          <nav className='account-nav action-nav'>
                            <Icon name='menu' />
                            <ul className='action-nav-popup'>
                              <div>
                                {isSuperUser() ? (
                                  <li>
                                    <a
                                      className='action-nav-popup-item'
                                      onClick={this.revertCampaign.bind(
                                        this,
                                        historyItem,
                                      )}
                                    >
                                      <Icon name='refresh' /> {t('Revert')}
                                    </a>
                                  </li>
                                ) : (
                                  ''
                                )}
                                <li>
                                  <a
                                    className='action-nav-popup-item'
                                    onClick={this.compareCampaign.bind(
                                      this,
                                      historyItem,
                                    )}
                                  >
                                    <Icons name='compare' color='#fff' />
                                    {t('Compare')}
                                  </a>
                                </li>
                              </div>
                            </ul>
                          </nav>
                        ) : (
                          '-  '
                        )}
                      </td>
                      <td />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <CampaignDiff
              firstInput={this.state.currentCampaign}
              secondInput={
                this.state.previousCampaign.campaign ||
                this.state.previousCampaign.recommendation_widget_template
              }
              operationId={
                this.state.previousCampaign.operation.id ||
                this.state.previousCampaign.operation.operationId
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default CampaignHistoryModal;
