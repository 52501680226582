import React from 'react';
import GaBlock from '../components/gaanalytics/gaanalytics.block';
import { clone } from '../system/object';
import { numericDiffRatio } from '../system/ratio';
import Currency from '../components/currency';
import { toLocaleStringDigits } from '../system/string';

const analyticsType = {
  all: 'Overall',
  mobile: 'Mobile (Including Tablets)',
  pc: 'PC (Desktops and Laptops)',
};

const staticTableHead = [
  'Metrics',
  'Segmentify',
  'Google Analytics 4',
  'Diff',
  '%',
];

const mapping = [
  { metrics: 'Revenue', x: 'purchase:amount' },
  { metrics: 'Purchases', x: 'purchase:count' },
  { metrics: 'Products', x: 'purchase:items' },
  { metrics: 'Avg Order Quantity', x: 'avgBasketSize' },
  { metrics: 'Avg Order Value', x: 'avgBasketAmount' },
  { metrics: 'Page View', x: 'page:ALL', isSpecial: 'all' },
  { metrics: 'Page View', x: 'page:ALL', isSpecial: 'pc' },
  { metrics: 'Page View', x: 'page:ALL', isSpecial: 'mobile' },
  { metrics: 'Total Users', x: 'unique:visitor' },
  { metrics: 'Sessions', x: 'unique:session' },
  { metrics: 'Page/Session', x: 'pagesPerSession' },
  { metrics: 'Push Clicks', x: 'Notification [clicked]' },
  { metrics: 'Push Revenue', x: 'Purchase Amount:push' },
  { metrics: 'Push Purchases', x: 'Purchases:push' },
  { metrics: 'Push Products', x: 'Purchased Items:push' },
  { metrics: 'Mail Clicks', x: 'Email [clicked]' },
  { metrics: 'Mail Revenue', x: 'Purchase Amount:mail' },
  { metrics: 'Mail Products', x: 'Purchased Items:mail' },
  { metrics: 'Mail Purchases', x: 'Purchases:mail' },
];

class DashboardAnalytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comparisonArray: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.segmentifyAnalytics !== null &&
      this.props.googleAnalytics !== null
    ) {
      this.listStatAnalytics();
    }
    if (this.props.selectedModule !== nextProps.selectedModule) {
      this.listStatAnalytics();
    }
  }

  listStatAnalytics() {
    if (
      this.props.segmentifyAnalytics.length > 0 &&
      this.props.googleAnalytics.length > 0
    ) {
      const segmentifyList = this.props.segmentifyAnalytics.filter(
        analytics =>
          analytics.t !== 'OTHER' ||
          analytics.z !== 'push' ||
          analytics.z !== 'mail',
      );
      const googleList = this.props.googleAnalytics.filter(
        analytics =>
          analytics.t !== 'OTHER' ||
          analytics.z !== 'push' ||
          analytics.z !== 'mail',
      );

      const copyMapping = clone(mapping);

      const comparisonArray = [];
      copyMapping.map(maps => {
        let segmentify;
        let google;
        Object.keys(analyticsType).map(analyze => {
          if (
            (maps.isSpecial !== undefined && maps.isSpecial === analyze) ||
            maps.isSpecial === undefined
          ) {
            segmentify = segmentifyList.find(
              analytics =>
                analytics.x === maps.x && analytics.t.toLowerCase() === analyze,
            );
            google = googleList.find(
              analytics =>
                analytics.x === maps.x && analytics.t.toLowerCase() === analyze,
            );

            if (segmentify !== undefined && google !== undefined) {
              const comparionObj = {};

              comparionObj.metrics = maps.metrics;
              comparionObj.segmentify = segmentify.y.toFixed(2);
              comparionObj.google = google.y.toFixed(2);
              comparionObj.diff = (segmentify.y - google.y).toFixed(2);
              comparionObj.ratio =
                google.y > 0
                  ? (parseFloat(segmentify.y.toFixed(2)) -
                      parseFloat(google.y.toFixed(2))) /
                    parseFloat(google.y.toFixed(2))
                  : '-';
              comparionObj.type = analyze;
              comparisonArray.push(comparionObj);
            }
          }
        });
      });
      this.setState({ comparisonArray });
    }
  }

  getTypeList(type) {
    if (this.state.comparisonArray.length > 0) {
      const typeList = this.state.comparisonArray.filter(
        comparison => comparison.type === type,
      );
      return typeList;
    }
  }

  render() {
    const { account } = this.props;
    const googleTimeZone =
      account.googleAnalytics.timeZone !== undefined ||
      account.ga4.timeZone !== undefined
        ? account.googleAnalytics.timeZone || account.ga4.timeZone
        : '?';
    const accountTimeZone = account.timeZone;
    const timeZoneMeta =
      `Account Timezone: ${accountTimeZone}    |     ` +
      `Google Timezone: ${googleTimeZone}`;
    return (
      <>
        {Object.keys(analyticsType).map(type => {
          return (
            <GaBlock title={analyticsType[type]} key={type} meta={timeZoneMeta}>
              <div className='aawp'>
                <div className='aawp-tb aawp-tb--desktop'>
                  <div className='aawp-tb__row'>
                    {staticTableHead.map((head, index) => {
                      if (index === 0)
                        return <div className='aawp-tb__head'>{head}</div>;
                      return (
                        <div className='aawp-tb__head aawp-tb__head__center'>
                          {head}
                        </div>
                      );
                    })}
                  </div>

                  {this.state.comparisonArray &&
                    this.state.comparisonArray.length > 0 &&
                    this.getTypeList(type).map(item => {
                      return (
                        <div className='aawp-tb__row'>
                          {Object.keys(item).map(key => {
                            if (key === 'metrics')
                              return (
                                <div className='aawp-tb__head'>{item[key]}</div>
                              );

                            if (
                              (key === 'segmentify' ||
                                key === 'google' ||
                                key === 'diff') &&
                              (item.metrics.toString().includes('Rev') ||
                                item.metrics.toString().includes('Amount'))
                            ) {
                              return (
                                <div className='aawp-tb__data aawp-tb__data--type-custom_text'>
                                  <div className='aawp-tb-product-data-custom_text'>
                                    <Currency
                                      currency={item[key]}
                                      accountCurrency={account.mainCurrency}
                                    />
                                  </div>
                                </div>
                              );
                            }
                            if (
                              key === 'segmentify' ||
                              key === 'google' ||
                              key === 'diff'
                            ) {
                              return (
                                <div className='aawp-tb__data aawp-tb__data--type-custom_text'>
                                  <div className='aawp-tb-product-data-custom_text'>
                                    {toLocaleStringDigits(item[key])}
                                  </div>
                                </div>
                              );
                            }

                            if (key === 'ratio') {
                              return (
                                <div className='aawp-tb__data aawp-tb__data--type-custom_text'>
                                  <div className='aawp-tb-product-data-custom_text'>
                                    {item[key] !== '-'
                                      ? numericDiffRatio(item[key], 2)
                                      : item[key]}
                                    {item[key] < 0 && (
                                      <span style={{ color: '#cc4331' }}>
                                        {' '}
                                        ▼
                                      </span>
                                    )}
                                    {item[key] > 0 && (
                                      <span style={{ color: 'rgb(0, 0, 255)' }}>
                                        {' '}
                                        ▲
                                      </span>
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      );
                    })}
                </div>
              </div>
            </GaBlock>
          );
        })}
      </>
    );
  }
}

export default DashboardAnalytics;
