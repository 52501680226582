import React from 'react';

class AudienceIcon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: '16px',
      height: '16px',
      color: '#eee',
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + 'px' : this.state.width;
    let height = this.props.height
      ? this.props.height + 'px'
      : this.state.height;
    let color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 44 50'
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <path d='M20.4861 38.5416V41.3194C19.0972 41.6666 17.0139 41.6666 15.2778 41.6666C5.90277 41.6666 0 37.5 0 31.9444V27.7778C0 24.6528 2.43055 22.2222 5.55555 22.2222H25C28.125 22.2222 29.9381 23.7269 29.9381 26.8519C29.9381 26.8519 20.8333 28.125 20.4861 38.5416ZM40.2777 27.7778H43.0555C43.0555 24.6528 40.625 22.2222 37.5 22.2222H29.8611C30.5555 22.9167 31.25 23.9583 31.5972 25H37.5C38.8889 25 40.2777 26.3889 40.2777 27.7778ZM15.2778 0C20.4861 0 25 4.51389 25 9.72222C25 14.9305 20.4861 19.4444 15.2778 19.4444C10.0694 19.4444 5.55555 14.9305 5.55555 9.72222C5.55555 4.51389 10.0694 0 15.2778 0ZM33.3333 5.55555C37.1527 5.55555 40.2777 8.68055 40.2777 12.5C40.2777 16.3194 37.1527 19.4444 33.3333 19.4444C29.5139 19.4444 26.3889 16.3194 26.3889 12.5C26.3889 8.68055 29.5139 5.55555 33.3333 5.55555Z' />
        <path d='M33.0244 40.8595C34.2708 40.8595 35.1018 40.0285 35.1018 38.7821C35.1018 37.5356 34.2708 36.7047 33.0244 36.7047C31.7779 36.7047 30.947 37.5356 30.947 38.7821C30.947 40.0285 31.7779 40.8595 33.0244 40.8595ZM33.0244 32.9654C29.7006 32.9654 26.7922 35.8737 26.7922 39.1975C26.7922 42.5214 29.7006 45.4297 33.0244 45.4297C36.3482 45.4297 39.2566 42.5214 39.2566 39.1975C39.2566 35.4582 36.3482 32.9654 33.0244 32.9654ZM28.8696 39.1975C28.8696 37.1201 30.5315 35.0427 33.0244 35.0427C35.1018 35.0427 37.1792 36.7047 37.1792 39.1975C37.1792 41.6904 35.5173 43.3523 33.0244 43.3523C30.5315 43.3523 28.8696 41.2749 28.8696 39.1975ZM33.0244 28.3951C27.2077 28.3951 22.2219 32.9654 22.2219 39.1975C22.2219 45.0142 26.7922 50 33.0244 50C38.8411 50 43.8268 45.4297 43.8268 39.1975C43.4114 33.3808 38.8411 28.3951 33.0244 28.3951ZM24.7148 39.1975C24.7148 34.6273 28.4541 30.888 33.0244 30.888C37.5947 30.888 41.334 34.6273 41.334 39.1975C41.334 43.7678 37.5947 47.5071 33.0244 47.5071C28.4541 47.5071 24.7148 43.7678 24.7148 39.1975Z' />
      </svg>
    );
  }
}

export default AudienceIcon;
