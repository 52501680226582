export const resolveFilterSchema = ({
  rawData,
  productFilterSchema,
  isSortable,
}) => {
  if (!rawData) return;
  const schema = productFilterSchema;
  // eslint-disable-next-line consistent-return
  if (!schema) return rawData;

  const filterSchema = schema.map(({ value }) => {
    return {
      value,
    };
  });

  const filteredArr = Object.entries(rawData).map(([key, value]) => {
    return filterSchema.map(({ value: filterValue }) => {
      if (key === filterValue) {
        return {
          [key]: value,
        };
      }
      return null;
    });
  });

  const sanitize = filteredArr
    .flatMap(item => item)
    .filter(Boolean)
    .map(item => item);

  if (isSortable) {
    sanitize.sort((a, b) => {
      const keyA = Object.keys(a)[0];
      const keyB = Object.keys(b)[0];
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  }

  // eslint-disable-next-line consistent-return
  return Object.assign({}, ...sanitize);
};
