/**
 * Created by mehmetyurtar on 3/23/18.
 */
import React from "react";

class IconPlusCircle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#CED9E0"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Wizard-3"
            transform="translate(-1039.000000, -499.000000)"
            fill={this.state.color}
            fillRule="nonzero"
          >
            <g
              id="Recommendation-Types"
              transform="translate(107.000000, 279.000000)"
            >
              <g id="Recommendation-Algorithms">
                <g
                  id="Smart-Offers"
                  transform="translate(25.000000, 198.000000)"
                >
                  <g id="Group" transform="translate(25.000000, 16.000000)">
                    <g
                      id="Duplicate/Delete"
                      transform="translate(882.000000, 6.000000)"
                    >
                      <g id="ui-32px-outline-1_circle-add">
                        <path
                          d="M23,15 L17,15 L17,9 C17,8.447 16.552,8 16,8 C15.448,8 15,8.447 15,9 L15,15 L9,15 C8.448,15 8,15.447 8,16 C8,16.553 8.448,17 9,17 L15,17 L15,23 C15,23.553 15.448,24 16,24 C16.552,24 17,23.553 17,23 L17,17 L23,17 C23.552,17 24,16.553 24,16 C24,15.447 23.552,15 23,15 Z"
                          id="Shape"
                        />
                        <path
                          d="M16,0 C7.178,0 0,7.178 0,16 C0,24.822 7.178,32 16,32 C24.822,32 32,24.822 32,16 C32,7.178 24.822,0 16,0 Z M16,30 C8.28,30 2,23.72 2,16 C2,8.28 8.28,2 16,2 C23.72,2 30,8.28 30,16 C30,23.72 23.72,30 16,30 Z"
                          id="Shape"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconPlusCircle;
