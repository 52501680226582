/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from 'react';
import './ShopTheLook.scss';
import { modalActions } from '../../../actions';
import { t } from '../../../system/ui';
import { Context as shopTheLookContext } from './store/shopTheLookProvider';
import { Context as PaginationContext } from '../../../components/pagination/store/paginationProvider';
import { getSTLList } from './services/shopTheLookServices';
import {
  SFYHeader,
  SFYImportExport,
  SFYList,
  SFYNoResult,
  SFYWrapper,
} from '../../../components/sfy-components/module-exports';
import SFYPagination from '../../../components/pagination/SFYPagination';
import { actions, breadCrumbOptions } from './constants/constants';
import { setTitle } from '../../../system/document';
import { importExportOptions } from '../automated-bundles/constants/constants';
import { getRestEndPoint } from '../../../system/api';
import {
  getAccount,
  getApiKey,
  getRegion,
  getSwitchAccountUser,
  getToken,
  isMultiAccountUser,
  isSwitchAccount,
} from '../../../modules/auth/user';
import STLImport from './modals/shopTheLookImport';

/**
 * @name ShopTheLook view holds shop the look components
 * @description shop the look components responsible from pagination and path to any related page
 * @param {record} location - location object
 * @returns {JSX.Element} - ShopTheLook component
 */
const ShopTheLook = ({ location }) => {
  const {
    state: { pagination, searchQueryString },
    paginationSetter,
    searchQuerySetter,
  } = useContext(PaginationContext);

  const {
    state: { list },
    setSTLList,
  } = useContext(shopTheLookContext);

  const listInvoker = () => {
    getSTLList({
      pageNumber: pagination.pageNumber,
      payloadSize: pagination.payloadSize,
      searchQueryString,
    }).then(({ bundleGroups, totalRecordSize, pageNo, pageSize }) => {
      setSTLList(bundleGroups);
      paginationSetter({
        totalRecords: totalRecordSize,
        pageNumber: pageNo,
        payloadSize: pageSize,
      });
    });
  };

  const openSTLImportModal = stlListInvoker => {
    const content = () => <STLImport listInvoker={stlListInvoker} />;

    modalActions.openModal({
      title: t('Upload Your File'),
      className: 'bundle-product-import-modal',
      content,
      dynamicHeight: true,
    });
  };

  const getImportExportOptions = () => {
    return importExportOptions.map(option => ({
      ...option,
      action: () => {
        if (option.label === 'Import') {
          openSTLImportModal(listInvoker);
        } else if (option.label === 'Export') {
          const endpoint = `${getRestEndPoint(
            'stl/export',
          )}?apiKey=${getApiKey()}`;
          let exportUrl = `${endpoint}&authToken=${getToken()}`;
          if (isSwitchAccount() && !isMultiAccountUser()) {
            exportUrl += `&switchUser=${getSwitchAccountUser(true).username}`;
          }
          if (isMultiAccountUser() && isSwitchAccount()) {
            exportUrl += `&switchAccount=${getAccount().accountId.trim()}`;
          }
          exportUrl += `&switchRegion=${getRegion()}`;
          window.open(exportUrl);
        }
      },
    }));
  };

  useEffect(() => {
    setTitle('Shop The Look');
    listInvoker({
      pageNumber: pagination.pageNumber,
      payloadSize: pagination.payloadSize,
      searchQueryString,
    });
  }, [pagination.pageNumber, pagination.payloadSize, searchQueryString]);

  // clear pagination after route change

  return (
    <>
      <SFYHeader
        pageTitle='Shop The Look'
        breadCrumbOptions={breadCrumbOptions}
      />

      <SFYWrapper inlineStyle={{ position: 'relative' }}>
        <SFYImportExport
          buttons={getImportExportOptions()}
          customWrapperClass='stl-export-wrapper'
        />
        {list && list.length > 0 ? (
          <>
            <SFYPagination
              location={location}
              pagination={pagination}
              linkToRoute='/dynamic_bundle/shop_the_look/add'
              stateSetter={searchQuerySetter}
              state={searchQueryString}
              paginationSetter={paginationSetter}
              text='Create a New Bundle'
            />
            <SFYList
              linkToDetail='/dynamic_bundle/shop_the_look/edit'
              listName='Bundles'
              listData={list}
              actions={actions}
              totalRecords={pagination.totalRecords}
              listInvoker={listInvoker}
            />
          </>
        ) : searchQueryString !== '' && list.length === 0 ? (
          <>
            <SFYPagination
              location={location}
              pagination={pagination}
              linkToRoute='/dynamic_bundle/shop_the_look/add'
              stateSetter={searchQuerySetter}
              state={searchQueryString}
              paginationSetter={paginationSetter}
            />
            <SFYNoResult linkToCreate='/dynamic_bundle/shop_the_look/add' />
          </>
        ) : (
          <SFYNoResult linkToCreate='/dynamic_bundle/shop_the_look/add' />
        )}
      </SFYWrapper>
    </>
  );
};

export default ShopTheLook;
