import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.replace('px', '')}px`;
};

const IconPlusThin = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 0C10.8284 0 11.5 0.67158 11.5 1.5V8.5H18.5C19.3284 8.5 20 9.1716 20 10C20 10.8284 19.3284 11.5 18.5 11.5H11.5V18.5C11.5 19.3284 10.8284 20 10 20C9.1716 20 8.5 19.3284 8.5 18.5V11.5H1.5C0.67158 11.5 0 10.8284 0 10C0 9.1716 0.67158 8.5 1.5 8.5H8.5V1.5C8.5 0.67158 9.1716 0 10 0Z'
        fill={color}
      />
    </svg>
  );
};

IconPlusThin.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

IconPlusThin.defaultProps = {
  width: '16px',
  height: '16px',
  color: '#787878',
};

export default IconPlusThin;
