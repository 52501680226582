import moment from 'moment';
import React, { useEffect, useReducer, useRef } from 'react';
import { uiActions } from '../../../actions';
import {
  reducerGroupStateChange,
  reducerStateChange,
} from '../../../actions/edit-account';
import {
  getOwners,
  getUserMailList,
  updateAccount,
} from '../../../modules/account/ajax';
import {
  getCurrentEnvironment,
  isMasterUser,
} from '../../../modules/auth/user';
import {
  editAccountReducer,
  initialState,
} from '../../../reducers/admin/edit-account';
import { t } from '../../../system/ui';

import BaseDetails from './BaseDetails';
import Features from './Features';
import Modules from './Modules';
import Owners from './Owners';
import WeeklyReport from './WeeklyReport';

/**
 * This component helps to show and edit account details.
 * @param {Object} account
 * @param {Function} onUpdate
 * @returns {JSX.Element|null}
 */
const EditUser = ({ account, onUpdate }) => {
  const formRef = useRef(null);
  const [state, dispatch] = useReducer(editAccountReducer, initialState);

  const reducerStateChangeHandler = (stateName, newState) => {
    dispatch(reducerStateChange(stateName, newState));
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = e => {
    e.preventDefault();

    if (e.target.innerHTML.indexOf('has-error') > -1) {
      return null;
    }

    uiActions.isLoading();

    const {
      domain,
      description,
      person,
      email,
      phone,
      features,
      cartUrl,
      mobileCartUrl,
      ownership,
      global,
      incremental,
      variantEnabled,
      recommendVariants,
      recommendVariantsForSearch,
    } = state;
    const tier = formRef.current.querySelector('[name="tier"]')
      ? formRef.current.querySelector('[name="tier"]').value
      : '';
    const type = formRef.current.querySelector('[name="type"]').value;
    const version = 'V2';
    const mainLanguage = formRef.current.querySelector('[name="mainLanguage"]')
      .value;
    const mainRegion = formRef.current.querySelector('[name="mainRegion"]')
      .value;
    const allowedUrlDomain = formRef.current.querySelector(
      '[name="allowedUrlDomain"]',
    ).value;

    const timeZone =
      formRef.current.querySelectorAll('[name="timeZone"]').length !== 0
        ? formRef.current.querySelectorAll('[name="timeZone"]')[0].value
        : '';
    const additionalLanguages = formRef.current.querySelectorAll(
      '[name="additionalLanguages"]',
    );
    const additionalCurrencyList = formRef.current.querySelectorAll(
      '[name="additionalCurrencyList"]',
    );
    const additionalRegions = formRef.current.querySelectorAll(
      '[name="additionalRegions"]',
    );
    const allowedImageDomains = formRef.current.querySelectorAll(
      '[name="allowedImageDomains"]',
    );

    const insightsProductCount =
      formRef.current.querySelector('[name="insightsProductCount"]').value ||
      10;

    const insightsProductExportCount =
      formRef.current.querySelector('[name="insightsProductExportCount"]')
        .value || 10;

    const additionalDomains = state.additionalDomains.map(
      additionalDomain => additionalDomain.name,
    );

    const weeklyReportMails = [...state.weeklyReportMails];

    const pageUrlQueryParamWhitelist = state.pageUrlQueryParamWhitelist.map(
      pageUrlQueryParamWhitelistItem => pageUrlQueryParamWhitelistItem.name,
    );

    const tempAccount = {
      ...account,
      domain,
      tier,
      type,
      panelVersion: version,
      ownership,
      description,
      mainLanguage,
      mainRegion,
      allowedUrlDomain,
      insightsProductCount: Number(insightsProductCount),
      insightsProductExportCount: Number(insightsProductExportCount),
      contact: { person, email, phone },
      features,
      cartUrl,
      mobileCartUrl,
      timeZone,
      sector: state.betting ? 'BETTING' : 'OTHER',
      additionalDomains,
      pageUrlQueryParamWhitelist,
      global,
      incremental,
      importantDates: {
        freeTrialStart: state.startDate.valueOf(),
        freeTrialEnd: state.endDate.valueOf(),
      },
      weeklyReportMails,
      variantEnabled,
      recommendVariants,
      recommendVariantsForSearch,
    };

    tempAccount.additionalLanguages = [];
    [].forEach.call(additionalLanguages, lang => {
      tempAccount.additionalLanguages.push(lang.value);
    });

    tempAccount.additionalCurrencyList = [];
    [].forEach.call(additionalCurrencyList, curr => {
      tempAccount.additionalCurrencyList.push(curr.value);
    });

    tempAccount.additionalRegions = [];
    [].forEach.call(additionalRegions, curr => {
      tempAccount.additionalRegions.push(curr.value);
    });
    tempAccount.allowedImageDomains = [];
    [].forEach.call(allowedImageDomains, curr => {
      tempAccount.allowedImageDomains.push(curr.value);
    });

    updateAccount(tempAccount, response => {
      let notificationClassName;

      const notificationContent = () => <div>{response.message}</div>;

      if (response.status !== 'OK') {
        notificationClassName = 'notification-fail';
      }

      uiActions.showNotification({
        content: notificationContent,
        className: notificationClassName,
      });
      uiActions.isLoaded();

      if (response.status === 'OK') {
        onUpdate(tempAccount);
      }
    });
  };

  const setAccountData = () => {
    if (!account) {
      return null;
    }

    const newStateData = {
      startDate: moment(account.importantDates.freeTrialStart),
      endDate: moment(account.importantDates.freeTrialEnd),
      domain: account.domain,
      person: account.contact.person,
      email: account.contact.email,
      phone: account.contact.phone,
      description: account.description,
      features: account.features,
      cartUrl: account.cartUrl,
      mobileCartUrl: account.mobileCartUrl,
      timeZone: account.timeZone,
      mainRegion: account.mainRegion,
      allowedUrlDomain: account.allowedUrlDomain,
      betting: account.sector !== 'OTHER',
      ownership: account.ownership,
      global: { ...account.global },
      percentage: Object.assign(10, account.global.globalPercentage),
      globalEnabled: Object.assign(false, account.global.globalEnabled),
      incremental: account.incremental,
      variantEnabled: account.variantEnabled,
      recommendVariants: account.recommendVariants,
      recommendVariantsForSearch: account.recommendVariantsForSearch,
    };

    return dispatch(reducerGroupStateChange(newStateData));
  };

  const getAccountOwners = () => {
    getOwners(response => {
      if (response) {
        reducerStateChangeHandler('owners', response);
      }
    });
  };

  const getAccountUserMailList = () => {
    const options = {
      apiKey: account.apiKey,
      mainUser: account.contact.email,
    };
    getUserMailList(options, response => {
      if (response) {
        reducerStateChangeHandler('allUserMails', response);
      }
    });
  };

  const listAdditionalDomains = () => {
    const additionalDomains = account.additionalDomains
      ? account.additionalDomains.map((domain, index) => ({
          id: index,
          name: domain,
        }))
      : [];

    reducerStateChangeHandler('additionalDomains', additionalDomains);
  };

  const listPageUrlQueryParamWhitelist = () => {
    const pageUrlQueryParamWhitelist = account.pageUrlQueryParamWhitelist
      ? account.pageUrlQueryParamWhitelist.map((listItem, index) => ({
          id: index,
          name: listItem,
        }))
      : [];

    reducerStateChangeHandler(
      'pageUrlQueryParamWhitelist',
      pageUrlQueryParamWhitelist,
    );
  };

  useEffect(() => {
    listAdditionalDomains();
    listPageUrlQueryParamWhitelist();
    getAccountOwners();
    setAccountData();
    getAccountUserMailList();
  }, []);

  return (
    <form className='edit-user' onSubmit={handleSubmit} ref={formRef}>
      <ol className='form-elements'>
        <BaseDetails
          account={account}
          betting={state.betting}
          additionalDomains={state.additionalDomains}
          pageUrlQueryParamWhitelist={state.pageUrlQueryParamWhitelist}
          startDate={state.startDate}
          endDate={state.endDate}
          reducerStateChangeHandler={reducerStateChangeHandler}
        />
        <Modules
          account={account}
          features={state.features}
          reducerStateChangeHandler={reducerStateChangeHandler}
          readonly={!isMasterUser() && getCurrentEnvironment() === 'prod'}
        />
        <Features
          account={account}
          stateAll={state}
          stateFeatures={state.features}
          global={state.global}
          globalEnabled={state.globalEnabled}
          incremental={state.incremental}
          reducerStateChangeHandler={reducerStateChangeHandler}
        />
        <Owners
          account={account}
          ownership={state.ownership}
          stateOwners={state.owners}
          reducerStateChangeHandler={reducerStateChangeHandler}
        />
        <WeeklyReport
          account={account}
          userMailList={state.allUserMails}
          selectedUserMails={state.weeklyReportMails}
          reducerStateChangeHandler={reducerStateChangeHandler}
        />
        <li className='buttons'>
          <button type='submit' className='tertiary-action'>
            {t('Update')}
          </button>
        </li>
      </ol>
    </form>
  );
};

export default EditUser;
