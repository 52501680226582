/**
 * @author Bilal Cinarli
 */

import { createConstants } from '../../system/store';

export default createConstants(
  'IS_LOADING',
  'IS_LOADED',
  'HAS_SUBMENU',
  'HAS_BREADCRUMB',
  'HIDE_BREADCRUMB',
  'ADD_PAGE_CLASS',
  'REMOVE_PAGE_CLASS',
  'UPDATE_BROWSER_TITLE',
  'VALIDATE_FORMS',
  'VALIDATED_TRIGGERED',
  'VALIDATING_TRIGGERED',
  'VALIDATED_FORMS',
  'UPDATE_LANGUAGE',
  'FORM_SAVED',
  'FORM_EDITED',
);
