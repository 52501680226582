import { getRestEndPoint } from '../../../../system/api';

const tools = {
  form: {
    enabled: true,
  },
  timer: {
    enabled: false,
  },
  html: {
    properties: {
      html: {
        editor: {
          widgetParams: {
            codeMirrorOptions: {
              lineWrapping: true,
              lineNumbers: true,
            },
          },
        },
      },
    },
  },
  features: {
    audit: true,
    imageEditor: true,
    userUploads: true,
    undoRedo: true,
    stockImages: {
      enabled: true,
      safeSearch: true,
    },
    textEditor: {
      spellChecker: true,
    },
    svgImageUpload: true,
  },
  tabs: {
    content: {
      enabled: false,
    },
  },
  'custom#segmentify_reco': {
    enabled: true,
    properties: {
      productIndex: {
        editor: {
          data: {
            options: [
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
              { label: '4', value: 4 },
              { label: '5', value: 5 },
              { label: '6', value: 6 },
              { label: '7', value: 7 },
              { label: '8', value: 8 },
              { label: '9', value: 9 },
            ],
          },
        },
      },
      rowCount: {
        editor: {
          data: {
            options: [
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
              { label: '4', value: 4 },
              { label: '5', value: 5 },
              { label: '6', value: 6 },
            ],
          },
        },
      },
      columnCount: {
        editor: {
          data: {
            options: [
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
            ],
          },
        },
      },
      productNameLines: {
        editor: {
          data: {
            options: [
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
            ],
          },
        },
      },
      productBrandLines: {
        editor: {
          data: {
            options: [
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
            ],
          },
        },
      },
      productDescriptionLines: {
        editor: {
          data: {
            options: [
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
            ],
          },
        },
      },
      priceDisplayAxis: {
        editor: {
          data: {
            options: [
              { label: 'Horizontal', value: 'horizontal' },
              { label: 'Vertical', value: 'vertical' },
            ],
          },
        },
      },
      titleAlign: {
        editor: {
          data: {
            options: [
              { label: 'Center', value: 'center' },
              { label: 'Left', value: 'left' },
              { label: 'Right', value: 'right' },
              { label: 'Justified', value: 'justify' },
            ],
          },
        },
      },
      discountType: {
        editor: {
          data: {
            options: [
              { label: "Don't Show", value: 'none' },
              { label: 'Percentage', value: 'percentage' },
            ],
          },
        },
      },
      productButtonBorderStyle: {
        editor: {
          data: {
            options: [
              { label: 'Solid', value: 'solid' },
              { label: 'Dashed', value: 'dashed' },
              { label: 'Dotted', value: 'dotted' },
              { label: 'Double', value: 'double' },
              { label: 'Groove', value: 'groove' },
              { label: 'Ridge', value: 'ridge' },
              { label: 'Inset', value: 'inset' },
              { label: 'Outset', value: 'outset' },
              { label: 'None', value: 'none' },
              { label: 'Hidden', value: 'hidden' },
            ],
          },
        },
      },
      productPreset: {
        editor: {
          data: {
            options: [
              { label: 'Vertical - Image over Text', value: 'vertical' },
              { label: 'Horizontal - Image Beside Text', value: 'horizontal' },
              { label: 'Custom Design', value: 'custom' },
            ],
          },
        },
      },
    },
  },
  'custom#product_tool': {
    enabled: true,
    data: {
      products: [],
    },
    properties: {
      productLibrary: {
        editor: {
          data: {
            fetchConfig: {
              url: `${getRestEndPoint('search/product')}/`,
            },
          },
        },
      },
      productContainerBorderStyle: {
        editor: {
          data: {
            options: [
              { label: 'Solid', value: 'solid' },
              { label: 'Dashed', value: 'dashed' },
              { label: 'Dotted', value: 'dotted' },
              { label: 'Double', value: 'double' },
              { label: 'Groove', value: 'groove' },
              { label: 'Ridge', value: 'ridge' },
              { label: 'Inset', value: 'inset' },
              { label: 'Outset', value: 'outset' },
              { label: 'None', value: 'none' },
              { label: 'Hidden', value: 'hidden' },
            ],
          },
        },
      },
      productNameLines: {
        editor: {
          data: {
            options: [
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
            ],
          },
        },
      },
      productBrandLines: {
        editor: {
          data: {
            options: [
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
            ],
          },
        },
      },
      productDescriptionLines: {
        editor: {
          data: {
            options: [
              { label: '1', value: 1 },
              { label: '2', value: 2 },
              { label: '3', value: 3 },
              { label: '4', value: 4 },
              { label: '5', value: 5 },
              { label: '6', value: 6 },
              { label: '7', value: 7 },
              { label: '8', value: 8 },
              { label: '9', value: 9 },
              { label: '10', value: 10 },
            ],
          },
        },
      },
      priceDisplayAxis: {
        editor: {
          data: {
            options: [
              { label: 'Horizontal', value: 'horizontal' },
              { label: 'Vertical', value: 'vertical' },
            ],
          },
        },
      },
      discountType: {
        editor: {
          data: {
            options: [
              { label: "Don't Show", value: 'none' },
              { label: 'Percentage', value: 'percentage' },
            ],
          },
        },
      },
      productButtonBorderStyle: {
        editor: {
          data: {
            options: [
              { label: 'Solid', value: 'solid' },
              { label: 'Dashed', value: 'dashed' },
              { label: 'Dotted', value: 'dotted' },
              { label: 'Double', value: 'double' },
              { label: 'Groove', value: 'groove' },
              { label: 'Ridge', value: 'ridge' },
              { label: 'Inset', value: 'inset' },
              { label: 'Outset', value: 'outset' },
              { label: 'None', value: 'none' },
              { label: 'Hidden', value: 'hidden' },
            ],
          },
        },
      },
    },
  },
};

export { tools };
