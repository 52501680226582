import React, { useEffect, useRef, useState } from "react";

import { modalActions, uiActions } from "../../actions";
import { editMultiUser, getUserAccounts } from "../../modules/account/ajax";
import { t } from "../../system/ui";
import UserAccountsList from "./multi-accountselect";

/**
 * This component is used to edit multi - user accounts.
 * Only will appear if isAdmin is true.
 * route for this component is /user/users
 * @param {Object} props - The props passed to the component.
 * */

const EditUser = ({ user, userToEdit, context }) => {
  const [_switchableAccounts, set_SwitchableAccounts] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [switchableAccounts, setSwitchableAccounts] = useState([]);

  const formRef = useRef();

  const { isLoaded, isLoading, showNotification } = uiActions;
  const { closeModal } = modalActions;

  useEffect(() => {
    isLoaded();
    getUserAccounts(
      { multiUserAccountId: user.account.accountId },
      response => {
        isLoaded();
        if (response) {
          const userAccountIds = userToEdit.switchableAccounts
            ? userToEdit.switchableAccounts.map(account => account.accountId)
            : [];
          const selectedAccountsObj = response.filter(
            account => userAccountIds.indexOf(account.accountId) > -1,
          );
          setSwitchableAccounts(response);
          set_SwitchableAccounts(response);
          setSelectedAccounts(
            selectedAccountsObj.map(account => account.accountId),
          );
        }
      },
    );
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    const data = {};
    data.displayName = userToEdit.displayName;
    data.username = userToEdit.username;
    data.switchableAccounts = selectedAccounts;
    data.language = userToEdit.language;
    if (selectedModules && selectedModules.length <= 3) {
      data.privileges = selectedModules;
    }
    isLoading();
    editMultiUser(data, response => {
      isLoaded();
      if (response) {
        const notificationContent = () => (
          <div>
            {`${t('You have successfully updated user.')} ${data.username}`}
          </div>
        );
        showNotification({ content: notificationContent });
        closeModal();
        context.getUsers();
      }
    });
  };

  const searchAccounts = e => {
    const searchVal = e.target.value && e.target.value.toLowerCase();
    let filteredAccounts;
    if (searchVal) {
      filteredAccounts = switchableAccounts.filter(acc => {
        return (
          (acc.domain && acc.domain.toLowerCase().indexOf(searchVal)) > -1 ||
          (acc.description &&
            acc.description.toLowerCase().indexOf(searchVal)) > -1
        );
      });
    } else {
      filteredAccounts = switchableAccounts;
    }

    set_SwitchableAccounts(filteredAccounts);
  };

  const toggleAccountSelect = accountId => {
    if (selectedAccounts.indexOf(accountId) > -1) {
      setSelectedAccounts(
        selectedAccounts.filter(
          selectedAccount => selectedAccount !== accountId,
        ),
      );
    } else {
      setSelectedAccounts([...selectedAccounts, accountId]);
    }
  };

  const selectAllAccounts = selectAll => {
    const tempSelectedAccounts = [];
    if (selectAll) {
      _switchableAccounts.forEach(account => {
        tempSelectedAccounts.push(account.accountId);
      });
    }

    setSelectedAccounts(tempSelectedAccounts);
  };

  const onModulesChange = childSelectedModules => {
    setSelectedModules(childSelectedModules);
  };

  return (
    <div style={{ minWidth: '700px' }}>
      <form className='create-user' onSubmit={handleSubmit} ref={formRef}>
        <ol className='form-elements'>
          <UserAccountsList
            switchableAccounts={_switchableAccounts}
            toggleAccountSelect={toggleAccountSelect}
            selectedAccounts={selectedAccounts}
            searchAccounts={searchAccounts}
            selectAllAccounts={selectAllAccounts}
            onModulesChange={onModulesChange}
            defaultModules={userToEdit.privileges}
          />
          <div className='user-accounts-select-button'>
            <button className='tertiary-action' type='submit'>
              {t('Update User')}
            </button>
          </div>
        </ol>
      </form>
    </div>
  );
};

export default EditUser;
