import React from 'react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '../../../components/fields';
import { filterOptions } from './constants';
import classes from './SearchProductForm.module.scss';

export const SearchProductForm = ({ onSubmit }) => {
  const { handleSubmit, watch, control, register, setValue } = useForm({
    defaultValues: {
      productId: '',
      multipleSearchProduct: false,
      showFilter: false,
      sortResults: false,
      productFilter: [],
    },
  });

  const show = watch('showFilter', false);
  const multiple = watch('multipleSearchProduct', false);

  const { actions, form, checkbox } = classes;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={form}>
      <Controller
        name='productId'
        control={control}
        render={({ field }) => (
          <TextField required type='text' label='Product ID' {...field} />
        )}
      />
      <div className={checkbox}>
        <label htmlFor='multipleSearchProduct'>
          <input type='checkbox' {...register('multipleSearchProduct')} />
          <span>Multiple Product Search</span>
        </label>
        {multiple === false && (
          <label htmlFor='showFilter'>
            <input type='checkbox' {...register('showFilter')} />
            <span>Show Filter</span>
          </label>
        )}
        {show && multiple === false && (
          <label htmlFor='sortResults'>
            <input type='checkbox' {...register('sortResults')} />
            <span>Sort Results</span>
          </label>
        )}
      </div>
      {show && multiple === false && (
        <Controller
          name='productFilter'
          control={control}
          render={({ field }) => (
            <Select
              multi
              clearable
              searchable
              placeholder='Create Your Own Product Schema'
              options={filterOptions}
              {...field}
            />
          )}
        />
      )}
      <div className={actions}>
        <button
          type='submit'
          onClick={() => {
            if (multiple) {
              setValue('showFilter', false);
              setValue('sortResults', false);
              setValue('productFilter', []);
            }
          }}
        >
          Submit
        </button>
      </div>
    </form>
  );
};
