/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React from 'react';

import { uiActions } from '../../actions';
import { salesDetailTable } from '../../constants/datamaps/analytics';
import { getSearchInstanceId } from '../../constants/datamaps/wizard';
import {
  getControlGroupByValue,
  hasGlobalControl,
} from '../../modules/auth/user';
import { getCampaignsByModuleType } from '../../modules/campaigns/ajax';
import { getCampaignPage } from '../../modules/campaigns/data';
import { getSalesDetail } from '../../modules/sales/ajax';
import { productUrl } from '../../modules/trendify/page';
import { reformatDateByTimeZone } from '../../system/date';
import { decodeHtml } from '../../system/string';
import { t } from '../../system/ui';
import TableHeadings from '../table/heading';
import Currency from '../currency';
import Icon from '../icon';
import Tooltip from '../tooltip';
import Icons from '../icons';

class SalesDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      purchaseProducts: [],
      campaigns: [],
      isLoading: true,
    };

    this.getCampaignNames = this.getCampaignNames.bind(this);
  }

  componentDidMount() {
    uiActions.isLoading();
    getSalesDetail(this.props.purchaseProductIds, response => {
      const campaignsRaw = this.props.campaigns.filter(
        module => module.instanceIds.length > 0,
      );

      /**
       * Workaround, there is no `ABANDONED_CART_FIRST_REMINDER` like campaign,
       * instead, `ABANDONED_CART` is the campaign and `FIRST_REMINDER` is
       * just one step.
       *
       * Hence, we filtrate all campaigns to return ABANDONED_CART instead.
       */
      const campaignsToGet = campaignsRaw.map(campaign => ({
        ...campaign,
        instanceIds: campaign.instanceIds.map(instance =>
          instance.includes('ABANDONED_CART') ? 'ABANDONED_CART' : instance,
        ),
      }));

      getCampaignsByModuleType(campaignsToGet, allCampaigns => {
        uiActions.isLoaded();
        const campaigns = Object.keys(allCampaigns).flatMap(module =>
          // We need to create a campaign for each instance ID.
          allCampaigns[module].reduce((result, campaign) => {
            /*
             * As we know, `ABANDONED_CART` works a bit different from other campaigns.
             * We want to know the exact step of the campaign the product was promoted.
             * We get the first `ABANDONED_CART` item, we get its suffix, and we extract the step title it owns.
             * */
            if (!campaign) {
              return [...result];
            }
            const isAbandoned = campaign.instanceId.includes('ABANDONED_CART');
            let currentItem;
            if (isAbandoned && module === 'push') {
              const globalIds = campaignsRaw.flatMap(raw => raw.instanceIds);
              const abandonedIds = globalIds.filter(id =>
                id.includes('ABANDONED_CART'),
              ); // We get all ids which include `ABANDONED_CART`
              currentItem = abandonedIds.map(id => {
                const idTerms = id.split('_'); // We split the ID in order to get the step name
                const hasSuffix = idTerms.length > 2; // We check if it has a suffix
                let label = campaign.name;
                if (hasSuffix) {
                  label = idTerms
                    .slice(-1 * (idTerms.length - 2))
                    .join(' ')
                    .toLowerCase()
                    .split(' ')
                    .map(w => w.charAt(0).toUpperCase() + w.substring(1))
                    .join(' ');
                }
                return {
                  value: id,
                  label: `${campaign.name} ${hasSuffix && ` ${label}`}`,
                  campaign,
                };
              }); // We return items as long as `ABANDONED_CART` including id are.
            } else {
              currentItem = [
                {
                  value: campaign.instanceId,
                  label: campaign.scenarioName || campaign.name,
                  campaign,
                },
              ];
            }
            return [...result, ...currentItem];
          }, []),
        );

        this.setState({
          purchaseProducts: response,
          campaigns,
          isLoading: false,
        });
        uiActions.isLoaded();
        window.dispatchEvent(new Event('resize'));
      });
    });
  }

  getCampaignNames(instanceIds) {
    const campaignNames =
      instanceIds && instanceIds.length
        ? this.state.campaigns
            .filter(campaign => instanceIds.includes(campaign.value))
            .map(campaign => campaign.label)
        : [];

    if (campaignNames.length) {
      const uniqueCampaignNames = [...new Set(campaignNames)];
      return <span>{uniqueCampaignNames.join(',')}</span>;
    }
    return '-';
  }

  getContribution = (product, soldProduct) => {
    const elements = [];

    if (product.instanceIds && product.instanceIds.length) {
      elements.push([<Icon name='heart' />]);
    }

    if (product.mailIds && product.mailIds.length) {
      elements.push([
        <Icons name='emailModule' color='#7578ab' width='24' height='16' />,
      ]);
    }

    if (product.pushIds && product.pushIds.length) {
      elements.push([
        <Icons name='pushModule' color='#bc86ad' width='24' height='16' />,
      ]);
    }

    if (product.searchIds && product.searchIds.length) {
      elements.push([
        <Icons name='search' color='#7578ab' width='24' height='16' />,
      ]);
    }

    if (
      soldProduct &&
      soldProduct.searchPinList &&
      soldProduct.searchPinList.length
    ) {
      elements.push([
        <Icons name='pinIcon' color='#7578ab' width='24' height='16' />,
      ]);
    }

    if (product.returned) {
      elements.push([<Icons name='returned' />]);
    }

    return elements.length > 0 ? elements : ['-'];
  };

  render() {
    const tableStyle = {
      height: 'calc(100% - 100px)',
      minWidth: '880px',
    };

    const findProduct = item =>
      this.state.purchaseProducts.find(
        product => product.productId === item.productId,
      );

    const renderCategories = item => {
      const category = item.category || item.categories;

      if (category) {
        if (category.length > 2)
          return (
            <Tooltip
              content={category.map(x => decodeHtml(x)).join(', ')}
              newTooltip
              alignment='top'
            >
              {category[0]} ... {category[category.length - 1]}
            </Tooltip>
          );
        return category.map(x => decodeHtml(x)).join(', ');
      }
      return <div>-</div>;
    };

    return (
      <div className='analytics-details' style={{ minHeight: '360px' }}>
        <div className='sales-order-detail'>
          {hasGlobalControl() && (
            <div className='sales-order-detail-item one-quarters'>
              <span>
                {t(getControlGroupByValue(this.props.basketDetail.mode).label)}
              </span>
            </div>
          )}
          <div className='sales-order-detail-item one-quarters'>
            {t('Order No')}: <span>{this.props.basketDetail.orderNo}</span>
          </div>
          <div className='sales-order-detail-item one-quarters'>
            {t('Sale Time')}:{' '}
            <span>{reformatDateByTimeZone(this.props.basketDetail.time)}</span>
          </div>
          <div className='sales-order-detail-item one-quarters'>
            {t('Amount')}:{' '}
            <span>
              <Currency
                currency={this.props.basketDetail.totalPrice}
                predefinedCode={this.props.predefinedCode}
              />
            </span>
          </div>
        </div>
        {!this.state.isLoading && (
          <div style={tableStyle}>
            <table className='data-table'>
              <thead>
                <TableHeadings heading={salesDetailTable.header} />
              </thead>
              <tbody>
                {this.props.basketDetail.productList.map(item => {
                  let newProductObj = findProduct(item);
                  if (!newProductObj) {
                    newProductObj = { ...item };
                  }

                  newProductObj.allInstanceIds = [];
                  newProductObj.instanceIds = item.instanceIds || [];
                  newProductObj.mailIds = item.mailIds || [];
                  newProductObj.pushIds = item.pushIds || [];
                  newProductObj.searchIds =
                    item.searchIds && item.searchIds.length > 0
                      ? item.searchIds.map(id => getSearchInstanceId(id))
                      : [];
                  newProductObj.allInstanceIds = newProductObj.allInstanceIds
                    .concat(newProductObj.instanceIds)
                    .concat(newProductObj.mailIds)
                    .concat(newProductObj.pushIds)
                    .concat(newProductObj.searchIds);
                  newProductObj.quantity = item.quantity;
                  newProductObj.price = item.price;
                  newProductObj.returned = item?.returned || false;
                  return (
                    <tr key={newProductObj.productId}>
                      <td style={{ padding: '5px 15px' }}>
                        {newProductObj.image ? (
                          <a
                            href={productUrl(newProductObj.url)}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <img
                              alt={newProductObj.name}
                              src={productUrl(newProductObj.image)}
                              height='40'
                            />
                          </a>
                        ) : (
                          <span className='analytics-nophoto' />
                        )}
                      </td>
                      <td style={{ padding: '5px 15px' }}>
                        {newProductObj.name ? (
                          <a
                            href={productUrl(newProductObj.url)}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {newProductObj.name} - {newProductObj.productId}
                          </a>
                        ) : (
                          <span>
                            Product Unavailable - {newProductObj.productId}
                          </span>
                        )}
                      </td>
                      <td style={{ padding: '5px 15px' }}>
                        {renderCategories(newProductObj)}
                      </td>
                      <td style={{ padding: '5px 15px' }}>
                        {newProductObj.brand
                          ? decodeHtml(newProductObj.brand)
                          : '-'}
                      </td>
                      <td style={{ padding: '5px 15px' }}>
                        {newProductObj.inStock ? (
                          <Icon name='check-circle' />
                        ) : newProductObj.name ? (
                          <span className='out-of-stock' />
                        ) : (
                          '-'
                        )}
                      </td>
                      <td style={{ padding: '5px 15px' }}>
                        {newProductObj.quantity}
                      </td>
                      <td style={{ padding: '5px 15px' }}>
                        <Currency
                          currency={newProductObj.price}
                          predefinedCode={this.props.predefinedCode}
                        />
                      </td>
                      <td
                        style={{ padding: '5px 15px', minWidth: '115px' }}
                        className='sales-sgf-cont'
                      >
                        {this.getContribution(newProductObj, item)}
                      </td>
                      <td className='product-list' colSpan='2'>
                        <table className='data-table colspan-fifty'>
                          <tbody>
                            {newProductObj && newProductObj.allInstanceIds ? (
                              newProductObj.allInstanceIds.map(instanceId => {
                                const targetCampaign = this.state.campaigns.find(
                                  campaign =>
                                    campaign.campaign.instanceId ===
                                      instanceId ||
                                    campaign.value === instanceId, // `ABANDONED_CART` campaigns has a unique ID different from the object can have
                                );

                                return (
                                  targetCampaign && (
                                    <>
                                      <tr className='instance-campaign-list'>
                                        <td>
                                          {this.getCampaignNames([instanceId])}
                                        </td>
                                        {targetCampaign &&
                                        targetCampaign.campaign.inputs ? (
                                          <td>
                                            {getCampaignPage(
                                              targetCampaign.campaign,
                                            )}
                                          </td>
                                        ) : (
                                          <td>N/A</td>
                                        )}
                                      </tr>
                                      {item.searchPinList &&
                                        item.searchPinList.length > 0 &&
                                        item.searchPinList.map(
                                          (prod, index) => {
                                            if (
                                              newProductObj.searchIds[index] ===
                                              instanceId
                                            ) {
                                              return (
                                                <tr className='instance-campaign-list'>
                                                  <td>
                                                    {prod.term} - Position{' '}
                                                    {prod.position}
                                                  </td>
                                                  <td>
                                                    {targetCampaign &&
                                                    targetCampaign.campaign
                                                      .inputs ? (
                                                      <td>
                                                        {getCampaignPage(
                                                          targetCampaign.campaign,
                                                        )}
                                                      </td>
                                                    ) : (
                                                      <td>N/A</td>
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                            return null;
                                          },
                                        )}
                                    </>
                                  )
                                );
                              })
                            ) : (
                              <tr>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default SalesDetails;
