import { getRestEndPoint } from '../../../../system/api';
import { getApiKey } from '../../../../modules/auth/user';
import { $http } from '../../../../system/ajax';
import { isLoading } from '../../../../actions/ui';

export const fetchContactReport = async () => {
  isLoading();
  const url = `${getRestEndPoint('contacts/report')}?apiKey=${getApiKey()}`;

  const response = await $http(url).get();
  return await response;
};
