import React from 'react';
import { Link } from 'react-router';

import { config } from '../../constants/config';
import { isSwitchAccount, isMultiAccountUser } from '../../modules/auth/user';

import { t } from '../../system/ui';

import Icon from '../icon';
import Icons from '../icons';
import UserNav from '../nav/user';
import Preloader from './preloader';
import { ExternalAppUtility } from '../../external';

function Header(props) {
  if (ExternalAppUtility.isHeaderHidden()) {
    return (
      <div>
        <Preloader />
        <h2 className='page-title page-title--analytics'>
          <span className='for-screenreader-only'>{t('Trendify')}</span>
        </h2>
        <h2 className='page-title page-title--analytics page-title--sales'>
          <span>{t('Sales')}</span>
        </h2>
      </div>
    );
  }

  return (
    <header id='masthead' className='page-header'>
      <div className='header-top-field'>
        <Link
          to={
            props.isSetPassword || (isMultiAccountUser() && !isSwitchAccount())
              ? false
              : config.menus.primary.dashboard.slug
          }
          className='page-logo'
        >
          <Icon name='segmentify' size='2x' />
          <Icons
            name='dashboardTfy'
            size='2x'
            color='#989593'
            width='16'
            height='16'
          />
        </Link>
      </div>

      {document.location.pathname.includes('trendify') ? (
        <h2 className='page-title page-title--analytics'>
          <Icons name='segmentifyAnalytics' width='35' height='35' />
          {t('Segmentify Analytics')}
        </h2>
      ) : (
        <h2 className='page-title page-title--analytics'>
          <span className='for-screenreader-only'>{t('Trendify')}</span>
        </h2>
      )}

      <h2 className='page-title page-title--analytics page-title--sales'>
        <span>{t('Sales')}</span>
      </h2>

      <UserNav {...props} />

      <Preloader />
    </header>
  );
}

export default Header;
