/**
 * Created by mehmetyurtar on 11/08/2017.
 */
"use strict";

import React from "react";
import { connect } from "react-redux";

import EngagementCard from "../components/engagement/card";
import { t } from "../system/ui";
import { campaignActions, uiActions } from "../actions";

import Icon from "../components/icon";
import Icons from "../components/icons";
import StatusFilters from "../components/filters/status-filters";
import LoaderPlaceholder from "../components/widget/loader-placeholder";
import { getReports } from "../modules/reports/ajax";
import { getCampaignsWithApiKey } from "../modules/campaign/events";
import { CampaignList } from "../modules/widgets";

class AllEngagements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      _campaigns: [],
      campaigns: [],
      title: "Active",
      showOptions: false,
      sort: "DESC",
      sortType: "",
      addSortedClass: false,
      toggleIcon: "preferences",
      filteredCampaigns: [],
      allCampaigns: []
    };

    this.getCampaigns = this.getCampaigns.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.sortByDate = this.sortByDate.bind(this);
    this.sortByRevenue = this.sortByRevenue.bind(this);
    this.getCampaignsReport = this.getCampaignsReport.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.getViewAllCampaigns = this.getViewAllCampaigns.bind(this);
  }

  componentDidMount() {
    this.getViewAllCampaigns();
  }

  componentDidUpdate(oldProps, oldStates) {
    if (oldProps.status !== this.props.status) {
      this.getCampaigns(this.state.allCampaigns, this.props.status, this.props.medium);
    }

    if (oldProps.medium !== this.props.medium) {
      this.getCampaigns(this.state.allCampaigns, this.props.status, this.props.medium);
    }
  }

  getViewAllCampaigns(status) {
    uiActions.isLoading();
    getCampaignsWithApiKey(campaigns => this.setState({ allCampaigns: campaigns.response },
      () => {
        status !== undefined ? campaignActions.filterCampaigns(status)
          : this.getCampaigns(this.state.allCampaigns, this.props.status, this.props.medium);
      }));
  }

  getCampaignsReport(filteredCampaigns) {
    uiActions.isLoading();
    let campaigns = [];
    getReports(filteredCampaigns, this.props.timeRange, campaignsWithReport => {
      const list = typeof campaignsWithReport === "object" ? campaignsWithReport : [];
      list.forEach(item => {
        item.campaign.logs = item.logs;
        campaigns.push(item.campaign);
      });

      this.setState({ campaigns: campaigns, filteredCampaigns: campaigns }, () => {
        uiActions.isLoaded();
      });
    });
  }

  onUpdate(campaignId, status) {
    if (campaignId && status) {
      let currStatus =
        status === "TEST" || status === "ACTIVE" ? status : "PASSIVE";
      this.getViewAllCampaigns(currStatus);
    }
  }

  getCampaigns(campaigns, stts, medium) {
    let _campaigns = [],
      count = 0,
      status = stts === "PASSIVE" ? "ARCHIVE" : stts;
    campaigns.forEach(campaign => {
      if (campaign.status.toLowerCase() === status.toLowerCase()) {
        let isSelectedMedium = medium === "ALL" || medium.length === 0;
        let search = ["ALL"];
        search = search.concat(medium);
        if (search.indexOf("PCTABLET") > -1) {
          search = search.concat(["PC", "TABLET"]);
        }
        if (search.indexOf("ANDROID") > -1 || search.indexOf("IOS") > -1) {
          search = search.concat(["ANDROIDIOS"]);
        }
        campaign.devices.forEach(deviceItem => {
          if (search.indexOf(deviceItem) > -1) {
            isSelectedMedium = true;
          }
        });
        if (isSelectedMedium) {
          _campaigns.push(campaign);
          count++;
        }
      }
    });

    _campaigns.sort((a, b) => {
      return (b.startDate || -1) - (a.startDate || 0);
    });

    this.setState({
      _campaigns: _campaigns,
      campaigns: [],
      filteredCampaigns: [],
      count: count,
      title: status
    }, () => this.getCampaignsReport(this.state._campaigns));
  }

  searchHandler(e) {
    let visibleCampaigns = this.state.filteredCampaigns,
      matchedCampaigns = [],
      search = e.currentTarget.value;

    visibleCampaigns.forEach(item => {
      if (
        item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        item.instanceId.toLowerCase().indexOf(search.toLowerCase()) > -1
      ) {
        matchedCampaigns.push(item);
      }
    });

    if (matchedCampaigns.length > 0) {
      this.setState({
        count: matchedCampaigns.length,
        campaigns: matchedCampaigns
      });
    }
  }

  toggleOptions(e) {
    e.preventDefault();
    this.setState({
      showOptions: !this.state.showOptions,
      toggleIcon: !this.state.showOptions ? "cross" : "preferences"
    });
  }

  sortByDate(e) {
    e.preventDefault();
    let campaigns = this.state.campaigns;
    campaigns.sort((a, b) => {
      return (b.startDate || -1) - (a.startDate || 0);
    });
    let sortingOrder;
    if (this.state.sortType !== "date") {
      sortingOrder = "DESC";
    } else {
      if (this.state.sort === "DESC") {
        campaigns.reverse();
      }
      sortingOrder = this.state.sort === "ASC" ? "DESC" : "ASC";
    }
    this.setState({
      campaigns: campaigns,
      sort: sortingOrder,
      addSortedClass: true,
      sortType: "date"
    });
  }

  sortByRevenue(e) {
    e.preventDefault();

    let campaigns = this.state.campaigns;

    campaigns.forEach(campaign => {
      let logs = campaign.logs || [];
      let totalRevenue = 0;
      if (logs.length) {
        logs.forEach(log => {
          if (log.trend === "Purchase Amount") {
            totalRevenue = log.count;
          }
        });
      }
      campaign.totalRevenue = totalRevenue;
    });

    campaigns.sort((a, b) => {
      return (b.totalRevenue || -1) - (a.totalRevenue || 0);
    });

    let sortingOrder;

    if (this.state.sortType !== "revenue") {
      sortingOrder = "DESC";
    } else {
      if (this.state.sort === "DESC") {
        campaigns.reverse();
      }
      sortingOrder = this.state.sort === "ASC" ? "DESC" : "ASC";
    }

    this.setState({
      campaigns: campaigns,
      sort: sortingOrder,
      addSortedClass: true,
      sortType: "revenue"
    });
  }

  render() {
    const { idsInAB } = this.props;
    return (
      <div>
        <h2 className="page-title">
          {this.state.title === "ACTIVE" ? t("LIVE") : t(this.state.title)}
          <span className="emphasise">({this.state.count})</span>
        </h2>

        <div className="page-options">
          <label className="item is-stacked is-search">
            <Icon name="magnify" />
            <input
              type="text"
              placeholder={t("Search")}
              className="text-field"
              onChange={this.searchHandler}
            />
          </label>
          <div className="data-preferences">
            <a href="#" className="toggler" onClick={this.toggleOptions}>
              <Icon name={this.state.toggleIcon} />
            </a>
            <ul
              className={
                !this.state.showOptions
                  ? "data-preferences-options is-hidden"
                  : "data-preferences-options"
              }
            >
              <li className="data-preferences-option">
                <a
                  href="#"
                  className="data-preferences-option-item"
                  onClick={this.sortByDate}
                >
                  <Icon name="calendar" />
                  {t("Sort by Date")}
                  {this.state.sortType === "date" ? (
                    this.state.sort === "ASC" ? (
                      <i className="icon-caret-up viewall-sorting" />
                    ) : (
                      <i className="icon-caret-down viewall-sorting" />
                    )
                  ) : (
                    ""
                  )}
                </a>
              </li>
              <li className="data-preferences-option">
                <a
                  href="#"
                  className="data-preferences-option-item"
                  onClick={this.sortByRevenue}
                >
                  <Icons name="revenueSort" color="#acacac" />
                  {t("Sort by Revenue")}
                  {this.state.sortType === "revenue" ? (
                    this.state.sort === "ASC" ? (
                      <i className="icon-caret-up viewall-sorting" />
                    ) : (
                      <i className="icon-caret-down viewall-sorting" />
                    )
                  ) : (
                    ""
                  )}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="page-filters has-filter-form">
          <StatusFilters isEngagement={true} />
        </div>
        <div className="page-content-wrapper">
          {this.state.campaigns.length > 0 ? (
            this.state.campaigns.map(campaign => {
              const isInAB = idsInAB.find(id => campaign.instanceId === id);
              return (
                <EngagementCard
                  {...campaign}
                  {...this.props}
                  key={campaign.instanceId}
                  onUpdate={this.onUpdate}
                  isInAB={isInAB}
                />
              );
            })
          ) : this.props.ui !== "isLoading" ? (
            <div className="no-report">
              <p className="no-report-info">{t("There is no campaign")}</p>
            </div>
          ) : (
            <LoaderPlaceholder />
          )}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  ui: store.ui.ui,
  update: store.campaigns.update,
  status: store.campaigns.status,
  medium: store.campaigns.medium
});

export default connect(mapStatesToProps)(AllEngagements);
