import { $http } from '../../system/ajax';
import { getRestEndPoint } from '../../system/api';

export const getUserResponsibleAccounts = (
  username,
  callback,
  errorCallback,
) => {
  const url = getRestEndPoint(
    'userManagement/getResponsibleAccountsByUser',
  ).replace('{username}', username);
  return $http(url)
    .get()
    .then(function(response) {
      callback(response);
    })
    .catch(function(error) {
      errorCallback(error);
    });
};
