import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Cell, Row, StickyTable } from 'react-sticky-table';
import cn from 'classnames';

import BadResponseCat from 'components/BadResponseCat';

import {
  dateActions,
  filterActions,
  modalActions,
  trendifyActions,
  uiActions,
} from '../actions';
import SalesBanners from '../components/analytics/sales.banners';
import SalesDetails from '../components/analytics/sales.details';
import Currency from '../components/currency';
import Export from '../components/export';
import Filters from '../components/filters/filters';
import SalesFilters from '../components/filters/sales-filters';
import Icon from '../components/icon';
import Icons from '../components/icons';
import ScrollUpButtonAnalytics from '../components/scrollUpAnalytics';
import TableHeadings from '../components/table/heading';
import TotalsLine from '../components/table/totalsLine';
import { config } from '../constants/config';
import { salesQuery, salesTable } from '../constants/datamaps/sales';
import { getSearchInstanceId } from '../constants/datamaps/wizard';
import grid from '../modules/analytics/grid';
import {
  getControlGroupByValue,
  hasBannerify,
  hasGlobalControl,
  hasModulePrivilege,
  isSwitchAccount,
} from '../modules/auth/user';
import { getSales, getSalesCount, getSalesTotal } from '../modules/sales/ajax';
import { addSalesClasses } from '../modules/trendify/page';
import { queryDate, reformatDateByTimeZone } from '../system/date';
import { setTitle } from '../system/document';
import { t } from '../system/ui';
import { clone } from '../system/object';

const dummyRows = [
  {
    userId: '1892210923626497',
    sessionId: '1892210923626496',
    device: 'PC',
    time: 1522218831844,
    basketId: 'basket_1892210923626497',
    totalPrice: 200,
    productList: [
      {
        productId: 'ITEM_01',
        price: 100,
        quantity: 2,
      },
    ],
  },
];

class Sales extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      query: clone(salesQuery),
      sort: 'DESC',
      currentSorted: 'time',
      filteredCampaign: false,
      showTrendifyFilters: false,
      showLoadingAnimation: true,
      selectedCurrency: 'ALL',
      showScrollToTop: false,
      totals: {
        totalAmount: 0,
        totalQuantity: 0,
        totalPrice: 0,
        totalSales: 0,
      },
      isTotalSalesLoading: true,
    };

    this.sort = this.sort.bind(this);
    this.refresh = this.refresh.bind(this);
    this.reset = this.reset.bind(this);
    this.showPurchaseDetail = this.showPurchaseDetail.bind(this);
    this.showSalesBanners = this.showSalesBanners.bind(this);
    this.checkSegmentifyContribution = this.checkSegmentifyContribution.bind(
      this,
    );
    this.exportFile = this.exportFile.bind(this);
    this.toggleAnalyticsFilters = this.toggleAnalyticsFilters.bind(this);
    this.getTotals = this.getTotals.bind(this);
    this.setScrollToTop = this.setScrollToTop.bind(this);
    this.calculateSticky = this.calculateSticky.bind(this);
    this.onTableScroll = this.onTableScroll.bind(this);
    this.getFinalPrice = this.getFinalPrice.bind(this);
  }

  toggleAnalyticsFilters() {
    this.setState({
      showTrendifyFilters: !this.state.showTrendifyFilters,
    });
  }

  componentDidMount() {
    setTitle(t('Sales'));
    uiActions.hideBreadcrumb();
    uiActions.isLoading();

    filterActions.filtersReset();
    dateActions.updateRangeAlias('lastSevenDays', false);

    // trendifyIntegrationInfo();
    addSalesClasses();

    getSales(this.state.query, response => {
      this.setState({
        timeZone: isSwitchAccount()
          ? this.props.switchedAccount.timeZone
          : this.props.userAccount.timeZone,
        rows: response,
        showLoadingAnimation: false,
      });
      this.getTotals(this.state.query);
      uiActions.isLoaded();
      this.setScrollToTop();
      this.calculateSticky();
    });
    getSalesCount(this.state.query, response => {
      trendifyActions.updateCount(response);
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      // trendifyIntegrationInfo(newProps.user);
      addSalesClasses();
      this.refresh(newProps);
    }

    if (newProps.update === true) {
      filterActions.changePageSize(100);
      filterActions.changePageNumber(1);
      this.refresh(newProps, 100, 1);
    }

    if (newProps.reset === true) {
      this.reset(newProps);
    }

    if (
      newProps.pageSize !== this.props.pageSize ||
      newProps.pageNumber !== this.props.pageNumber
    ) {
      if (newProps.update !== true) {
        this.refresh(newProps, newProps.pageSize, newProps.pageNumber);
      }
    }
  }

  refresh(newProps, pageSize, pageNumber) {
    uiActions.isLoading();

    if (this.refs.pageFilters === null || this.refs.pageFilters === undefined) {
      const notificationContent = () => (
        <span>{t('something-went-wrong')}</span>
      );
      uiActions.showErrorNotification(notificationContent);
      return;
    }

    const query = clone(salesQuery);
    const { range } = newProps;
    const campaigns = this.refs.pageFilters?.querySelector('[name=campaigns]');
    const minTotalPrice = this.refs.pageFilters?.querySelector(
      '#minTotalPrice',
    );
    const maxTotalPrice = this.refs.pageFilters?.querySelector(
      '#maxTotalPrice',
    );
    const orderNo = this.refs.pageFilters?.querySelector('#orderNo');
    const productId = this.refs.pageFilters?.querySelector('#productId');
    const device = this.refs.pageFilters?.querySelector('[name=visitorDevice]');
    const viaBanners = this.refs.pageFilters?.querySelector('#viaBanners');
    const mode = this.refs.pageFilters?.querySelector('[name=mode]');
    const currency = this.refs.pageFilters?.querySelector('[name=currency]');

    if (!campaigns) {
      const notificationContent = () => <span>{t('campaigns-not-found')}</span>;
      uiActions.showErrorNotification(notificationContent);
      return;
    }

    query.start = queryDate(range[0]);
    query.end = queryDate(range[1]);
    query.campaignId = campaigns
      ? ['cancel', 'returned'].includes(campaigns.value.split('|')[0])
        ? ''
        : campaigns.value.split('|')[0]
      : '';
    query.campaignType = campaigns ? campaigns.value.split('|')[1] : '';
    if (
      campaigns.value.split('|')[0] === 'segmentify' ||
      campaigns.value.split('|')[0] === 'recommendation' ||
      campaigns.value.split('|')[0] === 'email' ||
      campaigns.value.split('|')[0] === 'push' ||
      campaigns.value.split('|')[0] === 'search'
    ) {
      query.campaignId = 'all';
      query.campaignType = campaigns.value.split('|')[0];
    } else if (
      campaigns.value.split('|')[0] === 'instant' ||
      campaigns.value.split('|')[0] === 'faceted'
    ) {
      query.campaignId = 'all';
      query.campaignType = 'search';
      query.searchType = campaigns.value.split('|')[0];
    } else if (campaigns.value.split('|')[0] === 'pinned') {
      query.campaignId = 'all';
      query.campaignType = 'searchPin';
    }
    query.minTotalPrice = minTotalPrice.value
      ? parseInt(minTotalPrice.value)
      : '';
    query.maxTotalPrice = maxTotalPrice.value
      ? parseInt(maxTotalPrice.value)
      : '';
    if (campaigns.value.split('|')[0] === 'cancel') {
      query.cancel = true;
    }
    if (campaigns.value.split('|')[0] === 'returned') {
      query.returned = true;
    }
    query.orderNo = orderNo.value;
    query.productId = productId.value;
    query.viaBanners = viaBanners.checked;
    query.items = this.props.pageSize;
    query.page = this.props.pageNumber;
    query.sort = this.state.currentSorted;
    query.currency = currency ? currency.value || 'ALL' : 'ALL';
    if (mode && mode.value && mode.value !== 'ALL') {
      query.mode = mode.value;
    }

    if (
      typeof newProps !== 'undefined' &&
      typeof newProps.pageSize !== 'undefined'
    ) {
      query.items = newProps.pageSize;
    }

    if (
      typeof newProps !== 'undefined' &&
      typeof newProps.pageNumber !== 'undefined'
    ) {
      query.page = newProps.pageNumber;
    }

    if (device.value !== 'all') {
      query.device = device.value;

      // need data standardization. For all other device select, we use lower case 'pc'
      if (device.value === 'pc') {
        query.device = 'PC';
      }
    }

    if (
      typeof newProps !== 'undefined' &&
      typeof newProps.pageSize !== 'undefined'
    ) {
      query.items = pageSize || 100;
    }

    if (
      typeof newProps !== 'undefined' &&
      typeof newProps.pageNumber !== 'undefined'
    ) {
      query.page = pageNumber || 1;
    }

    getSales(query, response => {
      this.setState(
        {
          timeZone: isSwitchAccount()
            ? this.props.switchedAccount.timeZone
            : this.props.userAccount.timeZone,
          rows: response,
          query,
          filteredCampaign: campaigns ? campaigns.value : false,
          showLoadingAnimation: false,
          selectedCurrency: currency ? currency.value || 'ALL' : 'ALL',
        },
        () => {
          this.setScrollToTop();
          this.getTotals(this.state.query);
          this.calculateSticky();
          uiActions.isLoaded();
        },
      );
    });
    getSalesCount(query, response => {
      trendifyActions.updateCount(response);
    });

    filterActions.filtersUpdated();
  }

  reset(newProps) {
    filterActions.filtersUpdated();
    const { range } = newProps;
    const query = clone({
      ...salesQuery,
      start: queryDate(range[0]),
      end: queryDate(range[1]),
    });

    grid.reset(query, getSales, getSalesCount, this, () => {
      this.getTotals(query);
      this.setScrollToTop();
      this.calculateSticky();
    });
    this.setState({
      filteredCampaign: false,
      selectedCurrency: 'ALL',
    });
  }

  sort(sorting) {
    const query = clone(this.state.query);

    grid.sort(sorting, query, getSales, this);
  }

  showPurchaseDetail(purchase) {
    const purchaseProductIds = [];
    const campaignsObj = [
      {
        type: 'recommendation',
        instanceIds: [],
      },
      {
        type: 'mail',
        instanceIds: [],
      },
      {
        type: 'push',
        instanceIds: [],
      },
      {
        type: 'search',
        instanceIds: [],
      },
    ];

    purchase.productList.forEach(purchaseProduct => {
      purchaseProductIds.push(purchaseProduct.productId);
      campaignsObj[0].instanceIds = campaignsObj[0].instanceIds.concat(
        purchaseProduct.instanceIds || [],
      );
      campaignsObj[1].instanceIds = campaignsObj[1].instanceIds.concat(
        purchaseProduct.mailIds || [],
      );
      campaignsObj[2].instanceIds = campaignsObj[2].instanceIds.concat(
        purchaseProduct.pushIds || [],
      );
      if (purchaseProduct.searchIds && purchaseProduct.searchIds.length > 0) {
        campaignsObj[3].instanceIds = campaignsObj[3].instanceIds.concat(
          purchaseProduct.searchIds.map(id => getSearchInstanceId(id)),
        );
      }
    });

    const content = () => (
      <SalesDetails
        basketDetail={purchase}
        purchaseProductIds={purchaseProductIds.filter(id => id)}
        predefinedCode={
          this.state.selectedCurrency !== 'ALL'
            ? this.state.selectedCurrency
            : purchase.currency || false
        }
        campaigns={campaignsObj}
      />
    );

    modalActions.openModal({
      title: t('Cart Detail'),
      className: 'trendify-sales-modal',
      content,
    });
  }

  showSalesBanners(banners, time) {
    const content = () => <SalesBanners banners={banners} salesDate={time} />;
    uiActions.openModal({ title: t('Banners'), content });
  }

  checkOtherModuleContribution(product) {
    const elements = [];
    if (product.mailIds || product.pushIds) {
      if (product.mailIds && product.mailIds.length) {
        elements.push([
          <Icons name='emailModule' color='#7578ab' width='24' height='16' />,
        ]);
      }

      if (product.pushIds && product.pushIds.length) {
        elements.push([<Icons name='pushModule' color='#bc86ad' />]);
      }
    }
    return elements;
  }

  checkSearchContribution(product) {
    return product.searchIds && product.searchIds.length
      ? [<Icons name='search' color='#7578ab' width='24' height='16' />]
      : [];
  }

  checkPinnedContribution(product) {
    return product.searchPinList && product.searchPinList.length
      ? [<Icons name='pinIcon' color='#7578ab' width='24' height='16' />]
      : [];
  }

  checkReturnedProduct(product) {
    return product?.returned ? [<Icons name='returned' key={product} />] : [];
  }

  checkSegmentifyContribution(product) {
    let result = [];
    let viaCampaign = false;
    let viaFilteredCampaign = false;
    if (this.state.filteredCampaign) {
      if (product.instanceIds && product.instanceIds.length) {
        // mailIds - pushIds
        product.instanceIds.forEach(instanceId => {
          if (instanceId.includes(this.state.filteredCampaign))
            viaFilteredCampaign = true;
        });
        if (!viaFilteredCampaign) viaCampaign = true;
      }
      if (viaCampaign) {
        result = [<Icon name='heart' />];
      }
      if (viaFilteredCampaign) {
        result = [<Icon name='check-circle' />];
      }
    } else {
      result =
        product.instanceIds && product.instanceIds.length > 0
          ? [<Icon name='heart' key={product.instanceIds} />]
          : [];
    }

    const otherContrib = this.checkOtherModuleContribution(product);
    const searchContrib = this.checkSearchContribution(product);
    const pinnedContrib = this.checkPinnedContribution(product);
    const returnedContrib = this.checkReturnedProduct(product);

    const resultContrib = otherContrib
      .concat(searchContrib)
      .concat(pinnedContrib)
      .concat(result)
      .concat(returnedContrib);

    if (resultContrib.length === 0) {
      return ['-'];
    }

    return resultContrib;
  }

  exportFile(e) {
    e.preventDefault();

    const { range } = this.props;
    const name = `Sales_Report_${range[0].format(
      'MMMM_D_YYYY',
    )}-${range[1].format('MMMM_D_YYYY')}.csv`;
    const queryToExport = { ...this.state.query };
    delete queryToExport.type;
    const content = () => (
      <Export name={name} query={queryToExport} endpoint='sales/export' />
    );

    uiActions.openModal({ title: t('Export Sales Report'), content });
  }

  getTotals(query) {
    uiActions.isLoading();
    this.setState({
      isTotalSalesLoading: true,
    });
    getSalesTotal(query, response => {
      this.setState(
        {
          totals: {
            totalQuantity: response.itemCount,
            totalSales: response.purchaseCount,
            totalAmount: response.totalAmount,
            totalPrice: response.totalPrice,
          },
          isTotalSalesLoading: false,
        },
        () => {
          uiActions.isLoaded();
        },
      );
    });
  }

  setScrollToTop() {
    const element = this.refs.scrollElement;
    let showButton = !!element;
    if (element) {
      if (
        element.scrollHeight <=
        parseFloat(element.style['max-height'].split('px')[0])
      ) {
        showButton = false;
      }
      if (element.scrollTop <= 100) {
        showButton = false;
      }
    }
    if (this.state.showScrollToTop !== showButton) {
      this.setState({
        showScrollToTop: showButton,
      });
    }
  }

  calculateSticky() {
    const element = this.refs.scrollElement;
    if (element) {
      const { maxHeight } = element.style;
      const headerEl = document.querySelector('.sticky-table-header-wrapper')
        .clientHeight;
      document.querySelector('.sticky-table').style.maxHeight = maxHeight;
      document.querySelector(
        '.sticky-table-y-wrapper',
      ).style.maxHeight = `${parseInt(maxHeight.split('px')[0]) -
        headerEl -
        20}px`;
      document.querySelector(
        '.sticky-table-header .sticky-table-row',
      ).style.height = '50px';

      const borderElements = document.querySelectorAll('.add-border');
      [].forEach.call(borderElements, function(item) {
        item.parentElement.style['border-right'] = '1px solid #efeef8';
      });

      this.setState({
        maxHeight: document
          .getElementsByClassName('sticky-table-y-wrapper')[0]
          .style.maxHeight.split('px')[0],
      });
    }
  }

  onTableScroll(event) {
    let showButton = true;
    if (event.scrollTop <= this.state.maxHeight) {
      showButton = false;
    }
    if (event.scrollTop <= 100) {
      showButton = false;
    }
    if (showButton !== this.state.showScrollToTop) {
      this.setState({
        showScrollToTop: showButton,
      });
    }
  }

  getFinalPrice(item, key, convertedKey) {
    let predefinedCode = '';
    let finalPrice = item[key];
    let finalComp;
    if (this.state.selectedCurrency !== 'ALL') {
      predefinedCode = this.state.selectedCurrency;
    }
    if (typeof finalPrice !== 'undefined') {
      if (this.state.selectedCurrency === 'ALL') {
        if (item[convertedKey]) {
          finalPrice = item[convertedKey];
        }
        finalComp = predefinedCode ? (
          <Currency currency={finalPrice} predefinedCode={predefinedCode} />
        ) : (
          <Currency currency={finalPrice} />
        );
      }
    } else {
      finalPrice = '-';
      finalComp = <span>{finalPrice}</span>;
    }
    return finalComp;
  }

  render() {
    if (this.props.user.displayName && !hasModulePrivilege(false, 'RECOUSER')) {
      browserHistory.push('/');
    }
    const salesNavItem = {
      sales: config.menus.primary.trendify.children.sales,
    };
    const tableHeight = window.innerHeight - 195;
    const dummyRowsToShow = [];
    for (let i = 0; i < 15; i++) {
      dummyRowsToShow.push(dummyRows[0]);
    }
    const rows = this.state.showLoadingAnimation
      ? dummyRowsToShow
      : this.state.rows;
    const { badResponseCatVisible } = this.props;

    const parentClass = cn(
      'segmentify-analytics segmentify-analytics--trendify',
      { '.hide': badResponseCatVisible },
    );

    return (
      <>
        <div className={parentClass}>
          <div>
            <div ref='pageFilters'>
              <Filters
                visibleUpdate={false}
                toggleAnalyticsFilters={this.toggleAnalyticsFilters}
                analyticsFilters
                exportFile={this.exportFile}
                analyticsType='sales'
              >
                <SalesFilters
                  showTrendifyFilters={this.state.showTrendifyFilters}
                  toggleAnalyticsFilters={this.toggleAnalyticsFilters}
                />
              </Filters>
            </div>
            {this.state.showLoadingAnimation || this.state.rows.length > 0 ? (
              <div
                className={cn('analytics-details', {
                  'analytics-placeholder': this.state.showLoadingAnimation,
                })}
                style={{ maxHeight: `${tableHeight}px`, overflow: 'hidden' }}
                ref='scrollElement'
              >
                <StickyTable
                  stickyColumnCount={0}
                  stickyHeaderCount={1}
                  onScroll={this.onTableScroll}
                >
                  {rows.length > 0 && (
                    <TableHeadings
                      currentSorted={this.state.currentSorted}
                      direction={this.state.sort}
                      heading={salesTable.header}
                      isSticky
                      onSort={this.sort}
                    />
                  )}
                  {rows.map((item, index) => {
                    const order =
                      index +
                      1 +
                      this.props.pageSize * (this.props.pageNumber - 1);
                    const hasSourceBannerify = false;
                    const salesBanners = [];
                    let finalPrice = item.totalPrice;
                    let predefinedCode = '';
                    if (
                      this.state.selectedCurrency === 'ALL' &&
                      item.convertedTotalPrice
                    ) {
                      finalPrice = item.convertedTotalPrice;
                    } else {
                      predefinedCode = this.state.selectedCurrency;
                    }
                    const productList = item.productList || [];
                    return (
                      <Row key={`item-${item.orderNo}`}>
                        <Cell>
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr 1fr',
                            }}
                          >
                            <span>
                              {item.step === 'cancel' && (
                                <Icons
                                  name='cancelled'
                                  color='black'
                                  width='20'
                                  height='20'
                                />
                              )}
                            </span>
                          </div>
                        </Cell>
                        <Cell>{order}</Cell>
                        <Cell>
                          <div>
                            <span className='groups-control'>
                              {hasGlobalControl()
                                ? t(getControlGroupByValue(item.mode).label)
                                : '-'}
                            </span>
                          </div>
                        </Cell>
                        <Cell>
                          <div>{reformatDateByTimeZone(item.time)}</div>
                        </Cell>
                        <Cell>
                          <div>{item.device}</div>
                        </Cell>
                        <Cell>
                          <div>{item.orderNo}</div>
                        </Cell>
                        <Cell>
                          <div>
                            <Currency
                              currency={finalPrice}
                              predefinedCode={
                                predefinedCode !== 'ALL'
                                  ? predefinedCode
                                  : false
                              }
                            />
                          </div>
                        </Cell>
                        <Cell>
                          <div>
                            {this.getFinalPrice(
                              item,
                              'totalDiscount',
                              'convertedTotalDiscount',
                            )}
                          </div>
                        </Cell>
                        <Cell>
                          <div>
                            {this.getFinalPrice(
                              item,
                              'shipment',
                              'convertedShipment',
                            )}
                          </div>
                        </Cell>
                        <Cell>
                          <div className='add-border'>
                            {item.paymentType || '-'}
                          </div>
                        </Cell>
                        <Cell>
                          <div>
                            {productList.map(product => (
                              <span
                                className='table-el'
                                key={`product-item-${product.productId}`}
                              >
                                {product.productId}
                              </span>
                            ))}
                          </div>
                        </Cell>
                        <Cell>
                          <div>
                            {productList.map(product => (
                              <span
                                className='table-el'
                                key={`product-item-${product.productId}`}
                              >
                                {product.quantity}
                              </span>
                            ))}
                          </div>
                        </Cell>
                        <Cell>
                          <div>
                            {productList.map(product => {
                              let predefinedProductCode = '';
                              let finalProductPrice = product.price;
                              if (this.state.selectedCurrency === 'ALL') {
                                if (product.convertedPrice) {
                                  finalProductPrice = product.convertedPrice;
                                }
                              } else {
                                predefinedProductCode = this.state
                                  .selectedCurrency;
                              }
                              return (
                                <span
                                  className='table-el'
                                  key={`product-item-${product.productId}`}
                                >
                                  <Currency
                                    currency={finalProductPrice}
                                    predefinedCode={
                                      predefinedProductCode !== 'ALL'
                                        ? predefinedProductCode
                                        : false
                                    }
                                  />
                                </span>
                              );
                            })}
                          </div>
                        </Cell>
                        <Cell>
                          <div>
                            {productList.map(product => (
                              <span
                                className='table-el sales-sgf-cont'
                                key={`product-item-${product.productId}`}
                              >
                                {this.checkSegmentifyContribution(product)}
                              </span>
                            ))}
                          </div>
                        </Cell>
                        <Cell>
                          <div>
                            {hasBannerify() ? (
                              productList.map(product =>
                                product.relatedBanners &&
                                product.relatedBanners.length ? (
                                  <span
                                    className='table-el'
                                    key={`product-item-${product.productId}`}
                                  >
                                    <a
                                      className='secondary-action helper-action'
                                      onClick={this.showSalesBanners.bind(
                                        null,
                                        product.relatedBanners,
                                        item.time,
                                      )}
                                    >
                                      <span
                                        style={{
                                          display: 'inline-block',
                                          marginRight: '5px',
                                        }}
                                      >
                                        <Icons
                                          name='imageholder'
                                          color='#fff'
                                        />
                                      </span>
                                      {t('Details')}
                                    </a>
                                  </span>
                                ) : (
                                  <span className='table-el' key={product}>
                                    -
                                  </span>
                                ),
                              )
                            ) : (
                              <span className='table-el'>-</span>
                            )}
                          </div>
                        </Cell>
                        <Cell>
                          <div>
                            <a
                              className='secondary-action helper-action'
                              onClick={this.showPurchaseDetail.bind(null, item)}
                              style={{ paddng: '8px 10px' }}
                            >
                              {t('Details')}
                            </a>
                          </div>
                        </Cell>
                      </Row>
                    );
                  })}

                  {rows.length === 0 && !this.state.showLoadingAnimation && (
                    <div className='data-content-message'>
                      <div className='data-content-message-text'>
                        <i className='icon-info' />
                        {t('There are no records to show.')}
                      </div>
                    </div>
                  )}
                </StickyTable>
              </div>
            ) : (
              <div className='data-content-message'>
                <div className='data-content-message-text'>
                  <i className='icon-info' />
                  {t('There are no records to show.')}
                </div>
              </div>
            )}
            {rows.length > 0 && (
              <TotalsLine
                totals={[this.state.totals]}
                totalsLabels={[salesTable.totals]}
                isLoading={this.state.isTotalSalesLoading}
                selectedCurrency={this.state.selectedCurrency}
              />
            )}
          </div>
          {this.state.showScrollToTop && <ScrollUpButtonAnalytics />}
        </div>
        {badResponseCatVisible && <BadResponseCat />}
      </>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  switchedAccount: store.switchedUser.switchedUser.account,
  userAccount: store.user.user.account,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  ui: store.ui.ui,
  pageSize: store.filters.pageSize,
  pageNumber: store.filters.pageNumber,
  count: store.filters.count,
  update: store.filters.update,
  reset: store.filters.reset,
  badResponseCatVisible: store.ui.badResponseCatVisible,
});

export default connect(mapStatesToProps)(Sales);
