import React from 'react';
import Monitoring from './Monitoring';
import { MonitoringProvider } from './store/MonitoringProvider';

/**
 * @name MonitoringProvider
 * @description MonitoringProvider component that renders the monitoring provider
 * @return {JSX.Element}
 * @constructor
 */
const MonitoringWithProvider = () => {
  return (
    <MonitoringProvider>
      <Monitoring />
    </MonitoringProvider>
  );
};

export default MonitoringWithProvider;
