import Services from '../../../../service/Services';

class CustomerProfileService extends Services {
  constructor({ endPoint, paginationIsActive }) {
    super({ endPoint, paginationIsActive });
  }
}

export default new CustomerProfileService({
  endPoint: 'customerProfile/list',
  paginationIsActive: true,
});
