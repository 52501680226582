/**
 * @fileOverview This file open the modal of the create or edit users of multiuser.
 * @requires react
 */
import React, { useState, useEffect } from 'react';
/**
 * Module dependencies
 */
import { t } from '../../system/ui';
import Icons from '../icons';
import Icon from '../icon';

const modules = [
  {
    label: t('Recommendation'),
    value: 'RECOUSER',
  },
  {
    label: t('Trendify'),
    value: 'TRENDUSER',
  },
  {
    label: t('Bannerify'),
    value: 'BANNERUSER',
  },
];

const defaultSelectedModules = ['RECOUSER', 'TRENDUSER', 'BANNERUSER'];

const filterModulesByDefaultModules = mixedModules => {
  return mixedModules?.filter(module =>
    defaultSelectedModules.includes(module),
  );
};

/**
 * This component is used to edit or create user.
 * Only will appear for user who has MultiUser type.
 * @param {Object[]} switchableAccounts - List of Switchable Accounts
 * @param {String[] | Number[]} selectedAccounts - List of Selected Accounts
 * @param {String[]} defaultModules - List of Modules that defined as default
 * @param {Function} onModulesChange - setState for new values from parent component
 * @param {Function} selectAllAccounts - Select all accounts and setState to selectedAccounts from parent component
 * @param {Function} toggleAccountSelect - Toggle the selected status of account by accountId from parent component
 * @param {Function} searchAccounts - Search accounts and setState to switchableAccounts from parent component
 * @return {React.ReactDOM}
 * */
const UserAccountsList = ({
  switchableAccounts,
  selectedAccounts,
  defaultModules,
  onModulesChange,
  selectAllAccounts,
  toggleAccountSelect,
  searchAccounts,
}) => {
  const [selectedModules, setSelectedModules] = useState([]);
  const [isAllAccountsSelected, setIsAllAccountsSelected] = useState(false);

  useEffect(() => {
    setSelectedModules(
      filterModulesByDefaultModules(defaultModules) || defaultSelectedModules,
    );
  }, []);

  useEffect(() => {
    const isAllSelected = switchableAccounts.every(account =>
      selectedAccounts.includes(account.accountId),
    );

    setIsAllAccountsSelected(isAllSelected);
  }, [selectedAccounts, switchableAccounts]);

  useEffect(() => {
    const filteredDefaultModules = filterModulesByDefaultModules(
      defaultModules,
    );
    if (selectedModules?.length !== filteredDefaultModules?.length) {
      setSelectedModules(filteredDefaultModules || defaultSelectedModules);
    }
  }, [defaultModules]);

  useEffect(() => {
    if (onModulesChange) {
      onModulesChange(selectedModules);
    }
  }, [selectedModules]);

  const toggleModule = module => {
    const hasSelectedModule = selectedModules.some(
      selectedModule => selectedModule === module,
    );

    if (hasSelectedModule) {
      const filteredSelectedModules = selectedModules.filter(
        selectedModule => selectedModule !== module,
      );
      setSelectedModules(filteredSelectedModules);
    } else {
      setSelectedModules([...selectedModules, module]);
    }
  };

  const toggleAllModules = () => {
    const isAllModulesSelected = selectedModules.length === modules.length;

    if (isAllModulesSelected) {
      setSelectedModules([]);
    } else {
      const allModules = modules.map(module => {
        return module.value;
      });
      setSelectedModules(allModules);
    }
  };

  const isAllModulesSelected = () => {
    return modules.every(module => selectedModules.includes(module.value));
  };

  return (
    <li className='user-accounts-select-container'>
      <h3>{t('Module Access')}</h3>
      <div className='privilege-select'>
        <div
          onClick={() => toggleAllModules()}
          className='privilige-select-item'
        >
          <div className='privilige-select-value'>
            <Icons
              name={
                isAllModulesSelected() ? 'checkboxSelected' : 'checkboxEmpty'
              }
              width='20'
              height='20'
            />
          </div>
          <div>{t('All')}</div>
        </div>
        {modules.map(module => {
          return (
            <div
              key={module.value}
              onClick={() => toggleModule(module.value)}
              className='privilige-select-item'
            >
              <div className='privilige-select-value'>
                <Icons
                  name={
                    selectedModules.indexOf(module.value) > -1
                      ? 'checkboxSelected'
                      : 'checkboxEmpty'
                  }
                  width='20'
                  height='20'
                />
              </div>
              <div>{module.label}</div>
            </div>
          );
        })}
      </div>
      <h3>{t('Account Access')}</h3>
      <div className='user-accounts-select'>
        <span>{t('Select Accounts')}</span>
        {switchableAccounts.length > 0 && (
          <span>
            <label className='item item-stacked is-checkbox'>
              <input
                type='checkbox'
                checked={isAllAccountsSelected}
                onChange={() => selectAllAccounts(!isAllAccountsSelected)}
              />
              <span className='item-label'>{t('Select All')}</span>
            </label>
          </span>
        )}
      </div>
      <div className='user-accounts-select-search'>
        <label className='item is-stacked is-search'>
          <Icon name='magnify' />
          <input
            type='text'
            placeholder={t('Search Account')}
            className='text-field'
            onChange={searchAccounts}
          />
        </label>
      </div>
      <div className='user-accounts-select-list'>
        {switchableAccounts.map(account => {
          return (
            <div
              key={account.accountId}
              className='user-accounts-select-account'
              onClick={() => toggleAccountSelect(account.accountId)}
            >
              <div className='select-checkbox'>
                <Icons
                  name={
                    selectedAccounts.indexOf(account.accountId) > -1
                      ? 'checkboxSelected'
                      : 'checkboxEmpty'
                  }
                  width='20'
                  height='20'
                />
              </div>
              <div className='select-details'>
                <span>{account.domain}</span>
                <p>{account.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </li>
  );
};

export default UserAccountsList;
