import React from 'react';
import Icons from '../../icons';

const InsightsPlaceHolder = () => (
  <div className='insight-placeholder'>
    <Icons name='insightProduct' />
  </div>
);

InsightsPlaceHolder.propTypes = {};

export default InsightsPlaceHolder;
