/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import {config} from "../constants/config";
import {t} from "../system/ui";
import {setTitle} from "../system/document";

import { uiActions, campaignActions, filterActions } from '../actions';
import {getPersonalizationReport} from "../modules/reports/ajax";
import {transFormPersReport} from "../modules/transform";

import PersonalizationWidgetReport from "../components/widget/personalizationreport";
import StatusFilters from "../components/filters/status-filters";
import PushFilters from "../components/filters/push-filters";
import {isAfterNow, isBeforeNow} from "../system/date";

class Report extends React.Component {
  constructor(props) {
    super(props);

    let children =
      config.menus.primary['behavioural-targeting'].children[this.props.params.report];

    this.state = {
      sub: this.props.params.report,
      children: children,
      pageTitle: children.name,
      campaigns: [],
      _campaigns: [],
      personalizationCampaigns: {
        popup: "POPUP_BANNER",
        hero_banner: "HERO_BANNER",
        gamification: "GAMIFICATION",
        popup_recommendation: "POPUP_RECOMMENDATION",
        push_permission: "PUSH_PERMISSION",
        notification_bar: "NOTIFICATION_BAR",
        email_collection: "NEWSLETTER",
        push_notification: "PUSH_NOTIFICATION",
        form_survey: "FORM",
        segmentation: "SEGMENTATION",
        'see-all': 'SEE_ALL',
        countdown: 'COUNTDOWN',
      },
      // grantedPermissionCount: 0,
      selectedPushStatus: "inFuture",
      campaingsNotRequested: true
    };

    this.changeStatus = this.changeStatus.bind(this);
    this.changeMedium = this.changeMedium.bind(this);
    this.getReport = this.getReport.bind(this);
    this.onPushFilterChange = this.onPushFilterChange.bind(this);
  }

  componentDidMount() {
    setTitle(t(this.state.pageTitle) + " " + t("Reports"));
    campaignActions.filterActiveCampaigns();
    let isPushReports = this.props.params.report === "push_notification";
    this.getReport(this.props.range, this.props.status, isPushReports);
  }

  getReport(boundaries, status, isPushReports) {
    uiActions.isLoading();
    let timeRange = {};
    timeRange.start = boundaries[0].format("YYYY-MM-DD");
    timeRange.end = boundaries[1].format("YYYY-MM-DD");
    getPersonalizationReport(
      this.props.instanceId,
      timeRange,
      this.state.personalizationCampaigns[this.props.params.report],
      campaignReport => {
        let campaigns = campaignReport.filter(function(campaign) {
          status = status === "PASSIVE" ? "ARCHIVE" : status;
          return campaign.campaign.status === status;
        });
        this.setState(
          {
            campaigns: campaigns,
            _campaigns: campaigns,
            // grantedPermissionCount: permissionCount,
            campaingsNotRequested: false
          },
          () => {
            if (isPushReports) {
              this.onPushFilterChange(this.state.selectedPushStatus);
            }
            filterActions.filtersUpdated();
            filterActions.filtersReset();
            uiActions.isLoaded();
          }
        );
      }
    );
  }
  componentWillReceiveProps(newProps) {
    if (newProps.status !== this.props.status) {
      this.changeStatus(newProps.range, newProps.status);
      this.changeMedium(newProps.medium);
    }

    if (newProps.medium !== this.props.medium) {
      this.changeMedium(newProps.medium);
    }

    if (newProps.user !== this.props.user) {
      this.getReport(
        newProps.range,
        newProps.status,
        newProps.params.report === "push_notification"
      );
    }

    if (
      (newProps.update === true || newProps.reset === true) &&
      newProps.ui !== 'isLoading'
    ) {
      this.getReport(
        newProps.range,
        newProps.status,
        newProps.params.report === 'push_notification',
      );
    }
  }

  changeStatus(range, status) {
    this.getReport(range, status);
  }

  changeMedium(value) {
    let visibleCampaigns = this.state._campaigns,
      filteredMediums = [],
      search = [];

    if (!value.length) {
      search = ["ALL"];
    }

    search = search.concat(value);

    if (search.indexOf("PCTABLET") > -1) {
      search = search.concat(["PC", "TABLET"]);
    }

    if (search.indexOf("ANDROID") > -1 || search.indexOf("IOS") > -1) {
      search = search.concat(["ANDROIDIOS"]);
    }

    visibleCampaigns.forEach(item => {
        if (search.indexOf("ALL") > -1) {
          filteredMediums.push(item);
        } else {
          if(item.campaign.devices.some(deviceItem => search.includes(deviceItem))){
            filteredMediums.push(item);
          }
        }
    });

    this.setState({
      campaigns: filteredMediums,
      campaingsNotRequested: false
    });
  }

  onPushFilterChange(value) {
    let newStatus = value; // inFuture or sent
    let list = this.state._campaigns;
    let campaigns = [];
    list.forEach(campaign => {
      if (
        campaign.campaign.status !== "ARCHIVE" &&
        value === "inFuture" &&
        (campaign.campaign.schedule.type === "periodic" ||
          (campaign.campaign.schedule.type === "one_time" &&
            isAfterNow(moment(campaign.campaign.schedule.date))))
      ) {
        campaigns.push(campaign);
      }
      if (
        campaign.campaign.status !== "ARCHIVE" &&
        value === "sent" &&
        campaign.campaign.schedule.type === "one_time"
      ) {
        if (isBeforeNow(moment(campaign.campaign.schedule.date))) {
          campaigns.push(campaign);
        }
      }
    });
    this.setState({
      selectedPushStatus: newStatus,
      campaigns: campaigns,
      campaingsNotRequested: false
    });
  }

  render() {
    let campaignsStatus = this.props.status,
      statusText = "";
    if (campaignsStatus === "ACTIVE") {
      statusText = t("Live");
    } else if (campaignsStatus === "TEST") {
      statusText = t("Test");
    } else {
      statusText = t("Archived");
    }
    statusText += " " + t(this.state.children.name);
    let sortedCampaigns = [];
    if (this.props.params.report === "push_notification") {
      sortedCampaigns = this.state.campaigns.sort(function(a, b) {
        let keyA = a.campaign.schedule.date,
          keyB = b.campaign.schedule.date;
        // Compare the 2 dates
        if (keyB < keyA) {
          return -1;
        }
        if (keyB > keyA) {
          return 1;
        }
        return 0;
      });
    } else {
      sortedCampaigns = this.state.campaigns;
    }

    return (
      <div>
        <div className="page-filters has-filter-form">
          {this.props.params.report === "push_notification" ? (
            <PushFilters
              onPushFilterChange={this.onPushFilterChange}
              selectedValue={this.state.selectedPushStatus}
            />
          ) : (
            <StatusFilters isEngagement={true} />
          )}
        </div>

        {sortedCampaigns.length > 0 ? (
          <div>
            {sortedCampaigns.map(campaign => {
              return (
                <PersonalizationWidgetReport
                  key={campaign.campaign.instanceId}
                  campaign={campaign.campaign}
                  logs={transFormPersReport(campaign.logs)}
                  //  grantedPermissionCount={this.state.grantedPermissionCount}
                />
              );
            })}
          </div>
        ) : (
          this.props.ui !== "isLoading" && (
            <div className="no-report">
              <p className="no-report-info">
                {t("no-personalization-report-info", statusText)}
              </p>
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  update: store.filters.update,
  reset: store.filters.reset,
  status: store.campaigns.status,
  medium: store.campaigns.medium
});

export default connect(mapStatesToProps)(Report);
