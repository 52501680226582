"use strict";

import { createAction } from "../system/store";
import * as synonymActions from "../constants/actions/synonyms";


export const resetSynonyms = () => {
    createAction(synonymActions.default.RESET_SYNONYMS);
};

export const languageChanged = (selectedLanguage) => {
    createAction(synonymActions.default.LANGUAGE_CHANGED, { selectedLanguage });
};

export const addSynonymsItemGroup = (selectedLanguage, selectedSynonyms, leftValue, rightValues) => {
    createAction(synonymActions.default.ADD_SYNONYMS_ITEM_GROUP, { selectedLanguage, selectedSynonyms, leftValue, rightValues });
};

export const updateSynonymItem = (synonymItem, language, operator) => {
    createAction(synonymActions.default.UPDATE_SYNONYMS_ITEM, { synonymItem, language, operator });
};

export const removeSynonymsItem = (selectedLanguage, operator, id) => {
    createAction(synonymActions.default.REMOVE_SYNONYMS_ITEM, {selectedLanguage, operator, id});
};

export const addAllSynonyms = (synonyms) => {
    createAction(synonymActions.default.ADD_ALL_SYNONYM, { synonyms });
}

export default{
    resetSynonyms,
    languageChanged,
    addSynonymsItemGroup,
    updateSynonymItem,
    removeSynonymsItem,
    addAllSynonyms,
}