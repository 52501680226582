const setGlobalBoost = dispatch => {
  return boost => {
    dispatch({
      type: 'GLOBAL_BOOST',
      payload: boost,
    });
  };
};

const setGlobalBoostSearchIsActive = dispatch => {
  return boostSearchIsActive => {
    dispatch({
      type: 'GLOBAL_BOOST_SEARCH_IS_ACTIVE',
      payload: boostSearchIsActive,
    });
  };
};

const setProductPin = dispatch => {
  return productPin => {
    dispatch({
      type: 'PRODUCT_PIN',
      payload: productPin,
    });
  };
};

const setPPSearchIsActive = dispatch => {
  return ppSearchIsActive => {
    dispatch({
      type: 'PP_SEARCH_IS_ACTIVE',
      payload: ppSearchIsActive,
    });
  };
};

const setProductExclusion = dispatch => {
  return productExclusion => {
    dispatch({
      type: 'PRODUCT_EXCLUSION',
      payload: productExclusion,
    });
  };
};

const setPESearchIsActive = dispatch => {
  return peSearchIsActive => {
    dispatch({
      type: 'PE_SEARCH_IS_ACTIVE',
      payload: peSearchIsActive,
    });
  };
};

const setSearchResultsIndex = dispatch => {
  return searchResultsIndex => {
    dispatch({
      type: 'SEARCH_RESULTS_INDEX',
      payload: searchResultsIndex,
    });
  };
};

const addNewSearchResultsIndex = dispatch => {
  return searchResultsIndex => {
    dispatch({
      type: 'ADD_NEW_SEARCH_RESULTS_INDEX',
      payload: searchResultsIndex,
    });
  };
};

const editSearchResultsIndex = dispatch => {
  return searchResultsIndex => {
    dispatch({
      type: 'EDIT_SEARCH_RESULTS_INDEX',
      payload: searchResultsIndex,
    });
  };
};

const resetSearchResultsIndex = dispatch => {
  return () => {
    dispatch({
      type: 'RESET_SEARCH_RESULTS_INDEX',
    });
  };
};

export {
  setGlobalBoost,
  setGlobalBoostSearchIsActive,
  setProductPin,
  setProductExclusion,
  setSearchResultsIndex,
  addNewSearchResultsIndex,
  editSearchResultsIndex,
  setPPSearchIsActive,
  setPESearchIsActive,
  resetSearchResultsIndex,
};
