/**
 * @author Barış Altun
 */

"use strict";

import React from "react";
import { connect } from "react-redux";

import Icon from "../icon";
import Icons from "../icons";

import { t } from "../../system/ui";
import Select from "react-select";
import Tooltip from "../../components/tooltip";

let types = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
];
class CriteriaCategoryS extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileValue:3,
      value: 3,
      clickable: false,
      treeView: false,
      highlight: true,
      type: "CATEGORY",
    };
    this.onChange = this.onChange.bind(this);
    this.setValues = this.setValues.bind(this);
    this.handleClickable = this.handleClickable.bind(this);
    this.handleTreeView = this.handleTreeView.bind(this);
    this.handleHighlight = this.handleHighlight.bind(this);
  }

  componentDidMount() {
    this.setValues();
  }

  setValues() {
    this.setState({
      value: this.props.itemCount!== undefined
        ? this.props.itemCount
        : this.props.values !== undefined
        ? this.props.values.itemCount.value
        : 3,
      mobileValue: this.props.mobileItemCount !== undefined
        ? this.props.mobileItemCount
        : this.props.values !== undefined
        ? this.props.values.mobileItemCount.value
        : 3,
      clickable: this.props.clickable,
      treeView:
        this.props.treeView !== undefined
          ? this.props.treeView
          : this.props.categoryTreeView,
      highlight:
        this.props.highlight !== undefined ? this.props.highlight : false,
    },()=>{
      this.props.onSearchCriteriaChange(
        this.refs.inputs,
        this.props.id,
        this.state
      )
    });
  }

  onChange(type,e) {
    if (type === "Pc") {
      this.setState({ value: e.value }, () => {
        this.props.onSearchCriteriaChange(
          this.refs.inputs,
          this.props.id,
          this.state
        );
      });
    } else {
      this.setState({ mobileValue: e.value }, () => {
        this.props.onSearchCriteriaChange(
          this.refs.inputs,
          this.props.id,
          this.state
        );
      });
    }
  }

  handleClickable() {
    this.setState(
      {
        clickable: !this.state.clickable,
      },
      () => {
        this.props.onSearchCriteriaChange(
          this.refs.inputs,
          this.props.id,
          this.state
        );
      }
    );
  }
  handleTreeView() {
    this.setState(
      {
        treeView: !this.state.treeView,
      },
      () => {
        this.props.onSearchCriteriaChange(
          this.refs.inputs,
          this.props.id,
          this.state
        );
      }
    );
  }

  handleHighlight() {
    this.setState(
      {
        highlight: !this.state.highlight,
      },
      () => {
        this.props.onSearchCriteriaChange(
          this.refs.inputs,
          this.props.id,
          this.state
        );
      }
    );
  }
  render() {
    return (
      <li className="wizard-criterion" key={this.props.id}>
        <span ref="inputs">
          <span className="criteria-type">
            <Icons name={"categoryBoxes"} color="#5a7390" />
          </span>
          <span className="criteria-item-count">
            <span className="item-label keyword">
              <Icon name="mobile" /> {"Category"}
            </span>
            <Select
              value={this.state.mobileValue}
              options={types}
              name="itemCount"
              clearable={false}
              searchable={false}
              onChange={this.onChange.bind(this, "Mobile")}
              className="keyword-criteria criteria-field"
              style={{
                display: "inline-block",
                top: "12px",
                boxShadow: "rgba(0, 0, 0, 0.06) 0px 1px 0px",
              }}
            />

            <span className="item-label mobile">
              {" "}
              <Icon name="pc" /> {"Category"}
            </span>
            <Select
              value={this.state.value}
              options={types}
              name="itemCount"
              clearable={false}
              searchable={false}
              onChange={this.onChange.bind(this, "Pc")}
              className="keyword-criteria criteria-field keyword-mobile"
              style={{
                display: "inline-block",
                top: "12px",
                boxShadow: "rgba(0, 0, 0, 0.06) 0px 1px 0px",
              }}
            />

            <span className="item-label tip">
              <Tooltip
                content={t(
                  "If you want to redirect your customers to the relevant url when they click the topic under Categories and Popular Categories assets, please click this option."
                )}
              >
                <Icon name="info" />
              </Tooltip>
            </span>
            <span
              className="item-label clickable"
              onClick={this.handleClickable}
            >
              {t("Clickable")}{" "}
              <span style={{ top: "2px", left: "5px", position: "relative" }}>
                <Icons
                  name={
                    this.state.clickable ? "checkboxSelected" : "checkboxEmpty"
                  }
                  width="12"
                  height="12"
                  style={{ top: "2px", left: "5px", position: "relative" }}
                />
              </span>
            </span>
            <span className="item-label tip" style={{ left: "507px" }}>
              <Tooltip
                content={t(
                  "If you want to show your categories as Category Tree in the searchbox, please click this option."
                )}
              >
                <Icon name="info" />
              </Tooltip>
            </span>
            <span
              className="item-label clickable"
              style={{ left: "534px" }}
              onClick={this.handleTreeView}
            >
              {t("Category Tree View")}
              {""}
              <span style={{ top: "2px", left: "5px", position: "relative" }}>
                <Icons
                  name={
                    this.state.treeView ? "checkboxSelected" : "checkboxEmpty"
                  }
                  width="12"
                  height="12"
                />
              </span>
            </span>
            <span className="item-label tip" style={{ left: "675px" }}>
              <Tooltip
                content={t(
                  "Highlight the text on the Category asset that is matching the search query."
                )}
              >
                <Icon name="info" />
              </Tooltip>
            </span>
            <span
              className="item-label clickable"
              style={{ left: "702px" }}
              onClick={this.handleHighlight}
            >
              {t("Highlight")}
              {""}
              <span style={{ top: "2px", left: "5px", position: "relative" }}>
                <Icons
                  name={
                    this.state.highlight ? "checkboxSelected" : "checkboxEmpty"
                  }
                  width="12"
                  height="12"
                />
              </span>
            </span>
            <div className="buttons-criteria">
              <a
                onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
                className={
                  this.props.isOnlySelection
                    ? "cancel-action cancel-action--disabled"
                    : "cancel-action"
                }
              >
                <Icons name="crossCircle" />
              </a>
            </div>
          </span>
        </span>
      </li>
    );
  }
}

const mapStatesToProps = (store) => ({
  validate: store.ui.validate,
});

export default connect(mapStatesToProps)(CriteriaCategoryS);
