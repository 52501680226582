import Cookies from 'js-cookie';
import React, { createContext, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import {
  ACCOUNT_HOLD_TYPES,
  PRIVILEGES,
  REDIRECT_ROUTE,
  TOKEN_LIST,
} from './constants';

const AccountStatusContext = createContext('');

function AccountStatusProvider(props) {
  const getAndRemoveCookies = useCallback(() => {
    if (window.location.hostname.includes('localhost')) {
      return Object.keys(TOKEN_LIST).forEach(token =>
        TOKEN_LIST[token]
          ? Cookies.remove(TOKEN_LIST[token], { path: '/' })
          : null,
      );
    }
    return Object.keys(TOKEN_LIST).forEach(token =>
      TOKEN_LIST[token]
        ? Cookies.remove(TOKEN_LIST[token], {
            path: '/',
            domain: '.segmentify.com',
          })
        : null,
    );
  }, []);

  const checkPrivileges = props?.user?.privileges?.some(
    privilege => PRIVILEGES[privilege],
  );

  const userAccountTypeExists =
    typeof props.user?.account?.type !== 'undefined';

  const accountHoldTypeIsPaying =
    ACCOUNT_HOLD_TYPES[props?.user?.account?.type] ===
      ACCOUNT_HOLD_TYPES.CHURN ||
    ACCOUNT_HOLD_TYPES[props?.user?.account?.type] ===
      ACCOUNT_HOLD_TYPES.ONHOLD ||
    ACCOUNT_HOLD_TYPES[props?.user?.account?.type] ===
      ACCOUNT_HOLD_TYPES.FAILED;

  useEffect(() => {
    if (
      userAccountTypeExists &&
      accountHoldTypeIsPaying &&
      checkPrivileges === false
    ) {
      getAndRemoveCookies();
      browserHistory.push({
        pathname: REDIRECT_ROUTE,
      });
    }
  }, [
    props.user?.privileges,
    props.user.account?.type,
    props.switchedUser?.privileges,
    props.switchedUser?.account?.type,
  ]);

  return (
    <AccountStatusContext.Provider>
      {props.children}
    </AccountStatusContext.Provider>
  );
}

const mapStateToProps = store => ({
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStateToProps)(AccountStatusProvider);
