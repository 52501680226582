import React, { useContext } from 'react';
import './SFYAddBundleActions.scss';
import { Context as ShopTheLookContext } from '../../../views/dynamic-bundle/shop-the-look/store/shopTheLookProvider';

const SFYAddBundleActions = () => {
  const { setSTLBundle } = useContext(ShopTheLookContext);

  const addProductBundle = () => {
    const bundle = {
      type: 'product',
      productId: '',
      name: '',
      inStock: true,
    };
    setSTLBundle(bundle);
  };

  const addCategoryBundle = () => {
    const bundle = {
      type: 'category',
      category: '',
      productId: '',
      params: {},
    };
    setSTLBundle(bundle);
  };

  return (
    <>
      <div className='sfy-footer-add-bundle-field'>
        <button
          type='button'
          className='add-bundle-button'
          onClick={() => addCategoryBundle()}
        >
          + Add Category
        </button>
        <span className='add-separator'>OR</span>
        <button
          type='button'
          className='add-bundle-button'
          onClick={() => addProductBundle()}
        >
          + Add Product
        </button>
      </div>
    </>
  );
};

export default SFYAddBundleActions;
