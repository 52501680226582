import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoaderPlaceholder from '../components/widget/loader-placeholder';
import { filterActions, uiActions } from '../actions';
import { queryDate, getNowByTimeZone } from '../system/date';
import { setTitle } from '../system/document';
import { hasModule } from '../modules/auth/user';
import { getAllSegment, getAllStat } from '../modules/segmentation/ajax';
import AllSegments from './segmentation.viewall';
import { smartSegments as SmartSegments } from './segmentation.smart';

class Segmentations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      segments: [],
      activeSegments: [],
      draftSegments: [],
      passiveSegments: [],
      smartSegments: [],
      scrollCount: 0,
      stats: {},
    };

    this.getSegments = this.getSegments.bind(this);
    this.getStats = this.getStats.bind(this);
    this.consolidateSegments = this.consolidateSegments.bind(this);
    this.increaseScrollCount = this.increaseScrollCount.bind(this);
  }

  componentDidMount() {
    uiActions.hideBreadcrumb();
    setTitle('All Segments');

    this.getSegments();
    this.getStats();

    window.onscroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        this.increaseScrollCount();
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.update === true || this.props.user !== prevProps.user) {
      this.getSegments();
      this.getStats();
    }
  }

  getSegments() {
    if (hasModule('segmentation')) {
      uiActions.isLoading();
      getAllSegment(response => {
        if (response) {
          const segments = response.sort((a, b) =>
            a.name === 'Lost Customers' ||
            (b.name !== 'Lost Customers' && a.id >= b.id)
              ? 1
              : -1,
          );
          this.setState(
            {
              segments,
              isLoading: false,
            },
            () => this.consolidateSegments(response),
          );
        }
        uiActions.isLoaded();
      });
      // make update paramater false
      filterActions.filtersUpdated();
    }
  }

  getStats() {
    const opts = {};
    opts.start = queryDate(getNowByTimeZone().subtract(6, 'month'));
    opts.end = queryDate(getNowByTimeZone());
    getAllStat(response => {
      if (response) {
        this.setState({ stats: response });
      }
    }, opts);
  }

  increaseScrollCount() {
    this.setState({
      scrollCount: this.state.scrollCount + 1,
    });
  }

  consolidateSegments(segments) {
    const active = [];
    const draft = [];
    const passive = [];
    const all = [];
    segments.forEach(s => {
      if (s.segment.type === 'SMART') {
        all.push(s.segment);
        if (s.segment.status === 'ACTIVE') {
          active.push(s.segment);
        } else if (s.segment.status === 'DRAFT') {
          draft.push(s.segment);
        } else if (s.segment.status === 'PASSIVE') {
          passive.push(s.segment);
        }
      }
    });

    this.setState({
      activeSegments: active,
      draftSegments: draft,
      passiveSegments: passive,
      smartSegments: all,
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div>
          <div style={{ marginTop: '80px' }}>
            <LoaderPlaceholder />
          </div>
        </div>
      );
    }
    let element = '';
    switch (this.props.params.segment) {
      case 'smart-segments':
        element = (
          <div>
            <SmartSegments
              activeSegments={this.state.activeSegments}
              draftSegments={this.state.draftSegments}
              passiveSegments={this.state.passiveSegments}
              segments={this.state.smartSegments}
              key='smart-segments'
              placeHolder={<LoaderPlaceholder />}
            />
          </div>
        );
        break;
      default:
        element = (
          <div>
            <AllSegments
              segments={this.state.segments}
              stats={this.state.stats}
              key='view-all'
              placeHolder={<LoaderPlaceholder />}
            />
          </div>
        );
    }
    return element;
  }
}

const MapStatesToProps = store => ({
  user: store.user.user,
  update: store.filters.update,
  status: store.segment.status,
});

export default connect(MapStatesToProps)(Segmentations);
