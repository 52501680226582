import React from 'react';
import Select from 'react-select';

import { t } from '../../system/ui';
import { isSuperiestUser } from '../../modules/auth/user';
import {
  getAccountAllOwners,
  getAccountAllIndustries,
  getAccountTiers,
  getAccountTypes,
  getAccountTypesMap,
} from '../../modules/account/ajax';

class AccountFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialFilters();

    this.onChange = this.onChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.callFilterAccounts = this.callFilterAccounts.bind(this);
  }

  componentDidMount() {
    const allOwner = [{ name: 'owner', value: 'ALL', label: 'ALL' }];
    const allType = [{ name: 'type', value: 'ALL', label: 'ALL' }];
    const allTier = [{ name: 'tier', value: 'ALL', label: 'ALL' }];
    const allIndustry = [{ name: 'industry', value: 'ALL', label: 'ALL' }];

    getAccountAllOwners(null, true, response => {
      this.setState({ owners: allOwner.concat(response) });
    });
    this.setState({ tiers: allTier.concat(getAccountTiers()) });
    if (this.props.isAlertFilter) {
      this.setState({ types: allType.concat(getAccountTypes('ALL', true)) });
    } else {
      this.setState({ types: allType.concat(getAccountTypes('ALL')) });
    }
    getAccountAllIndustries(response => {
      this.setState({ industries: allIndustry.concat(response) });
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.reset === true) {
      this.reset();
    }
  }

  onChange(selectedOption) {
    if (selectedOption && selectedOption.name && selectedOption.value) {
      this.setState(prevState => ({
        ...prevState,
        filter: {
          ...prevState.filter,
          [selectedOption.name]: selectedOption.value,
        },
      }));
    }
  }

  getInitialFilters() {
    return {
      ...this.state,
      filter: {
        owner: '',
        type: '',
        tier: '',
        industry: '',
      },
    };
  }

  resetFilters() {
    this.setState(this.getInitialFilters(), () => {
      this.callFilterAccounts();
    });
  }

  updateFilters() {
    this.callFilterAccounts();
  }

  callFilterAccounts() {
    const filter = {};
    this.state.filter.owner !== '' &&
      this.state.filter.owner !== 'ALL' &&
      (filter.owner = this.state.filter.owner);
    this.state.filter.type !== '' &&
      this.state.filter.type !== 'ALL' &&
      (filter.accountType = getAccountTypesMap(this.state.filter.type)[
        this.state.filter.type
      ]);
    this.state.filter.tier !== '' &&
      this.state.filter.tier !== 'ALL' &&
      (filter.tier = this.state.filter.tier);
    this.state.filter.industry !== '' &&
      this.state.filter.industry !== 'ALL' &&
      (filter.industry = this.state.filter.industry);
    this.props.filterAccounts(filter);
  }

  render() {
    return (
      <div className='account-filters-comp'>
        {isSuperiestUser() && (
          <label className='filter-item account-filters-comp__owner-select'>
            <Select
              ref='owner'
              name='owner'
              clearable={false}
              value={this.state.filter.owner}
              options={this.state.owners}
              onChange={this.onChange}
              placeholder={t('Owners')}
              id='account-filters-owner-select'
            />
          </label>
        )}

        <label className='filter-item account-filters-comp__type-select'>
          <Select
            ref='type'
            name='type'
            clearable={false}
            value={this.state.filter.type}
            onChange={this.onChange}
            options={this.state.types}
            placeholder={t('Type')}
            id='account-filters-type-select'
          />
        </label>

        <label className='filter-item account-filters-comp__tier-select'>
          <Select
            ref='tier'
            name='tier'
            clearable={false}
            value={this.state.filter.tier}
            onChange={this.onChange}
            options={this.state.tiers}
            placeholder={t('Tier')}
            id='account-filters-tier-select'
          />
        </label>

        <label className='filter-item account-filters-comp__industry-select'>
          <Select
            ref='industry'
            name='industry'
            clearable={false}
            value={this.state.filter.industry}
            onChange={this.onChange}
            options={this.state.industries}
            placeholder={t('Industry')}
            id='account-filters-industry-select'
          />
        </label>

        <div className='account-filters-comp__actions'>
          <a
            className='account-filters-comp__actions__update'
            onClick={this.updateFilters}
          >
            {t('Update')}
          </a>

          <a
            className='account-filters-comp__actions__reset'
            onClick={this.resetFilters}
          >
            {t('Reset Filters')}
          </a>
        </div>
      </div>
    );
  }
}

export default AccountFilter;
