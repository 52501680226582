import { getProvider, getSource, getSubPath } from '../index';
import { hasModule } from '../modules/auth/user';

export const HIDDEN_HEADER_APPS = ['DENGAGE'];

export const EXTERNAL_TRANSLATE_APPS = ['DENGAGE'];

export const MERGE_SETTINGS_TO_PRIMARY_MENU_APPS = ['DENGAGE'];

export const EXTERNAL_APPS_RESTRICTED_MODULES = {
  DENGAGE: ['PUSH', 'EMAIL', 'SEGMENTATION'],
};

export const EXTERNAL_APPS_RESTRICTED_PRIMARY_MENUS = {
  DENGAGE: ['email', 'push', 'segmentation'],
};

export const EXTERNAL_APPS_RESTRICTED_SECONDARY_MENUS = {
  DENGAGE: ['payments', 'integrations', 'email', 'push', 'feed'],
};

export const EXTERNAL_APPS_SALES_FILTERS = {
  DENGAGE: [
    {
      value: 'cancel',
      label: 'All Cancelled Sales',
      icon: 'cancelled',
      iconColor: 'black',
    },
    {
      value: 'segmentify',
      label: 'All Sales by Personalization',
      icon: '',
      iconColor: 'black',
    },
    {
      value: 'recommendation',
      label: 'All Sales by Recommendation',
      icon: 'iconHeart',
      iconColor: 'red',
    },
  ],
};

export const EXTERNAL_APPS_AVAILABLE_LINKS = {
  DENGAGE: {
    '/recommendation': '/recommendations',
    '/search': '/search',
    '/trendify': '/trendify',
    '/bannerify': '/bannerify',
    '/personalization': '/behavioural-targeting',
    '/sales': '/trendify/sales',
  },
};

export const isExternalApp = () => {
  let result = false;
  const source = getSource();
  if (source && source === 'external_app') {
    result = true;
  }
  return result;
};

export const isDengage = () => {
  let result = false;
  const provider = getProvider();
  if (isExternalApp() && provider && provider === 'DENGAGE') {
    result = true;
  }
  return result;
};

export const isHeaderHidden = () => {
  let result = false;
  const provider = getProvider();
  if (
    isExternalApp() &&
    provider &&
    HIDDEN_HEADER_APPS.indexOf(provider) > -1
  ) {
    result = true;
  }
  return result;
};

export const isExternalTranslate = () => {
  let result = false;
  const provider = getProvider();
  if (
    isExternalApp() &&
    provider &&
    EXTERNAL_TRANSLATE_APPS.indexOf(provider) > -1
  ) {
    result = true;
  }
  return result;
};

export const setPrimaryMenusForExternalApp = primaryMenu => {
  if (isExternalApp()) {
    const provider = getProvider();
    if (EXTERNAL_APPS_RESTRICTED_PRIMARY_MENUS[provider]) {
      const hasSearchModule = hasModule('search');
      const hasTrendify = hasModule('trendify');
      const hasBannerify = hasModule('bannerify');
      primaryMenu.push.visible =
        EXTERNAL_APPS_RESTRICTED_MODULES[provider].indexOf('push') > -1;
      primaryMenu.email.visible =
        EXTERNAL_APPS_RESTRICTED_MODULES[provider].indexOf('email') > -1;
      primaryMenu['behavioural-targeting'].visible = false;
      primaryMenu.search.visible = hasSearchModule;
      primaryMenu.trendify.visible = hasTrendify;
      primaryMenu.bannerify.visible = hasBannerify;
      if (!primaryMenu.trendify.visible && !primaryMenu.bannerify.visible) {
        primaryMenu.sep.separator = false;
      } else {
        primaryMenu.sep.separator = true;
      }
    }
  }
};

export const setBTReportMenusForExternalApp = primaryMenu => {
  if (isExternalApp()) {
    const provider = getProvider();
    if (EXTERNAL_APPS_RESTRICTED_PRIMARY_MENUS[provider]) {
      primaryMenu.reports.children['behavioural-targeting'].visible = false;
      primaryMenu.reports.children['segmentation-module-all'].visible = false;
      primaryMenu.reports.children[
        'executive-summary-engagement'
      ].visible = false;
      primaryMenu.reports.children['all-personalizations'].visible = false;
      primaryMenu.reports.children.popup.visible = false;
      primaryMenu.reports.children.notification_bar.visible = false;
      primaryMenu.reports.children.email_collection.visible = false;
      primaryMenu.reports.children.form_survey.visible = false;
      primaryMenu.reports.children.hero_banner.visible = false;
    }
  }
};

export const setSecondaryMenusForExternalApp = secondaryMenu => {
  const provider = getProvider();
  if (
    isExternalApp() &&
    provider &&
    EXTERNAL_APPS_RESTRICTED_SECONDARY_MENUS[provider]
  ) {
    EXTERNAL_APPS_RESTRICTED_SECONDARY_MENUS[provider].forEach(item => {
      delete secondaryMenu.settings.children[item];
    });
    const hasSearchModule = hasModule('search');
    if (!hasSearchModule) {
      delete secondaryMenu.settings.children.search;
    }
  }
};

export const mergePrimaryAndSecondaryMenusForExternalApp = (
  primaryMenu,
  secondaryMenu,
) => {
  const provider = getProvider();
  if (
    isExternalApp() &&
    provider &&
    MERGE_SETTINGS_TO_PRIMARY_MENU_APPS.indexOf(provider) > -1
  ) {
    if (secondaryMenu) {
      setSecondaryMenusForExternalApp(secondaryMenu);
      primaryMenu.settingSep.separator = true;
      primaryMenu.settings = secondaryMenu.settings;
    }
  }
};

export const getExternalSalesFilters = () => {
  if (isExternalApp()) {
    const provider = getProvider();
    if (EXTERNAL_APPS_SALES_FILTERS[provider]) {
      const filterList = EXTERNAL_APPS_SALES_FILTERS[provider];
      const hasSearchModule = hasModule('search');
      if (hasSearchModule) {
        const alreadyAdded = filterList.find(x => x.value === 'search');
        if (!alreadyAdded) {
          filterList.push({
            value: 'search',
            label: 'All Sales by Search & Discovery',
            icon: 'search',
            iconColor: 'black',
          });
        }
      }
      return filterList.filter((x, i, a) => a.indexOf(x) == i);
    }
  }
};

export const getLinkToBeRedirectedViaAvailableExternalPaths = () => {
  const subPath = getSubPath();
  if (isExternalApp() && subPath) {
    const provider = getProvider();
    if (EXTERNAL_APPS_AVAILABLE_LINKS[provider]) {
      if (EXTERNAL_APPS_AVAILABLE_LINKS[provider][subPath]) {
        return EXTERNAL_APPS_AVAILABLE_LINKS[provider][subPath];
      }
      return '/';
    }
  }
};

export default {
  isDengage,
  isExternalApp,
  isExternalTranslate,
  setPrimaryMenusForExternalApp,
  setSecondaryMenusForExternalApp,
  mergePrimaryAndSecondaryMenusForExternalApp,
  isHeaderHidden,
  getExternalSalesFilters,
  getLinkToBeRedirectedViaAvailableExternalPaths,
  setBTReportMenusForExternalApp,
};
