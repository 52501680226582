import React from "react";

class IconOpera extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox="0 0 23.4 23.4"
        fill={this.state.color}
      >
        <path
          d="M14.8,1.5c-8,0-11.9,10.5-6.9,16.7c1.7,2.1,4.2,3.6,6.9,3.6c1.7,0,3.3-0.5,4.7-1.4c-2.1,1.8-4.8,3-7.8,3c-0.2,0-0.4,0-0.6,0
	C4.9,23.1,0,18,0,11.7C0,5.2,5.2,0,11.7,0h0c3,0,5.7,1.1,7.7,3C18.1,2.1,16.5,1.5,14.8,1.5z M19.6,20.3c-1.9,1.2-4.3,1.1-6.2-0.3
	c2.6-1,4.6-4.3,4.6-8.3c0-4-1.9-7.3-4.6-8.3c2-1.4,4.3-1.4,6.3-0.2C24.6,7.8,24.6,15.6,19.6,20.3z"
        />
      </svg>
    );
  }
}

export default IconOpera;
