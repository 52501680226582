import React from 'react';
import classes from '../downloadFTP.module.scss';
import { Select } from '../../../../../../components/fields';
import { fileTypeOptions } from '../../../constants';

export function PreviewSchema({
  downloadState,
  handleOnSubmit,
  handleOnFileTypeChange,
  fileType,
}) {
  const downloadStateShape = {
    endDate: downloadState.selection[0].endDate,
    startDate: downloadState.selection[0].startDate,
    subscribed: downloadState.subscribed,
    unSubscribed: downloadState.unSubscribed,
    bounceBack: downloadState.bounceBack,
  };
  return (
    <div className={classes.preview}>
      <div className={classes.statePreview}>
        <pre>{JSON.stringify(downloadStateShape, null, 2)}</pre>
      </div>
      <div>
        <Select
          label='File Type'
          name='fileType'
          className='item-stacked one-whole'
          value={fileType}
          options={fileTypeOptions}
          required
          onChange={e => handleOnFileTypeChange(e.value)}
        />
      </div>
      <button
        type='submit'
        onClick={() => handleOnSubmit()}
        className={classes.submit}
      >
        {new Date(downloadState.selection[0].startDate).getTime() ===
        new Date(downloadState.selection[0].endDate).getTime()
          ? 'Download Last Changes'
          : 'Download Date Range Filtered Data'}
      </button>
    </div>
  );
}
