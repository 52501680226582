/**
 * @fileOverview This file helps to show notifications to users. These
 * notifications are located in the top bar of the panel next to the username.
 * @deprecated
 * @requires react
 * @requires classNames
 * @requires react-redux
 */
import React, { useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

/**
 * Module dependencies
 */
import { switchUserActions } from '../../actions';
import { getFormattedDetailedDate } from '../../system/date';
import setHighchartsDefaults from '../../modules/highcharts.global';
import Icons from '../icons';
import { t } from '../../system/ui';
import {
  initialNotificationState,
  notificationReducer,
} from '../../reducers/admin/notifications';
import {
  setNotifications,
  toggleNotificationBar,
  toggleOffNotificationBar,
} from '../../actions/admin.notification';
import {
  getNotifications,
  readNotifications,
} from '../../modules/account/ajax';

/**
 * This component helps to show notifications to users.
 * @return {React.ReactNode|null}
 */
const Notifications = () => {
  const { username } = useSelector(store => store.switchedUser.switchedUser);
  const [state, dispatch] = useReducer(
    notificationReducer,
    initialNotificationState,
  );

  const bellElement = useRef(null);
  const modalElement = useRef(null);

  const onNotificationClick = (user, id) => {
    dispatch(toggleNotificationBar());

    if (id) {
      readNotifications(id, () => {
        switchUserActions.updateSwitchUser(user);
        setHighchartsDefaults(user);
      });
    } else {
      switchUserActions.updateSwitchUser(user);
      setHighchartsDefaults(user);
    }
  };

  useEffect(() => {
    getNotifications(notificationList => {
      if (notificationList.length) {
        dispatch(setNotifications(notificationList));
      }
    });

    const onBlur = event => {
      if (
        bellElement?.current?.contains &&
        !bellElement.current.contains(event.target)
      ) {
        if (
          modalElement?.current?.contains &&
          !modalElement.current.contains(event.target)
        ) {
          dispatch(toggleOffNotificationBar());
        }
      }
    };

    document.addEventListener('mousedown', onBlur);

    return () => {
      document.removeEventListener('mousedown', onBlur);
    };
  }, [username]);

  if (username) {
    return null;
  }

  return (
    <>
      <div
        onClick={() => {
          dispatch(toggleNotificationBar());
        }}
        ref={bellElement}
        className={classNames('admin-notification-bell', {
          'admin-notification-bell--unseen': state.notification.unseen > 0,
        })}
      >
        <Icons name='bell' width='16' height='16' />
        {state.notification.unseen > 0 && (
          <div className='admin-notification-count'>
            {state.notification.unseen}
          </div>
        )}
      </div>
      {state.opened && (
        <ul className='admin-notifications-list' ref={modalElement}>
          {state.notification.items.length ? (
            state.notification.items.map(notification => {
              return (
                <NotificationItem
                  onClick={() =>
                    onNotificationClick(
                      notification.users[0],
                      notification.notification.id,
                    )
                  }
                  notification={notification.notification}
                  domain={notification?.users[0]?.account?.domain || 'Account'}
                />
              );
            })
          ) : (
            <li style={{ padding: '25px' }}>
              <div>{t('There is no new notification')}</div>
            </li>
          )}
        </ul>
      )}
    </>
  );
};

/**
 * This component creates the area where each notification will be displayed
 * @param {function} onClick Actions to be taken when clicking the notification
 * @param {Object} notification
 * @param {string} notification.id
 * @param {string} notification.status
 * @param {string} notification.time
 * @param {string} notification.text
 * @param {string} domain
 * @return {React.ReactNode}
 */
const NotificationItem = (onClick, { id, status, time, text }, domain) => {
  return (
    // TODO: Remove non-interactive elements should not be assigned mouse or
    // keyboard event listeners.
    <li
      className={classNames('admin-notification-item', {
        'admin-notification-read': status !== 'ADDED',
      })}
      onClick={onClick}
      key={id}
    >
      <div className='admin-notification-icon'>
        {status === 'ADDED' ? (
          <Icons name='link' width='20' height='20' color='#8e9ed1' />
        ) : (
          <Icons name='read' width='20' height='20' color='#83d09d' />
        )}
      </div>
      <div className='admin-notification-detail'>
        <div className='admin-notifications-domain'>
          {domain}
          <span className='admin-notification-date'>
            {getFormattedDetailedDate(time)}
          </span>
        </div>
        <div className='admin-notification-title'>{text}</div>
      </div>
    </li>
  );
};

export default Notifications;
