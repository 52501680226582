import React, { Component } from 'react';
import { t } from '../../system/ui';
import Icons from '../../components/icons';

class SampleContentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: '',
    };
    this.setContent = this.setContent.bind(this);
  }

  componentDidMount() {}

  setContent(content) {
    this.setState({
      selected: content,
    });
  }

  render() {
    return (
      <div style={{ minWidth: '800px' }} className='mail-modal-content'>
        <p>
          {t(
            'You can select a sample which is listed below and note that you can edit it.',
          )}
        </p>
        {this.props.content &&
          this.props.content.length > 0 &&
          this.props.content.map(content => {
            let iconName =
              this.state.selected === content ? 'radioSelected' : 'radioEmpty';
            return (
              <p
                key={content}
                onClick={this.setContent.bind(this, content)}
                style={{ cursor: 'pointer' }}
                className='mail-modal-item'
              >
                <Icons name={iconName} />
                <label>{content}</label>
              </p>
            );
          })}
        {(!this.props.content.length || this.props.content.length < 1) && (
          <div>There is no suggestion...</div>
        )}
        <button
          className='tertiary-action'
          style={{ float: 'right', marginTop: '20px' }}
          onClick={this.props.onSelect.bind(
            null,
            this.props.contentType,
            this.state.selected,
          )}
        >
          {t('Copy')}
        </button>
      </div>
    );
  }
}

export default SampleContentModal;
