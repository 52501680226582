/**
 * @author Bilal Cinarli
 */

"use strict";

import en from "./en";
import fr from "./fr";
import tr from "./tr";
import de from "./de";
import es from "./es";

import external_en from "./external_en";
import external_fr from "./external_fr";
import external_tr from "./external_tr";
import external_de from "./external_de";
import external_es from "./external_es";

export default {
  en,
  fr,
  tr,
  de,
  es,
  external_en,
  external_fr,
  external_tr,
  external_de,
  external_es
};
