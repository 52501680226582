import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const noUpdateIcon = ({ width, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 40 40'
      fill='none'
      x='0px'
      y='0px'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
    >
      <path
        d='M33.6385 33.889L8.31 2.92578L5.24582 5.49693L8.9749 9.82991C7.28376 11.6624 6.04367 13.9174 5.4309 16.4187L10.287 17.6124C10.648 16.1388 11.3356 14.7933 12.2687 13.6572L25.1212 28.591C23.6233 29.4857 21.8718 29.9999 20.0002 29.9999C17.554 29.9999 15.313 29.1216 13.5752 27.6631C14.248 27.4713 14.6614 27.3209 14.9715 27.1018C15.8587 26.4749 16.3377 25.4174 16.2237 24.3371C16.1676 23.8044 15.8729 23.2073 15.2835 22.0131L3.69238 25.1188L6.79821 36.7099C8.1155 36.625 8.77419 36.5825 9.26026 36.3684C10.2584 35.9288 10.9393 34.9822 11.0386 33.896C11.0833 33.4072 10.9421 32.8251 10.6532 31.7324C13.217 33.7777 16.466 35.0002 20.0007 35.0002C23.1196 35.0002 26.0162 34.0483 28.4158 32.4192L31.3403 35.8173L33.6385 33.889Z'
        fill={color}
      />
      <path
        d='M29.2556 23.7933L32.6938 27.9966C33.5415 26.6539 34.1819 25.1673 34.5704 23.5819L29.7133 22.3879C29.5949 22.8711 29.4414 23.3405 29.2556 23.7933Z'
        fill={color}
      />
      <path
        d='M14.6778 5.97206L18.1174 10.1769C18.7272 10.0607 19.3566 9.99989 20.0002 9.99989C22.4481 9.99989 24.6906 10.8795 26.4288 12.3398C25.7535 12.5322 25.3389 12.6827 25.0282 12.9023C24.141 13.5292 23.662 14.5867 23.7759 15.667C23.8321 16.1997 24.1268 16.7968 24.7162 17.9911L36.3073 14.8853L33.2015 3.29417C31.8842 3.37913 31.2255 3.42161 30.7394 3.63569C29.7413 4.07529 29.0603 5.02197 28.9611 6.1081C28.9165 6.59602 29.057 7.17673 29.3448 8.26545C26.7815 6.22172 23.5337 5.00022 20.0007 5.00022C18.1262 5.00022 16.3321 5.34404 14.6778 5.97206Z'
        fill={color}
      />
    </svg>
  );
};

noUpdateIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

noUpdateIcon.defaultProps = {
  width: '33px',
  height: '28px',
  color: '#ffffff',
};

export default noUpdateIcon;
