import React from "react";

class IconMonitorCollapse extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: "24px",
            height: "24px",
            color: "#eee"
        };

        this.setStyles = this.setStyles.bind(this);
    }

    componentDidMount() {
        this.setStyles();
    }

    setStyles() {
        let width = this.props.width ? this.props.width + "px" : this.state.width,
            height = this.props.height ? this.props.height + "px" : this.state.height,
            color = this.props.color ? this.props.color : this.state.color;

        this.setState({width, height, color});
    }

    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 400 400"
                width={this.state.width}
                height={this.state.height}
                fill={this.state.color}
            >
                <g transform="translate(0.000000,417.000000) scale(0.100000,-0.100000)"
                    stroke="none">
                    <path d="M1025 3550 c-43 -44 -76 -83 -72 -87 4 -4 168 -169 365 -365 l357
                    -358 -262 0 -263 0 -5 -22 c-3 -13 -5 -65 -3 -116 l3 -92 465 0 465 0 0 462 0
                    463 -115 0 -115 0 -3 -257 c-1 -142 -6 -258 -10 -258 -4 0 -167 160 -362 355
                    -195 195 -357 355 -360 355 -3 0 -41 -36 -85 -80z"/>
                    <path d="M3150 3275 c-195 -195 -358 -355 -362 -355 -4 0 -9 116 -10 258 l-3
                    257 -115 0 -115 0 -3 -462 -2 -463 460 0 c253 0 463 0 468 0 4 0 7 51 7 113
                    l0 112 -257 3 c-142 1 -258 6 -258 10 0 5 160 167 355 361 195 194 355 356
                    355 360 0 7 -153 161 -160 161 -3 0 -165 -160 -360 -355z"/>
                    <path d="M1147 2033 c-9 -8 -9 -196 -1 -217 5 -14 38 -16 260 -16 140 0 254
                    -3 254 -7 0 -5 -161 -169 -357 -365 l-358 -358 83 -82 82 -83 358 358 c196
                    196 360 357 365 357 4 0 7 -115 7 -255 l0 -255 23 -5 c12 -3 65 -5 117 -3 l95
                    3 0 465 0 465 -461 3 c-253 1 -464 -1 -467 -5z"/>
                    <path d="M2545 2028 c-3 -7 -4 -218 -2 -468 l2 -455 95 -3 c52 -2 105 0 118 3
                    l22 5 0 255 c0 140 3 255 7 255 5 0 169 -161 365 -357 l358 -358 82 83 83 82
                    -358 358 c-196 196 -357 360 -357 364 0 4 116 9 258 10 l257 3 0 115 0 115
                    -463 3 c-365 2 -464 0 -467 -10z"/>
                </g>
            </svg>
        );
    }
}

export default IconMonitorCollapse;
