/**
 * Created by mehmetyurtar on 3/19/18.
 */
import React, { Component } from 'react';
import { statTitles, trendTitles, units, icons } from '../../constants/ui';
import * as modals from '../../constants/datamaps/modals';

import { t, openModal } from '../../system/ui';
import { toSlug, localeString } from '../../system/string';

import Icon from '../icon';
import Currency from '../currency';

import { statTypes, statsMap } from '../../constants/datamaps';

import Icons from '../icons';

import { hasNoRevenueModule } from '../../modules/auth/user';

class ComparisonColumn extends Component {
  render() {
    let stats = {};
    let trends = {};
    let pageStats = {};
    let comparisonStats = {};
    let comparisonTrends = {};
    let comparisonPageStats = {};
    if (this.props.stats.length) {
      stats = this.props.stats[0];
      trends = this.props.stats[1];
      pageStats = this.props.stats[2];
    }
    if (this.props.comparisonStats && this.props.comparisonStats.length) {
      comparisonStats = this.props.comparisonStats[0];
      comparisonTrends = this.props.comparisonStats[1];
      comparisonPageStats = this.props.comparisonStats[2];
    }
    return (
      <div className='widget-stat-list widget-report'>
        {!this.props.hideSegmentify &&
          !hasNoRevenueModule() && [
            <div className='dashboard-compare--title'>
              {this.props.isStatic && t('Executive Summary')}
              {!this.props.isStatic &&
                !this.props.isOverall &&
                t('Segmentify Performance')}
              {!this.props.isStatic &&
                this.props.isOverall &&
                t('Overall Segmentify Performance')}
            </div>,
            <div>
              {Object.keys(trends).map((item, index) => {
                let title = trendTitles[item];
                const data = trends[item];
                const comparisonData =
                  comparisonTrends && comparisonTrends[item];
                const type = statTypes[item];
                const typeName = type?.name;
                let typeUnit = type?.unit;
                const stat = data?.stats;
                const isSecondColumn = this.props.withCompareIcons;
                let statDifference;
                if (!this.props.isOverall) {
                  statDifference = isSecondColumn
                    ? stat?.all?.count -
                      comparisonTrends[item]?.stats?.all?.count
                    : 0;
                } else {
                  statDifference = isSecondColumn
                    ? ((stat?.all?.count -
                        comparisonTrends[item]?.stats?.all?.count) /
                        comparisonTrends[item]?.stats?.all?.count) *
                      100
                    : 0;
                }
                if (item === 'widget') {
                  statDifference = isSecondColumn
                    ? stat.view.count -
                      comparisonTrends[item]?.stats?.view?.count
                    : 0;
                }
                if (title === 'Widget' && this.props.isMail) {
                  title = 'E-Mail';
                  data.stats.all.name = 'Emails Sent';
                  data.stats.conversion.name = 'Open Rate';
                  data.stats.bounceBack.name = 'Bounce Back';
                  data.stats.unsubscribed.name = 'Unsubscribed';
                  typeUnit = 'Opens';
                }

                if (title === 'Widget' && this.props.isPush) {
                  title = 'Push';
                  data.stats.all.name = 'Sent';
                  data.stats.conversion.name = 'Impression Rate';
                }

                return (
                  <div className='compare-stat' key={`${item}-${index}`}>
                    <div className='compare-stat-container one-half'>
                      <h4 className='widget-stat-title'>{t(title)}</h4>
                      {Object.keys(data.stats).map((pItem, pIndex) => {
                        if (
                          !this.props.isMail &&
                          (pItem === 'bounceBack' || pItem === 'unsubscribed')
                        )
                          return;
                        let statToPrint;
                        const singleStat = data.stats[pItem];
                        if (
                          this.props.isSearch &&
                          trendTitles[item] === 'Widget'
                        )
                          return;
                        if (singleStat.name === 'Conversion') {
                          singleStat.name = `${data.modal}Conversion`;
                        }
                        if (pItem === 'bounceBack') {
                          statToPrint = localeString(
                            singleStat.HardBounce + singleStat.SoftBounce,
                          );
                        } else if (pItem === 'unsubscribed') {
                          statToPrint = localeString(singleStat.total);
                        } else {
                          statToPrint =
                            localeString(singleStat.count) ||
                            `${localeString(singleStat.ratio)}%`;
                        }
                        if (singleStat.name === 'Spam' || !singleStat.name) {
                          return null;
                        }
                        if (pIndex > 0) {
                          return (
                            <p key={pItem}>
                              {`${t(singleStat.name)} `}
                              <span>{statToPrint}</span>
                            </p>
                          );
                        }
                        return '';
                      })}
                    </div>
                    <div
                      className='compare-stat-container one-half'
                      style={{ textAlign: 'right', position: 'relative' }}
                    >
                      <p className='widget-stat-count'>
                        {item === 'widget'
                          ? !type.currency
                            ? localeString(stat.view.count)
                            : ''
                          : !type.currency
                          ? localeString(stat.all.count)
                          : ''}
                        <span className='compare-unit'>
                          {type.currency === true ? (
                            <Currency
                              currency={
                                item === 'widget'
                                  ? stat.view.count
                                  : stat.all.count
                              }
                              compareCurrency
                            />
                          ) : typeName === 'Email' ? (
                            <Icons name='email' color='#4bb3d2' />
                          ) : (
                            <Icon name={type.icon} />
                          )}
                          {type.currency !== true ? t(typeUnit) : ''}
                        </span>
                        <span
                          style={{
                            position: 'absolute',
                            right: '0',
                            bottom: '-20px',
                          }}
                        >
                          {isSecondColumn && statDifference > 0 && (
                            <span style={{ color: '#56bc94' }}>
                              +
                              {this.props.isOverall &&
                                `${localeString(statDifference)} %`}
                              {!this.props.isOverall &&
                                !type.currency &&
                                localeString(statDifference)}
                              {!this.props.isOverall && type.currency && (
                                <Currency currency={statDifference} />
                              )}{' '}
                              <Icons name='increase' color='#56bc94' />
                            </span>
                          )}
                          {isSecondColumn && statDifference < 0 && (
                            <span style={{ color: '#dc3322' }}>
                              {' '}
                              {this.props.isOverall &&
                                `${localeString(Math.abs(statDifference))} %`}
                              {!this.props.isOverall &&
                                !type.currency &&
                                localeString(statDifference)}
                              {!this.props.isOverall && type.currency && (
                                <Currency currency={statDifference} />
                              )}{' '}
                              <Icons name='decrease' color='#dc3322' />
                            </span>
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>,
          ]}
        {!this.props.isStatic && (
          <>
            <div className='dashboard-compare--title'>
              {this.props.isGoogleAnalytics
                ? t('Google Analytics 4')
                : t('Your website')}
            </div>
            <div>
              {Object.keys(stats).map((mainItem, index) => {
                const title = statTitles[mainItem];
                const data = stats[mainItem];
                const comparisonData =
                  comparisonStats && comparisonStats[mainItem];
                const type = statsMap[mainItem];
                const typeName = type.name;
                const typeUnit = type.unit;
                const stat = data.stats;
                let statDifference;
                let firstCount;
                let mainText;
                const isSecondColumn = this.props.withCompareIcons;
                let currentIcon = '';
                return (
                  <div className='compare-stat' key={`${mainItem}-${index}`}>
                    <div className='compare-stat-container one-half'>
                      <h4 className='widget-stat-title'>{t(title)}</h4>
                      {Object.keys(data.stats).map((item, index) => {
                        /* if(mainItem === "device"){

                                                    } */
                        const singleStat = data.stats[item];
                        const singleComparisonStat =
                          comparisonData && comparisonData.stats[item];
                        if (index === 0) {
                          const firstCountNum =
                            singleStat.count || singleStat.ratio;
                          if (singleStat.currency) {
                            firstCount = (
                              <Currency
                                currency={singleStat.count}
                                compareCurrency
                              />
                            );
                          } else {
                            firstCount =
                              localeString(singleStat.count) ||
                              `${localeString(singleStat.ratio)}%`;
                          }
                          if (singleComparisonStat) {
                            const firstComparisonCount =
                              singleComparisonStat.count ||
                              singleComparisonStat.ratio;
                            statDifference = isSecondColumn
                              ? firstCountNum - firstComparisonCount
                              : 0;
                          }
                        }
                        if (mainItem === 'device') {
                          currentIcon = (
                            <span style={{ marginRight: '5px' }}>
                              <Icon name={singleStat.name.toLowerCase()} />
                            </span>
                          );
                          if (singleStat.name.toLowerCase() === 'other') {
                            currentIcon = (
                              <span
                                style={{
                                  marginRight: '5px',
                                  position: 'relative',
                                  top: '4px',
                                }}
                              >
                                <Icons
                                  name='browser'
                                  width='15'
                                  height='17'
                                  color='#b3b3b3'
                                />
                              </span>
                            );
                          }
                        }
                        if (
                          index === 0 &&
                          (mainItem === 'page' ||
                            mainItem === 'purchase' ||
                            mainItem === 'unique')
                        ) {
                          return '';
                        }
                        return (
                          <p key={item}>
                            {currentIcon}
                            {`${t(singleStat.name)} `}
                            <span>
                              {localeString(singleStat.count) ||
                                `${localeString(singleStat.ratio)}%`}
                            </span>
                          </p>
                        );
                      })}
                    </div>
                    <div
                      className='compare-stat-container one-half'
                      style={{ textAlign: 'right', position: 'relative' }}
                    >
                      <p className='widget-stat-count'>
                        {mainItem !== 'device' && firstCount}
                        <span className='compare-unit'>
                          {mainItem === 'page' && t('views')}
                          {mainItem === 'unique' && t('average sessions')}
                        </span>
                      </p>
                      <span
                        style={{
                          position: 'absolute',
                          right: '0',
                          bottom: '-20px',
                        }}
                      >
                        {isSecondColumn &&
                          statDifference > 0 &&
                          mainItem !== 'device' && (
                            <span style={{ color: '#56bc94' }}>
                              +{' '}
                              {mainItem !== 'purchase' ? (
                                localeString(statDifference)
                              ) : (
                                <Currency currency={statDifference} />
                              )}
                              <Icons name='increase' color='#56bc94' />
                            </span>
                          )}
                        {isSecondColumn &&
                          statDifference < 0 &&
                          mainItem !== 'device' && (
                            <span style={{ color: '#dc3322' }}>
                              {' '}
                              {mainItem !== 'purchase' ? (
                                localeString(statDifference)
                              ) : (
                                <Currency currency={statDifference} />
                              )}{' '}
                              <Icons name='decrease' color='#dc3322' />
                            </span>
                          )}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ComparisonColumn;
