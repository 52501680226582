/**
 * Created by mehmetyurtar on 3/23/18.
 */
import React from "react";

class IconCrossCircle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#D75252"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Wizard-3"
            transform="translate(-1086.000000, -499.000000)"
            fill={this.state.color}
            fillRule="nonzero"
          >
            <g
              id="Recommendation-Types"
              transform="translate(107.000000, 279.000000)"
            >
              <g id="Recommendation-Algorithms">
                <g
                  id="Smart-Offers"
                  transform="translate(25.000000, 198.000000)"
                >
                  <g id="Group" transform="translate(25.000000, 16.000000)">
                    <g
                      id="Duplicate/Delete"
                      transform="translate(882.000000, 6.000000)"
                    >
                      <g
                        id="ui-32px-outline-1_circle-remove"
                        transform="translate(47.000000, 0.000000)"
                      >
                        <path
                          d="M22.707,9.293 C22.316,8.902 21.684,8.902 21.293,9.293 L16,14.586 L10.707,9.293 C10.316,8.902 9.684,8.902 9.293,9.293 C8.902,9.684 8.902,10.316 9.293,10.707 L14.586,16 L9.293,21.293 C8.902,21.684 8.902,22.316 9.293,22.707 C9.488,22.902 9.744,23 10,23 C10.256,23 10.512,22.902 10.707,22.707 L16,17.414 L21.293,22.707 C21.488,22.902 21.744,23 22,23 C22.256,23 22.512,22.902 22.707,22.707 C23.098,22.316 23.098,21.684 22.707,21.293 L17.414,16 L22.707,10.707 C23.098,10.316 23.098,9.684 22.707,9.293 Z"
                          id="Shape"
                        />
                        <path
                          d="M16,0 C7.178,0 0,7.178 0,16 C0,24.822 7.178,32 16,32 C24.822,32 32,24.822 32,16 C32,7.178 24.822,0 16,0 Z M16,30 C8.28,30 2,23.72 2,16 C2,8.28 8.28,2 16,2 C23.72,2 30,8.28 30,16 C30,23.72 23.72,30 16,30 Z"
                          id="Shape"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconCrossCircle;
