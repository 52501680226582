import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import classes from './ExecutiveReport.module.scss';

import { t } from '../../../../system/ui';
import { setTitle } from '../../../../system/document';
import { hasKlaviyoConfiguration } from '../../../../modules/auth/user';

import StatsScreen from './components/StatsScreen';
import CompareDashboardModal from '../../../../components/reports/compareModal.dashboard';
import { transformTrends } from '../../../../modules/transform';
import fetchData from './services/requests';
import { modalActions } from '../../../../actions';

const EmailExecutiveReport = ({ range, stats }) => {
  const boundaries = {};
  boundaries.start = range[0].format('YYYY-MM-DD');
  boundaries.end = range[1]?.format('YYYY-MM-DD');
  const getData = async () => {
    const type = '';
    return await fetchData(boundaries, type);
  };

  const rangeStr = boundaries.start + boundaries.end;
  const { data } = useQuery(['data', rangeStr], getData);
  const purchaseAmount = stats?.purchase?.stats?.amount?.count;
  const transformedTrends = transformTrends(
    data,
    purchaseAmount,
    0,
    0,
    false,
    'E-Mail',
  );

  useEffect(() => {
    setTitle(t('Executive Reports'));
  }, []);

  const openComparisonModal = () => {
    const compareModal = () => (
      <CompareDashboardModal range={range} selectedModule='E-Mail' isStatic />
    );
    modalActions.openModal({
      title: t('Date Comparison'),
      content: compareModal,
    });
  };

  return (
    <div className={classes.component}>
      <div className={classes.component__header}>
        <h2 className={classes.component__header__text}>
          {t('Executive Summary')}
        </h2>
        <button
          type='button'
          className={classes['component__header__compare-btn']}
          onClick={() => {
            openComparisonModal();
          }}
        >
          Compare
        </button>
      </div>
      <StatsScreen
        hasKlaviyoConfiguration={hasKlaviyoConfiguration()}
        trends={transformedTrends}
      />
    </div>
  );
};

export default EmailExecutiveReport;
