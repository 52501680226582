import React from 'react';
import './EmailAction.scss';
import { browserHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { t } from '../../../../system/ui';
import Icons from '../../../../components/icons';
import Icon from '../../../../components/icon';
import { modalActions, uiActions } from '../../../../actions';
import CampaignHistoryModal from '../../../../modules/campaigns/historymodal';
import { isLoaded, isLoading } from '../../../../actions/ui';
import { updateEmailStatus } from '../../../../modules/emails/ajax';

/**
 * @name EmailActions this component is used to show the actions of the Email
 * @description this component is used to show the actions of the EmailStepper
 * @returns {JSX.Element} - Countdown component
 */

const EmailActions = ({ data, statusType }) => {
  const userRole = useSelector(
    ({ switchedUser, user }) =>
      switchedUser?.switchedUser?.userRole || user?.user?.userRole,
  );
  const createAction = () => {
    const actions = [
      {
        icon: 'view',
        handler: 'viewAction',
        name: 'Edit',
      },
      {
        icon: 'history',
        handler: 'historyAction',
        name: 'History',
        iconSvg: true,
      },
      {
        icon: 'pie-chart',
        handler: 'reportAction',
        name: 'Report',
      },
    ];

    if (statusType === 'sent') {
      // Remove viewAction then add previewAction for sent email at the first index
      actions.shift();
      actions.unshift({
        icon: 'view',
        handler: 'previewAction',
        name: 'Preview Campaign',
      });
    }

    if (data.type === 'RECOMMENDATION' || data.type === 'FLY') {
      actions.push({
        icon: 'duplicate',
        handler: 'duplicateCampaign',
        name: 'Duplicate',
      });
    }

    if (userRole === 'ADMIN')
      return [
        ...actions,
        { icon: 'archive', handler: 'archiveAction', name: 'Archive' },
      ];

    return actions;
  };

  const actions = createAction();

  const reportAction = () => {
    browserHistory.push({
      pathname: `/reports/email-single/${data?.instanceId}`,
    });
  };

  const duplicateCampaign = () => {
    const path = '/email/templates/create/';
    browserHistory.push({
      pathname: path,
      state: {
        campaignPathname: `/email-sgm/create/${data.type}`,
        campaign: data,
        templateId: data.templateId,
        isFork: true,
      },
    });
  };

  const archiveAction = () => {
    const notificationContent = () => (
      <div>{`${t('You have successfully archived email campaign.')} `}</div>
    );
    uiActions.confirmationDialog({
      title: 'Attention!',
      content: 'This action will archive current email campaign.',
      onConfirm: () => {
        isLoading();
        updateEmailStatus('PASSIVE', data?.instanceId, response => {
          if (response) {
            isLoaded();
            uiActions.notification(notificationContent());
          } else {
            isLoaded();
          }
        });
      },
    });
  };

  const historyAction = () => {
    const campaignHistoryModal = () => (
      <CampaignHistoryModal campaignId={data?.instanceId} isEmail />
    );

    modalActions.openModal({
      title: `Campaign History (${data?.name || data?.scenarioName})`,
      content: campaignHistoryModal,
    });
  };

  const viewAction = () => {
    const path = `/email-sgm/edit/${data?.instanceId}`;
    browserHistory.push({
      pathname: path,
    });
  };

  const previewAction = () => {
    const path = `/email-sgm/preview/${data?.instanceId}`;
    browserHistory.push({
      pathname: path,
    });
  };

  const handleClick = action => {
    switch (action) {
      case 'historyAction':
        historyAction();
        break;
      case 'archiveAction':
        archiveAction(data);
        break;
      case 'reportAction':
        reportAction();
        break;
      case 'viewAction':
        viewAction();
        break;
      case 'previewAction':
        previewAction();
        break;
      case 'duplicateCampaign':
        duplicateCampaign();
        break;
      default:
        break;
    }
  };

  return (
    <li className='widget-list-item'>
      <div className='right-side-wrapper'>
        <nav className='widget-nav action-nav' aria-haspopup='true'>
          <a className='secondary-action'>{t('Actions')}</a>
          <ul className='widget-nav-popup action-nav-popup drop-up'>
            {actions.map(action => (
              <li key={action.name}>
                <a
                  className='widget-nav-popup-item action-nav-popup-item'
                  onClick={() => handleClick(action.handler)}
                >
                  {action.iconSvg ? (
                    <Icons name={action.icon} color='#fff' />
                  ) : (
                    <Icon name={action.icon} />
                  )}
                  {action.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </li>
  );
};

export default EmailActions;
