"use strict";

import { createAction } from "../system/store";


export const wizardReset = () => {
    createAction("RESET_SEARCH_WIZARD");
};

export const addCriteria = criteria => {
    createAction("ADD_SEARCH_WIZARD_CRITERIA", { criteria });
};

export const addExclusion = (excludes, isMobile) => {
    createAction("ADD_SEARCH_WIZARD_EXCLUSION", { excludes, isMobile });
};

export const removeExclusion = (excludes, isMobile, type) => {
    createAction("REMOVE_SEARCH_WIZARD_EXCLUSION", { excludes, isMobile, type});
};

export default{
  wizardReset,
  addCriteria,
  addExclusion,
  removeExclusion
}