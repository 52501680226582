export const emailReducers = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_QUERY_STRING':
      return {
        ...state,
        queryString: payload,
      };
    case 'SET_UPLOAD_FILE':
      return {
        ...state,
        uploadFile: payload,
      };
    case 'SET_ACTIVE_EMAIL_DETAILS':
      return {
        ...state,
        activeEmailDetails: payload,
      };
    case 'EDIT_ACTIVE_EMAIL_DETAILS':
      return {
        ...state,
        activeEmailDetails: {
          ...state.activeEmailDetails,
          ...payload,
        },
      };
    default:
      return state;
  }
};
