import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const iysIcon = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 47 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.0588 22.9412C25.278 22.9412 25.4934 22.9579 25.7036 22.9899C24.8773 23.5807 24.1315 24.277 23.4858 25.0588H10.2353C9.06576 25.0588 8.11765 26.0069 8.11765 27.1765V30.3529C8.11765 33.3327 11.4606 35.6471 17.6471 35.6471C18.9542 35.6471 20.1343 35.5437 21.1829 35.3527C21.36 36.0486 21.5999 36.7196 21.896 37.3592C20.6195 37.6237 19.1979 37.7647 17.6471 37.7647C10.4218 37.7647 6 34.7034 6 30.3529V27.1765C6 24.8373 7.8962 22.9412 10.2353 22.9412H25.0588Z'
        fill='white'
      />
      <path
        d='M37.7645 15.5294C37.7645 18.4533 35.3942 20.8235 32.4704 20.8235C29.5466 20.8235 27.1763 18.4533 27.1763 15.5294C27.1763 12.6056 29.5466 10.2353 32.4704 10.2353C35.3942 10.2353 37.7645 12.6056 37.7645 15.5294ZM32.4704 12.3529C30.7161 12.3529 29.2939 13.7751 29.2939 15.5294C29.2939 17.2837 30.7161 18.7059 32.4704 18.7059C34.2246 18.7059 35.6469 17.2837 35.6469 15.5294C35.6469 13.7751 34.2246 12.3529 32.4704 12.3529Z'
        fill={color}
      />
      <path
        d='M17.6471 6C21.7405 6 25.0589 9.31835 25.0589 13.4118C25.0589 17.5052 21.7405 20.8235 17.6471 20.8235C13.5537 20.8235 10.2354 17.5052 10.2354 13.4118C10.2354 9.31835 13.5537 6 17.6471 6ZM17.6471 8.11765C14.7233 8.11765 12.353 10.4879 12.353 13.4118C12.353 16.3356 14.7233 18.7059 17.6471 18.7059C20.571 18.7059 22.9412 16.3356 22.9412 13.4118C22.9412 10.4879 20.571 8.11765 17.6471 8.11765Z'
        fill={color}
      />
      <path
        d='M24.0083 28.7008L24.7662 29.4409C26.4671 31.1026 26.4671 33.8386 24.7662 35.5003L24.0083 36.2404C24.2827 36.8988 24.6267 37.5188 25.0307 38.0906L25.8684 37.8392C28.2017 37.1394 30.6429 38.5472 31.206 40.917L31.4495 41.9422C31.7843 41.9803 32.1246 42 32.4691 42C32.8139 42 33.1542 41.9803 33.489 41.9422L33.7325 40.917C34.2956 38.5472 36.7368 37.1394 39.0701 37.8392L39.9076 38.0906C40.3119 37.5188 40.6558 36.8988 40.9302 36.2404L40.1723 35.5003C38.4714 33.8386 38.4714 31.1026 40.1723 29.4409L40.9302 28.7008C40.6558 28.0424 40.3119 27.4223 39.9076 26.8506L39.0701 27.1019C36.7368 27.8018 34.2956 26.394 33.7325 24.0241L33.489 22.999C33.1542 22.9609 32.8139 22.9412 32.4691 22.9412C32.1246 22.9412 31.7843 22.9609 31.4495 22.999L31.206 24.0241C30.6429 26.394 28.2017 27.8018 25.8684 27.1019L25.0307 26.8506C24.6267 27.4223 24.2827 28.0424 24.0083 28.7008V28.7008ZM32.4691 34.5882C31.2998 34.5882 30.3515 33.6402 30.3515 32.4706C30.3515 31.301 31.2998 30.3529 32.4691 30.3529C33.6387 30.3529 34.5868 31.301 34.5868 32.4706C34.5868 33.6402 33.6387 34.5882 32.4691 34.5882Z'
        fill={color}
      />
    </svg>
  );
};

iysIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

iysIcon.defaultProps = {
  width: '33px',
  height: '28px',
  color: '#ffffff',
};

export default iysIcon;
