import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { openUrlInNewTab } from './utils';

import ThumbUpIcon from '../../images/thumb-up-icon.png';
import SegmentifyLongLogo from './icons/SegmentifyLongLogo';

const SideBarMenu = ({ menuItems, selectedMenu, selectMenuHandler }) => {
  const isActive = id => selectedMenu.id === id;

  return (
    <div className='msp-sidebar-menu'>
      <aside className='msp-sidebar'>
        <div className='msp-header'>
          <div className='msp-logo-container'>
            <Link to='/managed-services' className='msp-logo' onlyActiveOnIndex>
              <SegmentifyLongLogo />
            </Link>
          </div>
        </div>
        <nav className='msp-nav'>
          {menuItems.map(item => {
            if (!item.isVisible) {
              return null;
            }

            const isThisActive = isActive(item.id);
            return (
              <div
                key={item.id}
                className={`nav-item ${isThisActive ? 'active' : ''}`}
              >
                <button
                  type='button'
                  onClick={() => selectMenuHandler(item.id, item.tempUrl)}
                  disabled={isThisActive}
                >
                  <item.iconComponent />
                  <span className='button-text'>{item.title}</span>
                </button>
              </div>
            );
          })}
        </nav>
        <div className='msp-bottom'>
          <div className='msp-bottom-container'>
            <div className='msp-bottom-logo'>
              <img src={ThumbUpIcon} alt='Segmentify!' />
            </div>
            <div className='msp-bottom-description'>
              <span>
                Discover Segmentify’s eCommerce personalization platform
              </span>
            </div>
            <div
              className='msp-bottom-link'
              onClick={() => openUrlInNewTab('https://www.segmentify.com')}
            >
              <span>Book a Demo</span>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

SideBarMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      iconComponent: PropTypes.func.isRequired,
      component: PropTypes.func,
    }),
  ).isRequired,
  selectedMenu: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    iconComponent: PropTypes.func,
    component: PropTypes.func,
  }).isRequired,
  selectMenuHandler: PropTypes.func.isRequired,
};

export default SideBarMenu;
