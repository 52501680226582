/**
 * Created by mehmetyurtar on 18/07/2017.
 */

"use strict";

const defaultFormHTML = innerFields => {
  let strVar = "";
  strVar += '<div class="seg-popup-overlay"></div>\n';
  strVar +=
    '<div class="seg-popup seg-form-survey seg-[[vertical]]-[[horizontal]] segFadeInUp">\n';
  strVar += '  <div class="seg-popup-container">\n';
  strVar += '    <div class="seg-popup-header">\n';
  strVar += '      <h1 class="seg-popup-title">[[title]]</h1>\n';
  strVar += "      <p>[[description]]</p>\n";
  strVar += "    </div>\n";
  strVar += '    <div class="seg-popup-content">\n';
  strVar += '      <form method="post" class="seg-form">\n';
  strVar += innerFields;
  strVar += "      </form>\n";
  strVar += '      <div class="seg-form-footer">\n';
  strVar +=
    '        <button type="submit" class="seg-form-submit" style="background-color:#d94848;">\n';
  strVar += '          <span style="color:#fff">[[buttonSubmitText]]</span>\n';
  strVar += '          <span style="color:#fff"></span>\n';
  strVar += "        </button>\n";
  strVar += "      </div>\n";
  strVar += "    </div>\n";
  strVar += "  </div>\n";
  strVar += '  <span class="seg-popup-close"></span>\n';
  strVar += "</div>";
  return strVar;
};

const generateInputField = {
  input: (fieldItem, fieldIndex) => {
    let strVar = "";
    strVar += '          <div class="seg-form-control-group">\n';
    strVar +=
      '              <span class="seg-form-control-group-title">[[ff' +
      fieldIndex +
      "_name]]</span>\n";
    strVar +=
      '              <input type="[[& ff' +
      fieldIndex +
      '_inputType]]"  name="[[& ff' +
      fieldIndex +
      '_fieldName]]" class="seg-form-input" placeholder="[[& ff' +
      fieldIndex +
      '_placeholder]]" [[#ff' +
      fieldIndex +
      "_required]]required[[/ff" +
      fieldIndex +
      "_required]] >\n";
    strVar += "          </div>\n";
    return strVar;
  },
  textArea: (fieldItem, fieldIndex) => {
    let strVar = "";
    strVar += '          <div class="seg-form-control-group">\n';
    strVar +=
      '             <span class="seg-form-control-group-title">[[ff' +
      fieldIndex +
      "_name]]</span>\n";
    strVar +=
      '             <textarea name="[[& ff' +
      fieldIndex +
      '_fieldName]]" class="seg-form-input" rows="3" cols="50" placeholder="[[& ff' +
      fieldIndex +
      '_placeholder]]" [[#ff' +
      fieldIndex +
      "_required]]required[[/ff" +
      fieldIndex +
      "_required]] ></textarea>\n";
    strVar += "          </div>\n";
    return strVar;
  },
  checkbox: (fieldItem, fieldIndex) => {
    var strVar = "";
    strVar += '          <div class="seg-form-control-group">\n';
    strVar += "             [[#ff" + fieldIndex + "_groupTitle]]\n";
    strVar +=
      '                 <span class="seg-form-control-group-title">[[ff' +
      fieldIndex +
      "_groupTitle]]</span>\n";
    strVar += "             [[/ff" + fieldIndex + "_groupTitle]]\n";
    strVar += "             [[#ff" + fieldIndex + "_options]]\n";
    strVar +=
      '                 <label class="seg-form-control seg-form-control--checkbox">[[.]]\n';
    strVar +=
      '                     <input type="checkbox" name="[[& ff' +
      fieldIndex +
      '_fieldName]]" value="[[.]]" [[#ff' +
      fieldIndex +
      "_required]]required[[/ff" +
      fieldIndex +
      "_required]]>\n";
    strVar +=
      '                     <div class="seg-form-control__indicator"></div>\n';
    strVar += "                 </label>\n";
    strVar += "             [[/ff" + fieldIndex + "_options]]\n";
    strVar += "          </div>\n";
    return strVar;
  },

  radio: (fieldItem, fieldIndex) => {
    var strVar = "";
    strVar += '         <div class="seg-form-control-group">\n';
    strVar += "             [[#ff" + fieldIndex + "_groupTitle]]\n";
    strVar +=
      '                 <span class="seg-form-control-group-title">[[ff' +
      fieldIndex +
      "_groupTitle]]</span>\n";
    strVar += "             [[/ff" + fieldIndex + "_groupTitle]]\n";
    strVar += "             [[#ff" + fieldIndex + "_options]]\n";
    strVar +=
      '                 <label class="seg-form-control seg-form-control--radio">[[.]]\n';
    strVar +=
      '                     <input type="radio" name="[[& ff' +
      fieldIndex +
      '_fieldName]]" value="[[.]]" [[#ff' +
      fieldIndex +
      "_required]]required[[/ff" +
      fieldIndex +
      "_required]] >\n";
    strVar +=
      '                     <div class="seg-form-control__indicator"></div>\n';
    strVar += "                 </label>\n";
    strVar += "             [[/ff" + fieldIndex + "_options]]\n";
    strVar += "         </div>";
    return strVar;
  }
};

const generateInnerHTML = (fieldItem, fieldIndex) => {
  return generateInputField[fieldItem.type](fieldItem, fieldIndex);
};

export const generateFormHTML = fields => {
  let innerFields = "";
  fields.forEach((fieldItem, fieldIndex) => {
    innerFields += generateInnerHTML(fieldItem, fieldIndex);
  });
  return defaultFormHTML(innerFields);
};

export const generateFormCampaign = fields => {
  let currentFields = [];
  let availableTypes = {
    input: "INPUT",
    textArea: "TEXT_AREA",
    checkbox: "CHECKBOX",
    radio: "RADIO_BUTTON"
  };
  fields.forEach(item => {
    let currentField = {},
      currentOptions = [];
    currentField.type = availableTypes[item.type];

    Object.keys(item.values).forEach(function(key) {
      currentField[key] = item.values[key].value;
    });

    if (item.type === "checkbox" || item.type === "radio") {
      item.options.forEach(option => {
        currentOptions.push(option.name);
      });
      currentField.options = currentOptions;
    }
    currentFields.push(currentField);
  });

  return currentFields;
};
