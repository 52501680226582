/**
 * Created by mehmetyurtar on 1/15/18.
 */
import { createReducer } from "../system/store";
import * as brandsActions from "../constants/actions/brands";

const initialState = {
  options: [],
  isSet: false
};

export default createReducer(initialState, {
  [brandsActions.default.SET_BRANDS]: (state, payload) => {
    return Object.assign({}, state, {
      options: payload,
      isSet: true
    });
  },
  [brandsActions.default.REMOVE_BRANDS]: state => {
    return Object.assign({}, state, {
      options: [],
      isSet: false
    });
  }
});
