"use strict";

import React from "react";
import {t} from "../../../system/ui";
import {setTitle} from "../../../system/document";
import {isSwitchAccount, getUser} from "../../../modules/auth/user";
import {updateRecommendationSettings} from "../../../modules/account/ajax";
import {uiActions, userActions} from "../../../actions";
import {Button} from "../../../components/fields";
import {TextField} from "../../../components/fields";

class RecoSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {
        sliderClick: {
          enabled: false,
          leftArrowSelector: "",
          rightArrowSelector: ""
        }
      }
    };
    this.update = this.update.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.onChangeSliderClickData = this.onChangeSliderClickData.bind(this);
    this.onChangeSliderClickEnabled = this.onChangeSliderClickEnabled.bind(this);
  }

  componentDidMount() {
    setTitle(t("Recommendation Settings"));
    uiActions.isLoading();
    let account = isSwitchAccount();
    if (account) {
      this.updateForm(account)
      uiActions.isLoaded();
    } else {
      getUser(response => {
        this.updateForm(response.account);
        uiActions.isLoaded();
      });
    }
  }

  update(e) {
    e.preventDefault();
    let errors = document.querySelectorAll(".has-error");
    if (errors.length === 0) {
      uiActions.isLoading();
      updateRecommendationSettings(this.state.settings, (resultObj) => {
        uiActions.isLoaded();
        if (resultObj && resultObj.recommendationSettings) {
          if (isSwitchAccount()) {
            let newUserToSave = isSwitchAccount();
            newUserToSave.recommendationSettings = resultObj.recommendationSettings;
            userActions.updateSwitchAccount(newUserToSave);
          } else {
            userActions.updateRecommendationSettings(resultObj);
          }

          let notificationContent = () => (
            <div>{t("You have successfully updated recommendation settings...")}</div>
          );
          uiActions.showNotification({content: notificationContent});
        }
      })
    }
  }

  updateForm(account) {
    if (!account.recommendationSettings) {
      return;
    }
    this.setState({
      settings: {
        ...this.state.settings,
        sliderClick: {
          enabled: account.recommendationSettings.sliderClick.enabled,
          leftArrowSelector: account.recommendationSettings.sliderClick.leftArrowSelector,
          rightArrowSelector: account.recommendationSettings.sliderClick.rightArrowSelector
        }
      }
    });
  }

  onChangeSliderClickEnabled(e) {
    this.setState({
      settings: {
        ...this.state.settings,
        sliderClick: {
          ...this.state.settings.sliderClick,
          enabled: e.target.checked
        }
      }
    })
  }

  onChangeSliderClickData(e) {
    this.setState({
      settings: {
        ...this.state.settings,
        sliderClick: {
          ...this.state.settings.sliderClick,
          [e.target.name]: e.target.value
        }
      }
    })
  }

  render() {
    return (
      <div className="page-content-block one-percent-60 settings-recommendation-form-wrapper">
        <form id={"recommendation-settings-form"} onSubmit={this.update}>
          <p className="form-info">{t("Recommendation Configurations")}</p>
          <div id={"slider-click-settings"}>
            <ol className="form-elements">
              <li className="form-element">
                <label className="item item-field is-checkbox">
                  <input
                    type={"checkbox"}
                    name={"enabled"}
                    checked={this.state.settings.sliderClick.enabled}
                    onChange={this.onChangeSliderClickEnabled}
                  />
                  <span className="item-label settings-item-label-toggle">
                      {t("Arrow Calculations")}
                  </span>

                </label>
              </li>
              {this.state.settings.sliderClick.enabled === true &&
              <li className="form-element">
                <label className="item item-stacked one-whole">
                    <span className="item-label settings-item-label">
                        {t("Left Arrow Selector")}
                    </span>
                  <TextField
                    type={"text"}
                    required={this.state.settings.sliderClick.enabled === true}
                    name={"leftArrowSelector"}
                    value={this.state.settings.sliderClick.leftArrowSelector}
                    onChange={this.onChangeSliderClickData}
                  />
                </label>
              </li>
              }
              {this.state.settings.sliderClick.enabled === true &&
              <li className="form-element">
                <label className="item item-stacked one-whole">
                    <span className="item-label settings-item-label">
                        {t("Right Arrow Selector")}
                    </span>
                  <TextField
                    type={"text"}
                    required={this.state.settings.sliderClick.enabled === true}
                    name={"rightArrowSelector"}
                    value={this.state.settings.sliderClick.rightArrowSelector}
                    onChange={this.onChangeSliderClickData}
                  />
                </label>
              </li>
              }
              <li className="buttons">
                <Button attr={{submitAnimation: true, label: t("Update"), type: "tertiary"}}/>
              </li>
            </ol>
          </div>
        </form>
      </div>
    );
  }
}

export default RecoSettings;
