/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import {
  SFYAddBundleActions,
  SFYCardBundle,
  SFYCardHeader,
  SFYHeader,
  SFYPreview,
  CampaignActionButton,
} from '../../../../components/sfy-components/module-exports';
import {
  createSTL,
  getSTL,
  getSTLPreviewItemByCategory,
  updateSTL,
} from '../services/shopTheLookServices';
import { uiActions } from '../../../../actions';
import {
  getDynamicBreadCrumbOptions,
  campaignActionButtonConstant,
} from '../constants/constants';
import { Context as ShopTheLookContext } from '../store/shopTheLookProvider';
import { inclusionOrExclusionConverterForBackEnd } from '../../../../components/sfy-components/Filters/utils/inclusion-exlusion-converter';
import { getAllPreviewProducts } from '../../../../components/sfy-components/utils';
import { getAccount } from '../../../../modules/auth/user';
import { setTitle } from '../../../../system/document';

const AddOrEdit = ({ params }) => {
  const {
    state: { bundleGroupName, bundleList, previewList },
    setSTLBundleGroupName,
    setSTLBundleList,
    setSTLPreview,
    resetSTL,
  } = useContext(ShopTheLookContext);

  const { mode, id } = params;
  const [breadCrumbName, setBreadCrumbName] = useState('Create New');

  const validate = () => {
    if (bundleList.length < 2) {
      return 'Please add at least 2 products.';
    }
    const isFilledAll = bundleList.every(({ type, name, category }) => {
      if (type === 'product' && !name) {
        return false;
      }
      return !(type === 'category' && !category);
    });

    if (!isFilledAll) {
      return 'Please fill all the required fields.';
    }

    if (bundleGroupName === '') {
      return 'Please fill all the required fields.';
    }

    return true;
  };

  const revertListForBackEnd = useCallback(
    list => {
      return list.map(bundle => {
        if (bundle.type === 'category') {
          return {
            ...bundle,
            params: {
              inclusions: inclusionOrExclusionConverterForBackEnd(
                bundle.params.inclusions,
              ),
              exclusions: inclusionOrExclusionConverterForBackEnd(
                bundle.params.exclusions,
              ),
            },
          };
        }
        return bundle;
      });
    },
    [bundleList],
  );

  const setPreviewList = useCallback(() => {
    const { previewProducts, categoryPostList } = getAllPreviewProducts(
      bundleList,
    );
    let productCount = 0;
    let pId = '';
    const bundleListValidate = bundleList.every(bundle => {
      if (bundle.category) {
        return bundle.category.length > 0;
      }
      if (bundle.type === 'product') {
        productCount++;
        return bundle.id;
      }
    });

    if (productCount === 1) {
      previewProducts.map(product => {
        pId = product.productId;
      });
      categoryPostList.map(category => {
        category.productId = pId;
      });
    } else {
      categoryPostList.map(category => {
        category.productId = pId;
      });
    }

    if (bundleListValidate) {
      const account = getAccount();
      getSTLPreviewItemByCategory({
        accountId: account.accountId,
        data: revertListForBackEnd(categoryPostList),
      }).then(response => {
        if (response && response.length > 0) {
          response?.forEach(item => {
            if (item?.product) {
              previewProducts.push({
                ...item.product,
                index: item.index,
              });
            }
          });
          previewProducts.sort((a, b) => a.index - b.index);
          setSTLPreview(previewProducts);
        }
        if (previewProducts && previewProducts.length > 0) {
          setSTLPreview(previewProducts);
        }
      });
    }
  }, [bundleList]);

  const save = () => {
    const validation = validate();
    if (validation !== true) {
      const content = () => <p>{validation}</p>;
      uiActions.showNotification({
        content,
        className: 'notification-fail',
      });
      return;
    }
    const paramsRevertedBundles = revertListForBackEnd(bundleList);
    if (mode === 'edit') {
      uiActions.isLoading();
      updateSTL({
        id,
        bundleList: paramsRevertedBundles,
        bundleGroupName,
      }).then(response => {
        uiActions.isLoaded();
        if (response) {
          const content = () => <p>Campaign is successfully updated.</p>;
          uiActions.showNotification({
            content,
            className: 'notification-success',
          });
          setBreadCrumbName(response.name);
        }
      });
    } else {
      resetSTL();
      uiActions.isLoading();
      createSTL({
        bundleList: paramsRevertedBundles,
        bundleGroupName,
      }).then(response => {
        uiActions.isLoaded();
        if (response) {
          const content = () => <p>Campaign is successfully created.</p>;
          uiActions.showNotification({
            content,
            className: 'notification-success',
          });
          browserHistory.push(
            `/dynamic_bundle/shop_the_look/edit/${response.id}`,
          );
        }
      });
    }
  };

  const campaignActionButtonConstantWithFunctions = campaignActionButtonConstant.map(
    actionButton => {
      let actionButtonWithFunction;
      switch (actionButton.type) {
        case 'save-justify-right':
          actionButtonWithFunction = () => save();
          break;
        default:
          actionButtonWithFunction = () => {};
          break;
      }
      return {
        ...actionButton,
        action: actionButtonWithFunction,
      };
    },
  );

  useEffect(() => {
    setPreviewList();
  }, [bundleList]);

  useEffect(() => {
    setTitle(mode === 'edit' ? 'Edit Shop The Look' : 'Create Shop The Look');
    if (mode === 'edit') {
      getSTL({ id }).then(response => {
        setSTLBundleList(response);
        setBreadCrumbName(response.name);
      });
      return;
    }

    resetSTL();
  }, [mode]);

  useEffect(() => {
    return () => {
      resetSTL();
    };
  }, []);

  return (
    <>
      <SFYHeader
        pageTitle='Shop The Look'
        breadCrumbOptions={getDynamicBreadCrumbOptions(breadCrumbName)}
      />
      <SFYCardHeader
        cardTitle='Name of Bundle Group*'
        stateSetter={setSTLBundleGroupName}
        state={bundleGroupName}
      />
      {bundleList?.map((bundle, index) => (
        <SFYCardBundle
          cardTitle={`Bundle ${index + 1}`}
          type={bundle.type}
          bundleDetail={bundle}
          index={index}
          bundleListLength={bundleList.length}
        />
      ))}
      <SFYAddBundleActions />
      <SFYPreview previewTitle='Preview' previews={previewList} />
      <CampaignActionButton
        campaignActionButtonConstant={campaignActionButtonConstantWithFunctions}
      />
    </>
  );
};

export default AddOrEdit;
