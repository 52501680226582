import React from 'react';

import { config } from '../../constants/config';
import {
  hasBetting,
  hasModule,
  hasModulePrivilege,
  hasFeature,
  mailProviderIsSegmentify,
  hasReportsVisible,
  hasIysIntegrationEnabled,
  hasOnlyDynamicBundles,
} from '../../modules/auth/user';
import {
  behaviouralTargetingModules,
  behaviouralTargetingPlusModules,
  dynamicBundleModules,
  audienceModules,
} from './constants';

import Navigation from '../navigation';
import { ExternalAppUtility } from '../../external';

const PrimaryNav = () => {
  const {
    audience,
    bannerify,
    'behavioural-targeting': behaviouralTargeting,
    dynamic_bundle: dynamicBundle,
    email,
    reports,
    recommendations,
    personalisedAds,
    push,
    sales,
    search,
    trendify,
  } = config.menus.primary;

  const newItems = config.menus.primary;

  const hasEngagementModule = hasModule('personalization');
  const hasEngagementPlusModule = hasModule('engagementPlus');
  const hasPushModule =
    hasModule('push') || document.location.pathname.indexOf('ext/push') >= 0;
  const hasMailModule = hasModule('email');
  const hasSearchModule = hasModule('search');
  const hasBettingModule = hasBetting();
  const hasAdvancedPersonalisation = hasModule('personalisation');
  const hasDynamicBundleModule = hasModule('dynamicBundles');
  const hasIysIntegration = hasIysIntegrationEnabled();

  const hasReportVisible = hasReportsVisible();

  const isRestrictedReco = hasOnlyDynamicBundles();
  // Dynamic Bundle modules
  dynamicBundle.visible = true;
  dynamicBundleModules.forEach(module => {
    dynamicBundle.children[module].visible = hasDynamicBundleModule;
  });

  // Engagement & Engagement Plus  modules
  const behaviourTargetingStatement = hasEngagementModule;
  const behaviouralTargetingPlusStatement =
    hasEngagementPlusModule && hasEngagementModule;

  reports.children['behavioural-targeting'].visible = hasEngagementModule;
  reports.children['segmentation-module-all'].visible = hasModule(
    'segmentation',
  );

  behaviouralTargeting.visible = true;
  behaviouralTargetingModules.forEach(module => {
    behaviouralTargeting.children[module].visible = behaviourTargetingStatement;
  });

  behaviouralTargetingPlusModules.forEach(module => {
    behaviouralTargeting.children[
      module
    ].visible = behaviouralTargetingPlusStatement;
  });

  // Audience modules

  audience.visible = true;
  audienceModules.forEach(module => {
    audience.children[module].visible = hasModule('ruleBasedSegmentation');
  });

  audience.children['all-segments'].visible = hasModule('segmentation');
  audience.children['smart-segments'].visible = hasModule('segmentation');

  search.visible = true;
  reports.visible = hasReportVisible;

  ExternalAppUtility.setPrimaryMenusForExternalApp(newItems);
  ExternalAppUtility.mergePrimaryAndSecondaryMenusForExternalApp(
    newItems,
    config.menus.secondary,
  );

  reports.children[
    'executive-summary-engagement'
  ].visible = hasEngagementModule;
  reports.children['all-personalizations'].visible = hasEngagementModule;
  reports.children.popup.visible = hasEngagementModule;
  reports.children.form_survey.visible = hasEngagementModule;
  reports.children.notification_bar.visible = hasEngagementModule;
  reports.children.email_collection.visible = hasEngagementPlusModule;
  reports.children['see-all'].visible = hasEngagementPlusModule;
  reports.children.hero_banner.visible = hasEngagementPlusModule;
  reports.children.gamification.visible = hasEngagementPlusModule;
  reports.children.countdown.visible = hasEngagementPlusModule;

  // dont show bt reports at dengage
  ExternalAppUtility.setBTReportMenusForExternalApp(newItems);

  reports.children.emails.visible = hasMailModule;
  reports.children['executive-summary-emails'].visible = hasMailModule;
  reports.children['all-email'].visible = hasMailModule;
  reports.children['email-marketing-analytics'].visible = hasMailModule;
  reports.children.winback.visible = hasMailModule;
  reports.children.growth.visible = hasMailModule;
  reports.children.retention.visible = hasMailModule;
  reports.children.recommendation.visible = hasMailModule;
  reports.children.flymode.visible = hasMailModule;
  reports.children.em_email_collection.visible = hasMailModule;

  reports.children.push.visible = hasPushModule;
  reports.children['executive-summary-push'].visible = hasPushModule;
  reports.children['all-push'].visible = hasPushModule;
  reports.children['permission-push'].visible = hasPushModule;
  reports.children['bulk-push'].visible = hasPushModule;
  reports.children['recommendation-push'].visible = hasPushModule;
  reports.children['winback-push'].visible = hasPushModule;
  reports.children['growth-push'].visible = hasPushModule;
  reports.children['retention-push'].visible = hasPushModule;
  reports.children.betting.visible = hasBettingModule;
  reports.children.fly.visible = hasPushModule;
  reports.children.palantir.visible = hasSearchModule;
  reports.children['executive-summary-search'].visible = hasSearchModule;
  reports.children.iys.visible = hasIysIntegration;

  recommendations.disabled = !hasModulePrivilege(false, 'RECOUSER');
  recommendations.children.journey_builder.visible = hasAdvancedPersonalisation;
  recommendations.children.promotion.visible = hasFeature('promotion');

  if (isRestrictedReco) {
    Object.keys(recommendations.children).forEach(item => {
      if (
        item !== 'product' &&
        item !== 'basket' &&
        item !== 'view-all' &&
        item !== 'templates'
      ) {
        recommendations.children[item].disabled = true;
      }
    });
  } else {
    Object.keys(recommendations.children).forEach(item => {
      if (
        item !== 'product' &&
        item !== 'basket' &&
        item !== 'view-all' &&
        item !== 'templates'
      ) {
        delete recommendations.children[item].disabled;
      }
    });
  }

  push.disabled = !hasModulePrivilege(false, 'PUSH');
  search.disabled = !hasModulePrivilege(false, 'SEARCH');
  email.disabled = !hasModulePrivilege(false, 'EMAIL');
  audience.disabled = !hasModulePrivilege(false, 'SEGMENTATION');
  trendify.disabled = !hasModulePrivilege(false, 'TRENDUSER');
  bannerify.disabled = !hasModulePrivilege(false, 'BANNERUSER');
  bannerify.visible = hasModule('bannerify');
  personalisedAds.visible = hasModule('personalisedAds');
  email.children['email-templates'].visible =
    hasMailModule && mailProviderIsSegmentify('SEGMENTIFY');
  email.children['email-activity-report'].visible =
    hasMailModule && mailProviderIsSegmentify('SEGMENTIFY');
  dynamicBundle.disabled = !hasModulePrivilege(false, 'DYNAMIC_BUNDLE');
  behaviouralTargeting.disabled = !hasModulePrivilege(false, 'ENGAGEMENT');

  return (
    <Navigation
      items={newItems}
      id='primary-nav'
      itemClass='primary-nav-item'
      tooltip
      isPrimarySidebar
    />
  );
};

export default PrimaryNav;
