import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { t } from '../../../system/ui';
import Icon from '../../icon';
import './PaginationController.scss';

const PaginationController = ({
  isBannerifyFilter,
  paginationSetter,
  totalRecords,
  payloadSize,
  currentPage,
  pageNumberOptions,
  analyticsType,
}) => {
  const [customPageSize, setCustomPageSize] = useState(0);

  const setPageSize = () => {
    if (isBannerifyFilter) setCustomPageSize(250);
  };

  const pageSize = selectedValue => {
    const newSize = selectedValue.value * 1;

    paginationSetter({
      payloadSize: newSize,
      pageNumber: 1,
      totalRecords,
      isInfinite: false,
    });
  };

  const prevPage = () => {
    const totalPages = Math.ceil(
      totalRecords / (customPageSize || payloadSize),
    );

    if (totalPages > 1 && currentPage > 1) {
      paginationSetter({
        pageNumber: currentPage - 1,
        payloadSize,
        totalRecords,
        isInfinite: false,
      });
    }
  };

  const nextPage = () => {
    const totalPages = Math.ceil(
      totalRecords / (customPageSize || payloadSize),
    );

    if (totalPages > 1 && currentPage < totalPages) {
      paginationSetter({
        pageNumber: currentPage + 1,
        payloadSize,
        totalRecords,
        isInfinite: false,
      });
    }
  };

  const gotoPage = selectedValue => {
    const totalPages = Math.ceil(
      totalRecords / (customPageSize || payloadSize),
    );
    const pageNumber = selectedValue.value;

    if (pageNumber > 0 && pageNumber <= totalPages) {
      paginationSetter({
        pageNumber,
        payloadSize,
        totalRecords,
        isInfinite: false,
      });
    }
  };

  const totalPages = Math.ceil(totalRecords / (customPageSize || payloadSize));
  const pageOptions = [];
  for (let i = 1; i <= totalPages; i++) {
    pageOptions.push({ value: i, label: i });
  }
  let itemKeyword = t('items');

  switch (analyticsType) {
    case 'sales':
      itemKeyword = t('sales');
      break;
    case 'categories':
      itemKeyword = t('categories');
      break;
    case 'brands':
      itemKeyword = t('brands');
      break;
    case 'group':
      itemKeyword = t('Bundles');
      break;
    case 'customers':
      itemKeyword = t('Customers');
      break;
    case 'products':
      itemKeyword = t('products');
      break;
    case 'templates':
      itemKeyword = t('templates');
      break;
    default:
      itemKeyword = t('items');
  }

  const currentPageOptions = pageNumberOptions.map(currentPageOption => ({
    value: `${currentPageOption}`,
    label: `${currentPageOption} ${itemKeyword}`,
  }));

  useEffect(() => {
    setPageSize();
  }, [totalRecords, currentPage, payloadSize]);

  return (
    <div className='pagination-wrapper'>
      <div className='pager-size move-to-start'>
        {!isBannerifyFilter && (
          <Select
            value={payloadSize}
            options={currentPageOptions}
            name='pageSize'
            clearable={false}
            onChange={pageSize}
            className='select--pagenumber'
            searchable={false}
          />
        )}
      </div>

      <div
        className='pagination'
        style={
          analyticsType === 'group'
            ? { backgroundColor: '#F8F8F8' }
            : { backgroundColor: '#fff' }
        }
      >
        <a
          className={`${
            currentPage === 1 ? 'isDisabled' : ''
          } pager-pagination-item pager-pagination-prev`}
          onClick={prevPage}
        >
          <Icon name='chevron-left' />
        </a>
        <Select
          value={currentPage}
          options={pageOptions}
          name='currentPage'
          clearable={false}
          onChange={gotoPage}
          className='select--pagesize flex-basis-44'
          inputProps={{ maxLength: totalPages.toString().length }}
          noResultsText=''
        />
        <a
          className={`${
            totalPages === currentPage ? 'isDisabled' : ''
          } pager-pagination-item pager-pagination-next`}
          onClick={nextPage}
        >
          <Icon name='chevron-right' />
        </a>
        <span className='item-label'> / {totalPages}</span>
      </div>
    </div>
  );
};

export default PaginationController;
