import { v1 } from 'uuid';
import { t } from '../../../../system/ui';
import { behaviouralTargetingParams } from '../../../../constants/datamaps/wizard';
import {
  cssTemplate,
  htmlTemplate,
  postJSTemplate,
  preJSTemplate,
} from './templatesWOF';

import {
  htmlTemplate as ScratchHTMLTemplate,
  preJSTemplate as ScratchPreJSTemplate,
  postJSTemplate as ScratchPostJSTemplate,
  cssTemplate as ScratchCSSTemplate,
} from './templatesScratch';

const breadCrumbOptions = {
  home: {
    name: t('Engagement'),
    icon: 'behaviouralTargeting',
    slug: '/behavioural-targeting/engagement/view-all',
    iconSvg: true,
  },
  current: {
    name: 'Gamification',
  },
};

const getDynamicBreadCrumbOptions = currentName => {
  return {
    home: {
      name: t('Engagement'),
      icon: 'behaviouralTargeting',
      slug: '/behavioural-targeting/engagement/view-all',
      iconSvg: true,
    },
    top: {
      name: t('Engagement'),
      slug: '/behavioural-targeting/engagement/gamification/list',
    },
    tops: {
      name: t('Gamification'),
      slug: '/behavioural-targeting/engagement/gamification/list',
    },
    current: {
      name: currentName,
    },
  };
};

const actions = [
  {
    icon: 'eye',
    handler: 'editAction',
    name: 'Edit',
    iconSvg: true,
  },
  {
    icon: 'pie-chart',
    handler: 'reportAction',
    name: 'Report',
    iconSvg: false,
    activeOnly: ['SCRATCH'],
  },
  {
    icon: 'duplicate',
    handler: 'duplicateAction',
    name: 'Duplicate',
    iconSvg: true,
  },
  {
    icon: '', // This icon name is not important for this action
    handler: 'activeAction',
    name: 'Active or Passive',
    iconSvg: true,
    activeOnly: ['WHEEL_OF_FORTUNE'],
  },
  {
    icon: 'crossCircle',
    handler: 'removeAction',
    name: 'Delete',
    iconSvg: true,
    activeOnly: ['WHEEL_OF_FORTUNE'],
  },
  {
    icon: 'archive',
    handler: 'removeAction',
    name: 'Archive',
    iconSvg: true,
    activeOnly: ['SCRATCH'],
  },
  {
    icon: 'history',
    handler: 'historyAction',
    name: 'History',
    iconSvg: true,
  },
];

const campaignWidgets = [
  {
    name: 'Wheel of Fortune',
    type: 'WHEEL_OF_FORTUNE',
    icon: 'wheel_of_fortune',
    description: 'Spin the wheel and get promotions!',
    actionTitle: 'Create',
    actionIcon: 'plus',
  },
  {
    name: 'Scratch Off',
    type: 'SCRATCH',
    icon: 'scratch',
    description: 'Feeling Lucky? Scratch the card and get promotions!',
    actionTitle: 'Create',
    actionIcon: 'plus',
  },
];

const campaignActionButtonConstant = [
  { btnLabel: 'Advanced Configuration', type: 'advanceConfig', key: '1' },
  { btnLabel: 'Go Test', type: 'test', key: '2' },
  { btnLabel: 'Go Live', type: 'live', key: '3' },
];

const wofTypes = [
  {
    label: 'Generic Code Distribution',
    description:
      'It allows to distribute the same code to each person in a single slice. The total distribution can be limited. (Ex: TECH20)',
    value: 'GENERIC',
  },
  {
    label: 'Unique Code Distribution',
    description:
      'It allows different (unique) codes are distributed to each person with the imported Excel. (Coupon codes are limited to 12 characters.)',
    value: 'UNIQUE',
  },
];

const scratchTypes = [
  {
    label: 'Generic Code Distribution',
    description:
      'It allows to distribute the same code to each person in a single slice. The total distribution can be limited. (Ex: TECH20)',
    value: 'GENERIC',
  },
  {
    label: 'Unique Code Distribution',
    description:
      'It allows different (unique) codes are distributed to each person with the imported Excel.',
    value: 'UNIQUE',
  },
];

const wofAdvancedConfig = {
  activeTabs: [
    { key: 'html', label: 'Edit HTML' },
    { key: 'preJs', label: 'Edit PreJS' },
    { key: 'postJs', label: 'Edit PostJS' },
    { key: 'css', label: 'Edit CSS' },
  ],
  params: behaviouralTargetingParams,
};

const wofModalStates = Object.freeze({
  WOF_TYPE: 'wof-type',
  UNIQUE_LIST_SCREEN: 'unique-list-screen',
  UNIQUE_EDIT_SCREEN: 'unique-edit-screen',
});

const modalStateDetails = Object.freeze({
  [wofModalStates.WOF_TYPE]: {
    title: 'Select Type of Your Coupons',
    titlePosition: 'left',
    description: '',
    buttonText: 'Continue',
  },
  [wofModalStates.UNIQUE_LIST_SCREEN]: {
    title: 'Upload Your Coupon File',
    titlePosition: 'center',
    description: '',
    buttonText: 'Continue',
  },
  [wofModalStates.UNIQUE_EDIT_SCREEN]: {
    title: 'Re-Upload Your Coupon File',
    titlePosition: 'center',
    description: '',
    buttonText: 'Continue',
  },
});

const initialWOFDetails = Object.freeze({
  type: 'WHEEL_OF_FORTUNE',
  name: '',
  instanceId: '',
  status: 'ACTIVE',
  devices: [],
  startDate: '',
  endDate: '',
  note: '',
  timing: {
    param: '',
    type: 'IMMEDIATE',
  },
  frequency: {
    param: '',
    type: 'ALWAYS',
  },
  filters: [],
  prioritized: true,
  campaignTitle: '',
  resultTitle: '',
  priority: 0,
  possibleRewards: [
    {
      type: 'slice',
      baseCodeCount: 1,
      basePossibility: 25,
      couponCodes: [],
      couponUrl: '',
      description: '',
      possibility: 25,
      content: '',
      color: '#FF6D6D',
      index: 0,
    },
    {
      type: 'slice',
      baseCodeCount: 1,
      basePossibility: 25,
      couponCodes: [],
      couponUrl: '',
      description: '',
      possibility: 25,
      content: '',
      color: '#83E889',
      index: 1,
    },
    {
      type: 'slice',
      baseCodeCount: 1,
      basePossibility: 25,
      couponCodes: [],
      couponUrl: '',
      description: '',
      possibility: 25,
      content: '',
      color: '#CF71F5',
      index: 2,
    },
    {
      type: 'slice',
      baseCodeCount: 1,
      basePossibility: 25,
      couponCodes: [],
      couponUrl: '',
      description: '',
      possibility: 25,
      content: '',
      color: '#F5DE59',
      index: 3,
    },
  ],
  gamificationType: 'GENERIC',
  html: htmlTemplate || '',
  css: cssTemplate || '',
  preJs: preJSTemplate || '',
  postJs: postJSTemplate || '',
  overlay: false,
  verticalPosition: 'middle',
  horizontalPosition: 'center',
  limit: '',
  ctaButtonContent: '',
  ctaButtonColor: '#FFFFFF',
  ctaButtonTextColor: '#000000',
  baseColor: '#FFFFFF',
  bgImage: 'https://cdn.segmentify.com/v3/assets/wofBackgroundImg.png',
  pointerColor: '#D31115',
  pointerImage: '',
});

const initialScratchDetails = {
  type: 'SCRATCH',
  name: '',
  instanceId: '',
  status: 'ACTIVE',
  description: '',
  devices: [],
  startDate: '',
  endDate: '',
  note: '',
  timing: {
    param: '',
    type: 'IMMEDIATE',
  },
  frequency: {
    type: 'ALWAYS',
  },
  filters: [],
  prioritized: true,
  campaignTitle: '',
  priority: 0,
  possibleRewards: [
    {
      type: 'scratch',
      baseCodeCount: 1,
      basePossibility: 100,
      couponCodes: [],
      couponUrl: '',
      description: '',
      possibility: 100,
      content: '',
      color: '#FF6D6D',
      index: 0,
    },
  ],
  gamificationType: 'GENERIC',
  html: ScratchHTMLTemplate || '',
  css: ScratchCSSTemplate || '',
  preJs: ScratchPreJSTemplate || '',
  postJs: ScratchPostJSTemplate || '',
  overlay: false,
  verticalPosition: 'middle',
  horizontalPosition: 'center',
  scratchBgColor: '#FFFFFF',
  scratchTextColor: '#000000FF',
  contentOfResultButton: '',
  bgBgColor: '#FFFFFF',
  bgTextColor: '#000000FF',
  bgImage: '',
};

const wofTabs = [
  {
    id: v1(),
    active: true,
    componentName: t('Settings'),
    type: 'settings',
  },
  {
    id: v1(),
    active: false,
    componentName: t('Style'),
    type: 'style',
  },
];

const scratchTabs = [
  {
    id: v1(),
    active: true,
    componentName: t('Settings'),
    type: 'settings',
  },
  {
    id: v1(),
    active: false,
    componentName: t('Style'),
    type: 'style',
  },
];

export {
  campaignWidgets,
  breadCrumbOptions,
  getDynamicBreadCrumbOptions,
  actions,
  campaignActionButtonConstant,
  wofAdvancedConfig,
  wofTypes,
  scratchTypes,
  wofModalStates,
  modalStateDetails,
  initialWOFDetails,
  initialScratchDetails,
  wofTabs,
  scratchTabs,
};
