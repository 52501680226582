import React, { Component } from 'react';
import { connect } from 'react-redux';

import { confirmOnLeave } from '../actions/ui';
import { t } from '../system/ui';
import { setTitle } from '../system/document';

import EditPushCampaign from '../components/push/edit';

import { initialStates } from '../constants/datamaps/push';
import { wizardActions } from '../actions';

class EditPushPermission extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.setCampaign = this.setCampaign.bind(this);
  }

  componentDidMount() {
    setTitle(t('Edit Push Campaign'));
    if (!this.props.route.isReplicate && !this.props.isPreview) {
      confirmOnLeave(this);
    }
    this.setCampaign(this.props.params.pushType);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (this.props.params.pushType !== newProps.params.pushType) {
      this.setCampaign(newProps.params.pushType);
    }
  }

  componentWillUnmount() {
    wizardActions.saveReset();
    wizardActions.wizardEdit();
    wizardActions.wizardReset();
  }

  setCampaign(campaignType) {
    this.setState(initialStates[campaignType]);
  }

  render() {
    return this.state.config ? (
      <EditPushCampaign
        type={this.props.params.pushType}
        actualType={this.props.params.pushType}
        title={this.state.title}
        mainField={this.state.mainField}
        additionalFields={this.state.additionalFields}
        config={this.state.config}
        buttons={this.state.buttons}
        additionalConfig={this.state.additionalConfig}
        campaignId={this.props.params.campaignId || this.props.params.pushType}
        mutualFields={this.state.mutualFields}
        isReplicate={this.props.route.isReplicate}
        isPreview={this.props.isPreview}
      />
    ) : null;
  }
}

const MapStatesToProps = () => ({});

export default connect(MapStatesToProps)(EditPushPermission);
