import React, { useEffect, useState } from 'react';
import classes from './SegmentsCard.module.scss';
import './segmentation-modal.scss';
import { t } from '../../../../system/ui';
import { modalActions } from '../../../../actions';

const SegmentsCard = ({ segments, enumSegmentType }) => {
  const [segmentListWoSlice, setSegmentListWoSlice] = useState(segments);

  const segmentParser = () => {
    let segmentList = [];
    Object.keys(segments).forEach(segmentType => {
      segmentList = { ...segmentList, [segmentType]: segments[segmentType] };
    });
    setSegmentListWoSlice(segmentList);
  };
  const invokeModal = (listItemsbyType, segmentType) => {
    const { openModal } = modalActions;
    openModal({
      title: `${enumSegmentType[segmentType]} (${listItemsbyType.length})`,
      content: () => (
        <div className='text-items'>
          {listItemsbyType.map(el => {
            return <div className='text-item'>{el}</div>;
          })}
        </div>
      ),
      className: 'sfy-modal--segmentation',
    });
  };
  const segmentList = () => {
    return Object.keys(segmentListWoSlice).map(segmentType => {
      const isShowMoreButton = segmentListWoSlice[segmentType].length > 3;
      const moreItemsCount = segmentListWoSlice[segmentType].length - 3;
      const segmentListItems = segmentListWoSlice[segmentType]
        .slice(0, 3)
        .map(segment => {
          return (
            <div className={classes['segment-content']} key={segment}>
              {segment}
            </div>
          );
        });

      return (
        <div className={classes['segments-content']} key={segmentType}>
          <div className={classes['segment-header']}>
            {enumSegmentType[segmentType]}
          </div>
          <div className={classes['segment-list']}>
            {segmentListItems && segmentListItems.length > 0 ? (
              segmentListItems
            ) : (
              <div className={classes['segment-content-nodata']}>
                <i className='icon-info' color='#979797' />
                {t('There is no data for now')}
              </div>
            )}
            {isShowMoreButton && (
              <div
                className={classes['segment-list-more']}
                onClick={() => {
                  invokeModal(segmentListWoSlice[segmentType], segmentType);
                }}
              >
                +{`${moreItemsCount} ${t('More')}`}
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    segmentParser();
    segmentList();
  }, [segments]);

  return (
    <div className={classes['segments-container']}>
      <div className={classes['segments-header']}>{t('User Segments')}</div>
      <div className={classes['segments-content']}>{segmentList()}</div>
    </div>
  );
};

export default SegmentsCard;
