import React, { Component } from 'react';
import { connect } from 'react-redux';

import { uiActions, wizardActions } from '../../../actions';

import { TextField } from '../../../components/fields';
import { t } from '../../../system/ui';

import Icons from '../../../components/icons';

class ButtonText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      name: 'buttonText',
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    const buttonText = this.props.campaign.buttonText || '';
    this.updateValue(buttonText);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.buttonText);
    }

    if (this.props.options.personaQuiz) {
      this.updateValue(newProps.campaign.resultButton?.title);
    }
  }

  onFieldChange(e) {
    this.updateValue(e.target.value);
    uiActions.formEdited();
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  render() {
    return (
      <>
        {this.props.options.personaQuiz ? (
          <div>
            <h3 className='wizard-input-type-title'>Result CTA Button</h3>
            <div className='item-buttonText-field'>
              <Icons name='callToAction' />
              <TextField
                name='buttonText'
                label={t(this.props.options.label)}
                className='item-stacked one-whole'
                value={this.state.value}
                required={
                  this.props.forceOptional ? false : this.props.options.required
                }
                onChange={this.onFieldChange}
                tooltip={this.props.options.tooltip}
                tooltipText={this.props.options.tooltipContent}
                placeholder={this.props.options.placeholder}
              />
            </div>
          </div>
        ) : (
          <li className='item-buttonText-field'>
            <TextField
              name='buttonText'
              label={t(this.props.options.label)}
              className='item-stacked one-whole'
              value={this.state.value}
              required={
                this.props.forceOptional ? false : this.props.options.required
              }
              onChange={this.onFieldChange}
              tooltip={this.props.options.tooltip}
              tooltipText={this.props.options.tooltipContent}
              placeholder={this.props.options.placeholder}
            />
          </li>
        )}
      </>
    );
  }
}

ButtonText.defaultProps = {
  options: {
    label: 'Button Text',
    required: true,
    tooltip: false,
    tooltipContent: '',
  },
};

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  ButtonText,
);
