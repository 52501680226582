/**
 * @author Bilal Cinarli
 */

import React from 'react';
import { connect } from 'react-redux';

import { browserHistory } from 'react-router';
import { uiActions, campaignActions, filterActions } from '../actions';

import { getCampaigns } from '../modules/campaigns/ajax';

import Recommendation from './recommendation';
import AllRecommendations from './recommendations.viewall';
import LoaderPlaceholder from '../components/widget/loader-placeholder';

import { getStartEndDate } from '../system/date';
import {
  hasModule,
  hasModulePrivilege,
  hasOnlyDynamicBundles,
} from '../modules/auth/user';
import { t } from '../system/ui';
import { recoModuleIntegrationInfo } from '../modules/trendify/page';
import Icon from '../components/icon';
import { facetedSearchRequest } from '../actions/faceted-search';

class Recommendations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasCampaigns: false,
      campaigns: [],
      isLoading: true,
      defaultStatus: false,
      idsInAB: [],
    };

    this.getCampaigns = this.getCampaigns.bind(this);
    this.getCampaignsAfterArchive = this.getCampaignsAfterArchive.bind(this);
  }

  componentDidMount() {
    uiActions.resetPage();
    uiActions.isLoading();
    campaignActions.resetWizard();
    this.getCampaigns();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.user !== newProps.user) {
      this.getCampaigns();
    }
    if (newProps.update === true) {
      // this.update(newProps.campaigns);
      this.getCampaigns();
    }
  }

  getCampaigns(defaultStatus) {
    recoModuleIntegrationInfo();
    if (hasModule('recommendation') || hasOnlyDynamicBundles()) {
      const boundaries = getStartEndDate('weekly');
      facetedSearchRequest(
        'experimentation/campaignlist',
        null,
        '&module=RECOMMENDATION',
      )
        .get()
        .then(idsInAB => this.setState({ idsInAB }));
      getCampaigns(response => {
        let redirect = '/recommendations/view-all';

        this.setState({
          campaigns: response,
          defaultStatus: defaultStatus || false,
        });

        uiActions.isLoaded();

        if (response.length === 0) {
          redirect = '/recommendations/home';
          if (!this.props.params.recommendation) {
            this.props.router.push({
              pathname: redirect,
            });
          }

          this.setState(
            {
              isLoading: false,
            },
            () => {
              uiActions.addPageClass('has-no-campaign');
            },
          );
        } else {
          this.setState(
            {
              hasCampaigns: true,
              isLoading: false,
              defaultStatus: defaultStatus || false,
            },
            () => {
              if (!this.props.params.recommendation) {
                this.props.router.push({
                  pathname: redirect,
                });
              }
            },
          );
        }
      }, boundaries);
      // make update paramater false
      filterActions.filtersUpdated();
    } else {
      uiActions.isLoaded();
    }
  }

  getCampaignsAfterArchive() {
    uiActions.isLoading();
    getCampaigns(response => {
      this.setState({
        campaigns: response,
        defaultStatus: 'Test',
      });
      uiActions.isLoaded();
    });
  }

  render() {
    if (!hasModulePrivilege(false, 'RECOUSER')) {
      browserHistory.push('/');
    }
    if (!hasModule('recommendation') && !hasOnlyDynamicBundles()) {
      return (
        <div>
          <div className='promo promo--centered reco-discover-dashboard'>
            <Icon name='heart' />
            <p className='promo-text'>{t('RecoPromo')}</p>
            <a
              href='https://segmentify.com/solution/recommendation/'
              target='_blank'
              className='secondary-action'
              rel='noreferrer'
            >
              {t('Discover')}
            </a>
          </div>
        </div>
      );
    }
    return (
      <div>
        {this.state.isLoading ? (
          <LoaderPlaceholder />
        ) : this.props.params.recommendation === 'view-all' ||
          !this.props.params.recommendation ? (
          <AllRecommendations
            {...this.props}
            campaigns={this.state.campaigns}
            key='view-all'
            campaignType='recommendation'
            defaultStatus={this.state.defaultStatus}
            getCampaigns={this.getCampaigns}
            getCampaignsAfterArchive={this.getCampaignsAfterArchive}
            placeHolder={<LoaderPlaceholder />}
            idsInAB={this.state.idsInAB}
          />
        ) : (
          <Recommendation
            {...this.props}
            campaigns={this.state.campaigns}
            key={this.props.params.recommendation}
            idsInAB={this.state.idsInAB}
          />
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  breadcrumb: store.ui.breadcrumb,
  update: store.filters.update,
});

export default connect(mapStatesToProps)(Recommendations);
