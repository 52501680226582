import React from "react";
import connect from "react-redux/es/connect/connect";
import { t } from "../../../system/ui";
import { getPopularSearches, initialRequestQueryParams } from "../../../modules/search/insights/ajax";
import { defaultFieldMap } from "./utils/table-utils";
import SgfDivTable from "./sub-components/table/sgf-div-table";
import { searchInsightsActions, uiActions } from "../../../actions";
import CardWrapper from "../../CardWrapper";
import { queryDate } from '../../../system/date';
import { exportCb, revenueConverter } from './utils/common-capabilities';
class SearchInsightsPopularSearchesDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      range: undefined,
      tableName: "popularSearchesDetail",
      tableHeaders: [
        {
          name: "Popular Searches",
          fieldMap: defaultFieldMap["Popular Searches"]
        },
        {
          name: "Total Searches",
          fieldMap: defaultFieldMap["Total Searches"]
        },
        {
          name: "CTR",
          fieldMap: defaultFieldMap["CTR"]
        },
        {
          name: "Purchases",
          fieldMap: defaultFieldMap["Purchases"]
        },
        {
          name: "Revenue",
          fieldMap: defaultFieldMap["Revenue"]
        },
        {
          name: "Conversion Rate",
          fieldMap: defaultFieldMap["Conversion Rate"]
        },
        {
          name: "Per Search Value",
          fieldMap: defaultFieldMap["Per Search Value"]
        },
        {
          name: "Best Product",
          fieldMap: defaultFieldMap["Best Product Detail"]
        }
      ],
      totalSearchesChartConfig: {},
      totalRevenueChartConfig: {},
      requestParameter: Object.assign({}, initialRequestQueryParams, {sort: "search", reverse: true, device: 'all' }),
    };

    this.getData = this.getData.bind(this);
    this.onClickBreadCrumb = this.onClickBreadCrumb.bind(this);

    this.deviceFilterLookup = ['PC', 'TABLET', 'MOBILE', "IOS", "ANDROID"];
  }

  selectDevice = (selectedDevice) => {
    this.setState({
      requestParameter: {
        ...this.state.requestParameter,
        lang: this.props.selectedLanguage,
        device: selectedDevice,
        start: this.props.range[0],
        end: this.props.range[1]
      }
    }, () => {
      this.getData(true);
    });
  };

  componentDidMount() {
    this.setState({
      requestParameter: {
        lang: this.props.selectedLanguage,
        device: "all",
        sort: "search",
        reverse: true,
        start: this.props.range[0],
        end: this.props.range[1]
      }
    }, () => {
      this.getData();
    });
  }

  componentWillReceiveProps(newProps) {
    let sortKey = this.state.requestParameter.sort;
    let reverse = this.state.requestParameter.reverse;
    let sortChanged = false;
    if ( newProps.clickedCellItem
      && newProps.clickedCellItem.fieldMap
      && newProps.clickedCellItem.fieldMap.sortKey
      && this.state.tableName === newProps.clickedCellItem.tableName
      && this.isSortedChanged(this.props.clickedCellItemId, newProps.clickedCellItemId)
      ) {
      sortKey = newProps.clickedCellItem.fieldMap.sortKey;
      reverse = (this.state.requestParameter.sort === newProps.clickedCellItem.fieldMap.sortKey) ? !this.state.requestParameter.reverse : this.state.requestParameter.reverse;
      sortChanged = true;
    }
    if (newProps.range[0] && newProps.range[1]) {
      if (this.props.range[0] !== newProps.range[0]
        || this.props.range[1] !== newProps.range[1]
        || this.props.selectedLanguage !== newProps.selectedLanguage
        || sortChanged) {
        this.setState({
          requestParameter: {
            ...this.state.requestParameter,
            lang: newProps.selectedLanguage,
            sort: sortKey,
            reverse,
            start: newProps.range[0],
            end: newProps.range[1]
          }
        }, () => {
          this.getData();
        });
      }
    }
  }
  getData() {
    uiActions.isLoading();
    getPopularSearches(this.state.requestParameter, popularSearchSummaryData => {
      uiActions.isLoaded();
      this.setState({
        data: popularSearchSummaryData,
      });
    });
  }

  onClickBreadCrumb() {
    searchInsightsActions.openDetailTable("");
  }

  isSortedChanged = (currentId, nextId) => {
    if (currentId !== nextId) {
      return true;
    }
    return false;
  };

  export = () => {
    const { start, end, lang } = this.state.requestParameter;
    exportCb(queryDate(start), queryDate(end), lang, 'popular');
  };

  convertData(data) {
    return revenueConverter(revenueConverter(data, 'revenue'), 'perSearchValue');
  }

  render() {
    return (
      <div>
        {this.props.isDetail &&
          <div style={{display: "flex", justifyContent: "space-between", marginBottom: "5px"}}>
            <nav id="breadcrumb" className="breadcrumb search__breadcrumb">
                <a className="nav-item breadcrumb-item current" onClick={this.onClickBreadCrumb}>
                  <i className="icon-asset" role="presentation">
                    <span className="for-screenreader-only">asset icon</span>
                  </i>
                  {t("General")}
                </a>
                <span className="nav-item breadcrumb-item current">{t("Popular Searches")}</span>
            </nav>
            <a className='secondary-action' onClick={this.export}>{t('Export')}</a>
          </div>
        }

        <CardWrapper
          hasDeviceFilter
          devicesLookup={this.deviceFilterLookup}
          devicesValue={this.selectDevice}
          label={t("Popular Searches")}
          className="widget"
          infoContent={
            <div className="search__info-text">
              <strong> Popular Searches: </strong>
              <span>The most searched words/phrases independent from assets</span>
            </div>
          }
        >

          <SgfDivTable data={this.convertData(this.state.data)} headers={this.state.tableHeaders} tableName={this.state.tableName}/>
        </CardWrapper>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  clickedCellItem: store.sgfDivTable.clickedCellItem,
  clickedCellItemId: store.sgfDivTable.clickedCellItemId,
  selectedLanguage: store.switchLanguage.selectedLanguage,
});

export default connect(mapStatesToProps)(SearchInsightsPopularSearchesDetail);
