import {
  getApiKey,
  getSwitchAccountUser,
  getToken,
  getRegion,
  isSwitchAccount,
  isMultiAccountUser,
  getAccount,
} from '../../../../../modules/auth/user';
import { getEndPoint } from '../../../../../system/api';
import { getExternalAccount, getSource } from '../../../../../index';

const fetchData = async (boundaries, type) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  const token = getToken();
  const region = getRegion().trim();
  let headerObj = {};
  let noSwitchHeader = true;
  let account = '';
  if (
    getSource() !== null &&
    getSource() === 'external_app' &&
    noSwitchHeader
  ) {
    if (getExternalAccount() !== null && getExternalAccount().length > 0) {
      account = getExternalAccount().trim();
    }
    noSwitchHeader = false;
  }

  if (isMultiAccountUser() && isSwitchAccount() && noSwitchHeader) {
    account = getAccount().accountId.trim();
  }

  headerObj = {
    Authorization: `Bearer ${token}`,
    'X-Switch-Region': region,
    'X-Switch-User': getSwitchAccountUser().username,
    'Content-Type': 'application/json; charset:utf-8',
  };

  if (!getSwitchAccountUser().username) {
    delete headerObj['X-Switch-User'];
  }

  if (account) {
    headerObj['X-Switch-Account'] = account;
  }

  const res = await fetch(
    `${getEndPoint('emails/account_trend')}${globalApiKey}&type=${type}&start=${
      boundaries.start
    }&end=${boundaries.end}&interval=total&limit=2000`,
    {
      headers: new Headers(headerObj),
    },
  );
  return res.json();
};

export default fetchData;
