import React, { createContext, useReducer } from 'react';
import { monitoringReducers } from './reducers/monitoringReducers';
import {
  setUsers,
  setSelectedUser,
  setSelectableUsers,
  setAccounts,
  setSelectedAccounts,
  editSelectedAccounts,
  setIsMonitoringLoaded,
} from './actions/monitoringActions';

/**
 * @name MonitoringProvider
 * @description MonitoringProvider component that provides state for Monitoring Context
 * @param {React.ReactNode} children
 * @return {JSX.Element}
 * @constructor
 */

export const Context = createContext(null);

const monitoringState = {
  users: [],
  selectableUsers: [],
  selectedUser: '',
  accounts: [],
  selectedAccounts: [],
  isMonitoringLoaded: false,
};

export const MonitoringProvider = ({ children }) => {
  const [state, dispatch] = useReducer(monitoringReducers, monitoringState);

  const boundActions = {};
  boundActions.setUsers = setUsers(dispatch);
  boundActions.setSelectableUsers = setSelectableUsers(dispatch);
  boundActions.setSelectedUser = setSelectedUser(dispatch);
  boundActions.setAccounts = setAccounts(dispatch);
  boundActions.setSelectedAccounts = setSelectedAccounts(dispatch);
  boundActions.editSelectedAccounts = editSelectedAccounts(dispatch);
  boundActions.setIsMonitoringLoaded = setIsMonitoringLoaded(dispatch);

  return (
    <Context.Provider value={{ state, ...boundActions }}>
      {children}
    </Context.Provider>
  );
};
