import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const spPurchaseCounter = ({ width, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 18 20'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      fill='none'
    >
      <path
        d='M14.5005 4.50097C14.5005 5.19148 13.9409 5.75124 13.2506 5.75124C12.5602 5.75124 12.0006 5.19148 12.0006 4.50097C12.0006 3.81046 12.5602 3.2507 13.2506 3.2507C13.9409 3.2507 14.5005 3.81046 14.5005 4.50097ZM7.50834 0.898363C8.11282 0.321709 8.91609 0 9.75136 0H15.2501C16.7688 0 18 1.23148 18 2.75059V7.7043C18 8.57554 17.6504 9.41032 17.0295 10.0214L10.0844 16.8571C9.20518 17.7224 7.79343 17.7184 6.91921 16.8481L0.662796 10.62C-0.234226 9.72699 -0.218726 8.26998 0.697085 7.39627L7.50834 0.898363ZM9.75136 1.50032C9.30158 1.50032 8.86909 1.67355 8.5436 1.98406L1.73231 8.48192C1.42704 8.77319 1.42188 9.25889 1.72088 9.55655L7.97732 15.7847C8.26871 16.0748 8.7393 16.0762 9.03239 15.7877L15.9776 8.95192C16.3119 8.62285 16.5 8.17346 16.5 7.7043V2.75059C16.5 2.06008 15.9405 1.50032 15.2501 1.50032H9.75136ZM17.7313 10.7343L16.2563 12.186C16.2165 12.2403 16.1718 12.292 16.1222 12.3408L10.7889 17.5703C10.5793 17.7758 10.3488 17.9473 10.1044 18.0846C8.86679 18.7842 7.26944 18.6075 6.21419 17.557L4.28288 15.6345L1.8717 13.2479C1.83299 13.2096 1.79731 13.1694 1.76466 13.1276L0.135923 11.5062C-0.175278 12.4634 0.0518354 13.5574 0.816681 14.3144L5.17294 18.6261C7.01755 20.4519 9.98576 20.4588 11.839 18.6417L17.1721 13.4122C17.9453 12.6541 18.1732 11.5568 17.8632 10.5985C17.8204 10.6446 17.7764 10.6899 17.7313 10.7343Z'
        fill={color}
      />
    </svg>
  );
};

spPurchaseCounter.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

spPurchaseCounter.defaultProps = {
  width: '18px',
  height: '20px',
  color: '#5D5D61',
};

export default spPurchaseCounter;
