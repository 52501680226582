"use strict";

import React, { Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { SortableHandle } from "react-sortable-hoc";
import { brandsActions } from "../../../actions";
import { items } from "../../../constants/datamaps/wizard";
import { t } from "../../../system/ui";

import { getBrands } from "../../../modules/account/ajax";
import { decodeHtml } from "../../../system/string";

import Icon from "../../icon";
import Icons from "../../icons";

import Tooltip from "../../../components/tooltip";

class SearchCriterionBrand extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brands: [],
    };

    this.onChange = this.onChange.bind(this);
    this.setBrands = this.setBrands.bind(this);
  }

  componentDidMount() {
    if (this.props.isBrandsSet && this.props.brands.length) {
      this.setBrands(this.props.brands);
    } else {
      getBrands().then(response => {
        let sorted = response.sort();
        let brands = [];
        sorted.map(item => {
          brands.push({
            value: item,
            label: decodeHtml(item)
          });
        });
        if (typeof this.props.values.criterion !== "undefined") {
          brands.push({
            value: this.props.values.criterion.value,
            label: decodeHtml(this.props.values.criterion.value)
          });
        }
        brandsActions.setBrands(brands);
        this.setBrands(brands);
      });
    }
  }

  setBrands(brands) {
    this.setState(
      {
        brands: brands,
        criterion:
          typeof this.props.values.criterion !== "undefined"
            ? this.props.values.criterion.value
            : ""
      },
      () => {
        this.onChange({
          value: this.state.criterion,
          label: this.state.criterion
        });
      }
    );
  }

  onChange(selectedValue) {
    let selectedBrand =
      "value" in selectedValue ? selectedValue.value : this.state.criterion;
    this.setState({ criterion: selectedBrand });
    this.props.onCriteriaChange(this.refs.inputs, this.props.id, selectedBrand);
  }


  render() {
    let theItemCount =
      typeof this.props.values.itemCount !== "undefined"
        ? this.props.values.itemCount.value
        : "";

    const DragHandle = SortableHandle(() => (
      <span className="draghandler">
        <Icons name="burger" color="#e8e7f2" />
      </span>
    ));

    return (
      <li className="wizard-criterion" ref="inputs">
        <DragHandle />
        <span className="criteria-type">
          <Tooltip content={t("Static (Brand)")} alignment="left">
            <Icon name="star" />
          </Tooltip>
        </span>

        <label className="item item-field is-select criteria-name-search">
          <Select
            value={this.state.criterion}
            options={this.state.brands}
            name="criterion"
            clearable={false}
            onChange={this.onChange}
            className="criteria-field-search one-whole"
            placeholder={t("Select Brand")}
          />
        </label>

        <label className="item item-field is-select criteria-item-count">
          <span className="item-label">{this.props.mainType.toLowerCase()}</span>{" "}
          <select
            name="itemCount"
            className="one-whole criteria-field-search"
            onChange={this.onChange}
            disabled={true}
            defaultValue={theItemCount}
          >
            {items.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </label>

        
        <a
          onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
          className={"cancel-action"}
        >
          <Icons name="crossCircle" />
        </a>
      </li>
    );
  }
}

const mapStatesToProps = (store) => ({
  criteria: store.searchWizard.criteria,
  brands: store.brands.options,
  isBrandsSet: store.brands.isSet,
});

export default connect(mapStatesToProps)(SearchCriterionBrand);
