/**
 * Created by mehmetyurtar on 11/8/17.
 */
import React, { Component } from "react";
import { connect } from "react-redux";

import { uiActions } from "../../../actions";

import { Checkbox } from "../../../components/fields";

class Sticky extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: true,
      name: "sticky"
    };
    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    let sticky = this.props.campaign.sticky === "true";
    this.updateValue(sticky);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.sticky === "true");
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  onFieldChange() {
    this.updateValue(!this.state.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <span className="move-to-end">
        <Checkbox
          name="sticky"
          label="Sticky"
          checked={this.state.value}
          onChange={this.onFieldChange}
        />
      </span>
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(Sticky);
