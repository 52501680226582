import React from 'react';
import { t } from '../../../system/ui';
import { Select } from '../../fields';
import { isResponsible, isSuperiestUser } from '../../../modules/auth/user';
import { owners } from '../../../constants/datamaps/account';

const REDUCER_STATE_NAME = 'ownership';

const ACCOUNT_TYPE = {
  SALES_MANAGER: 'salesManager',
  ONBOARDING_MANAGER: 'onboardingManager',
  ONBOARDING_DEVELOPER: 'onboardingDeveloper',
  ACCOUNT_MANAGER: 'accountManager',
  ACCOUNT_DEVELOPER: 'accountDeveloper',
  AGENT_USER: 'agentUser',
};
const ROLE = {
  SALES_MANAGER: 'ROLE_SM',
  ONBOARDING_MANAGER: 'ROLE_OBM',
  ONBOARDING_DEVELOPER: 'ROLE_OBD',
  ACCOUNT_MANAGER: 'ROLE_ACM',
  ACCOUNT_DEVELOPER: 'ROLE_ACD',
  AGENT_USER: 'ROLE_AGU',
};

/**
 * This component helps to show Owners part of edit screen.
 * @param {Object} account
 * @param {Object} account.ownership
 * @param {Object} ownership
 * @param {Object[]} stateOwners
 * @param {Function} reducerStateChangeHandler
 * @returns {JSX.Element|null}
 */
const Owners = ({
  account,
  ownership,
  stateOwners,
  reducerStateChangeHandler,
}) => {
  const getResponsibleType = accountType => {
    switch (accountType) {
      case ACCOUNT_TYPE.SALES_MANAGER:
        return ROLE.SALES_MANAGER;
      case ACCOUNT_TYPE.ONBOARDING_DEVELOPER:
        return ROLE.ONBOARDING_DEVELOPER;
      case ACCOUNT_TYPE.ACCOUNT_DEVELOPER:
        return ROLE.ACCOUNT_DEVELOPER;
      case ACCOUNT_TYPE.ONBOARDING_MANAGER:
        return ROLE.ONBOARDING_MANAGER;
      case ACCOUNT_TYPE.ACCOUNT_MANAGER:
        return ROLE.ACCOUNT_MANAGER;
      case ACCOUNT_TYPE.AGENT_USER:
        return ROLE.AGENT_USER;
      default:
        return '';
    }
  };

  const prepareOwnersOptions = stateOwnerOptions => {
    const opts = {
      'Sales Manager': [],
      'Onboarding Manager': [],
      'Onboarding Developer': [],
      'Account Manager': [],
      'Account Developer': [],
      'Agent User': [],
    };

    stateOwnerOptions.forEach(item =>
      opts[item.type].push({ value: item.username, label: item.username }),
    );

    return opts;
  };

  const handleOwnerUpdate = (selectedOption, name = null) => {
    const tempOwnership = { ...ownership };

    if (name === ACCOUNT_TYPE.AGENT_USER) {
      tempOwnership[ACCOUNT_TYPE.AGENT_USER] = [
        ...selectedOption?.map(item => item?.value),
      ];
    }

    tempOwnership[selectedOption.name] = selectedOption.value;

    reducerStateChangeHandler(REDUCER_STATE_NAME, tempOwnership);
  };

  const ownerOptions = prepareOwnersOptions(stateOwners);

  return (
    <li>
      <h3 className='section-title'>{t('Owners')}</h3>
      <div className='row module-segment'>
        <div className='widget-stats'>
          <div className='widget-stat-segmentation  stat-segmentation' />

          <div className='widget-stat-segmentation  stat-segmentation'>
            <p className='widget-stat-conversion-account'>
              <b>{t('Responsible')}</b>
            </p>
          </div>
        </div>
        {owners.map(item => (
          <div
            className='row responsible'
            style={{ marginBottom: '-10px' }}
            key={item.key + item.name}
          >
            <hr className='widget-separator' style={{ margin: '10px' }} />
            <Select
              name={item.key}
              className='item-stacked owner-account-label ten-columns'
              value={account.ownership[item.key]}
              options={ownerOptions[item.name]}
              label={item.name}
              onChange={e =>
                getResponsibleType(item.key) === ROLE.AGENT_USER
                  ? handleOwnerUpdate(e, ACCOUNT_TYPE.AGENT_USER)
                  : handleOwnerUpdate(e)
              }
              multi={getResponsibleType(item.key) === ROLE.AGENT_USER ?? false}
              disabled={
                isSuperiestUser()
                  ? false
                  : !isResponsible(getResponsibleType(item.key))
              }
            />
          </div>
        ))}
      </div>
    </li>
  );
};

export default Owners;
