import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icons from './icons';

class Icon extends React.Component {
  onClickHandler() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const size = this.props.size ? `icon-${this.props.size}` : '';
    const className = classNames(
      `icon-${this.props.name}`,
      size,
      this.props.className,
    );

    return className === 'icon-sales' ? (
      <div className='sales-icon'>
        <Icons name='sales' />
      </div>
    ) : (
      <i
        className={className}
        role='presentation'
        onClick={e => this.onClickHandler(e)}
      >
        <span className='for-screenreader-only'>{this.props.name} icon</span>
      </i>
    );
  }
}

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
};

export default Icon;
