import { $http } from '../../system/ajax';
import { getRestEndPoint, getNode, getPath } from '../../system/api';
import { getAccountData, getApiKey } from '../auth/user';
import {
  operatorColumns,
  dateOperators,
} from '../../constants/datamaps/rule-based-segmentation';
import { config } from '../../constants/config';

const convertDataForBackend = data => {
  const dataToBeConvert = { ...data };
  const shouldAddState = statement => {
    let resultBool = false;
    if (statement.length > 0) {
      // If statement exists.
      resultBool = statement.every(item => {
        return item?.column && item?.operator && item?.value;
      });
    }
    return resultBool;
  };

  const criteriaStatementsControl = (criteriaType, statements) => {
    if (!criteriaType) {
      return null;
    }

    const margedStatements = [];
    Object.keys(statements).forEach(key => {
      const statement = statements[key];
      if (shouldAddState(statement)) {
        margedStatements.push(...statement);
      }
    });

    return { criteriaType, statements: margedStatements };
  };

  const groupControl = groups => {
    if (!groups) {
      return null;
    }

    const tempGroups = groups;
    tempGroups.criteriaGroups = groups?.criteriaGroups
      ?.map(group => {
        const tempCriterias = group?.criterias
          ?.map(criteria => {
            return criteriaStatementsControl(
              criteria.criteriaType,
              criteria.statements,
            );
          })
          .filter(criteria => criteria);

        if (tempCriterias.length === 0) {
          return null;
        }

        return { operator: group.operator, criterias: tempCriterias };
      })
      .filter(group => group);

    return { filter: tempGroups };
  };

  return groupControl(dataToBeConvert);
};

const isPeriodicSegmentation = data => {
  // Use this function with only converted segmentation data [convertDataForBackend]
  let resultBool = false;
  if (!data || data.length === 0) {
    return resultBool;
  }

  // Check every statement of each criteria of each group to see if it is periodic.
  resultBool = data.filter.criteriaGroups.some(group => {
    return group.criterias.some(criteria => {
      return criteria.statements.some(statement => {
        return (
          statement.column === operatorColumns.dateOperator &&
          statement.operator === dateOperators.last.value
        );
      });
    });
  });

  return resultBool;
};

export const addCampaign = ({
  segmentName,
  description,
  segmentStatus,
  segmentFilters,
}) => {
  const convertedSegmentation = convertDataForBackend(segmentFilters);
  const isPeriodic = isPeriodicSegmentation(convertedSegmentation);
  const campaignData = {
    segmentName,
    description,
    status: segmentStatus,
    type: 'RULE_BASED_SEGMENTATION',
    segmentationRequest: convertedSegmentation,
    accountId: getAccountData('accountId') || '',
    isPeriodic,
  };

  const url = `${getRestEndPoint('rbs/campaign')}?apiKey=${getApiKey()}`;

  return $http(url).post(campaignData, { type: 'json' });
};

export const editCampaign = ({
  segmentName,
  description,
  segmentStatus,
  segmentFilters,
  campaignDetails,
}) => {
  const convertedSegmentation = convertDataForBackend(segmentFilters);
  const isPeriodic = isPeriodicSegmentation(convertedSegmentation);
  const campaignData = {
    ...campaignDetails,
    segmentName,
    description,
    status: segmentStatus,
    segmentationRequest: convertedSegmentation,
    accountId: getAccountData('accountId') || '',
    isPeriodic,
  };

  const url = `${getRestEndPoint('rbs/campaign')}/${
    campaignData.instanceId
  }?apiKey=${getApiKey()}`;

  return $http(url).put(campaignData, { type: 'json' });
};

export const revertCampaign = campaign => {
  const campaignData = { ...campaign };
  campaignData.accountId = getAccountData('accountId') || '';
  campaignData.isPeriodic =
    campaign.isPeriodic ||
    isPeriodicSegmentation(campaign?.segmentationRequest);

  const url = `${getRestEndPoint('rbs/campaign')}/${
    campaignData.instanceId
  }?apiKey=${getApiKey()}`;

  return $http(url).put(campaignData, { type: 'json' });
};

export const getCampaignWithDetail = (instance, callback) => {
  return $http(`${getRestEndPoint('rbs/campaign')}/${instance}`)
    .get({
      apiKey: getApiKey(),
      withOps: true,
    })
    .then(response => {
      callback(response);
    });
};

export const moveCampaignToTest = instanceId => {
  return $http(
    `${getRestEndPoint(
      'rbs/campaign',
    )}/${instanceId}/TEST?apiKey=${getApiKey()}`,
  ).put();
};

export const moveCampaignToLive = instanceId => {
  return $http(
    `${getRestEndPoint(
      'rbs/campaign',
    )}/${instanceId}/LIVE?apiKey=${getApiKey()}`,
  ).put();
};

export const moveCampaignToArchive = instanceId => {
  return $http(
    `${getRestEndPoint(
      'rbs/campaign',
    )}/${instanceId}/ARCHIVE?apiKey=${getApiKey()}`,
  ).put();
};

export const getAllRbsStats = callback => {
  $http(`${getRestEndPoint('rbs/userCount')}?apiKey=${getApiKey()}`)
    .get()
    .then(response => {
      callback(response);
    });
};

export const getRbsGenders = callback => {
  $http(
    `${getPath()}${getNode(
      config.api.endpoints.rest,
      'search/rbs/gender',
    )}?&apiKey=${getApiKey()}`,
  )
    .get()
    .then(response => {
      callback(response);
    });
};
