import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const ReturnedMini = ({ width, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 17 16'
      fill='none'
    >
      <path
        xmlns='http://www.w3.org/2000/svg'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.17798 16C12.5963 16 16.178 12.4183 16.178 8C16.178 3.58172 12.5963 0 8.17798 0C3.7597 0 0.177979 3.58172 0.177979 8C0.177979 12.4183 3.7597 16 8.17798 16ZM6.7255 10.884C6.88182 10.7262 6.88198 10.4701 6.72587 10.312L4.74438 8.3053H8.92401L9.10246 8.30751C9.80007 8.32499 10.3057 8.44821 10.7523 8.6897C11.2041 8.93399 11.5562 9.29008 11.7978 9.7469C12.0566 10.236 12.178 10.7954 12.178 11.5955C12.178 11.8189 12.3571 12 12.578 12C12.7989 12 12.978 11.8189 12.978 11.5955C12.978 10.6693 12.8283 9.97992 12.5033 9.36542C12.1871 8.76763 11.7208 8.29608 11.1296 7.97634L10.9981 7.90866C10.4232 7.62689 9.77455 7.49639 8.92401 7.49639H4.74171L6.72571 5.49043C6.88193 5.33246 6.88187 5.07637 6.72566 4.91844C6.56945 4.7605 6.31617 4.76053 6.15995 4.91849L3.4951 7.61363C3.33899 7.77147 3.33894 8.02741 3.49494 8.18537L6.15979 10.8836C6.3159 11.0417 6.56918 11.0419 6.7255 10.884Z'
        fill={color}
      />
    </svg>
  );
};

ReturnedMini.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

ReturnedMini.defaultProps = {
  width: '17px',
  height: '16px',
  color: '#FBA72A',
};

export default ReturnedMini;
