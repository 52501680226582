import React from 'react';
import './CampaignCreate.scss';
import CampaignWidget from '../../sfy-components/CampaignWidget/CampaignWidget';

/**
 * @name GFCampaignList component
 * @param actions
 * @returns {JSX.Element}
 * @constructor
 */

const CampaignCreate = ({ campaignWidgets, paginationOptions }) => {
  return (
    <div className='gamification-create'>
      <div className='gamification-create-title'>Campaigns</div>
      <div className='gamification-create-widgets'>
        {campaignWidgets.map(campaignWidget => (
          <CampaignWidget
            name={campaignWidget.name}
            type={campaignWidget.type}
            icon={campaignWidget.icon}
            description={campaignWidget.description}
            actionTitle={campaignWidget.actionTitle}
            actionIcon={campaignWidget.actionIcon}
            action={paginationOptions.action}
          />
        ))}
      </div>
    </div>
  );
};

export default CampaignCreate;
