<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" />;

/**
 * Created by mehmetyurtar on 29/06/2017.
 */
import React from "react";

class IconHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <path
          d="M8.70309091,7 L13.4054545,7 C14.1164,7 14.6994909,7.58592727 14.6994909,8.29687273 L14.6994909,13.0134182 C14.6994909,13.7243455 14.1164,14.3102909 13.4054545,14.3102909 L8.70309091,14.3102909 C7.99214545,14.3102909 7.40905455,13.7243636 7.40905455,13.0134182 L7.40905455,8.29687273 C7.40905455,7.58594545 7.99214545,7 8.70309091,7 Z M17.412,7 L22.1165455,7 C22.8260727,7 23.4091636,7.58592727 23.4091636,8.29687273 L23.4091636,13.0134182 C23.4091636,13.7243455 22.8260545,14.3102909 22.1165455,14.3102909 L17.412,14.3102909 C16.7024727,14.3102909 16.1179636,13.7257818 16.1179636,13.0134182 L16.1179636,8.29687273 C16.1179636,7.58523636 16.7024909,7 17.412,7 Z M8.70309091,15.6874545 L13.4054545,15.6874545 C14.1171091,15.6874545 14.6994909,16.2741091 14.6994909,16.9843273 L14.6994909,21.7023273 C14.6994909,22.4125455 14.1171091,22.9992 13.4054545,22.9992 L8.70309091,22.9992 C7.99143636,22.9992 7.40905455,22.4125455 7.40905455,21.7023273 L7.40905455,16.9843273 C7.40905455,16.2741091 7.99143636,15.6874545 8.70309091,15.6874545 Z M17.412,15.6874545 L22.1165455,15.6874545 C22.8274909,15.6874545 23.4091636,16.2741091 23.4091636,16.9843273 L23.4091636,21.7023273 C23.4091636,22.4125455 22.8274909,22.9992 22.1165455,22.9992 L17.412,22.9992 C16.7017636,22.9992 16.1179636,22.4132727 16.1179636,21.7023273 L16.1179636,16.9843273 C16.1179636,16.2734 16.7010545,15.6874545 17.412,15.6874545 Z"
          transform="translate(-7 -7)"
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default IconHistory;
