import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import './CreateSegmentGroup.scss';
import { addGroup, editGroupName } from '../../../../../modules/ds/ajax';
import { modalActions } from '../../../../../actions';
import { notification } from '../../../../../system/ui';
import { getCampaigns } from '../../../../../reducers/ds/extraReducers';

const initialState = {
  segmentName: '',
  validInput: {
    isValid: true,
    message: '',
  },
};

const SegmentNameReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_SEGMENT_NAME':
      return {
        ...state,
        segmentName: payload,
      };
    case 'SET_VALID_INPUT':
      return {
        ...state,
        validInput: payload,
      };
    default:
      return state;
  }
};

const CreateSegmentGroup = ({ mode, id, groupName }) => {
  const dispatch = useDispatch();
  const { closeModal } = modalActions;

  const [state, segmentDispatcher] = useReducer(
    SegmentNameReducer,
    initialState,
  );

  const trimmedName = state.segmentName.trim();

  const handleCancel = () => {
    closeModal();
  };

  const handleSubmit = () => {
    if (trimmedName.length === 0) {
      segmentDispatcher({
        type: 'SET_VALID_INPUT',
        payload: {
          isValid: false,
          message: 'This field cannot be empty',
        },
      });
    } else if (mode === 'edit') {
      editGroupName(id, state.segmentName)
        .then(() => {
          notification({
            title: 'Success',
          });
          dispatch(getCampaigns());
          closeModal();
        })
        .catch(() => {
          segmentDispatcher({
            type: 'SET_VALID_INPUT',
            payload: {
              isValid: false,
              message: `This group name ' ${state.segmentName} ' is already exist`,
            },
          });
        });
    } else if (mode === 'add') {
      addGroup(state.segmentName)
        .then(() => {
          notification({
            title: 'Success',
          });
          dispatch(getCampaigns());
          closeModal();
        })
        .catch(() => {
          segmentDispatcher({
            type: 'SET_VALID_INPUT',
            payload: {
              isValid: false,
              message: `This group name ' ${state.segmentName} ' is already exist`,
            },
          });
        });
    }
  };

  useEffect(() => {
    if (mode === 'edit') {
      segmentDispatcher({ type: 'SET_SEGMENT_NAME', payload: groupName });
    }
  }, [mode, groupName]);

  return (
    <div className='segment-modal-container'>
      <div className='segment-modal-input-container'>
        <input
          type='text'
          placeholder='Casual Segments'
          value={state.segmentName}
          onChange={e =>
            segmentDispatcher({
              type: 'SET_SEGMENT_NAME',
              payload: e.target.value,
            })
          }
        />
        {!state.validInput.isValid && (
          <p className='input-has-error'>{state.validInput.message}</p>
        )}
      </div>
      <div className='segment-modal-actions'>
        <button
          type='button'
          className='button gotest-action'
          onClick={() => handleCancel()}
        >
          Cancel
        </button>
        <button
          type='button'
          className='button tertiary-action golive-action'
          onClick={() => handleSubmit()}
        >
          {mode === 'add' ? 'Create' : 'Edit'}
        </button>
      </div>
    </div>
  );
};

CreateSegmentGroup.propTypes = {
  mode: PropTypes.string,
  id: PropTypes.string,
  groupName: PropTypes.string,
};

CreateSegmentGroup.defaultProps = {
  mode: 'add',
  id: '',
  groupName: '',
};

export default CreateSegmentGroup;
