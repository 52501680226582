const boilerplate = [
  { x: 'purchase:amount', y: 0, z: '' },
  { x: 'Basket Amount', y: 0, z: 'PC' },
  { x: 'Basket Items', y: 0, z: 'PC' },
  { x: 'Basket Quantity', y: 0, z: 'PC' },
  { x: 'Notification [sent]', y: 0, z: 'PC' },
  { x: 'Notification [shown]', y: 0, z: 'PC' },
  { x: 'Notification [clicked]', y: 0, z: 'PC' },
  { x: 'Purchase Amount', y: 0, z: 'PC' },
  { x: 'Purchased Items', y: 0, z: 'PC' },
  { x: 'Purchases', y: 0, z: 'PC' },
  { x: 'Basket Amount', y: 0, z: 'mobile' },
  { x: 'Basket Items', y: 0, z: 'mobile' },
  { x: 'Basket Quantity', y: 0, z: 'mobile' },
  { x: 'Notification [sent]', y: 0, z: 'mobile' },
  { x: 'Notification [shown]', y: 0, z: 'mobile' },
  { x: 'Notification [clicked]', y: 0, z: 'mobile' },
  { x: 'Purchase Amount', y: 0, z: 'mobile' },
  { x: 'Purchased Items', y: 0, z: 'mobile' },
  { x: 'Purchases', y: 0, z: 'mobile' },
  { x: 'Basket Amount', y: 0, z: 'tablet' },
  { x: 'Basket Items', y: 0, z: 'tablet' },
  { x: 'Basket Quantity', y: 0, z: 'tablet' },
  { x: 'Notification [sent]', y: 0, z: 'tablet' },
  { x: 'Notification [shown]', y: 0, z: 'tablet' },
  { x: 'Notification [clicked]', y: 0, z: 'tablet' },
  { x: 'Purchase Amount', y: 0, z: 'tablet' },
  { x: 'Purchased Items', y: 0, z: 'tablet' },
  { x: 'Purchases', y: 0, z: 'tablet' },
];

function reduceBasket(reminders) {
  return boilerplate.map(item => {
    const total = reminders.reduce((acc, values) => {
      values.map(obj => ({
        ...obj,
        ...(obj.x === 'purchase:amount' && { z: '' }),
      }));
      const nextReports = values.filter(i => i.x === item.x && i.z === item.z);
      let y = 0;
      nextReports.forEach(next => {
        if (next) {
          if (next.z) {
            item.z = next.z;
          }
          y = next.y;
          if (next.x === 'purchase:amount') {
            acc = y;
            y = 0;
          }
        }
        acc = acc + y;
      });
      return acc;
    }, 0);

    return {
      x: item.x,
      y: total,
      z: item.z,
    };
  });
}

export const reduceBasketByZ = reminders => {
  return boilerplate.map(item => {
    const total = reminders.reduce((acc, values) => {
      const next = values.find(i => i.x === item.x);
      let z = 0;
      if (next) {
        z = next.z;
      }
      return acc + z;
    }, 0);
    return {
      x: item.x,
      y: item.y,
      z: total,
    };
  });
};

export default reduceBasket;
