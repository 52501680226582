import { $http } from "../../system/ajax";
import { $http as $promiseHTTP } from "../../system/http";
import api from "../../system/api";
import { getAccountData, getApiKey } from "../auth/user";
import { getStartEndDate } from "../../system/date";

export const getPushCampaigns = (
  callback,
  options,
  predefinedApiKey,
  predefinedDataCenter
) => {
  let apiKey = predefinedApiKey ? predefinedApiKey : getApiKey();
  $http(api.getEndPoint("push/list") + "?apiKey=" + apiKey)
    .get(false, options)
    .then(response => {
      callback(response);
    });
};

export const getSampleContent = (instance, callback) => {
  let globalApiKey = getApiKey() !== undefined ? "?apiKey=" + getApiKey() : "";
  $http(
    (api.getEndPoint("push/sampleContent") + "/" + globalApiKey).replace(
      "{CAMPAIGN_ID}",
      instance
    )
  )
    .get(false)
    .then(response => {
      callback(response);
    });
};

export const getActivePushCampaigns = (
  callback,
  options,
  predefinedApiKey,
  predefinedDataCenter
) => {
  let apiKey = predefinedApiKey ? predefinedApiKey : getApiKey();
  $http(api.getEndPoint("push/activeList") + "?apiKey=" + apiKey)
    .get(false, options)
    .then(response => {
      callback(response);
    });
};

export const addCampaign = campaign => {
  campaign.accountId = getAccountData("accountId") || "";
  return $http(
    api.getRestEndPoint("push/campaign") + "?apiKey=" + getApiKey()
  ).post(campaign, { type: "json" });
};

export const getCampaign = instance => {
  return $http(api.getEndPoint("push/campaign") + "/" + instance).get({
    apiKey: getApiKey()
  });
};

export const editCampaign = campaign => {
  let globalApiKey = getApiKey() !== undefined ? "?apiKey=" + getApiKey() : "";
  campaign.accountId = getAccountData("accountId") || "";
  return $promiseHTTP(
    api.getRestEndPoint("push/campaign") +
      "/" +
      campaign.instanceId +
      globalApiKey
  ).put(campaign, { type: "json" });
};

export const getEmails = (
  callback,
  options,
  predefinedApiKey,
  predefinedDataCenter
) => {
  let apiKey = predefinedApiKey ? predefinedApiKey : getApiKey();
  $http(api.getRestEndPoint("emails/list") + "?apiKey=" + apiKey)
    .get(false, options)
    .then(response => {
      callback(response);
    });
};

export const getRangedPushReports = (
  instanceIds,
  boundaries,
  callback,
  options,
  predefinedApiKey
) => {
  let apiKey = predefinedApiKey ? predefinedApiKey : getApiKey();
  let opts;
  if (boundaries === "weekly") {
    opts = getStartEndDate("weekly");
  } else {
    opts = boundaries;
  }

  opts.interval = "total";
  opts.limit = 2000;

  opts = Object.assign({}, opts, options);
  let uri = "?";
  let argcount = 0;

  for (let key in opts) {
    if (opts.hasOwnProperty(key)) {
      if (argcount++) {
        uri += "&";
      }
      uri += encodeURIComponent(key) + "=" + encodeURIComponent(opts[key]);
    }
  }
  $http(api.getEndPoint("report/pushMultiReport") + uri + "&apiKey=" + apiKey)
    .post(instanceIds, { type: "json" })
    .then(response => {
      callback(response);
    });
};

export const getRangedPushReport = (instanceId, boundaries, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  const opts = boundaries;
  opts.interval = 'total';
  opts.instanceid = instanceId;
  opts.limit = 2000;

  $http(api.getEndPoint('report/pushSingleReport') + globalApiKey)
    .get(opts, { preventAbort: false })
    .then(response => {
      callback(response);
    });
};

export const getRangedPushReportInterval = (
  instance,
  boundaries,
  interval,
  callback,
) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  const opts = boundaries;

  opts.interval = interval;
  opts.instanceid = instance;
  opts.limit = 2000;

  $http(api.getRestEndPoint('report/pushSingleReport') + globalApiKey)
    .get(opts, { preventAbort: false })
    .then(response => {
      callback(response);
    });
};

export const moveCampaignToTest = instanceId => {
  return $http(
    api.getRestEndPoint("push/campaign") +
      "/" +
      instanceId +
      "/TEST?apiKey=" +
      getApiKey()
  ).put();
};

export const moveCampaignToLive = instanceId => {
  return $http(
    `${api.getRestEndPoint(
      'push/campaign',
    )}/${instanceId}/ACTIVE?apiKey=${getApiKey()}`,
  ).put();
};

export const moveCampaignToArchive = instanceId => {
  return $http(
    api.getRestEndPoint("push/campaign") +
      "/" +
      instanceId +
      "/PASSIVE?apiKey=" +
      getApiKey()
  ).put();
};

export const getPushCampaignHistory = (instanceId, callback) => {
  $http(
    api.getEndPoint("push/campaign") +
      "/" +
      instanceId +
      "?withOps=true&apiKey=" +
      getApiKey()
  )
    .get(false)
    .then(response => {
      callback(response);
    });
};

export const getPermissionReport = (callback, boundaries) => {
  let opts = boundaries || {};

  opts.apiKey = getApiKey();
  $http(api.getEndPoint("push/permissionreport"))
    .get(opts, { preventAbort: false })
    .then(response => {
      callback(response);
    });
};

export const getActiveUsers = (callback, boundaries, predefinedApiKey) => {
  let apiKey = predefinedApiKey ? predefinedApiKey : getApiKey();
  $http(api.getEndPoint("push/activeUsers") + "?apiKey=" + apiKey)
    .get(boundaries)
    .then(response => {
      callback(response);
    });
};
