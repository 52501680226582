export const filterOptions = [
  {
    value: 'userId',
    label: 'User ID',
  },
  {
    value: 'segments',
    label: 'Segments',
  },
  {
    value: 'smartSegments',
    label: 'Smart Segments',
  },
  {
    value: 'sessionId',
    label: 'Session ID',
  },
  {
    value: 'firstSessionId',
    label: 'First Session ID',
  },
  {
    value: 'lastUpdateInMillis',
    label: 'Last Update In Millis',
  },
  {
    value: 'scenarioGroups',
    label: 'Scenario Groups',
  },
  {
    value: 'mode',
    label: 'Mode',
  },
  {
    value: 'freqConstraints',
    label: 'Freq Constraints',
  },
  {
    value: 'notifications',
    label: 'Notifications',
  },
  {
    value: 'language',
    label: 'Language',
  },
  {
    value: 'segmentifyed',
    label: 'Segmentifyed',
  },
  {
    value: 'dotId',
    label: 'Dot ID',
  },
  {
    value: 'wishlistProducts',
    label: 'Wishlist Products',
  },
  {
    value: 'wishlistVisitedProducts',
    label: 'Wishlist Visited Products',
  },
  {
    value: 'external',
    label: 'External',
  },
  {
    value: 'personaQuizAnsweredCampaigns',
    label: 'Persona Quiz Answered Campaigns',
  },
  {
    value: 'firstVisitTime',
    label: 'First Visit Time',
  },
  {
    value: 'lastVisitTime',
    label: 'Last Visit Time',
  },
  {
    value: 'stats',
    label: 'Stats',
  },
];
