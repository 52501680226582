import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.replace('px', '')}px`;
};

const ReUpload = ({ width, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 20 14'
      className='re-upload-icon'
      fill='none'
    >
      <path
        d='M7.91675 0C4.925 0 2.5 2.3883 2.5 5.35299C2.5 5.35299 2.4995 5.34805 2.5 5.43007C1.0135 6.17125 0 7.65853 0 9.47045C0 11.941 2.052 14 4.58325 14H15.8333C18.1343 14 20 12.1058 20 9.8823C20 8.15289 18.9193 6.65523 17.3958 5.99608C17.455 5.83154 17.5 5.5998 17.5 5.35299C17.5 3.70586 16.1943 2.47057 14.5833 2.47057C13.9183 2.47057 13.3033 2.62498 12.8125 3.03683C11.9432 1.22491 10.0775 0 7.91675 0ZM10 4.11771C11.15 4.11771 12.187 4.5088 12.9427 5.24997L14.1667 4.11771V7.41172H10.8335L12.0312 6.17643C11.5173 5.68232 10.8077 5.35299 10.0262 5.35299C8.73525 5.35299 7.6625 6.2587 7.29175 7.41172H5.91175C6.1034 6.48055 6.61533 5.64364 7.36058 5.04318C8.10583 4.44271 9.03844 4.1157 10 4.11771ZM5.83325 9.05885H9.16675L7.96875 10.1911C8.4825 10.6852 9.19225 11.0148 9.97375 11.0148C11.2648 11.0148 12.3375 10.2119 12.7083 9.05885H14.0885C13.7002 10.8705 12.0135 12.3529 10 12.3529C8.85 12.3529 7.813 11.8588 7.0575 11.1176L5.83325 12.3529V9.05885Z'
        fill={color}
      />
    </svg>
  );
};

ReUpload.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

ReUpload.defaultProps = {
  width: '20px',
  height: '14px',
  color: '#47607B',
};

export default ReUpload;
