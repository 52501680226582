import { useEffect } from 'react';

import { Privileges } from '../constants';

/**
 * Custom hook for identifying a user.
 * @param {Object} options - The options for identifying the user.
 * @param {string} options.displayName - The display name of the user.
 * @param {Array<string>} options.privileges - The privileges of the user.
 * @param {string} options.username - The username of the user.
 * @param {Object} options.rest - Additional properties for identifying the user.
 */
export const userIdentify = ({
  displayName,
  privileges,
  username,
  ...rest
}) => {
  useEffect(() => {
    const isAccountUserOrAdmin = Object.keys(Privileges).some(privilege =>
      privileges.includes(Privileges[privilege]),
    );

    const isDisplayNameValid = displayName !== '' && displayName !== null;

    if (isDisplayNameValid && !isAccountUserOrAdmin) {
      let products = {};

      Object.keys(rest.account.features).forEach(feature => {
        products = {
          ...products,
          [feature]: rest.account.features[feature].status === 'ACTIVE',
        };
      });

      /**
       * Identify the user using the UserGuiding library.
       * @param {string} username - The username of the user.
       * @param {Object} data - The data to identify the user.
       * @param {Object} data.user - The user information.
       * @param {string} data.user.fullName - The full name of the user.
       * @param {string} data.user.role - The role of the user.
       * @param {string} data.user.language - The language of the user.
       * @param {Object} data.company - The company information.
       * @param {string} data.company.id - The ID of the company.
       * @param {string} data.company.name - The name of the company.
       * @param {string} data.company.type - The type of the company.
       * @param {string} data.company.industry - The industry of the company.
       * @param {string} data.company.country - The country of the company.
       * @param {string} data.company.accountManager - The account manager of the company.
       * @param {string} data.company.salesManager - The sales manager of the company.
       * @param {string} data.company.agentUser - The agent user of the company.
       * @param {string} data.company.currency - The currency of the company.
       * @param {string} data.company.langugage - The language of the company.
       * @param {string} data.company.tier - The tier of the company.
       * @param {Object} data.company.products - The products of the company.
       */
      window.userGuiding.identify(username, {
        user: {
          fullName: displayName,
          role: rest.userRole,
          language: rest.language,
        },
        company: {
          id: rest.account.accountId,
          name: rest.account.domain,
          type: rest.account.type,
          industry: rest.account.industry,
          country: rest.account.country,
          accountManager: rest.account.ownership.accountManager,
          salesManager: rest.account.ownership.salesManager,
          agentUser: rest.account.ownership.agentUser,
          currency: rest.account.mainCurrency.currency,
          language: rest.account.mainLanguage,
          tier: rest.account.tier,
          products,
        },
      });
    }
  }, [displayName, privileges]);
};
