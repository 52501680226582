import React from "react";
import Icons from "../components/icons";
import { connect } from "react-redux";

class ScrollUpButtonAnalytics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButton: false,
      intervalId: 0
    };

    this.scrollToTop = this.scrollToTop.bind(this);
  }

  scrollToTop() {
    let element = document.getElementsByClassName("sticky-table-y-wrapper")[0];
    if (element) {
      let index = 0;
      let animationFn = function() {
        element.scrollTop = element.scrollTop - element.scrollTop / 10;
        if (element.scrollTop > 0) {
          setTimeout(animationFn, 50 - 2 * index++);
        }
      };
      animationFn();
    }
  }

  render() {
    return (
      <div
        className="scroll-up"
        onClick={() => {
          this.scrollToTop();
        }}
      >
        <Icons name="scroll" width="20" height="20" />
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  scrollUpButton: store.ui.scrollUpButton
});

export default connect(MapStatesToProps)(ScrollUpButtonAnalytics);
