/**
 * @author Bilal Cinarli
 */

import { createAction } from '../system/store';

export const addCriteria = criteria => {
  createAction('ADD_WIZARD_CRITERIA', { criteria });
};

export const addNoResultCriteria = noResultCriteria => {
  createAction('ADD_NO_RESULT_WIZARD_CRITERIA', { noResultCriteria });
};

export const addFormField = (version, field) => {
  createAction('ADD_FORM_FIELD', { version, field });
};

export const removeFormField = (version, field) => {
  createAction('REMOVE_FORM_FIELD', { version, field });
};

export const removeCriteria = criteria => {
  createAction('REMOVE_WIZARD_CRITERIA', { criteria });
};

export const removeNoResultCriteria = noResultCriteria => {
  createAction('REMOVE_NO_RESULT_WIZARD_CRITERIA', { noResultCriteria });
};

export const addExclusion = excludes => {
  createAction('ADD_WIZARD_EXCLUSION', { excludes });
};

export const addNoResultExclusion = noResultExcludes => {
  createAction('ADD_NO_RESULT_WIZARD_EXCLUSION', { noResultExcludes });
};

export const removeExclusion = excludes => {
  createAction('REMOVE_WIZARD_EXCLUSION', { excludes });
};

export const removeNoResultExclusion = noResultExcludes => {
  createAction('REMOVE_NO_RESULT_WIZARD_EXCLUSION', { noResultExcludes });
};

export const addInclusion = includes => {
  createAction('ADD_WIZARD_INCLUSION', { includes });
};

export const addNoResultInclusion = noResultIncludes => {
  createAction('ADD_NO_RESULT_WIZARD_INCLUSION', { noResultIncludes });
};

export const removeInclusion = includes => {
  createAction('REMOVE_WIZARD_INCLUSION', { includes });
};

export const removeNoResultInclusion = noResultIncludes => {
  createAction('REMOVE_NO_RESULT_WIZARD_INCLUSION', { noResultIncludes });
};

export const addTemplates = (html, prejs, postjs, css) => {
  createAction('DUPLICATE_WIZARD_TEMPLATES', { html, prejs, postjs, css });
};

export const showStyles = () => createAction('SHOW_STYLES');
export const hideStyles = () => createAction('HIDE_STYLES');

export const showPosition = () => createAction('SHOW_POSITION');
export const hidePosition = () => createAction('HIDE_POSITION');

export const showTiming = () => createAction('SHOW_TIMING');
export const hideTiming = () => createAction('HIDE_TIMING');

export const showAudience = () => createAction('SHOW_AUDIENCE');
export const hideAudience = () => createAction('HIDE_AUDIENCE');

export const showScheduling = () => createAction('SHOW_SCHEDULING');
export const hideScheduling = () => createAction('HIDE_SCHEDULING');

export const showAdvancedConfig = () => createAction('SHOW_ADVANCED_CONFIG');
export const hideAdvancedConfig = () => createAction('HIDE_ADVANCED_CONFIG');

export const wizardAdd = () => createAction('WIZARD_TYPE_ADD');
export const wizardEdit = () => createAction('WIZARD_TYPE_EDIT');
export const wizardReset = () => createAction('RESET_WIZARD');

export const saveReset = () => createAction('SAVE_RESET');
export const saveTest = () => createAction('SAVE_TEST');
export const saveLive = () => createAction('SAVE_LIVE');

export const updateWizardForm = (name, value) => {
  return {
    type: 'UPDATE_WIZARD_FORM',
    payload: {
      name,
      value,
    },
  };
};

export const initWizardValues = values => {
  return {
    type: 'INIT_WIZARD_VALUES',
    payload: values,
  };
};

export default {
  addCriteria,
  removeCriteria,
  addExclusion,
  removeExclusion,
  addInclusion,
  removeInclusion,
  addTemplates,
  showStyles,
  hideStyles,
  showPosition,
  hidePosition,
  showTiming,
  hideTiming,
  showAudience,
  hideAudience,
  showScheduling,
  hideScheduling,
  showAdvancedConfig,
  hideAdvancedConfig,
  wizardAdd,
  wizardEdit,
  wizardReset,
  saveReset,
  saveTest,
  saveLive,
  addFormField,
  removeFormField,
  updateWizardForm,
  initWizardValues,
  addNoResultCriteria,
  addNoResultExclusion,
  addNoResultInclusion,
  removeNoResultCriteria,
  removeNoResultExclusion,
  removeNoResultInclusion,
};
