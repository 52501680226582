import {
  initialScratchDetails,
  initialWOFDetails,
} from '../../constants/constants';
import { getInitialWOFPossibleReward } from '../../utils/utils';

/**
 * @name gamificationReducer
 * @summary Reducer for gamification
 * @param {any} state
 * @param {any[]} action
 */

export const GamificationReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_GAMIFICATION_LIST': {
      return {
        ...state,
        gamificationCampaignList: payload,
      };
    }
    case 'SET_ACTIVE_WOF_DETAILS':
      return {
        ...state,
        activeWOFDetails: payload,
      };
    case 'EDIT_ACTIVE_WOF_DETAILS': {
      return {
        ...state,
        activeWOFDetails: {
          ...state.activeWOFDetails,
          ...payload,
        },
      };
    }
    case 'RESET_ACTIVE_WOF_DETAILS': {
      const newPossibleRewards = new Array(4).fill(0).map((_, index) => {
        return getInitialWOFPossibleReward(index);
      });
      const newDetails = {
        ...initialWOFDetails,
        possibleRewards: newPossibleRewards,
      };
      return {
        ...state,
        activeWOFDetails: { ...newDetails },
      };
    }
    case 'SET_ACTIVE_SCRATCH_DETAILS':
      return {
        ...state,
        activeScratchDetails: {
          ...payload,
          overlay: payload.overlay === 'true',
        },
      };
    case 'EDIT_ACTIVE_SCRATCH_DETAILS':
      return {
        ...state,
        activeScratchDetails: {
          ...state.activeScratchDetails,
          ...payload,
        },
      };
    case 'RESET_ACTIVE_SCRATCH_DETAILS': {
      const newPossibleRewards = new Array(1).fill(0).map((_, index) => {
        return getInitialWOFPossibleReward(index);
      });
      const newDetails = {
        ...initialScratchDetails,
        possibleRewards: newPossibleRewards,
      };
      return {
        ...state,
        activeScratchDetails: { ...newDetails },
      };
    }
    default:
      return state;
  }
};
