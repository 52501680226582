import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';

import { t } from '../../system/ui';

import { filterActions } from '../../actions';

import Icon from '../icon';

class AnalyticsPager extends React.Component {
  constructor(props) {
    super(props);

    this.pageSize = this.pageSize.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.gotoPage = this.gotoPage.bind(this);
    this.setPageSize = this.setPageSize.bind(this);
    this.state = {
      customPageSize: 0,
    };
  }

  componentDidMount() {
    this.setPageSize();
  }

  setPageSize() {
    this.setState({
      customPageSize: this.props.isBannerifyFilter ? 250 : 0,
    });
  }

  pageSize(selectedValue) {
    let newSize = selectedValue.value * 1;

    filterActions.changePageSize(newSize);
    filterActions.showButtons();
  }

  prevPage() {
    let totalPages = Math.ceil(
        this.props.count / (this.state.customPageSize || this.props.pageSize),
      ),
      pageNumber = this.props.pageNumber;

    if (totalPages > 1 && pageNumber > 1) {
      filterActions.changePageNumber(pageNumber - 1);
      filterActions.showButtons();
    }
  }

  nextPage() {
    let totalPages = Math.ceil(
        this.props.count / (this.state.customPageSize || this.props.pageSize),
      ),
      pageNumber = this.props.pageNumber;

    if (totalPages > 1 && pageNumber < totalPages) {
      filterActions.changePageNumber(pageNumber + 1);
      filterActions.showButtons();
    }
  }

  gotoPage(selectedValue) {
    let totalPages = Math.ceil(
        this.props.count / (this.state.customPageSize || this.props.pageSize),
      ),
      pageNumber = selectedValue.value;

    if (pageNumber > 0 && pageNumber <= totalPages) {
      filterActions.changePageNumber(pageNumber);
      filterActions.showButtons();
    }
  }

  render() {
    let totalPages = Math.ceil(
      this.props.count / (this.state.customPageSize || this.props.pageSize),
    );
    let pageOptions = [];
    for (let i = 1; i <= totalPages; i++) {
      pageOptions.push({ value: i, label: i });
    }
    let itemKeyword = t('items');
    if (this.props.analyticsType === 'sales') {
      itemKeyword = t('sales');
    } else if (this.props.analyticsType === 'categories') {
      itemKeyword = t('categories');
    } else if (this.props.analyticsType === 'brands') {
      itemKeyword = t('brands');
    } else if (this.props.analyticsType === 'group') {
      itemKeyword = t('Group');
    }
    const pageNumberOptions = this.props.pageNumberOptions.map(
      pageNumberOption => ({
        value: pageNumberOption + '',
        label: pageNumberOption + ' ' + itemKeyword,
      }),
    );
    return (
      <div className='pager-analytics'>
        <div className='pager-size move-to-start'>
          {!this.props.isBannerifyFilter && (
            <Select
              value={(
                this.state.customPageSize || this.props.pageSize
              ).toString()}
              options={pageNumberOptions}
              name='pageSize'
              clearable={false}
              onChange={this.pageSize}
              className='select--pagenumber'
              searchable={false}
            />
          )}
        </div>

        <div
          className='pager-pagination move-to-end'
          style={
            this.props.analyticsType === 'group'
              ? { backgroundColor: '#F8F8F8' }
              : { backgroundColor: '#fff' }
          }
        >
          <a
            className='pager-pagination-item pager-pagination-prev'
            onClick={this.prevPage}
          >
            <Icon name='chevron-left' />
          </a>
          <Select
            value={this.props.pageNumber}
            options={pageOptions}
            name='pagenumber'
            clearable={false}
            onChange={this.gotoPage}
            className='select--pagesize'
            inputProps={{ maxLength: totalPages.toString().length }}
            noResultsText=''
          />
          <a
            className='pager-pagination-item pager-pagination-next'
            onClick={this.nextPage}
          >
            <Icon name='chevron-right' />
          </a>
          <span className='item-label' style={{ minWidth: '50px' }}>
            {' '}
            / {totalPages}
          </span>
        </div>
      </div>
    );
  }
}

const mapStatsToProps = state => ({
  pageSize: state.filters.pageSize,
  pageNumber: state.filters.pageNumber,
  count: state.filters.count,
});

AnalyticsPager.defaultProps = {
  pageNumberOptions: [100, 200, 300, 500, 1000],
};

export default connect(mapStatsToProps)(AnalyticsPager);
