import { getEndPoint } from '../../system/api';
import { $http } from '../../system/ajax';

import { getApiKey } from '../auth/user';

export const getRbsPageCategories = callback => {
  const url = `${getEndPoint('rest/search/rbs/page')}/?apiKey=${getApiKey()}`;

  return $http(url)
    .get({}, { preventAbort: false })
    .then(callback);
};
