import React from 'react';
import styles from './SFYChildWrapper.module.scss';

/**
 * @name SFYChildWrapper component
 * @description SFYChildWrapper component to wrap all the components
 * @param {string} flexColGap - flexColGap: flexCol or flexGap-1 etc.
 * @param {JSX.Element | JSX.Element[]} children
 * @returns {JSX.Element}
 * @constructor
 */

const SFYChildWrapper = ({ children, type }) => {
  return <section className={styles[type]}>{children}</section>;
};

export default SFYChildWrapper;
