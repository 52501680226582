/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions, switchUserActions } from '../../../../actions';

import { getInsightEndpoint } from '../../../../modules/insights/ajax';
import { insightsQuery } from '../../../../modules/insights/data';
import getInsightsFields from '../../../../modules/transform/insightsFieldsTransform';
import { getInsights } from '../../../../reducers/managed-services/extraReducers';
import {
  insightsTrendifyCardTypes,
  insightsTrendifyShowTypes,
} from '../../enums';
import { InsightTrendifyItems } from '../../msp-static-data';

import SelectUser from '../../../../components/admin/select-user';
import Export from '../../../../components/export';
import Products from '../../../../components/insights/products';
import setHighchartsDefaults from '../../../../modules/highcharts.global';
import RightArrowIcon from '../../icons/RightArrowIcon';
import TrendifyLogoIcon from '../../icons/TrendifyLogoIcon';

const switchAccount = (accountList, userList, account) => {
  const accountUsers = userList;
  const switchedAccount = accountUsers[account.accountId][0].user;

  let allSwitchableAccounts = [];
  let switchableAccountsWithDetails = [];

  accountUsers[account.accountId].forEach(user => {
    if (user.user.switchableAccounts) {
      allSwitchableAccounts = allSwitchableAccounts.concat(
        user.user.switchableAccounts,
      );
    }
  });

  switchableAccountsWithDetails = accountList.filter(accountListItem =>
    allSwitchableAccounts.includes(accountListItem.accountId),
  );

  if (accountUsers[account.accountId].length > 1) {
    const content = () => (
      <SelectUser
        account={account}
        users={accountUsers[account.accountId]}
        accounts={accountList}
        switchableAccountsWithDetails={switchableAccountsWithDetails}
      />
    );
    modalActions.openModal({
      title: 'Select User',
      content,
    });
  } else {
    const selectedSwitchAccount = {
      ...switchedAccount,
      account,
      switchableAccountsWithDetails,
    };

    switchUserActions.updateSwitchUser(selectedSwitchAccount);
    setHighchartsDefaults(selectedSwitchAccount);
  }
};

const OpenAccountListModal = (accountList, userList) => {
  if (!accountList || !accountList.length) {
    return;
  }

  const accountListItem = item => {
    return (
      <div
        className='account-list-item account-list-row'
        key={item.accountId}
        onClick={() => switchAccount(accountList, userList, item)}
      >
        <div className='account-list-row-part'>
          <span className='account-list-text account-name'>{item.domain}</span>
          <span className='account-list-text account-id'>{item.accountId}</span>
        </div>
        <div className='account-list-row-part'>
          <span className='account-list-text contact-name'>
            {item.contact?.person}
          </span>
          <span className='account-list-text contact-email'>
            {item.contact?.email}
          </span>
        </div>
        <div className='account-list-row-part'>
          <span className='account-list-text responsible-email'>
            {item.type === 'POC' || item.type === 'PRE_POC'
              ? item.ownership?.onboardingManager
              : item.ownership?.accountManager}
          </span>
        </div>
        <div className='account-list-arrow'>
          <RightArrowIcon />
        </div>
      </div>
    );
  };

  modalActions.openModal({
    title: 'Accounts',
    content: () => (
      <div className='insights-account-list-modal'>
        <div className='insights-account-list-modal-header'>
          Please select an account to track the performances of products and
          special insights in Trendify.
        </div>
        <div className='insights-account-list-modal-body'>
          <div className='account-list-header'>
            <div className='account-list-row'>
              <div className='account-list-row-part'>
                <span className='account-list-text'>Account</span>
              </div>
              <div className='account-list-row-part flex-center'>
                <span className='account-list-text'>Contact</span>
              </div>
              <div className='account-list-row-part'>
                <span className='account-list-text'>Account Responsible</span>
              </div>
            </div>
          </div>
          <div className='account-list-column'>
            {accountList.map(item => accountListItem(item))}
          </div>
        </div>
      </div>
    ),
    className: 'msp-account-list-modal',
  });
};

const redirectToInsightPage = (selectedUser, selectedAccount) => {
  const switchedAccount = {
    ...selectedUser,
    account: selectedAccount,
    switchableAccountsWithDetails: [selectedAccount],
  };

  switchUserActions.updateSwitchUser(switchedAccount);
  setHighchartsDefaults(switchedAccount);
};

const exportInsightData = (
  insightId,
  insightName,
  insightQuery,
  insightExportEndPoint,
  switchUser,
  exportProductCount,
) => {
  const query = {
    ...insightQuery,
    items: exportProductCount,
  };
  query.export = true;
  query.export_mail = true;

  const name = `${insightName.replace(/\s/g, '-')}-Report`;

  const content = () => (
    <Export
      name={name}
      query={query}
      insightsId={insightId}
      endpoint={insightExportEndPoint}
      switchUser={switchUser}
      isMsp
    />
  );

  modalActions.openModal({ title: 'Export Products', content });
};

const TrendifyContentCard = ({
  title,
  text,
  icon,
  type,
  accountList,
  userList,
}) => {
  const textSplitter = () => {
    if (!text.includes('|||')) {
      return text;
    }

    const splittedText = text.split('|||');

    return (
      <>
        {splittedText[0]}
        <span className='bolder-text'> {splittedText[1]} </span>
        {splittedText[2]}
      </>
    );
  };

  return (
    <div
      className={`welcome-card clickable ${type}`}
      onClick={() => {
        OpenAccountListModal(accountList, userList);
      }}
    >
      {type === insightsTrendifyCardTypes.HALF_WIDTH && (
        <div className='card-content-title'>{title}</div>
      )}
      <div className='card-row'>
        <div className='card-content'>
          {type === insightsTrendifyCardTypes.FULL_WIDTH && (
            <div className='card-content-title'>{title}</div>
          )}
          <div className='card-content-text-field'>{textSplitter()}</div>
        </div>
        <div className='card-icon'>{icon}</div>
      </div>
      <div className='card-trendify-logo'>
        <TrendifyLogoIcon />
      </div>
    </div>
  );
};

TrendifyContentCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.values(insightsTrendifyCardTypes)).isRequired,
  accountList: PropTypes.array.isRequired,
  userList: PropTypes.object.isRequired,
};

const TrendifyInsightDataCard = ({
  insight,
  query,
  isWeekSelect,
  endpoint,
  exportEndpoint,
  exportProductCount,
}) => {
  const selectedAccount = useSelector(
    state => state.managedServices.accountList.data[0],
  );
  const userList = useSelector(state => state.managedServices.userList.data);

  const getSelectedUser = () => {
    const selectedUser = userList[selectedAccount.accountId].find(
      user => user.isOwner,
    );
    return selectedUser.user;
  };

  return (
    <div
      className={`welcome-card clickable insight-data ${insightsTrendifyCardTypes.FULL_WIDTH}`}
    >
      <div className='card-row'>
        <div className='card-content'>
          <div className='card-content-title'>
            Account:{' '}
            <span className='insight-account-name'>
              {selectedAccount.domain}
            </span>
          </div>
          <Products
            {...insight}
            query={query}
            isWeekSelect={isWeekSelect}
            endpoint={endpoint}
            isMsp
            switchUser={getSelectedUser().username}
          />
        </div>
      </div>
      <div
        className='click-field'
        onClick={() => {
          redirectToInsightPage(getSelectedUser(), selectedAccount);
        }}
      />
      <div
        className='insight-redirect-button'
        onClick={() => {
          redirectToInsightPage(getSelectedUser(), selectedAccount);
        }}
      >
        <i className='icon-trendify' role='presentation' />
        <span>Go to Trendify Insights for more</span>
      </div>
      <a
        className='export-button'
        onClick={() => {
          exportInsightData(
            insight.id,
            insight.name,
            query,
            exportEndpoint,
            getSelectedUser().username,
            exportProductCount,
          );
        }}
      >
        <i className='icon-pdf' /> Export
      </a>
    </div>
  );
};

TrendifyInsightDataCard.propTypes = {
  insight: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  isWeekSelect: PropTypes.bool.isRequired,
  endpoint: PropTypes.string.isRequired,
  exportEndpoint: PropTypes.string.isRequired,
  exportProductCount: PropTypes.number.isRequired,
};

const InsightsTrendify = () => {
  const dispatch = useDispatch();
  const accountList = useSelector(
    state => state.managedServices.accountList.data,
  );
  const userList = useSelector(state => state.managedServices.userList.data);
  const insights = useSelector(state => state.managedServices.insights);
  const [trendifyShowType, setTrendifyShowType] = useState('');
  const [accountApiKey, setAccountApiKey] = useState('');
  const [insightsQueryObj, setInsightsQueryObj] = useState({
    ...insightsQuery,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      accountList.length === 1 &&
      accountList[0]?.features?.trendify?.status !== 'PASSIVE'
    ) {
      setTrendifyShowType(insightsTrendifyShowTypes.REAL_DATA);
      dispatch(getInsights());
    } else if (accountList.length >= 1 && Object.keys(userList).length > 0) {
      setTrendifyShowType(insightsTrendifyShowTypes.STATIC_CONTENT);
    }
  }, [accountList, userList]);

  useEffect(() => {
    if (
      accountList.length > 0 &&
      Object.keys(userList).length > 0 &&
      insights.loaded
    ) {
      setInsightsQueryObj({
        ...insightsQueryObj,
        items: accountList[0]?.insightsProductCount,
      });
      setAccountApiKey(accountList[0]?.apiKey);
      setIsLoading(false);
    }
  }, [insights, userList, accountList]);

  return (
    <div className='welcome-column column-wrap trendify-field'>
      {trendifyShowType === insightsTrendifyShowTypes.STATIC_CONTENT &&
        InsightTrendifyItems.map(item => (
          <TrendifyContentCard
            key={item.title}
            title={item.title}
            text={item.text}
            icon={<item.icon />}
            type={item.type}
            accountList={accountList}
            userList={userList}
          />
        ))}

      {trendifyShowType === insightsTrendifyShowTypes.REAL_DATA &&
        insights.loaded &&
        !isLoading &&
        insights.data.map(insight => (
          <TrendifyInsightDataCard
            insight={insight}
            query={insightsQueryObj}
            key={insight.id}
            isWeekSelect={getInsightsFields(insight.name).includes('date')}
            endpoint={`${getInsightEndpoint() +
              insight.id}?apiKey=${accountApiKey}`}
            exportEndpoint={`${getInsightEndpoint()}export/${
              insight.id
            }?apiKey=${accountApiKey}`}
            exportProductCount={accountList[0]?.insightsProductExportCount}
          />
        ))}
    </div>
  );
};

export default InsightsTrendify;
