import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const PlusIcon = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 18 18'
      fill='none'
    >
      <path d='M8.99645 0C9.38695 0 9.70978 0.290033 9.7609 0.666517L9.76799 0.771182L9.76923 8.22758H17.2287C17.6546 8.22758 18 8.57294 18 8.99892C18 9.38942 17.7098 9.71215 17.3334 9.76326L17.2287 9.77026H9.76923L9.77128 17.2256C9.77139 17.6516 9.42614 17.9971 9.00015 17.9971C8.60965 17.9971 8.28682 17.7071 8.23571 17.3306L8.22861 17.2259L8.22655 9.77026H0.771336C0.345332 9.77026 0 9.4249 0 8.99892C0 8.60842 0.290177 8.28569 0.666671 8.23458L0.771336 8.22758H8.22655L8.22532 0.77149C8.22522 0.345497 8.57047 0 8.99645 0Z' />
    </svg>
  );
};

PlusIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

PlusIcon.defaultProps = {
  width: '18px',
  height: '18px',
};

export default PlusIcon;
