import React from 'react';
import { eventsConstants } from '../../../constants/events';
import classes from './EventContent.module.scss';

/**
 * @description - EventContent component
 * @returns {JSX.Element} - EventContent component
 */

export const EventContent = () => {
  return (
    <>
      {Object.keys(eventsConstants).map(key => {
        return (
          <div key={key} className={classes['event-content']}>
            <div className={classes['event-content__title']}>
              <span>{key.replace(/_/g, ' ')}</span>
            </div>
            <div className={classes['event-content__list']}>
              {eventsConstants[key].map(item => {
                return (
                  <div
                    key={item.eventName}
                    className={classes['event-content__list-item']}
                  >
                    <span className={classes['event-content__list-item__name']}>
                      {item.eventName.replace(/_/g, ' ')}
                    </span>
                    <span
                      className={classes['event-content__list-item__data']}
                      onClick={() => {
                        navigator.clipboard.writeText(item.eventData);
                      }}
                    >
                      &rarr; {item.eventData}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};
