import React from 'react';
import classes from './UserCardStats.module.scss';
import Icons from '../../../icons';
import { t } from '../../../../system/ui';
import { DateFormatter } from '../../utils';

const UserCardStats = ({ stats, enumUserInformationType }) => {
  const { totalVisit, ...rest } = stats;

  const statsList = Object.keys(rest).map((key) => {
    return (
      <div className={classes['ucs-item']} key={key}>
        <Icons name={enumUserInformationType[key]?.icon} />
        <div className={classes['ucs-item-text']}>
          <div className={classes['ucs-item-text-key']}>
            {enumUserInformationType[key]?.name}
          </div>
          <div className={classes['ucs-item-text-value']}>
            {DateFormatter(stats[key], 'DD-MMM-YY', ',') || '-'}
          </div>
        </div>
      </div>
    );
  });

  return (
    <section className={classes['ucs-container']}>
      <div className={classes['ucs-header']}>
        <div className={classes['ucs-header-milestones']}>
          {t('Milestones')}
        </div>
        <div className={classes['ucs-header-totalVisit']}>
          {t('Total Visit')}: {totalVisit || '-'}
        </div>
      </div>
      <div className={classes['ucs-list-container']}>{statsList}</div>
    </section>
  );
};

export default UserCardStats;
