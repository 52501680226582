/* eslint-disable react/require-default-props */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IconAllDevices from './allDevices';
import IconYandex from './yandex';
import IconUCBrowser from './ucBrowser';
import IconSamsung from './samsung';
import IconPc from './pc';
import IconPie from './pie';
import IconIos from './ios';
import IconGear from './gear';
import IconWand from './wand';
import IconPush from './push';
import IconCheck from './check';
import IconCheckCircle from './checkCircle';
import IconCheckGray from './checkGray';
import IconCross from './cross';
import IconEmail from './email';
import IconClock from './clock';
import IconPopup from './popup';
import IconSocialProof from './social-proof';
import IconGamification from './gamification';
import IconWheelOfFortune from './WheelOfFortune';
import IconScratch from './Scratch';
import IconGoActive from './goActive';
import IconMobile from './mobile';
import IconTarget from './target';
import IconArchive from './archive';
import IconAndroid from './android';
import IconPalette from './palette';
import IconUserAdd from './user-add';
import IconUserManagement from './user-management';
import IconPosition from './position';
import IconCalendar from './calendar';
import IconDashboard from './dashboard';
import IconDuplicate from './duplicate';
import IconNotification from './notification';
import IconScheduled from './scheduled';
import IconCompleted from './completed';
import IconCustomExclude from './excludecustom';
import IconLabelExclude from './excludelabel';
import IconGenderExclude from './excludegender';
import IconSales from './sales';
import IconUpArrow from './uparrow';
import IconDownArrow from './downarrow';
import IconHistory from './history';
import IconCompare from './compare';
import IconFieldText from './fieldText';
import IconFieldCheckbox from './fieldCheckbox';
import IconFieldTextArea from './fieldTextArea';
import IconFieldRadio from './fieldRadio';
import IconImageHolder from './imageholder';
import IconFormSurvey from './formsurvey';
import IconLike from './like';
import IconLock from './lock';
import IconGraph from './graph';
import IconIncrease from './increase';
import IconDecrease from './decrease';
import IconRemoveUser from './remove-user';
import IconToggleUser from './toggle-user';
import IconStats from './stats';
import IconPrevious from './previous';
import IconBrowser from './browser';
import IconBurger from './burger';
import IconReferral from './referral';
import IconSocial from './social';
import IconListing from './listing';
import IconAffiliate from './affiliate';
import IconAdwords from './adwords';
import IconRetargeting from './retargeting';
import IconChevronDown from './chevron-down';
import IconChevronUp from './chevron-up';
import IconDashboardTfy from './dashboard-tfy';
import IconFilter from './filter';
import IconFeedback from './feedback';
import IconBrain from './brain';
import IconAsteriks from './asteriks';
import IconCheckboxEmpty from './checkbox-empty';
import IconCheckboxCircleSelected from './checkbox-circle-selected';
import IconCheckboxCircleEmpty from './checkbox-circle-empty';
import IconCheckboxSelected from './checkbox-selected';
import IconCheckboxRoundTick from './check-round-tick';
import IconHeart from './heart';
import IconCrossCircle from './crosscircle';
import IconPlusCircle from './pluscircle';
import IconTailRight from './tail-right';
import IconPlusCircleSmall from './pluscirclesmall';
import IconMinusCircleSmall from './minuscirclesmall';
import IconUsers from './users';
import IconUserList from './user-list';
import IconDelete from './delete';
import IconMegaphone from './megaphone';
import IconScroll from './scroll';
import IconBell from './bell';
import IconRead from './read';
import IconLink from './link';
import IconStar from './star';
import IconDetails from './details';
import IconEmailModule from './emailmodule';
import IconExampleText from './exampletext';
import IconRadioEmpty from './radio-empty';
import IconRadioSelected from './radio-selected';
import IconMetrics from './metrics';
import IconFunnel from './funnel';
import IconCrownBroken from './crown-broken';
import IconCheckboxPartial from './checkbox-partial';
import IconCreateAccount from './create-account';
import IconDownload from './download';
import IconPushModule from './push-module';
import IconAnalytics from './analytics';
import IconPopupOption from './popup-option';
import IconPersonalizedPush from './personalizedPush';
import IconSentPush from './sentPush';
import IconPeriodicPush from './periodicPush';
import RssFeed from './rss-feed';
import IconWinback from './winback';
import IconGrowth from './growth';
import IconRetention from './retention';
import IconRecoCmp from './reco_cmp';
import IconBulk from './bulk';
import IconFly from './fly';
import IconPermission from './permission';
import IconWarning from './warning';
import IconNumberCurrency from './numberCurrency';
import IconQuestion from './question';
import IconChrome from './chrome';
import IconFirefox from './firefox';
import IconSafari from './safari';
import IconApple from './apple';
import IconOpera from './opera';
import IconCongrats from './congrats';
import IconCongratsGray from './congratsGray';
import GoTest from './goTest';
import IconMoney from './money';
import IconRevenue from './revenue';
import IconProducts from './products';
import Cancelled from './cancelledOrder';
import IconPlus from './plus';
import IconCategory from './categoryIcon';
import Segmentify from './segmentify';
import IconHeartPure from './iconHeart';
import IconSearch from './search';
import IconEdit from './edit';
import IconCollapse from './collapse';
import InsightProduct from './insightProduct';
import IconBetting from './betting';
import IconSegment from './segment';
import IconMonitorCollapse from './monitorCollapse';
import IconMonitorExpand from './monitorExpand';
import IconHeroBanner from './hero-banner';
import BehaviouralTargeting from './behavioural-targeting';
import AudienceIcon from './audienceIcon';
import IconEditRowPencil from './edit-row-pencil';
import IconTrashBin from './trash-bin';
import LandingPage from './landingPage';
import RelationLookup from './relation-lookup';
import ProductSearch from './product-search';
import UserDetail from './user-detail';
import PlusWithBorder from './plusWithBorder';
import CircleBack from './circle-back';
import UserSplit from './user-split';
import Tick from './tick';
import DownArrowNoFill from './downarrownofill';
import BackArrow from './back-arrow';
import EyeIcon from './eye-icon';
import Experimentation from './experimentation';
import Trophy from './trophy';
import Returned from './returned';
import ReturnedMini from './returnedMini';

import GarbageBin from './garbageBin';
import NewBadge from './new_badge';
import LeftLongArrow from './leftLongArrow';
import Import from './import';
import Export from './export';
import EditPencil from './editPencil';
import SPAddedToBasket from './social-proof/spAddedToBasket';
import SPStockCount from './social-proof/spStockCount';
import SPLastBoughtTime from './social-proof/spLastBoughtTime';
import SPSavingOver from './social-proof/spSavingOver';
import SPScoreAndReview from './social-proof/spScoreAndReview';
import SPPurchaseCounter from './social-proof/spPurchaseCounter';
import SPViewCounter from './social-proof/spViewCounter';
import SPLastVisit from './social-proof/spLastVisit';
import SPPreviouslyAddedToBasket from './social-proof/spPreviouslyAddedToBasket';
import SPFavoriteActivity from './social-proof/spFavoriteActivity';
import PcBigger from './pcBigger';
import PcCanvas from './pcCanvas';
import MobileBigger from './mobileBigger';
import Replenishment from './replenishment';
import ReplenishmentSystem from './replenishmentSystem';
import ReplenishmentUser from './replenishmentUser';
import MoveIcon from './moveIcon';
import RenewIcon from './renew';
import SeeAll from './seeAll';
import PersonaQuiz from './persona-quiz';
import PictureCard from './picture-card';
import PersonalisedAds from './personalisedAds';
import CallToAction from './callToAction';
import PinIcon from './pinIcon';
import CategoryAccessoryIcon from './categoryAccessoryIcon';
import ReUpload from './reUpload';
import EmailTemplatesIcon from './EmailTemplatesIcon';
import EmailMessageCanvas from './EmailMessageCanvas';
import Reply from './Reply';
import VerticalEllipsis from './VerticalEllipsis';
import Person from './Person';
import StarNoFill from './StarNoFill';
import IconPercentage from './Percentage';
import dynamicBundleIcon from './dynamicBundleIcon';
import PeopleGroupIcon from './peopleGroup';
import IconContact from './contact';
import IconFtpSettings from './ftp-settings';
import IconPlusThin from './PlusThin';
import EmailScheduleTimeClock from './EmailScheduleTimeClock';
import Alert from './Alert';
import SegmentifyAnalytics from './segmentifyAnalytics';

import {
  PriceDrop,
  BackInStock,
  WeMissedYou,
  ArrowTrendDown,
  EmailBell,
  EmailBirthday,
  EmailOrderFollow,
  EmailDiamond,
  EmailEditIcon,
  EmailSent,
  AbandonedCart,
  LastVisitAlternative,
  LastVisitReminder,
  NiceToMeetYou,
  FullScreenIcon,
} from './segmentify-mail/module-export';

import People from './People';
import {
  confirmationTickIcon,
  cpBirthdayIcon,
  cpGenderIcon,
  cpMailIcon,
  cpNotePadIcon,
  cpPhoneIcon,
  customerPictureIcon,
  dismissIcon,
  firstOrderIcon,
  lastOrderIcon,
  firstVisitIcon,
  lastVisitIcon,
  pushIcon,
  registrationIcon,
} from './customer-profile/cp-icons-exports';
import Countdown from './countdown';
import Twitter from './twitter';
import Facebook from './facebook';
import Instagram from './instagram';
import Snapchat from './snapchat';
import Tiktok from './tiktok';
import Others from './others';
import EmailPreviewIcon from './EmailPreviewIcon';
import iysIcon from './iysIcon';
import noUpdateIcon from './noUpdate';
import Emoji from './emojiOpener';

import {
  WofReport,
  WofCta,
  WofWheel,
  WofSpin,
  ScratchBG,
  ScratchStyle,
} from './gamification/gf-icons-export';

import {
  HTML5ICON,
  CSS3ICON,
  JSICON,
  GitMergeIcon,
  EventListIcon,
} from './code-editor/module-export';

import { SortUp, SortDown } from './sort/sort-icons-export';

const icons = {
  garbageBin: GarbageBin,
  insightProduct: InsightProduct,
  allDevices: IconAllDevices,
  pc: IconPc,
  pie: IconPie,
  ios: IconIos,
  goTest: GoTest,
  samsung: IconSamsung,
  ucbrowser: IconUCBrowser,
  gear: IconGear,
  wand: IconWand,
  push: IconPush,
  cross: IconCross,
  email: IconEmail,
  yandex: IconYandex,
  clock: IconClock,
  popup: IconPopup,
  socialProof: IconSocialProof,
  gamification: IconGamification,
  wheel_of_fortune: IconWheelOfFortune,
  scratch: IconScratch,
  goActive: IconGoActive,
  mobile: IconMobile,
  target: IconTarget,
  archive: IconArchive,
  android: IconAndroid,
  palette: IconPalette,
  useradd: IconUserAdd,
  usermanagement: IconUserManagement,
  position: IconPosition,
  calendar: IconCalendar,
  dashboard: IconDashboard,
  duplicate: IconDuplicate,
  notification: IconNotification,
  heroBanner: IconHeroBanner,
  scheduled: IconScheduled,
  completed: IconCompleted,
  excludeCustom: IconCustomExclude,
  excludeLabel: IconLabelExclude,
  excludeGender: IconGenderExclude,
  sales: IconSales,
  revenueSort: IconSales,
  upArrow: IconUpArrow,
  downArrow: IconDownArrow,
  history: IconHistory,
  compare: IconCompare,
  text: IconFieldText,
  fieldCheckbox: IconFieldCheckbox,
  fieldTextArea: IconFieldTextArea,
  fieldRadio: IconFieldRadio,
  imageholder: IconImageHolder,
  formsurvey: IconFormSurvey,
  like: IconLike,
  lock: IconLock,
  graph: IconGraph,
  increase: IconIncrease,
  decrease: IconDecrease,
  removeUser: IconRemoveUser,
  toggleUser: IconToggleUser,
  stats: IconStats,
  previous: IconPrevious,
  browser: IconBrowser,
  burger: IconBurger,
  referral: IconReferral,
  social: IconSocial,
  listing: IconListing,
  affiliate: IconAffiliate,
  adwords: IconAdwords,
  retargeting: IconRetargeting,
  chevronDown: IconChevronDown,
  chevronUp: IconChevronUp,
  dashboardTfy: IconDashboardTfy,
  filter: IconFilter,
  feedback: IconFeedback,
  brain: IconBrain,
  asteriks: IconAsteriks,
  checkboxEmpty: IconCheckboxEmpty,
  checkboxCircleEmpty: IconCheckboxCircleEmpty,
  checkboxCircleSelected: IconCheckboxCircleSelected,
  checkboxSelected: IconCheckboxSelected,
  checkRoundTick: IconCheckboxRoundTick,
  heart: IconHeart,
  crossCircle: IconCrossCircle,
  plusCircle: IconPlusCircle,
  tailRight: IconTailRight,
  plusCircleSmall: IconPlusCircleSmall,
  minusCircleSmall: IconMinusCircleSmall,
  users: IconUsers,
  userList: IconUserList,
  addUser: IconUserAdd,
  delete: IconDelete,
  megaphone: IconMegaphone,
  scroll: IconScroll,
  bell: IconBell,
  read: IconRead,
  link: IconLink,
  star: IconStar,
  details: IconDetails,
  emailModule: IconEmailModule,
  mailModule: IconEmailModule,
  exampleText: IconExampleText,
  radioEmpty: IconRadioEmpty,
  radioSelected: IconRadioSelected,
  metrics: IconMetrics,
  funnel: IconFunnel,
  cancelSwitch: IconCrownBroken,
  checkboxPartial: IconCheckboxPartial,
  createAccount: IconCreateAccount,
  download: IconDownload,
  pushModule: IconPushModule,
  analytics: IconAnalytics,
  popupOption: IconPopupOption,
  personalizedPush: IconPersonalizedPush,
  sentPush: IconSentPush,
  periodicPush: IconPeriodicPush,
  scheduledPush: IconScheduled,
  feedTrigger: RssFeed,
  winback: IconWinback,
  growth: IconGrowth,
  retention: IconRetention,
  reco_cmp: IconRecoCmp,
  bulk: IconBulk,
  fly: IconFly,
  permission: IconPermission,
  warning: IconWarning,
  numberAndCurrency: IconNumberCurrency,
  question: IconQuestion,
  chrome: IconChrome,
  firefox: IconFirefox,
  opera: IconOpera,
  safari: IconSafari,
  apple: IconApple,
  checkGray: IconCheckGray,
  check: IconCheck,
  checkCircle: IconCheckCircle,
  congrats: IconCongrats,
  iconHeart: IconHeartPure,
  congratsGray: IconCongratsGray,
  products: IconProducts,
  revenue: IconRevenue,
  money: IconMoney,
  plus: IconPlus,
  categoryBoxes: IconCategory,
  cancelled: Cancelled,
  segmentify: Segmentify,
  search: IconSearch,
  edit: IconEdit,
  collapse: IconCollapse,
  betting: IconBetting,
  segmentation: IconSegment,
  monitorCollapse: IconMonitorCollapse,
  monitorExpand: IconMonitorExpand,
  behaviouralTargeting: BehaviouralTargeting,
  audienceIcon: AudienceIcon,
  editRowPencil: IconEditRowPencil,
  trashBin: IconTrashBin,
  landingPage: LandingPage,
  relationLookUp: RelationLookup,
  productSearch: ProductSearch,
  userDetail: UserDetail,
  plusWithBorder: PlusWithBorder,
  circleBack: CircleBack,
  newBadge: NewBadge,
  leftLongArrow: LeftLongArrow,
  import: Import,
  export: Export,
  editPencil: EditPencil,
  emailScheduleTimeClock: EmailScheduleTimeClock,
  spAddedToBasket: SPAddedToBasket,
  spStockCount: SPStockCount,
  spLastBoughtTime: SPLastBoughtTime,
  spSavingOver: SPSavingOver,
  spScoreAndReview: SPScoreAndReview,
  spPurchaseCounter: SPPurchaseCounter,
  spViewCounter: SPViewCounter,
  spLastVisit: SPLastVisit,
  spPreviouslyAddedToBasket: SPPreviouslyAddedToBasket,
  spFavoriteActivity: SPFavoriteActivity,
  pcBigger: PcBigger,
  pcCanvas: PcCanvas,
  emailMessageCanvas: EmailMessageCanvas,
  mobileBigger: MobileBigger,
  userSplit: UserSplit,
  tick: Tick,
  downarrownofill: DownArrowNoFill,
  backarrow: BackArrow,
  eye: EyeIcon,
  experimentation: Experimentation,
  trophy: Trophy,
  replenishment: Replenishment,
  replenishmentSystem: ReplenishmentSystem,
  replenishmentUser: ReplenishmentUser,
  returned: Returned,
  returnedMini: ReturnedMini,
  move: MoveIcon,
  renew: RenewIcon,
  seeAll: SeeAll,
  personalisedAds: PersonalisedAds,
  personaQuiz: PersonaQuiz,
  pictureCard: PictureCard,
  callToAction: CallToAction,
  pinIcon: PinIcon,
  categoryAccessoryIcon: CategoryAccessoryIcon,
  reUpload: ReUpload,
  percentage: IconPercentage,
  iysIcon,
  noUpdateIcon,
  confirmationTickIcon,
  cpBirthdayIcon,
  cpGenderIcon,
  cpMailIcon,
  cpPhoneIcon,
  cpNotePadIcon,
  customerPictureIcon,
  dismissIcon,
  firstOrderIcon,
  lastOrderIcon,
  firstVisitIcon,
  lastVisitIcon,
  pushIcon,
  registrationIcon,
  wofReport: WofReport,
  wofCta: WofCta,
  wofWheel: WofWheel,
  wofSpin: WofSpin,
  scratchBG: ScratchBG,
  scratchStyle: ScratchStyle,
  countdown: Countdown,
  twitter: Twitter,
  facebook: Facebook,
  instagram: Instagram,
  snapchat: Snapchat,
  tiktok: Tiktok,
  others: Others,
  emoji: Emoji,
  EmailTemplatesIcon,
  EmailPreviewIcon,
  People,
  Reply,
  VerticalEllipsis,
  Person,
  StarNoFill,
  PriceDrop,
  BackInStock,
  WeMissedYou,
  ArrowTrendDown,
  EmailBell,
  EmailBirthday,
  EmailOrderFollow,
  EmailDiamond,
  EmailEditIcon,
  EmailSent,
  AbandonedCart,
  LastVisitAlternative,
  LastVisitReminder,
  NiceToMeetYou,
  FullScreenIcon,
  dynamicBundleIcon,
  PeopleGroupIcon,
  contact: IconContact,
  ftpSettings: IconFtpSettings,
  IconPlusThin,
  htmlIcon: HTML5ICON,
  cssIcon: CSS3ICON,
  jsIcon: JSICON,
  gitMergeIcon: GitMergeIcon,
  EventListIcon,
  SortDownIcon: SortDown,
  SortUpIcon: SortUp,
  alert: Alert,
  segmentifyAnalytics: SegmentifyAnalytics,
};

class Icons extends Component {
  render() {
    if (typeof icons[this.props.name] === 'undefined') {
      return false;
    }

    const Icon = icons[this.props.name];
    return <Icon {...this.props} />;
  }
}

Icons.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

export default Icons;
