import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Icons from '../../../icons';
import { t } from '../../../../system/ui';
import { TextField } from '../../../fields';
import Tooltip from '../../../tooltip';
import Icon from '../../../icon';

const Custom = ({
  onInputChange,
  onFilterRemove,
  values,
  id,
  segmentType,
  type,
  setAttributeComp,
  filterParams,
}) => {
  const [isBetweenSelected, setIsBetweenSelected] = useState(false);
  const [isNumberSelected, setIsNumberSelected] = useState(false);
  const [showValueError, setShowValueError] = useState(false);
  const [state, setState] = useState({
    parameter: '',
    operation: '',
    value: '',
    otherValue: '',
  });

  const options = [
    { value: 'EQUALS', label: 'Equals' },
    { value: 'NOT_EQUALS', label: 'Not Equals' },
    { value: 'CONTAINS', label: 'Contains' },
    { value: 'NOT_CONTAINS', label: 'Not Contains' },
    { value: 'GREATER', label: 'Greater Than' },
    { value: 'LESS', label: 'Less Than' },
    { value: 'BETWEEN', label: 'Between' },
  ];

  const onChange = e => {
    setShowValueError(false);
    const { name, value } = e.target;

    const newState = {
      ...state,
      [name]: value,
    };

    setState(newState);

    if (parseFloat(newState.value) > parseFloat(newState.otherValue))
      setShowValueError(true);

    onInputChange(`custom-${id}`, segmentType, newState, id);
  };

  const onOperatorChange = selected => {
    const newState = {
      ...state,
      operation: selected.value,
    };
    setIsBetweenSelected(false);
    setIsNumberSelected(false);
    setState(newState);

    if (selected.value === 'BETWEEN') {
      setIsBetweenSelected(true);
    }

    if (selected.value !== 'BETWEEN') {
      newState.otherValue = '';
    }

    if (
      selected.value === 'GREATER' ||
      selected.value === 'LESS' ||
      selected.value === 'BETWEEN'
    ) {
      setIsNumberSelected(true);
    }

    onInputChange(`custom-${id}`, segmentType, newState, id);
  };

  const setDefaults = () => {
    setIsBetweenSelected(
      typeof values.operation !== 'undefined' && values.operation === 'BETWEEN',
    );
    setIsNumberSelected(
      typeof values.operation !== 'undefined' &&
        (values.operation === 'BETWEEN' ||
          values.operation === 'GREATER' ||
          values.operation === 'LESS'),
    );

    const newState = {
      parameter:
        typeof values.parameter !== 'undefined' ? values.parameter : '',
      operation:
        typeof values.operation !== 'undefined' ? values.operation : 'EQUALS',
      value: typeof values.value !== 'undefined' ? values.value : '',
      otherValue:
        typeof values.otherValue !== 'undefined' ? values.otherValue : '',
    };

    return setState(newState);
  };

  useEffect(() => {
    setDefaults();
  }, [filterParams, values]);

  return (
    <div className='sfy-color'>
      <div className='sfy-color-left'>
        <div className='sfy-color-icoText'>
          <span className='sfy-color-icon'>
            <Icons name='excludeCustom' color='#787774' />
          </span>
          <Tooltip
            content='Custom filter allows you to include custom filtering such as displaying products based on rating or reviews.'
            alignment='top'
            newTooltip
            isInline
          >
            <Icon name='info' />
          </Tooltip>
          <div className='sfy-color-titleSelectMain'>
            <div className='sfy-color-titleSelect'>
              <span className='sfy-color-title'>{t('Custom')}</span>
            </div>
          </div>
          <TextField
            name='parameter'
            value={state.parameter}
            className='item-field criteria-name'
            inputClassName='one-whole criteria-field'
            placeholder='Param'
            required
            onChange={onChange}
          />
          <Select
            id='operation'
            className='item item-field is-select criteria-name wizard-operator'
            options={options}
            onChange={onOperatorChange}
            value={state.operation}
            clearable={false}
          />
          <TextField
            name='value'
            value={state.value}
            className='item-field criteria-name wizard-value'
            inputClassName='one-whole criteria-field'
            placeholder='Value'
            required
            onChange={onChange}
            type={isNumberSelected ? 'number' : 'text'}
          />
          {isBetweenSelected && (
            <TextField
              name='otherValue'
              value={state.otherValue}
              className='item-field criteria-name wizard-value'
              inputClassName='one-whole criteria-field'
              placeholder='Other Value'
              required
              onChange={onChange}
              type='number'
            />
          )}
        </div>
      </div>
      <div className='sfy-color-button-criteria'>
        <a
          className='sfy-color-actions'
          onClick={() => setAttributeComp('custom', segmentType, true, id)}
        >
          <Icons name='plusCircle' width='32' height='32' />
        </a>
        <a
          onClick={() => onFilterRemove(id, segmentType, type)}
          className='sfy-color-actions'
        >
          <Icons name='crossCircle' />
        </a>
      </div>
    </div>
  );
};

export default Custom;
