import { createAction } from '../system/store';

export const resetRange = () => createAction('RANGE_RESET');

export const updateRange = range =>
  createAction('DATE_RANGE_CHANGED', { range });

export const updateRangeAlias = (rangeAlias, defaultOpening = false) =>
  createAction('NAMED_RANGE_CHANGED', { rangeAlias, defaultOpening });

export const resetToCustom = (range, rangeAlias) =>
  createAction('RESET_TO_CUSTOM_SETTINGS', { rangeAlias, range });

export default {
  resetRange,
  updateRange,
  updateRangeAlias,
  resetToCustom,
};
