import React, { useState, useEffect } from 'react';
import { Creatable } from 'react-select';
import { getFilterOptions } from '../../../../modules/shop-the-look/ajax';
import { decodeHtml } from '../../../../system/string';
import Icons from '../../../icons';
import { t } from '../../../../system/ui';
import './AttributeComponents.scss';

const Label = props => {
  const [labels, setLabels] = useState([]);
  const [label, setLabel] = useState('');

  const onChange = selectedValue => {
    const selectedLabel = selectedValue !== undefined ? selectedValue : label;
    setLabel(selectedLabel);

    props.onSelectChange('label', selectedLabel, props.id, props.segmentType);
  };

  const fetchLabels = () => {
    const selectedLabels = [];
    getFilterOptions('label').then(res => {
      const sorted = res.sort();
      const fetchedLabels = [];
      sorted.forEach(sortedLabel => {
        fetchedLabels.push({
          value: sortedLabel,
          label: decodeHtml(sortedLabel),
        });
      });
      if (props.values.label !== undefined) {
        fetchedLabels.push({
          value: props.values.label.value[0],
          label: decodeHtml(props.values.label.value[0]),
        });
        props.values.label.value.forEach(propsLabel => {
          selectedLabels.push({
            value: propsLabel,
            label: decodeHtml(propsLabel),
            title: decodeHtml(propsLabel),
          });
        });
      }

      setLabels(fetchedLabels);
      setLabel(selectedLabels);
    });
  };

  useEffect(() => {
    fetchLabels();
  }, []);

  return (
    <div className='sfy-color'>
      <div className='sfy-color-left'>
        <div className='sfy-color-icoText'>
          <span className='sfy-color-icon'>
            <Icons name='excludeLabel' color='#787774' />
          </span>
          <div className='sfy-color-titleSelectMain'>
            <div className='sfy-color-titleSelect'>
              <span className='sfy-color-title'>{t('Label')}</span>
            </div>
          </div>

          <Creatable
            value={label}
            options={labels}
            name='label'
            clearable={false}
            onChange={onChange}
            className='criteria-field criteria-field-category'
            placeholder={t('Select Label')}
            multi
            shouldKeyDownEventCreateNewOption={e => {
              switch (e.keyCode) {
                case 9:
                case 13:
                  return !0;
                default:
                  return !1;
              }
            }}
          />
        </div>
      </div>
      <a
        onClick={() =>
          props.onFilterRemove(props.id, props.segmentType, props.type)
        }
        className='sfy-color-actions'
      >
        <Icons name='crossCircle' />
      </a>
    </div>
  );
};

export default Label;
