import React, { useState, useEffect } from 'react';
import './ScratchCouponCard.scss';
import { t } from '../../../system/ui';
import Icons from '../../icons';
import { TextField } from '../../fields';
import { InputCardWrapper } from '../module-exports';

/**
 * @name WofSliceCard component
 * @description WofExpandableCardWrapper component
 * @param {JSX.Element | JSX.Element[]} children
 * @returns {JSX.Element}
 * @constructor
 */
const WofSliceCard = ({
  type = 'null',
  count,
  cardDetail,
  handleChange,
  handleDelete,
  isRemovable = true,
  isOverLimit = false,
  isReadOnly,
}) => {
  const [cardData, setCardData] = useState(cardDetail);

  useEffect(() => {
    setCardData(cardDetail);
  }, [cardDetail]);

  const renderCard = () => {
    if (type === 'GENERIC') {
      return (
        <div className='WofSliceCard__scratch' id={count}>
          <div className='WofSliceCard__scratch__header'>
            <h3 className='WofSliceCard__scratch__title'>
              {t('Coupon')} {count + 1}*
            </h3>
            {isRemovable && (
              <div
                className='WofSliceCard__scratch__remove'
                onClick={() => handleDelete(count)}
              >
                <div className='WofSliceCard__scratch__remove__icon'>
                  <Icons
                    name='trashBin'
                    color='#CC4230'
                    width='12'
                    height='12'
                  />
                </div>
                <span>{t('Remove')}</span>
              </div>
            )}
          </div>
          <div className='WofSliceCard__scratch__body'>
            <div className='WofSliceCard__scratch__left'>
              <InputCardWrapper
                type='simple'
                cardTitle={t('Code of Coupon')}
                labelFor={`couponCode${count}`}
                required
              >
                <TextField
                  id={`couponCode${count}`}
                  type='text'
                  name='couponCodes'
                  required
                  className='item-stacked one-whole'
                  placeholder='Ex: TECH20'
                  value={cardData.couponCodes?.[0] || ''}
                  onChange={e =>
                    handleChange('couponCodes', [e.target.value], count)
                  }
                />
              </InputCardWrapper>
              <InputCardWrapper
                type='simple'
                cardTitle={t('URL of Coupon')}
                labelFor={`couponUrl${count}`}
                tooltipContent='couponUrl'
              >
                <TextField
                  id={`couponUrl${count}`}
                  type='text'
                  name='couponUrl'
                  className='item-stacked one-whole'
                  placeholder={t('Type here')}
                  value={cardData.couponUrl}
                  onChange={e =>
                    handleChange('couponUrl', e.target.value, count)
                  }
                />
              </InputCardWrapper>
              <InputCardWrapper
                type='simple'
                cardTitle={t('Description of Wining Result')}
                labelFor={`descriptionWin${count}`}
              >
                <TextField
                  id={`descriptionWin${count}`}
                  type='text'
                  name='description'
                  className='item-stacked one-whole'
                  placeholder={t('Type here')}
                  value={cardData.description}
                  onChange={e =>
                    handleChange('description', e.target.value, count)
                  }
                />
              </InputCardWrapper>
            </div>
            <div className='WofSliceCard__scratch__right'>
              <div className='WofSliceCard__scratch__possibility'>
                <div className='WofSliceCard__scratch__possibility--bottom'>
                  <label htmlFor={`possibility${count}`}>
                    {t('Possibility of win')}
                  </label>
                  <div className='WofSliceCard__scratch__possibility__input'>
                    <input
                      className={`${isOverLimit ? 'has-error' : ''}`}
                      id={`possibility${count}`}
                      type='number'
                      min={0}
                      name='possibility'
                      placeholder={t('Ex: 25')}
                      value={cardData.possibility}
                      onChange={e => {
                        handleChange(
                          'possibility',
                          e.target.value === ''
                            ? ''
                            : parseFloat(e.target.value),
                          count,
                        );
                      }}
                      onBlur={e => {
                        handleChange(
                          'possibilityAlgorithm',
                          e.target.value === ''
                            ? 0
                            : parseFloat(e.target.value),
                          count,
                        );
                      }}
                      readOnly={isReadOnly}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className='WofSliceCard__scratch' id='unique-scratch'>
        <div className='WofSliceCard__scratch__upload-header'>
          <div
            className='WofSliceCard__scratch__remove'
            onClick={() => handleDelete()}
          >
            <div className='WofSliceCard__scratch__remove__icon'>
              <Icons name='trashBin' color='#CC4230' width='12' height='12' />
            </div>
            <span>{t('Remove')}</span>
          </div>
        </div>
        <div className='WofSliceCard__scratch__body'>
          <div className='WofSliceCard__scratch__left'>
            <span className='WofSliceCard__scratch__left--title'>
              {t('Code of Coupon')}
            </span>
            <div className='WofSliceCard__scratch__left--uploaded'>
              <Icons name='tick' color='#06BE44' width='13' height='13' />
              {t('Uploaded')}
            </div>
          </div>
          <div className='WofSliceCard__scratch__right'>
            <div className='WofSliceCard__scratch__possibility'>
              <div className='WofSliceCard__scratch__possibility--bottom'>
                <label htmlFor='possibility'>{t('Possibility of win')}</label>
                <div className='WofSliceCard__scratch__possibility__input'>
                  <input
                    id='possibility'
                    type='number'
                    min={0}
                    name='possibility'
                    placeholder={t('Automatic')}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <>{renderCard()}</>;
};

export default WofSliceCard;
