import React, { useContext, useEffect, useState } from 'react';
import './ProductBundleAddOrEdit.scss';
import { browserHistory } from 'react-router';
import {
  SFYHeader,
  CampaignActionButton,
  SFYWrapper,
} from '../../../../components/sfy-components/module-exports';
import { modalActions, uiActions } from '../../../../actions';
import {
  getDynamicBreadCrumbOptions,
  campaignActionButtonConstant,
} from '../constants/constants';
import { ConvertForBackend, ConvertForFrontend } from '../utils/utils';
import { Context as ProductBundleContext } from '../store/productBundleProvider';
import { ProductBundleService } from '../services/productBundleServices';
import BundleModalManager from '../modals/bundleModalManager';
import RectangleBundleCard from '../../../../components/sfy-components/Card/RectangleBundleCard';
import { getSTLPreviewItemByCategory } from '../../shop-the-look/services/shopTheLookServices';
import { getAccount } from '../../../../modules/auth/user';
import { inclusionOrExclusionConverterForBackEnd } from '../../../../components/sfy-components/Filters/utils/inclusion-exlusion-converter';
import { getProductDetails } from '../../../../modules/account/ajax';
import { setTitle } from '../../../../system/document';

const AddOrEdit = ({ params }) => {
  const {
    state: { activeBundleDetails },
    setActiveBundleDetails,
    editActiveBundleDetails,
    resetProductBundle,
  } = useContext(ProductBundleContext);

  const [isVariantVisible, setIsVariantVisible] = useState(true);
  const [isVariantEnabledState, setIsVariantEnabledState] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { mode, id } = params;

  const validate = () => {
    const { mainItem } = activeBundleDetails;
    const { subItems } = activeBundleDetails;

    if (subItems.length < 1) {
      return 'Please add at least 1 product.';
    }
    if (mainItem.type === 'product') {
      if (mainItem?.exist !== true) {
        return 'This main product not in the catalog anymore. Please select another product.';
      }
    }
    const isAllSubItemsExist = subItems.every(
      subItem => subItem?.exist === true,
    );

    if (!isAllSubItemsExist) {
      return 'A bundle product not in the catalog anymore. Please select another product.';
    }

    return true;
  };

  const save = () => {
    const validation = validate();
    if (validation !== true) {
      const content = () => <p>{validation}</p>;
      uiActions.showNotification({
        content,
        className: 'notification-fail',
      });
      return;
    }

    if (mode === 'edit') {
      uiActions.isLoading();
      const data = ConvertForBackend(activeBundleDetails);
      ProductBundleService.updatewithIdParam({ id, body: data }).then(() => {
        uiActions.isLoaded();
        uiActions.showNotification({
          content: () => <p> Bundle updated successfully.</p>,
          className: 'notification-success',
        });
      });
    } else {
      uiActions.isLoading();
      const data = ConvertForBackend(activeBundleDetails);
      ProductBundleService.create({ body: data }).then(response => {
        if (response) {
          uiActions.isLoaded();
          uiActions.showNotification({
            content: () => <p>Bundle created successfully.</p>,
            className: 'notification-success',
          });
          browserHistory.push(
            `/dynamic_bundle/automated-bundles/edit/${response}`,
          );
        }
      });
    }
  };

  const checkMissingFieldsAndFill = () => {
    // Can be only one missing item. Because we are adding one by one and this control working every time that we add a new subItem or mainItem.
    let missingItem = null;
    let missingItemField = null;
    if (activeBundleDetails?.subItems?.length > 0) {
      missingItem = activeBundleDetails?.subItems.find(
        item => item?.shouldBeDetailed,
      );
      missingItemField = 'bundle-item';
    }
    if (activeBundleDetails?.mainItem?.shouldBeDetailed) {
      missingItem = activeBundleDetails?.mainItem;
      missingItemField = 'main-item';
    }

    if (missingItem) {
      uiActions.isLoading();
      const account = getAccount();
      if (missingItem.type === 'product') {
        getProductDetails(account.accountId, missingItem.key, response => {
          if (response) {
            const productRecord = response?.productRecord;
            const filledItem = {
              ...missingItem,
              productRecord,
            };
            delete filledItem.shouldBeDetailed;
            editActiveBundleDetails({
              type: missingItemField,
              item: filledItem,
            });
            setIsDisabled(response?.variantBundleExist);
            setIsVariantEnabledState(false);
          }
        });
      }
      if (missingItem.type === 'category') {
        getSTLPreviewItemByCategory({
          accountId: account.accountId,
          data: [
            {
              ...missingItem,
              category: missingItem.key,
              params: {
                inclusions: inclusionOrExclusionConverterForBackEnd(
                  missingItem?.params?.inclusions,
                ),
                exclusions: inclusionOrExclusionConverterForBackEnd(
                  missingItem?.params?.exclusions,
                ),
              },
            },
          ],
        }).then(response => {
          if (response && response.length > 0) {
            const imageUrl = response[0]?.product?.image || '';
            const filledItem = {
              ...missingItem,
              imageUrl,
            };
            delete filledItem.shouldBeDetailed;
            editActiveBundleDetails({
              type: missingItemField,
              item: filledItem,
            });
          }
        });
      }
      uiActions.isLoaded();
    }
  };

  useEffect(() => {
    checkMissingFieldsAndFill();
  }, [activeBundleDetails]);

  useEffect(() => {
    setTitle(
      mode === 'edit' ? 'Edit Automated Bundle' : 'Create Automated Bundle',
    );
    const account = getAccount();
    setIsVariantVisible(account?.variantEnabled);
    if (mode === 'edit') {
      uiActions.isLoading();
      ProductBundleService.getWithParams({ id }).then(response => {
        const convertedResponse = ConvertForFrontend(response);
        setActiveBundleDetails(convertedResponse);
        setIsVariantEnabledState(convertedResponse.isVariantsEnabled);
        uiActions.isLoaded();
      });
    }
  }, [mode]);

  useEffect(() => {
    return () => {
      resetProductBundle();
    };
  }, []);

  const openBundleModal = (
    bundleFor = '',
    bundleType = '',
    bundleItem = {},
  ) => {
    const content = () => (
      <BundleModalManager
        bundleFor={bundleFor}
        bundleType={bundleType}
        bundleItem={bundleItem}
        isEditMode={mode === 'edit'}
      />
    );

    modalActions.openModal({
      className: 'bundle-product-modal',
      content,
      dynamicHeight: true,
    });
  };

  const addNewBundle = () => {
    openBundleModal('bundle-item');
  };

  const editBundle = (cardType, index) => {
    const bundleForType = cardType === 'main' ? 'main-item' : 'bundle-item';
    let bundleType = '';
    let bundleItem = {};
    if (cardType === 'main') {
      bundleType = activeBundleDetails.mainItem.type;
      bundleItem = activeBundleDetails.mainItem;
    }
    if (cardType === 'bundle') {
      const subItem = activeBundleDetails.subItems.find(
        item => item.index === index,
      );
      bundleType = subItem.type;
      bundleItem = subItem;
    }
    openBundleModal(bundleForType, bundleType, bundleItem);
  };

  const removeBundle = index => {
    uiActions.confirmationDialog({
      title: 'Remove the Bundle?',
      content: 'Are you sure you want to remove the bundle you added?',
      onConfirm: () => {
        const newSubItems = activeBundleDetails.subItems
          .filter(item => item.index !== index)
          .map((item, itemIndex) => {
            return { ...item, index: itemIndex };
          });
        setActiveBundleDetails({
          ...activeBundleDetails,
          subItems: newSubItems,
        });
      },
    });
  };

  const moveCard = (dragIndex, hoverIndex) => {
    const newSubItems = [];
    activeBundleDetails.subItems.forEach((item, index) => {
      if (index === dragIndex) {
        newSubItems[hoverIndex] = { ...item, index: hoverIndex };
      } else if (index === hoverIndex) {
        newSubItems[dragIndex] = { ...item, index: dragIndex };
      } else {
        newSubItems[index] = { ...item, index };
      }
    });
    setActiveBundleDetails({
      ...activeBundleDetails,
      subItems: newSubItems,
    });
  };

  const onVariantChange = () => {
    setIsVariantEnabledState(prevState => !prevState);
  };

  useEffect(() => {
    setActiveBundleDetails({
      ...activeBundleDetails,
      isVariantsEnabled: isVariantEnabledState,
    });
  }, [isVariantEnabledState]);

  const campaignActionButtonConstantWithFunctions = campaignActionButtonConstant.map(
    actionButton => {
      let actionButtonWithFunction;
      switch (actionButton.type) {
        case 'save-justify-right':
          actionButtonWithFunction = () => save();
          break;
        default:
          actionButtonWithFunction = () => {};
          break;
      }
      return {
        ...actionButton,
        action: actionButtonWithFunction,
      };
    },
  );

  return (
    <>
      <SFYHeader
        pageTitle='Product Bundled'
        breadCrumbOptions={getDynamicBreadCrumbOptions('Bundles')}
      />
      <SFYWrapper>
        <div className='product-bundle-mainWrapper'>
          <RectangleBundleCard
            cardDetails={activeBundleDetails.mainItem}
            cardType='main'
            editFunction={editBundle}
            addFunction={addNewBundle}
            removeFunction={removeBundle}
            isVariantVisible={isVariantVisible}
            isVariantActive={isVariantEnabledState}
            setIsVariantActive={onVariantChange}
            isDisabled={isDisabled}
          />

          <div className='product-bundle-main-right'>
            {activeBundleDetails?.subItems?.map(item => (
              <RectangleBundleCard
                key={Math.random()}
                cardDetails={item}
                cardType='bundle'
                editFunction={editBundle}
                addFunction={addNewBundle}
                removeFunction={removeBundle}
                moveCard={moveCard}
              />
            ))}

            <RectangleBundleCard
              cardDetails={{}}
              cardType='empty'
              editFunction={editBundle}
              addFunction={addNewBundle}
              removeFunction={removeBundle}
            />
          </div>
        </div>
      </SFYWrapper>

      <CampaignActionButton
        campaignActionButtonConstant={campaignActionButtonConstantWithFunctions}
        isDisabled={isDisabled}
      />
    </>
  );
};

export default AddOrEdit;
