import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { config } from '../../constants/config';

import { campaignSpecificData } from '../../constants/datamaps/campaign';
import { t } from '../../system/ui';

import { editCampaign, getCampaign } from './events';

import Fields from './fields';
import {
  personalizationRecommendationExclusions,
  personalizationRecommendations,
} from '../transform/personalizationRecommendations';
import { generateFormCampaign } from '../transform/formFields';

import { uiActions, wizardActions } from '../../actions';
import {
  LiveModeUpdatePersonalization,
  TestModeUpdatePersonalization,
} from '../../constants/datamaps/notifications';
import WizardTarget from '../../components/wizard/target';
import { ExpandableCard } from '../../components/personaQuiz/components/index';
import { createSegment } from '../segmentation/ajax';
import { hasModule } from '../auth/user';

const menuSlugs = {
  popup: 'POPUP_BANNER',
  'see-all': 'SEE_ALL',
  hero_banner: 'HERO_BANNER',
  notification_bar: 'NOTIFICATION_BAR',
  email_collection: 'NEWSLETTER',
  form_survey: 'FORM',
  segmentation: 'SEGMENTATION',
  persona_quiz: 'PERSONA_QUIZ',
};

const getCampaignBreadCrumb = campaignType => {
  let result = '';
  Object.keys(menuSlugs).forEach(val => {
    if (menuSlugs[val] === campaignType) {
      result = val;
    }
  });
  return result;
};

class EditCampaign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: {},
      campaignStatus: '',
    };

    this.save = this.save.bind(this);
    this.campaignDetails = this.campaignDetails.bind(this);
  }

  componentDidMount() {
    uiActions.isValidated();
    uiActions.resetPage();
    wizardActions.saveReset();
    wizardActions.wizardReset();
    wizardActions.wizardEdit();
    this.campaignDetails();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      this.campaignDetails();
    }

    if (newProps.save !== false) {
      this.save(newProps.save);
    }
  }

  campaignDetails() {
    getCampaign(this.props.campaignId).then(response => {
      if (response.response !== null) {
        const campaign = response.response;
        const campaignStatus =
          response.response.status === 'ACTIVE' ? 'Live' : 'Test';
        const recommendations =
          campaign.recommendations ||
          (campaign.recommendation ? [campaign.recommendation] : '');
        const { exclusions } = campaign;
        const { inclusions } = campaign;
        const criteria = [];
        const staticItems = [];
        const dynamicItems = [];
        const excludes = [];
        const includes = [];
        const formCampaignFields = campaign.fields;
        const toAddFormFields = [];
        let products = [];
        if (['PERSONA_QUIZ'].includes(campaign.type)) {
          const textColor = campaign?.resultButton?.textColor;
          const bgColor = campaign?.resultButton?.backgroundColor;
          campaign.textColor = textColor;
          campaign.bgColor = bgColor;
        }
        this.setState({
          campaign,
          campaignStatus,
        });

        if (recommendations || exclusions || inclusions || formCampaignFields) {
          exclusions &&
            exclusions.forEach(item => {
              const values = {};

              Object.keys(item).forEach(key => {
                if (key !== 'type') {
                  values[key] = {
                    value: item[key],
                  };
                }
              });

              excludes.push({
                id: Math.random(),
                type: item.type.toLowerCase(),
                values,
              });
            });

          inclusions &&
            inclusions.forEach(item => {
              const values = {};

              Object.keys(item).forEach(key => {
                if (key !== 'type') {
                  values[key] = {
                    value: item[key],
                  };
                }
              });

              includes.push({
                id: Math.random(),
                type: item.type.toLowerCase(),
                values,
              });
            });

          recommendations &&
            recommendations.forEach(item => {
              if (item.type.toLowerCase() !== 'product') {
                if (item.type.toLowerCase() !== 'intelligent') {
                  dynamicItems.push({
                    id: Math.random(),
                    type: item.type.toLowerCase(),
                    values: {
                      criterion: {
                        value: item[item.type.toLowerCase()],
                      },
                      itemCount: {
                        value: item.itemCount,
                      },
                      timeFrame: {
                        value: item.timeFrame,
                      },
                    },
                  });
                } else {
                  dynamicItems.push({
                    id: Math.random(),
                    type: item.type.toLowerCase(),
                    values: {
                      criterion: {
                        value: item.source,
                      },
                      itemCount: {
                        value: item.itemCount,
                      },
                      timeFrame: {
                        value: item.timeFrame,
                      },
                    },
                  });
                }
              } else if (item.productId) {
                if (Array.isArray(item.productId)) {
                  products = products.concat(item.productId);
                } else {
                  products.push(item.productId);
                }
              }
            });

          if (products.length) {
            staticItems.push({
              id: Math.random(),
              type: 'product',
              values: {
                productId: {
                  value: products,
                },
              },
            });
          }

          formCampaignFields &&
            formCampaignFields.forEach(field => {
              const currentField = {
                id: Math.random(),
                type: field.type.toLowerCase(),
                values: {},
                options: [],
              };
              if (currentField.type === 'text_area') {
                currentField.type = 'textArea';
              }
              if (currentField.type === 'radio_button') {
                currentField.type = 'radio';
              }
              Object.keys(field).forEach(function(key) {
                if (key !== 'type') {
                  if (key === 'options') {
                    field[key].forEach(option => {
                      currentField.options.push({
                        id: Math.random(),
                        name: option,
                      });
                    });
                  } else {
                    currentField.values[key] = {};
                    currentField.values[key].value = field[key];
                  }
                }
              });
              toAddFormFields.push(currentField);
            });
        }

        wizardActions.addCriteria(staticItems.concat(dynamicItems));
        wizardActions.addExclusion(excludes);
        wizardActions.addInclusion(includes);
        wizardActions.addFormField(Math.random(), toAddFormFields);

        if (getCampaignBreadCrumb(this.props.type)) {
          const hasEngagementModule = hasModule('personalization');
          const hasEngagementPlusModule = hasModule('engagementPlus');
          const behaviouralTargetingPlusStatement =
            hasEngagementPlusModule && hasEngagementModule;

          if (behaviouralTargetingPlusStatement) {
            uiActions.showBreadcrumb({
              home: {
                name: t('Engagement'),
                icon: 'wand',
                iconSvg: true,
                slug: '/behavioural-targeting/engagement',
              },
              top: {
                name: t(
                  config.menus.primary['behavioural-targeting'].children[
                    getCampaignBreadCrumb(this.props.type)
                  ].name,
                ),
                slug:
                  config.menus.primary['behavioural-targeting'].children[
                    getCampaignBreadCrumb(this.props.type)
                  ].slug,
              },
              current: {
                name: campaign.name,
              },
            });
          } else {
            // eslint-disable-next-line no-lonely-if
            if (getCampaignBreadCrumb(this.props.type) === 'email_collection') {
              uiActions.showBreadcrumb({
                home: {
                  name: t('Email Marketing'),
                  icon: 'wand',
                  iconSvg: true,
                  slug: '/email/view-all',
                },
                top: {
                  name: t(
                    config.menus.primary.email.children['email-collection']
                      .name,
                  ),
                  slug:
                    config.menus.primary.email.children['email-collection']
                      .slug,
                },
                current: {
                  name: campaign.name,
                },
              });
            } else {
              uiActions.showBreadcrumb({
                home: {
                  name: t('Engagement'),
                  icon: 'wand',
                  iconSvg: true,
                  slug: '/behavioural-targeting/engagement',
                },
                top: {
                  name: t(
                    config.menus.primary['behavioural-targeting'].children[
                      getCampaignBreadCrumb(this.props.type)
                    ].name,
                  ),
                  slug:
                    config.menus.primary['behavioural-targeting'].children[
                      getCampaignBreadCrumb(this.props.type)
                    ].slug,
                },
                current: {
                  name: campaign.name,
                },
              });
            }
          }
        }
      }
    });
  }

  save(saveAs) {
    const {
      separatedContentFields,
      contentFields,
      contentButtons,
      contentExtra,
      contentOptions,
      contentOptionAdvancedConfig,
    } = this.refs;
    const { campaign } = this.state;
    const campaignData = campaignSpecificData[this.props.type];
    const campaignProps = {};

    const status = saveAs === 'test' ? 'TEST' : 'ACTIVE';

    if (separatedContentFields) {
      Object.keys(separatedContentFields.refs).forEach(ref => {
        campaignProps[ref] = separatedContentFields.refs[ref];
      });
    }

    if (contentFields) {
      Object.keys(contentFields.refs).forEach(ref => {
        campaignProps[ref] = contentFields.refs[ref];
      });
    }

    if (contentButtons) {
      Object.keys(contentButtons.refs).forEach(ref => {
        campaignProps[ref] = contentButtons.refs[ref];
      });
    }

    Object.keys(contentExtra.refs).forEach(ref => {
      campaignProps[ref] = contentExtra.refs[ref];
    });

    Object.keys(contentOptions.refs).forEach(ref => {
      campaignProps[ref] = contentOptions.refs[ref];
    });

    Object.keys(contentOptionAdvancedConfig.refs).forEach(ref => {
      campaignProps[ref] = contentOptionAdvancedConfig.refs[ref];
    });

    campaign.name = campaignProps.name.state.value.trim();
    if (campaignProps.recommendations) {
      campaign.ordering = campaignProps.recommendations.state.shuffle
        ? 'SHUFFLE'
        : 'GIVEN_ORDER';
    }
    campaign.status = status;
    if (campaignProps.devices) {
      campaign.devices = campaignProps.devices.state.value;
    } else {
      campaign.devices = ['ALL'];
    }
    if (campaignProps.startEndDate) {
      campaign.startDate = campaignProps.startEndDate.state.values.startDate;
      if (campaignProps.startEndDate.state.showEndDate) {
        campaign.endDate = campaignProps.startEndDate.state.values.endDate;
      } else {
        campaign.endDate = '';
      }
    } else {
      campaign.startDate = '';
      campaign.endDate = '';
    }

    campaign.note = '';

    if (campaignProps.timingOptions) {
      Object.keys(campaignProps.timingOptions.state.values).forEach(key => {
        let timingType = campaignProps.timingOptions.state.values[key].type;

        if (
          campaignProps.timingOptions.state.values[key].type === 'DELAY' &&
          campaignProps.timingOptions.state.delayAllPages
        ) {
          timingType = 'DELAY_ALL_PAGES';
        }

        campaign[key] = {
          type: timingType,
          param: campaignProps.timingOptions.state.values[key].param,
        };
      });
    }

    campaign.filters = [];

    if (campaignProps.pages) {
      if (!campaignProps.pages.state.onlyPageUrlFilter) {
        if (campaignProps.pages.state.value.indexOf('All') !== -1) {
          campaignProps.pages.state.value = [];
        }

        campaign.filters.push({
          type: 'PAGE',
          includedCategories: campaignProps.pages.state.value,
          excludedCategories: [],
        });
      }

      if (campaignProps.pages.state.hasPageUrlFilter) {
        const includedUrls =
          campaignProps.pages.state.selectedUrlFilter === 'contains'
            ? campaignProps.pages.state.filterUrls
            : [];
        const excludedUrls =
          campaignProps.pages.state.selectedUrlFilter === 'notContains'
            ? campaignProps.pages.state.filterUrls
            : [];

        campaign.filters.push({
          type: 'PAGE_URL',
          includedUrls: includedUrls?.map(url => url.value).filter(Boolean),
          excludedUrls: excludedUrls?.map(url => url.value).filter(Boolean),
        });
      }
    }

    if (campaignProps.audienceOptions) {
      campaignProps.audienceOptions.state.values.segment.forEach(segment => {
        campaign.filters.push({
          type: 'SEGMENT',
          segment: segment.value,
          include: segment.member,
        });
      });

      campaignProps.audienceOptions.state.values.visitor.forEach(visitor => {
        campaign.filters.push({
          type: 'VISITOR',
          name: visitor.value,
          include: visitor.member,
        });
      });
    }

    // Segmentation Campaign Variable
    if (campaignProps.segmentationOptions) {
      Object.keys(campaignProps.segmentationOptions.state.values).forEach(
        key => {
          campaign[key] = campaignProps.segmentationOptions.state.values[key];
        },
      );
    }

    // loop through the campaign specific options
    campaignData.forEach(dataName => {
      let data;
      let recommendations;
      let excludes;
      let includes;
      let recommendation;

      if (typeof dataName === 'object') {
        if (typeof campaignProps[dataName[0]] !== 'undefined') {
          data = campaignProps[dataName[0]].state.values;

          dataName[1] &&
            dataName[1].forEach(singleDataName => {
              campaign[singleDataName] = data[singleDataName];
            });
        }
      } else {
        if (typeof campaignProps[dataName] !== 'undefined') {
          if (dataName === 'recommendations') {
            recommendations = personalizationRecommendations(
              this.props.criteria,
            );
            campaign.recommendations = recommendations;
            excludes = personalizationRecommendationExclusions(
              this.props.excludes,
            );
            includes = personalizationRecommendationExclusions(
              this.props.includes,
            );
            campaign.inclusions = includes;
            campaign.exclusions = excludes;
          } else if (dataName === 'formFields') {
            campaign.fields = generateFormCampaign(
              this.props.formFields.fields,
            );
          } else {
            data = campaignProps[dataName].state.value;
            campaign[dataName] = data;
          }
        }
        if (
          dataName === 'image' &&
          typeof campaignProps.notificationImage !== 'undefined'
        ) {
          data = campaignProps.notificationImage.state.value;
          campaign[dataName] = data;
        }
      }
    });

    if (campaign.type === 'NEWSLETTER') {
      campaign.buttonThanksText = 'Thanks';
    }

    if (
      ['HERO_BANNER', 'POPUP_BANNER', 'NOTIFICATION_BAR'].includes(
        campaign.type,
      )
    ) {
      campaign.image = campaignProps.imageWithUpload.state.imgSrc;
    }

    if (['HERO_BANNER', 'SEE_ALL'].includes(campaign.type)) {
      const target = this.refs.wizardTarget.state;
      campaign.targetSelector = target.selector;
      campaign.targetPosition = target.insertType;
    }

    const newCustomSegmentsToCreate = [];
    if (['PERSONA_QUIZ'].includes(campaign.type)) {
      const resultButton = {
        title: '',
        backgroundColor: '',
        textColor: '',
      };
      const expandableRef = this.refs.expandableCard.state;
      const { quizTitle, responses, fetchedStaticSegments } = expandableRef;
      const existingSegments = [];
      responses.forEach(response => {
        if (
          fetchedStaticSegments.some(
            segment =>
              segment.value?.toString() === response?.segmentId?.toString(),
          )
        ) {
          existingSegments.push(response);
        } else {
          newCustomSegmentsToCreate.push(response);
        }
      });
      const buttonsRef = this.refs.separatedContentsFields.refs;

      const title = buttonsRef.buttonText.state.value;
      const {
        bgColor: backgroundColor,
      } = buttonsRef.stylesOptions.state.values;
      const buttonText = buttonsRef.stylesOptions.state.values.textColor;

      resultButton.title = title;
      resultButton.backgroundColor = backgroundColor;
      resultButton.textColor = buttonText;

      // save function uses campaign object line-277
      campaign.resultButton = resultButton;
      campaign.responses = existingSegments;
      campaign.quizTitle = quizTitle;
    }

    if (campaign.type === 'POPUP_BANNER') {
      campaign.pureHtml = campaignProps.advancedConfig.state.values.isPureHtml;
    }

    if (newCustomSegmentsToCreate.length > 0) {
      const newSegments = newCustomSegmentsToCreate.map(segment => ({
        name: segment.segmentName,
        type: 'EVENT_BASED',
      }));
      createSegment(newSegments, responses => {
        const newCustomSegmentsFromResponse = responses.map(response => ({
          ...newCustomSegmentsToCreate.find(
            segment => segment.segmentName === response.name,
          ),
          segmentId: response.id,
        }));
        campaign.responses = [
          ...campaign.responses,
          ...newCustomSegmentsFromResponse,
        ];
        editCampaign(campaign)
          .then(() => {
            this.campaignDetails();

            const note =
              status === 'TEST'
                ? TestModeUpdatePersonalization
                : LiveModeUpdatePersonalization;

            uiActions.showNotification({
              content: note,
            });
            wizardActions.saveReset();
            if (campaign.type !== 'HERO_BANNER') {
              wizardActions.wizardReset();
            }
          })
          .catch(response => {
            const error = () => (
              <p>{response.response.message ?? response.response}</p>
            );
            uiActions.showNotification({
              content: error,
              className: 'notification-fail',
            });
            wizardActions.saveReset();
            wizardActions.wizardReset();
          });
      });
    } else {
      editCampaign(campaign)
        .then(() => {
          this.campaignDetails();

          const note =
            status === 'TEST'
              ? TestModeUpdatePersonalization
              : LiveModeUpdatePersonalization;

          uiActions.showNotification({
            content: note,
          });
          wizardActions.saveReset();
          if (campaign.type !== 'HERO_BANNER') {
            wizardActions.wizardReset();
          }
        })
        .catch(response => {
          const error = () => (
            <p>{response.response.message ?? response.response}</p>
          );
          uiActions.showNotification({
            content: error,
            className: 'notification-fail',
          });
          wizardActions.saveReset();
          wizardActions.wizardReset();
        });
    }
  }

  render() {
    const advancedOption = [];
    const allOptions = [];
    this.props.options.forEach(option => {
      if (option.field === 'advancedConfig') {
        advancedOption.push(option);
      } else {
        allOptions.push(option);
      }
    });
    return (
      <form
        id='wizard'
        ref='wizard'
        className={`wizard engagement-form ${
          this.props.type === 'SEE_ALL' ? 'see-all-wizard' : ''
        }`}
      >
        <h3 className='page-title low-space'>
          <span
            className={`status-label status-label-${this.state.campaignStatus.toLowerCase()}`}
          >
            {this.state.campaignStatus}{' '}
          </span>
          {this.props.title}
        </h3>
        {this.props?.description && (
          <div className='page-description'>
            <i className='icon-info' />
            <span>{this.props.description}</span>
          </div>
        )}
        {this.props?.expandableCard && (
          <div className='widget'>
            <ExpandableCard
              ref='expandableCard'
              campaign={this.state.campaign}
              comps={this.props.personaQuizImg}
              campaignType={this.props.type}
            />
          </div>
        )}

        {this.props?.separatedContent?.length > 0 && (
          <div className='widget page-content-block separated-content'>
            <Fields
              ref='separatedContentFields'
              campaign={this.state.campaign}
              comps={this.props.separatedContent}
              campaignType={this.props.type}
            />
          </div>
        )}
        <div
          className={`widget page-content-block ${
            this.props.content.length === 0
              ? 'page-content-block-no-padding'
              : ''
          }`}
          style={{ paddingBottom: '5px' }}
        >
          <ol className='form-elements'>
            {this.props.content.length > 0 && (
              <li>
                <Fields
                  ref='contentFields'
                  campaign={this.state.campaign}
                  comps={this.props.content}
                  campaignType={this.props.type}
                />
              </li>
            )}
            {this.props.contentExtra ? (
              <li className='wizard-comp'>
                <ol className='form-elements'>
                  {this.props.contentExtra.buttons.length > 0 && (
                    <li className='wizard-input-types wizard-perso-buttons'>
                      <Fields
                        ref='contentButtons'
                        campaign={this.state.campaign}
                        comps={this.props.contentExtra.buttons}
                        campaignType={this.props.type}
                      />
                    </li>
                  )}
                  <li className='wizard-perso-fields'>
                    <Fields
                      ref='contentExtra'
                      campaign={this.state.campaign}
                      comps={this.props.contentExtra.fields}
                      campaignType={this.props.type}
                    />
                  </li>
                </ol>
              </li>
            ) : (
              ''
            )}
          </ol>
        </div>
        {this.props?.separatedContents?.length > 0 && (
          <div className='widget page-content-block separated-content'>
            <Fields
              ref='separatedContentsFields'
              campaign={this.state.campaign}
              comps={this.props.separatedContents}
              campaignType={this.props.type}
            />
          </div>
        )}

        <div className='wizard-options'>
          <ol className='form-elements'>
            <li>
              <Fields
                ref='contentOptions'
                campaign={this.state.campaign}
                comps={allOptions}
                campaignType={this.props.type}
              />
              {['HERO_BANNER', 'SEE_ALL'].includes(this.props.type) && (
                <li>
                  <WizardTarget
                    ref='wizardTarget'
                    campaign={this.state.campaign}
                    isHeroBanner
                  />
                </li>
              )}
            </li>
            <li className='buttons'>
              <Fields
                campaign={this.state.campaign}
                comps={this.props.buttons}
                campaignType={this.props.type}
                campaignFields={this.refs.contentFields}
              />
            </li>
          </ol>
        </div>
        <Fields
          ref='contentOptionAdvancedConfig'
          campaign={this.state.campaign}
          comps={advancedOption}
          campaignType={this.props.type}
        />
      </form>
    );
  }
}

EditCampaign.propTypes = {
  content: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  buttons: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const MapStatesToProps = store => ({
  save: store.wizard.save,
  user: store.user.user,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes,
  formFields: store.wizard.formFields,
});

export default connect(MapStatesToProps)(EditCampaign);
