import React from "react";

class IconPushModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60 60"
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <path d="M47.1,25.7c-7.1,0-12.8-5.8-12.8-12.9c0-7.1,5.8-12.8,13-12.8C54.3,0,60,5.8,60,12.9C60,20,54.2,25.7,47.1,25.7z" />
        <path
          d="M0,33c0-4.1,0.2-8.4,1.2-12.7c0.8-3.4,2.2-6.5,4.9-8.9c2.4-2.1,5.2-3.2,8.3-3.9c5.4-1.2,10.9-1.2,16.4-1
	c0.8,0,1.2,0.5,1.1,1.1C28.4,20.5,36.7,32,52.4,28.1c0.7,0,1.1,0.4,1.1,1.2c0.2,5.6,0.2,11.2-1.1,16.8c-0.8,3.4-2.2,6.5-4.8,8.9
	c-2.4,2.1-5.2,3.3-8.3,3.9c-5.4,1.2-10.8,1.2-16.3,1c-3.4-0.1-6.9-0.5-10.2-1.4c-3.8-1.1-7-3-9.2-6.4c-1.5-2.4-2.3-5.1-2.8-7.9
	C0.2,40.6,0,36.9,0,33z"
        />
      </svg>
    );
  }
}

export default IconPushModule;
