/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import './AddOrEdit.scss';
import { browserHistory } from 'react-router';
import {
  SFYHeader,
  SFYRightBar,
  SFYWrapper,
  StaticTab,
} from '../../../../../components/sfy-components/module-exports';
import { uiActions } from '../../../../../actions';
import { getAccountData } from '../../../../../modules/auth/user';
import { GamificationService } from '../../services/gamificationServices';
import {
  campaignActionButtonConstant,
  getDynamicBreadCrumbOptions,
  wofAdvancedConfig,
  wofTabs,
} from '../../constants/constants';
import {
  getWOFAdvancedConfigCampaignDetails,
  getWOFAdvancedConfigInitialTemplates,
} from '../../utils/utils';
import { Context as GamificationContext } from '../../store/gamificationProvider';
import AdvancedConfig from '../../../../../components/sfy-components/advanced-config/AdvancedConfig';
import SettingsTab from './tabs/SettingsTab';
import StyleTab from './tabs/StyleTab';
import {
  LiveModeUpdatePersonalization,
  TestModeAddPersonalization,
} from '../../../../../constants/datamaps/notifications';

const AddOrEdit = ({ params }) => {
  const { mode, campaignId } = params;
  const {
    isLoaded,
    isLoading,
    showNotification,
    confirmationDialog,
  } = uiActions;
  const {
    state: { activeWOFDetails },
    setActiveWOFDetails,
    resetActiveWOFDetails,
    editActiveWOFDetails,
  } = useContext(GamificationContext);

  const [breadCrumbName, setBreadCrumbName] = useState('Wheel of Fortune');
  const [tabs, setTabs] = useState(wofTabs);
  const [isAdvancedConfigShown, setIsAdvancedConfigShown] = useState(false);
  const [templates, setTemplates] = useState(
    getWOFAdvancedConfigInitialTemplates(activeWOFDetails),
  );

  useEffect(() => {
    setTemplates(getWOFAdvancedConfigInitialTemplates(activeWOFDetails));
  }, [activeWOFDetails]);

  const validate = () => {
    const {
      name,
      campaignTitle,
      devices,
      filters,
      possibleRewards,
      resultTitle,
    } = activeWOFDetails;
    if (!resultTitle || !name || !campaignTitle || devices.length === 0) {
      return 'Please fill all the required fields.';
    }

    const pagesToShow =
      filters?.find(filter => {
        return filter.type === 'PAGE' && filter?.includedCategories?.length > 0;
      })?.includedCategories || [];
    if (pagesToShow.length === 0) {
      return 'Please select at least one page to show the campaign.';
    }

    const sumOfRewardPercentage = possibleRewards?.reduce(
      (acc, curr) => acc + (curr?.possibility || 0),
      0,
    );
    if (sumOfRewardPercentage > 100) {
      return 'Sum of reward percentage should not be greater than 100.';
    }
    if (sumOfRewardPercentage < 100) {
      return 'Sum of reward percentage should not be less than 100.';
    }

    return true;
  };

  const getActiveWOFDetailsAfterCheckPossibleRewardsIndex = () => {
    const { possibleRewards } = activeWOFDetails;
    let hasIndexProblem = false;
    const controlledPossibleRewards = possibleRewards.map(
      (possibleReward, index) => {
        if (possibleReward.index !== index) {
          hasIndexProblem = true;
        }
        return {
          ...possibleReward,
          index,
        };
      },
    );

    if (hasIndexProblem) {
      editActiveWOFDetails({
        possibleRewards: controlledPossibleRewards,
      });
    }

    return {
      ...activeWOFDetails,
      possibleRewards: controlledPossibleRewards,
    };
  };

  const goTestOrLive = saveType => {
    const fixedStatus = saveType === 'live' ? 'ACTIVE' : 'TEST';
    const readyToSaveActiveWOFDetails = getActiveWOFDetailsAfterCheckPossibleRewardsIndex();
    const body = {
      ...readyToSaveActiveWOFDetails,
      status: fixedStatus,
      ...templates,
    };
    if (mode === 'edit') {
      const id = campaignId;
      isLoading();
      GamificationService()
        .update({ id, body })
        .then(res => {
          const { status, name } = res;
          isLoaded();
          let content = TestModeAddPersonalization;
          if (status === 'ACTIVE') {
            content = LiveModeUpdatePersonalization;
          }
          showNotification({
            content,
            className: 'notification-success',
          });
          setActiveWOFDetails(res);
          setBreadCrumbName(name);
        })
        .finally(() => {
          isLoaded();
        });
    } else {
      isLoading();
      body.accountId = getAccountData('accountId');
      GamificationService()
        .create({ body })
        .then(res => {
          const { status, instanceId } = res;
          isLoaded();
          let content = TestModeAddPersonalization;
          if (status === 'ACTIVE') {
            content = LiveModeUpdatePersonalization;
          }
          showNotification({
            content,
            className: 'notification-success',
          });
          browserHistory.push(
            `/behavioural-targeting/engagement/gamification/wof/edit/${instanceId}`,
          );
        })
        .finally(() => {
          isLoaded();
        });
    }
  };

  const validateAndSave = type => {
    const validation = validate();
    if (validation !== true) {
      const content = () => <p>{validation}</p>;
      showNotification({
        content,
        className: 'notification-fail',
      });
      return;
    }

    const displayStatus = type === 'test' ? 'Test' : 'Live';
    confirmationDialog({
      title: `Go ${displayStatus} the Campaign?`,
      content: `Are you sure you want to get the campaign ${displayStatus}?`,
      onConfirm: () => {
        goTestOrLive(type);
      },
    });
  };

  const editCampaign = (type, value) => {
    if (type === 'filters') {
      const filters = activeWOFDetails?.filters.filter(
        filter => filter.type !== value.type,
      );
      editActiveWOFDetails({
        filters: [...filters, value],
      });
      return;
    }
    editActiveWOFDetails({ [type]: value });
  };

  const duplicateEditHandler = useCallback(() => {
    if (mode === 'edit' || mode === 'duplicate') {
      isLoading();
      GamificationService()
        .get({ id: campaignId })
        .then(res => {
          const tempRes = { ...res };

          if (mode === 'duplicate') {
            if (tempRes.gamificationType === 'GENERIC') {
              tempRes.possibleRewards.map(detail => {
                detail.couponCodes.pop();
                detail.content = '';
                detail.description = '';
                detail.couponUrl = '';
              });
            }
            if (tempRes.gamificationType === 'UNIQUE') {
              if (
                activeWOFDetails?.possibleRewards.length >
                tempRes.possibleRewards.length
              ) {
                for (let i = 0; i < tempRes.possibleRewards.length; i++) {
                  tempRes.possibleRewards[i].content =
                    activeWOFDetails?.possibleRewards[i].content;
                  tempRes.possibleRewards[i].possibility =
                    activeWOFDetails?.possibleRewards[i].possibility;
                  tempRes.possibleRewards[i].couponCodes.splice(
                    0,
                    tempRes.possibleRewards[i].couponCodes.length,
                    ...activeWOFDetails.possibleRewards[i].couponCodes,
                  );
                }
                activeWOFDetails.possibleRewards.splice(
                  0,
                  tempRes.possibleRewards.length,
                );
                tempRes.possibleRewards.push(
                  ...activeWOFDetails.possibleRewards,
                );
              } else if (
                activeWOFDetails?.possibleRewards.length <
                tempRes.possibleRewards.length
              ) {
                const newArr = [];
                for (
                  let i = 0;
                  i < activeWOFDetails?.possibleRewards.length;
                  // eslint-disable-next-line no-plusplus
                  i++
                ) {
                  tempRes.possibleRewards[i].content =
                    activeWOFDetails?.possibleRewards[i].content;
                  tempRes.possibleRewards[i].possibility =
                    activeWOFDetails?.possibleRewards[i].possibility;
                  tempRes.possibleRewards[i].couponCodes.splice(
                    0,
                    tempRes.possibleRewards[i].couponCodes.length,
                    ...activeWOFDetails?.possibleRewards[i].couponCodes,
                  );
                  newArr.push(tempRes.possibleRewards[i]);
                }
                tempRes.possibleRewards.splice(
                  0,
                  tempRes.possibleRewards.length,
                  ...newArr,
                );
              } else {
                for (
                  let i = 0;
                  i < tempRes.possibleRewards.length;
                  // eslint-disable-next-line no-plusplus
                  i++
                ) {
                  tempRes.possibleRewards[i].content =
                    activeWOFDetails?.possibleRewards[i].content;
                  tempRes.possibleRewards[i].possibility =
                    activeWOFDetails?.possibleRewards[i].possibility;
                  tempRes.possibleRewards[i].couponCodes.splice(
                    0,
                    tempRes.possibleRewards[i].couponCodes.length,
                    ...activeWOFDetails.possibleRewards[i].couponCodes,
                  );
                }
              }
            }
            tempRes.instanceId = '';
            tempRes.name = `${tempRes.name} Copy`;
          }
          if (mode === 'edit') {
            setBreadCrumbName(tempRes.name);
          }
          setActiveWOFDetails(tempRes);
        })
        .finally(() => {
          isLoaded();
        });
    }
  }, []);

  useEffect(() => {
    const settingsTab = <SettingsTab mode={mode} />;
    const styleTab = <StyleTab />;

    setTabs(
      tabs.map(tab => {
        return {
          ...tab,
          children: tab.type === 'settings' ? settingsTab : styleTab,
        };
      }),
    );

    duplicateEditHandler();
  }, [mode]);

  useEffect(() => {
    return () => {
      resetActiveWOFDetails();
    };
  }, []);

  const setAdvancedConfigVisibility = (actionType = 'open') => {
    setIsAdvancedConfigShown(actionType === 'open');
  };

  const campaignActionButtonConstantWithFunctions = campaignActionButtonConstant.map(
    actionButton => {
      let actionButtonWithFunction;
      switch (actionButton.type) {
        case 'test':
        case 'live':
          actionButtonWithFunction = () => validateAndSave(actionButton.type);
          break;
        case 'advanceConfig':
          actionButtonWithFunction = setAdvancedConfigVisibility;
          break;
        default:
          actionButtonWithFunction = () => {};
          break;
      }
      return {
        ...actionButton,
        action: actionButtonWithFunction,
      };
    },
  );

  return (
    <>
      <SFYHeader
        pageTitle='Wheel of Fortune'
        breadCrumbOptions={getDynamicBreadCrumbOptions(breadCrumbName)}
      />

      <SFYWrapper>
        <StaticTab
          tabs={tabs}
          tabsSetter={newTabs => setTabs(newTabs)}
          alignCenter
        />
      </SFYWrapper>

      <SFYRightBar
        campaignActionButtonConstant={campaignActionButtonConstantWithFunctions}
        activeCampaign={activeWOFDetails}
        startDate={
          activeWOFDetails?.startDate === ''
            ? editCampaign('startDate', new Date().getTime())
            : new Date(activeWOFDetails?.startDate).getTime()
        }
        endDate={new Date(activeWOFDetails.endDate).getTime()}
        campaignSetter={editCampaign}
        isCampaignNameVisible
        isLimitVisible
        isDevicesVisible
        isPagesAllVisible
        isPagesUrlVisible
      />
      <AdvancedConfig
        campaignDetails={getWOFAdvancedConfigCampaignDetails(activeWOFDetails)}
        templates={templates}
        setTemplates={setTemplates}
        activeTabs={wofAdvancedConfig.activeTabs}
        params={wofAdvancedConfig.params}
        isShow={isAdvancedConfigShown}
        devices={activeWOFDetails?.devices || []}
        onHide={() => setAdvancedConfigVisibility('close')}
      />
    </>
  );
};

export default AddOrEdit;
