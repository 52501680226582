import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import styles from './SaveAsTemplateModal.module.scss';
import { t } from '../../../system/ui';
import { uiActions } from '../../../actions';
import { EmailBuilder as EmailBuilderServices } from '../services/emailServices';

const SaveAsTemplateModal = ({
  campaignInfo,
  templateId,
  mode,
  saveType,
  fromPathName = '',
  isCollection = false,
}) => {
  const [templateName, setTemplateName] = useState(
    `${campaignInfo?.templateName || ''}${
      saveType === 'saveAs' ? ' Copy' : ''
    }`,
  );
  const { isLoaded, isLoading } = uiActions;

  const saveAndContinue = async () => {
    isLoading();
    if (
      mode === 'edit' &&
      (saveType === 'save' || saveType === 'continue') &&
      !isCollection
    ) {
      EmailBuilderServices.updateWithInsideId({
        body: {
          ...campaignInfo,
          id: templateId,
          templateName,
        },
      }).then(({ id }) => {
        const notificationContent = () => (
          <div>{t('Template updated successfully.')}</div>
        );
        uiActions.showNotification({ content: notificationContent });
        if (fromPathName) {
          if (saveType === 'continue') {
            browserHistory.push({
              pathname: fromPathName,
              state: { templateName },
            });
          } else {
            browserHistory.push({
              pathname: `/email/templates/edit/${id}`,
              state: { campaignPathname: fromPathName },
            });
          }
        } else {
          browserHistory.push({
            pathname: `/email/templates/edit/${id}`,
          });
        }
      });
    } else {
      EmailBuilderServices.create({
        body: { ...campaignInfo, templateName },
      }).then(({ id }) => {
        const notificationContent = () => (
          <div>{t('Template created successfully.')}</div>
        );
        uiActions.showNotification({ content: notificationContent });
        if (fromPathName) {
          if (saveType === 'saveAs' || saveType === 'save') {
            browserHistory.push({
              pathname: `/email/templates/edit/${id}`,
              state: { campaignPathname: fromPathName },
            });
          } else {
            browserHistory.push({
              pathname: fromPathName,
              state: { templateName },
            });
          }
        } else {
          browserHistory.push({
            pathname: `/email/templates/edit/${id}`,
          });
        }
      });
    }
    isLoaded();
  };

  return (
    <div className={styles['modal-wrapper']}>
      <div className={styles['modal-overview-body']}>
        <div className={styles['modal-overview-body-info']}>
          <div className={styles['modal-overview-body-row']}>
            <div className={styles['modal-overview-body-title']}>
              {t('Template Name*')}
            </div>
            <div className={styles['modal-overview-body-text']}>
              <input
                type='text'
                id='template-name'
                placeholder='Template Name'
                onChange={e => setTemplateName(e.target.value)}
                value={templateName}
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles['modal-bottom-area']}>
        <button
          className={styles['action-button']}
          type='button'
          onClick={saveAndContinue}
        >
          {t(saveType === 'continue' ? 'Continue' : 'Save')}
        </button>
      </div>
    </div>
  );
};

export default SaveAsTemplateModal;
