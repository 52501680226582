import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import { decodeHtml } from '../../../../system/string';
import Icon from '../../../icon';
import Icons from '../../../icons';
import { t } from '../../../../system/ui';
import './AttributeComponents.scss';
import { onProductsInputChange } from '../../SFYRelatedProducts/Attributes/common/common-functions';
import { uiActions } from '../../../../actions';
import { searchMultipleProducts } from '../../../../modules/product/ajax';

const Product = props => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState('');

  const onChange = selectedValue => {
    const selectedProduct = selectedValue !== undefined ? selectedValue : '';
    setProduct(selectedProduct);

    props.onSelectChange(
      'product',
      selectedProduct,
      props.id,
      props.segmentType,
    );
  };

  const onInputChange = useCallback(
    value => onProductsInputChange(value, setProducts),
    [setProducts],
  );

  useEffect(() => {
    // eslint-disable-next-line dot-notation
    if (props.values['product'] && products.length === 0) {
      uiActions.isLoading();
      const idList = props.values?.product?.value;
      searchMultipleProducts(idList, respondedProducts => {
        if (Array.isArray(respondedProducts)) {
          const productOptions = respondedProducts.map(thisProduct => ({
            value: thisProduct.productId,
            label: decodeHtml(thisProduct.name),
          }));
          setProducts(productOptions);
          setProduct(productOptions);
        }
        uiActions.isLoaded();
      });
    }
  }, []);

  return (
    <div className='sfy-color'>
      <div className='sfy-color-left'>
        <div className='sfy-color-icoText'>
          <span className='sfy-color-icon'>
            <Icon name='product' color='#787774' />
          </span>
          <div className='sfy-color-titleSelectMain'>
            <div className='sfy-color-titleSelect'>
              <span className='sfy-color-title'>{t('Product')}</span>
            </div>
          </div>

          <Select
            value={product}
            options={products}
            name='product'
            clearable={false}
            onChange={onChange}
            onInputChange={onInputChange}
            className='criteria-field criteria-field-category'
            placeholder={t('Search Product')}
            multi
            shouldKeyDownEventCreateNewOption={e => {
              switch (e.keyCode) {
                case 9:
                case 13:
                  return !0;
                default:
                  return !1;
              }
            }}
          />
        </div>
      </div>
      <a
        onClick={() =>
          props.onFilterRemove(props.id, props.segmentType, props.type)
        }
        className='sfy-color-actions'
      >
        <Icons name='crossCircle' />
      </a>
    </div>
  );
};

export default Product;
