/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { uiActions } from "../../../actions";

import { Checkbox } from "../../../components/fields";

class Overlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: true,
      name: "overlay"
    };
    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    let overlay = this.props.campaign.overlay === "true";
    if (this.props.duplicate && this.props.duplicate.isDuplicate) {
      this.updateValue(this.props.duplicate.duplicatedValues[6]);
    } else {
      if (this.props.isRecommendationModule) {
        this.updateValue(
          this.props.campaign.actions[0].params.overlay === "true"
        );
      } else {
        this.updateValue(overlay);
      }
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      if (newProps.isRecommendationModule) {
        this.updateValue(
          newProps.campaign.actions[0].params.overlay === "true"
        );
      } else {
        this.updateValue(newProps.campaign.overlay === "true");
      }
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  onFieldChange() {
    this.updateValue(!this.state.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <span className="move-to-end">
        <Checkbox
          name="overlay"
          label="Overlay"
          checked={this.state.value}
          onChange={this.onFieldChange}
        />
      </span>
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  Overlay
);
