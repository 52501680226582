/**
 * @author Bilal Cinarli
 */

"use strict";

import api from "../../system/api";
import { $http } from "../../system/ajax";
import { getApiKey } from "../auth/user";
import { getState } from 'system/store';
import { hideBadResponseCat, showBadResponseCat } from 'actions/ui';

const getBanners = (data, callback) => {
  let globalApiKey = getApiKey() !== undefined ? "?apiKey=" + getApiKey() : "";
  $http(api.getEndPoint("bannerify/banner/list") + globalApiKey)
    .post(data, { type: "json" })
    .then(response => {
      if (getState().ui.badResponseCatVisible) {
        hideBadResponseCat();
      }
      callback(response);
    })
    .catch(err => {
      if (err.status === 429 || err.status === '429') {
        showBadResponseCat();
      }
    });
};

const getBanner = (data, callback) => {
  let globalApiKey = getApiKey() !== undefined ? "?apiKey=" + getApiKey() : "";
  $http(api.getEndPoint("bannerify/banner/single") + globalApiKey)
    .post(data, { type: "json" })
    .then(response => {
      if (getState().ui.badResponseCatVisible) {
        hideBadResponseCat();
      }
      callback(response);
    })
    .catch(err => {
      if (err.status === 429 || err.status === '429') {
        showBadResponseCat();
      }
    });
};

const getBannerCount = (data, callback) => {
  let globalApiKey = getApiKey() !== undefined ? "?apiKey=" + getApiKey() : "";
  $http(api.getEndPoint("bannerify/banner/count") + globalApiKey)
    .post(data, { type: "json" })
    .then(response => {
      if (getState().ui.badResponseCatVisible) {
        hideBadResponseCat();
      }
      callback(response);
    })
    .catch(err => {
      if (err.status === 429 || err.status === '429') {
        showBadResponseCat();
      }
    });
};

export { getBanners, getBanner, getBannerCount };
