import React from "react";

class IconRetention extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={this.state.width}
        height={this.state.height}
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-3.000000, -3.000000)"
            fill={this.state.color}
            fillRule="nonzero"
          >
            <path
              d="M16.4402035,21.6376526 L16.7231641,21.3546921 L18.4463939,23.0779219 L18.1634333,23.3608825 L16.4402035,21.6376526 Z M14.7283937,23.3476015 L15.0113048,23.0645916 L16.7348354,24.7875206 L16.4519242,25.0705306 L14.7283937,23.3476015 Z M12.9920978,25.0817415 L13.275009,24.7987315 L14.9985396,26.5216606 L14.7156284,26.8046705 L12.9920978,25.0817415 Z M24.8621134,13.2105677 L25.1450245,12.9275577 L26.8685551,14.6504868 L26.5856439,14.9334967 L24.8621134,13.2105677 Z M23.1498228,14.9244152 L23.432734,14.6414053 L25.1562645,16.3643343 L24.8733534,16.6473443 L23.1498228,14.9244152 Z M21.4135274,16.6585553 L21.6964386,16.3755454 L23.4199691,18.0984744 L23.1370579,18.3814844 L21.4135274,16.6585553 Z M5.94022667,18.28937 C2.53341999,14.8759331 2.53879481,9.34703334 5.95223167,5.94022667 C9.36566854,2.53341999 14.8945683,2.53879481 18.301375,5.95223167 L24.4679433,12.1188 L20.5623167,16.0244267 L14.3957483,9.85785833 C13.1370984,8.66206785 11.1546991,8.68744883 9.92707644,9.91507144 C8.69945383,11.1426941 8.67407285,13.1250934 9.86986333,14.3837433 L16.0124217,20.5503117 L12.106795,24.4559383 L5.94022667,18.28937 Z M9.50571167,21.0025 L12.130805,23.6275933 L15.1960817,20.5663183 L12.599,17.9252183 L9.50571167,21.0025 Z M12.106795,4.00742167 C8.81462455,4.00298049 5.84496765,5.98504323 4.58609599,9.02702418 C3.32722433,12.0690051 4.02783462,15.569945 6.36040167,17.893205 L9.08553667,20.6023333 L12.1508133,17.5370567 L9.42567833,14.8119217 C8.38851356,13.8707206 7.95430804,12.4346314 8.29624937,11.0764539 C8.63819067,9.71827632 9.70064317,8.65899293 11.0598361,8.32111045 C12.4190291,7.98322796 13.8538148,8.42172155 14.7919133,9.46169333 L17.5170483,12.1628183 L20.6023333,9.09754167 L17.8531883,6.39641667 C16.3320846,4.86838349 14.2628546,4.01244852 12.106795,4.01942667 L12.106795,4.00742167 Z M17.9372233,12.599 L20.5623167,15.2240933 L23.6275933,12.1588167 L21.02651,9.53372333 L17.9372233,12.599 Z"
              id="retention"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default IconRetention;
