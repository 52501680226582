import React from 'react';
import { t } from '../../../system/ui';
import { Calendar } from '../../fields';
import classes from './SFYScheduling.module.scss';
import {
  isBeforeNowByTimeZone,
  isValidStartDate,
  isValidCampaignEndDate,
  reformatDateByTimeZone,
} from '../../../system/date';
import { SFYCheckBox } from '../module-exports';

const StartEndDate = ({
  editCampaign,
  componentDetail,
  activeEmailDetails,
}) => {
  const handleDateChange = (type, date) => {
    let tempDate;
    const fixedType = type === 'endDateToggle' ? 'endDate' : type;
    switch (type) {
      case 'startDate':
        tempDate = date.valueOf();
        break;
      case 'endDate':
        tempDate = date.valueOf();
        break;
      case 'endDateToggle':
        if (date) {
          tempDate = activeEmailDetails.startDate + 60 * 60 * 1000; // After 1 hour from start date
          break;
        }
        tempDate = null;
        break;
      default:
        break;
    }

    editCampaign(fixedType, tempDate);
    if (
      type === 'startDate' &&
      activeEmailDetails?.endDate &&
      tempDate > activeEmailDetails.endDate
    ) {
      editCampaign('endDate', tempDate + 60 * 60 * 1000); // After 1 hour from start date
    }
  };

  return (
    <label
      className={`item item-stacked label-emphasise label-mail ${classes['sfy-scheduling-repeat']}`}
    >
      <span className={classes['sfy-label-wrapper']}>
        <span className={classes['sfy-label']}>{t('Start Date - Time')}</span>
      </span>
      <Calendar
        name='date'
        className={`item-field ${classes['sfy-scheduling-repeat']} ${classes['sfy-border']}`}
        checkPastDate
        value={activeEmailDetails?.startDate}
        isValidDate={isValidStartDate}
        onChange={date => handleDateChange('startDate', date)}
      />
      <SFYCheckBox
        label='End Date - Time'
        checked={!!activeEmailDetails?.endDate}
        searchActiveSetter={isChecked =>
          handleDateChange('endDateToggle', isChecked)
        }
      />
      {activeEmailDetails?.endDate && (
        // <>
        //   <span className={classes['sfy-label-wrapper']}>
        //     <span className={classes['sfy-label']}>{t('End Date - Time')}</span>
        //   </span>
        <Calendar
          name='date'
          className={`item-field ${classes['sfy-scheduling-repeat']} ${classes['sfy-border']}`}
          checkPastDate
          value={reformatDateByTimeZone(activeEmailDetails.endDate)}
          isValidDate={current => {
            return isValidCampaignEndDate(
              current,
              activeEmailDetails?.startDate,
            );
          }}
          onChange={date => handleDateChange('endDate', date)}
        />
        // </>
      )}
    </label>
  );
};

export default StartEndDate;
