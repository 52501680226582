import React from 'react';

const DelayComponent = ({ delayTime, children }) => {
  const [show, setShow] = React.useState(false);

  React.useEffect(
    () => {
      setTimeout(() => {
        setShow(true);
      }, delayTime);
    },
    [show],
  );

  if (!show) return null;

  return children;
};

export default DelayComponent;
