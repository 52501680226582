/**
 * @author Bilal Cinarli
 */

import { createAction } from '../system/store';
import { tryParse } from '../system/string';
import * as userActionTypes from '../constants/actions/user';

export const loggedUser = user => createAction('USER_IS_LOGGED', { user });

export const updateUser = user => createAction('UPDATE_USER', { user });

export const updateUserAccount = user =>
  createAction('UPDATE_ACCOUNT', { user });

export const updateSwitchAccount = account =>
  localStorage.setItem('account', JSON.stringify(account));

export const updateSwitchUser = user => {
  // TODO switchedUser hafizada tutan sistem
  // localStorage.setItem('_su', JSON.stringify(user));
  createAction('UPDATE_SWITCH_USER', { user });
};

export const userIntegrations = integrations => {
  createAction('GET_INTEGRATIONS', { integrations: tryParse(integrations) });
};

export const updateMailConf = conf => {
  createAction('UPDATE_MAIL_CONF', { conf });
};

export const resetMailConf = () => {
  createAction('RESET_MAIL_CONF');
};

export const updateUserCurrency = payload => {
  createAction('UPDATE_CURRENCY', payload);
};

export const updateRegion = (mainRegion, additionalCurrencyRegionList) => {
  createAction('UPDATE_REGION', { mainRegion, additionalCurrencyRegionList });
};

export const updateSearchProperty = searchProperty => {
  createAction('UPDATE_SEARCH_PROPERTY', searchProperty);
};

export const updatePersonalisationConfig = (
  featureStatus,
  personalisationConfig,
) => {
  createAction(
    'UPDATE_PERSONALISATION_CONFIG',
    featureStatus,
    personalisationConfig,
  );
};

export const updateRecommendationSettings = recommendationSettings => {
  createAction('UPDATE_RECOMMENDATION_SETTINGS', recommendationSettings);
};

const logoutUser = () => {
  localStorage.removeItem('lastLogin');
  // TODO switchedUser hafizada tutan sistem
  // localStorage.removeItem('_su');
  createAction(userActionTypes.default.LOG_OUT_USER);
};

export default {
  loggedUser,
  updateUser,
  updateUserAccount,
  updateSwitchAccount,
  updateSwitchUser,
  userIntegrations,
  updateMailConf,
  resetMailConf,
  updateUserCurrency,
  updateRegion,
  updateSearchProperty,
  updatePersonalisationConfig,
  updateRecommendationSettings,
  logoutUser,
};
