import React from 'react';
import PropTypes from 'prop-types';
import { TextArea } from '../../../../components/fields';

const CampaignMessage = ({ value, onChange }) => {
  return (
    <div className='form-element'>
      <span className='element-title'>Campaign Message*</span>
      <TextArea
        className='element-textarea'
        name='campaignMessage'
        value={value}
        onChange={e => onChange(e.target.value)}
        required
      />
    </div>
  );
};

CampaignMessage.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CampaignMessage;
