/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { t } from "../../../../system/ui";

import { wizardActions } from "../../../../actions";

class AdvancedConfigButton extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    e.preventDefault();

    this.props.isAdvancedConfigVisible
      ? wizardActions.hideAdvancedConfig()
      : wizardActions.showAdvancedConfig();
  }

  render() {
    return (
      <a className="button secondary-action one-whole" onClick={this.toggle}>
        {t("Advanced Configuration")}
      </a>
    );
  }
}

const MapStatesToProps = store => ({
  isAdvancedConfigVisible: store.wizard.isAdvancedConfigVisible
});

export default connect(MapStatesToProps)(AdvancedConfigButton);
