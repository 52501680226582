import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';

import {
  getAccountList,
  getUserList,
  getEgsVideos,
  getWelcomeVideo,
} from '../../reducers/managed-services/extraReducers';
import {
  isMainRequestsLoaded,
  showForm as showFormChange,
} from '../../reducers/managed-services';
import { uiActions } from '../../actions';

import Modal from '../../components/modal';
import Dialog from '../../components/dialog';
import Notification from '../../components/notification';
import Preloader from '../../components/layout/preloader';

import { openUrlInNewTab } from './utils';
import { loadSalesIqWidget, removeSalesIqWidget } from './salesIq';
import { componentRoots } from './msp-root';
import SideBarMenu from './SideBarMenu';
import SideBarContent from './SideBarContent';

const index = () => {
  const dispatch = useDispatch();
  const isLoadCompleted = useSelector(isMainRequestsLoaded);
  const pageClass = useSelector(state => state.ui.pageClass);
  const userState = useSelector(state => state.user.user);
  const { dialog, showDialog } = useSelector(state => state.dialog);
  const { modal, showModal } = useSelector(state => state.modal);
  const { notification, showNotification } = useSelector(
    state => state.notification,
  );
  const { showForm } = useSelector(state => state.managedServices.form);
  const [selectedMenu, setSelectedMenu] = useState(componentRoots[0]);
  const [previousMenu, setPreviousMenu] = useState();

  const selectMenuHandler = (id, tempUrl) => {
    if (tempUrl) {
      openUrlInNewTab(tempUrl);
    } else {
      if (selectedMenu.id === 99) {
        dispatch(showFormChange({ formName: 'addClient', status: false }));
      }
      setPreviousMenu(selectedMenu);
      setSelectedMenu(componentRoots.find(item => item.id === id));
    }
  };

  useEffect(() => {
    dispatch(getAccountList());
    dispatch(getUserList());
    dispatch(getEgsVideos(3));
    dispatch(getWelcomeVideo());

    // Zoho SalesIQ widget load and remove
    loadSalesIqWidget();

    return () => {
      removeSalesIqWidget();
    };
  }, []);

  useEffect(() => {
    if (isLoadCompleted) {
      uiActions.isLoaded();
    } else {
      uiActions.isLoading();
    }
  }, [isLoadCompleted]);

  useEffect(() => {
    if (showForm) {
      selectMenuHandler(99, '');
    } else if (previousMenu && previousMenu.id !== 99) {
      selectMenuHandler(previousMenu.id, previousMenu.tempUrl);
    }
  }, [showForm]);

  return (
    <>
      <div
        id='wrapper'
        className={`msp-wrap ${pageClass} ${(showDialog || showModal) &&
          'has-overlay-window'}`}
      >
        <Preloader />

        <div className='msp-container'>
          <SideBarMenu
            menuItems={componentRoots}
            selectedMenu={selectedMenu}
            selectMenuHandler={selectMenuHandler}
          />
          <div id='main' className='msp-main'>
            {selectedMenu.component && (
              <selectedMenu.component {...selectedMenu.props} />
            )}
            {/* {showForm ? (
              <AddClientForm formName={formName} />
            ) : (
              selectedMenu.component && <selectedMenu.component />
            )} */}
          </div>
          <SideBarContent user={userState} />
        </div>
      </div>

      {showDialog && <Dialog {...dialog} />}
      {showModal && <Modal {...modal} />}
      {showNotification && <Notification {...notification} />}
    </>
  );
};

export default index;
