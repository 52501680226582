/**
 * Created by mehmetyurtar on 1/13/18.
 */
import { createReducer } from "../system/store";
import * as categoriesActions from "../constants/actions/categories";

const initialState = {
  options: [],
  isSet: false
};

export default createReducer(initialState, {
  [categoriesActions.default.SET_CATEGORIES]: (state, payload) => {
    return Object.assign({}, state, {
      options: payload,
      isSet: true
    });
  },
  [categoriesActions.default.REMOVE_CATEGORIES]: state => {
    return Object.assign({}, state, {
      options: [],
      isSet: false
    });
  }
});
