import React from 'react';
import * as Components from '../../../../components/sfy-components/module-exports';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import { getAccount, isSwitchAccount } from '../../../../modules/auth/user';

const CampaignName = ({
  component,
  editCampaign,
  activeEmailDetails,
  emailType,
  readOnly,
}) => {
  const editParams = (fieldKey, fieldValue) => {
    editCampaign('params', {
      ...activeEmailDetails.params,
      [fieldKey]: fieldValue,
    });
  };

  const getOptions = comp => {
    const account = getAccount();

    if (comp.cardName === 'language') {
      let langList =
        account.additionalLanguages &&
        account.additionalLanguages.indexOf(account.mainLanguage) > -1
          ? account.additionalLanguages
          : [account.mainLanguage].concat(account.additionalLanguages);
      langList = langList.map(lang => {
        return { value: lang, label: lang };
      });
      langList.push({ value: 'SESSION_BASED', label: 'Session based' });

      return langList;
    }

    if (comp.cardName === 'currency') {
      let currencyList =
        account.additionalCurrencyList &&
        account.additionalCurrencyList.indexOf(account.mainCurrency.code) > -1
          ? account.additionalCurrencyList
          : [account.mainCurrency.code].concat(account.additionalCurrencyList);
      currencyList = currencyList.map(currency => {
        return { value: currency, label: currency };
      });
      currencyList.push({ value: 'SESSION_BASED', label: 'Session based' });

      return currencyList;
    }

    return comp.options;
  };

  return (
    <InputCardWrapper
      type='adaptiveBlock'
      cardTitle={component.title}
      labelFor='scheduling'
      labelClass='cardTitle'
    >
      <div style={{ display: 'flex', gap: '2rem' }}>
        {component.componentDetails.map(comp => {
          const Component = Components[comp.componentName];
          return (
            <Component
              key={comp.cardName}
              fieldValue={activeEmailDetails.params[comp.cardName]}
              componentDetail={comp}
              options={getOptions(comp)}
              editCampaign={editParams}
              activeEmailDetails={activeEmailDetails}
              emailType={emailType}
              readOnly={readOnly}
            />
          );
        })}
      </div>
    </InputCardWrapper>
  );
};

export default CampaignName;
