import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.replace('px', '')}px`;
};

const NewBadge = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 35'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      className='new-badge-icon'
      fill='none'
    >
      <path
        d='M28.046 0.000403259C28.5999 -0.0313745 29.2738 1.81824 29.749 4.39151H26.8601C26.8633 3.60425 26.9603 2.82017 27.149 2.05585C27.3606 1.19713 27.6488 0.0235144 28.046 0.000403259Z'
        fill='#891202'
      />
      <path
        d='M1.90295 0.000403259C1.34973 -0.0313745 0.675173 1.81824 0.199951 4.39151H3.09317C3.08957 3.60427 2.9926 2.82024 2.80428 2.05585C2.58762 1.19713 2.29873 0.0235144 1.90295 0.000403259Z'
        fill='#891202'
      />
      <path
        d='M28 0C27.9536 0 27.9065 0 27.8572 0C27.808 0 27.7637 0 27.7195 0H2.28054C2.23628 0 2.19059 0 2.14277 0C2.09494 0 2.04569 0 2 0C2.19274 0.384121 2.32765 0.707629 2.41188 0.923788C3.02649 2.49532 3.15855 3.83901 3.15855 4.48092V5.30612V23.3101V34.1911C3.15855 34.9754 4.14365 35.3004 4.58622 34.6599C7.08036 31.0429 10.8994 27.8436 14.9218 27.7845C19.0685 27.7231 22.8947 30.937 25.4195 34.6395C25.8585 35.2836 26.8472 34.9586 26.8472 34.1729V5.30612C26.8472 5.03057 26.8472 4.7555 26.8472 4.48092C26.8472 3.83901 26.9778 2.49532 27.5931 0.923788C27.6752 0.707629 27.8094 0.384121 28 0Z'
        fill='url(#paint0_linear_274_561)'
      />
      <path
        d='M8.15556 16.0447H7V11H8.97528L10.8198 15.2734V11H11.9754V16.0447H10.0001L8.15556 11.7713V16.0447Z'
        fill='white'
      />
      <path
        d='M16.7931 11V12.0451H14.0486V12.9457H16.5764V13.9907H14.0486V15.0018H16.8653V16.0469H12.8931V11H16.7931Z'
        fill='white'
      />
      <path
        d='M17.2092 11H18.3987L19.121 15.3023L19.9154 11H21.8553L22.6497 15.3023L23.372 11H24.5607L23.6218 16.0447H21.6776L20.8832 11.7713L20.0887 16.0447H18.146L17.2092 11Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_274_561'
          x1='14.9939'
          y1='1.42037'
          x2='14.657'
          y2='63.9867'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.19' stopColor='#CC4230' />
          <stop offset='0.786458' stopColor='#692820' />
        </linearGradient>
      </defs>
    </svg>
  );
};

NewBadge.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

NewBadge.defaultProps = {
  width: '30px',
  height: '35px',
};

export default NewBadge;
