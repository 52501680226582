import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LanguageChooser = ({
  langList,
  language,
  selectedLanguage,
  onSelectLanguage,
  hasError = false,
}) => {
  const approvedLangList = langList.filter(
    item => item.label !== 'Session based',
  );

  return (
    <div
      role='group'
      aria-label='outlined primary button group'
      className='campaign-language-selection-extended'
      style={{ marginTop: '1.5rem', marginBottom: '.5rem' }}
    >
      {approvedLangList.map((item, index) => (
        <button
          type='button'
          onClick={() => onSelectLanguage(item.label)}
          className={classNames('language-box', {
            'is-selected':
              selectedLanguage === item.label ||
              (index === 0 &&
                language === 'SESSION_BASED' &&
                selectedLanguage === ''),
            'is-disabled': language !== 'SESSION_BASED',
            'has-error': hasError,
          })}
          key={`lang-${item.label}`}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

LanguageChooser.defaultProps = {
  hasError: false,
};

LanguageChooser.propTypes = {
  langList: PropTypes.arrayOf(PropTypes.string).isRequired,
  language: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  selectedLanguage: PropTypes.string.isRequired,
  onSelectLanguage: PropTypes.func.isRequired,
};

export default LanguageChooser;
