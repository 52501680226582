import React from 'react';
import classNames from 'classnames';
import { t } from '../../system/ui';
import SegmentActions from '../../components/widget/segment-actions';
import { idPropFunc } from '../../system/id';

const title = {
  draft: 'Draft Segments',
  active: 'Live Segments',
  passive: 'Passive Segments',
};

export const segmentList = props => {
  return (
    <div className={classNames('widget-list-has-items is-toggled')}>
      <h3
        className={classNames('page-subtitle', {
          passive: props.status.toLowerCase() !== 'active',
        })}
      >
        {t(title[props.status])} ({props.segments.length})
      </h3>
      <ul className='widget-list'>
        {props.segments.map(segment => {
          return (
            <li
              className='widget-list-item'
              {...idPropFunc(props.id, 'segmentList')}
              key={segment.id}
            >
              <h2 className='widget-title'>
                {segment.name === 'Sleeping Opportunists'
                  ? 'Discount Seekers'
                  : segment.name}
              </h2>
              <SegmentActions segment={segment} id={props.id} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
