import React from 'react';
import { store } from '../../store';

export const Revenue = () => {
  return (
    <div>
      <p>
        This box shows the statistics about products that are purchased through
        Segmentify Recommendation Widgets and the effect of those purchases over
        your website’s overall revenue.
      </p>
      <ul>
        <li>
          <strong>Revenue:</strong> Shows the total monetary amount of products
          sold through recommendation widgets. Segmentify only tracks the
          products clicked from the recommendation widgets in the same session.
          When a visitor buys 2 products with only one of them directed from a
          recommendation widget, Segmentify only adds that one product to its
          revenue contribution.
        </li>
        <li>
          <strong>Purchases:</strong> Shows the number of purchases that include
          at least one product directed from Segmentify Recommendation Widgets.
        </li>
        <li>
          <strong>Purchased Products:</strong> Shows the total number of
          products that are sold through Segmentify Recommendation Widgets. If a
          visitor buys two of the same product after clicking to it in a
          recommendation widget, it is counted as two items.
        </li>
        <li>
          <strong>Average Order Value:</strong> Shows Segmentify Recommendation
          Widgets’ average amount of contribution to an order
        </li>
        <li>
          <strong>Conversion Rate:</strong> The percentage of products that have
          been purchased after being clicked in a Segmentify Recommendation
          widget.
        </li>

        <li>
          <strong>Contribution Ratio: </strong> Shows the ratio between
          Segmentify Recommendation Widgets’ contribution and the overall
          revenue. It is calculated by Revenue of the Widgets divided by Overall
          Revenue.
        </li>
      </ul>
      <p>
        Expected value for contribution ratio is between 7% to 12%. If the ratio
        is below this range, you should consider adding new recommendation
        widgets and personalization campaigns.
      </p>
    </div>
  );
};

export const AssistedRevenue = () => {
  const text = {
    head:
      'This box shows the statistics about products that are purchased through Searchandising and the effect of those purchases over your website’s overall revenue.',
    body: [
      {
        title: 'Assisted Revenue',
        text: `Shows the total monetary amount
          after using the Searchandising. Segmentify only tracks
          the products clicked after searching any keyword from the
          Searchandising in the same session.`,
      },
      {
        title: 'Purchases',
        text:
          'Shows the number of purchases that include after using Searchandising.',
      },
      {
        title: 'Purchased Products',
        text:
          'Shows the total number of products that are sold after using Searchandising. If a visitor buys two of the same product after using Searchandising, it is counted as two items.',
      },
      {
        title: 'Average Order Value',
        text:
          'Shows Segmentify Recommendation Widgets’ average amount of contribution to an order',
      },
      {
        title: 'Conversion Rate',
        text:
          'The percentage of products that have been purchased after being clicked in a Segmentify Recommendation widget',
      },
      {
        title: 'Contribution Ratio:',
        text: `Shows the ratio between Segmentify Searchandising assisted contribution and the overall revenue. It is calculated by Assisted Revenue divided by Overall Revenue.
          Expected value for contribution ratio is between 7% to 12%.`,
      },
    ],
  };
  return (
    <div>
      <p>{text.head}</p>
      <ul>
        {text.body.map(i => (
          <li>
            <strong>{i.title}:</strong> {i.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const Basket = () => {
  return (
    <div>
      <p>
        This box shows the statistics about products that are added to the
        basket through Segmentify Recommendation Widgets.
      </p>
      <ul>
        <li>
          <strong>Products:</strong> Shows how many products are added to the
          basket through Segmentify Recommendation Widgets. This is the total
          number of items added to the basket. If a visitor adds two of the same
          product after clicking to a recommendation widget, it is counted as
          two items.
        </li>
        <li>
          <strong>Total Amount: </strong> Shows the total monetary amount of
          items added to the basket through recommendation widgets
        </li>
        <li>
          <strong>Add to Basket Rate:</strong> Shows the ratio between the
          number of products that are added to the basket and the the number of
          products that are clicked through the recommendation widgets. It is
          calculated by dividing Products Added to the Basket to Products
          Clicked
        </li>
      </ul>
      <p>
        If this ratio is low, you should consider updating the recommendation
        types and filters of the products to create the best match for visitor’s
        preferences.
      </p>
    </div>
  );
};

export const Interaction = () => {
  return (
    <div>
      <p>
        This box shows the statistics about user interaction with Segmentify
        Recommendation Widgets.
      </p>
      <ul>
        <li>
          <strong>Clicks:</strong> Shows how many times the products inside the
          recommendation widgets are clicked by your visitor.
        </li>
        <li>
          <strong>CTR:</strong> Click-through rate shows the ratio between the
          number of clicks on a recommendation widget and the number of views by
          your visitors. It is calculated by dividing Clicks to Views.
        </li>
      </ul>
      <p>
        If this ratio is low, you should consider changing the rules and filters
        of the recommendations.
      </p>
    </div>
  );
};

export const Widget = () => {
  return (
    <div>
      <p>
        This box shows the statistics about Segmentify Recommendation Widgets
      </p>
      <ul>
        <li>
          <strong>Views:</strong> Indicate the total number of recommendation
          widgets on your website that have actually been seen by your visitors.
        </li>
        <li>
          <strong>Impressions:</strong> Indicates the total number of pageviews
          with a recommendation widget on the page. Whether the widget was seen
          by your visitor is irrelevant in this count.
        </li>
        <li>
          <strong>View Rate:</strong> The ratio between the number of views of
          the recommendation widgets over the total amount of pageviews with
          widgets on the page. It is calculated by dividing Views to
          Impressions.
        </li>
      </ul>
      <p>
        If this ratio is low, you should consider placing the recommendation
        widgets higher up on your page to increase the chances of it being
        scrolled into the visible area by your visitors.
      </p>
    </div>
  );
};

export const Performance = () => {
  return (
    <div>
      <p>
        It shows the account's performance based on the selected date range.
      </p>
      <ul>
        <li>
          <strong>Rising:</strong> Accounts which were below target in the
          previous “last 7 days” but now meet the target in the “last 7 days.”
        </li>
        <li>
          <strong>Falling:</strong> Accounts which met the target in the
          previous “last 7 days” but are now under the target in the “last 7
          days”.
        </li>
        <li>
          <strong>Still Under Target:</strong> Accounts that have been below the
          target in the “last 7 days” and in the previous consecutive “last 7
          days”.
        </li>
        <li>
          <strong>Well Performing:</strong> Accounts that have been above the
          target in the “last 7 days” and in the previous consecutive “last 7
          days”.
        </li>
        <li>
          <strong>Declining Trend:</strong> Accounts that are above the target
          but have performed poorly compared to the previous last “7 days” by
          x%.
        </li>
      </ul>
    </div>
  );
};

export const gaDiff = () => {
  return (
    <div>
      <p>
        The GA Difference metric shows the difference between the sales that
        Segmentify counts and the sales that Google Analytics 4 counts
      </p>
      <p>
        When the date range is chosen as “weeks”, purchases are compared based
        on 30 days, whereas, when the date range is chosen as "days", purchases
        are compared based on 5 days.
      </p>
      <p>Please don't forget that GA metrics comes from 2 days ago.</p>
    </div>
  );
};

export const revenuegraph = () => {
  const userLang = store.getState().ui.language;
  if (userLang === 'tr') {
    return (
      <p>
        Seçtiğiniz tarih aralığında, sitenizde gerçekleşen toplam satış geliri
        ile Segmentify önerisi ile gerçekleşen satış geliri karşılaştırmalı
        olarak gösterilmektedir.
      </p>
    );
  }
  return (
    <p>
      This bar bar chart illustrates you the overall sales revenue generated on
      your website in comparison to the Segmentify contribution within the
      selected date range.
    </p>
  );
};

export const purchasegraph = lang => {
  const userLang = store.getState().ui.language;
  if (userLang === 'tr') {
    return (
      <p>
        Seçtiğiniz tarih aralığında sitenizde gerçekleşen toplam satış işlem
        adedi ile Segmentify önerisi ile satın alınan bir ya da birden fazla
        ürünün içinde bulunduğu satış işlem adedi karşılaştırmalı olarak
        gösterilmektedir. Bir satın alma işlemi içinde bir ya da birden fazla
        ürün yer alabilir. Ziyaretçiniz, Segmentify ile önerilen bir ürüne
        tıklayarak aynı oturum içinde satın alma yaparsa, bu ürüne ait satış
        Segmentify katkısı olarak sayılır.
      </p>
    );
  }
  return (
    <p>
      This bar chart illustrates you the total number of purchase transactions
      on your website in comparison to the ones that include items that are
      recommended by Segmentify widget within the selected date range. A
      purchase transaction may include one or more products. If a visitor clicks
      on a product recommended by Segmentify and purchase in the same session,
      it counts as Segmentify contribution.
    </p>
  );
};

export const pagegraph = lang => {
  const userLang = store.getState().ui.language;
  if (userLang === 'tr') {
    return (
      <p>
        Seçtiğiniz tarih aralığında, toplam sayfa görüntüleme adedinin sayfa
        bazında kırılımı gösterilmektedir.
      </p>
    );
  }
  return (
    <p>
      This pie chart illustrates the breakdown of the total pageviews by pages
      within selected date range.
    </p>
  );
};

export const devicegraph = lang => {
  const userLang = store.getState().ui.language;
  if (userLang === 'tr') {
    return (
      <p>
        Seçtiğiniz tarih aralığında, toplam sayfa görüntüleme adedinin cihaz
        bazında kırılımı gösterilmektedir.
      </p>
    );
  }
  return (
    <p>
      This pie chart illustrates you the breakdown of your page views by device
      only within the selected date range.
    </p>
  );
};

export const audiencegraph = lang => {
  const userLang = store.getState().ui.language;
  if (userLang === 'tr') {
    return (
      <div>
        <p>
          Seçtiğiniz tarih aralığında, sitenize gelen tekil ziyaretçilerin
          davranışları rakamlarla gösterilmektedir.
        </p>
        <ul>
          <li>Sitenize toplam gelen tekil ziyaretçi adedi</li>
          <li>Bu ziyaretçilerin açtığı toplam oturum adedi</li>
          <li>Açılan oturumlarda görüntülenen toplam ürün adedi</li>
          <li>Görüntülenen toplam sayfa sayısı</li>
        </ul>
      </div>
    );
  }
  return (
    <div>
      <p>
        This funnel view illustrates the figures reflecting your visitors’
        behavior on your website.
      </p>
      <ul>
        <li>
          How many unique visitors your website has within selected time period?
        </li>
        <li>How many sessions do these unique visitors open?</li>
        <li>How many products have they viewed?</li>
        <li>How many pages have they viewed?</li>
      </ul>
    </div>
  );
};

export const segmentifygraph = lang => {
  const userLang = store.getState().ui.language;
  if (userLang === 'tr') {
    return (
      <p>
        Seçtiğiniz tarih aralığında, Segmentify ile önerilen ürünlere ait satış
        hunisinin dönüşüm adet ve oranları gösterilmektedir.
      </p>
    );
  }
  return (
    <p>
      This graph illustrates you the top to down sales funnel conversion figures
      of the products recommended by Segmentify within the selected date range.
    </p>
  );
};

export const PageView = () => {
  return (
    <div>
      <p>
        Segmentify tags and tracks all your pages with its various page types.
        This box shows the overall and the breakdown page views and ratios.
      </p>
      <p>
        Segmentify groups pages as Home Page, Product Page, Category Page,
        Basket Page and Other Pages (Empty Search, 404 Pages, Brand Pages,
        Custom Pages etc.)
      </p>
      <ul>
        <li>
          <strong>Page View:</strong>
          Total page views of your website.
        </li>
        <li>
          <strong>Home Page: </strong>
          The number of views your website had on the main page. The percentage
          shows the % of the overall pageview coming from the home page.
        </li>
        <li>
          <strong>Product Page: </strong>
          The number of views of your products. The percentage shows the % of
          the overall pageview coming from the product page.
        </li>
        <li>
          <strong>Category Page: </strong>
          The number of views of your Category Pages and Product List Pages. The
          percentage shows the % of the overall pageview coming from the
          category page.
        </li>
        <li>
          <strong>Basket Page: </strong>
          The number of views of your basket page. The percentage shows the % of
          the overall pageview coming from the basket page.
        </li>
        <li>
          <strong>Other Pages: </strong>
          The number of views on other pages in your site. The percentage shows
          the % of the overall pageview coming from other pages.
        </li>
      </ul>
    </div>
  );
};

export const Device = () => {
  return (
    <div>
      <p>
        This box shows from which devices your visitors are entering your
        website. And you can see the usage rate according to the devices. The
        detailed statistics for mobile devices on the Android Apps, iOS Apps and
        Mobile Web breakdown could also be seen here.
      </p>
    </div>
  );
};

export const Audience = () => {
  return (
    <div>
      <p>
        This box shows the statistics based on your website’s visitor traffic.
      </p>
      <ul>
        <li>
          <strong>Average Sessions: </strong>
          The average number of unique sessions
        </li>
        <li>
          <strong>Unique Visitors: </strong>
          Unique visitors refers to the number of distinct individuals visiting
          your website, regardless of how often they visit.{' '}
        </li>
        <li>
          <strong>Unique Sessions: </strong>
          The total number of visits from unique visitors
        </li>
        <li>
          <strong>Pages/Sessions: </strong>
          Ratio of page views to sessions.
        </li>
        <li>
          <strong>Product/Sessions: </strong>
          Ratio of product views to sessions.
        </li>
      </ul>
    </div>
  );
};

export const RevenueOverall = () => {
  return (
    <div>
      <p>This box shows all purchase based metrics of your website.</p>
      <ul>
        <li>
          <strong>Revenue (Overall): </strong>
          Shows the total monetary amount of products sold on your website
        </li>
        <li>
          <strong>Purchases: </strong>
          Shows the total number of orders given on your website
        </li>
        <li>
          <strong>Purchased Products: </strong>
          Shows the total number of products sold on your website.
        </li>
        <li>
          <strong>Average Order Value: </strong>
          Shows the average monetary value of orders given through your website.
        </li>
      </ul>
    </div>
  );
};

export const AccountStatus = ({ type }) => {
  const renderDynamicContent = () => {
    let message;
    let ctx;
    switch (type) {
      case 'CHURN':
      case 'FAILED':
        message =
          'Your account has been deactivated. If you want to get access & support please contact us.';
        ctx = 'Contact Us';
        break;
      case 'PANEL_RESTRICTED':
        message =
          'This account has been locked and access restricted due to payment problems. If urgent action is required, contact Umut or Melisa.';
        ctx = 'Contact Us';
        break;
      case 'ONHOLD':
        message =
          'Your account has been deactivated due to unpaid invoices. Please contact your account manager.';
        ctx = 'Contact Your Account Manager';
        break;
      default:
        return '';
    }

    return {
      message,
      ctx,
    };
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p>{renderDynamicContent()?.message}</p>
        <button
          onClick={() =>
            window.location.assign('mailto: success@segmentify.com')
          }
          className='account-filters-comp__actions__update'
          style={{
            marginTop: '10px',
            padding: '12px 20px',
          }}
          type='submit'
        >
          {renderDynamicContent()?.ctx}
        </button>
      </div>
    </>
  );
};

export default {
  Widget,
  Basket,
  Revenue,
  AssistedRevenue,
  Interaction,
  revenuegraph,
  purchasegraph,
  pagegraph,
  devicegraph,
  audiencegraph,
  segmentifygraph,
  PageView,
  Device,
  Audience,
  RevenueOverall,
};
