/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-named-as-default-member */
/**
 * @author Bilal Cinarli
 */

import api from '../../system/api';
import { $http, plainGet } from '../../system/ajax';
import { getStartEndDate } from '../../system/date';
import { getApiKey, getUserRestUrl } from '../auth/user';

const getCampaignReport = (instance, interval, callback, isv2 = false) => {
  const opts = getStartEndDate(interval);
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  opts.instanceid = instance;
  opts.limit = 2000;
  opts.interval = 'total';
  if (isv2) {
    $http(api.getEndPoint('report/campaign_trend_v2') + globalApiKey)
      .get(opts)
      .then(response => {
        callback(response);
      });
  } else {
    $http(api.getEndPoint('report/campaign_trend') + globalApiKey)
      .get(opts)
      .then(response => {
        callback(response);
      });
  }
};

const getReports = (data, boundaries, callback) => {
  const opts = boundaries;
  const globalApiKey = `&apiKey=${getApiKey()}`;
  let uri = '?';
  let argcount = 0;

  for (const key in opts) {
    if (opts.hasOwnProperty(key)) {
      if (argcount++) {
        uri += '&';
      }
      uri += `${encodeURIComponent(key)}=${encodeURIComponent(opts[key])}`;
    }
  }

  $http(`${api.getRestEndPoint('v2/campaign')}/report${uri}${globalApiKey}`)
    .post(data, { type: 'json' })
    .then(response => callback(response));
};

const getCampaignHistory = (instance, callback) => {
  const globalApiKey = getApiKey() !== undefined ? getApiKey() : '';
  plainGet(
    `${api.getEndPoint('campaign/history')}&apiKey=${globalApiKey}`.replace(
      '{CAMPAING_ID}',
      instance,
    ),
    callback,
  );
};

const getEngagementHistory = (instance, callback) => {
  const opts = {};
  opts.apiKey = getApiKey();
  opts.withOps = true;
  $http(
    api
      .getRestEndPoint('v2/campaignHistory')
      .replace('{CAMPAIGN_ID}', instance),
  )
    .get(opts)
    .then(response => {
      callback(response);
    });
};

const getNewsletterReports = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  const opts = {};
  $http(api.getEndPoint('report/newsletters_trend') + globalApiKey)
    .get(opts)
    .then(response => {
      callback(response);
    });
};

const getNewsletterReport = (instanceId, callback, startDate, endDate) => {
  const globalApiKey =
    getApiKey() !== undefined ? `&apiKey=${getApiKey()}` : '';
  plainGet(
    `${api.getEndPoint(
      'report/newsletter_trend',
    )}?instanceId=${instanceId}${globalApiKey}&startDate=${startDate ||
      ''}&endDate=${endDate || ''}`,
    callback,
  );
};

const exportNewsletterReport = (campaignName, authToken, switchUser) => {
  return resultUrl;
};

const getPersonalizationReport = (
  instance,
  boundaries,
  campaignType,
  callback,
) => {
  const opts = boundaries;
  opts.apiKey = getApiKey();
  opts.campaignType = campaignType;
  opts.preventAbort = false;
  $http(`${api.getRestEndPoint('v2/campaign')}/report`)
    .get(opts)
    .then(response => {
      callback(response);
    });
};

const getGrantedPermissionCount = callback => {
  const opts = {};
  opts.apiKey = getApiKey();
  opts.preventAbort = false;
  $http(`${getUserRestUrl()}subscriptions/count`)
    .get(opts)
    .then(response => {
      callback(response);
    });
};

const getEmailCampaigns = callback => {
  $http(`${api.getRestEndPoint('emails/list')}?apiKey=${getApiKey()}`)
    .get()
    .then(function(response) {
      callback(response);
    });
};

const getSinglePersonalizationReport = (instance, boundaries, callback) => {
  const opts = boundaries;
  opts.apiKey = getApiKey();
  $http(`${api.getRestEndPoint('v2/campaign')}/${instance}/report`)
    .get(opts)
    .then(response => {
      callback(response);
    });
};

const getSinglePersonalizationReportInterval = (
  instance,
  boundaries,
  type,
  callback,
) => {
  const opts = boundaries;
  opts.apiKey = getApiKey();
  opts.type = type;
  $http(`${api.getRestEndPoint('v2/campaign')}/${instance}/report`)
    .get(opts)
    .then(response => {
      callback(response);
    });
};

const getRangedReport = (instance, boundaries, callback, isv2 = false) => {
  const opts = boundaries;
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  opts.interval = 'total';
  opts.instanceid = instance;
  opts.limit = 2000;

  if (isv2) {
    $http(api.getEndPoint('report/campaign_trend_v2') + globalApiKey)
      .get(opts, { preventAbort: false })
      .then(response => {
        callback(response);
      });
  } else {
    $http(api.getEndPoint('report/campaign_trend') + globalApiKey)
      .get(opts, { preventAbort: false })
      .then(response => {
        callback(response);
      });
  }
};

const getRangedReports = (
  instanceIds,
  boundaries,
  callback,
  options,
  isv2 = false,
  accountApiKey = '',
) => {
  let opts;
  if (boundaries === 'weekly') {
    opts = getStartEndDate('weekly');
  } else if (boundaries === 'lastSevenDays') {
    opts = getStartEndDate('lastSevenDays');
  } else {
    opts = boundaries;
  }
  opts.interval = 'total';
  opts.limit = 2000;
  opts = { ...opts, ...options };
  let uri = '?';
  let argcount = 0;
  let globalApiKey = '';
  if (accountApiKey !== '') {
    globalApiKey = `&apiKey=${accountApiKey}`;
  } else {
    globalApiKey = `&apiKey=${getApiKey()}`;
  }
  for (const key in opts) {
    if (opts.hasOwnProperty(key)) {
      if (argcount++) {
        uri += '&';
      }
      uri += `${encodeURIComponent(key)}=${encodeURIComponent(opts[key])}`;
    }
  }
  if (isv2) {
    $http(api.getEndPoint('report/campaign_trends_v2') + uri + globalApiKey)
      .post(instanceIds, { type: 'json' })
      .then(response => {
        callback(response);
      });
  } else {
    $http(api.getEndPoint('report/campaign_trends') + uri + globalApiKey)
      .post(instanceIds, { type: 'json' })
      .then(response => {
        callback(response);
      });
  }
};

const getRangedReportInterval = (
  instance,
  boundaries,
  interval,
  callback,
  isv2 = false,
) => {
  const opts = boundaries;
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  opts.interval = interval;
  opts.instanceid = instance;
  opts.limit = 2000;
  if (isv2) {
    $http(api.getEndPoint('report/campaign_trend_v2') + globalApiKey)
      .get(opts, { preventAbort: false })
      .then(response => {
        callback(response);
      });
  } else {
    $http(api.getEndPoint('report/campaign_trend') + globalApiKey)
      .get(opts, { preventAbort: false })
      .then(response => {
        callback(response);
      });
  }
};

const getEmailStatsBounceBackReport = async ({
  campaignId,
  startDate,
  endDate,
  isExport = false,
}) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';

  const endPointChange = isExport
    ? 'emails/bounceBackReportExport'
    : 'emails/bounceBackReport';

  return await $http(
    `${api.getRestEndPoint(
      endPointChange,
    )}/${campaignId}${globalApiKey}&startDate=${startDate}&endDate=${endDate}`,
  )
    .get()
    .then(response => {
      return response;
    });
};

export {
  getCampaignReport,
  getCampaignHistory,
  getEngagementHistory,
  getPersonalizationReport,
  getGrantedPermissionCount,
  getNewsletterReports,
  getNewsletterReport,
  exportNewsletterReport,
  getSinglePersonalizationReport,
  getSinglePersonalizationReportInterval,
  getRangedReport,
  getRangedReports,
  getRangedReportInterval,
  getEmailCampaigns,
  getReports,
  getEmailStatsBounceBackReport,
};
