import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { wizardActions } from "../../../actions";
import {
  criterionTypeButtons,
  exclusionButtons,
  inclusionButtons,
} from "../../../constants/datamaps/wizard";

import Icon from "../../../components/icon";
import Icons from "../../../components/icons";
import Criteria from "../../../components/wizard/criteria";
import Excludes from "../../../components/wizard/excludes";
import Includes from "../../../components/wizard/includes";
import CriteriaIntelligent from "../../../components/wizard/criterion-intelligent";

import { t } from "../../../system/ui";

class PushLimitedRecommendations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      excludes: [],
      shuffle: true,
      hideExcludes: true,
      hideIncludes: true,
      showSingleProductErr: false,
      hasError: false,
      showRequiredError: false,
      includeTypes: [],
      excludeTypes: []
    };

    this.button = this.button.bind(this);
    this.addInclusionRow = this.addInclusionRow.bind(this);
    this.addExclusionRow = this.addExclusionRow.bind(this);
    this.validate = this.validate.bind(this);
    this.toggleFilterAction = this.toggleFilterAction.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.excludes !== newProps.excludes) {
      if (newProps.excludes.length > 0) {
        this.setState({
          hideExcludes: false
        });
      }
    }

    if (newProps.includes.length > 0) {
      this.setState({
        hideIncludes: false
      });
    }

    if (newProps.criteria.length < 1) {
      this.setState({
        showSingleProductErr: false
      });
    }

    if (this.props.includes !== newProps.includes) {
      this.setState({
        includeTypes: newProps.includes.map(include => include.type)
      });
    }

    if (this.props.excludes !== newProps.excludes) {
      this.setState({
        excludeTypes: newProps.excludes.map(exclude => exclude.type)
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (
      this.props.criteria !== oldProps.criteria ||
      this.props.excludes !== oldProps.excludes
    ) {
      this.validate();
    }
  }

  toggleFilterAction(action) {
    this.setState({ [action]: false }, () => {
      let el =
        action === "hideIncludes" ? "includesToScroll" : "excludesToScroll";
      el = this.refs[el];
      //this.scrollToElement(el, action);
    });
  }

  validate() {
    if (this.props.excludes.length === 0 && this.props.criteria.length === 0) {
      this.setState({
        hasError: true,
        showRequiredError: true
      });
    } else {
      this.setState({
        hasError: false,
        showRequiredError: false
      });
    }
  }

  addInclusionRow(type) {
    let current = this.props.includes;
    current = current.concat({ id: Math.random(), type: type, values: {} });
    wizardActions.addInclusion(current);
  }

  addExclusionRow(type) {
    let current = this.props.excludes;
    current = current.concat({ id: Math.random(), type: type, values: {} });
    wizardActions.addExclusion(current);
  }

  button(item, type, cb, filterType) {
    let currentIcon = "";
    if (
      item.icon === "excludeLabel" ||
      item.icon === "excludeGender" ||
      item.icon === "excludeCustom" ||
      item.iconSvg
    ) {
      currentIcon = (
        <Icons
          name={item.icon}
          color={
            item.icon.indexOf("asteriks") < 0 && item.icon.indexOf("brain") < 0
              ? "#787779"
              : "#fff"
          }
        />
      );
    } else {
      currentIcon = <Icon name={item.icon} />;
    }
    let isSelectedCriteria =
      (filterType === "inclusion" &&
        this.state.includeTypes.indexOf(type) > -1) ||
      (filterType === "exclusion" &&
        this.state.excludeTypes.indexOf(type) > -1);
    return (
      <a
        key={type}
        className={classNames("button", item.className, {
          "include-exclude-selected": isSelectedCriteria,
          "custom-include-exclude-selected": type === "custom"
        })}
        onClick={cb ? cb.bind(null, type) : null}
      >
        {isSelectedCriteria && (
          <span>
            <Icons name="checkboxSelected" width="15" height="15" />
          </span>
        )}
        {currentIcon}
        {t(item.title)}
      </a>
    );
  }

  isAllSwitchedOff = () => {
    const steps = ['FIRST_REMINDER', 'SECOND_REMINDER', 'ALTERNATIVE_PRODUCTS', 'COUPON'];
    const { abandon } = this.props;
    let allSwitchedOff = true;
    steps.map(step => {
      if (abandon[step].active === true) {
        allSwitchedOff = false;
      }
    });
    return allSwitchedOff;
  }

  render() {
    const { title, subTitle } = this.props;
    let criterionButtonsToShow = criterionTypeButtons;
    let Component = CriteriaIntelligent;
    return (
      <div>
        <li className="item-title-field">
          <div className="wizard-comp">
            <h3 className="wizard-comp-title item-title-sub item-title-sub__rec">
              {title || t("Recommendation")}
            </h3>
            <p className="wizard-comp-description">
              {subTitle ||
                t(
                  "Select from these algorithms to create your recommendations based on your needs."
                )}
            </p>
            <div id="scroll-container">
              <div className="wizard-input-types">
                {Object.keys(criterionButtonsToShow).map(item => {
                  return this.button(criterionButtonsToShow[item], item, false);
                })}
                <div className="open-filters-buttons">
                  <div
                    className="open-filters open-filters--inclusion"
                    onClick={this.toggleFilterAction.bind(null, "hideIncludes")}
                  >
                    <Icons name="plusCircleSmall" color="#787779" />
                    {t("Include Filters")}
                  </div>

                  <div
                    className="open-filters open-filters--exclusion"
                    onClick={this.toggleFilterAction.bind(null, "hideExcludes")}
                  >
                    <Icons name="minusCircleSmall" color="#787779" />
                    {t("Exclude Filters")}
                  </div>
                </div>
              </div>
            </div>
            <div className="wizard-input-type-wrapper wizard-input-criteria wizard-email-input-type-wrapper">
              <div className="wizard-criteria wizard-items-container">
                <div className="wizard-item-draggable wizard-item-cards-draggable">
                  <Criteria
                    isPushModule={true}
                    campaign={this.props.campaign}
                  />
                  {this.isAllSwitchedOff() &&
                    <div>
                      <span className="item-error has-error">{t('You should activate at least one step from Cart Abandonment Flow.')}</span>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div
            className={classNames("wizard-comp wizard-comp-filters", {
              "is-hidden": this.state.hideIncludes
            })}
            ref="includesToScroll"
          >
            <h3 className="wizard-comp-title section-title section-title-criteria">
              {t("Criteria")}
            </h3>
            <p className="wizard-comp-description">
              {t(
                "Select from these algorithms to create your recommendations based on your needs."
              )}
            </p>
            {Object.keys(inclusionButtons).map(item => {
              return this.button(
                inclusionButtons[item],
                item,
                this.addInclusionRow,
                "inclusion"
              );
            })}
            <div className="wizard-includes wizard-input-type-wrapper">
              <div className="wizard-criteria wizard-items-container">
                <Includes />
              </div>
            </div>
          </div>
        </li>
        <li>
          <div
            className={classNames("wizard-comp wizard-comp-filters", {
              "is-hidden": this.state.hideExcludes
            })}
            ref="excludesToScroll"
          >
            <h3 className="wizard-comp-title section-title">{t("Excludes")}</h3>
            <p className="wizard-comp-description">
              {t(
                "Select from these algorithms to create your recommendations based on your needs."
              )}
            </p>
            {Object.keys(exclusionButtons).map(item => {
              return this.button(
                exclusionButtons[item],
                item,
                this.addExclusionRow,
                "exclusion"
              );
            })}
            <div className="wizard-includes wizard-input-type-wrapper">
              <div className="wizard-criteria wizard-items-container">
                <Excludes />
              </div>
            </div>
          </div>
        </li>
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  validate: store.ui.validate,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes,
  abandon: store.abandon
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  PushLimitedRecommendations
);
