import { $http } from '../../system/ajax';
import { getEndPoint, getRestEndPoint } from '../../system/api';
import { getStartEndDate } from '../../system/date';
import { getApiKey } from '../auth/user';

export const getEmails = (callback, options, predefinedApiKey) => {
  const apiKey = predefinedApiKey || getApiKey();
  $http(`${getEndPoint('emails/list')}?apiKey=${apiKey}`)
    .get(false, options)
    .then(response => {
      callback(response);
    });
};

export const getActiveEmails = (callback, options, predefinedApiKey) => {
  const apiKey = predefinedApiKey || getApiKey();
  $http(`${getEndPoint('emails/activeList')}?apiKey=${apiKey}`)
    .get(false, options)
    .then(response => {
      callback(response);
    });
};

export const getActiveUsers = (callback, boundaries, predefinedApiKey) => {
  const apiKey = predefinedApiKey || getApiKey();
  $http(`${getRestEndPoint('emails/activeUsers')}?apiKey=${apiKey}`)
    .get(boundaries)
    .then(response => {
      callback(response);
    });
};

export const updateEmailStatus = (status, instanceId, callback) => {
  $http(
    `${getRestEndPoint(
      'emails/updatestatus',
    )}/${instanceId}/${status}?apiKey=${getApiKey()}`,
  )
    .post()
    .then(response => {
      callback(response);
    });
};

export const getRangedEmailReports = (
  instanceIds,
  boundaries,
  callback,
  options,
  predefinedApiKey,
) => {
  let opts;
  const apiKey = predefinedApiKey || getApiKey();

  if (boundaries === 'weekly') {
    opts = getStartEndDate('weekly');
  } else {
    opts = boundaries;
  }

  opts.interval = 'total';
  opts.limit = 2000;

  opts = { ...opts, ...options };
  let uri = '?';
  let argcount = 0;
  for (const key in opts) {
    if (opts.hasOwnProperty(key)) {
      if (argcount++) {
        uri += '&';
      }
      uri += `${encodeURIComponent(key)}=${encodeURIComponent(opts[key])}`;
    }
  }
  $http(`${getEndPoint('report/emailMultiReport') + uri}&apiKey=${apiKey}`)
    .post(instanceIds, { type: 'json' })
    .then(response => {
      callback(response);
    });
};

export const getEmailCampaign = (instanceId, callback) => {
  $http(
    `${getEndPoint('emails/getCampaign')}/${instanceId}?apiKey=${getApiKey()}`,
  )
    .get(false)
    .then(response => {
      callback(response);
    });
};

export const getEmailCampaignHistory = (instanceId, callback) => {
  $http(
    `${getEndPoint(
      'emails/getCampaign',
    )}/${instanceId}?withOps=true&apiKey=${getApiKey()}`,
  )
    .get(false)
    .then(response => {
      callback(response);
    });
};

export const getSampleContent = (instance, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(
    `${getEndPoint('emails/sampleContent')}/${globalApiKey}`.replace(
      '{CAMPAIGN_ID}',
      instance,
    ),
  )
    .get(false)
    .then(response => {
      callback(response);
    });
};

export const getEmailTemplates = (
  callback,
  isCustomTemplate = false,
  collectionType,
  pageNo = 1,
  pageSize = 100,
) => {
  let endPoint = `${getRestEndPoint(
    'emails/templates',
  )}?pageNo=${pageNo}&pageSize=${pageSize}&apiKey=${getApiKey()}`;
  if (isCustomTemplate) {
    endPoint = `${getRestEndPoint(
      'emails/custom-template',
    )}?collection=${collectionType}&pageNo=${pageNo}&pageSize=${pageSize}&apiKey=${getApiKey()}`;
  }
  $http(endPoint)
    .get(false)
    .then(response => {
      callback(response);
    })
    .catch(response => {
      callback(response);
    });
};

export const searchEmailTemplates = (
  searchKeyword,
  collectionType,
  callback,
) => {
  const endPoint = `${getRestEndPoint(
    'emails/search-template',
  )}?templateName=${searchKeyword}&collection=${collectionType}&apiKey=${getApiKey()}`;
  $http(endPoint)
    .get(false)
    .then(response => {
      callback(response);
    })
    .catch(response => {
      callback(response);
    });
};

export const deleteEmailTemplate = (templateId, callback) => {
  $http(
    `${getRestEndPoint(
      'emails/custom-template',
    )}?apiKey=${getApiKey()}&id=${templateId}`,
  )
    .delete()
    .then(response => {
      callback(response);
    });
};

export const updateEmailCampaign = (instance, callback) => {
  const endPoint = `${getRestEndPoint('emails/update')}?apiKey=${getApiKey()}`;
  $http(endPoint)
    .post(instance, { type: 'json' })
    .then(response => {
      callback(response);
    })
    .catch(response => {
      callback(response);
    });
};

export const getRangedEmailReport = (instanceId, boundaries, callback) => {
  const opts = boundaries;
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  opts.interval = 'total';
  opts.instanceid = instanceId;
  opts.limit = 2000;

  $http(getEndPoint('report/emailSingleReport') + globalApiKey)
    .get(opts, { preventAbort: false })
    .then(response => {
      callback(response);
    });
};

export const getRangedEmailReportInterval = (
  instance,
  boundaries,
  interval,
  callback,
) => {
  const opts = boundaries;
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  opts.interval = interval;
  opts.instanceid = instance;
  opts.limit = 2000;

  $http(getEndPoint('report/emailSingleReport') + globalApiKey)
    .get(opts, { preventAbort: false })
    .then(response => {
      callback(response);
    });
};

export const sendTestEmail = (data, callback) => {
  const endPoint = `${getRestEndPoint('emails/test')}?apiKey=${getApiKey()}`;
  $http(endPoint)
    .post(data, { type: 'json' })
    .then(response => {
      callback(response);
    })
    .catch(response => {
      callback(response);
    });
};

export const getUnsubReport = (instanceId, unSubBoundaries, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(`${getRestEndPoint('emails/unsubReport')}/${instanceId}${globalApiKey}`)
    .get(unSubBoundaries)
    .then(response => {
      callback(response);
    });
};

export const addEmailCampaign = (instance, callback) => {
  const endPoint = `${getRestEndPoint('emails/add')}?apiKey=${getApiKey()}`;
  $http(endPoint)
    .post(instance, { type: 'json' })
    .then(response => {
      callback(response);
    })
    .catch(response => {
      callback(response);
    });
};

export const spamCheckEmailCampaign = (instance, callback) => {
  const endPoint = `${getRestEndPoint(
    'emails/spamCheck',
  )}?apiKey=${getApiKey()}`;
  $http(endPoint)
    .post(instance, { type: 'json' })
    .then(response => {
      callback(response);
    })
    .catch(response => {
      callback(response);
    });
};

export const getActivityReports = (data, callback) => {
  const endPoint = `${getRestEndPoint(
    'emails/activityReport',
  )}/${data}?apiKey=${getApiKey()}`;
  $http(endPoint)
    .get(false)
    .then(response => {
      callback(response);
    })
    .catch(response => {
      callback(response);
    });
};
