import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { t } from '../../system/ui';
import { queryDate } from '../../system/date';
import Icon from '../icon';
import Icons from '../icons';
import { modalActions, uiActions } from '../../actions';
import {
  isSwitchAccount,
  getToken,
  getSwitchAccountUser,
  isMultiAccountUser,
  getAccount,
  getApiKey,
  getRegion,
} from '../../modules/auth/user';
import { getEndPoint } from '../../system/api';
import CollectedEmails from '../widget/personalization-collected-mails';

import ComparePersonalizationModal from './compareModal.personalization';
import CompareRecommendationModal from './compareModal.recommendation';
import CompareEmailModal from './compareModal.email';
import GraphPersonalizationModal from './graphModal.personalization';
import GraphRecommendationModal from './graphModal.recommendation';
import DateRangedFormStats from './widgets/DateRangedFormStats';

const reportActionTypes = [
  { name: 'Date Comparison', icon: 'calendar', action: 'compare' },
  { name: 'Graphical View', icon: 'graph', iconSvg: true, action: 'viewGraph' },
  { name: 'View List', icon: 'view', action: 'viewResults', hasExport: true },
  {
    name: 'View Stats',
    icon: 'stats',
    iconSvg: true,
    action: 'viewStats',
    hasExport: true,
    campaignType: 'FORM',
  },
  {
    name: 'Export List',
    icon: 'pdf',
    action: 'exportResults',
    hasExport: true,
  },
  {
    name: 'Export',
    icon: 'pdf',
    action: 'exportReport',
    visibleModules: ['recommendation', 'email', 'push'],
  },
  {
    name: 'Export Users Click',
    icon: 'pdf',
    action: 'userClickExportReport',
    hasUserClickExport: true,
  },
];

const ReportActions = props => {
  const {
    campaign,
    hasExport,
    hasUserClickExport,
    moduleName,
    moduleType,
    range,
  } = props;
  const { instanceId: campaignId, type: campaignType } = campaign;
  const startDate = range[0]?.format('YYYY-MM-DD');
  const endDate =
    typeof range[1] === 'object'
      ? range[1]?.format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
  const actionMenu = React.useRef('actionMenu');

  const compare = () => {
    let compareModal;
    if (moduleName === 'email') {
      // Email Modal
      compareModal = () => <CompareEmailModal {...props} />;
    } else if (moduleName === 'personalization') {
      compareModal = () => <ComparePersonalizationModal {...props} />;
    } else {
      compareModal = () => <CompareRecommendationModal {...props} />;
    }
    if (compareModal) {
      modalActions.openModal({
        title: t('Date Comparison'),
        content: compareModal,
      });
    }
  };

  const exportResults = () => {
    const globalApiKey = `?apiKey=${getApiKey()}`;
    let resultUrl = getEndPoint('report/newsletter_export') + globalApiKey;
    resultUrl += `&instanceId=${campaignId}`;
    resultUrl += `&authToken=${getToken()}`;
    if (isSwitchAccount()) {
      if (isMultiAccountUser()) {
        resultUrl += `&switchAccount=${getAccount().accountId.trim()}`;
      } else {
        resultUrl += `&switchUser=${getSwitchAccountUser(true).username}`;
      }
    }
    resultUrl += `&startDate=${startDate}&endDate=${endDate}`;
    window.open(resultUrl);
  };

  const viewGraph = () => {
    const graphModal = () =>
      moduleType === 'engagement' ? (
        <GraphPersonalizationModal {...props} />
      ) : (
        <GraphRecommendationModal
          {...props}
          isEmailCampaign={moduleName === 'email'}
          isPushCampaign={moduleName === 'push'}
        />
      );
    modalActions.openModal({
      title: t('Graphical View'),
      content: graphModal,
    });
  };

  const viewResults = () => {
    const modalTitle = `${
      campaignType === 'FORM' ? 'Form Results' : 'Email List'
    }`;
    const content = () => (
      <CollectedEmails
        campaign={campaign}
        startDate={startDate}
        endDate={endDate}
      />
    );
    uiActions.openModal({ title: modalTitle, content });
  };

  const viewStats = () => {
    const content = () => (
      <DateRangedFormStats
        campaign={campaign}
        startDate={startDate}
        endDate={endDate}
      />
    );

    uiActions.openModal({ title: t('Stats'), content });
  };

  const exportReport = () => {
    const globalApiKey =
      getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
    const modulePath = moduleType === 'engagement' ? 'engagement' : moduleName;
    const endpoint =
      moduleName === 'email'
        ? getEndPoint('export/mail') + globalApiKey
        : getEndPoint(`export/${modulePath}`) + globalApiKey;
    const instanceId = props.instanceId || campaignId;
    let exportUrl = `${endpoint}&end=${queryDate(range[1])}&start=${queryDate(
      range[0],
    )}&interval=total&limit=2000&instanceid=${instanceId}&authToken=${getToken()}`;
    if (isSwitchAccount() && !isMultiAccountUser()) {
      exportUrl += `&switchUser=${getSwitchAccountUser(true).username}`;
    }
    if (isMultiAccountUser() && isSwitchAccount()) {
      exportUrl += `&switchAccount=${getAccount().accountId.trim()}`;
    }
    exportUrl += `&switchRegion=${getRegion()}`;
    window.open(exportUrl);
  };

  const userClickExportReport = () => {
    const apiKey = getApiKey();
    const globalApiKey = apiKey ? `?apiKey=${apiKey}` : '';
    const endpoint = getEndPoint('export/special/interactions') + globalApiKey;

    const instanceId = props.instanceId || campaignId;
    let exportUrl = `${endpoint}&end=${queryDate(range[1])}&start=${queryDate(
      range[0],
    )}&instanceId=${instanceId}&authToken=${getToken()}`;
    if (isSwitchAccount()) {
      exportUrl += isMultiAccountUser()
        ? `&switchAccount=${getAccount().accountId.trim()}`
        : `&switchUser=${getSwitchAccountUser(true).username}`;
    }
    window.open(exportUrl);
  };

  const actions = {
    compare,
    viewGraph,
    viewResults,
    viewStats,
    exportReport,
    exportResults,
    userClickExportReport,
  };

  const handleClick = action => actions[action]();

  return (
    <nav className='account-nav action-nav report-nav' ref={actionMenu}>
      <Icon name='menu' />
      <ul className='action-nav-popup'>
        {reportActionTypes
          .filter(action => {
            return !(
              (action.hasExport && !hasExport) ||
              (action.hasUserClickExport && !hasUserClickExport) ||
              (campaignType !== 'HERO_BANNER' &&
                action.visibleModules &&
                action.visibleModules.indexOf(moduleName) < 0) ||
              (action.isRecOnly && moduleName !== 'recommendation') ||
              (action.campaignType && action.campaignType !== campaignType)
            );
          })
          .map(action => (
            <li key={`action-${action.name}`}>
              <a
                className='action-nav-popup-item'
                onClick={() => handleClick(action.action)}
              >
                {action.iconSvg ? (
                  <span style={{ marginRight: '5px' }}>
                    <Icons name={action.icon} color='#fff' />
                  </span>
                ) : (
                  <Icon name={action.icon} />
                )}
                {t(action.name)}
              </a>
            </li>
          ))}
      </ul>
    </nav>
  );
};

ReportActions.propTypes = {
  campaign: PropTypes.shape({
    instanceId: PropTypes.string,
    type: PropTypes.string,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        groupTitle: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.string),
        required: PropTypes.bool,
        type: PropTypes.string,
      }),
    ),
  }),
  instanceId: PropTypes.string,
  hasExport: PropTypes.bool,
  hasUserClickExport: PropTypes.bool,
  moduleName: PropTypes.string,
  moduleType: PropTypes.string,
  range: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ReportActions;
