import React from 'react';
import { connect } from 'react-redux';

import * as modals from '../constants/datamaps/modals';
import { statsMap, trendsMap, statTypes } from '../constants/datamaps';

import { t, openModal } from '../system/ui';

import { clone } from '../system/object';

import { setTitle } from '../system/document';
import {
  localeString,
  localeStringMoney,
  toLocaleStringDigits,
} from '../system/string';

import { uiActions, filterActions } from '../actions';

import stats from '../modules/stats';
import {
  transformStats,
  transformPushTrends as transformTrends,
} from '../modules/transform';

import { findLongestStat } from '../components/reports/utils';

import Currency from '../components/currency';
import Icon from '../components/icon';
import Tooltip from '../components/tooltip';

class ExecutiveReportPush extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: clone(statsMap),
      trends: clone(trendsMap),
    };

    this.showModal = this.showModal.bind(this);
  }

  componentDidMount() {
    setTitle(t('Executive Reports'));
    this.getRangedStats(this.props.range);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.update === true) {
      this.getRangedStats(newProps.range);
    }

    if (newProps.reset === true) {
      this.getRangedStats(newProps.range);
    }
  }

  getRangedStats(range) {
    filterActions.filtersUpdated();
    filterActions.filtersReset();
    if (range[0] && range[1]) {
      uiActions.isLoading();
      const boundaries = {};
      boundaries.start = range[0].format('YYYY-MM-DD');
      boundaries.end = range[1].format('YYYY-MM-DD');

      stats.getRangedStats(boundaries, allStats => {
        stats.getRangedTrends(
          boundaries,
          allTrends => {
            this.setState(
              {
                stats: transformStats(allStats),
              },
              () => {
                this.setState({
                  trends: transformTrends(
                    allTrends,
                    this.state.stats.purchase.stats.amount.count,
                    0,
                    0,
                    false,
                  ),
                });
              },
            );
            uiActions.isLoaded();
          },
          false,
          'Push',
        );
      });
    }
  }

  showModal(modal, e) {
    e.preventDefault();

    const modalContent = modals[modal];

    openModal({
      title: modal,
      content: modalContent,
    });
  }

  render() {
    /* let exportUrl = getEndPoint('export/campaign') + '?end=' + queryDate(this.props.range[1]) + '&start=' + queryDate(this.props.range[0]) + '&interval=total&limit=2000'; */
    const longestStat = findLongestStat(this.state.trends);
    try {
      // eslint-disable-next-line no-param-reassign
      delete this.state.trends.widget.stats.bounceBack;
      delete this.state.trends.widget.stats.unsubscribed;
    } catch (err) {
      uiActions.showNotification({
        content: () => <p>{err}</p>,
        className: 'notification-fail',
      });
    }
    return (
      <div className='page-content-block'>
        <div className='widget-stat-list widget-report'>
          <div className='widget-stats'>
            {Object.keys(this.state.trends).map((item, index) => {
              const stat = this.state.trends[item].stats;
              const type = statTypes[item];
              const mainTitle = type.name;
              const className = `widget-stat stat-${item.toLowerCase()}`;
              const originalStatVal =
                item !== 'widget' ? stat.all.count : stat.view.count;

              const statName = item;
              let emailTypeName = false;
              if (mainTitle === 'Widget') {
                emailTypeName = 'Push';
              } else if (mainTitle === 'Revenue') {
                emailTypeName = 'Push Conversion';
              } else {
                emailTypeName = mainTitle;
              }

              return (
                <div className={className} key={`${item} - ${index}`}>
                  <h4 className='widget-stat-title'>{t(emailTypeName)}</h4>
                  <p
                    className='widget-stat-count'
                    title={toLocaleStringDigits(originalStatVal)}
                  >
                    {type.currency === true
                      ? localeStringMoney(originalStatVal)
                      : localeString(originalStatVal)}
                  </p>
                  <p className='widget-stat-type'>
                    {type.currency === true ? (
                      <Currency />
                    ) : (
                      <Icon name={type.icon} />
                    )}
                    {type.currency !== true
                      ? t(type.unit === 'views' ? 'views' : type.unit)
                      : ''}
                  </p>
                  <p key={item} className='widget-stat-name'>
                    {mainTitle === 'Widget' && t('Sent')}
                    {mainTitle === 'Widget' && (
                      <span className='widget-stat-conversion-amount'>
                        {localeString(stat.all.count)}
                      </span>
                    )}
                  </p>
                  {Object.keys(stat).map((item, index) => {
                    const singleStat = stat[item];
                    const isLastItem = index + 1 === Object.keys(stat).length;
                    let singleStatData;
                    if (item === 'all' || singleStat.name === 'Actual View') {
                      return;
                    }

                    if (singleStat.currency) {
                      singleStatData = <Currency currency={singleStat.count} />;
                    } else {
                      singleStatData =
                        localeString(singleStat.count) ||
                        `${localeString(singleStat.ratio)} %`;
                    }
                    return (
                      <>
                        {singleStat.name !== 'Spam' && (
                          <p
                            key={item}
                            className={`widget-stat-name ${
                              statName !== longestStat && isLastItem
                                ? 'widget-stat-conversion-bottom'
                                : ''
                            }`}
                          >
                            {mainTitle === 'Widget' ||
                            mainTitle === 'Interaction' ||
                            mainTitle === 'Basket'
                              ? t(emailTypeName + singleStat.name)
                              : t(singleStat.name)}
                            {singleStat.tooltip && (
                              <span className='stat-tooltip'>
                                <Tooltip
                                  content={singleStat.tooltip}
                                  alignment='top'
                                  newTooltip
                                >
                                  <Icon name='info' />
                                </Tooltip>
                              </span>
                            )}
                            <span className='widget-stat-data'>
                              {singleStatData}
                            </span>
                          </p>
                        )}
                      </>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  user: store.user.user,
  update: store.filters.update,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  reset: store.filters.reset,
});

export default connect(mapStatesToProps)(ExecutiveReportPush);
