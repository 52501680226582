/**
 * Created by mehmetyurtar on 4/20/18.
 */
import React from 'react';

import classNames from 'classnames';
import { checkToken, setMultiUserPassword } from '../modules/auth/user';
import { uiActions } from '../actions';
import Header from '../components/layout/header';
import Preloader from '../components/layout/preloader';
import { t } from '../system/ui';
import { getApiPaths } from '../constants/config';

class Set_Password extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.loginUser = this.loginUser.bind(this);

    this.state = {
      displayName: '',
      token: '',
      password: '',
      passwordAgain: '',
      passwordTokenCheckMessage: undefined,
    };
  }

  componentDidMount() {
    this.getUserInfo(this.props.params.token);
  }

  handleSubmit(e) {
    e.preventDefault();
    const matched = this.state.password === this.state.passwordAgain;
    const passwordChecker = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!\@\#\.\_\-\(\)])(?=.*[a-zA-Z]).{8,}$/;
    const controlStatement =
      passwordChecker.test(this.state.password) &&
      passwordChecker.test(this.state.passwordAgain);
    if (typeof this.state.passwordTokenCheckMessage !== 'undefined') {
      return;
    }
    if (matched && controlStatement) {
      setMultiUserPassword(
        { password: this.state.password },
        this.state.token,
        response => {
          if (response) {
            this.loginUser({
              username: this.state.username,
              password: this.state.password,
            });
          }
        },
        response => {
          let errorMessage = 'Error occurred while setting password';
          if (response && response.response && response.response !== '') {
            errorMessage = response.response;
          }
          this.showErrorMessage(errorMessage);
        },
      );
    }
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handlePasswordAgainChange(e) {
    this.setState({ passwordAgain: e.target.value });
  }

  getUserInfo(token) {
    checkToken(
      token,
      response => {
        this.setState({
          displayName: response.displayName,
          username: response.username,
          token,
        });
      },
      response => {
        let errorMessage = 'Error occurred while setting password';
        if (response && response.response && response.response !== '') {
          errorMessage = response.response;
        }
        this.showErrorMessage(errorMessage);
      },
    );
  }

  showErrorMessage(errorMessage) {
    this.setState({
      passwordTokenCheckMessage: errorMessage,
    });
  }

  loginUser(fields) {
    const notificationContent = () => (
      <div>
        {t(
          'Your password is successfully set. You are now being redirected to login page.',
        )}
      </div>
    );
    uiActions.showNotification({ content: notificationContent });
    setTimeout(function() {
      window.location = `${getApiPaths().login}?mode=1`;
    }, 400);
  }

  render() {
    const pageClass = classNames('page-wrap not-found');
    const touched =
      this.state.password === '' || this.state.passwordAgain === '';
    const matched = this.state.password === this.state.passwordAgain;
    const passwordChecker = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!\@\#\.\_\-\(\)])(?=.*[a-zA-Z]).{8,}$/;
    const passwordCheck = passwordChecker.test(this.state.password);
    const passwordAgainCheck = passwordChecker.test(this.state.passwordAgain);
    return (
      <div id='wrapper' className={pageClass}>
        <Header isSetPassword name={this.state.displayName} />

        <Preloader />

        <div id='content' className='page-content'>
          <div id='main' className='page-main'>
            <div className='set-password'>
              <h3>{t('Set Password')}</h3>
              <form
                onSubmit={this.handleSubmit}
                ref='form'
                className='set-password-form'
              >
                <label className='item item-stacked one-whole'>
                  <span className='item-label'>{t('Password')}</span>
                  <input
                    type='password'
                    name='password'
                    value={this.state.password}
                    className='text-field'
                    onChange={e => this.handlePasswordChange(e)}
                  />
                </label>
                {this.state.password !== '' && !passwordCheck && (
                  <p>
                    <span className='password-err'>
                      {t('This field should have at least 8 characters')}
                    </span>
                    <span className='password-err'>
                      {t(
                        'Password must have at least one uppercase character (A-Z), one lowercase character (a-z) , one digit (0-9) and one special character: ! @ # . _ - ( )',
                      )}
                    </span>
                  </p>
                )}
                <label className='item item-stacked one-whole'>
                  <span className='item-label'>{t('Password Again')}</span>
                  <input
                    type='password'
                    name='passwordAgain'
                    value={this.state.passwordAgain}
                    className='text-field'
                    onChange={e => this.handlePasswordAgainChange(e)}
                  />
                </label>
                {this.state.passwordAgain !== '' && !passwordAgainCheck && (
                  <p>
                    <span className='password-err'>
                      {t('This field should have at least 8 characters')}
                    </span>
                    <span className='password-err'>
                      {t(
                        'Password must have at least one uppercase character (A-Z), one lowercase character (a-z) , one digit (0-9) and one special character: ! @ # . _ - ( )',
                      )}
                    </span>
                  </p>
                )}
                {!touched && !matched && (
                  <span className='password-err'>
                    {t('Password fields must be match.')}
                  </span>
                )}
                <button
                  className={classNames({
                    'tertiary-action': true,
                    disabled: !(matched && passwordCheck && passwordAgainCheck),
                  })}
                  type='submit'
                >
                  {t('Set Password')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Set_Password;
