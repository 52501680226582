export const fieldMapper = (user, languageOptions) => {
  return [
    {
      label: 'Username',
      name: 'username',
      type: 'email',
      className: 'item-stacked one-whole',
      required: true,
      defaultValue: user.username,
      readOnly: true,
      validation: {
        email: true,
        businessEmail: true,
      },
    },
    {
      label: 'Display Name',
      name: 'displayname',
      className: 'item-stacked one-whole',
      defaultValue: user.displayName,
    },
    {
      label: 'Display Language',
      name: 'display-language',
      className: 'item-stacked one-whole',
      value: user.language || 'en',
      options: languageOptions,
      type: 'select',
    },
    {
      label: 'New Password',
      name: 'password',
      type: 'password',
      className: 'item-stacked one-whole',
      validation: {
        minLength: 8,
        password: true,
      },
    },
    {
      label: 'New Password (Again)',
      name: 'password_repeat',
      type: 'password',
      className: 'item-stacked one-whole',
      validation: {
        equalTo: 'password',
        minLength: 8,
        password: true,
      },
    },
  ];
};
