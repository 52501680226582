import React from 'react';
import { connect } from 'react-redux';

import { uiActions } from 'actions';
import CreateUserModal from 'components/admin/create-user-modal';
import Icons from 'components/icons';
import { setTitle } from 'system/document';
import { t } from 'system/ui';

import { isMasterUser, isSuperiestUser } from '../../modules/auth/user';
import { onResetFilterClick } from '../../reducers/user-management';
import '../../styles/gui/components/_user-management.scss';
import UserManagementFilters from './user.management.filters';
import UserManagementTable from './user.management.table';
import UserManagementSearch from './user.management.search';

class UserManagement extends React.Component {
  constructor(props) {
    super(props);

    this.createUser = this.createUser.bind(this);
  }

  componentDidMount() {
    uiActions.resetPage();
    uiActions.addPageClass('sidebar-is-hidden');
    setTitle(t('User Management'));
  }

  componentWillUnmount() {
    this.props.onResetFilterClick();
  }

  getFilterQuery = () => {
    const { filterState } = this.props;

    return Object.keys(filterState)
      .filter(
        type =>
          filterState[type].selected && filterState[type].selected !== 'ALL',
      )
      .reduce(
        (result, type) => ({ ...result, [type]: filterState[type].selected }),
        {},
      );
  };

  createUser = e => {
    e.preventDefault();

    const getAdminType = () => {
      if (isMasterUser()) return 'master';
      if (isSuperiestUser()) return 'super';
      return 'none';
    };
    const content = () => <CreateUserModal adminType={getAdminType()} />;

    uiActions.openModal({
      title: isSuperiestUser() ? t('Create User') : t('Create Account User'),
      content,
    });
  };

  Button = () => (
    <a className='button secondary-action' onClick={this.createUser}>
      <span className='span-icon'>
        <Icons name='plus' width='13' />
      </span>
      {t('Create User')}
    </a>
  );

  render() {
    return (
      <div className='user-management'>
        <div className='user-management-header'>
          <h2 className='page-title'>{t('User Management')}</h2>
          <div className='user-management-header-right-side'>
            <this.Button />
            <UserManagementSearch getFilterQuery={this.getFilterQuery} />
          </div>
        </div>
        <div className='user-management-body'>
          <UserManagementFilters getFilterQuery={this.getFilterQuery} />
          <div className='user-management-body-table'>
            <UserManagementTable />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  filterState: state.userManagement.filter,
});

const mapDispatchToProps = {
  onResetFilterClick,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
