/**
 * @author Bilal Cinarli
 */

"use strict";

import { createReducer } from "../system/store";
import * as dialogActions from "../constants/actions/dialog";

const initialState = {
  dialog: {},
  showDialog: false
};

export default createReducer(initialState, {
  [dialogActions.default.OPEN_DIALOG]: (state, payload) => {
    return Object.assign({}, state, {
      dialog: payload,
      showDialog: true
    });
  },
  [dialogActions.default.CLOSE_DIALOG]: state => {
    return Object.assign({}, state, {
      dialog: {},
      showDialog: false
    });
  }
});
