/**
 * @fileOverview Actions for gamification and its related components
 * @description This file contains the actions for the gamification and its related components.
 * @param {React.dispatch} dispatch
 * @returns {void}
 */

export const setGamificationList = dispatch => {
  return list => {
    dispatch({
      type: 'SET_GAMIFICATION_LIST',
      payload: list,
    });
  };
};

export const setActiveWOFDetails = dispatch => {
  return details => {
    dispatch({
      type: 'SET_ACTIVE_WOF_DETAILS',
      payload: details,
    });
  };
};

export const editActiveWOFDetails = dispatch => {
  return newDetails => {
    dispatch({
      type: 'EDIT_ACTIVE_WOF_DETAILS',
      payload: newDetails,
    });
  };
};

export const resetActiveWOFDetails = dispatch => {
  return () => {
    dispatch({
      type: 'RESET_ACTIVE_WOF_DETAILS',
    });
  };
};

export const setActiveScratchDetails = dispatch => {
  return details => {
    dispatch({
      type: 'SET_ACTIVE_SCRATCH_DETAILS',
      payload: details,
    });
  };
};

export const editActiveScratchDetails = dispatch => {
  return newDetails => {
    dispatch({
      type: 'EDIT_ACTIVE_SCRATCH_DETAILS',
      payload: newDetails,
    });
  };
};

export const resetActiveScratchDetails = dispatch => {
  return () => {
    dispatch({
      type: 'RESET_ACTIVE_SCRATCH_DETAILS',
    });
  };
};
