import React from 'react';
import styles from './Section.module.scss';

const Section = ({ data }) => {
  return (
    <div className={styles.section}>
      <h3 className={styles.section__header}>{data?.header}</h3>
      <div className={styles['section__event-container']}>
        {data?.events.map(item => (
          <span className={styles['section__event-container__event']}>
            {item.eventData}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Section;
