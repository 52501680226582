/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width={this.state.width}
        height={this.state.height}
      >
        <path
          fill={this.state.color}
          d="M46,1H2C1.4,1,1,1.4,1,2v44c0,0.6,0.4,1,1,1h44c0.6,0,1-0.4,1-1V2C47,1.4,46.6,1,46,1z M45,45H3V3h42V45z"
        />
        <path
          fill={this.state.color}
          d="M8,39c0.3,0,0.6-0.1,0.8-0.4l9.5-11.3l9.3,5.6c0.4,0.2,0.9,0.2,1.2-0.2l12-12c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L27.8,30.7
	l-9.3-5.6c-0.4-0.3-1-0.2-1.3,0.2l-10,12c-0.4,0.4-0.3,1.1,0.1,1.4C7.5,38.9,7.8,39,8,39z"
        />
        <path
          fill={this.state.color}
          d="M20,19c2.8,0,5-2.2,5-5s-2.2-5-5-5s-5,2.2-5,5S17.2,19,20,19z M20,11c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S18.3,11,20,11z
	"
        />
      </svg>
    );
  }
}

export default IconPopup;
