import React from 'react';
import Highcharts from 'highcharts';

const defaultOptions = {
  chart: {
    backgroundColor: null,
    borderWidth: 0,
    type: 'column',
    margin: [25, 0, 25, 0],
    width: 180,
    height: 120,
    style: {
      overflow: 'visible',
    },

    // small optimalization, saves 1-2 ms each sparkline
    skipClone: true,
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    className: 'monitor-zone-1',
    labels: {
      enabled: false,
    },
    title: {
      align: 'high',
    },
    startOnTick: false,
    endOnTick: false,
    tickPositions: [],
  },
  yAxis: {
    endOnTick: false,
    startOnTick: false,
    labels: {
      enabled: false,
    },
    title: {
      text: null,
    },
    min: -20,
    max: 20,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    backgroundColor: 'white',
    borderWidth: 1,
    hideDelay: 0,
    shared: true,
    outside: true,
    padding: 8,
    borderColor: 'silver',
    borderRadius: 3,
  },
  plotOptions: {
    series: {
      animation: false,
      lineWidth: 1,
      shadow: false,
      states: {
        hover: {
          lineWidth: 1,
        },
      },
      marker: {
        radius: 1,
        states: {
          hover: {
            radius: 2,
          },
        },
      },
      fillOpacity: 0.25,
      zones: [
        {
          className: 'monitor-zone-0',
        },
      ],
    },
  },

  series: [
    {
      data: [1, 3, 2, 4, 5],
    },
  ],
};

class SparkLine extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const options = Highcharts.merge(defaultOptions, this.props.options);
    this.chart = Highcharts.chart(this.container, options);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.options !== this.props.options) {
      const options = Highcharts.merge(defaultOptions, this.props.options);
      this.chart = Highcharts.chart(this.container, options);
    }
  }

  componentWillUnmount() {
    this.chart.destroy();
  }

  render() {
    return (
      <table>
        <tbody>
          <tr>
            <td ref={container => (this.container = container)} />
          </tr>
        </tbody>
      </table>
    );
  }
}

export default SparkLine;
