/**
 * Created by mehmetyurtar on 10/18/17.
 */
import React from "react";

class IconToggleUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <path d="M9,12c2.761,0,5-3.239,5-6V5c0-2.761-2.239-5-5-5S4,2.239,4,5v1C4,8.761,6.239,12,9,12z" />
        <path d="M10,19c0-1.812,0.609-3.477,1.625-4.818C10.794,14.072,9.917,14,9,14c-2.824,0-5.329,0.638-6.974,1.193	C0.81,15.604,0,16.749,0,18.032V22h10.587C10.211,21.073,10,20.062,10,19z" />
        <path d="M15.095,17.249C15.436,15.925,16.63,15,18,15c0.808,0,1.573,0.331,2.131,0.893l-1.608,1.168L22.792,18l0.428-4.351	l-1.466,1.065C20.814,13.644,19.451,13,18,13c-2.282,0-4.273,1.542-4.843,3.75L15.095,17.249z" />
        <path d="M18,24c2.282,0,4.273-1.542,4.843-3.75l-1.938-0.499C20.564,21.075,19.37,22,18,22c-0.808,0-1.573-0.331-2.131-0.893	l1.608-1.168L13.208,19l-0.428,4.351l1.466-1.065C15.186,23.356,16.549,24,18,24z" />
      </svg>
    );
  }
}

export default IconToggleUser;
