/**
 * Created by mehmetyurtar on 11/07/2017.
 */
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { TextField } from "../../../components/fields";
import Icon from "../../../components/icon";
import Icons from "../../../components/icons";

import { timeFrame, items } from "../../../constants/datamaps/wizard";

import { t } from "../../../system/ui";

import { getBrands } from "../../../modules/account/ajax";

class FormFieldTextArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      placeholder: "",
      name: "",
      required: false
    };

    this.onChange = this.onChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.updateValues = this.updateValues.bind(this);
  }

  componentDidMount() {
    if (this.props.values.name) {
      this.updateValues({
        placeholder: this.props.values.placeholder.value,
        name: this.props.values.name.value,
        required: this.props.values.required.value
      });
    }
  }

  updateValue(changed, newValue) {
    let currentState = this.state;
    currentState[changed] = newValue;
    this.setState({
      placeholder: currentState.placeholder,
      name: currentState.name,
      required: currentState.required
    });
  }

  updateValues(newValues) {
    this.setState({
      placeholder: newValues.placeholder,
      name: newValues.name,
      required: newValues.required
    });
  }

  onChange(e) {
    let newVal = e.target.value;
    if (e.target.name === "required") {
      newVal = e.target.checked;
    }
    this.updateValue(e.target.name, newVal);
    this.props.onFormFieldChange(this.refs.inputs, this.props.id);
  }

  render() {
    return (
      <li className="wizard-form-field" ref="inputs">
        <span className="criteria-type">
          <Icons name="fieldTextArea" color="#7a7a7c" />
        </span>
        <TextField
          name="placeholder"
          value={this.state.placeholder}
          className="item-field field-name field-params field-textarea-placeholder"
          inputClassName="one-whole criteria-field"
          placeholder="Placeholder"
          required={false}
          onChange={this.onChange}
        />
        <TextField
          name="name"
          value={this.state.name}
          className="item-field field-name field-params field-textarea-name"
          inputClassName="one-whole criteria-field"
          placeholder="Name"
          required={false}
          onChange={this.onChange}
        />
        <label className="item item-field is-checkbox field-textarea-required">
          <input
            type="checkbox"
            name="required"
            checked={this.state.required}
            className="criteria-field"
            onChange={this.onChange}
          />
          <span className="item-label"> {t("Required")} </span>
        </label>

        <a
          onClick={this.props.onFormFieldRemove.bind(null, this.props.id)}
          className="cancel-action engagement-form-cancel-action"
        >
          <Icon name="cross" />
        </a>
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  criteria: store.wizard.criteria
});

export default connect(mapStatesToProps)(FormFieldTextArea);
