import React from 'react';
import clsx from 'clsx';
import { modalActions, switchUserActions } from '../../actions';
import { resetSwitchRegion } from '../../actions/switchRegion';
import setHighchartsDefaults from '../../modules/highcharts.global';
import { t } from '../../system/ui';
import Icon from '../icon';
import Icons from '../icons';
import Tooltip from '../tooltip';

/**
 * This component is used to select a user to switch to. It is used in the
 * switch user modal.
 * @params {object} props - Component props
 * @returns {React.ReactNode} - Rendered component (List Component)
 * */

const SelectUser = ({
  account,
  accounts,
  switchableAccountsWithDetails,
  users,
}) => {
  const { updateSwitchUser } = switchUserActions;
  const { closeModal } = modalActions;

  const switchToUser = user => {
    resetSwitchRegion();

    const targetAccount = user.account || account;

    const processedUser = {
      ...user,
      account: accounts.find(acc => acc.accountId === targetAccount.accountId),
      switchableAccountsWithDetails,
    };
    updateSwitchUser(processedUser);
    setHighchartsDefaults(processedUser);
    closeModal();
  };

  return (
    <table className='data-table'>
      <thead>
        <tr>
          <th>{t('Display Name')}</th>
          <th>{t('E-Mail')}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {users.map(item => {
          const element = item.user || item;
          const isBold = element.userRole && element.userRole === 'ADMIN';
          const noSwitch = item.user && !item.isOwner;

          // Don't show the user if they are not the owner of the account
          if (noSwitch) {
            return null;
          }

          return (
            <tr
              key={element.username}
              className={clsx({ 'no-switch': noSwitch })}
            >
              <td>
                {element.displayName}{' '}
                {isBold && !noSwitch && (
                  <span style={{ fontWeight: 'bold' }}>(A)</span>
                )}
                {element.privileges.includes('MULTI_ACC_USER') && (
                  <span className='star-icon-holder'>
                    <Tooltip
                      content={t('This is the main user of the account.')}
                      newTooltip
                      alignment='top'
                    >
                      <Icons
                        name='star'
                        width='16'
                        height='16'
                        color='#f3c10f'
                      />
                    </Tooltip>
                  </span>
                )}
              </td>
              <td>{element.username}</td>
              <td>
                <a
                  className='secondary-action'
                  onClick={() =>
                    switchToUser(
                      item.user
                        ? {
                            ...item.user,
                            account,
                          }
                        : item,
                    )
                  }
                >
                  <Icon name='refresh' />
                </a>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default SelectUser;
