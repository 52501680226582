import React from "react";

class IconNumberCurrency extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60.56 38.98"
        width={this.state.width}
        height={this.state.height}
      >
        <g data-name="Layer 2">
          <g data-name="Layer 1">
            <path
              d="M26.46 17.12a1.61 1.61 0 0 1 1.14.47L31 21l8.42-8.41a11 11 0 0 0-19.2 10.05l5.08-5.07a1.59 1.59 0 0 1 1.16-.45zM1 31.09H0V39h7.89v-1A6.9 6.9 0 0 0 1 31.09z"
              style={{ fill: this.state.color }}
            />
            <path
              d="M32.17 24.44a1.61 1.61 0 0 1-1.14.47 1.59 1.59 0 0 1-1.13-.47L26.46 21l-4.73 4.74a11 11 0 0 0 20.14-6.25 10.81 10.81 0 0 0-.76-4z"
              style={{ fill: this.state.color }}
            />
            <path
              d="M49.53 1V0H11v1A10.05 10.05 0 0 1 1 11H0v16.9h1A10.05 10.05 0 0 1 11 38v1h38.53v-1a10.05 10.05 0 0 1 10-10h1V11h-1a10.05 10.05 0 0 1-10-10zM46 10.59l-4 4a12 12 0 0 1 1 4.87 12.17 12.17 0 0 1-22.1 7.06L17 30.44a1.61 1.61 0 0 1-1.14.47 1.63 1.63 0 0 1-1.14-.47 1.61 1.61 0 0 1-.47-1.14 1.63 1.63 0 0 1 .47-1.14l4.6-4.6a12.18 12.18 0 0 1 20.95-11.77l3.47-3.47a1.57 1.57 0 0 1 1.14-.47 1.59 1.59 0 0 1 1.12.47 1.62 1.62 0 0 1 0 2.27z"
              style={{ fill: this.state.color }}
            />
            <path
              d="M52.67 38v1h7.89v-7.91h-1A6.9 6.9 0 0 0 52.67 38zm0-38v1a6.9 6.9 0 0 0 6.89 6.89h1V0zM7.89 1V0H0v7.89h1A6.9 6.9 0 0 0 7.89 1z"
              style={{ fill: this.state.color }}
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default IconNumberCurrency;
