export const paginationReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_PAGINATION':
      return {
        ...state,
        pagination: payload,
      };
    case 'SET_QUERY_STRING':
      return {
        ...state,
        searchQueryString: payload,
      };
    case 'SET_CAMPAIGN_PATHNAME':
      return {
        ...state,
        campaignPathname: payload,
      };
    default:
      return state;
  }
};
