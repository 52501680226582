/**
 * Created by mehmetyurtar on 13/07/2017.
 */
"use strict";

import React from "react";
import { t } from "../../system/ui";

import { uiActions } from "../../actions";
import { TextField } from "../fields";

class WizardPrioritySelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priority: ""
    };
    this.priorityChange = this.priorityChange.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.setState({
        priority: newProps.campaign.priority
      });
    }
  }

  priorityChange(e) {
    this.setState({
      priority: e.currentTarget.value
    });
    uiActions.formEdited();
  }

  render() {
    return (
      <div className="wizard-priority">
        <span className="item item-stacked label-emphasise one-whole">
          <span className="item-label">{t("Campaign Priority")}</span>
        </span>

        <span
          className="item one-whole"
          style={{ display: "inline-block", verticalAlign: "top" }}
        >
          <TextField
            name="priority"
            value={this.state.priority}
            placeholder={t("Campaign Priority")}
            required={false}
            style={{ width: "100%" }}
            onChange={this.priorityChange}
            className="one-whole"
          />
        </span>
      </div>
    );
  }
}

export default WizardPrioritySelection;
