import React, { useState, useEffect } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styles from './Alerts.module.scss';
import { t } from '../../../system/ui';
import Icon from '../../../components/icon';
import { filterActions } from '../../../actions';
import AccountFilter from '../../../components/filters/account-filter';
import AlertTable from './components/AlertTable';
import { getMonitoringAlerts } from '../../../modules/account/ajax';
import { filterMapping } from './utils/utils';
import { isLoading, isLoaded } from '../../../actions/ui';

const Alerts = () => {
  const [searchStr, setSearchStr] = useState('');
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [filterObj, setFilterObj] = useState({});
  const [initialAlerts, setInitialAlerts] = useState([]);
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    isLoading();
    getMonitoringAlerts(response => {
      setAlerts(response);
      setInitialAlerts(response);
      isLoaded();
    });
  }, []);

  const searchFilteredAccounts = (isInitial = false) => {
    const result = [];
    if (isInitial || searchTriggered) {
      initialAlerts.forEach(item => {
        if (
          item.domain.includes(searchStr) ||
          item.accountId.includes(searchStr)
        ) {
          result.push(item);
        }
      });
      return result;
    }

    alerts.forEach(item => {
      if (
        item.domain.includes(searchStr) ||
        item.accountId.includes(searchStr)
      ) {
        result.push(item);
      }
    });
    return result;
  };

  const manuelFilteredAccounts = filterOb => {
    const isItemIncludes = elem => {
      let res = true;
      Object.keys(filterOb).forEach(item => {
        if (!elem[filterMapping[item]]?.includes(filterOb[item])) {
          res = false;
        }
      });
      return res;
    };
    const result = [];

    if (searchTriggered) {
      initialAlerts.forEach(item => {
        if (
          isItemIncludes(item) &&
          (item.domain.includes(searchStr) ||
            item.accountId.includes(searchStr))
        ) {
          result.push(item);
        }
      });
    } else {
      initialAlerts.forEach(item => {
        if (isItemIncludes(item)) {
          result.push(item);
        }
      });
    }

    return result;
  };

  const resetSearch = () => {
    if (searchTriggered) {
      setSearchStr('');
      setSearchTriggered(false);
      filterActions.updateFilters();
    }
  };

  const checkKey = e => {
    if (e.key === 'Enter') {
      const filteredAccounts = searchFilteredAccounts();
      setSearchTriggered(true);
      setAlerts(filteredAccounts);
    }
  };

  const updateSearchStr = e => {
    setSearchStr(e.target.value);
  };

  const filterAccounts = filterO => {
    if (Object.keys(filterO).length === 0) {
      if (searchTriggered) {
        setAlerts(searchFilteredAccounts(true));
      } else {
        setAlerts(initialAlerts);
      }
    } else {
      setAlerts(manuelFilteredAccounts(filterO));
    }
    setFilterObj(filterO);
  };

  useEffect(() => {
    if (Object.keys(filterObj).length > 0) {
      setAlerts(manuelFilteredAccounts(filterObj));
    } else if (!searchTriggered) {
      setAlerts(initialAlerts);
    }
    filterActions.updateFilters();
  }, [searchTriggered, Object.keys(filterObj).length]);

  return (
    <div className='accounts-vertical-result admin-stats admin-stats-page'>
      <ReactTooltip effect='solid' className='sg-tooltip' />

      <h2 className={`page-title ${styles['page-title']}`}>
        {t('Account Alerts')}
      </h2>

      <div className='page-options page-options-admin-stats'>
        <label className='item is-stacked is-search'>
          <span
            onClick={resetSearch}
            style={{ cursor: searchTriggered ? 'pointer' : 'initial' }}
          >
            <Icon name={searchTriggered ? 'cross' : 'magnify'} />
          </span>
          <input
            type='text'
            placeholder={t('Account ID or Domain')}
            className='text-field'
            name='accountId'
            value={searchStr}
            onChange={updateSearchStr}
            onKeyDown={checkKey}
          />
        </label>
      </div>

      <AccountFilter filterAccounts={filterAccounts} isAlertFilter />

      <AlertTable alerts={alerts} />
    </div>
  );
};

export default Alerts;
