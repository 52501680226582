import React, { Component } from 'react';
import { connect } from 'react-redux';

import Icons from '../../../../components/icons';
import {
  Select,
  NumberField,
  TextField,
  Checkbox,
} from '../../../../components/fields';
import Tooltip from '../../../../components/tooltip';
import { clone } from '../../../../system/object';
import { t } from '../../../../system/ui';

const pageViewsOptionAllowedCampaignTypes = ['PUSH_PERMISSION'];

const timing = [
  { label: t('Immediate'), value: 'IMMEDIATE' },
  { label: t('Delay'), value: 'DELAY' },
  { label: t('Scroll'), value: 'SCROLL' },
  { label: t('Visitor Leaving Page'), value: 'PAGELEAVE' },
];

const frequency = [
  { label: t('Always'), value: 'ALWAYS' },
  { label: t('Session'), value: 'SESSION' },
  { label: t('Life Time'), value: 'LIFETIME' },
  { label: t('Days'), value: 'DAYS' },
];

const transformToEngagement = obj => {
  if (!obj) {
    return false;
  }
  let newType;
  switch (obj.type) {
    case 'IMMEDIATE_ACTION':
      newType = 'IMMEDIATE';
      break;
    case 'WAIT_ALL_PAGES':
      newType = 'DELAY';
      break;
    case 'WAIT_SAME_PAGE':
      newType = 'DELAY';
      break;
    case 'SCROLL':
      newType = 'SCROLL';
      break;
    case 'LEAVE_PAGE':
      newType = 'PAGELEAVE';
      break;
    case 'PAGE_VIEWS':
      newType = 'PAGE_VIEWS';
      break;
    case 'ALWAYS':
      newType = 'ALWAYS';
      break;
    case 'PER_SESSION':
      newType = 'SESSION';
      break;
    case 'PER_LIFETIME':
      newType = 'LIFETIME';
      break;
    case 'PER_DAYS':
      newType = 'DAYS';
      break;
    default:
      newType = '';
  }
  return {
    param: obj.time || obj.times || '',
    type: newType,
  };
};

const defaults = {
  timing: {
    type: 'IMMEDIATE',
    param: '',
  },
  frequency: {
    type: 'ALWAYS',
    param: '',
  },
};

class TimingOptions extends Component {
  constructor(props) {
    super(props);

    const timingClone = [...timing];
    if (pageViewsOptionAllowedCampaignTypes.includes(props.campaignType)) {
      timingClone.push({
        label: t('Page Views'),
        value: 'PAGE_VIEWS',
      });
    }

    this.state = {
      values: clone(defaults),
      delayAllPages: false,
      timingOptions: [...timingClone],
    };

    this.handleTiming = this.handleTiming.bind(this);
    this.handleTimingParam = this.handleTimingParam.bind(this);
    this.handleFrequency = this.handleFrequency.bind(this);
    this.handleFrequencyParam = this.handleFrequencyParam.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeParam = this.onChangeParam.bind(this);
    this.update = this.update.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.onDelayTypeChange = this.onDelayTypeChange.bind(this);
  }

  componentDidMount() {
    if (this.props.duplicate && this.props.duplicate.isDuplicate) {
      this.update(
        this.props,
        this.props.duplicate.duplicatedValues[9],
        this.props.duplicate.duplicatedValues[10],
      );
    } else {
      this.update(this.props);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.update(newProps);
    }
  }

  handleTiming(selection) {
    this.onChange(selection, 'timing');
  }

  handleFrequency(selection) {
    this.onChange(selection, 'frequency');
  }

  updateValue(newValue, type) {
    const realValue = newValue;
    let isDelayAll = false;
    if (realValue.type === 'DELAY_ALL_PAGES') {
      realValue.type = 'DELAY';
      isDelayAll = true;
    }
    const { values } = this.state;
    values[type] = newValue;
    if (type === 'timing') {
      this.setState({
        values,
        delayAllPages: isDelayAll,
      });
    } else {
      this.setState({
        values,
      });
    }
  }

  handleTimingParam(e) {
    this.onChangeParam(e.target.value, 'timing');
  }

  handleFrequencyParam(e) {
    this.onChangeParam(e.target.value, 'frequency');
  }

  onChangeParam(param, type) {
    const { values } = this.state;
    values[type].param = param;
    this.setState({ values });
  }

  onChange(selection, type) {
    const { values } = this.state;
    values[type].type = selection.value;
    this.setState({ values });
  }

  onDelayTypeChange() {
    this.setState({
      delayAllPages: !this.state.delayAllPages,
    });
  }

  update(props, duplicatedTiming, duplicatedFrequency) {
    let timing = props.campaign.timing || clone(defaults.timing);
    let frequency = props.campaign.frequency || clone(defaults.frequency);

    if (props.isRecommendationModule) {
      timing =
        transformToEngagement(props.campaign.actions[0].delayAction) ||
        clone(defaults.timing);
      frequency =
        transformToEngagement(props.campaign.actions[0].constraint) ||
        clone(defaults.frequency);
      if (props.campaign.actions[0].delayAction) {
        timing.type =
          props.campaign.actions[0].delayAction.type === 'WAIT_ALL_PAGES'
            ? 'DELAY_ALL_PAGES'
            : timing.type;
      }
    }

    if (duplicatedTiming && duplicatedFrequency) {
      timing = transformToEngagement(duplicatedTiming);
      frequency = transformToEngagement(duplicatedFrequency);
      if (timing) {
        timing.type =
          transformToEngagement(duplicatedTiming).type === 'WAIT_ALL_PAGES'
            ? 'DELAY_ALL_PAGES'
            : transformToEngagement(duplicatedTiming).type;
      }
    }

    this.updateValue(timing, 'timing');
    this.updateValue(frequency, 'frequency');
  }

  render() {
    if (!this.props.isPush && !this.props.isTimingVisible) {
      return false;
    }

    return (
      <>
        <h3 className='wizard-input-type-title'>
          <Icons name='clock' />
          {t('Timing & Frequency')}
          <span className='field-tooltip-icon'>
            <Tooltip
              content={t(`timingTitle_${this.props.campaignType}`)}
              alignment='left'
            >
              <i className='icon-info' role='presentation'>
                <span className='for-screenreader-only' />
              </i>
            </Tooltip>
          </span>
        </h3>
        <div className='wizard-input-type-wrapper'>
          <ol className='form-elements'>
            <li className='row'>
              <label className='item item-stacked one-whole'>
                <h4 className='item-label' style={{ fontWeight: 'normal' }}>
                  {t('Timing')}
                  <span className='field-tooltip-icon'>
                    <Tooltip
                      content={t(`timing_${this.props.campaignType}`)}
                      alignment='left'
                    >
                      <i className='icon-info' role='presentation'>
                        <span className='for-screenreader-only' />
                      </i>
                    </Tooltip>
                  </span>
                </h4>
              </label>
              <div className='timing-row'>
                <Select
                  name='timing'
                  className='item-field large'
                  label=''
                  value={this.state.values.timing.type}
                  options={this.state.timingOptions}
                  onChange={this.handleTiming}
                />
                {this.state.values.timing.type === 'DELAY' && (
                  <div>
                    <NumberField
                      name='timingParam'
                      label={t('seconds')}
                      labelPlace='after'
                      inputClassName='x-small'
                      value={this.state.values.timing.param}
                      onChange={this.handleTimingParam}
                      min='0'
                      step='1'
                    />
                    <div>
                      <Checkbox
                        name='delayAllPages'
                        label='Keep on page change'
                        checked={this.state.delayAllPages}
                        onChange={this.onDelayTypeChange}
                      />
                    </div>
                  </div>
                )}
                {this.state.values.timing.type === 'SCROLL' && (
                  <NumberField
                    name='timingParam'
                    label='%'
                    labelPlace='after'
                    inputClassName='x-small'
                    value={this.state.values.timing.param}
                    onChange={this.handleTimingParam}
                    min='0'
                    step='1'
                  />
                )}
                {this.state.values.timing.type === 'PAGE_VIEWS' && (
                  <div>
                    <NumberField
                      name='timingParam'
                      label={t('Show when')}
                      labelPlace='before'
                      inputClassName='x-small'
                      value={this.state.values.timing.param}
                      onChange={this.handleTimingParam}
                      min='0'
                      step='1'
                    />
                    <label
                      className='item item-label'
                      style={{ margin: '0.5rem' }}
                    >
                      page views
                    </label>
                  </div>
                )}
              </div>
            </li>
            <li className='row'>
              <label className='item item-stacked one-whole'>
                <h4 className='item-label' style={{ fontWeight: 'normal' }}>
                  {t('Frequency')}
                  <span className='field-tooltip-icon'>
                    <Tooltip
                      content={t(`frequency_${this.props.campaignType}`)}
                      alignment='left'
                    >
                      <i className='icon-info' role='presentation'>
                        <span className='for-screenreader-only' />
                      </i>
                    </Tooltip>
                  </span>
                </h4>
              </label>
              <Select
                name='frequency'
                className='item-field large'
                label=''
                value={this.state.values.frequency.type}
                options={frequency}
                onChange={this.handleFrequency}
              />
              {this.state.values.frequency.type === 'DAYS' && (
                <TextField
                  name='frequencyParam'
                  label='days'
                  labelPlace='after'
                  inputClassName='x-small'
                  value={this.state.values.frequency.param}
                  onChange={this.handleFrequencyParam}
                />
              )}
            </li>
          </ol>
        </div>
      </>
    );
  }
}

const MapStatesToProps = store => ({
  isTimingVisible: store.wizard.isTimingVisible,
  editForm: store.wizard.editForm,
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  TimingOptions,
);
