/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { mediums } from "../../constants/datamaps/campaigns";

import { t } from "../../system/ui";

import { uiActions } from "../../actions";

import { CheckboxGroup, Checkbox } from "../fields";

class WizardMediumSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      device: "PCTABLET",
      web: ["PCTABLET"],
      app: [],
      mediums: ["PCTABLET"],
      hasError: false,
    };

    if (this.props.duplicate) {
      let web = [],
        app = [];
      this.props.duplicate.isDuplicate &&
        this.props.duplicate.duplicatedValues[4].forEach(device => {
          if (device === "PCTABLET" || device === "MOBILE") {
            web.push(device);
          }

          if (device === "ANDROID" || device === "IOS") {
            app.push(device);
          }
        });

      this.state = {
        app: app,
        web: web,
        mediums: app.concat(web),
        hasError: app.concat(web).length === 0,
      };
    }

    this.update = this.update.bind(this);
    this.validate = this.validate.bind(this);
    this.toggleWeb = this.toggleWeb.bind(this);
    this.toggleApp = this.toggleApp.bind(this);
    this.setDevice = this.setDevice.bind(this);
    this.mediumSelection = this.mediumSelection.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      let devices = mediums[newProps.campaign.device];

      this.update(devices);

      this.setState({
        device: newProps.campaign.device
      });
    }

    if (newProps.validate === true) {
      this.validate();
    }
  }

  componentDidMount() {
    if (this.props.duplicate) {
      this.setDevice();
    }
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldState.mediums !== this.state.mediums) {
      this.setDevice();
      this.validate();
    }
  }

  validate() {
    if (this.state.mediums.length === 0) {
      this.setState({
        hasError: true
      });
    } else {
      this.setState({
        hasError: false
      });
    }
  }

  update(devices) {
    let web = [],
      app = [];

    devices.forEach(device => {
      if (device === "PCTABLET" || device === "MOBILE") {
        web.push(device);
      }

      if (device === "ANDROID" || device === "IOS") {
        app.push(device);
      }
    });

    this.setState({
      app: app,
      web: web,
      mediums: app.concat(web)
    });
  }

  setDevice() {
    let device = "";

    if (this.state.web.length > 0 && this.state.app.length > 0) {
      device = "ALL";
    } else if (this.state.web.length > 1) {
      device = "PCMOBILETABLET";
    } else if (this.state.app.length > 1) {
      device = "ANDROIDIOS";
    } else {
      device = this.state.mediums.join("");
    }

    this.setState({
      device: device
    });
  }

  mediumSelection(value) {
    this.update(value);
    uiActions.formEdited();
  }

  toggleWeb() {
    let web = [],
      app = this.state.app;

    if (this.state.web.length < 2) {
      web = ["PCTABLET", "MOBILE"];
    }

    this.setState({
      web: web,
      mediums: app.concat(web)
    });
  }

  toggleApp() {
    let app = [],
      web = this.state.web;

    if (this.state.app.length < 2) {
      app = ["ANDROID", "IOS"];
    }

    this.setState({
      app: app,
      mediums: app.concat(web)
    });
  }

  render() {
    return (
      <div
        className={classNames("wizard-medium-selection", {
          "has-error": this.state.hasError
        })}
      >
        <label className="item item-stacked label-emphasise one-whole">
          <span className="item-label">{t("Device Types")}</span>
        </label>

        <CheckboxGroup
          value={this.state.mediums}
          className="device-type-selection"
          onChange={this.mediumSelection}
        >
          <div className="check-group">
            <div className="check-group-header">
              {t("Web")}
              <span
                className={classNames("check-group-toggle", {
                  checked: this.state.web.length === 2,
                  "semi-checked": this.state.web.length === 1
                })}
                onClick={this.toggleWeb}
              />
            </div>
            <div className="device-types check-group-drop">
              <Checkbox label="Desktop" value="PCTABLET" />
              <Checkbox label="Mobile Web" value="MOBILE" />
            </div>
          </div>
          <div className="check-group">
            <div className="check-group-header">
              {t("App")}
              <span
                className={classNames("check-group-toggle", {
                  checked: this.state.app.length === 2,
                  "semi-checked": this.state.app.length === 1
                })}
                onClick={this.toggleApp}
              />
            </div>
            <div className="os-types check-group-drop">
              <Checkbox label="Android" value="ANDROID" />
              <Checkbox label="iOS" value="IOS" />
            </div>
          </div>
        </CheckboxGroup>
        {this.state.hasError ? (
          <span className="item-error">
            {t("You should select at least one device type")}
          </span>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate
});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
  WizardMediumSelection
);
