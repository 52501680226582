import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Icon from '../icon';
import Icons from '../icons';
import { TextField } from '../fields';
import {
  filterActions,
  modalActions,
  dialogActions,
  uiActions,
} from '../../actions';
import { trendifyFiltersQuery } from '../../constants/datamaps/analytics';
import { getCustomTrendifyColumns } from '../../modules/auth/user';
import { searchCategories } from '../../modules/category/ajax';
import {
  getAnalyticsUserFilters,
  removeAnalyticsUserFilters,
} from '../../modules/trendify/ajax';
import { clone } from '../../system/object';
import { t } from '../../system/ui';
import DeviceSelect from './device-select';
import TfyFilterModal from './tfy-filter-modal';
import { getCategoryBrandLabel } from './utils';

class TrendifyFilters extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchedCategory: '',
      selectedCategories: [],
      searchedBrand: '',
      selectedBrands: [],
      productName: '',
      minPrice: '',
      maxPrice: '',
      maxPriceMin: 0,
      maxPriceError: false,
      stock: 'all',
      device: 'all',
      categories: [],
      customFields: [],
      showCategories: false,
      showBrands: false,
      filters: [],
      selectedFilter: 'none',
    };

    this.reset = this.reset.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeMin = this.onChangeMin.bind(this);
    this.onChangeMax = this.onChangeMax.bind(this);
    this.onChangeStock = this.onChangeStock.bind(this);
    this.onSearchCategory = this.onSearchCategory.bind(this);
    this.onSelectCategory = this.onSelectCategory.bind(this);
    this.onDeselectCategory = this.onDeselectCategory.bind(this);
    this.onSelectBrand = this.onSelectBrand.bind(this);
    this.onDeselectBrand = this.onDeselectBrand.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.getCustomFieldComp = this.getCustomFieldComp.bind(this);
    this.setCustomFields = this.setCustomFields.bind(this);
    this.onChangeStateEl = this.onChangeStateEl.bind(this);
    this.onDeviceChange = this.onDeviceChange.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.saveFilter = this.saveFilter.bind(this);
    this.onFilterSelect = this.onFilterSelect.bind(this);
  }

  componentDidMount() {
    this.setCustomFields();

    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.reset === true) {
      this.reset();
    }
  }

  setCustomFields() {
    const customFieldsToState = {};
    customFieldsToState.customFields = [];
    if (getCustomTrendifyColumns()) {
      getCustomTrendifyColumns().forEach(column => {
        if (column.filterable) {
          customFieldsToState[column.field] = '';
          customFieldsToState.customFields.push({
            name: column.field,
            dataType: column.dataType,
            label: column.label,
            values: [],
          });
        }
      });
    }
    this.setState(customFieldsToState, () => {
      this.getFilters();
    });
  }

  getFilters(filterName) {
    getAnalyticsUserFilters(filters => {
      this.setState({
        filters,
        selectedFilter: filterName
          ? filters.filter(flt => flt.name === filterName)[0].name
          : this.state.selectedFilter,
      });
    });
  }

  onChangeStateEl(customField, selectedOption) {
    const customObj = {};
    customObj[customField] = selectedOption.value;
    this.setState(customObj);

    filterActions.showButtons();
  }

  resetFilters(e) {
    e.preventDefault();
    this.props.toggleAnalyticsFilters();
    filterActions.resetAllFilters();
  }

  updateFilters(e) {
    e.preventDefault();
    this.props.toggleAnalyticsFilters();
    if (this.props.disableButtons) {
      return false;
    }
    filterActions.updateFilters();
  }

  handleClick(event) {
    const newState = {};
    if (event.target !== this.refs.categoryClickHandler) {
      if (
        this.refs.categoryHolder &&
        this.refs.categoryHolder.contains(event.target)
      ) {
        newState.showCategories = true;
      }
      if (
        this.refs.categoryHolder &&
        !this.refs.categoryHolder.contains(event.target)
      ) {
        newState.showCategories = false;
      }
    }
    if (event.target !== this.refs.brandClickHandler) {
      if (
        this.refs.brandHolder &&
        this.refs.brandHolder.contains(event.target)
      ) {
        newState.showBrands = true;
      }
      if (
        this.refs.brandHolder &&
        !this.refs.brandHolder.contains(event.target)
      ) {
        newState.showBrands = false;
      }
    }
    this.setState(newState);
  }

  toggleSelect(selectType) {
    if (selectType === 'Categories') {
      this.setState({ showCategories: !this.state.showCategories });
    } else {
      this.setState({ showBrands: !this.state.showBrands });
    }
  }

  onChange(e) {
    e.preventDefault();
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        filterActions.showButtons();
      },
    );
  }

  onChangeMin(e) {
    filterActions.showButtons();
    filterActions.enableButtons();

    const maxPrice = this.refs.maxPrice.value * 1;

    this.setState({
      maxPriceMin: e.currentTarget.value,
      minPrice: e.currentTarget.value,
    });

    if (maxPrice > 0 && e.currentTarget.value > maxPrice) {
      this.setState({
        maxPriceError: true,
      });

      filterActions.disableButtons();
    }
  }

  onChangeMax(e) {
    filterActions.showButtons();

    if (
      e.currentTarget.value !== '' &&
      e.currentTarget.value * 1 < this.state.maxPriceMin * 1
    ) {
      this.setState({
        maxPriceError: true,
        maxPrice: e.currentTarget.value,
      });
      filterActions.disableButtons();
    } else {
      this.setState({
        maxPriceError: false,
        maxPrice: e.currentTarget.value,
      });

      filterActions.enableButtons();
    }
  }

  onChangeStock(selectedOption) {
    this.setState({
      stock: selectedOption.value,
    });

    filterActions.showButtons();
  }

  onDeviceChange(device) {
    this.setState({
      device,
    });
  }

  onSelectCategory(cat) {
    this.setState({
      selectedCategories: this.state.selectedCategories.concat([cat]),
      searchedCategory: '',
      categories: [],
    });
    filterActions.showButtons();
  }

  onDeselectCategory(deselectedCat) {
    this.setState({
      selectedCategories: this.state.selectedCategories.filter(
        cat => cat !== deselectedCat,
      ),
    });
  }

  onSearchCategory(e) {
    const newCat = e.target.value;
    this.setState(
      {
        searchedCategory: newCat,
      },
      () => {
        if (newCat.length > 2) {
          searchCategories(newCat, response => {
            this.setState({
              categories: response || [],
            });
          });
        } else {
          this.setState({
            categories: [],
          });
        }
      },
    );
  }

  onSelectBrand(brand) {
    if (brand.label === 'All Product Brands') return;
    this.setState({
      selectedBrands: this.state.selectedBrands.concat([brand.value]),
      searchedBrand: '',
    });
    filterActions.showButtons();
  }

  onDeselectBrand(deselectedBrand) {
    this.setState({
      selectedBrands: this.state.selectedBrands.filter(
        brand => brand !== deselectedBrand,
      ),
    });
  }

  getCustomFieldComp(customField) {
    if (customField.dataType === 'string') {
      return (
        <TextField
          name={customField.name}
          value={this.state[customField.name]}
          className='item-stacked'
          label={customField.label}
          placeholder={customField.label}
          onChange={this.onChange}
        />
      );
    }
    if (customField.dataType === 'boolean') {
      const fieldOptions = [
        { value: 'all', label: t(customField.label) },
        { value: 'true', label: t('True') },
        { value: 'false', label: t('False') },
      ];
      return (
        <label
          className='item item-field is-select'
          style={{ minWidth: `${customField.name.length * 18}px` }}
        >
          <Select
            searchable={false}
            clearable={false}
            value={this.state[customField.name]}
            options={fieldOptions}
            name={customField.name}
            onChange={this.onChangeStateEl.bind(this, customField.name)}
          />
        </label>
      );
    }
    if (
      customField.dataType === 'currency' ||
      customField.dataType === 'number'
    ) {
      return (
        <span>
          <label className='item item-stacked price-range price-range-min'>
            <span className='item-label'>{t(customField.label)}</span>
            <span className='item-label-alt'>{t('min')}:</span>
            <input
              type='number'
              id={`${customField.name}min`}
              name={`${customField.name}.min`}
              value={this.state[`${customField.name}.min`]}
              ref={`${customField.name}min`}
              min='0'
              step='1'
              onChange={this.onChange}
            />
          </label>

          <label className='item item-stacked price-range'>
            <span className='item-label'>&nbsp;</span>
            <span className='item-label-alt'>{t('max')}:</span>
            <input
              type='number'
              id={`${customField.name}max`}
              name={`${customField.name}.max`}
              value={this.state[`${customField.name}.max`]}
              ref={`${customField.name}max`}
              min='0'
              step='1'
              onChange={this.onChange}
            />
          </label>
        </span>
      );
    }
  }

  reset() {
    this.refs.filters.querySelector('[name=productName]').value = '';
    this.refs.minPrice.value = '';
    this.refs.maxPrice.value = '';

    const fieldsToState = {
      category: '',
      brand: '',
      stock: 'all',
      searchedCategory: '',
      selectedCategories: [],
      searchedBrand: '',
      selectedBrands: [],
      selectedFilter: 'none',
      device: 'all',
      minPrice: '',
      maxPrice: '',
    };

    this.state.customFields.forEach(customField => {
      if (customField.dataType === 'string') {
        fieldsToState[customField.name] = '';
      } else if (
        customField.dataType === 'number' ||
        customField.dataType === 'currency'
      ) {
        fieldsToState[`${customField.name}.min`] = '';
        fieldsToState[`${customField.name}.max`] = '';
      } else if (customField.dataType === 'boolean') {
        fieldsToState[customField.name] = 'all';
      }
    });

    this.setState(fieldsToState);
  }

  saveFilter() {
    const query = clone(trendifyFiltersQuery);

    query.brand = this.state.selectedBrands;
    query.category = this.state.selectedCategories;
    query.product = this.state.productName;
    query.device = this.state.device;
    query.minPrice = this.state.minPrice;
    query.maxPrice = this.state.maxPrice;
    if (this.state.stock !== 'all') {
      query.stock = this.state.stock;
    }

    query.custom = {};

    this.state.customFields.forEach(customField => {
      if (
        customField.dataType === 'currency' ||
        customField.dataType === 'number'
      ) {
        query.custom[`${customField.name}.min`] = this.state[
          `${customField.name}.min`
        ];
        query.custom[`${customField.name}.max`] = this.state[
          `${customField.name}.max`
        ];
      } else {
        query.custom[customField.name] = this.state[customField.name];
      }
    });

    modalActions.openModal({
      title: 'Save Filter',
      className: 'tfy-filter-modal',
      content: () => (
        <TfyFilterModal
          currentFilters={query}
          filters={this.state.filters}
          selectedFilter={this.state.selectedFilter}
          getFilters={this.getFilters}
        />
      ),
    });
  }

  onFilterSelect(selectedOption) {
    filterActions.showButtons();
    const selectedFilter = this.state.filters.filter(
      flt => flt.name === selectedOption.value,
    );
    this.setState({
      ...this.state,
      selectedFilter: selectedOption.value,
      selectedBrands: selectedFilter[0].data.brand,
      selectedCategories: selectedFilter[0].data.category,
      productName: selectedFilter[0].data.product,
      device: selectedFilter[0].data.device,
      minPrice: selectedFilter[0].data.minPrice,
      maxPrice: selectedFilter[0].data.maxPrice,
      stock:
        Object.keys(selectedFilter[0].data).indexOf('stock') > -1
          ? selectedFilter[0].data.stock
          : 'all',
      ...(selectedFilter[0].data.custom || {}),
    });
  }

  onFilterRemove(filterName, event) {
    event.stopPropagation();
    const filterToRemove = this.state.filters.filter(
      flt => flt.name === filterName,
    )[0];
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('Do you want to delete the filter?'),
      onConfirm: () => {
        removeAnalyticsUserFilters(filterToRemove, response => {
          if (response) {
            uiActions.showNotification({
              content: () => (
                <div>{t('The filter was successfully deleted.')}</div>
              ),
            });
            this.setState({
              filters: this.state.filters.filter(
                flt => flt.name !== filterName,
              ),
            });
          }
        });
        dialogActions.closeDialog();
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  render() {
    const stocks = [
      { value: 'all', label: t('Product Stock') },
      { value: 'true', label: t('Only In Stock') },
      { value: 'false', label: t('Only Out of Stock') },
    ];

    const brands = [{ value: '', label: t('All Product Brands') }];

    this.props.brands.map(item => {
      brands.push({
        value: item,
        label: item,
      });
    });

    const categoryCSS = this.state.showCategories ? 'block' : 'none';
    const brandCSS = this.state.showBrands ? 'block' : 'none';
    const className = this.props.showTrendifyFilters
      ? 'analytics-filters group show-filters'
      : 'analytics-filters group hide-filters';

    return (
      <div className={className} ref='filters'>
        <label
          className='item item-stacked is-select large analytics--multiselect category-label'
          ref='categoryHolder'
        >
          <span
            className='item-label'
            ref='categoryClickHandler'
            onClick={this.toggleSelect.bind(this, 'Categories')}
          >
            {t('Product Category')}
          </span>
          <span className='multiselect--placeholder'>
            {getCategoryBrandLabel(
              'category',
              this.state.selectedCategories.length,
            )}
          </span>
          <div
            className='category-holder analytics--multiselect--holder'
            style={{ display: categoryCSS }}
          >
            <div className='multiselect--items__selected'>
              <ul>
                {this.state.selectedCategories.map(cat => (
                  <li
                    key={`${cat}Selected`}
                    data-selectedval={cat}
                    className='multiselect--item__selected tfy-selected-category'
                    title={cat}
                  >
                    <i
                      className='icon-cross'
                      onClick={this.onDeselectCategory.bind(this, cat)}
                    />
                    <span>
                      {cat.length > 10 ? `${cat.substring(0, 10)}...` : cat}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className='multiselect--items--search'>
              <input
                type='text'
                placeholder={t('Search')}
                onChange={this.onSearchCategory}
                value={this.state.searchedCategory}
              />
              <Icon name='magnify' />
            </div>
            <ul className='multiselect--items--list'>
              {this.state.categories.map(cat => (
                <li
                  key={cat}
                  className='multiselect--item'
                  onClick={this.onSelectCategory.bind(this, cat)}
                >
                  {cat}
                </li>
              ))}
            </ul>
          </div>
        </label>

        <label
          className='item item-stacked is-select large analytics--multiselect brand-label'
          ref='brandHolder'
        >
          <span
            className='item-label'
            ref='brandClickHandler'
            onClick={this.toggleSelect.bind(this, 'Brands')}
          >
            {t('Product Brand')}
          </span>
          <span className='multiselect--placeholder'>
            {getCategoryBrandLabel('brand', this.state.selectedBrands.length)}
          </span>
          <div
            className='brand-holder analytics--multiselect--holder'
            style={{ display: brandCSS }}
          >
            <div className='multiselect--items__selected'>
              <ul>
                {this.state.selectedBrands.map(brand => (
                  <li
                    key={`${brand}Selected`}
                    data-selectedval={brand}
                    className='multiselect--item__selected tfy-selected-brand'
                  >
                    <i
                      className='icon-cross'
                      onClick={this.onDeselectBrand.bind(this, brand)}
                    />
                    <span>
                      {brand.length > 10
                        ? `${brand.substring(0, 10)}...`
                        : brand}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className='multiselect--items--search'>
              <Select
                arrowRenderer={() => <Icon name='magnify' />}
                type='text'
                placeholder={t('Search')}
                options={brands}
                onChange={this.onSelectBrand}
                value={this.state.searchedBrand}
              />
            </div>
          </div>
        </label>

        <TextField
          name='productName'
          className='item-stacked'
          label='Search Product'
          placeholder='Product ID or Title'
          value={this.state.productName}
          onChange={this.onChange}
        />

        <label className='item item-stacked price-range price-range-min'>
          <span className='item-label'>{t('Price Range')}</span>
          <span className='item-label-alt'>{t('min')}:</span>
          <input
            type='number'
            id='minPrice'
            ref='minPrice'
            min='0'
            step='1'
            onChange={this.onChangeMin}
            value={this.state.minPrice}
          />
        </label>

        <label className='item item-stacked price-range'>
          <span className='item-label'>&nbsp;</span>
          <span className='item-label-alt'>{t('max')}:</span>
          <input
            type='number'
            id='maxPrice'
            ref='maxPrice'
            min={this.state.maxPriceMin}
            step='1'
            onChange={this.onChangeMax}
            value={this.state.maxPrice}
          />
          {this.state.maxPriceError && (
            <span className='item-error'>
              {t('This number should bigger than min price')}
            </span>
          )}
        </label>

        <label
          className='item item-field is-select has-icon-label'
          style={{ width: '160px' }}
        >
          <span className='item-label'>
            <Icon name='stock' />
          </span>
          <Select
            searchable={false}
            clearable={false}
            value={this.state.stock}
            options={stocks}
            name='productStock'
            onChange={this.onChangeStock}
          />
        </label>

        <DeviceSelect
          onDeviceChange={this.onDeviceChange}
          selectedDevice={this.state.device}
        />
        {this.state.customFields.map(customField =>
          this.getCustomFieldComp(customField),
        )}
        <div
          className='analytics-filters-close'
          onClick={this.props.toggleAnalyticsFilters}
        >
          <Icons name='cross' color='#7b7e7e' />
        </div>
        <div className='analytics-filters-actions'>
          <label
            className='item item-field is-select has-icon-label'
            style={{
              width: '160px',
              textAlign: 'left',
              position: 'relative',
              top: '-8px',
              right: '-10px',
            }}
          >
            <span>
              <Select
                searchable={false}
                clearable={false}
                value={this.state.selectedFilter}
                options={this.state.filters.map(filter => {
                  return { value: filter.name, label: filter.name };
                })}
                name='filters'
                placeholder='Load Filter'
                onChange={this.onFilterSelect}
                optionRenderer={option => {
                  return (
                    <span
                      style={{
                        position: 'relative',
                        minWidth: '100%',
                        display: 'inline-block',
                      }}
                      className='filter-select-option'
                    >
                      {option.label}
                      <span
                        onMouseDown={this.onFilterRemove.bind(
                          this,
                          option.value,
                        )}
                        style={{
                          position: 'absolute',
                          right: '5px',
                        }}
                      >
                        <Icons
                          name='cross'
                          width={14}
                          height={14}
                          color='#c7cbd1'
                        />
                      </span>
                    </span>
                  );
                }}
              />
            </span>
          </label>
          <a
            className='cancel-action analytics-filters--reset'
            onClick={this.saveFilter}
          >
            {t('Save Filter')}
          </a>
          <a
            className='cancel-action analytics-filters--reset'
            onClick={this.resetFilters}
            style={{
              opacity: this.props.visibleReset ? '1' : '0.6',
              pointerEvents: this.props.visibleReset ? 'auto' : 'none',
            }}
          >
            {t('Reset Filters')}
          </a>

          <a
            className={classNames('tertiary-action analytics-filters--update', {
              disabled: this.props.disableButtons,
            })}
            onClick={this.updateFilters}
          >
            {t('Update Results')}
          </a>
        </div>
      </div>
    );
  }
}

const mapStatsToProps = store => ({
  brands: store.filters.trendifyBrands,
  reset: store.filters.reset,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  visibleReset: store.filters.visibleReset,
  disableButtons: store.filters.disableButtons,
});

export default connect(mapStatsToProps)(TrendifyFilters);
