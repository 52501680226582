import React from 'react';
import { fixedPxValue } from './pcBigger';

const EmailMessageCanvas = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 498 86'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width="498" height="86" fill="#EFEFEF"/>
      <circle cx="31" cy="32" r="20" fill="#D9D9D9"/>
      <rect x="61" y="15" width="147.13" height="8" fill="#D9D9D9"/>
      <rect x="61" y="36" width="423" height="4" fill="#D9D9D9"/>
      <rect x="61" y="43" width="300.069" height="4" fill="#D9D9D9"/>
    </svg>
  );
};

EmailMessageCanvas.defaultProps = {
  width: '498px',
  height: '86px',
};

export default EmailMessageCanvas;
