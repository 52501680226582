import React from "react";

class IconReco extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={this.state.width}
        height={this.state.height}
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="icon/recommendation"
            transform="translate(-3.000000, -3.000000)"
            fill={this.state.color}
            fillRule="nonzero"
          >
            <path
              d="M14.7899125,26.4297604 L14.4997917,26.1996646 C14.0295958,25.8145042 2.995,16.6957063 2.995,10.3830771 C2.99594199,7.57213474 4.76734507,5.06655984 7.4170602,4.12825952 C10.0667753,3.18995921 13.0202162,4.02240236 14.7899125,6.2063375 C16.5578717,4.02038545 19.5118946,3.18650546 22.1619318,4.12531796 C24.811969,5.06413047 26.5822508,7.57166103 26.5798229,10.3830771 C26.5798229,16.7107125 15.540225,25.8145042 15.0750312,26.1996646 L14.7899125,26.4297604 Z M9.63276458,4.6456875 C6.4663756,4.65119427 3.90088177,7.2166881 3.895375,10.3830771 C3.895375,15.7503125 13.0441854,23.7786563 14.7899125,25.2542708 C16.5356396,23.7536458 25.6794479,15.7503125 25.6794479,10.3730729 C25.6772886,7.84711932 24.0219934,5.62027954 21.6038392,4.89022726 C19.185685,4.16017499 16.5746626,5.09899321 15.1750729,7.20175208 L14.7999167,7.7669875 L14.4247604,7.20175208 C13.3573775,5.59978178 11.5577532,4.63985696 9.63276458,4.6456875 Z"
              id="recommendation"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default IconReco;
