/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { modalActions } from '../../actions';
import { openUrlInNewTab } from './utils';
import { latestFeaturesModalTypes } from './enums';

const ContentNoImage = ({ descriptions }) => (
  <div className='feature-modal-content'>
    <div className='content-text'>
      <span className='msp-features-item-text'>
        {descriptions.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </span>
    </div>
  </div>
);

const ContentRowImage = ({ image, descriptions }) => {
  const firstParagraph = descriptions[0];
  const otherParagraphs = descriptions.slice(1);

  return (
    <div className='feature-modal-content'>
      <div className='feature-modal-column'>
        <div className='content-image image-area'>
          <img src={image} alt='placeholder' />
        </div>
        <div className='content-text text-area'>
          <span className='msp-features-item-text'>{firstParagraph}</span>
        </div>
      </div>
      <div className='content-text'>
        <span className='msp-features-item-text'>
          {otherParagraphs.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </span>
      </div>
    </div>
  );
};

const ContentColumnImage = ({ image, descriptions }) => {
  const imageContainerRef = useRef(null);
  const [height, setHeight] = useState(0);

  const getHeight = () => {
    if (
      imageContainerRef.current &&
      imageContainerRef.current.clientHeight !== height
    ) {
      setHeight(imageContainerRef.current.clientHeight);
    }
  };

  useEffect(() => {
    modalActions.calculateModalHeight();
  }, [height]);

  return (
    <div className='feature-modal-content'>
      <div className='content-text'>
        <span className='msp-features-item-text'>
          {descriptions.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </span>
      </div>
      <div className='content-image' ref={imageContainerRef}>
        <img src={image} alt='placeholder' onLoad={() => getHeight()} />
      </div>
    </div>
  );
};

const LatestFeatureModal = ({
  title,
  descriptions,
  image,
  redirectUrl,
  redirectText,
  styleType,
}) => {
  const getContent = () => {
    if (!image) {
      return <ContentNoImage descriptions={descriptions} />;
    }

    switch (styleType) {
      case latestFeaturesModalTypes.NO_IMAGE:
        return <ContentNoImage descriptions={descriptions} />;
      case latestFeaturesModalTypes.ROW:
        return <ContentRowImage image={image} descriptions={descriptions} />;
      case latestFeaturesModalTypes.COLUMN:
        return <ContentColumnImage image={image} descriptions={descriptions} />;
      default:
        return <ContentNoImage descriptions={descriptions} />;
    }
  };

  return (
    <div className='feature-modal-container'>
      <div className='feature-modal-title'>{title}</div>
      {getContent()}
      <div className='feature-modal-footer'>
        <div
          className='footer-button'
          onClick={() => openUrlInNewTab(redirectUrl)}
        >
          {redirectText && (
            <span>
              {redirectText}
              {'  >'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

LatestFeatureModal.propTypes = {
  title: PropTypes.string.isRequired,
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.string,
  redirectUrl: PropTypes.string.isRequired,
  redirectText: PropTypes.string.isRequired,
  styleType: PropTypes.oneOf(Object.values(latestFeaturesModalTypes))
    .isRequired,
};

LatestFeatureModal.defaultProps = {
  image: '',
};

export default LatestFeatureModal;
