"use strict";

import React from "react";
import Icons from "../icons";
import { t } from "../../system/ui";
import SearchSynonymsItem from "./search-synonyms-item";
import { algorithmDescriptions } from "../../constants/datamaps/wizard";
import { idPropFunc } from "../../system/id";

const shouldComponentUpdate = (prevProps, nextProps) => {
  const jsonSynonymInnerItemsPrev = JSON.parse(JSON.stringify(prevProps.synonymInnerItems));
  const jsonSynonymInnerItemsNext = JSON.parse(JSON.stringify(nextProps.synonymInnerItems));
  return jsonSynonymInnerItemsNext === jsonSynonymInnerItemsPrev;
}


export const SearchSynonymsItemGroup = (props) => {
  const { operator, showSize, className, selectedLanguage } = props;
  const synonymInnerItems = JSON.parse(JSON.stringify(props.synonymInnerItems));
  // const prevProps = usePrevious(props);
  const spliceComponent = () => {
    if (synonymInnerItems && synonymInnerItems.length > 0) {
      return synonymInnerItems.slice(0, showSize);
    }
  }

  return (
    <li className={"wizard-criterion " + className} {...idPropFunc(props.id)}>
      <span className="criteria-type">
        <Icons name={"excludeLabel"} color="#787775" />
      </span>
      <span>{algorithmDescriptions()[operator].title}</span>
      <p className="wizard-comp-description search__m_t_20">
        {t(algorithmDescriptions()[operator].description)}
      </p>
      {spliceComponent().map((item, index) => {
        
        if (index >= showSize) { return null; }
        return (<SearchSynonymsItem
          id={item.id}
          index={index}
          synonymItem={item}
          operator={operator} />);
      })}
    </li>
  );
}

export default React.memo(SearchSynonymsItemGroup, shouldComponentUpdate);
