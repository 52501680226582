/**
 * @author Bilal Cinarli
 */

"use strict";

import { createConstants } from "../../system/store";

export default createConstants(
  "USER_IS_LOGGED",
  "GET_INTEGRATIONS",
  "UPDATE_USER",
  "UPDATE_ACCOUNT",
  "UPDATE_MAIL_CONF",
  "RESET_MAIL_CONF",
  "UPDATE_CURRENCY",
  "UPDATE_REGION",
  "UPDATE_SEARCH_PROPERTY",
  "UPDATE_RECOMMENDATION_SETTINGS",
  "UPDATE_PERSONALISATION_CONFIG",
  "LOG_OUT_USER"
);
