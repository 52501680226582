const initialState = {
  image: {
    mobil: null,
    web: null,
  },
  url: {
    mobil: '',
    web: '',
  },
  radioType: {
    web: {
      url: false,
      upload: false,
    },
    mobil: {
      url: false,
      upload: false,
    },
  },
  isLoading: {
    mobil: false,
    web: false,
  },
};

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'image':
      if (payload.type === 'mobil') {
        return {
          ...state,
          image: {
            mobil: payload.image,
            web: state.image.web,
          },
        };
      }
      return {
        ...state,
        image: {
          mobil: state.image.mobil,
          web: payload.image,
        },
      };
    case 'url':
      if (payload.type === 'mobil') {
        return {
          ...state,
          url: {
            mobil: payload.url,
            web: state.url.web,
          },
        };
      }
      return {
        ...state,
        url: {
          mobil: state.url.mobil,
          web: payload.url,
        },
      };
    case 'radioType':
      if (payload.type === 'mobil') {
        return {
          ...state,
          radioType: {
            web: state.radioType.web,
            mobil: payload.radioType,
          },
        };
      }
      return {
        ...state,
        radioType: {
          web: payload.radioType,
          mobil: state.radioType.mobil,
        },
      };
    case 'isLoading':
      if (payload.type === 'mobil') {
        return {
          ...state,
          isLoading: { mobil: payload.isLoading, web: state.isLoading.web },
        };
      }
      return {
        ...state,
        isLoading: { mobil: state.isLoading.mobil, web: payload.isLoading },
      };
    default:
      return state;
  }
};

export { initialState, reducer };
