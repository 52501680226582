import React from 'react';
import { calculateRatio, numericDiffRatio } from '../../../system/ratio';

export const getSortWeightedType = type => {
  if (type === undefined || type === '') return 9;
  const weightTierMap = {
    CHURN: 0,
    FAILED: 1,
    FREEMIUM: 2,
    ONHOLD: 3,
    STAGING: 4,
    PAYING: 5,
    POC: 6,
    PRE_POC: 7,
  };
  return weightTierMap[type];
};

export const getSortWeightedPerformance = performance => {
  if (performance === undefined || performance === '') return 5;
  const weightTierMap = {
    'Well Performing': 0,
    Rising: 1,
    'Declinig Trend': 2,
    Falling: 3,
    'Still Under Target': 4,
  };
  return weightTierMap[performance.text];
};

export const getSparklineColor = value => {
  let color;
  if (value < -10) {
    color = '#DC3322';
  } else if (value >= -10 && value < -5) {
    color = 'rgb(220,51,34,0.71)';
  } else if (value >= -5 && value <= 0) {
    color = 'rgb(220,51,34,0.32)';
  } else if (value > 0 && value <= 5) {
    color = '#85DDCC';
  } else if (value > 5 && value <= 10) {
    color = '#2BC4A6';
  } else {
    color = '#00A080';
  }

  return color;
};

export const prepareGARatio = ga => {
  const value =
    parseFloat(ga[2]) === 0
      ? 0
      : (parseFloat(ga[1]) - parseFloat(ga[2])) / parseFloat(ga[2]);
  const ratio = parseFloat(value * 100).toFixed(2);
  const color = ratio >= -5 && ratio <= 5 ? 'rgb(0,0,5)' : '#cc4331';

  return (
    <div>
      {numericDiffRatio(value, 2)}
      {value !== 0 &&
        ((value < 0 && <span style={{ color }}> ▼</span>) ||
          (value > 0 && <span style={{ color }}> ▲</span>))}
    </div>
  );
};

export const preparePerformance = (array, target, totalPurchase) => {
  const performance = {};
  performance.text = 'No Data';

  const lastRealized =
    calculateRatio(array[array.length - 1], totalPurchase) - target;
  const beforeLastRealized =
    calculateRatio(array[array.length - 2], totalPurchase) - target;

  if (lastRealized >= 0 && beforeLastRealized >= 0) {
    performance.text = 'Well Performing';
    performance.color = 'Green';
  } else if (lastRealized >= 0 && beforeLastRealized < 0) {
    performance.text = 'Rising';
    performance.color = 'Blue';
  } else if (lastRealized >= 0 && lastRealized < beforeLastRealized) {
    performance.text = 'Declinig Trend';
    performance.color = 'Purple';
  } else if (lastRealized < 0 && beforeLastRealized >= 0) {
    performance.text = 'Falling';
    performance.color = 'Yellow';
  } else if (lastRealized < 0 && beforeLastRealized < 0) {
    performance.text = 'Still Under Target';
    performance.color = 'Red';
  }

  return performance;
};

export const getDefaultTargetValue = module => {
  let value;
  switch (module) {
    case 'recommendation':
      value = parseFloat(10);
      break;
    case 'search':
      value = parseFloat(5);
      break;
    case 'faceted':
      value = parseFloat(10);
      break;
    case 'push':
      value = parseFloat(2);
      break;
    default:
      value = 0;
  }
  return value;
};

export const findTotalTarget = (target, modules, stats) => {
  const sum =
    target && Object.keys(target).length > 0
      ? Object.keys(target)
          .filter(key => modules[key].status === 'ACTIVE' && stats[key])
          .map(key => parseFloat(target[key].value))
          .reduce((a, b) => a + b, 0)
      : Object.keys(modules)
          .map(key =>
            modules[key].status === 'ACTIVE' ? getDefaultTargetValue(key) : 0,
          )
          .reduce((a, b) => a + b, 0);

  return parseFloat(sum).toFixed(2);
};

export const findRealized = (realized, totalPurchase) =>
  calculateRatio(realized[realized.length - 1], totalPurchase);

export const getTargetRatio = (target, module) => {
  const checkedTarget = target[module] ? target[module] : {};

  if (Object.keys(checkedTarget).length === 0) {
    checkedTarget.value = getDefaultTargetValue(module);
  }

  return parseFloat(checkedTarget.value).toFixed(2);
};

export const getSortWeightedTiers = tier => {
  if (tier === undefined || tier === '') return 0;

  const weightTierMap = {
    'Extra Small': 1,
    Small: 2,
    Medium: 3,
    Large: 4,
    'Extra Large': 5,
  };
  return weightTierMap[tier];
};
