import React from 'react';
import { useQuery } from 'react-query';
import classes from './FlyModeEmails.module.scss';

import { t } from '../../../../system/ui';
import { hasKlaviyoConfiguration } from '../../../../modules/auth/user';

import StatsScreen from './components/StatsScreen';
import fetchData from './services/requests';
import { transformTrends } from '../../../../modules/transform';

const FlyModeEmails = ({ range, stats }) => {
  const boundaries = {};
  boundaries.start = range[0]?.format('YYYY-MM-DD');
  boundaries.end = range[1]?.format('YYYY-MM-DD');

  const getData = async () => {
    const type = 'FLY_MODE_EMAILS';
    return await fetchData(boundaries, type);
  };
  const rangeStr = boundaries.start + boundaries.end;
  const { data } = useQuery(['data4', rangeStr], getData);

  const purchaseAmount = stats?.purchase?.stats?.amount?.count;
  const transformedTrends = transformTrends(
    data,
    purchaseAmount,
    0,
    0,
    false,
    'E-Mail',
  );

  return (
    <div className={classes.component}>
      <h2 className={classes.component__header}>{t('Fly Mode Emails')}</h2>
      <StatsScreen
        hasKlaviyoConfiguration={hasKlaviyoConfiguration()}
        trends={transformedTrends}
      />
    </div>
  );
};

export default FlyModeEmails;
