import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import RootReducer from '../reducers';

// if you want to see console logs of actions add logger to middleware array.

export const store = configureStore({
  reducer: RootReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== 'prod',
});
