import React from 'react';
import { t, confirm } from '../../system/ui';
import {
  moveSegmentToLive,
  moveSegmentToTest,
} from '../../modules/segmentation/ajax';
import { filterActions } from '../../actions';

class SegmentWidgetOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLive: false,
    };

    this.setIsLive = this.setIsLive.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isLive = this.isLive.bind(this);
    this.isActive = this.isActive.bind(this);
  }

  componentDidMount() {
    this.setIsLive();
  }

  setIsLive() {
    this.setState({
      isLive: this.isLive(),
    });
  }

  handleChange() {
    if (this.state.isLive) {
      moveSegmentToTest(this.props.segment.id, response => {
        filterActions.updateFilters();
        if (response.status === 'OK') {
          this.setState({
            isLive: false,
          });
        }
      });
    } else {
      moveSegmentToLive(this.props.segment.id, response => {
        filterActions.updateFilters();
        if (response.status === 'OK') {
          this.setState({
            isLive: true,
          });
        }
      });
    }
  }

  isActive() {
    return (
      this.props.segment &&
      (this.props.segment.status === 'ACTIVE' ||
        this.props.segment.status === 'DRAFT')
    );
  }

  isLive() {
    return this.props.segment && this.props.segment.status === 'ACTIVE';
  }

  render() {
    return (
      <div className='widget-options'>
        {this.isActive() ? (
          <div>
            <label className='item is-toggle'>
              <input
                type='checkbox'
                checked={this.state.isLive}
                onChange={() => {
                  confirm({ onConfirm: this.handleChange });
                }}
              />
              <span className='item-label' />
            </label>
            <span className='item-help'>
              {' '}
              {t('draft')} / <span className='live'>{t('live')}</span>
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default SegmentWidgetOptions;
