import React from 'react';
import { uiActions } from '../../../actions';

const RemoveListItemModal = ({ deleteFn, content, title, confirmBtn }) => {
  const { confirmationDialog } = uiActions;
  if (confirmBtn) {
    confirmationDialog({
      buttonConfirm: {
        className: 'dialog-confirm-button',
        text: 'Confirm',
      },
      title: <span>{title}</span>,
      content: <span>{content}</span>,
      onConfirm: () => {
        deleteFn();
      },
    });
  } else {
    confirmationDialog({
      buttonConfirm: {
        className: 'dialog-delete-button',
        text: 'Delete',
      },
      title: <span>{title}</span>,
      content: <span>{content}</span>,
      onConfirm: () => {
        deleteFn();
      },
    });
  }
};

export default RemoveListItemModal;
