import { toLocaleStringDigits, formatMoneyValue } from '../../../system/string';

export const calculateDiffMetric = (segmentValue, otherValue) => {
  let result = '';
  if (segmentValue === otherValue) {
    result = 'equal';
  } else if (segmentValue > otherValue && otherValue !== 0) {
    result = `${toLocaleStringDigits(segmentValue / otherValue)}x more`;
  } else if (otherValue > segmentValue && segmentValue !== 0) {
    result = `${toLocaleStringDigits(otherValue / segmentValue)}x less`;
  }
  return result;
};

export const getInterestedTitle = segmentName => {
  let title = '';
  switch (segmentName) {
    case 'Active Loyals':
      title = 'Lifetime Value';
      break;
    case 'Big Spenders':
    case 'One Time Shoppers':
      title = 'Average Order Value';
      break;
    case 'Loyals at Risk':
    case 'Churn Candidates':
    case 'Lost Customers':
      title = 'Days Since Last Order';
      break;
    case 'Richie Riches':
      title = 'Average Product Price';
      break;
    case 'Bulk Shoppers':
      title = 'Items Per Order';
      break;
    case 'Sleeping Opportunists':
      title = 'Discount Usage';
      break;
    case 'Waiting First Order':
      title = 'Waiting Days';
      break;
    default:
      title = 'NOT FOUND';
  }
  return title;
};

export const getInterestedValue = (segmentName, stat) => {
  let value = 0;
  switch (segmentName) {
    case 'Active Loyals':
      value = formatMoneyValue(stat ? stat.averageCustomerLifetimeValue : 0);
      break;
    case 'Big Spenders':
    case 'One Time Shoppers':
      value = formatMoneyValue(stat ? stat.averageOrderValue : 0);
      break;
    case 'Loyals at Risk':
    case 'Churn Candidates':
    case 'Lost Customers':
      value =
        stat && stat.averageDaysSinceLastOrder
          ? Math.round(stat.averageDaysSinceLastOrder)
          : 0;
      break;
    case 'Richie Riches':
      value = formatMoneyValue(stat ? stat.averageProductPrice : 0);
      break;
    case 'Bulk Shoppers':
      value = stat ? stat.itemsPerOrder : 0;
      break;
    case 'Sleeping Opportunists':
      value = `${stat ? stat.averageDiscountUsage : 0}%`;
      break;
    case 'Waiting First Order':
      if (stat) {
        const isNumeric = parseInt(stat.averageWaitingDays);
        value = Number.isNaN(isNumeric)
          ? 'No Data'
          : Math.round(stat.averageWaitingDays);
      }
      break;
    default:
      break;
  }
  return value;
};
