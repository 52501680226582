/**
 * @fileOverview EmailCampaignPages.jsx - Email Campaign Pages Component
 */

import React, { useState } from 'react';
import { EmailCampaignCard } from './components/EmailCampaignCard';
import { useEmailCampaigns } from './utils/useEmailCampaigns';
import EmailCampaignList from '../components/email-homepage-list/EmailCampaignList';
import { t } from '../../../system/ui';
import './EmailCampaignPages.scss';

const EmailCampaignPages = props => {
  const [show, setShow] = useState({
    live: true,
    draft: false,
    sent: false,
  });
  const {
    renderRaw,
    activeCampaign,
    draftCampaign,
    sentCampaign,
  } = useEmailCampaigns(props);

  const handleDropDownByStatus = type => {
    setShow(prev => {
      return {
        ...prev,
        [type]: !prev[type],
      };
    });
  };

  return (
    <div>
      <EmailCampaignCard campaign={renderRaw} {...props} />
      <div className='EmailCardWrapper'>
        <>
          {activeCampaign?.length > 0 ? (
            <div
              className='EmailCardWrapper__title--live'
              onClick={() => handleDropDownByStatus('live')}
            >
              {t('Live Campaigns')} ({activeCampaign?.length})
            </div>
          ) : null}
          {activeCampaign?.map(item => (
            <div className={show.live ? 'block' : 'hidden'}>
              <EmailCampaignList item={item} statusType='live' />
            </div>
          ))}
        </>
        <>
          {draftCampaign.length > 0 ? (
            <div
              className='EmailCardWrapper__title'
              onClick={() => handleDropDownByStatus('draft')}
            >
              {t('Draft Campaigns')} ({draftCampaign.length})
            </div>
          ) : null}
          {draftCampaign?.map(item => (
            <div className={show.draft ? 'block' : 'hidden'}>
              <EmailCampaignList item={item} statusType='draft' />
            </div>
          ))}
        </>
        <>
          {sentCampaign.length > 0 ? (
            <div
              className='EmailCardWrapper__title'
              onClick={() => handleDropDownByStatus('sent')}
            >
              {t('Sent Campaigns')} ({sentCampaign.length})
            </div>
          ) : null}
          {sentCampaign?.map(item => (
            <div className={show.sent ? 'block' : 'hidden'}>
              <EmailCampaignList item={item} statusType='sent' />
            </div>
          ))}
        </>
      </div>
    </div>
  );
};

export default EmailCampaignPages;
