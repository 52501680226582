"use strict";

import { createAction } from "../system/store";


export const wizardReset = () => {
    createAction("RESET_JOURNEY_BUILDER_CRITERIA");
};

export const addCriteria = criteria => {
    createAction("ADD_JOURNEY_BUILDER_CRITERIA", { criteria });
};

export const wizardDeliveryReset = () => {
    createAction("RESET_JOURNEY_BUILDER_DELIVERY_CRITERIA");
};

export const addDeliveryCriteria = criteria => {
    createAction("ADD_JOURNEY_BUILDER_DELIVERY_CRITERIA", { criteria });
};

export const updateCategoryFlag = flag => {
    createAction("UPDATE_CATEGORY_TREE_FLAG", { flag });
};

export default{
  wizardReset,
  addCriteria,
  wizardDeliveryReset,
  addDeliveryCriteria,
  updateCategoryFlag
};