import React from 'react';
import BaseSelect, { Creatable } from 'react-select';
import classNames from 'classnames';

import * as classes from '../../constants/ui.classes';
import * as validations from '../../system/validations';
import { t } from '../../system/ui';
import Tooltip from '../tooltip';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      validate: false,
      required: false,
    };
    this.setValue = this.setValue.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setValue();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.value !== newProps.value) {
      this.setState({
        value: newProps.value,
      });
    }
  }

  onChange(selectedOption) {
    // if (!selectedOption.isProcessCompleted) return;
    if ('isProcessCompleted' in selectedOption) {
      if (!selectedOption.isProcessCompleted) return;
    }
    const so = selectedOption;
    const input = this.refs.select;

    if (this.props.required) {
      if (validations.validateRequired(input, this)) {
        this.setState({
          required: true,
        });
      } else {
        this.setState({
          required: false,
        });
      }
    }

    if (this.props.validate) {
      if (!validations.validateURL(input, this)) {
        this.setState({
          validate: false,
        });
        if (this.props.multi) {
          this.setState({
            value: selectedOption || '',
          });
        } else {
          if (so) {
            so.name = this.props.name;
          }

          this.setState({
            value: selectedOption ? selectedOption.value : '',
          });
        }

        if (this.props.onChange) {
          this.props.onChange(so);
        }
      } else {
        this.setState({
          validate: true,
        });
      }
    } else {
      this.setState({
        validate: false,
      });
      if (this.props.multi) {
        this.setState({
          value: selectedOption || '',
        });
      } else {
        if (so) {
          so.name = this.props.name;
        }

        this.setState({
          value: selectedOption ? selectedOption.value : '',
        });
      }

      if (this.props.onChange) {
        this.props.onChange(so);
      }
    }
  }

  setValue() {
    this.setState({
      value: this.props.value,
    });

    if (this.props.required && !this.props.value) {
      this.setState({
        required: true,
      });
    }
  }

  render() {
    const searchable = this.props.searchable || false;
    const clearable = this.props.clearable || false;
    const multi = this.props.multi || false;
    const creatable = this.props.creatable || false;

    return (
      <label
        className={classNames('item field-select', this.props.className, {
          'has-error': this.state.required,
        })}
      >
        <div>
          {this.props.label && (
            <span
              className={classes.fieldLabel}
              style={{ marginBottom: '1em' }}
            >
              {t(this.props.label)}
            </span>
          )}
          {this.props.tooltipContent && (
            <span className='field-tooltip-icon'>
              <Tooltip content={this.props.tooltipContent} alignment='left'>
                <i className='icon-info' role='presentation'>
                  <span className='for-screenreader-only' />
                </i>
              </Tooltip>
            </span>
          )}
        </div>
        <div>
          {this.props.specialLabel && (
            <span className={classes.fieldLabel}>
              {t(this.props.specialLabel)}
            </span>
          )}
          {creatable ? (
            <div className='is-select'>
              <Creatable
                value={this.state.value}
                options={this.props.options}
                name={this.props.name}
                clearable={clearable}
                onChange={this.onChange}
                searchable={searchable}
                multi={multi}
                disabled={this.props.disabled}
                shouldKeyDownEventCreateNewOption={e => {
                  switch (e.keyCode) {
                    case 9:
                    case 13:
                      return !0;
                    default:
                      return !1;
                  }
                }}
                ref='select'
              />
              {this.state.validate && (
                <span className='item-error'>
                  <span className='item-error'>
                    {t('This field must be valid URL.')}
                  </span>
                </span>
              )}
            </div>
          ) : (
            <div className='is-select'>
              <BaseSelect
                searchable={searchable}
                clearable={clearable}
                name={this.props.name}
                options={this.props.options}
                value={this.state.value}
                onChange={this.onChange}
                multi={multi}
                disabled={this.props.disabled}
                ref='select'
                placeholder={this.props.placeholder || 'Select...'}
              />
              {this.state.validate && (
                <span className='item-error'>
                  <span className='item-error'>
                    {t('Image domains must be valid URLs.')}
                  </span>
                </span>
              )}
            </div>
          )}
        </div>
      </label>
    );
  }
}

export default Select;
