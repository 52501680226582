/**
 * @author Bilal Cinarli
 */

import { $http } from '../system/ajax';
import { getStartEndDate } from '../system/date';
import api from '../system/api';
import { getApiKey } from './auth/user';

const getStats = (url, options, callback) => {
  const opts = options;

  opts.limit = 2000;

  $http(url)
    .get(opts)
    .then(function(response) {
      callback(response);
    });
};

const getRangedStats = (boundaries, callback, interval, module) => {
  if (module === 'E-Mail') {
    getRangedMailTrends(boundaries, callback, interval);
  } else if (module === 'Push') {
    getRangedPushTrends(boundaries, callback, interval);
  } else if (module === 'Search') {
    getRangedSearchTrends(boundaries, callback, interval);
  } else {
    const opts = boundaries;

    opts.interval = interval || 'total';
    const globalApiKey =
      getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
    return getStats(
      api.getEndPoint('report/stats_trend') + globalApiKey,
      opts,
      callback,
    );
  }
};

const getTotalStats = (interval, callback) => {
  const opts = getStartEndDate(interval);
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  opts.interval = 'total';

  return getStats(
    api.getEndPoint('report/stats_trend') + globalApiKey,
    opts,
    callback,
  );
};

const getEmailAnalytics = callback => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  const opts = {};

  return getStats(
    api.getRestEndPoint('emails/analytics') + globalApiKey,
    opts,
    callback,
  );
};

const getRangedTrends = (boundaries, callback, interval, module) => {
  if (module === 'E-Mail') {
    getRangedMailTrends(boundaries, callback, interval);
  } else if (module === 'Push') {
    getRangedPushTrends(boundaries, callback, interval);
  } else if (module === 'Search') {
    getRangedSearchTrends(boundaries, callback, interval);
  } else {
    const globalApiKey =
      getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
    const opts = boundaries;
    opts.interval = interval || 'total';
    return getStats(
      api.getEndPoint('report/account_trend') + globalApiKey,
      opts,
      callback,
    );
  }
};

const getTotalTrends = (interval, callback) => {
  const opts = getStartEndDate(interval);

  opts.interval = 'total';
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return getStats(
    api.getEndPoint('report/account_trend') + globalApiKey,
    opts,
    callback,
  );
};

const getAnalyticsTrends = (boundaries, callback, interval) => {
  const opts = boundaries;

  opts.interval = interval || 'total';
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return getStats(
    api.getEndPoint('report/google_analytics_trend') + globalApiKey,
    opts,
    callback,
  );
};

const getGAnalyticsDashboardTrends = (boundaries, callback, interval) => {
  const opts = boundaries;

  opts.interval = interval || 'total';
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return getStats(
    api.getEndPoint('report/google_analytics_dashboard_trend') + globalApiKey,
    opts,
    callback,
  );
};

const getRangedMailTrends = (boundaries, callback, interval) => {
  const opts = boundaries;
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  opts.interval = interval || 'total';
  return getStats(
    api.getEndPoint('emails/account_trend') + globalApiKey,
    opts,
    callback,
  );
};

const getRangedPushTrends = (boundaries, callback, interval) => {
  const opts = boundaries;
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  opts.interval = interval || 'total';
  return getStats(
    api.getEndPoint('push/account_trend') + globalApiKey,
    opts,
    callback,
  );
};

const getRangedSearchTrends = (boundaries, callback, interval) => {
  const opts = boundaries;
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  opts.interval = interval || 'total';
  return getStats(
    api.getEndPoint('report/search') +
      globalApiKey +
      (boundaries.instanceid ? `&instanceid=${boundaries.instanceid}` : ''),
    opts,
    callback,
  );
};

const getSearchDetail = (boundaries, callback, interval, instanceId) => {
  const opts = boundaries;
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  opts.interval = interval || 'total';
  return getStats(
    api.getEndPoint('report/searchDetailReport') +
      globalApiKey +
      (instanceId ? `&instanceid=${instanceId}` : ''),
    opts,
    callback,
  );
};

const getRangedSearchReports = (
  boundaries,
  callback,
  options,
  predefinedApiKey,
) => {
  const apiKey = predefinedApiKey || getApiKey();
  let opts;
  if (boundaries === 'weekly') {
    opts = getStartEndDate('weekly');
  } else {
    opts = boundaries;
  }
  opts.interval = 'total';

  opts = { ...opts, ...options };

  let uri = '?';
  let argcount = 0;

  for (const key in opts) {
    if (opts.hasOwnProperty(key)) {
      if (argcount++) {
        uri += '&';
      }
      uri += `${encodeURIComponent(key)}=${encodeURIComponent(opts[key])}`;
    }
  }
  return getStats(
    `${api.getEndPoint('report/searchDetailReport')}?apiKey=${apiKey}`,
    opts,
    callback,
  );
};

const getTotalKeyMetrics = (boundaries, callback, interval) => {
  const opts = boundaries;
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  opts.interval = interval || 'total';
  return getStats(
    api.getEndPoint('report/key_metrics') + globalApiKey,
    opts,
    callback,
  );
};

export default {
  getTotalStats,
  getTotalTrends,
  getRangedStats,
  getRangedTrends,
  getAnalyticsTrends,
  getGAnalyticsDashboardTrends,
  getRangedMailTrends,
  getRangedPushTrends,
  getTotalKeyMetrics,
  getRangedSearchTrends,
  getRangedSearchReports,
  getSearchDetail,
  getEmailAnalytics,
};
