import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppContainer } from 'react-hot-loader';
import Redbox from 'redbox-react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SegmentifyQueryProvider } from './hooks/segmentify-query/SegmentifyQuery';
import { store } from './store';
import RootContainer from './containers/root';
import { userGuidanceScripts } from './user-guidance/scripts';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// EXTERNAL PARAMETERS
/* eslint-disable no-var */
var providerApp = null;
var sourceApp = null;
var externalAccount = null;
var externalCallback = null;
var externalErrorCallback = null;
var subPath = null;

/* eslint-disable no-var */

export function setSource(source) {
  sourceApp = source;
}

export function getSource() {
  return sourceApp;
}

export function setExternalAccount(account) {
  externalAccount = account;
}

export function getExternalAccount() {
  return externalAccount;
}

export function setExternalCallback(callback) {
  externalCallback = callback;
}

export function getExternalCallback() {
  return externalCallback;
}

export function setExternalErrorCallback(callback) {
  externalErrorCallback = callback;
}

export function getExternalErrorCallback() {
  return externalErrorCallback;
}

export function setProvider(provider) {
  providerApp = provider;
}

export function getProvider() {
  return providerApp;
}

export function setSubPath(_subPath) {
  subPath = _subPath;
}

export function getSubPath() {
  return subPath;
}

// EXTERNAL PARAMETERS

// OTHER PARAMETERS
let extToken = null;
let extAppType = null;
let extAppTypeEvent = null;

export function setExtToken(token) {
  extToken = token;
}

export function getExtToken() {
  return extToken;
}

export function setExtAppType(appType) {
  extAppType = appType;
  if (extAppTypeEvent) {
    extAppTypeEvent();
  }
}

export function getExtAppType() {
  return extAppType;
}

export function setExtAppTypeEvent(appTypeEvent) {
  extAppTypeEvent = appTypeEvent;
}

// OTHER PARAMETERS

const userGuidanceScript = document.getElementById('user-guiding');
const currentEnvironment = process.env.NODE_ENV;

if (userGuidanceScript) {
  const userGuidanceScriptFunction = userGuidanceScripts[currentEnvironment];

  if (userGuidanceScriptFunction) {
    userGuidanceScript.innerHTML = userGuidanceScriptFunction;
  }
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <SegmentifyQueryProvider>
    <DndProvider backend={HTML5Backend}>
      <AppContainer errorReporter={Redbox}>
        <RootContainer store={store} />
      </AppContainer>
    </DndProvider>
  </SegmentifyQueryProvider>,
);
