import React from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { isSuperUser } from '../../modules/auth/user';
import { devices, mediums } from '../../constants/datamaps/campaigns';

import { t } from '../../system/ui';
import { getFormattedDate } from '../../system/date';

import { getCampaignPage } from '../../modules/campaigns/data';

import { campaignActions, dialogActions } from '../../actions';

import { activateCampaign } from '../../modules/campaigns/ajax';
import Tooltip from '../tooltip';

import Icon from '../icon';
import WidgetStats from './stats';
import WidgetActions from './actions';
import WidgetOptions from './options';
import Icons from '../icons';

class WidgetCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDevice: 'ALL',
    };
    this.goTestFromArchive = this.goTestFromArchive.bind(this);
    this.goTestConfirmed = this.goTestConfirmed.bind(this);
  }

  goTestFromArchive() {
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('Are you sure to switch campaign to test mode?'),
      onConfirm: () => {
        this.goTestConfirmed();

        dialogActions.closeDialog();
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  selectDevice(selectedDevice) {
    this.setState({
      selectedDevice,
    });
  }

  goTestConfirmed() {
    activateCampaign(this.props.instanceId, response => {
      if (response.status === 'OK') {
        // update action
        this.props.getCampaigns('Test');
        campaignActions.filterCampaigns('TEST');
      }
    });
  }

  render() {
    const isPromotion =
      this.props.actions.length > 0 &&
      this.props.actions[0].type === 'recommendPromotion';
    const isKeyword =
      this.props.actions.length > 0 &&
      this.props.actions[0].type === 'recommendKeyword';
    const isSegmentCampaign =
      this.props.actions.length > 0 &&
      this.props.actions[0].type === 'segmentVisitor';
    const isInAB = this.props.idsInAB.find(
      id => this.props.campaign.instanceId === id,
    );
    return (
      <div className='widget page-content-block' ref='widget'>
        <WidgetOptions
          instance={this.props.instanceId}
          testMode={this.props.testMode}
          limitations={this.props.limitations}
        />

        {isSuperUser() ? (
          <h3 className='widget-page'>
            {t(getCampaignPage(this.props))} -{' '}
            <span style={{ color: '##c0bebe', fontWeight: 'normal' }}>
              {this.props.instanceId}
            </span>
          </h3>
        ) : (
          <h3 className='widget-page'>{t(getCampaignPage(this.props))}</h3>
        )}
        <h2 className='widget-title'>{this.props.scenarioName}</h2>

        <div className='widget-meta meta-flex'>
          {isPromotion && (
            <span className='promotion-label'>{t('Promotion')}</span>
          )}
          {isKeyword && <span className='promotion-label'>{t('Keyword')}</span>}
          <div className='meta-flex'>
            <span className='meta-flex-border'>
              <Icon name='calendar' />
              {getFormattedDate(this.props.limitations.activeDates.startDate)}
              {this.props.limitations.activeDates.endDate
                ? ` - ${getFormattedDate(
                    this.props.limitations.activeDates.endDate,
                  )}`
                : ''}
            </span>

            {this.props.campaignsInited && (
              <span
                className='recommendation-device-selection'
                style={{ marginRight: '10px' }}
              >
                <Tooltip
                  content='All Devices'
                  alignment='top'
                  newTooltip
                  isInline
                >
                  <span
                    key='all-devices'
                    onClick={this.selectDevice.bind(this, 'ALL')}
                    className={classNames(
                      'widget-meta-mobile all-device-report',
                      {
                        'is-selected': this.state.selectedDevice === 'ALL',
                      },
                    )}
                  >
                    <Icons
                      name='allDevices'
                      width='16'
                      height='16'
                      color='#bababa'
                    />
                    {t('ALL')}
                  </span>
                </Tooltip>

                {mediums[this.props.device].map(device => {
                  if (
                    device.toLowerCase() === 'pctablet' ||
                    device.toLowerCase() === 'tablet'
                  ) {
                    return null;
                  }

                  return (
                    <Tooltip
                      key={t(devices[device])}
                      content={t(devices[device])}
                      alignment='top'
                      newTooltip
                      isInline
                    >
                      <span
                        key={device}
                        onClick={this.selectDevice.bind(this, device)}
                        className={classNames('widget-meta-mobile', {
                          'is-selected': this.state.selectedDevice === device,
                        })}
                      >
                        <Icon name={device.toLowerCase()} />
                      </span>
                    </Tooltip>
                  );
                })}
              </span>
            )}
          </div>

          {isInAB && (
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              <Icons name='experimentation' />
              <span style={{ marginLeft: '5px' }}>in an A/B test</span>
            </span>
          )}
        </div>

        <hr className='widget-separator' />

        <WidgetStats
          ref='widgetStats'
          isSegmentCampaign={isSegmentCampaign}
          isKeywordCampaign={isKeyword}
          report={this.props.report}
          campaignType={this.props.campaignType}
          selectedDevice={
            this.state.selectedDevice === 'PC'
              ? 'PC'
              : this.state.selectedDevice.toLowerCase()
          }
          withReport={this.props.withReport}
          {...this.props}
        />

        <hr className='widget-separator' />

        <div className='widget-buttons group'>
          {this.props.status !== 'PASSIVE' ? (
            <WidgetActions campaign={this.props.campaign} {...this.props} />
          ) : (
            <WidgetActions
              isArchive
              campaign={this.props.campaign}
              {...this.props}
              goTestFromArchive={this.goTestFromArchive}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
});

export default connect(mapStatesToProps)(WidgetCard);
