/**
 * @fileOverview This file open the modal of the edit screen of admin monitor target.
 * @requires react
 * @requires classNames
 */
import React, { createRef, useEffect, useReducer } from 'react';
import classNames from 'classnames';

/**
 * Module dependencies
 */
import { t } from '../../system/ui';
import { clone } from '../../system/object';
import { featureModules } from '../../constants/datamaps/account';
import {
  monitorRecoTargetReasons,
  monitorModuleList,
  monitorDefaultTarget,
} from './constants';
import { uiActions } from '../../actions';
import { updateAccount } from '../../modules/account/ajax';
import { TextField, Select } from '../fields';
import { isSuperiestUser } from '../../modules/auth/user';
import { getDefaultTargetValue } from '../monitor/utils/functions';

const TargetReducer = (state, action) => {
  const { type, payload } = action;
  const newTargetFlag = Object.keys(state.newTargets).length === 0;
  const relatedReasonFlag =
    state.newTargetFlag ||
    Object.keys(state.newTargets).some(
      key => state.newTargets[key] === '' || state.targets[key].reason === '',
    );
  switch (type) {
    case 'SET_TARGETS':
      return {
        ...state,
        newTargetFlag,
        relatedReasonFlag,
        targets: payload,
      };
    case 'SET_NEW_TARGETS':
      return {
        ...state,
        newTargetFlag,
        relatedReasonFlag,
        newTargets: payload,
      };
    case 'SET_NEW_TARGET_FLAG':
      return {
        ...state,
        newTargetFlag: payload,
      };
    case 'SET_RELATED_REASON_FLAG':
      return {
        ...state,
        relatedReasonFlag: payload,
      };
    default:
      return state;
  }
};

/**
 * This component edits admin monitor targets.
 * @param {Object} account Details of selected account.
 * @param {Function} onUpdate Updates user account targets.
 * @returns {JSX.Element}
 */
const EditTarget = ({ account, onUpdate }) => {
  const initialState = {
    targets: account.targets ? account.targets : {},
    newTargets: {},
    newTargetFlag: true,
    relatedReasonFlag: true,
    formRef: createRef(),
  };

  const [state, targetDispatcher] = useReducer(TargetReducer, initialState);
  const { showNotification, isLoaded, isLoading } = uiActions;

  useEffect(() => {
    let { targets } = state;

    if (Object.keys(targets).length === 0) {
      featureModules.forEach(item => {
        if (
          monitorModuleList.includes(item.key) &&
          account.features[item.key].status === 'ACTIVE'
        ) {
          targets[item.key] = clone(monitorDefaultTarget[item.key]);
        }
      });
      targetDispatcher({
        type: 'SET_TARGETS',
        payload: targets,
      });
    }
  });

  const handleSubmit = e => {
    const eInner = { ...e };
    e.preventDefault();
    if (eInner.target.innerHTML.indexOf('has-error') < 0) {
      isLoading();
      const { targets, newTargets } = state;
      Object.keys(newTargets).forEach(item => {
        targets[item].value = newTargets[item];
      });

      account.targets = targets;

      updateAccount(account, response => {
        const notificationContent = () => <div>{response.message}</div>;
        let notificationClassName;

        if (response.status !== 'OK') {
          notificationClassName = 'notification-fail';
        }

        showNotification({
          content: notificationContent,
          className: notificationClassName,
        });
        isLoaded();

        if (response.status === 'OK') {
          onUpdate(account);
        }
      });
    }
  };

  const handleRecoReasonUpdate = selectedOption => {
    const { targets } = state;
    targets.recommendation.reason = selectedOption.value;

    targetDispatcher({
      type: 'SET_TARGETS',
      payload: targets,
    });
  };

  const onChange = e => {
    let { value, name } = e.target;

    if (name.includes('new')) {
      name = name.split(' ')[1];
    }
    const { newTargets } = state;

    const onlyDigitAndDot = /[^0-9.]/g;
    const findStartsWithDot = /(\..*?)\..*/g;
    const findStartWithNumberDotAndTwoFraction = /^(\d+.?\d{0,2})\d*$/g;
    // allow only numeric value with two fraction
    value = value
      .replace(onlyDigitAndDot, '')
      .replace(findStartsWithDot, '$1')
      .replace(findStartWithNumberDotAndTwoFraction, '$1');
    value = value > 100 ? 100 : value;
    newTargets[name] = value;

    targetDispatcher({
      type: 'SET_NEW_TARGETS',
      payload: newTargets,
    });
  };

  const onReasonChange = e => {
    const { targets } = state;
    const { value, name } = e.target;

    targets[name.split(' ')[1]].reason = value;
    targetDispatcher({
      type: 'SET_TARGETS',
      payload: targets,
    });
  };

  return (
    <form
      className='edit-user'
      style={{ width: '640px' }}
      onSubmit={handleSubmit}
      ref={state.formRef}
      id={account.accountId}
    >
      <ol className='form-elements'>
        <li className='row monitor-account-edit' style={{ zIndex: 2 }}>
          <div className='row'>
            <div className='widget-stats'>
              <div className='widget-stat-segmentation  stat-segmentation'>
                <p
                  className='widget-stat-conversion-modules'
                  style={{ textAlign: 'center', paddingRight: '50px' }}
                >
                  <b>{t('Current Target')}</b>
                </p>
              </div>

              <div className='widget-stat-segmentation  stat-segmentation'>
                <p
                  className='widget-stat-conversion-account'
                  style={{ paddingLeft: '7px' }}
                >
                  <b>{t('New Target')}</b>
                </p>
              </div>

              <div className='widget-stat-segmentation  stat-segmentation'>
                <p
                  className='widget-stat-conversion-modules'
                  style={{ paddingLeft: '25px' }}
                >
                  <b>{t('Reason to Change')}</b>
                </p>
              </div>
            </div>
            {featureModules
              .filter(
                item =>
                  monitorModuleList.includes(item.key) &&
                  account.features[item.key].status === 'ACTIVE' &&
                  account.stats[item.key],
              )
              .map(item => {
                const reasonFlag = state.newTargets[item.key]
                  ? state.newTargets[item.key].length === 0
                  : true;
                return (
                  <li
                    key={item.key}
                    className='row pull-push'
                    style={{ marginBottom: '-10px' }}
                  >
                    <hr
                      className='widget-separator'
                      style={{ margin: '20px' }}
                    />
                    <TextField
                      name={item.key}
                      defaultValue={
                        state.targets[item.key]
                          ? state.targets[item.key].value
                          : getDefaultTargetValue(item.key)
                      }
                      className='item-stacked module-account-label four-columns'
                      label={item.key === 'recommendation' ? 'Reco' : item.name}
                      onChange={onChange}
                      disabled
                      renderProp={() => <span className='item-label'>%</span>}
                    />

                    <TextField
                      name={`new ${item.key}`}
                      defaultValue=''
                      value={state.newTargets[item.key]}
                      className='item-stacked module-account-label three-columns'
                      onChange={e => onChange(e)}
                      disabled={!isSuperiestUser()}
                      autocomplete='off'
                      renderProp={() => <span className='item-label'>%</span>}
                    />
                    {item.key === 'recommendation' ? (
                      <Select
                        name='dropdownReason'
                        className='item-stacked five-columns'
                        value={
                          state.targets[item.key]
                            ? state.targets[item.key].reason
                            : ''
                        }
                        options={monitorRecoTargetReasons}
                        disabled={
                          !isSuperiestUser() ||
                          state.newTargetFlag ||
                          reasonFlag
                        }
                        onChange={handleRecoReasonUpdate}
                      />
                    ) : (
                      <TextField
                        name={`reason ${item.key}`}
                        defaultValue={
                          state.targets[item.key]
                            ? state.targets[item.key].reason
                            : ''
                        }
                        className='item-stacked module-account-label five-columns'
                        disabled={
                          !isSuperiestUser() ||
                          state.newTargetFlag ||
                          reasonFlag
                        }
                        onChange={onReasonChange}
                        autocomplete='off'
                      />
                    )}
                  </li>
                );
              })}
          </div>
        </li>
        <li className='buttons'>
          <button
            className={classNames('tertiary-action', {
              disabled: !isSuperiestUser() || state.relatedReasonFlag,
            })}
            disabled={!isSuperiestUser() || state.relatedReasonFlag}
          >
            {t('Update')}
          </button>
        </li>
      </ol>
    </form>
  );
};

export default EditTarget;
