import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Form from '../controlled-form';
import Icons from '../icons';
import { setTitle } from '../../system/document';
import { t } from '../../system/ui';
import { dialogActions, uiActions } from '../../actions';
import {
  getUser,
  isSwitchAccount,
  disableEmailConfig,
} from '../../modules/auth/user';
import Tooltip from '../tooltip';
import { clone } from '../../system/object';
import { resetMailConf } from '../../actions/user';
import { resetMailConfInSwitched } from '../../actions/switchUser';

class Settings_Instructions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
      selectedESP: 'MAILCHIMP',
      capping: {
        capCount: '',
        frequency: '',
        types: [],
      },
      error: null,
      ui: 'isLoaded',
      hasCappingError: false,
      button: true,
      account: clone(isSwitchAccount()),
    };

    this.onSelectESP = this.onSelectESP.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
    this.checkIntegration = this.checkIntegration.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  componentDidMount() {
    setTitle(t('Email'));
    uiActions.isLoading();
    this.updateForm(isSwitchAccount());
    uiActions.isValidating();
    getUser(response => {
      this.setState(
        {
          selectedESP: response.account.mailConfiguration.provider,
        },
        () => {
          this.updateForm(response.account);
        },
      );
      this.checkIntegration(response.account);
      this.props.updateData(response.account.mailConfiguration.params);
      this.props.updateData({
        endPoint: response.account.mailConfiguration.endPoint,
      });
      this.props.updateProvider(response.account.mailConfiguration.provider);
      uiActions.isLoaded();
      window.stateLog = this.state;
      window.propsLog = this.props;
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.congrats === true) {
      const newAccount = {
        ...newProps.account,
        mailConfiguration: newProps.mailConf,
      };

      getUser(response => {
        this.setState({
          account: response.account,
        });

        this.updateForm(response.account);
      });
    }
  }

  checkIntegration(accountR) {
    const account = accountR;
    this.setState(
      {
        button: !!(
          account.mailConfiguration !== undefined &&
          account.mailConfiguration.params !== undefined &&
          (account.mailConfiguration.params.apiKey !== undefined ||
            account.mailConfiguration.params.username !== undefined ||
            account.mailConfiguration.params.privateKey !== undefined)
        ),
      },
      () => {
        getUser(response => {
          this.updateForm(response.account);
          uiActions.isLoaded();
        });
      },
    );
  }

  clickHandler() {
    if (!this.props.isSuperUser) return;

    if (this.state.button === true) {
      dialogActions.openDialog({
        title: t('Attention!'),
        content: t('This action will disable your email configurations.'),
        onConfirm: () => {
          if (isSwitchAccount()) {
            resetMailConfInSwitched();
          } else {
            resetMailConf();
          }

          disableEmailConfig();
          dialogActions.closeDialog();
          this.setState(
            {
              button: !this.state.button,
            },
            () => {
              this.props.updateData('disable');
              this.updateForm(this.state.account);
              uiActions.isLoaded();
            },
          );
        },
        onCancel: () => {
          dialogActions.closeDialog();
        },
      });
    } else {
      this.setState(
        {
          button: !this.state.button,
        },
        () => {
          getUser(response => {
            this.updateForm(response.account);
            uiActions.isLoaded();
          });
        },
      );
    }
  }

  updateForm(account) {
    let fieldsMandrill = [];
    let fieldsDotDigital = [];
    let fieldsKlaviyo = [];
    let fieldsSegmentifyV2 = [];
    fieldsSegmentifyV2 = [
      {
        label: 'API Key',
        name: 'apiKey',
        className: 'item-stacked one-whole',
        placeholder: 'SEGMENTIFY API Key',
        required: true,
        value:
          account.mailConfiguration &&
          account.mailConfiguration.provider === 'SEGMENTIFY_V2' &&
          account.mailConfiguration.params.apiKey
            ? account.mailConfiguration.params.apiKey
            : '',
      },
      {
        label: 'Server EndPoint URL',
        name: 'endPoint',
        className: 'item-stacked one-whole',
        placeholder: 'Server EndPoint URL',
        required: true,
        value:
          account.mailConfiguration &&
          account.mailConfiguration.provider === 'SEGMENTIFY_V2' &&
          account.mailConfiguration.endPoint
            ? account.mailConfiguration.endPoint
            : '',
      },
      {
        label: 'From Name',
        name: 'fromName',
        className: 'item-stacked one-whole',
        placeholder: 'From Name',
        required: true,
        value:
          account.mailConfiguration &&
          account.mailConfiguration.provider === 'SEGMENTIFY_V2' &&
          account.mailConfiguration.params.fromName
            ? account.mailConfiguration.params.fromName
            : '',
      },
      {
        label: 'From Address',
        name: 'fromAddress',
        className: 'item-stacked one-whole',
        placeholder: 'From Address',
        required: true,
        value:
          account.mailConfiguration &&
          account.mailConfiguration.provider === 'SEGMENTIFY_V2' &&
          account.mailConfiguration.params.fromAddress
            ? account.mailConfiguration.params.fromAddress
            : '',
      },
      {
        label: 'Reply Name',
        name: 'replyName',
        className: 'item-stacked one-whole',
        placeholder: 'Reply Name"',
        required: true,
        value:
          account.mailConfiguration &&
          account.mailConfiguration.provider === 'SEGMENTIFY_V2' &&
          account.mailConfiguration.params.replyName
            ? account.mailConfiguration.params.replyName
            : '',
      },
      {
        label: 'Reply Address',
        name: 'replyAddress',
        className: 'item-stacked one-whole',
        placeholder: 'Reply Address',
        required: true,

        value:
          account.mailConfiguration &&
          account.mailConfiguration.provider === 'SEGMENTIFY_V2' &&
          account.mailConfiguration.params.replyAddress
            ? account.mailConfiguration.params.replyAddress
            : '',
      },
    ];
    if (this.state.button) {
      fieldsMandrill = [
        {
          label: 'API Key',
          name: 'apiKey',
          className: 'item-stacked one-whole',
          placeholder: 'Mailchimp API Key',
          required: true,
          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'MAILCHIMP'
              ? account.mailConfiguration.params.apiKey
              : '',
        },
        {
          label: 'From Name',
          name: 'fromName',
          className: 'item-stacked one-whole',
          placeholder: 'From Name',
          required: true,
          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'MAILCHIMP'
              ? account.mailConfiguration.params.fromName
              : '',
        },
        {
          label: 'From Address',
          name: 'fromAddress',
          className: 'item-stacked one-whole',
          placeholder: 'From Address',
          required: true,
          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'MAILCHIMP'
              ? account.mailConfiguration.params.fromAddress
              : '',
        },
        {
          label: 'Reply Name',
          name: 'replyName',
          className: 'item-stacked one-whole',
          placeholder: 'Reply Name"',
          required: true,
          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'MAILCHIMP'
              ? account.mailConfiguration.params.replyName
              : '',
        },
        {
          label: 'Reply Address',
          name: 'replyAddress',
          className: 'item-stacked one-whole',
          placeholder: 'Reply Address',
          required: true,

          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'MAILCHIMP'
              ? account.mailConfiguration.params.replyAddress
              : '',
        },
      ];
      fieldsDotDigital = [
        {
          label: 'Username',
          name: 'username',
          className: 'item-stacked one-whole',
          placeholder: 'Username',
          required: true,
          autocomplete: 'new-password',
          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'DOTDIGITAL'
              ? account.mailConfiguration.params.username
              : '',
        },
        {
          label: 'Password',
          name: 'password',
          className: 'item-stacked one-whole',
          placeholder: 'Password',
          type: 'password',
          role: 'presentation',
          autocomplete: 'new-password',
          required: true,
          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'DOTDIGITAL'
              ? account.mailConfiguration.params.password
              : '',
        },
        {
          label: 'From Name',
          name: 'fromName',
          className: 'item-stacked one-whole',
          placeholder: 'From Name',
          required: true,
          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'DOTDIGITAL'
              ? account.mailConfiguration.params.fromName
              : '',
        },
        {
          label: 'From Address',
          name: 'fromAddress',
          className: 'item-stacked one-whole',
          placeholder: 'From Address',
          required: true,
          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'DOTDIGITAL'
              ? account.mailConfiguration.params.fromAddress
              : '',
        },
        {
          label: 'Reply Name',
          name: 'replyName',
          className: 'item-stacked one-whole',
          placeholder: 'Reply Name"',
          required: true,
          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'DOTDIGITAL'
              ? account.mailConfiguration.params.replyName
              : '',
        },
        {
          label: 'Reply Address',
          name: 'replyAddress',
          className: 'item-stacked one-whole',
          placeholder: 'Reply Address',
          required: true,

          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'DOTDIGITAL'
              ? account.mailConfiguration.params.replyAddress
              : '',
        },
      ];
      fieldsKlaviyo = [
        {
          label: 'Private API Key',
          name: 'privateKey',
          className: 'item-stacked one-whole',
          placeholder: 'Private API Key',
          required: true,
          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'KLAVIYO'
              ? account.mailConfiguration.params.privateKey
              : '',
        },
        {
          label: 'API Key / Site ID',
          name: 'apiKey',
          className: 'item-stacked one-whole',
          placeholder: 'API Key / Site ID',
          required: true,
          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'KLAVIYO'
              ? account.mailConfiguration.params.apiKey
              : '',
        },
      ];
    } else {
      fieldsMandrill = [
        {
          label: 'API Key',
          name: 'apiKey',
          className: 'item-stacked one-whole',
          placeholder: 'Mailchimp API Key',
          value:
            account.mailConfiguration &&
            this.state.button &&
            account.mailConfiguration.provider === 'MAILCHIMP'
              ? account.mailConfiguration.params.apiKey
              : '',
        },
      ];
      fieldsDotDigital = [
        {
          label: 'Username',
          name: 'username',
          className: 'item-stacked one-whole',
          placeholder: 'Username',
          value:
            account.mailConfiguration &&
            this.state.button &&
            account.mailConfiguration.provider === 'DOTDIGITAL'
              ? account.mailConfiguration.params.username
              : '',
        },
      ];
      fieldsKlaviyo = [
        {
          label: 'Private API Key',
          name: 'privateKey',
          className: 'item-stacked one-whole',
          placeholder: 'Private API Key',
          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'KLAVIYO'
              ? account.mailConfiguration.params.privateKey
              : '',
        },
        {
          label: 'API Key / Site ID',
          name: 'apiKey',
          className: 'item-stacked one-whole',
          placeholder: 'API Key / Site ID',
          value:
            account.mailConfiguration &&
            account.mailConfiguration.provider === 'KLAVIYO'
              ? account.mailConfiguration.params.apiKey
              : '',
        },
      ];
    }

    let fields;
    if (this.state.selectedESP === 'SEGMENTIFY_V2') {
      fields = fieldsSegmentifyV2;
    } else if (this.state.selectedESP === 'DOTDIGITAL') {
      fields = fieldsDotDigital;
    } else if (this.state.selectedESP === 'KLAVIYO') {
      fields = fieldsKlaviyo;
    } else {
      fields = fieldsMandrill;
    }

    this.setState(
      {
        fields,
        account,
      },
      () => {
        this.state.fields.map(field => {
          this.refs.forms.querySelector(`input[name='${field.name}']`).focus();
          this.refs.forms.querySelector(`input[name='${field.name}']`).blur();
        });
      },
    );
  }

  redirect() {
    if (!this.props.isSuperUser) return;

    browserHistory.push('settings/integrations');
  }

  onSelectESP(method, e) {
    e.preventDefault();

    if (!this.props.isSuperUser) return;

    if (this.state.button === true) {
      return;
    }

    this.props.updateProvider(method);
    this.setState(
      {
        selectedESP: method,
      },
      () => {
        getUser(response => {
          this.updateForm(response.account, true);
          uiActions.isLoaded();
        });
      },
    );
  }

  render() {
    return (
      <div className='page-content-block one-whole email-settings-form'>
        <h3
          className='modal-content-title'
          style={{ color: '#6968b2', marginBottom: '40px' }}
        >
          {t('Initial Instructions')}
        </h3>
        <div className='page-forms' ref='forms'>
          <form
            id='currency-form'
            style={{
              display: 'grid',
              'grid-template-columns': '100px auto',
              'grid-gap': '10px',
              'grid-template-rows': 'auto auto auto',
            }}
          >
            <span
              className={
                this.props.isIntegrated
                  ? 'grid-box-left green'
                  : 'grid-box-left white'
              }
            >
              {this.props.isIntegrated ? (
                <Icons name='check' width={40} height={40} />
              ) : (
                <Icons name='checkGray' width={40} height={40} />
              )}
            </span>
            <div className='sdk-section grid-box'>
              <h3 className='modal-content-title' style={{ color: '#4a4a4a' }}>
                {t('Segmentify JS SDK')}
                <Tooltip
                  content={t(
                    'Segmentify SDKs able to collecting data from desktop, mobile web, Android and IOS channel.',
                  )}
                  alignment='right'
                  placement='bottom'
                >
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              </h3>
              <p className='widget-description'>
                {t(
                  "Personalized Email Campaign segments need to analyze website's user behaviour data.",
                )}
              </p>
              <button
                className={
                  !this.props.isIntegrated
                    ? 'tertiary-action active-button'
                    : 'tertiary-action passive-button'
                }
                onClick={this.redirect}
              >
                Integrations
              </button>
            </div>
            <span
              className={
                this.state.button
                  ? 'grid-box-left green'
                  : 'grid-box-left white'
              }
            >
              {this.state.button ? (
                <Icons name='check' width={40} height={40} />
              ) : (
                <Icons name='checkGray' width={40} height={40} />
              )}
            </span>

            <div className='email-setting-extra grid-box'>
              <h3 className='modal-content-title' style={{ color: '#4a4a4a' }}>
                {t('ESP Config')}
                <Tooltip
                  content={t(
                    'Integration process needs cred keys on your ESP platform.',
                  )}
                  alignment='right'
                  placement='bottom'
                >
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              </h3>
              <p className='widget-description'>
                {t(
                  'It is super easy to start integration between Segmentify and Mailchimp, Dotdigital or Klaviyo.',
                )}
              </p>
              <div className='email-listing'>
                <ul>
                  <li
                    className='esp'
                    style={{
                      cursor: 'pointer',
                      opacity:
                        this.state.selectedESP === 'SEGMENTIFY_V2'
                          ? '1'
                          : '0.2',
                    }}
                    onClick={this.onSelectESP.bind(this, 'SEGMENTIFY_V2')}
                  >
                    <span className='logo-segmentify'>
                      <Icons
                        name='segmentify'
                        color='#d31116'
                        bgColor='transparent'
                        width='30'
                        height='30'
                      />
                    </span>
                    <span>Segmentify</span>
                    <Icons
                      name={
                        this.state.selectedESP === 'SEGMENTIFY_V2'
                          ? 'checkboxSelected'
                          : 'checkboxEmpty'
                      }
                      width={14}
                      height={14}
                    />
                  </li>
                  <li
                    className='esp'
                    style={{
                      cursor: 'pointer',
                      opacity:
                        this.state.selectedESP === 'MAILCHIMP' ? '1' : '0.2',
                    }}
                    onClick={this.onSelectESP.bind(this, 'MAILCHIMP')}
                  >
                    <div className='mandrill' />
                    <span>Mandrill</span>
                    <Icons
                      name={
                        this.state.selectedESP === 'MAILCHIMP'
                          ? 'checkboxSelected'
                          : 'checkboxEmpty'
                      }
                      width={14}
                      height={14}
                    />
                  </li>
                  <li
                    className='esp'
                    style={{
                      cursor: 'pointer',
                      opacity:
                        this.state.selectedESP === 'DOTDIGITAL' ? '1' : '0.2',
                    }}
                    onClick={this.onSelectESP.bind(this, 'DOTDIGITAL')}
                  >
                    <div className='dotdigital' />
                    <span>Dotdigital</span>
                    <Icons
                      name={
                        this.state.selectedESP === 'DOTDIGITAL'
                          ? 'checkboxSelected'
                          : 'checkboxEmpty'
                      }
                      width={14}
                      height={14}
                    />
                  </li>
                  <li
                    className='esp'
                    style={{
                      cursor: 'pointer',
                      opacity:
                        this.state.selectedESP === 'KLAVIYO' ? '1' : '0.2',
                    }}
                    onClick={this.onSelectESP.bind(this, 'KLAVIYO')}
                  >
                    <div className='klaviyo' />
                    <span>Klaviyo</span>
                    <Icons
                      name={
                        this.state.selectedESP === 'KLAVIYO'
                          ? 'checkboxSelected'
                          : 'checkboxEmpty'
                      }
                      width={14}
                      height={14}
                    />
                  </li>
                  <Form
                    ref='configForms'
                    fields={this.state.fields}
                    ui={this.state.ui}
                    {...this.props}
                    isSpecial
                    activeLabel
                    clickHandler={this.clickHandler}
                    button={this.state.button}
                    onChange={this.props.updateData}
                    isMultipleComps={this.props.isMultipleComps}
                    isKlaviyo={this.state.selectedESP === 'KLAVIYO'}
                    isSegmentify={this.state.selectedESP === 'SEGMENTIFY'}
                    isSegmentifyV2={this.state.selectedESP === 'SEGMENTIFY_V2'}
                    onSave={this.props.updateSettings}
                    readOnly={!this.props.isSuperUser}
                  />
                </ul>
              </div>
            </div>
            <span className='grid-box-left'>
              {this.state.account.mailConfiguration &&
              this.state.account.mailConfiguration.params !== undefined &&
              (this.state.account.mailConfiguration.params.apiKey !==
                undefined ||
                this.state.account.mailConfiguration.params.username !==
                  undefined ||
                this.state.account.mailConfiguration.params.privateKey !==
                  undefined) &&
              (this.state.account.mailConfiguration.params.apiKey !== '' ||
                this.state.account.mailConfiguration.params.username !== '' ||
                this.state.account.mailConfiguration.params.privateKey !==
                  '') &&
              (this.state.button ||
                this.state.selectedESP === 'SEGMENTIFY' ||
                this.state.selectedESP === 'SEGMENTIFY_V2') ? (
                <Icons
                  name='congrats'
                  width={40}
                  height={40}
                  color='#fff'
                  bgColor='#52c866'
                />
              ) : (
                <Icons
                  name='congratsGray'
                  width={40}
                  height={40}
                  color='#d7d7d7'
                  bgColor='#fff'
                />
              )}
            </span>
            <span>
              <h3
                className='modal-content-title'
                style={{
                  color:
                    this.state.account.mailConfiguration &&
                    this.state.account.mailConfiguration.params !== undefined &&
                    (this.state.account.mailConfiguration.params.apiKey !==
                      undefined ||
                      this.state.account.mailConfiguration.params.username !==
                        undefined ||
                      this.state.account.mailConfiguration.params.privateKey !==
                        undefined) &&
                    (this.state.account.mailConfiguration.params.apiKey !==
                      '' ||
                      this.state.account.mailConfiguration.params.username !==
                        '' ||
                      this.state.account.mailConfiguration.params.privateKey !==
                        '') &&
                    (this.state.button ||
                      this.state.selectedESP === 'SEGMENTIFY' ||
                      this.state.selectedESP === 'SEGMENTIFY_V2')
                      ? '#52c866'
                      : '#d7d7d7',
                }}
              >
                {t('Congrats! All set!')}
              </h3>
            </span>
            <div />
          </form>
        </div>
        {(this.state.selectedESP === 'SEGMENTIFY' ||
          this.state.selectedESP === 'SEGMENTIFY_V2') && (
          <li
            className='buttons'
            style={{ listStyle: 'none', background: 'white' }}
          >
            <button
              onClick={this.props.updateSettings}
              className='tertiary-action'
              style={{ position: 'relative', right: '30px' }}
              type='submit'
            >
              {t('Update and Send Test')}
            </button>
          </li>
        )}
      </div>
    );
  }
}
const mapStatesToProps = store => ({
  user: store.user.user,
  account: store.user.user.account,
  mailConf: store.user.user.account.mailConfiguration,
  validate: store.ui.validate,
  language: store.ui.language,
});
export default connect(mapStatesToProps)(Settings_Instructions);
