/**
 * @author Bilal Cinarli
 */

"use strict";

import { createAction } from "../system/store";

export const openDialog = options => {
  createAction("OPEN_DIALOG", options);
};

export const closeDialog = () => {
  createAction("CLOSE_DIALOG");
};

export default {
  openDialog,
  closeDialog
};
