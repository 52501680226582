import { createReducer } from "../system/store";
import * as switchLanguageActions from "../constants/actions/switchLanguage";
const initialState = {
  selectedLanguage: ""
};

export default createReducer(initialState, {
  [switchLanguageActions.default.UPDATE_SWITCH_LANGUAGE]: (state, language) => {
    return Object.assign({}, state, {
      selectedLanguage: language
    });
  },
});
