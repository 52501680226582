import React, { Component } from "react";
import Tooltip from "../../components/tooltip";

class SelectBox extends Component {
  render() {
    const {
      onChange,
      name,
      title,
      values,
      defaultValue,
      value,
      tooltip
    } = this.props;
    return (
      <React.Fragment>
        <span className="item-label">
          {title}
          <Tooltip content={tooltip} alignment="left">
            <i className="icon-info" role="presentation">
              <span className="for-screenreader-only" />
            </i>
          </Tooltip>
        </span>
        <select
          defaultValue={defaultValue}
          value={value}
          className="one-whole criteria-field"
          onChange={onChange}
          name={name}
        >
          {values.map(item => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
      </React.Fragment>
    );
  }
}

export default SelectBox;
