/**
 * Created by mehmetyurtar on 11/07/2017.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import uiActions from '../actions/ui';
import { t } from '../system/ui';
import { setTitle } from '../system/document';

import { advancedConfig } from '../constants/datamaps/campaign';
import AddCampaign from '../modules/campaign/add';

class AddFormSurvey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 'Form - Survey',
      content: [
        {
          field: 'title',
          options: {
            label: 'Title',
            required: true,
            tooltip: true,
            tooltipContent:
              'Enter the title that your visitors will see on the form survey pop-up banner.',
          },
        },
        {
          field: 'description',
          options: {
            label: 'Description',
            name: 'description',
            tooltip: true,
            tooltipContent:
              'Enter the description of the form survey pop-up banner.',
          },
        },
        {
          field: 'buttonSubmitText',
          options: {
            label: 'Submit Button Text',
            name: 'buttonSubmitText',
            tooltip: true,
            tooltipContent:
              "Enter the 'Call to Action' text. For example, 'Send'",
          },
        },
        { field: 'formFields' },
      ],
      contentExtra: {
        buttons: [
          { field: 'positionButton', ignoreRef: true },
          { field: 'timingButton', ignoreRef: true },
          { field: 'audienceButton', ignoreRef: true },
          { field: 'overlay' },
        ],
        fields: [
          { field: 'positionOptions' },
          { field: 'timingOptions' },
          { field: 'audienceOptions' },
        ],
      },
      options: [
        { field: 'name' },
        { field: 'startEndDate' },
        { field: 'devices' },
        {
          field: 'pages',
          options: {
            hasPageUrlFilter: true,
          },
        },
        {
          field: 'advancedConfig',
          options: advancedConfig.FORM_SURVEY,
        },
      ],
      buttons: [
        { field: 'advancedConfigButton' },
        { field: 'goTest' },
        { field: 'goLive' },
      ],
    };
  }

  componentDidMount() {
    setTitle(t('Add Form - Survey'));
    uiActions.engagementConfirmOnLeave(this);
  }

  render() {
    return (
      <AddCampaign
        type='FORM'
        title={this.state.title}
        content={this.state.content}
        contentExtra={this.state.contentExtra}
        options={this.state.options}
        buttons={this.state.buttons}
        duplicate={this.props.routeParams.campaignId}
      />
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps)(AddFormSurvey);
