import useExtendedContext from '../../../../hooks/module-context/useExtendedContext';
import { CustomerProfileReducers } from './reducers/customer-profile-reducers';
import { customerProfileConstants } from '../constants/constants';
import {
  setCustomerProfileList,
  setCustomerProfileNotes,
  setCustomerProfile,
  resetCustomerProfile,
} from './actions/customer-profile-actions';

const customerProfileState = {
  customerProfileList: [],
  customerProfile: customerProfileConstants,
};

export const { Context, Provider } = useExtendedContext(
  CustomerProfileReducers,
  {
    setCustomerProfileList,
    setCustomerProfileNotes,
    setCustomerProfile,
    resetCustomerProfile,
  },
  customerProfileState,
);
