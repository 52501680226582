import { createConstants } from '../../system/store';

export default createConstants(
  'DATE_RANGE_CHANGED',
  'NAMED_RANGE_CHANGED',
  'RANGE_RESET',
  'VIEW_DAY',
  'VIEW_YESTERDAY',
  'VIEW_WEEK',
  'VIEW_MONTH',
  'VIEW_LAST_MONTH',
  'VIEW_YEARLY',
  'RESET_TO_CUSTOM_SETTINGS',
);
