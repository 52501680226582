import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import * as classes from '../../constants/ui.classes';
import { t } from '../../system/ui';
import * as validations from '../../system/validations';

class NumberField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      showRequiredError: false,
      showEqualToError: false,
    };

    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.validate === true) {
      this.validate();
    }
  }

  onFocus(e) {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    this.validate();
  }

  onChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }

    this.validate();
  }

  static getFieldLabel(fieldName) {
    const field = document.querySelector(`[name=${fieldName}]`);
    return field.parentNode.querySelector('.item-label').innerHTML;
  }

  validate() {
    const { input } = this.refs;
    let hasAnyError = false;

    if (this.props.required) {
      hasAnyError = validations.validateRequired(input, this);
    }

    if (this.props.validation && input.value !== '') {
      if (this.props.validation.equalTo) {
        hasAnyError = validations.validateEqualTo(input, this);
      }

      if (this.props.validation.minNumber) {
        hasAnyError = validations.validateMinNumber(input, this);
      }

      if (this.props.validation.maxNumber) {
        hasAnyError = validations.validateMaxNumber(input, this);
      }
    }

    this.setState({
      hasError: hasAnyError,
    });
  }

  render() {
    const requiredText = this.props.requiredText
      ? this.props.requiredText
      : t('This field is required');

    return (
      <label
        className={classNames(classes.fieldItem, this.props.className, {
          'has-error': this.state.hasError,
        })}
      >
        {this.props.label &&
          (!this.props.labelPlace || this.props.labelPlace === 'before') && (
            <span className={classes.fieldLabel}>{t(this.props.label)}</span>
          )}

        <input
          type='number'
          name={this.props.name}
          id={this.props.id}
          className={classNames(classes.textField, this.props.inputClassName)}
          defaultValue={this.props.defaultValue}
          value={this.props.value}
          readOnly={this.props.readOnly}
          required={this.props.required}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.onChange}
          style={this.props.style}
          placeholder={t(this.props.placeholder)}
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          disabled={this.props.disabled}
          ref='input'
        />
        {this.props.label && this.props.labelPlace === 'after' && (
          <span className={classes.fieldLabel}>{t(this.props.label)}</span>
        )}

        {this.state.hasError && (
          <span className='item-error'>
            {this.state.showRequiredError && <span>{requiredText}</span>}
            {this.state.showEqualToError && (
              <span>
                {t(
                  'This field should equal to #fieldName#',
                  NumberField.getFieldLabel(this.props.validation.equalTo),
                )}
              </span>
            )}
          </span>
        )}
      </label>
    );
  }
}

NumberField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  requiredText: PropTypes.string,
  validation: PropTypes.object,
  readOnly: PropTypes.bool,
  disabled: PropTypes.string,
  style: PropTypes.object,
};

const mapStatesToProps = store => ({
  validate: store.ui.validate,
});

export default connect(mapStatesToProps)(NumberField);
