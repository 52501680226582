/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from 'react';
import { connect } from 'react-redux';

import uiActions from '../actions/ui';
import { t } from '../system/ui';
import { setTitle } from '../system/document';

import { advancedConfig } from '../constants/datamaps/campaign';

import EditCampaign from '../modules/campaign/edit';

class EditPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 'Edit Popup Banner',
      content: [
        {
          field: 'title',
          options: {
            label: 'Title',
            required: false,
            tooltip: true,
            tooltipContent:
              'Enter the title that your visitors will see on the pop-up banner.',
          },
        },
        {
          field: 'imageWithUpload',
          options: {
            label: 'Background Image URL (Recommend 800x700px)',
            required: true,
            tooltip: true,
            tooltipContent:
              'Enter the URL of the image that will be used as the background image of the pop-up banner.',
          },
        },
        {
          field: 'url',
          options: {
            label: 'Target URL',
            name: 'url',
            required: false,
            tooltip: true,
            tooltipContent:
              'Enter the URL of the page that your visitors will be directed when they click on the pop-up banner.',
          },
        },
      ],
      contentExtra: {
        buttons: [
          { field: 'positionButton', ignoreRef: true },
          { field: 'timingButton', ignoreRef: true },
          { field: 'audienceButton', ignoreRef: true },
          { field: 'overlay' },
        ],
        fields: [
          { field: 'positionOptions' },
          { field: 'timingOptions' },
          { field: 'audienceOptions' },
        ],
      },
      options: [
        { field: 'name' },
        { field: 'startEndDate' },
        { field: 'devices' },
        {
          field: 'pages',
          options: {
            hasPageUrlFilter: true,
          },
        },
        {
          field: 'advancedConfig',
          options: advancedConfig.POPUP_BANNER,
        },
      ],
      buttons: [
        { field: 'advancedConfigButton' },
        { field: 'goTest' },
        { field: 'goLive' },
      ],
    };
  }

  componentDidMount() {
    setTitle(t('Edit Popup Banner'));
    uiActions.confirmOnLeave(this);
  }

  render() {
    return (
      <EditCampaign
        type='POPUP_BANNER'
        title={this.state.title}
        content={this.state.content}
        contentExtra={this.state.contentExtra}
        options={this.state.options}
        buttons={this.state.buttons}
        campaignId={this.props.params.campaignId}
      />
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps)(EditPopup);
