import ReactHighcharts from 'react-highcharts';
import moment from 'moment-timezone';

const setHighchartsDefaults = user => {
  const { account } = user;
  if (account) {
    const accountTimezone = account.timezone || 'Europe/Istanbul';
    const offset = -moment()
      .tz(accountTimezone)
      .utcOffset();
    ReactHighcharts.Highcharts.setOptions({
      global: {
        timezoneOffset: offset,
        useUTC: false,
      },
    });
  }
};

export default setHighchartsDefaults;
