import React from 'react';
import { browserHistory } from 'react-router';
import classes from './TemplateInfo.module.scss';
import EventList from '../event-list/EventList';
import { UsedTemplates } from '../used-templates/UsedTemplates';

const TemplateInfo = ({
  templateName,
  setTemplateName,
  saveHandler,
  usedTemplates,
}) => {
  return (
    <div className={classes['info-wrapper']}>
      <h2 className={classes['info-wrapper__header']}>Template Name</h2>
      <div className={classes['info-wrapper__input-section']}>
        <input
          type='input'
          value={templateName}
          onChange={e => setTemplateName(e.target.value)}
          placeholder='Type Here...'
        />
      </div>
      <div className={classes['info-wrapper__buttons-section']}>
        <button
          type='button'
          className={classes['info-wrapper__buttons-section__save']}
          onClick={() => saveHandler()}
        >
          Save
        </button>
        <button
          type='button'
          className={classes['info-wrapper__buttons-section__cancel']}
          onClick={() =>
            browserHistory.push({
              pathname: '/recommendations/templates/list',
            })
          }
        >
          Cancel
        </button>
      </div>
      {usedTemplates?.length > 0 ? (
        <UsedTemplates usedTemplates={usedTemplates} />
      ) : null}
      <EventList />
    </div>
  );
};

export default TemplateInfo;
