/**
 * @author Bilal Çınarlı
 **/

import api from '../../../system/api';
import { $http } from '../../../system/http';
import { getApiKey, getAccountData } from '../../auth/user';

export const getCampaigns = () => {
  return $http(api.getRestEndPoint('v2/campaign')).get();
};

export const getCampaignsWithApiKey = callback => {
  $http(api.getRestEndPoint('v2/campaign') + '?apiKey=' + getApiKey())
    .get()
    .then(response => {
      callback(response);
    });
};

export const getCampaign = instance => {
  return $http(api.getRestEndPoint('v2/campaign') + '/' + instance).get({
    apiKey: getApiKey(),
  });
};
export const getPersoCampaign = instance => {
  return $http(api.getRestEndPoint('v2/campaign') + '/' + instance).get({
    apiKey: getApiKey(),
  });
};
export const addCampaign = campaign => {
  campaign.accountId = getAccountData('accountId') || '';
  return $http(
    api.getRestEndPoint('v2/campaign') + '?apiKey=' + getApiKey(),
  ).post(campaign, { type: 'json' });
};

export const editCampaign = campaign => {
  campaign.accountId = getAccountData('accountId') || '';
  return $http(
    api.getRestEndPoint('v2/campaign') +
      '/' +
      campaign.instanceId +
      '?apiKey=' +
      getApiKey(),
  ).put(campaign, { type: 'json' });
};

export const updateCampaignList = list => {
  return $http(
    api.getRestEndPoint('v2/campaign') + '?apiKey=' + getApiKey(),
  ).put(list, { type: 'json' });
};

export const getCampaignsByType = data => {
  return $http(api.getRestEndPoint('v2/campaign')).get(data);
};

export const moveCampaignToTest = instanceId => {
  return $http(
    api.getRestEndPoint('v2/campaign') +
      '/' +
      instanceId +
      '/TEST?apiKey=' +
      getApiKey(),
  ).put();
};

export const moveCampaignToLive = instanceId => {
  return $http(
    api.getRestEndPoint('v2/campaign') +
      '/' +
      instanceId +
      '/LIVE?apiKey=' +
      getApiKey(),
  ).put();
};

export const moveCampaignToArchive = instanceId => {
  return $http(
    api.getRestEndPoint('v2/campaign') +
      '/' +
      instanceId +
      '/ARCHIVE?apiKey=' +
      getApiKey(),
  ).put();
};
