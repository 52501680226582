import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const EgsVideosIcon = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 21 21'
      fill='none'
    >
      <path d='M5.40002 4.8C3.74317 4.8 2.40002 6.14315 2.40002 7.8V11.2042C2.77304 11.027 3.17634 10.9031 3.60002 10.8425V7.8C3.60002 6.80589 4.40591 6 5.40002 6H13.8C14.7941 6 15.6 6.80589 15.6 7.8V16.2C15.6 17.1941 14.7941 18 13.8 18H13.2V19.2H13.8C15.4569 19.2 16.8 17.8568 16.8 16.2V15L19.68 17.16C20.4711 17.7533 21.6 17.1888 21.6 16.2V7.79998C21.6 6.81113 20.4711 6.24667 19.68 6.83998L16.8 8.99998V7.8C16.8 6.14315 15.4569 4.8 13.8 4.8H5.40002ZM16.8 10.5L20.4 7.79998V16.2L16.8 13.5V10.5Z' />
      <path d='M1.19995 15C1.19995 13.3432 2.5431 12 4.19995 12H8.99995C10.6568 12 12 13.3432 12 15V19.8C12 21.4568 10.6568 22.8 8.99995 22.8H4.19995C2.5431 22.8 1.19995 21.4568 1.19995 19.8V15ZM6.02405 15.0408C5.93046 14.9773 5.82672 14.9455 5.71279 14.9455C5.63345 14.9455 5.55818 14.9624 5.48698 14.9963C5.41578 15.0281 5.35373 15.0715 5.30084 15.1265C5.24795 15.1816 5.20523 15.2461 5.17268 15.3202C5.14217 15.3943 5.1269 15.4726 5.1269 15.5551V19.2449C5.1269 19.3254 5.14217 19.4027 5.17268 19.4767C5.20523 19.5509 5.24795 19.6164 5.30084 19.6736C5.35577 19.7286 5.41883 19.7731 5.49004 19.807C5.56123 19.8388 5.63549 19.8546 5.71279 19.8546C5.77178 19.8546 5.82672 19.8461 5.87758 19.8292C5.93046 19.8122 5.98234 19.7868 6.0332 19.753L8.78869 17.8732C8.8721 17.8182 8.9372 17.744 8.98399 17.6509C9.03078 17.5578 9.05418 17.4604 9.05418 17.3587C9.05418 17.2529 9.02977 17.1556 8.98094 17.0666C8.93212 16.9756 8.86499 16.9026 8.77955 16.8475L6.02405 15.0408Z' />
    </svg>
  );
};

EgsVideosIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

EgsVideosIcon.defaultProps = {
  width: '21px',
  height: '21px',
};

export default EgsVideosIcon;
