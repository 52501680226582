import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import {
  ageOperators,
  dateOperators,
  listOperators,
  numberOperators,
  operatorTitles,
  pageOperators,
  quantityOperators,
  stringOperators,
  urlOperators,
} from '../../../constants/datamaps/rule-based-segmentation';
import { getAccountCurrency } from '../../../modules/auth/user';
import { attributeOperators } from '../../../constants/datamaps/dynamic-segmentation';

const FilterDetail = ({ filterType, statements, isFirst }) => {
  const getFixedOperatorValue = () => {
    const filter = statements[filterType];
    if (filter.length > 1) {
      return 'between';
    }
    const { operator, column } = filter[0];
    switch (filterType) {
      case 'dateOperator':
        return dateOperators[operator]?.name;
      case 'quantityOperator':
        return quantityOperators[operator]?.name;
      case 'amountOperator':
      case 'transactionOperator':
      case 'totalRevenueOperator':
      case 'productViewCountOperator':
      case 'pageViewCountOperator':
        return numberOperators[operator]?.name;
      case 'categoryOperator':
        return listOperators[operator]?.name;
      case 'productOperator':
      case 'brandOperator':
        return stringOperators[operator]?.name;
      case 'urlOperator':
        return urlOperators[operator]?.name;
      case 'attributeOperator':
        return attributeOperators[column]?.name;
      case 'ageOperator':
        return ageOperators[operator]?.name;
      case 'pageOperator':
      case 'deviceOperator':
      case 'operatingSystemOperator':
      case 'emailSubscription':
      case 'pushSubscriptionOperator':
      case 'browserOperator':
      case 'genderOperator':
      case 'customSegmentOperator':
        return pageOperators[operator]?.name;
      default:
        return null;
    }
  };

  const getFixedValue = () => {
    const currency = getAccountCurrency();
    const filter = statements[filterType];

    let value =
      filterType === 'dateOperator' && filter[0].operator !== 'last'
        ? moment(filter[0].value).format('DD/MM/YYYY')
        : filter[0].value;

    let secondValue = '';

    if (filter.length > 1) {
      secondValue =
        filterType === 'dateOperator'
          ? moment(filter[1].value).format('DD/MM/YYYY')
          : filter[1].value;
    }

    if (['totalRevenueOperator', 'amountOperator'].includes(filterType)) {
      value = currency.symbolBefore
        ? `${currency.symbol} ${value}`
        : `${value} ${currency.symbol}`;
      if (secondValue) {
        secondValue = currency.symbolBefore
          ? `${currency.symbol} ${secondValue}`
          : `${secondValue} ${currency.symbol}`;
      }
    }

    if (filterType === 'attributeOperator' && filter[0].value.value) {
      value = filter[0].value.value;
    }

    return `${value}${secondValue ? ` - ${secondValue}` : ''}`;
  };

  if (statements[filterType].length === 0) {
    return null;
  }
  if (!statements[filterType][0]?.value) {
    return null;
  }

  if (filterType === 'dateOperator') {
    return (
      <div className='preview-filter-detail-item'>
        <div className='item-detail'>
          <span className='item-detail-title'>Time Range</span>
          <span className='item-detail-value'>{getFixedOperatorValue()}</span>
        </div>
        <div className='item-detail'>
          <span className='item-detail-title'>
            {statements[filterType][0].operator === 'last'
              ? 'Value Date'
              : 'Date'}
          </span>
          <span className='item-detail-value'>{getFixedValue()}</span>
        </div>
        {statements[filterType][0].operator === 'last' && (
          <div className='item-detail'>
            <span className='item-detail-title'>Value</span>
            <span className='item-detail-value'>
              {statements[filterType][0].unit}
            </span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className='preview-filter-detail-item'>
      <div className='item-detail'>
        <span className='item-detail-title'>
          {isFirst ? 'Operator' : `${operatorTitles[filterType]} Operator`}
        </span>
        <span className='item-detail-value'>{getFixedOperatorValue()}</span>
      </div>
      <div className='item-detail'>
        <span className='item-detail-title'>{operatorTitles[filterType]}</span>
        <span className='item-detail-value'>{getFixedValue()}</span>
      </div>
    </div>
  );
};

FilterDetail.propTypes = {
  filterType: PropTypes.string.isRequired,
  statements: PropTypes.shape({}).isRequired,
  isFirst: PropTypes.bool.isRequired,
};

export default FilterDetail;
