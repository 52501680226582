import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { clone } from 'system/object';
import CardWrapper from './CardWrapper';
import { uiActions } from 'actions';
import { facetedSearchRequest } from '../../../../actions/faceted-search';
import { getAccount } from 'modules/auth/user';

export default class AddOrdering extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: [],
      order: this.props.order,
    };
  }

  componentDidMount() {
    this.getFields();
  }

  getFields = () => {
    const { type, instanceId } = this.props;
    const account = getAccount();
    const language = `&language=${account.mainLanguage}`;
    const instanceIdParam = instanceId ? `&instanceId=${instanceId}` : '';
    const queryParams = `&type=${type}${instanceIdParam}${language}&isOrdering=true`;
    facetedSearchRequest('search/fields', null, queryParams)
      .get()
      .then(data => {
        this.setState({
          data,
          loading: false,
        });
      })
      .catch(err => {
        this.setState({
          error: true,
        });
      });
  };

  apply = () => {
    const { order } = this.state;
    const { addOrderAction } = this.props;
    if (!order.name) {
      uiActions.showErrorNotification(() => <span>Name must be filled</span>);
    } else if (!Array.isArray(order.fields) || order.fields.length === 0) {
      uiActions.showErrorNotification(() => <span>You must select at least one field</span>);
    } else {
      addOrderAction(order);
    }
  };

  onFieldClick = productAccessor => {
    const { order } = this.state;
    const copyOrder = clone(order);
    if (!Array.isArray(copyOrder.fields)) {
      copyOrder.fields = [];
    }
    const index = copyOrder.fields.findIndex(
      field => field.productAccessor === productAccessor,
    );
    if (index >= 0) {
      copyOrder.fields.splice(index, 1);
    } else {
      copyOrder.fields.push({
        productAccessor,
        direction: 'desc',
        textSorting: false,
      });
    }
    this.setState({
      order: copyOrder,
    });
  };

  onNameChange = ({ target }) => {
    const { order } = this.state;
    const copyOrder = clone(order);
    copyOrder.name = target.value;
    this.setState({
      order: copyOrder,
    });
  };

  render() {
    const { data, error, loading, order } = this.state;
    const { close, mode } = this.props;
    return (
      <div className='add-facet-modal'>
        <CardWrapper
          title={mode === 'create' ? 'Add Ordering' : 'Edit Ordering'}
          description={`${mode === 'create' ? 'Add' : 'Edit'} order by selecting related fields and entering order's name`}
          id='add-ordering-modal-card'
          extraClasses='add-facet-modal-card-wrapper'
        >
          {loading && <h3>{error ? 'Failed to fetch fields information' : 'Loading...'}</h3>}
          {!loading && (
            <>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: '5px',
                }}
              >
                <input
                  type='text'
                  value={order?.name}
                  onChange={this.onNameChange}
                  placeholder='Ordering Name'
                  style={{
                    backgroundColor: 'unset',
                  }}
                />
              </div>
              <div style={{ display: 'flex', marginBottom: '5px' }}>
                <span style={{ color: '#6D6C6E' }}>
                  Choose sorting options:
                </span>
              </div>
              <div className='params-container'>
                {data.map(productAccessor => {
                  const checked =
                    order?.fields?.find(
                      field => field.productAccessor === productAccessor,
                    ) !== undefined;
                  const paramClass = classnames('param-wrapper', {
                    'param-wrapper-checked': checked,
                  });
                  const checkBoxClass = classnames('check-group-toggle', {
                    checked,
                  });
                  return (
                    <div
                      key={productAccessor}
                      className={paramClass}
                      onClick={() => this.onFieldClick(productAccessor)}
                    >
                      <span>{productAccessor}</span>
                      <span className={checkBoxClass} />
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <div className='actions-container'>
            <button
              disabled={loading}
              type='button'
              className='facet-action facet-action-add'
              onClick={this.apply}
            >
              Apply
            </button>
            <button
              type='button'
              className='facet-action facet-action-close'
              onClick={close}
            >
              Close
            </button>
          </div>
        </CardWrapper>
      </div>
    );
  }
}

AddOrdering.propTypes = {
  close: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  addOrderAction: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['instant', 'faceted']).isRequired,
  instanceId: PropTypes.string.isRequired,
};
