/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/sort-comp */
/**
 * @author Bilal Çınarlı
 * */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import classNames from 'classnames';

import { types } from '../constants/datamaps/campaign';

import { uiActions, modalActions } from '../actions';
import { getStartEndDate } from '../system/date';

import { t } from '../system/ui';

import {
  getCampaignsByType,
  moveCampaignToArchive,
  updateCampaignList,
} from '../modules/campaign/events';
import {
  personalizationTypes,
  socialProofActions,
  socialProofTypes,
} from '../constants/datamaps/campaigns';

import AllEngagements from './personalization.viewall';

import Icon from '../components/icon';

import { CampaignList } from '../modules/widgets';
import Icons from '../components/icons';
import {
  getApiKey,
  hasModulePrivilege,
  isSwitchAccount,
} from '../modules/auth/user';
import { facetedSearchRequest } from '../actions/faceted-search';
import { GFCampaignList } from '../components/sfy-components/module-exports';
import { updateAccount } from '../modules/account/ajax';
import CampaignHistoryModal from '../modules/campaigns/historymodal';

class Personalization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      type: 'popup',
      idsInAB: [],
      maxCount: {
        visible: true,
        count: 3,
      },
      account: {},
    };

    this.listCampaigns = this.listCampaigns.bind(this);
    this.setCampaignType = this.setCampaignType.bind(this);
    this.onClickLink = this.onClickLink.bind(this);
    this.socialProofSpecialRender = this.socialProofSpecialRender.bind(this);
    this.setSocialProofCampaigns = this.setSocialProofCampaigns.bind(this);
    this.getSocialProofActions = this.getSocialProofActions.bind(this);
    this.addToArchiveConfirmed = this.addToArchiveConfirmed.bind(this);
    this.reDesignMaxCount = this.reDesignMaxCount.bind(this);
    this.debounceLeading = this.debounceLeading.bind(this);
  }

  componentDidMount() {
    uiActions.hideBreadcrumb();

    facetedSearchRequest('experimentation/campaignlist', null, '&module=BT')
      .get()
      .then(idsInAB => this.setState({ idsInAB }));
    uiActions.addPageClass('personalization');
    if (this.props.params && this.props.params.engagement !== 'view-all') {
      if (
        this.props.params &&
        typeof this.props.params.engagement !== 'undefined' &&
        this.props.params.engagement !== 'view-all'
      ) {
        this.setCampaignType(this.props.params.engagement);
      } else {
        uiActions.redirect('/behavioural-targeting/engagement/view-all');
      }
    }
  }

  setCampaignType(engagementType) {
    this.setState(
      {
        type: engagementType,
      },
      () => {
        this.listCampaigns(engagementType);
      },
    );
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      if (
        this.props.params.engagement !== 'view-all' &&
        this.props.params.engagement
      ) {
        this.setState(
          { type: this.props.params.engagement },
          this.listCampaigns,
        );
      }
    }

    if (
      newProps.params !== this.props.params &&
      typeof newProps.params.engagement !== 'undefined'
    ) {
      if (
        newProps.params.engagement !== 'view-all' &&
        newProps.params.engagement
      ) {
        this.setState({ type: newProps.params.engagement }, this.listCampaigns);
      }
    }

    if (typeof newProps.params.engagement === 'undefined') {
      uiActions.redirect('/engagement/popup');
    }
  }

  listCampaigns(param) {
    const type = param || this.state.type;
    uiActions.isLoading();
    getCampaignsByType({
      apiKey: getApiKey(),
      campaignType: types[type].type,
    })
      .then(response => {
        const campaigns =
          typeof response.response === 'object' ? response.response : [];

        this.setState({ campaigns });

        if (type === 'social-proof') {
          const account = isSwitchAccount();
          this.setState({ account });

          if (
            account?.socialProofMaxExecutableCampaigns !== 0 &&
            account?.socialProofMaxExecutableCampaigns > 0
          ) {
            this.setState({
              maxCount: {
                ...this.state.maxCount,
                count: account.socialProofMaxExecutableCampaigns,
                maxSize:
                  campaigns.filter(c => c.status === 'ACTIVE').length +
                  campaigns.filter(c => c.status === 'TEST').length,
              },
            });
          } else {
            this.setState({
              maxCount: {
                ...this.state.maxCount,
                count: campaigns.filter(c => c.status === 'ACTIVE').length,
                maxSize:
                  campaigns.filter(c => c.status === 'ACTIVE').length +
                  campaigns.filter(c => c.status === 'TEST').length,
              },
            });
          }
        }
        uiActions.isLoaded();
      })
      .catch(response => {
        const error = () => <p>{response?.response?.message}</p>;

        uiActions.showNotification({
          content: error,
          className: 'notification-fail',
        });
        uiActions.isLoaded();
      });
  }

  onClickLink(e) {}

  socialProofSpecialRender() {
    return Object.keys(socialProofTypes).map(typeKey => {
      const type = socialProofTypes[typeKey];
      const link = `/campaign/social-proof/${type.id}/add`;

      return (
        <Link
          key={type.id}
          to={link}
          className='available-type available-type-social-proof'
          onClick={this.onClickLink}
        >
          <span className='available-type-wireframe'>
            <span className='campaign-title'>
              <Icons name={type.iconName} />
              {type.name}
            </span>
            <span className='campaign-description'>{type.description}</span>
            <div className='campaign-create-icon-field'>
              <Icon className='plus-icon' name='plus' size='2x' />
              <span className='create-text'>{t('Create')}</span>
            </div>
          </span>
        </Link>
      );
    });
  }

  topCreateButtonRender() {
    const link = `/campaign/${this.state.type}/add`;

    return (
      <Link
        to={link}
        className='available-type available-type-custom available-type-filled'
        onClick={this.onClickLink}
      >
        <span className='available-type-wireframe'>
          <Icon name='plus' size='2x' />
          <span>{t(types[this.state.type].new)}</span>
        </span>
      </Link>
    );
  }

  addToArchiveConfirmed(instanceId) {
    moveCampaignToArchive(instanceId)
      .then(response => {
        const message = () => (
          <p>
            Campaign <strong>{response.response.name}</strong> moved to Archive
          </p>
        );
        uiActions.showNotification({ content: message });

        this.listCampaigns();
      })
      .catch(response => {
        const error = () => <p>{response?.response?.message}</p>;
        uiActions.showErrorNotification(error);
      });
  }

  setSocialProofCampaigns(campaigns) {
    uiActions.isLoading();
    const newCampaignList = [
      ...campaigns.active,
      ...campaigns.test,
      ...campaigns.passive,
    ];
    updateCampaignList(newCampaignList).then(res => {
      const campaigns = typeof res.response === 'object' ? res.response : [];
      const message = () => <div>Priority updated successfully.</div>;
      uiActions.isLoaded();
      uiActions.showNotification({ content: message });
      this.setState({ campaigns });
    });
  }

  getSocialProofActions() {
    return socialProofActions.map(action => {
      const newAction = {
        ...action,
      };

      if (newAction.handler === 'editAction') {
        newAction.actionFunc = (instanceId, type) => {
          const relatedType = types['social-proof']?.relatedTypes
            ? types['social-proof']?.relatedTypes[type]
            : null;
          uiActions.redirect(
            `/campaign/social-proof/${relatedType.pathType}/edit/${instanceId}`,
          );
        };
      }

      if (newAction.handler === 'duplicateAction') {
        newAction.actionFunc = (instanceId, type) => {
          const relatedType = types['social-proof']?.relatedTypes
            ? types['social-proof']?.relatedTypes[type]
            : null;
          uiActions.redirect(
            `/campaign/social-proof/${relatedType.pathType}/duplicate/${instanceId}`,
          );
        };
      }

      if (newAction.handler === 'archiveAction') {
        newAction.actionFunc = instanceId => {
          uiActions.confirmationDialog({
            onConfirm: () => this.addToArchiveConfirmed(instanceId),
          });
        };
      }

      if (newAction.handler === 'historyAction') {
        newAction.actionFunc = instanceId => {
          const campaignHistoryModal = () => (
            <CampaignHistoryModal campaignId={instanceId} engagement />
          );

          modalActions.openModal({
            title: `Campaign History (${instanceId})`,
            content: campaignHistoryModal,
          });
        };
      }

      return newAction;
    });
  }

  debounceLeading(func, wait, immediate) {
    let timeout;

    return function executedFunction() {
      const context = this;
      const args = arguments;

      const later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      const callNow = immediate && !timeout;

      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      if (callNow) func.apply(context, args);
    };
  }

  updateAccountWithDebounce = this.debounceLeading(account => {
    updateAccount(
      account,
      response => {
        const notificationContent = () => <div>{response.message}</div>;
        let notificationClassName;

        if (response.status !== 'OK') {
          notificationClassName = 'notification-fail';
        }

        uiActions.showNotification({
          content: notificationContent,
          className: notificationClassName,
        });
        uiActions.isLoaded();
      },
      { isSwitchedUser: true },
    );
  }, 1800);

  reDesignMaxCount() {
    const { maxCount, account } = this.state;
    maxCount.onInputChange = value => {
      maxCount.count = value;
      if (value > 0) {
        account.socialProofMaxExecutableCampaigns = value;
        this.updateAccountWithDebounce(account);
      }
      this.setState({ maxCount });
    };
    return maxCount;
  }

  render() {
    const { idsInAB } = this.state;
    if (!hasModulePrivilege(false, 'ENGAGEMENT')) {
      browserHistory.push('/');
    }
    const link = `/campaign/${this.state.type}/add`;
    const timeRange = getStartEndDate('lastSevenDays');
    let isNewDesign = false;
    let socialProofCampaigns = [];
    if (this.state.type.includes('social-proof')) {
      isNewDesign = true;
    }

    if (
      this.props.params.engagement === 'social-proof' &&
      this.state.campaigns.length
    ) {
      socialProofCampaigns = this.state.campaigns.map(campaign => {
        return {
          ...campaign,
          index: this.state.campaigns
            .map(e => e?.instanceId)
            .indexOf(campaign?.instanceId),
          priority:
            this.state.campaigns
              .map(e => e?.instanceId)
              .indexOf(campaign?.instanceId) + 1,
        };
      });
    }

    return (
      <div>
        {this.props.params.engagement === 'view-all' ||
        !this.props.params.engagement ? (
          <AllEngagements
            {...this.props}
            timeRange={timeRange}
            key='view-all'
            idsInAB={idsInAB}
          />
        ) : (
          <div>
            <h2
              className={`page-title ${
                types[this.state.type].isButtonsTop ? 'low-space' : ''
              }`}
            >
              {t(types[this.state.type].title)}
            </h2>
            {types[this.state.type].isButtonsTop &&
              this.topCreateButtonRender()}

            {this.state.type === 'social-proof' && (
              <>
                <span
                  style={{
                    display: 'block',
                    fontSize: '19px',
                    fontWeight: 600,
                    color: '#4F4F4F',
                    marginBottom: '20px',
                  }}
                >
                  {t('Campaigns')}
                </span>
                <div className='personalization-social-proof'>
                  {this.socialProofSpecialRender()}
                </div>
              </>
            )}

            {this.props.params.engagement === 'social-proof' ? (
              <GFCampaignList
                campaignList={{
                  active: socialProofCampaigns.filter(
                    c => c.status === 'ACTIVE',
                  ),
                  test: socialProofCampaigns.filter(c => c.status === 'TEST'),
                  passive: socialProofCampaigns.filter(
                    c => c.status !== 'ACTIVE' && c.status !== 'TEST',
                  ),
                }}
                setCampaignList={this.setSocialProofCampaigns}
                actions={this.getSocialProofActions()}
                maxCount={this.reDesignMaxCount()}
              />
            ) : this.state.campaigns.filter(
                camp => camp.status === 'TEST' || camp.status === 'ACTIVE',
              ).length > 0 ? (
              <div className='widget-list-wrapper'>
                <CampaignList
                  status='active'
                  campaigns={this.state.campaigns}
                  open
                  type={this.state.type}
                  onUpdate={() => this.listCampaigns()}
                  newDesign={isNewDesign}
                  idsInAB={idsInAB}
                />
                <CampaignList
                  status='test'
                  campaigns={this.state.campaigns}
                  type={this.state.type}
                  onUpdate={() => this.listCampaigns()}
                  newDesign={isNewDesign}
                  idsInAB={idsInAB}
                />
                <CampaignList
                  status='active'
                  campaigns={this.state.campaigns}
                  type={this.state.type}
                  onUpdate={() => this.listCampaigns()}
                  dateFilter='SCHEDULED'
                  newDesign={isNewDesign}
                  idsInAB={idsInAB}
                />
                <CampaignList
                  status='active'
                  campaigns={this.state.campaigns}
                  type={this.state.type}
                  onUpdate={() => this.listCampaigns()}
                  dateFilter='COMPLETED'
                  newDesign={isNewDesign}
                  idsInAB={idsInAB}
                />
              </div>
            ) : (
              <div className='widget-list-wrapper'>
                <div className='widget-list-no-items'>
                  {t('No Active Campaigns')}
                </div>
              </div>
            )}

            {this.state.type !== 'social-proof' &&
            this.state.type !== 'persona-quiz' &&
            !types[this.state.type].isButtonsTop
              ? Object.keys(personalizationTypes).map((type, index) => {
                  if (this.state.type === 'segmentation') {
                    // There is no placeholder image for segmentation campaign, so hide first placeholder.
                    return false;
                  }
                  return (
                    <Link
                      key={index}
                      to={link}
                      className={classNames(
                        'available-type',
                        'available-type-personalization',
                      )}
                      onClick={this.onClickLink}
                    >
                      <h4 className='available-type-name'>
                        {this.state.type === 'hero-banner'
                          ? 'Image Banner'
                          : t(types[this.state.type].title)}
                      </h4>
                      <span>{t('Campaign')}</span>
                      <span
                        className={classNames(
                          'available-type-wireframe',
                          `available-type-personalization-${this.state.type}`,
                        )}
                      />
                    </Link>
                  );
                })
              : null}
            {this.props.params &&
            typeof this.props.params.engagement !== 'undefined' &&
            !types[this.state.type].isButtonsTop &&
            this.state.type !== 'social-proof' &&
            this.state.type !== 'persona-quiz' ? (
              <Link
                to={link}
                className='available-type available-type-custom available-type-personalization'
                onClick={this.onClickLink}
              >
                <span className='available-type-wireframe'>
                  <Icon name='plus' size='2x' />
                  <span>{t(types[this.state.type].new)}</span>
                </span>
              </Link>
            ) : (
              ''
            )}

            {this.props.params &&
            typeof this.props.params.engagement !== 'undefined' &&
            this.props.params.engagement === 'persona-quiz' &&
            !types[this.state.type].isButtonsTop ? (
              <Link
                to={link}
                className='available-type available-type-custom available-type-personalization-persona-quiz'
                onClick={this.onClickLink}
              >
                <span className='available-type-wireframe'>
                  <Icon name='plus' size='2x' />
                  <span>{t(types[this.state.type].new)}</span>
                </span>
              </Link>
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  user: store.user.user,
});

export default connect(MapStatesToProps)(Personalization);
