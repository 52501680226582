import React from 'react';
import './ExperimentationReportResultsTable.scss';
import Icons from '../../icons';
import { localeString, localeStringMoney } from '../../../system/string';
import { getAccountCurrency } from '../../../modules/auth/user';
import { fixPrecision } from './ExperimentationReport';

const resultTableRows = [
  { metricKey: 'Widget View' },
  { metricKey: 'Impression' },
  { metricKey: 'Click' },
  { metricKey: 'CTR', isPercent: true },
  { metricKey: 'Basket Products' },
  { metricKey: 'Basket Rate', isPercent: true },
  { metricKey: 'Total Revenue', currency: true },
  { metricKey: 'Revenue Per View', currency: true },
  { metricKey: 'Average Order Contribution', currency: true },
  { metricKey: 'Purchases' },
  { metricKey: 'Purchased Products' },
  { metricKey: 'Conversion Rate', isPercent: true },
];

export default class ExperimentationReportResultsTable extends React.Component {
  constructor(props) {
    super(props);
  }

  getHeader = () => {
    const { experiment } = this.props;
    const withoutOriginal =
      experiment?.variations?.filter(
        variation => variation.variationName !== 'Original',
      ) ?? [];
    return (
      <thead>
        <tr>
          <th scope='col'>Metrics</th>
          <th scope='col'>Original</th>
          {withoutOriginal.map(variation => (
            <>
              <th scope='col'>{variation.variationName}</th>
              <th scope='col'>Normalized Improvement</th>
            </>
          ))}
        </tr>
      </thead>
    );
  };

  getNormalizedImprovement(improvement, improvementRatio) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>{improvement}</span>
        <span
          style={{
            color:
              improvementRatio > 0
                ? '#1B9645'
                : (improvementRatio < 0 ? '#CC4230' : 'inherit'),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span>{Math.abs(improvementRatio)}%</span>
          {improvementRatio !== 0 && (
            <span
              style={{
                transform: `rotate(${improvementRatio > 0 ? '' : '-'}90deg)`,
                display: 'inline-block',
              }}
            >
              <Icons
                name='backarrow'
                color={improvementRatio > 0 ? '#1B9645' : '#CC4230'}
                width={10}
                height={10}
              />
            </span>
          )}
        </span>
      </div>
    );
  }

  getFormattedValue(value, currency, isPercent) {
    let formattedValue = isPercent
      ? parseFloat(fixPrecision((value ?? 0) * 100))
      : value;
    if (currency) {
      formattedValue = localeStringMoney(
        formattedValue,
        false,
        getAccountCurrency(),
      );
    } else {
      formattedValue = localeString(formattedValue);
    }
    if (isPercent) {
      formattedValue = `${formattedValue}%`;
    }
    return formattedValue;
  }

  getBody() {
    const { experiment, report } = this.props;
    const originalInstanceId = experiment?.variations?.find(variation => variation.variationName === 'Original')?.instanceId;
    const variations =
      experiment?.variations?.filter(
        variation => variation.variationName !== 'Original',
      ) ?? [];
    return (
      <tbody>
        {resultTableRows.map(({ metricKey, currency, isPercent }) => {
          const metricOfOriginal = report?.[originalInstanceId]?.dataList?.find(data => data.x === metricKey)?.y ?? 0;
          return (
            <tr>
              <td>{metricKey}</td>
              <td>{this.getFormattedValue(metricOfOriginal, currency, isPercent)}</td>
              {variations.map(variation => {
                const variationData = report?.[variation.instanceId]?.dataList?.find(data => data.x === metricKey) ?? {y: 0, z: 0, t: 0};
                return (
                  <>
                    <td>{this.getFormattedValue(variationData.y, currency, isPercent)}</td>
                    <td>{this.getNormalizedImprovement(fixPrecision(variationData.z ?? 0), fixPrecision((variationData.t ?? 0) * 100) )}</td>
                  </>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  }

  render() {
    const { experiment, report } = this.props;
    if (!experiment || !report) return null;

    return (
      <div className='experimentation-result-table-container'>
        <p>Results</p>
        <div style={{ position: 'relative', width: '100%', overflowX: 'scroll', overflowY: 'hidden' }}>
          <div style={{ width: '100%', maxWidth: '100%' }}>
            <table className='data-table result-table'>
              {this.getHeader()}
              {this.getBody()}
            </table>
          </div>
        </div>
      </div>
    );
  }
}
