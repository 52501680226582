import React from 'react';
import { clone } from '../../system/object';
import { toCamelCase } from '../../system/string';
import {
  calculateRatio,
  calculateProportion,
  numericCalculateRatio,
  diffRatio,
} from '../../system/ratio';

import {
  trendsMap,
  trendsMapWithDiff,
  permissionTrendsMap,
} from '../../constants/datamaps/trends';

const transformTrends = (
  trendData,
  purchaseAmount,
  purchaseCount,
  purchaseItemCount,
  isPushPermission,
  selectedDevice,
  isCampaignDetail,
) => {
  let trends;
  let mappedTrends;
  let totalRevenue;
  let totalPurchase;
  let totalPurchaseItems;
  if (!Array.isArray(trendData)) {
    return [];
  }
  if (!isPushPermission) {
    trendData = transformCampaignReport(trendData, selectedDevice);
    if (isCampaignDetail) {
      trends = clone(trendsMapWithDiff);
    } else {
      trends = clone(trendsMap);
    }

    (mappedTrends = {
      impression: 0,
      widgetView: 0,
      click: 0,
      basketItems: 0,
      basketAmount: 0,
      purchaseAmount: 0,
      purchaseAmountPrev: 0,
      purchases: 0,
      purchasedItems: 0,
      'notification[clicked]': 0,
      'notification[shown]': 0,
      'notification[sent]': 0,
      'purchase:amount': 0,
    }),
      (totalRevenue = purchaseAmount || 0),
      (totalPurchase = purchaseCount || 0),
      (totalPurchaseItems = purchaseItemCount || 0);

    if (trendData && trendData.length > 0) {
      trendData.forEach(item => {
        if (item.x && item.y) mappedTrends[toCamelCase(item.x)] = item.y;
      });
    }

    if (!totalRevenue) {
      totalRevenue = mappedTrends['purchase:amount'] || 0;
    }

    // remap data for the view
    trends.widget.stats.all.count =
      mappedTrends['notification[sent]'] || trends.widget.stats.all.count;
    trends.widget.stats.view.count =
      mappedTrends['notification[shown]'] || trends.widget.stats.view.count;

    trends.widget.stats.conversion.ratio = calculateRatio(
      trends.widget.stats.view.count,
      trends.widget.stats.all.count,
    );

    trends.interaction.stats.all.count =
      mappedTrends['notification[clicked]'] ||
      trends.interaction.stats.all.count;
    trends.interaction.stats.conversion.ratio = calculateRatio(
      trends.interaction.stats.all.count,
      trends.widget.stats.view.count,
    );

    trends.basket.stats.all.count =
      mappedTrends.basketItems || trends.basket.stats.all.count;
    trends.basket.stats.amount.count =
      mappedTrends.basketAmount || trends.basket.stats.amount.count;
    trends.basket.stats.conversion.ratio = calculateRatio(
      trends.basket.stats.all.count,
      trends.interaction.stats.all.count,
    );

    trends.revenue.stats.all.count =
      mappedTrends.purchaseAmount || trends.revenue.stats.all.count;
    trends.revenue.stats.purchases.count =
      mappedTrends.purchases || trends.revenue.stats.purchases.count;
    trends.revenue.stats.items.count =
      mappedTrends.purchasedItems || trends.revenue.stats.items.count;
    trends.revenue.stats.average.count = calculateProportion(
      mappedTrends.purchaseAmount,
      mappedTrends.purchases,
    );
    trends.revenue.stats.purchases.ratio = calculateRatio(
      trends.revenue.stats.purchases.count,
      totalPurchase,
    );
    trends.revenue.stats.items.ratio = calculateRatio(
      trends.revenue.stats.items.count,
      totalPurchaseItems,
    );

    trends.revenue.stats.conversion.ratio = calculateRatio(
      trends.revenue.stats.purchases.count,
      trends.interaction.stats.all.count,
    );
    trends.revenue.stats.contribution.ratio = calculateRatio(
      trends.revenue.stats.all.count,
      totalRevenue,
    );

    if (isCampaignDetail) {
      trends.revenue.stats.diff.ratio = calculateRatio(
        trends.revenue.stats.all.count,
        totalRevenue,
      );

      const numericRatio = numericCalculateRatio(
        trends.revenue.stats.all.count,
        totalRevenue,
      );

      const numericRatioPrev = numericCalculateRatio(
        mappedTrends.purchaseAmountPrev,
        mappedTrends['purchase:amountPrev'],
      );

      const diff = diffRatio(numericRatio, numericRatioPrev);

      trends.revenue.stats.diff.ratio = (
        <span style={{ color: '#f0000' }}>
          <span>{diff}</span>
          {numericRatio > numericRatioPrev && (
            <span style={{ color: 'rgb(86, 188, 148)' }}>▲</span>
          )}

          {numericRatio < numericRatioPrev && (
            <span style={{ color: '#cc4331' }}>▼</span>
          )}
        </span>
      );
    }
  } else {
    trends = clone(permissionTrendsMap);
    mappedTrends = {
      impression: 0,
      widgetView: 0,
      click: 0,
      basketItems: 0,
      basketAmount: 0,
      purchaseAmount: 0,
      purchases: 0,
      purchasedItems: 0,
      'notificationPermission[granted]': 0,
      'notificationPermission[denied]': 0,
      formSubmit: 0,
      'notification[shown]': 0,
      'notification[sent]': 0,
      'notification[clicked]': 0,
      segmentVisitor: 0,
      unsegmentVisitor: 0,
      toggleVisitorSegment: 0,
    };
    totalRevenue = purchaseAmount || 0;

    if (trendData && trendData.length > 0 && Array.isArray(trendData)) {
      trendData.forEach(item => {
        mappedTrends[toCamelCase(item.x)] = item.y;
      });
    }
    // remap data for the view
    trends.widget.stats.all.count =
      mappedTrends.impression || trends.widget.stats.all.count;
    trends.grantedPermission.stats.all.count = mappedTrends.impression;
    trends.deniedPermission.stats.all.count = mappedTrends.impression;
    trends.grantedPermission.stats.granted.count =
      mappedTrends['notificationPermission[granted]'];
    trends.deniedPermission.stats.denied.count =
      mappedTrends['notificationPermission[denied]'];
  }
  return trends;
};

const unique = data => {
  const newData = [];
  if (!data) {
    return newData;
  }
  data.forEach(function(o) {
    if (!this[o.x]) {
      this[o.x] = { x: o.x, y: 0 };
      newData.push(this[o.x]);
    }
    this[o.x].y += o.y;
  }, Object.create(null));
  return newData;
};

const transformCampaignReport = (reportData, selectedDevice) => {
  if (!selectedDevice) {
    return reportData;
  }
  const finalData = reportData.filter(data => {
    if (data.x === 'purchase:amount') {
      return true;
    }
    if (selectedDevice === 'all') {
      return true;
    }
    if (selectedDevice === 'tablet' || selectedDevice === 'mobile') {
      return data.z === 'tablet' || data.z === 'mobile';
    }
    return data.z === selectedDevice;
  });
  return unique(finalData);
};

export default transformTrends;
