/**
 * @author Bilal Cinarli
 */

'use strict';

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { uiActions } from '../../actions';

import { t } from '../../system/ui';

import { getInsight } from '../../modules/insights/ajax';
import Export from '../../components/export';

import Product from './widgets/product';
import ProductsDetails from './products-details';
import { insightsQuery, insightsQueryWeek } from '../../modules/insights/data';
import { clone } from '../../system/object';
import {
  insightsExportCount,
  isInsightsCountDefined,
  getCustomInsightColumns,
} from '../../modules/auth/user';
import InsightPlaceHolder from './widgets/placeholder';

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      ui: 'isLoading',
    };

    this.listDetails = this.listDetails.bind(this);
    this.exportFile = this.exportFile.bind(this);
    this.setQueryandListProducts = this.setQueryandListProducts.bind(this);
  }

  componentDidMount() {
    this.setQueryandListProducts();
  }

  setQueryandListProducts() {
    this.listProducts();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.query !== newProps.query && !newProps.showModal) {
      this.listProducts(newProps.query);
    }
  }

  getInsightData(endpoint, query, range) {
    uiActions.isLoading();
    this.setState({
      products: [],
      ui: 'isLoading',
    });
    getInsight(
      endpoint,
      query,
      response => {
        const insightProducts = response !== '' ? response : [];
        insightProducts.forEach(currentInsightItem => {
          const insightProduct = currentInsightItem;
          insightProduct.id = insightProduct.record.productId || '';
          insightProduct.productId = insightProduct.record.productId || '';
          insightProduct.inStock = insightProduct.record.inStock || false;
          insightProduct.productCategory = insightProduct.record.categories
            ? insightProduct.record.categories.join(',')
            : '';
          insightProduct.productBrand = insightProduct.record.brand || '';
          insightProduct.productName = insightProduct.record.name || '';
          insightProduct.productOldPrice = insightProduct.record.oldPrice || '';
          insightProduct.productPrice = insightProduct.record.price || '';
          insightProduct.productURL = insightProduct.record.url || '';
          insightProduct.productImage = insightProduct.record.image || '';
        });
        this.setState({ products: insightProducts });
        this.props.productsFn(insightProducts.length);
        if (this.props.hasProducts) {
          this.props.hasProducts(this.props.id, insightProducts.length);
        }
        uiActions.isLoaded();
        this.setState({
          ui: 'isLoaded',
        });
      },
      this.props.isMsp ? { switchUser: this.props.switchUser } : null,
    );
  }

  listProducts(query) {
    let _query =
      query ||
      (this.props.isWeekSelect
        ? clone(insightsQueryWeek)
        : clone(insightsQuery));
    if (isInsightsCountDefined()) {
      _query.items = isInsightsCountDefined();
    }
    if (this.props.isMsp) {
      _query.items = this.props.query.items;
    }
    this.getInsightData(this.props.endpoint, _query);
  }

  listDetails(e) {
    const { range, query, name, id, isWeekSelect, endpoint } = this.props;
    const queryObj = {
      ...query,
      startDate: range[0].format('YYYY-MM-DD'),
      endDate: range[1].format('YYYY-MM-DD'),
    };
    e.preventDefault();
    const content = () => (
      <ProductsDetails
        products={this.state.products}
        insightName={name}
        endpoint={endpoint}
        query={queryObj}
        insightId={id}
        isWeekSelect={isWeekSelect}
        customFields={getCustomInsightColumns()}
      />
    );

    uiActions.openModal({
      title: this.props.name,
      subtitle: this.props.desc,
      className: 'insights-modal',
      content,
    });
  }

  exportFile(e) {
    e.preventDefault();
    let _query = clone(this.props.query);
    _query.export = true;
    _query.export_mail = true;
    if (!this.props.isMsp) {
      _query.items = insightsExportCount() ?? _query.items;
    }

    let name = this.props.name.replace(/\s/g, '-') + '-Report';

    let content = () => (
      <Export
        name={name}
        query={_query}
        insightsId={this.props.id}
        endpoint={this.props.endpoint}
      />
    );

    uiActions.openModal({ title: t('Export Products'), content });
  }

  render() {
    if (this.state.products.length === 0 && this.state.ui !== 'isLoading') {
      return null;
    }

    return (
      <div className='product-insights'>
        <h3 className='widget-title'>{t(this.props.name)}</h3>
        <p className='widget-description'>{t(this.props.desc)}</p>
        <div>
          <a className='secondary-action view-more' onClick={this.listDetails}>
            {t('Details')}
          </a>
          {this.state.products.length > 0 && (
            <a
              className='secondary-action ghost export'
              onClick={this.exportFile}
            >
              <i className='icon-pdf' /> {t('Export')}
            </a>
          )}
          {this.state.products.length > 0 ? (
            <div>
              <div className={classNames('product-insights-details')}>
                {this.state.products.map((product, index) => {
                  if (index > 3) {
                    return;
                  }
                  if (this.state.ui === 'isLoading') {
                    return (
                      <InsightPlaceHolder
                        key={`insight-product-${product.id}`}
                      />
                    );
                  } else {
                    return (
                      <Product
                        {...product}
                        insightsId={this.props.id}
                        key={`insight-product-${product.id}`}
                        customFields={getCustomInsightColumns()}
                      />
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            <div
              className={classNames(
                'product-insights-details product-insights-nodata',
              )}
            >
              {this.state.ui === 'isLoading' && <InsightPlaceHolder />}
              {this.state.ui !== 'isLoading' && (
                <div>
                  <i className='icon-info' />
                  {t('You do not have enough data for this insight.')}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  showModal: store.modal.showModal,
});

export default connect(mapStatesToProps)(Products);
