/**
 * @fileOverview Shop The Look Actions
 * @description This file contains the actions for the Shop The Look component
 * @param {React.dispatch}dispatch
 * @returns {void}
 */

export const setSTLBundleGroupName = dispatch => {
  return bundleGroupName => {
    dispatch({
      type: 'SET_SHOP_THE_LOOK_BUNDLE_GROUP_NAME',
      payload: bundleGroupName,
    });
  };
};

export const setRelatedProductId = dispatch => {
  return relatedProductId => {
    dispatch({
      type: 'SET_RELATED_PRODUCT_ID',
      payload: relatedProductId,
    });
  };
};

export const setSTLList = dispatch => {
  return list => {
    dispatch({
      type: 'SET_SHOP_THE_LOOK_LIST',
      payload: list,
    });
  };
};

export const setSTLBundleList = dispatch => {
  return bundleList => {
    dispatch({
      type: 'SET_SHOP_THE_LOOK_BUNDLE_LIST',
      payload: bundleList,
    });
  };
};

export const setSTLBundle = dispatch => {
  return bundle => {
    dispatch({
      type: 'SET_SHOP_THE_LOOK_BUNDLE',
      payload: bundle,
    });
  };
};

export const removeSTLBundle = dispatch => {
  return index => {
    dispatch({
      type: 'REMOVE_SHOP_THE_LOOK_BUNDLE',
      payload: index,
    });
  };
};

export const editSTLBundle = dispatch => {
  return bundle => {
    dispatch({
      type: 'EDIT_SHOP_THE_LOOK_BUNDLE',
      payload: bundle,
    });
  };
};

export const setSTLInclusionExclusionOfCategory = dispatch => {
  return payload => {
    dispatch({
      type: 'SET_SHOP_THE_LOOK_INCLUSION_EXCLUSION_OF_CATEGORY',
      payload,
    });
  };
};

export const setSTLPreview = dispatch => {
  return preview => {
    dispatch({
      type: 'SET_SHOP_THE_LOOK_PREVIEW',
      payload: preview,
    });
  };
};

export const resetSTL = dispatch => {
  return () => {
    dispatch({
      type: 'RESET_SHOP_THE_LOOK',
    });
  };
};
