import React from "react";

class IconFirefox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox="0 0 23.6 24.4"
        fill={this.state.color}
      >
        <path
          d="M23.5,11.1c0-0.2-0.1-0.3-0.1-0.3s-0.1,0.1-0.2,0.3c0-0.5-0.1-1-0.3-1.6c-0.2-0.6-0.4-1.2-0.7-1.8c-0.2-0.4-0.4-0.8-0.7-1.1
	c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4-0.7-0.9-1.1-1.5-2c-0.4-0.6-0.6-1.3-0.8-2c-0.2,0.4-0.3,0.9-0.4,1.3c-0.6-0.6-1.1-1-1.4-1.3
	C15.7,0.7,15.9,0,15.9,0S13,3.2,14.3,6.5c0.4,1.1,1.2,2.1,2.1,2.8c1.2,1,2.5,1.8,3.2,3.8c-0.6-1-1.4-1.9-2.4-2.5
	c0.3,0.7,0.5,1.5,0.5,2.3c0,3-2.4,5.4-5.4,5.4c-0.4,0-0.8,0-1.2-0.1c-0.5-0.1-0.9-0.2-1.3-0.5C9,17.4,8.5,16.9,8,16.3l0,0l0.1,0
	c0.2,0.1,0.5,0.1,0.7,0.2c0.9,0.2,1.9,0.1,2.7-0.3c0.9-0.5,1.4-0.8,1.8-0.7h0c0.4,0.1,0.7-0.3,0.4-0.7c-0.5-0.7-1.3-1-2.2-0.8
	c-0.9,0.1-1.6,0.7-2.8,0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0,0.2,0.1,0.2,0c-0.2-0.1-0.5-0.3-0.7-0.4c0,0,0.2,0.1,0.2,0
	c-0.3-0.2-0.5-0.5-0.7-0.7c-0.2-0.4-0.2-0.8,0-1.2c0.1-0.2,0.3-0.3,0.5-0.4c0.1,0.1,0.2,0.1,0.2,0.1s-0.1-0.1-0.1-0.2c0,0,0,0,0,0
	c0.1,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0-0.1c-0.1-0.1-0.1-0.2-0.3-0.3h0c0.1,0.1,0.2,0.1,0.3,0.2
	c0.1-0.2,0.1-0.5,0.1-0.7c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.1,0.1,0c0-0.1,0-0.1-0.1-0.2v0c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.2-0.1
	c0.4-0.2,0.7-0.4,1.1-0.6c0.3-0.1,0.6-0.2,0.6-0.3c0.1,0,0.2-0.1,0.2-0.2c0.3-0.2,0.4-0.5,0.5-0.9c0,0,0-0.1,0-0.1V7.8
	c0-0.2-0.3-0.3-1.9-0.4c-0.5-0.1-1-0.5-1.1-1v0c0,0.1,0,0.1-0.1,0.2c0-0.1,0-0.1,0.1-0.2v0c0.3-0.8,0.8-1.4,1.5-1.9c0,0-0.2,0-0.1,0
	c0.1-0.1,0.3-0.1,0.4-0.2c0.1,0-0.3-0.2-0.6-0.1c-0.2,0-0.4,0.1-0.6,0.1c0.1-0.1,0.3-0.2,0.3-0.2C9.3,4.2,8.9,4.3,8.5,4.5
	c0,0,0-0.1,0-0.1C8.2,4.5,8,4.7,7.8,5c0,0,0-0.1,0-0.1C7.7,5,7.5,5.1,7.4,5.2l0,0C6.6,4.9,5.6,4.8,4.7,5l0,0h0
	C4.6,4.8,4.4,4.6,4.3,4.4l0,0l0,0C4.2,4.3,4.1,4.2,4.1,4.1C4,4.1,4,4,3.9,3.9c0,0,0,0,0,0c0,0,0,0.1,0,0.1v0
	C3.7,3.5,3.6,3.1,3.6,2.6l0,0C3.4,2.8,3.2,3.1,3.1,3.4C3,3.5,3,3.5,3,3.6v0c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0C2.9,3.5,2.8,3.6,2.8,3.7
	c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0-0.1,0-0.1l0,0C2.3,4.6,2.1,5.4,2.1,6.2c0,0.1,0,0.3,0,0.4v0C1.8,6.9,1.6,7.2,1.4,7.5
	c-0.6,1-1,2.1-1.3,3.2c0.2-0.4,0.4-0.8,0.7-1.2C0.3,10.8,0,12.2,0,13.7c0.1-0.4,0.2-0.8,0.3-1.2c-0.1,1.7,0.2,3.4,1,4.9
	c1,2.1,2.5,3.9,4.5,5.2c0.8,0.6,1.7,1,2.6,1.3c0.1,0,0.3,0.1,0.4,0.1c0,0-0.1,0-0.1,0c1.1,0.3,2.3,0.5,3.4,0.5
	c4.1,0,5.5-1.6,5.6-1.7c0.2-0.2,0.4-0.4,0.5-0.7c0.1,0,0.2-0.1,0.2-0.1l0,0l0.1,0c0.6-0.3,1.2-0.7,1.7-1.1c0.8-0.6,1.4-1.4,1.6-2.4
	c0.1-0.3,0.2-0.7,0-1.1c0-0.1,0.1-0.1,0.1-0.2c0.9-1.4,1.4-3,1.5-4.7v-0.1C23.6,11.8,23.6,11.5,23.5,11.1z"
        />
      </svg>
    );
  }
}

export default IconFirefox;
