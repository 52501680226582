import React from 'react';
import SynonymSettings from './settings.search.synonym';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { clone } from 'system/object';
import { searchSettingsCampaignInitialData } from 'constants/datamaps/palantir';
import { setTitle } from 'system/document';
import { t } from 'system/ui';
import Form from 'components/form';
import { connect } from 'react-redux';
import { uiActions } from 'actions';
import { confirmationDialog } from 'actions/ui';
import { getSearchSettingsRequest } from 'modules/search/ajax';
import { hasModule, isSuperiestUser } from 'modules/auth/user';
import AdminSettings from './settings.search.admin';
import SearchandisingSettings from './settings.search.searchandising';
import TypoSettings from './typo/settings.search.typo';
import SearchBoxSettings from './settings.search.searchbox';
import RedirectSettings from './redirect/RedirectSettings';

class SearchSettings extends React.Component {
  constructor(props) {
    super(props);
    this.searchBoxRef = null;
    this.synonymRef = null;
    this.typoRef = null;
    this.searchandisingRef = null;
    this.adminRef = null;
    this.state = {
      campaign: {},
      // searchablePages: [],
      selectedIndex: 0,
      selectedKey: '',
      saved: true,
      dataLoaded: false,
    };
    this.activeRed = false;
    this.redirectJs = '';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.formSaved !== prevProps.formSaved) {
      this.setState({
        saved: this.props.formSaved,
      });
    }
  }

  componentDidMount() {
    setTitle(t('Search & Discovery Settings'));
    uiActions.isLoading();
    uiActions.formSaved();
    uiActions.confirmOnLeave(this);
    const promises = [getSearchSettingsRequest('search/property').get()];
    Promise.all(promises)
      .then(results => {
        const searchProperty = results[0];
        const campaign = clone(searchSettingsCampaignInitialData);
        Object.keys(campaign).forEach(propKey => {
          if (
            searchProperty &&
            typeof searchProperty[propKey] !== 'undefined'
          ) {
            campaign[propKey] = searchProperty[propKey];
          }
        });
        this.setState({
          campaign,
          dataLoaded: true,
        });
        this.activeRed = campaign.redirectionEnabled;
        this.redirectJs = campaign.redirectionJs
          ? campaign.redirectionJs
          : '';
        uiActions.isLoaded();
      })
      .catch(err => this.showErrorMessage(err, false));
  }

  showErrorMessage(err, update) {
    let errorMessage = `Error occurred while ${
      update ? 'updating' : 'retrieving'
    } search settings`;
    err.response?.message && (errorMessage = err.response.message);
    const notificationContent = () => <span>{errorMessage}</span>;
    uiActions.isLoaded();
    uiActions.showErrorNotification(notificationContent);
  }

  showSuccessMessage() {
    const notificationContent = () => (
      <span>You have successfully updated search settings</span>
    );
    uiActions.showNotification({ content: notificationContent });
  }

  checkErrorExists() {
    const errors = document.querySelectorAll('.item-error');
    const synonymLangTabErrors = document.querySelectorAll('.has-error');
    return errors.length > 0 || synonymLangTabErrors.length > 0;
  }

  componentWillUnmount() {
    uiActions.resetPage();
  }

  updateRedirectState = redirectionEnabled => {
    this.activeRed = redirectionEnabled;
  };

  updateRedirectJs = redirectJs => {
    this.redirectJs = redirectJs;
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.checkErrorExists() || !this.state.dataLoaded) {
      return;
    }
    const searchandisingActive = hasModule('faceted');
    const searchActive = hasModule('search');
    const isSearchandisingTab =
      searchandisingActive && this.state.selectedKey === 'searchandising';
    const isRedirectTab =
      (searchActive || searchandisingActive) &&
      this.state.selectedKey === 'redirection';
    if (this.state.selectedIndex <= 2 || isSearchandisingTab) {
      let campaignOnTab = null;
      if (this.state.selectedIndex === 0) {
        campaignOnTab = this.searchBoxRef.getUpdatedCampaign();
      } else if (this.state.selectedIndex === 1) {
        campaignOnTab = this.synonymRef.getUpdatedCampaign();
      } else if (this.state.selectedIndex === 2) {
        campaignOnTab = this.typoRef.getUpdatedCampaign();
      } else if (isSearchandisingTab) {
        campaignOnTab = this.searchandisingRef.getUpdatedCampaign();
      }
      uiActions.isLoading();
      const updatedCampaign = {
        ...this.state.campaign,
        ...campaignOnTab,
      };

      const temporaryEmptyLangs = [];
      if (updatedCampaign.typoCorrection.typoReplaceMap) {
        Object.keys(updatedCampaign.typoCorrection.typoReplaceMap).map(
          language => {
            if (
              updatedCampaign.typoCorrection.typoReplaceMap[language].length ===
              0
            ) {
              temporaryEmptyLangs.push(language);
            }
          },
        );
        temporaryEmptyLangs.map(lang => {
          delete updatedCampaign.typoCorrection.typoReplaceMap[lang];
        });
      }

      getSearchSettingsRequest('search/property')
        .put(updatedCampaign, { type: 'json' })
        .then(response => {
          uiActions.formSaved();
          uiActions.isLoaded();
          this.showSuccessMessage();
          this.setState({
            campaign: updatedCampaign,
          });
        })
        .catch(err => this.showErrorMessage(err, true));
    } else if (isRedirectTab) {
      const updatedCampaign = {
        ...this.state.campaign,
        redirectionEnabled: this.activeRed,
        redirectionJs: this.redirectJs,
      };
      getSearchSettingsRequest('search/property')
        .put(updatedCampaign, { type: 'json' })
        .then(response => {
          uiActions.formSaved();
          uiActions.isLoaded();
          this.showSuccessMessage();
          this.setState({
            campaign: updatedCampaign,
          });
        });
    } else {
      const { config, version } = this.adminRef.getUpdatedCampaign();
      if (version) {
        uiActions.isLoading();
        getSearchSettingsRequest(
          'search/adminUpdateQueryList',
          null,
          `&schemaVersion=${version}`,
        )
          .put(config, { type: 'json' })
          .then(response => {
            uiActions.formSaved();
            uiActions.isLoaded();
            this.showSuccessMessage();
          })
          .catch(err => this.showErrorMessage(err, true));
      }
    }
  };

  onSelect = (index, last, event) => {
    const keyStringValue = event?.target?.textContent
      ?.toLowerCase()
      .replaceAll(' ', '_');
    if (this.props.formSaved) {
      this.setState({
        selectedIndex: index,
        selectedKey: keyStringValue,
      });
    } else {
      confirmationDialog({
        content: t('Your work is not saved! Are you sure want to continue?'),
        onConfirm: () => {
          uiActions.formSaved();
          this.setState({
            selectedIndex: index,
            selectedKey: keyStringValue,
          });
        },
      });
    }
  };

  render() {
    const { campaign, selectedIndex } = this.state;
    const { formSaved } = this.props;
    const isAdmin = isSuperiestUser();
    const searchandisingActive = hasModule('faceted');
    const searchActive = hasModule('search');
    return (
      <Tabs selectedIndex={selectedIndex} onSelect={this.onSelect}>
        <h2 className='page-title'>{t('Search & Discovery')}</h2>
        <div className='page-forms'>
          <div className='page-content-block' style={{ width: '70%' }}>
            <form
              id='currency-form'
              className='settings-search'
              onSubmit={this.onSubmit}
            >
              <TabList>
                <Tab>Search Box</Tab>
                <Tab>Synonym</Tab>
                <Tab>Typo</Tab>
                {searchandisingActive && <Tab>Searchandising</Tab>}
                {isAdmin && <Tab>Admin Configuration</Tab>}
                {(searchActive || searchandisingActive) && (
                  <Tab>Redirection</Tab>
                )}
              </TabList>
              <TabPanel>
                <SearchBoxSettings
                  ref={searchBoxRef => {
                    this.searchBoxRef = searchBoxRef;
                  }}
                  campaign={campaign}
                />
              </TabPanel>
              <TabPanel>
                <SynonymSettings
                  ref={synonymRef => {
                    this.synonymRef = synonymRef;
                  }}
                  campaign={campaign}
                />
              </TabPanel>
              <TabPanel>
                <TypoSettings
                  ref={typoRef => {
                    this.typoRef = typoRef;
                  }}
                  campaign={campaign}
                />
              </TabPanel>
              {searchandisingActive && (
                <TabPanel>
                  <SearchandisingSettings
                    ref={searchandisingRef => {
                      this.searchandisingRef = searchandisingRef;
                    }}
                    campaign={campaign}
                  />
                </TabPanel>
              )}
              {isAdmin && (
                <TabPanel>
                  <AdminSettings
                    ref={adminRef => {
                      this.adminRef = adminRef;
                    }}
                    formSaved={formSaved}
                  />
                </TabPanel>
              )}
              {(searchActive || searchandisingActive) && (
                <TabPanel>
                  <RedirectSettings
                    redirectionState={this.activeRed}
                    redirectionJs={this.redirectJs}
                    updateRedirectState={this.updateRedirectState}
                    updateRedirectJs={this.updateRedirectJs}
                  />
                </TabPanel>
              )}
              <div style={{ marginTop: '50px' }}>
                <Form
                  fields={[]}
                  buttons={[
                    {
                      type: 'tertiary',
                      label: 'Update',
                      submitAnimation: true,
                    },
                  ]}
                  ui='isLoaded'
                />
              </div>
            </form>
          </div>
        </div>
      </Tabs>
    );
  }
}

const MapStatesToProps = store => ({
  formSaved: store.ui.formSaved,
});

export default connect(MapStatesToProps)(SearchSettings);
