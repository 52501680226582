import React from 'react';

import { uiActions } from '../actions';
import { hasModule } from '../modules/auth/user';
import Icons from '../components/icons';
import { t } from '../system/ui';
import { btIntegrationInfo } from '../modules/trendify/page';
import { setTitle } from '../system/document';
import { config } from '../constants/config';

class BehaviouralTargeting extends React.Component {
  componentDidMount() {
    let children;
    if (!this.props.params.engagement) {
      children =
        config.menus.primary['behavioural-targeting'].children['view-all'];
    } else if (this.props.params.engagement === 'see-all') {
      children =
        config.menus.primary['behavioural-targeting'].children['see-all'];
    } else if (this.props.params.engagement === 'view-all') {
      children =
        config.menus.primary['behavioural-targeting'].children['view-all'];
    } else {
      const val = this.props.params.engagement.replace('-', '_');
      children = config.menus.primary['behavioural-targeting'].children[val];
    }
    setTitle(`${children.name}`);
    uiActions.resetPage();
    btIntegrationInfo();
    if (
      document.location.pathname === '/behavioural-targeting' &&
      btIntegrationInfo()
    ) {
      this.props.router.push({
        pathname: '/behavioural-targeting/engagement/view-all',
      });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    let children;
    if (newProps.params.engagement === 'see-all') {
      children =
        config.menus.primary['behavioural-targeting'].children['see-all'];
    } else if (newProps.params.engagement === 'view-all') {
      children =
        config.menus.primary['behavioural-targeting'].children['view-all'];
    } else {
      const val = newProps.params.engagement.replace('-', '_');
      children = config.menus.primary['behavioural-targeting'].children[val];
    }
    setTitle(`${children.name}`);
  }

  render() {
    const comp = !hasModule('personalization') ? (
      <div className='promo promo--centered bt-discover-dashboard'>
        <Icons
          name='behaviouralTargeting'
          color='#ffffff'
          width='64'
          height='64'
        />
        <p className='promo-text'>{t('BehaviouralTargetPromo')}</p>
        <a
          href='https://segmentify.com/solution/engagement/'
          target='_blank'
          className='secondary-action'
          rel='noreferrer'
        >
          {t('Discover')}
        </a>
      </div>
    ) : (
      this.props.children
    );
    return comp;
  }
}

export default BehaviouralTargeting;
