import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { t } from '../../system/ui';
import { dateActions, filterActions } from '../../actions';
import Icon from '../icon';
import Icons from '../icons';
import DateFilters from './date-filters';
import Navigation from '../navigation';
import AnalyticsPager from '../pager/analyticspager';
import { hasTrendify } from '../../modules/auth/user';
import { config } from '../../constants/config';
import { ExternalAppUtility } from '../../external';

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.dateFilterRef = null;
    this.resetFilters = this.resetFilters.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
  }

  resetFilters(e) {
    e.preventDefault();
    filterActions.resetAllFilters();
    // TODO: Resetting filters must not change the date.
    if (this.props.defaultDateYesterday) {
      dateActions.updateRangeAlias('daily');
    } else if (this.props.weekSelect) {
      /*
      TODO: honestly, we don't know why we need this.
      dateActions.updateRange([
        getNow()
          .subtract(1, 'weeks')
          .startOf('isoWeek'),
        getNow().startOf('isoweek'),
      ]);
       */
    } else if (this.props.isMonitor) {
      dateActions.updateRangeAlias('weeks');
    } else {
      // dateActions.resetRange();
    }
  }

  updateFilters(e) {
    e.preventDefault();

    if (this.props.disableButtons) {
      return false;
    }
    filterActions.updateFilters();
  }

  getButtons() {
    return (
      <div className='page-filters-buttons'>
        {this.props.visibleUpdate ? (
          <span>
            {this.props.isInsights && (
              <a
                className='cancel-action'
                style={{
                  backgroundColor: '#fff',
                  border: '1px solid #ece6e6',
                  color: '#827e7c',
                }}
                onClick={this.resetFilters}
              >
                {t('Reset Filters')}
              </a>
            )}
            <a
              className={classNames('tertiary-action', {
                disabled: this.props.disableButtons,
              })}
              onClick={this.updateFilters}
            >
              {t('Update')}
              <Icon name='refresh' />
            </a>
          </span>
        ) : (
          <span />
        )}
      </div>
    );
  }

  getTrendifyButtons() {
    return (
      <div className='page-filters-buttons-container'>
        <div className='page-filters-buttons'>
          {this.props.exportFile && (
            <a
              onClick={this.props.exportFile}
              className='page-filter-button page-filter-button--export'
            >
              {t('Export')} <Icon name='pdf' />
            </a>
          )}

          <a
            className={classNames('page-filter-button', {
              disabled: this.props.disableButtons,
            })}
            onClick={this.props.toggleAnalyticsFilters}
          >
            {t('Filters')}
            <Icons name='filter' width='16' height='16' color='#827e7c' />
          </a>
          <AnalyticsPager
            isBannerifyFilter={this.props.isBannerifyFilter}
            analyticsType={this.props.analyticsType}
          />
        </div>
      </div>
    );
  }

  forceFirstAndSecondToChange = () => {
    this.dateFilterRef?.forceFirstAndSecondToChange();
  };

  render() {
    const { hideDateAlias = [], limitRange = false } = this.props;
    const tfyLinks = config.menus.primary.trendify.children;
    const isExternal = ExternalAppUtility.isHeaderHidden();

    return (
      <div
        className={classNames({
          external: isExternal,
          'page-filters__insights': this.props.isInsights,
          'page-filters': true,
        })}
      >
        {hasTrendify() && (
          <div className='page-filters-buttons'>
            <div className='submenu--analytics'>
              <Navigation id='inner-nav' items={tfyLinks} />
            </div>
          </div>
        )}
        <DateFilters
          ref={dateFilterRef => (this.dateFilterRef = dateFilterRef)}
          hideDateAlias={hideDateAlias}
          limitRange={limitRange}
          showUpdateButton={!this.props.visibleUpdate}
          hideDateAliases={this.props.hideDateAliases}
          hidePicker={this.props.hidePicker}
          includedFields={this.props.includedFields}
          showYesterday={this.props.showYesterday}
          isArchive={this.props.isArchive}
          isGoogleAnalytics={this.props.isGoogleAnalytics}
          isSearchInsights={this.props.isSearchInsights}
          isMonitor={this.props.isMonitor}
          forceFirstSecondDate={this.props.forceFirstSecondDate}
        />
        {this.props.children}
        {this.props.analyticsFilters
          ? this.getTrendifyButtons()
          : this.getButtons()}
      </div>
    );
  }
}

const mapStatesToProps = (store, ownProps) => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  disableButtons: store.filters.disableButtons,
  forceFirstSecondDate: ownProps?.forceFirstSecondDate,
});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
  Filters,
);
