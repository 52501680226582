import { ExternalAppUtility } from '../external';
import { store } from '../store';
import sharedTranslations from '../translations';

export const confirm = options => {
  const defaults = {
    title: 'Attention!',
    content: 'This action will immediately effect on your website',
    onConfirm: () => {
      return false;
    },
    onCancel: () => {
      return false;
    },
  };

  const payload = { ...defaults, ...options };

  store.dispatch({
    type: 'OPEN_DIALOG',
    payload,
  });
};

export const notification = options => {
  const defaults = {
    title: '',
    content: '',
  };

  const payload = { ...defaults, ...options };

  store.dispatch({
    type: 'SHOW_NOTIFICATION',
    payload,
  });
};

export const openModal = options => {
  const defaults = {
    title: '',
    content: '',
  };

  const payload = { ...defaults, ...options };

  store.dispatch({
    type: 'OPEN_MODAL',
    payload,
  });
};

export const translate = (stringOrKey, variables, translations) => {
  let translatedString = null;
  const currentLang = store.getState().ui.language;
  const key =
    typeof stringOrKey !== 'undefined'
      ? stringOrKey.toString().trim()
      : stringOrKey;

  const isTranslateExternal = ExternalAppUtility.isExternalTranslate();
  // first check local translation e.g. component or view's itself
  if (translations) {
    if (isTranslateExternal) {
      translatedString = translations[`external_${currentLang}`][key] || null;
    } else {
      translatedString = translations[currentLang][key] || null;
    }
  }

  // then check system translations
  if (translatedString === null) {
    if (isTranslateExternal) {
      translatedString =
        sharedTranslations[`external_${currentLang}`][key] || null;
      // if still null then get non-external translations
      if (translatedString === null) {
        translatedString = sharedTranslations[currentLang][key] || null;
      }
    } else {
      translatedString = sharedTranslations[currentLang][key] || null;
    }
  }

  if (
    typeof translatedString !== 'function' &&
    typeof variables !== 'undefined'
  ) {
    translatedString = translatedString?.replace(/#(.*)#/g, variables);
  }

  // translation have html tags in
  if (typeof translatedString === 'function') {
    return translatedString.call(this, variables);
  }

  // usually the String itself will return if not matched translation key is found. So for single words you do not
  // need to add translations for English
  return translatedString !== null ? translatedString : stringOrKey;
};

export const t = (stringOrKey, variables, translations) => {
  return translate(stringOrKey, variables, translations);
};
