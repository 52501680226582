/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconMobile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 280 490"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            fill={this.state.color}
            className="svg-path"
            d="M336.86,5H163.13a53.46,53.46,0,0,0-53.45,53.45V441.55A53.46,53.46,0,0,0,163.13,495H336.86a53.46,53.46,0,0,0,53.46-53.45V58.45A53.46,53.46,0,0,0,336.86,5ZM250,470.85a27.07,27.07,0,1,1,27.08-27.07A27.07,27.07,0,0,1,250,470.85Zm91.35-79.08H158.65V67a12.2,12.2,0,0,1,12.18-12.17H329.18A12.2,12.2,0,0,1,341.35,67V391.77h0Z"
            transform="translate(-109.68 -5)"
          />
        </g>
      </svg>
    );
  }
}

export default IconMobile;
