import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.replace('px', '')}px`;
};

const LeftLongArrow = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 16 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9929 4.73249C12.9897 4.75166 12.9865 4.77076 12.986 4.79026C14.716 5.18167 16 6.56182 16 8.21889C16 10.1795 14.209 11.7691 12 11.7691H4.5C2.015 11.7691 0 9.98069 0 7.77511C0 6.0142 1.293 4.53642 3.075 4.00477C3.439 1.87109 5.495 0.230885 8 0.230885C10.761 0.230885 13 2.21812 13 4.66867C13 4.69045 12.9965 4.71152 12.9929 4.73249ZM8.99974 9.99397V7.3313H11.9997L7.99974 3.78107L3.99974 7.3313H6.99974V9.99397H8.99974Z'
        fill='#656565'
      />
    </svg>
  );
};

LeftLongArrow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

LeftLongArrow.defaultProps = {
  width: '20px',
  height: '16px',
};

export default LeftLongArrow;
