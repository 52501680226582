import React from 'react';
import './SFYComponents.scss';
import { TextField } from '../../../fields';
import { t } from '../../../../system/ui';

const TextFieldComp = ({
  title,
  value,
  valueSetter,
  isNumberText = false,
  valueType,
  isRequired = false,
  id,
}) => {
  const onChange = e => {
    if (isNumberText) {
      const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
        valueSetter(
          valueType,
          e.target.value === '' ? '' : parseInt(e.target.value),
        );
      }
    } else {
      valueSetter(valueType, e.target.value);
    }
  };
  return (
    <div className='form-element form-style'>
      <label
        htmlFor={id}
        className={`${isNumberText ? 'element-title' : 'item-label'}`}
      >
        {t(title)}
      </label>
      <TextField
        id={id}
        className='element-row-field'
        placeholder={t('Type Here...')}
        value={value}
        onChange={onChange}
        required={isRequired}
      />
    </div>
  );
};

export default TextFieldComp;
