import React from "react";
import { withRouter } from "react-router";
import { CardWrapper, Input } from "../common";
import Icons from "components/icons";
import Icon from "components/icon";
import RulesetRightSideWizard from "./RulesetRightSideWizard";
import RulesetPinWizard from "./RulesetPinWizard";
import { confirm, t } from "system/ui";
import { facetedSearchRequest } from "actions/faceted-search";
import { uiActions } from "actions";
import { FACETED_SEARCH_NAME } from "constants/faceted-search";
import ContentAdvancedConfig from "./ContentAdvancedConfig";
import { clone } from "system/object";
import { searchMultipleProducts } from "modules/product/ajax";
import BoostFieldModal from "./BoostFieldModal";
import Ordering from "../../common/Ordering";
import Banners from "../../common/Banners";

const orderingOperations = ["contains", "starts with", "exactly matches"];

class RulesetDefinitionPanel extends React.Component {
  constructor(props) {
    super(props);
    const { params } = this.props;
    const { mode } = params;
    const isInstant = this.isInstant();
    this.bannerRef = null;
    this.usedKeywords = [];
    this.usedOperations = [];
    this.state = {
      isInstant,
      advancedConfig: {
        show: false,
        index: null
      },
      productsForPins: [], // products to show product name on pin list.
      wizardOpen: false,
      boostFieldModalOpen: false,
      mode,
      saved: false, // uiActions.confirmOnLeave func checks this value.
      ruleSet: {
        condition: {
          type: "query",
          operation: orderingOperations[0]
        },
        ordering: {},
        override: {
          results: {
            pins: [],
            hides: []
          },
          customContents: []
        },
        boostFields: []
      },
      campaignOptions: [],
      banners: [],
      errors: false,
    };
  }

  isInstant = () => {
    const { location } = this.props;
    const { pathname } = location;
    return pathname.includes("instant-rule-set");
  };

  async componentDidMount() {
    uiActions.isLoading();
    uiActions.confirmOnLeave(this);
    const allRulesets = [];
    this.props.location?.state?.campaigns?.forEach(item => {
      if (item.ruleSets) {
        allRulesets.push(...item.ruleSets);
      }
    });
    allRulesets.forEach(item => {
      if (item.condition?.type === 'query') {
        this.usedKeywords.push(item.condition?.keyword.toLowerCase());
        this.usedOperations.push(item.condition?.operation);
      }
    });
    const { params } = this.props;
    const { mode, instanceId } = params;
    const promises = [];
    promises.push(this.getCampaignOptions());
    (mode === "edit") && promises.push(facetedSearchRequest("ruleset/getSingle", { instanceId }).get());
    try {
      const responses = await Promise.all(promises);
      let banners = [];
      if (mode === "edit") {
        banners = await facetedSearchRequest("searchbanner/get", null, `&instanceId=${responses[1].id}`).get();
      }
      this.setState(prevState => {
        const copyRuleset = clone(prevState.ruleSet);
        let campaignOptions = [];
        if (this.isInstant()) {
          if (responses[0]?.search?.status && responses[0].search.status !== "PASSIVE") {
            campaignOptions = [responses[0].search];
            (mode === "new") && (copyRuleset.ordering = this.copyOrdersInCampaign(responses[0].search));
          }
        } else {
          campaignOptions = responses[0].filter(campaign => campaign.status !== "PASSIVE");
        }
        return {
          campaignOptions,
          ruleSet: mode === "edit" ? responses[1] : copyRuleset,
          banners
        };
      });
    } catch (e) {
      const notificationContent = () =>
        <span>{t("rule-set-retrieve-failed")}</span>;
      uiActions.showErrorNotification(notificationContent);
    }
    uiActions.isLoaded();
    this.setBreadCrumb();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.setBreadCrumb();
    if (
      JSON.stringify(prevState.ruleSet?.override?.results?.pins) !==
      JSON.stringify(this.state.ruleSet?.override?.results?.pins) ||
      JSON.stringify(prevState.ruleSet?.override?.results?.hides) !==
      JSON.stringify(this.state.ruleSet?.override?.results?.hides)
    ) {
      let body = [];
      if (Array.isArray(this.state.ruleSet.override?.results?.pins)) {
        body = body.concat(this.state.ruleSet.override?.results?.pins?.map(pin => pin.elementId));
      }
      if (Array.isArray(this.state.ruleSet.override?.results?.hides)) {
        body = body.concat(this.state.ruleSet.override?.results?.hides);
      }
      searchMultipleProducts(body, productsForPins => {
        if (Array.isArray(productsForPins)) {
          this.setState({
            productsForPins
          });
        }
      });
    }
  }

  componentWillUnmount() {
    uiActions.resetPage();
  }

  getCampaignOptions = () => {
    const { isInstant } = this.state;
    return isInstant
      ? facetedSearchRequest("palantir/get").get()
      : facetedSearchRequest("facetedSearch/get").get();
  };

  setBreadCrumb = () => {
    const { isInstant } = this.state;
    uiActions.showBreadcrumb({
      home: {
        name: t("Search & Discovery"),
        icon: "search",
        slug: "/search"
      },
      before: {
        name: isInstant ? "Search Box" : FACETED_SEARCH_NAME,
        slug: isInstant ? "/search/searchbox" : "/search/faceted"
      },
      current: {
        name: `${this.state.mode === "new" ? `${t("New")} - ${t("rule-set")}` : `${t("Edit")} - ${this.state.ruleSet.name}`}`
      }
    });
  };

  wizardToggle = () => {
    this.setState(prevProps => {
      const errors = [...this.validatePinPositions(), ...this.validateCampaign()];
      if (errors.length !== 0) {
        const notificationContent = () => errors[0];
        uiActions.showErrorNotification(notificationContent);
      }
      return {
        wizardOpen: errors.length === 0 ? !prevProps.wizardOpen : prevProps.wizardOpen
      };
    });
  };

  boostFieldToggle = () => {
    this.setState(prevProps => {
      const errors = [
        ...this.validateBoostFields()
      ];
      if (errors.length !== 0) {
        const notificationContent = () => errors[0];
        uiActions.showErrorNotification(notificationContent);
      }
      return {
        boostFieldModalOpen:
          errors.length === 0
            ? !prevProps.boostFieldModalOpen
            : prevProps.boostFieldModalOpen
      };
    });
  };

  onNameChange = event => {
    event.persist();
    this.setState(prevState => {
      const ruleSetCopy = {
        ...prevState.ruleSet,
        name: event.target.value
      };
      return {
        ruleSet: ruleSetCopy
      };
    });
  };

  onCampaignChange = event => {
    event.persist();
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      const instanceId = event.target.value || null;
      ruleSetCopy.instanceId = instanceId;
      const relatedCampaign = prevState.campaignOptions?.find(campaign => campaign.instanceId === instanceId);
      ruleSetCopy.ordering = this.copyOrdersInCampaign(relatedCampaign);
      return {
        ruleSet: ruleSetCopy
      };
    });
  };

  /*
    *  This function returns campaign order by
    *  turning every order's default and enable -> false
   */
  copyOrdersInCampaign = campaign => {
    if (!campaign?.orders) {
      return {};
    }

    return clone(campaign.orders);
  };

  onKeywordChange = event => {
    event.persist();
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      ruleSetCopy.condition.keyword = event.target.value;
      return {
        ruleSet: ruleSetCopy
      };
    });
    if (
      this.isKeywordAndOperationUsed(
        event.target.value.toLowerCase(),
        'keyword',
      )
    ) {
      this.setState({
        errors: true,
      });
    } else {
      this.setState({
        errors: false,
      });
    }
  };

  onOperationChange = event => {
    event.persist();
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      ruleSetCopy.condition.operation = event.target.value;
      return {
        ruleSet: ruleSetCopy
      };
    });
    if (this.isKeywordAndOperationUsed(event.target.value, 'rule')) {
      this.setState({
        errors: true,
      });
    } else {
      this.setState({
        errors: false,
      });
    }
  };

  isKeywordAndOperationUsed = (kw, rule) => {
    let result = false;
    if (rule === 'keyword') {
      if (this.usedKeywords.includes(kw)) {
        this.usedKeywords.forEach((keyword, index) => {
          if (
            keyword === kw &&
            this.usedOperations[index] === this.state.ruleSet.condition.operation
          ) {
            result = true;
          }
        });
      }
    } else {
      const keyword = this.state.ruleSet.condition.keyword.toLowerCase();
      if (this.usedKeywords.includes(keyword)) {
        this.usedKeywords.forEach((kword, index) => {
          if (kword === keyword && this.usedOperations[index] === kw) {
            result = true;
          }
        });
      }
    }
    return result;
  };

  onBoostFieldsChange = boostFields => {
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      ruleSetCopy.boostFields = boostFields;
      return {
        ruleSet: ruleSetCopy
      };
    });
  };

  onPinsChange = (pins, hides) => {
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      ruleSetCopy.override.results.pins = pins;
      ruleSetCopy.override.results.hides = hides;
      return {
        wizardOpen: false,
        ruleSet: ruleSetCopy
      };
    });
  };

  onSinglePinPositionChange = (index, event) => {
    event.persist();
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      ruleSetCopy.override.results.pins[index].position = parseInt(event.target.value);
      return {
        ruleSet: ruleSetCopy
      };
    });
  };

  onSaveButtonClick = () => {
    const errors = this.validate();
    if (errors.length === 0) {
      confirm({
        title: t("rule-set-live-confirmation-title"),
        content: t("rule-set-delete-confirmation-content"),
        onConfirm: async () => {
          const instanceId = this.state.ruleSet.id;
          const endpoint =
            this.state.mode === "new"
              ? ["ruleset/add"]
              : ["ruleset/update", { instanceId }];
          const ajax = facetedSearchRequest(...endpoint);
          const ruleSetCopy = { ...this.state.ruleSet };
          ruleSetCopy.instanceId = ruleSetCopy.instanceId ?? "SEARCH";
          ruleSetCopy.searchType = this.state.isInstant ? "instant" : "faceted";
          ruleSetCopy.condition.keyword = ruleSetCopy.condition.keyword.trim();

          try {
            const ruleset =
              this.state.mode === "new"
                ? await ajax.post(ruleSetCopy, { type: "json" })
                : await ajax.put(ruleSetCopy, { type: "json" });

            const banners = await facetedSearchRequest("searchbanner/upsert", {
              instanceId: ruleset.id
            }).post(this.bannerRef.getBanners().map(banner => {
              return {
                ...banner,
                instanceId: ruleset.id,
                searchType: this.state.isInstant ? "instant" : "faceted"
              };
            }), { type: "json" });

            this.setState({
              ruleset,
              mode: "edit",
              banners
            });

            const notificationContent = () => (
              <span>{t("rule-set-save-successful")}</span>
            );
            uiActions.showNotification({ content: notificationContent });
          } catch (e) {
            const notificationContent = () => <span>{e.response}</span>;
            uiActions.showErrorNotification(notificationContent);
          }
        }
      });
    } else {
      const notificationContent = () => errors[0];
      uiActions.showErrorNotification(notificationContent);
    }
  };

  validate = () => {
    const errors = [];
    if (!this.state.ruleSet.name) {
      errors.push(t("rule-set-mandatory-error", t("rule-name")));
    }
    if (!this.state.ruleSet.condition.keyword) {
      errors.push(t("rule-set-mandatory-error", t("Search Keyword")));
    }
    return [
      ...errors,
      ...this.validateCampaign(),
      ...this.validatePinPositions(),
      ...this.validateCustomContentKeys(),
      ...this.validateBoostFields()
    ];
  };

  validateCampaign = () => {
    const { isInstant } = this.state;
    const errors = [];
    if (!isInstant && !this.state.ruleSet.instanceId) {
      errors.push(<span>{t("rule-set-campaign-mandatory")}</span>);
    }
    return errors;
  };

  validatePinPositions = () => {
    const errors = [];
    const pinPositions = new Map();
    const { pins } = this.state.ruleSet.override.results;
    for (let i = 0; i < pins.length; i++) {
      if (pinPositions.has(pins[i].position)) {
        errors.push(<span>{t("rule-set-position-collapse")}</span>);
        break;
      } else {
        pinPositions.set(pins[i].position, false);
      }
    }
    return errors;
  };

  validateCustomContentKeys = () => {
    const errors = [];
    const { customContents } = this.state.ruleSet.override;
    if (customContents) {
      for (let i = 0; i < customContents.length; i++) {
        if (!customContents[i].key) {
          errors.push(<span>{t("rule-set-custom-content-key-error")}</span>);
          break;
        }
      }
    }
    return errors;
  };

  validateBoostFields = () => {
    const errors = [];
    const { boostFields } = this.state.ruleSet;
    if (boostFields) {
      for (let i = 0; i < boostFields.length; i++) {
        if (!boostFields[i].productAccessor || !boostFields[i].value || isNaN(boostFields[i].weight)) {
          errors.push(<span>{t("rule-set-boost-field-error")}</span>);
          break;
        }
      }
    }
    return errors;
  };

  changeAdvancedConfigAppearance = (show, index) => {
    this.setState({
      advancedConfig: {
        show,
        index
      }
    });
  };

  addContent = () => {
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      !Array.isArray(ruleSetCopy.override.customContents) && (ruleSetCopy.override.customContents = []);
      ruleSetCopy.override.customContents = prevState.ruleSet.override.customContents.concat({
        key: null,
        html: null
      });
      return {
        ruleSet: ruleSetCopy
      };
    });
  };

  onCustomKeyChange = (index, event) => {
    event.persist();
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      ruleSetCopy.override.customContents[index].key = event.target.value;
      return {
        ruleSet: ruleSetCopy
      };
    });
  };

  onCustomHTMLChange = (index, html) => {
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      ruleSetCopy.override.customContents[index].html = html;
      return {
        ruleSet: ruleSetCopy
      };
    });
  };

  removeCustomContent = index => {
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      ruleSetCopy.override.customContents.splice(index, 1);
      return {
        ruleSet: ruleSetCopy
      };
    });
  };

  removePin = index => {
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      ruleSetCopy.override.results.pins.splice(index, 1);
      return {
        ruleSet: ruleSetCopy
      };
    });
  };

  removeHide = index => {
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      ruleSetCopy.override.results.hides.splice(index, 1);
      return {
        ruleSet: ruleSetCopy
      };
    });
  };

  getPinCard = () => {
    const { ruleSet, productsForPins } = this.state;
    return (
      <CardWrapper
        id="rule-set-result-customization"
        title={t("rule-set-result-customization-title")}
        description={t("rule-set-result-customization-description")}
        tooltipText={t("rule-set-result-customization-tooltip")}
        headerRightComponent={
          <button
            type="button"
            className="add-facet-button"
            onClick={this.wizardToggle}
          >
            <div className="add-facet-button-inner">
              <label>{t("pin-product")}</label>
            </div>
          </button>
        }
      >
        {ruleSet?.override?.results?.pins?.map((pin, index) => {
          let pinnedProductLabel = pin.elementId;
          const pinnedProduct = productsForPins.find(product => product.productId === pin.elementId);
          if (pinnedProduct) {
            pinnedProductLabel = pinnedProductLabel + " - " + pinnedProduct.name;
          }
          return (
            <div className="faceted-search-white-card-row-wrapper">
                <span className="draghandler">
                  <Icons name="burger" color="#e8e7f2" />
                </span>
              <span className="criteria-type">
                  <Icon name="product" />
                </span>
              <label htmlFor="-"
                     className="item item-field is-select criteria-name product-label">
                <span>{pinnedProductLabel}</span>
              </label>
              <Input
                inputProps={{
                  type: "number",
                  min: 1,
                  value: pin.position,
                  step: 1
                }}
                label="position"
                onChange={event => this.onSinglePinPositionChange(index, event)}
              />
              <span onClick={() => this.removePin(index)}>
                  <Icons width={24} height={24} name="crossCircle" />
                </span>
            </div>
          );
        })}
      </CardWrapper>
    );
  };

  getHidesCard = () => {
    const { ruleSet, productsForPins } = this.state;
    return (
      <CardWrapper
        id="rule-set-hide-card"
        title={t("rule-set-hides-title")}
        description={t("rule-set-hides-description")}
      >
        {ruleSet?.override?.results?.hides?.map((hide, index) => {
          let hiddenProductLabel = hide;
          const hiddenProduct = productsForPins.find(product => product.productId === hide);
          if (hiddenProduct) {
            hiddenProductLabel = hiddenProductLabel + " - " + hiddenProduct.name;
          }
          return (
            <div className="faceted-search-white-card-row-wrapper">
                <span className="draghandler">
                  <Icons name="burger" color="#e8e7f2" />
                </span>
              <span className="criteria-type">
                  <Icon name="product" />
                </span>
              <label htmlFor="-" className="item is-select product-label"
                     style={{ width: "100%", marginRight: "1em" }}>
                <span>{hiddenProductLabel}</span>
              </label>
              <span onClick={() => this.removeHide(index)}>
                  <Icons width={24} height={24} name="crossCircle" />
                </span>
            </div>
          );
        })}
      </CardWrapper>
    );
  };

  getBoostFields = () => {
    const { ruleSet } = this.state;
    return (
      <CardWrapper
        id="rule-set-boost-fields"
        title={t("rule-set-boost-fields")}
        description={t("rule-set-boost-fields-description")}
        headerRightComponent={
          <div className="wizard-comp-title-wrapper">
            <button
              type="button"
              className="add-facet-button"
              style={{ marginRight: "2px" }}
              onClick={this.wizardToggle}
            >
              <div className="add-facet-button-inner">
                <label>{t("Open Visual Builder")}</label>
              </div>
            </button>
            <button
              type="button"
              className="add-facet-button"
              onClick={this.boostFieldToggle}
            >
              <div className="add-facet-button-inner">
                <Icon name="plus" size="1x" />
                <label style={{ marginLeft: "3px" }}>{t("Add Boosting")}</label>
              </div>
            </button>
          </div>
        }
      >
        <>
          {ruleSet?.boostFields?.map((boostField, index) => {
            return (
              <div className="faceted-search-white-card-row-wrapper">
                <span className="boost-img" />
                <label
                  htmlFor="-"
                  className="item boost-item-product-accessor is-select criteria-name product-label"
                >
                  <span>{boostField.productAccessor}</span>
                </label>
                <Input
                  extraDivClasses="boost-item-value"
                  inputProps={{
                    type: "text",
                    placeholder: "Boost Query",
                    className: "text-field",
                    value: boostField.value,
                    style: { marginLeft: "10px" }
                  }}
                  label="Value"
                  onChange={e => {
                    this.onBoostFieldValueChange(e, index);
                  }}
                />
                <Input
                  extraDivClasses="boost-item-weight"
                  inputProps={{
                    type: "number",
                    min: 1,
                    max: 250,
                    value: parseInt(boostField.weight),
                    step: 1
                  }}
                  label="Weight"
                  onChange={e => {
                    this.onBoostFieldWeightChange(e, index);
                  }}
                />
                <span onClick={() => this.removeSelectedBoostField(index)}>
                  <Icons width={24} height={24} name="crossCircle" />
                </span>
              </div>
            );
          })}
        </>
      </CardWrapper>
    );
  };

  getBoostFieldModal = () => {
    const { boostFieldModalOpen } = this.state;
    const isInstant = this.isInstant();
    return (
      boostFieldModalOpen && (
        <BoostFieldModal
          ruleSet={this.state.ruleSet}
          boostFieldToggle={this.boostFieldToggle}
          addSelectedBoostFields={this.addSelectedBoostFields}
          type={isInstant ? "instant" : "faceted"}
          instanceId={
            this.state.isInstant
              ? "SEARCH"
              : this.state.campaignOptions[0].instanceId
          }
        />
      )
    );
  };

  addSelectedBoostFields = selectedBoostFieldList => {
    this.boostFieldToggle();
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      const boostFieldObjList = selectedBoostFieldList.map(field => {
        return {
          weight: 50,
          value: "",
          productAccessor: field
        };
      });
      ruleSetCopy.boostFields.push.apply(
        ruleSetCopy.boostFields,
        boostFieldObjList
      );
      return {
        wizardOpen: false,
        ruleSet: ruleSetCopy
      };
    });
  };

  removeSelectedBoostField = index => {
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      ruleSetCopy.boostFields.splice(index, 1);
      return {
        ruleSet: ruleSetCopy
      };
    });
  };

  onBoostFieldValueChange = (event, index) => {
    event.persist();
    this.setState(prevState => {
      const ruleSetCopy = { ...prevState.ruleSet };
      ruleSetCopy.boostFields[index].value = event.target.value;
      return {
        ruleSet: ruleSetCopy
      };
    });
  };

  onBoostFieldWeightChange = (event, index) => {
    event.persist();
    this.setState(prevState => {
      const ruleSetCopy = clone(prevState.ruleSet);
      let newValue = parseInt(event.target.value);
      if (newValue < 1) {
        newValue = 1;
      } else if (newValue > 250) {
        newValue = 250;
      }
      ruleSetCopy.boostFields[index].weight = newValue;
      return {
        ruleSet: ruleSetCopy
      };
    });
  };

  updateOrders = orders => {
    this.setState(prevState => {
      const ruleSet = clone(prevState.ruleSet);
      ruleSet.ordering = orders;
      return {
        ruleSet
      };
    });
  };

  getRuleSetDefaultOrder = () => {
    const { ruleSet } = this.state;
    const ordering = ruleSet.ordering ?? {};
    let defaultOrderKey = null;
    Object.keys(ordering).forEach(orderKey => {
      (ordering[orderKey].isDefault === true) && (defaultOrderKey = orderKey);
    });
    return {
      defaultOrderKey,
      order: {
        [defaultOrderKey]: ordering[defaultOrderKey]
      }
    };
  };

  render() {
    const {
      campaignOptions,
      ruleSet,
      advancedConfig,
      wizardOpen,
      isInstant,
      banners
    } = this.state;
    const ruleSetDefaultOrder = this.getRuleSetDefaultOrder();
    return (
      !wizardOpen ?
        <div className="rule-set-definition">
          <CardWrapper
            id="rule-set-query"
            title={t("rule-set-query-condition-title")}
            tooltipText={t("rule-set-query-condition-tooltip")}
            description={t("rule-set-query-condition-description")}
          >
            <div className="wizard-criterion faceted-search-result">
              <label htmlFor="-" className="item item-field is-select">
                <select
                  name="search-result"
                  id="rule-set-ordering-options"
                  className="criteria-field"
                  onChange={this.onOperationChange}
                  value={ruleSet?.condition?.operation}
                >
                  {orderingOperations.map(option => <option
                    value={option}>{t(option)}</option>)}
                </select>
              </label>
              <div className="item item-field rule-set-keyword">
                <input name="desktopPageSize" type="text"
                       className="one-whole criteria-field"
                       onChange={this.onKeywordChange}
                       value={ruleSet?.condition?.keyword ?? null} />
                {this.state.errors && (
                <span className='item-error'>
                  <span className='item-error'>
                    {t('This query already used!')}
                  </span>
                </span>
              )}
              </div>
            </div>
          </CardWrapper>
          <Ordering
            orders={ruleSet.ordering ?? {}}
            type={isInstant ? "instant" : "faceted"}
            removeOlParentClass={false}
            updateOrders={this.updateOrders}
            instanceId={ruleSet?.instanceId ?? null}
          />
          {this.getPinCard()}
          {this.getHidesCard()}
          {this.getBoostFields()}
          {this.getBoostFieldModal()}
          <CardWrapper
            id="rule-set-contents"
            title={t("rule-set-contents-title")}
            description={t(isInstant ? "rule-set-contents-description-searchbox" : "rule-set-contents-description")}
            tooltipText={t(isInstant ? "rule-set-contents-tooltip-searchbox" : "rule-set-contents-tooltip")}
            headerRightComponent={
              <button
                type="button"
                className="add-facet-button"
                onClick={() => this.addContent()}
              >
                <div className="add-facet-button-inner">
                  <label>{t("add-content")}</label>
                </div>
              </button>
            }
          >
            {ruleSet?.override?.customContents?.map((customContent, index) =>
              <div
                className="wizard-criterion faceted-search-result rule-set-custom-content-list-item">
                <div className="item item-field rule-set-keyword">
                  <input name="desktopPageSize" type="text"
                         className="one-whole criteria-field"
                         onChange={event => this.onCustomKeyChange(index, event)}
                         value={customContent.key ?? null} />
                </div>
                <button
                  onClick={() => this.changeAdvancedConfigAppearance(true, index)}>{t("Advanced Configuration")}</button>
                <div className="rule-set-custom-content-list-cross"
                     onClick={() => this.removeCustomContent(index)}>
                  <Icons name="crossCircle" />
                </div>
              </div>
            )}
          </CardWrapper>
          <Banners
            banners={banners}
            ref={bannerRef => {
              this.bannerRef = bannerRef;
            }}
            removeOlParentClass={false}
            isInstant={isInstant}
          />
          <RulesetRightSideWizard
            isInstant={isInstant}
            name={ruleSet?.name ?? null}
            campaign={ruleSet?.instanceId ?? null}
            onNameChange={this.onNameChange}
            campaignOptions={campaignOptions}
            onCampaignChange={this.onCampaignChange}
            save={this.onSaveButtonClick}
            hasError={this.state.errors}
          />
          <ContentAdvancedConfig
            showAdvancedConfig={advancedConfig.show}
            onHide={() => this.changeAdvancedConfigAppearance(false)}
            html={ruleSet?.override?.customContents?.[advancedConfig.index ?? 0]?.html ?? ""}
            onChange={html => this.onCustomHTMLChange(advancedConfig.index, html)}
          />
        </div> : <RulesetPinWizard
          isInstant={isInstant}
          campaign={ruleSet?.instanceId}
          ruleSetDefaultOrder={ruleSetDefaultOrder}
          searchQuery={ruleSet?.condition?.keyword ?? null}
          discard={this.wizardToggle}
          save={this.onPinsChange}
          pins={ruleSet?.override?.results?.pins ?? []}
          hides={ruleSet?.override?.results?.hides ?? []}
          boostFields={ruleSet?.boostFields ?? []}
        />
    );
  }
}

export default withRouter(RulesetDefinitionPanel);
