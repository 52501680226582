import React from 'react';
import classNames from 'classnames';
import { t } from 'system/ui';
import Snippet from 'components/snippet';
import PropTypes from 'prop-types';

export default class ContentAdvancedConfig extends React.Component {
  constructor(props) {
    super(props);
    this.snippetRef = null;
    this.state = {
      snippet: this.props.html,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.showAdvancedConfig !== prevProps.showAdvancedConfig) {
      this.setState({
        snippet: this.props.html,
      }, () => this.snippetRef.refresh());
    }
  }

  checkDefault = snippet => {
    this.setState({
        snippet,
      },
      () => this.props.onChange(this.state.snippet),
    );
  };

  render() {
    const { onHide, showAdvancedConfig } = this.props;
    return (
      <div
        className={classNames('wizard-advanced-config rule-set-custom-content', {
          'is-hidden': !showAdvancedConfig,
        })}
      >
        <div className='code-samples'>
          <nav className='code-sample-tabs'>
            <a className='code-sample-tab-nav current'>{t('Edit HTML')}</a>
          </nav>
          <div className='code-sample-tab'>
            <Snippet
              ref={ref => this.snippetRef = ref}
              value={this.state.snippet}
              language='htmlmixed'
              checkDefault={this.checkDefault}
            />
          </div>
        </div>

        <a
          className='wizard-advanced-config-save tertiary-action'
          onClick={onHide}
        >
          {t('Back To Wizard')}
        </a>
      </div>
    );
  }
}

ContentAdvancedConfig.propTypes = {
  showAdvancedConfig: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  html: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
