/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import Tooltip from "../../components/tooltip";
import Icons from "../../components/icons";

import * as classes from "../../constants/ui.classes";

import { validPassword, validEmail } from "../../system/string";

import { t } from "../../system/ui";

class TextArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      showRequiredError: false,
      showEqualToError: false,
      showMinLengthError: false,
      showPasswordError: false,
      showEmailError: false
    };

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
    this.getFieldLabel = this.getFieldLabel.bind(this);
  }

  componentDidMount() {
    this.validate();
  }

  componentWillReceiveProps(newProps) {
    setTimeout(() => {
      this.validate();
    }, 250);
  }

  onBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    this.validate();
  }

  onChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }

    this.validate();
  }

  validate() {
    let input = this.refs.input,
      hasAnyError = false;

    const style = getComputedStyle(input);

    const opacity = style.opacity;

    if (this.props.required) {
      if (input.value === "") {
        hasAnyError = true;

        this.setState({
          showRequiredError: true
        });
      } else {
        hasAnyError = false;

        this.setState({
          showRequiredError: false
        });
      }
    }

    if (this.props.validation) {
      if (this.props.validation.equalTo) {
        if (
          input.value !== "" &&
          input.value !==
            document.querySelector(
              "[name=" + this.props.validation.equalTo + "]"
            ).value
        ) {
          hasAnyError = true;

          this.setState({
            showEqualToError: true
          });
        } else {
          hasAnyError = false;

          this.setState({
            showEqualToError: false
          });
        }
      }

      if (this.props.validation.minLength) {
        if (
          input.value !== "" &&
          input.value.length < this.props.validation.minLength
        ) {
          hasAnyError = true;

          this.setState({
            showMinLengthError: true
          });
        } else {
          hasAnyError = false;

          this.setState({
            showMinLengthError: false
          });
        }
      }

      if (this.props.validation.password) {
        if (input.value !== "") {
          hasAnyError = true;

          this.setState({
            hasError: true,
            showPasswordError: true
          });
        } else {
          hasAnyError = false;

          this.setState({
            showPasswordError: false
          });
        }
      }

      if (this.props.validation.email) {
        if (input.value !== "") {
          hasAnyError = true;

          this.setState({
            hasError: true,
            showEmailError: true
          });
        } else {
          hasAnyError = false;

          this.setState({
            showEmailError: false
          });
        }
      }
    }

    this.setState({
      hasError: hasAnyError
    });
  }

  getFieldLabel(fieldName) {
    let field = document.querySelector("[name=" + fieldName + "]");
    return field.parentNode.querySelector(".item-label").innerHTML;
  }

  render() {
    let showError = !this.props.disabled;
    return (
      <label
        className={classNames(classes.fieldItem, this.props.className, {
          "has-error": this.props.disabled ? false : this.state.hasError,
          "disabled-field": this.props.disabled
        })}
      >
        {this.props.label ? (
          <span className={classes.fieldLabel}>
            {t(this.props.label)}
            {this.props.tooltip && (
              <span style={{ position: "absolute", top: "-3px" }}>
                {this.props.tooltipLink ? (
                  <Tooltip content={this.props.tooltipText} alignment="left">
                    <a href={this.props.tooltipLink} target="_blank">
                      <i className="icon-info" role="presentation">
                        <span className="for-screenreader-only" />
                      </i>
                    </a>
                  </Tooltip>
                ) : (
                  <Tooltip content={this.props.tooltipText} alignment="left">
                    <i className="icon-info" role="presentation">
                      <span className="for-screenreader-only" />
                    </i>
                  </Tooltip>
                )}

                {this.props.secondTooltip && (
                  <span
                    style={{
                      position: "absolute",
                      left: "20px"
                    }}
                  >
                    <Tooltip
                      content={this.props.secondTooltip}
                      alignment={
                        this.props.tooltipAlignment
                          ? this.props.tooltipAlignment
                          : "left"
                      }
                      placement={this.props.tooltipPlacement}
                    >
                      <i className="icon-info" role="presentation">
                        <span className="for-screenreader-only" />
                      </i>
                    </Tooltip>
                  </span>
                )}
              </span>
            )}
          </span>
        ) : (
          ""
        )}

        <textarea
          name={this.props.name}
          id={this.props.id}
          className={classNames(classes.textField, this.props.inputClassName)}
          defaultValue={this.props.defaultValue}
          value={this.props.value}
          readOnly={this.props.readOnly}
          required={this.props.disabled ? false : this.props.required}
          onBlur={this.onBlur}
          onChange={this.onChange}
          style={this.props.style}
          placeholder={t(this.props.placeholder)}
          ref="input"
        />

        {this.props.icon && (
          <span
            className="field-icon"
            onClick={this.props.iconAction ? this.props.iconAction : null}
          >
            <Icons name={this.props.icon} />
          </span>
        )}
        {this.props.renderProp && this.props.renderProp()}

        {this.state.showRequiredError && showError ? (
          <span className="item-error">{t("This field is required")}</span>
        ) : (
          ""
        )}

        {this.state.showEqualToError ? (
          <span className="item-error">
            {t(
              "This field should equal to #fieldName#",
              this.getFieldLabel(this.props.validation.equalTo)
            )}
          </span>
        ) : (
          ""
        )}

        {this.state.showMinLengthError ? (
          <span className="item-error">
            {t(
              "This field should have at least #minLength# characters",
              this.props.validation.minLength
            )}
          </span>
        ) : (
          ""
        )}

        {this.state.showPasswordError ? (
          <span className="item-error">{t("PasswordField")}</span>
        ) : (
          ""
        )}

        {this.state.showEmailError ? (
          <span className="item-error">{t("EmailField")}</span>
        ) : (
          ""
        )}
      </label>
    );
  }
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

const mapStatesToProps = store => ({
  validate: store.ui.validate
});

export default connect(mapStatesToProps)(TextArea);
