/* eslint-disable no-underscore-dangle */
import { uiActions } from '../actions';
import { getExternalAccount, getSource } from '../index';
import { redirectAuth } from '../modules/auth/require-auth';
import {
  getAccount,
  getRegion,
  getToken,
  getSwitchAccountUser,
  isSuperUser,
  isSwitchAccount,
  isMultiAccountUser,
} from '../modules/auth/user';
import { tryParse } from './string';

const activeRequests = {
  GET: {},
  POST: {},
  PUT: {},
  DELETE: {},
};

export function createHeaders(options = {}, method = 'GET') {
  const headers = [];

  let contentType = 'application/x-www-form-urlencoded; charset:utf-8';

  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    if (options.type === 'json') {
      contentType = 'application/json; charset:utf-8';
    }
    if (options.type === 'application/octet-stream') {
      contentType = 'application/json; charset:utf-8';
    }
  }

  if (options.type !== 'form') {
    headers.push({ 'Content-Type': contentType });
  }

  if (typeof options.auth === 'undefined' || options.auth === true) {
    const tokenToSet = options.token || getToken();
    headers.push({ Authorization: `Bearer ${tokenToSet}` });
  }

  if (isSuperUser() && isSwitchAccount() && !isMultiAccountUser()) {
    const isExists = headers.find(h => h['X-Switch-User'] !== undefined);
    if (!isExists) {
      headers.push({
        'X-Switch-User': getSwitchAccountUser().username,
      });
    }
  }

  let noSwitchHeader = true;

  if (
    getSource() !== null &&
    getSource() === 'external_app' &&
    noSwitchHeader
  ) {
    if (getExternalAccount() !== null && getExternalAccount().length > 0) {
      headers.push({ 'X-Switch-Account': getExternalAccount().trim() });
    }
    noSwitchHeader = false;
  }

  if (isMultiAccountUser() && isSwitchAccount() && noSwitchHeader) {
    headers.push({
      'X-Switch-Account': getAccount().accountId.trim(),
    });
    noSwitchHeader = false;
  }

  if (noSwitchHeader && options && options.multiUserAccountId) {
    headers.push({ 'X-Switch-Account': options.multiUserAccountId.trim() });
  }

  if (options && options.switchUser) {
    const isExists = headers.find(h => h['X-Switch-User'] !== undefined);
    if (!isExists) {
      headers.push({ 'X-Switch-User': options.switchUser });
    }
  }

  if (getRegion() && !options.switchRegion) {
    const region = getRegion().trim();
    headers.push({ 'X-Switch-Region': region });
  }

  return headers;
}

export function $http(url, defaultOptions = null) {
  const ajax = (method, ajaxUrl, data, options = defaultOptions) => {
    const _options = options || {};
    let uri = ajaxUrl;

    if (typeof _options.auth === 'undefined') {
      _options.auth = true;
    }

    if (data && method === 'GET') {
      if (uri.indexOf('?') >= 0) {
        uri += '&';
      } else {
        uri += '?';
      }
      let argCount = 0;

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (argCount++) {
            uri += '&';
          }
          uri += `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
        }
      }
    }

    if (
      typeof activeRequests[method][uri] !== 'undefined' &&
      activeRequests[method][uri].preventAbort === false
    ) {
      activeRequests[method][uri].request.abort();
    }

    const promise = new Promise(function(resolve, reject) {
      const request = new XMLHttpRequest();
      let postData = '';

      if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
        postData = data;

        if (_options.type === 'json') {
          postData = JSON.stringify(data);
        }
        if (_options.type === 'application/octet-stream') {
          request.responseType = 'blob';
          postData = JSON.stringify(data);
        }
      }

      activeRequests[method][uri] = {
        request,
        data,
        preventAbort: _options.preventAbort === false,
      };

      request.open(method, uri);

      const headers = createHeaders(_options, method);

      headers.forEach(obj => {
        Object.keys(obj).forEach(key => {
          const value = obj[key];
          request.setRequestHeader(key, value);
        });
      });

      request.onload = function() {
        delete activeRequests[method][ajaxUrl];
        if (this.status >= 200 && this.status < 400) {
          let { response } = this;
          if (ajaxUrl.includes('/insights/export'))
            resolve({
              response,
              request,
            });

          if (isJSONResponse(response)) {
            response = JSON.parse(response);
          }

          resolve(response);
        } else if (this.status === 419) {
          redirectAuth(1); // mode1
        } else {
          let currentStatusText;
          if (this.status === 500) {
            currentStatusText = 'Something went wrong';
          }
          if (
            this.response ===
            "Can't update user information, new password is same with current password"
          ) {
            currentStatusText =
              "Can't update user information, new password is same with current password";
          }
          const parsedTxt = tryParse(this.response);
          if (!_options.customErrMsg) {
            uiActions.notifyRequestError({
              status: this.status,
              statusText:
                currentStatusText ||
                (parsedTxt && parsedTxt.message) ||
                this.response ||
                'Something went wrong',
              response: tryParse(this.response),
              originalResponse: this,
            });
          }
          reject({
            status: this.status,
            statusText: this.statusText,
            response: tryParse(this.response),
            originalResponse: this,
          });
        }
      };

      request.onerror = function() {
        delete activeRequests[method][ajaxUrl];

        uiActions.notifyRequestError({
          status: this.status,
          statusText: this.statusText || 'Something went wrong',
          response: tryParse(this.response),
          originalResponse: this,
        });

        reject({
          status: this.status,
          statusText: this.statusText,
          response: tryParse(this.response),
          originalResponse: this,
        });
      };
      request.send(postData);
    });

    return promise;
  };

  let isJSONResponse = function(jsonString) {
    try {
      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {
      return false;
    }

    return false;
  };

  return {
    get(data, options) {
      return ajax('GET', url, data, options);
    },
    post(data, options) {
      return ajax('POST', url, data, options);
    },
    put(data, options) {
      return ajax('PUT', url, data, options);
    },
    delete(data, options) {
      return ajax('DELETE', url, data, options);
    },
  };
}

export const plainGet = (url, callback, data, options) => {
  return $http(url)
    .get(data, options)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(response => {
      if (callback) {
        callback(response);
      }
    });
};
