import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  getAccount,
  hasModule,
  isCategoryHierarchy,
  isSuperUser,
  getAdditionalLanguages,
} from '../modules/auth/user';

import { pageTypes } from '../constants/datamaps/wizard';

import { t, confirm } from '../system/ui';
import { setTitle } from '../system/document';

import { uiActions, wizardActions } from '../actions';

import { config } from '../constants/config';

import { getCampaign } from '../modules/campaigns/ajax';
import { goTestOrLive, singleCampaign } from '../modules/campaigns/wizard';
import { getCampaignPage } from '../modules/campaigns/data';

import { TextField } from '../components/fields';
import WizardItems from '../components/wizard/items';
import WizardTarget from '../components/wizard/target';
import WizardPageType from '../components/wizard/page-type';
import WizardDateSelection from '../components/wizard/date-selection';
import WizardAdvancedConfig from '../components/wizard/advanced-config';
import WizardMediumSelection from '../components/wizard/medium-selection';
import WizardPrioritySelection from '../components/wizard/priority-selection';
import getLanguageName from '../modules/transform/languageTransform';
import Fields from '../modules/campaign/fields';
import { popupRecoTransform } from '../components/wizard/utils';
import Dropdown from '../components/sfy-components/Dropdown/Dropdown';
import { RecommendationTemplateService } from './recommendation/templates/services/recommendationTemplateServices';

let lastType = '';
const popupRecoFields = {
  buttons: [
    { field: 'positionButton', ignoreRef: true },
    { field: 'timingButton', ignoreRef: true },
    { field: 'audienceButton', ignoreRef: true },
    { field: 'overlay' },
  ],
  fields: [
    { field: 'positionOptions' },
    { field: 'timingOptions' },
    { field: 'audienceOptions' },
  ],
};

const recoFields = {
  fields: [{ field: 'audienceOptions' }],
};

class EditWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: {},
      title: '',
      additionalTitles: {
        AR: '',
        AZ: '',
        DE: '',
        EN: '',
        ES: '',
        FR: '',
        IT: '',
        NL: '',
        PL: '',
        PT: '',
        RO: '',
        TR: '',
        RU: '',
      },
      showAdditionalLanguages: false,
      scenarioName: '',
      eventName: '',
      selectedType: '',
      showAdvancedConfig: false,
      saved: true,
      campaignStatus: '',
      isSubmitting: false,
      submittingButton: '',
      levelUp: 'false',
      AlgoString: '',
      devices: '',
      widgetTemplateId: null,
      widgetTemplates: [],
      useWidgetTemplate: null,
    };

    this.onTitleChange = this.onTitleChange.bind(this);
    this.onAdditionalTitleChange = this.onAdditionalTitleChange.bind(this);
    this.onScenarioNameChange = this.onScenarioNameChange.bind(this);
    this.onEventNameChange = this.onEventNameChange.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
    this.toggleLanguages = this.toggleLanguages.bind(this);

    this.showAdvancedConfig = this.showAdvancedConfig.bind(this);
    this.hideAdvancedConfig = this.hideAdvancedConfig.bind(this);

    this.campaignDetails = this.campaignDetails.bind(this);

    this.goTest = this.goTest.bind(this);
    this.goLive = this.goLive.bind(this);
    this.editWidgetTemplate = this.editWidgetTemplate.bind(this);
    this.toggleLevelUp = this.toggleLevelUp.bind(this);
    this.validate = this.validate.bind(this);
    this.getEngagementValues = this.getEngagementValues.bind(this);
    this.updateAlgorithmString = this.updateAlgorithmString.bind(this);
    this.toggleUseTemplate = this.toggleUseTemplate.bind(this);
    this.getAllTemplates = this.getAllTemplates.bind(this);
  }

  componentDidMount() {
    setTitle(
      t(
        `Edit ${
          config.menus.primary.recommendations.children[
            this.props.routeParams.pagetype === '404'
              ? 'notfound'
              : this.props.routeParams.pagetype
          ].name
        } Page Campaign`,
      ),
    );

    uiActions.isValidated();
    uiActions.removePageClass();

    uiActions.confirmOnLeave(this);

    this.campaignDetails();
    this.getAllTemplates();
    wizardActions.saveReset();
    wizardActions.wizardReset();
    wizardActions.wizardEdit();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      this.campaignDetails();
    }

    if (this.state.saved !== newProps.formSaved) {
      this.setState({
        saved: newProps.formSaved,
      });
    }

    if (newProps.formSaved === true) {
      this.setState({
        isSubmitting: false,
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.validate === true && oldProps.validate === false) {
      this.validate(lastType);
    }
  }

  componentWillUnmount() {
    wizardActions.saveReset();
    wizardActions.wizardEdit();
    wizardActions.wizardReset();
  }

  getAllTemplates() {
    RecommendationTemplateService()
      .getAll()
      .then(res => {
        const options = res.map(item => {
          return { label: item?.templateName, value: item?.id };
        });
        this.setState({
          widgetTemplates: options,
        });
      });
  }

  campaignDetails() {
    getCampaign(this.props.params.widgetId, response => {
      singleCampaign(response);

      const value = getCampaignPage(response)
        .replace(' Page', '')
        .toLowerCase();
      let pageType = pageTypes.indexOf(value) > -1 ? value : 'custom';

      if (pageType === '404') {
        pageType = 'notfound';
      }

      if (response.templateId === 'SMSG_01') {
        pageType = 'triggerbased';
      }

      if (response.templateId === 'T21') {
        pageType = 'checkout-success';
      }

      if (
        response &&
        response.actions &&
        response.actions[0] &&
        response.actions[0].params &&
        response.actions[0].params.actionType === 'POPUP_RECO'
      ) {
        pageType = 'popup_recommendation';
      }

      const getEventName = response => {
        const evtName =
          response.inputs.length && response.inputs[0].value
            ? response.inputs[0].value[0]
            : '';

        if (
          response.actions.length > 0 &&
          response.actions[0].params.actionType === 'POPUP_RECO'
        ) {
          return 'Popup Recommendation';
        }
        if (pageType === 'custom') {
          return 'Custom Page';
        }

        return evtName;
      };
      if (response?.widgetTemplateId) {
        this.setState({
          useWidgetTemplate: true,
        });
        this.getAllTemplates();
      } else {
        this.setState({
          useWidgetTemplate: false,
        });
      }

      this.setState(
        {
          campaign: response,
          scenarioName: response.scenarioName,
          title: response.actions[0].params.notificationTitle,
          additionalTitles: response.actions[0].params.notificationTitleMap
            ? JSON.parse(response.actions[0].params.notificationTitleMap)
            : {},
          eventName: getEventName(response),
          selectedType: { page: pageType },
          levelUp:
            response.actions[0].params.levelUp !== undefined
              ? response.actions[0].params.levelUp
              : 'false',
          saved: true,
          campaignStatus: response.testMode ? 'Test' : 'Live',
          devices: response.device,
          widgetTemplateId: response?.widgetTemplateId
            ? response?.widgetTemplateId
            : null,
        },
        () => {
          uiActions.showBreadcrumb({
            home: {
              name: t('Recommend'),
              icon: 'heart',
              slug: '/recommendations',
            },
            top: {
              name:
                pageType === 'popup_recommendation'
                  ? t('Popup Recommendation')
                  : t(
                      `${
                        config.menus.primary.recommendations.children[
                          this.state.selectedType.page === '404'
                            ? 'notfound'
                            : this.state.selectedType.page
                        ].name
                      } Page Widgets`,
                    ),
              slug: `/recommendations/${this.state.selectedType.page}`,
            },
            current: {
              name: this.state.scenarioName,
            },
          });
        },
      );
    });
  }

  getEngagementValues() {
    if (this.refs.contentExtra) {
      if (this.state.selectedType.page === 'popup_recommendation') {
        const positionOptions = this.refs.contentExtra.refs.positionOptions
          .state;
        const timingOptions = this.refs.contentExtra.refs.timingOptions.state;
        const overlay = this.refs.contentButtons.refs.overlay.state;
        const audienceOptions = this.refs.contentExtra.refs.audienceOptions
          .state;
        const audience = [];
        audienceOptions.values.segment.forEach(option => {
          audience.push({
            type: 'SEGMENT',
            include: option.member,
            param: option.value,
          });
        });
        audienceOptions.values.visitor.forEach(option => {
          audience.push({
            type: 'VISITOR',
            include: option.member,
            param: option.value,
          });
        });
        return {
          overlay: overlay.value,
          verticalPosition: positionOptions.values.verticalPosition,
          horizontalPosition: positionOptions.values.horizontalPosition,
          delayAction: {
            type: popupRecoTransform(
              timingOptions.values.timing.type,
              timingOptions.delayAllPages,
            ),
            time: timingOptions.values.timing.param || '',
          },
          constraint: {
            type: popupRecoTransform(timingOptions.values.frequency.type),
            times: timingOptions.values.frequency.param || '',
          },
          filters: audience,
        };
      }
      const audienceOptions = this.refs.contentExtra.refs.audienceOptions.state;
      const audience = [];
      audienceOptions.values.segment.forEach(option => {
        audience.push({
          type: 'SEGMENT',
          include: option.member,
          param: option.value,
        });
      });
      audienceOptions.values.visitor.forEach(option => {
        audience.push({
          type: 'VISITOR',
          include: option.member,
          param: option.value,
        });
      });

      return { filters: audience };
    }
    return false;
  }

  toggleUseTemplate() {
    const isUseTemplate = this.state.useWidgetTemplate;
    this.setState({
      useWidgetTemplate: !isUseTemplate,
      widgetTemplateId: null,
    });
  }

  toggleLevelUp() {
    if (this.state.levelUp === 'true') {
      this.setState({
        levelUp: 'false',
      });
    } else if (this.state.levelUp === 'false') {
      this.setState({
        levelUp: 'true',
      });
    }
  }

  goTest() {
    this.setState(
      {
        isSubmitting: true,
        submittingButton: 'test',
        campaignStatus: 'Test',
      },
      () => {
        goTestOrLive(
          'test',
          this,
          this.state.selectedType.page,
          false,
          false,
          this.getEngagementValues(),
        );
        uiActions.formSaved();
      },
    );
  }

  goLive() {
    this.setState(
      {
        isSubmitting: true,
        submittingButton: 'live',
        campaignStatus: 'Live',
      },
      () => {
        goTestOrLive(
          'live',
          this,
          this.state.selectedType.page,
          false,
          false,
          this.getEngagementValues(),
        );
        uiActions.formSaved();
      },
    );
  }

  editWidgetTemplate(id) {
    this.props.router.push(`/recommendations/templates/edit/${id}`);
  }

  checkUseTemplate() {
    return !(
      this.state.useWidgetTemplate && this.state.widgetTemplateId === null
    );
  }

  validate(type) {
    const checkUseTemplate = this.checkUseTemplate();
    const errors = document.querySelectorAll('.has-error');

    lastType = type;

    if (checkUseTemplate) {
      if (this.props.validate === true && errors.length === 0) {
        if (type === 'test') {
          confirm({
            title: 'Update Campaign on Test Mode?',
            onConfirm: this.goTest,
            onCancel: () => {
              this.setState({
                isSubmitting: false,
              });
            },
          });
        }

        if (type === 'live') {
          confirm({
            title: 'Update Campaign on Live?',
            onConfirm: this.goLive,
            onCancel: () => {
              this.setState({
                isSubmitting: false,
              });
            },
          });
        }
      }
      if (type === 'edit') {
        this.editWidgetTemplate(this.state.widgetTemplateId);
      } else {
        uiActions.isValidating();
      }
    } else {
      uiActions.showNotification({
        // eslint-disable-next-line react/no-unescaped-entities
        content: () => <p>'Please select a template'</p>,
        className: 'notification-fail',
      });
    }
  }

  // eslint-disable-next-line react/sort-comp
  onTitleChange(e) {
    const account = getAccount();
    const { mainLanguage } = account;
    const additionalTitles = { ...this.state.additionalTitles };
    additionalTitles[mainLanguage] = e.target.value;
    this.setState({
      title: e.currentTarget.value,
      additionalTitles,
      saved: false,
    });

    uiActions.formEdited();
  }

  updateAlgorithmString(AlgoString) {
    this.setState({
      AlgoString,
    });
  }

  onScenarioNameChange(e) {
    this.setState({
      scenarioName: e.currentTarget.value,
      saved: false,
    });

    uiActions.formEdited();
  }

  onDropdownChange(e) {
    this.setState({
      widgetTemplateId: e,
    });
  }

  onEventNameChange(e) {
    this.setState({
      eventName: e.currentTarget.value,
      saved: false,
    });
    uiActions.formEdited();
  }

  showAdvancedConfig() {
    const devices = this.refs.wizardMediumSelection.state.mediums.join('');
    this.setState({
      showAdvancedConfig: true,
      devices,
    });
    uiActions.formEdited();
  }

  hideAdvancedConfig() {
    this.setState({
      showAdvancedConfig: false,
    });
  }

  onAdditionalTitleChange(e) {
    const additionalTitles = { ...this.state.additionalTitles };
    additionalTitles[e.target.name] = e.target.value;
    this.setState({
      additionalTitles,
    });
  }

  toggleLanguages() {
    this.setState({
      showAdditionalLanguages: !this.state.showAdditionalLanguages,
    });
  }

  render() {
    const isHierarchical = isCategoryHierarchy();
    const selectedIntelligents =
      this.state.AlgoString && this.state.AlgoString.length > 0
        ? this.state.AlgoString.filter(item => item.type === 'intelligent')
        : [];
    const selectedAlgorithms =
      selectedIntelligents.length > 0
        ? selectedIntelligents.map(item => {
            return item.values.criterion.value;
          })
        : [];
    const selectedTimeFrames =
      selectedIntelligents.length > 0
        ? selectedIntelligents.map(item => {
            return item.values.timeFrame.value;
          })
        : [];
    const showLevelUp =
      isSuperUser() &&
      isHierarchical &&
      this.state.selectedType.page === 'category' &&
      selectedAlgorithms.indexOf('RECOMMENDATION_CATEGORY_ROCKS') >= 0;

    return (
      <form id='wizard' ref='wizard' className='wizard wizard-cards'>
        {this.state.campaign.testMode !== '' ? (
          <h3 className='widget page-title'>
            <span
              className={`status-label status-label-${this.state.campaignStatus.toLowerCase()}`}
            >
              {this.state.campaignStatus}{' '}
            </span>
            {this.state.title}
          </h3>
        ) : (
          <h3 className='page-title'>{this.state.title}</h3>
        )}
        <div className='widget page-content-block'>
          <ol className='form-elements'>
            <div className='wizard-comp wizard-comp-campaigntitle'>
              <li className='item-title-field'>
                <TextField
                  name='notificationTitle'
                  label='Widget Title'
                  className='item-stacked one-whole'
                  value={this.state.title}
                  required
                  onChange={this.onTitleChange}
                  tooltipText={t('Title, as will appear on your website')}
                  tooltip
                  tooltipLink='#'
                />
                {this.state.showAdditionalLanguages &&
                  getAdditionalLanguages().map(lang => {
                    return (
                      <li className='item-title-field'>
                        <TextField
                          name={lang}
                          label={`${getLanguageName(lang)} ${t(
                            'Widget Title',
                          )}`}
                          className='item-stacked one-whole'
                          value={this.state.additionalTitles[lang] || ''}
                          required={false}
                          onChange={this.onAdditionalTitleChange}
                        />
                      </li>
                    );
                  })}
                {getAdditionalLanguages().length > 0 && (
                  <div
                    className='other-languages'
                    onClick={this.toggleLanguages}
                  >
                    <i className='icon-plus' />
                    {t('Other Languages')}
                  </div>
                )}
                {this.state.selectedType.page === 'triggerbased' ? (
                  <li>
                    <TextField
                      name='eventName'
                      label='Event Name'
                      className='item-stacked one-whole label-emphasise'
                      value={this.state.eventName}
                      required
                      onChange={this.onEventNameChange}
                    />
                  </li>
                ) : (
                  ''
                )}
              </li>
            </div>
            <li>
              <WizardItems
                ref='wizardItems'
                campaign={this.state.campaign}
                edit
                selectedType={this.state.selectedType}
                selectedPage={this.props.router.params.pagetype}
                isReco
                updateAlgorithmString={this.updateAlgorithmString}
              />
            </li>
            <li>
              {this.state.selectedType.page === 'popup_recommendation' ||
              (this.state.campaign &&
                this.state.campaign.actions &&
                this.state.campaign.actions[0] &&
                this.state.campaign.actions[0].params &&
                this.state.campaign.actions[0].params.actionType ===
                  'POPUP_RECO') ? (
                // eslint-disable-next-line
                <li className='wizard-comp'>
                  <ol className='form-elements'>
                    {popupRecoFields.buttons.length > 0 && (
                      <li className='wizard-input-types wizard-perso-buttons'>
                        <Fields
                          ref='contentButtons'
                          campaign={this.state.campaign}
                          comps={popupRecoFields.buttons}
                          isRecommendationModule
                        />
                      </li>
                    )}
                    <li className='wizard-perso-fields'>
                      <Fields
                        ref='contentExtra'
                        campaign={this.state.campaign}
                        comps={popupRecoFields.fields}
                        isRecommendationModule
                      />
                    </li>
                  </ol>
                </li>
              ) : (
                this.state.campaign &&
                this.state.campaign.actions &&
                this.state.campaign.actions[0] &&
                this.state.campaign.actions[0].filters &&
                (hasModule('segmentation') ||
                  hasModule('personalization') ||
                  hasModule('ruleBasedSegmentation')) && (
                  <li className='wizard-comp'>
                    <ol className='form-elements'>
                      <li className='wizard-perso-fields'>
                        <Fields
                          ref='contentExtra'
                          campaign={this.state.campaign}
                          comps={recoFields.fields}
                          isRecommendationModule
                          campaignType='RECOMMENDATION'
                        />
                      </li>
                    </ol>
                  </li>
                )
              )}
            </li>
          </ol>
        </div>

        <div className='wizard-options'>
          <ol className='form-elements'>
            <li className='campaign-subject'>
              <TextField
                name='scenarioName'
                label='Campaign Name'
                className='item-stacked one-whole label-emphasise'
                value={this.state.scenarioName}
                required
                onChange={this.onScenarioNameChange}
              />
            </li>
            <li>
              <WizardDateSelection
                ref='wizardDateSelection'
                campaign={this.state.campaign}
              />
            </li>
            {showLevelUp ? (
              <li>
                <label className='item item-stacked is-checkbox'>
                  <input
                    type='checkbox'
                    checked={this.state.levelUp === 'true'}
                    onChange={this.toggleLevelUp}
                  />
                  <span className='item-label'>{t('Level-up')}</span>
                </label>
              </li>
            ) : (
              ''
            )}
            <li>
              <WizardMediumSelection
                ref='wizardMediumSelection'
                campaign={this.state.campaign}
              />
            </li>
            <li>
              <WizardPrioritySelection
                ref='wizardPrioritySelection'
                campaign={this.state.campaign}
              />
            </li>
            {this.state.selectedType.page !== 'popup_recommendation' && (
              <li>
                <WizardTarget
                  ref='wizardTarget'
                  campaign={this.state.campaign}
                />
              </li>
            )}
            {['custom', 'popup_recommendation'].includes(
              this.state.selectedType.page,
            ) && (
              <li>
                <WizardPageType
                  ref='wizardPageType'
                  campaign={this.state.campaign}
                />
              </li>
            )}
            {['category', 'product', 'search'].includes(
              this.state.selectedType.page,
            ) && (
              <li>
                <WizardPageType
                  ref='wizardPageType'
                  campaign={this.state.campaign}
                  activeFields={['url']}
                />
              </li>
            )}
            <li>
              <label className='item item-stacked is-checkbox'>
                <input
                  type='checkbox'
                  checked={this.state.useWidgetTemplate === true}
                  onChange={this.toggleUseTemplate}
                />
                <span className='item-label'>{t('Use Template')}</span>
              </label>
            </li>
            {this.state.useWidgetTemplate && (
              <Dropdown
                value={this.state.widgetTemplateId}
                options={this.state.widgetTemplates}
                editCampaign={this.onDropdownChange}
                header='Template'
              />
            )}
            <li className='buttons'>
              {!this.state.useWidgetTemplate && (
                <a
                  className='button secondary-action one-whole'
                  onClick={this.showAdvancedConfig}
                >
                  {t('Advanced Configuration')}
                </a>
              )}
              {this.state.useWidgetTemplate && this.state.widgetTemplateId && (
                <a
                  className={classNames('button goedit-action one-whole', {
                    'gotest-action--disabled':
                      this.state.isSubmitting &&
                      this.state.submittingButton === 'edit',
                  })}
                  onClick={this.validate.bind(null, 'edit')}
                >
                  {t('Edit Template')}
                  {this.state.isSubmitting &&
                    this.state.submittingButton === 'test' && (
                      <span className='page-progress page-progress--button' />
                    )}
                </a>
              )}
              <a
                className={classNames('button gotest-action one-whole', {
                  'gotest-action--disabled':
                    this.state.isSubmitting &&
                    this.state.submittingButton === 'test',
                })}
                onClick={this.validate.bind(null, 'test')}
              >
                {t('Go Test')}
                {this.state.isSubmitting &&
                  this.state.submittingButton === 'test' && (
                    <span className='page-progress page-progress--button' />
                  )}
              </a>
              <a
                className={classNames(
                  'button tertiary-action one-whole golive-action',
                  {
                    'golive-action--disabled':
                      this.state.isSubmitting &&
                      this.state.submittingButton === 'live',
                  },
                )}
                onClick={this.validate.bind(null, 'live')}
              >
                {t('Go Live')}
                {this.state.isSubmitting &&
                  this.state.submittingButton === 'live' && (
                    <span className='page-progress page-progress--button' />
                  )}
              </a>
            </li>
          </ol>
        </div>

        <WizardAdvancedConfig
          ref='wizardAdvancedConfig'
          campaign={this.state.campaign}
          show={this.state.showAdvancedConfig}
          onHide={this.hideAdvancedConfig}
          campaignType={
            this.state.selectedType.page === 'popup_recommendation'
              ? 'popup_recommendation'
              : 'rec'
          }
          isRecommendation
          campaignSpecifics={this}
          submittingButton={this.state.submittingButton}
          device={this.state.devices}
        />
      </form>
    );
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate,
  breadcrumb: store.ui.breadcrumb,
  formSaved: store.ui.formSaved,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes,
  user: store.user.user,
});

EditWidget.contextTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  router: PropTypes.object.isRequired,
};

EditWidget.propTypes = {
  // eslint-disable-next-line react/require-default-props
  validate: PropTypes.bool,
};

export default connect(mapStatesToProps)(EditWidget);
