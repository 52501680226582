import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { uiActions } from '../../actions';
import { hasModule, hasOnlyDynamicBundles } from '../../modules/auth/user';
import {
  transformStats,
  transformTrends,
  transformPageStats,
  transFormPersReport,
  transformOverallTrends,
} from '../../modules/transform';
import stats from '../../modules/stats';
import { t } from '../../system/ui';
import { getOtherDates } from '../analytics/utils';
import { RangePicker } from '../fields';
import DashboardComparisonColumn from './dashboardComparisonColumn';
import { getPersonalizationReport } from '../../modules/reports/ajax';

class CompareDashboardModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserSelect: false,
      firstStart: '',
      firstEnd: '',
      secondStart: '',
      secondEnd: '',
      firstColumnReports: [],
      secondColumnReports: [],
      selectedModule: 'OverallPerformance',
    };

    this.getInitialReports = this.getInitialReports.bind(this);
    this.onFirstDatesChange = this.onFirstDatesChange.bind(this);
    this.onSecondDatesChange = this.onSecondDatesChange.bind(this);
    this.changeFirstColumn = this.changeFirstColumn.bind(this);
    this.changeSecondColumn = this.changeSecondColumn.bind(this);
    this.selectModule = this.selectModule.bind(this);
    this.transformPersonalizationReports = this.transformPersonalizationReports.bind(
      this,
    );
    this.sumDataForSummary = this.sumDataForSummary.bind(this);
  }

  componentDidMount() {
    if (this?.props?.isDashboard) {
      this.selectModule('OverallPerformance');
    } else {
      this.selectModule(this?.props?.selectedModule);
    }
  }

  getInitialReports(module) {
    uiActions.isLoading();
    const boundaries = {};
    boundaries.start = this.state.firstStart || this.props.range[0];
    boundaries.end = this.state.firstEnd || this.props.range[1];
    boundaries.start = boundaries.start.format('YYYY-MM-DD');
    boundaries.end = boundaries.end.format('YYYY-MM-DD');

    const secondBoundaries = {};
    secondBoundaries.start =
      this.state.secondStart || getOtherDates(this.props.range).start;
    secondBoundaries.end =
      this.state.secondEnd || getOtherDates(this.props.range).end;
    secondBoundaries.start = secondBoundaries.start.format('YYYY-MM-DD');
    secondBoundaries.end = secondBoundaries.end.format('YYYY-MM-DD');

    if (this.state.selectedModule === 'OverallPerformance') {
      let additionalState = {};
      let sAdditionalState = {};
      stats.getRangedStats(boundaries, allStats => {
        stats.getRangedTrends(
          boundaries,
          recoTrends => {
            stats.getRangedTrends(
              boundaries,
              pushTrends => {
                stats.getRangedTrends(
                  boundaries,
                  emailTrends => {
                    stats.getRangedTrends(
                      boundaries,
                      searchTrends => {
                        additionalState = {
                          allStats,
                          summaryRecommendationTrends: recoTrends,
                          summaryEmailTrends: emailTrends,
                          summaryPushTrends: pushTrends,
                          summarySearchTrends: searchTrends,
                        };
                        const overallTrends = this.sumDataForSummary(
                          additionalState,
                        );
                        const firstColumnReports = [
                          transformStats(allStats),
                          transformOverallTrends(overallTrends),
                          transformPageStats(allStats),
                        ];
                        stats.getRangedStats(secondBoundaries, sAllStats => {
                          stats.getRangedTrends(
                            secondBoundaries,
                            sRecoTrends => {
                              stats.getRangedTrends(
                                secondBoundaries,
                                sPushTrends => {
                                  stats.getRangedTrends(
                                    secondBoundaries,
                                    sEmailTrends => {
                                      stats.getRangedTrends(
                                        secondBoundaries,
                                        sSearchTrends => {
                                          sAdditionalState = {
                                            allStats: sAllStats,
                                            summaryRecommendationTrends: sRecoTrends,
                                            summaryEmailTrends: sEmailTrends,
                                            summaryPushTrends: sPushTrends,
                                            summarySearchTrends: sSearchTrends,
                                          };
                                          const sOverallTrends = this.sumDataForSummary(
                                            sAdditionalState,
                                          );
                                          const secondColumnReports = [
                                            transformStats(sAllStats),
                                            transformOverallTrends(
                                              sOverallTrends,
                                            ),
                                            transformPageStats(sAllStats),
                                          ];
                                          this.setState(
                                            {
                                              firstColumnReports,
                                              secondColumnReports,
                                            },
                                            () => {
                                              window.dispatchEvent(
                                                new Event('resize'),
                                              );
                                            },
                                          );
                                          uiActions.isLoaded();
                                        },
                                        false,
                                        'Search',
                                      );
                                    },
                                    false,
                                    'E-Mail',
                                  );
                                },
                                false,
                                'Push',
                              );
                            },
                            false,
                            'Recommendation',
                          );
                        });
                      },
                      false,
                      'Search',
                    );
                  },
                  false,
                  'E-Mail',
                );
              },
              false,
              'Push',
            );
          },
          false,
          'Recommendation',
        );
      });
    } else if (this.state.selectedModule !== 'Engagement') {
      stats.getRangedStats(
        boundaries,
        allStats => {
          stats.getRangedTrends(
            boundaries,
            allTrends => {
              const firstColumnReports = [
                transformStats(allStats),
                transformTrends(
                  allTrends,
                  transformStats(allStats).purchase.stats.amount.count,
                  transformStats(allStats).purchase.stats.count.count,
                  transformStats(allStats).purchase.stats.items.count,
                  module === 'Recommendation',
                  module,
                ),
                transformPageStats(allStats),
              ];
              stats.getRangedStats(
                secondBoundaries,
                otherAllStats => {
                  stats.getRangedTrends(
                    secondBoundaries,
                    otherAllTrends => {
                      const secondColumnReports = [
                        transformStats(otherAllStats),
                        transformTrends(
                          otherAllTrends,
                          transformStats(otherAllStats).purchase.stats.amount
                            .count,
                          transformStats(otherAllStats).purchase.stats.count
                            .count,
                          transformStats(otherAllStats).purchase.stats.items
                            .count,
                          module === 'Recommendation',
                          module,
                        ),
                        transformPageStats(otherAllStats),
                      ];
                      this.setState(
                        {
                          firstColumnReports,
                          secondColumnReports,
                        },
                        () => {
                          window.dispatchEvent(new Event('resize'));
                        },
                      );
                      uiActions.isLoaded();
                    },
                    false,
                    module,
                  );
                },
                false,
              );
            },
            false,
            module,
          );
        },
        false,
      );
    } else {
      stats.getRangedStats(boundaries, allStats => {
        getPersonalizationReport('ALL', boundaries, 'ALL', allCampaigns => {
          const firstColumnReports = allCampaigns;

          getPersonalizationReport(
            'ALL',
            secondBoundaries,
            'ALL',
            otherCampaigns => {
              this.setState(
                {
                  firstColumnReports: [
                    {},
                    this.transformPersonalizationReports(
                      firstColumnReports,
                      allStats,
                    ),
                    {},
                  ],
                  secondColumnReports: [
                    {},
                    this.transformPersonalizationReports(
                      otherCampaigns,
                      allStats,
                    ),
                    {},
                  ],
                },
                () => {
                  window.dispatchEvent(new Event('resize'));
                  uiActions.isLoaded();
                },
              );
            },
          );
        });
      });
    }
  }

  sumDataForSummary(state) {
    let totalPurchase = 0;
    const total = {
      revenue: 0,
      revenueRatio: 0,
      totalProducts: 0,
    };
    if (state.hasOwnProperty('allStats')) {
      state.allStats.map(prop => {
        prop.x === 'purchase:amount' ? (totalPurchase = prop.y) : '';
      });
    }
    if (
      state.hasOwnProperty('summaryRecommendationTrends') &&
      hasModule('recommendation')
    ) {
      state.summaryRecommendationTrends.map(prop => {
        prop.x === 'Purchase Amount' ? (total.revenue += prop.y) : '';
        prop.x === 'Purchased Items' ? (total.totalProducts += prop.y) : '';
      });
    }
    if (state.hasOwnProperty('summaryPushTrends') && hasModule('push')) {
      state.summaryPushTrends.map(prop => {
        prop.x === 'Purchase Amount' ? (total.revenue += prop.y) : '';
        prop.x === 'Purchased Items' ? (total.totalProducts += prop.y) : '';
      });
    }
    if (state.hasOwnProperty('summaryEmailTrends') && hasModule('email')) {
      state.summaryEmailTrends.map(prop => {
        prop.x === 'Purchase Amount' ? (total.revenue += prop.y) : '';
        prop.x === 'Purchased Items' ? (total.totalProducts += prop.y) : '';
      });
    }
    if (state.hasOwnProperty('summarySearchTrends') && hasModule('search')) {
      state.summarySearchTrends.map(prop => {
        prop.x === 'Purchase Amount' ? (total.revenue += prop.y) : '';
        prop.x === 'Purchased Items' ? (total.totalProducts += prop.y) : '';
      });
    }
    return {
      total,
      totalPurchase,
    };
  }

  transformPersonalizationReports(campaigns, allStats) {
    let allCampaignLogs = [];
    campaigns.forEach(campaign => {
      allCampaignLogs = allCampaignLogs.concat(campaign.logs);
    });
    const ref = {};
    const res = allCampaignLogs.reduce(function(arr, o) {
      if (!(o.trend in ref)) {
        ref[o.trend] = arr.length;
        arr.push(Object.assign({}, o));
      } else {
        arr[ref[o.trend]].count += o.count;
      }
      return arr;
    }, []);

    const transformedStats = transformStats(allStats);
    const purchaseAmount =
      transformedStats?.purchase?.stats?.amount?.count || 0;

    const transformedReports = transFormPersReport(res, purchaseAmount, true);

    const transformedView = transformedReports.widget;
    transformedView.stats.view.count = transformedView.stats.all.count;
    delete transformedView.stats.all;
    delete transformedView.stats.conversion;

    return {
      widget: transformedReports.widget,
      interaction: transformedReports.interaction,
      basket: transformedReports.basket,
      revenue: transformedReports.revenue,
    };
  }

  selectModule(selectedModule, e) {
    if (e) {
      e.preventDefault();
    }
    this.setState(
      {
        selectedModule,
      },
      () => {
        this.getInitialReports(this.state.selectedModule);
      },
    );
  }

  onFirstDatesChange(newDates) {
    this.setState({
      firstStart: newDates.startDate,
      firstEnd: newDates.endDate,
      isUserSelect: true,
    });
  }

  onSecondDatesChange(newDates) {
    this.setState({
      secondStart: newDates.startDate,
      secondEnd: newDates.endDate,
      isUserSelect: true,
    });
  }

  changeFirstColumn(dates) {
    if (
      dates.startDate === this.state.firstStart &&
      dates.endDate === this.state.firstEnd
    ) {
      return;
    }
    uiActions.isLoading();
    const boundaries = {};
    boundaries.start = dates.startDate.format('YYYY-MM-DD');
    boundaries.end = dates.endDate.format('YYYY-MM-DD');

    if (this.state.selectedModule === 'OverallPerformance') {
      stats.getRangedStats(boundaries, allStats => {
        stats.getRangedTrends(
          boundaries,
          recoTrends => {
            stats.getRangedTrends(
              boundaries,
              pushTrends => {
                stats.getRangedTrends(
                  boundaries,
                  emailTrends => {
                    stats.getRangedTrends(
                      boundaries,
                      searchTrends => {
                        const additionalState = {
                          allStats,
                          summaryRecommendationTrends: recoTrends,
                          summaryEmailTrends: emailTrends,
                          summaryPushTrends: pushTrends,
                          summarySearchTrends: searchTrends,
                        };
                        const sOverallTrends = this.sumDataForSummary(
                          additionalState,
                        );
                        const firstColumnReports = [
                          transformStats(allStats),
                          transformOverallTrends(sOverallTrends),
                          transformPageStats(allStats),
                        ];
                        this.setState(
                          {
                            firstColumnReports,
                          },
                          () => {
                            uiActions.isLoaded();
                            window.dispatchEvent(new Event('resize'));
                          },
                        );
                        uiActions.isLoaded();
                      },
                      false,
                      'Search',
                    );
                  },
                  false,
                  'E-Mail',
                );
              },
              false,
              'Push',
            );
          },
          false,
          'Recommendation',
        );
      });
    } else if (this.state.selectedModule !== 'Engagement') {
      stats.getRangedStats(
        boundaries,
        allStats => {
          stats.getRangedTrends(
            boundaries,
            allTrends => {
              this.setState({
                firstColumnReports: [
                  transformStats(allStats),
                  transformTrends(
                    allTrends,
                    transformStats(allStats).purchase.stats.amount.count,
                    transformStats(allStats).purchase.stats.count.count,
                    transformStats(allStats).purchase.stats.items.count,
                    this.state.selectedModule === 'Recommendation',
                    this.state.selectedModule,
                  ),
                ],
              });
              uiActions.isLoaded();
              window.dispatchEvent(new Event('resize'));
            },
            false,
            this.state.selectedModule,
          );
        },
        false,
      );
    } else {
      stats.getRangedStats(boundaries, allStats => {
        getPersonalizationReport('ALL', boundaries, 'ALL', allCampaigns => {
          this.setState(
            {
              firstColumnReports: [
                {},
                this.transformPersonalizationReports(allCampaigns, allStats),
                {},
              ],
            },
            () => {
              window.dispatchEvent(new Event('resize'));
              uiActions.isLoaded();
            },
          );
        });
      });
    }
  }

  changeSecondColumn(dates) {
    if (
      dates.startDate === this.state.secondtStart &&
      dates.enddDate === this.state.secondEnd
    ) {
      return;
    }
    uiActions.isLoading();
    const boundaries = {};
    boundaries.start = dates.startDate.format('YYYY-MM-DD');
    boundaries.end = dates.endDate.format('YYYY-MM-DD');

    if (this.state.selectedModule === 'OverallPerformance') {
      stats.getRangedStats(boundaries, allStats => {
        stats.getRangedTrends(
          boundaries,
          recoTrends => {
            stats.getRangedTrends(
              boundaries,
              pushTrends => {
                stats.getRangedTrends(
                  boundaries,
                  emailTrends => {
                    stats.getRangedTrends(
                      boundaries,
                      searchTrends => {
                        const additionalState = {
                          allStats,
                          summaryRecommendationTrends: recoTrends,
                          summaryEmailTrends: emailTrends,
                          summaryPushTrends: pushTrends,
                          summarySearchTrends: searchTrends,
                        };
                        const sOverallTrends = this.sumDataForSummary(
                          additionalState,
                        );
                        const secondColumnReports = [
                          transformStats(allStats),
                          transformOverallTrends(sOverallTrends),
                          transformPageStats(allStats),
                        ];
                        this.setState(
                          {
                            secondColumnReports,
                          },
                          () => {
                            uiActions.isLoaded();
                            window.dispatchEvent(new Event('resize'));
                          },
                        );
                        uiActions.isLoaded();
                      },
                      false,
                      'Search',
                    );
                  },
                  false,
                  'E-Mail',
                );
              },
              false,
              'Push',
            );
          },
          false,
          'Recommendation',
        );
      });
    } else if (this.state.selectedModule !== 'Engagement') {
      stats.getRangedStats(
        boundaries,
        allStats => {
          stats.getRangedTrends(
            boundaries,
            allTrends => {
              this.setState({
                secondColumnReports: [
                  transformStats(allStats),
                  transformTrends(
                    allTrends,
                    transformStats(allStats).purchase.stats.amount.count,
                    transformStats(allStats).purchase.stats.count.count,
                    transformStats(allStats).purchase.stats.items.count,
                    this.state.selectedModule === 'Recommendation',
                    this.state.selectedModule,
                  ),
                ],
              });
              uiActions.isLoaded();
              window.dispatchEvent(new Event('resize'));
            },
            false,
            this.state.selectedModule,
          );
        },
        false,
      );
    } else {
      stats.getRangedStats(boundaries, allStats => {
        getPersonalizationReport('ALL', boundaries, 'ALL', allCampaigns => {
          this.setState(
            {
              secondColumnReports: [
                {},
                this.transformPersonalizationReports(allCampaigns, allStats),
                {},
              ],
            },
            () => {
              window.dispatchEvent(new Event('resize'));
              uiActions.isLoaded();
            },
          );
        });
      });
    }
  }

  render() {
    return (
      <div>
        {!this.props?.isStatic && (
          <div className='dashboard-modal-module-selection'>
            <span
              onClick={this.selectModule.bind(this, 'OverallPerformance')}
              className={classNames('single-module', {
                'is-selected':
                  this.state.selectedModule === 'OverallPerformance',
              })}
            >
              Overall Performance
            </span>
            {hasModule('recommendation') || hasOnlyDynamicBundles() ? (
              <span
                onClick={this.selectModule.bind(this, 'Recommendation')}
                className={classNames('single-module', {
                  'is-selected': this.state.selectedModule === 'Recommendation',
                })}
              >
                Recommendation
              </span>
            ) : (
              ''
            )}
            {hasModule('email') ? (
              <span
                onClick={this.selectModule.bind(this, 'E-Mail')}
                className={classNames('single-module', {
                  'is-selected': this.state.selectedModule === 'E-Mail',
                })}
              >
                Email
              </span>
            ) : (
              ''
            )}
            {hasModule('push') ? (
              <span
                onClick={this.selectModule.bind(this, 'Push')}
                className={classNames('single-module', {
                  'is-selected': this.state.selectedModule === 'Push',
                })}
              >
                Push
              </span>
            ) : (
              ''
            )}

            {hasModule('search') ? (
              <span
                onClick={this.selectModule.bind(this, 'Search')}
                className={classNames('single-module', {
                  'is-selected': this.state.selectedModule === 'Search',
                })}
              >
                Search
              </span>
            ) : (
              ''
            )}
          </div>
        )}
        <div className='report-compare one-whole' style={{ minWidth: '932px' }}>
          <div className='one-half report-compare__field'>
            <div className='report-compare-date'>
              <span
                style={{
                  display: 'inline-block',
                  marginRight: '12px',
                  fontSize: '14px',
                  color: '#a3a3a5',
                }}
              >
                {t('Compare To')}:
              </span>
              <RangePicker
                startDate={
                  this.state.secondStart ||
                  getOtherDates(this.props.range).start
                }
                endDate={
                  this.state.secondEnd || getOtherDates(this.props.range).end
                }
                onDatesChange={this.onSecondDatesChange}
                onPickerClose={this.changeSecondColumn}
              />
            </div>
            <DashboardComparisonColumn
              stats={this.state.secondColumnReports}
              isMail={this.state.selectedModule === 'E-Mail'}
              isPush={this.state.selectedModule === 'Push'}
              isOverall={this.state.selectedModule === 'OverallPerformance'}
              isStatic={!!this.props.isStatic}
              isSearch={this.state.selectedModule === 'Search'}
            />
          </div>
          <div className='one-half report-compare__field'>
            <div className='report-compare-date'>
              <span
                style={{
                  display: 'inline-block',
                  marginRight: '12px',
                  fontSize: '14px',
                  color: '#a3a3a5',
                }}
              >
                {t('With')}:
              </span>
              <RangePicker
                startDate={this.state.firstStart || this.props.range[0]}
                endDate={this.state.firstEnd || this.props.range[1]}
                onDatesChange={this.onFirstDatesChange}
                onPickerClose={this.changeFirstColumn}
              />
            </div>
            <DashboardComparisonColumn
              stats={this.state.firstColumnReports}
              withCompareIcons
              comparisonStats={this.state.secondColumnReports}
              isMail={this.state.selectedModule === 'E-Mail'}
              isPush={this.state.selectedModule === 'Push'}
              isOverall={this.state.selectedModule === 'OverallPerformance'}
              isStatic={!!this.props.isStatic}
              isSearch={this.state.selectedModule === 'Search'}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
});

export default connect(mapStatesToProps)(CompareDashboardModal);
