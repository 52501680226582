import React from 'react';
import styles from './InputCardWrapper.module.scss';
import Tooltip from '../../tooltip';
import Icons from '../../icons';
import { t } from '../../../system/ui';

/**
 * @name InputCardWrapper component
 * @description Wrapper component to wrap all the components
 * @param {string} cardTitle - cardTitle of the InputCardWrapper
 * @param cardDescription
 * @param {JSX.Element | JSX.Element[]} children
 * @param {string} type = The type parameter is the rendering condition of the css class
 * @param {string} labelFor = html input id
 * @param {string} tooltipContent The type parameter is the rendering condition of the tooltip
 * @param { boolean } required = The required parameter is the rendering condition of the asterisk character.
 * @param {string} iconType = The iconType parameter is the rendering condition of the icon.
 * @param {string} iconWidth = The iconWidth parameter is the rendering condition of the icon.
 * @param {string} iconHeight = The iconHeight parameter is the rendering condition of the icon.
 * @param {string} iconColor = The iconColor parameter is the rendering condition of the icon color.
 * @param {string} rectColor = The rectColor parameter is the rendering condition of the icon rectangle color.
 * @param {number} leftCharacter = The leftCharacter parameter is the rendering condition of the character counter.
 * @param labelClass = The labelClass parameter is the rendering condition of the label class.
 * @param errorMessage = The errorMessage parameter is the rendering condition of the error message.
 * @returns {JSX.Element}
 * @constructor
 */

const InputCardWrapper = ({
  cardTitle = '',
  cardDescription = '',
  children,
  labelFor,
  type,
  required,
  tooltipContent,
  iconType,
  iconColor,
  iconWidth,
  iconHeight,
  rectColor,
  leftCharacter = null,
  labelClass = '',
  errorMessage = '',
}) => {
  return (
    <div className={`${styles[type]} ${errorMessage && 'has-error'}`}>
      {cardTitle && (
        <label className={styles[labelClass]} htmlFor={labelFor}>
          {required ? `${cardTitle}*` : cardTitle}
          {tooltipContent && (
            <Tooltip content={tooltipContent} alignment='left'>
              <a href='#' rel='noreferrer'>
                <i className='icon-info' role='presentation'>
                  <span className='for-screenreader-only' />
                </i>
              </a>
            </Tooltip>
          )}
        </label>
      )}
      {cardDescription && (
        <div className={styles.cardDescription}>{cardDescription}</div>
      )}
      {type !== 'withIcon' ? (
        children
      ) : (
        <div className={styles.iconWrapper}>
          <Icons
            name={iconType}
            color={iconColor}
            width={iconWidth}
            height={iconHeight}
            rectColor={rectColor}
          />
          {children}
        </div>
      )}
      {leftCharacter !== null && (
        <div className={styles.characterLimitCounter}>
          <span className={styles.characterLimitCounterValue}>
            {leftCharacter}
          </span>
          {t(' characters left')}
        </div>
      )}
      {errorMessage && <span className='item-error'>{t(errorMessage)}</span>}
    </div>
  );
};

export default InputCardWrapper;
