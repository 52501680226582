import React, { useEffect, useReducer } from 'react';
import { TextField } from '../../fields';
import './SFYImageInput.scss';
import { $http } from '../../../system/ajax';
import api from '../../../system/api';
import { getApiKey } from '../../../modules/auth/user';
import {
  initialState,
  reducer,
} from './image-upload-reducer/image-upload-reducer';
import Icon from '../../icon';
import NoImage from '../../../images/noImage.svg';
import { Loading } from '../module-exports';

/**
 * @name SFYImageInput
 * @description Image upload component
 * @param fetchedImage {string} - fetched image from feed
 * @param title {string} - title of the image input example 'Web Image*'
 * @param iconName {string} - icon name of the image input example 'pc'
 * @param stateSetter {function} - state setter function
 * @param type {string} - type of the image input example 'webImage'
 * @param withoutCardSpace {boolean} - if true, it will not add card space
 * @param endpointType
 * @param isRequired
 * @return {string}
 * @constructor
 */

const SFYImageInput = ({
  fetchedImage,
  title,
  iconName,
  stateSetter,
  type,
  withoutCardSpace = false,
  endpointType = 'push',
  isRequired = false,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { image, url, radioType, isLoading } = state;

  const onChange = ({ target }) => {
    const { name, value, files } = target;
    switch (name) {
      case `imageUpload-${type}`:
        dispatch({
          type: 'image',
          payload: { image: files[0], type },
        });
        break;
      case 'url':
        dispatch({
          type: 'url',
          payload: { url: value, type },
        });
        break;
      case `radioUpload-${type}`:
        dispatch({
          type: 'radioType',
          payload: {
            radioType: {
              url: false,
              upload: true,
            },
            type,
          },
        });
        break;
      case `radioUrl-${type}`:
        dispatch({
          type: 'radioType',
          payload: {
            radioType: {
              url: true,
              upload: false,
            },
            type,
          },
        });
        break;
      default:
        return null;
    }
  };

  const sendImage = async uploadedImage => {
    const formData = new FormData();
    formData.append('file', uploadedImage);
    return await $http(
      `${api.getRestEndPoint(
        'upload/file',
      )}?type=${endpointType.toUpperCase()}&apiKey=${getApiKey()}`,
    ).post(formData, { type: 'form', fileType: uploadedImage.type });
  };

  useEffect(() => {
    if (image[type] && image[type].size > 0) {
      dispatch({
        type: 'isLoading',
        payload: { isLoading: true, type },
      });
      try {
        sendImage(image[type]).then(response => {
          const parseResponseStringUrl = response.substr(
            1,
            response.length - 2,
          );
          dispatch({
            type: 'url',
            payload: { url: parseResponseStringUrl, type },
          });
          stateSetter(
            parseResponseStringUrl,
            type === 'web' ? 'image' : 'mImage',
          );
          dispatch({
            type: 'radioType',
            payload: {
              radioType: {
                url: true,
                upload: false,
              },
              type,
            },
          });
          dispatch({
            type: 'isLoading',
            payload: { isLoading: false, type },
          });
        });
      } catch (err) {}
    }
  }, [image[type], type]);

  useEffect(() => {
    if (fetchedImage !== undefined) {
      dispatch({
        type: 'radioType',
        payload: {
          radioType: {
            url: true,
            upload: false,
          },
          type,
        },
      });
      dispatch({
        type: 'url',
        payload: { url: fetchedImage, type },
      });
    }
  }, [fetchedImage]);

  useEffect(() => {
    if (url[type] !== undefined) {
      stateSetter(url[type], type === 'web' ? 'image' : 'mImage');
    }
  }, [url.image, url.mImage, url[type]]);

  return (
    <div
      className={`upload-image-container ${withoutCardSpace &&
        'without-space'}`}
    >
      <div className='title-container'>
        <Icon name={iconName} />
        <span>{title}</span>
      </div>
      <div className='upload-image-sub-container'>
        {isLoading[type] ? (
          <Loading />
        ) : (
          <caption className='upload-image-caption'>
            <img
              src={
                fetchedImage !== undefined && fetchedImage !== ''
                  ? fetchedImage
                  : image[type] !== null
                  ? URL.createObjectURL(image[type])
                  : NoImage
              }
              className='upload-image-img'
              alt={`selectImage-${type}`}
            />
          </caption>
        )}
        <div className='image-upload-depended'>
          <div className='radio-container'>
            <div className='radio'>
              <input
                checked={radioType[type].url}
                value={radioType[type].url}
                id={`radioUrl-${type}`}
                name={`radioUrl-${type}`}
                type='radio'
                onChange={onChange}
              />
              <label htmlFor={`radioUrl-${type}`} className='radio-label'>
                Url
              </label>
            </div>

            <div className='radio'>
              <input
                checked={radioType[type].upload}
                value={radioType[type].upload}
                id={`radioUpload-${type}`}
                name={`radioUpload-${type}`}
                type='radio'
                onChange={onChange}
              />
              <label htmlFor={`radioUpload-${type}`} className='radio-label'>
                Upload
              </label>
            </div>
          </div>
          <div className='image-upload-comps'>
            {radioType[type].url === true ? (
              <>
                <TextField
                  value={url[type]}
                  onChange={onChange}
                  className='item-stacked one-whole'
                  name='url'
                  required={isRequired}
                  placeholder='Type Url'
                />
              </>
            ) : radioType[type].upload === true ? (
              <>
                <label
                  htmlFor={`imageUpload-${type}`}
                  className='image-upload-label'
                >
                  <i className='icon-upload' style={{ color: '#547494' }} />
                  <span className='image-upload-label-text'>Upload</span>
                </label>
                <input
                  type='file'
                  id={`imageUpload-${type}`}
                  name={`imageUpload-${type}`}
                  onChange={onChange}
                  hidden
                />
                {isRequired && (
                  <span className='item-error has-error'>
                    This field is required
                  </span>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SFYImageInput;
