/**
 * Created by barisaltun on 28/03/2019.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { uiActions, filterActions } from "../../actions";
import { t } from "../../system/ui";
import { getSinglePersonalizationReportInterval } from "../../modules/reports/ajax";

import ReportClickChart from "./chart.click";
import ReportPurchaseChart from "./chart.purchase";

class GraphPersonalizationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: [],
      graphType: "click"
    };

    this.getReport = this.getReport.bind(this);
    this.changeGraphType = this.changeGraphType.bind(this);
  }

  componentDidMount() {
    this.getReport();
  }

  changeGraphType() {
    if (
      this.props.campaign.type !== "FORM" &&
      this.props.campaign.type !== "NEWSLETTER" &&
      this.props.campaign.type !== "PUSH_PERMISSION"
    ) {
      this.setState({
        graphType: this.state.graphType === "click" ? "purchase" : "click"
      });
    }
  }

  getReport() {
    let boundaries = [];
    boundaries.start = this.props.range[0].format("YYYY-MM-DD");
    boundaries.end = this.props.range[1].format("YYYY-MM-DD");
    let timeFrame = "monthly";
    if (this.props.range[1] && this.props.range[0]) {
      let timeDiff = this.props.range[1] - this.props.range[0];
      if (timeDiff < 24 * 60 * 60 * 1000) {
        timeFrame = "daily";
      } else if (timeDiff < 90 * 24 * 60 * 60 * 1000) {
        timeFrame = "daily";
      }
    }
    getSinglePersonalizationReportInterval(
      this.props.campaign.instanceId,
      boundaries,
      timeFrame,
      campaignReport => {
        this.setState({
          stats: campaignReport
        });
        filterActions.filtersUpdated();
        filterActions.filtersReset();
        uiActions.isLoaded();
      }
    );
  }

  getFieldsToHide() {
    let fields = [];
    if (this.props.campaign.type !== "POPUP_RECOMMENDATION") {
      fields.push("Widget View");
    }
    if (
      this.props.campaign.type === "FORM" ||
      this.props.campaign.type === "NEWSLETTER" ||
      this.props.campaign.type === "PUSH_PERMISSION" ||
      this.props.campaign.type === "SEGMENTATION"
    ) {
      fields.push("Click");
      fields.push("Basket Items");
      fields.push("Purchased Items");
    }
    if (
      this.props.campaign.type === "PUSH_NOTIFICATION" ||
      this.props.campaign.type === "SEGMENTATION"
    ) {
      fields.push("Click");
      fields.push("Impression");
    }
    return fields;
  }

  addExtraFields() {
    let fields = [];
    if (
      this.props.campaign.type === "FORM" ||
      this.props.campaign.type === "NEWSLETTER"
    ) {
      fields.push({
        name: "Form Submit",
        label: t("Form Submit"),
        isVisible: true,
        type: "line",
        yAxis: 0
      });
    }
    if (this.props.campaign.type === "PUSH_NOTIFICATION") {
      fields.push({
        name: "Notification [clicked]",
        label: t("Notification [clicked]"),
        isVisible: true,
        type: "line",
        yAxis: 0
      });
      fields.push({
        name: "Notification [shown]",
        label: t("Notification [shown]"),
        isVisible: true,
        type: "line",
        yAxis: 0
      });
      fields.push({
        name: "Notification [sent]",
        label: t("Notification [sent]"),
        isVisible: true,
        type: "line",
        yAxis: 0
      });
    }
    if (this.props.campaign.type === "PUSH_PERMISSION") {
      fields.push({
        name: "Notification Permission [granted]",
        label: t("Notification Permission [granted]"),
        isVisible: true,
        type: "line",
        yAxis: 0
      });
      fields.push({
        name: "Notification Permission [denied]",
        label: t("Notification Permission [denied]"),
        isVisible: true,
        type: "line",
        yAxis: 0
      });
    }
    if (this.props.campaign.type === "SEGMENTATION") {
      fields.push({
        name: "Segment Visitor",
        label: t("Segment Visitor"),
        isVisible: true,
        type: "line",
        yAxis: 0
      });
      fields.push({
        name: "Unsegment Visitor",
        label: t("Unsegment Visitor"),
        isVisible: true,
        type: "line",
        yAxis: 0
      });
      fields.push({
        name: "Toggle Visitor Segment",
        label: t("Toggle Visitor Segment"),
        isVisible: true,
        type: "line",
        yAxis: 0
      });
    }
    return fields;
  }

  render() {
    return (
      <div className="report-compare one-whole" style={{ minWidth: "932px" }}>
        <div className="report--graph__buttons">
          <ul>
            {this.props.campaign.type !== "FORM" &&
            this.props.campaign.type !== "NEWSLETTER" &&
            this.props.campaign.type !== "PUSH_PERMISSION" &&
            this.props.campaign.type === "SEGMENTATION" ? (
              <div>
                <li
                  className={
                    this.state.graphType === "click"
                      ? "secondary-action selected"
                      : "report--graph__button"
                  }
                  onClick={this.changeGraphType}
                >
                  {t("Conversion Graph")}
                </li>
                <li
                  className={
                    this.state.graphType === "purchase"
                      ? "secondary-action selected"
                      : "report--graph__button"
                  }
                  onClick={this.changeGraphType}
                >
                  {t("Revenue Graph")}
                </li>
              </div>
            ) : (
              <li
                className={
                  this.state.graphType === "click"
                    ? "secondary-action selected"
                    : "report--graph__button"
                }
                onClick={this.changeGraphType}
              >
                {t("Conversion Graph")}
              </li>
            )}
          </ul>
        </div>
        {this.state.graphType === "click" ? (
          <ReportClickChart
            stats={this.state.stats}
            fieldsToHide={this.getFieldsToHide()}
            extraFields={this.addExtraFields()}
          />
        ) : this.props.campaign.type !== "FORM" &&
          this.props.campaign.type !== "NEWSLETTER" &&
          this.props.campaign.type !== "PUSH_PERMISSION" &&
          this.props.campaign.type !== "SEGMENTATION" ? (
          <ReportPurchaseChart stats={this.state.stats} />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias
});

export default connect(mapStatesToProps)(GraphPersonalizationModal);
