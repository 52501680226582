import React from 'react';
import PropTypes from 'prop-types';

import Card from '../components/card';
import EmailCard from '../components/email-card';
import EmailCount from '../components/email-count';
import PushCard from '../components/push-card';
import PushCount from '../components/push-count';
import { statTitles, trendTitles } from '../constants/ui';
import { t } from '../system/ui';
import { uiActions } from '../actions';

const GridView = ({ noModule, trends, selectedModule, pageStats, stats }) => {
  let sectionTitle;
  if (selectedModule === 'E-Mail') {
    sectionTitle = 'section-title section-title-email';
  } else if (selectedModule === 'Push') {
    sectionTitle = 'section-title section-title-push';
  } else if (selectedModule === 'Search') {
    sectionTitle = 'section-title section-title-search';
  } else {
    sectionTitle = 'section-title';
  }
  try {
    // eslint-disable-next-line no-param-reassign
    delete trends.widget.stats.bounceBack;
    // eslint-disable-next-line no-param-reassign
    delete trends.widget.stats.unsubscribed;
  } catch (err) {
    uiActions.showNotification({
      content: () => <p>{err}</p>,
      className: 'notification-fail',
    });
  }

  return (
    <div>
      <div className='page-content-wrapper export-content-wrapper'>
        {!noModule && (
          <div className='account-trends'>
            <h3 className={sectionTitle}>{t('Segmentify Performance')}</h3>
            {selectedModule === 'E-Mail' && <EmailCount />}
            {selectedModule === 'Push' && <PushCount />}
            <div className='stat-cards'>
              {Object.keys(trends).map(item => {
                const title = trendTitles[item];
                if (selectedModule === 'E-Mail') {
                  return (
                    <EmailCard
                      key={item}
                      data={trends[item]}
                      type={item}
                      title={title}
                    />
                  );
                }
                if (selectedModule === 'Push') {
                  return (
                    <PushCard
                      key={item}
                      data={trends[item]}
                      type={item}
                      title={title}
                    />
                  );
                }
                if (selectedModule === 'Search') {
                  // TODO this condition added due to V3-9633 DO NOT CHANGE IT.
                  return item !== 'assistedRevenue' ? (
                    <Card
                      key={item}
                      data={trends[item]}
                      type={item}
                      title={title}
                      isSearch
                    />
                  ) : null;
                }
                return (
                  <Card
                    key={item}
                    data={trends[item]}
                    type={item}
                    title={title}
                  />
                );
              })}
            </div>
          </div>
        )}
        <div className='stat-trends stats-your-website'>
          <h3 className='section-title'>{t('Your Website')}</h3>
          <div className='stat-cards'>
            {Object.keys(stats).map(item => {
              const title = statTitles[item];

              return (
                <Card
                  showIn='your-website'
                  key={item}
                  data={stats[item]}
                  type={item}
                  title={title}
                  pageStats={pageStats}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

GridView.propTypes = {
  noModule: PropTypes.bool,
  trends: PropTypes.arrayOf(PropTypes.shape({})),
  selectedModule: PropTypes.shape({}),
  pageStats: PropTypes.shape({}),
  stats: PropTypes.arrayOf(PropTypes.shape({})),
};

export default GridView;
