/**
 * Created by mehmetyurtar on 10/18/17.
 */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isSuperUser } from "../modules/auth/user";
import classNames from "classnames";

import { pageTypes } from "../constants/datamaps/wizard";

import { t, confirm } from "../system/ui";
import { setLocal } from "../system/object";
import { setTitle } from "../system/document";

import { uiActions } from "../actions";

import { config } from "../constants/config";
import { widgetTypes } from "../constants/datamaps/campaigns";

import { getCampaign } from "../modules/campaigns/ajax";
import { goTestOrLive } from "../modules/campaigns/wizard";
import { getCampaignPage } from "../modules/campaigns/data";
import { singleCampaign } from "../modules/campaigns/wizard";

import Icon from "../components/icon";
import { TextField } from "../components/fields";
import WizardItems from "../components/wizard/items";
import WizardTarget from "../components/wizard/target";
import WizardPageType from "../components/wizard/page-type";
import WizardDateSelection from "../components/wizard/date-selection";
import WizardAdvancedConfig from "../components/wizard/advanced-config";
import WizardMediumSelection from "../components/wizard/medium-selection";
import WizardPrioritySelection from "../components/wizard/priority-selection";

let lastType = "";

class EditKeyword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: {},
      title: "",
      scenarioName: "",
      eventName: "",
      selectedType: "",
      showAdvancedConfig: false,
      saved: true,
      campaignStatus: "",
      isSubmitting: false,
      submittingButton: ""
    };

    this.onTitleChange = this.onTitleChange.bind(this);
    this.onScenarioNameChange = this.onScenarioNameChange.bind(this);
    this.onEventNameChange = this.onEventNameChange.bind(this);

    this.showAdvancedConfig = this.showAdvancedConfig.bind(this);
    this.hideAdvancedConfig = this.hideAdvancedConfig.bind(this);

    this.campaignDetails = this.campaignDetails.bind(this);

    this.goTest = this.goTest.bind(this);
    this.goLive = this.goLive.bind(this);

    this.validate = this.validate.bind(this);
  }

  componentDidMount() {
    setTitle(t("Edit Campaign"));

    uiActions.isValidated();
    uiActions.removePageClass();

    uiActions.confirmOnLeave(this);

    this.campaignDetails();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      this.campaignDetails();
    }

    if (this.state.saved !== newProps.formSaved) {
      this.setState({
        saved: newProps.formSaved
      });
    }

    if (newProps.formSaved === true) {
      this.setState({
        isSubmitting: false
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.validate === true && oldProps.validate === false) {
      this.validate(lastType);
    }
  }

  campaignDetails() {
    getCampaign(this.props.params.widgetId, response => {
      singleCampaign(response);

      let value = getCampaignPage(response)
          .replace(" Page", "")
          .toLowerCase(),
        pageType = pageTypes.indexOf(value) > -1 ? value : "custom";

      if (pageType === "404") {
        pageType = "notfound";
      }

      if (response.templateId === "SMSG_01") {
        pageType = "triggerbased";
      }
      this.setState(
        {
          campaign: response,
          scenarioName: response.scenarioName,
          title: response.actions[0].params.notificationTitle,
          eventName: response.inputs.length ? response.inputs[0].value[0] : "",
          selectedType: { page: pageType },
          saved: true,
          campaignStatus: response.testMode ? "Test" : "Live"
        },
        () => {
          uiActions.showBreadcrumb({
            home: {
              name: t("Recommend"),
              icon: "heart",
              slug: "/recommendations"
            },
            top: {
              name: t(
                config.menus.primary.recommendations.children[
                  this.state.selectedType.page
                ].name + " Page Widgets"
              ),
              slug: "/recommendations/" + this.state.selectedType.page
            },
            current: {
              name: this.state.scenarioName
            }
          });
        }
      );
    });
  }

  goTest() {
    this.setState({ isSubmitting: true, submittingButton: "test" }, () => {
      goTestOrLive("test", this, this.state.selectedType.page, false, true);
      uiActions.formSaved();
    });
  }

  goLive() {
    this.setState({ isSubmitting: true, submittingButton: "live" }, () => {
      goTestOrLive("live", this, this.state.selectedType.page, false, true);
      uiActions.formSaved();
    });
  }

  validate(type) {
    let errors = document.querySelectorAll(".has-error");

    lastType = type;

    if (this.props.validate === true && errors.length === 0) {
      if (type === "test") {
        confirm({
          title: "Update Campaign on Test Mode?",
          onConfirm: this.goTest,
          onCancel: () => {
            this.setState({
              isSubmitting: false
            });
          }
        });
      }

      if (type === "live") {
        confirm({
          title: "Update Campaign on Live?",
          onConfirm: this.goLive,
          onCancel: () => {
            this.setState({
              isSubmitting: false
            });
          }
        });
      }
    } else {
      uiActions.isValidating();
    }
  }

  onTitleChange(e) {
    this.setState({
      title: e.currentTarget.value,
      saved: false
    });

    uiActions.formEdited();
  }

  onScenarioNameChange(e) {
    this.setState({
      scenarioName: e.currentTarget.value,
      saved: false
    });

    uiActions.formEdited();
  }

  onEventNameChange(e) {
    this.setState({
      eventName: e.currentTarget.value,
      saved: false
    });
    uiActions.formEdited();
  }

  showAdvancedConfig() {
    this.setState({
      showAdvancedConfig: true
    });

    uiActions.formEdited();
  }

  hideAdvancedConfig() {
    this.setState({
      showAdvancedConfig: false
    });
  }

  render() {
    return (
      <form id="wizard" ref="wizard" className="wizard wizard-cards">
        {this.state.campaign.testMode !== "" ? (
          <h3 className="page-title">
            <span
              className={
                "status-label status-label-" +
                this.state.campaignStatus.toLowerCase()
              }
            >
              {this.state.campaignStatus}{" "}
            </span>
            {this.state.title}
          </h3>
        ) : (
          <h3 className="page-title">{this.state.title}</h3>
        )}
        <div className="widget page-content-block">
          <ol className="form-elements">
            <div className="wizard-comp wizard-comp-campaigntitle wizard-comp-campaigntitle--keyword">
              <li className="item-title-field">
                <TextField
                  name="notificationTitle"
                  label="Widget Title"
                  className="item-stacked one-whole"
                  value={this.state.title}
                  required={true}
                  onChange={this.onTitleChange}
                  tooltipText={t("Title, as will appear on your website")}
                  tooltip={true}
                  tooltipLink="#"
                />
              </li>
              {this.state.selectedType.page === "triggerbased" ? (
                <li>
                  <TextField
                    name="eventName"
                    label="Event Name"
                    className="item-stacked one-whole label-emphasise"
                    value={this.state.eventName}
                    required={true}
                    onChange={this.onEventNameChange}
                  />
                </li>
              ) : (
                ""
              )}
            </div>
            <li>
              <WizardItems
                ref="wizardItems"
                campaign={this.state.campaign}
                edit={true}
                selectedType={this.state.selectedType}
                selectedPage={this.props.router.params.pagetype}
                isKeyword={true}
              />
            </li>
          </ol>
        </div>

        <div className="wizard-options">
          <ol className="form-elements">
            <li className="campaign-subject">
              <TextField
                name="scenarioName"
                label="Campaign Name"
                className="item-stacked one-whole label-emphasise"
                value={this.state.scenarioName}
                required={true}
                onChange={this.onScenarioNameChange}
              />
            </li>
            <li>
              <WizardDateSelection
                ref="wizardDateSelection"
                campaign={this.state.campaign}
              />
            </li>
            <li>
              <WizardMediumSelection
                ref="wizardMediumSelection"
                campaign={this.state.campaign}
              />
            </li>
            <li>
              <WizardTarget ref="wizardTarget" campaign={this.state.campaign} />
            </li>
            {isSuperUser() ? (
              <li>
                <WizardPrioritySelection
                  ref="wizardPrioritySelection"
                  campaign={this.state.campaign}
                />
              </li>
            ) : (
              ""
            )}
            {this.state.selectedType.page === "custom" ? (
              <li>
                <WizardPageType
                  ref="wizardPageType"
                  campaign={this.state.campaign}
                />
              </li>
            ) : (
              ""
            )}
            <li className="buttons">
              <a
                className="button secondary-action one-whole"
                onClick={this.showAdvancedConfig}
              >
                {t("Advanced Configuration")}
              </a>
              <a
                className={classNames("button gotest-action one-whole", {
                  "gotest-action--disabled":
                    this.state.isSubmitting &&
                    this.state.submittingButton === "test"
                })}
                onClick={this.validate.bind(null, "test")}
              >
                {t("Go Test")}
                {this.state.isSubmitting &&
                  this.state.submittingButton === "test" && (
                    <span className="page-progress page-progress--button" />
                  )}
              </a>
              <a
                className={classNames(
                  "button tertiary-action one-whole golive-action",
                  {
                    "golive-action--disabled":
                      this.state.isSubmitting &&
                      this.state.submittingButton === "live"
                  }
                )}
                onClick={this.validate.bind(null, "live")}
              >
                {t("Go Live")}
                {this.state.isSubmitting &&
                  this.state.submittingButton === "live" && (
                    <span className="page-progress page-progress--button" />
                  )}
              </a>
            </li>
          </ol>
        </div>

        <WizardAdvancedConfig
          ref="wizardAdvancedConfig"
          campaign={this.state.campaign}
          show={this.state.showAdvancedConfig}
          onHide={this.hideAdvancedConfig}
          campaignType="keyword"
          campaignSpecifics={this}
          device={this.state.devices}
        />
      </form>
    );
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate,
  breadcrumb: store.ui.breadcrumb,
  formSaved: store.ui.formSaved,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes,
  user: store.user.user
});

EditKeyword.contextTypes = {
  router: PropTypes.object.isRequired
};

EditKeyword.propTypes = {
  validate: PropTypes.bool
};

export default connect(mapStatesToProps)(EditKeyword);
