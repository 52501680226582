import React from "react";

export default () => {
  return {
    search: {
      title: "Search",
      content: () => (
        <div>
          <p>This box shows the statistics about Searchandising</p>
          <ul>
            <li>
              <strong>Searches:</strong> Indicate the total number of search
              queries done by the visitors and customers.
              A search can be triggered by changing search keyword, changing
              page,
              facet selection or changing ordering
            </li>
            <li>
              <strong>Keyword Searches:</strong> This number indicates how many
              times
              search is triggered by changing search keyword
            </li>
            <li>
              <strong>Next Page:</strong> This number indicates how many times
              search is triggered by changing page for search results
            </li>
            <li>
              <strong>Facet Selections:</strong> This number indicates how many
              times
              search is triggered by changing facets (filters)
            </li>
            <li>
              <strong>Change Ordering:</strong> This number indicates how many
              times
              search is triggered by changing ordering for search results
            </li>
          </ul>
        </div>
      )
    },
    interaction: {
      title: "Interaction",
      content: () => (
        <div>
          <p>
            This box shows the statistics about user interaction with
            Searchandising.
          </p>
          <ul>
            <li>
              <strong>Clicks:</strong> Shows how many times the products inside
              the Searchandising are clicked by your visitor.
            </li>
            <li>
              <strong>CTR:</strong> Click-through rate shows the ratio between
              the number of clicks on a Searchandising and the
              number of views by your visitors. It is calculated by dividing
              Clicks to Views. If this ratio is low, you should consider
              changing the rules and filters of the recommendations.
            </li>
          </ul>
        </div>
      )
    },
    basket: {
      title: "Basket",
      content: () => (
        <div>
          <p>
            This box shows the statistics about products that are added to the
            basket through Searchandising.
          </p>
          <ul>
            <li>
              <strong>Products:</strong> Shows how many products are added to
              the basket through Searchandising. This is the total
              number of items added to the basket. If a visitor adds two of the
              same product after clicking to a Searchandising, it
              is counted as two items.
            </li>
            <li>
              <strong>Total Amount:</strong> Shows the total monetary amount of
              items added to the basket through Searchandising
            </li>
            <li>
              <strong>Add to Basket Rate:</strong> Shows the ratio between the number
              of products that are added to the basket and the the number of
              products that are clicked through the Searchandising.
              It is calculated by dividing Products Added to the Basket to
              Products Clicked If this ratio is low, you should consider
              updating the recommendation types and filters of the products to
              create the best match for visitor’s preferences.
            </li>
          </ul>
        </div>
      )
    },
    revenue: {
      title: "Revenue",
      content: () => (
        <div>
          <p>
            This box shows the statistics about products that are purchased
            through Searchandising and the effect of those
            purchases over your website’s overall revenue.
          </p>
          <ul>
            <li>
              <strong>Revenue:</strong> Shows the total monetary amount of
              products sold through Searchandising. Segmentify only
              tracks the products clicked from the Searchandising
              in the same session. When a visitor buys 2 products with only one
              of them directed from a Searchandising, Segmentify
              only adds that one product to its revenue contribution.
            </li>
            <li>
              <strong>Purchases:</strong> Shows the number of purchases that
              include at least one product directed from Personalised
              Searchandising.
            </li>
            <li>
              <strong>Purchased Products:</strong> Shows the total number of
              products that are sold through Searchandising. If a
              visitor buys two of the same product after clicking to it in a
              Searchandising, it is counted as two items.
            </li>
            <li>
              <strong>Average Order Value:</strong> Shows Personalised
              Searchandising’ average amount of contribution to an order
            </li>
            <li>
              <strong>Conversion Rate:</strong> The percentage of products that
              have been purchased after being clicked in a Personalised
              Searchandising.
            </li>
            <li>
              <strong>Contribution Ratio:</strong> Shows the ratio between
              Searchandising’ contribution and the overall revenue.
              It is calculated by Revenue of the Searchandising
              divided by Overall Revenue.
            </li>
          </ul>
        </div>
      )
    },
    assistedRevenue: {
      title: "Assisted Revenue",
      content: () => (
        <div>
          <p>
            This box shows the statistics about products that are purchased
            through Searchandising and the effect of those
            purchases over your website’s overall revenue.
          </p>
          <ul>
            <li>
              <strong>Assisted Revenue:</strong> Shows the total monetary amount
              after using the Searchandising. Segmentify only
              tracks the products clicked after searching any keyword from the
              Searchandising in the same session.
            </li>
            <li>
              <strong>Purchases:</strong> Shows the number of purchases that
              include after using Searchandising.
            </li>
            <li>
              <strong>Purchased Products:</strong> Shows the total number of
              products that are sold after using Searchandising. If
              a visitor buys two of the same product after using Personalised
              Searchandising, it is counted as two items.
            </li>
            <li>
              <strong>Average Order Value:</strong> Shows Segmentify
              Recommendation Widgets’ average amount of contribution to an
              order.
            </li>
            <li>
              <strong>Conversion Rate:</strong> The percentage of products that
              have been purchased after being clicked in a Segmentify
              Recommendation widget.
            </li>
            <li>
              <strong>Contribution Ratio:</strong> Shows the ratio between
              Segmentify Searchandising assisted contribution and
              the overall revenue. It is calculated by Assisted Revenue divided
              by Overall Revenue. Expected value for contribution ratio is
              between 7% to 12%.
            </li>
          </ul>
        </div>
      )
    }
  };
};
