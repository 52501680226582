// POP-UP template

const htmlTemplate = '<div class="seg-popup-overlay"></div>\n' +
  '<div class="seg-popup seg-flexCol-center seg-countdown-popup seg-[[vertical]]-[[horizontal]] segFadeInUp" style="background-color:[[bgColor]]">\n' +
  ' <a href="[[targetUrl]]" class="seg-countdown-popup-link-[[instanceId]]" target="_blank">\n' +
  '      <img class="seg-countdown-img" src="[[imageUrl]]" alt="[[title]]">\n' +
  '</a>\n' +
  '<h1 style="color:[[textColor]]" class="seg-popup-countdown-title seg-helper-z-index">[[title]]</h1>\n' +
  '    <div class="seg-hero-countdown-content seg-flexCol-center seg-gutter-12">\n' +
  '      <p style="color:[[textColor]]">[[description]]</p>\n' +
  '    </div>\n' +
  '<div class="seg-popup-countdown-container">\n' +
  '  <div class="seg-hero-banner seg-hero-popup seg-flexCol-center">\n' +
  '       <div class="seg-countdown-timeWrapper seg-flexRow-center seg-gutter-20 seg-helper-z-index">\n' +
  '      </div>\n' +
  '    </div>\n' +
  '   </div>\n' +
  '   <span class="seg-popup-countdown-close"></span>\n' +
  '</div>\n';

const preJSTemplate =
  '// this function will get a configuration object to be executed at html rendering with mustache.js\n' +
  '// conf object has two pre-added objects:\n' +
  '//   - title: name of the recommendation widget\n' +
  '//   - products: products array to be used at recommendation widget\n' +
  '// do not change the function name\n' +
  'function preRenderConf(conf) {\n' +
  '  // add your custom code inside the function\n' +
  '}';
const postJSTemplate = '';
const cssTemplate = '';

// HERO template

const htmlHEROTemplate =
  ' <div class="seg-hero-banner seg-hero-countdown seg-flexCol-center" style="background-color:[[bgColor]]">\n' +
  '        <a class="seg-hero-banner-link-[[instanceId]] seg-hero-countdown-link" href="[[targetUrl]]">\n' +
  '            <img class="seg-countdown-img" src="[[imageUrl]]" alt="[[title]]">\n' +
  '        </a>\n' +
  '        <div class="seg-helper-z-index seg-gutter-40 seg-flexCol-center">\n' +
  '        <h1 style="color:[[textColor]]" class="seg-popup-title seg-helper-z-index">[[title]]</h1>\n' +
  '            <div class="seg-hero-countdown-content seg-flexCol-center seg-gutter-12">\n' +
  '              <p style="color:[[textColor]]">[[description]]</p>\n' +
  '            </div>\n' +
  '         </div>\n' +
  '        <div class="seg-hero-banner seg-hero-popup seg-flexCol-center">\n' +
  '               <div class="seg-countdown-timeWrapperH seg-flexRow-center seg-gutter-20 seg-helper-z-index">\n' +
  '              </div>\n' +
  '            </div>\n' +
  '        </div>\n' +
  '    </div>\n';

const preJSHEROTemplate =
  '// this function will get a configuration object to be executed at html rendering with mustache.js\n' +
  '// conf object has two pre-added objects:\n' +
  '//   - title: name of the recommendation widget\n' +
  '//   - products: products array to be used at recommendation widget\n' +
  '// do not change the function name\n' +
  'function preRenderConf(conf) {\n' +
  '  // add your custom code inside the function\n' +
  '}';
const postJSHEROTemplate = '';
const cssHEROTemplate = '';

// Notification template
const htmlNotificationTemplate = `<div class="seg-notification-bar seg-notification-bar-v2 seg-flexRow-center seg-countdown seg-gutter-40 [[divClass]]" style="background-color:[[bgColor]]">
  <a href="[[targetUrl]]" class="seg-countdown-notification-link" target="_blank">
    [[#imageUrl]]
    <img class="seg-countdown-img" src="[[imageUrl]]" alt="[[title]]">
    [[/imageUrl]]
  </a>
  <div class="seg-helper-z-index"><h1 style="color:[[textColor]]" class="seg-popup-title seg-helper-z-index">[[title]]</h1></div>
  <div class="seg-countdown-timeWrapperN seg-flexRow-center seg-gutter-20"> </div>
</div>`;

const preJSNotificationTemplate =
  '// this function will get a configuration object to be executed at html rendering with mustache.js\n' +
  '// conf object has two pre-added objects:\n' +
  '//   - title: name of the recommendation widget\n' +
  '//   - products: products array to be used at recommendation widget\n' +
  '// do not change the function name\n' +
  'function preRenderConf(conf) {\n' +
  '  // add your custom code inside the function\n' +
  '}';
const postJSNotificationTemplate = '';
const cssNotificationTemplate = '';

export {
  htmlTemplate,
  preJSTemplate,
  postJSTemplate,
  cssTemplate,
  htmlHEROTemplate,
  preJSHEROTemplate,
  postJSHEROTemplate,
  cssHEROTemplate,
  htmlNotificationTemplate,
  preJSNotificationTemplate,
  postJSNotificationTemplate,
  cssNotificationTemplate,
};
