import {
  inclusionOrExclusionConverterForBackEnd,
  inclusionOrExclusionConverterForFrontEnd,
} from '../../../../components/sfy-components/Filters/utils/inclusion-exlusion-converter';

export const ConvertForFrontend = requestResponse => {
  const { isVariantsEnabled } = requestResponse;
  let { mainItem, subItems } = requestResponse;
  if (requestResponse?.mainItem?.type === 'category') {
    mainItem = {
      ...requestResponse.mainItem,
      params: {
        inclusions: inclusionOrExclusionConverterForFrontEnd(
          requestResponse.mainItem?.params?.inclusions,
          requestResponse.mainItem?.index,
        ),
        exclusions: inclusionOrExclusionConverterForFrontEnd(
          requestResponse.mainItem?.params?.exclusions,
          requestResponse.mainItem?.index,
        ),
      },
    };
  }
  if (requestResponse?.subItems?.length > 0) {
    subItems = requestResponse.subItems.map(subItem => {
      if (subItem?.type === 'category') {
        return {
          ...subItem,
          params: {
            inclusions: inclusionOrExclusionConverterForFrontEnd(
              subItem?.params?.inclusions,
              subItem?.index,
            ),
            exclusions: inclusionOrExclusionConverterForFrontEnd(
              subItem?.params?.exclusions,
              subItem?.index,
            ),
          },
        };
      }
      return subItem;
    });
  }

  return {
    id: requestResponse.id,
    isVariantsEnabled,
    mainItem,
    subItems,
  };
};

export const ConvertForBackend = ({
  id = '',
  isVariantsEnabled = false,
  mainItem,
  subItems,
}) => {
  let newMainItem = mainItem;
  let newSubItems = subItems;
  if (mainItem?.type === 'category') {
    newMainItem = {
      ...mainItem,
      params: {
        inclusions: inclusionOrExclusionConverterForBackEnd(
          mainItem?.params?.inclusions,
        ),
        exclusions: inclusionOrExclusionConverterForBackEnd(
          mainItem?.params?.exclusions,
        ),
      },
    };
  }
  if (subItems?.length > 0) {
    newSubItems = subItems.map(subItem => {
      if (subItem?.type === 'category') {
        return {
          ...subItem,
          params: {
            inclusions: inclusionOrExclusionConverterForBackEnd(
              subItem?.params?.inclusions,
            ),
            exclusions: inclusionOrExclusionConverterForBackEnd(
              subItem?.params?.exclusions,
            ),
          },
        };
      }
      return subItem;
    });
  }

  const returnValue = {
    isVariantsEnabled,
    mainItem: newMainItem,
    subItems: newSubItems,
  };

  if (id) {
    returnValue.id = id;
  }

  return returnValue;
};
