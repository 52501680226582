export const dateConverter = value => {
  if (!value) return 'No Date Provided';
  const date = new Date(value);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${day}.${month}.${year} ${hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
};
