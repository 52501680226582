import moment from 'moment';

import { createReducer } from '../system/store';
import * as switchUserActions from '../constants/actions/switchUser';

const initialState = {
  switchedUser: {
    displayName: '',
    account: {
      mainCurrency: {},
      mainRegion: '',
      additionalCurrencyRegionList: [],
      analytics: {},
      recommendationSettings: {
        sliderClick: {},
      },
      contact: {
        person: '',
        phone: '',
        address: '',
      },
      domain: '',
      features: {
        trendify: '',
        bannerify: '',
        recommendation: '',
        email: '',
        push: '',
        search: '',
        segmentation: '',
        personalization: '', // engagement
        personalisation: '',
        promotion: '',
        weeklyReport: '',
        dataLayer: '',
        problems: '',
        priceDrop: '',
        keyword: '',
        hidden: '',
        bundle: '',
      },
      industry: '',
    },
    language: '',
    username: '',
    privileges: [],
  },
  integrations: {},
};

export default createReducer(initialState, {
  [switchUserActions.default.UPDATE_SWITCH_USER]: (state, payload) => {
    /** TODO: this is a workaround, reducers MUST NOT contain unrelated code.
     * but we are doing this here cause this reducers is being called from two different places:
     * src/actions/user.js
     * src/actions/switchUser.js
     * And from more different places
     */
    const { account } = payload.user;
    if (account && account.timeZone) moment.tz.setDefault(account.timeZone);
    return { ...state, switchedUser: payload.user };
  },

  [switchUserActions.default.RESET_MAIL_CONF_IN_SWITCHED]: state => {
    const cloneState = {
      ...state,
    };

    cloneState.switchedUser.account.mailConfiguration = null;
    return cloneState;
  },

  [switchUserActions.default.UPDATE_MAIL_CONF_IN_SWITCHED]: (
    state,
    payload,
  ) => {
    const cloneState = {
      ...state,
    };

    cloneState.switchedUser.account.mailConfiguration = payload.conf;
    return cloneState;
  },
  [switchUserActions.default.UPDATE_REGION_IN_SWITCHED]: (state, payload) => {
    return {
      ...state,
      switchedUser: {
        ...state.switchedUser,
        account: {
          ...state.switchedUser.account,
          mainRegion: payload.mainRegion,
          additionalCurrencyRegionList: payload.additionalCurrencyRegionList,
        },
      },
    };
  },
});
