import React, { useEffect } from 'react';
import SingleFacetedSearchReport from 'components/search/faceted-search/report/SingleFacetedSearchReport';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllFacetedReports,
  getFacetedSearchList,
} from 'actions/faceted-search';
import moment from 'moment';
import FacetedReportsSumReport from 'components/search/faceted-search/report/FacetedReportsSumReport';

const AllFacetedSearchReports = () => {
  const {
    date,
    facetedSearch: { campaigns },
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const getStartAndEndDate = () => {
    return {
      start: moment(date.range[0]).format('YYYY-MM-DD'),
      end: moment(date.range[1]).format('YYYY-MM-DD'),
    };
  };
  useEffect(() => {
    dispatch(getFacetedSearchList());
  }, []);
  useEffect(
    () => {
      const selectedDate = getStartAndEndDate();
      if (campaigns.length > 0) {
        const instanceList = campaigns.map(c => c.instanceId);
        if (instanceList) {
          dispatch(getAllFacetedReports(selectedDate.start, selectedDate.end));
        }
      }
    },
    [campaigns],
  );
  return (
    <div>
      <FacetedReportsSumReport />
      {campaigns.length > 0 &&
        campaigns.map(c => (
          <SingleFacetedSearchReport
            key={c.instanceId}
            hideFilters
            title={c.name}
            instanceId={c.instanceId}
          />
        ))}
    </div>
  );
};

export default AllFacetedSearchReports;
