import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'system/ui';
import Tooltip from 'components/tooltip';
import Icons from 'components/icons';
import { devices } from 'constants/datamaps/campaigns';
import Icon from 'components/icon';
import Currency from 'components/currency';
import { localeString, localeStringMoney } from 'system/string';
import { getAccountCurrency } from 'modules/auth/user';
import { uiActions } from 'actions';
import tooltipContents from 'components/search/faceted-search/report/tooltipContents';
import * as modals from 'constants/datamaps/search-modals';

export class SearchReportRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDevice: 'all',
      reportArray: [],
      revenue: 0,
    };
    this.obj = {};
    this.reportSections = [
      'search',
      'interaction',
      'basket',
      'revenue',
      // 'assistedRevenue', TODO this part commented out due to V3-9633 DO NOT REMOVE IT
    ];
    this.map = [
      {
        key: 'search',
        icon: 'view',
        text: {
          title: this.getSearchSectionTitle(),
          iconText: this.getSearchSectionIconText(),
        },
        mainValueAccessor: [this.getFirstMainValueAccessor()],
        stats:
          this.props.type === 'faceted'
            ? [
                {
                  text: 'Keyword Searches',
                  accessor: ['keyword'],
                },
                {
                  text: 'Next Pages',
                  accessor: ['page'],
                },
                {
                  text: 'Facet Selects',
                  accessor: ['filter'],
                },
                {
                  text: 'Change Ordering',
                  accessor: ['order'],
                },
              ]
            : [],
      },
      {
        key: 'interaction',
        icon: 'hand',
        text: {
          title: 'Interaction',
          iconText: 'clicks',
          bottomText: 'CTR',
        },
        mainValueAccessor: ['Click'],
        stats: [
          {
            key: 'ctr',
            text: 'CTR',
            isPercent: true,
            isBottom: true,
            // bölünen
            dividendAccessor: ['Click'],
            // bölen
            divisorAccessor: [this.getFirstMainValueAccessor()],
          },
        ],
      },
      {
        key: 'basket',
        icon: 'cart',
        text: {
          title: 'Basket',
          iconText: 'products',
          bottomText: 'Add to Basket Rate',
        },
        mainValueAccessor: ['Basket Items'],
        stats: [
          {
            text: 'Total Amount',
            accessor: 'Basket Amount',
            currency: true,
          },
          {
            key: 'basketRate',
            text: 'Add to Basket Rate',
            isBottom: true,
            isPercent: true,
            // bölünen
            dividendAccessor: ['Basket Items'],
            // bölen
            divisorAccessor: ['Click'],
          },
        ],
      },
      {
        key: 'revenue',
        currency: true,
        icon: 'try',
        text: {
          title: 'Revenue',
          iconText: '',
          bottomText: 'Contribution Rate',
        },
        mainValueAccessor: ['Purchase Amount'],
        stats: [
          {
            text: 'Purchases',
            accessor: 'Purchases',
          },
          {
            text: 'Purchased Products',
            accessor: 'Purchased Items',
          },
          {
            key: 'averageOrderContribution',
            text: 'Average Order Value',
            currency: true,
            isRatio: true,
            // bölünen
            dividendAccessor: ['Purchase Amount'],
            // bölen
            divisorAccessor: ['Purchases'],
          },
          {
            key: 'contributionRatio',
            text: 'Contribution Ratio',
            isPercent: true,
            isBottom: true,
            // bölünen
            dividendAccessor: ['Purchase Amount'],
            // bölen
            divisorAccessor: ['purchase:amount'],
          },
          {
            key: 'conversionRate',
            text: 'Conversion Rate',
            isPercent: true,
            // bölünen
            dividendAccessor: ['Purchases'],
            // bölen
            divisorAccessor: ['Click'],
          },
        ],
      },
      // { TODO this part commented out due to V3-9633 DO NOT REMOVE IT
      //   key: 'assistedRevenue',
      //   currency: true,
      //   icon: 'try',
      //   text: {
      //     title: 'Assisted Revenue',
      //     iconText: '',
      //     bottomText: 'Contribution Rate',
      //   },
      //   mainValueAccessor: ['AR Purchase Amount'],
      //   stats: [
      //     {
      //       text: 'Purchases',
      //       accessor: 'AR Purchases',
      //     },
      //     {
      //       text: 'Purchased Products',
      //       accessor: 'AR Purchased Items',
      //     },
      //     {
      //       key: 'averageOrderContribution',
      //       text: 'Average Order Value',
      //       currency: true,
      //       isRatio: true,
      //       // bölünen
      //       dividendAccessor: ['AR Purchase Amount'],
      //       // bölen
      //       divisorAccessor: ['AR Purchases'],
      //     },
      //     {
      //       key: 'contributionRatio',
      //       text: 'Contribution Ratio',
      //       isPercent: true,
      //       isBottom: true,
      //       // bölünen
      //       dividendAccessor: ['AR Purchase Amount'],
      //       // bölen
      //       divisorAccessor: ['purchase:amount'],
      //     },
      //     {
      //       key: 'conversionRate',
      //       text: 'Conversion Rate',
      //       isPercent: true,
      //       // bölünen
      //       dividendAccessor: ['AR Purchased Items'],
      //       // bölen
      //       divisorAccessor: ['Click'],
      //     },
      //   ],
      // },
    ];
  }

  getSearchSectionTitle = () => {
    let searchSectionTitle = '';
    switch (this.props.type) {
      case 'faceted':
        searchSectionTitle = 'Search';
        break;
      case 'all':
      case 'banner':
        searchSectionTitle = 'Total';
        break;
      default:
        searchSectionTitle = 'Widget';
        break;
    }
    return searchSectionTitle;
  };

  getSearchSectionIconText = () => {
    let searchSectionIconText = '';
    switch (this.props.type) {
      case 'faceted':
        searchSectionIconText = 'searches';
        break;
      case 'all':
      case 'banner':
        searchSectionIconText = 'widgets';
        break;
      default:
        searchSectionIconText = 'views';
        break;
    }
    return searchSectionIconText;
  };

  getFirstMainValueAccessor = () => {
    let firstMainValueAccessor = '';
    switch (this.props.type) {
      case 'faceted':
        firstMainValueAccessor = 'Facet Search';
        break;
      case 'all':
        firstMainValueAccessor = 'Total View';
        break;
      default:
        firstMainValueAccessor = 'Widget View';
        break;
    }
    return firstMainValueAccessor;
  };

  componentDidMount() {
    this.calculateReportStats(this.props);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data) ||
      this.props.purchaseAmount !== nextProps.purchaseAmount
    ) {
      this.calculateReportStats(nextProps);
    }
  }

  onDeviceChange(deviceName) {
    const selectedDevice =
      deviceName === 'PC' ? deviceName : deviceName.toLowerCase();
    this.setState(
      {
        selectedDevice,
      },
      () => this.calculateReportStats(this.props),
    );
  }

  calculateReportStats(props) {
    const { selectedDevice } = this.state;
    const { instanceId } = props;
    const copyArray = instanceId
      ? props.data[instanceId]
        ? [...props.data[instanceId]]
        : []
      : [...props.data];
    let filteredStats;
    if (selectedDevice === 'all') {
      filteredStats = Array.from(
        copyArray.reduce(
          (m, { x, y }) => m.set(x, (m.get(x) || 0) + y),
          new Map(),
        ),
        ([x, y]) => ({ x, y }),
      );
    } else {
      filteredStats = Array.from(
        copyArray
          .filter(
            obj => obj.z === selectedDevice || obj.x === 'purchase:amount',
          )
          .reduce((m, { x, y }) => m.set(x, (m.get(x) || 0) + y), new Map()),
        ([x, y]) => ({ x, y }),
      );
    }
    const purchaseAmount = filteredStats
      .filter(d => d.x === 'Purchase Amount')
      .map(d => d.y)
      .reduce((a, b) => a + b, 0);

    this.setState({
      reportArray: filteredStats,
      revenue: purchaseAmount,
    });
  }

  printStats(stat) {
    if (stat.key === 'empty') {
      return null;
    }
    const { title } = this.props;
    let currentSubStatValue = 0;
    if (stat.accessor) {
      // the value is sum of main value accessors for the selected section
      currentSubStatValue = this.state.reportArray
        .filter(d => {
          if (Array.isArray(stat.accessor)) {
            return stat.accessor.indexOf(d.x) > -1;
          }
          return stat.accessor === d.x;
        })
        .map(d => d.y)
        .reduce((a, b) => a + b, 0);
    } else if (stat.isPercent || stat.isRatio) {
      let dividendSum = this.state.reportArray
        .filter(d => stat.dividendAccessor.indexOf(d.x) > -1)
        .map(d => d.y)
        .reduce((a, b) => a + b, 0);

      let divisorSum = this.state.reportArray
        .filter(d => stat.divisorAccessor.indexOf(d.x) > -1)
        .map(d => d.y)
        .reduce((a, b) => a + b, 0);

      /*
       * contribution ratio specific code
       * !!! needs to be pay attention while changing this.map !!!
       * */
      if (stat.key === 'contributionRatio' && this.props.purchaseAmount) {
        divisorSum = this.props.purchaseAmount;
      }

      if (stat.isPercent) {
        if (divisorSum === 0 && dividendSum > 0) {
          currentSubStatValue = 1;
        } else if (divisorSum === 0 && dividendSum === 0) {
          currentSubStatValue = 0;
        } else {
          dividendSum = dividendSum > divisorSum ? divisorSum : dividendSum;
          currentSubStatValue = dividendSum / divisorSum;
        }
        currentSubStatValue *= 100;
      } else if (stat.isRatio) {
        currentSubStatValue = divisorSum > 0 ? dividendSum / divisorSum : 0;
      }
    }

    if (stat.currency) {
      currentSubStatValue = localeStringMoney(
        currentSubStatValue,
        false,
        getAccountCurrency(),
      );
    } else {
      currentSubStatValue = localeString(currentSubStatValue);
    }
    const statClass = classnames('widget-stat-name', {
      'widget-stat-conversion-bottom':
        stat.isBottom && stat.key !== 'contributionRatio',
    });
    return (
      <p className={statClass} key={stat.text}>
        {stat.text}
        <span className='widget-stat-data'>
          <span className='user-currency'>
            {stat.currency && <Currency />}
            <span className='user-currency-amount' id={`${title}-${stat.text}`}>
              {currentSubStatValue}
              {stat.isPercent && '%'}
            </span>
          </span>
        </span>
      </p>
    );
  }

  printColumn(key) {
    const section = this.map.find(m => m.key === key); // search, interaction, basket, revenue, assistedRevenue
    const { title } = this.props;
    const InfoTooltip = ({ modalKey }) => (
      <a
        className='modal-trigger'
        onClick={() => {
          const modalContent = modals[section.text.title];
          const modal = tooltipContents()[modalKey];
          uiActions.openModal({
            title:
              this.props.type === 'faceted' ? modal.title : section.text.title,
            content:
              this.props.type === 'faceted' ? modal.content : modalContent,
          });
        }}
      >
        <Icon name='info' />
      </a>
    );

    // move element to the last position of array which has isBottom property
    const isBottomIndex = section.stats.findIndex(s => s.isBottom);
    if (isBottomIndex > -1)
      section.stats.push(section.stats.splice(isBottomIndex, 1)[0]);

    // added one empty stat to prevent css bug, when there is only bottom stat exists
    if (isBottomIndex > -1 && section.stats.length === 1)
      section.stats.push({ key: 'empty' });

    // the value is sum of main value accessors for the selected section
    const currentValue = this.state.reportArray
      .filter(d => section.mainValueAccessor.indexOf(d.x) > -1)
      .map(d => d.y)
      .reduce((a, b) => a + b, 0);

    const statCount =
      key === 'revenue' || key === 'assistedRevenue'
        ? localeStringMoney(currentValue, false, getAccountCurrency())
        : localeString(currentValue);

    return (
      <div
        className={classnames(
          { 'view-all-faceted-widget-stats': this.props.viewAll },
          `widget-stat stat-${key}`,
        )}
        key={key}
      >
        {this.props.type !== 'all' && <InfoTooltip modalKey={key} />}
        <h4 className='widget-stat-title'>{section.text.title}</h4>
        <p
          className='widget-stat-count'
          id={`${title}-${section.text.title}`}
          title={currentValue}
        >
          {currentValue ? statCount : 0}
        </p>
        <p className='widget-stat-type'>
          {section.currency === true ? (
            <Currency />
          ) : (
            <Icon name={section.icon} />
          )}
          {section.text.iconText}
        </p>
        <p className='widget-stat-name' />
        {section.stats &&
          section.stats
            .filter(stat =>
              this.props.viewAll ? stat.key === 'contributionRatio' : true,
            )
            .map(stat => this.printStats(stat))}
      </div>
    );
  }

  render() {
    const { loaded, title, subtitle } = this.props;
    const WidgetTitle = () => (
      <div className='one-whole'>
        <h2 className='widget-title'>{title}</h2>
        {subtitle}
      </div>
    );
    const WidgetMeta = () => (
      <div className='widget-meta' style={{ marginTop: 10 }}>
        <span className='recommendation-device-selection'>
          <Tooltip
            content={t('All Devices')}
            alignment='top'
            newTooltip
            isInline
          >
            <span
              id={`${title}-all-devices`}
              key='all-devices'
              onClick={() => this.onDeviceChange('all')}
              className={classnames('widget-meta-mobile all-device-report', {
                'is-selected': this.state.selectedDevice === 'all',
              })}
            >
              <Icons name='allDevices' width='16' height='16' color='#bababa' />
              {t('ALL')}
            </span>
          </Tooltip>
          {this.props.devices.map(device => (
            <Tooltip
              key={device}
              content={t(devices[device.toUpperCase()])}
              alignment='top'
              newTooltip
              isInline
            >
              <span
                id={`${title}-${device}`}
                key={device}
                onClick={() => {
                  this.onDeviceChange(device);
                }}
                className={classnames('widget-meta-mobile', {
                  'is-selected':
                    this.state.selectedDevice ===
                    (device === 'PC' ? device : device.toLowerCase()),
                })}
              >
                <Icon name={device.toLowerCase()} />
              </span>
            </Tooltip>
          ))}
        </span>
        {this.props.viewAll && (
          <>
            <br />
            <small>({this.props.instanceId})</small>
          </>
        )}
      </div>
    );
    const Separator = () => <hr className='widget-separator' />;
    const ViewAllSections = () => (
      <>
        <WidgetMeta />
        <Separator />
        <div className='widget-stat-list'>
          <p>Last 7 Days</p>
          <div className='widget-stats'>
            {this.reportSections.map(key => this.printColumn(key))}
          </div>
          <span className='widget-amount'>
            <Currency currency={this.state.revenue} />
          </span>
        </div>
      </>
    );
    const MainFrame = () => (
      <div className='page-content-block' style={{ marginBottom: 10 }}>
        <div className='widget-stat-list widget-report'>
          <WidgetTitle />
          <WidgetMeta />
          <Separator />
          <div className='widget-stats'>
            {this.reportSections.map(key => this.printColumn(key))}
          </div>
        </div>
      </div>
    );
    return (
      <div>
        {loaded && (!this.props.viewAll ? <MainFrame /> : <ViewAllSections />)}
      </div>
    );
  }
}

SearchReportRow.defaultProps = {
  instanceId: null,
  title: '',
  subtitle: '',
  viewAll: false,
  devices: ['PC', 'MOBILE', 'TABLET'],
};

SearchReportRow.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  viewAll: PropTypes.bool,
  data: PropTypes.instanceOf(Array).isRequired,
  instanceId: PropTypes.string,
  devices: PropTypes.instanceOf(Array),
  purchaseAmount: PropTypes.number,
};
