import React, { useContext } from 'react';
import './GCSearchResultsIndexList.scss';
import { Context as GlobalConfigurationContext } from '../../../../../views/searchandising/global-configuration/store/globalConfigurationProvider';
import { fixedInputtedNumber } from '../common/common-functions';
import Tooltip from '../../../../tooltip';
import Icons from '../../../../icons';

/**
 * @name GCSearchResultsIndexList
 * @description List of Search Results Index
 * @param data {Object} - List of Search Results Index
 * @param data.searchResultsIndex {Object} - List of Search Results Index
 * @param actionHandler {function} - Action handler
 * @return {JSX.Element}
 * @constructor
 */
const GCSearchResultsIndexList = ({ data, actionHandler }) => {
  const { editSearchResultsIndex } = useContext(GlobalConfigurationContext);

  const onValueChange = (type, item, value) => {
    const newItem = { ...item };
    if (type === 'weight') {
      newItem.weight = fixedInputtedNumber(1, 5, value);
    }

    if (type === 'status') {
      newItem.status = value;
    }

    editSearchResultsIndex(newItem);
  };

  return (
    <div className='gc-search-results-list'>
      {data.map(item => {
        return (
          <div className='gc-search-results-list-item' key={item.index}>
            {item.facetField && (
              <div className='gc-search-results-list-item-facet-field'>
                <div className='item-facet-holder'>
                  <span className='item-facet-text'>FACET</span>
                </div>
              </div>
            )}
            <label
              className={`gc-search-results-list-item-status is-toggle ${item.status?.toLowerCase()}`}
            >
              <input
                type='checkbox'
                checked={item.status === 'ACTIVE'}
                onClick={() =>
                  onValueChange(
                    'status',
                    item,
                    item.status === 'ACTIVE' ? 'PASSIVE' : 'ACTIVE',
                  )
                }
                disabled={item.fieldName === 'productId'}
              />
              <span className='item-label' />
            </label>
            <div className='gc-search-results-list-item-name'>
              <span className='item-name-text'>{item.fieldName}</span>
              {item?.info && (
                <Tooltip content={item.info} placement='top' alignment='center'>
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              )}
            </div>
            <div
              className={`gc-search-results-list-item-weight ${item.status?.toLowerCase()}`}
            >
              <span className='gc-search-results-list-item-weight-label'>
                Weight :
              </span>
              <input
                placeholder='Type Weight'
                type='number'
                className='gc-search-results-list-item-weight-value gc-weight-text-input'
                value={item.status === 'ACTIVE' ? item.weight : ''}
                onChange={e => onValueChange('weight', item, e.target.value)}
                disabled={
                  item.status === 'PASSIVE' || item.fieldName === 'productId'
                }
              />
            </div>
            {!item.isDefaultField && (
              <div
                className={`gc-search-results-list-item-actions ${item.status?.toLowerCase()}`}
              >
                <div
                  className='gc-search-results-list-item-actions-item'
                  onClick={() => actionHandler('edit', item)}
                >
                  <Icons
                    name='editRowPencil'
                    color='#547494'
                    height='20'
                    width='20'
                  />
                </div>
                <div
                  className='gc-search-results-list-item-actions-item'
                  onClick={() => actionHandler('delete', item)}
                >
                  <Icons
                    name='trashBin'
                    color='#D75252'
                    height='20'
                    width='20'
                  />
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default GCSearchResultsIndexList;
