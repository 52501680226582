import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getAccount, getAccountCurrency } from '../../modules/auth/user';
import { getRandomProduct } from '../../modules/product/ajax';
import { t } from '../../system/ui';
import Icons from '../icons';
import ReactPlaceholder from '../shared/react-placeholder';

class PreviewModalContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 'first',
      randomProduct: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.NotificationOptions = {};

    this.getProduct();
  }

  getProduct() {
    getRandomProduct(response => {
      if (response) {
        this.setState({
          randomProduct: response,
        });
      }
    });
  }

  processTitle = title =>
    title
      ? title.replace('{{product:name}}', this.state.randomProduct.name)
      : '';

  buildOptionsByStep(step) {
    const selected = this.props.abandon[step];
    const account = getAccount();
    const { defaultIcon } = account.pushConfiguration;

    const calculateBody = () => {
      const hideInfo = this.props.abandon?.hideInformation;
      const hidePrc = this.props.abandon?.hidePrice;
      if (hideInfo) return '';
      if (hidePrc) return this.state?.randomProduct?.name;
      return `${this.state?.randomProduct?.name}   ${this.state?.randomProduct?.priceText}`;
    };

    const options = {
      body: calculateBody(),
    };

    const { selectedLanguage } = selected;
    const { wizardLanguage } = this.props;

    let titleKeyLanguage;
    if (wizardLanguage === 'SESSION_BASED') {
      if (selectedLanguage) {
        titleKeyLanguage = selectedLanguage;
      } else {
        [titleKeyLanguage] = Object.keys(selected.additionalTitles);
      }
    } else {
      titleKeyLanguage = wizardLanguage;
    }

    options.title =
      selected.additionalTitles[titleKeyLanguage].replace(
        '{{coupon_code}}',
        selected.couponCode,
      ) || '';
    if (step !== 'COUPON') {
      options.title = this.processTitle(options.title);
    }

    if (selected.image && selected.image !== '{{product:image}}') {
      options.image = selected.image;
    }

    if (selected.icon && selected.icon !== '{{push:icon}}') {
      options.icon = selected.icon;
    }

    if (selected.image === '{{product:image}}') {
      options.image = this.state.randomProduct.image;
    }

    if (selected.icon && selected.icon === '{{product:icon}}') {
      options.icon = this.state.randomProduct.image;
    }
    if (selected.icon && selected.icon === '{{push:icon}}') {
      options.icon = defaultIcon;
    }
    if (!selected.icon) {
      options.icon = this.state.randomProduct.image;
    }

    let redUrl = '';
    if (step === 'FIRST_REMINDER' || step === 'SECOND_REMINDER') {
      redUrl = account?.cartUrl || this.state?.randomProduct?.url;
    } else if (step === 'ALTERNATIVE_PRODUCTS') {
      redUrl = this.state?.randomProduct?.url;
    } else {
      // COUPON option
      redUrl = `${selected.target}?utm_source=segmentify&utm_campaign=ABANDONED_CART_COUPON&utm_content=static&utm_medium=push&_sgm_coupon=${selected.couponCode}&_sgf_campaign_name=Abandoned+Cart`;
    }

    options.data = {};
    options.data.url = redUrl || '';
    if (selected.actions.length > 0) {
      options.actions = selected.actions;
      options.data.actionUrls = selected.actions.map(item => item.redirectUrl);
    }

    return options;
  }

  changeStep(step) {
    this.setState({
      step,
    });

    this.NotificationOptions = this.buildOptionsByStep(step);
  }

  displayNotification(options) {
    if (Notification.permission === 'granted') {
      navigator.serviceWorker.getRegistration().then(reg => {
        reg.showNotification(options.title, options);
      });
    }
  }

  register() {
    const _this = this;
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker
        .register('/sw.js')
        .then(function(swReg) {
          _this.swRegistration = swReg;
          _this.initializeUI();
          _this.displayNotification(_this.NotificationOptions);
        })
        .catch(function(error) {
          console.error('Service Worker Error', error);
        });
    } else {
      console.warn('Push messaging is not supported');
    }
  }

  initializeUI() {
    const _this = this;
    // Set the initial subscription value
    this.swRegistration.pushManager
      .getSubscription()
      .then(function(subscription) {
        const isSubscribed = !(subscription === null);

        if (isSubscribed) {
        } else {
          _this.setState({
            notSubcribed: true,
          });
        }
      });
  }

  handleClick() {
    this.register();
  }

  render() {
    const rowStyle = {
      cursor: 'pointer',
      margin: '5px',
    };

    const btnStyle = {
      backgroundColor: '#63ca63',
      padding: '10px',
      color: '#fff',
      float: 'right',
    };

    return (
      <div>
        <div>Push Flow Steps</div>
        {this.state.notSubcribed && (
          <div style={{ color: 'red' }}>
            Please accept push notifications...
          </div>
        )}
        <ReactPlaceholder isLoading={!this.state.randomProduct}>
          <div>
            {this.props.abandon.FIRST_REMINDER.active && (
              <div
                onClick={() => this.changeStep('FIRST_REMINDER')}
                style={rowStyle}
              >
                <Icons
                  name={
                    this.state.step === 'FIRST_REMINDER'
                      ? 'radioSelected'
                      : 'radioEmpty'
                  }
                  width={14}
                  height={14}
                />{' '}
                {t('First Reminder')}
              </div>
            )}
            {this.props.abandon.SECOND_REMINDER.active && (
              <div
                onClick={() => this.changeStep('SECOND_REMINDER')}
                style={rowStyle}
              >
                <Icons
                  name={
                    this.state.step === 'SECOND_REMINDER'
                      ? 'radioSelected'
                      : 'radioEmpty'
                  }
                  width={14}
                  height={14}
                />{' '}
                {t('Second Reminder')}
              </div>
            )}
            {this.props.abandon.ALTERNATIVE_PRODUCTS.active && (
              <div
                onClick={() => this.changeStep('ALTERNATIVE_PRODUCTS')}
                style={rowStyle}
              >
                <Icons
                  name={
                    this.state.step === 'ALTERNATIVE_PRODUCTS'
                      ? 'radioSelected'
                      : 'radioEmpty'
                  }
                  width={14}
                  height={14}
                />{' '}
                {t('Alternative Products')}
              </div>
            )}
            {this.props.abandon.COUPON.active && (
              <div onClick={() => this.changeStep('COUPON')} style={rowStyle}>
                <Icons
                  name={
                    this.state.step === 'COUPON'
                      ? 'radioSelected'
                      : 'radioEmpty'
                  }
                  width={14}
                  height={14}
                />{' '}
                {t('Coupon')}
              </div>
            )}
          </div>
        </ReactPlaceholder>
        <a href style={btnStyle} className='button' onClick={this.handleClick}>
          {t('Preview')}
        </a>
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  editForm: store.wizard.editForm,
  abandon: store.abandon,
  wizardLanguage: store.wizard.wizardForm.language,
});

export default connect(MapStatesToProps)(PreviewModalContent);
