"use strict";

export const localItems = [
  'logged',
  'restUrl',
  'account',
  'isLocal',
  'currency',
  'widget-type',
  'integrations',
  'externalNav',
  'externalMenu',
  'externalApp',
  '_sgf_dc',
];
