import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { monitorTable } from '../../../../constants/datamaps/account';
import Icon from '../../../../components/icon';
import { openModal, t } from '../../../../system/ui';
import * as modals from '../../../../constants/datamaps/modals';

/**
 * @name StatsHeader
 * @description StatsHeader component that renders monitoring statistics headers
 * @return {JSX.Element}
 * @constructor
 */

const StatsHeader = () => {
  const showModal = e => {
    e.preventDefault();

    openModal({
      title: 'Performance',
      content: modals.Performance,
    });
  };

  const showGAModal = e => {
    e.preventDefault();

    openModal({
      title: 'GA Difference',
      content: modals.gaDiff,
    });
  };

  return (
    <thead>
      <tr>
        {monitorTable.header.map(item => {
          let { text } = item;

          if (item.icon) {
            text = <Icon name={item.icon} />;
          }

          if (item.sortable) {
            text = (
              <span>
                {item.tooltip && (
                  <a onClick={text === 'Performance' ? showModal : showGAModal}>
                    <Icon name='info' />
                  </a>
                )}
                <a>{t(text)}</a>
              </span>
            );
          }

          return <th key={uuidv4()}>{t(text)}</th>;
        })}
      </tr>
    </thead>
  );
};

export default StatsHeader;
