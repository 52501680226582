import Services from '../../../../service/Services';

class CountdownServices extends Services {
  constructor({ endPoint, paginationIsActive }) {
    super({ endPoint, paginationIsActive });
  }
}

const CountdownService = (
  endPoint = 'v2/campaign',
  paginationIsActive = false,
) => {
  return new CountdownServices({
    endPoint,
    paginationIsActive,
  });
};

export { CountdownService };
