import React from "react";
import { clone } from "system/object";
import Fields from "modules/campaign/fields";
import { initialState } from "constants/datamaps/palantir";

export default class SearchBoxSettings extends React.Component {
  constructor(props) {
    super(props);
  }

  getUpdatedCampaign = () => {
    const campaign = clone(this.props.campaign);
    const campaignProps = {};
    const contentOptions = this.refs.campaignOptions;

    Object.keys(contentOptions.refs).forEach(ref => {
      campaignProps[ref] = contentOptions.refs[ref];
    });

    campaign.searchDelay = campaignProps.delay.state.value;
    campaign.minCharacterCount = campaignProps.minChar.state.value;
    campaign.searchUrlPrefix = campaignProps.urlPrefix.state.value;
    campaign.searchInputSelector = campaignProps.inputSelector.state.value;
    campaign.hideCurrentSelector = campaignProps.currentSelector.state.value;
    campaign.triggerSelector = campaignProps.triggerSelector.state.value;
    campaign.openingDirection = campaignProps.openingDirection.state.value;
    campaign.forceRemoveInputVal =
      campaignProps.forceRemoveInputVal.state.value;
    campaign.instantIncludeOutOfStock =
      campaignProps.outOfStockInputVal.state.value;
    campaign.useInStockInOrderForInstant =
      campaignProps.useInStockInOrderForInstant.state.value;
    campaign.clearInputBindingsOnSite =
      campaignProps.clearInputBindingsOnSite.state.value;
    campaign.shadowRootElements =
      campaignProps.inputSelectorShadowRoots.state.selectors;
    campaign.shadowRootElementsForHide =
      campaignProps.hideSelectorShadowRoots.state.selectors;
    campaign.shadowRootElementsForTrigger =
      campaignProps.manuelSelectorShadowRoots.state.selectors;
    campaign.activateProductIdAndCategoryAndBrandRedirection =
      campaignProps?.activateProductIdAndCategoryAndBrandRedirection?.state?.value;
    campaign.rockScoreFrameType = campaignProps?.rockScoreFrameType.state.value;

    return campaign;
  };

  render() {
    const { campaign } = this.props;
    return (
      <ol className='form-elements'>
        <li>
          <Fields
            ref='campaignOptions'
            campaign={campaign}
            comps={initialState.settings}
          />
        </li>
      </ol>
    );
  }
}
