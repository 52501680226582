import React from 'react';
import PropTypes from 'prop-types';

import CriteriaGroupItem from './CriteriaGroupItem';
import { removeEmptyCriteriaAndGroups } from '../utils/utils';

const CriteriaDetail = ({ criteriaData }) => {
  const clearedCriteriaData = removeEmptyCriteriaAndGroups(criteriaData);

  return (
    <div className='rbs-preview-detail'>
      {clearedCriteriaData?.criteriaGroups?.map((groupItem, groupIndex) => (
        <CriteriaGroupItem
          // eslint-disable-next-line react/no-array-index-key
          key={criteriaData.operator + ' ' + groupIndex}
          criteriaData={criteriaData}
          groupItem={groupItem}
          groupIndex={groupIndex}
        />
      ))}
    </div>
  );
};

CriteriaDetail.propTypes = {
  criteriaData: PropTypes.shape({
    operator: PropTypes.string,
    criteriaGroups: PropTypes.arrayOf({}),
  }).isRequired,
};

export default CriteriaDetail;
