import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { t } from 'system/ui';
import Icons from 'components/icons';
import Icon from 'components/icon';
import { modalActions, uiActions } from 'actions';
import CampaignHistoryModal from 'modules/campaigns/historymodal';
import { browserHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { archiveCampaign } from 'actions/faceted-search';
import { isSuperUser } from 'modules/auth/user';
import CreateDraftModal from './CreateDraftModal';

const FacetedSearchActions = ({ campaign, getCampaignsAfterStatusChange }) => {
  const dispatch = useDispatch();
  const menuRef = useRef(null);
  const [menuDirection, setMenuDirection] = useState('down');
  const handleScroll = () => {
    const partOfDocHeight = (document.documentElement.offsetHeight * 70) / 100;
    const listTop =
      menuRef.current && menuRef.current.getBoundingClientRect().top;
    setMenuDirection(listTop > partOfDocHeight ? 'up' : 'down');
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const menuActionFunctions = {
    history() {
      const campaignHistoryModal = () => (
        <CampaignHistoryModal
          campaignId={campaign.instanceId}
          isFacetedSearch
        />
      );
      modalActions.openModal({
        title: `Campaign History (${campaign.name})`,
        content: campaignHistoryModal,
      });
    },
    edit() {
      browserHistory.push(`/search/edit/faceted/${campaign.instanceId}`);
    },
    createDraft() {
      modalActions.openModal({
        title: 'Draft Version Name',
        content: () => <CreateDraftModal instanceId={campaign.instanceId} />,
      });
    },
    report() {
      browserHistory.push(
        `/search/faceted/single-report/${campaign.instanceId}`,
      );
    },
    passive() {
      uiActions.confirmationDialog({
        title: 'Are you sure to make the campaign passive?',
        onConfirm: () => {
          dispatch(
            archiveCampaign(campaign.instanceId, () => {
              if (getCampaignsAfterStatusChange)
                getCampaignsAfterStatusChange();
            }),
          );
        },
      });
    },
  };
  const menuActions = [
    { name: 'Edit', icon: 'gear', action: menuActionFunctions.edit },
    {
      name: 'Create Draft Version',
      icon: 'duplicate',
      action: menuActionFunctions.createDraft,
    },
    { name: 'Report', icon: 'pie-chart', action: menuActionFunctions.report },
    {
      name: 'Passive',
      icon: 'archive',
      action: menuActionFunctions.passive,
      superUserOnly: true,
    },
    {
      name: 'History',
      icon: 'history',
      iconSvg: true,
      action: menuActionFunctions.history,
    },
  ];
  const isDropUpClass = menuDirection === 'up';
  return (
    <nav
      id='faceted-search-actions'
      className='widget-nav action-nav'
      aria-haspopup='true'
    >
      <a className='secondary-action'>{t('Actions')}</a>
      <ul
        className={`widget-nav-popup action-nav-popup ${
          isDropUpClass ? 'drop-up' : ''
        }`}
      >
        {menuActions.map(item => {
          if (
            (!isSuperUser() && item.superUserOnly) ||
            (item.name.toLowerCase() === 'passive' &&
              campaign.status.toLowerCase() === 'passive')
          ) {
            return null;
          }
          return (
            <li key={item.action}>
              <a
                ref={menuRef}
                className='widget-nav-popup-item action-nav-popup-item'
                onClick={() => item.action()}
              >
                {item.iconSvg ? (
                  <Icons name={item.icon} color='#fff' />
                ) : (
                  <Icon name={item.icon} />
                )}
                {t(item.name)}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

FacetedSearchActions.defaultProps = {
  getCampaignsAfterStatusChange: null,
};

FacetedSearchActions.propTypes = {
  campaign: PropTypes.instanceOf(Object).isRequired,
  getCampaignsAfterStatusChange: PropTypes.func,
};

export default FacetedSearchActions;
