import React, { useState, useEffect } from 'react';
import classes from './IysSettingsSyncModal.module.scss';
import { closeModal } from '../../../../actions/modal';
import Icons from '../../../../components/icons';
import { isLoading, isLoaded, showNotification } from '../../../../actions/ui';
import {
  getIYSSettings,
  syncIYSSettings,
} from '../../../../modules/account/ajax';
import { t } from '../../../../system/ui';

const IysSettingsSyncModal = () => {
  const [consentNumbers, setConsentNumbers] = useState({
    smsCount: 0,
    emailCount: 0,
    callCount: 0,
  });
  useEffect(() => {
    getIYSSettings(response => {
      if (response) setConsentNumbers({ ...response });
    });
  }, [
    consentNumbers.smsCount,
    consentNumbers.emailCount,
    consentNumbers.callCount,
  ]);

  const handleSync = e => {
    isLoading();
    syncIYSSettings(response => {
      isLoaded();
      if (response) {
        const notificationContent = () => <div>{`${t(response)}`}</div>;
        showNotification({ content: notificationContent });
        closeModal();
      }
    });
  };

  return (
    <div className={classes['iys-settings-sync-modal']}>
      <div className={classes['iys-settings-sync-modal__header']}>
        <span className={classes['iys-settings-sync-modal__header__icon']}>
          <Icons name='People' />
        </span>
        <span className={classes['iys-settings-sync-modal__header__text']}>
          <h2>{t('İYS veri eşitleme yapılacak onaylıyor musunuz?')}</h2>
        </span>
      </div>
      <div className={classes['iys-settings-sync-modal__body']}>
        <p>
          {t(
            'İYS verisini ilk kez göndermek ya da toplu veri içe aktarımından sonra bu özelliği kullanın.',
          )}
        </p>
        <p>
          {t(
            `Aktarılacak veri miktarı: ${consentNumbers.emailCount} Mail, ${consentNumbers.smsCount} SMS, ${consentNumbers.callCount} Arama`,
          )}
        </p>
      </div>
      <div className={classes['iys-settings-sync-modal__buttons-row']}>
        <button
          type='button'
          className={classes['iys-settings-sync-modal__buttons-row__cancel']}
          onClick={() => closeModal()}
        >
          {t('Cancel')}
        </button>
        <button
          type='button'
          className={classes['iys-settings-sync-modal__buttons-row__accept']}
          onClick={() => handleSync()}
        >
          {t('Accept')}
        </button>
      </div>
    </div>
  );
};

export default IysSettingsSyncModal;
