/**
 * @fileoverview Helps to manage edit account states
 */

/**
 * @type {EditAccountState}
 */
export const initialState = {
  domain: '',
  person: '',
  email: '',
  phone: '',
  features: {},
  description: '',
  startDate: false,
  endDate: false,
  cartUrl: '',
  mobileCartUrl: '',
  timeZone: '',
  additionalDomains: [],
  pageUrlQueryParamWhitelist: [],
  mainRegion: '',
  allowedUrlDomain: '',
  betting: false,
  ownership: {},
  owners: [],
  global: {},
  percentage: 10,
  globalEnabled: false,
  incremental: {},
  weeklyReportMails: [],
  allUserMails: [],
};

/**
 * This method help to manage edit account component states.
 * @param {EditAccountState} state
 * @param {EditAccountActions} action
 * @returns {EditAccountState}
 */
export const editAccountReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'STATE_CHANGE':
      state[payload.stateName] = payload.newState;

      return { ...state };
    case 'GROUP_STATE_CHANGE':
      Object.keys(payload).forEach(key => {
        state[key] = payload[key];
      });

      return { ...state };
    default:
      return { ...state };
  }
};

/** @typedef {Object} EditAccountState
 * @property {string} domain
 * @property {string} person
 * @property {string} email
 * @property {string} phone
 * @property {Object} features
 * @property {string} description
 * @property {string|boolean} startDate
 * @property {string|boolean} endDate
 * @property {string} cartUrl
 * @property {string} mobileCartUrl
 * @property {string} timeZone
 * @property {Object[]} additionalDomains
 * @property {Object[]} pageUrlQueryParamWhitelist
 * @property {string} mainRegion
 * @property {string} allowedUrlDomain
 * @property {boolean} betting
 * @property {Object} ownership
 * @property {Object[]} owners
 * @property {Object} global
 * @property {number} percentage
 * @property {boolean} globalEnabled
 * @property {Object} incremental
 * @property {Object[]} weeklyReportMails
 * @property {Object[]} allUserMails
 */

/**
 * @typedef {"STATE_CHANGE" | "GROUP_STATE_CHANGE"} Actions
 */

/** @typedef {object} EditAccountActions
 * @property {Actions} type
 * @property {number|Array|Object[]|Object|string} payload
 */
