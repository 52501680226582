"use strict";

import React from "react";
import { connect } from "react-redux";
import { Creatable } from "react-select";
import { SortableHandle } from "react-sortable-hoc";
import { items } from "../../../constants/datamaps/wizard";

import Icons from "../../icons";
import { t } from "../../../system/ui";
import Tooltip from "../../../components/tooltip";

import Icon from "../../icon";
import TextField from "../../fields/field.textfield";

class SearchCriterionKeyword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            labels: [],
            labelExclusion: "",
            isSearchable: true,
        };
        this.onChange = this.onChange.bind(this);
    }


    componentDidMount() {
        this.setState({
            labelExclusion : this.props.values && this.props.values.criterion && this.props.values.criterion.value || ""
        });
    }

    onChange(selectedValue) {
        let selectedLabel =
            typeof selectedValue.target.value !== "undefined"
                ? selectedValue.target.value
                : this.state.labelExclusion;
        this.setState({ labelExclusion: selectedLabel });
        this.props.onCriteriaChange(this.refs.inputs, this.props.id, selectedLabel);
    }

    render() {
        let theItemCount =
                typeof this.props.values.itemCount !== "undefined"
                    ? this.props.values.itemCount.value
                    : "";
        const DragHandle = SortableHandle(() => (
            <span className="draghandler">
        <Icons name="burger" color="#e8e7f2" />
      </span>
        ));

        return (
            <li className="wizard-criterion" ref="inputs">
                <DragHandle />
                <span className="criteria-type">
          <Tooltip content={t("Static (Product)")} alignment="left">
            <Icon name="product" />
          </Tooltip>
        </span>
                <label className="item item-field criteria-name-search">
                    <TextField
                        value={this.state.labelExclusion}
                        name="keyword"
                        onChange={this.onChange}
                        className="criteria-field-search one-whole"
                        multi={false}
                        shouldKeyDownEventCreateNewOption={(e) => {
                            switch (e.keyCode) {
                                case 9:
                                case 13:
                                    return !0;
                                default:
                                    return !1;
                            }
                        }}
                    />
                </label>

                <label className="item item-field is-select criteria-item-count">
          <span className="item-label">
            {this.props.mainType.toLowerCase()}
          </span>{" "}
                    <select
                        name="itemCount"
                        className="one-whole criteria-field-search"
                        disabled={true}
                        defaultValue={theItemCount}
                    >
                        {items.map((item) => {
                            return (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            );
                        })}
                    </select>
                </label>

                <a
                    onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
                    className={"cancel-action"}
                >
                    <Icons name="crossCircle" />
                </a>
            </li>
        );
    }
}

const mapStatesToProps = (store) => ({
    criteria: store.searchWizard.criteria,
    brands: store.brands.options,
    isBrandsSet: store.brands.isSet,
});

export default connect(mapStatesToProps)(SearchCriterionKeyword);
