/**
 * @fileoverview Helps to manage notification states
 */

/**
 * @type {NotificationState}
 */
export const initialNotificationState = {
  opened: false,
  notification: {
    unseen: 0,
    items: [],
  },
};

/**
 * This method help to manage notification component states.
 * @param {NotificationState} state
 * @param {NotificationActions} action
 * @returns {NotificationState}
 */
export const notificationReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'TOGGLE':
      state.opened = !state.opened;
      return { ...state };
    case 'TOGGLE_ON':
      state.opened = true;
      return { ...state };
    case 'TOGGLE_OFF':
      state.opened = false;
      return { ...state };
    case 'SET_NOTIFICATIONS':
      state.notification.items = payload;
      state.notification.unseen = payload.filter(notification => {
        return notification.notification.status === 'ADDED';
      }).length;
      return { ...state };
    default:
      return { ...state };
  }
};

/** @typedef {Object} NotificationState
 * @property {boolean} opened Controls whether the notification area is open
 * @property {Object} notification
 * @property {number} notification.unseen Unseen notification count
 * @property {Object[]} notification.items List of notification
 */

/**
 * @typedef {"TOGGLE" | "TOGGLE_ON" | "TOGGLE_OFF" | "SET_NOTIFICATIONS"} Actions
 */

/** @typedef {object} NotificationActions
 * @property {Actions} type
 * @property {number|Object[]} payload
 */
