import React from 'react';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import {
  SFYCheckBox,
  SFYTextWithLabel,
} from '../../../../components/sfy-components/module-exports';

const UtmSettings = ({
  component,
  editCampaign,
  activeEmailDetails,
  readOnly,
}) => {
  const { campaignUtm } = activeEmailDetails;
  const {
    isCustomUtm,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    utmTerm,
  } = campaignUtm;

  const editUtmSettings = (field, value) => {
    const newUtmCampaign = { ...campaignUtm, [field]: value };
    editCampaign('campaignUtm', newUtmCampaign);
  };

  return (
    <InputCardWrapper
      type='adaptiveBlock'
      cardTitle={component.title}
      labelClass='cardTitle'
    >
      <SFYCheckBox
        label='Use custom UTM parameters'
        cardName='isCustomUtm'
        checked={isCustomUtm}
        searchActiveSetter={value => editUtmSettings('isCustomUtm', value)}
        customClass='email-custom-utm-checkbox'
        disabled={readOnly}
      />
      <SFYTextWithLabel
        cardLabel='UTM Source'
        cardName='utmSource'
        placeholder={component.placeholder}
        fieldValue={utmSource}
        editCampaign={editUtmSettings}
        disabled={readOnly || !isCustomUtm}
      />
      <SFYTextWithLabel
        cardLabel='UTM Medium'
        cardName='utmMedium'
        placeholder={component.placeholder}
        fieldValue={utmMedium}
        editCampaign={editUtmSettings}
        disabled={readOnly || !isCustomUtm}
      />
      <SFYTextWithLabel
        cardLabel='UTM Campaign'
        cardName='utmCampaign'
        placeholder={
          isCustomUtm
            ? component.placeholder
            : 'Will be generated automatically after saving the campaign.'
        }
        fieldValue={utmCampaign}
        editCampaign={editUtmSettings}
        notRequired={!isCustomUtm}
        disabled={readOnly || !isCustomUtm}
      />
      <SFYTextWithLabel
        cardLabel='UTM Content'
        cardName='utmContent'
        placeholder={component.placeholder}
        fieldValue={utmContent}
        editCampaign={editUtmSettings}
        disabled={readOnly}
      />
      <SFYTextWithLabel
        cardLabel='UTM Term'
        cardName='utmTerm'
        placeholder={component.placeholder}
        fieldValue={utmTerm}
        editCampaign={editUtmSettings}
        notRequired
        disabled={readOnly}
      />
    </InputCardWrapper>
  );
};

export default UtmSettings;
