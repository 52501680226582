/**
 * @author Bilal Cinarli
 */

"use strict";

export const plugins = [
  {
    name: "Sendloop",
    logo: "sendloop.png",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
  },
  {
    name: "Shopify",
    logo: "shopify.png",
    info: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
  }
];
