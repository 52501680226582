import React, { useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { editUser, getUserList } from 'reducers/user-management/extraReducers';
import {
  allModules,
  masterUserTypesArray,
  module2privilege,
  userAdminTypesArray,
  userType2Backend,
  userTypesArray,
  userRoles,
} from './constants';
import SelectAccountModules from './SelectAccountModules';
import { TextField } from '../../fields';
import { dialogActions, modalActions, uiActions } from '../../../actions';
import {
  isMasterUser,
  isSuperUser,
  isUserAdmin,
} from '../../../modules/auth/user';
import { getAccounts } from '../../../modules/account/ajax';
import { getUserResponsibleAccounts } from '../../../modules/auth/user-management';
import { onResetFilterClick } from '../../../reducers/user-management';
import { t } from '../../../system/ui';
import { setUpModules, setUpUserType } from './common/common-exports';
import {
  addEditUserReducer,
  selectUserState,
} from './store/add-edit-user-reducer';

/**
 * @name EditUserForm
 * @description - EditUserForm component is a form component which is used to edit an existing user
 * @param {object} -user
 * @returns {JSX.Element}
 * @constructor
 */
const EditUserForm = ({ user }) => {
  const dispatch = useDispatch();

  const { openDialog, closeDialog } = dialogActions;
  const { closeModal } = modalActions;
  const { showNotification } = uiActions;

  const [reducerState, reducerDispatcher] = useReducer(
    addEditUserReducer,
    selectUserState,
  );

  const {
    displayName,
    accountId,
    userType,
    username,
    switchableAccounts,
  } = user;

  const {
    accountsOptions,
    selectedAccounts,
    modules,
    selectedModules,
    accountsRequiredError,
    selectedUserType,
    userRole,
    userRoleRequiredError,
  } = reducerState;

  const userTypeSetter = () => {
    const setUserType = setUpUserType(userType);

    reducerDispatcher({
      type: 'SET_SELECTED_USER_TYPE',
      payload: setUserType,
    });

    return setUserType;
  };

  const handleUserTypesOptions = () => {
    let options;
    if (isUserAdmin() || selectedUserType === 'Account User')
      options = ['Account User'];
    else if (isMasterUser()) options = masterUserTypesArray;
    else if (isSuperUser()) options = userAdminTypesArray;
    return options.map(type => ({
      value: type,
      label: type,
    }));
  };

  const onUserRoleChange = e => {
    reducerDispatcher({
      type: 'SET_USER_ROLE',
      payload: e,
    });
  };

  const fetchResponsibleAccounts = () =>
    getUserResponsibleAccounts(
      username,
      response => {
        const options = response.map(
          ({ features, accountId: derivedAccId, domain }) => ({
            features,
            value: derivedAccId,
            label: domain,
          }),
        );
        reducerDispatcher({
          type: 'SET_SELECTED_ACCOUNTS',
          payload: options,
        });
      },
      error => {
        showNotification(error.message);
      },
    );

  const moduleSetter = () => {
    const { newModules } = setUpModules(selectedAccounts);
    reducerDispatcher({
      type: 'SET_MODULES',
      payload: newModules,
    });
  };

  const onSelectModule = item => {
    if (item === 'All') {
      if (selectedModules.length === modules.length) {
        reducerDispatcher({
          type: 'SET_SELECTED_MODULES',
          payload: [],
        });
      } else {
        reducerDispatcher({
          type: 'SET_SELECTED_MODULES',
          payload: modules,
        });
      }
    } else {
      reducerDispatcher({
        type: 'SET_SELECTED_MODULES',
        payload: selectedModules.includes(item)
          ? selectedModules.filter(module => module !== item)
          : [...selectedModules, item],
      });
    }
  };

  const onAccountSelectChange = selectedValue => {
    const payload =
      typeof selectedValue !== 'undefined' ? selectedValue : selectedAccounts;
    reducerDispatcher({
      type: 'SET_SELECTED_ACCOUNTS',
      payload,
    });
  };

  const onAccountSelectInputChange = value => {
    if (value.length > 2) {
      const options = {
        accountId: value,
      };
      getAccounts(options, response => {
        const newOptions = response.map(account => ({
          features: account.features,
          value: account.accountId,
          label: account.domain,
        }));
        reducerDispatcher({
          type: 'SET_ACCOUNTS_OPTIONS',
          payload: newOptions,
        });
      });
    } else {
      reducerDispatcher({
        type: 'SET_ACCOUNTS_OPTIONS',
        payload: [],
      });
    }
  };

  const fetchAccounts = () => {
    const newAccountsOptions = [...switchableAccounts, accountId].reduce(
      (acc, item) => {
        const options = {
          accountId: item,
        };

        return new Promise(resolve => {
          getAccounts(options, accountsResponse => {
            const accounts = accountsResponse.map(
              ({ features, accountId: derivedAccId, domain }) => {
                return {
                  features,
                  value: derivedAccId,
                  label: domain,
                  className: derivedAccId === accountId && 'disabled-option',
                };
              },
            );
            acc.then(array => {
              resolve([...array, ...accounts]);
            });
          });
        });
      },
      Promise.resolve([]),
    );
    return newAccountsOptions.then(value => {
      reducerDispatcher({
        type: 'SET_SELECTED_ACCOUNTS',
        payload: value,
      });
    });
  };

  const processQuery = () => {
    const accounts = selectedAccounts.map(account => account.value);
    const accessibleModules = selectedModules.map(
      module => module2privilege[module],
    );
    const processedUserRole = userRole?.value ? userRole?.value : userRole;

    const query = {
      displayName,
      userName: username,
      userType: userType2Backend[selectedUserType],
      responsibleAccounts: [],
      accessibleModules: [],
      switchableAccounts: [],
      userRole: processedUserRole,
    };

    if (selectedUserType === 'Account User') {
      query.switchableAccounts = accounts.filter(
        account => account !== accountId,
      );
      query.accessibleModules =
        accessibleModules.length === 0 ||
        accessibleModules.length === modules.length
          ? ['ACCOUNT_ADMIN']
          : accessibleModules;
    } else if (userAdminTypesArray.includes(selectedUserType)) {
      query.responsibleAccounts = accounts;
    }

    return query;
  };

  const handleSubmit = e => {
    e.preventDefault();
    openDialog({
      title: t('Attention!'),
      content: t(`Are you sure to update the user ${username}?`),
      onConfirm: async () => {
        const query = processQuery();
        await dispatch(editUser(query));
        closeDialog();
        await dispatch(onResetFilterClick());
        await dispatch(getUserList());
        closeModal();
        const notificationContent = () => (
          <div>{t(`User (${username}) successfully updated.`)}</div>
        );
        showNotification({
          content: notificationContent,
        });
      },
      onCancel: () => closeDialog(),
    });
  };

  const setUpSelectedModules = () => {
    if (userType.includes('Account Admin')) {
      return reducerDispatcher({
        type: 'SET_SELECTED_MODULES',
        payload: modules.length ? modules : allModules,
      });
    }
    const newSelectedModules = [];
    userType.forEach(type => {
      let probableModuleName = type.split(' ')[0].toLowerCase();
      if (
        probableModuleName === 'engagement' ||
        probableModuleName === 'dynamic'
      ) {
        probableModuleName =
          probableModuleName === 'dynamic'
            ? 'dynamicBundles'
            : 'personalization';
      }
      if (allModules.includes(probableModuleName)) {
        newSelectedModules.push(probableModuleName);
      }
    });
    return reducerDispatcher({
      type: 'SET_SELECTED_MODULES',
      payload: newSelectedModules,
    });
  };

  const Setup = async () => {
    const matchedUserType = userTypeSetter();
    if (matchedUserType === 'Account User') {
      await fetchAccounts();
      await setUpSelectedModules();
    } else if (userAdminTypesArray.includes(matchedUserType)) {
      await fetchResponsibleAccounts();
    }
  };

  useEffect(() => {
    Setup();
  }, []);

  useEffect(() => {
    if (
      selectedUserType === 'Master User' ||
      selectedUserType === 'Super User' ||
      selectedAccounts.length >= 1
    ) {
      reducerDispatcher({
        type: 'SET_ACCOUNTS_REQUIRED_ERROR',
        payload: false,
      });
    } else {
      reducerDispatcher({
        type: 'SET_ACCOUNTS_REQUIRED_ERROR',
        payload: true,
      });
    }
  }, [selectedUserType]);

  useEffect(() => {
    if (selectedUserType === 'Account User') {
      if (!userRole || !userRole.value) {
        reducerDispatcher({
          type: 'SET_USER_ROLE_REQUIRED_ERROR',
          payload: true,
        });
      } else {
        reducerDispatcher({
          type: 'SET_USER_ROLE_REQUIRED_ERROR',
          payload: false,
        });
      }
    }
  }, [userRole]);

  useEffect(() => {
    const uRole = userRoles.find(item => item.value === user?.userRole);
    reducerDispatcher({
      type: 'SET_USER_ROLE',
      payload: uRole,
    });
  }, []);

  useEffect(() => {
    if (selectedAccounts.length < 1) {
      reducerDispatcher({
        type: 'SET_ACCOUNTS_REQUIRED_ERROR',
        payload: true,
      });
      reducerDispatcher({
        type: 'SET_MODULES',
        payload: [],
      });
      reducerDispatcher({
        type: 'SET_SELECTED_MODULES',
        payload: [],
      });
    } else {
      reducerDispatcher({
        type: 'SET_ACCOUNTS_REQUIRED_ERROR',
        payload: false,
      });
      if (selectedUserType === 'Account User') moduleSetter();
    }
  }, [selectedAccounts]);

  useEffect(() => {
    if (
      modules.length > 0 &&
      selectedModules.length > 0 &&
      selectedUserType !== 'Account User'
    ) {
      const newSelectedModules = selectedModules.filter(module =>
        modules.includes(module),
      );
      reducerDispatcher({
        type: 'SET_SELECTED_MODULES',
        payload: newSelectedModules,
      });
    }
    setUpSelectedModules();
  }, [modules]);

  return (
    <div className='user-management-modal'>
      <div className='select-user-container'>
        <form onSubmit={handleSubmit}>
          <div className='grid-container'>
            <div className='grid-label'>
              <label htmlFor='displayName'>{t('Display Name')}</label>
            </div>
            <div className='grid-item display-name'>
              <TextField
                readOnly
                id='displayName'
                name='displayName'
                className='width-95'
                inputClassName='width-95'
                value={displayName}
              />
            </div>
            <div className='grid-label'>
              <label htmlFor='username'>{t('Mail Address')}</label>
            </div>
            <div className='grid-item mail-address'>
              <TextField
                readOnly
                id='username'
                name='username'
                className='width-95'
                inputClassName='width-95'
                validation={{
                  email: true,
                  businessEmail: true,
                }}
                value={username}
              />
            </div>
            <div className='grid-label'>
              <label htmlFor='accounts'>{t('User Type')}</label>
            </div>
            <div className='grid-item'>
              <div className='width-90'>
                <Select
                  required
                  options={handleUserTypesOptions()}
                  value={selectedUserType}
                  placeholder="The account user's type."
                  name='selectedUserType'
                  id='selectedUserType'
                  clearable={false}
                  disabled={selectedUserType === 'Account User'}
                  onChange={({ value }) => {
                    reducerDispatcher({
                      type: 'SET_SELECTED_USER_TYPE',
                      payload: value,
                    });
                  }}
                />
              </div>
            </div>
            {userTypesArray.includes(selectedUserType) && (
              <>
                <div className='grid-label'>
                  <label htmlFor='accounts'>
                    {selectedUserType === 'Account User'
                      ? t('Accounts')
                      : t('Responsible Accounts')}
                  </label>
                </div>
                <div className='grid-item'>
                  <div className='width-90'>
                    <Select
                      options={accountsOptions}
                      value={selectedAccounts}
                      placeholder={t('You can add one or more accounts.')}
                      name='accounts'
                      id='accounts'
                      clearable={false}
                      searchable
                      allowCreate={false}
                      onChange={onAccountSelectChange}
                      onInputChange={onAccountSelectInputChange}
                      multi
                    />
                    {accountsRequiredError === true && (
                      <span className='item-error'>
                        {t('At least one account should be selected.')}
                      </span>
                    )}
                  </div>
                </div>
                {selectedUserType === 'Account User' && (
                  <>
                    <div className='grid-label'>
                      <label htmlFor='accounts'>{t('User Role')}</label>
                    </div>
                    <div className='grid-item'>
                      <div className='width-90'>
                        <Select
                          value={userRole}
                          options={userRoles}
                          name='userRole'
                          onChange={onUserRoleChange}
                        />
                      </div>
                    </div>
                  </>
                )}
                {selectedUserType === 'Account User' && (
                  <SelectAccountModules
                    modules={modules}
                    selectModule={onSelectModule}
                    selectedModules={selectedModules}
                  />
                )}
              </>
            )}
          </div>
          <div className='user-manage-submit'>
            <button
              aria-label='submit-button'
              disabled={
                accountsRequiredError ||
                userRoleRequiredError ||
                !selectedModules.length
              }
              className='tertiary-action'
              type='submit'
            >
              {t('Update')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUserForm;
