/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { uiActions } from '../../../actions';

import { TextField } from '../../../components/fields';

class Name extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      name: 'name',
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    let name = this.props.campaign.name || '';
    this.updateValue(name);
  }

  componentWillReceiveProps(newProps) {
    if (!isEqual(this.props.campaign, newProps.campaign)) {
      this.updateValue(newProps.campaign.name);
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
    if (this.props.options && this.props.options.updateWizardForm) {
      this.props.options.updateWizardForm('name', newValue);
    }
  }

  onFieldChange(e) {
    this.updateValue(e.target.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <li className='campaign-subject'>
        <TextField
          name='name'
          label={this.props.isPush ? '' : 'Campaign Name'}
          className='item-stacked one-whole label-emphasise'
          value={this.state.value}
          required={true}
          onChange={this.onFieldChange}
          disabled={this.props.disabled}
        />
      </li>
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  Name,
);
