/**
 * @author Barış Altun
 * */

const validateField = (validation, field, errorMessage, context) => {
  let hasAnyError;

  if (
    !validation(
      field !== undefined && field.value !== undefined
        ? field.value
        : field.props.options[0].value,
      context,
    )
  ) {
    hasAnyError = true;

    context.setState({
      hasError: true,
      [errorMessage]: true,
    });
  } else {
    hasAnyError = false;

    context.setState({
      [errorMessage]: false,
    });
  }

  return hasAnyError;
};

export const validateRequired = (field, context) => {
  return validateField(isFilled, field, 'showRequiredError', context);
};

const isFilled = value => {
  return value.length > 0;
};

export const isValidMustacheOrUrl = value => {
  return (
    /(^{{.+:.+}})/.test(value.value) ||
    (value.value.indexOf('https://') >= 0 && isURL(value.value))
  );
};

export const validateEmail = (field, context) => {
  return validateField(isValidEmail, field, 'showEmailError', context);
};

const isValidEmail = email => {
  return /^([a-zA-Z0-9.!#$%&’*+=?^_`{|}~-]+)@([a-zA-Z0-9-]+)(?:\.[a-zA-Z0-9-]+)*/.test(
    email,
  );
};

export const validateBusinessEmail = (field, context) => {
  return validateField(
    isEmailBusiness,
    field,
    'showBusinessEmailError',
    context,
  );
};

const isEmailBusiness = (email, context) => {
  const businessEmailList = [...context.state.businessEmailList];
  const emailExt = email.split('@');
  return !businessEmailList.includes(emailExt[1]);
};

export const validatePassword = (field, context) => {
  return validateField(isValidPassword, field, 'showPasswordError', context);
};

const isValidPassword = password => {
  const passwordCheck = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!\@\#\.\_\-\(\)])(?=.*[a-zA-Z]).{8,}$/;
  const isValid = passwordCheck.test(password);

  return isValid;
};

export const validateEqualTo = (field, context) => {
  return validateField(isEqualTo, field, 'showEqualToError', context);
};

const isEqualTo = (value, context) => {
  return (
    value ===
    document.querySelector(`[name=${context.props.validation.equalTo}]`).value
  );
};

export const validateMinLength = (field, context) => {
  return validateField(isMinLength, field, 'showMinLengthError', context);
};

const isMinLength = (value, context) => {
  const { minLength } = context.props.validation;

  return value.length >= minLength;
};

const isMinNumber = (value, context) => {
  const { minNumber } = context.props.validation;
  return parseFloat(value) >= parseFloat(minNumber);
};

const isMaxNumber = (value, context) => {
  const { maxNumber } = context.props.validation;
  return parseFloat(value) <= parseFloat(maxNumber);
};

export const validateMaxNumber = (field, context) => {
  return validateField(isMaxNumber, field, 'showMaxNumberError', context);
};

export const validateMinNumber = (field, context) => {
  return validateField(isMinNumber, field, 'showMinNumberError', context);
};

export const validateSecureURL = (field, context) => {
  return validateField(isSecureURL, field, 'showSecureUrlError', context);
};

export const validateSecureURLWithPath = (field, context) => {
  return validateField(
    isSecureUrlWithPath,
    field,
    'showSecureUrlWithPathError',
    context,
  );
};

const isSecureURL = url => {
  const urlregex = /^(https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return urlregex.test(url);
};

const isSecureUrlWithPath = url => {
  const urlregex = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)(\/[-a-zA-Z0-9()@:%_\+.*~#?&//=]+)/;
  return urlregex.test(url);
};

export const validateURL = (field, context) => {
  return validateField(isURL, field, 'showURLError', context);
};

export const validateKlaviyoPrefix = (field, context) => {
  return validateField(isValidPrefix, field, 'showKlaviyoPrefixError', context);
};

const isValidPrefix = prefix => {
  const prefixRegex = /^[a-zA-Z0-9\_]*$/;
  return prefixRegex.test(prefix);
};

const isURL = url => {
  const urlregex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return urlregex.test(url);
};

export default {
  validateRequired,
  validateEmail,
  validatePassword,
  validateEqualTo,
  validateMinLength,
  validateMinNumber,
  validateSecureURL,
  validateURL,
  isValidMustacheOrUrl,
};
