const criteriaTypes = {
  behaviour: {
    type: 'title',
    name: 'Behaviour',
    criteriaList: {
      categoryPurchase: {
        id: 'categoryPurchase',
        name: 'Purchase by Category',
        type: 'criteria',
        dataType: 'purchase',
        isActive: true,
        criteriaFilters: [
          'categoryOperator',
          'quantityOperator',
          'amountOperator',
          'dateOperator',
        ],
      },
      productPurchase: {
        id: 'productPurchase',
        name: 'Purchase by Product',
        type: 'criteria',
        dataType: 'purchase',
        isActive: true,
        criteriaFilters: [
          'productOperator',
          'quantityOperator',
          'amountOperator',
          'dateOperator',
        ],
      },
      brandPurchase: {
        id: 'brandPurchase',
        name: 'Purchase by Brand',
        type: 'criteria',
        dataType: 'purchase',
        isActive: true,
        criteriaFilters: [
          'brandOperator',
          'quantityOperator',
          'amountOperator',
          'dateOperator',
        ],
      },
      totalRevenue: {
        id: 'totalRevenue',
        name: 'Total Revenue',
        type: 'criteria',
        dataType: 'purchase',
        isActive: true,
        criteriaFilters: ['totalRevenueOperator', 'dateOperator'],
      },
      transaction: {
        id: 'transaction',
        name: 'Based on Transaction',
        type: 'criteria',
        dataType: 'purchase',
        isActive: true,
        criteriaFilters: ['transactionOperator', 'dateOperator'],
      },
      categoryView: {
        id: 'categoryView',
        name: 'View by Category',
        type: 'criteria',
        dataType: 'productView',
        isActive: true,
        criteriaFilters: [
          'categoryOperator',
          'productViewCountOperator',
          'dateOperator',
        ],
      },
      productView: {
        id: 'productView',
        name: 'View by Product',
        type: 'criteria',
        dataType: 'productView',
        isActive: true,
        criteriaFilters: [
          'productOperator',
          'productViewCountOperator',
          'dateOperator',
        ],
      },
      brandView: {
        id: 'brandView',
        name: 'View by Brand',
        type: 'criteria',
        dataType: 'productView',
        isActive: true,
        criteriaFilters: [
          'brandOperator',
          'productViewCountOperator',
          'dateOperator',
        ],
      },
      urlView: {
        id: 'urlView',
        name: 'URL Visit',
        type: 'criteria',
        dataType: 'pageView',
        isActive: true,
        criteriaFilters: [
          'urlOperator',
          'pageViewCountOperator',
          'dateOperator',
        ],
      },
      pageView: {
        id: 'pageView',
        name: 'Page Visit',
        type: 'criteria',
        dataType: 'pageView',
        isActive: true,
        criteriaFilters: [
          'pageOperator',
          'pageViewCountOperator',
          'dateOperator',
        ],
      },
      offlinePurchaseByProduct: {
        id: 'offlinePurchaseByProduct',
        name: 'Offline Purchase by Product',
        type: 'criteria',
        dataType: 'purchase',
        isActive: false,
        criteriaFilters: [],
      },
      productAddedToCart: {
        id: 'productAddedToCart',
        name: 'Product Added to Cart',
        type: 'criteria',
        dataType: 'addToCart',
        isActive: true,
        criteriaFilters: [
          'productOperator',
          'quantityOperator',
          'amountOperator',
          'dateOperator',
        ],
      },
      categoryAddedToCart: {
        id: 'categoryAddedToCart',
        name: 'Category Added to Cart',
        type: 'criteria',
        dataType: 'addToCart',
        isActive: true,
        criteriaFilters: [
          'categoryOperator',
          'quantityOperator',
          'amountOperator',
          'dateOperator',
        ],
      },
      brandAddedToCart: {
        id: 'brandAddedToCart',
        name: 'Brand Added to Cart',
        type: 'criteria',
        dataType: 'addToCart',
        isActive: true,
        criteriaFilters: [
          'brandOperator',
          'quantityOperator',
          'amountOperator',
          'dateOperator',
        ],
      },
    },
  },
  userInfo: {
    type: 'title',
    name: 'User Info (Demographic)',
    criteriaList: {
      registerDate: {
        id: 'registerDate',
        name: 'Register Date',
        type: 'criteria',
        dataType: 'demographic',
        isActive: true,
        criteriaFilters: ['dateOperator'],
      },
      pushSubscription: {
        id: 'pushSubscription',
        name: 'Push Subscription',
        type: 'criteria',
        dataType: 'consents',
        isActive: true,
        criteriaFilters: ['pushSubscriptionOperator'],
      },
      emailSubscription: {
        id: 'emailSubscription',
        name: 'E-Mail Subscription',
        type: 'criteria',
        dataType: 'consents',
        isActive: true,
        criteriaFilters: ['emailSubscription'],
      },
      gender: {
        id: 'gender',
        name: 'Gender',
        type: 'criteria',
        dataType: 'demographic',
        isActive: true,
        criteriaFilters: ['genderOperator'],
      },
      age: {
        id: 'age',
        name: 'Birthday (Age)',
        type: 'criteria',
        dataType: 'demographic',
        isActive: true,
        criteriaFilters: ['ageOperator'],
      },
      lastLoginDate: {
        id: 'lastLoginDate',
        name: 'Date of Last Login',
        type: 'criteria',
        dataType: 'demographic',
        isActive: true,
        criteriaFilters: ['dateOperator'],
      },
    },
  },
  technology: {
    type: 'title',
    name: 'Technology',
    criteriaList: {
      device: {
        id: 'device',
        name: 'Device',
        type: 'criteria',
        dataType: 'technology',
        isActive: true,
        criteriaFilters: ['deviceOperator'],
      },
      operatingSystem: {
        id: 'operatingSystem',
        name: 'Operating System',
        type: 'criteria',
        dataType: 'technology',
        isActive: true,
        criteriaFilters: ['operatingSystemOperator'],
      },
      browser: {
        id: 'browser',
        name: 'Browser',
        type: 'criteria',
        dataType: 'technology',
        isActive: true,
        criteriaFilters: ['browserOperator'],
      },
    },
  },
  segmentation: {
    type: 'title',
    name: 'Segmentation',
    criteriaList: {
      customSegment: {
        id: 'customSegment',
        name: 'Custom Segment',
        type: 'customSegment',
        dataType: 'customSegment',
        isActive: true,
        criteriaFilters: ['customSegmentOperator'],
      },
    },
  },
  geoLocation: {
    type: 'title',
    name: 'Geo Location',
    criteriaList: {
      region: {
        id: 'region',
        name: 'Region',
        type: 'criteria',
        dataType: '',
        isActive: false,
        criteriaFilters: [],
      },
      country: {
        id: 'country',
        name: 'Country',
        type: 'criteria',
        dataType: '',
        isActive: false,
        criteriaFilters: [],
      },
      city: {
        id: 'city',
        name: 'City',
        type: 'criteria',
        dataType: '',
        isActive: false,
        criteriaFilters: [],
      },
    },
  },
};

const stringOperators = {
  equals: {
    name: 'is',
    value: 'equals',
  },
  equalsNot: {
    name: 'is not',
    value: 'equalsNot',
  },
  contains: {
    name: 'contains',
    value: 'contains',
  },
  containsNot: {
    name: 'does not contains',
    value: 'containsNot',
  },
};

const listOperators = {
  is: {
    name: 'is',
    value: 'is',
  },
  isNot: {
    name: 'is not',
    value: 'isNot',
  },
  contains: {
    name: 'contains',
    value: 'contains',
  },
  containsNot: {
    name: 'does not contains',
    value: 'containsNot',
  },
};

const numberOperators = {
  equals: {
    name: 'is equal to',
    value: 'equals',
  },
  equalsNot: {
    name: 'is not equal to',
    value: 'equalsNot',
  },
  gt: {
    name: 'is greater than',
    value: 'gt',
  },
  lt: {
    name: 'is smaller',
    value: 'lt',
  },
  lte: {
    name: 'is less or equal',
    value: 'lte',
  },
  gte: {
    name: 'greater than or equal',
    value: 'gte',
  },
  between: {
    name: 'between',
    value: 'between',
  },
};

const dateOperators = {
  last: {
    name: 'in the last',
    value: 'last',
  },
  between: {
    name: 'between',
    value: 'between',
  },
  equals: {
    name: 'on date',
    value: 'equals',
  },
};

const dateOperatorUnits = {
  days: {
    name: 'days',
    value: 'days',
  },
  weeks: {
    name: 'weeks',
    value: 'weeks',
  },
  months: {
    name: 'months',
    value: 'months',
  },
};

const quantityOperators = {
  gte: {
    name: 'at least',
    value: 'gte',
  },
  lte: {
    name: 'at most',
    value: 'lte',
  },
  equals: {
    name: 'number X time',
    value: 'equals',
  },
};

const pageOperators = {
  equals: {
    name: 'is',
    value: 'equals',
  },
  equalsNot: {
    name: 'is not',
    value: 'equalsNot',
  },
};

const urlOperators = {
  equals: {
    name: 'is',
    value: 'equals',
  },
  contains: {
    name: 'contains',
    value: 'contains',
  },
};

const ageOperators = {
  gte: {
    name: 'is greater than',
    value: 'gte',
  },
  lte: {
    name: 'is smaller',
    value: 'lte',
  },
  between: {
    name: 'between',
    value: 'between',
  },
};

const browserOperators = {
  chrome: {
    name: 'Chrome',
    value: 'Chrome',
  },
  firefox: {
    name: 'Firefox',
    value: 'Firefox',
  },
  opera: {
    name: 'Opera',
    value: 'Opera',
  },
  safari: {
    name: 'Safari',
    value: 'Safari',
  },
  // edge: {
  //   name: 'Edge',
  //   value: 'edge',
  // },
  yandex: {
    name: 'Yandex',
    value: 'Yandex',
  },
};

const deviceOperators = {
  mobil: {
    name: 'Mobil',
    value: 'Mobil',
  },
  desktop: {
    name: 'Desktop',
    value: 'Desktop',
  },
  tablet: {
    name: 'Tablet',
    value: 'Tablet',
  },
};

const operatingSystemOperators = {
  android: {
    name: 'Android',
    value: 'Android',
  },
  windows: {
    name: 'Windows',
    value: 'Windows',
  },
  ios: {
    name: 'IOS',
    value: 'iOS',
  },
  mac: {
    name: 'Mac OS',
    value: 'Mac',
  },
  // chrome: {
  //   name: 'Chrome',
  //   value: 'chrome',
  // },
};

const operatorColumns = {
  categoryOperator: 'category',
  productOperator: 'productName',
  brandOperator: 'brand',
  totalRevenueOperator: 'totalRevenue',
  transactionOperator: 'transaction',
  quantityOperator: 'quantity',
  amountOperator: 'amount',
  ageOperator: 'age',
  dateOperator: 'date',
  pageOperator: 'page',
  urlOperator: 'url',
  browserOperator: 'browser',
  deviceOperator: 'device',
  operatingSystemOperator: 'operatingSystem',
  productViewCountOperator: 'productViewCount',
  pageViewCountOperator: 'pageViewCount',
  attributeOperator: 'attribute',
  pushSubscriptionOperator: 'pushSubscription',
  emailSubscription: 'emailSubscription',
  genderOperator: 'gender',
  customSegmentOperator: 'customSegment',
};

const operatorTitles = {
  categoryOperator: 'Category',
  productOperator: 'Product',
  brandOperator: 'Brand',
  totalRevenueOperator: 'Total Revenue',
  transactionOperator: 'Transaction',
  quantityOperator: 'Quantity',
  amountOperator: 'Amount',
  dateOperator: 'Time Range',
  pageOperator: 'Page',
  urlOperator: 'URL',
  deviceOperator: 'Device',
  browserOperator: 'Browser',
  operatingSystemOperator: 'Operating System',
  productViewCountOperator: 'View Count',
  pageViewCountOperator: 'View Count',
  attributeOperator: 'Attribute',
  ageOperator: 'Age',
  pushSubscriptionOperator: 'Push',
  emailSubscription: 'Email',
  genderOperator: 'Gender',
  customSegmentOperator: 'Custom Segment',
};

export {
  criteriaTypes,
  stringOperators,
  listOperators,
  numberOperators,
  dateOperators,
  dateOperatorUnits,
  quantityOperators,
  pageOperators,
  urlOperators,
  operatorColumns,
  operatorTitles,
  ageOperators,
  browserOperators,
  deviceOperators,
  operatingSystemOperators,
};
