import React, { Component } from 'react';
import { connect } from 'react-redux';

import { confirmOnLeave } from '../actions/ui';
import { t } from '../system/ui';
import { setTitle } from '../system/document';

import EditCampaign from '../modules/campaign/edit';

class EditSegmentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 'Edit Segmentation',
      content: [{ field: 'segment' }],
      contentExtra: {
        buttons: [],
        fields: [{ field: 'segmentationOptions' }],
      },
      options: [
        { field: 'name' },
        { field: 'startEndDate' },
        { field: 'devices' },
        { field: 'pages' },
      ],
      buttons: [{ field: 'goTest' }, { field: 'goLive' }],
    };
  }

  componentDidMount() {
    setTitle(t('Edit Segmentation'));
    confirmOnLeave(this);
  }

  render() {
    return (
      <EditCampaign
        type='SEGMENTATION'
        title={this.state.title}
        content={this.state.content}
        contentExtra={this.state.contentExtra}
        options={this.state.options}
        buttons={this.state.buttons}
        campaignId={this.props.params.campaignId}
      />
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps)(EditSegmentation);
