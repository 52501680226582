import React from 'react';
import { useSelector } from 'react-redux';
import { browserHistory } from 'react-router';
import { isLoaded, isLoading, showNotification } from '../../../../actions/ui';
import Icons from '../../../../components/icons';
import { deleteEmailTemplate } from '../../../../modules/emails/ajax';
import { confirm, t } from '../../../../system/ui';
import classes from './EmailTemplateListItem.module.scss';

/**
 * @description Email template list mapper component
 * @param {Array.<{id: Number, image: String, headline: string}>} templates - list of custom templates
 * @param {boolean} isCollection - new item collection status
 * @param {boolean} isCollectionActive - whether collection is active
 * @param {function} setTemplatesList - setTemplatesList function
 * @param {string} campaignPathname - campaign pathname
 * @returns {JSX.Element} - EmailTemplateList component
 */

export const EmailTemplateListItem = ({
  templates,
  isCollection,
  isCollectionActive = true,
  setTemplatesList,
  campaignPathname,
}) => {
  const userRole = useSelector(
    ({ switchedUser, user }) =>
      switchedUser?.switchedUser?.userRole || user?.user?.userRole,
  );
  const deleteHandler = id => {
    const notificationContent = () => (
      <div>{`${t('You have successfully deleted email template.')} `}</div>
    );
    confirm({
      title: t('Remove the Template ?'),
      content: t('Are you sure you want to remove the template?'),
      onConfirm: () => {
        isLoading();
        deleteEmailTemplate(id, response => {
          setTemplatesList(
            templates.filter(item => !response.includes(item.id)),
          );
          isLoaded();
          showNotification({
            content: () => notificationContent(),
          });
        });
      },
    });
  };

  const renderTemplateList = () => {
    return templates?.map(
      ({ id, templateName, templatePreviewImageUrlForPc }) => (
        <li className={classes['email-list-item']} key={id}>
          <div className={classes['email-list-item__image-wrapper']}>
            <img
              src={
                templatePreviewImageUrlForPc === ''
                  ? '/images/layer.png'
                  : templatePreviewImageUrlForPc
              }
              alt={templateName}
            />
          </div>
          <div className={classes['email-list-item__item-header']}>
            {templateName}
          </div>
          <div className={classes['email-list-item__buttons']}>
            {userRole !== 'READ_ONLY' && !isCollection && isCollectionActive && (
              <button
                type='button'
                className={classes['email-list-item__buttons__delete-btn']}
                onClick={() => deleteHandler(id)}
              >
                <Icons
                  name='garbageBin'
                  className={
                    classes['email-list-item__buttons__delete-btn__icon']
                  }
                />
                {t('Delete')}
              </button>
            )}
            {userRole !== 'READ_ONLY' && (
              <button
                type='button'
                className={classes['email-list-item__buttons__edit-btn']}
                onClick={() => {
                  browserHistory.push({
                    pathname: !isCollectionActive
                      ? '/email/templates/create/'
                      : `/email/templates/edit/${id}`,
                    state: {
                      campaignPathname,
                      isCollection,
                      templateId: !isCollectionActive ? id : null,
                      isFork: !isCollectionActive,
                    },
                  });
                }}
              >
                {t('Continue & Edit')}
              </button>
            )}
          </div>
        </li>
      ),
    );
  };

  return <ul className={classes['email-item-list']}>{renderTemplateList()}</ul>;
};
