import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { PageConfigurator, findCampaignType } from '../utils/utils-exports';
import { t } from '../../../../system/ui';
import classes from './styles/emailCampaignCard.module.scss';
import CustomIcon from '../../../../components/icons';
import Icon from '../../../../components/icon';

/**
 * @description - Email Campaign Cards Component renders the cards for the email campaign pages based on the campaign type
 * @param location - location object from window object
 * @param campaignStatus - ACTIVE, TEST, PASSIVE back from the API
 * @param campaign - array of campaigns
 * @returns {JSX.Element}
 * @constructor
 */

export const EmailCampaignCard = ({ location, campaign }) => {
  const campaignType = findCampaignType(location);
  const [pageConfig, setPageConfig] = useState({});

  const userRole = useSelector(
    ({ switchedUser, user }) =>
      switchedUser?.switchedUser?.userRole || user?.user?.userRole,
  );

  useEffect(() => {
    const tempPageConfig = JSON.parse(
      JSON.stringify(PageConfigurator(campaignType)),
    );
    campaign.forEach(item => {
      if (tempPageConfig[item.type]) {
        tempPageConfig[item.type].activate = item.status.toLowerCase();
        tempPageConfig[item.type].modeIcon = ['TEST', 'ACTIVE'].includes(
          item?.status,
        )
          ? 'EmailEditIcon'
          : 'plus';
        tempPageConfig[item.type].mode = ['TEST', 'ACTIVE'].includes(
          item?.status,
        )
          ? 'edit'
          : 'create';
      }
    });
    setPageConfig(tempPageConfig);
  }, [campaign]);

  const routeAction = (mode, type, status = null) => {
    let pathname = `/email-sgm/${mode}/${type}`;
    let state = {};

    if (mode === 'create' || status === 'passive') {
      pathname = '/email/templates';
      const fixedMode = status !== 'test' ? 'create' : 'edit';
      state = {
        campaignPathname: `/email-sgm/${fixedMode}/${type}`,
        isFork: true,
      };
    }

    browserHistory.push({
      pathname,
      state,
    });
  };

  const createCampaignCard = createCampaignType => {
    return (
      <div
        className='available-type available-type-custom available-type-filled'
        onClick={() => {
          userRole === 'ADMIN'
            ? routeAction(
                'create',
                createCampaignType === 'fly' ? 'FLY' : 'RECOMMENDATION',
              )
            : {};
        }}
      >
        <span className='available-type-wireframe'>
          <Icon name='plus' size='2x' />
          <span>{t('Create Campaign')}</span>
        </span>
      </div>
    );
  };

  return (
    <>
      <h2 className={classes.mainTitle}>
        {campaignType === 'fly'
          ? 'Fly Mode'
          : campaignType === 'recommendation'
          ? 'Bulk / Segmented'
          : campaignType}
      </h2>
      <div className={classes.subTitle}>
        {campaignType !== 'fly' &&
          campaignType !== 'recommendation' &&
          `${campaignType.toLowerCase()} Campaigns`}
      </div>
      <div className={classes['ectc-wrapper']}>
        {campaignType !== 'fly' && campaignType !== 'recommendation'
          ? Object.keys(pageConfig).map((key, index) => {
              const selectedObj = pageConfig[key];
              return (
                <div
                  key={index}
                  className={classes[`ectc__${selectedObj.activate}`]}
                >
                  <div className={classes['ectc-header']}>
                    <div className={classes['ectc-header-flex']}>
                      <CustomIcon name={selectedObj.icon} color='#222' />
                      <h4>{selectedObj.name}</h4>
                    </div>
                    {selectedObj.activate !== 'passive' && (
                      <div className={classes['ectc-state']}>
                        {selectedObj.activate === 'active'
                          ? 'live'
                          : selectedObj.activate === 'test'
                          ? 'draft'
                          : ''}
                      </div>
                    )}
                  </div>
                  <p className={classes.ectc__description}>
                    {selectedObj.description}
                  </p>
                  <div
                    className={classes['ectc__router-container']}
                    onClick={() => {
                      userRole === 'ADMIN' ||
                      (userRole === 'EDITOR' && selectedObj.mode === 'edit')
                        ? routeAction(
                            selectedObj.mode,
                            selectedObj.type,
                            selectedObj.activate,
                          )
                        : {};
                    }}
                  >
                    <div className={classes.ectc__iconOutline}>
                      <CustomIcon name={selectedObj.modeIcon} color='#948CF3' />
                    </div>
                    <p>{t(selectedObj.mode)}</p>
                  </div>
                </div>
              );
            })
          : createCampaignCard(campaignType)}
      </div>
    </>
  );
};
