import { browserHistory } from 'react-router';
import { getApiUrl } from '../../constants/config';
import { localItems } from '../../constants/user';
import { getExtToken } from '../../index';
import { store } from '../../store';
import { $http } from '../../system/ajax';
import {
  buildUrl,
  checkTokenEndPoint,
  getEndPoint,
  getRestEndPoint,
  getRestPath,
  getTokenEndPoints,
  setPasswordEndPoint,
} from '../../system/api';
import { del, get, select, set } from '../../system/cookie';
import { $http as $promiseHttp } from '../../system/http';
import { getLocal, removeLocal, setLocal } from '../../system/object';

export const isSwitchAccount = () => {
  return store.getState().switchedUser.switchedUser.username
    ? store.getState().switchedUser.switchedUser.account
    : false;
};
export const getAccount = () => {
  return isSwitchAccount() || store.getState().user.user.account;
};
export const getAccountData = key => getAccount()[key];
export const getApiKey = () => getAccountData('apiKey');

function loginRequest(userPayload, env, disableSwitchRegion) {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return new Promise((resolve, reject) => {
    $http(getTokenEndPoints(env) + globalApiKey)
      .post(
        {
          username: userPayload.email,
          password: userPayload.password,
          recaptchaObj: userPayload.recaptchaObj,
        },
        { type: 'json', auth: false, switchRegion: disableSwitchRegion },
      )
      .then(function(response) {
        resolve({ response, env });
      })
      .catch(function(error) {
        reject({ error, env });
      });
  });
}

export const login = async userPayload =>
  await loginRequest(userPayload, 'prod', true).then(r => r);

export const internalLogin = (env, userPayload) => {
  const errors = [];
  let requests = [];
  if (env !== 'prod') {
    const v3 = new Promise((resolve, reject) => {
      loginRequest(userPayload, 'prod', true)
        .then(r => resolve(r))
        .catch(e => {
          reject(e);
        });
    });

    requests = [
      v3.catch(error => {
        errors.push({ env: error.env, response: error.error });
      }),
    ];
  }

  return Promise.all(requests).then(values => {
    const tokens = values.filter(item => item);
    return { tokens, errors };
  });
};

export const logout = () => {
  let domain = '.segmentify.com';
  if (window.location.hostname.includes('localhost')) {
    domain = 'localhost';
  }
  del('SegmentifyAuthenticationToken', '/', domain);
  del('SegmentifyAuthenticationTokenV3', '/', domain);
  del('_sgf_dc', '/', domain);
  removeLocal(localItems);
};

export const updateUser = (updatedData, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(getEndPoint('user/update/user') + globalApiKey)
    .post(updatedData, { type: 'json', customErrMsg: true })
    .then(function(response) {
      callback(response);
    })
    .catch(function(error) {
      callback(error);
    });
};

export const updateAccount = (updatedData, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(getEndPoint('user/update/account') + globalApiKey)
    .post(updatedData, { type: 'json' })
    .then(function(response) {
      callback(response);
    })
    .catch(function(error) {
      callback(error);
    });
};

export const updateCurrency = (updatedData, callback) => {
  $http(`${getRestEndPoint('currency/update')}?apiKey=${getApiKey()}`)
    .post(updatedData, { type: 'json' })
    .then(function(response) {
      callback(response);
    })
    .catch(function(error) {
      callback(error);
    });
};

export const getUserPromise = () => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $promiseHttp(getEndPoint('user/current') + globalApiKey).get();
};

export const getSwitchedUserPromise = username => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $promiseHttp(getEndPoint('user/current') + globalApiKey).get({
    userToSwitch: username,
  });
};

export const unlockUser = (callback, token) => {
  $http(buildUrl(`unlock/user/${token}`))
    .get(null, { switchRegion: true })
    .then(function(response) {
      callback(response);
    })
    .catch(function(response) {
      callback(response);
    });
};

export const resetPasswordCheck = (callback, token, v) => {
  let url = buildUrl(`check/token/${token}`);
  if (v === '1') url = `https://dcetr4.segmentify.com/check/token/${token}`;
  $http(url)
    .get(null, { switchRegion: true })
    .then(function(response) {
      callback(response);
    })
    .catch(function(response) {
      callback(response);
    });
};

export const getUser = (callback, token) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  $http(getEndPoint('user/current') + globalApiKey)
    .get(null, { token })
    .then(function(response) {
      callback(response);
    })
    .catch(function(response) {
      callback(response);
    });
};

export const dashboardAccess = () => {
  $http(getRestEndPoint('account/dashboardAccess')).get();
};

export const getUserCurrent = (token, callback) => {
  const url = getEndPoint('user/current');
  fetch(url, {
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  })
    .then(r => r.json())
    .then(resp => callback(false, resp, url))
    .catch(err => callback(true, err, ''));
};

export const postForgottenPassword = (email, callback) => {
  const url = `${getApiUrl()}forgot/password`;
  $http(url)
    .post({ username: email }, { type: 'json' })
    .then(function(response) {
      callback(response);
    })
    .catch(function(response) {
      callback(response);
    });
};

export const postV1ForgottenPassword = (email, callback) => {
  const url = 'https://dcetr4.segmentify.com/forgot/password';
  $http(url)
    .post({ username: email }, { type: 'json', switchRegion: true })
    .then(function(response) {
      callback(response);
    })
    .catch(function(response) {
      callback(response);
    });
};

export const postResetPassword = (payload, token, v, callback) => {
  let url = buildUrl(`reset/password/${token}`);
  if (v === '1') url = `https://dcetr4.segmentify.com/reset/password/${token}`;
  const options =
    v === '1' ? { type: 'json', switchRegion: true } : { type: 'json' };

  $http(url)
    .post(payload, options)
    .then(function(response) {
      callback(response);
    })
    .catch(function(response) {
      callback(response);
    });
};

export const getUserList = (accountId, callback, errorCallback, token) => {
  $http(getEndPoint('user/locked'))
    .get({ accountId }, { type: 'json' })
    .then(function(response) {
      callback(response);
    })
    .catch(function(error) {
      errorCallback(error);
    });
};

export const getUserRestUrl = () => {
  // let account = isSwitchAccount() || store.getState().user.user.account;
  return getRestPath();
};

export const getToken = () => {
  const extToken = getExtToken();
  if (extToken && extToken !== null && extToken !== '') {
    return extToken;
  }

  return get(select());
};

export const checkToken = (token, callback, errorCallback) => {
  $http(`${checkTokenEndPoint()}/${token}`)
    .get(null, { type: 'json', auth: false })
    .then(function(response) {
      callback(response);
    })
    .catch(function(error) {
      errorCallback(error);
    });
};

export const setMultiUserPassword = (data, token, callback, errorCallback) => {
  $http(`${setPasswordEndPoint()}/${token}`)
    .post(data, { type: 'json', auth: false })
    .then(function(response) {
      callback(response);
    })
    .catch(function(error) {
      errorCallback(error);
    });
};

export const getRefreshToken = () => {
  return getLocalUserInfo('refreshToken');
};

export const getUserCurrency = () => {
  return getLocalUserInfo('currency');
};

export const isIntegrationsOk = () => {
  return getLocalUserInfo('integrations').PAGE_VIEW === 'COMPLETED';
};

const getLocalUserInfo = key => {
  return getLocal(key);
};

export const isLogged = currentState => {
  if (
    typeof currentState.location.query.myEnv !== 'undefined' &&
    currentState.location.query.myEnv === 'isLocal'
  ) {
    isLocal();
  }

  const token = getToken();

  return token !== null;
};

export const isTechResponsible = user => {
  const targetUser = user || store.getState().user.user;
  return (
    targetUser.privileges.indexOf('ROLE_ACD') > -1 ||
    targetUser.privileges.indexOf('ROLE_OBD') > -1
  );
};

// TODO: What is this? IT IS NOT CLEAR!
export const isSuperUser = user => {
  const targetUser = user || store.getState().user.user;
  return (
    targetUser.privileges.includes('MASTERUSER') ||
    targetUser.privileges.includes('SUPERUSER') ||
    targetUser.privileges.includes('ROLE_ACD') ||
    targetUser.privileges.includes('ROLE_OBD') ||
    targetUser.privileges.includes('ROLE_ACM') ||
    targetUser.privileges.includes('ROLE_OBM') ||
    targetUser.privileges.includes('ROLE_SM') ||
    targetUser.privileges.includes('ROLE_AGU')
  );
};

export const isMasterUser = user => {
  const targetUser = user || store.getState().user.user;
  return targetUser.privileges.includes('MASTERUSER');
};

export const isSuperiestUser = user => {
  const targetUser = user || store.getState().user.user;
  return (
    targetUser.privileges.includes('MASTERUSER') ||
    targetUser.privileges.includes('SUPERUSER')
  );
};

export const getUserPrivilege = (user, privilege) => {
  return user.privileges.indexOf(privilege) > -1;
};

export const isResponsible = (privilege, user) => {
  const _user = user || store.getState().user.user;
  return getUserPrivilege(_user, privilege);
};

export const isMultiAccountUser = user => {
  const _user = user || store.getState().user.user;
  return _user.privileges.indexOf('MULTI_ACC_USER') > -1;
};

export const isUserAdmin = user => {
  const _user = user || store.getState().user.user;
  return _user.privileges.indexOf('USERADMIN') > -1;
};

export const isLimitedUser = user => {
  const _user = user || store.getState().user.user;
  return _user.privileges.indexOf('ACCOUNT_ADMIN') < 0;
};

// `user || store.getState().user.user;` it is a very duplicated code. TODO: make a function
// We are checking the same user in many places. TODO: make it a function to simplify it
export const isMSPUser = user => {
  const targetUser = user || store.getState().user.user;
  return targetUser.privileges.includes('ROLE_AGU');
};

export const isGlobalAccount = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  const { dataCenter } = account;
  return dataCenter === 'gc';
};

export const hasModule = module => {
  const account = isSwitchAccount() || store.getState().user.user.account;

  return (
    account.features[module]?.status === 'ACTIVE' ||
    account.features[module]?.status === 'POC'
  );
};

export const hasFeature = feature => {
  const account = isSwitchAccount() || store.getState().user.user.account;

  return account.features[feature]?.status === 'ACTIVE';
};

export const mailProviderIsSegmentify = () => {
  const mailProvider = isSwitchAccount() || store.getState().user.user.account;
  return (
    mailProvider?.mailConfiguration?.provider === 'SEGMENTIFY' ||
    'SEGMENTIFY_V2'
  );
};

export const hasNoModule = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  let noModule = true;
  const modules = [
    'recommendation',
    'personalization',
    'email',
    'push',
    'search',
    'personalisedAds',
  ];
  modules.forEach(module => {
    if (
      account.features[module].status === 'ACTIVE' ||
      account.features[module].status === 'POC'
    ) {
      noModule = false;
    }
  });
  return noModule;
};

export const hasNoRevenueModule = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  let noRevenueModule = true;
  const modules = ['recommendation', 'email', 'push', 'search'];
  modules.forEach(module => {
    if (
      account.features[module].status === 'ACTIVE' ||
      account.features[module].status === 'POC'
    ) {
      noRevenueModule = false;
    }
  });
  return noRevenueModule;
};

export const hasModulePrivilege = (user, module) => {
  const _user = user || store.getState().user.user;
  if (!isLimitedUser(_user)) {
    return true;
  }
  return _user.privileges.indexOf(module) > -1;
};

export const getSwitchAccountUser = withEncodedUsername => {
  if (withEncodedUsername) {
    const switchedUser = {
      ...store.getState().switchedUser.switchedUser,
    };
    let switchedUserName =
      switchedUser.username.split('@').length > 1
        ? switchedUser.username.split('@')[0]
        : false;
    const switchedUserMail =
      switchedUser.username.split('@').length > 1
        ? switchedUser.username.split('@')[1]
        : false;

    if (switchedUserName && switchedUserMail) {
      switchedUserName = encodeURIComponent(switchedUserName);
      switchedUser.username = `${switchedUserName}@${switchedUserMail}`;
      return switchedUser;
    }
    return false;
  }
  return store.getState().switchedUser.switchedUser;
};

export const hasSegmentifyListing = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.analytics.segmentifyListing;
};

export const hasTrendifySources = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.analytics.trendifySources &&
    account.analytics.trendifySources.length
    ? account.analytics.trendifySources
    : false;
};

export const hasAnalytics = analytics => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return (
    account.features[analytics].status === 'ACTIVE' ||
    account.features[analytics].status === 'BIGDATA' ||
    account.features[analytics].status === 'POC'
  );
};

export const hasGlobalControl = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.global && account.global.globalEnabled;
};

export const hasTrendify = () => hasAnalytics('trendify');
export const hasBannerify = () => hasAnalytics('bannerify');

export const hasDynamicBundle = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;

  return (
    account?.features?.dynamicBundles?.status === 'ACTIVE' ||
    account?.features?.dynamicBundles?.status === 'POC'
  );
};

export const hasReplenishment = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;

  return (
    account?.features?.replenishment?.status === 'ACTIVE' ||
    account?.features?.replenishment?.status === 'POC'
  );
};

export const hasMatchedIndustry = accountIndustry => {
  const industry = store.getState().switchedUser.switchedUser.account?.industry;
  return accountIndustry === industry;
};

export const hasEmailConfiguration = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  let hasMailConfig = false;
  const mailConfig = account.mailConfiguration;
  if (
    mailConfig &&
    mailConfig.provider &&
    (mailConfig.provider === 'SEGMENTIFY' ||
      mailConfig.provider === 'SEGMENTIFY_V2')
  ) {
    hasMailConfig = true;
  } else if (mailConfig) {
    hasMailConfig =
      mailConfig &&
      mailConfig.params &&
      (mailConfig.params.apiKey ||
        mailConfig.params.username ||
        mailConfig.params.privateKey);
  }
  return hasMailConfig;
};

export const getEmailConfiguration = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.mailConfiguration;
};

export const hasDotDigitalConfiguration = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  let hasMailConfig = false;
  const mailConfig = account.mailConfiguration;
  if (mailConfig) {
    hasMailConfig = account.mailConfiguration.provider === 'DOTDIGITAL';
  }
  return hasMailConfig;
};

export const hasKlaviyoConfiguration = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  let hasMailConfig = false;
  const { mailConfiguration } = account;
  if (mailConfiguration) {
    hasMailConfig = account.mailConfiguration.provider === 'KLAVIYO';
  }
  return hasMailConfig;
};

export const hasBetting = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  let hasBetting = false;
  if (account.sector === 'BETTING') hasBetting = true;
  return hasBetting;
};

export const hasReportsVisible = () => {
  return (
    hasModule('recommendation') ||
    hasModule('search') ||
    hasModule('push') ||
    hasModule('email')
  );
};

export const hasGoogleAnalyticsId = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account?.googleAnalytics?.viewId || account?.ga4?.propertyId;
};

export const isCategoryHierarchy = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.categoryHierarchy;
};

export const isMostSearchForEnabled = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.mostSearchedForEnabled;
};

export const isBunsarEnabled = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.bunsarEnabled;
};

export const isBundleEnabled = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return (
    account.features.dynamicBundles.status === 'ACTIVE' ||
    account.features.dynamicBundles.status === 'POC'
  );
};

export const isHiddenProductsEnabled = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return (
    account.features.hidden.status === 'ACTIVE' ||
    account.features.hidden.status === 'POC'
  );
};

export const getCustomTrendifyColumns = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.analytics.trendifyCustoms &&
    account.analytics.trendifyCustoms.length
    ? account.analytics.trendifyCustoms
    : false;
};

export const getCustomInsightColumns = () => {
  const trendifyColumns = getCustomTrendifyColumns();
  let insightsColumns = [];
  if (trendifyColumns) {
    insightsColumns = trendifyColumns.filter(column => {
      return column.insights;
    });
  } else {
    return false;
  }
  return insightsColumns.length ? insightsColumns : false;
};

export const isGG = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return (
    account.accountId === 'acc_618692a136df2000' ||
    account.accountId === 'acc_66b98cb9cc000'
  );
};

export const hasIysIntegrationEnabled = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.iysEnabled;
};

export const isEbuyer = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.accountId === 'acc_8771fbab04000';
};

export const getAdditionalLanguages = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.additionalLanguages || [];
};

export const getAdditionalCurrencies = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.additionalCurrencyList || [];
};

export const isInsightsCountDefined = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.insightsProductCount;
};

export const insightsExportCount = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.insightsProductExportCount;
};

export const getMultiUserAccountId = user => {
  return user.account.accountId;
};

const isLocal = () => {
  setLocal('isLocal', true);
  set('SegmentifyAuthenticationToken', 'segmentify@local');
};

export const getAccountId = () => getAccountData('accountId');

export const getAccountCurrency = () => getAccountData('mainCurrency');

export const getAccountReplenishmentConfig = () =>
  getAccountData('replenishmentConfig');

export const removeCustomers = (data, callback) => {
  $http(`${getRestEndPoint('gdpr/remove')}?apiKey=${getApiKey()}`)
    .post(data, { type: 'json' })
    .then(function(response) {
      callback(response);
    })
    .catch(function(error) {
      callback(error);
    });
};

export const updateEmailConfig = (updatedData, callback) => {
  $http(`${getRestEndPoint('emails/config')}?apiKey=${getApiKey()}`)
    .post(updatedData, { type: 'json' })
    .then(function(response) {
      callback(response);
    })
    .catch(function(error) {
      callback(error);
    });
};

export const unsubscribeEmail = (email, callback) => {
  $http(`${getRestEndPoint('email/unsub')}/${email}?apiKey=${getApiKey()}`)
    .delete()
    .then(function(response) {
      callback(response, true);
    })
    .catch(function(error) {
      callback(error, false);
    });
};

export const disableEmailConfig = () => {
  $http(
    `${getRestEndPoint('emails/disableConfig')}?apiKey=${getApiKey()}`,
  ).post();
};
export const updatePushConfig = (platform, updatedData, callback) => {
  $http(`${getRestEndPoint(`push/config${platform}`)}?apiKey=${getApiKey()}`)
    .post(updatedData, { type: 'json' })
    .then(function(response) {
      callback(response);
    })
    .catch(function(error) {
      callback(error);
    });
};

export const updateSearchConfig = (updatedData, callback) => {
  $http(`${getRestEndPoint('palantir/config')}?apiKey=${getApiKey()}`)
    .post(updatedData, { type: 'json' })
    .then(function(response) {
      callback(response);
    })
    .catch(function(error) {
      callback(error);
    });
};

export const generateConfigFileUrl = fileType => {
  let url = `${getRestEndPoint(`push/get${fileType}`)}?apiKey=${getApiKey()}`;
  if (isSwitchAccount() && !isMultiAccountUser()) {
    url += `&switchUser=${getSwitchAccountUser(true).username}`;
  }
  return url;
};

export const getXmlUrl = () => {
  const restUrl = getUserRestUrl();
  const apiKey = getApiKey();
  const XMLURL = `${restUrl}trigger/feed?apiKey=${apiKey}`;
  return XMLURL;
};

export const hasFeed = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return account.feed && account.feed.active;
};

export const getRegion = () => {
  return store.getState().switchRegion.selectedRegion;
};

export const getControlGroups = () => {
  let groups = [
    { label: 'All', value: 'ALL' },
    { label: 'Active', value: 'REAL' },
    { label: 'Control', value: 'CONTROL' },
  ];
  if (isEbuyer()) {
    groups = [
      { label: 'All', value: 'ALL' },
      { label: 'Active', value: 'REAL' },
      { label: 'Active-A', value: 'REAL-A' },
      { label: 'Control', value: 'CONTROL' },
    ];
  }
  return groups;
};

export const getControlGroupByValue = value => {
  let cGroup = { label: 'Active', value: 'REAL' };
  const filteredGroup = getControlGroups().filter(item => {
    return item.value === value;
  });
  if (filteredGroup && filteredGroup.length > 0) {
    cGroup = filteredGroup[0];
  }
  return cGroup;
};

export const isRecommendationTrackEnabled = trackType => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  return (
    account.recommendationSettings &&
    account.recommendationSettings[trackType] &&
    account.recommendationSettings[trackType].enabled === true
  );
};

export const hasOnlyDynamicBundles = () => {
  return hasModule('dynamicBundles') && !hasModule('recommendation');
};

export const getCurrentEnvironment = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'development':
      return 'dev';
    case 'search':
      return 'search';
    case 'qa':
      return 'qa';
    case 'local':
      return 'local';
    default:
      return 'prod';
  }
};

export const redirectUserToFirstPage = () => {
  const account = isSwitchAccount() || store.getState().user.user.account;
  const isTrendifyEnabled = account.features.trendify.status === 'ACTIVE';
  browserHistory.push(isTrendifyEnabled ? '/trendify/insights' : '/welcome');
};

export default {
  login,
  internalLogin,
  logout,
  updateUser,
  updateAccount,
  updateCurrency,
  getUserPromise,
  getSwitchedUserPromise,
  unlockUser,
  resetPasswordCheck,
  postForgottenPassword,
  postV1ForgottenPassword,
  postResetPassword,
  getUser,
  getUserCurrent,
  getUserList,
  getToken,
  checkToken,
  getRefreshToken,
  getUserRestUrl,
  getUserCurrency,
  getApiKey,
  getAccountId,
  getAccountCurrency,
  hasAnalytics,
  hasTrendify,
  hasBannerify,
  isCategoryHierarchy,
  isBunsarEnabled,
  getCustomTrendifyColumns,
  getCustomInsightColumns,
  isGG,
  isInsightsCountDefined,
  insightsExportCount,
  isLogged,
  isTechResponsible,
  isSuperUser,
  isSuperiestUser,
  isResponsible,
  isMultiAccountUser,
  isUserAdmin,
  isSwitchAccount,
  getSwitchAccountUser,
  getAdditionalLanguages,
  hasSegmentifyListing,
  hasTrendifySources,
  getMultiUserAccountId,
  getAccount,
  removeCustomers,
  hasGoogleAnalyticsId,
  hasGlobalControl,
  hasFeed,
  getXmlUrl,
  getRegion,
  dashboardAccess,
  hasDynamicBundle,
  getCurrentEnvironment,
  redirectUserToFirstPage,
};
