import React from 'react';
import PropTypes from 'prop-types';
import { reformatDate } from '../../system/date';
import { t } from '../../system/ui';
import { uiActions } from '../../actions';
import { getNewsletterReport } from '../../modules/reports/ajax';
import IconTailRight from '../icons/tail-right';

const CollectedEmails = ({ campaign, startDate, endDate }) => {
  const { instanceId, type, fields } = campaign;
  const [renderedMailNumbers, setRenderedMailNumbers] = React.useState(0);
  const [results, setResults] = React.useState([]);
  const [showAllResults, setShowAllResults] = React.useState(false);
  const [slicedEmails, setSlicedEmails] = React.useState([]);
  const [tableHeadings, setTableHeadings] = React.useState([]);

  const fetchResults = () => {
    uiActions.isLoading();
    getNewsletterReport(
      instanceId,
      result => {
        uiActions.isLoaded();
        setResults(result);
      },
      showAllResults === false && startDate,
      showAllResults === false && endDate,
    );
  };

  React.useEffect(() => {
    fetchResults();
  }, [showAllResults]);

  React.useEffect(() => {
    setRenderedMailNumbers(results.length > 50 ? 50 : results.length);
    setSlicedEmails(results.slice(-50).reverse());
  }, [results]);

  React.useEffect(() => {
    const headings =
      type !== 'FORM'
        ? Object.keys(slicedEmails[0]?.fields || {}).map(heading => heading)
        : fields.map(
            (heading, headingInd) =>
              heading.name ||
              heading.groupTitle ||
              `${heading.type}-${headingInd}`,
          );
    setTableHeadings([...headings, 'Date Added']);
  }, [slicedEmails]);

  return (
    <>
      <div className='collected-emails-date-range'>
        <p>
          <strong>
            ({renderedMailNumbers} of {results.length})
          </strong>{' '}
          {startDate} - {endDate} listed
        </p>
        <p>
          <a onClick={() => setShowAllResults(true)}>
            See All time
            <IconTailRight width='12' />
          </a>
        </p>
      </div>
      {results.length ? (
        <div className='collected-emails'>
          <div className='account-details'>
            <table className='data-table'>
              <thead>
                <tr>
                  {tableHeadings.map(heading => (
                    <th key={Math.random()}>{heading}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {type !== 'FORM' &&
                  slicedEmails.map(item => {
                    const fieldArr = Object.values(item.fields).map(
                      field => field,
                    );
                    fieldArr.push(reformatDate(item.submitTime));
                    return (
                      <tr key={Math.random()}>
                        {fieldArr.map(fieldResult => (
                          <td key={Math.random()}>{fieldResult}</td>
                        ))}
                      </tr>
                    );
                  })}
                {type === 'FORM' &&
                  slicedEmails.map(item => {
                    const fieldArr = tableHeadings.map(() => '-');
                    Object.keys(item.fields).forEach(field => {
                      tableHeadings.forEach((heading, headingInd) => {
                        if (
                          heading === field ||
                          headingInd === +field.split('-')[1]
                        ) {
                          fieldArr[headingInd] = item.fields[field];
                        }
                      });
                    });
                    fieldArr[fieldArr.length - 1] = reformatDate(
                      item.submitTime,
                    );
                    return (
                      <tr key={Math.random()}>
                        {fieldArr.map(fieldResult => (
                          <td key={Math.random()}>{fieldResult}</td>
                        ))}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {results.length > 50 && (
            <div className='collected-emails-warning'>
              <i className='icon-info' />
              {type === 'FORM'
                ? t('You can see only last 50 results.')
                : t('You can see only last 50 emails.')}
            </div>
          )}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <i
            className='icon-info'
            style={{ color: '#98241a', padding: '10px 5px' }}
          />
          {t('There is no data.')}
        </div>
      )}
    </>
  );
};

CollectedEmails.propTypes = {
  campaign: PropTypes.shape({
    instanceId: PropTypes.string,
    type: PropTypes.string,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        headingInd: PropTypes.string,
      }),
    ),
  }),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default CollectedEmails;
