import React, { useState, useEffect } from 'react';
import moment from 'moment/moment';
import { v4 as uuid } from 'uuid';
import styles from './AlertModal.module.scss';
import { getIndividualMonitoringAlert } from '../../../../modules/account/ajax';
import { monitoringTypeMaps } from '../../../../constants/datamaps/account';
import { monitoringTypeFixer } from '../utils/utils';

const AlertModal = ({ accountId }) => {
  const [monitoringTypes, setMonitoringTypes] = useState([]);

  useEffect(() => {
    getIndividualMonitoringAlert(accountId, response => {
      const allMonitoringData = monitoringTypeFixer(
        monitoringTypeMaps,
        response,
      );
      setMonitoringTypes(allMonitoringData);
    });
  }, []);

  const textFixer = text => {
    return text === 'SUCCESS' ? 'OK' : text;
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <td className={styles['table-header']}>Monitoring Type</td>
          <td className={styles['table-header']}>Status</td>
          <td className={styles['table-header']}>Last Check Time</td>
          <td className={styles['table-header']}>Last Check Time with Error</td>
          <td className={styles['table-header']}>Error Details</td>
        </tr>
      </thead>
      <tbody>
        {monitoringTypes.map(item => {
          return (
            <tr key={uuid()} className={styles['table-row']}>
              <td className={styles['table-cell']}>
                {monitoringTypeMaps[item.monitoringType].name}
              </td>
              <td
                className={`${styles['table-cell']} ${
                  styles[item.status.toLowerCase()]
                }`}
              >
                {textFixer(item.status)}
              </td>
              <td className={styles['table-cell']}>
                {item.checkTime
                  ? moment(item.checkTime).format('DD/MM/YYYY HH:mm')
                  : '-'}
              </td>
              <td className={styles['table-cell']}>
                {item.lastCheckTimeWithError
                  ? moment(item.lastCheckTimeWithError).format(
                      'DD/MM/YYYY HH:mm',
                    )
                  : '-'}
              </td>
              <td className={styles['table-cell']}>
                {item.status === 'FAILED' ? item.details?.content : '-'}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AlertModal;
