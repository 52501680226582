import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const CallToAction = ({
                        width = "90px",
                        height = "72px",
                        color = "#CC4230"
                      }) => {
  const [state, setState] = useState({
    width: "",
    height: "",
    color: ""
  });

  const setStyles = () => {
    setState({
      width,
      height
    });
  };

  useEffect(() => {
    setStyles();
  }, [width, height, color]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 91 73"
      width={state.width}
      height={state.height}
      className="svg-icon"
      fill="none"
    >
      <rect x="0.5" y="0.5" width="90" height="72" rx="9.5" fill="white" stroke="#D1D1D1" />
      <rect x="15" y="21.5916" width="16" height="23.6479" rx="3" fill="#DFDFDF" />
      <rect x="38" y="21.5916" width="16" height="23.6479" rx="3" fill="#DFDFDF" />
      <rect x="61" y="21.5916" width="16" height="23.6479" rx="3" fill="#DFDFDF" />
      <line x1="16" y1="7.72534" x2="76" y2="7.72534" stroke="#DFDFDF" />
      <line x1="16" y1="11.838" x2="45" y2="11.838" stroke="#DFDFDF" />
      <line x1="16" y1="15.9507" x2="45" y2="15.9507" stroke="#DFDFDF" />
      <rect x="23" y="52.4366" width="45" height="11.3099" rx="5" fill="#CC4230" />
      <path
        d="M41.6542 60.662C41.1521 60.662 40.6978 60.5519 40.2912 60.3317C39.8895 60.1067 39.5739 59.8004 39.3443 59.4127C39.1148 59.0201 39 58.5798 39 58.0915C39 57.6033 39.1148 57.1653 39.3443 56.7776C39.5739 56.3851 39.8919 56.0788 40.2984 55.8586C40.7049 55.6336 41.1593 55.5211 41.6614 55.5211C42.0536 55.5211 42.4122 55.5881 42.7374 55.7222C43.0626 55.8514 43.34 56.0453 43.5696 56.3037L43.1033 56.7561C42.7255 56.3588 42.2544 56.1601 41.6901 56.1601C41.3171 56.1601 40.9799 56.2439 40.6786 56.4114C40.3773 56.579 40.1406 56.8111 39.9684 57.1079C39.8011 57.3999 39.7174 57.7278 39.7174 58.0915C39.7174 58.4553 39.8011 58.7856 39.9684 59.0824C40.1406 59.3744 40.3773 59.6041 40.6786 59.7716C40.9799 59.9392 41.3171 60.0229 41.6901 60.0229C42.2496 60.0229 42.7207 59.8219 43.1033 59.4198L43.5696 59.8722C43.34 60.1306 43.0603 60.3269 42.7303 60.4609C42.4051 60.595 42.0464 60.662 41.6542 60.662Z"
        fill="white" />
      <path d="M45.534 56.2032H43.8124V55.5786H47.9659V56.2032H46.2442V60.6045H45.534V56.2032Z" fill="white" />
      <path
        d="M51.6944 59.348H49.0258L48.4735 60.6045H47.7346L50.0086 55.5786H50.7188L53 60.6045H52.2468L51.6944 59.348ZM51.4433 58.7736L50.3601 56.3109L49.2769 58.7736H51.4433Z"
        fill="white" />
    </svg>
  );
};

CallToAction.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

CallToAction.defaultProps = {
  width: "90px",
  height: "72px",
  color: "#CC4230"
};

export default CallToAction;
