/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconPie extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            fill={this.state.color}
            className="svg-path"
            d="M226.2,51.8C104,51.79,5,150.82,5,273s99,221.21,221.2,221.2,221.21-99,221.2-221.2H226.2V51.8Z"
            transform="translate(-5 -5.81)"
          />
          <path
            fill={this.state.color}
            className="svg-path"
            d="M273.8,5.81V227H495C495,104.84,396,5.79,273.8,5.81Z"
            transform="translate(-5 -5.81)"
          />
        </g>
      </svg>
    );
  }
}

export default IconPie;
