/**
 * Created by mehmetyurtar on 10/18/17.
 */
import React from "react";
import { connect } from "react-redux";

import * as modals from "../../constants/datamaps/modals";
import {
  trendsMap,
  statTypes,
  personalizationTrendsMap
} from "../../constants/datamaps";

import { t, openModal } from "../../system/ui";
import { queryDate } from "../../system/date";
import { localeString, localeStringMoney } from "../../system/string";

import { transformCampaignReport } from "../../modules/transform";
import { transFormPersReport } from "../../modules/transform";
import {
  getCampaignReport,
  getRangedReport,
  getPersonalizationReport
} from "../../modules/reports/ajax";

import Currency from "../currency";
import Icon from "../icon";
import Icons from "../icons";

class SegmentationReport extends React.Component {
  constructor(props) {
    super(props);
  }

  getActionReport(action) {
    let actionReport = "";
    if (action === "segment") {
      actionReport = "segmentVisitor";
    } else if (action === "unsegment") {
      actionReport = "unsegmentVisitor";
    } else {
      actionReport = "toggleVisitorSegment";
    }
    return actionReport;
  }

  getStats(stats, action) {
    let newStats = {};
    let noReport = true;
    let availableReports = {};
    Object.keys(stats).map((item, index) => {
      let stat = stats[item].stats,
        type = statTypes[item];
      let mainCount = "";
      if (
        type.name === "Widget" ||
        type.name === "Interaction" ||
        type.name === "Basket" ||
        type.name === "Revenue" ||
        type.name === "Granted" ||
        type.name === "Denied" ||
        type.name === "Form Submit"
      ) {
        return;
      } else {
        mainCount = stat.all.count;
        if (mainCount) {
          noReport = false;
          availableReports[item] = stats[item];
        }
      }
    });
    if (noReport && stats[this.getActionReport(action)]) {
      newStats[this.getActionReport(action)] =
        stats[this.getActionReport(action)];
      return newStats;
    } else {
      return availableReports;
    }
  }

  render() {
    return this.props.compareMode ? (
      <div className="widget-stat-list widget-report">
        <div>
          {Object.keys(this.props.stats).map((item, index) => {
            let stat = this.props.stats[item].stats,
              type = statTypes[item];
            let mainCount = "",
              otherCount = "";
            if (
              type.name === "Widget" ||
              type.name === "Interaction" ||
              type.name === "Basket" ||
              type.name === "Revenue" ||
              type.name === "Granted" ||
              type.name === "Denied" ||
              type.name === "Form Submit"
            ) {
              return;
            } else {
              mainCount = stat.all.count;
              otherCount = this.props.comparisonStats[item].stats.all.count;
            }
            let className = "compare-stat stat-" + item.toLowerCase(),
              isSecondColumn = this.props.withCompareIcons,
              statDifference = isSecondColumn ? mainCount - otherCount : 0;
            return (
              <div className={className} key={item + "-" + index}>
                <div className="compare-stat-container one-half">
                  <h4 className="widget-stat-title">{t(type.name)}</h4>
                </div>
                <div
                  className="compare-stat-container one-half"
                  style={{ textAlign: "right", position: "relative" }}
                >
                  <p className="widget-stat-count">
                    {localeString(mainCount)}
                    <span className="compare-unit">
                      {type.currency === true ? (
                        <Currency />
                      ) : type.iconSvg ? (
                        <Icons name={type.icon} color="#b3b3b3" />
                      ) : (
                        <Icon name={type.icon} />
                      )}
                      {type.currency !== true ? t(type.unit) : ""}
                    </span>
                  </p>
                  <span
                    style={{
                      position: "absolute",
                      right: "0",
                      bottom: "-20px"
                    }}
                  >
                    {isSecondColumn && statDifference > 0 && (
                      <span style={{ color: "#56bc94" }}>
                        +{localeString(statDifference)}{" "}
                        <Icons name="increase" color="#56bc94" />
                      </span>
                    )}
                    {isSecondColumn && statDifference < 0 && (
                      <span style={{ color: "#dc3322" }}>
                        {" "}
                        {localeString(statDifference)}{" "}
                        <Icons name="decrease" color="#dc3322" />
                      </span>
                    )}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    ) : this.props.onViewAll ? (
      <div style={{ display: "inherit", width: "100%" }}>
        {Object.keys(this.getStats(this.props.stats, this.props.action)).map(
          (item, index) => {
            let stat = this.getStats(this.props.stats, this.props.action)[item]
                .stats,
              type = statTypes[item],
              className = "widget-stat stat-" + item.toLowerCase();
            let mainCount = "";
            if (
              type.name === "Widget" ||
              type.name === "Interaction" ||
              type.name === "Basket" ||
              type.name === "Revenue" ||
              type.name === "Granted" ||
              type.name === "Denied" ||
              type.name === "Form Submit"
            ) {
              return;
            } else {
              mainCount = stat.all.count;
            }
            return (
              <div className={className} key={item + "-" + index}>
                <h4 className="widget-stat-title">{t(type.name)}</h4>
                <p className="widget-stat-count">{localeString(mainCount)}</p>
                <p className="widget-stat-type">
                  {type.currency === true ? (
                    <Currency />
                  ) : type.iconSvg ? (
                    <Icons name={type.icon} color="#b3b3b3" />
                  ) : (
                    <Icon name={type.icon} />
                  )}
                  {type.currency !== true ? t(type.unit) : ""}
                </p>
              </div>
            );
          }
        )}
      </div>
    ) : (
      <div className="widget-stat-list widget-report">
        <div className="widget-stats">
          {Object.keys(this.getStats(this.props.stats, this.props.action)).map(
            (item, index) => {
              let stat = this.getStats(this.props.stats, this.props.action)[
                  item
                ].stats,
                type = statTypes[item],
                className = "widget-stat stat-" + item.toLowerCase();
              let mainCount = "";
              if (
                type.name === "Widget" ||
                type.name === "Interaction" ||
                type.name === "Basket" ||
                type.name === "Revenue" ||
                type.name === "Granted" ||
                type.name === "Denied" ||
                type.name === "Form Submit"
              ) {
                return;
              } else {
                mainCount = stat.all.count;
              }
              return (
                <div className={className} key={item + "-" + index}>
                  <h4 className="widget-stat-title">{t(type.name)}</h4>
                  <p className="widget-stat-count">{localeString(mainCount)}</p>
                  <p className="widget-stat-type">
                    {type.currency === true ? (
                      <Currency />
                    ) : type.iconSvg ? (
                      <Icons name={type.icon} color="#b3b3b3" />
                    ) : (
                      <Icon name={type.icon} />
                    )}
                    {type.currency !== true ? t(type.unit) : ""}
                  </p>
                </div>
              );
            }
          )}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias
});

export default connect(mapStatesToProps)(SegmentationReport);
