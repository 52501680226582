import { createReducer } from '../system/store';
import * as switchDataCenterActions from '../constants/actions/switchDataCenter';

const initialState = {
  selectedDataCenter: 'V3',
};

export default createReducer(initialState, {
  [switchDataCenterActions.default.UPDATE_SWITCH_DATACENTER]: (
    state,
    dataCenter,
  ) => {
    return { ...state, selectedDataCenter: dataCenter };
  },
  [switchDataCenterActions.default.RESET_SWITCH_DATACENTER]: () => {
    return { ...initialState };
  },
});
