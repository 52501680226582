/**
 * Created by mehmetyurtar on 10/26/17.
 */
import React from 'react';
import { t } from '../../system/ui';

import { reformatDate } from '../../system/date';

class FormStats extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='form-responses'>
        {this.props.results &&
          this.props.results.map(result => {
            if (this.props.unsubStats) {
              return (
                <div className='form-response form-response-unsub'>
                  <ul className='form-response--stats'>
                    <li>
                      <span>{decodeURIComponent(result.name)}</span>
                      {result.count && (
                        <span className='form-response--stats__count'>
                          {result.count.count}
                        </span>
                      )}
                      {result.count && result.count.count !== 0 && (
                        <div className='form-response--stats__percentage'>
                          <div
                            className='percentage__progress'
                            style={{ width: `${result.count.percentage}%` }}
                          />
                        </div>
                      )}
                      <span className='percentage__count'>
                        {result.count.percentage} %
                      </span>
                    </li>
                  </ul>
                </div>
              );
            }
            return (
              <div className='form-response'>
                <div className='form-response--title'>
                  <span>
                    {result.groupTitle ||
                      result.name ||
                      result.placeholder ||
                      result.type}
                  </span>
                  <span className='userResponses'>
                    {result.userResponses.length > 1
                      ? `${result.userResponses.length} ${t('responses')}`
                      : `${result.userResponses.length} ${t('response')}`}
                  </span>
                </div>
                <ul className='form-response--stats'>
                  {result.stats.map(stat => {
                    return (
                      <li>
                        <span>{stat.name}</span>
                        {stat.count && (
                          <span className='form-response--stats__count'>
                            {stat.count.count}
                          </span>
                        )}
                        {stat.count && stat.count.count !== 0 && (
                          <div className='form-response--stats__percentage'>
                            <div
                              className='percentage__progress'
                              style={{ width: `${stat.count.percentage}%` }}
                            />
                          </div>
                        )}
                        <span className='percentage__count'>
                          {stat.count.percentage} %
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        {this.props.unsubStats && (
          <div style={{ marginTop: '30px', textAlign: 'center' }}>
            <strong>{this.props.totalCount}</strong> Visitors are totally
            unsubscribed.
          </div>
        )}
      </div>
    );
  }
}

export default FormStats;
