import { t } from '../../../../system/ui';

const breadCrumbOptions = () => {
  return {
    home: {
      name: t('Recommend'),
      icon: 'heart',
      slug: '/recommendations',
    },
    current: {
      name: 'Widget Template Library',
    },
  };
};

const actions = [
  {
    icon: 'eye',
    handler: 'editAction',
    name: 'Edit',
    iconSvg: true,
  },
  {
    icon: 'history',
    handler: 'customHistoryAction',
    name: 'History',
    iconSvg: true,
  },
  {
    icon: 'archive',
    handler: 'removeAction',
    name: 'Archive',
    iconSvg: true,
  },
];

const eventMap = {
  USER: 'User',
  EVENT: 'Event',
  PRODUCT: 'Product',
  PRODUCT_COUNTERS: 'Product Counters',
  PRODUCT_PURCHASE_COUNTERS: 'Product Purchase Counters',
  CONSTANTS: 'Constants',
};

export { breadCrumbOptions, actions, eventMap };
