import { clone } from 'system/object';
import { getAccount } from 'modules/auth/user';
import api from 'system/api';
import {
  getApiKey,
  getToken,
  getSwitchAccountUser,
  getRegion,
  isSwitchAccount,
  isMultiAccountUser,
} from 'modules/auth/user';

export function revenueConverter(data, propName) {
  const dataClone = clone(data);
  const account = getAccount();
  const getValueWithCurrency = value => {
    if (account.mainCurrency.symbolBefore) {
      return `${account.mainCurrency.symbol}${value}`;
    }
    return `${value}${account.mainCurrency.symbol}`;
  };
  dataClone?.forEach(singleRow => {
    if (singleRow[propName] !== null && singleRow[propName] !== undefined) {
      singleRow[propName] = getValueWithCurrency(singleRow[propName]);
    }
  });
  return dataClone;
}

export function exportCb(start, end, lang, reportType) {
  const endpoint = api.getEndPoint('export/searchInsights');
  let exportUrl =
    endpoint +
    "?end=" + end +
    "&start=" + start +
    "&reportType=" + reportType +
    "&lang=" + lang +
    "&apiKey=" + getApiKey() +
    "&authToken=" + getToken();
  if (isSwitchAccount() && !isMultiAccountUser()) {
    exportUrl += "&switchUser=" + getSwitchAccountUser(true).username;
  }
  if (isMultiAccountUser() && isSwitchAccount()) {
    exportUrl += "&switchAccount=" + getAccount().accountId.trim();
  }
  exportUrl += "&switchRegion=" +  getRegion();
  window.open(exportUrl);
}
