import React from 'react';
import { connect } from 'react-redux';
import { t } from '../system/ui';
import Icons from './icons';
import Currency from './currency';
import { isSwitchAccount } from '../modules/auth/user';
import { store } from '../store';

class Summary extends React.Component {
  constructor(props) {
    super(props);
  }

  format(amount, noDecimal = false) {
    const account = isSwitchAccount() || store.getState().user.user.account;

    const {
      decimals,
      decimalSeparator,
      thousandSeparator,
    } = account.mainCurrency;
    return this._formatMoney(
      amount,
      decimals,
      decimalSeparator,
      thousandSeparator,
      noDecimal,
    );
  }

  _formatMoney(
    amount,
    decimalCount = 2,
    decimal = '.',
    thousands = ',',
    noDecimal = false,
  ) {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    if (thousands === '__NONE__') {
      thousands = '';
    }

    if (thousands === '__EMPTY__') {
      thousands = ' ';
    }

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)),
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    let result = '';
    if (noDecimal === false) {
      result =
        negativeSign +
        (j ? i.substring(0, j) + thousands : '') +
        i.substring(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : '');
    } else {
      result =
        negativeSign +
        (j ? i.substring(0, j) + thousands : '') +
        i.substring(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`);
    }

    return result;
  }

  render() {
    let { ratio } = this.props;
    if (ratio === 'NaN') {
      ratio = 0;
    } else if (ratio === 'Infinity' || ratio > 100) {
      ratio = 100;
    } else if (ratio % 1 === 0) {
      ratio = ratio.toString().split('.')[0];
    }
    return (
      <div style={{ marginBottom: '30px' }}>
        <h3 style={{ color: '#4c7497' }}>
          {t('Overall Segmentify Performance')}
        </h3>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridTemplateRows: '1fr',
            gridColumnGap: '10px',
          }}
        >
          {this.props.isBefore ? (
            <span className='summary-blocks'>
              <div className='icon-container'>
                <Icons name='money' width='70' height='70' color='#424242' />{' '}
              </div>
              <div className='text-container'>
                <div className='value-container'>
                  <Currency /> {this.format(this.props.summary.revenue, true)}
                </div>
                {t('Revenue Generated by Segmentify')}
              </div>
            </span>
          ) : (
            <span className='summary-blocks'>
              <div className='icon-container'>
                <Icons name='money' width='70' height='70' color='#424242' />{' '}
              </div>
              <div className='text-container'>
                <div className='value-container'>
                  {this.format(this.props.summary.revenue, true)} <Currency />
                </div>
                {t('Revenue Generated by Segmentify')}
              </div>
            </span>
          )}

          <span className='summary-blocks'>
            <div className='icon-container'>
              <Icons name='revenue' width='70' height='70' color='#424242' />{' '}
            </div>
            <div className='text-container'>
              <div className='value-container'>{this.format(ratio)}%</div>
              {t('Segmentify’s Revenue Contribution')}
            </div>
          </span>
          <span
            className='summary-blocks'
            style={{
              marginRight: '0px',
            }}
          >
            <div className='icon-container'>
              <Icons name='products' width='70' height='70' color='#424242' />{' '}
            </div>
            <div className='text-container'>
              <div className='value-container'>
                {this.format(this.props.summary.totalProducts, true)}
              </div>
              {t('Products Sold via Segmentify')}
            </div>
          </span>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  mainCurrency: store.user.user.account.mainCurrency,
});

export default connect(mapStatesToProps)(Summary);
