/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import { uiActions } from '../../../actions';
import { t, confirm } from '../../../system/ui';
import {
  moveCampaignToLive,
  moveCampaignToTest,
} from '../../../modules/rbs/ajax';
import { getCampaign, getCampaigns } from '../../../reducers/rbs/extraReducers';

import Icons from '../../../components/icons';
import { TextField } from '../../../components/fields';
import CriteriaDetail from '../../../components/ruleBasedSegmentation/CriteriaDetail';

const SegmentDetailPreview = ({ campaignDetails }) => {
  const dispatch = useDispatch();
  const rbsFilterState = useSelector(state => state.rbs.wizardFilter.data);
  const [campaignStatus, setCampaignStatus] = useState(
    campaignDetails.status.toLowerCase(),
  );

  useEffect(() => {
    if (campaignDetails) {
      dispatch(getCampaign(campaignDetails.instanceId));
    }
    return () => {
      dispatch(getCampaigns());
    };
  }, []);

  const showNotification = status => {
    uiActions.isLoaded();
    setCampaignStatus(status.toLowerCase());

    let note = () => {
      return <div>Segmentation is successfully updated in Draft mode.</div>;
    };
    if (status === 'ACTIVE') {
      note = () => {
        return <div>Segmentation is successfully updated in Live mode.</div>;
      };
    }
    uiActions.showNotification({
      content: note,
    });
  };

  const moveSegmentToLive = () => {
    const instanceId = campaignDetails?.instanceId;
    if (instanceId) {
      moveCampaignToLive(campaignDetails.instanceId)
        .then(response => {
          showNotification(response.status);
        })
        .catch(() => {
          uiActions.isLoaded();
        });
    }
  };

  const moveSegmentToTest = () => {
    const instanceId = campaignDetails?.instanceId;
    if (instanceId) {
      moveCampaignToTest(campaignDetails.instanceId)
        .then(response => {
          showNotification(response.status);
        })
        .catch(() => {
          uiActions.isLoaded();
        });
    }
  };

  const toggleCampaignStatus = () => {
    const fixedStatus =
      campaignStatus.toLowerCase() === 'test' ? 'Live' : 'Draft';
    confirm({
      title: `Update Campaign on ${fixedStatus} Mode?`,
      onConfirm: () => {
        uiActions.isLoading();
        if (fixedStatus === 'Live') {
          moveSegmentToLive();
        } else {
          moveSegmentToTest();
        }
      },
    });
  };

  const editTheSegment = () => {
    const path = `audience/rule-based-segmentation/edit/${campaignDetails.instanceId}`;

    browserHistory.push({
      pathname: path,
    });
  };

  return (
    <div className='rbs-wizard-save-preview'>
      <div className='rbs-detail-preview-buttons'>
        <div className='segment-options'>
          <label className='item is-toggle'>
            <input
              type='checkbox'
              checked={campaignStatus === 'active'}
              onClick={() => toggleCampaignStatus()}
            />
            <span className='item-label' />
          </label>
          <span className='item-help'>
            Draft / <span className='live'>Live</span>
          </span>
        </div>
        <a
          className='button preview-edit-button'
          onClick={() => editTheSegment()}
        >
          <Icons name='editPencil' /> Edit
        </a>
      </div>
      <div className='save-preview-segmentName-field readonly'>
        <span className='save-preview-title'>{t('Segment Name')}</span>
        <TextField
          className='save-preview-segmentName'
          name='segmentName'
          value={campaignDetails?.segmentName}
          readOnly
        />
      </div>
      <CriteriaDetail criteriaData={rbsFilterState} />
    </div>
  );
};

SegmentDetailPreview.propTypes = {
  campaignDetails: PropTypes.shape({}).isRequired,
};

export default SegmentDetailPreview;
