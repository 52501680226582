import React from "react";

class IconQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24.01 24.01"
        width={this.state.width}
        height={this.state.height}
      >
        <g>
          <g>
            <path
              d="M10.52,16.87a1.26,1.26,0,0,1,.37-.9,1.26,1.26,0,0,1,1.8,0,1.26,1.26,0,0,1,0,1.8,1.27,1.27,0,0,1-2.17-.9Zm.37-2.32c0-.11,0-.21-.05-.32s0-.22,0-.34a2.64,2.64,0,0,1,.29-1.22,3.07,3.07,0,0,1,1-1.1l.77-.57a1.54,1.54,0,0,0,.69-1.27,1.39,1.39,0,0,0-.1-.53,1.28,1.28,0,0,0-.29-.47,1.53,1.53,0,0,0-.51-.32,1.86,1.86,0,0,0-.7-.12,1.5,1.5,0,0,0-1.24.57,1.88,1.88,0,0,0-.3.58,2.5,2.5,0,0,0-.09.66,1.73,1.73,0,0,0,.06.51l-2-.08a1.93,1.93,0,0,1-.06-.36c0-.12,0-.24,0-.36a3.43,3.43,0,0,1,.24-1.25,3.16,3.16,0,0,1,.71-1.09,3.4,3.4,0,0,1,1.14-.76A4.08,4.08,0,0,1,12,6.42a4.53,4.53,0,0,1,1.63.27,3.56,3.56,0,0,1,1.18.72,2.84,2.84,0,0,1,.72,1,3.08,3.08,0,0,1,.24,1.19,3,3,0,0,1-.43,1.64,4.32,4.32,0,0,1-1.15,1.19l-.66.46a2.41,2.41,0,0,0-.64.68,1.75,1.75,0,0,0-.22.87v.1ZM12,21a8.69,8.69,0,0,0,4.5-1.22,9.26,9.26,0,0,0,3.29-3.29A8.69,8.69,0,0,0,21,12,8.7,8.7,0,0,0,19.8,7.5a9.26,9.26,0,0,0-3.29-3.29A8.69,8.69,0,0,0,12,3,8.7,8.7,0,0,0,7.5,4.21,9.34,9.34,0,0,0,4.21,7.5,8.7,8.7,0,0,0,3,12a8.69,8.69,0,0,0,1.22,4.5A9.34,9.34,0,0,0,7.5,19.8,8.7,8.7,0,0,0,12,21ZM12,0a11.7,11.7,0,0,1,6,1.62A12,12,0,0,1,22.39,6,11.71,11.71,0,0,1,24,12a11.7,11.7,0,0,1-1.62,6A12,12,0,0,1,18,22.39,11.7,11.7,0,0,1,12,24a11.71,11.71,0,0,1-6-1.62A12,12,0,0,1,1.62,18,11.7,11.7,0,0,1,0,12,11.71,11.71,0,0,1,1.62,6,12,12,0,0,1,6,1.62,11.71,11.71,0,0,1,12,0Z"
              fill={this.state.color}
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default IconQuestion;
