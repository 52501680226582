import React from 'react';
import connect from 'react-redux/es/connect/connect';
import ReactHighcharts from 'react-highcharts';
import FunnelChart from 'highcharts-funnel';
import { t } from '../../../system/ui';
import { localeString } from '../../../system/string';
import DeviceFilter from './sub-components/device-filter';
import { getOverallTotalSearches } from '../../../modules/search/insights/ajax';
import { dateActions, filterActions, uiActions } from '../../../actions';

// import CardWrapper from 'components/CardWrapper'; // absolute import example !!
import CardWrapper from '../../CardWrapper';

FunnelChart(ReactHighcharts.Highcharts);

class SearchInsightsTotalSearches extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      range: undefined,
      requestParameter: {
        lang: '',
        device: '',
        start: '',
        end: '',
        limit: '',
        sort: '',
        reverse: false,
        hourly: false,
      },
      selectedGraphView: 'daily',
    };

    this.getData = this.getData.bind(this);
    this.getConfig = this.getConfig.bind(this);
    this.selectDevice = this.selectDevice.bind(this);
    this.selectGraphView = this.selectGraphView.bind(this);
    this.deviceFilterLookup = ['PC', 'TABLET', 'MOBILE', 'IOS', 'ANDROID'];
  }

  getConfig() {
    const config = {
      title: {
        text: '',
        margin: 0,
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        type: 'datetime',
        tickInterval: '',
        labels: {
          align: 'left',
        },
      },
      yAxis: [
        {
          title: {
            text: '',
          },
          min: 0,
          showFirstLabel: false,
          allowDecimals: false,
          labels: {},
        },
        {
          title: {
            text: t('Searches'),
          },
          min: 0,
          showFirstLabel: false,
          allowDecimals: false,
          labels: {},
          opposite: true,
        },
      ],
      options: {
        chart: {
          type: 'spline',
          showAxes: true,
          zoomType: 'x',
          marginLeft: 75,
          spacingTop: 30,
          spacingBottom: 30,
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          borderRadius: 10,
          borderWidth: 3,
          backgroundColor: 'rgba(225, 225, 255, 0.9)',
        },
        exporting: {
          buttons: {
            contextButton: {
              y: -30,
              x: -25,
            },
          },
          chartOptions: {
            title: {
              text: 'Total Search Counts',
            },
          },
        },
      },
      loading: true,
      series: [],
      credits: { enabled: false },
    };

    if (!this.state.data || Object.keys(this.state.data).length === 0) {
      return config;
    }
    const availableFields = [
      {
        name: 'overall',
        label: t('Total Searches'),
        isVisible: true,
        type: 'line',
        yAxis: 0,
        zIndex: 2,
      },
      {
        name: 'unique',
        label: t('Total Unique Searches'),
        isVisible: true,
        type: 'line',
        yAxis: 0,
        zIndex: 2,
      },
    ];
    availableFields.forEach(field => {
      let data = this.state.data[field.name];
      data = data
        .map(item => {
          if (item.hour) {
            return {
              x: new Date(this.state.requestParameter.start).setHours(
                item.hour.split(':')[0],
                0,
                0,
                0,
              ),
              name: item.hour,
              y: item.count,
            };
          }
          return {
            x: Date.parse(item.day),
            y: item.count,
          };
        })
        .sort(function(item1, item2) {
          // Compare the 2 dates
          if (item1.x < item2.x) {
            return -1;
          }
          if (item1.x > item2.x) {
            return 1;
          }
          return 0;
        });

      config.series.push({
        id: field.name,
        name: field.label || field.name,
        data,
        visible: field.isVisible,
        fillOpacity: 0.3,
        marker: {
          enabled: true,
          fillColor: '#FFFFFF',
          lineColor: null,
          lineWidth: 2,
        },
        type: field.type,
        yAxis: field.yAxis,
        zIndex: field.type === 'line' ? 2 : 1,
      });
    });

    const timeFrame = {};
    const oneDayTime = 24 * 60 * 60 * 1000;
    if (this.props.range[1] && this.props.range[0]) {
      const timeDiff = this.props.range[1] - this.props.range[0];

      if (timeDiff < oneDayTime) {
        timeFrame.mode = 'no_aggregate';
      } else if (timeDiff < 8 * oneDayTime) {
        timeFrame.mode = 'daily';
      } else {
        timeFrame.mode = 'monthly';
      }
    }

    if (this.state.selectedGraphView === 'hourly') {
      timeFrame.mode = 'hourly';
    }

    switch (timeFrame.mode) {
      case 'monthly':
        config.xAxis.minTickInterval = 28 * oneDayTime; // 1 month
        config.xAxis.tickInterval = 30 * oneDayTime; // 1 month
        break;
      case 'daily':
        config.xAxis.tickInterval = oneDayTime; // one day
        break;
      case 'hourly':
        config.xAxis.tickInterval = 3600 * 1000;
        config.xAxis.minTickInterval = 3600 * 1000;
        config.xAxis.labels.format = '{value:%H:%M}';
        break;
      case 'no_aggregate':
        config.xAxis.tickInterval = 3600 * 1000; // one hour
        config.xAxis.labels.format = '{value:%H:%M}';
        break;
      default:
        config.xAxis.tickInterval = 3 * oneDayTime;
    }

    const totals = {};
    config.series.forEach(serie => {
      totals[serie.name] = 0;
      serie.data.forEach(data => {
        totals[serie.name] += data.y;
      });
    });

    config.legend = {
      useHTML: true,
      labelFormatter() {
        return (
          `${'<div style="">' +
            '<div class="widget-stats">' +
            '<div class="widget-stat stat-widget">' +
            '<h4 class="widget-stat-title">'}${t(this.name)}</h4>` +
          `<p class="widget-stat-count" style="color:${
            this.color
          };text-align:center;">${localeString(totals[this.name])}</p>` +
          `<p style="font-weight:normal;color:#bdbdbd">${t('Total')}</p>` +
          '</div>' +
          '</div>' +
          '</div>'
        );
      },
    };

    return config;
  }

  componentDidMount() {
    filterActions.filtersUpdated();
    filterActions.filtersReset();
    dateActions.updateRangeAlias('lastSevenDays', false);
    this.setState(
      {
        requestParameter: {
          lang: this.props.selectedLanguage,
          device: 'all',
          start: this.props.range[0],
          end: this.props.range[1],
        },
      },
      () => {
        this.getData();
      },
    );
  }

  componentWillReceiveProps(newProps) {
    if (newProps.range[0] && newProps.range[1]) {
      this.setState(
        {
          requestParameter: {
            ...this.state.requestParameter,
            lang: newProps.selectedLanguage,
            device: 'all',
            start: newProps.range[0],
            end: newProps.range[1],
          },
        },
        () => {
          this.getData();
        },
      );
    }
  }

  selectDevice(selectedDevice) {
    this.setState(
      {
        requestParameter: {
          lang: this.props.selectedLanguage,
          device: selectedDevice,
          start: this.props.range[0],
          end: this.props.range[1],
          hourly: this.state.requestParameter.hourly,
        },
      },
      () => {
        this.getData();
      },
    );
  }

  getData() {
    uiActions.isLoading();
    getOverallTotalSearches(
      this.state.requestParameter,
      totalSearchesResponse => {
        uiActions.isLoaded();
        this.setState({
          data: totalSearchesResponse,
        });
      },
    );
  }

  selectGraphView(selectedGraphView) {
    const oldRequestParameter = this.state.requestParameter;
    this.setState(
      {
        selectedGraphView,
        requestParameter: {
          ...oldRequestParameter,
          hourly: selectedGraphView === 'hourly',
        },
      },
      () => {
        this.getData();
      },
    );
  }

  render() {
    return (
      <CardWrapper
        hasDeviceFilter
        devicesLookup={this.deviceFilterLookup}
        devicesValue={this.selectDevice}
        label={t('Total Searches')}
        infoContent={
          <div className='search__info-text'>
            <strong> Total Searches: </strong>
            <span>
              This number shows the total number of search queries for the
              entire duration of your selected date range.
            </span>
          </div>
        }
        isTimeSelectVisible
        selectGraphView={this.selectGraphView}
        selectedGraphView={this.state.selectedGraphView}
      >
        <ReactHighcharts config={this.getConfig()} />
      </CardWrapper>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  selectedLanguage: store.switchLanguage.selectedLanguage,
});

export default connect(mapStatesToProps)(SearchInsightsTotalSearches);
