const htmlTemplate =
  '<div class="seg-scratch-drop-layout">\n' +
  '    <div class="seg-scratch-modal-bg">\n' +
  '        <div class="seg-scratch">\n' +
  '            <div class="seg-scratch-header">\n' +
  '                <!-- Scratch Header-->\n' +
  '            </div>\n' +
  '            <div class="seg-scratch-modal-close">\n' +
  '                <svg\n' +
  '                        width="24"\n' +
  '                        height="24"\n' +
  '                        viewBox="0 0 17 17"\n' +
  '                        fill="none"\n' +
  '                        xmlns="http://www.w3.org/2000/svg"\n' +
  '                >\n' +
  '                    <path\n' +
  '                            d="M12.3062 1.9375C13.4875 2.59375 14.4062 3.5125 15.0625 4.69375C15.7187 5.875 16.1125 7.05629 16.1125 8.50004C16.1125 9.94379 15.7187 11.125 15.0625 12.3062C14.4062 13.4875 13.4875 14.4063 12.3062 15.0625C11.125 15.7188 9.94372 16.1125 8.49997 16.1125C7.05622 16.1125 5.87498 15.7188 4.69373 15.0625C3.51248 14.4063 2.59373 13.4875 1.93748 12.3062C1.28123 11.125 0.887451 9.94379 0.887451 8.50004C0.887451 7.05629 1.28123 5.875 1.93748 4.69375C2.59373 3.5125 3.51248 2.59375 4.69373 1.9375C5.87498 1.28125 7.05622 0.887512 8.49997 0.887512C9.94372 0.887512 11.125 1.28125 12.3062 1.9375ZM11.3875 13.3562C12.3062 12.8312 12.9625 12.175 13.4875 11.2563C14.0125 10.3375 14.275 9.41874 14.275 8.36874C14.275 7.31874 14.0125 6.39997 13.4875 5.48122C12.9625 4.56247 12.3062 3.90625 11.3875 3.38125C10.4687 2.85625 9.54997 2.59375 8.49997 2.59375C7.44997 2.59375 6.53121 2.85625 5.61246 3.38125C4.69371 3.90625 4.03745 4.56247 3.51245 5.48122C2.98745 6.39997 2.72498 7.31874 2.72498 8.36874C2.72498 9.41874 2.98745 10.3375 3.51245 11.2563C4.03745 12.175 4.69371 12.8312 5.61246 13.3562C6.53121 13.8812 7.44997 14.1438 8.49997 14.1438C9.54997 14.1438 10.4687 13.8812 11.3875 13.3562ZM11.7812 6.39999L9.81247 8.36874L11.7812 10.3375L10.4687 11.65L8.49997 9.68124L6.53122 11.65L5.21872 10.3375L7.18747 8.36874L5.21872 6.39999L6.53122 5.08749L8.49997 7.05624L10.4687 5.08749L11.7812 6.39999Z"\n' +
  '                            fill="#656565"\n' +
  '                    />\n' +
  '                </svg>\n' +
  '            </div>\n' +
  '            <div class="seg-scratch-container">\n' +
  '                <div class="seg-scratch-content">\n' +
  '                    <div class="seg-scratch">\n' +
  '                        <div class="seg-coupon-container">\n' +
  '                            <div class="seg-scratch-coupon"></div>\n' +
  '                            <canvas id="seg-scratch-canvas" width="440" height="85"></canvas>\n' +
  '                        </div>\n' +
  '                    </div>\n' +
  '                    <div class="seg-scratch-description"></div>\n' +
  '                </div>\n' +
  '            </div>\n' +
  '        </div>\n' +
  '    </div>\n' +
  '    <!--Will appear after scratch finish -->\n' +
  '    <div class="seg-scratch-win-bg">\n' +
  '        <div class="seg-scratch">\n' +
  '            <div class="seg-scratch-header">Congratulations!</div>\n' +
  '            <div class="seg-scratch-modal-close">\n' +
  '                <svg\n' +
  '                        width="24"\n' +
  '                        height="24"\n' +
  '                        viewBox="0 0 17 17"\n' +
  '                        fill="none"\n' +
  '                        xmlns="http://www.w3.org/2000/svg"\n' +
  '                >\n' +
  '                    <path\n' +
  '                            d="M12.3062 1.9375C13.4875 2.59375 14.4062 3.5125 15.0625 4.69375C15.7187 5.875 16.1125 7.05629 16.1125 8.50004C16.1125 9.94379 15.7187 11.125 15.0625 12.3062C14.4062 13.4875 13.4875 14.4063 12.3062 15.0625C11.125 15.7188 9.94372 16.1125 8.49997 16.1125C7.05622 16.1125 5.87498 15.7188 4.69373 15.0625C3.51248 14.4063 2.59373 13.4875 1.93748 12.3062C1.28123 11.125 0.887451 9.94379 0.887451 8.50004C0.887451 7.05629 1.28123 5.875 1.93748 4.69375C2.59373 3.5125 3.51248 2.59375 4.69373 1.9375C5.87498 1.28125 7.05622 0.887512 8.49997 0.887512C9.94372 0.887512 11.125 1.28125 12.3062 1.9375ZM11.3875 13.3562C12.3062 12.8312 12.9625 12.175 13.4875 11.2563C14.0125 10.3375 14.275 9.41874 14.275 8.36874C14.275 7.31874 14.0125 6.39997 13.4875 5.48122C12.9625 4.56247 12.3062 3.90625 11.3875 3.38125C10.4687 2.85625 9.54997 2.59375 8.49997 2.59375C7.44997 2.59375 6.53121 2.85625 5.61246 3.38125C4.69371 3.90625 4.03745 4.56247 3.51245 5.48122C2.98745 6.39997 2.72498 7.31874 2.72498 8.36874C2.72498 9.41874 2.98745 10.3375 3.51245 11.2563C4.03745 12.175 4.69371 12.8312 5.61246 13.3562C6.53121 13.8812 7.44997 14.1438 8.49997 14.1438C9.54997 14.1438 10.4687 13.8812 11.3875 13.3562ZM11.7812 6.39999L9.81247 8.36874L11.7812 10.3375L10.4687 11.65L8.49997 9.68124L6.53122 11.65L5.21872 10.3375L7.18747 8.36874L5.21872 6.39999L6.53122 5.08749L8.49997 7.05624L10.4687 5.08749L11.7812 6.39999Z"\n' +
  '                            fill="#656565"\n' +
  '                    />\n' +
  '                </svg>\n' +
  '            </div>\n' +
  '            <div class="seg-scratch-container">\n' +
  '                <div class="seg-scratch-win-description">\n' +
  '                    <!-- Win DESCRIPTION -->\n' +
  '                </div>\n' +
  '            </div>\n' +
  '            <div class="seg-scratch-win-coupon-container">\n' +
  '                <input readonly class="seg-scratch-win-coupon" />\n' +
  '                <!-- COUPON Code -->\n' +
  '                <button class="seg-scratch-copy-coupon">\n' +
  '                    <div class="seg-copy-btn-initial-state">\n' +
  '                        <svg\n' +
  '                                width="15"\n' +
  '                                height="17"\n' +
  '                                viewBox="0 0 15 17"\n' +
  '                                fill="none"\n' +
  '                                xmlns="http://www.w3.org/2000/svg"\n' +
  '                        >\n' +
  '                            <path\n' +
  '                                    d="M9.60603 16.7609H2.3856C1.85898 16.7604 1.3542 16.5377 0.98189 16.1415C0.60958 15.7453 0.400275 15.208 0.399902 14.6477V5.42621C0.400299 4.86576 0.60959 4.32853 0.98189 3.9323C1.35419 3.53606 1.85898 3.3133 2.3856 3.31291H3.28808V2.35229C3.28847 1.79197 3.49776 1.25475 3.87006 0.858512C4.24236 0.462276 4.74729 0.239521 5.27377 0.239124H12.4942C13.0208 0.239546 13.5256 0.462288 13.8979 0.858512C14.2702 1.25474 14.4795 1.79197 14.4799 2.35229V11.5738C14.4795 12.1342 14.2702 12.6715 13.8979 13.0677C13.5256 13.4639 13.0208 13.6867 12.4942 13.6871H11.5917V14.6477C11.5913 15.208 11.382 15.7452 11.0097 16.1415C10.6374 16.5377 10.1325 16.7605 9.60603 16.7609ZM1.48292 5.42636V14.6478C1.48331 14.9024 1.57851 15.1465 1.74774 15.3266C1.91684 15.5066 2.14621 15.6079 2.38554 15.6083H9.60597C9.84528 15.6079 10.0745 15.5066 10.2438 15.3266C10.413 15.1465 10.5082 14.9024 10.5086 14.6478V5.42636C10.5082 5.17167 10.413 4.92753 10.2438 4.74757C10.0745 4.56746 9.8453 4.46615 9.60597 4.46573H2.38554C2.14623 4.46615 1.91683 4.56746 1.74774 4.74757C1.57851 4.92754 1.48331 5.17165 1.48292 5.42636ZM12.4941 12.5345C12.7334 12.534 12.9628 12.4327 13.1319 12.2526C13.3012 12.0727 13.3964 11.8285 13.3968 11.5738V2.35235C13.3964 2.09781 13.3012 1.85367 13.1319 1.67356C12.9628 1.4936 12.7335 1.39229 12.4941 1.39186H5.27371C5.0344 1.39229 4.80514 1.4936 4.6359 1.67356C4.46667 1.85367 4.37148 2.09779 4.37108 2.35235V3.31297H9.6061C10.1326 3.31339 10.6375 3.53613 11.0098 3.93236C11.3821 4.32858 11.5914 4.86582 11.5918 5.42628V12.5344L12.4941 12.5345Z"\n' +
  '                                    fill="white"\n' +
  '                            />\n' +
  '                        </svg>\n' +
  '                        Copy\n' +
  '                    </div>\n' +
  '                    <div class="seg-copy-btn-final-state" style="display: none">\n' +
  '                        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '                            <path\n' +
  '                                    d="M1 6.5L5.71716 11.2172C5.87337 11.3734 6.12663 11.3734 6.28284 11.2172L16.5 1"\n' +
  '                                    stroke="white"\n' +
  '                                    stroke-width="1.5"\n' +
  '                                    stroke-linecap="round">\n' +
  '                            </path>\n' +
  '                        </svg>\n' +
  '                        <span>Copied</span>\n' +
  '                    </div>\n' +
  '                </button>\n' +
  '            </div>\n' +
  '            <a class="seg-scratch-win-description-button" href="#" role="button">\n' +
  '                Use Now!\n' +
  '            </a>\n' +
  '        </div>\n' +
  '    </div>\n' +
  '</div>\n' +
  '<div class="seg-coupon-ready-container">\n' +
  '    <div class="seg-coupon-ready-icon">\n' +
  '        <svg width="35" height="35" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '            <path d="M29.5841 7.90054C29.0035 7.32004 28.0623 7.32004 27.4818 7.90054L7.90249 27.4798C7.32199 28.0603 7.32199 29.0016 7.90249 29.5821L10.0197 31.6993C10.807 31.2591 11.7146 31.0085 12.6767 31.0085C15.6869 31.0085 18.1272 33.4488 18.1272 36.459C18.1272 37.4212 17.8767 38.3288 17.4365 39.116L19.5539 41.2336C20.1345 41.8141 21.0756 41.8141 21.6561 41.2336L41.2355 21.6542C41.8161 21.0737 41.8161 20.1325 41.2355 19.552L39.1182 17.4347C38.3307 17.875 37.4228 18.1257 36.4606 18.1257C33.4503 18.1257 31.0101 15.6855 31.0101 12.6753C31.0101 11.7129 31.2608 10.805 31.7012 10.0176L29.5841 7.90054ZM25.3797 5.79833C27.1213 4.05681 29.9448 4.05681 31.6864 5.79833L34.008 8.12018C35.0204 9.13248 34.8391 10.5535 34.3285 11.412C34.1093 11.7808 33.9831 12.211 33.9831 12.6753C33.9831 14.0436 35.0922 15.1528 36.4606 15.1528C36.9247 15.1528 37.355 15.0265 37.7237 14.8072C38.5823 14.2968 40.0033 14.1154 41.0155 15.1277L43.3376 17.4498C45.0792 19.1913 45.0792 22.0149 43.3376 23.7565L23.7584 43.3357C22.0169 45.0772 19.1932 45.0772 17.4517 43.3357L15.1295 41.0136C14.1173 40.0014 14.2985 38.5805 14.8088 37.7221C15.028 37.3533 15.1542 36.9232 15.1542 36.459C15.1542 35.0908 14.045 33.9815 12.6767 33.9815C12.2126 33.9815 11.7824 34.1078 11.4138 34.327C10.5553 34.8373 9.13443 35.0185 8.12225 34.0063L5.80028 31.6844C4.05875 29.9428 4.05877 27.1193 5.80028 25.3777L25.3797 5.79833Z" fill="white"/>\n' +
  '            <path d="M32.1454 24.6112C31.5628 24.426 31.0976 24.034 30.6485 23.632C29.5654 22.6626 28.5673 21.5943 27.5264 20.5812C26.4695 19.5526 25.4226 18.349 24.1426 17.5813C23.7137 17.324 23.4821 16.9914 23.799 17.5469C24.5067 18.7876 24.9677 20.1466 25.523 21.4559C26.0914 22.796 26.6501 24.1049 27.4989 25.3004C27.568 25.3977 28.86 27.0395 28.4664 26.9871C25.6755 26.6155 22.9714 24.9673 20.752 23.3436C20.0536 22.8327 19.3356 22.2911 18.7918 21.6138C18.4228 21.1543 18.7293 21.7818 18.8199 21.9815C19.6595 23.831 20.3051 25.7468 21.1151 27.6053C21.1383 27.6586 21.7094 28.8537 21.3859 28.8886C20.9428 28.9364 20.3968 28.5873 20.043 28.3877C19.0023 27.8005 17.9207 27.2286 17.1049 26.3497" stroke="white" stroke-width="1.7" stroke-linecap="round"/>\n' +
  '        </svg>         \n' +
  '    </div>\n' +
  '    <div class="seg-coupon-ready-modal">\n' +
  '        <div class="seg-coupon-ready-header">\n' +
  '            <span class="seg-coupon-ready-header-text">Your Coupon Code</span>\n' +
  '            <div class="seg-arrow-close-icon">\n' +
  '                <svg\n' +
  '                        width="12"\n' +
  '                        height="12"\n' +
  '                        viewBox="0 0 12 12"\n' +
  '                        fill="none"\n' +
  '                        xmlns="http://www.w3.org/2000/svg"\n' +
  '                >\n' +
  '                    <path\n' +
  '                            d="M1.88953 10.3717L1.8095 1.53215C1.81039 1.40583 1.75839 1.28813 1.66691 1.19829C1.57646 1.10947 1.45683 1.06063 1.33052 1.06178C1.20625 1.0629 1.0865 1.11288 0.996661 1.20436C0.907835 1.29481 0.859002 1.41444 0.860145 1.54075L0.946494 10.8529C0.948855 11.1137 1.16367 11.3246 1.42444 11.3223L10.7377 11.239C10.8619 11.2379 10.9796 11.1859 11.0715 11.0964C11.0725 11.0954 11.0745 11.0933 11.0756 11.0923C11.1341 11.0327 11.185 10.911 11.185 10.911C11.1998 10.863 11.2085 10.813 11.2091 10.761C11.2067 10.5003 10.9919 10.2893 10.7311 10.2917L1.88953 10.3717Z"\n' +
  '                            fill="white"\n' +
  '                            stroke="white"\n' +
  '                    />\n' +
  '                </svg>\n' +
  '            </div>\n' +
  '        </div>\n' +
  '        <div class="seg-coupon-ready-body">\n' +
  '            <div class="seg-coupon-ready-header-content"></div>\n' +
  '            <div class="seg-scratch-win-coupon-container">\n' +
  '                <input  readonly class="seg-scratch-win-coupon" />\n' +
  '                <!-- COUPON Code -->\n' +
  '                <button class="seg-scratch-copy-coupon">\n' +
  '                    <div class="seg-copy-btn-initial-state">\n' +
  '                        <svg\n' +
  '                                width="15"\n' +
  '                                height="17"\n' +
  '                                viewBox="0 0 15 17"\n' +
  '                                fill="none"\n' +
  '                                xmlns="http://www.w3.org/2000/svg"\n' +
  '                        >\n' +
  '                            <path\n' +
  '                                    d="M9.60603 16.7609H2.3856C1.85898 16.7604 1.3542 16.5377 0.98189 16.1415C0.60958 15.7453 0.400275 15.208 0.399902 14.6477V5.42621C0.400299 4.86576 0.60959 4.32853 0.98189 3.9323C1.35419 3.53606 1.85898 3.3133 2.3856 3.31291H3.28808V2.35229C3.28847 1.79197 3.49776 1.25475 3.87006 0.858512C4.24236 0.462276 4.74729 0.239521 5.27377 0.239124H12.4942C13.0208 0.239546 13.5256 0.462288 13.8979 0.858512C14.2702 1.25474 14.4795 1.79197 14.4799 2.35229V11.5738C14.4795 12.1342 14.2702 12.6715 13.8979 13.0677C13.5256 13.4639 13.0208 13.6867 12.4942 13.6871H11.5917V14.6477C11.5913 15.208 11.382 15.7452 11.0097 16.1415C10.6374 16.5377 10.1325 16.7605 9.60603 16.7609ZM1.48292 5.42636V14.6478C1.48331 14.9024 1.57851 15.1465 1.74774 15.3266C1.91684 15.5066 2.14621 15.6079 2.38554 15.6083H9.60597C9.84528 15.6079 10.0745 15.5066 10.2438 15.3266C10.413 15.1465 10.5082 14.9024 10.5086 14.6478V5.42636C10.5082 5.17167 10.413 4.92753 10.2438 4.74757C10.0745 4.56746 9.8453 4.46615 9.60597 4.46573H2.38554C2.14623 4.46615 1.91683 4.56746 1.74774 4.74757C1.57851 4.92754 1.48331 5.17165 1.48292 5.42636ZM12.4941 12.5345C12.7334 12.534 12.9628 12.4327 13.1319 12.2526C13.3012 12.0727 13.3964 11.8285 13.3968 11.5738V2.35235C13.3964 2.09781 13.3012 1.85367 13.1319 1.67356C12.9628 1.4936 12.7335 1.39229 12.4941 1.39186H5.27371C5.0344 1.39229 4.80514 1.4936 4.6359 1.67356C4.46667 1.85367 4.37148 2.09779 4.37108 2.35235V3.31297H9.6061C10.1326 3.31339 10.6375 3.53613 11.0098 3.93236C11.3821 4.32858 11.5914 4.86582 11.5918 5.42628V12.5344L12.4941 12.5345Z"\n' +
  '                                    fill="white"\n' +
  '                            />\n' +
  '                        </svg>\n' +
  '                        Copy\n' +
  '                    </div>\n' +
  '                    <div class="seg-copy-btn-final-state" style="display: none">\n' +
  '                        <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '                            <path\n' +
  '                                    d="M1 6.5L5.71716 11.2172C5.87337 11.3734 6.12663 11.3734 6.28284 11.2172L16.5 1"\n' +
  '                                    stroke="white"\n' +
  '                                    stroke-width="1.5"\n' +
  '                                    stroke-linecap="round">\n' +
  '                            </path>\n' +
  '                        </svg>\n' +
  '                        <span>Copied</span>\n' +
  '                    </div>\n' +
  '                </button>\n' +
  '            </div>\n' +
  '            <div class="seg-icon-wrapper">\n' +
  '                <div class="seg-coupon-icon">\n' +
  '                    <svg width="35" height="35" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '                        <path d="M28.7989 7.11636C28.2184 6.53586 27.2771 6.53586 26.6966 7.11636L7.11733 26.6956C6.53683 27.2762 6.53683 28.2174 7.11733 28.7979L9.23452 30.9151C10.0218 30.4749 10.9295 30.2244 11.8916 30.2244C14.9018 30.2244 17.342 32.6646 17.342 35.6748C17.342 36.6371 17.0915 37.5446 16.6513 38.3319L18.7688 40.4494C19.3493 41.0299 20.2905 41.0299 20.871 40.4494L40.4504 20.87C41.0309 20.2895 41.0309 19.3483 40.4504 18.7678L38.333 16.6505C37.5456 17.0909 36.6376 17.3415 35.6754 17.3415C32.6652 17.3415 30.2249 14.9013 30.2249 11.8911C30.2249 10.9287 30.4757 10.0208 30.9161 9.23341L28.7989 7.11636ZM24.5945 5.01415C26.3361 3.27263 29.1596 3.27263 30.9012 5.01415L33.2229 7.336C34.2353 8.3483 34.0539 9.76934 33.5434 10.6279C33.3242 10.9966 33.1979 11.4268 33.1979 11.8911C33.1979 13.2594 34.307 14.3686 35.6754 14.3686C36.1396 14.3686 36.5699 14.2423 36.9385 14.023C37.7971 13.5126 39.2182 13.3312 40.2304 14.3435L42.5525 16.6656C44.294 18.4071 44.294 21.2307 42.5525 22.9723L22.9733 42.5515C21.2317 44.2931 18.4081 44.2931 16.6666 42.5515L14.3444 40.2294C13.3322 39.2172 13.5134 37.7963 14.0237 36.9379C14.2428 36.5691 14.3691 36.139 14.3691 35.6748C14.3691 34.3067 13.2599 33.1973 11.8916 33.1973C11.4274 33.1973 10.9973 33.3236 10.6286 33.5428C9.77012 34.0532 8.34927 34.2343 7.33709 33.2221L5.01512 30.9002C3.27359 29.1587 3.27361 26.3351 5.01512 24.5936L24.5945 5.01415Z" fill="#433C97"/>\n' +
  '                        <path d="M31.3602 23.827C30.7777 23.6418 30.3125 23.2498 29.8634 22.8479C28.7802 21.8784 27.7822 20.8101 26.7412 19.797C25.6843 18.7684 24.6374 17.5649 23.3574 16.7971C22.9285 16.5398 22.697 16.2072 23.0139 16.7628C23.7216 18.0034 24.1826 19.3624 24.7379 20.6717C25.3063 22.0119 25.8649 23.3208 26.7137 24.5162C26.7828 24.6135 28.0749 26.2553 27.6812 26.2029C24.8903 25.8313 22.1863 24.1831 19.9668 22.5594C19.2685 22.0486 18.5504 21.5069 18.0066 20.8296C17.6377 20.3702 17.9441 20.9976 18.0348 21.1973C18.8743 23.0469 19.5199 24.9626 20.33 26.8212C20.3532 26.8744 20.9243 28.0696 20.6007 28.1044C20.1576 28.1522 19.6116 27.8031 19.2579 27.6035C18.2171 27.0163 17.1356 26.4445 16.3197 25.5655" stroke="#433C97" stroke-width="1.7" stroke-linecap="round"/>\n' +
  '                    </svg>\n' +
  '                </div>\n' +
  '            </div>\n' +
  '        </div>\n' +
  '    </div>\n' +
  '</div>';

const preJSTemplate =
  '// this function will get a configuration object to be executed at html rendering with mustache.js\n' +
  '// conf object has two pre-added objects:\n' +
  '//   - title: name of the recommendation widget\n' +
  '//   - products: products array to be used at recommendation widget\n' +
  '// do not change the function name\n' +
  'function preRenderConf(conf) {\n' +
  '  // add your custom code inside the function\n' +
  '}';

const postJSTemplate = '';

const cssTemplate = '';

export { htmlTemplate, preJSTemplate, postJSTemplate, cssTemplate };
