/**
 * Created by mehmetyurtar on 2/2/18.
 */

import React from "react";

class IconFeedback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
      >
        <path
          fill={this.state.color}
          d="M15,0H1C0.448,0,0,0.448,0,1v10c0,0.552,0.448,1,1,1h3v4l6-4h5c0.552,0,1-0.448,1-1V1
	C16,0.448,15.552,0,15,0z"
        />
      </svg>
    );
  }
}

export default IconFeedback;
