import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from '../../system/ui';
import { idPropFunc } from '../../system/id';
import Icon from '../icon';
import SearchActions from '../widget/search-actions';
import { devices } from '../../constants/datamaps/campaigns';
import CampaignRuleSetItem from './faceted-search/rule-set/CampaignRuleSetItem';

const scenarioName = {
  BEFORE_SEARCH: 'Before Search Input',
  SEARCH: 'After Search Input',
};

export default function SearchsList({ searchs, id, getSearchs }) {
  const [open, setOpen] = useState(false);
  function getCampaigns(allSearchs, status) {
    return allSearchs.filter(
      search => search.status === status && search.type === 'instant',
    );
  }

  function getCampaignListComponent(
    searchList,
    status,
    title,
    totalCampaignCount,
  ) {
    if (searchList.length === 0) return null;
    return (
      <div
        className={classnames('widget-list-has-items', {
          'is-toggled': open,
          'will-toggle':
            status === 'TEST' && totalCampaignCount - searchList.length > 0,
        })}
      >
        <h3
          className={classnames('page-subtitle', {
            passive: status === 'TEST',
          })}
          onClick={() => status === 'TEST' && setOpen(!open)}
        >
          {t(title)} ({searchList.length})
        </h3>
        <ul className='widget-list'>
          {searchList.map(campaign => {
            return (
              <>
                <li
                  key={campaign.instanceId}
                  className='widget-list-item'
                  {...idPropFunc(id, 'SearchsList', campaign.instanceId)}
                >
                  <h2 className='widget-title'>
                    {scenarioName[campaign.instanceId]}
                  </h2>
                  <div className='widget-meta'>
                    {campaign.devices.map(device => {
                      if (device === 'PCTABLET') return;

                      return (
                        <span key={device} className='widget-meta-mobile'>
                        <Icon name={device.toLowerCase()} />
                          {t(devices[device])}
                      </span>
                      );
                    })}
                  </div>
                  <SearchActions campaign={campaign} />
                </li>
                <CampaignRuleSetItem
                  campaign={campaign}
                  isInstant
                  instantRefresh={getSearchs}
                />
              </>
            );
          })}
        </ul>
      </div>
    );
  }

  const activeCampaigns = getCampaigns(searchs, 'ACTIVE');
  const testCampaigns = getCampaigns(searchs, 'TEST');
  const totalCampaignCount = activeCampaigns.length + testCampaigns.length;
  return (
    <>
      {getCampaignListComponent(
        activeCampaigns,
        'ACTIVE',
        'Live Campaigns',
        totalCampaignCount,
      )}
      {getCampaignListComponent(
        testCampaigns,
        'TEST',
        'Test Campaigns',
        totalCampaignCount,
      )}
    </>
  );
}

SearchsList.defaultProps = {
  searchs: [],
};

SearchsList.propTypes = {
  searchs: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string.isRequired,
  getSearchs: PropTypes.func.isRequired,
};
