export const campaignWidgets = {
  name: 'Upload a New Contact List',
  type: 'UPLOAD_CONTACT_LIST',
  description: 'Upload a .csv document from your computer.',
  actionTitle: 'New List',
  actionIcon: 'plus',
};

export const actions = [
  {
    icon: 'gear',
    handler: 'importNewAction',
    name: 'Import New Users',
    iconSvg: true,
  },
  {
    icon: 'trashBin',
    handler: 'removeAction',
    name: 'Delete List',
    iconSvg: true,
  },
];
