import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const EmailEditIcon = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.40019 8.85001H0.200195V6.75L6.5002 0.450012C6.6002 0.350012 6.8002 0.25 7.0002 0.25C7.2002 0.25 7.4002 0.350012 7.5002 0.450012L8.60019 1.54999C8.70019 1.64999 8.8002 1.84999 8.8002 2.04999C8.8002 2.24999 8.70019 2.44999 8.60019 2.54999L2.40019 8.85001ZM0.300201 11.75H11.8002V10.25H0.300201V11.75Z'
        fill='#948CF3'
      />
    </svg>
  );
};

EmailEditIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

EmailEditIcon.defaultProps = {
  width: '12px',
  height: '12px',
};

export default EmailEditIcon;
