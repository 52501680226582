/**
 * Created by mehmetyurtar on 11/07/2017.
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { wizardActions } from "../../../actions";
import { formFieldButtons } from "../../../constants/datamaps/wizard";

import FormFieldsContainer from "./form-fields-container";
import Icons from "../../../components/icons";

import { t } from "../../../system/ui";

class FormFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      excludes: [],
      shuffle: true,
      hideExcludes: true,
      showSingleProductErr: false,
      hasError: false,
      showRequiredError: false,
      hideIncludes: true
    };

    this.button = this.button.bind(this);
    this.addDetailsRow = this.addDetailsRow.bind(this);
    this.addCriterion = this.addCriterion.bind(this);
  }

  componentDidMount() {}

  addDetailsRow(type) {
    this.addCriterion(type);
  }

  addCriterion(type) {
    let current = this.props.formFields.fields;
    current = current.concat({
      id: Math.random(),
      type: type,
      values: {},
      options: []
    });
    wizardActions.addFormField(Math.random(), current);
  }

  button(item, type, cb) {
    let currentIcon = <Icons name={item.icon} color="#fff" />;
    return (
      <a
        key={type}
        className={classNames("button", item.className)}
        onClick={cb.bind(null, type)}
      >
        {currentIcon}
        {t(item.title)}
      </a>
    );
  }

  render() {
    return (
      <li className="item-title-field">
        <h3 className="item-title-sub">{t("Form Fields")}</h3>
        <div className="wizard-input-types">
          {Object.keys(formFieldButtons).map(item => {
            return this.button(
              formFieldButtons[item],
              item,
              this.addDetailsRow
            );
          })}
        </div>
        <div
          className={classNames("wizard-input-type-wrapper", {
            "has-error": this.state.showRequiredError
          })}
        >
          <div className="wizard-form-fields">
            <FormFieldsContainer />
          </div>
        </div>
      </li>
    );
  }
}

const MapStatesToProps = store => ({
  validate: store.ui.validate,
  formFields: store.wizard.formFields
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  FormFields
);
