import React from 'react';
import PropTypes from 'prop-types';

export default function DownArrowNoFill(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${props.width}px`}
      height={`${props.height}px`}
      viewBox='0 0 16 9'
      fill='none'
    >
      <path
        d='M0.219668 0.469668C0.512558 0.176777 0.987438 0.176777 1.28033 0.469668L8 7.1893L14.7197
        0.469668C15.0126 0.176777 15.4874 0.176777 15.7803 0.469668C16.0732 0.762558 16.0732 1.23744
        15.7803 1.53033L8.5303 8.7803C8.2374 9.0732 7.7626 9.0732 7.4697 8.7803L0.219668 1.53033C-0.0732225
        1.23744 -0.0732225 0.762558 0.219668 0.469668Z'
        fill={props.color}
      />
    </svg>
  );
}

DownArrowNoFill.defaultProps = {
  color: '#AFAFB5',
  width: 20,
  height: 20,
};

DownArrowNoFill.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};
