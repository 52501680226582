import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { hasModule, hasModulePrivilege } from '../../modules/auth/user';

import CampaignStats from '../admin/campaign-stats';
import EmailCampaignStats from '../admin/email-campaign-stats';
import PushCampaignStats from '../admin/push-campaign-stats';
import SearchCampaignStats from '../admin/search-campaign-stats';

const defaultModulesStatus = {
  recommendation: false,
  email: false,
  push: false,
  search: false,
};

const CampaignDetailsModal = ({
  account,
  user,
  rangeAlias,
  isSwitchAccount,
}) => {
  const [modulesStatus, setModulesStatus] = useState(defaultModulesStatus);
  const [selectedModule, setSelectedModule] = useState('none');

  const getDefaultSelectedModule = tempModules => {
    const modules = tempModules || modulesStatus;
    if (modules.recommendation) {
      return 'Recommendation';
    }
    if (modules.email) {
      return 'Email';
    }
    if (modules.push) {
      return 'Push';
    }
    if (modules.search) {
      return 'Search';
    }
    return 'none';
  };

  useEffect(() => {
    const tempModulesStatus = {
      recommendation:
        hasModule('recommendation') && hasModulePrivilege(false, 'RECOUSER'),
      email: hasModule('email') && hasModulePrivilege(false, 'RECOUSER'),
      push: hasModule('push') && hasModulePrivilege(false, 'PUSH'),
      search: hasModule('search') && hasModulePrivilege(false, 'SEARCH'),
    };

    setModulesStatus(tempModulesStatus);

    setSelectedModule(getDefaultSelectedModule(tempModulesStatus));
  }, []);

  const getSelectedModuleContent = () => {
    let campaignStatsProps = {
      account,
      rangeAlias,
    };

    if (isSwitchAccount) {
      campaignStatsProps = {
        ...campaignStatsProps,
        userToSwitch: user,
      };
    }

    switch (selectedModule) {
      case 'Recommendation':
        return <CampaignStats {...campaignStatsProps} />;
      case 'Email':
        return <EmailCampaignStats {...campaignStatsProps} />;
      case 'Push':
        return <PushCampaignStats {...campaignStatsProps} />;
      case 'Search':
        return <SearchCampaignStats {...campaignStatsProps} />;
      default:
        return null;
    }
  };

  const moduleTab = moduleName => (
    <span
      onClick={() => setSelectedModule(moduleName)}
      className={`module-item ${
        selectedModule === moduleName ? 'selected' : ''
      }`}
    >
      {moduleName}
    </span>
  );

  return (
    <>
      <div className='campaign-details-module-selection'>
        {modulesStatus.recommendation && moduleTab('Recommendation')}
        {modulesStatus.email && moduleTab('Email')}
        {modulesStatus.push && moduleTab('Push')}
        {modulesStatus.search && moduleTab('Search')}
      </div>
      <div className='campaign-details-module-content'>
        {getSelectedModuleContent()}
      </div>
    </>
  );
};

CampaignDetailsModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  account: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
  rangeAlias: PropTypes.string.isRequired,
  isSwitchAccount: PropTypes.bool.isRequired,
};

export default CampaignDetailsModal;
