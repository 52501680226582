import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Tooltip from '../tooltip';
import Icons from '../icons';
import FREE_MAIL_PROVIDER_DOMAIN from '../../constants/free-mail-providers';
import * as classes from '../../constants/ui.classes';
import { idPropFunc } from '../../system/id';
import { t } from '../../system/ui';
import * as validations from '../../system/validations';

class TextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      showRequiredError: false,
      showEqualToError: false,
      showMinLengthError: false,
      showMinNumberError: false,
      showPasswordError: false,
      showEmailError: false,
      showBusinessEmailError: false,
      showSecureUrlError: false,
      showSecureUrlWithPathError: false,
      showURLError: false,
      showKeyboardPrefixError: false,
      businessEmailList: [],
    };

    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onPaste = this.onPaste.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.validate = this.validate.bind(this);
    this.clearableOnclick = this.clearableOnclick.bind(this);
  }

  componentDidMount() {
    this.validate();
    if (this.props.validation && this.props.validation.businessEmail) {
      this.setState({ businessEmailList: FREE_MAIL_PROVIDER_DOMAIN });
    }
  }

  componentWillReceiveProps(newProps) {
    setTimeout(() => {
      this.validate();
    }, 250);
  }

  onFocus(e) {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onBlur(e) {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    setTimeout(() => {
      this.validate();
    }, 100);
  }

  onChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }

    setTimeout(() => {
      this.validate();
    }, 100);
  }

  onPaste(e) {
    if (this.props.onPaste) {
      this.props.onPaste(e);
    }

    setTimeout(() => {
      this.validate();
    }, 100);
  }

  onMouseUp(e) {
    if (this.props.onMouseUp) {
      this.props.onMouseUp(e);
    }
  }

  onKeyUp(e) {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e);
    }
  }

  clearableOnclick(e) {
    this.refs.input.value = '';
    this.onChange(e);
  }

  validate(nextProps) {
    if (!this.refs.input) {
      return;
    }
    const { input } = this.refs;
    let hasAnyError = false;
    let urlError = false;

    if (this.props.required) {
      hasAnyError = validations.validateRequired(input, this);
    }

    if (this.props.validation && input.value !== '') {
      if (this.props.validation.minLength) {
        hasAnyError = validations.validateMinLength(input, this);
      }

      if (this.props.validation.minNumber) {
        hasAnyError = validations.validateMinNumber(input, this);
      }

      if (this.props.validation.email && input.value !== '') {
        hasAnyError = validations.validateEmail(input, this);
        if (!hasAnyError && this.props.validation.businessEmail) {
          hasAnyError = validations.validateBusinessEmail(input, this);
        }
      }
      if (this.props.validation.secureURL && input.value !== '') {
        hasAnyError = validations.validateSecureURL(input, this);
      }
      if (this.props.validation.secureURLWithPath && input.value !== '') {
        hasAnyError = validations.validateSecureURLWithPath(input, this);
      }
      if (this.props.validation.URL && input.value !== '') {
        urlError = validations.validateURL(input, this);
      }
      if (this.props.validation.mustacheOrUrl && input !== '') {
        hasAnyError = !validations.isValidMustacheOrUrl(input, this);
      }
      if (this.props.validation.klaviyoPrefix && input.value !== '') {
        hasAnyError = validations.validateKlaviyoPrefix(input, this);
      }

      if (this.props.validation.equalTo && this.props.validation.password) {
        const equalTo = validations.validateEqualTo(input, this);
        const password = validations.validatePassword(input, this);
        hasAnyError = equalTo || password;
      } else if (this.props.validation.equalTo) {
        hasAnyError = validations.validateEqualTo(input, this);
      } else if (this.props.validation.password) {
        hasAnyError = validations.validatePassword(input, this);
      } else {
        hasAnyError = false;
      }
    }

    if (
      nextProps &&
      nextProps.required &&
      (nextProps.value === '' || !!nextProps.value)
    ) {
      hasAnyError = validations.validateRequired(input, this);
    }

    if (this.props.customErrorMessage) {
      hasAnyError = true;
    }

    this.setState({
      hasError: hasAnyError || urlError,
      showURLError: urlError,
    });
  }

  static getFieldLabel(fieldName) {
    const field = document.querySelector(`[name=${fieldName}]`);
    return field.parentNode.querySelector('.item-label').innerText;
  }

  renderEmailError() {
    if (this.state.showEmailError) return <span>{t('EmailField')}</span>;
    if (this.state.showBusinessEmailError)
      return <span>{t('BusinessEmailCheck')}</span>;
    return null;
  }

  render() {
    const showError = !this.props.disabled;
    const fieldType = this.props.type || 'text';
    const requiredText = this.props.requiredText
      ? this.props.requiredText
      : t('This field is required');

    return (
      <label
        className={classNames(classes.fieldItem, this.props.className, {
          'has-error': this.props.disabled ? false : this.state.hasError,
          'disabled-field': this.props.disabled,
        })}
        {...idPropFunc('xF', this.props.name)}
      >
        {this.props.label && !this.props.labelPlace && (
          <span
            className={classes.fieldLabel}
            style={{
              marginTop: this.props.isSecond ? '20px' : 'unset',
              fontWeight: this.props.isSearch ? '600' : 100,
              color: this.props.isSearch ? 'black' : 'unset',
            }}
          >
            {t(this.props.label)}
            {this.props.tooltip && (
              <span style={{ position: 'absolute', top: '-3px' }}>
                {this.props.tooltipLink ? (
                  <Tooltip
                    content={this.props.tooltipText}
                    alignment='left'
                    placement={this.props.tooltipPlacement}
                  >
                    <a
                      href={this.props.tooltipLink}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='icon-info' role='presentation'>
                        <span className='for-screenreader-only' />
                      </i>
                    </a>
                  </Tooltip>
                ) : (
                  <span>
                    <Tooltip
                      content={this.props.tooltipText}
                      alignment={
                        this.props.tooltipAlignment
                          ? this.props.tooltipAlignment
                          : 'left'
                      }
                      placement={this.props.tooltipPlacement}
                    >
                      <i className='icon-info' role='presentation'>
                        <span className='for-screenreader-only' />
                      </i>
                    </Tooltip>
                    {this.props.isPush && (
                      <span
                        style={{
                          position: 'absolute',
                          top: '4px',
                          left: '20px',
                        }}
                      >
                        <Tooltip
                          content={
                            <span>
                              <strong>
                                WEB PUSH (WINDOWS): Chromium based browsers
                              </strong>
                              <br />
                              ~30 <br />
                              <br />
                              <strong>
                                WEB PUSH (Android Mobile): Chromium based
                                browsers
                              </strong>{' '}
                              <br />
                              ~40 (Chrome 67 and lower)
                              <br />
                              ~60 (Chrome 68+)
                              <br />
                              <br />
                              <strong>WEB PUSH (WINDOWS): FIREFOX</strong>{' '}
                              <br /> ~40
                              <br />
                              <strong>
                                WEB PUSH (MAC): Chromium based browsers,
                                FIREFOX, SAFARI
                              </strong>
                              <br />
                              ~20 - 40
                              <br />
                              <br />
                              <strong>
                                Recommended character length : ~20
                              </strong>
                            </span>
                          }
                          alignment='right'
                          placement='bottom'
                        >
                          <Icons
                            name='question'
                            width='16'
                            height='16'
                            color='#e7eef3'
                          />
                        </Tooltip>
                      </span>
                    )}
                  </span>
                )}
              </span>
            )}
          </span>
        )}
        <input
          type={fieldType}
          name={this.props.name}
          id={this.props.id}
          className={classNames(classes.textField, this.props.inputClassName)}
          defaultValue={this.props.defaultValue}
          value={this.props.value}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled}
          required={this.props.required}
          autoComplete={this.props.autocomplete}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.onChange}
          onPaste={this.onPaste}
          onMouseUp={this.onMouseUp}
          onKeyUp={this.onKeyUp}
          style={this.props.style}
          placeholder={t(this.props.placeholder)}
          maxLength={this.props.maxLength}
          ref='input'
        />
        {this.props.emailErrorMessage && (
          <span className='item-error'>{t(this.props.emailErrorMessage)}</span>
        )}
        {this.props.clearable && (
          <a
            onClick={this.clearableOnclick}
            className={classNames(this.props.clearableButtonClass)}
            title='Clear'
          >
            &times;
          </a>
        )}
        {this.props.icon && (
          <span className='field-icon' onClick={this.props.iconAction}>
            <Icons name={this.props.icon} />
          </span>
        )}

        {this.props.renderProp && this.props.renderProp()}
        {this.props.label && this.props.labelPlace === 'after' && (
          <span className={classes.fieldLabel}>{t(this.props.label)}</span>
        )}
        {this.state.hasError && (
          <span className='item-error'>
            {this.state.showRequiredError && showError && (
              <span>{requiredText}</span>
            )}
            {this.props.customErrorMessage && showError && (
              <span className='custom-error-message'>
                {this.props.customErrorMessage}
              </span>
            )}

            {this.renderEmailError()}
            {this.state.showEqualToError && (
              <span>
                {t(
                  'This field should equal to #fieldName#',
                  TextField.getFieldLabel(this.props.validation.equalTo),
                )}
              </span>
            )}
            {(this.state.showMinLengthError ||
              this.state.showPasswordError) && (
              <span>
                {t(
                  'This field should have at least #minLength# characters',
                  this.props.validation.minLength,
                )}
              </span>
            )}

            {this.state.showMinNumberError && (
              <span>
                This field should be minimum {this.props.validation.minNumber}
              </span>
            )}

            {(this.state.showMinLengthError ||
              this.state.showPasswordError) && (
              <span>{t('PasswordField')}</span>
            )}
            {this.state.showSecureUrlError && (
              <span className='item-error'>
                {t('URL must use HTTPS protocol.')}
              </span>
            )}

            {this.state.showSecureUrlWithPathError && (
              <span className='item-error'>
                {t(
                  'URL must use HTTPS protocol and URL must contain a service worker file path',
                )}
              </span>
            )}

            {this.state.showURLError && (
              <span className='item-error'>
                {t('Field must be a valid URL.')}
              </span>
            )}

            {this.state.showKlaviyoPrefixError && (
              <span className='item-error'>
                {t(
                  "Please enter a valid prefix. Example: 'best_products_ever'",
                )}
              </span>
            )}
          </span>
        )}
      </label>
    );
  }
}

TextField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onPaste: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  dontShow: PropTypes.bool,
  requiredText: PropTypes.string,
  validation: PropTypes.object,
  readOnly: PropTypes.bool,
  clearable: PropTypes.bool,
  clearableButtonClass: PropTypes.string,
  maxLength: PropTypes.number,
};

const mapStatesToProps = store => ({
  validate: store.ui.validate,
});

export default connect(mapStatesToProps)(TextField);
