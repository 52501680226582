import React, { useState } from 'react';
import './RectangleRadioButton.scss';

const RectangleRadioButtonGroup = ({ radioButtons, selected, onChange }) => {
  const [selectedRadioButton, setSelectedRadioButton] = useState(selected);

  return (
    <div className='bundle-type-modal--content--radio-buttons'>
      {radioButtons.map(({ label, description = '', value }) => (
        <div
          className='rectangle-radio-button'
          onClick={() => {
            setSelectedRadioButton(value);
            onChange(value);
          }}
        >
          <span className='rectangle-radio-button--label'>{label}</span>
          {description && (
            <span className='rectangle-radio-button--description'>
              {description}
            </span>
          )}
          <label className='item item-stacked is-checkbox rectangle-radio-button--input'>
            <input
              id=':r1:'
              type='checkbox'
              checked={selectedRadioButton === value}
            />
            <span className='item-label' />
          </label>
        </div>
      ))}
    </div>
  );
};

export default RectangleRadioButtonGroup;
