import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { filterActions } from '../../actions';
import Icon from '../icon';
import Icons from '../icons';
import { searchCategories } from '../../modules/category/ajax';
import { t } from '../../system/ui';
import DeviceSelect from './device-select';
import { getCategoryBrandLabel } from './utils';

class CategoryFilters extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.onSearchCategory = this.onSearchCategory.bind(this);
    this.onDeselectCategory = this.onDeselectCategory.bind(this);
    this.onSelectCategory = this.onSelectCategory.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      selectedCategories: [],
      categories: [],
      showCategories: false
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.reset === true) {
      this.setState({
        selectedCategories: [],
        categories: [],
        showCategories: false
      });
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  handleClick(event) {
    let newState = {};
    if (event.target !== this.refs.categoryClickHandler) {
      if (
        this.refs.categoryHolder &&
        this.refs.categoryHolder.contains(event.target)
      ) {
        newState.showCategories = true;
      }
      if (
        this.refs.categoryHolder &&
        !this.refs.categoryHolder.contains(event.target)
      ) {
        newState.showCategories = false;
      }
    }
    this.setState(newState);
  }

  resetFilters(e) {
    e.preventDefault();
    this.props.toggleAnalyticsFilters();
    filterActions.resetAllFilters();
  }

  onSelectCategory(cat) {
    this.setState({
      selectedCategories: this.state.selectedCategories.concat([cat]),
      searchedCategory: "",
      categories: []
    });
    filterActions.showButtons();
  }

  updateFilters(e) {
    e.preventDefault();
    this.props.toggleAnalyticsFilters();
    if (this.props.disableButtons) {
      return false;
    }
    filterActions.updateFilters();
  }

  onChange() {
    filterActions.showButtons();
  }

  toggleSelect(selectType) {
    if (selectType === "Categories") {
      this.setState({ showCategories: !this.state.showCategories });
    }
  }

  onSearchCategory(e) {
    let newCat = e.target.value;
    this.setState(
      {
        searchedCategory: newCat
      },
      () => {
        if (newCat.length > 2) {
          searchCategories(newCat, response => {
            this.setState({
              categories: response || []
            });
          });
        } else {
          this.setState({
            categories: []
          });
        }
      }
    );
  }

  onDeselectCategory(deselectedCat) {
    this.setState({
      selectedCategories: this.state.selectedCategories.filter(cat => {
        return cat !== deselectedCat;
      })
    });
  }

  render() {
    let categoryCSS = this.state.showCategories ? "block" : "none";
    let className = "analytics-filters group hide-filters";
    if (this.props.showTrendifyFilters) {
      className = "analytics-filters group show-filters";
    }
    return (
      <div className={className} ref="filters">
        <label
          className="item item-stacked is-select large analytics--multiselect category-label"
          ref="categoryHolder"
        >
          <span
            className="item-label"
            ref="categoryClickHandler"
            onClick={this.toggleSelect.bind(this, "Categories")}
          >
            {t("Product Category")}
          </span>
          <span className="multiselect--placeholder">
            {getCategoryBrandLabel(
              "category",
              this.state.selectedCategories.length
            )}
          </span>
          <div
            className="category-holder analytics--multiselect--holder"
            style={{ display: categoryCSS }}
          >
            <div className="multiselect--items__selected">
              <ul>
                {this.state.selectedCategories.map(cat => {
                  return (
                    <li
                      key={cat + "Selected"}
                      data-selectedval={cat}
                      className="multiselect--item__selected tfy-selected-category"
                      title={cat}
                    >
                      <i
                        className="icon-cross"
                        onClick={this.onDeselectCategory.bind(this, cat)}
                      />
                      <span>
                        {cat.length > 10 ? cat.substring(0, 10) + "..." : cat}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="multiselect--items--search">
              <input
                type="text"
                placeholder={t("Search")}
                onChange={this.onSearchCategory}
                value={this.state.searchedCategory}
              />
              <Icon name="magnify" />
            </div>
            <ul className="multiselect--items--list">
              {this.state.categories.map(cat => {
                return (
                  <li
                    key={cat}
                    className="multiselect--item"
                    onClick={this.onSelectCategory.bind(this, cat)}
                  >
                    {cat}
                  </li>
                );
              })}
            </ul>
          </div>
        </label>
        <DeviceSelect />
        <div
          className="analytics-filters-close"
          onClick={this.props.toggleAnalyticsFilters}
        >
          <Icons name="cross" color="#7b7e7e" />
        </div>
        <div className="analytics-filters-actions">
          {this.props.visibleReset ? (
            <a
              className="cancel-action analytics-filters--reset"
              onClick={this.resetFilters}
            >
              {t("Reset Filters")}
            </a>
          ) : (
            ""
          )}

          <a
            className={classNames("tertiary-action analytics-filters--update", {
              disabled: this.props.disableButtons
            })}
            onClick={this.updateFilters}
          >
            {t("Update")}
          </a>
        </div>
      </div>
    );
  }
}

const mapStatsToProps = store => ({
  reset: store.filters.reset,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  visibleReset: store.filters.visibleReset,
  disableButtons: store.filters.disableButtons
});

export default connect(mapStatsToProps)(CategoryFilters);
