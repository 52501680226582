/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Fields from '.';

class PopupOption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupOption: true,
    };
  }

  onFieldChange(e) {
    if (e.target.name === 'title') {
      const tempTitles = this.props.additionalTitles;
      tempTitles[this.props.selectedLanguage] = e.target.value;
      this.setState({
        additionalTitles: tempTitles,
      });
    }
  }

  render() {
    return (
      <div className='wizard-comp' style={{ marginTop: '-15px' }}>
        <p>
          Ask for your visitors’ permission to send them push notifications with
          a simple popup modal or opt-in box. Modal components are customizable
          to fit your business.
        </p>
        <p>
          This modal will be sent automatically just for the visitors who are
          using Chrome 80 version and above.
        </p>
        <div>
          <Fields
            comps={this.props.itemFields}
            campaign={this.props.campaign}
            ref='itemFields'
            forceOptional
            selectedLanguage={this.props.selectedLanguage}
            additionalTitles={this.props.additionalTitles}
            additionalDescriptions={this.props.additionalDescriptions}
            additionalButtonLaterText={this.props.additionalButtonLaterText}
            additionalButtonAllowText={this.props.additionalButtonAllowText}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  validate: store.ui.validate,
  editForm: store.wizard.editForm,
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  PopupOption,
);
