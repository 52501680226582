/**
 * Created by mehmetyurtar on 02/08/2017.
 */
"use strict";

import { createReducer } from "../system/store";
import * as reportActions from "../constants/actions/reports";

const initialState = {
  reports: {}
};

export default createReducer(initialState, {
  [reportActions.default.ADD_CAMPAIGN_REPORT]: (state, payload) => {
    return Object.assign({}, state, {
      reports: payload.reports
    });
  },
  [reportActions.default.EMPTY_CAMPAING_REPORTS]: state => {
    return Object.assign({}, state, {
      reports: {}
    });
  }
});
