import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const spPreviouslyAddedToBasket = ({ width, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 22 24'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      fill='none'
    >
      <path
        xmlns='http://www.w3.org/2000/svg'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 9.10713C0 4.0774 4.0774 0 9.10713 0C14.1369 0 18.2143 4.0774 18.2143 9.10713C18.2143 9.52626 17.8745 9.86606 17.4553 9.86606C17.0362 9.86606 16.6964 9.52626 16.6964 9.10713C16.6964 4.91569 13.2985 1.51786 9.10713 1.51786C4.91569 1.51786 1.51786 4.91569 1.51786 9.10713C1.51786 11.9655 3.11255 14.5266 5.56449 15.8211L5.56547 13.4077L5.5724 13.3047C5.62265 12.9344 5.94018 12.6488 6.3244 12.6488C6.74354 12.6488 7.08333 12.9886 7.08333 13.4077V17.4553L7.07639 17.5583C7.02614 17.9287 6.70862 18.2143 6.3244 18.2143H2.27678L2.1738 18.2074C1.80336 18.1571 1.51786 17.8396 1.51786 17.4553C1.51786 17.0362 1.85764 16.6964 2.27678 16.6964L4.07099 16.6973C1.57784 15.0419 0 12.2215 0 9.10713ZM20.1729 19.0676L21.8571 13.0526C21.8571 12.3308 21.6165 11.9699 21.1353 11.9699H14.2782V13.6541H20.0526L18.8496 17.9849H11.6316V11.1278C11.6316 10.8872 11.5113 10.6466 11.391 10.5263C11.2707 10.406 11.0301 10.2857 10.7895 10.2857H8.14285V11.9699H9.82706V18.8271C9.82706 19.0677 9.94736 19.3083 10.0677 19.4286C10.188 19.5489 10.4286 19.6691 10.6692 19.6691H19.3308H19.3309C19.4512 19.6691 19.6917 19.6691 19.812 19.5489C19.9323 19.4286 20.0526 19.3082 20.1729 19.0676ZM10.6692 20.6316C10.188 20.6316 9.82705 20.7519 9.46615 21.1128C9.10525 21.4737 8.98496 21.8346 8.98496 22.3158C8.98496 22.797 9.10525 23.1579 9.46615 23.5188C9.82705 23.8797 10.188 24 10.6692 24C11.1504 24 11.5113 23.8797 11.8722 23.5188C12.2331 23.1579 12.3534 22.797 12.3534 22.3158C12.3534 21.8346 12.2331 21.4737 11.8722 21.1128C11.5113 20.7519 11.1504 20.6316 10.6692 20.6316ZM19.2105 20.6316C18.7293 20.6316 18.3684 20.7519 18.0075 21.1128C17.7669 21.4737 17.5263 21.8346 17.5263 22.3158C17.5263 22.797 17.6466 23.1579 18.0075 23.5188C18.3684 23.8797 18.7293 24 19.2105 24C19.6917 24 20.0526 23.8797 20.4135 23.5188C20.7744 23.1579 20.8947 22.797 20.8947 22.3158C20.8947 21.8346 20.7744 21.4737 20.4135 21.1128C20.0526 20.7519 19.6917 20.6316 19.2105 20.6316Z'
        fill={color}
      />
    </svg>
  );
};

spPreviouslyAddedToBasket.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

spPreviouslyAddedToBasket.defaultProps = {
  width: '22px',
  height: '24px',
  color: '#5D5D61',
};

export default spPreviouslyAddedToBasket;
