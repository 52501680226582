import { queryDate, today, yesterday } from '../../system/date';
import { clone } from '../../system/object';
import { getDashboardUrl } from '../../constants/config';

const imageProxyURL = `${getDashboardUrl()}yUsFJ9slrLASPuLF5oGBu5ugAFlssSwR/`;

// TODO: rangeAlias must be a enum

export const getDatesSchema = (range, rangeAlias) => {
  let secondStartDate;
  let secondEndDate;
  let firstStartDate;
  let firstEndDate;
  if (rangeAlias === 'lastSevenDays') {
    // done
    secondStartDate = range[0]
      .clone()
      .subtract(7, 'd')
      .startOf('day');
    secondEndDate = range[1]
      .clone()
      .subtract(7, 'd')
      .startOf('day');
    [firstStartDate, firstEndDate] = range;
  } else if (rangeAlias === 'monthly') {
    // done
    secondStartDate = range[0]
      .clone()
      .add(-1, 'month')
      .startOf('month');
    secondEndDate = range[1]
      .clone()
      .add(-1, 'month')
      .endOf('day');
    [firstStartDate, firstEndDate] = range;
  } else if (rangeAlias === 'lastmonth') {
    // done
    secondStartDate = range[0]
      .clone()
      .add(-1, 'month')
      .startOf('month');
    secondEndDate = range[1]
      .clone()
      .add(-1, 'month')
      .endOf('month');
    [firstStartDate, firstEndDate] = range;
  } else if (rangeAlias === 'weekly') {
    // done
    secondStartDate = range[0]
      .clone()
      .subtract(7, 'd')
      .startOf('day');
    secondEndDate = range[1]
      .clone()
      .subtract(7, 'd')
      .startOf('day');
    [firstStartDate, firstEndDate] = range;
  } else if (rangeAlias === 'daily') {
    // done
    secondStartDate = yesterday();
    secondEndDate = yesterday();
    firstStartDate = today();
    firstEndDate = today();
  } else if (rangeAlias === 'custom') {
    [firstStartDate, firstEndDate, secondStartDate, secondEndDate] = range;
    const diff = firstEndDate.diff(firstStartDate, 'days');
    if (secondEndDate === undefined)
      secondEndDate = firstStartDate
        .clone()
        .subtract(1, 'd')
        .startOf('day');
    if (secondStartDate === undefined)
      secondStartDate = secondEndDate
        .clone()
        .subtract(diff, 'd')
        .startOf('day');
  }
  return {
    secondStartDate,
    secondEndDate,
    firstStartDate,
    firstEndDate,
  };
};

export const dateUpdatedQuery = ({ range, rangeAlias, analyticsQuery }) => {
  const calculatedDates = getDatesSchema(range, rangeAlias);

  return clone({
    ...analyticsQuery,
    startDate: queryDate(range[0]),
    endDate: queryDate(range[1]),
    secondStartDate: queryDate(calculatedDates.secondStartDate),
    secondEndDate: queryDate(calculatedDates.secondEndDate),
    firstStartDate: queryDate(calculatedDates.firstStartDate),
    firstEndDate: queryDate(calculatedDates.firstEndDate),
  });
};

export const parseTotalLineAPI = (response, deviceType) => {
  return [
    {
      totalViews: response[`${deviceType}SiteView`] ?? 0,
      totalBasketProducts: response[`${deviceType}SiteUBasket`] ?? 0,
      totalBaskets: response[`${deviceType}SiteBasket`] ?? 0,
      totalBasketsAmount: response[`${deviceType}SgmBamount`] ?? 0,
      // totalPurchasedProducts: response[`${deviceType}SiteUPurchase`] ?? 0,
      totalPurchases: response[`${deviceType}SitePurchase`] ?? 0,
      totalPurchasesAmount: response[`${deviceType}SiteAmount`] ?? 0,
    },
    {
      totalClick: response[`${deviceType}SgmClick`] ?? 0,
      totalBasketProducts: response[`${deviceType}SgmUBasket`] ?? 0,
      totalBaskets: response[`${deviceType}SgmBasket`] ?? 0,
      totalBasketsAmount: response[`${deviceType}SgmBamount`] ?? 0,
      totalPurchasedProducts: response[`${deviceType}SgmUPurchase`] ?? 0,
      totalPurchases: response[`${deviceType}SgmPurchase`] ?? 0,
      totalPurchasesAmount: response[`${deviceType}SgmAmount`] ?? 0,
    },
    {
      totalEmailClick: response[`${deviceType}EmailClick`] ?? 0,
      totalInternalClick: response[`${deviceType}InternalClick`] ?? 0,
      totalListingClick: response[`${deviceType}ListingClick`] ?? 0,
      totalOtherClick: response[`${deviceType}OtherClick`] ?? 0,
      totalPaidClick: response[`${deviceType}PaidClick`] ?? 0,
      totalSearchClick: response[`${deviceType}SearchClick`] ?? 0,
      totalSocialClick: response[`${deviceType}SocialClick`] ?? 0,
    },
  ];
};

function getBase64Image(img, type = 'image/png') {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, img.width, img.height);
  return canvas.toDataURL(type);
}

export function imgToBase64(src) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = `${imageProxyURL}?imgURL=${src.split('?')[0]}`;
    image.crossOrigin = '*';
    image.onload = () => {
      const base64 = getBase64Image(image);
      resolve(base64);
    };
    image.onerror = err => reject(err);
  });
}
