"use strict";

import React, { Fragment } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { t } from "../../system/ui";
import { idPropFunc } from "../../system/id";
import Icon from "../icon";

import Icons from "../icons";
import SwitchButton from "../../components/shared/switch-button";
import Tooltip from "../../components/tooltip";
import SearchLanguageBox from "./search-language-box";
import SearchInnerItems from "./inner-search/search-inner-item";
import LastSearches from './search.history';

class SearchRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.properties.active,
      clickable: false,
      treeView: false,
      type: "",
      assetItems: [],
      isMobile: false,
      exclusions: [],
      isDetailsHidden: true,
      additionalTitles: this.props.values.criterion.additionalTitles?this.props.values.criterion.additionalTitles:{},
    };
    this.handleClickable = this.handleClickable.bind(this);
    this.handleTreeView = this.handleTreeView.bind(this);
    this.handleChangeTitles = this.handleChangeTitles.bind(this);
    this.toggleButton = this.toggleButton.bind(this);
    this.handleAssetItems = this.handleAssetItems.bind(this);
    this.myRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.validateTriggered === true &&
      nextProps.validateTriggered !== this.props.validateTriggered
    ) {
      let errors = this.myRef.current.querySelectorAll(".has-error");
      if (errors.length > 0) {
        this.setState({
          isDetailsHidden: false,
        });
      }
    }
  }

  componentDidMount() {
    this.setValues();
  }

  setValues() {
    if (!this.props.properties.isData && this.state.active === true) {
      this.handleClickable();
      if (this.props.properties.type === "CATEGORY") this.handleTreeView();
    }
    if (this.props.properties.isData) {
      this.setState({
        active: this.props.values.criterion.status === "ACTIVE",
        exclusions : this.props.exclusions|| [],
        clickable:
          this.props.values.criterion && this.props.values.criterion.clickable
            ? this.props.values.criterion.clickable
            : false,
        treeView:this.props.values.criterion && this.props.values.criterion.treeView
        ? this.props.values.criterion.treeView
        : false,
        assetItems:this.props.values.criterion&&this.props.values.criterion.assetItems?
        this.props.values.criterion.assetItems:[],
        additionalTitles:this.props.values.criterion.additionalTitles?this.props.values.criterion.additionalTitles:{},
      });
    }

    this.setState({
      isMobile: this.props.properties.isMobile,
      type: this.props.properties.type,
    });
  }

  toggleButton() {
    this.setState(
      {
        active: !this.state.active,
      },
      () => {
        if (this.state.active === false)
          this.setState({ clickable: false, treeView: false });
        else {
          this.setState({ clickable: true });
          if (this.props.properties.type === "CATEGORY") this.handleTreeView();
        }
        this.sendRowChange();
        if (this.state.active) {
          this.handleAssetItems(this.state.assetItems);
        }
      }
    );
  }

  toggleMenu() {
    this.setState({
      isDetailsHidden: !this.state.isDetailsHidden,
    });
  }

  sendRowChange() {
    this.props.onSearchCriteriaChange(this.props.id, this.state);
  }

  handleClickable() {
    this.setState(
      {
        clickable: !this.state.clickable,
      },
      () => {
        this.sendRowChange();
      }
    );
  }

  handleTreeView() {
    this.setState(
      {
        treeView: !this.state.treeView,
      },
      () => {
        this.sendRowChange();
      }
    );
  }

  handleChangeTitles(additionalTitles) {
    this.setState(
      {
        additionalTitles,
      },
      () => {
        this.sendRowChange();
      }
    );
  }

  handleAssetItems(assetItems) {
    this.setState(
      {
        assetItems,
      },
      () => {
        this.sendRowChange();
      }
    );
  }

  getTooltip() {
    switch (this.props.properties.type) {
      case "KEYWORD":
        return "If you want to redirect your customers to the relevant url when they click the topic under Popular Keywords assets, please select clickable option.";
      case "BRAND":
        return "If you want to redirect your customers to the relevant url when they click the topic under Brands and Popular Brands assets, please select clickable option.";
      case "CATEGORY":
        return "If you want to redirect your customers to the relevant url when they click the topic under Categories and Popular Categories assets, please click this option.";
      case "LAST_SEARCHES":
        return "If you want to redirect your customers to the relevant url when they click the topic under Search History assets, please click this option.";
    }
  }

  render() {
    const isLastSearches = this.props.properties.type === 'LAST_SEARCHES';
    return (
      <li className="wizard-criterion" ref={this.myRef} {...idPropFunc(...this.props.idParams, "SearchRow")}>
        <div className="search__row">
          <div className="search__left">
            <div className="search__switch">
              <SwitchButton
                isSwitchedOn={this.state.active}
                onToggle={this.toggleButton}
              />
            </div>
            <div>
              <span className="criteria-type" style={{ marginTop: "5px" }}>
                <Icons name={this.props.properties.icons} color="#5a7390" />
              </span>
              <span className={"criteria-item-count"}>
                {this.props.properties.text}
              </span>

              <span
                className="item-label tip"
                style={{ top: "23px", left: "250px" }}
              >
                <Tooltip content={t(this.getTooltip())}>
                  <Icon name="info" />
                </Tooltip>
              </span>
              <span
                className="item-label clickable"
                style={{ top: "20px", left: "277px" }}
                onClick={this.handleClickable}
              >
                {t("Clickable")}{" "}
                <span style={{ top: "2px", left: "5px", position: "relative" }}>
                  <Icons
                    name={
                      this.state.active && this.state.clickable
                        ? "checkboxSelected"
                        : "checkboxEmpty"
                    }
                    width="12"
                    height="12"
                    style={{ top: "2px", left: "5px", position: "relative" }}
                  />
                </span>
              </span>

              {this.props.properties.isTreeView && (
                <Fragment>
                  <span
                    className="item-label tip"
                    style={{ left: "387px", top: "23px" }}
                  >
                    <Tooltip
                      content={t(
                        "If you want to show your categories as Category Tree in the searchbox, please click this option."
                      )}
                    >
                      <Icon name="info" />
                    </Tooltip>
                  </span>
                  <span
                    className="item-label clickable"
                    style={{ left: "415px", top: "20px" }}
                    onClick={this.handleTreeView}
                  >
                    {t("Category Tree View")}
                    {""}
                    <span
                      style={{ top: "2px", left: "5px", position: "relative" }}
                    >
                      <Icons
                        name={
                            this.state.active && this.state.treeView
                            ? "checkboxSelected"
                            : "checkboxEmpty"
                        }
                        width="12"
                        height="12"
                      />
                    </span>
                  </span>
                </Fragment>
              )}
            </div>
          </div>

          <div className="search__right">
            <div className="search__col">
              {this.state.active && (
                <div
                  className="search__expand"
                  onClick={this.toggleMenu.bind(this)}
                >
                  <div
                    className={classNames({
                      "sg-hidden": !this.state.isDetailsHidden,
                    })}
                  >
                    <Icons name="edit" color="#547496" />
                  </div>
                  <div
                    className={classNames({
                      "sg-hidden": this.state.isDetailsHidden,
                    })}
                  >
                    <Icons name="collapse" color="#000" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.active && (
          <div className={classNames({ "sg-hidden": this.state.isDetailsHidden }, {"search__row--container": !this.state.isDetailsHidden && isLastSearches})}>
            <div className="search__row search__row--margin">
              <SearchLanguageBox
                account={this.props.account}
                wizardLanguage={this.props.wizardLanguage}
                additionalTitles={this.state.additionalTitles}
                assetItems={this.state.assetItems}
                onLanguageChange={this.handleChangeTitles}
                handleAsset={this.handleAssetItems}
                type={this.props.properties.type}
              />
            </div>
            <div className={classNames("search__row search__row--margin", {"search__row--last_searches": isLastSearches})}>
              {isLastSearches
                ? <LastSearches
                  assetItems={this.state.assetItems}
                  handleAsset={this.handleAssetItems}
                />
                : <SearchInnerItems
                  isMobile={this.state.isMobile}
                  assetItems={this.state.assetItems}
                  exclusions={this.state.exclusions}
                  type={this.props.properties.type}
                  text={this.props.properties.text}
                  isAssetActive={this.state.active}
                  handleAsset={this.handleAssetItems}
                />
              }
            </div>
          </div>
        )}
      </li>
    );
  }
}

const mapStatesToProps = (store) => ({
  validateTriggered: store.ui.validateTriggered,
});
export default connect(mapStatesToProps, null)(SearchRow);
