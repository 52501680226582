import React from 'react';
import PropTypes from 'prop-types';
import { ColorPicker, Select } from '../../../../components/fields';
import Icons from '../../../../components/icons';
import { convertToSelectOptions } from '../utils';
import { desktopPosOptions, mobilePosOptions } from '../constants';
import { SFYImageInput } from '../../../../components/sfy-components/module-exports';

const PopupStyle = ({
  activeStyles = ['position', 'color', 'image'],
  desktopPosition,
  mobilePosition,
  colorBackground,
  colorText,
  onChangeDesktopPosition,
  onChangeMobilePosition,
  onChangeColorBg,
  onChangeColorText,
  icon,
  onChangeIcon,
  title,
}) => {
  const optionsHandlerForDesktop = () => {
    let options = [];
    if (window.location.pathname.includes('countdown/notification/')) {
      options = mobilePosOptions.filter(option => option !== 'Middle');
    } else {
      options = desktopPosOptions;
    }
    return convertToSelectOptions(options);
  };

  return (
    <div className='form-element'>
      {title ? (
        <span className='element-title group-title'>{title}</span>
      ) : (
        <span className='element-title group-title'>Pop-up Style</span>
      )}
      {activeStyles.includes('position') && (
        <div className='element-column-field'>
          {!title && <span className='element-column-label'>Position</span>}
          <div className='element-row-field'>
            {!title && (
              <span className='element-row-label popup-style-equal-width'>
                <Icons name='pc' color='#7A7A7C' />
                Desktop
              </span>
            )}
            <Select
              name='desktopPosition'
              className='element-select'
              label=''
              value={desktopPosition}
              options={optionsHandlerForDesktop()}
              onChange={e => onChangeDesktopPosition(e.value)}
              clearable={false}
            />
          </div>
          {mobilePosition ? (
            <div className='element-row-field'>
              <span className='element-row-label popup-style-equal-width'>
                <Icons name='mobile' color='#7A7A7C' />
                Mobile
              </span>
              <Select
                name='desktopPosition'
                className='element-select'
                label=''
                value={mobilePosition}
                options={convertToSelectOptions(mobilePosOptions)}
                onChange={e => onChangeMobilePosition(e.value)}
                clearable={false}
              />
            </div>
          ) : null}
        </div>
      )}

      {activeStyles.includes('color') && (
        <div className='element-row-field'>
          <div className='element-column-field popup-style-color-picker'>
            <span className='element-column-label'>Background Color</span>
            <ColorPicker
              name='backgroundColor'
              className='element-color-picker'
              label=''
              labelDefault=''
              value={colorBackground}
              type='withAlpha'
              onChange={color => onChangeColorBg(color)}
              disableTooltip
            />
          </div>
          <div className='element-column-field popup-style-color-picker'>
            <span className='element-column-label'>Text Color</span>
            <ColorPicker
              name='textColor'
              className='element-color-picker'
              label=''
              labelDefault=''
              value={colorText}
              type='withAlpha'
              onChange={color => onChangeColorText(color)}
              disableTooltip
            />
          </div>
        </div>
      )}

      {activeStyles.includes('image') && (
        <div className='element-column-field'>
          <span className='element-column-label popup-style-icon'>
            Upload Image
          </span>
          <SFYImageInput
            key='promotion-web-image'
            fetchedImage={icon}
            stateSetter={onChangeIcon}
            type='web'
            withoutCardSpace
            color='#E0D4FF'
            endpointType='engagement'
          />
        </div>
      )}
    </div>
  );
};

PopupStyle.propTypes = {
  desktopPosition: PropTypes.string.isRequired,
  mobilePosition: PropTypes.string.isRequired,
  colorBackground: PropTypes.string.isRequired,
  colorText: PropTypes.string.isRequired,
  onChangeDesktopPosition: PropTypes.func.isRequired,
  onChangeMobilePosition: PropTypes.func.isRequired,
  onChangeColorBg: PropTypes.func.isRequired,
  onChangeColorText: PropTypes.func.isRequired,
  onChangeIcon: PropTypes.func.isRequired,
};

export default PopupStyle;
