import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { browserHistory } from 'react-router';
import {
  isSwitchAccount,
  hasDotDigitalConfiguration,
  hasKlaviyoConfiguration,
  mailProviderIsSegmentify,
} from '../modules/auth/user';
import Tooltip from '../components/tooltip';

import { t, confirm } from '../system/ui';
import { setTitle } from '../system/document';

import {
  modalActions,
  uiActions,
  wizardActions,
  mailWizardActions,
} from '../actions';

import {
  getEmailCampaign,
  getSampleContent,
  getEmailTemplates,
  getActiveEmails,
} from '../modules/emails/ajax';
import {
  emailGoTestOrLive,
  singleEmailCampaign,
} from '../modules/campaigns/wizard';

import { TextField, TextArea, Calendar } from '../components/fields';
import Fields from '../modules/campaign/fields';
import SampleContentModal from '../modules/emails/sample-content-modal';
import SendTestModal from '../modules/emails/send-test-modal';
import {
  paramsTypes,
  days,
  localizationParams,
  paramsInWizard,
  campaignInitialData,
} from '../constants/datamaps/email';
import MailMapping from '../components/email/mapping';
import MailCapping from '../components/email/capping';
import WizardDateSelection from '../components/wizard/date-selection';
import { clone } from '../system/object';
import {
  MPExtraContainer,
  MPScheduling,
  MPAudienceEmail,
} from '../components/multipleModule';

import DotDigitalModal from '../components/email/dotdigital-modal';
import Icons from '../components/icons';

import TimeAlert from '../components/time-alert';
import SendTestModalKlaviyo from '../modules/emails/send-test-modal-klaviyo';

let lastType = '';

const getCampaignNames = type => {
  let result;
  const names = {
    Retention: [
      'ABANDONED_CART',
      'LAST_VISIT_ALTERNATIVES',
      'NICE_TO_MEET_YOU',
      'LAST_VISIT_REMINDER',
    ],
    Growth: [
      'NEW_COMERS',
      'TOP_SELLERS',
      'ORDER_FOLLOW_UP',
      'BIRTHDAY',
      'ANNIVERSARY',
    ],
    Winback: ['WE_MISSED_YOU', 'CHURN', 'PRICE_DROP', 'BACK_IN_STOCK'],
    Recommendation: ['RECOMMENDATION'],
    Fly: ['FLY'],
  };

  Object.keys(names).forEach(name => {
    if (names[name].indexOf(type) > -1) {
      result = name;
    }
  });

  return result;
};

class EditEmailCampaign extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: {
        schedule: {},
      },
      scenarioName: '',
      campaignStatus: '',
      subject: '',
      _subject: '',
      mailContent: '',
      _mailContent: '',
      campaignCustoms: {},
      contentSamples: {},
      mailTemplates: [],
      template: '',
      currency: '',
      language: '',
      capping: {
        capCount: '',
        frequency: '',
        types: [],
      },
      cappignError: false,
      disableLiveButton: false,
      showDiscount: false,
      selectedLanguage: '',
      additionalSubjects: {},
      additionalContents: {},
      isMultiLang: false,
      sendToIdentifyApi: false,
      klaviyoEventName: '',
      customTemplateList: [],
      isSegmentifyProvider: mailProviderIsSegmentify('SEGMENTIFY'),
      userRole: '',
    };

    this.campaignDetails = this.campaignDetails.bind(this);
    this.onCampaignDataChange = this.onCampaignDataChange.bind(this);
    this.validate = this.validate.bind(this);
    this.goTest = this.goTest.bind(this);
    this.goLive = this.goLive.bind(this);
    this.onCustomParamChange = this.onCustomParamChange.bind(this);
    this.onSampleTextSelect = this.onSampleTextSelect.bind(this);
    this.onSelectSampleContent = this.onSelectSampleContent.bind(this);
    this.openTestModal = this.openTestModal.bind(this);
    this.openTestModalKlaviyo = this.openTestModalKlaviyo.bind(this);
    this.onWorkingHourChange = this.onWorkingHourChange.bind(this);
    this.onMailToChange = this.onMailToChange.bind(this);
    this.onScenarioNameChange = this.onScenarioNameChange.bind(this);
    this.onCampaignCappingParamChange = this.onCampaignCappingParamChange.bind(
      this,
    );
    this.toggleDiscountInput = this.toggleDiscountInput.bind(this);
    this.getSelectedLanguage = this.getSelectedLanguage.bind(this);
    this.toggleIdentifyInput = this.toggleIdentifyInput.bind(this);
    this.onKlaviyoEventNameChange = this.onKlaviyoEventNameChange.bind(this);
    this.customTemplateAction = this.customTemplateAction.bind(this);
    this.workingHourRef = React.createRef();
  }

  openDotModal() {
    modalActions.openModal({
      title: 'Dotdigital Campaign Template Variables',
      className: 'tfy-filter-modal',
      content: () => <DotDigitalModal />,
    });
  }

  componentDidMount() {
    setTitle(t('Edit Email Marketing Campaign'));
    uiActions.confirmOnLeave(this);

    this.campaignDetails(
      isSwitchAccount() ? this.props.switchedUser : this.props.user,
    );
    uiActions.isValidated();
    uiActions.removePageClass();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      this.campaignDetails(
        isSwitchAccount() ? newProps.switchedUser : newProps.user,
      );
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.validate === true && oldProps.validate === false) {
      this.validate(lastType);
    }
  }

  componentWillUnmount() {
    wizardActions.saveReset();
    wizardActions.wizardEdit();
    wizardActions.wizardReset();
    uiActions.hideBreadcrumb();
    uiActions.confirmOnLeave(this);
  }

  updateAdditionalLanguage() {
    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;

    const { mainLanguage } = account;
    const selectedLanguage = this.getSelectedLanguage(
      account,
      this.state.campaign,
    );

    let langList =
      account.additionalLanguages &&
      account.additionalLanguages.indexOf(account.mainLanguage) > -1
        ? account.additionalLanguages
        : [account.mainLanguage].concat(account.additionalLanguages);

    langList =
      langList.indexOf(selectedLanguage) === -1
        ? [...langList, selectedLanguage]
        : langList;

    if (
      this.state.campaign.type === 'FLY' ||
      this.state.campaign.type === 'RECOMMENDATION'
    ) {
      return;
    }

    uiActions.isLoading();
    getSampleContent(this.state.campaign.instanceId, samples => {
      uiActions.isLoaded();

      const additionalContents = {};
      const additionalSubjects = {};

      langList.map(item => {
        const stateValue = this.state.additionalContents[item];
        const contentList = samples[item] && samples[item].contentList[0];
        const mainContentList =
          samples[mainLanguage] && samples[mainLanguage].contentList[0];

        if (stateValue) {
          additionalContents[item] = stateValue;
          return;
        }

        if (contentList) {
          additionalContents[item] = contentList;
          return;
        }

        if (mainContentList) {
          additionalContents[item] = mainContentList;
          return;
        }

        additionalContents[item] = '';
      });

      langList.map(item => {
        const stateValue = this.state.additionalSubjects[item];
        const contentList = samples[item] && samples[item].subjectList[0];
        const mainContentList =
          samples[mainLanguage] && samples[mainLanguage].subjectList[0];

        if (stateValue) {
          additionalSubjects[item] = stateValue;
          return;
        }

        if (contentList) {
          additionalSubjects[item] = contentList;
          return;
        }

        if (mainContentList) {
          additionalSubjects[item] = mainContentList;
          return;
        }

        additionalSubjects[item] = '';
      });

      this.setState({
        additionalContents,
        additionalSubjects,
      });
    });
  }

  getSelectedLanguage(account, campaign) {
    if (campaign.params.language === 'SESSION_BASED') {
      return account.mainLanguage;
    }

    if (campaign.params.language) {
      return campaign.params.language;
    }

    return account.mainLanguage;
  }

  campaignDetails(user) {
    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
    const langs = [];
    const emptyAddObject = {};
    this.setState({
      userRole: user.userRole,
    });
    langs.push(account.mainLanguage);
    if (account.additionalLanguages) {
      langs.push(...account.additionalLanguages);
    }
    for (let i = 0; i < langs.length; i++) {
      emptyAddObject[langs[i]] = '';
    }
    if (account.additionalLanguages && account.additionalLanguages.length > 0) {
      this.setState({
        isMultiLang: true,
      });
    }
    if (user.account) {
      if (
        this.props.location.state &&
        Object.keys(this.props.location.state).length &&
        this.props.location.state.campaign &&
        !this.props.location.state.setInitialData
      ) {
        const { campaign } = this.props.location.state;
        const { templates } = this.props.location.state;
        const additionalSubjects = { ...emptyAddObject };
        const additionalContents = { ...emptyAddObject };
        additionalSubjects[account.mainLanguage] = campaign.params.subject;
        additionalContents[account.mainLanguage] = campaign.params.mailContent;
        this.setState(
          {
            campaign,
            scenarioName: campaign.scenarioName,
            campaignStatus: campaign.status,
            subject: campaign.params.subject,
            _subject: campaign.params.subject,
            mailContent: campaign.params.mailContent,
            _mailContent: campaign.params.mailContent,
            selectedLanguage: this.getSelectedLanguage(account, campaign),
            additionalSubjects:
              campaign.hasOwnProperty('additionalSubjects') &&
              Object.keys(campaign.additionalSubjects).length
                ? campaign.additionalSubjects
                : { ...additionalSubjects },
            additionalContents:
              campaign.hasOwnProperty('additionalContents') &&
              Object.keys(campaign.additionalContents).length
                ? campaign.additionalContents
                : { ...additionalContents },
            campaignCustoms: campaign.params,
            contentSamples: {},
            mailTemplates: templates,
            template: campaign.template || templates[0],
            disableLiveButton: false,
            showDiscount:
              campaign.params.discountRatio !== '' &&
              campaign.params.discountRatio !== undefined,
            sendToIdentifyApi: campaign.sendToIdentifyApi,
            klaviyoEventName: campaign.params.klaviyoEventName || '',
          },
          () => {
            this.onCustomParamChange();
            this.updateAdditionalLanguage();
            uiActions.showBreadcrumb({
              home: {
                name: t('Email Marketing'),
                icon: 'emailModule',
                slug: '/email/view-all',
                iconSvg: true,
              },
              top: {
                name: getCampaignNames(this.state.campaign.type),
                slug: `/email/${getCampaignNames(
                  this.state.campaign.type,
                ).toLowerCase()}`,
              },
              current: {
                name: this.state.scenarioName,
              },
            });
            if (
              account.additionalLanguages.length > 0 &&
              Object.keys(this.state.additionalContents).length === 0 &&
              Object.keys(this.state.additionalSubjects).length === 0
            ) {
              const additionalContents = {};
              const additionalSubjects = {};
              account.additionalLanguages.map(lang => {
                additionalContents[lang] = '';
                additionalSubjects[lang] = '';
              });
              additionalContents[account.mainLanguage] = this.state.mailContent;
              additionalSubjects[account.mainLanguage] = this.state.subject;
              this.setState({
                additionalContents: { ...additionalContents },
                additionalSubjects: { ...additionalSubjects },
              });
            }
          },
        );
      } else if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.setInitialData
      ) {
        let response;
        campaignInitialData.forEach(campaign => {
          if (campaign.type === this.props.params.emailType) {
            response = clone(campaign);
          }
        });
        if (response.params) {
          this.setState({
            campaignCustoms: response.params,
          });
        }
        uiActions.isLoading();
        getSampleContent(this.props.params.emailType, samples => {
          getEmailTemplates(templates => {
            uiActions.isLoaded();
            if (templates) {
              let fixedTemplates = templates;
              let customTemplates = [];
              if (this.state.isSegmentifyProvider) {
                fixedTemplates = templates.map(
                  template => template.templateName,
                );
                customTemplates = templates;
              }

              singleEmailCampaign(response);
              const additionalSubjects = { ...emptyAddObject };
              const additionalContents = { ...emptyAddObject };
              additionalSubjects[account.mainLanguage] =
                response.params.subject;
              additionalContents[account.mainLanguage] =
                response.params.mailContent;
              this.setState(
                {
                  campaign: response,
                  scenarioName: response.scenarioName,
                  campaignStatus: response.status,
                  subject: response.params.subject,
                  _subject: response.params.subject,
                  mailContent: response.params.mailContent,
                  _mailContent: response.params.mailContent,
                  campaignCustoms: response.params,
                  contentSamples: samples
                    ? samples[
                        response.params.language === 'SESSION_BASED'
                          ? account.mainLanguage
                          : response.params.language
                      ]
                    : {},
                  mailTemplates: fixedTemplates,
                  template: response.template || fixedTemplates[0],
                  currency: response.params.currency || false,
                  language: response.params.language || false,
                  additionalSubjects:
                    response.hasOwnProperty('additionalSubjects') &&
                    Object.keys(response.additionalSubjects).length
                      ? response.additionalSubjects
                      : { ...additionalSubjects },
                  additionalContents:
                    response.hasOwnProperty('additionalContents') &&
                    Object.keys(response.additionalContents).length
                      ? response.additionalContents
                      : { ...additionalContents },
                  selectedLanguage:
                    response.params.language === 'SESSION_BASED'
                      ? account.mainLanguage
                      : response.params.language,
                  capping: response.capping || this.state.capping,
                  disableLiveButton: true,
                  showDiscount:
                    response.params.discountRatio !== '' &&
                    response.params.discountRatio !== undefined,
                  sendToIdentifyApi: response.sendToIdentifyApi || false,
                  klaviyoEventName: response.params.klaviyoEventName || '',
                  customTemplateList: customTemplates,
                },
                () => {
                  this.onCustomParamChange();
                  this.updateAdditionalLanguage();

                  uiActions.showBreadcrumb({
                    home: {
                      name: t('Email Marketing'),
                      icon: 'emailModule',
                      slug: '/email/view-all',
                      iconSvg: true,
                    },
                    top: {
                      name: getCampaignNames(this.state.campaign.type),
                      slug: `/email/${getCampaignNames(
                        this.state.campaign.type,
                      ).toLowerCase()}`,
                    },
                    current: {
                      name: this.state.scenarioName,
                    },
                  });
                  if (
                    account.additionalLanguages.length > 0 &&
                    Object.keys(this.state.additionalContents).length === 0 &&
                    Object.keys(this.state.additionalSubjects).length === 0
                  ) {
                    const additionalContents = {};
                    const additionalSubjects = {};
                    account.additionalLanguages.map(lang => {
                      additionalContents[lang] = '';
                      additionalSubjects[lang] = '';
                    });
                    additionalContents[
                      account.mainLanguage
                    ] = this.state.mailContent;
                    additionalSubjects[
                      account.mainLanguage
                    ] = this.state.subject;
                    this.setState({
                      additionalContents: { ...additionalContents },
                      additionalSubjects: { ...additionalSubjects },
                    });
                  }
                },
              );
            }
          }, this.state.isSegmentifyProvider);
        });
      } else {
        const initialCampaign = campaignInitialData.filter(campaign => {
          return campaign.type === this.props.params.emailType;
        });
        if (initialCampaign[0] && initialCampaign[0].params) {
          this.setState({
            campaignCustoms: initialCampaign[0].params,
          });
        }
        uiActions.isLoading();
        getEmailCampaign(this.props.params.emailType, response => {
          getSampleContent(this.props.params.emailType, samples => {
            getEmailTemplates(templates => {
              uiActions.isLoaded();
              if (templates) {
                let fixedTemplates = templates;
                let customTemplates = [];
                if (this.state.isSegmentifyProvider) {
                  fixedTemplates = templates.map(
                    template => template.templateName,
                  );
                  customTemplates = templates;
                }

                singleEmailCampaign(response);
                const additionalSubjects = { ...emptyAddObject };
                const additionalContents = { ...emptyAddObject };
                additionalSubjects[
                  this.getSelectedLanguage(account, response)
                ] = response.params.subject;
                additionalContents[
                  this.getSelectedLanguage(account, response)
                ] = response.params.mailContent;
                this.setState(
                  {
                    campaign: response,
                    scenarioName: response.scenarioName,
                    campaignStatus: response.status,
                    subject: response.params.subject,
                    _subject: response.params.subject,
                    mailContent: response.params.mailContent,
                    _mailContent: response.params.mailContent,
                    campaignCustoms: response.params,
                    contentSamples: samples
                      ? samples[
                          response.params.language === 'SESSION_BASED'
                            ? account.mainLanguage
                            : response.params.language
                        ]
                      : {},
                    mailTemplates: fixedTemplates,
                    template: response.template || fixedTemplates[0],
                    currency: response.params.currency || false,
                    language: response.params.language || false,
                    selectedLanguage: this.getSelectedLanguage(
                      account,
                      response,
                    ),
                    additionalSubjects:
                      response.hasOwnProperty('additionalSubjects') &&
                      Object.keys(response.additionalSubjects).length
                        ? response.additionalSubjects
                        : { ...additionalSubjects },
                    additionalContents:
                      response.hasOwnProperty('additionalContents') &&
                      Object.keys(response.additionalContents).length
                        ? response.additionalContents
                        : { ...additionalContents },
                    capping: response.capping || this.state.capping,
                    disableLiveButton: false,
                    showDiscount:
                      response.params.discountRatio !== '' &&
                      response.params.discountRatio !== undefined,
                    sendToIdentifyApi: response.sendToIdentifyApi || false,
                    klaviyoEventName: response.params.klaviyoEventName || '',
                    customTemplateList: customTemplates,
                  },
                  () => {
                    this.onCustomParamChange();
                    this.updateAdditionalLanguage();

                    uiActions.showBreadcrumb({
                      home: {
                        name: t('Email Marketing'),
                        icon: 'emailModule',
                        slug: '/email/view-all',
                        iconSvg: true,
                      },
                      top: {
                        name: getCampaignNames(this.state.campaign.type),
                        slug: `/email/${getCampaignNames(
                          this.state.campaign.type,
                        ).toLowerCase()}`,
                      },
                      current: {
                        name: this.state.scenarioName,
                      },
                    });
                    if (
                      account.additionalLanguages.length > 0 &&
                      Object.keys(this.state.additionalContents).length === 0 &&
                      Object.keys(this.state.additionalSubjects).length === 0
                    ) {
                      const additionalContents = {};
                      const additionalSubjects = {};
                      account.additionalLanguages.map(lang => {
                        additionalContents[lang] = '';
                        additionalSubjects[lang] = '';
                      });
                      additionalContents[
                        account.mainLanguage
                      ] = this.state.mailContent;
                      additionalSubjects[
                        account.mainLanguage
                      ] = this.state.subject;
                      this.setState({
                        additionalContents: {
                          ...additionalContents,
                        },
                        additionalSubjects: {
                          ...additionalSubjects,
                        },
                      });
                    }
                  },
                );
              }
              mailWizardActions.addMapping(
                response.customParams.map(param => {
                  return {
                    id: Math.random(),
                    key: param.key,
                    value: param.value,
                  };
                }),
              );
            }, this.state.isSegmentifyProvider);
          });
        });
      }
    }
  }

  selectLanguage(selectedLanguage, e) {
    e.preventDefault();
    this.setState(
      {
        selectedLanguage,
      },
      () => {
        getSampleContent(this.state.campaign.instanceId, samples => {
          this.setState({
            contentSamples: samples ? samples[this.state.selectedLanguage] : {},
          });
        });
      },
    );
  }

  onCampaignDataChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      this.onCustomParamChange,
    );
    if (e.target.name === 'language' && e.target.value !== 'SESSION_BASED') {
      this.setState(
        {
          selectedLanguage: e.target.value,
        },
        () => {
          getSampleContent(this.state.campaign.instanceId, samples => {
            this.setState({
              contentSamples: samples
                ? samples[this.state.selectedLanguage]
                : {},
            });
          });
        },
      );
    }
    if (e.target.name === 'subject') {
      const tempSubjects = this.state.additionalSubjects;
      tempSubjects[this.state.selectedLanguage] = e.target.value;
      this.setState({
        additionalSubjects: tempSubjects,
      });
      if (this.state.campaign.type === 'FLY') {
        this.state._subject = e.target.value;
      }
    }
    if (e.target.name === 'mailContent') {
      const tempContents = this.state.additionalContents;
      tempContents[this.state.selectedLanguage] = e.target.value;
      this.setState({
        additionalContents: tempContents,
      });
      if (this.state.campaign.type === 'FLY') {
        this.state._mailContent = e.target.value;
      }
    }
  }

  onKlaviyoEventNameChange(e) {
    this.setState({
      klaviyoEventName: e.target.value,
    });
  }

  validate(type) {
    const errors = document.querySelectorAll('.has-error');

    lastType = type;

    if (this.props.validate === true && errors.length === 0) {
      if (type === 'test') {
        confirm({
          title: 'Update Campaign on Draft Mode?',
          content: 'This action will immediately effect campaign status',
          onConfirm: this.goTest,
          onCancel: () => {
            this.setState({
              isSubmitting: false,
            });
          },
        });
      }

      if (type === 'live') {
        confirm({
          title: 'Update Campaign on Live?',
          content: 'This action will immediately effect campaign status',
          onConfirm: this.goLive,
          onCancel: () => {
            this.setState({
              isSubmitting: false,
            });
          },
        });
      }
    } else {
      uiActions.isValidating();
    }
  }

  goTest() {
    const status = this.state.campaign.type === 'RECOMMENDATION' ? '' : 'test';
    this.setState({ isSubmitting: true, submittingButton: 'live' }, () => {
      emailGoTestOrLive(
        status,
        this,
        this.props.criteria,
        this.props.includes,
        this.props.excludes,
        this.props.mappings,
        campaign => {
          this.setState({
            saved: true,
            campaignStatus: 'TEST',
            disableLiveButton: false,
            campaign,
          });
        },
      );
      uiActions.formSaved();
    });
  }

  goLive() {
    this.setState({ isSubmitting: true, submittingButton: 'live' }, () => {
      emailGoTestOrLive(
        'live',
        this,
        this.props.criteria,
        this.props.includes,
        this.props.excludes,
        this.props.mappings,
        campaign => {
          this.setState({
            saved: true,
            campaignStatus: 'ACTIVE',
            disableLiveButton: false,
            campaign,
          });
        },
      );
      uiActions.formSaved();
    });
  }

  onCustomParamChange() {
    const newCustoms = {
      subject: this.state.subject,
      _subject: this.state.subject,
      mailContent: this.state.mailContent,
      _mailContent: this.state.mailContent,
      mailTo: this.state.campaignCustoms.mailTo,
    };
    Object.keys(this.state.campaignCustoms).forEach(custom => {
      if (
        paramsInWizard.indexOf(custom) < 0 &&
        Object.keys(localizationParams).indexOf(custom) < 0
      ) {
        if (custom === 'workingHour') {
          newCustoms.workingHour = this.state.campaignCustoms.workingHour;
        } else if (this.refs.wizard.querySelector(`[name='${custom}Start']`)) {
          const startValue = this.refs.wizard.querySelector(
            `[name='${custom}Start']`,
          ).value;
          const endValue = this.refs.wizard.querySelector(
            `[name='${custom}End']`,
          ).value;
          newCustoms[custom] = `${startValue}-${endValue}`;
        } else if (this.refs.wizard.querySelector(`[name='${custom}']`)) {
          if (
            paramsTypes[custom]?.type === 'integer' ||
            paramsTypes[custom]?.type === 'text'
          ) {
            const val = this.refs.wizard.querySelector(`[name='${custom}']`)
              .value;
            if (val) {
              if (paramsTypes[custom]?.type === 'integer') {
                newCustoms[custom] =
                  val > 0 ? val : this.state.campaignCustoms[custom];
              } else {
                newCustoms[custom] = val;
              }
            } else {
              newCustoms[custom] = val;
            }
          }
        }
      }
    });
    this.setState({
      campaignCustoms: newCustoms,
    });
  }

  onSampleTextSelect(sampleType) {
    let sampleContent = this.state.contentSamples;
    sampleContent =
      sampleType === 'subject'
        ? sampleContent && sampleContent.subjectList
        : sampleContent && sampleContent.contentList;
    if (!sampleContent) {
      sampleContent = [];
    }

    const sampleContentModal = () => (
      <SampleContentModal
        content={sampleContent}
        onSelect={this.onSelectSampleContent}
        contentType={sampleType}
      />
    );

    modalActions.openModal({
      title:
        `Select ${sampleType}` === 'subject' ? 'Mail Subject' : 'Mail Content',
      content: sampleContentModal,
    });
  }

  onSelectSampleContent(contentType, selectedContent) {
    modalActions.closeModal();
    let additionalSubjects;
    let additionalContents = {};
    const tempType = `additional${contentType
      .charAt(0)
      .toUpperCase()}${contentType.slice(1)}s`;
    const lang = this.state.selectedLanguage;
    if (contentType === 'subject') {
      additionalSubjects = {
        ...this.state.additionalSubjects,
        [lang]: selectedContent,
      };
    }

    if (contentType === 'mailContent') {
      additionalContents = {
        ...this.state.additionalContents,
        [lang]: selectedContent,
      };
    }
    this.setState(
      {
        [contentType]: selectedContent,
        [`_${contentType}`]: selectedContent,
        [tempType === 'additionalSubjects' ? tempType : 'additionalContents']:
          contentType === 'subject' ? additionalSubjects : additionalContents,
      },
      () => {
        this.refs.wizard.querySelector('input').focus();
        this.refs.wizard.querySelector('textarea').focus();
        this.refs.wizard.querySelector('input').blur();
        this.refs.wizard.querySelector('textarea').blur();
      },
    );
  }

  openTestModal() {
    const sendTestModal = () => (
      <SendTestModal
        instanceId={this.state.campaign.instanceId}
        template={this.state.template}
        campaignStatus={this.state.campaign.status}
        additionalSubjects={this.state.additionalSubjects}
        additionalContents={this.state.additionalContents}
      />
    );

    modalActions.openModal({
      title: 'Send Test Mail',
      content: sendTestModal,
    });
  }

  openTestModalKlaviyo() {
    const sendTestModal = () => (
      <SendTestModalKlaviyo
        instanceId={this.state.campaign.instanceId}
        campaignStatus={this.state.campaign.status}
        additionalSubjects={this.state.additionalSubjects}
        additionalContents={this.state.additionalContents}
      />
    );

    modalActions.openModal({
      title: 'Send Test Mail',
      content: sendTestModal,
    });
  }

  onWorkingHourChange(newVal) {
    const campaignCustoms = { ...this.state.campaignCustoms };
    campaignCustoms.workingHour = newVal.format('HH:mm');
    this.setState({
      campaignCustoms,
    });
  }

  onMailToChange(e) {
    const campaignCustoms = { ...this.state.campaignCustoms };
    campaignCustoms.mailTo = e.target.value;
    this.setState({
      campaignCustoms,
    });
  }

  onScenarioNameChange(e) {
    this.setState({
      scenarioName: e.target.value,
    });
  }

  onCampaignCappingParamChange(param, value) {
    const { capping } = this.state;
    capping[param] = value;
    let emptyValue = '';
    let emptyValueCount = 0;
    Object.keys(capping).forEach(cappingKey => {
      if (cappingKey !== 'types') {
        if (!capping[cappingKey]) {
          emptyValue = cappingKey;
          emptyValueCount++;
        }
      }
    });
    this.setState({
      capping,
      cappignError:
        emptyValue && emptyValueCount < 2
          ? 'You should fill all fields'
          : false,
    });
  }

  toggleDiscountInput() {
    this.setState({
      showDiscount: !this.state.showDiscount,
    });
  }

  toggleIdentifyInput() {
    this.setState({
      sendToIdentifyApi: !this.state.sendToIdentifyApi,
    });
  }

  customTemplateAction(actionType) {
    let pathname = '';
    const state = { fromPathName: this.props.location.pathname };
    if (actionType === 'create') {
      pathname = '/email/templates/create';
    } else if (actionType === 'edit' && this.state.template) {
      const selectedTemplateObj = this.state.customTemplateList.filter(
        template => template.templateName === this.state.template,
      );
      pathname = `/email/templates/edit/${selectedTemplateObj[0].id}`;
    }

    if (pathname) {
      browserHistory.push({ pathname, state });
    }
  }

  render() {
    const campaignStatus =
      this.state.campaignStatus === 'ACTIVE'
        ? 'LIVE'
        : this.state.campaignStatus;
    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
    const _hasKlaviyoConfiguration = hasKlaviyoConfiguration();
    const mainLanguage = this.state.language || account.mainLanguage;
    const mainCurrency = this.state.currency || account.mainCurrency.code;

    let langList =
      account.additionalLanguages &&
      account.additionalLanguages.indexOf(account.mainLanguage) > -1
        ? account.additionalLanguages
        : [account.mainLanguage].concat(account.additionalLanguages);
    langList = langList.map(lang => {
      return { value: lang, label: lang };
    });
    langList.push({ value: 'SESSION_BASED', label: 'Session based' });
    let currencyList =
      account.additionalCurrencyList &&
      account.additionalCurrencyList.indexOf(account.mainCurrency.code) > -1
        ? account.additionalCurrencyList
        : [account.mainCurrency.code].concat(account.additionalCurrencyList);
    currencyList = currencyList.map(currency => {
      return { value: currency, label: currency };
    });
    currencyList.push({ value: 'SESSION_BASED', label: 'Session based' });

    const isNew = () => {
      return (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.setInitialData
      );
    };
    return (
      <form id='wizard' ref='wizard' className='wizard wizard-cards'>
        {campaignStatus !== 'PASSIVE' && (
          <h3 className='page-title'>
            <span
              className={`status-label status-label-${campaignStatus.toLowerCase()}`}
            >
              {campaignStatus.toLowerCase() === 'test'
                ? 'Draft'
                : campaignStatus}{' '}
            </span>
            {this.state.scenarioName}
          </h3>
        )}
        <div className='widget page-content-block'>
          <ol className='form-elements'>
            <div className='wizard-comp wizard-comp-campaigntitle'>
              <li>
                <div
                  className='wizard-comp'
                  style={{ marginTop: '0', paddingTop: '0' }}
                >
                  <h3
                    className='wizard-comp-title'
                    style={{ display: 'inline-block', fontWeight: 600 }}
                  >
                    {t('Mail Settings')}
                  </h3>
                  <span
                    style={{
                      position: 'relative',
                      top: '-2px',
                      left: '2px',
                    }}
                  >
                    <Tooltip
                      content={t(
                        'Prepare your email’s subject and content here. You can either get creative with your own words or select one from the content list and change it as you wish.',
                      )}
                      alignment='left'
                      placement='right'
                    >
                      <i className='icon-info' role='presentation'>
                        <span className='for-screenreader-only' />
                      </i>
                    </Tooltip>
                  </span>
                  {this.props.routeParams.emailType.indexOf('eml') < 0 &&
                    langList.length > 2 && (
                      <span className='campaign-language-selection'>
                        {langList.map((item, index) => {
                          if (item.label !== 'Session based') {
                            return (
                              <span
                                key={`${item} ${index}`}
                                onClick={this.selectLanguage.bind(
                                  this,
                                  item.label,
                                )}
                                className={classNames('language-box', {
                                  'is-selected':
                                    this.state.selectedLanguage ===
                                      item.label ||
                                    (index === 0 &&
                                      this.state.language === 'SESSION_BASED' &&
                                      this.state.selectedLanguage === ''),
                                  'is-disabled':
                                    this.state.language !== 'SESSION_BASED',
                                })}
                              >
                                {item.label}
                              </span>
                            );
                          }
                        })}
                      </span>
                    )}
                  {!_hasKlaviyoConfiguration && (
                    <TextField
                      name='subject'
                      label='Mail Subject'
                      className='item-stacked one-whole'
                      value={
                        this.state.additionalSubjects[
                          this.state.selectedLanguage
                        ] || ''
                      }
                      required
                      tooltipText={t('Prepare the subject of your emails')}
                      tooltip
                      onChange={this.onCampaignDataChange}
                      renderProp={
                        this.state.campaign.type !== 'FLY' &&
                        this.state.campaign.type !== 'RECOMMENDATION' &&
                        (() => (
                          <div
                            className='sample-text'
                            onClick={this.onSampleTextSelect.bind(
                              this,
                              'subject',
                            )}
                          >
                            <Icons
                              name='exampleText'
                              width='14'
                              height='16'
                              color='#fff'
                            />{' '}
                            <span>{t('Select sample text')}</span>
                          </div>
                        ))
                      }
                      ref='subject'
                    />
                  )}
                  <TextArea
                    name='mailContent'
                    label='Mail Content'
                    className='item-stacked one-whole'
                    value={
                      this.state.additionalContents[
                        this.state.selectedLanguage
                      ] || ''
                    }
                    required
                    tooltipText={t(
                      'Prepare the text in your emails. This will be shown above the recommended products inside the email.',
                    )}
                    tooltip
                    tooltipLink='#'
                    onChange={this.onCampaignDataChange}
                    renderProp={
                      this.state.campaign.type !== 'FLY' &&
                      this.state.campaign.type !== 'RECOMMENDATION' &&
                      (() => (
                        <div
                          className='sample-text'
                          onClick={this.onSampleTextSelect.bind(
                            this,
                            'mailContent',
                          )}
                        >
                          <Icons
                            name='exampleText'
                            width='14'
                            height='16'
                            color='#fff'
                          />{' '}
                          <span>{t('Select sample text')}</span>
                        </div>
                      ))
                    }
                    ref='content'
                  />
                </div>
              </li>
            </div>

            <li>
              <ol className='form-elements wizard-items'>
                <div
                  className='wizard-comp'
                  style={{ marginTop: '0', paddingTop: '0' }}
                >
                  <li
                    className={
                      this.state.campaign.instanceId === 'PRICE_DROP' ||
                      this.state.campaign.instanceId === 'ABANDONED_CART' ||
                      this.state.campaign.instanceId === 'BACK_IN_STOCK'
                        ? 'wizard-perso-fields'
                        : 'wizard-perso-fields'
                    }
                  >
                    <Fields
                      ref='contentExtra'
                      campaign={this.state.campaign}
                      comps={[{ field: 'recommendations' }]}
                      isEmail
                      emailType={this.state.campaign.type}
                      disabled={
                        this.props.criteria.length === 0 &&
                        this.state.campaign.type === 'FLY'
                      }
                    />
                  </li>
                </div>
              </ol>
            </li>

            {this.state.campaign.type === 'FLY' && (
              <MailMapping
                campaignCustoms={this.state.campaignCustoms}
                onMappingChange={this.onMappingChange}
                onMailToChange={this.onMailToChange}
              />
            )}

            {this.state.campaign.type === 'RECOMMENDATION' && (
              <MPExtraContainer isEmail>
                <MPScheduling
                  isEmail
                  schedule={this.state.campaign.schedule}
                  ref='schedule'
                  isAdd
                />
                <MPAudienceEmail
                  audience={this.state.campaign.filters}
                  ref='audience'
                />
              </MPExtraContainer>
            )}

            {(this.state.campaign.type === 'ABANDONED_CART' ||
              this.state.campaign.type === 'LAST_VISIT_ALTERNATIVES' ||
              this.state.campaign.type === 'LAST_VISIT_REMINDER') && (
              <div>
                <MailCapping
                  campaignCapping={this.state.capping}
                  onCampaignCappingParamChange={
                    this.onCampaignCappingParamChange
                  }
                  errorText={this.state.cappignError}
                  scenarioName={this.state.scenarioName}
                />
              </div>
            )}
          </ol>
        </div>

        <div className='wizard-options email-wizard-option'>
          <ol className='form-elements'>
            <label className='item-head-title' style={{ oveflow: 'hidden' }}>
              {t('Campaign Name')}
            </label>
            {this.state.campaign.type === 'RECOMMENDATION' ||
            this.state.campaign.type === 'FLY' ? (
              <li
                className='campaign-subject'
                style={{
                  marginBottom: '35px',
                  paddingTop: '20px',
                  pointerEvents: 'auto',
                  opacity: '1',
                }}
              >
                <TextField
                  name='scenarioName'
                  className='item-stacked one-whole label-emphasise'
                  value={this.state.scenarioName}
                  required
                  onChange={this.onScenarioNameChange}
                />
              </li>
            ) : (
              <li
                className='campaign-subject'
                style={{
                  marginBottom: '35px',
                  paddingTop: '20px',
                  pointerEvents: 'none',
                  opacity: '0.7',
                }}
              >
                <TextField
                  name='scenarioName'
                  className='item-stacked one-whole label-emphasise'
                  value={this.state.scenarioName}
                  required
                  onChange={this.onScenarioNameChange}
                  readOnly
                  disabled
                />
              </li>
            )}
            <label className='item-head-title'>
              {t('Campaign Details')}
              <Tooltip
                content={t('Determine campaign based configurations')}
                alignment='right'
                placement='bottom'
              >
                <i className='icon-info' role='presentation'>
                  <span className='for-screenreader-only' />
                </i>
              </Tooltip>
            </label>
            {this.state.campaign.type === 'FLY' && (
              <WizardDateSelection
                ref='wizardDateSelection'
                campaign={this.state.campaign}
                isEmail
              />
            )}
            {Object.keys(this.state.campaignCustoms).map(custom => {
              if (custom === 'daysWithin') return;

              // Hide non-sidebar params and localization params
              if (
                this.state.campaign.scenarioName === 'Price Drop' &&
                this.state.campaignCustoms.discountRatio === undefined
              ) {
                this.setState({
                  campaignCustoms: {
                    ...this.state.campaignCustoms,
                    discountRatio: '',
                  },
                });
              }
              if (
                paramsInWizard.indexOf(custom) < 0 &&
                Object.keys(localizationParams).indexOf(custom) < 0
              ) {
                if (paramsTypes[custom]?.type === 'integer') {
                  if (paramsTypes[custom]?.max) {
                    const start = paramsTypes[custom]?.min;
                    const end = paramsTypes[custom]?.max;
                    const list = [];
                    for (let i = start; i <= end; i++) {
                      list.push(i);
                    }
                    return (
                      <label
                        className='item item-stacked one-whole label-emphasise is-select label-mail'
                        key={paramsTypes[custom]?.type}
                      >
                        <span className='item-label'>
                          {t(paramsTypes[custom]?.label)}
                          <Tooltip
                            content={t(paramsTypes[custom]?.tooltip)}
                            alignment='right'
                            placement='bottom'
                          >
                            <i className='icon-info' role='presentation'>
                              <span className='for-screenreader-only' />
                            </i>
                          </Tooltip>
                        </span>
                        <select
                          defaultValue={this.state.campaignCustoms[custom]}
                          value={this.state.campaignCustoms[custom]}
                          className='one-whole criteria-field'
                          onChange={this.onCustomParamChange}
                          name={custom}
                        >
                          {list.map(item => {
                            return (
                              <option key={item} value={item}>
                                {custom === 'workingDay'
                                  ? t(days[item - 1])
                                  : item}
                              </option>
                            );
                          })}
                        </select>
                      </label>
                    );
                  }
                  return (
                    <span key={custom} className='label-mail label-text-field'>
                      <TextField
                        name={custom}
                        label={t(paramsTypes[custom]?.label)}
                        className='item-stacked one-whole label-emphasise'
                        value={this.state.campaignCustoms[custom]}
                        required
                        tooltipText={t(paramsTypes[custom]?.tooltip)}
                        tooltipPlacement='top'
                        tooltipAlignment='center'
                        tooltip
                        type='number'
                        onChange={this.onCustomParamChange}
                      />
                    </span>
                  );
                }
                if (paramsTypes[custom]?.type === 'timepicker') {
                  const constraint = { minutes: { step: 15 } };
                  let val = this.state.campaignCustoms[custom];
                  if (val.length < 3) {
                    val += ':00';
                  }
                  return (
                    <React.Fragment key={paramsTypes[custom]?.type}>
                      {this.state.campaign.instanceId && (
                        <TimeAlert
                          getter={getActiveEmails}
                          refElem={this.workingHourRef}
                          hour={this.state.campaignCustoms.workingHour}
                          instanceId={this.state.campaign.instanceId}
                        />
                      )}
                      <label
                        className='item item-stacked one-whole label-emphasise is-select label-mail'
                        ref={this.workingHourRef}
                      >
                        <span className='item-label'>
                          {t(paramsTypes[custom]?.label)}
                          <Tooltip
                            content={t(paramsTypes[custom]?.tooltip)}
                            alignment='right'
                            placement='bottom'
                          >
                            <i className='icon-info' role='presentation'>
                              <span className='for-screenreader-only' />
                            </i>
                          </Tooltip>
                        </span>
                        <Calendar
                          name='date'
                          className='item-field small'
                          value={val}
                          dateFormat={false}
                          timeConstraints={constraint}
                          onChange={this.onWorkingHourChange}
                        />
                      </label>
                    </React.Fragment>
                  );
                }
                if (paramsTypes[custom]?.type === 'text') {
                  if (custom === 'discountRatio') {
                    return (
                      <span
                        key={custom}
                        className='label-mail label-text-field'
                      >
                        <label className='item item-stacked is-checkbox'>
                          <input
                            type='checkbox'
                            checked={this.state.showDiscount}
                            onChange={this.toggleDiscountInput}
                          />
                          <span className='item-label'>
                            {t(paramsTypes[custom]?.label)}
                            <Tooltip
                              content={t(paramsTypes[custom]?.tooltip)}
                              alignment='right'
                              placement='top'
                            >
                              <i className='icon-info' role='presentation'>
                                <span className='for-screenreader-only' />
                              </i>
                            </Tooltip>
                          </span>

                          {this.state.showDiscount && (
                            <span className='label-mail label-text-field'>
                              <TextField
                                name='discountRatio'
                                value={this.state.campaignCustoms[custom]}
                                className='item-stacked one-whole label-emphasise'
                                required={false}
                                type='text'
                                onChange={this.onCustomParamChange}
                              />
                            </span>
                          )}
                        </label>
                      </span>
                    );
                  }
                  if (custom !== 'klaviyoEventName') {
                    return (
                      <span
                        key={custom}
                        className='label-mail label-text-field'
                      >
                        <TextField
                          name={custom}
                          label={t(paramsTypes[custom]?.label)}
                          className='item-stacked one-whole label-emphasise'
                          value={this.state.campaignCustoms[custom]}
                          required
                          tooltipText={t(paramsTypes[custom]?.tooltip)}
                          tooltipPlacement='bottom'
                          tooltip
                          type='text'
                          onChange={this.onCustomParamChange}
                        />
                      </span>
                    );
                  }
                  if (
                    this.state.campaign.type === 'FLY' &&
                    custom === 'klaviyoEventName'
                  ) {
                    return (
                      <span key={custom} className='label-text-field'>
                        <TextField
                          name={custom}
                          label={t(paramsTypes[custom]?.label)}
                          className='item-stacked one-whole label-emphasise'
                          value={this.state.campaignCustoms[custom]}
                          tooltipText={t(paramsTypes[custom]?.tooltip)}
                          tooltipPlacement='bottom'
                          tooltip
                          type='text'
                          validation={{ klaviyoPrefix: true }}
                          onChange={this.onCustomParamChange}
                          required
                        />
                      </span>
                    );
                  }
                } else {
                  const start = paramsTypes[custom]?.min;
                  const end = paramsTypes[custom]?.max;
                  const list = [];
                  for (let i = start; i <= end; i++) {
                    list.push(i);
                  }
                  return (
                    <label className='item item-stacked one-whole label-emphasise is-select label-mail label-interval'>
                      <span className='item-label'>
                        {t(paramsTypes[custom]?.label)}
                        <Tooltip
                          content={t(paramsTypes[custom]?.tooltip)}
                          alignment='left'
                        >
                          <i className='icon-info' role='presentation'>
                            <span className='for-screenreader-only' />
                          </i>
                        </Tooltip>
                      </span>
                      <select
                        value={this.state.campaignCustoms[custom].split('-')[0]}
                        className='one-whole criteria-field label-interval--start'
                        onChange={this.onCustomParamChange}
                        name={`${custom}Start`}
                      >
                        {list.map(item => {
                          const higherValue = this.state.campaignCustoms[
                            custom
                          ].split('-')[1];
                          if (higherValue <= item) {
                            return (
                              <option key={item} value={item} disabled>
                                {item}
                              </option>
                            );
                          }
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      -
                      <select
                        value={this.state.campaignCustoms[custom].split('-')[1]}
                        className='one-whole criteria-field label-interval--end'
                        onChange={this.onCustomParamChange}
                        name={`${custom}End`}
                      >
                        {list.map(item => {
                          const lowerValue = this.state.campaignCustoms[
                            custom
                          ].split('-')[0];
                          if (lowerValue >= item) {
                            return (
                              <option key={item} value={item} disabled>
                                {item}
                              </option>
                            );
                          }
                          return (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </label>
                  );
                }
              }
            })}

            {!_hasKlaviyoConfiguration && (
              <label className='item item-stacked one-whole label-emphasise is-select label-mail'>
                <span className='item-label'>
                  {t('Campaign Template')}
                  <Tooltip
                    content={t(
                      'Choose an email template from your ESP account.',
                    )}
                    alignment='right'
                    placement='bottom'
                  >
                    <i className='icon-info' role='presentation'>
                      <span className='for-screenreader-only' />
                    </i>
                  </Tooltip>
                </span>
                <select
                  value={this.state.template}
                  className='one-whole criteria-field'
                  onChange={this.onCampaignDataChange}
                  name='template'
                >
                  {this.state.mailTemplates.map(item => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </label>
            )}
            {hasDotDigitalConfiguration() && (
              <label className='item item-stacked one-whole'>
                <span className='item-label'>
                  <a
                    href
                    onClick={this.openDotModal.bind(this)}
                    style={{
                      display: 'inline',
                      color: '#375065',
                      textDecoration: 'underline',
                      fontSize: '9pt',
                    }}
                  >
                    {t('Show dotdigital template variables')}
                  </a>
                  <Tooltip
                    content={t('List template variables.')}
                    alignment='right'
                    placement='top'
                  >
                    <i
                      className='icon-info'
                      style={{ color: '#375065' }}
                      role='presentation'
                    >
                      <span className='for-screenreader-only' />
                    </i>
                  </Tooltip>
                </span>
              </label>
            )}
            {this.state.campaign.type !== 'FLY' && (
              <div>
                <label className='item item-stacked one-whole label-emphasise is-select label-mail'>
                  <span className='item-label'>
                    {t('Language')}
                    <Tooltip
                      content={t("Email campaign's selected language")}
                      alignment='left'
                    >
                      <i className='icon-info' role='presentation'>
                        <span className='for-screenreader-only' />
                      </i>
                    </Tooltip>
                  </span>
                  <select
                    value={mainLanguage}
                    className='one-whole criteria-field'
                    onChange={this.onCampaignDataChange}
                    name='language'
                    ref='language'
                  >
                    {langList.map(item => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </label>

                <label className='item item-stacked one-whole label-emphasise is-select label-mail'>
                  <span className='item-label'>
                    {t('Currency')}
                    <Tooltip
                      content={t("Email campaign's selected currency")}
                      alignment='left'
                    >
                      <i className='icon-info' role='presentation'>
                        <span className='for-screenreader-only' />
                      </i>
                    </Tooltip>
                  </span>
                  <select
                    value={mainCurrency}
                    className='one-whole criteria-field'
                    onChange={this.onCampaignDataChange}
                    name='currency'
                    ref='currency'
                  >
                    {currencyList.map(item => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                </label>

                {_hasKlaviyoConfiguration &&
                  this.state.campaign.type === 'RECOMMENDATION' && (
                    <label className='item item-stacked one-whole label-emphasise'>
                      <span className='item-label'>
                        {t('Prefix Name')}
                        <Tooltip
                          content={t(
                            'Enter a prefix to add the campaign to the profile in Klaviyo',
                          )}
                          alignment='left'
                        >
                          <i className='icon-info' role='presentation'>
                            <span className='for-screenreader-only' />
                          </i>
                        </Tooltip>
                      </span>
                      <span className='label-mail label-text-field'>
                        <TextField
                          name='klaviyoEvent'
                          value={this.state.klaviyoEventName}
                          className='item-stacked one-whole label-emphasise'
                          type='text'
                          validation={{ klaviyoPrefix: true }}
                          onChange={this.onKlaviyoEventNameChange}
                          required
                        />
                      </span>
                    </label>
                  )}

                {_hasKlaviyoConfiguration && (
                  <span
                    key='sendToIdentifyApi'
                    className='label-mail label-text-field'
                  >
                    <label className='item item-stacked is-checkbox'>
                      <input
                        type='checkbox'
                        name='sendToIdentifyApi'
                        checked={this.state.sendToIdentifyApi}
                        onChange={this.toggleIdentifyInput}
                      />
                      <span className='item-label'>{t('Sync To Profile')}</span>
                    </label>
                  </span>
                )}
              </div>
            )}

            {this.state.campaign.type === 'FLY' && (
              <div>
                {_hasKlaviyoConfiguration && (
                  <span
                    key='sendToIdentifyApi'
                    className='label-mail label-text-field'
                  >
                    <label className='item item-stacked is-checkbox'>
                      <input
                        type='checkbox'
                        name='sendToIdentifyApi'
                        checked={this.state.sendToIdentifyApi}
                        onChange={this.toggleIdentifyInput}
                      />
                      <span className='item-label'>{t('Sync To Profile')}</span>
                    </label>
                  </span>
                )}
              </div>
            )}

            {this.state.isSegmentifyProvider && (
              <>
                <label
                  className='item-head-title'
                  style={{ marginTop: '20px' }}
                >
                  {t('Template Actions')}
                  <Tooltip
                    content={t(
                      'Edit the selected template or create a new one.',
                    )}
                    alignment='right'
                    placement='bottom'
                  >
                    <i className='icon-info' role='presentation'>
                      <span className='for-screenreader-only' />
                    </i>
                  </Tooltip>
                </label>
                <li className='buttons' style={{ marginBottom: '40px' }}>
                  <a
                    className={classNames({
                      'button custom-template-action ct-edit one-whole': true,
                      disabled: !this.state.template,
                    })}
                    onClick={() => this.customTemplateAction('edit')}
                  >
                    {t('Edit Template')}
                  </a>

                  <a
                    className={classNames({
                      'button custom-template-action ct-create one-whole': true,
                    })}
                    onClick={() => this.customTemplateAction('create')}
                  >
                    {t('Create Template')}
                  </a>
                </li>
              </>
            )}

            <li className='buttons' style={{ marginTop: '20px' }}>
              {this.state.campaign.type !== 'FLY' &&
                !_hasKlaviyoConfiguration &&
                this.state.userRole !== 'READ_ONLY' && (
                  <a
                    className={classNames({
                      'button preview-action one-whole': true,
                      'preview-action--disabled':
                        isNew() && !this.props.formSaved,
                    })}
                    onClick={this.openTestModal}
                  >
                    {t('Send Test Email')}
                  </a>
                )}
              {this.state.campaign.type !== 'FLY' && _hasKlaviyoConfiguration && (
                <a
                  className={classNames({
                    'button preview-action one-whole': true,
                    'preview-action--disabled':
                      isNew() && !this.props.formSaved,
                  })}
                  onClick={this.openTestModalKlaviyo}
                >
                  {t('Send Test Email')}
                </a>
              )}
              {this.state.userRole !== 'READ_ONLY' && (
                <a
                  className={classNames('button gotest-action one-whole', {
                    'gotest-action--disabled': this.props.ui === 'isLoading',
                  })}
                  onClick={this.validate.bind(null, 'test')}
                >
                  {t('Go Draft')}
                </a>
              )}
              {this.state.userRole === 'ADMIN' && (
                <a
                  className={classNames(
                    'button tertiary-action one-whole golive-action',
                    {
                      'golive-action--disabled':
                        this.props.ui === 'isLoading' ||
                        this.state.disableLiveButton,
                    },
                  )}
                  onClick={this.validate.bind(null, 'live')}
                >
                  {t('Go Live')}
                </a>
              )}
            </li>
          </ol>
        </div>
      </form>
    );
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate,
  breadcrumb: store.ui.breadcrumb,
  formSaved: store.ui.formSaved,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes,
  mappings: store.mailWizard.mappings,
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
  ui: store.ui.ui,
});

EditEmailCampaign.contextTypes = {
  router: PropTypes.object.isRequired,
};

EditEmailCampaign.propTypes = {
  validate: PropTypes.bool,
};

export default connect(mapStatesToProps)(EditEmailCampaign);
