"use strict";

import React, { Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { SortableHandle } from "react-sortable-hoc";
import { decodeHtml } from "../../../system/string";
import Icon from "../../icon";
import Icons from "../../icons";
import Tooltip from "../../../components/tooltip";
import { searchCategories } from "../../../modules/category/ajax";
import { t } from "../../../system/ui";
import {  items } from "../../../constants/datamaps/wizard";

class SearchCriterionCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      criterion: "",
    };
    this.onChange = this.onChange.bind(this);
    this.setCategories = this.setCategories.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    this.setCategories();
  }

  setCategories() {
    if (typeof this.props.values.criterion !== "undefined") {
      this.setState(
        {
          criterion: this.props.values.criterion.value,
        },
        () => {
          this.onChange({
            value: this.state.criterion,
            label: this.state.criterion,
          });
        }
      );
    }
  }

  
  onChange(selectedValue) {
    let selectedCategory =
      "value" in selectedValue ? selectedValue.value : this.state.criterion;
    this.setState(
      {
        criterion: selectedCategory,
        categories: [
          {
            value: selectedCategory,
            title: decodeHtml(selectedCategory),
            label: decodeHtml(selectedCategory),
          },
        ],
      },
      () => {
        this.props.onCriteriaChange(
          this.refs.inputs,
          this.props.id,
          selectedCategory
        );
      }
    );
  }


  onInputChange(value) {
    if (value.length > 2) {
      searchCategories(value, (response) => {
        let categoryOptions = [];
        if (response) {
          response.forEach((category) => {
            categoryOptions.push({
              value: category,
              title: decodeHtml(category),
              label: decodeHtml(category),
            });
          });
        }
        this.setState({
          categories: categoryOptions,
        });
      });
    } else {
      if (this.state.criterion) {
        this.setState({
          categories: [
            {
              value: this.state.criterion,
              title: decodeHtml(this.state.criterion),
              label: decodeHtml(this.state.criterion),
            },
          ],
        });
      } else {
        this.setState({
          categories: [],
        });
      }
    }
    return value;
  }

  render() {
      let  theItemCount =
      typeof this.props.values.itemCount !== "undefined"
        ? this.props.values.itemCount.value
        : "";
    const DragHandle = SortableHandle(() => (
      <span className="draghandler">
        <Icons name="burger" color="#e8e7f2" />
      </span>
    ));

    return(
        <li className="wizard-criterion" ref="inputs">
        <DragHandle />
        <span className="criteria-type">
          <Tooltip content={t("Static (Category)")} alignment="left">
            <Icon name="tag" />
          </Tooltip>
        </span>
  
        <label className="item item-field is-select criteria-name-search">
          <Select
            value={this.state.criterion}
            options={this.state.categories}
            name="criterion"
            clearable={false}
            searchable={true}
            onChange={this.onChange}
            onInputChange={this.onInputChange}
            className="criteria-field-search one-whole"
            openOnClick={false}
            placeholder={t("Search Categories")}
          />
        </label>
  
        <label className="item item-field is-select criteria-item-count">
          <span className="item-label">{this.props.mainType.toLowerCase()}</span>{" "}
          <select
            name="itemCount"
            className="one-whole criteria-field-search"
            disabled={true}
            onChange={this.onChange}
            defaultValue={theItemCount}
          >
            {items.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </label>
  
        <a
          onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
          className={"cancel-action"}
        >
          <Icons name="crossCircle" />
        </a>
      </li>
    )
  }
}

const mapStatesToProps = (store) => ({
    criteria: store.searchWizard.criteria,
    categories: store.categories.options,
    isCategoriesSet: store.categories.isSet,
  });
  
  export default connect(mapStatesToProps)(SearchCriterionCategory);
  