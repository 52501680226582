/**
 * Created by mehmetyurtar on 4/4/18.
 */
import React from "react";

class IconMinusCircleSmall extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Wizard-2"
            transform="translate(-994.000000, -398.000000)"
            fill={this.state.color}
            fillRule="nonzero"
          >
            <g
              id="Recommendation-Types"
              transform="translate(107.000000, 285.000000)"
            >
              <g
                id="Intelligent-[Button]-Copy-2"
                transform="translate(872.000000, 99.000000)"
              >
                <g id="Icon+Text" transform="translate(15.000000, 12.000000)">
                  <g
                    id="ui-16px-2_circle-bold-delete"
                    transform="translate(0.000000, 2.000000)"
                  >
                    <rect
                      id="Rectangle-path"
                      x={4}
                      y={7}
                      width={8}
                      height={2}
                    />
                    <path
                      d="M8,0 C3.589,0 0,3.589 0,8 C0,12.411 3.589,16 8,16 C12.411,16 16,12.411 16,8 C16,3.589 12.411,0 8,0 Z M8,14 C4.691,14 2,11.309 2,8 C2,4.691 4.691,2 8,2 C11.309,2 14,4.691 14,8 C14,11.309 11.309,14 8,14 Z"
                      id="Shape"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconMinusCircleSmall;
