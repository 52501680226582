import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import {
  dateActions,
  filterActions,
  modalActions,
  switchUserActions,
  uiActions,
} from '../actions';
import AdminActions from '../components/admin/admin-actions';
import CampaignStats from '../components/admin/campaign-stats';
import EmailCampaignStats from '../components/admin/email-campaign-stats';
import PushCampaignStats from '../components/admin/push-campaign-stats';
import SearchCampaignStats from '../components/admin/search-campaign-stats';
import SelectUser from '../components/admin/select-user';
import Currency from '../components/currency';
import AccountFilter from '../components/filters/account-filter';
import Filters from '../components/filters/filters';
import Icon from '../components/icon';
import Tooltip from '../components/tooltip';
import { statsTable } from '../constants/datamaps/account';
import { getAccountStats } from '../modules/account/ajax';
import { isSuperUser, redirectUserToFirstPage } from '../modules/auth/user';
import setHighchartsDefaults from '../modules/highcharts.global';
import { queryDate } from '../system/date';
import { setTitle } from '../system/document';
import {
  calculateProportion,
  calculateRatio,
  diffRatio,
  numericCalculateRatio,
} from '../system/ratio';
import { localeString } from '../system/string';
import { t } from '../system/ui';

const platforms = ['PC', 'MOBILE', 'APPS'];

const browserSlugs = {
  Chrome: 'chrome',
  Firefox: 'firefox',
  Safari: 'safari',
  Opera: 'opera',
  Yandex: 'yandex',
  Samsung: 'samsung',
};

const apps = {
  android: 'ANDROID',
  ios: 'IOS',
};

class Admin_Stats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: [],
      _stats: [],
      filters: ['type', 'provider'],
      sorting: 'ratioAmount',
      reverse: true,
      filter: 'type',
      types: [
        'PRE_POC',
        'POC',
        'PAYING',
        'FREEMIUM',
        'STAGING',
        'ONHOLD',
        'FAILED',
        'CHURN',
        'RESTRICTED',
      ],
      providers: [
        'SEGMENTIFY',
        'INTERNAL',
        'BIGCOMMERCE',
        'SHOPIFY',
        'TSOFT',
        'DENGAGE',
      ],
      accountsWithWarning: {},
      defaultTypes: [
        'PRE_POC',
        'POC',
        'PAYING',
        'FREEMIUM',
        'STAGING',
        'ONHOLD',
        'FAILED',
        'CHURN',
        'RESTRICTED',
      ],
      selectedProviders: [],
      selectedModule: 'recommendation',
      searched: false,
      searchTriggered: false,
      pushValues: [],
    };

    this.sort = this.sort.bind(this);
    this.switchToUser = this.switchToUser.bind(this);
    this.getAccountsWithWarning = this.getAccountsWithWarning.bind(this);
    this.showCampaignStats = this.showCampaignStats.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.updateStats = this.updateStats.bind(this);
    this.onPushUpdate = this.onPushUpdate.bind(this);
    this.onGaUpdate = this.onGaUpdate.bind(this);
    this.onGa4Update = this.onGa4Update.bind(this);
    this.filterAccounts = this.filterAccounts.bind(this);
  }

  componentDidMount() {
    setTitle(t('Account Statistics'));
    uiActions.resetPage();
    uiActions.addPageClass('sidebar-is-hidden');
    dateActions.updateRangeAlias('daily');
    localStorage.removeItem('account');

    if (isSuperUser()) {
      const params = {
        sorting: false,
        dateRange: [
          moment()
            .add('days')
            .startOf('day'),
          moment()
            .add('days')
            .endOf('day'),
        ],
        listBySorting: false,
      };
      this.listStats(params);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.user !== newProps.user) {
      if (!isSuperUser(newProps.user)) {
        this.context.router.push({
          pathname: '/',
        });

        return;
      }

      const params = {};
      this.listStats(params);
    }

    if (newProps.switchedUser !== this.props.switchedUser) {
      redirectUserToFirstPage();
    }

    if (newProps.reset === true) {
      this.reset(newProps.range);
    }

    if (newProps.update === true) {
      const params = {
        sorting: this.state.sorting,
        dateRange: newProps.range,
        listBySorting: false,
        rangeAlias: newProps.rangeAlias,
        query: this.state.filterObj,
      };
      this.listStats(params);
    }
  }

  filterAccounts(filterObj) {
    this.setState(
      {
        filterObj,
      },
      () => {
        const params = {
          sorting: this.state.sorting,
          dateRange: this.props.range,
          listBySorting: false,
          rangeAlias: this.props.rangeAlias,
          query: filterObj,
        };
        this.listStats(params);
      },
    );
  }

  subsTotal(subsIds) {
    const stateCopy = this.state.stats.slice().map(statsObj => {
      const total = statsObj.stats
        ? Object.keys(statsObj.stats).reduce(
            (acc, key) =>
              acc +
              subsIds.reduce(
                (result, subid) =>
                  result + (subid === key ? statsObj.stats[key] : 0),
                0,
              ),
            0,
          )
        : 0;
      return {
        ...statsObj,
        stats: { ...statsObj.stats, totalSubValue: total },
      };
    });
    this.setState({ stats: stateCopy });
  }

  sort(sorting) {
    const params = {
      sorting,
      dateRange: false,
      listBySorting: true,
      query: this.state.filterObj,
    };
    this.listStats(params);
  }

  listStats(params) {
    const { sorting } = params;
    const { dateRange } = params;
    const { listBySorting } = params;
    const { rangeAlias } = params;
    const query = { ...params.query };

    uiActions.isLoading();

    const range = dateRange || this.props.range;
    const accountId = this.refs.accountSearch.querySelector('[name=accountId]');

    if (sorting) {
      query.sort = sorting;
      if (listBySorting) {
        query.reverse =
          this.state.sorting === sorting ? !this.state.reverse : true;
      } else {
        query.reverse = this.state.reverse;
      }
    }

    query.start = queryDate(range[0]);
    query.end = queryDate(range[1]);
    query.accountId = accountId.value;
    query.datelabel = rangeAlias;

    getAccountStats(query, response => {
      let { selectedModule } = this.state;

      if (this.state.searchTriggered) {
        const accountsWithRecommendation = response.filter(stat => {
          const acc = stat.account;
          return (
            acc.features.recommendation.status === 'ACTIVE' ||
            acc.features.recommendation.status === 'POC'
          );
        });
        const accountsWithMail = response.filter(stat => {
          const acc = stat.account;
          return (
            acc.features.email.status === 'ACTIVE' ||
            acc.features.email.status === 'POC'
          );
        });

        const accountsWithPush = response.filter(stat => {
          const acc = stat.account;
          return (
            acc.features.push.status === 'ACTIVE' ||
            acc.features.push.status === 'POC'
          );
        });

        const accountsWithSearch = response.filter(stat => {
          const acc = stat.account;
          return (
            acc.features.search.status === 'ACTIVE' ||
            acc.features.search.status === 'POC'
          );
        });

        if (accountsWithRecommendation.length) {
          selectedModule = 'recommendation';
        } else if (accountsWithMail.length) {
          selectedModule = 'email';
        } else if (accountsWithPush.length) {
          selectedModule = 'push';
        } else if (accountsWithSearch.length) {
          selectedModule = 'search';
        } else {
          selectedModule = 'other';
        }
      }

      this.setState(
        {
          stats: response,
          _stats: response,
          sorting: query.sort,
          reverse: query.reverse,
          accountsWithWarning:
            selectedModule === 'other'
              ? {}
              : this.getAccountsWithWarning(response, selectedModule),
          searched: accountId.value.length,
          selectedModule,
          searchTriggered: false,
        },
        () => {
          if (this.state.stats.length !== 0 && this.state.stats[0].stats) {
            const pushValues = Object.keys(
              this.state.stats[0].stats,
            ).filter(item => item.startsWith('pushSubsc'));
            this.setState(
              {
                pushValues,
              },
              () => {
                this.subsTotal(this.state.pushValues);
              },
            );
          }
        },
      );

      uiActions.isLoaded();
      filterActions.filtersUpdated();
    });
  }

  onChange() {
    filterActions.showButtons();
  }

  reset(dateRange) {
    this.refs.accountSearch.querySelector('[name=accountId]').value = '';

    const params = {
      sorting: false,
      dateRange,
      listBySorting: false,
    };
    this.listStats(params);

    filterActions.filtersReset();
  }

  switchToUser(users, account) {
    const accountUsers = users;
    const switchedAccount = users[0];
    switchedAccount.account = account;

    const allSwitchableAccounts = users.reduce(
      (result, user) => [
        ...result,
        ...(user.switchableAccounts ? user.switchableAccounts : []),
      ],
      [],
    );

    const switchableAccountsWithDetails = this.state._stats
      .filter(
        stat =>
          stat.account &&
          allSwitchableAccounts.includes(stat.account.accountId),
      )
      .map(stat => stat.account);

    if (users.length > 1) {
      const modal = () => (
        <SelectUser
          account={account}
          users={accountUsers}
          accounts={[account]}
          switchableAccountsWithDetails={switchableAccountsWithDetails}
        />
      );
      modalActions.openModal({
        title: t('Select User'),
        content: modal,
      });
    } else {
      switchUserActions.updateSwitchUser({
        ...switchedAccount,
        switchableAccountsWithDetails,
      });
      setHighchartsDefaults({
        ...switchedAccount,
        switchableAccountsWithDetails,
      });
    }
  }

  getAccountsWithWarning(stats, moduleValue) {
    const warningValues = {
      recommendation: {
        partial: 'sgfAmount',
        full: 'allAmount',
        criticLimit: 7.5,
        dangerLimit: 5,
      },
      email: {
        partial: 'mailAmount',
        full: 'allAmount',
        criticLimit: 2,
        dangerLimit: 1,
      },
      push: {
        partial: 'pushAmount',
        full: 'allAmount',
        criticLimit: 2,
        dangerLimit: 1,
      },
      search: {
        partial: 'searchAmount',
        full: 'allAmount',
        criticLimit: 5,
        dangerLimit: 2.5,
      },
    };

    const selectedModule = moduleValue || 'recommendation';
    return stats.reduce((result, statItem) => {
      let warningType = '';
      const { account } = statItem;
      if (account) {
        const ctr = calculateRatio(
          statItem.stats[warningValues[selectedModule].partial],
          statItem.stats[warningValues[selectedModule].full],
        );
        if (ctr < warningValues[selectedModule].criticLimit) {
          warningType = 'CRITIC';
        }
        if (ctr < warningValues[selectedModule].dangerLimit) {
          warningType = 'DANGER';
        }

        if (moduleValue === 'search') {
          return { ...result, [account.accountId]: { warningType } };
        }
        const isProperType =
          (account && account.type === 'POC') ||
          account.type === 'PAYING' ||
          account.type === 'PRE_POC';

        if (isProperType && warningType) {
          return { ...result, [account.accountId]: { warningType } };
        }
      }
      return result;
    }, {});
  }

  showCampaignStats(account, user, e) {
    e.preventDefault();
    if (this.state.selectedModule !== 'other') {
      let content;
      if (this.state.selectedModule === 'email') {
        content = () => (
          <EmailCampaignStats
            account={account}
            userToSwitch={user}
            rangeAlias={this.props.rangeAlias}
          />
        );
      } else if (this.state.selectedModule === 'recommendation') {
        // content = () => (
        //   <CampaignStats
        //     account={account}
        //     userToSwitch={user}
        //     rangeAlias={this.props.rangeAlias}
        //   />
        // );
      } else if (this.state.selectedModule === 'search') {
        content = () => (
          <SearchCampaignStats
            account={account}
            userToSwitch={user}
            rangeAlias={this.props.rangeAlias}
          />
        );
      } else {
        content = () => (
          <PushCampaignStats
            account={account}
            userToSwitch={user}
            rangeAlias={this.props.rangeAlias}
          />
        );
      }

      uiActions.openModal({
        title: `${t('Campaign Details')} - ${account.domain}`,
        content,
        className: 'campaign-details-modal',
      });
    }
  }

  updateAccount() {
    modalActions.closeModal();
    filterActions.updateFilters();
  }

  onPushUpdate(accountId, data) {
    const updatedStats = this.state._stats.map(stat => {
      if (stat.account.accountId === accountId) {
        const newObj = { ...stat };
        if (!newObj.account.pushConfiguration) {
          newObj.account.pushConfiguration = {
            webConfiguration: {},
          };
        }

        newObj.account.pushConfiguration.webConfiguration = {
          ...newObj.account.pushConfiguration.webConfiguration,
          ...data,
        };
        return newObj;
      }
      return stat;
    });

    const updatedCurrentStats = this.state.stats.map(stat => {
      if (stat.account.accountId === accountId) {
        const newObj = { ...stat };
        if (!newObj.account.pushConfiguration) {
          newObj.account.pushConfiguration = {
            webConfiguration: {},
          };
        }

        newObj.account.pushConfiguration.webConfiguration = {
          ...newObj.account.pushConfiguration.webConfiguration,
          ...data,
        };
        return newObj;
      }
      return stat;
    });
    this.setState({
      _stats: updatedStats,
      stats: updatedCurrentStats,
    });
  }

  onGaUpdate(accountId, data) {
    const updatedStats = this.state._stats.map(stat => {
      if (stat.account.accountId === accountId) {
        const newObj = { ...stat };
        if (!newObj.account.googleAnalytics) {
          newObj.account.googleAnalytics = {
            googleAnalytics: {},
          };
        }
        newObj.account.googleAnalytics = {
          ...newObj.account.googleAnalytics,
          ...data,
        };
        return newObj;
      }
      return stat;
    });

    const updatedCurrentStats = this.state.stats.map(stat => {
      if (stat.account.accountId === accountId) {
        const newObj = { ...stat };
        if (!newObj.account.googleAnalytics) {
          newObj.account.googleAnalytics = {
            googleAnalytics: {},
          };
        }
        newObj.account.googleAnalyticsn = {
          ...newObj.account.googleAnalytics,
          ...data,
        };
        return newObj;
      }
      return stat;
    });
    this.setState({
      _stats: updatedStats,
      stats: updatedCurrentStats,
    });
  }

  onGa4Update(accountId, data) {
    const updatedStats = this.state._stats.map(stat => {
      if (stat.account.accountId === accountId) {
        const newObj = { ...stat };
        if (!newObj.account.ga4) {
          newObj.account.ga4 = {
            ga4: {},
          };
        }
        newObj.account.ga4 = {
          ...newObj.account.ga4,
          ...data,
        };
        return newObj;
      }
      return stat;
    });

    const updatedCurrentStats = this.state.stats.map(stat => {
      if (stat.account.accountId === accountId) {
        const newObj = { ...stat };
        if (!newObj.account.ga4) {
          newObj.account.ga4 = {
            ga4: {},
          };
        }
        newObj.account.ga4 = {
          ...newObj.account.ga4,
          ...data,
        };
        return newObj;
      }
      return stat;
    });
    this.setState({
      _stats: updatedStats,
      stats: updatedCurrentStats,
    });
  }

  updateStats(e) {
    const code = e.keyCode ? e.keyCode : e.which;

    if (code === 13) {
      this.setState(
        {
          searchTriggered: true,
        },
        () => {
          filterActions.updateFilters();
        },
      );
    }
  }

  onSelectedModuleChange(module) {
    this.setState({
      selectedModule: module,
      accountsWithWarning:
        module === 'other'
          ? {}
          : this.getAccountsWithWarning(this.state.stats, module),
    });
  }

  resetSearch() {
    if (this.state.searched) {
      const searchInput = this.refs.accountSearch.querySelector(
        '[name=accountId]',
      );
      searchInput.value = '';
      filterActions.updateFilters();
    }
  }

  hasKlaviyoConfiguration(account) {
    let hasMailConfig = false;
    const mailConfig = account.mailConfiguration;
    if (mailConfig) {
      hasMailConfig = account.mailConfiguration.provider === 'KLAVIYO';
    }
    return hasMailConfig;
  }

  render() {
    const name = 'pushSubscription:';
    let newName = '';
    let niceName = '';
    const { accountsWithWarning } = this.state;
    let containerClass =
      this.state.stats.length <= 10 ? 'accounts-vertical-result' : '';
    containerClass += ' admin-stats admin-stats-page';

    return (
      <div className={containerClass}>
        <ReactTooltip effect='solid' className='sg-tooltip' />
        <div
          className='account-filters account-filters-stats'
          ref='pageFilters'
        >
          <Filters
            defaultDateYesterday={false}
            visibleUpdate={false}
            showYesterday
          />
        </div>

        <h2 className='page-title'>{t('Account Statistics')}</h2>

        <div className='page-options page-options-admin-stats'>
          <label className='item is-stacked is-search' ref='accountSearch'>
            <span
              onClick={this.resetSearch}
              style={{ cursor: this.state.searched ? 'pointer' : 'initial' }}
            >
              <Icon name={this.state.searched ? 'cross' : 'magnify'} />
            </span>
            <input
              type='text'
              placeholder={t('Account ID or Domain')}
              className='text-field'
              name='accountId'
              onKeyPress={this.updateStats}
            />
          </label>
        </div>

        <AccountFilter filterAccounts={this.filterAccounts} />
        <div className='vertical-content account-statistics'>
          <ul className='stats-modules'>
            <li
              onClick={this.onSelectedModuleChange.bind(this, 'recommendation')}
              className={classNames({
                'active-module': this.state.selectedModule === 'recommendation',
              })}
            >
              Recommendation
            </li>
            <li
              onClick={this.onSelectedModuleChange.bind(this, 'email')}
              className={classNames({
                'active-module': this.state.selectedModule === 'email',
              })}
            >
              Email Marketing
            </li>
            <li
              onClick={this.onSelectedModuleChange.bind(this, 'push')}
              className={classNames({
                'active-module': this.state.selectedModule === 'push',
              })}
            >
              Web Push
            </li>
            <li
              onClick={this.onSelectedModuleChange.bind(this, 'search')}
              className={classNames({
                'active-module': this.state.selectedModule === 'search',
              })}
            >
              Search & Discovery
            </li>
            <li
              onClick={this.onSelectedModuleChange.bind(this, 'other')}
              className={classNames({
                'active-module': this.state.selectedModule === 'other',
              })}
            >
              Other
            </li>
          </ul>
          <div className='account-details account-last-child'>
            <table className='data-table'>
              <thead>
                <tr>
                  {statsTable[`${this.state.selectedModule}Header`].map(
                    (item, index) => {
                      let { text } = item;
                      const alignment = item.alignment || 'center';
                      const className = classNames('', item.className, {
                        'sort-up':
                          this.state.reverse === true &&
                          this.state.sorting === item.sort,
                        'sort-down':
                          this.state.reverse === false &&
                          this.state.sorting === item.sort,
                      });

                      if (item.icon) {
                        text = <Icon name={item.icon} />;
                      }

                      if (item.sortable) {
                        text = (
                          <a
                            className='sortable'
                            onClick={this.sort.bind(null, item.sort)}
                          >
                            {t(text)}
                          </a>
                        );
                      }

                      if (item.tooltip) {
                        text = (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <span data-tip={item.tooltip}>{text}</span>
                          </div>
                        );
                      }

                      return (
                        <th key={index} className={className}>
                          {t(text)}
                        </th>
                      );
                    },
                  )}
                </tr>
              </thead>
              <tbody>
                {this.state.stats
                  .filter(_stat => {
                    if (this.state.selectedModule === 'other') {
                      return (
                        _stat.account &&
                        _stat.account.features.recommendation &&
                        _stat.account.features.recommendation.status ===
                          'PASSIVE' &&
                        _stat.account.features.email &&
                        _stat.account.features.email.status === 'PASSIVE' &&
                        _stat.account.features.push &&
                        _stat.account.features.push.status === 'PASSIVE' &&
                        _stat.account.features.search &&
                        _stat.account.features.search.status === 'PASSIVE'
                      );
                    }
                    return (
                      _stat.account &&
                      _stat.account.features[this.state.selectedModule] &&
                      (_stat.account.features[this.state.selectedModule]
                        .status === 'ACTIVE' ||
                        _stat.account.features[this.state.selectedModule]
                          .status === 'POC')
                    );
                  })
                  .filter(
                    statsObj =>
                      statsObj.account && statsObj && statsObj.users[0],
                  )
                  .map((statsObj, index) => {
                    const { account } = statsObj;
                    const accountId = account ? account.accountId : false;
                    let isWarning = false;
                    let isDanger = false;
                    const hasRecommendation =
                      account &&
                      account.features &&
                      account.features.recommendation &&
                      (account.features.recommendation.status === 'ACTIVE' ||
                        account.features.recommendation.status === 'POC');
                    let contRatio;
                    let numericRatio;
                    let numericPrevRatio;
                    let diff;
                    const klaviyoAccount = this.hasKlaviyoConfiguration(
                      account,
                    );

                    if (this.state.selectedModule === 'recommendation') {
                      contRatio = `${calculateRatio(
                        statsObj.stats.sgfAmount,
                        statsObj.stats.allAmount,
                      )}%`;

                      numericRatio = numericCalculateRatio(
                        statsObj.stats.sgfAmount,
                        statsObj.stats.allAmount,
                      );
                      numericPrevRatio = numericCalculateRatio(
                        statsObj.stats.sgfAmountPrev,
                        statsObj.stats.allAmountPrev,
                      );

                      diff = diffRatio(numericRatio, numericPrevRatio);
                    } else if (this.state.selectedModule === 'email') {
                      contRatio = `${calculateRatio(
                        statsObj.stats.mailAmount,
                        statsObj.stats.allAmount,
                      )}%`;

                      numericRatio = numericCalculateRatio(
                        statsObj.stats.mailAmount,
                        statsObj.stats.allAmount,
                      );
                      numericPrevRatio = numericCalculateRatio(
                        statsObj.stats.mailAmountPrev,
                        statsObj.stats.allAmountPrev,
                      );

                      diff = diffRatio(numericRatio, numericPrevRatio);
                    } else if (this.state.selectedModule === 'push') {
                      contRatio = `${calculateRatio(
                        statsObj.stats.pushAmount,
                        statsObj.stats.allAmount,
                      )}%`;

                      numericRatio = numericCalculateRatio(
                        statsObj.stats.pushAmount,
                        statsObj.stats.allAmount,
                      );
                      numericPrevRatio = numericCalculateRatio(
                        statsObj.stats.pushAmountPrev,
                        statsObj.stats.allAmountPrev,
                      );

                      diff = diffRatio(numericRatio, numericPrevRatio);
                    } else {
                      contRatio = `${calculateRatio(
                        statsObj.stats.searchAmount,
                        statsObj.stats.allAmount,
                      )}%`;

                      numericRatio = numericCalculateRatio(
                        statsObj.stats.searchAmount,
                        statsObj.stats.allAmount,
                      );
                      numericPrevRatio = numericCalculateRatio(
                        statsObj.stats.searchAmountPrev,
                        statsObj.stats.allAmountPrev,
                      );

                      diff = diffRatio(numericRatio, numericPrevRatio);
                    }

                    if (statsObj.stats.allAmount === 0) {
                      contRatio = '-';
                    }
                    if (this.state.selectedModule === 'other') {
                      contRatio = 'N/A';
                    }
                    isWarning =
                      accountId &&
                      accountsWithWarning[accountId] &&
                      accountsWithWarning[accountId].warningType === 'CRITIC' &&
                      hasRecommendation;
                    isDanger =
                      accountId &&
                      accountsWithWarning[accountId] &&
                      accountsWithWarning[accountId].warningType === 'DANGER' &&
                      hasRecommendation;
                    return (
                      <tr
                        key={index}
                        className={classNames('accounts-row', {
                          'accounts-row-warning': isWarning,
                          'accounts-row-danger': isDanger,
                        })}
                      >
                        <td>
                          {index + 1}
                          {(isWarning || isDanger) && (
                            <Tooltip
                              content={isWarning ? 'CRITIC' : 'DANGER'}
                              alignment='right'
                              newTooltip
                            >
                              <span
                                className={classNames('account-type', {
                                  'account-type-warning': isWarning,
                                  'account-type-danger': isDanger,
                                })}
                              />
                            </Tooltip>
                          )}
                        </td>
                        <td
                          onClick={this.switchToUser.bind(
                            null,
                            statsObj.users,
                            statsObj.account,
                          )}
                          className='domain'
                        >
                          {statsObj.account.domain}
                        </td>
                        <td>{statsObj.account.accountId}</td>
                        <td className='cell-group'>
                          {localeString(statsObj.stats.pageAll)}
                        </td>
                        {this.state.selectedModule === 'recommendation' && (
                          <>
                            <td>{localeString(statsObj.stats.widgetView)}</td>
                            <td>{localeString(statsObj.stats.impression)}</td>
                            <td>
                              {calculateRatio(
                                statsObj.stats.widgetView,
                                statsObj.stats.impression,
                              )}
                              %
                            </td>
                            <td>{localeString(statsObj.stats.click)}</td>
                            <td>
                              {calculateRatio(
                                statsObj.stats.click,
                                statsObj.stats.widgetView,
                              )}
                              %
                            </td>
                            <td className='cell-group'>
                              {statsObj.stats.sgfPurchases}
                            </td>
                            <td>{localeString(statsObj.stats.allPurchases)}</td>
                            <td>
                              {calculateRatio(
                                statsObj.stats.sgfPurchases,
                                statsObj.stats.allPurchases,
                              )}
                              %
                            </td>
                            <td className='cell-group'>
                              {localeString(statsObj.stats.sgfItems)}
                            </td>
                            <td>{localeString(statsObj.stats.allItems)}</td>
                            <td>
                              {calculateRatio(
                                statsObj.stats.sgfItems,
                                statsObj.stats.allItems,
                              )}
                              %
                            </td>
                            <td className='cell-group'>
                              <Currency
                                currency={statsObj.stats.sgfAmount}
                                accountCurrency={account.mainCurrency}
                              />
                            </td>
                            <td>
                              <Currency
                                currency={statsObj.stats.allAmount}
                                accountCurrency={account.mainCurrency}
                              />
                            </td>
                            <td className='cont-ratio'>{contRatio}</td>
                            <td className='cont-diff'>
                              <span style={{ color: '#f0000' }}>
                                <span>{diff}</span>
                                {numericRatio > numericPrevRatio && (
                                  <span style={{ color: 'rgb(86, 188, 148)' }}>
                                    ▲
                                  </span>
                                )}

                                {numericRatio < numericPrevRatio && (
                                  <span style={{ color: '#cc4331' }}>▼</span>
                                )}
                              </span>
                            </td>
                          </>
                        )}
                        {this.state.selectedModule === 'email' && (
                          <>
                            <td>
                              {localeString(
                                klaviyoAccount ? '-' : statsObj.stats.mailSent,
                              )}
                            </td>
                            <td>
                              {localeString(
                                klaviyoAccount
                                  ? '-'
                                  : statsObj.stats.mailOpened,
                              )}
                            </td>
                            <td>{localeString(statsObj.stats.mailClicked)}</td>
                            <td>
                              {klaviyoAccount
                                ? '-'
                                : calculateRatio(
                                    statsObj.stats.mailOpened,
                                    statsObj.stats.mailSent,
                                  )}
                              {klaviyoAccount ? '' : '%'}
                            </td>
                            <td>
                              {klaviyoAccount
                                ? '-'
                                : calculateRatio(
                                    statsObj.stats.mailClicked,
                                    statsObj.stats.mailSent,
                                  )}
                              {klaviyoAccount ? '' : '%'}
                            </td>
                            <td className='cell-group'>
                              {statsObj.stats.mailPurchases}
                            </td>
                            <td>{localeString(statsObj.stats.allPurchases)}</td>
                            <td>
                              {calculateRatio(
                                statsObj.stats.mailPurchases,
                                statsObj.stats.allPurchases,
                              )}
                              %
                            </td>
                            <td>{localeString(statsObj.stats.mailItems)}</td>
                            <td>{localeString(statsObj.stats.allItems)}</td>
                            <td>
                              {calculateRatio(
                                statsObj.stats.mailItems,
                                statsObj.stats.allItems,
                              )}
                              %
                            </td>
                            <td className='cell-group'>
                              <Currency
                                currency={statsObj.stats.mailAmount}
                                accountCurrency={account.mainCurrency}
                              />
                            </td>
                            <td>
                              <Currency
                                currency={statsObj.stats.allAmount}
                                accountCurrency={account.mainCurrency}
                              />
                            </td>
                            <td className='cont-ratio'>{contRatio}</td>
                            <td className='cont-diff'>
                              <span style={{ color: '#f0000' }}>
                                <span>{diff}</span>
                                {numericRatio > numericPrevRatio && (
                                  <span style={{ color: 'rgb(86, 188, 148)' }}>
                                    ▲
                                  </span>
                                )}

                                {numericRatio < numericPrevRatio && (
                                  <span style={{ color: '#cc4331' }}>▼</span>
                                )}
                              </span>
                            </td>
                            <td>
                              {localeString(
                                calculateProportion(
                                  statsObj.stats.mailLTVGeneralRevenue,
                                  statsObj.stats.mailLTVGeneralSent,
                                ),
                              )}
                            </td>
                            <td>
                              {localeString(statsObj.stats.totalMailAddress)}
                            </td>
                            <td>
                              {localeString(
                                statsObj.stats.reachableMailUsersCount,
                              )}
                            </td>
                            <td>
                              {localeString(statsObj.stats.mailTotalCampaign)}
                            </td>
                          </>
                        )}
                        {this.state.selectedModule === 'push' && (
                          <>
                            <td>
                              <Tooltip
                                alignment='left'
                                newTooltip
                                content={platforms.map(device => (
                                  <div key={device.toLowerCase()}>
                                    <p
                                      style={{
                                        textDecoration: 'underline',
                                        margin: 0,
                                      }}
                                    >
                                      {device} :
                                    </p>
                                    {device !== 'APPS' &&
                                      Object.keys(browserSlugs)
                                        .filter(
                                          browser =>
                                            !(
                                              (browser === 'Samsung' &&
                                                device === 'PC') ||
                                              (browser === 'Safari' &&
                                                device === 'MOBILE')
                                            ),
                                        )
                                        .map(browser => {
                                          newName = `${name +
                                            browser}:${device}`;
                                          niceName = browser;
                                          return (
                                            <div key={browser}>
                                              {niceName} :{' '}
                                              {statsObj.stats[newName]}
                                            </div>
                                          );
                                        })}

                                    {device === 'APPS' &&
                                      Object.keys(apps).map(app => {
                                        newName = name + apps[app];
                                        niceName = apps[app];
                                        return (
                                          <div>
                                            {niceName}:{statsObj.stats[newName]}
                                          </div>
                                        );
                                      })}
                                  </div>
                                ))}
                              >
                                {localeString(statsObj.stats.totalSubValue)}
                              </Tooltip>
                            </td>
                            <td>{localeString(statsObj.stats.pushSent)}</td>
                            <td>{localeString(statsObj.stats.pushShown)}</td>
                            <td>{localeString(statsObj.stats.pushClicked)}</td>
                            <td>
                              {calculateRatio(
                                statsObj.stats.pushShown,
                                statsObj.stats.pushSent,
                              )}
                              %
                            </td>
                            <td>
                              {calculateRatio(
                                statsObj.stats.pushClicked,
                                statsObj.stats.pushSent,
                              )}
                              %
                            </td>
                            <td className='cell-group'>
                              {statsObj.stats.pushPurchases}
                            </td>
                            <td>{localeString(statsObj.stats.allPurchases)}</td>
                            <td>
                              {calculateRatio(
                                statsObj.stats.pushPurchases,
                                statsObj.stats.allPurchases,
                              )}
                              %
                            </td>
                            <td>{localeString(statsObj.stats.pushItems)}</td>
                            <td>{localeString(statsObj.stats.allItems)}</td>
                            <td>
                              {calculateRatio(
                                statsObj.stats.pushItems,
                                statsObj.stats.allItems,
                              )}
                              %
                            </td>
                            <td className='cell-group'>
                              <Currency
                                currency={statsObj.stats.pushAmount}
                                accountCurrency={account.mainCurrency}
                              />
                            </td>
                            <td>
                              <Currency
                                currency={statsObj.stats.allAmount}
                                accountCurrency={account.mainCurrency}
                              />
                            </td>
                            <td className='cont-ratio'>{contRatio}</td>
                            <td className='cont-diff'>
                              <span style={{ color: '#f0000' }}>
                                <span>{diff}</span>
                                {numericRatio > numericPrevRatio && (
                                  <span style={{ color: 'rgb(86, 188, 148)' }}>
                                    ▲
                                  </span>
                                )}

                                {numericRatio < numericPrevRatio && (
                                  <span style={{ color: '#cc4331' }}>▼</span>
                                )}
                              </span>
                            </td>
                            <td>
                              <Currency
                                currency={calculateProportion(
                                  statsObj.stats.pushLTVGeneralRevenue,
                                  statsObj.stats.pushLTVGeneralSent,
                                )}
                                accountCurrency={account.mainCurrency}
                              />
                            </td>
                            <td>
                              <Currency
                                currency={calculateProportion(
                                  statsObj.stats.pushLTVBulkRevenue,
                                  statsObj.stats.pushLTVBulkSent,
                                )}
                                accountCurrency={account.mainCurrency}
                              />
                            </td>
                            <td>
                              <Currency
                                currency={calculateProportion(
                                  statsObj.stats.pushLTVPersonalizedRevenue,
                                  statsObj.stats.pushLTVPersonalizedSent,
                                )}
                                accountCurrency={account.mainCurrency}
                              />
                            </td>
                            <td>
                              <Currency
                                currency={calculateProportion(
                                  statsObj.stats.pushLTVRecommendationRevenue,
                                  statsObj.stats.pushLTVRecommendationSent,
                                )}
                                accountCurrency={account.mainCurrency}
                              />
                            </td>
                            <td>
                              {localeString(statsObj.stats.pushTotalCampaign)}
                            </td>
                          </>
                        )}
                        {this.state.selectedModule === 'other' && (
                          <>
                            <td>{localeString(statsObj.stats.allPurchases)}</td>
                            <td>{localeString(statsObj.stats.allItems)}</td>
                            <td>
                              <Currency
                                currency={statsObj.stats.allAmount}
                                accountCurrency={account.mainCurrency}
                              />
                            </td>
                          </>
                        )}
                        {this.state.selectedModule === 'search' && (
                          <>
                            <td> {localeString(statsObj.stats.facetViews)}</td>
                            <td>
                              {localeString(statsObj.stats.searchWidgetView)}
                            </td>
                            <td>{localeString(statsObj.stats.searchClick)}</td>
                            <td>
                              {calculateRatio(
                                statsObj.stats.searchClick,
                                statsObj.stats.searchWidgetView,
                              )}
                              %
                            </td>
                            <td className='cell-group'>
                              {statsObj.stats.searchPurchases}
                            </td>
                            <td>{localeString(statsObj.stats.allPurchases)}</td>
                            <td>
                              {calculateRatio(
                                statsObj.stats.searchPurchases,
                                statsObj.stats.allPurchases,
                              )}
                              %
                            </td>

                            <td className='cell-group'>
                              {localeString(statsObj.stats.searchItems)}
                            </td>
                            <td> {localeString(statsObj.stats.allItems)}</td>
                            <td>
                              {calculateRatio(
                                statsObj.stats.searchItems,
                                statsObj.stats.allItems,
                              )}
                              %
                            </td>
                            <td className='cell-group'>
                              <Currency
                                currency={statsObj.stats.searchAmount}
                                accountCurrency={account.mainCurrency}
                              />
                            </td>
                            <td>
                              <Currency
                                currency={statsObj.stats.allAmount}
                                accountCurrency={account.mainCurrency}
                              />
                            </td>
                            <td className='cont-ratio'> {contRatio} </td>
                            <td className='cont-diff'>
                              <span style={{ color: '#f0000' }}>
                                <span> {diff} </span>
                                {numericRatio > numericPrevRatio && (
                                  <span style={{ color: 'rgb(86, 188, 148)' }}>
                                    ▲
                                  </span>
                                )}
                                {numericRatio < numericPrevRatio && (
                                  <span style={{ color: '#cc4331' }}>▼</span>
                                )}
                              </span>
                            </td>
                          </>
                        )}
                        <td className='cell-group'>
                          {localeString(statsObj.stats.productCount)}
                        </td>
                        <td className='cell-group'>
                          {localeString(statsObj.stats.userCount)}
                        </td>
                        <td>
                          <AdminActions
                            account={statsObj.account}
                            onDetail={this.showCampaignStats.bind(
                              null,
                              statsObj.account,
                              statsObj.users[0],
                            )}
                            showDetails={statsObj.users && statsObj.users[0]}
                            hiddenFields={
                              this.state.selectedModule === 'other'
                                ? ['create', 'archive', 'campaignStats']
                                : ['create', 'archive']
                            }
                            onUpdate={this.updateAccount}
                            onPushUpdate={this.onPushUpdate}
                            onGa4Update={this.onGa4Update}
                            features={statsObj.account.features}
                            user={statsObj.users[0]}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

Admin_Stats.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = store => ({
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  update: store.filters.update,
  reset: store.filters.reset,
});

export default connect(mapStateToProps)(Admin_Stats);
