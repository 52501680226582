import React from 'react';
import Fields from 'modules/campaign/fields';
import {
  initialState,
} from 'constants/datamaps/palantir';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { algorithmDescriptions } from 'constants/datamaps/wizard';
import { maxSynonymDefinition } from 'modules/campaign/fields/searchSynonyms';
import _forEach from 'lodash/forEach';
import { synonymsActions } from 'actions';
import { isSwitchAccount } from '../../../modules/auth/user';

class SynonymSettings extends React.Component {
  constructor(props) {
    super(props);
    this.setSynonymOfAccount = this.setSynonymOfAccount.bind(this);
    this.state = {
      flag: true,
    };
  }

  componentDidMount() {
    this.setSynonymOfAccount(this.props.campaign.synonyms);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.campaign.synonyms) !==
      JSON.stringify(this.props.campaign.synonyms)
    ) {
      this.setSynonymOfAccount(this.props.campaign.synonyms);
    }
  }

  setSynonymOfAccount(synonyms) {
    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;
    const { mainLanguage } = account;
    const additionalLanguages = account.additionalLanguages || [];
    const accountLanguages = additionalLanguages.concat(mainLanguage);

    if (synonyms && Object.keys(synonyms).length > 0) {
      // yeni gelistirmeler ---------
      const tempSynonymForStore = {};
      accountLanguages.forEach(lang => {
        tempSynonymForStore[lang] = {};

        //TODO only MAX 300 synonyms.. if account has more than 300 synonym campaign update request will not work properly
        const slicedSynonyms =
          synonyms[lang] && synonyms[lang].length > 0
            ? synonyms[lang].slice(0, maxSynonymDefinition)
            : [];
        _forEach(slicedSynonyms, item => {
          const operator = Object.values(algorithmDescriptions()).find(algorithm => algorithm.operatorKey === item.operator ).operatorUIKey;
          const tempSynonym = {
            id: Math.random(),
            leftValue: {
              label: item.leftValue,
              value: item.leftValue,
            },
            rightValues: item.rightValues.map(rightValue => ({
              label: rightValue,
              value: rightValue,
            })),
          };
          if (!tempSynonymForStore[lang][operator]) {
            tempSynonymForStore[lang][operator] = [];
          }
          tempSynonymForStore[lang][operator].push(tempSynonym);
        });
      });
      synonymsActions.addAllSynonyms(tempSynonymForStore);
    }
  }

  getUpdatedCampaign = () => {
    const synonyms = {};
    for (const lang in this.props.synonymItems) {
      if (!synonyms.hasOwnProperty(lang)) {
        synonyms[lang] = [];
      }
      for (const operator in this.props.synonymItems[lang]) {
        this.props.synonymItems[lang][operator].forEach(item => {
          synonyms[lang].push({
            leftValue: item.leftValue.value,
            rightValues: item.rightValues.map(rVal => {
              return rVal.value;
            }),
            operator: algorithmDescriptions()[operator].operatorKey,
          });
        });
      }
    }
    return {
      synonyms,
    };
  };

  render() {
    return (
      <ol className='form-elements'>
        <li>
          <div className='widget'>
            <Fields
              ref='synonymCompRef'
              campaign={{}}
              comps={initialState.synonymField}
              isEdit
            />
          </div>
        </li>
      </ol>
    );
  }
}

SynonymSettings.propTypes = {
  synonymItems: PropTypes.objectOf(PropTypes.object).isRequired,
  switchedUser: PropTypes.objectOf(PropTypes.object).isRequired,
  user: PropTypes.objectOf(PropTypes.object).isRequired,
};

const MapStatesToProps = store => ({
  switchedUser: store.switchedUser.switchedUser,
  user: store.user.user,
  synonymItems: store.synonyms.synonymItems,
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  SynonymSettings,
);
