import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const EmailOrderFollow = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 19 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 3V5H1.5C0.67157 5 0 5.67157 0 6.5V16C0 18.2091 1.79086 20 4 20H8.8096C8.3832 19.5557 8.0194 19.051 7.7322 18.5H4C2.61929 18.5 1.5 17.3807 1.5 16V6.5H9V10.8096C9.4443 10.3832 9.949 10.0194 10.5 9.7322V6.5H14.5V9.0764C15.0232 9.1572 15.5258 9.3004 16 9.4982V6.5C16 5.67157 15.3284 5 14.5 5H13V3C13 1.34315 11.6569 0 10 0C9.2317 0 8.5308 0.28885 8 0.7639C7.4692 0.28886 6.7684 0 6 0C4.34315 0 3 1.34315 3 3ZM6 1.5C6.8284 1.5 7.5 2.17157 7.5 3V5H4.5V3C4.5 2.17157 5.17157 1.5 6 1.5ZM11.5 5H9V3C9 2.66563 8.9453 2.34403 8.8444 2.04368C9.1195 1.71156 9.535 1.5 10 1.5C10.8284 1.5 11.5 2.17157 11.5 3V5ZM19 15.5C19 18.5377 16.5376 21 13.5 21C10.4624 21 8 18.5377 8 15.5C8 12.4623 10.4624 10 13.5 10C16.5376 10 19 12.4623 19 15.5ZM12.9134 13.097C12.8181 13.0323 12.7124 13.0001 12.5964 13.0001C12.5156 13.0001 12.4389 13.0174 12.3664 13.0517C12.2939 13.0842 12.2307 13.1284 12.1768 13.1845C12.1229 13.2406 12.0795 13.3062 12.0463 13.3817C12.0152 13.4572 11.9997 13.5369 11.9997 13.621V17.379C11.9997 17.461 12.0152 17.5398 12.0463 17.6153C12.0795 17.6905 12.1229 17.7574 12.1768 17.8158C12.2328 17.8716 12.297 17.917 12.3695 17.9516C12.442 17.9838 12.5177 17.9999 12.5964 17.9999C12.6565 17.9999 12.7124 17.9913 12.7642 17.9743C12.7792 17.9695 12.7941 17.9641 12.809 17.9579C12.8475 17.9421 12.8853 17.9215 12.9228 17.8967L15.7293 15.9819C15.7924 15.9401 15.8453 15.8879 15.8878 15.8247C15.9025 15.8029 15.9159 15.7799 15.9282 15.7554C15.9759 15.6605 15.9997 15.5615 15.9997 15.4579C15.9997 15.3502 15.9748 15.2511 15.9251 15.1604C15.8754 15.0676 15.807 14.9933 15.7199 14.9372L12.9134 13.097Z'
        fill='#5D5D61'
      />
    </svg>
  );
};

EmailOrderFollow.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

EmailOrderFollow.defaultProps = {
  width: '19px',
  height: '21px',
};

export default EmailOrderFollow;
