/**
 * @author Bilal Cinarli
 */

import { createAction } from '../system/store';

export const openModal = options => {
  let defaults = {
    title: '',
    content: '',
    className: '',
  };

  let modal = Object.assign({}, defaults, options);

  createAction('OPEN_MODAL', modal);
};

export const closeModal = () => createAction('CLOSE_MODAL');

export const calculateModalHeight = () =>
  createAction('CALCULATE_MODAL_HEIGHT');

export const cancelCalculation = () => createAction('CANCEL_CALCULATION');

export const isProperEmail = value => {
  let urlregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return urlregex.test(value);
};

export default {
  openModal,
  closeModal,
  calculateModalHeight,
  cancelCalculation,
  isProperEmail,
};
