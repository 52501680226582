import React from 'react';
import { useRanger } from 'react-ranger';
import './PercentageRangeIndicator.scss';

const PercentageRangeIndicator = ({ values, setValues, variations }) => {
  const { getTrackProps, segments, handles } = useRanger({
    min: 0,
    max: 100,
    stepSize: 1,
    values,
    onChange: setValues,
    onDrag: setValues,
  });

  return (
    <div className='sfy-ranger'>
      <p>Move slider to optimize test split</p>
      <div className='track' {...getTrackProps()}>
        {segments.map(({ getSegmentProps }, index) => {
          let segmentProps = { ...getSegmentProps() };
          const style = { ...(segmentProps.style ?? {}) };
          style.background = variations[index].color;
          segmentProps = { ...segmentProps, style };
          return (
            <div className='segment' {...segmentProps}>
              {`${variations[index].testSplit ?? 0}%`}
            </div>
          );
        })}
        {handles.map(({ getHandleProps }) => (
          <span className='handle' {...getHandleProps()} />
        ))}
      </div>
    </div>
  );
};

export default PercentageRangeIndicator;
