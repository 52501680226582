import React, { Component } from 'react';
import { Select } from '../fields';

const mailGroups = [
  'ABANDONED_CART',
  'LAST_VISIT_ALTERNATIVES',
  'LAST_VISIT_REMINDER',
  'NICE_TO_MEET_YOU',
  'NEW_COMERS',
  'TOP_SELLERS',
  'ORDER_FOLLOW_UP',
  'BIRTHDAY',
  'ANNIVERSARY',
  'WE_MISSED_YOU',
  'CHURN',
  'PRICE_DROP',
  'FLY',
  'BACK_IN_STOCK',
];

class MailCapping extends Component {
  constructor(props) {
    super(props);

    this.onCappingParamChange = this.onCappingParamChange.bind(this);
    this.onFrequencyChange = this.onFrequencyChange.bind(this);
  }

  onCappingParamChange(param, e) {
    this.props.onCampaignCappingParamChange(
      'capCount',
      parseInt(e.target.value) < 1 ? 1 : e.target.value,
    );
  }

  onFrequencyChange(selectedOption) {
    this.props.onCampaignCappingParamChange(
      'frequency',
      selectedOption ? selectedOption.value : '',
    );
  }

  render() {
    const values = this.props.campaignCapping;
    const frequency = [
      { value: 'DAILY', label: 'Daily' },
      { value: 'WEEKLY', label: 'Weekly' },
      { value: 'MONTHLY', label: 'Monthly' },
    ];
    const campaigns = mailGroups.map(mailGroup => ({
      value: mailGroup,
      label: mailGroup,
    }));

    return (
      <li style={{ 'list-style': 'none' }}>
        <ol className='form-elements wizard-items'>
          <div
            className='wizard-comp'
            style={{ marginTop: '0', paddingTop: '0' }}
          >
            <ol className='form-elements'>
              <div className='wizard-cards'>
                <div className='email-settings-capping wizard'>
                  <div
                    className='wizard-comp'
                    style={{ paddingBottom: '20px' }}
                  >
                    <h3
                      className='wizard-comp-title item-title-sub item-title-sub__rec'
                      style={{ fontWeight: 600 }}
                    >
                      Capping
                    </h3>
                    <p className='wizard-comp-description'>
                      Select from these filters to specify limit how often{' '}
                      {this.props.scenarioName} campaign send to customers.
                    </p>
                    {this.props.errorText && (
                      <span
                        className='item-error has-error'
                        style={{ display: 'block', marginTop: '10px' }}
                      >
                        {'*'}
                        {this.props.errorText}
                      </span>
                    )}
                    <div style={{ marginTop: '10px' }}>
                      <p>
                        Frequency Cap is
                        <input
                          type='number'
                          name='capCount'
                          value={values.capCount}
                          className='capping-capCount'
                          onChange={this.onCappingParamChange.bind(
                            null,
                            'capCount',
                          )}
                        />
                        per user and {this.props.isPush ? 'push' : 'email'} is
                        sent
                        <Select
                          name='frequency'
                          className='item-stacked eleven-columns capping-frequency'
                          value={values.frequency}
                          options={frequency}
                          onChange={this.onFrequencyChange}
                          clearable
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ol>
          </div>
        </ol>
      </li>
    );
  }
}

export default MailCapping;
