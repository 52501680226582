/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import classNames from "classnames";

import { t } from "../../../system/ui";
import {
  getNow,
  reformatDate,
  isValidStartDate,
  isValidEndDate,
  isBeforeNow,
  isValidCampaignEndDate
} from "../../../system/date";

import { uiActions } from "../../../actions";

import { Calendar } from "../../../components/fields";

class StartEndDate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        startDate: getNow().valueOf(),
        endDate: ""
      },
      startDate: getNow(),
      endDate: false,
      showEndDate: false
    };

    this.endDateChange = this.endDateChange.bind(this);
    this.startDateChange = this.startDateChange.bind(this);

    this.toggleEndDate = this.toggleEndDate.bind(this);

    if (this.props.options && this.props.options.updateWizardForm) {
      this.props.options.updateWizardForm(
        "startDate",
        this.state.values.startDate
      );
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.setState(
        {
          values: {
            startDate: moment(newProps.campaign.startDate).valueOf(),
            endDate: newProps.campaign.endDate
              ? moment(newProps.campaign.endDate).valueOf()
              : ""
          },
          startDate: moment(newProps.campaign.startDate),
          endDate: newProps.campaign.endDate
            ? moment(newProps.campaign.endDate)
            : false,
          showEndDate: !!newProps.campaign.endDate
        },
        () => {
          if (this.props.options && this.props.options.updateWizardForm) {
            this.props.options.updateWizardForm(
              "startDate",
              this.state.values.startDate
            );
            this.props.options.updateWizardForm(
              "endDate",
              this.state.values.endDate
            );
          }
          this.setEndDate();
        }
      );
    }
  }

  setEndDate() {
    let endDate,
      startDate = new Date(
        this.state.startDate.toDate().getTime() + 15 * 60000
      );
    if (this.state.endDate) {
      endDate = isValidEndDate(this.state.endDate, startDate)
        ? this.state.endDate
        : moment(startDate);
    } else {
      endDate = moment(startDate);
    }
    this.setState(
      {
        endDate: endDate
      },
      () => {
        if (this.props.options && this.props.options.updateWizardForm) {
          this.props.options.updateWizardForm(
            "endDate",
            this.state.values.endDate
          );
        }
      }
    );
  }

  startDateChange(datetime) {
    let values = this.state.values;
    values.startDate = datetime.valueOf();
    this.setState(
      {
        values,
        startDate: datetime
      },
      () => {
        if (this.props.options && this.props.options.updateWizardForm) {
          this.props.options.updateWizardForm(
            "endDate",
            this.state.values.startDate
          );
        }
      }
    );
    uiActions.formEdited();
  }

  endDateChange(datetime) {
    let values = this.state.values;
    values.endDate = datetime.valueOf();
    this.setState(
      {
        values,
        endDate: datetime
      },
      () => {
        if (this.props.options && this.props.options.updateWizardForm) {
          this.props.options.updateWizardForm(
            "endDate",
            this.state.values.endDate
          );
        }
      }
    );
    uiActions.formEdited();
  }

  toggleEndDate() {
    let values = this.state.values;
    values.endDate = this.state.endDate;
    this.setState({
      values,
      showEndDate: !this.state.showEndDate
    });
  }

  render() {
    return (
      <ol className="form-elements wizard-date-selection">
        <li>
          <Calendar
            label="Start Date - Time"
            value={reformatDate(this.state.startDate)}
            isValidDate={isValidStartDate}
            onChange={this.startDateChange}
            className="item-stacked one-whole"
          />
        </li>
        <li>
          <label className="item item-stacked is-checkbox">
            <input
              type="checkbox"
              checked={this.state.showEndDate}
              onChange={this.toggleEndDate}
            />
            <span className="item-label">{t("End Date - Time")}</span>
          </label>
          <Calendar
            className={classNames("item item-stacked one-whole", {
              "is-hidden": !this.state.showEndDate
            })}
            value={reformatDate(this.state.endDate)}
            isValidDate={current => {
              return isValidCampaignEndDate(current, this.state.startDate);
            }}
            onChange={this.endDateChange}
            ref="endDate"
          />
          {this.state.showEndDate &&
            this.state.endDate &&
            isBeforeNow(this.state.endDate) && (
              <span className="has-error">
                <span className="item-error">
                  {t("You must change the end date in order to save campaign.")}
                </span>
              </span>
            )}
        </li>
      </ol>
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  StartEndDate
);
