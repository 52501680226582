import React from 'react';
import PropTypes from 'prop-types';
import { CardWrapper } from 'components/search/faceted-search/common';
import classnames from 'classnames';
import { t } from '../../../../system/ui';
import { facetedSearchRequest } from '../../../../actions/faceted-search';
import { uiActions } from '../../../../actions';

export default class BoostFieldModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBoostFieldList: [],
      availableBoostFieldList: [],
      ruleSet: this.props.ruleSet,
      loading: true,
    };
  }

  componentDidMount() {
    const queryParams = `&instanceId=${this.props.instanceId}&type=${this.props.type}&isOrdering=false`;
    facetedSearchRequest('palantir/getFields', null, queryParams)
      .get()
      .then(fieldList => {
        this.setState({
          availableBoostFieldList: fieldList,
          loading: false,
        });
      }).catch = err => {
      const notificationContent = () => <span>{err.response}</span>;
      uiActions.showErrorNotification(notificationContent);
      this.setState(
        {
          loading: false,
        },
        () => {
          this.props.boostFieldToggle();
        },
      );
    };
  }

  onBoostFieldSelect = field => {
    const { selectedBoostFieldList } = this.state;
    const fieldIndex = selectedBoostFieldList.indexOf(field);
    if (fieldIndex === -1) {
      selectedBoostFieldList.push(field);
    } else {
      selectedBoostFieldList.splice(fieldIndex, 1);
    }
    this.setState({
      selectedBoostFieldList,
    });
  };

  render() {
    const { availableBoostFieldList, ruleSet } = this.state;
    const usedBoostFields = ruleSet.boostFields.map(
      bField => bField.productAccessor,
    );
    return (
      !this.state.loading && (
        <div className='add-facet-modal'>
          <CardWrapper
            title='Add Boosting'
            description='Add the boosting you want to promote rule on the search result pages'
            id='add-facet-modal-card'
            extraClasses='add-facet-modal-card-wrapper'
          >
            <>
              <div className='params-container'>
                {availableBoostFieldList
                  .map(field => {
                    const isUsed = this.state.selectedBoostFieldList.includes(
                      field,
                    );
                    const paramClass = classnames('param-wrapper', {
                      'param-wrapper-checked': isUsed,
                    });
                    const checkBoxClass = classnames('check-group-toggle', {
                      checked: isUsed,
                    });
                    return (
                      <div
                        key={field}
                        className={paramClass}
                        onClick={() => this.onBoostFieldSelect(field)}
                      >
                        <span>{field}</span>
                        <span className={checkBoxClass} />
                      </div>
                    );
                  })}
              </div>

              <div className='actions-container'>
                <button
                  type='button'
                  className='facet-action facet-action-add'
                  onClick={() =>
                    this.props.addSelectedBoostFields(
                      this.state.selectedBoostFieldList,
                    )
                  }
                >
                  {t('Add Selected')}
                </button>
                <button
                  type='button'
                  className='facet-action facet-action-close'
                  onClick={this.props.boostFieldToggle}
                >
                  {t('Close')}
                </button>
              </div>
            </>
          </CardWrapper>
        </div>
      )
    );
  }
}

BoostFieldModal.propTypes = {
  ruleSet: PropTypes.object.isRequired,
  instanceId: PropTypes.string.isRequired,
  boostFieldToggle: PropTypes.func.isRequired,
  addSelectedBoostFields: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
