/**
 * Created by mehmetyurtar on 10/08/2017.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// TODO - Causing defaultProps to be undefined, need to fix(react-highcharts version may cause).
import ReactHighcharts from 'react-highcharts';
import { t } from '../../system/ui';
import { localeString } from '../../system/string';

class ReportClickChart extends Component {
  constructor(props) {
    super(props);

    this.getConfig = this.getConfig.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.chartRenderCount) {
      return this.props.chartRenderCount !== nextProps.chartRenderCount;
    } else {
      return true;
    }
  }

  getConfig() {
    let config;
    config = {
      title: {
        text: '',
        margin: 0,
      },
      tooltip: {
        shared: true,
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 34560000,
        labels: {
          align: 'left',
        },
      },
      yAxis: [
        {
          title: {
            text: '',
          },
          min: 0,
          showFirstLabel: false,
          allowDecimals: false,
          labels: {},
        },
        {
          title: {
            text: t('Items'),
          },
          min: 0,
          showFirstLabel: false,
          allowDecimals: false,
          labels: {},
          opposite: true,
        },
      ],
      options: {
        chart: {
          type: 'spline',
          showAxes: true,
          zoomType: 'x',
          marginLeft: 75,
          spacingTop: 30,
          spacingBottom: 30,
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          borderRadius: 10,
          borderWidth: 3,
          backgroundColor: 'rgba(225, 225, 255, 0.9)',
        },
        exporting: {
          buttons: {
            contextButton: {
              y: -30,
              x: -25,
            },
          },
          chartOptions: {
            title: {
              text: 'Campaign Visitor Trends',
            },
          },
        },
      },
      loading: true,
      series: [],
    };
    let availableFields = [
      {
        name: 'Impression',
        label: t('Impression'),
        isVisible: false,
        type: 'line',
        yAxis: 0,
        zIndex: 2,
      },
      {
        name: 'Widget View',
        label: t('Widget View'),
        isVisible: true,
        type: 'line',
        yAxis: 0,
        zIndex: 2,
      },
      {
        name: 'Click',
        label: t('Click'),
        isVisible: true,
        type: 'line',
        yAxis: 0,
        zIndex: 2,
      },
      {
        name: 'Basket Items',
        label: t('Basket Items'),
        isVisible: true,
        type: 'column',
        yAxis: 1,
        zIndex: 1,
      },
      {
        name: 'Purchased Items',
        label: t('Purchased Items'),
        isVisible: true,
        type: 'column',
        yAxis: 1,
        zIndex: 1,
      },
    ];

    if (this.props.emailFields && this.props.emailFields.length) {
      availableFields = this.props.emailFields.concat(availableFields);
    }

    if (this.props.pushFields && this.props.pushFields.length) {
      availableFields = this.props.pushFields.concat(availableFields);
    }

    if (this.props.extraFields && this.props.extraFields.length) {
      this.props.extraFields.forEach(field => {
        if (
          field.name === 'Notification [sent]' ||
          field.name === 'Notification [shown]' ||
          field.name === 'Notification [clicked]'
        ) {
          availableFields.unshift(field);
        } else {
          availableFields.push(field);
        }
      });
    }

    availableFields.forEach(field => {
      if (this.props.fieldsToHide && this.props.fieldsToHide.length) {
        if (this.props.fieldsToHide.indexOf(field.name) === -1) {
          config.series.push({
            id: field.name,
            name: field.label || field.name,
            data: [],
            visible: field.isVisible,
            fillOpacity: 0.3,
            marker: {
              enabled: true,
              fillColor: '#FFFFFF',
              lineColor: null,
              lineWidth: 2,
            },
            type: field.type,
            yAxis: field.yAxis,
            zIndex: field.type === 'line' ? 2 : 1,
          });
        }
      } else {
        config.series.push({
          id: field.name,
          name: field.label,
          data: [],
          visible: field.isVisible,
          fillOpacity: 0.3,
          marker: {
            enabled: true,
            fillColor: '#FFFFFF',
            lineColor: null,
            lineWidth: 2,
          },
          type: field.type,
          yAxis: field.yAxis,
          zIndex: field.type === 'line' ? 2 : 1,
        });
      }
    });

    this.props.stats &&
      this.props.stats.forEach(stat => {
        if (typeof stat.x === 'undefined') {
          stat.x = stat.trend;
          stat.y = stat.time;
          stat.z = stat.count;
        }
        config.series.forEach(serie => {
          if (stat.x === serie.id) {
            serie.data.push({
              x: stat.y,
              y: stat.z,
            });
          }
        });
      });

    config.series.forEach(serie => {
      serie.data.sort(function(a, b) {
        var keyA = new Date(a.x),
          keyB = new Date(b.x);
        // Compare the 2 dates
        if (keyA < keyB) {
          return -1;
        }
        if (keyA > keyB) {
          return 1;
        }
        return 0;
      });
    });

    config.xAxis.tickInterval = '';
    let timeFrame = {};
    if (this.props.customFrame) {
      timeFrame.mode = this.props.customFrame;
    } else {
      if (this.props.range[1] && this.props.range[0]) {
        let timeDiff = this.props.range[1] - this.props.range[0];
        if (timeDiff < 24 * 60 * 60 * 1000) {
          timeFrame.mode = 'no_aggregate';
        } else if (timeDiff < 90 * 24 * 60 * 60 * 1000) {
          timeFrame.mode = 'daily';
        } else {
          timeFrame.mode = 'monthly';
        }
      }
    }
    switch (timeFrame.mode) {
      case 'monthly':
        config.xAxis.minTickInterval = 28 * 24 * 3600 * 1000; // 1 month
        config.xAxis.tickInterval = 30 * 24 * 3600 * 1000; // 1 month
        break;
      case 'daily':
        config.xAxis.tickInterval = 3 * 24 * 3600 * 1000; // three days
        break;
      case 'hourly':
        config.xAxis.tickInterval = 6 * 3600 * 1000; // 6 hours
        break;
      case 'no_aggregate':
        config.xAxis.tickInterval = 3600 * 1000; // one hour
        break;
      default:
        config.xAxis.tickInterval = 3 * 24 * 3600 * 1000;
    }
    config.credits = { enabled: false };
    let totals = {};
    config.series.forEach(serie => {
      totals[serie.name] = 0;
      serie.data.forEach(data => {
        totals[serie.name] += data.y;
      });
    });
    config.legend = {
      useHTML: true,
      labelFormatter: function() {
        return (
          '<div style="padding-top: 20px;"><div class="widget-stats"> <div class="widget-stat stat-widget"><h4 class="widget-stat-title">' +
          t(this.name) +
          '</h4><p class="widget-stat-count" style="color:' +
          this.color +
          ';text-align:center;">' +
          localeString(totals[this.name]) +
          '</p><p style="font-weight:normal;color:#bdbdbd">' +
          t('Total') +
          '</p></div></div></div>'
        );
      },
    };
    return config;
  }

  render() {
    return (
      <div className='report-chart'>
        <ReactHighcharts config={this.getConfig()} isPureConfig />
      </div>
    );
  }
}
const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  update: store.filters.update,
});

export default connect(mapStatesToProps)(ReportClickChart);
