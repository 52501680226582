/* eslint-disable react/jsx-indent */
/* eslint-disable no-param-reassign */
import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import cn from 'classnames';

import BadResponseCat from 'components/BadResponseCat';

import {
  uiActions,
  filterActions,
  trendifyActions,
  dateActions,
} from '../actions';
import TrendifyProductDetail from '../components/analytics/product.details';
import Currency from '../components/currency';
import Export from '../components/export';
import Filters from '../components/filters/filters';
import TrendifyFilters from '../components/filters/trendify-filters';
import Icon from '../components/icon';
import ScrollUpButtonAnalytics from '../components/scrollUpAnalytics';
import TableHeadings from '../components/table/heading';
import TotalsLine from '../components/table/totalsLine';
import Tooltip from '../components/tooltip';
import {
  trendifyTable,
  trendifyQuery,
  dummyDefaultTrendifyTotals,
} from '../constants/datamaps/analytics';
import { ExternalAppUtility } from '../external';
import {
  hasSegmentifyListing,
  hasTrendify,
  hasTrendifySources,
  getCustomTrendifyColumns,
  hasModulePrivilege,
} from '../modules/auth/user';
import { getBrands } from '../modules/account/ajax';
import {
  sort as gridSort,
  reset as gridReset,
} from '../modules/analytics/grid';
import transformAnalyticsSorting from '../modules/transform/analyticsTransforms';
import {
  getProducts,
  getProductCount,
  getProductTotal,
} from '../modules/trendify/ajax';
import { trendifyIntegrationInfo, productUrl } from '../modules/trendify/page';
import { dateUpdatedQuery, parseTotalLineAPI } from '../modules/trendify/utils';
import { queryDate, reformatDate, today } from '../system/date';
import { setTitle } from '../system/document';
import { clone } from '../system/object';
import { calculateRatio } from '../system/ratio';
import { localeString, decodeHtml } from '../system/string';
import { t } from '../system/ui';
import Icons from '../components/icons';

const dummyRow = {
  record: {
    productId: '',
    name: '',
    url: '',
    image: '',
    imageL: '',
    price: 35,
    categories: ['Shirt'],
    lastUpdateTime: 1522252593559,
    inStock: true,
    insertTime: 1518100741513,
    publishTime: 1518100741513,
    brand: '',
    labels: ['a'],
    params: {
      days_to_supply: '12',
      hours_to_supply: '6',
      supply: '24',
      supply2: '3',
    },
  },
  stats: {
    allSiteView: 8,
    pcSiteView: 8,
    allSitePurchase: 1,
    pcSitePurchase: 1,
    allSiteAmount: 110,
    pcSiteAmount: 110,
    allSgmClick: 2,
    pcSgmClick: 2,
    allInternalClick: 8,
    pcInternalClick: 8,
  },
};

const getStockValues = record => {
  if (record.stockCount && record.stockRatio) {
    return `${t('Count')}:${record.stockCount} - ${t('Ratio')}:${
      record.stockRatio
    }%`;
  }
  if (record.stockCount && !record.stockRatio) {
    return `${t('Count')}:${record.stockCount}`;
  }
  if (!record.stockCount && record.stockRatio) {
    return `${t('Ratio')}:${record.stockRatio}%`;
  }
  return false;
};

class Trendify extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      query: clone(trendifyQuery),
      sort: 'DESC',
      currentSorted: "stats->>'allSiteView'",
      device: 'all',
      showTrendifyFilters: false,
      showLoadingAnimation: true,
      showScrollToTop: false,
      maxHeight: 0,
      isTotalsLoading: false,
      totals: dummyDefaultTrendifyTotals,
    };

    this.refresh = this.refresh.bind(this);
    this.reset = this.reset.bind(this);
    this.sort = this.sort.bind(this);
    this.exportFile = this.exportFile.bind(this);
    this.onProductDetail = this.onProductDetail.bind(this);
    this.toggleAnalyticsFilters = this.toggleAnalyticsFilters.bind(this);
    this.getTotals = this.getTotals.bind(this);
    this.setScrollToTop = this.setScrollToTop.bind(this);
    this.calculateSticky = this.calculateSticky.bind(this);
    this.onTableScroll = this.onTableScroll.bind(this);
  }

  componentDidMount() {
    setTitle(t('Segmentify Analytics'));

    if (trendifyIntegrationInfo(false, true)) {
      uiActions.hideBreadcrumb();
      uiActions.isLoading();

      filterActions.filtersReset();
      const newQuery = { ...this.state.query };
      newQuery.startDate = queryDate(today().subtract(6, 'd'));
      newQuery.endDate = queryDate(today());
      getProducts(newQuery, response => {
        this.setState({
          rows: response,
          showLoadingAnimation: false,
        });
        this.getTotals(newQuery);
        uiActions.isLoaded();
        this.setScrollToTop();
        this.calculateSticky();
      });

      getProductCount(newQuery, response => {
        trendifyActions.updateCount(response);
      });

      getBrands(response => {
        trendifyActions.updateBrands(response);
      });
    }
    dateActions.updateRangeAlias('lastSevenDays');
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      trendifyIntegrationInfo();
      setTimeout(() => {
        this.refresh(newProps, newProps.pageSize, newProps.pageNumber);
      }, 200);
    }

    if (newProps.update === true) {
      this.refresh(newProps, 100, 1);
    }

    if (newProps.reset === true) {
      this.reset();
    }

    if (
      newProps.pageSize !== this.props.pageSize ||
      newProps.pageNumber !== this.props.pageNumber
    ) {
      this.refresh(newProps, newProps.pageSize, newProps.pageNumber);
    }
  }

  onProductDetail = (product, device, e) => {
    e.preventDefault();
    const content = () => (
      <TrendifyProductDetail
        product={product.productId}
        device={device}
        record={product}
        hasSegmentifyListing={hasSegmentifyListing()}
        hasTrendifySources={hasTrendifySources()}
      />
    );

    uiActions.openModal({
      title: `${product.productId} - ${product.name}`,
      content,
    });
  };

  onTableScroll(event) {
    let showButton = true;
    if (event.scrollTop <= this.state.maxHeight) {
      showButton = false;
    }
    if (event.scrollTop <= 100) {
      showButton = false;
    }
    if (showButton !== this.state.showScrollToTop) {
      this.setState({
        showScrollToTop: showButton,
      });
    }
  }

  setScrollToTop() {
    const element = this.refs.scrollElement;
    let showButton = !!element;
    if (element) {
      if (
        element.scrollHeight <=
        parseFloat(element.style['max-height'].split('px')[0])
      ) {
        showButton = false;
      }
      if (element.scrollTop <= 100) {
        showButton = false;
      }
    }
    if (this.state.showScrollToTop !== showButton) {
      this.setState({
        showScrollToTop: showButton,
      });
    }
  }

  getTotals(query) {
    uiActions.isLoading();
    this.setState({
      isTotalsLoading: true,
    });
    getProductTotal(query, response => {
      this.setState(
        prevState => {
          return {
            totals: parseTotalLineAPI(response, prevState.device),
            isTotalsLoading: false,
          };
        },
        () => {
          uiActions.isLoaded();
        },
      );
    });
  }

  getAllColumns = () => {
    const defaults = [
      'inStock',
      'insertTime',
      'productId',
      'name',
      'category',
      'oldPrice',
      'brand',
      'image',
    ];
    const columns = [];
    const columnsWithOrder = [];
    defaults.forEach(column => {
      columns.push({ name: column });
    });
    if (getCustomTrendifyColumns()) {
      getCustomTrendifyColumns().forEach(column => {
        if (!column.order) {
          columns.push({ name: column.field, dataType: column.dataType });
        } else {
          columnsWithOrder.push({
            name: column.field,
            dataType: column.dataType,
            order: column.order,
          });
        }
      });
    }
    columnsWithOrder.forEach(column => {
      // Order columns
      columns.splice(column.order - 1, 0, column);
    });
    return columns;
  };

  getAllHeaders() {
    let defaults = [...trendifyTable.header];
    if (!hasSegmentifyListing()) {
      defaults = [...trendifyTable.headerWithoutSgf];
    }
    let sourceStartIndex = 0;
    defaults.forEach((heading, headingIndex) => {
      if (heading.isSourceStart) {
        if (sourceStartIndex === 0) {
          sourceStartIndex = headingIndex;
        }
      }
    });
    if (sourceStartIndex && hasTrendifySources()) {
      hasTrendifySources().forEach((source, sourceIndex) => {
        defaults[sourceStartIndex] = {
          icon: source.icon,
          sortable: true,
          sort: `stats->>'${this.state.device}${source.field}Click'`,
          tooltip: source.label,
          alignment: 'right',
          svg: true,
        };
        sourceStartIndex += 1;
        if (sourceIndex === 0) {
          defaults[sourceStartIndex].className = 'cell-group';
        }
      });
    }
    const columnsWithOrder = [];
    const columnsWithoutOrder = [];
    if (getCustomTrendifyColumns()) {
      getCustomTrendifyColumns().forEach(column => {
        const columnObj = {
          text: column.label || '',
          sortable: column.sortable || false,
          sort: column.sortable ? column.field : false,
          tooltip: column.label,
        };
        if (!column.order) {
          columnsWithoutOrder.push(columnObj);
        } else {
          columnsWithOrder.push(
            Object.assign(columnObj, { order: column.order }),
          );
        }
      });
    }
    columnsWithoutOrder.forEach((column, index) => {
      // add columns without order to end of defaults.
      defaults.splice(9 + index, 0, column);
    });
    columnsWithOrder.forEach(column => {
      // order columns
      defaults.splice(column.order, 0, column);
    });
    return defaults;
  }

  setSideBarHidden = () => {
    const bodyEl = document.querySelector('#primary-sidebar');
    if (bodyEl) {
      bodyEl.style.display = 'none';
    }
  };

  getCustomHeadingData = (recordObj, record, dataType) => {
    const data = { className: '', value: '' };
    // Stock
    if (record === 'inStock') {
      data.className = 'analytics-stock';
      if (recordObj.inStock) {
        data.value = getStockValues(recordObj) ? (
          <Tooltip
            content={getStockValues(recordObj)}
            placement='right'
            newTooltip
            alignment='top'
          >
            <Icon name='check-circle' />
          </Tooltip>
        ) : (
          <Icon name='check-circle' />
        );
      } else {
        data.value = <span className='out-of-stock' />;
      }
    }
    // insertTime
    if (record === 'insertTime') {
      data.value = reformatDate(recordObj.insertTime);
    }
    // productId
    if (record === 'productId') {
      data.className = 'product-id';
      data.value = recordObj.productId;
    }
    // name
    if (record === 'name') {
      data.className = 'product-name';
      data.value = (
        <a href={productUrl(recordObj.url)} target='_blank' rel='noreferrer'>
          {decodeHtml(recordObj.name)}
        </a>
      );
    }
    // category
    if (record === 'category') {
      if (recordObj.category) {
        data.value = (
          <Tooltip content={recordObj.category[0]} newTooltip alignment='top'>
            {recordObj.category[0]}
          </Tooltip>
        );
      } else if (recordObj.categories) {
        data.value = (
          <Tooltip
            content={decodeHtml(recordObj.categories[0])}
            alignment='center'
            placement='left'
          >
            {recordObj.categories[0]}
          </Tooltip>
        );
      } else {
        data.value = <div>-</div>;
      }
    }
    /// oldPrice
    if (record === 'oldPrice') {
      data.className = 'product-price';
      data.value = recordObj.oldPrice ? (
        <span>
          <span className='stroke'>
            <Currency currency={recordObj.oldPrice} />
          </span>
          <Currency currency={recordObj.price} />
        </span>
      ) : (
        <Currency currency={recordObj.price} />
      );
    }
    // brand
    if (record === 'brand') {
      data.className = 'product-name';
      data.value = recordObj.brand ? decodeHtml(recordObj.brand) : '-';
    }
    // image
    if (record === 'image') {
      data.value = (
        <Tooltip
          content={productUrl(recordObj.image)}
          type='image'
          placement='left'
          newTooltip
          alignment='left'
        >
          <img
            src={productUrl(recordObj.image)}
            height='40'
            width='40'
            alt={recordObj.name}
          />
        </Tooltip>
      );
    }
    if (!data.value) {
      const dataValue =
        recordObj.params && typeof recordObj.params[record] !== 'undefined'
          ? recordObj.params[record]
          : '-';
      if (dataType === 'currency') {
        if (dataValue !== '-' && typeof dataValue !== 'undefined') {
          data.value = <Currency currency={dataValue} />;
        } else {
          data.value = '-';
        }
      } else {
        data.value = dataValue;
      }
    }
    return data;
  };

  tooltipContent = (statPrefix, item) => {
    return (
      <div className='click-tooltip-content'>
        <div className='click-tooltip-left'>
          <div className='click-tooltip-subItem'>
            <div>
              <Icons name='twitter' width='15px' height='15px' />
            </div>
            <div>
              Twitter <br />
              <b>
                {localeString(
                  item.stats[`${statPrefix}TwitterClick`]
                    ? item.stats[`${statPrefix}TwitterClick`]
                    : '-',
                )}
              </b>
            </div>
          </div>
          <div className='click-tooltip-subItem'>
            <div>
              <Icons name='instagram' width='15px' height='15px' />
            </div>
            <div>
              Instagram <br />
              <b>
                {localeString(
                  item.stats[`${statPrefix}InstagramClick`]
                    ? item.stats[`${statPrefix}InstagramClick`]
                    : '-',
                )}
              </b>
            </div>
          </div>
          <div className='click-tooltip-subItem'>
            <div>
              <Icons name='tiktok' width='15px' height='15px' />
            </div>
            <div>
              Tiktok <br />
              <b>
                {localeString(
                  item.stats[`${statPrefix}TiktokClick`]
                    ? item.stats[`${statPrefix}TiktokClick`]
                    : '-',
                )}
              </b>
            </div>
          </div>
        </div>
        <div className='click-tooltip-right'>
          <div className='click-tooltip-subItem'>
            <div>
              <Icons name='facebook' width='15px' height='15px' />
            </div>
            <div>
              Facebook <br />
              <b>
                {localeString(
                  item.stats[`${statPrefix}FacebookClick`]
                    ? item.stats[`${statPrefix}FacebookClick`]
                    : '-',
                )}
              </b>
            </div>
          </div>
          <div className='click-tooltip-subItem'>
            <div>
              <Icons name='snapchat' width='15px' height='15px' />
            </div>
            <div>
              Snapchat <br />
              <b>
                {localeString(
                  item.stats[`${statPrefix}SnapchatClick`]
                    ? item.stats[`${statPrefix}SnapchatClick`]
                    : '-',
                )}
              </b>
            </div>
          </div>
          <div className='click-tooltip-subItem'>
            <div>
              <Icons name='others' width='15px' height='15px' />
            </div>
            <div>
              Other <br />
              <b>
                {localeString(
                  item.stats[`${statPrefix}OtherSocialMediaClick`]
                    ? item.stats[`${statPrefix}OtherSocialMediaClick`]
                    : '-',
                )}
              </b>
            </div>
          </div>
        </div>
      </div>
    );
  };

  sort(sorting) {
    const query = clone(this.state.query);
    query.startDate = this.props.range[0].format('YYYY-MM-DD');
    query.endDate = this.props.range[1].format('YYYY-MM-DD');
    gridSort(sorting, query, getProducts, this, this.state.device);
  }

  refresh(newProps, pageSize, pageNumber) {
    uiActions.isLoading();

    const query = this.query(newProps, pageSize, pageNumber);

    getProducts(query, response => {
      this.setState(
        {
          rows: response,
          query,
          showLoadingAnimation: false,
        },
        () => {
          this.setScrollToTop();
          this.getTotals(query);
          this.calculateSticky();
          uiActions.isLoaded();
        },
      );
    });

    getProductCount(query, response => {
      trendifyActions.updateCount(response);
    });

    filterActions.filtersUpdated();
  }

  query(newProps, pageSize, pageNumber) {
    if (!this.refs.pageFilters) return null;
    const query = clone(trendifyQuery);
    const range = newProps ? newProps.range : this.props.range;
    const minPrice = this.refs.pageFilters.querySelector('#minPrice').value;
    const maxPrice = this.refs.pageFilters.querySelector('#maxPrice').value;
    const product = this.refs.pageFilters.querySelector('[name=productName]')
      .value;
    const stock = this.refs.pageFilters.querySelector('[name=productStock]')
      .value;
    const device = this.refs.pageFilters.querySelector('[name=visitorDevice]')
      .value;

    // Multiselect for Category
    query.category = [];
    const currentCategories = this.refs.pageFilters.querySelectorAll(
      '.tfy-selected-category',
    );
    [].forEach.call(currentCategories, function(cat) {
      query.category.push(cat.dataset.selectedval);
    });

    // MultiSelect for Brands
    query.brand = [];
    const currentBrands = this.refs.pageFilters.querySelectorAll(
      '.tfy-selected-brand',
    );
    [].forEach.call(currentBrands, function(brand) {
      query.brand.push(brand.dataset.selectedval);
    });

    query.startDate = range[0].format('YYYY-MM-DD');
    query.endDate = range[1].format('YYYY-MM-DD');
    query.minPrice = minPrice;
    query.maxPrice = maxPrice;
    query.product = product;
    query.items = this.props.pageSize;
    query.page = this.props.pageNumber;
    query.reverse = this.state.sort === 'DESC';

    if (stock !== 'all') {
      query.stock = stock;
    }

    query.sort = transformAnalyticsSorting(this.state.currentSorted, device);

    if (
      typeof newProps !== 'undefined' &&
      typeof newProps.pageSize !== 'undefined'
    ) {
      query.items = pageSize || 100;
    }

    if (
      typeof newProps !== 'undefined' &&
      typeof newProps.pageNumber !== 'undefined'
    ) {
      query.page = pageNumber || 1;
    }

    this.setState({ device });

    if (getCustomTrendifyColumns()) {
      const custom = {};
      getCustomTrendifyColumns().forEach(column => {
        if (column.filterable) {
          if (column.dataType === 'string') {
            if (
              this.refs.pageFilters.querySelector(`[name=${column.field}]`)
                .value
            ) {
              custom[column.field] = this.refs.pageFilters.querySelector(
                `[name=${column.field}]`,
              ).value;
            }
          } else if (
            column.dataType === 'number' ||
            column.dataType === 'currency'
          ) {
            if (
              this.refs.pageFilters.querySelector(`#${column.field}min`)
                .value !== ''
            ) {
              custom[
                `${column.field}.min`
              ] = this.refs.pageFilters.querySelector(
                `#${column.field}min`,
              ).value;
            }
            if (
              this.refs.pageFilters.querySelector(`#${column.field}max`)
                .value !== ''
            ) {
              custom[
                `${column.field}.max`
              ] = this.refs.pageFilters.querySelector(
                `#${column.field}max`,
              ).value;
            }
          } else if (column.dataType === 'boolean') {
            if (
              this.refs.pageFilters.querySelector(`[name=${column.field}]`)
                .value !== 'all'
            ) {
              custom[column.field] = this.refs.pageFilters.querySelector(
                `[name=${column.field}]`,
              ).value;
            }
          }
        }
      });
      if (Object.keys(custom).length) {
        query.custom = custom;
      }
    }

    return query;
  }

  reset() {
    const query = dateUpdatedQuery({
      range: this.props.range,
      rangeAlias: this.props.rangeAlias,
      analyticsQuery: trendifyQuery,
    });

    this.setState({ device: 'all' });

    gridReset(query, getProducts, getProductCount, this, () => {
      this.getTotals(query);
      this.setScrollToTop();
      this.calculateSticky();
    });
  }

  exportFile() {
    const { range } = this.props;
    const name = `Product_Report_${range[0].format(
      'MMMM_D_YYYY',
    )}-${range[1].format('MMMM_D_YYYY')}.csv`;

    const content = () => (
      <Export
        name={name}
        query={this.query()}
        endpoint='trendify/product/export'
        range={this.props.range}
      />
    );

    uiActions.openModal({ title: t('Export Products'), content });
  }

  calculateSticky() {
    const element = this.refs.scrollElement;
    if (element) {
      const { maxHeight } = element.style;
      const headerEl = document.querySelector('.sticky-table-header-wrapper')
        .clientHeight;
      document.querySelector('.sticky-table').style.maxHeight = maxHeight;
      document.querySelector(
        '.sticky-table-y-wrapper',
      ).style.maxHeight = `${parseInt(maxHeight.split('px')[0]) -
        headerEl -
        20}px`;

      let addBorderToProducts = document.querySelectorAll(
        '.product-tableColumn--latest',
      );
      addBorderToProducts = [].slice.call(addBorderToProducts);
      addBorderToProducts.forEach(domEl => {
        domEl.parentElement.style.borderRight = '1px solid #ecebf7';
      });

      let addBorderToSgf = document.querySelectorAll(
        '.sgf-tableColumn--latest',
      );
      addBorderToSgf = [].slice.call(addBorderToSgf);
      addBorderToSgf.forEach(domEl => {
        domEl.parentElement.style.borderRight = '1px solid #ecebf7';
      });

      let addBorderToConversion = document.querySelectorAll(
        '.conversion-tableColumn-latest',
      );
      addBorderToConversion = [].slice.call(addBorderToConversion);
      addBorderToConversion.forEach(domEl => {
        domEl.parentElement.style.borderRight = '1px solid #ecebf7';
      });

      let addBorderToHeader = document.querySelectorAll('.add-border');
      addBorderToHeader = [].slice.call(addBorderToHeader);
      addBorderToHeader.forEach(domEl => {
        domEl.parentElement.style.borderRight = '1px solid #ecebf7';
      });

      /// Main Header Borders
      const columnsLength = this.getAllColumns().length;
      const conversionLength = columnsLength + 9;
      const sgfLength = conversionLength + (hasSegmentifyListing() ? 9 : 0);
      const sourcesLength =
        sgfLength + (!hasTrendifySources() ? 7 : hasTrendifySources().length);

      const headers = document.querySelectorAll(
        '.sticky-table-header .sticky-table-row:first-child .sticky-table-cell',
      );
      const mainHeaders = document.querySelectorAll('.tfy-table-header');

      const intervals = [];
      intervals.push({ start: 0, end: columnsLength });
      intervals.push({ start: columnsLength, end: conversionLength });
      if (hasSegmentifyListing()) {
        intervals.push({ start: conversionLength, end: sgfLength });
        intervals.push({ start: sgfLength, end: sourcesLength });
      } else {
        intervals.push({ start: conversionLength, end: sourcesLength });
      }
      const widths = [];
      intervals.forEach((interval, intervalInd) => {
        let startWidth = 0;
        let endWidth = 0;
        [].forEach.call(headers, (item, index) => {
          // :) hooray `item` can be used here
          if (index < interval.start) {
            startWidth += item.clientWidth;
          }
          if (index < interval.end) {
            endWidth += item.clientWidth;
          }
        });
        if (intervalInd !== 0) {
          widths.push({
            totalWidth:
              endWidth - startWidth + widths[intervalInd - 1].totalWidth,
            width:
              widths[intervalInd + -1].totalWidth + (endWidth - startWidth) / 2,
          });
        } else {
          widths.push({
            totalWidth: endWidth - startWidth,
            width: (endWidth - startWidth) / 2,
          });
        }
      });

      mainHeaders.forEach((mainHeader, mainHeaderIndex) => {
        if (widths[mainHeaderIndex]) {
          mainHeader.style.left = `${widths[mainHeaderIndex].width}px`;
        }
      });

      document.querySelector(
        '.sticky-table-header .sticky-table-row',
      ).style.height = '50px';

      this.setState({
        maxHeight: document
          .getElementsByClassName('sticky-table-y-wrapper')[0]
          .style.maxHeight.split('px')[0],
      });
    }
  }

  toggleAnalyticsFilters() {
    this.setState(prevState => {
      return {
        showTrendifyFilters: !prevState.showTrendifyFilters,
      };
    });
  }

  render() {
    if (
      this.props.user.displayName &&
      !hasModulePrivilege(false, 'TRENDUSER')
    ) {
      browserHistory.push('/');
    }
    const tableHeight = window.innerHeight - 195;
    const dummyRowsToShow = [];
    for (let i = 0; i < 15; i += 1) {
      dummyRowsToShow.push(dummyRow);
    }
    const rows = this.state.showLoadingAnimation
      ? dummyRowsToShow
      : this.state.rows;
    const columnsLength = this.getAllColumns().length;
    const conversionLength = columnsLength + 9;
    const sgfLength = conversionLength + (hasSegmentifyListing() ? 9 : 0);
    const sourcesLength =
      sgfLength + (!hasTrendifySources() ? 7 : hasTrendifySources().length);
    const allLength = sourcesLength + 1;

    const { badResponseCatVisible } = this.props;

    const parentClass = cn(
      'segmentify-analytics segmentify-analytics--trendify',
      {
        '.hide': badResponseCatVisible,
      },
    );
    return (
      <>
        <div className={parentClass}>
          {!hasTrendify() && (
            <h2 className='page-title'>
              <span className='for-screenreader-only'>{t('Trendify')}</span>
            </h2>
          )}
          {hasTrendify() && hasModulePrivilege(false, 'TRENDUSER') && (
            <div>
              <div ref='pageFilters'>
                <Filters
                  limitRange='months'
                  visibleUpdate={false}
                  toggleAnalyticsFilters={() => this.toggleAnalyticsFilters()}
                  analyticsFilters
                  exportFile={() => this.exportFile()}
                >
                  <TrendifyFilters
                    showTrendifyFilters={this.state.showTrendifyFilters}
                    toggleAnalyticsFilters={() => this.toggleAnalyticsFilters()}
                  />
                </Filters>
              </div>
              {this.state.showLoadingAnimation || this.state.rows.length > 0 ? (
                <div
                  className={cn('analytics-details export-content-wrapper', {
                    'analytics-placeholder':
                      this.state.showLoadingAnimation === true,
                  })}
                  style={{
                    maxHeight: `${tableHeight}px`,
                    overflow: 'hidden',
                  }}
                  ref='scrollElement'
                >
                  <StickyTable
                    stickyColumnCount={0}
                    stickyHeaderCount={2}
                    onScroll={this.onTableScroll}
                  >
                    <Row>
                      {[...Array(allLength + 1)].map((item, index) => {
                        let givenClass =
                          index === columnsLength ||
                          index === conversionLength ||
                          index === sgfLength
                            ? 'add-border'
                            : '';
                        if (index === 1) {
                          givenClass += ' tfy-table-header analytics-product';
                        } else if (index === 2) {
                          givenClass +=
                            ' tfy-table-header analytics-conversion';
                        } else if (index === 3 && hasSegmentifyListing()) {
                          givenClass +=
                            ' tfy-table-header analytics-segmentify';
                        } else if (index === 4) {
                          givenClass += ' tfy-table-header analytics-sources';
                        }

                        if (this.state.showLoadingAnimation) {
                          givenClass += '--loading';
                        }

                        return (
                          <Cell key={`heading-segmentify-cell-${item}`}>
                            <div className={givenClass}>
                              {index === 1 &&
                                `${t('Product')} (${this.props.count})`}
                              {index === 2 && t('Website')}
                              {hasSegmentifyListing() &&
                                index === 3 &&
                                t('Segmentify')}
                              {((hasSegmentifyListing() && index === 4) ||
                                (!hasSegmentifyListing() && index === 4)) &&
                                t('Sources')}
                            </div>
                          </Cell>
                        );
                      })}
                    </Row>
                    <TableHeadings
                      currentSorted={this.state.currentSorted}
                      direction={this.state.sort}
                      heading={this.getAllHeaders()}
                      isSticky
                      onSort={this.sort}
                    />
                    {this.state.rows.length
                      ? rows.map((item, index) => {
                          const order =
                            index +
                            1 +
                            this.props.pageSize * (this.props.pageNumber - 1);
                          const statPrefix = this.state.device;
                          return (
                            <Row>
                              <Cell>
                                <div className='product-tableColumn'>
                                  {order}
                                </div>
                              </Cell>
                              {this.getAllColumns().map(
                                (columnData, columnIndex) => {
                                  let givenClass =
                                    columnIndex ===
                                    this.getAllColumns().length - 1
                                      ? 'product-tableColumn product-tableColumn--latest'
                                      : 'product-tableColumn';
                                  const column = this.getCustomHeadingData(
                                    item.record,
                                    columnData.name,
                                    columnData.dataType,
                                  );
                                  givenClass += ` ${columnData.name}`;
                                  if (column.value !== 'undefined') {
                                    return (
                                      <Cell>
                                        <div className={givenClass}>
                                          {column.value}
                                        </div>
                                      </Cell>
                                    );
                                  }
                                  return (
                                    <Cell>
                                      <div className={givenClass}>-</div>
                                    </Cell>
                                  );
                                },
                              )}
                              <Cell>
                                <div className='conversion-tableColumn'>
                                  {localeString(
                                    item.stats[`${statPrefix}SiteView`],
                                  ) || '-'}
                                </div>
                              </Cell>
                              <Cell>
                                <div className='conversion-tableColumn'>
                                  {localeString(
                                    item.stats[`${statPrefix}SiteBasket`],
                                  ) || '-'}
                                </div>
                              </Cell>
                              <Cell>
                                <div className='conversion-tableColumn'>
                                  {localeString(
                                    item.stats[`${statPrefix}SiteUBasket`],
                                  ) || '-'}
                                </div>
                              </Cell>
                              <Cell>
                                <div className='conversion-tableColumn'>
                                  {localeString(
                                    calculateRatio(
                                      item.stats[`${statPrefix}SiteBasket`],
                                      item.stats[`${statPrefix}SiteView`],
                                    ),
                                  )}
                                  %
                                </div>
                              </Cell>
                              <Cell>
                                <div className='conversion-tableColumn'>
                                  <Currency
                                    currency={
                                      item.stats[`${statPrefix}SiteBamount`] ||
                                      0
                                    }
                                  />
                                </div>
                              </Cell>
                              <Cell>
                                <div className='conversion-tableColumn'>
                                  {localeString(
                                    item.stats[`${statPrefix}SitePurchase`],
                                  ) || '-'}
                                </div>
                              </Cell>
                              <Cell>
                                <div className='conversion-tableColumn'>
                                  {localeString(
                                    item.stats[`${statPrefix}SiteUPurchase`],
                                  ) || '-'}
                                </div>
                              </Cell>
                              <Cell>
                                <div className='conversion-tableColumn'>
                                  {localeString(
                                    calculateRatio(
                                      item.stats[`${statPrefix}SitePurchase`],
                                      item.stats[`${statPrefix}SiteView`],
                                    ),
                                  )}
                                  %
                                </div>
                              </Cell>
                              <Cell>
                                <div className='conversion-tableColumn conversion-tableColumn-latest'>
                                  <Currency
                                    currency={
                                      item.stats[`${statPrefix}SiteAmount`] || 0
                                    }
                                  />
                                </div>
                              </Cell>
                              {hasSegmentifyListing() && (
                                <>
                                  <Cell>
                                    <div>
                                      {localeString(
                                        item.stats[`${statPrefix}SgmClick`],
                                      ) || '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {localeString(
                                        item.stats[`${statPrefix}SgmBasket`],
                                      ) || '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {localeString(
                                        item.stats[`${statPrefix}SgmUBasket`],
                                      ) || '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {localeString(
                                        calculateRatio(
                                          item.stats[`${statPrefix}SgmBasket`],
                                          item.stats[`${statPrefix}SgmClick`],
                                        ),
                                      )}
                                      %
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      <Currency
                                        currency={
                                          item.stats[
                                            `${statPrefix}SgmBamount`
                                          ] || 0
                                        }
                                      />
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {localeString(
                                        item.stats[`${statPrefix}SgmPurchase`],
                                      ) || '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {localeString(
                                        item.stats[`${statPrefix}SgmUPurchase`],
                                      ) || '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {localeString(
                                        calculateRatio(
                                          item.stats[
                                            `${statPrefix}SgmPurchase`
                                          ],
                                          item.stats[`${statPrefix}SgmClick`],
                                        ),
                                      )}
                                      %
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div className='sgf-tableColumn--latest'>
                                      <Currency
                                        currency={
                                          item.stats[
                                            `${statPrefix}SgmAmount`
                                          ] || 0
                                        }
                                      />
                                    </div>
                                  </Cell>
                                </>
                              )}

                              {!hasTrendifySources() && (
                                <>
                                  <Cell>
                                    <div>
                                      {localeString(
                                        item.stats[
                                          `${statPrefix}InternalClick`
                                        ],
                                      ) || '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      <Tooltip
                                        content={this.tooltipContent(
                                          statPrefix,
                                          item,
                                        )}
                                        alignment='bottom'
                                        overlayClassName='tooltip-border'
                                        newTooltip
                                        isInline
                                      >
                                        <div className='tooltip-main-content'>
                                          {localeString(
                                            item.stats[
                                              `${statPrefix}SocialClick`
                                            ],
                                          ) || '-'}
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {localeString(
                                        item.stats[`${statPrefix}SearchClick`],
                                      ) || '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {localeString(
                                        item.stats[`${statPrefix}PaidClick`],
                                      ) || '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {localeString(
                                        item.stats[`${statPrefix}EmailClick`],
                                      ) || '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div>
                                      {localeString(
                                        item.stats[`${statPrefix}ListingClick`],
                                      ) || '-'}
                                    </div>
                                  </Cell>
                                  <Cell>
                                    <div className='sources-tableColumn-latest'>
                                      {localeString(
                                        item.stats[`${statPrefix}OtherClick`],
                                      ) || '-'}
                                    </div>
                                  </Cell>
                                </>
                              )}
                              {hasTrendifySources() &&
                                hasTrendifySources().map(
                                  (source, sourceIndex) => {
                                    if (sourceIndex === 0) {
                                      return (
                                        <Cell>
                                          <div>
                                            {localeString(
                                              item.stats[
                                                `${statPrefix +
                                                  source.field}Click`
                                              ],
                                            ) || '-'}
                                          </div>
                                        </Cell>
                                      );
                                    }
                                    return (
                                      <Cell>
                                        <div className='sources-tableColumn-latest'>
                                          {localeString(
                                            item.stats[
                                              `${statPrefix +
                                                source.field}Click`
                                            ],
                                          ) || '-'}
                                        </div>
                                      </Cell>
                                    );
                                  },
                                )}

                              <Cell>
                                <div>
                                  <nav className='account-nav action-nav'>
                                    <Icon name='menu' />
                                    <ul className='action-nav-popup'>
                                      <li>
                                        <a
                                          className='action-nav-popup-item'
                                          onClick={this.onProductDetail.bind(
                                            this,
                                            item.record,
                                            statPrefix,
                                          )}
                                          style={{ color: '#fff' }}
                                        >
                                          <Icon name='calendar' />
                                          {t('Compare')}
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </Cell>
                            </Row>
                          );
                        })
                      : [...Array(30)].map(() => (
                          <Row>
                            {[...Array(allLength + 1)].map(() => (
                              <Cell>
                                <div className='category-tableColumn'>TFY</div>
                              </Cell>
                            ))}
                          </Row>
                        ))}
                  </StickyTable>
                </div>
              ) : (
                <div className='data-content-message'>
                  <div className='data-content-message-text'>
                    <i className='icon-info' />
                    {t('There are no records to show.')}
                  </div>
                </div>
              )}
              {rows.length > 0 && (
                <TotalsLine
                  totals={this.state.totals}
                  totalsLabels={trendifyTable.totals}
                  isLoading={this.state.isTotalsLoading}
                  selectedCurrency={this.state.selectedCurrency}
                />
              )}
              {this.state.showScrollToTop && <ScrollUpButtonAnalytics />}
            </div>
          )}
          {hasTrendify() && !hasModulePrivilege(false, 'TRENDUSER') && (
            <div>{t('User has no privilege')}</div>
          )}
          {!hasTrendify() && (
            <div className='promo promo--centered'>
              <p className='promo-text'>{t('TrendifyPromo')}</p>
              {!ExternalAppUtility.isDengage() && (
                <a
                  href='https://segmentify.com/solution/growth-optimisation/'
                  target='_blank'
                  className='secondary-action'
                  rel='noreferrer'
                >
                  {t('Discover')}
                </a>
              )}
            </div>
          )}
        </div>
        {badResponseCatVisible && <BadResponseCat />}
      </>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  ui: store.ui.ui,
  pageSize: store.filters.pageSize,
  pageNumber: store.filters.pageNumber,
  count: store.filters.count,
  update: store.filters.update,
  reset: store.filters.reset,
  badResponseCatVisible: store.ui.badResponseCatVisible,
});

export default connect(mapStatesToProps)(Trendify);
