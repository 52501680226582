import moment from 'moment';

import { createReducer } from '../system/store';
import * as dateActions from '../constants/actions/date';

let dates = {};

const updateDateData = () => {
  dates = {
    now: moment().startOf('day'),
    today: moment().endOf('day'),
    yesterday: moment()
      .add(-1, 'days')
      .startOf('day'),
    yesterdayEnd: moment()
      .add(-1, 'days')
      .endOf('day'),
    twoDaysAgo: moment()
      .subtract(2, 'd')
      .startOf('day'),
    twoDaysAgoEnd: moment()
      .subtract(2, 'd')
      .endOf('day'),
    previousWeek: moment()
      .subtract(1, 'isoWeek')
      .startOf('isoWeek'),
    previousWeekEnd: moment()
      .subtract(1, 'isoWeek')
      .endOf('isoWeek'),
    week: moment().startOf('isoweek'),
    eightDays: moment()
      .subtract(8, 'd')
      .startOf('day'),
    sevenDays: moment()
      .subtract(6, 'd')
      .startOf('day'),
    month: moment().startOf('month'),
    lastMonth: moment()
      .add(-1, 'month')
      .startOf('month'),
    lastMonthEnd: moment()
      .add(-1, 'month')
      .endOf('month'),
    year: moment().startOf('year'),
  };
};
updateDateData();

const dateUpdatedControl = () => {
  const today = moment().endOf('day');
  if (today !== dates.today) {
    updateDateData();
  }
};

const initialState = {
  custom: false,
  range: [dates.week, dates.today],
  rangeAlias: 'weekly',
  resetVisible: false,
  useDefaultOpening: true,
};

export default createReducer(initialState, {
  [dateActions.default.DATE_RANGE_CHANGED]: (state, payload) => {
    dateUpdatedControl();
    return {
      ...state,
      custom: true,
      range: payload.range,
      resetVisible: true,
      useDefaultOpening: false,
    };
  },
  [dateActions.default.NAMED_RANGE_CHANGED]: (state, payload) => {
    dateUpdatedControl();
    let range = [];
    let custom = false;
    switch (payload.rangeAlias) {
      default:
      case 'custom':
        range = state.range;
        custom = true;
        break;
      case 'daily':
        range = [dates.now, dates.today];
        break;
      case 'yesterday':
        range = [dates.yesterday, dates.yesterdayEnd];
        break;
      case 'weekly':
        range = [dates.week, dates.today];
        break;
      case 'lastSevenDays':
      case 'days':
        range = [
          moment()
            .subtract(6, 'd')
            .startOf('day'),
          dates.today,
        ];
        break;
      case 'monthly':
        range = [dates.month, dates.today];
        break;
      case 'lastmonth':
        range = [dates.lastMonth, dates.lastMonthEnd];
        break;
      case 'weeks':
        range = [
          moment()
            .subtract(5, 'weeks')
            .startOf('day'),
          dates.today,
        ];
        break;
      case 'yearly':
        range = [dates.year, dates.today];
        break;
      case 'previousWeek':
        range = [dates.previousWeek, dates.previousWeekEnd];
        break;
      case 'gWeekly':
        range = [
          moment()
            .subtract(2, 'd')
            .startOf('isoweek'),
          dates.twoDaysAgoEnd,
        ];
        break;
      case 'gPreviousWeek':
        range = [dates.previousWeek, dates.previousWeekEnd.subtract(2, 'd')];
        break;
      case 'gLastSevenDays':
        range = [dates.eightDays, dates.twoDaysAgoEnd];
        break;
      case 'gMonthly':
        range = [dates.month, dates.twoDaysAgoEnd];
        break;
      case 'twoDaysAgo':
        range = [dates.twoDaysAgo, dates.twoDaysAgoEnd];
        break;
      case 'all':
        range = [dates.year, dates.today];
        break;
    }

    return {
      ...state,
      custom,
      range,
      rangeAlias: payload.rangeAlias,
      resetVisible: true,
      useDefaultOpening: payload.defaultOpening,
    };
  },
  [dateActions.default.RESET_TO_CUSTOM_SETTINGS]: (
    state,
    { range, rangeAlias },
  ) => {
    dateUpdatedControl();
    return {
      ...state,
      custom: false,
      range,
      rangeAlias,
      resetVisible: false,
    };
  },
  [dateActions.default.RANGE_RESET]: state => {
    dateUpdatedControl();
    return {
      ...state,
      custom: false,
      range: [dates.week, dates.today],
      rangeAlias: 'weekly',
      resetVisible: false,
    };
  },
  [dateActions.default.VIEW_DAY]: state => {
    dateUpdatedControl();
    return {
      ...state,
      custom: false,
      range: [dates.now, dates.today],
      rangeAlias: 'daily',
      resetVisible: true,
    };
  },
  [dateActions.default.VIEW_YESTERDAY]: state => {
    dateUpdatedControl();
    return {
      ...state,
      custom: false,
      range: [dates.yesterday, dates.today],
      rangeAlias: 'yesterday',
      resetVisible: true,
    };
  },
  [dateActions.default.VIEW_WEEK]: state => {
    dateUpdatedControl();
    return {
      ...state,
      custom: false,
      range: [dates.week, dates.today],
      rangeAlias: 'weekly',
      resetVisible: true,
    };
  },
  [dateActions.default.VIEW_MONTH]: state => {
    dateUpdatedControl();
    return {
      ...state,
      custom: false,
      range: [dates.month, dates.today],
      rangeAlias: 'monthly',
      resetVisible: true,
    };
  },
  [dateActions.default.VIEW_LAST_MONTH]: state => {
    dateUpdatedControl();
    return {
      ...state,
      custom: false,
      range: [dates.lastMonth, dates.lastMonthEnd],
      rangeAlias: 'lastmonth',
      resetVisible: true,
    };
  },
  [dateActions.default.VIEW_YEAR]: state => {
    dateUpdatedControl();
    return {
      ...state,
      custom: false,
      range: [dates.year, dates.today],
      rangeAlias: 'yearly',
      resetVisible: true,
    };
  },
});
