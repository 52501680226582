import { t } from '../../system/ui';

const getCategoryBrandLabel = (type, count) => {
  if (type === 'category') {
    if (count) {
      const text =
        count > 1 ? t('categories selected') : t('category selected');
      return `${count} ${text}`;
    }
    return t('All Product Categories');
  }
  if (count) {
    const text = count > 1 ? t('brands selected') : t('brand selected');
    return `${count} ${text}`;
  }
  return t('All Product Brands');
};

export { getCategoryBrandLabel };
