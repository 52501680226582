/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";

import { plugins } from "../constants/datamaps/plugins";

import { t } from "../system/ui";
import { setTitle } from "../system/document";

class Settings_Payments extends React.Component {
  componentDidMount() {
    setTitle("Plugins");
  }

  render() {
    return (
      <div>
        <h2 className="page-title">{t("Plugins")}</h2>
        <div className="page-content-wrapper">
          {plugins.map(plugin => {
            return (
              <div key={plugin.name} className="page-content-block plugin">
                <span className="plugin-logo">
                  <img
                    src={"../content/images/plugins/" + plugin.logo}
                    alt={plugin.name}
                    width="100"
                  />
                </span>
                <div className="plugin-info">
                  <h3 className="plugin-name">{plugin.name}</h3>
                  <p>{plugin.info}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect()(Settings_Payments);
