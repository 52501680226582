import React from 'react';
import Icons from '../../../../../icons';
import classes from './ActionButtonGroup.module.scss';
import { t } from '../../../../../../system/ui';
import { actionButtonConstants } from './constants';

export const ActionButtonGroup = ({ setFileName, fileName }) => {
  return (
    <div className={classes['flex-container']}>
      {actionButtonConstants.map(button => {
        return (
          <button
            key={button.name}
            type='button'
            className={
              classes[
                `action-button${fileName !== button.name ? '' : '--active'}`
              ]
            }
            onClick={() => setFileName(button.name)}
          >
            <Icons name={button.icon} height='24' width='24' />
            <span className={classes['file-name']}>{t(button.text)}</span>
          </button>
        );
      })}
    </div>
  );
};
