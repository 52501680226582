import { getNow, getFirstDayOfWeek, queryDate } from '../../system/date';

const defaultTotalsTrendifyLabels = {
  totalBasketProducts: 'Baskets: ',
  totalBaskets: 'Basket Products: ',
  totalBasketsAmount: 'Baskets Amount: ',
  // totalPurchasedProducts: 'Purchases: ',
  totalPurchases: 'Purchased Products: ',
  totalPurchasesAmount: 'Purchases Amount: ',
};

const dummyDefaultTotalsTrendify = {
  totalBasketProducts: 0,
  totalBaskets: 0,
  totalBasketsAmount: 0,
  totalPurchasedProducts: 0,
  totalPurchases: 0,
  totalPurchasesAmount: 0,
};

const defaultTrendifyTotals = [
  {
    title: 'Website',
    titleColor: '#525252',
    totalViews: 'Views: ',
    ...defaultTotalsTrendifyLabels,
  },
  {
    title: 'Segmentify',
    titleColor: '#AB3636',
    totalClick: 'Clicks: ',
    ...defaultTotalsTrendifyLabels,
  },
  {
    title: 'Sources',
    icons: true,
    titleColor: '#1A8391',
    totalEmailClick: 'newsletter',
    totalInternalClick: 'click',
    totalListingClick: 'bullet-list',
    totalOtherClick: 'other-sources',
    totalPaidClick: 'paid',
    totalSearchClick: 'magnify',
    totalSocialClick: 'share',
  },
];

const dummyDefaultTrendifyTotals = [
  {
    totalViews: 0,
    ...dummyDefaultTotalsTrendify,
  },
  {
    totalClick: 0,
    ...dummyDefaultTotalsTrendify,
  },
  {
    totalInternalClick: 0,
    totalSocialClick: 0,
    totalSearchClick: 0,
    totalPaidClick: 0,
    totalEmailClick: 0,
    totalListingClick: 0,
    totalOtherClick: 0,
  },
];

const trendifyTable = {
  header: [
    { text: '#', className: 'record-id', alignment: 'left' },
    { icon: 'stock', tooltip: 'Product Stock Status', alignment: 'left' },
    {
      text: 'Check-in',
      sortable: true,
      sort: "record->>'insertTime'",
      tooltip: 'Product Check-in Time',
      className: 'check-in',
      alignment: 'left',
    },
    {
      text: 'ID',
      sortable: true,
      sort: "record->>'productId'",
      tooltip: 'Product ID',
    },
    {
      text: 'Product',
      sortable: true,
      sort: "record->>'name'",
      tooltip: 'Product Name',
    },
    { text: 'Categories', tooltip: 'Product Categories' },
    {
      text: 'Price',
      sortable: true,
      sort: "record->>'price'",
      tooltip: 'Product Price and Discounted Price',
    },
    {
      text: 'Brand',
      sortable: true,
      sort: "record->>'brand'",
      tooltip: 'Product Brand',
    },
    {
      text: 'Image',
      tooltip: 'Product Image Url',
      className: 'analytics-th-image add-border',
    },
    {
      text: 'View',
      sortable: true,
      sort: "stats->>'allSiteView'",
      tooltip: 'Number Of Product Views',
    },
    {
      text: 'Basket Products',
      sortable: true,
      sort: "stats->>'allSiteBasket'",
      tooltip: 'Number Of Add To Basket Products',
    },
    {
      text: 'Baskets',
      sortable: true,
      sort: "stats->>'allSiteUBasket'",
      tooltip: 'Number Of Add To Basket Operations',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSiteBasket'/stats->>'allSiteView'",
      tooltip: 'Conversion Rate (Add To Basket/View)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSiteBamount'",
      tooltip: 'Amount of Products added to Basket',
    },
    {
      text: 'Purchased Products',
      sortable: true,
      sort: "stats->>'allSitePurchase'",
      tooltip: 'Number Of Purchased Products',
    },
    {
      text: 'Purchases',
      sortable: true,
      sort: "stats->>'allSiteUPurchase'",
      tooltip: 'Number Of Purchases',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSitePurchase'/stats->>'allSiteView'",
      tooltip: 'Conversion Rate (Purchase/View)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSiteAmount'",
      tooltip: 'Amount Of Product Purchases',
      className: 'add-border',
    },
    {
      text: 'Click',
      sortable: true,
      sort: "stats->>'allSgmClick'",
      tooltip: 'Number Of Clicks By Segmentify Recommendation Widgets',
    },
    {
      text: 'Basket Products',
      sortable: true,
      sort: "stats->>'allSgmBasket'",
      tooltip:
        'Number Of Add To Basket Products By Segmentify Recommendation Widgets',
    },
    {
      text: 'Baskets',
      sortable: true,
      sort: "stats->>'allSgmUBasket'",
      tooltip:
        'Number Of Add To Basket Operations By Segmentify Recommendation Widgets',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSgmBasket'/stats->>'allSgmClick'",
      tooltip: 'Conversion Rate (Add To Basket/Click)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSgmBamount'",
      tooltip: 'Amount of Products added to Basket',
    },
    {
      text: 'Purchased Products',
      sortable: true,
      sort: "stats->>'allSgmPurchase'",
      tooltip:
        'Number Of Purchased Products By Segmentify Recommendation Widgets',
    },
    {
      text: 'Purchases',
      sortable: true,
      sort: "stats->>'allSgmUPurchase'",
      tooltip: 'Number Of Purchases By Segmentify Recommendation Widgets',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSgmPurchase'/stats->>'allSgmClick'",
      tooltip: 'Conversion Rate (Purchase/Click)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSgmAmount'",
      tooltip: 'Amount Of Purchases By Segmentify Recommendation Widgets',
      className: 'add-border',
    },
    {
      icon: 'click',
      sortable: true,
      sort: "stats->>'allInternalClick'",
      tooltip: 'Views Referred From In-site',
      isSourceStart: true,
    },
    {
      icon: 'share',
      sortable: true,
      sort: "stats->>'allSocialClick'",
      tooltip: 'Views Referred From Social Media',
    },
    {
      icon: 'magnify',
      sortable: true,
      sort: "stats->>'allSearchClick'",
      tooltip: 'Views Referred From Search Engines',
      alignment: 'right',
    },
    {
      icon: 'paid',
      sortable: true,
      sort: "stats->>'allPaidClick'",
      tooltip: 'Views Referred From Advertisements',
      alignment: 'right',
    },
    {
      icon: 'newsletter',
      sortable: true,
      sort: "stats->>'allEmailClick'",
      tooltip: 'Views Referred From Email Campaigns',
      alignment: 'right',
    },
    {
      icon: 'bullet-list',
      sortable: true,
      sort: "stats->>'allListingClick'",
      tooltip: 'Views Referred From Price Listing Sites',
      alignment: 'right',
    },
    {
      icon: 'other-sources',
      sortable: true,
      sort: "stats->>'allOtherClick'",
      tooltip: 'Views Referred From Other Sources',
      alignment: 'right',
    },
    {
      sortable: false,
    },
  ],
  headerWithoutSgf: [
    { text: '#', className: 'record-id', alignment: 'left' },
    { icon: 'stock', tooltip: 'Product Stock Status', alignment: 'left' },
    {
      text: 'Check-in',
      sortable: true,
      sort: "record->>'insertTime'",
      tooltip: 'Product Check-in Time',
      className: 'check-in',
      alignment: 'left',
    },
    {
      text: 'ID',
      sortable: true,
      sort: "record->>'productId'",
      tooltip: 'Product ID',
    },
    {
      text: 'Product',
      sortable: true,
      sort: "record->>'name'",
      tooltip: 'Product Name',
    },
    { text: 'Categories', tooltip: 'Product Categories' },
    {
      text: 'Price',
      sortable: true,
      sort: "record->>'price'",
      tooltip: 'Product Price and Discounted Price',
    },
    {
      text: 'Brand',
      sortable: true,
      sort: "record->>'brand'",
      tooltip: 'Product Brand',
    },
    {
      text: 'Image',
      tooltip: 'Product Image Url',
      className: 'analytics-th-image add-border',
    },
    {
      text: 'View',
      sortable: true,
      sort: "stats->>'allSiteView'",
      tooltip: 'Number Of Product Views',
    },
    {
      text: 'Basket Products',
      sortable: true,
      sort: "stats->>'allSiteBasket'",
      tooltip:
        'Number Of Add To Basket Products By Segmentify Recommendation Widgets',
    },
    {
      text: 'Baskets',
      sortable: true,
      sort: "stats->>'allSiteUBasket'",
      tooltip:
        'Number Of Add To Basket Operations By Segmentify Recommendation Widgets',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSiteBasket'/stats->>'allSiteView'",
      tooltip: 'Conversion Rate (Add To Basket/View)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSiteBamount'",
      tooltip: 'Amount of Products added to Basket',
    },
    {
      text: 'Purchased Products',
      sortable: true,
      sort: "stats->>'allSitePurchase'",
      tooltip:
        'Number Of Purchased Products By Segmentify Recommendation Widgets',
    },
    {
      text: 'Purchases',
      sortable: true,
      sort: "stats->>'allSiteUPurchase'",
      tooltip: 'Number Of Purchases By Segmentify Recommendation Widgets',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSitePurchase'/stats->>'allSiteView'",
      tooltip: 'Conversion Rate (Purchase/View)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSiteAmount'",
      tooltip: 'Amount Of Product Purchases',
      className: 'add-border',
    },
    {
      icon: 'click',
      sortable: true,
      sort: "stats->>'allInternalClick'",
      tooltip: 'Views Referred From In-site',
      isSourceStart: true,
    },
    {
      icon: 'share',
      sortable: true,
      sort: "stats->>'allSocialClick'",
      tooltip: 'Views Referred From Social Media',
    },
    {
      icon: 'magnify',
      sortable: true,
      sort: "stats->>'allSearchClick'",
      tooltip: 'Views Referred From Search Engines',
      alignment: 'right',
    },
    {
      icon: 'paid',
      sortable: true,
      sort: "stats->>'allPaidClick'",
      tooltip: 'Views Referred From Advertisements',
      alignment: 'right',
    },
    {
      icon: 'newsletter',
      sortable: true,
      sort: "stats->>'allEmailClick'",
      tooltip: 'Views Referred From Email Campaigns',
      alignment: 'right',
    },
    {
      icon: 'bullet-list',
      sortable: true,
      sort: "stats->>'allListingClick'",
      tooltip: 'Views Referred From Price Listing Sites',
      alignment: 'right',
    },
    {
      icon: 'other-sources',
      sortable: true,
      sort: "stats->>'allOtherClick'",
      tooltip: 'Views Referred From Other Sources',
      alignment: 'right',
    },
    {
      sortable: false,
    },
  ],
  totals: defaultTrendifyTotals,
};

const categoryTable = {
  header: [
    { text: '#', className: 'record-id', alignment: 'left' },
    {
      text: 'Category Name',
      sortable: true,
      sort: "record->>'name'",
      tooltip: 'Category Name',
      alignment: 'left',
    },
    {
      text: 'Visit Count',
      sortable: true,
      sort: "stats->>'allCatVisit'",
      tooltip: 'Number of visits of the category',
      alignment: 'left',
      className: 'analytics-tfy-ctgview add-border',
    },
    {
      text: 'View',
      sortable: true,
      sort: "stats->>'allSiteView'",
      tooltip: 'Number Of Product Views',
    },
    {
      text: 'Basket Products',
      sortable: true,
      sort: "stats->>'allSiteBasket'",
      tooltip: 'Number Of Add To Basket Products',
    },
    {
      text: 'Baskets',
      sortable: true,
      sort: "stats->>'allSiteUBasket'",
      tooltip: 'Number Of Add To Basket Operations',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSiteBasket'/stats->>'allSiteView'",
      tooltip: 'Conversion Rate (Add To Basket/View)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSiteBamount'",
      tooltip: 'Amount of Products added to Basket',
    },
    {
      text: 'Purchased Products',
      sortable: true,
      sort: "stats->>'allSitePurchase'",
      tooltip: 'Number Of Purchased Products',
    },
    {
      text: 'Purchases',
      sortable: true,
      sort: "stats->>'allSiteUPurchase'",
      tooltip: 'Number Of Purchases',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSitePurchase'/stats->>'allSiteView'",
      tooltip: 'Conversion Rate (Purchase/View)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSiteAmount'",
      tooltip: 'Amount Of Product Purchases',
      className: 'add-border',
    },
    {
      text: 'Click',
      sortable: true,
      sort: "stats->>'allSgmClick'",
      tooltip: 'Number Of Clicks By Segmentify Recommendation Widgets',
    },
    {
      text: 'Basket Products',
      sortable: true,
      sort: "stats->>'allSgmBasket'",
      tooltip:
        'Number Of Add To Basket Products By Segmentify Recommendation Widgets',
    },
    {
      text: 'Baskets',
      sortable: true,
      sort: "stats->>'allSgmUBasket'",
      tooltip:
        'Number Of Add To Basket Operations By Segmentify Recommendation Widgets',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSgmBasket'/stats->>'allSgmClick'",
      tooltip: 'Conversion Rate (Add To Basket/Click)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSgmBamount'",
      tooltip: 'Amount of Products added to Basket',
    },
    {
      text: 'Purchased Products',
      sortable: true,
      sort: "stats->>'allSgmPurchase'",
      tooltip:
        'Number Of Purchased Products By Segmentify Recommendation Widgets',
    },
    {
      text: 'Purchases',
      sortable: true,
      sort: "stats->>'allSgmUPurchase'",
      tooltip: 'Number Of Purchases By Segmentify Recommendation Widgets',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSgmPurchase'/stats->>'allSgmClick'",
      tooltip: 'Conversion Rate (Purchase/Click)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSgmAmount'",
      className: 'add-border',
      tooltip: 'Amount Of Purchases By Segmentify Recommendation Widgets',
    },
    {
      icon: 'click',
      sortable: true,
      sort: "stats->>'allInternalClick'",
      tooltip: 'Views Referred From In-site',
      isSourceStart: true,
    },
    {
      icon: 'share',
      sortable: true,
      sort: "stats->>'allSocialClick'",
      tooltip: 'Views Referred From Social Media',
    },
    {
      icon: 'magnify',
      sortable: true,
      sort: "stats->>'allSearchClick'",
      tooltip: 'Views Referred From Search Engines',
      alignment: 'right',
    },
    {
      icon: 'paid',
      sortable: true,
      sort: "stats->>'allPaidClick'",
      tooltip: 'Views Referred From Advertisements',
      alignment: 'right',
    },
    {
      icon: 'newsletter',
      sortable: true,
      sort: "stats->>'allEmailClick'",
      tooltip: 'Views Referred From Email Campaigns',
      alignment: 'right',
    },
    {
      icon: 'bullet-list',
      sortable: true,
      sort: "stats->>'allListingClick'",
      tooltip: 'Views Referred From Price Listing Sites',
      alignment: 'right',
    },
    {
      icon: 'other-sources',
      sortable: true,
      sort: "stats->>'allOtherClick'",
      tooltip: 'Views Referred From Other Sources',
      alignment: 'right',
    },
    {
      sortable: false,
    },
  ],
  headerWithoutSgf: [
    { text: '#', className: 'record-id', alignment: 'left' },
    {
      text: 'Category Name',
      sortable: true,
      sort: "record->>'name'",
      tooltip: 'Category Name',
      alignment: 'left',
    },
    {
      text: 'Visit Count',
      sortable: true,
      sort: "stats->>'allCatVisit'",
      tooltip: 'Number of visits of the category',
      alignment: 'left',
      className: 'analytics-tfy-ctgview add-border',
    },
    {
      text: 'View',
      sortable: true,
      sort: "stats->>'allSiteView'",
      tooltip: 'Number Of Product Views',
    },
    {
      text: 'Basket Products',
      sortable: true,
      sort: "stats->>'allSiteBasket'",
      tooltip: 'Number Of Add To Basket Products',
    },
    {
      text: 'Baskets',
      sortable: true,
      sort: "stats->>'allSiteUBasket'",
      tooltip: 'Number Of Add To Basket Operations',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSiteBasket'/stats->>'allSiteView'",
      tooltip: 'Conversion Rate (Add To Basket/View)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSiteBamount'",
      tooltip: 'Amount of Products added to Basket',
    },
    {
      text: 'Purchased Products',
      sortable: true,
      sort: "stats->>'allSitePurchase'",
      tooltip: 'Number Of Purchased Products',
    },
    {
      text: 'Purchases',
      sortable: true,
      sort: "stats->>'allSiteUPurchase'",
      tooltip: 'Number Of Purchases',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSitePurchase'/stats->>'allSiteView'",
      tooltip: 'Conversion Rate (Purchase/View)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSiteAmount'",
      tooltip: 'Amount Of Product Purchases',
      className: 'add-border',
    },
    {
      icon: 'click',
      sortable: true,
      sort: "stats->>'allInternalClick'",
      tooltip: 'Views Referred From In-site',
      isSourceStart: true,
    },
    {
      icon: 'share',
      sortable: true,
      sort: "stats->>'allSocialClick'",
      tooltip: 'Views Referred From Social Media',
    },
    {
      icon: 'magnify',
      sortable: true,
      sort: "stats->>'allSearchClick'",
      tooltip: 'Views Referred From Search Engines',
      alignment: 'right',
    },
    {
      icon: 'paid',
      sortable: true,
      sort: "stats->>'allPaidClick'",
      tooltip: 'Views Referred From Advertisements',
      alignment: 'right',
    },
    {
      icon: 'newsletter',
      sortable: true,
      sort: "stats->>'allEmailClick'",
      tooltip: 'Views Referred From Email Campaigns',
      alignment: 'right',
    },
    {
      icon: 'bullet-list',
      sortable: true,
      sort: "stats->>'allListingClick'",
      tooltip: 'Views Referred From Price Listing Sites',
      alignment: 'right',
    },
    {
      icon: 'other-sources',
      sortable: true,
      sort: "stats->>'allOtherClick'",
      tooltip: 'Views Referred From Other Sources',
      alignment: 'right',
    },
    {
      sortable: false,
    },
  ],
  totals: defaultTrendifyTotals,
};

const brandTable = {
  header: [
    { text: '#', className: 'record-id', alignment: 'left' },
    {
      text: 'Brand',
      sortable: true,
      sort: "record->>'name'",
      tooltip: 'Brand Name',
      alignment: 'left',
    },
    {
      text: 'Visit Count',
      sortable: true,
      sort: "stats->>'allBrandVisit'",
      tooltip: 'Number of visits of the brand',
      alignment: 'left',
      className: 'analytics-tfy-ctgview add-border',
    },
    {
      text: 'View',
      sortable: true,
      sort: "stats->>'allSiteView'",
      tooltip: 'Number Of Product Views',
    },
    {
      text: 'Basket Products',
      sortable: true,
      sort: "stats->>'allSiteBasket'",
      tooltip: 'Number Of Add To Basket Products',
    },
    {
      text: 'Baskets',
      sortable: true,
      sort: "stats->>'allSiteUBasket'",
      tooltip: 'Number Of Add To Basket Operations',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSiteBasket'/stats->>'allSiteView'",
      tooltip: 'Conversion Rate (Add To Basket/View)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSiteBamount'",
      tooltip: 'Amount of Products added to Basket',
    },
    {
      text: 'Purchased Products',
      sortable: true,
      sort: "stats->>'allSitePurchase'",
      tooltip: 'Number Of Purchased Products',
    },
    {
      text: 'Purchases',
      sortable: true,
      sort: "stats->>'allSiteUPurchase'",
      tooltip: 'Number Of Purchases',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSitePurchase'/stats->>'allSiteView'",
      tooltip: 'Conversion Rate (Purchase/View)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSiteAmount'",
      tooltip: 'Amount Of Product Purchases',
      className: 'add-border',
    },
    {
      text: 'Click',
      sortable: true,
      sort: "stats->>'allSgmClick'",
      tooltip: 'Number Of Clicks By Segmentify Recommendation Widgets',
    },
    {
      text: 'Basket Products',
      sortable: true,
      sort: "stats->>'allSgmBasket'",
      tooltip:
        'Number Of Add To Basket Products By Segmentify Recommendation Widgets',
    },
    {
      text: 'Baskets',
      sortable: true,
      sort: "stats->>'allSgmUBasket'",
      tooltip:
        'Number Of Add To Basket Operations By Segmentify Recommendation Widgets',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSgmBasket'/stats->>'allSgmClick'",
      tooltip: 'Conversion Rate (Add To Basket/Click)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSgmBamount'",
      tooltip: 'Amount of Products added to Basket',
    },
    {
      text: 'Purchased Products',
      sortable: true,
      sort: "stats->>'allSgmPurchase'",
      tooltip:
        'Number Of Purchased Products By Segmentify Recommendation Widgets',
    },
    {
      text: 'Purchases',
      sortable: true,
      sort: "stats->>'allSgmUPurchase'",
      tooltip: 'Number Of Purchases By Segmentify Recommendation Widgets',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSgmPurchase'/stats->>'allSgmClick'",
      tooltip: 'Conversion Rate (Purchase/Click)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSgmAmount'",
      tooltip: 'Amount Of Purchases By Segmentify Recommendation Widgets',
      className: 'add-border',
    },
    {
      icon: 'click',
      sortable: true,
      sort: "stats->>'allInternalClick'",
      tooltip: 'Views Referred From In-site',
      isSourceStart: true,
    },
    {
      icon: 'share',
      sortable: true,
      sort: "stats->>'allSocialClick'",
      tooltip: 'Views Referred From Social Media',
    },
    {
      icon: 'magnify',
      sortable: true,
      sort: "stats->>'allSearchClick'",
      tooltip: 'Views Referred From Search Engines',
      alignment: 'right',
    },
    {
      icon: 'paid',
      sortable: true,
      sort: "stats->>'allPaidClick'",
      tooltip: 'Views Referred From Advertisements',
      alignment: 'right',
    },
    {
      icon: 'newsletter',
      sortable: true,
      sort: "stats->>'allEmailClick'",
      tooltip: 'Views Referred From Email Campaigns',
      alignment: 'right',
    },
    {
      icon: 'bullet-list',
      sortable: true,
      sort: "stats->>'allListingClick'",
      tooltip: 'Views Referred From Price Listing Sites',
      alignment: 'right',
    },
    {
      icon: 'other-sources',
      sortable: true,
      sort: "stats->>'allOtherClick'",
      tooltip: 'Views Referred From Other Sources',
      alignment: 'right',
    },
    {
      sortable: false,
    },
  ],
  headerWithoutSgf: [
    { text: '#', className: 'record-id', alignment: 'left' },
    {
      text: 'Brand',
      sortable: true,
      sort: "record->>'name'",
      tooltip: 'Brand Name',
      alignment: 'left',
    },
    {
      text: 'Visit Count',
      sortable: true,
      sort: "stats->>'allBrandVisit'",
      tooltip: 'Number of visits of the brand',
      alignment: 'left',
      className: 'analytics-tfy-ctgview add-border',
    },
    {
      text: 'View',
      sortable: true,
      sort: "stats->>'allSiteView'",
      tooltip: 'Number Of Product Views',
    },
    {
      text: 'Basket Products',
      sortable: true,
      sort: "stats->>'allSiteBasket'",
      tooltip: 'Number Of Add To Basket Products',
    },
    {
      text: 'Baskets',
      sortable: true,
      sort: "stats->>'allSiteUBasket'",
      tooltip: 'Number Of Add To Basket Operations',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSiteBasket'/stats->>'allSiteView'",
      tooltip: 'Conversion Rate (Add To Basket/View)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSiteBamount'",
      tooltip: 'Amount of Products added to Basket',
    },
    {
      text: 'Purchased Products',
      sortable: true,
      sort: "stats->>'allSitePurchase'",
      tooltip: 'Number Of Purchased Products',
    },
    {
      text: 'Purchases',
      sortable: true,
      sort: "stats->>'allSiteUPurchase'",
      tooltip: 'Number Of Purchases',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allSitePurchase'/stats->>'allSiteView'",
      tooltip: 'Conversion Rate (Purchase/View)',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allSiteAmount'",
      tooltip: 'Amount Of Product Purchases',
      className: 'add-border',
    },
    {
      icon: 'click',
      sortable: true,
      sort: "stats->>'allInternalClick'",
      tooltip: 'Views Referred From In-site',
      isSourceStart: true,
    },
    {
      icon: 'share',
      sortable: true,
      sort: "stats->>'allSocialClick'",
      tooltip: 'Views Referred From Social Media',
    },
    {
      icon: 'magnify',
      sortable: true,
      sort: "stats->>'allSearchClick'",
      tooltip: 'Views Referred From Search Engines',
      alignment: 'right',
    },
    {
      icon: 'paid',
      sortable: true,
      sort: "stats->>'allPaidClick'",
      tooltip: 'Views Referred From Advertisements',
      alignment: 'right',
    },
    {
      icon: 'newsletter',
      sortable: true,
      sort: "stats->>'allEmailClick'",
      tooltip: 'Views Referred From Email Campaigns',
      alignment: 'right',
    },
    {
      icon: 'bullet-list',
      sortable: true,
      sort: "stats->>'allListingClick'",
      tooltip: 'Views Referred From Price Listing Sites',
      alignment: 'right',
    },
    {
      icon: 'other-sources',
      sortable: true,
      sort: "stats->>'allOtherClick'",
      tooltip: 'Views Referred From Other Sources',
      alignment: 'right',
    },
    {
      sortable: false,
    },
  ],
  totals: defaultTrendifyTotals,
};

const bannerifyTable = {
  header: [
    { text: '#' },
    { text: 'Group', tooltip: 'Banner Group' },
    {
      text: 'Order',
      sortable: true,
      sort: "record->>'order'",
      tooltip: 'Banner Order',
    },
    {
      text: 'Check-in',
      sortable: true,
      sort: "record->>'insertTime'",
      tooltip: 'Check-in Time of Banner',
    },
    {
      text: 'Last Update',
      sortable: true,
      sort: "record->>'udateTime'",
      tooltip: 'Last Update Time of Banner',
    },
    {
      text: 'Title',
      sortable: true,
      sort: "record->>'title'",
      tooltip: 'Banner Title',
    },
    { text: 'Image', tooltip: 'Banner Image Url' },
    { text: 'Targets', tooltip: 'Banner Target(s)', className: 'add-border' },
    {
      text: 'Views',
      sortable: true,
      sort: "stats->>'allImpression'",
      tooltip: 'Number Of Views',
    },
    {
      text: 'Click',
      sortable: true,
      sort: "stats->>'allClick'",
      tooltip: 'Number Of Clicks',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allClick'/stats->>'allImpression'",
      tooltip: 'Conversion Rate (Click/Views)',
    },
    {
      text: 'Product Views',
      sortable: true,
      sort: "stats->>'allProdView'",
      tooltip: 'Number Of Product Views',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allProdView'/stats->>'allImpression'",
      tooltip: 'Conversion Rate (Product View/Views)',
    },
    {
      text: 'Basket',
      sortable: true,
      sort: "stats->>'allBasket'",
      tooltip: 'Number Of Add To Basket Operations',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allBasket'/stats->>'allClick'",
      tooltip: 'Conversion Rate (Add To Basket/Click)',
    },
    {
      text: 'Purchase',
      sortable: true,
      sort: "stats->>'allPurchase'",
      tooltip: 'Number Of Purchases',
      alignment: 'right',
    },
    {
      text: 'Rate',
      sortable: true,
      sort: "stats->>'allPurchase'/stats->>'allClick'",
      tooltip: 'Conversion Rate (Purchase/Click)',
      alignment: 'right',
    },
    {
      text: 'Amount',
      sortable: true,
      sort: "stats->>'allAmount'",
      tooltip: 'Amount of Purchases',
    },
    {
      sortable: false,
    },
  ],
  totals: {
    title: 'Website',
    titleColor: '#525252',
    totalViews: 'Views',
    ...defaultTotalsTrendifyLabels,
  },
};

const salesDetailTable = {
  header: [
    { text: 'Image', tooltip: 'Product Image', alignment: 'left' },
    { text: 'Product', tooltip: 'Product Name', alignment: 'left' },
    { text: 'Categories', tooltip: 'Product Categories', alignment: 'left' },
    { text: 'Brand', tooltip: 'Product Brand' },
    { text: 'Stock', tooltip: 'Product Stock' },
    { text: 'Quantity', tooltip: 'Product Quantity' },
    { text: 'Price at Cart', tooltip: 'Product Price at Cart' },
    { text: 'Segmentify', tooltip: 'Segmentify' },
    { text: 'Campaign', tooltip: 'Campaign Name' },
    { text: 'Campaign Page', tooltip: 'Campaign Page Name' },
  ],
};

const bannerProductDetailTable = {
  header: [
    { text: 'Image', tooltip: 'Product Image' },
    { text: 'Product', tooltip: 'Product Name' },
    { text: 'Categories', tooltip: 'Product Categories' },
    { text: 'Brand', tooltip: 'Product Brand' },
    { text: 'Stock', tooltip: 'Product Stock' },
    { text: 'Quantity', tooltip: 'Product Quantity' },
    { text: 'Price at Cart', tooltip: 'Product Price at Cart' },
    { text: 'Purchase Time', tooltip: 'Purchase Time of Product' },
    { text: 'Segmentify', tooltip: 'Segmentify' },
  ],
};

const salesBannerDetailTable = {
  header: [
    { text: 'Order', tooltip: 'Order' },
    { text: 'Image', tooltip: 'Image' },
    { text: 'Title', tooltip: 'Title' },
    { text: 'Group', tooltip: 'Group' },
  ],
};

const bannerifyQuery = {
  aggregation: 'latest',
  endDate: queryDate(getNow()),
  group: '',
  reverse: false,
  sort: "record->>'order'",
  startDate: queryDate(getFirstDayOfWeek()),
  title: '',
  items: 250,
  page: 1,
};

const trendifyQuery = {
  items: 100,
  page: 1,
  sort: "stats->>'allSiteView'",
  startDate: queryDate(getFirstDayOfWeek()),
  endDate: queryDate(getNow()),
  product: '',
  category: [],
  brand: [],
  minPrice: '',
  maxPrice: '',
  reverse: true,
};

const categoryQuery = {
  items: 100,
  page: 1,
  sort: "stats->>'allSiteView'",
  startDate: queryDate(getFirstDayOfWeek()),
  endDate: queryDate(getNow()),
  category: '',
  reverse: true,
};

const brandQuery = {
  items: 100,
  page: 1,
  sort: "stats->>'allSiteView'",
  startDate: queryDate(getFirstDayOfWeek()),
  endDate: queryDate(getNow()),
  brand: '',
  reverse: true,
};

const trendifyFiltersQuery = {
  product: '',
  category: [],
  brand: [],
  minPrice: '',
  maxPrice: '',
  device: '',
};

export {
  trendifyTable,
  bannerifyTable,
  trendifyQuery,
  salesDetailTable,
  bannerProductDetailTable,
  salesBannerDetailTable,
  bannerifyQuery,
  categoryTable,
  categoryQuery,
  brandTable,
  brandQuery,
  trendifyFiltersQuery,
  dummyDefaultTrendifyTotals,
};
