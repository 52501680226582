import React from 'react';
import LastProductRecommendations from './recommendations/LastProductRecommendations';
import classes from './BrandsRecommendation.module.scss';

const BrandsRecommendation = ({
  lastProductRecommends,
  enumLastProductRecommendations,
}) => {
  return (
    <div className={classes['br-container']}>
      <LastProductRecommendations
        lastProductRecommends={lastProductRecommends}
        enumLastProductRecommendations={enumLastProductRecommendations}
      />
    </div>
  );
};

export default BrandsRecommendation;
