/**
 * Created by mehmetyurtar on 14/07/2017.
 */
import React from "react";

import { salesBannerDetailTable } from "../../constants/datamaps/analytics";

import { productUrl } from "../../modules/trendify/page";
import { reformatDate } from "../../system/date";
import Tooltip from "../../components/tooltip";

import TableHeadings from "../table/heading";
import { getSalesBanner } from "../../modules/sales/ajax";

class SalesBanners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      banners: []
    };

    this.getBannerDetails = this.getBannerDetails.bind(this);
  }

  componentDidMount() {
    this.getBannerDetails();
  }

  getBannerDetails() {
    let summaryBanners = this.props.banners;
    let salesDate = reformatDate(this.props.salesDate, "YYYY-MM-DD");
    getSalesBanner(salesDate, summaryBanners, response => {
      this.setState({ banners: response });
    });
  }

  render() {
    return (
      <div>
        <table className="data-table">
          <thead>
            <TableHeadings heading={salesBannerDetailTable.header} />
          </thead>
          <tbody>
            {this.state.banners.map(item => {
              return (
                <tr key={Math.random()}>
                  <td style={{ width: "100px" }}>{item.order}</td>
                  <td style={{ width: "200px" }}>
                    {item.image ? (
                      <Tooltip
                        content={productUrl(item.image)}
                        type="image"
                        placement="left"
                      >
                        <img src={productUrl(item.image)} height="40" />
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td style={{ width: "250px" }}>{item.title}</td>
                  <td style={{ width: "250px" }}>{item.group}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default SalesBanners;
