import React, { Component } from "react";
import { connect } from "react-redux";
import { uiActions} from "../../../actions";
import DropzoneComponent from "react-dropzone-component";
import { uploadFileWaitUrl } from "../../upload/ajax";
import { TextField } from "../../../components/fields";
import Icons from "../../../components/icons";
import Tooltip from "../../../components/tooltip";
import isEqual from "lodash/isEqual";

let dropzoneObj;
const componentConfig = { postUrl: "no-url" };
const djsConfig = {
  addRemoveLinks: false,
  autoProcessQueue: false,
  acceptedFiles: "image/jpeg,image/png",
  createImageThumbnails: false
};
const methods = ["URL", "Upload"];
class IconUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      name: "text",
      imgSrc: "",
      method: "URL",
      disabled: false
    };

    this.updateValue = this.updateValue.bind(this);
    this.setDropzoneHandlers = this.setDropzoneHandlers.bind(this);
    this.changeImage = this.changeImage.bind(this);
  }

  componentDidMount() {
      if (this.props.account && this.props.account.pushConfiguration) {
          let text = this.props.account.pushConfiguration.defaultIcon;
          this.updateValue(text);
      }
  }

  componentWillReceiveProps(newProps) {
      if (this.props.account && this.props.account.pushConfiguration) {
          let text = this.props.account.pushConfiguration.defaultIcon;
          if (!isEqual(this.state.imgSrc, newProps.account.pushConfiguration.defaultIcon)) {
              this.updateValue(text);
          }
      }
  }

  updateValue(newValue) {
      this.setState({
        imgSrc: newValue,
        fileName: newValue,
        method: "URL"
      });
  }

  triggerOnIconChanged() {
      this.props.onDefaultIconValueChange(this.state.imgSrc);
  }

  setDropzoneHandlers() {
    return {
      init: dropzone => {
          dropzoneObj = dropzone;
      },
      addedfile: file => {
        window.dispatchEvent(new Event("resize"));
        this.setState(
          {
            imgSrc: "/assets/images/loading.gif",
            fileName: "/assets/images/loading.gif"
          },
          () => {
            const reader = new FileReader();
            reader.onload = event => {
              let fileToUpload = dropzoneObj.getAcceptedFiles()[dropzoneObj.getAcceptedFiles().length - 1];
              let fileQuery = {};
              fileQuery.feedBackFile = fileToUpload;
              if (fileToUpload.size / (1024 * 1024) < 5) {
                uploadFileWaitUrl(fileQuery, 'PUSH', response => {
                  if (response) {
                    this.setState(
                      {
                        file: file,
                        imgSrc: response.replace(/['"]+/g, ""),
                        fileName: response.replace(/['"]+/g, "")
                      },
                      () => this.triggerOnIconChanged()
                    );
                  }
                });
              } else {
                this.setState(
                  {
                    imgSrc: "",
                    fileName: ""
                  },
                  () => {
                    uiActions.notifyRequestError({
                      statusText:
                        "File is too big to upload! Please try another file below 5MB"
                    });

                    this.triggerOnIconChanged();
                  }
                );
              }
            };
            reader.readAsDataURL(file);
          }
        );
      }
    };
  }

  changeImage(e) {
      this.setState({
        imgSrc: e.target.value.trim(),
        fileName: e.target.value.trim()
      });
      setTimeout(() => {
          this.triggerOnIconChanged();
      }, 500);
  }

  onSelectMethod(method, e) {
    e.preventDefault();
    this.setState(
      {
        method,
        imgSrc: this.state.fileName
      },
      () => {
        if (method === "URL") {
          this.setState({
            isURL: true
          });
        }
        this.triggerOnIconChanged();
      }
    );
  }

  render() {
    let imgComp;
    if (
      this.state.imgSrc &&
      (this.state.imgSrc.indexOf("http") > -1 ||
        this.state.imgSrc.indexOf("loading") > -1)
    ) {
      imgComp = (
        <img
          src={this.state.imgSrc}
          onError={e => (e.target.src = "/images/layer.png")}
          alt={this.state.fileName}
          onLoad={e => (e.target.style.display = "block")}
        />
      );
    } else {
      imgComp = <img src={"/images/layer.png"} />;
    }
    return (
      <li className="one-whole icon-with-upload-push-settings page-forms">
        {imgComp}
        <span className="item-label">
          {this.props.options.label}
          <span className="recommended-text" />
          {this.props.options.tooltip && (
            <span style={{ position: "absolute", top: "-3px" }}>
              <Tooltip
                content={this.props.options.tooltipContent}
                alignment="left"
              >
                <i className="icon-info" role="presentation">
                  <span className="for-screenreader-only" />
                </i>
              </Tooltip>
            </span>
          )}
          <React.Fragment>
              <span
                  style={{position: "absolute", top: "-2px", right: "-45px"}}
              >
                <Tooltip
                    content={
                      (
                          <span>
                        200x200 - jpg, png, gif, webp, ico, cur, bmp (Not
                        supported: svg or gif animations.)
                      </span>
                      )
                    }
                    alignment="right"
                >
                  <Icons
                      name="question"
                      width="16"
                      height="16"
                      color="#e7eef3"
                  />
                </Tooltip>
              </span>
              <span
                  style={{position: "absolute", top: "-2px", right: "-65px"}}
              >
                <Tooltip
                    content={
                      (
                          <span>The maximum file size is 5 MB</span>
                      )
                    }
                    alignment="right"
                >
                  <Icons
                      name="question"
                      width="16"
                      height="16"
                      color="#e7eef3"
                  />
                </Tooltip>
              </span>
            </React.Fragment>
        </span>
        <ul
          className="whitelist-radio"
          style={{
            "margin-top": "10px",
            "margin-left": "98px",
            opacity: this.state.disabled ? "0.3" : "1",
            pointerEvents: this.state.disabled ? "none" : "initial",
            paddingBottom: "4px"
          }}
        >
          {methods.map(method => {
            return (
                <li
                  onClick={this.onSelectMethod.bind(this, method)}
                  key={method}
                  style={{
                    display: "inline",
                    fontSize: "14px"
                  }}
                >
                  <Icons
                    name={
                      this.state.method === method
                        ? "radioSelected"
                        : "radioEmpty"
                    }
                    width="14"
                    height="14"
                  />
                  <span>{method}</span>
                </li>
              );
          })}
        </ul>
          {this.state.method === "URL" && (
                <TextField
                  name="image"
                  className="item-stacked one-whole icon-with-upload-input"
                  value={this.state.fileName}
                  onChange={this.changeImage}
                  validation={{ mustacheOrUrl: true }}
                  placeholder={this.props.options.placeholder}
                  isURL={this.state.isURL}
                  required={true}
                />
          )}
        {this.state.method === "Upload" && (
          <DropzoneComponent
            config={componentConfig}
            eventHandlers={this.setDropzoneHandlers()}
            djsConfig={djsConfig}
          >
            Upload
            <i className="icon-upload" />
          </DropzoneComponent>
        )}
      </li>
    );
  }
}

export default IconUpload;
