/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconArchive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            fill={this.state.color}
            className="svg-path"
            d="M96.88,66.25H403.13c18.38,0,30.63-12.25,30.63-30.62S421.5,5,403.13,5H96.88C78.5,5,66.25,17.25,66.25,35.63S78.5,66.25,96.88,66.25Z"
            transform="translate(-5 -5)"
          />
          <path
            fill={this.state.color}
            className="svg-path"
            d="M464.38,127.5H35.63C17.25,127.5,5,139.75,5,158.13V464.38C5,482.75,17.25,495,35.63,495H464.38c18.38,0,30.63-12.25,30.63-30.62V158.13C495,139.75,482.75,127.5,464.38,127.5ZM372.5,341.88h-245V219.38h61.25v61.25h122.5V219.38H372.5Z"
            transform="translate(-5 -5)"
          />
        </g>
      </svg>
    );
  }
}

export default IconArchive;
