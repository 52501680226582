import { EmailPageTypes } from '../constants/page-configuration-constants';

/**
 * @name PageConfigurator
 * @param PageType {string} - The page type to configure passed from router state
 * @constructor
 */

export const PageConfigurator = PageType => {
  let pageConfig = {};

  const upperCasePageType = PageType.toUpperCase();
  if (EmailPageTypes[upperCasePageType]) {
    pageConfig = EmailPageTypes[upperCasePageType];
  }

  return pageConfig;
};
