import React from 'react';

import { NameWithTooltip } from './name-with-tooltip';
import { SFYProgressBar } from './progress-bar';

import classes from './index.module.scss';

export const StatsContainer = ({ report }) => {
  const totalCount = report?.reduce((acc, { count }) => acc + count, 0);

  return (
    <>
      {report?.map(({ name, count, ratio }) => {
        return (
          <>
            <div className={classes.item} key={name}>
              <NameWithTooltip name={name} key={name} />
              <p className={classes.count}>{count}</p>
              <SFYProgressBar ratio={Number(ratio?.toFixed(2))} />
            </div>
          </>
        );
      })}
      <p className={classes.description}>
        <span className={classes.totalCount}>{totalCount}</span> Visitors are
        totally bounce backed.
      </p>
    </>
  );
};
