import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const spSavingOver = ({ width, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 19 21'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      fill='none'
    >
      <path
        d='M3 3V5H1.5C0.67157 5 0 5.67157 0 6.5V16C0 18.2091 1.79086 20 4 20H8.1683C7.9439 19.5266 7.9439 18.9734 8.1683 18.5H4C2.61929 18.5 1.5 17.3807 1.5 16V6.5H9.01V9.3321C9.4621 9.1191 9.9671 9 10.5 9H10.51V6.5H14.5V12.0251L16 10.5251V6.5C16 5.67157 15.3284 5 14.5 5H13V3C13 1.34315 11.6569 0 10 0C9.2317 0 8.5308 0.28885 8 0.7639C7.4692 0.28886 6.7684 0 6 0C4.34315 0 3 1.34315 3 3ZM7.5 3V5H4.5V3C4.5 2.17157 5.17157 1.5 6 1.5C6.8284 1.5 7.5 2.17157 7.5 3ZM11.5 5H9V3C9 2.66563 8.9453 2.34403 8.8444 2.04368C9.1195 1.71156 9.535 1.5 10 1.5C10.8284 1.5 11.5 2.17157 11.5 3V5ZM10.5 15C11.8807 15 13 13.8807 13 12.5C13 11.1193 11.8807 10 10.5 10C9.1193 10 8 11.1193 8 12.5C8 13.8807 9.1193 15 10.5 15ZM10.5 11.5C11.0523 11.5 11.5 11.9477 11.5 12.5C11.5 13.0523 11.0523 13.5 10.5 13.5C9.9477 13.5 9.5 13.0523 9.5 12.5C9.5 11.9477 9.9477 11.5 10.5 11.5ZM16.5 21C17.8807 21 19 19.8807 19 18.5C19 17.1193 17.8807 16 16.5 16C15.1193 16 14 17.1193 14 18.5C14 19.8807 15.1193 21 16.5 21ZM16.5 17.5C17.0523 17.5 17.5 17.9477 17.5 18.5C17.5 19.0523 17.0523 19.5 16.5 19.5C15.9477 19.5 15.5 19.0523 15.5 18.5C15.5 17.9477 15.9477 17.5 16.5 17.5ZM17.7803 12.2803C18.0732 11.9874 18.0732 11.5126 17.7803 11.2197C17.4874 10.9268 17.0126 10.9268 16.7197 11.2197L9.2197 18.7197C8.9268 19.0126 8.9268 19.4874 9.2197 19.7803C9.5126 20.0732 9.9874 20.0732 10.2803 19.7803L17.7803 12.2803Z'
        fill={color}
      />
    </svg>
  );
};

spSavingOver.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

spSavingOver.defaultProps = {
  width: '19px',
  height: '21px',
  color: '#5D5D61',
};

export default spSavingOver;
