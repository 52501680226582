/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";

import { t } from "../../system/ui";

import { dialogActions } from "../../actions";
import { pageTypes } from "../../constants/datamaps/wizard";

import { goTestCampaign, goLiveCampaign } from "../../modules/campaigns/ajax";

import Icon from "../icon";

class ExternalWidgetActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actions: [
        { name: "Report", icon: "pie-chart", action: "report" },
        { name: "Edit", icon: "gear", action: "edit", newPanelOnly: true },
        { name: "Go Test", action: "goTest" },
        { name: "Go Live", action: "goLive" }
      ]
    };

    this.handleClick = this.handleClick.bind(this);
    this.edit = this.edit.bind(this);
    this.report = this.report.bind(this);
    this.goTest = this.goTest.bind(this);
    this.goLive = this.goLive.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.statusConfirmed = this.statusConfirmed.bind(this);
  }

  handleClick(action) {
    this[action]();
  }

  edit() {
    let page;

    if (this.props.inputs.length > 0) {
      let value = this.props.inputs[0].value[0]
        .replace(" Page", "")
        .toLowerCase();
      page = pageTypes.indexOf(value) > -1 ? value : "custom";
    } else {
      if (this.props.templateId === "HUR_8") {
        page = "product";
      }

      if (this.props.templateId === "T16") {
        page = "basket";
      }
    }
    this.props.router.push({
      pathname:
        "/ext/widget/edit/" + page + "/fullWidth/" + this.props.instanceId
    });
  }

  report() {
    this.props.router.push({
      pathname: "/ext/reports/single/recommendation/" + this.props.instanceId
    });
  }

  goTest() {
    this.changeStatus(goTestCampaign, "test");
  }

  goLive() {
    this.changeStatus(goLiveCampaign, "live");
  }

  changeStatus(statusFunc, status) {
    dialogActions.openDialog({
      title: t("Attention!"),
      content: t("This action will immediately effect on your website"),
      onConfirm: () => {
        this.statusConfirmed(statusFunc, status);

        dialogActions.closeDialog();
      },
      onCancel: () => {
        dialogActions.closeDialog();
      }
    });
  }

  statusConfirmed(testOrLive, status) {
    testOrLive(this.props.instanceId, () => {
      this.props.changeStatus(this.props.instanceId, status);
    });
  }

  isLive() {
    return (
      (this.props.status === "ACTIVE" || this.props.status === "TEST") &&
      this.props.testMode === false
    );
  }

  render() {
    return (
      <nav className="widget-nav action-nav" aria-haspopup="true">
        <a className="secondary-action">{t("Actions")}</a>

        <ul className="widget-nav-popup action-nav-popup">
          {this.state.actions.map((action, index) => {
            if (
              this.props.actions &&
              this.props.actions[0] &&
              this.props.actions[0].params &&
              this.props.actions[0].params.actionType !== "INPAGE_WIDGET" &&
              this.props.actions[0].params.actionType !== "POPUP_RECO" &&
              action.newPanelOnly
            ) {
              return;
            }

            if (
              (action.name === "Go Test" && !this.isLive()) ||
              (action.name === "Go Live" && this.isLive())
            ) {
              return;
            }

            return (
              <li key={index}>
                <a
                  className="widget-nav-popup-item action-nav-popup-item"
                  onClick={this.handleClick.bind(null, action.action)}
                >
                  <Icon name={action.icon} /> {t(action.name)}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default ExternalWidgetActions;
