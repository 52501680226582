import React, {Component} from "react";
import {connect} from "react-redux";

import {uiActions} from "../../../actions";

import {TextField} from "../../../components/fields";
import {t} from "../../../system/ui";

class PushPermissionTitle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      name: "title"
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    let title = this.props.campaign[this.props.options.name];
    this.updateValue(title);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.title);
    }
  }

  updateValue(newValue) {
    this.setState({
      value: newValue
    });
  }

  onFieldChange(e) {
    let tempTitles = this.props.additionalTitles;
    tempTitles[this.props.selectedLanguage] = e.target.value;
    this.setState({
      additionalTitles: tempTitles
    });
    this.updateValue(e.target.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <li className="item-title-field">
        <TextField
          name={this.props.options.name}
          label={t(this.props.options.label)}
          className="item-stacked one-whole"
          value={this.props.additionalTitles[this.props.selectedLanguage] || ""}
          required={true}
          onChange={this.onFieldChange}
          tooltip={this.props.options.tooltip}
          tooltipText={this.props.options.tooltipContent}
          placeholder={this.props.options.placeholder}
        />
      </li>
    );
  }
}

PushPermissionTitle.defaultProps = {
  options: {
    label: "Title",
    name: "title",
    required: true,
    tooltip: false,
    tooltipContent: ""
  }
};

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, {forwardRef: true})(PushPermissionTitle);
