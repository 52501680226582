import React, { useState, useEffect } from 'react';
import { Calendar } from '../../fields';
import classes from './StartEndDateWithDropdown.module.scss';
import {
  isValidStartDateWithEndDate,
  isValidCampaignEndDate,
  validDayCannotBeAfterYesterday,
  printReverseFormatDate,
  // reformatDateByTimeZone,
} from '../../../system/date';
import { SFYScheduleDropdown } from '../module-exports';
import { t } from '../../../system/ui';

const StartEndDateWithDropdown = ({
  editCampaign,
  componentDetail,
  options,
  activeEmailDetails,
  error = '',
  readOnly = false,
}) => {
  const [selectedInterval, setSelectedInterval] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const filterObj = activeEmailDetails?.filters?.find(
      item => item.type === 'SEND_MAIL_SEGMENT',
    );
    if (filterObj?.startDate && filterObj?.endDate) {
      setSelectedInterval('between');
      editCampaign('interval', 'between', true, true);
    } else if (filterObj?.startDate) {
      setSelectedInterval('after');
      editCampaign('interval', 'after', true, true);
    } else if (filterObj?.endDate) {
      setSelectedInterval('before');
      editCampaign('interval', 'before', true, true);
    } else {
      setSelectedInterval('');
      editCampaign('interval', '', true, true);
    }
    setStartDate(filterObj?.startDate || null);
    setEndDate(filterObj?.endDate || null);
  }, []);

  const handleDateChange = (type, date) => {
    switch (type) {
      case 'startDate':
        setStartDate(printReverseFormatDate(date));
        editCampaign(type, printReverseFormatDate(date));
        break;
      case 'endDate':
        setEndDate(printReverseFormatDate(date));
        editCampaign(type, printReverseFormatDate(date));
        break;
      default:
        break;
    }
  };

  const handleIntervalChange = (type, interval) => {
    editCampaign('interval', interval, true);
    setSelectedInterval(interval);
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <label className={`${classes['sfy-advanced-wrapper']}`}>
      <>
        <SFYScheduleDropdown
          key={componentDetail.cardName}
          fieldValue={selectedInterval}
          componentDetail={componentDetail}
          options={options}
          editCampaign={handleIntervalChange}
          activeEmailDetails={activeEmailDetails}
          error={selectedInterval === '' && error !== '' ? error : ''}
          readOnly={readOnly}
        />
      </>
      {(selectedInterval === 'between' || selectedInterval === 'after') && (
        <div className={`${classes['sfy-calendar-wrapper']}`}>
          <span className={classes['sfy-label-wrapper']}>
            {selectedInterval === 'between' && (
              <span className={classes['sfy-label']}>{t('Start Date')}</span>
            )}
            {selectedInterval !== 'between' && (
              <span className={classes['sfy-top-margin']}>{t('')}</span>
            )}
          </span>
          <Calendar
            name='startDate'
            className={`${classes['sfy-calendar']}`}
            checkPastDate
            value={startDate}
            isValidDate={
              selectedInterval === 'between'
                ? current => {
                    return (
                      isValidStartDateWithEndDate(current, endDate) &&
                      validDayCannotBeAfterYesterday(current)
                    );
                  }
                : () => {
                    return true;
                  }
            }
            onChange={date => handleDateChange('startDate', date)}
            placeholder='dd/mm/yyyy'
            timeFormat={false}
            disabled={readOnly}
          />
          {!startDate && error !== '' && (
            <span className='item-error'>{t(error)}</span>
          )}
        </div>
      )}
      {(selectedInterval === 'between' || selectedInterval === 'before') && (
        <div className={`${classes['sfy-calendar-wrapper']}`}>
          <span className={classes['sfy-label-wrapper']}>
            {selectedInterval === 'between' && (
              <span className={classes['sfy-label']}>{t('End Date')}</span>
            )}
            {selectedInterval !== 'between' && (
              <span className={classes['sfy-top-margin']}>{t('')}</span>
            )}
          </span>
          <Calendar
            name='endDate'
            className={`${classes['sfy-calendar']}`}
            checkPastDate
            value={endDate}
            isValidDate={
              selectedInterval === 'between'
                ? current => {
                    return (
                      isValidCampaignEndDate(current, startDate) &&
                      validDayCannotBeAfterYesterday(current)
                    );
                  }
                : () => {
                    return true;
                  }
            }
            onChange={date => handleDateChange('endDate', date)}
            placeholder='dd/mm/yyyy'
            timeFormat={false}
            disabled={readOnly}
          />
          {!endDate && error !== '' && (
            <span className='item-error'>{t(error)}</span>
          )}
        </div>
      )}
    </label>
  );
};

export default StartEndDateWithDropdown;
