import React from 'react';
import Icons from '../../../../../components/icons';
import classes from './SortIconGroup.module.scss';

/**
 * @name SortIconGroup
 * @description
 * This component is responsible for rendering the sort icons
 * @param {String} sort - Sort type
 * @param {Boolean} isSorting - Boolean to check if the column is sorted
 * @returns JSX.Element - Sort Icon Group
 */

export const SortIconGroup = ({ sort, isSorting }) => {
  return (
    <span className={classes.icon_group}>
      <Icons
        name='SortUpIcon'
        color={sort && isSorting ? '#656565' : 'white'}
      />
      <Icons
        name='SortDownIcon'
        color={sort && !isSorting ? '#656565' : 'white'}
      />
    </span>
  );
};
