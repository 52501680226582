export const welcomeHtml = `<div class="sgm-journey-wrapper sgm-journey-[[step]]">
    <div class="sgm-journey-topbar sgm-journey-border">
        <div>
            <span class="sgm-journey-close"></span>
        </div>
    </div>
    <div class="sgm-journey-description sgm-journey-border">
        <p>
            [[#title]]
            <span>[[title]]</span>
            [[/title]]
        </p>
    </div>
    <div class="sgm-journey-action sgm-journey-border">
        <button class="sgm-journey-discovery-action" data-type="next">[[button.next]]</button>
    </div>
    <div class="sgm-journey-progress-bar">
     <label id="sgm-journey-progress-label"></label>
     <div class="sgm-journey-progress">
        <div id="sgm-journey-bar"></div>
     </div>
    </div>
</div>`;

export const mainCategoryHtml = `<div class="sgm-journey-wrapper sgm-journey-discovery sgm-journey-[[step]] sgm-journey-border">
    <div class="sgm-journey-topbar sgm-journey-border">
        <div>
            <span class="sgm-journey-close"></span>
        </div>
    </div>
    <div class="sgm-journey-description sgm-journey-border">
        <p>
            [[#title]]
            <span>[[title]]</span>
            [[/title]]
        </p>
    </div>
    <div class="sgm-journey-content sgm-journey-border">
        <div class="sgm-journey-content-main-category">
            <ul>
                [[#items]]
                <li class="sgm-journey-content-item" data-original-value="[[.]]">
                    <p><span>[[.]]</span></p>
                </li>
                [[/items]]
            </ul>
        </div>
    </div>
    <div class="sgm-journey-action sgm-journey-border">
        <button class="sgm-journey-discovery-prev-action" data-type="[[button.actionType]]">[[button.text]]</button>
    </div>
    <div class="sgm-journey-progress-bar">
     <label id="sgm-journey-progress-label"></label>
     <div class="sgm-journey-progress">
        <div id="sgm-journey-bar"></div>
     </div>
    </div>
</div>`;

export const categoryHtml = `<div class="sgm-journey-wrapper sgm-journey-discovery sgm-journey-[[step]] sgm-journey-border">
    <div class="sgm-journey-topbar sgm-journey-border">
        <div>
            <span class="sgm-journey-close"></span>
        </div>
    </div>
    <div class="sgm-journey-description sgm-journey-border">
        <p>
            [[#title]]
            <span>[[title]]</span>
            [[/title]]
        </p>
    </div>
    <div class="sgm-journey-content sgm-journey-border">
        <div class="sgm-journey-content-category">
            <ul>
                [[#items]]
                 <li class="sgm-journey-content-item" data-original-value="[[originalData]]">
                    <p><span>[[lastChild]]</span></p>
                </li>
                [[/items]]
            </ul>
        </div>
    </div>
    <div class="sgm-journey-action sgm-journey-border">
     <button class="sgm-journey-discovery-prev-action" data-type="[[button.actionType]]">[[button.text]]</button>
    </div>
    <div class="sgm-journey-progress-bar">
     <label id="sgm-journey-progress-label"></label>
     <div class="sgm-journey-progress">
        <div id="sgm-journey-bar"></div>
     </div>
    </div>
</div>`;

export const brandHtml = `<div class="sgm-journey-wrapper sgm-journey-discovery sgm-journey-[[step]] sgm-journey-border">
    <div class="sgm-journey-topbar sgm-journey-border">
        <div>
            <span class="sgm-journey-close"></span>
        </div>
    </div>
    <div class="sgm-journey-description sgm-journey-border">
        <p>
            [[#title]]
            <span>[[title]]</span>
            [[/title]]
        </p>
    </div>
    <div class="sgm-journey-content sgm-journey-border">
        <div class="sgm-journey-content-brand sgm-journey-content-grid-two">
            <ul>
                [[#items]]
                <li class="sgm-journey-content-item" data-original-value="[[.]]">
                    <p><span>[[.]]</span></p>
                </li>
                [[/items]]
            </ul>
        </div>
    </div>
    <div class="sgm-journey-action sgm-journey-border">
      <button class="sgm-journey-discovery-prev-action" data-type="[[button.actionType]]">[[button.text]]</button>
      <button class="sgm-journey-discovery-action" data-type="[[button.actionType]]">[[button.text]]</button>
    </div>
    <div class="sgm-journey-progress-bar">
     <label id="sgm-journey-progress-label"></label>
     <div class="sgm-journey-progress">
        <div id="sgm-journey-bar"></div>
     </div>
    </div>
</div>`;

export const sizeHtml = `<div class="sgm-journey-wrapper sgm-journey-discovery sgm-journey-[[step]] sgm-journey-border">
    <div class="sgm-journey-topbar sgm-journey-border">
        <div>
            <span class="sgm-journey-close"></span>
        </div>
    </div>
    <div class="sgm-journey-description sgm-journey-border">
        <p>
            [[#title]]
            <span>[[title]]</span>
            [[/title]]
        </p>
    </div>
    <div class="sgm-journey-content sgm-journey-border">
        <div class="sgm-journey-content-size sgm-journey-content-grid-two">
            <ul>
                [[#items]]
                <li class="sgm-journey-content-item" data-original-value="[[.]]">
                    <p><span>[[.]]</span></p>
                </li>
                [[/items]]
            </ul>
        </div>
    </div>
    <div class="sgm-journey-action sgm-journey-border">
      <button class="sgm-journey-discovery-prev-action" data-type="[[button.actionType]]">[[button.text]]</button>
      <button class="sgm-journey-discovery-action" data-type="[[button.actionType]]">[[button.text]]</button>
    </div>
    <div class="sgm-journey-progress-bar">
     <label id="sgm-journey-progress-label"></label>
     <div class="sgm-journey-progress">
        <div id="sgm-journey-bar"></div>
     </div>
    </div>
</div>`;

export const colorHtml = `<div class="sgm-journey-wrapper sgm-journey-discovery sgm-journey-[[step]] sgm-journey-border">
    <div class="sgm-journey-topbar sgm-journey-border">
        <div>
            <span class="sgm-journey-close"></span>
        </div>
    </div>
    <div class="sgm-journey-description sgm-journey-border">
        <p>
            [[#title]]
            <span>[[title]]</span>
            [[/title]]
        </p>
    </div>
    <div class="sgm-journey-content sgm-journey-border">
\t\t<div class="sgm-journey-content-color">
            <ul>
                [[#items]]
                <li class="sgm-journey-content-item" data-original-value="[[.]]">
                \t<span></span>
                    <label>[[.]]</label>
                </li>
                [[/items]]
            </ul>
        </div>
    </div>
    <div class="sgm-journey-action sgm-journey-border">
      <button class="sgm-journey-discovery-prev-action" data-type="[[button.actionType]]">[[button.text]]</button>
      <button class="sgm-journey-discovery-action" data-type="[[button.actionType]]">[[button.text]]</button>
    </div>
    <div class="sgm-journey-progress-bar">
     <label id="sgm-journey-progress-label"></label>
     <div class="sgm-journey-progress">
        <div id="sgm-journey-bar"></div>
     </div>
    </div>
</div>`;

export const priceRangeHtml = `<div class="sgm-journey-wrapper sgm-journey-discovery sgm-journey-[[step]] sgm-journey-border">
    <div class="sgm-journey-topbar sgm-journey-border">
        <div>
            <span class="sgm-journey-close"></span>
        </div>
    </div>
    <div class="sgm-journey-description sgm-journey-border">
        <p>
            [[#title]]
            <span>[[title]]</span>
            [[/title]]
        </p>
    </div>
    <div class="sgm-journey-content sgm-journey-border">
        <div class="sgm-journey-content-price-range-select">
            <ul>
                [[#items]]
                <li class="sgm-journey-content-item" data-original-value="[[.]]">
                    <p><span>[[currencyCode]]</span> <span>[[.]]</span></p>
                </li>
                [[/items]]
                <li class="sgm-journey-content-item sgm-journey-content-item-custom sgm-journey-none" data-original-value="-">
            </ul>
\t        <a href="#" class="sgm-journey-price-range-customize-button">[[button.customize]]</a>
        </div>
      
      \t<div class="sgm-journey-content-price-range-custom sgm-journey-none">
            <div id="sgm-journey-slider">
                <div>
                    <div class="sgm-journey-slider-inverse sgm-journey-slider-inverse-left"></div>
                    <div class="sgm-journey-slider-inverse sgm-journey-slider-inverse-right"></div>

                    <div class="sgm-journey-slider-range-fill"></div>

                    <span class="sgm-journey-slider-range-point sgm-journey-slider-range-point-min"></span>
                    <span class="sgm-journey-slider-range-point sgm-journey-slider-range-point-max"></span>

                    <div class="sgm-journey-slider-range-point-value">
                        <span id="sgm-journey-slider-range-point-value-min"></span>
                    </div>
                    <div class="sgm-journey-slider-range-point-value">
                        <span id="sgm-journey-slider-range-point-value-max"></span>
                    </div>
                </div>
                <input id="sgm-journey-slider-range-input-value-min" type="range" tabindex="0" max="500" min="0" step="1" />
                <input id="sgm-journey-slider-range-input-value-max" type="range" tabindex="0" max="500" min="0" step="1" />
            </div>
            <div class="sgm-journey-content-price-range-custom-input" style="align-items: center; display: inline-block;">
                <input id="price-range-min" readonly>
                <input id="price-range-max" readonly>
            </div>

        </div>
    </div>
    <div class="sgm-journey-action sgm-journey-border">
      <button class="sgm-journey-discovery-prev-action" data-type="[[button.actionType]]">[[button.text]]</button>
      <button class="sgm-journey-discovery-action" data-type="[[button.actionType]]">[[button.text]]</button>
    </div>
    <div class="sgm-journey-progress-bar">
     <label id="sgm-journey-progress-label"></label>
     <div class="sgm-journey-progress">
        <div id="sgm-journey-bar"></div>
     </div>
    </div>
</div>`;

export const preJs = `// this function will get a configuration object to be executed at html rendering with mustache.js do not change the function name
function preRenderConf(settings) {
                        
     _SgmntfY_._variables.journey.buttonTextMap = {
          EN: {
            copy: "Copy",
            next: "Next",
            previous: "Go Back",
            close: "Close",
            skip: "En Skip",
            finish: "Finish",
            start: "Let's Start",
            customize: "Customize",
            skipAndFinish: "Skip & Finish"
          },
          TR: {
            copy: "Kopyala",
            next: "Sonraki",
            previous: "Geri Dön",
            close: "Kapat",
            skip: "Geçelim",
            finish: "Bitir",
            start: "Hadi Başlayalım",
            customize: "Özelleştir",
            skipAndFinish: "Atla ve Bitir"
          }
        }
}`;
