import { searchDefaultOrder } from "../../constants/datamaps/palantir";

/**
 * @property {string} instanceId
 * @property {string} accountId
 * @property {string} name
 * @property {string} status
 * @property {object[]} customContents
 * @property {string} customContents.key
 * @property {string} customContents.html
 * @property {object} limitations
 * @property {number} limitations.startDate
 * @property {number} limitations.endDate
 * @property {string[]} limitations.includeUrls
 * @property {string[]} limitations.excludeUrls
 * @property {string[]} devices
 * @property {number} mobilePageSize
 * @property {number} desktopPageSize
 * @property {object[]} mobileFacetDefs
 * @property {string} mobileFacetDefs.property
 * @property {number} mobileFacetDefs.limit
 * @property {string} mobileFacetDefs.viewMode
 * @property {boolean} mobileFacetDefs.enabled
 * @property {string[]} mobileFacetDefs.exclusions
 * @property {object[]} desktopFaceDefs
 * @property {string} desktopFacetDefs.property
 * @property {number} desktopFacetDefs.limit
 * @property {string} desktopFacetDefs.viewMode
 * @property {boolean} desktopFacetDefs.enabled
 * @property {string[]} desktopFacetDefs.exclusions
 * @property {object} template
 * @property {object} template.*
 * @property {object} template.*.template
 * @property {string} template.*.template.html
 * @property {string} template.*.template.css
 * @property {string} template.*.template.preJs
 * @property {string} template.*.template.postJs
 * @property {object[]} orders
 * @property {string} orders.key
 * @property {boolean} orders.default
 * @property {string} languageConfiguration
 */

export const DEFAULT_FACETS = [
  {
    property: "category",
    viewMode: "treeview",
    limit: -1,
    enabled: false,
    exclusions: [],
    sort: "COUNT"
  },
  {
    property: "brand",
    viewMode: "picklist",
    limit: -1,
    enabled: false,
    exclusions: [],
    sort: "COUNT"
  },
  {
    property: "sizes",
    viewMode: "picklist",
    limit: -1,
    enabled: false,
    exclusions: [],
    sort: "COUNT"
  },
  {
    property: "colors",
    viewMode: "picklist",
    limit: -1,
    enabled: false,
    exclusions: [],
    sort: "COUNT"
  },
  {
    property: "price",
    viewMode: "sliding",
    limit: -1,
    enabled: false,
    exclusions: [],
    sort: "COUNT"
  },
  {
    property: "gender",
    viewMode: "picklist",
    limit: -1,
    enabled: false,
    exclusions: [],
    sort: "COUNT"
  },
  {
    property: "badges",
    viewMode: "picklist",
    limit: -1,
    enabled: false,
    exclusions: [],
    sort: "COUNT"
  }
];
export const campaignDefaults = {
  instanceId: "",
  accountId: "",
  name: "Searchandising",
  status: "ACTIVE", // DRAFT
  devices: ["PC", "MOBILE", "TABLET", "ANDROID", "IOS"],
  service: "default",
  collection: "",
  limitations: {
    startDate: new Date().getTime(),
    endDate: null,
    includeUrls: [],
    excludeUrls: []
  },
  mobilePageSize: 30,
  desktopPageSize: 60,
  mobileFacetDefs: DEFAULT_FACETS,
  desktopFacetDefs: DEFAULT_FACETS,
  template: {},
  orders: searchDefaultOrder,
  customContents: [],
  languageConfigurationJson: "",
  type: "faceted"
};

export default {
  addFacetModal: {
    isOpen: false
  },
  ui: {
    loading: false,
    loaded: false,
    error: null,
    saveCampaign: {
      loading: false,
      loaded: false,
      error: null
    }
  },
  campaignForm: campaignDefaults,
  facetList: {
    loading: false,
    loaded: false,
    error: null,
    data: []
  },
  orderList: {
    loading: false,
    loaded: false,
    error: null,
    data: []
  },
  campaigns: [],
  banners: [],
  bannersCopy: [],
  singleReport: {
    loading: false,
    loaded: false,
    error: null,
    data: {},
    totalRevenue: {}
  },
  allReportsSum: {
    loading: false,
    loaded: false,
    error: null,
    data: []
  }
};
