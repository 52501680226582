/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { uiActions, wizardActions } from "../../../actions";

import { TextField } from "../../../components/fields";

class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      name: "input"
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    let input = this.props.campaign.input || "";
    this.updateValue(input);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.input);
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  onFieldChange(e) {
    this.updateValue(e.target.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <li className="item-title-field">
        <TextField
          name="input"
          label={
            this.props.options.label !== undefined
              ? this.props.options.label
              : "Form Field"
          }
          className="item-stacked one-whole"
          value={this.state.value}
          required={true}
          onChange={this.onFieldChange}
        />
      </li>
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(Input);
