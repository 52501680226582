/**
 * @author Bilal Cinarli
 */

"use strict";

import { createConstants } from "../../system/store";

export default createConstants(
  "UPDATE_FILTERED",
  "FILTERED_UPDATED",
  "RESET_FILTERED",
  "FILTERED_RESET",
  "UPDATE_PAGESIZE",
  "UPDATE_PAGENUMBER",
  "UPDATE_COUNT",
  "UPDATE_TRENDIFY_CATEGORIES",
  "UPDATE_TRENDIFY_BRANDS",
  "UPDATE_BANNERIFY_GROUPS",
  "UPDATE_BANNERIFY_TITLES",
  "SHOW_RESET_BUTTON",
  "HIDE_RESET_BUTTON",
  "ENABLE_BUTTONS",
  "DISABLE_BUTTONS"
);
