import { t } from "../../../system/ui";

export const breadCrumbOptions = {
  home: {
    name: t("Customer Data Management"),
    icon: "audienceIcon",
    slug: "/audience/segmentation/view-all",
    iconSvg: true
  },
  current: {
    name: "Rule Based Segmentation"
  }
};
