import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const WofCta = ({ width = '91px', height = '73px' }) => {
  const [state, setState] = useState({
    width: '',
    height: '',
  });

  const setStyles = () => {
    setState({
      width,
      height,
    });
  };

  useEffect(() => {
    setStyles();
  }, [width, height]);

  return (
    <svg
      width={state.width}
      height={state.height}
      viewBox='0 0 91 73'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='90'
        height='72'
        rx='9.5'
        fill='white'
        stroke='#D1D1D1'
      />
      <path
        d='M41.6542 60.662C41.1521 60.662 40.6978 60.5519 40.2912 60.3317C39.8895 60.1067 39.5739 59.8004 39.3443 59.4127C39.1148 59.0202 39 58.5798 39 58.0915C39 57.6033 39.1148 57.1653 39.3443 56.7776C39.5739 56.3851 39.8919 56.0788 40.2984 55.8586C40.7049 55.6336 41.1593 55.5211 41.6614 55.5211C42.0536 55.5211 42.4122 55.5881 42.7374 55.7222C43.0626 55.8514 43.34 56.0453 43.5696 56.3037L43.1033 56.7561C42.7255 56.3588 42.2544 56.1601 41.6901 56.1601C41.3171 56.1601 40.9799 56.2439 40.6786 56.4114C40.3773 56.579 40.1406 56.8111 39.9684 57.1079C39.8011 57.3999 39.7174 57.7278 39.7174 58.0915C39.7174 58.4553 39.8011 58.7856 39.9684 59.0824C40.1406 59.3744 40.3773 59.6041 40.6786 59.7717C40.9799 59.9392 41.3171 60.023 41.6901 60.023C42.2496 60.023 42.7207 59.8219 43.1033 59.4198L43.5696 59.8722C43.34 60.1307 43.0603 60.3269 42.7303 60.4609C42.4051 60.595 42.0464 60.662 41.6542 60.662Z'
        fill='white'
      />
      <path
        d='M45.534 56.2032H43.8124V55.5786H47.9659V56.2032H46.2442V60.6045H45.534V56.2032Z'
        fill='white'
      />
      <path
        d='M51.6944 59.348H49.0258L48.4735 60.6045H47.7346L50.0086 55.5786H50.7188L53 60.6045H52.2468L51.6944 59.348ZM51.4433 58.7736L50.3601 56.3109L49.2769 58.7736H51.4433Z'
        fill='white'
      />
      <rect
        x='27.7646'
        y='12'
        width='40.1176'
        height='3'
        rx='1.5'
        fill='#D9D9D9'
      />
      <rect x='15' y='18' width='62' height='3' rx='1.5' fill='#D9D9D9' />
      <rect x='15' y='27' width='62' height='17' rx='3' fill='#F0F0F0' />
      <rect
        x='22'
        y='51.623'
        width='48'
        height='12.0638'
        rx='5'
        fill='#CC4230'
      />
      <path
        d='M32.2227 55.6678V60.1563H31.6425V59.3371C31.4832 59.6102 31.2641 59.8235 30.9854 59.9771C30.7066 60.125 30.3881 60.199 30.0297 60.199C29.4437 60.199 28.9801 60.0369 28.6387 59.7126C28.3031 59.3826 28.1353 58.9019 28.1353 58.2705V55.6678H28.7411V58.2107C28.7411 58.6829 28.8577 59.0413 29.091 59.2859C29.3242 59.5305 29.657 59.6529 30.0894 59.6529C30.5616 59.6529 30.9342 59.5106 31.2073 59.2262C31.4803 58.9361 31.6169 58.535 31.6169 58.023V55.6678H32.2227Z'
        fill='white'
      />
      <path
        d='M35.1228 60.199C34.7587 60.199 34.4088 60.1478 34.0732 60.0454C33.7432 59.9373 33.4844 59.8036 33.2967 59.6443L33.5697 59.1665C33.7575 59.3144 33.9935 59.4367 34.278 59.5334C34.5624 59.6244 34.8583 59.6699 35.1655 59.6699C35.5751 59.6699 35.8766 59.6073 36.07 59.4822C36.2691 59.3513 36.3687 59.1693 36.3687 58.9361C36.3687 58.7711 36.3146 58.6431 36.2065 58.5521C36.0984 58.4553 35.9619 58.3842 35.7969 58.3387C35.6319 58.2875 35.4129 58.2392 35.1399 58.1937C34.7758 58.1254 34.4828 58.0571 34.2609 57.9889C34.0391 57.9149 33.8485 57.7926 33.6892 57.6219C33.5356 57.4513 33.4588 57.2152 33.4588 56.9137C33.4588 56.5382 33.6152 56.231 33.9281 55.9921C34.241 55.7531 34.6762 55.6337 35.2337 55.6337C35.5239 55.6337 35.814 55.6735 36.1041 55.7531C36.3943 55.8271 36.6332 55.9266 36.8209 56.0518L36.5564 56.5382C36.1866 56.2822 35.7457 56.1542 35.2337 56.1542C34.8469 56.1542 34.5539 56.2225 34.3548 56.359C34.1614 56.4955 34.0647 56.6747 34.0647 56.8966C34.0647 57.0673 34.1187 57.2038 34.2268 57.3062C34.3406 57.4086 34.4799 57.4854 34.6449 57.5366C34.8099 57.5821 35.0375 57.6305 35.3276 57.6817C35.686 57.7499 35.9733 57.8182 36.1895 57.8865C36.4056 57.9547 36.5905 58.0713 36.7441 58.2363C36.8977 58.4013 36.9745 58.6289 36.9745 58.919C36.9745 59.3115 36.8095 59.6244 36.4796 59.8577C36.1553 60.0852 35.7031 60.199 35.1228 60.199Z'
        fill='white'
      />
      <path
        d='M42.0029 58.0998H38.2482C38.2823 58.5663 38.4615 58.9446 38.7858 59.2347C39.1101 59.5192 39.5197 59.6614 40.0146 59.6614C40.2933 59.6614 40.5493 59.613 40.7826 59.5163C41.0158 59.4139 41.2178 59.266 41.3885 59.0726L41.7298 59.4651C41.5307 59.7041 41.2804 59.8861 40.9789 60.0113C40.683 60.1364 40.3559 60.199 39.9975 60.199C39.5367 60.199 39.1271 60.1023 38.7687 59.9089C38.416 59.7097 38.1401 59.4367 37.941 59.0897C37.7419 58.7426 37.6423 58.3501 37.6423 57.9121C37.6423 57.474 37.7362 57.0815 37.9239 56.7345C38.1173 56.3874 38.379 56.1172 38.709 55.9238C39.0446 55.7304 39.4201 55.6337 39.8354 55.6337C40.2507 55.6337 40.6233 55.7304 40.9533 55.9238C41.2832 56.1172 41.5421 56.3874 41.7298 56.7345C41.9175 57.0758 42.0114 57.4683 42.0114 57.9121L42.0029 58.0998ZM39.8354 56.1542C39.403 56.1542 39.0389 56.2936 38.7431 56.5723C38.453 56.8454 38.288 57.2038 38.2482 57.6475H41.4311C41.3913 57.2038 41.2235 56.8454 40.9277 56.5723C40.6375 56.2936 40.2734 56.1542 39.8354 56.1542Z'
        fill='white'
      />
      <path
        d='M47.3382 60.199C46.8945 60.199 46.4962 60.1023 46.1435 59.9089C45.7965 59.7154 45.5234 59.4452 45.3243 59.0982C45.1252 58.7455 45.0257 58.3501 45.0257 57.9121C45.0257 57.474 45.1252 57.0815 45.3243 56.7345C45.5234 56.3874 45.7965 56.1172 46.1435 55.9238C46.4962 55.7304 46.8945 55.6337 47.3382 55.6337C47.725 55.6337 48.0692 55.7105 48.3707 55.8641C48.6779 56.012 48.9197 56.231 49.0961 56.5211L48.6438 56.8283C48.4959 56.6065 48.3081 56.4415 48.0806 56.3334C47.853 56.2196 47.6056 56.1627 47.3382 56.1627C47.0139 56.1627 46.7209 56.2367 46.4593 56.3846C46.2033 56.5268 46.0013 56.7316 45.8534 56.999C45.7112 57.2664 45.6401 57.5707 45.6401 57.9121C45.6401 58.2591 45.7112 58.5663 45.8534 58.8337C46.0013 59.0953 46.2033 59.3001 46.4593 59.4481C46.7209 59.5903 47.0139 59.6614 47.3382 59.6614C47.6056 59.6614 47.853 59.6073 48.0806 59.4993C48.3081 59.3912 48.4959 59.2262 48.6438 59.0043L49.0961 59.3115C48.9197 59.6017 48.6779 59.8235 48.3707 59.9771C48.0635 60.125 47.7193 60.199 47.3382 60.199Z'
        fill='white'
      />
      <path
        d='M52.0459 60.199C51.6136 60.199 51.2239 60.1023 50.8769 59.9089C50.5298 59.7097 50.2568 59.4367 50.0577 59.0897C49.8585 58.7426 49.759 58.3501 49.759 57.9121C49.759 57.474 49.8585 57.0815 50.0577 56.7345C50.2568 56.3874 50.5298 56.1172 50.8769 55.9238C51.2239 55.7304 51.6136 55.6337 52.0459 55.6337C52.4783 55.6337 52.868 55.7304 53.215 55.9238C53.562 56.1172 53.8322 56.3874 54.0257 56.7345C54.2248 57.0815 54.3243 57.474 54.3243 57.9121C54.3243 58.3501 54.2248 58.7426 54.0257 59.0897C53.8322 59.4367 53.562 59.7097 53.215 59.9089C52.868 60.1023 52.4783 60.199 52.0459 60.199ZM52.0459 59.6614C52.3645 59.6614 52.6489 59.5903 52.8993 59.4481C53.1553 59.3001 53.3544 59.0925 53.4966 58.8251C53.6388 58.5577 53.7099 58.2534 53.7099 57.9121C53.7099 57.5707 53.6388 57.2664 53.4966 56.999C53.3544 56.7316 53.1553 56.5268 52.8993 56.3846C52.6489 56.2367 52.3645 56.1627 52.0459 56.1627C51.7273 56.1627 51.4401 56.2367 51.1841 56.3846C50.9337 56.5268 50.7346 56.7316 50.5867 56.999C50.4445 57.2664 50.3734 57.5707 50.3734 57.9121C50.3734 58.2534 50.4445 58.5577 50.5867 58.8251C50.7346 59.0925 50.9337 59.3001 51.1841 59.4481C51.4401 59.5903 51.7273 59.6614 52.0459 59.6614Z'
        fill='white'
      />
      <path
        d='M59.6573 53.8246V60.1563H59.077V59.2689C58.8949 59.5704 58.6532 59.8008 58.3517 59.9601C58.0558 60.1193 57.7202 60.199 57.3447 60.199C56.9237 60.199 56.5426 60.1023 56.2013 59.9089C55.8599 59.7154 55.5925 59.4452 55.3991 59.0982C55.2057 58.7512 55.109 58.3558 55.109 57.9121C55.109 57.4683 55.2057 57.0729 55.3991 56.7259C55.5925 56.3789 55.8599 56.1115 56.2013 55.9238C56.5426 55.7304 56.9237 55.6337 57.3447 55.6337C57.7088 55.6337 58.0359 55.7105 58.3261 55.8641C58.6219 56.012 58.8637 56.231 59.0514 56.5211V53.8246H59.6573ZM57.3959 59.6614C57.7088 59.6614 57.9904 59.5903 58.2407 59.4481C58.4967 59.3001 58.6958 59.0925 58.8381 58.8251C58.986 58.5577 59.0599 58.2534 59.0599 57.9121C59.0599 57.5707 58.986 57.2664 58.8381 56.999C58.6958 56.7316 58.4967 56.5268 58.2407 56.3846C57.9904 56.2367 57.7088 56.1627 57.3959 56.1627C57.0773 56.1627 56.7901 56.2367 56.5341 56.3846C56.2837 56.5268 56.0846 56.7316 55.9367 56.999C55.7945 57.2664 55.7234 57.5707 55.7234 57.9121C55.7234 58.2534 55.7945 58.5577 55.9367 58.8251C56.0846 59.0925 56.2837 59.3001 56.5341 59.4481C56.7901 59.5903 57.0773 59.6614 57.3959 59.6614Z'
        fill='white'
      />
      <path
        d='M65.2529 58.0998H61.4982C61.5323 58.5663 61.7115 58.9446 62.0358 59.2347C62.3601 59.5192 62.7697 59.6614 63.2646 59.6614C63.5433 59.6614 63.7993 59.613 64.0326 59.5163C64.2658 59.4139 64.4678 59.266 64.6385 59.0726L64.9798 59.4651C64.7807 59.7041 64.5304 59.8861 64.2289 60.0113C63.933 60.1364 63.6059 60.199 63.2475 60.199C62.7867 60.199 62.3771 60.1023 62.0187 59.9089C61.666 59.7097 61.3901 59.4367 61.191 59.0897C60.9919 58.7426 60.8923 58.3501 60.8923 57.9121C60.8923 57.474 60.9862 57.0815 61.1739 56.7345C61.3673 56.3874 61.629 56.1172 61.959 55.9238C62.2946 55.7304 62.6701 55.6337 63.0854 55.6337C63.5007 55.6337 63.8733 55.7304 64.2033 55.9238C64.5332 56.1172 64.7921 56.3874 64.9798 56.7345C65.1675 57.0758 65.2614 57.4683 65.2614 57.9121L65.2529 58.0998ZM63.0854 56.1542C62.653 56.1542 62.2889 56.2936 61.9931 56.5723C61.703 56.8454 61.538 57.2038 61.4982 57.6475H64.6811C64.6413 57.2038 64.4735 56.8454 64.1777 56.5723C63.8875 56.2936 63.5234 56.1542 63.0854 56.1542Z'
        fill='white'
      />
    </svg>
  );
};

WofCta.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

WofCta.defaultProps = {
  width: '91px',
  height: '73px',
};

export default WofCta;
