import React, { Component } from "react";
import { connect } from "react-redux";

import { t } from "../../../../system/ui";
import Icons from "../../../../components/icons";
import { ColorPicker } from "../../../../components/fields";
import Tooltip from "../../../../components/tooltip";

class StylesOptions extends Component {
  constructor(props) {
    super(props);

    const defaults = {
      buttonTextColor: this.props.options.buttonTextColor?.color || "#fff",
      buttonBgColor: this.props.options.buttonBgColor?.color || "#000",
      bgColor: this.props.options.bgColor?.color || "#000",
      textColor: this.props.options.textColor?.color || "#fff"
    };

    this.state = {
      values: {
        buttonTextColor: defaults.buttonTextColor,
        buttonBgColor: defaults.buttonBgColor,
        bgColor: defaults.bgColor,
        textColor: defaults.textColor
      }
    };
    this.onChange = this.onChange.bind(this);
    this.update = this.update.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.handleBgColorChange = this.handleBgColorChange.bind(this);
    this.handleTextColorChange = this.handleTextColorChange.bind(this);
    this.handleButtonBgColorChange = this.handleButtonBgColorChange.bind(this);
    this.handleButtonTextColorChange = this.handleButtonTextColorChange.bind(
      this
    );
  }

  componentDidMount() {
    this.update(this.props.campaign);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.update(newProps.campaign);
    }
  }

  update(campaign) {
    const { buttonTextColor, buttonBgColor, bgColor, textColor } = campaign;

    this.updateValue(bgColor, "bgColor");
    this.updateValue(textColor, "textColor");
    this.updateValue(buttonBgColor, "buttonBgColor");
    this.updateValue(buttonTextColor, "buttonTextColor");
  }

  updateValue(newValue, type) {
    if (!newValue) {
      return;
    }

    let values = this.state.values;
    values[type] = newValue;
    this.setState({ values });
  }

  onChange(selection, type) {
    let values = this.state.values;
    values[type] = selection.value;
    this.setState({ values });
  }

  handleButtonTextColorChange(color) {
    this.updateValue(color, "buttonTextColor");
  }

  handleButtonBgColorChange(color) {
    this.updateValue(color, "buttonBgColor");
  }

  handleBgColorChange(color) {
    this.updateValue(color, "bgColor");
  }

  handleTextColorChange(color) {
    this.updateValue(color, "textColor");
  }

  render() {
    if (!this.props.isStylesVisible) {
      return false;
    }

    let buttonTextColor = this.props.options.buttonTextColor.show,
      buttonBgColor = this.props.options.buttonBgColor.show,
      textColor = this.props.options.textColor.show,
      bgColor = this.props.options.bgColor.show;
    return (
      <div className="wizard-input-type-wrapper">
        <h3 className="wizard-input-type-title">
          <Icons name="palette" />
          {t(this.props.options?.title || "Styles")}
          <span className="field-tooltip-icon">
            <Tooltip
              content={t(`audience_${this.props.campaignType}`)}
              alignment="left"
            >
              <i className="icon-info" role="presentation">
                <span className="for-screenreader-only" />
              </i>
            </Tooltip>
          </span>
        </h3>
        <div
          className={`${
            this.props.options.previewButton?.show ? "color-picker-form" : ""
          } form-elements`}
        >
          {bgColor ? (
            <ColorPicker
              name="bgColor"
              className="item-field large"
              label={t(this.props.options.bgColor.label)}
              labelDefault={this.props.options.bgColor.label}
              value={this.state.values.bgColor}
              onChange={this.handleBgColorChange}
            />
          ) : (
            ""
          )}
          {textColor ? (
            <ColorPicker
              name="textColor"
              className="item-field large"
              label={t(this.props.options.textColor.label)}
              labelDefault={this.props.options.textColor.label}
              value={this.state.values.textColor}
              onChange={this.handleTextColorChange}
            />
          ) : (
            ""
          )}
          {buttonBgColor ? (
            <ColorPicker
              name="backgroundColor"
              className="item-field large"
              label={t(this.props.options.buttonBgColor.label)}
              labelDefault={this.props.options.buttonBgColor.label}
              value={this.state.values.buttonBgColor}
              onChange={this.handleButtonBgColorChange}
            />
          ) : (
            ""
          )}
          {buttonTextColor ? (
            <ColorPicker
              name="color"
              className="item-field medium"
              label={t(this.props.options.buttonTextColor.label)}
              labelDefault={this.props.options.buttonTextColor.label}
              value={this.state.values.buttonTextColor}
              onChange={this.handleButtonTextColorChange}
            />
          ) : (
            ""
          )}
          {this.props.options.previewButton?.show && (
            <div className="color-picker-preview">
              <span className="item-label label-text">
                {this.props.options.previewButton.label}
              </span>
              <span
                className="button-text"
                style={{
                  background: this.state.values.bgColor,
                  color: this.state.values.textColor
                }}
              >
                {this.props.options.previewButton.text}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

StylesOptions.defaultProps = {
  options: {
    buttonTextColor: {
      show: true,
      label: "Button Text Color",
      color: "#fff"
    },
    buttonBgColor: {
      show: true,
      label: "Button Background Color",
      color: "#000"
    },
    bgColor: {
      show: false,
      label: "Background Color",
      color: "#000"
    },
    textColor: {
      show: false,
      label: "Text Color",
      color: "#fff"
    },
    previewButton: {
      show: false,
      label: "Label Text",
      text: "Button Text"
    }
  }
};

const MapStatesToProps = store => ({
  isStylesVisible: store.wizard.isStylesVisible,
  editForm: store.wizard.editForm
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  StylesOptions
);