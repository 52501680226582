/**
 * @author Bilal Cinarli
 */

import React from "react";
import PropTypes from "prop-types";
import * as classes from "../../constants/ui.classes";

class Radio extends React.Component {
  render() {
    const { name, selectedValue, onChange } = this.context.radioGroup;
    const optional = {};
    let className = "item item-field is-radio";

    if (selectedValue !== undefined) {
      optional.checked = this.props.value === selectedValue;
    }

    if (typeof onChange === "function") {
      optional.onChange = onChange.bind(null, this.props.value);
    }

    if (optional.checked) {
      className += " is-checked";
    }

    return (
      <label className={className}>
        <input {...this.props} type="radio" name={name} {...optional} />
        <span className={classes.fieldLabel}>{this.props.label}</span>
      </label>
    );
  }
}

Radio.contextTypes = {
  radioGroup: PropTypes.object
};

export default Radio;
