import React from 'react';
import { connect } from 'react-redux';
import { selectedIndexPropType } from 'react-tabs/lib/helpers/propTypes';
import Icons from '../icons';
import { setTitle } from '../../system/document';
import { t } from '../../system/ui';
import { uiActions } from '../../actions';
import { TextField } from '../fields';
import { getUser, unsubscribeEmail } from '../../modules/auth/user';

class Settings_Unsub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCheckMethod: 'blacklist',
      unsubEmail: '',
      customUnsubErrorMessage: '',
      unsubscriptionLink: '',
      unsubscriptionLinkBackup: '',
      selectedUnsub: '',
      isSegmentify: false,
    };

    this.onSelectCheckMethod = this.onSelectCheckMethod.bind(this);
    this.unsubEmail = this.unsubEmail.bind(this);
    this.onSelectUnsub = this.onSelectUnsub.bind(this);
    this.onUnsubUrlChange = this.onUnsubUrlChange.bind(this);
  }

  componentDidMount() {
    setTitle(t('Email'));
    uiActions.isLoading();

    if (this.props.checkMethod !== undefined)
      this.setState({ selectedCheckMethod: this.props.checkMethod });

    if (this.props.unsubscriptionLink !== undefined) {
      const isLink = this.props.unsubscriptionLink !== '';
      this.setState({
        unsubscriptionLink: this.props.unsubscriptionLink,
        unsubscriptionLinkBackup: this.props.unsubscriptionLink,
        isSegmentify: !isLink,
        selectedUnsub: isLink ? 'OTHER' : 'SEGMENTIFY',
      });
    } else {
      this.setState({
        unsubscriptionLink: '',
        unsubscriptionLinkBackup: '',
        isSegmentify: true,
        selectedUnsub: 'SEGMENTIFY',
      });
    }

    this.props.updateData(this.state);
    uiActions.isLoaded();
  }

  onSelectCheckMethod(method, e) {
    e.preventDefault();

    if (!this.props.isSuperUser) return;

    this.setState(
      {
        selectedCheckMethod: method,
      },
      () => {
        this.props.updateData(this.state);
      },
    );
  }

  unsubEmail() {
    this.setState({
      customUnsubErrorMessage: '',
    });
    if (this.state.unsubEmail === '') {
      return;
    }
    uiActions.confirmationDialog({
      title: t('Unsubscribe the User?'),
      content: t('Are you sure you want to unsubscribe the user?'),
      onConfirm: () => {
        unsubscribeEmail(this.state.unsubEmail, (res, isSuccess) => {
          if (!isSuccess) {
            this.setState({
              customUnsubErrorMessage: t('There is no email address.'),
            });
            return;
          }

          this.setState({
            unsubEmail: '',
          });
          const content = () => {
            return <div>Successfully updated.</div>;
          };
          uiActions.showNotification({
            content,
          });
        });
      },
    });
  }

  onUnsubUrlChange(e) {
    if (!this.props.isSuperUser) return;

    this.setState(
      {
        unsubscriptionLink: e?.target?.value || '',
        unsubscriptionLinkBackup: e?.target?.value || '',
      },
      () => {
        this.props.updateData(this.state);
      },
    );
  }

  onSelectUnsub(method, e) {
    e.preventDefault();

    if (!this.props.isSuperUser) return;

    const isSegmentify = method === 'SEGMENTIFY' || method === 'SEGMENTIFY_V2';
    if (this.state.button === true) {
      return;
    }

    if (isSegmentify) {
      this.setState(
        {
          selectedUnsub: method,
          isSegmentify,
          unsubscriptionLink: '',
        },
        () => {
          this.props.updateData(this.state);
        },
      );
    } else {
      this.setState(
        {
          selectedUnsub: method,
          isSegmentify,
          unsubscriptionLink: this.state.unsubscriptionLinkBackup,
        },
        () => {
          this.props.updateData(this.state);
        },
      );
    }
  }

  render() {
    const readOnly = !this.props.isSuperUser;

    return (
      <>
        {(this.props.provider === 'SEGMENTIFY' ||
          this.props.provider === 'SEGMENTIFY_V2') && (
          <div className='unsub-listing'>
            <ul>
              <li
                className='unsub'
                style={{
                  cursor: 'pointer',
                  borderColor:
                    this.state.selectedUnsub === 'SEGMENTIFY'
                      ? '#29cd43'
                      : '#d8d8d8',
                }}
                onClick={this.onSelectUnsub.bind(this, 'SEGMENTIFY')}
              >
                <Icons
                  name={
                    this.state.selectedUnsub === 'SEGMENTIFY'
                      ? 'checkRoundTick'
                      : 'checkboxEmpty'
                  }
                  width={20}
                  height={20}
                />
                <span className='logo-sfy'>
                  <Icons
                    name='segmentify'
                    color='#d31116'
                    bgColor='transparent'
                    width='40'
                    height='40'
                  />
                </span>
                <span>Segmentify</span>
              </li>
              <li
                className='unsub'
                style={{
                  cursor: 'pointer',
                  borderColor:
                    this.state.selectedUnsub === 'OTHER'
                      ? '#29cd43'
                      : '#d8d8d8',
                }}
                onClick={this.onSelectUnsub.bind(this, 'OTHER')}
              >
                <Icons
                  name={
                    this.state.selectedUnsub === 'OTHER'
                      ? 'checkRoundTick'
                      : 'checkboxEmpty'
                  }
                  width={20}
                  height={20}
                />
                <span>Custom Unsub URL</span>
              </li>
            </ul>
          </div>
        )}
        <div className='page-content-block one-whole email-settings-form'>
          <h3
            className='modal-content-title'
            style={{ color: '#6968b2', marginBottom: '5px' }}
          >
            {t('Unsubscribe Settings')}
          </h3>
          <br />
          <form id='currency-form' onSubmit={this.update}>
            <div className='email-setting-extra'>
              <p className='widget-description'>
                Visitor Integration Check Method
              </p>
              <ul className='email-unsub'>
                <li onClick={this.onSelectCheckMethod.bind(this, 'blacklist')}>
                  <Icons
                    name={
                      this.state.selectedCheckMethod === 'blacklist'
                        ? 'radioSelected'
                        : 'radioEmpty'
                    }
                    width={14}
                    height={14}
                  />
                  <span>Black List</span>
                </li>
              </ul>
            </div>
          </form>
          {(this.props.provider === 'SEGMENTIFY' ||
            this.props.provider === 'SEGMENTIFY_V2') && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span>Unsub URL</span>
              <TextField
                className='email-unsub-url-input'
                value={this.state.unsubscriptionLinkBackup}
                placeholder='Enter the unsubscription URL'
                onChange={e => this.onUnsubUrlChange(e)}
                readOnly={this.state.selectedUnsub === 'SEGMENTIFY' || readOnly}
                style={{
                  color:
                    this.state.selectedUnsub === 'SEGMENTIFY' ? '#222' : '#000',
                  opacity:
                    this.state.selectedUnsub === 'SEGMENTIFY' ? '0.35' : '1',
                }}
              />
            </div>
          )}
          <div className='page-forms' ref='forms' />
        </div>
        <li
          className='buttons'
          style={{ listStyle: 'none', marginTop: '15px' }}
        >
          <button
            onClick={() => {
              this.props.updateSettings();
            }}
            className='tertiary-action email-unsub-save-btn'
            style={{
              position: 'relative',
              backgroundColor: readOnly ? '#d8d8d8' : '#63ca63',
            }}
            disabled={readOnly}
          >
            Save
          </button>
        </li>
        <div
          className='page-content-block one-whole email-settings-form'
          style={{ marginTop: '20px' }}
        >
          <h3
            className='modal-content-title'
            style={{ color: '#6968b2', marginBottom: '5px' }}
          >
            {t('Remove Email Subscription')}
          </h3>
          <br />
          <div className='email-unsub-content'>
            <TextField
              className='email-unsub-input'
              value={this.state.unsubEmail}
              placeholder='Enter the email address'
              customErrorMessage={this.state.customUnsubErrorMessage}
              onChange={e => {
                this.setState({ unsubEmail: e.target.value });
              }}
            />
            <button
              className='email-unsub-button'
              onClick={this.unsubEmail}
              type='button'
              style={{ marginLeft: '10px' }}
            >
              Remove
            </button>
          </div>
        </div>
      </>
    );
  }
}
const mapStatesToProps = store => ({
  user: store.user.user,
  validate: store.ui.validate,
  language: store.ui.language,
});
export default connect(mapStatesToProps)(Settings_Unsub);
