import { t } from '../../../../system/ui';

const breadCrumbOptions = {
  home: {
    name: t('Dynamic Bundles'),
    icon: 'bundle',
    slug: '/dynamic_bundle',
  },
  current: {
    name: 'Shop The Look',
  },
};

const getDynamicBreadCrumbOptions = currentName => {
  return {
    home: {
      name: t('Dynamic Bundles'),
      icon: 'bundle',
      slug: '/dynamic_bundle',
      iconSvg: true,
    },
    top: {
      name: t('Shop The Look'),
      slug: '/dynamic_bundle/shop_the_look/list',
    },
    current: {
      name: currentName,
    },
  };
};

const campaignActionButtonConstant = [
  {
    btnLabel: 'Save All',
    type: 'save-justify-right',
    position: 'flex-end',
    key: '1',
  },
];

const actions = [
  {
    name: 'Remove',
    superUserOnly: false,
    iconSvg: true,
    icon: 'crossCircle',
    colorName: 'red',
    action: 'remove',
  },
];

const importExportOptions = [
  {
    label: 'Import',
    icon: 'import',
  },
  {
    label: 'Export',
    icon: 'export',
  },
];

export {
  breadCrumbOptions,
  getDynamicBreadCrumbOptions,
  actions,
  campaignActionButtonConstant,
  importExportOptions,
};
