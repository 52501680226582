/**
 * @fileOverview Actions for Monitoring and its related components
 * @description This file contains the actions for the Monitoring and its related components.
 * @param {React.dispatch} dispatch
 * @returns {void}
 */

const setUsers = dispatch => {
  return users => {
    dispatch({
      type: 'SET_USERS',
      payload: users,
    });
  };
};

const setSelectableUsers = dispatch => {
  return users => {
    dispatch({
      type: 'SET_SELECTABLE_USERS',
      payload: users,
    });
  };
};

const setSelectedUser = dispatch => {
  return user => {
    dispatch({
      type: 'SET_SELECTED_USER',
      payload: user,
    });
  };
};

const setAccounts = dispatch => {
  return accounts => {
    dispatch({
      type: 'SET_ACCOUNTS',
      payload: accounts,
    });
  };
};

const setSelectedAccounts = dispatch => {
  return selectedAccounts => {
    dispatch({
      type: 'SET_SELECTED_ACCOUNTS',
      payload: selectedAccounts,
    });
  };
};

const editSelectedAccounts = dispatch => {
  return account => {
    dispatch({
      type: 'EDIT_SELECTED_ACCOUNTS',
      payload: account,
    });
  };
};

const setIsMonitoringLoaded = dispatch => {
  return isMonitoringLoaded => {
    dispatch({
      type: 'SET_IS_MONITORING_LOADED',
      payload: isMonitoringLoaded,
    });
  };
};

export {
  setUsers,
  setSelectableUsers,
  setSelectedUser,
  setAccounts,
  editSelectedAccounts,
  setSelectedAccounts,
  setIsMonitoringLoaded,
};
