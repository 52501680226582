/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { uiActions } from "../../../actions";

import { TextField } from "../../../components/fields";
import { t } from "../../../system/ui";

class BackgroundImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      name: "image"
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    let image = this.props.campaign.image || "";
    this.updateValue(image);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.image);
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  onFieldChange(e) {
    this.updateValue(e.target.value);
    uiActions.formEdited();
  }

  render() {
    return (
      <li>
        <TextField
          name="backgroundImage"
          label={t(this.props.options.label)}
          className="item-stacked one-whole"
          value={this.state.value}
          required={this.props.options.required}
          onChange={this.onFieldChange}
          tooltip={this.props.options.tooltip}
          tooltipText={this.props.options.tooltipContent}
        />
      </li>
    );
  }
}

BackgroundImage.defaultProps = {
  options: {
    label: "Background Image URL (Recommend 800x700px)",
    required: true,
    tooltip: false,
    tooltipText: ""
  }
};

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  BackgroundImage
);
