import React from 'react';

class IconUpArrow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: '16px',
      height: '16px',
      color: '#acacac',
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    const width = this.props.width ? `${this.props.width}px` : this.state.width;
    const height = this.props.height
      ? `${this.props.height}px`
      : this.state.height;
    const color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 1000 1000'
        width={this.state.width}
        height={this.state.height}
      >
        <g>
          <path
            fill={this.state.color}
            d='M500,317.9l433.9,433.9c6.1,6.3,14.4,9.6,23.3,9.6c8.8,0,17.1-3.4,23.3-9.6c6.1-6.1,9.6-14.4,9.6-23.3c0-8.8-3.4-17.1-9.6-23.3L523.3,248.2c-12.8-12.8-33.7-12.8-46.5,0L19.6,705.3c-6.1,6.1-9.4,14.4-9.6,23.3c0,0.1,0,0.3,0,0.4c0,5.8,3.4,14.3,3.4,14.3c1.6,3.1,3.6,6,6.1,8.6c12.8,12.8,33.7,12.8,46.5,0L500,317.9z'
          />
        </g>
      </svg>
    );
  }
}

export default IconUpArrow;
