import React from 'react';
import styles from './ListCalendar.module.scss';
import Icons from '../../icons';
import { getFormattedDate } from '../../../system/date';

const ListCalendar = ({
  scheduleDate,
  statusType,
  calendarText,
  iconSize,
  iconColor,
  styleType,
}) => {
  if (!scheduleDate || statusType === 'draft') return null;

  return (
    <div className={styles[styleType]} data-content={calendarText}>
      <Icons name='calendar' color={iconColor} width={iconSize} />
      <span>{getFormattedDate(scheduleDate)}</span>
    </div>
  );
};

export default ListCalendar;
