/**
 * Created by mehmetyurtar on 1/6/18.
 */
import React from "react";

class IconRetargeting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <path
          fill={this.state.color}
          d="M13,7l3-3l-3-1l-1-3L9,3l0.6,1.9l-1,1c-0.9-0.6-2-1-3.1-1C2.5,5,0,7.5,0,10.5S2.5,16,5.5,16s5.5-2.5,5.5-5.5
	c0-1.2-0.4-2.2-1-3.1l1-1L13,7z M9,10.5C9,12.4,7.4,14,5.5,14S2,12.4,2,10.5S3.6,7,5.5,7c0.6,0,1.2,0.2,1.7,0.4L4.8,9.8
	c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l2.4-2.4C8.8,9.3,9,9.9,9,10.5z"
        />
      </svg>
    );
  }
}

export default IconRetargeting;
