import React, { Component } from 'react';
import { connect } from 'react-redux';

import { uiActions } from '../../../actions';
import { t } from '../../../system/ui';
import { getAccount, getAccountCurrency } from '../../auth/user';
import { getRandomProduct } from '../../product/ajax';

const urlBase64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const isIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

class PreviewButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: false,
      randomProduct: {},
    };

    this.validate = this.validate.bind(this);
    this.showPushNotification = this.showPushNotification.bind(this);
    this.showStaticPushNotification = this.showStaticPushNotification.bind(
      this,
    );
    this.showProductPushNotification = this.showProductPushNotification.bind(
      this,
    );
    this.showRandomPushNotification = this.showRandomPushNotification.bind(
      this,
    );
    this.getField = this.getField.bind(this);
    this.showPushWithServiceWorker = this.showPushWithServiceWorker.bind(this);
  }

  componentDidMount() {
    this.getProduct();
  }

  getProduct() {
    getRandomProduct(response => {
      if (response) {
        this.setState({
          randomProduct: response,
        });
      }
    });
  }

  getField(fieldName, secondFieldName) {
    if (this.props.campaignFields.refs[fieldName]) {
      if (fieldName === 'actionButtons') {
        return this.props.campaignFields.refs[fieldName].state.actions;
      }
      return this.props.campaignFields.refs[fieldName].state.value;
    }
    if (this.props.campaignFields.refs[secondFieldName]) {
      if (
        this.props.campaignFields.refs[secondFieldName].state.method ===
        'Company Logo'
      ) {
        return getAccount().pushConfiguration.defaultIcon;
      }
      if (
        this.props.campaignFields.refs[secondFieldName].state.method !==
        'Product Image'
      ) {
        return this.props.campaignFields.refs[secondFieldName].state.imgSrc;
      }
      return '';
    }
    return '';
  }

  processTitle = title => {
    if (!title) return '';

    const blockReplacementOfCampaignTypes = [
      'PUSH_NOTIFICATION',
      'UPCOMING',
      'PUSH_PERMISSION',
    ];

    if (!blockReplacementOfCampaignTypes.includes(this.props.campaignType)) {
      return title.replace('{{product:name}}', this.state.randomProduct.name);
    }
    return title;
  };

  showPushNotification(type) {
    if (type) {
      const funcName = `show${type}PushNotification`;
      this.showPushWithServiceWorker(funcName);
    }
  }

  showPushWithServiceWorker(funcName) {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/sw.js')
        .then(registration => {})
        .catch(e => {});
    } else {
      return;
    }
    const _this = this;
    navigator.serviceWorker.ready.then(registration =>
      registration.pushManager.getSubscription().then(subscription => {
        if (subscription) {
          _this[funcName](registration);
        } else {
          return registration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(
                'BEl62iUYgUivxIkv69yViEuiBIa-Ib9-SkvMeAtA3LFgDzkrxZJjSgSnfckjBJuBkr3qBUYIHBQFLXYp5Nksh8U',
              ),
            })
            .then(subscription => {
              if (subscription) {
                _this[funcName](registration);
              }
            });
        }
      }),
    );
  }

  showStaticPushNotification(registration) {
    const options = this.prepareOptionsForStaticSent();
    registration.showNotification(
      this.processTitle(
        this.props.additionalTitles[this.props.selectedLanguage],
      ),
      options,
    );
  }

  prepareOptionsForStaticSent() {
    let urls = [];
    if (this.getField('actionButtons').length === 1) {
      urls.push(this.getField('actionButtons')[0].redirectUrl);
    } else if (this.getField('actionButtons').length === 2) {
      urls.push(this.getField('actionButtons')[0].redirectUrl);
      urls.push(this.getField('actionButtons')[1].redirectUrl);
    } else {
      urls = [];
    }

    return {
      body: this.prepareBody(),
      icon: this.prepareIcon(),
      image: this.prepareImage(),
      actions: this.getField('actionButtons'),
      data: {
        url:
          this.getField('redirectUrl') === ''
            ? this.state.randomProduct.url
            : this.getField('redirectUrl'),
        actionUrls: urls,
      },
    };
  }

  prepareBody() {
    if (this.props.campaignType === 'PUSH_NOTIFICATION') {
      return (
        this.props.additionalDescriptions[this.props.selectedLanguage] || ''
      );
    }
    if (
      this.props?.campaignFields?.refs?.hideInformation?.state?.hideInformation
    ) {
      return '';
    }
    if (this.props?.campaignFields?.refs?.hidePrice?.state?.hidePrice) {
      return this.state.randomProduct.name;
    }
    return `${this.state.randomProduct.name} ${this.state.randomProduct.priceText}`;
  }

  prepareIcon() {
    if (
      this.getField('icon', 'iconWithUpload') === '' ||
      this.getField('icon', 'iconWithUpload') === undefined
    ) {
      if (this.props.campaignType === 'PUSH_NOTIFICATION') return '';
      return this.state.randomProduct.image;
    }
    return this.getField('icon', 'iconWithUpload');
  }

  prepareImage() {
    // TODO: Image/Icon field method and value must be handle in a better way.
    if (
      this.props?.campaignFields?.refs?.hideInformation?.state
        ?.hideInformation ||
      this.props?.campaignFields?.refs?.imageWithUpload?.state?.method ===
        'No Image'
    )
      return '';
    if (
      this.getField('image', 'imageWithUpload') === '' ||
      this.getField('image', 'imageWithUpload') === undefined
    ) {
      if (this.props?.campaignType === 'PUSH_NOTIFICATION') return '';
      return this.state?.randomProduct?.image;
    }
    return this.getField('image', 'imageWithUpload');
  }

  showProductPushNotification(registration) {
    let urls = [];
    if (this.getField('actionButtons').length === 1) {
      urls.push(this.getField('actionButtons')[0].redirectUrl);
    } else if (this.getField('actionButtons').length === 2) {
      urls.push(this.getField('actionButtons')[0].redirectUrl);
      urls.push(this.getField('actionButtons')[1].redirectUrl);
    } else {
      urls = [];
    }
    registration.showNotification(
      this.processTitle(
        this.props.additionalTitles[this.props.selectedLanguage],
      ),
      {
        body: 'The MacBook Air   XXXX TL',
        icon: 'https://cdn.segmentify.com/images/572px-Aluminium_MacBook.png',
        actions: this.getField('actionButtons'),
        data: {
          url: this.getField('redirectUrl'),
          actionUrls: urls,
        },
      },
    );
  }

  showRandomPushNotification(registration) {
    let urls = [];
    if (this.getField('actionButtons').length === 1) {
      urls.push(this.getField('actionButtons')[0].redirectUrl);
    } else if (this.getField('actionButtons').length === 2) {
      urls.push(this.getField('actionButtons')[0].redirectUrl);
      urls.push(this.getField('actionButtons')[1].redirectUrl);
    } else {
      urls = [];
    }

    const calculateBody = () => {
      if (
        this.props?.campaignFields?.refs?.hideInformation?.state
          ?.hideInformation
      )
        return '';
      if (this.props?.campaignFields?.refs?.hidePrice?.state?.hidePrice)
        return this.state.randomProduct.name;
      return `${this.state?.randomProduct?.name}   ${this.state?.randomProduct?.priceText}`;
    };

    const calculateImage = () => {
      // TODO: Image/Icon field method and value must be handle in a better way.
      if (
        this.props?.campaignFields?.refs?.hideInformation?.state
          ?.hideInformation ||
        this.props?.campaignFields?.refs?.imageWithUpload?.state?.method ===
          'No Image'
      )
        return '';
      if (
        this.getField('image', 'imageWithUpload') === '' ||
        this.getField('image', 'imageWithUpload') === undefined
      ) {
        return this.state.randomProduct.image;
      }
      return this.getField('image', 'imageWithUpload');
    };

    registration.showNotification(
      this.processTitle(
        this.props.additionalTitles[this.props.selectedLanguage],
      ),
      {
        body: calculateBody(),
        icon:
          this.getField('icon', 'iconWithUpload') === '' ||
          this.getField('icon', 'iconWithUpload') === undefined
            ? this.state.randomProduct.image
            : this.getField('icon', 'iconWithUpload'),
        image: calculateImage(),
        actions: this.getField('actionButtons'),
        data: {
          url: this.state.randomProduct.url,
          actionUrls: urls,
        },
      },
    );
  }

  validate() {
    const errors = document.querySelectorAll('.has-error');

    if (this.props.validate === true && errors.length === 0) {
      const pushType =
        this.props.campaign.messageType &&
        this.props.campaign.messageType === 'product'
          ? 'Product'
          : 'Static';
      if (this.props.isPush) {
        if (
          (this.props.campaignFields.refs.imageWithUpload &&
            this.props.campaignFields.refs.imageWithUpload.state.method !==
              'Product Image') ||
          (this.props.campaignFields.refs.iconWithUpload &&
            this.props.campaignFields.refs.iconWithUpload.state.method !==
              'Product Image')
        ) {
          if (
            (this.props.additionalTitles[this.props.selectedLanguage] ||
              this.props.campaign.params.title) &&
            !this.props.campaignFields.props.algorithmSelected
          ) {
            this.showPushNotification('Static');
          }
        } else {
          this.showPushNotification('Random');
        }
      } else {
        this.showPushNotification(pushType);
      }
    } else {
      uiActions.isValidating();

      if (!this.state.clicked) {
        this.setState({ clicked: true }, this.validate);
      }
    }
  }

  render() {
    if (isIframe()) {
      return null;
    }
    return (
      <a className='button preview-action one-whole' onClick={this.validate}>
        <i className='icon-view' role='presentation'>
          <span className='for-screenreader-only' />
        </i>
        {t('Preview')}
      </a>
    );
  }
}

const MapStatesToProps = store => ({
  validate: store.ui.validate,
  editForm: store.wizard.editForm,
});

export default connect(MapStatesToProps)(PreviewButton);
