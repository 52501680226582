import React from 'react';
import Icons from '../../../icons';
import { t } from '../../../../system/ui';
import './SFYFilterButton.scss';

const SFYFilterButton = ({
  filterType,
  toggleFilterAction,
  filterName,
  buttonIcon,
}) => {
  return (
    <div
      className={`sfy-filters sfy-filters-${
        filterType === 'inclusions' ? 'inclusion' : 'exclusion'
      }`}
      onClick={() => {
        toggleFilterAction(filterType);
      }}
    >
      <Icons name={buttonIcon} color='#787779' height='12' width='12' />
      {t(filterName)}
    </div>
  );
};

export default SFYFilterButton;
