import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '../../../../components/fields';
import Icons from '../../../../components/icons';

const Multiplier = ({
  currentState,
  value,
  onChange,
  closeField,
  componentDetail,
}) => {
  const handleChangeMultiplier = val => {
    const fixedValue = val < 0 ? 0 : val;
    onChange({
      ...currentState,
      value: fixedValue,
    });
  };

  return (
    <div className='form-element optional-multiplier'>
      <span className='element-title'>Multiplier</span>
      <span className='element-description'>{componentDetail.description}</span>
      <div
        className='element-row-field remove-option'
        onClick={() => closeField('multiplier')}
      >
        <Icons name='minusCircleSmall' color='#D31116' />
        <span className='element-row-label'>Exclude Multiplier</span>
      </div>
      <div className='element-row-field'>
        <span className='element-row-label'>{componentDetail.keyName} x</span>
        <TextField
          className='element-textfield'
          name='multiplier'
          type='number'
          value={value}
          onChange={e => handleChangeMultiplier(e.target.value)}
        />
      </div>
    </div>
  );
};

Multiplier.propTypes = {
  currentState: PropTypes.shape({}).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  closeField: PropTypes.func.isRequired,
  componentDetail: PropTypes.shape({
    keyName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default Multiplier;
