import React from 'react';
import PropTypes from 'prop-types';
import SwitchButton from 'components/shared/switch-button';
import { clone } from 'system/object';
import { uiActions } from 'actions';
import { t } from 'system/ui';
import CardWrapper from '../faceted-search/common/CardWrapper';
import Select from '../faceted-search/common/Select';
import CrossIcon from '../faceted-search/common/CrossIcon';
import AddOrdering from '../faceted-search/common/AddOrdering';
import Icons from '../../icons';
import { TextField } from '../../fields';

export default class Ordering extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalMode: null,
      modalOrderKey: null,
    };
    this.disabledOrdersKeys = [
      'NEWEST',
      'PRICE_ASC',
      'BEST_MATCH',
      'MOST_POPULAR',
      'PRICE_DESC',
      'BEST_SELLERS',
      'SMART_SORTING',
      'ALPHABETICAL_ASC',
      'ALPHABETICAL_DESC',
      'BOOSTED_SMART_SORTING',
    ];
  }

  setNextDefault = (copyOrders, toggledOrderKey) => {
    let nextOrderToBeDefault = null;
    for (const copyOrderKey in copyOrders) {
      if (
        copyOrderKey !== toggledOrderKey &&
        copyOrders[copyOrderKey].enabled
      ) {
        nextOrderToBeDefault = copyOrderKey;
        break;
      }
    }
    nextOrderToBeDefault !== null &&
      (copyOrders[nextOrderToBeDefault].isDefault = true);
  };

  disableExistingOrderIfInstantCampaign = copyOrders => {
    const { type } = this.props;
    let alreadyEnabledOrder = null;
    for (const copyOrderKey in copyOrders) {
      if (copyOrders[copyOrderKey].enabled) {
        alreadyEnabledOrder = copyOrders[copyOrderKey];
        break;
      }
    }
    if (type === 'instant' && alreadyEnabledOrder !== null) {
      alreadyEnabledOrder.isDefault = false;
      alreadyEnabledOrder.enabled = false;
    }
  };

  getExistingDefaultOrder = copyOrders =>
    Object.keys(copyOrders).find(
      copyOrderKey => copyOrders[copyOrderKey].isDefault,
    );

  onSwitchToggle = orderKey => {
    const { orders, updateOrders } = this.props;
    const copyOrders = clone(orders);
    const toggledOrder = copyOrders[orderKey];
    if (toggledOrder.enabled === true) {
      if (this.getExistingDefaultOrder(copyOrders) === orderKey) {
        toggledOrder.isDefault = false;
        this.setNextDefault(copyOrders, orderKey);
      }
      toggledOrder.enabled = false;
    } else {
      this.disableExistingOrderIfInstantCampaign(copyOrders);
      this.getExistingDefaultOrder(copyOrders) === undefined &&
        (toggledOrder.isDefault = true);
      toggledOrder.enabled = true;
    }
    updateOrders(copyOrders);
  };

  onRadioChecked = ({ currentTarget }) => {
    const { orders, updateOrders } = this.props;
    const selectedOrderKey = currentTarget.name;
    const copyOrders = clone(orders);
    const existingDefaultOrderKey = Object.keys(copyOrders).find(
      orderKey => copyOrders[orderKey].isDefault,
    );
    if (
      existingDefaultOrderKey !== selectedOrderKey &&
      copyOrders[selectedOrderKey].enabled === true
    ) {
      existingDefaultOrderKey !== undefined &&
        (copyOrders[existingDefaultOrderKey].isDefault = false);
      copyOrders[selectedOrderKey].isDefault = true;
      updateOrders(copyOrders);
    }
  };

  onDirectionChange = ({ target }, orderKey, productAccessor) => {
    const { orders, updateOrders } = this.props;
    const copyOrders = clone(orders);
    const fieldToChange = copyOrders[orderKey].fields.find(
      field => field.productAccessor === productAccessor,
    );
    fieldToChange.direction = target.value;
    updateOrders(copyOrders);
  };

  onTextSortingChange = (orderKey, productAccessor) => {
    const { orders, updateOrders } = this.props;
    const copyOrders = clone(orders);
    const fieldToChange = copyOrders[orderKey].fields.find(
      field => field.productAccessor === productAccessor,
    );
    fieldToChange.textSorting = !fieldToChange.textSorting;
    updateOrders(copyOrders);
  };

  rockScorePercentageChange = (orderKey, val) => {
    let fixedVal = parseInt(val);
    if (val < 0) fixedVal = 0;
    if (val > 100) fixedVal = 100;
    const { orders, updateOrders } = this.props;
    const copyOrders = clone(orders);
    copyOrders[orderKey].rockScorePercentage = fixedVal;
    updateOrders(copyOrders);
  };

  removeField = (orderKey, productAccessor) => {
    const { orders, updateOrders } = this.props;
    const copyOrders = clone(orders);
    const fieldToRemoveIndex = copyOrders[orderKey].fields.findIndex(
      field => field.productAccessor === productAccessor,
    );
    copyOrders[orderKey].fields.splice(fieldToRemoveIndex, 1);
    // remove the order if no field exists inside it.
    if (copyOrders[orderKey].fields.length === 0) {
      if (copyOrders[orderKey].isDefault) {
        copyOrders[orderKey].isDefault = false;
        this.setNextDefault(copyOrders, orderKey);
      }
      delete copyOrders[orderKey];
    }
    updateOrders(copyOrders);
  };

  removeOrder = orderKey => {
    const { orders, updateOrders } = this.props;
    const copyOrders = clone(orders);
    if (copyOrders[orderKey].isDefault) {
      copyOrders[orderKey].isDefault = false;
      this.setNextDefault(copyOrders, orderKey);
    }
    delete copyOrders[orderKey];
    updateOrders(copyOrders);
  };

  openAddOrderingModal = (modalMode, modalOrderKey) => {
    this.setState({
      modalMode,
      modalOrderKey,
    });
  };

  addOrderAction = order => {
    const { orders, updateOrders } = this.props;
    const { modalMode, modalOrderKey } = this.state;
    const copyOrder = clone(orders);
    let uniqueKey = modalOrderKey;
    if (modalMode === 'create') {
      uniqueKey = this.createKey(order.name);
      let keyExists = false;
      Object.keys(orders).forEach(orderKey => {
        orderKey === uniqueKey && (keyExists = true);
      });
      if (keyExists) {
        uiActions.showErrorNotification(() => (
          <span>Unique id could not be created. Try different order name</span>
        ));
        return;
      }
    }
    if (modalMode === 'create') {
      order.isDefault = false;
      order.enabled = false;
    }
    copyOrder[uniqueKey] = order;
    updateOrders(copyOrder);
    this.openAddOrderingModal(null, null);
  };

  createKey(orderName) {
    return orderName
      .replace(/[^0-9a-z ]/gi, '') // replace all non alphanumericals except space
      .replace(/ +(?= )/g, '') // remove all multiple spaces and replace with single space
      .replace(/ /g, '_') // replace spaces with underscore
      .toUpperCase();
  }

  render() {
    const { orders, instanceId, type, removeOlParentClass } = this.props;
    const { modalMode, modalOrderKey } = this.state;
    return (
      <>
        <CardWrapper
          id='faceted-card-ordering'
          title='Ordering'
          tooltipText={`Smart Sorting: Brings results thanks to Segmentify's smart algorithms.
            Best Match: It brings the products related to the searched keyword that match the keyword the most.
            Descending by price: Sorts the results by price in descending order.
            Ascending by price: Sorts the results in ascending order by price.
            Alphabetical (A-Z): Sort the results alphabetically.
            Alphabetical (Z-A): Sorts the results alphabetically in reverse order.
            Bestsellers: Sorts the results from best seller to least selling product.
            New arrivals: It sorts the results from the newest products to the oldest products.`}
          description={
            type === 'faceted'
              ? 'Select the sorting options you want to use in the search results page'
              : 'Select the sorting options you want to use in the search box'
          }
          headerRightComponent={
            <button
              type='button'
              className='add-facet-button'
              onClick={() => this.openAddOrderingModal('create', null)}
            >
              <div className='add-facet-button-inner'>
                <label htmlFor='-'>Add Ordering</label>
              </div>
            </button>
          }
          removeOlParentClass={removeOlParentClass}
        >
          {Object.keys(orders).map(orderKey => {
            const order = orders[orderKey];
            const isDisabled = this.disabledOrdersKeys.includes(orderKey);
            const isSmartSorting = orderKey === 'SMART_SORTING';
            return (
              <>
                <div
                  className='wizard-criterion faceted-search-white-card faceted-search-ordering'
                  key={orderKey}
                >
                  <div
                    className='faceted-search-white-card-row'
                    style={{ justifyContent: 'space-between' }}
                  >
                    <div className='faceted-search-white-card-row-wrapper'>
                      <SwitchButton
                        isSwitchedOn={order.enabled}
                        onToggle={() => this.onSwitchToggle(orderKey)}
                      />
                      <span className='facet-type'>{order.name}</span>
                    </div>
                    {!isDisabled && (
                      <div className='faceted-search-white-card-row-wrapper'>
                        <span style={{ color: '#5A7491' }}>
                          unique id: {orderKey}
                        </span>
                      </div>
                    )}
                    <div
                      className='faceted-search-white-card-row-wrapper'
                      style={{ flex: 'unset' }}
                    >
                      <div className='order-right-actions'>
                        {!isDisabled && (
                          <button
                            className='default-order-label'
                            type='button'
                            placeholder='Ordering Name'
                            style={{
                              backgroundColor: '#5A7491',
                              color: '#ffffff',
                              padding: 0,
                            }}
                            onClick={() =>
                              this.openAddOrderingModal('edit', orderKey)
                            }
                          >
                            Edit
                          </button>
                        )}
                        {order.isDefault && (
                          <div className='default-order-label'>
                            <span>default</span>
                          </div>
                        )}
                        <input
                          type='radio'
                          name={orderKey}
                          checked={order.isDefault}
                          onChange={this.onRadioChecked}
                        />
                      </div>
                      {!isDisabled && (
                        <CrossIcon
                          style={{ marginLeft: '5px' }}
                          onClick={() => this.removeOrder(orderKey)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {!isDisabled &&
                  order.fields?.map(field => {
                    return (
                      <div
                        className='wizard-criterion faceted-search-white-card faceted-search-ordering'
                        style={{ marginLeft: '3em' }}
                      >
                        <div
                          className='faceted-search-white-card-row'
                          style={{ justifyContent: 'space-between' }}
                        >
                          <div
                            className='faceted-search-white-card-row-wrapper'
                            style={{ flex: 'unset', alignItems: 'center' }}
                          >
                            <span
                              className='facet-type'
                              style={{ width: '150px', fontSize: '13px' }}
                            >
                              {field.productAccessor}
                            </span>
                            <Select
                              name='facet-component'
                              defaultClasses='is-select'
                              inputProps={{
                                value: field.direction,
                                onChange: e =>
                                  this.onDirectionChange(
                                    e,
                                    orderKey,
                                    field.productAccessor,
                                  ),
                              }}
                            >
                              <option value='desc'>descending</option>
                              <option value='asc'>ascending</option>
                            </Select>
                            <span
                              className='item-label'
                              onClick={() =>
                                this.onTextSortingChange(
                                  orderKey,
                                  field.productAccessor,
                                )
                              }
                            >
                              {t('Text')}{' '}
                              <span>
                                <Icons
                                  name={
                                    field.textSorting
                                      ? 'checkboxSelected'
                                      : 'checkboxEmpty'
                                  }
                                  width='12'
                                  height='12'
                                  style={{
                                    top: '2px',
                                    left: '5px',
                                    position: 'relative',
                                  }}
                                />
                              </span>
                            </span>
                          </div>
                          <div
                            className='faceted-search-white-card-row-wrapper'
                            style={{ flex: 'unset' }}
                          >
                            <CrossIcon
                              onClick={() =>
                                this.removeField(
                                  orderKey,
                                  field.productAccessor,
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {isSmartSorting &&
                  order.fields?.map(field => {
                    const rockScorePercentage = order.rockScorePercentage ?? 50; // 50 is default value
                    return (
                      <div
                        className='wizard-criterion faceted-search-white-card faceted-search-ordering'
                        style={{ marginLeft: '3em' }}
                      >
                        <div
                          className='faceted-search-white-card-row'
                          style={{ justifyContent: 'space-between' }}
                        >
                          <div
                            className='faceted-search-white-card-row-wrapper'
                            style={{ flex: 'unset', alignItems: 'center' }}
                          >
                            <span
                              className='facet-type'
                              style={{ width: '150px', fontSize: '13px' }}
                            >
                              {field.productAccessor}
                            </span>
                            <TextField
                              type='number'
                              name='facet-component'
                              defaultClasses='is-select'
                              onChange={e =>
                                this.rockScorePercentageChange(
                                  orderKey,
                                  e.target.value,
                                )
                              }
                              value={
                                field.productAccessor === 'rockScore'
                                  ? rockScorePercentage
                                  : 100 - rockScorePercentage
                              }
                              min='0'
                              max='100'
                              disabled={field.productAccessor !== 'rockScore'}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            );
          })}
        </CardWrapper>
        {modalMode && (
          <AddOrdering
            close={() => this.openAddOrderingModal(null, null)}
            order={orders[modalOrderKey] ?? {}}
            mode={modalMode}
            addOrderAction={this.addOrderAction}
            instanceId={instanceId}
            type={type}
          />
        )}
      </>
    );
  }
}

Ordering.defaultProps = {
  removeOlParentClass: true,
};

Ordering.propTypes = {
  type: PropTypes.oneOf(['instant', 'faceted']).isRequired,
  updateOrders: PropTypes.func.isRequired,
  instanceId: PropTypes.string.isRequired,
  removeOlParentClass: PropTypes.bool,
  orders: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
      isDefault: PropTypes.bool.isRequired,
      rockScorePercentage: PropTypes.number,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          productAccessor: PropTypes.string.isRequired,
          direction: PropTypes.string.isRequired,
          textSorting: PropTypes.bool.isRequired,
        }),
      ),
    }),
  ).isRequired,
};
