import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRestEndPoint } from 'system/api';
import { $http } from 'system/ajax';

export function transformUserType(obj) {
  return Object.keys(obj).map(key => {
    if (key === 'Normal User') return { label: 'Account User', value: key };
    return { label: key, value: key };
  });
}

const serialize = function(obj) {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export const getUserTypes = createAsyncThunk(
  'userManagement/getUserTypes',
  async () => {
    const url = getRestEndPoint('userManagement/getUserTypes');
    return await $http(url)
      .get()
      .catch(err => err);
  },
);
export const getUserTypeReducer = {
  [getUserTypes.pending]: state => {
    state.filter.userType.loading = true;
  },
  [getUserTypes.fulfilled]: (state, action) => {
    state.filter.userType.loading = false;
    state.filter.userType.data = transformUserType(action.payload);
  },
  [getUserTypes.rejected]: state => {
    state.filter.userType.loading = false;
    state.filter.userType.data = [];
  },
};

export const getUserList = createAsyncThunk(
  'userManagement/getUserList',
  async query => {
    let url = getRestEndPoint('userManagement/getUserList');
    url += `?${serialize(query)}`;
    return await $http(url)
      .post(query, { type: 'json' })
      .catch(err => err);
  },
);
export const getUserListReducer = {
  [getUserList.pending]: state => {
    state.users.loading = true;
    state.users.loaded = false;
  },
  [getUserList.fulfilled]: (state, action) => {
    state.users.loading = false;
    state.users.loaded = true;
    state.users.data = action.payload;
  },
  [getUserList.rejected]: state => {
    state.users.loading = false;
    state.users.loaded = false;
    state.users.data = [];
  },
};

export const getUserHistory = userName => {
  const url = getRestEndPoint('userManagement/getUserHistory').replace(
    '{username}',
    userName,
  );
  return $http(url).get();
};

export const deleteUser = createAsyncThunk(
  'userManagement/deleteUser',
  async userName => {
    const url = getRestEndPoint('userManagement/deleteUser').replace(
      '{username}',
      userName,
    );
    return $http(url).get();
  },
);

export const deleteUserReducer = {
  [deleteUser.pending]: state => {
    state.users.loading = true;
    state.users.loaded = false;
  },
  [deleteUser.fulfilled]: state => {
    state.users.loading = false;
    state.users.loaded = true;
  },
  [deleteUser.rejected]: state => {
    state.users.loading = false;
    state.users.loaded = false;
  },
};

export const editUser = createAsyncThunk(
  'userManagement/editUser',
  async query => {
    const url = `${getRestEndPoint('userManagement/editUser')}?${serialize(
      query,
    )}`;
    return await $http(url)
      .post(query, { type: 'json' })
      .catch(err => err);
  },
);

export const editUserReducer = {
  [editUser.pending]: state => {
    state.users.loading = true;
    state.users.loaded = false;
  },
  [editUser.fulfilled]: state => {
    state.users.loading = false;
    state.users.loaded = true;
  },
  [editUser.rejected]: state => {
    state.users.loading = false;
    state.users.loaded = false;
  },
};

export const unlockUser = createAsyncThunk(
  'userManagement/unlockUser',
  async userName => {
    const url = getRestEndPoint('userManagement/unlockUser').replace(
      '{username}',
      userName,
    );
    return $http(url).get();
  },
);

export const unlockUserReducer = {
  [unlockUser.pending]: state => {
    state.users.loading = true;
    state.users.loaded = false;
  },
  [unlockUser.fulfilled]: state => {
    state.users.loading = false;
    state.users.loaded = true;
  },
  [unlockUser.rejected]: state => {
    state.users.loading = false;
    state.users.loaded = false;
  },
};

export const getUserAccounts = createAsyncThunk(
  'userManagement/getUserAccounts',
  async () => {
    const url = getRestEndPoint('userManagement/getUserAccounts');
    return await $http(url)
      .get()
      .catch(err => err);
  },
);

export const getUserAccountsReducer = {
  [getUserAccounts.pending]: state => {
    state.filter.account.loading = true;
    state.filter.account.loaded = false;
  },
  [getUserAccounts.fulfilled]: (state, action) => {
    state.filter.account.loading = false;
    state.filter.account.loaded = true;
    if (Array.isArray(action.payload)) {
      state.filter.account.data = action.payload.map(item => ({
        label: item.domain,
        value: item.accountId,
      }));
    } else {
      throw new Error('Account list can not fetch');
    }
  },
  [getUserAccounts.rejected]: state => {
    state.filter.account.loading = false;
    state.filter.account.loaded = false;
    state.filter.account.data = [];
  },
};

export const searchUser = createAsyncThunk(
  'userManagement/searchUser',
  async parameters => {
    const { searchParam, query } = parameters;

    const url = getRestEndPoint('userManagement/searchUser').replace(
      '{param}',
      searchParam,
    );
    return $http(url).post(query, { type: 'json' });
  },
);

export const searchUserReducer = {
  [searchUser.fulfilled]: (state, action) => {
    state.users.data = action.payload;
  },
};
