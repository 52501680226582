import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { setTitle } from '../../../system/document';
import { clone } from '../../../system/object';
import { t } from '../../../system/ui';

import { uiActions, userActions } from '../../../actions';

import Form from '../../../components/form';
import {
  getUser,
  isSuperUser,
  isSwitchAccount,
  updateCurrency,
} from '../../../modules/auth/user';
import { UI_ACTIONS, initialState } from './constants';
import { updateForm } from './utils';

const SettingsCurrency = props => {
  const [state, setState] = useState(initialState);

  const forms = useRef(null);

  const { isLoading, isLoaded, showNotification } = uiActions;

  const updateHandler = e => {
    e.preventDefault();
    isLoading();

    setState(prevState => ({
      ...prevState,
      ui: UI_ACTIONS.IS_SUBMITTING,
    }));

    const account =
      isSuperUser(props.user) && isSwitchAccount()
        ? clone(isSwitchAccount())
        : clone(props.user.account);

    account.mainCurrency.code = forms.current.querySelector(
      '[name="code"]',
    ).value;
    account.mainCurrency.decimalSeparator = forms.current.querySelector(
      '[name="decimalSeparator"',
    ).value;
    account.mainCurrency.decimals = parseInt(
      forms.current.querySelector('[name="decimals"').value,
    );
    account.mainCurrency.symbol = forms.current.querySelector(
      '[name="symbol"',
    ).value;
    if (forms.current.querySelector('[name="symbolBefore"').value === 'true') {
      account.mainCurrency.symbolBefore = true;
    } else {
      account.mainCurrency.symbolBefore = false;
    }
    account.mainCurrency.thousandSeparator = forms.current.querySelector(
      '[name="thousandSeparator"',
    ).value;

    updateCurrency(account.mainCurrency, response => {
      if (response === '"Currency settings updated"') {
        if (isSwitchAccount()) {
          const newUserToSave = isSwitchAccount();
          newUserToSave.mainCurrency = account.mainCurrency;
          userActions.updateSwitchAccount(newUserToSave);
        } else {
          userActions.updateUserCurrency(account.mainCurrency);
        }
        isLoaded();
        setState(prevState => ({
          ...prevState,
          ui: UI_ACTIONS.IS_LOADED,
        }));
      } else {
        isLoaded();
        setState(prevState => ({
          ...prevState,
          ui: UI_ACTIONS.IS_LOADED,
        }));
      }

      const notificationContent = () => (
        <>{t('You have successfully updated currency...')}</>
      );
      showNotification({ content: notificationContent });
    });
  };

  const fetchItems = () => {
    if (isSwitchAccount()) {
      updateForm({ account: isSwitchAccount(), stateSetter: setState });
      isLoaded();
    } else {
      getUser(response => {
        updateForm({ account: response.account, stateSetter: setState });
        isLoaded();
      });
    }
  };

  useEffect(() => {
    setTitle(t('Currency'));
    isLoading();
    fetchItems();
  }, []);

  return (
    <>
      <h2 className='page-title'>{t('Currency')}</h2>
      <div className='page-forms' ref={forms}>
        <div className='page-content-block one-half'>
          <form id='currency-form' onSubmit={updateHandler}>
            <p className='form-info'>{t('Main Currency')}</p>
            <Form
              fields={state.fields}
              buttons={state.buttons}
              ui={state.ui}
              {...props}
            />
          </form>
        </div>
      </div>
    </>
  );
};

const mapStatesToProps = store => ({
  user: store.user.user,
  validate: store.ui.validate,
  language: store.ui.language,
});

export default connect(mapStatesToProps)(SettingsCurrency);
