import api from '../../system/api';
import { plainGet } from '../../system/ajax';

import { getApiKey } from '../auth/user';

export const searchCategories = (keyword, callback) => {
  plainGet(
    api.getPath() +
      'search/category/?query=' +
      encodeURI(keyword) +
      '&apiKey=' +
      getApiKey(),
    callback,
  );
};

export const searchRbsCategories = (keyword, callback) => {
  plainGet(
    api.getPath() +
      'search/rbs/category/?query=' +
      encodeURI(keyword) +
      '&apiKey=' +
      getApiKey(),
    callback,
  );
};
