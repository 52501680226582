import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  changeWidgetItem,
  toggleButton,
  changeDelayTime,
  changeDelayUnit,
  changeAddTitles,
  changeAddDescriptions,
  changeIcon,
  changeImage,
  changeAdditionalImages,
  changeAdditionalIcons,
  changeTarget,
  changeActions,
  changeCouponCode,
  changeCouponExpire,
  resetAbandon,
  changeSelectedLanguage,
  changeCouponBasketAmount,
} from '../../actions/abandon';
import { fetchAbandon, saveAbandon } from '../../actions/abandon.database';
import { uiActions, wizardActions } from '../../actions';
import AbandonmentRow from '../../components/push/abandonment-row';
import ReactPlaceholder from '../../components/shared/react-placeholder';
import { isSwitchAccount } from '../../modules/auth/user';
import HideInformation from '../../modules/campaign/fields/hideInformation';
import HidePrice from '../../modules/campaign/fields/hidePrice';
import PushLimitedRecommendations from '../../modules/campaign/fields/pushLimitedRecommendations';
import { setTitle } from '../../system/document';
import { t } from '../../system/ui';
import RightWizardContainer from './right-wizard-container';

class AbandonedCartContainer extends Component {
  constructor(props) {
    super(props);

    if (!this.isNew()) {
      props.fetchAbandon();
    }
    uiActions.showBreadcrumb({
      home: {
        name: t('Web Push'),
        icon: 'pushModule',
        slug: '/push/view-all',
        iconSvg: true,
      },
      top: {
        name: 'Retention',
        slug: '/push/retention',
      },
      current: {
        name: 'Abandoned Cart',
      },
    });
    this.account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;

    setTitle(t('Edit Abandonment Cart'));
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (newProps.save !== false) {
      const { mainLanguage } = this.account;
      this.props.saveAbandon(newProps.save, this.isNew(), mainLanguage);
      wizardActions.saveReset();
    }

    if (
      newProps.route.path !== this.props.route.path &&
      newProps.route.path === '/push/edit/ABANDONED_CART'
    ) {
      this.props.fetchAbandon();
    }
  }

  componentWillUnmount() {
    this.props.resetAbandon();
  }

  isNew() {
    const { path } = this.props.route;
    return path === '/push/add/ABANDONED_CART';
  }

  showLoading() {
    if (this.isNew()) {
      return false;
    }

    return this.props.isFetching;
  }

  render() {
    const campaign = {
      type: 'ABANDONED_CART',
    };

    const account = isSwitchAccount()
      ? this.props.switchedUser.account
      : this.props.user.account;

    const buttons = [
      { field: 'previewDialog' },
      { field: 'goTest', isPush: true, includeHiddens: true },
      { field: 'goLive', isPush: true, includeHiddens: true },
    ];

    return (
      <div className='wizard'>
        <div>
          <h3 className='page-title'>
            {!this.isNew() && (
              <span
                className={
                  this.props.status.toLowerCase() === 'active'
                    ? 'status-label status-label-live'
                    : 'status-label status-label-test'
                }
              >
                {this.props.status.toLowerCase() === 'active'
                  ? 'live'
                  : this.props.status}
              </span>
            )}
            {t('Abandoned Cart')}
          </h3>
          <div className='widget page-content-block page-content-block-no-padding'>
            <div className='wizard wizard-push-fields wizard-push-disabled-fields'>
              <ReactPlaceholder isLoading={this.showLoading()}>
                <div className='form-elements'>
                  <div className='wizard-cards'>
                    <PushLimitedRecommendations
                      title={
                        <span style={{ fontWeight: 600 }}>
                          {t('Card Abandonment Flow')}
                        </span>
                      }
                      subTitle={t(
                        'Remind your customers about their card with an effective push notification flow, recommend alternative products or given coupon',
                      )}
                    />
                  </div>
                </div>
                <div>
                  <AbandonmentRow
                    number={1}
                    name='FIRST_REMINDER'
                    title={t('First Reminder')}
                    tooltip={t(
                      'Informs your customers about the items they left in their carts',
                    )}
                    {...this.props}
                  />
                  <AbandonmentRow
                    number={2}
                    name='SECOND_REMINDER'
                    title={t('Second Reminder')}
                    tooltip={t(
                      'Reminds your customers about the last item they left in their carts',
                    )}
                    {...this.props}
                  />
                  <AbandonmentRow
                    number={3}
                    name='ALTERNATIVE_PRODUCTS'
                    title={t('Alternative Products')}
                    tooltip={t(
                      'Suggest alternative product to customers based on abandoned items in their carts',
                    )}
                    {...this.props}
                  />
                  <AbandonmentRow
                    number={4}
                    isLast
                    name='COUPON'
                    title={t('Coupon')}
                    tooltip={t(
                      'Gives a coupon code to customers who has abandoned products in their carts',
                    )}
                    {...this.props}
                  />
                </div>
                <div className='wizard-input-type-wrapper wizard-input-criteria wizard-email-input-type-wrapper'>
                  <div className='wizard-criteria wizard-items-container'>
                    <div className='wizard-item-draggable wizard-item-cards-draggable'>
                      <div className='wizard-items-container' />
                    </div>
                  </div>
                </div>
                <div className='form-elements'>
                  <div className='wizard-cards'>
                    <HidePrice campaign={campaign} />
                    <HideInformation campaign={campaign} />
                  </div>
                </div>
                <div className='wizard-input-type-wrapper wizard-input-criteria wizard-email-input-type-wrapper'>
                  <div className='wizard-criteria wizard-items-container'>
                    <div className='wizard-item-draggable wizard-item-cards-draggable'>
                      <div className='wizard-items-container' />
                    </div>
                  </div>
                </div>
              </ReactPlaceholder>
            </div>
          </div>
        </div>
        <RightWizardContainer
          campaign={campaign}
          buttons={buttons}
          mainLanguage={account.mainLanguage}
          additionalLanguages={account.additionalLanguages}
          additionalCurrencyList={account.additionalCurrencyList}
          mainCurrency={account.mainCurrency}
        />
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  wizardLanguage: store.wizard.wizardForm.language,
  save: store.wizard.save,
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
  abandon: store.abandon,
  isFetching: store.abandon.isFetching,
  status: store.abandon.status,
  hidePrice: store.wizard.wizardForm.hidePrice,
  hideInformation: store.wizard.wizardForm.hideInformation,
});

const mapDispatchToProps = dispatch => ({
  changeWidgetItem: (name, value) => dispatch(changeWidgetItem(name, value)),
  resetAbandon: () => dispatch(resetAbandon()),
  fetchAbandon: () => dispatch(fetchAbandon()),
  saveAbandon: (isTest, isNew, mainLanguage) =>
    dispatch(saveAbandon(isTest, isNew, mainLanguage)),
  toggleButton: name => dispatch(toggleButton(name)),
  changeDelayTime: (name, selected) =>
    dispatch(changeDelayTime(name, selected)),
  changeDelayUnit: (name, unit) => dispatch(changeDelayUnit(name, unit)),
  changeAddTitles: (name, titlesObj) =>
    dispatch(changeAddTitles(name, titlesObj)),
  changeAddDescriptions: (name, descObj) =>
    dispatch(changeAddDescriptions(name, descObj)),
  changeTarget: (name, target) => dispatch(changeTarget(name, target)),
  changeIcon: (name, icon) => dispatch(changeIcon(name, icon)),
  changeImage: (name, image) => dispatch(changeImage(name, image)),
  changeAdditionalIcons: (name, additionalIcons) =>
    dispatch(changeAdditionalIcons(name, additionalIcons)),
  changeAdditionalImages: (name, additionalImages) =>
    dispatch(changeAdditionalImages(name, additionalImages)),
  changeActions: (name, actions) => dispatch(changeActions(name, actions)),
  changeCouponCode: (name, code) => dispatch(changeCouponCode(name, code)),
  changeSelectedLanguage: (name, language) =>
    dispatch(changeSelectedLanguage(name, language)),
  changeCouponExpire: (name, code) => dispatch(changeCouponExpire(name, code)),
  changeCouponBasketAmount: (name, code) =>
    dispatch(changeCouponBasketAmount(name, code)),
});

export default connect(
  mapStatesToProps,
  mapDispatchToProps,
)(AbandonedCartContainer);
