/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { t } from "../../system/ui";

import {
  searchProducts,
  searchMultipleProducts
} from "../../modules/product/ajax";

import Icon from "../icon";
import Icons from "../icons";
import { decodeHtml } from "../../system/string";

class ExcludesProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autocompleteVisible: true,
      products: [],
      criterion: "",
      productId: "",
      selected: false,
      preloader: "",
      hasError: false,
      showRequiredError: false,
      selectedOption: ""
    };

    this.setDefaults = this.setDefaults.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectProduct = this.selectProduct.bind(this);
    this.closeAutocomplete = this.closeAutocomplete.bind(this);
    this.validate = this.validate.bind(this);
    this.setStaticProducts = this.setStaticProducts.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.timeout = false;
  }

  componentDidMount() {
    this.setStaticProducts(this.props);
  }

  componentWillReceiveProps(newprops) {
    if (newprops.validate === true) {
      this.validate();
    }
  }

  componentDidUpdate(oldprops, oldstates) {
    if (oldstates.productId !== this.state.productId) {
      this.props.onExclusionChange(this.refs.inputs, this.props.id);
      this.validate();
    }
  }

  setStaticProducts(newProps) {
    let defaultCriterion = "",
      defaultProductId = "";
    if (Object.keys(newProps.values).length > 0) {
      if (newProps.values.productId) {
        defaultProductId = newProps.values.productId
          ? newProps.values.productId.value
          : "";
      } else {
        defaultProductId = newProps.values.product
          ? newProps.values.product.value
          : "";
      }
      let products = [];
      if (defaultProductId !== "") {
        searchMultipleProducts(defaultProductId, product => {
          if (product) {
            product.forEach(item => {
              products.push({
                value: item.productId,
                title: item.productId + " - " + decodeHtml(item.name),
                label: decodeHtml(item.name)
              });
            });
            this.setState({
              products: products,
              selected: true,
              selectedOption: products
            });
          }
        });
      } else {
        this.setDefaults(defaultCriterion, defaultProductId);
      }
    } else {
      this.validate();
    }
  }

  setDefaults(defaultCriterion, defaultProductId) {
    this.setState({
      criterion: defaultCriterion,
      productId: defaultProductId,
      selected: defaultProductId ? true : false
    });
  }

  closeAutocomplete() {
    setTimeout(() => {
      if (this.state.selected) {
        this.setState({
          autocompleteVisible: false
        });
      } else {
        this.setState({
          criterion: "",
          productId: "",
          autocompleteVisible: false
        });
      }
    }, 250);
  }

  selectProduct(product) {
    this.setState({
      criterion: product.productId + " - " + product.name,
      productId: product.productId,
      autocompleteVisible: false,
      selected: true
    });
  }

  onChange(e) {
    let keyword = "",
      name = "";

    clearTimeout(this.timeout);

    if (Object.keys(this.props.values).length > 0) {
      name = this.props.values.criterion
        ? this.props.values.criterion.value
        : "";
      keyword = this.props.values.productId
        ? this.props.values.productId.value
        : "";
    }

    if (typeof e !== "undefined") {
      keyword = e.currentTarget.value;
      name = e.currentTarget.value;
    }

    this.setState({
      criterion: name.replace(this.refs.productId.value + " - ", ""),
      selected: false
    });

    if (keyword.length > 2) {
      this.timeout = setTimeout(() => {
        this.setState({
          preloader: "isLoading"
        });

        searchMultipleProducts(keyword, response => {
          this.setState({
            products: response,
            autocompleteVisible: true,
            preloader: "isLoaded"
          });
        });
      }, 500);
    }
  }

  validate() {
    let input = this.refs.productId;

    if (input.value === "") {
      this.setState({
        hasError: true,
        showRequiredError: true
      });
    } else {
      this.setState({
        hasError: false,
        showRequiredError: false
      });
    }
  }

  handleChange(selectedOption) {
    this.setState({ selectedOption }, () => {
      this.validate();
      this.props.onExclusionSelectChange(
        "productId",
        selectedOption,
        this.props.id
      );
    });
  }

  onInputChange(value) {
    if (value.length > 2) {
      searchProducts(value, response => {
        let productOptions = [];
        if (response) {
          response.forEach(product => {
            productOptions.push({
              value: product.productId,
              title: product.productId + " - " + decodeHtml(product.name),
              label: decodeHtml(product.name)
            });
          });
        }
        this.setState({
          products: productOptions
        });
      });
    } else {
      this.setState({
        products: []
      });
    }
  }

  render() {
    return (
      <li className="wizard-criterion" ref="inputs">
        <span className="criteria-type">
          <Icon name="product" color="#787774" />
        </span>
        <div className="wizard-criterion-text">
          <h3 className="wizard-criterion-title">{t("Product")}</h3>
          <p className="wizard-criterion-description">
            {t(
              "Exclude the products you don't want showing up in your recommendation."
            )}
          </p>
        </div>
        <span className="wizard-product-name wizard-product-select-container">
          <Select
            name="criterion"
            value={this.state.selectedOption}
            onChange={this.handleChange}
            onInputChange={this.onInputChange}
            options={this.state.products}
            searchable={true}
            multi={true}
            ref="productId"
            clearable={false}
            placeholder={t("Search Product")}
          />
        </span>

        <a
          onClick={this.props.onExclusionRemove.bind(null, this.props.id)}
          className="cancel-action recommend-filter-cancel"
        >
          <Icons name="crossCircle" />
        </a>
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  criteria: store.wizard.criteria,
  validate: store.ui.validate,
  user: store.user.user
});

export default connect(mapStatesToProps)(ExcludesProduct);
