import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';

import { filterActions } from '../../actions';
import { t } from '../../system/ui';

class DeviceSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 'all',
    };

    this.reset = this.reset.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    if (this.props.onDeviceChange) {
      this.onChange({ value: this.props.selectedDevice || 'all' });
    }
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.reset === true ||
      (newProps.hasOwnProperty('resetDevice') && newProps.resetDevice === true)
    ) {
      this.reset();
    }
    if (
      newProps.selectedDevice !== this.props.selectedDevice &&
      this.props.onDeviceChange
    ) {
      this.onChange({ value: newProps.selectedDevice });
    }
  }

  onChange(selectedOption) {
    this.setState(
      {
        selected: selectedOption.value,
      },
      () => {
        if (this.props.onDeviceChange) {
          this.props.onDeviceChange(selectedOption.value);
        }
      },
    );

    filterActions.showButtons();
  }

  reset() {
    this.setState({
      selected: 'all',
    });

    filterActions.filtersReset();
  }

  render() {
    const options = [
      { value: 'all', label: t('All') },
      { value: 'pc', label: t('PC') },
      { value: 'tablet', label: t('Tablet') },
      { value: 'mobile', label: t('Mobile') },
      { value: 'android', label: t('Android') },
      { value: 'ios', label: t('iOS') },
    ];

    return (
      <label className='item item-stacked' style={{ width: '100px' }}>
        <span className='item-label'>{t('Devices')}</span>
        <Select
          options={options}
          value={this.state.selected}
          ref='visitorDevice'
          id='visitorDevice'
          name='visitorDevice'
          clearable={false}
          searchable={false}
          onChange={this.onChange}
        />
      </label>
    );
  }
}

const mapStatesToProps = store => ({
  reset: store.filters.reset,
});

export default connect(mapStatesToProps)(DeviceSelect);
