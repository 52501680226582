import React from 'react';
import { statTypes } from '../../../../../constants/datamaps';
import { t } from '../../../../../system/ui';
import {
  localeString,
  localeStringMoney,
  toLocaleStringDigits,
} from '../../../../../system/string';
import Currency from '../../../../../components/currency';
import Icon from '../../../../../components/icon';
import Tooltip from '../../../../../components/tooltip';
import { findLongestStat } from '../../../../../components/reports/utils';

const StatsScreen = ({ hasKlaviyoConfiguration, trends }) => {
  const longestStat = findLongestStat(trends);
  if (!hasKlaviyoConfiguration) {
    return (
      <div className='page-content-block'>
        <div className='widget-stat-list widget-report'>
          <div className='widget-stats'>
            {Object.keys(trends).map((item, index) => {
              const stat = trends[item].stats;
              const type = statTypes[item];
              const mainTitle = type.name;
              const className = `widget-stat stat-${item.toLowerCase()}`;
              const originalStatVal =
                item !== 'widget' ? stat.all.count : stat.view.count;

              const statName = item;
              let emailTypeName = false;
              if (mainTitle === 'Widget') {
                emailTypeName = 'Email';
              } else if (mainTitle === 'Revenue') {
                emailTypeName = 'Email Conversion';
              } else {
                emailTypeName = mainTitle;
              }

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={className} key={`${item}-${index}`}>
                  <h4 className='widget-stat-title'>{t(emailTypeName)}</h4>
                  <p
                    className='widget-stat-count'
                    title={toLocaleStringDigits(originalStatVal)}
                  >
                    {type.currency === true
                      ? localeStringMoney(originalStatVal)
                      : localeString(originalStatVal)}
                  </p>
                  <p className='widget-stat-type'>
                    {type.currency === true ? (
                      <Currency />
                    ) : (
                      <Icon name={type.icon} />
                    )}
                    {type.currency !== true
                      ? t(type.unit === 'views' ? 'opens' : type.unit)
                      : ''}
                  </p>
                  <p key={item} className='widget-stat-name'>
                    {mainTitle === 'Widget' && t('Emails Sent')}
                    {mainTitle === 'Widget' && (
                      <span className='widget-stat-conversion-amount'>
                        {localeString(stat.all.count)}
                      </span>
                    )}
                  </p>
                  {/* eslint-disable-next-line no-shadow */}
                  {Object.keys(stat).map((item, index) => {
                    const isLastItem = index + 1 === Object.keys(stat).length;
                    const singleStat = stat[item];
                    let singleStatData;
                    if (
                      item === 'all' ||
                      singleStat.name === 'Actual View' ||
                      singleStat.name === 'Average Order Value'
                    ) {
                      return;
                    }

                    if (singleStat.currency) {
                      singleStatData = (
                        <Currency
                          currency={localeStringMoney(singleStat.count)}
                        />
                      );
                    } else {
                      singleStatData =
                        localeString(singleStat.total) ||
                        localeString(singleStat.count) ||
                        `${localeString(singleStat.ratio)}%`;
                    }
                    const bounceback = () => {
                      if (singleStat.name === 'Bounce Back') {
                        return (
                          <Tooltip
                            content={
                              <>
                                <span>
                                  Hard Bounce : {singleStat.HardBounce}
                                </span>
                                <br />
                                <span>
                                  Soft Bounce : {singleStat.SoftBounce}
                                </span>
                              </>
                            }
                            alignment='top'
                            newTooltip
                          >
                            {t(`${emailTypeName} ${singleStat.name}`)};
                            <span className='widget-stat-data'>
                              {localeString(
                                singleStat.HardBounce + singleStat.SoftBounce,
                              )}
                            </span>
                          </Tooltip>
                        );
                      }
                      if (singleStat.name === 'Unsubscribed') {
                        return (
                          <>
                            {t(`${emailTypeName} ${singleStat.name}`)}
                            <span className='widget-stat-data'>
                              {singleStatData}
                            </span>
                          </>
                        );
                      }
                      if (emailTypeName === 'Email Conversion') {
                        return (
                          <>
                            {t(`${singleStat.name}`)}
                            <span className='widget-stat-data'>
                              {singleStatData}
                            </span>
                          </>
                        );
                      }
                      return (
                        <>
                          {singleStat.name?.includes('Spam') ? null : (
                            <>
                              {t(`${emailTypeName}${singleStat.name}`)}
                              <span className='widget-stat-data'>
                                {singleStatData}
                              </span>
                            </>
                          )}
                        </>
                      );
                    };
                    // eslint-disable-next-line consistent-return
                    return (
                      <>
                        {typeof singleStat.name !== 'undefined' && (
                          <p
                            key={item}
                            className={`widget-stat-name${
                              statName !== longestStat && isLastItem
                                ? 'widget-stat-conversion-bottom'
                                : ''
                            }`}
                          >
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {mainTitle === 'Widget' ||
                            mainTitle === 'Interaction' ||
                            mainTitle === 'Basket' ||
                            mainTitle === 'Revenue'
                              ? bounceback()
                              : ''}
                            {singleStat.tooltip && (
                              <span className='stat-tooltip'>
                                <Tooltip
                                  content={singleStat.tooltip}
                                  alignment='top'
                                  newTooltip
                                >
                                  <Icon name='info' />
                                </Tooltip>
                              </span>
                            )}
                          </p>
                        )}
                      </>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className='page-content-block'>
      <div className='widget-stat-list widget-report'>
        <div className='widget-stats'>
          {Object.keys(trends).map((item, index) => {
            const stat = trends[item].stats;
            const type = statTypes[item];
            const mainTitle = type.name;
            const className = `widget-stat stat-${item.toLowerCase()}`;
            const originalStatVal =
              item !== 'widget' ? stat.all.count : stat.view.count;

            const statName = item;
            let emailTypeName = false;
            if (mainTitle === 'Widget') {
              emailTypeName = 'Email';
            } else if (mainTitle === 'Revenue') {
              emailTypeName = 'Email Conversion';
            } else {
              emailTypeName = mainTitle;
            }

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className={className} key={`${item}-${index}`}>
                <h4 className='widget-stat-title'>{t(emailTypeName)}</h4>
                {item !== 'widget' && (
                  <p
                    className='widget-stat-count'
                    title={toLocaleStringDigits(originalStatVal)}
                  >
                    {type.currency === true
                      ? localeStringMoney(originalStatVal)
                      : localeString(originalStatVal)}
                  </p>
                )}
                {item !== 'widget' && (
                  <p className='widget-stat-type'>
                    {type.currency === true ? (
                      <Currency />
                    ) : (
                      <Icon name={type.icon} />
                    )}
                    {type.currency !== true
                      ? t(type.unit === 'views' ? 'opens' : type.unit)
                      : ''}
                  </p>
                )}
                <p className='widget-stat-conversion'>
                  {item === 'widget' && t('Triggered To Flow')}
                  {item === 'widget' && (
                    <span className='widget-stat-data'>
                      {localeString(stat.klaviyo.flow)}
                    </span>
                  )}
                </p>
                <p className='widget-stat-conversion'>
                  {item === 'widget' && t('Triggered To Profile')}
                  {item === 'widget' && (
                    <span className='widget-stat-data'>
                      {localeString(stat.klaviyo.profile)}
                    </span>
                  )}
                </p>
                {/* eslint-disable-next-line no-shadow */}
                {Object.keys(stat).map((item, index) => {
                  const isLastItem = index + 1 === Object.keys(stat).length;
                  const singleStat = stat[item];
                  let singleStatData;
                  if (
                    item === 'all' ||
                    item === 'klaviyo' ||
                    singleStat.name === 'Actual View'
                  ) {
                    return;
                  }
                  if (mainTitle === 'Widget' || mainTitle === 'Interaction') {
                    if (item === 'conversion') {
                      return;
                    }
                  }

                  if (singleStat.currency) {
                    singleStatData = (
                      <Currency
                        currency={localeStringMoney(singleStat.count)}
                      />
                    );
                  } else if (
                    mainTitle !== 'Widget' &&
                    mainTitle !== 'Interaction'
                  ) {
                    singleStatData =
                      localeString(singleStat.count) ||
                      `${localeString(singleStat.ratio)}%`;
                  }
                  // eslint-disable-next-line consistent-return
                  return (
                    <p
                      key={item}
                      style={{ bottom: '-25px' }}
                      className={`widget-stat-name ${
                        statName !== longestStat && isLastItem
                          ? 'widget-stat-conversion-bottom'
                          : ''
                      }`}
                    >
                      {mainTitle === 'Basket'
                        ? t(emailTypeName + singleStat.name)
                        : t(singleStat.name)}
                      {singleStat.tooltip && (
                        <span className='stat-tooltip'>
                          <Tooltip
                            content={singleStat.tooltip}
                            alignment='top'
                            newTooltip
                          >
                            <Icon name='info' />
                          </Tooltip>
                        </span>
                      )}
                      <span className='widget-stat-data'>{singleStatData}</span>
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StatsScreen;
