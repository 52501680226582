import React, { Component } from "react";
import { connect } from "react-redux";
import Icons from "../../components/icons";
import { t } from "../../system/ui";
import { addCampaign } from "../../modules/campaigns/ajax";
import { modalActions, uiActions } from "../../actions";
import {isMultiAccountUser} from "../auth/user";

class CampaignReplicateModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAccounts: [],
      accountsToSave: 0,
      accountsToSaveLeft: 0,
      buttonText: "Replicate Campaign",
      buttonLoading: false
    };

    this.toggleAccount = this.toggleAccount.bind(this);
    this.toggleAllStatus = this.toggleAllStatus.bind(this);
    this.duplicateCampaign = this.duplicateCampaign.bind(this);
  }

  toggleAccount(accountId) {
    let newSelected = [];
    if (this.state.selectedAccounts.indexOf(accountId) > -1) {
      newSelected = this.state.selectedAccounts.filter(selectedAccountId => {
        return selectedAccountId !== accountId;
      });
    } else {
      newSelected = this.state.selectedAccounts.concat([accountId]);
    }
    this.setState({
      selectedAccounts: newSelected
    });
  }

  toggleAllStatus(isAllSelected) {
    let selectedAccounts = [];
    if (isAllSelected) {
      selectedAccounts = [];
    } else {
      selectedAccounts = this.props.switchedUser.switchableAccountsWithDetails.map(
        account => {
          return account.accountId;
        }
      );
      selectedAccounts = selectedAccounts.filter(account => {
        return account.accountId !== this.props.switchedUser.account.accountId;
      });
    }
    this.setState({
      selectedAccounts: selectedAccounts
    });
  }

  duplicateCampaign() {
    let actualCampaign = this.props.campaign;
    this.setState(
      {
        accountsToSave: this.state.selectedAccounts.length,
        accountsToSaveLeft: this.state.selectedAccounts.length
      },
      () => {
        this.state.selectedAccounts.forEach(accountId => {
          let campaign = Object.assign({}, actualCampaign);
          let account = this.props.switchedUser.switchableAccountsWithDetails.filter(
            account => {
              return account.accountId === accountId;
            }
          );
          account = account[0];
          campaign.accountId = account.accountId;
          campaign.testMode = true;
          campaign.instanceId = undefined;
          this.setState(
            {
              buttonText: "0 of " + this.state.accountsToSave,
              buttonLoading: true
            },
            () => {
              addCampaign(
                campaign,
                () => {
                  let leftAccounts = this.state.accountsToSaveLeft - 1;
                  this.setState(
                    {
                      accountsToSaveLeft: leftAccounts
                    },
                    () => {
                      let buttonText =
                        this.state.accountsToSave -
                        leftAccounts +
                        " of " +
                        this.state.accountsToSave;
                      let buttonLoading = leftAccounts > 0;
                      if (!buttonLoading) {
                        buttonText = "Replicate Campaign";
                        modalActions.closeModal();
                        let message = () => (
                          <p>
                            {t(
                              "The campaign has been replicated to your selected accounts."
                            )}
                          </p>
                        );
                        uiActions.showNotification({ content: message });
                      }
                      this.setState({
                        buttonText,
                        buttonLoading
                      });
                    }
                  );
                },
                account.apiKey,
                account.dataCenterUrl,
                isMultiAccountUser(),
              );
            }
          );
        });
      }
    );
  }

  render() {
    let switchableAccounts =
      this.props.switchedUser.switchableAccountsWithDetails &&
      this.props.switchedUser.switchableAccountsWithDetails.filter(account => {
        return (
          account.accountId !== this.props.switchedUser.account.accountId &&
          this.props.switchedUser.switchableAccounts.indexOf(
            account.accountId
          ) > -1
        );
      });
    if (!switchableAccounts) {
      switchableAccounts = [];
    }
    let isAllSelected = true;
    if (switchableAccounts) {
      switchableAccounts.forEach(account => {
        if (this.state.selectedAccounts.indexOf(account.accountId) < 0) {
          isAllSelected = false;
        }
      });
    }
    return (
      <div className="multiaccount-replicate">
        <h3>Accounts</h3>

        <div className="multiaccount-replicate-selectAll">
          {switchableAccounts && (
            <span>
              <label className="item item-stacked is-checkbox">
                <input
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={this.toggleAllStatus.bind(this, isAllSelected)}
                />
                <span className="item-label">{t("Select All")}</span>
              </label>
            </span>
          )}
        </div>

        <ul className="multiaccount-replicate-list">
          {switchableAccounts.map(account => {
            let isSelected =
              this.state.selectedAccounts.indexOf(account.accountId) > -1;
            return (
              <li onClick={this.toggleAccount.bind(this, account.accountId)}>
                <div>
                  <Icons
                    name={isSelected ? "checkboxSelected" : "checkboxEmpty"}
                    width="20"
                    height="20"
                  />
                </div>
                <div>
                  <span>{account.domain}</span>
                  <p>{account.description}</p>
                </div>
              </li>
            );
          })}
        </ul>

        <div className="user-accounts-select-button">
          <button
            className={
              this.state.buttonLoading
                ? "tertiary-action disabled"
                : "tertiary-action"
            }
            onClick={this.duplicateCampaign}
          >
            {t(this.state.buttonText)}
          </button>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(CampaignReplicateModal);
