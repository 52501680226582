import { t } from '../../../system/ui';

const breadCrumbOptions = module => {
  if (module === 'RECOMMENDATION') {
    return {
      home: {
        name: t('Recommend'),
        icon: 'heart',
        slug: '/recommendations',
      },
      current: {
        name: 'A/B Test',
      },
    };
  }
  if (module === 'BT') {
    return {
      home: {
        name: t('Engagement'),
        icon: 'heart',
        slug: '/behavioural-targeting/engagement/view-all',
      },
      current: {
        name: 'A/B Test',
      },
    };
  }
};

const getDynamicBreadCrumbOptions = (currentName, module) => {
  if (module === 'RECOMMENDATION') {
    return {
      home: {
        name: t('Recommend'),
        icon: 'heart',
        slug: '/recommendations',
        iconSvg: true,
      },
      top: {
        name: t('A/B Test'),
        slug: '/recommendations/experimentation/ab_test/list',
      },
      current: {
        name: currentName,
      },
    };
  }
  if (module === 'BT') {
    return {
      home: {
        name: t('Engagement'),
        icon: 'heart',
        slug: '/behavioural-targeting/engagement/view-all',
      },
      top: {
        name: t('A/B Test'),
        slug: '/behavioural-targeting/experimentation/ab_test/list',
      },
      current: {
        name: currentName,
      },
    };
  }
};

const actions = [
  {
    name: 'Remove',
    superUserOnly: true,
    iconSvg: true,
    icon: 'crossCircle',
    colorName: 'red',
    action: 'remove',
  },
];

export { breadCrumbOptions, getDynamicBreadCrumbOptions, actions };
