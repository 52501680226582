import React, { useState } from 'react';
import './style.scss';

import { bannerItems } from '../msp-static-data';

import SubPageWelcome from './SubPageWelcome';
import SubPageMyAccounts from './SubPageMyAccounts';

const subPages = [
  {
    title: 'Welcome',
    component: <SubPageWelcome />,
  },
  {
    title: 'My Accounts',
    component: <SubPageMyAccounts />,
  },
];

const index = () => {
  const [selectedSubPageIndex, setSelectedSubPageIndex] = useState(0);

  return (
    <div className='msp-homepage'>
      <div className='msp-homepage-header'>
        <div className='msp-subPageSelector'>
          <nav className='msp-subPageSelector-nav'>
            {subPages.map((item, _index) => {
              const isThisActive = _index === selectedSubPageIndex;
              return (
                <div
                  key={item.title}
                  className={`nav-item ${isThisActive && 'active'}`}
                >
                  <button
                    type='button'
                    onClick={() => setSelectedSubPageIndex(_index)}
                    disabled={isThisActive}
                  >
                    <span className='button-text'>{item.title}</span>
                  </button>
                </div>
              );
            })}
          </nav>
        </div>
        <div className='msp-search'>
          <input
            className='msp-search-input'
            type='text'
            placeholder='Search'
          />
          <div className='input-icon'>
            <i className='icon-magnify' role='presentation' />
          </div>
        </div>
      </div>
      <div className='msp-homepage-content'>
        {selectedSubPageIndex === 0 && (
          <SubPageWelcome bannerItems={bannerItems} />
        )}
        {selectedSubPageIndex === 1 && <SubPageMyAccounts />}
      </div>
    </div>
  );
};

export default index;
