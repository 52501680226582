import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const LastVisitAlternative = ({ width, height }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.5911 0.513006C9.4947 0.146706 10.5053 0.146706 11.4089 0.513006L18.9075 3.55298C19.5679 3.82071 20 4.46216 20 5.17477V14.501C20 13.0394 19.4299 11.711 18.5 10.726V5.77292L10.7503 8.7651V10.4773C10.062 11.119 9.5385 11.9353 9.2503 12.8556V8.7638L1.5 5.74979V14.8275C1.5 14.9293 1.56173 15.0209 1.65607 15.0592L9.1547 18.0991C9.1788 18.1089 9.203 18.1183 9.2274 18.1272L9.439 18.2132C9.7699 18.3478 10.1369 18.3594 10.474 18.2482C10.8682 18.6715 11.3284 19.0327 11.8383 19.3152L11.4089 19.4892C10.5053 19.8555 9.4947 19.8555 8.5911 19.4892L1.09252 16.4493C0.43211 16.1815 0 15.5401 0 14.8275V5.17477C0 4.46216 0.43211 3.82071 1.09252 3.55298L8.5911 0.513006ZM10.8453 1.90312C10.3032 1.68334 9.6968 1.68334 9.1547 1.90312L7.24097 2.67894L14.7678 5.60604L17.437 4.57542L10.8453 1.90312ZM12.6911 6.40787L5.21472 3.50039L2.59029 4.56435L10.0013 7.44642L12.6911 6.40787ZM20 14.5632V14.501C20 14.5218 20.0002 14.5425 20 14.5632ZM18.1681 17.1084C18.6919 16.3728 19 15.4728 19 14.501C19 12.0157 16.9853 10.001 14.5 10.001C12.0147 10.001 10 12.0157 10 14.501C10 16.9863 12.0147 19.001 14.5 19.001C15.4719 19.001 16.3718 18.6929 17.1074 18.169L19.7197 20.7813C20.0126 21.0742 20.4874 21.0742 20.7803 20.7813C21.0732 20.4884 21.0732 20.0135 20.7803 19.7206L18.1681 17.1084ZM17.5 14.501C17.5 16.1578 16.1569 17.501 14.5 17.501C12.8431 17.501 11.5 16.1578 11.5 14.501C11.5 12.8441 12.8431 11.501 14.5 11.501C16.1569 11.501 17.5 12.8441 17.5 14.501Z'
        fill='#5D5D61'
      />
    </svg>
  );
};

LastVisitAlternative.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

LastVisitAlternative.defaultProps = {
  width: '21px',
  height: '21px',
};

export default LastVisitAlternative;
