/**
 * @author Mehmet Yurtar
 **/

import React from "react";

class IconSales extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#7a7a7c"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            d="M9,7.2V4.1c0.6,0.2,1,0.8,1,1.4c0,0.6,0.4,1,1,1s1-0.4,1-1c0-1.8-1.3-3.2-3-3.4V1c0-0.6-0.4-1-1-1S7,0.4,7,1&#xA;&#x9;v1.1C5.3,2.3,4,3.7,4,5.5c0,2.1,1.6,2.9,3,3.3v3.1c-0.6-0.2-1-0.8-1-1.4c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,1.8,1.3,3.2,3,3.4V15&#xA;&#x9;c0,0.6,0.4,1,1,1s1-0.4,1-1v-1.1c1.7-0.2,3-1.7,3-3.4C12,8.4,10.4,7.6,9,7.2z M6,5.5c0-0.7,0.4-1.2,1-1.4v2.6C6.3,6.4,6,6.1,6,5.5z&#xA;&#x9; M9,11.9V9.3c0.7,0.3,1,0.6,1,1.2C10,11.2,9.6,11.7,9,11.9z"
            fill={this.state.color}
          />
        </g>
      </svg>
    );
  }
}

export default IconSales;
