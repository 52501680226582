import { t } from '../../../../system/ui';

const DEFAULT = {
  RECOMMENDATION: {
    name: 'Create Campaign',
  },
  FLY: {
    name: 'Create Campaign',
  },
};

const FLY = {
  FLY: {
    name: 'Create Campaign',
  },
};

const RECOMMENDATION = {
  RECOMMENDATION: {
    name: 'Create Campaign',
  },
};

const WINBACK = {
  WE_MISSED_YOU: {
    name: 'We Missed You',
    description: t('We Missed You! Come and see what’s new for you ! ...'),
    icon: 'WeMissedYou',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'WE_MISSED_YOU',
  },
  CHURN: {
    name: 'Churn',
    description: t('Where have you been? We haven’t heard from you lately...'),
    icon: 'ArrowTrendDown',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'CHURN',
  },
  PRICE_DROP: {
    name: 'Price Drop',
    description: t('Good News! Discount on your favorite item.'),
    icon: 'PriceDrop',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'PRICE_DROP',
  },
  BACK_IN_STOCK: {
    name: 'Back in Stock',
    description: t('The product that you viewed before is available now!'),
    icon: 'BackInStock',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'BACK_IN_STOCK',
  },
};

const GROWTH = {
  NEW_COMERS: {
    name: 'New Arrivals',
    description: t(
      'Shop our new collection before anyone else! Click right now.',
    ),
    icon: 'EmailBell',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'NEW_COMERS',
  },
  TOP_SELLERS: {
    name: 'Top Sellers',
    description: t('Shop our top sellers before anyone else! Click right now.'),
    icon: 'EmailDiamond',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'TOP_SELLERS',
  },
  ORDER_FOLLOW_UP: {
    name: 'Order Follow Up',
    description: t('This goes the best with your latest purchase.Shop it now!'),
    icon: 'EmailOrderFollow',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'ORDER_FOLLOW_UP',
  },
  BIRTHDAY: {
    name: 'Birthday',
    description: t(
      'Happy birthday! A special offer for you for this special day.',
    ),
    icon: 'EmailBirthday',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'BIRTHDAY',
  },
  ANNIVERSARY: {
    name: 'Anniversary',
    description: t(
      'Happy anniversary! A special offer for you for this special day.',
    ),
    icon: 'EmailBirthday',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'ANNIVERSARY',
  },
};

const RETENTION = {
  ABANDONED_CART: {
    name: 'Abandoned Cart',
    description: t(
      'Remind your customers about the items they left in their cart.',
    ),
    icon: 'AbandonedCart',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'ABANDONED_CART',
  },
  LAST_VISIT_ALTERNATIVES: {
    name: 'Last Visit Alternatives',
    description: t('This product still waiting for you. Grab it now!'),
    icon: 'LastVisitAlternative',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'LAST_VISIT_ALTERNATIVES',
  },
  NICE_TO_MEET_YOU: {
    name: 'Nice to Meet You',
    description: t('Welcome! Here are the hottest items of the day.'),
    icon: 'NiceToMeetYou',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'NICE_TO_MEET_YOU',
  },
  LAST_VISIT_REMINDER: {
    name: 'Last Visit Reminder',
    description: t('The product that you viewed before is available now!'),
    icon: 'LastVisitReminder',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'LAST_VISIT_REMINDER',
  },
  REPLENISHMENT: {
    name: 'Replenishment',
    description: t(
      'Remind your customer when to restock products based on their purchase history.',
    ),
    icon: 'replenishment',
    activate: 'none',
    modeIcon: 'plus',
    mode: 'create',
    type: 'REPLENISHMENT',
  },
};

export const EmailPageTypes = {
  DEFAULT,
  WINBACK,
  GROWTH,
  RETENTION,
  FLY,
  RECOMMENDATION,
};
