import { clone } from '../../system/object';
import { toCamelCase } from '../../system/string';
import { calculateRatio, calculateProportion } from '../../system/ratio';

import { personalizationTrendsMap } from '../../constants/datamaps/personalizationTrends';

const transformTrends = (trendData, purchaseAmount, isExecutive) => {
  const trends = clone(personalizationTrendsMap);
  const mappedTrends = {
    impression: 0,
    widgetView: 0,
    click: 0,
    basketItems: 0,
    basketAmount: 0,
    purchaseAmount: 0,
    purchases: 0,
    purchasedItems: 0,
    'notificationPermission[granted]': 0,
    'notificationPermission[denied]': 0,
    formSubmit: 0,
    'notification[shown]': 0,
    'notification[sent]': 0,
    'notification[clicked]': 0,
    segmentVisitor: 0,
    unsegmentVisitor: 0,
    toggleVisitorSegment: 0,
  };
  const totalRevenue = purchaseAmount || 0;
  let isPushNot = false;

  if (trendData.length > 0 && Array.isArray(trendData)) {
    trendData.forEach(item => {
      mappedTrends[toCamelCase(item.trend)] = item.count;
      if (toCamelCase(item.trend) === 'notification[sent]') {
        isPushNot = !isExecutive && true;
      }
    });
  }
  // remap data for the view
  trends.widget.stats.all.count =
    mappedTrends.impression || trends.widget.stats.all.count;
  trends.interaction.stats.all.count =
    mappedTrends.click + mappedTrends['notification[clicked]'] ||
    trends.interaction.stats.all.count;
  trends.interaction.stats.conversion.ratio = calculateRatio(
    trends.interaction.stats.all.count,
    trends.widget.stats.all.count,
  );
  trends.basket.stats.all.count =
    mappedTrends.basketItems || trends.basket.stats.all.count;
  trends.basket.stats.amount.count =
    mappedTrends.basketAmount || trends.basket.stats.amount.count;
  trends.basket.stats.conversion.ratio = calculateRatio(
    trends.basket.stats.all.count,
    trends.interaction.stats.all.count,
  );

  trends.revenue.stats.all.count =
    mappedTrends.purchaseAmount || trends.revenue.stats.all.count;
  trends.revenue.stats.purchases.count =
    mappedTrends.purchases || trends.revenue.stats.purchases.count;
  trends.revenue.stats.items.count =
    mappedTrends.purchasedItems || trends.revenue.stats.items.count;
  trends.revenue.stats.average.count = calculateProportion(
    mappedTrends.purchaseAmount,
    mappedTrends.purchases,
  );
  trends.revenue.stats.conversion.ratio = calculateRatio(
    trends.revenue.stats.purchases.count,
    trends.interaction.stats.all.count,
  );
  trends.revenue.stats.contribution.ratio = calculateRatio(
    trends.revenue.stats.all.count,
    totalRevenue,
  );

  trends.grantedPermission.stats.all.count = mappedTrends.impression;
  trends.deniedPermission.stats.all.count = mappedTrends.impression;
  trends.grantedPermission.stats.granted.count =
    mappedTrends['notificationPermission[granted]'];
  trends.deniedPermission.stats.denied.count =
    mappedTrends['notificationPermission[denied]'];
  trends.formSubmit.stats.count = mappedTrends.formSubmit;
  if (isPushNot) {
    trends.widget.stats.all.count =
      mappedTrends['notification[shown]'] || trends.widget.stats.all.count;
    trends.widget.stats.view.count =
      mappedTrends['notification[sent]'] || trends.widget.stats.view.count;
    trends.widget.stats.conversion.ratio = calculateRatio(
      trends.widget.stats.all.count,
      trends.widget.stats.view.count,
    );
    trends.interaction.stats.all.count =
      mappedTrends['notification[clicked]'] ||
      trends.interaction.stats.all.count;
    trends.interaction.stats.conversion.ratio = calculateRatio(
      trends.interaction.stats.all.count,
      trends.widget.stats.all.count,
    );
  }

  trends.segmentVisitor.stats.all.count = mappedTrends.segmentVisitor;
  trends.unsegmentVisitor.stats.all.count = mappedTrends.unsegmentVisitor;
  trends.toggleVisitorSegment.stats.all.count =
    mappedTrends.toggleVisitorSegment;

  return trends;
};

export default transformTrends;
