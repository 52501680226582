import React from 'react';
import Tooltip from '../tooltip';
import { TextField } from '../fields';
import {
  getMailMergeTags,
  createMailMergeTags,
  deleteMailMergeTags,
  updateMailMergeTags,
} from '../../modules/account/ajax';
import { t } from '../../system/ui';
import {
  isLoaded,
  isLoading,
  showNotification,
  showErrorNotification,
} from '../../actions/ui';
import classes from './userParameters.module.scss';

const UserParameters = () => {
  const [userParameters, setUserParameters] = React.useState([]);
  const [mergeTagValue, setMergeTagValue] = React.useState('');
  const [userParameterValue, setUserParameterValue] = React.useState('');
  const [backupValue, setBackupValue] = React.useState('');
  const [backupValues, setBackupValues] = React.useState([]);

  const handleChange = e => {
    if (e.target.name === 'mergeTag') {
      setMergeTagValue(e.target.value);
    } else if (e.target.name === 'userParameter') {
      setUserParameterValue(e.target.value);
    } else if (e.target.name === 'backupValue') {
      setBackupValue(e.target.value);
    }
  };

  const handleChangeBackupValues = e => {
    const backupValuesC = [...backupValues];
    backupValuesC[e.target.name] = e.target.value;
    setBackupValues(backupValuesC);
    const userParametersC = [...userParameters];
    userParametersC[e.target.name].backupValue = e.target.value;
    setUserParameters(userParametersC);
  };

  const createUserParameters = async () => {
    if (!mergeTagValue || !userParameterValue) {
      const notificationContent = () => (
        <div>{t('Please fill all fields')}</div>
      );
      showErrorNotification(notificationContent);
      return;
    }
    if (mergeTagValue.trim() === '' || userParameterValue.trim() === '') {
      const notificationContent = () => (
        <div>{t('Please fill all fields')}</div>
      );
      showErrorNotification(notificationContent);
      return;
    }
    const data = {
      mergeTags: {
        name: mergeTagValue,
        value: `{{user:${mergeTagValue}}}`,
        sample: mergeTagValue,
      },
      userParameter: userParameterValue,
      backupValue: backupValue || '',
    };

    createMailMergeTags(data)
      .then(res => {
        setMergeTagValue('');
        setUserParameterValue('');
        setBackupValue('');
        const notificationContent = () => <div>{res}</div>;
        showNotification({ content: notificationContent });
        setUserParameters([...userParameters, data]);
      })
      .catch(err => {
        const notificationContent = () => <div>{err}</div>;
        showNotification({ content: notificationContent });
      });
  };

  const removeUserParameters = index => {
    if (userParameters[index].mergeTags.name !== '') {
      const deletedRow = userParameters.filter((item, i) => i === index);

      deleteMailMergeTags(deletedRow[0].mergeTags.name)
        .then(res => {
          const notificationContent = () => <div>{res}</div>;
          showNotification({ content: notificationContent });
          const userParametersC = userParameters.filter(
            (item, i) => i !== index,
          );
          setUserParameters(userParametersC);
        })
        .catch(err => {
          const notificationContent = () => <div>{err}</div>;
          showErrorNotification({ content: notificationContent });
        });
    }
  };

  const updateUserParameters = index => {
    console.log('backup values ', backupValues[index]);
    const data = {
      mergeTag: userParameters[index].mergeTags.name,
      newBackupValue:
        backupValues[index][index] === ''
          ? ''
          : typeof backupValues[index] === 'object'
          ? backupValues[index][index]
          : backupValues[index],
    };

    updateMailMergeTags(data)
      .then(res => {
        const notificationContent = () => <div>{res}</div>;
        showNotification({ content: notificationContent });
      })
      .catch(err => {
        const notificationContent = () => <div>{err}</div>;
        showErrorNotification({ content: notificationContent });
      });
  };

  React.useEffect(() => {
    isLoading();
    getMailMergeTags()
      .then(res => {
        const userParametersC = [...res];
        userParametersC.push(...userParameters);
        setUserParameters(userParametersC);

        const backupValuesC = [];
        userParametersC.forEach((item, index) => {
          if (item.backupValue) {
            backupValuesC.push({ [index]: item.backupValue });
          } else {
            backupValuesC.push({ [index]: '' });
          }
        });
        setBackupValues(backupValuesC);
      })
      .catch(err => {
        const notificationContent = () => <div>{err}</div>;
        showNotification({ content: notificationContent });
      })
      .finally(() => isLoaded());
  }, []);

  const renderComponent = () => {
    return (
      <>
        {userParameters.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={classes.row}>
            <div className={classes.row__value}>{item.mergeTags.name}</div>
            <div className={classes.row__separator}>
              <div className='arrowsImg' />
            </div>
            <div className={classes.row__value}>{item.userParameter}</div>
            <div className={classes.row__separator}>
              <div className='equalImg' />
            </div>
            <div className={classes.row__value} style={{ marginRight: '10px' }}>
              <TextField
                type='text'
                name={index}
                value={item.backupValue ? item.backupValue : ''}
                onChange={e => handleChangeBackupValues(e)}
                placeholder='Please enter a backup value (optional)'
                style={{ width: '100%' }}
                className='one-whole'
              />
            </div>
            <div className={classes.row__separator}>
              <button
                type='button'
                className='cancel-action helper-action'
                onClick={() => removeUserParameters(index)}
                disabled={item.isRequired}
              >
                {t('Delete')}
              </button>

              <button
                type='button'
                className='secondary-action helper-action'
                onClick={() => updateUserParameters(index)}
              >
                {t('Update')}
              </button>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className='page-content-block one-whole email-settings-form'>
      <div className='email-settings-test-users'>
        <h3
          className='modal-content-title'
          style={{ color: '#6968b2', marginBottom: '5px' }}
        >
          {t('User Parameters')}
        </h3>
        <br />
        <div style={{ fontWeight: '600' }}>
          User Parameters Relationship{' '}
          <Tooltip
            content={t('Shows which tags are being used')}
            alignment='left'
          >
            <i
              className='icon-info'
              style={{ color: '#CEDAE4' }}
              role='presentation'
            >
              <span className='for-screenreader-only' />
            </i>
          </Tooltip>
        </div>
        <div className={classes.header}>
          <div className={classes.header__title}>{t('Merge Tag')}</div>
          <div className={classes.header__title}>{t('User Parameter')}</div>
          <div className={classes.header__title}>{t('Backup Value')}</div>
        </div>
        {renderComponent()}
        <div className={classes.row}>
          <div className={classes.row__value}>
            <TextField
              type='text'
              value={mergeTagValue}
              onChange={e => handleChange(e)}
              name='mergeTag'
              placeholder='Please enter a merge tag'
              required
              style={{ width: '100%' }}
              className='one-whole'
            />
          </div>
          <div className={classes.row__separator}>
            <div className='arrowsImg' />
          </div>
          <div className={classes.row__value}>
            <TextField
              type='text'
              value={userParameterValue}
              onChange={e => handleChange(e)}
              name='userParameter'
              placeholder='Please enter a user parameter'
              required
              style={{ width: '100%' }}
              className='one-whole'
            />
          </div>
          <div className={classes.row__separator}>
            <div className='equalImg' />
          </div>
          <div className={classes.row__value}>
            <TextField
              type='text'
              value={backupValue}
              onChange={e => handleChange(e)}
              name='backupValue'
              placeholder='Please enter a backup value (optional)'
              style={{ width: '100%' }}
              className='one-whole'
            />
          </div>
          <div className={classes.row__separator}>
            <a
              className='tertiary-action helper-action'
              onClick={createUserParameters}
            >
              {t('Save')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserParameters;
