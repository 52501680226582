/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useMemo } from 'react';
import { Context as PaginationContext } from '../../../components/pagination/store/paginationProvider';

import {
  SFYHeader,
  SFYList,
  SFYNoResult,
  SFYWrapper,
} from '../../../components/sfy-components/module-exports';
import SFYPagination from '../../../components/pagination/SFYPagination';
import {
  breadCrumbOptions,
  promotionHeadConstant,
} from './constants/constants';
import { actions } from '../../dynamic-bundle/shop-the-look/constants/constants';
import PromotionServices from './services/promotionServices';
import { Context as PromotionContext } from './store/promotionProvider';
import RemoveListItemModal from '../../../components/sfy-components/RemoveListItemModal/RemoveListItemModal';
import { uiActions } from '../../../actions';
import { t } from '../../../system/ui';
import { setTitle } from '../../../system/document';

const Promotion = ({ location }) => {
  const {
    state: { pagination, searchQueryString },
    paginationSetter,
    searchQuerySetter,
  } = useContext(PaginationContext);

  const {
    state: { promotionList },
    setPromotionList,
  } = useContext(PromotionContext);

  const { isLoaded, isLoading } = uiActions;

  const listInvoker = () => {
    isLoading();
    PromotionServices.getAll({
      pageNumber: pagination.pageNumber,
      payloadSize: pagination.payloadSize,
      searchQueryString: encodeURIComponent(searchQueryString),
    }).then(({ promotionRecord, totalRecordSize, pageNo, pageSize }) => {
      setPromotionList(promotionRecord || []);
      paginationSetter({
        totalRecords: totalRecordSize,
        pageNumber: pageNo,
        payloadSize: pageSize,
      });
      isLoaded();
    });
  };

  const removePromotion = useMemo(
    () => id => {
      RemoveListItemModal({
        listInvoker,
        title: <>Remove the Promotion ?</>,
        content: (
          <>
            Are you sure you want to remove this <br /> promotion ?
          </>
        ),
        deleteFn: () => {
          return PromotionServices.delete({ id: encodeURIComponent(id) }).then(
            () => {
              listInvoker();
            },
          );
        },
      });
    },
    [listInvoker, PromotionServices.delete],
  );

  useEffect(() => {
    setTitle('Promotion List - Recommendations');
    listInvoker({
      pageNumber: pagination.pageNumber,
      payloadSize: pagination.payloadSize,
      searchQueryString: encodeURIComponent(searchQueryString),
    });
  }, [pagination.pageNumber, pagination.payloadSize, searchQueryString]);

  return (
    <>
      <SFYHeader
        pageTitle='Promotion List'
        breadCrumbOptions={breadCrumbOptions}
      />

      <SFYWrapper>
        {promotionList && promotionList.length > 0 ? (
          <>
            <SFYPagination
              location={location}
              pagination={pagination}
              linkToRoute='/recommendations/promotion/add'
              stateSetter={searchQuerySetter}
              state={searchQueryString}
              paginationSetter={paginationSetter}
              text='Create a New Promotion'
            />
            <SFYList
              linkToDetail='/recommendations/promotion/edit'
              tableHead
              imgList
              listData={promotionList}
              modalInvoker={removePromotion}
              actions={actions}
              totalRecords={pagination.totalRecords}
              listInvoker={listInvoker}
              promotionHeadConstant={promotionHeadConstant}
              promotionHead
            />
          </>
        ) : searchQueryString !== '' && promotionList.length === 0 ? (
          <>
            <SFYPagination
              location={location}
              pagination={pagination}
              linkToRoute='/recommendations/promotion/add'
              stateSetter={searchQuerySetter}
              state={searchQueryString}
              paginationSetter={paginationSetter}
            />
            <SFYNoResult
              linkToCreate='/recommendations/promotion/add'
              listType={t('Create New Promotion')}
              noListDescription={t('There is no promotion yet.')}
            />
          </>
        ) : (
          <SFYNoResult
            linkToCreate='/recommendations/promotion/add'
            listType={t('Create New Promotion')}
            noListDescription={t('There is no promotion yet.')}
          />
        )}
      </SFYWrapper>
    </>
  );
};

export default Promotion;
