import { useState, useEffect } from 'react';
import { EmailPageTypes } from '../constants/page-configuration-constants';
import { findCampaignType } from './findCampaignType';
import { getRangedEmailReports } from '../../../../modules/emails/ajax';

export const useCampaignExtractorByType = ({ location, emailList }) => {
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);
  const [renderList, setRenderList] = useState([]);
  const rawData = [];

  const campaignType = findCampaignType(location).toUpperCase();

  const possibleCampaignType = {
    WINBACK: Object.keys(EmailPageTypes.WINBACK),
    RETENTION: Object.keys(EmailPageTypes.RETENTION),
    GROWTH: Object.keys(EmailPageTypes.GROWTH),
    RECOMMENDATION: 'RECOMMENDATION', // BULK SEGMENTATED EMAILS
    FLY: 'FLY',
  };

  const matchedCampaignTypes = possibleCampaignType[campaignType];

  const dataUpdate = res => {
    filteredCampaigns.forEach(campaign => {
      Object.keys(res).forEach(key => {
        const campaignKey = ['RECOMMENDATION', 'FLY'].includes(campaignType)
          ? campaign.instanceId
          : campaign.type;
        if (campaignKey === key) {
          const stats = {
            [campaign.type]: {
              reportValues: res[key].map(({ x, y }) => {
                return {
                  type: x,
                  value: y,
                };
              }),
              ...campaign,
            },
          };
          rawData.push(stats);
          setRenderList(rawData);
        }
      });
    });
  };

  useEffect(() => {
    const reportIds =
      filteredCampaigns?.map(campaign => campaign?.instanceId) || [];
    if (reportIds.length > 0) {
      getRangedEmailReports(reportIds, 'weekly', res => {
        dataUpdate(res);
      });
    }
  }, [filteredCampaigns]);

  const EmailListSetter = () => {
    const newCampaigns = [];
    emailList.forEach(campaign => {
      if (matchedCampaignTypes.includes(campaign.type)) {
        newCampaigns.push(campaign);
      }
    });
    return newCampaigns;
  };

  useEffect(() => {
    setFilteredCampaigns(EmailListSetter());
  }, [location.pathname, emailList.length]);

  return { filteredCampaigns, renderList };
};
