import { getRestEndPoint } from '../../../system/api';
import { getApiKey } from '../../../modules/auth/user';
import { $http } from '../../../system/ajax';
import { isLoading } from '../../../actions/ui';

export const uploadCouponCodes = async data => {
  isLoading();
  const { id, file } = data;
  const url = `${getRestEndPoint('emails/couponCodes')}?apiKey=${getApiKey()}`;
  const formData = new FormData();
  formData.append('instanceId', id);
  formData.append('file', file);

  const response = await $http(url).post(formData, {
    type: 'form',
  });
  return await response;
};
