import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const NewBadge = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 39 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.0803 15.0192C28.0803 14.5536 27.7028 14.1761 27.2371 14.1761L11.5324 14.1761C11.0668 14.1761 10.6893 14.5536 10.6893 15.0192L10.6893 16.7174C11.1816 16.8566 11.6461 17.1201 12.032 17.506C13.2392 18.7133 13.2392 20.6706 12.032 21.8779C11.646 22.2638 11.1816 22.5273 10.6893 22.6665L10.6893 24.3649C10.6893 24.8306 11.0667 25.208 11.5324 25.208L27.2372 25.208C27.7028 25.208 28.0803 24.8306 28.0803 24.3649L28.0803 22.6666C27.5878 22.5274 27.1232 22.2638 26.7373 21.8779C25.53 20.6706 25.53 18.7133 26.7373 17.506C27.1232 17.1201 27.5879 16.8565 28.0803 16.7173L28.0803 15.0192ZM27.2372 12.4899C28.6341 12.4899 29.7665 13.6223 29.7665 15.0192L29.7664 16.8815C29.7665 17.6936 29.1238 18.1907 28.5748 18.3303C28.339 18.3903 28.1158 18.5122 27.9296 18.6984C27.3808 19.2471 27.3808 20.1368 27.9296 20.6856C28.1157 20.8717 28.339 20.9936 28.5747 21.0536C29.1238 21.1932 29.7665 21.6904 29.7664 22.5023V24.3649C29.7664 25.7618 28.634 26.8942 27.2371 26.8942L11.5325 26.8942C10.1355 26.8942 9.00312 25.7618 9.00313 24.3649L9.00309 22.5023C9.00309 21.6904 9.64562 21.1932 10.1945 21.0536C10.4304 20.9935 10.6535 20.8717 10.8396 20.6855C11.3883 20.1368 11.3884 19.2471 10.8396 18.6983C10.6535 18.5122 10.4303 18.3903 10.1946 18.3304C9.64558 18.1907 9.00309 17.6935 9.0031 16.8817L9.00307 15.0192C9.00308 13.6223 10.1355 12.4899 11.5324 12.4899L27.2372 12.4899Z'
        fill={color}
      />
      <path
        d='M22.4061 22.7481C22.2467 22.4402 22.2173 22.0965 22.1984 21.7551C22.1528 20.9319 22.181 20.1032 22.1698 19.2794C22.1585 18.443 22.2213 17.5405 22.0159 16.7192C21.9471 16.444 21.9876 16.2178 21.8919 16.5677C21.6781 17.3491 21.318 18.079 21.0156 18.8268C20.7061 19.5922 20.4052 20.3412 20.2662 21.161C20.2548 21.2278 20.1145 22.4044 19.9777 22.2255C19.0074 20.9572 18.584 19.2117 18.345 17.6704C18.2699 17.1854 18.1991 16.6802 18.2526 16.1905C18.289 15.8583 18.1602 16.2328 18.1165 16.3493C17.7114 17.4277 17.202 18.455 16.7815 19.5252C16.7695 19.5559 16.5192 20.2643 16.3754 20.1485C16.1786 19.9899 16.0996 19.631 16.0378 19.409C15.8559 18.7561 15.6515 18.093 15.6768 17.4133'
        stroke='#5D5D61'
        stroke-width='1.7'
        stroke-linecap='round'
      />
    </svg>
  );
};

NewBadge.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

NewBadge.defaultProps = {
  width: '39px',
  height: '39px',
  color: '#5D5D61',
};

export default NewBadge;
