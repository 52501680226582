/**
 * Created by mehmetyurtar on 11/08/2017.
 */

import React from 'react';
import moment from 'moment';

import { isSuperUser } from '../../modules/auth/user';
import { getFormattedDate, getNow, isBeforeNow } from '../../system/date';
import { devices } from '../../constants/datamaps/campaigns';
import { types } from '../../constants/datamaps/campaign';
import Icon from '../icon';
import Icons from '../icons';
import { t } from '../../system/ui';
import Tooltip from '../tooltip';

import CampaignActions from '../../modules/widgets/campaign-actions';
import { moveCampaignToTest } from '../../modules/campaign/events';

import EngagementStats from './stats';
import EngagementOptions from './options';

export const personalizationCampaigns = {
  POPUP_BANNER: 'popup',
  SEE_ALL: 'see-all',
  PERSONA_QUIZ: 'persona-quiz',
  NEWSLETTER: 'email-collection',
  NOTIFICATION_BAR: 'notification-bar',
  HERO_BANNER: 'hero-banner',
  FORM: 'form-survey',
  SEGMENTATION: 'segmentation',
  WHEEL_OF_FORTUNE: 'wheel-of-fortune',
  SCRATCH: 'scratch',
  COUNTDOWN: 'countdown',
  HERO_BANNER_COUNTDOWN: 'hero-banner-countdown',
  NOTIFICATION_BAR_COUNTDOWN: 'notification-bar-countdown',
  POP_UP_BANNER_COUNTDOWN: 'pop-up-banner-countdown',
};

class EngagementCard extends React.Component {
  constructor(props) {
    super(props);

    this.goTestFromArchive = this.goTestFromArchive.bind(this);
    this.getCampaignPages = this.getCampaignPages.bind(this);
  }

  goTestFromArchive() {
    const campaignId = this.props.instanceId;
    if (campaignId) {
      moveCampaignToTest(campaignId).then(response => {
        this.props.onUpdate(campaignId, response.response.status);
      });
    }
  }

  isLive() {
    return this.props.status === 'ACTIVE';
  }

  isWaiting() {
    const now = getNow().valueOf();
    return this.isLive() && this.props.startDate && this.props.startDate > now;
  }

  isCompleted() {
    const now = getNow().valueOf();
    return this.isLive() && this.props.endDate && this.props.endDate < now;
  }

  isNotLimited() {
    return !this.isCompleted() && !this.isWaiting();
  }

  getCampaignPages(filter) {
    if (!filter.includedCategories) {
      return false;
    }
    let includedPages = '';
    if (filter.includedCategories.indexOf('allpages') !== -1) {
      includedPages += t('All Pages');
    } else {
      filter.includedCategories.forEach((pageName, pageIndex) => {
        if (pageIndex === filter.includedCategories.length - 1) {
          includedPages += t(pageName);
        } else {
          includedPages += `${t(pageName)}, `;
        }
      });
    }
    return (
      <span className='widget-meta-pages'>
        <Tooltip content={includedPages} alignment='left'>
          <Icons name='browser' width='15' height='17' color='#bababa' />{' '}
          {t('Pages')}
        </Tooltip>
      </span>
    );
  }

  render() {
    const { isInAB } = this.props;
    let campaignStatusMarkup = null;
    if (this.isWaiting()) {
      campaignStatusMarkup = (
        <Tooltip
          content={t('Scheduled Campaign')}
          placement='left'
          className='widget-timelimit-tooltip'
        >
          <div className='widget-timelimit-badge'>
            <Icons name='scheduled' color='#547494' />
          </div>
        </Tooltip>
      );
    }
    if (this.isCompleted()) {
      campaignStatusMarkup = (
        <Tooltip
          content={t('Completed Campaign')}
          placement='left'
          className='widget-timelimit-tooltip'
        >
          <div className='widget-timelimit-badge'>
            <Icons name='completed' color='#bababb' />
          </div>
        </Tooltip>
      );
    }
    const isCompletedPushCampaign = campaign => {
      if (
        campaign &&
        campaign.type.indexOf('PUSH') !== -1 &&
        campaign.schedule
      ) {
        if (
          campaign.status !== 'ARCHIVE' &&
          campaign.schedule &&
          campaign.schedule.type === 'one_time'
        ) {
          if (isBeforeNow(moment(campaign.schedule.date))) {
            return true;
          }
        }
      }
      return false;
    };
    return (
      <div className='widget page-content-block' ref='widget'>
        {!this.isNotLimited() && (
          <div className='widget-options'>{campaignStatusMarkup}</div>
        )}
        {this.isNotLimited() && (
          <EngagementOptions
            instance={this.props.instanceId}
            testMode={this.props.status !== 'ACTIVE'}
            onUpdate={this.props.onUpdate}
          />
        )}
        {this.props.type === 'POPUP_BANNER' ? (
          <div>
            <div className='one-eighths widget-popup-image'>
              <Tooltip content={this.props.image} type='image' placement='left'>
                <img src={this.props.image} />
              </Tooltip>
            </div>
            <div className='seven-eighths widget-popup-text'>
              {isSuperUser() ? (
                <h3 className='widget-page'>
                  {t(types[personalizationCampaigns[this.props.type]]?.title)} -{' '}
                  <span style={{ color: '##c0bebe', fontWeight: 'normal' }}>
                    {this.props.instanceId}
                  </span>
                </h3>
              ) : (
                <h3 className='widget-page'>
                  {t(types[personalizationCampaigns[this.props.type]].title)}
                </h3>
              )}
              <h2 className='widget-title'>{this.props.name}</h2>
            </div>
          </div>
        ) : (
          <div>
            {isSuperUser() ? (
              <h3 className='widget-page'>
                {t(types[personalizationCampaigns[this.props.type]]?.title)} -{' '}
                <span style={{ color: '##c0bebe', fontWeight: 'normal' }}>
                  {this.props.instanceId}
                </span>
              </h3>
            ) : (
              <h3 className='widget-page'>
                {t(types[personalizationCampaigns[this.props.type]]?.title)}
              </h3>
            )}
            <h2 className='widget-title'>{this.props.name}</h2>
          </div>
        )}

        <div className='widget-meta'>
          {this.props.startDate && (
            <span className='widget-meta-date'>
              <Icon name='calendar' />
              {getFormattedDate(this.props.startDate)}
              {this.props.endDate
                ? ` - ${getFormattedDate(this.props.endDate)}`
                : ''}
            </span>
          )}
          {this.props.devices.map(device => {
            return (
              <span key={device} className='widget-meta-mobile'>
                <Tooltip content={t(devices[device])} alignment='left'>
                  <Icon name={device.toLowerCase()} />
                </Tooltip>
              </span>
            );
          })}
          {this.props.filters &&
            this.props.filters[0] &&
            this.getCampaignPages(this.props.filters[0])}
          {isInAB && (
            <span
              className='widget-meta-experiment'
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                position: 'relative',
                top: '3px',
              }}
            >
              <Icons name='experimentation' />
              <span style={{ marginLeft: '5px' }}>in an A/B test</span>
            </span>
          )}
          <hr className='widget-separator' />

          <EngagementStats {...this.props} ref='widgetStats' />

          <hr className='widget-separator' />

          <div className='widget-buttons group'>
            {this.props.status !== 'PASSIVE' ? (
              <CampaignActions
                type={personalizationCampaigns[this.props.type]}
                instanceId={this.props.instanceId}
                onUpdate={this.props.onUpdate}
                campaign={this.props}
                isReplicate={isCompletedPushCampaign(this.props)}
              />
            ) : (
              <div
                onClick={this.goTestFromArchive}
                className='secondary-action'
              >
                {t('Go Test')}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default EngagementCard;
