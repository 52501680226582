import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { TextField } from "../../../../components/fields";

import { behaviouralTargetingParams } from "../../../../constants/datamaps/wizard";

import { t, confirm } from "../../../../system/ui";
import Icons from "../../../../components/icons/index";

import Snippet from "../../../../components/snippet";
import { generateFormHTML } from "../../../../modules/transform/formFields";

import { wizardActions } from "../../../../actions";

class AdvancedConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        html: "",
        pureHtml: "",
        css: "",
        preJs:
          "function preRenderConf(conf) { /* add your custom code inside this function */ }",
        postJs: "",
        url: "",
        isPureHtml: true,
        campaignType: ""
      },
      snippet: "",
      codetab: "html",
      codeLanguage: "htmlmixed",
      isDefault: true
    };

    this.setValues = this.setValues.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.generateNewHTML = this.generateNewHTML.bind(this);
    this.backToDefaults = this.backToDefaults.bind(this);
    this.checkDefault = this.checkDefault.bind(this);
    this.confirmDefault = this.confirmDefault.bind(this);
  }

  componentDidMount() {
    this.setValues({
      html: this.props.options.html || "",
      pureHtml: this.props.options.pureHtml || "",
      preJs:
        this.props.options.preJs ||
        "function preRenderConf(conf) { /* add your custom code inside this function */ }",
      postJs: this.props.options.postJs || "",
      css: this.props.options.css || "",
      url: this.props.options.url || "",
      isPureHtml: true,
      campaignType: ""
    });
  }

  componentWillReceiveProps(newProps) {
    if (this.props.options !== newProps.options) {
      this.setValues({
        html: newProps.options.html || "",
        pureHtml: this.props.options.pureHtml || "",
        preJs:
          newProps.options.preJs ||
          "function preRenderConf(conf) { /* add your custom code inside this function */ }",
        postJs: newProps.options.postJs || "",
        css: newProps.options.css || "",
        url: newProps.url || ""
      });
    }

    if (this.props.campaign !== newProps.campaign) {
      this.setValues({
        html: newProps.campaign.html,
        preJs: newProps.campaign.preJs,
        postJs: newProps.campaign.postJs,
        css: newProps.campaign.css,
        url: newProps.campaign.url || "",
        isPureHtml:
          typeof newProps.campaign.pureHtml !== "undefined" &&
          newProps.campaign.pureHtml === "true",
        campaignType: newProps.campaign.type
      });
    }

    if (this.props.formFields !== newProps.formFields) {
      if (
        !newProps.campaign.instanceId &&
        this.props.options.html.indexOf("seg-form-survey") !== -1
      ) {
        this.generateNewHTML(newProps);
      }
    }
  }

  componentDidUpdate() {
    this.refs.editor.refresh();
  }

  checkDefault(val) {
    this.setState({
      isDefault: false,
      snippet: val
    });
  }

  setValues(values) {
    this.setState({
      snippet: values.html,
      codetab: "html",
      codeLanguage: "htmlmixed",
      values
    });
  }

  toggle() {
    let lastSnippet = this.state.codetab,
      values = this.state.values;

    values[lastSnippet] = this.refs.editor.state.value;

    this.setState({ values });

    this.refs.editor.refresh();

    wizardActions.hideAdvancedConfig();
  }

  changeTab(current) {
    let lastSnippet = this.state.codetab,
      values = this.state.values,
      newSnippet = this.state.values[current];

    let languages = {
      html: "htmlmixed",
      preJs: "javascript",
      postJs: "javascript",
      css: "css"
    };

    values[lastSnippet] = this.refs.editor.state.value;

    this.setState(
      {
        codetab: current,
        codeLanguage: languages[current],
        snippet: newSnippet || "",
        values
      },
      this.refs.editor.refresh()
    );
  }

  onUrlChange(e) {
    let values = this.state.values;
    values.url = e.target.value;
    this.setState({
      values
    });
  }

  generateNewHTML(newProps) {
    let generatedHTML = generateFormHTML(newProps.formFields.fields);
    this.setValues({
      html: generatedHTML || "",
      preJs:
        newProps.options.preJs ||
        "function preRenderConf(conf) { /* add your custom code inside this function */ }",
      postJs: newProps.options.postJs || "",
      css: newProps.options.css || "",
      url: newProps.url || ""
    });
  }

  confirmDefault() {
    confirm({
      onConfirm: this.backToDefaults,
      onCancel: () => {
        this.setState({
          isSubmitting: false
        });
      },
      content: t("Your changes will be lost!")
    });
  }

  backToDefaults() {
    this.setState(
      {
        isDefault: true
      },
      () => {
        this.setValues({
          html: this.props.options.html || "",
          pureHtml: this.props.options.pureHtml || "",
          preJs:
            this.props.options.preJs ||
            "function preRenderConf(conf) { /* add your custom code inside this function */ }",
          postJs: this.props.options.postJs || "",
          css: this.props.options.css || "",
          url: this.props.options.url || "",
          isPureHtml: true
        });
      }
    );
    this.refs.editor.setValue();
  }

  render() {
    let isDefault = false;
    if (!this.state.isDefault) {
      isDefault = false;
    } else {
      if (
        this.state.values.html === (this.props.options.html || "") &&
        this.state.values.pureHtml === (this.props.options.pureHtml || "") &&
        this.state.values.preJs ===
          (this.props.options.preJs ||
            "function preRenderConf(conf) { /* add your custom code inside this function */ }") &&
        this.state.values.postJs === (this.props.options.postJs || "") &&
        this.state.values.css === (this.props.options.css || "") &&
        this.state.values.url === (this.props.options.url || "") &&
        this.state.values.isPureHtml === true
      ) {
        isDefault = true;
      }
    }
    return (
      <div
        className={classNames("wizard-advanced-config", {
          "is-hidden": !this.props.isAdvancedConfigVisible
        })}
      >
        <div className="campaign-variables">
          {!isDefault && (
            <span onClick={this.confirmDefault} className="back-to-defaults">
              <Icons name="previous" /> {t("Back to the defaults")}
            </span>
          )}
          <div>
            {typeof this.props.options.url !== "undefined" && (
              <div className="campaign-variable-set advanced-config-form">
                <TextField
                  name="url"
                  label="Permission Url"
                  className="item-stacked one-whole"
                  value={this.state.values.url}
                  required={false}
                  onChange={this.onUrlChange}
                />
              </div>
            )}
          </div>
          {Object.keys(behaviouralTargetingParams).map(item => {
            return (
              <div key={item} className='campaign-variable-set'>
                <h3 className='page-title'>
                  {behaviouralTargetingParams[item].title}
                </h3>
                {behaviouralTargetingParams[item].variables.map(variable => {
                  if (variable.includes('none')) {
                    return (
                      <span key={variable} className='campaign-variable'>
                        {`{{${item}}}`}
                      </span>
                    );
                  }
                  return (
                    <span key={variable} className='campaign-variable'>
                      {`{{${item}:${variable}}}`}
                    </span>
                  );
                })}
              </div>
            );
          })}
        </div>

        <div className="code-samples">
          <nav className="code-sample-tabs">
            <a
              className={classNames("code-sample-tab-nav", {
                current: this.state.codetab === "html"
              })}
              onClick={this.changeTab.bind(null, "html")}
            >
              {t("Edit HTML")}
            </a>
            <a
              className={classNames("code-sample-tab-nav", {
                current: this.state.codetab === "css"
              })}
              onClick={this.changeTab.bind(null, "css")}
            >
              {t("Edit CSS")}
            </a>
            <a
              className={classNames("code-sample-tab-nav", {
                current: this.state.codetab === "preJs"
              })}
              onClick={this.changeTab.bind(null, "preJs")}
            >
              {t("Edit PreJS")}
            </a>
            <a
              className={classNames("code-sample-tab-nav", {
                current: this.state.codetab === "postJs"
              })}
              onClick={this.changeTab.bind(null, "postJs")}
            >
              {t("Edit PostJS")}
            </a>
          </nav>
          <div className="code-sample-tab">
            <Snippet
              ref="editor"
              value={this.state.snippet}
              language={this.state.codeLanguage}
              checkDefault={this.checkDefault}
            />
          </div>
        </div>

        <a
          className="wizard-advanced-config-save tertiary-action"
          onClick={this.toggle}
        >
          {t("Back To Wizard")}
        </a>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  isAdvancedConfigVisible: store.wizard.isAdvancedConfigVisible,
  formFields: store.wizard.formFields,
  popupOption: store.push.popupOption
});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
  AdvancedConfig
);
