import React from 'react';
import { t } from '../../../system/ui';
import { Calendar } from '../../fields';
import classes from './SFYScheduling.module.scss';
import Tooltip from '../../tooltip';

const SelectTime = ({ componentDetail, editCampaign, fieldValue }) => {
  const { tooltipText, cardName, cardTitle } = componentDetail;
  const constraint = { minutes: { step: 15 } };
  let val = fieldValue;
  if (val?.length < 3) {
    val += ':00';
  }
  return (
    <label
      className={`item item-stacked label-emphasise label-mail ${classes['sfy-scheduling-repeat']}`}
    >
      <span className={classes['sfy-label-wrapper']}>
        <span className={classes['sfy-label']}>{t(cardTitle)}</span>
        <Tooltip
          content={t(tooltipText)}
          alignment='top'
          placement='right'
          newTooltip
          isInline
        >
          <i className='icon-info' role='presentation'>
            <span className='for-screenreader-only' />
          </i>
        </Tooltip>
      </span>
      <Calendar
        name='date'
        className={`item-field ${classes['sfy-scheduling-repeat']} ${classes['sfy-border']}`}
        value={val}
        dateFormat={false}
        timeConstraints={constraint}
        onChange={value => editCampaign(cardName, value.format('HH:mm'))}
      />
    </label>
  );
};

export default SelectTime;
