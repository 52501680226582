import React from "react";

class IconBrowser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <path
          id="circle-more-detail-glyph"
          d="M0,8c0-4.4,3.6-8,8-8s8,3.6,8,8s-3.6,8-8,8S0,12.4,0,8z M12.2,9.4c0.8,0,1.4-0.6,1.4-1.4
	S13,6.6,12.2,6.6c-0.8,0-1.4,0.6-1.4,1.4S11.4,9.4,12.2,9.4z M8,9.4c0.8,0,1.4-0.6,1.4-1.4S8.8,6.6,8,6.6S6.6,7.2,6.6,8
	S7.3,9.4,8,9.4z M3.9,9.4c0.8,0,1.4-0.6,1.4-1.4S4.6,6.6,3.9,6.6S2.5,7.2,2.5,8S3.1,9.4,3.9,9.4z"
        />
      </svg>
    );
  }
}

export default IconBrowser;
