import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { getActiveEmails } from '../../modules/emails/ajax';
import { getActivePushCampaigns } from '../../modules/push/ajax';
import {
  get15MinutesLaterWithTimeZone,
  isBeforeNowByTimeZone,
  isValidStartDate,
  periods,
  reformatDateByTimeZone,
} from '../../system/date';
import { clone } from '../../system/object';
import { t } from '../../system/ui';
import { Select, Calendar } from '../fields';
import Icons from '../icons';
import Tooltip from '../tooltip';

const defaults = {
  schedule: {
    type: 'one_time',
    period: 'every_day',
    periodList: null,
  },
  validity: '72',
};

const types = [
  { value: 'one_time', label: t('Once') },
  { value: 'periodic', label: t('Periodic') },
  { value: 'now', label: t('As soon as possible') },
];

class MPScheduling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: clone(defaults),
      oneTimeValueChanged: false,
      campaigns: [],
      conflictCampaigns: [],
      showWarning: false,
      warningShown: false,
      scheduleController: 0,
      isMulti: false,
      selectablePeriods: periods,
    };

    this.handleType = this.handleType.bind(this);
    this.update = this.update.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.handlePeriod = this.handlePeriod.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.getCampaignsAndUpdate = this.getCampaignsAndUpdate.bind(this);
    this.checkConflict = this.checkConflict.bind(this);
    this.onWarningClick = this.onWarningClick.bind(this);
  }

  componentDidMount() {
    defaults.schedule.date = get15MinutesLaterWithTimeZone().valueOf();
    this.setState({ isMulti: this.props.isPush && this.props.isBulk });
    if (this.props.isAdd) {
      this.getCampaignsAndUpdate(this.props.isPush);
    } else {
      this.update(this.props.schedule);
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.schedule !== newProps.schedule) {
      if (this.props.isReplicate) {
        this.update({ ...newProps.schedule, date: newProps.schedule.date });
      } else {
        this.update(newProps.schedule);
      }
    }
  }

  update(schedule) {
    const scheduleToCopy = clone(
      this.state.isMulti
        ? { ...defaults.schedule, period: '' }
        : defaults.schedule,
    );
    const scheduling =
      schedule !== undefined && Object.keys(schedule).length > 0
        ? schedule
        : clone(scheduleToCopy);
    this.updateValue(scheduling, 'schedule');
  }

  updateValue(newValue, type) {
    const { values } = this.state;
    values[type] = newValue;

    const [day, ...rest] = periods;
    let updatedDay = day;
    let updatedRest = rest;

    if (this.state.isMulti) {
      if (newValue.periodList === undefined) {
        values.schedule.periodList = newValue.period;
        values.schedule.period = null;
      }
      const existingPeriods = newValue.periodList || newValue.period || [];
      if (existingPeriods.length < 1) {
        updatedDay = day;
        updatedRest = rest;
      } else if (existingPeriods.includes('every_day')) {
        updatedDay = day;
        updatedRest = rest.map(period => ({
          ...period,
          disabled: true,
        }));
      } else {
        updatedDay = { ...day, disabled: true };
        updatedRest = rest;
      }
    }
    this.setState(
      { values, selectablePeriods: [updatedDay, ...updatedRest] },
      () => {
        this.checkConflict();
      },
    );
  }

  handleType(selection) {
    const { values } = this.state;
    values.schedule.type = selection.value;
    this.setState({ values }, () => {
      this.checkConflict();
      if (this.props.onDateChange) {
        this.props.onDateChange(values);
      }
    });
    if (selection.value === 'one_time') {
      this.setState({
        scheduleController: 2,
      });
    } else if (selection.value === 'periodic') {
      this.setState({
        scheduleController: 3,
      });
    } else {
      this.setState({
        scheduleController: 1,
      });
    }
  }

  handleDateChange(datetime) {
    const { values } = this.state;
    values.schedule.date = datetime.valueOf();

    if (values.schedule.type === 'periodic') {
      values.schedule.hour = reformatDateByTimeZone(
        datetime.valueOf(),
        'HH:mm',
      );
    }

    this.setState({ values, oneTimeValueChanged: true }, () => {
      this.checkConflict();
    });
  }

  handlePeriod(selection) {
    const { values, isMulti } = this.state;
    const currentSelection = isMulti ? selection : [selection];
    const currentValues = currentSelection.map(option => option.value);

    values.schedule.period = isMulti ? null : selection.value;
    values.schedule.periodList = isMulti ? currentValues : null;

    const [day, ...rest] = periods;
    let updatedDay;
    let updatedRest;
    if (currentValues.includes('every_day')) {
      updatedDay = day;
      updatedRest = rest.map(period => ({
        ...period,
        disabled: true,
      }));
    } else {
      updatedDay = { ...day, disabled: true };
      updatedRest = rest;
    }
    if (!isMulti || currentValues.length < 1) {
      updatedDay = day;
      updatedRest = rest;
    }

    this.setState(
      { values, selectablePeriods: [updatedDay, ...updatedRest] },
      () => {
        this.checkConflict();
        if (this.props.onDateChange) {
          this.props.onDateChange(values);
        }
      },
    );
  }

  getCampaignsAndUpdate(isPush) {
    // Get Campaigns then
    if (this.props.isPush) {
      getActivePushCampaigns(response => {
        if (response) {
          this.setState(
            {
              campaigns: response,
            },
            () => {
              this.update(this.props.schedule);
            },
          );
        }
      });
    } else {
      getActiveEmails(response => {
        if (response) {
          this.setState(
            {
              campaigns: response,
            },
            () => {
              this.update(this.props.schedule);
            },
          );
        }
      });
    }
  }

  checkConflict() {
    const selectedDate = this.state.values.schedule.date;
    const selectedDateDay = moment(selectedDate)
      .format('dddd')
      .toLowerCase();
    const oneHourBeforeSelected = moment(selectedDate).subtract(1, 'hours');
    const oneHourBeforeSelectedMinutes =
      oneHourBeforeSelected.minutes() + oneHourBeforeSelected.hours() * 60;
    const oneHourAfterSelected = moment(selectedDate).add(1, 'hours');
    const oneHourAfterSelectedMinutes =
      oneHourAfterSelected.minutes() + oneHourAfterSelected.hours() * 60;

    // if schedule.type === "periodic" and schedule.period === "selectedday", same hour with selected date
    const periodicConflictedCampaigns = this.state.campaigns.filter(
      campaign => {
        if (campaign.schedule) {
          const campaignDate = moment(campaign.schedule.date);
          const campaignDateMinutes =
            campaignDate.minutes() + campaignDate.hours() * 60;
          return (
            campaign.schedule.type === 'periodic' &&
            (campaign.schedule.period === 'every_day' ||
              campaign.schedule.period === this.state.values.schedule.period) &&
            oneHourBeforeSelectedMinutes < campaignDateMinutes &&
            campaignDateMinutes < oneHourAfterSelectedMinutes
          );
        }
        return false;
      },
    );
    // If schedule.type === "one_time" and date is forward, same hour with selected date
    const scheduledConflictedCampaigns = this.state.campaigns.filter(
      campaign => {
        if (campaign.schedule) {
          const campaignDate = moment(campaign.schedule.date);
          return (
            campaign.schedule.type === 'one_time' &&
            campaignDate.isBetween(oneHourBeforeSelected, oneHourAfterSelected)
          );
        }
        return false;
      },
    );

    // if campaign.type === "newcomers" or "topsellerz" and params day and params time, same hour with selected date

    const personalizedConflictedCampaigns = this.state.campaigns.filter(
      campaign => {
        if (campaign.type === 'NEW_COMERS' || campaign.type === 'TOP_SELLERS') {
          const { workingDay, workingHour } = campaign.params;
          const selectedDayNum = moment(selectedDate).isoWeekday();
          if (workingDay === selectedDayNum) {
            if (
              moment(selectedDate).hours() - 1 <=
              workingHour <=
              moment(selectedDate).hours() + 1
            ) {
              return true;
            }
          }
        }
      },
    );

    this.setState({
      conflictCampaigns: periodicConflictedCampaigns.concat(
        scheduledConflictedCampaigns,
        personalizedConflictedCampaigns,
      ),
    });
  }

  onWarningClick(e) {
    e.preventDefault();
    this.setState({
      warningShown: true,
    });
  }

  render() {
    const exmap = [{ id: Math.random(), key: '', value: '' }];
    const tooltipText = this.props.isPush
      ? 'You can schedule when to send the push notification.'
      : 'You can plan and schedule to send mail';

    return (
      <div
        className={classNames('mp-field-wrapper mp-field-wrapper-schedule', {
          'mp-field-wrapper-email': this.props.isEmail,
          'mp-schedule-full': this.state.values.schedule.type !== 'one_time',
          'has-error':
            !Object.keys(this.state.values.schedule).length ||
            Object.keys(this.state.values.schedule).length <
              this.state.scheduleController,
        })}
      >
        <h3 className='wizard-input-type-title'>
          <Icons name='clock' />
          {t('Scheduling')}
          <span className='field-tooltip-icon'>
            <Tooltip content={tooltipText} alignment='left'>
              <i className='icon-info' role='presentation'>
                <span className='for-screenreader-only' />
              </i>
            </Tooltip>
          </span>
        </h3>
        <div className='email-mapping'>
          <Select
            name='type'
            className='item-field large'
            label='Repeating'
            value={this.state.values.schedule.type}
            options={types}
            onChange={this.handleType}
          />
          {this.state.values.schedule.type === 'periodic' && (
            <>
              <Select
                name='period'
                className='item-field large'
                label='Period'
                value={
                  this.state.isMulti
                    ? this.state.values.schedule.periodList
                    : this.state.values.schedule.period
                }
                options={this.state.selectablePeriods}
                onChange={this.handlePeriod}
                specialLabel='Every'
                multi={this.state.isMulti}
              />
              <Calendar
                name='date'
                className='item-field small'
                dateFormat={false}
                isValidDate={isValidStartDate}
                value={reformatDateByTimeZone(
                  this.state.values.schedule.date,
                  'HH:mm',
                )}
                label='Hour'
                onChange={this.handleDateChange}
              />
            </>
          )}
          {this.state.values.schedule.type === 'one_time' && (
            <Calendar
              name='date'
              className='item-field medium'
              checkPastDate
              value={
                this.props.isPreview
                  ? reformatDateByTimeZone(this.state.values.schedule.date)
                  : isBeforeNowByTimeZone(this.state.values.schedule.date)
                  ? reformatDateByTimeZone(defaults.schedule.date)
                  : reformatDateByTimeZone(this.state.values.schedule.date)
              }
              isValidDate={isValidStartDate}
              label='Date'
              onChange={this.handleDateChange}
            />
          )}
          {!this.state.warningShown &&
            this.state.conflictCampaigns.length > 0 &&
            this.state.values.schedule.type !== 'now' && (
              <div
                className={classNames('conflict-warning', {
                  'conflict-warning--periodic':
                    this.state.values.schedule.type === 'periodic',
                  'conflict-warning--mail': !this.props.isPush,
                })}
              >
                <Icons name='clock' color='#7a7a7c' />
                <div
                  style={{
                    float: 'right',
                    width: 'calc(100% - 25px)',
                  }}
                >
                  You already have active campaign(s) called
                  {this.state.conflictCampaigns.map((cmp, ind) => {
                    if (ind !== this.state.conflictCampaigns.length - 1) {
                      return <strong> {cmp.name || cmp.scenarioName},</strong>;
                    }
                    return <strong> {cmp.name || cmp.scenarioName} </strong>;
                  })}
                  at this time. Change the time to prevent your campaigns from
                  overlapping.
                  <button type='button' onClick={this.onWarningClick}>
                    Got It!
                  </button>
                </div>
              </div>
            )}
          {!Object.keys(this.state.values.schedule).length ||
            (Object.keys(this.state.values.schedule).length <
              this.state.scheduleController && (
              <div className='item-error'>
                {t('You should select scheduling method')}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
  MPScheduling,
);
