/**
 * @author Mehmet Yurtar
 */

/* eslint react/display-name: "off" */

import React from 'react';

// shared of config translations
// add component translation files together with the component
export const tr = {
  // Menu
  Dashboard: 'Panel',
  Recommend: 'Öneriler',
  'View All': 'Tümü',
  Report: 'Rapor',
  Reports: 'Raporlar',
  'Executive Summary': 'Yönetici Özeti',
  Sales: 'Satışlar',
  Trendify: 'Trendify',
  Products: 'Ürünler',
  Categories: 'Kategoriler',
  Brands: 'Markalar',
  Insights: 'Insights',
  Bannerify: 'Bannerify',
  Settings: 'Ayarlar',
  Account: 'Hesap',
  Plugins: 'Pluginler',
  Payments: 'Ödemeler',
  Integrations: 'Entegrasyon',
  Currency: 'Para Birimi',
  'Segmentation Module': 'Segmentation',
  'Recommendation Settings': 'Öneriler',

  // Dashboard
  'WidgetActual View': 'Bileşen Görüntülenme Sayısı',
  WidgetConversion: 'Görüntülenme Oranı',
  EmailConversion: 'Email Görüntülenme Oranı',
  'WidgetTotal View': 'Bileşenin Sayfada Yüklenme Sayısı',
  ImpressionRate: 'Görüntülenme Oranı',
  InteractionConversion: 'Tıklanma Or.. (CTR)',
  'InteractionLeft Arrow Click': 'Left Arrow Click',
  'InteractionRight Arrow Click': 'Right Arrow Click',
  'BasketTotal Amount': 'Toplam Tutar',
  'BasketAverage Contribution': 'Ortalama Katkı',
  BasketConversion: 'Dönüşüm Oranı',
  RevenuePurchases: 'Toplam Satış Adedi',
  'RevenuePurchase Items': 'Satılan Ürün Adedi',
  RevenueConversion: 'Dönüşüm Oranı',
  'RevenueContribution Ratio': 'Katkı Oranı',

  view: 'görüntülenme',
  item: 'ürün',
  views: 'görüntülenme',
  click: 'tıklanma',
  banner_clicks: 'afiş tıklama',
  product_clicks: 'ürün tıklanma',
  items: 'ürün',

  'Segmentify Performance': 'Segmentify Performansı',
  'Your Website': 'Websiteniz',

  Widget: 'BİLEŞEN (WIDGET)',
  Interaction: 'ETKİLEŞİM',
  Revenue: 'SATIŞ GELİRİ',
  'Page View': 'SAYFA GÖRÜNTÜLENMESİ',
  Device: 'CİHAZ',
  'Audience-Website': 'SİTE ZİYARETÇİLERİ',
  Audience: 'Audience',
  Overall: 'TOPLAM',
  'Revenue (Overall)': 'TOPLAM SATIŞ GELİRİ',
  'Average Sessions': 'Ortalama Oturum Adedi',
  'Actual View': 'Görüntülenme',
  Conversion: 'Dönüşüm Oranı',
  'Total Amount': 'Toplam Tutar',
  'Average Contribution': 'Ortalama Katkı',
  Purchases: 'Toplam Satış Adedi',
  'Purchase Items': 'Satın Alınan Ürün Adedi',
  'Average Basket': 'Sepet Ortalaması',
  'Contribution Ratio': 'Katkı Oranı',
  PC: 'PC',
  Mobile: 'Mobil',
  Tablet: 'Tablet',
  'Unique Visitors': 'Tekil Ziyaretçi',
  'Unique Sessions': 'Tekil Oturum',
  'Pages/Sessions': 'Sayfa / Oturum',
  'Product/Sessions': 'Ürün / Oturum',

  // Recommendation Page Types
  Page: 'Sayfa',
  'Home Page': 'Ana Sayfa',
  'Category Page': 'Kategori Sayfası',
  'Product Page': 'Ürün Sayfası',
  'Basket Page': 'Sepet Sayfası',
  'Search Page': 'Arama Sayfası',
  '404 Page': '404 Sayfası',
  'Custom Pages': 'Diğer Sayfalar',
  'Trigger Based Page': 'Eylem Bazlı Kampanya',
  'Other Pages': 'Diğer Sayfalar',
  'Custom Page': 'Özelleştirilmiş Sayfa',
  Home: 'Anasayfa',
  Category: 'Kategori',
  Product: 'Ürün',
  Basket: 'Sepet',
  Search: 'Arama',
  Palantir: 'Arama',
  Other: 'Diğer',

  // Campaign Actions
  Actions: 'İşlemler',
  Edit: 'Düzenle',
  Duplicate: 'Kopyala',
  History: 'Tarihçe',

  // Persona Quiz
  Add_New_Response: 'Yeni Soru Ekle',

  'Live Campaigns': 'Yayında Olan Kampanyalar',
  'Test Campaigns': 'Test Kampanyaları',

  // When there is no Active Campaign
  NoActiveHomeCampaigns: () => {
    return (
      <div>
        <p>
          Anasayfada kampanya ya da kategori bannerlarının ağırlıklı
          kullanıldığı tasarımlarda daha çok jenerik ürünler öne çıkarıldığı
          için satış fırsatları kaçıyor. Halbuki ana sayfadaki
          ziyaretçilerinizi, “Sizin için Seçilenler” başlığı altında kişiye özel
          ürün önerisi ve eylem çağrısı (Call to Action) yaparak direkt satışa
          yönlendirebilirsiniz.
        </p>
        <p>
          <strong>
            Ana sayfanızda kişiselleştirilmiş ürün önerisi yapabilmek için{' '}
          </strong>
          lütfen aşağıda tercih ettiğiniz widget tipini seçin.
        </p>
      </div>
    );
  },
  NoActiveCategoryCampaigns: () => {
    return (
      <div>
        <p>
          Kategori sayfaları en çok ziyaretçi aldığınız sayfalardır ancak en
          yüksek bounce rate’i olan sayfalar da yine kategori sayfalarıdır.
          Ziyaretçinizin gezdiği bir kategoride ilgi gösterdiği ürünleri baz
          alarak o kategorideki en popüler ürünleri önermek, kategori
          sayfalarından sitenizin terk edilme oranlarını düşürecektir.
        </p>
        <p>
          <strong>
            Kategori sayfanızda kişiselleştirilmiş, ilgi çekici öneri yapabilmek
            için{' '}
          </strong>
          lütfen aşağıda tercih ettiğiniz widget tipini seçin.
        </p>
      </div>
    );
  },
  NoActiveProductCampaigns: () => {
    return (
      <div>
        <p>
          Ürün sayfaları, bir e-ticaret sitesinde dönüşümün gerçekleştiği sayfa
          olması nedeniyle dönüşüm optimizasyon çalışmalarının odaklanması
          gereken sayfalardır. Görüntülenen ürüne benzer ürün alternatiflerini
          ürün önerisi olarak sunmak, satın alma olasılığını artıracaktır. Ürün
          sayfanızda ziyaretçilerinize beğenebilecekleri alternatif ürün
          önerileri getirmek için lütfen aşağıda tercih ettiğiniz widget tipini
          seçin.
        </p>
        <p>
          <strong>
            Ürün sayfanızda ziyaretçilerinize beğenebilecekleri alternatif ürün
            önerileri getirmek için{' '}
          </strong>{' '}
          lütfen aşağıda tercih ettiğiniz widget tipini seçin.
        </p>
      </div>
    );
  },
  NoActiveBasketCampaigns: () => {
    return (
      <div>
        <p>
          Ortalama sepet sipariş tutarını artırmanın en etkili yöntemlerinden
          bir tanesi sepet sayfasında çapraz satış yapmaktır. Halihazırda
          alışveriş yapan müşterilerinize doğru ürünleri önerdiğinizde, çoğunun
          ilave en az bir adet ürün daha sepete ekleyerek satın alma yaptığını
          görebilirsiniz.
        </p>
        <p>
          Sepet sayfanızda müşterilerinize alakalı ürün önerileri göstererek
          çapraz satış yapmak için
          <strong>lütfen aşağıda tercih ettiğiniz widget tipini seçin. </strong>
        </p>
      </div>
    );
  },
  NoActiveSearchCampaigns: () => {
    return (
      <div>
        <p>
          Pek çok ziyaretçi, sitede aradığı ürünü bulamadığında siteyi terk
          eder. Arama çubuğunda belirli bir ürünü aratan ziyaretçinize, boş bir
          arama sonuç sayfası göstermek yerine aradığı ürüne en yakın alternatif
          ürünleri ya da ilgili kategorinin en çok satanlarını önererek,
          kaçmakta olan bir fırsatı satışa çevirin ve müşterilerinizi memnun
          edin.
        </p>
        <p>
          <strong>
            Arama sonuç sayfasında müşterilerinize alakalı ürünler önererek,
            kaçmakta olan bir fırsatı satışa çevirmek için{' '}
          </strong>
          lütfen aşağıda tercih ettiğiniz widget tipini seçin.
        </p>
      </div>
    );
  },
  NoActive404Campaigns: () => {
    return (
      <div>
        <p>
          Sitenizde bir sayfanın url’sini kaldırdığınızda bile, indekslenmiş ise
          arama motorlarından ya da bağlantı verilmiş farklı kaynaklardan
          ziyaretçi çekmeye devam edebilir. Bu tip durumlarda,
          ziyaretçilerinizin karşısına hiçbir yönlendirmesi olmayan 404 Error
          sayfası çıkarmak, müşterilerinizin sitenizi dolaşamadan terk etmesine
          sebep olacaktır. 404 Error sayfalarında bile ilgi çekici ürün
          önerileri yaparak, müşteri deneyimini iyileştirebilir ve
          ziyaretçilerinizi satın almaya yönlendirebilirsiniz.
        </p>
        <p>
          <strong>
            404 sayfasında ziyaretçilerinizi, ilgi çekici ürünler önererek
            satışa yönlendirmek için{' '}
          </strong>{' '}
          lütfen aşağıda tercih ettiğiniz widget tipini seçin.
        </p>
      </div>
    );
  },
  NoActiveCustomCampaigns: () => {
    return (
      <div>
        <p>
          Segmentify’da öntanımlı olmayan özel bir sayfada kampanya
          oluşturabilirsiniz. Örneğin, kullanıcı hesap sayfası, çıkış sayfası
          gibi.
        </p>
        <p>Lütfen aşağıda tercih ettiğiniz widget tipini seçin.</p>
      </div>
    );
  },
  'NoActiveTrigger BasedCampaigns': () => {
    return (
      <div>
        <p>
          Ziyaretçilerinizin site içindeki hareketine bağlı kampanya oluşturmak
          için “Trigger Based” kampanyalar oluşturabilirsiniz
        </p>
        <p>Lütfen aşağıda tercih ettiğiniz widget tipini seçin.</p>
      </div>
    );
  },

  'Need Help?': 'Yardım ister misin?',
  'Full Width': 'Sayfa Genişliğinde',
  'Right/Left Side': 'Sağ/Sol Kenar Çubuğu',
  'Add Custom Style Widget': 'Özel Stilli Bileşen Ekle',

  // Add Campaign
  'Widget Title': 'Bileşen Başlığı',
  'This title will shown on your widget':
    'Bu başlık bileşen üzerinde gözükecektir.',

  Intelligent: 'Akıll Algoritmalar',
  Criteria: 'Filtrele (Dahil Et)',
  Excludes: 'Filtreler (Hariç Tut)',
  'Shuffle Items': 'Sıralamayı Karıştır',
  'Smart Offers': 'Akıllı Öneriler',
  'All Category Products': 'Tüm Kategori Ürünleri',
  'All Child Category Products': 'Alt Kategori Ürünleri',
  'All Parent Category Products': 'Ana Kategori Ürünleri',
  'All Products': 'Tüm Ürünler',
  'Last Visited (Product)': 'Son Bakılan Ürünler',
  'Purchased Together (Basket)': 'Birlikte Alınan (Sepet)',
  'Purchased Together (Product)': 'Birlikte Alınan (Ürün)',
  'Viewed Together (Basket)': 'Birlikte Bakılan (Basket)',
  'Viewed Together (Product)': 'Birlikte Bakılan (Product)',
  'Rockstar Products': 'Yıldız Ürünler',
  'Trending Products': 'Yükselişteki Ürünler',
  'Shining Stars': 'Işıldayan Ürünler',
  'Discounted Products': 'İndirimli Ürünler',
  'Brand Products': 'Marka Ürünleri',
  'Alternative Products': 'Alternatif Ürünler',
  'All Category Product': 'Tüm Kategori Ürünleri',
  'View - Today': 'En Çok Görüntülenen - Bugün',
  'View - This Week': 'En Çok Görüntülenen - Bu Hafta',
  'View - This Month': 'En Çok Görüntülenen - Bu Ay',
  'Purchase - Today': 'Satın Alınanlar - Bugün',
  'Purchase - This Week': 'Satın Alınanlar - Bu Hafta',
  'Purchase - This Month': 'Satın Alınanlar - Bu Ay',
  'New Arrivals': 'Yeni Gelen Ürünler',
  'Product Name': 'Ürün Adı',
  'Product Name/ID': 'Ürün Adı/ID',
  'Price Range': 'Fiyat Aralığı',
  Custom: 'Özel',
  Param: 'Parametre',
  Value: 'Değer',
  Brand: 'Marka',
  Label: 'Etiket',
  Gender: 'Cinsiyet',
  Size: 'Ölçü',

  min: 'min',
  max: 'max',

  Equals: 'Eşitse',
  'Not Equals': 'Eşit Değilse',
  Contains: 'İçeriyorsa',
  'Not Contains': 'İçermiyorsa',

  'Campaign Subject': 'Kampanya Başlığı',

  'Start Date - Time': 'Başlangıç Tarihi',
  'End Date - Time': 'Bitiş Tarihi',

  'Device Types': 'Cihaz Tipleri',
  Web: 'Web',
  App: 'App',

  'You should select at least one device type':
    'En az bir cihaz tipi seçmelisiniz.',

  'Target Div Selector': 'Hedeflenen Div Seçicisi',
  'The target component on your website where recommendation box going to insert itself':
    'Sayfanızda ürün önerilerinin gösterileceği kısmı belirten html elemanı.',

  Self: 'İçerisine',
  Before: 'Öncesine',
  After: 'Sonrasına',
  Append: 'Sonuna',
  Prepend: 'Önüne',

  'Edit HTML': "HTML'i düzenle",
  'Edit PreJS': "Pre  JS'i Düzenle",
  'Edit PostJS': "Post JS'i Düzenle",

  'Advanced Configuration': 'Gelişmiş Konfigurasyon',
  'Go Test': 'Test Moduna Al',
  'Go Live': 'Yayına Al',

  'Attention!': 'Dikkat!',
  'This action will immediately effect on your website':
    'Bu işlem websitenize hemen etki edecektir',

  'no-recommendation-report-info': statusAndCampaign => {
    return (
      <span>
        Bu sayfada <strong>{statusAndCampaign}</strong> bulunmuyor.
      </span>
    );
  },

  'no-personalization-report-info': statusAndCampaign => {
    return (
      <span>
        <strong>{statusAndCampaign}</strong> Kampanyası bulunmuyor.
      </span>
    );
  },
  'no-campaign-info': status => {
    return (
      <span>
        <strong>{status} Kampanya </strong>Bulunmuyor
      </span>
    );
  },
  'Create Now': 'Hemen Oluştur',
  'Campaign Report': 'Kampanya Raporu',

  // Trendify
  'Product Stock': 'Ürün Stoğu',
  'Only In Stock': 'Stoktaki Ürünler',
  'Only Out of Stock': 'Stokta Olmayan Ürünler',
  'All Product Categories': 'Tüm Ürün Kategorileri',
  'Search Category': 'Kategori Ara',
  'Product Category': 'Ürün Kategorisi',
  'Product ID or Title': "Ürün ID'si ya da adı",
  'Search Product': 'Ürün Ara',
  'Product Brand': 'Ürün Markası',
  'All Product Brands': 'Tüm Markalar',
  Segmentify: 'Segmentify',
  Sources: 'Kaynak',
  'Check-in': 'Check-in',
  'Product Check-in Time': 'Ürün Check-in zamanı',
  ID: 'ID',
  'Product ID': 'Ürün ID',
  'Product name': 'Ürün adı',
  'Product Categories': 'Ürün kategorileri',
  Price: 'Fiyat',
  OldPrice: 'Old Price',
  'Product price and discounted price': 'Fiyat ve indirimli fiyat',
  Image: 'Görsel',
  'Product Image Url': 'Ürün Görseli',
  View: 'görüntülenme',
  'Number Of Product Views': 'Ürün Görüntülenme Sayısı',
  'Number Of Add To Basket Operations': 'Sepete Eklenme Sayısı',
  Rate: 'Oran',
  'Conversion Rate (Purchase/Vew)': 'Dönüşüm Oranı (Satın Alma/Görüntülenme)',
  'Conversion Rate (Add To Basket/View)':
    'Dönüşüm Oranı (Sepete Ekleme/Görüntülenme)',
  Purchase: 'Satış Adedi',
  'Number Of Product Purchases': 'Ürün Satış Adedi',
  Amount: 'Satış Tutarı',
  'Amount Of Product Purchases': 'Satılan Ürün Tutarı',
  'Number Of Clicks By Segmentify Recommendation Widgets':
    'Segmentify Öneri Bileşenlerinden Tıklanma Sayısı',
  'Number Of Add To Basket Operations By Segmentify Recommendation Widgets':
    'Segmentify Öneri Bileşenlerinden Sepete Ekleme Sayısı',
  'Conversion Rate (Add To Basket/Click)':
    'Dönüşüm Oranı (Sepete Ekleme/Tıklanma)',
  'Number Of Purchases By Segmentify Recommendation Widgets':
    'Segmentify Öneri Bileşenlerinden Gerçekleşen Satış Adedi',
  'Conversion Rate (Purchase/Click)': 'Dönüşüm Oranı (Satın Alma/Tıklanma)',
  'Amount Of Purchases By Segmentify Recommendation Widgets':
    'Segmentify Öneri Bileşenlerinden Gerçekleşen Satış Tutarı',
  'Views Referred From In-site': 'Site İçerisinden Gelen Görüntülenme',
  'Views Referred From Social Media': 'Sosyal Medyadan Gelen Görüntülenme',
  'Views Referred From Search Engines':
    'Arama Motorlarından Gelen Görüntülenme',
  'Views Referred From Advertisements': 'Reklamlardan Gelen Görüntülenme',
  'Views Referred From Email Campaigns':
    'Eposta Kampanyalarından Gelen Görüntülenme',
  'Views Referred From Price Listing Sites':
    'Fiyat Listeleme Sitelerinden Gelen Görüntülenme',
  'Views Referred From Other Sources': 'Diğer Kaynaklardan Gelen Görüntülenme',
  Show: 'Göster',
  Campaigns: 'Kampanyalar',
  TrendifyPromo: () => {
    return (
      <span>
        <strong>Ürünlerinizin Performansını</strong>{' '}
        <strong> Gerçek Zamanlı</strong> Takip Edin
      </span>
    );
  },
  'something-went-wrong':
    'Sayfa yüklenirken bir şeyler ters gitti, lütfen sayfayı yenileyiniz.',
  'campaigns-not-found': 'Kampanyalar bulunamadı, lütfen sayfayı yenileyiniz.',

  // Insights
  'Whenever your customers see these products they buy them':
    'Müşterileriniz bu ürünleri gördüğü an satın alıyor.',
  'High Spending': 'Yüksek Bütçe Harcayan Ürünler',
  'Most viewed products with a referral from a paid channel':
    'Ücretli kanallarda en çok tıklanan ama satışa dönüşmeyen ürünler',
  'The best of a bad bunch': 'İlgi çeken ama satılmayan ürünler',
  'These are the most viewed products among the none-selling ones':
    'Hiç satılmamamış ürünler arasında en çok görüntelenen ürünler.',
  'Discount Works': 'İndirimde en çok satan ürünler',
  'Most selling products among the discounted ones':
    'İndirimli ürünler arasında en çok satılanlar.',
  'We Wish You Had': 'Stoklarınızda olsaydı satabileceğiniz ürünler',
  'Most viewed products that are out of stock':
    'Stokta olmayan ürünleriniz arasında en çok görüntülenen ürünler',
  'Abandoned Items': 'Sepette bırakılmış ürünler',
  'Which products your customers left in their cart':
    'Müşterilerinizin sepette en çok bıraktığı ürünler',
  'Organic Bazaar': 'Arama motoru ile görüntülenenler',
  'Most viewed products with a referral from Search Engine':
    'Arama motoru sonuçlarına tıklanarak en çok görüntülenen ürünler',
  'Social Trends': 'Sosyal medya kanallarındaki trendler',
  'Most viewed products with a referral from Social Media':
    'Sosyal medyada yapılan paylaşımlara, beğenilere tıklanarak en çok görüntülenen ürünler.',
  'You push hard': 'Sepete eklenme oranı düşük',
  "Your customers see these products but they don't buy them":
    'Bu ürünlerin görüntülenme adedine göre sepete eklenme oranı düşük.',
  'Products to Improve': 'Satışı düşük giden ürünleriniz',
  'Worst converting products':
    'Müşterileriniz bu ürünleri sıklıkla görüntülüyor ama satışları çok düşük.',
  'Most Revenue Growth (prior to compared period)':
    'Son 7 gün içinde gerçekleşen satış gelirinde bir önceki haftaya göre en çok artış görülen ürünler.',
  'Losing Power': 'Satışı Geliri Düşen Ürünler',
  'Most Revenue Loss (prior to compared period)':
    'Son 7 gün içinde gerçekleşen satış gelirinde bir önceki haftaya göre en çok düşüş görülen ürünler.',
  'An increasing trend occurred for these items in a selected basis':
    'Son 7 gün içinde gerçekleşen satış adedinde bir önceki haftaya göre en çok artış görülen ürünler.',
  'Under Shadow': 'Satışları Düşen Ürünler',
  'A decreasing trend occurred for these items in a selected basis':
    'Son 7 gün içinde gerçekleşen satış adedinde bir önceki haftaya göre en çok düşüş görülen ürünler.',
  'Basket Complementaries': 'Sepet tamamlayıcıları',
  'List of basket complementaries for the top selling products':
    'En fazla satılan ürünlerle birlikte alınan tamamlayıcı ürünler',
  allView: 'Gösterim',
  purchase: 'Satın Alma',
  basket: 'Sepetlere Ekle',
  allViewCurWeek: 'İkinci Dönem Görüntülemeleri',
  purchaseCurWeek: 'İkinci Dönem Satın Almaları',
  allViewLastWeek: 'İlk Dönem Görüntülemeleri',
  purchaseLastWeek: 'İlk Dönem Satın Almaları',
  allPaidClick: 'Ücretli Kanaldan Gelen Tıklamalar',
  allSearchClick: 'Arama Motorundan Gelen Tıklamalar',
  allSocialClick: 'Sosyal Medya Kanallarından Gelen Tıklamalar',
  allAmountLastWeek: 'İlk Dönem Geliri',
  allAmountCurWeek: 'İkinci Dönem Geliri',
  amount: 'Gelir',

  // Bannerify
  Banner: 'Banner',
  'Banner Group': 'Banner Grubu',
  'Banner Titles': 'Banner Başlıkları',

  'All Groups': 'Tüm Gruplar',
  'All Titles': 'Tüm Başlıklar',
  'Show Active Banners': 'Aktif Bannerları Göster',
  'Show All Banners': 'Tüm Bannerları Göster',

  Group: 'Grup',
  'Banner Order': 'Banner Sırası',
  'Check-in Time of Banner': 'Banner Check-in Zamanı',
  'Last Update': 'Son Güncelleme',
  'Last Update Time of Banner': 'Banner Son Güncellenme Zamanı',
  Title: 'Başlık',
  'Banner Title': 'Banner Başlığı',
  'Banner Image Url': 'Banner Görseli',
  Targets: 'Hedefler',
  'Banner Target(s)': 'Banner Hedefi',
  Impression: 'Yüklenme',
  'Number of Impressions': 'Görüntülenme Sayısı',
  Click: 'Tıklanma',
  'Number Of Clicks': 'Tıklanma Sayısı',
  'Conversion Rate (Click/Impression)':
    'Tıklanma Oranı (Tıklanma/Banner Görüntülenmesi)',
  'Product Views': 'Ürün Görüntülenmesi',
  'Conversion Rate (Product View/Impression)':
    'Ürün Görüntülenme Oranı (Ürün Görüntülenme/Banner Görüntülenmesi)',
  'Conversion Rate (Add To Basket/Impression)':
    'Sepete Eklenme Oranı (Sepete Eklenme/Banner Görüntülenme)',
  'Number Of Purchases': 'Satın Alma Sayısı',
  'Conversion Rate (Purchase/Impression)':
    'Satın Alma Oranı (Satın Alma/Banner Görüntülenme)',

  BannerifyPromo: () => {
    return (
      <span>
        <strong>Bannerlarınızın Performansını</strong>
        <strong> Gerçek Zamanlı</strong> Takip Edin
      </span>
    );
  },
  Discover: 'Keşfet',

  of: 'of',
  Previous: 'Önceki',
  Next: 'Sonraki',

  // Settings
  'Account Settings': 'Hesap Ayarları',
  'User Settings': 'Kullanıcı Ayarları',
  'Api Key': 'API Key',
  'Account Domain': "Site URL'i",
  'Contact Person': 'İletişime Geçilecek Kişi',
  'Contact Phone': 'İletişime Geçilecek Telefon',
  'Contact Address': 'İletişim Adresi',
  Username: 'Kullanıcı Adı',
  'Display Name': 'İsim',
  'Display Language': 'Dil Seçeneği',
  'New Password': 'Yeni Parola',
  'New Password (Again)': 'Yeni Parola (Tekrar)',
  'Customer Removal Tool': 'Customer Removal Tool',
  'Customer Removal Tool Description':
    'All historical personal data related to the given email address can be removed via this tool.',
  'Customer Removal Tool Description Label':
    "Insert customer's email address(es) here, separated by commas for more than one user.",
  'Customer Removal Tool Disclaimer': () => {
    return (
      <p>
        All historical personal information related to given email addresses
        will be deleted within the next 24 hours. When the removal process is
        finished, you will receive an email.
        <br />
        Please note that the removal process is permanent and irreversible, upon
        its completion Segmentify can't recover any data.
      </p>
    );
  },

  // Payments
  'Monthly Subscription': 'Aylık Abonelik',
  'Standard Packages': 'Standart Paketler',
  Name: 'Ad',
  Surname: 'Soyad',
  Address: 'Adres',
  City: 'Şehir',
  Country: 'Ülke',
  STARTER: 'BAŞLANGIÇ',
  BASIC: 'TEMEL',
  PRO: 'PRO',
  'Payment History': 'Ödeme Geçmişi',
  mo: 'ay',
  'Package Details & Payment Info': 'Paket Detayları & Ödeme Bilgisi',
  Date: 'Tarih',
  Operation: 'İşlem',

  // Settings.Integrations
  "Let's Integrate Segmentify": 'Segmentify Entegrason Adımları',
  Initialize: 'Kodu Ekle',
  'integration-script-info':
    "Sitenizde Segmentify ile Akıllı Ürün Önerileri yapmak çok kolay. Tek yapmanız gereken JavaScript kodunu bütün sayfalarınızın <HEAD> tag'i kapanmadan hemen öncesine eklemek",
  'Complete the Integration': 'Entegrasyonu Tamamla',
  'integration-type-selection':
    'Entegrasyonu kendiniz yapabilir ya da her zaman bizden destek alabilirsiniz!',
  'Self Service': 'Kendin Yap',
  IntegrationZendDesk: () => {
    return (
      <span>
        Entegrasyon için gerekli adımları{' '}
        <a
          href='https://segmentify.zendesk.com/hc/en-us'
          target='_blank'
          rel='noreferrer'
        >
          Segmentify Customer success Center
        </a>{' '}
        da takip edebilirsiniz.
      </span>
    );
  },
  StartChat: var1 => {
    return (
      <span>
        Segmentify Customer Success ekibi entegrasyonu sizin için
        hazırlayabilir, <a>şimdi iletişime geçin</a>
      </span>
    );
  },
  'Help me, please': 'Yardım',
  'Choose what type recommendation features you want to use from Segmentify':
    'Hangi çözüm ile ilgilendiğinizi seçin.',
  'integration-page-types':
    'Önce önerileri göstermek istediğiniz sayfa tipini (Ürün Sayfası, Anasayfa gibi) sonrasında öneri tiplerini seçin (Editörün Seçimi, Sizin İçin Seçtikleriniz, En İyiler gibi).',
  'Recommended for You': 'Sizin İçin Önerilenler',
  'Best of Category': 'Kategorinin En İyileri',
  'Last Minute Offers': 'Son Dakika Teklifleri',
  'Start Recommend': 'Öneri Yapmaya Başla',

  // 404 Texts
  'Page Not Found': 'Sayfa Bulunamadı',

  // Account Types
  type: 'Type',
  provider: 'Kaynak',
  WAITING: 'Waiting',
  PRE_POC: 'Pre-POC',
  POC: 'POC',
  STAGING: 'Staging',
  FAILED: 'Failed',
  FREEMIUM: 'Freemium',
  GROUP_A: 'Group A',
  GROUP_B: 'Group B',
  GROUP_C: 'Group C',
  ARCHIVE: 'Archive',
  PAYING: 'Paying',
  ONHOLD: 'Onhold',
  CHURN: 'Churn',
  PANEL_RESTRICTED: 'Restricted',

  // Engagement
  Engagement: 'Kişiselleştirme',
  Personalization: 'Kişiselleştirme',
  Popup: 'Popup',
  'Add Popup': 'Popup Oluştur',
  Popups: 'Popup',
  'Popup Banner': 'Popup Banner',
  'Popup Recommendation': 'Öneri Popupı',
  'E-Mail Collection': 'E-Mail Toplama',
  Notifications: 'Bildirimler',
  'Push Permission': 'Push İzni',
  'Push Notification': 'Push Bildirimi',
  'Push Notifications': 'Push Bildirimleri',
  'Notification Bar': 'Bildirim Barı',
  'No Active Campaigns': 'Aktif Kampanya Bulunmuyor',
  Campaign: 'Kampanya',

  'Add Popup Banner': 'Popup Banner Oluştur',
  'Add Popup Recommendation': "Ürün Öneri Popup'ı Oluştur",
  'Add E-Mail Collection Popup': 'E-Mail Toplama Formu Oluştur',
  'Add Push Permission': 'Push İzin Kampanyası Oluştur',
  'Add Push Notification': 'Push Bildirimi Oluştur',
  'Add Notification Bar': 'Bildirim Barı Oluştur',

  'Pages To Show': 'Gösterilecek Sayfalar',
  Position: 'Pozisyon',
  Top: 'Üst',
  Middle: 'Orta',
  Bottom: 'Alt',
  Left: 'Sol',
  Center: 'Orta',
  Right: 'Sağ',
  'Timing & Frequency': 'Zamanlama ve Sıklık',
  Timing: 'Zamanlama',
  Immediate: 'Hemen',
  Delay: 'Ertele',
  Scroll: 'Kaydır(Scroll)',
  'Visitor Leaving Page': 'Ziyaretçi sayfadan çıkarken',
  Always: 'Her Zaman',
  Session: 'Oturum',
  'Life Time': 'Yaşam Süresi',
  Days: 'Gün',
  seconds: 'saniye',
  days: 'gün',
  times: 'kex',
  'Target Audience': 'Hedeflenen Kitle',
  'Visitor is Member': 'Ziyaretçi şu segmentte ise',
  'Visitor is not a Member': 'Ziyaretçi şu segmentte değilse',
  'Visitor is': 'Ziyaretçi',

  // Campaign Spesifics
  'Edit Popup Banner': 'Popup Banner Düzenle',
  'Background Image URL (Recommend 800x700px)':
    "Arkaplan Görsel URL'i (Önerilen 800x700px)",
  'Push Title': 'Push İzin Kampanyası Popup Başlığı',
  'Push Description': 'Push İzin Kampanyası Popup Açıklaması',
  'Your Logo URL (Recommend 200x200px)': "Logo URL'i (Önerilen 200x200px)",
  'Background Image URL (Recommend 1920x80px)':
    "Arkaplan Görsel URL'i (Önerilen 1920x80px)",
  'Vertical Align': 'Dikey Hiza',
  'Horizontal Align': 'Yatay Hiza',
  Once: 'Bir kez',
  Periodic: 'Tekrarlayan',
  Everyday: 'Hergün',
  'Every Monday': 'Her Pazartesi',
  'Every Tuesday': 'Her Salı',
  'Every Wednesday': 'Her Çarşamba',
  'Every Thursday': 'Her Perşembe',
  'Every Friday': 'Her Cuma',
  'Every Saturday': 'Her Cumartesi',
  'Every Sunday': 'Her Pazar',
  Scheduling: 'Zamanlama',
  'Visitor Segment': 'Ziyaretçi Segmenti',
  'Visitor Type': 'Ziyaretçi Tipi',
  Styles: 'Stil',
  Hours: 'Saat',
  'Valid for X': 'Geçerli',
  'Push Recommendation': 'Push Ürün Önerisi',
  'Description (Max 192 characters)': 'Açıklama (Maksimum 192 karakter)',
  'Notification Icon URL (Recommended 192x192px)':
    'Bildirim ikon linki (Önerilen 192x192px)',
  'Notification Image URL': 'Bildirim Görsel Linki',
  'Target URL': 'Yönleneceği Link',
  Frequency: 'Sıklık',
  'Background Color': 'Arkaplan Rengi',
  'Text Color': 'Yazı Rengi',
  'Your work is not saved!. Are you sure want to leave?':
    'Yaptığınız değişiklikler kaydedilmedi!. Çıkmak istediğinize emin misiniz?',
  'Scheduled Campaign': 'İleri Tarihli Kampanya',
  'Completed Campaign': 'Sona Ermiş Kampanya',
  Recommendation: 'Öneri',
  'unique-selector': 'seçici',

  // Campaign Statuses
  'Live Recommendation': 'Canlı Ürün Öneri Kampanyası',
  'Test Recommendation': 'Test Ürün Öneri Kampanyası',
  'Archived Recommendation': 'Arşivlenmiş Ürün Öneri Kampanyası',
  Archived: 'Arşivlenmiş',

  'Campaign Note': 'Kampanya Notu',

  // Sales
  'Purchase Time': 'Satınalma Zamanı',
  'Order No': 'Sipariş No',

  // Currency Settings
  'Main Currency': 'Para Birimi',
  Code: 'Kod',
  'Decimal Places': 'Ondalık',
  'Decimal Separator': 'Ondalık Ayracı',
  'Thousand Seperator': 'Binlik Ayracı',
  Symbol: 'Sembol',
  'Place Symbol': 'Sembol Konumu',
  'Comma (,)': 'Virgül (,)',
  'Dot (.)': 'Nokta (.)',
  'Empty Space': 'Boşluk',
  None: 'Hiçbiri',
  'You can check your currency code here':
    'Para birimi kodunuzu buradan öğrenebilirsiniz',
  "This Month's Cost": 'Bu ayki ödeme',

  // Campaigns Status Filters
  Live: 'Canlı',
  Test: 'Test',
  Archive: 'Arşiv',
  live: 'canlı',
  test: 'test',

  // Campaign Device Filters
  Desktop: 'Desktop',
  'Mobile Web': 'Mobile Web',
  Android: 'Android',
  iOS: 'iOS',

  // Campaigns Sorting
  'Sort by Date': 'Tarihe Göre Sırala',
  'Sort by Revenue': 'Kazanca Göre Sırala',

  // Named Time Ranges
  All: 'Tümü',
  'Last Month': 'Geçen Ay',
  'This Year': 'Bu Yıl',
  'This Month': 'Bu Ay',
  'This Week': 'Bu Hafta',
  Yesterday: 'Dün',
  Today: 'Bugün',

  // CTA Buttons
  Save: 'Kaydet',
  Reset: 'Sıfırla',
  Update: 'Güncelle',
  Cancel: 'Vazgeç',
  Confirm: 'Onayla',
  Continue: 'Devam',
  Export: 'Dışa Aktar',
  'View More': 'Daha Fazla',
  Details: 'Detay',

  // Validation Texts
  'You should select a product from suggestions':
    'En az bir ürün seçmelisiniz.',
  'You should define at least one recommendation':
    'En az bir öneri tipi seçmelisiniz.',
  'This field is required': 'Bu alan zorunludur',
  'This field should equal to #fieldName#':
    'Bu alan #fieldName# ile aynı olmalıdır',
  'This field should have at least #minLength# characters':
    'Bu alan en az #minLength#' + ' karakter içermelidir',
  PasswordField:
    'Parola en az bir harf ve bir rakam içermeli, sadece harfler, rakamlar ve şu karakterler kullanılabilir: ! @ # . _ - ( )',
  EmailField: 'Bu alan için geçerli bir e-mail adresi girilmelidir.',
  BusinessEmailCheck: 'Lütfen iş e-mail adresinizi girin.',
  'Field must be a valid URL.': 'Bu alan geçerli bir URL olmalıdır.',
  DeliveryRequired: 'En az bir delivery adımını aktive etmelisiniz.',

  // Update notification Bars
  'Recommendation widget is updated in test mode.Chrome Extension.':
    'Ürün önerisi bileşeni test modda güncellendi. Chrome uzantısını kullanarak görüntüleyebilirsiniz.',
  'Recommendation widget is updated in live mode.':
    'Ürün önerisi bileşeni canlı modda güncellendi.',
  'Recommendation widget is activated in test mode and only test users can see. You can check the widget by activating test mode in your browserChrome Extension.':
    'Ürün önerisi bileşeni test modda oluşturuldu. Tarayıcınızda Chrome uzantısını aktive ederek bileşeni test modda görüntüleyebilirsiniz.',

  // Emails
  LAST_VISIT_ALTERNATIVES: 'Son Ziyaret Alternatifleri',
  ABANDONED_CART: 'Bırakılmış Sepet',
  PERSONALIZED_NEWSLETTER: 'Kişiselleştirilmiş Bülten',
  PURCHASE_REMINDER: 'Satın Alma Hatırlatması',

  // Provider Warnings
  ShopifyWarning:
    "Segmentify uygulamasını indirmişsiniz. Shopify Store'da yönetici panelinden Segmentify Uygulamasını kullanmanızı tavsiye ederiz.",
  PrestashopWarning:
    'Segmentify eklentisini kurmuşsunuz. Prestashop yönetici panelinde Segmentify eklentisini kullanmanızı tavsiye ederiz.',
  MagentoWarning:
    'Segmentify eklentisini kurmuşsunuz. Magento yönetici panelinde Segmentify eklentisini kullanmanızı tavsiye ederiz.',

  'All Recommendations': 'Öneriler',
  Recommendations: 'Öneriler',
  'Trigger Based Campaign': 'Tetikleyici Tabanlı',
  'Form - Survey': 'Form - Anket',
  'Form Survey': 'Form - Anket',
  'Add Form - Survey': 'Form - Anket Oluştur',
  'In Future/Recurring Notifications': 'Planlanmış / Tekrarlayan Bildirimler',
  'Sent Notifications': 'Gönderilmiş Bildirimler',
  'WidgetSent Notifications': 'Sent Notifications',
  'Add Push Recommendation': 'Push Öneri Oluştur',
  'All Campaigns': 'Tüm Kampanyalar',
  'All Campaign Reports': 'Tüm Kampanya Raporları',
  'Date Comparison': 'Karşılaştır',
  'Compare To': 'Tarih Aralığında Karşılaştır',
  'Widget View': 'Bileşen Görüntülenmesi',
  'Basket Items': 'Sepet Ürünleri',
  'Purchased Items': 'Satın Alınan Ürünler',
  'Form Submit': 'Doldurulmuş Formlar',
  'Notification [shown]': 'Gönderilmiş Bildirimler',
  'Notification [clicked]': 'Tıklanan Bildirimler',
  'Notification [sent]': 'Notification Sent',
  'Notification Permission [granted]': 'Alınan Bildirim İzni',
  'Notification Permission [denied]': 'Red Edilen Bildirim İzni',
  'Purchase Amount': 'Satın Alınan Tutar',
  Quantity: 'Adet',
  'Payment Type': 'Ödeme Tipi',
  'Cart Detail': 'Sepet Detayı',
  'Sale Time': 'Tarih',
  'Graphical View': 'Grafiksel Görüntüleme',
  'Conversion Graph': 'Dönüşüm Grafiği',
  'Revenue Graph': 'Gelir Grafiği',
  'Purchase Graph': 'Satın Alım Grafiği',
  'Page Graph': 'Sayfa Görüntüleme Grafiği',
  'Device Graph': 'Cihaz Grafiği',
  'Audience Graph': 'Ziyaretçi Grafiği',
  'Segmentify Graph': 'Segmentify Grafiği',
  Stock: 'Stok',
  'Price at Cart': 'Sepetteki Fiyat',
  Order: 'Sipariş',
  'Export Products': 'Ürünleri Dışa Aktar',
  'Export File Name': 'Dosya Adı',
  'Page Selection': 'Sayfa Seçimi',
  'CSV Delimiter': 'CSV Ayırıcı',
  'Self Service? See your account specific values':
    'Self-Service Entegrasyon. Hesabınıza özel değerleri görmek için tıklayın.',
  'Custom Segmentify integration requires two important values.':
    'Özelleştirilmiş Segmentify entegrasyonu için iki önemli değer gerekmektedir.',
  'Your account specific keys': 'Hesabınıza özel değerler',
  'System Variable': 'Sistem Değişkenleri',
  Description: 'Açıklama',
  'Current Page': 'Mevcut Sayfa',
  'All Pages': 'Tüm Sayfalar',
  'Semicolon (;)': 'Noktalı Virgül (;)',
  'Tab (\\t)': 'Sekme (\\t)',
  'Export Bannerify Report': 'Bannerify Raporunu Dışa Aktar',
  Banners: 'Bannerlar',
  'Grid View': 'Izgara Görüntüleme',
  'Funnel View': 'Huni Görütüleme',
  'Event Name': 'Event İsmi',
  'Back To Wizard': 'Geri Dön',
  'Your work is not saved! Are you sure want to leave?':
    'Yaptığınız çalışmalar kaydedilmedi! Ayrılmak istediğinizden emin misiniz?',
  'There is no campaign': 'Kampanya bulunmamaktadır',
  'Edit CSS': "CSS'i Düzenle",
  'Visitor is not': 'Ziyaretçi Değil',
  New: 'Yeni',
  Registered: 'Kayıtlı',
  Logged: 'Giriş Yapmış',
  'Checkout Page': 'Kasadan Çıkış Sayfası',
  'Select Page': 'Sayfa Seç',
  Type: 'Type',
  PrivacyPolicyCta:
    "Segmentify'ı kullandığınız için cookie ve gizlilik politikalarınızı yeniden düzenlemeniz gerekiyor mu?",
  PrivacyPolicyContent:
    "Eğer Avrupa'da online faaliyetleriniz varsa, gizlilik sözleşmenizi Avrupa Birliği hukukuna göre uyarlamanız gerekecektir. Avrupa hukuku web sitesi sahiplerinden veri depolayabilmeleri için ziyaretçilerinden onay alınmasını zorunlu tutmaktadır.",
  PrivacyPolicy: () => {
    return (
      <a
        href='https://segmentify.com/privacy-policy/'
        target='_blank'
        rel='noreferrer'
      >
        Daha Fazla
      </a>
    );
  },

  // ====== 2017.09======= //
  Labels: 'Labels',
  // ======= 2017.09 ========= //
  Preview: 'Push-bildirimini görüntüleyin',
  "Your browser doesn't support Notification":
    'Tarayiciniz push-bildirimlere izin vermiyor.',
  'You have to allow notifications to preview':
    'Push-bildirimlerini görüntüleyebilmek için izin vermelisiniz.',
  'Scheduled Campaigns': 'Planlanan Kampanyalar',
  'Purchases via Banners': 'Bannerlardan Elde Edilen Satışlar',
  'Event Data': 'Event Data',
  'You should select a promotion from suggestions':
    'Öneriler arasından bir promosyon seçmelisiniz.',
  'Purchases and Items': 'Satın Almalar ve Ürünler',
  'Recommended For You': 'Sizin için önerilenler',
  'Most Popular': 'En popüler ürünler',
  'Products You Recently Viewed': 'En son göz attığınız ürünler',
  'You May Also Like': 'Bunlar da hoşunuza gidebilir',
  'Account Statistics': 'Hesap istatistikleri',
  'Checkout Success Page': 'Sipariş Başarılı sayfası',
  'NoActiveCheckout SuccessCampaigns': () => {
    return <div>Bu sayfada aktif kampanya bulunmuyor.</div>;
  },
  'Selected For You': 'Sizin için seçilenler',
  'Most Popular Products in this Category':
    'Bu kategorideki en popüler ürünler',
  'You Might Also Like': 'Bunlar da hoşunuza gidebilir',
  'Frequently Bought Together': 'Birlikte alınan ürünler',
  'Complementary Products': 'Birbirini tamamlayan ürünler',
  'You Recently Viewed': 'En son bunlara baktınız',
  'Do you want to check once more?':
    'Bir kez daha kontrol etmek ister misiniz?',
  'Top Sellers': 'En çok satanlar',
  'Top Sellers in this Category': 'Bu kategoride en çok satanlar',
  'You should select at least one page type':
    'En az bir sayfa tipi seçmelisiniz',
  'Select Pages': 'Sayfaları seçin',
  'Segmented Users': 'Segmented kullanıcılar',
  Champions: 'Rockstarlar',
  'Loyal Customers': 'Favoriler',
  'Potential Loyalist': 'Giderek daha çok alışveriş yapanlar',
  'New Customers': 'Yeni gelenler',
  Promising: 'Az harcayan yeni gelen müşteriler',
  'Customers Needing Attention': 'Kaybedilmek üzere olan favoriler',
  'About To Sleep': 'Kaybedilmek üzere olan müşteriler',
  'At Risk': 'Kaybedilmek üzere olan rockstarlar',
  "Can't Lose Them": 'Bir zamanlar çok harcama yapan müşteriler',
  Hibernating: 'Hareketsiz müşteriler',
  Lost: 'Lost',
  // ======= 2017.10 ========= //
  'Segment Visitor': 'Ziyaretçiyi bir segmente ekle',
  'Unsegment Visitor': 'Ziyaretçiyi bir segmentten çıkar',
  'Toggle Visitor Segment': 'Toggled Visitor Segment',
  Segmentation: 'Segmentleme',
  Keyword: 'Anahtar kelime',
  keyword: 'anahtar kelime',
  keywords: 'anahtar kelimeler',
  'Search Keyword': 'Arama anahtar kelimeleri',
  'Add Search Keyword Recommendation': 'Arama anahtar kelimeleri ekleyin',
  Count: 'Stok sayısı',
  Ratio: 'Oran',
  'Stock Count': 'Stok Sayısı',
  'Stock Ratio': 'Stok Oranı',
  'Greater Than': 'Büyük',
  'Less Than': 'Küçük',
  'pages selected': 'sayfa seçildi',
  'No Results': 'Sonuç Yok',
  'You can see only last 50 results.':
    'Yalnızca son 50 sonuç listelenmektedir.',
  'You can see only last 50 emails.': 'Yalnızca son 50 email listelenmektedir.',
  "Can't create user, user exists with same email.":
    'Aynı email adresi ile bir kullanıcı zaten mevcut. Aynı email adresi ile 2 farklı kullanıcı yaratamazsınız.',
  'categories selected': 'kategori seçildi',
  'category selected': 'kategori seçildi',
  'brands selected': 'marka seçildi',
  'brand selected': 'marka seçildi',
  'View List': 'Listeyi görüntüleyin',
  'View Stats': 'İstatistikleri görüntüleyin',
  Stats: 'İstatistikler',
  'There is no data': 'Sonuç Yok',
  responses: 'yanıtlar',
  response: 'yanıt',

  // ======= 2017.11 ========= //
  'Select Products': 'Ürünleri Seçin',
  'Search Products': 'Ürün Adı/ ID',
  'Add Selected Products': 'Seçilen Urunleri Ekle',
  'Your changes will be lost!': 'Değişiklikleriniz kaybolacak!',
  'Back to the defaults': 'Başlangıç ayarlarına geri dönün.',
  'Other Languages': 'Diğer Diller',
  Sticky: 'Sticky / Yapışkan',
  'Banner Performance': 'Banner performansı',
  'Purchased Products': 'Satın alınan ürünler',

  // ======= Field Descriptions ========= //
  // Popup Banner
  'Enter the title that your visitors will see on the pop-up banner.':
    "Ziyaretçilerinizin göreceği pop-up banner'ın üzerinde gösterilmesini istediğiniz başlığı girin.",
  'Enter the URL of the image that will be used as the background image of the pop-up banner.':
    'Pop-up bannerın arka planında kullanmak istediğiniz görselin yer aldığı bağlantı adresini (URL) girin.',
  'Enter the URL of the page that your visitors will be directed when they click on the pop-up banner.':
    "Müşteriniz pop-up banner'a tıkladığında yönlendirilmesini istediğiniz sayfanın bağlantı adresini (URL'i) girin.",
  position_POPUP_BANNER:
    "Pop-up banner'ın sayfadaki pozisyonunu belirleyebilirsiniz.",
  verticalPosition_POPUP_BANNER:
    "Pop-up banner'ın sayfanın üst, orta ya da alt kısmında yer almasını belirleyebilirsiniz.",
  horizontalPosition_POPUP_BANNER:
    "Pop-up banner'ın sayfanın sol, orta ya da sağ kısmında yer almasını belirleyebilirsiniz.",
  timingTitle_POPUP_BANNER:
    'Pop-up bannerın gösterilmesini istediğiniz zamanı ve sıklığı belirleyebilirsiniz.',
  timing_POPUP_BANNER:
    'Pop-up bannerı ziyaretçinize sayfaya gelir gelmez, geldikten birkaç saniye sonra ya da sayfadan çıkmak isterken gösterebilirsiniz.',
  frequency_POPUP_BANNER:
    "Pop-up banner'ın bir ziyaretçiye gösterim sıklığını belirleyebilirsiniz. Örneğin, bir oturumda bir kez gibi.",
  audience_POPUP_BANNER:
    "Pop-up banner'ın gösterilmesini istediğiniz hedef kitleyi belirleyebilirsiniz. Örneğin, yeni gelen ziyaretçi ya da geri dönen ziyaretçi gibi.",

  // Popup Recommendation
  'Enter the title that your visitors will see on the recommendation pop-up banner.':
    "Ziyaretçilerilerinizin göreceği öneri pop-up'ının üzerinde gösterilmesini istediğiniz başlığı girin.",
  position_POPUP_RECOMMENDATION:
    "Öneri pop-up'ının sayfadaki pozisyonunu belirleyebilirsiniz.",
  verticalPosition_POPUP_RECOMMENDATION:
    "Öneri pop-up'ının sayfanın üst, orta ya da alt kısmında yer almasını belirleyebilirsiniz.",
  horizontalPosition_POPUP_RECOMMENDATION:
    "Öneri pop-up'ının sayfanın sol, orta ya da sağ kısmında yer almasını belirleyebilirsiniz.",
  timingTitle_POPUP_RECOMMENDATION:
    "Öneri pop-up'ının gösterilmesini istediğiniz zamanı ve sıklığı belirleyebilirsiniz.",
  timing_POPUP_RECOMMENDATION:
    "Öneri pop-up'ının bir ziyaretçiye ne zaman gösterileceğini belirleyebilirsiniz. Örneğin, ziyaretçi sayfadan çıkarken.",
  frequency_POPUP_RECOMMENDATION:
    "Öneri pop-up'ının bir ziyaretçiye gösterim sıklığını belirleyebilirsiniz. Örneğin, bir oturumda bir kez gibi.",
  audience_POPUP_RECOMMENDATION:
    "Öneri pop-up'ının gösterilmesini istediğiniz hedef kitleyi belirleyebilirsiniz. Örneğin, yeni gelen ziyaretçi ya da geri dönen ziyaretçi gibi.",

  // Journey Builder
  position_JOURNEY_BUILDER:
    "Journey builder'ın sayfadaki pozisyonunu belirleyebilirsiniz.",
  verticalPosition_JOURNEY_BUILDER:
    "Journey builder'ın sayfanın üst, orta ya da alt kısmında yer almasını belirleyebilirsiniz.",
  horizontalPosition_JOURNEY_BUILDER:
    "Journey builder'ın sayfanın sol, orta ya da sağ kısmında yer almasını belirleyebilirsiniz.",
  timingTitle_JOURNEY_BUILDER:
    "Journey builder'ın gösterilmesini istediğiniz zamanı ve sıklığı belirleyebilirsiniz.",
  timing_JOURNEY_BUILDER:
    "Journey builder'ın bir ziyaretçiye ne zaman gösterileceğini belirleyebilirsiniz. Örneğin, ziyaretçi sayfadan çıkarken.",
  frequency_JOURNEY_BUILDER:
    "Journey builder'ın bir ziyaretçiye gösterim sıklığını belirleyebilirsiniz. Örneğin, bir oturumda bir kez gibi.",
  audience_JOURNEY_BUILDER:
    "Journey builder'ın gösterilmesini istediğiniz hedef kitleyi belirleyebilirsiniz. Örneğin, yeni gelen ziyaretçi ya da geri dönen ziyaretçi gibi.",

  // See All
  position_SEE_ALL:
    'See All Kampanyasının sayfadaki pozisyonunu belirleyebilirsiniz.',
  verticalPosition_SEE_ALL:
    'See All Kampanyasının sayfanın üst, orta ya da alt kısmında yer almasını belirleyebilirsiniz.',
  horizontalPosition_SEE_ALL:
    'See All Kampanyasının sayfanın üst, orta ya da alt kısmında yer almasını belirleyebilirsiniz.',
  timingTitle_SEE_ALL:
    'See All Kampanyasının gösterilmesini istediğiniz zamanı ve sıklığı belirleyebilirsiniz.',
  timing_SEE_ALL:
    'See All Kampanyasının bir ziyaretçiye ne zaman gösterileceğini belirleyebilirsiniz. Örneğin, ziyaretçi sayfadan çıkarken.',
  frequency_SEE_ALL:
    'See All Kampanyasının bir ziyaretçiye gösterim sıklığını belirleyebilirsiniz. Örneğin, bir oturumda bir kez gibi.',
  audience_SEE_ALL:
    'See All Kampanyasının gösterilmesini istediğiniz hedef kitleyi belirleyebilirsiniz. Örneğin, yeni gelen ziyaretçi ya da geri dönen ziyaretçi gibi.',

  // Email Collection
  'Enter the title of the pop-up that will be displayed for email collection.':
    "Ziyaretçilerinize eposta adresi toplama amacıyla yayınlamak istediğiniz pop-up'ın üzerinde gösterilmesini istediğiniz başlığı girin.",
  'Enter the description of the pop-up that will be displayed for email collection.':
    "Ziyaretçilerinize eposta adresi toplama amacıyla yayınlamak istediğiniz pop-up'ın üzerinde gösterilmesini istediğiniz açıklamayı girin.",
  'Enter the name of the information that you would like your visitors to fill in the form as a placeholder.':
    "Bu alana, müşterilerinizin doldurmasını istediğiniz form boş halde iken, yönlendirmek için girilmesini istediğiniz bilginin ismini girebilirsiniz. Örneğin, 'isim soyad' gibi.",
  "Enter the 'Call to Action' text. For example, 'Send'":
    "Form'da 'Eylem Çağrısı' butonunun üzerinde yer almasını istediğiniz metni girin. Örneğin, 'Gönder' ",
  "Enter the text to direct visitors to visit the page where your legal statement is published. For example, 'Terms&Conditions'":
    "Site ziyaretçileriniz için geçerli olan Hukuki Şart ve Koşullar'ın yer aldığı metne yönlendirme yapmak için kullanacağınız metni girin. Örneğin: 'Hukuki Şart ve Koşullar'",
  'Enter the URL address of the page where your legal statement is published to direct your visitors.':
    "Site ziyaretçileriniz için geçerli olan Hukuki Şart ve Koşullar'ın yer aldığı metne yönlendirme yapacağınız bağlantı adresini ( URL ) girin.",
  styles_NEWSLETTER:
    'Tercih ettiğiniz yazı ve arkaplan renklerini seçebilirsiniz.',
  'styles_Text Color': 'Tercih ettiğiniz yazı rengini seçebilirsiniz.',
  'styles_Background Color':
    'Tercih ettiğiniz arka plan rengini seçebilirsiniz.',
  position_NEWSLETTER:
    "Eposta adresi toplamak amacıyla kullanacağınız pop-up'ın sayfadaki pozisyonunu belirleyebilirsiniz.",
  verticalPosition_NEWSLETTER:
    "Eposta adresi toplamak amacıyla kullanacağınız pop-up'ın sayfanın üst, orta ya da alt kısmında yer almasını belirleyebilirsiniz.",
  horizontalPosition_NEWSLETTER:
    "Eposta adresi toplamak amacıyla kullanacağınız pop-up'ın sayfanın sol, orta ya da sağ kısmında yer almasını belirleyebilirsiniz.",
  timingTitle_NEWSLETTER:
    "Eposta adresi toplamaya başlamak için kullanacağınız pop-up'ın gösterilmesini istediğiniz zamanı ve sıklığı belirleyebilirsiniz.",
  timing_NEWSLETTER:
    "Eposta toplamak amacıyla kullanacağınız pop-up'ın bir ziyaretçiye ne zaman gösterileceğini belirleyebilirsiniz. Örneğin, kullanıcı sayfadan çıkarken.",
  frequency_NEWSLETTER:
    "Eposta toplamak için kullanacağınız pop-up'ın bir ziyaretçiye gösterim sıklığını belirleyebilirsiniz. Örneğin, bir oturumda bir kez gibi.",
  audience_NEWSLETTER:
    "Eposta toplamak için kullanacağınız pop-up'ın gösterilmesini istediğiniz hedef kitleyi belirleyebilirsiniz. Örneğin, yeni gelen ziyaretçi ya da geri dönen ziyaretçi gibi.",

  // Form Survey
  'Enter the title that your visitors will see on the form survey pop-up banner.':
    "Ziyaretçilerinizin göreceği form-anket'in üzerinde gösterilmesini istediğiniz başlığı girin.",
  'Enter the description of the form survey pop-up banner.':
    "Form-anket'in üzerinde gösterilmesini istediğiniz açıklamayı girin.",
  position_FORM: "Form-anket'in sayfadaki pozisyonunu belirleyebilirsiniz.",
  verticalPosition_FORM:
    "Form-anket'in sayfanın üst, orta ya da alt kısmında yer almasını belirleyebilirsiniz.",
  horizontalPosition_FORM:
    "Form-anket'in sayfanın sol, orta ya da sağ kısmında yer almasını belirleyebilirsiniz.",
  timingTitle_FORM:
    "Form-anket'in gösterilmesini istediğiniz zamanı ve sıklığı belirleyebilirsiniz.",
  timing_FORM:
    "Form-anket'in ziyaretçinize sayfaya gelir gelmez, geldikten birkaç saniye sonra ya da sayfadan çıkmak isterken gösterebilirsiniz.",
  frequency_FORM:
    "Form-anket'in bir ziyaretçiye gösterim sıklığını belirleyebilirsiniz. Örneğin, bir oturumda bir kez gibi.",
  audience_FORM:
    "Form-anket'in gösterilmesini istediğiniz hedef kitleyi belirleyebilirsiniz. Örneğin, yeni gelen ziyaretçi ya da geri dönen ziyaretçi gibi.",

  // Hero Banner
  timingTitle_HERO_BANNER: 'You can set the time and frequency of the banner.',
  timing_HERO_BANNER:
    'You can display the banner right after a visit to your website, a few seconds after a visit or when an exit-intent happens.',
  frequency_HERO_BANNER:
    'You can set the frequency of the banner to display. For example, one time in one session.',
  audience_HERO_BANNER:
    'You can set the audience of the banner to display. For example, to the new visitors or returning visitors.',

  // Push Permission
  'Enter the title that your visitors will see on the push permission.':
    'Ziyaretçilerinizin göreceği push izninin üzerinde gösterilmesini istediğiniz başlığı girin.',
  'Enter the description of the push permission.':
    'Push izninin üzerinde gösterilmesini istediğiniz açıklamayı girin.',
  'Enter the URL address of the logo that will be displayed on the push permission.':
    'Push izninin üzerinde gösterilecek olan logonun yer aldığı bağlantı adresini (URL) girin.',
  position_PUSH_PERMISSION:
    "Push izni'nin sayfadaki pozisyonunu belirleyebilirsiniz.",
  verticalPosition_PUSH_PERMISSION:
    "Push izni'nin sayfanın üst, orta ya da alt kısmında yer almasını belirleyebilirsiniz.",
  horizontalPosition_PUSH_PERMISSION:
    "Push izni'nin sayfanın sol, orta ya da sağ kısmında yer almasını belirleyebilirsiniz.",
  timingTitle_PUSH_PERMISSION:
    "Push izni'nin gösterilmesini istediğiniz zamanı ve sıklığı belirleyebilirsiniz.",
  timing_PUSH_PERMISSION:
    "Push izni'ni ziyaretçinize sayfaya gelir gelmez, geldikten birkaç saniye sonra ya da sayfadan çıkmak isterken gösterebilirsiniz.",
  frequency_PUSH_PERMISSION:
    "Push izni'nin bir ziyaretçiye gösterim sıklığını belirleyebilirsiniz. Örneğin, bir oturumda bir kez gibi.",
  "Enter the call to action text to give permission. For example, 'Allow'":
    "Push izni vermek için 'Eylem Çağrısı' butonunun üzerinde yer almasını istediğiniz metni girin. Örneğin, 'İzin Veriyorum'",
  "Enter the call to action text to ask for permission later again. For example, 'Remind me later'":
    "Push iznini daha sonra vermek için 'Eylem Çağrısı' butonunun üzerinde yer almasını istediğiniz metni girin. Örneğin, 'Daha sonra hatırlat'",

  // Push Notifications
  'Enter the title that your visitors will see on the web push notification.':
    'Ziyaretçilerinize göndereceğiniz push iletisinin üzerinde gösterilmesini istediğiniz başlığı girin.',
  'Enter the description of the web push notificaiton.':
    'Ziyaretçilerinize göndereceğiniz push iletisinin üzerinde gösterilmesini istediğiniz açıklamayı girin.',
  'Enter the URL address of the icon that will be displayed on the web push notification.':
    'Push iletisinde görüntülenecek ikonun yer aldığı bağlantı adresini (URL) girin.',
  'Enter the URL address of the image that will be displayed on the web push notification.':
    'Push iletisinde görüntülenecek görselin yer aldığı bağlantı adresini (URL) girin. ',
  'Enter the URL of the page that your visitors will be directed when they click on the web push notification.':
    "Müşteriniz push iletisine tıkladığında yönlendirilmesini istediğiniz sayfanın bağlantı adresini (URL'i) girin.",
  scheduling_PUSH_NOTIFICATION:
    'Push iletisinin gönderim zamanını planlayabilirsiniz.',
  audience_PUSH_NOTIFICATION:
    'Push iletisinin gösterilmesini istediğiniz hedef kitleyi belirleyebilirsiniz. Örneğin, yeni gelen ziyaretçi ya da geri dönen ziyaretçi gibi.',

  // Notification Bar
  'Enter the title that your visitors will see on the notification bar.':
    'Ziyaretçilerinizin göreceği bildirim barı üzerinde gösterilmesini istediğiniz başlığı girin.',
  'Enter the URL address of the image that will be displayed on the notification bar.':
    'Bildirim barında görüntülenecek görselin yer aldığı bağlantı adresini (URL) girin.',
  'Enter the URL of the page that your visitors will be directed when they click on the notification bar.':
    "Bildirim barına tıkladığında yönlendirilmesini istediğiniz sayfanın bağlantı adresini (URL'i) girin.",
  styles_NOTIFICATION_BAR:
    'Tercih ettiğiniz yazı ve arkaplan renklerini belirleyebilirsiniz.',
  position_NOTIFICATION_BAR:
    'Bildirim barının sayfadaki pozisyonunu belirleyebilirsiniz.',
  verticalPosition_NOTIFICATION_BAR:
    'Bildirim barının sayfanın üst, orta ya da alt kısmında yer almasını belirleyebilirsiniz.',
  horizontalPosition_NOTIFICATION_BAR:
    'Bildirim barının sayfanın sol, orta ya da sağ kısmında yer almasını belirleyebilirsiniz.',
  timingTitle_NOTIFICATION_BAR:
    'Bildirim barının gösterilmesini istediğiniz zamanı ve sıklığı belirleyebilirsiniz.',
  timing_NOTIFICATION_BAR:
    'Bildirim barının bir ziyaretçiye ne zaman gösterileceğini belirleyebilirsiniz. Örneğin, kullanıcı sayfadan çıkarken.',
  frequency_NOTIFICATION_BAR:
    'Bildirim barının bir ziyaretçiye gösterim sıklığını belirleyebilirsiniz. Örneğin, bir oturumda bir kez gibi.',
  audience_NOTIFICATION_BAR:
    'Bildirim barının gösterilmesini istediğiniz hedef kitleyi belirleyebilirsiniz. Örneğin, yeni gelen ziyaretçi ya da geri dönen ziyaretçi gibi.',

  "Can't update user information, new password is same with current password":
    "Can't update user information, new password is same with current password",

  'Keep on page change': 'Keep on page change',
  'Category View': 'Görüntülenme Sayısı',
  'Category Name': 'Kategori Adı',
  impressions: 'etkileşim',
  'Completed Campaigns': 'Biten Kampanyalar',
  'Conversion Rate': 'Dönüşüm Oranı',
  Pages: 'Sayfalar',
  'Views Referred From Adwords': 'Views Referred From Adwords',
  'Views Referred From Retargeting': 'Views Referred From Retargeting',
  'Views Referred From Price Comparison Sites':
    'Views Referred From Price Comparison Sites',
  'Views Referred From Affiliates': 'Views Referred From Affiliates',
  'Views Referred From Referrals': 'Views Referred From Referrals',

  'Will be sent at': 'Will be sent at',
  'Last 7 Days': 'Son 7 Gün',
  'The campaign was successfully archived.':
    'The campaign was successfully archived.',
  'Shuffle Promotions': 'Shuffle Promotions',

  'You must change the end date in order to save campaign.':
    'You must change the end date in order to save campaign.',
  Feature: 'Feature',

  'Impression / View / Click': 'Impression / View / Click',
  Items: 'Items',
  'The percentage of products that have been purchased after being clicked in a Segmentify Recommendation widget.':
    'Segmentify öneri bileşenlerine tıklandıktan sonra satın alınan ürünlerin yüzdesi.',

  'There are no records to show.': 'There are no records to show.',

  'You should make at least one selection from Segmentify Intelligent or Static algorithms':
    'You should make at least one selection from Segmentify Intelligent or Static algorithms',
  'New Comers': 'New Comers',
  'All Rocks': 'All Rocks',
  "All Recommends from all products in your website's catalog with an intelligent sorting ":
    "All Recommends from all products in your website's catalog with an intelligent sorting ",
  // Widget/Promotion/Search Keywrods Desc&Info
  Widget_Desc:
    "Create personal recommendation campaigns with Segmentify's intelligent and static algorithms.",
  Promotion_Desc:
    "Personalize the Special Offers you display in your website with Segmentify's Promotion campaigns.",
  'Search-Keyword_Desc':
    "Find out the top searched keywords in your website and recommend them so your customer's can find what they want more easily.",

  'NoActivePopup RecommendationCampaigns': () => {
    return (
      <div>
        To continue making personalized recommendations on your website, you can
        use popup recommendations on any page type. Click ‘Add Custom Widget’
        below to create a new popup recommendation widget.
      </div>
    );
  },
  'NoActiveJourney BuilderCampaigns': () => {
    return (
      <div>
        <p>
          When you need a journey which created uniquely for each of your
          customers, you can define journey builder popups.
        </p>
        <p>
          Please select journey builder below to{' '}
          <strong>add new journey builder popup.</strong>
        </p>
      </div>
    );
  },
  Before_Search_Input:
    "Attract your customers with Segmentify's intelligent algorithms even before they make a search.",

  // FACETED SEARCH
  General: 'Genel',
  Language: 'Dil',
  FACETED_SEARCH_PRODUCT_DESC:
    'Gelişmiş filtreler kullanarak kişiselleştirilmiş arama deneyimi oluşturun.',
  'faceted-report-subtitle': 'Searchandising performansını görüntüleyin.',
  'faceted-all-report-subtitle':
    'Tüm Searchandising performansını görüntüleyin',

  // Rule Set
  'rule-set-query-condition-title': 'Sorgu koşullarını belirle',
  'rule-set-query-condition-tooltip':
    'Arama sonuç sayfanızı koşullara göre özelleştirmek çok kolay. Bir koşul seçerek başlayın.\n' +
    'Kullanılabilir operatörler: Birebir eşleşme, İçerme, Başlar',
  'rule-set-query-condition-description':
    'Kuralı belirlemek için sorgunuzu seçin',
  'rule-set-result-customization-title': 'Arama sonuçları özelleştirmesi',
  'rule-set-result-customization-description':
    'Arama sonuçlarını özelleştirmek için ürünleri değiştir ya da sabitle',
  'rule-set-result-customization-tooltip':
    'Arama anahtar kelimenizle ürünlerinizi sabitlemek ve tanıtımını yapmak için görsel düzenleyici.\n' +
    'Ürünü sabitleye tıkla ve başla',
  'rule-set-hides-title': 'Gizlenmiş ürünler',
  'rule-set-hides-description': 'Arama sonucu gösterilmesi istenmeyen ürünler.',
  'rule-set-boost-fields': 'Boost Fields',
  'rule-set-boost-fields-description':
    'Boost specific fields to make products appear first.',
  'rule-set-contents-title': 'İçerikler',
  'rule-set-contents-description':
    'Sayfanızı özelleştirmek için özel içerikler ekleyin',
  'rule-set-contents-description-searchbox':
    'Arama kutunuzu özelleştirmek için özel içerikler ekleyin',
  'rule-set-contents-tooltip':
    'Bannerlar, butonlar ekleyerek arama sonuçları sayfanızı özelleştirin',
  'rule-set-contents-tooltip-searchbox':
    'Bannerlar, butonlar ekleyerek arama kutunuzu özelleştirin',
  Discard: 'İptal et',
  Pinned: 'Sabit',
  Pin: 'Sabitle',
  Replace: 'Değiştir',
  'pin-product': 'Ürünü Sabitle',
  'add-content': 'İçerik ekle',
  'rule-name': 'Kural Adı',
  'rule-set': 'Kural Kümesi',
  'rule-set-mandatory-error': field => <span>{field} zorunlu alan</span>,
  'rule-set-campaign-mandatory':
    'Ürün sabitleme yeteneğini kullanabilmek için lütfen bir kampanya seçin.',
  'rule-set-custom-content-key-error': 'Özelleşmiş içerik anahtarı boş.',
  'rule-set-boost-field-error': 'Boosting Özellikleri boş.',
  'rule-set-position-collapse':
    'Sabitlenmiş ürün pozisyonları birbiri üzerinde olamaz.',
  'rule-set-delete-confirmation-title': 'Kuralı sil?',
  'rule-set-live-confirmation-title': 'Kuralı kaydet?',
  'rule-set-delete-confirmation-content':
    'Bu aksiyon derhal kural setine etki edecektir.',
  'select-campaign': 'Kampanya seç',
  'rule-set-save-successful': 'Kural seti başarıyla kaydedildi.',
  'rule-set-save-failed': 'Kural seti kaydedilirken hata oluştu.',
  'rule-set-retrieve-failed': 'Kural seti bilgileri getirilirken hata oluştu.',
  'No Product': 'Ürün Bulunamadı',
  RESULTS_FOOTER: 'Results Footer',
  RESULTS_HEADER: 'Results Header',
  FILTERS_FOOTER: 'Filters Footer',
  FILTERS_HEADER: 'Filters Header',
  ASSETS_FOOTER: 'Assets Footer',
  ASSETS_HEADER: 'Assets Header',
  // Start - User Management
  'search-and-hit-enter': 'Ara',
  // End - User Management
};

export default tr;
