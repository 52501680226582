import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { productUrl } from '../../../modules/trendify/page';
import { imgToBase64 } from '../../../modules/trendify/utils';
import { localeString } from '../../../system/string';
import { t } from '../../../system/ui';
import Currency from '../../currency';
import Icon from '../../icon';
import Tooltip from '../../tooltip';

const Product = ({
  customFields,
  inStock,
  params,
  productCategory,
  productId,
  productImage,
  productName,
  productOldPrice,
  productPrice,
  productURL,
  stats,
  insightsId,
}) => {
  const [baitImageSource, setBaitImageSource] = React.useState(
    productUrl(productImage),
  );
  const reverseIds = [4, 6, 7, 8];
  const productStats = reverseIds.includes(insightsId)
    ? stats.filter(stat => stat.summary).reverse()
    : stats.filter(stat => stat.summary);

  React.useEffect(() => {
    async function getBaitImage() {
      const imgUrl = await imgToBase64(productUrl(productImage));
      setBaitImageSource(imgUrl);
    }

    getBaitImage();
  }, []);

  return (
    <div className='product-insight'>
      <div className='product-details'>
        <span className='product-image-wrapper'>
          <a href={productUrl(productURL)} target='_blank' rel='noreferrer'>
            <img
              src={baitImageSource}
              className='product-image'
              alt={`Product ${productName}`}
            />
          </a>
        </span>
        <div className='product-info'>
          <h3 className='product-name'>
            <a href={productUrl(productURL)} target='_blank' rel='noreferrer'>
              {productName}
            </a>
          </h3>
          {productCategory && productCategory.length > 20 ? (
            <Tooltip
              content={productCategory}
              alignment='top'
              newTooltip
              seeMore
            >
              <div className='product-category'>
                {productCategory.substr(0, 20)}...
              </div>
            </Tooltip>
          ) : (
            <div className='product-category'>{productCategory}</div>
          )}
          <p className='product-id'>{productId}</p>
          <p className='product-price'>
            {productOldPrice && (
              <span className='product-old-price'>
                <Currency currency={productOldPrice} />
              </span>
            )}

            <span className='product-sale-price'>
              <Currency currency={productPrice} />
            </span>
          </p>
          {customFields &&
            customFields.map(field => {
              const renderedField = params ? params[field.field] : false;
              const prefix = `${t(field.label)}: `;
              if (renderedField) {
                const value =
                  field.dataType === 'currency' ? (
                    <Currency currency={params[field.field]} />
                  ) : (
                    params[field.field]
                  );
                return (
                  <p className='product-price-custom'>
                    {prefix}
                    {value}
                  </p>
                );
              }
              return <p className='product-price-custom'>{`${prefix}-`}</p>;
            })}
          <p
            className={classNames('product-stock', {
              'product-in-stock': inStock,
            })}
          >
            {inStock ? (
              <Icon name='check-circle' />
            ) : (
              <span className='out-of-stock' />
            )}
            {inStock ? t('In Stock') : t('Out of Stock')}
          </p>
        </div>
      </div>
      <div className='product-stats'>
        {productStats.map(stat => (
          <div className='product-stat' key={`${stat.name}-${productId}`}>
            <p className='product-stat-name'>{t(stat.name)}</p>
            <p className='product-stat-value'>
              {stat.type === 'currency' ? (
                <Currency currency={stat.value} />
              ) : (
                localeString(stat.value)
              )}
              {stat.type === 'ratio' && '%'}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

Product.propTypes = {
  customFields: PropTypes.bool.isRequired,
  inStock: PropTypes.bool,
  params: PropTypes.shape({}),
  productCategory: PropTypes.string,
  productId: PropTypes.string,
  productImage: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productOldPrice: PropTypes.number,
  productPrice: PropTypes.number.isRequired,
  productURL: PropTypes.string.isRequired,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Product;
