/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import Select from "react-select";

import { insertTypes } from "../../constants/datamaps/wizard";

import { t } from "../../system/ui";

import { uiActions } from "../../actions";

import Icon from "../icon";
import Tooltip from "../tooltip";
import { TextField } from "../fields";

class WizardTarget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selector: "",
      insertType: "SELF"
    };

    if (this.props.duplicate) {
      this.state = {
        selector: this.props.duplicate.isDuplicate
          ? this.props.duplicate.duplicatedValues[2]
          : "",
        insertType: this.props.duplicate.isDuplicate
          ? this.props.duplicate.duplicatedValues[3]
          : "SELF"
      };
    }

    this.selectorChange = this.selectorChange.bind(this);
    this.insertTypeChange = this.insertTypeChange.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      if (this.props.isPalantir) {
        this.setState({
          selector: newProps.campaign.targetSelector,
          insertType: newProps.campaign.targetSelectorPosition
        });
      }else if(this.props.isHeroBanner){
        this.setState({
          selector: newProps.campaign.targetSelector,
          insertType: newProps.campaign.targetPosition
        });
      } else {
        this.setState({
          selector: newProps.campaign.actions[0].params.selector,
          insertType: newProps.campaign.actions[0].params.insertType
        });
      }
    }
  }

  insertTypeChange(selectedOptions) {
    this.setState({
      insertType: selectedOptions.value
    });
    uiActions.formEdited();
  }

  selectorChange(e) {
    this.setState({
      selector: e.currentTarget.value
    });
    uiActions.formEdited();
  }

  render() {
    return (
      <div className="wizard-target">
        <span className="item item-stacked label-emphasise one-whole">
          <span className="item-label">
            {t("Target Div Selector")}
            <Tooltip
              content={t(
                "The target component on your website where recommendation box going to insert itself"
              )}
              alignment="right"
            >
              <Icon name="help" />
            </Tooltip>
          </span>
        </span>

        <span
          className="item two-thirds"
          style={{ display: "inline-block", verticalAlign: "top" }}
        >
          <TextField
            name="selector"
            value={this.state.selector}
            placeholder={"#" + t("unique-selector")}
            required={true}
            style={{ width: "90%" }}
            onChange={this.selectorChange}
          />
        </span>

        <label className="item is-select one-third">
          <Select
            searchable={false}
            clearable={false}
            name="insertType"
            options={insertTypes}
            value={this.state.insertType}
            onChange={this.insertTypeChange}
          />
        </label>
      </div>
    );
  }
}

export default WizardTarget;
