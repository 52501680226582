import { createReducer } from "../system/store";
import * as pushActions from "../constants/actions/push";

const initialState = {
  popupOption: false
};

export default createReducer(initialState, {
  [pushActions.default.SET_POPUP_OPTION]: (state, payload) => {
    return Object.assign({}, state, {
      popupOption: payload
    });
  }
});
