import React, { Component } from 'react';
import { reformatDate } from '../../system/date';
import { getSegment } from './ajax';

class SegmentationHistoryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      segmentHistory: [],
      modalTitle: '',
    };

    this.listSegmentationHistory = this.listSegmentationHistory.bind(this);
  }

  componentDidMount() {
    this.listSegmentationHistory();
  }

  listSegmentationHistory() {
    const opts = {};
    opts.withOps = true;
    getSegment(
      this.props.id,
      response => {
        this.setState(
          {
            segmentHistory: response.operations,
            modalTitle: document.querySelector('#modal-title').innerHTML,
          },
          function() {
            window.dispatchEvent(new Event('resize'));
          },
        );
      },
      opts,
    );
  }

  render() {
    return (
      <div>
        <div style={{ minWidth: '760px' }}>
          <table className='data-table'>
            <thead>
              <tr>
                <th>Modified By</th>
                <th>Action</th>
                <th>Date</th>
                <th>-</th>
              </tr>
            </thead>
            <tbody>
              {this.state.segmentHistory.map(historyItem => {
                let actionColor = '#9f9798';
                if (historyItem.operation.type === 'WENT_LIVE') {
                  actionColor = '#59c471';
                } else if (historyItem.operation.type === 'BACK_TO_TEST') {
                  actionColor = '#cd3d3e';
                } else {
                  actionColor = '#7da6d1';
                }
                return (
                  <tr key={Math.random()}>
                    <td>{historyItem.operation.username}</td>
                    <td style={{ color: actionColor, fontWeight: 'bold' }}>
                      {historyItem.operation.type}
                    </td>
                    <td>{reformatDate(historyItem.operation.time)}</td>
                    <td />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default SegmentationHistoryModal;
