import React from 'react';
import Icons from 'components/icons';
import Icon from 'components/icon';
import { Input, Select } from 'components/search/faceted-search/common';
import { updateCampaignForm } from 'actions/faceted-search';
import { useDispatch, useSelector } from 'react-redux';

export default () => {
  const dispatch = useDispatch();
  const state = useSelector(s => s.facetedSearch);
  const { campaignForm } = state;
  const onInputChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    dispatch(
      updateCampaignForm({
        [name]: value,
      }),
    );
  };
  return (
    <div className="wizard-criterion faceted-search-result">
      <span className="draghandler">
        <Icons name="burger" color="#e8e7f2" />
      </span>
      <Icon name="tag" />
      <Select name="search-result">
        <option value="SMART_SEARCH_RESULTS">Smart Search Results</option>
      </Select>
      <Input
        inputProps={{
          name: 'mobilePageSize',
          type: 'number',
          min: 0,
          max: 50,
          value: campaignForm.mobilePageSize,
        }}
        label="mobile"
        onChange={onInputChange}
      />
      <Input
        inputProps={{
          name: 'desktopPageSize',
          type: 'number',
          min: 0,
          max: 50,
          value: campaignForm.desktopPageSize,
        }}
        label="desktop"
        onChange={onInputChange}
      />
    </div>
  );
};
