import React from "react";

class IconSafari extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        viewBox="0 0 23.7 23.7"
        fill={this.state.color}
      >
        <path
          d="M10.9,11.9c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8C11.3,12.6,10.9,12.3,10.9,11.9z M23.7,11.8
	c0,6.5-5.3,11.8-11.8,11.8S0,18.4,0,11.8S5.3,0,11.8,0S23.7,5.3,23.7,11.8z M22.4,11.8c0-5.8-4.7-10.6-10.6-10.6S1.3,6,1.3,11.8
	S6,22.4,11.8,22.4S22.4,17.7,22.4,11.8z M18.9,16.5c0,0.2,0.6,0.5,0.8,0.6c-1.3,2-3.3,3.4-5.6,4l-0.2-0.9c0-0.1-0.1-0.1-0.2-0.1
	c-0.1,0-0.1,0.1-0.1,0.2l0.2,0.9c-0.6,0.1-1.3,0.2-1.9,0.2c-1.7,0-3.4-0.5-4.9-1.4c0.1-0.1,0.6-0.9,0.6-1c0-0.1-0.1-0.2-0.2-0.2
	c-0.2,0-0.6,0.8-0.7,0.9c-2-1.3-3.4-3.4-4-5.7l0.9-0.2c0.1,0,0.1-0.1,0.1-0.2c0-0.1-0.1-0.1-0.2-0.1l-0.9,0.2
	c-0.1-0.6-0.2-1.2-0.2-1.8c0-1.8,0.5-3.5,1.4-5C4,6.9,4.6,7.3,4.7,7.3c0.1,0,0.2-0.1,0.2-0.2C4.9,7,4.2,6.6,4,6.5
	c1.3-2,3.4-3.4,5.7-3.9l0.2,0.9c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1-0.1,0.1-0.2l-0.2-0.9c0.6-0.1,1.2-0.2,1.8-0.2c1.8,0,3.5,0.5,5,1.4
	c-0.1,0.1-0.5,0.8-0.5,0.9c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.5-0.7,0.6-0.8c2,1.3,3.4,3.3,3.9,5.6l-0.7,0.2c-0.1,0-0.1,0.1-0.1,0.2
	c0,0.1,0.1,0.1,0.2,0.1l0.8-0.2c0.1,0.6,0.2,1.2,0.2,1.8c0,1.7-0.5,3.4-1.4,4.9c-0.1-0.1-0.7-0.5-0.8-0.5
	C19,16.3,18.9,16.4,18.9,16.5z M17.4,4.9c-0.6,0.6-6.4,5.9-6.6,6.2l-4.6,7.7c0.6-0.6,6.4-5.9,6.6-6.2L17.4,4.9z"
        />{" "}
      </svg>
    );
  }
}

export default IconSafari;
