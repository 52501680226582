import React from 'react';
import ExecutiveSearchReport from './searchreport';
import Filters from '../components/filters/filters';

function SingleSearchReport(props) {
  return (
    <div>
      <Filters />
      <ExecutiveSearchReport {...props} key='executive-summary' />
    </div>
  );
}

export default SingleSearchReport;
