import React from 'react';

const useRecursiveElement = (elementArr, children) => {
  const Provider = elementArr.shift();
  const isLastProvider = elementArr.length === 0;
  return (
    <Provider>
      {isLastProvider ? children : useRecursiveElement(elementArr, children)}
    </Provider>
  );
};

export default useRecursiveElement;
