import React from "react";
import connect from "react-redux/es/connect/connect";
import SearchInsightsPopularSearches from "./search-insights-popular-searches"
import SearchInsightsNoResultSearches from "./search-insights-no-result-searches";
import SearchInsightsTotalSearches from "./search-insights-total-searches";
import {detailTablesComponentMap} from "./search-insights-assets";

class SearchInsightsGeneral extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };

  }

  render() {
    if (this.props.tableName && this.props.tableName !== '') {
      let DetailTableComponent = detailTablesComponentMap[this.props.tableName];
      return (
        <div className={"search__m_t_10"}>
          <DetailTableComponent isDetail={true}/>
        </div>)

    } else {
      return (
        <div className={"search__m_t_10"}>
          <SearchInsightsTotalSearches/>
          <SearchInsightsPopularSearches/>
          <SearchInsightsNoResultSearches/>
        </div>
      );
    }
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  tabName: store.searchInsights.tabName,
  tableName: store.searchInsights.tableName,
  selectedLanguage: store.switchLanguage.selectedLanguage,
});

export default connect(mapStatesToProps)(SearchInsightsGeneral);
