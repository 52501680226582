import React from "react";

class IconAllDevices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        version="1.1"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <path d="M0,0h24v24h-24Z" fill="none" />
        <path
          fill={this.state.color}
          d="M4,6h18v-2h-18c-1.1,0 -2,0.9 -2,2v11h-2v3h14v-3h-10v-11Zm19,2h-6c-0.55,0 -1,0.45 -1,1v10c0,0.55 0.45,1 1,1h6c0.55,0 1,-0.45 1,-1v-10c0,-0.55 -0.45,-1 -1,-1Zm-1,9h-4v-7h4v7Z"
        />
      </svg>
    );
  }
}

export default IconAllDevices;
