import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import { arrayMove } from 'react-sortable-hoc';
import Icons from '../../../icons';
import { onProductsInputChange } from './common/common-functions';
import {
  DragHandler,
  DraggableList,
  DraggableItemWrap,
} from '../../../wizard/criterion-product-draggable';

const RPProducts = ({
  mappedProducts,
  setAttributes,
  onRemove,
  isDraggable = false,
  isRemovable = false,
  isSingle = false,
}) => {
  const [productOptions, setProductOptions] = useState([]);

  const onInputChange = useCallback(
    value => onProductsInputChange(value, setProductOptions),
    [setProductOptions],
  );

  const valueRenderer = option => {
    return <DragHandler title={option.title}>{option.label}</DragHandler>;
  };

  // Function for setting array on drag
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newMappedProducts = arrayMove(mappedProducts, oldIndex, newIndex);
    setAttributes(newMappedProducts, 'mappedProducts');
  };

  const renderSelect = () => {
    if (isDraggable) {
      return (
        <DraggableList
          axis='xy'
          shouldCancelStart={() => mappedProducts && mappedProducts.length < 2}
          onSortEnd={props => onSortEnd(props)}
          useDragHandle
          helperClass='draggable-dragging'
        >
          <Select
            options={productOptions}
            onInputChange={onInputChange}
            onChange={selected => {
              setAttributes(selected, 'mappedProducts');
            }}
            searchable
            value={mappedProducts}
            valueRenderer={valueRenderer}
            valueComponent={DraggableItemWrap}
            multi
            placeholder='Search Product'
            className='sfy-attribute-field-select-input'
            clearable={false}
          />
        </DraggableList>
      );
    }
    return (
      <Select
        options={productOptions}
        onInputChange={onInputChange}
        onChange={selected => {
          setAttributes(selected, 'mappedProducts');
        }}
        searchable
        value={mappedProducts}
        multi={!isSingle}
        placeholder='Search Product'
        className='sfy-attribute-field-select-input'
        clearable={false}
      />
    );
  };

  return (
    <div className='sfy-attribute-field sfy-related-products'>
      <div className='sfy-attribute-field-icon'>
        <i className='icon-product noV-align' color='#7A7A7C' />
      </div>
      <div className='sfy-attribute-field-select'>{renderSelect()}</div>
      {isRemovable && (
        <div
          className='sfy-attribute-field-remove'
          onClick={() => onRemove('mappedProducts')}
        >
          <Icons name='crossCircle' color='#D31116' height='18' width='18' />
        </div>
      )}
    </div>
  );
};

export default RPProducts;
