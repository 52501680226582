import { crush } from 'html-crush';

export function minifyTemplateHtml(html) {
  const compress = crush(html, {
    lineLengthLimit: 500,
    removeIndentations: true,
    removeLineBreaks: false,
    removeHTMLComments: false,
    removeCSSComments: true,
    breakToTheLeftOf: [
      '</td',
      '<html',
      '</html',
      '<head',
      '</head',
      '<meta',
      '<link',
      '<table',
      '<script',
      '</script',
      '<!DOCTYPE',
      '<style',
      '</style',
      '<title',
      '<body',
      '@media',
      '</body',
      '<!--[if',
      '<!--<![endif',
      '<![endif]',
    ],
    mindTheInlineTags: [
      'a',
      'abbr',
      'acronym',
      'audio',
      'b',
      'bdi',
      'bdo',
      'big',
      'br',
      'button',
      'canvas',
      'cite',
      'code',
      'data',
      'datalist',
      'del',
      'dfn',
      'em',
      'embed',
      'i',
      'iframe',
      'img',
      'input',
      'ins',
      'kbd',
      'label',
      'map',
      'mark',
      'meter',
      'noscript',
      'object',
      'output',
      'picture',
      'progress',
      'q',
      'ruby',
      's',
      'samp',
      'script',
      'select',
      'slot',
      'small',
      'span',
      'strong',
      'sub',
      'sup',
      'svg',
      'template',
      'textarea',
      'time',
      'u',
      'tt',
      'var',
      'video',
      'wbr',
    ],
  });

  console.group('%cMinified HTML Report', 'color: green; font-size: 16px');
  console.log(
    'Time Taken In Milliseconds:',
    compress.log.timeTakenInMilliseconds,
  );
  console.log('Original Length:', compress.log.originalLength);
  console.log('Cleaned Length:', compress.log.cleanedLength);
  console.log('BytesSaved:', compress.log.bytesSaved);
  console.log(
    'Percentage Reduced of Original Template:',
    compress.log.percentageReducedOfOriginal,
  );
  console.groupEnd();

  return {
    log: compress.log,
    result: compress.result,
  };
}
