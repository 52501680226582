import React from 'react';
import PropTypes from 'prop-types';
import { userTypesArray } from './constants';
import Icons from '../../icons';
import { t } from '../../../system/ui';

const ChooseUserTypes = ({ selectUserType, selectedUserType, adminType }) => {
  const [typesToShow, setTypesToShow] = React.useState([]);

  React.useEffect(() => {
    setTypesToShow(
      adminType === 'master'
        ? ['Master User', 'Super User', ...userTypesArray]
        : userTypesArray,
    );
  }, []);

  return (
    <>
      <h2>{t('User Type')}</h2>
      <ul>
        {typesToShow.map(userType => (
          <li
            className='usertype-li'
            key={userType}
            onClick={() => selectUserType(userType)}
          >
            <span className='icon-create-user'>
              {selectedUserType === userType ? (
                <Icons name='checkboxCircleSelected' />
              ) : (
                <Icons name='checkboxCircleEmpty' />
              )}
            </span>
            {userType}
          </li>
        ))}
      </ul>
      {selectedUserType && <hr className='separator' />}
    </>
  );
};

ChooseUserTypes.propTypes = {
  adminType: PropTypes.string.isRequired,
  selectUserType: PropTypes.func.isRequired,
  selectedUserType: PropTypes.string.isRequired,
};

export default ChooseUserTypes;
