import React from 'react';
import { connect } from 'react-redux';

import { personalizationCampaigns } from 'components/engagement/card';
import {
  types,
  campaignTypesForGamificationReport,
} from '../../constants/datamaps/campaign';

import { uiActions } from '../../actions';
import { devices } from '../../constants/datamaps/campaigns';
import { getNewsletterReport } from '../../modules/reports/ajax';
import {
  isSwitchAccount,
  getToken,
  isMultiAccountUser,
  getAccount,
  getApiKey,
  isSuperUser,
} from '../../modules/auth/user';
import api from '../../system/api';
import { getFormattedDate } from '../../system/date';
import { t } from '../../system/ui';
import Icon from '../icon';
import Icons from '../icons';
import ReportActions from '../reports/actions';
import Tooltip from '../tooltip';
import EmailCollectionReport from './email-collection-report';
import CollectedEmails from './personalization-collected-mails';
import HeroBannerReport from './personalization-hero-report';
import NotificationBarReport from './personalization-notbar-report';
import PopupBannerReport from './personalization-popup-report';
import SegmentationReport from './segmentation-report';
import SeeAllReport from './personalization-seeall-report';
import GamificationReport from './personalization-gamification-report';

class PersonalizationWidgetReport extends React.Component {
  constructor(props) {
    super(props);

    this.listDetails = this.listDetails.bind(this);
    this.exportFile = this.exportFile.bind(this);
    this.getCampaignPages = this.getCampaignPages.bind(this);
  }

  listDetails() {
    uiActions.isLoading();
    const campaignInstanceId = this.props.campaign.instanceId;
    let results = [];
    let modalTitle;
    let maxEmails;
    getNewsletterReport(campaignInstanceId, result => {
      uiActions.isLoaded();
      results = result;
      maxEmails = results.length > 50 ? 50 : results.length;
      modalTitle = `Email List (${maxEmails} of ${results.length})`;
      if (this.props.campaign.type === 'FORM') {
        modalTitle = `Form Results (${maxEmails} of ${results.length})`;
      }
      if (result.length) {
        const content = () => <CollectedEmails collectedEmails={results} />;
        uiActions.openModal({ title: modalTitle, content });
      }
    });
  }

  exportFile() {
    const globalApiKey = `?apiKey=${getApiKey()}`;
    let resultUrl = api.getEndPoint('report/newsletter_export') + globalApiKey;
    resultUrl += `&instanceId=${this.props.campaign.instanceId}`;
    resultUrl += `&authToken=${getToken()}`;
    if (isSwitchAccount() && !isMultiAccountUser()) {
      resultUrl += `&switchUser=${isSwitchAccount().contact.email}`;
    }
    if (isMultiAccountUser() && isSwitchAccount()) {
      resultUrl += `&switchAccount=${getAccount().accountId.trim()}`;
    }
    window.open(resultUrl);
  }

  getCampaignPages(filter) {
    if (!filter.includedCategories) {
      return false;
    }
    let includedPages = '';
    if (filter.includedCategories.indexOf('allpages') !== -1) {
      includedPages += t('All Pages');
    } else {
      filter.includedCategories.forEach((pageName, pageIndex) => {
        if (pageIndex === filter.includedCategories.length - 1) {
          includedPages += t(pageName);
        } else {
          includedPages += `${t(pageName)}, `;
        }
      });
    }
    return (
      <span className='widget-meta-pages'>
        <Tooltip content={includedPages} alignment='left'>
          <Icons name='browser' width='15' height='17' color='#bababa' />
          {t('Pages')}
        </Tooltip>
      </span>
    );
  }

  render() {
    let statsComponent = null;
    if (this.props.campaign.type === 'POPUP_BANNER') {
      statsComponent = <PopupBannerReport stats={this.props.logs} />;
    } else if (this.props.campaign.type === 'SEE_ALL') {
      statsComponent = <SeeAllReport stats={this.props.logs} />;
    } else if (
      campaignTypesForGamificationReport.includes(this.props.campaign.type)
    ) {
      statsComponent = <GamificationReport stats={this.props.logs} />;
    } else if (this.props.campaign.type === 'NOTIFICATION_BAR') {
      statsComponent = <NotificationBarReport stats={this.props.logs} />;
    } else if (
      this.props.campaign.type === 'NEWSLETTER' ||
      this.props.campaign.type === 'FORM'
    ) {
      statsComponent = <EmailCollectionReport stats={this.props.logs} />;
    } else if (this.props.campaign.type === 'SEGMENTATION') {
      statsComponent = (
        <SegmentationReport
          stats={this.props.logs}
          action={this.props.campaign.action}
        />
      );
    } else if (this.props.campaign.type === 'HERO_BANNER') {
      statsComponent = <HeroBannerReport stats={this.props.logs} />;
    }

    // TODO: Fixed these bug only for frontend. This todo will be removed after backend fix.
    if (!personalizationCampaigns[this.props.campaign.type]) {
      return null;
    }

    return (
      <div className='widget page-content-block widget-perso-report'>
        {this.props.campaign.type === 'POPUP_BANNER' ? (
          <div>
            <div className='one-twelfths widget-popup-image widget-popup-image__full '>
              <Tooltip
                content={this.props.campaign.image || ''}
                type='image'
                placement='right'
              >
                <img
                  src={this.props.campaign.image || ''}
                  alt={`Campaign ${this.props.campaign.name}`}
                />
              </Tooltip>
            </div>
            <div className='eleven-twelfths widget-popup-text'>
              <div className='one-whole'>
                {isSuperUser() ? (
                  <h3 className='widget-page'>
                    {t(
                      types[personalizationCampaigns[this.props.campaign.type]]
                        .title,
                    )}
                    {' - '}
                    <span style={{ color: '##c0bebe', fontWeight: 'normal' }}>
                      {this.props.campaign.instanceId}
                    </span>
                  </h3>
                ) : (
                  <h3 className='widget-page'>
                    {t(
                      types[personalizationCampaigns[this.props.campaign.type]]
                        .title,
                    )}
                  </h3>
                )}
                <h2 className='widget-title'>{this.props.campaign.name}</h2>
                <ReportActions
                  {...this.props}
                  campaign={this.props.campaign}
                  moduleName='personalization'
                  hasExport={
                    this.props.campaign.type === 'NEWSLETTER' ||
                    this.props.campaign.type === 'FORM'
                  }
                  hasUserClickExport={
                    this.props.campaign.type === 'POPUP_BANNER'
                  }
                  moduleType='engagement'
                />
              </div>
              <div className='widget-meta'>
                {this.props.campaign.startDate && (
                  <span className='widget-meta-date'>
                    <Icon name='calendar' />
                    {getFormattedDate(this.props.campaign.startDate)}
                    {this.props.campaign.endDate &&
                      ` - ${getFormattedDate(this.props.campaign.endDate)}`}
                  </span>
                )}

                {this.props.campaign.devices &&
                  this.props.campaign.devices.map(device => {
                    return (
                      <span key={device} className='widget-meta-mobile'>
                        <Tooltip content={t(devices[device])} alignment='left'>
                          <Icon name={device.toLowerCase()} />
                        </Tooltip>
                      </span>
                    );
                  })}
                {this.props.campaign.filters &&
                  this.props.campaign.filters[0] &&
                  this.getCampaignPages(this.props.campaign.filters[0])}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className='one-whole'>
              {isSuperUser() ? (
                <h3 className='widget-page'>
                  {t(
                    types[personalizationCampaigns[this.props.campaign.type]]
                      .title,
                  )}
                  {' - '}
                  <span style={{ color: '##c0bebe', fontWeight: 'normal' }}>
                    {this.props.campaign.instanceId}
                  </span>
                </h3>
              ) : (
                <h3 className='widget-page'>
                  {t(
                    types[personalizationCampaigns[this.props.campaign.type]]
                      .title,
                  )}
                </h3>
              )}
              <h2 className='widget-title'>{this.props.campaign.name}</h2>
              <ReportActions
                {...this.props}
                campaign={this.props.campaign}
                moduleName='personalization'
                hasExport={
                  this.props.campaign.type === 'NEWSLETTER' ||
                  this.props.campaign.type === 'FORM'
                }
                hasUserClickExport={
                  this.props.campaign.type === 'NOTIFICATION_BAR'
                }
                moduleType='engagement'
              />
            </div>
            <div className='widget-meta'>
              {this.props.campaign.startDate && (
                <span className='widget-meta-date'>
                  <Icon name='calendar' />
                  {getFormattedDate(this.props.campaign.startDate)}
                  {this.props.campaign.endDate &&
                    ` - ${getFormattedDate(this.props.campaign.endDate)}`}
                </span>
              )}
              {this.props.campaign.devices &&
                this.props.campaign.devices.map(device => {
                  return (
                    <span key={device} className='widget-meta-mobile'>
                      <Tooltip content={t(devices[device])} alignment='left'>
                        <Icon name={device.toLowerCase()} />
                      </Tooltip>
                    </span>
                  );
                })}
              {this.props.campaign.filters &&
                this.props.campaign.filters[0] &&
                this.getCampaignPages(this.props.campaign.filters[0])}
            </div>
          </div>
        )}
        <hr className='widget-separator' />
        {statsComponent}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
});

export default connect(mapStatesToProps)(PersonalizationWidgetReport);
