import { getRestEndPoint } from '../../system/api';
import { $http } from '../../system/ajax';
import { getApiKey } from '../auth/user';

export const uploadFile = (data, type = 'ENGAGEMENT', callback) => {
  const formData = new FormData();
  formData.append('file', data.feedBackFile);
  $http(`${getRestEndPoint('upload/file')}?type=${type}&apiKey=${getApiKey()}`)
    .post(formData, { type: 'form', fileType: data.feedBackFile.type })
    .then(callback);
};

export const feedbackUploadFile = async (data, callback) => {
  const formData = new FormData();
  formData.append('file', data.feedBackFile);
  await $http(`${getRestEndPoint('upload/file')}?apiKey=${getApiKey()}`)
    .post(formData, { type: 'form', fileType: data.feedBackFile.type })
    .then(callback);
};

export const uploadFileWaitUrl = (data, type = 'ENGAGEMENT', callback) => {
  const formData = new FormData();
  formData.append('file', data.feedBackFile);
  $http(`${getRestEndPoint('upload/file')}?type=${type}&apiKey=${getApiKey()}`)
    .post(formData, { type: 'form', fileType: data.feedBackFile.type })
    .then(callback);
};

export const sendFeedback = (data, callback) => {
  return $http(`${getRestEndPoint('feedback/add')}?apiKey=${getApiKey()}`)
    .post(data, { type: 'json' })
    .then(callback);
};

export const getFeedback = () => {
  return $http(getRestEndPoint('feedback/get')).get();
};

export const contactUs = async (data, callback) =>
  await $http(`${getRestEndPoint('contact-us/add')}?apiKey=${getApiKey()}`)
    .post(data, { type: 'form' })
    .then(callback);
