/**
 * @fileoverview This page helps to manage notification actions
 */

/**
 * Toggle off the notification bar
 * @returns {{type: string}}
 */
export const toggleOffNotificationBar = () => {
  return { type: 'TOGGLE_OFF' };
};

/**
 * Toggle on the notification bar
 * @returns {{type: string}}
 */
export const toggleOnNotificationBar = () => {
  return { type: 'TOGGLE_ON' };
};

/**
 * Toggle of the notification bar
 * @returns {{type: string}}
 */
export const toggleNotificationBar = () => {
  return { type: 'TOGGLE' };
};

/**
 * Set the notifications and update the unseen notification count
 * @param {object[]} notifications
 * @returns {{payload, type: string}}
 */
export const setNotifications = notifications => {
  return { type: 'SET_NOTIFICATIONS', payload: notifications };
};
