import React from "react";
import connect from "react-redux/es/connect/connect";
import { getPopularSearches, initialRequestQueryParams } from "../../../modules/search/insights/ajax";
import { defaultFieldMap } from "./utils/table-utils";
import { createChart } from "./utils/chart-utils";
import ReactHighcharts from "react-highcharts";
import FunnelChart from "highcharts-funnel";
import SearchInsightsProductWithImageCell from "./sub-components/search-insights-product-with-image-cell";
FunnelChart(ReactHighcharts.Highcharts);
import { t } from "../../../system/ui";
import SgfDivTable from "./sub-components/table/sgf-div-table";
import classNames from "classnames";
import { filterActions, searchInsightsActions, uiActions } from "../../../actions";
import CardWrapper from "../../CardWrapper";
import InfoModal from 'components/InfoModal';
import { revenueConverter } from './utils/common-capabilities';
const totalSearchChartData = {
  data: [],
  name: "query",
  yLabel: "search",
  yLabelName: "searches",
  type: "pie",
  calculateTotalByYLabel: true,
  isPriceField: false,
  labelFormatter: function () {
    return (
      '<div class="dashboard--funnel__halfbox--legend">' +
      '<div class="title ellipses" title="' + this.name + '">' +
      this.name +
      '</div>' +
      '<div class="count-container">' +
      '<span class="count" style="color:' + this.color + '">' +
      this.y_label +
      '</span>' +
      'searches' +
      '</div>' +
      '</div>'
    );
  },
  yLabelFormatConversionF: (item) => { return parseInt(item) }
};

const totalRevenueChartData = {
  data: [],
  name: "query",
  yLabel: "revenue",
  yLabelName: "",
  type: "pie",
  calculateTotalByYLabel: true,
  isPriceField: true,
  labelFormatter: function () {
    return (
      '<div class="dashboard--funnel__halfbox--legend">' +
      '<div class="title ellipses" title="' + this.name + '">' +
      this.name +
      '</div>' +
      '<div class="count-container">' +
      '<span class="count" style="color:' + this.color + '">' +
      this.y_label +
      '</span>' +
      '</div>' +
      '</div>'
    );
  },
  yLabelFormatConversionF: (item) => { return parseInt(item) }
};

class SearchInsightsPopularSearches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      range: undefined,
      tableName: "popularSearches",
      tableHeaders: [
        {
          name: "Popular Searches",
          fieldMap: defaultFieldMap["Popular Searches"],
        },
        {
          name: "Total Searches",
          fieldMap: defaultFieldMap["Total Searches"]
        },
        {
          name: "CTR",
          fieldMap: defaultFieldMap["CTR"]
        },
        {
          name: "Purchases",
          fieldMap: defaultFieldMap["Purchases"]
        },
        {
          name: "Revenue",
          fieldMap: defaultFieldMap["Revenue"]
        },
        {
          name: "Conversion Rate",
          fieldMap: defaultFieldMap["Conversion Rate"]
        },
        {
          name: "Per Search Value",
          fieldMap: defaultFieldMap["Per Search Value"]
        },
        {
          name: "Best Product",
          fieldMap: defaultFieldMap["Best Product"],
          className: 'flex-grow-2',
          component: SearchInsightsProductWithImageCell
        }
      ],
      totalSearchesChartConfig: {},
      totalRevenueChartConfig: {},
      requestParameter: Object.assign({}, initialRequestQueryParams, { limit: 5, sort: "search", reverse: true, device: 'all' }),
    };

    this.getData = this.getData.bind(this);
    this.selectDevice = this.selectDevice.bind(this);
    this.showPopularSearches = this.showPopularSearches.bind(this);

    this.deviceFilterLookup = ['PC', 'TABLET', 'MOBILE', "IOS", "ANDROID"];
  }

  componentDidMount() {
    filterActions.filtersUpdated();
    filterActions.filtersReset();
    this.setState({
      requestParameter: {
        ...this.state.requestParameter,
        lang: this.props.selectedLanguage,
        device: "all",
        sort: "search",
        reverse: true,
        start: this.props.range[0],
        end: this.props.range[1]
      }
    }, () => {
      this.getData(true);
    });
  }

  componentWillReceiveProps(newProps) {
    let needChartUpdate = false;

    let sortKey = this.state.requestParameter.sort;
    let reverse = this.state.requestParameter.reverse;
    let sortChanged = false;
    if (newProps.clickedCellItem
      && newProps.clickedCellItem.fieldMap
      && newProps.clickedCellItem.fieldMap.sortKey
      && this.state.tableName === newProps.clickedCellItem.tableName
      && this.isSortedChanged(this.props.clickedCellItemId, newProps.clickedCellItemId)
      ) {
      sortKey = newProps.clickedCellItem.fieldMap.sortKey;
      reverse = (this.state.requestParameter.sort === newProps.clickedCellItem.fieldMap.sortKey) ? !this.state.requestParameter.reverse : this.state.requestParameter.reverse;
      sortChanged = true;
    }

    if (newProps.range[0] && newProps.range[1]) {

      if (this.props.range[0] !== newProps.range[0]
        || this.props.range[1] !== newProps.range[1]
        || this.props.selectedLanguage !== newProps.selectedLanguage) {
        needChartUpdate = true;
      }
      if (sortChanged || needChartUpdate)
        this.setState(prevState => {
          return (
            {
              requestParameter: {
                ...this.state.requestParameter,
                reverse,
                lang: newProps.selectedLanguage,
                sort: sortKey,
                // reverse: sortChanged ? !this.state.requestParameter.reverse : this.state.requestParameter.reverse,
                start: newProps.range[0],
                end: newProps.range[1]
              }
            }
          )
        }, () => {
          this.getData(needChartUpdate);
        });
    }
  }
  isSortedChanged = (currentId, nextId) => {
    if (currentId !== nextId) {
      return true;
    }
    return false;
  }

  selectDevice(selectedDevice) {
    this.setState(prevState => {
      return (
        {
          requestParameter: {
            ...this.state.requestParameter,
            lang: this.props.selectedLanguage,
            device: selectedDevice,
            start: this.props.range[0],
            end: this.props.range[1]
          }
        }
      )
    }, () => {
      this.getData(true);
    });
  }

  getData(needChartUpdate) {
    needChartUpdate = false; // piechartlar kapatıldıgı icin !
    uiActions.isLoading();
    getPopularSearches({ ...this.state.requestParameter }, popularSearchSummaryData => {
      uiActions.isLoaded();

      this.setState({
        data: popularSearchSummaryData,
      });

      if (needChartUpdate) {
        let totalTotalSearchRequestParameter = Object.assign({}, this.state.requestParameter, { sort: "search", reverse: true });
        getPopularSearches(totalTotalSearchRequestParameter, popularSearchSummaryData => {
          totalSearchChartData.data = popularSearchSummaryData;
          let totalSearchConfig = createChart(totalSearchChartData);

          this.setState({
            totalSearchesChartConfig: totalSearchConfig
          });

          let totalRevenueRequestParameter = Object.assign({}, this.state.requestParameter, { sort: "revenue", reverse: true });
          getPopularSearches(totalRevenueRequestParameter, popularSearchSummaryData => {
            totalRevenueChartData.data = popularSearchSummaryData;
            let totalRevenueConfig = createChart(totalRevenueChartData);
            this.setState({
              totalRevenueChartConfig: totalRevenueConfig
            });
          });


        });
      }
    });

  }
  showPopularSearches() {
    searchInsightsActions.openDetailTable("general_searchInsightPopularSearchesDetail");
  }

  convertData(data) {
    return revenueConverter(revenueConverter(data, 'revenue'), 'perSearchValue');
  }

  render() {

    return (
      <CardWrapper
        hasDeviceFilter
        devicesLookup={this.deviceFilterLookup}
        devicesValue={this.selectDevice}
        label={t("Popular Searches")}
        className="widget"
        infoContent={
          <div className="search__info-text">
            <strong>Popular Searches: </strong>
            <span>The most searched words/phrases independent from assets.</span>
          </div>
        }
      >
        <div className="dashboard--funnel__container one-whole search__insights__body d-none">
          {/* TODO Pie chart is closed with V3-4910 issue */}
          {this.state.totalSearchesChartConfig && Object.keys(this.state.totalSearchesChartConfig).length > 0 &&
            <div className="dashboard--funnel__halfbox search__insights__body__chart-wrapper">
              <h2 className="chart-headers"> {t('Total Searches')}
                <InfoModal iconClassName="search__info-icon" title={'Info'} >
                  <div className="search__info-text">
                    <strong>Total Searches: </strong>
                    <span>“This number shows the total number of search queries for the entire duration of your selected date range.”</span>
                  </div>
                </InfoModal>
              </h2>
              <ReactHighcharts config={this.state.totalSearchesChartConfig} ref={"totalSearches"} />
              <div className="dashboard--funnel__total">
                <div className="dashboard--funnel__halfbox--legend">
                  <div className="title">{t("Total Searches")}</div>
                  <div className="count-container">
                    <span className="count" style={{ color: "#000", fontWeight: "bolder" }}>
                      {this.state.totalSearchesChartConfig.sgfData && this.state.totalSearchesChartConfig.sgfData.totalValView}
                    </span>
                    {t("")}
                  </div>
                </div>
              </div>
            </div>
          }

          <div className={"search__insights__body__chart-divider"} />

          {this.state.totalRevenueChartConfig && Object.keys(this.state.totalRevenueChartConfig).length > 0 &&
            <div className="dashboard--funnel__halfbox search__insights__body__chart-wrapper">
              <h2 className="chart-headers"> {t('Total Revenue')}
                <InfoModal iconClassName="search__info-icon" title={'Info'} >
                  <div className="search__info-text">
                    <strong>Total Searches: </strong>
                    <span>Shows the total monetary amount of products sold through Searchbox widgets inline with the searched keyword.</span>
                  </div>
                </InfoModal>
              </h2>
              <ReactHighcharts config={this.state.totalRevenueChartConfig} ref={"totalRevenue"} />
              <div className="dashboard--funnel__total">
                <div className="dashboard--funnel__halfbox--legend">
                  <div className="title">{t("Total Revenue")}</div>
                  <div className="count-container">
                    <span className="count" style={{ color: "#000", fontWeight: "bolder" }}>
                      {this.state.totalRevenueChartConfig.sgfData && this.state.totalRevenueChartConfig.sgfData.totalValView}
                    </span>
                    {t("")}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>

        <SgfDivTable data={this.convertData(this.state.data)} headers={this.state.tableHeaders} tableName={this.state.tableName} />

        <div style={{ textAlign: "right", marginRight: "10px" }}>
          <a className={classNames("button", "add-criteria")} onClick={this.showPopularSearches.bind(this)}>
            {t("Show All")}
          </a>
        </div>
      </CardWrapper>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  clickedCellItem: store.sgfDivTable.clickedCellItem,
  clickedCellItemId: store.sgfDivTable.clickedCellItemId,
  selectedLanguage: store.switchLanguage.selectedLanguage,
});

export default connect(mapStatesToProps)(SearchInsightsPopularSearches);

