import React from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';

import { Tooltip } from 'react-tooltip';
import Icon from '../../icon';
import { StatsContainer } from './stats-container';

import classes from './index.module.scss';

import { getEmailStatsBounceBackReport } from '../../../modules/reports/ajax';

export const EmailStatsBounceBackReport = ({ report, campaignId }) => {
  const [startDate, endDate] = useSelector(({ date }) => date.range);

  const handleExportReport = async () =>
    await getEmailStatsBounceBackReport({
      campaignId,
      startDate: moment(startDate)
        .utc()
        .format('YYYY-MM-DD'),
      endDate: moment(endDate)
        .utc()
        .format('YYYY-MM-DD'),
      isExport: true,
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `bounceback_report_${campaignId}_${moment(startDate)
          .utc()
          .format('YYYY-MM-DD')}_${moment(endDate)
          .utc()
          .format('YYYY-MM-DD')}.csv`,
      );
      document.body.appendChild(link);
      link.click();
    });

  const isBtnDisabled = report?.length === 0 || !report;

  return (
    <section className={classes.container}>
      {report?.length > 0 ? (
        <StatsContainer report={report} />
      ) : (
        <p> No Available Data Yet!</p>
      )}
      <button
        type='button'
        onClick={handleExportReport}
        disabled={isBtnDisabled}
        className={classes.exportBtn}
        aria-label='Export Report'
        data-tooltip-id='Export Report'
      >
        <Icon name='pdf' />
      </button>
      <Tooltip
        effect='solid'
        className='sg-tooltip'
        id='Export Report'
        content='Export'
      />
    </section>
  );
};
