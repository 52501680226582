export const useGroupErrorMarkers = errorMarkers => {
  const ErrorMarkers = errorMarkers?.reduce((acc, marker) => {
    const { startLineNumber, endLineNumber } = marker;
    const key = `${startLineNumber}-${endLineNumber}`;
    if (!acc[key]) {
      // eslint-disable-next-line no-param-reassign
      acc[key] = [];
    }
    acc[key].push(marker);
    return acc;
  }, {});
  return ErrorMarkers;
};
