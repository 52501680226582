import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { uiActions, jbActions } from '../actions';
import { config } from '../constants/config';
import {
  initialJourneyBuilderDiscoveryAsset,
  initialJourneyBuilderDeliveryAsset,
  jbInitialData,
  jbRecoFields,
} from '../constants/datamaps/journey-builder';
import { TextField } from '../components/fields';
import JourneyBuilderItems from '../components/journeyBuilder/jb.item';
import WizardAdvancedConfig from '../components/wizard/advanced-config';
import WizardDateSelection from '../components/wizard/date-selection';
import WizardMediumSelection from '../components/wizard/medium-selection';
import WizardPageType from '../components/wizard/page-type';
import { popupRecoTransform } from '../components/wizard/utils';
import { isSwitchAccount } from '../modules/auth/user';
import Fields from '../modules/campaign/fields';
import { goTestOrLive } from '../modules/campaigns/jbwizard';
import Overlay from '../modules/campaign/fields/overlay';
import { setTitle } from '../system/document';
import { clone } from '../system/object';
import { t, confirm } from '../system/ui';

class JourneyBuilderAdd extends React.Component {
    constructor(props) {
        super(props);
        this.discoveryRef = React.createRef();
        this.deliveryRef = React.createRef();

        this.state = {
            campaign: clone(jbInitialData),
            title: "",
            scenarioName: "",
            showAdvancedConfig: false,
            saved: true,
            selectedType: {
                page: "journey_builder",
                placement: this.props.routeParams.placement || ""
            },
            isSubmitting: false,
            submittingButton: "",
            devices: ""
        };

        this.onTitleChange = this.onTitleChange.bind(this);
        this.onScenarioNameChange = this.onScenarioNameChange.bind(this);
        this.showAdvancedConfig = this.showAdvancedConfig.bind(this);
        this.hideAdvancedConfig = this.hideAdvancedConfig.bind(this);
        this.goTest = this.goTest.bind(this);
        this.validate = this.validate.bind(this);
        this.getEngagementValues = this.getEngagementValues.bind(this);
    }

    componentDidMount() {
        let  account = isSwitchAccount()
            ? this.props.switchedUser.account
            : this.props.user.account;
        this.setState({title: account && account.mainLanguage ==="TR" ? "Yardım ister misin?" : "Need Help?"},
            ()=>setTitle(
            t(
                "Add " +
                config.menus.primary.recommendations.children[
                    this.state.selectedType.page
                    ].name +
                " Page" +
                " Campaign"
            )
        ));

        uiActions.isValidated();
        uiActions.resetPage();
        uiActions.confirmOnLeave(this);
        this.setCreateFields()
        this.setBreadCrump();
    }

    setCreateFields() {
        let discoveryAssetList = clone(initialJourneyBuilderDiscoveryAsset);
        let deliveryAssetList= clone(initialJourneyBuilderDeliveryAsset);
        jbActions.addCriteria(discoveryAssetList);
        jbActions.addDeliveryCriteria(deliveryAssetList);
    }

    setBreadCrump() {
        uiActions.showBreadcrumb({
            home: {
                name: t("Recommend"),
                icon: "heart",
                slug: "/recommendations"
            },
            top: {
                name: t("Journey Builder"),
                slug: "/recommendations/" + this.state.selectedType.page
            },
            current: {
                name: "Create New"
            }
        });
    }

    componentWillReceiveProps(newProps) {
        if (this.state.saved !== newProps.formSaved) {
            this.setState({
                saved: newProps.formSaved
            });
        }
    }

    componentDidUpdate(oldProps) {
        if (this.props.validate === true && oldProps.validate === false) {
            this.validate("test");
        }
    }

    getEngagementValues() {
        if (this.refs.contentExtra) {
            let positionOptions = this.refs.contentExtra.refs.positionOptions.state;
            let timingOptions = this.refs.contentExtra.refs.timingOptions
                .state;
            let audienceOptions = this.refs.contentExtra.refs.audienceOptions
                .state;
            let overlay = this.refs.contentButtons.state;
            let audience = [];
            audienceOptions.values.segment.forEach(option => {
                audience.push({
                    type: "SEGMENT",
                    include: option.member,
                    param: option.value
                });
            });
            audienceOptions.values.visitor.forEach(option => {
                audience.push({
                    type: "VISITOR",
                    include: option.member,
                    param: option.value
                });
            });
            return {
                overlay: overlay.value,
                verticalPosition: positionOptions.values.verticalPosition,
                horizontalPosition: positionOptions.values.horizontalPosition,
                delayAction: {
                    type: popupRecoTransform(
                        timingOptions.values.timing.type,
                        timingOptions.delayAllPages
                    ),
                    time: timingOptions.values.timing.param || ""
                },
                constraint: {
                    type: popupRecoTransform(timingOptions.values.frequency.type),
                    times: timingOptions.values.frequency.param || ""
                },
                filters: audience
            };
        }
        return false;
    }

    goTest() {
        this.setState({isSubmitting: true, submittingButton: "test"}, () => {
            goTestOrLive(
                "test",
                this,
                this.state.selectedType.page,
                this.getEngagementValues()
            );
            uiActions.formSaved();
        });
    }

    validate(type) {
        let errors = document.querySelectorAll(".has-error");

        if (this.props.validate === true && errors.length === 0) {
            if (type === "test") {
                confirm({
                    onConfirm: this.goTest,
                    onCancel: () => {
                        this.setState({
                            isSubmitting: false
                        });
                    }
                });
            }
        } else {
            const deliveryItems = this.props.delivery;
            if (deliveryItems) {
                const isAnyOneActive = deliveryItems.some(item => item.properties.active)
                if(isAnyOneActive) uiActions.isValidating();
            }
        }
    }

    onTitleChange(e) {
        this.setState({
            title: e.currentTarget.value,
            saved: false
        });
        uiActions.formEdited();
    }

    onScenarioNameChange(e) {
        this.setState({
            scenarioName: e.currentTarget.value,
            saved: false
        });
        uiActions.formEdited();
    }

    showAdvancedConfig() {
        let devices = this.refs.wizardMediumSelection
          .state.mediums.join("");
        this.setState({
            showAdvancedConfig: true,
            devices
        });
        uiActions.formEdited();
    }

    hideAdvancedConfig() {
        this.setState({
            showAdvancedConfig: false
        });
    }

    render() {
       let  account = isSwitchAccount()
            ? this.props.switchedUser.account
            : this.props.user.account;
        return (
            <form id="wizard" ref="wizard" className="wizard wizard-cards">
                <div className="widget page-content-block">
                    <ol className="form-elements">
                        <li className="wizard-comp" style={{marginTop:"0px"}}>
                            <h3 className="wizard-comp-title">Trigger</h3>
                            <p className="widget-title-desc">Prepare your triggering method for your survey</p>
                            <li className="wizard-input-types wizard-perso-buttons">
                                <TextField
                                    name="notificationTitle"
                                    label="Title"
                                    className="item-stacked"
                                    value={this.state.title}
                                    required={true}
                                    onChange={this.onTitleChange}
                                    tooltip={false}
                                    style={{minWidth: "600px"}}
                                />
                                <Overlay
                                    ref="contentButtons"
                                    campaign={this.state.campaign}
                                />
                            </li>

                            <li className="wizard-perso-fields">
                                <Fields
                                    ref="contentExtra"
                                    campaign={this.state.campaign}
                                    comps={jbRecoFields.fields}
                                    campaignType="JOURNEY_BUILDER"
                                />
                            </li>
                        </li>
                        <hr className="widget-separator" style={{ height: "0px" }} />
                        <li style={{marginBottom:"60px"}}>
                            <JourneyBuilderItems
                                title={"Discovery"}
                                desc={"Choose the interests you want ask you shoppers"}
                                account={account}
                                isDelivery={false}
                                ref={this.discoveryRef}
                                isNewCampaign={true}
                            />

                        </li>
                        <hr className="widget-separator" style={{ height: "0px" }} />
                        <li>
                            <JourneyBuilderItems
                                title={"Delivery"}
                                desc={"Choose the best journey for your customers"}
                                account={account}
                                isDelivery={true}
                                ref={this.deliveryRef}
                            />

                        </li>
                    </ol>
                </div>

                <div className="wizard-options">
                    <ol className="form-elements">
                        <li className="campaign-subject">
                            <TextField
                                name="scenarioName"
                                label="Campaign Name"
                                className="item-stacked one-whole label-emphasise"
                                value={this.state.scenarioName}
                                required={true}
                                onChange={this.onScenarioNameChange}
                            />
                        </li>
                        <li>
                            <WizardDateSelection
                                ref="wizardDateSelection"
                            />
                        </li>
                        <li>
                            <WizardMediumSelection
                                ref="wizardMediumSelection"
                            />
                        </li>
                        <li>
                            <WizardPageType ref="wizardPageType"/>
                        </li>

                        <li className="buttons">
                            <a
                                className="button secondary-action one-whole"
                                onClick={this.showAdvancedConfig}
                            >
                                {t("Advanced Configuration")}
                            </a>
                            <a
                                className={classNames("button gotest-action one-whole", {
                                    "gotest-action--disabled":
                                        this.state.isSubmitting &&
                                        this.state.submittingButton === "test"
                                })}
                                onClick={this.validate.bind(null, "test")}
                            >
                                {t("Go Test")}
                                {this.state.isSubmitting &&
                                this.state.submittingButton === "test" && (
                                    <span className="page-progress page-progress--button"/>
                                )}
                            </a>
                            <a className="button disabled one-whole">{t("Go Live")}</a>
                        </li>
                    </ol>
                </div>

                <WizardAdvancedConfig
                    ref="wizardAdvancedConfig"
                    show={this.state.showAdvancedConfig}
                    onHide={this.hideAdvancedConfig}
                    campaignType={"jbTrigger"}
                    isJourneyBuilder={true}
                    campaignSpecifics={this}
                />
            </form>
        );
    }
}

const mapStatesToProps = store => ({
    switchedUser: store.switchedUser.switchedUser,
    validate: store.ui.validate,
    breadcrumb: store.ui.breadcrumb,
    formSaved: store.ui.formSaved,
    user: store.user.user,
    discovery: store.jbWizard.discovery,
    delivery: store.jbWizard.delivery,
    ui: store.ui.ui
});

JourneyBuilderAdd.contextTypes = {
    router: PropTypes.object.isRequired
};

export default connect(mapStatesToProps)(JourneyBuilderAdd);
