import { createAction } from '../system/store';

export const updateFilters = () => createAction('UPDATE_FILTERED');

export const filtersUpdated = () => createAction('FILTERED_UPDATED');

export const resetFilters = () => createAction('RESET_FILTERED');

export const filtersReset = () => createAction('FILTERED_RESET');

export const showButtons = () => createAction('SHOW_RESET_BUTTON');

export const hideButtons = () => createAction('HIDE_RESET_BUTTON');

export const disableButtons = () => createAction('DISABLE_BUTTONS');
export const enableButtons = () => createAction('ENABLE_BUTTONS');

export const changePageSize = pageSize =>
  createAction('UPDATE_PAGESIZE', { pageSize });

export const changePageNumber = pageNumber =>
  createAction('UPDATE_PAGENUMBER', { pageNumber });

export const resetAllFilters = () => {
  resetFilters();
  hideButtons();
};

export default {
  updateFilters,
  filtersUpdated,
  resetFilters,
  filtersReset,
  showButtons,
  hideButtons,
  disableButtons,
  enableButtons,
  changePageSize,
  changePageNumber,
  resetAllFilters,
};
