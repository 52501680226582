/**
 * Created by mehmetyurtar on 02/08/2017.
 */

"use strict";

import { createAction } from "../system/store";

export const addCampaignReport = reports => {
  createAction("ADD_CAMPAIGN_REPORT", { reports });
};

export const emptyCampaignReports = () => {
  createAction("EMPTY_CAMPAING_REPORTS", {});
};

export default {
  addCampaignReport,
  emptyCampaignReports
};
