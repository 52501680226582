import React, { useEffect, useState, useReducer } from 'react';
import classes from './FtpSettings.module.scss';
import { initialState, ftpTabs } from './constants';
import { isLoading, isLoaded, showNotification } from '../../../actions/ui';
import FtpService from './services';
import {
  SFYHeader,
  SFYWrapper,
} from '../../../components/sfy-components/module-exports';
import ImportFTPTab from './tabs/importFTP';
import ExportFTPTab from './tabs/exportFTP';
import DownLoadFTPTab from './tabs/download-ftp/downloadFTP';

const FtpSettings = () => {
  const [tabs, setTabs] = useState(ftpTabs);

  const reducer = (state, newState) => {
    return { ...state, ...newState };
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const ftpSettingsInvoker = () => {
    isLoading();
    FtpService('contacts/ftpGet')
      .getAll()
      .then(response => {
        if (response.length > 0) {
          for (let res of response) {
            if (res.configType === 'IMPORT') {
              dispatch({
                importFtpConfig: {
                  ...res,
                },
              });
            }
            if (res.configType === 'EXPORT') {
              dispatch({
                exportFtpConfig: {
                  ...res,
                },
              });
            }
          }
        }
      })
      .catch(err => {
        showNotification({
          content: () => <p>{err.response}</p>,
          className: 'notification-fail',
        });
      })
      .finally(() => isLoaded());
  };

  useEffect(() => {
    ftpSettingsInvoker();
  }, []);

  const handleTabClick = id => {
    const newTab = tabs.map(tab => {
      if (tab.id === id) {
        return { ...tab, active: true };
      }
      return { ...tab, active: false };
    });
    setTabs(newTab);
  };

  return (
    <>
      <SFYHeader pageTitle='FTP Settings' />
      <SFYWrapper>
        <div className={classes['sfy-tab-container--align-center']}>
          {tabs.map(tab => {
            return (
              <div
                key={tab.id}
                className={`${classes['sfy-tab']} ${
                  tab.active ? classes['--active'] : classes['sfy-tab']
                }`}
                onClick={() => {
                  handleTabClick(tab.id);
                }}
              >
                <span>{tab.componentName}</span>
              </div>
            );
          })}
        </div>
        {tabs.map(tab => {
          if (tab.active) {
            if (tab.name === 'importFTP') {
              return (
                <ImportFTPTab key={tab.id} state={state} dispatch={dispatch} />
              );
            }
            if (tab.name === 'exportFTP') {
              return (
                <ExportFTPTab key={tab.id} state={state} dispatch={dispatch} />
              );
            }
            if (tab.name === 'downloadUserPermissionChanges') {
              return (
                <DownLoadFTPTab
                  key={tab.id}
                  state={state?.exportFtpConfig}
                  dispatch={dispatch}
                />
              );
            }
          }
        })}
      </SFYWrapper>
    </>
  );
};

export default FtpSettings;
