import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { setTitle } from '../../../../system/document';
import { t } from '../../../../system/ui';

import { uiActions } from '../../../../actions';

import { CustomerRemoval } from '../../../../constants/datamaps/notifications';
import { removeCustomers } from '../../../../modules/auth/user';

import NavigationTab from '../ components/navigation-tab';
import { TextArea } from '../../../../components/fields';

const CustomerRemovalTool = props => {
  const [state, setState] = useState({
    value: '',
    name: 'text',
  });
  const formRef = useRef(null);

  const { isLoaded, isLoading, showNotification } = uiActions;

  const onChangeHandler = e => {
    const { value } = e.target;
    setState({ value });
  };

  const updateHandler = e => {
    e.preventDefault();
    isLoading();

    const textAreaContentArr = state.value.split(',');
    textAreaContentArr.forEach((item, index) => {
      textAreaContentArr[index] = item.trim();
    });

    removeCustomers(textAreaContentArr, () => {
      isLoaded();
      setState({
        value: '',
      });
      showNotification({
        content: CustomerRemoval,
      });
    });
  };

  useEffect(() => {
    setTitle(t('Customer Removal Tool'));
  }, []);

  return (
    <div>
      <NavigationTab />
      <div className='page-forms' ref={formRef}>
        <p>{t('Customer Removal Tool Description')}</p>
        <div className='page-content-block one-half'>
          <form id='settings-form' onSubmit={updateHandler}>
            <ol className='form-elements'>
              <li className='form-element'>
                <TextArea
                  name={props.name}
                  label={props.label}
                  className='item item-stacked one-whole'
                  value={state.value}
                  onChange={onChangeHandler}
                  style={{ height: 200 }}
                />
              </li>
              <li>{t('Customer Removal Tool Disclaimer')}</li>
              <li className='buttons'>
                <button
                  className='tertiary-action'
                  type='submit'
                  disabled={state.value === '' || state.value === null}
                >
                  {t('Remove')}
                </button>
              </li>
            </ol>
          </form>
        </div>
      </div>
    </div>
  );
};

CustomerRemovalTool.defaultProps = {
  options: {
    label: 'Customer Removal Tool Description Label',
    name: 'text',
  },
};
const mapStatesToProps = store => ({
  user: store.user.user,
  validate: store.ui.validate,
  language: store.ui.language,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(CustomerRemovalTool);
