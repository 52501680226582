import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { operatorObjToArray } from './utils/utils';
import { decodeHtml } from '../../system/string';
import { t } from '../../system/ui';
import {
  pageOperators,
  operatorColumns,
} from '../../constants/datamaps/rule-based-segmentation';
import { getSegmentByType } from '../../modules/segmentation/ajax';
import ErrorText from './ErrorText';

const defaultSelectData = {
  value: 'any',
  label: 'any segment',
  title: 'any segment',
};

/**
 * @name criteriaFilterCustomSegment
 * @description Component that renders the segment filter
 * @param {object} criteriaData - The criteria data
 * @param {string} filterType - The filter type
 * @param {array} statements - The statements
 * @param {function} callbackFunc - The callback function
 * @returns {JSX.Element}
 */
const criteriaFilterCustomSegment = ({
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const defaultFilterData = {
    dataType: criteriaData?.dataType,
    column: operatorColumns.customSegmentOperator,
    operator: pageOperators.equals.value,
    value: 'any',
  };

  const [selectedSegment, setSelectedSegment] = useState('');
  const [segments, setSegments] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState('');

  if (statements?.[filterType].length === 0) {
    callbackFunc(filterType, [defaultFilterData]);
  }

  useEffect(() => {
    const statement = statements?.[filterType]?.[0];

    setSelectedOperator(statement?.operator || defaultFilterData.operator);
    const defaultSegment = statement
      ? statement?.value
      : defaultFilterData.value;
    setSelectedSegment(defaultSegment);

    const isDefaultSegmentExistInPages = segments.some(
      item => item.value === defaultSegment,
    );

    if (
      (!isDefaultSegmentExistInPages && defaultSegment !== 'any') ||
      segments.length === 0
    ) {
      getSegmentByType(
        response => {
          const activeSegments = response.filter(
            item => item.status === 'ACTIVE',
          );
          const stringArrSegments = activeSegments.map(item => item.name);
          const isDefaultSegmentExist = [
            defaultFilterData.value,
            stringArrSegments,
          ].includes(defaultSegment);
          const responseWithDefaultSegment =
            !isDefaultSegmentExist && defaultSegment
              ? [...stringArrSegments, defaultSegment]
              : stringArrSegments;
          const sorted = responseWithDefaultSegment.sort();
          const sortedSegments = sorted.map(item => {
            return {
              value: item,
              label: decodeHtml(item),
              title: decodeHtml(item),
            };
          });
          setSegments([defaultSelectData, ...sortedSegments]);
        },
        ['EVENT_BASED'],
      );
    }
  }, [statements]);

  const triggerCallbackFunc = (val, type) => {
    const columnVal = operatorColumns.customSegmentOperator;
    const operatorVal = type === 'operator' ? val?.value : selectedOperator;
    const segmentVal = type === 'segment' ? val?.value : selectedSegment;

    const filterData = [
      {
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator: operatorVal,
        value: segmentVal,
      },
    ];

    callbackFunc(filterType, filterData);
  };

  const onChange = (selectedValue, type) => {
    switch (type) {
      case 'segment':
        setSelectedSegment(selectedValue?.value || '');
        break;
      case 'operator':
        setSelectedOperator(selectedValue?.value);
        break;
      default:
        break;
    }
    triggerCallbackFunc(selectedValue, type);
  };

  return (
    <div className='rbs-filter-field'>
      <div className='filter-operator'>
        <span className='filter-operator-title'>Operator*</span>
        <Select
          value={selectedOperator}
          options={operatorObjToArray(pageOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          onChange={e => onChange(e, 'operator')}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      <div className='filter-connector' />
      <div className='filter-operator'>
        <span className='filter-operator-title'>Segment*</span>
        <Select
          value={selectedSegment}
          options={segments}
          name='segment'
          clearable={false}
          searchable
          onChange={e => onChange(e, 'segment')}
          className={`criteria-field one-whole ${
            selectedSegment ? '' : 'has-error'
          }`}
          openOnClick
          placeholder={t('Select or text')}
        />
        {!selectedSegment && <ErrorText />}
      </div>
    </div>
  );
};

export default criteriaFilterCustomSegment;
