/**
 * Created by mehmetyurtar on 1/15/18.
 */
import { createAction } from "../system/store";

export const setBrands = options => {
  createAction("SET_BRANDS", options);
};

export const removeBrands = () => {
  createAction("REMOVE_BRANDS");
};

export default {
  setBrands,
  removeBrands
};
