import React from "react";
import Icon from "../components/icon";
import { t, openModal } from "../system/ui";
import * as modals from "../constants/datamaps/modals";
import { localeString } from "../system/string";

const showModal = (modal, e) => {
  e.preventDefault();
  let modalContent = modals[modal];

  openModal({
    title: modal,
    content: modalContent
  });
};

const DeviceCard = props => {
  const stats = props.data.stats;

  return (
    <div className="stat-card stat-page">
      <h3 className="stat-card-title">{t(props.title)}</h3>

      <a
        className="modal-trigger"
        onClick={e => {
          showModal(props.data.modal, e);
        }}
      >
        <Icon name="info" />
      </a>

      <div className="stat-card-grid">
        <div className="stat-card-subtitle stat-card-text--bold">Type</div>
        <div className="stat-card-subtitle stat-card-text--bold stat-card-text--center">Views</div>
        <div className="stat-card-subtitle stat-card-text--bold stat-card-text--center">Rate</div>

        <div className="stat-card-holder"><Icon name="pc" />PC</div>
        <div className="stat-card-text--white stat-card-text--center">{localeString(stats.pCCount.count)}</div>
        <div className="stat-card-text--white stat-card-text--center">{stats.pCRatio.ratio}%</div>

        {props.showIn !== "your-website" &&
          <div className="stat-card-line">
              <div className="stat-card-draw-line"></div>
          </div>
        }

        <div className="stat-card-holder"><Icon name="mobile" />Mobile</div>
        <div className="stat-card-text--white stat-card-text--center">{localeString(stats.totalMobileCount.count)}</div>
        <div className="stat-card-text--white stat-card-text--center">{stats.totalMobileRatio.ratio}%</div>

        <div className="stat-card-subgrid">
            <div className="stat-card-small-holder"><Icon name="mobile" />Mobile Web</div>
            <div className="stat-card-text--white stat-card-text--center">{localeString(stats.mobileCount.count)}</div>
            <div className="stat-card-text--white stat-card-text--center">{stats.mobileRatio.ratio}%</div>

            <div className="stat-card-small-holder"><Icon name="android" />Android App</div>
            <div className="stat-card-text--white stat-card-text--center">{localeString(stats.androidCount.count)}</div>
            <div className="stat-card-text--white stat-card-text--center">{stats.androidRatio.ratio}%</div>

            <div className="stat-card-small-holder"><Icon name="ios" />iOS App</div>
            <div className="stat-card-text--white stat-card-text--center">{localeString(stats.iosCount.count)}</div>
            <div className="stat-card-text--white stat-card-text--center">{stats.iosRatio.ratio}%</div>
        </div>
        {props.showIn !== "your-website" &&
            <div className="stat-card-line">
                <div className="stat-card-draw-line"></div>
            </div>
        }
        <div className="stat-card-holder"><Icon name="tablet" />Tablet</div>
        <div className="stat-card-text--white stat-card-text--center">{localeString(stats.tabletCount.count)}</div>
        <div className="stat-card-text--white stat-card-text--center">{stats.tabletRatio.ratio}%</div>

        <div className="stat-card-holder"><Icon name="globe" />Other</div>
        <div className="stat-card-text--white stat-card-text--center">{localeString(stats.otherCount.count)}</div>
        <div className="stat-card-text--white stat-card-text--center">{stats.otherRatio.ratio}%</div>
      </div>
    </div>
  );
};

export default DeviceCard;
