import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { StickyTable, Row, Cell } from 'react-sticky-table';

import { uiActions, filterActions, trendifyActions } from '../actions';
import TrendifyBrandDetail from '../components/analytics/brand.details';
import Currency from '../components/currency';
import Export from '../components/export';
import BrandFilters from '../components/filters/brand-filters';
import Filters from '../components/filters/filters';
import Icon from '../components/icon';
import ScrollUpButtonAnalytics from '../components/scrollUpAnalytics';
import TableHeadings from '../components/table/heading';
import TotalsLine from '../components/table/totalsLine';
import { config } from '../constants/config';
import {
  brandTable,
  brandQuery,
  dummyDefaultTrendifyTotals,
} from '../constants/datamaps/analytics';
import grid from '../modules/analytics/grid';
import {
  hasModulePrivilege,
  hasSegmentifyListing,
  hasTrendify,
  hasTrendifySources,
} from '../modules/auth/user';
import transformAnalyticsSorting from '../modules/transform/analyticsTransforms';
import {
  getBrands,
  getBrandCount,
  getBrandTotal,
} from '../modules/trendify/ajax';
import { trendifyIntegrationInfo } from '../modules/trendify/page';
import { dateUpdatedQuery, parseTotalLineAPI } from '../modules/trendify/utils';
import { queryDate } from '../system/date';
import { setTitle } from '../system/document';
import { clone } from '../system/object';
import { calculateRatio } from '../system/ratio';
import { localeString, decodeHtml } from '../system/string';
import { t } from '../system/ui';

const dummyRow = {
  name: '',
  stats: {
    allSiteView: 117811,
    pcSiteView: 42418,
    tabletSiteView: 3066,
    mobileSiteView: 72327,
    allSiteBasket: 3263,
    pcSiteBasket: 1566,
    tabletSiteBasket: 74,
    mobileSiteBasket: 1623,
    allSitePurchase: 95,
    pcSitePurchase: 67,
    tabletSitePurchase: 2,
    mobileSitePurchase: 26,
    allSiteAmount: 291774.95,
    pcSiteAmount: 210007.5,
    tabletSiteAmount: 488.9,
    mobileSiteAmount: 81278.55,
    allSgmClick: 4867,
    pcSgmClick: 2355,
    tabletSgmClick: 284,
    mobileSgmClick: 2228,
    allSgmBasket: 57,
    pcSgmBasket: 43,
    tabletSgmBasket: 1,
    mobileSgmBasket: 13,
    allSgmPurchase: 4,
    pcSgmPurchase: 3,
    mobileSgmPurchase: 1,
    allSgmAmount: 10086.9,
    pcSgmAmount: 6387.9,
    mobileSgmAmount: 3699,
    allInternalClick: 50601,
    pcInternalClick: 23474,
    tabletInternalClick: 1607,
    mobileInternalClick: 25520,
    allSocialClick: 26,
    pcSocialClick: 15,
    tabletSocialClick: 1,
    mobileSocialClick: 10,
    allSearchClick: 40954,
    pcSearchClick: 12006,
    tabletSearchClick: 958,
    mobileSearchClick: 27990,
    allPaidClick: 19810,
    pcPaidClick: 5436,
    tabletPaidClick: 224,
    mobilePaidClick: 14150,
    allListingClick: 600,
    pcListingClick: 245,
    tabletListingClick: 15,
    mobileListingClick: 340,
    allOtherClick: 5820,
    pcOtherClick: 1242,
    tabletOtherClick: 261,
    mobileOtherClick: 4317,
  },
};

class Brands extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      brand: [],
      query: clone(brandQuery),
      sort: 'DESC',
      currentSorted: "stats->>'allSiteView'",
      device: 'all',
      showTrendifyFilters: false,
      showLoadingAnimation: true,
      showScrollToTop: false,
      maxHeight: 0,
      isTotalsLoading: false,
      totals: dummyDefaultTrendifyTotals,
    };

    this.refresh = this.refresh.bind(this);
    this.reset = this.reset.bind(this);
    this.sort = this.sort.bind(this);
    this.onBrandDetail = this.onBrandDetail.bind(this);
    this.toggleAnalyticsFilters = this.toggleAnalyticsFilters.bind(this);
    this.exportFile = this.exportFile.bind(this);
    this.getTotals = this.getTotals.bind(this);
    this.setScrollToTop = this.setScrollToTop.bind(this);
    this.calculateSticky = this.calculateSticky.bind(this);
    this.onTableScroll = this.onTableScroll.bind(this);
  }

  toggleAnalyticsFilters() {
    this.setState({
      showTrendifyFilters: !this.state.showTrendifyFilters,
    });
  }

  componentDidMount() {
    setTitle(t('Segmentify Analytics'));
    uiActions.hideBreadcrumb();
    uiActions.isLoading();

    filterActions.filtersReset();
    // dateActions.resetRange();

    trendifyIntegrationInfo();

    const newQuery = { ...this.state.query };
    newQuery.startDate = this.props.range[0].format('YYYY-MM-DD');
    newQuery.endDate = this.props.range[1].format('YYYY-MM-DD');

    getBrands(newQuery, response => {
      this.setState({
        rows: response,
        showLoadingAnimation: false,
      });
      this.getTotals(newQuery);
      uiActions.isLoaded();
      this.setScrollToTop();
      this.calculateSticky();
    });

    getBrandCount(newQuery, response => {
      trendifyActions.updateCount(response);
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      trendifyIntegrationInfo();
      if (Object.keys(this.refs).length) {
        this.refresh(newProps, newProps.pageSize, newProps.pageNumber);
      }
    }

    if (newProps.update === true) {
      this.refresh(newProps, 100, 1);
    }

    if (newProps.reset === true) {
      this.reset();
    }

    if (
      newProps.pageSize !== this.props.pageSize ||
      newProps.pageNumber !== this.props.pageNumber
    ) {
      this.refresh(newProps, newProps.pageSize, newProps.pageNumber);
    }
  }

  reset() {
    const query = dateUpdatedQuery({
      range: this.props.range,
      rangeAlias: this.props.rangeAlias,
      analyticsQuery: brandQuery,
    });

    this.setState({ device: 'all' });

    grid.reset(query, getBrands, getBrandCount, this, () => {
      this.getTotals(query);
      this.setScrollToTop();
      this.calculateSticky();
    });
  }

  query(newProps, pageSize, pageNumber) {
    const query = clone(brandQuery);
    const range = newProps ? newProps.range : this.props.range;
    const brand = this.refs.pageFilters.querySelector('[name=productBrand]')
      .value;
    const device = this.refs.pageFilters.querySelector('[name=visitorDevice]')
      .value;

    query.startDate = queryDate(range[0]);
    query.endDate = queryDate(range[1]);
    query.brand = brand;
    query.items = this.props.pageSize;
    query.page = this.props.pageNumber;
    query.reverse = this.state.sort === 'DESC';

    query.sort = transformAnalyticsSorting(this.state.currentSorted, device);

    if (
      typeof newProps !== 'undefined' &&
      typeof newProps.pageSize !== 'undefined'
    ) {
      query.items = pageSize || 100;
    }

    if (
      typeof newProps !== 'undefined' &&
      typeof newProps.pageNumber !== 'undefined'
    ) {
      query.page = pageNumber || 1;
    }

    this.setState({ device });

    return query;
  }

  refresh(newProps, pageSize, pageNumber) {
    uiActions.isLoading();

    const query = this.query(newProps, pageSize, pageNumber);

    getBrands(query, response => {
      this.setState(
        {
          rows: response,
          query,
          showLoadingAnimation: false,
        },
        () => {
          this.setScrollToTop();
          this.getTotals(query);
          this.calculateSticky();
          uiActions.isLoaded();
        },
      );
    });

    getBrandCount(query, response => {
      trendifyActions.updateCount(response);
    });

    filterActions.filtersUpdated();
  }

  sort(sorting) {
    const query = clone(this.state.query);
    query.startDate = this.props.range[0].format('YYYY-MM-DD');
    query.endDate = this.props.range[1].format('YYYY-MM-DD');
    grid.sort(sorting, query, getBrands, this, this.state.device);
  }

  onBrandDetail(brandName, device, e) {
    e.preventDefault();
    const content = () => (
      <TrendifyBrandDetail
        brand={brandName}
        device={device}
        hasSegmentifyListing={hasSegmentifyListing()}
        hasTrendifySources={hasTrendifySources()}
      />
    );
    uiActions.openModal({ title: brandName, content });
  }

  showSgfData(comp) {
    return hasSegmentifyListing() && comp;
  }

  exportFile(e) {
    e.preventDefault();

    const { range } = this.props;
    const name = `Brand_Report_${range[0].format(
      'MMMM_D_YYYY',
    )}-${range[1].format('MMMM_D_YYYY')}.csv`;

    const content = () => (
      <Export
        name={name}
        query={this.query()}
        endpoint='trendify/brand/export'
      />
    );

    uiActions.openModal({ title: t('Export Brands'), content });
  }

  getTotals(query) {
    uiActions.isLoading();
    this.setState({
      isTotalsLoading: true,
    });
    getBrandTotal(query, response => {
      this.setState(
        {
          totals: parseTotalLineAPI(response, this.state.device),
          isTotalsLoading: false,
        },
        () => {
          uiActions.isLoaded();
        },
      );
    });
  }

  setScrollToTop() {
    const element = this.refs.scrollElement;
    let showButton = !!element;
    if (element) {
      if (
        element.scrollHeight <=
        parseFloat(element.style['max-height'].split('px')[0])
      ) {
        showButton = false;
      }
      if (element.scrollTop <= 100) {
        showButton = false;
      }
    }
    this.setState({
      showScrollToTop: showButton,
    });
  }

  calculateSticky() {
    const element = this.refs.scrollElement;
    if (element) {
      const { maxHeight } = element.style;
      const headerEl = document.querySelector('.sticky-table-header-wrapper')
        .clientHeight;
      document.querySelector('.sticky-table').style.maxHeight = maxHeight;
      document.querySelector(
        '.sticky-table-y-wrapper',
      ).style.maxHeight = `${parseInt(maxHeight.split('px')[0]) -
        headerEl -
        20}px`;

      let addBorderToProducts = document.querySelectorAll(
        '.product-tableColumn--latest',
      );
      addBorderToProducts = [].slice.call(addBorderToProducts);
      addBorderToProducts.forEach(domEl => {
        domEl.parentElement.style.borderRight = '1px solid #ecebf7';
      });

      let addBorderToSgf = document.querySelectorAll(
        '.sgf-tableColumn--latest',
      );
      addBorderToSgf = [].slice.call(addBorderToSgf);
      addBorderToSgf.forEach(domEl => {
        domEl.parentElement.style.borderRight = '1px solid #ecebf7';
      });

      let addBorderToConversion = document.querySelectorAll(
        '.conversion-tableColumn-latest',
      );
      addBorderToConversion = [].slice.call(addBorderToConversion);
      addBorderToConversion.forEach(domEl => {
        domEl.parentElement.style.borderRight = '1px solid #ecebf7';
      });

      let addBorderToHeader = document.querySelectorAll('.add-border');
      addBorderToHeader = [].slice.call(addBorderToHeader);
      addBorderToHeader.forEach(domEl => {
        domEl.parentElement.style.borderRight = '1px solid #ecebf7';
      });

      /// Main Header Borders
      const columnsLength = 3;
      const conversionLength = columnsLength + 9;
      const sgfLength = conversionLength + (hasSegmentifyListing() ? 9 : 0);
      const sourcesLength =
        sgfLength + (!hasTrendifySources() ? 7 : hasTrendifySources().length);
      const allLength = sourcesLength + 1;

      const headers = document.querySelectorAll(
        '.sticky-table-header .sticky-table-row:first-child .sticky-table-cell',
      );
      const mainHeaders = document.querySelectorAll('.tfy-table-header');

      const intervals = [];
      intervals.push({ start: 0, end: columnsLength });
      intervals.push({ start: columnsLength, end: conversionLength });
      if (hasSegmentifyListing()) {
        intervals.push({ start: conversionLength, end: sgfLength });
        intervals.push({ start: sgfLength, end: sourcesLength });
      } else {
        intervals.push({ start: conversionLength, end: sourcesLength });
      }
      const widths = [];
      intervals.forEach((interval, intervalInd) => {
        let startWidth = 0;
        let endWidth = 0;
        [].forEach.call(headers, function(item, index) {
          // :) hooray `item` can be used here
          if (index < interval.start) {
            startWidth += item.clientWidth;
          }
          if (index < interval.end) {
            endWidth += item.clientWidth;
          }
        });
        if (intervalInd !== 0) {
          widths.push({
            totalWidth:
              endWidth - startWidth + widths[intervalInd - 1].totalWidth,
            width:
              widths[intervalInd + -1].totalWidth + (endWidth - startWidth) / 2,
          });
        } else {
          widths.push({
            totalWidth: endWidth - startWidth,
            width: (endWidth - startWidth) / 2,
          });
        }
      });

      [].forEach.call(mainHeaders, function(mainHeader, mainHeaderInd) {
        if (widths[mainHeaderInd]) {
          mainHeader.style.left = `${parseInt(
            widths[mainHeaderInd].width - mainHeader.clientWidth / 2,
          )}px`;
        }
      });

      document.querySelector(
        '.sticky-table-header .sticky-table-row',
      ).style.height = '50px';
    }
  }

  onTableScroll(event) {
    let showButton = true;
    if (event.scrollTop <= this.state.maxHeight) {
      showButton = false;
    }
    if (event.scrollTop <= 100) {
      showButton = false;
    }
    if (showButton !== this.state.showScrollToTop) {
      this.setState({
        showScrollToTop: showButton,
      });
    }
  }

  render() {
    if (
      this.props.user.displayName &&
      !hasModulePrivilege(false, 'TRENDUSER')
    ) {
      browserHistory.push('/');
    }
    const dummyRowsToShow = [];
    for (let i = 0; i < 15; i++) {
      dummyRowsToShow.push(dummyRow);
    }
    const rows = this.state.showLoadingAnimation
      ? dummyRowsToShow
      : this.state.rows;
    let tfyLinks = config.menus.primary.trendify.children;
    if (this.props.router.location.pathname.indexOf('/ext/') !== -1) {
      tfyLinks = config.menus.primary.trendify.extChildren;
    }
    let finalHeaders = brandTable.header;
    if (!hasSegmentifyListing()) {
      finalHeaders = brandTable.headerWithoutSgf;
    }
    let sourceStartIndex = 0;
    finalHeaders.forEach((heading, headingIndex) => {
      if (heading.isSourceStart) {
        if (sourceStartIndex === 0) {
          sourceStartIndex = headingIndex;
        }
      }
    });
    if (sourceStartIndex && hasTrendifySources()) {
      hasTrendifySources().forEach((source, sourceIndex) => {
        finalHeaders[sourceStartIndex] = {
          // icon: source.icon,
          icon: source.icon,
          sortable: true,
          sort: `stats->>'${this.state.device}${source.field}Click'`,
          tooltip: source.label,
          alignment: 'right',
          svg: true,
        };
        sourceStartIndex++;
        if (sourceIndex === 0) {
          finalHeaders[sourceStartIndex].className = 'cell-group';
        }
      });
    }
    const tableHeight = window.innerHeight - 195;
    const categoriesLength = 3;
    const conversionLength = categoriesLength + 9;
    const sgfLength = conversionLength + (hasSegmentifyListing() ? 9 : 0);
    const sourcesLength =
      sgfLength + (hasTrendifySources() ? hasTrendifySources().length : 7);
    const allLength = sourcesLength + 1;
    return (
      <div className='segmentify-analytics segmentify-analytics--trendify'>
        {!hasTrendify() && (
          <h2 className='page-title'>
            <span className='for-screenreader-only'>{t('Trendify')}</span>
          </h2>
        )}
        {hasTrendify() ? (
          <div>
            <div ref='pageFilters'>
              <Filters
                limitRange='months'
                visibleUpdate={false}
                toggleAnalyticsFilters={this.toggleAnalyticsFilters.bind(this)}
                analyticsFilters
                exportFile={this.exportFile.bind(this)}
                analyticsType='brands'
              >
                <BrandFilters
                  showTrendifyFilters={this.state.showTrendifyFilters}
                  toggleAnalyticsFilters={this.toggleAnalyticsFilters.bind(
                    this,
                  )}
                />
              </Filters>
            </div>

            {this.state.showLoadingAnimation || this.state.rows.length > 0 ? (
              <div
                className={
                  this.state.showLoadingAnimation
                    ? 'analytics-details analytics-placeholder'
                    : 'analytics-details'
                }
                style={{ maxHeight: `${tableHeight}px`, overflow: 'hidden' }}
                ref='scrollElement'
              >
                <StickyTable
                  stickyColumnCount={0}
                  stickyHeaderCount={2}
                  onScroll={this.onTableScroll}
                >
                  <Row>
                    {[...Array(allLength)].map((item, index) => {
                      let givenClass =
                        index === categoriesLength - 1 ||
                        index === conversionLength - 1 ||
                        index === sgfLength - 1
                          ? 'add-border'
                          : '';
                      if (index === 1) {
                        givenClass += ' tfy-table-header analytics-product';
                      } else if (index === 2) {
                        givenClass += ' tfy-table-header analytics-conversion';
                      } else if (index === 3 && hasSegmentifyListing()) {
                        givenClass += ' tfy-table-header analytics-segmentify';
                      } else if (index === 4) {
                        givenClass += ' tfy-table-header analytics-sources';
                      }
                      if (this.state.showLoadingAnimation) {
                        givenClass += ' --loading';
                      }
                      return (
                        <Cell>
                          <div className={givenClass}>
                            {index === 1 &&
                              `${t('Brand')} (${this.props.count})`}
                            {index === 2 && t('Website')}
                            {hasSegmentifyListing() &&
                              index === 3 &&
                              t('Segmentify')}
                            {((hasSegmentifyListing() && index === 4) ||
                              (!hasSegmentifyListing() && index === 4)) &&
                              t('Sources')}
                          </div>
                        </Cell>
                      );
                    })}
                  </Row>
                  {rows.length > 0 && (
                    <TableHeadings
                      currentSorted={this.state.currentSorted}
                      direction={this.state.sort}
                      heading={finalHeaders}
                      isSticky
                      onSort={this.sort}
                    />
                  )}
                  {this.state.rows.length
                    ? this.state.rows.map((item, index) => {
                        const order =
                          index +
                          1 +
                          this.props.pageSize * (this.props.pageNumber - 1);
                        const statPrefix = this.state.device;

                        return (
                          <Row>
                            <Cell>
                              <div className='brand-tableColumn'>{order}</div>
                            </Cell>
                            <Cell>
                              <div className='brand-tableColumn'>
                                {decodeHtml(item.name)}
                              </div>
                            </Cell>
                            <Cell>
                              <div className='brand-tableColumn product-tableColumn--latest'>
                                {localeString(
                                  item.stats[`${statPrefix}BrandVisit`],
                                ) || '-'}
                              </div>
                            </Cell>

                            <Cell>
                              <div className='conversion-tableColumn'>
                                {localeString(
                                  item.stats[`${statPrefix}SiteView`],
                                ) || '-'}
                              </div>
                            </Cell>
                            <Cell>
                              <div className='conversion-tableColumn'>
                                {localeString(
                                  item.stats[`${statPrefix}SiteBasket`],
                                ) || '-'}
                              </div>
                            </Cell>
                            <Cell>
                              <div className='conversion-tableColumn'>
                                {localeString(
                                  item.stats[`${statPrefix}SiteUBasket`],
                                ) || '-'}
                              </div>
                            </Cell>
                            <Cell>
                              <div className='conversion-tableColumn'>
                                {calculateRatio(
                                  item.stats[`${statPrefix}SiteBasket`],
                                  item.stats[`${statPrefix}SiteView`],
                                )}
                                %
                              </div>
                            </Cell>
                            <Cell>
                              <div className='conversion-tableColumn'>
                                <Currency
                                  currency={
                                    item.stats[`${statPrefix}SiteBamount`] || 0
                                  }
                                />
                              </div>
                            </Cell>
                            <Cell>
                              <div className='conversion-tableColumn'>
                                {localeString(
                                  item.stats[`${statPrefix}SitePurchase`],
                                ) || '-'}
                              </div>
                            </Cell>
                            <Cell>
                              <div className='conversion-tableColumn'>
                                {localeString(
                                  item.stats[`${statPrefix}SiteUPurchase`],
                                ) || '-'}
                              </div>
                            </Cell>
                            <Cell>
                              <div className='conversion-tableColumn'>
                                {calculateRatio(
                                  item.stats[`${statPrefix}SitePurchase`],
                                  item.stats[`${statPrefix}SiteView`],
                                )}
                                %
                              </div>
                            </Cell>
                            <Cell>
                              <div className='conversion-tableColumn conversion-tableColumn-latest'>
                                <Currency
                                  currency={
                                    item.stats[`${statPrefix}SiteAmount`] || 0
                                  }
                                />
                              </div>
                            </Cell>

                            {this.showSgfData(
                              <Cell>
                                <div>
                                  {localeString(
                                    item.stats[`${statPrefix}SgmClick`],
                                  ) || '-'}
                                </div>
                              </Cell>,
                            )}
                            {this.showSgfData(
                              <Cell>
                                <div>
                                  {localeString(
                                    item.stats[`${statPrefix}SgmBasket`],
                                  ) || '-'}
                                </div>
                              </Cell>,
                            )}
                            {this.showSgfData(
                              <Cell>
                                <div>
                                  {localeString(
                                    item.stats[`${statPrefix}SgmUBasket`],
                                  ) || '-'}
                                </div>
                              </Cell>,
                            )}
                            {this.showSgfData(
                              <Cell>
                                <div>
                                  {calculateRatio(
                                    item.stats[`${statPrefix}SgmBasket`],
                                    item.stats[`${statPrefix}SgmClick`],
                                  )}
                                  %
                                </div>
                              </Cell>,
                            )}
                            {this.showSgfData(
                              <Cell>
                                <div>
                                  <Currency
                                    currency={
                                      item.stats[`${statPrefix}SgmBamount`] || 0
                                    }
                                  />
                                </div>
                              </Cell>,
                            )}
                            {this.showSgfData(
                              <Cell>
                                <div>
                                  {localeString(
                                    item.stats[`${statPrefix}SgmPurchase`],
                                  ) || '-'}
                                </div>
                              </Cell>,
                            )}
                            {this.showSgfData(
                              <Cell>
                                <div>
                                  {localeString(
                                    item.stats[`${statPrefix}SgmUPurchase`],
                                  ) || '-'}
                                </div>
                              </Cell>,
                            )}
                            {this.showSgfData(
                              <Cell>
                                <div>
                                  {calculateRatio(
                                    item.stats[`${statPrefix}SgmPurchase`],
                                    item.stats[`${statPrefix}SgmClick`],
                                  )}
                                  %
                                </div>
                              </Cell>,
                            )}
                            {this.showSgfData(
                              <Cell>
                                <div className='sgf-tableColumn--latest'>
                                  <Currency
                                    currency={
                                      item.stats[`${statPrefix}SgmAmount`] || 0
                                    }
                                  />
                                </div>
                              </Cell>,
                            )}

                            {!hasTrendifySources() && (
                              <>
                                <Cell>
                                  <div>
                                    {localeString(
                                      item.stats[`${statPrefix}InternalClick`],
                                    ) || '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {localeString(
                                      item.stats[`${statPrefix}SocialClick`],
                                    ) || '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {localeString(
                                      item.stats[`${statPrefix}SearchClick`],
                                    ) || '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {localeString(
                                      item.stats[`${statPrefix}PaidClick`],
                                    ) || '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {localeString(
                                      item.stats[`${statPrefix}EmailClick`],
                                    ) || '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div>
                                    {localeString(
                                      item.stats[`${statPrefix}ListingClick`],
                                    ) || '-'}
                                  </div>
                                </Cell>
                                <Cell>
                                  <div className='sources-tableColumn-latest'>
                                    {localeString(
                                      item.stats[`${statPrefix}OtherClick`],
                                    ) || '-'}
                                  </div>
                                </Cell>
                              </>
                            )}

                            {hasTrendifySources() &&
                              hasTrendifySources().map(source => (
                                <Cell>
                                  <div>
                                    {localeString(
                                      item.stats[
                                        `${statPrefix + source.field}Click`
                                      ],
                                    ) || '-'}
                                  </div>
                                </Cell>
                              ))}
                            <Cell>
                              <div>
                                <nav className='account-nav action-nav'>
                                  <Icon name='menu' />
                                  <ul className='action-nav-popup'>
                                    <li>
                                      <a
                                        className='action-nav-popup-item'
                                        onClick={this.onBrandDetail.bind(
                                          this,
                                          item.name,
                                          statPrefix,
                                        )}
                                        style={{ color: '#fff' }}
                                      >
                                        <Icon name='calendar' /> {t('Compare')}
                                      </a>
                                    </li>
                                  </ul>
                                </nav>
                              </div>
                            </Cell>
                          </Row>
                        );
                      })
                    : [...Array(30)].map(() => {
                        return (
                          <Row>
                            {[...Array(allLength)].map(() => {
                              return (
                                <Cell>
                                  <div className='category-tableColumn'>
                                    TFY
                                  </div>
                                </Cell>
                              );
                            })}
                          </Row>
                        );
                      })}
                </StickyTable>
              </div>
            ) : (
              <div className='data-content-message'>
                <div className='data-content-message-text'>
                  <i className='icon-info' />
                  {t('There are no records to show.')}
                </div>
              </div>
            )}
            {rows.length > 0 && (
              <TotalsLine
                totals={this.state.totals}
                totalsLabels={brandTable.totals}
                isLoading={this.state.isTotalsLoading}
                selectedCurrency={this.state.selectedCurrency}
              />
            )}
            {this.state.showScrollToTop && <ScrollUpButtonAnalytics />}
          </div>
        ) : (
          <div className='promo'>
            <p className='promo-text'>{t('TrendifyPromo')}</p>
            <a
              href='https://www.segmentify.com/solutions/real-time-conversion-analytics/#trendify'
              target='_blank'
              className='secondary-action'
              rel='noreferrer'
            >
              {t('Discover')}
            </a>
          </div>
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  ui: store.ui.ui,
  pageSize: store.filters.pageSize,
  pageNumber: store.filters.pageNumber,
  count: store.filters.count,
  update: store.filters.update,
  reset: store.filters.reset,
});

export default connect(mapStatesToProps)(Brands);
