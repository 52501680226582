/**
 * Created by mehmetyurtar on 12/07/2017.
 */
import React from "react";

class FieldTextArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <path
          fill={this.state.color}
          d="M8,0H1v4h1l0.72361-1.44721C2.893,2.214,3.23926,2,3.61803,2H7v11.27924
	c0,0.43043-0.27543,0.81257-0.68377,0.94868L4,15v1h4h4v-1l-2.31623-0.77208C9.27543,14.09181,9,13.70967,9,13.27924V2h3.38197
	c0.37877,0,0.72504,0.214,0.89443,0.55279L14,4h1V0H8z"
        />
      </svg>
    );
  }
}

export default FieldTextArea;
