import React from 'react';
import PropTypes from 'prop-types';

import * as modals from '../constants/datamaps/push-modals';
import { units, icons } from '../constants/ui';
import { t, openModal } from '../system/ui';
import { toSlug, localeString, toLocaleStringDigits } from '../system/string';
import Icon from './icon';
import Currency from './currency';
import Tooltip from './tooltip';

const PushCard = ({ title, type, data, pageStats }) => {
  const className = `stat-card-push stat-card stat-${type}`;
  const mainTitle = title;
  let cardTitle = false;

  if (mainTitle === 'Widget') {
    cardTitle = 'Web Push';
  } else if (mainTitle === 'Revenue') {
    cardTitle = 'Email Conversion';
  } else {
    cardTitle = mainTitle;
  }

  const showModal = modal => {
    const modalContent = modals[modal];
    let modalTitle = false;

    if (modal === 'Widget') {
      modalTitle = 'Email';
    } else if (modal === 'Revenue') {
      modalTitle = 'Email Conversion';
    } else {
      modalTitle = modal;
    }

    openModal({
      title: modalTitle,
      content: modalContent,
    });
  };

  return (
    <div className={className}>
      <h3 className='stat-card-title'>{t(cardTitle)}</h3>

      {data.modal && typeof modals[data.modal] !== 'undefined' && (
        <a className='modal-trigger' onClick={() => showModal(data.modal)}>
          <Icon name='info' />
        </a>
      )}

      <ol className='stat-card-list'>
        {Object.keys(data.stats).map(item => {
          const singleStat = data.stats[item];
          const statKey = `stat-${item}-${
            singleStat.name
              ? singleStat.name.toLowerCase().replace(' ', '-')
              : 'no-name'
          }`;
          let row;

          if (singleStat.name === 'Spam') return null;

          if (!singleStat) return null;

          if (mainTitle !== 'Widget') {
            if (singleStat.hasOwnProperty('total')) {
              row = (
                <li key={statKey} className='stat-total'>
                  <span
                    className='stat-item-count'
                    title={toLocaleStringDigits(singleStat.count)}
                  >
                    {singleStat.hasOwnProperty('currency') ? (
                      <Currency currency={singleStat.count} />
                    ) : (
                      localeString(singleStat.count)
                    )}
                  </span>
                  <span className='stat-item-unit'>
                    {type === 'unique' ? t(singleStat.name) : t(units[type])}
                  </span>
                </li>
              );
            } else {
              row = (
                <li
                  key={statKey}
                  className={`${toSlug(
                    singleStat.name,
                  )} stat-item stat-item-${toSlug(mainTitle)}`}
                >
                  <span className='stat-item-name'>
                    {typeof icons[type] !== 'undefined' &&
                      typeof icons[type][item] !== 'undefined' && (
                        <Icon name={icons[type][item]} />
                      )}
                    {mainTitle === 'Widget' ||
                    mainTitle === 'Interaction' ||
                    mainTitle === 'Basket'
                      ? t(cardTitle + singleStat.name)
                      : t(singleStat.name)}
                    {singleStat.tooltip && (
                      <span className='stat-tooltip'>
                        <Tooltip
                          content={singleStat.tooltip}
                          alignment='top'
                          newTooltip
                        >
                          <Icon name='info' />
                        </Tooltip>
                      </span>
                    )}
                  </span>
                  {singleStat.hasOwnProperty('count') === true && (
                    <span
                      className='stat-item-count'
                      title={toLocaleStringDigits(singleStat.count)}
                    >
                      {singleStat.hasOwnProperty('currency') ? (
                        <Currency currency={singleStat.count} />
                      ) : (
                        localeString(singleStat.count)
                      )}
                      {singleStat.name === 'Purchases' &&
                        mainTitle === 'Revenue' && (
                          <span> ({localeString(singleStat.ratio)}%)</span>
                        )}
                      {singleStat.name === 'Purchase Items' &&
                        mainTitle === 'Revenue' && (
                          <span> ({localeString(singleStat.ratio)}%)</span>
                        )}
                    </span>
                  )}
                  <span className='stat-item-unit'>{t(units[type])}</span>
                  {singleStat.hasOwnProperty('ratio') === true &&
                    singleStat.name !== 'Purchases' &&
                    singleStat.name !== 'Purchase Items' && (
                      <span className='stat-item-percentage'>
                        {localeString(singleStat.ratio)}%
                      </span>
                    )}
                </li>
              );
            }
          } else if (singleStat.name === 'Actual View') {
            row = (
              <li key={statKey} className='stat-total'>
                <span
                  className='stat-item-count'
                  title={toLocaleStringDigits(singleStat.count)}
                >
                  {singleStat.hasOwnProperty('currency') ? (
                    <Currency currency={singleStat.count} />
                  ) : (
                    localeString(singleStat.count)
                  )}
                </span>
                <span className='stat-item-unit'>
                  {type === 'unique'
                    ? t(singleStat.name)
                    : t(units[type]) === 'views'
                    ? 'views'
                    : t(units[type])}
                </span>
              </li>
            );
          } else {
            row = (
              <li key={statKey} className='stat-item'>
                <span className='stat-item-name'>
                  {typeof icons[type] !== 'undefined' &&
                    typeof icons[type][item] !== 'undefined' && (
                      <Icon name={icons[type][item]} />
                    )}
                  {mainTitle === 'Widget' && singleStat.name === 'Total View'
                    ? t('Sent')
                    : t('View Rate')}
                </span>
                {singleStat.hasOwnProperty('count') === true && (
                  <span
                    className='stat-item-count'
                    title={toLocaleStringDigits(singleStat.count)}
                  >
                    {singleStat.hasOwnProperty('currency') ? (
                      <Currency currency={singleStat.count} />
                    ) : (
                      localeString(singleStat.count)
                    )}
                  </span>
                )}
                <span className='stat-item-unit'>{t(units[type])}</span>
                {singleStat.hasOwnProperty('ratio') === true && (
                  <span className='stat-item-percentage'>
                    {localeString(singleStat.ratio)}%
                  </span>
                )}
              </li>
            );
          }

          return row;
        })}
        {type === 'purchase' && (
          <li className='stat-item'>
            <span className='stat-item-name'>{t('Success Page View')}</span>
            <span className='stat-item-count'>
              {pageStats && pageStats.page.stats.other.checkoutCount}
            </span>
          </li>
        )}
      </ol>
    </div>
  );
};

PushCard.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.shape({
    stats: PropTypes.shape({
      name: PropTypes.string,
      count: PropTypes.number,
      tooltip: PropTypes.string,
      ratio: PropTypes.number,
    }),
    modal: PropTypes.string,
  }),
  pageStats: PropTypes.shape({
    page: PropTypes.shape({
      stats: PropTypes.shape({
        other: PropTypes.shape({ checkoutCount: PropTypes.number }),
      }),
    }),
  }),
};

export default PushCard;
