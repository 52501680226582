import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const Returned = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 79 24'
      fill='none'
    >
      <rect width='79' height='24' rx='5' fill='#FFEACA' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.178 20C16.5963 20 20.178 16.4183 20.178 12C20.178 7.58172 16.5963 4 12.178 4C7.7597 4 4.17798 7.58172 4.17798 12C4.17798 16.4183 7.7597 20 12.178 20ZM10.7255 14.884C10.8818 14.7262 10.882 14.4701 10.7259 14.312L8.74438 12.3053H12.924L13.1025 12.3075C13.8001 12.325 14.3057 12.4482 14.7523 12.6897C15.2041 12.934 15.5562 13.2901 15.7978 13.7469C16.0566 14.236 16.178 14.7954 16.178 15.5955C16.178 15.8189 16.3571 16 16.578 16C16.7989 16 16.978 15.8189 16.978 15.5955C16.978 14.6693 16.8283 13.9799 16.5033 13.3654C16.1871 12.7676 15.7208 12.2961 15.1296 11.9763L14.9981 11.9087C14.4232 11.6269 13.7746 11.4964 12.924 11.4964H8.74171L10.7257 9.49043C10.8819 9.33246 10.8819 9.07637 10.7257 8.91844C10.5694 8.7605 10.3162 8.76053 10.16 8.91849L7.4951 11.6136C7.33899 11.7715 7.33894 12.0274 7.49494 12.1854L10.1598 14.8836C10.3159 15.0417 10.5692 15.0419 10.7255 14.884Z'
        fill='#FBA72A'
      />
      <path
        d='M25.178 7.63199H29.27C30.214 7.63199 30.922 7.83599 31.394 8.24399C31.866 8.65199 32.102 9.21999 32.102 9.94799C32.102 10.356 32.042 10.696 31.922 10.968C31.802 11.232 31.662 11.448 31.502 11.616C31.342 11.776 31.182 11.892 31.022 11.964C30.87 12.036 30.758 12.084 30.686 12.108V12.132C30.814 12.148 30.95 12.188 31.094 12.252C31.246 12.316 31.386 12.42 31.514 12.564C31.642 12.7 31.746 12.88 31.826 13.104C31.914 13.328 31.958 13.608 31.958 13.944C31.958 14.448 31.994 14.908 32.066 15.324C32.146 15.732 32.266 16.024 32.426 16.2H30.818C30.706 16.016 30.638 15.812 30.614 15.588C30.598 15.364 30.59 15.148 30.59 14.94C30.59 14.548 30.566 14.212 30.518 13.932C30.47 13.644 30.382 13.408 30.254 13.224C30.126 13.032 29.95 12.892 29.726 12.804C29.51 12.716 29.23 12.672 28.886 12.672H26.678V16.2H25.178V7.63199ZM26.678 11.532H29.138C29.618 11.532 29.982 11.42 30.23 11.196C30.478 10.964 30.602 10.62 30.602 10.164C30.602 9.89199 30.562 9.67199 30.482 9.50399C30.402 9.32799 30.29 9.19199 30.146 9.09599C30.01 8.99999 29.85 8.93599 29.666 8.90399C29.49 8.87199 29.306 8.85599 29.114 8.85599H26.678V11.532Z'
        fill='#FBA72A'
      />
      <path
        d='M37.6952 12.552C37.6792 12.336 37.6312 12.128 37.5512 11.928C37.4792 11.728 37.3752 11.556 37.2392 11.412C37.1112 11.26 36.9512 11.14 36.7592 11.052C36.5752 10.956 36.3672 10.908 36.1352 10.908C35.8952 10.908 35.6752 10.952 35.4752 11.04C35.2832 11.12 35.1152 11.236 34.9712 11.388C34.8352 11.532 34.7232 11.704 34.6352 11.904C34.5552 12.104 34.5112 12.32 34.5032 12.552H37.6952ZM34.5032 13.452C34.5032 13.692 34.5352 13.924 34.5992 14.148C34.6712 14.372 34.7752 14.568 34.9112 14.736C35.0472 14.904 35.2192 15.04 35.4272 15.144C35.6352 15.24 35.8832 15.288 36.1712 15.288C36.5712 15.288 36.8912 15.204 37.1312 15.036C37.3792 14.86 37.5632 14.6 37.6832 14.256H38.9792C38.9072 14.592 38.7832 14.892 38.6072 15.156C38.4312 15.42 38.2192 15.644 37.9712 15.828C37.7232 16.004 37.4432 16.136 37.1312 16.224C36.8272 16.32 36.5072 16.368 36.1712 16.368C35.6832 16.368 35.2512 16.288 34.8752 16.128C34.4992 15.968 34.1792 15.744 33.9152 15.456C33.6592 15.168 33.4632 14.824 33.3272 14.424C33.1992 14.024 33.1352 13.584 33.1352 13.104C33.1352 12.664 33.2032 12.248 33.3392 11.856C33.4832 11.456 33.6832 11.108 33.9392 10.812C34.2032 10.508 34.5192 10.268 34.8872 10.092C35.2552 9.91599 35.6712 9.82799 36.1352 9.82799C36.6232 9.82799 37.0592 9.93199 37.4432 10.14C37.8352 10.34 38.1592 10.608 38.4152 10.944C38.6712 11.28 38.8552 11.668 38.9672 12.108C39.0872 12.54 39.1192 12.988 39.0632 13.452H34.5032Z'
        fill='#FBA72A'
      />
      <path
        d='M39.4792 9.99599H40.5112V8.13599H41.8792V9.99599H43.1152V11.016H41.8792V14.328C41.8792 14.472 41.8832 14.596 41.8912 14.7C41.9072 14.804 41.9352 14.892 41.9752 14.964C42.0232 15.036 42.0912 15.092 42.1792 15.132C42.2672 15.164 42.3872 15.18 42.5392 15.18C42.6352 15.18 42.7312 15.18 42.8272 15.18C42.9232 15.172 43.0192 15.156 43.1152 15.132V16.188C42.9632 16.204 42.8152 16.22 42.6712 16.236C42.5272 16.252 42.3792 16.26 42.2272 16.26C41.8672 16.26 41.5752 16.228 41.3512 16.164C41.1352 16.092 40.9632 15.992 40.8352 15.864C40.7152 15.728 40.6312 15.56 40.5832 15.36C40.5432 15.16 40.5192 14.932 40.5112 14.676V11.016H39.4792V9.99599Z'
        fill='#FBA72A'
      />
      <path
        d='M49.5473 16.2H48.2033V15.336H48.1793C48.0113 15.648 47.7593 15.9 47.4233 16.092C47.0953 16.276 46.7593 16.368 46.4153 16.368C45.5993 16.368 45.0073 16.168 44.6393 15.768C44.2793 15.36 44.0993 14.748 44.0993 13.932V9.99599H45.4673V13.8C45.4673 14.344 45.5713 14.728 45.7793 14.952C45.9873 15.176 46.2793 15.288 46.6553 15.288C46.9433 15.288 47.1833 15.244 47.3753 15.156C47.5673 15.068 47.7233 14.952 47.8433 14.808C47.9633 14.656 48.0473 14.476 48.0953 14.268C48.1513 14.06 48.1793 13.836 48.1793 13.596V9.99599H49.5473V16.2Z'
        fill='#FBA72A'
      />
      <path
        d='M50.9899 9.99599H52.2739V11.196H52.2979C52.3379 11.028 52.4139 10.864 52.5259 10.704C52.6459 10.544 52.7859 10.4 52.9459 10.272C53.1139 10.136 53.2979 10.028 53.4979 9.94799C53.6979 9.86799 53.9019 9.82799 54.1099 9.82799C54.2699 9.82799 54.3779 9.83199 54.4339 9.83999C54.4979 9.84799 54.5619 9.85599 54.6259 9.86399V11.184C54.5299 11.168 54.4299 11.156 54.3259 11.148C54.2299 11.132 54.1339 11.124 54.0379 11.124C53.8059 11.124 53.5859 11.172 53.3779 11.268C53.1779 11.356 53.0019 11.492 52.8499 11.676C52.6979 11.852 52.5779 12.072 52.4899 12.336C52.4019 12.6 52.3579 12.904 52.3579 13.248V16.2H50.9899V9.99599Z'
        fill='#FBA72A'
      />
      <path
        d='M55.4196 9.99599H56.7156V10.908L56.7396 10.932C56.9476 10.588 57.2196 10.32 57.5556 10.128C57.8916 9.92799 58.2636 9.82799 58.6716 9.82799C59.3516 9.82799 59.8876 10.004 60.2796 10.356C60.6716 10.708 60.8676 11.236 60.8676 11.94V16.2H59.4996V12.3C59.4836 11.812 59.3796 11.46 59.1876 11.244C58.9956 11.02 58.6956 10.908 58.2876 10.908C58.0556 10.908 57.8476 10.952 57.6636 11.04C57.4796 11.12 57.3236 11.236 57.1956 11.388C57.0676 11.532 56.9676 11.704 56.8956 11.904C56.8236 12.104 56.7876 12.316 56.7876 12.54V16.2H55.4196V9.99599Z'
        fill='#FBA72A'
      />
      <path
        d='M66.5702 12.552C66.5542 12.336 66.5062 12.128 66.4262 11.928C66.3542 11.728 66.2502 11.556 66.1142 11.412C65.9862 11.26 65.8262 11.14 65.6342 11.052C65.4502 10.956 65.2422 10.908 65.0102 10.908C64.7702 10.908 64.5502 10.952 64.3502 11.04C64.1582 11.12 63.9902 11.236 63.8462 11.388C63.7102 11.532 63.5982 11.704 63.5102 11.904C63.4302 12.104 63.3862 12.32 63.3782 12.552H66.5702ZM63.3782 13.452C63.3782 13.692 63.4102 13.924 63.4742 14.148C63.5462 14.372 63.6502 14.568 63.7862 14.736C63.9222 14.904 64.0942 15.04 64.3022 15.144C64.5102 15.24 64.7582 15.288 65.0462 15.288C65.4462 15.288 65.7662 15.204 66.0062 15.036C66.2542 14.86 66.4382 14.6 66.5582 14.256H67.8542C67.7822 14.592 67.6582 14.892 67.4822 15.156C67.3062 15.42 67.0942 15.644 66.8462 15.828C66.5982 16.004 66.3182 16.136 66.0062 16.224C65.7022 16.32 65.3822 16.368 65.0462 16.368C64.5582 16.368 64.1262 16.288 63.7502 16.128C63.3742 15.968 63.0542 15.744 62.7902 15.456C62.5342 15.168 62.3382 14.824 62.2022 14.424C62.0742 14.024 62.0102 13.584 62.0102 13.104C62.0102 12.664 62.0782 12.248 62.2142 11.856C62.3582 11.456 62.5582 11.108 62.8142 10.812C63.0782 10.508 63.3942 10.268 63.7622 10.092C64.1302 9.91599 64.5462 9.82799 65.0102 9.82799C65.4982 9.82799 65.9342 9.93199 66.3182 10.14C66.7102 10.34 67.0342 10.608 67.2902 10.944C67.5462 11.28 67.7302 11.668 67.8422 12.108C67.9622 12.54 67.9942 12.988 67.9382 13.452H63.3782Z'
        fill='#FBA72A'
      />
      <path
        d='M74.8222 16.2H73.5262V15.36H73.5022C73.3182 15.72 73.0502 15.98 72.6982 16.14C72.3462 16.292 71.9742 16.368 71.5822 16.368C71.0942 16.368 70.6662 16.284 70.2982 16.116C69.9382 15.94 69.6382 15.704 69.3982 15.408C69.1582 15.112 68.9782 14.764 68.8582 14.364C68.7382 13.956 68.6782 13.52 68.6782 13.056C68.6782 12.496 68.7542 12.012 68.9062 11.604C69.0582 11.196 69.2582 10.86 69.5062 10.596C69.7622 10.332 70.0502 10.14 70.3702 10.02C70.6982 9.89199 71.0302 9.82799 71.3662 9.82799C71.5582 9.82799 71.7542 9.84799 71.9542 9.88799C72.1542 9.91999 72.3462 9.97599 72.5302 10.056C72.7142 10.136 72.8822 10.24 73.0342 10.368C73.1942 10.488 73.3262 10.632 73.4302 10.8H73.4542V7.63199H74.8222V16.2ZM70.0462 13.164C70.0462 13.428 70.0782 13.688 70.1422 13.944C70.2142 14.2 70.3182 14.428 70.4542 14.628C70.5982 14.828 70.7782 14.988 70.9942 15.108C71.2102 15.228 71.4662 15.288 71.7622 15.288C72.0662 15.288 72.3262 15.224 72.5422 15.096C72.7662 14.968 72.9462 14.8 73.0822 14.592C73.2262 14.384 73.3302 14.152 73.3942 13.896C73.4662 13.632 73.5022 13.364 73.5022 13.092C73.5022 12.404 73.3462 11.868 73.0342 11.484C72.7302 11.1 72.3142 10.908 71.7862 10.908C71.4662 10.908 71.1942 10.976 70.9702 11.112C70.7542 11.24 70.5742 11.412 70.4302 11.628C70.2942 11.836 70.1942 12.076 70.1302 12.348C70.0742 12.612 70.0462 12.884 70.0462 13.164Z'
        fill='#FBA72A'
      />
    </svg>
  );
};

Returned.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

Returned.defaultProps = {
  width: '79px',
  height: '24px',
};

export default Returned;
