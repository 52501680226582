/**
 * Created by mehmetyurtar on 11/4/17.
 */
import React, { Component } from "react";
import { searchProducts } from "../../modules/product/ajax";
import Icon from "../icon";
import { t } from "../../system/ui";

class ProductSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      selectedProducts: [],
      searchedProduct: ""
    };

    this.onSelectProduct = this.onSelectProduct.bind(this);
    this.onDeselectProduct = this.onDeselectProduct.bind(this);
    this.onSearchProduct = this.onSearchProduct.bind(this);
  }

  onSelectProduct(product) {
    this.setState({
      selectedProducts: this.state.selectedProducts.concat([product])
    });
  }

  onDeselectProduct(product) {
    this.setState({
      selectedProducts: this.state.selectedProducts.filter(selectedProduct => {
        return selectedProduct.productId !== product.productId;
      })
    });
  }

  onSearchProduct(e) {
    e.preventDefault();
    let inputVal = e.target.value;
    this.setState({
      searchedProduct: inputVal
    });
    if (inputVal.length > 3) {
      searchProducts(inputVal, response => {
        this.setState({
          products: response
        });
      });
    } else {
      this.setState({
        products: []
      });
    }
  }

  render() {
    let allProducts = this.state.products.filter(product => {
      return this.props.existingProducts.indexOf(product.productId) === -1;
    });
    let selectedProducts = [];
    this.state.selectedProducts.forEach(product => {
      selectedProducts.push(product.productId);
    });
    return (
      <div>
        <div className="products--multiselect--holder">
          <div className="multiselect--items__selected">
            <ul>
              {this.state.selectedProducts.map(product => {
                return (
                  <li
                    key={product.productId}
                    className="multiselect--item__selected"
                  >
                    <i
                      className="icon-cross"
                      onClick={this.onDeselectProduct.bind(this, product)}
                    />
                    <span>
                      {product.name.length > 10
                        ? product.name.substring(0, 10) + "..."
                        : product.name}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="multiselect--items--search">
            <input
              type="text"
              placeholder={t("Search Products")}
              onChange={this.onSearchProduct}
              value={this.state.searchedProduct}
            />
            <Icon name="magnify" />
          </div>
          <ul className="multiselect--items--list">
            {allProducts.map(product => {
              if (selectedProducts.indexOf(product.productId) === -1) {
                return (
                  <li
                    key={product.productId}
                    className="multiselect--item"
                    onClick={this.onSelectProduct.bind(this, product)}
                  >
                    <span style={{ fontWeight: "bold", display: "block" }}>
                      {product.productId}
                    </span>
                    {product.name}
                  </li>
                );
              }
              return false;
            })}
          </ul>
        </div>
        {this.state.selectedProducts.length > 0 && (
          <div className="buttons buttons--product-select">
            <button
              className="tertiary-action"
              onClick={this.props.addProducts.bind(
                this,
                this.state.selectedProducts
              )}
            >
              <i className="icon-plus" />
              {t("Add Selected Products")}
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default ProductSelection;
