import React, { useState } from 'react';
import './WofExpandableCardWrapper.scss';
import { t } from '../../../system/ui';
/**
 * @name WofExpandableCard component
 * @description WofExpandableCardWrapper component
 * @param {JSX.Element | JSX.Element[]} children
 * @param {String} cardTitle - title of the card
 * @param {object} customButtonField - custom button field
 * @param isHideVisible
 * @param {JSX.Element} customButtonField.button - custom button
 * @param {Function} customButtonField.onClick - custom button click handler
 * @returns {JSX.Element}
 * @constructor
 */

const WofExpandableCardWrapper = ({
  cardTitle,
  children,
  customButtonField = null,
  isHideVisible = true,
}) => {
  const [isClick, setIsClick] = useState(true);

  const handleEvents = () => {
    setIsClick(!isClick);
  };

  return (
    <>
      <div className='WofExpCardWrapper'>
        <div className='WofExpCardWrapper__head'>
          <div className='WofExpCardWrapper__left'> {cardTitle}</div>
          <div className='WofExpCardWrapper__right'>
            {customButtonField && (
              <div
                className='WofExpCardWrapper__right--custom-button'
                onClick={customButtonField.onClick}
              >
                {customButtonField.button}
              </div>
            )}
            {isHideVisible && (
              <div
                className='WofExpCardWrapper__right--hide'
                onClick={() => handleEvents()}
              >
                <span> {isClick ? t('Hide') : t('Show')}</span>
                <i className={isClick ? 'arrow up' : 'arrow down'} />
              </div>
            )}
          </div>
        </div>
        {isClick && (
          <div className='WofExpCardWrapper__content'>{children}</div>
        )}
      </div>
    </>
  );
};

export default WofExpandableCardWrapper;
