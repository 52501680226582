import { decodeHtml } from '../../../system/string';

export const convertToPositiveInt = (value, defaultValue) => {
  if (value === '' || value === undefined || value === null) {
    // If value is empty, return empty value
    return value;
  }

  let returnValue = defaultValue;

  if (typeof value === 'number') {
    returnValue = value;
  }

  if (typeof value === 'string') {
    returnValue = parseInt(value, 10);
  }

  if (returnValue < defaultValue) {
    returnValue = defaultValue;
  }

  return returnValue;
};

export const removeEmptyCriteriaAndGroups = criteriaData => {
  const tempCriteriaData = { ...criteriaData };
  tempCriteriaData.criteriaGroups = tempCriteriaData.criteriaGroups
    .map(group => {
      const tempGroup = { ...group };
      tempGroup.criterias = group.criterias
        .map(criteria => {
          if (!criteria.criteriaType) {
            return null;
          }

          return criteria;
        })
        .filter(criteria => criteria);

      if (tempGroup.criterias.length === 0) {
        return null;
      }

      return tempGroup;
    })
    .filter(group => group);

  return tempCriteriaData;
};

export const operatorObjToArray = operatorObj => {
  const operatorArray = [];
  Object.keys(operatorObj).forEach(key => {
    operatorArray.push({
      value: decodeHtml(operatorObj[key].value),
      title: decodeHtml(operatorObj[key].name),
      label: decodeHtml(operatorObj[key].name),
    });
  });
  return operatorArray;
};
