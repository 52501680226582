export const gridState = {
  sort: {
    accessor: '',
    isSorting: false,
  },
};

export const gridReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'sort':
      return {
        sort: {
          accessor: payload.accessor,
          isSorting: payload.isSorting,
        },
      };
    default:
      return state;
  }
};
