import React, { useCallback, useContext } from 'react';
import { browserHistory } from 'react-router';
import './NavigationController.scss';
import Icons from '../../icons';
import { Context as PaginationContext } from '../store/paginationProvider';

const NavigationController = ({
  linkToRoute,
  text,
  action,
  disableIcon = false,
  iconName = 'plusCircle',
  iconColor = '#47607B',
  iconWidth = '16',
  iconHeight = '16',
  isExternalButton = false,
}) => {
  const {
    state: { campaignPathname },
  } = useContext(PaginationContext);

  const actionSwitchHandler = useCallback(() => {
    if (linkToRoute) {
      browserHistory.push({
        pathname: linkToRoute,
        state: {
          campaignPathname,
        },
      });
    } else if (action) {
      action();
    }
  }, [linkToRoute, action, campaignPathname]);
  return (
    <>
      <a
        className={`create-button ${isExternalButton && 'external-button'}`}
        onClick={actionSwitchHandler}
      >
        {!disableIcon && (
          <div className='nav-controller-icon'>
            <Icons
              name={iconName}
              color={iconColor}
              width={iconWidth}
              height={iconHeight}
            />
          </div>
        )}
        <div className='nav-controller-text'>
          {isExternalButton ? text : <b>{text}</b>}
        </div>
      </a>
    </>
  );
};

export default NavigationController;
