import React, { useContext } from 'react';
import { t } from '../../../../../../system/ui';
import { InputCardWrapper } from '../../../../../../components/gamification/module-exports';
import { ColorPicker, TextField } from '../../../../../../components/fields';
import {
  SFYChildWrapper,
  SFYImageInput,
} from '../../../../../../components/sfy-components/module-exports';
import { Context as GamificationContext } from '../../../store/gamificationProvider';

const StyleTab = () => {
  const {
    state: { activeWOFDetails },
    editActiveWOFDetails,
  } = useContext(GamificationContext);

  const editCampaign = (fieldKey, fieldValue) => {
    editActiveWOFDetails({ [fieldKey]: fieldValue });
  };

  const onChangeBGIcon = iconVal => {
    editCampaign('bgImage', iconVal);
  };

  const onChangePointerIcon = iconVal => {
    editCampaign('pointerImage', iconVal);
  };

  return (
    <SFYChildWrapper type='flexHalfColGap2'>
      <InputCardWrapper
        type='withIcon'
        cardTitle='CTA Button'
        iconType='wofCta'
      >
        <div className='CTAButton'>
          <div className='CTAButton__content'>
            <div className='CTAButton__textField'>
              <span className='CTAButton__title'>Content of Button</span>
              <TextField
                placeholder='Ex : Spin Now!'
                value={activeWOFDetails?.ctaButtonContent || ''}
                onChange={e => editCampaign('ctaButtonContent', e.target.value)}
              />
            </div>
            <div className='CTAButton__colorPicker'>
              <span className='CTAButton__colorPicker__title'>
                Color of Button
              </span>
              <div className='CTAButton__colorPicker__body'>
                <div className='CTAButton__colorPicker__col1'>
                  <span className='ColorOfBase__color__title'>
                    Background Color
                  </span>
                  <ColorPicker
                    className='item-field large'
                    value={activeWOFDetails?.ctaButtonColor || '#FFFFFF'}
                    onChange={color => editCampaign('ctaButtonColor', color)}
                    disableTooltip
                  />
                </div>
                <div className='CTAButton__colorPicker__col2'>
                  <span className='ColorOfBase__color__title'>Text Color</span>
                  <ColorPicker
                    className='item-field large'
                    value={activeWOFDetails?.ctaButtonTextColor}
                    onChange={color =>
                      editCampaign('ctaButtonTextColor', color)
                    }
                    disableTooltip
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </InputCardWrapper>

      <InputCardWrapper type='withIcon' cardTitle='Base' iconType='wofWheel'>
        <div className='ColorOfBase'>
          <span className='ColorOfBase__title'>Color of Base</span>
          <div className='ColorOfBase__color'>
            <span className='ColorOfBase__color__title'>Select Color</span>
            <ColorPicker
              className='item-field large'
              value={activeWOFDetails?.baseColor}
              onChange={color => editCampaign('baseColor', color)}
              disableTooltip
            />
          </div>
        </div>
      </InputCardWrapper>

      <InputCardWrapper
        type='withIcon'
        cardTitle='Pop-Up Background'
        iconType='wofWheel'
        iconColor='#979797'
        rectColor='#D31116'
      >
        <div className='PopupBackGround'>
          <div className='BannerStyleImg__checkbox'>
            <label className='CountSettings__control'>
              <input
                id='wof-overlay'
                key='wof-overlay'
                name='activate'
                type='checkbox'
                checked={
                  activeWOFDetails?.overlay === 'true' ||
                  activeWOFDetails?.overlay === true ||
                  false
                }
                value={
                  activeWOFDetails?.overlay === 'true' ||
                  activeWOFDetails?.overlay === true ||
                  false
                }
                onChange={overlay => {
                  editCampaign('overlay', overlay.target.checked);
                }}
              />
              {t('Add Overlay')}
            </label>
          </div>
          <span className='PopupBackGround__title'>
            Background Image Upload
          </span>
          <div className='PopupBackGround__img'>
            <small>
              You can upgrade your wheel of fortune’s base with adding an image
              behind on your wheel of fortune.
            </small>
            <SFYImageInput
              key='wof-bg-image'
              fetchedImage={activeWOFDetails?.bgImage || undefined}
              iconName='wof-bg-image'
              stateSetter={onChangeBGIcon}
              type='web'
              withoutCardSpace
              endpointType='engagement'
            />
          </div>
        </div>
      </InputCardWrapper>

      <InputCardWrapper type='withIcon' cardTitle='Spin' iconType='wofSpin'>
        <div className='SpinWheel'>
          <div className='SpinWheel__content'>
            <span className='SpinWheel__title'>Color of Pointer</span>
            <div className='SpinWheel__colorPicker'>
              <span className='SpinWheel__colorPicker__title'>
                Select Color
              </span>
              <ColorPicker
                className='item-field large'
                value={activeWOFDetails?.pointerColor}
                onChange={color => editCampaign('pointerColor', color)}
                disableTooltip
              />
            </div>
            <div className='SpinWheel__img'>
              <span className='SpinWheel__img__title'>
                Center Point Image Upload
              </span>
              <small>
                You can upgrade your wheel of fortune’s style with adding an
                image in the center of wheel base.
              </small>
              <SFYImageInput
                key='wof-pointer-image'
                iconName='wof-pointer-image'
                fetchedImage={activeWOFDetails?.pointerImage || undefined}
                stateSetter={onChangePointerIcon}
                type='mobil'
                withoutCardSpace
                endpointType='engagement'
              />
            </div>
          </div>
        </div>
      </InputCardWrapper>
    </SFYChildWrapper>
  );
};

export default StyleTab;
