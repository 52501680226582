import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const WofWheel = ({ width = '91px', height = '73px', color, rectColor }) => {
  const [state, setState] = useState({
    width: '',
    height: '',
    color: '',
    rectColor: '',
  });

  const setStyles = () => {
    setState({
      width,
      height,
      color,
      rectColor,
    });
  };

  useEffect(() => {
    setStyles();
  }, [width, height, color, rectColor]);

  return (
    <svg
      width={state.width}
      height={state.height}
      viewBox='0 0 91 73'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='90'
        height='72'
        rx='9.5'
        fill='white'
        stroke={rectColor}
      />
      <rect x='25' y='59' width='39' height='5' rx='2.5' fill={state.color} />
      <rect x='41' y='44' width='5' height='15' fill={state.color} />
      <path
        d='M59 29C59 37.2555 52.0896 44 43.5 44C34.9104 44 28 37.2555 28 29C28 20.7445 34.9104 14 43.5 14C52.0896 14 59 20.7445 59 29Z'
        fill='#D9D9D9'
        stroke={state.color}
        strokeWidth='2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M43.5002 40.7114C49.9562 40.7114 55.1899 35.4777 55.1899 29.0217C55.1899 22.5657 49.9562 17.3321 43.5002 17.3321C37.0442 17.3321 31.8106 22.5657 31.8106 29.0217C31.8106 35.4777 37.0442 40.7114 43.5002 40.7114ZM43.5002 41C50.1156 41 55.4785 35.6371 55.4785 29.0217C55.4785 22.4063 50.1156 17.0435 43.5002 17.0435C36.8848 17.0435 31.522 22.4063 31.522 29.0217C31.522 35.6371 36.8848 41 43.5002 41Z'
        fill='#979797'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M43.5004 33.1079C45.7572 33.1079 47.5866 31.2785 47.5866 29.0217C47.5866 26.765 45.7572 24.9356 43.5004 24.9356C41.2437 24.9356 39.4143 26.765 39.4143 29.0217C39.4143 31.2785 41.2437 33.1079 43.5004 33.1079ZM43.5004 33.4348C45.9377 33.4348 47.9135 31.459 47.9135 29.0217C47.9135 26.5845 45.9377 24.6087 43.5004 24.6087C41.0632 24.6087 39.0874 26.5845 39.0874 29.0217C39.0874 31.459 41.0632 33.4348 43.5004 33.4348Z'
        fill='#979797'
      />
      <path
        d='M43.5 18.3044L39.087 12L47.9131 12L43.5 18.3044Z'
        fill='#979797'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M40.1849 25.8696L35.3047 21.0261L35.4679 20.8261L40.3482 25.6696L40.1849 25.8696Z'
        fill='#979797'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M46.6523 25.6696L51.5326 20.8261L51.6958 21.0261L46.8156 25.8696L46.6523 25.6696Z'
        fill='#979797'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M51.5193 37.2174L46.6523 32.3504L46.8288 32.1739L51.6958 37.0409L51.5193 37.2174Z'
        fill='#979797'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.3047 37.0431L40.165 32.1739L40.3482 32.3482L35.4879 37.2174L35.3047 37.0431Z'
        fill='#979797'
      />
    </svg>
  );
};

WofWheel.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  rectColor: PropTypes.string,
};

WofWheel.defaultProps = {
  width: '91px',
  height: '73px',
  color: '#D31116',
  rectColor: '#D1D1D1',
};

export default WofWheel;
