/**
 * @author Bilal Cinarli
 */

"use strict";

import { uiActions, filterActions, trendifyActions } from "../../actions";
import transformAnalyticsSorting from "../../modules/transform/analyticsTransforms";

export const reset = (query, rowFn, rowCountFn, context, callback) => {
  uiActions.isLoading();
  rowFn(query, response => {
    context.setState({
      rows: response,
      query: query,
      currentSorted: query.sort,
      showLoadingAnimation: false
    });

    filterActions.filtersReset();
    uiActions.isLoaded();
  });

  if (rowCountFn) {
    rowCountFn(query, response => {
      trendifyActions.updateCount(response);
    });
  }
  if (callback) {
    callback(query);
  }
};

export const sort = (sorting, query, rowFn, context, device) => {
  uiActions.isLoading();

  query.sort = transformAnalyticsSorting(sorting, device);
  query.reverse =
    context.state.currentSorted === sorting ? !query.reverse : query.reverse;

  context.setState({
    query: query,
    currentSorted: sorting,
    sort: query.reverse ? "DESC" : "ASC",
    showLoadingAnimation: false
  });

  rowFn(query, response => {
    context.setState({
      rows: response
    });

    uiActions.isLoaded();
  });
};

export default {
  reset,
  sort
};
