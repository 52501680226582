/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { t } from "../system/ui";
import { setTitle } from "../system/document";

import user from "../modules/auth/user";

import Header from "../components/layout/header";
import Sidebar from "../components/layout/sidebar";
import Preloader from "../components/layout/preloader";

class NotFound extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: false
    };
  }

  componentDidMount() {
    setTitle(t("Page Not Found"));

    user.getUser(loggedUser => {
      this.props.dispatch({
        type: "USER_IS_LOGGED",
        payload: {
          user: loggedUser
        }
      });

      this.setState({
        logged: true
      });

      localStorage.setItem("restUrl", this.props.user.account.dataCenterUrl);
    });
  }

  render() {
    let pageClass = classNames("page-wrap not-found");

    return (
      <div id="wrapper" className={pageClass}>
        <Header />

        <Preloader />

        <div id="content" className="page-content">
          <Sidebar />

          <div id="main" className="page-main">
            <div className="no-integration-found">
              <div className="no-integration-icon" />
              <p className="no-report-info">{t("Page Not Found")}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapStatesToProps = store => ({
  ui: store.ui.ui,
  pageClass: store.ui.pageClass,
  user: store.user.user,
  integrations: store.user.integrations
});

export default connect(MapStatesToProps)(NotFound);
