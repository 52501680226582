/*eslint react/display-name: "off"*/

"use strict";

import React from "react";

export const external_es = {
  "Segmentify Performance": "Personalization rendimiento",
  NoActiveCategoryCampaigns: () => {
    return (
      <div>
        <p>
          Category pages are landing so much visitors but bounce rates in
          e-commerce from category pages are also so high. A solution provided
          by Personalization for high bounce rates from category pages is using
          “Trending in Category” product recommendation widgets and include the
          popular products in category selected based on the behaviour of the
          current visitor.
        </p>
        <p>
          Please select a widget type below to{" "}
          <strong>add new category page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveSearchCampaigns: () => {
    return (
      <div>
        <p>
          Visitors became frustrated when they can't finding no product when
          they searched keywords on online stores, and bounce rates on search
          pages are much higher than other pages. With Personalization, you can offer
          variety of products inside widgets on search result page to decrease
          bounce rates and gaining back leaving visitors.
        </p>
        <p>
          Please select a widget type below to{" "}
          <strong>add new search page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActive404Campaigns: () => {
    return (
      <div>
        <p>
          When you removed a url from your store, this pages actually doesn't
          remove from search engines and other external links. When visitors
          clicks this url, they will face a 404 page without no navigation, and
          they will definitely bounce from this page. With Personalization, you can
          offer variety of products inside widgets on 404 page to decrease
          bounce rates and gaining back leaving visitors.
        </p>
        <p>
          Please select a widget type below to{" "}
          <strong>add new 404 page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  NoActiveCustomCampaigns: () => {
    return (
      <div>
        <p>
          When you need a campaign on a page that are not predefined in
          Personalization, you can define custom page campaigns like user account
          page, checkout page etc.
        </p>
        <p>
          Please select a widget type below to{" "}
          <strong>add new custom page recommendation widget.</strong>
        </p>
      </div>
    );
  },
  Segmentify: "Personalization",
  "Number Of Clicks By Segmentify Recommendation Widgets":
    "Número de clics mediante los widgets de Personalization de recomendaciones",
  "Number Of Add To Basket Operations By Segmentify Recommendation Widgets":
    "Número de eventos 'Agregar a la cesta' mediante los widgets de Personalization de recomendaciones",
  "Number Of Purchases By Segmentify Recommendation Widgets":
    "Número de compras mediante los widgets de Personalization de recomendaciones",
  "Conversion Rate (Purchase/Click)": "Tasa de conversión (compra/clic)",
  "Amount Of Purchases By Segmentify Recommendation Widgets":
    "Importe de compras mediante los widgets de Personalization de recomendaciones",
  "Customer Removal Tool Disclaimer": () => {
    return (
      <p>
        All historical personal information related to given email addresses
        will be deleted within the next 24 hours. When the removal process is
        finished, you will receive an email.
        <br />
        Please note that the removal process is permanent and irreversible, upon
        its completion Personalization can't recover any data.
      </p>
    );
  },
  "integration-script-info":
    "Es muy fácil comenzar a usar las soluciones de Personalization. Todo lo que tiene que hacer es agregar la siguiente línea de código JS a todas sus páginas, en el final del bloque HEAD.",
  IntegrationZendDesk: () => {
    return (
      <span>
        You might follow the steps in{" "}
        <a href="https://segmentify.zendesk.com/hc/en-us" target="_blank">
          Personalization Customer success Center
        </a>{" "}
        for integration.
      </span>
    );
  },
  StartChat: () => {
    return (
      <span>
        Personalization Customer Success team is ready to do the integration for you,{" "}
        <a>start a chat</a>
      </span>
    );
  },
  Personalization: "Personalización",
  PrivacyPolicyCta:
    "Do you need to adjust your cookie and privacy policy for using Personalization?",
  ShopifyWarning:
    "Ya ha instalado la aplicación Personalization. Le recomendamos utilizar la aplicación Personalization en el panel de administración de su tienda Shopify.",
  PrestashopWarning:
    "Ya ha instalado el complemento de Personalization. Le recomendamos utilizar  el complemento de Personalization en su panel de administración de Prestashop.",
  MagentoWarning:
    "Ya ha instalado el complemento de Personalization. Le recomendamos utilizar  el complemento de Personalization en su panel de administración de Magento.",
  "Segmentify Graph": "Gráfico de Personalization",
  "Custom Segmentify integration requires two important values.":
    "La integración personalizada de Personalization requiere dos valores importantes.",
  "The percentage of products that have been purchased after being clicked in a Segmentify Recommendation widget.":
    "The percentage of products that have been purchased after being clicked in a Personalization Recommendation widget.",
  "You should make at least one selection from Segmentify Intelligent or Static algorithms":
    "You should make at least one selection from Personalization Intelligent or Static algorithms",
  Widget_Desc:
    "Create personal recommendation campaigns with Personalization's intelligent and static algorithms.",
  Promotion_Desc:
    "Personalize the Special Offers you display in your website with Personalization's Promotion campaigns.",
  "Overall Segmentify Performance":"Overall Personalization Performance",
  "Revenue Generated by Segmentify": "Generated by Personalization",
  "Segmentify’s Revenue Contribution": "of all revenue via Personalization",
  "Products Sold via Segmentify":"products via Personalization",
  "Select from Segmentify Intelligent Recommendation Types to create your personalized recommendation campaigns" :
      "Select from Personalization Intelligent Recommendation Types to create your personalized recommendation campaigns",
  "Select from Segmentify Static Recommendation Types to create your personalized recommendation campaigns"
      : "Select from Personalization Static Recommendation Types to create your personalized recommendation campaigns",
  "Select from Segmentify Promotion Types to create your personalized recommendation campaigns"
      : "Select from Personalization Promotion Types to create your personalized recommendation campaigns",
  "Before_Search_Input" : "Attract your customers with Personalization's intelligent algorithms even before they make a search."
};

export default external_es;
