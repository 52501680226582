import classNames from 'classnames';
import { store } from '../../store';
import { isAbsoluteUrl } from '../../system/string';
import { uiActions } from '../../actions';

import {
  hasAnalytics,
  hasModule,
  isSwitchAccount,
  hasModulePrivilege,
  hasDynamicBundle,
  hasOnlyDynamicBundles,
} from '../auth/user';

export const trendifyIntegrationInfo = (isInsights, returnValue) => {
  const trendifyEnabled = hasAnalytics('trendify');
  uiActions.addPageClass(
    classNames('', {
      trendify: !isInsights,
      'no-integration': !trendifyEnabled,
      insights: isInsights,
    }),
  );
  if (returnValue) {
    return trendifyEnabled;
  }
};

export const bannerifyIntegrationInfo = () => {
  const trendifyEnabled = hasAnalytics('trendify');

  uiActions.addPageClass(
    classNames('trendify', {
      'sidebar-is-hidden': trendifyEnabled,
      'no-integration': !trendifyEnabled,
    }),
  );
};

export const emailIntegrationInfo = () => {
  const mailEnabled = hasModule('email');

  uiActions.addPageClass(
    classNames('email', {
      'no-integration': !mailEnabled,
    }),
  );
};
export const searchIntegrationInfo = () => {
  const searchEnabled = hasModule('search');

  uiActions.addPageClass(
    classNames('search', {
      'no-integration': !searchEnabled,
    }),
  );
};

export const dynamicBundleInfo = () => {
  const dynamicBundleEnabled = hasDynamicBundle();
  uiActions.addPageClass(
    classNames('dynamic_bundle', {
      'no-integration': !dynamicBundleEnabled,
    }),
  );
};

export const btIntegrationInfo = () => {
  const enabled = hasModule('personalization');
  uiActions.addPageClass(
    classNames('bt', {
      'no-integration': !enabled,
    }),
  );
};

export const audienceIntegrationInfo = () => {
  const enabled =
    hasModule('ruleBasedSegmentation') || hasModule('segmentation');
  uiActions.addPageClass(
    classNames('audience', {
      'no-integration': !enabled,
    }),
  );
};

export const pushIntegrationInfo = () => {
  const pushEnabled = hasModule('push');

  uiActions.addPageClass(
    classNames('push', {
      'no-integration': !pushEnabled,
    }),
  );
};

export const recommendationIntegrationInfo = () => {
  const recoHidden = !hasModulePrivilege(false, 'RECOUSER');
  uiActions.addPageClass(
    classNames('dashboard', {
      'no-integration': recoHidden,
    }),
  );
};

export const recoModuleIntegrationInfo = () => {
  const recoEnabled = hasModule('recommendation') || hasOnlyDynamicBundles();

  uiActions.addPageClass(
    classNames('recommendation', {
      'no-integration': !recoEnabled,
    }),
  );
};

export const addSalesClasses = () => {
  uiActions.addPageClass(
    classNames('sales', {
      'sidebar-is-hidden': false,
      'no-integration': false,
    }),
  );
};
export const productUrl = url => {
  const account = isSwitchAccount() || store.getState().user.user.account;

  const domain =
    account.domain.indexOf('http') < 0
      ? `http://${account.domain}`
      : account.domain;

  if (!url) {
    return '-';
  }
  if (isAbsoluteUrl(url)) {
    return url;
  }
  if (url.indexOf('/') !== 0) {
    return `${domain}/${url}`;
  }
  return domain + url;
};
