/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconCalendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 490 490"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            fill={this.state.color}
            className="svg-path"
            d="M464.38,66.25H403.13V35.63a30.63,30.63,0,0,0-61.25,0V66.25H280.63V35.63a30.63,30.63,0,0,0-61.25,0V66.25H158.13V35.63a30.63,30.63,0,0,0-61.25,0V66.25H35.63A30.6,30.6,0,0,0,5,96.88v367.5A30.6,30.6,0,0,0,35.63,495H464.38A30.6,30.6,0,0,0,495,464.38V96.88A30.6,30.6,0,0,0,464.38,66.25Zm-30.62,367.5H66.25V219.38h367.5V433.75Z"
            transform="translate(-5 -5)"
          />
        </g>
      </svg>
    );
  }
}

export default IconCalendar;
