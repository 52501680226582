import React from 'react';
import { connect } from 'react-redux';
import Datetime from 'react-datetime';
import classNames from 'classnames';
import moment from 'moment';
import 'moment-timezone';

import { dateFormat, timeFormat } from '../../constants/ui';
import * as classes from '../../constants/ui.classes';
import user from '../../modules/auth/user';
import { t } from '../../system/ui';
import Icon from '../icon';

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidMount() {
    const account = user.getAccount();
    if (account.timeZone) moment.tz.setDefault(account.timeZone);
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.checkPastDate &&
      newProps.validate === true &&
      typeof newProps.value !== 'undefined'
    ) {
      this.validate(newProps);
    }
  }

  validate(newProps) {
    const { input } = this.refs;
    let hasAnyError = false;

    if (this.props.checkPastDate) {
      const currentDate = newProps.unformattedDate;
      let now = new Date();
      now = now.getTime();
      if (currentDate < now) {
        hasAnyError = 'Please specify a time before current time.';
      }
    }

    this.setState({
      hasError: hasAnyError,
    });
  }

  render() {
    const labelClass = classNames(
      classes.fieldItem,
      'is-calendar',
      this.props.className,
      { 'has-icon': this.props.icon },
    );
    const _dateFormat = this.props.dateFormat !== false ? dateFormat : false;
    const _timeFormat = this.props.timeFormat !== false ? timeFormat : false;
    const _viewMode = this.props.viewMode || 'days';
    const _timeConstraints = this.props.timeConstraints || {};

    return (
      <label className={labelClass}>
        {this.props.label && (
          <span className={classes.fieldLabel}>{t(this.props.label)}</span>
        )}
        {this.props.icon && <Icon name='calendar' />}
        <Datetime
          defaultValue={this.props.defaultValue}
          value={this.props.value}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          isValidDate={this.props.isValidDate}
          dateFormat={_dateFormat}
          timeFormat={_timeFormat}
          inputProps={
            this.props.disabled
              ? {
                  disabled: true,
                  readOnly: true,
                  placeholder: this.props.placeholder
                    ? this.props.placeholder
                    : '',
                }
              : {
                  readOnly: true,
                  placeholder: this.props.placeholder
                    ? this.props.placeholder
                    : '',
                }
          }
          closeOnSelect
          viewMode={_viewMode}
          timeConstraints={_timeConstraints}
        />
        {this.state.hasError && (
          <span
            style={{ fontSize: '12px', color: '#e87352' }}
            className='has-error'
          >
            {t(this.state.hasError)}
          </span>
        )}
      </label>
    );
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate,
});
export default connect(mapStatesToProps)(Calendar);
