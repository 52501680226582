/**
 * @author Bilal Cinarli
 */

import React from "react";
import PropTypes from "prop-types";

class Highlight extends React.Component {
  render() {
    let result = this.props.text,
      originalText = this.props.text,
      highlighted = <span className="matched">{this.props.keyword}</span>,
      text = this.props.text.toLowerCase(),
      keyword = this.props.keyword.toLowerCase(),
      match = text.search(keyword);

    if (keyword.length >= 2 && match >= 0) {
      if (match === 0) {
        result = (
          <span>
            {highlighted}
            {originalText.substr(keyword.length)}
          </span>
        );
      } else {
        result = (
          <span>
            {originalText.substr(0, match)}
            {highlighted}
            {originalText.substr(match + keyword.length)}
          </span>
        );
      }
    }

    return <span>{result}</span>;
  }
}

Highlight.propTypes = {
  keyword: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default Highlight;
