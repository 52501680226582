import React, { Component } from 'react';
import ColorPick from 'rc-color-picker';
import classNames from 'classnames';
import Tooltip from '../tooltip';
import { t } from '../../system/ui';

const addAlpha = (color, opacity) => {
  const hexColor = color.replace('#', '').toUpperCase();
  let hexOpacity = Math.round((opacity / 100) * 255)
    .toString(16)
    .toUpperCase();

  if (hexOpacity.length === 1) {
    hexOpacity = `0${hexOpacity}`;
  }

  return `#${hexColor}${hexOpacity.toString(16).toUpperCase()}`;
};

const separateAlpha = color => {
  const hexColor = color.replace('#', '').toUpperCase();
  const hexOpacity = hexColor.substring(hexColor.length - 2);
  const hexColorNoAlpha = `#${hexColor.substring(0, hexColor.length - 2)}`;
  const opacity = Math.round((parseInt(hexOpacity, 16) / 255) * 100);

  return {
    hexColor: hexColorNoAlpha,
    opacity,
  };
};

class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      color: '',
      alpha: 100,
      showPalette: false,
    };

    this.updateColorPicker = this.updateColorPicker.bind(this);
    this.updateColor = this.updateColor.bind(this);
    this.updateAlpha = this.updateAlpha.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.updateColorPicker(this.props.value);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.value !== newProps.value) {
      this.updateColorPicker(newProps.value);
    }
  }

  onChange(colors) {
    if (this.props.onChange) {
      let exportColor = colors.color;
      if (this.props.type === 'withAlpha') {
        exportColor = addAlpha(colors.color, colors.alpha);
      }
      this.props.onChange(exportColor);
    }

    this.setState({ color: colors.color, alpha: colors.alpha });
  }

  updateColorPicker(colorValue) {
    let propsColor = colorValue;
    let propsAlpha = 100;
    if (this.props.type === 'withAlpha' && colorValue.length === 9) {
      const colorObject = separateAlpha(colorValue);
      propsColor = colorObject.hexColor;
      propsAlpha = colorObject.opacity;
    }

    this.updateColor(propsColor);
    this.updateAlpha(propsAlpha);
  }

  updateColor(hex) {
    this.setState({ color: hex });
  }

  updateAlpha(alpha) {
    this.setState({ alpha });
  }

  toggle() {
    this.setState({
      showPalette: !this.state.showPalette,
    });
  }

  render() {
    const title = this.props.labelDefault
      ? t('styles_' + this.props.labelDefault)
      : this.props.tooltipText;
    return (
      <span
        className={classNames('item is-color-picker', this.props.className)}
      >
        <span className='item-trigger'>
          <span className='item-label'>
            {this.props.label}
            {!this.props.disableTooltip && (
              <span className='field-tooltip-icon'>
                <Tooltip content={title} alignment='left'>
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              </span>
            )}
          </span>
          <div className='item-color-select'>
            <span className='item-color-preview'>
              <ColorPick
                color={this.state.color}
                alpha={this.state.alpha}
                onChange={this.onChange}
              />
            </span>
            <span className='text-field'>
              {this.props.type === 'withAlpha'
                ? addAlpha(this.state.color, this.state.alpha)
                : this.state.color?.toUpperCase()}
            </span>
          </div>
        </span>
      </span>
    );
  }
}

export default ColorPicker;
