import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { uiActions, userActions } from '../../../../actions';
import { AccountUpdate } from '../../../../constants/datamaps/notifications';
import { getUser, updateAccount } from '../../../../modules/auth/user';
import { setTitle } from '../../../../system/document';
import { clone } from '../../../../system/object';
import { t } from '../../../../system/ui';
import { fieldMapper } from './utils';

import NavigationTab from '../ components/navigation-tab';
import Form from '../../../../components/form';

const AccountSettings = props => {
  const [state, setState] = useState({
    fields: [],
    buttons: [
      {
        type: 'tertiary',
        label: 'Update',
        submitAnimation: true,
      },
    ],
    error: null,
    ui: 'isLoaded',
  });
  const forms = useRef(null);

  const { isLoading, isLoaded, showNotification } = uiActions;

  const updateForm = account => {
    setState(prev => ({
      ...prev,
      fields: fieldMapper(account),
    }));
  };

  const updateFormHandler = e => {
    e.preventDefault();
    isLoading();
    setState(prev => ({
      ...prev,
      ui: 'isSubmitting',
    }));

    const isSwitchedUser = !!props.switchedUser.username;
    const account = isSwitchedUser
      ? clone(props.switchedUser.account)
      : clone(props.user.account);

    if (typeof account.contact === 'undefined') {
      account.contact = {};
    }
    account.domain = forms.current.querySelector('[name="domain"]').value;
    account.contact.person = forms.current.querySelector(
      '[name="person"]',
    ).value;
    account.contact.phone = forms.current.querySelector('[name="phone"]').value;
    account.contact.address = forms.current.querySelector(
      '[name="address"]',
    ).value;

    updateAccount(account, response => {
      if (response.accountId) {
        const user = isSwitchedUser
          ? clone(props.switchedUser)
          : clone(props.user);

        user.account = response;

        if (isSwitchedUser) {
          userActions.updateSwitchUser(user);
        } else {
          userActions.updateUserAccount(user);
        }
      }

      isLoaded();
      setState(prev => ({
        ...prev,
        ui: 'isLoaded',
      }));
      showNotification({
        content: AccountUpdate,
      });
    });
  };

  useEffect(() => {
    setTitle(t('Account Settings'));
    isLoading();

    getUser(response => {
      updateForm(response.account);
      isLoaded();
    });
  }, [props.user]);

  if (props.location.pathname === '/settings/account') {
    return (
      <>
        <NavigationTab />
        <div className='page-forms' ref={forms}>
          <div className='page-content-block one-half'>
            <form id='settings-form' onSubmit={updateFormHandler}>
              <Form
                fields={state.fields}
                buttons={state.buttons}
                ui={state.ui}
                {...props}
              />
            </form>
          </div>
        </div>
      </>
    );
  }
  return <div>{props.children}</div>;
};

const mapStatesToProps = store => ({
  user: store.user.user,
  language: store.ui.language,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(AccountSettings);
