import React, { useState, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getApiKey } from '../../../modules/auth/user';
import { getRestEndPoint } from '../../../system/api';
import { SFYHeader } from '../../../components/sfy-components/module-exports';
import { Context as PaginationContext } from '../../../components/pagination/store/paginationProvider';
import IysStatistics from './statistics';
import SFYPagination from '../../../components/pagination/SFYPagination';
import { GridList } from './dashboard-list';
import { $http } from '../../../system/ajax';
import { schema } from './common/constants/constants';
import { t } from '../../../system/ui';
import { setTitle } from '../../../system/document';

/**
 * @name IysDashBoard
 * @returns JSX.Element - IYS Dashboard
 */

const IysDashBoard = () => {
  const [sortingUrl, setUrlParams] = useState('');

  const {
    state: { pagination, searchQueryString },
    paginationSetter,
    searchQuerySetter,
  } = useContext(PaginationContext);

  const fetchIysDashBoard = async (page, payload, queryStr, sortUrl) => {
    let url = `${getRestEndPoint(
      'iys/dashboard',
    )}?apiKey=${getApiKey()}&pageNo=${page}&pageSize=${payload}`;

    if (queryStr.length > 0) {
      url += `&query=${queryStr}`;
    }

    if (sortUrl.length > 0) {
      url += sortUrl;
    }

    const response = await $http(url).get();
    return await response;
  };

  const { data } = useQuery({
    queryKey: [
      'iys',
      sortingUrl,
      searchQueryString,
      pagination.pageNumber,
      pagination.payloadSize,
    ],
    queryFn: () =>
      fetchIysDashBoard(
        pagination.pageNumber,
        pagination.payloadSize,
        searchQueryString,
        sortingUrl,
      ),
    retry: 1,
    keepPreviousData: true,
    onSuccess: result => {
      paginationSetter({
        totalRecords: result.totalUserSize,
        pageNumber: result.pageNo,
        payloadSize: result.pageSize,
      });
    },
  });

  useEffect(() => {
    setTitle(`${t('IYS Dashboard')} ${t('Reports')}`);
  }, []);

  // We can assume by this point that `isSuccess === true`
  return (
    <>
      <SFYHeader
        pageTitle='IYS Dashboard'
        childrenAttr={{ subTitle: 'Last 7 Days' }}
      />
      <>
        <IysStatistics iysData={data?.iysAnalyticStats} />
        <SFYPagination
          location={window.location}
          pagination={pagination}
          stateSetter={searchQuerySetter}
          state={searchQueryString}
          paginationSetter={paginationSetter}
          navigationController={false}
          analyticsType='customers'
        />
        <GridList
          data={data?.permissionList}
          schema={schema}
          setUrlParams={setUrlParams}
        />
      </>
    </>
  );
};

export default IysDashBoard;
