import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const WofSpin = ({ width = '91px', height = '73px' }) => {
  const [state, setState] = useState({
    width: '',
    height: '',
  });

  const setStyles = () => {
    setState({
      width,
      height,
    });
  };

  useEffect(() => {
    setStyles();
  }, [width, height]);

  return (
    <svg
      width={state.width}
      height={state.height}
      viewBox='0 0 91 73'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='90'
        height='72'
        rx='9.5'
        fill='white'
        stroke='#D1D1D1'
      />
      <rect x='16' y='12' width='60' height='48' rx='3' fill='#F0F0F0' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.5599 50.7901C52.9331 50.7901 58.9102 44.813 58.9102 37.4398C58.9102 30.0666 52.9331 24.0894 45.5599 24.0894C38.1867 24.0894 32.2095 30.0666 32.2095 37.4398C32.2095 44.813 38.1867 50.7901 45.5599 50.7901ZM45.5599 51.1198C53.1151 51.1198 59.2399 44.995 59.2399 37.4398C59.2399 29.8845 53.1151 23.7598 45.5599 23.7598C38.0046 23.7598 31.8799 29.8845 31.8799 37.4398C31.8799 44.995 38.0046 51.1198 45.5599 51.1198Z'
        fill='#7A7A7C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.56 42.1066C48.1373 42.1066 50.2267 40.0172 50.2267 37.4399C50.2267 34.8626 48.1373 32.7732 45.56 32.7732C42.9827 32.7732 40.8934 34.8626 40.8934 37.4399C40.8934 40.0172 42.9827 42.1066 45.56 42.1066ZM45.56 42.4799C48.3435 42.4799 50.6 40.2234 50.6 37.4399C50.6 34.6564 48.3435 32.3999 45.56 32.3999C42.7765 32.3999 40.52 34.6564 40.52 37.4399C40.52 40.2234 42.7765 42.4799 45.56 42.4799Z'
        fill='#D31116'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M45.56 53.6752C54.5265 53.6752 61.7953 46.4064 61.7953 37.4399C61.7953 28.4734 54.5265 21.2046 45.56 21.2046C36.5935 21.2046 29.3247 28.4734 29.3247 37.4399C29.3247 46.4064 36.5935 53.6752 45.56 53.6752ZM45.56 53.9999C54.7058 53.9999 62.12 46.5857 62.12 37.4399C62.12 28.294 54.7058 20.8799 45.56 20.8799C36.4142 20.8799 29 28.294 29 37.4399C29 46.5857 36.4142 53.9999 45.56 53.9999Z'
        fill='#7A7A7C'
      />
      <path
        d='M45.5601 25.2002L40.5201 18.0002L50.6001 18.0002L45.5601 25.2002Z'
        fill='#D31116'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M41.7738 33.8401L36.2002 28.3085L36.3866 28.0801L41.9602 33.6117L41.7738 33.8401Z'
        fill='#7A7A7C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.1602 33.6117L54.7337 28.0801L54.9202 28.3085L49.3466 33.8401L49.1602 33.6117Z'
        fill='#7A7A7C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M54.7186 46.8L49.1602 41.2416L49.3617 41.04L54.9202 46.5985L54.7186 46.8Z'
        fill='#7A7A7C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.2002 46.601L41.751 41.04L41.9602 41.2391L36.4094 46.8L36.2002 46.601Z'
        fill='#7A7A7C'
      />
    </svg>
  );
};

WofSpin.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default WofSpin;
