import React, { useContext, useEffect, useState } from 'react';
import './SFYCardBundleCategory.scss';
import Select from 'react-select';
import { t } from '../../../../system/ui';
import { searchCategories } from '../../../../modules/category/ajax';
import { decodeHtml } from '../../../../system/string';
import { Attributes, ErrorText, SFYFilterButton } from '../../module-exports';
import { Context as ShopTheLookContext } from '../../../../views/dynamic-bundle/shop-the-look/store/shopTheLookProvider';
import Icons from '../../../icons';

const SFYCardBundleCategory = ({
  bundleIndex,
  bundleDetail,
  setFilterParams,
  filterParams,
  setBGColorClassName,
}) => {
  const { editSTLBundle } = useContext(ShopTheLookContext);

  const filterTypes = [
    'size',
    'label',
    'color',
    'gender',
    'priceRange',
    'stock',
    'custom',
    'brand',
  ];

  const [selectedCategory, setSelectedCategory] = useState(
    bundleDetail?.category || '',
  );
  const [categories, setCategories] = useState(
    bundleDetail?.category
      ? [
          {
            value: bundleDetail?.category,
            label: bundleDetail?.category,
            title: bundleDetail?.category,
          },
        ]
      : [],
  );
  const [isTouched, setIsTouched] = useState(false);
  const [hideInclude, setHideInclude] = useState(
    !!(
      filterParams?.inclusions &&
      Object.keys(filterParams?.inclusions).length > 0
    ),
  );
  const [hideExclude, setHideExclude] = useState(
    !!(
      filterParams?.exclusions &&
      Object.keys(filterParams?.exclusions).length > 0
    ),
  );

  const onInputChange = value => {
    setIsTouched(true);

    if (value.length > 2) {
      searchCategories(value, response => {
        const categoryOptions = [];
        if (response) {
          response.forEach(category => {
            categoryOptions.push({
              value: decodeHtml(category),
              title: decodeHtml(category),
              label: decodeHtml(category),
            });
          });
        }
        setCategories(categoryOptions);
      });
    } else if (selectedCategory) {
      setCategories([
        {
          value: selectedCategory,
          title: selectedCategory,
          label: selectedCategory,
        },
      ]);
    } else {
      setCategories([]);
    }
  };

  const onChange = selectedValue => {
    if (!selectedValue) {
      setSelectedCategory('');
      setCategories([]);
      return;
    }

    setSelectedCategory(selectedValue.value);
    setCategories([
      {
        value: selectedValue.value,
        title: selectedValue.value,
        label: selectedValue.value,
      },
    ]);

    const categoryBundle = {
      type: 'category',
      index: bundleIndex,
      category: selectedValue.value,
      params: bundleDetail?.params || {},
    };

    editSTLBundle(categoryBundle);
  };

  const toggleFilterAction = filter => {
    switch (filter) {
      case 'inclusions':
        setHideInclude(!hideInclude);
        break;
      case 'exclusions':
        setHideExclude(!hideExclude);
        break;
      default:
    }
  };

  useEffect(() => {
    if (filterParams?.inclusions && !hideInclude) {
      setFilterParams({ ...filterParams, inclusions: [] });
    }
  }, [hideInclude]);

  useEffect(() => {
    if (filterParams?.exclusions && !hideExclude) {
      setFilterParams({ ...filterParams, exclusions: [] });
    }
  }, [hideExclude]);

  useEffect(() => {
    setBGColorClassName('');
    setSelectedCategory(bundleDetail?.category);
    setCategories([
      {
        value: bundleDetail?.category,
        label: bundleDetail?.category,
        title: bundleDetail?.category,
      },
    ]);
    setFilterParams({
      exclusions: bundleDetail.params.exclusions || [],
      inclusions: bundleDetail.params.inclusions || [],
    });

    return () => {
      setHideInclude(bundleDetail.params.inclusions);
      setHideExclude(bundleDetail.params.exclusions);
    };
  }, [bundleDetail.category]);

  // A seperate useEffect hook runs to check inclusions or exclusions array are set to open or closed

  useEffect(() => {
    const {
      params: { inclusions, exclusions },
    } = bundleDetail;

    if (inclusions?.length === 0) setHideInclude(false);

    if (exclusions?.length === 0) setHideExclude(false);
  }, [bundleDetail.params.inclusions, bundleDetail.params.exclusions]);

  return (
    <div className='sfy-card-bundle'>
      <div className='sfy-card-nav'>
        <div className='sfy-card-nav-select'>
          <span className='sfy-card-select-title'>Category:</span>
          <div className='sfy-card-select'>
            <Select
              value={selectedCategory}
              options={categories}
              name='category'
              clearable={false}
              searchable
              onChange={e => onChange(e)}
              onInputChange={onInputChange}
              className={`bundle-select one-whole ${!selectedCategory &&
                isTouched &&
                'has-error'}`}
              openOnClick={false}
              placeholder={t('Select or Type')}
            />
            <div className='sfy-card-select-icon'>
              <Icons name='chevronDown' color='#979797' />
            </div>
          </div>
          {!selectedCategory && isTouched && <ErrorText />}
        </div>
        <div className='sfy-card-nav-actions'>
          <SFYFilterButton
            filterType='inclusions'
            filterName='Include Filters'
            buttonIcon='plusCircleSmall'
            toggleFilterAction={toggleFilterAction}
          />

          <SFYFilterButton
            filterType='exclusions'
            filterName='Exclude Filters'
            buttonIcon='minusCircleSmall'
            toggleFilterAction={toggleFilterAction}
          />
        </div>
      </div>

      <div>
        {hideInclude && (
          <Attributes
            title='Include Filters'
            titleIcon='plusCircleSmall'
            titleColor='#8ea976'
            description='Include filters allows you to highlight the products you need in your recommendations based on your selected attributes.'
            segmentType='inclusions'
            bundleIndex={bundleIndex}
            bundleParams={bundleDetail.params}
            setFilterParams={setFilterParams}
            filterParams={filterParams}
            filterTypes={filterTypes}
          />
        )}
        {hideExclude && (
          <Attributes
            title='Exclude Filters'
            titleIcon='minusCircleSmall'
            titleColor='#c54343'
            description='Exclude filters allows you to narrow down your recommendation selection to the products that meet your criteria'
            segmentType='exclusions'
            bundleIndex={bundleIndex}
            bundleParams={bundleDetail.params}
            setFilterParams={setFilterParams}
            filterParams={filterParams}
            filterTypes={filterTypes}
          />
        )}
      </div>
    </div>
  );
};

export default SFYCardBundleCategory;
