import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { productUrl } from '../../../modules/trendify/page';
import { localeString } from '../../../system/string';
import { t } from '../../../system/ui';
import Currency from '../../currency';
import Icon from '../../icon';
import { imgToBase64 } from '../../../modules/trendify/utils';

const RelatedProduct = ({
  inStock,
  productCategory,
  productId,
  productImage,
  productName,
  productOldPrice,
  productPrice,
  productURL,
  related,
  stats,
}) => {
  const [baitImageSource, setBaitImageSource] = React.useState(
    productUrl(productImage),
  );
  const [
    baitRelatedProductImageSource,
    setBaitRelatedProductImageSource,
  ] = React.useState(productUrl(related.record.image));

  React.useEffect(() => {
    async function getBaitImage() {
      const imgUrl = await imgToBase64(productUrl(productImage));
      const relatedImgUrl = await imgToBase64(productUrl(related.record.image));
      setBaitImageSource(imgUrl);
      setBaitRelatedProductImageSource(relatedImgUrl);
    }

    getBaitImage();
  }, []);

  return (
    <tr key={`${productName}-${productId}`}>
      <td>
        <a href={productUrl(productURL)} target='_blank' rel='noreferrer'>
          <img
            src={baitImageSource}
            className='product-image'
            alt={`Product ${productName}`}
          />
        </a>
      </td>
      <td>
        <h3 className='product-name'>
          <a href={productUrl(productURL)} target='_blank' rel='noreferrer'>
            {productName}
          </a>
        </h3>
        <p className='product-category'>{productCategory}</p>
        <p className='product-id'>{productId}</p>
      </td>
      <td
        className={classNames('product-stock', {
          'product-in-stock': inStock,
        })}
      >
        {inStock ? (
          <span className='product-in-stock-icon'>
            <Icon name='check-circle' />
          </span>
        ) : (
          <span className='out-of-stock' />
        )}
        {inStock ? t('In Stock') : t('Out of Stock')}
      </td>
      <td className='product-price'>
        {productOldPrice && (
          <span className='product-old-price'>
            <Currency currency={productOldPrice} />
          </span>
        )}

        <span className='product-sale-price'>
          <Currency currency={productPrice} />
        </span>
      </td>
      <td className='cell-group'>
        <div className='product-stats'>
          {stats
            .filter(stat => stat.name === 'purchase')
            .map(stat => (
              <div className='product-stat' key={`${stat.name}-${productId}`}>
                <p className='product-stat-value'>
                  {stat.type === 'currency' ? (
                    <Currency currency={stat.value} />
                  ) : (
                    localeString(stat.value)
                  )}
                  {stat.type === 'ratio' ? '%' : ''}
                </p>
              </div>
            ))}
        </div>
      </td>
      <td className='cell-group'>
        <a
          href={productUrl(related.record.url)}
          target='_blank'
          rel='noreferrer'
        >
          <img
            src={baitRelatedProductImageSource}
            className='product-image'
            alt={`Product ${related.record.name}`}
          />
        </a>
      </td>
      <td>
        <h3 className='product-name'>
          <a
            href={productUrl(related.record.url)}
            target='_blank'
            rel='noreferrer'
          >
            {related.record.name}
          </a>
        </h3>
        <p className='product-category'>
          {related.record.categories !== undefined &&
            related.record.categories.length > 0 &&
            related.record.categories.join(',')}
        </p>
        <p className='product-id'>{related.record.productId}</p>
      </td>
      <td
        className={classNames('product-stock', {
          'product-in-stock': related.record.inStock,
        })}
      >
        {related.record.inStock ? (
          <span className='product-in-stock-icon'>
            <Icon name='check-circle' />
          </span>
        ) : (
          <span className='out-of-stock' />
        )}
        {related.record.inStock ? t('In Stock') : t('Out of Stock')}
      </td>
      <td className='product-price'>
        {related.record.oldPrice && (
          <span className='product-old-price'>
            <Currency currency={related.record.oldPrice} />
          </span>
        )}

        <span className='product-sale-price'>
          <Currency currency={related.record.price} />
        </span>
      </td>
    </tr>
  );
};

RelatedProduct.propTypes = {
  inStock: PropTypes.bool,
  productCategory: PropTypes.string,
  productId: PropTypes.number,
  productImage: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  productOldPrice: PropTypes.number,
  productPrice: PropTypes.number.isRequired,
  productURL: PropTypes.string.isRequired,
  related: PropTypes.shape({
    record: PropTypes.shape({
      categories: PropTypes.arrayOf(PropTypes.string),
      image: PropTypes.string.isRequired,
      inStock: PropTypes.bool,
      name: PropTypes.string.isRequired,
      oldPrice: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
      productId: PropTypes.number,
      url: PropTypes.string.isRequired,
    }),
  }),
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default RelatedProduct;
