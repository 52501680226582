export function ExportDownload({ data, type }) {
  const date = new Date();
  const fileName = `exported-users-${date.toLocaleDateString()}.${type}`;

  const blob = new Blob([data], { type: `text/${type}` });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}
