import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import lodash from 'lodash';
import classnames from 'classnames';
import { filterActions, trendifyActions, uiActions } from '../../actions';
import { t } from '../../system/ui';
import './BundledProductsList.scss';
import AnalyticsPager from '../pager/analyticspager';
import Icons from '../icons';
import { facetedSearchRequest } from '../../actions/faceted-search';
import ReplaceProductModal from '../search/faceted-search/rule-set/ReplaceProductModal';

class BundledProductsList extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      bundles: [],
      searchQuery: '',
      modalOpen: false,
    };
    this.debounced = lodash.debounce(this.sendSearchRequest, 500);
  }

  componentDidMount() {
    filterActions.resetAllFilters();
    this.setBreadcrumbs();
    this.sendSearchRequest();
  }

  setBreadcrumbs() {
    uiActions.showBreadcrumb({
      home: {
        name: t('Recommend'),
        icon: 'heart',
        slug: '/recommendations',
      },
      current: {
        name: 'Product List of Bundled',
      },
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.searchQuery !== this.state.searchQuery) {
      this.debounced();
    }
    if (
      prevProps.pageSize !== this.props.pageSize ||
      prevProps.pageNumber !== this.props.pageNumber
    ) {
      this.sendSearchRequest();
    }
  }

  sendSearchRequest = () => {
    const { pageSize, pageNumber } = this.props;
    const { searchQuery } = this.state;
    let queryParams = `&pageNo=${pageNumber}&pageSize=${pageSize}`;
    if (searchQuery) queryParams += `&keyword=${searchQuery}`;
    facetedSearchRequest('bundle/search', null, queryParams)
      .get()
      .then(response => {
        filterActions.changePageNumber(response.pageable.page + 1);
        filterActions.changePageSize(response.pageable.size);
        trendifyActions.updateCount(response.total);
        this.setState({ bundles: response.content ?? [] });
      });
  };

  createNewBundle = () => this.setState({ modalOpen: true });

  onSearchQueryChange = ({ target }) =>
    this.setState({ searchQuery: target.value });

  onAddClick = product => {
    const { router } = this.props;
    router.push({
      pathname: `/product_bundle/new/${product.productId}`,
    });
  };

  onRemoveClick = (event, id) => {
    event.stopPropagation();
    uiActions.confirmationDialog({
      title: 'Remove the Product?',
      content: 'Are you sure you want to remove the product you added?',
      onConfirm: () => {
        facetedSearchRequest('bundle/crud', null, `&id=${id}`)
          .delete()
          .then(response => {
            const notificationContent = () => (
              <span>Bundle successfully deleted</span>
            );
            uiActions.showNotification({ content: notificationContent });
            this.sendSearchRequest();
          });
      },
    });
  };

  onEditClick = id => {
    const { router } = this.props;
    router.push({
      pathname: `/product_bundle/edit/${id}`,
    });
  };

  render() {
    const { bundles, modalOpen, searchQuery } = this.state;
    const mainElementIdList = bundles.map(
      bundle => bundle.mainElement.productId,
    );
    const rows = bundles.map(({ mainElement, bundleCount, id }) => {
      return (
        mainElement && (
          <tr key={Math.random()} onClick={() => this.onEditClick(id)}>
            <td>{mainElement.productId}</td>
            <td>
              <a
                href={mainElement.url}
                target='_blank'
                rel='noreferrer'
                style={{ float: 'left' }}
              >
                <img
                  src={mainElement.image}
                  style={{ width: '50px', height: '50px' }}
                />
              </a>
              <div
                style={{
                  textAlign: 'left',
                  position: 'relative',
                  left: '10px',
                  color: '#656565',
                }}
              >
                {mainElement.name}
              </div>
            </td>
            <td>{mainElement.category?.[0] ?? null}</td>
            <td>
              <span style={{ color: '#47607B', marginRight: '12px' }}>
                {`${bundleCount} products`}
              </span>
              <span
                className='bundle-product-remove'
                onClick={event => this.onRemoveClick(event, id)}
              >
                <Icons name='trashBin' color='#D31116' width='14' height='14' />
              </span>
            </td>
          </tr>
        )
      );
    });
    return (
      <div className='bundled-products-list'>
        <h2 className='page-title' style={{ marginBottom: '0' }}>
          Product List of Bundled
        </h2>
        <div
          className={classnames('widget-list-wrapper', {
            empty: rows.length === 0,
          })}
        >
          <div className='create-search-bar trendify sidebar-is-hidden'>
            <div
              className='page-options page-options-sort-search'
              style={{ position: 'static' }}
            >
              <label
                className='item is-stacked is-search'
                style={{ right: 'unset' }}
              >
                <i className='icon-magnify' role='presentation'>
                  <span className='for-screenreader-only'>magnify icon</span>
                </i>
                <input
                  type='text'
                  placeholder='Search'
                  className='text-field'
                  value={searchQuery}
                  onChange={this.onSearchQueryChange}
                />
              </label>
            </div>
            <a className='create-button' onClick={this.createNewBundle}>
              <span style={{ marginRight: '5px' }}>
                <Icons
                  name='plusCircle'
                  width='16'
                  height='16'
                  color='#47607B'
                />
              </span>
              {t('Create New Bundle')}
            </a>
            <AnalyticsPager pageNumberOptions={[20, 50, 100]} />
          </div>
          <table className='data-table'>
            <thead>
              <tr>
                <th scope='col'>Product Id</th>
                <th scope='col'>Product</th>
                <th scope='col'>Category</th>
                <th scope='col'>Bundle</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
          {rows.length === 0 && (
            <div className='bundle-product-empty'>No bundled product found</div>
          )}
        </div>
        {modalOpen && (
          <ReplaceProductModal
            ignoredList={mainElementIdList || []}
            onAddClick={this.onAddClick}
            onCloseClick={() => this.setState({ modalOpen: false })}
            subText='Add the product you want to show on the bundle list'
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  pageSize: state.filters.pageSize,
  pageNumber: state.filters.pageNumber,
  count: state.filters.count,
});

export default withRouter(connect(mapStateToProps)(BundledProductsList));
