import React, { useState, useCallback, useEffect } from 'react';
import Editor from '@monaco-editor/react';
import { WithCodeEditor } from './WithCodeEditor';
import DifferenceEditor from './DifferenceEditor';
import { editorOptions } from './constants/editor-options';
import { useGroupErrorMarkers } from './hooks/useGroupMarkers';

import { files } from './constants/defaults';
import { Drawer } from './drawer';
import { Loader } from '../module-exports';
import { ButtonContainer } from './drawer/components/button-container/ButtonContainer';

/**
 * Code Editor Component
 * @description CodeEditor Component that wraps the Monaco Editor
 * @param {object} fileValue - file value
 * @param {string} fileName - file name
 * @param {function} setFileName - callback function to set the file name
 * @param {function} handleEditorChange - callback function to handle editor change
 * @param {function} handleEditorDidMount - callback function to handle editor did mount
 * @param {function} handleEditorWillMount - callback function to handle editor will mount
 * @param {function} handleEditorValidation - callback function to handle editor validation
 * @returns JSX.Element
 */

const CodeEditor = ({
  fileName,
  setFileName,
  fileValue,
  handleEditorChange,
  handleEditorDidMount,
  handleEditorWillMount,
  handleEditorValidation,
  errorMarkers,
}) => {
  const [drawerState, setDrawerState] = useState({
    open: false,
    type: 'errorBoundary',
  });
  const [showDiffEditor, setShowDiffEditor] = useState(false);

  const [groupedErrorMarkers, setGroupedErrorMarkers] = useState({});

  const handleDrawerOpen = useCallback(type => {
    setDrawerState(prevState => ({
      ...prevState,
      open: true,
      type,
    }));
  }, []);

  const handleDrawerClose = useCallback(() => {
    setDrawerState(false);
    setGroupedErrorMarkers({});
  }, []);

  useEffect(() => {
    setGroupedErrorMarkers(useGroupErrorMarkers(errorMarkers));
  }, [errorMarkers]);

  return (
    <>
      <ButtonContainer
        showDiffEditor={showDiffEditor}
        setShowDiffEditor={setShowDiffEditor}
        handleDrawerOpen={handleDrawerOpen}
        groupedErrorMarkers={groupedErrorMarkers}
        fileName={fileName}
        setFileName={setFileName}
      />
      <Drawer
        anchor='right'
        open={drawerState?.open}
        type={drawerState?.type}
        onClose={handleDrawerClose}
        groupedErrorMarkers={groupedErrorMarkers}
      />
      {showDiffEditor ? (
        <DifferenceEditor
          originalSourceCode={files[fileName]?.value}
          modifiedSourceCode={fileValue[fileName]}
          defaultLanguage={
            fileName.name === 'prejs' || fileName.name === 'postjs'
              ? 'javascript'
              : fileName.name
          }
        />
      ) : null}
      <Editor
        height='90vh'
        theme='vs-dark'
        path={files[fileName]?.name}
        defaultLanguage={files[fileName]?.language}
        // defaultValue={fileValue[fileName]}
        value={fileValue[fileName]}
        onChange={handleEditorChange}
        onMount={handleEditorDidMount}
        onValidate={handleEditorValidation}
        beforeMount={handleEditorWillMount}
        loading={<Loader />}
        options={editorOptions}
      />
    </>
  );
};

export default WithCodeEditor(CodeEditor);
