import { socialProofTypes } from './campaigns';

const socialProofRelatedTypes = () => {
  const relatedTypes = {};

  Object.keys(socialProofTypes).forEach(typeKey => {
    const type = socialProofTypes[typeKey];
    relatedTypes[type.type] = {
      title: type.name,
      type: type.type,
      pathType: type.id,
    };
  });
  return relatedTypes;
};

export const campaignTypesForGamificationReport = [
  'WHEEL_OF_FORTUNE',
  'SCRATCH',
  'POP_UP_BANNER_COUNTDOWN',
  'NOTIFICATION_BAR_COUNTDOWN',
  'HERO_BANNER_COUNTDOWN',
];

export const types = {
  'social-proof': {
    title: 'Social Proof',
    new: 'Add New Campaign',
    type: 'SOCIAL_PROOF',
    relatedTypes: socialProofRelatedTypes(),
  },
  'see-all': {
    title: 'See All',
    new: 'Create a new Campaign',
    type: 'SEE_ALL',
    isButtonsTop: true,
  },
  popup: {
    title: 'Popup',
    new: 'Add Popup Banner',
    type: 'POPUP_BANNER',
  },
  'email-collection': {
    title: 'E-Mail Collection',
    new: 'Add E-Mail Collection Popup',
    type: 'NEWSLETTER',
  },
  'form-survey': {
    title: 'Form - Survey',
    new: 'Add Form - Survey',
    type: 'FORM',
  },
  'notification-bar': {
    title: 'Notification Bar',
    new: 'Add Notification Bar',
    type: 'NOTIFICATION_BAR',
  },
  'hero-banner': {
    title: 'Hero Banner',
    new: 'Add Image Banner',
    type: 'HERO_BANNER',
  },
  segmentation: {
    title: 'Segmentation',
    new: 'Add Segmentation',
    type: 'SEGMENTATION',
  },
  'persona-quiz': {
    title: 'Persona Quiz',
    new: 'Create a new Quiz',
    type: 'PERSONA_QUIZ',
    isButtonsTop: true,
  },
  'wheel-of-fortune': {
    title: 'Wheel of Fortune',
    new: 'Add Wheel of Fortune',
    type: 'WHEEL_OF_FORTUNE',
  },
  scratch: {
    title: 'Scratch Off',
    new: 'Add Scratch Off',
    type: 'SCRATCH',
  },
  countdown: {
    title: 'Countdown',
    new: 'Add Countdown',
    type: 'COUNTDOWN',
  },
  'pop-up-banner-countdown': {
    title: 'Popup Banner Countdown',
    new: 'Add Popup Banner Countdown',
    type: 'POP_UP_BANNER_COUNTDOWN',
  },
  'notification-bar-countdown': {
    title: 'Notification Bar Countdown',
    new: 'Add Notification Bar Countdown',
    type: 'NOTIFICATION_BAR_COUNTDOWN',
  },
  'hero-banner-countdown': {
    title: 'Hero Banner Countdown',
    new: 'Add Hero Banner Countdown',
    type: 'HERO_BANNER_COUNTDOWN',
  },
};

export const sharedData = [
  'campaignName',
  'instanceId',
  'devices',
  'startDate',
  'endDate',
  'note',
  'campaignType',
  'timing',
  'frequency',
  'filters',
];

// this contains only campaign specific data
export const campaignSpecificData = {
  PERSONA_QUIZ: [
    'overlay',
    ['positionOptions', ['verticalPosition', 'horizontalPosition']],
    ['advancedConfig', ['html', 'css', 'preJs', 'postJs']],
  ],
  SOCIAL_PROOF: [
    'title',
    ['stylesOptions', ['bgColor', 'textColor']],
    ['positionOptions', ['verticalPosition', 'horizontalPosition']],
    ['advancedConfig', ['html', 'css', 'preJs', 'postJs']],
  ],
  SEE_ALL: [
    'buttonText',
    ['stylesOptions', ['bgColor', 'textColor']],
    ['advancedConfig', ['html', 'css', 'preJs', 'postJs']],
  ],
  POPUP_BANNER: [
    'title',
    'image',
    'url',
    'overlay',
    ['positionOptions', ['verticalPosition', 'horizontalPosition']],
    ['advancedConfig', ['html', 'css', 'preJs', 'postJs']],
  ],
  NEWSLETTER: [
    'title',
    'description',
    'placeholder',
    'fullNamePlaceHolder',
    'buttonSubmitText',
    'termsText',
    'termsUrl',
    ['stylesOptions', ['buttonTextColor', 'buttonBgColor']],
    ['positionOptions', ['verticalPosition', 'horizontalPosition']],
    ['advancedConfig', ['html', 'css', 'preJs', 'postJs']],
  ],
  NOTIFICATION_BAR: [
    'title',
    'image',
    'url',
    'sticky',
    ['stylesOptions', ['bgColor', 'textColor']],
    ['positionOptions', ['verticalPosition']],
    ['advancedConfig', ['html', 'css', 'preJs', 'postJs']],
  ],
  FORM: [
    'title',
    'description',
    'buttonSubmitText',
    'formFields',
    'overlay',
    ['positionOptions', ['verticalPosition', 'horizontalPosition']],
    ['advancedConfig', ['html', 'css', 'preJs', 'postJs']],
  ],
  SEGMENTATION: ['segment', ['segmentationOptions']],
  HERO_BANNER: [
    'title',
    'url',
    ['advancedConfig', ['html', 'css', 'preJs', 'postJs']],
  ],
};

export const pushCampaignSpecificData = {
  PUSH_PERMISSION: [
    'popupOption',
    ['confirmButtons', ['buttonLaterText', 'buttonAllowText']],
    ['positionOptions', ['verticalPosition', 'horizontalPosition']],
    ['advancedConfig', ['html', 'css', 'preJs', 'postJs', 'url']],
  ],
  PUSH_NOTIFICATION: [
    'title',
    'text',
    'icon',
    'image',
    'redirectUrl',
    'schedulingAndAudience',
  ],
  FLY: ['title', 'text', 'icon', 'image', 'redirectUrl'],
  RECOMMENDATION: [
    'title',
    'recommendations',
    'inclusions',
    'exclusions',
    'schedulingAndAudience',
  ],
  PRICE_DROP: ['title', 'recommendations', 'inclusions', 'exclusions'],
  REPLENISHMENT: ['title', 'recommendations', 'inclusions', 'exclusions'],
  BACK_IN_STOCK: ['title', 'recommendations', 'inclusions', 'exclusions'],
  WE_MISSED_YOU: ['title', 'recommendations', 'inclusions', 'exclusions'],
  ORDER_FOLLOW_UP: ['title', 'recommendations', 'inclusions', 'exclusions'],
  ABANDONED_CART: ['title', 'recommendations', 'inclusions', 'exclusions'],
  LAST_VISIT_ALTERNATIVES: [
    'title',
    'recommendations',
    'inclusions',
    'exclusions',
  ],
  LAST_VISIT_REMINDER: ['title', 'recommendations', 'inclusions', 'exclusions'],
  NICE_TO_MEET_YOU: ['title', 'recommendations', 'inclusions', 'exclusions'],
  NEW_COMERS: ['title', 'recommendations', 'inclusions', 'exclusions'],
  TOP_SELLERS: ['title', 'recommendations', 'inclusions', 'exclusions'],
  BIRTHDAY: ['title', 'recommendations', 'inclusions', 'exclusions'],
  CHURN: ['title', 'recommendations', 'inclusions', 'exclusions'],
  UPCOMING: [
    'title',
    'recommendations',
    'inclusions',
    'exclusions',
    'redirectUrl',
  ],
};

export const advancedConfig = {
  PERSONA_QUIZ: {
    html:
      '<div class="seg-popup-overlay"></div>\n' +
      '<div class="seg-popup seg-rec-banner seg-[[vertical]]-[[horizontal]] segFadeInUp seg-my-persona-[[instanceId]]">\n' +
      '  <div class="seg-popup-container">\n' +
      '    <div class="seg-persona">\n' +
      '      <h3 class="seg-persona--title">[[quizTitle]]</h3>\n' +
      '      <h3 class="seg-persona--title-success">Happy Shopping!</h3>\n' +
      '      <div class="seg-persona--container">\n' +
      '        [[#responses]]\n' +
      '        <div class="seg-persona-item">\n' +
      '          <div class="seg-persona-item--header">\n' +
      '            <div class="seg-persona-item--checkbox" segment-name="[[segmentName]]" segment-id="[[segmentId]]">\n' +
      '              <div class="seg-persona-item--checkbox--check"></div>\n' +
      '            </div>\n' +
      '            <div class="seg-persona-item--title">[[response]]</div>\n' +
      '          </div>\n' +
      '          <div class="seg-persona-item--image">\n' +
      '            <img src="[[image]]" alt="[[response]]">\n' +
      '          </div>\n' +
      '        </div>\n' +
      '        [[/responses]]\n' +
      '        <div class="seg-persona-success--text">\n' +
      '          <img src="https://img.segmentify.com/ae272bfb-214b-4cdd-b5c4-1dddde09e95e/u/6962ecee-0eea-4cb3-9fc4-74b06db9f704.png" />\n' +
      '          <p>\n' +
      '            We got your response and from now on we will list the most suitable products for you.\n' +
      '          </p>\n' +
      '    	</div>\n' +
      '      </div>\n' +
      '    <span class="seg-persona--error">You must make at least one choice</span>\n' +
      '    <div class="seg-persona-submit">\n' +
      '        <button class="seg-persona-submit--button">[[resultButton.title]]</button>\n' +
      '      </div>\n' +
      '    </div>\n' +
      '  </div>\n' +
      '  <span id="seg-my-persona-[[instanceId]]--close" class="seg-popup-close"></span>\n' +
      '</div>\n' +
      '<style>\n' +
      '  .seg-my-persona-[[instanceId]].seg-persona-success .seg-persona--title-success{\n' +
      '  	display:block;\n' +
      '    color: [[resultButton.backgroundColor]];\n' +
      '  }\n' +
      '\n' +
      '  .seg-my-persona-[[instanceId]] .seg-persona-submit .seg-persona-submit--button {\n' +
      '    background-color: [[resultButton.backgroundColor]];\n' +
      '    color: [[resultButton.textColor]];\n' +
      '    font-weight: 400;\n' +
      '    padding: 0 32px;\n' +
      '    font-size: 14px;\n' +
      '    height: 40px;\n' +
      '    box-shadow: none;\n' +
      '    border-radius: 4px;\n' +
      '  }\n' +
      '\n' +
      '  .seg-my-persona-[[instanceId]] .seg-persona-submit .seg-persona-submit--button:active,\n' +
      '  .seg-my-persona-[[instanceId]] .seg-persona-submit .seg-persona-submit--button:focus {\n' +
      '    border-color: [[resultButton.backgroundColor]];\n' +
      '    box-shadow: inset 0 0 0 1px [[resultButton.backgroundColor]], inset 0 0 0 2px #fff;\n' +
      '    position: relative;\n' +
      '    top: 0;\n' +
      '    bottom: 0;\n' +
      '  }\n' +
      '\n' +
      '  .seg-my-persona-[[instanceId]] .seg-persona-item--checkbox.selected {\n' +
      '    background-color: [[resultButton.backgroundColor]];\n' +
      '  }\n' +
      '\n' +
      '  .seg-my-persona-[[instanceId]] .seg-persona-item--checkbox.selected .seg-persona-item--checkbox--check {\n' +
      '    background-color: [[resultButton.textColor]];\n' +
      '  }\n' +
      '</style>',
    css:
      '@media only screen and (min-width: 1024px) {\n' +
      '  .seg-popup-container {\n' +
      '    min-width: 680px;\n' +
      '  }\n' +
      '}\n' +
      '\n' +
      '.seg-persona--error {\n' +
      '  display: none;\n' +
      '}\n' +
      '.seg-persona-error .seg-persona--error {\n' +
      '  display: block;\n' +
      '}\n' +
      '.seg-persona--title {\n' +
      '  color: rgb(0, 0, 0);\n' +
      '  font-weight: 500;\n' +
      '  font-size: 24px;\n' +
      '  margin: 8px 0;\n' +
      '  font-size: 26px;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-success--text {\n' +
      '  line-height: 32px;\n' +
      '  width: 320px;\n' +
      '  color: rgb(151 151 151);\n' +
      '  font-size: 16px;\n' +
      '  display: none;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-success .seg-persona-submit {\n' +
      '  display: none;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-success .seg-persona-item {\n' +
      '  display: none;\n' +
      '}\n' +
      '\n' +
      '.seg-persona--title-success {\n' +
      '  display: none;\n' +
      '  font-size: 26px;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-success .seg-persona-success--text {\n' +
      '  display: block;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-success .seg-persona--title {\n' +
      '  display: none;\n' +
      '}\n' +
      '\n' +
      '.seg-persona {\n' +
      '  display: flex;\n' +
      '  flex-direction: column;\n' +
      '  flex-wrap: nowrap;\n' +
      '  align-content: center;\n' +
      '  align-items: center;\n' +
      '  padding: 32px;\n' +
      '  max-height: 520px;\n' +
      '  overflow: auto;\n' +
      '}\n' +
      '\n' +
      '.seg-persona--container {\n' +
      '  display: flex;\n' +
      '  flex-direction: row;\n' +
      '  flex-wrap: wrap;\n' +
      '  align-content: center;\n' +
      '  justify-content: center;\n' +
      '  align-items: flex-start;\n' +
      '  margin: 32px 0;\n' +
      '  gap: 32px 16px;\n' +
      '  position: relative;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-item {\n' +
      '  width: 160px;\n' +
      '  padding: 8px;\n' +
      '  overflow: hidden;\n' +
      '  cursor: pointer;\n' +
      '  border-radius: 4px;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-item:hover .seg-persona-item--checkbox--check {\n' +
      '  background-color: rgba(255, 255, 255, 0.5);\n' +
      '}\n' +
      '\n' +
      '.seg-persona-item.selected:hover .seg-persona-item--checkbox--check {\n' +
      '  transition: 0.5s ease all;\n' +
      '  background-color: rgba(255, 255, 255);\n' +
      '}\n' +
      '\n' +
      '.seg-persona-item--image {\n' +
      '  border-radius: 8px;\n' +
      '  padding: 16px 8px;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-item:hover .seg-persona-item--image {\n' +
      '  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);\n' +
      '  transition: 0.5s ease all;\n' +
      '  background: rgb(0 0 0 / 2.5%);\n' +
      '}\n' +
      '\n' +
      '.seg-persona-item.selected .seg-persona-item--image {\n' +
      '  background: rgb(0 0 0 / 5%);\n' +
      '}\n' +
      '\n' +
      '.seg-persona-item--title {\n' +
      '  font-size: 14px;\n' +
      '  color: rgb(0, 0, 0);\n' +
      '}\n' +
      '\n' +
      '.seg-persona-item--image img {\n' +
      '  width: 100%;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-item--checkbox {\n' +
      '  width: 16px;\n' +
      '  height: 16px;\n' +
      '  background-color: rgb(216 216 216);\n' +
      '  border-radius: 4px;\n' +
      '  display: flex;\n' +
      '  flex-direction: row;\n' +
      '  flex-wrap: nowrap;\n' +
      '  justify-content: center;\n' +
      '  align-items: center;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-item--header {\n' +
      '  display: flex;\n' +
      '  flex-direction: row;\n' +
      '  align-content: flex-start;\n' +
      '  align-items: center;\n' +
      '  gap: 8px;\n' +
      '  margin-bottom: 8px;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-item--checkbox--check {\n' +
      '  width: 8px;\n' +
      '  height: 8px;\n' +
      '  background: rgb(216 216 216);\n' +
      '  border-radius: 2px;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-submit {\n' +
      '  margin-bottom: 16px;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-success--text p {\n' +
      '  font-size: 22px;\n' +
      '  text-align: center;\n' +
      '}\n' +
      '\n' +
      '.seg-persona-success .seg-persona-success--text {\n' +
      '  display: flex;\n' +
      '  align-items: center;\n' +
      '  justify-content: center;\n' +
      '  flex-direction: column;\n' +
      '}',
  },
  POPUP_BANNER: {
    html:
      '<div class="seg-popup-overlay"></div>\n' +
      '<div class="seg-popup seg-rec-banner seg-[[vertical]]-[[horizontal]] segFadeInUp">\n' +
      '    <div class="seg-popup-container">\n' +
      '        [[#title]]\n' +
      '            <div class="seg-popup-header">\n' +
      '                <h1 class="seg-popup-title">[[title]]</h1>\n' +
      '            </div>\n' +
      '        [[/title]]\n' +
      '        <div class="seg-popup-content">\n' +
      '            <div class="seg-banner-container-v2">\n' +
      '                [[#url]]<a href="[[url]]" target="_blank">[[/url]]\n' +
      '                <img src="[[image]]" />\n' +
      '                [[#url]]</a>[[/url]]\n' +
      '            </div>\n' +
      '        </div>\n' +
      '    </div>\n' +
      '    <span class="seg-popup-close"></span>\n' +
      '</div>',
    pureHtml:
      '<div class="seg-popup-overlay"></div>\n' +
      '<div class="seg-popup seg-rec-banner seg-[[vertical]]-[[horizontal]] segFadeInUp">\n' +
      '    <div class="seg-popup-container">\n' +
      '        [[#title]]\n' +
      '            <div class="seg-popup-header">\n' +
      '                <h1 class="seg-popup-title">[[title]]</h1>\n' +
      '            </div>\n' +
      '        [[/title]]\n' +
      '        <div class="seg-popup-content">\n' +
      '            <div class="seg-banner-container-v2">\n' +
      '                [[#url]]<a href="[[url]]" target="_blank">[[/url]]\n' +
      '                <img src="[[image]]" />\n' +
      '                [[#url]]</a>[[/url]]\n' +
      '            </div>\n' +
      '        </div>\n' +
      '    </div>\n' +
      '    <span class="seg-popup-close"></span>\n' +
      '</div>',
    isFancy: false,
  },
  NOTIFICATION_BAR: {
    html:
      '<div class="seg-notification-bar seg-notification-bar-v2 [[divClass]]" style="background-color:[[bgColor]];">\n' +
      '    [[#url]]<a class="seg-notification-bar-v2-[[instanceId]]" href="[[url]]">[[/url]]\n' +
      '        [[#image]]<img src="[[image]]">[[/image]]\n' +
      '        [[#title]]<h1 style="color:[[textColor]]">[[title]]</h1>[[/title]]\n' +
      '    [[#url]]</a>[[/url]]\n' +
      '</div>',
  },
  SOCIAL_PROOF: {
    html:
      '<div class="seg-popup-overlay"></div>\n' +
      '<div class="seg-popup seg-social-proof-popup-close-[[instanceId]] seg-social-proof-banner seg-[[vertical]]-[[horizontal]] segFadeInUp" style="background-color:[[bgColor]]">\n' +
      '  <span class="seg-popup-close seg-social-proof-close seg-social-proof-close-[[instanceId]]"></span>\n' +
      '  <div class="seg-popup-container">\n' +
      '    <div class="seg-social-proof-header">\n' +
      '      <img class="seg-social-proof-icon" src="[[iconUrl]]" alt="[[iconUrl]]">\n' +
      '      <p class="seg-popup-title" style="color:[[textColor]]">[[campaignMessage]]</p>\n' +
      '    </div>\n' +
      '  </div>\n' +
      '</div>',
  },
  SEE_ALL: {
    html:
      '<button class="sgm-see-all-button seg-see-all-link-[[instanceId]]" type="button" style="background-color:[[bgColor]]">\n' +
      '  <span class="sgm-see-all-button-text" style="color:[[textColor]]">[[buttonText]]</span>\n' +
      '  <span class="sgm-see-all-arrow" />\n' +
      '</button>',
  },
  NEWSLETTER: {
    html:
      '<div class="seg-popup seg-email-collection seg-[[vertical]]-[[horizontal]] segFadeInUp">\n' +
      '    <div class="seg-popup-container">\n' +
      '        <div class="seg-popup-header">\n' +
      '            <h1 class="seg-popup-title">[[title]]</h1>\n' +
      '            <p>[[description]]</p>\n' +
      '        </div>\n' +
      '        <div class="seg-popup-content">\n' +
      '            <form method="post" action="" class="seg-form" id="seg-email-collection">\n' +
      '                <input type="email" class="seg-form-input" name="email" placeholder="[[placeholder]]" required>\n' +
      '                <input type="text" class="seg-form-input" name="fullName" placeholder="[[fullNamePlaceHolder]]" required>\n' +
      '                <div class="seg-form-footer">\n' +
      '                    <div class="seg-terms">\n' +
      '                        <label class="seg-checkbox">\n' +
      '                            <input type="checkbox" required>\n' +
      '                            <div class="seg-indicator-icon"></div>\n' +
      '                        </label>\n' +
      '                        <a href="[[& termsUrl]]" target="_blank"> [[termsText]]</a>\n' +
      '                    </div>\n' +
      '                    <button type="submit" class="seg-form-submit" style="background-color:[[buttonBgColor]]">\n' +
      '                        <span style="color:[[buttonTextColor]]">[[buttonSubmitText]]</span>\n' +
      '                        <span style="color:[[buttonTextColor]]"></span>\n' +
      '                    </button>\n' +
      '                </div>\n' +
      '            </form>\n' +
      '        </div>\n' +
      '    </div>\n' +
      '    <span class="seg-popup-close"></span>\n' +
      '</div>',
  },
  FORM_SURVEY: {
    html:
      '<div class="seg-popup-overlay"></div>\n' +
      '<div class="seg-popup seg-form-survey seg-[[vertical]]-[[horizontal]] segFadeInUp">\n' +
      '    <div class="seg-popup-container">\n' +
      '        <div class="seg-popup-header">\n' +
      '            <h1 class="seg-popup-title">[[title]]</h1>\n' +
      '            <p>[[description]]</p>\n' +
      '        </div>\n' +
      '        <div class="seg-popup-content">\n' +
      '            <form method="post" class="seg-form">\n' +
      '            </form>\n' +
      '            <div class="seg-form-footer">\n' +
      '                <button type="submit" class="seg-form-submit" style="background-color:#d94848;">\n' +
      '                    <span style="color:#fff">[[buttonSubmitText]]</span>\n' +
      '                    <span style="color:#fff"></span>\n' +
      '                </button>\n' +
      '            </div>\n' +
      '        </div>\n' +
      '    </div>\n' +
      '    <span class="seg-popup-close"></span>\n' +
      '</div>',
  },
  HERO_BANNER: {
    html:
      '<div class="seg-hero-banner">\n' +
      '  [[#url]]<a class="seg-hero-banner-link-[[instanceId]]" href="[[url]]">[[/url]]\n' +
      '    [[#image]]<img class="seg-hero-banner-image" src="[[image]]" alt="[[title]]">[[/image]]\n' +
      '  [[#url]]</a>[[/url]]\n' +
      '</div>',
  },
};
