/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconIos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            fill={this.state.color}
            className="svg-path"
            d="M383.76,265.38c-.62-62.07,50.62-91.84,52.91-93.34-28.79-42.1-73.65-47.88-89.62-48.53C308.91,119.63,272.58,146,253.23,146s-49.21-21.9-80.84-21.34c-41.61.62-80,24.2-101.38,61.45C27.79,261,60,372.14,102.06,433c20.59,29.74,45.14,63.2,77.35,62,31-1.23,42.76-20.08,80.29-20.08s48.07,20.08,80.9,19.45c33.38-.61,54.55-30.31,75-60.17a271,271,0,0,0,33.94-69.7C448.78,364.14,384.43,339.49,383.76,265.38Z"
          />
          <path
            fill={this.state.color}
            className="svg-path"
            d="M322.06,83.23C339.14,62.5,350.72,33.68,347.57,5c-24.65,1-54.49,16.38-72.19,37.1-15.86,18.39-29.72,47.66-26,75.82C276.87,120.05,304.92,103.93,322.06,83.23Z"
          />
        </g>
      </svg>
    );
  }
}

export default IconIos;
