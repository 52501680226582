import moment from 'moment';
import 'moment-timezone';

import user from '../modules/auth/user';
import { t } from './ui';

export const periods = [
  { value: 'every_day', label: t('Day') },
  { value: 'every_monday', label: t('Monday') },
  { value: 'every_tuesday', label: t('Tuesday') },
  { value: 'every_wednesday', label: t('Wednesday') },
  { value: 'every_thursday', label: t('Thursday') },
  { value: 'every_friday', label: t('Friday') },
  { value: 'every_saturday', label: t('Saturday') },
  { value: 'every_sunday', label: t('Sunday') },
];

export const queryDate = date => date.format('YYYY-MM-DD');

export const getStartEndDate = interval => {
  const now = moment().startOf('day');
  const today = moment().endOf('day');
  const yesterday = moment()
    .add(-1, 'days')
    .startOf('day');
  const yesterdayEnd = moment()
    .add(-1, 'days')
    .endOf('day');
  const week = moment().startOf('isoweek');
  const lastSevenDays = moment()
    .subtract(6, 'd')
    .startOf('day');
  const month = moment().startOf('month');
  const lastMonth = moment()
    .add(-1, 'month')
    .startOf('month');
  const lastMonthEnd = moment()
    .add(-1, 'month')
    .endOf('month');
  const lastFiveWeeks = moment()
    .subtract(5, 'weeks')
    .startOf('day');
  const year = moment().startOf('year');

  const boundaries = {};

  boundaries.end = queryDate(today);

  if (interval === 'yesterday') {
    boundaries.start = queryDate(yesterday);
    boundaries.end = queryDate(yesterdayEnd);
  }

  if (interval === 'days') {
    boundaries.start = queryDate(lastSevenDays);
    boundaries.end = queryDate(today);
  }

  if (interval === 'weeks') {
    boundaries.start = queryDate(lastFiveWeeks);
    boundaries.end = queryDate(today);
  }

  if (interval === 'daily') {
    boundaries.start = queryDate(now);
  }

  if (interval === 'weekly') {
    boundaries.start = queryDate(week);
  }

  if (interval === 'lastSevenDays') {
    boundaries.start = queryDate(lastSevenDays);
  }

  if (interval === 'monthly') {
    boundaries.start = queryDate(month);
  }

  if (interval === 'lastmonth') {
    boundaries.start = queryDate(lastMonth);
    boundaries.end = queryDate(lastMonthEnd);
  }

  if (interval === 'yearly') {
    boundaries.start = queryDate(year);
  }

  if (interval === 'all') {
    boundaries.start = queryDate(year);
  }

  return boundaries;
};

export const reformatDateByTimeZone = (timestamp, dateFormat) => {
  const format = dateFormat || 'DD/MM/YYYY HH:mm';
  const account = user.getAccount();

  if (account.timeZone === undefined)
    return timestamp ? moment(timestamp).format(format) : false;

  if (timestamp) {
    if (account.timeZone)
      return moment(timestamp)
        .tz(account.timeZone)
        .format(format);
    return moment(timestamp).format(format);
  }

  return false;
};

export const getMomentObjDate = date => {
  return moment(date);
};

export const getFormattedDate = timestamp => {
  const date = new Date(timestamp);

  return date.toLocaleDateString();
};

export const getFormattedDetailedDate = timestamp =>
  reformatDateByTimeZone(timestamp, 'LLL');

export const getFormattedDetailedHour = timestamp =>
  moment(timestamp).format('LT');

export const getNow = () => moment();

export const getNowByTimeZone = () => {
  const account = user.getAccount();

  if (account.timeZone) {
    const zone = moment()
      .tz(account.timeZone)
      .format('Z');
    return moment()
      .utc()
      .utcOffset(zone);
  }

  return moment();
};

export const yesterday = () => moment().subtract(1, 'day');

export const isValidStartDate = current => current.isAfter(yesterday());

export const isValidStartDateWithEndDate = (current, endDate) => {
  if (endDate) {
    return current.isSameOrBefore(endDate);
  }
  return true;
};

export const isAfterNow = current => current.isAfter(getNow());

export const isBeforeNow = current => {
  if (moment.isMoment(current)) {
    return getNow().isAfter(current);
  }
  return getNow().isAfter(moment(current));
};

export const isBeforeNowByTimeZone = current => {
  if (moment.isMoment(current)) {
    return getNow().isAfter(current);
  }
  if (current === undefined) return false;
  let zone;

  const account = user.getAccount();
  const now = getNowByTimeZone();

  if (account.timeZone)
    zone = moment()
      .tz(account.timeZone)
      .format('Z');
  else zone = moment().format('Z');

  const selectTime = moment(current).utcOffset(zone);

  return now.isAfter(selectTime);
};

export const isValidEndDate = (current, startDate) =>
  current.isSameOrAfter(startDate);

export const isValidCampaignEndDate = (current, startDate) => {
  const clonedDate = current.clone().endOf('day');
  return clonedDate.isSameOrAfter(startDate);
};

export const validDayCannotBeAfterYesterday = current => {
  return current.isSameOrBefore(yesterday());
};

export const reformatDate = (timestamp, dateFormat) => {
  const format = dateFormat || 'DD/MM/YYYY HH:mm';
  return timestamp ? moment(timestamp).format(format) : false;
};

export const printFormatDate = date => date.format('DD/MM/YYYY');
export const printReverseFormatDate = date => date.format('YYYY-MM-DD');

export const getFirstDayOfWeek = () => moment().startOf('isoweek');

export const get7DaysAgo = () =>
  moment()
    .subtract(6, 'd')
    .startOf('day');

export const getStartOfDay = () => moment().startOf('day');

export const getStartOfLastWeek = () =>
  moment()
    .subtract(1, 'weeks')
    .startOf('isoWeek');

export const getEndOfLastWeek = () =>
  moment()
    .subtract(1, 'weeks')
    .endOf('isoWeek');

export const convertToEpochTime = current => {
  let zone;
  const account = user.getAccount();

  if (account.timeZone)
    zone = moment()
      .tz(account.timeZone)
      .format('Z');
  else zone = moment().format('Z');

  return moment(current)
    .utcOffset(zone)
    .valueOf();
};

export const get15MinutesLater = () => moment().add(15, 'm');

export const get15MinutesLaterWithTimeZone = () => {
  const account = user.getAccount();
  if (account.timeZone) {
    const zone = moment()
      .tz(account.timeZone)
      .format('Z');
    return moment()
      .utcOffset(zone)
      .add(15, 'm')
      .format();
  }

  return moment().add(15, 'm');
};

export const isWeekend = () => {
  return [0, 6].indexOf(new Date().getDay()) !== -1;
};

export const today = () => moment().endOf('day');
