import React from 'react';
import { TextField, Select, Button } from './fields';
import { t } from '../system/ui';
import { localeString } from '../system/string';

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.onValueChange = this.onValueChange.bind(this);
    this.onChangeField = this.onChangeField.bind(this);

    this.state = {
      inputs: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.fields !== this.props.fields) {
      this.setState({
        inputs: {},
      });
    }
  }

  onValueChange(e) {
    const { inputs } = this.state;
    const input = e.currentTarget;

    inputs[input.name] = input.value;

    this.setState({
      inputs,
    });
  }

  onChangeField(evt) {
    this.props.onChange(evt);
    this.onValueChange(evt);
  }

  render() {
    const _this = this;
    const _props = this.props;
    const {
      isSpecial,
      isSegmentify,
      isSegmentifyV2,
      isKlaviyo,
      readOnly = false,
    } = this.props;

    return (
      <ol
        className='form-elements'
        style={{ position: _props.activeLabel ? 'relative' : 'unset' }}
      >
        {_props.activeLabel ? (
          <span
            style={{
              backgroundColor: '#fbfbfb',
              height: '65px',
              position: 'absolute',
              width: '100%',
              top: '0',
              left: '0',
            }}
          />
        ) : null}

        {_props.activeLabel ? (
          <span>
            <label className='switch'>
              <input type='checkbox' checked={this.props.button} readOnly={readOnly} />
              <span
                className='slider round'
                onClick={this.props.clickHandler}
              />
            </label>{' '}
            <span style={{ position: 'relative' }}>Active</span>
          </span>
        ) : null}
        {this.props.fields.map(function(item, index) {
          item = {
            ...item,
            value:
              _this.state.inputs[item.name] != undefined
                ? _this.state.inputs[item.name]
                : item.value,
          };

          let defaultMarginLeft = 'unset';
          let defaultWidth = 'unset';
          if (_props.activeLabel) {
            if (index === 0) {
              defaultMarginLeft = '100px';
              defaultWidth = '230px';
            }
            if (index === 1 && isKlaviyo) {
              defaultMarginLeft = '350px';
              defaultWidth = '460px';
            }
          }

          return (
            <li
              className='form-element'
              style={{
                display: isSpecial ? 'inline' : 'list-item',
                position:
                  _props.activeLabel &&
                  (index === 0 || (isKlaviyo && index === 1))
                    ? 'absolute'
                    : 'unset',
                top:
                  _props.activeLabel &&
                  (index === 0 || (isKlaviyo && index === 1))
                    ? '0px'
                    : 'unset',
                marginLeft: defaultMarginLeft,
                width: defaultWidth,
              }}
              key={index}
            >
              {item.type === 'select' ? (
                <Select {...item} disabled={readOnly} />
              ) : (
                <TextField
                  {...item}
                  isSecond={_props.activeLabel && index && !isKlaviyo}
                  onChange={
                    _props.isMultipleComps ? _this.onChangeField : false
                  }
                  readOnly={readOnly}
                />
              )}
            </li>
          );
        })}

        {this.props.withAdditionalInfo && (
          <li className='settings-current-cost'>
            {t("This Month's Cost")}:
            <span>
              {`${localeString(this.props.account.thisMonthCost)} ${
                this.props.account.mainCurrency.code
              }`}
            </span>
          </li>
        )}

        {(() => {
          if (this.props.buttons) {
            return (
              <li className='buttons'>
                {this.props.buttons.map(function(item, index) {
                  return <Button key={index} attr={item} {..._props} />;
                })}
              </li>
            );
          }
        })()}
        {!isSegmentify && !isSegmentifyV2 ? (
          <div className='email-instructions-save-btn'>
            <button
              type='button'
              className='secondary-action'
              onClick={this.props.updateSettings}
              style={{ padding: '5px 40px' }}
              disabled={readOnly}
            >
              Save
            </button>
          </div>
        ) : null}
      </ol>
    );
  }
}

export default Form;
