"use strict";

import React from "react";
import { Link } from "react-router";
import { connect } from "react-redux";
import { TextField } from "../components/fields";
import { t } from "../system/ui";
import { setTitle } from "../system/document";
import { uiActions, userActions } from "../actions";

import {
  getAccount,
  getUser,
  isSwitchAccount,
  updatePushConfig
} from "../modules/auth/user";
import PushGlobalCapping from "../components/push/globalCapping";
import IconUpload from "../modules/campaign/fields/iconUpload";

class Settings_Push_Android extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serverApiKey: "",
      capping: null
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.update = this.update.bind(this);
  }

  componentDidMount() {
    setTitle("Web Push Settings");
    uiActions.isLoading();
    if (isSwitchAccount()) {
      let value =
        isSwitchAccount().pushConfiguration &&
        isSwitchAccount().pushConfiguration.androidConfiguration
          ? isSwitchAccount().pushConfiguration.androidConfiguration
              .serverApiKey
          : "";
      let capping =
        isSwitchAccount().pushConfiguration &&
        isSwitchAccount().pushConfiguration.capping;
      this.onValueChange(value, capping);
      uiActions.isLoaded();
    } else {
      getUser(response => {
        let value =
          response.account.pushConfiguration &&
          response.account.pushConfiguration.androidConfiguration
            ? response.account.pushConfiguration.androidConfiguration
                .serverApiKey
            : "";
        let capping =
          response.account.pushConfiguration &&
          response.account.pushConfiguration.capping;
        this.onValueChange(value, capping);
        let defaultIcon =
            response.account.pushConfiguration
                ? response.account.pushConfiguration.defaultIcon
                : "";
        this.onDefaultIconValueChange(defaultIcon);
        uiActions.isLoaded();
      });
    }
  }

  onValueChange(value, capping) {
    this.setState({
      serverApiKey: value,
      capping
    });
  }

  onInputChange(e) {
    this.onValueChange(e.target.value);
  }

  onDefaultIconValueChange(value) {
    this.state.defaultIcon = value;
  }

  update(e) {
    e.preventDefault();

    let account=getAccount();
    let cappingState = this.refs.capping.state;
    let config = {
      androidConfiguration: { serverApiKey: '' },
      vapidConfiguration: {},
      vapidEnabled: false,
      defaultIcon: '',
    };
    config.androidConfiguration.serverApiKey = this.state.serverApiKey;

    if (
      account.pushConfiguration &&
      account.pushConfiguration.androidConfiguration
    ) {
      config.androidConfiguration.senderId =
        account.pushConfiguration.androidConfiguration.senderId;
      config.androidConfiguration.type =
        account.pushConfiguration.androidConfiguration.type;
    }
    if (
      account.pushConfiguration &&
      account.pushConfiguration.vapidConfiguration
    ) {
      config.vapidConfiguration = account.pushConfiguration.vapidConfiguration;
    }

    if (account.pushConfiguration && account.pushConfiguration.vapidEnabled) {
      config.vapidEnabled = account.pushConfiguration.vapidEnabled;
    }

    config.defaultIcon = this.state.defaultIcon;
    config.capping = Object.assign({}, cappingState.capping);
    for (var key in config.capping) {
      if (config.capping && !config.capping[key]) {
        config.capping = null;
      }
    }
    if (!cappingState.hasCappingError) {
      updatePushConfig('android', config, response => {
        if (response.pushConfiguration) {
          if (isSwitchAccount()) {
            let newUserToSave = isSwitchAccount();
            newUserToSave.pushConfiguration = response.pushConfiguration;
            userActions.updateSwitchAccount(newUserToSave);
          }
          uiActions.isLoaded();
          let success = () => <p>{t("Push settings successfully updated.")}</p>;
          uiActions.showNotification({
            content: success
          });
          this.setState({
            ui: "isLoaded"
          });
        } else {
          uiActions.isLoaded();
          this.setState({
            ui: "isLoaded"
          });
        }
      });
    }
  }

  render() {
    return (
      <form onSubmit={this.update}>
        <div className="settings-push">
          <h2 className="page-title">
            {"Let's Integrate Segmentify Push Service"}
          </h2>
          <div className="settings-push-form">
            <nav id="page-nav" className="page-nav">
              <Link to="/settings/push" className="nav-item page-nav-item">
                {"Chromium & Firefox "}
              </Link>
              <Link
                to="/settings/push/safari"
                className="nav-item page-nav-item"
              >
                {"Safari"}
              </Link>
              <Link
                to="/settings/push/android"
                className="nav-item page-nav-item current"
              >
                {"Android"}
              </Link>
              <Link to="/settings/push/ios" className="nav-item page-nav-item">
                {"iOS"}
              </Link>
            </nav>
            <div className="one-whole">
              <p className="item-label">Your Server Key</p>
              <TextField
                name="serverApiKey"
                label={"Server Key"}
                className="item-stacked one-whole"
                value={this.state.serverApiKey}
                onChange={this.onInputChange}
              />
            </div>
          </div>

          <div className="settings-push-form">
            <IconUpload
                account={getAccount()}
                options={{ label: "Notification Icon" }}
                uploadType="icon"
                onDefaultIconValueChange={this.onDefaultIconValueChange.bind(this)}
            />
          </div>

          <PushGlobalCapping ref="capping" capping={this.state.capping} />
          <li
            className="buttons"
            style={{ marginTop: "50px", listStyle: "none" }}
          >
            <button className="tertiary-action">Save</button>
          </li>
        </div>
      </form>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  user: store.user.user
});

export default connect(mapStatesToProps)(Settings_Push_Android);
