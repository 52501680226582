import React, { useMemo } from 'react';
import { t } from '../../../system/ui';
import ModuleButton from './ModuleButton';

/**
 * @name SelectAccountModules
 * @description SelectAccountModules component
 * @param {array<string>} modules
 * @param {function} selectModule
 * @param {array<string>} selectedModules
 * @returns {JSX.Element}
 */
const SelectAccountModules = ({ modules, selectModule, selectedModules }) => {
  const moduleButtons = useMemo(() => {
    return modules.map(module => {
      return (
        <ModuleButton
          key={module}
          isChecked={selectedModules.includes(module)}
          onClick={() => selectModule(module)}
          text={module}
        />
      );
    });
  }, [modules, selectModule, selectedModules]);

  return (
    <>
      <div className='grid-label'>{t('Accessible Modules')}</div>
      <div className='grid-item input-modules input-modules-grid'>
        {modules.length !== 0 && (
          <ModuleButton
            isChecked={modules.length === selectedModules.length}
            onClick={() => selectModule('All')}
            text='All'
          />
        )}
        {moduleButtons}
      </div>
    </>
  );
};

export default SelectAccountModules;
