import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const firstOrder = ({ width, height }) => {
  return (
    <svg
      width='42'
      height='43'
      viewBox='0 0 42 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M42 21.5C42 33.098 32.598 42.5 21 42.5C9.40202 42.5 0 33.098 0 21.5C0 9.90202 9.40202 0.5 21 0.5C32.598 0.5 42 9.90202 42 21.5Z'
        fill='#E0F4E0'
      />
      <path
        d='M25.2744 17.054C25.4163 16.9823 25.5837 16.9823 25.7256 17.054L29.2256 18.8231C29.3939 18.9082 29.5 19.0808 29.5 19.2694V23.7343C29.5 23.9229 29.3939 24.0954 29.2256 24.1805L25.7256 25.9496C25.5837 26.0213 25.4163 26.0213 25.2744 25.9496L21.7744 24.1805C21.6061 24.0954 21.5 23.9229 21.5 23.7343V19.2694C21.5 19.0808 21.6061 18.9082 21.7744 18.8231L25.2744 17.054ZM26.4022 15.7153C25.8349 15.4285 25.1651 15.4285 24.5978 15.7153L21.0978 17.4844C20.4245 17.8248 20 18.515 20 19.2694V23.7343C20 24.4887 20.4245 25.1789 21.0978 25.5192L24.5978 27.2883C25.1651 27.5751 25.8349 27.5751 26.4022 27.2883L29.9022 25.5192C30.5755 25.1789 31 24.4887 31 23.7343V19.2694C31 18.515 30.5755 17.8248 29.9022 17.4844L26.4022 15.7153ZM13 17.2451C13 16.8309 13.3358 16.4951 13.75 16.4951H18.25C18.6642 16.4951 19 16.8309 19 17.2451C19 17.6593 18.6642 17.9951 18.25 17.9951H13.75C13.3358 17.9951 13 17.6593 13 17.2451ZM11 21.2451C11 20.8309 11.3358 20.4951 11.75 20.4951H16.25C16.6642 20.4951 17 20.8309 17 21.2451C17 21.6593 16.6642 21.9951 16.25 21.9951H11.75C11.3358 21.9951 11 21.6593 11 21.2451ZM13.75 24.4951C13.3358 24.4951 13 24.8309 13 25.2451C13 25.6593 13.3358 25.9951 13.75 25.9951H18.25C18.6642 25.9951 19 25.6593 19 25.2451C19 24.8309 18.6642 24.4951 18.25 24.4951H13.75ZM22.5816 19.9049C22.7694 19.5358 23.221 19.3888 23.5902 19.5767L25.5 20.5486L27.4098 19.5767C27.779 19.3888 28.2306 19.5358 28.4184 19.9049C28.6063 20.2741 28.4593 20.7257 28.0902 20.9135L26.25 21.85V23.7525C26.25 24.1667 25.9142 24.5025 25.5 24.5025C25.0858 24.5025 24.75 24.1667 24.75 23.7525V21.85L22.9098 20.9135C22.5407 20.7257 22.3937 20.2741 22.5816 19.9049Z'
        fill='#51793F'
      />
    </svg>
  );
};

firstOrder.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

firstOrder.defaultProps = {
  width: '42px',
  height: '43px',
};

export default firstOrder;
