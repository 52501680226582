import React, { Component } from 'react';
import { connect } from 'react-redux';

import { uiActions } from 'actions';

import { TextField } from 'components/fields';

class Delay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 1000,
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    let input = this.props.campaign.searchDelay || '';
    this.updateValue(input);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.searchDelay);
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  onFieldChange(e) {
    const { value } = e.target;
    this.updateValue(value);
    uiActions.formEdited();
  }

  render() {
    return (
      <li className="item-title-field">
        <TextField
          name="input"
          type="number"
          label={
            this.props.options.label !== undefined
              ? this.props.options.label
              : 'Form Field'
          }
          className="item-stacked one-whole"
          value={this.state.value}
          required={true}
          tooltip={this.props.options.tooltip}
          tooltipText={this.props.options.tooltipContent}
          tooltipAlignment="center"
          validation={{ minNumber: 400 }}
          onChange={this.onFieldChange}
          placeholder={this.props.options.placeholder}
        />
      </li>
    );
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true },
)(Delay);
