/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";
import { t } from "../../system/ui";

import Icon from "../icon";
import Icons from "../icons";
import { TextField } from "../fields";

class IncludesGender extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      genderExclusion: ""
    };

    this.onChange = this.onChange.bind(this);
    this.setValues = this.setValues.bind(this);
    this.onSubComponentChange = this.onSubComponentChange.bind(this);
  }

  componentDidMount() {
    this.setValues();
  }

  setValues() {
    let newState = {
      genderExclusion:
        typeof this.props.values.gender !== "undefined"
          ? this.props.values.gender.value
          : ""
    };
    this.props.subComponents.forEach(subComponent => {
      newState["genderExclusion" + subComponent.id] =
        typeof subComponent.values.gender !== "undefined"
          ? subComponent.values.gender.value
          : "";
    });
    this.setState(newState);
  }

  onChange(e) {
    let value = e.currentTarget.value;

    this.setState({
      genderExclusion: value
    });

    this.props.onInclusionChange(this.refs.inputs, this.props.id);
  }

  onSubComponentChange(ref, id, e) {
    let value = e.currentTarget.value;

    let newState = {};
    newState["genderExclusion" + id] = value;
    this.setState(newState);

    this.props.onInclusionChange(this.refs[ref], id);
  }

  render() {
    return (
      <li className="wizard-criterion">
        <span ref="inputs">
          <span className="criteria-type">
            <Icons name="excludeGender" color="#787774" />
          </span>
          <div className="wizard-criterion-text">
            <h3 className="wizard-criterion-title">{t("Gender")}</h3>
            <p className="wizard-criterion-description">
              {t(
                "Include the gender that your selected algorithm will recommend products from."
              )}
            </p>
          </div>
          <span className="wizard-product-name">
            <TextField
              name="gender"
              value={this.state.genderExclusion}
              className="item-field criteria-name"
              inputClassName="one-whole criteria-field"
              onChange={this.onChange}
            />
          </span>
          <div className="buttons-criteria">
            <a
              onClick={this.props.onInclusionRemove.bind(null, this.props.id)}
              className="remove-criteria button-criteria"
            >
              <Icons name="crossCircle" />
            </a>
            {this.props.subComponents.length < 2 && (
              <a
                onClick={this.props.onInclusionAdd.bind(null, "gender")}
                className="button-criteria"
              >
                <Icons name="plusCircle" width="32" height="32" />
              </a>
            )}
          </div>
        </span>
        {this.props.subComponents.map((subComponent, ind) => {
          if (ind > 0) {
            return (
              <span
                ref={"subComponentInputs" + subComponent.id}
                className="criteria-subcomponent"
                key={subComponent.id}
              >
                <span className="wizard-product-name">
                  <TextField
                    name="gender"
                    value={this.state["genderExclusion" + subComponent.id]}
                    className="item-field criteria-name"
                    inputClassName="one-whole criteria-field"
                    onChange={this.onSubComponentChange.bind(
                      this,
                      "subComponentInputs" + subComponent.id,
                      subComponent.id
                    )}
                  />
                </span>
                <div className="buttons-criteria">
                  {ind === this.props.subComponents.length - 1 && (
                    <a
                      onClick={this.props.onInclusionAdd.bind(
                        null,
                        subComponent.type
                      )}
                      className="button-criteria"
                    >
                      <Icons name="plusCircle" width="32" height="32" />
                    </a>
                  )}
                  <a
                    onClick={this.props.onInclusionRemove.bind(
                      null,
                      subComponent.id
                    )}
                    className="remove-criteria button-criteria"
                  >
                    <Icons name="crossCircle" />
                  </a>
                </div>
              </span>
            );
          } else {
            return null;
          }
        })}
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  includes: store.wizard.includes
});

export default connect(mapStatesToProps)(IncludesGender);
