import React from "react";
import Icon from "../components/icon";
import { t, openModal } from "../system/ui";
import * as modals from "../constants/datamaps/modals";
import { localeString } from "../system/string";

const showModal = (modal, e) => {
  e.preventDefault();
  let modalContent = modals[modal];

  openModal({
    title: modal,
    content: modalContent
  });
};

const PageCard = props => {
  const stats = props.data.stats;

  return (
    <div className="stat-card stat-page">
      <h3 className="stat-card-title">{t(props.title)}</h3>

      <a
        className="modal-trigger"
        onClick={e => {
          showModal(props.data.modal, e);
        }}
      >
        <Icon name="info" />
      </a>
      <h3 className="stat-card-title stat-card-title--thin">
        {localeString(stats.aLL.count)}
      </h3>
      {" "}
      <h3 className="stat-card-title stat-card-title--small">views</h3>
      <div className="stat-card-grid stat-card-page-grid">
        <div className="stat-card-subtitle stat-card-text--bold">Page Type</div>
        <div className="stat-card-subtitle stat-card-text--bold stat-card-text--center">Views</div>
        <div className="stat-card-subtitle stat-card-text--bold stat-card-text--center">Rate</div>
  
        <div className={"stat-card-subtitle"}>Home</div>
        <div className="stat-card-text--white stat-card-text--center">{localeString(stats.homePage.count)}</div>
        <div className="stat-card-text--white stat-card-text--center">{stats.homePage.ratio}%</div>

        <div className={"stat-card-subtitle"}>Category</div>
        <div className="stat-card-text--white stat-card-text--center">{localeString(stats.categoryPage.count)}</div>
        <div className="stat-card-text--white stat-card-text--center">{stats.categoryPage.ratio}%</div>

        <div className={"stat-card-subtitle"}>Product</div>
        <div className="stat-card-text--white stat-card-text--center">{localeString(stats.productPage.count)}</div>
        <div className="stat-card-text--white stat-card-text--center">{stats.productPage.ratio}%</div>

        <div className={"stat-card-subtitle"}>Basket</div>
        <div className="stat-card-text--white stat-card-text--center">{localeString(stats.basketPage.count)}</div>
        <div className="stat-card-text--white stat-card-text--center">{stats.basketPage.ratio}%</div>

        <div className={"stat-card-subtitle"}>Other</div>
        <div className="stat-card-text--white stat-card-text--center">{localeString(stats.other.count)}</div>
        <div className="stat-card-text--white stat-card-text--center">{stats.other.ratio}%</div>
      </div>
    </div>
  );
};

export default PageCard;
