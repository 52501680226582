import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'system/ui';
import CampaignMainItem from './CampaignMainItem';
import CampaignRuleSetItem from '../rule-set/CampaignRuleSetItem';

const Campaigns = ({ campaigns }) => {
  const liveCampaigns = campaigns.filter(c => c.status === 'ACTIVE');
  const draftCampaigns = campaigns.filter(c => c.status === 'DRAFT');
  const NoActiveCampaignsFound = () => (
    <div className="widget-list-no-items">{t('No Active Campaigns')}</div>
  );

  const renderCampaigns = (campaignArray, campaignStatus) => {
    const subtitleClass = classnames('page-subtitle', {
      passive: campaignStatus === 'draft',
    });
    return (
      <div className="widget-list-has-items" id="faceted-search-campaigns">
        <h3 className={subtitleClass}>
          {campaignStatus === 'live'
            ? t('Live Campaigns')
            : t('Draft Campaigns')}
          <span style={{ marginLeft: 5 }}>({campaignArray.length})</span>
        </h3>
        <ul className="widget-list">
          {campaignArray.map(campaign => (
            <React.Fragment key={campaign.instanceId}>
              <CampaignMainItem campaign={campaign} />
              <CampaignRuleSetItem campaign={campaign} />
            </React.Fragment>
          ))}
        </ul>
      </div>
    );
  };
  return (
    <div className="widget-list-wrapper faceted-widget-list-wrapper">
      {liveCampaigns.length > 0 ? (
        renderCampaigns(liveCampaigns, 'live')
      ) : (
        <NoActiveCampaignsFound />
      )}
      {draftCampaigns.length > 0 && renderCampaigns(draftCampaigns, 'draft')}
    </div>
  );
};

Campaigns.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Campaigns;
