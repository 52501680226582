import React, { useEffect, useState } from 'react';
import { SFYTextWithIcon } from '../../../../components/sfy-components/module-exports';
import { getSampleContent } from '../../../../modules/emails/ajax';
import { store } from '../../../../store';

const MailSubject = ({
  component,
  editCampaign,
  activeEmailDetails,
  emailType,
  readOnly,
}) => {
  const [sampleContent, setSampleContent] = useState([]);
  const [subject, setSubject] = useState(activeEmailDetails.params.subject);

  const editSubject = (fieldKey, fieldValue) => {
    editCampaign('params', {
      ...activeEmailDetails.params,
      [fieldKey]: fieldValue,
    });
    setSubject(fieldValue);
  };

  useEffect(() => {
    getSampleContent(emailType, samples => {
      setSampleContent(samples[store.getState().ui.language.toUpperCase()]);
    });
  }, []);

  return (
    <SFYTextWithIcon
      key={component.name}
      sampleTextVisibility
      cardTitle={component.title}
      cardName={component.name}
      placeholder={component.placeholder}
      editCampaign={editSubject}
      fieldValue={subject}
      sampleContent={sampleContent?.subjectList || []}
      readOnly={readOnly}
    />
  );
};

export default MailSubject;
