import React from 'react';

class IconCheckboxRoundTick extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: '16px',
      height: '16px',
      color: '#29cd43',
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    const width = this.props.width ? `${this.props.width}px` : this.state.width;
    const height = this.props.height
      ? `${this.props.height}px`
      : this.state.height;
    const color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        width={this.state.width}
        height={this.state.height}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill={this.state.color}
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4ZM12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z'
        />
      </svg>
    );
  }
}

export default IconCheckboxRoundTick;
