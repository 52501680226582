import React from 'react';
import { fixedPxValue } from './pcBigger';

const Reply = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 14 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.5 10.625V7.625C12.5 6.93033 12.257 6.34 11.771 5.854C11.285 5.368 10.6947 5.125 10 5.125H2.875L5.438 7.688L4.375 8.75L0 4.375L4.375 0L5.438 1.062L2.875 3.625H10C11.1113 3.625 12.0557 4.014 12.833 4.792C13.611 5.56933 14 6.51367 14 7.625V10.625H12.5Z'
        fill={color}
      />
    </svg>
  );
};

Reply.defaultProps = {
  width: '14px',
  height: '15px',
  color: 'white',
};

export default Reply;
