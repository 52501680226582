import React from "react";
import { browserHistory } from "react-router";

import user from "../modules/auth/user";

class UnlockUserPage extends React.Component {
  componentDidMount() {
    let token = this.props.params.token;
    if (token) {
      user.unlockUser((response) => {
        if (response === "Success")
          browserHistory.push("/login")
        
        if (response.status === 403)
          browserHistory.push("/mail-send/verification-mail-expired")
      }, token)
    }
  }
  render() {
    return null;
  }
}



export default UnlockUserPage;

