import { getApiKey } from '../auth/user';
import { $http, plainGet } from '../../system/ajax';
import api from '../../system/api';

export const getFilterOptions = (route, callback) => {
  const globalApiKey =
    getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
  return $http(api.getEndPoint(`account/${route}`) + globalApiKey)
    .get({}, { preventAbort: false })
    .then(callback);
};

export const searchProducts = (keyword, callback) => {
  plainGet(
    `${api.getRestEndPoint('search/product')}/?query=${encodeURI(
      keyword,
    )}&apiKey=${getApiKey()}`,
    callback,
  );
};
