import React from 'react';
import Icon from 'components/icon';
import { CardWrapper } from '../common';
import Select from 'react-select';
import { searchDetailedProducts } from 'modules/product/ajax';
import { decodeHtml } from 'system/string';
import PropTypes from 'prop-types';
import Icons from 'components/icons';

export default class ReplaceProductModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      options: [],
      value: null,
    };
    this.timer = null;
  }

  onInputChange = inputValue => {
    this.setState({
      inputValue,
    });
  };

  onInputKeyDown = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.state.inputValue) {
        searchDetailedProducts(this.state.inputValue, (response) => {
          if (Array.isArray(response)) {
            const ignoredList = this.props.ignoredList || [];
            this.setState({
              options: response.filter(product => ignoredList.indexOf(product.productId) === -1).map(product => ({
                value: product.productId,
                title: product.productId + " - " + decodeHtml(product.name),
                label: decodeHtml(product.name),
                product,
              })),
            });
          }
        });
      } else {
        this.setState({
          options: [],
        });
      }
    }, 1000);
  };

  onChange = value => {
    this.setState({
      value,
    });
  };

  valueRenderer(option) {
    return <span className='rule-set-modal-selected-product'>{option.label}</span>;
  }

  getCloseComponent = () => {
    return (
      <div className='rule-set-modal-cross' onClick={this.props.onCloseClick}>
        <Icons name='cross' />
      </div>
    );
  };

  render() {
    return (
      <div className="dialog">
        <div className="dialog-cover"/>
        <div className="dialog-content">
          <CardWrapper
            id="rule-set-replace-modal"
            title="Choose the Product"
            description={this.props.subText}
            headerRightComponent={this.getCloseComponent()}
          >
            <li className="wizard-criterion">
              <span className="criteria-type">
                <Icon name="product"/>
              </span>
                <span className="wizard-product-name wizard-product-select-container">
                <Select
                  value={this.state.value}
                  options={this.state.options}
                  onChange={this.onChange}
                  onInputKeyDown={this.onInputKeyDown}
                  onInputChange={this.onInputChange}
                  valueRenderer={this.valueRenderer}
                />
              </span>
            </li>
            <div className="rule-set-modal-button-container">
              <button
                onClick={() =>
                  this.state.value &&
                  this.props.onAddClick(this.state.value.product)
                }
                className='rule-set-modal-button'
              >
                Add Selected
              </button>
            </div>
          </CardWrapper>
        </div>
      </div>
    );
  }
}

ReplaceProductModal.propTypes = {
  onAddClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  subText: PropTypes.string.isRequired,
};
