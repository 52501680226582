import React, { useCallback } from 'react';
import { SFYVisualBuilder } from '../../../../components/sfy-components/module-exports';
import { getRestEndPoint } from '../../../../system/api';
import { getApiKey } from '../../../../modules/auth/user';
import { $http } from '../../../../system/ajax';
import {
  getOnlyFilledArrayWithRequiredFields,
  productPinConverter,
} from '../../../../components/sfy-components/utils';

const VisualBuilder = ({ location }) => {
  const {
    state: {
      data: { boostFields, productPin, exclusions },
      from,
      endPoint,
      searchType,
    },
  } = location;

  const searchHandler = useCallback(
    async (searchString, data) => {
      const url = `${getRestEndPoint(
        endPoint,
      )}?apiKey=${getApiKey()}&query=${searchString}&searchType=${searchType}`;

      return await $http(url).post(
        {
          boostFields: data.boostFields,
          pins: data.productPin,
          exclusions,
        },
        { type: 'json' },
      );
    },
    [endPoint, searchType, boostFields, productPin],
  );

  return (
    <SFYVisualBuilder
      data={{
        boostFields: getOnlyFilledArrayWithRequiredFields(boostFields, [
          'weight',
          'value',
        ]),
        productPin: productPinConverter(productPin),
      }}
      from={from}
      endPoint={endPoint}
      searchType={searchType}
      searchHandler={searchHandler}
    />
  );
};

export default VisualBuilder;
