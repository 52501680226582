import React from "react";

class IconUserList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg width={this.state.width} height={this.state.height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.99575 0.251595C3.72475 0.377309 2.66899 1.38683 2.32733 2.80017C2.26241 3.07064 2.24874 3.21159 2.25216 3.65731C2.25558 4.11826 2.26924 4.23636 2.34783 4.5335C2.52208 5.18874 2.79883 5.68779 3.23616 6.13731C3.84091 6.75826 4.61308 7.06683 5.44333 7.01731C5.8875 6.99064 6.24625 6.88779 6.62208 6.68588L6.90908 6.52969L6.94666 6.33159C7.10725 5.47826 7.4455 4.79255 8.01608 4.15636L8.35775 3.77921L8.33725 3.4135C8.29283 2.63255 7.98533 1.84779 7.507 1.2954C6.8305 0.514452 5.96266 0.152547 4.99575 0.251595Z" fill={this.state.color}/>
        <path d="M15.4166 0.251479C15.0715 0.285764 14.8426 0.346716 14.5248 0.495288C13.5032 0.975287 12.7721 2.08767 12.6696 3.32195L12.6491 3.581L12.8233 3.71052C13.6057 4.29338 14.2652 5.47052 14.3813 6.48005L14.4052 6.69338L14.5863 6.78862C14.9895 6.99433 15.6933 7.08195 16.2058 6.98671C17.0053 6.84576 17.7912 6.28195 18.2319 5.53148C18.4608 5.1429 18.536 4.96767 18.6522 4.53338C18.7342 4.23624 18.7444 4.12195 18.7478 3.65719C18.7512 3.21148 18.7376 3.07052 18.6727 2.80005C18.2729 1.14672 16.937 0.0990977 15.4166 0.251479Z" fill={this.state.color}/>
        <path d="M10.2267 3.77536C9.4989 3.92774 9.03765 4.18297 8.55248 4.69345C8.10148 5.16583 7.8179 5.72583 7.6744 6.43821C7.59923 6.79631 7.5924 6.91821 7.60607 7.27631C7.66415 8.62107 8.37823 9.77536 9.46815 10.2858C9.89182 10.4839 10.0524 10.5144 10.6537 10.5144C11.2517 10.5144 11.4157 10.4839 11.8325 10.2858C12.6012 9.92774 13.2026 9.23059 13.5067 8.34298C14.1558 6.46488 13.1582 4.34678 11.3883 3.84012C11.2004 3.78678 10.3872 3.74107 10.2267 3.77536Z" fill={this.state.color}/>
        <path d="M3.6325 7.29924C2.01983 7.47067 0.666829 8.724 0.239746 10.4383C0.0894126 11.0364 0.0791626 11.2307 0.0791626 13.2726V15.2154L0.413996 15.3297C1.34333 15.6421 2.11891 15.8478 2.9765 16.0002C3.48216 16.0954 4.37733 16.2059 4.60966 16.2097H4.719L4.73608 15.284C4.75658 14.305 4.77366 14.1526 4.96158 13.5164C5.26908 12.4688 5.92508 11.505 6.77241 10.865C7.04233 10.663 7.61633 10.3469 7.86233 10.263C7.94091 10.2402 8.00583 10.2059 8.00583 10.1945C8.00583 10.183 7.92041 10.0611 7.8145 9.924C7.29858 9.25353 7.00475 8.51829 6.90566 7.66876L6.86466 7.30305L6.71433 7.28019C6.49225 7.24591 3.97758 7.26114 3.6325 7.29924Z" fill={this.state.color}/>
        <path d="M14.4462 7.30309C14.4394 7.32976 14.4155 7.49738 14.395 7.67643C14.2891 8.57547 14.0192 9.24976 13.4657 9.96595C13.3768 10.084 13.3017 10.1907 13.3017 10.1983C13.3017 10.2098 13.3666 10.2402 13.4486 10.2631C13.6912 10.3469 14.2652 10.6631 14.5351 10.865C15.3278 11.4669 15.953 12.3469 16.2947 13.3412C16.4997 13.9469 16.5543 14.3279 16.5714 15.2955C16.5817 15.7869 16.5988 16.2021 16.609 16.2174C16.6363 16.2479 17.3368 16.1983 17.8288 16.1298C18.8093 16.0002 19.9642 15.6612 20.6919 15.2879L20.9208 15.1698V13.2498C20.9208 12.0612 20.9072 11.2231 20.8833 11.0555C20.668 9.45928 19.766 8.17928 18.4369 7.58119C17.8322 7.31071 17.3914 7.25738 15.7002 7.25738C14.7401 7.25738 14.4565 7.26881 14.4462 7.30309Z" fill={this.state.color}/>
        <path d="M8.60374 10.8801C7.07991 11.2306 5.95583 12.4306 5.55266 14.1335L5.46383 14.5144L5.45016 16.6325L5.43991 18.7468L5.63808 18.8116C7.21999 19.322 8.25524 19.562 9.49549 19.6954C10.2096 19.7754 11.7095 19.7754 12.3792 19.6992C13.5887 19.5582 14.805 19.2306 15.5703 18.8382L15.8642 18.6859V16.8116C15.8642 14.7316 15.8402 14.3735 15.666 13.8097C15.1911 12.263 14.1046 11.1963 12.6832 10.8763C12.3621 10.804 12.2152 10.8001 10.623 10.804C9.09233 10.8078 8.87708 10.8154 8.60374 10.8801Z" fill={this.state.color}/>
      </svg>
    );
  }
}

export default IconUserList;