import { combineReducers } from 'redux';
import ui from './ui';
import user from './user';
import switchedUser from './switchUser';
import date from './date';
import modal from './modal';
import dialog from './dialog';
import wizard from './wizard';
import filters from './filters';
import campaigns from './campaigns';
import reports from './reports';
import notification from './notification';
import categories from './categories';
import brands from './brands';
import labels from './labels';
import mailWizard from './mail-wizard';
import push from './push';
import switchRegion from './switchRegion';
import switchLanguage from './switchLanguage';
import abandon from './abandon';
import searchWizard from './search-wizard';
import synonyms from './synonyms';
import searchInsights from './search-insights';
import sgfDivTable from './sgf-div-table';
import segment from './segment';
import jbWizard from './jb-wizard';
import facetedSearch from './faceted-search/index';
import userManagement from './user-management/index';
import switchDataCenter from './switchDataCenter';
import managedServices from './managed-services/index';
import rbs from './rbs/index';
import ds from './ds/index';

export default combineReducers({
  ui,
  user,
  switchedUser,
  date,
  modal,
  dialog,
  wizard,
  mailWizard,
  searchWizard,
  filters,
  campaigns,
  notification,
  reports,
  categories,
  brands,
  labels,
  push,
  switchRegion,
  switchDataCenter,
  switchLanguage,
  abandon,
  synonyms,
  searchInsights,
  sgfDivTable,
  segment,
  jbWizard,
  facetedSearch,
  userManagement,
  managedServices,
  rbs,
  ds,
});
