"use strict";

import React from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { SortableHandle } from "react-sortable-hoc";
import { decodeHtml } from "../../../system/string";
import Icon from "../../icon";
import Icons from "../../icons";
import Tooltip from "../../tooltip";
import { searchCategories } from "../../../modules/category/ajax";
import { searchProducts } from "../../../modules/product/ajax";
import { t } from "../../../system/ui";
import { items } from "../../../constants/datamaps/wizard";
import {getBrands} from "../../../modules/account/ajax";
import classNames from "classnames";

class JourneyBuilderStatic extends React.Component {
  constructor(props) {
    super(props);
    this.staticRef = React.createRef();

    this.state = {
      options: [],
      criterion: ""
    };
    this.onChange = this.onChange.bind(this);
    this.onBrandChange = this.onBrandChange.bind(this);
    this.onRecommendChange = this.onRecommendChange.bind(this);
    this.setCriteria = this.setCriteria.bind(this);
    this.setBrands = this.setBrands.bind(this);
    this.setStaticProducts = this.setStaticProducts.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    this.props.mainType === "BRAND" ? this.setBrands() :
        this.props.mainType === "CATEGORY" ? this.setCriteria() :
        this.setStaticProducts();
  }

  setBrands() {
    getBrands().then(response => {
      let sorted = response.sort();
      let brands = [];
      sorted.map(item => {
        brands.push({
          value: item,
          label: decodeHtml(item)
        });
      });
      if (this.props.values.criterion) {
        brands.push({
          value: this.props.values.criterion.value,
          title: decodeHtml(this.props.values.criterion.value),
          label: decodeHtml(this.props.values.criterion.value)
        });
        this.setState({options: brands,criterion: this.props.values.criterion.value});
      }else{
        this.setState({options: brands});
      }
    });
  }

  setCriteria() {
    if (this.props.values.criterion) {
      this.setState(
        {
          criterion: this.props.values.criterion.value,
          options: [
            {
              value: this.props.values.criterion.value,
              title: decodeHtml(this.props.values.criterion.value),
              label: decodeHtml(this.props.values.criterion.value),
            }
          ]
        }
      );
    }
  }

  setStaticProducts() {
    let products=[];
    if (this.props.values.criterion && this.props.values.criterion.value) {
        this.props.staticProducts.forEach((product) => {
          if (product) {
            products.push({
              value: product.productId,
              title: product.productId + " - " + decodeHtml(product.name),
              label: decodeHtml(product.name),
            });
          }
        });
        this.setState({
          criterion: this.props.values.criterion.value,
          options: products,
        });
    }
  }


    onBrandChange(selectedValue) {
    let selected = "value" in selectedValue ? selectedValue.value : this.state.criterion;
    this.setState(
        {criterion: selected},
        () => this.props.onCriteriaChange(this.staticRef, this.props.id, selected)
    );
  }

  onChange(selectedValue) {
    let selected = "value" in selectedValue ? selectedValue.value : this.state.criterion;
    this.setState(
        {criterion: selected,
          options: [
            {
              value: selected,
              title: decodeHtml(selected),
              label: decodeHtml(selected),
            }
          ]
        },
        () => this.props.onCriteriaChange(this.staticRef, this.props.id, selected)
    );
  }

  onRecommendChange(selected) {
    this.setState(
        {
          criterion: selected.value,
          options: [
            {
              value: selected.value,
              title: decodeHtml(selected.title),
              label: decodeHtml(selected.label),
            }
          ]
        },
        () => this.props.onCriteriaChange(this.staticRef, this.props.id, selected)
    );
  }

  onInputChange(value,type) {
    if (value.length > 2) {
      if(type==="CATEGORY"){
        searchCategories(value, (response) => {
          let categoryOptions = [];
          if (response) {
            response.forEach((category) => {
              categoryOptions.push({
                value: category,
                title: decodeHtml(category),
                label: decodeHtml(category),
              });
            });
          }
          this.setState({
            options: categoryOptions,
          });
        });
      }else{
        searchProducts(value, (response) => {
          let productOptions = [];
          if (response) {
            response.forEach((product) => {
              productOptions.push({
                value: product.productId,
                title: product.productId + " - " + decodeHtml(product.name),
                label: decodeHtml(product.name),
              });
            });
          }
          this.setState({
            options: productOptions,
          });
        });
      }
    } else {
      if (this.state.criterion) {
        this.setState({
          options: [
            {
              value: this.state.criterion,
              title: decodeHtml(this.state.criterion),
              label: decodeHtml(this.state.criterion),
            },
          ],
        });
      } else {
        this.setState({
          options: [],
        });
      }
    }
    return value;
  }

  render() {
    let  theItemCount = this.props.values.itemCount  ? this.props.values.itemCount.value : 1;
    let className = this.props.mainType==='RECOMMEND' ? "criteria-name-jb": "criteria-name-search";
    let iconName = this.props.mainType === "RECOMMEND" ? "product" : this.props.mainType==='BRAND' ? "star" : "dashboard";
    let placeholder = this.props.mainType === "RECOMMEND" ? t("Search Product") :
        this.props.mainType==='BRAND' ? t("Select Brand") : t("Search Categories");
    let change = this.props.mainType === "BRAND" ? (e) => this.onBrandChange(e) :
        this.props.mainType === "RECOMMEND" ? (e) => this.onRecommendChange(e):
            (e) => this.onChange(e);

    const DragHandle = SortableHandle(() => (
      <span className="draghandler">
        <Icons name="burger" color="#e8e7f2" />
      </span>
    ));

    return(
        <li className="wizard-criterion" ref={this.staticRef}>
        <DragHandle />
        <span className="criteria-type">
          {
            < Tooltip content={t("Static (Category)")} alignment="left">
                <Icon name={iconName}/>
              </Tooltip>
          }
        </span>

        <label className={classNames("item item-field is-select", className)}>
          <Select
            value={this.state.criterion}
            options={this.state.options}
            name="criterion"
            clearable={false}
            searchable={this.props.mainType === "BRAND" ? false : true}
            onChange={change}
            onInputChange= {this.props.mainType !== "BRAND" ? (e)=>this.onInputChange(e,this.props.mainType) : null}
            className="one-whole criteria-field"
            openOnClick={this.props.mainType === "Brand" ? true : false}
            placeholder={placeholder}
          />
        </label>
  
        <label className="item item-field is-select criteria-item-count">
          <span className="item-label">{this.props.mainType.toLowerCase()}</span>{" "}
          <select
            name="itemCount"
            className="one-whole criteria-field"
            disabled={true}
            onChange={this.onChange}
            defaultValue={theItemCount}
          >
            {items.map((item) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </label>
  
        <a
          onClick={this.props.onCriteriaRemove.bind(null, this.props.id)}
          className={"cancel-action"}
        >
          <Icons name="crossCircle" />
        </a>
      </li>
    )
  }
}

const mapStatesToProps = (store) => ({
  discovery: store.jbWizard.discovery,
  delivery: store.jbWizard.delivery
});

export default connect(mapStatesToProps)(JourneyBuilderStatic);
