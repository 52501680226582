import React from 'react';
import Filters from '../components/filters/filters';
import classNames from 'classnames';
import { t } from '../system/ui';
import SearchInsightsGeneral from '../components/search/insights/search-insights-general';
import SearchInsightsAssets from '../components/search/insights/search-insights-assets';
import connect from 'react-redux/es/connect/connect';
import { setTitle } from '../system/document';
import { filterActions, dateActions, searchInsightsActions } from '../actions';
import LanguageSwitcher from '../components/nav/language-switcher';
import { queryDate } from '../system/date';
import { exportCb } from '../components/search/insights/utils/common-capabilities';

const searchInsightsTabs = [
  {
    name: 'general',
    text: 'General',
    component: SearchInsightsGeneral,
  },
  {
    name: 'assets',
    text: 'Assets',
    component: SearchInsightsAssets,
  },
];

class SearchInsights extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: searchInsightsTabs[0],
      range: this.props.range,
    };
  }

  componentDidMount() {
    filterActions.filtersUpdated();
    filterActions.filtersReset();
    setTitle(t('Search Insights Reports'));
    this.setState({
      selectedTab: searchInsightsTabs[0],
      range: this.props.range,
    });
    searchInsightsActions.openDetailTable('');
    if (this.props.useDefaultOpening)
      dateActions.updateRangeAlias('lastSevenDays', true);
  }
  componentWillUnmount() {
    if (this.props.useDefaultOpening)
      dateActions.updateRangeAlias('weekly', true);
  }

  componentWillReceiveProps(newProps) {
    filterActions.filtersUpdated();
    filterActions.filtersReset();
    if (
      newProps.tableName &&
      this.props.tableName !== newProps.tableName &&
      newProps.tableName !== ''
    ) {
      if (newProps.tableName.startsWith(this.state.selectedTab.name)) {
        this.setState({
          tableName: newProps.tableName,
        });
      } else {
        this.setState({
          tableName: newProps.tableName,
          selectedTab: newProps.tableName.startsWith('assets_')
            ? searchInsightsTabs[1]
            : searchInsightsTabs[0],
        });
      }
    }
    if (newProps.range) {
      this.forceUpdate();
    }
  }

  onSelectedTabChange(tab) {
    this.setState({
      selectedTab: tab,
      tableName: '',
    });
    searchInsightsActions.changeTab(tab);
    searchInsightsActions.openDetailTable('');
  }

  export = () => {
    exportCb(
      queryDate(this.props.range[0]),
      queryDate(this.props.range[1]),
      this.props.selectedLanguage,
      'all&device=all',
    );
  };

  render() {
    let SearchInsightComponent = this.state.selectedTab.component;

    return (
      <div className={'search__insights'}>
        <Filters isSearchInsights={true} />

        <h2 className='page-title'>{t('Insights')}</h2>

        <div className='vertical-content'>
          <LanguageSwitcher />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ul className='stats-modules'>
              {searchInsightsTabs.map(tab => {
                return (
                  <li
                    key={tab.name}
                    onClick={this.onSelectedTabChange.bind(this, tab)}
                    className={classNames('tab-item', {
                      'active-module': this.state.selectedTab.name === tab.name,
                    })}
                  >
                    {t(tab.text)}
                  </li>
                );
              })}
            </ul>
            {(this.props.tabName === 'general' || this.props.tabName === '') &&
              this.props.tableName === '' && (
                <a className='secondary-action' onClick={this.export}>
                  {t('Export All')}
                </a>
              )}
          </div>
          <SearchInsightComponent tableName={this.state.tableName} />
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  range: store.date.range,
  useDefaultOpening: store.date.useDefaultOpening,
  tabName: store.searchInsights.tabName,
  tableName: store.searchInsights.tableName,
  selectedLanguage: store.switchLanguage.selectedLanguage,
});

export default connect(mapStatesToProps)(SearchInsights);
