import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { jbInitialData } from '../../constants/datamaps/journey-builder';
import { campaignInitialData } from '../../constants/datamaps/palantir';
import { params } from '../../constants/datamaps/wizard';
import {
  campaignTemplate,
  keywordTemplate,
  promotionTemplate,
  preJS,
  preJSKeyword,
  postJS,
  preJSPromotion,
  popupRecoTemplate,
  stickyRecommendationHtmlTemplate,
  stickyRecommendationCssTemplate,
  stickyRecommendationPreJsTemplate,
  stickyRecommendationPostJsTemplate,
  shopSimilarHtmlTemplate,
  shopSimilarPreJsTemplate,
  shopSimilarPostJsTemplate,
  shopSimilarCSSTemplate,
} from '../../constants/template';
import { t } from '../../system/ui';
import Snippet from '../snippet';
import Icons from '../icons';

const campaignDefaults = {
  keyword: {
    html: keywordTemplate,
    preJS: preJSKeyword,
  },
  promotion: {
    html: promotionTemplate,
    preJS: preJSPromotion,
  },
  recommendation: {
    html: campaignTemplate,
    preJS,
  },
  popup_recommendation: {
    html: popupRecoTemplate,
    preJS,
    css: '',
  },
  palantir: {
    html: campaignInitialData.html,
    preJS: campaignInitialData.preJs,
    css: '',
  },
  jbTrigger: {
    html: jbInitialData.html,
    preJS: jbInitialData.prejs,
  },
  jbDelivery: {
    html: jbInitialData.deliveryAdvancedConfig.html,
    preJS: jbInitialData.deliveryAdvancedConfig.prejs,
  },
  stickyRecommendation: {
    html: stickyRecommendationHtmlTemplate,
    css: stickyRecommendationCssTemplate,
    preJS: stickyRecommendationPreJsTemplate,
    postJS: stickyRecommendationPostJsTemplate,
  },
  shopSimilar: {
    html: shopSimilarHtmlTemplate,
    preJS: shopSimilarPreJsTemplate,
    postJS: shopSimilarPostJsTemplate,
    css: shopSimilarCSSTemplate,
  },
};

const languages = {
  html: 'htmlmixed',
  prejs: 'javascript',
  postjs: 'javascript',
  css: 'css',
};

const getDefaultHTML = campaignType =>
  campaignDefaults[campaignType] || campaignDefaults.recommendation;

class WizardAdvancedConfig extends React.Component {
  constructor(props) {
    super(props);
    let postjsTemplate = postJS;
    if (
      this.props.campaignType === 'shopSimilar' ||
      this.props.campaignType === 'stickyRecommendation'
    ) {
      postjsTemplate = getDefaultHTML(this.props.campaignType).postJS;
    }
    this.state = {
      snippet: getDefaultHTML(this.props.campaignType).html,
      htmlTemplate: getDefaultHTML(this.props.campaignType).html,
      prejsTemplate: getDefaultHTML(this.props.campaignType).preJS,
      postjsTemplate,
      cssTemplate: getDefaultHTML(this.props.campaignType).css || '',
      codetab: 'html',
      codeLanguage: 'htmlmixed',
      showAdvancedConfig: false,
      isDefault: true,
      status: '',
    };

    this.setValues = this.setValues.bind(this);
    this.toggle = this.toggle.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.checkDefault = this.checkDefault.bind(this);
    this.saveTabs = this.saveTabs.bind(this);
    this.handleCriteriaChange = this.handleCriteriaChange.bind(this);
  }

  componentDidMount() {
    if (this.props.duplicate && this.props.duplicate.isDuplicate) {
      this.setValues();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.show === true) {
      if (this.props.isPalantir || this.props.isJourneyBuilder) {
        this.setState({
          showAdvancedConfig: true,
        });
      } else {
        this.setState({
          showAdvancedConfig: true,
          status: newProps.campaignSpecifics.state.campaignStatus
            ? newProps.campaignSpecifics.state.campaignStatus
            : '',
        });
      }
    }

    if (newProps.submittingButton !== '') {
      this.setState({
        status: newProps.submittingButton,
      });
    }

    if (this.props.campaignType === 'jbDiscovery') {
      if (this.props.jbCriteria !== newProps.jbCriteria) {
        const snippet =
          newProps.jbCriteria.advanceConfig[
            newProps.jbCriteria.advanceConfig.codetab
          ];
        this.setState({ snippet });
      }
    }

    if (this.props.campaign !== newProps.campaign) {
      if (this.props.isPalantir) {
        let tab = this.state.codetab;
        if (tab === 'prejs') {
          tab = 'preJs';
        }

        if (tab === 'postjs') {
          tab = 'postJs';
        }

        const snippet = newProps.campaign[tab] || '';
        this.setState(
          {
            snippet,
            htmlTemplate: newProps.campaign.html || '',
            prejsTemplate: newProps.campaign.preJs || '',
            postjsTemplate: newProps.campaign.postJs || '',
            cssTemplate: newProps.campaign.css || '',
          },
          () => {
            this.refs.editor.refresh();
          },
        );
      } else if (this.props.campaignType === 'jbDelivery') {
        this.setState({
          snippet: newProps.campaign.actions[2].params.recommendationTemplate,
          htmlTemplate:
            newProps.campaign.actions[2].params.recommendationTemplate,
          prejsTemplate: newProps.campaign.actions[2].params.preJsCode,
          postjsTemplate: newProps.campaign.actions[2].params.postJsCode,
          cssTemplate: newProps.campaign.actions[2].params.cssCode,
        });
      } else if (this.props.campaignType !== 'jbDiscovery') {
        this.setState({
          snippet: newProps.campaign.actions[0].params.recommendationTemplate,
          htmlTemplate:
            newProps.campaign.actions[0].params.recommendationTemplate,
          prejsTemplate: newProps.campaign.actions[0].params.preJsCode,
          postjsTemplate: newProps.campaign.actions[0].params.postJsCode,
          cssTemplate: newProps.campaign.actions[0].params.cssCode,
        });
      }
    }
  }

  componentDidUpdate() {
    this.refs.editor.refresh();
  }

  handleCriteriaChange(snippet, codeTab) {
    const { jbCriteria } = this.props;
    if (jbCriteria && jbCriteria.advanceConfig) {
      jbCriteria.advanceConfig[jbCriteria.advanceConfig.codetab] = snippet;
      if (codeTab) {
        jbCriteria.advanceConfig.codetab = codeTab;
        this.setState({
          snippet: jbCriteria.advanceConfig[jbCriteria.advanceConfig.codetab],
        });
      }
      this.props.handleAdvanceConfigChange(jbCriteria);
    }
  }

  setValues() {
    this.setState({
      snippet: this.props.html,
      htmlTemplate: this.props.html,
      prejsTemplate: this.props.prejs,
      postjsTemplate: this.props.postjs,
      cssTemplate: this.props.css,
    });
  }

  saveTabs(status) {
    if (this.props.campaignType === 'jbDiscovery') {
      this.handleCriteriaChange(this.refs.editor.state.value, null);
    } else {
      const lastSnippet = this.state.codetab;

      this.setState({
        [`${lastSnippet}Template`]: this.refs.editor.state.value,
      });
    }
    this.props.campaignSpecifics.validate(status);
  }

  toggle() {
    if (this.props.campaignType === 'jbDiscovery') {
      this.setState(
        { showAdvancedConfig: !this.state.showAdvancedConfig },
        () => this.handleCriteriaChange(this.refs.editor.state.value, null),
      );
    } else {
      const lastSnippet = this.state.codetab;
      this.setState({
        [`${lastSnippet}Template`]: this.refs.editor.state.value,
        showAdvancedConfig: !this.state.showAdvancedConfig,
      });
    }

    this.props.onHide();
    this.refs.editor.refresh();
  }

  changeTab(current) {
    if (this.props.campaignType === 'jbDiscovery') {
      this.handleCriteriaChange(this.refs.editor.state.value, current);
    } else {
      const lastSnippet = this.state.codetab;
      this.setState({
        codetab: current,
        codeLanguage: languages[current],
        snippet: this.state[`${current}Template`],
        [`${lastSnippet}Template`]: this.refs.editor.state.value,
      });
    }

    this.refs.editor.refresh();
  }

  checkDefault(val) {
    this.setState({
      isDefault: false,
      snippet: val,
    });
  }

  renderDevices = devices =>
    devices
      .filter(
        device =>
          this.props.device.indexOf(device) > -1 || this.props.device === 'ALL',
      )
      .map(device => (
        <span
          style={{
            margin: '2px',
            top: device === 'PC' ? '2px' : 'unset',
            position: device === 'PC' ? 'relative' : 'unset',
          }}
          key={device}
        >
          <Icons color='#adaeb5' name={device.toLowerCase()} />
        </span>
      ));

  render() {
    const devices = ['PC', 'MOBILE', 'ANDROID', 'IOS'];
    let discoveryConfig = {};
    if (this.props.campaignType === 'jbDiscovery') {
      if (this.props.jbCriteria) {
        discoveryConfig = this.props.jbCriteria.advanceConfig;
      }
    }

    let isDefault = false;
    if (!this.state.isDefault) {
      isDefault = false;
    } else if (
      this.state.htmlTemplate ===
        getDefaultHTML(this.props.campaignType).html &&
      this.state.prejsTemplate ===
        getDefaultHTML(this.props.campaignType).preJS &&
      this.state.postjsTemplate === postJS &&
      this.state.cssTemplate === getDefaultHTML(this.props.campaignType).css
    ) {
      isDefault = true;
    }
    return (
      <div
        className={classNames('wizard-advanced-config', {
          'is-hidden': !this.state.showAdvancedConfig,
        })}
      >
        <div className='campaign-variables'>
          {this.props.campaignSpecifics !== undefined &&
            (this.props.campaignSpecifics.state.campaign.testMode !== '' ? (
              <h3 className='page-title'>
                {this.state.status && (
                  <span
                    className={`status-label status-label-${this.state.status.toLowerCase()}`}
                  >
                    {this.state.status}
                  </span>
                )}
                <h5
                  className='page-title'
                  style={{ color: '#727277', display: 'block' }}
                >
                  {this.props.campaignSpecifics.state.eventName}
                </h5>
                {this.props.campaignSpecifics.state.title}

                {this.props.device !== undefined && (
                  <div>{this.renderDevices(devices)}</div>
                )}
              </h3>
            ) : (
              <div>
                <h3 className='page-title'>
                  <h5
                    className='page-title'
                    style={{ color: '#727277', display: 'block' }}
                  >
                    {this.props.campaignSpecifics.state.eventName}
                  </h5>
                  {this.props.campaignSpecifics.state.title}
                  {this.props.device !== undefined && (
                    <div>{this.renderDevices(devices)}</div>
                  )}
                </h3>
              </div>
            ))}
          {!this.props.isJourneyBuilder && (
            <>
              <a
                className={classNames('button gotest-action five-column')}
                onClick={this.saveTabs.bind(null, 'test')}
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {t('Go Test')}
              </a>
              <a
                className={classNames(
                  'button tertiary-action five-column golive-action',
                )}
                onClick={this.saveTabs.bind(null, 'live')}
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                {t('Go Live')}
              </a>
            </>
          )}

          {Object.keys(params).map(item => (
            <div key={item} className='campaign-variable-set'>
              <h3 className='page-title'>{params[item].title}</h3>
              {params[item].variables.map(variable => (
                <span key={variable} className='campaign-variable'>
                  {`{{${item}:${variable}}}`}
                </span>
              ))}
            </div>
          ))}
        </div>

        <div className='code-samples'>
          <nav className='code-sample-tabs'>
            <a
              className={classNames('code-sample-tab-nav', {
                current:
                  Object.keys(discoveryConfig).length > 0
                    ? discoveryConfig.codetab === 'html'
                    : this.state.codetab === 'html',
              })}
              onClick={this.changeTab.bind(null, 'html')}
            >
              {t('Edit HTML')}
            </a>
            <a
              className={classNames('code-sample-tab-nav', {
                current:
                  Object.keys(discoveryConfig).length > 0
                    ? discoveryConfig.codetab === 'prejs'
                    : this.state.codetab === 'prejs',
              })}
              onClick={this.changeTab.bind(null, 'prejs')}
            >
              {t('Edit PreJS')}
            </a>
            <a
              className={classNames('code-sample-tab-nav', {
                current:
                  Object.keys(discoveryConfig).length > 0
                    ? discoveryConfig.codetab === 'postjs'
                    : this.state.codetab === 'postjs',
              })}
              onClick={this.changeTab.bind(null, 'postjs')}
            >
              {t('Edit PostJS')}
            </a>
            {(this.props.campaignType === 'popup_recommendation' ||
              this.props.campaignType === 'stickyRecommendation' ||
              this.props.campaignType === 'shopSimilar' ||
              this.props.campaignType === 'rec' ||
              this.props.isPalantir ||
              this.props.isJourneyBuilder) && (
              <a
                className={classNames('code-sample-tab-nav', {
                  current:
                    Object.keys(discoveryConfig).length > 0
                      ? discoveryConfig.codetab === 'css'
                      : this.state.codetab === 'css',
                })}
                onClick={this.changeTab.bind(null, 'css')}
              >
                {t('Edit CSS')}
              </a>
            )}
          </nav>
          <div className='code-sample-tab'>
            <Snippet
              ref='editor'
              value={this.state.snippet}
              language={
                Object.keys(discoveryConfig).length > 0
                  ? languages[discoveryConfig.codetab]
                  : this.state.codeLanguage
              }
              checkDefault={this.checkDefault}
            />
          </div>
        </div>

        <a
          className='wizard-advanced-config-save tertiary-action'
          onClick={this.toggle}
        >
          {t('Back To Wizard')}
        </a>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  html: store.wizard.html,
  prejs: store.wizard.prejs,
  postjs: store.wizard.postjs,
  css: store.wizard.css,
});

export default connect(mapStatesToProps, null, null, { forwardRef: true })(
  WizardAdvancedConfig,
);
