"use strict";

import React from "react";
import { connect } from "react-redux";

import { searchStatuses, mediumFilters } from "../../constants/datamaps/campaigns";

import { t } from "../../system/ui";

import { campaignActions } from "../../actions";

import { Radio, RadioGroup, Checkbox, CheckboxGroup } from "../fields";

class StatusFilters extends React.Component {
    constructor(props) {
        super(props);

        this.toggleStatus = this.toggleStatus.bind(this);
        this.toggleMedium = this.toggleMedium.bind(this);
    }

    toggleStatus(value) {
        campaignActions.filterCampaigns(value);
    }

    toggleMedium(value) {
        campaignActions.filterMedium(value);
    }

    componentWillReceiveProps(newProps) {
        if (
            newProps.defaultStatus &&
            this.props.defaultStatus !== newProps.defaultStatus
        ) {
            this.toggleStatus("TEST");
        }
    }

    componentWillUnmount() {
        campaignActions.filterActiveCampaigns();
        campaignActions.filterMedium([]);
    }

    render() {
        const { showCheckbox, status } = this.props;
        return (
            <div className='status-filters'>
                <RadioGroup
                    name='status'
                    selectedValue={status}
                    onChange={this.toggleStatus}
                    className='parent-filters'
                >
                    {Object.keys(searchStatuses).map(item => {
                        return <Radio key={item} value={item} label={t(searchStatuses[item])} />;
                    })}
                </RadioGroup>

                {showCheckbox && (
                  <CheckboxGroup
                    checkedValues={this.props.medium}
                    name="medium"
                    onChange={this.toggleMedium}
                    className="filter-details"
                  >
                      {Object.keys(mediumFilters).map(item => {
                          return (
                            <Checkbox
                              key={item}
                              value={item}
                              label={t(mediumFilters[item])}
                            />
                          );
                      })}
                  </CheckboxGroup>
                )}
            </div>
        );
    }
}

StatusFilters.defaultProps = {
    showCheckbox: true,
};

const mapStatesToProps = store => ({
    status: store.campaigns.status,
    medium: store.campaigns.medium
});

export default connect(mapStatesToProps)(StatusFilters);
