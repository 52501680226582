import React from 'react';
import { useQuery, useMutation } from 'react-query';
import classes from './ContactList.module.scss';
import {
  openModal,
  isLoaded,
  confirmationDialog,
  showNotification,
} from '../../../actions/ui';
import { actions, campaignWidgets } from './constants/constants';
import {
  fetchContactList,
  deleteContactList,
} from './services/contactListServices';
import {
  SFYCampaignList,
  SFYCampaignStatus,
  SFYHeader,
  CampaignWidget,
} from '../../../components/sfy-components/module-exports';
import UploadContactListModal from './modals/UploadContactListModal';

const ContactList = ({ route }) => {
  const { name, type, description, actionTitle, actionIcon } = campaignWidgets;

  const { refetch, data } = useQuery('contactList', fetchContactList, {
    refetchOnWindowFocus: false,
    retry: 0,
    onSuccess: () => {
      isLoaded();
    },
    onError: () => {
      isLoaded();
    },
  });

  const { mutateAsync } = useMutation({
    mutationFn: itemname => deleteContactList(itemname),
    onSuccess: () => {
      refetch();
    },
  });

  const openUploadContactModal = (
    modalType,
    instanceId = null,
    listName = '',
  ) => {
    const content = () => (
      <UploadContactListModal
        continueHandler={refetch}
        instanceId={instanceId}
        listName={listName}
      />
    );

    openModal({
      className: 'upload-contact-list-modal',
      title:
        modalType === 'importNewUsers'
          ? 'Import New Users'
          : 'Upload Your Contact List',
      content,
      dynamicHeight: true,
    });
  };

  const reDesignedActions = () => {
    return actions.map(action => {
      const newAction = { ...action };

      if (newAction.handler === 'importNewAction') {
        newAction.actionFunc = (instanceId, listName) => {
          openUploadContactModal('importNewUsers', instanceId, listName);
        };
      }

      if (newAction.handler === 'removeAction') {
        newAction.actionFunc = (instanceId, item) => {
          confirmationDialog({
            title: 'Delete Contact List',
            content: 'Are you sure you want to delete this contact list?',
            onConfirm: () => {
              mutateAsync(item.name).then(res => {
                if (res) {
                  const content = () => {
                    return <div>{res}</div>;
                  };
                  showNotification({
                    content,
                  });
                }
              });
            },
          });
        };
      }
      return newAction;
    });
  };

  const renderContactList = (campaignList, campaignStatus, campaignTitle) => {
    return (
      <>
        <SFYCampaignStatus
          campaignStatus={campaignStatus}
          allCampaign={campaignList}
          campaignTitle={campaignTitle}
        />
        {campaignList.map(campaign => {
          const fixedCampaign = {
            instanceId: campaign.id,
            type: 'CONTACT_LIST',
            name: campaign?.firstName || '',
            startDate: campaign?.createDate || '',
            isProcessCompleted: campaign?.isProcessCompleted,
            customDetails: [
              `${campaign?.successDataCount || '0'} contacts`,
              ` - ${campaign?.bounceDataCount || '0'} bouncebacks`,
            ],
          };
          return (
            <SFYCampaignList
              route={route}
              campaign={fixedCampaign}
              campaignActions={reDesignedActions()}
              campaignUpdate={refetch}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      <SFYHeader pageTitle='Contact List' />
      <CampaignWidget
        name={name}
        type={type}
        description={description}
        actionTitle={actionTitle}
        actionIcon={actionIcon}
        action={openUploadContactModal}
      />
      <div className={classes['contact-list']}>
        {data?.contactLogs?.length > 0 &&
          renderContactList(data?.contactLogs, 'TEST', 'Your contact lists')}
      </div>
    </>
  );
};

export default ContactList;
