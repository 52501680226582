import React from "react";
import connect from "react-redux/es/connect/connect";
import {productUrl } from "../../../../modules/trendify/page";
import classNames from "classnames";

class SearchInsightsProductWithImageCell extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
      let textContentStyle = {
        // marginLeft: "calc(100% - 250px)",
        position: "relative",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "13px",
        lineHeight: "15px",
        color: "#4C4C50",
        padding: "5px",
        textDecoration: "underline"
      };

      if (this.props.data) {
        return (
          <div style={{ gridTemplateAreas: "'customDiv_layout1 customDiv_layout2' 'customDiv_layout1 customDiv_layout3'" }} className="sgf-div-table__cell flex-grow-2 sgf-div-table__custom-div-parent">
            <div className={"customDiv_layout1"} style={{gridArea: "customDiv_layout1", display: "grid"}}><img src={productUrl(this.props.data.image)} width="60"/></div>
            <div className={classNames("customDiv-text-content", "customDiv_layout2")} style={Object.assign({}, textContentStyle, {gridArea: "customDiv_layout2"})}><p>{this.props.data.name}</p></div>
            <div className={classNames("customDiv-text-content", "customDiv_layout3")} style={Object.assign({}, textContentStyle, {gridArea: "customDiv_layout3"})}><p>{this.props.data.productId}</p></div>
          </div>
        )
      } else {
        return (
          <div style={{ gridTemplateAreas: "'customDiv_layout1 customDiv_layout2' 'customDiv_layout1 customDiv_layout3'" }} className="sgf-div-table__cell flex-grow-2 sgf-div-table__custom-div-parent item-center">-</div>
        )
      }
    }
}

export default connect()(SearchInsightsProductWithImageCell);

