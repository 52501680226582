import React from "react";
import connect from "react-redux/es/connect/connect";
import {
  getNoResultSearchesSummary,
  getOverallTotalSearches,
  initialRequestQueryParams
} from "../../../modules/search/insights/ajax";
import { defaultFieldMap } from "./utils/table-utils";
import { t } from "../../../system/ui";
import SgfDivTable from "./sub-components/table/sgf-div-table";
import classNames from "classnames";
import { searchInsightsActions, uiActions } from "../../../actions";
import CardWrapper from '../../CardWrapper';
import { queryDate } from '../../../system/date';
import { exportCb } from './utils/common-capabilities';

class SearchInsightsNoResultSearches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tableName: "noResultSearches",
      tableHeaders: [
        {
          name: "No-Result Searches",
          fieldMap: defaultFieldMap["No-Result Searches"]
        },
        {
          name: "Total Searches",
          fieldMap: defaultFieldMap["No Result Total Searches"]
        },
        {
          name: "No-Result Ratio",
          fieldMap: defaultFieldMap["No-Result Ratio"]
        }
      ],
      requestParameter: Object.assign({}, initialRequestQueryParams, { limit: this.props.isDetail ? undefined : 5, sort: "count", reverse: true, device: 'all' })
    };

    this.getData = this.getData.bind(this);
    this.selectDevice = this.selectDevice.bind(this);
    this.showNoResultSearches = this.showNoResultSearches.bind(this);
    this.onClickBreadCrumb = this.onClickBreadCrumb.bind(this);

    this.deviceFilterLookup = ['PC', 'TABLET', 'MOBILE', "IOS", "ANDROID"];
  }

  componentDidMount() {
    this.setState({
      requestParameter: {
        ...this.state.requestParameter,
        device: "all",
        sort: "count",
        reverse: true,
        start: this.props.range[0],
        end: this.props.range[1],
        lang: this.props.selectedLanguage
      }
    }, () => {
      this.getData();
    });
  }

  componentWillReceiveProps(newProps) {
    let sortKey = this.state.requestParameter.sort;

    let reverse = this.state.requestParameter.reverse;
    let sortChanged = false;
    if (newProps.clickedCellItem
      && newProps.clickedCellItem.fieldMap
      && newProps.clickedCellItem.fieldMap.sortKey
      && this.state.tableName === newProps.clickedCellItem.tableName
      && this.isSortedChanged(this.props.clickedCellItemId, newProps.clickedCellItemId)
      ) {
      sortKey = newProps.clickedCellItem.fieldMap.sortKey;
      reverse = (this.state.requestParameter.sort === newProps.clickedCellItem.fieldMap.sortKey) ? !this.state.requestParameter.reverse : this.state.requestParameter.reverse;
      sortChanged = true;
    }
    if (newProps.range[0] && newProps.range[1]) {
      if (this.props.range[0] !== newProps.range[0]
        || this.props.range[1] !== newProps.range[1]
        || this.props.selectedLanguage !== newProps.selectedLanguage
        || sortChanged) {
        this.setState({
          requestParameter: {
            ...this.state.requestParameter,
            lang: newProps.selectedLanguage,
            sort: sortKey,
            reverse,
            start: newProps.range[0],
            end: newProps.range[1]
          }
        }, () => {
          this.getData();
        });
      }
    }
  }
  isSortedChanged = (currentId, nextId) => {
    if (currentId !== nextId) {
      return true;
    }
    return false;
  }
  selectDevice(selectedDevice) {
    this.setState({
      requestParameter: {
        ...this.state.requestParameter,
        lang: this.props.selectedLanguage,
        device: selectedDevice,
        start: this.props.range[0],
        end: this.props.range[1]
      }
    }, () => {
      this.getData();
    });
  }

  getData() {
    uiActions.isLoading();
    getOverallTotalSearches(this.state.requestParameter, response => {
      getNoResultSearchesSummary(Object.assign({}, this.state.requestParameter, { total: response.overallTotal }), noResultSearchSummaryData => {
        uiActions.isLoaded();
        this.setState({
          data: noResultSearchSummaryData
        });
      });
    });
  }

  showNoResultSearches() {
    searchInsightsActions.openDetailTable("general_searchInsightNoResultSearchesDetail");
  }

  onClickBreadCrumb() {
    searchInsightsActions.openDetailTable("");
  }

  export = () => {
    const { start, end, lang } = this.state.requestParameter;
    exportCb(queryDate(start), queryDate(end), lang, 'noresult&device=all');
  };

  render() {
    return (
      <div>
        {this.props.isDetail &&
        <div style={{display: "flex", justifyContent: "space-between", marginBottom: "5px"}}>
          <nav id="breadcrumb" className="breadcrumb search__breadcrumb">
            <a className="nav-item breadcrumb-item current" onClick={this.onClickBreadCrumb}>
              <i className="icon-asset" role="presentation">
                <span className="for-screenreader-only">asset icon</span>
              </i>{t("General")}</a>
            <span className="nav-item breadcrumb-item current">{t("No Result Searches")}</span></nav>
          <a className='secondary-action' onClick={this.export}>{t('Export')}</a>
        </div>
        }
        <CardWrapper
          hasDeviceFilter
          devicesLookup={this.deviceFilterLookup}
          devicesValue={this.selectDevice}
          label={t("No-Result Searches")}
          className="widget"
          infoContent={
            <div className="search__info-text">
              <strong> No-Result Searches: </strong>
              <span>The most searched but no-result words/phrases.</span>
            </div>
          }
        >
          <SgfDivTable data={this.state.data} headers={this.state.tableHeaders} tableName={this.state.tableName}/>
          {this.props.isDetail !== true &&
            <div style={{ textAlign: "right", marginRight: "10px" }}>
              <a className={classNames("button", "add-criteria")} onClick={this.showNoResultSearches.bind(this)}>
                {t("Show All")}
              </a>
            </div>
          }
        </CardWrapper>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
    range: store.date.range,
    rangeAlias: store.date.rangeAlias,
    clickedCellItem: store.sgfDivTable.clickedCellItem,
    clickedCellItemId: store.sgfDivTable.clickedCellItemId,
    selectedLanguage: store.switchLanguage.selectedLanguage,
});

export default connect(mapStatesToProps)(SearchInsightsNoResultSearches);
