import React, { useContext, useEffect, useState } from 'react';
import './SFYCardBundleProduct.scss';
import Select from 'react-select';
import { t } from '../../../../system/ui';
import { searchDetailedProducts } from '../../../../modules/product/ajax';
import { decodeHtml } from '../../../../system/string';
import { ErrorText } from '../../module-exports';
import { Context as ShopTheLookContext } from '../../../../views/dynamic-bundle/shop-the-look/store/shopTheLookProvider';
import Icons from '../../../icons';

const getBundleProductFromDetail = (bundleDetail, responseType) => {
  if (!bundleDetail?.id) {
    if (responseType === 'object') {
      return {
        value: '',
        label: '',
        title: '',
      };
    }
    if (responseType === 'array') {
      return [];
    }
  }

  const returnItem = {
    value: bundleDetail?.id,
    label: bundleDetail?.name,
    title: bundleDetail?.name,
    inStock: bundleDetail?.inStock || false,
    image: bundleDetail?.image || '',
    price: bundleDetail?.price || '',
    oldPrice: bundleDetail?.oldPrice || '',
  };

  return responseType === 'array' ? [returnItem] : returnItem;
};

const SFYCardBundleProduct = ({
  bundleIndex,
  bundleDetail,
  setBGColorClassName,
}) => {
  const {
    state: { bundleList },
    editSTLBundle,
  } = useContext(ShopTheLookContext);

  const [selectedProduct, setSelectedProduct] = useState(
    getBundleProductFromDetail(bundleDetail, 'object') || {
      value: '',
      label: '',
      title: '',
    },
  );
  const [products, setProducts] = useState(
    getBundleProductFromDetail(bundleDetail, 'array') || [],
  );
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    if (setBGColorClassName) {
      setBGColorClassName(
        selectedProduct.value && !selectedProduct?.inStock
          ? 'bg-color-red'
          : '',
      );
    }
  }, [selectedProduct.inStock]);

  const onInputChange = value => {
    setIsTouched(true);

    if (value.length > 2) {
      searchDetailedProducts(value, response => {
        const productOptions = [];
        const getSelectedProducts = bundleList?.map(({ id }) => id);

        if (response) {
          response.forEach(product => {
            if (getSelectedProducts?.length > 0) {
              if (!getSelectedProducts.includes(product.productId)) {
                productOptions.push({
                  value: decodeHtml(product.productId),
                  title: decodeHtml(product.name),
                  label: decodeHtml(product.name),
                  inStock: product?.inStock || false,
                  image: product?.image || '',
                  price: product?.price || '',
                  oldPrice: product?.oldPrice || '',
                });
              }
            } else {
              productOptions.push({
                value: decodeHtml(product.productId),
                title: decodeHtml(product.name),
                label: decodeHtml(product.name),
                inStock: product?.inStock || false,
                image: product?.image || '',
                price: product?.price || '',
                oldPrice: product?.oldPrice || '',
              });
            }
          });
        }
        setProducts(productOptions);
      });
    } else if (selectedProduct) {
      setProducts([
        {
          value: selectedProduct.value,
          title: selectedProduct.title,
          label: selectedProduct.label,
          inStock: selectedProduct?.inStock || false,
          image: selectedProduct?.image || '',
          price: selectedProduct?.price || '',
          oldPrice: selectedProduct?.oldPrice || '',
        },
      ]);
    } else {
      setProducts([]);
    }
  };

  const onChange = selectedValue => {
    if (!selectedValue) {
      setSelectedProduct('');
      setProducts([]);
      return;
    }

    setSelectedProduct(selectedValue);
    setProducts([
      {
        value: selectedValue.value,
        title: selectedValue.title,
        label: selectedValue.label,
        inStock: selectedValue?.inStock || false,
        image: selectedValue?.image || '',
        price: selectedValue?.price || '',
        oldPrice: selectedValue?.oldPrice || '',
      },
    ]);

    editSTLBundle({
      type: 'product',
      index: bundleIndex,
      id: selectedValue.value,
      name: selectedValue.label,
      inStock: selectedValue?.inStock || false,
      image: selectedValue?.image || '',
      price: selectedValue?.price || '',
      oldPrice: selectedValue?.oldPrice || '',
    });
  };

  useEffect(() => {
    setSelectedProduct(getBundleProductFromDetail(bundleDetail, 'object'));
    setProducts(getBundleProductFromDetail(bundleDetail, 'array'));
  }, [bundleDetail.id]);

  return (
    <div className='bundle-content bundle-product'>
      <span className='select-label'>Product:</span>
      <div className='select-field'>
        <Select
          value={selectedProduct.value}
          options={products}
          name='product'
          clearable={false}
          searchable
          onChange={e => onChange(e)}
          onInputChange={onInputChange}
          className={`bundle-select one-whole ${!selectedProduct &&
            isTouched &&
            'has-error'}`}
          openOnClick={false}
          placeholder={t('Select or Type')}
        />
        {!selectedProduct && isTouched && <ErrorText />}
      </div>
      {selectedProduct.value && !selectedProduct.inStock ? (
        <div className='sfy-item-out-of-stock'>
          <Icons name='crossCircle' color='#D31116' />
          <span>Out of stock</span>
        </div>
      ) : null}
    </div>
  );
};

export default SFYCardBundleProduct;
