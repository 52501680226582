/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";

import { t } from "../../system/ui";

import Icon from "../icon";
import Icons from "../icons";

import { TextField } from "../fields";

class IncludesCustom extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onOperatorChange = this.onOperatorChange.bind(this);
    this.setDefaults = this.setDefaults.bind(this);
    this.state = {
      isBetweenSelected: false,
      isNumberSelected: false,
      showValueError: false,
      subComponents: []
    };
  }

  componentDidMount() {
    this.setDefaults();
  }

  setDefaults() {
    this.props.subComponents.forEach(() => {});
    this.setState(
      {
        isBetweenSelected:
          typeof this.props.values.operation !== "undefined" &&
          this.props.values.operation.value === "BETWEEN",
        isNumberSelected:
          typeof this.props.values.operation !== "undefined" &&
          (this.props.values.operation.value === "BETWEEN" ||
            this.props.values.operation.value === "GREATER" ||
            this.props.values.operation.value === "LESS")
      },
      () => {
        this.onChange();
      }
    );
  }

  onChange() {
    this.props.onInclusionChange(this.refs.inputs, this.props.id);
    if (
      this.refs.inputs &&
      this.refs.inputs.querySelector("input[name='value']").value &&
      this.refs.otherValue &&
      this.refs.inputs.querySelector("input[name='otherValue']").value
    ) {
      this.setState({
        showValueError:
          parseFloat(
            this.refs.inputs.querySelector("input[name='value']").value
          ) >
          parseFloat(
            this.refs.inputs.querySelector("input[name='otherValue']").value
          )
      });
    }
  }

  onOperatorChange() {
    let operatorField = this.refs.inputs.querySelectorAll(
      ".operation-field"
    )[0];
    let betweenSelected = operatorField && operatorField.value === "BETWEEN";
    let numberSelected =
      operatorField &&
      (operatorField.value === "BETWEEN" ||
        operatorField.value === "GREATER" ||
        operatorField.value === "LESS");
    this.setState(
      {
        isBetweenSelected: betweenSelected,
        isNumberSelected: numberSelected
      },
      () => {
        this.props.onInclusionChange(this.refs.inputs, this.props.id);
      }
    );
  }

  render() {
    let parameter =
        typeof this.props.values.parameter !== "undefined"
          ? this.props.values.parameter.value
          : "",
      operator =
        typeof this.props.values.operation !== "undefined"
          ? this.props.values.operation.value
          : "",
      value =
        typeof this.props.values.value !== "undefined"
          ? this.props.values.value.value
          : "",
      otherValue =
        typeof this.props.values.otherValue !== "undefined"
          ? this.props.values.otherValue.value
          : "";
    return (
      <li className="wizard-criterion" ref="inputs">
        <span className="criteria-type">
          <Icons name="excludeCustom" color="#787774" />
        </span>
        <div className="wizard-criterion-text">
          <h3 className="wizard-criterion-title">{t("Custom")}</h3>
          <p className="wizard-criterion-description">
            {t(
              "Custom filter allows you to include custom filtering such as displaying products based on rating or reviews."
            )}
          </p>
        </div>
        <TextField
          name="parameter"
          defaultValue={parameter}
          className="item-field criteria-name wizard-params"
          inputClassName="one-whole criteria-field"
          placeholder="Param"
          required={true}
          onChange={this.onChange}
        />

        <label className="item item-field is-select criteria-name wizard-operator">
          <select
            name="operation"
            className="one-whole criteria-field operation-field"
            onChange={this.onOperatorChange}
            defaultValue={operator}
          >
            <option value="EQUALS">{t("Equals")}</option>
            <option value="NOT_EQUALS">{t("Not Equals")}</option>
            <option value="CONTAINS">{t("Contains")}</option>
            <option value="NOT_CONTAINS">{t("Not Contains")}</option>
            <option value="GREATER">{t("Greater Than")}</option>
            <option value="LESS">{t("Less Than")}</option>
            <option value="BETWEEN">{t("Between")}</option>
          </select>
        </label>

        <TextField
          name="value"
          defaultValue={value}
          className={
            this.state.isBetweenSelected
              ? "item-field criteria-name wizard-value criteria-between"
              : "item-field criteria-name wizard-value"
          }
          inputClassName="one-whole criteria-field"
          placeholder="Value"
          required={true}
          onChange={this.onChange}
          type={this.state.isNumberSelected ? "number" : "text"}
          ref="mainValue"
        />

        {this.state.isBetweenSelected && (
          <TextField
            name="otherValue"
            defaultValue={otherValue}
            className="item-field criteria-name wizard-value criteria-between"
            inputClassName="one-whole criteria-field"
            placeholder="Other Value"
            required={true}
            onChange={this.onChange}
            type="number"
            ref="otherValue"
          />
        )}

        {this.state.showValueError && (
          <span className="has-error item-error" style={{ display: "block" }}>
            {t("First value must be less than second value")}
          </span>
        )}

        <div className="buttons-criteria buttons-criteria-custom">
          <a
            onClick={this.props.onInclusionAdd.bind(null, "custom")}
            className="button-criteria"
          >
            <Icons name="plusCircle" width="32" height="32" />
          </a>
          <a
            onClick={this.props.onInclusionRemove.bind(null, this.props.id)}
            className="remove-criteria button-criteria"
          >
            <Icons name="crossCircle" />
          </a>
        </div>
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate,
  includes: store.wizard.includes
});

export default connect(mapStatesToProps)(IncludesCustom);
