import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { openUrlInNewTab } from '../../utils';

const VideoCard = ({ videoUrl }) => {
  return (
    <div className='video-card'>
      <div className='video-card-content'>
        <iframe
          title='welcome-video'
          src={videoUrl}
          frameBorder='0'
          allowFullScreen
        />
      </div>
    </div>
  );
};

VideoCard.propTypes = {
  videoUrl: PropTypes.string.isRequired,
};

const EgsVideos = () => {
  const egsVideos = useSelector(state => state.managedServices.egsVideos);
  const [videoItems, setVideoItems] = useState([]);

  useEffect(() => {
    setVideoItems(egsVideos.data);
  }, [egsVideos]);

  return (
    <div className='welcome-group-field'>
      <div className='welcome-title'>Latest Episodes from EGS</div>
      <div
        className='welcome-redirect-link'
        onClick={() => {
          openUrlInNewTab('https://www.segmentify.com/ecommerce-growth-show');
        }}
      >
        <span>Guest EGS Community {'>'}</span>
      </div>
      <div className='welcome-content'>
        <div className='welcome-column'>
          {videoItems.length <= 0 &&
            Array.from(Array(3).keys()).map(_index => (
              <div key={_index} className='video-card'>
                <div className='video-card-content' />
              </div>
            ))}

          {videoItems.map(item => (
            <VideoCard key={item.videoId} videoUrl={item.videoUrl} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EgsVideos;
