import React, { Component } from 'react';
import { connect } from 'react-redux';

import Select from 'react-select';
import { uiActions } from '../../../actions';
import { t } from '../../../system/ui';

import Tooltip from '../../../components/tooltip';

const options = [
  { value: 'MONTLY', label: t('Monthly') },
  { value: 'WEEKLY', label: t('Weekly') },
  { value: 'DAILY', label: t('Daily') },
];

class RockScoreFrameType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 'div#rockScoreFrameType',
    };

    this.updateValue = this.updateValue.bind(this);
    this.timeFrameChanged = this.timeFrameChanged.bind(this);
  }

  componentDidMount() {
    const input = this.props.campaign.rockScoreFrameType || 'MONTLY';
    this.updateValue(input);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.rockScoreFrameType || 'MONTLY');
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  timeFrameChanged(selectedOptions) {
    this.setState({
      value: selectedOptions.value,
    });

    uiActions.formEdited();
  }

  render() {
    return (
      <li className='item-title-field'>
        <span className='item item-stacked label-emphasise one-whole'>
          <span className='item-label'>
            {t(this.props.options.label)}
            {this.props.options.tooltip && (
              <span style={{ marginTop: '-5px' }}>
                <Tooltip
                  content={t(this.props.options.tooltipContent)}
                  alignment='right'
                >
                  <i className='icon-info' role='presentation'>
                    <span className='for-screenreader-only' />
                  </i>
                </Tooltip>
              </span>
            )}
          </span>
        </span>
        <label className='item is-select one-whole'>
          <Select
            searchable={false}
            clearable={false}
            name='insertType'
            options={options}
            value={this.state.value}
            onChange={this.timeFrameChanged}
          />
        </label>
      </li>
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  RockScoreFrameType,
);
