import {
  latestFeaturesModalTypes,
  insightsTrendifyCardTypes,
  playbookTypes
} from "./enums";

import featuresDynamicSegmentation
  from "./images/features-dynamic-segmentation.png";
import featuresPersonaQuiz from "./images/features-persona-quiz.png";

import BannerCrossSell from "./images/home-banner-crossSell.png";
import BannerRBS from "./images/home-banner-rbs.png";
import BannerDominosBoost from "./images/home-banner-dominos.png";
import Banner01 from "./images/home-banner-01.png";
import Banner02 from "./images/home-banner-02.png";
import Banner03 from "./images/home-banner-03.png";
import Banner05 from "./images/home-banner-5.gif";
import BannerSocialProof from "./images/home-banner-social-proof.png";
import MspArtOfCustomer from "./images/msp-art-of-customer.png";
import MspSocialProof from "./images/msp-social-proof.png";
import MspEmailMarketing from "./images/msp-email-marketing.png";

// Playbook Search Images
import playbookSearchBoxOneImage from "./images/playbook-searchbox-1.png";
import playbookSearchBoxTwoImage from "./images/playbook-searchbox-2.png";
import playbookSearchBoxThreeImage from "./images/playbook-searchbox-3.png";
import playbookSearchBoxFourImage from "./images/playbook-searchbox-4.png";
import playbookSearchBoxFiveImage from "./images/playbook-searchbox-5.png";

// Playbook Black Friday Images
import playbookBlackFridayOneImage from "./images/playbook-blackfriday-1.png";
import playbookBlackFridayTwoImage from "./images/playbook-blackfriday-2.png";
import playbookBlackFridayThreeImage from "./images/playbook-blackfriday-3.png";
import playbookBlackFridayFourImage from "./images/playbook-blackfriday-4.png";
import playbookBlackFridayFiveImage from "./images/playbook-blackfriday-5.png";

// Playbook Push Images
import playbookPushOneImage from "./images/playbook-push-1.png";
import playbookPushTwoImage from "./images/playbook-push-2.png";
import playbookPushThreeImage from "./images/playbook-push-3.png";
import playbookPushFourImage from "./images/playbook-push-4.png";
import playbookPushFiveImage from "./images/playbook-push-5.png";
import playbookPushSixImage from "./images/playbook-push-6.png";

// Recommendation Push Images
import playbookRecommendationOneImage
  from "./images/playbook-recommendation-1.png";
import playbookRecommendationTwoImage
  from "./images/playbook-recommendation-2.png";
import playbookRecommendationThreeImage
  from "./images/playbook-recommendation-3.png";
import playbookRecommendationFourImage
  from "./images/playbook-recommendation-4.png";
import playbookRecommendationFiveImage
  from "./images/playbook-recommendation-5.png";
import playbookRecommendationSixImage
  from "./images/playbook-recommendation-6.png";
import playbookRecommendationSevenImage
  from "./images/playbook-recommendation-7.png";

// Searchandising Push Images
import playbookSearchandisingOneImage
  from "./images/playbook-searchandising-1.png";
import playbookSearchandisingTwoImage
  from "./images/playbook-searchandising-2.png";
import playbookSearchandisingThreeImage
  from "./images/playbook-searchandising-3.png";
import playbookSearchandisingFourImage
  from "./images/playbook-searchandising-4.png";
import playbookSearchandisingFiveImage
  from "./images/playbook-searchandising-5.png";

// Quick Actions Icons
import UmbrellaIcon from "./icons/UmbrellaIcon";
import LightBulbIcon from "./icons/LightBulbIcon";
import MegaphoneIcon from "./icons/MegaphoneIcon";
import RocketIcon from "./icons/RocketIcon";

import SearchBoxPlaybookCard from "./icons/SearchBoxPlaybookCard";
import BlackFridayPlaybookCard from "./icons/BlackFridayPlaybookCard";
import PushPlaybookCard from "./icons/PushPlaybookCard";
import RecommendationPlaybookCard from "./icons/RecommendationPlaybookCard";
import SearchandisingPlaybookCard from "./icons/SearchandisingPlaybookCard";

// Insights Trendify Icons
import InsightsTrendifyCardBg1 from "./icons/InsightsTrendifyCardBg1";
import InsightsTrendifyCardBg2 from "./icons/InsightsTrendifyCardBg2";

export const featureItems = [
  {
    id: 2,
    title: "Getting to know the website visitors better with Persona Quiz!",
    descriptions: [
      "Meet the new Engagement | Engagement campaign type: Persona Quiz. This new feature aims to prepare simple and straightforward quizzes for visitors and assign them to the corresponding dynamic segments. Persona Quizzes prepared by our clients will make audience segmentation much healthier. The store can ask the client directly about their skin type, fashion style choices, the purpose of their laptop usage, etc. This will definitely get the visitors to participate since it gamifies the shopping experience. And everybody loves games and rarely misses an opportunity to talk about themselves, right? How does this Persona Quiz work exactly? The client has to set at least 2 different answers (max 4) and designate each answer to a dynamic segment. The visitors can choose multiple answers since it’s highly possible that they belong in more than one dynamic segment. The website can also adjust when and where this quiz will pop up on the page."
    ],
    image: featuresPersonaQuiz,
    redirectUrl:
      "https://drive.google.com/file/d/1ieGW3Valqjw8hlibixyGCtRwvDLVsuYB/view",
    redirectText: "Meet the Engagement",
    styleType: latestFeaturesModalTypes.COLUMN
  },
  {
    id: 3,
    title: "Using Segmentify’s own segments to show Personalised Ads!",
    descriptions: [
      "How does the ability to transfer segment, user and external network id information collected by Segmentify to ad networks sound? And the ability to show visitors personalised ads based on their browsing behaviour? Pretty exciting, right? The best part is that revolutionising digital advertising is a lot easier than you might think! First, the client has to open up a Facebook Business Manager account. Then, they need to download the Segmentify app. After we get the authorisation from the client, the integration is complete, and we start matching the Facebook data on their site with Segmentify’s data. We get to feed our segments into the client’s Facebook database. Users are matched using email, phone number and user id."
    ],
    image: "",
    redirectUrl: "",
    redirectText: "",
    styleType: latestFeaturesModalTypes.NO_IMAGE
  },
  {
    id: 4,
    title:
      "A new user interface that helps manage Promotion Banners is live! Meet the Promotion UI",
    descriptions: [
      "We are now able to manage all banners from one place. Instead of searching for banners in each campaign, we will have the comfort of listing all expired and live banners in the Promotion List. Banners no longer need to be live on the site to be addressed in the panel. Creating new banners by entering images and URLs on the panel is now possible.We can add banners that are not yet published on the site as promotions and use them in algorithms. "
    ],
    image: "",
    redirectUrl: "",
    redirectText: "",
    styleType: latestFeaturesModalTypes.NO_IMAGE
  }
];

export const InsightHorizontalChartItems = [
  {
    title: "Top Sellers",
    value: 0.345,
    color: "#FB8832"
  },
  {
    title: "Last Visit Alternatives",
    value: 0.23,
    color: "#0177F9"
  },
  {
    title: "We Missed You",
    value: 0.115,
    color: "#9B51E0"
  },
  {
    title: "Price Drop",
    value: 0.115,
    color: "#81F26F"
  },
  {
    title: "Order Follow Up",
    value: 0.115,
    color: "#3BCE23"
  },
  {
    title: "Others",
    value: 0.08,
    color: "#C4C4C4"
  }
];

export const InsightVerticalChartItems = [
  {
    title: "Mon",
    value: 25
  },
  {
    title: "Tue",
    value: 25
  },
  {
    title: "Wed",
    value: 25
  },
  {
    title: "Thur",
    value: 75
  },
  {
    title: "Fri",
    value: 25
  },
  {
    title: "Sat",
    value: 25
  },
  {
    title: "Sun",
    value: 25
  }
];

export const InsightTrendifyItems = [
  {
    title: "Discover Your Rockstar Products",
    text:
      "Your visitor can’t resist their buying/shoping instinct when they see these products. How about pinning them to your search box to get more revenue? |||Let’s see these products.|||",
    icon: InsightsTrendifyCardBg1,
    type: insightsTrendifyCardTypes.FULL_WIDTH
  },
  {
    title: "Convert Window Shoppers into Buyers",
    text:
      "Visitor love looking at these but they’re not buying these products. They are clearly interested, so why not offer discounts or special campaigns at the checkout? |||See what visitors are into.|||",
    icon: InsightsTrendifyCardBg2,
    type: insightsTrendifyCardTypes.FULL_WIDTH
  }
];

export const bannerItems = [
  {
    imageURL: MspArtOfCustomer,
    linkURL:
      "https://www.segmentify.com/wp-content/uploads/2023/01/The%20Art%20of%20Customer%20Engagement.pdf"
  },
  {
    imageURL: MspEmailMarketing,
    linkURL: "https://www.segmentify.com/blog/category/email-marketing"
  },
  {
    imageURL: MspSocialProof,
    linkURL:
      "https://www.segmentify.com/blog/create-urgency-and-convince-hesitant-shoppers-with-social-proof"
  },
  {
    imageURL: BannerSocialProof,
    linkURL:
      "https://www.segmentify.com/blog/create-urgency-and-convince-hesitant-shoppers-with-social-proof"
  },
  {
    imageURL: BannerRBS,
    linkURL:
      "https://www.segmentify.com/blog/rule-based-segmentation-for-more-personalised-campaigns"
  },
  {
    imageURL: BannerCrossSell,
    linkURL:
      "https://www.segmentify.com/blog/cross-selling-vs-upselling-definition-examples-tips"
  },
  {
    imageURL: BannerDominosBoost,
    linkURL:
      "https://www.segmentify.com/blog/how-dominos-pizza-personalised-its-ecommerce-experience-to-boost-conversion-rates-and-aov"
  },
  {
    imageURL: Banner01,
    linkURL:
      "https://www.segmentify.com/blog/product-zoom-in-dynamic-segmentation-persona-quiz"
  },
  {
    imageURL: Banner02,
    linkURL:
      "https://www.segmentify.com/blog/how-to-increase-conversion-rates-with-trendify-insights"
  },
  {
    imageURL: Banner03,
    linkURL: "https://www.segmentify.com/partners"
  },
  {
    imageURL: Banner05,
    linkURL: "https://www.segmentify.com/blog/g2-users-love-segmentify"
  }
];

export const quickActionItems = [
  {
    title: "Overstock for an item ?",
    text: "Shout out with an exit pop-up !",
    icon: UmbrellaIcon,
    redirectUrl: "https://www.segmentify.com/solutions/behavioural-targeting"
  },
  {
    title: "Uncertain between 2 designs?",
    text: "Start an A/B !",
    icon: LightBulbIcon,
    redirectUrl: ""
  },
  {
    title: "Have a new campaign?",
    text: "Apply a discount and send via bulk push now!",
    icon: MegaphoneIcon,
    redirectUrl:
      "https://www.segmentify.com/solutions/personalised-push-notifications"
  },
  {
    title: "Have an outbound idea?",
    text: "Create a Segment and reach to them now !",
    icon: RocketIcon,
    redirectUrl: ""
  }
];

export const PlaybookItemsOfSearchBox = [
  {
    title: "Adding Static Banners for Seasonal Campaigns",
    image: playbookSearchBoxOneImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Find seasonal campaign banners",
        "Add before & after Search Box",
        "Get seasonal campaigns target URL",
        "Add target URL as search banner URL"
      ]
    }
  },
  {
    title: "Add Synonyms for “No Results” Keywords",
    image: playbookSearchBoxTwoImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Find “no results” keywords from Search Insights",
        "Define synonym for them"
      ]
    }
  },
  {
    title: "Pin Products to Promote",
    image: playbookSearchBoxThreeImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Choose the product you want to promote",
        "Pin the product to Search Box based on the contains, starts with or exactly match"
      ]
    }
  },
  {
    title: "Bring Products from Specific Categories",
    image: playbookSearchBoxFourImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Find the category you specifically want to promote in the search box",
        "Boost this special category",
        "See that the searched word comes from the relevant category"
      ]
    }
  },
  {
    title: "Promote Brands",
    image: playbookSearchBoxFiveImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Define the brand you want to promote (might be based on sponsored agreements)",
        "Add a brand statically under popular brands",
        "Add the target url of the brand"
      ]
    }
  }
];

export const PlaybookItemsOfBlackFriday = [
  {
    title: "SEARCH-BOX BLACK FRIDAY BUTTON",
    image: playbookBlackFridayOneImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Prepare the black friday campaigns",
        "Create a black friday button on Search Box",
        "Add target URL as a Black Friday Campaigns URL on Search Box button"
      ]
    }
  },
  {
    title: "FLASH SALES (HYPE BUILDING) PUSH NOTIFICATION",
    image: playbookBlackFridayTwoImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Find your banner with high discount information",
        "Add sale image as push image",
        "Copy sale information as push message & title",
        "Send Bulk Push Notifications on black friday"
      ]
    }
  },
  {
    title: "EXIT INTENT POP-UPS",
    image: playbookBlackFridayThreeImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Prepare pop-up content",
        "Use “Check out black friday deals before you leave the site!” message",
        "Add black friday campaign’s page URL’s as target URL",
        "Trigger pop-up before user leaves site"
      ]
    }
  },
  {
    title: "Product Page Recommendation",
    image: playbookBlackFridayFourImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Create a recommendation widget",
        "Use a message like; “Check it out! People who bought this product also bought these products on black friday”",
        "Use complementary products",
        "Use this widget on product page"
      ]
    }
  },
  {
    title: "Black Friday’s Best Seller Announcement",
    image: playbookBlackFridayFiveImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Create best sellers banner",
        "Copy banner title as push title",
        "Copy banner message as push message",
        "Take a screenshot of banner as push image",
        "Add best sellers page URL’s as target URL",
        "Send bulk push notifications on black friday"
      ]
    }
  }
];

export const PlaybookItemsOfPush = [
  {
    title: "Last Visit Alternatives",
    image: playbookPushOneImage,
    designType: "horizontal",
    content: {
      title: "Solutions",
      type: "dotList",
      items: [
        "Segmentify Personalized Push | Last Visit Alternatives Push Notification allows sending push to users on the website.",
        "Remind your customers of their last visits with alternative products.",
        "This automation targets visitors who has at least checked a product in the last session and leaves the website without any basket action. For these visitors the most relevant products are recommended after wait period (10 - 24 hours)"
      ]
    }
  },
  {
    title: "Seosanal Sales",
    image: playbookPushTwoImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Find your banner about the back to school season",
        "Copy banner title as push title",
        "Find the category to which the seasonal banner is directed",
        "Take a seasonal category description as push message",
        "Send bulk push notifications on every new season"
      ]
    }
  },
  {
    title: "Shipping Limit Notification",
    image: playbookPushThreeImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Find your shipping information",
        "Copy shipping information as push message",
        "Send bulk push notifications on one day of the month",
        "Repeat one day of the every month"
      ]
    }
  },
  {
    title: "Flash Sale (Hype Building)",
    image: playbookPushFourImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Find your banner with high discount information",
        "Add sale image as push image",
        "Copy sale information as push message & title",
        "Send Bulk Push Notifications on every sale time"
      ]
    }
  },
  {
    title: "Time Remaining To Campaign",
    image: playbookPushFiveImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Find your campaign timer",
        "Copy time remaining to campaign title as push title",
        "Copy time remaining to campaign’s message as push message",
        "Add time remaining to campaign’s page URL’s as target URL",
        "Send bulk push notifications on before campaign"
      ]
    }
  },
  {
    title: "Friend Referral Encouragements",
    image: playbookPushSixImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Find coupons/discount for registered users",
        "Add “invite your friend, win coupon” message as push message",
        "Add invitation page URL as target URL",
        "Send Bulk Push Notifications on selected week",
        "Repeat every week"
      ]
    }
  }
];

export const PlaybookItemsOfRecommendation = [
  {
    title: "Prepare Behavior Based Changing Banners/Icons",
    image: playbookRecommendationOneImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Define every category as labels",
        "Collect the user behavior data of clicking / viewing these categories",
        "List the category icons or banners based on the user behaviors"
      ]
    }
  },
  {
    title: "Create Exit Pop Up Recommendation Campaigns",
    image: playbookRecommendationTwoImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Create the content of the pop up for example; Christmas season",
        "Define the details through pop up recommendation section on dashboard",
        "Take the campaign to live in the requested date."
      ]
    }
  },
  {
    title: "Create Bundle Recommendations",
    image: playbookRecommendationThreeImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Get a bundle products list from the customer",
        "Import the bundle list to our system",
        "Use the bundle products as recommended products on basket page widget for the selected products."
      ]
    }
  },
  {
    title: "Create “Selected for You” Page",
    image: playbookRecommendationFourImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Get a “selected for you” landing page url from the customer",
        "Prepare personalised recommendation widgets especially for the landing page",
        "Let the customer to promote the landing page on their website."
      ]
    }
  },
  {
    title: "Create Free Shipping Campaign for Basket Pages",
    image: playbookRecommendationFiveImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Learn the free shipping limit of the e-commerce website",
        "Add a basket page widget and define the free shipping limit as a campaign label",
        "Create a call to action text like; Add 10$ to get the free shipping chance!"
      ]
    }
  },
  {
    title: "Add the Visitors’ Names on Widgets by User Integration",
    image: playbookRecommendationSixImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Ask technical team to complete the user integration",
        "Get the names of the users from the user integration",
        "Call the users by their names through the widgets"
      ]
    }
  },
  {
    title: "Add Review and Stars to Widgets",
    image: playbookRecommendationSevenImage,
    designType: "vertical",
    content: {
      title: "Use Case(s)",
      type: "numberList",
      items: [
        "Get the review and stars information through the integration",
        "Update the widget designs with the review and stars"
      ]
    }
  }
];

export const PlaybookItemsOfSearchandising = [
  {
    title: "RELEVANCY",
    image: playbookSearchandisingOneImage,
    designType: "horizontal",
    content: {
      title: "",
      type: "paragraph",
      items: [
        "It doesn’t matter if the product name doesn’t contain the search term. The search is done across category, brand, colour, and gender parameters. The most relevant results are presented.",
        "",
        "A grading logic is followed: Name is the primary parameter, with others following it."
      ]
    }
  },
  {
    title: "Improve user experience with dynamic filters!",
    image: playbookSearchandisingTwoImage,
    designType: "horizontal",
    content: {
      title: "",
      type: "paragraph",
      items: [
        "Adding dynamic filters to the search results page makes navigation easier. Brand, category, colour, gender, price and size facets are the most using ones."
      ]
    }
  },
  {
    title:
      "Show the benefits, advantages or discounts at first glance with customised search results!",
    image: playbookSearchandisingThreeImage,
    designType: "horizontal",
    content: {
      title: "",
      type: "paragraph",
      items: [
        "Badges always grab the shoppers’ attention.",
        "It’s a known fact that the visitors are more interested in the product and likely to visit the product page if they see a discount or free shipping for the item they’re searching for."
      ]
    }
  },
  {
    title: "One screen all benefits with badges!",
    image: playbookSearchandisingFourImage,
    designType: "",
    content: {
      title: "",
      type: "paragraph",
      items: []
    }
  },
  {
    title:
      "Add customised campaign content to the search results page: Use banners!",
    image: playbookSearchandisingFiveImage,
    designType: "vertical",
    content: {
      title: "",
      type: "paragraph",
      items: [
        "Banners containing text/image/video/etc. can be added to the upper or bottom part of the search results page.",
        "",
        "The process is rule-based: If X is searched, show BannerA; if Y is searched, show BannerB."
      ]
    }
  }
];

export const playbookPageItems = [
  {
    title: "Segmentify published a |||Search Box Playbook||| for better CX !",
    color: "#AA327A",
    svgIcon: SearchBoxPlaybookCard,
    url:
      "https://drive.google.com/file/d/1uvt1MkGmuf_pxiMndquS6yqpJnupRmJG/view",
    playbookItems: PlaybookItemsOfSearchBox,
    playbookType: playbookTypes.SEARCH_BOX,
    modal: true
  },
  {
    title: "PERSONALISED BEST PRACTICES FOR |||BLACK FRIDAY|||",
    color: "#8D1B1E",
    svgIcon: BlackFridayPlaybookCard,
    url:
      "https://drive.google.com/file/d/12nCUjubqKUhHFa6A-7HVWMl2OqoCeU2w/view",
    playbookItems: PlaybookItemsOfBlackFriday,
    playbookType: playbookTypes.BLACK_FRIDAY,
    windowOpenUrl:
      "https://www.segmentify.com/wp-content/uploads/2022/10/Black-Friday-eBook.pdf?utm_source=newsletter&utm_medium=email-nt&utm_campaign=pb-black-friday-191022&dm_i=59YM,NSBO,4RZD4D,2VGPW,1",
    modal: false
  },
  {
    title: "Let’s explore the best practices for personalised push campaigns !",
    color: "#AE2228",
    svgIcon: PushPlaybookCard,
    url:
      "https://drive.google.com/file/d/1uimefkIhPfwgtPdObDQceyab9uZ8lh7P/view",
    playbookItems: PlaybookItemsOfPush,
    playbookType: playbookTypes.PUSH,
    modal: true
  },
  {
    title:
      "Segmentify published a |||Recommendation Playbook||| for better web experience !",
    color: "#4273D3",
    svgIcon: RecommendationPlaybookCard,
    url:
      "https://drive.google.com/file/d/1Yhrklagmu934mgKYvDdyngdoWOJR9Utg/view",
    playbookItems: PlaybookItemsOfRecommendation,
    playbookType: playbookTypes.RECOMMENDATION,
    modal: true
  },
  {
    title: "Feed the Search Result Page with Searchandising Best Practices!",
    color: "#4232AA",
    svgIcon: SearchandisingPlaybookCard,
    url:
      "https://drive.google.com/file/d/12LFc49ftSVTQqUjxrT8EqwCqSIT8sEW3/view",
    playbookItems: PlaybookItemsOfSearchandising,
    playbookType: playbookTypes.SEARCHANDISING,
    modal: true
  }
];
