'use strict';

import React from 'react';

import {
  emailStatuses,
  pushStatuses,
  devices,
} from '../../constants/datamaps/campaigns';
import { t } from '../../system/ui';
import { Radio, RadioGroup, Checkbox, CheckboxGroup } from '../fields';

class EmailStatusFilters extends React.Component {
  constructor(props) {
    super(props);

    this.toggleStatus = this.toggleStatus.bind(this);
    this.toggleMedium = this.toggleMedium.bind(this);
  }

  toggleStatus(value) {
    this.props.onStatusChange(value);
  }

  toggleMedium(value) {
    this.props.onMediumChange(value);
  }

  render() {
    let statusTypes = emailStatuses;
    if (
      this.props.params &&
      this.props.params?.campaigntype === 'push' &&
      ['bulk', 'view-all'].includes(this.props.params?.report)
    ) {
      statusTypes = pushStatuses;
    }

    return (
      <div className='status-filters'>
        <RadioGroup
          name='status'
          selectedValue={this.props.status}
          onChange={this.toggleStatus}
          className='parent-filters'
        >
          {Object.keys(statusTypes).map(item => {
            return (
              <Radio key={item} value={item} label={t(statusTypes[item])} />
            );
          })}
        </RadioGroup>
        {this.props.isPush && (
          <CheckboxGroup
            checkedValues={this.props.medium}
            name='medium'
            onChange={this.toggleMedium}
            className='filter-details'
          >
            {Object.keys(devices).map(item => {
              if (item === 'TABLET') {
                return null;
              }
              return (
                <Checkbox key={item} value={item} label={t(devices[item])} />
              );
            })}
          </CheckboxGroup>
        )}
      </div>
    );
  }
}

export default EmailStatusFilters;
