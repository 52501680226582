/**
 * @author Bilal Cinarli
 * Reducers for old campaign model
 */

"use strict";

import { createReducer } from "../system/store";
import * as campaignActions from "../constants/actions/campaigns";

const initialState = {
  campaigns: [],
  campaignRevenues: {},
  update: false,
  status: "ACTIVE",
  medium: "ALL",
  duplicate: { isDuplicate: false, duplicatedValues: [] }
};

export default createReducer(initialState, {
  [campaignActions.default.ADD_CAMPAIGN_REVENUE]: (state, payload) => {
    return Object.assign({}, state, {
      campaignRevenues: payload
    });
  },
  [campaignActions.default.FILTER_CAMPAIGN_STATUS]: (state, payload) => {
    return Object.assign({}, state, {
      status: payload.status
    });
  },
  [campaignActions.default.FILTER_CAMPAIGN_MEDIUM]: (state, payload) => {
    return Object.assign({}, state, {
      medium: payload.medium
    });
  },
  [campaignActions.default.DUPLICATE_CAMPAIGN]: (state, payload) => {
    return Object.assign({}, state, {
      duplicate: {
        isDuplicate: payload.isDuplicate,
        duplicatedValues: payload.duplicatedValues
      }
    });
  }
});
