import useExtendedContext from '../../../../hooks/module-context/useExtendedContext';
import { PromotionReducers } from './reducers/promotion-reducers';
import {
  resetPromotion,
  setPromotion,
  setPromotionList,
} from './actions/promotion-actions';

const promotionState = {
  promotionList: [],
  promotion: {
    title: '',
    image: undefined,
    mImage: undefined,
    code: '',
    url: '',
    mUrl: '',
    productIds: [],
    categories: [],
    brands: [],
    labels: [],
    startDate: '',
    endDate: '',
  },
};

/**
 * @name promotionContent
 * @description Context for promotion component. Provides access to the state and actions of the Wrapped component. Actions, state and dispatch are provided as react-hook basis.
 * @property {object} state - State of the context
 * @property {function} setSTLSearchParams - Function to set search query string
 * @property {function} setSTLList - Function to set list of bundles
 * @property {function} setSTLBundles - Function to set list of bundles
 * @property {function} dispatch - Function to dispatch actions
 */

export const { Context, Provider } = useExtendedContext(
  PromotionReducers,
  {
    setPromotionList,
    resetPromotion,
    setPromotion,
  },
  promotionState,
);
