import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { connect } from 'react-redux';
import LoginForm from '../components/login/login.form';
import LoginHeader from '../components/login/login.header';
import getReCaptchaPublicKey from '../constants/reCaptcha.config';
import { setTitle } from '../system/document';

class LoginBase extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    setTitle('Login');
  }

  render() {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={getReCaptchaPublicKey()}
        scriptProps={{ async: true, defer: true, appendTo: 'head' }}
      >
        <div id='wrapper' className='page-wrap login-wrap'>
          <LoginHeader
            bookButton
            defaultLanguage={this.props.defaultLanguage}
          />
          <LoginForm props={this.props} />
        </div>
      </GoogleReCaptchaProvider>
    );
  }
}

const mapStateToProps = store => ({
  language: store.ui.language,
  modal: store.modal.modal,
  showModal: store.modal.showModal,
});

export const Login = connect(mapStateToProps)(LoginBase);
export const LoginDe = () => <Login defaultLanguage='de' />;
