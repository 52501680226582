import React from "react";

class IconExampleText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "17px",
      height: "17px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17 17"
        width={this.state.width}
        height={this.state.height}
      >
        <title>Combined Shape</title>
        <desc>Created with Sketch.</desc>
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="icon/edit-2"
            transform="translate(-7.000000, -7.000000)"
            fill={this.state.color}
            fillRule="nonzero"
          >
            <path
              d="M14.5288925,7.06537764 C14.9859676,7.06537764 15.3581738,7.43756712 15.3581738,7.89465893 C15.3581738,8.35173402 14.9859844,8.72394022 14.5288925,8.72394022 L14.5288925,8.72394022 L8.72380649,8.72394022 L8.72380649,21.9925412 L21.9924075,21.9925412 L21.9924075,16.1874551 C21.9924075,15.73038 22.3645969,15.3581738 22.8216887,15.3581738 C23.2787806,15.3581906 23.6509868,15.73038 23.6509868,16.1874718 L23.6509868,16.1874718 L23.6509868,22.8218559 C23.6509868,23.278931 23.2787973,23.6511372 22.8217055,23.6511372 L22.8217055,23.6511372 L7.89450848,23.6511372 C7.43743339,23.6511372 7.06522719,23.2789477 7.06522719,22.8218559 L7.06522719,22.8218559 L7.06522719,7.89465893 C7.06522719,7.43758383 7.43741667,7.06537764 7.89450848,7.06537764 L7.89450848,7.06537764 Z M21.0620926,7.30691214 C21.3853199,6.98368484 21.9109654,6.98368484 22.2341927,7.30691214 L22.2341927,7.30691214 L23.4095525,8.48227198 C23.7327798,8.80549928 23.7327798,9.33114483 23.4095525,9.65437213 L23.4095525,9.65437213 L15.377849,17.6860757 C15.2602996,17.8003553 15.1133847,17.8787132 14.9534042,17.9113619 L14.9534042,17.9113619 L12.4394327,18.6688131 C12.2043507,18.7145156 12.0019257,18.5088209 12.0476349,18.2770153 L12.0476349,18.2770153 L12.8051028,15.7630605 C12.8344901,15.6030683 12.9161177,15.4561534 13.030389,15.3386157 L13.030389,15.3386157 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconExampleText;
