import React, { useRef } from 'react';

import { DiffEditor } from '@monaco-editor/react';

/**
 * DifferenceEditor Editor Component
 * @description DifferenceEditor Editor Component that wraps the Monaco Editor
 * @param {string} originalSourceCode - original source code to be compared with modified source code
 * @param {string} modifiedSourceCode - modified source code to be compared with original source code
 * @param {string} defaultLanguage - default language to be used for the editor
 *
 */

const DifferenceEditor = ({
  originalSourceCode,
  modifiedSourceCode,
  defaultLanguage,
}) => {
  const diffEditorRef = useRef(null);

  function handleEditorMount(editor) {
    diffEditorRef.current = editor;
  }

  return (
    <>
      <DiffEditor
        height='90vh'
        theme='vs-dark'
        defaultLanguage={defaultLanguage}
        original={originalSourceCode}
        modified={modifiedSourceCode}
        onMount={handleEditorMount}
      />
    </>
  );
};

export default DifferenceEditor;
