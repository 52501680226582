import { useState } from 'react';
import moment from 'moment';

const inputTypeEnum = {
  checkbox: 'checkbox',
  selectBox: 'selectBox',
  datepicker: 'datepicker',
};
export function useHandleStateChange({ initialState }) {
  const [downloadState, setDownloadState] = useState({
    // frequency: initialState?.frequency || 'DAILY',
    subscribed: initialState?.subscribed || true,
    unSubscribed: initialState?.unSubscribed || false,
    bounceBack: initialState?.bounceBack || false,
    selection: [
      {
        startDate: moment()
          .subtract(1, 'days')
          .startOf('day')
          .toISOString(),
        endDate: new Date(),
        key: 'selection',
      },
    ],
  });

  const setValues = ({ e, type, stateName }) => {
    if (type === inputTypeEnum.checkbox) {
      const result = {
        subscribed: false,
        unSubscribed: false,
        bounceBack: false,
      };
      setDownloadState(prevState => {
        return {
          ...prevState,
          ...result,
          [stateName]: true,
        };
      });
    }

    if (type === inputTypeEnum.datepicker) {
      setDownloadState(prevState => ({
        ...prevState,
        selection: [e.selection],
      }));
    }
  };

  return { downloadState, setValues };
}
