export const fieldMapper = account => {
  return [
    {
      label: 'Api Key',
      name: 'apikey',
      className: 'item-stacked one-whole',
      defaultValue: account.apiKey,
      readOnly: true,
    },
    {
      label: 'Account Domain',
      name: 'domain',
      className: 'item-stacked one-whole',
      defaultValue: account.domain,
      required: true,
    },
    {
      label: 'Contact Person',
      name: 'person',
      className: 'item-stacked one-whole',
      defaultValue: account.contact ? account.contact.person : '',
    },
    {
      label: 'Contact Phone',
      name: 'phone',
      className: 'item-stacked one-whole',
      defaultValue: account.contact ? account.contact.phone : '',
    },
    {
      label: 'Contact Address',
      name: 'address',
      className: 'item-stacked one-whole',
      defaultValue: account.contact ? account.contact.address : '',
    },
  ];
};
