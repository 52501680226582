import React from 'react';
import classNames from 'classnames';
import { segmentAlgorithmTitles } from '../../constants/datamaps/wizard';

export const segmentSelection = props => {
  return (
    <div
      className={classNames(
        'available-types available-widgets available-widgets-mail',
      )}
    >
      <h3 className='widget-title-head'>
        {props.title === 'Sleeping Opportunists'
          ? 'Discount Seekers'
          : props.title}
      </h3>
      <p className='widget-title-desc'>{props.description}</p>

      <div className='available-type-selection'>
        {props.segments.map(segment => {
          const uniqueSubClass = `available-type-${
            segmentAlgorithmTitles(
              segment.name === 'Sleeping Opportunists'
                ? 'Discount Seekers'
                : segment.name,
            ).imageName
          }`;
          const isLive = segment.status === 'ACTIVE';
          const isDraft = segment.status === 'DRAFT';
          const comps = [
            <span key={segmentAlgorithmTitles(segment).title}>
              <div
                className={classNames(
                  'available-type',
                  uniqueSubClass,
                  {
                    'list-email-live': isLive,
                    'list-email-test': isDraft,
                  },
                  'list-email',
                )}
              >
                <h4 className='available-type-name'>
                  {segment.name === 'Sleeping Opportunists'
                    ? 'Discount Seekers'
                    : segment.name}
                </h4>
                <span className='available-type-wireframe'>
                  {isLive && <div className='list-email-live-label'>Live</div>}

                  {isDraft && (
                    <div className='list-email-test-label'>Draft</div>
                  )}
                </span>
                <p className='list-email-desc'>
                  {
                    segmentAlgorithmTitles(
                      segment.name === 'Sleeping Opportunists'
                        ? 'Discount Seekers'
                        : segment.name,
                    ).desc
                  }
                </p>
              </div>
            </span>,
          ];
          return comps;
        })}
      </div>
    </div>
  );
};
