import { dateConverter } from '../functions/dateConverter';

export const IYS_STATISTICS = {
  queue: 'Queue',
  optIn: 'Opt-In',
  optOut: 'Opt-Out',
  iysError: 'IYS Error',
};

export const schema = [
  {
    accessor: 'emailAddress',
    header: 'Email Address',
    order: 1,
    resolver: {
      hasResolver: false,
    },
    sortable: true,
    type: 'text',
  },
  {
    accessor: 'source',
    header: 'Source',
    order: 2,
    resolver: {
      hasResolver: true,
      resolver: value => {
        if (value === 'PC') return 'Web Site';
        if (value === 'MOBILE') return 'Mobile';
        return value;
      },
    },
    sortable: true,
    type: 'text',
  },
  {
    accessor: 'status',
    header: 'Status',
    order: 3,
    resolver: {
      hasResolver: true,
      resolver: value => {
        if (value === 'QUEUE') return 'Queue';
        if (value === 'OPT_IN') return 'Opt-In';
        if (value === 'OPT_OUT') return 'Opt-Out';
        if (value === 'IYS_ERROR') return 'IYS Error';
        return value;
      },
    },
    sortable: true,
    type: 'text',
  },
  {
    header: 'User Type',
    accessor: 'userType',
    order: 4,
    resolver: {
      hasResolver: true,
      resolver: value => {
        if (value === 'BIREYSEL') return 'Individual';
        if (value === 'TACIR') return 'Merchant';
        return value;
      },
    },
    sortable: true,
    type: 'text',
  },
  {
    header: 'Consent Date',
    accessor: 'consentDate',
    order: 5,
    resolver: {
      hasResolver: true,
      resolver: value => dateConverter(value),
    },
    sortable: true,
    type: 'date',
  },
  {
    accessor: 'iysConsentDate',
    header: 'IYS Consent Date',
    order: 6,
    resolver: {
      hasResolver: true,
      resolver: value => dateConverter(value),
    },
    sortable: true,
    type: 'date',
  },
  {
    accessor: 'transferStatus',
    header: 'Transfer Status',
    order: 7,
    resolver: {
      hasResolver: true,
      resolver: value => {
        // https://segmentify.atlassian.net/browse/SFY-11980
        if (value === 'Iys_Not_Send') return 'Success';
        return value;
      },
    },
    sortable: true,
    type: 'text',
  },
  {
    accessor: 'errorMessage',
    header: 'Error Message',
    order: 8,
    resolver: {
      hasResolver: true,
      resolver: value => {
        if (value !== null || value !== '') return value;
      },
    },
    sortable: true,
    type: 'text',
  },
];
