/**
 * Created by mehmetyurtar on 11/6/17.
 */
import {languages} from "../../components/admin/constants";

const getLanguageName = code => {
  let language = "";

  languages.forEach(lang => {
    if (lang.value === code) {
      language = lang;
    }
  });

  return language.label;
};

export default getLanguageName;
