import moment from 'moment';

const getOtherDates = range => {
  const difference = range[1].diff(range[0], 'days');
  let subtract = 'month';
  if (difference <= 1) {
    subtract = 'day';
  } else if (difference < 8) {
    subtract = 'week';
  }
  const otherBoundaries = {};
  otherBoundaries.start = moment(range[0]);
  otherBoundaries.end = moment(range[1]);
  otherBoundaries.formattedStart = otherBoundaries.start
    .subtract(1, subtract)
    .format('YYYY-MM-DD');
  otherBoundaries.formattedEnd = otherBoundaries.end
    .subtract(1, subtract)
    .format('YYYY-MM-DD');
  return otherBoundaries;
};

export { getOtherDates };
