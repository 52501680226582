import React from 'react';

import { uiActions, campaignActions, wizardActions } from '../../actions';
import { getAccountId, isSuperUser, isGG } from '../auth/user';
import { config } from '../../constants/config';
import { templates } from '../../constants/datamaps/templates';
import {
  defaultValues,
  pageTypes,
  campaignData,
  emailCampaignData,
} from '../../constants/datamaps/wizard';
import * as Notifications from '../../constants/datamaps/notifications';
import { campaignTemplate, preJS, postJS } from '../../constants/template';
import { addEmailCampaign, updateEmailCampaign } from '../emails/ajax';
import { convertToEpochTime } from '../../system/date';
import { t, notification } from '../../system/ui';
import { capitalize } from '../../system/string';
import { clone } from '../../system/object';
import {
  personalizationRecommendationExclusions,
  personalizationRecommendations,
} from '../transform/personalizationRecommendations';
import { getCampaignPage } from './data';
import {
  addCampaign,
  addCampaignV2,
  updateCampaign,
  updateCampaignV2,
} from './ajax';
import { getRockstarScore, isRockstarAlgorithmSelected } from './utils';

const categoryOptions = ['all', 'category', 'parent', 'child'];

export const goTestOrLive = (
  type,
  context,
  routerPageType,
  isPromotion,
  isKeyword,
  optionalFields,
) => {
  let pageType =
    pageTypes.indexOf(routerPageType) > -1 ? routerPageType : 'custom';
  if (pageType === '404') {
    pageType = 'notfound';
  }

  const isEdit = !!context.state.campaign.instanceId;
  const isPromotionOrSearch = isPromotion || isKeyword;
  const isWithTemplate = context.state.useWidgetTemplate;

  const query = clone(campaignData);
  let method = isPromotionOrSearch ? addCampaign : addCampaignV2;
  let action = 'add';
  let templateId = isKeyword ? 'HUR_4' : defaultValues[pageType].templateId;

  // connect attached so get the real component with getWrappedInstance()
  const wizardItems = context.refs.wizardItems.state;
  const wizardTarget = context.refs.wizardTarget
    ? context.refs.wizardTarget.state
    : '';
  const wizardDateSelection = context.refs.wizardDateSelection.state;
  // connect attached so get the real component with getWrappedInstance()
  const wizardAdvancedConfig =
    isEdit || isPromotionOrSearch || !isWithTemplate
      ? context.refs.wizardAdvancedConfig.state
      : null;
  // connect attached so get the real component with getWrappedInstance()
  const wizardMediumSelection = context.refs.wizardMediumSelection.state;
  const wizardPrioritySelection = context.refs.wizardPrioritySelection
    ? context.refs.wizardPrioritySelection.state
    : false;
  const wizardPageType =
    [
      'custom',
      'popup_recommendation',
      'category',
      'product',
      'search',
    ].includes(pageType) && context.refs.wizardPageType
      ? context.refs.wizardPageType.state
      : null;
  const dynamicItems = [];
  const staticItems = [];
  const inclusions = [];
  const exclusions = [];

  method = isWithTemplate ? addCampaignV2 : addCampaign;

  if (pageType === 'triggerbased') {
    templateId = 'SMSG_01';
  }

  query.accountId = getAccountId();
  if (context.state.campaign.instanceId) {
    method = isPromotionOrSearch ? updateCampaign : updateCampaignV2;
    action = 'update';
    query.instanceId = context.state.campaign.instanceId;
  }
  query.widgetTemplateId = context?.state?.widgetTemplateId;
  query.scenarioName = context.state.scenarioName;
  query.templateId = templateId;
  query.testMode = type === 'test';
  query.limitations.activeDates.startDate = wizardDateSelection.startDate.toISOString();
  query.limitations.activeDates.endDate = null;
  if (wizardDateSelection.showEndDate) {
    if (wizardDateSelection.endDate) {
      query.limitations.activeDates.endDate = wizardDateSelection.endDate.toISOString();
    } else {
      const newDate = wizardDateSelection.startDate.clone();
      newDate.add(15, 'm');
      query.limitations.activeDates.endDate = newDate.toISOString();
    }
  }
  query.actions[0].targets = [];
  query.actions[0].targets.push(templates[templateId].text);
  if (typeof context.state.campaign.actions !== 'undefined') {
    query.actions[0].id = context.state.campaign.actions[0].id;
    query.actions[0].params = context.state.campaign.actions[0].params;
  }
  query.actions[0].params.notificationTitle = context.state.title;
  query.actions[0].params.notificationTitleMap = JSON.stringify(
    context.state.additionalTitles,
  );
  query.actions[0].params.ordering = wizardItems.shuffle
    ? 'SHUFFLE'
    : 'GIVEN_ORDER';
  query.actions[0].params.recommendOtherCategories =
    wizardItems.recommendOtherCategories;

  query.actions[0].params.selector = wizardTarget.selector || '';

  const selectedIntelligents =
    context.state.AlgoString && context.state.AlgoString.length > 0
      ? context.state.AlgoString.filter(item => item.type === 'intelligent')
      : [];
  const selectedAlgorithms =
    selectedIntelligents.length > 0
      ? selectedIntelligents.map(item => {
          return item.values.criterion.value;
        })
      : [];
  const selectedTimeFrames =
    selectedIntelligents.length > 0
      ? selectedIntelligents.map(item => {
          return item.values.timeFrame.value;
        })
      : [];

  const isLevelUpUsed =
    isSuperUser() &&
    ((context.state.selectedType.page === 'category' &&
      selectedAlgorithms.indexOf('RECOMMENDATION_CATEGORY_ROCKS') >= 0) ||
      (context.state.selectedType.page === 'product' &&
        selectedAlgorithms.indexOf('RECOMMENDATION_SOURCE_RELATED_PRODUCT') >=
          0 &&
        selectedTimeFrames.indexOf('category') >= 0));

  if (isLevelUpUsed) {
    query.actions[0].params.levelUp = context.state.levelUp;
  }
  query.actions[0].params.insertType = wizardTarget.insertType || '';
  query.actions[0].params.itemsPerRow = '4';
  query.actions[0].params.recommendationTemplate =
    isEdit || isPromotionOrSearch || !isWithTemplate
      ? wizardAdvancedConfig.htmlTemplate || campaignTemplate
      : null;
  query.actions[0].params.preJsCode =
    isEdit || isPromotionOrSearch || !isWithTemplate
      ? wizardAdvancedConfig.prejsTemplate || preJS
      : null;
  query.actions[0].params.postJsCode =
    isEdit || isPromotionOrSearch || !isWithTemplate
      ? wizardAdvancedConfig.postjsTemplate || postJS
      : null;
  query.actions[0].params.cssCode =
    isEdit || isPromotionOrSearch || !isWithTemplate
      ? wizardAdvancedConfig.cssTemplate || ''
      : null;

  /* Protected Existing Variables */
  if (typeof context.state.campaign.actions !== 'undefined') {
    query.actions[0].constraint =
      context.state.campaign.actions[0].constraint || null;
    query.actions[0].controlGroup =
      context.state.campaign.actions[0].controlGroup || null;
    query.actions[0].delayAction =
      context.state.campaign.actions[0].delayAction || null;
    query.actions[0].group = context.state.campaign.actions[0].group || null;
  }
  /* Protect Campaign priority */
  if (typeof context.state.campaign.priority !== 'undefined') {
    if (wizardPrioritySelection && wizardPrioritySelection.priority) {
      query.priority = wizardPrioritySelection.priority;
    } else {
      query.priority = context.state.campaign.priority;
    }
  }

  // Popup type
  if (pageType === 'popup_recommendation') {
    query.actions[0].params.actionType = 'POPUP_RECO';
  }

  context.props.criteria.forEach(criteria => {
    if (criteria.type === 'product') {
      staticItems.push({
        productId: criteria.values.productId.value,
        productSource: 'INTERNAL',
        type: criteria.type.toUpperCase(),
      });
    } else if (criteria.type === 'promotion') {
      staticItems.push({
        promoCode: criteria.values.code.value,
        type: criteria.type.toUpperCase(),
      });
    } else {
      const dynamicObj = {
        recommendationSource:
          criteria.values.criterion.value ===
            'RECOMMENDATION_RELATED_COMBINE_PRODUCTS' && isGG()
            ? 'RECOMMENDATION_ALTERNATIVE_COMBINE_PRODUCTS'
            : criteria.values.criterion.value,
        productSource: 'INTERNAL',
        itemCount: criteria.values?.itemCount?.value
          ? criteria.values.itemCount.value
          : 1,
        type: criteria.type.toUpperCase(),
      };
      if (criteria.values.timeFrame) {
        let isCategory = false;
        categoryOptions.forEach(category => {
          if (!isCategory) {
            if (criteria.values.timeFrame.value === category) {
              isCategory = true;
            }
          }
        });
        if (isCategory) {
          dynamicObj.categoryChoice = criteria.values.timeFrame.value;
        } else {
          dynamicObj.timeFrame = criteria.values.timeFrame.disabled
            ? 'context_WEEK'
            : criteria.values.timeFrame.value;
        }
      }
      // Score for Rockstar Algorithms
      if (isRockstarAlgorithmSelected(criteria.values.criterion.value)) {
        dynamicObj.score = getRockstarScore(criteria.values.criterion.value);
      } else if (
        criteria.type === 'brand' ||
        criteria.type === 'category' ||
        criteria.type === 'label'
      ) {
        dynamicObj.score = 'ROCK';
      } else {
        dynamicObj.score = 'NONE';
      }

      // Score for Event Data Algorithms
      if (criteria.values.criterion.value === 'RECOMMENDATION_EVENT_DATA') {
        if (dynamicObj.timeFrame === 'NONE') {
          dynamicObj.score = '';
          dynamicObj.timeFrame = 'CURRENT';
        } else {
          dynamicObj.score = 'VIEW';
        }
      }

      if (
        criteria.values.criterion.value === 'RECOMMENDATION_HIDDEN_PRODUCTS'
      ) {
        dynamicObj.timeFrame = '';
        dynamicObj.score = '';
      }

      dynamicItems.push(dynamicObj);
    }
  });

  context.props.excludes.forEach(exclusion => {
    const params = {};

    Object.keys(exclusion.values).forEach(value => {
      params[value] = exclusion.values[value].value;
    });

    params.type = exclusion.type.toUpperCase();

    exclusions.push(params);
  });

  context.props.includes.forEach(inclusion => {
    const params = {};

    Object.keys(inclusion.values).forEach(value => {
      params[value] = inclusion.values[value].value;
    });

    params.type = inclusion.type.toUpperCase();

    inclusions.push(params);
  });

  query.actions[0].params.dynamicItems = JSON.stringify(dynamicItems);
  query.actions[0].params.staticItems = JSON.stringify(staticItems);
  query.actions[0].params.exclusions = JSON.stringify(exclusions);
  query.actions[0].params.inclusions = JSON.stringify(inclusions);
  query.inputs = [];

  templates[templateId].inputs.forEach(input => {
    let pageName = '';

    if (wizardPageType !== null) {
      pageName = wizardPageType.value;
    } else {
      pageName = [
        `${config.menus.primary.recommendations.children[pageType].name} Page`,
      ];
    }

    if (pageName && pageName[0] === 'Trigger Based Page') {
      pageName = [context.state.eventName];
    }

    if (wizardPageType) {
      if (
        wizardPageType.selectedFilter.includes('url') &&
        wizardPageType.selectedFilter.includes('page')
      ) {
        const extractFilterUrls = wizardPageType.filterUrls.map(({ value }) => {
          if (value === '') return;
          // eslint-disable-next-line
          return value;
        });

        query.inputs = [
          {
            name: 'category',
            clazz: input.clazz,
            value: wizardPageType.value,
          },
          {
            name:
              wizardPageType.selectedUrlFilter === 'contains'
                ? 'includeUrls'
                : 'excludeUrls',
            clazz: input.clazz,
            value: extractFilterUrls.filter(Boolean) ?? [],
          },
        ].filter(Boolean);

        return query.inputs;
      }

      if (wizardPageType.selectedFilter.includes('url')) {
        const isOnlyUrl =
          wizardPageType.activeFields.length === 1 &&
          wizardPageType.activeFields[0] === 'url';
        const pageName = [];
        const pageUrls = wizardPageType.filterUrls.map(url => {
          return url.value;
        });
        pageUrls.forEach(item => {
          if (item) {
            pageName.push(item);
          }
        });
        if (input.name === 'includeUrls') {
          query.inputs.push({
            name: input.name,
            clazz: input.clazz,
            value:
              wizardPageType.selectedUrlFilter === 'contains' ? pageName : [],
          });
        } else if (input.name === 'excludeUrls') {
          query.inputs.push({
            name: input.name,
            clazz: input.clazz,
            value:
              wizardPageType.selectedUrlFilter === 'notContains'
                ? pageName
                : [],
          });
        } else if (input.name === 'category' && isOnlyUrl) {
          const categoryName = `${config.menus.primary.recommendations.children[pageType].name} Page`;
          query.inputs.push({
            name: input.name,
            clazz: input.clazz,
            value: [categoryName],
          });
        } else {
          query.inputs.push({
            name: input.name,
            clazz: input.clazz,
            value: wizardPageType.customPages,
          });
        }
      } else {
        query.inputs.push({
          name: input.name,
          clazz: input.clazz,
          value:
            input.name !== 'includeUrls' && input.name !== 'excludeUrls'
              ? pageName
              : [],
        });
      }
    } else if (!input?.onlyWithRef) {
      query.inputs.push({
        name: input.name,
        clazz: input.clazz,
        value: pageName,
      });
    }
  });

  query.device = wizardMediumSelection.device;

  if (isPromotion) {
    query.actions[0].type = 'recommendPromotion';
  }

  if (isKeyword) {
    query.actions[0].type = 'recommendKeyword';
  }

  // Engagement Options
  if (optionalFields) {
    if (pageType === 'popup_recommendation') {
      query.actions[0].params.overlay = optionalFields.overlay;
      query.actions[0].params.verticalPosition =
        optionalFields.verticalPosition;
      query.actions[0].params.horizontalPosition =
        optionalFields.horizontalPosition;
      query.actions[0].delayAction = optionalFields.delayAction;
      query.actions[0].constraint = optionalFields.constraint;
    }
    query.actions[0].filters = optionalFields.filters;
  }

  if (pageType === 'custom') {
    query.custom = true;
  }

  method(query, response => {
    if (response.instanceId) {
      const note =
        Notifications[`${capitalize(type)}Mode${capitalize(action)}`];
      notification({
        content: note,
      });

      // Moving up pagetype variable declarations to prevent 'undefined' error
      let value;
      if (response.actions[0].params.actionType === 'POPUP_RECO') {
        value = 'Popup Recommendation';
        pageType = 'popup_recommendation';
      } else {
        value = getCampaignPage(response)
          .replace(' Page', '')
          .toLowerCase();
        pageType = pageTypes.indexOf(value) > -1 ? value : 'custom';
      }
      // Custom handler for checkout success page
      if (value === 'checkout success') {
        pageType = 'checkout-success';
      }

      campaignActions.duplicateCampaign(false);

      const ext = context.context.router.routes[0].path || '';

      if (response.actions[0].type === 'recommendPromotion') {
        context.context.router.push({
          pathname: `${ext}/promotion/edit/${pageType}/${response.instanceId}`,
        });
      } else if (response.actions[0].type === 'recommendKeyword') {
        context.context.router.push({
          pathname: `${ext}/searchkeywords/edit/${pageType}/${response.instanceId}`,
        });
      } else {
        context.context.router.push({
          pathname: `${ext}/widget/edit/${pageType}/fullWidth/${response.instanceId}`,
        });
      }

      if (response.templateId === 'SMSG_01') {
        pageType = 'triggerbased';
      }

      if (response.templateId === 'T21') {
        pageType = 'checkout-success';
      }

      uiActions.showBreadcrumb({
        home: {
          name: t('Recommend'),
          icon: 'heart',
          slug: '/recommendations',
        },
        top: {
          name:
            pageType === 'popup_recommendation'
              ? 'Popup Recommendation'
              : pageType === 'journey_builder'
              ? 'Journey Builder'
              : t(
                  `${config.menus.primary.recommendations.children[pageType].name} Page Widgets`,
                ),
          slug: `/recommendations/${pageType}`,
        },
        current: {
          name: query.scenarioName,
        },
      });

      context.setState({ campaignStatus: query.testMode ? 'Test' : 'Live' });
    } else {
      context.setState(
        {
          isSubmitting: false,
          submittingButton: '',
        },
        () => {
          const errorMessage = () => <p>{response.response.message}</p>;
          if (
            typeof response.response === 'object' &&
            response.response !== null
          ) {
            notification({
              content: errorMessage,
              className: `notification-${response.response.status.toLowerCase()}`,
            });
          }
        },
      );
    }
  });
};

export const emailGoTestOrLive = (
  type,
  context,
  criteria,
  inclusions,
  exclusions,
  mappings,
  callback,
) => {
  const query = clone(emailCampaignData);
  query.type = context.state.campaign.type;
  query.instanceId = context.state.campaign.instanceId;
  query.lastLiveDate = context.state.campaign.lastLiveDate || null;
  query.sendToIdentifyApi = context.state.sendToIdentifyApi || false;
  if (context.state.campaign.executedBefore) {
    query.executedBefore = context.state.campaign.executedBefore;
  }
  query.scenarioName = context.state.scenarioName;
  query.accountId = context.state.campaign.accountId || getAccountId();
  query.status = type === 'test' ? 'TEST' : 'ACTIVE';
  criteria.forEach(function(c) {
    c.isEmail = true;
  });
  criteria = criteria.filter(singleCriteria => {
    if (
      singleCriteria.type !== 'product' &&
      singleCriteria.values.hasOwnProperty('criterion')
    ) {
      return true;
    }
    if (
      singleCriteria.type === 'product' &&
      singleCriteria.values.hasOwnProperty('productId')
    ) {
      return true;
    }
    return false;
  });

  query.additionalRecommendationAllowed =
    context.state.campaign.additionalRecommendationAllowed;
  if (context.state.campaign.type === 'FLY') {
    const additional = personalizationRecommendations(criteria);
    query.additionalRecommendationAllowed =
      additional !== undefined && additional.length > 0;
  }

  query.defaultRecommendation = personalizationRecommendations(
    criteria.filter((item, index) => {
      return index === 0;
    }),
  );
  if (query.additionalRecommendationAllowed) {
    query.additionalRecommendations = personalizationRecommendations(
      criteria.filter((item, index) => {
        return index !== 0;
      }),
    );
  }
  if (inclusions) {
    query.inclusions = personalizationRecommendationExclusions(inclusions);
  }
  if (exclusions) {
    query.exclusions = personalizationRecommendationExclusions(exclusions);
  }

  query.params.subject = context.state.campaignCustoms._subject;
  query.params.mailContent = context.state.campaignCustoms._mailContent;
  if (context.state.campaign.type !== 'FLY') {
    query.params.subject =
      context.state.additionalSubjects[
        context.props.switchedUser.account.mainLanguage
      ];
    query.params.mailContent =
      context.state.additionalContents[
        context.props.switchedUser.account.mainLanguage
      ];
    query.additionalSubjects = context.state.additionalSubjects;
    query.additionalContents = context.state.additionalContents;
  }

  if (context.state.campaign.type === 'PRICE_DROP') {
    query.params.discountRatio = context.state.campaignCustoms.discountRatio;
  }
  if (context.state.campaign.type !== 'FLY') {
    query.params.language =
      context.state.language || context.refs.language.value;
    query.params.currency =
      context.state.currency || context.refs.currency.value;
    query.params.logoUrl = context.state.logoUrl;
    if (
      context.state.campaign.type === 'ABANDONED_CART' ||
      context.state.campaign.type === 'LAST_VISIT_ALTERNATIVES' ||
      context.state.campaign.type === 'LAST_VISIT_REMINDER'
    ) {
      if (context.state.capping && context.state.capping.capCount) {
        query.capping = context.state.capping;
        query.capping.types = [context.state.campaign.type];
      } else {
        query.capping = null;
      }
    }
  } else {
    // Fly Mode Specific Params
    // Mappings
    query.defaultRecommendation = [];
    query.additionalRecommendations = personalizationRecommendations(criteria);
    query.params.mailContent = context.state.campaignCustoms._mailContent;
    query.params.subject = context.state.campaignCustoms._subject;
    query.customParams = mappings.map(mapping => {
      return { key: mapping.key, value: mapping.value };
    });
    // Start date End Date
    if (context.refs.wizardDateSelection) {
      const wizardDateSelection = context.refs.wizardDateSelection.state;
      query.startDate = wizardDateSelection.startDate.toISOString();
      query.endDate = null;
      if (wizardDateSelection.showEndDate) {
        if (wizardDateSelection.endDate) {
          query.endDate = wizardDateSelection.endDate.toISOString();
        } else {
          const newDate = wizardDateSelection.startDate.clone();
          newDate.add(15, 'm');
          query.endDate = newDate.toISOString();
        }
      }
    }
    query.params.klaviyoEventName =
      context.state.campaignCustoms.klaviyoEventName;
  }

  if (context.state.campaign.type === 'RECOMMENDATION') {
    query.defaultRecommendation = [];
    query.additionalRecommendations = personalizationRecommendations(criteria);
    query.params.klaviyoEventName = context.state.klaviyoEventName;
  }

  query.prioritized = context.state.campaign.prioritized;
  query.template = context.state.template;

  Object.keys(context.state.campaign.params).forEach(custom => {
    if (
      custom !== 'mailContent' &&
      custom !== 'subject' &&
      custom !== 'logoUrl' &&
      custom !== 'language' &&
      custom !== 'currency' &&
      custom !== 'klaviyoEventName'
    ) {
      query.params[custom] = context.state.campaignCustoms[custom];
    }
  });

  if (query.params.hasOwnProperty('discountRatio')) {
    if (!context.state.showDiscount) {
      query.params.discountRatio = '';
    }
  }

  if (context.refs.schedule) {
    const { schedule } = context.refs;
    query.schedule = schedule.state.values.schedule;
    query.schedule.date = convertToEpochTime(query.schedule.date);
  }

  if (context.refs.audience) {
    const { audience } = context.refs;
    query.filters = audience.state.filters
      .filter(filter => {
        return filter.segment && filter.type;
      })
      .map(filter => {
        return {
          type: filter.type,
          segment: filter.segment,
          include: filter.include,
        };
      });

    if (query.filters.length > 1) {
      query.filterOperator = audience.state.operator;
    }
  }

  if (type === 'test' || type === 'live') {
    if (
      query.startDate === '' ||
      query.startDate === undefined ||
      query.startDate === null
    ) {
      query.startDate = context.state.campaign.startDate || '';
    }
    updateEmailCampaign(query, response => {
      if (response.status === 'TEST' || response.status === 'ACTIVE') {
        const note = () => {
          return <div>Campaign Updated Successfully</div>;
        };
        notification({
          content: note,
        });
        callback(response);
      }
    });
  } else {
    query.accountId = getAccountId();
    query.prioritized = false;
    query.scenarioName = context.state.scenarioName;
    query.status = 'TEST';
    addEmailCampaign(query, response => {
      if (response) {
        const note = () => {
          return <div>Campaign Added Successfully</div>;
        };
        notification({
          content: note,
        });
        callback({ ...response, instanceId: response.instanceId });
        context.context.router.push({
          pathname: `/email/edit/${response.instanceId}`,
          state: {
            campaign: { ...response, instanceId: response.instanceId },
            templates: context.state.mailTemplates,
          },
        });
      } else {
        const note = () => {
          return <div>Something went wrong</div>;
        };
        notification({
          content: note,
        });
        callback();
      }
    });
  }
};

export const singleCampaign = campaign => {
  try {
    if (campaign) {
      const criteria = [];
      const excludes = [];
      const includes = [];
      const htmlTemplate = campaign.actions[0].params.recommendationTemplate;
      const prejsTemplate = campaign.actions[0].params.preJsCode;
      const postjsTemplate = campaign.actions[0].params.postJsCode;
      const cssTemplate = campaign.actions[0].params.cssCode || '';
      let products = [];
      const dynamicItems =
        typeof campaign.actions[0].params.dynamicItems !== 'undefined'
          ? JSON.parse(campaign.actions[0].params.dynamicItems)
          : [];
      const staticItems =
        typeof campaign.actions[0].params.staticItems !== 'undefined'
          ? JSON.parse(campaign.actions[0].params.staticItems)
          : [];
      const exclusions =
        typeof campaign.actions[0].params.exclusions !== 'undefined'
          ? JSON.parse(campaign.actions[0].params.exclusions)
          : [];
      const inclusions =
        typeof campaign.actions[0].params.inclusions !== 'undefined'
          ? JSON.parse(campaign.actions[0].params.inclusions)
          : [];

      const includesMap = {
        category: [],
        brand: [],
        label: [],
      };

      const excludesMap = {
        category: [],
        brand: [],
        label: [],
        product: [],
      };

      exclusions.forEach(item => {
        const values = {};

        Object.keys(item).forEach(key => {
          if (
            (key !== 'type' && key !== 'criterion') ||
            (key === 'type' && item[key] === 'DISCOUNTED_PRODUCT')
          ) {
            if (key === 'categoryId') {
              if (Array.isArray(item.categoryId)) {
                excludesMap.category = excludesMap.category.concat(
                  item.categoryId,
                );
              } else if (item.categoryId.indexOf('|') > -1) {
                excludesMap.category = excludesMap.category.concat(
                  item.categoryId.split('|'),
                );
              } else {
                excludesMap.category.push(item.categoryId);
              }
            } else if (key === 'brand') {
              if (Array.isArray(item.brand)) {
                excludesMap.brand = excludesMap.brand.concat(item.brand);
              } else if (item.brand.indexOf('|') > -1) {
                excludesMap.brand = excludesMap.brand.concat(
                  item.brand.split('|'),
                );
              } else {
                excludesMap.brand.push(item.brand);
              }
            } else if (key === 'label') {
              if (Array.isArray(item.label)) {
                excludesMap.label = excludesMap.label.concat(item.label);
              } else if (item.label.indexOf('|') > -1) {
                excludesMap.label = excludesMap.label.concat(
                  item.label.split('|'),
                );
              } else {
                excludesMap.label.push(item.label);
              }
            } else if (key === 'productId' || key === 'product') {
              if (Array.isArray(item[key])) {
                excludesMap.product = excludesMap.product.concat(item[key]);
              } else if (item[key].indexOf('|') > -1) {
                excludesMap.product = excludesMap.product.concat(
                  item[key].split('|'),
                );
              } else {
                excludesMap.product.push(item[key]);
              }
            } else {
              values[key] = {
                value: item[key],
              };
            }
          }
        });

        if (Object.keys(values).length) {
          excludes.push({
            id: Math.random(),
            type: item.type.toLowerCase(),
            values,
          });
        }
      });

      inclusions.forEach(item => {
        const values = {};

        Object.keys(item).forEach(key => {
          if (
            key !== 'type' ||
            (key === 'type' && item[key] === 'DISCOUNTED_PRODUCT')
          ) {
            if (key === 'category') {
              if (Array.isArray(item.category)) {
                includesMap.category = includesMap.category.concat(
                  item.category,
                );
              } else if (item.category.indexOf('|') > -1) {
                includesMap.category = includesMap.category.concat(
                  item.category.split('|'),
                );
              } else {
                includesMap.category.push(item.category);
              }
            } else if (key === 'brand') {
              if (Array.isArray(item.brand)) {
                includesMap.brand = includesMap.brand.concat(item.brand);
              } else if (item.brand.indexOf('|') > -1) {
                includesMap.brand = includesMap.brand.concat(
                  item.brand.split('|'),
                );
              } else {
                includesMap.brand.push(item.brand);
              }
            } else if (key === 'label') {
              if (Array.isArray(item.label)) {
                includesMap.label = includesMap.label.concat(item.label);
              } else if (item.label.indexOf('|') > -1) {
                includesMap.label = includesMap.label.concat(
                  item.label.split('|'),
                );
              } else {
                includesMap.label.push(item.label);
              }
            } else {
              values[key] = {
                value: item[key],
              };
            }
          }
        });
        if (Object.keys(values).length) {
          includes.push({
            id: Math.random(),
            type: item.type.toLowerCase(),
            values,
          });
        }
      });

      staticItems.forEach(item => {
        if (item.productId) {
          if (Array.isArray(item.productId)) {
            products = products.concat(item.productId);
          } else {
            products.push(item.productId);
          }
        }

        if (item.promoCode) {
          criteria.push({
            id: Math.random(),
            type: item.type.toLowerCase(),
            values: {
              code: {
                value: item.promoCode,
              },
            },
          });
        }
      });

      if (products.length) {
        criteria.push({
          id: Math.random(),
          type: 'product',
          values: {
            productId: {
              value: products,
            },
          },
        });
      }

      Object.keys(includesMap).forEach(key => {
        const targetInclude = includesMap[key];
        const values = {};
        values[key] = {};
        values[key].value = targetInclude;
        if (targetInclude.length) {
          includes.push({
            id: Math.random(),
            type: key.toLowerCase(),
            values,
          });
        }
      });

      Object.keys(excludesMap).forEach(key => {
        const targetExclude = excludesMap[key];
        const values = {};
        if (key === 'category') {
          values.categoryId = {};
          values.categoryId.value = targetExclude;
        } else if (key === 'product') {
          values.productId = {};
          values.productId.value = targetExclude;
        } else {
          values[key] = {};
          values[key].value = targetExclude;
        }
        if (targetExclude.length) {
          excludes.push({
            id: Math.random(),
            type: key.toLowerCase(),
            values,
          });
        }
      });

      dynamicItems.forEach(item => {
        criteria.push({
          id: Math.random(),
          type: item.type.toLowerCase(),
          values: {
            criterion: {
              value:
                item.recommendationSource ===
                  'RECOMMENDATION_ALTERNATIVE_COMBINE_PRODUCTS' && isGG()
                  ? 'RECOMMENDATION_RELATED_COMBINE_PRODUCTS'
                  : item.recommendationSource,
            },
            itemCount: {
              value: item.itemCount,
            },
            timeFrame: {
              value: item.timeFrame || item.categoryChoice,
            },
          },
        });
      });

      wizardActions.addCriteria(criteria);
      wizardActions.addExclusion(excludes);
      wizardActions.addInclusion(includes);
      wizardActions.addTemplates(
        htmlTemplate,
        prejsTemplate,
        postjsTemplate,
        cssTemplate,
      );
    }
  } catch (err) {}
};

export const singleEmailCampaign = campaign => {
  try {
    if (campaign) {
      let recommendations;
      const { exclusions } = campaign;
      const { inclusions } = campaign;
      const items = [];
      const dynamicItems = [];
      const excludes = [];
      const includes = [];
      let products = [];

      if (campaign.additionalRecommendationAllowed) {
        recommendations = campaign.defaultRecommendation.concat(
          campaign.additionalRecommendations,
        );
      } else {
        recommendations = campaign.defaultRecommendation;
      }

      if (recommendations || exclusions || inclusions) {
        exclusions &&
          exclusions.forEach(item => {
            const values = {};

            Object.keys(item).forEach(key => {
              if (key !== 'type') {
                values[key] = {
                  value: item[key],
                };
              }
            });

            excludes.push({
              id: Math.random(),
              type: item.type.toLowerCase(),
              values,
            });
          });

        inclusions &&
          inclusions.forEach(item => {
            const values = {};

            Object.keys(item).forEach(key => {
              if (key !== 'type') {
                values[key] = {
                  value: item[key],
                };
              }
            });

            includes.push({
              id: Math.random(),
              type: item.type.toLowerCase(),
              values,
            });
          });

        recommendations &&
          recommendations.forEach(item => {
            if (item.type.toLowerCase() !== 'product') {
              if (item.type.toLowerCase() !== 'intelligent') {
                items.push({
                  id: Math.random(),
                  type: item.type.toLowerCase(),
                  values: {
                    criterion: {
                      value:
                        item[item.type.toLowerCase()] ||
                        item.recommendationSource,
                    },
                    itemCount: {
                      value: item.itemCount,
                    },
                    timeFrame: {
                      value: item.timeFrame,
                    },
                  },
                });
              } else {
                items.push({
                  id: Math.random(),
                  type: item.type.toLowerCase(),
                  values: {
                    criterion: {
                      value: item.source || item.recommendationSource,
                    },
                    itemCount: {
                      value: item.itemCount,
                    },
                    timeFrame: {
                      value: item.timeFrame,
                    },
                  },
                });
              }
            } else if (item.productId) {
              if (Array.isArray(item.productId)) {
                products = products.concat(item.productId);
              } else {
                products.push(item.productId);
              }
            }
          });

        if (products.length) {
          items.push({
            id: Math.random(),
            type: 'product',
            values: {
              productId: {
                value: products,
              },
            },
          });
        }
      }

      wizardActions.addCriteria(items);
      wizardActions.addExclusion(excludes);
      wizardActions.addInclusion(includes);
    }
  } catch (e) {}
};
