import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import Icons from '../icons';
import CampaignDevices from '../../modules/widgets/campaign-devices';
import {
  isAfterNow,
  getFormattedDate,
  getFormattedDetailedHour,
  reformatDate,
  periods,
} from '../../system/date';
import { t } from '../../system/ui';
import PushWidgetActions from '../widget/push-actions';

class PushList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pushCampaigns: [],
      count: 0,
      isToggled: false,
      sentPushCampaigns: [],
      requestedCampaign: 1,
    };

    this.getCampaigns = this.getCampaigns.bind(this);
    this.toggle = this.toggle.bind(this);
    this.loadMore = this.loadMore.bind(this);

    this.timeFormat = 'DD/MM/YYYY h:mm A';
  }

  componentDidMount() {
    this.getCampaigns(this.props.pushCampaigns);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.pushCampaigns !== newProps.pushCampaigns) {
      this.getCampaigns(newProps.pushCampaigns);
    }
  }

  getCampaigns(allPushCampaigns) {
    const { type } = this.props;
    let filteredCampaigns = allPushCampaigns.filter(push => {
      if (this.props.noStatusFilter) {
        return true;
      }
      if (this.props?.scheduleFilter === 'scheduled') {
        if (push.status === 'PASSIVE' && push?.schedule?.type === 'periodic') {
          return false;
        }
        return push.status === 'ACTIVE' || push.status === 'TEST';
      }
      if (this.props?.scheduleFilter === 'noScheduledTest') {
        const isScheduled =
          (push.schedule?.type === 'one_time' &&
            isAfterNow(moment(push.schedule.date))) ||
          push.schedule?.type === 'periodic';
        return push.status === 'TEST' && !isScheduled;
      }
      return push.status === type;
    });

    if (this.props.scheduleFilter) {
      if (this.props.scheduleFilter === 'scheduled') {
        filteredCampaigns = filteredCampaigns.filter(push => {
          return push.schedule
            ? (push.schedule?.type === 'one_time' &&
                isAfterNow(moment(push.schedule.date))) ||
                push.schedule?.type === 'periodic'
            : false;
        });
      } else if (this.props.scheduleFilter === 'sent') {
        filteredCampaigns = filteredCampaigns.filter(push => {
          return (
            push.status === 'COMPLETED' ||
            (push.schedule?.type === 'periodic' && push.status === 'PASSIVE') ||
            (push.schedule?.type === 'one_time' &&
              push.status === 'ACTIVE' &&
              !isAfterNow(moment(push.schedule.date))) ||
            (push.schedule?.type === 'now' && push.status === 'ACTIVE')
          );
        });
      }
    }
    this.setState(
      {
        pushCampaigns: filteredCampaigns,
        count: filteredCampaigns.length,
        isToggled: false,
        requestedCampaign: 1,
      },
      () => {
        if (this.props.onCountChange) {
          this.props.onCountChange(filteredCampaigns.length);
        }
      },
    );
  }

  toggle() {
    if (this.props.closed) {
      this.setState({
        isToggled: !this.state.isToggled,
      });
    }
  }

  loadMore(e) {
    e.preventDefault();
    this.setState({
      requestedCampaign: this.state.requestedCampaign + 1,
    });
  }

  render() {
    const sortedPushCampaigns = [...this.state.pushCampaigns].sort((a, b) => {
      if (a.schedule && b.schedule) {
        return b.schedule.date - a.schedule.date;
      }
      if (a.startDate && b.startDate) {
        return b.startDate - a.startDate;
      }
      return true;
    });

    let pushCampaigns = [];

    if (this.props.scheduleFilter === 'sent') {
      pushCampaigns = sortedPushCampaigns.slice(
        0,
        5 * this.state.requestedCampaign,
      );
    } else {
      pushCampaigns = sortedPushCampaigns;
    }

    const processScheduledPeriod = (isBulk, target) => {
      const { period, periodList } = target;
      const targetList = periodList || [period];
      const valuesList = periods.filter(time =>
        targetList.includes(time.value),
      );

      return valuesList.map(time => time.label).join(', ');
    };

    return pushCampaigns.length > 0 ? (
      <div
        className={classNames('widget-list-has-items', {
          'will-toggle': this.props.closed,
          'is-toggled': this.state.isToggled,
        })}
      >
        <h3
          className={classNames('page-subtitle', {
            passive: this.props.type === 'TEST',
          })}
          onClick={this.toggle}
        >
          {t(this.props.title)} ({this.state.count})
        </h3>
        <ul className='widget-list'>
          {pushCampaigns.map(campaign => {
            let scheduleText;
            let scheduleDate;
            let isScheduledDraft = false;
            if (this.props.scheduleFilter && campaign.schedule) {
              if (this.props.scheduleFilter === 'scheduled') {
                scheduleText =
                  campaign.schedule.type === 'one_time'
                    ? 'Will be sent at '
                    : 'Will be sent ';
                scheduleDate =
                  campaign.schedule.type === 'one_time'
                    ? reformatDate(campaign.schedule.date, this.timeFormat)
                    : `Every ${processScheduledPeriod(
                        campaign.pushType === 'BULK',
                        campaign.schedule,
                      )}`;
                if (campaign.schedule.type !== 'one_time') {
                  scheduleDate += ` at ${getFormattedDetailedHour(
                    campaign.schedule.date,
                  )}`;
                }
                if (campaign.status === 'TEST') {
                  isScheduledDraft = true;
                }
              } else {
                scheduleText = 'Sent at ';
                scheduleDate = reformatDate(
                  campaign.schedule.date,
                  this.timeFormat,
                );
              }
            }

            return (
              <li key={campaign.instanceId} className='widget-list-item'>
                <h2 className='widget-title'>{campaign.name}</h2>
                <div className='widget-meta'>
                  {isScheduledDraft && (
                    <span
                      className='promotion-label'
                      style={{
                        background: '#4c4c50',
                        color: '#fff',
                        fontWeight: '600',
                        width: '61px',
                      }}
                    >
                      {t('Draft')}
                    </span>
                  )}
                  {campaign.type !== 'PUSH_PERMISSION' && (
                    <CampaignDevices devices={campaign.devices} isPushModule />
                  )}
                  {scheduleText ? (
                    <span
                      className='widget-meta-date'
                      style={{ borderRight: 0 }}
                    >
                      <span>
                        <Icons name='calendar' />
                        <span>{scheduleText}</span>
                        {scheduleDate}
                      </span>
                    </span>
                  ) : (
                    <span
                      className='widget-meta-date'
                      style={{ borderRight: 0 }}
                    >
                      {campaign.startDate && (
                        <span>
                          <Icons name='calendar' />
                          {getFormattedDate(campaign.startDate)}
                        </span>
                      )}
                    </span>
                  )}
                </div>
                <PushWidgetActions
                  campaign={campaign}
                  withReplicateAction={this.props.withReplicateAction}
                  onChangeStatus={this.props.onChangeStatus}
                />
              </li>
            );
          })}
          {this.props.scheduleFilter === 'sent' &&
            pushCampaigns.length < this.state.pushCampaigns.length &&
            this.state.pushCampaigns.length > 5 && (
              <a href='#' onClick={this.loadMore} className='list-load-more'>
                Show More
              </a>
            )}
        </ul>
      </div>
    ) : (
      <></>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  user: store.user.user,
  status: store.campaigns.status,
});

export default connect(mapStatesToProps)(PushList);
