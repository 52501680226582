import React from 'react';
import PropTypes from 'prop-types';

function IconTrashBin(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64.125 64.125'
      x='0px'
      y='0px'
      width={`${props.width}px`}
      height={`${props.height}px`}
    >
      <g>
        <path
          d='M56.228,9.125h-13.04V4.836C43.188,2.17,41.019,0,38.353,0h-12.58c-2.666,0-4.835,2.17-4.835,4.836v4.289H7.897
          c-2.666,0-4.835,2.17-4.835,4.836v6.289c0,1.105,0.896,2,2,2h5.25v37.041c0,2.666,2.169,4.834,4.835,4.834h34.58
          c2.666,0,4.835-2.168,4.835-4.834V22.25h4.5c1.104,0,2-0.895,2-2v-6.289C61.062,11.295,58.894,9.125,56.228,9.125z M24.938,4.836
          C24.938,4.375,25.312,4,25.772,4h12.58c0.46,0,0.835,0.375,0.835,0.836v4.289h-14.25V4.836z M50.562,59.291
          c0,0.461-0.375,0.834-0.835,0.834h-34.58c-0.46,0-0.835-0.373-0.835-0.834V22.25h36.25V59.291z M57.062,18.25h-4.5h-40.25h-5.25
           v-4.289c0-0.461,0.375-0.836,0.835-0.836h15.04h18.25h15.04c0.46,0,0.835,0.375,0.835,0.836V18.25z'
          fill={props.color}
        />
      </g>
    </svg>
  );
}

IconTrashBin.defaultProps = {
  color: '#eee',
  width: 16,
  height: 16,
};

IconTrashBin.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default IconTrashBin;
