"use strict";

import React from "react";
import Select from "react-select";
import {Button} from "../../../components/fields";
import {uiActions, userActions} from "../../../actions";
import SwitchButton from "../../../components/shared/switch-button";
import { t } from "../../../system/ui";
import { decodeHtml } from "../../../system/string";
import { isSwitchAccount } from "../../../modules/auth/user";
import { updateAccount } from "../../../modules/account/ajax";

import {searchCategories} from "../../../modules/category/ajax";

class PersonalisationSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            account: {},
            categoryOptions: [],
            settings: {
                personalisation: {
                    mainCategories: [],
                    enabled: false
                }
            }
        };

        this.update = this.update.bind(this);
        this.toggleButton = this.toggleButton.bind(this);
        this.updateForm = this.updateForm.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onChangeSliderClickData = this.onChangeSliderClickData.bind(this);
        this.onChangeSliderClickEnabled = this.onChangeSliderClickEnabled.bind(this);
    }

    componentDidMount() {
        this.setState({account: this.props.account}, ()=> {
            this.updateForm();
        });
    }

    componentWillReceiveProps(newProps) {
        this.setState({account: newProps.account}, ()=> {
            this.updateForm();
        });
    }

    onChange(selectedValue) {
        let selectedCategory = typeof selectedValue !== "undefined" ? selectedValue : this.state.settings.personalisation.mainCategories;
        this.setState({
            settings: {
                ...this.state.settings,
                personalisation: {
                    ...this.state.settings.personalisation,
                    mainCategories: selectedCategory
                }
            }
        });
    }

    onInputChange(value) {
        if (value.length > 2) {
            searchCategories(value, response => {
                let catResponse = [];

                response.forEach(function(catListItem) {
                    let internalCat = [];
                    catListItem.split(">").forEach(onIterateHierarchicalCat);

                    //////

                    function onIterateHierarchicalCat(cat) {
                        internalCat.push(cat.trim());
                        let internalCatEl = internalCat.join(" > ");
                        if (catResponse.indexOf(internalCatEl) === -1) {
                            catResponse.push(internalCat.join(" > "));
                        }
                    }
                });

                let filteredResponse = catResponse.filter((item)=> {
                      let passed = true;
                      let selectedCategories = this.state.settings.personalisation.mainCategories.map((item) => {
                          return item.value
                      })
                      selectedCategories.forEach((category) => {
                          if (item.startsWith(category)) {
                              passed = passed && false;
                          }
                      });
                      return passed;
                  }
                );


                let categories = [];
                filteredResponse.forEach(item => {
                    categories.push({
                        value: item,
                        label: decodeHtml(item)
                    });
                });

                this.setState({
                    categoryOptions: categories
                });

                this.onChange();
            });
        } else {
            this.setState({
                categoryOptions: []
            });
        }
        return value;
    }

    toggleButton() {
        this.setState({
            settings: {
                ...this.state.settings,
                personalisation: {
                    ...this.state.settings.personalisation,
                    enabled: !this.state.settings.personalisation.enabled
                }
            }
        });
    }

    update(e) {
        e.preventDefault();
        let errors = document.querySelectorAll(".has-error");
        if (errors.length === 0) {
            uiActions.isLoading();

            let account = this.props.account;
            let status = "PASSIVE";
            let parentCategories = [];
            if (this.state.settings.personalisation.enabled === true &&
                    this.state.settings.personalisation.mainCategories &&
                    this.state.settings.personalisation.mainCategories.length > 0) {
                status = "ACTIVE";
                parentCategories = this.state.settings.personalisation.mainCategories.map((categorySelect) => {return categorySelect.value});
            }
            account.features.personalisation.status = status;
            if (account.personalisationConfig) {
                account.personalisationConfig.parentCategories = parentCategories;
            }

            updateAccount(account, response => {
                uiActions.isLoaded();
                if (response && response.status === "OK") {
                    if (isSwitchAccount()) {
                        let newUserToSave = isSwitchAccount();
                        newUserToSave.personalisationConfig = account.personalisationConfig;
                        userActions.updateSwitchAccount(newUserToSave);
                    } else {
                        userActions.updatePersonalisationConfig(account);
                    }

                    let notificationContent = () => (
                      <div>{t("You have successfully updated personalisation settings...")}</div>
                    );
                    uiActions.showNotification({content: notificationContent});
                }

            }, {isSwitchedUser: true});
        }
    }

    updateForm () {
        if (!this.state.account || !this.state.account.personalisationConfig) {
            return;
        }
        let mainCategories = this.state.account.personalisationConfig.parentCategories.map((item)=> {
            return {value: item, label: item};
        })
        this.setState({
            settings: {
                ...this.state.settings,
                personalisation: {
                    enabled: this.state.account.features.personalisation.status === "ACTIVE",
                    mainCategories: mainCategories
                }
            }
        });
    }

    onChangeSliderClickEnabled(e) {
        this.setState({
            settings: {
                ...this.state.settings,
                sliderClick: {
                    ...this.state.settings.sliderClick,
                    enabled: e.target.checked
                }
            }
        })
    }

    onChangeSliderClickData(e) {
        this.setState({
            settings: {
                ...this.state.settings,
                sliderClick: {
                    ...this.state.settings.sliderClick,
                    [e.target.name]: e.target.value
                }
            }
        })
    }

    render() {
        let categoryFieldRequiredError = this.state.settings.personalisation.enabled === true && (!this.state.settings.personalisation.mainCategories || this.state.settings.personalisation.mainCategories.length === 0);
        return (
            <div className="page-content-block one-percent-60 settings-recommendation-form-wrapper">
                <form id={"personalisation-settings-form"} onSubmit={this.update}>
                    <p className="form-info">{t("Personalisation Configurations")}</p>
                    <div id={"personalisation-settings"}>
                        <ol className="form-elements">
                            <li className="form-element">
                                <label className="item item-field is-switch">
                                    <SwitchButton isSwitchedOn={this.state.settings.personalisation.enabled} onToggle={this.toggleButton}/>
                                    <span className="item-label settings-item-label-toggle">
                                        {t("Advanced Personalisation")}
                                    </span>
                                </label>
                            </li>
                            {this.state.settings.personalisation.enabled === true &&
                                <li className="form-element">
                                    <label className={"item item-field personalization-parent-categories " + (categoryFieldRequiredError ? "has-error" : "")}>
                                        <span className="item-label settings-item-label-toggle">
                                            {t("Main Categories")}
                                        </span>
                                        <Select
                                          required={this.state.settings.personalisation.enabled === true}
                                          value={this.state.settings.personalisation.mainCategories}
                                          options={this.state.categoryOptions}
                                          name="categoryId"
                                          clearable={false}
                                          searchable={true}
                                          allowCreate={false}
                                          onChange={this.onChange}
                                          className={"criteria-field criteria-field-category"}
                                          multi={true}
                                          onInputChange={this.onInputChange}
                                        />
                                        {categoryFieldRequiredError &&
                                        <span className="item-error"><span> {t("This field is required")}</span></span>
                                        }

                                    </label>
                                </li>
                            }

                            <li className="buttons">
                                <Button attr={{submitAnimation: true, label: t("Update"), type: "tertiary"}} />
                            </li>
                        </ol>
                    </div>
                </form>
            </div>
        );
    }
}

export default PersonalisationSettings;
