import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SfyAccordion.scss';
import { t } from '../../../../../system/ui';
import DsSubActions from '../DsSubActions';
import Icons from '../../../../../components/icons';
import classes from '../../../RuleBasedSegmentation/SegmentList.module.scss';
import Tooltip from '../../../../../components/tooltip';

const SfySingleAccordion = ({ fetchSingleData }) => {
  const [isToggled, setIsToggled] = useState(false);
  const tooltipHandler = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div className='sfy-accordion--container'>
      <div className={classes.ds_single_segment_header}>
        <h3>Single Segments</h3>
        <div className={classes['toggle-container']}>
          <label className='item is-toggle' style={{ marginBottom: 0 }}>
            <input
              type='checkbox'
              checked={isToggled}
              disabled={fetchSingleData.length === 0}
              onChange={() => {
                tooltipHandler();
              }}
            />
            <span
              className='item-label'
              style={{ background: 'white', borderRadius: '10px' }}
            />
          </label>
          <span className='item-help'>
            <span className='live'>{t('Stats')}</span>
          </span>
        </div>
      </div>
      {fetchSingleData.length > 0 ? (
        fetchSingleData.map(segment => {
          return (
            <div className='sfy-accordion--item' key={segment.segmentId}>
              <div className='sfy-accordion--item-header-left'>
                <div className='sfy-accordion--item-header-name'>
                  {segment.segmentName}
                  {segment?.description && (
                    <Tooltip content={t(segment.description)} alignment='left'>
                      <i className='icon-info' role='presentation'>
                        <span className='for-screenreader-only' />
                      </i>
                    </Tooltip>
                  )}
                </div>
                {segment.status === 'ACTIVE' && (
                  <div
                    className={
                      classes[
                        `widget-list-item-container${isToggled ? '' : '-dnone'}`
                      ]
                    }
                  >
                    <div className={classes['widget-list-item-details']}>
                      <Icons name='PeopleGroupIcon' />
                      <span
                        className={classes['widget-list-item-details-text']}
                      >
                        {segment?.reachableUser === 0 || !segment?.reachableUser
                          ? t('Not segmented yet')
                          : `${segment?.reachableUser} ${t(
                              'users in segment',
                            )}`}
                      </span>
                    </div>
                    <div className={classes['widget-list-item-details']}>
                      <Icons name='pushModule' color='#737373' />
                      <span
                        className={classes['widget-list-item-details-text']}
                      >
                        {segment?.reachablePushUser === 0 ||
                        !segment?.reachablePushUser
                          ? t('No Data yet')
                          : `${t('Push Opt-In')}: ${
                              segment?.reachablePushUser
                            } `}
                      </span>
                    </div>
                    <div className={classes['widget-list-item-details']}>
                      <Icons name='emailModule' color='#737373' />
                      <span
                        className={classes['widget-list-item-details-text']}
                      >
                        {segment?.reachableEmailUser === 0 ||
                        !segment?.reachableEmailUser
                          ? t('No Data yet')
                          : `${t('Email Opt-In')}: ${
                              segment?.reachableEmailUser
                            } `}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='sfy-accordion--item-header-right --flex'>
                <div className='sfy-accordion--item-content-item-right-title'>
                  {segment.status === 'ACTIVE' ? (
                    <div className='sfy-accordion--item-active'>Live</div>
                  ) : (
                    <div className='sfy-accordion--item-passive'>Draft</div>
                  )}
                </div>
                <DsSubActions segments={segment} />
              </div>
            </div>
          );
        })
      ) : (
        <div className='widget-list-wrapper' />
      )}
    </div>
  );
};

SfySingleAccordion.propTypes = {
  cardHeader: PropTypes.string,
  fetchSingleData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    }).isRequired,
  ),
};

SfySingleAccordion.defaultProps = {
  cardHeader: '',
  fetchSingleData: [],
};

export default SfySingleAccordion;
