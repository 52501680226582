import React, { useState, useEffect } from 'react';
import { modalActions } from '../../../../actions';
import { SFYWrapper } from '../../../../components/sfy-components/module-exports';
import './productBundleModals.scss';
import RPProducts from '../../../../components/sfy-components/SFYRelatedProducts/Attributes/RPProducts';
import { decodeHtml } from '../../../../system/string';

/**
 * @name BundleProductModal
 * @description Modal to select the product for the bundle
 * @param {object} productProp
 * @param {function} onChange
 * @return {JSX.Element}
 */
const BundleProduct = ({
  productProp = {
    type: 'product',
    index: 0,
    key: '',
    exist: true,
    productRecord: {},
  },
  onChange,
}) => {
  const [product, setProduct] = useState(
    productProp.key
      ? {
          value: productProp?.productRecord?.productId,
          label: decodeHtml(productProp?.productRecord?.name),
        }
      : {},
  );

  useEffect(() => {
    modalActions.calculateModalHeight();
  }, []);

  useEffect(() => {
    const newProduct = {
      ...productProp,
      key: product?.value,
    };
    onChange(newProduct);
  }, [product]);

  return (
    <div className='bundle-product-modal'>
      <SFYWrapper>
        <RPProducts
          setAttributes={setProduct}
          mappedProducts={product || undefined}
          isSingle
        />
      </SFYWrapper>
    </div>
  );
};

export default BundleProduct;
