import React from "react";

class Samsung extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 219.61 220.37"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <title>samsung</title>
        <path
          d="M176.63,117.44a66.89,66.89,0,0,1-5.21,19.66c5.78-1.71,9.51-4.38,10.51-8s-.93-8-5.22-12.49Z"
          fill={this.state.color}
        />
        <path
          d="M109.83,52.4A57.77,57.77,0,0,0,52,110.13c12,8.06,30.22,16,51.25,21.72,21.72,5.92,42,8.25,56.59,7.17a57.42,57.42,0,0,0,7.69-28.48v-.35A57.76,57.76,0,0,0,109.83,52.4Z"
          fill={this.state.color}
        />
        <path
          d="M128,0H91.62L86.78.1C34.89,2.16,0,37.49,0,90.37v39.5c0,54.51,37.07,90.51,91.62,90.5H128c53.19,0,89.75-35.22,91.57-87.54l.07-4.08V91.62C219.61,37.07,182.53,0,128,0ZM109.8,177.37A67.23,67.23,0,0,1,45,128.1,131.36,131.36,0,0,0,58.08,136a57.74,57.74,0,0,0,83.55,22.44v-.11a119.46,119.46,0,0,0,15.3-.3A67,67,0,0,1,109.8,177.37Zm82.35-44.62c-2.23,8.17-12.24,13.44-27,15.5a114.77,114.77,0,0,1-12.79,1c-14.86.29-32.65-2-51.25-7.06s-35.17-12.15-47.88-20v-.08a105.39,105.39,0,0,1-10.52-7.29c-11.57-9.21-17.52-18.8-15.3-27S39.62,74.51,54.18,72.44a67.23,67.23,0,0,1,122.69,33.35C188.43,115,194.37,124.59,192.15,132.75Z"
          fill={this.state.color}
        />
        <path
          d="M36.92,89.58c-1.08,4,1.17,8.56,6,13.35a66.9,66.9,0,0,1,5.74-20.84h0l.5-1C42.36,82.75,38,85.6,36.92,89.58Z"
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default Samsung;
