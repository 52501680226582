import React from "react";
import connect from "react-redux/es/connect/connect";
import SearchInsightsPopularSearchesDetail from "./search-insights-popular-searches-detail";
import SearchInsightsNoResultSearches from "./search-insights-no-result-searches"
import SearchInsightsMostValuableProducts from "./search-insights-most-valuable-products"

  export const detailTablesComponentMap = {
  "general_searchInsightPopularSearchesDetail": SearchInsightsPopularSearchesDetail,
  "general_searchInsightNoResultSearchesDetail": SearchInsightsNoResultSearches,
  "assets_searchInsightMostValuableProductsDetail": SearchInsightsMostValuableProducts
};

class SearchInsightsAssets extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.tableName && this.props.tableName !== '') {
            let DetailTableComponent = detailTablesComponentMap[this.props.tableName];
            return (
              <div className={"search__m_t_10"}>
                <DetailTableComponent isDetail={true}/>
              </div>)

        } else {
          return (
            <div className={"search__m_t_10"}>
              <SearchInsightsMostValuableProducts isDetail={false}/>
            </div>
          );
        }
    }
}

const mapStatesToProps = store => ({
  tabName: store.searchInsights.tabName,
  tableName: store.searchInsights.tableName,
  selectedLanguage: store.switchLanguage.selectedLanguage,
});

export default connect(mapStatesToProps)(SearchInsightsAssets);
