/**
 * @author Sarpkaya Türe
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const DocumentIcon = ({ width = '32px', height = '32px', color = '#eee' }) => {
  const [state, setState] = useState({
    width: '',
    height: '',
    color: '',
  });

  const setStyles = () => {
    setState({
      width,
      height,
      color,
    });
  };

  useEffect(() => {
    setStyles();
  }, [width, height, color]);

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
      width={state.width}
      height={state.height}
      className='svg-icon'
    >
      <path
        d='M464 96h-192l-64-64h-160C21.5 32 0 53.5 0 80V160h512V144C512 117.5 490.5 96 464 96zM0 432C0 458.5 21.5 480 48 480h416c26.5 0 48-21.5 48-48V192H0V432z'
        fill={state.color}
      />
    </svg>
  );
};

DocumentIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

DocumentIcon.defaultProps = {
  width: '1rem',
  height: '1rem',
  color: '#eee',
};

export default DocumentIcon;
