/**
 * Created by Barisaltun on 21/02/19.
 */
import React from "react";
import Icons from "./index";

class GoTest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40.63 40.6"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon test"
      >
        <path
          d="M26.45,19.15l-7.52-5.74a1.45,1.45,0,0,0-2.33,1.15V26a1.45,1.45,0,0,0,2.33,1.15l7.52-5.74A1.45,1.45,0,0,0,26.45,19.15Z"
          fill={this.state.color}
        />
        <path
          d="M36.67,24.23l.16-.7,3.8-1.68v-3.1l-3.15-1.4-.81-1a18.21,18.21,0,0,0-.87-2.77L35.54,13l2.14-3.69a21.14,21.14,0,0,0-1.74-2.44l-4.11.94-.55-.48A16.2,16.2,0,0,0,29,5.6l-.61-.37L28,.93A19.47,19.47,0,0,0,25.24,0L22.5,3.18H18.13L15.38,0a18.93,18.93,0,0,0-2.75.93l-.4,4.3-.6.37A16.2,16.2,0,0,0,9.35,7.29l-.55.48L4.7,6.83A21.21,21.21,0,0,0,3,9.27l2,3.49c-.21.62-.88,3-1,3.6l-.15.7L0,18.75v3.1l3.81,1.68.15.7A16.5,16.5,0,0,0,4.83,27l.26.64L3,31.33A20.88,20.88,0,0,0,4.7,33.77l4.1-.94.55.48A17,17,0,0,0,11.63,35l.6.37.4,4.3a21.68,21.68,0,0,0,2.75.93l2.75-3.19H22.5l2.74,3.19A20.82,20.82,0,0,0,28,39.67l.35-4.28L29,35a16.2,16.2,0,0,0,2.28-1.69l.55-.48,4.11.94a22,22,0,0,0,1.74-2.44l-2.14-3.69L35.8,27A18.57,18.57,0,0,0,36.67,24.23Zm-6.51,5.91a13.92,13.92,0,1,1,4.07-9.84A13.84,13.84,0,0,1,30.16,30.14Z"
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default GoTest;
