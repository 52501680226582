import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAdminEndPoint, getInsightEndPoint } from 'system/api';
import { $http } from 'system/ajax';

const googleYoutubeApi = 'https://www.googleapis.com/youtube/v3/playlistItems';
const youtubeApiKey = 'AIzaSyCU8QeQbDO6GaYvIUFjdph3cQ5M1VAozJQ';
const youtubePlayListIds = {
  welcomeList: 'PLcvHvj7j3KZeANrFrCm3DgE7rnMM6JxPI',
  EgsList: 'PLcvHvj7j3KZfB588-Suj0LITATcYtsOJc',
};
const insightIdsToUse = [3, 15]; // 3: Rockstar, 9: Wish You Have, 15: Push Hard

const getUserListFilter = async data => {
  const accountUsers = {};
  data.forEach(item => {
    item.switchableAccounts.forEach(accountId => {
      if (!accountUsers[accountId]) {
        accountUsers[accountId] = [];
      }
      accountUsers[accountId].push({
        user: item,
        isOwner: item.account.accountId === accountId,
      });
    });
  });
  return accountUsers;
};

const getEgsVideosFilter = async (data, count) => {
  const responseArray = [];
  data.items.forEach(item => {
    const itemSnippetTitle = item.snippet.title.toLowerCase();
    if (
      !itemSnippetTitle.includes('deleted') &&
      !itemSnippetTitle.includes('removed') &&
      !itemSnippetTitle.includes('private') &&
      responseArray.length < count
    ) {
      responseArray.push({
        title: item.snippet.title,
        description: item.snippet.description,
        thumbnail: item.snippet.thumbnails.medium.url,
        videoId: item.snippet.resourceId.videoId,
        videoUrl: `https://www.youtube.com/embed/${item.snippet.resourceId.videoId}`,
      });
    }
  });
  return responseArray;
};

const getWelcomeVideoFilter = async data => {
  const item = data.items[0];
  return {
    title: item.snippet.title,
    description: item.snippet.description,
    thumbnail: item.snippet.thumbnails.medium.url,
    videoId: item.snippet.resourceId.videoId,
    videoUrl: `https://www.youtube.com/embed/${item.snippet.resourceId.videoId}`,
  };
};

const getInsightsFilter = async data => {
  const responseArray = [];
  insightIdsToUse.forEach(id => {
    const item = data.find(insight => insight.id === id);
    if (item) {
      responseArray.push(item);
    }
  });
  return responseArray;
};

export const getUserList = createAsyncThunk('MSP/getUserList', async () => {
  const url = getAdminEndPoint('accounts/users');
  const response = await $http(url).get();
  return await getUserListFilter(response);
});

export const getUserListReducer = {
  [getUserList.pending]: state => {
    state.userList.loading = true;
    state.userList.loaded = false;
  },
  [getUserList.fulfilled]: (state, action) => {
    state.userList.loading = false;
    state.userList.loaded = true;
    state.userList.data = action.payload;
  },
  [getUserList.rejected]: state => {
    state.userList.loading = false;
    state.userList.loaded = false;
    state.userList.data = {};
  },
};

export const getAccountList = createAsyncThunk(
  'MSP/getAccountList',
  async () => {
    const url = getAdminEndPoint('accounts/list');
    const response = await $http(url).get();
    return response;
  },
);

export const getAccountListReducer = {
  [getAccountList.pending]: state => {
    state.accountList.loading = true;
    state.accountList.loaded = false;
  },
  [getAccountList.fulfilled]: (state, action) => {
    state.accountList.loading = false;
    state.accountList.loaded = true;
    state.accountList.data = action.payload;
  },
  [getAccountList.rejected]: state => {
    state.accountList.loading = false;
    state.accountList.loaded = false;
    state.accountList.data = [];
  },
};

export const getEgsVideos = createAsyncThunk(
  'MSP/getEgsVideos',
  async count => {
    const url = `${googleYoutubeApi}?part=snippet&maxResults=${count +
      3}&playlistId=${youtubePlayListIds.EgsList}&key=${youtubeApiKey}`;
    const response = await fetch(url);
    const responseJson = await response.json();
    return await getEgsVideosFilter(responseJson, count);
  },
);

export const getEgsVideosReducer = {
  [getEgsVideos.pending]: state => {
    state.egsVideos.loading = true;
    state.egsVideos.loaded = false;
  },
  [getEgsVideos.fulfilled]: (state, action) => {
    state.egsVideos.loading = false;
    state.egsVideos.loaded = true;
    state.egsVideos.data = action.payload;
  },
  [getEgsVideos.rejected]: state => {
    state.egsVideos.loading = false;
    state.egsVideos.loaded = false;
    state.egsVideos.data = [];
  },
};

export const getWelcomeVideo = createAsyncThunk(
  'MSP/getWelcomeVideo',
  async () => {
    const url = `${googleYoutubeApi}?part=snippet&maxResults=1&playlistId=${youtubePlayListIds.welcomeList}&key=${youtubeApiKey}`;
    const response = await fetch(url);
    const responseJson = await response.json();
    return await getWelcomeVideoFilter(responseJson);
  },
);

export const getWelcomeVideoReducer = {
  [getWelcomeVideo.pending]: state => {
    state.welcomeVideo.loading = true;
    state.welcomeVideo.loaded = false;
  },
  [getWelcomeVideo.fulfilled]: (state, action) => {
    state.welcomeVideo.loading = false;
    state.welcomeVideo.loaded = true;
    state.welcomeVideo.data = action.payload;
  },
  [getWelcomeVideo.rejected]: state => {
    state.welcomeVideo.loading = false;
    state.welcomeVideo.loaded = false;
    state.welcomeVideo.data = {};
  },
};

export const getInsights = createAsyncThunk('MSP/getInsights', async () => {
  const url = getInsightEndPoint('info');
  const response = await $http(url).get();
  return await getInsightsFilter(response);
});

export const getInsightsReducer = {
  [getInsights.pending]: state => {
    state.insights.loading = true;
    state.insights.loaded = false;
  },
  [getInsights.fulfilled]: (state, action) => {
    state.insights.loading = false;
    state.insights.loaded = true;
    state.insights.data = action.payload;
  },
  [getInsights.rejected]: state => {
    state.insights.loading = false;
    state.insights.loaded = false;
    state.insights.data = [];
  },
};
