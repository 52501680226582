import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import { TextField } from '../fields';
import { convertToPositiveInt, operatorObjToArray } from './utils/utils';
import { getMomentObjDate } from '../../system/date';

import {
  dateOperators,
  dateOperatorUnits,
  operatorColumns,
} from '../../constants/datamaps/rule-based-segmentation';
import { ErrorText } from './module-exports';

const defaultValuesByUnit = {
  [dateOperatorUnits.days.value]: 30,
  [dateOperatorUnits.weeks.value]: 4,
  [dateOperatorUnits.months.value]: 1,
};

/**
 * @name CriteriaFilterDate
 * @description Component that renders the date filter
 * @param {object} criteriaData - The criteria data
 * @param {string} filterType - The filter type
 * @param {array} statements - The statements
 * @param {function} callbackFunc - The callback function
 * @returns {JSX.Element}
 */
const criteriaFilterDate = ({
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const defaultFilterData = {
    dataType: criteriaData?.dataType,
    column: operatorColumns.dateOperator,
    operator: dateOperators.last.value,
    unit: dateOperatorUnits.days.value,
    value: defaultValuesByUnit[dateOperatorUnits.days.value],
  };

  const [date, setDate] = useState(null);
  const [dateMax, setDateMax] = useState(null); // Using only with between operator
  const [dateNumber, setDateNumber] = useState(''); // Using only with last X days operator
  const [selectedOperator, setSelectedOperator] = useState('');
  const [selectedOperatorUnit, setSelectedOperatorUnit] = useState('');
  const [focusedSingleDate, setFocusedSingleDate] = useState(null);
  const [focusedRangeDate, setFocusedRangeDate] = useState(null);

  if (statements?.[filterType].length === 0) {
    callbackFunc(filterType, [defaultFilterData]);
  }

  useEffect(() => {
    const tempStatements = statements?.[filterType];

    if (tempStatements?.length > 1) {
      // Between operator
      tempStatements.forEach(statement => {
        if (statement.operator === 'gte') {
          const checkedDateVal = statement.value
            ? getMomentObjDate(statement.value)
            : null;
          setDate(checkedDateVal);
        } else if (statement.operator === 'lte') {
          const checkedDateVal = statement.value
            ? getMomentObjDate(statement.value)
            : null;
          setDateMax(checkedDateVal);
        }
      });
      setSelectedOperator(dateOperators.between.value);
    } else if (tempStatements?.length === 1 && tempStatements[0].operator) {
      if (tempStatements[0].operator === dateOperators.last.value) {
        // Last X days operator
        setDateNumber(tempStatements[0].value);
        setSelectedOperatorUnit(tempStatements[0].unit || 'days');
      } else {
        // Specific Date operator
        const checkedDateVal = tempStatements[0].value
          ? getMomentObjDate(tempStatements[0].value)
          : null;
        setDate(checkedDateVal);
      }
      setSelectedOperator(tempStatements[0].operator);
    } else {
      setSelectedOperator(defaultFilterData.operator);
      setSelectedOperatorUnit(defaultFilterData.unit);
      setDateNumber(defaultFilterData.value);
    }
  }, [statements]);

  const triggerCallbackFunc = (val, type) => {
    const columnVal = operatorColumns.dateOperator;
    const operatorVal = type === 'operator' ? val.value : selectedOperator;
    let unitVal = type === 'operatorUnit' ? val.value : selectedOperatorUnit;
    let dateVal = type === 'date' ? val : date;
    if (type === 'dateRange') {
      dateVal = val.startDate;
    }

    let dateMaxVal = type === 'dateRange' ? val.endDate : dateMax;
    let dateNumberVal =
      type === 'dateNumber'
        ? convertToPositiveInt(val.target.value, 1)
        : dateNumber;

    if (type === 'operatorUnit') {
      dateNumberVal = defaultValuesByUnit[unitVal];
    }

    if (type === 'operator') {
      dateVal = null;
      dateMaxVal = null;
      dateNumberVal = null;
      unitVal = '';
    }

    if (dateVal) {
      dateVal = dateVal.format('YYYY-MM-DD');
    }
    if (dateMaxVal) {
      dateMaxVal = dateMaxVal.format('YYYY-MM-DD');
    }

    const filterData = [
      {
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator:
          operatorVal === dateOperators.between.value ? 'gte' : operatorVal,
        unit: unitVal,
        value:
          operatorVal === dateOperators.last.value ? dateNumberVal : dateVal,
      },
    ];
    if (operatorVal === dateOperators.between.value) {
      filterData.push({
        dataType: criteriaData?.dataType,
        column: columnVal,
        operator: 'lte',
        value: dateMaxVal,
      });
    }

    callbackFunc(filterType, filterData);
  };

  const onChange = (selectedValue, type) => {
    switch (type) {
      case 'date':
        setDate(selectedValue);
        break;
      case 'dateRange':
        setDate(selectedValue.startDate);
        setDateMax(selectedValue.endDate);
        break;
      case 'dateNumber':
        setDateNumber(convertToPositiveInt(selectedValue.target.value, 1));
        break;
      case 'operator':
        setSelectedOperator(selectedValue?.value);
        setDate(null);
        setDateMax(null);
        setDateNumber(defaultFilterData.value);
        if (selectedValue?.value !== dateOperators.last.value) {
          setSelectedOperatorUnit('');
        }
        break;
      case 'operatorUnit':
        setSelectedOperatorUnit(selectedValue?.value);
        setDateNumber(defaultValuesByUnit[selectedValue?.value]);
        break;
      default:
        break;
    }
    if (type !== 'dateRange') {
      triggerCallbackFunc(selectedValue, type);
    }
  };

  let className = 'double-row';
  if (selectedOperator === dateOperators.between.value) {
    className = 'multi-date-row double-row';
  } else if (selectedOperator === dateOperators.last.value) {
    className = 'three-row date-row';
  }

  return (
    <div className={`rbs-filter-field ${className}`}>
      <div className='filter-operator'>
        <span className='filter-operator-title'>Time Range*</span>
        <Select
          value={selectedOperator}
          options={operatorObjToArray(dateOperators)}
          name='operator'
          clearable={false}
          searchable={false}
          onChange={e => onChange(e, 'operator')}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      {selectedOperator === dateOperators.equals.value && (
        <>
          <div className='filter-connector' />
          <div className='filter-multi-connector' />
          <div className={`filter-operator ${!date ? 'has-error' : ''}`}>
            <span className='filter-operator-title'>Date*</span>
            <SingleDatePicker
              date={date} // momentPropTypes.momentObj or null
              placeholder='dd/mm/yy'
              onDateChange={dateVal => onChange(dateVal, 'date')} // PropTypes.func.isRequired
              focused={focusedSingleDate} // PropTypes.bool
              onFocusChange={({ focused }) => setFocusedSingleDate(focused)} // PropTypes.func.isRequired
              isOutsideRange={() => false}
              required
              showDefaultInputIcon
              inputIconPosition='after'
              displayFormat='DD/MM/YYYY'
            />
            {!date && <ErrorText />}
          </div>
        </>
      )}
      {selectedOperator === dateOperators.between.value && (
        <>
          <div className='filter-connector' />
          <div
            className={`filter-operator ${
              !date || !dateMax ? 'has-error' : ''
            }`}
          >
            <span className='filter-operator-title'>Date*</span>
            <DateRangePicker
              startDate={date} // momentPropTypes.momentObj or null,
              endDate={dateMax} // momentPropTypes.momentObj or null,
              startDatePlaceholderText='dd/mm/yy'
              endDatePlaceholderText='dd/mm/yy'
              customArrowIcon={<span name='calendar-separator'>-</span>}
              onDatesChange={({ startDate, endDate }) => {
                onChange({ startDate, endDate }, 'dateRange');
              }} // PropTypes.func.isRequired,
              onClose={({ startDate, endDate }) => {
                triggerCallbackFunc({ startDate, endDate }, 'dateRange');
              }} // PropTypes.func.isRequired,
              focusedInput={focusedRangeDate} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => setFocusedRangeDate(focusedInput)} // PropTypes.func.isRequired,
              isOutsideRange={() => false}
              required
              showDefaultInputIcon
              inputIconPosition='after'
              displayFormat='DD/MM/YYYY'
            />
            {(!date || !dateMax) && <ErrorText />}
          </div>
        </>
      )}
      {selectedOperator === dateOperators.last.value && (
        <>
          <div className='filter-multi-connector' />
          <div className='filter-operator'>
            <span className='filter-operator-title'>Value Date*</span>
            <TextField
              value={dateNumber}
              onChange={e => onChange(e, 'dateNumber')}
              type='number'
              required
            />
          </div>
          <div className='filter-multi-connector' />
          <div className='filter-operator'>
            <span className='filter-operator-title'>Unit*</span>
            <Select
              value={selectedOperatorUnit}
              options={operatorObjToArray(dateOperatorUnits)}
              name='operatorUnit'
              clearable={false}
              searchable={false}
              onChange={e => onChange(e, 'operatorUnit')}
              className='criteria-field one-whole'
              openOnClick
            />
          </div>
        </>
      )}
    </div>
  );
};

export default criteriaFilterDate;
