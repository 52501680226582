import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const CategoryAccessoryIcon = ({ color, width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 12 12'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.939331 1.53133C0.939331 1.05738 1.32806 0.666748 1.80202 0.666748H4.93693C5.41089 0.666748 5.79962 1.05737 5.79962 1.53133V4.67569C5.79962 5.14964 5.41089 5.54028 4.93693 5.54028H1.80202C1.32806 5.54028 0.939331 5.14966 0.939331 4.67569V1.53133ZM6.74527 1.53133C6.74527 1.05691 7.13496 0.666748 7.60796 0.666748H10.7443C11.2173 0.666748 11.6061 1.05737 11.6061 1.53133V4.67569C11.6061 5.14964 11.2173 5.54028 10.7443 5.54028H7.60796C7.13494 5.54028 6.74527 5.1506 6.74527 4.67569V1.53133ZM1.80202 6.45838C1.32759 6.45838 0.939331 6.84949 0.939331 7.32297V10.4683C0.939331 10.9418 1.32759 11.3329 1.80202 11.3329H4.93693C5.41137 11.3329 5.79962 10.9418 5.79962 10.4683V7.32297C5.79962 6.84949 5.41137 6.45838 4.93693 6.45838H1.80202ZM6.74527 7.32297C6.74527 6.84901 7.134 6.45838 7.60796 6.45838H10.7443C11.2183 6.45838 11.6061 6.84949 11.6061 7.32297V10.4683C11.6061 10.9418 11.2183 11.3329 10.7443 11.3329H7.60796C7.13447 11.3329 6.74527 10.9423 6.74527 10.4683V7.32297Z'
        fill={color}
      />
    </svg>
  );
};

CategoryAccessoryIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

CategoryAccessoryIcon.defaultProps = {
  width: '12px',
  height: '12px',
  color: '#7A7A7C',
};

export default CategoryAccessoryIcon;
