import React, { useEffect, useState } from 'react';
import { Creatable } from 'react-select';
import Icons from '../../../icons';
import { t } from '../../../../system/ui';
import { getFilterOptions } from '../../../../modules/shop-the-look/ajax';
import { decodeHtml } from '../../../../system/string';
import './AttributeComponents.scss';

const Gender = props => {
  const [genders, setGenders] = useState([]);
  const [gender, setGender] = useState('');

  const onChange = selectedValue => {
    const selectedLabel =
      typeof selectedValue !== 'undefined' ? selectedValue : gender;
    setGender(selectedLabel);

    props.onSelectChange(
      'label',
      selectedLabel,
      props.id,
      props.segmentType,
      props.type,
    );
  };

  const fetchGenders = () => {
    const selectedGenders = [];
    getFilterOptions('gender').then(res => {
      const sorted = res.sort();
      const fetchedGenders = [];
      sorted.forEach(sortedGender => {
        fetchedGenders.push({
          value: sortedGender,
          label: decodeHtml(sortedGender),
        });
      });
      if (props.values.label !== undefined) {
        fetchedGenders.push({
          value: props.values.label.value[0],
          label: decodeHtml(props.values.label.value[0]),
        });
        props.values.label.value.forEach(propsGender => {
          selectedGenders.push({
            value: propsGender,
            label: decodeHtml(propsGender),
            title: decodeHtml(propsGender),
          });
        });
      }

      setGenders(fetchedGenders);
      setGender(selectedGenders);
    });
  };

  useEffect(() => {
    fetchGenders();
  }, []);

  return (
    <div className='sfy-color'>
      <div className='sfy-color-left'>
        <div className='sfy-color-icoText'>
          <span className='sfy-color-icon'>
            <Icons name='excludeGender' color='#787774' />
          </span>

          <div className='sfy-color-titleSelectMain'>
            <div className='sfy-color-titleSelect'>
              <span className='sfy-color-title'>{t('Gender')}</span>
            </div>
          </div>

          <Creatable
            value={gender}
            options={genders}
            name='gender'
            clearable={false}
            onChange={onChange}
            className='criteria-field criteria-field-category'
            multi
            placeholder={t('Type Gender')}
            shouldKeyDownEventCreateNewOption={e => {
              switch (e.keyCode) {
                case 9:
                case 13:
                  return !0;
                default:
                  return !1;
              }
            }}
          />
        </div>
      </div>

      <a
        onClick={() =>
          props.onFilterRemove(props.id, props.segmentType, props.type)
        }
        className='sfy-color-actions'
      >
        <Icons name='crossCircle' />
      </a>
    </div>
  );
};

export default Gender;
