import React from "react";

class UCBrowser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 220 220"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <title>uc</title>
        <path
          d="M91.72,147.18a11.68,11.68,0,0,0-11,11.58,11.27,11.27,0,0,0,22.53-.57C103.12,152.25,97.6,147,91.72,147.18Z"
          fill={this.state.color}
        />
        <path
          d="M173.06,0H46.94A47,47,0,0,0,0,46.94V173.06A47,47,0,0,0,46.94,220H173.06A47,47,0,0,0,220,173.06V46.94A47,47,0,0,0,173.06,0ZM142.62,84.21c4-2.26,5.56-5.67,6-10.44.44-5,2.55-9.82,3.93-14.72l2.49-.2c1.54,2.84,3.65,5.52,4.46,8.55,1.22,4.51,3.55,7,7.92,8.88,13.94,6.1,20.63,17.4,21.83,32.37.39,4.89-1.7,7.6-6.26,7.62-5.4,0-11.44.6-16.07-1.53-15.33-7-29.87-15.89-48.08-17.35C127.51,92.58,135.11,88.47,142.62,84.21ZM45.41,176.34A49.38,49.38,0,0,1,37.59,114C42.68,106,49.85,99.34,55,91.41c3.88-5.93,7.86-12.73,8.67-19.52,1.49-12.65-11.38-21.5-24.58-18.23-1.74.44-3.43,1.08-6.14,1.94C43.76,34.71,73.82,24,93.9,33.19,117.68,44,122.7,73,103.51,92.42,97,99,88.91,103.93,81.2,109.13c-9.55,6.44-18.27,13.42-23,24.41-8,18.8-1.18,41.49,16,52.51,1.51,1,3.06,1.86,4.77,2.89C69.13,190.19,53.84,184.39,45.41,176.34Zm23.83-17.82a22.79,22.79,0,1,1,45.57.31c-.06,12.76-10,22.41-22.93,22.37A22.26,22.26,0,0,1,69.24,158.52Zm88.85,29.72-51.24,1.87c8.63-5.88,15.39-12.76,18.23-22.82,2.89-10.24.91-19.72-4.89-28.42C110.11,123.78,92,121,70.6,131.71c8.83-14.09,25.21-18.66,41.92-12.42,22.25,8.31,30.56,26.6,25.59,57.37,3.74.91,7.77,1.34,11.31,2.91s6.36,4.19,9.51,6.36Zm7.41-48.48c-10.13,3.64-15,1.83-21.37-6.95-11.56-15.83-26.73-25-46.77-25.4,16.49-4.06,32.25-1.18,46.66,7,11.26,6.37,22.41,11.92,35.53,13,4.51.36,8.28,2.85,10.55,9.17C180.5,132.75,173.18,137,165.5,139.76Z"
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default UCBrowser;
