/**
 * Created by mehmetyurtar on 4/19/18.
 */

import React from "react";

class IconUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 12 11"
        width={this.state.width}
        height={this.state.height}
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
          opacity="0.477921196"
        >
          <g
            id="1-Account-Switch"
            transform="translate(-865.000000, -279.000000)"
            fill={this.state.color}
            fillRule="nonzero"
          >
            <g id="Group-2" transform="translate(193.000000, 84.000000)">
              <g id={1} transform="translate(11.000000, 154.000000)">
                <g id="Group-3" transform="translate(21.504298, 27.000000)">
                  <g id="Group-5" transform="translate(640.000000, 4.000000)">
                    <g id="Group-4" transform="translate(0.000000, 7.000000)">
                      <g
                        id="users-16px_multiple_1"
                        transform="translate(0.000000, 3.000000)"
                      >
                        <path
                          d="M0.636363636,11 L6.36363636,11 C6.7150903,11 7,10.6803189 7,10.2859723 L7,8.85791685 C7,8.40842597 6.81138925,7.98516702 6.49090909,7.7154725 L4.76954545,6.26671026 C5.36923301,5.80110947 5.72715438,5.03499434 5.72727273,4.21673668 L5.72727273,3.64551451 C5.75414988,2.27962705 4.83249127,1.12433657 3.61963636,1.00361195 C3.00848508,0.966724035 2.41073518,1.2136446 1.9667939,1.68637374 C1.52285263,2.15910288 1.27184367,2.81597988 1.27272727,3.50270896 L1.27272727,4.21673668 C1.27284133,5.03663603 1.63208538,5.8041039 2.23363636,6.26956637 L0.498909091,7.76045624 C0.184402701,8.03070065 -2.49459284e-05,8.44985133 0,8.89433226 L0,10.2859723 C2.06688698e-17,10.4753442 0.0670452621,10.65696 0.186386594,10.7908661 C0.305727926,10.9247723 0.467589506,11 0.636363636,11 Z"
                          id="Shape"
                        />
                        <path
                          d="M10.3421441,3.84351116 L9.01757236,3.12348149 C9.34091309,2.84732058 9.52188566,2.48103713 9.52333136,2.09983932 L9.52333136,1.56761739 C9.54048238,0.813157297 8.88528527,0.15873698 7.9728293,0.0189535917 C7.15143899,-0.0879125848 6.34357576,0.264922628 6,0.88058909 C6.83322374,1.50285344 7.30771103,2.38025314 7.30832841,3.29988876 L7.30832841,3.89991348 C7.30825599,4.42933268 7.15096833,4.9509185 6.8498228,5.42037612 C6.8498228,5.42037612 7.70481394,5.96039837 7.75871234,6 L10.2616657,6 C10.6694365,6 11,5.73135978 11,5.39997528 L11,4.84195229 C10.9999093,4.44068776 10.753042,4.06601234 10.3421441,3.84351116 Z"
                          id="Shape"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconUsers;
