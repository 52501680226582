import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
/**
 * Module dependencies
 */
import { switchUserActions, uiActions } from '../../actions';
import { resetSwitchRegion } from '../../actions/switchRegion';
import setHighchartsDefaults from '../../modules/highcharts.global';
import {
  getPushCampaigns,
  getRangedPushReports,
} from '../../modules/push/ajax';
import transformTrends from '../../modules/transform/pushReport';
import { queryDate } from '../../system/date';
import { calculateRatio } from '../../system/ratio';
import { localeString, localeStringMoney } from '../../system/string';
import Currency from '../currency';
import { pushCampaignTableHeaders } from './constants';

const pushAdditionalGroups = {
  ABANDONED_CART_FIRST_REMINDER: 'Abandoned Cart - First Reminder',
  ABANDONED_CART_SECOND_REMINDER: 'Abandoned Cart - Second Reminder',
  ABANDONED_CART_ALTERNATIVE_PRODUCTS: 'Abandoned Cart - Alternative Products',
  ABANDONED_CART_COUPON: 'Abandoned Cart - Coupon',
};

const pushGroups = {
  Retention: [
    'ABANDONED_CART',
    'LAST_VISIT_ALTERNATIVES',
    'NICE_TO_MEET_YOU',
    'LAST_VISIT_REMINDER',
  ],
  Growth: ['NEW_COMERS', 'TOP_SELLERS', 'ORDER_FOLLOW_UP', 'BIRTHDAY'],
  Winback: ['WE_MISSED_YOU', 'CHURN', 'PRICE_DROP', 'BACK_IN_STOCK'],
  'FLY Mode': ['FLY'],
  'Bulk / Segmented': ['PUSH_NOTIFICATION'],
  Recommendation: ['RECOMMENDATION'],
  Betting: ['UPCOMING'],
};

/**
 * @name unique
 * @description get unique values from array Mobile, Web, Desktop
 * @param {Array} data
 * @returns {Array} unique values
 * */

const unique = data => {
  return data.reduce((acc, curr) => {
    const found = acc.find(item => item.x === curr.x);
    if (found) {
      found.y += curr.y;
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);
};

/**
 * @name groupPushCampaigns
 * @description group push campaigns by group
 * @param {Array} campaigns
 * @param {Object} reportResponse
 * @returns {Object}  grouped push campaigns
 * */
const groupPushCampaigns = (campaigns, reportResponse) => {
  const groups = Object.keys(pushGroups).reduce(
    (result, group) => ({
      ...result,
      [group]: campaigns.filter(
        campaign =>
          pushGroups[group].includes(campaign.campaign.type) &&
          campaign.report.length > 0,
      ),
    }),
    {},
  );

  const additionalPush = Object.keys(pushAdditionalGroups)
    .filter(addGroups => Object.keys(reportResponse).includes(addGroups))
    .map(addGroups => {
      const response = Object.keys(reportResponse).find(
        res => res === addGroups,
      );
      return response === 'ABANDONED_CART_FIRST_REMINDER'
        ? {
            instanceId: addGroups,
            report: unique(
              reportResponse[response].concat(
                reportResponse.ABANDONED_CART.filter(
                  i => i.x !== 'purchase:amount',
                ),
              ),
            ),
            campaign: {
              name: pushAdditionalGroups[addGroups],
              instanceId: addGroups,
            },
          }
        : {
            instanceId: addGroups,
            report: unique(reportResponse[response]),
            campaign: {
              name: pushAdditionalGroups[addGroups],
              instanceId: addGroups,
            },
          };
    });
  return { ...groups, Retention: [...groups.Retention, ...additionalPush] };
};

/**
 * @name PushCampaignStats
 * @description PushCampaignStats component
 * @param {Object} userToSwitch
 * @param {Object} range
 * @param {Object} account
 * @param {Object} rangeAlias
 * @returns {ReactNode}  PushCampaignStats component as Table
 * */
const PushCampaignStats = ({ userToSwitch, range, account, rangeAlias }) => {
  const [campaigns, setCampaigns] = useState({});
  const { isLoading, isLoaded } = uiActions;

  const editCampaign = (type, instanceId) => {
    if (userToSwitch) {
      resetSwitchRegion();
      const processedUser = {
        ...userToSwitch,
        account: account,
      };
      switchUserActions.updateSwitchUser(processedUser);
      setHighchartsDefaults(processedUser);
      isLoading();
      setTimeout(() => {
        isLoaded();

        const targetURL = instanceId.includes('ABANDONED')
          ? 'ABANDONED_CART'
          : `${type}/${instanceId}`;

        if (window.location.pathname.includes('/ext/push')) {
          browserHistory.push(`/ext/push/edit/${targetURL}`);
        } else {
          browserHistory.push(`/push/edit/${targetURL}`);
        }
      }, 500);
    } else {
      const targetURL = instanceId.includes('ABANDONED')
        ? 'ABANDONED_CART'
        : `${type}/${instanceId}`;

      if (window.location.pathname.includes('/ext/push')) {
        browserHistory.push(`/ext/push/edit/${targetURL}`);
      } else {
        browserHistory.push(`/push/edit/${targetURL}`);
      }
    }
  };

  useEffect(() => {
    isLoading();
    getPushCampaigns(
      response => {
        if (response) {
          const allCampaigns = response.map(campaign => ({
            instanceId: campaign.instanceId,
            campaign,
            report: {},
          }));
          const reportIds = response.map(push => push.instanceId);

          const boundaries = {};
          boundaries.start = queryDate(range[0]);
          boundaries.end = queryDate(range[1]);
          boundaries.datelabel = rangeAlias;

          getRangedPushReports(
            reportIds,
            boundaries,
            reportsResponse => {
              isLoaded();
              if (reportsResponse) {
                setCampaigns(
                  groupPushCampaigns(
                    allCampaigns.map(campaign => ({
                      ...campaign,
                      report: reportsResponse[campaign.instanceId] || {},
                    })),
                    reportsResponse,
                  ),
                );
                window.dispatchEvent(new Event('resize'));
              }
            },
            userToSwitch ? { switchUser: userToSwitch.username } : {},
            account.apiKey,
          );
        }
      },
      userToSwitch ? { switchUser: userToSwitch.username } : {},
      account.apiKey,
      account.dataCenterUrl,
    );
  }, []);

  const tableHead = pushCampaignTableHeaders.map((header, i) => (
    <th key={i}>{header}</th>
  ));

  return (
    <div className='account-details campaign-stats-table'>
      <table className='data-table'>
        <thead>
          <tr>{tableHead}</tr>
        </thead>
        <tbody>
          {Object.keys(campaigns)
            .filter(group => campaigns[group].length)
            .map(group => {
              const title = group;
              const campaignsWithReport = campaigns[group];

              return campaignsWithReport.map((campaignWithReport, index) => {
                const { campaign } = campaignWithReport;
                const report = transformTrends(
                  campaignWithReport.report,
                  0,
                  0,
                  0,
                  false,
                  'all',
                  true,
                );
                return (
                  <tr key={campaign.instanceId}>
                    {index === 0 && (
                      <td rowSpan={campaignsWithReport.length}>{title}</td>
                    )}
                    <td
                      className='domain'
                      onClick={() =>
                        editCampaign(campaign.type, campaign.instanceId)
                      }
                      title={campaign.name}
                    >
                      {campaign.name}
                    </td>
                    <td>{localeString(report.widget.stats.all.count)}</td>
                    <td>{localeString(report.widget.stats.view.count)}</td>
                    <td>{localeString(report.interaction.stats.all.count)}</td>
                    <td>
                      {calculateRatio(
                        report.widget.stats.view.count,
                        report.widget.stats.all.count,
                      )}
                      %
                    </td>
                    <td>{localeString(report.basket.stats.all.count)}</td>
                    <td>
                      {localeString(report.basket.stats.conversion.ratio)} %
                    </td>
                    <td>
                      <Currency
                        currency={localeStringMoney(
                          report.revenue.stats.all.count,
                        )}
                        accountCurrency={account.mainCurrency}
                      />
                    </td>
                    <td>
                      {localeString(report.revenue.stats.purchases.count)}
                    </td>
                    <td>{localeString(report.revenue.stats.items.count)}</td>
                    <td>
                      {localeString(report.revenue.stats.conversion.ratio)} %
                    </td>
                    <td className='cont-ratio'>
                      {localeString(report.revenue.stats.contribution.ratio)} %
                    </td>
                    <td className='diff-ratio'>
                      {report.revenue.stats.diff.ratio}
                    </td>
                  </tr>
                );
              });
            })}
        </tbody>
      </table>
    </div>
  );
};

const MapStateToProps = store => ({
  range: store.date.range,
});

export default connect(MapStateToProps)(PushCampaignStats);
