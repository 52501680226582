/**
 * Created by mehmetyurtar on 11/17/17.
 */

const defaultFields = [
  'dateRange',
  'date',
  'period',
  'productCategory',
  'productBrand',
  'productName',
  'minPrice',
  'maxPrice',
  'productStock',
  'device',
];

const insightFields = {
  'Rockstar Products': [
    'dateRange',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'productStock',
    'device',
  ],
  'High Spending': [
    'dateRange',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'productStock',
    'device',
  ],
  'The best of a bad bunch': [
    'dateRange',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'productStock',
    'device',
  ],
  'Discount Works': [
    'dateRange',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'productStock',
    'device',
  ],
  'We Wish You Had': [
    'dateRange',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'device',
  ],
  'Abandoned Items': [
    'dateRange',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'productStock',
    'device',
  ],
  'Organic Bazaar': [
    'dateRange',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'productStock',
    'device',
  ],
  'Social Trends': [
    'dateRange',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'productStock',
    'device',
  ],
  'You push hard': [
    'dateRange',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'device',
  ],
  'Products to Improve': [
    'dateRange',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'device',
  ],
  'Trending Products': [
    'date',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'productStock',
    'device',
  ],
  'Losing Power': [
    'date',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'productStock',
    'device',
  ],
  'Shining Stars': [
    'date',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'productStock',
    'device',
  ],
  'Under Shadow': [
    'date',
    'productCategory',
    'productBrand',
    'productName',
    'minPrice',
    'maxPrice',
    'productStock',
    'device',
  ],
  'Basket Complementaries': [
    'dateRange',
    'productCategory',
    'productName',
    'productBrand',
    'minPrice',
    'maxPrice',
    'productStock',
    'device',
  ],
  'Most Searched Keywords': ['period'],
};

const getInsightsFields = insightName => {
  let fields = [];
  if (insightName && insightFields[insightName]) {
    fields = defaultFields.filter(defaultField => {
      return insightFields[insightName].indexOf(defaultField) !== -1;
    });
  }
  return fields;
};

export default getInsightsFields;
