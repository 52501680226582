import React from "react";

class RadioEmpty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="2.-Edit-Screen"
            transform="translate(-357.000000, -493.000000)"
            fill="#E6E5E8"
            fillRule="nonzero"
          >
            <g id="See-Example">
              <g id="Modal" transform="translate(320.000000, 341.000000)">
                <g
                  id="User_manage"
                  transform="translate(37.000000, 118.000000)"
                >
                  <g id="subject" transform="translate(0.000000, 33.000000)">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="1"
                      width="16"
                      height="16"
                      rx="8"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default RadioEmpty;
