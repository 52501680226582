"use strict";

import React from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import Icons from "../icons";
import Icon from "../icon";
import SwitchButton from "../shared/switch-button";
import DiscoveryItems from "./discovery/jb-discovery-item";
import {TextField, TextArea} from "../fields";
import {t} from "../../system/ui";
import {jbActions} from "../../actions";
import Tooltip from "../tooltip";


class JourneyBuilderRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: this.props.properties.active,
            type: "",
            buttonText: this.props.values.criterion.buttonText ? this.props.values.criterion.buttonText : {},
            desc: this.props.values.criterion.desc ? this.props.values.criterion.desc : {},
            coupon: this.props.values.criterion.coupon ? this.props.values.criterion.coupon : "",
            pageUrl: this.props.values.criterion.pageUrl ? this.props.values.criterion.pageUrl : "",
            assetItems: [],
            isDetailsHidden: this.props.properties.isDetailsHidden,
            additionalTitles: this.props.values.criterion.additionalTitles ? this.props.values.criterion.additionalTitles : {},
            isCategoryTree: this.props.isNewCampaign ? this.props.isNewCampaign : this.props.isCategoryTree
        };
        this.handleChangeTitles = this.handleChangeTitles.bind(this);
        this.toggleButton = this.toggleButton.bind(this);
        this.handleAssetItems = this.handleAssetItems.bind(this);
        this.handleText = this.handleText.bind(this);
        this.getSecondTextValue = this.getSecondTextValue.bind(this);
        this.handleChangeButtonText = this.handleChangeButtonText.bind(this);
        this.handleChangeDesc = this.handleChangeDesc.bind(this);
        this.onFieldChange = this.onFieldChange.bind(this);

        this.myRef = React.createRef();
    }


    componentWillReceiveProps(nextProps) {
        if (
            nextProps.validateTriggered === true &&
            nextProps.validateTriggered !== this.props.validateTriggered
        ) {
            let errors = this.myRef.current.querySelectorAll(".has-error");
            if (errors.length > 0) {
                this.setState({
                    isDetailsHidden: false,
                }, () => this.sendRowChange());
            }
        }

        if (nextProps.isCategoryTree != this.props.isCategoryTree) {
            this.setState({isCategoryTree: nextProps.isCategoryTree});
        }
    }

    componentDidMount() {
        this.setState({type: this.props.properties.type}, () => this.setValues());
    }

    setValues() {
        if (this.props.properties.isData && this.props.properties.type !== 'MAIN_CATEGORY') {
            this.setState({
                assetItems: this.props.values.criterion && this.props.values.criterion.assetItems ?
                    this.props.values.criterion.assetItems : [],
            });
        }
    }

    toggleButton() {
        if (this.props.properties.type !== 'MAIN_CATEGORY' && this.props.properties.type !== 'CATEGORY') {
            this.setState(
                {
                    active: !this.state.active,
                },
                () => {
                    if (this.state.active) {
                        this.handleAssetItems(this.state.assetItems);
                    } else {
                        this.sendRowChange();
                    }
                }
            );
        }
    }

    toggleMenu() {
        this.setState({
            isDetailsHidden: !this.state.isDetailsHidden,
        }, () => this.sendRowChange());
    }

    sendRowChange() {
        this.props.onJBCriteriaChange(this.props.id, this.state, this.props.isDelivery);
    }

    handleChangeTitles(e) {
        this.setState({
            additionalTitles: {
                TR: e.target.value,
                EN: e.target.value,
            }
        }, () => {
            this.sendRowChange();
        });
    }

    handleChangeButtonText(e) {
        this.setState({
            buttonText: {
                TR: e.target.value,
                EN: e.target.value,
            }
        }, () => {
            this.sendRowChange();
        });
    }

    handleChangeDesc(e) {
        this.setState({
            desc: {
                TR: e.target.value,
                EN: e.target.value,
            }
        }, () => {
            this.sendRowChange();
        });
    }

    handleAssetItems(assetItems) {
        this.setState({assetItems}, () => this.sendRowChange());
    }

    handleText(e) {
        let target = e.target;
        this.setState({[target.name]: target.value}, () => this.sendRowChange());
    }

    getSecondTextValue(name) {
        let currentLang = this.props.account && this.props.account.mainLanguage === "TR" ? this.props.account.mainLanguage.toUpperCase() : "EN";
        return this.state[name][currentLang];
    }

    onFieldChange() {
        this.setState({isCategoryTree: !this.state.isCategoryTree}, () => jbActions.updateCategoryFlag(this.state.isCategoryTree));
    }

    render() {
        return (
            <li className="wizard-criterion" ref={this.myRef}>
                <div className="search__row">
                    <div className="search__left">
                        <div className="search__switch">
                            <SwitchButton
                                isSwitchedOn={this.state.active}
                                onToggle={this.toggleButton}
                            />
                        </div>
                        <div>
                          <span className="criteria-type jb" style={{marginTop: "5px"}}>
                            {this.props.properties.type === 'COUPON' ?
                                <Icon name={"tag"}/> :
                                <Icons name={this.props.properties.icons} color="#5a7390"/>}
                          </span>
                            <span className={"criteria-item-count"}>
                            {this.props.properties.text}
                          </span>
                        </div>
                        {this.props.properties.type === 'CATEGORY' &&
                        <div>
                            <span className="item-label clickable-jb" onClick={this.onFieldChange}>
                                <span className="item-label check-jb">
                                    <Icons name={this.state.isCategoryTree ? "checkboxSelected" : "checkboxEmpty"}
                                           width="12" height="12"
                                    />
                                </span>
                                {t("Category Tree View")}
                            </span>
                            <span className="item-label tip-jb">
                                  <Tooltip
                                      content={t(
                                          "If you want to show your categories as Category Tree in the Journey Builder, please click this option."
                                      )}
                                  >
                                    <Icon name="info"/>
                                  </Tooltip>
                            </span>
                        </div>
                        }
                    </div>

                    <div className="search__right">
                        <div className="search__col">
                            {this.state.active && <div
                                className={classNames({
                                    "sg-hidden": this.state.isDetailsHidden,
                                })}
                            >
                                <a
                                    className="button secondary-action"
                                    onClick={() => this.props.showAdvancedConfig(this.props.id, (this.props.isDelivery ? "jbDiscovery" : "jbDiscovery"))}
                                >
                                    {t("Advanced Configuration")}
                                </a>
                            </div>
                            }
                        </div>
                        <div className="search__col">
                            {this.state.active && (
                                <div
                                    className="search__expand"
                                    onClick={this.toggleMenu.bind(this)}
                                >
                                    <div
                                        className={classNames({
                                            "sg-hidden": !this.state.isDetailsHidden,
                                        })}
                                    >
                                        <Icons name="edit" color="#547496"/>
                                    </div>
                                    <div
                                        className={classNames({
                                            "sg-hidden": this.state.isDetailsHidden,
                                        })}
                                    >
                                        <Icons name="collapse" color="#000"/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {this.state.active && (
                    <div className={classNames({"sg-hidden": this.state.isDetailsHidden})}>
                        <div className="search__row search__row--margin">
                            {this.props.properties.type === 'COUPON' &&
                            <span className="item-stacked one-whole search__m_b_20">
                      <TextField
                          TextField
                          name="coupon"
                          label="Coupon"
                          className="item-stacked one-whole search__m_b_20"
                          value={this.state.coupon}
                          required={true}
                          onChange={this.handleText}
                          tooltip={false}
                      />
              </span>
                            }
                            <span className="item-stacked one-whole search__m_b_20">
                {this.props.properties.type === 'WELCOME'
                    ? <TextArea
                        name="title"
                        label="Title"
                        className="item-stacked one-whole"
                        value={this.getSecondTextValue("additionalTitles")}
                        required={true}
                        onChange={this.handleChangeTitles}
                    /> : this.props.properties.type === 'LANDING' ?
                            <TextField
                                name="pageUrl"
                                label="Page URL"
                                className="item-stacked one-whole"
                                value={this.state.pageUrl}
                                required={true}
                                onChange={this.handleText}
                                tooltip={false}
                                autocomplete={"off"}
                            /> :
                            <TextField
                                name="title"
                                label="Title"
                                className="item-stacked one-whole"
                                value={this.getSecondTextValue("additionalTitles")}
                                required={true}
                                onChange={this.handleChangeTitles}
                                tooltip={false}
                            />
                }
              </span>
                            {
                                (this.props.properties.type === 'WELCOME' || this.props.properties.type === 'COUPON') ?
                                    <span className="item-stacked one-whole search__m_b_20">
                         <TextField
                             name={this.props.properties.type === 'COUPON' ? "desc" : "buttonText"}
                             label={this.props.properties.type === 'COUPON' ? "Description" : "Button Text"}
                             className="item-stacked one-whole search__m_b_20"
                             value={this.getSecondTextValue(this.props.properties.type === 'COUPON' ? "desc" : "buttonText")}
                             required={true}
                             onChange={this.props.properties.type === 'COUPON' ? this.handleChangeDesc : this.handleChangeButtonText}
                             tooltip={false}
                         />
                        </span> :
                                    (this.props.properties.type !== 'PRICE_RANGE' && this.props.properties.type !== 'LANDING') &&
                                    <DiscoveryItems
                                        assetItems={this.state.assetItems}
                                        type={this.props.properties.type}
                                        text={this.props.properties.text}
                                        isAssetActive={this.state.active}
                                        handleAsset={this.handleAssetItems}
                                        account={this.props.account}
                                    />
                            }
                            {
                                this.props.properties.type === 'LANDING' &&
                                <TextField
                                    name={"buttonText"}
                                    label={"Button Text"}
                                    className="item-stacked one-whole search__m_b_20"
                                    value={this.getSecondTextValue("additionalTitles")}
                                    required={true}
                                    onChange={this.handleChangeTitles}
                                    tooltip={false}
                                />
                            }
                        </div>
                    </div>
                )}
            </li>
        );
    }
}

const mapStatesToProps = (store) => ({
    validateTriggered: store.ui.validateTriggered,
    isCategoryTree: store.jbWizard.isCategoryTree
});
export default connect(mapStatesToProps, null)(JourneyBuilderRow);
