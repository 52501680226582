/* eslint-disable react/no-unused-state */ // Disable this rule because it is not used in this file but used in other files by referencing this file.
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { uiActions } from '../../../actions';
import { TextArea } from '../../../components/fields';

class PushDescription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      name: 'pushDescription',
    };

    this.updateValue = this.updateValue.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
  }

  componentDidMount() {
    const text = this.props.campaign[this.props.options.name];
    this.updateValue(text);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign[this.props.options.name]);
    }
  }

  onFieldChange(e) {
    const tempDescriptions = this.props.additionalDescriptions;
    tempDescriptions[this.props.selectedLanguage] = e.target.value;
    this.setState({
      additionalDescriptions: tempDescriptions,
    });
    this.updateValue(e.target.value);
    uiActions.formEdited();
  }

  updateValue(newValue) {
    this.setState({
      value: newValue,
    });
  }

  render() {
    return (
      <li className='item-title-field one-whole'>
        <TextArea
          name={this.props.options.name}
          label={this.props.options.label}
          className='item-stacked one-whole'
          value={
            this.props.additionalDescriptions[this.props.selectedLanguage] || ''
          }
          required
          onChange={this.onFieldChange}
          tooltip={this.props.options.tooltip}
          tooltipText={this.props.options.tooltipContent}
          secondTooltip={this.props.options.secondTooltip}
          placeholder={this.props.options.placeholder}
          disabled={this.props.disableFields}
        />
      </li>
    );
  }
}

PushDescription.defaultProps = {
  options: {
    label: 'PushDescription',
    name: 'pushDescription',
    required: true,
    tooltip: false,
    tooltipContent: '',
  },
};

const MapStatesToProps = () => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  PushDescription,
);
