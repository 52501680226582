import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const spLastBoughtTime = ({ width, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 25 19'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      fill='none'
    >
      <path
        d='M17.0312 12.7292C16.5308 12.7292 16.125 13.1629 16.125 13.6979C16.125 14.2329 16.5308 14.6667 17.0312 14.6667H20.0521C20.5526 14.6667 20.9583 14.2329 20.9583 13.6979C20.9583 13.1629 20.5526 12.7292 20.0521 12.7292H17.0312ZM3.73958 0.458344C1.90439 0.458344 0.416664 2.04867 0.416664 4.01043V14.9896C0.416664 16.9514 1.90439 18.5417 3.73958 18.5417H21.2604C23.0956 18.5417 24.5833 16.9514 24.5833 14.9896V4.01043C24.5833 2.04867 23.0956 0.458344 21.2604 0.458344H3.73958ZM2.22916 14.9896V8.20834H22.7708V14.9896C22.7708 15.8814 22.0946 16.6042 21.2604 16.6042H3.73958C2.9054 16.6042 2.22916 15.8814 2.22916 14.9896ZM2.22916 6.27084V4.01043C2.22916 3.11871 2.9054 2.39584 3.73958 2.39584H21.2604C22.0946 2.39584 22.7708 3.11871 22.7708 4.01043V6.27084H2.22916Z'
        fill={color}
      />
    </svg>
  );
};

spLastBoughtTime.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

spLastBoughtTime.defaultProps = {
  width: '25px',
  height: '19px',
  color: '#5D5D61',
};

export default spLastBoughtTime;
