const somePreJSCodeExample = `// this function will get a configuration object to be executed at html rendering with mustache.js
// conf object has two pre-added objects:
//   - title: name of the recommendation widget
//   - products: products array to be used at recommendation widget
// do not change the function name
function preRenderConf(conf) {
  // add your custom code inside the function
}
`;

const somePostJSCodeExample = `// Please add your campaign post JS code below.
`;

const someCSSCodeExample = `/* Please add your campaign css code below */
`;

const someHTMLCodeExample = `<div class="seg-rec-container">
  <div class="seg-rec-title">[[title]]</div>
  <div class="seg-product-container">
    [[#products]]
    <div class="seg-rec-product seg-col-md-3">
      <a href="[[url]]" target="_self" title="[[name]]">
        <span class="seg-product-images">
          <img src="[[image]]" title="[[name]]"/>
        </span>
        <span class="seg-product-title">[[name]]</span>
        <span class="seg-price-box">
          <span class="seg-regular-price">
            [[#price]][[commaFormatPrice]][[/price]]
            <span class="seg-product-currency">
              {{account:currencySymbol}}
            </span>
          </span>
          [[#oldPrice]]
          <span class="seg-old-price">
            [[#oldPrice]][[commaFormatPrice]][[/oldPrice]]
            <span class="seg-product-currency">
              {{account:currencySymbol}}
            </span>
          </span>
          [[/oldPrice]]
        </span>
      </a>
    </div>
    [[/products]]
  </div>
</div>
`;

export const files = {
  html: {
    name: 'html',
    language: 'html',
    value: someHTMLCodeExample,
  },
  prejs: {
    name: 'prejs',
    language: 'javascript',
    value: somePreJSCodeExample,
  },
  postjs: {
    name: 'postjs',
    language: 'javascript',
    value: somePostJSCodeExample,
  },
  css: {
    name: 'css',
    language: 'css',
    value: someCSSCodeExample,
  },
};
