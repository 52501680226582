const mediums = {
  ALL: ['PC', 'PCTABLET', 'MOBILE', 'TABLET', 'ANDROID', 'IOS'],
  PC: ['PC', 'PCTABLET'],
  PCTABLET: ['PC', 'TABLET', 'PCTABLET'],
  PCANDROID: ['PC', 'ANDROID'],
  ANDROIDPC: ['PC', 'ANDROID'],
  PCIOS: ['PC', 'IOS'],
  IOSPC: ['PC', 'IOS'],
  PCIOSANDROID: ['PC', 'IOS', 'ANDROID'],
  PCANDROIDIOS: ['PC', 'IOS', 'ANDROID'],
  IOSPCANDROID: ['PC', 'IOS', 'ANDROID'],
  IOSANDROIDPC: ['PC', 'IOS', 'ANDROID'],
  ANDROIDIOSPC: ['PC', 'IOS', 'ANDROID'],
  ANDROIDPCIOS: ['PC', 'IOS', 'ANDROID'],
  TABLETPC: ['PC', 'TABLET', 'PCTABLET'],
  MOBILE: ['MOBILE'],
  MOBILETABLET: ['MOBILE'],
  PCMOBILETABLET: ['PC', 'MOBILE', 'TABLET', 'PCTABLET'],
  MOBILEPCTABLET: ['PC', 'MOBILE', 'TABLET', 'PCTABLET'],
  MOBILETABLETPC: ['PC', 'MOBILE', 'TABLET', 'PCTABLET'],
  PCTABLETMOBILE: ['PC', 'MOBILE', 'TABLET', 'PCTABLET'],
  TABLETPCMOBILE: ['PC', 'MOBILE', 'TABLET', 'PCTABLET'],
  TABLETMOBILEPC: ['PC', 'MOBILE', 'TABLET', 'PCTABLET'],
  TABLETMOBILEPCIOS: ['PC', 'MOBILE', 'TABLET', 'PCTABLET', 'IOS'],
  TABLETPCMOBILEIOS: ['PC', 'MOBILE', 'TABLET', 'PCTABLET', 'IOS'],
  TABLETPCIOSMOBILE: ['PC', 'MOBILE', 'TABLET', 'PCTABLET', 'IOS'],
  PCTABLETIOSMOBILE: ['PC', 'MOBILE', 'TABLET', 'PCTABLET', 'IOS'],
  PCIOSTABLETMOBILE: ['PC', 'MOBILE', 'TABLET', 'PCTABLET', 'IOS'],
  PCIOSMOBILETABLET: ['PC', 'MOBILE', 'TABLET', 'PCTABLET', 'IOS'],
  IOSPCMOBILETABLET: ['PC', 'MOBILE', 'TABLET', 'PCTABLET', 'IOS'],
  IOSMOBILEPCTABLET: ['PC', 'MOBILE', 'TABLET', 'PCTABLET', 'IOS'],
  IOSMOBILETABLETPC: ['PC', 'MOBILE', 'TABLET', 'PCTABLET', 'IOS'],
  MOBILEIOSTABLETPC: ['PC', 'MOBILE', 'TABLET', 'PCTABLET', 'IOS'],
  MOBILETABLETIOSPC: ['PC', 'MOBILE', 'TABLET', 'PCTABLET', 'IOS'],
  MOBILETABLETPCIOS: ['PC', 'MOBILE', 'TABLET', 'PCTABLET', 'IOS'],
  TABLET: ['TABLET'],
  IOS: ['IOS'],
  ANDROID: ['ANDROID'],
  ANDROIDIOS: ['ANDROID', 'IOS'],
  IOSANDROID: ['ANDROID', 'IOS'],
  PCMOBILETABLETIOSANDROID: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'ANDROID',
    'IOS',
  ],
  PCMOBILETABLETANDROIDIOS: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  MOBILETABLETANDROIDIOS: ['MOBILE', 'TABLET', 'IOS', 'ANDROID'],
  MOBILEPCTABLETANDROIDIOS: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  MOBILETABLETPCANDROIDIOS: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  MOBILETABLETANDROIDPCIOS: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  MOBILETABLETANDROIDIOSPC: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  TABLETMOBILEANDROIDIOSPC: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  TABLETANDROIDMOBILEIOSPC: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  TABLETANDROIDIOSMOBILEPC: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  TABLETANDROIDIOSPCMOBILE: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  ANDROIDTABLETIOSPCMOBILE: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  ANDROIDIOSTABLETPCMOBILE: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  ANDROIDIOSPCTABLETMOBILE: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  ANDROIDIOSPCMOBILETABLET: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  IOSANDROIDPCMOBILETABLET: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  IOSPCANDROIDMOBILETABLET: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  IOSPMOBILECANDROIDTABLET: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
  IOSPMOBILECTABLETANDROID: [
    'PC',
    'PCTABLET',
    'MOBILE',
    'TABLET',
    'IOS',
    'ANDROID',
  ],
};

const mediumList = {
  ALL: ['PCTABLET', 'MOBILE', 'ANDROID', 'IOS'],
  PCTABLET: 'PCTABLET',
  MOBILE: 'MOBILE',
  ANDROID: 'ANDROID',
  IOS: 'IOS',
};

const status = {
  ACTIVE: 'Live',
  TEST: 'Test',
  COMPLETED: 'Completed',
  PASSIVE: 'Passive',
};

const emailStatuses = {
  ACTIVE: 'Live',
  TEST: 'Draft',
  PASSIVE: 'Passive',
};

// Only for Bulk and View All for now
const pushStatuses = {
  ACTIVE: 'Live',
  TEST: 'Draft',
  COMPLETED: 'Completed',
  PASSIVE: 'Passive',
};

const searchStatuses = {
  ACTIVE: 'Live',
  TEST: 'Test/Draft',
  PASSIVE: 'Passive',
};

const statusEngagement = {
  ACTIVE: 'Live',
  TEST: 'Test',
  ARCHIVE: 'Archive',
};

const mediumFilters = {
  PCTABLET: 'Desktop',
  MOBILE: 'Mobile Web',
  ANDROID: 'Android',
  IOS: 'iOS',
};

const searchFilters = {
  PCTABLET: 'Desktop',
  MOBILE: 'Mobile Web',
};

const devices = {
  PC: 'PC',
  MOBILE: 'Mobile',
  TABLET: 'Tablet',
  IOS: 'iOS',
  ANDROID: 'Android',
};

const devicesWithoutNative = {
  PC: 'PC',
  MOBILE: 'Mobile',
  TABLET: 'Tablet',
};

const widgetTypes = {
  fullWidth: {
    name: 'Full Width',
    className: 'main',
  },
  rightSide: {
    name: 'Right/Left Side',
    className: 'sidebar',
  },
  custom: {
    name: 'Custom',
  },
  shopSimilar: {
    name: 'Shop Similar',
    className: 'main',
    alternativeName: 'Shop Similar',
    algorithms: {
      product: [
        {
          name: 'RECOMMENDATION_SMART_OFFERS',
          timeFrame: false,
        },
      ],
    },
  },
  stickyRecommendation: {
    name: 'Sticky Recommendation',
    className: 'main',
    alternativeName: 'Sticky Recommendation',
    algorithms: {
      custom: [
        {
          name: 'RECOMMENDATION_SMART_OFFERS',
          timeFrame: false,
        },
        {
          name: 'RECOMMENDATION_SOURCE_LAST_VISITED',
          timeFrame: false,
        },
        {
          name: 'RECOMMENDATION_NEW_COMERS',
          timeFrame: false,
        },
        {
          name: 'RECOMMENDATION_BROWSING_ALTERNATIVES',
          timeFrame: false,
        },
      ],
    },
  },
  recommended: {
    name: 'Recommended For You',
    className: 'recommended',
    algorithms: {
      home: [
        {
          title: 'Selected For You',
          name: 'RECOMMENDATION_SMART_OFFERS',
          timeFrame: false,
        },
      ],
      search: [
        {
          title: 'Selected For You',
          name: 'RECOMMENDATION_SMART_OFFERS',
          timeFrame: false,
        },
      ],
      notfound: [
        {
          title: 'Selected For You',
          name: 'RECOMMENDATION_SMART_OFFERS',
          timeFrame: false,
        },
      ],
    },
  },
  mostPopular: {
    name: 'Most Popular',
    className: 'mostPopular',
    algorithms: {
      home: [
        {
          title: 'Most Popular',
          name: 'RECOMMENDATION_SOURCE_ALL_PRODUCTS',
          timeFrame: 'THIS_WEEK',
        },
      ],
      category: [
        {
          title: 'Most Popular Products in this Category',
          name: 'RECOMMENDATION_CATEGORY_ROCKS',
          timeFrame: 'THIS_WEEK',
        },
      ],
      categoryHierarchy: [
        {
          title: 'Most Popular Products in this Category',
          name: 'RECOMMENDATION_CATEGORY_ROCKS',
          timeFrame: 'THIS_WEEK',
        },
      ],
      search: [
        {
          title: 'Most Popular',
          name: 'RECOMMENDATION_SOURCE_ALL_PRODUCTS',
          timeFrame: 'THIS_WEEK',
        },
      ],
      notfound: [
        {
          title: 'Most Popular',
          name: 'RECOMMENDATION_SOURCE_ALL_PRODUCTS',
          timeFrame: 'THIS_WEEK',
        },
      ],
      'checkout-success': [
        {
          title: 'Most Popular',
          name: 'RECOMMENDATION_SOURCE_ALL_PRODUCTS',
          timeFrame: 'THIS_WEEK',
        },
      ],
    },
  },
  youMayAlsoLike: {
    name: 'You May Also Like',
    className: 'youMayAlsoLike',
    algorithms: {
      product: [
        {
          title: 'You Might Also Like',
          name: 'RECOMMENDATION_SOURCE_RELATED_PRODUCT',
          timeFrame: false,
        },
      ],
    },
  },
  boughtTogether: {
    name: 'Bought Together',
    className: 'boughtTogether',
    algorithms: {
      'checkout-success': [
        {
          title: 'Frequently Bought Together',
          name: 'RECOMMENDATION_COLLABORATIVE_FILTERING',
          timeFrame: false,
        },
      ],
    },
  },
  alternativeProducts: {
    name: 'Complementary Products',
    className: 'alternativeProducts',
    algorithms: {
      product: [
        {
          title: 'Complementary Products',
          name: 'RECOMMENDATION_COLLABORATIVE_FILTERING',
          timeFrame: false,
        },
      ],
      basket: [
        {
          title: 'Complementary Products',
          name: 'RECOMMENDATION_COLLABORATIVE_FILTERING',
          timeFrame: false,
        },
      ],
    },
  },
  recentlyViewed: {
    name: 'Products You Recently Viewed',
    className: 'recentlyViewed',
    algorithms: {
      home: [
        {
          title: 'You Recently Viewed',
          name: 'RECOMMENDATION_SOURCE_LAST_VISITED',
          timeFrame: false,
        },
      ],
      product: [
        {
          title: 'You Recently Viewed',
          name: 'RECOMMENDATION_SOURCE_LAST_VISITED',
          timeFrame: false,
        },
      ],
      search: [
        {
          title: 'You Recently Viewed',
          name: 'RECOMMENDATION_SOURCE_LAST_VISITED',
          timeFrame: false,
        },
      ],
      notfound: [
        {
          title: 'You Recently Viewed',
          name: 'RECOMMENDATION_SOURCE_LAST_VISITED',
          timeFrame: false,
        },
      ],
      basket: [
        {
          title: 'Do you want to check once more?',
          name: 'RECOMMENDATION_SOURCE_LAST_VISITED',
          timeFrame: false,
        },
      ],
    },
  },
  topSellers: {
    name: 'Top Sellers',
    className: 'topSellers',
    algorithms: {
      home: [
        {
          title: 'Top Sellers',
          name: 'RECOMMENDATION_TOP_SELLERS',
          timeFrame: 'THIS_WEEK_PURCHASE',
        },
      ],
      category: [
        {
          title: 'Top Sellers in this Category',
          name: 'RECOMMENDATION_TOP_SELLERS',
          timeFrame: 'THIS_WEEK_PURCHASE',
        },
      ],
      categoryHierarchy: [
        {
          title: 'Top Sellers in this Category',
          name: 'RECOMMENDATION_TOP_SELLERS',
          timeFrame: 'THIS_WEEK_PURCHASE',
        },
      ],
      search: [
        {
          title: 'Top Sellers',
          name: 'RECOMMENDATION_TOP_SELLERS',
          timeFrame: 'THIS_WEEK_PURCHASE',
        },
      ],
      notfound: [
        {
          title: 'Top Sellers',
          name: 'RECOMMENDATION_TOP_SELLERS',
          timeFrame: 'THIS_WEEK_PURCHASE',
        },
      ],
    },
  },
  lastMinuteOffers: {
    name: 'Last Minute Offers',
    className: 'lastMinuteOffers',
    algorithms: {
      basket: [
        {
          title: 'Last Minute Offers',
          name: 'RECOMMENDATION_DISCOUNTED_PRODUCTS',
          timeFrame: 'THIS_WEEK_PURCHASE',
        },
      ],
    },
  },
  previouslyAddedToBasket: {
    name: 'Previously Added to Basket',
    className: 'previouslyAddedToBasket',
    algorithms: {
      basket: [
        {
          title: 'Previously Added to Basket',
          name: 'RECOMMENDATION_PREVIOUSLY_ADDED_TO_BASKET',
          timeFrame: false,
        },
      ],
    },
  },
  popup_recommendation: {
    name: 'Popup Recommendation',
    className: 'bg',
    algorithms: {
      popup_recommendation: [
        {
          title: 'Selected For You',
          name: 'RECOMMENDATION_SMART_OFFERS',
          timeFrame: false,
        },
      ],
    },
    alternativeName: 'Popup Recommendation',
  },
};

const searchTypes = {
  fullWidth: {
    name: 'Top Searched Keywords',
    className: 'topSearched',
  },
};

const promotionTypes = {
  fullWidth: {
    name: 'Top Banners',
    className: 'topBanners',
  },
};

const personalizationTypes = {
  popup: {
    name: 'Popup',
    className: 'main',
  },
};

const popupTypes = [
  { name: 'Recommendation', slug: 'recommendation' },
  { name: 'Banner', slug: 'banner' },
];

const pushTypes = [{ name: 'Recommendation', slug: 'push' }];

const socialProofTypes = {
  'added-to-basket': {
    id: 'added-to-basket',
    type: 'ADDED_TO_BASKET',
    name: 'Added to Basket',
    dynamicKeys: ['{{AddedToBasket}}'],
    description:
      'Hurry up! This product is in the cart of {{AddedToBasket}} people.',
    descriptionTR: 'Acele et! Bu ürün {{AddedToBasket}} kişinin sepetinde.',
    iconName: 'spAddedToBasket',
    timeRanges: [
      '1 hour',
      '2 hours',
      '3 hours',
      '6 hours',
      '12 hours',
      'a day',
      'week',
      'month',
    ],
    hasRealTimeTimeRange: true,
    multiplier: {
      isActive: true,
      description:
        'You can managed the campaign with changed the number of impressions of the real data : ',
      keyName: 'added to basket',
    },
    condition: {
      isActive: true,
      description:
        'You can specify the condition under which this campaign will run',
      conditionType: 'default',
      keys: [
        {
          keyId: 'default',
          keyName: 'if added to basket',
        },
      ],
    },
  },
  'stock-count': {
    id: 'stock-count',
    type: 'STOCK_COUNT',
    name: 'Stock Count',
    dynamicKeys: ['{{StockCount}}'],
    description:
      'Hurry up! Only {{StockCount}} items left in stock from this item.',
    descriptionTR:
      'Acele et! bu üründen stokta sadece {{StockCount}} ürün kaldı.',
    iconName: 'spStockCount',
    timeRanges: [],
    hasRealTimeTimeRange: false,
    multiplier: {
      isActive: false,
      description: '',
      keyName: '',
    },
    condition: {
      isActive: true,
      description:
        'You can specify the condition under which this campaign will run',
      conditionType: 'default',
      keys: [
        {
          keyId: 'default',
          keyName: 'Hide if the stock count',
        },
      ],
    },
  },
  'last-bought-time': {
    id: 'last-bought-time',
    type: 'LAST_BOUGHT_TIME',
    name: 'Last Bought Time',
    dynamicKeys: ['{{LastBoughtTime}}'],
    description:
      'This item was purchased {{LastBoughtTime}} ago by someone. You can buy too!',
    descriptionTR:
      'Bu ürün {{LastBoughtTime}} önce biri tarafından satın alındı. Sen de satın alabilirsin!',
    iconName: 'spLastBoughtTime',
    timeRanges: [],
    hasRealTimeTimeRange: false,
    multiplier: {
      isActive: false,
      description: '',
      keyName: '',
    },
    condition: {
      isActive: true,
      description:
        'You can specify the condition under which this campaign will run',
      conditionType: 'time',
      keys: [
        {
          keyId: 'default',
          keyName: 'if last bought',
        },
      ],
    },
  },
  'saving-over': {
    id: 'saving-over',
    type: 'SAVING_OVER',
    name: 'Saving Over',
    dynamicKeys: ['{{SavingOver}}', '{{SavingOverTime}}'],
    description:
      'You are a winner! {{SavingOver}}% discount was applied to this product {{SavingOverTime}} days ago.',
    descriptionTR:
      'Kazançlısın! Bu ürüne {{SavingOverTime}} gün önce %{{SavingOver}} indirim uygulandı.',
    iconName: 'spSavingOver',
    timeRanges: [],
    hasRealTimeTimeRange: false,
    multiplier: {
      isActive: false,
      description: '',
      keyName: '',
    },
    condition: {
      isActive: true,
      description:
        'You can specify the condition under which this campaign will run',
      conditionType: 'default',
      keys: [
        {
          keyId: 'default',
          keyName: 'Hide if the amount of saving',
        },
      ],
    },
    savingOverConditions: {
      isActive: true,
      description:
        'You can specify the condition under which this campaign will run',
      conditionType: 'time',
      keys: [
        {
          keyId: 'default',
          keyName: 'Hide if saving over time',
        },
      ],
    },
  },
  'score-and-review': {
    id: 'score-and-review',
    type: 'SCORE_AND_REVIEW',
    name: 'Score & Review',
    dynamicKeys: ['{{ScoreCounter}}', '{{ReviewCounter}}'],
    description:
      'Shining star! This product has score {{ScoreCounter}}, review {{ReviewCounter}}.',
    descriptionTR:
      'Parlayan yıldız! Bu üründe score {{ScoreCounter}}, review {{ReviewCounter}}.',
    iconName: 'spScoreAndReview',
    timeRanges: [],
    hasRealTimeTimeRange: false,
    multiplier: {
      isActive: false,
      description: '',
      keyName: '',
    },
    condition: {
      isActive: true,
      description:
        'You can specify the condition under which this campaign will run',
      conditionType: 'default',
      keys: [
        {
          keyId: 'score',
          keyName: 'if product score',
        },
        {
          keyId: 'review',
          keyName: 'if product review',
        },
      ],
    },
  },
  'purchase-counter': {
    id: 'purchase-counter',
    type: 'PRODUCT_PURCHASE_COUNTER',
    name: 'Purchase Counter',
    dynamicKeys: ['{{productPurchaseCounter}}'],
    description:
      'Bestselling! {{productPurchaseCounter}} people bought this product.',
    descriptionTR:
      'Çok satılıyor! Bu ürünü {{productPurchaseCounter}} kişi satın aldı.',
    iconName: 'spPurchaseCounter',
    timeRanges: [
      '1 hour',
      '2 hours',
      '3 hours',
      '6 hours',
      '12 hours',
      'a day',
      'week',
      'month',
    ],
    hasRealTimeTimeRange: false,
    multiplier: {
      isActive: true,
      description:
        'You can managed the campaign with changed the number of impressions of the real data : ',
      keyName: 'purchase counter',
    },
    condition: {
      isActive: true,
      description:
        'You can specify the condition under which this campaign will run',
      conditionType: 'default',
      keys: [
        {
          keyId: 'default',
          keyName: 'if purchase counter',
        },
      ],
    },
  },
  'view-counter': {
    id: 'view-counter',
    type: 'PRODUCT_VIEW_COUNTER',
    name: 'View Counter',
    dynamicKeys: ['{{productViewCounter}}'],
    description:
      'Eyes on you! {{productViewCounter}} people viewed this product.',
    descriptionTR:
      'Gözler üzerinde! {{productViewCounter}} kişi bu ürünü inceledi.',
    iconName: 'spViewCounter',
    timeRanges: [
      '1 hour',
      '2 hours',
      '3 hours',
      '6 hours',
      '12 hours',
      'a day',
      'week',
      'month',
    ],
    hasRealTimeTimeRange: true,
    multiplier: {
      isActive: true,
      description:
        'You can managed the campaign with changed the number of impressions of the real data : ',
      keyName: 'view count',
    },
    condition: {
      isActive: true,
      description:
        'You can specify the condition under which this campaign will run',
      conditionType: 'default',
      keys: [
        {
          keyId: 'default',
          keyName: 'if view count',
        },
      ],
    },
  },
  'last-visit': {
    id: 'last-visit',
    type: 'LAST_VISIT_TIME',
    name: 'Last Visit',
    dynamicKeys: ['{{LastVisitTime}}', '{{LastVisitProductPrice}}'],
    description:
      'Check it out again! You visited the product {{LastVisitTime}} ago when the price was {{LastVisitProductPrice}}.',
    descriptionTR:
      'Tekrar göz at! {{LastVisitTime}} önce ürünü fiyatı {{LastVisitProductPrice}} iken ziyaret ettin.',
    iconName: 'spLastVisit',
    timeRanges: [],
    hasRealTimeTimeRange: false,
    multiplier: {
      isActive: false,
      description: '',
      keyName: '',
    },
    condition: {
      isActive: true,
      description:
        'You can specify the condition under which this campaign will run',
      conditionType: 'time',
      keys: [
        {
          keyId: 'default',
          keyName: 'if last visited time',
        },
      ],
    },
  },
  'previously-added-to-basket': {
    id: 'previously-added-to-basket',
    type: 'PREVIOUSLY_ADDED_TO_BASKET',
    name: 'Previously Added to Basket',
    dynamicKeys: [
      '{{PreviouslyAddedToBasketTime}}',
      '{{PreviouslyAddedToBasketProductPrice}}',
    ],
    description:
      'Check it out again! You {{PreviouslyAddedToBasketTime}} first added this product to the cart when its price was {{PreviouslyAddedToBasketProductPrice}}',
    descriptionTR:
      'Tekrar göz at! Bu ürünü {{PreviouslyAddedToBasketTime}} önce fiyatı {{PreviouslyAddedToBasketProductPrice}} iken sepete ekledin.',
    iconName: 'spPreviouslyAddedToBasket',
    timeRanges: [],
    hasRealTimeTimeRange: false,
    multiplier: {
      isActive: false,
      description: '',
      keyName: '',
    },
    condition: {
      isActive: true,
      description:
        'You can specify the condition under which this campaign will run',
      conditionType: 'time',
      keys: [
        {
          keyId: 'default',
          keyName: 'Hide if amount of saving',
        },
      ],
    },
  },
  'favorite-activity': {
    id: 'favorite-activity',
    type: 'FAVORITE_ACTIVITY',
    name: 'Favorite Activity',
    dynamicKeys: ['{{FavoriteActivity}}'],
    description:
      'Most favourite! This product is a favorite of {{FavoriteActivity}} people!',
    descriptionTR:
      'En çok favorilenen! Bu ürün {{FavoriteActivity}} kişinin favorisinde!',
    iconName: 'spFavoriteActivity',
    timeRanges: ['a day', 'week', 'month'],
    hasRealTimeTimeRange: false,
    multiplier: {
      isActive: true,
      description:
        'You can managed the campaign with changed the number of impressions of the real data :',
      keyName: 'favorite activity',
    },
    condition: {
      isActive: true,
      description:
        'You can specify the condition under which this campaign will run',
      conditionType: 'default',
      keys: [
        {
          keyId: 'default',
          keyName: 'if favorite activity',
        },
      ],
    },
  },
};

const socialProofActions = [
  {
    icon: 'eye',
    handler: 'editAction',
    name: 'Edit',
    iconSvg: true,
  },
  {
    icon: 'duplicate',
    handler: 'duplicateAction',
    name: 'Duplicate',
    iconSvg: true,
  },
  {
    icon: 'archive',
    handler: 'archiveAction',
    name: 'Archive',
    iconSvg: true,
  },
  {
    icon: 'history',
    handler: 'historyAction',
    name: 'History',
    iconSvg: true,
  },
];

export {
  status,
  emailStatuses,
  searchStatuses,
  pushStatuses,
  statusEngagement,
  mediums,
  mediumList,
  mediumFilters,
  searchFilters,
  devices,
  devicesWithoutNative,
  pushTypes,
  popupTypes,
  widgetTypes,
  searchTypes,
  promotionTypes,
  personalizationTypes,
  socialProofTypes,
  socialProofActions,
};
