import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import { dateActions, filterActions, uiActions } from '../actions';
import { devices } from '../constants/datamaps/campaigns';
import Filters from '../components/filters/filters';
import Icon from '../components/icon';
import Icons from '../components/icons';
import ReportActions from '../components/reports/actions';
import { ABANDONED_LIST } from '../components/reports/utils';
import Tooltip from '../components/tooltip';
import EmailStats from '../components/widget/email-stats';
import { getCampaign, getRangedPushReport } from '../modules/push/ajax';
import { isBeforeNow, queryDate } from '../system/date';
import { setTitle } from '../system/document';
import reduceBasket from '../system/reducer';
import { t } from '../system/ui';

class MultiplePushReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: {},
      campaignReport: {},
      permissionReport: false,
      query: document.location.search,
      permissionElement: '',
      selectedDevice: 'ALL',
    };

    this.getPushReport = this.getPushReport.bind(this);
    this.getPushWithReport = this.getPushWithReport.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.getAllPermissions = this.getAllPermissions.bind(this);
  }

  componentDidMount() {
    const { reportID } = this.props.params;
    setTitle(t('Campaign Report'));
    this.getPushWithReport(reportID, this.props.range, true);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      this.getPushWithReport(newProps.params.reportID, newProps.range, true);
    }

    if (newProps.update === true) {
      this.getPushWithReport(newProps.params.reportID, newProps.range);
    }

    if (newProps.reset === true) {
      this.getPushWithReport(newProps.params.reportID, newProps.range);
    }
  }

  getAllPermissions(permissionElement) {
    this.setState({
      permissionElement,
    });
  }

  getPushReport(reportId, range) {
    const boundaries = {};
    boundaries.start = queryDate(range[0]);
    boundaries.end = queryDate(range[1]);
    getRangedPushReport(reportId, boundaries, response => {
      uiActions.isLoaded();
      const reminders = [
        response.ABANDONED_CART_FIRST_REMINDER,
        response.ABANDONED_CART_SECOND_REMINDER,
        response.ABANDONED_CART_ALTERNATIVE_PRODUCTS,
        response.ABANDONED_CART_COUPON,
        response.ABANDONED_CART,
      ];

      const oldReminders = [
        response.ABANDONED_CART,
        response.ABANDONED_CART_FIRST_REMINDER,
      ];

      response.ABANDONED_CART_FIRST_REMINDER = reduceBasket(oldReminders);
      const allCampaignStats = reduceBasket(reminders);
      this.setState({
        campaignReport: response,
        allCampaignStats,
      });
    });
  }

  getCampaignDataFromStart(reportId, startDate) {
    const boundaries = [];
    const unSubBoundaries = {};
    const today = new Date();
    boundaries.start = this.formatDate(startDate);
    boundaries.end = this.formatDate(today);
    unSubBoundaries.startDate = this.formatDate(startDate);
    unSubBoundaries.endDate = this.formatDate(today);
    getRangedPushReport(reportId, boundaries, campaignReport => {
      uiActions.isLoaded();
      this.setState({
        campaignReport,
      });
    });
  }

  getPushWithReport(reportId, range, campaignDate) {
    const reportIdArchive = reportId;
    uiActions.isLoading();
    filterActions.filtersUpdated();
    filterActions.filtersReset();
    if (this.state.campaign.name) {
      if (
        campaignDate &&
        this.state.campaign.schedule &&
        this.state.campaign.schedule.type === 'one_time' &&
        isBeforeNow(moment(this.state.campaign.schedule.date))
      ) {
        dateActions.updateRange([
          moment(this.state.campaign.schedule.date),
          moment(),
        ]);
        setTimeout(() => {
          this.getPushReport(reportId, [
            moment(this.state.campaign.schedule.date),
            moment(),
          ]);
        }, 200);
      } else {
        this.getPushReport(reportId, range);
      }
    } else {
      getCampaign(reportId).then(campaign => {
        if (campaign) {
          this.setState(
            {
              campaign,
            },
            () => {
              if (
                campaignDate &&
                campaign.schedule &&
                campaign.schedule.type === 'one_time' &&
                isBeforeNow(moment(campaign.schedule.date))
              ) {
                if (
                  this.state.campaign.startDate &&
                  this.state.query === '?archive'
                ) {
                  this.getCampaignDataFromStart(
                    reportIdArchive,
                    this.state.campaign.startDate,
                  );
                } else {
                  dateActions.updateRange([
                    moment(campaign.schedule.date),
                    moment(),
                  ]);
                  setTimeout(() => {
                    this.getPushReport(reportId, [
                      moment(campaign.schedule.date),
                      moment(),
                    ]);
                  }, 200);
                }
              } else if (
                this.state.campaign.startDate &&
                this.state.query === '?archive'
              ) {
                this.getCampaignDataFromStart(
                  reportIdArchive,
                  this.state.campaign.startDate,
                );
              } else {
                this.getPushReport(reportId, range);
              }
            },
          );
        }
      });
    }
  }

  formatDate(date) {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) {
      month = `0${month}`;
    }
    if (day.length < 2) {
      day = `0${day}`;
    }

    return [year, month, day].join('-');
  }

  selectDevice(selectedDevice) {
    this.setState({
      selectedDevice,
    });
  }

  render() {
    return (
      <div className='fullw-report'>
        <Filters isArchive={this.state.query === '?archive'} />
        <h2 className='page-title'>{t('Campaign Report')}</h2>

        <div className='widget page-content-block'>
          <div className='one-whole'>
            <h2 className='widget-title'>Summary</h2>
            <span>
              This box shows the statistics about Abandonment Cart Campaign. The
              values here indicates the total of all flow.
            </span>
            <ReportActions
              {...this.props}
              campaign={this.state.campaign}
              moduleName='push'
            />
            {this.state.campaign &&
              this.state.campaign.type &&
              this.state.campaign.type !== 'PUSH_PERMISSION' && (
                <div className='widget-meta'>
                  <span className='recommendation-device-selection'>
                    <Tooltip
                      content='All Devices'
                      alignment='top'
                      newTooltip
                      isInline
                    >
                      <span
                        key='all-devices'
                        onClick={this.selectDevice.bind(this, 'ALL')}
                        className={classNames(
                          'widget-meta-mobile all-device-report',
                          {
                            'is-selected': this.state.selectedDevice === 'ALL',
                          },
                        )}
                      >
                        <Icons
                          name='allDevices'
                          width='16'
                          height='16'
                          color='#bababa'
                        />
                        {t('ALL')}
                      </span>
                    </Tooltip>

                    {this.state.campaign.devices
                      .filter(
                        device =>
                          !(
                            device.toLowerCase() === 'tablet' ||
                            device.toLowerCase() === 'pctablet'
                          ),
                      )
                      .map(device => (
                        <Tooltip
                          content={t(devices[device])}
                          alignment='top'
                          newTooltip
                          isInline
                        >
                          <span
                            key={device}
                            onClick={this.selectDevice.bind(this, device)}
                            className={classNames('widget-meta-mobile', {
                              'is-selected':
                                this.state.selectedDevice === device,
                            })}
                          >
                            <Icon name={device.toLowerCase()} />
                          </span>
                        </Tooltip>
                      ))}
                  </span>
                </div>
              )}
          </div>

          <hr className='widget-separator' />

          <EmailStats
            report={this.state.allCampaignStats}
            isReport
            isPush
            pushType={this.state.campaign.type}
            isPushPermission={this.state.campaign.type === 'PUSH_PERMISSION'}
            permissionReport={this.state.permissionReport}
            getAllPermissions={this.getAllPermissions}
            isSingle
            selectedDevice={
              this.state.selectedDevice === 'PC'
                ? 'PC'
                : this.state.selectedDevice.toLowerCase()
            }
          />
          <hr className='widget-separator' />
          {this.state.permissionElement}
        </div>

        {ABANDONED_LIST.map(item => (
          <div className='widget page-content-block'>
            <div className='one-whole'>
              <h2 className='widget-title'>{item.title}</h2>
              <span>{item.desc}</span>
            </div>

            <hr className='widget-separator' />

            <EmailStats
              report={this.state.campaignReport[item.key]}
              isReport
              isPush
              pushType={item.key}
              isPushPermission={this.state.campaign.type === 'PUSH_PERMISSION'}
              permissionReport={this.state.permissionReport}
              getAllPermissions={this.getAllPermissions}
              isSingle
              selectedDevice={
                this.state.selectedDevice === 'PC'
                  ? 'PC'
                  : this.state.selectedDevice.toLowerCase()
              }
            />
            <hr className='widget-separator' />
            {this.state.permissionElement}
          </div>
        ))}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  ui: store.ui.ui,
  range: store.date.range,
  update: store.filters.update,
  reset: store.filters.reset,
});

export default connect(mapStatesToProps)(MultiplePushReport);
