import React from 'react';
import { browserHistory } from 'react-router';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { TextField } from '../fields';

import user from '../../modules/auth/user';
import { t } from '../../system/ui';
import { getQueryString } from '../../system/string';
import getReCaptchaPublicKey from '../../constants/reCaptcha.config';

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: true,
      password: '',
      passwordAgain: '',
    };

    this.changeShowHide = this.changeShowHide.bind(this);
    this.onChangeParams = this.onChangeParams.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { executeRecaptcha } = this.props.googleReCaptchaProps;

    if (!executeRecaptcha) {
      console.log('Recaptcha has not been loaded');
      return;
    }

    const reCaptchaToken = await executeRecaptcha('reset_password');

    const { password, passwordAgain } = this.state;
    const matched = this.state.password === this.state.passwordAgain;
    const passwordChecker = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!\@\#\.\_\-\(\)])(?=.*[a-zA-Z]).{8,}$/;
    const controlStatement =
      passwordChecker.test(this.state.password) &&
      passwordChecker.test(this.state.passwordAgain);

    const token = getQueryString('token');
    const version = getQueryString('v');

    const payload = {
      password,
      passwordAgain,
      recaptchaObj: {
        siteKey: getReCaptchaPublicKey(),
        token: reCaptchaToken,
        recaptchaAction: 'PASSWORD_RESET',
      },
    };
    if (matched && controlStatement)
      user.postResetPassword(payload, token, version, response => {
        if (response.status === 'OK') browserHistory.push('/login');
      });
  }

  onChangeParams(e) {
    const { type, checked, value, name } = e.target;
    const stateValue = type === 'checkbox' ? checked : value;

    this.setState({
      [name]: stateValue,
    });
  }

  changeShowHide() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  render() {
    const type = this.state.showPassword ? 'password' : 'text';
    const touched =
      this.state.password === '' || this.state.passwordAgain === '';
    const matched = this.state.password === this.state.passwordAgain;
    const passwordChecker = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!\@\#\.\_\-\(\)])(?=.*[a-zA-Z]).{8,}$/;
    const passwordCheck = passwordChecker.test(this.state.password);
    const passwordAgainCheck = passwordChecker.test(this.state.passwordAgain);

    return (
      <div className='lgn-container'>
        <div className='login-container'>
          <h1 className='has-text-align-center'>{t('Reset Password')}</h1>
          <p className='align-center'>
            {t('Enter your new password to change your current password.')}
          </p>
          <form id='login-form' method='POST' onSubmit={this.handleSubmit}>
            <div id='password-field' className='input-field password-field'>
              <TextField
                id='password'
                name='password'
                className='labelClass'
                type={type}
                isSearch
                inputClassName='inputClassName'
                label={t('Password')}
                value={this.state.password}
                onChange={this.onChangeParams}
                renderProp={() => (
                  <div className='password-toggle'>
                    {this.state.showPassword ? (
                      <span
                        className='pass-show pass-icon'
                        onClick={this.changeShowHide}
                      >
                        {t('Show')}
                      </span>
                    ) : (
                      <span
                        className='pass-hide pass-icon'
                        onClick={this.changeShowHide}
                      >
                        {t('Hide')}
                      </span>
                    )}
                  </div>
                )}
              />
            </div>
            {this.state.password !== '' && !passwordCheck && (
              <p
                style={{
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {t('This field should have at least 8 characters')},{' '}
                {t(
                  'Password must have at least one uppercase character (A-Z), one lowercase character (a-z) , one digit (0-9) and one special character: ! @ # . _ - ( )',
                )}
              </p>
            )}

            <div
              id='password-field-again'
              className='input-field password-field'
            >
              <TextField
                id='passwordAgain'
                name='passwordAgain'
                className='labelClass'
                type={type}
                isSearch
                inputClassName='inputClassName'
                label={t('Password Again')}
                value={this.state.passwordAgain}
                onChange={this.onChangeParams}
                renderProp={() => (
                  <div className='password-toggle'>
                    {this.state.showPassword ? (
                      <span
                        className='pass-show pass-icon'
                        onClick={this.changeShowHide}
                      >
                        {t('Show')}
                      </span>
                    ) : (
                      <span
                        className='pass-hide pass-icon'
                        onClick={this.changeShowHide}
                      >
                        {t('Hide')}
                      </span>
                    )}
                  </div>
                )}
              />
            </div>
            {this.state.passwordAgain !== '' && !passwordAgainCheck && (
              <p
                style={{
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {t('This field should have at least 8 characters')},{' '}
                {t(
                  'Password must have at least one uppercase character (A-Z), one lowercase character (a-z) , one digit (0-9) and one special character: ! @ # . _ - ( )',
                )}
              </p>
            )}

            {!touched && !matched && (
              <p
                style={{
                  color: 'red',
                  fontSize: '10px',
                }}
              >
                {t('Password fields must be match.')}
              </p>
            )}

            <div id='submit-field' className='input-field'>
              <button className='full-width' id='sign-up' type='submit'>
                {t('Send')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const WithGoogleRecaptchaResetPasswordForm = withGoogleReCaptcha(
  ResetPasswordForm,
);

export default WithGoogleRecaptchaResetPasswordForm;
