import React from 'react';
import classNames from 'classnames';
import Icons from '../../icons';

const ExperimentationDefinitionStep = ({
  children,
  property,
  opened,
  stepCount,
  collapsableController,
  touched,
  errors,
  name,
}) => {
  return (
    <div
      className={classNames(
        'step',
        { 'step-active': property in opened },
        { 'step-error': errors[property] && touched[property] },
        { last: stepCount === 4 },
      )}
    >
      <div>
        <div className='circle'>{stepCount}</div>
      </div>
      <div>
        <div className='title-container'>
          <div
            className='title'
            onClick={() => collapsableController(property)}
          >
            {name}
            <span
              className={classNames('title-arrow', {
                right: property in opened,
              })}
            >
              <Icons name='downarrownofill' width='20' height='20' />
            </span>
          </div>
          <span
            className={classNames('status-indicator', {
              touched: touched[property] && !errors[property],
            })}
          >
            <Icons name='tick' color='#06BE44' width='20' height='20' />
          </span>
        </div>
        <div
          className={classNames('collapsable', {
            open: property in opened,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ExperimentationDefinitionStep;
