/**
 * @fileoverview EmailTemplateImageCard component to display email templates
 */

import React from 'react';
import { EmailTemplateListItem } from './EmilTemplateListItem';
import classes from './EmailTemplateList.module.scss';

/**
 * @description Email template list wrapper component
 * @param {Array.<{id: Number, image: String, headline: string, templateJson:Array}>} customTemplates - list of custom templates
 * @param {boolean} isCollection - new item collection status
 * @param {boolean} isCollectionActive - whether collection is active
 * @param {function} setTemplatesList - setTemplatesList function
 * @param {string} campaignPathname - campaign pathname
 * @returns {React.Component} - EmailTemplateList component
 */

const EmailTemplateList = ({
  customTemplates,
  isCollection = false,
  isCollectionActive = true,
  setTemplatesList,
  campaignPathname,
}) => {
  return (
    <div className={classes['email-template-list-wrapper']}>
      <div className={classes['email-template-buttons']} />
      <div className={classes['email-template-list']}>
        <EmailTemplateListItem
          isCollection={isCollection}
          isCollectionActive={isCollectionActive}
          templates={customTemplates}
          setTemplatesList={setTemplatesList}
          campaignPathname={campaignPathname}
        />
      </div>
    </div>
  );
};

export default EmailTemplateList;
