import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tooltip from '../../../components/tooltip';
import { updateHideInformation } from '../../../actions/abandon';

class HideInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideInformation: false,
    };

    this.setHideInformation = this.setHideInformation.bind(this);
    this.handleHideInformation = this.handleHideInformation.bind(this);
  }

  componentDidMount() {
    this.setHideInformation(this.props.campaign);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      if (this.props.campaign.type === 'ABANDONED_CART') {
        this.updateValue(newProps.abandon.hideInformation);
      } else {
        this.updateValue(newProps.campaign.hideInformation);
      }
    }
  }

  handleHideInformation() {
    if (this.props.campaign.type === 'ABANDONED_CART') {
      this.props.updateHideInformation(
        'hideInformation',
        !this.props.abandon.hideInformation,
      );
      this.setState({
        hideInformation: !this.props.abandon.hideInformation,
      });
    } else {
      this.setState({
        hideInformation: !this.state.hideInformation,
      });
    }
  }

  setHideInformation(campaign) {
    if (campaign.type === 'ABANDONED_CART') {
      this.setState({
        hideInformation: this.props.abandon.hideInformation,
      });
    } else {
      this.setState({
        hideInformation: campaign.hideInformation,
      });
    }
  }

  updateValue(newValue) {
    this.setState({ hideInformation: newValue });
  }

  render() {
    return (
      <li className='item item-stacked is-checkbox'>
        <span className='label-mail label-text-field'>
          <label className='item item-stacked is-checkbox'>
            <input
              key='push-hide-price'
              type='checkbox'
              checked={this.state.hideInformation}
              onChange={this.handleHideInformation}
            />
            <span className='item-label'>
              {this.props.options.label}
              <Tooltip
                content={this.props.options.tooltipContent}
                alignment='right'
              >
                <i className='icon-info' role='presentation'>
                  <span className='for-screenreader-only' />
                </i>
              </Tooltip>
            </span>
          </label>
        </span>
      </li>
    );
  }
}

HideInformation.defaultProps = {
  options: {
    required: false,
    label: 'Hide Product Information on Push Message',
    tooltip: true,
    tooltipContent:
      "If you check this option, products' will not be shown in the push notifications.",
  },
};

HideInformation.propTypes = {
  options: PropTypes.shape({
    required: PropTypes.bool,
    label: PropTypes.string,
    toolTip: PropTypes.bool,
    tooltipContent: PropTypes.string,
  }),
};

const mapStatesToProps = store => ({
  abandon: store.abandon,
});

const mapDispatchToProps = dispatch => {
  return {
    updateHideInformation: (name, status) =>
      dispatch(updateHideInformation(name, status)),
  };
};

export default connect(mapStatesToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(HideInformation);
