/**
 * Created by mehmetyurtar on 11/21/17.
 */
import React from "react";
import { calculateRatio } from "../../system/ratio";
import Currency from "../../components/currency";
import { localeString, decodeHtml } from "../../system/string";
import { t } from "../../system/ui";
import Icons from "../../components/icons";

class CompareSegmentifty extends React.Component {
  render() {
    return (
      <div className="one-whole">
        <ul className="one-half trendify-compare-stats">
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Click")}</h4>
              <p>
                {t("Number Of Clicks By Segmentify Recommendation Widgets")}
              </p>
            </div>
            <div className="compare-stat-container one-half">
              {localeString(
                this.props.firstColumnData.stats[this.props.device + "SgmClick"]
              ) || "-"}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Basket")}</h4>
              <p>
                {t(
                  "Number Of Add To Basket Operations By Segmentify Recommendation Widgets"
                )}
              </p>
            </div>
            <div className="compare-stat-container one-half">
              {localeString(
                this.props.firstColumnData.stats[
                  this.props.device + "SgmBasket"
                ]
              ) || "-"}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Rate")}</h4>
              <p>{t("Conversion Rate (Add To Basket/Click)")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {calculateRatio(
                this.props.firstColumnData.stats[
                  this.props.device + "SgmBasket"
                ],
                this.props.firstColumnData.stats[this.props.device + "SgmClick"]
              )}
              %
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Purchase")}</h4>
              <p>
                {t("Number Of Purchases By Segmentify Recommendation Widgets")}
              </p>
            </div>
            <div className="compare-stat-container one-half">
              {localeString(
                this.props.firstColumnData.stats[
                  this.props.device + "SgmPurchase"
                ]
              ) || "-"}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Rate")}</h4>
              <p>{t("Conversion Rate (Purchase/Click)")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {calculateRatio(
                this.props.firstColumnData.stats[
                  this.props.device + "SgmPurchase"
                ],
                this.props.firstColumnData.stats[this.props.device + "SgmClick"]
              )}
              %
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Amount")}</h4>
              <p>
                {t("Amount Of Purchases By Segmentify Recommendation Widgets")}
              </p>
            </div>
            <div className="compare-stat-container one-half">
              <Currency
                currency={
                  this.props.firstColumnData.stats[
                    this.props.device + "SgmAmount"
                  ] || 0
                }
              />
            </div>
          </li>
        </ul>
        <ul className="one-half trendify-compare-stats">
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Click")}</h4>
              <p>
                {t("Number Of Clicks By Segmentify Recommendation Widgets")}
              </p>
            </div>
            <div className="compare-stat-container one-half">
              {localeString(
                this.props.secondColumnData.stats[
                  this.props.device + "SgmClick"
                ]
              ) || "-"}
              {this.props
                .getCompareIcon()
                .count(
                  (this.props.secondColumnData.stats[
                    this.props.device + "SgmClick"
                  ] || 0) -
                    (this.props.firstColumnData.stats[
                      this.props.device + "SgmClick"
                    ] || 0)
                )}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Basket")}</h4>
              <p>
                {t(
                  "Number Of Add To Basket Operations By Segmentify Recommendation Widgets"
                )}
              </p>
            </div>
            <div className="compare-stat-container one-half">
              {localeString(
                this.props.secondColumnData.stats[
                  this.props.device + "SgmBasket"
                ]
              ) || "-"}
              {this.props
                .getCompareIcon()
                .count(
                  (this.props.secondColumnData.stats[
                    this.props.device + "SgmBasket"
                  ] || 0) -
                    (this.props.firstColumnData.stats[
                      this.props.device + "SgmBasket"
                    ] || 0)
                )}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Rate")}</h4>
              <p>{t("Conversion Rate (Add To Basket/Click)")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {calculateRatio(
                this.props.secondColumnData.stats[
                  this.props.device + "SgmBasket"
                ],
                this.props.firstColumnData.stats[this.props.device + "SgmClick"]
              )}
              %
              {this.props
                .getCompareIcon()
                .ratio(
                  this.props.secondColumnData.stats[
                    this.props.device + "SgmBasket"
                  ],
                  this.props.secondColumnData.stats[
                    this.props.device + "SgmClick"
                  ],
                  this.props.firstColumnData.stats[
                    this.props.device + "SgmBasket"
                  ],
                  this.props.firstColumnData.stats[
                    this.props.device + "SgmClick"
                  ]
                )}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Purchase")}</h4>
              <p>
                {t("Number Of Purchases By Segmentify Recommendation Widgets")}
              </p>
            </div>
            <div className="compare-stat-container one-half">
              {localeString(
                this.props.secondColumnData.stats[
                  this.props.device + "SgmPurchase"
                ]
              ) || "-"}
              {this.props
                .getCompareIcon()
                .count(
                  (this.props.secondColumnData.stats[
                    this.props.device + "SgmPurchase"
                  ] || 0) -
                    (this.props.firstColumnData.stats[
                      this.props.device + "SgmPurchase"
                    ] || 0)
                )}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Rate")}</h4>
              <p>{t("Conversion Rate (Purchase/Click)")}</p>
            </div>
            <div className="compare-stat-container one-half">
              {calculateRatio(
                this.props.secondColumnData.stats[
                  this.props.device + "SgmPurchase"
                ],
                this.props.firstColumnData.stats[this.props.device + "SgmClick"]
              )}
              %
              {this.props
                .getCompareIcon()
                .ratio(
                  this.props.secondColumnData.stats[
                    this.props.device + "SgmPurchase"
                  ],
                  this.props.secondColumnData.stats[
                    this.props.device + "SgmClick"
                  ],
                  this.props.firstColumnData.stats[
                    this.props.device + "SgmPurchase"
                  ],
                  this.props.firstColumnData.stats[
                    this.props.device + "SgmClick"
                  ]
                )}
            </div>
          </li>
          <li className="trendify-compare-stat">
            <div className="compare-stat-container one-half">
              <h4>{t("Amount")}</h4>
              <p>
                {t("Amount Of Purchases By Segmentify Recommendation Widgets")}
              </p>
            </div>
            <div className="compare-stat-container one-half">
              <Currency
                currency={
                  this.props.secondColumnData.stats[
                    this.props.device + "SgmAmount"
                  ] || 0
                }
              />
              {this.props
                .getCompareIcon()
                .currency(
                  this.props.secondColumnData.stats[
                    this.props.device + "SgmAmount"
                  ] || 0,
                  this.props.firstColumnData.stats[
                    this.props.device + "SgmAmount"
                  ] || 0
                )}
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default CompareSegmentifty;
