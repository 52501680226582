import React from 'react';
import PropTypes from 'prop-types';

function LandingPage(props) {
  return (
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width={`${props.width}px`}
      height={`${props.height}px`}
      viewBox='0 0 500 500'
    >
      <g
        transform='translate(0.000000,452.000000) scale(0.110000,-0.110000)'
        fill={props.color}
        stroke='none'
      >
        <path
          d='M468 3966 c-84 -23 -158 -96 -184 -182 -22 -73 -21 -2977 1 -3051 19
-63 80 -134 143 -165 l47 -23 1073 -3 1072 -2 -60 81 c-33 44 -60 85 -60 90 0
5 -395 9 -979 9 -690 0 -987 3 -1010 11 -65 23 -61 -55 -61 1294 l0 1225 1810
0 1810 0 0 -640 0 -640 23 0 c12 0 52 -11 90 -24 l67 -24 0 907 c0 776 -2 915
-15 958 -19 63 -80 134 -143 165 l-47 23 -1765 2 c-1398 1 -1775 -1 -1812 -11z
m3541 -177 c52 -18 61 -49 61 -209 l0 -140 -1810 0 -1810 0 0 140 c0 155 8
186 55 206 40 18 3453 20 3504 3z'
        />
        <path d='M593 3694 c-83 -42 -54 -164 39 -164 78 0 117 86 66 144 -30 33 -65 40 -105 20z' />
        <path d='M875 3701 c-92 -39 -71 -171 27 -171 107 0 122 152 18 174 -14 3 -34 1 -45 -3z' />
        <path d='M1150 3703 c-92 -35 -73 -173 24 -173 78 0 119 92 65 146 -26 24 -65 36 -89 27z' />
        <path
          d='M3005 1795 c-190 -42 -331 -149 -411 -310 -131 -267 -52 -594 211
-873 102 -109 201 -187 420 -334 99 -67 203 -141 232 -165 28 -23 54 -43 58
-43 4 0 39 24 79 54 39 29 143 102 231 161 333 226 477 360 584 544 109 187
139 415 78 583 -34 94 -72 155 -141 224 -208 206 -537 228 -769 51 -26 -21
-49 -37 -52 -37 -3 0 -26 16 -52 37 -133 102 -312 143 -468 108z m261 -196
c68 -26 156 -106 214 -194 23 -36 44 -65 45 -65 1 0 22 29 45 64 136 209 349
278 550 176 76 -38 162 -132 190 -207 44 -118 33 -266 -29 -399 -90 -192 -232
-331 -564 -550 l-188 -125 -59 38 c-415 266 -551 382 -662 565 -137 224 -126
463 29 609 117 111 279 144 429 88z'
        />
      </g>
    </svg>
  );
}

LandingPage.defaultProps = {
  color: '#000000',
  width: 16,
  height: 16,
};

LandingPage.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default LandingPage;
