import React from 'react';
import { InputCardWrapper } from '../../../../components/gamification/module-exports';
import * as Components from '../../../../components/sfy-components/module-exports';
import { convertToPositiveInt } from '../../../../components/ruleBasedSegmentation/utils/utils';

const Frequency = ({
  component,
  editCampaign,
  activeEmailDetails,
  emailType,
}) => {
  const editParams = (fieldKey, fieldValue) => {
    let fixedValue = fieldValue;
    if (['frequency'].includes(fieldKey)) {
      fixedValue = `${convertToPositiveInt(fieldValue, 1)}`;
    }

    editCampaign('params', {
      ...activeEmailDetails.params,
      [fieldKey]: fixedValue,
    });
  };

  return (
    <InputCardWrapper
      type='adaptiveBlock'
      cardTitle='Replenishment Configuration'
      labelFor='frequency'
      labelClass='cardTitle'
    >
      <>
        <div style={{ display: 'flex', gap: '2rem' }}>
          {component.componentDetails.map(comp => {
            const Component = Components[comp.componentName];
            return (
              <Component
                key={comp.cardName}
                fieldValue={activeEmailDetails.params[comp.cardName] || 0}
                componentDetail={comp}
                editCampaign={editParams}
                activeEmailDetails={activeEmailDetails}
                emailType={emailType}
              />
            );
          })}
        </div>
      </>
    </InputCardWrapper>
  );
};

export default Frequency;
