import Services from '../../../../service/Services';

class ProductBundleServices extends Services {
  constructor({ endPoint, paginationIsActive }) {
    super({ endPoint, paginationIsActive });
  }
}

const ProductBundleSearchService = new ProductBundleServices({
  endPoint: 'bundle/search',
  paginationIsActive: true,
});

const ProductBundleService = new ProductBundleServices({
  endPoint: 'bundle/crud',
  paginationIsActive: false,
});

const ProductBundleImportService = new ProductBundleServices({
  endPoint: 'bundle/import',
  paginationIsActive: false,
});

export {
  ProductBundleService,
  ProductBundleSearchService,
  ProductBundleImportService,
};
