import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const spLastVisit = ({ width, height, color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 24'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      fill='none'
    >
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M14.758 5.75056C14.4012 5.47844 14.3326 4.96859 14.6047 4.61179C14.8768 4.25498 15.3867 4.18633 15.7434 4.45844C18.0796 6.24015 19.4791 9.00487 19.4791 12C19.4791 16.9728 15.6499 21.0513 10.7793 21.4475L11.5921 22.2588L11.6707 22.35C11.9067 22.668 11.8805 23.1194 11.5921 23.4078C11.2748 23.7251 10.7603 23.7251 10.443 23.4078L8.27634 21.2412L8.19768 21.1501C7.96163 20.832 7.98784 20.3806 8.27634 20.0922L8.63708 19.7314C9.07973 19.809 9.53528 19.8494 10.0001 19.8494C10.2923 19.8494 10.5808 19.8335 10.8647 19.8023L10.8593 19.8077C14.7928 19.3797 17.8541 16.0473 17.8541 12C17.8541 9.51723 16.6954 7.22808 14.758 5.75056ZM8.40774 1.74119C8.09044 1.4239 8.09044 0.909442 8.40774 0.592145C8.72505 0.274847 9.23953 0.274847 9.55683 0.592145L11.7235 2.75881C12.0408 3.07611 12.0408 3.59056 11.7235 3.90786L11.3624 4.26891C10.92 4.1915 10.4648 4.15112 10.0001 4.15112C9.7072 4.15112 9.41806 4.16718 9.13347 4.19844L9.13943 4.19243C5.20651 4.621 2.14575 7.95306 2.14575 12C2.14575 14.3749 3.20518 16.5748 5.00153 18.0587C5.34749 18.3444 5.39627 18.8566 5.1105 19.2025C4.82472 19.5486 4.31259 19.5973 3.96663 19.3115C1.80045 17.5222 0.520752 14.8649 0.520752 12C0.520752 7.02759 4.34935 2.9494 9.21948 2.5525L8.40774 1.74119ZM13.2827 10.9495C13.6001 10.6322 13.6001 10.1178 13.2827 9.80048C12.9654 9.48318 12.4511 9.48318 12.1338 9.80048L8.91658 13.0176L7.86608 11.9672C7.54881 11.6499 7.03436 11.6499 6.71706 11.9672C6.39976 12.2845 6.39976 12.7989 6.71706 13.1162L8.34209 14.7412C8.6594 15.0585 9.17377 15.0585 9.49108 14.7412L13.2827 10.9495ZM9.99992 18.5C13.5898 18.5 16.4999 15.5898 16.4999 12C16.4999 8.41015 13.5898 5.5 9.99992 5.5C6.41007 5.5 3.49992 8.41015 3.49992 12C3.49992 15.5898 6.41007 18.5 9.99992 18.5ZM9.99992 16.875C7.30753 16.875 5.12492 14.6924 5.12492 12C5.12492 9.30761 7.30753 7.125 9.99992 7.125C12.6923 7.125 14.8749 9.30761 14.8749 12C14.8749 14.6924 12.6923 16.875 9.99992 16.875Z'
        fill={color}
      />
    </svg>
  );
};

spLastVisit.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

spLastVisit.defaultProps = {
  width: '20px',
  height: '24px',
  color: '#5D5D61',
};

export default spLastVisit;
