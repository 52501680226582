import React from 'react';
import Icon from 'components/icon';
import { browserHistory } from 'react-router';
import '../styles/gui/bad_response_cat.scss';

const BadResponseCat = () => {
  // eslint-disable-next-line global-require
  const imgSrc = require('../images/error-cat 1.svg');
  return (
    <div className="bad-response-cat">
      <img src={imgSrc} alt="error-cat" />
      <h3>Meoow!</h3>
      <p>Everything is fine, nothing is ruined but please stand by!</p>
      <p>I will try to fix it!</p>
      <button onClick={() => browserHistory.push('/')}>
        <Icon name="dashboard" />
        Dashboard
      </button>
    </div>
  );
};

export default BadResponseCat;
