import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { uiActions, filterActions } from '../../actions';
import { resetToCustom } from '../../actions/date';
import Currency from '../currency';
import Export from '../export';
import Filters from '../filters/filters';
import {
  isInsightsCountDefined,
  insightsExportCount,
} from '../../modules/auth/user';
import Icon from '../icon';
import { getInsight } from '../../modules/insights/ajax';
import getInsightsFields from '../../modules/transform/insightsFieldsTransform';
import TrendifyFilters from '../filters/trendify-filters-insights';
import { productUrl } from '../../modules/trendify/page';
import { dateUpdatedQuery, getDatesSchema } from '../../modules/trendify/utils';
import { queryDate } from '../../system/date';
import { localeString } from '../../system/string';
import { t } from '../../system/ui';
import { clone } from '../../system/object';
import { insightsQuery } from '../../modules/insights/data';

class ProductsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.filterRef = null;
    this.state = {
      products: [],
      query: this.props.query,
      spliceIDs: [4, 6, 7, 8],
      ui: 'isLoading',
      range: this.props.range, // this is for reset func.
      rangeAlias: this.props.rangeAlias, // this is for reset func.
    };

    this.listProducts = this.listProducts.bind(this);
    this.resetUpdate = this.resetUpdate.bind(this);
    this.exportFile = this.exportFile.bind(this);
  }

  componentDidMount() {
    this.listProducts(this.props.query);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.query !== newProps.query) {
      this.listProducts(newProps.query);
    }

    if (newProps.update === true) {
      this.resetUpdate(newProps, 'update');
    }

    if (newProps.reset === true) {
      this.resetUpdate(newProps, 'reset');
    }
  }

  componentWillUnmount() {
    resetToCustom(this.state.range, this.state.rangeAlias);
  }

  getInsightData(endpoint, query) {
    this.setState({
      products: [],
      ui: 'isLoading',
    });

    getInsight(endpoint, query, response => {
      const insightProducts = response !== '' ? response : [];
      insightProducts.forEach(currentInsightItem => {
        const insightProduct = currentInsightItem;
        insightProduct.id = insightProduct.record.productId || '';
        insightProduct.productId = insightProduct.record.productId || '';
        insightProduct.inStock = insightProduct.record.inStock || false;
        insightProduct.productCategory = insightProduct.record.categories
          ? insightProduct.record.categories.join(',')
          : '';
        insightProduct.productBrand = insightProduct.record.brand || '';
        insightProduct.productName = insightProduct.record.name || '';
        insightProduct.productOldPrice = insightProduct.record.oldPrice || '';
        insightProduct.productPrice = insightProduct.record.price || '';
        insightProduct.productURL = insightProduct.record.url || '';
        insightProduct.productImage = insightProduct.record.image || '';
      });
      if (this.state.spliceIDs.includes(this.props.insightId)) {
        insightProducts.map(product => {
          this.insertAndShift(product.stats, 2, 0);
          this.insertAndShift(product.stats, 3, 1);
          if (this.props.insightId === 4 || this.props.insightId === 6)
            this.insertAndShift(product.stats, 4, 5);
        });
      }
      this.setState({ products: insightProducts }, () => {
        window.dispatchEvent(new Event('resize'));
      });
      this.setState({
        ui: 'isLoaded',
      });
    });
  }

  /*
   * This method is used to insert a value at a specific index in an array
   */
  insertAndShift(arr, from, to) {
    const cutOut = arr.splice(from, 1)[0]; // cut the element at index 'from'
    arr.splice(to, 0, cutOut); // insert it at index 'to'
  }

  listProducts(query) {
    const newQuery =
      query ||
      dateUpdatedQuery({
        range: this.props.range,
        rangeAlias: this.props.rangeAlias,
        analyticsQuery: insightsQuery,
      });
    if (isInsightsCountDefined()) {
      newQuery.items = isInsightsCountDefined();
    }
    this.getInsightData(this.props.endpoint, newQuery);
  }

  resetUpdate(newProps, action) {
    filterActions.filtersUpdated();
    const { range, rangeAlias } = newProps;
    const category = this.refs.pageFilters.querySelector(
      '[name=productCategory]',
    );
    const brand = this.refs.pageFilters.querySelector('[name=productBrand]');
    const product = this.refs.pageFilters.querySelector('[name=productName]');
    const minPrice = this.refs.pageFilters.querySelector('#minPrice');
    const maxPrice = this.refs.pageFilters.querySelector('#maxPrice');
    const device = this.refs.pageFilters.querySelector('[name=visitorDevice]');
    const stock = this.refs.pageFilters.querySelector('[name=productStock]');

    const query = dateUpdatedQuery({
      range,
      rangeAlias,
      analyticsQuery: insightsQuery,
    });

    if (action === 'update') {
      query.minPrice = minPrice ? minPrice.value || query.minPrice : '';
      query.maxPrice = maxPrice ? maxPrice.value || query.maxPrice : '';
      query.category = category ? category.value : '';
      query.brand = brand ? brand.value : '';
      query.productId = product ? product.value.toLowerCase() : '';
      query.device = device.value || 'all';
      query.stock = stock ? stock.value || 'all' : '';
    }
    if (isInsightsCountDefined()) {
      query.items = isInsightsCountDefined();
    }

    if (
      this.refs.pageFilters.querySelector(
        "#weekpicker-firstWeek input[name='date']",
      )
    ) {
      query.startDate = queryDate(range[0].startOf('week'));
    }

    if (
      this.refs.pageFilters.querySelector(
        "#weekpicker-secondWeek input[name='date']",
      )
    ) {
      query.endDate = queryDate(range[1].startOf('week'));
    }

    this.setState({ query });
    this.getInsightData(this.props.endpoint, query);
  }

  exportFile() {
    const { query } = this.state;
    query.export = true;
    query.export_mail = true;
    query.items = insightsExportCount() ?? query.items;
    const name = `${this.props.insightName.replace(/\s/g, '-')}-Report`;

    const content = () => (
      <Export
        name={name}
        query={query}
        insightsId={this.props.insightId}
        endpoint={this.props.endpoint}
        range={this.props.range}
      />
    );

    uiActions.openModal({
      title: t('Export Products'),
      className: 'insight-export-modal',
      content,
    });
  }

  render() {
    return (
      <div
        className='product-insights filter-inside'
        style={{ minWidth: '1100px' }}
        ref='pageFilters'
      >
        <Filters
          ref={filterRef => {
            this.filterRef = filterRef;
          }}
          limitRange='months'
          isInsights
          visibleUpdate
          hideDateAliases={false}
          hidePicker={false}
          includedFields={getInsightsFields(this.props.insightName)}
          weekSelect={
            getInsightsFields(this.props.insightName).indexOf('date') > -1
          }
          forceFirstSecondDate={
            this.props.isWeekSelect
              ? getDatesSchema(this.state.range, this.state.rangeAlias)
              : null
          }
        >
          <TrendifyFilters
            isInsights
            includedFields={getInsightsFields(this.props.insightName)}
            filterValues={this.state.query}
          />
        </Filters>
        {this.state.ui === 'isLoaded' && (
          <a
            className='secondary-action ghost export'
            onClick={this.exportFile}
            style={{ top: '30px', right: '42px', zIndex: '99999' }}
          >
            <i className='icon-pdf' /> {t('Export')}
          </a>
        )}
        {this.state.products.length > 0 ? (
          <div
            className={classNames(
              'product-insights-details export-content-wrapper',
              {
                'page-progress': this.state.ui === 'isLoading',
              },
            )}
          >
            <table className='data-table'>
              <thead>
                <tr>
                  <th className='left'>{t('Image')}</th>
                  <th className='left'>{t('Info')}</th>
                  <th className='center'>{t('Stock')}</th>
                  <th className='center'>{t('OldPrice')}</th>
                  <th className='center'>{t('Price')}</th>
                  {this.props.customFields &&
                    this.props.customFields.map(field => (
                      <th className='center'>{t(field.label)}</th>
                    ))}
                  <th className='center'>{t('Statistics')}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.products.map(product => (
                  <tr key={`product-${product.id}`}>
                    <td>
                      <a
                        href={productUrl(product.productURL)}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={productUrl(product.productImage)}
                          width='100'
                          alt={`Product ${product.id}.`}
                        />
                      </a>
                    </td>
                    <td>
                      <h3 className='product-name'>
                        <a
                          href={productUrl(product.productURL)}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {product.productName}
                        </a>
                      </h3>
                      <p className='product-category'>
                        {product.productCategory}
                      </p>
                      <p className='product-id'>{product.productId}</p>
                    </td>
                    <td
                      className={classNames('product-stock', 'center', {
                        'product-in-stock': product.inStock,
                      })}
                    >
                      {product.inStock ? (
                        <Icon name='check-circle' />
                      ) : (
                        <span className='out-of-stock' />
                      )}
                      {product.inStock ? t('In Stock') : t('Out of Stock')}
                    </td>
                    <td className='product-price center'>
                      {product.productOldPrice ? (
                        <span className='product-old-price'>
                          <Currency currency={product.productOldPrice} />
                        </span>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td className='product-price center'>
                      <span className='product-sale-price'>
                        <Currency currency={product.productPrice} />
                      </span>
                    </td>
                    {this.props.customFields &&
                      this.props.customFields.map(field => {
                        if (product.params) {
                          return (
                            <td className='custom-insights center'>
                              <span className='custom-insights-item'>
                                {field.dataType === 'currency' ? (
                                  <Currency
                                    currency={product.params[field.field]}
                                  />
                                ) : (
                                  localeString(product.params[field.field])
                                )}
                              </span>
                            </td>
                          );
                        }
                        return (
                          <td className='custom-insights center'>
                            <span className='custom-insights-item'>-</span>
                          </td>
                        );
                      })}
                    <td className='cell-group'>
                      <div className='product-stats'>
                        {product.stats.map(stat => (
                          <div
                            className='product-stat'
                            key={`${stat.name}-${product.productId}`}
                          >
                            <p className='product-stat-name'>{t(stat.name)}</p>
                            <p className='product-stat-value'>
                              {stat.type === 'currency' ? (
                                <Currency currency={stat.value} />
                              ) : (
                                localeString(stat.value)
                              )}
                              {stat.type === 'ratio' ? '%' : ''}
                            </p>
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div
            className={classNames('product-insights-details', {
              'page-progress': this.state.ui === 'isLoading',
              'product-insights-nodata': this.state.ui === 'isLoaded',
            })}
          >
            {this.state.ui !== 'isLoading' && (
              <div>
                <i className='icon-info' />
                {t('You do not have enough data for this insight.')}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  update: store.filters.update,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  reset: store.filters.reset,
});

export default connect(mapStatesToProps)(ProductsDetail);
