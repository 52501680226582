import React from "react";

class UserDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "24px",
      height: "24px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 462.406 462.406"
        width={this.state.width}
        height={this.state.height}
        fill={this.state.color}
      >
        <path
          d="M251.361,163.465c0,33.803-27.396,61.2-61.2,61.2c-33.803,0-61.2-27.397-61.2-61.2s27.397-61.2,61.2-61.2
		C223.958,102.265,251.361,129.669,251.361,163.465z M450.453,450.453c-7.963,7.969-18.408,11.953-28.846,11.953
		c-10.439,0-20.883-3.984-28.846-11.953c0,0-103.918-104.027-104.74-105.047c-29.078,18.557-63.587,29.355-100.641,29.355
		C83.892,374.762,0,290.869,0,187.381C0,83.892,83.898,0,187.381,0s187.381,83.892,187.381,187.38
		c0,37.053-10.799,71.563-29.355,100.641c1.02,0.814,105.047,104.74,105.047,104.74C466.391,408.688,466.391,434.52,450.453,450.453
		z M335.723,187.38c0-81.926-66.416-148.342-148.342-148.342S39.039,105.454,39.039,187.38c0,47.28,22.147,89.366,56.603,116.532
		c4.481-33.211,32.872-58.84,67.313-58.84h54.4c33.028,0,60.534,23.555,66.694,54.773
		C315.67,272.639,335.723,232.363,335.723,187.38z"
          fill={this.state.color}
        />
      </svg>
    );
  }
}

export default UserDetail;
