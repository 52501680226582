import React from 'react';
import PropTypes from 'prop-types';

export const fixedPxValue = value => {
  return `${value.toString().replace('px', '')}px`;
};

const NewBadge = ({ width, height, color }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.1101 21.627C16.8163 21.627 20.6315 17.8118 20.6315 13.1056C20.6315 8.39941 16.8163 4.58426 12.1101 4.58426C7.40389 4.58426 3.58875 8.39941 3.58875 13.1056C3.58875 17.8118 7.40389 21.627 12.1101 21.627ZM12.1101 22.0584C17.0546 22.0584 21.0629 18.0501 21.0629 13.1056C21.0629 8.16112 17.0546 4.1528 12.1101 4.1528C7.1656 4.1528 3.15729 8.16112 3.15729 13.1056C3.15729 18.0501 7.1656 22.0584 12.1101 22.0584Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.1101 15.5865C13.4803 15.5865 14.591 14.4758 14.591 13.1056C14.591 11.7355 13.4803 10.6247 12.1101 10.6247C10.74 10.6247 9.62922 11.7355 9.62922 13.1056C9.62922 14.4758 10.74 15.5865 12.1101 15.5865ZM12.1101 16.018C13.7186 16.018 15.0225 14.7141 15.0225 13.1056C15.0225 11.4972 13.7186 10.1933 12.1101 10.1933C10.5017 10.1933 9.19775 11.4972 9.19775 13.1056C9.19775 14.7141 10.5017 16.018 12.1101 16.018Z'
        fill={color}
      />
      <path
        d='M22.3231 12.9977C22.3231 18.6978 17.7023 23.3185 12.0023 23.3185C6.30224 23.3185 1.68146 18.6978 1.68146 12.9977C1.68146 7.29772 6.30224 2.67694 12.0023 2.67694C17.7023 2.67694 22.3231 7.29772 22.3231 12.9977ZM12.0023 24.25C18.2167 24.25 23.2545 19.2122 23.2545 12.9977C23.2545 6.78329 18.2167 1.74548 12.0023 1.74548C5.78781 1.74548 0.75 6.78329 0.75 12.9977C0.75 19.2122 5.78781 24.25 12.0023 24.25Z'
        fill='#656565'
        stroke='#656565'
        fill={color}
      />
      <path
        d='M12.1101 5.01573L8.65379 -4.9409e-06L15.5664 -4.33658e-06L12.1101 5.01573Z'
        fill='#656565'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.0243 11.2165L5.70966 7.33337L5.99829 7.01266L10.3129 10.8958L10.0243 11.2165Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.8943 10.3011V4.26065H12.3258V10.3011H11.8943Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M14.0152 10.788L18.3298 6.9048L18.6185 7.2255L14.3039 11.1087L14.0152 10.788Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M20.9551 13.2135H14.9146V12.782H20.9551V13.2135Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18.1059 19.5144L13.8991 15.3076L14.2042 15.0025L18.411 19.2093L18.1059 19.5144Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.8943 21.9506V16.018H12.3258V21.9506H11.8943Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.80725 19.2112L9.90613 15.0045L10.2152 15.3056L6.11628 19.5123L5.80725 19.2112Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3.26517 12.782H9.19776V13.2135H3.26517V12.782Z'
        fill={color}
      />
    </svg>
  );
};

NewBadge.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

NewBadge.defaultProps = {
  width: '24px',
  height: '25px',
  color: '#656565',
};

export default NewBadge;
