import React from 'react';
import { fixedPxValue } from '../customer-profile/utils';

const SortDown = ({ width = '9', height = '7', color = 'white' }) => {
  return (
    <svg
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.0507 0.449999L4.5 6.6L0.949295 0.45L8.0507 0.449999Z'
        fill={color}
        stroke='#656565'
        strokeWidth='0.4'
      />
    </svg>
  );
};

export default SortDown;
