import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  getAccount,
  isCategoryHierarchy,
  isSuperUser,
  getAdditionalLanguages,
  hasModule,
} from '../modules/auth/user';

import { t, confirm } from '../system/ui';
import { setTitle } from '../system/document';

import { uiActions } from '../actions';

import { goTestOrLive } from '../modules/campaigns/wizard';

import { config } from '../constants/config';

import getLanguageName from '../modules/transform/languageTransform';
import Fields from '../modules/campaign/fields';

import { TextField } from '../components/fields';
import WizardItems from '../components/wizard/items';
import WizardTarget from '../components/wizard/target';
import { widgetTypes } from '../constants/datamaps/campaigns';
import WizardPageType from '../components/wizard/page-type';
import WizardDateSelection from '../components/wizard/date-selection';
import WizardAdvancedConfig from '../components/wizard/advanced-config';
import WizardMediumSelection from '../components/wizard/medium-selection';
import { popupRecoTransform } from '../components/wizard/utils';
import { RecommendationTemplateService } from './recommendation/templates/services/recommendationTemplateServices';
import Dropdown from '../components/sfy-components/Dropdown/Dropdown';

const popupRecoFields = {
  buttons: [
    { field: 'positionButton', ignoreRef: true },
    { field: 'timingButton', ignoreRef: true },
    { field: 'audienceButton', ignoreRef: true },
    { field: 'overlay' },
  ],
  fields: [
    { field: 'positionOptions' },
    { field: 'timingOptions' },
    { field: 'audienceOptions' },
  ],
};

const recoFields = {
  fields: [{ field: 'audienceOptions' }],
};

class AddWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: {},
      title: '',
      additionalTitles: {
        AR: '',
        AZ: '',
        DE: '',
        EN: '',
        ES: '',
        FR: '',
        IT: '',
        NL: '',
        PL: '',
        PT: '',
        RO: '',
        TR: '',
      },
      showAdditionalLanguages: false,
      scenarioName: '',
      eventName: '',
      showAdvancedConfig: false,
      saved: true,
      selectedType: {
        page: this.props.routeParams.pagetype || '',
        placement: this.props.routeParams.placement || '',
      },
      isSubmitting: false,
      submittingButton: '',
      levelUp: 'false',
      AlgoString: '',
      devices: '',
      widgetTemplates: [],
      widgetTemplateId: null,
      useWidgetTemplate: false,
    };

    const { duplicate } = this.props;
    if (duplicate.isDuplicate) {
      this.state = {
        campaign: {},
        additionalTitles: duplicate.isDuplicate
          ? duplicate.duplicatedValues[6]
          : '',
        title: duplicate.isDuplicate ? duplicate.duplicatedValues[1] : '',
        scenarioName: duplicate.isDuplicate
          ? duplicate.duplicatedValues[0]
          : '',
        eventName: '',
        showAdvancedConfig: false,
        saved: true,
        selectedType: {
          page: this.props.routeParams.pagetype || '',
          placement: this.props.routeParams.placement || '',
        },
        levelUp: 'false',
        AlgoString: '',
        devices: '',
        widgetTemplates: [],
        widgetTemplateId: duplicate.duplicatedValues[7],
        useWidgetTemplate: !!duplicate.duplicatedValues[7],
      };
    }

    this.initialSaved = this.initialSaved.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onAdditionalTitleChange = this.onAdditionalTitleChange.bind(this);
    this.onScenarioNameChange = this.onScenarioNameChange.bind(this);
    this.onEventNameChange = this.onEventNameChange.bind(this);

    this.showAdvancedConfig = this.showAdvancedConfig.bind(this);
    this.hideAdvancedConfig = this.hideAdvancedConfig.bind(this);

    this.toggleLanguages = this.toggleLanguages.bind(this);

    this.onDropdownChange = this.onDropdownChange.bind(this);

    this.goTest = this.goTest.bind(this);
    this.editWidgetTemplate = this.editWidgetTemplate.bind(this);
    this.validate = this.validate.bind(this);

    this.getEngagementValues = this.getEngagementValues.bind(this);
    this.toggleLevelUp = this.toggleLevelUp.bind(this);
    this.updateAlgorithmString = this.updateAlgorithmString.bind(this);
    this.toggleUseTemplate = this.toggleUseTemplate.bind(this);
  }

  componentDidMount() {
    setTitle(
      t(
        `Add ${
          config.menus.primary.recommendations.children[
            this.state.selectedType.page
          ].name
        } Page Campaign`,
      ),
    );
    uiActions.isValidated();
    uiActions.resetPage();

    uiActions.confirmOnLeave(this);

    this.getAllTemplates();

    uiActions.showBreadcrumb({
      home: {
        name: t('Recommend'),
        icon: 'heart',
        slug: '/recommendations',
      },
      top: {
        name:
          this.state.selectedType.page === 'popup_recommendation'
            ? t('Popup Recommendation')
            : t(
                `${
                  config.menus.primary.recommendations.children[
                    this.state.selectedType.page
                  ].name
                } Page Widgets`,
              ),
        slug: `/recommendations/${this.state.selectedType.page}`,
      },
      current: {
        name:
          this.state.selectedType.page === 'popup_recommendation'
            ? 'Create New'
            : t(`${widgetTypes[this.state.selectedType.placement].name} Style`),
      },
    });
    if (this.props.duplicate && !this.props.duplicate.isDuplicate) {
      this.initialSaved();
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.state.saved !== newProps.formSaved) {
      this.setState({
        saved: newProps.formSaved,
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (this.props.validate === true && oldProps.validate === false) {
      this.validate('test');
    }
  }

  onDropdownChange(e) {
    this.setState({
      widgetTemplateId: e,
    });
  }

  onTitleChange(e) {
    const account = getAccount();
    const { mainLanguage } = account;
    const additionalTitles = { ...this.state.additionalTitles };
    additionalTitles[mainLanguage] = e.target.value;
    this.setState({
      title: e.currentTarget.value,
      additionalTitles,
      saved: false,
    });
    uiActions.formEdited();
  }

  onEventNameChange(e) {
    this.setState({
      eventName: e.currentTarget.value,
      saved: false,
    });
    uiActions.formEdited();
  }

  onScenarioNameChange(e) {
    this.setState({
      scenarioName: e.currentTarget.value,
      saved: false,
    });
    uiActions.formEdited();
  }

  onAdditionalTitleChange(e) {
    const additionalTitles = { ...this.state.additionalTitles };
    additionalTitles[e.target.name] = e.target.value;
    this.setState({
      additionalTitles,
    });
  }

  getAllTemplates() {
    RecommendationTemplateService()
      .getAll()
      .then(res => {
        const options = res.map(item => {
          return { label: item?.templateName, value: item?.id };
        });
        this.setState({
          widgetTemplates: options,
        });
      });
  }

  getEngagementValues() {
    if (this.refs.contentExtra) {
      if (this.state.selectedType.page === 'popup_recommendation') {
        const positionOptions = this.refs.contentExtra.refs.positionOptions
          .state;
        const timingOptions = this.refs.contentExtra.refs.timingOptions.state;
        const overlay = this.refs.contentButtons.refs.overlay.state;
        const audienceOptions = this.refs.contentExtra.refs.audienceOptions
          .state;
        const audience = [];
        audienceOptions.values.segment.forEach(option => {
          audience.push({
            type: 'SEGMENT',
            include: option.member,
            param: option.value,
          });
        });
        audienceOptions.values.visitor.forEach(option => {
          audience.push({
            type: 'VISITOR',
            include: option.member,
            param: option.value,
          });
        });
        return {
          overlay: overlay.value,
          verticalPosition: positionOptions.values.verticalPosition,
          horizontalPosition: positionOptions.values.horizontalPosition,
          delayAction: {
            type: popupRecoTransform(
              timingOptions.values.timing.type,
              timingOptions.delayAllPages,
            ),
            time: timingOptions.values.timing.param || '',
          },
          constraint: {
            type: popupRecoTransform(timingOptions.values.frequency.type),
            times: timingOptions.values.frequency.param || '',
          },
          filters: audience,
        };
      }
      const audienceOptions = this.refs.contentExtra.refs.audienceOptions.state;
      const audience = [];
      audienceOptions.values.segment.forEach(option => {
        audience.push({
          type: 'SEGMENT',
          include: option.member,
          param: option.value,
        });
      });
      audienceOptions.values.visitor.forEach(option => {
        audience.push({
          type: 'VISITOR',
          include: option.member,
          param: option.value,
        });
      });
      return { filters: audience };
    }
    return false;
  }

  toggleUseTemplate() {
    const isUseTemplate = this.state.useWidgetTemplate;
    this.setState({
      useWidgetTemplate: !isUseTemplate,
    });
    if (isUseTemplate) {
      this.setState({
        widgetTemplateId: null,
      });
    }
  }

  showAdvancedConfig() {
    this.setState({
      showAdvancedConfig: true,
    });
    uiActions.formEdited();
  }

  hideAdvancedConfig() {
    this.setState({
      showAdvancedConfig: false,
    });
  }

  initialSaved() {
    const { placement } = this.state.selectedType;
    let title = '';
    if (
      placement &&
      placement !== 'fullWidth' &&
      placement !== 'rightSide' &&
      placement !== 'custom' &&
      placement !== 'stickyRecommendation' &&
      placement !== 'shopSimilar'
    ) {
      title = t(
        widgetTypes[this.state.selectedType.placement].algorithms[
          this.state.selectedType.page
        ][0].title,
      );
    } else {
      title = this.state.title;
    }
    this.setState({
      saved: true,
      title,
    });
  }

  toggleLevelUp() {
    if (this.state.levelUp === 'true') {
      this.setState({
        levelUp: 'false',
      });
    } else if (this.state.levelUp === 'false') {
      this.setState({
        levelUp: 'true',
      });
    }
  }

  updateAlgorithmString(AlgoString) {
    this.setState({
      AlgoString,
    });
  }

  goTest() {
    this.setState({ isSubmitting: true, submittingButton: 'test' }, () => {
      goTestOrLive(
        'test',
        this,
        this.state.selectedType.page,
        false,
        false,
        this.getEngagementValues(),
      );
      uiActions.formSaved();
    });
  }

  editWidgetTemplate(id) {
    this.props.router.push(`/recommendations/templates/edit/${id}`);
  }

  toggleLanguages() {
    const { showAdditionalLanguages } = this.state;
    this.setState({
      showAdditionalLanguages: !showAdditionalLanguages,
    });
  }

  checkUseTemplate() {
    return !(
      this.state.useWidgetTemplate && this.state.widgetTemplateId === null
    );
  }

  validate(type) {
    const errors = document.querySelectorAll('.has-error');
    const checkUseTemplate = this.checkUseTemplate();

    if (checkUseTemplate) {
      if (this.props.validate === true && errors.length === 0) {
        if (type === 'test') {
          confirm({
            onConfirm: this.goTest,
            onCancel: () => {
              this.setState({
                isSubmitting: false,
              });
            },
          });
        }
      }
      if (type === 'edit') {
        this.editWidgetTemplate(this.state.widgetTemplateId);
      } else {
        uiActions.isValidating();
      }
    } else {
      uiActions.showNotification({
        // eslint-disable-next-line react/no-unescaped-entities
        content: () => <p>'Please select a template'</p>,
        className: 'notification-fail',
      });
    }
  }

  render() {
    const isHierarchical = isCategoryHierarchy();
    const selectedIntelligents =
      this.state.AlgoString && this.state.AlgoString.length > 0
        ? this.state.AlgoString.filter(item => item.type === 'intelligent')
        : [];
    const selectedAlgorithms =
      selectedIntelligents.length > 0
        ? selectedIntelligents.map(item => {
            return item.values.criterion.value;
          })
        : [];
    const selectedTimeFrames =
      selectedIntelligents.length > 0
        ? selectedIntelligents.map(item => {
            return item.values.timeFrame.value;
          })
        : [];
    const showLevelUp =
      isSuperUser() &&
      isHierarchical &&
      this.state.selectedType.page === 'category' &&
      selectedAlgorithms.indexOf('RECOMMENDATION_CATEGORY_ROCKS') >= 0;

    let advancedConfigWizardType;
    if (this.props?.params?.placement === 'stickyRecommendation') {
      advancedConfigWizardType = 'stickyRecommendation';
    } else if (this.props?.params?.placement === 'shopSimilar') {
      advancedConfigWizardType = 'shopSimilar';
    } else {
      advancedConfigWizardType =
        this.state.selectedType.page === 'popup_recommendation'
          ? 'popup_recommendation'
          : 'rec';
    }

    return (
      <form id='wizard' ref='wizard' className='wizard wizard-cards'>
        <h3 className='widget page-title'>{this.state.title}</h3>
        <div className='widget page-content-block'>
          <ol className='form-elements'>
            <div className='wizard-comp wizard-comp-campaigntitle'>
              <li className='item-title-field'>
                <TextField
                  name='notificationTitle'
                  label='Widget Title'
                  className='item-stacked one-whole'
                  value={this.state.title}
                  required
                  onChange={this.onTitleChange}
                  tooltipText={t('Title, as will appear on your website')}
                  tooltip
                  tooltipLink='#'
                />
                {this.state.showAdditionalLanguages &&
                  getAdditionalLanguages().map(lang => {
                    return (
                      <li className='item-title-field'>
                        <TextField
                          name={lang}
                          label={`${getLanguageName(lang)} ${t(
                            'Widget Title',
                          )}`}
                          className='item-stacked one-whole'
                          value={this.state.additionalTitles[lang]}
                          required={false}
                          onChange={this.onAdditionalTitleChange}
                        />
                      </li>
                    );
                  })}
                {getAdditionalLanguages().length > 0 && (
                  <div
                    className='other-languages'
                    onClick={this.toggleLanguages}
                  >
                    <i className='icon-plus' />
                    <span>{t('Other Languages')}</span>
                  </div>
                )}
                {this.state.selectedType.page === 'triggerbased' ? (
                  <TextField
                    name='eventName'
                    label={t('Event Name')}
                    className='item-stacked one-whole label-emphasise'
                    value={this.state.eventName}
                    required
                    onChange={this.onEventNameChange}
                  />
                ) : (
                  ''
                )}
              </li>
            </div>
            <li>
              <WizardItems
                ref='wizardItems'
                edit={false}
                duplicate={this.props.duplicate}
                selectedType={this.state.selectedType}
                selectedPage={this.props.router.params.pagetype}
                addPage
                isReco
                updateAlgorithmString={this.updateAlgorithmString}
              />
            </li>
            <li>
              {this.state.selectedType.page === 'popup_recommendation' ||
              (this.state.campaign &&
                this.state.campaign.actions &&
                this.state.campaign.actions[0] &&
                this.state.campaign.actions[0].params &&
                this.state.campaign.actions[0].params.actionType ===
                  'POPUP_RECO') ? (
                <li className='wizard-comp'>
                  <ol className='form-elements'>
                    {popupRecoFields.buttons.length > 0 && (
                      <li className='wizard-input-types wizard-perso-buttons'>
                        <Fields
                          ref='contentButtons'
                          campaign={this.state.campaign}
                          comps={popupRecoFields.buttons}
                          duplicate={this.props.duplicate}
                          campaignType='POPUP_RECOMMENDATION'
                        />
                      </li>
                    )}
                    <li className='wizard-perso-fields'>
                      <Fields
                        ref='contentExtra'
                        campaign={this.state.campaign}
                        comps={popupRecoFields.fields}
                        duplicate={this.props.duplicate}
                        campaignType='POPUP_RECOMMENDATION'
                      />
                    </li>
                  </ol>
                </li>
              ) : (
                (hasModule('segmentation') ||
                  hasModule('personalization') ||
                  hasModule('ruleBasedSegmentation')) && (
                  <li className='wizard-comp'>
                    <ol className='form-elements'>
                      <li className='wizard-perso-fields'>
                        <Fields
                          ref='contentExtra'
                          campaign={this.state.campaign}
                          comps={recoFields.fields}
                          duplicate={this.props.duplicate}
                          campaignType='RECOMMENDATION'
                        />
                      </li>
                    </ol>
                  </li>
                )
              )}
            </li>
          </ol>
        </div>

        <div className='wizard-options'>
          <ol className='form-elements'>
            <li className='campaign-subject'>
              <TextField
                name='scenarioName'
                label='Campaign Name'
                className='item-stacked one-whole label-emphasise'
                value={this.state.scenarioName}
                required
                onChange={this.onScenarioNameChange}
              />
            </li>
            <li>
              <WizardDateSelection ref='wizardDateSelection' />
            </li>
            {showLevelUp ? (
              <li>
                <label className='item item-stacked is-checkbox'>
                  <input
                    type='checkbox'
                    checked={this.state.levelUp === 'true'}
                    onChange={this.toggleLevelUp}
                  />
                  <span className='item-label'>{t('Level-up')}</span>
                </label>
              </li>
            ) : (
              ''
            )}
            <li>
              <WizardMediumSelection
                ref='wizardMediumSelection'
                duplicate={this.props.duplicate}
              />
            </li>
            {this.state.selectedType.page !== 'popup_recommendation' && (
              <li>
                <WizardTarget
                  ref='wizardTarget'
                  duplicate={this.props.duplicate}
                />
              </li>
            )}
            {['custom', 'popup_recommendation'].includes(
              this.state.selectedType.page,
            ) && (
              <li>
                <WizardPageType
                  ref='wizardPageType'
                  campaign={this.state.campaign}
                />
              </li>
            )}
            {['category', 'product', 'search'].includes(
              this.state.selectedType.page,
            ) && (
              <li>
                <WizardPageType
                  ref='wizardPageType'
                  campaign={this.state.campaign}
                  activeFields={['url']}
                />
              </li>
            )}
            <li>
              <label className='item item-stacked is-checkbox'>
                <input
                  type='checkbox'
                  checked={this.state.useWidgetTemplate}
                  onChange={this.toggleUseTemplate}
                />
                <span className='item-label'>{t('Use Template')}</span>
              </label>
            </li>
            {this.state.useWidgetTemplate && (
              <Dropdown
                value={this.state.widgetTemplateId}
                options={this.state.widgetTemplates}
                editCampaign={this.onDropdownChange}
                header='Template'
              />
            )}
            <li className='buttons'>
              {!this.state.useWidgetTemplate && (
                <a
                  className='button secondary-action one-whole'
                  onClick={this.showAdvancedConfig}
                >
                  {t('Advanced Configuration')}
                </a>
              )}
              {this.state.useWidgetTemplate && this.state.widgetTemplateId && (
                <a
                  className={classNames('button goedit-action one-whole', {
                    'gotest-action--disabled':
                      this.state.isSubmitting &&
                      this.state.submittingButton === 'edit',
                  })}
                  onClick={this.validate.bind(null, 'edit')}
                >
                  {t('Edit Template')}
                  {this.state.isSubmitting &&
                    this.state.submittingButton === 'test' && (
                      <span className='page-progress page-progress--button' />
                    )}
                </a>
              )}
              <a
                className={classNames('button gotest-action one-whole', {
                  'gotest-action--disabled':
                    this.state.isSubmitting &&
                    this.state.submittingButton === 'test',
                })}
                onClick={this.validate.bind(null, 'test')}
              >
                {t('Go Test')}
                {this.state.isSubmitting &&
                  this.state.submittingButton === 'test' && (
                    <span className='page-progress page-progress--button' />
                  )}
              </a>
              <a className='button disabled one-whole'>{t('Go Live')}</a>
            </li>
          </ol>
        </div>

        <WizardAdvancedConfig
          ref='wizardAdvancedConfig'
          show={this.state.showAdvancedConfig}
          onHide={this.hideAdvancedConfig}
          duplicate={this.props.duplicate}
          campaignType={advancedConfigWizardType}
          campaignSpecifics={this}
          device={this.state.devices}
        />
      </form>
    );
  }
}

const mapStatesToProps = store => ({
  validate: store.ui.validate,
  breadcrumb: store.ui.breadcrumb,
  formSaved: store.ui.formSaved,
  user: store.user.user,
  criteria: store.wizard.criteria,
  excludes: store.wizard.excludes,
  includes: store.wizard.includes,
  duplicate: store.campaigns.duplicate,
  ui: store.ui.ui,
});

AddWidget.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStatesToProps)(AddWidget);
