import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const LogoutIcon = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 20 25'
      fill='none'
    >
      <path d='M0 2.94545C0 1.31873 1.22105 0 2.72727 0H13.6364C15.1426 0 16.3636 1.31873 16.3636 2.94545V11.219C15.9277 11.0526 15.4715 10.9327 15 10.8649V2.94545C15 2.13209 14.3895 1.47273 13.6364 1.47273H2.72727C1.97415 1.47273 1.36364 2.13209 1.36364 2.94545V20.6182C1.36364 21.4315 1.97415 22.0909 2.72727 22.0909H8.32236C8.63991 22.6339 9.02136 23.1289 9.45536 23.5636H2.72727C1.22105 23.5636 0 22.245 0 20.6182V2.94545ZM4.09091 12.7636C4.844 12.7636 5.45455 12.1042 5.45455 11.2909C5.45455 10.4776 4.844 9.81818 4.09091 9.81818C3.33779 9.81818 2.72727 10.4776 2.72727 11.2909C2.72727 12.1042 3.33779 12.7636 4.09091 12.7636ZM20 18.1636C20 21.6883 17.3545 24.5455 14.0909 24.5455C10.8274 24.5455 8.18182 21.6883 8.18182 18.1636C8.18182 14.639 10.8274 11.7818 14.0909 11.7818C17.3545 11.7818 20 14.639 20 18.1636ZM10.4545 17.6727C10.2035 17.6727 10 17.8926 10 18.1636C10 18.4347 10.2035 18.6545 10.4545 18.6545H16.6299L14.6785 20.7619C14.5011 20.9537 14.5011 21.2645 14.6785 21.4563C14.8561 21.6479 15.1439 21.6479 15.3215 21.4563L18.0487 18.5108C18.0923 18.4637 18.1252 18.4095 18.1474 18.3516C18.1692 18.2945 18.1815 18.2321 18.1818 18.1666V18.1636V18.1607C18.1811 18.036 18.1367 17.9116 18.0487 17.8165L15.3215 14.871C15.1439 14.6794 14.8561 14.6794 14.6785 14.871C14.5011 15.0628 14.5011 15.3736 14.6785 15.5654L16.6299 17.6727H10.4545Z' />
    </svg>
  );
};

LogoutIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

LogoutIcon.defaultProps = {
  width: '20px',
  height: '25px',
};

export default LogoutIcon;
