export const monitoringTypeFixer = (
  monitoringTypeMaps,
  serverMonitoringTypes,
) => {
  const result = [];
  const ifHasType = type => {
    let res = false;
    let ind = -1;
    serverMonitoringTypes.forEach((elem, index) => {
      if (elem.monitoringType === type) {
        res = true;
        ind = index;
      }
    });
    return { res, ind };
  };
  Object.keys(monitoringTypeMaps).forEach(item => {
    const { res, ind } = ifHasType(item);
    if (res) {
      result.push(serverMonitoringTypes[ind]);
    } else {
      result.push({ monitoringType: item, status: 'PASSIVE' });
    }
  });
  return result;
};

export const filterMapping = {
  tier: 'tier',
  accountType: 'type',
  industry: 'industry',
  owner: 'owners',
};
