import React from "react";

class IconDownload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
      >
        <path
          d="M8,12c0.256,0,0.512-0.098,0.707-0.293L14.414,6L13,4.586l-4,4V0H7v8.586l-4-4L1.586,6l5.707,5.707
	C7.488,11.902,7.744,12,8,12z"
        />
        <path d="M14,14H2v-3H0v4c0,0.552,0.447,1,1,1h14c0.553,0,1-0.448,1-1v-4h-2V14z" />
      </svg>
    );
  }
}

export default IconDownload;
