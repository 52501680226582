import { t } from '../../../../system/ui';

const breadCrumbOptions = {
  home: {
    name: t('Customer Data Management'),
    icon: 'audienceIcon',
    slug: '/audience/segmentation/view-all',
    iconSvg: true,
  },
  current: {
    name: 'Customer Profiles',
  },
};

const getDynamicBreadCrumbOptions = currentName => {
  return {
    home: {
      name: t('Customer Data Management'),
      icon: 'audienceIcon',
      slug: '/audience/segmentation/view-all',
      iconSvg: true,
    },
    top: {
      name: t('Customer Profiles'),
      slug: '/audience/customer-profile',
    },
    current: {
      name: currentName,
    },
  };
};

const enumSegmentType = {
  smartSegments: 'Smart Segmentation',
  dynamicSegments: 'Dynamic Segments',
  ruleBasedSegments: 'Rule Based Segments',
};

const notesSegmentationStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '1.313rem',
  flexGrow: 1,
  justifyContent: 'space-between',
};

const BrandsCategoryVisitStyle = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: '1rem',
};

const enumUserInformationType = {
  firstVisit: {
    name: t('First Visit'),
    icon: 'firstVisitIcon',
  },
  lastVisit: {
    name: t('Last Visit'),
    icon: 'lastVisitIcon',
  },
  firstOrder: {
    name: t('First Order'),
    icon: 'firstOrderIcon',
  },
  lastOrder: {
    name: t('Last Order'),
    icon: 'lastOrderIcon',
  },
  registration: {
    name: t('Registration'),
    icon: 'registrationIcon',
  },
};

const enumUserActivityType = {
  lastProductHistory: {
    lastPurchasedProducts: t('Last Purchased Product'),
    lastVisitedProduct: t('Last Visited Product'),
    latestAddedToBasket: t('Latest Added to Basket'),
  },
  wishlistActivity: {
    latestWishlistProducts: t('Latest wishlist Product'),
    wishlistVisitedProducts: t('Wishlist Visited Product'),
  },
  returnsProducts: {
    returnsRecord: t('Latest Return'),
  },
};

const enumLastProductRecommendations = {
  latestSeenRecommendationCampaign: t('Last Seen Recommendations'),
  latestClickedRecommendationCampaign: t('Last Clicked Recommendations'),
};

const enumCategoryVisit = {
  topVisitedCategory: t('Top Viewed'),
  topPurchasedCategory: t('Top Purchased'),
};

const enumPush = {
  pushDelivered: t('Delivered'),
  pushClickRate: t('Click Rate (CTR)'),
  pushConversionRate: t('Conversion Rate'),
  pushLastClick: t('Push Last Click'),
};

const enumEmail = {
  emailDelivered: t('Delivered'),
  emailClickRate: t('Click Rate (CTR)'),
  emailConversionRate: t('Conversion Rate'),
  emailLastClick: t('Email Last Click'),
};

const customerProfileConstants = {
  user: {
    userName: '',
    userId: '',
    device: [],
    browser: [],
    email: '',
    phoneNumber: '',
    gender: '',
    birthDate: '',
    notifications: {
      email: '',
      push: '',
      sms: '',
    },
  },
  notes: '',
  segments: {
    smartSegments: [],
    dynamicSegments: [],
    ruleBasedSegments: [],
  },
  stats: {
    firstVisit: 1663673158231,
    lastVisit: 1663673158231,
    firstOrder: 1663673158231,
    lastOrder: 1663673158231,
    registration: 1663673158231,
    totalVisit: 12,
  },
  lastProductHistory: {
    lastPurchasedProducts: [],
    lastVisitedProduct: {},
    latestAddedToBasket: {},
  },
  wishlistActivity: {
    latestWishlistProducts: [],
    wishlistVisitedProducts: [],
  },
  returnsProducts: {
    returnsRecord: [],
  },
  topBrands: [],
  lastProductRecommendations: {
    latestSeenRecommendationCampaign: [],
    latestClickedRecommendationCampaign: [],
  },
  categoryVisit: {
    topVisitedCategory: '',
    topPurchasedCategory: '',
  },
  push: {
    pushDelivered: 0,
    pushClickRate: 0,
    pushConversionRate: 0,
    pushLastClick: {},
  },
};

export {
  breadCrumbOptions,
  getDynamicBreadCrumbOptions,
  enumSegmentType,
  notesSegmentationStyle,
  enumUserInformationType,
  enumUserActivityType,
  enumLastProductRecommendations,
  enumCategoryVisit,
  enumPush,
  enumEmail,
  customerProfileConstants,
  BrandsCategoryVisitStyle,
};
