import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { getSource } from '../..';
import {
  brandsActions,
  categoriesActions,
  labelsActions,
  modalActions,
  switchUserActions,
  userActions,
} from '../../actions';
import { resetSwitchRegion } from '../../actions/switchRegion';
import { redirect } from '../../actions/ui';
import { appEnv } from '../../constants/config';
import {
  isMSPUser,
  isMultiAccountUser,
  isSuperUser,
  isMasterUser,
  isTechResponsible,
  logout,
} from '../../modules/auth/user';
import ContactUsModal from '../../modules/contact-us.modal';
import { t } from '../../system/ui';
import Notifications from '../admin/notifications';
import Icon from '../icon';
import Icons from '../icons';
import Navlink from '../navlink';
import RegionSwitcher from './region-switcher';
import packageJson from '../../../package.json';

class UserNav extends React.Component {
  constructor(props) {
    super(props);

    this.handleLogout = this.handleLogout.bind(this);
    this.changeName = this.changeName.bind(this);
    this.state = {
      isSwitchAccount: false,
      switchedUser: {},
    };
  }

  handleLogout(e) {
    e.preventDefault();

    const env = appEnv();

    logout();
    userActions.logoutUser();
    window.location = '/login';
  }

  cancelAccountSwitch(e) {
    e.preventDefault();

    localStorage.removeItem('account');
    localStorage.removeItem('integrations');
    switchUserActions.updateSwitchUser({});
    categoriesActions.removeCategories();
    brandsActions.removeBrands();
    labelsActions.removeLabels();
    resetSwitchRegion();
    if (isMSPUser()) {
      redirect('/managed-services');
    } else if (isMultiAccountUser()) {
      redirect('user/accounts');
    } else {
      redirect('admin/accounts');
    }
  }

  componentDidMount() {
    const { switchedUser } = this.props;
    if (switchedUser.account.accountId) {
      this.setState({
        isSwitchAccount: true,
        switchedUser,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    const { switchedUser } = newProps;
    if (switchedUser.username) {
      this.setState({
        isSwitchAccount: true,
        switchedUser,
      });
    } else {
      this.setState({
        isSwitchAccount: false,
        switchedUser: {},
      });
    }
  }

  openContactUsModal(e) {
    e.preventDefault();
    modalActions.openModal({
      title: t('Contact Us'),
      content: ContactUsModal,
      className: 'feedback-modal',
    });
  }

  changeName(name) {
    this.setState({
      setPasswordName: name,
    });
  }

  isLogout() {
    const sourceApp = getSource();
    if (sourceApp !== null && sourceApp === 'external_app') return <></>;

    return (
      <a href='/logout' className='user-nav-item' onClick={this.handleLogout}>
        <Icon name='logout' /> {t('Log Out')}
      </a>
    );
  }

  isChangeAccount() {
    const sourceApp = getSource();
    if (sourceApp !== null && sourceApp === 'external_app') return <></>;

    return (
      <a onClick={this.cancelAccountSwitch} className='user-nav-item'>
        {t('Change Your Account')}
      </a>
    );
  }

  isCancelAccount() {
    const sourceApp = getSource();
    if (sourceApp !== null && sourceApp === 'external_app') return <></>;

    return (
      <div>
        <a onClick={this.cancelAccountSwitch} className='user-nav-item'>
          <Icons name='cancelSwitch' width='16' height='16' />{' '}
          {t('Cancel Account Switch')}
        </a>
      </div>
    );
  }

  render() {
    const settingsLink =
      isMultiAccountUser() && !this.state.isSwitchAccount
        ? false
        : '/settings/account';

    return (
      <nav id='user-nav' className='user-nav'>
        {this.props.isSetPassword && (
          <div>
            <div className='user-nav-list'>
              <span className='user-icon'>
                <Icon name='user' />
              </span>
              <span className='user-name'>{this.props.name}</span>
            </div>
          </div>
        )}
        {!this.props.isSetPassword && [
          <div style={{ display: 'inline-flex' }}>
            <RegionSwitcher isHeader />
          </div>,
          <div className='feedback-container'>
            <a href='#' id='feedback-button' onClick={this.openContactUsModal}>
              <Icons name='feedback' color='#fff' />
              {t('Contact Us')}
              <span className='header-release-version-info'>
                v{packageJson.version}
              </span>
            </a>
          </div>,
          <div>{isTechResponsible() && <Notifications />}</div>,
          <div
            className={classNames('user-nav-list border-left', {
              'is-super': isSuperUser(),
            })}
          >
            <Link to={settingsLink} className='user-nav-item'>
              <span className='user-icon'>
                {isSuperUser() ? <Icon name='crown' /> : <Icon name='user' />}
              </span>
              <span className='user-name'>
                {this.props.user.displayName}
                {this.state.isSwitchAccount &&
                  t(` - (${this.state.switchedUser?.account?.domain})`)}
                {this.props.isSetPassword && this.props.name}
                {this.state.isSwitchAccount &&
                  (isSuperUser() ? (
                    <span className='acting'>
                      {t('Acting as')}: {this.state.switchedUser?.displayName} (
                      {this.state.switchedUser?.username})
                    </span>
                  ) : (
                    <span className='acting'>
                      {t('Using')}:{' '}
                      {`${this.state.switchedUser.account.domain} ${t(
                        'account',
                      )}`}
                    </span>
                  ))}
              </span>
            </Link>
            <nav
              className={
                this.state.isSwitchAccount
                  ? 'user-nav-drop user-switch-drop user-top-nav'
                  : 'user-nav-drop user-top-nav'
              }
            >
              {isSuperUser() &&
                (this.state.isSwitchAccount ? (
                  this.isCancelAccount()
                ) : (
                  <div>
                    <Navlink to='/admin/accounts' className='user-nav-item'>
                      <span>
                        <Icon name='flag-alt' /> {t('Accounts')}
                      </span>
                    </Navlink>
                    <Navlink to='/admin/stats' className='user-nav-item'>
                      <span>
                        <Icon name='growth' /> {t('Statistics')}
                      </span>
                    </Navlink>
                    <Navlink to='/admin/monitoring' className='user-nav-item'>
                      <span>
                        <Icon name='pc' /> {t('Monitoring')}
                      </span>
                    </Navlink>
                    <Navlink to='/admin/alerts' className='user-nav-item'>
                      <span>
                        <Icons name='alert' /> {t('Account Alerts')}
                      </span>
                    </Navlink>
                    <Navlink
                      to='/admin/gacomparision'
                      className='user-nav-item'
                    >
                      <span>
                        <Icon name='growth' /> {t('GA4 Comparison')}
                      </span>
                    </Navlink>
                    {isMasterUser() && (
                      <Navlink to='/add/account' className='user-nav-item'>
                        <span>
                          <Icons name='createAccount' /> {t('Create Account')}
                        </span>
                      </Navlink>
                    )}
                    {isSuperUser() && (
                      <Navlink to='/usermanagement' className='user-nav-item'>
                        <span>
                          <Icons name='usermanagement' /> {t('User Management')}
                        </span>
                      </Navlink>
                    )}
                  </div>
                ))}
              {isMultiAccountUser() &&
                this.state.isSwitchAccount &&
                this.isChangeAccount()}
              {settingsLink && (
                <Navlink to={settingsLink} className='user-nav-item'>
                  <span>
                    <Icon name='gear' /> {t('Settings')}
                  </span>
                </Navlink>
              )}
              {this.isLogout()}
            </nav>
          </div>,
        ]}
      </nav>
    );
  }
}

UserNav.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStatesToProps = store => ({
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(UserNav);
