import React from 'react';

import ProgressBar from '@ramonak/react-progress-bar';

export const SFYProgressBar = ({ ratio }) => {
  return (
    <div style={{ flexBasis: '150px' }}>
      <ProgressBar
        completed={ratio}
        bgColor='#7694b9'
        height='30px'
        borderRadius='6px'
        labelAlignment='outside'
        labelColor='#0d0d0d'
        labelSize='12px'
        transitionDuration='1000'
        animateOnRender
        maxCompleted={100}
      />
    </div>
  );
};
