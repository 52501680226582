import React, { useEffect, useState } from 'react';
import { Creatable } from 'react-select';
import { t } from '../../system/ui';
import {
  getTestUsers,
  createTestUsers,
  updateTestUsers,
} from '../../modules/account/ajax';
import { uiActions, modalActions } from '../../actions';

const TestUsers = () => {
  const [emails, setEmails] = useState([]);
  const [groupNames, setGroupNames] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [serverData, setServerData] = useState([]);
  const [error, setError] = useState(false);

  const { showNotification, showErrorNotification } = uiActions;
  const { isProperEmail } = modalActions;

  const changeEmails = values => {
    let isError = false;
    values.forEach(item => {
      if (!isProperEmail(item.value)) {
        isError = true;
      }
    });
    if (!isError) {
      setEmails(values);
    }
    setError(isError);
  };

  const convertArrayToLabelList = arr => {
    return arr.map(item => {
      return { label: item, value: item };
    });
  };

  const convertLabelListToArray = lbl => {
    return lbl.map(item => {
      return item.value;
    });
  };

  const hasKey = (element, obj) => {
    return element === obj.name;
  };

  const changeGroupNames = value => {
    let selectedValue = '';
    if (value) selectedValue = value.value;

    if (!groupNames.includes(selectedValue) && selectedValue !== '') {
      setGroupNames([...groupNames, selectedValue]);
    }
    const itemIndex = serverData.findIndex(item => hasKey(selectedValue, item));

    if (itemIndex >= 0) {
      setEmails(convertArrayToLabelList(serverData[itemIndex].emailList));
    } else {
      setEmails([]);
    }
    setSelectedName(selectedValue);
  };

  const getTestUsersData = () => {
    getTestUsers().then(data => {
      setServerData(data);
      const keys = data.map(item => item.name);
      setGroupNames(keys);
    });
  };

  const getMode = () => {
    const itemIndex = serverData.findIndex(item => hasKey(selectedName, item));
    if (itemIndex >= 0) return { mode: 'SAVE', itemIndex };
    return { mode: 'CREATE', itemIndex: null };
  };

  const handleServerResponse = (res, mode) => {
    let operationStr = '';
    if (mode === 'CREATE') {
      operationStr = 'created';
    } else {
      operationStr = 'updated';
    }
    if (res.status === 'FAIL') {
      const notificationContent = () => <div>{t(res.message)}</div>;
      showErrorNotification(notificationContent);
    } else {
      const notificationContent = () => (
        <div>{t(`Email test group successfully ${operationStr}.`)}</div>
      );
      showNotification({
        content: notificationContent,
      });
      getTestUsersData();
    }
  };

  const createOrUpdateTestUsers = () => {
    const { mode, itemIndex } = getMode();
    const data = {
      name: selectedName,
      emailList: convertLabelListToArray(emails),
    };

    if (mode === 'CREATE') {
      createTestUsers(data).then(res => {
        handleServerResponse(res, mode);
      });
    } else {
      data.id = serverData[itemIndex].id;
      updateTestUsers(data).then(res => {
        handleServerResponse(res, mode);
      });
    }
  };

  useEffect(() => {
    getTestUsersData();
  }, []);

  return (
    <div className='page-content-block one-whole email-settings-form'>
      <div className='email-settings-test-users'>
        <h3
          className='modal-content-title'
          style={{ color: '#6968b2', marginBottom: '5px' }}
        >
          {t('Email Test Users')}
        </h3>
        <br />
        <div>
          <h5 style={{ color: '#7F7F81' }}>{t('Group Name')}</h5>
          <Creatable
            id='test-users-entered'
            options={convertArrayToLabelList(groupNames)}
            multi={false}
            autosize
            clearable
            searchable
            name='userGroups'
            placeholder='Select Email Test Group to edit or create new one'
            value={selectedName}
            onChange={changeGroupNames}
            // value={dummyEmails}
          />
          <hr className='widget-separator' />
          <span>
            <h5 style={{ color: '#7F7F81' }}>Emails</h5>
            <Creatable
              id='test-users-entered'
              options={[]}
              multi
              autosize
              clearable={false}
              searchable
              name='testUsers'
              value={emails}
              onChange={changeEmails}
            />
            {error && (
              <span className='item-error'>
                <span>All emails must be proper email addresses!</span>
              </span>
            )}
          </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '24px',
            }}
          >
            <button
              type='button'
              className='tertiary-action'
              onClick={() => {
                createOrUpdateTestUsers();
              }}
            >
              {t('Save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestUsers;
