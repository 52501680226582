import React, { useContext, useEffect, useState } from 'react';
import Icons from '../../../../components/icons';
import * as Components from '../../../../components/sfy-components/module-exports';
import { getCampaingsList } from '../../../../modules/account/ajax';
import { Context as EmailContext } from '../../store/EmailProvider';
import styles from './AdvancedTargeting.module.scss';
import RecommendationPlaybookCard from '../../../managed-services-portal/icons/RecommendationPlaybookCard';

const AdvancedTargeting = ({
  component,
  editCampaign,
  activeEmailDetails,
  emailType,
  getSegmentCounts,
  readOnly = false,
}) => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [dateInterval, setDateInterval] = useState('');
  const [availableCampOptions, setAvailableCampOptions] = useState([]);
  const [dateError, setDateError] = useState(false);
  const [actionError, setActionError] = useState(false);
  const [isInitial, setIsInitial] = useState(false);

  const { editActiveEmailDetails } = useContext(EmailContext);

  const editParams = (
    fieldKey,
    fieldValue,
    dateInter = false,
    initial = false,
  ) => {
    if (dateInter) {
      setIsInitial(initial);
      setDateInterval(fieldValue);
      if (!initial) {
        clearData(true);
      }
    } else {
      editCampaign(fieldKey, fieldValue, true);
    }
  };

  const clearData = (all = false, initial = false) => {
    if (initial) return;
    const data = {};
    if (all) {
      data.type = 'SEND_MAIL_SEGMENT';
      data.actionType = null;
      data.campaign = '';
      data.startDate = null;
      data.endDate = null;
      editActiveEmailDetails({
        filters: [{ ...activeEmailDetails?.filters[0] }, { ...data }],
      });
    } else {
      data.campaign = '';
      Object.keys(data).forEach(item => {
        editParams(item, data[item]);
      });
    }
  };

  const handleAdvancedTargeting = () => {
    setDateInterval('');
    clearData(true);
    setShowAdvanced(!showAdvanced);
  };

  const getDates = data => {
    getCampaingsList(data).then(response => {
      const opts = response.map(item => {
        return {
          label: item.campaignName,
          value: item.campaignId,
        };
      });
      opts.unshift({ label: 'All Campaigns', value: '' });
      setAvailableCampOptions(opts);
    });
    Object.keys(data).forEach(item => editParams(item, data[item]));
  };

  useEffect(() => {
    if (
      (activeEmailDetails.filters[1]?.startDate ||
        activeEmailDetails.filters[1]?.endDate) &&
      activeEmailDetails.filters[1]?.actionType
    ) {
      setShowAdvanced(true);
    }
  }, []);

  useEffect(() => {
    if (
      dateInterval === 'between' &&
      activeEmailDetails.filters[1]?.startDate &&
      activeEmailDetails.filters[1]?.endDate
    ) {
      getDates({
        startDate: activeEmailDetails.filters[1]?.startDate,
        endDate: activeEmailDetails.filters[1]?.endDate,
      });
      clearData(false, isInitial);
    } else if (
      dateInterval === 'before' &&
      activeEmailDetails.filters[1]?.endDate
    ) {
      getDates({ endDate: activeEmailDetails.filters[1]?.endDate });
      clearData(false, isInitial);
    } else if (
      dateInterval === 'after' &&
      activeEmailDetails.filters[1].startDate
    ) {
      getDates({ startDate: activeEmailDetails.filters[1]?.startDate });
      clearData(false, isInitial);
    }
  }, [
    dateInterval,
    activeEmailDetails?.filters[1]?.startDate,
    activeEmailDetails?.filters[1]?.endDate,
  ]);

  useEffect(() => {
    getSegmentCounts(dateInterval);
    if (showAdvanced) {
      if (dateInterval === 'between') {
        if (
          !activeEmailDetails?.filters[1]?.startDate ||
          !activeEmailDetails?.filters[1]?.endDate
        ) {
          setDateError(true);
        } else {
          setDateError(false);
        }
      } else {
        if (
          dateInterval === 'before' &&
          !activeEmailDetails?.filters[1]?.endDate
        ) {
          setDateError(true);
        } else if (
          dateInterval === 'before' &&
          activeEmailDetails?.filters[1]?.endDate
        ) {
          setDateError(false);
        } else if (
          dateInterval === 'after' &&
          !activeEmailDetails?.filters[1]?.startDate
        ) {
          setDateError(true);
        } else {
          setDateError(false);
        }
        if (dateInterval === '') setDateError(true);
      }
      if (!activeEmailDetails?.filters[1]?.actionType) {
        setActionError(true);
      } else {
        setActionError(false);
      }
    }
  }, [
    activeEmailDetails?.filters[1]?.startDate,
    activeEmailDetails?.filters[1]?.endDate,
    activeEmailDetails?.filters[1]?.campaign,
    activeEmailDetails?.filters[1]?.actionType,
    dateInterval,
    activeEmailDetails?.filters[0]?.segment,
    activeEmailDetails?.filters[0]?.include,
    showAdvanced,
  ]);

  const actionOr = cardName => {
    return cardName === 'action'
      ? activeEmailDetails.filters?.[1].actionType
      : '';
  };

  return (
    <>
      <div className={styles['advanced-targeting__title']}>
        <div
          className={styles['advanced-targeting__clickable']}
          onClick={() => handleAdvancedTargeting()}
        >
          <label>Advanced Targeting</label>
          {showAdvanced ? (
            <Icons name='chevronUp' width={24} height={24} color='#5A7491' />
          ) : (
            <Icons name='chevronDown' width={24} height={24} color='#5A7491' />
          )}
        </div>
      </div>
      {showAdvanced && (
        <>
          <div style={{ display: 'flex', gap: '2rem' }}>
            {component?.advancedTargeting?.firstRowComponentDetails.map(
              comp => {
                const Component = Components[comp.componentName];
                return (
                  <Component
                    key={comp.cardName}
                    fieldValue=''
                    componentDetail={comp}
                    options={comp.options ? comp.options : []}
                    editCampaign={editParams}
                    activeEmailDetails={activeEmailDetails}
                    emailType={emailType}
                    error={
                      dateError && !readOnly
                        ? 'Date field must not be empty.'
                        : ''
                    }
                    readOnly={readOnly}
                  />
                );
              },
            )}
          </div>
          <div style={{ display: 'flex', gap: '2rem' }}>
            {component?.advancedTargeting?.secondRowComponentDetails.map(
              comp => {
                const Component = Components[comp.componentName];
                return (
                  <Component
                    key={comp.cardName}
                    fieldValue={
                      comp.cardName === 'campaign'
                        ? activeEmailDetails.filters?.[1].campaign
                        : actionOr(comp.cardName)
                    }
                    componentDetail={comp}
                    options={
                      (comp.cardName === 'campaign'
                        ? availableCampOptions
                        : comp.options) || []
                    }
                    editCampaign={editParams}
                    activeEmailDetails={activeEmailDetails}
                    emailType={emailType}
                    error={
                      comp.cardName === 'action' && actionError && !readOnly
                        ? 'Action field must not be empty.'
                        : ''
                    }
                    readOnly={readOnly}
                  />
                );
              },
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AdvancedTargeting;
