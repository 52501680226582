import React from "react";
import { connect } from "react-redux";
import Icons from "../components/icons";

class ScrollUpButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButton: false,
      intervalId: 0
    };

    this.showOrHideScrollButton = this.showOrHideScrollButton.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.showOrHideScrollButton);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.showOrHideScrollButton);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.scrollUpButton !== this.props.scrollUpButton) {
      //this.setState({showButton:newProps.scrollUpButton});
    }
  }

  showOrHideScrollButton() {
    let screenHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName("body")[0].clientHeight;
    if (window.scrollY > screenHeight - 100) {
      this.setState({
        showButton: true
      });
    } else {
      if (this.state.showButton) {
        this.setState({
          showButton: false
        });
      }
    }
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(
      0,
      window.pageYOffset > 500
        ? window.pageYOffset - window.pageYOffset / 2
        : window.pageYOffset - window.pageYOffset / 3
    );
  }

  scrollToTop() {
    let intervalId = setInterval(this.scrollStep.bind(this), 0.5);
    this.setState({ intervalId: intervalId });
  }

  render() {
    if (this.state.showButton) {
      return (
        <div
          className="scroll-up"
          onClick={() => {
            this.scrollToTop();
          }}
        >
          <Icons name="scroll" width="20" height="20" />
        </div>
      );
    } else {
      return null;
    }
  }
}

const MapStatesToProps = store => ({
  scrollUpButton: store.ui.scrollUpButton
});

export default connect(MapStatesToProps)(ScrollUpButton);
