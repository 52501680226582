import React from 'react';
import PropTypes from 'prop-types';
import { fixedPxValue } from './utils';

const RightArrowIcon = ({ width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={fixedPxValue(width)}
      height={fixedPxValue(height)}
      viewBox='0 0 13 8'
      fill='none'
    >
      <path d='M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645L9.17157 0.464467C8.97631 0.269205 8.65973 0.269205 8.46447 0.464467C8.2692 0.659729 8.2692 0.976311 8.46447 1.17157L11.2929 4L8.46447 6.82843C8.2692 7.02369 8.2692 7.34027 8.46447 7.53553C8.65973 7.7308 8.97631 7.7308 9.17157 7.53553L12.3536 4.35355ZM1 4.5L12 4.5L12 3.5L1 3.5L1 4.5Z' />
    </svg>
  );
};

RightArrowIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

RightArrowIcon.defaultProps = {
  width: '13px',
  height: '8px',
};

export default RightArrowIcon;
