import React, { useState, useEffect } from 'react';
import ExperimentationCampaignTable from './ExperimentationCampaignTable';
import PercentageRangeIndicator from './PercentageRangeIndicator';
import { clone } from '../../../system/object';
import { getSearch } from '../../../modules/search/ajax';
import { getCampaigns } from '../../../modules/campaigns/ajax';
import { getCampaignsByType } from '../../../modules/campaign/events';
import { getApiKey } from '../../../modules/auth/user';

const ExperimentationVariations = ({
  module,
  mode,
  getRandomColor,
  variations,
  setVariations,
  setFieldTouched,
  experimentId,
  isCompleted,
}) => {
  const [campaigns, setCampaigns] = useState([]);

  const split = variations => {
    const n = variations.length;
    const x = 100;

    if (x % n === 0) {
      for (let i = 0; i < n; i += 1) {
        variations[i].variationName = i === 0 ? 'Original' : `Variation ${i}`;
        variations[i].testSplit = x / n;
      }
    } else {
      const zp = Math.trunc(n - (x % n));
      const pp = Math.trunc(x / n);
      for (let i = 0; i < n; i += 1) {
        variations[i].variationName = i === 0 ? 'Original' : `Variation ${i}`;
        variations[i].testSplit = i >= zp ? pp + 1 : pp;
      }
    }
  };

  const onAddNewVariation = () => {
    let randomColor = getRandomColor();
    while (variations.find(variation => variation.color === randomColor)) {
      randomColor = getRandomColor();
    }
    const copyVariations = clone(variations);
    copyVariations.push({
      color: randomColor,
      instanceId: null,
    });
    split(copyVariations);
    setVariations(copyVariations);
  };

  const onRemoveVariation = index => {
    const copyVariations = clone(variations);
    copyVariations.splice(index, 1);
    split(copyVariations);
    setVariations(copyVariations);
  };

  const calculateRangeValues = () => {
    const values = [];
    for (let i = 0; i < variations.length - 1; i += 1) {
      const splitRatio = variations[i].testSplit ?? 0;
      if (values.length === 0) {
        values.push(splitRatio);
      } else {
        values.push(values[values.length - 1] + splitRatio);
      }
    }
    return values;
  };

  const onRatioChange = (event, index) => {
    let { value } = event.target;
    const copyVariations = clone(variations);
    if (value > 100) {
      value = '100';
    }

    if (!value) {
      copyVariations[index].testSplit = '0';
    } else if (value && copyVariations[index].testSplit === '0') {
      copyVariations[index].testSplit = value.slice(1);
    } else {
      copyVariations[index].testSplit = value;
    }
    if (index === 0)
      copyVariations[index + 1].testSplit =
        '100' - copyVariations[index].testSplit;
    if (index === 1)
      copyVariations[index - 1].testSplit =
        '100' - copyVariations[index].testSplit;
    setVariations(copyVariations);
  };

  const onCampaignChange = (value, index) => {
    const copyVariations = clone(variations);
    copyVariations[index].instanceId = value ?? null;
    setVariations(copyVariations);
  };

  const setValues = values => {
    const copyVariations = clone(variations);
    for (let i = 0; i < values.length; i += 1) {
      if (i === 0) {
        copyVariations[i].testSplit = values[i];
      } else {
        copyVariations[i].testSplit = values[i] - values[i - 1];
      }
    }
    copyVariations[copyVariations.length - 1].testSplit =
      100 - values[values.length - 1];
    setVariations(copyVariations);
    setFieldTouched('variations');
  };

  useEffect(() => {
    if (module === 'SEARCH') {
      getSearch(null, searchCampaignMap => {
        if (searchCampaignMap) {
          const tempCampaigns = Object.keys(searchCampaignMap)
            .map(instanceId => searchCampaignMap[instanceId])
            .filter(campaign => campaign.type !== 'faceted');
          setCampaigns(tempCampaigns);
        }
      });
    } else if (module === 'RECOMMENDATION') {
      // reco
      getCampaigns(
        tempCampaigns => {
          setCampaigns(
            tempCampaigns.map(campaign => ({
              ...campaign,
              name: campaign.scenarioName,
            })),
          );
        },
        { ...(mode !== 'preview' && { excludeABTest: true }) },
      );
    } else {
      getCampaignsByType({
        apiKey: getApiKey(),
        campaignType: 'ALL',
        ...(mode !== 'preview' && { excludeABTest: true }),
      }).then(({ response }) => {
        if (Array.isArray(response)) {
          setCampaigns(response);
        }
      });
    }
  }, []);

  return (
    <>
      <ExperimentationCampaignTable
        variations={variations}
        onAddNewVariation={onAddNewVariation}
        onRemoveVariation={onRemoveVariation}
        onRatioChange={onRatioChange}
        onCampaignChange={onCampaignChange}
        campaigns={campaigns}
        setFieldTouched={setFieldTouched}
        mode={mode}
        experimentId={experimentId}
        isCompleted={isCompleted}
      />
      {!mode && (
        <PercentageRangeIndicator
          values={calculateRangeValues()}
          setValues={setValues}
          variations={variations}
        />
      )}
    </>
  );
};

export default ExperimentationVariations;
