export const paginationSetter = dispatch => {
  return paginationParams => {
    dispatch({
      type: 'SET_PAGINATION',
      payload: paginationParams,
    });
  };
};

export const searchQuerySetter = dispatch => {
  return searchQuery => {
    dispatch({
      type: 'SET_QUERY_STRING',
      payload: searchQuery,
    });
  };
};

export const campaignPathnameSetter = dispath => {
  return campaignPath => {
    dispath({
      type: 'SET_CAMPAIGN_PATHNAME',
      payload: campaignPath,
    });
  };
};
