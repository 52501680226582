import { fieldMapper } from './constants';

export const updateForm = ({ account, stateSetter }) => {
  const mainCurrency = account && account.mainCurrency;
  const additionalCurrencyList = account && account.additionalCurrencyList;
  const additionalCurrencyRegionList =
    (account && account.additionalCurrencyRegionList) || [];
  const additionalRegions = (account && account.additionalRegions) || [];

  let currencies = [];
  if (mainCurrency) {
    currencies.push(mainCurrency.code);
  }

  if (additionalCurrencyList && additionalCurrencyList.length > 0) {
    currencies = currencies.concat(additionalCurrencyList);
  }

  currencies = currencies.map(item => {
    return {
      label: item,
      value: item,
    };
  });

  return stateSetter(prevState => ({
    ...prevState,
    fields: fieldMapper(account),
    mainCurrency: account.mainCurrency,
    mainRegion: account.mainRegion,
    currencies,
    additionalCurrencyRegionList,
    additionalRegions,
  }));
};
