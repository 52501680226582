/**
 * Created by mehmetyurtar on 08/08/2017.
 */
import React, { Component } from 'react';
import { statTypes } from '../../constants/datamaps';

import { t } from '../../system/ui';
import { localeString } from '../../system/string';

import Currency from '../currency';
import Icon from '../icon';
import Icons from '../icons';

class ComparisonColumn extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className='widget-stat-list widget-report'>
        <div>
          {Object.keys(this.props.stats).map((item, index) => {
            const stat = this.props.stats[item].stats;
            const type = statTypes[item];
            let typeName = type.name;
            let typeUnit = type.unit;
            const className = `compare-stat stat-${item.toLowerCase()}`;
            const isSecondColumn = this.props.withCompareIcons;
            let statDifference = isSecondColumn
              ? stat.all.count -
                this.props.comparisonStats[item].stats.all.count
              : 0;
            const mainItem = item;

            if (item === 'widget') {
              statDifference = isSecondColumn
                ? stat.view.count -
                  this.props.comparisonStats[item].stats.view.count
                : 0;
            }

            if (this.props.isEmailCampaign && item === 'widget') {
              typeName = 'Email';
              typeUnit = 'Opens';
            }

            return (
              <div className={className} key={`${item}-${index}`}>
                <div className='compare-stat-container one-half'>
                  <h4 className='widget-stat-title'>{t(typeName)}</h4>
                  {Object.keys(stat).map(item => {
                    const singleStat = stat[item];
                    if (!singleStat.name) return null;

                    if (mainItem === 'widget') {
                      if (item === 'view') {
                        return;
                      }
                      if (item === 'all') {
                        singleStat.name = 'Impression';
                      }
                    } else if (item === 'all') {
                      return;
                    }
                    if (singleStat.name === 'Conversion') {
                      singleStat.name = `${mainItem}Conversion`;
                      singleStat.name =
                        singleStat.name.charAt(0).toUpperCase() +
                        singleStat.name.slice(1);
                    }
                    if (
                      !this.props.isEmailCampaign &&
                      (singleStat.name === 'Unsubscribed' ||
                        singleStat.name === 'Bounce Back')
                    ) {
                      return;
                    }
                    if (this.props.isEmailCampaign) {
                      if (singleStat.name === 'Impression') {
                        singleStat.name = 'Sent';
                      }

                      if (singleStat.name === 'WidgetConversion') {
                        singleStat.name = 'Open Rate';
                      }
                      if (singleStat.name === 'Contribution Ratio') {
                        return;
                      }
                    }

                    if (singleStat.name === 'Spam') {
                      return null;
                    }

                    if (
                      this.props.isEmailCampaign &&
                      (singleStat.name === 'Unsubscribed' ||
                        singleStat.name === 'Bounce Back')
                    ) {
                      return (
                        <p key={item}>
                          {`${t(singleStat.name)} `}
                          <span>{localeString(singleStat.count) || 0}</span>
                        </p>
                      );
                    }

                    return (
                      <p key={item}>
                        {`${t(singleStat.name)} `}
                        <span>
                          {localeString(singleStat.count) ||
                            `${localeString(singleStat.ratio)}%`}
                        </span>
                      </p>
                    );
                  })}
                </div>
                <div
                  className='compare-stat-container one-half'
                  style={{ textAlign: 'right', position: 'relative' }}
                >
                  <p className='widget-stat-count'>
                    {mainItem === 'widget'
                      ? !type.currency
                        ? localeString(stat.view.count)
                        : ''
                      : !type.currency
                      ? localeString(stat.all.count)
                      : ''}
                    <span className='compare-unit compare-unit-price'>
                      {type.currency === true ? (
                        <Currency
                          currency={
                            mainItem === 'widget'
                              ? stat.view.count
                              : stat.all.count
                          }
                          compareCurrency
                        />
                      ) : typeName === 'Email' ? (
                        <Icons name='email' color='#4bb3d2' />
                      ) : (
                        <Icon name={type.icon} />
                      )}
                      {type.currency !== true ? t(typeUnit) : ''}
                    </span>
                  </p>
                  <span
                    style={{
                      position: 'absolute',
                      right: '0',
                      bottom: '-20px',
                    }}
                  >
                    {isSecondColumn && statDifference > 0 && (
                      <span style={{ color: '#56bc94' }}>
                        +
                        {!type.currency ? (
                          localeString(statDifference)
                        ) : (
                          <Currency currency={statDifference} />
                        )}{' '}
                        <Icons name='increase' color='#56bc94' />
                      </span>
                    )}
                    {isSecondColumn && statDifference < 0 && (
                      <span style={{ color: '#dc3322' }}>
                        {' '}
                        {!type.currency ? (
                          localeString(statDifference)
                        ) : (
                          <Currency currency={statDifference} />
                        )}{' '}
                        <Icons name='decrease' color='#dc3322' />
                      </span>
                    )}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ComparisonColumn;
