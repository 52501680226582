/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconGear extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g>
          <path
            fill={this.state.color}
            className="svg-path"
            d="M249.4,341.1c-50.3,0-91.1-40.8-91.1-91.1s40.8-91.1,91.1-91.1s91.1,40.8,91.1,91.1
				S299.8,341.1,249.4,341.1 M434.1,250c0-9.2-0.9-18.1-2.2-26.9l58.5-47.1l-56.4-97l-69.6,27c-14.1-11.2-29.8-20.3-46.8-27L306.1,5
				H193.9l-11.5,73.9c-17,6.7-32.7,15.8-46.8,27L66,78.9L9.6,176l58.5,47.1c-1.3,8.8-2.2,17.8-2.2,26.9c0,9.2,0.9,18.1,2.2,26.9
				L9.6,324l56.4,97l69.6-27c14.1,11.2,29.8,20.3,46.8,27l11.5,73.9h112.2l11.5-73.9c17-6.7,32.7-15.8,46.8-27l69.6,27l56.4-97
				L431.9,277C433.2,268.1,434.1,259.2,434.1,250"
          />
        </g>
      </svg>
    );
  }
}

export default IconGear;
