import React from 'react';
import { t } from '../system/ui';
import { segmentList as SegmentList } from '../modules/widgets/segment-list';
import { segmentSelection as SegmentationTypeSelection } from '../components/segmentation/segmentation-type-selection';
import { setTitle } from '../system/document';

const titleDescription =
  "Segment your visitors with Segmentify's AI-based Smart Segmentation for better targeting and delivery of personalized experiences.";

export const smartSegments = ({
  activeSegments,
  draftSegments,
  passiveSegments,
  segments,
}) => {
  setTitle('Smart Segmentation - Customer Data Management');
  return (
    <div>
      <h2 className='page-title' style={{ marginBottom: '30px' }}>
        {t('Smart Segmentation')}
      </h2>
      <div className='widget-list-wrapper'>
        {activeSegments && activeSegments.length > 0 && (
          <SegmentList status='active' segments={activeSegments} id='smSga' />
        )}
        {draftSegments && draftSegments.length > 0 && (
          <SegmentList status='draft' segments={draftSegments} id='smSgd' />
        )}
        {passiveSegments && passiveSegments.length > 0 && (
          <SegmentList status='passive' segments={passiveSegments} id='smSgp' />
        )}
      </div>
      <SegmentationTypeSelection
        title='Smart Segmentation'
        description={titleDescription}
        segments={segments}
      />
    </div>
  );
};
