import { t } from '../../../system/ui';

export const inputConfiguration = {
  main: [
    {
      type: 'input',
      order: 1,
      className: ' item-stacked one-whole',
      name: 'IYS Code *',
      stateName: 'iysCode',
      placeHolder: 'Type Here...',
      validation: 'required',
    },
    {
      type: 'input',
      order: 2,
      className: ' item-stacked one-whole',
      name: 'Brand IYS Code *',
      stateName: 'brandCode',
      placeHolder: 'Type Here...',
      validation: 'required',
    },
  ],
  consent: [
    {
      type: 'checkbox',
      order: 1,
      name: t('Mail'),
      stateName: 'mailConsent',
    },
    {
      type: 'checkbox',
      order: 2,
      name: t('SMS'),
      stateName: 'smsConsent',
    },
    {
      type: 'checkbox',
      order: 3,
      name: t('Call'),
      stateName: 'phoneConsent',
    },
  ],
  ftp_settings: [
    {
      type: 'input',
      order: 1,
      name: 'Address',
      stateName: 'ftpAddress',
      placeHolder: 'Type Here...',
    },
    {
      type: 'input',
      order: 2,
      name: 'User',
      stateName: 'ftpUser',
      placeHolder: 'Type Here...',
    },
    {
      type: 'password',
      order: 3,
      name: 'Password',
      stateName: 'ftpPassword',
      placeHolder: 'Type Here...',
    },
  ],
};

export const consentTypes = ['EPOSTA', 'MESAJ', 'ARAMA'];
