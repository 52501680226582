import React, { useReducer, useMemo, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';

import { gridState, gridReducer } from '../state-manager/grid-reducer';
import classes from './GridList.module.scss';

import { SortIconGroup } from './components/SortIconGroup';
import { GridListRender } from './components/GridListRender';

/**
 * @name GridList
 * @description
 * This component is responsible for rendering the grid list
 * @param {Function} setUrlParams - Function to set the url params
 * @param {Array} schema - Schema of the grid
 * @param {Array} data - Data to be rendered in the grid
 * @returns JSX.Element - Grid List
 */
export const GridList = ({ data, schema, setUrlParams }) => {
  const [state, dispatch] = useReducer(gridReducer, gridState);

  const sortedSchema = useMemo(() => {
    return schema.sort((a, b) => a.order - b.order);
  }, [schema]);

  const handleSort = useCallback(
    ({ accessor, type = 'sort' }) => {
      return dispatch({
        type,
        payload: { accessor, isSorting: !state.sort.isSorting },
      });
    },
    [dispatch, state.sort.accessor, state.sort.isSorting],
  );

  const prepareSortedArrayToUrl = useCallback(() => {
    if (state.sort.accessor)
      return `&sortingField=${state.sort.accessor}&isSorting=${state.sort.isSorting}`;
    return '';
  }, [state.sort.accessor, state.sort.isSorting]);

  useEffect(() => {
    const debounced = debounce(() => {
      setUrlParams(prepareSortedArrayToUrl());
    }, 1000);
    debounced();
    return debounced.cancel;
  }, [prepareSortedArrayToUrl]);

  return (
    <div>
      <table className={classes.table}>
        <thead>
          <tr>
            {sortedSchema.map((item, i) => (
              <th className={classes.header_container} key={i}>
                <span className={classes.header}>{item.header}</span>
                {item.sortable && (
                  <span
                    className={classes.header__sortable}
                    onClick={() => {
                      handleSort({
                        accessor: item.accessor,
                      });
                    }}
                  >
                    <SortIconGroup
                      sort={state?.sort?.accessor === item.accessor}
                      isSorting={state?.sort?.isSorting}
                    />
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <GridListRender data={data} schema={sortedSchema} />
      </table>
    </div>
  );
};
