import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';

import {
  getFormattedDate,
  getFormattedDetailedDate,
  getFormattedDetailedHour,
  getNow,
  isAfterNow,
  periods,
} from '../../system/date';
import { t } from '../../system/ui';
import EmailWidgetActions from '../widget/email-actions';
import Icons from '../icons';

class EmailsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      count: 0,
      isToggled: false,
      requestedCampaign: 1
    };

    this.getCampaigns = this.getCampaigns.bind(this);
    this.toggle = this.toggle.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    this.getCampaigns(this.props.emails);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.emails !== newProps.emails) {
      this.getCampaigns(newProps.emails);
    }
  }

  isScheduled(campaign) {
    let now = getNow().valueOf();
    return campaign.startDate > now;
  }

  isCompleted(campaign) {
    let now = getNow().valueOf();
    return campaign.endDate < now;
  }

  getCampaigns(allEmails) {
    let type = this.props.type;
    let filteredCampaigns = allEmails.filter(email => {
      if (this.props.noStatusFilter) {
        return true;
      }
      if (this.props.scheduleFilter) {
        if (this.props.scheduleFilter === "scheduled") {
          return email.status === "ACTIVE" || email.status === "TEST";
        }
      }
      return email.status === type;
    });
    if (this.props.scheduleFilter) {
      if (this.props.scheduleFilter === "scheduled") {
        filteredCampaigns = filteredCampaigns.filter(email => {
          return email.schedule
            ? (email.schedule.type === "one_time" &&
                isAfterNow(moment(email.schedule.date))) ||
                email.schedule.type === "periodic"
            : false;
        });
      } else {
        filteredCampaigns = filteredCampaigns.filter(email => {
          return email.schedule
            ? email.schedule.type === "now" ||
                (email.schedule.type === "one_time" &&
                  !isAfterNow(moment(email.schedule.date)))
            : false;
        });
      }
    }
    this.setState(
      {
        emails: filteredCampaigns,
        count: filteredCampaigns.length,
        isToggled: false,
        requestedCampaign: 1
      },
      () => {
        if (this.props.onCountChange) {
          this.props.onCountChange(filteredCampaigns.length);
        }
      }
    );
  }

  toggle() {
    if (this.props.closed) {
      this.setState({
        isToggled: !this.state.isToggled
      });
    }
  }
  loadMore(e) {
    e.preventDefault();
    this.setState({
      requestedCampaign: this.state.requestedCampaign + 1
    });
  }
  render() {
    let emailsList = [];
    if (this.props.scheduleFilter === "sent") {
      emailsList = this.state.emails.slice(0, 5 * this.state.requestedCampaign);
    } else {
      emailsList = this.state.emails;
    }
    return emailsList.length > 0 ? (
      <div
        className={classNames("widget-list-has-items", {
          "will-toggle": this.props.closed,
          "is-toggled": this.state.isToggled
        })}
      >
        <h3
          className={classNames("page-subtitle", {
            passive: this.props.type === "TEST"
          })}
          onClick={this.toggle}
        >
          {t(this.props.title)} ({this.state.count})
        </h3>
        <ul className="widget-list">
          {emailsList.map((campaign, index) => {
            let scheduleText;
            let scheduleDate;
            let isScheduledDraft = false;
            if (this.props.scheduleFilter && campaign.schedule) {
              if (this.props.scheduleFilter === "scheduled") {
                scheduleText =
                  campaign.schedule.type === "one_time"
                    ? "Will be sent at "
                    : "Will be sent ";
                scheduleDate =
                  campaign.schedule.type === "one_time"
                    ? getFormattedDetailedDate(campaign.schedule.date)
                    : `Every ${
                        periods.filter(period => {
                          return period.value === campaign.schedule.period;
                        })[0].label
                      }`;
                if (campaign.schedule.type !== 'one_time') {
                  scheduleDate += ` at ${getFormattedDetailedHour(
                    campaign.schedule.date,
                  )}`;
                }
                if (campaign.status === "TEST") {
                  isScheduledDraft = true;
                }
              } else {
                scheduleText = "Sent at ";
                scheduleDate = getFormattedDetailedDate(campaign.schedule.date);
              }
            }
            return (
              <li key={campaign.instanceId} className="widget-list-item">
                <h2 className="widget-title">{campaign.scenarioName}</h2>
                <div className="widget-meta">
                  {isScheduledDraft && (
                    <span
                      className="promotion-label"
                      style={{
                        background: "#4c4c50",
                        color: "#fff",
                        "font-weight": "600",
                        width: "61px"
                      }}
                    >
                      {"Draft"}
                    </span>
                  )}
                  {scheduleText ? (
                    <span
                      className="widget-meta-date"
                      style={{ borderRight: 0 }}
                    >
                      <span>
                        <span>{scheduleText}</span>
                        {scheduleDate}
                      </span>
                    </span>
                  ) : (
                    <span
                      className="widget-meta-date"
                      style={{ borderRight: 0 }}
                    >
                      {campaign.startDate && (
                        <span>
                          <Icons name="calendar" />
                          {getFormattedDate(campaign.startDate)}
                        </span>
                      )}
                    </span>
                  )}
                </div>
                <EmailWidgetActions
                  campaign={campaign}
                  onChangeStatus={this.props.onChangeStatus}
                />
              </li>
            );
          })}
          {this.props.scheduleFilter === "sent" &&
            emailsList.length < this.state.emails.length &&
            this.state.emails.length > 5 && (
              <a href="#" onClick={this.loadMore} className="list-load-more">
                Show More
              </a>
            )}
        </ul>
      </div>
    ) : (
      false
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  user: store.user.user,
  status: store.campaigns.status
});

export default connect(mapStatesToProps)(EmailsList);
