/**
 * @author Bilal Cinarli
 */

"use strict";

const personalizationTrendsMap = {
  widget: {
    stats: {
      view: {
        name: "Actual View",
        count: 0
      },
      all: {
        count: 0,
        total: true,
        name: "Total View"
      },
      conversion: {
        name: "Conversion",
        ratio: 0
      }
    },
    modal: "Widget"
  },
  interaction: {
    stats: {
      all: {
        count: 0,
        total: true
      },
      conversion: {
        name: "Conversion",
        ratio: 0
      }
    },
    modal: "Interaction"
  },
  basket: {
    stats: {
      all: {
        count: 0,
        total: true
      },
      amount: {
        name: "Total Amount",
        count: 0,
        currency: true
      },
      conversion: {
        name: "Conversion",
        ratio: 0,
        tooltip:
          "Starting from December 17th, Add to Basket Rate will be calculated by dividing the Number of Unique Products in the Basket by the Number of Clicks, instead of dividing the Total Number of Products in the Basket by the Number of Clicks."
      }
    },
    modal: "Basket"
  },
  revenue: {
    stats: {
      all: {
        count: 0,
        total: true,
        currency: true
      },
      purchases: {
        name: "Purchases",
        count: 0
      },
      items: {
        name: "Purchase Items",
        count: 0
      },
      average: {
        name: "Average Order Value",
        count: 0,
        currency: true
      },
      conversion: {
        name: "Conversion",
        ratio: 0
      },
      contribution: {
        name: "Contribution Ratio",
        ratio: 0
      }
    },
    modal: "Revenue"
  },
  grantedPermission: {
    stats: {
      granted: {
        count: 0,
        name: "Granted"
      },
      all: {
        count: 0,
        name: "All"
      }
    }
  },
  deniedPermission: {
    stats: {
      denied: {
        count: 0,
        name: "Denied"
      },
      all: {
        count: 0,
        name: "All"
      }
    }
  },
  formSubmit: {
    stats: {
      count: 0,
      name: "Submitted"
    }
  },
  segmentVisitor: {
    stats: {
      all: {
        count: 0,
        name: "Segment Visitor"
      }
    }
  },
  unsegmentVisitor: {
    stats: {
      all: {
        count: 0,
        name: "Unsegment Visitor"
      }
    }
  },
  toggleVisitorSegment: {
    stats: {
      all: {
        count: 0,
        name: "Toggle Visitor Segment"
      }
    }
  }
};

export { personalizationTrendsMap };
