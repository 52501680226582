import moment from 'moment';
import React from 'react';

import { devices } from 'constants/datamaps/campaigns';
import Icon from 'components/icon';
import { FACETED_SEARCH_NAME } from 'constants/faceted-search';
import { t } from 'system/ui';
import PropTypes from 'prop-types';
import FacetedSearchActions from './FacetedSearchActions';

export default function CampaignMainItem({ campaign }) {
  return (
    <li className="widget-list-item">
      <div className="widget-title-wrapper">
        <h2 className="widget-title">{campaign.name || FACETED_SEARCH_NAME}</h2>
        <span className="widget-title-separator-dot">&#183;</span>
        <span className="widget-service-name">(service: <b>{campaign.service}</b>)</span>
      </div>
      <div className="right-side-wrapper">
        <div className="widget-actions-wrapper">
          <div className="widget-meta">
            {campaign.devices.map(device => (
              <span key={device} className="widget-meta-mobile">
                <Icon name={device.toLowerCase()} />
                {t(devices[device])}
              </span>
            ))}
            |{' '}
            <span style={{ marginLeft: 10 }} className="widget-meta-mobile">
              <Icon name="calendar" />
              <span>
                {moment(campaign.limitations.startDate).format('DD/MM/YYYY')}
              </span>
            </span>
          </div>
        </div>
        <FacetedSearchActions campaign={campaign} />
      </div>
    </li>
  );
}

CampaignMainItem.propTypes = {
  campaign: PropTypes.objectOf(PropTypes.object).isRequired,
};
