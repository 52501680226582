import React from 'react';
import { t } from '../../../system/ui';
import { getNowByTimeZone, reformatDate } from '../../../system/date';
import { featureModules } from '../../../constants/datamaps/account';
import { Calendar, NumberField, Select } from '../../fields';
import { moduleOptions } from '../constants';

/**
 * This component helps to show Modules part of edit screen.
 * @param {Object} account
 * @param {Object} account.features
 * @param {string|Number} account.insightsProductCount
 * @param {string|Number} account.insightsProductExportCount
 * @param {Object} features
 * @param {Function} reducerStateChangeHandler
 * @param {boolean} readonly
 * @returns {JSX.Element|null}
 */
const Modules = ({
  account,
  features,
  reducerStateChangeHandler,
  readonly = false,
}) => {
  const pocDateChange = (type, name, datetime) => {
    const tempFeatures = { ...features };

    if (type === 'start') {
      tempFeatures[name].pocStart = datetime;
    } else {
      tempFeatures[name].pocEnd = datetime;
    }

    reducerStateChangeHandler('features', tempFeatures);
  };

  const handleModuleUpdate = selectedOption => {
    const tempFeatures = JSON.parse(JSON.stringify(features));

    tempFeatures[selectedOption.name].status = selectedOption.value;

    if (selectedOption.value === 'POC') {
      const end = getNowByTimeZone().add(19, 'day');
      tempFeatures[selectedOption.name].pocStart = getNowByTimeZone();
      tempFeatures[selectedOption.name].pocEnd = end;
    } else {
      // Remove POC dates
      delete tempFeatures[selectedOption.name].pocStart;
      delete tempFeatures[selectedOption.name].pocEnd;
    }

    reducerStateChangeHandler('features', tempFeatures);
  };

  const startPocDateChange = (moment, name) => {
    pocDateChange('start', name, moment);
  };

  const endPocDateChange = (moment, name) => {
    pocDateChange('end', name, moment);
  };

  const isRelatedModuleActive = (thisModuleKey, relatedModuleKey) => {
    if (!relatedModuleKey) return true;

    const relatedModule = features[relatedModuleKey];
    if (relatedModule?.status === 'PASSIVE') {
      if (features[thisModuleKey]?.status !== 'PASSIVE') {
        // If related module is PASSIVE, then this module should be PASSIVE
        handleModuleUpdate({
          label: 'Passive',
          name: thisModuleKey,
          value: 'PASSIVE',
        });
      }
      return false;
    }
    return true;
  };

  return (
    <li>
      <h3 className='section-title'>{t('Modules')}</h3>
      <div className='row module-segment'>
        <div className='widget-stats'>
          <div className='widget-stat-segmentation  stat-segmentation' />

          <div className='widget-stat-segmentation  stat-segmentation'>
            <p className='widget-stat-conversion-modules'>
              <b>{t('Status')}</b>
            </p>
          </div>

          <div className='widget-stat-segmentation  stat-segmentation'>
            <p className='widget-stat-conversion-account'>
              <b>{t('POC Start')}</b>
            </p>
          </div>
          <div className='widget-stat-segmentation  stat-segmentation'>
            <p className='widget-stat-conversion-account'>
              <b>{t('POC End')}</b>
            </p>
          </div>
        </div>
        {featureModules.map(item => {
          const accountFeatureItem = features[item.key]
            ? features[item.key]
            : account.features[item.key];

          return (
            <div
              className='row'
              style={{ marginBottom: '-10px' }}
              key={item.key + item.name}
            >
              <hr className='widget-separator' style={{ margin: '10px' }} />
              <Select
                name={item.key}
                className='item-stacked module-account-label five-columns'
                value={accountFeatureItem.status}
                options={moduleOptions}
                label={item.name}
                onChange={handleModuleUpdate}
                disabled={
                  !isRelatedModuleActive(item.key, item?.relatedModuleKey) ||
                  readonly
                }
              />
              <Calendar
                className='item-stacked three-columns'
                icon
                value={reformatDate(features[item.key]?.pocStart, 'DD/MM/YYYY')}
                timeFormat={false}
                dateFormat
                onChange={moment => startPocDateChange(moment, item.key)}
                disabled={
                  // eslint-disable-next-line no-nested-ternary
                  readonly
                    ? { disabled: true, readOnly: true }
                    : features[item.key]
                    ? {
                        disabled: features[item.key].status !== 'POC',
                        readOnly: true,
                      }
                    : {
                        disabled: accountFeatureItem.status !== 'POC',
                        readOnly: true,
                      }
                }
              />
              <Calendar
                className='item-stacked three-columns'
                icon
                value={reformatDate(features[item.key]?.pocEnd, 'DD/MM/YYYY')}
                timeFormat={false}
                dateFormat
                onChange={moment => endPocDateChange(moment, item.key)}
                disabled={
                  // eslint-disable-next-line no-nested-ternary
                  readonly
                    ? { disabled: true, readOnly: true }
                    : features[item.key]
                    ? {
                        disabled: features[item.key].status !== 'POC',
                        readOnly: true,
                      }
                    : {
                        disabled: accountFeatureItem.status !== 'POC',
                        readOnly: true,
                      }
                }
              />
            </div>
          );
        })}
      </div>
      <div className='row module-segment'>
        <div className='widget-stats'>
          <h5 className='section-subtitle'>{t('Insights')}</h5>
        </div>
        <div className='row' style={{ marginBottom: '-10px' }}>
          <span className='item-label simple-label'>Insight</span>
          <NumberField
            name='insightsProductCount'
            label='Number of Products'
            labelPlace='before'
            inputClassName='large'
            placeholder='Example: 10'
            min='1'
            max='100'
            step='1'
            defaultValue={account.insightsProductCount ?? ''}
          />
        </div>
        <div className='row' style={{ marginBottom: '-10px' }}>
          <span className='item-label simple-label'>Export</span>
          <NumberField
            name='insightsProductExportCount'
            label='Number of Products'
            labelPlace='before'
            inputClassName='large'
            placeholder='Example: 10'
            min='1'
            max='100'
            step='1'
            defaultValue={account.insightsProductExportCount ?? ''}
          />
        </div>
      </div>
    </li>
  );
};

export default Modules;
