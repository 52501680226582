import { getNow } from 'system/date';

export const permissionCodes = {
  html:
    '<div class="seg-popup-push">\n' +
    '<div class="seg-popup-push-logo">\n' +
    '<img src="[[icon]]" alt="Our company\'s Logo"/> \n' +
    '<span class="seg-popup-push-pulse">1</span>\n' +
    '</div>\n' +
    '<div class="seg-popup-push-content">\n' +
    '<h1 class="seg-popup-push-title">[[title]]</h1>\n' +
    '<p class="seg-popup-push-desc">[[text]]</p>\n' +
    '<div class="seg-popup-push-bttns-new">\n' +
    '<button type="button" class="seg-popup-push-bttn-later-new">[[buttonLaterText]]</button>\n' +
    '<button type="button" class="seg-popup-push-bttn-allow-new"><span>[[buttonAllowText]]</span></button>\n' +
    '</div>\n' +
    '</div>\n' +
    '</div>',
};

export const pushNotificationsLifeTime = [
  '1',
  '3',
  '6',
  '12',
  '24',
  '48',
  '72',
  '96',
  '120',
  '144',
  '168',
];

export const pushNotificationsPeriodicLifeTime = ['1', '3', '6', '12', '24'];

export const paramsTypes = {
  title: {
    label: 'Title',
    type: 'text',
  },
  delayInHours: {
    label: 'Trigger time in hours',
    tooltip:
      'Select how many hours should pass after the user action before the email is triggered.',
    type: 'integer',
    min: 1,
    max: 24,
  },
  triggerInHours: {
    label: 'Trigger Time in Hours',
    tooltip:
      'Select how many hours should pass after the user action before the email is triggered.',
    type: 'integer',
    min: 2,
    max: 12,
  },
  interval: {
    label: 'Send Between',
    tooltip: 'The campaign will be launched during the time slot you choose.',
    type: 'interval',
    min: 1,
    max: 24,
  },
  sendBetweenHour: {
    label: 'Send Between',
    tooltip: 'The campaign will be launched during the time slot you choose.',
    type: 'interval',
    min: 1,
    max: 24,
  },
  delayInDays: {
    label: 'Trigger time in days',
    tooltip:
      'Select how many days should pass after the user action before the email is triggered.',
    type: 'integer',
    min: 1,
  },
  purchaseCount: {
    label: 'Purchase Count',
    tooltip: 'Min count of purchases done by the user',
    type: 'integer',
  },
  workingHour: {
    label: 'Select Time',
    tooltip: 'When should the campaign launch?',
    type: 'timepicker',
    min: 0,
    max: 23,
  },
  workingDay: {
    label: 'Select a day',
    tooltip: 'The day that campaign will work on',
    type: 'integer',
    min: 1,
    max: 7,
  },
  daysWithin: {
    label: 'Days within',
    tooltip:
      'The Days within contains information that provides further trigger times as day.',
    type: 'integer',
    min: 1,
  },
  productLimit: {
    label: 'Product Limit',
    tooltip:
      'The Product Limit contains information that provides product count limit.',
    type: 'integer',
    min: 1,
    max: 9,
  },
  eventName: {
    label: 'Event Name',
    type: 'text',
    tooltip: 'Event name that will trigger the campaign.',
  },
  startDate: {
    label: 'Start Date',
    type: 'datepicker',
    tooltip:
      'The Product Limit contains information that provides product count limit.',
  },
  endDate: {
    label: 'End Date',
    type: 'datepicker',
    tooltip:
      'The Product Limit contains information that provides product count limit.',
  },
  mailTo: {
    label: 'Mail To',
    type: 'text',
  },
  discountRatio: {
    label: 'Discount Ratio (%)',
    type: 'text',
    tooltip:
      'The value that is the minimum discounted limit per product as a percentage',
  },
  delay: {
    label: 'Trigger Time',
    tooltip:
      'How long should past after visitor action for the campaign to be triggered?',
    type: 'timepicker',
    min: 0,
    max: 23,
  },
  sendBefore: {
    label: 'Send Before',
    tooltip: '',
    type: 'sendbefore',
    min: 1,
    max: 24,
  },
  klaviyoEventName: {
    label: 'Prefix Name',
    type: 'text',
    tooltip: 'Enter a prefix to add the campaign to the profile in Klaviyo',
  },
  frequency: {
    label: 'Sending Time',
    tooltip:
      'Select how many days before the next date the Push Sending Time should be sent according to the purchase frequency.',
    type: 'integer',
    min: 0,
  },
  sendingPeriod: {
    label: 'Frequency',
    tooltip: 'How frequently will the campaign be sent??',
    type: 'select',
  },
};

export const paramsInWizard = [
  'mailContent',
  'subject',
  'logoUrl',
  'mailTo',
  '_mailContent',
  '_subject',
];

export const localizationParams = {
  language: {
    tooltip: '',
    type: 'select',
    label: 'Language',
  },
  currency: {
    tooltip: '',
    type: 'select',
    label: 'Currency',
  },
};

export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const periods = ['Minute', 'Hour', 'Day'];

export const campaignInitialData = [
  {
    type: 'ABANDONED_CART',
    name: 'Abandoned Cart',
    instanceId: 'ABANDONED_CART',
    accountId: '',
    status: 'PASSIVE',
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_SOURCE_ALL_PRODUCTS',
      timeFrame: 'WEEKLY',
      itemCount: 9,
      score: 'ROCK',
    },
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      interval: '10-21',
    },
    hidePrice: false,
    hideInformation: false,
    devices: [],
    title: '',
    pushType: 'PERSONALIZED',
    additionalTitles: {},
    steps: {
      FIRST_REMINDER: {
        active: true,
        title: '',
        icon: '{{push:icon}}',
        image: '{{product:image}}',
        delay: {
          delay: 0,
          type: 'MINUTE',
        },
        additionalTitles: {},
        additionalIcons: {},
        additionalImages: {},
        actions: [],
      },
      SECOND_REMINDER: {
        active: false,
        title: '',
        icon: '{{push:icon}}',
        image: '{{product:image}}',
        delay: {
          delay: 0,
          type: 'HOUR',
        },
        additionalTitles: {},
        additionalIcons: {},
        additionalImages: {},
        actions: [],
      },
      ALTERNATIVE_PRODUCTS: {
        active: false,
        title: '',
        icon: '{{push:icon}}',
        image: '{{product:image}}',
        delay: {
          delay: 2,
          type: 'DAY',
        },
        additionalTitles: {},
        additionalIcons: {},
        additionalImages: {},
        actions: [],
      },
      COUPON: {
        active: false,
        title: '',
        icon: '',
        image: '',
        delay: {
          delay: 0,
          type: 'DAY',
        },
        additionalTitles: {},
        additionalIcons: {},
        additionalImages: {},
        basketAmount: null,
        couponCode: '',
        expireDate: null,
        actions: [],
      },
    },
  },
  {
    type: 'ORDER_FOLLOW_UP',
    name: 'Order Follow-Up',
    accountId: '',
    status: 'PASSIVE',
    hidePrice: false,
    hideInformation: false,
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_PURCHASE_ALTERNATIVES',
      timeFrame: 'WEEKLY',
      itemCount: 9,
      score: 'ROCK',
    },
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingHour: '09:00',
      delayInDays: '1',
    },
    devices: [],
    title: '',
  },
  {
    type: 'NICE_TO_MEET_YOU',
    name: 'Nice To Meet You',
    accountId: '',
    status: 'PASSIVE',
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_SOURCE_ALL_PRODUCTS',
      timeFrame: 'WEEKLY',
      itemCount: 9,
      score: 'ROCK',
    },
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
    },
    hidePrice: false,
    hideInformation: false,
    devices: [],
    title: '',
  },
  {
    type: 'REPLENISHMENT',
    name: 'Replenishment',
    accountId: '',
    status: 'PASSIVE',
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_REPLENISHMENT_PRODUCTS',
      timeFrame: 'WEEKLY',
      itemCount: 9,
      score: 'ROCK',
    },
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingHour: '11:00',
      frequency: 0,
    },
    hidePrice: false,
    hideInformation: false,
    devices: [],
    title: '',
  },
  {
    type: 'BIRTHDAY',
    name: 'Birthday Campaign',
    accountId: '',
    status: 'PASSIVE',
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_SMART_OFFERS',
      timeFrame: 'WEEKLY',
      itemCount: 9,
      score: 'ROCK',
    },
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingHour: '10:00',
    },
    hidePrice: false,
    hideInformation: false,
    devices: [],
    title: '',
  },
  {
    type: 'CHURN',
    name: 'Churn',
    accountId: '',
    status: 'PASSIVE',
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_SMART_OFFERS',
      timeFrame: 'WEEKLY',
      itemCount: 9,
      score: 'ROCK',
    },
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      purchaseCount: '1',
      workingHour: '09:00',
      delayInDays: '30',
    },
    hidePrice: false,
    hideInformation: false,
    devices: [],
    title: '',
  },
  {
    type: 'NEW_COMERS',
    name: 'New Arrivals',
    accountId: '',
    status: 'PASSIVE',
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_NEW_COMERS',
      timeFrame: 'MONTHLY',
      itemCount: 9,
      score: 'ROCK',
    },
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingDay: '1',
      workingHour: '09:00',
      daysWithin: '-1',
    },
    hidePrice: false,
    hideInformation: false,
    devices: [],
    title: '',
  },
  {
    type: 'LAST_VISIT_ALTERNATIVES',
    name: 'Last Visit Alternatives',
    instanceId: 'LAST_VISIT_ALTERNATIVES',
    accountId: '',
    status: 'PASSIVE',
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_SMART_OFFERS',
      timeFrame: 'WEEKLY',
      itemCount: 9,
      score: 'ROCK',
    },
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      delay: '02:00',
      interval: '10-21',
    },
    hidePrice: false,
    hideInformation: false,
    capping: {
      capCount: '1',
      frequency: 'DAILY',
      types: [],
    },
    devices: [],
    title: '',
  },
  {
    type: 'WE_MISSED_YOU',
    name: 'We Missed You',
    instanceId: 'WE_MISSED_YOU',
    accountId: '',
    status: 'PASSIVE',
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_SMART_OFFERS',
      timeFrame: 'WEEKLY',
      itemCount: 9,
      score: 'ROCK',
    },
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingHour: '09:00',
      delayInDays: '15',
    },
    hidePrice: false,
    hideInformation: false,
    devices: [],
    title: '',
  },
  {
    type: 'TOP_SELLERS',
    name: 'Top Sellers',
    accountId: '',
    status: 'PASSIVE',
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_TOP_SELLERS',
      timeFrame: 'WEEKLY',
      itemCount: 9,
      score: 'PURCHASE',
    },
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingDay: '4',
      workingHour: '16:00',
      daysWithin: '-1',
    },
    hidePrice: false,
    hideInformation: false,
    devices: [],
    title: '',
  },
  {
    type: 'PRICE_DROP',
    name: 'Price Drop',
    accountId: '',
    status: 'PASSIVE',
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_DISCOUNTED_PRODUCTS',
      timeFrame: 'MONTHLY',
      itemCount: 9,
      score: 'ROCK',
    },
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      discountRatio: '',
      sendingPeriod: 'every_day',
      workingHour: '11:00',
      sendBetweenHour: '10-14',
      triggerInHours: '2',
    },
    hidePrice: false,
    hideInformation: false,
    capping: {
      capCount: '1',
      frequency: 'DAILY',
      types: [],
    },
    devices: [],
    title: '',
  },
  {
    type: 'BACK_IN_STOCK',
    title: '',
    validity: 24,
    name: 'Back In Stock',
    instanceId: 'BACK_IN_STOCK',
    status: '',
    devices: [],
    startDate: null,
    filters: [],
    accountId: '',
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      workingHour: '10:00',
    },
    inclusions: [],
    exclusions: [],
    hidePrice: false,
    hideInformation: false,
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_SMART_OFFERS',
      timeFrame: 'WEEKLY',
      itemCount: 9,
      score: 'ROCK',
    },
    endDate: '',
  },
  {
    type: 'RECOMMENDATION',
    name: 'Push Recommendation',
    accountId: '',
    status: 'PASSIVE',
    recommendation: {},
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
    },
    schedule: {},
    filters: [
      {
        type: 'SEGMENT',
        segment: '',
        include: true,
      },
    ],
    devices: [],
    title: '',
  },
  {
    type: 'PUSH_NOTIFICATION',
    devices: [],
    filters: [],
    params: {
      language: 'SESSION_BASED',
    },
    hidePrice: false,
    hideInformation: false,
    inclusions: [],
    exclusions: [],
    title: '',
    image: '',
  },
  {
    type: 'PUSH_PERMISSION',
    name: '',
    instanceId: '',
    status: 'ACTIVE',
    devices: [],
    startDate: getNow(),
    params: {
      language: 'SESSION_BASED',
    },
    timing: {
      type: 'IMMEDIATE',
      param: '',
    },
    frequency: {
      type: 'ALWAYS',
      param: '3',
    },
    filters: [
      {
        type: 'PAGE',
        includedCategories: [],
        excludedCategories: [],
      },
    ],
    accountId: '',
    title: '',
    text: '',
    icon: '{{push:icon}}',
    url: '',
    buttonLaterText: '',
    buttonAllowText: '',
    html: '',
    css: '',
    preJs:
      'function preRenderConf(conf) { /* add your custom code inside this function */ }',
    postJs: '',
    additionalDescriptions: {},
    additionalTitles: {},
    additionalButtonLaterText: {},
    additionalButtonAllowText: {},
    twoStepEnabled: true,
  },
  {
    type: 'FLY',
    name: 'Fly Mode',
    instanceId: '',
    validity: 72,
    status: 'ACTIVE',
    devices: [],
    filters: [],
    accountId: '',
    params: {},
    inclusions: [],
    exclusions: [],
    text: '',
    icon: '',
    redirectUrl: '',
    image: '',
    title: '',
    eventName: '',
  },
  {
    type: 'UPCOMING',
    name: 'Upcoming Reminder',
    instanceId: 'UPCOMING',
    accountId: '',
    status: 'PASSIVE',
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_UPCOMING',
      timeFrame: 'WEEKLY',
      itemCount: 9,
      score: 'PURCHASE',
    },
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      sendBefore: '1-Hour',
    },
    hidePrice: true,
    hideInformation: false,
    capping: {
      capCount: '5',
      frequency: 'DAILY',
      types: [],
    },
    devices: [],
    title: '',
  },
  {
    type: 'LAST_VISIT_REMINDER',
    name: 'Last Visit Reminder',
    instanceId: 'LAST_VISIT_REMINDER',
    accountId: '',
    status: 'PASSIVE',
    recommendation: {
      type: 'INTELLIGENT',
      source: 'RECOMMENDATION_LAST_VISIT_REMINDER',
      timeFrame: 'WEEKLY',
      itemCount: 9,
      score: 'ROCK',
    },
    inclusions: [],
    exclusions: [],
    params: {
      language: 'SESSION_BASED',
      currency: 'SESSION_BASED',
      delay: '00:30',
      interval: '10-21',
    },
    hidePrice: false,
    hideInformation: false,
    capping: {
      capCount: '2',
      frequency: 'DAILY',
      types: [],
    },
    devices: [],
    title: '',
  },
];

export const initialStates = {
  PUSH_PERMISSION: {
    title: 'Permission Prompt',
    mainField: [
      {
        field: 'popupOption',
        fields: [
          {
            field: 'pushPermissionTitle',
            options: {
              label: 'Title',
              name: 'title',
              required: true,
              tooltip: true,
              tooltipContent:
                'Enter the title that your visitors will see on the push permission.',
            },
          },
          {
            field: 'pushDescription',
            options: {
              label: 'Description',
              name: 'pushDescription',
              tooltip: true,
              required: true,
              tooltipContent: 'Enter the description of the push permission.',
            },
          },
          {
            field: 'iconWithUpload',
            options: {
              label: 'Your Logo URL (Recommend 200x200px)',
              tooltip: true,
              tooltipContent:
                'Enter the URL of the notification icon or upload an icon..',
            },
          },
          { field: 'confirmButtons', isPush: true },
        ],
      },
    ],
    additionalFields: {
      fields: [{ field: 'timingOptions', isPush: true }],
    },
    config: [
      { field: 'name', isPush: true },
      { field: 'startEndDate' },
      { field: 'devices', isPush: true },
      { field: 'pages' },
      {
        field: 'advancedConfig',
        options: permissionCodes,
      },
    ],
    buttons: [
      { field: 'advancedConfigButton' },
      { field: 'goTest', isPush: true },
      { field: 'goLive' },
    ],
  },
  PUSH_NOTIFICATION: {
    title: 'Bulk / Segmented Notification',
    mainField: [
      {
        field: 'pushDescription',
        options: {
          label: 'Description',
          name: 'pushDescription',
          tooltip: true,
          tooltipContent: 'Enter the description of the push notification.',
          secondTooltip:
            'Limit of 120 characters will be more efficient for your campaign.',
        },
      },
      {
        field: 'iconWithUpload',
        options: {
          label: 'Notification Icon',
          tooltip: true,
          tooltipContent:
            'Enter the URL of the notification icon or upload an icon.',
        },
      },
      {
        field: 'imageWithUpload',
        options: {
          label: 'Notification Image',
          tooltip: true,
          tooltipContent:
            'Enter the URL of the notification image or upload an image.',
        },
      },
      {
        field: 'actionButtons',
        options: {
          label: 'Action Buttons',
          tooltip: false,
        },
      },
      { field: 'redirectUrl', options: { placeholder: '' } },
    ],
    additionalFields: {
      fields: [],
    },
    mutualFields: ['scheduling', 'target'],
    config: [
      { field: 'name', isPush: true },
      { field: 'startEndDate' },
      { field: 'devices' },
    ],
    buttons: [
      { field: 'preview' },
      { field: 'goTest', isPush: true },
      { field: 'goLive', isPush: true },
    ],
  },
  UPCOMING: {
    mutualFields: ['target'],
  },
  RECOMMENDATION: {
    title: 'Recommendation',
    mainField: [
      {
        field: 'actionButtons',
        options: {
          label: 'Action Buttons',
          tooltip: false,
        },
      },
      {
        field: 'hidePrice',
        options: {
          label: 'Hide Product Price on Push Message',
          tooltip: true,
          tooltipContent:
            'If you check this option, price of the products will not be shown in the push notifications.',
        },
        isPush: true,
      },
      { field: 'pushRecommendations' },
    ],
    additionalFields: {
      fields: [],
    },
    mutualFields: ['scheduling', 'target'],
    config: [
      { field: 'name', isPush: true },
      { field: 'startEndDate' },
      { field: 'devices' },
    ],
    buttons: [
      { field: 'preview', isPush: true },
      { field: 'goTest', isPush: true },
      { field: 'goLive', isPush: true },
    ],
  },
  FLY: {
    title: 'Fly Mode',
    mainField: [
      { field: 'pushRecommendations' },
      {
        field: 'text',
        options: {
          label: 'Description',
          name: 'text',
          tooltip: true,
          tooltipContent: 'Enter the description of the push notification.',
          placeholder: '{{event:desc}}',
        },
      },
      {
        field: 'iconWithUpload',
        options: {
          label: 'Notification Icon',
          tooltip: true,
          tooltipContent:
            'Enter the URL of the notification icon or upload an icon..',
          placeholder: '{{event:icon}}',
        },
      },
      {
        field: 'imageWithUpload',
        options: {
          label: 'Notification Image',
          tooltip: true,
          tooltipContent:
            'You can choose to send one static image to promote your campaign with URL or File Upload option, instead of sending product images automatically.',
          placeholder: '{{event:image}}',
        },
      },
      {
        field: 'actionButtons',
        options: {
          label: 'Action Buttons',
          tooltip: false,
        },
      },
      { field: 'redirectUrl', options: { placeholder: '{{event:targetURL}}' } },
    ],
    additionalFields: {
      fields: [],
    },
    config: [
      { field: 'name', isPush: true },
      { field: 'startEndDate' },
      { field: 'devices' },
    ],
    buttons: [
      { field: 'preview' },
      { field: 'goTest', isPush: true },
      { field: 'goLive', isPush: true },
    ],
  },
};

export const pushList = [
  'ABANDONED_CART',
  'LAST_VISIT_ALTERNATIVES',
  'LAST_VISIT_REMINDER',
  'NICE_TO_MEET_YOU',
  'REPLENISHMENT',
  'NEW_COMERS',
  'TOP_SELLERS',
  'ORDER_FOLLOW_UP',
  'BIRTHDAY',
  'WE_MISSED_YOU',
  'CHURN',
  'PRICE_DROP',
  'BACK_IN_STOCK',
  'UPCOMING',
];

export const pushTypes = {
  PUSH_PERMISSION: 'PUSH_PERMISSION',
  PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
  UPCOMING: 'UPCOMING',
  RECOMMENDATION: 'RECOMMENDATION',
  FLY: 'FLY',
};

pushList.forEach(push => {
  pushTypes[push] = push;
  initialStates[push] = {
    title: campaignInitialData.filter(cmp => {
      return cmp.type === push;
    })[0].name,
    mainField: [
      {
        field: 'iconWithUpload',
        options: {
          label: 'Notification Icon',
          tooltip: true,
          tooltipContent:
            'Enter the URL of the notification icon or upload an icon.',
        },
      },
      {
        field: 'imageWithUpload',
        options: {
          label: 'Notification Image',
          tooltip: true,
          tooltipContent:
            'You can choose to send one static image to promote your campaign with URL or File Upload option, instead of sending product images automatically.',
        },
      },
      {
        field: 'hideInformation',
        options: {
          label: 'Hide Product Information on Push Message',
          tooltip: true,
          tooltipContent:
            "If you check this option, products' will not be shown in the push notifications.",
        },
        isPush: true,
      },
      {
        field: 'hidePrice',
        options: {
          label: 'Hide Product Price on Push Message',
          tooltip: true,
          tooltipContent:
            'If you check this option, price of the products will not be shown in the push notifications.',
        },
        isPush: true,
      },
      {
        field: 'actionButtons',
        options: {
          label: 'Action Buttons',
          tooltip: false,
        },
      },
      { field: 'pushLimitedRecommendations', isPush: true },
    ],
    config: [
      { field: 'name', isPush: true, disabled: true },
      { field: 'devices' },
    ],
    additionalConfig: campaignInitialData.filter(cmp => {
      return cmp.type === push;
    })[0].params,
    buttons: [
      { field: 'preview', isPush: true },
      { field: 'goTest', isPush: true },
      { field: 'goLive', isPush: true },
    ],
  };
  if (push === 'UPCOMING') {
    initialStates[push].mutualFields = ['target'];

    initialStates[push].mainField.splice(0, 0, {
      field: 'pushDescription',
      options: {
        label: 'Description',
        name: 'pushDescription',
        tooltip: true,
        required: true,
        tooltipContent: 'Enter the description of the push permission.',
      },
    });
    initialStates[push].mainField.push({
      field: 'redirectUrl',
      options: { placeholder: '' },
    });

    const hideIndex = initialStates[push].mainField.findIndex(
      cur => cur.field === 'hidePrice',
    );
    initialStates[push].mainField.splice(hideIndex, 1);
  }
});

export const pushAlgorithmTitles = mailType => {
  let title;
  let key;
  let campaignTitle;
  let description;
  switch (mailType) {
    case 'ABANDONED_CART':
      title = 'Abandoned Cart';
      key = false;
      campaignTitle = 'Abandoned Cart';
      description =
        'Reminds your customers about the items they left in their carts';
      break;
    case 'LAST_VISIT_ALTERNATIVES':
      title = 'Smart Offers';
      key = 'RECOMMENDATION_SMART_OFFERS';
      campaignTitle = 'Last Visit Alternatives';
      description =
        'Lets your customers know about the most relevant products based on their browsing history';
      break;
    case 'LAST_VISIT_REMINDER':
      title = 'Last Visited Product';
      key = 'RECOMMENDATION_LAST_VISIT_REMINDER';
      campaignTitle = 'Last Visit Reminder';
      description =
        'Reminds your customers about the products they visited before';
      break;
    case 'NICE_TO_MEET_YOU':
      title = 'Spotlights';
      key = 'RECOMMENDATION_SOURCE_ALL_PRODUCTS';
      campaignTitle = 'Nice to meet you';
      description =
        'Welcomes your new customers with the most popular products of the day';
      break;
    case 'REPLENISHMENT':
      title = 'Replenishment Product';
      key = 'RECOMMENDATION_REPLENISHMENT_PRODUCTS';
      campaignTitle = 'Replenishment';
      description =
        'Reminds your customers to repurchase the product based on the purchase frequency of the products';
      break;
    case 'NEW_COMERS':
      title = 'New Arrivals';
      key = 'RECOMMENDATION_NEW_COMERS';
      campaignTitle = 'New Arrivals';
      description =
        'Lets your customers know about the new products on your online store';
      break;
    case 'TOP_SELLERS':
      title = 'Top Sellers';
      key = 'RECOMMENDATION_TOP_SELLERS';
      campaignTitle = 'Top Sellers';
      description = 'Display customer-favorites of the week';
      break;
    case 'ORDER_FOLLOW_UP':
      title = 'Purchase Alternatives';
      key = false;
      campaignTitle = 'Order Follow Up';
      description =
        'Offers customers purchase alternatives for their last purchases';
      break;
    case 'WE_MISSED_YOU':
      title = 'Smart Offers';
      key = 'RECOMMENDATION_SMART_OFFERS';
      campaignTitle = 'We missed you';
      description =
        'Reminds the most relevant products to customers who haven’t been visiting online store for a while';
      break;
    case 'CHURN':
      title = 'Smart Offers';
      key = 'RECOMMENDATION_SMART_OFFERS';
      campaignTitle = 'Churn';
      description =
        "Offers the most relevant products to customers who made purchases but haven't been visiting online store for a while";
      break;
    case 'PRICE_DROP':
      title = 'Price Drop';
      key = false;
      campaignTitle = 'Price Drop';
      description =
        'Lets your customers know about the discounts on the products they were previously interested in';
      break;
    case 'FLY':
      title = 'Event Data';
      key = 'RECOMMENDATION_EVENT_DATA';
      campaignTitle = 'FLY Mode';
      break;
    case 'BIRTHDAY':
      title = 'Smart Offers';
      key = 'RECOMMENDATION_SMART_OFFERS';
      campaignTitle = 'Birthday';
      description =
        'Wish your customers a happy birthday with a bundle that includes personalised offers, new arrivals and weekly discounts';
      break;
    case 'RECOMMENDATION':
      title = 'Smart Offers';
      key = 'RECOMMENDATION_SMART_OFFERS';
      campaignTitle = 'Recommendation Campaign';
      break;
    case 'PUSH_NOTIFICATION':
      title = 'Smart Offers';
      key = 'RECOMMENDATION_SMART_OFFERS';
      campaignTitle = 'Bulk / Segmented Campaign';
      break;
    case 'BACK_IN_STOCK':
      title = 'Back in Stock';
      key = false;
      campaignTitle = 'Back in Stock Campaign';
      description =
        'Lets your customers know about the back in stock on the your products they were previously interested in.';
      break;
    case 'UPCOMING':
      title = 'Upcoming Reminder';
      key = false;
      campaignTitle = 'Upcoming Reminder';
      description = 'Reminds the customers about their favorite games';
      break;
    default:
      return 'Push Algorithm';
  }
  return { title, key, campaignTitle, description };
};
