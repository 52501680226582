/**
 * @author Bilal Çınarlı
 **/

import React from "react";

class IconPalette extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={this.state.width}
        height={this.state.height}
      >
        <g>
          <path
            fill={this.state.color}
            d="M436.9,127.5c-110.3-52.1-202.2,55.2-245.1,0c-15.3-21.4,21.4-61.3,0-88.9c-15.3-18.4-52.1-15.3-73.5,0
C53.9,84.6,11,158.1,11,240.9C11,375.7,121.3,486,256.1,486c113.4,0,208.4-76.6,235.9-180.8C504.3,271.5,516.6,167.3,436.9,127.5z
M102.9,256.2c-18.4,0-30.6-12.3-30.6-30.6s12.3-30.6,30.6-30.6s30.6,12.3,30.6,30.6S121.3,256.2,102.9,256.2z M164.2,378.8c-18.4,0-30.6-12.3-30.6-30.6s12.3-30.6,30.6-30.6s30.6,12.3,30.6,30.6S182.6,378.8,164.2,378.8z M286.8,440c-18.4,0-30.6-12.3-30.6-30.6s12.3-30.6,30.6-30.6s30.6,12.3,30.6,30.6S305.1,440,286.8,440z M378.7,317.5c-33.7,0-61.3-27.6-61.3-61.3s27.6-61.3,61.3-61.3s61.3,27.6,61.3,61.3S412.4,317.5,378.7,317.5z"
          />
        </g>
      </svg>
    );
  }
}

export default IconPalette;
