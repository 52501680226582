"use strict";

import React from "react";
import { connect } from "react-redux";

import { searchWizardActions, uiActions } from "../../actions";

import ExcludesSearchCategory from "./excludes-search-category";
import ExcludesSearchBrand from "./excludes-search-brands";
import ExcludesSearchKeyword from "./excludes-search-keyword";

const exclusionTypes = {
  category: ExcludesSearchCategory,
  brand: ExcludesSearchBrand,
  keyword: ExcludesSearchKeyword
};

class SearchExcludes extends React.Component {
  constructor(props) {
    super(props);

    this.handleExclusionRemove = this.handleExclusionRemove.bind(this);
    this.handleExclusionChange = this.handleExclusionChange.bind(this);
    this.handleExclusionSelectChange = this.handleExclusionSelectChange.bind(this);
    this.handleExclusionAdd = this.handleExclusionAdd.bind(this);
    this.getExclusions = this.getExclusions.bind(this);
  }
  getExclusions() {
      let searchCriteriaItem = this.props.searchCriteria.find(item=>item.properties.type.toLowerCase()===this.props.assetType.toLowerCase() && item.properties.isMobile === this.props.isMobile);
      if (searchCriteriaItem && searchCriteriaItem.properties && searchCriteriaItem.properties.type) {
          let device = this.props.isMobile ? 'mobile' : 'pc';
          return this.props.excludes && this.props.excludes[device] && this.props.excludes[device][searchCriteriaItem.properties.type] || [];
      }
      return [];
  }
  handleExclusionRemove(exclusionId) {
    let modifiedExclusions;

    modifiedExclusions = this.getExclusions().filter(exclude => {
      return exclude.id !== exclusionId;
    });
    let removedExclusion = this.getExclusions().find(exclude => {
          return exclude.id === exclusionId;
      });
    searchWizardActions.removeExclusion(modifiedExclusions, this.props.isMobile, removedExclusion ? removedExclusion.type : null);
    uiActions.formEdited();
  }

  handleExclusionChange(fields, exclusionId, options) {
    let oldExcludes = this.getExclusions(),
      inputs = fields.querySelectorAll(".criteria-field"),
      values = {},
      excludes = {};

    if (options && Object.keys(options).length > 0) {
      excludes = oldExcludes.filter(exclude => {
        if (exclude.type !== options.reset) {
          return true;
        }
        return exclude.id === exclusionId;
      });
    } else {
      excludes = oldExcludes;
    }

    inputs.forEach(item => {
      values[item.name] = {
        value: item.value
      };
    });

    for (let i = 0; i < excludes.length; i++) {
      if (excludes[i].id === exclusionId) {
        excludes[i].values = values;
        break;
      }
    }

    searchWizardActions.addExclusion(excludes, this.props.isMobile);
    uiActions.formEdited();
  }

  handleExclusionSelectChange(fieldName, newValues, exclusionId) {
    let excludes = this.getExclusions(),
    values = {};
    values[fieldName] = {
      value: []
    };

    let items = [];
    if (newValues) {
      newValues.forEach(item => {
        items.push(item.value);
      });
      values[fieldName].value = items;
      for (let i = 0; i < excludes.length; i++) {
        if (excludes[i].id === exclusionId) {
          excludes[i].values = values;
          break;
        }
      }
    }
    searchWizardActions.addExclusion(excludes, this.props.isMobile);
    uiActions.formEdited();
  }

  handleExclusionAdd(type) {
    let current = this.getExclusions();
    current = current.concat({ id: Math.random(), type: type, values: {} });
    searchWizardActions.addExclusion(current, this.props.isMobile);
  }

  render() {
    let subComponents = {};
    let allComponents = [];
    this.getExclusions().forEach(exclude => {
      if (exclude.type) {
        if (subComponents[exclude.type]) {
          if (exclude.type === 'category') {
            let addedExclusions = exclude.values && exclude.values.categoryId && exclude.values.categoryId.value || [];
              subComponents[exclude.type][0].values.categoryId.value.concat(addedExclusions);
          } else if (exclude.type === 'brand') {
              let addedExclusions = exclude.values && exclude.values.brand && exclude.values.brand.value || [];
              subComponents[exclude.type][0].values.brand.value.concat(addedExclusions);
          } else if (exclude.type === 'keyword') {
              let addedExclusions = exclude.values && exclude.values.keyword && exclude.values.keyword.value || [];
              subComponents[exclude.type][0].values.keyword.value.concat(addedExclusions);
          }
        } else {
          subComponents[exclude.type] = [exclude];
        }
      }
    });
    Object.keys(subComponents).forEach(subComponent => {
      let items = subComponents[subComponent];
      items.forEach((item, ind) => {
        let Component = exclusionTypes[item.type];
        let renderedComponent = (
          <Component
            key={item.id}
            {...item}
            onExclusionRemove={this.handleExclusionRemove}
            onExclusionChange={this.handleExclusionChange}
            onExclusionAdd={this.handleExclusionAdd}
            onExclusionSelectChange={this.handleExclusionSelectChange}
            subComponents={subComponents[item.type]}
          />
        );
        if (subComponent === "custom" || ind === 0) {
          allComponents.push(renderedComponent);
        }
      });
    });
    if (this.getExclusions().length > 0) {
      return (
        <ol className="exclusion-list criteria-list wizard-items-container">
          {allComponents}
        </ol>
      );
    } else {
      return "";
    }
  }
}



const mapStatesToProps = store => ({
  searchCriteria: store.searchWizard.criteria,
  excludes: store.searchWizard.excludes,
  typedExcludes: store.searchWizard.typedExcludes
});

export default connect(mapStatesToProps)(SearchExcludes);
