import React, { useEffect, useState } from 'react';

import Select from 'react-select';
import { fetchAttributeByType } from '../../modules/ds/ajax';
import { operatorObjToArray } from './utils/utils';
import { decodeHtml } from '../../system/string';
import { t } from '../../system/ui';
import { ErrorText } from './module-exports';

import {
  attributeOperators,
  operatorColumns,
} from '../../constants/datamaps/dynamic-segmentation';

/**
 * @name CriteriaFilterAttribute
 * @description Component that renders the attribute filter
 * @param {object} criteriaData - The criteria data
 * @param {string} filterType - The filter type
 * @param {array} statements - The statements
 * @param {function} callbackFunc - The callback function
 * @returns {JSX.Element}
 */
const criteriaFilterCategory = ({
  // eslint-disable-next-line no-unused-vars
  criteriaData,
  filterType,
  statements,
  callbackFunc,
}) => {
  const [selectedAttribute, setSelectedAttribute] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [fetchedAttributes, setFetchedAttributes] = useState([]);

  const defaultFilterData = {
    dataType: operatorColumns.attributeOperator, // Using the value of the selected option as the dataType, only for attribute type
    column: '',
    operator: 'is',
    value: '',
  };
  if (statements?.[filterType].length === 0) {
    callbackFunc(filterType, [defaultFilterData]);
  }

  const triggerCallbackFunc = () => {
    const filterData = [
      {
        dataType: operatorColumns.attributeOperator, // Using the value of the selected option as the dataType, only for attribute type
        column: selectedOption,
        operator: 'is',
        value: selectedAttribute.value,
      },
    ];

    callbackFunc(filterType, filterData);
  };
  const onChange = () => {
    switch (selectedOption) {
      case 'productColor':
        fetchAttributeByType('productColor').then(response => {
          setFetchedAttributes(
            response.map(item => {
              return {
                value: item,
                label: decodeHtml(item),
                title: decodeHtml(item),
              };
            }),
          );
        });
        break;
      case 'productSize':
        fetchAttributeByType('productSize').then(response => {
          setFetchedAttributes(
            response.map(item => {
              return {
                value: item,
                label: decodeHtml(item),
                title: decodeHtml(item),
              };
            }),
          );
        });
        break;
      case 'productGender':
        fetchAttributeByType('productGender').then(response => {
          setFetchedAttributes(
            response.map(item => {
              return {
                value: item,
                label: decodeHtml(item),
                title: decodeHtml(item),
              };
            }),
          );
        });
        break;
      default:
        break;
    }

    triggerCallbackFunc(selectedOption, selectedAttribute);
  };

  useEffect(() => {
    const statement = statements?.[filterType]?.[0];

    setSelectedOption(statement?.column || defaultFilterData.column);
    const defaultAttribute = statement
      ? statement?.value
      : defaultFilterData.value;
    setSelectedAttribute(defaultAttribute);
  }, []);

  useEffect(() => {
    onChange();
  }, [selectedAttribute, selectedOption]);

  return (
    <div className='rbs-filter-field'>
      <div className='filter-operator'>
        <span className='filter-operator-title'>Options*</span>
        <Select
          value={selectedOption}
          options={operatorObjToArray(attributeOperators)}
          name='options'
          clearable={false}
          searchable={false}
          onChange={val => {
            setSelectedOption(val.value);
            setSelectedAttribute('');
          }}
          className='criteria-field one-whole'
          openOnClick
        />
      </div>
      <div className='filter-connector' />
      <div className='filter-operator'>
        <span className='filter-operator-title --capitalize'>
          {selectedOption === 'productGender'
            ? 'Gender'
            : selectedOption === 'productSize'
            ? 'Size'
            : selectedOption === 'productColor'
            ? 'Color'
            : 'Attribute*'}
        </span>
        <Select
          value={selectedAttribute}
          options={fetchedAttributes}
          name='attributes'
          clearable={false}
          searchable
          onChange={val => setSelectedAttribute(val)}
          className={`criteria-field one-whole ${
            selectedAttribute ? '' : 'has-error'
          }`}
          openOnClick={false}
          placeholder={t('Select or text')}
        />
        {!selectedAttribute && <ErrorText />}
      </div>
    </div>
  );
};

export default criteriaFilterCategory;
