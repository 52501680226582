/**
 * Created by mehmetyurtar on 4/18/18.
 */
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import { isMultiAccountUser } from "../modules/auth/user";
import { getMultiUserUsers, deleteMultiUser } from "../modules/account/ajax";
import { modalActions, uiActions } from "../actions";
import Icon from "../components/icon";
import Icons from "../components/icons";
import { t } from "../system/ui";
import CreateUser from "../components/admin/multi-createuser";
import EditUser from "../components/admin/multi-edituser";
import UserAccountList from "../components/admin/user.accounts";

const preventFunc = e => {
  let confirmationMessage = "o/";
  e.returnValue = confirmationMessage;
  return confirmationMessage;
};

class User_Accounts extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    uiActions.resetPage();
    uiActions.addPageClass("sidebar-is-hidden");
  }

  componentWillReceiveProps(newProps) {
    if (this.props.user !== newProps.user) {
      this.getUsers(newProps.user);
    }

    if (newProps.switchedUser !== this.props.switchedUser) {
      this.props.router.push({
        pathname: "/welcome"
      });
    }
  }

  render() {
    return [
      <div className="user-accounts-header">
        <div className="user-accounts-header-titles">
          <Link to="/user/accounts">{t("Select Your Account")}</Link>
          <Link to="/user/users" className="user-accounts-header--active">
            {t("Manage Users")}
          </Link>
        </div>
      </div>,
      <div className="user-accounts">
        <UserAccountList user={this.props.user} />
      </div>
    ];
  }
}

const MapStateToProps = store => ({
  user: store.user.user,
  switchedUser: store.switchedUser.switchedUser
});

export default connect(MapStateToProps)(User_Accounts);
