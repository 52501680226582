import React from 'react';
import { connect } from 'react-redux';
import { t } from '../../system/ui';

import Icons from '../icons';

class IncludesDiscounted extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <li className='wizard-criterion' ref='inputs'>
        <span className='criteria-type'>
          <Icons name='percentage' color='#787878' />
        </span>
        <div className='wizard-criterion-text'>
          <h3 className='wizard-criterion-title'>{t('Discounted Products')}</h3>
          <p className='wizard-criterion-description'>
            {t('Include discounted products in algorithm.')}
          </p>
        </div>
        <a
          onClick={this.props.onInclusionRemove.bind(null, this.props.id)}
          className='cancel-action recommend-filter-cancel discounted-cancel'
        >
          <Icons name='crossCircle' />
        </a>
      </li>
    );
  }
}

const mapStatesToProps = store => ({
  includes: store.wizard.includes,
});

export default connect(mapStatesToProps)(IncludesDiscounted);
