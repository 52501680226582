/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { t } from "../../../../system/ui";

import { wizardActions } from "../../../../actions";

import Icons from "../../../../components/icons";

class StylesButton extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    e.preventDefault();

    this.props.isStylesVisible
      ? wizardActions.hideStyles()
      : wizardActions.showStyles();
  }

  render() {
    return (
      <button onClick={this.toggle} className="secondary-action">
        <Icons name="palette" />
        {t("Styles")}
      </button>
    );
  }
}

const MapStatesToProps = store => ({
  isStylesVisible: store.wizard.isStylesVisible
});

export default connect(MapStatesToProps)(StylesButton);
