/**
 * @author Bilal Cinarli
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { t } from '../system/ui';

import { notificationActions } from '../actions';

class Notification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.startTimer = this.startTimer.bind(this);

    this.timeout = false;
  }

  componentDidMount() {
    this.open();
  }

  componentWillReceiveProps(newProps) {
    if (!this.state.isVisible && newProps) {
      this.open();
    }
  }

  close() {
    this.setState({
      isVisible: false,
    });

    if (this.props.onClose) {
      this.props.onClose();
    }

    clearTimeout(this.timeout);

    notificationActions.closeNotification();
  }

  open() {
    this.setState({
      isVisible: true,
    });

    this.startTimer();

    if (this.props.onOpen) {
      this.props.onOpen();
    }
  }

  startTimer() {
    let duration = 5000;

    if (this.props.timer) {
      duration = this.props.timer;
    }

    if (duration > 0) {
      this.timeout = setTimeout(() => {
        this.close();
      }, duration);
    }
  }

  render() {
    return this.state.isVisible ? (
      <div
        key={Math.random()}
        className={classNames(
          'notification fadeInDown animated',
          this.props.className,
        )}
      >
        <div className='notification-content'>
          {this.props.title ? (
            <h3 className='notification-content-title'>
              {t(this.props.title)}
            </h3>
          ) : (
            ''
          )}

          <div className='notification-content-text'>
            {this.props.content
              ? (() => {
                  let Content = t(this.props.content);

                  return <Content />;
                })()
              : this.props.children}
          </div>

          <a className='notification-close' onClick={this.close}>
            X
          </a>
        </div>
      </div>
    ) : (
      false
    );
  }
}

Notification.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

Notification.defaultProps = {
  title: '',
};

export default connect()(Notification);
