/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import { getCampaignPage } from 'modules/campaigns/data';
import {
  uiActions,
  campaignActions,
  reportActions,
  filterActions,
} from '../actions';
import Icon from '../components/icon';
import StatusFilters from '../components/filters/status-filters';
import {
  csSearchParams,
  recommendationSortingTypes as sortingTypes,
  itemsPerScroll,
} from '../components/reports/utils';
import Tooltip from '../components/tooltip';
import WidgetAllReportStats from '../components/widget/report-stats.all';
import {
  getAccount,
  getApiKey,
  getSwitchAccountUser,
  getToken,
  isMultiAccountUser,
  isSwitchAccount,
  getRegion,
  isSuperUser,
  hasOnlyDynamicBundles,
} from '../modules/auth/user';
import {
  getActiveCampaigns,
  getCampaignsByStatus,
} from '../modules/campaigns/ajax';
import { getRangedReports } from '../modules/reports/ajax';
import { getEndPoint } from '../system/api';
import { queryDate } from '../system/date';
import { setTitle } from '../system/document';
import { t } from '../system/ui';

class AllRecommendationReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaigns: [],
      _campaigns: [],
      scrollCount: 0,
      deviceStatusFilteredCampaigns: [],
      reports: {},
      count: 0,
      showOptions: false,
      sort: 'DESC',
      sortType: 'revenue',
      toggleIcon: 'preferences',
      isLoading: true,
      recommendationProps: props,
    };

    this.changeStatus = this.changeStatus.bind(this);
    this.changeMedium = this.changeMedium.bind(this);

    this.increaseScrollCount = this.increaseScrollCount.bind(this);
    this.sortBy = this.sortBy.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.toggleOptions = this.toggleOptions.bind(this);
    this.exportAll = this.exportAll.bind(this);
  }

  componentDidMount() {
    setTitle(t('View All Reports'));

    campaignActions.filterActiveCampaigns();
    uiActions.isLoading();
    getActiveCampaigns(response => {
      if (response) {
        // Set All Campaigns here, there is no medium filter at this state, so call sortBy directly.
        this.setState(
          {
            _campaigns: response,
            deviceStatusFilteredCampaigns: response,
          },
          () => {
            this.sortBy('statusOrMediumChange');
          },
        );
      }
    });

    const debouncedScroll = debounce(() => {
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        this.increaseScrollCount();
      }
    }, 350);

    window.onscroll = debouncedScroll;
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (newProps.status !== this.props.status) {
      this.changeStatus(newProps.status, newProps.medium);
    }

    if (newProps.medium !== this.props.medium) {
      this.changeMedium(newProps.medium);
    }

    if (newProps.update === true) {
      this.sortBy('statusOrMediumChange', false, false, newProps.range);
      this.state.recommendationProps = newProps;
    }
  }

  getFilteredMediums = (campaigns, value) => {
    const visibleCampaigns = campaigns;
    let search = ['ALL'];

    search = search.concat(value);

    if (search.indexOf('PCTABLET') > -1) {
      search = search.concat(['PC', 'TABLET', 'PCMOBILETABLET']);
    }

    if (search.indexOf('MOBILE') > -1) {
      search = search.concat(['MOBILETABLET']);
      search = search.concat(['PCMOBILETABLET']);
    }

    if (search.indexOf('ANDROID') > -1 || search.indexOf('IOS') > -1) {
      search = search.concat(['ANDROIDIOS']);
    }

    if (search.length === 1) {
      search = [
        'ALL',
        'PCTABLET',
        'PC',
        'TABLET',
        'PCMOBILETABLET',
        'MOBILETABLET',
        'MOBILE',
        'ANDROID',
        'IOS',
        'ANDROIDIOS',
      ];
    }

    const filteredMediums = visibleCampaigns.filter(item => {
      return search.indexOf(item.device) > -1;
    });

    return filteredMediums;
  };

  exportAll() {
    const globalApiKey =
      getApiKey() !== undefined ? `?apiKey=${getApiKey()}` : '';
    const endpoint = getEndPoint('export/all/campaign') + globalApiKey;
    const instanceId = '';
    let exportUrl = `${endpoint}&end=${queryDate(
      this.state.recommendationProps.range[1],
    )}&start=${queryDate(
      this.state.recommendationProps.range[0],
    )}&interval=total&limit=2000&instanceid=${instanceId}&authToken=${getToken()}`;
    if (isSwitchAccount() && !isMultiAccountUser()) {
      exportUrl += `&switchUser=${getSwitchAccountUser(true).username}`;
    }
    if (isMultiAccountUser() && isSwitchAccount()) {
      exportUrl += `&switchAccount=${getAccount().accountId.trim()}`;
    }
    exportUrl += `&switchRegion=${getRegion()}`;
    window.open(exportUrl);
  }

  increaseScrollCount() {
    if (
      document.location.pathname.indexOf('reports/all/recommendations') > -1
    ) {
      this.setState(
        prevState => ({
          scrollCount: prevState.scrollCount + 1,
        }),
        () => {
          this.sortBy('scroll', false, false);
        },
      );
    }
  }

  changeStatus(status, medium) {
    let tempMedium = medium;
    if (medium === 'ALL') {
      tempMedium = [];
    }
    uiActions.isLoading();
    reportActions.emptyCampaignReports();
    getCampaignsByStatus(status, allCampaigns => {
      // Set All Campaigns here, than make a medium filter and set deviceMediumFiltered state and then cal sortBy
      const deviceStatusFilteredCampaigns = this.getFilteredMediums(
        allCampaigns,
        tempMedium,
      );
      this.setState(
        { deviceStatusFilteredCampaigns, _campaigns: allCampaigns },
        () => {
          this.sortBy('statusOrMediumChange');
        },
      );
    });
  }

  changeMedium(value) {
    let tempValue = value;
    if (value === 'ALL') {
      tempValue = [];
    }
    // eslint-disable-next-line react/no-access-state-in-setstate
    const finalList = this.getFilteredMediums(this.state._campaigns, tempValue);
    this.setState(
      {
        deviceStatusFilteredCampaigns: finalList,
      },
      () => {
        // Call SortBy
        this.sortBy('statusOrMediumChange');
      },
    );
    // Take all campaigns here than manipulate it with medium and set deviceMediumFiltered here than call sortBy
  }

  sortBy(source, eventParam, e, range) {
    filterActions.filtersUpdated();
    if (e) {
      e.preventDefault();
    }
    let sortType;
    let sortOrder;
    let scrollCount;
    let deviceStatusFilteredCampaigns;
    let { campaigns } = this.state;
    const matchedCampaigns = [];
    const search = this.refs.search && this.refs.search.value;

    const isFiltered = hasOnlyDynamicBundles();

    if (search) {
      this.state.deviceStatusFilteredCampaigns.forEach(item => {
        if (
          item.scenarioName.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          item.instanceId.toLowerCase().indexOf(search.toLowerCase()) > -1
        ) {
          matchedCampaigns.push(item);
        }
      });
      deviceStatusFilteredCampaigns = matchedCampaigns;
    } else {
      deviceStatusFilteredCampaigns = this.state.deviceStatusFilteredCampaigns;
    }

    if (
      source === 'statusOrMediumChange' ||
      source === 'search' ||
      source === 'scroll'
    ) {
      sortType = this.state.sortType;
      sortOrder = this.state.sort;
      scrollCount = source === 'scroll' ? this.state.scrollCount : 0;
    } else {
      // source is checkbox selected
      sortType = eventParam;
      scrollCount = 0;
      if (this.state.sortType !== sortType) {
        sortOrder = 'DESC';
      } else {
        sortOrder = this.state.sort === 'ASC' ? 'DESC' : 'ASC';
      }
    }

    if (source !== 'scroll') {
      campaigns = [];
    }

    const reportIds = deviceStatusFilteredCampaigns.map(cmp => {
      return cmp.instanceId;
    });

    const boundaries = {};
    if (range) {
      boundaries.start = queryDate(range[0]);
      boundaries.end = queryDate(range[1]);
    } else {
      boundaries.start = queryDate(this.props.range[0]);
      boundaries.end = queryDate(this.props.range[1]);
    }

    if (deviceStatusFilteredCampaigns.length) {
      if (csSearchParams.indexOf(sortType) < 0) {
        // Server side sorting
        if (
          itemsPerScroll * (scrollCount + 1) - itemsPerScroll <
          deviceStatusFilteredCampaigns.length
        ) {
          uiActions.isLoading();
          getRangedReports(
            reportIds,
            boundaries,
            campaignReports => {
              let newCampaigns = Object.keys(campaignReports).map(
                reportCampaignId => {
                  const targetCampaign = deviceStatusFilteredCampaigns.filter(
                    cmp => {
                      return cmp.instanceId === reportCampaignId;
                    },
                  );
                  return targetCampaign[0];
                },
              );

              if (isFiltered) {
                newCampaigns = newCampaigns.filter(item => {
                  return (
                    item.templateId === 'HUR_8' || item.templateId === 'T16'
                  );
                });
              }

              this.setState(
                prevState => ({
                  reports: {
                    ...prevState.reports,
                    ...campaignReports,
                  },
                  campaigns: campaigns.concat(newCampaigns),
                  count: isFiltered
                    ? campaigns.concat(newCampaigns).length
                    : deviceStatusFilteredCampaigns.length,
                  sort: sortOrder,
                  sortType,
                  scrollCount,
                  showOptions: false,
                  toggleIcon: 'preferences',
                  isLoading: false,
                }),
                () => {
                  uiActions.isLoaded();
                },
              );
            },
            {
              items: isFiltered ? 1000 : itemsPerScroll,
              page: scrollCount + 1,
              sortBy: sortType,
              reverse: sortOrder === 'DESC',
            },
            true,
          );
        } else {
          uiActions.isLoaded();
        }
      } else {
        // Client Side Sorting
        const campaignsByDate = [...deviceStatusFilteredCampaigns].sort(
          (a, b) => {
            if (sortOrder === 'DESC') {
              return (
                b.limitations.activeDates.startDate -
                a.limitations.activeDates.startDate
              );
            }
            return (
              a.limitations.activeDates.startDate -
              b.limitations.activeDates.startDate
            );
          },
        );
        const campaignIdsToGetReport = [];
        const latestExistingCampaignIndex = campaigns.length;
        const newCampaigns = campaignsByDate.slice(
          latestExistingCampaignIndex,
          latestExistingCampaignIndex + itemsPerScroll,
        );
        let campaignsToShow = campaigns.concat(newCampaigns);

        campaignsToShow.forEach(campaign => {
          if (!this.state.reports[campaign.instanceId]) {
            campaignIdsToGetReport.push(campaign.instanceId);
          }
        });

        if (isFiltered) {
          campaignsToShow = campaignsToShow.filter(item => {
            return item.templateId === 'HUR_8' || item.templateId === 'T16';
          });
        }

        this.setState(
          {
            campaigns: campaignsToShow,
            count: campaignsToShow.length,
            sort: sortOrder,
            sortType,
            scrollCount,
            showOptions: false,
            toggleIcon: 'preferences',
          },
          () => {
            if (campaignIdsToGetReport.length) {
              uiActions.isLoading();
              getRangedReports(
                campaignIdsToGetReport,
                boundaries,
                campaignReports => {
                  uiActions.isLoaded();
                  this.setState(prevState => ({
                    reports: {
                      ...prevState.reports,
                      ...campaignReports,
                    },
                  }));
                },
                {},
                true,
              );
            }
          },
        );
      }
    } else {
      uiActions.isLoaded();
      this.setState({
        reports: {},
        campaigns: [],
        count: deviceStatusFilteredCampaigns.length,
        sort: sortOrder,
        sortType,
        scrollCount,
        showOptions: false,
        toggleIcon: 'preferences',
      });
    }
  }

  searchHandler() {
    this.setState(
      {
        scrollCount: 0,
      },
      () => {
        this.sortBy('search');
      },
    );
  }

  toggleOptions(e) {
    e.preventDefault();

    this.setState(prevState => ({
      showOptions: !prevState.showOptions,
      toggleIcon: !prevState.showOptions ? 'cross' : 'preferences',
    }));
  }

  render() {
    const campaignsStatus = this.props.status;
    let statusText = '';
    if (campaignsStatus === 'ACTIVE') {
      statusText = 'Live';
    } else if (campaignsStatus === 'TEST') {
      statusText = 'Test';
    } else {
      statusText = 'Archived';
    }
    statusText += ' Recommendation';

    let exportAllButton;
    const monthDiff = this.state.recommendationProps.range[1].diff(
      this.state.recommendationProps.range[0],
      'months',
    );
    if (monthDiff >= 3) {
      exportAllButton = (
        <div>
          <Tooltip
            content={t(
              'This may take a while, please wait. Your report will be downloaded when it is ready',
            )}
            alignment='top'
            placement='bottom'
          >
            <a
              style={{
                float: 'left',
                marginTop: '-40px',
              }}
              className='date-filter current'
              onClick={this.exportAll}
            >
              {t('Export All')}
            </a>{' '}
          </Tooltip>
        </div>
      );
    } else {
      exportAllButton = (
        <div>
          <a
            style={{
              float: 'left',
              marginTop: '-40px',
            }}
            className='date-filter current'
            onClick={this.exportAll}
          >
            {t('Export All')}
          </a>
        </div>
      );
    }

    return (
      <div>
        <div className='page-filters has-filter-form'>
          <StatusFilters />
        </div>
        <div className='page-options page-options-sort-search page-options-with-date'>
          <label className='item is-stacked is-search'>
            <Icon name='magnify' />
            <input
              type='text'
              placeholder={t('Search')}
              className='text-field'
              onChange={this.searchHandler}
              ref='search'
            />
          </label>
          <div className='data-preferences'>
            <a href='#' className='toggler' onClick={this.toggleOptions}>
              <Icon name={this.state.toggleIcon} />
              <span>Sort</span>
            </a>
            <ul
              className={
                !this.state.showOptions
                  ? 'data-preferences-options is-hidden'
                  : 'data-preferences-options'
              }
            >
              {sortingTypes.map(sortingType => {
                return (
                  <li
                    className='data-preferences-option'
                    key={sortingType.type}
                    onClick={this.sortBy.bind(this, 'event', sortingType.type)}
                  >
                    <a href='#' className='data-preferences-option-item'>
                      {sortingType.text}
                      {this.state.sortType === sortingType.type ? (
                        this.state.sort !== 'DESC' ? (
                          <i className='icon-caret-up viewall-sorting' />
                        ) : (
                          <i className='icon-caret-down viewall-sorting' />
                        )
                      ) : (
                        ''
                      )}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div />
        {this.state.count > 0 ? (
          <div>
            <h2 className='page-title'>
              {t('All Recommendations')} ({this.state.count})
            </h2>
            {exportAllButton}
            <div className='page-content-wrapper'>
              {this.state.campaigns.map(campaign => {
                return (
                  <div
                    className='widget page-content-block'
                    key={Math.random()}
                  >
                    {isSuperUser() ? (
                      <h3 className='widget-page'>
                        {t(getCampaignPage(campaign))} -{' '}
                        <span
                          style={{ color: '##c0bebe', fontWeight: 'normal' }}
                        >
                          {campaign.instanceId}
                        </span>
                      </h3>
                    ) : (
                      <h3 className='widget-page'>
                        {t(getCampaignPage(this.props))}
                      </h3>
                    )}
                    <h2 className='widget-title'>{campaign.scenarioName}</h2>

                    <WidgetAllReportStats
                      campaign={campaign}
                      campaignType={this.props.campaignType}
                      stats={this.state.reports[campaign.instanceId]}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          this.state.isLoading === false && (
            <div className='no-report'>
              <p className='no-report-info'>
                {t('no-campaign-info', statusText)}
              </p>
            </div>
          )
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  ui: store.ui.ui,
  status: store.campaigns.status,
  medium: store.campaigns.medium,
  range: store.date.range,
  update: store.filters.update,
  reset: store.filters.reset,
});

export default connect(mapStatesToProps)(AllRecommendationReport);
