/**
 * @author Bilal Cinarli
 */

"use strict";

import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import { uiActions, dateActions, filterActions } from "../../actions";

import { t, openModal } from "../../system/ui";

import { getInsight, getProductsDetails } from "../../modules/insights/ajax";
import Export from "../../components/export";
import { insightsExportCount } from "../../modules/auth/user";

import RelatedProductDetailsModal from "./products-related-details.modal";
import RelatedProductDetails from "./products-related-details";
import InsightPlaceHolder from "./widgets/placeholder";

class RelatedProducts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      ui: "isLoading"
    };

    this.listDetails = this.listDetails.bind(this);
    this.exportFile = this.exportFile.bind(this);
  }

  componentDidMount() {
    //dateActions.updateRangeAlias("daily");
    this.listProducts();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.query !== newProps.query) {
      this.listProducts(newProps.query);
    }
  }

  getInsightData(endpoint, query, range) {
    uiActions.isLoading();
    this.setState({
      products: [],
      ui: "isLoading"
    });
    getInsight(endpoint, query, response => {
      let insightProducts = response !== "" ? response : [];
      insightProducts.forEach(currentInsightItem => {
        let insightProduct = currentInsightItem;
        insightProduct.id = insightProduct.record.productId || "";
        insightProduct.productId = insightProduct.record.productId || "";
        insightProduct.inStock = insightProduct.record.inStock || false;
        insightProduct.productCategory = insightProduct.record.categories
          ? insightProduct.record.categories.join(",")
          : "";
        insightProduct.productBrand = insightProduct.record.brand || "";
        insightProduct.productName = insightProduct.record.name || "";
        insightProduct.productOldPrice = insightProduct.record.oldPrice || "";
        insightProduct.productPrice = insightProduct.record.price || "";
        insightProduct.productURL = insightProduct.record.url || "";
        insightProduct.productImage = insightProduct.record.image || "";
      });
      this.setState({ products: insightProducts });
      if (this.props.hasProducts) {
        this.props.hasProducts(this.props.id, insightProducts.length);
      }
      uiActions.isLoaded();
      this.setState({
        ui: "isLoaded"
      });
    });
  }

  listProducts(query) {
    let _query = query || this.props.query;
    this.getInsightData(this.props.endpoint, _query);
  }

  listDetails(e) {
    e.preventDefault();
    const content = () => (
      <RelatedProductDetailsModal
        products={this.state.products}
        insightName={this.props.name}
        endpoint={this.props.endpoint}
        query={this.props.query}
        insightId={this.props.id}
        showFilters
      />
    );

    openModal({
      title: this.props.name,
      subtitle: this.props.desc,
      className: 'insights-modal',
      content,
    });
  }

  exportFile(e) {
    e.preventDefault();

    let _query = this.props.query;
    _query.export = true;
    _query.export_mail = true;
    _query.items = insightsExportCount() ? insightsExportCount() : _query.items;
    let name = this.props.name.replace(/\s/g, "-") + "-Report";

    let content = () => (
      <Export
        name={name}
        query={_query}
        insightsId={this.props.id}
        endpoint={this.props.endpoint}
      />
    );

    uiActions.openModal({ title: t("Export Products"), content });
  }

  render() {
    if (this.state.products.length === 0 && this.state.ui !== "isLoading") {
      return null;
    }

    return (
      <div className="related-insights">
        {this.state.products.length > 0 ? (
          <div>
            <div className="product-insights">
              <h3 className="widget-title">{t(this.props.name)}</h3>
              <p className="widget-description">{t(this.props.desc)}</p>
              <a
                className="secondary-action view-more"
                onClick={this.listDetails}
              >
                {t("Details")}
              </a>
              <a
                className="secondary-action ghost export"
                onClick={this.exportFile}
              >
                <i className="icon-pdf" /> {t("Export")}
              </a>
              <RelatedProductDetails
                ui={this.state.ui}
                products={this.state.products}
                name={this.props.name}
                desc={this.props.desc}
                limit="4"
                endpoint={this.props.endpoint}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="product-insights">
              <h3 className="widget-title">{t(this.props.name)}</h3>
              <p className="widget-description">{t(this.props.desc)}</p>
              <a
                className="secondary-action view-more"
                onClick={this.listDetails}
              >
                {t("Details")}
              </a>
              <div
                className={classNames(
                  "product-insights-details product-insights-nodata"
                )}
              >
                {this.state.ui === "isLoading" && <InsightPlaceHolder />}
                {this.state.ui !== "isLoading" && (
                  <div>
                    <i className="icon-info" />
                    {t("You do not have enough data for this insight.")}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  update: store.filters.update,
  reset: store.filters.reset
});

export default connect(mapStatesToProps)(RelatedProducts);
