import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Icons from '../../../../icons';
import { fixedInputtedNumber } from '../common/common-functions';
import { getBrands } from '../../../../../modules/account/ajax';
import { decodeHtml } from '../../../../../system/string';

const BrandAccessor = ({ data, setAttributes, onRemove }) => {
  const [brandOptions, setBrandOptions] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [weight, setWeight] = useState('');

  useEffect(() => {
    getBrands().then(response => {
      const sorted = response.sort();
      const options = [];
      sorted.forEach(item => {
        options.push({
          value: item,
          label: decodeHtml(item),
        });
      });
      setBrandOptions(options);
    });

    if (data?.value) {
      setSelectedBrand({
        value: data.value,
        label: data.value,
      });
    }

    if (data?.weight) {
      setWeight(data.weight);
    }
  }, []);

  const onWeightChange = value => {
    const fixedValue = fixedInputtedNumber(1, 250, value).toString();
    setWeight(fixedValue);
  };

  // set attributes
  useEffect(() => {
    setAttributes({
      id: data.id,
      productAccessor: 'brand',
      value: selectedBrand?.value,
      weight,
    });
  }, [selectedBrand, weight]);

  return (
    <div className='sfy-attribute-field sfy-related-products'>
      <div className='sfy-attribute-field-icon'>
        <Icons name='star' color='#7A7A7C' height='16' width='16' />
      </div>
      <div className='sfy-attribute-field-select'>
        <Select
          value={selectedBrand}
          options={brandOptions}
          onChange={selected => {
            setSelectedBrand(selected);
          }}
          searchable
          placeholder='Select Brand'
          className='sfy-attribute-field-select-input'
          clearable={false}
        />
      </div>
      <div className='sfy-attribute-field-weight'>
        <span className='sfy-attribute-field-weight-label'>Weight :</span>
        <input
          placeholder='Type Weight'
          type='number'
          className='gc-weight-text-input'
          value={weight}
          onChange={e => {
            onWeightChange(e.target.value);
          }}
        />
      </div>
      <div
        className='sfy-attribute-field-remove'
        onClick={() => onRemove(data.id)}
      >
        <Icons name='crossCircle' color='#D31116' height='18' width='18' />
      </div>
    </div>
  );
};

export default BrandAccessor;
