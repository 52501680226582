import { isEmpty } from 'lodash';
import { customerProfileConstants } from '../../constants/constants';

export const CustomerProfileReducers = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_CUSTOMER_PROFILE_LIST':
      return {
        ...state,
        customerProfileList: payload,
      };
    case 'SET_CUSTOMER_PROFILE':
      return {
        ...state,
        customerProfile: {
          user: {
            userName: payload?.userName,
            userId: payload?.userId,
            device: payload?.device,
            browser: payload?.browser,
            email: payload?.email,
            phoneNumber: payload?.phoneNumber,
            gender: payload?.gender || '',
            birthDate: payload?.birthDate || '',
            notifications: {
              mail: payload?.notifications?.mail || 'NONE',
              push: payload?.notifications?.push || 'NONE',
              sms: payload?.notifications?.sms || 'NONE',
            },
          },
          notes: payload?.notes || '',
          segments: {
            smartSegments: payload?.includingUserSegment?.SMART || [],
            dynamicSegments: payload?.includingUserSegment?.DYNAMIC || [],
            ruleBasedSegments: payload?.includingUserSegment?.RBS || [],
          },
          stats: {
            firstVisit: payload?.firstVisit,
            lastVisit: payload?.lastVisit,
            firstOrder: payload?.firstOrder,
            lastOrder: payload?.lastOrder,
            registration: payload?.registration,
            totalVisit: payload?.totalVisit,
          },
          lastProductHistory: {
            lastPurchasedProducts: payload?.lastPurchasedProducts || [],
            lastVisitedProduct: payload?.lastVisitedProduct || {},
            latestAddedToBasket: payload?.latestAddedToBasket || {},
          },
          wishlistActivity: {
            latestWishlistProducts: payload?.latestWishlistProducts || [],
            wishlistVisitedProducts: payload?.wishlistVisitedProducts || [],
          },
          returnsProducts: {
            returnsRecord: payload?.returnsRecord || [],
          },
          topBrands: {
            topBrands: payload?.topBrands || [],
          },
          lastProductRecommendations: {
            latestSeenRecommendationCampaign:
              payload?.latestSeenRecommendationCampaign || [],
            latestClickedRecommendationCampaign:
              payload?.latestClickedRecommendationCampaign || [],
          },
          categoryVisit: {
            topVisitedCategory: payload?.topVisitedCategory || [],
            topPurchasedCategory: payload?.topPurchasedCategory || [],
          },
          email: {
            emailDelivered: payload?.emailDelivered,
            emailClickRate: payload?.emailClickRate,
            emailConversionRate: payload?.emailConversionRate,
            emailLastClick:
              !isEmpty(payload?.emailLastClick) && payload.emailLastClick
                ? Object?.keys(payload?.emailLastClick)?.map(key => {
                    return {
                      name: key,
                      value: payload.emailLastClick[key],
                    };
                  })
                : {},
          },
          push: {
            pushDelivered: payload?.pushDelivered,
            pushClickRate: payload?.pushClickRate,
            pushConversionRate: payload?.pushConversionRate,
            pushLastClick: !isEmpty(payload?.pushLastClick)
              ? Object.keys(payload?.pushLastClick)?.map(key => {
                  return {
                    name: key,
                    value: payload.pushLastClick[key],
                  };
                })
              : {},
          },
        },
      };
    case 'SET_CUSTOMER_PROFILE_NOTES':
      return {
        ...state,
        customerProfile: {
          ...state.customerProfile,
          notes: payload,
        },
      };
    case 'RESET_CUSTOMER_PROFILE':
      return {
        ...state,
        customerProfile: customerProfileConstants,
      };
    default:
      return state;
  }
};
