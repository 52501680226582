"use strict";

import { createAction } from "../system/store";
import * as searchInsightsActions from "../constants/actions/search-insights";


export const openDetailTable = (tableName) => {
  createAction(searchInsightsActions.default.OPEN_DETAIL_TABLE, {tableName});
};

export const changeTab = (tabName) => {
  createAction(searchInsightsActions.default.TAB_CHANGED, {tabName});
};

export default{
  changeTab,
  openDetailTable
}