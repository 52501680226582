import React from 'react';
import SwitchButton from 'components/shared/switch-button';
import { Input, Select } from 'components/search/faceted-search/common';
import { capitalize } from 'system/string';
import { useDispatch, useSelector } from 'react-redux';
import { updateCampaignForm } from 'actions/faceted-search';
import CrossIcon from 'components/search/faceted-search/common/CrossIcon';

const Facets = () => {
  const dispatch = useDispatch();
  const state = useSelector(s => s.facetedSearch);
  const onSwitchToggle = facetName => {
    const { desktopFacetDefs } = state.campaignForm;
    const updatedFacets = [...desktopFacetDefs];
    const facetIndex = desktopFacetDefs.findIndex(
      f => f.property === facetName,
    );
    if (facetIndex > -1) {
      const facetObject = updatedFacets[facetIndex];
      updatedFacets[facetIndex] = {
        ...facetObject,
        enabled: !facetObject.enabled,
      };
    }
    dispatch(
      updateCampaignForm({
        desktopFacetDefs: updatedFacets,
        mobileFacetDefs: updatedFacets,
      }),
    );
  };
  const onCountChange = ({ currentTarget }, facetName) => {
    const { desktopFacetDefs } = state.campaignForm;
    const updatedFacets = [...desktopFacetDefs];
    const facetIndex = desktopFacetDefs.findIndex(
      f => f.property === facetName,
    );
    if (facetIndex > -1) {
      const facetObject = updatedFacets[facetIndex];
      updatedFacets[facetIndex] = {
        ...facetObject,
        limit: currentTarget.value,
      };
    }
    dispatch(
      updateCampaignForm({
        desktopFacetDefs: updatedFacets,
        mobileFacetDefs: updatedFacets,
      }),
    );
  };
  const getViewModeText = viewMode => {
    const matches = [
      {
        from: 'treeview',
        to: 'Treeview',
      },
      {
        from: 'picklist',
        to: 'Selection box',
      },
      {
        from: 'sliding',
        to: 'Sliding bar',
      },
    ];
    const found = matches.find(m => m.from === viewMode);
    return found ? found.to : viewMode;
  };
  const removeFacet = facet => {
    const updatedFacets = [...state.campaignForm.desktopFacetDefs].filter(
      f => f.property !== facet.property,
    );
    dispatch(
      updateCampaignForm({
        desktopFacetDefs: updatedFacets,
        mobileFacetDefs: updatedFacets,
      }),
    );
  };
  return (
    <div className="wizard-criterion faceted-search-white-card">
      {state.campaignForm.desktopFacetDefs
        .sort((a, b) => a.property.localeCompare(b.property))
        .map(facet => {
          return (
            <div className="faceted-search-white-card-row" key={facet.property}>
              <div className="faceted-search-white-card-row-wrapper">
                <SwitchButton
                  onToggle={() => onSwitchToggle(facet.property)}
                  isSwitchedOn={facet.enabled}
                />
                <span className="facet-type">{capitalize(facet.property)}</span>
              </div>
              <div className="faceted-search-white-card-row-wrapper">
                <div style={{display: 'none'}}>  {/* TODO facet view mode is closed with https://segmentify.atlassian.net/browse/BARCA-1101 issue */}
                  <Select name="facet-component" label="view">
                    <option value="treeview">
                      {getViewModeText(facet.viewMode)}
                    </option>
                  </Select>
                </div>
                <Input
                  inputProps={{
                    type: 'number',
                    min: -1,
                    max: 50,
                    value: facet.limit,
                  }}
                  label="count"
                  onChange={e => onCountChange(e, facet.property)}
                />
              </div>
              <div className="faceted-search-white-card-row-wrapper">
                <CrossIcon onClick={() => removeFacet(facet)} />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Facets;
