import React, { useEffect, useState } from 'react';
import Icons from '../../../icons';
import { t } from '../../../../system/ui';
import { TextField } from '../../../fields';
import Icon from '../../../icon';
import Tooltip from '../../../tooltip';

const Stocks = ({
  subComponents,
  onInputChange,
  onFilterRemove,
  addSubComponents,
  removeSubComponents,
  values,
  filterParams,
  id,
  segmentType,
  type,
}) => {
  const [state, setState] = useState(subComponents || []);
  const setValues = () => {
    const newState = subComponents.map((subComponent, index) => {
      if (index === 0) {
        return {
          ...subComponent,
          values: {
            field:
              typeof subComponent.values.field !== 'undefined'
                ? subComponent.values.field
                : 'stockCount',
            operation:
              typeof subComponent.values.operation !== 'undefined'
                ? subComponent.values.operation
                : 'GREATER',
            value:
              typeof subComponent.values.value !== 'undefined'
                ? subComponent.values.value
                : '',
          },
        };
      }
      return {
        ...subComponent,
        values: {
          operation:
            typeof subComponent.values.operation !== 'undefined'
              ? subComponent.values.operation
              : 'GREATER',
          value:
            typeof subComponent.values.value !== 'undefined'
              ? subComponent.values.value
              : '',
        },
      };
    });

    return setState(newState);
  };

  const onChange = (e, ind, subId) => {
    const { value, name } = e.target;

    const newState = state.map((subComponent, index) => {
      if (index === ind) {
        return {
          ...subComponent,
          values: {
            ...subComponent.values,
            [name]: value,
          },
        };
      }
      return subComponent;
    });

    setState(newState);

    onInputChange('stock', segmentType, newState[ind].values, subId);
  };

  useEffect(() => {
    setValues();
  }, [filterParams, values]);

  useEffect(() => {
    if (state[0].values.field !== 'stockCount') {
      subComponents.forEach((subComponent, index) => {
        if (index !== 0) {
          removeSubComponents('stock', segmentType, subComponent.id);
        }
      });
    }
  }, [state]);

  return (
    <div className='sfy-color'>
      <div className='sfy-color-sub'>
        <div className='sfy-color-main-item'>
          <div className='sfy-color-left'>
            <div className='sfy-color-icoText'>
              <span className='sfy-color-icon'>
                <Icon name='stock' color='#547494' />
              </span>
              <Tooltip
                content={
                  <span>
                    <div>
                      &emsp;Stock filter allows you to specify a range for the
                      stock counts of the products that will be included to the
                      recommendation.
                      <br /> <br />
                      &nbsp;<b>Count</b> Refers to the count of the product left
                      in the stock (Ex: Products with 50 stock or more).
                      <br /> <br />
                      &nbsp;<b>Ratio</b> Refers to the percentage of the product
                      left in the stock (Ex: Products with %50 in stock or
                      more).
                      <br /> <br />
                      &nbsp;<b>Status</b> Refers to the percentage of the
                      availability of the sizes per product (Ex: Products with
                      %50 in stock sizes or more).
                    </div>
                  </span>
                }
                alignment='top'
                newTooltip
                isInline
                className='sfy-color-tooltip'
              >
                <Icon name='info' />
              </Tooltip>
              <div className='sfy-color-titleSelectMain'>
                <div className='sfy-color-titleSelect'>
                  <span className='sfy-color-title'>{t('Stock')}</span>
                </div>
              </div>

              <label className='item item-field is-select'>
                <select
                  name='field'
                  className='one-whole criteria-field'
                  onChange={e => onChange(e, 0, id)}
                  value={state[0]?.values.field || ''}
                >
                  <option value='stockCount'>{t('Stock Count')}</option>
                  <option value='stockRatio'>{t('Stock Ratio')}</option>
                  <option value='stockStatus'>{t('Stock Status')}</option>
                </select>
              </label>
              <label className='item item-field is-select'>
                <select
                  name='operation'
                  className='one-whole criteria-field'
                  onChange={e => onChange(e, 0, id)}
                  value={state[0]?.values.operation || ''}
                >
                  <option value='GREATER'>{t('Greater Than')}</option>
                  <option value='LESS'>{t('Less Than')}</option>
                </select>
              </label>
              <TextField
                name='value'
                label={state[0]?.values.field === 'stockCount' ? '' : '%'}
                placeholder={
                  state[0]?.values.field === 'stockCount' ? 'Value' : ''
                }
                value={state[0]?.values.value}
                className={`item-field ${
                  state[0]?.values.field === 'stockCount' ? '' : 'sfy-price'
                }`}
                inputClassName={
                  state[0]?.values.field === 'stockCount'
                    ? 'one-whole criteria-field'
                    : 'one-whole criteria-field label-percent'
                }
                required
                onChange={e => onChange(e, 0, id)}
                type='number'
              />
            </div>
          </div>
          <div className='sfy-color-button-criteria'>
            {subComponents.length < 2 &&
              state[0]?.values.field === 'stockCount' && (
                <a
                  onClick={() => addSubComponents('stock', segmentType)}
                  className='sfy-color-actions'
                >
                  <Icons name='plusCircle' width='32' height='32' />
                </a>
              )}
            <a
              onClick={() => onFilterRemove(id, segmentType, type)}
              className='sfy-color-actions'
            >
              <Icons name='crossCircle' />
            </a>
          </div>
        </div>
        {subComponents.map((subComponent, ind) => {
          if (ind > 0) {
            return (
              <div className='sfy-color-sub-items' key={subComponent.id}>
                <div className='sfy-color-left'>
                  <div className='sfy-color-icoText'>
                    <div className='sfy-aligner-stock'>
                      <label className='item item-field is-select sfy-color-hidden'>
                        <select
                          name='field'
                          className='one-whole criteria-field'
                          onChange={e => onChange(e, 0, id)}
                          value={state[0]?.values.field || ''}
                        >
                          <option value='stockCount'>{t('Stock Count')}</option>
                          <option value='stockRatio'>{t('Stock Ratio')}</option>
                          <option value='stockStatus'>
                            {t('Stock Status')}
                          </option>
                        </select>
                      </label>
                      <label className='item item-field is-select'>
                        <select
                          name='operation'
                          className='one-whole criteria-field'
                          onChange={e => onChange(e, ind, subComponent.id)}
                          value={state[ind]?.values.field || 'GREATER'}
                        >
                          <option value='GREATER'>{t('Greater Than')}</option>
                          <option value='LESS'>{t('Less Than')}</option>
                        </select>
                      </label>

                      <TextField
                        name='value'
                        value={state[ind]?.values.value || ''}
                        label={
                          state[0]?.values.field === 'stockCount' ? '' : '%'
                        }
                        placeholder={
                          state[0]?.values.field === 'stockCount' ? 'Value' : ''
                        }
                        className={`item-field ${
                          state[0]?.values.field === 'stockCount'
                            ? ''
                            : 'sfy-price'
                        }`}
                        inputClassName={
                          state[0]?.values.field === 'stockCount'
                            ? 'one-whole criteria-field'
                            : 'one-whole criteria-field label-percent'
                        }
                        required
                        onChange={e => onChange(e, ind, subComponent.id)}
                        type='number'
                      />
                    </div>
                  </div>
                </div>
                <div className='sfy-color-button-criteria'>
                  {ind === subComponents.length - 1 && (
                    <a
                      onClick={() => addSubComponents('stock', segmentType)}
                      className='sfy-color-actions'
                    >
                      <Icons name='plusCircle' width='32' height='32' />
                    </a>
                  )}
                  <a
                    onClick={() =>
                      removeSubComponents('stock', segmentType, subComponent.id)
                    }
                    className='sfy-color-actions'
                  >
                    <Icons name='crossCircle' />
                  </a>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default Stocks;
