import React, { Component } from 'react';

import { engagementConfirmOnLeave } from '../actions/ui';
import { t } from '../system/ui';
import { setTitle } from '../system/document';
import { advancedConfig } from '../constants/datamaps/campaign';
import EditCampaign from '../modules/campaign/edit';

class EditSeeAll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 'See All',
      description:
        'With this campaign, the last category URLs of the product are dynamically added to the See All button.',
      separatedContent: [
        {
          field: 'buttonText',
          options: {
            label: 'Button Text',
            required: true,
            tooltip: false,
            tooltipContent: '',
            placeholder:
              'You can add {{category:name}} to use the product name in the See All button.',
          },
        },
      ],
      content: [],
      contentExtra: {
        buttons: [],
        fields: [
          {
            field: 'stylesOptions',
            options: {
              title: 'Button Style',
              buttonTextColor: { show: false },
              buttonBgColor: { show: false },
              bgColor: { show: true, label: 'Background Color', color: '#fff' },
              textColor: { show: true, label: 'Text Color', color: '#000' },
              previewButton: {
                show: true,
                label: 'Preview :',
                text: 'Explore More',
              },
            },
          },
          { field: 'timingOptions' },
          { field: 'audienceOptions' },
        ],
      },
      options: [
        { field: 'name' },
        { field: 'startEndDate' },
        { field: 'devices' },
        {
          field: 'pages',
          options: {
            hasPageUrlFilter: true,
            onlyPageUrlFilter: true,
          },
        },
        {
          field: 'advancedConfig',
          options: advancedConfig.SEE_ALL,
        },
      ],
      buttons: [
        { field: 'advancedConfigButton' },
        { field: 'goTest' },
        { field: 'goLive' },
      ],
    };
  }

  componentDidMount() {
    setTitle(t('Add See All'));
    engagementConfirmOnLeave(this);
  }

  render() {
    return (
      <EditCampaign
        type='SEE_ALL'
        title={this.state.title}
        description={this.state.description}
        separatedContent={this.state.separatedContent}
        content={this.state.content}
        contentExtra={this.state.contentExtra}
        options={this.state.options}
        buttons={this.state.buttons}
        campaignId={this.props.params.campaignId}
      />
    );
  }
}

export default EditSeeAll;
