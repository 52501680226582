/**
 * @fileOverview This file open the modal of the create multiuser account.
 * @requires react
 */
import React, { useReducer, useRef, useEffect, useState } from "react";

/**
 * Module dependencies
 */
import { modalActions, uiActions } from '../../actions';
import { getUserAccounts, createMultiUser } from '../../modules/account/ajax';
import UserAccountsList from './multi-accountselect';
import { t } from '../../system/ui';
import { TextField } from '../fields';

const initialState = {
  switchableAccounts: [],
  _switchableAccounts: [],
  selectedAccounts: [],
};

const UserReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_SWITCHABLE_ACCOUNTS':
      return {
        ...state,
        switchableAccounts: payload,
      };
    case 'SET_SWITCH_ACCOUNTS':
      return {
        ...state,
        _switchableAccounts: payload,
      };
    case 'SET_SELECTED_ACCOUNTS':
      return {
        ...state,
        selectedAccounts: payload,
      };
    default:
      return state;
  }
};

/**
 * This component creates new multiuser account.
 * @param {Object} user Details of active account.
 * @param {JSX.Element} context Send request to get active users.
 * @returns {JSX.Element}
 */
const CreateUser = ({ user, context }) => {
  const [state, userDispatcher] = useReducer(UserReducer, initialState);
  const [selectedModules, setSelectedModules] = useState([]);

  const { isLoaded, isLoading, showNotification } = uiActions;

  const form = useRef();

  useEffect(() => {
    isLoaded();
    getUserAccounts(
      { multiUserAccountId: user.account.accountId },
      response => {
        isLoaded();
        userDispatcher({
          type: 'SET_SWITCHABLE_ACCOUNTS',
          payload: response,
        });
        userDispatcher({
          type: 'SET_SWITCH_ACCOUNTS',
          payload: response,
        });
      },
    );
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    const { displayName, username } = form.current;
    const data = {};

    data.displayName = displayName.value;
    data.username = username.value;
    data.switchableAccounts = state.selectedAccounts;

    if (selectedModules && selectedModules.length <= 3) {
      data.privileges = selectedModules;
    }

    isLoading();
    createMultiUser(user.account, data, response => {
      isLoaded();
      if (response) {
        const notificationContent = () => (
          <div>
            {`${t('You have successfully created user:')} ${data.username}`}
          </div>
        );
        showNotification({ content: notificationContent });
        modalActions.closeModal();
        context.getUsers();
      }
    });
  };

  const searchAccounts = e => {
    const searchVal = e.target.value && e.target.value.toLowerCase();
    let filteredAccounts;
    if (searchVal) {
      filteredAccounts = state.switchableAccounts.filter(acc => {
        return (
          (acc.domain && acc.domain.toLowerCase().indexOf(searchVal)) > -1 ||
          (acc.description &&
            acc.description.toLowerCase().indexOf(searchVal)) > -1
        );
      });
    } else {
      filteredAccounts = state.switchableAccounts;
    }
    userDispatcher({
      type: 'SET_SWITCH_ACCOUNTS',
      payload: filteredAccounts,
    });
  };

  const toggleAccountSelect = accountId => {
    let { selectedAccounts } = state;
    if (selectedAccounts.indexOf(accountId) > -1) {
      selectedAccounts = selectedAccounts.filter(
        selectedAccount => selectedAccount !== accountId,
      );
    } else {
      selectedAccounts.push(accountId);
    }
    userDispatcher({
      type: 'SET_SELECTED_ACCOUNTS',
      payload: [...selectedAccounts],
    });
  };

  const selectAllAccounts = selectAll => {
    const selectedAccounts = [];
    if (selectAll) {
      state._switchableAccounts.forEach(account => {
        selectedAccounts.push(account.accountId);
      });
    }

    userDispatcher({
      type: 'SET_SELECTED_ACCOUNTS',
      payload: selectedAccounts,
    });
  };

  const onModulesChange = childSelectedModules => {
    setSelectedModules(childSelectedModules);
  };

  return (
    <div style={{ minWidth: '700px' }}>
      <form className='create-user' onSubmit={handleSubmit} ref={form}>
        <ol className='form-elements'>
          <li className='row pull-push'>
            <TextField
              name='displayName'
              className='item-stacked six-columns'
              label={t('Display Name')}
            />

            <TextField
              name='username'
              type='email'
              className='item-stacked six-columns'
              label={t('E-mail')}
            />
          </li>
          <UserAccountsList
            switchableAccounts={state._switchableAccounts}
            toggleAccountSelect={toggleAccountSelect}
            selectedAccounts={state.selectedAccounts}
            searchAccounts={searchAccounts}
            selectAllAccounts={selectAllAccounts}
            onModulesChange={onModulesChange}
          />
          <div className='user-accounts-select-button'>
            <button className='tertiary-action' type='submit'>
              {t('Create User')}
            </button>
          </div>
        </ol>
      </form>
    </div>
  );
};

export default CreateUser;
