export const useStatsFormatter = (data = {}, matchedObject) => {
  return Object?.entries(data)
    ?.map(([key, value]) => {
      return {
        key,
        value,
      };
    })
    .map(({ key, value }) => {
      return {
        classIdentifier: key,
        key: matchedObject[key],
        value,
      };
    });
};
