import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { uiActions, filterActions } from '../../actions';
import { resetToCustom } from '../../actions/date';
import {
  insightsExportCount,
  isInsightsCountDefined,
} from '../../modules/auth/user';
import { getInsight } from '../../modules/insights/ajax';
import { insightsQuery } from '../../modules/insights/data';
import getInsightsFields from '../../modules/transform/insightsFieldsTransform';
import { productUrl } from '../../modules/trendify/page';
import { dateUpdatedQuery } from '../../modules/trendify/utils';
import { queryDate } from '../../system/date';
import { localeString } from '../../system/string';
import { t } from '../../system/ui';
import Currency from '../currency';
import Export from '../export';
import Filters from '../filters/filters';
import TrendifyFilters from '../filters/trendify-filters-insights';
import Icon from '../icon';

class RelatedProductDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      query: this.props.query,
      ui: 'isLoading',
      range: this.props.range, // this is for reset func.
      rangeAlias: this.props.rangeAlias, // this is for reset func.
    };

    this.resetUpdate = this.resetUpdate.bind(this);
    this.exportFile = this.exportFile.bind(this);
  }

  componentDidMount() {
    this.listProducts(this.props.query);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.query !== newProps.query) {
      this.listProducts(newProps.query);
    }

    if (newProps.update === true) {
      this.resetUpdate(newProps, 'update');
    }

    if (newProps.reset === true) {
      this.resetUpdate(newProps, 'reset');
    }
  }

  componentWillUnmount() {
    resetToCustom(this.state.range, this.state.rangeAlias);
  }

  getInsightData(endpoint, query) {
    uiActions.isLoading();

    this.setState({
      products: [],
      ui: 'isLoading',
    });

    getInsight(endpoint, query, response => {
      const insightProducts = response !== '' ? response : [];
      insightProducts.forEach(currentInsightItem => {
        const insightProduct = currentInsightItem;
        insightProduct.id = insightProduct.record.productId || '';
        insightProduct.productId = insightProduct.record.productId || '';
        insightProduct.inStock = insightProduct.record.inStock || false;
        insightProduct.productCategory = insightProduct.record.categories
          ? insightProduct.record.categories.join(',')
          : '';
        insightProduct.productBrand = insightProduct.record.brand || '';
        insightProduct.productName = insightProduct.record.name || '';
        insightProduct.productOldPrice = insightProduct.record.oldPrice || '';
        insightProduct.productPrice = insightProduct.record.price || '';
        insightProduct.productURL = insightProduct.record.url || '';
        insightProduct.productImage = insightProduct.record.image || '';
      });

      this.setState({ products: insightProducts }, () => {
        window.dispatchEvent(new Event('resize'));
      });
      uiActions.isLoaded();
      this.setState({
        ui: 'isLoaded',
      });
    });
  }

  listProducts(query) {
    const newQuery =
      query ||
      dateUpdatedQuery({
        range: this.props.range,
        rangeAlias: this.props.rangeAlias,
        analyticsQuery: insightsQuery,
      });
    if (isInsightsCountDefined()) {
      newQuery.items = isInsightsCountDefined();
    }
    this.getInsightData(this.props.endpoint, newQuery);
  }

  resetUpdate(newProps, action) {
    filterActions.filtersUpdated();

    // TODO: It is supposed to do something but it blocks filter resetting.
    // filterActions.filtersReset();

    const { range } = newProps;
    const category = this.refs.pageFilters.querySelector(
      '[name=productCategory]',
    );
    const brand = this.refs.pageFilters.querySelector('[name=productBrand]');
    const product = this.refs.pageFilters.querySelector('[name=productName]');
    const minPrice = this.refs.pageFilters.querySelector('#minPrice');
    const maxPrice = this.refs.pageFilters.querySelector('#maxPrice');
    const device = this.refs.pageFilters.querySelector('[name=visitorDevice]');
    const stock = this.refs.pageFilters.querySelector('[name=productStock]');

    const query = dateUpdatedQuery({
      range,
      rangeAlias: newProps.rangeAlias,
      analyticsQuery: insightsQuery,
    });

    if (action === 'update') {
      query.minPrice = minPrice ? minPrice.value || query.minPrice : '';
      query.maxPrice = maxPrice ? maxPrice.value || query.maxPrice : '';
      query.category = category ? category.value : '';
      query.brand = brand ? brand.value : '';
      query.productId = product ? product.value.toLowerCase() : '';
      query.device = device ? device.value || 'all' : '';
      query.stock = stock ? stock.value || 'all' : '';
    }
    if (isInsightsCountDefined()) {
      query.items = isInsightsCountDefined();
    }

    if (
      this.refs.pageFilters.querySelector(
        "#weekpicker-firstWeek input[name='date']",
      )
    ) {
      query.startDate = queryDate(range[0].startOf('week'));
    }

    if (
      this.refs.pageFilters.querySelector(
        "#weekpicker-secondWeek input[name='date']",
      )
    ) {
      query.endDate = queryDate(range[1].startOf('week'));
    }

    this.setState({ query });
    this.getInsightData(this.props.endpoint, query);
  }

  exportFile() {
    const { query } = this.state;
    query.export = true;
    query.export_mail = true;
    query.items = insightsExportCount() ?? query.items;
    const name = `${this.props.insightName.replace(/\s/g, '-')}-Report`;

    const content = () => (
      <Export
        name={name}
        query={query}
        insightsId={this.props.insightId}
        endpoint={this.props.endpoint}
        range={this.props.range}
      />
    );

    uiActions.openModal({
      title: t('Export Products'),
      className: 'insight-export-modal',
      content,
    });
  }

  render() {
    return (
      <div
        className='product-insights filter-inside'
        style={{ minWidth: '1100px' }}
        ref='pageFilters'
      >
        <div>
          <Filters
            limitRange='months'
            isInsights
            visibleUpdate
            hideDateAliases={false}
            hidePicker={false}
            includedFields={getInsightsFields(this.props.insightName)}
          >
            <TrendifyFilters
              isInsights
              includedFields={getInsightsFields(this.props.insightName)}
              filterValues={this.state.query}
            />
          </Filters>
          {this.state.ui === 'isLoaded' && (
            <a
              className='secondary-action ghost export'
              onClick={this.exportFile}
              style={{ top: '30px', right: '42px', zIndex: '99999' }}
            >
              <i className='icon-pdf' /> {t('Export')}
            </a>
          )}
        </div>
        <div
          className={classNames(
            'product-insights-details export-content-wrapper',
            {
              'page-progress': this.state.ui === 'isLoading',
            },
          )}
        >
          <table className='data-table'>
            <thead>
              <tr>
                <th colSpan='4'>Product</th>
                <th className='cell-group'>#Orders</th>
                <th colSpan='4' className='cell-group'>
                  Bought Together With
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.products.map((product, index) => {
                if (
                  (this.props.limit && index > this.props.limit) ||
                  !product.related
                ) {
                  return;
                }
                return (
                  <tr key={`product-${product.id}`}>
                    <td>
                      <a
                        href={productUrl(product.productURL)}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={productUrl(product.productImage)}
                          className='product-image'
                          alt={`Product ${product.id}.`}
                        />
                      </a>
                    </td>
                    <td>
                      <h3 className='product-name'>
                        <a
                          href={productUrl(product.productURL)}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {product.productName}
                        </a>
                      </h3>
                      <p className='product-category'>
                        {product.productCategory}
                      </p>
                      <p className='product-id'>{product.productId}</p>
                    </td>
                    <td
                      className={classNames('product-stock', {
                        'product-in-stock': product.inStock,
                      })}
                    >
                      {product.inStock ? (
                        <Icon name='check-circle' />
                      ) : (
                        <span className='out-of-stock' />
                      )}
                      {product.inStock ? t('In Stock') : t('Out of Stock')}
                    </td>
                    <td className='product-price'>
                      {product.productOldPrice && (
                        <span className='product-old-price'>
                          <Currency currency={product.productOldPrice} />
                        </span>
                      )}
                      <span className='product-sale-price'>
                        <Currency currency={product.productPrice} />
                      </span>
                    </td>
                    <td className='cell-group'>
                      <div className='product-stats'>
                        {product.stats
                          .filter(stat => stat.name === 'purchase')
                          .map(stat => (
                            <div
                              className='product-stat'
                              key={`${stat.name}-${product.productId}`}
                            >
                              <p className='product-stat-value'>
                                {stat.type === 'currency' ? (
                                  <Currency currency={stat.value} />
                                ) : (
                                  localeString(stat.value)
                                )}
                                {stat.type === 'ratio' ? '%' : ''}
                              </p>
                            </div>
                          ))}
                      </div>
                    </td>
                    <td className='cell-group'>
                      <a
                        href={productUrl(product.related.record.url)}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          src={productUrl(product.related.record.image)}
                          className='product-image'
                          alt={`Product related ${product.id}.`}
                        />
                      </a>
                    </td>
                    <td>
                      <h3 className='product-name'>
                        <a
                          href={productUrl(product.related.record.url)}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {product.related.record.name}
                        </a>
                      </h3>
                      <p className='product-category'>
                        {product.related.record.category}
                      </p>
                      <p className='product-id'>
                        {product.related.record.productId}
                      </p>
                    </td>
                    <td
                      className={classNames('product-stock', {
                        'product-in-stock': product.related.record.inStock,
                      })}
                    >
                      {product.related.record.inStock ? (
                        <Icon name='check-circle' />
                      ) : (
                        <span className='out-of-stock' />
                      )}
                      {product.related.record.inStock
                        ? t('In Stock')
                        : t('Out of Stock')}
                    </td>
                    <td className='product-price'>
                      {product.related.record.oldPrice && (
                        <span className='product-old-price'>
                          <Currency
                            currency={product.related.record.oldPrice}
                          />
                        </span>
                      )}

                      <span className='product-sale-price'>
                        <Currency currency={product.related.record.price} />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  update: store.filters.update,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  reset: store.filters.reset,
});

export default connect(mapStatesToProps)(RelatedProductDetailsModal);
