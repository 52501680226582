import React, { createContext } from 'react';
import { useSelector } from 'react-redux';

import { UserGuidance } from './user-guidance.reducer';

import { GuidanceActions } from './constants';

/**
 * Context object for managing user guidance.
 * @type {React.Context}
 */
const UserGuidanceContext = createContext();

/**
 * Provides user guidance to the application.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The rendered UserGuidanceProvider component.
 */
export const UserGuidanceProvider = ({ children }) => {
  const { displayName, privileges, username, ...rest } = useSelector(
    ({ user }) => user.user,
  );

  UserGuidance({
    type: GuidanceActions.USER_IDENTIFY,
    payload: { displayName, privileges, username, ...rest },
  });

  return (
    <UserGuidanceContext.Provider>{children}</UserGuidanceContext.Provider>
  );
};
