import React from "react";
class IconCheckboxCircleEmpty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#C2C9D1" 
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg className="svg-icon" width={this.state.width} height={this.state.height} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={this.state.color} d="M5.89319 0.0253658C4.63127 0.147241 3.37952 0.66267 2.39944 1.45994C2.10999 1.69353 1.65295 2.15564 1.42444 2.44255C0.842993 3.17126 0.365649 4.15642 0.167603 5.04001C0.0431885 5.59353 0.0152588 5.86521 0.0152588 6.49998C0.0152588 7.13474 0.0431885 7.40642 0.167603 7.95994C0.368189 8.8613 0.858228 9.86169 1.45999 10.6006C1.69358 10.89 2.15569 11.347 2.4426 11.5756C3.17131 12.157 4.15647 12.6344 5.04006 12.8324C5.59358 12.9568 5.86526 12.9847 6.50002 12.9847C7.13479 12.9847 7.40647 12.9568 7.95999 12.8324C8.62268 12.6851 9.49104 12.3144 10.0623 11.9361C11.05 11.2785 11.8092 10.4152 12.3297 9.35642C12.6471 8.70896 12.8274 8.12751 12.9416 7.38865C13.0026 6.97986 13.0026 6.02009 12.9416 5.6113C12.8274 4.87244 12.6471 4.29099 12.3297 3.64353C11.9895 2.9529 11.6112 2.42478 11.0653 1.8865C10.5295 1.35583 10.0395 1.00798 9.35647 0.672827C8.71663 0.357983 8.12249 0.172631 7.41409 0.0634518C7.09417 0.0152092 6.22581 -0.00510311 5.89319 0.0253658ZM7.26174 1.68845C8.84358 1.95505 10.1487 2.91482 10.8672 4.33923C11.1998 5.00447 11.3598 5.70525 11.3598 6.49998C11.3598 7.1449 11.2836 7.59431 11.0703 8.1783C10.5854 9.50876 9.50881 10.5853 8.17834 11.0703C7.59436 11.2836 7.14495 11.3597 6.50002 11.3597C5.8551 11.3597 5.40569 11.2836 4.8217 11.0703C3.49124 10.5853 2.41467 9.50876 1.92971 8.1783C1.71643 7.59431 1.64026 7.1449 1.64026 6.49998C1.64026 5.85505 1.71643 5.40564 1.92971 4.82166C2.41467 3.49119 3.49124 2.41462 4.8217 1.92966C5.22034 1.78494 5.56057 1.70623 6.09377 1.64021C6.27405 1.61736 7.02815 1.65037 7.26174 1.68845Z" />
      </svg>
    );
  }
}

export default IconCheckboxCircleEmpty;
