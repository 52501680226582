import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { uiActions } from "actions";
import { t } from "system/ui";
import { FACETED_SEARCH_NAME } from "constants/faceted-search";
import classnames from "classnames";
import {
  AddFacetModal
} from "components/search/faceted-search/campaign/general/facets/AddFacet";
import { useSelector } from "react-redux";
import RightSideWizard
  from "components/search/faceted-search/campaign/general/RightSideWizard";
import General from "./general";
import Language from "./language";

const FacetedSearchCampaign = ({ router: { params } }) => {
  const modes = {
    new: t("New"),
    edit: t("Edit")
  };
  const tabs = {
    GENERAL: "general",
    LANGUAGE: "language"
  };
  const state = useSelector(s => s.facetedSearch);
  const { mode, instanceId } = params;
  const modeText = modes[mode];
  const [activeTab, setTabActive] = useState(tabs.GENERAL);
  const modalState = useSelector(s => s.facetedSearch.addFacetModal);
  useEffect(
    () => {
      uiActions.showBreadcrumb({
        home: {
          name: t("Search & Discovery"),
          icon: "search",
          slug: "/search"
        },
        faceted: {
          name: FACETED_SEARCH_NAME,
          slug: "/search/faceted"
        },
        current: {
          name: `${mode === "new" ? `${modeText} - ${FACETED_SEARCH_NAME}` : `${modeText} - ${state.campaignForm.name}`}`
        }
      });
    },
    [params.mode, state.campaignForm.name]
  );

  const tabClass = tabName =>
    classnames("faceted-search-tab", {
      "faceted-search-tab-active": activeTab === tabName
    });

  return (
    <>
      <div className="faceted-search-tabs">
        <button
          id="faceted-search-tab-general-button"
          className={tabClass(tabs.GENERAL)}
          type="button"
          onClick={() => setTabActive(tabs.GENERAL)}
        >
          {t("General")}
        </button>
        {/*<button*/}
        {/*  className={tabClass(tabs.LANGUAGE)}*/}
        {/*  type="button"*/}
        {/*  onClick={() => setTabActive(tabs.LANGUAGE)}*/}
        {/*>*/}
        {/*  {t('Language')}*/}
        {/*</button>*/}
      </div>
      {activeTab === tabs.GENERAL ? (
        <General mode={mode} instanceId={instanceId} />
      ) : (
        <Language />
      )}
      {modalState.isOpen && <AddFacetModal instanceId={instanceId || ""} />}
      <RightSideWizard mode={mode} />
    </>
  );
};

FacetedSearchCampaign.propTypes = {
  params: PropTypes.shape({
    mode: PropTypes.string.isRequired,
    instanceId: PropTypes.string
  }).isRequired,
  router: PropTypes.instanceOf(Object).isRequired
};

export default FacetedSearchCampaign;
