import Services from '../../../../service/Services';

class PromotionServices extends Services {
  constructor({ endPoint, paginationIsActive }) {
    super({ endPoint, paginationIsActive });
  }
}

export default new PromotionServices({
  endPoint: 'promotion/list',
  paginationIsActive: true,
});
