/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { connect } from 'react-redux';

import { trendsMap, statTypes } from '../../constants/datamaps';
import { campaignActions } from '../../actions';

import { t } from '../../system/ui';
import { clone } from '../../system/object';
import { localeString, localeStringMoney } from '../../system/string';

import { getCampaignReport } from '../../modules/reports/ajax';
import { transformCampaignReport } from '../../modules/transform';

import { findLongestStat } from '../reports/utils';

import Icon from '../icon';
import Currency from '../currency';
import Tooltip from '../tooltip';
import { getCampaignPage } from '../../modules/campaigns/data';

class WidgetStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: clone(trendsMap),
      segmentStats: [],
    };

    this.setDefaultReports = this.setDefaultReports.bind(this);
  }

  componentDidMount() {
    if (!this.props.withReport && !this.props.placeholderComponent) {
      getCampaignReport(
        this.props.instanceId,
        'lastSevenDays',
        campaignReport => {
          this.setState({
            stats: transformCampaignReport(
              campaignReport,
              this.props.selectedDevice,
              this.props.campaignType,
            ),
            segmentStats: campaignReport,
          });
          campaignActions.addCampaignRevenues({
            id: this.props.instanceId,
            revenue: transformCampaignReport(
              campaignReport,
              this.props.selectedDevice,
              this.props.campaignType,
            ).revenue.stats.all.count,
          });
        },
      );
    }
    if (this.props.report) {
      this.setDefaultReports(this.props.report, this.props.selectedDevice);
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    if (newProps.report !== this.props.report) {
      this.setDefaultReports(newProps.report, newProps.selectedDevice);
    }
    if (newProps.selectedDevice !== this.props.selectedDevice) {
      this.setDefaultReports(newProps.report, newProps.selectedDevice);
    }
  }

  setDefaultReports(report, selectedDevice) {
    this.setState({
      stats: transformCampaignReport(
        report,
        selectedDevice,
        this.props.campaignType,
      ),
      segmentStats: report,
    });
    campaignActions.addCampaignRevenues({
      id: this.props.instanceId,
      revenue: transformCampaignReport(
        report,
        selectedDevice,
        this.props.campaignType,
      ).revenue.stats.all.count,
    });
  }

  render() {
    const instance = {};
    let revenue = '0';
    let contRatio = '0';
    instance.inputs = this.props.inputs;
    if (this.props.isSegmentCampaign) {
      let segmentedVisitors = 0;
      this.state.segmentStats.forEach(stat => {
        if (stat.x === 'Segment Visitor') {
          segmentedVisitors = stat.y;
        }
      });
      return (
        <div className='widget-stats-segment'>
          <h4 className='widget-stat-title'>{t('Segmented Users')}</h4>
          <span className='widget-stat-data'>
            {localeString(segmentedVisitors)}
          </span>
          <div className='widget-stats-segment--icon'>
            <i className='icon-add-user' />
          </div>
        </div>
      );
    }

    const checkListForLongestStat = [
      'all',
      'contribution',
      'view',
      'leftArrowClick',
      'rightArrowClick',
      'conversion',
    ];

    const defaultPageTypes = [
      'Home Page',
      'Category Page',
      'Search Page',
      '404 Page',
      'Product Page',
      'Basket Page',
      'Checkout Success Page',
    ];
    const campaignPage = getCampaignPage(this.props);
    if (defaultPageTypes.includes(campaignPage)) {
      checkListForLongestStat.push('pageView', 'impressionRate');
    }

    const longestStat = findLongestStat(
      this.state.stats,
      checkListForLongestStat,
    );

    return (
      <div className='widget-stat-list'>
        <p>{t('Last 7 Days')}</p>
        <div className='widget-stats'>
          {Object.keys(this.state.stats).map((item, index) => {
            const stat = this.state.stats[item].stats;
            const type = statTypes[item];
            const className = `widget-stat stat-${item.toLowerCase()}`;

            if (item === 'revenue') {
              revenue = stat.all.count;
              contRatio = stat.contribution.ratio;
            }

            if (
              this.props.isKeywordCampaign &&
              (item === 'basket' || item === 'revenue')
            ) {
              return null;
            }

            return (
              <div className={className} ref={item} key={`${item}-${index}`}>
                <h4 className='widget-stat-title'>{t(type.name)}</h4>
                <p className='widget-stat-count' ref={`${item}Count`}>
                  {item !== 'widget'
                    ? type.currency === true
                      ? localeStringMoney(stat.all.count)
                      : localeString(stat.all.count)
                    : type.currency === true
                    ? localeStringMoney(stat.view.count)
                    : localeString(stat.view.count)}
                </p>
                <p className='widget-stat-type'>
                  {type.currency === true ? (
                    <Currency />
                  ) : (
                    <Icon name={type.icon} />
                  )}
                  {type.currency !== true ? t(type.unit) : ''}
                </p>
                {!this.props.isSearch && (
                  <p className='widget-stat-conversion'>
                    {type.name === 'Widget' && t('Impression')}
                    {type.name === 'Widget' && (
                      <span className='widget-stat-conversion-amount'>
                        {localeString(stat.all.count)}
                      </span>
                    )}
                  </p>
                )}

                {!this.props.isSearch &&
                  type.name === 'Interaction' &&
                  stat.leftArrowClick &&
                  stat.leftArrowClick.visible === true && (
                    <p className='widget-stat-conversion widget-stat-arrow-click-count'>
                      {t(stat.leftArrowClick.name)}
                      <span className='widget-stat-conversion-amount'>
                        {stat.leftArrowClick.count}
                      </span>
                    </p>
                  )}
                {!this.props.isSearch &&
                  type.name === 'Interaction' &&
                  stat.rightArrowClick &&
                  stat.rightArrowClick.visible === true && (
                    <p className='widget-stat-conversion widget-stat-arrow-click-count'>
                      {t(stat.rightArrowClick.name)}

                      <span className='widget-stat-conversion-amount'>
                        {stat.rightArrowClick.count}
                      </span>
                    </p>
                  )}

                {!this.props.isSearch && (
                  <p
                    style={{ bottom: '-25px' }}
                    className={`widget-stat-conversion ${
                      type.name !== 'Revenue' && item !== longestStat
                        ? 'widget-stat-conversion-bottom'
                        : ''
                    }`}
                  >
                    {type.name === 'Widget' ||
                    type.name === 'Interaction' ||
                    type.name === 'Basket'
                      ? t(`${type.name}Conversion`)
                      : t('Conversion')}
                    {stat.conversion && stat.conversion.tooltip && (
                      <span className='stat-tooltip'>
                        <Tooltip
                          content={stat.conversion.tooltip}
                          alignment='top'
                          newTooltip
                        >
                          <Icon name='info' />
                        </Tooltip>
                      </span>
                    )}
                    <span className='widget-stat-conversion-amount'>
                      {stat.conversion.ratio}%
                    </span>
                  </p>
                )}

                {!this.props.isSearch &&
                  defaultPageTypes.includes(campaignPage) && (
                    <p className='widget-stat-conversion'>
                      {type.name === 'Widget' && t('PageView')}
                      {type.name === 'Widget' && (
                        <span className='widget-stat-conversion-amount'>
                          {localeString(stat?.pageView?.count)}
                        </span>
                      )}
                    </p>
                  )}

                {!this.props.isSearch &&
                  defaultPageTypes.includes(campaignPage) && (
                    <p className='widget-stat-conversion'>
                      {type.name === 'Widget' && t('ImpressionRate')}
                      {type.name === 'Widget' && (
                        <span className='widget-stat-conversion-amount'>
                          {stat?.impressionRate?.ratio}%
                        </span>
                      )}
                    </p>
                  )}

                {type.name === 'Revenue' && (
                  <p
                    className={`widget-stat-conversion ${
                      item !== longestStat
                        ? 'widget-stat-conversion-bottom'
                        : ''
                    }`}
                  >
                    {t('Contribution Ratio')}
                    <span className='widget-stat-conversion-amount'>
                      {contRatio}%
                    </span>
                  </p>
                )}
              </div>
            );
          })}
        </div>

        <span className='widget-amount'>
          <Currency currency={revenue} />
        </span>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
});

export default connect(mapStatesToProps)(WidgetStats);
