import React from 'react';
import './ExperimentationCampaignTable.scss';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import ExperimentationActionButton from '../list/ExperimentationActionButton';
import ExperimentationVariationLabel from '../common/ExperimentationVariationLabel';
import Tooltip from '../../tooltip';
import Icons from '../../icons';

const ExperimentationCampaignTable = ({
  campaigns,
  onAddNewVariation,
  onRemoveVariation,
  variations,
  onCampaignChange,
  onRatioChange,
  setFieldTouched,
  mode,
  isCompleted,
  experimentId,
}) => {
  const domain = useSelector(
    state => state.switchedUser.switchedUser.account.domain,
  );
  const openUrlInNewTab = instanceId => {
    const url = `https://${domain}?_sgf_experiment=${experimentId}:${instanceId}`;
    window.open(url, '_blank').focus();
  };

  const disabled = !!mode;

  return (
    <div className='ab-table'>
      <div className='ab-head'>
        <div className='ab-head-left'>
          <small>Variation Name</small>
          <small>Campaign</small>
        </div>
        <div className='ab-head-right'>
          <span>Test Split</span>
        </div>
      </div>
      <div className='ab-body'>
        {variations.map((variation, index) => {
          const otherVariations = variations.filter(
            (variation, _index) => _index !== index,
          );
          const campaignOptions = campaigns.filter(
            campaign =>
              otherVariations.findIndex(
                variation => variation.instanceId === campaign.instanceId,
              ) < 0,
          );
          return (
            <div
              className='ab-body-item'
              key={`${variation.instanceId} ${index}`}
            >
              <div className='ab-body-item-left'>
                <div className='ab-body-item-variant'>
                  <ExperimentationVariationLabel
                    variationName={variation.variationName}
                    testSplit={variation.testSplit}
                    color={variation.color}
                    hideSplitRatio
                  />
                </div>
                <div className='ab-body-item-select'>
                  <Select
                    placeholder='Select Campaign'
                    options={campaignOptions.map(campaign => ({
                      value: campaign.instanceId,
                      label: campaign.name,
                    }))}
                    isSearchable={false}
                    isClearable={false}
                    onChange={option => onCampaignChange(option?.value, index)}
                    value={variation.instanceId}
                    onInputChange={() => setFieldTouched('variations')}
                    onMenuOpen={() => setFieldTouched('variations')}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className='ab-body-item-input'>
                <input
                  type='number'
                  onChange={event => onRatioChange(event, index)}
                  value={variation.testSplit}
                  onBlur={() => setFieldTouched('variations')}
                  disabled={disabled}
                  className='experimentation-input'
                  min='0'
                  max='100'
                />
                <i className='input-icon' />
              </div>
              <div className='ab-body-item-conditional'>
                {!disabled && (
                  <ExperimentationActionButton
                    onAddNewVariation={onAddNewVariation}
                    onRemoveVariation={() => onRemoveVariation(index)}
                    hideRemove={index === 0 || index === 1}
                    setFieldTouched={setFieldTouched}
                  />
                )}
                {mode === 'preview' && !isCompleted && (
                  <Tooltip content='See on live' placement='left'>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => openUrlInNewTab(variation.instanceId)}
                    >
                      <span className='icon-wrapper'>
                        <Icons name='eye' color='#1570FF' />
                        <small> See on live</small>
                      </span>
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ExperimentationCampaignTable;
