export const ACCOUNT_HOLD_TYPES = {
  ONHOLD: 'ONHOLD',
  CHURN: 'CHURN',
  FAILED: 'FAILED',
  PAYING: 'PAYING',
};

export const PRIVILEGES = {
  MASTERUSER: 'MASTERUSER',
  ROLE_ACD: 'ROLE_ACD',
  ROLE_OBD: 'ROLE_OBD',
  ROLE_ACM: 'ROLE_ACM',
  ROLE_SM: 'ROLE_SM',
  ROLE_AGU: 'ROLE_AGU',
};

export const TOKEN_LIST = {
  SEGMENTIFY_TOKEN: 'SegmentifyAuthenticationToken',
  SEGMENTIFY_EXTERNAL_TOKEN: 'SegmentifyExternalAuthenticationToken',
  SEGMENTIFY_TOKEN_V3: 'SegmentifyAuthenticationTokenV3',
};

export const REDIRECT_ROUTE = '/login';
