import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import moment from 'moment';

import { getRestEndPoint } from '../../../../../system/api';
import { getApiKey } from '../../../../../modules/auth/user';
import { $http } from '../../../../../system/ajax';

import { useHandleStateChange } from './utils/useHandleStateChange';
import { PreviewSchema, FTPDownloadForm, FTPWrapper } from './components';

import { showErrorNotification } from '../../../../../actions/ui';
import { ExportDownload } from './utils/exportDownload';

async function downloadFtpUsers(data) {
  const url = `${getRestEndPoint(
    'contacts/ftpDownload',
  )}?apiKey=${getApiKey()}`;

  const response = await $http(url).post(data, { type: 'json' });
  return await response;
}

function DownloadFTP({ state }) {
  const [fileType, setFileType] = useState('csv');

  const { mutate, isSuccess } = useMutation({
    mutationFn: downloadFtpUsers,
    onSuccess: data => ExportDownload({ data, type: fileType }),
  });

  const { downloadState, setValues } = useHandleStateChange({
    initialState: state,
  });

  const handleOnFileTypeChange = useCallback(
    type => {
      setFileType(type);
    },
    [fileType],
  );

  const handleOnSubmit = useCallback(() => {
    const {
      subscribed,
      unSubscribed,
      bounceBack,
      selection,
      frequency,
    } = downloadState;

    const { startDate, endDate } = selection[0];

    const newStartDate = moment(startDate)
      .utcOffset(3)
      .format('YYYY-MM-DD');
    const newEndDate = moment(endDate)
      .utcOffset(3)
      .format('YYYY-MM-DD');

    const verifiedCheckboxes = [subscribed, unSubscribed, bounceBack].some(
      checkbox => checkbox === true,
    );

    if (!verifiedCheckboxes) {
      showErrorNotification(() => (
        <>
          Please select at least one type <strong>Bounce Back</strong>,
          <strong> Unsubscribed</strong> or <strong>Subscribed</strong>
        </>
      ));
      return;
    }

    mutate({
      frequency,
      subscribed,
      unsubscribed: unSubscribed,
      bounceBack,
      startDate: newStartDate,
      endDate: newEndDate,
    });
  }, [downloadState]);

  return (
    <FTPWrapper>
      <FTPDownloadForm downloadState={downloadState} setValues={setValues} />
      <PreviewSchema
        downloadState={downloadState}
        handleOnSubmit={handleOnSubmit}
        handleOnFileTypeChange={handleOnFileTypeChange}
        fileType={fileType}
      />
    </FTPWrapper>
  );
}

export default DownloadFTP;
