export const actionButtonConstants = [
  {
    name: 'html',
    icon: 'htmlIcon',
    text: 'Edit HTML',
  },
  {
    name: 'prejs',
    icon: 'jsIcon',
    text: 'Edit PreJs',
  },
  {
    name: 'postjs',
    icon: 'jsIcon',
    text: 'Edit PostJs',
  },
  {
    name: 'css',
    icon: 'cssIcon',
    text: 'Edit Style',
  },
];
