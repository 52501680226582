/* eslint-disable no-nested-ternary */
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import FunnelChart from 'highcharts-funnel';
import { t } from '../../system/ui';
import { calculateSegmentRatio } from '../../system/ratio';
import { formatValue, formatMoneyValue } from '../../system/string';

FunnelChart(ReactHighcharts.Highcharts);

export const report = props => {
  return (
    <div className='widget-stats'>
      <div className='widget-stats'>
        <div className='widget-stat-segmentation  stat-segmentation'>
          <ReactHighcharts config={props.pieConfig} />
          <p className='segmentation-title-ratio'>
            {props.position === 'UP'
              ? calculateSegmentRatio(
                  props.segmentUser,
                  props.segmentUser + props.otherUser,
                )
              : calculateSegmentRatio(
                  props.revenue,
                  props.revenue + props.otherRevenue,
                )}
            %
          </p>
        </div>
        <div className='widget-stat-segmentation  stat-segmentation search__m_t_10'>
          <p className='segmentation-title' style={{ color: '#4F7398' }}>
            {props.position === 'UP'
              ? formatValue(props.segmentUser)
              : formatMoneyValue(props.revenue)}
          </p>
          <p className='widget-stat-conversion' style={{ color: '#4F7398' }}>
            {props.position === 'UP'
              ? t('Segmented User')
              : t('Revenue from Segment')}
          </p>

          <p className='segmentation-title'>
            {props.position === 'UP'
              ? formatValue(props.segmentUser + props.otherUser)
              : formatMoneyValue(props.revenue + props.otherRevenue)}
          </p>
          <p
            className='widget-stat-conversion'
            style={{ marginBlockEnd: '0px' }}
          >
            {props.position === 'UP'
              ? t('Total Reachable')
              : t('Total Revenue')}
          </p>
          {props.position === 'UP' && (
            <p className='widget-stat-conversion'>{t('Users')}</p>
          )}
        </div>
      </div>

      <div className='widget-stat  stat-segmentation'>
        <h4 className='widget-stat-title'>
          {props.position === 'UP'
            ? t(props.data.title[0])
            : t(props.data.title[2])}
        </h4>

        <div className='widget-stats'>
          <div className='widget-stat-segmentation  stat-segmentation'>
            <p
              className='segmentation-title'
              style={{ color: '#4F7398', textAlign: 'center' }}
            >
              {props.position === 'UP'
                ? props.data.segment[0] || 0
                : props.data.segment[2] || 0}
            </p>
            <p
              className='widget-stat-conversion'
              style={{ color: '#4F7398', textAlign: 'center' }}
            >
              {t(props.segmentName)}
            </p>
          </div>

          <div style={{ width: '30px' }}>
            <h4 className='widget-stat-title'>{t('vs')}</h4>
          </div>

          <div className='widget-stat-segmentation  stat-segmentation'>
            <p className='segmentation-title' style={{ textAlign: 'center' }}>
              {props.position === 'UP'
                ? props.data.other[0] || 0
                : props.data.other[2] || 0}
            </p>
            <p
              className='widget-stat-conversion'
              style={{ textAlign: 'center' }}
            >
              {t('Other Customers')}
            </p>
          </div>
        </div>
        <h4 className='widget-stat-title' style={{ textAlign: 'center' }}>
          {props.position === 'UP'
            ? props.data.diffRatio[0] || 0
            : props.data.diffRatio[2] || 0}
        </h4>
      </div>

      <div className='widget-stat  stat-segmentation '>
        <h4 className='widget-stat-title' style={{ textAlign: 'center' }}>
          {props.position === 'UP'
            ? t(props.data.title[1])
            : t(props.data.title[3])}
        </h4>

        <div className='widget-stats'>
          <div className='widget-stat-segmentation  stat-segmentation'>
            <p
              className='segmentation-title'
              style={{ color: '#4F7398', textAlign: 'center' }}
            >
              {props.position === 'UP'
                ? props.data.segment[1] || 0
                : props.data.segment[3] || 0}
            </p>
            <p
              className='widget-stat-conversion'
              style={{ color: '#4F7398', textAlign: 'center' }}
            >
              {t(props.segmentName)}
            </p>
          </div>

          <div style={{ width: '30px' }}>
            <h4 className='widget-stat-title'>{t('vs')}</h4>
          </div>

          <div className='widget-stat-segmentation  stat-segmentation'>
            <p className='segmentation-title' style={{ textAlign: 'center' }}>
              {props.position === 'UP'
                ? props.data.other[1] || 0
                : props.data.other[3] || 0}
            </p>
            <p
              className='widget-stat-conversion'
              style={{ textAlign: 'center' }}
            >
              {t('Other Customers')}
            </p>
          </div>
        </div>
        <h4 className='widget-stat-title' style={{ textAlign: 'center' }}>
          {props.position === 'UP'
            ? props.data.diffRatio[1] || 0
            : props.data.diffRatio[3] || 0}
        </h4>
      </div>
    </div>
  );
};
