/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import actionButtons from '../../../components/push/actionButtons';
import AdvancedConfig from './advanced-config/advanced-config';
import AdvancedConfigButton from './advanced-config/button';
import AudienceButton from './audience/button';
import AudienceOptions from './audience/options';
import BackgroundImage from './background-image';
import Button from './button';
import Checkbox from './checkbox';
import ConfirmButtons from './confirm-button';
import CurrentSelector from './currentSelector';
import Delay from './delay';
import PushDescription from './description';
import Devices from './devices';
import FormFields from './form-fields';
import GoLiveButton from './go-live';
import GoTestButton from './go-test';
import HideInformation from './hideInformation';
import HidePrice from './hidePrice';
import Icon from './icon';
import IconWithUpload from './iconWithUpload';
import ImageWithUpload from './imageWithUpload';
import Input from './input';
import InputSelector from './inputSelector';
import MinChar from './minChar';
import Name from './name';
import NotificationImage from './notificationimage';
import OpeningDirection from './openingDirection';
import Overlay from './overlay';
import Pages from './pages';
import Placeholder from './placeholder';
import PopupOption from './popup-option';
import PositionButton from './position/button';
import PositionOptions from './position/options';
import PreviewButton from './preview';
import PreviewDialogButton from './preview-dialog';
import PushLimitedRecommendations from './pushLimitedRecommendations';
import PushPermissionTitle from './pushPermissionTitle';
import PushRecommendations from './pushRecommendations';
import Recommendations from './recommendations';
import RedirectURL from './redirect-url';
import SchedulingButton from './scheduling/button';
import SchedulingOptions from './scheduling/options';
import SearchDevices from './searchDevices';
import SearchRecommendation from './searchRecommendation';
import SearchSynonyms from './searchSynonyms';
import Segment from './segment';
import SegmentationOptions from './segmentation/options';
import ShadowRoot from './shadowRoot';
import StartEndDate from './start-end-date';
import Sticky from './sticky';
import StylesButton from './style/button';
import StylesOptions from './style/options';
import Text from './text';
import TimingButton from './time/button';
import TimingOptions from './time/options';
import Title from './title';
import TriggerSelector from './triggerSelector';
import URL from './url';
import UrlPrefix from './urlPrefix';
import ButtonText from './buttonText';
import RockScoreFrameType from './rockScoreFrameType';
import fullNamePlaceHolder from './fullNamePlaceHolder';

const CompList = {
  openingDirection: OpeningDirection,
  triggerSelector: TriggerSelector,
  title: Title,
  searchDevices: SearchDevices,
  recommendations: Recommendations,
  searchRecommendation: SearchRecommendation,
  searchSynonyms: SearchSynonyms,
  formFields: FormFields,
  text: Text,
  description: Text,
  input: Input,
  buttonSubmitText: Button,
  termsText: Text,
  termsUrl: URL,
  placeholder: Placeholder,
  notificationImage: NotificationImage,
  icon: Icon,
  image: BackgroundImage,
  url: URL,
  redirectUrl: RedirectURL,
  name: Name,
  startEndDate: StartEndDate,
  devices: Devices,
  pages: Pages,
  overlay: Overlay,
  sticky: Sticky,
  confirmButtons: ConfirmButtons,
  stylesButton: StylesButton,
  stylesOptions: StylesOptions,
  positionButton: PositionButton,
  positionOptions: PositionOptions,
  timingButton: TimingButton,
  timingOptions: TimingOptions,
  schedulingButton: SchedulingButton,
  schedulingOptions: SchedulingOptions,
  audienceButton: AudienceButton,
  audienceOptions: AudienceOptions,
  advancedConfig: AdvancedConfig,
  advancedConfigButton: AdvancedConfigButton,
  segment: Segment,
  segmentationOptions: SegmentationOptions,
  goTest: GoTestButton,
  goLive: GoLiveButton,
  preview: PreviewButton,
  previewDialog: PreviewDialogButton,
  popupOption: PopupOption,
  iconWithUpload: IconWithUpload,
  imageWithUpload: ImageWithUpload,
  pushLimitedRecommendations: PushLimitedRecommendations,
  pushRecommendations: PushRecommendations,
  actionButtons,
  delay: Delay,
  minChar: MinChar,
  currentSelector: CurrentSelector,
  urlPrefix: UrlPrefix,
  inputSelector: InputSelector,
  pushDescription: PushDescription,
  pushPermissionTitle: PushPermissionTitle,
  hidePrice: HidePrice,
  hideInformation: HideInformation,
  forceRemoveInputVal: Checkbox,
  outOfStockInputVal: Checkbox,
  activateProductIdAndCategoryAndBrandRedirection: Checkbox,
  useInStockInOrderForInstant: Checkbox,
  inputSelectorShadowRoots: ShadowRoot,
  hideSelectorShadowRoots: ShadowRoot,
  manuelSelectorShadowRoots: ShadowRoot,
  clearInputBindingsOnSite: Checkbox,
  buttonText: ButtonText,
  rockScoreFrameType: RockScoreFrameType,
  fullNamePlaceHolder,
};

class Fields extends Component {
  render() {
    return (
      <ol className='form-elements'>
        <div className='wizard-cards'>
          {this.props.comps
            .filter(item => typeof CompList[item.field] !== 'undefined')
            .map((item, index) => {
              const Comp = CompList[item.field];
              const ref = item.ignoreRef || false;
              return ref ? (
                <Comp
                  key={index}
                  options={item.options}
                  campaign={this.props.campaign}
                  campaignFields={this.props.campaignFields}
                  isAddCampaign={this.props.isAddCampaign}
                  campaignType={this.props.campaignType}
                  isRecommendationModule={this.props.isRecommendationModule}
                  duplicate={this.props.duplicate}
                  isEmail={this.props.isEmail}
                  emailType={this.props.emailType}
                  itemFields={item.fields}
                  isPush={item.isPush}
                  includeHiddens={item.includeHiddens}
                  compName={item.field}
                  criteria={this.props.criteria}
                  disableFields={this.props.disableFields}
                  disabled={item.disabled}
                  disableCustom={this.props.disabled}
                  item={this.props.item}
                />
              ) : (
                <Comp
                  key={index}
                  ref={item.field}
                  options={item.options}
                  campaign={this.props.campaign}
                  campaignType={this.props.campaignType}
                  campaignFields={this.props.campaignFields}
                  isAddCampaign={this.props.isAddCampaign}
                  isRecommendationModule={this.props.isRecommendationModule}
                  duplicate={this.props.duplicate}
                  isEmail={this.props.isEmail}
                  isPush={item.isPush}
                  includeHiddens={item.includeHiddens}
                  emailType={this.props.emailType}
                  itemFields={item.fields}
                  compName={item.field}
                  criteria={this.props.criteria}
                  disableFields={
                    this.props.disableField ||
                    (this.props.campaignType === 'FLY' &&
                      item.field === 'redirectUrl' &&
                      this.props.algorithmSelected)
                  }
                  algorithmSelected={this.props.algorithmSelected}
                  disabled={item.disabled}
                  disableCustom={this.props.disabled}
                  selectedLanguage={this.props.selectedLanguage}
                  additionalTitles={this.props.additionalTitles}
                  getData={this.props.getData}
                  isFinished={this.props.isFinished}
                  isEdit={this.props.isEdit}
                  additionalRedirectUrls={this.props.additionalRedirectUrls}
                  additionalDescriptions={this.props.additionalDescriptions}
                  additionalIcons={this.props.additionalIcons}
                  additionalImages={this.props.additionalImages}
                  additionalButtonLaterText={
                    this.props.additionalButtonLaterText
                  }
                  additionalButtonAllowText={
                    this.props.additionalButtonAllowText
                  }
                  item={this.props.item}
                  updateStateValue={this.props.updateStateValue}
                />
              );
            })}
        </div>
      </ol>
    );
  }
}

Fields.propTypes = {
  comps: PropTypes.array.isRequired,
};

export default Fields;
