import React, { Component } from 'react';
import { Select, TextField } from '../../components/fields';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import Tooltip from '../../components/tooltip';

class ActionButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      firstText: '',
      secondText: '',
      firstRedirect: '',
      secondRedirect: '',
      firstAction: false,
      secondAction: false,
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.clickHandler = this.clickHandler.bind(this);

    this.debounced = debounce(() => {
      this.props.onButtonChanged(this.state.actions);
    }, 250);
  }

  componentWillReceiveProps(newProps) {
    if (newProps && newProps.campaign.actions !== undefined) {
      if (newProps.campaign.actions.length > this.state.actions.length) {
        this.setState({
          actions: newProps.campaign.actions,
        });
        if (newProps.campaign.actions.length === 1) {
          this.setState({
            firstText: newProps.campaign.actions[0].title,
            firstRedirect: newProps.campaign.actions[0].redirectUrl,
            firstAction: true,
          });
        }
        if (newProps.campaign.actions.length === 2) {
          this.setState({
            firstText: newProps.campaign.actions[0].title,
            firstRedirect: newProps.campaign.actions[0].redirectUrl,
            secondText: newProps.campaign.actions[1].title,
            secondRedirect: newProps.campaign.actions[1].redirectUrl,
            firstAction: true,
            secondAction: true,
          });
        }
      }
    }
  }
  onInputChange(e) {
    let actions = this.state.actions.slice(0);
    let eSource = e.target;
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (eSource.name.indexOf('first') > -1) {
          if (this.state.firstAction && this.state.secondAction) {
            if (actions.length === 0) {
              actions.push(
                { action: 'action0', title: '', redirectUrl: '' },
                { action: 'action1', title: '', redirectUrl: '' },
              );
            }
          } else {
            if (actions.length === 0) {
              actions.push({ action: 'action0', title: '', redirectUrl: '' });
            }
          }

          if (eSource.name === 'firstText') {
            actions[0].title = this.state.firstText;
          }
          if (eSource.name === 'firstRedirect') {
            actions[0].redirectUrl = this.state.firstRedirect;
          }
        } else {
          if (actions.length === 0) {
            actions.push(
              { action: 'action0', title: '', redirectUrl: '' },
              { action: 'action1', title: '', redirectUrl: '' },
            );
          } else if (actions.length === 1) {
            actions.push({ action: 'action1', title: '', redirectUrl: '' });
          }

          if (eSource.name === 'secondText') {
            actions[1].title = this.state.secondText;
          }

          if (eSource.name === 'secondRedirect') {
            actions[1].redirectUrl = this.state.secondRedirect;
          }
        }

        this.setState(
          {
            actions: actions,
          },
          () => {
            if (this.props.onButtonChanged) {
              this.debounced();
            }
          },
        );
      },
    );
  }

  clickHandler(e) {
    let eSource = e.target;
    let actions = this.state.actions.slice();
    this.setState(
      {
        [e.target.getAttribute('name')]: !this.state[
          e.target.getAttribute('name')
        ],
      },
      () => {
        if (
          eSource.getAttribute('name') === 'firstAction' &&
          this.state.firstAction === false
        ) {
          this.setState(
            {
              actions: [],
              secondAction: false,
            },
            () => {
              if (this.props.onButtonChanged) {
                this.props.onButtonChanged([]);
              }
            },
          );
        } else if (
          eSource.getAttribute('name') === 'secondAction' &&
          this.state.secondAction === false
        ) {
          this.state.actions.pop();
          this.setState(
            {
              actions: this.state.actions,
            },
            () => {
              if (this.props.onButtonChanged) {
                this.props.onButtonChanged(this.state.actions);
              }
            },
          );
        } else if (
          eSource.getAttribute('name') === 'secondAction' &&
          this.state.secondAction === true
        ) {
          actions.push({
            action: 'action1',
            title: this.state.secondText,
            redirectUrl: this.state.secondRedirect,
          });
          this.setState(
            {
              actions,
            },
            () => {
              if (this.props.onButtonChanged) {
                this.props.onButtonChanged(actions);
              }
            },
          );
        } else if (
          eSource.getAttribute('name') === 'firstAction' &&
          this.state.firstAction === true
        ) {
          actions.push({
            action: 'action0',
            title: this.state.firstText,
            redirectUrl: this.state.firstRedirect,
          });
          this.setState(
            {
              actions,
            },
            () => {
              if (this.props.onButtonChanged) {
                this.props.onButtonChanged(actions);
              }
            },
          );
        }
      },
    );
  }
  render() {
    return (
      <div style={{ marginBottom: '3em' }}>
        <li style={{ listStyle: 'none' }}>
          <ol className='form-elements wizard-items'>
            <div
              className='wizard-comp'
              style={{ marginTop: '0', paddingTop: '0' }}
            >
              <ol className='form-elements'>
                <div className='wizard-cards'>
                  <div className='email-settings-capping wizard'>
                    <div className='wizard-comp'>
                      <h3
                        className='wizard-comp-title item-title-sub item-title-sub__rec'
                        style={{ fontWeight: 600 }}
                      >
                        Action Buttons{' '}
                        <Tooltip
                          content={
                            'make sure that the directive you provide is clear, short and understandable.'
                          }
                          alignment={'right'}
                        >
                          <i className='icon-info' role='presentation'>
                            <span className='for-screenreader-only' />
                          </i>
                        </Tooltip>
                      </h3>
                      <p className='wizard-comp-description'>
                        Add action buttons to the push notification that can
                        redirect pages.
                      </p>
                      <div style={{ marginTop: '10px' }}>
                        <div>
                          <div
                            style={{ marginTop: '20px', marginBottom: '20px' }}
                          >
                            <label className='switch action-button-1'>
                              <input
                                type='checkbox'
                                checked={this.state.firstAction}
                              />
                              <span
                                name='firstAction'
                                className='slider round'
                                onClick={this.clickHandler}
                              />
                            </label>
                            <span> First Action </span>
                            {this.state.firstAction && (
                              <div
                                style={{
                                  marginTop: '20px',
                                  minHeight: '85px',
                                }}
                              >
                                <div className='actionText'>
                                  <TextField
                                    name='firstText'
                                    label={'First Button Text'}
                                    className='item-stacked one-whole'
                                    value={this.state.firstText}
                                    onChange={this.onInputChange}
                                    validation={{ minLength: 1 }}
                                    required={true}
                                  />
                                </div>
                                <div className='actionUrl'>
                                  <TextField
                                    name='firstRedirect'
                                    label={'Redirect Url'}
                                    className='item-stacked one-whole'
                                    value={this.state.firstRedirect}
                                    onChange={this.onInputChange}
                                    validation={{ secureURL: true }}
                                    required={true}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            style={{ marginTop: '20px', marginBottom: '20px' }}
                            className={classNames('actionButton', {
                              disabled: !this.state.firstAction,
                            })}
                          >
                            <label className='switch action-button-2'>
                              <input
                                type='checkbox'
                                checked={this.state.secondAction}
                              />
                              <span
                                name='secondAction'
                                className='slider round'
                                onClick={this.clickHandler}
                              />
                            </label>
                            <span> Second Action </span>
                            {this.state.secondAction && (
                              <div
                                style={{
                                  marginTop: '20px',
                                  minHeight: '85px',
                                }}
                              >
                                <div className='actionText'>
                                  <TextField
                                    name='secondText'
                                    label={'Second Button Text'}
                                    className='item-stacked one-whole'
                                    value={this.state.secondText}
                                    onChange={this.onInputChange}
                                    required={true}
                                    validation={{ minLength: 1 }}
                                  />
                                </div>
                                <div className='actionUrl'>
                                  <TextField
                                    name='secondRedirect'
                                    label={'Redirect Url'}
                                    className='item-stacked one-whole'
                                    value={this.state.secondRedirect}
                                    onChange={this.onInputChange}
                                    validation={{ secureURL: true }}
                                    required={true}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ol>
            </div>
          </ol>
        </li>
      </div>
    );
  }
}

export default ActionButtons;
