import React from 'react';
import { browserHistory, IndexRoute, Route } from 'react-router';

import * as Containers from '../containers';
import AbandonedCartContainer from '../containers/push/abandoned-cart-container';
import { Auth } from '../modules/auth';
import cookie from '../system/cookie';
import { trackingEventNames, trackingService } from '../trackingService';
import * as Views from '../views';

import ExperimentationDefinitionPanel from '../components/experimentation/definition/ExperimentationDefinitionPanel';
import ExperimentationReport from '../components/experimentation/report/ExperimentationReport';
import { externalRoutes } from './external';
import { settingRoutes } from './settings';

const onRouterChange = (_, routeInfo) => {
  let paramsInfo = [...Object.entries(routeInfo.params)];
  let parameter = '';
  const { pathname } = routeInfo.location;
  const splitPathname = pathname.split('/');
  const eventType = splitPathname[1] || 'dashboard';
  const eventName = splitPathname[2] || '';
  if (paramsInfo.length > 0) {
    paramsInfo = paramsInfo.flat();
    parameter = eventName === paramsInfo[1] ? '' : paramsInfo[1];
  }
  trackingService.sendPageView(
    `${trackingEventNames.PAGE_VIEW}: ${eventType}/${eventName}`,
    {
      eventType,
      eventName,
      parameter,
    },
  );

  // eslint-disable-next-line import/no-named-as-default-member
  const token = cookie.get(cookie.select());
  if (token) {
    // eslint-disable-next-line import/no-named-as-default-member
    cookie.set(cookie.select(), token, 30, '/', '.segmentify.com');
  }
};

const protectedRoutes = (
  <Route
    component={Containers.App}
    onEnter={Auth.loginAuth}
    onChange={onRouterChange}
  >
    <IndexRoute component={Views.Dashboard} />
    <Route path='/' component={Views.Dashboard} />
    <Route
      path='/refresh'
      component={() => {
        browserHistory.push('/');

        return null;
      }}
    />
    <Route path='/managed-services' component={Views.ManagedServicesPortal} />
    <Route path='/dashboard/:type' components={Views.Dashboard} />
    <Route path='/welcome' component={Views.Dashboard} />
    <Route path='/dynamic_bundle' component={Views.ProductBundle}>
      <Route path=':dynamic_bundle' components={Views.ProductBundle} />
    </Route>
    <Route path='/dynamic_bundle' component={Views.ProductBundle}>
      <Route path='automated-bundles/list' components={Views.ProductBundle} />
    </Route>
    <Route path='/dynamic_bundle' component={Views.ShopTheLookList}>
      <Route path='shop_the_look/list' components={Views.ShopTheLookList} />
    </Route>
    <Route
      path='/dynamic_bundle/shop_the_look/:mode'
      component={Views.ShopTheLookAddOrEdit}
    />
    <Route
      path='/dynamic_bundle/shop_the_look/:mode/:id'
      component={Views.ShopTheLookAddOrEdit}
    />
    <Route
      path='/dynamic_bundle/automated-bundles/:mode'
      component={Views.ProductBundleAddOrEdit}
    />
    <Route
      path='/dynamic_bundle/automated-bundles/:mode/:id'
      component={Views.ProductBundleAddOrEdit}
    />

    <Route path='/recommendations' component={Views.Recommendations}>
      <Route path=':recommendation' components={Views.Recommendations} />
    </Route>
    <Route path='/recommendations' component={Views.ExperimentationList}>
      <Route
        path='experimentation/list'
        component={Views.ExperimentationList}
      />
    </Route>

    <Route path='/recommendations' component={Views.AbTest}>
      <Route path='experimentation/ab_test/list' component={Views.AbTest} />
    </Route>

    <Route path='/recommendations' components={Views.PromotionList}>
      <Route path='promotion/list' components={Views.PromotionList} />
    </Route>

    <Route
      path='/recommendations/promotion/:mode'
      component={Views.PromotionAddOrEdit}
    />
    <Route
      path='/recommendations/promotion/:mode/:id'
      component={Views.PromotionAddOrEdit}
    />
    <Route path='/recommendations' component={Views.RecommendationTemplates}>
      <Route path='templates/list' component={Views.RecommendationTemplates} />
    </Route>
    <Route
      path='/recommendations/templates/:mode'
      component={Views.EditorPage}
    />
    <Route
      path='/recommendations/templates/:mode/:id'
      component={Views.EditorPage}
    />

    <Route path='/email/templates' component={Views.EmailTemplates} />
    <Route
      path='/email/activity-report'
      component={Views.EmailActivityReport}
    />
    <Route path='/email' components={Views.Emails}>
      <Route path=':email' components={Views.Emails} />
    </Route>
    <Route path='/push' components={Views.Pushes}>
      <Route path=':push' components={Views.Pushes} />
    </Route>
    <Route path='/push/add/ABANDONED_CART' component={AbandonedCartContainer} />
    <Route path='/push/add/:pushType' component={Views.AddPushCampaign} />
    <Route
      path='/push/replicate/:pushType/:campaignId'
      component={Views.EditPushCampaign}
      isReplicate
    />
    <Route
      path='/push/edit/ABANDONED_CART'
      component={AbandonedCartContainer}
    />
    <Route path='/push/edit/:pushType' component={Views.EditPushCampaign} />
    <Route path='/search' component={Views.Searchs}>
      <Route path=':search' component={Views.Searchs} />
    </Route>

    {/* <Route path="/search" component={Views.ExperimentationList}> */}
    {/*  <Route path="experimentation/list" component={Views.ExperimentationList} /> */}
    {/* </Route> */}

    <Route
      path='/experimentation/:module/:mode/:instanceId'
      component={ExperimentationDefinitionPanel}
    />
    <Route
      path='/experimentation/:module/:mode'
      component={ExperimentationDefinitionPanel}
    />
    <Route
      path='/report/experimentation/:instanceId'
      component={ExperimentationReport}
    />
    <Route
      path='/report/experimentation/:instanceId'
      component={ExperimentationReport}
    />
    <Route
      path='/search/:mode/faceted/:instanceId'
      component={Views.FacetedSearchCampaign}
    />
    <Route
      path='/search/:mode/faceted'
      component={Views.FacetedSearchCampaign}
    />
    <Route
      path='/search/faceted/single-report/:instanceId'
      component={Views.SingleFacetedSearchReport}
    />
    <Route
      path='/search/:mode/rule-set'
      component={Views.RulesetDefinitionPanel}
    />
    <Route
      path='/search/:mode/rule-set/:instanceId'
      component={Views.RulesetDefinitionPanel}
    />
    <Route
      path='/search/:mode/instant-rule-set'
      component={Views.RulesetDefinitionPanel}
    />
    <Route
      path='/search/:mode/instant-rule-set/:instanceId'
      component={Views.RulesetDefinitionPanel}
    />

    <Route path='/search/edit/search' component={Views.EditSearchCampaign} />
    <Route path='/search/add/search' component={Views.AddSearchCampaign} />

    <Route
      path='/search/add/before_search'
      component={Views.BeforeAddSearchCampaign}
    />
    <Route
      path='/search/edit/before_search'
      component={Views.BeforeEditSearchCampaign}
    />
    <Route
      path='/search/global-configuration/visual-builder'
      component={Views.GlobalSearchVisualBuilder}
    />
    <Route
      path='/reports/segment-single/:report'
      component={Views.SingleSegmentationReport}
    />

    <Route
      path='/push/edit/:pushType/:campaignId'
      component={Views.EditPushCampaign}
    />
    <Route
      path='/push/preview/:pushType/:campaignId'
      component={Views.PreviewPushCampaign}
    />

    <Route path='behavioural-targeting' component={Views.BehaviouralTargeting}>
      <Route path='segmentation' component={Views.Segments}>
        <Route path=':segment' component={Views.Segments} />
      </Route>
      <Route path='engagement' components={Views.Personalization}>
        <Route path=':engagement' component={Views.Personalization} />
      </Route>
    </Route>

    {/* <Route path='/behavioural-targeting' component={Views.ExperimentationList}> */}
    {/*   <Route */}
    {/*     path='experimentation/list' */}
    {/*     component={Views.ExperimentationList} */}
    {/*   /> */}
    {/* </Route> */}

    <Route path='/behavioural-targeting' component={Views.AbTest}>
      <Route path='experimentation/ab_test/list' component={Views.AbTest} />
    </Route>

    <Route path='behavioural-targeting' component={Views.Countdown}>
      <Route path='engagement/countdown/list' component={Views.Countdown} />
    </Route>
    <Route
      path='behavioural-targeting/engagement/countdown/:campaignType/:mode/:campaignId'
      component={Views.CountdownAddOrEdit}
    />

    <Route
      path='behavioural-targeting/engagement/countdown/:campaignType/:mode'
      component={Views.CountdownAddOrEdit}
    />

    <Route path='behavioural-targeting' component={Views.Gamification}>
      <Route
        path='engagement/gamification/list'
        component={Views.Gamification}
      />
    </Route>

    <Route
      path='behavioural-targeting/engagement/gamification/wof/:mode'
      component={Views.WheelOfFortuneAddOrEdit}
    />

    <Route
      path='behavioural-targeting/engagement/gamification/scratch/:mode'
      component={Views.ScratchAddOrEdit}
    />

    <Route
      path='behavioural-targeting/engagement/gamification/wof/:mode/:campaignId'
      component={Views.WheelOfFortuneAddOrEdit}
    />

    <Route
      path='behavioural-targeting/engagement/gamification/scratch/:mode/:campaignId'
      component={Views.ScratchAddOrEdit}
    />

    <Route path='audience' component={Views.Audience}>
      <Route path='segmentation' component={Views.Segments}>
        <Route path=':segment' component={Views.Segments} />
      </Route>
      <Route
        path='rule-based-segmentation'
        components={Views.RuleBasedSegmentation}
      />
      <Route
        path='dynamic-segmentation'
        components={Views.DynamicSegmentation}
      />
      <Route path='customer-profile' components={Views.CustomerProfile} />
    </Route>

    <Route
      path='audience/rule-based-segmentation/:mode'
      component={Views.RuleBasedSegmentationAddOrEdit}
    />
    <Route
      path='audience/rule-based-segmentation/:mode/:segmentId'
      component={Views.RuleBasedSegmentationAddOrEdit}
    />

    <Route
      path='audience/dynamic-segmentation/:mode'
      component={Views.DynamicSegmentationAddOrEdit}
    />

    <Route
      path='audience/dynamic-segmentation/:mode/:segmentId'
      component={Views.DynamicSegmentationAddOrEdit}
    />

    <Route
      path='audience/customer-profile/customer-detail/:id'
      component={Views.CustomerProfileDetailPage}
    />

    <Route path='/trendify' component={Views.Trendify} />
    <Route path='/trendify/categories' component={Views.Categories} />
    <Route path='/trendify/brands' component={Views.Brands} />
    <Route path='/trendify/sales' component={Views.Sales} />
    <Route path='/trendify/insights' component={Views.Insights} />
    <Route path='/bannerify' component={Views.Bannerify} />
    <Route path='/reports' component={Views.Reports}>
      <Route path='all/:campaigntype' components={Views.Reports} />
      <Route path='all/:campaigntype/:report' components={Views.Reports} />
    </Route>
    <Route path='/reports' component={Views.SearchInsights}>
      <Route path='search/insights' component={Views.SearchInsights} />
    </Route>

    <Route
      path='/reports/single/:campaigntype/:reportID'
      components={Views.SingleReport}
    />
    <Route
      path='/reports/email-single/:reportID'
      components={Views.SingleEmailReport}
    />
    <Route
      path='/reports/search-single/:report'
      component={Views.SingleSearchReport}
    />
    <Route
      path='/reports/push-single/:reportID'
      components={Views.SinglePushReport}
    />
    <Route
      path='/reports/push-multiple/:reportID'
      components={Views.MultiplePushReport}
    />
    <Route
      path='/widget/add/:pagetype/:placement'
      component={Views.AddWidget}
    />
    <Route
      path='/add/journey_builder/:placement'
      component={Views.JourneyBuilderAdd}
    />
    <Route
      path='/edit/journey_builder/:placement/:widgetId'
      component={Views.JourneyBuilderEdit}
    />
    <Route
      path='/widget/edit/:pagetype/:placement/:widgetId'
      component={Views.EditWidget}
    />
    <Route path='/personalisedAds' component={Views.PersonalisedAds} />
    <Route path='/email/templates' component={Views.EmailTemplates} />
    <Route path='/email/templates/:mode' component={Views.EmailBuilder} />
    <Route path='/email/templates/:mode/:id' component={Views.EmailBuilder} />
    <Route
      path='/email/email-templates/:id/:mode'
      component={Views.EmailTemplates}
    />
    <Route path='/email/add/fly' component={Views.AddEmailCampaign} />
    <Route path='/email/:mode/:emailType' component={Views.EditEmailCampaign} />
    <Route
      path='/email-sgm/:mode/:emailType'
      component={Views.EmailAddOrEdit}
    />
    <Route path='/promotion/add/:pagetype' component={Views.AddPromotion} />
    <Route
      path='/promotion/edit/:pagetype/:widgetId'
      component={Views.EditPromotion}
    />
    <Route
      path='/searchkeywords/add/:pagetype'
      component={Views.AddSearchKeywordRec}
    />
    <Route
      path='/searchkeywords/edit/:pagetype/:widgetId'
      component={Views.EditSearchKeywordRec}
    />

    <Route
      path='campaign/social-proof/:campaignType/:mode'
      component={Views.SocialProof}
    />
    <Route
      path='campaign/social-proof/:campaignType/:mode/:campaignId'
      component={Views.SocialProof}
    />

    <Route path='/campaign/see-all/add' component={Views.AddSeeAll} />
    <Route path='/campaign/persona-quiz/add' component={Views.AddPersonaQuiz} />
    <Route
      path='/campaign/persona-quiz/duplicate/:campaignId'
      component={Views.AddPersonaQuiz}
    />

    <Route
      path='campaign/social-proof/:campaignType/:mode'
      component={Views.SocialProof}
    />
    <Route
      path='campaign/social-proof/:campaignType/:mode/:campaignId'
      component={Views.SocialProof}
    />

    <Route path='/campaign/see-all/add' component={Views.AddSeeAll} />
    <Route path='/campaign/persona-quiz/add' component={Views.AddPersonaQuiz} />
    <Route
      path='/campaign/persona-quiz/duplicate/:campaignId'
      component={Views.AddPersonaQuiz}
    />

    <Route
      path='/campaign/see-all/duplicate/:campaignId'
      component={Views.AddSeeAll}
    />
    <Route
      path='/campaign/see-all/edit/:campaignId'
      component={Views.EditSeeAll}
    />
    <Route
      path='/campaign/persona-quiz/edit/:campaignId'
      component={Views.EditPersonaQuiz}
    />

    <Route path='/campaign/popup/add' component={Views.AddPopup} />
    <Route path='/campaign/see-all/add' component={Views.AddSeeAll} />
    <Route
      path='/campaign/see-all/duplicate/:campaignId'
      component={Views.AddSeeAll}
    />
    <Route
      path='/campaign/see-all/edit/:campaignId'
      component={Views.EditSeeAll}
    />
    <Route path='/campaign/popup/add' component={Views.AddPopup} />
    <Route
      path='/campaign/popup/duplicate/:campaignId'
      component={Views.AddPopup}
    />
    <Route
      path='/campaign/popup/edit/:campaignId'
      component={Views.EditPopup}
    />
    <Route path='/campaign/form-survey/add' component={Views.AddFormSurvey} />
    <Route
      path='/campaign/form-survey/duplicate/:campaignId'
      component={Views.AddFormSurvey}
    />
    <Route
      path='/campaign/form-survey/edit/:campaignId'
      component={Views.EditFormSurvey}
    />
    <Route
      path='/campaign/email-collection/add'
      component={Views.AddNewsletter}
    />
    <Route
      path='/campaign/email-collection/duplicate/:campaignId'
      component={Views.AddNewsletter}
    />
    <Route
      path='/campaign/email-collection/edit/:campaignId'
      component={Views.EditNewsletter}
    />
    <Route path='/campaign/hero-banner/add' component={Views.AddHeroBanner} />
    <Route
      path='/campaign/hero-banner/duplicate/:campaignId'
      component={Views.AddHeroBanner}
    />
    <Route
      path='/campaign/hero-banner/edit/:campaignId'
      component={Views.EditHeroBanner}
    />
    <Route
      path='/campaign/notification-bar/add'
      component={Views.AddNotificationBar}
    />
    <Route
      path='/campaign/notification-bar/duplicate/:campaignId'
      component={Views.AddNotificationBar}
    />
    <Route
      path='/campaign/notification-bar/edit/:campaignId'
      component={Views.EditNotificationBar}
    />

    <Route
      path='/campaign/segmentation/add'
      component={Views.AddSegmentation}
    />
    <Route
      path='/campaign/segmentation/duplicate/:campaignId'
      component={Views.AddSegmentation}
    />
    <Route
      path='/campaign/segmentation/edit/:campaignId'
      component={Views.EditSegmentation}
    />

    <Route path='/admin/accounts' component={Views.Admin_Accounts} />
    <Route path='/user/accounts' component={Views.User_Accounts} />
    <Route path='/user/users' component={Views.User_Users} />
    <Route path='/admin/stats' component={Views.Admin_Stats} />
    <Route path='/admin/monitoring' component={Views.Monitoring} />
    <Route path='/admin/gacomparision' component={Views.Admin_GaComparision} />
    <Route path='/add/account' component={Views.AddCreateAccount} />
    <Route path='/usermanagement' component={Views.UserManagement} />
    <Route path='/admin/alerts' component={Views.Alerts} />
    {settingRoutes}

    <Route
      path='/:module/experimentation/ab_test/:mode'
      component={Views.AbTestAddOrDuplicate}
    />

    <Route
      path='/:module/experimentation/ab_test/:mode/:id'
      component={Views.AbTestAddOrDuplicate}
    />
  </Route>
);

const staticRoutes = (
  <Route component={Containers.LoginController}>
    <Route path='/login' component={Views.Login} />
    <Route path='/login-de' component={Views.LoginDe} />
    <Route path='/forgot-password' component={Views.ForgotPassword} />
    <Route path='/mail-send/:mailType' component={Views.MailSend} />
    <Route path='/unlock/user/:token' component={Views.UnlockUser} />
    <Route path='/reset-password' component={Views.ResetPassword} />
  </Route>
);

const notFoundRoute = (
  <Route
    path='*'
    component={Views.NotFound}
    status='404'
    onEnter={Auth.loginAuth}
  />
);

const setPasswordRoute = (
  <Route path='/multiUser/setPassword/:token' component={Views.Set_Password} />
);

export {
  externalRoutes,
  notFoundRoute,
  protectedRoutes,
  setPasswordRoute,
  staticRoutes,
};
