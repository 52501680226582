/**
 * Created by mehmetyurtar on 1/4/18.
 */
import React from 'react';
import { connect } from 'react-redux';

import { t } from '../../system/ui';

import Icon from '../icon';
import Icons from '../icons';
import Navlink from '../navlink';
import {
  isResponsible,
  isSuperiestUser,
  hasModule,
  isGlobalAccount,
  hasOnlyDynamicBundles,
} from '../../modules/auth/user';
import Tooltip from '../tooltip';

class SidebarSecondaryReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openMenuIndex: 0,
      accordionClicked: false,
    };

    this.changeOpenMenu = this.changeOpenMenu.bind(this);
  }

  changeOpenMenu(newindex) {
    this.setState({
      openMenuIndex: this.state.openMenuIndex === newindex ? false : newindex,
    });
  }

  render() {
    const menu = this.props.submenu[this.props.parent.path.replace('/', '')];
    const isFiltered = hasOnlyDynamicBundles();
    const dynamicRecoModule = [
      'Executive Summary',
      'View All',
      'Product',
      'Basket',
    ];
    const newMenu = {};
    if (typeof menu !== 'undefined') {
      Object.keys(menu.children)?.filter(item => {
        if (item === 'recommendations') {
          if (!hasModule('recommendation') && !hasOnlyDynamicBundles()) {
            return null;
          }
        }

        if (['iys', 'iys_dashboard'].includes(item)) {
          if (isGlobalAccount()) {
            return null;
          }
        }

        let isAllowedUser = true;
        if (!menu.children[item].parent && menu.children[item].roles) {
          isAllowedUser = false;
          isAllowedUser = isSuperiestUser() || isAllowedUser;
          menu.children[item].roles.forEach(role => {
            isAllowedUser = isAllowedUser || isResponsible(role);
          });
          if (isAllowedUser === false) {
            return;
          }
        }

        if (
          menu.children[item].visible !== false &&
          menu.children[item].header
        ) {
          newMenu[item] = Object.keys(menu.children).filter(childMenu => {
            let isAllowedUser = true;
            if (menu.children[childMenu].roles) {
              isAllowedUser = false;
              isAllowedUser = isSuperiestUser() || isAllowedUser;
              menu.children[childMenu].roles.forEach(role => {
                isAllowedUser = isAllowedUser || isResponsible(role);
              });
            }
            return (
              isAllowedUser &&
              menu.children[childMenu].visible !== false &&
              menu.children[childMenu].parent === menu.children[item].name
            );
          });
        }
      });
    }
    const allHeadersLength = Object.keys(newMenu).length;
    const exceptHeight = 71 + allHeadersLength * 50;
    const listCssHeight = `calc(100% - ${exceptHeight}px)`;
    return (
      <aside
        id='secondary-sidebar'
        className='navigation-sidebar navigation-sidebar--report'
      >
        <nav id='sub-nav' className='sub-nav' ref='subNav'>
          {menu && <h3 className='sub-nav-title'>{t(menu.name)}</h3>}
          {Object.keys(newMenu).map((newMenuItem, newMenuIndex) => {
            const isActive = newMenuIndex === this.state.openMenuIndex;
            return (
              <span key={newMenuItem}>
                <p
                  key={newMenuItem}
                  className='nav-item-header'
                  onClick={this.changeOpenMenu.bind(this, newMenuIndex)}
                >
                  {t(menu.children[newMenuItem].name)}
                  <span className={isActive ? 'icon--active' : 'icon--passive'}>
                    <Icons name='chevronUp' color='#fff' />
                  </span>
                </p>
                <ul
                  style={{ maxHeight: isActive ? listCssHeight : 0 }}
                  className={
                    isActive
                      ? 'container-list container-list--show'
                      : 'container-list container-list--hide'
                  }
                >
                  {newMenu[newMenuItem].map(childMenu => {
                    const item = menu.children[childMenu];
                    let isDisabled = false;

                    if (isFiltered) {
                      isDisabled =
                        item.parent === 'Recommendation' &&
                        !dynamicRecoModule.includes(item.name);
                    }

                    const isVisible = item.visible !== false;
                    let icon = <Icon name={item.icon} />;
                    if (item.svg) {
                      icon = <Icons name={item.icon} width='24' height='24' />;
                    }
                    return (
                      isVisible && (
                        <li key={childMenu} className='relative-item'>
                          <Navlink
                            key={item.slug}
                            to={item.slug}
                            className={`nav-item sub-nav-item header-symbol ${
                              isDisabled ? 'disabled-nav' : null
                            }`}
                          >
                            {item.hasOwnProperty('icon') ? (
                              <span>{icon}</span>
                            ) : (
                              ''
                            )}
                            {t(`${item.name} ${item.name_alt}`)}
                          </Navlink>
                          {isDisabled && (
                            <span className='absolute-icon-reports'>
                              <Tooltip
                                content={t(
                                  'To unlock all potential of the Recommendation module, please contact your Account Manager or Sales team.',
                                )}
                                alignment='left'
                                placement='left'
                                isSidebarReports
                              >
                                <Icons
                                  width={30}
                                  height={30}
                                  name='lock'
                                  color='#fff'
                                />
                              </Tooltip>
                            </span>
                          )}
                        </li>
                      )
                    );
                  })}
                </ul>
              </span>
            );
          })}
        </nav>
      </aside>
    );
  }
}

const MapStatesToProps = store => ({
  submenu: store.ui.submenu,
});

export default connect(MapStatesToProps)(SidebarSecondaryReports);
