import React, { Component } from "react";
class IconCheckboxCircleEmpty extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#464646"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg className="svg-icon" width={this.state.width} height={this.state.height} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={this.state.color} d="M5.82372 0.00798035C4.15497 0.12923 2.62497 0.88923 1.53747 2.13798C-0.296275 4.24048 -0.511275 7.31173 1.00872 9.65048C2.34747 11.7105 4.77122 12.7942 7.20622 12.4205C8.50497 12.2217 9.71622 11.6092 10.6525 10.678C12.7525 8.59048 13.1012 5.31798 11.4887 2.83798C10.7325 1.67298 9.59747 0.78798 8.27997 0.33173C7.84122 0.18048 7.36747 0.07798 6.86747 0.02423C6.65372 0.00172997 6.03747 -0.00827026 5.82372 0.00798035ZM6.81122 1.27548C7.99622 1.41798 9.02872 1.92923 9.84997 2.77798C10.9362 3.90173 11.4325 5.48423 11.185 7.03173C10.9287 8.63923 9.90247 10.0255 8.44247 10.7367C7.92622 10.9892 7.40497 11.1405 6.79872 11.2155C6.54372 11.2455 5.95372 11.2455 5.69872 11.2155C4.71747 11.0942 3.86997 10.7417 3.11122 10.1367C2.89497 9.96423 2.52997 9.59923 2.35622 9.38173C1.40497 8.19048 1.04622 6.68048 1.36247 5.18923C1.70497 3.56923 2.86497 2.20423 4.41872 1.59048C4.87872 1.40923 5.41497 1.28923 5.93622 1.25173C6.08997 1.24048 6.64997 1.25548 6.81122 1.27548Z" />
        <path fill={this.state.color} d="M6.01744 3.1268C5.99744 3.1293 5.92619 3.13805 5.86119 3.14555C5.48119 3.18805 5.05494 3.3293 4.71494 3.52305C4.00619 3.92805 3.48994 4.57305 3.25744 5.3443C3.16494 5.65055 3.12994 5.8943 3.12994 6.2443C3.12994 6.5168 3.14119 6.63055 3.18744 6.8693C3.31869 7.5343 3.70494 8.1868 4.22369 8.6218C4.69994 9.0218 5.23619 9.2593 5.86119 9.3468C6.03744 9.37055 6.49369 9.36805 6.67994 9.3393C7.87494 9.16305 8.84119 8.3543 9.21744 7.21305C9.32494 6.8868 9.36744 6.6168 9.36744 6.2443C9.36744 5.8718 9.32494 5.6018 9.21744 5.27555C8.84244 4.1368 7.86994 3.3218 6.68619 3.15055C6.55119 3.13055 6.10244 3.1143 6.01744 3.1268Z" />
      </svg>
    );
  }
}

export default IconCheckboxCircleEmpty;
