import user, { getAccountData, getApiKey } from '../auth/user';
import { $http } from '../../system/ajax';
import api from '../../system/api';
import { sortObj } from '../../system/object';
import { getStartEndDate } from '../../system/date';
import { store } from '../../store';

export const getSearch = (instanceId, callback, options, predefinedApiKey) => {
  const apiKey = predefinedApiKey || getApiKey();
  let url = '';
  if (instanceId) {
    url = `${api.getRestEndPoint(
      'palantir/get',
    )}/${instanceId}.json?apiKey=${apiKey}`;
  } else {
    url = `${api.getRestEndPoint('palantir/get')}?apiKey=${apiKey}`;
  }

  $http(url)
    .get(options)
    .then(response => {
      callback(response);
    });
};

export const getAllSearch = (callback, options, predefinedApiKey) => {
  const apiKey = predefinedApiKey || getApiKey();
  $http(
    `${api.getRestEndPoint('palantir/get')}?withFacets=true&apiKey=${apiKey}`,
  )
    .get(options)
    .then(response => {
      callback(sortObj(response));
    });
};

export const addCampaign = (campaign, callback) => {
  campaign.accountId = getAccountData('accountId') || '';
  return $http(`${api.getRestEndPoint('palantir/add')}?apiKey=${getApiKey()}`)
    .post(campaign, { type: 'json' })
    .then(response => {
      callback(response);
    });
};

export const updateCampaign = (campaign, callback) => {
  campaign.accountId = getAccountData('accountId') || '';
  return $http(
    `${api.getRestEndPoint('palantir/update')}?apiKey=${getApiKey()}`,
  )
    .post(campaign, { type: 'json' })
    .then(response => {
      callback(response);
    });
};

export const moveCampaignToArchive = instanceId => {
  return $http(
    `${api.getRestEndPoint(
      'palantir/archive',
    )}/${instanceId}?apiKey=${getApiKey()}`,
  ).post();
};

export const moveCampaignToTest = (instanceId, callback) => {
  return $http(
    `${api.getRestEndPoint(
      'palantir/test',
    )}/${instanceId}?apiKey=${getApiKey()}`,
  )
    .post()
    .then(response => {
      if (callback) {
        callback(response);
      }
    });
};
export const moveCampaignToLive = (instanceId, callback) => {
  return $http(
    `${api.getRestEndPoint(
      'palantir/live',
    )}/${instanceId}?apiKey=${getApiKey()}`,
  )
    .post()
    .then(response => {
      if (callback) {
        callback(response);
      }
    });
};

export const getRangedSearchReports = (
  instanceIds,
  boundaries,
  callback,
  options,
  predefinedApiKey,
) => {
  let opts;
  const apiKey = predefinedApiKey || getApiKey();

  if (boundaries === 'weekly') {
    opts = getStartEndDate('weekly');
  } else if (boundaries === 'lastSevenDays') {
    opts = getStartEndDate('lastSevenDays');
  } else {
    opts = boundaries;
  }

  opts.interval = 'total';
  opts.limit = 2000;

  opts = { ...opts, ...options };
  let uri = '?';
  let argcount = 0;
  for (const key in opts) {
    if (opts.hasOwnProperty(key)) {
      if (argcount++) {
        uri += '&';
      }
      uri += `${encodeURIComponent(key)}=${encodeURIComponent(opts[key])}`;
    }
  }
  $http(`${api.getEndPoint('report/searchMultiReport') + uri}&apiKey=${apiKey}`)
    .post(instanceIds, { type: 'json' })
    .then(response => {
      callback(sortObj(response));
    });
};

export const getSearchSettingsRequest = function(
  endpointPath,
  replaceParams,
  queryParams,
) {
  function findReplaceString(string, find, replace) {
    if (/[a-zA-Z_]+/g.test(string)) {
      return string.replace(
        new RegExp(`{(?:\\s+)?(${find})(?:\\s+)?}`),
        replace,
      );
    }
    throw new Error(
      'Find statement does not match regular expression: /[a-zA-Z_]+/',
    );
  }

  function replaceAll(string, mapObj) {
    let replacedStr = string;
    Object.keys(mapObj).forEach(key => {
      replacedStr = findReplaceString(replacedStr, key, mapObj[key]);
    });
    return replacedStr;
  }

  let endpoint = api.getRestEndPoint(endpointPath);
  if (replaceParams) {
    endpoint = replaceAll(endpoint, replaceParams);
  }
  const apiKey = getApiKey();
  let url = `${endpoint}?apiKey=${apiKey}`;
  if (queryParams) url += queryParams;

  const options = {};

  return $http(url, options);
};
