import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { SFYChildWrapper } from '../../../../components/sfy-components/module-exports';
import * as Components from './module-exports';
import { Context as EmailContext } from '../../store/EmailProvider';
import { getEmailComponentList } from '../../utils/utils';

const EmailDetailComponents = ({ editCampaign, emailType, mode }) => {
  const {
    state: { activeEmailDetails },
    setUploadFile,
  } = useContext(EmailContext);

  // Get the status of the coupon code feature
  const status = useSelector(
    state =>
      state?.switchedUser?.switchedUser?.account?.mailConfiguration?.tools
        ?.couponEnabled,
  );
  const componentList = getEmailComponentList(emailType);

  return (
    <SFYChildWrapper type='flexColGap2'>
      {componentList.map(component => {
        const Component = Components[component.componentName];
        // If the component is CouponCodes and the status is ACTIVE, render the component
        if (component.componentName === 'CouponCodes') {
          if (status) {
            return (
              <Component
                key={component.key ? component.key : component.name}
                component={component}
                setUploadFile={setUploadFile}
                editCampaign={editCampaign}
                activeEmailDetails={activeEmailDetails}
                emailType={emailType}
                readOnly={mode === 'preview'}
              />
            );
          }
          return '';
        }
        return (
          <Component
            key={component.key ? component.key : component.name}
            component={component}
            activeEmailDetails={activeEmailDetails}
            editCampaign={editCampaign}
            emailType={emailType}
            readOnly={mode === 'preview'}
          />
        );
      })}
    </SFYChildWrapper>
  );
};

export default EmailDetailComponents;
