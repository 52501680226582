import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import { modalActions, uiActions } from '../../../../actions';
import { t } from '../../../../system/ui';
import {
  addCampaign,
  addSegmentToGroup,
  editCampaign,
} from '../../../../modules/ds/ajax';

import { TextField } from '../../../../components/fields';
import CriteriaDetail from '../../../../components/ruleBasedSegmentation/CriteriaDetail';
import Icons from '../../../../components/icons';

const SavePreview = ({
  segmentName,
  description,
  criteriaData,
  status,
  mode,
  id,
}) => {
  const campaignDetails = useSelector(
    state => state.ds.wizardFilter.campaignDetails,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [segmentNameState, setSegmentNameState] = useState(
    `${segmentName}${mode === 'duplicate' ? ' copy' : ''}`,
  );

  const showNotification = statusType => {
    setIsSubmitting(false);
    uiActions.isLoaded();

    let note = () => {
      return <div>Segmentation is successfully updated in Draft mode.</div>;
    };
    if (statusType === 'ACTIVE') {
      note = () => {
        return <div>Segmentation is successfully updated in Live mode.</div>;
      };
    }
    uiActions.showNotification({
      content: note,
    });
  };

  const saveTheSegment = () => {
    const fixedStatus = status === 'Live' ? 'ACTIVE' : 'TEST';
    const data = {
      segmentName: segmentNameState,
      segmentStatus: fixedStatus,
      segmentFilters: criteriaData,
      description,
    };

    if (mode === 'add' && id) {
      addSegmentToGroup(id, data)
        .then(response => {
          const { status: respStatus } = response;
          showNotification(respStatus);

          browserHistory.push('/audience/dynamic-segmentation');
        })
        .catch(() => {
          setIsSubmitting(false);
          uiActions.isLoaded();
        });
    } else if (mode === 'edit' && id) {
      editCampaign(id, data)
        .then(response => {
          if (response) {
            uiActions.showNotification({
              content: () => <div>Segmentation is successfully updated.</div>,
            });
            browserHistory.push('/audience/dynamic-segmentation');
          }
        })
        .catch(() => {
          setIsSubmitting(false);
          uiActions.isLoaded();
        });
    } else if (mode === 'add') {
      addCampaign(data)
        .then(response => {
          if (response) {
            uiActions.showNotification({
              content: () => <div>Segmentation is successfully added.</div>,
            });
            const { instanceId } = response;
            browserHistory.push(
              `/audience/dynamic-segmentation/edit/${instanceId}`,
            );
          }
        })
        .catch(() => {
          setIsSubmitting(false);
          uiActions.isLoaded();
        });
    } else if (mode === 'duplicate') {
      addSegmentToGroup(id, data).then(response => {
        if (response) {
          uiActions.showNotification({
            content: () => <div>Segmentation is successfully duplicated.</div>,
          });
          browserHistory.push('/audience/dynamic-segmentation');
        }
      });
    }
  };

  const editTheSegment = () => {
    const fixedStatus = status === 'Live' ? 'ACTIVE' : 'TEST';
    const data = {
      segmentName: segmentNameState,
      segmentStatus: fixedStatus,
      segmentFilters: criteriaData,
      description,
      campaignDetails,
    };

    editCampaign(data)
      .then(response => {
        showNotification(response.status);

        const { instanceId } = response;
        browserHistory.push(
          `/audience/dynamic-segmentation/edit/${instanceId}`,
        );
      })
      .catch(() => {
        setIsSubmitting(false);
        uiActions.isLoaded();
      });
  };

  const modalClose = () => {
    setIsSubmitting(false);
    modalActions.closeModal();
  };

  const onSubmit = () => {
    const errors = document.querySelectorAll('.has-error');

    if (errors.length === 0) {
      setIsSubmitting(true);
      uiActions.isLoading();
      if (mode === 'edit') {
        editTheSegment();
      } else {
        saveTheSegment();
      }
    } else {
      uiActions.isValidating();
    }
  };

  return (
    <div className='rbs-wizard-save-preview'>
      <div
        className={`save-preview-segmentName-field ${
          mode === 'edit' ? 'readonly' : ''
        }`}
      >
        <span className='save-preview-title'>
          {t(mode === 'edit' ? 'Segment Name' : 'Segment Name*')}
        </span>
        <TextField
          className='save-preview-segmentName'
          name='segmentName'
          value={segmentNameState}
          onChange={e => setSegmentNameState(e.target.value)}
          required
          readOnly={mode === 'edit'}
        />
      </div>
      <CriteriaDetail criteriaData={criteriaData} />
      <div className='rbs-wizard-save-preview-modal-buttons'>
        <a
          className={`button goback-action one-whole ${
            isSubmitting ? 'goback-action--disabled' : ''
          }`}
          onClick={() => modalClose()}
        >
          <Icons name='leftLongArrow' />
          {t('Back')}
          {isSubmitting && (
            <span className='page-progress page-progress--button' />
          )}
        </a>
        <a
          className={`button tertiary-action one-whole gosave-action ${
            isSubmitting ? 'gosave-action--disabled' : ''
          }`}
          onClick={() => onSubmit()}
        >
          {t(`Confirm and ${status === 'Test' ? 'Save as Draft' : 'Live'}`)}
          {isSubmitting && (
            <span className='page-progress page-progress--button' />
          )}
        </a>
      </div>
    </div>
  );
};

SavePreview.propTypes = {
  id: PropTypes.string,
  segmentName: PropTypes.string,
  description: PropTypes.string,
  criteriaData: PropTypes.shape({
    operator: PropTypes.string,
    criterias: PropTypes.shape([]),
  }).isRequired,
  status: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
};

SavePreview.defaultProps = {
  id: '',
  segmentName: '',
  description: '',
};

export default SavePreview;
