import React, { Component } from "react";
import { connect } from "react-redux";

import Select from 'react-select';
import { store } from "../../../store";
import { clone } from "../../../system/object";

import classNames from "classnames";
import { t } from "../../../system/ui";
import Icon from "../../../components/icon";
import Tooltip from "../../../components/tooltip";
import { isSwitchAccount } from "../../auth/user";
import { modalActions, synonymsActions, uiActions } from '../../../actions';
import IntelligentSelectionModal from "../../../components/wizard/selectionModal.intelligent";
import SearchSynonymsItemGroup from "../../../components/wizard/search-synonyms-item-group";
import {
    synonymsTypeButtonsPalantir,
    getSynomymOptions,
    algorithmDescriptions,
} from "../../../constants/datamaps/wizard";

const selectionModals = {
    intelligent: {
        icon: "search",
        iconSvg: true,
        title: "Synonyms",
        subtitle: "Add the synonyms.",
        modal: IntelligentSelectionModal,
    },
};

export const maxSynonymDefinition = 300;

class SearchSynonyms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            synonymItems: {},
            selectedLanguage: "",
            validateMaxSynonymDefinition: true,
            showSize: 50, // default show size value
        };

        this.hasError = this.hasError.bind(this);
        this.button = this.button.bind(this);
        this.setLang = this.setLang.bind(this);
        this.addDetailsRow = this.addDetailsRow.bind(this);
        this.selectLanguage = this.selectLanguage.bind(this);
        this.openAlgorithmSelection = this.openAlgorithmSelection.bind(this);
        this.getCurrentSynonymDefinitionSize = this.getCurrentSynonymDefinitionSize.bind(this);
        this.isValidSynonymDefinitionSize = this.isValidSynonymDefinitionSize.bind(this);
        this.synonymOperatorCount = {}
    }

    componentDidMount() {
        this.setLang();
        synonymsActions.resetSynonyms();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const stateCase = (this.state.selectLanguage !== nextState.selectLanguage) ||
                                    (this.state.showSize !== nextState.showSize) ||
                                    (this.state.validateMaxSynonymDefinition !== nextState.validateMaxSynonymDefinition);
        return (this.props.changesId !== nextProps.changesId) || stateCase;

    }

    hasError(lang) {
        let isValid = true;

        let synonymItems = this.props.synonymItems;
        if (synonymItems && synonymItems[lang]) {
            Object.keys(synonymItems[lang]).forEach(iterateOnSynonymItems);
        }

        ////////

        function iterateOnSynonymItems(operator) {
            if (synonymItems[lang][operator] && synonymItems[lang][operator].length > 0) {
                synonymItems[lang][operator].forEach(onIterateSynonymInnerItem);
            }

            ////////////

            function onIterateSynonymInnerItem(item) {
                if (operator !== algorithmDescriptions()["SEARCH_SYNONYMS_TWO_WAY"].operatorUIKey) {
                    isValid = isValid && item.leftValue.value && item.leftValue.value !== "";
                }
                isValid = isValid && item.rightValues.length > 0 && item.rightValues.filter(onFilterRightValues).length === 0;

                /////

                function onFilterRightValues(rVal) {
                    if (rVal && rVal.value && rVal.value !== "") {
                        return false;
                    }
                    return true;
                }
            }
        }
        return !isValid;
    }

    isValidSynonymDefinitionSize(additionalSynonymsSize) {
        // additionalSynonyms: if the user want to add new synonyms via modal we should check also how many synonyms will be added..
        let currentDefinitionSize = this.getCurrentSynonymDefinitionSize();
        if (currentDefinitionSize + (additionalSynonymsSize || 0) > maxSynonymDefinition) {
            this.setState({
                validateMaxSynonymDefinition: false
            });
            return false;
        } else {
            this.setState({
                validateMaxSynonymDefinition: true
            });
            return true;
        }
    }

    setLang() {
        let account = isSwitchAccount() || store.getState().user.user.account;
        let mainLanguage = account.mainLanguage;
        this.setState({
            selectedLanguage: mainLanguage,
        }, () => {
            synonymsActions.languageChanged(this.state.selectedLanguage);
        });
    }

    addDetailsRow(type, selectedSynonyms) {
        if (this.isValidSynonymDefinitionSize(selectedSynonyms.length)) {
            synonymsActions.addSynonymsItemGroup(this.state.selectedLanguage, selectedSynonyms, "", []);
            uiActions.formEdited();
        }
    }

    selectLanguage(selectedLanguage, e) {
        e.preventDefault();
        this.setState({
            selectedLanguage: selectedLanguage
        }, () => {
            synonymsActions.languageChanged(this.state.selectedLanguage);
            this.isValidSynonymDefinitionSize();
        });
    }

    getCurrentSynonymDefinitionSize() {
        let total = 0;
        let lang = this.state.selectedLanguage;
        let synonymItems = this.props.synonymItems;

        let isValidSynonyms = synonymItems && synonymItems[lang] && Object.keys(synonymItems[lang]).length > 0;
        isValidSynonyms && Object.keys(synonymItems[lang]).forEach((operator) => {
            total += synonymItems[lang][operator].length;
        });
        return total;
    }

    button(item, type, cb) {
        let currentIcon = <Icon name={item.icon} />;
        return (
            <a key={type}
                className={classNames("button", item.className, {})}
                onClick={cb.bind(null, type)}>
                {currentIcon}
                {t(item.title)}
            </a>
        );
    }

    openAlgorithmSelection(obj) {
        if (this.isValidSynonymDefinitionSize()) {
            let selectionModal = selectionModals[obj];
            let Modal = selectionModal.modal;
            let props = this.props;
            let currentIntelligents = getSynomymOptions();

            modalActions.openModal({
                title: t(selectionModal.title),
                subtitle: t(selectionModal.subtitle),
                icon: selectionModal.icon,
                className: "wizard-comp-modal",
                content: () => (
                    <Modal
                        currentAlgorithms={currentIntelligents}
                        addDetailsRow={this.addDetailsRow}
                        pageProps={props}
                        disabledStatics={[]} />
                )
            });
        }
    }

    static getAccountLanguages() {
        let account = isSwitchAccount() || store.getState().user.user.account;
        let langList =
            account.additionalLanguages &&
                account.additionalLanguages.indexOf(account.mainLanguage) > -1
                ? account.additionalLanguages
                : [account.mainLanguage].concat(account.additionalLanguages);
        langList = langList.map((lang) => {
            return { value: lang, label: lang };
        });
        return langList;
    }

    sizeComboGenerator = () => {
        const hasSynonymsInLanguage = this.props.synonymItems && Object.keys(this.props.synonymItems).length > 0
        && this.state.selectedLanguage
        && this.props.synonymItems[this.state.selectedLanguage]
        && Object.keys(this.props.synonymItems[this.state.selectedLanguage]).length > 0;

        if (hasSynonymsInLanguage) {
            const totalCount = Object.entries(this.props.synonymItems[this.state.selectedLanguage]).reduce((acc, [key, value], index) => {
                this.synonymOperatorCount[key] = value.length;
                return acc + value.length;
            }, 0);
            const sortingList = Object.entries(this.synonymOperatorCount).sort(([iKey, iValue], [jKey, jValue])  => iValue - jValue);
            let algorithmMaxShowCount = Math.ceil(this.state.showSize / sortingList.length);
            sortingList.forEach(([key,value]) => {
                let diffCount = algorithmMaxShowCount - value;
                if (diffCount > 0) {
                    this.synonymOperatorCount[key] = value;
                    algorithmMaxShowCount += diffCount;
                } else {
                    this.synonymOperatorCount[key] = algorithmMaxShowCount;
                }
            })
            if (totalCount >= this.state.showSize) {
                const optionsLookup = [
                    { value: 50, label: '50' },
                    { value: 100, label: '100' },
                    { value: 150, label: '150' },
                    { value: 200, label: '200' },
                    { value: 250, label: '250' },
                ];

                const options = optionsLookup.filter(item => item.value < totalCount);

                options.push({ value: totalCount, label: totalCount });
                return (
                    <div className="size-combo-wrapper">
                        <Select
                            className="size-combo"
                            options={options || []}
                            value={this.state.showSize}
                            onChange={(valObject) => this.setState({ showSize: valObject.value })}
                            singleValue
                            placeHolder="Select Synonym Show Size."
                            clearable={false}
                        />
                        <div className="size-combo-label"> Total Synonyms Count: {totalCount} </div>
                    </div>
                );
            }
        }
        return null;

    }
    fLeftControl = () => {
        const totalCount = this.getCurrentSynonymDefinitionSize();
        return totalCount > this.state.showSize;
    }
    render() {
        let langList = SearchSynonyms.getAccountLanguages();
        let hasSynonyms = this.props.synonymItems && Object.keys(this.props.synonymItems).length > 0;
        let hasSynonymsInLanguage = hasSynonyms
            && this.state.selectedLanguage
            && this.props.synonymItems[this.state.selectedLanguage]
            && Object.keys(this.props.synonymItems[this.state.selectedLanguage]).length > 0;

        return (
            <div>
                <li className="item-title-field">
                    <div className="wizard-comp">
                        <span>
                            {t("Synonyms")}{" "}
                            <Tooltip
                                content={t("Use Synonyms, Alternative Corrections or Replacements to easily expand user queries.")}
                                alignment="top"
                                newTooltip={true}
                                isInline={true}
                            >
                                <Icon name="info" />
                            </Tooltip>{" "}
                        </span>

                        <div id="scroll-cntnr" className="search__m_t_20">
                            <div className={classNames("wizard-input-types search-synonyms", { 'f-left': this.fLeftControl()})}>
                                {Object.keys(synonymsTypeButtonsPalantir).map((item) => {
                                    return this.button(
                                        synonymsTypeButtonsPalantir[item],
                                        item,
                                        this.openAlgorithmSelection
                                    );
                                })}
                            </div>
                            {this.sizeComboGenerator()}
                        </div>


                        {!this.state.validateMaxSynonymDefinition ? (
                            <div className="item-error">
                                <span className="item-error message search__m_b_10">
                                    {t("You can add up to " + maxSynonymDefinition + " synonyms.")}
                                </span>
                            </div>
                        ) : (
                                ""
                            )}

                        {hasSynonyms && langList.length > 1 && (
                            <div className={"campaign-language-selection-extended c-both"}>
                                {langList.map((item, index) => {
                                    return (
                                        <span key={index}
                                            onClick={this.selectLanguage.bind(this, item.label)}
                                            className={classNames("language-box", { "is-selected": this.state.selectedLanguage === item.label, "has-error": this.hasError(item.label) })}>
                                            {item.label}
                                        </span>
                                    );
                                })}
                            </div>
                        )}

                        {!hasSynonymsInLanguage &&
                            <p className="wizard-comp-description">
                                {t("Use Synonyms, Alternative Corrections or Replacements to easily expand user queries")}
                            </p>
                        }

                        <div>
                            {hasSynonymsInLanguage && Object.keys(this.props.synonymItems[this.state.selectedLanguage]).map((operator, index) => {
                                return (
                                    <SearchSynonymsItemGroup key={operator}
                                        id={'createable-'+index}
                                        operator={operator}
                                        className={"search__synonym-item-group search__m_t_20"}
                                        synonymInnerItems={clone(this.props.synonymItems[this.state.selectedLanguage][operator])}
                                        showSize={this.synonymOperatorCount[operator]}
                                        selectedLanguage={this.state.selectLanguage}
                                    />
                                );
                            })}

                        </div>

                    </div>
                </li>
            </div>
        );
    }
}

const MapStatesToProps = (store) => ({
    synonymItems: store.synonyms.synonymItems,
    selectedLanguage: store.synonyms.selectedLanguage,
    id: store.synonyms.id,
    changesId: store.synonyms.changesId,
});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
    SearchSynonyms
);
