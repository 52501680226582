import {
  htmlTemplate,
  preJSTemplate,
  postJSTemplate,
  cssTemplate,
  htmlHEROTemplate,
  preJSHEROTemplate,
  postJSHEROTemplate,
  cssHEROTemplate,
  htmlNotificationTemplate,
  preJSNotificationTemplate,
  postJSNotificationTemplate,
  cssNotificationTemplate,
} from '../constants/templatesCountDown';

const getCountDownInitialTemplate = (campaign, campaignType) => {
  if (campaignType === 'popup') {
    return {
      html: campaign?.html || htmlTemplate || '',
      preJs: campaign?.preJs || preJSTemplate || '',
      postJs: campaign?.postJs || postJSTemplate || '',
      css: campaign?.css || cssTemplate || '',
    };
  }
  if (campaignType === 'hero') {
    return {
      html: campaign?.html || htmlHEROTemplate || '',
      preJs: campaign?.preJs || preJSHEROTemplate || '',
      postJs: campaign?.postJs || postJSHEROTemplate || '',
      css: campaign?.css || cssHEROTemplate || '',
    };
  }
  if (campaignType === 'notification') {
    return {
      html: campaign?.html || htmlNotificationTemplate || '',
      preJs: campaign?.preJs || preJSNotificationTemplate || '',
      postJs: campaign?.postJs || postJSNotificationTemplate || '',
      css: campaign?.css || cssNotificationTemplate || '',
    };
  }
};

const getCountDownConfigCampaignDetails = campaign => {
  let campaignStatus = '';
  switch (campaign.status) {
    case 'ACTIVE':
      campaignStatus = 'Live';
      break;
    case 'DRAFT':
      campaignStatus = 'Test';
      break;
    default:
      break;
  }
  return {
    eventName: campaign?.eventName || '',
    title: campaign?.name || '',
    campaignStatus,
  };
};

export { getCountDownInitialTemplate, getCountDownConfigCampaignDetails };
