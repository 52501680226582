import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import NavigationTab from '../ components/navigation-tab';
import { uiActions, userActions } from '../../../../actions';
import Form from '../../../../components/form';
import { config } from '../../../../constants/config';
import { UserUpdate } from '../../../../constants/datamaps/notifications';
import {
  getUser,
  isMultiAccountUser,
  updateUser,
} from '../../../../modules/auth/user';
import { setTitle } from '../../../../system/document';
import { clone } from '../../../../system/object';
import { t } from '../../../../system/ui';
import { fieldMapper } from './utils';

let languageOptions = [];
const passwordCheck = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[a-z])(?=.*[!\@\#\.\_\-\(\)])(?=.*[a-zA-Z]).{8,}$/;

const SettingsUser = props => {
  const [state, setState] = useState({
    fields: [],
    buttons: [
      {
        type: 'tertiary',
        label: 'Update',
        submitAnimation: true,
      },
    ],
    error: null,
    ui: 'isLoaded',
  });

  const forms = useRef(null);
  const {
    isLoaded,
    isLoading,
    showNotification,
    showErrorNotification,
    updateLanguage,
  } = uiActions;

  const updateForm = user => {
    setState(prev => ({
      ...prev,
      fields: fieldMapper(user, languageOptions),
    }));
  };

  const update = e => {
    e.preventDefault();
    isLoading();

    const isSwitchedUser = !!props.switchedUser.username;
    const user = isSwitchedUser ? clone(props.switchedUser) : clone(props.user);

    const newPassword = forms.current.querySelector('[name="password"]').value;
    const newPasswordRepeat = forms.current.querySelector(
      '[name="password_repeat"]',
    ).value;

    delete user.password;

    const controlStatement =
      passwordCheck.test(newPassword) && passwordCheck.test(newPasswordRepeat);

    if (
      (newPassword !== '' && newPassword !== newPasswordRepeat) ||
      !controlStatement
    ) {
      isLoaded();
      return false;
    }
    user.password = newPassword;

    setState(prev => ({
      ...prev,
      ui: 'isSubmitting',
    }));

    user.displayName = forms.current.querySelector(
      '[name="displayname"]',
    ).value;
    user.language = forms.current.querySelector(
      '[name="display-language"]',
    ).value;

    updateUser(user, response => {
      isLoaded();
      setState(prev => ({
        ...prev,
        ui: 'isLoaded',
      }));

      if (response.status === 400) {
        const error = () => <p>{t(response.response)}</p>;
        showErrorNotification(error);
      } else {
        if (response.account && response.account.accountId) {
          if (isSwitchedUser && !isMultiAccountUser()) {
            userActions.updateSwitchUser(response);
          } else {
            userActions.updateUser(response);
            localStorage.setItem('userLanguage', response.language);
            updateLanguage(response.language);
          }

          forms.current.querySelector('[name="password"]').value = '';
          forms.current.querySelector('[name="password_repeat"]').value = '';
        }
        showNotification({
          content: UserUpdate,
        });
      }
    });
  };

  useEffect(() => {
    setTitle(t('User Settings'));

    isLoading();

    languageOptions = [];

    Object.keys(config.languages).forEach(item => {
      const lang = config.languages[item];

      languageOptions.push({
        label: lang.nativeName,
        value: lang.code,
      });
    });

    getUser(response => {
      updateForm(response);

      isLoaded();
    });
  }, [props.user]);

  return (
    <div>
      <NavigationTab />
      <div className='page-forms' ref={forms}>
        <div className='page-content-block one-half'>
          <form id='settings-form' onSubmit={update}>
            <Form
              fields={state.fields}
              buttons={state.buttons}
              ui={state.ui}
              {...props}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStatesToProps = store => ({
  user: store.user.user,
  validate: store.ui.validate,
  language: store.ui.language,
  switchedUser: store.switchedUser.switchedUser,
});

export default connect(mapStatesToProps)(SettingsUser);
