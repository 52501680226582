/**
 * Created by mehmetyurtar on 10/27/17.
 */

import React, { Component } from "react";

class IconStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
      >
        <path
          fill={this.state.color}
          d="M1,3A3,3,0,1,0,4,0,3,3,0,0,0,1,3ZM5,3A1,1,0,1,1,4,2,1,1,0,0,1,5,3Z"
        />
        <path
          fill={this.state.color}
          d="M12,10a3,3,0,1,0,3,3A3,3,0,0,0,12,10Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,14Z"
        />
        <path
          fill={this.state.color}
          d="M14.293.293l-14,14a1,1,0,1,0,1.414,1.414l14-14A1,1,0,0,0,14.293.293Z"
        />
      </svg>
    );
  }
}

export default IconStats;
