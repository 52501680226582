/**
 * @fileOverview This file helps to show Features part of edit screen.
 * @deprecated
 * @requires react
 */
import React from 'react';
import { t } from '../../../system/ui';
import { features, variants } from '../../../constants/datamaps/account';
import { Checkbox, NumberField, Select } from '../../fields';
import { featuresOptions, trueFalseOptions } from '../constants';
import { isSuperiestUser } from '../../../modules/auth/user';

/**
 * This component helps to show Features part of edit screen.
 * @param {Object} account
 * @param {Object} account.global
 * @param {Object} account.features
 * @param {Object} stateAll
 * @param {Object} stateFeatures
 * @param {Object} global
 * @param {String} global.type
 * @param {Boolean} global.globalEnabled
 * @param {Number} global.globalPercentage
 * @param {Boolean} globalEnabled
 * @param {Object} incremental
 * @param {Object} incremental.productParams
 * @param {Function} reducerStateChangeHandler
 * @returns {JSX.Element|null}
 */
const Features = ({
  account,
  stateAll,
  stateFeatures,
  global,
  globalEnabled,
  incremental,
  reducerStateChangeHandler,
}) => {
  const handleFeatureUpdate = selectedOption => {
    const tempFeatures = { ...stateFeatures };
    tempFeatures[selectedOption.name].status = selectedOption.value;

    reducerStateChangeHandler('features', tempFeatures);
  };

  const handleVariantUpdate = (variantKey, selectedOption) => {
    reducerStateChangeHandler([variantKey], selectedOption.value);
  };

  const isRelatedVariantActive = (thisModuleKey, relatedModuleKey) => {
    if (!relatedModuleKey) return true;

    const relatedModule = stateAll[relatedModuleKey];
    if (!relatedModule) {
      if (stateAll[thisModuleKey]) {
        // If related variant is false, then this module should be false
        handleVariantUpdate(thisModuleKey, { value: false });
      }
      return false;
    }
    return true;
  };

  const handleGlobalUpdate = selectedOption => {
    let tempGlobal = { ...global };
    if (selectedOption.value !== tempGlobal.globalEnabled) {
      tempGlobal = { ...account.global };
    }

    tempGlobal.globalEnabled = selectedOption.value;
    if (!tempGlobal.type) {
      tempGlobal.type = 'INTERNAL';
    }
    if (!tempGlobal.globalEnabled) {
      tempGlobal.globalPercentage = 0;
      tempGlobal.type = 'INTERNAL';
    }
    if (account.global.globalEnabled === false) {
      tempGlobal.globalPercentage = 10;
    }

    reducerStateChangeHandler('global', tempGlobal);
  };

  const onGlobalPercentageChange = e => {
    const { value } = e.currentTarget;
    const tempGlobal = { ...global };
    tempGlobal.globalPercentage = value;

    reducerStateChangeHandler('global', tempGlobal);
  };

  const onGlobalFieldChange = () => {
    const tempGlobal = { ...global };
    if (tempGlobal.type === 'EXTERNAL') {
      tempGlobal.type = 'INTERNAL';
      tempGlobal.globalPercentage = 10;
      if (account.global && account.global.globalPercentage) {
        if (account.global.globalPercentage !== 0) {
          tempGlobal.globalPercentage = account.global.globalPercentage;
        }
      }
    } else {
      tempGlobal.type = 'EXTERNAL';
      tempGlobal.globalPercentage = 0;
    }

    reducerStateChangeHandler('global', tempGlobal);
  };

  const handleIncrementalUpdate = selectedOption => {
    const tempIncremental = { ...incremental };
    tempIncremental[selectedOption.name] = selectedOption.value;

    reducerStateChangeHandler('incremental', tempIncremental);
  };

  return (
    <li>
      <h3 className='section-title'>{t('Features')}</h3>
      <div className='row module-segment'>
        <div className='widget-stats'>
          <div className='widget-stat-segmentation  stat-segmentation'>
            <p className='widget-stat-conversion-features'>
              <b>{t('Status')}</b>
            </p>
          </div>
          <div className='widget-stat-segmentation  stat-segmentation' />
        </div>
        {features.map(item => (
          <div
            className='row'
            style={{ marginBottom: '-10px' }}
            key={item.key + item.name}
          >
            <hr className='widget-separator' style={{ margin: '10px' }} />
            <Select
              name={item.key}
              className='item-stacked features-account-label six-columns'
              value={
                account.features[item.key]
                  ? account.features[item.key].status
                  : 'PASSIVE'
              }
              options={featuresOptions}
              label={item.name}
              onChange={handleFeatureUpdate}
            />
          </div>
        ))}
        <div className='row' style={{ marginBottom: '-10px' }}>
          <hr className='widget-separator' style={{ margin: '10px' }} />
          <Select
            key='global'
            name='global'
            className='item-stacked features-account-label six-columns'
            value={global.globalEnabled}
            options={trueFalseOptions}
            label='Control Group'
            onChange={handleGlobalUpdate}
          />

          {global.globalEnabled && (
            <>
              <span>
                <NumberField
                  name='globalPercentage'
                  label='Percentage % '
                  labelPlace='before'
                  inputClassName='x-small'
                  value={
                    global.type === 'EXTERNAL' ? 0 : global.globalPercentage
                  }
                  disabled={
                    global.type === 'EXTERNAL' || globalEnabled === true
                  }
                  readOnly={
                    global.type === 'EXTERNAL' || globalEnabled === true
                  }
                  onChange={onGlobalPercentageChange}
                  min='1'
                  max='100'
                  step='1'
                  style={{
                    backgroundColor:
                      global.type === 'EXTERNAL' || globalEnabled === true
                        ? '#7a7a7c2e'
                        : '',
                  }}
                />
              </span>
              <span style={{ verticalAlign: 'sub', marginLeft: '10px' }}>
                <Checkbox
                  label='External'
                  value='External'
                  checked={global.type === 'EXTERNAL'}
                  onChange={onGlobalFieldChange}
                  disabled={account.global.type === 'EXTERNAL'}
                />
              </span>
            </>
          )}
        </div>
        <div className='row' style={{ marginBottom: '-10px' }}>
          <hr className='widget-separator' style={{ margin: '10px' }} />
          <Select
            key='productParams'
            name='productParams'
            className='item-stacked features-account-label six-columns'
            value={
              account?.incremental?.productParams
                ? account.incremental.productParams
                : 'false'
            }
            options={trueFalseOptions}
            label='Incremental Params'
            onChange={handleIncrementalUpdate}
          />
        </div>
        <div className='row' style={{ marginBottom: '-10px' }}>
          <hr className='widget-separator' style={{ margin: '10px' }} />
          <Select
            key='personalisedAds'
            name='personalisedAds'
            className='item-stacked features-account-label six-columns'
            value={
              account.features.personalisedAds
                ? account.features.personalisedAds.status
                : 'PASSIVE'
            }
            options={featuresOptions}
            label='Personalised Ads'
            onChange={handleFeatureUpdate}
          />
        </div>
        {variants.map(item => (
          <div
            className='row'
            style={{ marginBottom: '-10px' }}
            key={item.key + item.name}
          >
            <hr className='widget-separator' style={{ margin: '10px' }} />
            <Select
              name={item.key}
              className='item-stacked features-account-label six-columns'
              value={stateAll[item.key] || false}
              options={trueFalseOptions}
              label={item.name}
              onChange={e => handleVariantUpdate(item.key, e)}
              disabled={
                !isSuperiestUser() ||
                !isRelatedVariantActive(item.key, item?.relatedModuleKey)
              }
            />
          </div>
        ))}
      </div>
    </li>
  );
};

export default Features;
