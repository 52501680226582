/**
 * @author Bilal Çınarlı
 **/

import React, { Component } from "react";
import { connect } from "react-redux";

import { uiActions, wizardActions } from "../../../actions";
import { t } from "../../../system/ui";

import Icon from "../../../components/icon";
import Tooltip from "../../../components/tooltip";
import Select from "react-select";

const options = [
  { value: "LEFT", label: t("Left") },
  { value: "CENTER", label: t("Center") },
  { value: "RIGHT", label: t("Right") }
];

class OpeningDirection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "div#openingDirection",
      name: "OpeningDirection"
    };

    this.updateValue = this.updateValue.bind(this);
  }

  componentDidMount() {
    let input = this.props.campaign.openingDirection || "LEFT";
    this.updateValue(input);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.campaign !== newProps.campaign) {
      this.updateValue(newProps.campaign.openingDirection || "LEFT");
    }
  }

  updateValue(newValue) {
    this.setState({ value: newValue });
  }

  directionChanged(selectedOptions) {
    this.setState({
      value: selectedOptions.value
    });

    uiActions.formEdited();
  }

  render() {
    return (
      <li className="item-title-field">
        <span className="item item-stacked label-emphasise one-whole">
          <span className="item-label">
            {t(this.props.options.label)}
            {this.props.options.tooltip && (
              <span style={{ marginTop: "-5px" }}>
                <Tooltip
                  content={t(this.props.options.tooltipContent)}
                  alignment="right"
                >
                  <i className="icon-info" role="presentation">
                    <span className="for-screenreader-only"></span>
                  </i>
                </Tooltip>
              </span>
            )}
          </span>
        </span>
        <label className="item is-select one-whole">
          <Select
            searchable={false}
            clearable={false}
            name="insertType"
            options={options}
            value={this.state.value}
            onChange={this.directionChanged.bind(this)}
          />
        </label>
      </li>
    );
  }
}

const MapStatesToProps = store => ({});

export default connect(MapStatesToProps, null, null, { forwardRef: true })(
  OpeningDirection
);
