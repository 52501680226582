import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';

import { filterActions } from 'actions';
import { TextField } from 'components/fields';
import DeviceSelect from 'components/filters/device-select';
import Icon from 'components/icon';
import Icons from 'components/icons';
import { searchCategories } from 'modules/category/ajax';
import { decodeHtml } from 'system/string';
import { t } from 'system/ui';
import { getCustomTrendifyColumns } from '../../../modules/auth/user';

class TrendifyInsightsFiltersNew extends React.Component {
  initialState = {
    brand: '',
    category: '',
    productName: '',
    minPrice: 0,
    maxPrice: 1000000000000,
    maxPriceMin: 1000000000000,
    maxPriceError: false,
    stock: 'all',
    categories: [],
    customFields: [],
  };

  state = this.initialState;

  componentDidMount() {
    this.setCustomFields();
  }

  // eslint-disable-next-line camelcase,react/sort-comp
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.reset === true) {
      this.reset();
    }
  }

  setCustomFields = () => {
    let newState = {};
    if (getCustomTrendifyColumns()) {
      newState = getCustomTrendifyColumns()
        .filter(field => field.filterable)
        .reduce(
          (result, field) => ({
            ...result,
            [field.field]: '',
            customFields: [
              ...result.customFields,
              {
                name: field.field,
                dataType: field.dataType,
                label: field.label,
              },
            ],
          }),
          { customFields: [] },
        );
    }

    this.initialState = newState;
    this.setState(newState);
  };

  onChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        filterActions.showButtons();
      },
    );
  };

  onSearchCategory = value => {
    if (value.length > 2) {
      searchCategories(value, response => {
        const sorted = response.sort();
        const categories = [];
        sorted.forEach(item => {
          categories.push({
            value: item,
            label: decodeHtml(item),
          });
        });
        this.setState({
          categories,
        });
      });
    } else {
      this.setState({
        categories: [],
      });
    }
    return value;
  };

  onChangeMin = e => {
    filterActions.showButtons();
    filterActions.enableButtons();

    const maxPrice = this.refs.maxPrice.value * 1;

    this.setState({
      maxPriceMin: e.currentTarget.value,
      minPrice: e.currentTarget.value,
    });

    if (maxPrice > 0 && e.currentTarget.value > maxPrice) {
      this.setState({
        maxPriceError: true,
      });

      filterActions.disableButtons();
    }
  };

  onChangeMax = e => {
    filterActions.showButtons();

    if (
      e.currentTarget.value !== '' &&
      e.currentTarget.value * 1 < this.state.maxPriceMin
    ) {
      this.setState({
        maxPriceError: true,
        maxPrice: e.currentTarget.value,
      });
      filterActions.disableButtons();
    } else {
      this.setState({
        maxPriceError: false,
        maxPrice: e.currentTarget.value,
      });

      filterActions.enableButtons();
    }
  };

  onChangeStock = selectedOption => {
    this.setState(
      {
        stock: selectedOption.value,
      },
      () => filterActions.showButtons(),
    );
  };

  onChangeCategory = selectedOption => {
    this.setState({
      category: selectedOption ? selectedOption.value : '',
    });

    filterActions.showButtons();
  };

  onChangeBrand = selectedOption => {
    this.setState({
      brand: selectedOption ? selectedOption.value : '',
    });

    filterActions.showButtons();
  };

  getCustomFieldComponent = customField => {
    const { dataType, name, label } = customField;
    const booleanFieldOptions = [
      { value: 'all', label: t(label) },
      { value: 'true', label: t('True') },
      { value: 'false', label: t('False') },
    ];
    switch (dataType) {
      case 'string':
        return (
          <TextField
            name={name}
            value={this.state[name]}
            className='item-stacked'
            label={label}
            placeholder={label}
            onChange={this.onChange}
          />
        );
      case 'boolean':
        return (
          <label
            className='item item-field is-select'
            style={{ minWidth: `${name.length * 18}px` }}
          >
            <Select
              searchable={false}
              clearable={false}
              value={this.state[name]}
              options={booleanFieldOptions}
              name={name}
              onChange={this.onChange}
            />
          </label>
        );
      case 'currency':
      case 'number':
        return (
          <span>
            <label className='item item-stacked price-range price-range-min'>
              <span className='item-label'>{t(label)}</span>
              <span className='item-label-alt'>{t('min')}:</span>
              <input
                type='number'
                id={`${name}min`}
                name={`${name}.min`}
                value={this.state[`${name}.min`]}
                ref={`${name}min`}
                min='0'
                step='1'
                onChange={this.onChange}
              />
            </label>

            <label className='item item-stacked price-range'>
              <span className='item-label'>&nbsp;</span>
              <span className='item-label-alt'>{t('max')}:</span>
              <input
                type='number'
                id={`${name}max`}
                name={`${name}.max`}
                value={this.state[`${name}.max`]}
                ref={`${name}max`}
                min='0'
                step='1'
                onChange={this.onChange}
              />
            </label>
          </span>
        );
      default:
        return <></>;
    }
  };

  reset = () => {
    this.setState(this.initialState);
  };

  resetFilters = e => {
    e.preventDefault();
    this.props.toggleAnalyticsFilters();
    filterActions.resetAllFilters();
  };

  updateFilters = e => {
    e.preventDefault();
    this.props.toggleAnalyticsFilters();
    if (this.props.disableButtons) {
      return;
    }
    filterActions.updateFilters();
  };

  render() {
    const stocks = [
      { value: 'all', label: t('Product Stock') },
      { value: 'true', label: t('Only In Stock') },
      { value: 'false', label: t('Only Out of Stock') },
    ];

    let className = 'ti-analytics-filters analytics-filters group hide-filters';
    if (this.props.showTrendifyFilters) {
      className = 'ti-analytics-filters analytics-filters group show-filters';
    }
    let categories = [{ value: '', label: t('All Product Categories') }];
    categories = categories.concat(this.state.categories);
    if (this.state.category) {
      categories = categories.concat([
        { value: this.state.category, label: decodeHtml(this.state.category) },
      ]);
    }

    const brands = [{ value: '', label: t('All Product Brands') }];

    this.props.brands.forEach(item => {
      brands.push({
        value: item,
        label: item,
      });
    });

    return (
      <div className={className} ref='filters'>
        <label
          htmlFor='productCategory'
          className='item item-stacked is-select large'
        >
          <span className='item-label'>{t('Product Category')}</span>
          <Select
            value={this.state.category}
            options={categories}
            name='productCategory'
            onChange={this.onChangeCategory}
            clearable={false}
            onInputChange={this.onSearchCategory}
            searchable
          />
        </label>
        <label
          htmlFor='productBrand'
          className='item item-stacked is-select large'
        >
          <span className='item-label'>{t('Product Brand')}</span>
          <Select
            value={this.state.brand}
            options={brands}
            name='productBrand'
            onChange={this.onChangeBrand}
            clearable={false}
          />
        </label>
        <TextField
          name='productName'
          className='item-stacked'
          label='Search Product'
          placeholder='Product ID or Title'
          value={this.state.productName}
          onChange={this.onChange}
        />

        <label
          htmlFor='minPrice'
          className='item item-stacked price-range price-range-min'
        >
          <span className='item-label'>{t('Price Range')}</span>
          <span className='item-label-alt'>{t('min')}:</span>
          <input
            type='number'
            id='minPrice'
            ref='minPrice'
            min={0}
            step='1'
            onChange={this.onChangeMin}
            value={this.state.minPrice}
          />
        </label>
        <label htmlFor='maxPrice' className='item item-stacked price-range'>
          <span className='item-label'>&nbsp;</span>
          <span className='item-label-alt'>{t('max')}:</span>
          <input
            type='number'
            id='maxPrice'
            ref='maxPrice'
            min={this.state.maxPriceMin}
            step='1'
            onChange={this.onChangeMax}
            value={this.state.maxPrice}
          />
          {this.state.maxPriceError && (
            <span className='item-error'>
              {t('This number should bigger than min price')}
            </span>
          )}
        </label>

        <label
          className='item item-field is-select has-icon-label'
          style={{ width: '160px' }}
        >
          <span className='item-label'>
            <Icon name='stock' />
          </span>
          <Select
            searchable={false}
            clearable={false}
            value={this.state.stock}
            options={stocks}
            name='productStock'
            onChange={this.onChangeStock}
          />
        </label>

        <DeviceSelect />

        {this.state.customFields.map(customField =>
          this.getCustomFieldComponent(customField),
        )}

        <div className='page-filters-buttons ti-analytics-filters-actions'>
          <div
            className='ti-analytics-filters-close'
            onClick={this.props.toggleAnalyticsFilters}
          >
            <Icons name='cross' color='#7b7e7e' />
          </div>

          <a
            className='cancel-action analytics-filters--reset'
            onClick={this.resetFilters}
            style={{
              opacity: this.props.visibleReset ? '1' : '0.6',
              pointerEvents: this.props.visibleReset ? 'auto' : 'none',
            }}
          >
            {t('Reset Filters')}
          </a>

          <a
            className={classNames(
              'tertiary-action ti-analytics-filters--update',
              {
                disabled: this.props.disableButtons,
              },
            )}
            onClick={this.updateFilters}
          >
            {t('Update')}
            <Icon name='refresh' />
          </a>
        </div>
      </div>
    );
  }
}

TrendifyInsightsFiltersNew.defaultProps = {
  disableButtons: false,
  visibleReset: false,
};

TrendifyInsightsFiltersNew.propTypes = {
  showTrendifyFilters: PropTypes.bool.isRequired,
  disableButtons: PropTypes.bool,
  visibleReset: PropTypes.bool,
  toggleAnalyticsFilters: PropTypes.func.isRequired,
  brands: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStatsToProps = store => ({
  reset: store.filters.reset,
  range: store.date.range,
  rangeAlias: store.date.rangeAlias,
  brands: store.filters.trendifyBrands,
  visibleReset: store.filters.visibleReset,
  disableButtons: store.filters.disableButtons,
});

export default connect(mapStatsToProps)(TrendifyInsightsFiltersNew);
