import React from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';
import ReportStats from 'components/search/faceted-search/report/ReportStatsViewAll';
import FacetedSearchActions from 'components/search/faceted-search/listing/FacetedSearchActions';
import { devices } from '../../constants/datamaps/campaigns';

import { t } from '../../system/ui';
import { idPropFunc } from '../../system/id';

import { activateCampaign } from '../../modules/campaigns/ajax';
import Tooltip from '../tooltip';
import Icons from '../icons';

import { dialogActions } from '../../actions';

import Icon from '../icon';
import WidgetStats from './stats';
import WidgetOptions from './options';
import SearchActions from './search-actions';

class SearchWidgetCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDevice: 'ALL',
    };
    this.goTestFromArchive = this.goTestFromArchive.bind(this);
    this.goTestConfirmed = this.goTestConfirmed.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  goTestFromArchive() {
    dialogActions.openDialog({
      title: t('Attention!'),
      content: t('Are you sure to switch campaign to test mode?'),
      onConfirm: () => {
        this.goTestConfirmed();

        dialogActions.closeDialog();
      },
      onCancel: () => {
        dialogActions.closeDialog();
      },
    });
  }

  selectDevice(selectedDevice) {
    this.setState({
      selectedDevice,
    });
  }

  goTestConfirmed() {
    activateCampaign(this.props.instanceId, response => {
      if (response.status === 'OK') {
        // update action
        this.props.getCampaigns('Test');
      }
    });
  }

  renderActions() {
    if (this.props.type === 'faceted') {
      return (
        <FacetedSearchActions
          getCampaignsAfterStatusChange={
            this.props.getCampaignsAfterStatusChange
          }
          campaign={this.props.campaign}
        />
      );
    }
    if (this.props.status === 'PASSIVE') {
      return (
        <SearchActions
          isArchive
          campaign={this.props.campaign}
          {...this.props}
          getCampaignsAfterStatusChange={
            this.props.getCampaignsAfterStatusChange
          }
          goTestFromArchive={this.goTestFromArchive}
        />
      );
    }
    return <SearchActions campaign={this.props.campaign} {...this.props} />;
  }

  render() {
    return (
      <div
        className='widget page-content-block'
        ref='widget'
        {...idPropFunc(this.props.id)}
      >
        <WidgetOptions
          isSearch
          campaign={this.props.campaign}
          instance={this.props.instanceId}
          testMode={this.props.status !== 'ACTIVE'}
          limitations={this.props.limitations}
          getCampaignsAfterStatusChange={
            this.props.getCampaignsAfterStatusChange
          }
        />
        <h2 className='widget-title'>
          {this.props.name || this.props.scenarioName}
        </h2>

        {this.props.type !== 'faceted' && (
          <div className='widget-meta'>
            {this.props.campaignsInited && (
              <span className='recommendation-device-selection'>
                <Tooltip
                  content='All Devices'
                  alignment='top'
                  newTooltip
                  isInline
                >
                  <span
                    key='all-devices'
                    onClick={this.selectDevice.bind(this, 'ALL')}
                    className={classNames(
                      'widget-meta-mobile all-device-report',
                      {
                        'is-selected': this.state.selectedDevice === 'ALL',
                      },
                    )}
                  >
                    <Icons
                      name='allDevices'
                      width='16'
                      height='16'
                      color='#bababa'
                    />
                    {t('ALL')}
                  </span>
                </Tooltip>

                {this.props.campaign.devices.map(device => {
                  if (
                    device.toLowerCase() === 'pctablet' ||
                    device.toLowerCase() === 'tablet'
                  ) {
                    return;
                  }

                  return (
                    <Tooltip
                      key={device}
                      content={t(devices[device])}
                      alignment='top'
                      newTooltip
                      isInline
                    >
                      <span
                        key={device}
                        onClick={this.selectDevice.bind(this, device)}
                        className={classNames('widget-meta-mobile', {
                          'is-selected': this.state.selectedDevice === device,
                        })}
                      >
                        <Icon name={device.toLowerCase()} />
                      </span>
                    </Tooltip>
                  );
                })}
              </span>
            )}
          </div>
        )}

        {this.props.type !== 'faceted' && <hr className='widget-separator' />}

        {this.props.type === 'faceted' ? (
          <ReportStats instanceId={this.props.instanceId} />
        ) : (
          <WidgetStats
            ref='widgetStats'
            report={this.props.report}
            selectedDevice={
              this.state.selectedDevice === 'PC'
                ? 'PC'
                : this.state.selectedDevice.toLowerCase()
            }
            withReport={this.props.withReport}
            {...this.props}
          />
        )}

        <hr className='widget-separator' />
        <div className='widget-buttons group'>{this.renderActions()}</div>
      </div>
    );
  }
}

const mapStatesToProps = store => ({
  user: store.user.user,
  totalRevenueFaceted: store.facetedSearch.singleReport.totalRevenue,
});

export default connect(mapStatesToProps)(SearchWidgetCard);
