/**
 * Created by mehmetyurtar on 4/20/18.
 */
import React from "react";

class IconDelete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: "16px",
      height: "16px",
      color: "#eee"
    };

    this.setStyles = this.setStyles.bind(this);
  }

  componentDidMount() {
    this.setStyles();
  }

  setStyles() {
    let width = this.props.width ? this.props.width + "px" : this.state.width,
      height = this.props.height ? this.props.height + "px" : this.state.height,
      color = this.props.color ? this.props.color : this.state.color;

    this.setState({ width, height, color });
  }

  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width={this.state.width}
        height={this.state.height}
        className="svg-icon"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth={1}
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="2-Account-Switch-2"
            transform="translate(-1030.000000, -439.000000)"
            fill={this.state.color}
            fillRule="nonzero"
          >
            <g id="Group-2" transform="translate(0.000000, 65.000000)">
              <g
                id="Group-Copy-2"
                transform="translate(204.000000, 313.000000)"
              >
                <g id="Group-5" transform="translate(813.000000, 21.000000)">
                  <g id="Group-6" transform="translate(13.000000, 39.000000)">
                    <g
                      id="ui-16px-1_circle-remove"
                      transform="translate(0.000000, 1.000000)"
                    >
                      <path
                        d="M8,0 C3.589,0 0,3.589 0,8 C0,12.411 3.589,16 8,16 C12.411,16 16,12.411 16,8 C16,3.589 12.411,0 8,0 Z M11.535,10.121 L10.121,11.535 L8,9.414 L5.879,11.535 L4.465,10.121 L6.586,8 L4.465,5.879 L5.879,4.465 L8,6.586 L10.121,4.465 L11.535,5.879 L9.414,8 L11.535,10.121 Z"
                        id="Shape"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export default IconDelete;
