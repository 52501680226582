import { appEnv } from '../../../constants/config';

export const userAdminTypesArray = [
  'Agent User',
  'Account Developer',
  'Onboarding Developer',
  'Account Manager',
  'Onboarding Manager',
  'Sales Manager',
];

export const userTypesArray = ['Account User', ...userAdminTypesArray];

export const userTypes = [
  'Master User',
  'Super User',
  'Account Developer',
  'Onboarding Developer',
  'Account Manager',
  'Onboarding Manager',
  'Sales Manager',
  'Agent User',
  'Account User',
];

export const masterUserTypesArray = [
  'Master User',
  'Super User',
  ...userAdminTypesArray,
];

export const userType2Backend = {
  'Account User': '',
  'Account Developer': 'ROLE_ACD',
  'Onboarding Developer': 'ROLE_OBD',
  'Account Manager': 'ROLE_ACM',
  'Onboarding Manager': 'ROLE_OBM',
  'Sales Manager': 'ROLE_SM',
  'Master User': 'MASTERUSER',
  'Super User': 'SUPERUSER',
  'Agent User': 'ROLE_AGU',
};

export const allModules = [
  'recommendation',
  'search',
  'push',
  'email',
  'segmentation',
  'trendify',
  'bannerify',
  'personalization',
  'dynamicBundles',
];

export const moduleMaps = {
  All: 'all',
  recommendation: 'recommendation',
  search: 'search & discovery',
  push: 'web push',
  email: 'email marketing',
  segmentation: 'segmentation',
  trendify: 'trendify',
  bannerify: 'bannerify',
  personalization: 'engagement',
  dynamicBundles: 'dynamic bundles',
};

export const module2privilege = {
  trendify: 'TRENDUSER',
  bannerify: 'BANNERUSER',
  recommendation: 'RECOUSER',
  email: 'EMAIL',
  push: 'PUSH',
  search: 'SEARCH',
  segmentation: 'SEGMENTATION',
  personalization: 'ENGAGEMENT',
  dynamicBundles: 'DYNAMIC_BUNDLE',
};

export const SuperUserRefObject = {
  'Master User': {
    inputArray: ['displayName', 'username'],
    privileges: ['ACCOUNT_ADMIN', 'MASTERUSER'],
    isInternal: true,
  },
  'Super User': {
    inputArray: ['displayName', 'username'],
    privileges: ['ACCOUNT_ADMIN', 'SUPERUSER'],
    isInternal: true,
  },
  'Account Developer': {
    inputArray: ['displayName', 'username'],
    privileges: ['ACCOUNT_ADMIN', 'ROLE_ACD'],
    isInternal: true,
  },
  'Onboarding Developer': {
    inputArray: ['displayName', 'username'],
    privileges: ['ACCOUNT_ADMIN', 'ROLE_OBD'],
    isInternal: true,
  },
  'Account Manager': {
    inputArray: ['displayName', 'username'],
    privileges: ['ACCOUNT_ADMIN', 'ROLE_ACM'],
    isInternal: true,
  },
  'Onboarding Manager': {
    inputArray: ['displayName', 'username'],
    privileges: ['ACCOUNT_ADMIN', 'ROLE_OBM'],
    isInternal: true,
  },
  'Sales Manager': {
    inputArray: ['displayName', 'username'],
    privileges: ['ACCOUNT_ADMIN', 'ROLE_SM'],
    isInternal: true,
  },
  'Agent User': {
    inputArray: ['displayName', 'username'],
    privileges: ['ACCOUNT_ADMIN', 'ROLE_AGU'],
    isInternal: true,
  },
  'Account User': {
    modules: allModules,
    inputArray: ['displayName', 'username', 'accounts', 'modules', 'userRole'],
    privileges: [],
  },
};

export const userRoles = [
  { label: 'Read-Only', value: 'READ_ONLY' },
  { label: 'Editor', value: 'EDITOR' },
  { label: 'Admin', value: 'ADMIN' },
];

const sgfAccountIdMap = {
  dev: 'acc_618542d18f8ce001',
  qa: 'acc_618542d18f8ce001',
  local: 'acc_618542d18f8ce001',
  prod: 'acc_618542d18f8ce000',
  search: 'acc_618542d18f8ce001',
};

export const getSgfAccountId = () => sgfAccountIdMap[appEnv()];
